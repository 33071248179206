import evenshotBG from '@/assets/oldgill/images/backgrounds/evenshot/evenshotBG.png';
import coin from '@/assets/oldgill/images/backgrounds/evenshot/coin.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    textTransform: 'capitalize',
  },
  flexWrapper: {
    mx: '0',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow:
      '0px 4px 10px 0px rgba(255, 255, 255, 0.12) inset, 0px 4px 5px 0px rgba(0, 0, 0, 0.49)',
    border: 'none',
    flexDir: 'column',
    color: 'white',
    p: '4',
    // because requires bg image & gradient
    bgImage: `url(${evenshotBG}), linear-gradient(180deg, #0067B2 0%, #00528D 100%)`,
    bgSize: 'contain',
    w: 'auto',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: ['41px', '46px'],
    fontFamily: 'Hitchcut ',
    fontWeight: 'normal',
    textAlign: 'center',
    color: 'beta.400',
    textShadow: '0px 4px 8px rgba(0, 0, 0, 0.30)',
  },
  addToBetslipButton: {
    variant: 'secondary',
  },
  loginToView: {
    variant: 'secondary',
    sx: {
      '&&': {
        background: 'beta.400',
        _hover: {
          bg: 'beta.600',
        },
      },
    },
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'beta.400',
    fontSize: 'lg',
    fontWeight: 'extrabold',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  evenShotImage: {
    boxSize: '147px',
    my: '6',
    backgroundImage: `url(${coin})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  oddsValueSpan: {
    color: 'beta.400',
  },
  subHeadingText: {
    fontSize: 'sm',
    color: 'white',
  },
  notAvailableText: {
    fontWeight: 'bold',
    color: 'beta.400',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notYetAvailableText: {
    color: 'beta.400',
    fontWeight: 'bold',
  },
};
