import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const IconRace = ({ selected }: TSelected) => ({
  boxSize: '5',
  bg: 'transparent',
  color: selected ? 'beta.800' : 'blackAlpha.600',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'blackAlpha.400',
  color: selected ? 'beta.800' : 'transparent',
  mr: '0.5',
});

export const TextFilterLabel: CSSObject = {
  color: 'beta.800',
  fontWeight: 'bold',
};

export const Divider: CSSObject = {
  bg: 'blackAlpha.400',
  display: 'block',
  h: '14px',
  my: 'auto',
  mx: '4',
};

export const FlexRaceTypeWrapper: CSSObject = {
  mr: 'unset',
};

export const FlexFilterWrapper: CSSObject = {
  px: '0',
  py: '0',
  mt: '0.5',
  mb: '2.5',
  h: ['fit-content', '9'],
  position: 'inherit',
  flexWrap: ['wrap', null],
};

export const ButtonFilter = () => ({
  h: '26px',
});
