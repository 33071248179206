import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'whiteAlpha.900', pt: 0.5 },
};

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  bgGradient: 'linear(to-b,gamma.400, gamma.500)',
  borderRadius: 'md',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'white' : 'gray.600',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'md',

  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'alpha.500' : 'transparent',
  boxShadow: isActive
    ? '0px 1px 0px rgba(255, 255, 255, 0.45), inset 0px 0px 5px rgba(0, 0, 0, 0.45)'
    : 'none',

  backgroundBlendMode: 'lighten, overlay, normal',

  _hover: {
    bg: isActive ? 'gamma.500' : 'gamma.700',
    color: 'white',
    boxShadow: 'none',
    textShadow: 'none',
  },
});

export const buttonAccountTabOptionProps: CustomButtonProps = {
  variant: 'tab',
  color: 'gamma.900',
  bgImage: 'none',
  boxShadow: 'none',
  bg: 'transparent',
  border: 'none',
  fontWeight: 'bold',
  textTransform: 'uppercase',

  sx: {
    '&&': {
      _hover: {
        bg: 'gamma.600',
        color: 'white',
        boxShadow: 'none',
        border: 'none',
      },
    },
    '&[aria-selected="true"]': {
      color: 'epsilon.100',
      bg: 'gamma.800',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.45)',
      _hover: {
        bg: 'gamma.600',
        color: 'white',
      },
    },
  },
};
