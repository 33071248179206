import React from 'react';
import { Global } from '@emotion/core';
import bg from '@/assets/chromabet/images/backgrounds/bg.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          background: `url(${bg}), #1A1D1B`,
          backgroundPosition: 'top',
          backgroundRepeat: 'repeat-x',
          backgroundSize: '344px 1684px, cover',
        },
      }}
    />
  );
}
