import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { TIndex } from '@/components/FormElements/Input';

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderWidth: '2px',
  borderColor: 'blackAlpha.400',
  borderStyle: 'solid',
  opacity: '0.24',
  boxShadow: '0px 1px 0px 0px rgba(0, 154, 188, 0.20)',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
  bg: 'red.500',
};

export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  color: 'alpha.800',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
};

export const TextMaxCards: CSSObject = {
  color: 'delta.100',
};

export const BoxInputContainer: CSSObject = {
  mb: '1',

  '.chakra-form-control': {
    mb: '0',
  },

  '& .chakra-input': {
    bg: 'gray.50',
    color: 'gray.700',
    boxShadow: 'unset',
    border: '1px',
    borderColor: 'gray.300',
    _placeholder: {
      color: 'gray.700',
      fontSize: 'xs',
    },
    _focus: {
      bg: 'gray.100',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const ButtonDeposit: CSSObject = {};

export const FormBox: CSSObject = {
  'div.chakra-collapse div': {
    bg: 'zeta.200',
  },
  'div.chakra-collapse div p strong': {
    color: 'alpha.800',
  },
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  h: '38px',
  textShadow: '0px 2px 1px rgba(0, 0, 0, 0.6)',
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'beta.800',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'regular',
  lineHeight: '14.4px',
  color: 'white',
  mt: '1',
};

export const depositInputProps: Partial<TIndex> = {
  sxWrapper: {
    '&&': {
      '.chakra-input': {
        bg: 'white',
      },
    },
  },
};
