import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';
import { TBetForRace } from '@/api/racing/bets/bets.types';
import IconSvg from '@/components/common/IconSvg';
import { getIconBySportName, centsToDollars } from '@/helpers/utils';
import { onRaceBetStyles } from '../styles/OnRaceBets.styles';
import {
  calculateExoticCombinations,
  calculateExoticFlexi,
  getPlaceFromNumber,
} from '@/components/Betslip/Services/Betslip.utils';
import { TBetSlipBetSubmissionExoticsType } from '@/components/Betslip/Services/Betslip.types';

type ExoticsBetProps = {
  bet: TBetForRace;
};

export const ExoticsBet = ({ bet }: ExoticsBetProps) => {
  const {
    proposition_info: propositionInfo,
    exotic_selections: exoticSelections,
    bet_stake: betStake,
  } = bet;
  const propInfo = propositionInfo?.[0];
  const { racingType } = useParams();
  const runnerSelections = exoticSelections?.map((exoticCombos) =>
    exoticCombos?.exotic_combos?.map((selection) => selection?.runner_number)
  );
  const intl = useIntl();

  const calculateCombinations = calculateExoticCombinations(
    runnerSelections,
    propInfo?.proposition_type as TBetSlipBetSubmissionExoticsType
  );
  const flexi = calculateExoticFlexi(
    betStake ? betStake / 100 : 0,
    calculateCombinations.length
  );

  return (
    <Flex key={bet.bet_id} {...onRaceBetStyles.betRow}>
      <IconSvg
        name={getIconBySportName(racingType)}
        {...onRaceBetStyles.categoryIcon}
      />
      <Flex direction="column" flex="1">
        <Flex direction="row">
          <Text {...onRaceBetStyles.runnerText} flex="1">
            {propInfo?.proposition_type}
          </Text>
          <Text {...onRaceBetStyles.runnerText}>{propInfo?.name}</Text>
          <Text {...onRaceBetStyles.subtitleText} flex="1">
            {intl.formatMessage({ id: 'generic.stake' })}:{' '}
            <Text as="span" {...onRaceBetStyles.stakeText}>
              {centsToDollars(bet.bet_stake)}
            </Text>
          </Text>
        </Flex>
        {exoticSelections.map((sel, index) => (
          <Flex key={index}>
            <Text {...onRaceBetStyles.exoticsPositionText}>
              {getPlaceFromNumber(index + 1)}:{' '}
            </Text>
            <Text as="span" {...onRaceBetStyles.subtitleText}>
              {sel.exotic_combos
                .map((runner) => runner.runner_number)
                .join(', ')}
            </Text>
          </Flex>
        ))}
        <Text {...onRaceBetStyles.subtitleText}>
          <Text as="span" {...onRaceBetStyles.flexiText}>
            {intl.formatMessage({ id: 'generic.flexi' })}:{' '}
          </Text>{' '}
          {flexi}%
        </Text>
      </Flex>
    </Flex>
  );
};
