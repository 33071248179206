import { AlertProps } from '@chakra-ui/react';
import { styleImports } from './BetRecoveryAlert.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const betRecoveryAlertProps: AlertProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.betRecoveryAlertProps;
    } catch (err) {
      return {};
    }
  })(),
};
