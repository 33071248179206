import betprofessorBg from '@/assets/betprofessor/images/backgrounds/evenshot/betprofessorBg.png';
import evenShot from '@/assets/betprofessor/images/backgrounds/evenshot/evenshot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    my: '4',
    fontWeight: 'bold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    mx: '4',
    mb: '4',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
    color: 'white',
    flexDir: 'column',
    p: '4',
    // because requires bg image & gradient
    bgImage: `url(${betprofessorBg}), linear-gradient(180deg, #2E5470 0%, rgba(255, 255, 255, 0.00) 100%)`,
    bgSize: 'cover',
    w: 'auto',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    color: 'alpha.400',
    fontSize: '61px',
    fontFamily: 'Dreadful',
    w: '495px',
    textAlign: 'center',
    fontWeight: 'normal',
    lineHeight: '100%',
    letterSpacing: 'wide',
    textShadow: '0px 1px 24px rgba(108, 215, 1, 0.60)',
    textTransform: 'uppercase',
  },
  addToBetslipButton: {
    variant: 'solid',
    fontFamily: 'Fredoka',
    fontSize: 'sm',
  },
  loginToView: {
    variant: 'solid',
    fontFamily: 'Fredoka',
    fontSize: 'sm',
  },
  evenShotImage: {
    h: '140px',
    w: '150px',
    mt: '6',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  oddsValueSpan: {
    color: 'beta.300',
  },
  subHeadingText: {
    fontSize: 'sm',
  },
};
