import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  bg: 'epsilon.200',
  color: 'white',
  mt: '0',
  border: '3px solid',
  borderColor: 'epsilon.200',
  boxShadow: '0px 4px 0px #6E7373',
  borderRadius: 'md',
  mb: ['3', '0'],
};

export const BoxWrapper: CSSObject = {
  bg: 'epsilon.400',
};

export const ButtonViewMore: CSSObject = {
  bg: 'epsilon.500',
  color: 'beta.500',
  fontSize: 'sm',
  fontWeight: 'normal',
  borderTop: 'none',
  fontFamily: 'Caveman',

  _hover: {
    color: 'beta.100',
  },

  _active: {
    bg: 'epsilon.500',
  },
};
