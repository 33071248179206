import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FlexCTAContainer, TextCTAInfo, TextCTALink } from './styles/index';

type TCTAProps = {
  messageId: string | undefined;
  href: string | undefined;
  dataCy?: string | undefined;
};

const CTA: React.FC<TCTAProps> = ({ messageId, href, dataCy }) => {
  if (!messageId || !href) return <></>;

  return (
    <FlexCTAContainer>
      <TextCTAInfo>
        <FormattedMessage
          id={messageId}
          values={{
            link: (string: string) => (
              <Link to={href || '/'}>
                <TextCTALink as="span" data-cy={dataCy}>
                  {string}
                </TextCTALink>
              </Link>
            ),
          }}
        />
      </TextCTAInfo>
    </FlexCTAContainer>
  );
};

export default CTA;
