import React from 'react';
import { Box, BoxProps, CSSObject, StackProps } from '@chakra-ui/react';

export const SignUpDatePicker: CSSObject = {
  zIndex: 'popover',
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'var(--bc-gray-500, #718096)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
  mb: ['4', null, '5'],
  pt: [null, null, '1'],
  gap: '5px',
};

export const FormStack = (props: StackProps) => (
  <Box
    {...props}
    display={['unset', null, 'flex']}
    gap={['unset', null, '2']}
  />
);

export const SignUpInput: CSSObject = {
  borderRadius: '4px',
};

export const addressWrapperProps: BoxProps = {
  sx: {
    '& .chakra-form-control': {
      mb: '2',
    },
  },

  mb: '4',
};

export const SignupAcknowledgementButton: CSSObject = {
  color: 'alpha.600',
  fontWeight: 'bold',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  mb: 0,
};

export const BoxManualAddressContainer: CSSObject = {
  p: '4',
  pb: '0',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: '4px',
};

export const CheckboxHelpText: CSSObject = {
  color: 'gray.500',
};
