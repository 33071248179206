import { CSSObject } from '@chakra-ui/react';

export const BoxInner: CSSObject = {
  borderRadius: 'md',
  overflow: 'hidden',
  mt: '2',
};

export const LinkWrapper: CSSObject = {
  bg: 'blackAlpha.300',
  borderColor: 'inherit',
  color: 'white',
  fontWeight: 'bold',
  px: '2',
  py: '4',

  '&:hover': {
    bg: 'blackAlpha.400',
  },
};
