import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { keys } from '@/api/api.keys';
import {
  TQueryCompetitionsParams,
  TQueryCompetitionsResponse,
} from './competitions.types';
import { queryCompetitions } from './competitions';

export const useQueryCompetitions = (
  params?: TQueryCompetitionsParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<TQueryCompetitionsResponse>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery(
    [keys.sportCompetitions, params],
    () => queryCompetitions(params),
    options
  );
