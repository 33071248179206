import {
  chakra,
  ChakraComponent,
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
} from '@chakra-ui/react';
import { styleImports } from './PrimaryLayout.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';

const themeName = getThemeName();

export const GridLayout = chakra(Grid, {
  label: 'PrimaryGridLayout',
  baseStyle: () => ({
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    rowGap: '0',
    gridTemplateAreas: `
      "header"
      "main"
      "footer"
      "responsibleGamblingMessage"
    `,
    minH: '100vh',

    ...(() => {
      try {
        return styleImports[themeName]?.GridLayout;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * The logic for "is-sensitive" really should load a different
 * Layout rather than this one.
 */
export const GridSubLayout = chakra(Grid, {
  label: 'primaryLayout-PrimaryGridSubLayout',
  baseStyle: ({ theme }) => ({
    gridArea: 'main',

    gridTemplateColumns: ['0 1fr', null, null, 'auto 1fr', 'auto 1fr auto'],
    gridTemplateRows: '1fr',
    columnGap: ['0', null, null, '4'],
    gridTemplateAreas: [
      `"sidenav content"`,
      null,
      null,
      null,
      `"sidenav content betslip"`,
    ],

    ...(theme.themeName === EThemes.Betgalaxy && {
      'body[data-sensitive="true"] &': {
        gridTemplateColumns: ['0 1fr', null, null, 'auto 1fr', 'auto 1fr'],
        gridTemplateAreas: [
          `"sidenav content"`,
          null,
          null,
          null,
          `"sidenav content"`,
        ],
      },
    }),

    px: [null, null, null, '4'],
    minW: '0px',
    w: 'full',
    maxW: 'container.xl',
    justifySelf: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.GridSubLayout;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', GridProps>;

export const GridItemContent = chakra(GridItem, {
  label: 'primaryLayout-PrimaryGridItemContent',

  baseStyle: () => ({
    minW: '0px',
    ...(IS_MOBILE_APP && { pb: '150px' }),

    ...(() => {
      try {
        return styleImports[themeName]?.GridItemContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GridItemBetslip = chakra(GridItem, {
  label: 'PrimaryGridItemBetslip',
  baseStyle: () => ({
    minW: '0',
    zIndex: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.GridItemBetslip;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GridItemToggle = chakra(GridItem, {
  label: 'PrimaryGridItemToggle',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.GridItemToggle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GridItemSideNav = chakra(GridItem, {
  label: 'GridItemSideNav',

  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.GridItemSideNav;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GridItemFooter = chakra(GridItem, {
  label: 'primaryLayout-GridItemFooter',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.GridItemFooter;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GridItemResponsibleGambling = chakra(GridItem, {
  label: 'PrimaryGridItemResponsibleGambling',
  baseStyle: () => ({
    mt: '6',
    textAlign: 'center',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    zIndex: 'base',

    ...(() => {
      try {
        return styleImports[themeName]?.GridItemResponsibleGambling;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', GridItemProps>;

export const GridHeader = chakra(GridItem, {
  label: 'PrimaryGridHeader',
  baseStyle: ({ theme }) => ({
    position: 'sticky',
    top: '0px',
    zIndex: 'overlay',

    ...(theme.themeName === EThemes.Betgalaxy && {
      position: 'relative',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.GridHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
