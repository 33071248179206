import { CSSObject } from '@chakra-ui/react';
import horses from '@/assets/oldgill/images/horses.png';
import horsesDk from '@/assets/oldgill/images/horsesDk.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  flexWrapperCardHeaderProps: {},
  svgMoreRacesArrowProps: {
    boxSize: 6,
    filter: 'drop-shadow(2px 1px 1px rgb(0 0 0 / 0.6))',
  },
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bg: [
      `linear-gradient(180deg, rgba(34, 159, 255, 0.63) 0%, #138EEE 82.08%), url(${horses})`,
      `linear-gradient(180deg, rgba(34, 159, 255, 0.63) 0%, #138EEE 82.08%), url(${horsesDk})`,
    ],
    bgRepeat: 'no-repeat, no-repeat',
    bgSize: ['cover', 'contain'],
    bgPos: 'top',
    pos: 'absolute',
    h: '300px',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    boxShadow:
      '0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  },

  bg: 'linear-gradient(180deg, rgba(34, 159, 255, 0.63) 0%, #138EEE 52.08%, #0680DE 100%)',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  minH: '250px',
  py: '4',
  px: '3',
  mb: '4',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
  mb: ['0', null, '60px'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};

export const TextRunner: CSSObject = {
  color: 'alpha.600',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const HeadingSlogan: CSSObject = {
  color: 'beta.400',
  fontFamily: 'Bowlby One',
  fontSize: 'lg',
  textTransform: 'uppercase',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: 'center',
  gap: '2',
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'Bowlby One',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
  mt: '20',
  px: '0',
  svg: {
    color: 'beta.400',
    boxSize: '22px',
  },
};

export const TextCardHeader: CSSObject = {
  fontWeight: 'normal',
  lineHeight: 'normal',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'roboto',
  bg: 'alpha.200',
  borderRadius: 'sm',
  color: 'alpha.600',
  py: '0.5',
  px: '1',

  h: '18px',
  maxH: '18px',
  minH: '18px',
  lineHeight: '18px',
};

export const Button = (): CSSObject => ({
  fontSize: 'xs',
  color: 'beta.400',
  fontFamily: 'body',
  fontWeight: 'black',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
  borderRadius: 'md',
  minW: '50px',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',

  _focus: {
    bg: 'alpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  },

  '&[data-active]': {
    bg: 'alpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    w: '41px',
    h: '34px',
  },
});

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  borderRadius: 'base',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
};

export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextRunnerNumber: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  '&:nth-child(2)': {
    fontSize: '2xs',
    position: 'relative',
    fontWeight: 'medium',
    top: '-0.5',
  },
};
