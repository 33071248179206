import { ButtonProps, theme } from '@chakra-ui/react';

const primary = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'beta.500',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    borderRadius: 'md',
    m: '2px',
    boxShadow:
      '0px 0px 0px 1px rgba(92, 68, 19, 1) inset, 0px 1px 0px 1px rgba(255, 255, 255, 0.5) inset',
  },
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    color: 'beta.700',
    _after: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.400 100%)',
    },
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: '0px 0px 8px rgba(255, 210, 123, 0.5)',

    _after: {
      display: 'none',
    },
  },
  '&[data-active=false]': {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(180deg, alpha.200 0%, alpha.300 100%)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'alpha.700',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(180deg, alpha.200 0%, alpha.300 100%)',
    borderRadius: 'md',
    m: '2px',
    boxShadow:
      '0px 0px 0px 1px rgba(11, 59, 71, 1) inset, 0px 1px 0px 1px rgba(255, 255, 255, 0.5) inset',
  },
  _hover: {
    _after: {
      bgGradient: 'linear(180deg, alpha.200 0%, alpha.200 100%)',
    },
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: '0px 0px 8px  rgba(255, 210, 123, 0.5)',

    _after: {
      display: 'none',
    },
  },
  '&[data-active=false]': {
    bgGradient: 'linear(180deg, alpha.200 0%, alpha.300 100%)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'white',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: 'none',
  _hover: {
    border: '2px',
    borderColor: 'beta.300',
    color: 'beta.300',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: '0px 0px 8px rgba(255, 210, 123, 0.5)',
    border: 'none',
  },
  '&[data-active=false]': {
    bg: 'transparent',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    borderColor: 'alpha.100',
    color: 'alpha.100',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const primaryAccess = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'beta.500',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    borderRadius: 'md',
    m: '2px',
    boxShadow:
      '0px 0px 0px 1px rgba(92, 68, 19, 1) inset, 0px 1px 0px 1px rgba(255, 255, 255, 0.5) inset',
  },
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    color: 'beta.700',
    _after: {
      bgGradient: 'linear(180deg, alpha.200 0%, alpha.300 100%)',
      boxShadow:
        '0px 0px 0px 1px rgba(23, 62, 76, 1) inset, 0px 1px 0px 1px rgba(255, 255, 255, 0.5) inset',
    },
  },
  ':active, &[aria-expanded=true]': {
    bgGradient: 'linear(180deg, white 0%, white 100%)',
    boxShadow: 'none',
    color: 'alpha.600',
    textShadow: 'none',
    borderBottomRadius: 'none',
    _after: {
      display: 'none',
    },
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const bets = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'beta.500',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    borderRadius: 'md',
    m: '2px',
    boxShadow:
      '0px 0px 0px 1px rgba(92, 68, 19, 1) inset, 0px 1px 0px 1px rgba(255, 255, 255, 0.5) inset',
  },
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    color: 'beta.700',
    _after: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.400 100%)',
    },
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(180deg, alpha.700 0%, alpha.700 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.100',
    textShadow: '0px 0px 8px rgba(65, 255, 244, 0.5)',

    _after: {
      display: 'none',
    },
  },
  '&[data-active=false]': {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const odds = {
  borderRadius: 'base',
  border: 'none',
  bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  color: 'beta.500',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(180deg, beta.400 0%, beta.400 100%)',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(180deg, alpha.500 0%, alpha.700 100%)',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.35) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'beta.300',
    textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    color: 'gray.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    primaryAccess: () => ({
      ...primaryAccess,
    }),
    bets: () => ({
      ...bets,
    }),
    odds: () => ({
      ...odds,
    }),
    unstyled: () => ({}),
  },
  defaultProps: {},
};
