import { CSSObject } from '@chakra-ui/react';

export const GridLayout: CSSObject = {
  width: ['full', null, '760px'],
  minH: '100dvh',
  borderRadius: [null, null, 'lg'],
  display: 'flex',
  alignItems: [null, null, 'center'],
  mx: 'auto',
};
