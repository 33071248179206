import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import SportsState from './sports.types';
import {
  TSport,
  TMatch,
  TSportCompetitionView,
  TMarketGroup,
  TMarket,
  TProposition,
} from '../../../lib/DBModels';

const initialState: SportsState = {
  allSports: [],
  allSportsLoading: false,
  sportCompetitions: null,
  sportCompetitionsLoading: false,
  competitionMatches: [],
  competitionMatchesLoading: false,
  matchMarketGroups: [],
  matchMarketGroupsLoading: null,
  marketsByMarketGroup: [],
  marketsByMarketGroupLoading: false,
  propositionsByMarket: null,
  propositionsByMarketLoading: false,
  propositionsByMarketPolling: false,
};

const sportsSlice = createSlice({
  name: 'sports',
  initialState,
  reducers: {
    setAllSports: (state: SportsState, action: PayloadAction<TSport[]>) => ({
      ...state,
      allSports: action.payload,
    }),
    setSportCompetitions: (
      state: SportsState,
      action: PayloadAction<TSportCompetitionView>
    ) => ({ ...state, sportCompetitions: action.payload }),
    setCompetitionMatches: (
      state: SportsState,
      action: PayloadAction<TMatch[]>
    ) => ({ ...state, competitionMatches: action.payload }),
    setMatchMarketGroups: (
      state: SportsState,
      action: PayloadAction<TMarketGroup[]>
    ) => ({ ...state, matchMarketGroups: action.payload }),
    setMarketsByMarketGroup: (
      state: SportsState,
      action: PayloadAction<TMarket[]>
    ) => ({ ...state, marketsByMarketGroup: action.payload }),
    setPropositionsByMarket: (
      state: SportsState,
      action: PayloadAction<{
        marketId: string;
        propositions: TProposition[];
      }>
    ) => ({
      ...state,
      propositionsByMarket: {
        ...state.propositionsByMarket,
        [action.payload.marketId]: action.payload.propositions,
      },
    }),
    removePropositionsByMarket: (
      state: SportsState,
      action: PayloadAction<string>
    ) => {
      const newPropositions = {
        ...state.propositionsByMarket,
      };
      delete newPropositions[action.payload];

      return { ...state, propositionsByMarket: newPropositions };
    },
    clearAllSports: (state: SportsState) => ({ ...state, allSports: [] }),
    clearAllSportCompetitions: (state: SportsState) => ({
      ...state,
      sportCompetitions: null,
    }),
    clearAllCompetitionMatches: (state: SportsState) => ({
      ...state,
      competitionMatches: [],
    }),
  },
  extraReducers: {
    // All Sports
    'sports/getAllSports/pending': (state: SportsState) => ({
      ...state,
      allSportsLoading: true,
    }),
    'sports/getAllSports/fulfilled': (state: SportsState) => ({
      ...state,
      allSportsLoading: false,
    }),
    'sports/getAllSports/rejected': (state: SportsState) => ({
      ...state,
      allSportsLoading: false,
    }),
    // Competitions by Sport
    'sports/getCompetitionsBySport/pending': (state: SportsState) => ({
      ...state,
      sportCompetitionsLoading: true,
    }),
    'sports/getCompetitionsBySport/fulfilled': (state: SportsState) => ({
      ...state,
      sportCompetitionsLoading: false,
    }),
    'sports/getCompetitionsBySport/rejected': (state: SportsState) => ({
      ...state,
      sportCompetitionsLoading: false,
    }),
    // Matches by competition & tournament
    'sports/getMatchesByCompetition/pending': (state: SportsState) => ({
      ...state,
      competitionMatchesLoading: true,
    }),
    'sports/getMatchesByCompetition/fulfilled': (state: SportsState) => ({
      ...state,
      competitionMatchesLoading: false,
    }),
    'sports/getMatchesByCompetition/rejected': (state: SportsState) => ({
      ...state,
      competitionMatchesLoading: false,
    }),

    // Market groups
    'sports/getMarketGroups/pending': (state: SportsState) => ({
      ...state,
      matchMarketGroupsLoading: null,
    }),
    'sports/getMarketGroups/fulfilled': (state: SportsState) => ({
      ...state,
      matchMarketGroupsLoading: false,
    }),
    'sports/getMarketGroups/rejected': (state: SportsState) => ({
      ...state,
      matchMarketGroupsLoading: false,
    }),

    // Markets by market group
    'sports/getMarketsByMarketGroup/pending': (state: SportsState) => ({
      ...state,
      marketsByMarketGroupLoading: true,
    }),
    'sports/getMarketsByMarketGroup/fulfilled': (state: SportsState) => ({
      ...state,
      marketsByMarketGroupLoading: false,
    }),
    'sports/getMarketsByMarketGroup/rejected': (state: SportsState) => ({
      ...state,
      marketsByMarketGroupLoading: false,
    }),

    // Propositions by market
    'sports/getPropositionsByMarket/pending': (state: SportsState) => ({
      ...state,
      propositionsByMarketLoading: true,
    }),
    'sports/getPropositionsByMarket/fulfilled': (state: SportsState) => ({
      ...state,
      propositionsByMarketLoading: false,
    }),
    'sports/getPropositionsByMarket/rejected': (state: SportsState) => ({
      ...state,
      propositionsByMarketLoading: false,
    }),

    // Propositions by market polling state
    'sports/getPropositionsByMarketPolling/pending': (state: SportsState) => ({
      ...state,
      propositionsByMarketPolling: true,
    }),
    'sports/getPropositionsByMarketPolling/fulfilled': (
      state: SportsState
    ) => ({
      ...state,
      propositionsByMarketPolling: false,
    }),
    'sports/getPropositionsByMarketPolling/rejected': (state: SportsState) => ({
      ...state,
      propositionsByMarketPolling: false,
    }),
  },
});

// Slice actions
export const {
  setAllSports,
  clearAllSports,
  setSportCompetitions,
  clearAllSportCompetitions,
  setCompetitionMatches,
  clearAllCompetitionMatches,
  setMatchMarketGroups,
  setMarketsByMarketGroup,
  setPropositionsByMarket,
  removePropositionsByMarket,
} = sportsSlice.actions;

// Slide reducer
export default sportsSlice.reducer;
