import { CSSObject } from '@chakra-ui/react';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'gray.600' },
  flexWrapper: {},
  checkbox: { sx: { '> span': { borderColor: 'gray.600' } } },
};

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  bg: 'gray.200',
  borderRadius: 'md',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  _active: {
    color: 'white',
    bg: 'alpha.600',
    boxShadow:
      '0px 1px 0px rgba(255, 255, 255, 0.45), inset 0px 0px 5px rgba(0, 0, 0, 0.45)',
  },

  color: 'gray.600',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'md',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: 'transparent',
  boxShadow: 'none',

  _hover: {
    bg: 'alpha.700',
    color: 'white',
  },
};
