import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  py: '2',
  px: '4',
};

export const BoxWrapper: CSSObject = {
  borderColor: 'transparent',
};

export const LinkItem = (isFeatured: boolean) => ({
  p: '2',
  py: '3',
  bg: isFeatured ? 'beta.500' : 'alpha.500',
  color: 'white',
  transition: 'all, .3s ease-in-out',
  borderBottom: '1px solid',
  borderColor: 'blackAlpha.300',

  _hover: {
    bg: 'alpha.600',
  },

  '&+&': {
    borderTop: '1px',
    borderTopColor: isFeatured ? 'whiteAlpha.100' : 'gray.200',
  },

  svg: {
    display: 'none',
  },
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};
