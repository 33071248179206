import React, { FC } from 'react';

import { Row, Column } from './Styles';

import { GridProps } from './types';

const Grid: FC<GridProps> = ({
  column,
  row,
  lg,
  md,
  sm,
  children,
  ...rest
}) => {
  const isRow = row || !column;

  return isRow ? (
    <Row {...rest}>{children}</Row>
  ) : (
    <Column sm={sm} md={md} lg={lg} {...rest}>
      {children}
    </Column>
  );
};

export default Grid;
