import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  borderTop: '1px',
  borderTopColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20) inset',
  h: ['unset', null, '46px'],
};

export const TextListName: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
  fontSize: ['11px', null, 'smxs'],
};

export const TextSpanName = (): CSSObject => ({
  '&[data-isVS="true"]': {
    color: 'beta.500',
  },
});

export const FlexSubTextWrapper: CSSObject = {
  fontSize: ['9px', null, '11px'],
  fontWeight: 'semibold',
  color: 'white',
  mb: '1',

  '& > svg': { color: 'beta.500' },

  p: { _last: { whiteSpace: 'nowrap' } },
};

export const IconSport: CSSObject = {
  alignSelf: ['flex-start', null, 'unset'],
  p: [0],
  borderRadius: 'none',
  boxShadow: ['none'],
  boxSize: '6',
  color: 'beta.500',
  bg: 'none',
};

export const IconChevron: CSSObject = {};

export const SubTextIcon: CSSObject = {};

export const FlexEndWrapper: CSSObject = {
  '.timestamp': { color: 'white' },
};

export const FlexCardInfoWrapper: CSSObject = {};

export const TextCardInfoBadge: CSSObject = {};
