import {
  Drawer,
  DrawerOverlay,
  Show,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { GridItemBetslip, ModalDrawerContent } from './styles/Betslip.styles';
import ModalWeb from './components/Modal/ModalWeb';
import { useRemoveRestrictedBets } from './Services/Betslip.hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  setBetSlipOpen,
  setBonusChanceVisibility,
} from '@/redux/Betslip.slices';

export default function Betslip() {
  const dispatch = useAppDispatch();
  const { betSlipOpen, bonusChance } = useAppSelector((state) => state.betSlip);

  const size = useBreakpointValue({
    base: 'full',
    xs: 'xs',
    sm: 'xs',
    md: 'xs',
    lg: 'xs',
  });
  useRemoveRestrictedBets();

  return (
    <>
      <Show below="xl">
        <Drawer
          isOpen={betSlipOpen}
          placement="right"
          onClose={() => {
            if (bonusChance?.isVisible === false) {
              dispatch(setBonusChanceVisibility(true));
            }

            dispatch(setBetSlipOpen(false));
          }}
          size={size}
        >
          <DrawerOverlay />

          <ModalDrawerContent>
            <ModalWeb />
          </ModalDrawerContent>
        </Drawer>
      </Show>
      <Show above="xl">
        <GridItemBetslip
          gridArea="betslip"
          sx={{
            'body[data-sensitive="true"] &': {
              display: 'none',
            },
          }}
        >
          <ModalWeb />
        </GridItemBetslip>
      </Show>
    </>
  );
}
