import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  bgGradient: 'linear(to-b, delta.100, delta.200)',
  borderRadius: 'md',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  variant: 'unstyled',
  borderRadius: 'md',
  color: 'delta.700',
  textShadow: 'none',
  bgImage: 'none',
  boxShadow: 'none',
  bg: 'transparent',
  cursor: 'pointer',
  sx: {
    '&[aria-selected="true"]': {
      color: 'beta.300',
      bg: 'blackAlpha.600',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.83) inset',
      px: '2',
      _hover: {
        bg: 'blackAlpha.600',
        color: 'white',
      },
    },

    '&&': {
      px: '2',
      py: '1',
      borderRadius: 'md',
    },
  },

  h: '5',
  fontWeight: 'bold',
  fontSize: '2xs',
  ml: '1',
  px: '2',
  py: '1.5',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',

  border: 'none',
  fontFamily: 'Roboto',
};
