import React from 'react';
import { Box, Link, OrderedList } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import { getThemeName } from '@/helpers/getThemeName';
import {
  BoxTakeABreakWrapper,
  HeadingInformation,
  InformationWrapper,
  ListItemExclusionItem,
  TextInformation,
} from '../../TakeABreak/styles/TakeABreak.styles';
import { Button } from '@/components/Button/Button';
import { buttonLifeTimeExclusionProps } from '../styles/LifetimeExclusion.styles';

export default function Information() {
  const [
    {
      Account: {
        LifetimeExclusion: {
          continueButton,
          information,
          informationShortBreak,
          informationLongBreak,
          informationText2,
          informationText3,
          informationText4,
          importantDetailsTitle,
          importantDetailsBalanceTitle,
          importantDetailsBalanceDesc,
          importantDetailsAlertsTitle,
          importantDetailsAlertsDesc,
        },
      },
    },
  ] = getStrings();

  const navigate = useNavigate();

  /**
   * TODO: maybe this should be a hook?
   * Not sure about performance
   */
  return (
    <InformationWrapper maxW="container.xs">
      <BoxTakeABreakWrapper>
        <TextInformation>
          {information} {informationShortBreak} {informationText2}{' '}
          {informationLongBreak}
        </TextInformation>
        <TextInformation>
          {informationText3.replace(/{themeName}/g, getThemeName())}
        </TextInformation>
        <TextInformation>
          {informationText4.replace(/{themeName}/g, getThemeName())}
        </TextInformation>
      </BoxTakeABreakWrapper>
      <Box mb="4">
        <HeadingInformation>{importantDetailsTitle}</HeadingInformation>
        <OrderedList>
          <ListItemExclusionItem>
            <TextInformation sx={{ fontWeight: 'bold', mb: '0' }}>
              {importantDetailsBalanceTitle.replace(
                /{themeName}/g,
                getThemeName()
              )}
            </TextInformation>
            <TextInformation>
              {importantDetailsBalanceDesc.replace(
                /{themeName}/g,
                getThemeName()
              )}
            </TextInformation>
          </ListItemExclusionItem>
          <ListItemExclusionItem>
            <TextInformation sx={{ fontWeight: 'bold', mb: '0' }}>
              {importantDetailsAlertsTitle}
            </TextInformation>
            <TextInformation>
              {importantDetailsAlertsDesc.replace(
                /{themeName}/g,
                getThemeName()
              )}
            </TextInformation>
          </ListItemExclusionItem>

          <ListItemExclusionItem>
            <TextInformation sx={{ fontWeight: 'bold', mb: '0' }}>
              <FormattedMessage id="account.betStop.title" />
            </TextInformation>
            <TextInformation>
              <FormattedMessage
                id="account.betStop.description"
                values={{
                  cta: (string: string) => (
                    <Link
                      href="https://www.acma.gov.au/betstop-national-self-exclusion-registertm"
                      target="_blank"
                      display="inline-block"
                      textDecoration="underline"
                    >
                      {string}
                    </Link>
                  ),
                }}
              />
            </TextInformation>
          </ListItemExclusionItem>
        </OrderedList>
      </Box>
      <Button
        {...buttonLifeTimeExclusionProps}
        type="button"
        data-cy="submitLifetimeExclusion"
        onClick={() =>
          navigate(
            `/account/settings/responsible-gambling/lifetime-exclusion/form`
          )
        }
        isFullWidth
      >
        {continueButton}
      </Button>
    </InformationWrapper>
  );
}
