import * as betprofessor from './Modal.styles.betprofessor';
import * as betroyale from './Modal.styles.betroyale';
import * as puntcity from './Modal.styles.puntcity';
import * as sterlingparker from './Modal.styles.sterlingparker';
import * as vikingbet from './Modal.styles.vikingbet';
import * as volcanobet from './Modal.styles.volcanobet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  puntcity,
  sterlingparker,
  vikingbet,
  volcanobet,
};
