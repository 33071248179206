import api from '../../api';
import { TMutateSgmPricingBody, TMutateSgmPricingResponse } from './sgm.types';

export const sgmPricingEndpoint = '/punter/pricing/sgm';

export async function mutateSgmPricing(body: TMutateSgmPricingBody) {
  const res = await api.post<TMutateSgmPricingResponse>(
    sgmPricingEndpoint,
    body
  );
  return res;
}
