import slamBorder from '@/assets/slambet/images/backgrounds/gradientBorderSmall.svg';

export const FlexWrapper = {
  borderRadius: 'md',
  alignItems: 'center',
  justifyContent: 'center',
  mx: ['1.5', null, 'unset'],
  mb: ['1', null, '3'],
  px: '2px',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  bgGradient: 'linear(to-b, beta.700, beta.800)',
  h: '16',
  pos: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '2px solid transparent',
    borderImage: `url(${slamBorder}) 30`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'heading',
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 12px rgba(255, 164, 28, 0.6)'
    : '0px 2px 8px rgba(0, 0, 0, 0.35)',
  color: isActive ? 'alpha.400' : 'white',
  letterSpacing: 'widest',
  px: '3',
});

export const ButtonToggle = (isActive: boolean, category: string) => ({
  fontFamily: 'heading',
  bg: isActive ? 'transparent' : 'beta.500',
  boxShadow: isActive
    ? '0px 0px 10px 0px rgba(0, 0, 0, 0.50) inset'
    : '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px #4C7493 inset',
  fontWeight: 'normal',
  fontSize: 'md',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 8px rgba(255, 164, 28, 0.6)'
    : '0px 2px 8px rgba(0, 0, 0, 0.35)',
  color: isActive ? 'alpha.400' : 'white',
  letterSpacing: 'wide',
  px: '3',
  w: '100%',
  h: '60px',
  _hover: {
    bg: isActive ? 'transparent' : 'beta.500',
    boxShadow: isActive
      ? 'none'
      : '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px #4C7493 inset',
  },
  ...(category === 'racing' && {
    borderRightRadius: '0',
  }),
  ...(category === 'sport' && {
    borderLeftRadius: '0',
  }),
});
