import { CustomButtonProps } from '@/components/Button/Button';

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  height: '38px',
  variant: 'secondary',
  lineHeight: 'normal',
};

export const buttonSubmitExclusionProps: CustomButtonProps = {
  height: '38px',
  width: '100%',
  variant: 'solid',
  color: 'blackAlpha.700',
};
