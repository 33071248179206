/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Text } from '@chakra-ui/react';

import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/Button/Button';
import { TTemplate } from '@/helpers/createTemplate';
import {
  WithdrawalCardContent,
  WithdrawalCardDetail,
  CardTitleWithdrawal,
  WithdrawalFlexContainer,
  WithdrawalStatus,
  WithdrawalWrapper,
} from '../styles/RequestedWithdrawalCard.styles';
import { centsToDollars } from '@/helpers/utils';
import { EWithdrawalStatus, TWithdrawal } from '../../../../../lib/DBModels';

/**
 * FIXME: This process for creating templates is outdated. We want to move any logic into reusable components.
 */
const template = (
  withdrawalRequest: TWithdrawal,
  handleCancelWithdrawal: (id: string | undefined) => void
): TTemplate => ({
  wrapper: <WithdrawalWrapper flexDir="column" />,
  content: (
    <WithdrawalCardContent>
      <CardTitleWithdrawal>
        <FormattedMessage id="account.withdraw.request.heading" />
      </CardTitleWithdrawal>
      <WithdrawalCardDetail>
        <Text fontSize="2xs">
          {dayjs(withdrawalRequest?.date_requested).format('DD/MM/YYYY')}
          {' | '}
          {dayjs(withdrawalRequest?.date_requested).format('h:mm:ss A')}
        </Text>
      </WithdrawalCardDetail>
      <WithdrawalCardDetail>
        <Text>
          <FormattedMessage id="account.withdraw.requests.status.withdraw" />
        </Text>
        <WithdrawalStatus
          status={withdrawalRequest?.withdrawal_status ?? undefined}
        >
          {withdrawalRequest?.withdrawal_status ?? ''}
        </WithdrawalStatus>
      </WithdrawalCardDetail>
      <WithdrawalCardDetail>
        <Text>{withdrawalRequest?.bank_nickname}</Text>
        <Text>{`-${centsToDollars(withdrawalRequest?.amount_requested)}`}</Text>
      </WithdrawalCardDetail>
    </WithdrawalCardContent>
  ),
  cancelButton: (
    <WithdrawalFlexContainer>
      {withdrawalRequest?.withdrawal_status === EWithdrawalStatus.Pending &&
        !withdrawalRequest.withdrawal_cancel_disabled && (
          <Button
            p="5"
            fontSize="xs"
            w="full"
            onClick={() =>
              handleCancelWithdrawal(withdrawalRequest?.withdrawal_id)
            }
          >
            <FormattedMessage id="account.withdraw.requests.cancelbutton" />
          </Button>
        )}
    </WithdrawalFlexContainer>
  ),
});

export default template;
