import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bg: 'white',
  '&&': { px: ['3', '4'], pb: ['1', null, '2'] },
  mt: ['unset', '2'],
  borderTopRadius: 'md',
  overflow: 'visible',
  color: 'beta.600',
  pb: '0',
  '> p': {
    fontSize: 'md',
    fontWeight: 'medium',
    color: 'beta.600',
    pb: '1',
  },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'extrabold',
  fontSize: 'md',
  textTransform: 'uppercase',
  color: 'beta.500',
  fontFamily: 'body',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  p: '3',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  color: 'beta.500',
  m: '2',
  minHeight: '0',
  borderRadius: [null, null, null, 'base'],
};

export const WrapperContainer: CSSObject = {
  mt: '0',
  mb: '3',
  px: ['2.5', '0'],
  bg: 'white',
  borderBottomRadius: 'base',
};
