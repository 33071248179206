import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { TPunterAccountOverview } from '../DBModels';
import { getStrings } from '@/helpers/utils';
import { EPunterStatus } from './types';

const [
  {
    Onboarding: { FirebaseErrorMessages, Login },
  },
] = getStrings();

/*  
    handlePunterStatus
    This method inspects the punter status and throws a error if it is not normal
    Used on both login to stop a login via error & on logged in page load to stop
    a already authed user continuing
 */
export const handlePunterStatus = (accountOverview: TPunterAccountOverview) => {
  const { status } = accountOverview;

  if (status === EPunterStatus.Normal) return; // If normal - do not do anything

  if (status === EPunterStatus.Suspended_KYC) return; // If KYC - do not do anything

  if (status === EPunterStatus.Closed_Customer_Requested) {
    toast.error(Login.Error_Closed_Account);
  } else if (status === EPunterStatus.Permanent_Self_Exclusion) {
    toast.error(Login.Error_Permanent_Exclusion);
  } else if (status === EPunterStatus.Temporary_Self_Exclusion) {
    const lockedUntil = dayjs(accountOverview.self_exclusion_expiry);

    const toastMessage = Login.Error_Temporary_Exclusion.replace(
      'DATE',
      lockedUntil.format('DD/MM/YY hh:mm A')
    );

    toast.error(toastMessage);
  } else {
    // Fall back error
    toast.error(FirebaseErrorMessages.UserNotFound);
  }

  throw new Error(`Punter status is not normal: ${status}`);
};
