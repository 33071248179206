import { chakra } from '@chakra-ui/react';
import { styleImports } from './Aside.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const WrapperAside = chakra('div', {
  label: 'aside-WrapperAside',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.WrapperAside;
      } catch (err) {
        return {};
      }
    })(),
  },
});
