import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
    mx: '0.5',
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
      pb: '0',
    },
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset`,
    minW: '90px',
    px: '2',
  },
  btnGroupText: {
    fontSize: 'sm',
    color: 'gamma.600',
    fontWeight: 'normal',
    fontFamily: 'accentStaat',
    textShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    h: 'full',
    lineHeight: '2.8',
    bg: 'alpha.700',
    px: '2',
  },
  btnGroupIcon: {
    color: 'alpha.600',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.6))',
  },
  name: {
    color: 'gamma.200',
  },
};
export default styles;
