import { Box, VisuallyHidden } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import promoBanner from '@/assets/betprofessor/images/banner/Promo.png';
import promoMobileBanner from '@/assets/betprofessor/images/banner/PromoMobile.png';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        order: 0,
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <Box
            key="banner-image"
            sx={{
              label: 'BetProfessorBannerImage',
              w: '101dvw',
              h: ['398px', '56'],
              bgImage: [promoMobileBanner, promoBanner],
              bgSize: 'contain',
              bgPosition: ['center bottom', 'center'],
              bgRepeat: 'no-repeat',
              borderBottom: '4px',
              borderColor: ['transparent', null],
            }}
          >
            <VisuallyHidden>
              <FormattedMessage id="home.carousel.accessbility" />
            </VisuallyHidden>
          </Box>,
        ]}
      />
    </Box>
  );
}
