import { rest } from 'msw';
import { TPunterStatement } from './statements.types';
import { MOCK_STATEMENTS } from './statements.mocks';
import { punterStatements } from './statements';

export const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;
export const statementsHandler = [
  rest.get<TPunterStatement[]>(
    `${baseURL}${punterStatements}`,
    (req, res, ctx) => res(ctx.json(MOCK_STATEMENTS))
  ),
];
