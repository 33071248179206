import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  borderRadius: 'md',
};
