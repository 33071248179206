export const FormLabel = {
  baseStyle: {
    mb: '1.5',
    fontSize: 'xs',
    color: 'gray.500',
    fontWeight: '600',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
