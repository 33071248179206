import { CSSObject } from '@chakra-ui/react';
import activeBg from '../../../../../assets/vikingbet/images/backgrounds/btn-active-bg.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: '#005773',
  fontSize: 'sm',
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'SkranjiBold',
  fontWeight: '700',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  border: 'var(--bc-colors-delta-700) 2px solid',
  textTransform: 'uppercase',
  textShadow: '0px 1px 2px rgba(255, 255, 255, 0.40)',
  minW: '14',
  w: '14',
  h: '9',
  sx: {
    '&&': {
      '&:active, &[data-active="true"]': {
        bgImg: `url(${activeBg})`,
        color: 'delta.300',
        bgSize: '100% 100%',
        border: 'none',
        boxShadow: '0px 0px 0px 3px rgba(0, 0, 0, 0.45) inset',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          color: 'white',
        },
      },
      p: '2',
    },
    '&:hover': {
      color: '#005773',
      bgGradient: 'linear(to-b, delta.600, delta.800)',
    },
    '&:disabled': {
      border: 'none',
      borderRadius: 'base',
      '&&': {
        opacity: '1',
        boxShadow: 'none',
        color: 'blackAlpha.400',
        bg: 'blackAlpha.300',
        fontSize: 'xs',
      },
      pointerEvents: 'none',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0',
  width: 'calc(100% + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  background: 'alpha.800',
  borderTop: '1px solid rgba(255, 255, 255, 0.10)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
  bg: 'alpha.700',
};

export const favouriteIconProps: CSSObject = {
  color: 'beta.400',
  boxSize: '2',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'beta.400',
  textShadow: 'none',
};
