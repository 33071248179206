import { CSSObject } from '@chakra-ui/react';

export const TransactionsContainer: CSSObject = {
  '.chakra-text': {
    color: 'gamma.200',
  },
  'div[data-cy="activityStatements"]': {
    'p:nth-of-type(1)': {
      mb: '14px',
      fontWeight: 'semibold',
      fontSize: 'sm',
    },
    'p:nth-of-type(2)': {
      fontWeight: 'semibold',
      fontSize: 'xs',
    },
    'p:nth-of-type(3)': {
      fontWeight: 'normal',
      fontSize: 'xs',
    },
  },
};
