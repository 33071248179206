import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  alignItems: 'center',
  px: '3',
  borderBottom: '4px',
  borderBottomColor: 'beta.600',
  h: '20',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
};
