import { CSSObject } from '@chakra-ui/react';

export const ButtonShowMore: CSSObject = {
  fontSize: 'xs',
  py: '1',
  px: '4',
  borderRadius: 'md',
  boxShadow: 'lg',
  bg: 'alpha.600',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

  _hover: {
    bg: 'alpha.800',
  },
};
