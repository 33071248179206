import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    position: [null, null, null, 'relative'],
    borderRadius: '10px',
    borderWidth: '1px',
    background: 'transparent',
    mx: ['2', null, '0', null],
    borderColor: '#5c6d8e',
  },
  boxWrapperProps: {
    position: [null, null, null, 'relative'],
  },
  boxNavigationWrapperProps: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4',
    width: '100%',
  },
  navigationBoxProps: {
    boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.25)',
    borderTop: 'none',
    m: '0',
    bgImage: 'transparent',

    _first: {
      m: '0',
    },
  },
};
