import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'beta.800',
  fontSize: 'md',
  fontWeight: 'semibold',
  mb: '2',
};
export const Subheading: CSSObject = {
  color: 'beta.800',
  fontWeight: 'normal',
  '>span': {
    color: 'beta.800',
  },
};
export const DividerWithdrawal: CSSObject = {
  display: 'none',
};
export const TextDescription: CSSObject = {
  color: 'beta.800',
  fontSize: 'xs',
  pb: '3',
  mb: '0',
  borderBottom: '1.5px dashed',
  borderBottomColor: 'whiteAlpha.500',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1.5px',
    borderColor: 'blackAlpha.200',
    borderStyle: 'dashed',
  },
};
export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'odds',
  fontWeight: 'normal',
  fontFamily: 'accent',
  fontSize: 'sm',
  filter: 'box-shadow(0px 4px 4px 0px var(--bc-colors-blackAlpha-50))',
  h: '8',
};
export const ButtonWithdrawal: CSSObject = {
  variant: 'secondary',
  fontWeight: 'normal',
  fontFamily: 'accent',
  mt: '1',
};
export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'beta.800',
  },
  '.chakra-form__helper-text': {
    color: 'beta.800',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    bg: 'gray.50',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'gray.600',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
    _placeholder: {
      fontWeight: 'bold',
      color: 'gray.600',
    },
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    boxShadow:
      '0px 2px 4px 0px var(--bc-colors-blackAlpha-50), 0px 1px 0px 0px var(--bc-colors-whiteAlpha-50) inset',
    color: 'white',
    minH: '5',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    p: '1',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const AmountInputWrapperWithdrawal: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'beta.800',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    borderStyle: 'solid',
    borderColor: 'beta.500',
    _hover: {
      borderColor: 'beta.500',
    },
  },
  '.chakra-input__left-addon': {
    fontWeight: 'bold',
    fontSize: 'sm',
    color: 'beta.900',
    bg: 'beta.400',
    border: 'none',
    borderLeftTopRadius: 'lg',
    borderLeftBottomRadius: 'lg',
  },
  '.chakra-input': {
    my: '.5',
    fontSize: 'sm',
    fontWeight: 'bold',
    borderRightRadius: 'md',
    border: '2px solid',
    borderColor: 'beta.400',
    borderLeft: 'none',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    bg: 'beta.900',
    color: 'white',
    _hover: {
      bg: 'beta.700',
      borderColor: 'beta.400',
    },
    _focus: {
      bg: 'beta.700',
      boxShadow: 'none',
      borderColor: 'beta.400',
      color: 'white',
    },
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    boxShadow:
      '0px 2px 4px 0px var(--bc-colors-blackAlpha-400), 0px 1px 0px 0px var(--bc-colors-whiteAlpha-400) inset',
    color: 'white',
    minH: '3',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    p: '1',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  pr: '2',
  pl: '0',
  borderBottomColor: 'whiteAlpha.500',
  borderBottom: '1.5px',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1.5px',
    borderColor: 'blackAlpha.200',
    borderStyle: 'dashed',
  },
};
export const BankRadio: CSSObject = {
  mb: '3',
  '&[data-checked]': {
    boxShadow: '0px 0px 0px 1px var(--bc-colors-beta-800)',
    bg: 'beta.800',
    border: '2px solid',
    borderColor: 'white',
    _hover: {
      bg: 'beta.800',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};
export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
  color: 'beta.800',
  gap: '5',
};
export const TextBankAccountDetail: CSSObject = {
  color: 'beta.800',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

export const ListContainer: CSSObject = {
  bgColor: 'transparent',
  boxShadow: 'none',
  mt: '0',
  p: '0',
  w: 'full',
};
export const ListContainerHeading: CSSObject = {
  color: 'beta.800',
  fontFamily: 'accent',
  fontSize: 'lg',
  mt: '3',
  '&&': {
    fontWeight: 'normal',
  },
};

export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
  color: 'beta.800',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'beta.800',
  fontWeight: 'bold',
};
export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  py: '0.5',
  px: '1',
  display: 'flex',
  justidyContent: 'center',
  alignItems: 'center',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'beta.200',
  color: status === EWithdrawalStatus.Pending && 'beta.900',
  textTransform: 'uppercase',
  fontSize: '2xs',
});
export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: '0',
};
export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '1',
  variant: 'odds',
  fontWeight: 'normal',
};
export const ButtonWithdrawalCardCancel: CSSObject = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'beta.800',
  color: 'beta.800',
  fontFamily: 'accent',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: 'none',
  _hover: {
    borderColor: 'beta.500',
    color: 'beta.500',
  },
  _active: {
    boxShadow: ' 0px 0px 4px 4px var(--bc-colors-blackAlpha-400) inset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      opacity: 0.4,
      borderColor: 'beta.800',
      color: 'beta.800',
    },
  },
};
export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};
export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  borderRadius: 'lg',
  p: '3',
  bgGradient: 'linear(to-b, beta.100, beta.100)',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 5px var(--bc-colors-beta-200) inset',
};
export const WithdrawalCardContent: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'semibold',
};
export const WithdrawalCardDetailRequested: CSSObject = {
  color: 'beta.800',
  borderBottom: '1.5px dashed',
  borderBottomColor: 'whiteAlpha.500',
  position: 'relative',
  '&> p': {
    my: 'auto',
    _first: {
      fontSize: 'sm',
      textTransform: 'capitalize',
    },
  },
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1.5px',
    borderColor: 'blackAlpha.200',
    borderStyle: 'dashed',
  },
};
export const BackButton: CSSObject = {
  display: 'none',
};

export const InputWithdrawal: CSSObject = {
  '&[aria-invalid="true"]': {
    borderColor: 'gray.300',
    boxShadow:
      '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset, 0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
  },
};
