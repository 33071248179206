export const colors = {
  alpha: {
    100: '#51BBFF',
    200: '#28ABFF',
    300: '#0B95EE',
    400: '#FF9F1C',
    500: '#075FAF',
    600: '#05357C',
    700: '#002B6A',
    800: '#002152',
    900: '#001635',
  },
  beta: {
    100: '#FFF8E5',
    200: '#EAD59E',
    300: '#C8A257',
    400: '#FED25D',
    500: '#FFC62A',
    600: '#CE9701',
    700: '#895C01',
    800: '#654301',
    900: '#392805',
  },
  delta: {
    100: '#9CFF79',
    200: '#71F343',
    300: '#5ED035',
    400: '#4EAD2C',
    500: '#3B971A',
    600: '#29770D',
    700: '#1B5706',
    800: '#144203',
    900: '#0C2A02',
  },
};
