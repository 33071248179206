import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { logError } from '@/helpers/utils';
import PrimaryWrapper from '../../../wrappers/PrimaryWrapper';
import { Editor, FlexPageWrapper } from '../Promos/styles/Promos.styles';
import { keys } from '@/api/api.keys';
import { queryPrivacy } from '@/api/punter/general/privacy';

export default function Privacy() {
  const intl = useIntl();

  const { data } = useQuery([keys.privacy], queryPrivacy, {
    staleTime: 60 * 5000, // 5 minutes
    onError(err) {
      logError(err);
    },
  });

  return (
    <PrimaryWrapper
      pageTitle={intl.formatMessage({
        id: 'account.privacy.pageTitle',
      })}
      pageHeader={intl.formatMessage({
        id: 'account.privacy.pageTitle',
      })}
    >
      <FlexPageWrapper mt="4">
        <Editor readOnly value={data?.privacy_policy} />
      </FlexPageWrapper>
    </PrimaryWrapper>
  );
}
