import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  fontSize: 'xs',
  '.chakra-checkbox__label': {
    color: 'gamma.200',
  },
};

export const TextS: CSSObject = {
  fontSize: 'xs',
};
