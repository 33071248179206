import { CSSObject } from '@chakra-ui/react';

export const ExoticsWrapper: CSSObject = {
  px: '2',
  pb: '2',
};

export const ExoticsContainer: CSSObject = {
  p: '2',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'beta.500',
  bg: 'white',
  borderRadius: 'md',
  'div:first-child div label': {
    bg: 'unset',
  },
};

export const CheckboxBoxed: CSSObject = {
  color: 'gray.700',
  right: 0,
  justifyContent: 'flex-end',
  mr: '3',
  borderColor: 'gray.300',
  '.chakra-checkbox__control': {
    bg: 'white',
    '&[data-checked]': {
      color: 'gray.700',
      borderColor: 'gray.300',
      borderWidth: '1px',
      bg: 'white',
      _hover: {
        bg: 'gray.50',
      },
    },
    _hover: {
      bg: 'gray.50',
    },
  },
};

export const TextBoxed: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const SelectFieldContainer: CSSObject = {
  gap: ['1', null, '2'],
  width: ['200px', null, '150px'],
};
