// Only NT Themes
import * as puntcity from './PromotionPreferences.styles.puntcity';
import * as vikingbet from './PromotionPreferences.styles.vikingbet';
import * as gigabet from './PromotionPreferences.styles.gigabet';
import * as slambet from './PromotionPreferences.styles.slambet';
import * as goldenbet888 from './PromotionPreferences.styles.goldenbet888';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  puntcity,
  vikingbet,
  gigabet,
  slambet,
  goldenbet888,
};
