import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    px: '2',
  },
  body: {
    color: 'white',
    mt: '-2',
  },
};
