import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  border: '2px',
  borderColor: 'epsilon.500',
  bg: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  flex: '1',
  mb: '0',
};

export const RaceResultsHeader: CSSObject = {
  color: 'gray.700',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  color: 'gray.700',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, gamma.400, gamma.500)',
  color: 'gamma.800',
  fontSize: '2xs',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const FlexEventRule: CSSObject = {
  color: 'white',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '1',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'white',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  minW: '12',
  minH: '8',
  borderRadius: 'md',
  fontWeight: 'bold',
  fontSize: 'sm',
  bg: 'gray.300',
  borderWidth: '2px',
  borderColor: 'white',
  color: 'blackAlpha.500',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  boxShadow:
    '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
  opacity: '1',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};
