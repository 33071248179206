const branding = {
  alpha: {
    50: '#EBEFF6',
    100: '#E0E9F7',
    200: '#C5D6F6',
    300: '#A5BFF0',
    400: '#88ACF1',
    500: '#3E63A9',
    600: '#1A376E',
    700: '#13274B',
    800: '#071125',
    900: '#030811',
  },
  beta: {
    50: '#FFF6E6',
    100: '#FDF1DA',
    200: '#F6E6C9',
    300: '#FFE5B5',
    400: '#D9B77A',
    500: '#AC8148',
    600: '#443318',
    700: '#302410',
    800: '#211A0C',
    900: '#0D0A04',
  },
  gamma: {
    50: '#E6E6FB',
    100: '#D8D8FB',
    200: '#C8C7FD',
    300: '#B9B6FF',
    400: '#9E9CFD',
    500: '#4B49A3',
    600: '#262575',
    700: '#14134B',
    800: '#0F0E39',
    900: '#0B0B2B',
  },
  delta: {
    50: '#C4ABF9',
    100: '#B395F2',
    200: '#9B78E7',
    300: '#845DD6',
    400: '#6B45BB',
    500: '#54329C',
    600: '#2F137E',
    700: '#1D0A51',
    800: '#180844',
    900: '#110530',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  delta: branding.delta,
  epsilon: {},
  theta: {},
};
