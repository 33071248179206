import React from 'react';
import { Flex } from '@chakra-ui/react';

import {
  CardContainer,
  CardMainContent,
  DividerCard,
} from './styles/MyBetsCard.styles';
import { Footer, HeaderRow, BottomRow } from './components';
import { TMyBetsRecords } from '../../../../../api/punter/punter.types';
import { TConfirmedBet } from '@/lib/DBModels';

const MyBetsCard: React.FC<TMyBetsCardProps> = ({
  bet,
  isResulted,
  onCashoutClick,
}) => {
  const handleCashOutClick = (data: TConfirmedBet, val: boolean) => {
    // eslint-disable-next-line
    onCashoutClick?.(data, val);
  };

  return (
    <CardContainer data-cy={`myBetsCard_${bet.bet_id}`}>
      <CardMainContent isWon={!!bet.is_won}>
        <Flex direction="column" gap="1">
          <HeaderRow bet={bet} isResulted={isResulted} />
          <BottomRow
            bet={bet}
            isResulted={isResulted}
            onCashoutClick={handleCashOutClick}
          />
        </Flex>
      </CardMainContent>
      <DividerCard />
      <Footer bet={bet} />
    </CardContainer>
  );
};

export type TMyBetsCardProps = {
  bet: TMyBetsRecords;
  isResulted?: boolean;
  onCashoutClick?: (bet: TMyBetsRecords, val: boolean) => void;
};

export default MyBetsCard;
