import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputGroup: {
    rounded: 'md',
    borderRightRadius: 'md',
    borderColor: 'gamma.200',
    borderLeftRadius: 'md',
    bg: 'gamma.200',
    overflow: 'hidden',
    p: 0.5,
  },
  betCardInputLeftAddon: {
    fontWeight: 'bold',
    fontSize: 'sm',
    color: 'beta.900',
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    h: '38px',
    border: 'none',
    borderLeftTopRadius: 'lg',
    borderLeftBottomRadius: 'lg',
  },
  betCardInput: {
    my: '.5',
    borderRightRadius: 'md',
    border: '2px solid',
    borderColor: 'beta.400',
    borderLeft: 'none',
    borderLeftRadius: 'md',
    bg: 'beta.900',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'sm',
    _hover: {
      bg: 'beta.400',
    },
    _focus: {
      bg: 'beta.800',
    },
  },
};
