import React, { useCallback, useEffect, useRef, useState } from 'react';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import api from '@/api/api';
import { TNotificationMessage } from './types';
import { NotificationItem } from './components/NotificationItem';
import { getNotificationStyle } from './utils';

const MAX_NOTIFICATION_DURATION = 5_000;

export function Notifications() {
  const isProd = window.BETCLOUD_ENV.REACT_APP_IS_PROD === 'true';
  const hasConnection = useRef(false);
  const hubConnectionBuilderRef = useRef<HubConnection>();
  const isMounted = useRef(false);
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState<User | null>();
  const currentUserRef = useRef<User | null>(null);
  const navigate = useNavigate();

  const handleToastNotification = useCallback(
    ({ meta, deepLinkUrl, notification }: TNotificationMessage) => {
      toast(
        (t) => {
          const props = getNotificationStyle(meta.type);

          return (
            <NotificationItem
              meta={meta}
              deepLinkUrl={deepLinkUrl}
              notification={notification}
              toastId={t.id}
              onClick={async () => {
                try {
                  await api.post(
                    `notifications-engine/punter/read/${
                      meta.id
                    }?global=${false}&dismissed=${false}`,
                    {}
                  );
                } catch (e) {
                  // TODO: Log error
                }
                navigate(deepLinkUrl);
              }}
              {...props}
            />
          );
        },
        {
          duration: MAX_NOTIFICATION_DURATION,
          position: 'top-right',
        }
      );
    },
    [navigate]
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onAuthStateChanged(auth, async (user) => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (user === null && hubConnectionBuilderRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        currentUserRef.current = null;
        await hubConnectionBuilderRef.current.stop();
        hubConnectionBuilderRef.current = undefined;
        hasConnection.current = false;
        isMounted.current = false;
      }
      currentUserRef.current = user ?? null;
      setCurrentUser(user ?? null);
    });
  }, [auth]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void (async () => {
      if (hasConnection.current || !currentUser || isMounted.current) return;
      try {
        const { data } = await api.get<{ url: string; access_token: string }>(
          `${window.BETCLOUD_ENV.REACT_APP_API_URL}/punter/signalr/connection-details`
        );

        if (data?.url) {
          const hbu = new HubConnectionBuilder()
            .withUrl(data.url ?? '', {
              accessTokenFactory: () => data.access_token,
            })
            .configureLogging(isProd ? LogLevel.None : LogLevel.Information)
            .build();

          hbu.on('PunterNotification', (message: TNotificationMessage) => {
            handleToastNotification(message);
          });

          // Start the connection
          await hbu.start();

          hbu.onclose(() => {
            hasConnection.current = false;
            if (!isProd) {
              console.log('connection closed');
            }
            if (!isMounted.current || !currentUserRef.current) return;
            hbu
              ?.start()
              .then(async () => {
                if (!isProd) {
                  console.log('connection reestablished');
                }
                await api.get(
                  `${window.BETCLOUD_ENV.REACT_APP_API_URL}/punter/signalr/subscribe-to-updates/notifications?connection=${hbu.connectionId}`
                );
                hasConnection.current = true;
              })
              .catch(() => {
                hasConnection.current = false;
              });
          });
          await api.get(
            `${window.BETCLOUD_ENV.REACT_APP_API_URL}/punter/signalr/subscribe-to-updates/notifications?connection=${hbu.connectionId}`
          );
          hasConnection.current = true;
          hubConnectionBuilderRef.current = hbu;
          isMounted.current = true;
          return hbu;
        }
      } catch (error) {
        console.error('Error connecting to SignalR', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return null;
}
