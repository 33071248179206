export const en = {
  'errorboundary.title': 'By the king’s beard!',
  'errorboundary.desc':
    '<firstpara>An error has made itself known.</firstpara> Refresh the page, or try again later.',
  'betslip.betslipmodal.emptybetslipheading':
    'Empty Slip <suffix>Start your royal journey</suffix>',
  'onboarding.login.subtitle':
    '<b>BetRoyale</b> welcomes you, where every player is treated like royalty. Embark on an unforgettable <b>betting journey</b> today.',
  'onboarding.login.title': '<Prefix>My liege</Prefix> let us begin.',
  'sports.allsports.azheading': 'A-Z Sports',
  'onboarding.step1.pageTitle': '<Prefix>My liege</Prefix> let us begin.',
  'onboarding.register.createAccount': 'Create an account',
  'onboarding.step1.pageSubtitle':
    '<b>BetRoyale</b> welcomes you, where every player is treated like royalty. Embark on an unforgettable <b>betting journey</b> today.',
  'onboarding.depositLimit.formTitle': 'Deposit limit',
  'error.heading': 'Royal Pause, Improvements in Action',
  'maintenance.heading': 'Regal Maintenance Interlude',
  'maintenance.error': `<bold>Our royalty engineers are swiftly restoring service.</bold>
    Refresh the page, or try again later.`,
  'onboarding.locationnotallowed.header': 'Royal Wagers Inaccessible',
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing BetRoyale from outside Australia.</b>',
  'account.settings.responsible.lastupdated': 'Published: 22/03/2023',
  'account.withdraw.addaccount.withdrawaldescription':
    'Withdrawals may take up to 1-3 days to reach your account. Only turned over funds can be withdrawn.',
  'account.withdraw.addaccount.holdernamelabel': 'Account holder full name',
  'account.withdraw.addbank': 'Add new Bank account',
  'account.withdraw.requests.heading': 'Withdrawal Request',
  'account.withdraw.requests.cancelbutton': 'Cancel Withdraw Request',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'betslip.betslipbetcard.errors.insufficientlegsformulti':
    'To build a Multi, please add more selections to increase your potential returns.',
  'header.betslipBtn.desc': 'Slip',
  'racing.errorMessages.noRacesAvailable': 'Roll the Dice Again',
  'racing.errorMessages.noRacesAvailableSubtext': `<b>There are no more races available.</b>
  <p>Please consider selecting a different day to try again.</p>`,
  'error.description': `<bold>Our regal experience is momentarily unavailable.</bold>
  Refresh the page, or try again later.`,
  'main.featured.subHeader': 'reckoning',
  'sideNav.promotions': 'Promotions',
  'generic.showmore': 'See All',
  'sports.matchDetailPage.clearAll': 'Cancel',
};
