import { CSSObject } from '@chakra-ui/react';

export const GridLayout: CSSObject = {
  background: [null, null, 'gray.200'],
  height: ['auto', null, '100dvh'],
  width: ['100dvw', null, '760px'],
  minH: ['100dvh', null, 'unset'],
  borderRadius: [null, null, 'lg'],
  display: 'flex',
  alignItems: [null, null, 'center'],
  mx: 'auto',
};
export const HeaderGridItem: CSSObject = {
  zIndex: 'docked',
  position: 'absolute',
  w: ['100%', null, '760px'],
  display: 'none',
};
