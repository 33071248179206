import { CSSObject } from '@chakra-ui/react';
import error from '../../../assets/fiestabet/images/backgrounds/error.png';
import errorAtRetina from '../../../assets/fiestabet/images/backgrounds/error@2x.png';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
});

export const MaintenanceContainer = (): CSSObject => ({
  alignItems: 'center',
  bg: `url(${error}) center/cover no-repeat #172732`, // Bespoke colour from background image
  borderRadius: [null, null, 'xl'],
  boxShadow: [null, null, '0 8px 30px rgba(0, 0, 0, .2)'],
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0 1 auto'],
  gap: '0',
  h: undefined,
  justifyContent: 'start',
  maxW: 'container.md',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',

  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${errorAtRetina})`,
  },
});

export const ContentHeadingText: CSSObject = {
  w: ['220px', '500px'],
  color: 'delta.300',
  fontFamily: 'accentUltra',
  fontSize: ['3xl', null, '4xl'],
  lineHeight: 'none',
  mb: '2.5',
  mt: '4',
  textTransform: 'uppercase',
  textShadow: '0px 4px 0px #A60E14',
};

export const ContentBodyText: CSSObject = {
  fontSize: 'sm',
  mb: '4',

  span: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'extrabold',
    mb: '0.5',
  },
};

export const ButtonRefresh: CSSObject = {
  _after: {
    bg: 'none',
    boxShadow: 'none',
  },
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  boxShadow:
    '0px 0px 0px 2px #83090D inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.15)',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  textTransform: 'capitalize',
  letterSpacing: 'wider',
  color: 'epsilon.300',
  fontWeight: 'normal',
  '&&': {
    border: '2px solid',
    borderColor: 'epsilon.500',
    borderRadius: 'md',
  },
  '&:hover, &:focus': {
    bg: 'delta.300',
    color: 'delta.500',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    border: '2px solid',
    borderColor: 'epsilon.500',
  },
};
