import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: {
    px: 2.5,
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
  },
  buttonCashOutAmount: { px: 2 },
  flexOddsBoostBanner: {
    bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
    color: 'alpha.700',
  },
  textOddsBoost: { color: 'alpha.700', fontWeight: 'medium' },
};

export const LinkVenueName: CSSObject = {
  color: 'blue.400',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'blue.400',
  color: 'white',
};

export const BonusBetText: CSSObject = {
  color: 'blue.400',
};
