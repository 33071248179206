import { CSSObject } from '@chakra-ui/react';
import locationUnavailable from '../../../assets/fiestabet/images/backgrounds/location-unavailable.png';
import locationUnavailableAtRetina from '../../../assets/fiestabet/images/backgrounds/location-unavailable@2x.png';

export const LocationNotAllowedContainer: CSSObject = {
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
};

export const CenterWrapper: CSSObject = {
  alignItems: 'center',
  bg: `url(${locationUnavailable}) center/cover no-repeat #172732`, // Bespoke colour from background image
  borderRadius: [null, null, 'xl'],
  boxShadow: [null, null, '0 8px 30px rgba(0, 0, 0, .2)'],
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0 1 auto'],
  gap: '0',
  h: undefined,
  justifyContent: 'start',
  maxW: 'container.md',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',

  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${locationUnavailableAtRetina})`,
  },
};

export const ContentHeadingText: CSSObject = {
  color: 'delta.300',
  fontFamily: 'accentUltra',
  fontSize: ['3xl', null, '2xl'],
  lineHeight: 'none',
  mb: '2.5',
  mt: '4',
  textShadow: '0px 4px 0px #A60E14',
  textTransform: 'uppercase',
};

export const ContentBodyText: CSSObject = {
  fontSize: 'sm',
  mb: '4',
  textAlign: 'center',
  color: 'epsilon.100',

  b: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'extrabold',
    mb: '4',
  },

  '.chakra-link': {
    fontSize: 'sm',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
};
