import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const DividerWithdrawal: CSSObject = {
  borderBottomWidth: '2px',
  borderBottomColor: 'blackAlpha.400',
  borderStyle: 'solid',
  boxShadow: '0px 1px 0px 0px rgba(0, 154, 188, 0.20)',
};

export const TextDescription: CSSObject = {
  color: 'white',
};

/**
 * Form Inputs
 */
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
};

export const InputWithdrawal: CSSObject = {
  '&&': { bg: 'white', color: 'gray.700' },
  fontSize: 'sm',
  height: '38px',
  boxShadow: 'unset',

  _placeholder: {
    color: 'gray.600',
  },
};

/**
 * Buttons
 */
export const ButtonAddAccount: CSSObject = {
  '&&': { borderRadius: 'lg' },
  color: 'white',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  boxShadow:
    '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',

  '&:active, &:hover': {
    bg: 'beta.700',
    boxShadow:
      '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  },
};

export const ButtonWithdrawal: CSSObject = {
  mt: '2',
  '&&': { borderRadius: 'lg' },
  color: 'white',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  boxShadow:
    '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',

  '&:active, &:hover': {
    bg: 'beta.700',
    boxShadow:
      '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  },
};

export const ButtonIncrease: CSSObject = {};

export const ButtonAddBank: CSSObject = {};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottomWidth: '2px',
  borderBottomColor: 'blackAlpha.400',
  borderStyle: 'solid',
  boxShadow: '0px 1px 0px 0px rgba(0, 154, 188, 0.20)',
};

export const BankRadio: CSSObject = {
  alignItems: 'start',
  border: 'none',
  bg: 'gray.400',

  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'alpha.400',
    _hover: {
      bg: 'alpha.400',
    },
  },

  '&[data-checked]:before': {
    display: 'flex',
    alignSelf: 'center',
    width: '40%',
    height: '40%',
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '3',
  pt: '0',
  pr: '0',
  pl: ['0', null, '3'],
  w: ['100%', null, '330px'],
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

/**
 * Withdrawal Request Card
 */
export const WithdrawalWrapper: CSSObject = {
  bg: 'white',
  color: 'white',
  fontSize: 'sm',
  mt: ['4', null, '0'],
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
  border: 'none',
};

export const WithdrawalCardDetailHeading: CSSObject = {
  fontSize: 'sm',
  color: 'gray.700',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  color: 'gray.700',

  '.withdrawName': {
    fontSize: 'xs',
  },
};

export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  color: 'gray.500',
  fontSize: 'xs',
};

export const WithdrawalCardDetailRequested: CSSObject = {
  fontWeight: 'bold',
  color: 'gray.700',
  fontSize: 'xs',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  bg: 'linear-gradient(180deg, #30BA00 0%, #00CAF6 0.01%, #006E86 100%)',
  boxShadow:
    '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
  fontWeight: 'black',
  textTransform: 'uppercase',
  fontSize: 'sm',
  '&&': { borderRadius: 'lg' },

  _disabled: {
    bg: 'gray.400',
    borderWidth: '0.5',
    borderColor: 'gray.300',
    color: 'blackAlpha.200',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.60) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
    border: 'none',
    opacity: '1',

    _hover: { border: 'none' },
  },

  '&[data-active="true"]': {
    bg: 'beta.800',
    boxShadow:
      '0px 0px 0px 2px #E57D24 inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.50) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
    color: 'gamma.500',
    textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
    border: 'none',
  },

  _hover: {
    bg: 'alpha.500',
  },
  _focus: {
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
    color: 'white',
    textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
    border: 'none',
  },
};

export const WithdrawalStatus = ({ status }: any): CSSObject => ({
  borderRadius: 'base',
  px: '2',
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  bg: () => {
    switch (status) {
      case EWithdrawalStatus.Cancelled:
        return 'gray.500';
      case EWithdrawalStatus.Error:
        return 'red.500';
      case EWithdrawalStatus.Pending:
        return 'yellow.500';
      case EWithdrawalStatus.Processed:
        return 'beta.500';
      case EWithdrawalStatus.Rejected:
        return 'red.500';
      default:
        return 'green.500';
    }
  },
});

export const Subheading: CSSObject = {
  color: 'white',
};

export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'secondary',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
};
