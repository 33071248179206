import React from 'react';
import Transactions from './components/TransactionTable/Transactions';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { ETransactionTabs } from './Transactions.types';
import ActivityStatements from './components/ActivityStatements/ActivityStatements';
import { useAccountTransactions } from './services/Transactions.hooks';
import ToggleAccountView from '../../../components/ToggleAccountView/ToggleAccountView';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { TransactionsContainer } from './styles/Transactions.styles';

const AccountTransactions: React.FC = () => {
  const { tabOptions, selectedTab, setSelectedTab } = useAccountTransactions();

  return (
    <AccountWrapper
      pageTitle={selectedTab.display}
      pageHeader={selectedTab.display}
    >
      <TransactionsContainer>
        {/* Toggle */}
        {!IS_MOBILE_APP && (
          <ToggleAccountView
            tabs={tabOptions}
            onChange={(tab) => setSelectedTab(tab)}
            selectedTab={selectedTab}
          />
        )}

        {/* Content */}
        {(() => {
          switch (selectedTab.value) {
            case ETransactionTabs.ActivityStatements:
              return <ActivityStatements />;
            case ETransactionTabs.BonusBets:
              return <Transactions isBonus />;
            default:
              return <Transactions />;
          }
        })()}
      </TransactionsContainer>
    </AccountWrapper>
  );
};

export default AccountTransactions;
