import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'alpha.300',
  borderTopRadius: 'md',
  w: '100%',
  mt: ['unset', '4'],
  overflow: 'visible',
  fontSize: 'md',
  fontFamily: 'Angkor',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  pb: '0',
  py: ['2', '4'],
  color: 'white',
  boxShadow:
    '0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  '&&': { px: ['2.5', '3'], pt: ['2.5', '3'], pb: '3' },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'uppercase',
  color: 'white',
  fontFamily: 'Bowlby One',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
  mx: ['2', 'unset'],
  mt: ['2', 'unset'],
};

export const WrapperContainer: CSSObject = {
  mb: '2',
  pt: '2',
  mt: [null, null, '-1'],
  px: ['2.5', '3'],
  pb: 9,
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  borderTopRadius: 'none',
  borderBottomRadius: 'md',
  boxShadow: '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset',
};
