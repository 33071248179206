import dayjs from 'dayjs';
import { TBreakPeriod } from '../types';
import { getStrings } from '@/helpers/utils';

const [
  {
    Account: { TakeABreak },
  },
] = getStrings();

const generatePeriod = (
  now: dayjs.Dayjs,
  date: dayjs.Dayjs,
  label: string
): TBreakPeriod => {
  const period = date.diff(now, 'day');
  return {
    label: `${date.format('DD/MM/YYYY')} ${TakeABreak.periodFormat.replace(
      'X',
      label
    )}`,
    period,
  };
};

export const getBreakPeriods = (): TBreakPeriod[] => {
  const now = dayjs();

  return [
    generatePeriod(now, now.add(1, 'day'), TakeABreak.period24),
    generatePeriod(now, now.add(2, 'day'), TakeABreak.period48),
    generatePeriod(now, now.add(3, 'day'), TakeABreak.period72),
    generatePeriod(now, now.add(7, 'day'), TakeABreak.periodWeek),
    generatePeriod(now, now.add(14, 'day'), TakeABreak.periodFortnight),
    generatePeriod(now, now.add(1, 'month'), TakeABreak.period1Month),
    generatePeriod(now, now.add(3, 'month'), TakeABreak.period3Months),
    generatePeriod(now, now.add(6, 'month'), TakeABreak.period6Months),
  ];
};
