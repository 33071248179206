import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  maxW: '100%',
  'button[data-cy="updateDepositLimitButton"]': {
    bgGradient: 'linear(to-b, beta.700, beta.800)',
    color: 'beta.500',
    textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontFamily: 'accent',
    borderRadius: 'md',
    letterSpacing: 'wide',
    fontWeight: 'hairline',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
    my: '2.5',
    _hover: {
      bgGradient: 'linear(to-b, delta.400, delta.500)',
      color: 'white',
    },
    _focus: {
      bgGradient: 'linear(to-b, beta.900, beta.900)',
      boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
    },
    _disabled: {
      bg: 'gray.400',
      borderColor: 'gray.600',
      color: 'gray.200',
      textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
      boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
    },
    '&::after': {
      bg: 'transparent',
    },
  },
};
