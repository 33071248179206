import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgGradient: 'linear(to-b, delta.500, delta.500)',
  h: '100%',
  maxH: '100dvh',
  borderRadius: 'xl',
  border: '7px solid',
  borderColor: 'alpha.500',
  backgroundClip: 'border-box',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  py: '11px',
  px: '8px',
  mx: '3px',
  mb: isKeypadVisible && 'unset',
});

export const BetSlipContainer: BoxProps = {
  border: '4px solid white',
  borderRadius: 'base',
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'delta.300',
  color: 'gamma.600',
  '&&': {
    p: '3',
    mb: '1',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'white',
  fontFamily: 'Chewy',
  fontSize: 'lg',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'extrabold',
  color: 'gamma.400',
  fontFamily: 'Chewy',
  fontSize: 'lg',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
});

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'secondary',
  h: '10',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  height: '6',
  width: '74px',
  bgGradient: 'linear(to-b, beta.400, beta.400)',
  borderRadius: 'xl',
  fontWeight: 'bold',
  margin: 'auto',
  mt: '-6',
  position: 'relative',
  lineHeight: 'normal',
  color: 'blackAlpha.600',
  minW: '16',
  border: '3px solid',
  w: 'auto',
  px: '2',
  textShadow: 'none',

  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '1',
    },
    '.chakra-icon': {
      boxSize: '3',
    },

    '&&': {
      fontSize: '2xs',
      fontFamily: 'roboto',
      boxShadow: 'none',
      borderColor: 'alpha.50',
      pb: '0.5',

      '&:hover, &:active': {
        bgGradient: 'linear(to-b, beta.500, beta.500)',
      },
    },
  },
};

export const BetslipVStack: CSSObject = {
  gap: '2',
};

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '1'],
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'secondary',
  height: '42px',
};
