import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  borderRadius: 'base',
  color: 'alpha.900',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '2',
  px: '3',
  py: '4',
};

export const ButtonGetVerified: CSSObject = {
  bg: 'gamma.500',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'base',
  color: 'blackAlpha.600',
  fontFamily: 'Caveman',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  h: '6',
  lineHeight: '6',
  ml: 'auto',
  minW: 'auto',
  px: '2.5',
  textTransform: 'uppercase',
  _hover: {
    bg: 'alpha.800',
  },
  _focus: {
    bg: 'alpha.800',
  },
};
