import { CSSObject } from '@chakra-ui/react';

export const StartTime: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'blackAlpha.500',
  px: '1',
  py: '0.5',
  borderRadius: 'base',
};
