import { CSSObject } from '@chakra-ui/react';

export const LoginButton: CSSObject = {
  bg: 'alpha.500',
  border: '2px',
  borderColor: 'alpha.700',
  borderRadius: 'md',
  boxShadow: 'inset 0px 0px 0px 4px #426DD6',
  fontSize: 'xs',
  _hover: {
    bg: 'alpha.700',
  },
  _active: {
    bg: 'alpha.800',
  },
};

export const LinkSignup: CSSObject = {
  display: 'none',
};

export const LinkForgotPassword: CSSObject = {
  color: 'alpha.500',
  fontSize: '2xs',
  fontWeight: 'medium',
};
