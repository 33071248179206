import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'outline',
    fontSize: 'xs',
    height: '7',
    textTransform: 'uppercase',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
  },
};
