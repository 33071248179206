import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  px: '3',
  py: '4',
  borderRadius: 'base',
  alignItems: 'center',
  bgGradient: 'linear(to-b, delta.200, delta.200)',
  maxH: ['unset', null, '46px'],
  mb: '2',
};

export const TextMustBeVerified: CSSObject = {
  color: 'delta.700',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ButtonGetVerified: CSSObject = {
  color: 'white',
  bgGradient: 'linear(to-b,alpha.400, alpha.600)',
  fontFamily: 'CarterOne',
  boxShadow:
    '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
  borderRadius: 'base',
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  px: '2.5',
  py: '1',
  ml: 'auto',
  minW: 'unset',
  h: '6',
  _hover: {
    bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
  },
  _focus: {
    bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
  },
};
