import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  flexExoticsHeadingRight: { alignItems: ['flex-end', 'center'] },
  buttonStyledGroupedProps: {
    variant: 'odds',
    boxSize: '34px',
    minWidth: '34px',
    minHeight: '34px',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-closed="true"][data-active="false"]': {
        border: '2px solid rgba(255, 255, 255, 0.08)',
        bg: 'transparent',
        boxShadow: 'none',
        rounded: 'base',
        '.btn-span': {
          color: 'whiteAlpha.500',
        },
      },
    },
  },
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mx: ['2', '0'],
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  py: '4px',
  borderRadius: 'base',
  color: 'white',
  flexDirection: 'row',
};

export const TextRaceConditions: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    w: '1px',
    h: '4',
    bg: 'whiteAlpha.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: 'green.500',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
};

export const RaceStatusText: CSSObject = {
  color: 'orange.800',
  bg: 'orange.400',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  textTransform: 'none',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  w: '48px',
  minW: '40px',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  py: '3',
};

export const RaceRunnerList: CSSObject = {
  bg: 'transparent',
  p: '0',
};

export const TextTitle: CSSObject = {
  fontFamily: 'accent',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: 'white',
  letterSpacing: '1px',
};

export const RaceName: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
  color: 'white ',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  mt: '1',
  mx: ['2', 0],
  pb: '1.5',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  '.chakra-switch__track': {
    background: 'alpha.100',
    boxShadow:
      '0px 2px 2px 0px rgba(0, 0, 0, 0.10) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
    '&[data-checked]': {
      bg: 'gray.500',
      boxShadow:
        '0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    },
    '& .chakra-switch__thumb': {
      bg: 'beta.400',
      '&[data-checked]': { bg: 'gray.300' },
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
  pb: '2',
  alignItems: 'center',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '12',
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  mb: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};

export const ShowMoreText: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};
