import { CSSObject } from '@chakra-ui/react';

export const ButtonSaveChanges: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
  color: 'alpha.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const FormWrapper: CSSObject = {
  color: 'white',
  bg: 'transparent',
  p: '2',
  '.chakra-switch__label': {
    color: 'white',
    fontSize: 'xs',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    border: '1px solid',
    borderColor: 'gray.300',
    width: '1rem',
    height: '1rem',
    borderRadius: 'base',
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _hover: {
      bg: 'alpha.100',
      boxShadow: '0px 0px 8px 0px rgba(43, 108, 176, 0.40)',
    },
    _checked: {
      boxShadow: 'none',
      color: 'blue.600',
      _hover: {
        bg: 'alpha.100',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
  'button[data-cy="promotionsPreferencesSubmit"]': {
    bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
    borderRadius: 'md',
    color: 'alpha.800',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    w: '100px',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    _hover: {
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
      boxShadow:
        '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
      color: 'alpha.400',
      textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
    },
    ':active, &[data-active="true"]': {
      bg: 'blackAlpha.500',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
      color: 'alpha.400',
      textShadow: 'unset',
    },
    _disabled: {
      bg: 'blackAlpha.400',
      color: 'gray.300',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      boxShadow:
        '0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
      pointerEvents: 'none',
    },

    '&&&': {
      '& > span, & > svg': { zIndex: 9 },
    },
  },
};
