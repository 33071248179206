import { CSSObject } from '@chakra-ui/react';

export const GridLayout: CSSObject = {
  width: ['full', null, '760px'],
  borderRadius: [null, null, 'lg'],
  display: 'flex',
  minH: '100dvh',
  alignItems: [null, null, 'center'],
  mx: 'auto',
  my: ['unset', null, '4'],
  '.bc-content, .bc-aside': {
    boxShadow: [null, null, '0px 8px 30px rgba(0, 0, 0, 0.2)'],
  },
  '.bc-content': {
    paddingTop: '4',
    width: '100%',
    justifyContent: ['flex-start', null, 'center'],
  },
};
