import clipImage from '@/assets/betprofessor/images/sidenav/clip.png';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    mt: ['6', null, null, '3'],
    mb: '4',
    mx: ['2', null, null, 'unset'],
    px: '0',
    position: 'relative',
    borderRadius: 'lg',
    p: ['0', null, null, '2'],
    bgGradient: ['none', null, null, 'linear(180deg, beta.600, beta.700)'],
    boxShadow: [
      'unset',
      null,
      null,
      '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #1A3347, 0px 2px 3px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    ],
    // Clipboard
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: ['-28px', null, null, '-15px'],
      left: ['38%', null, null, '36%'],
      width: ['100px', null, null, '80px'],
      height: ['52px', null, null, '42px'],
      zIndex: '1',
      backgroundImage: `url(${clipImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
  boxWrapperProps: {
    p: '1.5',
    border: ['none', null, null, '2px dashed rgba(0, 0, 0, 0.15)'],
    borderRadius: 'lg',
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    position: 'relative',
    width: 'full',
    maxW: ['unset', null, null, '14.9rem'],
    mr: ['0', null, null],
    borderTopRadius: 'base',
    bg: 'linear-gradient(to bottom, var(--bc-colors-epsilon-400), var(--bc-colors-epsilon-500))',
  },
};
