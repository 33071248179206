import { css } from 'styled-components';

export const container = css`
  margin-bottom: var(--bc-sizes-4);
`;

export const select = css`
  color: ${({ theme }) => theme.palette.secondary};
  background: ${({ theme }) => theme.palette.inputBg};
  border: ${({ theme }) => theme.borderStyles.tertiary};
  border-radius: 3px;
  height: 33px;
  font-size: var(--bc-fontSizes-md);
  font-family: ${({ theme }) => theme.fontFamily.body};

  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 5px;
`;

export const label = css`
  color: ${({ theme }) => theme.palette.primary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.M};
`;

export const disabled = css``;

export const error = {
  label: css`
    color: ${({ theme }) => theme.palette.warning};
    font-size: var(--bc-fontSizes-xs);
    padding-top: var(--bc-sizes-1);
    font-family: ${({ theme }) => theme.fontFamily.body};
    align-items: center;
  `,
  icon: css`
    background-size: var(--bc-sizes-3) var(--bc-sizes-3);
    width: var(--bc-sizes-3);
    height: var(--bc-sizes-3);
    margin-right: var(--bc-sizes-1);
    color: ${({ theme }) => theme.palette.warning};
  `,
};
