import React from 'react';
import { useIntl } from 'react-intl';
import { centsToDollars, getStrings, dollarsToCents } from '@/helpers/utils';
import {
  BetApprovalText,
  ApprovedBetContainer,
  IconApprovedBet,
} from './Footer.styles';
import { TBetApprovalProps } from '../../../../Services/Betslip.types';
import { EBetRequestStatus } from '../../../../../../api/punter/punter.types';

export const ApprovedBet: React.FC<TBetApprovalProps> = ({
  betRequest,
  bet,
}) => {
  const [{ BetSlip: Strings }] = getStrings();

  const intl = useIntl();

  const approvedBetAmount = bet ? dollarsToCents(bet?.stake) : 0;
  const approvalText = () => {
    // To account for MLBs
    if (bet?.confirmation?.reduced_stake) {
      return `${Strings.betSlipBetCard.partialBetApprovalInfo.replace(
        '%',
        centsToDollars(approvedBetAmount)
      )}
      ${intl.formatMessage({ id: 'betSlip.betSlipBetCard.approved' })}`;
    }

    // To account for pending requests
    switch (betRequest?.status) {
      case EBetRequestStatus.ReducedStake:
        return `${Strings.betSlipBetCard.partialBetApprovalInfo.replace(
          '%',
          centsToDollars(betRequest?.approved_stake)
        )}
        ${intl.formatMessage({ id: 'betSlip.betSlipBetCard.approved' })}`;
      case EBetRequestStatus.Approved:
        return `${Strings.betSlipBetCard.betApprovalInfo}
        ${intl.formatMessage({ id: 'betSlip.betSlipBetCard.approved' })}`;
      default:
        break;
    }
  };
  return (
    <ApprovedBetContainer>
      <IconApprovedBet />
      <BetApprovalText>{approvalText()}</BetApprovalText>
    </ApprovedBetContainer>
  );
};
