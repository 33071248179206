import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['0.5', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};
export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  bg: ['blackAlpha.400', null, 'rgba(78, 178, 255, 0.14)'],
  boxShadow: [
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    null,
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  ],
  borderRadius: 'md',
  '&::-webkit-scrollbar': { display: 'none' },
  mt: '2',
};
export const NavLink = (isActive: boolean): CSSObject => ({
  bg: ['transparent', null, isActive ? 'blackAlpha.400' : 'transparent'],
  color: [
    isActive ? 'alpha.400' : 'white',
    null,
    isActive ? 'beta.100' : 'gamma.100',
  ],
  textShadow: [
    isActive ? '0px 0px 8px rgba(255, 159, 28, 0.5)' : 'none',
    null,
    isActive
      ? '0px 0px 8px rgba(78, 178, 255, 0.5)'
      : '0px 2px 4px rgba(0, 0, 0, 0.25)',
  ],
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontSize: ['sm', null, 'md'],
  lineHeight: 'normal',
  py: ['2', '3'],
  pl: isActive ? ['2', '3'] : ['2', '4'],
  pr: ['2', '4'],
  fontFamily: 'accent',
  borderLeft: ['unset', null, isActive && '3px solid'],
  borderLeftColor: ['unset', null, isActive && 'beta.100'],
  borderBottom: ['2px solid', null, isActive ? 'none' : '1px solid'],
  borderBottomColor: [
    isActive ? 'alpha.400' : 'transparent',
    null,
    isActive ? 'unset' : 'blackAlpha.400',
  ],
  borderTop: ['none', null, isActive ? 'none' : '1px solid'],
  borderTopColor: ['none', null, isActive ? 'unset' : 'whiteAlpha.200'],
  _first: {
    borderTopRadius: ['none', null, '8px'],
  },
  _last: {
    borderBottomRadius: '8px',
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    bg: !isActive && 'blackAlpha.300',
  },
});

export const NavLinkLabel: CSSObject = {
  color: 'alpha.400',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  border: 'none',
  borderBottom: '3px solid',
  borderBottomColor: 'alpha.400',
  fontSize: 'sm',
  textAlign: 'center',
  fontFamily: 'Staatliches',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '110px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '40px',
  ml: ['2', '5'],
};

export const TopNavLabelWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  w: ['95%', '98%'],
  bg: 'blackAlpha.400',
  mx: '2',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10),0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  borderRadius: 'lg',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};
