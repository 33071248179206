import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { keys } from '@/api/api.keys';
import { queryUpcomingMatchesBySport } from './upcomingMatchesBySport';
import {
  TQueryUpcomingMatchesBySportParams,
  TQueryUpcomingMatchesBySportResponse,
} from './upcomingMatchesBySport.types';

export const useQueryUpcomingMatchesBySport = (
  params?: TQueryUpcomingMatchesBySportParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<TQueryUpcomingMatchesBySportResponse>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery(
    [keys.upcomingMatchesBySport, params],
    () => queryUpcomingMatchesBySport(params),
    options
  );
