import { css } from 'styled-components';

export const container = css`
  padding: var(--bc-sizes-8) var(--bc-sizes-5) var(--bc-sizes-4)
    var(--bc-sizes-5);

  ${({ theme }) => theme.device.tablet} {
    background-size: cover;
    padding: var(--bc-sizes-8) var(--bc-sizes-10) var(--bc-sizes-4)
      var(--bc-sizes-10);
  }
`;

export const linkContainer = css``;

export const icon = css``;

export const logoContainer = css``;

export const logoLink = css``;

export const logo = css`
  max-height: var(--bc-sizes-16);
`;

export const quickViewContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const accountBalance = css`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-md);
  font-family: ${({ theme }) => theme.fontFamily.heading};
  letter-spacing: 0.25px;

  &:hover {
    text-decoration: underline;
  }
`;

export const accountBonus = css`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-2xs);
  font-style: italic;
  font-family: ${({ theme }) => theme.fontFamily.body};
  letter-spacing: 0.1px;
`;
