import {
  Box,
  CSSObject,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbItemProps,
  BreadcrumbLinkProps,
  chakra,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { styleImports } from './Breadcrumb.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import IconSvg, { TIconSvg } from '../common/IconSvg';

export type BreadcrumbSchema = Partial<{
  breadcrumbWrapper: CSSObject;
  breadcrumbItemBreadcrumb: BreadcrumbItemProps;
  breadcrumbLinkItem: BreadcrumbLinkProps;
  chevronRightIcon: TIconSvg;
}>;

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    'data-testid': 'breadcrumbs-breadcrumbWrapper',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    // Not working on all themes
    '&::-webkit-scrollbar': { display: 'none' },
    ...(styleImports?.breadcrumbStyles?.breadcrumbWrapper ?? {}),
  },
  breadcrumbItemBreadcrumb: {
    'data-testid': 'breadcrumbs-breadcrumbItemBreadcrumb',
    spacing: '1',
    textTransform: 'capitalize',
    ...(styleImports?.breadcrumbStyles?.breadcrumbItemBreadcrumb ?? {}),
  },
  breadcrumbLinkItem: {
    'data-testid': 'breadcrumbs-breadcrumbLinkItem',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '2xs',
    fontWeight: 'bold',
    ...(styleImports?.breadcrumbStyles?.breadcrumbLinkItem ?? {}),
  },
  chevronRightIcon: {
    'data-testid': 'breadcrumbs-chevronRightIcon',
    mt: '1',
    boxSize: '0.7rem',
    color: 'white',
    ...(styleImports?.breadcrumbStyles?.chevronRightIcon ?? {}),
  },
};

export const BreadcrumbWrapper = chakra(Box, {
  label: 'breadcrumb-BreadcrumbWrapper',
  baseStyle: ({ theme }) => ({
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
    ...(theme.themeName === EThemes.Betgalaxy && {
      display: ['none', null, 'flex'],
      borderRadius: 'md',
      bg: rgba(`${theme.colors.beta[500]}`, 0.5),
      px: '3',
      h: '8',
      alignItems: 'center',
    }),
    ...(() => {
      try {
        return styleImports?.breadcrumbWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BreadcrumbItemBreadcrumb = chakra(BreadcrumbItem, {
  label: 'BreadcrumbItemBreadcrumb',
  baseStyle: () => ({
    spacing: '1',
    textTransform: 'capitalize',
    ...(() => {
      try {
        return styleImports?.BreadcrumbItemBreadcrumb;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BreadcrumbLinkItem = chakra(BreadcrumbLink, {
  label: 'BreadcrumbLinkItem',
  baseStyle: () => ({
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '2xs',
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports?.BreadcrumbLinkItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ChevronRightIcon = chakra(IconSvg, {
  label: 'ChevronRightIcon',
  baseStyle: () => ({
    mt: '1',
    boxSize: '0.7rem',
    color: 'white',
    ...(() => {
      try {
        return styleImports?.ChevronRightIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
