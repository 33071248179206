import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: ['2', null, null, '4'],
  pt: '2.5',
  pb: 0,
  m: 'auto',
  h: '38px',
  w: 'container.xl',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  '& > div:last-of-type': { mr: '0' },
  gap: '1',
};

export const LinkItem: CSSObject = {
  bg: 'linear-gradient(180deg, rgba(104, 219, 255, 0.80) 0%, rgba(26, 200, 255, 0.80) 100%)',
  borderRadius: '5px',
  px: '1.5',
  py: 0,
  alignItems: 'center',

  '&&': {
    '.chakra-badge': {
      pos: 'static',
      borderRadius: 'sm',
      px: '1',
      h: '17px',
      lineHeight: '17px',
      fontSize: '10px',
      ml: '1',
    },

    '.state-ended': {
      color: 'beta.800',
    },

    '.state-inprogress': {
      bg: 'alpha.300',
      color: 'alpha.900',
    },

    '.state-prior': {
      color: 'gray.700',
    },
  },
};

export const FlexDetails: CSSObject = {
  display: 'block',
  color: 'white',
  textShadow: '0px 2px 1px rgba(0, 0, 0, 0.15)',
  maxW: '90px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  fontSize: '10px',
  textTransform: 'uppercase',
};

export const RaceNumber: CSSObject = {
  color: 'gamma.400',
  borderRadius: 'sm',
  h: '17px',
  lineHeight: '17px',
  px: '1',
  bg: 'alpha.600',
  fontSize: '10px',
  order: 3,
  ml: '1',
};

export const Icon: CSSObject = {
  color: 'white',
  filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.15))',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '0',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  w: '4',
  h: 'full',
  bg: 'linear-gradient(180deg, rgba(104, 219, 255, 0.80) 0%, rgba(26, 200, 255, 0.80) 100%)',
  color: 'white',
  p: '0',
  borderRadius: '5px',
  border: 'none',
  backdropFilter: 'blur(2.5px)',

  '& > svg': {
    boxSize: '5',
    filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.15))',
  },

  _hover: {
    bg: 'linear-gradient(180deg, rgba(104, 219, 255, 0.80) 0%, rgba(26, 200, 255, 0.80) 100%)',
  },
};
