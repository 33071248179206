import { CSSObject } from '@chakra-ui/react';
import featuredCardsBg from '@/assets/juicybet/images/backgrounds/inner.png';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: ['0', '3'],
    gap: '3',
  },

  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    border: '3px solid',
    borderColor: 'alpha.50',
    borderRadius: 'xl',
    boxShadow:
      '0px 0px 4px 5px rgba(0, 0, 0, 0.15) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    _last: {
      mb: '0',
    },
    bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${featuredCardsBg})`,
    bgRepeat: 'repeat',
    bgSize: '90%',
    backgroundBlendMode: 'overlay',
  },

  LinkCardHeader: {
    color: 'white',
    mx: '2',
  },

  TextCardHeading: {
    fontFamily: 'Chewy',
    textShadow: '0px 2px 6px rgba(0, 0, 0, 0.30)',
    fontSize: 'md',
    fontWeight: 'normal',
    letterSpacing: 'wide',
  },

  TextRaceNumber: {
    fontSize: '9px',
    bg: 'alpha.800',
    borderRadius: 'base',
  },

  GridCardContent: {
    fontSize: 'sm',
    mx: '2',
  },

  BoxGridHeaderItem: {
    bg: 'beta.600',
    borderRadius: 'base',
    color: 'gamma.400',
    textShadow: '0px 2px 2px rgba(0, 0, 0, 0.20)',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    boxShadow:
      '0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    mr: '2',
  },

  FlexGridRunnerItem: {
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',

    _last: {
      border: 'none',
    },
  },

  TextRunner: {
    textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'semibold',
  },

  TextRunnerNumber: {
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'bold',
  },

  LinkRaceDetails: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
    color: 'alpha.700',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontFamily: 'Coiny',
    borderRadius: 'md',
    borderWidth: '2px',
    borderColor: 'white',
    letterSpacing: 'wide',
    mx: '2',
    my: '2.5',

    _hover: {
      bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    },

    _focus: {
      bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    },

    _disabled: {
      bg: 'gray.300',
      borderColor: 'gray.600',
      color: 'blackAlpha.200',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
      boxShadow:
        '0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.60) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
    },
  },

  TextScratched: {
    fontSize: '2xs',
    color: 'white',
  },

  buttonRaceCardOddsProps: {
    variant: 'solid',
    fontSize: 'sm',
    color: 'alpha.900',
    fontWeight: 'black',
    fontFamily: 'body',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};
