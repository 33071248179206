import { CSSObject } from '@chakra-ui/react';

export const FlexInner: CSSObject = {
  maxWidth: 'lg',
  color: 'white',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const TextHeading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const TextDescription: CSSObject = {
  fontSize: 'xs',
  mb: '7',
  color: 'white',
};
