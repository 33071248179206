import { CSSObject } from '@chakra-ui/react';

export const GreenIDContainer: CSSObject = {
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  maxW: ['100vw', null, '500px'],
  bg: 'white',
  'button#greenid-option-list-toggle': {
    borderRadius: 'md',
    bg: 'blackAlpha.400',
    color: 'beta.800',
    border: 'none',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },
  '#greenid-source-header #greenid-source-list-container': {
    mb: '8px !important',
  },
  '#greenid-source-header #greenid-source-title': {
    '#greenid-source-title-heading': {
      color: 'beta.800',
      fontWeight: 'medium',
      fontSize: 'sm',
      fontFamily: 'roboto',
    },
    '.glyphicon-question-sign': {
      color: 'alpha.400',
    },
  },
  '#greenid-intro-words': {
    '.lead': {
      color: 'beta.800',
      fontSize: 'xs',
      fontFamily: 'roboto',
      fontWeight: 'normal',
    },
  },
  '#greenid-intro-content': {
    h1: {
      color: 'beta.800',
      fontSize: 'md',
      fontFamily: 'heading',
      fontStyle: 'normal',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      mb: '4',
      gap: '5px',
      mt: 0,
    },
    p: {
      color: 'beta.800',
      fontWeight: 'normal',
      fontSize: 'sm',
      fontFamily: 'roboto',
    },
  },
  '&&': {
    '#greenid-container': {
      '.btn-primary': {
        border: 'none',
        width: '100%',
        h: 10,
        alignItems: 'center',
      },
      bg: 'unset',
      a: {
        color: 'beta.800',
      },
      '.greenid-helpicon': {
        color: '#FF9F1C',
      },
      '#greenid-source-header': {
        display: 'flex',
        flexDirection: 'column',
      },
      '#greenid-source-content': {
        borderTop: '1px solid',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset',
        borderTopColor: 'blackAlpha.500',
        '#greenid-source-body': {
          p: {
            color: 'beta.800',
            strong: {
              color: 'beta.800',
            },
          },
          '.row': {
            '.form-group': {
              mb: 2,
              select: {
                borderRadius: 'md',
                bg: 'gray.50',
                color: 'gray.600',
                border: 'none',
                boxShadow:
                  '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
                option: {
                  color: 'black',
                },
              },
              '.control-label': {
                color: 'beta.800',
              },
              'label[for="greenid-bdm-stateselect"]': {
                color: 'beta.800',
              },
              label: {
                color: 'black',
                fontWeight: 'medium',
                fontSize: 'sm',
                fontFamily: 'roboto',
                span: {
                  color: 'beta.800',
                  fontWeight: 'medium',
                  fontSize: 'sm',
                  fontFamily: 'roboto',
                },
              },
              'input[type="text"]': {
                borderLeftRadius: 'md',
                bg: 'gray.50',
                boxShadow:
                  '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
                border: 'none',
                color: 'gray.600',
                fontWeight: 'normal',
                '&&:hover, &&:focus': {
                  bg: 'blackAlpha.400',
                },
                _placeholder: {
                  color: 'gray.600',
                },
                h: '38px',
              },
            },
          },
          '.checkbox': {
            'input[type="checkbox"]': {
              width: '20px',
              height: '20px',
              bg: 'beta.800',
              borderWidth: '2px',
              borderColor: 'gray.200',
              marginLeft: '-28px',
              borderRadius: 'sm',

              '&[data-checked]': {
                width: '20px',
                height: '20px',
                bg: 'beta.800',
                borderColor: 'beta.800',
              },
              '&[data-checked]:hover': {
                width: '20px',
                height: '20px',
                bg: 'beta.800',
                borderColor: 'beta.800',
              },
            },
            label: {
              fontSize: 'xs',
              color: 'beta.800',
              paddingLeft: '28px',
              h: '28px',
            },
          },
        },
      },
      '.input-group-addon': {
        bg: 'rgba(0, 0, 0, 0.24)',
        border: 'none',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
        '.glyphicon-calendar': {
          color: 'beta.800',
        },
      },
    },
  },
  '#greenid-submit-button-container': {
    button: {
      color: 'beta.500',
      fontSize: 'sm',
      fontWeight: 'normal',
      py: '2',
      px: '4',
      h: 10,
      bgImage:
        'linear-gradient(to bottom, var(--bc-colors-beta-700), var(--bc-colors-beta-800))',
      textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
      fontFamily: 'accent',
      borderRadius: 'md',
      boxShadow:
        '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
      _hover: {
        bgImage:
          'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
        color: 'white',
      },
      _active: {
        boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
        bgImage:
          'linear-gradient(180deg, var(--bc-colors-beta-900) 0%, var(--bc-colors-beta-900) 100%)',
      },
      _focus: {
        bgImage:
          'linear-gradient(to bottom, var(--bc-colors-beta-900), var(--bc-colors-beta-900))',
        boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
      },
      _disabled: {
        bgImage:
          'linear-gradient(to bottom, var(--bc-colors-gray-400), var(--bc-colors-gray-400))',
        borderColor: 'gray.600',
        color: 'gray.200',
        textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
        boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
        opacity: '0.4',
      },
    },
  },
};
