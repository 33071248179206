import { Search } from '@styled-icons/bootstrap/Search';
import { CompetitionListSchema } from '@/views/sports/components/CompetitionList/styles/CompetitionList.styles';

export const competitionListStyles: CompetitionListSchema = {
  skeleton: {
    startColor: 'beta.300',
    endColor: 'beta.400',
    minW: 'full',
    h: '20',
    borderRadius: 'lg',
    flex: 1,
    mt: '8',
  },
  input: {
    name: 'x',
    sxWrapper: { mb: '0' },
    bg: 'blackAlpha.600',
    border: '1px',
    borderColor: 'beta.600',
    boxShadow: 'lg',
    borderRadius: 'md',
    color: 'white',
    mb: '0',

    _hover: {
      bg: 'blackAlpha.600',
    },

    _focus: {
      boxShadow: 'xl',
      color: 'alpha.500',
      textShadow: 'sm',
      bg: 'blackAlpha.700',
    },
  },
  inputRightIcon: {
    color: 'alpha.500',
    boxSize: '5',
    as: Search,
  },
  accordion: {
    color: 'white',
    mb: '3',
    variant: 'unstyled',
    w: 'full',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    py: '2',
  },
  accordionButton: {
    px: '0',
    pos: 'relative',
    _hover: { bg: 'transparent' },
  },
  hStack: {
    w: 'full',
    alignItems: 'center',
  },
  flex: { alignItems: 'center', gap: 2, flex: 1, textAlign: 'left' },
  accordionButtonIconSvg: {
    color: 'alpha.600',
    fontSize: 'sm',
  },
  accordionButtonText: {
    fontSize: ['sm', 'lg'],
    fontFamily: 'CrimsonPro',
  },
  marketTypesFlex: {
    mr: '2',
    gap: '2',
  },
  marketTypesText: {
    w: ['50px', '100px'],
    fontSize: 'xs',
  },
  accordionButtonInnerIcon: {
    pos: 'absolute',
    right: '0',
    boxSize: '6',
    color: 'alpha.600',
  },
  accordionPanel: {
    p: '0',
    mb: '3',
  },
};
