import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  maxW: ['none', 'lg'],
  '.chakra-checkbox__control': {
    bg: 'white',
    border: '0',
    boxShadow: 'inset 0 2px 4px rgba(49, 57, 77, .82)',
    _focus: {
      boxShadow: 'inset 0 2px 4px rgba(49, 57, 77, .82)',
    },
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _checked: {
      bg: 'alpha.400',
      boxShadow: 'none',
      _hover: {
        bg: 'alpha.400',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
  '.chakra-radio__input + span': {
    bg: 'white',
    border: '0',
    boxShadow: 'inset 0 2px 4px rgba(49, 57, 77, 0.82)',
  },
  '.chakra-radio': {
    _checked: {
      '.chakra-radio__input + span': {
        bg: 'alpha.500',
        border: '2px',
        borderColor: 'alpha.500',
        boxShadow: 'none',
      },
    },
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__left-addon': {
    bg: 'beta.700',
    borderLeftRadius: 'base',
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'sm',
    h: '9',
  },
  '.chakra-input': {
    border: '1px',
    borderColor: 'gray.400',
    bg: 'white',
    h: '9',
    fontWeight: 'semibold',
    _hover: {
      bg: 'gray.50',
    },
    _active: {
      bg: 'gray.100',
    },
  },
  '.chakra-input__group .chakra-input': {
    borderRightRadius: 'base',
    fontSize: 'sm',
  },
  '.chakra-select': {
    border: '1px',
    borderColor: 'gray.400',
    bg: 'gray.50',
    h: '9',
  },
  '.chakra-form__error-message': {
    color: 'red.300',
    fontSize: 'xs',
    mb: 2,
  },
  '.chakra-form__helper-text': {
    color: 'delta.100',
    fontSize: 'xs',
  },
};
