import React from 'react';
import { Image, Show, Hide } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import logo from '@/assets/templebet/images/logo/logo.png';
import logoMobile from '@/assets/templebet/images/logo/logo-mobile.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function Index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          alt={`${THEME_NAME.templebet}`}
          data-testid={`${THEME_NAME.templebet}_desktop`}
          mx="auto"
          mb="1"
          w="185px"
          h={{ base: '8', lg: 'auto' }}
          filter="drop-shadow(0px 3px 4px var(--bc-colors-blackAlpha-400))"
        />
      </Show>
      <Hide above="lg">
        <Image
          mt="2"
          minW="117px"
          w="full"
          src={logoMobile}
          alt={`${THEME_NAME.templebet}`}
          data-testid={`${THEME_NAME.templebet}_mobile`}
        />
      </Hide>
    </Link>
  );
}
