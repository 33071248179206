import styled from 'styled-components';

export const BannerContainer = styled.div`
  ${({ theme }) => theme.ui.banner.container};
`;

export const TitleContainer = styled.div`
  ${({ theme }) => theme.ui.banner.titleContainer};
`;

export const SubtitleContainer = styled.div`
  ${({ theme }) => theme.ui.banner.subtitleContainer};
`;

export const TextContainer = styled.div`
  ${({ theme }) => theme.ui.banner.textContainer};
`;

export const Title = styled.div`
  ${({ theme }) => theme.ui.banner.title};
`;

export const Subtitle = styled.div`
  ${({ theme }) => theme.ui.banner.subtitle};
`;

export const Text = styled.div`
  ${({ theme }) => theme.ui.banner.text};
`;

export const BackgroundImage = styled.div`
  ${({ theme }) => theme.ui.banner.backgroundImage};
`;

export const OverlayImage = styled.div`
  ${({ theme }) => theme.ui.banner.overlayImage};
`;
