import { ButtonProps, chakra, Flex } from '@chakra-ui/react';
import { styleImports } from './ToggleAccountView.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export const TabContainer = chakra(Flex, {
  baseStyle: () => ({
    label: 'toggleAccountView-TabContainer',
    width: '100%',
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.300',

    ...(() => {
      try {
        return styleImports[themeName]?.TabContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TabWrapper = chakra(Flex, {
  baseStyle: () => ({
    label: 'toggleAccountView-TabWrapper',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    width: '100%',
    margin: '0 auto',

    ...(() => {
      try {
        return styleImports[themeName]?.TabWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TTabOption = {
  isActive: boolean;
} & ButtonProps &
  any;
export const TabOption = chakra<TTabOption>(Button, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    label: 'toggleAccountView-TabOption',
    transition: 'all .1s ease-in-out',
    whiteSpace: 'nowrap',
    bg: 'transparent',
    borderRadius: '0',

    '&:hover, &:active': {
      background: 'blackAlpha.50',
      color: 'blackAlpha.900',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.TabOption?.({
          isActive,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});
