import { FlexProps, TextProps, TabProps } from '@chakra-ui/react';
import { styleImports } from './MarketLayout.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type MarketLayoutStylesSchema = Partial<{
  headerText: TextProps;

  rowWrapper: FlexProps;
  rowWrapperFirst: FlexProps;
  rowWrapperWithBtn: FlexProps;

  tab: TabProps;
}>;

export const marketLayoutStyles: MarketLayoutStylesSchema = {
  headerText: {
    label: 'marketLayout-headerText',

    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.headerText;
      } catch (err) {
        return {};
      }
    })(),
  },
  rowWrapper: {
    label: 'marketLayout-rowWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.rowWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  rowWrapperFirst: {
    label: 'marketLayout-rowWrapperFirst',

    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.rowWrapperFirst;
      } catch (err) {
        return {};
      }
    })(),
  },
  rowWrapperWithBtn: {
    label: 'marketLayout-rowWrapperWithBtn',

    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.rowWrapperWithBtn;
      } catch (err) {
        return {};
      }
    })(),
  },

  tab: {
    label: 'marketLayout-tab',

    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.tab;
      } catch (err) {
        return {};
      }
    })(),
  },
};
