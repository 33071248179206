import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: '100%',
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    px: ['2', null, '0'],
  },
};
