import { SelectionStylesSchema } from './Selections.styles';

export const selectionStyles: SelectionStylesSchema = {
  errorWrapper: {
    zIndex: 'dropdown',
    position: 'sticky',
    flexDir: 'column',
    alignItems: 'center',
    bottom: '0',
    left: ['0', null, 'unset'],
    w: 'unset',
    mx: '-2',
  },
};
