import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'alpha.500',
  borderRadius: 'base',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  color: 'white',
  fontWeight: 'bold',
  px: '2',
  py: '4',
  mb: '2',
};
