import { Box, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Warning } from 'styled-icons/entypo';

export default function Error() {
  return (
    <Box sx={{ py: '5', textAlign: 'center' }}>
      <Icon as={Warning} sx={{ boxSize: '5', mb: '3' }} />

      <Text sx={{ fontSize: 'xs', mb: '5' }}>
        <FormattedMessage id="generic.erroroccurred" />
      </Text>
    </Box>
  );
}
