import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/junglebet/images/backgrounds/backgroundErrorState.png';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: `url(${background})`,
  bgRepeat: 'no-repeat',
  bgSize: ['100% 100%', '100%'],
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  mx: 'auto',
  minH: [null, null, '668px'],
  maxW: 'container.lg',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  img: {
    mb: ['6', null, '0'],
  },
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Grimblocks',
  fontSize: ['6xl', null, '8xl'],
  mb: ['4', null, '6'],
  textTransform: 'uppercase',
  fontWeight: 'normal',
  color: 'beta.500',
  lineHeight: '0.96',
  textAlign: 'center',
  w: ['314px', '600px'],
  filter:
    'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.35)) drop-shadow(0px 4px 0px #A17600)',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  '&[data-ismaintenance="false"]': {
    w: ['243px', '545px'],
  },
};

export const ContentBodyText: CSSObject = {
  fontSize: ['sm', null, 'md'],
  mb: ['5', '4'],
  w: ['289px', '317px'],
  wordWrap: 'break-word',
  b: {
    color: 'gamma.500',
  },
  '&[data-ismaintenance="false"]': {
    w: ['232px', '250px'],
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'beta.500',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};
