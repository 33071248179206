import { CSSObject, TextProps } from '@chakra-ui/react';
import { primary } from '@/theme/sterlingparker/components/button';
import { CustomButtonProps } from '@/components/Button/Button';

export const CardNumber: CSSObject = {
  ml: '1',
};

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderRadius: 'base',
  boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.2)',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderColor: 'whiteAlpha.300',
  borderStyle: 'dashed',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};

export const IconButton: CSSObject = {
  ...primary,
  minWidth: '8',

  _after: {
    opacity: 0.4,
  },
};

export const TextMaxCards: CSSObject = {
  color: 'delta.100',
};

export const BoxInputContainer: CSSObject = {
  mb: '1',

  '.chakra-form-control': {
    mb: '0',
  },

  '& .chakra-input': {
    bg: 'gray.200',
    _placeholder: {
      color: 'beta.700',
      fontSize: 'xs',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'primary',
  height: '38px',
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: '120%',
  color: 'alpha.600',
};

export const bannerTextProps: TextProps = {
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: '120%',
  color: 'white',
};
