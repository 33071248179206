import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  mx: ['3.5', '4'],
  borderRadius: 'base',
  mb: '4',
};

export const Icon: CSSObject = {
  color: 'gamma.700',
  bg: 'gamma.500',
  borderRadius: 'base',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'medium',
};

export const TextNumber: CSSObject = {
  bg: 'beta.800',
  color: 'white',
  h: '4',
  py: '0',
  pt: '0.5',
  borderRadius: 'sm',
};

export const BoxHeading: CSSObject = {
  display: 'none',
};

export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    color: ['white', 'beta.900'],
  },
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    py: '0',
    mr: '0.5',
    color: 'alpha.800',
  },
};
