import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/goldenbet888/images/backgrounds/bg.png';

export const LocationNotAllowedContainer: CSSObject = {
  h: undefined,
  justifyContent: 'center',
  alignItems: 'center',
  minH: '100dvh',
  fontFamily: 'body',
  textAlign: 'center',
  bg: `url(${bg}) center/cover no-repeat`,
  color: 'gamma.200',
};

export const SubHeader: CSSObject = {};

export const CenterWrapper: CSSObject = {
  alignItems: 'center',
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  maxW: '500px',
  mb: [null, null, '12'],
  minH: [null, null, '480px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '0'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',

  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },
};

export const FlexHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'center',
};

export const ContentHeadingText: CSSObject = {
  fontFamily: 'accentMusashi',
  fontSize: ['xl', null, '32px'],
  color: 'gamma.600',
  lineHeight: 'none',
  textShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  mb: '2.5',
  mt: '8',
  w: ['100%', null, '80%'],
  textTransform: 'uppercase',
};

export const ContentBodyText: CSSObject = {
  fontSize: ['sm', null, 'md'],
  mb: '4',
  textAlign: 'center',
  fontWeight: 'semibold',
  '.chakra-link': {
    fontWeight: 'bold',
    color: 'gamma.600',
    fontSize: ['xs', null, 'sm'],
  },
  div: {
    fontSize: ['xs', null, 'sm'],
  },
};

export const TextBodyCopy: CSSObject = {
  lineHeight: '5',
  mt: '4',
  fontWeight: 'normal',
};

export const RegistrationContent: CSSObject = {
  fontWeight: 'normal',
  '.chakra-link': {
    textDecoration: 'none',
    color: 'gamma.200',
    fontWeight: 'normal',
  },
};

// export const TextLink: CSSObject = {
//   color: 'gamma.200',
//   textDecoration: 'none',
// };
