import { DepositSchema } from '@/views/account/Deposit/styles/Deposit.styles';

export const depositStyles: DepositSchema = {
  inputCardHolderNameProps: {
    sx: {
      '&&': {
        h: '10',
        fontSize: 'xs',
        px: '3',
        fontWeight: 'semibold',
        border: '1px solid',
        borderColor: 'rgb(0 0 0 / 10%)',
        borderRadius: 'base',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        bg: 'white',
        color: '#2D3748',
      },
    },
  },
};
