import {
  Box,
  ButtonProps,
  chakra,
  Checkbox,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { styleImports } from './Action.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import {
  Button as ButtonChakra,
  CustomButtonProps,
} from '@/components/Button/Button';

const themeName = getThemeName();

export type ExoticsActionSchema = Partial<{
  buttonExoticsActionProps: CustomButtonProps;
}>;

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    'data-testid': 'exoticsAction-buttonExoticsActionProps',

    ...styleImports[themeName]?.exoticsActionStyles?.buttonExoticsActionProps,
  },
};

export const HStackActions = chakra(HStack, {
  label: 'action-HStackActions',
  baseStyle: () => ({
    justifyContent: 'flex-end',
    py: ['1', '1'],
    gap: '1',
    flexDir: ['column-reverse', 'row'],
    alignItems: 'end',
    h: 'auto',

    ...(() => {
      try {
        return styleImports[themeName]?.HStackActions;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export type TButton = {
  isSelected: boolean;
  header?: boolean;
} & ButtonProps &
  any;
/**
 * @deprecated Use buttonExoticsActionProps instead, and add your theme into ButtonExoticsActionSwitched
 */
export const Button = chakra<TButton>(ButtonChakra, {
  label: 'action-Button',
  shouldForwardProp: (prop) => !['isSelected', 'header'].includes(prop),
  baseStyle: ({ theme, isSelected }: any) => ({
    w: '5',
    h: '5',
    fontSize: '10px',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: isSelected
        ? 'alpha.200'
        : 'linear-gradient(var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
      color: isSelected ? 'alpha.700' : 'white',
      '&&': {
        boxShadow: isSelected
          ? 'inset 0px 3px 4px var(--bc-colors-alpha-400)'
          : '0px 2px 5px rgba(0, 0, 0, 0.15)',
      },
      '&:hover': {
        bg: isSelected ? 'alpha.300' : 'alpha.800',
      },
      py: '1',
      boxSize: '30px',
      borderRadius: 'base',
      fontSize: 'sm',
      fontWeight: 'semibold',
      minWidth: 'unset',
      border: 'none',
      '&:disabled': {
        boxShadow: 'none',
        cursor: 'default',
        '&&': {
          color: 'gray.500',
          bg: 'blackAlpha.200',
        },
      },
      '&:disabled:hover': {
        bg: 'none',
      },
    }),
    ...(theme.themeName === EThemes.Vikingbet && {
      bg: isSelected
        ? 'alpha.200'
        : 'linear-gradient(180deg, #53D3FA 0%, #3EB0D3 100%)',
      color: isSelected ? 'alpha.700' : 'white',
      '&&': {
        boxShadow: isSelected
          ? '0px 2px 0px 1px rgba(0, 0, 0, 0.55) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 0px 0px rgba(0, 0, 0, 0.55) inset'
          : '',
      },
      '&:hover': {
        bg: isSelected ? 'alpha.300' : 'alpha.800',
      },
      py: '1',
      boxSize: '30px',
      borderRadius: 'base',
      fontSize: 'sm',
      fontWeight: 'semibold',
      minWidth: 'unset',
      border: 'none',
      '&:disabled': {
        boxShadow: 'none',
        cursor: 'default',
        '&&': {
          color: 'gray.500',
          bg: 'blackAlpha.200',
        },
      },
      '&:disabled:hover': {
        bg: 'none',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: isSelected
        ? 'beta.500'
        : 'linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 6.25%, #FFFFFF 100%)',
      color: isSelected ? 'white' : 'black',
      boxSize: '30px',
      borderRadius: 'base',
      boxShadow: '0px 4px 5px rgba(92, 102, 179, 0.15)',
      fontSize: 'sm',
      fontWeight: 'semibold',
      borderColor: isSelected ? 'accent.300' : 'gray.300',
      minWidth: 'unset',
      '&:hover': {
        bg: 'linear-gradient(180deg, #A3AAD8 0%, #717BC2 6.25%, #8C99EA 100%)',
        color: 'white',
      },
      '&:active, &:focus': {
        boxShadow: '0px 4px 5px rgba(92, 102, 179, 0.15)',
      },
      '&:disabled': {
        boxShadow: 'none',
        cursor: 'default',
        color: 'gray.500',
        border: '2px',
        borderColor: 'gray.300',
        borderRadius: 'sm',
        bg: 'none',
      },
      '&:disabled:hover': {
        bg: 'none',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Button?.({
          isSelected,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CheckboxRunnerBoxed = chakra<TButton>(Checkbox, {
  label: 'action-CheckboxRunnerBoxed',
  baseStyle: ({ theme }) => ({
    flexDirection: 'row-reverse',
    columnGap: '2',
    ...(theme.themeName === EThemes.Wellbet && {
      '.chakra-checkbox__control': {
        bg: 'white',
        color: 'gray.700',
        borderColor: 'gray.300',
        _hover: {
          bg: 'white',
        },
      },
      '.chakra-checkbox__label': {
        marginInlineStart: '0',
      },
      flexDir: 'column',
      gap: '2',
      boxSize: '30px',
      borderRadius: 'base',
      bg: 'blackAlpha.200',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    ...(theme.themeName === EThemes.Vikingbet && {
      '.chakra-checkbox__control': {
        bg: 'alpha.400',
        color: 'white',
        borderColor: 'alpha.700',
        borderWidth: '1px',
        width: '15px',
        height: '15px',
        _hover: {
          bg: 'alpha.400',
          borderColor: 'alpha.700',
          borderWidth: '1px',
        },
      },
      '.chakra-checkbox__label': {
        marginInlineStart: '0',
      },
      flexDir: 'column',
      gap: '2',
      boxSize: '30px',
      borderRadius: 'base',
      bg: 'blackAlpha.200',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.CheckboxRunnerBoxed;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexOdds = chakra(Flex, {
  label: 'action-FlexOdds',
  baseStyle: ({ theme }) => ({
    fontSize: 'sm',
    mr: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'xs',
      fontWeight: 'semibold',
      bg: 'blackAlpha.200',
      alignItems: 'center',
      w: '12',
      justifyContent: 'center',
      px: '1',
      py: ['1', null, '0.5'],
      minW: '10',
      textAlign: 'center',
      borderRadius: 'base',
      whiteSpace: 'nowrap',
      mr: '0',
      color: 'gray.600',
      h: 'full',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: ['xs', null, 'sm'],
      fontWeight: 'semibold',
      justifyContent: 'center',
      bg: 'beta.500',
      px: '1',
      py: ['1', null, '0.5'],
      minW: '10',
      h: ['6', null, '5'],
      textAlign: 'center',
      borderRadius: 'sm',
      color: 'white',
      alignSelf: 'end',
      mr: '0',
      mt: ['1.5', null, '2'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.FlexOdds;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ActionsContainer = chakra(Box, {
  label: 'action-ActionsContainer',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      display: 'flex',
      flexDir: 'row',
      gap: ['1', null, '2'],
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      display: 'flex',
      flexDir: 'row',
      gap: ['1', null, '2'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.ActionsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
