import React from 'react';
import { Img } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logo from '../../../assets/puntcity/images/logo.png';
import Heading from '../components/Heading';
import PCBody from '../components/PCBody';
import GlobalStyles from '../../../layouts/SecondaryLayout/conditionals/GlobalStyles/template';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: <Img src={logo} alt={REACT_APP_THEME_NAME} mt="5" w="221px" />,
    heading: <Heading />,
    desc: <PCBody />,
    body: <GlobalStyles />,
  },
};

export default template;
