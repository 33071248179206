/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import empty from '@/assets/fiestabet/images/backgrounds/betslip-empty3x.png';
import { BetSlipEmptyImage } from '../../styles/Empty.styles';

export const template: TTemplate = {
  wrapper: <Center />,
  empty: <BetSlipEmptyImage src={empty} />,
};

export default template;
