/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center, Image } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import empty from '../../../../../../../assets/templebet/images/betslip/empty.png';

export const template: TTemplate = {
  wrapper: <Center />,
  empty: (
    <Image
      src={empty}
      boxSize="40"
      m="4"
      borderRadius="lg"
      boxShadow=" 0px 4px 10px 0px var(--bc-colors-blackAlpha-500)"
    />
  ),
};

export default template;
