import { css } from 'styled-components';

export const dropdownWrapper = css`
  position: relative;
  margin-top: var(--bc-space-5);
`;

export const dropdownOption = css`
  cursor: pointer;
  padding: var(--bc-space-2) var(--bc-space-1);
  border-top: 1px solid grey;
  font-size: var(--bc-fontSizes-sm);
  background-color: ${({ theme }) => theme.basePalette.navyBlue};
  min-width: var(--bc-space-5);
  color: ${({ theme }) => theme.palette.white};
  text-transform: capitalize;

  &:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.basePalette.darkBlue};
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const optionsWrapper = css`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.inputBg};
  width: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 100;
`;

export const selectedOptionLabel = css`
  flex: 1;
  font-size: var(--bc-fontSizes-3xl);
  font-weight: 300;
  margin-right: var(--bc-space-5);
  text-transform: capitalize;
`;

export const dropdownControlIcon = css`
  width: var(--bc-space-5);
  height: var(--bc-space-5);
  cursor: pointer;
  color: ${({ theme }) => theme.palette.white};
`;

export const selectedOptionWrapper = css`
  cursor: pointer;
  min-height: 33px;
  background-color: ${({ theme }) => theme.basePalette.navyBlue};
  border-radius: 3px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  padding: var(--bc-space-1) 0;
  align-items: center;
`;
