import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'outline',
    fontSize: '2xs',
    height: 'auto',
    lineHeight: 'inherit',
    borderRadius: 'base',
    borderColor: 'white',
    px: '3',
    py: '1',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
    sx: { '&:active, &[data-active:"true"]': {} },
  },
};
