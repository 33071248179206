import { CSSObject } from '@chakra-ui/react';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'gray.600' },
  flexWrapper: {},
  checkbox: { sx: { '> span': { borderColor: 'gray.600' } } },
};

export const TabContainer: CSSObject = {
  border: '0',
};

export const TabWrapper: CSSObject = {
  bg: 'linear-gradient(180deg, #FFE0A6 0%, #E4C180 100%)',
  borderRadius: 'lg',
  boxShadow: '0 3px 8px rgba(0, 0, 0, .2)',
  p: '1.5',
  mt: '1',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  bg: 'transparent',
  borderRadius: 'md',
  boxShadow: 'none',
  color: 'alpha.600',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: 'none',
  textTransform: 'uppercase',
  _hover: {
    bg: 'transparent',
    color: 'alpha.600',
  },

  _focus: {
    boxShadow: 'none',
  },

  _active: {
    bg: 'alpha.600',
    boxShadow: 'none',
    color: 'white',
    textShadow: '0 2px 3px rgba(0, 0, 0, .2)',
    _hover: {
      bg: 'alpha.600',
    },
  },
};
