import { css } from 'styled-components';

// TODO: Remove
export const propositionTwoWrapper = css`
  justify-content: space-between;
  margin-top: var(--bc-sizes-2);
  padding: 0 var(--bc-sizes-5);

  ${({ theme }) => theme.device.laptop} {
    padding: 0;
  }
`;

export const propositionThreeWrapper = css`
  justify-content: space-between;
  margin-top: var(--bc-sizes-2);
  padding: 0 var(--bc-sizes-5);

  ${({ theme }) => theme.device.laptop} {
    padding: 0;
  }
`;

export const propositionFourWrapper = css`
  justify-content: space-between;
`;

export const propositionContainer = (
  isInBetslip: boolean,
  isOpen: boolean
) => css`
  background: var(--bc-colors-delta-500);
  padding: var(--bc-sizes-2);
  border-radius: var(--bc-radii-md);
  font-size: var(--bc-fontSizes-md);
  font-weight: var(--bc-fontWeights-bold);
  justify-content: center;
  transition: all, 0.1s linear;
  color: white;
  box-shadow: 0 var(--bc-sizes-1) 0 0 var(--bc-colors-epsilon-500);

  ${isOpen &&
  isInBetslip &&
  `
    background: var(--bc-colors-accent-500);
    color: var(--bc-colors-epsilon-500);

    &:hover {
      background: var(--bc-colors-accent-500);
    }
  `}

  ${isOpen &&
  !isInBetslip &&
  `
    &:hover {
      background: var(--bc-colors-epsilon-600);
    }
  `}

  ${!isOpen &&
  `
    text-decoration: crossed-out;
    opacity: 50%;
    cursor: default;
  `}

  &:disabled {
    box-shadow: none;
    cursor: default;
  }

  &:disabled:hover {
    background: var(--bc-colors-delta-500);
  }

  &:active {
    box-shadow: 0 var(--bc-sizes-0-5) 0 0 var(--bc-colors-epsilon-500);
    transform: translateY(4%);
  }
`;

export const propositionTwoContainer = css`
  width: 49%;
`;

export const propositionThreeContainer = css`
  flex: 1;
  &:nth-child(2) {
    margin: 0 var(--bc-sizes-2) 0 var(--bc-sizes-2);
  }
`;

export const propositionFourContainer = css`
  min-width: 125px;
`;

export const propositionTwoTitle = css`
  flex: 4;
  font-weight: 300;
  /* 2Layout */
  font-size: var(--bc-fontSizes-2xs);
  text-align: center;
  margin-bottom: var(--bc-sizes-1);
  font-style: none;
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const propositionThreeTitle = css`
  flex: 4;
  font-weight: 300;
  /* 3Layout */
  font-size: var(--bc-fontSizes-2xs);
  text-align: center;
  margin-bottom: var(--bc-sizes-1);
  font-style: none;
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const propositionFourTitle = css`
  flex: 4;
  color: white;
  font-weight: 400;
  /* 4Layout */
  text-align: left;
  font-size: var(--bc-fontSizes-xs);
  font-style: italic;
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const propositionRow = css`
  justify-content: space-between;
  padding: var(--bc-sizes-4) var(--bc-sizes-5);
  border-bottom: 1px solid ${({ theme }) => theme.palette.transparentBlue};

  ${({ theme }) => theme.device.laptop} {
    padding: var(--bc-sizes-4) 0;
  }
`;

export const propositionValue = css`
  text-align: center;
  font-size: var(--bc-fontSizes-md);
  font-family: ${({ theme }) => theme.fontFamily.body};
`;
