import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/Button/Button';

export type TMatchClosedDialogProps = Pick<
  AlertDialogProps,
  'isOpen' | 'onClose'
> & { matchName: string };

export default function MatchClosedDialog({
  isOpen,
  matchName,
  onClose,
  ...rest
}: TMatchClosedDialogProps) {
  const confirmRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={confirmRef}
      onClose={onClose}
      {...rest}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <FormattedMessage id="sports.marketsbymatch.matchclosedTitle" />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage
              id="sports.marketsbymatch.matchclosed"
              values={{ match: matchName }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={confirmRef}
              fontFamily="roboto"
              fontSize="xs"
              px="3"
              onClick={onClose}
            >
              <FormattedMessage
                id="sports.marketsbymatch.nomarketsbutton"
                values={{ sport: ' have a punt!' }}
              />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
