import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';
import pattern from '@/assets/goldenbet888/images/account/dragon.png';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'primary',
    px: '4',
    py: '2',
  },
};
export const FlexBannerContainer: CSSObject = {
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  borderRadius: 'md',
  bgImage: `url(${pattern}), linear-gradient(180deg, var(--bc-colors-alpha-300), var(--bc-colors-alpha-900))`,
  backgroundSize: '400px',
  backgroundPosition: 'top center',
  backgroundRepeat: 'repeat',
  color: 'white',
  gap: '0',
  p: ['3.5', null, '3'],
  minH: '107px',
};
export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
};
export const FlexAccountName: CSSObject = {
  fontSize: 'xl',
  fontFamily: 'accentMusashi',
  fontWeight: 'normal',
  color: 'gamma.200',
  textTransform: 'capitalize',
};
export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};
export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};
export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'accentStaat',
  textAlign: ['start', null, 'end'],
  color: 'gamma.200',
};
export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'accentStaat',
  fontWeight: 'normal',
  color: 'gamma.500',
  textTransform: 'uppercase',
};
export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'gamma.500',
});
export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'gamma.500',
});
export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'gamma.500',
});
export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
  borderTop: '1px solid',
  borderTopColor: 'whiteAlpha.300',
  pt: '2',
};
export const TextButton: CSSObject = {
  h: '4',
  fontFamily: 'accentStaat',
  pt: 'px',
  color: 'blackAlpha.700',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};
export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'gamma.500',
  color: 'gamma.500',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};
export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
};
