import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2.5',
  bg: 'white',
  border: 'none',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  flex: '1',
};

export const TypeItem: CSSObject = {
  color: 'gray.700',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: ['base', null, 'md'],
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'gray.100',
  '&:last-child': {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'gray.500',
  fontSize: 'sm',
};
