import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  color: 'white',
  fontWeight: 'medium',
  height: 'auto',
  border: 'none',
  mx: [2, 0],
  pb: 0,
  fontFamily: 'Roboto',

  '& .state-inprogress': {
    color: 'black',
  },
};

export const FlexHeadingMiscWrapper: CSSObject = {
  fontFamily: 'Roboto',
  zIndex: 'overlay',
  '.chakra-badge.state-inprogress': {
    color: 'black',
  },
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', 'row'],
    alignItems: ['flex-start', 'center'],

    '.timestamp': {
      bg: 'blackAlpha.500',
      borderRadius: 'base',
      color: 'white',
      fontWeight: 'bold',
      fontFamily: 'body',
      p: '1',
      fontSize: '2xs',
      mb: '0.5',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
  border: '1px',
  borderColor: 'white',
  bg: 'white',
};

export const TextSportName: CSSObject = {
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

export const TextVS: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
