import { CSSObject } from '@chakra-ui/react';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    borderRadius: 'md',
    color: 'white',
    border: '2px solid',
    borderColor: 'white',
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'epsilon.500',
};

export const ButtonKeys = (isBatch: boolean): CSSObject => ({
  color: 'blackAlpha.600',
  bgGradient: 'linear(180deg, beta.400, beta.700)',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'md',
  border: 'none',
  bg: 'none',

  ...(isBatch && {
    bgGradient: 'linear(180deg, alpha.400, alpha.600)',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
  }),
  '&.keypad-btn-done': {
    _disabled: {
      bgGradient: 'linear(180deg, alpha.300, alpha.600)',
      color: 'blackAlpha.600',
    },
    textTransform: 'uppercase',
    bgGradient: 'linear(180deg, alpha.300, alpha.600)',
    boShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    fontSize: 'md',
  },
});
