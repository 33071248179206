export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      bg: 'gray.100',
      color: 'black',
      caretColor: 'currentColor',
      border: '1px',
      borderColor: 'gray.200',

      '&::placeholder': { color: 'blackAlpha.500' },

      '&:focus, &:active, &:hover': { bg: 'gray.200' },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      color: 'black',
      '&&': { borderRightColor: 'blackAlpha.200' },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
    md: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
  },
  variants: {},
  defaultProps: {},
};
