import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '5',
  bg: 'alpha.200',
  borderRadius: 'sm',
  color: 'alpha.600',
  ml: '0.5',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
};

export const LinkRaceContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  color: 'white',
  borderRadius: 'base',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 1px #8ECEFF, 0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  fontFamily: 'Bowlby One',
  color: 'beta.400',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'md',
  textTransform: 'uppercase',
  fontWeight: 'normal',
};

export const TextRace: CSSObject = {
  fontFamily: 'Bowlby One',
  color: 'white',
  fontWeight: 'normal',
  fontSize: '2xs',
  textShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'beta.400',
  filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.55))',
};
