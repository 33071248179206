import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'unstyle',
    fontSize: '2xs',
    height: 'auto',
    lineHeight: 'inherit',
    borderRadius: 'md',
    px: '3',
    py: '1',
    textTransform: 'uppercase',
    bg: 'beta.700',
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
    border: '2px',
    color: 'white',
    _hover: {
      bg: 'white',
      borderColor: 'white',
      color: 'alpha.400',
      boxShadow: 'none',
      textShadow: 'none',
    },
    sx: { '&:active, &[data-active:"true"]': {} },
  },
};
