import { css } from 'styled-components';

export const container = css`
  margin-bottom: var(--bc-sizes-4);
`;

export const radio = css`
  border: ${({ theme }) => theme.borderStyles.primary};
  width: 15px;
  height: 15px;
`;

export const dot = css``;

export const dotActive = css`
  background-color: ${({ theme }) => theme.palette.primary};
  width: 9px;
  height: 9px;
  top: 2px;
  left: 2px;
`;

export const helpText = css`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S};
`;
