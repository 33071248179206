import React from 'react';
import { ExternalLink } from '@styled-icons/evaicons-solid';
import { Call } from '@styled-icons/ionicons-sharp';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import {
  BoxBordered,
  Link,
  SmallHeading,
  Text,
  ImageGamblersHelp,
  PageInfoWrapper,
  boxImageWrapperProps,
} from '../styles/ResponsibleGambling.styles';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG } from '@/constants/themeConfig';

import GamblersHelp from '../Assets/GamblersHelp.png';

const themeName = getThemeName();

export default function FindOutMore() {
  const [
    {
      Generic,
      Legal: { Responsible },
    },
  ] = getStrings();

  return (
    <BoxBordered>
      <PageInfoWrapper mb="6">
        <Flex flexDir="column">
          <Box pr="4">
            <SmallHeading>{Responsible.section.findOutMore.two}</SmallHeading>
            <Text>{Responsible.section.findOutMore.three}</Text>
          </Box>
          <Box pr="4" mt="3">
            <SmallHeading>
              <FormattedMessage id="legal.responsible.section.findOutMore.four.heading" />
            </SmallHeading>
            <Text>
              <FormattedMessage
                id="legal.responsible.section.findOutMore.four.description"
                values={{
                  cta: (string: string) => (
                    <Link
                      href="https://www.acma.gov.au/betstop-national-self-exclusion-registertm"
                      target="_blank"
                      display="inline-block"
                    >
                      {string}
                    </Link>
                  ),
                }}
              />
            </Text>
          </Box>
        </Flex>
        <Box {...boxImageWrapperProps}>
          <Link
            href="https://www.gamblinghelponline.org.au/"
            target="_blank"
            sx={{ display: 'inline-block' }}
          >
            <ImageGamblersHelp src={GamblersHelp} alt={Generic.GamblersHelp} />
          </Link>
        </Box>
      </PageInfoWrapper>

      <SmallHeading>{Responsible.titles.getInTouch}</SmallHeading>
      {themeName !== EThemesOG.Puntcity && (
        <Link href="https://responsiblegambling.vic.gov.au/" target="_blank">
          <Icon as={ExternalLink} boxSize="5" mr="2" />
          {Generic.GamblingVic}
        </Link>
      )}

      <Box css={{ marginTop: 'var(--bc-sizes-2)' }}>
        <Link href="https://www.gamblinghelponline.org.au/" target="_blank">
          <Icon as={ExternalLink} boxSize="5" mr="2" />
          {Generic.GamblersHelp}
        </Link>
      </Box>

      <Box css={{ marginTop: 'var(--bc-sizes-2)' }}>
        <Link href={`tel:${Generic.HelpLine}`} target="_blank">
          <Icon as={Call} boxSize="5" mr="2" />
          {Generic.HelpLine}
        </Link>
      </Box>
    </BoxBordered>
  );
}
