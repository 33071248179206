import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', null, 'base'],
  bg: 'white',
  mx: ['-2', '0'],
  pl: '2',
  pr: ['2', '0'],
  mt: '2',
  mb: '0',
  gap: '1',
  h: 'fit-content',

  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
  },
  '&.tabBar': {
    bg: 'alpha.700',
    borderRadius: ['inherit', 'lg', null],
    h: 'fit-content',
    mt: 2,
    color: 'white',
    '&:has(+ .subTabBar)': {
      borderBottomRadius: 'none',
    },
  },
  '&.subTabBar': {
    bg: 'gamma.300',
    color: 'alpha.800',
    borderBottomRadius: ['inherit', 'lg', null],
    borderTopRadius: 'none',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
    mt: 0,
    h: 'fit-content',
  },
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'quaddiesTab',
  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      border: 'none',
    },
    py: '2',
    '&&[data-active="true"]': {
      bg: 'gamma.300',
      borderBottom: 'none',
      h: '34px',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        bg: 'alpha.800',
        color: 'gamma.300',
        fontWeight: 'bold',
        fontSize: 'xs',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
      boxShadow: 'none',
    },
    '&.subTab': {
      color: 'gray.700',
      _hover: {
        '&&': {
          color: 'alpha.800',
          borderColor: 'alpha.800',
        },
      },
    },
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'alpha.500',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',
  _before: {
    content: "' '",
    height: '6px',
    width: '2px',
    backgroundColor: 'alpha.800',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },
  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'alpha.800',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};
