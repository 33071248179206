import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    w: '100%',
  },
  buttonCashOutDesc: {
    w: '100%',
    height: '29px',
    fontWeight: 'normal',
    fontSize: 'sm',
    textTransform: 'capitalize',
    color: 'alpha.400',
    sx: {
      '.cashoutValue': {
        color: 'alpha.400',
      },
      '&:hover': {
        '.cashoutValue': {
          color: 'alpha.900',
        },
      },
      '&:active': {
        '.cashoutValue': {
          color: 'alpha.400',
        },
      },
    },
  },
  buttonCashOutAmount: {
    variant: 'primary',
    height: '29px',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    _hover: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
      color: 'beta.900',
    },
  },
  flexOddsBoostBanner: {
    bg: 'beta.300',
    color: 'alpha.400',
  },
  textOddsBoost: { color: 'alpha.400', fontFamily: 'accent', pt: '0.5' },
  iconOddsBoost: {
    fill: 'alpha.400',
  },
  vstackBetsWrapper: {
    w: '100%',
    ml: 'inherit',
  },
  buttonGroupWrapper: {
    w: '100%',
  },
  iconCashOut: {
    fill: 'currentColor',
  },
  cashoutText: {
    color: 'currentColor',
    pt: '0.5',
  },
  cashoutValue: {
    lineHeight: 'normal',
    pt: '0.5',
  },
};
export const LinkVenueName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  ml: '26px',
};
export const RaceTime: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
  ml: '26px',
};
export const BonusBetCheckMark: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const BonusBetText: CSSObject = {
  color: 'alpha.400',
  fontSize: 'xs',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontFamily: 'accent',
  bg: 'beta.800',
  borderRadius: 'md',
  boxShadow:
    '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  px: '2',
  py: '4px',
  lineHeight: 'normal',
};
export const BetReturn: CSSObject = {
  bg: 'alpha.400',
  color: 'beta.700',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
};
export const BoxBetReturnValue: CSSObject = {
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
};
export const BoxBetReturnText: CSSObject = {
  my: '1',
  px: '1',
  borderRight: '1px solid',
  borderRightColor: 'beta.800',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  border: '2px solid',
  borderColor: 'white',
};
