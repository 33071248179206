import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    border: '2px',
    borderColor: 'beta.500',
    borderRadius: 'base',
    px: '2',
    h: '7',
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    sx: {},
  },
  breadcrumbLinkItem: {
    color: 'white',
    zIndex: '9999',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
  pr: '1.5',
};
