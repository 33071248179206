import React from 'react';
import { useIntl } from 'react-intl';

import {
  ButtonIncrease,
  buttonIncreaseProps,
  IncreaseAmountWrapper,
} from './styles/IncreaseAmountButton.styles';

const IncreaseAmount: React.FC<IncreaseAmountProps> = ({
  onChange,
  currentInputValue,
}) => {
  const intl = useIntl();

  const handleNumberPress = (plusAmount: number) => {
    const newVal = currentInputValue + plusAmount;

    onChange(newVal);
  };

  const ButtonData = [
    {
      label: intl.formatMessage({
        id: 'account.withdraw.increaseamountbutton.ten',
      }),
      onClick: () => handleNumberPress(10),
      key: '10',
    },
    {
      label: intl.formatMessage({
        id: 'account.withdraw.increaseamountbutton.twentyfive',
      }),
      onClick: () => handleNumberPress(25),
      key: '25',
    },
    {
      label: intl.formatMessage({
        id: 'account.withdraw.increaseamountbutton.fifty',
      }),
      onClick: () => handleNumberPress(50),
      key: '50',
    },
    {
      label: intl.formatMessage({
        id: 'account.withdraw.increaseamountbutton.hundred',
      }),
      onClick: () => handleNumberPress(100),
      key: '100',
    },
    {
      label: intl.formatMessage({
        id: 'account.withdraw.increaseamountbutton.twohundred',
      }),
      onClick: () => handleNumberPress(200),
      key: '200',
    },
    {
      label: intl.formatMessage({
        id: 'account.withdraw.increaseamountbutton.fivehundred',
      }),
      onClick: () => handleNumberPress(500),
      key: '500',
    },
  ];

  return (
    <IncreaseAmountWrapper>
      {ButtonData.map(({ label, onClick, key }) => (
        <ButtonIncrease
          key={key}
          onClick={onClick}
          data-cy={`add-${key}-button`}
          isFullWidth
          size="sm"
          {...buttonIncreaseProps}
        >
          {label}
        </ButtonIncrease>
      ))}
    </IncreaseAmountWrapper>
  );
};

interface IncreaseAmountProps {
  onChange: (value: number) => void;
  currentInputValue: number;
}

export default IncreaseAmount;
