import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  border: 'unset',
  pt: '3',
};

export const TabWrapper: CSSObject = {
  height: '8',
  overflowX: 'unset',
  p: '1',
  borderRadius: 'lg',
  gap: '1',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  border: 'unset',
  bg: isActive ? 'gamma.900' : 'transparent',
  borderRadius: 'lg',
  fontFamily: 'Montserrat',
  boxShadow: 'none',
  color: isActive ? 'white' : 'gamma.900',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: isActive ? '0 2px 3px rgba(0, 0, 0, .2)' : 'none',

  _hover: {
    opacity: isActive ? 'unset' : '0.6',
    border: 'unset',
    boxShadow: 'none',
    bg: isActive ? 'gamma.900' : 'transparent',
    color: isActive ? 'white' : 'gamma.900',
  },

  _focus: {
    boxShadow: 'none',
  },

  _active: {
    bg: 'gamma.900',
    boxShadow: 'none',
    color: 'white',
  },
});
