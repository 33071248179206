import { CSSObject } from '@chakra-ui/react';

export const WrapperFlexOnboarding: CSSObject = {
  w: ['100dvw', null, 'unset'],
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  h: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  w: ['full', null, '760px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, 'lg'],
  bg: 'white',
  boxShadow: [null, null, '0px 8px 30px 0px rgba(0, 0, 0, 0.20)'],
  pos: 'relative',
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['2', null, 'auto'],
  '#email': {
    bg: 'gray.50',
    boxShadow: 'unset',
    borderWidth: '1px',
    borderStyle: ' solid',
    borderColor: 'gray.300',
    color: 'gray.700',
    fontWeight: 'normal',
    borderRadius: 'lg',
    h: '38px',
    ':hover, :focus': {
      bg: 'gray.100',
      borderWidth: '1px',
      borderStyle: ' solid',
      borderColor: 'gray.400',
    },
    ':focus': {
      borderWidth: '2px',
      borderStyle: ' solid',
      borderColor: 'alpha.200',
      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },
  },
};

export const Title: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: '4',
};

export const Input: CSSObject = {
  borderRadius: 'lg',
  alignSelf: 'stretch',
  h: '38px',
  '&&': {
    bg: 'gray.50',
    boxShadow: 'unset',
    borderWidth: '1px',
    borderStyle: ' solid',
    borderColor: 'gray.300',
    color: 'gray.700',
    fontWeight: 'normal',
    '&&:hover, &&:focus': {
      bg: 'gray.100',
      borderWidth: '1px',
      borderStyle: ' solid',
      borderColor: 'gray.400',
    },
    '&&:focus': {
      borderWidth: '2px',
      borderStyle: ' solid',
      borderColor: 'blue.300',
      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },

    _placeholder: {
      color: 'gray.400',
    },
  },
};
