export const Checkbox = {
  parts: ['container', 'icon', 'control', 'label'],
  baseStyle: {
    control: {
      alignSelf: 'flex-start',
      transition: 'all .2s ease-in-out',
      bg: 'white',

      _hover: {
        opacity: 0.5,
      },
      _disabled: {
        borderColor: 'blackAlpha.200',
        bg: 'transparent',
      },

      _checked: {
        color: 'black',
        bg: 'white',
        borderColor: 'white',

        _disabled: {
          borderColor: 'blackAlpha.200',
          bg: 'transparent',
        },
        _hover: {
          borderColor: 'white',
          bg: 'white',
        },
      },
    },
    label: {
      color: 'gray.500',
    },
    container: {
      w: 'full',
      alignItems: 'flex-start',
    },
  },
  sizes: {
    lg: {
      label: { fontSize: 'sm' },
      icon: { fontSize: 'xs' },
    },
    md: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    sm: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    xs: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '3xs' },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
