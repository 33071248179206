import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '2',
  p: '2',
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  borderRadius: 'base',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.2)',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  variant: 'unstyled',
  color: 'white',
  bg: 'transparent',
  h: '6',
  fontWeight: 'bold',
  fontSize: '2xs',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  fontFamily: 'body',
  lineHeight: 'normal',
  _hover: {
    bg: 'alpha.600',
    color: 'white',
  },
  sx: {
    '&[aria-selected="true"]': {
      color: 'gamma.100',
      bg: 'gamma.500',
      boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 1) inset',
    },

    '&&': {
      borderRadius: 'base',
      px: '2',
      py: '1',
    },
  },
};
