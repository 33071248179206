import { CSSObject } from '@chakra-ui/react';

export const ButtonShowMore: CSSObject = {
  fontSize: 'xs',
  bgGradient: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  color: '#7A3B1E',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  border: '2px solid #A1741F',
  boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
  '&:hover, &:focus': {
    bgGradient: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
    color: '#7A3B1E',
  },

  _active: {
    bg: 'beta.700',
    color: 'white',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  },
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};

export const FlexBetCard: CSSObject = {};
