import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'sports.allsports.azheading': 'Sports',
  'generic.sportAZ': 'A-Z',
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading':
    'Like a fortune cookie yet to reveal its message.',
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your racing and sports selections.',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed.',
  'onboarding.register.createAccount': 'Create an account',
  'onboarding.step1.button': 'Join Now',
  'onboarding.locationnotallowed.header': "Wandering Off the Dragon's Trail.",
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing GoldenBet888 from outside of Australia',
  'maintenance.heading': 'Lighting the Lantern for Maintenance.',
  'error.heading': "Lost in the Dragon's Lair.",
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
};
