import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  display: 'flex',
  height: '24px',
  padding: '0px 9px',
  alignItems: 'center',
  gap: '4px',

  borderRadius: '5px',
  background:
    'var(--bc-gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.06) inset',
  border: 0,

  color: 'var(--bc-white, #FFF)',
  textAlign: 'center',
  fontSize: '10px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',

  '*': {
    transition: 'all .2s ease-in-out',
  },

  '&[aria-expanded="true"]': {
    svg: {
      transform: 'rotate(180deg)',
    },
  },
};

export const BoxDropdown: CSSObject = {
  borderRadius: '5px',
  background:
    'var(--bc-gradient-grey-light, linear-gradient(225deg, #4B5A6D 0%, #3F4953 100%))',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const TextTitle: CSSObject = {
  color: 'var(--bc-alpha-600, #D6A64A)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '100%',
};

export const CheckboxFilters: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
