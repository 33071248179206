import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  bg: state === 'inprogress' ? 'beta.600' : 'red.500',
  borderRadius: 'base',
});

export const TextTimestamp: CSSObject = {
  fontSize: '2xs',
};
