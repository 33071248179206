import { AnyAction, Store } from '@reduxjs/toolkit';
import React, { ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

type TRedux = {
  children: ReactNode;
  store: Store<any, AnyAction>;
};

export default function Redux({ children, store }: TRedux) {
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
}
