import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapperProps: BoxProps = {
  bg: 'beta.400',
  h: '100%',
  minW: '460px',
  display: 'flex',
  justifyContent: 'center',
  sx: {
    'div:first-child': {
      display: 'flex',
      flexDir: 'column',
      fontSize: 'xs',
      fontWeight: 'medium',
      alignItems: 'flex-start',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mb: '1',
      },
      '.chakra-link': {
        mb: '4',
        color: 'alpha.500',
        textTransform: 'unset',
        fontSize: 10,
      },
      p: {
        // color: 'gamma.200',
        fontFamily: 'accent',
        fontWeight: 'normal',
      },
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',
  '+ a': {
    display: 'none',
  },
};

export const linkLoginProps: CSSObject = {
  fontWeight: 'normal',
  color: 'alpha.400',
  textTransform: 'uppercase',
  fontSize: 'xs',
};

export const ForgotPasswordButton: CSSObject = {
  bgGradient: 'linear(to-b, beta.400 0%, beta.600 100%)',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.500',
  color: 'alpha.400',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 218, 47, 1) inset,0px 4px 6px 0px rgba(0, 0, 0, 0.3),0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset,0px 0px 0px 4px rgba(64, 44, 3, 1) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.600 0%, beta.600 100%)',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
    color: 'alpha.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'alpha.300',
    border: 'none',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    border: 'none',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },
  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};
