import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './PageButton.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type PageButtonSchema = Partial<{
  button: CustomButtonProps;
}>;

export const pageButtonStyles: PageButtonSchema = {
  button: {
    'data-testid': 'pageButton-button',

    ...(() => {
      try {
        return styleImports[themeName]?.pageButtonStyles?.button;
      } catch (err) {
        return {};
      }
    })(),
  },
};
