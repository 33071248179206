import { CSSObject } from '@chakra-ui/react';

export const BoxHeader: CSSObject = {
  pl: '6',
  pt: ['4', null, '10'],
};

export const CloseButton: CSSObject = {
  border: '2px',
  borderColor: 'white',
  color: 'white',
  p: '0.5',
  boxSize: '6',
  bg: ['transparent', null, 'white'],
  alignSelf: ['flex-start', null, 'unset'],
  mr: ['2', null, '6'],
};

export const LinkLogo: CSSObject = {
  mx: ['auto', null, 'unset'],
};
