import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ButtonLogIn, ButtonSignUp } from '../styles/Header.styles';
import BetSlipBanner from './BetSlipBanner';

/**
 * @deprecated The function should not be used anymore,
 * use AuthedV1 to template instead.
 */
export default function NotAuthButtons() {
  const navigate = useNavigate();
  return (
    <>
      <ButtonLogIn
        data-cy="HeaderLogin"
        variant="outline"
        onClick={() => navigate('/login')}
      >
        <FormattedMessage id="generic.login" />
      </ButtonLogIn>
      <ButtonSignUp data-cy="HeaderSignUp" onClick={() => navigate('/signup')}>
        <FormattedMessage id="generic.signup" />
      </ButtonSignUp>

      <BetSlipBanner />
    </>
  );
}
