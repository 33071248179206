import React from 'react';
import { Close } from '@styled-icons/material-sharp';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconCloseButton } from '../styles/OnboardingLayout.styles';

export default function CloseButton() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (pathname === '/deposit-limit') return null;

  return (
    <IconCloseButton
      as={Close}
      aria-label="close"
      zIndex="overlay"
      onClick={() => {
        if (navigate) navigate('/');
      }}
      variant="unstyled"
      cursor="pointer"
    />
  );
}
