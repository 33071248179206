import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './ActivityStatements.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FlexInner: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    flexDirection: 'column',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexInner;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextTitle: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    fontWeight: 'medium',
    mt: '2',
    mb: '1',
    color: 'beta.500',

    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextHeading: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'medium',
    mb: '3',

    ...(() => {
      try {
        return styleImports[themeName]?.TextHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextDescription: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    mb: '2',

    ...(() => {
      try {
        return styleImports[themeName]?.TextDescription;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextNoResults: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    textAlign: 'center',
    my: '10',
    ...(() => {
      try {
        return styleImports[themeName]?.TextNoResults;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
