import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordTooltip,
  TPasswordRule,
  TPasswordRulesIcon,
} from '../services/SignUp.types';

export const SignupAcknowledgementButton: CSSObject = {
  color: 'epsilon.700',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'epsilon.700',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'green.400' : 'blue.600',
  p: '3',

  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-green-400) !important'
      : 'var(--bc-colors-blue-600) !important',
  },
});

export const PasswordRulesContainer: CSSObject = {
  p: '0',
};

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  color: active
    ? ['green.400', null, 'white']
    : ['red.400', null, 'yellow.400'],
  fontSize: 'xs',
  opacity: '1',

  _last: {
    mb: '0',
  },
});

export const BoxManualAddressContainer: CSSObject = {
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: ['2', null, '4'],
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'normal',
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    direction={['column', null, 'row']}
    mb={['4', null, '0']}
    spacing={['0', null, '2']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="0.5" />
);

export const SignUpButton: CSSObject = {
  bg: 'alpha.500',
  border: '2px',
  borderColor: 'alpha.700',
  borderRadius: 'md',
  boxShadow: 'inset 0px 0px 0px 4px #426DD6',
};
