import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { StartTime } from '@/views/HomeDeprecated/components/NextToJumpList/styles/NextToJumpList.styles';
import { TNextToJumpRace } from '@/lib/DBModels';
import { getIconBySportName } from '@/helpers/utils';
import { formatTime, showCountdownTimer } from '@/lib/Time';
import {
  BoxHeading,
  BoxNextToJumpContainer,
  FlexWrapper,
  Icon,
  IconHeading,
  LinkContainer,
  TextNumber,
  TextRace,
} from './styles/NextToJumpItem.styles';
import { formatVenueNameNextToRace } from '../../../../../../lib/RaceNames';
import Countdown from '../../../../../../components/Countdown/Countdown';
import { raceNameMapping } from '@/helpers/sortRaces';
import IconSvg from '@/components/common/IconSvg';
import { raceTableStyles } from '../../../RaceTable/styles/RaceTable/RaceTable.styles';
import { TQueryPromotionsResponse } from '@/api/punter/deposit/deposit.types';

const NextToJumpItem: React.FC<NextToJumpListProps> = ({
  races,
  promotions,
}) => {
  function raceHasPromo(
    race_id: string,
    active_runner_count: number | undefined
  ) {
    const racePromotions = promotions?.map((promotion) =>
      promotion.race_ids?.includes(race_id) ? promotion : undefined
    );
    return racePromotions
      ?.map(
        (promotion) =>
          promotion &&
          active_runner_count &&
          !(
            promotion.min_active_runners &&
            active_runner_count &&
            promotion.min_active_runners > active_runner_count
          )
      )
      .some((value) => value);
  }
  return (
    <AnimatePresence exitBeforeEnter>
      <BoxNextToJumpContainer>
        {races.map((race, i, arr) => {
          const isTimerVisible = showCountdownTimer(race?.start_time);
          const url = `/racing/${race.race_type}/${race.venue_name}/R${race.race_number}?venueId=${race.venue_id}&raceId=${race.race_id}&meetingDate=${race.meeting_date}`;
          const isLast =
            i + 1 === arr.length
              ? true
              : race.race_type !== arr[i + 1].race_type;

          const icon = getIconBySportName(race.race_type);

          return (
            <>
              {!!race?.race_type &&
                (i === 0 || arr[i - 1].race_type !== race.race_type) && (
                  <BoxHeading>
                    <IconHeading name={icon} />
                    {raceNameMapping[race.race_type]}
                  </BoxHeading>
                )}
              <motion.div
                key={race.race_id}
                initial={{ translateY: -10, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
              >
                <LinkContainer to={url} isLast={isLast} data-cy="nextToJump">
                  <Icon name={icon} />
                  <TextRace noOfLines={1}>
                    {(() => {
                      if (
                        race.venue_country === 'AUS' ||
                        race.venue_country === 'NZL'
                      ) {
                        return race.venue_name?.toLowerCase();
                      }

                      return formatVenueNameNextToRace(race);
                    })()}
                  </TextRace>

                  <FlexWrapper>
                    {raceHasPromo(
                      race.race_id ?? '',
                      race.active_runner_count ?? undefined
                    ) && (
                      <IconSvg
                        name="money-back"
                        className="svgIcon"
                        {...raceTableStyles.iconSvgMoneyBackProps}
                        sx={{ mr: '3', boxSize: '6' }}
                      />
                    )}

                    {(() => {
                      if (race?.start_time) {
                        if (isTimerVisible) {
                          return (
                            <Countdown
                              eventTime={race.start_time}
                              sx={{ minH: '5', minW: '8' }}
                            />
                          );
                        }

                        return (
                          <StartTime>{formatTime(race.start_time)}</StartTime>
                        );
                      }

                      return null;
                    })()}

                    <TextNumber>{`R${race?.race_number ?? ''}`}</TextNumber>
                  </FlexWrapper>
                </LinkContainer>
              </motion.div>
            </>
          );
        })}
      </BoxNextToJumpContainer>
    </AnimatePresence>
  );
};

interface NextToJumpListProps {
  races: TNextToJumpRace[];
  promotions?: TQueryPromotionsResponse;
}

export default NextToJumpItem;
