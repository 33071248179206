import ThemeHome from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeHome, [
  // {
  //   name: 'homeLinks',
  //   component: <HomeLinks showOnHomeSports />,
  //   config: {
  //     after: 'toggle',
  //   },
  // },
]);
