import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  color: 'white',
  borderColor: 'blackAlpha.400',
  borderRight: 'none',
  borderStyle: 'solid',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  pl: '0',
  py: '2',
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'green.300' : 'white',
});

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};
