import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'alpha.500',
  borderTopRadius: 'lg',
  w: '100%',
  mt: ['unset', '4'],
  overflow: 'visible',
  fontSize: 'md',
  color: 'gamma.500',
  textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
  fontFamily: 'Angkor',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  pb: '0',
  py: ['2', '4'],
  borderLeft: '3px',
  borderRight: '3px',
  borderTop: '3px',
  borderStyle: 'solid',
  // @TODO Clarify with designers or add to colors.ts
  borderColor: '#E57D24',
  '&&': { px: ['2', '4'], pb: ['1', null, '2'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontFamily: 'Angkor',
  fontSize: 'xl',
  textTransform: 'capitalize',
  color: 'gamma.500',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
  mx: ['2', 'unset'],
  mt: ['2', 'unset'],
};

export const WrapperContainer: CSSObject = {
  bgGradient: 'linear(180deg, alpha.500 0%, alpha.600 100%)',
  px: ['2', '0'],
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderLeft: '3px',
  borderRight: '3px',
  borderBottom: '3px',
  borderStyle: 'solid',
  // #TODO Clarify with designers or add to colors.ts
  borderColor: '#E57D24',
  mb: '2',
  pb: ['2', '4'],
  pt: '2',
  mt: '-1',
};
