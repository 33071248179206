import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer: CSSObject = {
  py: '2',
  color: 'beta.500',
  fontWeight: 'semibold',
};

export const EventSubtitle: CSSObject = {
  color: 'blue.500',
  fontSize: '2xs',
  fontWeight: 'semibold',
  borderBottom: '2px solid white',
  px: '2',
  pb: '1',
  mx: '-2',
};

export const MultiCardWrapper: CSSObject = {
  bg: 'white',
  '&::before': {
    content: `""`,
    position: 'absolute',
    left: '0',
    top: '-31px',
    width: '100%',
    height: '31px',
    background:
      'linear-gradient(315deg,white 5px, transparent 0%),linear-gradient(-315deg,white 5px, transparent 0%)',
    backgroundSize: '13px 32px',
  },
  '&::after': {
    content: `""`,
    position: 'absolute',
    left: '0',
    bottom: '-31px',
    width: '100%',
    height: '31px',
    background:
      'linear-gradient(135deg,white 5px, transparent 0%),linear-gradient(-135deg,white 5px, transparent 0%)',
    backgroundSize: '13px 32px',
  },
};
