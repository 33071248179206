import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    boxShadow:
      '0px 3px 2px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    borderRadius: 'base',
    overflow: 'hidden',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    mb: '1px',
    _last: { mb: '0' },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
    bgGradient: 'linear(to-b, gamma.500, gamma.700)',
    _hover: { bgGradient: 'linear(to-b, gamma.700, gamma.700)' },
  },
  accordionHeadingText: {
    flex: '1',
    fontSize: 'sm',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  accordionIcon: {
    color: 'white',
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',

    _last: {
      border: 'none',
    },
  },
};
