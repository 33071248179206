import { IS_MOBILE_APP } from '../constants/isMobileApp';

export const postMobileAppMessage = (
  event: string,
  payload?: Record<string, any>
) => {
  if (!IS_MOBILE_APP || !window.ReactNativeWebView) {
    return;
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ event, ...payload }));
};
