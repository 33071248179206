import { CSSObject } from '@chakra-ui/react';

export const BoxHeader: CSSObject = {
  pl: '6',
  pt: ['4', null, '10'],
};

export const CloseButton: CSSObject = {
  border: '2px',
  borderColor: ['white', null, 'alpha.600'],
  color: ['white', null, 'alpha.600'],
  p: '0.5',
  boxSize: '6',
  bg: ['transparent', null, 'white'],
  alignSelf: ['flex-start', null, 'unset'],
  mr: ['2', null, '6'],
};

export const LinkLogo: CSSObject = {
  mx: 'unset',
  ml: ['6', '0', 'unset'],
  w: ['auto', null, '159px'],
};
