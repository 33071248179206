import { CSSObject } from '@chakra-ui/react';

/** To rename race types comming from BE */
export enum ERaceTypeDisplayNames {
  'Horse Racing' = 'Thoroughbreds',
  'Greyhounds' = 'Greyhounds',
  'Harness Racing' = 'Harness',
}

export type TListItem = {
  text: string;
  sx?: CSSObject;
  icon?: any;
  svg?: any;
  to?: string;
  sub?: boolean;
  dropDownIcon?: any;
  dropDownContainerSX?: CSSObject;
  isDropDown?: boolean;
  dropDownExpanded?: boolean;
  subs?: TListItem[];
  dataCy?: string;
  onClick?: () => Promise<unknown> | void;
};
