const branding = {
  alpha: {
    50: '#B4FF72',
    100: '#A0FF4E',
    200: '#94FD38',
    300: '#7EEE1C',
    400: '#6CD50F',
    500: '#36BF0F',
    600: '#3DA305',
    700: '#2D9D0C',
    800: '#26830B',
    900: '#1E6609',
  },
  beta: {
    50: '#FFF39C',
    100: '#FFEF76',
    200: '#FFEB54',
    300: '#FFE72E',
    400: '#FEDF02',
    500: '#FDD101',
    600: '#FDA703',
    700: '#A46B00',
    800: '#624809',
    900: '#392904',
  },
  gamma: {
    50: '#E7EDEE',
    100: '#CFDADE',
    200: '#9EB5BC',
    300: '#6E909B',
    400: '#3D6B79',
    500: '#0D4658',
    600: '#0A3846',
    700: '#082A35',
    800: '#051C23',
    900: '#030E12',
  },
  delta: {
    50: '#FFEDC8',
    100: '#E6C387',
    200: '#C39257',
    300: '#A3500F',
    400: '#814411',
    500: '#823E0B',
    600: '#8F3706',
    700: '#652900',
    800: '#541E01',
    900: '#321100',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  delta: branding.delta,
  epsilon: {},
  theta: {},
};
