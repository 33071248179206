/**
 * Injects a script URL into the react virtual dom to use external libs.
 *
 * @param scriptURL The url of the script to inject
 * @param asyncDefer Whether the script tag will be marked as aync/defer
 */
const injectScript = async (scriptURL: string): Promise<void> =>
  new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = scriptURL;

      script.addEventListener('load', () => resolve());
      script.addEventListener('error', (e: ErrorEvent) => {
        reject(e);
      });
      document.body.appendChild(script);
    } catch (e) {
      reject(e);
    }
  });

export const injectCSS = async (cssURL: string): Promise<void> =>
  new Promise((resolve, reject) => {
    try {
      const link = document.createElement('link');
      const head = document.getElementsByTagName('head')[0];

      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = cssURL;

      link.addEventListener('load', () => resolve());
      link.addEventListener('error', (e: ErrorEvent) => {
        reject(e);
      });
      head.appendChild(link);
    } catch (e) {
      reject(e);
    }
  });

export default injectScript;
