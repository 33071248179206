import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pt: ['8px', null, null, 0],
    pl: ['8px', null, null, '16px'],
    pr: [null, null, null, '10px'],
    h: ['fit-content', null, null, '44px'],
    display: 'flex',
    alignItems: 'center',
    overflowX: 'unset',
    whiteSpace: 'normal',
  },
  breadcrumbLinkItem: {
    fontSize: '10px',
    fontWeight: 'extrabold',
    color: 'white',
    textDecoration: 'none',
    fontFamily: 'ProximaNova',
    _hover: {
      textDecoration: 'underline',
    },

    sx: {
      '&:not([href])': {
        color: 'gamma.400',
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
