import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  p: '12',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  justifyContent: [null, null, 'center'],
  minW: ['unset', null, '460px'],
  maxW: ['100vw', null, null],
  bg: ['alpha.600', null, null],
  borderRightRadius: [null, null, '10px'],
  borderTop: ['2px', null, '0px'],
  borderColor: 'gamma.600',
  fontFamily: 'body',
  form: {
    '.chakra-form-control': {
      '.chakra-form__label': {
        color: 'gamma.200',
        fontSize: 'xs',
      },
    },
    'a[href*="forgot-password"]': {
      color: 'gamma.600',
      fontWeight: 'bold',
      fontSize: '10px',
    },
    '.chakra-form__error-message': {
      bg: 'gray.900',
      color: 'gray.200',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset',
      borderRadius: 'base',
      p: '1',
      fontWeight: 'medium',
      fontSize: 'xs',
    },
    'button[aria-label*="password toggle"]': {
      top: '-1px',
      color: 'gamma.600',
    },
    'input:-webkit-autofill, input:-webkit-autofill:focus': {
      transition: 'background-color 0s 600000s, color 0s 600000s',
    },
    '#email': {
      borderRadius: 'md',
      color: 'white',
      bg: 'alpha.700',
      fontSize: 'xs',
      fontWeight: 'normal',
    },
  },
};

export const Title: CSSObject = {
  color: 'gamma.200',
  fontSize: 'normal',
  fontFamily: 'accentMusashi',
  fontStyle: 'normal',
  textTransform: 'uppercase',
  mb: '4',
  mt: [null, null, '9'],
};
