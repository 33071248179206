import { CSSObject } from '@chakra-ui/react';
import featuredBg from '@/assets/junglebet/images/homeFeaturedRacing/featureBackground.png';
import cardsBg from '@/assets/junglebet/images/backgrounds/backgroundSport.png';
import leaves from '@/assets/junglebet/images/homeFeaturedRacing/featureLeaves3x.png';
import flower from '@/assets/junglebet/images/homeFeaturedRacing/featureFlower3x.png';

export const FlexFeatureWrapper: CSSObject = {
  bg: `transparent`,
  py: '0',
  minH: [null, '350px'],
  px: 0,
  flexDir: ['column', null, 'row'],
  mb: '5',
};

export const FlexFeatureCardWrapper: CSSObject = {
  py: ['3', null, '6'],
  px: ['2', null, '9'],
  pos: 'relative',
  _before: {
    bgImg: `url(${leaves})`,
    h: '90%',
    w: '100%',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    left: '0',
    right: '0',
    top: '9px',
    zIndex: '1',
    display: ['none', null, null, 'block'],
  },
  _after: {
    bgImg: `url(${flower})`,
    h: '30%',
    w: '20%',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    right: '0',
    top: '-25px',
    zIndex: '3',
    display: ['none', null, null, 'block'],
  },
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flex: 2,
  flexDir: 'row',
  alignItems: 'flex-start',
  gap: '2.5',
  pos: 'relative',
  minH: '64',
  _after: {
    content: `''`,
    bgImage: `url(${featuredBg})`,
    display: 'block',
    maxW: 'full',
    maxH: ['52', '294px'],
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    bgPos: 'bottom',
    pos: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: '25%',
    zIndex: '-1',
  },
};

export const TextFeatureHeading: CSSObject = {
  display: 'flex',
  mb: 0,
  fontFamily: 'Grimblocks',
  fontSize: ['65px', null, '90px'],
  fontWeight: 'normal',
  textTransform: 'uppercase',
  bgGradient: 'linear(to-b, beta.400, beta.600 100%)',
  backgroundClip: 'text',
  filter:
    'drop-shadow(0px 4px 0px var(--bc-colors-beta-700)) drop-shadow(0px 6px 3px rgba(0, 0, 0, 0.35))',
};

export const TextFeatureSubHeading: CSSObject = {
  fontFamily: 'Grimblocks',
  mb: 0,
  fontSize: ['65px', null, '90px'],
  fontWeight: 'normal',
  bgGradient: 'linear(to-b, alpha.400, alpha.600 100%)',
  textTransform: 'uppercase',
  backgroundClip: 'text',
  filter:
    'drop-shadow(0px 4px 0px var(--bc-colors-alpha-800)) drop-shadow(0px 6px 3px rgba(0, 0, 0, 0.35))',
};

export const BoxCard: CSSObject = {
  borderRadius: '2xl',
  maxW: ['unset', null, '308px'],
  bg: `url(${cardsBg})`,
  bgRepeat: 'repeat',
  bgSize: 'cover',
  zIndex: '2',
  boxShadow:
    '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 5px 0px 0px var(--bc-colors-delta-800), 0px 2px 2px 0px #D17917 inset, 0px -2px 1px 0px rgba(178, 78, 5, 0.50) inset',
};

export const LinkCardHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  px: '2.5',
  pt: '2',
  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'beta.800',
    borderRadius: 'base',
    maxW: '10',
  },
  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
    maxW: '10',
  },
  '.chakra-badge.state-ended': {
    bg: 'gamma.400',
    color: 'white',
    borderRadius: 'base',
    maxW: '10',
  },
};

export const IconCardHeader: CSSObject = {
  alignItems: 'center',
  color: 'delta.50',
  boxSize: '5',
};

export const TextCardHeader: CSSObject = {
  fontFamily: 'CarterOne',
  fontSize: 'sm',
  fontWeight: 'normal',
  letterSpacing: 'wide',
  color: 'delta.50',
  lineHeight: 'normal',
};

export const TextRaceNumber: CSSObject = {
  bg: 'delta.700',
  borderRadius: 'base',
  color: 'white',
  // Need to be 9px
  fontSize: '9px',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'delta.50',
  fontSize: '2xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: 'normal',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 4px -3px rgba(0, 0, 0, 0.30) inset',
  ':first-child': {
    pl: '3',
  },
};

export const ImageRunnerSilk: CSSObject = {
  borderRadius: 'base',
};

export const FlexGridRunnerItem: CSSObject = {
  px: '2.5',
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(206, 119, 22, 0.30)',
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'delta.50',
  fontWeight: 'semibold',
};

export const TextRunnerNumber: CSSObject = {
  fontWeight: 'semibold',

  '&:last-child': {
    color: 'delta.50',
    fontSize: '2xs',
    fontWeight: 'semibold',
    bottom: '2px',
    position: 'relative',
  },
};

export const LinkRaceDetails: CSSObject = {
  color: 'beta.500',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'capitalize',
  fontSize: 'md',
  fontFamily: 'CarterOne',
  fontWeight: 'normal',
  borderBottomRadius: 'md',
  letterSpacing: 'wide',
  pt: '2.5',
  _hover: {
    bg: 'blackAlpha.500',
  },
  _focus: {
    bg: 'blackAlpha.500',
  },
  _disabled: {
    bg: 'gray.300',
    borderColor: 'gray.600',
    color: 'blackAlpha.200',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.60) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  },
};
