import { CSSObject } from '@chakra-ui/react';
import { TIconSelected, TCircularNavButton } from './RaceMeetings.styles';

export const RaceMeetingsList: CSSObject = {
  px: '0',
};

export const CircularNavButton = ({
  selected,
}: TCircularNavButton): CSSObject => ({
  mr: '4',
  color: selected ? 'beta.500' : 'gray.400',
});

export const IconRace: CSSObject = {
  color: 'currentColor',
};

export const IconSelected = ({ selected }: TIconSelected): CSSObject => ({
  bg: selected ? 'beta.500' : 'gray.400',
  color: selected ? 'white' : 'transparent',
});

export const NextToJumpListWrapper: CSSObject = {
  mx: '0',
};
