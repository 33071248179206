import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { keys } from '../../../../api/api.keys';
import { queryLocation } from '../../../../api/location/location';
import { logError } from '@/helpers/utils';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';

/**
 * Hook that checks the location to ensure the user is from a
 * whitelist country, if not, navigate to location not allowed route.
 */
export const useLocation = (locationPage?: boolean) => {
  const navigate = useNavigate();

  const { data } = useQuery([keys.location], queryLocation, {
    enabled: !IS_MOBILE_APP,
    refetchInterval: Infinity,
    staleTime: Infinity,
    onError(error) {
      logError(error);
    },
  });

  useEffect(() => {
    if (!IS_MOBILE_APP && !!data?.networkErr) {
      throw new Error('💥 CABOOM 💥');
    }
    if (!IS_MOBILE_APP && data?.isAustralian === false) {
      navigate('/location-not-allowed', { replace: true });
    }
    if (data?.isAustralian && locationPage) {
      navigate('/', { replace: true });
    }
  }, [data, locationPage, navigate]);
};
