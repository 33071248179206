import { css } from 'styled-components';

export const backIcon = css`
  width: var(--bc-sizes-5);
  height: var(--bc-sizes-5);
  margin-right: var(--bc-sizes-4);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const matchesHeadingContainer = css`
  margin-top: var(--bc-sizes-6);
`;

export const matchesHeading = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-style: italic;
  font-size: var(--bc-fontSizes-3xl);
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const matchesContainer = css`
  row-gap: var(--bc-sizes-2);
  column-gap: var(--bc-sizes-5);

  ${({ theme }) => theme.device.tablet} {
    max-width: 760px;
    width: 70vw;
    flex-wrap: wrap;
    row-gap: var(--bc-sizes-3);
  }
`;
