import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    bg: 'epsilon.400',
    borderRadius: 'base',
    p: '2',
    border: '2px',
    borderColor: 'white',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    sx: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
      },
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    spacing: '0',
  },
  breadcrumbLinkItem: {
    textDecoration: 'underline',

    _hover: {
      textDecoration: 'none',
    },

    sx: {
      '&:not([href])': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  display: 'block',
  m: 0,
  color: 'epsilon.500',
  mx: '1',
};
