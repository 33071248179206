import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    fontSize: '2xl',
    fontFamily: 'Chewy',
    textShadow: '0px 1px 4px rgba(0, 0, 0, 0.50)',
    color: 'gamma.400',
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  navOverride: {
    mb: '0',
  },
  divider: {
    mb: '0',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
};
