import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/titanbet/images/feature/featSportBg.png';
import featuredMb from '@/assets/titanbet/images/feature/featSportBgMb.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  border: 'none',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  borderRadius: 'lg',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(193, 146, 98, 1) inset, 0px 0px 0px 5px rgba(27, 30, 31, 1) inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'gamma.300',
  fontSize: 'md',
  fontWeight: 'normal',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'gamma.300',
  fontFamily: 'body',
  boxShadow: 'unset',
};

export const TextMisc: CSSObject = {
  bgGradient: 'linear(to-b, alpha.200, alpha.300)',
  boxShadow:
    '0px 0px 0px 1px #4A5454 inset, 0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  color: 'beta.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  fontSize: '2xs',
  fontWeight: 'bold',
  borderBottom: 'none',
  span: { display: 'inline-block', color: 'blackAlpha.500', mx: '1' },
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontFamily: 'accent',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  pt: '1',
};

export const BoxHeadingWrapper: CSSObject = {
  fontFamily: 'Staatliches',
  fontSize: '3xl',
  fontWeight: 'normal',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  color: 'beta.500',
};

export const puntButtonProps: CSSObject = {
  variant: 'solid',
  pt: '1',
  borderRadius: 'md',
  fontSize: 'sm',
  fontWeight: 'normal',
};

export const HeadingSlogan: CSSObject = {
  fontFamily: 'accent',
  color: 'white',
  textTransform: 'capitalize',
  alignItems: 'center',
  fontSize: '30px',
  bgGradient: 'linear(to-b, #D1D1D1 0%, #838383 100%)',
  backgroundClip: 'text',
  filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))',
};
