import { SelectionStylesSchema } from './Selections.styles';

export const selectionStyles: SelectionStylesSchema = {
  selectionWrapper: {
    justifyContent: 'space-between',
    alignItems: 'end',
    color: 'gray.700',
    bg: 'gray.100',
    w: 'full',
  },
  errorWrapper: {
    zIndex: 'dropdown',
    position: 'sticky',
    flexDir: 'column',
    alignItems: 'center',
    bottom: '0',
    left: ['0', null, 'unset'],
    w: ['100%', null, 'unset'],
  },
  footerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'end',
    w: 'full',
    gap: '3',
    p: '4',
  },
  runnerContainer: {
    my: '2',
    w: '96',
    display: 'flex',
    alignItems: 'space-between',
    color: 'gray.500',
    fontWeight: 'semibold',
    justifyContent: 'space-between',
  },
  runnerText: {},
  clearSelectionText: {},
  expandContainer: {
    flex: '1',
    textAlign: 'left',
  },
  priceBox: {
    color: 'gray.600',
    mt: '.5',
  },
};
