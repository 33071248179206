import React from 'react';
import Aside from '../../components/Aside/Aside';
import Content from '../components/Content/Content';
import {
  WrapperOnboarding,
  WrapperContent,
} from '../../styles/onboarding.styles';
import CloseBtn from '../../components/CloseBtn/CloseBtn';
import Title from '../components/Title/Title';
import { wrapperProps } from '../styles/ForgotPassword.styles';

export const TemplateForgotPassword = [
  { name: 'wrapper', component: <WrapperOnboarding /> },
  { name: 'aside', component: <Aside /> },
  {
    name: 'content',
    component: (
      <WrapperContent {...wrapperProps}>
        <Title />
        <Content />
      </WrapperContent>
    ),
  },
  { name: 'closeBtn', component: <CloseBtn /> },
];

export default TemplateForgotPassword;
