import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flexEventContainerProps: FlexProps = {
  gap: '1',
};

export const flexEventProps: FlexProps = {
  gap: '1',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};

export const MultiCardWrapper: CSSObject = {
  borderRadius: 'md',
  p: '2',
  color: 'white',
  mt: '0',
  bg: 'alpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.20), 0px 0px 4px 0px rgba(0, 0, 0, 0.30) inset',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'beta.400',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  bg: 'alpha.400',
  mb: '6',
  color: 'white',
  alignItems: 'center',
  fontWeigth: 'medium',
  gap: '1',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.20), 0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset',
};

export const EventTitle: CSSObject = {
  color: 'beta.400',
};

export const InvalidityBox: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'medium',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};
