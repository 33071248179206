import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'gamma.500',
  fontSize: 'sm',
  fontWeight: 'normal',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
  fontFamily: 'Bungee',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: '#733924',
  minW: 'unset',
  bgGradient: 'linear(to-b, beta.400, #D36A11)',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, #B16524, #D36A11)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
    color: '#733924',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '2',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontFamily: 'roboto',
  fontWeight: 'bold',
};
