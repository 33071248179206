import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { spacing: '1' },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear(to-b, alpha.300, alpha.700)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: 'base',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    fontSize: '2xs',
    fontWeight: 'bold',
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    color: 'white',
    sx: {
      '&.active': {
        textShadow: 'none',
        bg: 'gray.300',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset',
        color: 'gray.600',
        fontWeight: 'bold',
        fontSize: '2xs',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
        svg: {
          color: 'yellow.800',
        },
      },
      svg: {
        color: 'yellow.400',
      },
    },
  },
};
