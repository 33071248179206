import { useQueriesNTJ } from '../../../../../api/nextToJump/nextToJump.hooks';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { sortByRaceType } from '@/helpers/sortRaces';
import { TNextToJumpRace } from '../../../../../lib/DBModels';
import { EFilters } from '../../../../../redux/ntjFilters.slice';

/**
 * Hook that queries the NTJ data
 */
export const useNextToJumpQueries = () => {
  const { filters: raceTableFilters } = useAppSelector(
    (state) => state.raceTable
  );

  const queries = useQueriesNTJ(
    raceTableFilters.map((f) => ({
      params: { race_type: f, limit: 66 },
    }))
  );

  const sortQueries = [...(queries ?? [])].sort((a, b) =>
    sortByRaceType(a.data?.[0]?.race_type, b.data?.[0]?.race_type)
  );

  return sortQueries;
};

/**
 * Hook to use the next to jump data
 */
export const useNextToJump = (races: TNextToJumpRace[]) => {
  const { filters } = useAppSelector((state) => state.ntjFilters);
  const activeFilters = Object.keys(filters ?? {}).filter(
    (k) => filters?.[k as unknown as EFilters]
  ) as unknown as EFilters[];

  const racesTime = [...races].sort(
    (a, b) =>
      new Date(a?.start_time ?? '').getTime() -
      new Date(b?.start_time ?? '').getTime()
  );

  return racesTime.filter((r) => {
    if (r && r.venue_country) {
      if (
        activeFilters.includes(EFilters.Aus) &&
        activeFilters.includes(EFilters.Intl)
      ) {
        return true;
      }

      if (activeFilters.includes(EFilters.Aus)) {
        return r.venue_country === 'AUS' || r.venue_country === 'NZL';
      }

      if (activeFilters.includes(EFilters.Intl)) {
        return r.venue_country !== 'AUS' && r.venue_country !== 'NZL';
      }
    }

    return false;
  });
};
