import { useSearchParams } from 'react-router-dom';

/**
 * Hook that toggles the race/sport view
 */
export const useCategory = () => {
  const [searchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const showRacing = !category || category === 'racing';
  const showSport = category === 'sport';

  return {
    showRacing,
    showSport,
  };
};
