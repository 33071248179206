import { CSSObject } from '@chakra-ui/react';
import borderTop from '@/assets/fiestabet/images/backgrounds/border-top3x.png';
import borderBottom from '@/assets/fiestabet/images/backgrounds/border-bottom3x.png';

export const GridItemBetslip: CSSObject = {
  position: 'relative',
  h: '98%',
  '& > div[class*="-BetSlipModalContainer"]': {
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '97%',
  },
  padding: '13px',
  '&::before': {
    content: `""`,
    position: 'absolute',
    left: '0',
    top: '0',
    backgroundImage: `url(${borderTop})`,
    height: '60px',
    width: '100%',
    bgRepeat: 'round',
    zIndex: '1',
  },
  '&::after': {
    content: `""`,
    position: 'absolute',
    left: '0',
    bottom: '2',
    backgroundImage: `url(${borderBottom})`,
    height: '40px',
    width: '100%',
    bgRepeat: 'round',
  },
};
