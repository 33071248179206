import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  ':last-of-type': {
    pb: '2',
  },
};
export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  borderBottomLeftRadius: 'none',
  borderBottomRightRadius: 'none',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  textTransform: 'uppercase',
  mb: '1',
};
export const EachWayText: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
};
export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px solid',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
};
export const ReviewStakeText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'white',
};
export const ReviewStake: CSSObject = {
  fontSize: 'sm',
  color: 'alpha.400',
  fontWeight: 'black',
};
export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
};
export const ReviewEst: CSSObject = {
  fontSize: 'lg',
  color: 'alpha.400',
  fontWeight: 'black',
};
export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'bold',
  fontSize: 'sm',
  color: 'gamma.800',
  bgGradient: 'linear(to-b, alpha.100, alpha.500)',
  border: 'none',
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
  h: '38px',
});
export const InputBetSlip: CSSObject = {
  border: '2px solid',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  h: '38px',
  pl: '2.8rem',
  ':first-child': {
    pl: '2',
  },
  '&&': {
    borderRightRadius: 'md',
    borderColor: 'alpha.300',
    borderLeft: '2px solid',
    borderLeftColor: 'alpha.500',
    borderLeftRadius: 'md',
    bg: 'beta.700',
  },
  _hover: {
    bg: 'beta.600',
  },
  _focus: {
    bg: 'beta.600',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
};
export const FlexiReview: CSSObject = {
  pl: '8',
};
export const FlexReviewExotic: CSSObject = {
  textAlign: 'left',
  "[class$='ReviewStake']": {
    color: 'beta.100',
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'alpha.400',
};
