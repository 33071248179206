import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { Stop } from '@styled-icons/octicons/Stop';

export const EstReturnsLabel: CSSObject = {
  bg: 'alpha.800',
  color: 'white',
  fontWeight: 'bold',
  height: '100%',
  alignItems: 'center',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.700, alpha.800)',
  borderRadius: 'md',
  w: 'full',
  color: 'gamma.500',
  fontWeight: 'bold',
  fontStyle: 'normal',
  justifyContent: 'center',
  textShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.35)',
};

export const BetApprovalText: CSSObject = {
  fontSize: 'xs',
};

export const BetPendingApprovalText: CSSObject = {
  fontSize: 'xs',
};

export const BetRejectedText: CSSObject = {
  fontSize: 'xs',
};

export const ApprovedBetContainer: CSSObject = {
  bg: 'gamma.400',
  color: 'gamma.900',
  borderRadius: 'md',
  textShadow: '1px 1px 0px rgba(255, 255, 255, 0.35)',
  fontWeight: 'bold',
  w: 'full',
  justifyContent: 'center',
  boxShadow: 'none',
  fontSize: '2xs',
};

export const RejectedBetContainer: CSSObject = {
  bg: 'red.400',
  color: 'red.900',
  borderRadius: 'md',
  w: 'full',
  textShadow: '1px 1px 0px rgba(255, 255, 255, 0.35)',
  justifyContent: 'center',
  fontWeight: 'bold',
  boxShadow: 'none',
  fontSize: '11px',
};

export const FooterContainer: CSSObject = {
  bg: 'alpha.50',
  color: 'alpha.800',
  px: '0',
  h: '6',
  border: '1px',
  fontSize: '2xs',
  borderColor: 'alpha.800',
  overflow: 'hidden',
  borderRadius: 'md',
  boxShadow: 'inset 0px 0px 5px rgba(12, 30, 90, 0.6)',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
};

export const IconApprovedBet = () => (
  <Icon as={CheckCircle} boxSize="4" mr="2" />
);

export const IconRejectedBet = () => <Icon as={Stop} boxSize="4" mr="2" />;
