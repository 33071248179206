import { CSSObject } from '@chakra-ui/react';
import { Receipt } from '@styled-icons/fa-solid';

export const BetslipHeaderIcon: CSSObject = {
  color: 'beta.800',
};

export const CloseIconButton: CSSObject = {
  color: 'beta.800',
  svg: {
    boxSize: '7',
  },
};

export const FlexBetslipHeading: CSSObject = {
  minH: ['31px', null, null, '31px', '31px'],
  borderBottomColor: 'whiteAlpha.500',
  borderBottom: '1.5px',
  mx: '1.5',
  px: '2.5',
  pt: '2',
  pb: '4',
  mt: '2',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1.5px',
    borderColor: 'blackAlpha.200',
    borderStyle: 'dashed',
  },
};

export const BetslipTitle: CSSObject = {
  fontSize: [null, null, null, 'lg', 'lg'],
  color: 'beta.800',
};

export const BetslipTitleText: CSSObject = {
  fontFamily: 'accent',
  fontWeight: 'hairline',
  textTransform: 'uppercase',
  color: 'beta.800',
  fontSize: 'lg',
  fontStyle: 'normal',
  lineHeight: '100%',
};

export const BetLengthIndicator: CSSObject = {
  ml: '2',
  color: 'beta.500',
  bg: 'beta.800',
  fontSize: 'sm',
  borderRadius: 'sm',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
  lineHeight: '11px',
};

export const headerIcon = Receipt;
