import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    bg: 'blackAlpha.500',
    w: ['100% + 8px', null, '100%'],
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
    borderRadius: ['none', null, 'md'],
    h: '8',
    px: '4',
    mx: ['-2', null, '0'],
    my: '3',
    pb: '0',
  },
  link: {
    boxShadow: 'none',
    border: 'none',
    fontSize: 'xs',
    borderRadius: 'none',
    fontFamily: 'SilvanoWestern',
    lineHeight: '32px',
    h: 8,
    color: 'alpha.300',
    borderBottom: '2px solid',
    borderBottomColor: 'transparent',
    textTransform: 'uppercase',
    px: '3',
    sx: {
      '&.active': {
        border: 'none',
        borderBottom: '2px solid',
        color: 'gamma.300',
        borderBottomColor: 'gamma.300',
      },
      '&&': {
        textDecoration: 'none',
      },
    },
  },
};
