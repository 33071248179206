import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    pt: '0',
    mb: '0',
    h: 'unset',
    ...(!IS_MOBILE_APP && {
      pb: '5',
    }),
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: () => '5',
      },
    },
  },
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['2', null, 'unset'],
  },
  textTitle: {
    mt: '0',
    ...(IS_MOBILE_APP && {
      fontSize: 'md',
    }),
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderRadius: 'md',
        pb: '2.5',
        bg: 'white',
        flexDir: 'column',
      },
    },
  },
  titleContainer: {
    w: 'full',
    bg: 'white',
    pl: '0',
    py: '2',
    pr: '0',
    borderTopRadius: 'md',
    h: 10,
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
