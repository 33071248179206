import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/bet777/images/backgrounds/onboardingAside.jpg';
import bgMobile from '@/assets/bet777/images/backgrounds/onboardingAsideMb.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${bgMobile})`,
    null,
    `url(${bgDesktop}), var(--bc-colors-alpha-600)`,
  ],
  bgSize: ['cover', null, '100%, auto'],
  bgPos: 'center, center calc(100% - 76px)',
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['beta.500', null, 'unset'],
  h: ['50vw', '55vw', 'full'],
  borderLeftRadius: [null, null, '10px'],
  borderRightRadius: ['unset'],
  pt: ['4', null, '6'],
  flex: 1,
};
