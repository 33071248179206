import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import {
  generateNewBetSlipBet,
  validateBets,
} from '../components/Betslip/Services/Betslip.utils';
import {
  TBetSlipState,
  TBetSlipBet,
  TBetSlipFocusedBet,
  EBetSlipViewMode,
  EBetSubmissionConfirmationStatus,
  TBetSlipBetMulti,
  EBetSlipBetSubmissionType,
  TBetSlipBetSingle,
  TMultiLeg,
  TAddBetPayload,
  EBetSubmissionRejectionReason,
  TBetSlipBonusChanceType,
} from '../components/Betslip/Services/Betslip.types';
import {
  getInitialBetSlipState,
  InitialBetSlipState,
} from '../components/Betslip/Services/Betslip.config';
import { IS_MOBILE_APP } from '../constants/isMobileApp';
import { postMobileAppMessage } from '../mobileapp/mobileapp.utils';

const wrapReturnState = (state: TBetSlipState) => {
  if (IS_MOBILE_APP)
    postMobileAppMessage('betslip_updated', { betslip: state });
  return state;
};

const betSlipSlice = createSlice({
  name: 'betSlip',
  initialState: getInitialBetSlipState(),
  reducers: {
    setHasKeypad: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasKeypad: (IS_MOBILE_APP || isMobile) && action.payload,
    }),
    setKeypadValue: (state, action: PayloadAction<string>) => {
      const { betIDFocused, storedDecimal } = state;
      const bet: TBetSlipBet | undefined = state.bets.find(
        (b) => b.request_id === betIDFocused
      );
      if (!bet) {
        return { ...state };
      }
      const keypadValue = action.payload;
      const indexOfBet = state.bets.findIndex(
        (b) => b.request_id === betIDFocused
      );

      const bets = _.cloneDeep(state.bets);

      let updatedBet;
      let updatedStoredDecimal = '';

      if (keypadValue.includes('+')) {
        const addition = Number(keypadValue.split('+')[1]);
        const isNumber = !Number.isNaN(Number(bet.stake));

        updatedBet = {
          ...bet,
          stake: isNumber ? addition + Number(bet?.stake ?? 0) : addition,
        };
      } else if (keypadValue.includes('-')) {
        if (storedDecimal) {
          return {
            ...state,
            storedDecimal:
              storedDecimal.length === 1
                ? ''
                : state.storedDecimal.slice(0, -1),
          };
        }
        const newStake = bet.stake.toString().slice(0, -1);
        if (newStake.slice(-1) === '.') {
          updatedStoredDecimal = newStake.slice(-1);
        } else if (newStake.slice(-2) === '.0') {
          updatedStoredDecimal = newStake.slice(-2);
        }

        updatedBet = {
          ...bet,
          stake: Number(newStake),
        };
      } else if (keypadValue === '.') {
        return {
          ...state,
          storedDecimal: bet.stake.toString().includes('.') ? '' : '.',
        };
      } else if (Number(keypadValue) === 0 && state.storedDecimal === '.') {
        return { ...state, storedDecimal: '.0' };
      } else if (Number(keypadValue) >= 0) {
        if (bet.stake.toString().split('.')[1]?.length === 2) {
          return { ...state };
        }
        const currentStake = Number(bet.stake) > 0 ? bet.stake?.toString() : '';
        const newStake = () => {
          if (currentStake || storedDecimal) {
            return storedDecimal
              ? `${currentStake}${storedDecimal}${keypadValue}`
              : `${currentStake}${keypadValue}`;
          }
          return keypadValue;
        };
        updatedBet = {
          ...bet,
          stake: Number(newStake()),
        };
      }

      if (indexOfBet > -1 && updatedBet) {
        bets[indexOfBet] = updatedBet;
      }
      // We need to revalidate the bets after updating them
      const validatedBets = validateBets(bets);

      return wrapReturnState({
        ...state,
        bets: validatedBets,
        storedDecimal: keypadValue === '.' ? '.' : updatedStoredDecimal,
      });
    },
    setBetIDFocused: (state, action: PayloadAction<string>) => ({
      ...state,
      betIDFocused: action.payload,
    }),
    setBetSlipOpen: (state: TBetSlipState, action: PayloadAction<boolean>) => ({
      ...state,
      betSlipOpen: action.payload,
    }),
    setBetSlipViewMode: (
      state: TBetSlipState,
      action: PayloadAction<EBetSlipViewMode>
    ) =>
      wrapReturnState({
        ...state,
        viewMode: action.payload,
      }),
    setGeneralError: (state: TBetSlipState, action: PayloadAction<boolean>) =>
      wrapReturnState({ ...state, generalError: action.payload }),
    setBetSlipShowFieldErrors: (
      state: TBetSlipState,
      action: PayloadAction<string[]>
    ) => wrapReturnState({ ...state, showFieldErrors: action.payload }),
    setBetFocused: (
      state: TBetSlipState,
      action: PayloadAction<TBetSlipFocusedBet | undefined>
    ) =>
      wrapReturnState({
        ...state,
        focusedBet: action.payload?.request_id,
        focusedBetField: action.payload?.field_name,
      }),
    /** DONE */
    updateBetsWithConfirmations: (
      state: TBetSlipState,
      action: PayloadAction<TBetSlipBet[]>
    ) => {
      const updatedBets: TBetSlipBet[] = [];

      // Update betslip with confirmations without deleting any bets that have
      // previously been submitted but may be awaiting bookie approval
      state.bets.forEach((bet) => {
        const payloadBet = action.payload.find(
          (b) => b.request_id === bet.request_id
        );
        // If bet is in most recent punter submission, push to updatedBets
        // otherwise push bet from current state
        updatedBets.push(payloadBet !== undefined ? payloadBet : bet);
      });

      return wrapReturnState({
        ...state,
        submissionLoading: false,
        bets: updatedBets,
      });
    },
    setViewMode: (
      state: TBetSlipState,
      action: PayloadAction<EBetSlipViewMode>
    ) => ({
      ...state,
      viewMode: action.payload,
    }),
    /** DONE */
    addBet: (state: TBetSlipState, action: PayloadAction<TAddBetPayload>) => {
      // Shallow copies can result in immer errors when run through validateBets

      const bets = _.cloneDeep(state.bets).filter(
        (bet) =>
          bet.confirmation?.status ===
            EBetSubmissionConfirmationStatus.Pending ||
          !bet.confirmation ||
          bet.confirmation.rejection_reason ===
            EBetSubmissionRejectionReason.OddsChange ||
          bet.confirmation.rejection_reason ===
            EBetSubmissionRejectionReason.InsufficentFund ||
          (bet.type === EBetSlipBetSubmissionType.Multi &&
            bet.confirmation.rejection_reason ===
              EBetSubmissionRejectionReason.PropositionClosed)
      );
      let updatedBetsList;
      if (
        ![
          EBetSlipBetSubmissionType.Exotics,
          EBetSlipBetSubmissionType.SGMulti,
          EBetSlipBetSubmissionType.Blended,
          EBetSlipBetSubmissionType.SRMulti,
          EBetSlipBetSubmissionType.MysteryBet,
        ].includes(action.payload.bet.type)
      ) {
        const shouldCreateMulti =
          (action.payload.multisSupported ?? true) &&
          bets.filter(
            (bet) =>
              ![
                EBetSlipBetSubmissionType.Exotics,
                EBetSlipBetSubmissionType.SGMulti,
                EBetSlipBetSubmissionType.SRMulti,
              ].includes(bet.type) && !bet.confirmation
          ).length === 0 &&
          !bets.some(
            (bet) =>
              bet.type === EBetSlipBetSubmissionType.Multi &&
              bet.confirmation?.status !==
                EBetSubmissionConfirmationStatus.Pending
          ) &&
          !action.payload.localStorageData;

        const betToBeAdded: TBetSlipBetSingle = action.payload
          .bet as TBetSlipBetSingle;
        const newLeg: TMultiLeg = {
          event_id: betToBeAdded.event_id,
          proposition_id: betToBeAdded.proposition_id,
          odds: betToBeAdded.odds,
          event_icon: betToBeAdded.event_icon,
          event_market_name: betToBeAdded.event_market_name,
          event_start_time: betToBeAdded.event_start_time,
          runner_id: betToBeAdded.runner_id,
          event_title: betToBeAdded.event_title,
          event_subtitle: betToBeAdded.event_subtitle,
          event_data: betToBeAdded.event_data,
          event_type: betToBeAdded.event_type,
          eventRule: betToBeAdded.eventRule,
          eventClosed: false,
          price_type: betToBeAdded.price_type,
        };
        if (shouldCreateMulti) {
          // if shouldCreateMulti is true, create new bet of type multi and add prop to it
          const newMultiBet: TBetSlipBetMulti = {
            ...generateNewBetSlipBet(EBetSlipBetSubmissionType.Multi),
            legs: [newLeg],
            event_start_time: '',
            event_market_name: '',
            event_title: '',
            event_subtitle: '',
          };
          updatedBetsList = validateBets([
            ...bets,
            action.payload.bet,
            newMultiBet,
          ]);
        } else {
          // if false, look for multi bet, and add leg if it exist
          // If a multi has a confirmation object but has been rejected on account of a) an odds change, b) insufficient funds
          // or c) some props have been closed, this is still editable -> add the leg to this one
          // Otherwise any multi with a confirmation is either in review or has been actioned, so should not have anything added to it
          // TODO: find a much cleaner way of handling the several rejection reasons that are still editable.
          const multiBet = bets.find(
            (bet) =>
              bet.type === EBetSlipBetSubmissionType.Multi &&
              ((bet.confirmation &&
                (bet.confirmation.rejection_reason ===
                  EBetSubmissionRejectionReason.OddsChange ||
                  bet.confirmation.rejection_reason ===
                    EBetSubmissionRejectionReason.InsufficentFund ||
                  bet.confirmation.rejection_reason ===
                    EBetSubmissionRejectionReason.PropositionClosed)) ||
                !bet.confirmation)
          ) as TBetSlipBetMulti;

          if (
            !multiBet?.legs.some(
              (leg) => leg.proposition_id === newLeg.proposition_id
            )
          ) {
            multiBet?.legs.push(newLeg);
          }
          updatedBetsList = validateBets([...bets, action.payload.bet]);
        }
      } else {
        // handle exotics
        updatedBetsList = validateBets([...bets, action.payload.bet]);
      }

      return {
        ...state,
        bets: updatedBetsList,
        inReview: false,
        viewMode: EBetSlipViewMode.EditingBets,
      };
    },
    /** DONE */
    updateBet: (state: TBetSlipState, action: PayloadAction<TBetSlipBet>) => {
      const indexOfBet = state.bets.findIndex(
        (bet) => bet.request_id === action.payload.request_id
      );

      const bets = _.cloneDeep(state.bets);

      if (indexOfBet > -1) {
        bets[indexOfBet] = action.payload;
      }

      // We need to revalidate the bets after updating them
      const validatedBets = validateBets(bets);

      return wrapReturnState({
        ...state,
        bets: validatedBets,
        //     inReview: false,
      });
    },
    /** DONE */
    removeBet: (state: TBetSlipState, action: PayloadAction<string>) => {
      const stateBets = _.cloneDeep(state.bets);
      const betToBeRemoved = stateBets.find(
        (bet) => bet.request_id === action.payload
      );

      // Remove the corresponding leg from the existing multi
      if (betToBeRemoved?.type !== EBetSlipBetSubmissionType.Exotics) {
        const multi = stateBets.find(
          (b) =>
            b.type === EBetSlipBetSubmissionType.Multi &&
            ((b.confirmation &&
              (b.confirmation.rejection_reason ===
                EBetSubmissionRejectionReason.OddsChange ||
                b.confirmation.rejection_reason ===
                  EBetSubmissionRejectionReason.InsufficentFund ||
                b.confirmation.rejection_reason ===
                  EBetSubmissionRejectionReason.PropositionClosed)) ||
              !b.confirmation)
        ) as TBetSlipBetMulti;
        const multiLegIndex = multi?.legs.findIndex(
          (leg) => leg.proposition_id === betToBeRemoved?.proposition_id
        );
        if (multiLegIndex > -1) {
          multi.legs.splice(
            multi.legs.findIndex(
              (leg) => leg.proposition_id === betToBeRemoved?.proposition_id
            ),
            1
          );
        }
      }

      const updatedBets = stateBets.filter((b) => {
        // If the punter is restricted from placing multis they will be removed on login
        if (betToBeRemoved?.type === EBetSlipBetSubmissionType.Multi)
          return b.request_id !== action.payload;
        if (b.type === EBetSlipBetSubmissionType.Multi) {
          // If the multi now has 0 legs, remove it
          const multiBet = b as TBetSlipBetMulti;
          return (
            multiBet.legs.filter(
              (leg) => leg.proposition_id !== betToBeRemoved?.proposition_id
            ).length > 0
          );
        }
        return b.request_id !== action.payload;
      });

      const bets = validateBets(updatedBets);

      return wrapReturnState({
        ...state,
        bets,
        //   inReview: false,
      });
    },
    /** DONE */
    removeAllBets: (state: TBetSlipState) => {
      // 👇 Remove all the bets except the bets in 'Pending approval' stage.
      const bets = validateBets(
        state.bets.filter(
          (bet) =>
            bet?.confirmation?.status ===
            EBetSubmissionConfirmationStatus.Pending
        )
      );

      return wrapReturnState({
        ...state,
        bets,
        generalError: false,
        // inReview: false,
      });
    },
    clearBetSlip: () => InitialBetSlipState,
    /** DONE */
    clearActionedBets: (state: TBetSlipState) => {
      const bets = _.cloneDeep(state.bets);
      const nonActionedBets = bets.filter(
        (bet) =>
          bet.confirmation?.status ===
            EBetSubmissionConfirmationStatus.Pending ||
          !bet.confirmation ||
          bet.confirmation?.rejection_reason ===
            EBetSubmissionRejectionReason.OddsChange ||
          bet.confirmation?.rejection_reason ===
            EBetSubmissionRejectionReason.InsufficentFund ||
          (bet.type === EBetSlipBetSubmissionType.Multi &&
            bet.confirmation?.rejection_reason ===
              EBetSubmissionRejectionReason.PropositionClosed)
      );

      const multi = nonActionedBets.find(
        (bet) => bet.type === EBetSlipBetSubmissionType.Multi
      ) as TBetSlipBetMulti;
      // Any singles that have been filtered out should also be removed from the multi bet
      if (multi && !multi?.confirmation) {
        const newMulti = {
          ...multi,
          legs: multi?.legs?.filter((leg) =>
            nonActionedBets.some((b) => b.proposition_id === leg.proposition_id)
          ),
        };
        const multiIndex = nonActionedBets.findIndex(
          (bet) => bet.request_id === multi.request_id
        );
        nonActionedBets[multiIndex] = newMulti;
      }

      return wrapReturnState({
        ...state,
        submissionLoading: false,
        bets: nonActionedBets,
      });
    },
    /** DONE */
    clearNonStakedBets: (state: TBetSlipState) => {
      const stakedBets = state.bets.filter((bet) => Number(bet.stake) > 0);

      return wrapReturnState({
        ...state,
        bets: stakedBets,
      });
    },
    mobileAppSyncBetSlip: (
      state: TBetSlipState,
      action: PayloadAction<TBetSlipState>
    ) => {
      // We need to reset the focus state
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document?.activeElement?.blur();

      return action.payload;
    },
    clearClosedEventBets: (state: TBetSlipState) => {
      // TODO: incorporate this to singles properly
      // There's an overreliance on the link between singles and multis
      // which will fail in events where punters can't create multis
      // i.e. sharps
      // TODO: re-evaluate if this can be done in removeBet

      const bets = _.cloneDeep(state.bets);
      const multiBetContainingClosedEventPropIds = bets.find(
        (bet) =>
          bet.type === EBetSlipBetSubmissionType.Multi &&
          bet.confirmation?.rejection_reason ===
            EBetSubmissionRejectionReason.PropositionClosed
      ) as TBetSlipBetMulti;
      const closedEventsPropositionIds =
        multiBetContainingClosedEventPropIds?.legs
          .filter((leg) => leg.eventClosed)
          .map((l) => l.proposition_id);

      if (!closedEventsPropositionIds?.length) {
        return { ...state };
      }

      const updatedBets = bets
        .filter((bet) => {
          if (!bet.proposition_id) {
            return true;
          }
          return !closedEventsPropositionIds?.includes(bet.proposition_id);
        })
        .map((b) => {
          if (b.type === EBetSlipBetSubmissionType.Multi) {
            const multiBet = b as TBetSlipBetMulti;
            const updatedMultiBet = {
              ...multiBet,
              legs: multiBet.legs.filter(
                (leg) =>
                  !closedEventsPropositionIds?.includes(leg.proposition_id)
              ),
            };
            return updatedMultiBet;
          }
          return b;
        });

      return {
        ...state,
        bets: updatedBets,
      };
    },
    setHasSufficientFunds: (
      state: TBetSlipState,
      action: PayloadAction<boolean>
    ) => wrapReturnState({ ...state, hasSufficientFunds: action.payload }),
    setDisplayOddsChangeBanner: (
      state: TBetSlipState,
      action: PayloadAction<boolean>
    ) => wrapReturnState({ ...state, displayOddsChangeBanner: action.payload }),
    setDisplayEventsClosedBanner: (
      state: TBetSlipState,
      action: PayloadAction<boolean>
    ) =>
      wrapReturnState({ ...state, displayEventsClosedBanner: action.payload }),
    setUpdateConfirmFlag: (
      state: TBetSlipState,
      action: PayloadAction<boolean>
    ) => wrapReturnState({ ...state, updateConfirmFlag: action.payload }),
    setStoredDecimal: (
      state: TBetSlipState,
      action: PayloadAction<string>
    ) => ({ ...state, storedDecimal: action.payload }),
    setBonusChanceModal: (
      state: TBetSlipState,
      action: PayloadAction<Partial<TBetSlipBonusChanceType> | null>
    ) => ({ ...state, bonusChance: action.payload }),
    setBonusChanceVisibility: (
      state: TBetSlipState,
      action: PayloadAction<boolean>
    ) => {
      if (state.bonusChance) {
        // eslint-disable-next-line no-param-reassign
        state.bonusChance.isVisible = action.payload;
      }
    },
  },
  extraReducers: {
    'betSlip/submitBetSlip/pending': (state: TBetSlipState) =>
      wrapReturnState({
        ...state,
        submissionLoading: true,
      }),
    'betSlip/submitBetSlip/fulfilled': (state: TBetSlipState) =>
      wrapReturnState({
        ...state,
        submissionLoading: false,
      }),
    'betSlip/submitBetSlip/rejected': (state: TBetSlipState) =>
      wrapReturnState({
        ...state,
        submissionLoading: false,
      }),
  },
});

export const {
  setHasKeypad,
  setKeypadValue,
  setBetIDFocused,
  setBetSlipOpen,
  setBetSlipViewMode,
  updateBetsWithConfirmations,
  updateBet,
  removeBet,
  removeAllBets,
  addBet,
  clearBetSlip,
  clearActionedBets,
  clearNonStakedBets,
  setBetFocused,
  setBetSlipShowFieldErrors,
  setGeneralError,
  mobileAppSyncBetSlip,
  setHasSufficientFunds,
  setDisplayOddsChangeBanner,
  setDisplayEventsClosedBanner,
  clearClosedEventBets,
  setUpdateConfirmFlag,
  setStoredDecimal,
  setBonusChanceModal,
  setBonusChanceVisibility,
  setViewMode,
} = betSlipSlice.actions;

export default betSlipSlice.reducer;
