import { CSSObject } from '@chakra-ui/react';
import astronaut from '../../../../../../assets/betgalaxy/images/header/astronaut.svg';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'xs',
};

export const BoxBallance: CSSObject = {
  bg: 'gray.50',
  color: 'gray.800',
};

export const buttonDepositProps: CSSObject = {
  bg: 'beta.400',
  color: 'white',
  w: 'full',

  sx: {
    _hover: { bg: 'beta.300', color: 'white' },
    _active: { bg: 'beta.500', color: 'white' },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  color: 'gray.800',

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.50',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${astronaut})`,
  bgSize: '100%',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  zIndex: '-1',

  display: ['none', null, 'block'],

  w: '63px',
  h: '63px',
};
