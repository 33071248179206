import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  bgGradient: 'linear(242deg, #3f4953, #4b5a6d)', // @TODO colour not in palette
  borderRadius: 'base',
  mb: '2',
  flex: '1',
  boxShadow:
    '0 4px 4px 0 #00000040, inset 0 1px 0 0 #ffffff26, -1px 0 18px 0 #00000026',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, #ffce70, #cc952b)', // @TODO colour not in palette
  color: 'blackAlpha.800',
  fontSize: '2xs',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'gray.500',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '34px',
  border: 'none',
  fontWeight: 'black',
  fontSize: 'md',
  textShadow: '1px 1px 0px rgba(255, 255, 255, 0.20)',
  color: 'blackAlpha.600',
  bg: 'beta.100',
  borderRadius: 'md',
  fontFamily: 'CrimsonPro',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
};
