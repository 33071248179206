import { Box, BoxProps, chakra, ChakraComponent } from '@chakra-ui/react';
import { styleImports } from './Carousel.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const themeName = getThemeName();

export const CarouselWrapper: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    label: 'carousel-CarouselWrapper',
    w: 'full',
    '& .control-dots ': {
      bg: 'rgba(0, 0, 0, 0.16)',
      w: 'fit-content',
      margin: 'auto',
      left: 0,
      right: 0,
      bottom: '3',
      borderRadius: 'xl',
      minW: '8',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.CarouselWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export type TDot = {
  selected: boolean;
} & any;
export const Dot = chakra<TDot>('div', {
  shouldForwardProp: (prop) => !['selected'].includes(prop),
  baseStyle: ({ selected }: any) => ({
    label: 'carousel-Dot',
    boxSize: '2.5',
    borderRadius: 'full',
    bg: selected ? 'white' : 'rgba(0, 0, 0, 0.48)',
    display: 'inline-block',
    marginX: '2px',
    cursor: 'pointer',

    ...(() => {
      try {
        return styleImports[themeName]?.Dot?.({ selected });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export type TDotContainer = {
  numberOfSlides: number;
  index: number;
} & any;
export const DotContainer = chakra<TDotContainer>('div', {
  baseStyle: ({ numberOfSlides, index }: any) => ({
    label: 'carousel-DotContainer',
    display: 'inline-block',

    ...(() => {
      try {
        return styleImports[themeName]?.DotContainer?.({
          numberOfSlides,
          index,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SlideWrapper = chakra(Box, {
  baseStyle: () => ({
    userSelect: 'none',
    h: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
}) as ChakraComponent<'div', BoxProps>;
