import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonCloseAccountProps: CustomButtonProps = {
  variant: 'secondary',
  height: '39px',
};

export const TextCheckbox: CSSObject = {
  color: 'white',
};
