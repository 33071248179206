import { CSSObject } from '@chakra-ui/react';

export const TextTitle: CSSObject = {
  color: 'beta.800',
  fontWeight: 'semibold',
  mt: '0',
};
export const TextDescription: CSSObject = {
  fontSize: 'xs',
  mb: '7',
  color: 'beta.800',
};
export const TextHeading: CSSObject = {
  fontSize: 'xs',
  mb: '4',
  color: 'beta.800',
};
export const TextNoResults: CSSObject = {
  color: 'beta.800',
};
