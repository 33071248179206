import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  borderRadius: 'base',
  bg: 'epsilon.600',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '24px',
  display: 'inline-block',
  fontSize: '2xs',
  h: '5',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'beta.500',
  boxShadow: 'inset 0px 0px 7px 2px rgba(0, 0, 0, 0.25)',
  color: 'gamma.500',
  borderWidth: '3px',
  borderColor: 'beta.200',
  borderRadius: '2xl',
  mb: '2',

  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'beta.400',
  },

  '.chakra-badge.state-prior': { color: 'gamma.500' },
};

export const TextRaceType: CSSObject = {
  bg: 'transparent',
  color: 'delta.400',
  mx: 'auto',
  mb: '2',
  fontWeight: 'bold',
  letterSpacing: 'wide',
  fontFamily: 'CinzelDecorative',
  textTransform: 'lowercase',
  fontSize: 'md',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  bgClip: 'text',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
};

export const BoxColumn: CSSObject = {
  mb: ['4', '2'],
  '.chakra-badge.state-prior': { color: 'yellow.900' },
};
