import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logoDesktop from '@/assets/junglebet/images/logos/logoDesktop3x.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logoDesktop}
          alt={THEME_NAME.junglebet}
          mx="auto"
          w="100%"
          mb="-5"
        />
      </Show>
      <Hide above="lg">
        <Image src={logoDesktop} alt={THEME_NAME.junglebet} h="16" />
      </Hide>
    </Link>
  );
}
