import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { px: 2.5 },
  buttonCashOutAmount: { px: 2.5, sx: { '&:hover': { bg: 'alpha.600' } } },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    bg: 'gray.200',
  },
  iconOddsBoost: {
    fill: 'alpha.700',
  },
  textOddsBoost: {
    color: 'alpha.700',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.600',
};

export const RaceTime: CSSObject = {
  color: 'gray.500',
  mt: '1',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'alpha.600',
  color: 'white',
};

export const BonusBetText: CSSObject = {
  color: 'alpha.600',
};
