import { Box, Image, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/puntcity/images/backgrounds/onboarding-logo.png';
import sloganImg from '@/assets/puntcity/images/backgrounds/slogan.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: (
      <>
        <Link as={LinkDom} to="/" maxW="150px" alignSelf="center" mt="25px">
          <Image src={logo} />
        </Link>
        <Box m={['10px 0 40px 0', null, '0 -40px']}>
          <Image
            src={sloganImg}
            m={['0 auto', null, null]}
            h="120px"
            maxW="unset"
          />
        </Box>
      </>
    ),
    config: {
      before: 'slogan',
    },
  },
]);
