import { rest } from 'msw';
import {
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
  TBetSlipBet,
  TBetSubmissionConfirmation,
} from '../../components/Betslip/Services/Betslip.types';
import { baseURL } from '@/helpers/baseURL';
import { dollarsToCents } from '@/helpers/utils';

// This handler returns placed for all bets.
// We also validate the total dollar amount sent and length of bets to catch issues with sending already placed bets OR incorrect $ conv. expectedTotalBets whole dollars
export const betslipHandlerSuccess = (
  expectedTotalBets: number,
  expectedTotalStake: number,
  bonusBets?: boolean
) =>
  rest.post<TBetSlipBet[]>(
    baseURL(`/punter/bet-slip/v2/submit`),
    (req, res, ctx) => {
      const totalBonusBets = req.body.filter((bet) => bet.is_bonus_bet).length;
      const totalStake =
        req.body.map((bet) => Number(bet.stake)).reduce((a, b) => a + b, 0) /
        100;

      const totalBets = req.body.length;

      if (
        totalBets !== expectedTotalBets ||
        totalStake !== expectedTotalStake ||
        (bonusBets && totalBets !== totalBonusBets)
      ) {
        console.log('🚨 Betslip handler failed validation', {
          expectedTotalBets,
          expectedTotalStake,
          totalStake,
          totalBets,
          totalBonusBets,
        });

        return res(ctx.status(400));
      }

      const returnedConfirmations = [...req.body].map((bet) => ({
        request_id: bet.request_id,
        status: EBetSubmissionConfirmationStatus.Placed,
        bonus_bet: bet.is_bonus_bet,
      })) as TBetSubmissionConfirmation[];

      return res(ctx.status(200), ctx.json(returnedConfirmations));
    }
  );

// This handler returns odds changed for specified bets
// newOdds: An array of numbers/undefined. Each array index relates to the bet index in the request body, if a number is supplied = new odds in whole dollars. null = bet is placed
export const betslipHandlerOddsChanged = (newOdds: (number | null)[]) =>
  rest.post<TBetSlipBet[]>(
    baseURL(`/punter/bet-slip/v2/submit`),
    (req, res, ctx) => {
      const returnedConfirmations = [...req.body].map((bet, index) => {
        if (newOdds[index] === null) {
          return {
            request_id: bet.request_id,
            status: EBetSubmissionConfirmationStatus.Placed,
          };
        }

        return {
          request_id: bet.request_id,
          status: EBetSubmissionConfirmationStatus.Rejected,
          rejection_reason: EBetSubmissionRejectionReason.OddsChange,
          changed_odds: [
            {
              new_odds: dollarsToCents(newOdds[index] as number),
              proposition_id: 'b14529e6-0682-4882-8576-f6d75b62704a',
            },
          ],
        };
      });

      return res(ctx.status(200), ctx.json(returnedConfirmations));
    }
  );

export const betRecoveryBetSlipHandler = rest.post<TBetSlipBet[]>(
  baseURL('/punter/bet-slip/v2/submit'),
  (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json<TBetSubmissionConfirmation[]>(
        req.body.map(({ request_id }, index) => ({
          is_betslip_rejection: false,
          promo_token_type: index === 0 ? 'recovery' : undefined,
          request_id,
          status: EBetSubmissionConfirmationStatus.Placed,
        }))
      )
    )
);
