import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Mascot } from '../../styles/Maintenance.styles';
import Description from '../Description';
import Title from '../Title';

export default function Content() {
  return (
    <Flex gap="4" flexDir="column" pb="2">
      <Mascot />

      <Title />
      <Description />
    </Flex>
  );
}
