import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  borderColor: 'gamma.500',
  borderWidth: '2px',
};

export const DividerCard: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.26)',
  borderWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.30)',
};

export const NoResultsText: CSSObject = {
  color: 'white',
};

export const ExoticSelectionText: CSSObject = {
  color: 'white',
};

export const dividerExoticProps: DividerProps = {
  borderColor: 'rgba(0, 0, 0, 0.26)',
  borderStyle: 'solid',
  borderWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.30)',
  maxW: '300px',
};

export const dividerExoticLastProps: DividerProps = {
  borderColor: 'rgba(0, 0, 0, 0.26)',
  borderStyle: 'solid',
  borderWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.30)',
};
