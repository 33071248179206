import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  columnGap: '8px',
};

export const BoxColumn: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  rowGap: '8px',
  mb: ['20px', null, '12px'],
};

export const TextRaceNumber: CSSObject = {
  width: '19px',
  height: '17px',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3px 4px',
  borderRadius: '3px',
  backgroundColor: '#1c1d21',
  color: '#fab735',

  fontFamily: 'Roboto',
  fontSize: '9px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.2,
  letterSpacing: 'normal',
  textAlign: 'center',
};

export const LinkRaceContainer: CSSObject = {
  height: '47px',
  gap: '4px',
  padding: '14px 16px',
  borderRadius: '5px',
  WebkitBackdropFilter: 'blur(10px)',
  backdropFilter: 'blur(10px)',
  boxShadow:
    '0 3px 10px 0 rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(255, 255, 255, 0.1)',
  backgroundImage: 'linear-gradient(to bottom, #30363f 0%, #21252b 100%)',

  '&&': {
    mb: 0,
  },

  '.chakra-badge': {
    '&.state-prior': {
      bg: 'beta.700',
      color: 'alpha.500',
    },
  },
};
export const TextRaceType: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 500,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.2,
  letterSpacing: '2.8px',
  textAlign: 'center',
  color: '#fff',
  mb: '-2px',
};
export const TextRace: CSSObject = {
  fontFamily: 'CrimsonPro',
  fontSize: '15px',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.2,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#fff',
};

export const Icon: CSSObject = {
  boxSize: '21px',
  color: '#d6a64a',
  mr: 0,
};
