import { CSSObject } from '@chakra-ui/react';

export const EstReturnsLabel: CSSObject = {
  bg: 'alpha.600',
  color: 'white',
  fontWeight: 'extrabold',
  height: '100%',
  alignItems: 'center',
  py: '0,5',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: 'none',
  borderRadius: 'md',
  w: 'full',
  color: 'alpha.300',
  fontWeight: 'bold',
  fontSize: 'xs',
  justifyContent: 'center',
};

export const ApprovedBetContainer: CSSObject = {
  background: 'delta.800',
  bgGradient: 'none',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'md',
  w: 'full',
  color: 'blackAlpha.600',
  justifyContent: 'center',
  fontSize: '11px',
  h: '34px',
};

export const RejectedBetContainer: CSSObject = {
  background: 'red.500',
  bgGradient: 'none',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'md',
  w: 'full',
  color: 'blackAlpha.600',
  justifyContent: 'center',
  fontSize: '11px',
  h: '34px',
};

export const FooterContainer: CSSObject = {
  bg: 'white',
  border: '1.5px var(--bc-colors-alpha-600) solid',
  color: 'alpha.600',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'md',
};

export const FooterText: CSSObject = {
  px: '1',
  fontWeight: 'medium',
};
