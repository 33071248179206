import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  color: 'white',
  boxShadow: '0px 0px 0px 1px #4A5454 inset, 0px 2px 8px 0px #00000040',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bg: 'beta.700',
  },
};

export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'white',
  },
};
export const TextTitle: CSSObject = {
  color: 'white',
};
