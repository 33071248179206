import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useAuth } from '../../hooks/useAuth';
import { keys } from '../api.keys';
import {
  mutatePreferences,
  queryPromotionPreferences,
  TPromotionPreferencesParams,
} from './preferences';

export const useMutationPreferences = (
  options?: UseMutationOptions<any, any, TPromotionPreferencesParams>
) => useMutation((data) => mutatePreferences(data), options);

type TQueryHook = {
  key?: any[];
  options?: UseQueryOptions<TPromotionPreferencesParams>;
};

export const useQueryPromotionPreferences = ({
  key = [],
  options = {},
}: TQueryHook = {}) => {
  const { isAuthenticated } = useAuth();
  const query = useQuery(
    [keys.promotionPreferences, isAuthenticated, ...key],
    () => queryPromotionPreferences(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    {
      enabled: !!isAuthenticated,
      ...(options as any),
    }
  );

  return query;
};
