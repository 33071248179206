import {
  ButtonProps,
  ModalContentProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './CashOutModal.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type CashOutModalSchema = Partial<{
  modalContent: ModalContentProps;
  stackWrapper: StackProps;
  heading: TextProps;
  moneyValues: TextProps;
  cashedOutText: TextProps;
  cashedOutQuestion: TextProps;
  declineButton: ButtonProps;
  acceptButton: ButtonProps;
  doneButton: ButtonProps;
}>;

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    'data-testid': 'cashOutModal-modalContent',
    bg: 'white',
    ...(styleImports?.[themeName]?.cashOutModalStyles?.modalContent ?? {}),
  },
  stackWrapper: {
    'data-testid': 'cashOutModal-stackWrapper',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '80px',
    borderRadius: 'md',
    textAlign: 'center',
    ...(styleImports?.[themeName]?.cashOutModalStyles?.stackWrapper ?? {}),
  },
  heading: {
    'data-testid': 'cashOutModal-heading',
    color: 'white',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    ...(styleImports?.[themeName]?.cashOutModalStyles?.heading ?? {}),
  },
  moneyValues: {
    'data-testid': 'cashOutModal-moneyValues',
    color: '#FAB735',
    fontWeight: 'bold',
    ...(styleImports?.[themeName]?.cashOutModalStyles?.moneyValues ?? {}),
  },
  cashedOutText: {
    'data-testid': 'cashOutModal-text',
    color: 'white',
    ...(styleImports?.[themeName]?.cashOutModalStyles?.cashedOutText ?? {}),
  },
  cashedOutQuestion: {
    'data-testid': 'cashOutModal-cashedOutQuestion',
    my: 3,
    color: 'white',
    ...(styleImports?.[themeName]?.cashOutModalStyles?.cashedOutQuestion ?? {}),
  },
  declineButton: {
    'data-testid': 'cashOutModal-declineButton',
    variant: 'secondary',
    mr: 3,
    w: '50%',
    py: 6,
    textTransform: 'uppercase',
    fontSize: 'sm',

    ...(styleImports?.[themeName]?.cashOutModalStyles?.declineButton ?? {}),
  },
  acceptButton: {
    'data-testid': 'cashOutModal-acceptButton',
    variant: 'primary',
    w: '50%',
    py: '6',
    textTransform: 'uppercase',
    fontSize: 'sm',

    ...(styleImports?.[themeName]?.cashOutModalStyles?.acceptButton ?? {}),
  },
  doneButton: {
    'data-testid': 'cashOutModal-doneButton',
    variant: 'primary',
    w: '100%',
    py: '6',
    ...(styleImports?.[themeName]?.cashOutModalStyles?.doneButton ?? {}),
  },
};
