import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  fontFamily: 'Bowlby One',
  color: 'white',
  letterSpacing: 'widest',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  textTransform: 'uppercase',
  pt: '1',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
