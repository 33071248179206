import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Icon,
  shouldForwardProp,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import { isValidMotionProp } from 'framer-motion';
import { rgba } from 'polished';
import { CustomButtonProps } from '@/components/Button/Button';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg from '../../../../common/IconSvg';
import { styleImports } from './BetCard.styles.imports';

const themeName = getThemeName();

export const betCardErrorFlexProps: FlexProps = {
  'data-testid': 'betCard-error-betCardErrorFlexProps',
  position: 'relative',
  zIndex: 99,
  ...(() => {
    try {
      return styleImports[themeName]?.betCardErrorFlexProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const betCardErrorFlexTextProps: TextProps = {
  'data-testid': 'betCard-error-FlexTextProps',

  ...(() => {
    try {
      return styleImports[themeName]?.betCardErrorFlexTextProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const BetCardContainer = chakra(Box, {
  label: 'betCard-BetCardContainer',
  shouldForwardProp: (prop) => !['viewMode'].includes(prop),
  baseStyle: ({ viewMode }: any) => ({
    w: 'full',
    alignItems: 'start',
    position: 'relative',
    ...(() => {
      try {
        return styleImports[themeName]?.BetCardContainer?.(viewMode);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', { viewMode: string } & BoxProps>;

type BetOddsProps = {
  oddsIncreased: boolean;
  oddsDecreased: boolean;
  oddsHaveBeenBoosted: boolean;
} & BoxProps &
  any;

export const BetOdds = chakra<BetOddsProps>(Text, {
  label: 'betCard-BetOdds',
  shouldForwardProp: (prop) =>
    !['oddsIncreased', 'oddsDecreased', 'oddsHaveBeenBoosted'].includes(prop),
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.BetOdds;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const InfoBetOdds = chakra<BetOddsProps>(Text, {
  label: 'betCard-InfoBetOdds',
  shouldForwardProp: (prop) =>
    !['oddsIncreased', 'oddsDecreased', 'oddsHaveBeenBoosted'].includes(prop),
  baseStyle: ({ oddsIncreased, oddsDecreased, oddsHaveBeenBoosted }: any) => ({
    display: 'none',
    alignItems: 'center',
    // TODO: once we have designs, move any new styles to theme specific files
    ...(oddsHaveBeenBoosted && {
      opacity: 0.5,
      textDecoration: 'line-through',
    }),
    textColor: () => {
      if (oddsIncreased) {
        return 'green.400';
      }
      if (oddsDecreased) {
        return 'red.400';
      }
      return 'black';
    },
    ...(() => {
      try {
        return styleImports[themeName]?.InfoBetOdds;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const EventTitle = chakra(Text, {
  label: 'betCard-EventTitle',
  baseStyle: () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 'md',
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.EventTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const EventSubtitle = chakra(Text, {
  label: 'betCard-EventSubtitle',
  baseStyle: () => ({
    fontSize: 'sm',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ml: '8',
    ...(() => {
      try {
        return styleImports[themeName]?.EventSubtitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TEventMarketName = {
  isExtraInfo: boolean;
} & any;

export const EventMarketName = chakra<TEventMarketName>(Text, {
  label: 'betCard-EventMarketName',
  shouldForwardProp: (prop) => !['isExtraInfo'].includes(prop),
  baseStyle: ({ isExtraInfo }: TEventMarketName) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.EventMarketName?.(isExtraInfo);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const buttonBonusBetsProps = (
  isBonusBet: boolean,
  isInReview: boolean,
  isMysteryBet: boolean
): CustomButtonProps => ({
  'data-testid': 'buttonBonusBetsProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonBonusBetsProps?.(
        isBonusBet,
        isInReview,
        isMysteryBet
      );
    } catch (err) {
      return {};
    }
  })(),
});

type BonusBetsButtonProps = {
  isBonusBet: boolean;
} & ButtonProps &
  any;

/**
 * @deprecated use `buttonBonusBetsProps` instead
 */
export const BonusBetsButton = chakra<BonusBetsButtonProps>(Button, {
  label: 'betCard-BonusBetsButton',
  shouldForwardProp: (prop) => !['isBonusBet'].includes(prop),
  baseStyle: ({ isBonusBet }: any) => ({
    size: 'xs',
    px: '3',
    alignSelf: 'flex-start',
    textTransform: 'capitalize',
    ...(() => {
      try {
        return styleImports[themeName]?.BonusBetsButton?.(isBonusBet);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TStakeCollapseProps = {
  isOpen: boolean;
} & BoxProps &
  any;

export const StakeCollapse = chakra<TStakeCollapseProps>(Box, {
  label: 'card-StakeCollapse',
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
  baseStyle: ({ isOpen }: any) => ({
    w: 'full',
    px: '2',
    // Collapse animation hides overflow by default, show overflow whilst visible
    overflow: isOpen ? 'initial !important' : 'hidden',
    ...(() => {
      try {
        return styleImports[themeName]?.StakeCollapse;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TStakeCollapseProps>;

export const buttonStakeProps: CustomButtonProps = {
  'data-testid': 'betCard-buttonStakeProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonStakeProps;
    } catch (err) {
      return {};
    }
  })(),
};

/**
 * @deprecated use `buttonStakeProps` instead
 */
export const StakeButton = chakra(Button, {
  label: 'betCard-StakeButton',
  baseStyle: () => ({
    flex: '1',
    h: '8',
    fontSize: 'sm',
    p: '1',
    borderRadius: 'none',
    textTransform: 'capitalize',
    _first: {
      borderStartRadius: 'sm',
    },
    _last: {
      borderEndRadius: 'sm',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.StakeButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', ButtonProps>;

export const ExoticsPlaceContainer = chakra(Flex, {
  label: 'betCard-ExoticsPlaceContainer',
  baseStyle: () => ({
    dir: 'row',
    ...(() => {
      try {
        return styleImports[themeName]?.ExoticsPlaceContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TextExoticsLabel = chakra(Text, {
  label: 'TextExoticsLabel',
  baseStyle: () => ({
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.TextExoticsLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextExoticsInfo = chakra(Text, {
  label: 'TextExoticsInfo',
  baseStyle: () => ({
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextExoticsInfo;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const ExoticsNumberContainer = chakra(Flex, {
  label: 'ExoticsNumberContainer',
  baseStyle: () => ({
    mr: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.ExoticsNumberContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TextExoticSelections = chakra(Text, {
  label: 'TextExoticSelections',
  baseStyle: () => ({
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.TextExoticSelections;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const BetPlaced = chakra(Flex, {
  label: 'betCard-BetPlaced',
  baseStyle: () => ({
    px: '3',
    py: '2',
    w: 'full',
    bg: 'gamma.300',

    ...(() => {
      try {
        return styleImports[themeName]?.BetPlaced;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const BetPlacedRow = chakra(Flex, {
  label: 'BetPlacedRow',
  baseStyle: () => ({
    justifyContent: 'space-between',
    fontSize: '2xs',
    ...(() => {
      try {
        return styleImports[themeName]?.BetPlacedRow;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TextPlacedLabel = chakra(Text, {
  label: 'betCard-TextPlacedLabel',
  baseStyle: () => ({
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextPlacedLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const FlexPlacedContainer = chakra(Flex, {
  label: 'betCard-FlexPlacedContainer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexPlacedContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const BetPlacedText = chakra(Text, {
  label: 'betCard-BetPlacedText',
  baseStyle: () => ({
    justifyContent: 'space-between',
  }),
}) as ChakraComponent<'p', TextProps>;

export const ExoticsFooterContainer = chakra(Flex, {
  label: 'betCard-ExoticsFooterContainer',
  baseStyle: () => ({
    w: 'full',
    px: '3',
    pt: '2',
    justifyContent: 'space-between',

    ...(() => {
      try {
        return styleImports[themeName]?.ExoticsFooterContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

type TBetCardSVG = {
  header?: boolean;
} & any;

export const BetCardSVG = chakra<TBetCardSVG>(IconSvg, {
  label: 'BetCardSVG',
  baseStyle: ({ header }: TBetCardSVG) => ({
    width: '4',
    height: '4',
    mr: '1',
    color: 'black',
    display: header && 'none',

    ...(() => {
      try {
        return styleImports[themeName]?.BetCardSVG?.(header) ?? null;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetCardVStack = chakra(VStack, {
  label: 'BetCardVStack',
  baseStyle: () => ({
    spacing: '5',
    w: 'full',
    pb: '3',
    overflow: 'hidden',
    '&:after': {
      border:
        'background: linear-gradient(145deg, white 8px, transparent 0), linear-gradient(-145deg, white 8px, transparent 0)',
    },
    '&::after': {
      display: 'inline-block',
      content: '""',
      flex: 1,
      bg: 'blackAlpha.400',
      borderRightRadius: 'md',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.BetCardVStack;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetPropositionClosedError = chakra(Flex, {
  label: 'betCard-BetPropositionClosedError',
  baseStyle: ({ theme }) => ({
    position: 'absolute',
    bg: rgba(`${theme.colors.whiteAlpha[700]}`, 0.7),
    w: '100%',
    pb: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    zIndex: '3',
    inset: 0,
    ...(() => {
      try {
        return styleImports[themeName]?.BetPropositionClosedError;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

/**
 * @deprecated - use `buttonBetPropositionClosedProps` instead
 */
export const BetPropositionClosedButton = chakra(Button, {
  label: 'betCard-BetPropositionClosedButton',
  baseStyle: () => ({
    bg: 'black',
    px: '7',
    zIndex: '1',
    _hover: {
      bg: 'gray.700',
      color: 'gray.200',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.BetPropositionClosedButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', ButtonProps>;

export const buttonBetPropositionClosedProps: CustomButtonProps = {
  'data-testid': 'buttonBetPropositionClosedProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonBetPropositionClosedProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const IconPropositionClosed = () => {
  try {
    return styleImports[themeName]?.IconPropositionClosed?.() ?? null;
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return null;
};

export const EventRule = chakra(Flex, {
  label: 'betCard-EventRule',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'xs',
    alignSelf: 'start',
    gap: '1',

    '&&': {
      mt: '0',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.EventRule;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const IconButtonThemed = chakra(Icon, {
  label: 'betCard-IconButtonThemed',
  baseStyle: () => ({
    ml: '2',
    cursor: 'pointer',
    boxSize: '5',
    ...(() => {
      try {
        return styleImports[themeName]?.IconButtonThemed;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetCardWrapper = chakra(Box, {
  label: 'BetCardWrapper',
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
  baseStyle: () => ({
    w: 'full',

    ...(() => {
      try {
        return styleImports[themeName]?.BetCardWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

type FlexBonusBetsWrapperProps = {
  displayBonusBetsButton: boolean;
} & FlexProps &
  any;

export const FlexBonusBetsWrapper = chakra<FlexBonusBetsWrapperProps>(Flex, {
  label: 'FlexBonusBetsWrapper',
  shouldForwardProp: (prop) =>
    !['displayBonusBetsButton', 'isMysteryBet'].includes(prop),
  baseStyle: ({ displayBonusBetsButton, isMysteryBet }: any) => ({
    gap: isMysteryBet ? '2' : '0',
    mt: '0',
    width: '100%',
    justifyContent: displayBonusBetsButton ? 'space-between' : 'end',
    flexDir: isMysteryBet ? 'column' : 'row',
    py: isMysteryBet ? '0' : '2',
    px: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBonusBetsWrapper?.(
          displayBonusBetsButton,
          isMysteryBet
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DefaultStakeFlexbox = chakra(Flex, {
  label: 'betCard-DefaultStakeFlexbox',
  baseStyle: () => ({
    w: 'full',
    flexDir: 'row',
    gap: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.DefaultStakeFlexbox;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const betRecoveryAlertWrapperProps: BoxProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.betRecoveryAlertWrapperProps;
    } catch (err) {
      return {};
    }
  })(),
};
