import { Box, chakra } from '@chakra-ui/react';
import { styleImports } from './PromotionsNotifications.styles.imports';

import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FormWrapper = chakra(Box, {
  label: 'promotionsNotifications-FormWrapper',
  baseStyle: ({ theme }: any) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      py: '4',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      '.chakra-checkbox__control': {
        bg: 'white',
      },
      '.chakra-checkbox__control[data-checked]': {
        bg: 'black',
      },
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.FormWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
