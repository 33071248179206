import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { keys } from '@/api/api.keys';
import { queryUpcomingMatches } from './upcomingMatches';
import {
  TQueryUpcomingMatchesParams,
  TQueryUpcomingMatchesResponse,
} from './upcomingMatches.types';

export const useQueryUpcomingMatches = (
  params?: TQueryUpcomingMatchesParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<TQueryUpcomingMatchesResponse>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery(
    [keys.upcomingMatches, params],
    () => queryUpcomingMatches(params),
    options
  );

export const useInfiniteQueryUpcomingMatches = (
  params?: Omit<TQueryUpcomingMatchesParams, 'offset'>,
  options?: Omit<
    UseInfiniteQueryOptions<TQueryUpcomingMatchesResponse>,
    'queryKey' | 'queryFn'
  >
) =>
  useInfiniteQuery(
    [keys.upcomingMatches, params],
    async ({ pageParam }) => {
      const res = await queryUpcomingMatches({ ...params, offset: pageParam });
      return res.data;
    },
    options
  );
