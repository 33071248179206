import { CSSObject } from '@chakra-ui/react';
import featuredCardsBg from '@/assets/juicybet/images/backgrounds/inner.png';

export const TextRaceNumber: CSSObject = {
  h: '18px',
  lineHeight: '18px',
  fontWeight: 'bold',
  bg: 'alpha.800',
  flex: 0,
  borderRadius: 'base',
  px: '1',
  fontSize: '2xs',
  color: 'white',
};

export const LinkRaceContainer: CSSObject = {
  bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${featuredCardsBg})`,
  bgRepeat: 'repeat',
  bgSize: '95%',
  backgroundBlendMode: 'overlay',
  boxShadow:
    '0px 0px 4px 5px rgba(0, 0, 0, 0.15) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.15)',

  color: 'white',
  border: '3px solid',
  borderColor: 'alpha.50',
  borderRadius: 'xl',
  mb: '1.5',

  '.chakra-badge.state-prior': {
    bg: 'transparent',
    color: 'white',
    fontWeight: 'semibold',
    fontSize: '2xs',
    px: '0',
  },
  '.chakra-badge.state-inprogress': {
    color: 'alpha.800',
    bg: 'alpha.300',
    fontSize: '2xs',
  },
  '.chakra-badge.state-ended': {
    fontSize: '2xs',
    bg: 'alpha.800',
    color: 'white',
  },
};

export const TextRaceType: CSSObject = {
  color: 'gamma.400',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Chewy',
  fontSize: 'xl',
  textTransform: 'capitalize',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
};

export const Icon: CSSObject = {
  color: 'gamma.400',
};
