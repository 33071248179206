import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  m: '6px',
  mb: 0,
  p: 0,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
};
export const CardContainer: CSSObject = {
  boxShadow:
    '0px 2px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.13) inset',
  borderRadius: 'md',
  bgGradient:
    'linear(113.55deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.15) 100%)',
  border: 'none',
  p: 1.5,
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  mx: '6px',
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  display: 'flex',
  '&': {
    px: 0,
  },
  alignItems: 'start',
  '> svg': {
    color: 'white',
    fill: 'white',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
};
export const dividerExoticProps: DividerProps = {
  display: 'none',
};

export const dividerExoticLastProps: DividerProps = {
  borderTop: '1px dashed',
  borderTopColor: 'rgba(0, 0, 0, 0.25)',
  borderBottom: '1px solid',
  borderBottomColor: 'rgba(255, 255, 255, 0.08)',
};
