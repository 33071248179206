import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Divider,
  Icon,
  Link,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import IconSvg from '@/components/common/IconSvg';
import Countdown from '@/components/Countdown/Countdown';
import DuotoneSgmLogo from '@/components/DuotoneSgmLogo/DuotoneSgmLogo';
import { getIconBySportName } from '@/helpers/utils';
import { newMatchCardStyles } from '@/views/sports/components/NewMatchCard/styles/NewMatchCard.styles';

export default function MatchCard(props: BoxProps) {
  return <Box {...newMatchCardStyles.boxWrapper} {...props} />;
}

export function MatchCardBody(props: StackProps) {
  return <Stack {...newMatchCardStyles.bodyStack} {...props} />;
}

export function MatchCardTitle(props: HeadingProps) {
  return <Heading {...newMatchCardStyles.titleHeading} {...props} />;
}

export type TMatchCardFooterProps = FlexProps & {
  matchStartTime?: string;
  matchUrl: string;
  sportName?: string;
  competitionName?: string;
  marketCount?: number;
  sgmAvailable?: boolean;
};

export function MatchCardFooter({
  matchStartTime,
  matchUrl,
  sportName,
  competitionName,
  marketCount,
  sgmAvailable,
  ...rest
}: TMatchCardFooterProps) {
  return (
    <Flex {...newMatchCardStyles.footerFlex} {...rest}>
      {sportName && (
        <>
          <IconSvg
            name={getIconBySportName(sportName)}
            {...newMatchCardStyles.footerSportNameIconSvg}
          />
          <Text {...newMatchCardStyles.footerCompetitionNameText}>
            {competitionName ?? ''}
          </Text>

          <Divider
            orientation="vertical"
            {...newMatchCardStyles.footerStackDivider}
          />
        </>
      )}

      {matchStartTime && (
        <Countdown
          eventTime={matchStartTime}
          dateFormat="D MMM HH:mm"
          {...newMatchCardStyles.footerCountDown}
        />
      )}

      {sgmAvailable && (
        <DuotoneSgmLogo
          primaryColor="alpha.500"
          secondaryColor="beta.600"
          {...newMatchCardStyles.footerDuotoneSgmLogo}
        />
      )}

      <Link
        as={ReactRouterLink}
        to={matchUrl}
        {...newMatchCardStyles.footerMatchLink}
      >
        {marketCount}
        <Icon as={ChevronRight} {...newMatchCardStyles.footerMatchLinkIcon} />
      </Link>
    </Flex>
  );
}
