import { CSSObject } from '@chakra-ui/react';
import errorBg from '@/assets/templebet/images/onboarding/onboardingBg2.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  justifyContent: 'center',
  bgImage: `url(${errorBg}), linear-gradient(180deg, var(--bc-colors-alpha-500), var(--bc-colors-alpha-600))`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
});
export const MaintenanceContainer = (): CSSObject => ({
  alignItems: 'center',
  color: 'white',
  contain: 'paint',
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  maxW: 'container.md',
  mb: [null, null, '0'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px var(--bc-colors-blackAlpha-400)',
  w: 'full',
  zIndex: '1',
  img: {
    mb: '0',
    ml: '0',
  },
  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },
});
export const ContentHeadingText: CSSObject = {
  w: ['full', '278px', '468px'],
  color: 'white',
  fontFamily: 'accent',
  fontSize: '3xl',
  lineHeight: 'none',
  mb: '4',
  mt: '4',
};
export const ContentBodyText: CSSObject = {
  fontSize: 'sm',
  mb: '4',
  span: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'semibold',
    mb: '4',
  },
};
export const buttonRefreshProps: CustomButtonProps = {
  variant: 'primary',
  minW: '24',
};
export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'white',
};
