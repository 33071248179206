import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
};

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  borderBottomLeftRadius: 'none',
  borderBottomRightRadius: 'none',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200), 0px 1px 5px 0px var(--bc-colors-whiteAlpha-400) inset',
  textTransform: 'uppercase',
  mb: '1',
};

export const EachWayText: CSSObject = {
  color: 'black',
  fontWeight: 'medium',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'beta.500',
  fontWeight: 'black',
};

export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'beta.500',
  fontWeight: 'black',
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'bold',
  fontSize: 'sm',
  color: 'beta.900',
  bgGradient:
    'linear(to-b, var(--bc-colors-beta-400), var(--bc-colors-beta-500))',
  border: 'none',
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
  h: '38px',
  w: '34px',
  '+ input': {
    bg: 'beta.900',
  },
});

export const InputBetSlip: CSSObject = {
  border: '2px solid',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  boxShadow: '0px 3px 8px 0px var(--bc-colors-blackAlpha-500)',
  h: '38px',
  pl: '2.8rem',
  ':first-child': {
    pl: '2',
  },
  '&&': {
    borderRightRadius: 'md',
    borderColor: 'beta.400',
    borderLeftRadius: 'md',
    bg: 'beta.900',
  },
  _hover: {
    bg: 'beta.700',
    boxShadow: '0px 3px 8px 0px var(--bc-colors-blackAlpha-500)',
  },
  _focus: {
    bg: 'beta.700',
    borderColor: 'beta.400',
    boxShadow: '0px 3px 8px 0px var(--bc-colors-blackAlpha-500)',
  },
};

export const FlexiReview: CSSObject = {
  pl: '8',
};

export const FlexReviewExotic: CSSObject = {
  textAlign: 'right',
  "[class$='ReviewStake']": {
    color: 'alpha.100',
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'beta.500',
};
