import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  pt: '20',
  textAlign: 'center',
  mx: 'auto',
  w: 'full',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
};

export const BetSlipEmptyHeading: CSSObject = {
  mt: '2',
  mb: '1',
  fontFamily: 'Angkor',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  color: 'gamma.500',
  // TODO: Using direct px value
  fontSize: '19px',
  w: '190px',
  mx: '4',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  mx: '4',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};

export const BetSlipEmptyResponsibleGamblingWrapper: CSSObject = {
  pt: '0',
};
