import { CSSObject, TextProps } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'white',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  color: () => {
    switch (status) {
      case 'won':
        return 'beta.700';
      case 'lost':
        return 'gamma.500';
      case 'voided':
        return 'gamma.500';
      case 'cancelled':
        return 'white';
      case 'pending':
        return 'gamma.900';
      default:
    }
  },
  bg: () => {
    switch (status) {
      case 'won':
        return 'beta.500';
      case 'lost':
        return 'blackAlpha.400';
      case 'voided':
        return 'blackAlpha.400';
      case 'cancelled':
        return 'gray.400';
      case 'pending':
        return 'gamma.500';
      default:
    }
  },
  px: '1',
  py: '1.5',
  lineHeight: '0.5',
});

export const TextBarrierNumber: CSSObject = {
  color: 'white',
};

export const RunnerDescription: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'bold',
};

export const TextDescription: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'bold',
};

export const dotSeperatorProps: TextProps = {
  color: 'beta.500',
};

export const HeaderSvg: CSSObject = {
  boxSize: '6',
  color: 'gamma.500',
};

export const BetMultiIcon: CSSObject = {
  bg: 'gamma.500',
  boxSize: '6',
  color: 'gamma.900',
};

export const BetOdds: CSSObject = {
  color: 'white',
};

export const headerRowStyles: HeaderRowSchema = {
  originalOdds: {
    color: 'white',
  },
  headerRowSvg: {
    fill: 'alpha.700',
  },
};
