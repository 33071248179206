/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  AbsoluteCenter,
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  TUpcomingMatch,
  TUpcomingMatchProposition,
} from '@/api/sports/upcomingMatches.types';
import {
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import MatchCard, {
  MatchCardBody,
  MatchCardFooter,
  MatchCardTitle,
} from '../NewMatchCard/NewMatchCard';
import PropositionButton from '../PropositionButton/PropositionButton';
import { isPendingAndMatchingId } from '@/helpers/utils';
import { useAppSelector } from '@/hooks/useRedux';
import { competitionsMatchCardStyles } from './styles/CompetitionsMatchCard.styles';
import { EGeneralStatus } from '@/lib/DBModels';
import {
  getBetSlipStoreActions,
  transformBetForLegacy,
  useBetSlipBets,
} from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { TBetSlipBet } from '@/components/Betslip/Services/Betslip.types';

/**
 * Containers
 */
type CompetitionsMatchCardContainerProps = Omit<
  TCompetitionsMatchCardProps,
  'bets'
>;

const CompetitionsMatchCardReduxContainer = (
  props: CompetitionsMatchCardContainerProps
) => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <CompetitionsMatchCard bets={bets} {...props} />;
};

const CompetitionsMatchCardZustandContainer = (
  props: CompetitionsMatchCardContainerProps
) => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <CompetitionsMatchCard bets={bets} {...props} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? CompetitionsMatchCardZustandContainer
  : CompetitionsMatchCardReduxContainer;

// ----

/**
 * View
 */
export type TCompetitionsMatchCardProps = {
  event: TUpcomingMatch;
  isSoccer?: boolean;
  marketCount?: number;
  bets: TBetSlipBet[];
};

function CompetitionsMatchCard({
  event,
  isSoccer,
  marketCount,
  bets,
}: TCompetitionsMatchCardProps) {
  const { removeFromBetSlip, singlePropositionInBetSlip } =
    useBetSlipManageBets();
  const { addSportsBetSlip } = useSingleBetSlip();
  const { setBet } = getBetSlipStoreActions();

  const navigate = useNavigate();

  const pathname = `/sports/${[
    event?.sport_name ?? '',
    event?.competition_name ?? '',
    event?.match_name ?? '',
  ]
    .map(encodeURIComponent)
    .join('/')}`;

  const search = `?${(['sport_id', 'competition_id', 'match_id'] as const)
    .map((key) => `${camelCase(key)}=${event[key]}`)
    .join('&')}`;

  const matchUrl = pathname + search;

  const homeTeam = event?.match_info?.participants?.find(
    (part) => part.role === 'Home'
  );

  const awayTeam = event?.match_info?.participants?.find(
    (part) => part.role === 'Away'
  );

  const onTeamClick = (team: TUpcomingMatchProposition) => {
    const inBetSlip = singlePropositionInBetSlip(team?.proposition_id);

    setBet({
      id: team?.proposition_id ?? '',
      type: 'Single',
      propId: team?.proposition_id ?? '',
      odds: team?.odds ?? 0,
      misc: {
        ...event,
        ...team,
        sportTitle: event?.main_markets?.[0]?.market_name,
        match: event,
        proposition: team,
      },
    });

    if (inBetSlip) {
      removeFromBetSlip(inBetSlip.request_id);
    } else {
      addSportsBetSlip(
        event?.main_markets?.[0]?.market_name ?? '',
        { ...team, return_amount: team.odds },
        event
      );
    }
  };

  return (
    <MatchCard {...competitionsMatchCardStyles.matchCardOverride}>
      <MatchCardBody>
        {isSoccer || !event?.main_markets?.length ? (
          <>
            <MatchCardTitle onClick={() => navigate(matchUrl)}>
              {event?.match_name}
            </MatchCardTitle>

            {event?.main_markets && (
              <Grid
                templateColumns={`repeat(${marketCount}, 1fr)`}
                {...competitionsMatchCardStyles.gridWrapper}
              >
                <ButtonGroup>
                  {event?.main_markets?.map((market) =>
                    market?.propositions?.map((prop) => {
                      const isActive = !!singlePropositionInBetSlip(
                        prop.proposition_id
                      );

                      return (
                        <GridItem
                          key={prop.proposition_id}
                          {...competitionsMatchCardStyles.gridItem}
                        >
                          <PropositionButton
                            {...competitionsMatchCardStyles.propositionButtonOverride}
                            odds={
                              prop?.status === EGeneralStatus.Voided
                                ? undefined
                                : prop?.odds
                            }
                            propositionName={prop.proposition_name}
                            onClick={() => onTeamClick(prop)}
                            isActive={isActive}
                            data-active={isActive}
                            isSuspended={prop.is_suspended}
                            isDisabled={
                              prop.status !== EGeneralStatus.Open ||
                              prop?.is_suspended ||
                              isPendingAndMatchingId(bets, prop.proposition_id)
                            }
                          />
                        </GridItem>
                      );
                    })
                  )}
                </ButtonGroup>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Flex {...competitionsMatchCardStyles.flexWrapper}>
              <Text
                onClick={() => navigate(matchUrl)}
                cursor="pointer"
                {...competitionsMatchCardStyles.flexText}
              >
                {homeTeam?.name}
              </Text>
              <Grid
                templateColumns={`repeat(${marketCount}, 1fr)`}
                {...competitionsMatchCardStyles.gridWrapper}
              >
                {event.main_markets.map((_, index) => {
                  const prop =
                    event.main_markets &&
                    event.main_markets[index]?.propositions?.[0];
                  const propBS = singlePropositionInBetSlip(
                    prop?.proposition_id
                  );
                  const isInBS = !!propBS;
                  const isOverUnder =
                    event.main_markets?.[index]?.market_info
                      ?.main_market_type === 'O/U';

                  const isHCP =
                    event.main_markets?.[index]?.market_info
                      ?.main_market_type === 'HCP';

                  return prop ? (
                    <GridItem
                      key={prop?.proposition_id}
                      {...competitionsMatchCardStyles.gridItem}
                    >
                      <PropositionButton
                        {...competitionsMatchCardStyles.propositionButtonOverrideSecondary}
                        key={prop?.proposition_id}
                        odds={
                          prop?.status === EGeneralStatus.Voided
                            ? undefined
                            : prop?.odds
                        }
                        propositionName={(() => {
                          if (prop?.proposition_info?.value1) {
                            const { value1, value2 } = prop?.proposition_info;

                            if (isOverUnder && value2 !== null) {
                              const isPos = value2 > 0;
                              return `${isPos ? 'O' : 'U'} ${value1}`;
                            }
                            if (isHCP && !!value2)
                              return `${value2 > 0 ? '+' : '-'}${value1}`;

                            return value1 as string;
                          }

                          return '';
                        })()}
                        isActive={isInBS}
                        isSuspended={prop.is_suspended}
                        isDisabled={
                          isPendingAndMatchingId(bets, prop?.proposition_id) ||
                          prop?.is_suspended ||
                          prop?.status !== 'Open'
                        }
                        onClick={() => onTeamClick(prop)}
                      />
                    </GridItem>
                  ) : (
                    <GridItem
                      key={`fallback-home-${index}`}
                      {...competitionsMatchCardStyles.gridItem}
                    >
                      <Text
                        {...competitionsMatchCardStyles.gridItemTextNoProposition}
                      >
                        <FormattedMessage id="sports.events.noPropositons" />
                      </Text>
                    </GridItem>
                  );
                })}
              </Grid>
            </Flex>

            <Box {...competitionsMatchCardStyles.vsBoxWrapper}>
              <Divider {...competitionsMatchCardStyles.vsDivider} />
              <AbsoluteCenter {...competitionsMatchCardStyles.absoluteCenter}>
                vs
              </AbsoluteCenter>
            </Box>

            <Flex {...competitionsMatchCardStyles.flexAwayTeam}>
              <Text
                cursor="pointer"
                onClick={() => navigate(matchUrl)}
                {...competitionsMatchCardStyles.flexAwayTeamText}
              >
                {awayTeam?.name}
              </Text>
              <Grid
                templateColumns={`repeat(${marketCount}, 1fr)`}
                {...competitionsMatchCardStyles.gridWrapper}
              >
                {event.main_markets.map((_, index) => {
                  const prop =
                    event.main_markets &&
                    event.main_markets[index]?.propositions?.[1];
                  const propBS = singlePropositionInBetSlip(
                    prop?.proposition_id
                  );
                  const isInBS = !!propBS;
                  const isOverUnder =
                    event.main_markets?.[index]?.market_info
                      ?.main_market_type === 'O/U';
                  const isHCP =
                    event.main_markets?.[index]?.market_info
                      ?.main_market_type === 'HCP';

                  return prop ? (
                    <GridItem
                      key={prop?.proposition_id}
                      {...competitionsMatchCardStyles.gridItem}
                    >
                      <PropositionButton
                        {...competitionsMatchCardStyles.propositionButtonOverrideSecondary}
                        key={prop?.proposition_id}
                        odds={
                          prop?.status === EGeneralStatus.Voided
                            ? undefined
                            : prop?.odds
                        }
                        propositionName={(() => {
                          if (prop?.proposition_info?.value1) {
                            const { value1, value2 } = prop?.proposition_info;

                            if (isOverUnder && value2 !== null) {
                              const isPos = value2 > 0;
                              return `${isPos ? 'O' : 'U'} ${value1}`;
                            }

                            if (isHCP && !!value2)
                              return `${value2 > 0 ? '+' : '-'}${value1}`;

                            return value1 as string;
                          }

                          return '';
                        })()}
                        isActive={isInBS}
                        isSuspended={prop.is_suspended}
                        isDisabled={
                          isPendingAndMatchingId(bets, prop?.proposition_id) ||
                          prop?.is_suspended ||
                          prop?.status !== 'Open'
                        }
                        onClick={() => onTeamClick(prop)}
                      />
                    </GridItem>
                  ) : (
                    <GridItem
                      key={`fallback-home-${index}`}
                      {...competitionsMatchCardStyles.gridItem}
                    >
                      <Text
                        {...competitionsMatchCardStyles.gridItemTextNoProposition}
                      >
                        <FormattedMessage id="sports.events.noPropositons" />
                      </Text>
                    </GridItem>
                  );
                })}
              </Grid>
            </Flex>
          </>
        )}
      </MatchCardBody>
      <MatchCardFooter
        sportName={event?.sport_name}
        sgmAvailable={event?.sgm_available}
        marketCount={event?.market_count}
        competitionName={event?.competition_name}
        matchStartTime={event?.match_start_time ?? ''}
        matchUrl={matchUrl}
      />
    </MatchCard>
  );
}
