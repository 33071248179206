import { DefaultTheme } from 'styled-components/macro';

import { defDevice } from '../core/common';
import hexBetBorderStyles from './common/border';
import { basePalette, hexBetPalette } from './common/color';
import {
  hexBetFontFamily,
  hexBetFontWeight,
  hexBetFontScale,
} from './common/text';

import * as onboardingLayout from './layouts/onboardingLayout';
import * as mainLayout from './layouts/mainLayout';
import * as navigation from './ui/navigation';
import * as topNav from './ui/topNav';
import * as banner from './ui/banner';
import * as grid from './ui/grid';
import * as content from './ui/content';
import * as locationNotAllowedPage from './pages/locationNotAllowedPage';
import * as depositLimitPage from './pages/depositLimitPage';
import * as forgotPasswordPage from './pages/forgotPasswordPage';
import * as home from './pages/home';
import * as sidenav from './ui/sidenav';
import * as datePicker from './components/datePicker';
import * as textInput from './components/textInput';
import * as button from './components/button';
import * as sportsAccordion from './components/accordion/sportsAccordion';
import * as marketsAccordion from './components/accordion/marketsAccordion';
import * as listSection from './features/sports/listSection';
import * as accountCreationPage from './pages/accountCreationPage';
import * as checkbox from './components/checkbox';
import * as inlineLink from './components/inlineLink';
import * as addressAutocomplete from './components/addressAutocomplete';
import * as select from './components/select';
import * as radio from './components/radio';
import * as matchCard from './components/matchCard';
import * as allSportsPage from './pages/allSportsPage';
import * as competitionsBySportPage from './pages/competitionsBySportPage';
import * as matches from './pages/matches';
import * as myBets from './pages/myBets';
import * as tabbedDisplay from './components/tabbedDisplay';
import * as marketsByMatchPage from './pages/marketsByMatchPage';
import * as propositions from './features/sports/propositions';
import * as nextToJumpList from './features/home/nextToJumpList';
import * as eventTile from './features/home/eventTile';
import * as featuredEvents from './features/home/featuredEvents';
import * as buttonGroup from './features/home/buttonGroup';
import * as pageHeaderDropdown from './components/dropdown/pageHeaderDropdown';
import * as betSlipBetCardDropdown from './components/dropdown/betSlipBetCardDropdown';
import * as raceDetailsPage from './pages/raceDetailsPage';
import * as raceRunnerListItem from './features/racing/raceRunnerListItem';
import * as raceResults from './features/racing/raceResults';
import * as raceMeetingsPage from './pages/raceMeetingsPage';
import * as raceMeetingsTable from './features/racing/raceMeetingsTable';
import * as myBetsCard from './features/account/myBetsCard';
import * as viewToggle from './components/viewToggle';
import * as keypad from './components/keypad';
import * as login from './pages/login';
import * as deposit from './pages/deposit';
import * as updateEmailPage from './pages/updateEmailPage';
import * as verifyAccount from './pages/verifyAccount';
import * as accountOverviewPage from './pages/accountOverviewPage';
import * as betSlipModal from './features/betslip/betslipModal';
import * as betSlipBetCard from './features/betslip/betslipBetCard';
import * as betSlipCTA from './features/betslip/betslipCTA';
import * as countdownTimer from './components/countdownTimer';
import * as settings from './pages/settings';
import * as promotionsNotificationsPage from './pages/promotionsNotificationsPage';
import * as termsAndPrivacy from './pages/termsAndPrivacy';
import * as modal from './components/modal';
import * as carousel from './components/carousel';
import * as desktopTransactionsTable from './features/account/desktopTransactionsTable';
import * as mobileTransactionsTable from './features/account/mobileTransactionsTable';
import * as accountDepositLimit from './pages/accountDepositLimit';
import * as responseToolbar from './components/responseToolbar';
import * as withdrawFunds from './pages/withdrawFunds';
import * as loadingSpinner from './components/loadingSpinner';

const coreTheme: DefaultTheme = {
  themeName: 'hexBet',
  basePalette,
  palette: hexBetPalette,
  fontFamily: hexBetFontFamily,
  fontScale: hexBetFontScale,
  fontWeight: hexBetFontWeight,
  device: defDevice,
  borderStyles: hexBetBorderStyles,
  ui: {
    navigation: { ...navigation },
    topNav: { ...topNav },
    banner: { ...banner },
    content: { ...content },
    grid: { ...grid },
    sideNav: { ...sidenav },
  },
  components: {
    loadingSpinner: { ...loadingSpinner },
    carousel: { ...carousel },
    countdownTimer: { ...countdownTimer },
    keypad: { ...keypad },
    viewToggle: { ...viewToggle },
    dropdown: {
      pageHeader: { ...pageHeaderDropdown },
      betSlipBetCard: { ...betSlipBetCardDropdown },
    },
    tabbedDisplay: { ...tabbedDisplay },
    accordion: {
      sports: { ...sportsAccordion },
      markets: { ...marketsAccordion },
    },
    datePicker: { ...datePicker },
    textInput: { ...textInput },
    button: { ...button },
    checkbox: { ...checkbox },
    inlineLink: { ...inlineLink },
    addressAutoComplete: { ...addressAutocomplete },
    select: { ...select },
    radio: { ...radio },
    matchCard: { ...matchCard },
    modal: { ...modal },
    responseToolbar: { ...responseToolbar },
  },
  layouts: {
    onboardingLayout: { ...onboardingLayout },
    mainLayout: { ...mainLayout },
  },
  pages: {
    promotionsNotifications: { ...promotionsNotificationsPage },
    myBets: { ...myBets },
    raceMeetings: { ...raceMeetingsPage },
    home: { ...home },
    raceDetails: { ...raceDetailsPage },
    marketsByMatch: { ...marketsByMatchPage },
    matches: { ...matches },
    competitionsBySport: { ...competitionsBySportPage },
    allSports: { ...allSportsPage },
    locationNotAllowed: { ...locationNotAllowedPage },
    updateEmail: { ...updateEmailPage },
    accountCreation: { ...accountCreationPage },
    depositLimit: { ...depositLimitPage },
    forgotPassword: { ...forgotPasswordPage },
    login: { ...login },
    deposit: { ...deposit },
    accountOverview: { ...accountOverviewPage },
    verifyIdentity: {
      greenID: { ...verifyAccount },
    },
    settings: { ...settings },
    termsAndPrivacy: { ...termsAndPrivacy },
    accountDepositLimit: { ...accountDepositLimit },
    withdrawFunds: { ...withdrawFunds },
  },
  features: {
    betslip: {
      betSlipBetCard: { ...betSlipBetCard },
      betSlipCTA: { ...betSlipCTA },
      betSlipModal: { ...betSlipModal },
    },
    home: {
      nextToJumpList: { ...nextToJumpList },
      eventTile: { ...eventTile },
      featuredEvents: { ...featuredEvents },
      buttonGroup: { ...buttonGroup },
    },
    account: {
      desktopTransactionsTable: { ...desktopTransactionsTable },
      mobileTransactionsTable: { ...mobileTransactionsTable },
      myBetsCard: { ...myBetsCard },
    },
    sports: {
      listSection: { ...listSection },
      propositions: { ...propositions },
    },
    racing: {
      raceRunnerListItem: { ...raceRunnerListItem },
      raceMeetingsTable: { ...raceMeetingsTable },
      raceResults: { ...raceResults },
    },
  },
};

export default coreTheme;
