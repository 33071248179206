import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'beta.700',
    boxShadow:
      '0px 2px 3px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'md',
    my: 2,
    px: 3,
  },
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    mt: '-2',
  },
  promotionWrapper: {
    my: '3',
    bgGradient: `linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))`,
    boxShadow:
      '0px 1px 5px 0px rgba(130, 151, 222, 1) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.45)',
    borderRadius: 'md',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
      '.chakra-button': {
        bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
        borderRadius: 'md',
        color: 'alpha.800',
        fontFamily: 'accent',
        fontSize: 'sm',
        fontWeight: 'normal',
        textTransform: 'capitalize',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        boxShadow:
          '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
        _hover: {
          bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
          boxShadow:
            '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
          color: 'alpha.400',
          textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
        },
        ':active, &[data-active="true"]': {
          bg: 'blackAlpha.500',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
          color: 'alpha.400',
          textShadow: 'unset',
        },
        _disabled: {
          bg: 'blackAlpha.400',
          color: 'gray.300',
          textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
          boxShadow:
            '0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
          pointerEvents: 'none',
        },

        '&&&': {
          '& > span, & > svg': { zIndex: 9 },
        },
      },
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    borderTop: '3px solid',
    borderColor: 'beta.500',
    mx: '1px',
  },
  termsAndConditions: {
    color: 'white',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
