import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema, TButton } from './Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    boxSize: '8',
    minW: 'unset',
    sx: {
      '&&': {
        h: '8',
        w: 'auto',
      },
    },
  },
};

export const Button = ({ isSelected }: TButton): CSSObject => ({
  borderRadius: 'base',
  bgGradient: isSelected
    ? 'linear(to-b, gamma.700, gamma.700)'
    : 'linear(to-b, alpha.500, gamma.500)',
  color: isSelected ? 'white' : 'gamma.800',
  boxShadow: isSelected && 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  border: 'none',
  py: '1',
  boxSize: '8',
  fontSize: 'sm',
  fontWeight: 'semibold',
  minWidth: 'unset',
  '&:hover': {
    color: isSelected ? 'white' : 'gamma.600',
    bgGradient: !isSelected && 'linear(to-b, alpha.500, alpha.500)',
  },
  '&:disabled': {
    boxShadow: 'none',
    cursor: 'default',
    '&&': {
      color: 'gray.500',
      bg: 'blackAlpha.200',
    },
  },
  '&:disabled:hover': {
    bg: 'none',
  },
});

export const FlexOdds: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  alignItems: 'center',
  w: '12',
  justifyContent: 'center',
  px: '1',
  py: ['1', null, '2'],
  minW: '10',
  textAlign: 'center',
  borderRadius: 'base',
  whiteSpace: 'nowrap',
  mr: '0',
  mt: ['2', null, 'unset'],
  bg: 'blackAlpha.200',
  color: 'gray.600',
  h: '30px',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
};

export const HStackActions: CSSObject = {
  gap: '0',
};
