import { CSSObject } from '@chakra-ui/react';
import mascot from '@/assets/fiestabet/images/header/sombrero.png';
import woodBg from '@/assets/fiestabet/images/header/redwood.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'xs',
  fontWeight: 'bold',
  h: 5,
};

export const BoxBallance: CSSObject = {
  bgImage: woodBg,
  bgPos: '272px 156px',
  mx: '1',
  borderBottomRadius: 'base',
  '> div:first-of-type': {
    color: 'epsilon.500',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
        fontWeight: 'black',
      },
    },
  },
  '> div:last-of-type': {
    color: 'white',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
        fontWeight: 'black',
      },
    },
  },
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  fontSize: 'xs',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
  w: 'full',
  mt: '2',
  sx: {
    svg: {
      mb: '1',
    },
  },
  _after: {
    bgImage: 'unset',
  },
};

export const ButtonNavItem = (): CSSObject => ({
  bg: 'white',
  color: 'delta.600',
  borderTop: '1px',
  borderColor: 'gray.200',

  '&& > p': { transition: 'all 0.2s ease-in-out' },
  ':first-child': {
    borderTop: 'none',
  },
  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.100',
  },
});

export const PopoverHeaderQA: CSSObject = {
  bgImage: woodBg,
  mx: '1',
  mt: '1',
  border: 'none',
  borderTopRadius: 'base',
  pb: '0',
  pt: '1.5',
  color: 'white',
  lineHeight: 'normal',
};

export const PopoverContentQA: CSSObject = {
  bg: 'white',
  boxShadow: '2xl',
  _focus: {
    boxShadow: '2xl',
  },
};

export const TextBalanceValue: CSSObject = {
  color: 'alpha.500',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'xs',
  lineHeight: '1.8',
  fontWeight: 'bold',
  h: 5,
};

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${mascot})`,
  bgSize: '100% 100%',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '5',
  mt: '2',

  display: ['none', null, null, null, 'block'],

  w: ['10', '10', '28'],
  h: ['10', '10', '85px'],
};
