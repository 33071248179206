import { ButtonProps, CSSObject } from '@chakra-ui/react';
import bg from '@/assets/puntcity/images/homeFeaturedSport/promo-bg.png';
import bgMobile from '@/assets/puntcity/images/homeFeaturedSport/promo-bg-mobile.png';

export const FlexWrapper: CSSObject = {
  border: 0,
  borderBottom: '3px solid',
  borderBottomColor: 'gamma.400',
  borderRadius: 'none',
  bg: [`url(${bgMobile})`, null, `url(${bg})`],
  bgSize: ['cover', null, 'cover'],
  bgRepeat: 'no-repeat',
  bgPos: ['center 20px', null, 'right'],
  mb: ['4'],
  py: ['9'],
  pb: ['218px', null, '9'],
};

export const FlexTeamWrapper: CSSObject = {
  mb: '3',
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '4',
};

export const TextVS: CSSObject = {
  boxSize: '8',
  minH: '8',
  minW: '8',
  bg: 'linear-gradient(180deg, #A9EBFF 0%, #51D0F7 100%)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  fontSize: 'sm',
  fontWeight: '900',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  color: 'alpha.800',
  my: '0.5',
};

export const TextMisc: CSSObject = {
  borderRadius: 'lg',
  background: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  color: 'beta.500',
  fontSize: 'xs',
  fontWeight: '800',
};

export const TextTeam: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: 'auto',
};

export const HeadingSlogan: CSSObject = {};

export const SpanSlogan: CSSObject = {};

export const puntButtonProps: ButtonProps = {
  variant: 'secondary',
};
