import React, { FC } from 'react';
import { InfoOutline } from '@styled-icons/evaicons-outline';
import { FormattedMessage } from 'react-intl';
import {
  FlexRaceAvailableWrapper,
  IconRaceAvailableInfo,
  TextRaceAvailability,
} from '../styles/RaceTypeAvailable/RaceTypeAvailable.styles';

type TRaceTypeNotAvailableProps = {
  type: string;
};

const RaceTypeNotAvailable: FC<TRaceTypeNotAvailableProps> = ({ type }) => (
  <FlexRaceAvailableWrapper>
    <IconRaceAvailableInfo as={InfoOutline} />
    <TextRaceAvailability>
      <FormattedMessage
        id="racing.errormessages.racetypeavailable"
        values={{
          types: () => type,
        }}
      />
    </TextRaceAvailability>
  </FlexRaceAvailableWrapper>
);

export default RaceTypeNotAvailable;
