import { NewMatchCardSchema } from '@/views/sports/components/NewMatchCard/styles/NewMatchCard.styles';

export const newMatchCardStyles: NewMatchCardSchema = {
  boxWrapper: {
    border: 'none',
    borderRadius: 'lg',
    boxShadow:
      '0px 1px 3px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px var(--bc-colors-beta-100) inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
    overflow: 'hidden',
    padding: '1',
  },
  bodyStack: {
    p: 2,
    borderTopRadius: 'md',
    mb: '0',
    bg: 'beta.100',
    fontWeight: 'bold',
  },
  titleHeading: {
    cursor: 'pointer',
    as: 'h5',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontFamily: 'heading',
    fontSize: 'lg',
    fontWeight: 'normal',
    color: 'beta.800',
  },
  footerFlex: {
    as: 'footer',
    bg: 'beta.100',
    boxShadow: '0px -1px 0px 0px var(--bc-colors-whiteAlpha-200)',
    fontSize: '2xs',
    fontWeight: 'semibold',
    px: '2',
    py: '1',
    borderTop: '1px',
    borderColor: 'blackAlpha.300',
    m: '0',
    mt: '0',
    borderBottomRadius: 'base',
    alignItems: 'center',
    gap: '1',
  },
  footerStack: {
    direction: 'row',
  },
  footerStackDivider: {
    height: '3.5',
    borderColor: 'blackAlpha.400',
    mx: '1',
  },
  footerSportNameFlex: {
    align: 'center',
    gap: '1',
    maxW: '85%',
    w: 'fit-content',
    mr: '2',
  },
  footerSportNameIconSvg: {
    color: 'beta.800',
    fontSize: 'md',
  },
  footerCompetitionNameText: {
    color: 'beta.800',
    fontWeight: 'normal',
    fontFamily: 'body',
    sx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 'initial',
    },
  },
  footerCountDown: {
    sx: {
      '&&&.state-prior': {
        textTransform: 'uppercase',
        bg: 'alpha.900',
        color: 'white',
      },
    },
    sxWrapper: {
      mr: 'auto',
    },
  },
  footerDuotoneSgmLogo: {
    h: 'auto',
    primaryColor: 'alpha.500',
    secondaryColor: 'beta.600',
    w: '10',
    sx: {
      svg: {
        color: 'alpha.500',
      },
    },
  },
  footerMatchLink: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: 'initial',
    fontWeight: 'normal',
    color: 'beta.800',
    fontSize: '2xs',
  },
  footerMatchLinkIcon: {
    color: 'beta.800',
    fontSize: '2xs',
  },
};
