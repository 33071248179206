import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'gamma.400';
      }
      if (state === 'ended') {
        return 'red.500';
      }
      // 'prior'
      return 'gamma.500';
    },
    color: () => {
      if (state === 'inprogress') {
        return 'beta.700';
      }
      if (state === 'ended') {
        return 'white';
      }
      // 'prior'
      return 'gamma.800';
    },
    borderRadius: 'sm',
    px: 1,
    fontFamily: 'roboto',
    fontWeight: 'bold',
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
});
