import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';
import menuBg from '@/assets/oldgill/images/sideNav/menuBg.png';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: 'alpha.500',

      background: {
        bgGradient: [
          `url(${menuBg})`,
          null,
          null,
          'linear(to-b, alpha.300, alpha.400)',
        ],
        bgRepeat: ['no-repeat', null, null, 'unset'],
        bgSize: ['cover', null, null, 'unset'],
        borderRadius: 'lg',
        px: ['0', null, null, '4'],
        py: ['0', null, null, '1'],
        mb: ['0', null, null, '3'],
        boxShadow:
          '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: '23px',
        mr: '2',
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.35))',
        color: 'beta.400',
      },
      headerSX: {
        cursor: 'default',
        color: 'beta.400',
        fontSize: 'sm',
        height: '45px',
        borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
        '&:hover': {
          borderColor: 'transparent',
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,
        textTransform: 'uppercase',
        fontSize: 'xs',
        fontWeight: 'normal',
        height: '45px',
        color: 'white',
        fontFamily: 'Bowlby One',
        transition: 'all, 0.2s linear',
        borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',

        ':last-child': {
          borderBottom: 'none',
          boxShadow: 'none',
        },

        px: [3, null, null, 0],
        py: 3,
        ml: '0',

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          bg: 'whiteAlpha.200',
        },

        ':active': {
          background: 'gamma.900',
          opacity: '1',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '0'],
          borderBottom: [
            'none',
            null,
            null,
            '1px dashed rgba(0, 0, 0, 0.2) !important',
          ],
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
        },

        '> a, .dropDownIcon, a': {
          color: 'beta.400',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '0'],
            borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',

        '.dropDownInner': {
          px: '2.5',
          color: 'alpha.200',
          bg: 'blackAlpha.400',
          textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
          borderLeftWidth: '3px',
          borderLeftColor: 'beta.400',
          borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
          'svg:not(.dropDownIcon)': {
            fill: 'beta.400',
          },
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            color: 'alpha.200',
            bg: 'blackAlpha.400',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'beta.400',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
