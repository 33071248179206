import { BoxProps, chakra, LinkProps, Text } from '@chakra-ui/react';
import { styleImports } from './ForgotPassword.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import Button from '../conditionals/Button/template';

const themeName = getThemeName();

export const ForgotPasswordButton = chakra(Button, {
  label: 'forgotPassword-ForgotPasswordButton',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ForgotPasswordButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextForgotPasswordSuccess = chakra(Text, {
  label: 'forgotPassword-TextForgotPasswordSuccess',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'sm',
      fontWeight: 'medium',
      mb: '2',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextForgotPasswordSuccess;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const linkLoginProps: LinkProps = {
  'data-testid': 'forgotPassword-linkLoginProps',
  ...(() => {
    try {
      return styleImports[themeName]?.linkLoginProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const wrapperProps: BoxProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.wrapperProps;
    } catch (err) {
      return {};
    }
  })(),
};
