import { CSSObject } from '@chakra-ui/react';

export const IconPerson: CSSObject = {
  boxSize: '5',
  mr: '1',
};

export const TextAccordion: CSSObject = {
  flex: '1',
  textAlign: 'left',
  fontWeight: 'medium',
  color: 'alpha.700',
  fontSize: 'sm',
};

export const FlexContactToChange: CSSObject = {
  bg: 'alpha.600',
  p: '2',
  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
  borderRadius: 'base',
  w: 'full',
  color: 'white',
  fontSize: 'xs',
  gap: '2',
  alignItems: 'center',
};

export const Accordion: CSSObject = {
  borderRadius: 'base',
  bg: 'gray.200',
  border: '1px',
  borderColor: 'gray.300',
};

export const AccordionHeader: CSSObject = {
  bg: 'white',
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'semibold',
  textAlign: 'left',
};
