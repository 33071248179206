import React from 'react';
import {
  Box,
  chakra,
  ChakraComponent,
  Flex,
  Icon,
  IconProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Clock } from '@styled-icons/fa-regular';
import { styleImports } from './Header.styles.imports';
import IconSvg, { TIconSvg } from '../../../../../../components/common/IconSvg';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxHeaderWrapper = chakra(Box, {
  label: 'header-BoxHeaderWrapper',
  baseStyle: () => ({
    borderBottom: '1px',
    borderBottomStyle: 'dashed',
    borderBottomColor: 'gray.300',
    pb: '2',
    color: 'beta.600',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeaderWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const textTeamNameProps: TextProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.textTeamNameProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const FlexHeadingMiscWrapper = chakra(Flex, {
  label: 'header-FlexHeadingMiscWrapper',
  baseStyle: () => ({
    lineHeight: 'none',
    alignItems: 'center',
    gap: '2.5',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexHeadingMiscWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexMiscContentWrapper = chakra(Flex, {
  label: 'header-FlexMiscContentWrapper',
  baseStyle: () => ({
    gap: '0.5',
    alignItems: 'center',

    '.chakra-badge.state-prior': {
      fontWeight: 'bold',
      fontSize: 'xs',
      color: 'beta.600',
      p: '0',
    },
  }),
});

// --

export const BoxDot = chakra(Box, {
  label: 'BoxDot',
  baseStyle: () => ({
    boxSize: '1',
    borderRadius: 'full',
    border: '1px',
    borderColor: 'black',
    bg: 'gray.400',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxDot;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextSportName = chakra(Text, {
  label: 'TextSportName',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'xs',

    ...(() => {
      try {
        return styleImports[themeName]?.TextSportName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

// --

function CustomIconClock(props: IconProps) {
  return <Icon as={Clock as any} {...(props as any)} />;
}
export const IconClock = chakra(CustomIconClock, {
  baseStyle: () => ({
    boxSize: '4',
  }),
}) as ChakraComponent<'svg', IconProps>;

function CustomIcon(props: TIconSvg) {
  return <IconSvg {...(props as any)} />;
}
export const IconSport = chakra(CustomIcon, {
  baseStyle: () => ({
    lineHeight: 'none',
    boxSize: '4',
  }),
}) as ChakraComponent<'svg', TIconSvg>;

// --

export const TextVS = chakra(Text, {
  label: 'TextVS',
  baseStyle: () => ({
    fontSize: 'md',
    textTransform: 'lowercase',

    ...(() => {
      try {
        return styleImports[themeName]?.TextVS;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;
