import {
  ButtonGroupProps,
  ButtonProps,
  BoxProps,
  FlexProps,
} from '@chakra-ui/react';
import { styleImports } from './ScrollButtonGroup.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { TIconProps } from '@/components/common/IconSvg';

const themeName = getThemeName();

export type ScrollButtonGroupSchema = Partial<{
  flexWrapper: FlexProps;
  buttonGroupSports: ButtonGroupProps;
  buttonGroupScrolls: ButtonGroupProps;
  scrollIndicator: BoxProps;
  iconButtonScrollLeft: ButtonProps;
  iconButtonScrollLeftSvg: TIconProps;
  iconButtonScrollRight: ButtonProps;
  iconButtonScrollRightSvg: TIconProps;
}>;

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    'data-testid': 'scrollButtonGroup-iconButtonScrollRightSvg',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles
          ?.iconButtonScrollRightSvg;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconButtonScrollRight: {
    'data-testid': 'scrollButtonGroup-iconButtonScrollRight',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles
          ?.iconButtonScrollRight;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconButtonScrollLeftSvg: {
    'data-testid': 'scrollButtonGroup-iconButtonScrollLeftSvg',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles
          ?.iconButtonScrollLeftSvg;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconButtonScrollLeft: {
    'data-testid': 'scrollButtonGroup-iconButtonScrollLeft',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles
          ?.iconButtonScrollLeft;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonGroupScrolls: {
    'data-testid': 'scrollButtonGroup-buttonGroupScrolls',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles
          ?.buttonGroupScrolls;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonGroupSports: {
    'data-testid': 'scrollButtonGroup-buttonGroupSports',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles
          ?.buttonGroupSports;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexWrapper: {
    'data-testid': 'scrollButtonGroup-flexWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles?.flexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  scrollIndicator: {
    'data-testid': 'scrollButtonGroup-scrollIndicator',

    ...(() => {
      try {
        return styleImports[themeName]?.scrollButtonGroupStyles
          ?.scrollIndicator;
      } catch (err) {
        return {};
      }
    })(),
  },
};
