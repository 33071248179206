import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  fontSize: 'xs',
  '.chakra-switch__label': {
    color: 'gamma.200',
  },
  'button[data-cy="promotionsPreferencesSubmit"]': {
    w: '100px',
  },
};

export const TextS: CSSObject = {
  fontSize: 'xs',
};
