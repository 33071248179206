import api from '../api';
import {
  TSportMarketsResponse,
  TSportMarketsParams,
} from './sportDetailsMarkets.types';

export const sportMarketsEndpoint = 'punter/sports/sport-details/markets';

export const querySportMarkets = (params: TSportMarketsParams) =>
  api
    .get<TSportMarketsResponse>(sportMarketsEndpoint, { params })
    .then((res) => res.data);
