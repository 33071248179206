import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  headerSportText: {
    fontSize: 'lg',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    color: 'gamma.200',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: 'widest',
  },
  boxWrapper: {
    px: 0,
    mt: '-2',
  },
  accordionButton: {
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      fontSize: 'sm',
    },
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'gamma.200',
    fontSize: 'xs',
    fontWeight: 'normal',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      fontFamily: 'Staatliches',
      color: 'gamma.200',
      fontsize: 'xl',
      my: '2',
      fontWeight: 'normal',
    },
  },
  topLeaguesAccordionItem: {
    mt: '3',
    borderBottomRadius: 'base',
    p: '1px',
    bg: 'alpha.200',
    borderRadius: 'md',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    bg: 'alpha.200',
    borderRadius: 'md',
    mb: '2',
    sx: {
      _last: {
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'gamma.200',
    sx: {
      display: 'flex',
      p: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: 'unset',
      svg: {
        color: 'gamma.400',
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    color: 'gamma.200',
    fontFamily: 'Staatliches',
    sx: {
      img: {
        borderRadius: 'base',
        filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25)',
      },
    },
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionPanel: {
    px: '2',
    pb: '3',
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'gamma.500',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'gamma.200',
    fontSize: 'sm',
    fontWeight: 'normal',
    sx: { _hover: { textDecoration: 'none' } },
  },
  accordionIcon: { color: 'gamma.500' },
  accordionPanel: {
    px: '2',
    py: '0',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'gamma.500',
    px: '2',
  },
  topLeaguesText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    fontFamily: 'accentStaat',
    color: 'gamma.200',
    fontSize: 'md',
    px: 0,
  },
  navWrapper: {
    pb: '0',
  },
};
