import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: {
    spacing: '1',
  },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    borderRadius: 'base',
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    zIndex: '1',
    lineHeight: 'tall',
    bgGradient: 'linear(180deg, brand.600 0%, brand.700 100%)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
    color: 'white',
    fontSize: '2xs',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.20)',
    sx: {
      '&.active': {
        color: 'gray.600',
        bg: '#CBD5E0',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset',

        '.chakra-icon': {
          color: 'gray.600',
        },
      },
    },
    _hover: {
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
      bg: 'brand.800',
    },
  },
};
