import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FlexBannerBalanceInner,
  TextAccountBalanceLabel,
  FlexAccountBalanceWrapper,
  TextAccountBalanceCurrencySymbol,
  TextAccountBalanceBaseUnit,
  TextAccountBalanceSubUnit,
} from './styles/Banner.styles';

type TBannerBalanceProps = {
  formattedBalance: string;
  isBonus?: boolean;
};

const BannerBalance: React.FC<TBannerBalanceProps> = ({
  formattedBalance,
  isBonus,
}) => (
  <FlexBannerBalanceInner>
    <TextAccountBalanceLabel>
      <FormattedMessage
        id={isBonus ? 'account.overview.bonusBets' : 'account.overview.balance'}
      />
    </TextAccountBalanceLabel>
    <FlexAccountBalanceWrapper>
      <TextAccountBalanceCurrencySymbol as="span" isBonus={isBonus}>
        {formattedBalance[0]}
      </TextAccountBalanceCurrencySymbol>
      <TextAccountBalanceBaseUnit isBonus={isBonus} as="span">
        {formattedBalance.substring(1).split('.')[0]}
      </TextAccountBalanceBaseUnit>
      <TextAccountBalanceSubUnit isBonus={isBonus} as="span">
        .{formattedBalance.split('.')[1]}
      </TextAccountBalanceSubUnit>
    </FlexAccountBalanceWrapper>
  </FlexBannerBalanceInner>
);

export default BannerBalance;
