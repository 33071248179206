import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    '&:disabled': { bg: 'transparent' },

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px dashed',
      borderTopColor: 'blackAlpha.500',
      p: '2',

      _first: { borderTop: 0 },
    }),
    ...(!!isSelected && {}),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',
    color: 'white',

    _first: {
      fontWeight: 'bold',
      bg: 'transparent',
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      bgGradient: isSelected
        ? 'linear(to-b, alpha.800, alpha.800)'
        : 'linear(to-b, alpha.400, alpha.500)',
      boxShadow: isSelected
        ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset'
        : '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
      p: 2,
      w: 'full',
      borderRadius: 'md',
      color: isSelected ? 'alpha.300' : 'alpha.800',
      fontSize: 'md',
      fontFamily: 'Fredoka',
      textShadow: isSelected ? 'none' : '0px 1px 0px rgba(255, 255, 255, 0.45)',
      transition: 'all, .3s ease-in-out',
      border: 'none',

      _hover: {
        bgGradient: isSelected
          ? 'gamma.600'
          : 'linear(to-b, alpha.600, alpha.600)',
        color: isSelected ? 'alpha.300' : 'alpha.800',
        textShadow: 'unset',
      },

      ...((propLength ?? 0) > 2 && {
        w: '60px',
        minW: ['58px', '60px'],
      }),
    },
  },
});
