import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  h: '44px',
  lineHeight: 'normal',
  _disabled: {
    opacity: '0.4',
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
};

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderColor: 'epsilon.200',
  borderStyle: 'dashed',
  opacity: '1',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
  bg: 'red.500',
};

export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  bg: 'beta.700',
  color: 'white',
  boxShadow:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.31), 0px 1px 0px 0px rgba(0, 0, 0, 0.35) inset',
};

export const TextMaxCards: CSSObject = {
  color: 'delta.100',
};

export const BoxInputContainer: CSSObject = {
  mb: '1',

  '.chakra-form-control': {
    mb: '0',
  },

  '&& .chakra-input__left-addon': {
    bg: 'alpha.300',
    color: 'alpha.800',
  },

  '&& .chakra-input': {
    bg: 'gray.50',
    borderTop: '1px',
    borderRight: '1px',
    borderBottom: '1px',
    borderColor: 'gray.300',
    _placeholder: {
      color: 'gray.700',
      fontSize: 'xs',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const FormBox: CSSObject = {
  'div.chakra-collapse div': {
    bg: 'beta.700',
    border: 'none',
  },
  'div.chakra-collapse div svg': {
    color: 'alpha.400',
  },
  'div.chakra-collapse div p strong': {
    color: 'alpha.800',
  },
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'alpha.300',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'regular',
  lineHeight: '14.4px',
  color: 'white',
  mt: '0.5',
};
