import { User, AuthError } from 'firebase/auth';

import { WhereFilterOp, QuerySnapshot, DocumentData } from 'firebase/firestore';

export type AuthUser = User | null;

export enum AuthErrorCode {
  INVALID_PASSWORD = 'auth/wrong-password',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled',
  EMAIL_EXISTS = 'auth/email-already-in-use',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  INVALID_LOGIN_CREDENTIALS = 'auth/invalid-login-credentials',
}

export type FirebaseAuthError = AuthError;

/* == Firestore Related Types == */
export type FirestoreQuerySnapshot = QuerySnapshot<DocumentData>;
export type FirestoreDocTypeUnion = FirestoreTestDoc & FirestoreTest2Doc;
export type FirestoreFieldKeys = keyof FirestoreDocTypeUnion;
export type FirestoreCollections = string | 'test' | 'none';

export interface FirestoreQuery {
  firstTerm: FirestoreFieldKeys;
  condition: WhereFilterOp;
  secondTerm: unknown;
}

export interface SnapshotResult<T> {
  data?: T[];
  error?: Error;
}

export interface FirestoreTestDoc {
  id: string;
  test: number;
}
export interface FirestoreTest2Doc {
  id: string;
  test: number;
  val: number;
}
