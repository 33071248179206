/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Text } from '@chakra-ui/react';
import { InfoCircle } from 'styled-icons/fa-solid';
import { EventsClosedContainer } from './EventsClosedBanner.styles';
import { useAppSelector } from '../../../../hooks/useRedux';
import { getRejectedMultiBet } from '../../Services/Betslip.utils';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { transformBetForLegacy, useBetSlipBets } from '@/store/BetSlipStore';
import { TBetSlipBet } from '../../Services/Betslip.types';

/**
 * Containers
 */
const EventsClosedBannerReduxContainer = () => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <EventsClosedBanner bets={bets} />;
};

const EventsClosedBannerZustandContainer = () => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <EventsClosedBanner bets={bets} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? EventsClosedBannerZustandContainer
  : EventsClosedBannerReduxContainer;

/**
 * View
 */
type EventsClosedBannerProps = {
  bets: TBetSlipBet[];
};

export const EventsClosedBanner = ({ bets }: EventsClosedBannerProps) => {
  const rejectedMultiBet = getRejectedMultiBet(bets);
  const betCanBeResubmitted =
    rejectedMultiBet?.legs.filter((leg) => !leg.eventClosed).length >= 2;

  return (
    <EventsClosedContainer>
      <Icon mt="1px" boxSize={4} mr={2} as={InfoCircle} />
      <Text>
        <FormattedMessage
          id={`betslip.betslipbetcard.errors.multi.propositionClosed.${
            betCanBeResubmitted ? 'updateConfirm' : 'removeBet'
          }`}
        />
      </Text>
    </EventsClosedContainer>
  );
};
