import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  px: ['2.5', null, null, '3'],
};

export const BoxWrapper: CSSObject = {
  borderRadius: 'lg',
  border: 'unset',
};

export const LinkItem = () => ({
  p: '2',
  py: '2',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.300',
  color: 'alpha.900',
  transition: 'all, .1s ease-in-out',
  bg: 'white',
  fontSize: 'sm',

  _hover: {
    bg: 'blackAlpha.300',
  },

  svg: {
    boxSize: '5',
    p: '1',
    color: 'alpha.900',
    border: '1px',
    borderColor: 'alpha.900',
    borderRadius: 'full',
  },
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};
