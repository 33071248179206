import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';
import koi from '@/assets/goldenbet888/images/sideNav/koi.png';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: 'beta.500',

      background: {
        bg: ['alpha.400', null, 'transparent'],
        borderRadius: 'lg',
        boxShadow: [
          '0px 4px 20px 0px rgba(0, 0, 0, 0.4)',
          null,
          '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
        ],
        backgroundImage: [
          'unset',
          null,
          `url(${koi}), linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-500))`,
        ],
        backgroundPosition: '-370px 0px',
        bgSize: '364px',
        bgBlendMode: 'overlay',
        px: ['0', null, null, '5'],
        py: ['0', null, null, '6'],
        mb: ['0', null, null, '2.5'],
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        ':not(.dropDownIcon)': {
          boxSize: '26px',
          mr: '2',
          p: '1',
          borderRadius: '3px',
          color: 'alpha.600',
          bgGradient: 'linear(to-b, gamma.500, gamma.600)',
          boxShadow:
            '0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(255, 255, 255, 0.3) inset',
        },
      },
      headerSX: {
        cursor: 'default',
        fontSize: 'sm',
        color: 'gamma.200',
        fontFamily: 'accentMusashi',
        height: '38px',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        '&:hover': {
          bg: 'transparent',
          color: 'gamma.200',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,
        pos: 'relative',
        textTransform: 'uppercase',
        fontSize: 'md',
        fontWeight: 'normal',
        height: ['46px', null, '38px'],
        color: 'gamma.200',
        fontFamily: 'accentMusashi',
        transition: 'all, 0.2s linear',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        borderBottom: ['1px dashed rgba(0, 0, 0, 0.25)', null, 'none'],
        boxShadow: ['0px 1px 0px 0px rgba(255, 255, 255, 0.1)', null, 'unset'],
        letterSpacing: '1px',
        zIndex: '5',

        ':first-child': {
          borderTop: 'none',
        },
        ':last-child': {
          borderBottom: ['1px dashed rgba(0, 0, 0, 0.25)', null, 'none'],
          boxShadow: [
            '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
            null,
            'unset',
          ],
        },

        py: '3',
        px: ['3', null, null, '2'],
        ml: '0',

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          bg: 'alpha.100',
          color: 'alpha.800',
          '.dropDownIcon': {
            color: 'alpha.600',
          },
        },

        ':active': {
          background: 'alpha.700',
          opacity: '1',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
          borderBottom: ['1px dashed rgba(0, 0, 0, 0.25)', null, 'none'],
          boxShadow: [
            '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
            null,
            'unset',
          ],
        },

        '> a, .dropDownIcon, a': {
          color: 'gamma.200',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            height: ['46px', null, '38px'],
            px: ['3', null, null, '2'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        height: ['46px', null, '38px'],
        p: '0',

        '.dropDownInner': {
          px: '2',
          color: 'gamma.500',
          bg: 'alpha.700',
          'svg:not(.dropDownIcon)': {
            fill: 'alpha.600',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'gamma.500',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
