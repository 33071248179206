import { AnyAction, Store } from '@reduxjs/toolkit';
import React, { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProviderProps } from '@tanstack/react-query';
import Axios from './Axios';
import Intl from './Intl';
import Query from './Query';
import Redux from './Redux';
import StyleSystem from './StyleSystem';
import Theme from './Theme';

type TProviders = {
  children: ReactNode;
  store: Store<any, AnyAction>;
  client: QueryClientProviderProps['client'];
};

/**
 * Provider that imports all the applications
 * required providers.
 */
export default function Providers({ children, store, client }: TProviders) {
  return (
    <HelmetProvider>
      <Axios>
        <Query client={client}>
          <Redux store={store}>
            <Intl>
              <StyleSystem>
                <Theme>{children}</Theme>
              </StyleSystem>
            </Intl>
          </Redux>
        </Query>
      </Axios>
    </HelmetProvider>
  );
}
