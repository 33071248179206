import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'linear-gradient(180deg, #30363F 0%, #21252B 100%)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.25)',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  maxW: ['full', null, null, null, '272px'],
  borderRadius: [null, null, null, null, 'lg'],
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '0',
  px: '2.5',
  py: '1.5',
  mb: isKeypadVisible && 'unset',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  bg: '#21252B', // no token
  color: 'white',
  borderBottomRadius: ['none', null, null, null, 'xl'],
  borderTop: '1px solid rgba(255, 255, 255, 0.08)',
  boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.25)',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'semibold',
  fontFamily: 'CrimsonPro',
  fontSize: 'lg',
  color: 'whiteAlpha.700',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'extrabold',
  fontFamily: 'CrimsonPro',
  fontSize: 'lg',
  color: 'alpha.600',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  height: '44px',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  height: '44px',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  height: '25px',
  width: '74px',
  bgGradient: 'linear(to-b, beta.200, beta.300)',
  borderRadius: 'md',
  fontWeight: 'bold',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  color: 'alpha.500',
  minW: '16',
  border: '1px solid',
  borderColor: 'gray.400',
  px: '3',
  textShadow: 'none',

  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '-1',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      bg: 'beta.400',
    },
    '&&': {
      fontSize: '2xs',
      fontFamily: 'roboto',
      boxShadow:
        '0px 0px 0px 0px rgba(255, 255, 255, 0.15) inset, 0px 3px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 4px 0px rgba(255, 255, 255, 0.4) inset',

      _after: {
        bgGradient: 'linear(to-b, beta.200, beta.300)',
        opacity: 1,
      },
    },
  },
};
