import topDecorations from '@/assets/goldenrush/images/navbar/topDecorations.png';
import bottomDecorations from '@/assets/goldenrush/images/navbar/bottomDecorations.png';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',

    _before: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'relative',
      top: '30px',
      left: '-7px',
      width: '266px',
      height: '120px',
      zIndex: '5',
      bg: `url(${topDecorations})`,
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
    },
    _after: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'relative',
      bottom: '-36px',
      right: '-11px',
      width: '266px',
      height: '120px',
      zIndex: '4',
      bg: `url(${bottomDecorations})`,
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
    },
  },
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: '100%',
    _first: {
      mt: ['1', null, null, '-105px'],
      mb: ['1', null, null, '-120px'],
    },
    bgGradient: ['none', null, null, 'linear(to-b, beta.600, beta.400)'],
    px: ['8', null, null, '2.5'],
    pt: '4',
    pb: '1',
    borderRadius: ['none', null, null, 'lg'],
    boxShadow: ['none', null, null, '0px 3px 6px 0px rgba(0, 0, 0, 0.25)'],
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '1',
      right: '1',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      zIndex: '3',
      borderRadius: 'md',
      bgGradient: ['none', null, null, 'linear(to-b, beta.400, beta.600)'],
      boxShadow: ['none', null, null, '0px 1px 0px 0px #ED8934'],
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '2',
      left: '2',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      zIndex: '4',
      borderRadius: 'base',
      bgGradient: ['none', null, null, 'linear(to-b, alpha.500, alpha.600)'],
      boxShadow: [
        'none',
        null,
        null,
        '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
      ],
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    boxShadow: [
      'none',
      null,
      null,
      '0px 6px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px #AD8B4C, 0px 2px 1px 0px rgba(255, 255, 255, 0.30) inset',
    ],
  },
};
