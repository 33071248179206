import React from 'react';
import { Flex, Icon, Text, Tooltip, useMediaQuery } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { CaretDownFill } from '@styled-icons/bootstrap/CaretDownFill';
import { FormattedMessage } from 'react-intl';
import { flucsStyles } from './styles/Flucs.styles';
import { getMarketMoverAnimation } from './services/Flucs.utils';
import { TFlucsData } from '@/lib/DBModels';

type TFlucs = {
  flucs: TFlucsData;
  isRaceOpen?: boolean;
};

export default function Flucs({ flucs, isRaceOpen }: TFlucs) {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const reversedData =
    flucs && flucs?.flucs?.slice(0, isLargerThan768 ? 6 : 4).reverse();

  return (
    <Flex {...flucsStyles.wrapper}>
      {flucs?.opening_price && (
        <Flex {...flucsStyles.innerWrapper}>
          <Text {...flucsStyles.priceTitle}>
            <FormattedMessage id="generic.open" />:
          </Text>
          <Text {...flucsStyles.priceNumber}>
            {flucs.opening_price.toFixed(2)}
          </Text>
        </Flex>
      )}

      <Flex {...flucsStyles.innerWrapper}>
        <Text {...flucsStyles.priceTitle}>
          <FormattedMessage id="generic.recent" />:
        </Text>
        {!!reversedData?.length &&
          reversedData?.map((price, i) => {
            // Determine if the price has increased, very first price compared against the opening price
            const hasIncreased =
              price > (reversedData?.[i - 1] ?? flucs?.opening_price ?? 0);

            return (
              <Text
                key={i}
                data-increased={hasIncreased}
                {...flucsStyles.priceText}
                {...(hasIncreased && flucsStyles.priceTextIncreased)}
              >
                {price.toFixed(2)}
                <Icon
                  as={CaretDownFill}
                  data-increased={hasIncreased}
                  {...flucsStyles.priceIcon}
                  {...(hasIncreased && flucsStyles.priceIconIncreased)}
                />
              </Text>
            );
          })}
      </Flex>

      {!!flucs.market_mover &&
        !!getMarketMoverAnimation(flucs.market_mover) &&
        isRaceOpen && (
          <Tooltip
            hasArrow
            placement="bottom-start"
            label={
              <Text
                textTransform="uppercase"
                fontSize="xs"
                fontWeight="semibold"
              >
                {flucs.market_mover.split('Animation')}
              </Text>
            }
            {...flucsStyles.tooltipWrapper}
            isDisabled
          >
            <Flex {...flucsStyles.lottieWrapper}>
              <Lottie
                animationData={getMarketMoverAnimation(flucs.market_mover)}
                loop
              />
            </Flex>
          </Tooltip>
        )}
    </Flex>
  );
}
