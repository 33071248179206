import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'odds',
    h: 8,
    w: 'auto',
    sx: {
      '&&': {
        px: 1,
      },
    },
  },
};
