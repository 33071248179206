import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  '.chakra-input': {
    borderLeftRadius: 'base',
  },
  '.chakra-form__label': {
    mt: '0',
    mb: '0',
    pt: '2',
  },
  '.chakra-form__helper-text': {
    fontSize: '13px',
    mt: '1',
  },
};
