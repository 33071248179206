import React from 'react';
import { Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useMyBetsLength } from '../services/Header.hooks';
import { ButtonBets, ButtonGroupBets } from '../styles/Header.styles';
import { getStrings } from '@/helpers/utils';
import BetSlipBanner from './BetSlipBanner';
import QuickAccessMenuTemplate from './QuickAccessMenu/templates/template';
import { createTemplate } from '@/lib/templateSwitch';
/**
 * @deprecated The function should not be used anymore,
 * use AuthedV1 to template instead.
 */
export default function Authed() {
  const quickAccessMenu = createTemplate(QuickAccessMenuTemplate);

  const navigate = useNavigate();
  const bets = useMyBetsLength();
  const [{ Generic }] = getStrings();

  return (
    <>
      {quickAccessMenu}

      <ButtonGroupBets>
        <ButtonBets onClick={() => navigate('/account/my-bets')}>
          <Text as="span" className="span-bet">
            {Generic.Bets}
          </Text>
          <Text
            as="span"
            className="span-bet-count"
            data-cy="header-pending-bets"
          >
            {bets}
          </Text>
        </ButtonBets>

        <BetSlipBanner />
      </ButtonGroupBets>
    </>
  );
}
