import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: 'beta.500',

      background: {
        bg: 'alpha.500',
        bgRepeat: ['no-repeat', null, null, 'unset'],
        bgSize: ['cover', null, null, 'unset'],
        borderRadius: 'lg',
        px: ['0', null, null, '5'],
        py: ['0', null, null, '3'],
        mb: ['0', null, null, '4'],
        boxShadow:
          '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: '18px',
        mr: '2',
        filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
        color: 'alpha.100',
      },
      headerSX: {
        cursor: 'default',
        color: 'beta.500',
        fontSize: 'md',
        textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
        height: '45px',
        borderBottom: '1px dashed rgba(0, 0, 0, 0.2)',
        '&:hover': {
          bg: 'transparent',
          color: 'beta.500',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,
        textTransform: 'uppercase',
        fontSize: 'md',
        fontWeight: 'normal',
        height: '45px',
        color: 'white',
        fontFamily: 'Staatliches',
        transition: 'all, 0.2s linear',
        borderTop: '1px dashed',
        borderTopColor: 'whiteAlpha.300',
        borderBottom: '1px dashed',
        borderBottomColor: 'blackAlpha.300',
        textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',

        ':first-child': {
          borderTop: 'none',
        },
        ':last-child': {
          borderBottom: 'none',
        },

        py: '3',
        px: ['3', null, null, '2'],
        ml: '0',

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          bg: 'alpha.700',
          color: 'alpha.100',
          textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
        },

        ':active': {
          background: 'alpha.700',
          opacity: '1',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
          borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
        },

        '> a, .dropDownIcon, a': {
          color: 'alpha.100',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '2'],
            borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',

        '.dropDownInner': {
          px: '2.5',
          color: 'beta.500',
          bg: 'alpha.700',
          textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
          borderLeftWidth: '3px',
          borderLeftColor: 'beta.500',
          borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
          'svg:not(.dropDownIcon)': {
            fill: 'beta.500',
            filter: 'drop-shadow(0px 0px 8px rgba(251, 201, 21, 0.50))',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'beta.500',
          filter: 'drop-shadow(0px 0px 8px rgba(251, 201, 21, 0.50))',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
