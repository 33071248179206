import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  mt: ['2', '4'],
  p: '2.5',
  position: 'relative',
  width: ['calc(100% - 12px)', '100%'],
  // TODO: See if we can leverage tokens for below elements, currently necessary to create complicated border(s)
  bgGradient: 'linear(180deg, #A5540E 0%, #DB7E2F 100%)',
  borderRadius: 'lg',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '1',
    right: '1',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    zIndex: '3',
    borderRadius: 'md',
    background: 'linear-gradient(180deg, #DB7E2F 0%, #A5540E 100%)',
    boxShadow: '0px 1px 0px 0px #ED8934',
  },
  _after: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '2',
    left: '2',
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    zIndex: '4',
    borderRadius: 'base',
    background: 'linear-gradient(180deg, #2E6474 0%, #275563 100%)',
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
  },
};

export const BoxWrapper: CSSObject = {
  p: '3',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  zIndex: 'docked',
  pos: 'relative',
};

export const ButtonViewMore: CSSObject = {
  zIndex: 'docked',
  position: 'relative',
  p: '2.5',
  bg: 'alpha.700',
  pb: '-1',
  color: 'beta.300',
  textAlign: 'center',
  fontSize: 'sm',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
  fontFamily: 'Bungee',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  borderTopWidth: '1px',
  borderTopColor: 'beta.600',

  _hover: {
    bgGradient: 'none',
    bg: 'alpha.600',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  _active: {
    color: 'alpha.300',
    bg: 'alpha.800',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
  },
};
