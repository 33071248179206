import { Checkmark } from '@styled-icons/evaicons-solid/Checkmark';
import { CheckmarkCircle } from '@styled-icons/fluentui-system-filled/CheckmarkCircle';
import { XCircleFill } from '@styled-icons/bootstrap/XCircleFill';
import { Clock } from '@styled-icons/fa-solid/Clock';
import { Clock as Clock2 } from '@styled-icons/octicons/Clock';
import { IconProps } from '@chakra-ui/react';
import { getStrings } from '@/helpers/utils';
import { EEventType, TConfirmedBet, TMyBetsMultiBetLegs } from '@/lib/DBModels';
import { TMultiLeg } from '@/components/Betslip/Services/Betslip.types';
import { getThemeName } from '@/helpers/getThemeConfig';
import { isToteMulti } from '@/views/races/bets/Exotics/services/Exotics.utils';

const themeName = getThemeName();

export const getEventHref = (
  bet?: TConfirmedBet,
  leg?: TMultiLeg | TMyBetsMultiBetLegs
) => {
  // If leg is populated it's a multi, so assign all event data appropriately
  // Otherwise take event data from bet
  const {
    venue_name: venueName,
    race_number: raceNumber,
    venue_id: venueId,
    race_meeting_date: raceMeetingDate,
    competition_name: competitionName,
    sport_id: sportId,
    competition_id: competitionId,
  } = (leg ? leg.event_data : bet?.event_data) || {};
  const {
    event_type: eventType,
    event_icon: eventIcon,
    event_id: eventId,
  } = leg ?? bet;

  return eventType === EEventType.Race
    ? `/racing/${eventIcon}/${venueName}/R${raceNumber}?venueId=${venueId}&raceId=${eventId}&meetingDate=${raceMeetingDate}`
    : `/sports/${eventIcon}/${competitionName}/${
        leg?.event_data?.match_name ||
        bet?.event_data?.match_name ||
        bet?.event_title
      }?sportId=${sportId}&competitionId=${competitionId}&matchId=${eventId}`;
};

export const getEventDetails = (
  bet: TConfirmedBet,
  leg?: TMyBetsMultiBetLegs
) => {
  // If leg is populated it's a multi, so assign all event data appropriately
  // Otherwise take event data from bet
  const [
    {
      Account: { MyBetsCard: Strings },
    },
  ] = getStrings();

  const isToteMultiBet = isToteMulti(bet.bet_description as string);
  if (isToteMultiBet) {
    return [
      `${bet.event_data?.venue_name} Races ${bet.tote_multi_selections
        ?.map((s) => s?.leg)
        ?.join(', ')}`,
    ];
  }
  const {
    venue_name: venueName,
    match_name: matchName,
    race_number: raceNumber,
  } = (leg ? leg.event_data : bet.event_data) || {};
  return `${venueName || matchName} ${
    raceNumber ? `${Strings.RaceAbbv}${raceNumber}` : ''
  }`;
};

export const getEventStart = (bet: TConfirmedBet, leg?: TMyBetsMultiBetLegs) =>
  leg ? leg.event_start : bet.event_start;

type StatusIconProps = Partial<IconProps> & { icon: any };

export const getStatusIconStyles = (
  status: 'won' | 'lost' | 'pending' | 'voided'
): StatusIconProps | undefined => {
  const matchTheme = ['vikingbet'].includes(themeName);
  switch (status) {
    case 'won':
      return {
        icon: matchTheme ? CheckmarkCircle : Checkmark,
        bg: 'green.500',
        color: 'white',
        border: 'none',
      };
    case 'lost':
      return {
        icon: XCircleFill,
        bg: 'white',
        color: 'gray.300',
        border: 'none',
      };
    case 'pending':
      return {
        icon: matchTheme ? Clock2 : Clock,
        bg: 'yellow.400',
        color: 'yellow.900',
        border: '2.5px solid',
        borderColor: 'yellow.400',
      };
    case 'voided':
      return {
        icon: matchTheme ? Clock2 : Clock,
        bg: 'gray.600',
        color: 'gray.50',
        border: '2.5px solid',
        borderColor: 'gray.600',
      };
    default:
  }
};
