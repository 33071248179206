import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  bg: 'epsilon.400',
  borderColor: 'epsilon.200',
  borderBottomRadius: 'md',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};

export const TextTitle: CSSObject = {
  color: 'white',
};

export const LinkItem: CSSObject = {
  borderTopColor: 'gray.300',
  borderBottom: 0,
  borderBottomColor: ['transparent'],
  borderTop: '1px',
  color: 'epsilon.600',
  bg: 'white',

  _hover: { bg: 'epsilon.100' },
};

export const IconSport: CSSObject = {
  color: 'beta.700',
};

export const IconChevron: CSSObject = {
  boxSize: ['4', '5'],
  border: '1px',
  borderColor: 'beta.700',
  borderRadius: 'full',
  p: '1',
  color: 'beta.700',
  transition: '.3s ease-in-out',

  _hover: {
    color: 'beta.800',
  },
};
