import { Box } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import FormControl from '../../../../../components/FormElements/HOCs/FormControl';
import { getStrings } from '@/helpers/utils';
import { Input } from '../../../styles/onboarding.styles';
import Password from '../../../components/Password';
import { LinkForgotPassword } from '../../legacy/styles/Login.styles';
import {
  buttonLogInProps,
  forgotPasswordContainerProps,
} from './styles/Form.styles';
import { useAppSelector } from '@/hooks/useRedux';
import { EGreenIDStatus } from '@/views/account/VerifyIdentity/Services/Config.VerifyIdentity';
import { Button } from '@/components/Button/Button';

/**
 * Hybrid Formik/Uncontrolled approach needed due to fix Chrome & CRA
 * not being able to handle autofill on a controlled component.
 *
 * Issue is logged here.
 * https://github.com/facebook/react/issues/1159.
 *
 * Any questions please reach out to Mark.
 */
export default function Form() {
  const {
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    submitForm,
    setSubmitting,
  } = useFormikContext<Record<string, string>>();
  const email = useRef<HTMLInputElement | null>(null);
  const password = useRef<HTMLInputElement | null>(null);
  const { verificationStatus, accountOverview } = useAppSelector(
    (state) => state.punter
  );

  const [
    {
      Onboarding: { Login },
      Generic,
    },
  ] = getStrings();

  if (
    !accountOverview?.identity_verified &&
    verificationStatus === EGreenIDStatus.PENDING
  ) {
    toast.error(Login.Verification_In_Progress);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSubmitting(true);

        setFieldValue('email', email.current?.value);
        setFieldValue('password', password.current?.value);

        setTimeout(() => {
          submitForm().catch(() => undefined);
        }, 500);
      }}
    >
      <FormControl
        id="email-input"
        label={Generic.Email}
        error={!!touched.email && errors.email ? errors.email : undefined}
      >
        <Input
          data-cy="emailInput"
          type="email"
          name="email"
          autoComplete="email"
          aria-labelledby="field-1-label"
          ref={email}
          onFocus={(e) => {
            setFieldValue('email', e.target.value);
          }}
          onBlur={(e) => {
            setFieldTouched('email', true);
            setFieldValue('email', e.target.value);
          }}
        />
      </FormControl>

      <FormControl
        id="password-input"
        label="Password"
        error={
          !!touched.password && errors.password ? errors.password : undefined
        }
      >
        <Password
          ref={password}
          name="password"
          aria-labelledby="field-2-label"
          autoComplete="current-password"
          onFocus={(e) => {
            setFieldValue('password', e.target.value);
          }}
          onBlur={(e) => {
            setFieldTouched('password', true);
            setFieldValue('password', e.target.value);
          }}
        />
      </FormControl>

      <Box {...forgotPasswordContainerProps}>
        <LinkForgotPassword to="/forgot-password" data-cy="forgotPassword">
          {Login.ForgotPassword}
        </LinkForgotPassword>
      </Box>

      <Button
        {...buttonLogInProps}
        type="submit"
        isLoading={isSubmitting}
        data-cy="loginSubmit"
        isFullWidth
        sx={{ mb: '4' }}
      >
        {Login.Button}
      </Button>
    </form>
  );
}
