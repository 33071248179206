import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spacer, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  ButtonGetVerified,
  FlexGetVerified,
  GetVerifiedText,
} from './Styles/GetVerified.styles';

type TGetVerified = { onClose: () => void };

export function GetVerified({ onClose }: TGetVerified) {
  const navigate = useNavigate();

  return (
    <FlexGetVerified>
      <GetVerifiedText>
        <FormattedMessage
          id="qam.getVerified.desc"
          values={{
            underline: (text: string) => (
              <Text as="u" fontWeight="bold">
                {text}
              </Text>
            ),
          }}
        />
      </GetVerifiedText>
      <Spacer />
      <ButtonGetVerified
        data-cy="qamGetVerifiedButton"
        onClick={() => {
          onClose();
          navigate('/account/settings/verify-identity');
        }}
      >
        <FormattedMessage id="qam.getVerified.btn" />
      </ButtonGetVerified>
    </FlexGetVerified>
  );
}
