import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  mt: ['-3', null, '-5'],
  px: '3',
  py: '3',
  top: ['0px', '3'],
  borderBottomRadius: 'base',
};

export const IconCheck = ({ selected }: TSelected) => ({
  mr: '0.4',
  color: selected ? 'beta.400' : 'transparent',
  background: 'blackAlpha.500',
  boxShadow: '0px 1px 1px 0px rgba(65, 22, 65, 0.50) inset',
});

export const IconRace = () => ({
  path: {
    fill: 'blackAlpha.500',
    boxShadow: '0px 1px 1px 0px rgba(65, 22, 65, 0.50) inset',
  },
});

export const TextFilterLabel: CSSObject = {
  color: 'blackAlpha.500',
  fontFamily: 'Roboto',
  fontSize: '11px',
  fontStyle: 'normal',
  fontweight: '800',
  lineheight: 'normal',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '3',
  mr: 'unset',
};

export const Divider: CSSObject = {
  bg: 'blackAlpha.500',
  display: 'block',
};
