/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Icon } from '@chakra-ui/react';
import { OptionsOutline } from '@styled-icons/evaicons-outline/OptionsOutline';

export const ThemeFilters = [
  {
    name: 'btnContent',
    component: <Icon as={OptionsOutline} boxSize="4" />,
  },
];

export default ThemeFilters;
