import { CSSObject } from '@chakra-ui/react';

export const ForgotPasswordButton: CSSObject = {
  bg: 'beta.600',
  border: '2px solid',
  borderColor: 'beta.400',
  color: 'blackAlpha.600',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'base',
  fontFamily: 'Caveman',
  fontWeight: 'normal',
  letterSpacing: 'wide',
  fontSize: 'sm',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  _hover: {
    bg: 'beta.700',
  },
};
