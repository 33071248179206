import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Stop } from '@styled-icons/octicons/Stop';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';

export const BetApprovalContainer: CSSObject = {
  px: '0',
};

export const EstReturnsLabel: CSSObject = {
  color: 'white',
  bg: 'epsilon.700',
  fontWeight: 'extrabold',
  height: '100%',
  alignItems: 'center',
  py: '0.5',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  borderRadius: 'lg',
  h: '10',
  color: 'black',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  justifyContent: 'center',
  boxShadow: 'none',
  mx: '2',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
};

export const ApprovedBetContainer: CSSObject = {
  background: 'alpha.500',
  bgGradient: 'none',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'lg',
  color: '#1C4532',
  justifyContent: 'center',
  mx: '2',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
};

export const RejectedBetContainer: CSSObject = {
  background: 'gamma.500',
  bgGradient: 'none',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'lg',
  color: '#322659',
  justifyContent: 'center',
  mx: '2',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
};

export const FooterContainer: CSSObject = {
  bg: 'white',
  color: 'epsilon.700',
  border: '1px solid',
  borderColor: 'epsilon.800',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'md',
};

export const FooterText: CSSObject = {
  px: '1',
  fontWeight: 'medium',
};

export const IconRejectedBet = () => <Icon as={Stop} boxSize="4" mr="2" />;
export const IconApprovedBet = () => (
  <Icon as={CheckCircle} boxSize="4" mr="2" />
);
