import React from 'react';
import { TNextToJumpRace } from '@/lib/DBModels';
import { NextToJumpListWrapper } from '../styles/RaceMeetings.styles';
import Loading from './components/Loading';
import NextToJumpItem from './components/NextToJumpItem/NextToJumpItem';
import {
  useNextToJump,
  useNextToJumpQueries,
} from './services/NextToJump.hooks';
import NoRaces from '../NoRaces/NoRaces';
import { TQueryPromotionsResponse } from '@/api/punter/deposit/deposit.types';

type TNextToJump = {
  promotions?: TQueryPromotionsResponse;
};

export default function NextToJump({ promotions }: TNextToJump) {
  const queries = useNextToJumpQueries();
  const allRaces = queries.flatMap((data) => data.data);
  const $races = useNextToJump(allRaces as TNextToJumpRace[]);

  return (
    <NextToJumpListWrapper>
      {(() => {
        if (queries.map((q) => q.isInitialLoading).includes(true))
          return <Loading />;

        if (!queries.flatMap((q) => q.data).length) return <NoRaces />;

        return (
          <NextToJumpItem
            promotions={promotions}
            races={$races as TNextToJumpRace[]}
          />
        );
      })()}
    </NextToJumpListWrapper>
  );
}
