import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema, TTableHeading } from './RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'gray.400', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'gray.400'] },
};

export const FlexHeading: CSSObject = {
  bg: 'epsilon.500',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  color: 'white',
  top: ['12', '84px'],
  borderTopLeftRadius: 'md',
  borderTopRightRadius: 'md',
  position: 'unset',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'gamma.800',
  bg: 'gamma.500',
  borderRadius: 'sm',
  p: '1.5',
};

export const BoxPageWrapper: CSSObject = {
  mx: '4',
  mb: '4',
  borderRadius: 'md',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: 'md',
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'gray.700',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'gray.400',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'beta.200' : 'transparent',
  borderColor: 'gray.300',
  _hover: {
    background: ['transparent', null, 'gray.100'],
  },
  '.chakra-badge.state-inprogress': {
    color: 'black',
  },
  '.chakra-badge.state-prior': {
    color: 'gray.700',
  },
});

export const FlexRow: CSSObject = {
  bg: 'white',
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'gray.300'],
  borderTop: '1px',
};

export const CenterTH = ({ isFirst }: TTableHeading) => ({
  bg: 'gray.50',
  color: 'gray.800',
  ...(isFirst && {
    bg: 'gray.50',
  }),
});

export const ButtonChevron: CSSObject = {
  color: 'gamma.800',
  bg: 'gamma.500',
  borderRadius: 'md',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
