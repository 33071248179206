import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  'button[data-cy="updateDepositLimitButton"]': {
    borderRadius: 'md',
    border: '1px solid',
    borderImage:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.11))',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1))',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'beta.400',
    fontFamily: 'ProximaNova',
    fontSize: 'sm',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    _hover: {
      bgGradient: 'linear(to-b, whiteAlpha.400, whiteAlpha.400)',
    },
    ':active, &[data-active=true]': {
      bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      color: 'beta.400',
      textShadow: '0px 0px 8px rgba(37, 254, 214, 0.7)',
      border: 'none',
    },
    _disabled: {
      opacity: 0.4,
      pointerEvents: 'none',
    },
    '&&&': {
      '& > svg, & > span': { zIndex: 9 },
    },
  },
};
