import { Accordion, AccordionProps } from '@chakra-ui/react';
import React from 'react';
import { TMarketsAccordionProps } from '../services/MatchDetailPage.types';
import MarketsAccordionItem from './MarketsAccordionItem';

export default function MarketsAccordion({
  data,
  defaultIndex,
  onMarketChange,
  onSelection,
}: TMarketsAccordionProps) {
  const handleChange: AccordionProps['onChange'] = (expandedIndex) => {
    if (!Array.isArray(expandedIndex)) return;
    const expandedMarkets = expandedIndex
      .map((index) => data[index].market_id)
      .filter((marketId): marketId is string => !!marketId);
    onMarketChange?.(expandedMarkets);
  };

  return (
    <Accordion
      allowMultiple
      defaultIndex={defaultIndex}
      onChange={handleChange}
    >
      {data.map(({ market_id, market_name, disclaimer, propositions }) => (
        <MarketsAccordionItem
          key={market_id}
          marketName={market_name}
          disclaimer={disclaimer}
          propositions={propositions}
          onSelection={onSelection}
        />
      ))}
    </Accordion>
  );
}
