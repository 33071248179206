import { CSSObject } from '@chakra-ui/react';

export const ButtonShowMore: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  fontFamily: 'Fredoka',
  h: '44px',
  lineHeight: 'none',
  minW: '44px',
  pos: 'relative',
  textTransform: 'uppercase',
  w: undefined,
  fontSize: 'sm',
  fontWeight: 'bold',
  borderRadius: 'md',
  textShadow: '0 1px 0 rgba(255, 255, 255, 0.45)',

  '&:hover, &:focus, &:active': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },
};

export const FlexBetCard: CSSObject = {
  rowGap: '2',
  w: '100%',
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};
