import vikingBetBg from '@/assets/vikingbet/images/backgrounds/evenshot/bg-pattern.png';
import evenShot from '@/assets/vikingbet/images/backgrounds/evenshot/evenshot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    my: '2',
    fontWeight: 'bold',
    fontSize: 'xl',
  },
  flexWrapper: {
    py: '70px',
    mx: '4',
    mb: '4',
    borderRadius: 'md',
    boxSize: 'full',
    alignItems: 'center',
    justifyContent: 'center',
    bgColor: 'alpha.700',
    color: 'white',
    flexDir: 'column',
    p: '10',
    bgImage: `url(${vikingBetBg})`,
    bgSize: 'contain',
    border: 'none',
    w: 'auto',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    color: 'beta.700',
    fontSize: '40px',
    fontFamily: 'SkranjiBold',
    w: ['auto', null, '686px'],
    textAlign: 'center',
    lineHeight: '100%',
    background: 'linear-gradient(180deg, #FCD236 0%, #E79004 100%)',
    backgroundClip: 'text',
    letterSpacing: '1.68px',
    textTransform: 'uppercase',
    mb: '2',
  },
  addToBetslipButton: {
    variant: 'primary',
  },
  loginToView: {
    variant: 'primary',
  },
  evenShotImage: {
    h: '240px',
    w: '220px',
    mt: '6',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  oddsValueSpan: {
    color: 'beta.500',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'beta.400',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    mb: '1',
  },
  notAvailableText: {
    color: 'beta.400',
    fontSize: 'sm',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};
