import { CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'gray.600',
  p: '0',
};

export const DepositButton: CSSObject = {
  fontSize: 'xs',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    color: 'gamma.600',
  },

  _active: {
    bg: 'gamma.700',
    color: 'white',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  },
};

export const CancelButton: CSSObject = {
  border: '2px solid',
  borderColor: 'border.700',
  fontSize: 'sm',
  bg: 'white',
  fontFamily: 'Caveman',
  color: 'beta.700',
  fontWeight: 'normal',

  '&:hover': {
    bg: 'beta.700',
    color: 'blackAlpha.600',
    borderColor: 'transparent',
  },

  _active: {
    bg: 'beta.700',
  },
};

export const ButtonSubmit: CSSObject = {
  fontSize: 'sm',
  fontFamily: 'Caveman',
  fontWeight: 'normal',
  border: '2px solid',
  borderColor: 'beta.400',
  bg: 'beta.600',
  textTransform: 'uppercase',
  color: 'blackAlpha.600',
  textShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.35)',
  boxShadow:
    '0px 4px 5px 0px rgba(0, 0, 0, 0.25), 0px 4px 5px 0px rgba(0, 0, 0, 0.15) inset',

  _hover: {
    bg: 'beta.700',
    color: 'blackAlpha.600',
    borderColor: 'transparent',
  },
  _active: {
    bg: 'beta.700',
  },
};

export const DepositLimitContainer: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  p: '4',
  color: 'white',
  fontWeight: '700',
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '& .chakra-form__label': {
    color: 'white',
  },
  '& .chakra-input__left-addon': {
    bg: 'beta.700',
    fontSize: 'xs',
    h: '9',
    borderLeftRadius: 'base',
  },
  '& .chakra-select': {
    bg: 'gray.50',
    borderY: '1px',
    borderColor: 'gray.400',
    borderRadius: 'base',
    fontSize: 'xs',
    h: '9',
  },
  '& .chakra-input': {
    borderRightRadius: 'base',
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'gray.50',
    h: '9',
    border: '1px',
    borderColor: 'gray.400',
    _hover: {
      bg: 'gray.100',
    },
    _placeholder: {
      color: 'beta.700',
    },
  },
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};
