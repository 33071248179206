import React from 'react';
import { TNextToJumpResponse } from '../../../../../api/nextToJump/nextToJump.types';
import { getIconBySportName } from '@/helpers/utils';
import Countdown from '../../../../../components/Countdown/Countdown';
import { formatVenueNameNextToRace } from '../../../../../lib/RaceNames';
import {
  Icon,
  LinkRaceContainer,
  TextRace,
  TextRaceNumber,
} from './styles/RaceTiles.styles';

type TRaceLink = {
  race: TNextToJumpResponse[0];
};

export default function RaceLink({ race }: TRaceLink) {
  return (
    <LinkRaceContainer
      data-cy="nextToJump"
      to={`/racing/${race.race_type}/${race.venue_name}/R${race.race_number}?venueId=${race.venue_id}&raceId=${race.race_id}&meetingDate=${race.meeting_date}`}
    >
      <Icon name={getIconBySportName(race.race_type) ?? ''} />
      <TextRace noOfLines={1}>{formatVenueNameNextToRace(race)}</TextRace>

      {race?.start_time && (
        <Countdown
          eventTime={race?.start_time}
          sx={{ ml: 'auto', minH: '5', minW: '8' }}
          dateFormat="HH:mm"
        />
      )}
      <TextRaceNumber>{`R${race?.race_number ?? ''}`}</TextRaceNumber>
    </LinkRaceContainer>
  );
}
