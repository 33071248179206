import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DownArrow, UpArrow } from 'styled-icons/boxicons-solid';
import { Button } from '@/components/Button/Button';
import SgmLogo from '@/components/SgmLogo/SgmLogo';
import { TMoMSchema, getBetSlipStoreActions } from '@/store/BetSlipStore';
import {
  FlexInnerHeaderContainer,
  HeaderContainer,
  IconOddsChangeArrow,
  flexEventProps,
} from '../../BetCard/components/Header/Header.styles';
import {
  BetCardContainer,
  BetCardSVG,
  EventTitle,
  EventSubtitle,
  InfoBetOdds,
} from '../../Card/styles/BetCard.styles';
import { centsToDollars, getStrings } from '@/helpers/utils';
import { FormsHStack } from '../../BetCard/components/Forms/Forms.styles';
import { BetCardInput } from './BetCardInput/BetCardInput';
import { validateStake } from '@/components/Betslip/lib';
import { BetCardFooter } from './BetCardFooter/BetCardFooter';
import {
  useBetRequests,
  usePunterBalance,
} from '@/components/Betslip/Services/Betslip.hooks';
import {
  EBetSlipViewMode,
  EBetSubmissionConfirmationStatus,
  EOddsChangeDirection,
} from '@/components/Betslip/Services/Betslip.types';
import { ReviewBetDetails } from './ReviewBetDetails/ReviewBetDetails';
import { MoMCardItems } from './MoMCardItems/MoMCardItems';
import { TBlendedMoMBet, TMultiMoMBet, TSGMoMMultiBet } from './types';
import { MoMBlendedCardItem } from './MoMCardItems/MoMBlendedCardItem';
import { MoMSGMCardItem } from './MoMCardItems/MoMSGMCardItem';
import { PendingBet } from '../../BetCard/components/Footer/PendingBet';
import { MoMCardPlacedBetFooter } from './MoMCardPlacedBetFooter/MoMCardPlacedBetFooter';
import { shouldShowErrorBannerForBet } from './utils';
import { BetCardError } from './BetCardError/BetCardError';
import { BetCardPropClosed } from './BetCardPropClosed/BetCardPropClosed';
import { BetCardRejection } from './BetCardReject/BetCardRejected';
import { EBetRequestStatus } from '@/api/punter/punter.types';
import { BetCardApproval } from './BetCardApproval/BetCardApproval';
import { OddsChangedBanner } from '../../OddsChangedBanner/OddsChangedBanner';
import { useBonusBetIcon } from '@/hooks/useIcon';
import { betCardStyles } from '../../BetCard/styles/BetCard.styles';

interface IMoMCardProps {
  moMBet: TMoMSchema;
}

export function MomCard({ moMBet }: IMoMCardProps) {
  const { bonusBetIcon } = useBonusBetIcon(moMBet.isBonusBet);
  const stakeAsCents = Number(moMBet.stake) * 100; // convert to cents
  const { bonusBalance } = usePunterBalance();
  const [{ BetSlip: Strings }] = getStrings();

  const intl = useIntl();

  useBetRequests();

  const { updateMoMBet } = getBetSlipStoreActions();
  const shouldShowBonusButton =
    stakeAsCents <= bonusBalance && stakeAsCents !== 0;

  const estReturn = centsToDollars(moMBet?.odds * stakeAsCents);

  const errorMsg = shouldShowErrorBannerForBet(
    moMBet.betSubmissionStatus,
    moMBet.betRejectionReason,
    moMBet.maxStakeLimit
  );

  const handleStakeChange = (value: string) => {
    const newStake = Number(value) * 100;
    let { isBonusBet } = moMBet;

    if (newStake > bonusBalance || newStake === 0) {
      isBonusBet = false;
    }

    updateMoMBet({
      stake: value,
      isBonusBet,
    });
  };

  return (
    <BetCardContainer spacing="2">
      {errorMsg && <BetCardError errorMsg={errorMsg} />}
      {moMBet.type !== 'Multi' && moMBet?.isClosed && (
        <BetCardPropClosed
          onClick={() => {
            updateMoMBet(null);
          }}
        />
      )}
      <HeaderContainer>
        <FlexInnerHeaderContainer>
          <Flex {...flexEventProps}>
            <BetCardSVG header name="multi_betslip" />
            <Box pl={2}>
              <Flex>
                <EventTitle mb={2} w="fit-content">
                  {moMBet?.title}
                </EventTitle>{' '}
                {moMBet.type === 'SGMulti' && (
                  <SgmLogo {...betCardStyles.betCardSVG} />
                )}
              </Flex>
              {moMBet.description && (
                <EventSubtitle>{moMBet?.description}</EventSubtitle>
              )}
              <EventSubtitle>{moMBet?.subTitle}</EventSubtitle>
            </Box>
          </Flex>
          <Flex alignItems="center">
            {moMBet.betOddsChange !== null && (
              <>
                {moMBet.betOddsChange === EOddsChangeDirection.Decrease && (
                  <IconOddsChangeArrow direction="decrease" as={DownArrow} />
                )}
                {moMBet.betOddsChange === EOddsChangeDirection.Increase && (
                  <IconOddsChangeArrow direction="increase" as={UpArrow} />
                )}
              </>
            )}
            <InfoBetOdds>{moMBet?.odds.toFixed(2)}</InfoBetOdds>
          </Flex>
        </FlexInnerHeaderContainer>
      </HeaderContainer>
      {moMBet.betSlipStatus === EBetSlipViewMode.EditingBets && (
        <>
          <FormsHStack gap={2} mt={2} direction="column">
            <BetCardInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                const { value } = e.target;
                const newStake = validateStake(value);
                if (newStake || value === '') {
                  handleStakeChange(newStake);
                }
              }}
              value={moMBet?.stake ?? '0.00'}
              name="test"
              placeHolder="0.00"
            />
          </FormsHStack>

          <Box px={2} pt={4}>
            <BetCardFooter
              isBonusBetApplied={!!moMBet?.isBonusBet}
              shouldShowBonusButton={shouldShowBonusButton}
              onClick={() => {
                updateMoMBet({
                  isBonusBet: !moMBet?.isBonusBet,
                });
              }}
              esimatedReturns={estReturn}
            />
          </Box>
        </>
      )}
      {moMBet.betSubmissionStatus ===
        EBetSubmissionConfirmationStatus.Pending && (
        <FormsHStack gap={2} mt={2} direction="column">
          <PendingBet />
        </FormsHStack>
      )}
      {moMBet.betSlipStatus === EBetSlipViewMode.ReviewingBets && (
        <FormsHStack gap={2} mt={2} direction="column">
          <ReviewBetDetails
            stake={centsToDollars(stakeAsCents)}
            estimatedReturn={centsToDollars(stakeAsCents * moMBet.odds)}
          />
        </FormsHStack>
      )}
      {moMBet?.oddsIncreased && (
        <Box
          sx={{
            padding: '4px 12px',
            '&>*': {
              width: '100%',
              ml: '0 !important',
            },
          }}
        >
          <OddsChangedBanner direction="increase" isInFooter={false} />
        </Box>
      )}
      {moMBet.betSubmissionStatus ===
        EBetSubmissionConfirmationStatus.Rejected && (
        <Box pt="2">
          <BetCardRejection text={Strings.betSlipBetCard.unfortunateReject} />
        </Box>
      )}
      {moMBet.betRequestStatus === EBetRequestStatus.Approved && (
        <Box pt="2">
          <BetCardApproval
            text={`${Strings.betSlipBetCard.betApprovalInfo}
        ${intl.formatMessage({ id: 'betSlip.betSlipBetCard.approved' })}`}
          />
        </Box>
      )}
      {moMBet.betRequestStatus === EBetRequestStatus.ReducedStake && (
        <Flex flexDir="column" pt="2" gap="2">
          <BetCardRejection
            text={intl.formatMessage(
              { id: 'betSlip.betSlipBetCard.rejected' },
              {
                amount: moMBet.reducedStakeInfo?.rejectedStake,
              }
            )}
          />
          <BetCardApproval
            text={`${Strings.betSlipBetCard.partialBetApprovalInfo.replace(
              '%',
              moMBet?.reducedStakeInfo?.approvedStake
            )}  ${intl.formatMessage({
              id: 'betSlip.betSlipBetCard.approved',
            })}`}
          />
        </Flex>
      )}
      {moMBet.type === 'Multi' && (
        <MoMCardItems moMBet={moMBet as TMultiMoMBet} />
      )}
      {moMBet.type === 'Blended' && (
        <MoMBlendedCardItem bet={moMBet as unknown as TBlendedMoMBet} />
      )}
      {moMBet.type === 'SGMulti' && (
        <MoMSGMCardItem bet={moMBet as unknown as TSGMoMMultiBet} />
      )}
      {moMBet?.isBonusBet &&
        moMBet.betSlipStatus !== EBetSlipViewMode.EditingBets && (
          <Box my={2} mx={2}>
            <Button
              width="100%"
              data-cy="useBonusBetsBtn"
              isBonusBet
              data-isbonusbet
              isDisabled={
                moMBet.betSlipStatus === EBetSlipViewMode.ReviewingBets ||
                moMBet.betRequestStatus === 'Pending'
              }
              leftIcon={bonusBetIcon}
              {...betCardStyles.buttonBonusBetsProps}
            >
              <FormattedMessage id="account.mybetscard.bonusapplied" />
            </Button>
          </Box>
        )}
      {(moMBet.betSubmissionStatus ===
        EBetSubmissionConfirmationStatus.ReducedStake ||
        moMBet.betSubmissionStatus ===
          EBetSubmissionConfirmationStatus.Placed ||
        moMBet.betRequestStatus === EBetRequestStatus.Approved) && (
        <MoMCardPlacedBetFooter
          requestId={moMBet.requestId}
          betPlacedAt={moMBet.betPlacedAt}
        />
      )}
    </BetCardContainer>
  );
}
