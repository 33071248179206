import evenShotBg from '@/assets/puntgenie/images/backgrounds/evenShotBg.png';
import evenShot from '@/assets/puntgenie/images/evenShotMascot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  notAvailableText: {
    color: 'delta.500',
    mt: 0,
  },
  oddsValueSpan: {
    color: 'white',
    sx: {
      '&[data-hasbets="true"]': {
        color: 'delta.500',
      },
    },
  },
  hasBetSubTitleText: {
    fontWeight: 'normal',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notYetAvailableText: {
    color: 'delta.500',
    fontWeight: 'bold',
    mt: 0,
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'delta.500',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'extrabold',
    fontSize: 'lg',
  },
  oddsText: {
    mt: '0',
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    border: '1px',
    borderColor: 'beta.600',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flexDir: 'column',
    bg: `linear-gradient(180deg, #192A82 0%, #0F194E 100%), url(${evenShotBg})`,
    bgBlendMode: 'overlay, normal',
    bgSize: 'cover',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    fontFamily: 'CinzelDecorative',
    fontWeight: 'black',
    bgGradient: 'linear(180deg, #F5C44D 0%, #E7A402 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textTransform: 'lowercase',
    fontSize: '35px',
  },
  addToBetslipButton: {
    variant: 'tertiary',
  },
  loginToView: {
    variant: 'tertiary',
  },
  evenShotImage: {
    mt: 4,
    w: '160px',
    h: '155px',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
  },
};
