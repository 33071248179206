import { ButtonProps } from '@chakra-ui/react';

export const closeBtn: ButtonProps = {
  variant: 'unstyled',
  pos: 'absolute',
  top: [1, null, '30px'],
  right: [1, null, '34px'],
  boxSize: '10',
  color: ['white', null, 'gray.700'],

  sx: {
    svg: {
      boxSize: '33px',
    },
  },
};
