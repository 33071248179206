import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  py: '2',
  bg: 'beta.800',
  borderTopRadius: 'md',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  px: '0',
  pb: '0',
  overflowX: 'visible',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'blackAlpha.500' : 'alpha.400',
  color: isActive ? 'white' : 'blackAlpha.700',
  textShadow: isActive ? 'none' : '0px 1px 0px rgba(255, 255, 255, 0.35)',
  textTransform: 'uppercase',
  fontWeight: '900',
  fontSize: '10px',
  lineHeight: '1.4',
  px: '8px',
  py: '1',
  borderRadius: '3px',
  boxShadow: isActive
    ? '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)'
    : '0px 1px 2px 0px #f9f17a inset,  0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #f9f17a inset', // no colour token
  '&:hover, &:focus': {
    bg: isActive ? 'delta.300' : 'alpha.500',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.500',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: '900',
  fontSize: '10px',
  lineHeight: '1.4',
  px: '8px',
  py: '1',
  borderRadius: '3px',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  '&:hover, &:focus': {
    bg: 'delta.300',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
  pt: '2',
  pb: '2',
  bg: 'beta.800',
  borderTopRadius: 'md',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};
