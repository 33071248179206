import React from 'react';
// import { Link } from 'react-router-dom';
// import { Flex, Icon, Text } from '@chakra-ui/react';
// import { ChevronLeft } from '@styled-icons/boxicons-regular';
// import { FormattedMessage } from 'react-intl';

export default function index() {
  return (
    <>
      {/* <Link to="/login">
        <Flex flexDir="row" alignItems="center">
          <Icon as={ChevronLeft} boxSize="3" color="gamma.500" />
          <Text fontWeight="semibold" fontSize="xs" color="gamma.500">
            <FormattedMessage id="onboarding.forgotpassword.loginCta" />
          </Text>
        </Flex>
      </Link>
      <Text fontWeight="semibold" fontSize="md" my="4" color="gray.500">
        <FormattedMessage id="onboarding.forgotpassword.title" />
      </Text> */}
    </>
  );
}
