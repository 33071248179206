import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  borderTop: '1px dashed',
  pt: '3.5',
  borderColor: 'blackAlpha.300',
  px: '2',
  pb: '2',
  '&&': {
    mt: '2.5',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  _groupFocusWithin: {
    bg: 'beta.800',
  },
  bg: 'beta.600',
  color: 'white',
  fontSize: 'sm',
  ...(isPrefix
    ? {
        borderLeftRadius: 'md',
        borderRightRadius: 'none',
      }
    : {
        borderLeftRadius: 'none',
        borderRightRadius: 'base',
      }),
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.200',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'epsilon.800',
  fontWeight: 'extrabold',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'epsilon.800',
  fontWeight: 'extrabold',
};

export const EachWayText: CSSObject = {
  color: 'epsilon.800',
};

export const InputBetSlip: CSSObject = {
  bg: 'beta.800',
  marginTop: '-0.5',
  borderColor: 'beta.600',
  color: 'white',
  pl: '10',
  borderRadius: 'md',
  ':first-child': {
    pl: '3',
  },
  _hover: {
    borderColor: 'beta.600',
    '&&': {
      background: 'beta.800',
    },
  },
  _focus: {
    borderColor: 'beta.800',
    '&&': {
      background: 'beta.600',
    },
    '&::placeholder': { color: 'white', opacity: '0.8' },
  },
  '&::placeholder': { color: 'white', opacity: '0.5' },
};

export const FlexiReview: CSSObject = {
  px: '3',
  "[class$='ReviewStakeText']": {
    color: 'epsilon.800',
  },
  "[class$='ReviewStake']": {
    color: 'beta.600',
  },
};

export const FlexReviewExotic: CSSObject = {
  mr: '2',
  "[class$='ReviewStake']": {
    color: 'beta.600',
  },
  "[class$='ReviewStakeText']": {
    color: 'epsilon.800',
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'alpha.400',
};
