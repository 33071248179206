import React from 'react';
import IconSvg from '../../../../common/IconSvg';
import { TSideIcon } from '../../../services/NavIcon.types';

export const HomeIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Home" className="svgIcon" sx={styles.icon} />
);

export const DepositIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Deposit" className="svgIcon" sx={styles.icon} />
);

export const PromoIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Promotion" className="svgIcon" sx={styles.icon} />
);

export const ContactIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Contact" className="svgIcon" sx={styles.icon} />
);

export const MyAccountIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Account" className="svgIcon" sx={styles.icon} />
);

export const SportsIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Axe" className="svgIcon" sx={styles.icon} />
);

export const ResponsibleGamblingIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Promotion" className="svgIcon" sx={styles.icon} />
);
