import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/slambet/images/account/bannerBg.png';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const CardMainContent: CSSObject = {
  p: 1,
  pb: 2,
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
};
export const CardContainer: CSSObject = {
  border: 'none',
  boxShadow:
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  bgImage: `url(${bg})`,
  bgSize: 'cover',
  p: 1.5,
  pos: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 55`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px solid',
  borderColor: 'whiteAlpha.300',
  '.multiDivider': {
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    width: 'unset',
  },
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  alignItems: 'start',
  '> svg': {
    color: 'alpha.400',
    fill: 'alpha.400',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
};
