import React from 'react';
import { TVenue } from '@/api/racing/racing.types';
import Countdown from '../../../../../components/Countdown/Countdown';
import { TRace } from '@/lib/DBModels';
import { getRaceURL, raceItemContent } from '../services/RaceTable.utils';
import {
  BadgeRaceItem,
  BadgeRaceNumber,
  LinkRaceItem,
  raceTableStyles,
  TextRaceResults,
} from '../styles/RaceTable/RaceTable.styles';

type TRaceItem = {
  venue: TVenue;
  race: TRace;
  initWidth: number;
  hasPromo?: boolean;
  selectedToteMulti?: string;
};

export default function RaceItem({
  venue,
  race,
  initWidth,
  hasPromo = false,
  selectedToteMulti,
}: TRaceItem) {
  const itemContent = Object.keys(race).length
    ? raceItemContent(race)
    : undefined;
  const hasContent = !!itemContent;
  let toteMultiSelection = null;
  if (venue?.race_venue_totes && selectedToteMulti) {
    const toteRace =
      venue?.race_venue_totes?.totes_by_race_num[race.race_number] &&
      venue?.race_venue_totes?.totes_by_race_num[race.race_number][
        selectedToteMulti
      ];
    toteMultiSelection = toteRace ?? null;
  }

  let isToteMultiAvailable = false;

  if (toteMultiSelection) {
    isToteMultiAvailable = toteMultiSelection[0].legs?.includes(
      race.race_number
    );
  }
  /**
   * Props explanation
   * hasContent === false for mobile hides the link & does nothing on desktop.
   * isActive toggles the bg color on desktop.
   */
  return (
    <LinkRaceItem
      to={getRaceURL(
        race,
        venue,
        isToteMultiAvailable ? selectedToteMulti : ''
      )}
      isActive={
        itemContent?.component === 'countdown' || itemContent === undefined
      }
      isToteMultiAvailable={isToteMultiAvailable}
      hasContent={hasContent}
      hasPromo={hasPromo}
      sx={{
        ...(itemContent === undefined && { pointerEvents: 'none' }),

        ':after': {
          display: hasPromo ? 'block' : 'none',
          ...raceTableStyles.afterLinkRaceItemProps,
        },
      }}
      initWidth={initWidth}
      data-cy={race.status && `tableRace-${race.status}`}
    >
      {(() => {
        if (itemContent?.component === 'badge') {
          return (
            <BadgeRaceItem state={itemContent.prop}>
              {itemContent.content}
            </BadgeRaceItem>
          );
        }

        if (itemContent?.component === 'countdown') {
          return (
            <Countdown
              eventTime={itemContent?.content ?? ''}
              dateFormat="HH:mm"
            />
          );
        }

        if (itemContent?.component === 'text') {
          return (
            <TextRaceResults data-cy="resultedRace">
              {itemContent?.content.replaceAll(' ', '')}
            </TextRaceResults>
          );
        }

        return undefined;
      })()}

      {hasContent && (
        <BadgeRaceNumber>{`R${race.race_number}`}</BadgeRaceNumber>
      )}
    </LinkRaceItem>
  );
}
