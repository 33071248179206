import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  fontSize: 'sm',
  display: 'flex',
  alignItems: 'center',
  w: '12',
  sx: {
    '&&': {
      '&:active, &[data-active="true"]': {
        bg: 'gamma.700',
        color: 'white',
        boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          color: 'white',
        },
      },
      p: '2',
    },
    '&:hover': {
      color: 'gamma.600',
      bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    },
    '&:disabled': {
      border: 'none',
      borderRadius: 'base',
      '&&': {
        opacity: '1',
        boxShadow: 'none',
        color: 'gray.600',
        bg: 'blackAlpha.200',
        fontSize: 'xs',
      },
      pointerEvents: 'none',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: 'calc(100%)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  borderTop: '1px solid rgba(0, 0, 0, 0.10)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
  borderLeft: '1px solid rgba(0, 0, 0, 0.10)',
  background: 'gamma.700',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'alpha.500',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '8px',
  color: 'alpha.500',
  textShadow: 'none',
};
