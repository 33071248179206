import { CSSObject } from '@chakra-ui/react';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    borderRadius: 'md',
    border: '2px solid',
    borderColor: 'beta.700',
    bg: 'beta.700',
    color: 'white',
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'beta.300',
};

export const ButtonKeys = (isBatch: boolean): CSSObject => ({
  color: 'white',
  bgGradient: 'linear(180deg, beta.600, beta.700)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset',
  borderRadius: 'md',
  border: 'none',
  bg: 'none',

  ...(isBatch && {
    bgGradient: 'linear(180deg, alpha.400, alpha.500)',
    boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.25)',
  }),
  '&.keypad-btn-done': {
    _disabled: {
      bgGradient: 'linear(180deg, alpha.400, alpha.500)',
      color: 'white',
    },
    color: 'white',
    textTransform: 'uppercase',
    bgGradient: 'linear(180deg, alpha.400, alpha.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    fontSize: 'md',
  },
});
