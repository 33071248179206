import { CSSObject, keyframes } from '@chakra-ui/react';

export const FlexBetsPlacedContainer: CSSObject = {
  h: '28',
  fontSize: 'md',
  justifyContent: 'center',
  flexDir: 'column',
  gap: '1',
  p: '0',
};

export const IconBetsPlaced: CSSObject = {
  '&&': {
    display: 'none',
  },
};

const loopPulse = keyframes`
  0% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 0.8));
  }
  50% {
    transform: scale(1.2);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 1));
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 0.8));
  }
`;

export const IconThumbsUp: CSSObject = {
  display: 'flex',
  boxSize: '7',
  animation: `${loopPulse} 1s ease-in-out infinite`,
  color: 'alpha.100',
};

export const TextBetsPlaced: CSSObject = {
  fontSize: 'sm',
  display: 'none',
};
