import { css } from 'styled-components';

export const nameContainer = css`
  display: flex;
  justify-content: space-between;
`;

// TODO: Remove
export const tournamentName = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-xs);
  color: white;
`;

// TODO: Remove
export const matchIcon = css`
  height: 25px;
  width: 25px;

  path {
    fill: ${({ theme }) => theme.basePalette.brightBlue};
  }
`;

// TODO: Remove
export const markets = css`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-xs);
`;

export const matchDate = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-xs);
`;

// TODO: Remove
export const teamItem = css`
  background: ${({ theme }) => theme.basePalette.navyBlue};
  border: 1px solid ${({ theme }) => theme.basePalette.midPurple};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 2px 1px ${({ theme }) => theme.basePalette.purple};
    transform: translateY(-3%);
  }

  &:active {
    box-shadow: none;
    transform: none;
  }
`;

// TODO: Remove
export const teamName = css`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-2xs);
`;

// TODO: Remove
export const teamOdds = css`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-md);
`;
