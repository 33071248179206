import { CSSObject } from '@chakra-ui/react';
import background from '../../../../../../assets/volcanobet/images/backgrounds/overview-bg.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    sx: {
      '&&': {
        h: 8,
        fontSize: 'xs',
        px: 3,
      },
    },
  },
};

export const FlexBannerContainer: CSSObject = {
  bg: '#722822', // Bespoke colour from background image
  bgImage: `url(${background})`,
  bgPos: [null, null, 'center'],
  bgRepeat: 'repeat',
  bgSize: ['cover', null, '30%'],
  borderRadius: 'base',
  color: 'white',
  gap: ['2', null, '0'],
  px: ['2', null, '5'],
  py: ['2', null, '4'],
  filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.1))',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1',
};

export const FlexAccountName: CSSObject = {
  fontSize: ['lg', null, 'xl'],
  fontFamily: 'Roboto',
  fontWeight: 'semibold',
  color: 'beta.500',
  letterSpacing: '0.04em',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '-2'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  mb: '0.5',
  textTransform: 'uppercase',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'beta.500',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: 'sm',
  verticalAlign: 'super',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: '2xl',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: 'lg',
  opacity: '0.7',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
};

export const TextButton: CSSObject = {
  h: '4',
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  borderRadius: 'base',
  border: '2px',
  borderColor: 'white',
  boxShadow: '0 2px 6px rgba(0, 0, 0, .2)',
  lineHeight: '4',
  flex: ['1', null, '0'],
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: ['9', null, '8'],
  justifyContent: 'center',
  px: '4',
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: [null, null, 'nowrap'],
};
