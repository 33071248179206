import { BoxProps, FlexProps, IconProps } from '@chakra-ui/react';

import { styleImports } from './MarketsAccordionItem.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type MarketAccordionItemSchema = Partial<{
  accordionBox: BoxProps;
  disclaimerFlex: FlexProps;
  disclaimerIcon: Partial<IconProps>;
}>;

export const marketAccordionItemStyles: MarketAccordionItemSchema = {
  accordionBox: {
    label: 'marketAccordionItem-accordionBox',
    ...(() => {
      try {
        return styleImports[themeName]?.marketAccordionItemStyles?.accordionBox;
      } catch (err) {
        return {};
      }
    })(),
  },
  disclaimerFlex: {
    label: 'marketAccordionItem-disclaimerFlex',
    ...(() => {
      try {
        return styleImports[themeName]?.marketAccordionItemStyles
          ?.disclaimerFlex;
      } catch (err) {
        return {};
      }
    })(),
  },
  disclaimerIcon: {
    label: 'marketAccordionItem-disclaimerIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.marketAccordionItemStyles
          ?.disclaimerIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
};
