import React, { useEffect, useState } from 'react';
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown';
import { useSearchParams } from 'react-router-dom';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { onRaceBetStyles } from './styles/OnRaceBets.styles';
import { Button } from '@/components/Button/Button';
import { EBetSlipBetSubmissionType } from '@/components/Betslip/Services/Betslip.types';
import { Group } from './components/Group';
import { TBetForRace } from '@/api/racing/bets/bets.types';

type TOnRaceBets = {
  betsForRace?: TBetForRace[];
  betsQueryLoading?: boolean;
  isAuth?: boolean;
  isAuthLoading?: boolean;
};

export const OnRaceBets = ({
  betsForRace,
  betsQueryLoading,
  isAuth,
  isAuthLoading,
}: TOnRaceBets) => {
  const [searchParams] = useSearchParams();
  const raceId = searchParams.get('raceId');
  const [expanded, setExpanded] = useState(true);
  const [viewMore, setViewMore] = useState(false);

  // Define the desired order of bet types
  const betTypeOrder = [
    'Singles',
    'Exotics',
    'ToteMulti',
    'SRMultis',
    'Blended',
  ];

  // Sort the keys based on the desired order
  const sortedBets = betsForRace?.sort(
    (a, b) =>
      betTypeOrder.indexOf(a.bet_type) - betTypeOrder.indexOf(b.bet_type)
  );
  const betsToDisplay = viewMore ? sortedBets : sortedBets?.slice(0, 2);

  useEffect(() => {
    // When main toggle is closed also close the Show More toggle
    if (!expanded) {
      setViewMore(false);
    }
  }, [expanded]);

  const hasBets = betsForRace && betsForRace.length;

  if (!isAuth || isAuthLoading || !raceId || !hasBets || betsQueryLoading) {
    return <></>;
  }

  const singleBets = betsToDisplay?.filter(
    (b) => b.bet_type.toLowerCase() === 'single'
  );
  const exoticBets = betsToDisplay?.filter((b) => b.exotic_selections.length);
  const blendedBets = betsToDisplay?.filter(
    (b) => b.bet_type === EBetSlipBetSubmissionType.Blended
  );
  const sameRaceMultiBets = betsToDisplay?.filter(
    (b) => b.bet_type === EBetSlipBetSubmissionType.SRMulti
  );

  const toteMultiBets = betsToDisplay?.filter(
    (b) => b.bet_type === EBetSlipBetSubmissionType.ToteMulti
  );

  const handleToggleViewMore = () => setViewMore(!viewMore);

  return (
    <Flex {...onRaceBetStyles.wrapper}>
      <Flex {...onRaceBetStyles.headerWrapper}>
        <Text {...onRaceBetStyles.headerText}>My Bets On This Race</Text>
        <Text {...onRaceBetStyles.headerCountText}>({betsForRace.length})</Text>

        <Button
          aria-label="expand my bets on this race"
          onClick={() => setExpanded(!expanded)}
          {...onRaceBetStyles.headerExpandButton}
        >
          <Icon
            as={ChevronDown}
            {...onRaceBetStyles.toggleIcon}
            sx={{ ...(!expanded && { transform: 'rotate(180deg)' }) }}
          />
        </Button>
      </Flex>
      {expanded && (
        <Flex {...onRaceBetStyles.betsAndViewMoreButtonWrapper}>
          <Flex {...onRaceBetStyles.betsContainer}>
            {!!singleBets?.length && (
              <Group
                bets={singleBets}
                betType={EBetSlipBetSubmissionType.Single}
              />
            )}
            {!!exoticBets?.length && (
              <Group
                bets={exoticBets}
                betType={EBetSlipBetSubmissionType.Exotics}
              />
            )}
            {!!sameRaceMultiBets?.length && (
              <Group
                bets={sameRaceMultiBets}
                betType={EBetSlipBetSubmissionType.SRMulti}
              />
            )}
            {!!blendedBets?.length && (
              <Group
                bets={blendedBets}
                betType={EBetSlipBetSubmissionType.Blended}
              />
            )}
            {!!toteMultiBets?.length && (
              <Group
                bets={toteMultiBets}
                betType={EBetSlipBetSubmissionType.ToteMulti}
              />
            )}
          </Flex>
          {betsForRace?.length > 2 && (
            <Button
              {...onRaceBetStyles.viewMoreButton}
              onClick={handleToggleViewMore}
            >
              <Text {...onRaceBetStyles.viewMoreButtonText}>
                {viewMore ? 'Show Less' : 'Show More'}
              </Text>
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
};
