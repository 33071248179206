import { CSSObject } from '@chakra-ui/react';
import activeBg from '../../../../../../assets/vikingbet/images/backgrounds/btn-active-bg.png';

import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({ propLength }: TButtonProp): CSSObject => ({
  '&&': {
    height: 'auto',
    bg: 'zeta.300',
    boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.10)',
    border: 'none',
    ...((propLength ?? 0) === 2 && {
      py: 3,
      px: 0,
      _first: { pl: 1 },
      _last: { pr: 1 },
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px dotted',
      borderTopColor: 'blackAlpha.500',
      p: '2',
      _first: { borderTop: 0 },
      _last: { boxShadow: 'none' },

      _hover: {
        span: {
          _first: {
            color: 'alpha.600',
          },
        },
        bg: 'blackAlpha.300',
      },
    }),
    _last: {
      borderBottomRadius: 'base',
    },
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'md',
    color: 'alpha.600',

    '&.return-amount': {
      bgImage: isSelected && `url(${activeBg})`,
      bg: !isSelected && 'linear-gradient(180deg, #53D3FA 0%, #3EB0D3 100%)',
      fontFamily: 'SkranjiBold',
      boxShadow: isSelected
        ? '0px 2px 0px 1px rgba(0, 0, 0, 0.55) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 0px 0px rgba(0, 0, 0, 0.55) inset'
        : '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
      p: 2,
      w: 'full',
      borderRadius: 'md',
      color: isSelected ? 'delta.500' : '#005773',
      border: isSelected ? 'none' : 'var(--bc-colors-delta-600) 2px solid',
      textShadow: isSelected
        ? '0px 1px 3px rgba(0, 0, 0, 0.45)'
        : '0px 1px 2px rgba(255, 255, 255, 0.40)',
      transition: 'all, .3s ease-in-out',
      _hover: {
        bg: 'linear-gradient(180deg, #55D7FF 0%, #3AABCD 100%)',
        color: 'gamma.600',
        opacity: 0.5,
        boxShadow:
          '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
      },

      _disabled: {
        border: 'none',
        borderRadius: 'base',
        '&&': {
          opacity: '1',
          boxShadow: 'none',
          color: 'alpha.400',
          bg: 'blackAlpha.200',
          fontSize: 'xs',
        },
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },
  },
});
