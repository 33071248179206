import { CSSObject } from '@chakra-ui/react';
import mascotSP from '../../../../../../assets/sterlingparker/images/header/mascotSP.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonNavItem = (): CSSObject => ({
  bg: 'var(--gradient-lighten, linear-gradient(180deg, rgba(194, 223, 255, 0.10) 0%, rgba(194, 223, 255, 0.00) 100%))',
  height: '54px',
  color: 'white',
  transition: 'all, 0.2s linear',
  borderTop: '1px',
  borderColor: 'rgba(0, 0, 0, 0.2)',
  boxShadow: 'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
  p: '18px',

  ':hover': {
    opacity: '0.2',
  },
  '.btn-span': {
    gap: '2',
  },
  svg: {
    boxSize: '18px',
  },
});

export const buttonDepositProps: CustomButtonProps = {
  color: 'blackAlpha.800',
  textShadow: '0 1px 0 rgba(255, 255, 255, 0.45)',
  w: '100%',
  height: '42px',
  fontWeight: 'black',
  mt: '2',

  sx: {
    '.chakra-button__icon': {
      mr: '0',
      zIndex: '9',
    },
  },
};

export const BoxBallance: CSSObject = {
  bgGradient: 'linear(to-b, beta.500, beta.600)',
  color: 'white',
};

export const PopoverBodyQA: CSSObject = {
  bg: 'var(--gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'link',
  display: 'inline-flex',
  lineHeight: '1',
  fontSize: 'xs',
  color: 'beta.50',

  sx: {
    svg: {
      ml: '-1',
      boxSize: '16px',
    },
  },
};

export const TextQuickAccessHello: CSSObject = {
  color: 'alpha.500',
  fontFamily: 'CrimsonPro',
  fontSize: 'md',
  fontWeight: 'bold',
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'var(--gradient-grey-light, linear-gradient(225deg, #4B5A6D 0%, #3F4953 100%))',
  borderColor: 'transparent',
  color: 'white',
  borderTopRadius: '1',
  px: '10px',
  fontSize: 'xs',
};

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${mascotSP})`,
  bgSize: ['66%', null, null, null, '100%'],
  pos: 'absolute',
  bgRepeat: 'no-repeat',

  boxSize: '90px',
  top: '2.5',
  left: ['50px', null, null, null, '-3'],
  display: ['none', null, null, null, 'block'],
};
