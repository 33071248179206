import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.400',
  p: '2',
  w: 'fill-available',
  borderRadius: 'base',
  color: 'white',
  '.chakra-badge.state-inprogress': {
    bg: 'delta.900',
    color: 'gamma.900',
    borderRadius: 'base',
    maxW: '40px',
  },

  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.400',
    color: '#552C18',
    borderRadius: 'base',
    maxW: '40px',
  },

  '.chakra-badge.state-ended': {
    bg: 'delta.900',
    color: 'white',
    borderRadius: 'base',
    maxW: '42px',
  },
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});

export const CountdownTimerBox: CSSObject = {
  span: {
    color: 'white',
  },
};
