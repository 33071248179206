import React, { useEffect, useState } from 'react';
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import toast from 'react-hot-toast';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { keys } from '@/api/api.keys';
import SVGIcon from '../../../components/SVGIcon';
import {
  AmountInputWrapperWithdrawal,
  BankError,
  buttonAddAccountProps,
  ButtonWithdrawal,
  buttonWithdrawalProps,
  DividerWithdrawal,
  Heading,
  InputWithdrawal,
  ListContainer,
  ListContainerHeading,
  Subheading,
  TextDescription,
  FlexWithdrawalWrapper,
} from './styles/Withdrawal.styles';
import IncreaseAmountButton from './IncreaseAmountButton';
import AddAccount from './AddAccount';
import BankAccountCard from './BankAccountCard';
import RequestedWithdrawalCard from './RequestedWithdrawalCard';
import { centsToDollars } from '@/helpers/utils';
import {
  deleteWithdrawRequest,
  getBankAccounts,
  getPunterAccountOverview,
  postWithdrawRequest,
  viewWithdrawalRequests,
} from '../services/account.actions';
import withdrawFundsSchema from '../services/schemas/withdrawFundsSchema';
import { TWithdrawFormData } from '../account.types';
import { setWithdrawals } from '../services/account.slices';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { ContainerForms } from '../styles/account.styles';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { Button } from '@/components/Button/Button';

const Withdrawal: React.FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const client = useQueryClient();
  const [addAccountFlow, setAddAccountFlow] = useState(false);

  const dispatch = useAppDispatch();
  const { banks, accountOverview, withdrawals } = useAppSelector(
    (state) => state.punter
  );

  const pendingWithdrawals = withdrawals.filter(
    (w) => w.withdrawal_status === EWithdrawalStatus.Pending
  );

  const initialValues: TWithdrawFormData = {
    amount: undefined,
    bank_id: banks.length > 0 && banks[0]?.id ? banks[0].id : '',
  };

  useEffect(() => {
    (async () => {
      await dispatch(viewWithdrawalRequests());
    })().catch((err) => console.log(err));
  }, [dispatch]);

  const handleCancelWithdrawal = async (withdrawalId: string | undefined) => {
    if (!withdrawalId) {
      return;
    }

    await dispatch(deleteWithdrawRequest({ request_id: withdrawalId }));

    await client.invalidateQueries([keys.punterAccountOverview]);

    toast.success(
      intl.formatMessage({
        id: 'account.withdraw.withdrawalcancelled',
      })
    );

    dispatch(
      setWithdrawals(
        withdrawals.map((request) =>
          request.withdrawal_id === withdrawalId
            ? { ...request, withdrawal_status: EWithdrawalStatus.Cancelled }
            : request
        )
      )
    );
  };

  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      setSubmitting(true);
      if (formValues.amount && formValues.bank_id) {
        const newWithdrawal = await dispatch(
          postWithdrawRequest(formValues)
        ).unwrap();

        dispatch(setWithdrawals([newWithdrawal, ...withdrawals]));

        await client.invalidateQueries([keys.punterAccountOverview]);

        toast.success(
          intl.formatMessage({
            id: 'account.withdraw.withdrawalsuccess',
          })
        );
      }
      await setFieldValue('amount', '');
      setSubmitting(false);
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validate: async (formValues) => {
      try {
        await validateYupSchema(formValues, withdrawFundsSchema, true, {
          withdrawBalance: accountOverview?.available_balance,
          accountBalance: accountOverview?.account_balance,
        });
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
  });

  useEffect(() => {
    (async () => {
      await dispatch(getBankAccounts());
      await dispatch(getPunterAccountOverview());
    })().catch(() => false);
  }, [dispatch]);

  return (
    <AccountWrapper
      pageTitle={intl.formatMessage({
        id: 'account.withdraw.pagetitle',
      })}
      pageHeader={intl.formatMessage({
        id: 'account.withdraw.withdrawfundstitle',
      })}
      fullWidth
    >
      <FlexWithdrawalWrapper>
        <ContainerForms>
          {!banks.length || addAccountFlow ? (
            <AddAccount closeAddAccount={() => setAddAccountFlow(false)} />
          ) : (
            <>
              {errors.bank_id && (
                <BankError>
                  <SVGIcon
                    svgName="iconAlertRed"
                    styles={theme.pages.withdrawFunds.errorIcon}
                  />
                  {errors.bank_id}
                </BankError>
              )}

              <Heading>
                <FormattedMessage id="account.withdraw.addaccount.makeawithdrawal" />
              </Heading>

              <TextDescription>
                <FormattedMessage id="account.withdraw.addaccount.withdrawaldescription" />
              </TextDescription>

              <DividerWithdrawal />

              <Wrap mb="6" w="full" align="center">
                <WrapItem w="full">
                  <BankAccountCard
                    onClick={(bank) => setFieldValue('bank_id', bank.id)}
                    bankId={values.bank_id}
                  />
                </WrapItem>
              </Wrap>

              <Button
                onClick={() => setAddAccountFlow(true)}
                {...buttonAddAccountProps}
              >
                <FormattedMessage id="account.withdraw.addbank" />
              </Button>

              <AmountInputWrapperWithdrawal>
                <InputWithdrawal
                  label={intl.formatMessage({
                    id: 'account.withdraw.withdrawalamountlabel',
                  })}
                  placeholder="0.00"
                  name="amount"
                  data-cy="withdrawalAmountInput"
                  type="number"
                  InputLeftAddon="$"
                  onChange={handleChange}
                  value={values.amount}
                  error={errors.amount}
                  isFormik={false}
                />
              </AmountInputWrapperWithdrawal>

              <Subheading data-cy="availableBalance">
                <FormattedMessage id="account.withdraw.withdrawalbalancelabel" />
                {`${centsToDollars(accountOverview?.available_balance || 0)}`}
              </Subheading>

              <IncreaseAmountButton
                onChange={(value) => setFieldValue('amount', value)}
                currentInputValue={values.amount || 0}
              />

              <ButtonWithdrawal
                onClick={() => handleSubmit()}
                data-cy="requestButtonWithdrawal"
                isLoading={isSubmitting}
                size="md"
                isFullWidth
                {...buttonWithdrawalProps}
              >
                <FormattedMessage id="account.withdraw.requestwithdrawalbutton" />
              </ButtonWithdrawal>
            </>
          )}
        </ContainerForms>
        {!addAccountFlow && !!pendingWithdrawals.length && (
          <ListContainer>
            <ListContainerHeading fontWeight="semibold" ml="1">
              <FormattedMessage id="account.withdraw.requests.heading" />
            </ListContainerHeading>

            {pendingWithdrawals.map((withdrawalRequest, i) => (
              <RequestedWithdrawalCard
                key={withdrawalRequest.withdrawal_id ?? i}
                withdrawalRequest={withdrawalRequest}
                handleCancelWithdrawal={handleCancelWithdrawal}
              />
            ))}
          </ListContainer>
        )}
      </FlexWithdrawalWrapper>
    </AccountWrapper>
  );
};

export default Withdrawal;
