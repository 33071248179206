import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  NavLink,
  TopNavLabelWrapper,
  NavLinkLabel,
} from '@/components/Navigation/AccountNavigation/styles/TopNav.styles';
import SgmLogo from '@/components/SgmLogo/SgmLogo';
import PrimaryWrapper from '@/wrappers/PrimaryWrapper/PrimaryWrapper';
import Header from '../MarketsByMatch/components/Header/Header';
import ModalRedirect from '../MarketsByMatch/components/ModalRedirect/ModalRedirect';
import { useMatchDetails } from '../MarketsByMatch/services/MarketsByMatch.hooks';
import { BoxWrapper } from '../MarketsByMatch/styles/MarketsByMatch.styles';

export const SGM_PATH = 'sgm';

export default function MatchDetailPage() {
  const intl = useIntl();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { data } = useMatchDetails();
  const pageTitle =
    data?.match_name ||
    intl.formatMessage({ id: 'sports.marketsbymatch.pagetitlefallback' });
  const { filter } = useParams();
  const isSgmPage = filter === SGM_PATH;

  const basePathname = pathname.split('/');
  const matchPathname = basePathname
    .filter((segment) => segment !== SGM_PATH)
    .join('/');

  if (data && !data?.sgm_available && isSgmPage) {
    navigate(matchPathname + search);
    return null;
  }

  return (
    <PrimaryWrapper
      fullWidth
      fullWidthMobile
      pageHeader={<Header />}
      pageTitle={pageTitle}
    >
      <BoxWrapper>
        <Stack>
          <Box mx="-2">
            <TopNavLabelWrapper>
              <NavLinkLabel>
                <FormattedMessage id="generic.allMarkets" />
              </NavLinkLabel>

              {data?.sgm_available && (
                <NavLink to={`./sgm${search}`} end isActive={isSgmPage}>
                  <SgmLogo h="auto" mr="1" w="8" />
                  <FormattedMessage id="generic.sameGameMulti" />
                </NavLink>
              )}
            </TopNavLabelWrapper>
          </Box>
          <Outlet context={data} />
        </Stack>
      </BoxWrapper>
      <ModalRedirect />
    </PrimaryWrapper>
  );
}
