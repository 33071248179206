import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'white',
};

export const RunnerDescription: CSSObject = {
  color: 'whiteAlpha.500',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const BetOdds: CSSObject = {
  color: 'white',
};

export const TextBarrierNumber: CSSObject = {
  color: 'whiteAlpha.500',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'alpha.300';
      case 'lost':
        return 'black';
      case 'voided':
        return 'black';
      case 'cancelled':
        return 'black';
      case 'pending':
        return 'gamma.800';
      default:
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'alpha.800';
      case 'pending':
        return 'white';
      default:
        return 'white';
    }
  },
  textShadow: status === 'pending' && '0 1px 0 rgba(255, 255, 255, 0.35)',
});

export const HeaderSvg: CSSObject = {
  color: 'beta.800',
  boxSize: '5',
};

export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};

export const MultiIcon = () => (
  <IconSvg
    name="MultiBet"
    className="svgIcon"
    color="beta.800"
    boxSize="6"
    mb="1.5"
  />
);

export const headerRowStyles: HeaderRowSchema = {
  headerRowSvg: {
    fill: '#3E6F95',
  },
};
