import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
    alignItems: 'flex-end',
    pb: 2,
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: 'xl',
    fontWeight: 'normal',
    color: 'beta.800',
    fontFamily: 'accent',
    pt: '2',
    px: ['2', '0'],
    ml: '0',
  },
  headerIconButton: {
    color: 'delta.700',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'delta.600',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'beta.500',
    h: '1.1em',
    ml: '2',
    mb: '0.5',
    w: 'auto',
  },
  scrollButtonGroup: {
    variant: 'filter',
    boxShadow: 'none',
    bg: 'none',
    ml: '0',
    w: 'full',
    sx: {
      _notFirst: {
        'button[data-testid*="-toMarketButton"]': {
          border: '2px solid',
          borderColor: 'beta.500',
          borderRadius: 'md',
          ml: '0',
          mr: '1',
          py: '3',
          fontFamily: 'body',
          fontSize: '2xs',
          fontWeight: 'extrabold',
        },
      },
    },
  },
  wrapperScrollSgm: {
    gap: '1',
    alignItems: 'center',
    display: 'flex',
    w: 'full',
  },
  toMarketButton: {
    border: 'none',
    boxShadow: 'none',
    fontSize: 'sm',
    textShadow: 'none',
    fontWeight: 'normal',
    borderRadius: 'none',
    fontFamily: 'accent',
    h: '6',
    textTransform: 'uppercase',
    color: 'white',
    borderBottom: '3px solid',
    borderBottomColor: 'transparent',
    px: '3',
    mx: '2',
    _hover: {
      color: 'beta.500',
    },
    _active: {
      color: 'beta.500',
      fontWeight: 'normal',
      borderBottom: '3px solid',
      borderBottomColor: 'beta.500',
    },
    sx: {
      '&[data-active]': {
        _after: {
          display: 'none',
        },
      },
    },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    px: ['2', '0'],
    mx: '0',
  },
  accordionStack: {
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    boxShadow:
      '0px 4px 6px 0px var(--bc-colors-blackAlpha-50), 0px 0px 0px 2px white inset, 0px 0px 0px 3px #C8B277 inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-50) inset',
    bg: `beta.100`,
    border: 'none',
    borderRadius: 'md',
    overflow: 'hidden',
    p: '1px',
  },
  accordionButton: {
    color: 'beta.800',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
    w: ['99%', '99.48%'],
    mx: '0.5',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _hover: {
      bg: 'none',
    },
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px var(--bc-colors-whiteAlpha-100)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
  },
  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
    fontSize: 'sm',
    fontFamily: 'subHeading',
    lineHeight: 'normal',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'beta.800',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    fontFamily: 'heading',
    fontSize: 'xs',
    px: '3',
  },
  clearButton: {
    fontFamily: 'heading',
    fontSize: 'xs',
    ml: '3',
    px: '3',
  },
  sgmLogoV2: {
    w: '3em',
    h: '1.1em',
  },
  sgmBtn: {
    height: '8',
    minWidth: '16',
    display: 'block',
    marginTop: ['4', '0'],
    marginRight: ['1', '0'],
    px: '1.5',
    sx: {
      '.btn-span': {
        gap: '0.5',
      },
      'svg[class*="StyledIconBase"]': {
        color: 'beta.500',
        w: '3.5',
        h: '3.5',
      },
    },
    _hover: {
      bgImage:
        'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
      color: 'beta.500',
    },
  },
  sgmBtnActive: {
    bgGradient: 'linear(to-b, beta.900, beta.900)',
    boxShadow: 'none',
  },
  accordionItemActive: {
    pb: '1.5',
    boxShadow:
      '0px 4px 6px 0px var(--bc-colors-blackAlpha-50), 0px 0px 0px 2px white inset, 0px 0px 0px 3px #C8B277 inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-50) inset',
    bg: `beta.100`,
  },
  toMarketButtonV2: {
    mb: '-3',
    mr: '0',
    pt: '0',
    _active: {
      height: '5',
      color: 'beta.500',
      borderBottom: '3px solid',
      borderBottomColor: 'beta.500',
    },
  },
  countdown: {
    sx: {
      textTransform: 'capitalize',
      '&&&.state-prior': {
        lineHeight: 'initial',
        color: 'white',
        bgColor: 'alpha.900',
        p: '1',
      },
    },
  },
  scrollButtonGroupV2: {
    flex: 1,
    width: '85%',
  },
};
