import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextNoResults } from '../styles/ActivityStatements.styles';

type TNoResults = {
  pageName: string;
};

export default function NoResults({ pageName }: TNoResults) {
  return (
    <TextNoResults>
      <FormattedMessage
        id="account.transactions.noresults"
        values={{ pageName: pageName.toLowerCase() }}
      />
    </TextNoResults>
  );
}
