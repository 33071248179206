import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';
import activeBg from '@/assets/junglebet/images/backgrounds/image-leaves.png';

const solid: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  boxShadow:
    '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px #89D255 inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
  fontWeight: 'black',
  textTransform: 'uppercase',
  fontSize: 'sm',

  '&&': {
    borderRadius: '7px',
  },

  _disabled: {
    bg: 'gray.400',
    borderWidth: '2px',
    borderColor: 'gray.300',
    color: 'blackAlpha.500',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset',
    border: 'none',
    opacity: '1',
    pointerEvents: 'none',
  },

  '&[data-active="true"]': {
    bg: 'delta.600',
    boxShadow:
      '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px #89D255 inset',
    color: 'white',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.40)',
    border: 'none',

    '&&': {
      bgImg: `url(${activeBg})`,
      bgRepeat: 'repeat-x',
      bgPosition: '0% 100%',
      bgSize: 'contain',
    },
  },

  _hover: {
    bgGradient: 'linear(to-b, alpha.700, alpha.800)',
    color: 'blackAlpha.700',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md', h: '34px', lineHeight: '34px' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...solid,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),

      color: 'blackAlpha.700',
      border: 0,
      boxShadow:
        '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px #7F6514 inset,0px 0px 0px 5px rgba(255, 255, 255, 0.50) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
      fontSize: 'sm',
      fontFamily: 'CarterOne',
      bgGradient: 'linear(to-b, beta.400, beta.600)',
      h: '44px',
      px: 3,
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',

      '&[data-active="true"]': {
        bg: 'delta.600',
        boxShadow:
          '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px #89D255 inset',
        color: 'white',
        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.40)',
        border: 'none',

        '&&': {
          bgImg: `url(${activeBg})`,
          bgRepeat: 'repeat-x',
          bgPosition: '0% 100%',
          bgSize: 'contain',
        },
      },
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      borderRadius: '7px !important',
      color: 'blackAlpha.700',
      bgGradient: 'linear(to-b, beta.400, beta.600)',
      boxShadow:
        '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px #89D255 inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',

      '&:active, &:hover': {
        bg: 'beta.700',
      },
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderRadius: '7px !important',
      border: '2px',
      borderColor: 'white',
      color: 'white',
      fontWeight: 'bold',

      '&:active, &:hover': {
        bg: 'whiteAlpha.300',
        borderColor: 'white',
      },
    }),
    tab: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      minW: 'auto',
      h: '3.5',
      py: '2.5',
      fontSize: '2xs',
      fontWeight: 'bold',
      boxShadow:
        '0px 0px 0px 3px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.20), 1px 3px 5px 0px rgba(0, 0, 0, 0.45)',
      borderRadius: 'md',
      bg: 'alpha.500',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
      textTransform: 'uppercase',
      px: '2',
      color: 'white',
      textAlign: 'center',
      fontFamily: 'roboto',
      fontStyle: 'normal',
      '&': {
        mb: '0.5',
        border: '2px',
        borderColor: 'beta.400',
      },
      '&:hover, &:focus': {
        bg: 'alpha.500',
      },
      '&[data-closed="true"]': {
        bg: 'alpha.500',
        color: 'whiteAlpha.400',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        boxShadow:
          '0px 0px 0px 2px #152E36 inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
        borderColor: 'alpha.700',
        h: '24px',
      },
      '&:active, &[data-active="true"]': {
        bg: 'blackAlpha.500',
        color: 'beta.400',
        border: '2px solid transparent',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
      cursor: 'auto !important',
    }),
    ghost: {
      ...solid,
      bg: undefined,
      boxShadow: undefined,
      textShadow: undefined,

      _hover: {
        bg: 'alpha.50',
      },

      _focus: undefined,

      _active: {
        bg: 'alpha.100',
      },
    },
    filter: {
      border: '2px',
      borderColor: 'delta.300',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '6',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',

      _hover: {
        color: '#a34f10',
      },

      _active: {
        borderColor: 'delta.700',
        color: 'white',
        bg: 'delta.700',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      textTransform: 'uppercase',

      _hover: {
        color: 'beta.500',
      },

      _active: {
        bg: 'orange.300',
        borderRadius: 'base',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
        fontSize: '2xs',
        fontWeight: 'black',
      },
    },
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      _hover: {
        '&&': {
          borderBottom: '3px solid',
          borderColor: 'beta.500',
          ':active, &[data-active="true"]': {
            border: 'none',
          },
        },
      },
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
