import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalProps,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

type TModal = {
  children: ReactNode;
  title: string;
} & ModalProps;

export default function Modal({ title, children, ...rest }: TModal) {
  return (
    <ChakraModal
      {...rest}
      scrollBehavior="inside"
      size={isMobile ? 'full' : 'lg'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
}
