import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  color: 'delta.50',
  h: 'full',
  borderRadius: 'base',
  border: 'none',
  _hover: {
    bg: 'delta.200',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  color: 'delta.50',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'delta.50',
};
