import sansitaBlack from '@/assets/puntcity/fonts/Sansita-Black.ttf';
import sansitaBlackItalic from '@/assets/puntcity/fonts/Sansita-BlackItalic.ttf';
import sansitaBold from '@/assets/puntcity/fonts/Sansita-Bold.ttf';
import sansitaBoldItalic from '@/assets/puntcity/fonts/Sansita-BoldItalic.ttf';
import sansitaExtraBold from '@/assets/puntcity/fonts/Sansita-ExtraBold.ttf';
import sansitaExtraBoldItalic from '@/assets/puntcity/fonts/Sansita-ExtraBoldItalic.ttf';
import sansitaItalic from '@/assets/puntcity/fonts/Sansita-Italic.ttf';
import sansitaRegular from '@/assets/puntcity/fonts/Sansita-Regular.ttf';

import MontserratBlack from '@/assets/puntcity/fonts/Montserrat-Black.ttf';
import MontserratBlackItalic from '@/assets/puntcity/fonts/Montserrat-BlackItalic.ttf';
import MontserratBold from '@/assets/puntcity/fonts/Montserrat-Bold.ttf';
import MontserratBoldItalic from '@/assets/puntcity/fonts/Montserrat-BoldItalic.ttf';
import MontserratExtraBold from '@/assets/puntcity/fonts/Montserrat-ExtraBold.ttf';
import MontserratExtraBoldItalic from '@/assets/puntcity/fonts/Montserrat-ExtraBoldItalic.ttf';
import MontserratExtraLight from '@/assets/puntcity/fonts/Montserrat-ExtraLight.ttf';
import MontserratExtraLightItalic from '@/assets/puntcity/fonts/Montserrat-ExtraLightItalic.ttf';
import MontserratItalic from '@/assets/puntcity/fonts/Montserrat-Italic.ttf';
import MontserratLight from '@/assets/puntcity/fonts/Montserrat-Light.ttf';
import MontserratLightItalic from '@/assets/puntcity/fonts/Montserrat-LightItalic.ttf';
import MontserratMedium from '@/assets/puntcity/fonts/Montserrat-Medium.ttf';
import MontserratMediumItalic from '@/assets/puntcity/fonts/Montserrat-MediumItalic.ttf';
import MontserratRegular from '@/assets/puntcity/fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from '@/assets/puntcity/fonts/Montserrat-SemiBold.ttf';
import MontserratSemiBoldItalic from '@/assets/puntcity/fonts/Montserrat-SemiBoldItalic.ttf';
import MontserratThin from '@/assets/puntcity/fonts/Montserrat-Thin.ttf';
import MontserratThinItalic from '@/assets/puntcity/fonts/Montserrat-ThinItalic.ttf';

import damionRegular from '@/assets/puntcity/fonts/Damion-Regular.ttf';

export const fontFace = `
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${sansitaBlack}') format('opentype');
  }
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${sansitaBlackItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${sansitaBold}') format('opentype');
  }
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${sansitaBoldItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('${sansitaExtraBold}') format('opentype');
  }
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('${sansitaExtraBoldItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${sansitaItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${sansitaRegular}') format('opentype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${MontserratBlack}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${MontserratBlackItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${MontserratBold}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${MontserratBoldItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('${MontserratExtraBold}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('${MontserratExtraBoldItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('${MontserratExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('${MontserratExtraLightItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${MontserratItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${MontserratLight}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${MontserratLightItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${MontserratMedium}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${MontserratMediumItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${MontserratRegular}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('${MontserratSemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('${MontserratSemiBoldItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${MontserratThin}') format('opentype');
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${MontserratThinItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Damion';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${damionRegular}') format('opentype');
  }
`;
