import {
  BoxProps,
  ButtonProps,
  CSSObject,
  FlexProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './EvenShot.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type EvenShotSchema = Partial<{
  betPlacedText: TextProps;
  oddsText: TextProps;
  flexWrapper: FlexProps;
  headingText: CSSObject;
  subHeadingText: TextProps;
  addToBetslipButton: ButtonProps;
  loginToView: ButtonProps;
  evenShotImage: BoxProps;
  notYetAvailableText: TextProps;
  comeBackLaterText: TextProps;
  hasBetSubTitleText: TextProps;
  oddsValueSpan: TextProps;
  notAvailableText: TextProps;
}>;

export const evenShotStyles: EvenShotSchema = {
  notAvailableText: {
    'data-testid': 'evenShot-notAvailableText',
    my: '1',
    textAlign: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.notAvailableText;
      } catch (err) {
        return {};
      }
    })(),
  },
  oddsValueSpan: {
    'data-testid': 'evenShot-oddsValueSpan',
    as: 'span',
    color: 'white',
    sx: {
      '&[data-hasbets="true"]': {
        color: '#FAB735',
      },
    },

    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.oddsValueSpan;
      } catch (err) {
        return {};
      }
    })(),
  },
  hasBetSubTitleText: {
    'data-testid': 'evenShot-hasBetSubTitleText',
    fontSize: 'sm',
    textAlign: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.hasBetSubTitleText;
      } catch (err) {
        return {};
      }
    })(),
  },
  comeBackLaterText: {
    textAlign: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.comeBackLaterText;
      } catch (err) {
        return {};
      }
    })(),
  },
  notYetAvailableText: {
    'data-testid': 'evenShot-notYetAvailableText',
    my: '1',
    textAlign: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.notYetAvailableText;
      } catch (err) {
        return {};
      }
    })(),
  },
  subHeadingText: {
    'data-testid': 'evenShot-subHeadingText',
    textAlign: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.subHeadingText;
      } catch (err) {
        return {};
      }
    })(),
  },
  betPlacedText: {
    'data-testid': 'evenShot-betPlacedText',
    mb: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.betPlacedText;
      } catch (err) {
        return {};
      }
    })(),
  },
  oddsText: {
    'data-testid': 'evenShot-oddsText',
    textAlign: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.oddsText;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexWrapper: {
    'data-testid': 'evenShot-flexWrapper',
    marginX: 2,
    p: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    minH: 'calc(100vh - 350px)',

    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.flexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  headingText: {
    'data-testid': 'evenShot-headingText',
    mb: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.headingText;
      } catch (err) {
        return {};
      }
    })(),
  },
  addToBetslipButton: {
    'data-testid': 'evenShot-addToBetslipButton',
    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.addToBetslipButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  loginToView: {
    'data-testid': 'evenShot-loginToView',
    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.loginToView;
      } catch (err) {
        return {};
      }
    })(),
  },
  evenShotImage: {
    'data-testid': 'evenShot-evenShotImage',
    ...(() => {
      try {
        return styleImports[themeName]?.evenShotStyles?.evenShotImage;
      } catch (err) {
        return {};
      }
    })(),
  },
};
