import { Image } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/questbet/images/logo.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Image
        src={logo}
        alt={`${THEME_NAME.questbet}`}
        sx={{
          pt: ['0', null, '1'],
          pb: ['0', null, '5'],
          m: 'auto',
          alignSelf: 'center',
        }}
      />
    </Link>
  );
}
