import { css } from 'styled-components';

export const container = css``;

// TODO: Remove
export const resultContainer = css`
  font-size: var(--bc-fontSizes-xs);
  background: ${({ theme }) => theme.palette.inputBg};
  border: ${({ theme }) => theme.borderStyles.tertiary};
  border-top: none;
`;

export const result = css`
  padding-left: var(--bc-sizes-1);

  &:hover {
    background: ${({ theme }) => theme.basePalette.midPurple};
    color: white;
  }
`;

export const resultFocussed = css`
  background: ${({ theme }) => theme.basePalette.midPurple};
  color: white;
`;

export const loadIndicator = css``;
