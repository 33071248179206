import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import hoverDecoration from '@/assets/goldenrush/images/betslip/decoration-small.png';

import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'white',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        bg: 'transparent',
        boxShadow: 'sm',
        borderRadius: [null, null, null, 'md'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
        w: 'auto',
        m: [null, null, null, '5px 0px'],
        mb: [null, null, null, '9px'],
        zIndex: '5',
        pos: 'relative',
        p: '5',
      },
      drawer: {
        ...base.drawer,
        p: 0,
        bgGradient: 'linear(180deg, alpha.500, alpha.600)',
      },
      drawerBody: {
        '> div, a': {
          ml: ['0', null, null, '2'],

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '5',
        color: 'gamma.600',
        mb: '0.5',
      },
      headerSX: {
        fontWeight: ['normal', null, null, 'extrabold'],
        letterSpacing: ['normal', null, null, 'widest'],
        cursor: 'default',
        color: 'gamma.500',
        height: '20px',
        borderColor: 'transparent',
        boxShadow: 'none',
        bg: 'transparent',
        fontFamily: 'Bungee',
        textTransform: 'uppercase',
        fontSize: ['md', null, null, 'lg'],
        textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',

        '&:hover, &:active': {
          borderColor: 'transparent',
          bg: 'transparent',
          color: 'gamma.500',
          height: '20px',

          _before: {
            content: 'none',
          },

          _after: {
            content: 'none',
          },
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,
        pos: 'relative',
        zIndex: '5',
        fontSize: 'sm',
        fontWeight: 'semibold',
        fontStyle: 'normal',
        height: ['40px', '38px'],
        color: 'white',
        transition: 'all, 0.2s linear',
        fontFamily: 'Roboto',
        textTransform: 'capialize',
        textShadow: '0px 2px 3px rgba(0, 0, 0, 0.20)',
        bg: 'blackAlpha.300',
        boxShadow:
          '0px 1px 5px 1px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
        borderRadius: 'base',
        pl: '2',
        mb: '2',
        ':last-child': {
          mb: '2',
        },
        '~ .nested': {
          pl: '10',
        },

        ':hover, :active': {
          borderBottomColor: 'transparent',
          bgGradient: 'linear(to-b, gamma.400, gamma.600)',
          color: '#7A3C25',
          filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.1))',
          height: ['40px', '38px'],

          WebkitBackdropFilter: 'blur(5px)',
          backdropFilter: 'blur(5px)',
          svg: {
            color: '#7A3C25',
          },

          _before: {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: ['10px', '9px'],
            left: ['-6px', '210px'],
            width: '6px',
            height: '20px',
            zIndex: 'docked',
            bg: `url(${hoverDecoration})`,
            bgRepeat: 'repeat',
            bgSize: '29px 10px',
          },

          _after: {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: ['10px', '9px'],
            right: ['-6px', '210px'],
            width: '6px',
            height: '20px',
            zIndex: 'docked',
            bg: `url(${hoverDecoration})`,
            bgRepeat: 'repeat',
            bgSize: '9px 10px',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: '2',
          mb: '2',
          bg: 'alpha.700',
          color: 'white',
        },

        '> a, .dropDownIcon, a': {
          color: 'white',
        },

        _hover: {
          '.dropDownInner': {
            bgGradient: 'linear(to-b, gamma.400, gamma.600)',
            color: '#7A3C25',
            mb: '2',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'white',
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',
        color: 'white',

        _hover: {
          '.dropDownInner': {
            bgGradient: 'linear(to-b, gamma.400, gamma.600)',
            color: '#7A3C25',
            mb: '2',
          },
        },

        '.dropDownInner': {
          bg: 'alpha.700',
          color: 'white',
          pl: '2',
        },

        '> a, .dropDownIcon, a': {
          color: 'white',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'white',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
