import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';

import { FormsHStack } from '../../../BetCard/components/Forms/Forms.styles';
import { BetCardSVG } from '../../../Card/styles/BetCard.styles';
import { InfoExoticsContainer } from '../../../BetCard/components/Info/styles/Info.styles';
import { srmInfoStyles } from '../../../BetCard/components/Info/SRM/styles/SRMInfo.styles';
import { TSGMultiMoMBet } from '../types';
import { betCardStyles } from '../../../BetCard/styles/BetCard.styles';
import SgmLogo from '@/components/SgmLogo/SgmLogo';

interface IMoMSGMCardItemProps {
  bet: TSGMultiMoMBet;
  hasHeader?: boolean;
}

export function MoMSGMCardItem({ bet, hasHeader }: IMoMSGMCardItemProps) {
  return (
    <FormsHStack gap={2} mt={2} direction="column">
      <Box>
        {hasHeader && (
          <Flex direction="column" gap="2" w="full">
            <Flex w="full">
              <BetCardSVG header name="multi_betslip" />
              <InfoExoticsContainer gap={2}>
                <Flex>
                  <Text {...srmInfoStyles.runnerDetails}>{bet.title}</Text>{' '}
                  <SgmLogo {...betCardStyles.betCardSVG} />
                </Flex>
                <Text {...srmInfoStyles.eventMarketName}>{bet.subTitle}</Text>
                <Text {...srmInfoStyles.eventSubtitle}>{bet.description}</Text>
              </InfoExoticsContainer>
            </Flex>
          </Flex>
        )}
        <Flex direction="column" mt={2}>
          <Box {...srmInfoStyles.infoExoticsContainer}>
            {bet.legs.map((leg) => (
              <Box key={leg.propName} {...srmInfoStyles.sgmLegsContainer}>
                <Text key={leg.propName} {...srmInfoStyles.eventMarketName}>
                  {leg.propName}
                </Text>
                <Text key={leg.propName} {...srmInfoStyles.eventSubtitle}>
                  {leg.marketName}
                </Text>
              </Box>
            ))}
          </Box>
        </Flex>
      </Box>
    </FormsHStack>
  );
}
