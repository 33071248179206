import React from 'react';
import { FormattedMessage } from 'react-intl';
import { buttonRefreshProps } from '../styles/Maintenance.styles';
import { Button } from '@/components/Button/Button';

export default function ButtonRefresh() {
  return (
    <Button
      data-cy="maintenance-ButtonRefresh"
      {...buttonRefreshProps}
      onClick={() => window.location.reload()}
    >
      <FormattedMessage id="generic.refresh" />
    </Button>
  );
}
