import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { TextItem, LinkItem, Wrapper } from './styles/JoinNow.styles';

export default function JoinNow() {
  const { pathname } = useLocation();
  const isLogin = pathname.includes('login');

  return (
    <Wrapper>
      <TextItem>
        <FormattedMessage
          id={
            isLogin ? 'onboarding.login.footerInfo' : 'onboarding.step1.login'
          }
          values={{
            link: (string: string) => (
              <LinkItem to={isLogin ? '/signup' : '/login'}>{string}</LinkItem>
            ),
          }}
        />
      </TextItem>
    </Wrapper>
  );
}
