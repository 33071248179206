import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: ['lg', 'xl'],
  fontWeight: 'semibold',
  fontFamily: 'Roboto',
  color: 'white',
  textShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.25)',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
