import { CSSObject } from '@chakra-ui/react';
import starryNight from '@/assets/betgalaxy/images/header/quickAccessMenu/starryNight.png';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  minh: '63px',
  mb: '2',
  px: '2',
  py: '2',
  bg: '#3B4175',
  bgImage: `url(${starryNight})`,
  bgSize: 'cover',
};

export const ButtonGetVerified: CSSObject = {
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px #FFF inset',
  px: '2',
  py: '1',
  bg: 'alpha.500',
  color: 'alpha.900',
  borderRadius: 'base',
  fontSize: '2xs',
  h: 6,
  fontWeight: 'extrabold',
  textTransform: 'uppercase',
  ml: 'auto',
};
