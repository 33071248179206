import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { COUNTDOWN_START_MINS } from './Constants';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

export const showCountdownTimer = (startTime: string | undefined): boolean => {
  if (!startTime) return false;

  return (
    dayjs().isAfter(
      dayjs(startTime).subtract(COUNTDOWN_START_MINS, 'minutes')
    ) && dayjs().isBefore(dayjs(startTime))
  );
};

const dateWithFormat = (str: string | undefined, format: string): string => {
  if (!str) return '';
  const dateObj = dayjs(str);
  if (!dateObj.isValid()) return '';
  return dateObj.format(format);
};

export const format12HrsDateTime = (str: string | undefined): string =>
  dateWithFormat(str, 'DD/MM/YY h:mmA');

export const format24HrDateTime = (str: string | undefined): string =>
  dateWithFormat(str, 'DD/MM/YY HH:mm');

export const formatDateMonthTime = (str: string | undefined): string =>
  dateWithFormat(str, 'DD/MM HH:mm');

export const formatDateMonthYear = (str: string | undefined): string =>
  dateWithFormat(str, 'DD/MM/YY');

export const formatDateMonth = (str: string | undefined): string =>
  dateWithFormat(str, 'DD/MM');

export const formatTime = (str: string | undefined): string =>
  dateWithFormat(str, 'HH:mm');

export const formatTimeWZone = (str: string | undefined) =>
  dateWithFormat(str, 'h:mm:ss A (z)');

export const formatDateTimeWZone = (str: string | undefined) =>
  dateWithFormat(str, 'DD/MM/YY hh:mm:ss z');

export const formatDayDateMonthTime = (str: string | undefined) =>
  dateWithFormat(str, 'ddd, MMM D, h:mm A');

export const formatDateFullMonth = (str: string | undefined): string =>
  dateWithFormat(str, 'Do MMMM');

export const formatDateToConditional = (str: string) => {
  const dtObj = dayjs(str);

  const currentDate = dayjs();
  const formattedDate = dtObj.format('dddd, D MMMM');

  let displayDate;

  if (currentDate.isSame(dtObj, 'day')) {
    displayDate = `Today, ${dtObj.format('D MMMM')}`;
  } else if (currentDate.add(1, 'day').isSame(dtObj, 'day')) {
    displayDate = `Tomorrow, ${dtObj.format('D MMMM')}`;
  } else {
    displayDate = formattedDate;
  }

  return displayDate;
};

export const sleep = (ms: number) =>
  new Promise((res) => {
    setTimeout(() => res(null), ms);
  });
