import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderColor: 'alpha.300',
  borderStyle: 'dashed',
  opacity: '1',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
  bg: 'red',
};

export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  bgGradient: 'linear-gradient(180deg, #55D7FF 0%, #3AABCD 100%)',
  color: 'alpha.800',
  boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
};

export const TextMaxCards: CSSObject = {
  color: 'delta.100',
};

export const BoxInputContainer: CSSObject = {
  mb: '1',

  '.chakra-form-control': {
    mb: '0',
  },

  '& .chakra-input': {
    bg: 'zeta.100',
    border: 'none',
    _placeholder: {
      color: 'gray.700',
      fontSize: 'xs',
    },
    _focus: {
      bg: 'zeta.100',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const FormBox: CSSObject = {
  'div.chakra-collapse div p strong': {
    color: 'alpha.800',
  },
  mt: '2',
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'gamma.800',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'regular',
  lineHeight: '14.4px',
  color: 'white',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  lineHeight: 'normal',
  fontSize: 'sm',
  fontFamily: 'body',
  fontWeight: 'black',
  sx: {
    '&&': {
      h: '10',
    },
  },
};
