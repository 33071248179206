import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
    marginBottom: '1.5',
  },
  countdown: {
    textTransform: 'capitalize',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: 'lg',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    color: 'white',
    textTransform: 'uppercase',
  },
  headerIconButton: {
    color: 'white',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'alpha.100',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'beta.500',
    h: '1.1em',
    ml: '2',
    mb: '0.5',
    w: 'auto',
  },
  scrollButtonGroup: {
    h: '10',
    variant: 'filter',
    boxShadow: 'none',
    w: 'full',
  },
  wrapperScrollSgm: {
    gap: '2',
    alignItems: 'center',
    display: 'flex',
    w: 'full',
  },
  toMarketButton: {
    bg: 'transparent',
    h: 8,
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    border: 'none',
    bg: '#7244EE',
    borderRadius: 'md',
    overflow: 'hidden',
    boxShadow: '0px 0px 0px 1px #FFFFFF1F inset, 0px 4px 10px 0px #0000004D',
  },
  accordionButton: {
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    p: '2',
    mx: '1',
    w: '99%',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
  },
  toMarketButtonV2: {
    color: 'white',
    fontSize: 'sm',
    textTransform: 'uppercase',
    minW: 'fit-content',
    fontWeight: 'normal',
    cursor: 'pointer',
    lineHeight: 'unset',
    bg: 'transparent',
    borderRadius: 0,
    border: 'none',
    sx: {
      '&&': {
        px: 2,
        ml: 1,
        h: 8,
        _first: { ml: 0 },
        _after: { display: 'none' },
      },
      '&[data-active]': {
        border: 'none',
        bgImage: 'unset',
        color: 'white',
        boxShadow: '0 3px rgba(255, 195, 28, 1)',
      },
      '.chakra-button__icon': {
        margin: 0,
      },
      '&:hover, &:focus': {
        bg: 'transparent',
        color: 'white',
      },
    },
    py: ['2', '3'],
    pl: ['2', '4'],
    pr: ['2', '4'],
    fontFamily: 'Staatliches',
  },
  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    variant: 'solid',
    px: '3',
  },
  clearButton: {
    variant: 'solid',
    ml: '3',
    px: '3',
  },
  scrollButtonGroupV2: {
    variant: 'filter',
    flex: 1,
    position: 'relative',
    minH: '34px',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    sx: {
      'div.chakra-button__group': {
        bg: 'transparent',
        boxShadow: 'none',
      },
    },
    mx: [-2, 0],
  },
  sgmLogoV2: {
    w: '3em',
    color: 'gamma.400',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
  sgmBtn: {
    bgGradient: 'linear(to-b, alpha.300, alpha.400)',
    w: ['86px', null, '96px'],
    sx: {
      'svg[class*="StyledIconBase"]': {
        w: '1.5em',
        h: '1.5em',
        fill: 'gamma.400',
        filter: 'drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.25))',
      },
    },
  },
  sgmBtnActive: {
    sx: {
      '&&': {
        px: '3',
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      },
      svg: {
        w: '3em',
        color: 'alpha.200',
      },
      'svg[class*="StyledIconBase"]': {
        w: '1.5em',
        h: '1.5em',
        fill: 'alpha.200',
      },
    },
  },
};
