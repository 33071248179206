/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import Card from '../Card';
import {
  FlexFeatureCardWrapper,
  FlexFeatureTitleWrapper,
  FlexFeatureWrapper,
  TextFeatureHeading,
  TextFeatureStyledHeading,
  TextFeatureSubHeading,
} from '../styles/Featured.styles';

export const template: TTemplate = {
  wrapper: <FlexFeatureWrapper />,
  feature: (
    <FlexFeatureTitleWrapper>
      <TextFeatureSubHeading>
        Featured{' '}
        <TextFeatureStyledHeading as="span">race</TextFeatureStyledHeading>{' '}
      </TextFeatureSubHeading>
      <TextFeatureHeading>IN OUTER SPACE</TextFeatureHeading>
    </FlexFeatureTitleWrapper>
  ),
  card: (
    <FlexFeatureCardWrapper>
      <Card />
    </FlexFeatureCardWrapper>
  ),
};

export default template;
