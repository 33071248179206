import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { keys } from '@/api/api.keys';
import { querySportMarkets } from './sportDetailsMarkets';
import {
  TSportMarketsParams,
  TSportMarketsResponse,
  TSportsMarketLayout,
} from './sportDetailsMarkets.types';

export const useQuerySportMarkets = (
  params: TSportMarketsParams,
  options?: Omit<UseQueryOptions<TSportMarketsResponse>, 'queryKey' | 'queryFn'>
) => {
  const query = useQuery(
    [keys.sportDetailsMarkets, params],
    () => querySportMarkets(params),
    options
  );
  return query;
};

export const useQuerySportsMarketLayouts = (
  params: TSportMarketsParams,
  options?: Omit<
    UseQueryOptions<TSportMarketsResponse, unknown, TSportsMarketLayout[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const query = useQuery<TSportMarketsResponse, unknown, TSportsMarketLayout[]>(
    [keys.sportDetailsMarkets, params],
    () => querySportMarkets(params),
    options
  );
  return query;
};
