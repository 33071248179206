import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/oldgill/images/logo.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image src={logo} alt={THEME_NAME.oldgill} pt={1} />
      </Show>
      <Hide above="lg">
        <Image src={logo} alt={THEME_NAME.oldgill} h="7" w="auto" />
      </Hide>
    </Link>
  );
}
