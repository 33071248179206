import { CSSObject, TextProps } from '@chakra-ui/react';

export const FormControlGroup: CSSObject = {
  mb: '3',
};

export const FormLabelThemed: CSSObject = {
  fontSize: 'sm',
};

export const formLabelError: TextProps = {
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  mt: '1.5',

  sx: { svg: { display: 'none' } },
};
