import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  borderColor: 'whiteAlpha.300',
  color: 'white',
  borderRight: 'none',
  borderStyle: 'dashed',
  pl: 0,
  py: 2,
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'beta.500' : 'white',
});

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const ButtonMoreTable: ButtonProps = {
  variant: 'primary',
};
