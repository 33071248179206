import {
  BoxProps,
  chakra,
  ChakraComponent,
  CSSObject,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports, styleImport } from './Home.styles.imports';

type TOverrideName = 'FlexBannerWrapper';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};
// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const FlexBannerWrapper = chakra(Flex, {
  baseStyle: () => ({
    flexDir: 'column',

    ...styles?.FlexBannerWrapper,
  }),
}) as ChakraComponent<'div', FlexProps>;

/**
 * These are the styles
 */
type TDataTestID = { 'data-testid'?: string };
export type THomeStyles = {
  wrapper: BoxProps & TDataTestID;
};
export const stylesNew: THomeStyles = {
  wrapper: {
    'data-testid': 'home-wrapper',

    ...styleImport?.wrapper,
  },
};
