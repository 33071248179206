import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};

export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  border: 'none',
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: '700',
  fontFamily: 'roboto',
  h: '5',
  px: '1',
  py: '2',
  mb: ['1', null, '0'],
  textTransform: 'uppercase',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  color: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'blackAlpha.700';
      default:
        return 'white';
    }
  },
  bg: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'beta.500';
      case EGreenIDStatus.PENDING:
        return 'yellow.500';
      default:
        return 'red.500';
    }
  },
  _hover: {
    bg: 'beta.600',
    color: 'blackAlpha.700',
    border: 'none',
  },
  _disabled: {
    border: 'none',
    boxShadow: 'none',
    bgGradient: 'none',
    fontFamily: 'roboto',
    cursor: 'default',
    opacity: '1',
    color: 'blackAlpha.700',
    _hover: {
      bg: 'beta.600',
      color: 'blackAlpha.700',
      border: 'none',
    },
    _after: { opacity: 0 },
  },

  '.chakra-button__icon': {
    ml: '1',
  },

  '.chakra-icon': {
    boxSize: '3',
    bg: 'blackAlpha.700',
    borderRadius: 'full',
    color: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'beta.500';
        default:
          return 'white';
      }
    },
    m: '1px',
    p: '1px',
    boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.50)',
  },
});

export const BoxOverviewTitle: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  fontFamily: 'Staatliches',
  pb: '2',
};
