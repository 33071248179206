import api from '@/api/api';
import {
  TQuerySportPropsParams,
  TQuerySportPropsResponse,
} from './sportDetailsProps.types';

export const sportPropsEndpoint = 'punter/sports/sport-details/props';

export async function querySportProps(params?: TQuerySportPropsParams) {
  const res = await api.get<TQuerySportPropsResponse>(sportPropsEndpoint, {
    params,
  });
  return res.data;
}
