import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  '> div:first-child': {
    color: 'gray.500',
    fontSize: 'md',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'semibold',
    mb: '4',
  },
  form: {
    label: {
      color: 'gray.500',
      fontSize: 'xs',
      fontWeight: 'semibold',
    },
    'select[name*="title-dropdown"], #residential-input': {
      h: '38px',
      borderRadius: 'lg',
      borderWidth: '1px',
      borderStyle: ' solid',
      borderColor: 'gray.300',
      background: 'gray.50',
    },
    '[class*=chakra-checkbox]': {
      alignItems: 'flex-start',
      '&[data-checked]': {
        bg: 'unset',
        borderColor: 'inherit',
        color: 'black',
      },
    },
    'button[aria-label*="password toggle"]': {
      top: 'px',
    },
  },
};

export const Title: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: '4',
  mt: [null, null, '9'],
};
