import {
  Box,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  TextProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './MobileTransactionsTable.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { TTableText } from '../../../types';

const themeName = getThemeName();

export const transactionDateTimeFlexProps: FlexProps = {
  'data-testid': 'mobileTransactionsTable-transactionDateTimeFlexProps',

  ...(() => {
    try {
      return styleImports[themeName]?.transactionDateTimeFlexProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const transactionInfoFlexProps: FlexProps = {
  'data-testid': 'mobileTransactionsTable-transactionInfoFlexProps',

  ...(() => {
    try {
      return styleImports[themeName]?.transactionInfoFlexProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const TableWrapper = chakra(Flex, {
  label: 'mobileTransactionsTable-TableWrapper',
  baseStyle: () => ({
    width: '100%',
    flexDir: 'column',
    pt: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.TableWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TableHeadingsWrapper = chakra(Flex, {
  label: 'mobileTransactionsTable-TableHeadingsWrapper',
  baseStyle: () => ({
    width: '100%',
    mb: '2',
    mx: '2',
    px: '5',
  }),
});

type TTableColumnWrapper = {
  rightSide?: boolean;
  flex?: number;
} & TextProps &
  any;

export const TableColumnWrapper = chakra<TTableColumnWrapper>(Flex, {
  label: 'mobileTransactionsTable-TableColumnWrapper',
  baseStyle: ({ rightSide, flex }: TTableColumnWrapper) => ({
    flexDir: 'column',
    flex: flex || 1,
    ...(rightSide && {
      justifyContent: 'flex-end',
      pt: '8',
    }),
  }),
});

type TTableFlexItem = {
  flex?: number;
} & FlexProps &
  any;

export const TableFlexItem = chakra<TTableFlexItem>(Flex, {
  label: 'mobileTransactionsTable-TableFlexItem',
  baseStyle: ({ flex }: TTableFlexItem) => ({
    flexDirection: 'column',
    flex: flex || '1',
    pr: '1',
    fontSize: 'md',
  }),
});

export const TableHeadingText = styled.h4`
  ${({ theme }) =>
    theme.features.account.mobileTransactionsTable.tableHeadingText};
`;

export const DayHeadingWrapper = chakra(Box, {
  label: 'mobileTransactionsTable-DayHeadingWrapper',
  baseStyle: () => ({
    px: '5',
    py: '2',
    borderRadius: 'base',
  }),
});

export const DayHeading = chakra('h4', {
  label: 'mobileTransactionsTable-DayHeading',
  baseStyle: () => ({
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.DayHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PageHeadingWrapper = chakra(Flex, {
  label: 'mobileTransactionsTable-PageHeadingWrapper',
  baseStyle: () => ({
    flexDirection: 'row',
    alignItems: 'baseline',
    width: '100%',
    maxWidth: '1200px',
    p: '1',
    borderBottom: '1px',
    justifyContent: 'flex-end',
    ...(() => {
      try {
        return styleImports[themeName]?.PageHeadingWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PageHeading = styled.h5`
  ${({ theme }) => theme.features.account.mobileTransactionsTable.pageHeading};
`;

export const DownloadActionLink = chakra('p', {
  label: 'mobileTransactionsTable-DownloadActionLink',
  baseStyle: () => ({
    fontSize: 'sm',
    ml: '10',
    cursor: 'pointer',
    marginLeft: 'auto',

    ...(() => {
      try {
        return styleImports[themeName]?.DownloadActionLink;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TransactionWrapper = chakra(Flex, {
  label: 'mobileTransactionsTable-TransactionWrapper',
  baseStyle: () => ({
    w: 'full',
    minH: 24,
    p: 5,

    alignItems: 'flex-start',
    borderBottom: '1px solid',
    flexDir: 'column',

    ...(() => {
      try {
        return styleImports[themeName]?.TransactionWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TableText = chakra('p', {
  label: 'mobileTransactionsTable-TableText',
  shouldForwardProp: (prop) => !['textVariation'].includes(prop),
  baseStyle: ({ textVariation, align }: any) => ({
    textAlign: align || 'left',
    fontSize: 'sm',
    color: textVariation === 'positive' ? 'green.500' : 'gray.700',
    ...(() => {
      try {
        return styleImports[themeName]?.TableText?.({ textVariation });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TTableText>;

export const FooterWrapper = chakra(Flex, {
  label: 'mobileTransactionsTable-FooterWrapper',
  baseStyle: () => ({
    pt: '6',
    pb: '4',
    justifyContent: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.FooterWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;
