import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import Carousel from '../../../../components/Carousel';
import promo from '@/assets/betgalaxy/images/carousel/Promo.png';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['1.5', null, null, '0'],
        borderRadius: [null, null, null, 'base'],
        contain: 'paint',
      }}
    >
      <Carousel slides={[<Image key={2} src={promo} />]} />
    </Box>
  );
}
