import { CSSObject, keyframes } from '@chakra-ui/react';

import toggle from '@/assets/juicybet/images/sidenav/toggle.png';
import featuredCardsBg from '@/assets/juicybet/images/backgrounds/inner.png';

export const StitchWrapper: CSSObject = {
  display: 'flex',
  boxSize: 'full',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow:
    '0px 0px 10px 0px #CD3A39 inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.12) inset',
  borderRadius: 'base',
  bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${featuredCardsBg})`,
  bgRepeat: 'repeat',
  bgSize: '95%',
  backgroundBlendMode: 'overlay',
};

export const FlexWrapper: CSSObject = {
  border: '6px solid',
  borderColor: 'alpha.500',
  alignItems: 'center',
  contain: 'layout',
  height: '85px',
  justifyContent: 'center',
  mx: '2',
  borderRadius: 'lg',
  mb: '4',
  p: '1.5',
  bg: 'alpha.50',
  bgRepeat: 'repeat',
  bgSize: '80%',
  backgroundBlendMode: 'overlay',
};

export const TextToggle = (isActive: boolean): CSSObject => ({
  fontFamily: 'Chewy',
  fontWeight: 'normal',
  fontSize: '2xl',
  textTransform: 'uppercase',
  color: isActive ? 'gamma.400' : 'beta.700',
  textShadow: isActive
    ? '0px 1px 4px rgba(0, 0, 0, 0.50), 0px 0px 30px #FDE832'
    : '0px 1px 0px #FF8B8A',
  letterSpacing: 'normal',
  px: '5',
  pt: '1',
});

export const Switcher = (): CSSObject => ({
  width: '60px',
  '.chakra-switch__track': {
    bg: 'red.600',
    boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    w: '50px',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',

    _before: {
      bgImg: `url(${toggle})`,
      boxSize: '46px',
      filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.30))',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-2.5',
      right: '0',
      top: '-3',
      animation: `
          ${keyframes`
            from { transform: rotate(0deg) }
            to { transform: rotate(-360deg) }
          `} 0.5s linear`,
    },
  },

  _active: {
    '.chakra-switch__track': {
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    },
  },

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
      _before: {
        animation: `
          ${keyframes`
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          `} 0.7s linear`,
      },
    },
    '.chakra-switch__track': {
      bg: 'red.600',
    },
  },
});
