import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    '&:disabled': { bg: 'transparent' },

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px',
      borderTopColor: 'blackAlpha.200',
      p: '2',

      _first: { borderTop: 0 },
    }),
    ...(!!isSelected && {}),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',

    _first: {
      color: 'white',
      textTransform: 'uppercase',
      fontSize: 'xs',
      fontWeight: 'bold',

      'button:hover &': { color: 'gamma.200' },

      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      fontFamily: 'ProximaNova',
      fontSize: 'sm',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      bgGradient: isSelected
        ? 'linear(to-b, blackAlpha.500, blackAlpha.500)'
        : 'linear(to-b, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1))',
      p: 2,
      w: 'full',
      borderRadius: 'base',
      color: 'beta.400',
      textShadow: isSelected
        ? 'none'
        : '0px 1px 4px rgba(0, 0, 0, 0.5)',
      boxShadow: isSelected
        ? '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)'
        : '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      border: 'none',
      transition: 'all, .3s ease-in-out',

      _hover: {
        bgGradient: 'linear(to-b, whiteAlpha.500, whiteAlpha.500)',
        border: 'none',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
