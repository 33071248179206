import React from 'react';
import { Text, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import {
  WithdrawalCardContent,
  WithdrawalCardDetailRequested,
  WithdrawalFlexContainer,
  WithdrawalStatus,
  WithdrawalWrapper,
  ButtonWithdrawalCardCancel,
} from '../styles/RequestedWithdrawalCard.styles';
import { centsToDollars } from '@/helpers/utils';
import { EWithdrawalStatus, TWithdrawal } from '../../../../../lib/DBModels';

const template = (
  withdrawalRequest: TWithdrawal,
  handleCancelWithdrawal: (id: string | undefined) => void
): TTemplate => ({
  wrapper: <WithdrawalWrapper flexDir="row" />,
  content: (
    <WithdrawalCardContent>
      <WithdrawalCardDetailRequested>
        <Text fontWeight="semibold" fontSize="sm">
          {withdrawalRequest?.bank_nickname} :{' '}
          {`-${centsToDollars(withdrawalRequest?.amount_requested)}`}
        </Text>
        <Flex fontSize="xs">
          <Text>
            {dayjs(withdrawalRequest?.date_requested).format('DD/MM/YYYY')}
          </Text>
          <Text px="2"> | </Text>
          <Text>
            {dayjs(withdrawalRequest?.date_requested).format('hh:mm A')}
          </Text>
          <Text px="2"> | </Text>
          <WithdrawalStatus
            status={withdrawalRequest?.withdrawal_status ?? undefined}
          >
            <FormattedMessage
              id={`account.withdraw.requests.status.${withdrawalRequest?.withdrawal_status?.toLowerCase()}`}
            />
          </WithdrawalStatus>
        </Flex>
      </WithdrawalCardDetailRequested>
    </WithdrawalCardContent>
  ),
  cancelButton: (
    <WithdrawalFlexContainer>
      {withdrawalRequest?.withdrawal_status === EWithdrawalStatus.Pending &&
        !withdrawalRequest.withdrawal_cancel_disabled && (
          <ButtonWithdrawalCardCancel
            px="5"
            py="2"
            fontSize="sm"
            w="full"
            onClick={() =>
              handleCancelWithdrawal(withdrawalRequest?.withdrawal_id)
            }
          >
            <FormattedMessage id="account.withdraw.requests.cancelbutton" />
          </ButtonWithdrawalCardCancel>
        )}
    </WithdrawalFlexContainer>
  ),
});

export default template;
