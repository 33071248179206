import React from 'react';
import { Helmet } from 'react-helmet-async';

const { REACT_APP_SITE_TITLE } = window.BETCLOUD_ENV;

type TSeoTitle = {
  title: string;
};

export default function SeoTitle({ title }: TSeoTitle) {
  return (
    <Helmet>
      <title>
        {REACT_APP_SITE_TITLE} {title}
      </title>
    </Helmet>
  );
}
