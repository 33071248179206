import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: selected ? 'gamma.600' : 'blackAlpha.500',
  border: selected ? '1px solid #182846' : 'unset',
  mt: '1',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  // no tokens
  bgGradient: 'linear(to-b, #D8B77B, #B1884E)',
  borderLeftRadius: index === 0 ? '2em' : '0',
  pl: index === 0 ? '0.5' : '0',
  borderRightRadius: index === numberOfSlides - 1 ? '2em' : '0',
  pr: index === numberOfSlides - 1 ? '0.5' : '0',
});
