import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    bg: 'alpha.200',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 'lg',
    mt: '3',
  },
  body: {
    color: 'gamma.200',
    px: '4',
    pt: '3',
    pb: '0',
    fontSize: 'xs',
  },
};
