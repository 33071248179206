import { css } from 'styled-components';

export const modalPageContainer = css`
  background: var(--bc-colors-blackAlpha-700);
`;

// TODO: Remove
export const headerContainer = css`
  padding: 20px 0 20px 25px;
  position: relative;
`;

// TODO: Remove
export const headerText = css`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  color: ${({ theme }) => theme.palette.secondary};
  font-size: var(--bc-fontSizes-md);
  font-weight: 600;
`;

export const contentContainer = css`
  padding: 0 20px 20px 20px;
`;

export const closeIcon = css`
  height: 12px;
  width: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;
