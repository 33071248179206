import React, { FC, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { getStrings } from '@/helpers/utils';

import MyBetsToggle from '../../../components/MyBetsToggle/MyBetsToggle';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { useMyBets } from './services/MyBets.hooks';
import { EBetState, TMyBetsRecords } from '@/api/punter/punter.types';
import MyBetsCard from './Components/MyBetsCard/MyBetsCard';
import Loading from './Components/Loading';
import NoResults from './Components/NoResults';
import Error from './Components/Error';
import {
  ButtonShowMore,
  FlexBetCard,
  myBetsStyles,
} from './styles/MyBets.styles';
import CashOutModal from './Components/MyBetsCard/components/CashOutModal/CashOutModal';
import { TConfirmedBet } from '@/lib/DBModels';

const MyBets: FC = () => {
  const {
    data,
    isLoading,
    error,
    filter,
    setFilter,
    isFetching,
    fetchNextPage,
    hasNextPage,
    hasMoreData,
    setCashoutOnly,
  } = useMyBets();

  const [
    {
      Account: { MyBets: Strings },
    },
  ] = getStrings();

  const [modalState, setModalState] = useState<{
    bet: TConfirmedBet | undefined;
    isOpen: boolean;
  }>({ bet: undefined, isOpen: false });

  const handleModalClose = () => {
    setModalState({ bet: undefined, isOpen: false });
  };

  return (
    <AccountWrapper pageTitle={Strings.PageTitle} pageHeader="My Bets">
      <CashOutModal
        bet={modalState.bet ?? undefined}
        isOpen={modalState.isOpen}
        onClose={handleModalClose}
      />

      <Box sx={{ margin: '0 auto', width: '100%' }}>
        {/* Toggle */}
        <MyBetsToggle
          onChange={(betView) => setFilter(betView)}
          setCashoutOnly={(e) => setCashoutOnly(e)}
          selectedOption={filter}
        />
        {/* Content */}
        <Box data-cy="myBetsTable" sx={{ py: '2' }}>
          <FlexBetCard>
            {(() => {
              if (error) return <Error />;

              if (isLoading) return <Loading />;

              if (!data?.pages?.length) {
                return <NoResults filterName={filter.display} />;
              }

              /**
               * FIXME:
               * Remove the "as" type on bet. I'm working with
               * existing functionality so need to force a "partial" type
               * to a "required" type.
               */
              return data.pages.map((r) =>
                r?.map((l) => (
                  <MyBetsCard
                    bet={l as TMyBetsRecords}
                    key={l?.bet_id}
                    isResulted={filter.value.includes(EBetState.Settled)}
                    onCashoutClick={() => {
                      setModalState({ bet: l as TMyBetsRecords, isOpen: true });
                    }}
                  />
                ))
              );
            })()}
          </FlexBetCard>
        </Box>
        {/* Load More Infinite */}
        <Flex
          role="navigation"
          aria-label="pagination"
          sx={{
            py: '4',
            flexWrap: 'wrap',
            gap: '1',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {(() => {
            if (hasMoreData !== 0)
              return (
                <ButtonShowMore
                  data-cy="showMoreButton"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetching}
                  {...myBetsStyles.buttonShowMoreProps}
                >
                  {isFetching ? Strings.Loading : Strings.ShowMore}
                </ButtonShowMore>
              );

            if (hasMoreData === 0 && data?.pages?.length)
              return <NoResults filterName={filter.display} />;

            return null;
          })()}
        </Flex>
      </Box>
    </AccountWrapper>
  );
};

export default MyBets;
