import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/betbetbet/images/background/bg.png';
import topMb from '@/assets/betbetbet/images/background/bgMb.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), linear-gradient(180deg, #0F1826 45%, #0D151F 100%)`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          '@media (max-width: 767px)': {
            background: `url(${topMb}), linear-gradient(180deg, #0F1826 45%, #0D151F 100%)`,
            backgroundPosition: 'top, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundAttachment: 'local',
          },
        },
      }}
    />
  );
}
