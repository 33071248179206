import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    pt: '3',
    pb: ['8', null, '4'],
  },
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: '2',
    my: [null, null, '2'],
  },
  textTitle: {
    my: '0',
    mb: IS_MOBILE_APP ? '0' : '2',
    color: 'gamma.500',
    fontSize: '2xl',
    fontWeight: 'medium',
    fontFamily: 'Damion',
  },
  accountHeaderContainer: {
    h: 'auto',
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      borderRadius: 'lg',
      bg: 'alpha.700',
      borderWidth: '3px',
      borderColor: 'beta.500',
      '&&': {
        p: '3',
        pt: '0',
        flexDir: 'column',
      },
    },
  },
  titleContainer: {
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
