import { CSSObject } from '@chakra-ui/react';

export const IncreaseAmountWrapper: CSSObject = {
  mb: '0',
  pb: '1',
  gap: ['1', '3'],

  '.chakra-button': {
    bg: 'beta.500',
    color: 'beta.700',
  },
};

export const IncreaseButton: CSSObject = {
  bg: 'gamma.500',

  _hover: {
    opacity: '0.6',
  },

  _active: {
    bg: 'gamma.700',
  },

  '& + &': {
    ml: '1',
  },
};
