import { chakra, Flex, Image, Text } from '@chakra-ui/react';
import { styleImports } from './NoRaces.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const NoRacesWrapper = chakra(Flex, {
  baseStyle: () => ({
    label: 'noRaces-NoRacesWrapper',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minH: '60vh',
    color: 'black',
    px: ['40px', 'initial'],
    ...(() => {
      try {
        return styleImports[themeName]?.NoRacesWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ImageNoRaces = chakra(Image, {
  baseStyle: () => ({
    label: 'noRaces-ImageNoRaces',
    width: '50%',
    mb: '6',
    ...(() => {
      try {
        return styleImports[themeName]?.ImageNoRaces;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextPrimary = chakra(Text, {
  baseStyle: () => ({
    label: 'noRaces-TextPrimary',
    color: 'black',
    fontWeight: 'bold',
    mb: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.TextPrimary;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextSecondary = chakra(Text, {
  baseStyle: () => ({
    label: 'noRaces-TextSecondary',
    color: 'black',
    ...(() => {
      try {
        return styleImports[themeName]?.TextSecondary;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
