import { CSSObject } from '@chakra-ui/react';
import fadeBgCrushed from '@/assets/templebet/images/feature/FeaturedBgCrushed.png';

export const FlexMiscWrapper: CSSObject = {
  pt: '2',
  pb: '0',
};

export const FlexWrapper: CSSObject = {
  h: ['280px', 'fit-content'],
  bg: `linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%),url(${fadeBgCrushed})`,
  backgroundBlendMode: 'soft-light',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  borderRadius: 'lg',
  mb: ['2', null, '0'],
  mt: '25px',
  mx: '0',
  border: '4px solid',
  borderColor: '#FFF1CB',
  py: ['0'],
  pl: ['0'],
  flexDir: ['column', 'row'],
  boxShadow:
    '0px 0px 0px 3px var(--bc-colors-beta-300) inset, 0px 0px 0px 6px #FFF1CB inset, 0px 5px 0px 0px var(--bc-colors-beta-300)',
  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
  },
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  pt: '3',
  pb: '0',
};
export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};
export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};
export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'delta.500',
  fontSize: 'sm',
  fontWeight: '900',
  borderRadius: 'lg',
  border: '2px solid',
  borderColor: 'delta.500',
  fontFamily: 'body',
  boxShadow: 'unset',
  lineHeight: '19.2px',
};
export const TextMisc: CSSObject = {
  bg: 'beta.100',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 5px var(--bc-colors-beta-200) inset',
  borderRadius: 'lg',
  color: 'beta.800',
  textShadow: 'none',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  borderBottom: 'none',
  display: 'flex',
  alignItems: 'center',
  span: {
    display: 'inline-block',
    bgColor: 'blackAlpha.300',
    color: 'transparent',
    fontSize: '0',
    mx: '1',
    h: '3.5',
    w: '1px',
  },
};
export const TextTeam: CSSObject = {
  color: 'beta.800',
  fontSize: 'xl',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  fontFamily: 'heading',
  textShadow: 'none',
  overflow: 'visible',
};
export const BoxHeadingWrapper: CSSObject = {
  fontSize: '3xl',
  fontWeight: 'normal',
  textShadow: '0px 4px 6px var(--bc-colors-blackAlpha-400)',
  color: 'alpha.600',
};
export const puntButtonProps: CSSObject = {
  variant: 'secondary',
  bgGradient: 'linear(to-b, beta.700, beta.800)',
  color: 'beta.500',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-20)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'accent',
  borderRadius: 'lg',
  letterSpacing: 'wide',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  my: '2.5',
  pl: '2.5',
  pr: '2.5',
  pb: '0',
  _hover: {
    bgGradient: 'linear(to-b, delta.400, delta.500)',
    color: 'white',
  },
  _focus: {
    bgGradient: 'linear(to-b, beta.900, beta.900)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  _disabled: {
    bg: 'gray.400',
    borderColor: 'gray.600',
    color: 'gray.200',
    textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  pt: '0',
};
export const HeadingSlogan: CSSObject = {
  fontFamily: 'accent',
  color: 'beta.800',
  textTransform: 'uppercase',
  alignItems: 'center',
  fontSize: ['3xl', null, '3xl'],
};
