import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonLogInProps: ButtonProps = {
  height: '48px',
};

export const buttonSignUpProps: CustomButtonProps = {
  height: '48px',
};

export const ButtonOutsideArea = {
  boxShadow: 'none',
};

export const FlexContainer = () => ({
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },

  w: ['100%', null, 'unset'],
  h: ['auto', null, null, null],
  pt: ['4', null, null, '4', 'unset'],
  pb: '2',
  px: '2',
});

export const ButtonGroup: CSSObject = {
  pb: ['0', null, '4'],
  pos: 'relative',
  gap: '1',
};

export const ButtonGroupBets: CSSObject = {
  gap: '1',
};

export const buttonBetSlipProps: ButtonProps = {
  variant: 'solid',
  boxSize: '12',
  lineHeight: 'normal',
  transition: 'all .3s ease-in-out',
  sx: {
    '&&': {
      ml: 0,
    },

    '.btn-span': {
      flexDir: 'column',
      gap: 0,
      alignSelf: 'center',
      lineHeight: 1,

      p: {
        fontSize: '15px',

        _first: {
          fontFamily: 'body',
          fontWeight: 'bold',
          fontSize: '10px',
        },
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  variant: 'solid',
  boxSize: '12',
  lineHeight: 'normal',
  sx: {
    '.btn-span': {
      flexDir: 'column',
      gap: 0,
      alignSelf: 'center',
      lineHeight: 1,
    },
    '.span-bet': {
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: '10px',
    },

    '.span-bet-count': {
      fontSize: '15px',
    },
  },
};

export const buttonQuickAccessProps: ButtonProps = {
  h: '48px',
  pl: '3',
  pr: ['1', '3'],
  lineHeight: 'unset',
  sx: {
    '.span-balance': {
      color: 'gamma.400',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
      fontSize: 'sm',
    },

    '.span-bonus': {
      fontSize: 'xs',
      fontWeight: 'bold',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',

      svg: {
        pos: 'relative',
        bottom: '-1px',
      },
    },
    '&[aria-expanded="true"]': {
      bg: 'beta.600',
      boxShadow:
        '0px 0px 0px 2px var(--bc-colors-alpha-200) inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EB9245 inset',
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'black',
  boxSize: 'auto',
  ml: '-1.5',

  '& svg': {
    boxSize: '7',
    color: 'white',
  },
});

export const LinkLogo: CSSObject = {
  w: ['auto', null, '160px'],
  display: ['block', null, null, 'none'],
  ml: '-3',
};

export const FlexButtonGroup: CSSObject = {
  pb: ['0', null, '2'],
  pos: 'relative',
};

export const TextButtonNumber: CSSObject = {
  fontSize: ['xs', null, 'md'],
};
