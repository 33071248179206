import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import Card from '../Card';
import {
  FlexFeatureWrapper,
  FlexFeatureTitleWrapper,
  FlexFeatureCardWrapper,
} from '../styles/Featured.styles';
import baseTemplate from './template.default';
import { FeaturedBanner } from './puntgenie/FeaturedBanner/FeaturedBanner';

const template: TTemplate = {
  wrapper: <FlexFeatureWrapper />,
  feature: (
    <FlexFeatureTitleWrapper>
      <FeaturedBanner />
    </FlexFeatureTitleWrapper>
  ),
  card: (
    <FlexFeatureCardWrapper>
      <Card />
    </FlexFeatureCardWrapper>
  ),

  ...(baseTemplate?.first as TTemplate),
};

export default template;
