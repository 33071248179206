/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Box } from '@chakra-ui/react';
import Races from '../races/races';
import Sports from '../sports/Sports';
import Toggle from '../components/Toggle/Toggle';
import HomeBanner from '../../../components/HomeBanner/HomeBanner';

import { stylesNew } from '../styles/Home.styles';

export const ThemeHome = [
  { name: 'wrapper', component: <Box {...stylesNew.wrapper} /> },
  { name: 'banner', component: <HomeBanner /> },
  { name: 'toggle', component: <Toggle /> },
  { name: 'races', component: <Races /> },
  { name: 'sports', component: <Sports /> },
];

export default ThemeHome;
