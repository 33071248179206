import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {},
  },
  iconButtonScrollRight: {
    fontSize: 'sm',
    p: '1',
    border: 'none',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
    color: 'white',
    bgGradient: 'linear(to-b, #7784D1, #485191)',
    borderRadius: '0px 3px 3px 0px',
    minW: 4,
    maxW: 4,
    h: '100%',
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {},
  },
  iconButtonScrollLeft: {
    fontSize: 'sm',
    p: '1',
    border: 'none',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
    color: 'white',
    bgGradient: 'linear(to-b, #7784D1, #485191)',
    borderRadius: '3px 0px 0px 3px',
    minW: 4,
    maxW: 4,
    h: '100%',
  },
  flexWrapper: {
    bg: 'blackAlpha.500',
    borderRadius: 'md',
    boxShadow: '0 1px rgba(255, 255, 255, .05)',
    pos: 'relative',
    minH: '34px',
  },
  buttonGroupSports: {
    display: 'inline-flex',
    borderRadius: 'md',
    bg: 'beta.500',
    flex: '1',
    overflowX: 'auto',
    p: '1',
    spacing: '1',
    whiteSpace: 'nowrap',
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    px: '1',
    pos: 'relative',
    spacing: '1',
    ml: 'auto',
    borderRadius: 'md',
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: '#F5CB23',
    pos: 'absolute',
    bottom: 'px',
    transition: 'all 0.2s ease',
  },
};
