/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  EBetSlipViewMode,
  TBetSlipBet,
  TBetSlipBetMulti,
} from '../../Services/Betslip.types';
import { useBetRequests } from '../../Services/Betslip.hooks';
import { useClaimTokenOnApproval } from './BetCard.hooks';
import { BetslipRefSchema } from '../Modal/ModalWeb';
import useEffectOnce from '@/hooks/useOnce';
import { Card } from '../Card/Card';
import { TPunterBetRequest } from '@/api/punter/punter.types';
import { checkMultiValidity } from '../MultiCard/Services/MultiBet.utils';
import EmptyMulti from '../MultiCard/EmptyMulti';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

type BetCardProps = {
  bet: TBetSlipBet;
  betslipRef: BetslipRefSchema;
  viewMode: EBetSlipViewMode;
  betRequest?: TPunterBetRequest;
};

// Container ---
export default function BetCardContainer(props: BetCardProps) {
  const { bet, betslipRef } = props;
  const { betRequest } = useBetCard({ bet, betslipRef });
  return <BetCardView {...props} betRequest={betRequest} />;
}

// View ---
const BetCardView = ({
  bet,
  betslipRef,
  viewMode,
  betRequest,
}: BetCardProps) => {
  const isMulti = bet.type === 'Multi';

  /** This is only triggered when the legacy BS is active */
  if (isMulti && !FEATURE_FLAGS.HAS_NEW_BS) {
    const _bet = bet as TBetSlipBetMulti;
    const validMulti = checkMultiValidity(_bet);
    if (!validMulti) return <EmptyMulti bet={_bet} />;
  }

  return !isMulti ? (
    <Card data={{ bet, betslipRef, viewMode, betRequest }}>
      <Card.Error />
      <Card.PropClosed />
      <Card.RoundedBanner />

      <Card.Content>
        <Card.Header />

        <Card.InfoExotics />
        <Card.SRMSelections />
        <Card.BlendedSelection />

        <Card.Stake />

        <Card.BetRecovery />

        <Card.DefaultStake />

        <Card.EstWrapper>
          <Card.BonusBet />
          <Card.Footer />
        </Card.EstWrapper>

        <Card.FooterMysteryBet />

        <Card.BetApproval />
        <Card.OddsIncreasedBanner />
        <Card.FooterExotic />
        <Card.SMGInfo />
      </Card.Content>

      <Card.Placed />
    </Card>
  ) : (
    <Card data={{ bet, betslipRef, viewMode, betRequest }}>
      <Card.Error />

      <Card.Content>
        <Card.HeaderMulti />
        <Card.Stake />
        <Card.EstWrapper>
          <Card.BonusBet />
          <Card.Footer />
        </Card.EstWrapper>
      </Card.Content>

      <Card.InfoMulti />
      <Card.BetApproval />
      <Card.OddsIncreasedBanner />
      <Card.Placed />
    </Card>
  );
};

// Controller ---
const useBetCard = ({
  bet,
  betslipRef,
}: Pick<BetCardProps, 'bet' | 'betslipRef'>) => {
  const { data } = useBetRequests();
  const betRequest = data?.records?.find(
    (req) => req?.request_id === bet.request_id
  );
  useClaimTokenOnApproval(betRequest);

  /**
   * Focus on the first bet if we're not already
   */
  useEffectOnce(() => {
    const handleBetRef = () => {
      if (betslipRef) {
        const betWrapper = betslipRef.current.betsGroupRef.current;
        const inputs =
          betWrapper?.querySelectorAll<HTMLInputElement>('[data-input]');
        const bets: string[] = [];
        inputs?.forEach((inp) => bets.push(inp.id));

        const focusedId = betslipRef.current.betsRef.find(
          (bt) => bt.isFocused
        )?.id;

        if (
          (focusedId && !bets.includes(focusedId)) ||
          focusedId === undefined
        ) {
          inputs?.[0]?.focus();
        }
      }
    };
    handleBetRef();

    return () => {
      handleBetRef();
    };
  });

  return {
    betRequest,
  };
};
