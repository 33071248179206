import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { keys } from '@/api/api.keys';
import { TMysteryBetParams, TMysteryBetResponse } from './mysteryBet.types';
import { queryMysteryBet } from './mysteryBet';
import { useAuth } from '@/hooks/useAuth';

type TQueryMysteryBet = {
  key?: string[];
  options?: UseQueryOptions<TMysteryBetResponse, string[]>;
  params: TMysteryBetParams;
};

export const useQueryMysteryBet = (hook: TQueryMysteryBet) => {
  const { key = [], options = {}, params } = hook;
  const { isAuthenticated } = useAuth();

  const query = useQuery<TMysteryBetResponse, string[]>(
    [keys.punterAccount, ...key, params, !!isAuthenticated],
    () => queryMysteryBet(params),
    {
      enabled: !!isAuthenticated,
      retry: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

  return query;
};
