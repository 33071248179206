import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    w: '100%',
  },
  buttonCashOutDesc: {
    w: '100%',
    variant: 'secondary',
    fontWeight: 'normal',
    fontSize: 'sm',
  },
  buttonCashOutAmount: {
    variant: 'primary',
    height: '29px',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    _hover: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
      color: 'beta.900',
    },
  },
  flexOddsBoostBanner: {
    bg: 'beta.400',
    color: 'white',
    boxShadow:
      '0px 0px 0px 1px rgba(2, 107, 68, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.1) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  textOddsBoost: { color: 'white', fontFamily: 'accentStaat' },
  iconOddsBoost: {
    fill: 'white',
  },
  vstackBetsWrapper: {
    w: '100%',
    ml: 'inherit',
  },
  buttonGroupWrapper: {
    w: '100%',
  },
  iconCashOut: {
    fill: 'currentColor',
  },
  cashoutText: {
    lineHeight: 'normal',
    color: 'currentColor',
    pt: '0.5',
  },
  cashoutValue: {
    lineHeight: 'normal',
    pt: '0.5',
    color: 'gamma.600',
  },
};
export const LinkVenueName: CSSObject = {
  color: 'gamma.800',
  fontSize: 'xs',
  fontWeight: 'medium',
  ml: '26px',
};
export const RaceTime: CSSObject = {
  color: 'gamma.800',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
  ml: '26px',
};
export const BonusBetCheckMark: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const BonusBetText: CSSObject = {
  color: 'gamma.200',
  fontSize: 'xs',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'accentStaat',
  bg: 'blackAlpha.500',
  borderRadius: 'md',
  boxShadow:
    '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  px: '2',
  py: '1',
  lineHeight: 'normal',
};
export const BetReturn: CSSObject = {
  bg: 'beta.400',
  boxShadow:
    '0px 0px 0px 1px rgba(2, 107, 68, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.1) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'white',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
};
export const BoxBetReturnValue: CSSObject = {
  py: '0.5',
  p: '1',
  fontWeight: 'extrabold',
};
export const BoxBetReturnText: CSSObject = {
  my: '1',
  px: '1',
  borderRight: '1px solid',
  borderRightColor: 'white',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  border: '2px solid',
  borderColor: 'gamma.800',
  bg: 'gamma.800',
  color: 'orange.500',
};
