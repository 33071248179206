import { CSSObject, TextProps } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const titleProps: TextProps = {
  display: 'flex',
  justifyContent: ['space-between', null, 'flex-start'],
};

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
};

export const ButtonSubmit: CSSObject = {
  bg: 'beta.600',
  border: '2px solid',
  borderColor: 'beta.400',
  color: 'blackAlpha.600',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'base',
  fontFamily: 'Caveman',
  fontWeight: 'normal',
  letterSpacing: 'wide',
  fontSize: 'sm',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  _hover: {
    bg: 'beta.700',
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    h: '9',
  },
  '.chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'xs',
  },
  '.chakra-input': {
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'gray.50',
    borderColor: 'gray.300',
    _hover: {
      bg: 'gray.100',
    },
  },
};
