import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  heading: {
    mb: '2',
    color: 'white',
    fontWeight: '700',
  },
  promotionWrapper: {
    p: '4',
    bg: 'alpha.500',
    my: '3',
    boxShadow: 'lg',
    borderRadius: 'md',
  },
  flexWrapper: { gap: '4', pt: '4', justifyContent: 'space-between' },
  termsAndConditions: { color: 'white', fontSize: 'sm', pt: '1' },
};
