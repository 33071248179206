import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  fontSize: 'sm',
  mb: '1',
};

export const DividerWithdrawal: CSSObject = {
  borderBottom: '1px solid',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.400',
  borderStyle: 'dashed',
};
export const TextDescription: CSSObject = {
  fontSize: 'xs',
};
export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  lineHeight: 'normal',
  w: 'full',
  mb: 1,
};
export const ButtonWithdrawal: CSSObject = {
  variant: 'secondary',
  fontWeight: 'normal',
};
export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};

export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__helper-text': {
    bg: 'orange.300',
    color: 'orange.800',
    borderRadius: 'base',
    py: '1',
    px: '2',
    fontSize: '2xs',
    fontWeight: 'semibold',
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  '&&': {
    '.chakra-form__label': {
      color: 'gamma.200',
    },
    '.chakra-input__group': {
      borderRadius: 'base',
      border: 'none',
      boxShadow: 'none',
      pt: '0.5',
      pb: '0.5',
      pr: '0.5',
    },
    '.chakra-input__left-addon': {
      bg: ' transparent',
      h: '10',
      border: 'none',
      fontWeight: 'bold',
      boxShadow: 'none',
    },
    '#amount-input': {
      bg: 'gamma.800',
      border: 'none',
      borderLeftRadius: 'none',
      borderRightRadius: 'base',
      '&:hover': {
        bg: 'gamma.900',
        border: 'none',
      },
      '&:focus': {
        bg: 'alpha.700',
        border: 'none',
      },
    },
  },
};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.400',
};
export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'alpha.200',
    border: '3px solid',
    borderColor: 'white',
    _hover: {
      bg: 'alpha.200',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
};
export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'sm',
};
export const TextBankAccountDetail: CSSObject = {
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};
/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'alpha.700',
  borderRadius: 'lg',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  mt: '3',
  px: '2',
  py: '0',
  w: '100%',
};
export const ListContainerHeading: CSSObject = {
  fontFamily: 'accentMusashi',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  fontSize: 'lg',
  mt: '3',
  '&&': {
    fontWeight: 'normal',
  },
};
/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
};
export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'gamma.500',
  color: status === EWithdrawalStatus.Pending && 'blackAlpha.700',
  textTransform: 'uppercase',
  fontSize: '2xs',
  lineHeight: 'normal',
});
export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};
export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '2',
  variant: 'solid',
  fontWeight: 'normal',
};
export const ButtonWithdrawalCardCancel: CSSObject = {
  fontWeight: 'normal',
};
export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
  color: 'gamma.200',
  '.chakra-form__label': {
    color: 'gamma.200',
  },
};
export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  p: '2',
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px solid',
};
export const WithdrawalCardDetailRequested: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'blackAlpha.500',
};
export const InputWithdrawal: CSSObject = {
  '&&&&': {
    border: 'none',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    color: 'gamma.200',
    '&:hover': {
      bg: 'blackAlpha.500',
    },
    '&:focus': {
      bg: 'blackAlpha.500',
    },
  },
};
