import { EThemesOG } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import LoginPageLegacy from './legacy/Login';
import Login from './Login';

const themeName = getThemeName();

/**
 * Don't add to this list, use the Login import above
 */
const legacyBuildBookies = [
  EThemesOG.Wellbet,
  EThemesOG.Betgalaxy,
  EThemesOG.Questbet,
  EThemesOG.Betroyale,
  EThemesOG.Volcanobet,
  EThemesOG.Fiestabet,
  EThemesOG.Puntgenie,
  EThemesOG.Betprofessor,
];

export default legacyBuildBookies.includes(themeName) ? LoginPageLegacy : Login;
