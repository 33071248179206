import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    fontFamily: 'roboto',
    fontSize: '2xs',
    height: 'auto',
    lineHeight: 'inherit',
    px: '6',
    py: '1',
    _active: {
      bg: 'beta.600',
      borderColor: 'transparent',
      boxShadow: 'inset 0 3px 3px 3px rgba(0, 0, 0, .3)',
      color: '#cc952b',
      textShadow: 'none',
      _after: { opacity: '0' },
    },
    sx: { '&:active, &[data-active:"true"]': {} },
  },
};
