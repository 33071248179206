import { chakra, ChakraComponent, Flex, FlexProps } from '@chakra-ui/react';
import { styleImports } from './MultiCardInput.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const OddsText = chakra(Flex, {
  label: 'multiCardInput-OddsText',
  baseStyle: () => ({
    fontSize: 'sm',
    alignItems: 'center',
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.OddsText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const OddsMultiCard = chakra(Flex, {
  label: 'multiCardInput-OddsMultiCard',
  flex: '0 0 auto',

  baseStyle: () => ({
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.OddsMultiCard;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const MultiCardContainer = chakra(Flex, {
  label: 'multiCardInput-MultiCardContainer',
  shouldForwardProp: (prop) => !['eventClosed'].includes(prop),
  baseStyle: ({ eventClosed }: any) => ({
    opacity: eventClosed ? '0.3' : '1',
    p: '2',
    justifyContent: 'space-between',
    fontSize: 'sm',
    position: 'relative',
    ...(() => {
      try {
        return styleImports[themeName]?.MultiCardContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps & { eventClosed?: boolean }>;
