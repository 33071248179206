import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: 0,
  mb: 0,
  mt: 0,
  borderRadius: 'lg',
  bg: 'alpha.600',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',

  '&&&': { mb: 0 },
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
};

export const ButtonViewMore: CSSObject = {
  bg: 'beta.400',
  mr: '0',
  color: 'alpha.600',
  textAlign: 'center',
  fontSize: 'sm',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  width: 'full',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  _hover: {
    bgGradient: 'none',
    bg: 'beta.500',
  },
};
