import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';

export const GridItemFooter: CSSObject = {
  px: ['2.5', 'unset'],
  alignItems: ['center', 'flex-start'],
  alignSelf: ['stretch', 'unset'],
  mt: ['4', null, null, 'unset'],
  mb: ['0', '1'],
  mx: ['0', '2', '2', '1'],
};

export const GridLayout: CSSObject = {
  rowGap: '0',
};
export const GridSubLayout: CSSObject = {
  gridTemplateColumns: ['1fr', null, null, '270px 1fr', '270px 1fr 270px'],
  gridTemplateRows: [
    'auto auto 1fr',
    null,
    null,
    'auto auto 1fr auto',
    'auto 1fr auto',
  ],
  columnGap: ['2.5', null, null, '4'],
  gridTemplateAreas: [
    `
        "betslip"
        "sidenav"
        "content"
        "footer"
      `,
    null,
    null,
    `
        "sidenav betslip"
        "sidenav content"
        "sidenav content"
        "sidenav footer"
      `,

    `
        "sidenav content betslip"
        "sidenav content betslip"
        "sidenav footer betslip"
      `,
  ],

  'body[data-sensitive="true"] &': {
    gridTemplateRows: '',
    gridTemplateColumns: '',
    gridTemplateAreas: '',
  },

  maxW: 'container.lg',
};

export const GridItemBetslip: CSSObject = {
  position: [null, null, null, null, 'sticky'],
  top: [null, null, null, null, '0px'],
  height: [null, null, null, null, 'calc(100vh - 120px)'],
};

export const GridItemToggle: CSSObject = {
  width: [null, null, '270px'],
};

export const GridItemSideNav: CSSObject = {
  h: [null, null, null, null, 'calc(100vh - 120px)'],
  gap: '4',
};

export const GridItemContent: CSSObject = {
  mt: ['0', '0', '0', '0', '10px'],
  ...(IS_MOBILE_APP && { pb: '150px' }),
};
