import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: '2xl',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontFamily: 'Damion',
  color: 'gamma.500',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  mb: ['4px', null, '0px'],
};
