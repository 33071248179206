import { Show, Icon as IconChakra } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { ChevronRight, ChevronLeft } from '@styled-icons/boxicons-regular';
import {
  FlexWrapper,
  FlexButtonContainer,
  ButtonChevron,
  FlexBannerWrapper,
} from './styles/NextToJumpBanner.styles';
import Filters from './components/Filters/Filters';
import List from './components/List';

export default function NextToJumpBanner() {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <Show above="sm">
      <FlexWrapper>
        <FlexBannerWrapper>
          <Filters />

          <List ref={scrollRef} />

          <FlexButtonContainer>
            <ButtonChevron
              variant="unstyled"
              aria-label="scroll right"
              icon={<IconChakra as={ChevronRight} />}
              onClick={() => {
                const { scrollLeft, clientWidth } = scrollRef.current ?? {};

                scrollRef.current?.scroll({
                  left: Number(scrollLeft) + Number(clientWidth),
                  behavior: 'smooth',
                });
              }}
            />
            <ButtonChevron
              variant="unstyled"
              aria-label="scroll left"
              icon={<IconChakra as={ChevronLeft} />}
              onClick={() => {
                const { scrollLeft, clientWidth } = scrollRef.current ?? {};

                scrollRef.current?.scroll({
                  left: Number(scrollLeft) - Number(clientWidth),
                  behavior: 'smooth',
                });
              }}
            />
          </FlexButtonContainer>
        </FlexBannerWrapper>
      </FlexWrapper>
    </Show>
  );
}
