import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bg: '#7244EE',
  border: 'none',
  borderRadius: 'md',
  flex: '1',
  fontFamily: 'accent',
};
export const TypeItem: CSSObject = {
  color: 'beta.400',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ResultRowWrapper: CSSObject = {
  border: 0,
  borderRadius: 'base',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  boxShadow: '0px 1px 5px 0px #00000066 inset',
  bg: 'blackAlpha.500',
  '&:last-child': {
    mb: '2',
  },
};
export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};
export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    fontWeight: 'bold',
    color: 'beta.400',
  },
};
