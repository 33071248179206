import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';
import pattern from '../../../assets/betroyale/images/backgrounds/brSideNavBg.png';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'white',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        background: [`url(${pattern}) center top repeat`, null, null, 'none'],
        bgGradient: [
          null,
          null,
          null,
          'linear(to-b,rgba(26, 165, 255, 0.15),rgba(26, 165, 255, 0.15))',
        ],
        borderRadius: [null, null, 'md'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
        mb: [null, null, '3'],
        boxShadow:
          '0px 2px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px rgba(255, 255, 255, 0.10) inset',
        px: '0.5',
      },
      drawer: {
        ...base.drawer,
      },
      icon: {
        ...base.icon,
        boxSize: '20px',
      },
      headerSX: {
        cursor: 'default',
        color: 'beta.500',
        fontSize: 'md',
        height: '12',
        fontFamily: 'Spectral',
        fontWeight: 'extrabold',
        letterSpacing: 'wide',
        lineHeight: 'normal',
        textTransform: 'capitalize',
        '&&': {
          boxShadow: [
            'unset',
            null,
            null,
            '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
          ],
          borderBottom: ['unset', null, null, '1px solid'],
          borderBottomColor: ['transparent', null, null, 'blackAlpha.300'],
        },
        mt: ['3', null, null, '0'],
        _hover: {
          bg: 'transparent',
        },
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,
        color: 'white',
        fontWeight: 'bold',
        fontSize: 'md',
        height: '12',
        fontFamily: 'Spectral',
        px: '3',
        py: '2',
        ml: '0',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
        textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',

        borderBottomWidth: '1px',
        borderBottomColor: 'blackAlpha.300',
        transition: 'all, 0.2s linear',

        '~ .nested': {
          pl: '10',
        },

        _hover: {
          bg: 'alpha.600',
        },

        '.icon, .svgIcon': {
          transition: 'all, 0.2s linear',
          color: 'beta.500',

          path: {
            transition: 'all, 0.2s linear',
          },
        },
        '.dropDownIcon': {
          color: 'white',
        },
      },

      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        bg: 'alpha.400',
        h: '12',

        '.icon, .svgIcon': {
          fill: 'beta.500',
        },
        '.dropDownIcon': {
          color: 'white',
          fill: 'none',
        },
        _hover: {
          bgGradient: ['none', null, 'linear(to-b, alpha.500, alpha.600)'],
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
