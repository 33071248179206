import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/fiestabet/images/backgrounds/betslip-bg-box3x.png';

export const MultiCardWrapper: CSSObject = {
  backgroundImage: `url(${background})`,
  bgSize: '100% 100%',
  bgRepeat: 'repeat-y',
  boxShadow: '0px 2px 0px 0px #0C6B5A',
  borderRadius: 'base',
  p: '2',
  mt: '2',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'zeta.400',
  alignSelf: 'baseline',
  fontSize: '2xl',
  h: [null, null, '23px'],
};

export const UnableToCreateMultiWrapper: CSSObject = {
  color: 'delta.700',
  p: '2',
  gap: '1',
  borderRadius: 'base',
  border: '1px dashed',
  borderColor: 'rgba(137, 20, 29, 0.30)',
  bg: 'transparent',
  mt: '2',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};
export const InvalidityBox: CSSObject = {
  color: 'delta.700',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'medium',
};
export const OddsMultiCard: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'semibold',
};
export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  lineHeight: '4',
  flex: '1',
  textTransform: 'capitalize',
  color: 'beta.500',
  fontFamily: 'Roboto',
};
export const OddsText: CSSObject = {
  fontSize: 'md',
  color: 'white',
  fontWeight: 'extrabold',
  bg: 'blue',
};
