import React, { useState } from 'react';
import {
  Icon,
  Popover,
  PopoverTrigger,
  Show,
  Text,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDown } from '@styled-icons/fluentui-system-regular';
import { FormattedMessage } from 'react-intl';
import { useHeader } from '../../services/Header.hooks';
import {
  ButtonOutsideArea,
  buttonQuickAccessProps,
} from '../../styles/Header.styles';
import { centsToDollars } from '@/helpers/utils';
import {
  BonusBalanceIcon,
  BoxMascotImage,
  PopoverBodyQA,
  PopoverContentQA,
  PopoverFooterQA,
  PopoverHeaderQA,
} from './styles/QuickAccessMenu.styles';
import Balances from './Balances/Balances';
import Nav from './Nav/Nav';
import { GetVerified } from './GetVerified/GetVerified';
import { LogOut } from './LogOut/Logout';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { isVerified } from '../../../../../views/account/Overview/services/Overview.utils';
import { useVerifyIdentity } from '../../../../../views/account/VerifyIdentity/Services/Hooks.VerifyIdentity';
import { QuickDeposit } from './QuickDeposit/QuickDeposit';
import { Button } from '@/components/Button/Button';
import { usePunterData } from '@/store/AuthStore';

export default function QuickAccessMenuV2() {
  const ls = localStorage.getItem('show-balance');
  const initShowBalance = ls === null ? true : ls === 'true';
  const { accountBallance, accountBonusBalance } = useHeader();
  const [showBalance, setShowBalance] = useBoolean(initShowBalance);
  const [isOpen, setIsOpen] = useBoolean(false);
  const { onClose } = useDisclosure();

  const punterProfile = usePunterData();
  const { verificationStatus } = useAppSelector((state) => state.punter);

  const [enableToggle, setEnableToggle] = useState(true);

  const punterPreviouslyVerified = punterProfile?.identity_verified;
  useVerifyIdentity(false, punterPreviouslyVerified);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={isOpen ? onClose : setIsOpen.toggle}
        isLazy
      >
        <>
          <BoxMascotImage />

          <Show below="sm">
            <ButtonOutsideArea
              isHeader
              isOpen={isOpen}
              onClick={setIsOpen.off}
            />
          </Show>
          <PopoverTrigger>
            <Button
              {...buttonQuickAccessProps}
              rightIcon={<Icon as={ChevronDown} className="chevron-icon" />}
              data-cy="btn-qa-quick-access"
              onClick={enableToggle ? setIsOpen.toggle : undefined}
            >
              {showBalance ? (
                <>
                  <Text
                    as="span"
                    className="span-balance"
                    data-cy="header-account-balance"
                  >
                    {centsToDollars(accountBallance ?? 0)}
                  </Text>
                  <Text
                    as="span"
                    className="span-bonus"
                    data-cy="header-bonus-balance"
                  >
                    <BonusBalanceIcon name="presentforMark" />
                    {centsToDollars(accountBonusBalance ?? 0)}
                  </Text>
                </>
              ) : (
                <Text as="span" className="account-text">
                  <FormattedMessage id="generic.account" />
                </Text>
              )}
            </Button>
          </PopoverTrigger>

          <PopoverContentQA>
            <PopoverHeaderQA>
              {!(
                punterPreviouslyVerified || isVerified(verificationStatus)
              ) && <GetVerified onClose={setIsOpen.toggle} />}
              <QuickDeposit
                onClose={setIsOpen.toggle}
                onProcessing={() => setEnableToggle(false)}
              />
            </PopoverHeaderQA>

            <PopoverBodyQA>
              <Nav onClose={setIsOpen.toggle} />
            </PopoverBodyQA>

            <PopoverFooterQA>
              <LogOut />
              <Balances
                showBalance={showBalance}
                setShowBalance={setShowBalance}
              />
            </PopoverFooterQA>

            <Show below="sm">
              <ButtonOutsideArea isOpen={isOpen} onClick={setIsOpen.off} />
            </Show>
          </PopoverContentQA>
        </>
      </Popover>
    </>
  );
}
