import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  bgGradient: 'linear(to-b, alpha.300, alpha.900)',
  alignItems: 'center',
  borderBottom: '2px',
  borderBottomColor: 'gamma.600',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
  color: 'gamma.600',
};
