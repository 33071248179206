import { CSSObject } from '@chakra-ui/react';

export const EstReturnsLabel: CSSObject = {
  bg: 'delta.700',
  color: 'epsilon.300',
  fontWeight: 'bold',
  height: '100%',
  alignItems: 'center',
  py: '0,5',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  color: 'epsilon.100',
  borderRadius: 'base',
  w: 'full',
  justifyContent: 'center',
  fontSize: 'xs',
};

export const ApprovedBetContainer: CSSObject = {
  background: 'beta.200',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2);',
  borderRadius: 'sm',
  w: 'full',
  color: 'beta.600',
  justifyContent: 'center',
  fontSize: 'xs',
};

export const RejectedBetContainer: CSSObject = {
  background: 'delta.600',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2);',
  borderRadius: 'sm',
  w: 'full',
  color: 'epsilon.100',
  justifyContent: 'center',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
};

export const FooterContainer: CSSObject = {
  bg: 'epsilon.100',
  borderWidth: '1px',
  borderColor: 'delta.700',
  color: 'alpha.600',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'md',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
  color: 'delta.700',
};
