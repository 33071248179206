import { CSSObject } from '@chakra-ui/react';

export const OddsChangedContainer = (isInFooter: boolean): CSSObject => ({
  display: isInFooter ? 'none' : 'flex',
  minH: '12',
  p: '2.5',
  mb: '1',
  mx: '2',
  alignItems: 'center',
  justifyItems: 'center',
  borderRadius: 'lg',
});
