import betroyaleBg from '@/assets/betroyale/images/backgrounds/evenshot/betroyaleBg.png';
import evenShot from '@/assets/betroyale/images/backgrounds/evenshot/evenshot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    my: '4',
    fontWeight: 'bold',
    fontSize: 'xl',
  },
  flexWrapper: {
    mx: '4',
    mb: '4',
    py: '70px',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px',
    borderColor: 'beta.500',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
    color: 'white',
    flexDir: 'column',
    p: '4',
    bgImage: `url(${betroyaleBg}), linear-gradient(180deg, #007DC6 0%, #0D4C77 100%)`,
    bgSize: 'cover',
    w: 'auto',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    color: 'beta.500',
    textTransform: 'uppercase',
    fontSize: '4xl',
    fontFamily: 'Angkor',
    fontWeight: 'normal',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
  },
  addToBetslipButton: {
    variant: 'primary',
  },
  loginToView: {
    variant: 'primary',
  },
  evenShotImage: {
    h: '210px',
    w: '160px',
    mt: '6',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  oddsValueSpan: {
    color: 'beta.500',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'beta.500',
    fontSize: 'lg',
    fontWeight: 'bold',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },
  notYetAvailableText: {
    color: 'beta.500',
    fontSize: 'md',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },
};
