import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'primary',
};
export const DepositContainer: CSSObject = {
  mb: '2',
};
export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};
export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};
export const RowDivider: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.4) ',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  borderStyle: 'dashed',
};
export const FlexWrapper: CSSObject = {
  py: '3',
  '.chakra-radio': {
    mr: 'auto',
  },
};
export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};
export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};
export const BoxCardsContainer: CSSObject = {
  mb: '2',
};
export const BoxInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bg: 'alpha.200',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bg: 'alpha.200',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};
export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};
export const RadioCard: CSSObject = {
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    borderColor: 'white',
    _hover: {
      bg: 'blue.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'rgba(102, 81, 4, 1)',
};
export const bannerTextProps: TextProps = {
  fontSize: 'sm',
  color: 'alpha.700',
  mt: '0.5',
};
export const buttonDepositProps: CustomButtonProps = {
  variant: 'odds',
  fontWeight: 'normal',
};
export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'alpha.800',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
};
