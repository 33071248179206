import evenshotBG from '@/assets/sugarcastle/images/backgrounds/evenshot/evenshotBG.png';
import coin from '@/assets/sugarcastle/images/backgrounds/evenshot/coin.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    textTransform: 'capitalize',
  },
  flexWrapper: {
    mx: '0',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow:
      '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px #000 inset',
    border: '2px',
    borderColor: '#FFE6B6',
    flexDir: 'column',
    p: '4',
    // because requires bg image & gradient
    bgImage: `url(${evenshotBG}), linear-gradient(180deg, #1c396e 0%, #0b1528 100%)`,
    bgSize: 'cover',
    w: 'auto',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: '42px',
    fontFamily: 'Imperator',
    fontWeight: 'normal',
    textAlign: 'center',
    bgGradient:
      'linear(to-b, #FFE5B8 0%, #AB8B5B 47.15%, #EFD4A6 63.06%, #876433 92.55%)',
    bgClip: 'text',
    filter: 'drop-shadow(0px 3.5px 3.5px rgba(0, 0, 0, 0.25))',
  },
  addToBetslipButton: {
    variant: 'solid',
    fontFamily: 'Fraunces',
    fontSize: 'sm',
    fontWeight: 'black',
    w: '36',
    p: '4',
    boxShadow:
      '0px 0px 0px 2px #443318 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.30)',
    color: 'beta.600',
    border: '2px',
    borderColor: '#FFE6B6',
    bgGradient: 'linear(to-b, #FFE6B6, #AC8148)',
    _hover: {
      bg: '#FFE6B6',
    },
  },
  loginToView: {
    variant: 'solid',
    fontFamily: 'Fraunces',
    fontSize: 'sm',
    fontWeight: 'black',
    w: '36',
    p: '4',
    boxShadow:
      '0px 0px 0px 2px #443318 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.30)',
    color: 'beta.600',
    border: '2px',
    borderColor: '#FFE6B6',
    bgGradient: 'linear(to-b, #FFE6B6, #AC8148)',
    _hover: {
      bg: '#FFE6B6',
    },
  },
  evenShotImage: {
    boxSize: '150px',
    my: '6',
    backgroundImage: `url(${coin})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    filter:
      'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 14px 13px rgba(0, 0, 0, 0.25)) drop-shadow(0px 20px 23px rgba(0, 0, 0, 0.25))',
  },
  oddsValueSpan: {
    color: 'beta.400',
  },
  subHeadingText: {
    fontSize: 'sm',
    color: 'white',
  },
  notAvailableText: {
    color: 'beta.400',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notYetAvailableText: {
    color: 'beta.400',
    fontWeight: 'bold',
  },
};
