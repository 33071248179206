import { useQuery } from '@tanstack/react-query';
import { keys } from '../../../../api/api.keys';
import { queryPromos } from '../../../../api/punter/general/promos';
import { logError } from '@/helpers/utils';

export const usePromos = () => {
  const { data, isInitialLoading } = useQuery([keys.promos], queryPromos, {
    staleTime: 60 * 5000, // 5 minutes
    onError(err) {
      logError(err);
    },
  });

  return { promos: data?.terms_of_service, isInitialLoading };
};
