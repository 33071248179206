import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  bg: 'white',
  px: '2.5',
  mb: '0',
  borderRadius: 'base',
  flex: '1',
  border: '2px',
  borderColor: '#FFE6B6',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px #000 inset',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
  boxShadow:
    '0px 2px 2px 0px rgba(137, 173, 241, 0.60) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.50)',
  color: 'beta.300',
  fontSize: '2xs',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'gray.500',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  minW: '12',
  minH: '8',
  borderRadius: 'md',
  fontWeight: 'bold',
  fontSize: 'sm',
  bg: 'gray.300',
  borderWidth: '2px',
  borderColor: 'white',
  color: 'blackAlpha.500',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  boxShadow:
    '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
  opacity: '1',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
  color: 'gray.700',
};
