import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  borderRadius: 'md',
  contain: 'paint',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(255, 255, 255, 1) inset, 0px 0px 0px 3px rgba(200, 178, 119, 1) inset',
  mb: '3',
  border: '0',
  p: '1',

  ...(isNested && {
    mb: '0',
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  border: 0,
  p: '3',
  bg: 'beta.100',

  ...(isNested && {
    py: '0',
    px: '2',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'beta.100',
  h: '32px',
  color: 'beta.800',
  fontWeight: 'normal',
  fontSize: 'md',
  fontFamily: 'heading',
  p: '2',

  svg: {
    border: '1px',
    borderColor: 'transparent',
    color: 'beta.800',
    borderRadius: '100%',
    boxSize: '20px',
    ml: '1',
  },

  _hover: { bg: 'beta.100' },

  ...(isNested && {
    '&&&': {
      bg: 'beta.100',
      p: '2',
      color: 'beta.800',

      svg: {
        borderColor: 'transparent',
        color: 'beta.800',
      },

      _expanded: {
        color: 'beta.800',
        borderBottomRadius: 0,
      },
    },
  }),

  _expanded: {
    borderBottomRadius: 'none',
    bg: 'beta.100',
    color: 'beta.800',
    borderBottom: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.25)',

    ...(isNested && {
      color: 'beta.800',

      _hover: { bg: 'beta.100' },
    }),
  },
});

export const BoxWrapper: CSSObject = {
  py: '2',
  px: '0',
  borderBottomRadius: '6px',
};
