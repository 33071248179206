export const colors = {
  alpha: {
    100: '#41fff4',
    200: '#59cefa',
    300: '#3598bc',
    400: '#106382',
    500: '#1d4554',
    600: '#0b3b47',
    700: '#17323d',
    800: '#0f2731',
    900: '#041c22',
  },
  beta: {
    100: '#fff6e4',
    200: '#ffe8bc',
    300: '#ffd27b',
    400: '#e1aa3e',
    500: '#533c10',
    600: '#483207',
    700: '#3f2c07',
    800: '#302003',
    900: '#291c02 ',
  },
};
