import { SpecialsStylesSchema } from './Specials.styles';

export const specialsStyles: SpecialsStylesSchema = {
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'sm',
    borderTopColor: '#1d3b52',
    borderTopWidth: '1px',
    boxShadow: '0 1px #3c607c inset',
    paddingTop: 3,
    mb: 3,

    sx: {
      '+ div': {
        p: 0,
      },
    },
  },
};
