import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/sugarcastle/images/backgrounds/background@2x.png';
import backgroundMobile from '@/assets/sugarcastle/images/backgrounds/mobileBg@2x.png';

export const LocationNotAllowedContainer: CSSObject = {
  h: undefined,
  justifyContent: [null, null, 'start'],
  minH: '100dvh',
  minW: '100dvw',
  bgImage: `url(${background})`,
  bgRepeat: 'no-repeat',
  bgPosition: 'center',
  lineHeight: '4',
  bgSize: 'cover',
  '@media (max-width: 480px)': {
    bgImage: `url(${backgroundMobile})`,
    bgSize: '125% 110%',
  },

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${backgroundMobile})`,
  },
};

export const CenterWrapper: CSSObject = {
  bg: 'transparent',
  borderRadius: [null, null, 'xl'],
  color: 'white',
  flex: ['1', null, '0'],
  gap: '0',
  justifyContent: 'center',
  mt: ['0', null, '8'],
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.md',
  px: ['4', null, '28'],
  py: ['3', null, '3'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
};

export const ContentHeadingText: CSSObject = {
  fontSize: ['4xl', null, '3xl'],
  backgroundImage: `linear-gradient(#FFE5B8 0%, #876433 92.55%)`,
  backgroundClip: 'text',
  textTransform: 'uppercase',
  fontFamily: 'ImperatorSmallCaps',
  fontWeight: 'normal',
  lineHeight: '1',
  mt: ['6', null, '-100px'],
  mb: ['2.5', null, '4'],
};

export const ContentBodyText: CSSObject = {
  fontSize: ['sm', 'lg'],
  lineHeight: 'tall',
  textAlign: 'center',
  filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.5))',
  color: 'white',

  b: {
    display: 'block',
    fontSize: 'md',
    lineHeight: 'base',
    mb: '4',
  },

  '.chakra-link': {
    fontSize: ['2xs', 'xs'],
    fontWeight: 'bold',
  },
};

export const ContactText: CSSObject = {
  '&&': {
    fontSize: ['sm', null, 'lg'],
  },
};

export const TextBodyCopy: CSSObject = {
  lineHeight: '5',
  fontSize: ['sm', null, 'lg'],
  mt: '4',
};

export const TextHeadingColor: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  bgClip: 'text',
  textShadow: 'none',
  display: 'flex',
};

export const RegistrationContent: CSSObject = {
  fontSize: ['xs', null],
  w: [null, null, '591px'],
  '.chakra-link': {
    fontSize: ['xs', null],
    fontWeight: 'bold',
  },
};
export const FlexVerbiageContainer: CSSObject = {
  maxW: 'unset',
  alignItems: 'center',
  mt: ['19rem', '0'],
};
export const SubHeader: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
};
