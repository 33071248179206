import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  color: 'beta.700',
  fontSize: '2xl',
  fontFamily: 'Angkor',
  w: '495px',
  textAlign: 'center',
  fontWeight: 'normal',
  lineHeight: '100%',
  bgGradient: 'linear(180deg, gamma.500 0%, gamma.600 100%)',
  backgroundClip: 'text',
  letterSpacing: 'widest',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  textTransform: 'uppercase',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
  fontWeight: 'bold',
};

export const ImageNoRaces: CSSObject = {
  w: '203px',
};
