import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'solid',
    color: 'beta.400',
    height: '5',

    _hover: {
      '&&': {
        background: 'transparent',
        color: 'gamma.100',
      },
    },
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: '0',
  bg: 'gamma.600',
  borderTop: '2px',
  borderTopColor: 'beta.400',
};

export const buttonActionProps: CustomButtonProps = {
  variant: 'solid',
  color: 'beta.400',
  fontSize: 'xs',
  height: '5',
  ml: '0.5',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  sx: {
    svg: { boxSize: '2.5', color: 'beta.400' },
    ':first-child': {
      ml: '0',
    },
  },

  _hover: {
    '&&': {
      background: 'transparent',
    },
  },
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '100%',
  border: 'none',
  display: 'block',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.4) inset',
  bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
  color: 'white',
  textShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.3)',
  fontSize: 'sm',

  sx: { '&&&': { borderRadius: 'base' } },
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'solid',
  border: 'none',
  boxShadow: 'none',
  h: '100%',
  fontSize: 'md',
  fontFamily: 'Roboto',
  _after: { opacity: 0 },
  sx: { '&&&': { borderRadius: 'base' } },
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  fontSize: 'sm',
  boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.25)',
  textShadow: '0px 3px 4px rgba(255, 255, 255, 0.3)',
  border: 'none',
  fontFamily: 'Roboto',
  _after: { opacity: 0 },
  sx: { '&&&': { borderRadius: 'base', py: 1 } },
};

export const GridWrapper: CSSObject = {
  m: '1',
  p: '1',
  bg: 'alpha.800',
  borderRadius: 'base',
  gap: '1',
};
