import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '5',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'beta.800',
  borderRadius: 'base',
  color: 'alpha.400',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
export const LinkRaceContainer: CSSObject = {
  borderRadius: 'base',
  bgGradient: `linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))`,
  boxShadow:
    '0px 1px 5px 0px rgba(130, 151, 222, 1) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.45)',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    'p[class*="TextRace"], svg[class*="Icon"]': {
      color: 'beta.700',
    },
    'p[class*="TextRaceNumber"]': {
      color: 'alpha.400',
    },
  },
  '.chakra-badge': {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};
export const TextRaceType: CSSObject = {
  fontFamily: 'accent',
  color: 'white',
  fontSize: 'md',
  textTransform: 'capitalize',
  fontWeight: 'normal',
};
export const TextRace: CSSObject = {
  fontFamily: 'accent',
  color: 'white',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  pt: '0.5',
};
export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.400',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
