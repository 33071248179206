import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTakeBreakProps: CustomButtonProps = {
  variant: 'secondary',
  lineHeight: 'normal',
  fontFamily: 'body',
  fontWeight: 'black',
  fontSize: 'xs',
  sx: {
    '&&': {
      h: '38px',
    },
  },
};

export const ListItemExclusionItem: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const HeadingInformation: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  mb: '1',
  fontSize: 'sm',
};

export const TextInformation: CSSObject = {
  fontSize: 'xs',
  my: '1.5',

  color: 'white',
  '&:first-of-type': {
    fontWeight: 'medium',
  },
};

export const BoxStyledLink: CSSObject = {
  color: 'white',
};

export const TextCheckbox: CSSObject = {
  color: 'white',
  mb: '0',
  mt: '0.5',
};

export const TextM: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
  mb: '0',
};

export const TextS: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontSize: 'xs',
  '&:first-of-type': {
    mb: '4',
  },
};

export const BoxTakeABreak: CSSObject = {
  pb: '0',
  border: 'none',
};

export const TextDate: CSSObject = {
  fontSize: 'md',
  color: 'white',
};

export const TextDateLabel: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextConfirmBreak: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const buttonConfirmBreakProps: CustomButtonProps = {
  height: '38px',
  variant: 'secondary',
  lineHeight: 'normal',
  fontFamily: 'body',
  fontSize: 'xs',
  fontWeight: 'black',
};

export const BoxTakeABreakWrapper: CSSObject = {
  borderBottom: 'none',
  pb: '0',
};

export const TakeABreakContainer: CSSObject = {
  borderBottom: 'none',
  borderBottomColor: 'none',
  pb: '1',
  mb: '2',
};

export const InputContainer: CSSObject = {
  '& .chakra-form__label': {
    color: 'white',
  },

  '& .chakra-select__icon-wrapper': {
    color: 'black',
  },

  '& .chakra-select': {
    h: '10',
    bg: 'white',
    color: 'black',
    fontSize: 'xs',
    borderRadius: 'base',
  },

  '& .chakra-select:hover': {
    bg: 'white',
  },

  '& .chakra-select:active': {
    bg: 'white',
  },

  '& .chakra-select:focus': {
    bg: 'white',
  },

  '& .chakra-checkbox': {
    color: 'white',
  },

  '& .chakra-checkbox__control': {
    boxShadow: 'none',
  },
};
