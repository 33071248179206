import {
  headerBaseStyle,
  THeaderTheme,
} from '../../base/customComponents/header';
import { TStyleConfig } from '../../base/types';

const headerHeight = ['54', '90'];

export const Header: TStyleConfig<THeaderTheme> = {
  baseStyle: {
    ...headerBaseStyle,
    headerHeight,
    wrapper: {
      ...headerBaseStyle.wrapper,
      px: ['2', null, null, 'unset'],

      '.buttonGroup': {
        display: ['none', 'unset'],
      },
    },
    wrapperContent: {
      ...headerBaseStyle.wrapperContent,
      justifyContent: 'space-between',
    },
    accountBallance: {
      ...headerBaseStyle.accountBallance,
      fontSize: 'sm',
      fontWeight: 'semibold',
      color: 'white',
      display: 'inline-flex',
      columnGap: '1',
      alignItems: 'center',
      lineHeight: '1',
    },
    accountBallanceVerbiage: {
      ...headerBaseStyle.accountBallanceVerbiage,
      fontWeight: 'extrabold',
    },
    menuIcon: {
      ...headerBaseStyle.menuIcon,
      bg: ['gamma.100'],
      h: ['9', null, 'unset'],
      minW: ['9', null, 'unset'],
      mr: '3',
    },
    betslip: {
      ...headerBaseStyle.betslip,
      bg: ['unset', 'yellow.400'],
      p: ['1', '2'],
      flexDirection: ['column', 'row'],
      ml: [null, '2'],
      borderRadius: 'md',
    },
    betslipCount: {
      ...headerBaseStyle.betslipCount,
      fontWeight: 'semibold',
      fontSize: ['xs', 'sm'],
      boxSize: '6',
      minW: '6',
      minH: '6',
      lineHeight: '6',
      bg: ['yellow.400', 'yellow.600'],
      color: ['yellow.900', 'white'],
      borderRadius: 'full',
      mr: ['unset', '1'],
      mb: ['1', 'unset'],
    },
    betslipLabel: {
      ...headerBaseStyle.betslipLabel,
      color: ['white', 'yellow.900'],
      fontSize: ['2xs', 'xs'],
      fontWeight: ['bold', null, null, 'extrabold'],
      mx: [0, '1'],
      textTransform: 'uppercase',
    },
    logo: {},
  },
  sizes: {},
  variants: {},
};
