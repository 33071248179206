import { CSSObject, chakra, Text } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';
import { styleImports } from './Logout.styles.imports';

type TOverrideName = 'ButtonLogOut' | 'TextLogOutBtn' | 'IconWrapperLogOutBtn';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};

// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const ButtonLogOut = chakra(Button, {
  baseStyle: () => ({
    flexDir: 'row',
    color: 'white',
    gap: '1.5',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 'md',
    fontWeight: 'bold',
    width: 'fit-content',

    ...styles?.ButtonLogOut,
  }),
});

export const TextLogOutBtn = chakra(Text, {
  baseStyle: () => ({
    ...styles?.TextLogOutBtn,
  }),
});

export const IconWrapperLogOutBtn = chakra(Text, {
  baseStyle: () => ({
    mt: '1',

    ...styles?.IconWrapperLogOutBtn,
  }),
});
