import React from 'react';
import { Box, BoxProps, CSSObject, Icon, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';
import { CustomButtonProps } from '@/components/Button/Button';

export const SignUpDatePicker: CSSObject = {
  zIndex: 'popover',
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'gamma.200',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontFamily: 'accentMusashi',
  textTransform: 'uppercase',
  mb: '4',
  gap: '5px',
};

export const FormStack = (props: StackProps) => (
  <Box
    {...props}
    display={['unset', null, 'flex']}
    gap={['unset', null, '2']}
  />
);

export const SignUpInput: CSSObject = {
  borderRadius: 'base',
};

export const addressWrapperProps: BoxProps = {
  sx: {
    '& .chakra-form-control': {
      mb: '2',
    },
  },
  mb: '4',
};

export const SignupAcknowledgementButton: CSSObject = {
  color: 'gamma.600',
  fontWeight: 'bold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CustomButtonProps = {
  variant: 'ghost',
  mb: '0',
  w: 'full',
  fontWeight: 'normal',
};

export const BoxManualAddressContainer: CSSObject = {
  p: '4',
  pb: '0',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
};

export const CheckboxHelpText: CSSObject = {
  color: 'gamma.200',
  fontWeight: 'medium',
  button: {
    h: 'unset',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'green.500' : 'red.500',
  borderRadius: 'md',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
  color: 'white',
  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-green-500) !important'
      : 'var(--bc-colors-red-500) !important',
  },
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active ? 'white' : 'white',
});

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="0.5" />
);
