import { chakra, Flex, Text } from '@chakra-ui/react';
import { styleImports } from './GetVerified.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export const FlexGetVerifiedContainer = chakra(Flex, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexGetVerifiedContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextMustBeVerified = chakra(Text, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextMustBeVerified;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonGetVerified = chakra(Button, {
  label: 'ButtonGetVerified',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonGetVerified;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
