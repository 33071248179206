import { CSSObject } from '@chakra-ui/react';

export const TextCheckbox: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
};
export const buttonCloseAccountProps: CSSObject = {
  fontWeight: 'normal',
  variant: 'secondary',
};
