import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    bg: '#2C3A56',
    pt: '2',
    pb: '1.5',
    px: '2',
    mb: '0',
    minH: '9',
  },
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: '2',
    my: [null, null, '2'],
  },
  textTitle: {
    my: '0',
    color: 'beta.500',
    fontSize: 'xl',
    fontWeight: 'bold',
    fontFamily: 'KHInterference',
  },
  accountHeaderContainer: {
    sx: {
      h: 'auto',
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        bg: 'linear-gradient(180deg, #2C3A56 0%, #202A3E 100%)',
        borderTopWidth: '2px',
        borderLeftWidth: '2px',
        borderRightWidth: '2px',
        borderColor: 'beta.500',
        borderTopRadius: 'base',
        flexDir: 'column',
        color: 'white',
        '&&': {
          p: '4',
          pl: ['2', '3'],
          ...(IS_MOBILE_APP && {
            pt: '0',
          }),
        },
      },
    },
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};

export const BoxHeading: CSSObject = {
  borderTopWidth: '2px',
  borderLeftWidth: '2px',
  borderRightWidth: '2px',
  borderColor: 'beta.500',
  borderTopRadius: 'base',
  bg: '#2C3A56',
  pt: '2',
  pb: '1.5',
  px: '2',
  mb: '0',
  minH: '9',
};

export const BoxWrapper: CSSObject = {
  pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
  mx: '2',
  my: [null, null, '2'],
};

export const TextTitle: CSSObject = {
  my: '0',
  color: 'beta.500',
  fontSize: 'xl',
  fontWeight: 'bold',
  fontFamily: 'KHInterference',
};

export const AccountHeaderContainer: CSSObject = {
  h: 'auto',
  '&&': {
    px: '0',
  },
};

export const AccountInfoContainer: CSSObject = {
  borderBottomRadius: 'base',
  bg: 'linear-gradient(180deg, #2C3A56 0%, #202A3E 100%)',
  borderLeftWidth: '2px',
  borderRightWidth: '2px',
  borderBottomWidth: '2px',
  borderColor: 'beta.500',
  color: 'white',
  flexDir: 'column',
  '&&': {
    p: '4',
    pl: ['2', '3'],
    ...(IS_MOBILE_APP && {
      pt: '0',
    }),
  },
};

export const TitleContainer: CSSObject = {
  px: '1',
  pb: '2',
  ...(IS_MOBILE_APP && {
    p: '2',
    pb: '0',
  }),
};
