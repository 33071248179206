import { chakra, Flex, Icon } from '@chakra-ui/react';
import { styleImports } from './DrawerHeader.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const DrawerHeaderContainer = chakra(Flex, {
  label: 'drawerHeader-DrawerHeaderContainer',
  baseStyle: ({ theme }) => ({
    h: '14',
    alignItems: 'center',
    borderBottom: '1px',
    borderBottomColor: 'whiteAlpha.200',
    px: '2.5',
    ...(theme.themeName === EThemes.Wellbet && {
      h: '16',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      h: ['54px', null, '90px'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.DrawerHeaderContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconCircleOutline = chakra(Icon, {
  label: 'drawerHeader-IconCircleOutline',
  baseStyle: () => ({
    boxSize: '6',
    ...(() => {
      try {
        return styleImports[themeName]?.IconCircleOutline;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
