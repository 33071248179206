import { rgba } from 'polished';

export const setThemeAlpha = (colors: Record<string, string>) => {
  if (!colors?.alpha || !colors?.beta) return {};

  return {
    alphaAlpha: {
      50: rgba(colors.alpha['500'], 0.05),
      100: rgba(colors.alpha['500'], 0.1),
      200: rgba(colors.alpha['500'], 0.2),
      300: rgba(colors.alpha['500'], 0.3),
      400: rgba(colors.alpha['500'], 0.4),
      500: rgba(colors.alpha['500'], 0.5),
      600: rgba(colors.alpha['500'], 0.6),
      700: rgba(colors.alpha['500'], 0.7),
      800: rgba(colors.alpha['500'], 0.8),
      900: rgba(colors.alpha['500'], 0.9),
    },
    betaAlpha: {
      50: rgba(colors.beta['500'], 0.05),
      100: rgba(colors.beta['500'], 0.1),
      200: rgba(colors.beta['500'], 0.2),
      300: rgba(colors.beta['500'], 0.3),
      400: rgba(colors.beta['500'], 0.4),
      500: rgba(colors.beta['500'], 0.5),
      600: rgba(colors.beta['500'], 0.6),
      700: rgba(colors.beta['500'], 0.7),
      800: rgba(colors.beta['500'], 0.8),
      900: rgba(colors.beta['500'], 0.9),
    },
    gammaAlpha: {
      50: rgba(colors.gamma['500'], 0.05),
      100: rgba(colors.gamma['500'], 0.1),
      200: rgba(colors.gamma['500'], 0.2),
      300: rgba(colors.gamma['500'], 0.3),
      400: rgba(colors.gamma['500'], 0.4),
      500: rgba(colors.gamma['500'], 0.5),
      600: rgba(colors.gamma['500'], 0.6),
      700: rgba(colors.gamma['500'], 0.7),
      800: rgba(colors.gamma['500'], 0.8),
      900: rgba(colors.gamma['500'], 0.9),
    },
  };
};
