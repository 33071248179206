import React from 'react';
import Favorites from '../races/components/Favorites/Favorites';
import ThemeHome from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeHome, [
  {
    name: 'favs',
    component: <Favorites />,
    config: {
      after: 'banner',
    },
  },
]);
