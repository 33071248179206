import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};
export const BoxTakeActionTable: CSSObject = {
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
  borderRadius: 'md',
  border: '0',
  p: '3',
};
export const TextTakeAction: CSSObject = {
  color: 'beta.800',
  fontWeight: 'medium',
};
export const TakeActionHeading: CSSObject = {
  color: 'beta.800',
  fontWeight: 'bold',
};
