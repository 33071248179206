import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormHOC from '../FormHOC/FormHOC';
import Form from '../Form/Form';
import { Title, WrapperContent } from './styles/Content.styles';

export default function Content() {
  return (
    <WrapperContent>
      <Title>
        <FormattedMessage id="onboarding.login.formTitle" />
      </Title>

      <FormHOC>
        <Form />
      </FormHOC>
    </WrapperContent>
  );
}
