/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAuth } from '../../../hooks/useAuth';
import { keys } from '../../api.keys';
import { queryPunterStatements } from './statements';
import { TPunterStatement } from './statements.types';

type TQueryHook = {
  key?: any[];
  options?: UseQueryOptions<TPunterStatement[]>;
};

export const useQueryPunterStatements = ({
  key = [],
  options = {},
}: TQueryHook = {}) => {
  const { isAuthenticated } = useAuth();

  const query = useQuery(
    [keys.statements, !!isAuthenticated, ...key],
    () => queryPunterStatements(),
    {
      staleTime: 60 * 1000, // 1 minute
      enabled: !!isAuthenticated,
      ...(options as any),
    }
  );

  return query;
};
