import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  bg: 'epsilon.400',
  py: '3.5',
  mt: ['-3', null, '-5'],
  px: '4',
  top: ['0px', '9'],
  borderBottomRadius: 'base',
  position: 'unset',
};

export const ButtonFilter = ({ selected }: TSelected) => ({
  color: selected ? 'beta.600' : 'white',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'beta.600' : 'white',
  color: selected ? 'white' : 'transparent',
  boxShadow: 'none',
  mr: '0.5',
});

export const TextFilterLabel: CSSObject = {
  color: 'white',
};

export const Divider: CSSObject = {
  bg: 'blackAlpha.600',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
};
