import { css } from 'styled-components';

export const checkboxContainer = css`
  padding-top: var(--bc-sizes-4);
  border-bottom: 1px solid ${({ theme }) => `${theme.palette.primary}20`};

  ${({ theme }) => theme.device.tablet} {
    padding-top: 0;
    padding-left: 0;
    border-bottom: none;
  }
`;

export const checkboxContainerIndented = css`
  padding-left: var(--bc-sizes-8);
`;

export const checkboxLabel = css``;

export const viaLabel = css`
  text-align: right;
  padding-right: var(--bc-sizes-2);
  color: ${({ theme }) => theme.palette.white};
`;
