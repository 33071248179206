import { EFilters } from '../../../../../redux/ntjFilters.slice';

export const availableFilters = [
  {
    title: 'Next up',
    filters: [
      { label: 'Racing', name: EFilters.Racing },
      // { label: 'Sport', name: EFilters.Sport },
    ],
  },
  {
    title: 'Race types',
    filters: [
      { label: 'Thoroughbreds', name: EFilters.Thoroughbred },
      { label: 'Greyhounds', name: EFilters.Greyhounds },
      { label: 'Harness', name: EFilters.Harness },
    ],
  },
  {
    title: 'Region',
    filters: [
      { label: 'AUS/NZ', name: EFilters.Aus },
      { label: `Int'l`, name: EFilters.Intl },
    ],
  },
];
