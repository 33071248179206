import { BoxProps, TextProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './PropositionButton.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type PropositionButtonSchema = Partial<{
  buttonBase: CustomButtonProps;
  buttonBaseBox: BoxProps;
  buttonBaseSpanProps: TextProps;
  textBase: TextProps;
}>;

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBaseSpanProps: {
    'data-testid': 'propositionButton-buttonBaseSpanProps',

    ...(() => {
      try {
        return styleImports[themeName]?.propositionButtonStyles
          ?.buttonBaseSpanProps;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonBaseBox: {
    'data-testid': 'propositionButton-buttonBaseBox',

    ...(() => {
      try {
        return styleImports[themeName]?.propositionButtonStyles?.buttonBaseBox;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonBase: {
    'data-testid': 'propositionButton-buttonBase',

    ...(() => {
      try {
        return styleImports[themeName]?.propositionButtonStyles?.buttonBase;
      } catch (err) {
        return {};
      }
    })(),
  },

  textBase: {
    'data-testid': 'propositionButton-textBase',

    ...(() => {
      try {
        return styleImports[themeName]?.propositionButtonStyles?.textBase;
      } catch (err) {
        return {};
      }
    })(),
  },
};
