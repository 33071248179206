import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textTransform: 'uppercase',
  fontSize: ['2xl', '5xl'],
  fontFamily: 'Imperator',
  fontWeight: 'normal',
  textAlign: 'center',
  bgGradient:
    'linear(to-b, #FFE5B8 0%, #AB8B5B 47.15%, #EFD4A6 63.06%, #876433 92.55%)',
  bgClip: 'text',
  filter: 'drop-shadow(0px 3.5px 3.5px rgba(0, 0, 0, 0.25))',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  w: ['288px', null, '420px'],
  textAlign: 'center',
  fontSize: 'sm',
  p: {
    w: '100%',
    fontWeight: 'normal',
    fontSize: ['xs', null, 'sm'],
  },
};

export const ImageNoRaces: CSSObject = {
  w: ['200px', null, '437px'],
  mb: 0,
};
