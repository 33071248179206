import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapper: BoxProps = {};
export const GroupText: CSSObject = {
  color: 'delta.700',
  fontFamily: 'CreteRound',
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: '120%',
  fontStyle: 'normal',
};
export const GroupChevron: CSSObject = {
  color: 'epsilon.200',
  bgGradient: 'linear(180deg, delta.300, delta.600)',
  borderRadius: 'md',
  borderColor: 'delta.700',
  borderWidth: '2px',
  boxShadow: 'unset',
  transition: 'unset',
  minWidth: 'unset',
  _hover: {
    bg: 'beta.800',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    color: 'delta.600',
    background: 'delta.300',
    borderColor: 'delta.700',
    borderWidth: '2px',
    boxShadow: 'unset',
    borderRadius: 'md',
  },
  _after: {
    content: `''`,
    bgGradient: 'unset',
    boxShadow: 'unset',
    transition: 'unset',
    borderRadius: 'unset',
  },
};

export const TextBetCount: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontWeight: 'bold',
};
