import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};
export const BoxSectionWrapper: CSSObject = {
  mb: '2',
  px: ['2', 0],
};
export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
};
export const TextTitle: CSSObject = {
  color: 'beta.800',
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
};
export const LinkItem: CSSObject = {
  color: 'beta.800',
  bg: `beta.100`,
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px white inset, 0px 0px 0px 3px #C8B277 inset',
  border: 'none',
  borderRadius: 'lg',
  h: '10',
  mb: ['1', '2'],
  px: '2.5',
  _hover: {
    bg: `beta.100`,
    color: 'beta.700',
  },
};
export const IconSport: CSSObject = {
  color: 'beta.800',
  boxSize: '6',
  mr: '1',
};
export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'beta.800',
};
export const TextName: CSSObject = {
  fontFamily: 'subHeading',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  lineHeight: 'normal',
};
