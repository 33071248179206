import frameTop from '@/assets/fiestabet/images/sideNav/navFrameTop.png';
import frameBot from '@/assets/fiestabet/images/sideNav/navFrameBottom.png';

import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',
    pos: 'relative',
    px: '10px',
    _before: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      top: '-13px',
      left: '-2px',
      width: '272px',
      height: '35px',
      zIndex: '1',
      backgroundImage: ['none', null, null, `url(${frameTop})`],
      backgroundSize: ['inherit', null, null, '272px'],
      backgroundRepeat: 'no-repeat',
    },
    _after: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      bottom: '-15px',
      left: '-2px',
      width: '272px',
      height: '35px',
      backgroundImage: ['none', null, null, `url(${frameBot})`],
      backgroundSize: ['inherit', null, null, '272px'],
      backgroundRepeat: 'no-repeat',
      zIndex: '1',
    },
  },
  boxWrapperProps: {
    // There are no tokens for these
    width: '100%',
    pt: '3',
    pb: '2.5',
    mb: ['0', null, null, '9'],
    bg: ['none', null, null, '#970406'],
    boxShadow: [
      'unset',
      null,
      null,
      '2px 4px 0px 0px #710008, -2px 4px 0px 0px #B43F17',
    ],
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    pb: '1',
  },
};
