import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',

    color: 'white',
    border: '2px solid',
    borderColor: 'alpha.500',
    height: '5',

    _hover: {
      '&&': {
        background: 'transparent',
        color: 'gray.600',
      },
    },
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'alpha.900',
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '100%',
  fontSize: 'xl',
  fontFamily: 'roboto',
  fontWeight: 'extrabold',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',

  color: 'white',
  _hover: {},
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '100%',
  fontSize: 'xl',
  fontFamily: 'roboto',
  textTransform: 'uppercase',
  fontWeight: 'extrabold',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  color: 'blackAlpha.700',
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '100%',
  fontSize: 'xl',
  fontFamily: 'roboto',
  fontWeight: 'extrabold',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  color: 'blackAlpha.700',
};
