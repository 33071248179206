import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  ButtonRefresh,
  CenterWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';

import Content from '../components/Content/Content';

const template = (): TTemplate => ({
  wrapper: <div />,
  center: {
    wrapper: <MaintenanceContainer />,
    main: {
      wrapper: <CenterWrapper />,
      body: <Content />,
      extras: (
        <Flex maxW="380px" flexDir="column" alignItems="start" gap="4">
          <ButtonRefresh as={Link} to="/" variant="outline">
            Refresh
          </ButtonRefresh>
        </Flex>
      ),
    },
  },
});

export default template;
