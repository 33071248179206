import { css } from 'styled-components';

export const container = css`
  border-top: 1px solid ${({ theme }) => theme.palette.transparentBlue};
  margin-top: var(--bc-sizes-5);

  &:first-child {
    border-top: none;
    margin-top: 0;
  }
`;

export const spacer = css`
  width: var(--bc-sizes-8);
`;

export const parentContainer = css`
  padding: var(--bc-sizes-2) var(--bc-sizes-5);

  ${({ theme }) => theme.device.laptop} {
    padding: var(--bc-sizes-2) 0;
  }
`;

export const childContainer = css``;

export const icon = css`
  margin: 0 var(--bc-sizes-2) 0 var(--bc-sizes-1);
  width: var(--bc-sizes-6);
  height: var(--bc-sizes-6);
`;

export const title = css`
  font-size: var(--bc-fontSizes-md);
  font-weight: 600;
  font-style: italic;
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const titleLink = css`
  font-size: var(--bc-fontSizes-md);
  font-style: italic;
  color: white;
`;
