import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import Card from '../CardNewFeature';
import {
  FlexFeatureWrapper,
  FlexFeatureCardWrapper,
} from '../styles/Featured.styles';
import baseTemplate from './template.default';

const template: TTemplate = {
  wrapper: <FlexFeatureWrapper />,
  card: (
    <FlexFeatureCardWrapper>
      <Card />
    </FlexFeatureCardWrapper>
  ),

  ...(baseTemplate?.first as TTemplate),
};

export default template;
