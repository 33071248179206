import React from 'react';
import { Image, Link, useMediaQuery } from '@chakra-ui/react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/goldenbet888/images/backgrounds/onboardingLogo.png';
import asideMb from '@/assets/goldenbet888/images/backgrounds/onboardingAsideMb.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

function LogoContainer() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Link
      as={LinkDom}
      to="/"
      maxW={['45vw', '55vw']}
      alignSelf="center"
      mb="auto"
      mt={['4vw', '2vw']}
    >
      <Image w={isMobile ? 'full' : '200px'} src={isMobile ? asideMb : logo} />
    </Link>
  );
}

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: <LogoContainer />,
    config: {
      before: 'slogan',
    },
  },
]);
