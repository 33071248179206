import api from '../api';
import {
  TSportMatchesResponse,
  TSportMatchesParams,
} from './sportDetailsMatches.types';

export const sportMatchesEndpoint = 'punter/sports/matches';

export const querySportsMatches = (params: TSportMatchesParams) =>
  api
    .get<TSportMatchesResponse>(sportMatchesEndpoint, { params })
    .then((res) => res.data);
