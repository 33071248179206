import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { keys } from '../../../../api/api.keys';
import { useAuth } from '../../../../hooks/useAuth';

/**
 * Hook that checks if the user is authenticated and has a
 * punter account created.
 */
export const useUserData = () => {
  const [isDelayed, setIsDelayed] = useState(true);
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  /**
   * To avoid a flash of the loading components, adding a
   * delay to only show it if its been longer than 1 second.
   */
  useEffect(() => {
    const timeout = setTimeout(() => setIsDelayed(false), 1000);
    return () => clearTimeout(timeout);
  }, []);

  /**
   * When leaving private routes clear the auth cache to
   * ensure next time we access one, the data is fresh.
   */
  useEffect(
    () => () => {
      queryClient.removeQueries([keys.auth], { exact: true });
    },
    [queryClient]
  );

  return { isAuthenticated, isLoading: isDelayed };
};
