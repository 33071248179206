import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: ['column', null, 'row'],
    my: '2',
    w: 'full',
    mr: ['0', '2'],
    px: '2',
  },
  container: {
    display: 'flex',
    flexDir: 'column',
    mt: ['1', null, 'unset'],
    sx: {
      contain: 'paint',
    },
  },
  racingBtnsContainer: {
    gap: '0.5',
    w: ['full', null, '380px'],
    flexGrow: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    pl: '0',
    mr: [null, '2'],
  },
  racingIcon: {
    ml: '1',
    color: '#E48D09',
    filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.35))',
    w: '60px',
    h: '48px',
  },
  sportBtnsContainer: {
    gap: '8px',
    flexBasis: 'auto',
    flexWrap: ['unset', null, 'wrap'],
    flexDirection: 'row',
    flexGrow: 2,
    w: ['96dvw', null, 'full'],
    maxW: ['96dvw', null, 'full'],
    justifyContent: ['unset', null, 'start'],
    overflowX: ['scroll', null, 'hidden'],
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    pl: ['0', '1'],
    pb: ['unset', null, '2'],
    mt: ['2', null, 'unset'],
  },
  sportIcon: {
    ml: '2',
    w: '22px',
    h: '22px',
    color: '#E48D09',
    filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.35))',
  },
  linkIconBox: {
    maxH: '56px',
    maxW: '56px',
    borderRadius: 'xl',
    bg: 'alpha.500',

    bgGradient: 'linear(to-b, beta.600, beta.400)',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
    p: '2',
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '1',
      right: '1',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      zIndex: '3',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, beta.400, beta.600)',
      boxShadow: '0px 1px 0px 0px #ED8934',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '2',
      left: '2',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      zIndex: '4',
      borderRadius: 'base',
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
    },
  },
  linkBox: {
    h: '122px',
    w: '120px',
    borderRadius: 'xl',
    bg: 'alpha.500',

    bgGradient: 'linear(to-b, beta.600, beta.400)',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
    p: '2',
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '1',
      right: '1',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      zIndex: '3',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, beta.400, beta.600)',
      boxShadow: '0px 1px 0px 0px #ED8934',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '2',
      left: '2',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      zIndex: '4',
      borderRadius: 'base',
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
    },
  },
  linkOverlay: {
    position: 'relative',
    zIndex: '5',
    _hover: {
      background: 'alpha.800',
      boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
    },
    boxSize: 'full',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'lg',
    p: '3',
  },
  linkBoxText: {
    display: 'none',
  },
  racingHeader: {
    display: ['none', null, 'block'],
    mb: '1',
    color: 'gamma.500',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
    fontFamily: 'Bungee',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133%',
    textTransform: 'uppercase',
  },
  sportsHeader: {
    display: ['none', null, 'block'],
    ml: '2',
    mb: '1',
    flexBasis: 'auto',
    color: 'gamma.500',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
    fontFamily: 'Bungee',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133%',
    textTransform: 'uppercase',
  },
};
