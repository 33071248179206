import { CSSObject } from '@chakra-ui/react';

export const ModalOverlay = (): CSSObject => ({
  bg: 'blackAlpha.700',
});

export const ModalHeader: CSSObject = {
  color: 'gray.700',
  fontWeight: 'bold',
  px: 4,
  pb: 2,
};

export const ModalBody: CSSObject = {
  color: 'gray.600',
  fontSize: 'sm',
  pt: 1,
  px: 4,
  pb: 4,
};

export const ButtonDeposit: CSSObject = {
  mt: 3,
  bg: 'beta.600',
  border: '2px',
  borderColor: 'beta.400',

  _hover: {
    bg: 'beta.700',
  },
};
