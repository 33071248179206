import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export default function index() {
  return (
    <Text color="gray.500" fontSize="md" fontWeight="medium" mb="5">
      <FormattedMessage id="onboarding.register.createAccount" />
    </Text>
  );
}
