import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  footer: {
    pt: '0',
    bg: 'transparent',
    bgSize: 'cover',
    bgPos: 'bottom',
    color: 'white',
  },
  stackGap: '1',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
    '& > img': {
      h: '68px',
      ml: '0',
    },
  },
  footerLink: {
    color: '#fab735',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
  },
  footerLinkService: {
    color: '#fab735',
    fontSize: 'md',
    fontWeight: 'normal',
  },
  footerLinkTel: {
    color: 'white',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
  },
  footerMobile: {
    borderRadius: 'lg',
    w: '130px',
    cursor: 'pointer',
  },
  customFooterLink: {
    bottom: '1px',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontSize: 'sm',
  },
  footerLinks: {
    ml: ['unset', null, 'auto'],
    pb: '0',
    alignItems: 'center',
    justifyContent: ['space-around', null, 'unset'],
    color: 'white',
    fontWeight: 'medium',
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
    mt: ['18px', null, '0'],
    gap: '1',
  },
  footerContentContainer: {
    gap: '6',
    mb: 'unset',
    bg: [null, null, 'transparent'],
    justifyContent: 'space-between',
    sx: {
      '&': {
        flexDirection: ['column', null, 'column'],
      },
    },
  },
  nav: {
    fontSize: 'sm',
    fontWeight: 'medium',
    minW: [null, null, '370px'],
    display: ['flex', 'grid'],
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '3',
    alignItems: ['center', 'end'],
    gridTemplateAreas: `
      ". ."
    `,

    '> a': {
      color: 'white',
    },
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', 'left', null],
    color: 'white',
    width: 'unset',
    mb: '0',
    ml: [null, '0'],
    mr: [null, null, '4'],
  },
  blurbHeading: {
    ml: 'unset',
    mt: 'unset',
    color: 'whiteAlpha.700',
  },
  blurbHeadingAge: {
    color: 'white',
    borderColor: 'blackAlpha.50',
    border: '4px',
    mb: '2',
    boxSize: '8',
    fontSize: 'xs',
  },
  blurbHeadingThink: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    mb: '4',
  },
  blurbContent: {
    ml: 'unset',
    mr: [null, null, 'auto'],
    px: 'unset',
    fontSize: ['xs', 'xs', 'xs', 'sm'],
  },
  copyright: {
    color: 'white',
    textAlign: ['center', 'left'],
    mb: ['2', 'unset'],
    px: ['8', 'unset'],
    py: '0',
    fontWeight: ['semibold', 'medium'],
  },
  container: {
    bg: 'transparent',
    gap: '4',
    display: 'flex',
    flexDir: 'column',
    px: '0',
    sx: {
      '&': {
        px: '0',
      },
    },
  },
  emailUs: {
    fontSize: ['sm', 'sm', 'sm', 'md'],
    fontWeight: 'bold',
    mb: ['0', null, '5'],
  },
  quickLinks: {
    textAlign: ['center', 'left'],
    color: 'alpha.600',
    textTransform: 'uppercase',
    fontSize: ['xs', 'xs', 'xs', 'sm'],
    letterSpacing: ['2.4px', '2.8px'],
  },
  weAcceptBox: {
    mb: '0',
  },
  weAccept: {
    color: 'alpha.600',
    textTransform: 'uppercase',
    fontSize: ['xs', 'xs', 'xs', 'sm'],
    letterSpacing: ['2.4px', '2.8px'],
  },
  imageWrapper: {
    gap: '0',
  },
  partnerWrapper: {
    mr: '1',
  },
  ourPartners: {
    color: 'alpha.600',
    textTransform: 'uppercase',
    fontSize: ['xs', 'xs', 'xs', 'sm'],
    letterSpacing: ['2.4px', '2.8px'],
  },
};

// export const FooterLink: CSSObject = {
//   textTransform: 'capitalize',
//   color: 'white',
//   fontWeight: 'bold',
// };
