import { TNextToJumpRace } from './DBModels';

// Formats a venue name for a match whilst handling nulls
export const formatVenueNameNextToRace = (race: TNextToJumpRace) => {
  if (race?.venue_country === 'AUS' || race?.venue_country === 'Australia')
    return race.venue_name ?? '';

  if (!!race?.venue_name && !!race?.venue_country) {
    // Make the venue name lowercase here and apply theme specific
    // capitalisation within the styles
    return `${race?.venue_name.toLowerCase()} (${race?.venue_country})`;
  }

  return '';
};
