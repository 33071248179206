import { chakra, Text } from '@chakra-ui/react';
import { styleImports } from './CloseAccount.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const buttonCloseAccountProps: CustomButtonProps = {
  'data-testid': 'closeAccount-buttonCloseAccountProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonCloseAccountProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const TextCheckbox = chakra(Text, {
  label: 'TextCheckbox',
  baseStyle: () => ({
    color: 'black',
    ...(() => {
      try {
        return styleImports[themeName]?.TextCheckbox;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
