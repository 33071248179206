import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'rgba(78, 178, 255, 0.14)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset,0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  border: 'none',
  px: '2',
  py: '4',
  mb: '2',
  color: 'white',
  _hover: {
    bg: 'rgba(78, 178, 255, 0.34)',
    color: 'white',
  },
  _focus: {
    bg: 'rgba(78, 178, 255, 0.34)',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
