import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { TextToggle } from '../../styles/Toggle.styles';

export default function TextRacing() {
  const [searchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  return (
    <TextToggle
      data-cy="racing-toggle-text"
      isActive={!category || category === 'racing'}
    >
      <FormattedMessage id="racing" defaultMessage="Racing" />
    </TextToggle>
  );
}
