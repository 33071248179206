import React from 'react';
import { Global } from '@emotion/react';
import bgHead from '@/assets/sterlingparker/images/backgrounds/bgHead.png';
import carbonFiber from '@/assets/sterlingparker/images/backgrounds/carbonFiber.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          background: `url(${bgHead}), url(${carbonFiber})`,
          backgroundPosition: 'top, top',
          backgroundRepeat: 'no-repeat, repeat',
          backgroundSize: 'cover, 98px 98px',
          backgroundAttachment: 'fixed',

          '@media (min-width: 370px)': {
            background: `url(${bgHead}), url(${carbonFiber})`,
            backgroundPosition: 'top, top',
            backgroundRepeat: 'no-repeat, repeat',
            backgroundSize: 'cover, 98px 98px',
            backgroundAttachment: 'fixed',
          },
        },
      }}
    />
  );
}
