import { Input } from './components/input';
import { Footer } from './customComponents/footer';
import { Header } from './customComponents/header';
import { SideNav } from './customComponents/sideNav';
import { colors } from './foundations/colors';
import { fontSizes } from './foundations/fontSizes';
import { images } from './foundations/images';
import { lineHeights } from './foundations/lineHeight';
import { shadows } from './foundations/shadows';
import { sizes } from './foundations/sizes';

/**
 * Base theme for every application.
 * It's best to handle each applications theme within the application,
 * however, if there is a universal change we want, it can be added here.
 */
export default [
  {
    lineHeights,
    colors,
    fontSizes,
    images,
    shadows,
    sizes,
    components: {
      Header,
      Footer,
      SideNav,
      Input,
    },
    config: { cssVarPrefix: 'bc' },
  },
];
