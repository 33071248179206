import { CSSObject, TextProps } from '@chakra-ui/react';
import rhs from '../../../../../../assets/junglebet/images/homeFeaturedSport/rhs.png';
import lhs from '../../../../../../assets/junglebet/images/homeFeaturedSport/lhs.png';
import bgMobile from '../../../../../../assets/junglebet/images/homeFeaturedSport/backgroundMobile.png';

export const FlexWrapper: CSSObject = {
  '&:after, &:before': {
    content: `''`,
    bgPos: 'center',
    pos: 'absolute',
    zIndex: '-1',
    bgRepeat: 'no-repeat',
  },
  _after: {
    bgImage: [`unset`, `url(${lhs})`],
    left: '0.5',
    bottom: ['0', 'unset'],
    top: ['unset', '0'],
    bgSize: 'contain',
    width: ['full', '40'],
    height: ['52', '60'],
  },

  _before: {
    bgImage: ['unset', `url(${rhs})`],
    right: '5',
    bottom: '0',
    bgSize: 'contain',
    width: '40',
    height: '72',
  },

  pl: '0',
  h: ['462px', '72'],
  alignItems: ['flex-start', 'center'],
  justifyContent: 'center',
  mt: '7',
  border: 'none',
  pos: 'relative',
  p: '0',
  py: '0',
};

export const FlexMiscWrapper: CSSObject = {
  flexDirection: 'column',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: ['2', '3'],
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  color: 'white',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
};

export const puntButtonProps: CSSObject = {
  h: '10',
  cursor: 'pointer',
  zIndex: 'tooltip',
};

export const FlexFeatureCardWrapper: CSSObject = {
  zIndex: 5,
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.500',
  fontSize: 'xs',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'lg',
  color: 'delta.50',
  fontWeight: 'extrabold',
  maxW: ['90dvw', '60dvw', '50dvw', '40dvw', '500px'],
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const TextTeam: CSSObject = {
  color: 'delta.50',
  fontSize: 'md',
  textShadow: 'none',
  fontWeight: 'extrabold',
};

export const BoxHeadingWrapper: CSSObject = {
  display: 'flex',
  flexDirection: ['column', null, 'row'],
  justifyContent: 'center',
  alignItems: 'center',
  maxW: 'full',
  gap: ['0', null, '6'],
};

export const HeadingSlogan: CSSObject = {
  fontFamily: 'Grimblocks',
  color: 'white',
  textTransform: 'uppercase',
  alignItems: 'center',
  fontSize: ['70px', null, '133px'],
  bgGradient: 'linear(180deg, beta.400, beta.600)',
  backgroundClip: 'text',
  filter:
    'drop-shadow(0px 6px 0px var(--bc-colors-beta-700)) drop-shadow(0px 9px 4px rgba(0, 0, 0, 0.35))',
  lineHeight: '80%',
};

export const textSloganProps: TextProps = {
  fontFamily: 'Grimblocks',
  color: 'white',
  textTransform: 'uppercase',
  fontSize: ['91px', null, '142px'],
  bgGradient: 'linear(180deg, alpha.400, alpha.600)',
  backgroundClip: 'text',
  filter:
    'drop-shadow(0px 6px 0px var(--bc-colors-alpha-800)) drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.35))',
  lineHeight: '80%',
};

export const FlexIllustrationWrapper: CSSObject = {
  zIndex: 1,
  bgImage: [`url(${bgMobile})`, 'unset'],
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
  boxSize: '100%',
  bottom: '0',
  bgPos: 'bottom',
  pos: 'absolute',
  display: 'block',
};
