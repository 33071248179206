import { css } from 'styled-components';

export const pageContainer = css`
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
`;

export const betCardList = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--bc-sizes-4);
`;

export const noBetsText = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.body};
  text-align: center;
`;
