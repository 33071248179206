import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: 'alpha.500',

      background: {
        bg: 'var(--gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '23px',
        mr: '1',

        color: ['white', null, 'alpha.600'],
      },
      headerSX: {
        cursor: 'default',
        color: ['white', null, 'alpha.700'],
        height: '45px',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
        boxShadow: 'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,

        fontSize: 'sm',
        fontWeight: 'semibold',
        height: '54px',
        color: 'white',
        transition: 'all, 0.2s linear',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        bg: 'var(--gradient-lighten, linear-gradient(180deg, rgba(194, 223, 255, 0.10) 0%, rgba(194, 223, 255, 0.00) 100%))',
        boxShadow: 'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.12)',

        _last: {
          borderBottom: 'none',
        },

        p: '4',
        ml: '0',

        '~ .nested': {
          pl: '10',
        },

        ':hover': {
          opacity: '0.2',
        },

        ':active': {
          background: 'gamma.900',
          opacity: '1',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['4', null, '4'],
        },

        '> a, .dropDownIcon, a': {
          color: ['white', null, 'alpha.600'],
        },

        '&:hover, &:active': {
          color: 'alpha.600',

          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '54px',
            px: '4',
            color: ['white', null, 'alpha.600'],
          },

          '.svgIcon, .icon': {
            color: ['white', null, 'alpha.600'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',
        color: ['white', null, 'red.700'],

        _hover: {
          '.dropDownInner': {
            opacity: '0.2',
          },
        },

        '.dropDownInner': {
          bg: 'gamma.900',
          color: 'white',
          borderLeftWidth: '3px',
          borderColor: 'gamma.900',
        },

        '> a, .dropDownIcon, a': {
          color: 'white',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'white',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
