import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  minh: '63px',
  mb: '2',
  px: '2',
  py: '2',
};

export const ButtonGetVerified: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #491475, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  borderRadius: 'base',
  color: 'gamma.50',
  fontFamily: 'Fredoka',
  fontWeight: 'semibold',
  textShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: 'sm',
  h: '10',
  lineHeight: '6',
  ml: 'auto',
  minW: 'auto',
  px: '3',
  textTransform: 'uppercase',
  _hover: {
    bgGradient: 'linear(to-b, gamma.600, gamma.600)',
  },
  _focus: {
    bgGradient: 'linear(to-b, gamma.700, gamma.700)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #491475, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  },
};
