import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'normal',
  fontFamily: 'accent',
  color: 'gamma.300',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['2', null, '0'],
};
