import React, { FC } from 'react';
import Content from '@/components/forms/DepositLimit/DepositLimit';

import { getStrings } from '@/helpers/utils';

import OnboardingWrapper from '../../../wrappers/OnboardingWrapper';

import DepositLimitProps from './types';
import { EOnboardingPageType } from '../../../wrappers/OnboardingWrapper/OnboardingWrapper.types';
import Aside from '../../../components/Aside/Aside';
import { FlexHeaderWrapper } from './styles/DepositLimit.styles';
import ContentHeading from './conditionals/ContentHeading/template';
import StepIndicator from '@/components/StepIndicator/StepIndicator';

const DepositLimit: FC<DepositLimitProps> = () => {
  const [
    {
      Onboarding: { Step3 },
    },
  ] = getStrings();

  return (
    <OnboardingWrapper
      aside={<Aside />}
      pageTitle={Step3.PageTitle}
      pageType={EOnboardingPageType.DepositLimit}
    >
      <FlexHeaderWrapper>
        <ContentHeading />
        <StepIndicator steps={3} activeStep={2} />
      </FlexHeaderWrapper>
      <Content />
    </OnboardingWrapper>
  );
};

export default DepositLimit;
