import { CompetitionsMatchCardSchema } from './CompetitionsMatchCard.styles';

export const competitionsMatchCardStyles: CompetitionsMatchCardSchema = {
  vsBoxWrapper: {
    position: 'relative',
    p: '1',
  },
  vsDivider: {
    borderColor: '#222',
    opacity: '0.2',
  },
  absoluteCenter: {
    px: '2',
    left: '2',
    bg: '#EEF8FF',
    fontWeight: 'bold',
    color: 'alpha.600',
  },
  matchCardOverride: {
    mb: '2',
  },
  propositionButtonOverride: {
    w: '100%',
  },
  gridItem: {
    w: '100%',
  },
  flexWrapper: {
    alignItems: 'center',
  },
  flexText: {
    as: 'span',
    flex: '1',
    textAlign: 'left',
  },
  gridWrapper: {
    gap: 2,
  },
  propositionButtonOverrideSecondary: {
    w: ['50px', '100px'],
    px: ['0', null, 'inherit'],
  },
  gridItemTextNoProposition: {
    w: ['50px', '100px'],
    h: 'full',
    textAlign: 'center',
    color: 'blackAlpha.400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
  },
  flexAwayTeam: {
    alignItems: 'center',
  },
  flexAwayTeamText: {
    flex: '1',
    textAlign: 'left',
  },
};
