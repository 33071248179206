import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { pb: 1.5 },
  buttonCashOutAmount: { pb: 1.5 },
  iconRefresh: { pt: '1px' },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'gamma.500',
    border: '2px solid',
    borderColor: 'gamma.500',
  },
  iconOddsBoost: {
    fill: 'gamma.500',
  },
  textOddsBoost: {
    color: 'gamma.500',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'white',
};

export const iconLegProps: CSSObject = {
  color: 'gamma.900',
  bg: 'gamma.500',
  borderColor: 'gamma.500',
};

export const RaceTime: CSSObject = {
  color: 'rgba(255, 255, 255, 0.48)',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.500',
  color: 'alpha.700',
};

export const BonusBetText: CSSObject = {
  color: 'beta.500',
};

export const BetReturn: CSSObject = {
  color: 'beta.500',
};

export const BoxBetReturnText: CSSObject = {
  fontSize: '2xs',
  borderLeftRadius: 'base',
  borderColor: 'beta.500',
  fontWeight: 'semibold',
  borderWidth: '1px',
  bg: 'beta.800',
  color: 'beta.100',
  pb: '1',
  pt: '1.5',
  px: '1',
  mr: '1',
};

export const BoxBetReturnValue: CSSObject = {
  fontSize: '2xs',
  borderRightRadius: 'base',
  ml: '-1',
  borderColor: 'beta.500',
  borderWidth: '1px',
  bg: 'beta.500',
  color: 'beta.800',
  pb: '1',
  pt: '1.5',
  pl: '1',
  pr: '2',
};
