/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { SelectProps } from '@chakra-ui/select';
import theme from '@chakra-ui/theme';

export const Select = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      color: 'white',

      '&::placeholder': { color: 'gray.300' },

      '&:focus, &:active, &:hover': { bg: 'gray.50' },
    },
    icon: { color: 'black' },
  },
  sizes: {
    lg: { addon: { borderRadius: '4px' }, field: { borderRadius: '4px' } },
    md: {
      addon: { borderRadius: '4px', h: '38px' },
      field: { borderRadius: '4px', fontSize: 'xs', px: '2', h: '38px' },
    },
    sm: { addon: { borderRadius: '4px' }, field: { borderRadius: '4px' } },
  },
  variants: {
    outline: (props: SelectProps) => {
      const { addon, field } = theme.components.Select.variants.outline(
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          bg: 'gray.50',
        },
      };
    },
  },
};
