import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  btnGroupWrapper: {
    borderRadius: '5px',
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #FFD69E inset, 0px 0px 0px 3px #BD873E inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
    minW: '90px',
    gap: 0,
    color: 'beta.800',
  },
  btnGroupText: {
    fontSize: 'xs',
    bg: 'beta.800',
    color: 'gamma.200',
    fontWeight: 'black',
    fontFamily: 'Roboto',
    h: 'calc(100% - 4px)',
    lineHeight: '36px',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    w: '35px',
  },
  btnGroupIcon: {
    color: 'blackAlpha.700',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.6))',
    w: '35px',
    fontWeight: 'black',
  },
};
export default styles;
