import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TTransaction } from '@/lib/DBModels';
import { TableText } from '../styles/DesktopTransactionsTable.styles';
import { useWinningsStake } from '@/views/account/Transactions/components/TransactionTable/Services/Transactions.hooks';

export type TSgmDetailsProps = {
  transaction: TTransaction;
};

export default function SgmDetails({ transaction }: TSgmDetailsProps) {
  const {
    bet_id: betId,
    match_name: matchName,
    match_start_time: matchStartTime,
    odds = 0,
    leg_details: legDetails = [],
  } = transaction.details ?? {};
  const winningsStake = useWinningsStake(transaction);

  return (
    <>
      <FormattedMessage
        id="account.transactions.sgmTransactionTitle"
        values={{
          legCount: legDetails.length,
          odds: odds.toFixed(2),
          hasOdds: !!odds,
        }}
      />
      {winningsStake}
      <TableText>{matchName}</TableText>
      <TableText>
        <FormattedMessage
          id="account.transactions.eventDateTimeWithValue"
          values={{
            value: dayjs(matchStartTime).format('DD/MM/YYYY HH:mm:ss (z)'),
            time: (chunks) => <time dateTime={matchStartTime}>{chunks}</time>,
          }}
        />
      </TableText>

      {legDetails.map(({ market_name, proposition_details }, matchIndex) => (
        <Box
          key={matchIndex}
          sx={{ borderBottom: '1px', borderColor: 'gray.300', my: '3' }}
        >
          <TableText>{market_name}</TableText>
          <TableText>{proposition_details.proposition_name}</TableText>
        </Box>
      ))}

      <TableText>
        <FormattedMessage
          id="account.transactions.betIdWithValue"
          values={{ value: betId }}
        />
      </TableText>
    </>
  );
}
