import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 'lg',
  border: 0,
  mb: '2',
  bg: 'white',

  _expanded: {
    borderRadius: 'lg',
  },

  ...(isNested && {
    boxShadow: 'none',
    borderRadius: 'lg',

    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  ...(isNested && {}),
  border: '1px',
  borderTop: 0,
  borderColor: 'alpha.100',
  borderBottomRadius: 'base',
  p: '2',
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'white',
  color: 'gray.700',
  px: '2',
  py: '2.5',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 'lg',
  fontWeight: 'bold',
  fontSize: 'sm',

  _expanded: {
    color: 'alpha.600',
    bg: 'alpha.50',
    borderBottomRadius: 0,
    border: 0,
    p: '2',
  },

  ...(isNested && {
    bg: 'alpha.50',

    _expanded: {
      bg: 'alpha.500',
      fontWeght: 'bold',
      color: 'white',
      borderBottomRadius: 0,
      px: '2',
      py: '1.5',
    },
  }),
});
