import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {},
  },
  iconButtonScrollRight: {
    fontSize: 'sm',
    p: '1',
    border: 'none',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
    color: 'alpha.600',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 3px 3px 0px',
    minW: 4,
    maxW: 4, 
    mt: 1,
    h: '29px',
    _hover: {
      color: 'alpha.700',
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {},
  },
  iconButtonScrollLeft: {
    fontSize: 'sm',
    p: '1',
    border: 'none',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
    color: 'alpha.600',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px 0px 0px 3px',
    minW: 4,
    maxW: 4,
    mt: 1,
    h: '29px',
    _hover: {
      color: 'alpha.700',
    },
  },
  flexWrapper: {
    bg: 'blackAlpha.600',
    borderRadius: 'md',
    boxShadow: '0 1px rgba(255, 255, 255, .05)',
    pos: 'relative',
    minH: '34px',
  },
  buttonGroupSports: {
    display: 'inline-flex',
    alignItems: 'center',
    flex: '1',
    bg: 'blackAlpha.500',
    borderRadius: 'md',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.25),0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    overflowX: 'auto',
    mr: [null, null, '50px'],
    mt: [null, null, '7px'],
    h: [null, null, '33px'],
    p: '1',
    spacing: '1',
    whiteSpace: 'nowrap',
    pos: 'absolute',
    inset: 0,
    sx: {
      '::-webkit-scrollbar': { display: 'none' },
      button: {
        '-webkit-tap-highlight-color': 'transparent',
      },
    },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    p: '1',
    pos: 'relative',
    spacing: '1',
    ml: 'auto',
    borderRadius: 'md',
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'beta.400',
    pos: 'absolute',
    bottom: 0,
    transition: 'all 0.2s ease',
  },
};
