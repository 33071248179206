import { TSRMPrices } from '@/lib/DBModels';

type ButtonData = {
  labelId: string;
  priceKey: keyof TSRMPrices;
  index: number;
};

export const buttonData: ButtonData[] = [
  {
    labelId: 'racing.srm.win',
    priceKey: 'top_1',
    index: 0,
  },
  {
    labelId: 'racing.srm.top',
    priceKey: 'top_2',
    index: 1,
  },
  {
    labelId: 'racing.srm.top',
    priceKey: 'top_3',
    index: 2,
  },
  {
    labelId: 'racing.srm.top',
    priceKey: 'top_4',
    index: 3,
  },
];
