import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'secondary',
    h: '6',
    fontSize: '2xs',
    fontFamily: 'Ultra',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  },
};
