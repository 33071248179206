import React from 'react';
import { Box } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getThemeName } from '@/helpers/getThemeName';
import {
  FlexInner,
  TextDescription,
  TextHeading,
  TextTitle,
} from './styles/ActivityStatements.styles';
import ToggleAccountView from '../../../../../components/ToggleAccountView/ToggleAccountView';
import { useActivityStatements } from './services/ActivityStatements.hooks';
import StatementCard from './components/StatementCard/StatementCard';
import NoResults from './components/NoResults';
import Loading from './components/Loading';
import Error from './components/Error';

const ActivityStatements: React.FC = () => {
  const themeName = getThemeName();
  const intl = useIntl();
  const {
    selectedTab,
    setSelectedTab,
    tabOptions,
    data,
    isLoading,
    error,
    months,
  } = useActivityStatements();

  return (
    <Box
      data-cy="activityStatements"
      sx={{ px: '1', py: '2', minH: '30vh', pb: '20' }}
    >
      <FlexInner>
        <TextTitle>
          <FormattedMessage id="account.transactions.activitystatements.title" />
        </TextTitle>
        <TextHeading>
          <FormattedMessage id="account.transactions.activitystatements.heading" />
        </TextHeading>
        <TextDescription>
          <FormattedMessage
            id="account.transactions.activitystatements.description"
            values={{
              themeName,
            }}
          />
        </TextDescription>

        {/* Toggle */}
        {!!tabOptions?.length && (
          <ToggleAccountView
            onChange={(tab) => setSelectedTab(tab)}
            selectedTab={selectedTab}
            tabs={tabOptions}
          />
        )}

        {/* Content */}
        {(() => {
          if (error) return <Error />;

          if (isLoading) return <Loading />;

          if (!data?.length) {
            return (
              <NoResults
                pageName={intl.formatMessage({
                  id: 'account.transactions.activitystatements.pagetitle',
                })}
              />
            );
          }

          return months.map((month) => {
            const selectedYear = Number(selectedTab.value);

            const statement = data
              ?.find((activity) => activity?.year === selectedYear)
              ?.statements?.find((s) => s?.month === month);

            const monthName = new Date(selectedYear, month - 1).toLocaleString(
              'default',
              { month: 'long' }
            );

            return (
              <StatementCard
                key={`${selectedYear}-${month}`}
                year={selectedYear}
                month={monthName}
                pdf_link={statement?.pdf_link}
              />
            );
          });
        })()}
      </FlexInner>
    </Box>
  );
};

export default ActivityStatements;
