import React from 'react';
import {
  BetRejectedText,
  IconRejectedBet,
  RejectedBetContainer,
} from '../../../BetCard/components/Footer/Footer.styles';

type TBetCardRejectedProps = {
  text: string;
};

export function BetCardRejection({ text }: TBetCardRejectedProps) {
  return (
    <RejectedBetContainer>
      <IconRejectedBet />
      <BetRejectedText>{text}</BetRejectedText>
    </RejectedBetContainer>
  );
}
