import { Image } from '@chakra-ui/react';
import React from 'react';
import logo from '@/assets/questbet/images/logo.svg';

export default function index() {
  return (
    <Image
      src={logo}
      w={['206px', null, 'unset']}
      mt={['72px', null, 'unset']}
    />
  );
}
