export const en = {
  'header.betslipBtn.desc': 'Slip',
  'footer.copyright':
    '© Viking Gaming Pty Ltd trading as VikingBet | v{appVersion}',
  'betslip.betslipmodal.emptybetslipheading': 'Yer bet slip be empty',
  'racing.errorMessages.noRacesAvailable':
    'Blazing trails no more, race unavailable.',
  'sports.allsports.azheading': 'A-Z',
  'main.featured.header': 'Rivals Clash',
  'onboarding.locationnotallowed.header': 'Lost At Sea',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing Vikingbet from outside of Australia.',
  'error.heading': 'Runes led astray',
  'maintenance.error': `<bold>Repairs are being made. Be back soon!</bold>
    Refresh the page, or try again later.`,
  'maintenance.heading': 'Ship under repair',
  'betslip.betslipbetcard.errors.insufficientlegsformulti':
    'To build a Multi, please add more selections to increase your potential returns.',
  'onboarding.login.subtitle':
    'Prepare to conquer the odds with <Node>VikingBet</Node>, where victory awaits your fearless wagers. <Node>Unlock your account today!</Node>',
  'onboarding.depositLimit.completeSignup': 'Continue',
  'betslip.betslipbetcard.multis.estreturns': 'Est. Payout:',
  'betslip.betslipbetcard.multis.estnoreturns': 'Est. Payout $0.00',
  'betSlip.potentialReturns': 'Est. Payout:',
  'error.description': `<bold>Best to steer clear while we investigate the issue.</bold>
  Refresh the page, or try again later.`,
  'generic.login': 'Login',
};
