import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  justifyContent: [null, null, 'center'],
  form: {
    '.chakra-form-control': {
      '.chakra-form__label': {
        color: 'gray.500',
        fontSize: 'xs',
      },
    },
    'a[href*="forgot-password"]': {
      fontWeight: 'medium',
    },
    'button[aria-label*="password toggle"]': {
      top: '2px',
    },
  },
};

export const Title: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: '4',
  mt: [null, null, '9'],
};
