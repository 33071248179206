import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getStrings } from '@/helpers/utils';
import Grid from '@/components/Grid/Grid';
import { useVerifyIdentity } from '@/views/account/VerifyIdentity/Services/Hooks.VerifyIdentity';
import OnboardingWrapper from '../../../wrappers/OnboardingWrapper';
import {
  GreenIDContainer,
  GreenIDText,
} from '@/views/account/VerifyIdentity/styles/VerifyIdentity.styles';
import { EGreenIDStatus } from '@/views/account/VerifyIdentity/Services/Config.VerifyIdentity';
import { isVerified } from '@/views/account/Overview/services/Overview.utils';
import { GREENID_CSS_URL } from '@/lib/Constants';
import { EOnboardingPageType } from '../../../wrappers/OnboardingWrapper/OnboardingWrapper.types';
import Aside from '../../../components/Aside/Aside';
import ContentHeading from './conditionals/ContentHeading/template';
import StepIndicator from '@/components/StepIndicator/StepIndicator';
import { useAppSelector } from '@/hooks/useRedux';
import { useAuth } from '@/hooks/useAuth';

const AccountVerification: FC = () => {
  const [
    {
      Onboarding: { Step2 },
      Account: { VerifyIdentity: VerifyIdentityStrings },
    },
  ] = getStrings();

  const { accountOverview, verificationStatus } = useAppSelector(
    (state) => state.punter
  );

  const { logout } = useAuth();

  useVerifyIdentity(true, undefined, () => {
    if (
      verificationStatus === EGreenIDStatus.PENDING ||
      verificationStatus === EGreenIDStatus.LOCKED_OUT
    ) {
      toast.error(VerifyIdentityStrings.StatusFailed, {
        id: 'error',
      });
      logout().catch(() => undefined);
    }
  });

  if (isVerified(verificationStatus)) {
    if (!accountOverview?.is_onboarded) return <Navigate to="/deposit-limit" />;

    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href={GREENID_CSS_URL}
        />
      </Helmet>

      <OnboardingWrapper
        aside={<Aside />}
        pageTitle={Step2.PageTitle}
        pageType={EOnboardingPageType.Verification}
      >
        <Flex mb="2">
          <ContentHeading />
          <StepIndicator steps={3} activeStep={1} />
        </Flex>
        <Grid sm={12} md={6}>
          {isVerified(verificationStatus) && (
            <GreenIDText color="black">
              {VerifyIdentityStrings.StatusComplete}
            </GreenIDText>
          )}

          {verificationStatus === EGreenIDStatus.IN_PROGRESS && (
            <GreenIDText color="black">
              {VerifyIdentityStrings.StatusInProgress}
            </GreenIDText>
          )}

          {verificationStatus === EGreenIDStatus.PENDING && (
            <GreenIDText color="black">
              {VerifyIdentityStrings.StatusPending}
            </GreenIDText>
          )}

          {!isVerified(verificationStatus) && <GreenIDContainer id="greenID" />}
        </Grid>
      </OnboardingWrapper>
    </>
  );
};

export default AccountVerification;
