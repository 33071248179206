import React from 'react';
import { Global } from '@emotion/react';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          '@media (min-width: 480px)': {
            backgroundPosition: 'center',
            bg: 'gray.400',
            backgroundSize: 'cover',
          },
        },
      }}
    />
  );
}
