import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    variant: 'tertiary',
    _disabled: {
      bg: 'alpha.700',
      color: 'white',
      fontTransform: 'uppercase',
      fontSize: '2xs',
      fontFamily: 'body',
      fontWeight: 'bold',
      p: '1',
      boxShadow: 'none',
      _after: {
        display: 'none',
      },
    },
  },
};

export const TextMonth: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const FlexInner: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};
