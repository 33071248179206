import { CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  my: '2.5',
};

export const DepositLimitContainer: CSSObject = {
  bg: 'white',
  borderRadius: 'lg',
  boxShadow: '0 3px 8px rgba(0, 0, 0, .2)',
  color: 'gray.600',
  p: '4',
};

export const PendingLimitContainer: CSSObject = {
  borderColor: 'blackAlpha.300',
  p: '4',
};

export const ModalDivider: CSSObject = {
  display: 'none',
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalSubheading: CSSObject = {
  m: '0',
  p: '0',
};

export const ButtonWrapper: CSSObject = {
  mt: '3',
};

export const CancelButton: CSSObject = {
  bg: 'transparent',
  border: '2px',
  borderRadius: 'lg',
  color: 'alpha.600',
  fontFamily: 'CinzelDecorative',
  fontSize: 'sm',
  fontWeight: 'black',
  h: '10',
  textShadow: 'none',
  textTransform: 'none',

  _hover: {
    bg: 'alpha.50',
  },

  _active: {
    bg: 'alpha.100',
  },
};

export const ButtonSubmit: CSSObject = {
  // TODO: fix theme colours
  bg: '#426dd6',
  border: '2px',
  borderColor: 'alpha.700',
  borderRadius: 'lg',
  fontFamily: 'CinzelDecorative',
  fontSize: 'sm',
  fontWeight: 'black',
  h: '10',
  overflow: 'hidden',
  pos: 'relative',
  textTransform: 'none',
  zIndex: '1',

  // Pseudo-element for smooth gradient transition
  _before: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    borderRadius: 'calc(var(--bc-radii-lg) - 4px)',
    bottom: '2px',
    content: '""',
    left: '2px',
    pos: 'absolute',
    right: '2px',
    top: '2px',
    transition: 'inherit',
    zIndex: '-1',
  },

  _hover: {
    bg: 'alpha.700',
    borderColor: 'alpha.500',

    _before: {
      opacity: '0',
    },
  },

  _active: {
    bg: 'alpha.800',
    borderColor: 'alpha.500',

    _before: {
      opacity: '0',
    },
  },
};

export const DepositButton: CSSObject = {
  color: 'white',
};
