import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  FlexItem,
  TextTitle,
  FlexLeftContent,
  FlexRightContent,
  TextValue,
  FlexResponsibleGamblingWrapper,
  FlexOverviewContentWrapper,
} from './styles/Overview.styles';
import { getStrings, centsToDollars } from '@/helpers/utils';
import Button from './conditionals/Button/template';
import { useAccountBalance } from './services/Overview.hooks';
import VerificationStatusButton from './components/VerificationStatus/VerificationStatusButton';
import ResponsibleGamblingBanner from '../../../components/ResponsibleGamblingBanner/ResponsibleGamblingBanner';
import Banner from '../../../components/BannerLegacy/Banner';
import { usePunterData } from '@/store/AuthStore';

const Overview: FC = () => {
  const punterProfile = usePunterData();
  // const { data: punterProfile } = useQueryPunterAccount();
  const { accountBallance, availableBalance, accountBonusBalance } =
    useAccountBalance();

  const [
    {
      Account: { Overview: OverviewStrings },
    },
  ] = getStrings();

  return (
    <FlexOverviewContentWrapper>
      <Banner />
      <FlexResponsibleGamblingWrapper>
        <ResponsibleGamblingBanner />
      </FlexResponsibleGamblingWrapper>

      <Box>
        <FlexItem>
          <FlexLeftContent>
            <TextTitle sx={{ mr: '1' }}>
              {punterProfile?.title} {punterProfile?.first_name}{' '}
              {punterProfile?.middle_name} {punterProfile?.last_name}
            </TextTitle>

            <Text data-cy="overviewAccountNumber" fontWeight="bold">
              {/* TODO: Update with Account Number when it is introduced to payload */}
              {OverviewStrings.Email} {punterProfile?.email}
            </Text>
          </FlexLeftContent>
        </FlexItem>

        <FlexItem>
          <FlexLeftContent>
            <TextTitle sx={{ mr: '1' }}>
              {OverviewStrings.AccountBalance}
            </TextTitle>

            <Text data-cy="overviewAccountBalance" fontWeight="extrabold">
              {centsToDollars(accountBallance || 0)}
            </Text>
          </FlexLeftContent>

          <FlexRightContent>
            <Button
              data-cy="overviewDepositButton"
              as={Link}
              to="/account/deposit"
            >
              {OverviewStrings.Deposit}
            </Button>
          </FlexRightContent>
        </FlexItem>

        <FlexItem>
          <FlexLeftContent>
            <TextTitle sx={{ mr: '1' }}>
              {OverviewStrings.WithdrawableBalance}
            </TextTitle>

            <TextValue
              data-cy="overviewWithdrawableBalance"
              fontWeight="extrabold"
            >
              {centsToDollars(availableBalance || 0)}
            </TextValue>
          </FlexLeftContent>

          <FlexRightContent>
            <Button
              data-cy="overviewWithdrawButton"
              as={Link}
              to="/account/withdrawal"
            >
              {OverviewStrings.Withdraw}
            </Button>
          </FlexRightContent>
        </FlexItem>

        <FlexItem>
          <FlexLeftContent>
            <TextTitle>{OverviewStrings.BonusBalance}</TextTitle>

            <TextValue data-cy="overviewBonusBalance" fontWeight="extrabold">
              {centsToDollars(accountBonusBalance || 0)}
            </TextValue>
          </FlexLeftContent>

          <FlexRightContent />
        </FlexItem>

        <FlexItem>
          <FlexLeftContent>
            <TextTitle>{OverviewStrings.VerificationStatus}</TextTitle>
          </FlexLeftContent>

          <FlexRightContent>
            <VerificationStatusButton />
          </FlexRightContent>
        </FlexItem>
      </Box>
    </FlexOverviewContentWrapper>
  );
};

export default Overview;
