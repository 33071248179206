import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TFormikValues } from '../types';
import { useLifetimeExclusion } from '../Services/Hooks.LifetimeExclusion';
import { getStrings } from '@/helpers/utils';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const [
    {
      Account: {
        LifetimeExclusion: { errorToast, validationErrorMessage },
      },
    },
  ] = getStrings();

  const [mutate] = useLifetimeExclusion();

  return (
    <Formik<TFormikValues>
      initialValues={{
        confirmedImmediate: false,
        confirmedClosed: false,
        confirmedFunds: false,
      }}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        confirmedImmediate: Yup.boolean().isTrue(validationErrorMessage),
        confirmedClosed: Yup.boolean().isTrue(validationErrorMessage),
        confirmedFunds: Yup.boolean().isTrue(validationErrorMessage),
      })}
      onSubmit={async (val, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await mutate();
        } catch (e) {
          toast.error(errorToast);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
