import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    h: '8',
    borderWidth: '3px',
    borderColor: 'beta.500',
    borderRadius: 'lg',
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), rgba(86, 215, 255, 0.10)',
    boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.30)',
    backdropFilter: 'blur(4px)',
    p: '1.5',
    mb: '3',
    sx: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
        gap: '2',
      },
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    gap: '1.2',
  },
  breadcrumbLinkItem: {
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    color: 'white',
    fontWeight: 'bold',
    _hover: {
      textDecoration: 'none',
    },
    sx: {
      '&:not([href])': {
        textDecoration: 'none',
      },
    },
  },
};
export const ChevronRightIcon: CSSObject = {
  display: 'block',
  m: '0',
};
