import {
  chakra,
  ChakraComponent,
  Flex,
  Icon,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { styleImports } from './ListItem.styles.imports';
import IconSvg from '../../../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import SgmLogo from '@/components/SgmLogo/SgmLogo';

const themeName = getThemeName();

export const LinkWrapper = chakra(Link, {
  label: 'listItem-LinkWrapper',
  baseStyle: ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto auto',
    gridTemplateRows: ['auto auto', 'auto'],
    gap: '0px 0px',
    gridTemplateAreas: [
      `
      "icon details details chevron"
      "icon time time chevron";
      `,
      `
      "icon details time chevron"
      `,
    ],
    alignItems: 'center',
    p: '2',
    flex: 1,

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
      borderTop: '1px',
      borderTopColor: 'blackAlpha.300',
      transition: 'background .2s ease-in-out',

      _hover: { bg: 'betaAlpha.500' },
    }),

    ...(theme.themeName === EThemes.Wellbet && {
      my: '1',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps>;

export const TextListName = chakra(Text, {
  label: 'listItem-TextListName',
  baseStyle: ({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 'sm',
    maxW: ['unset', '28ch', 'unset'],
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: ['xs', null, 'sm'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextListName;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

export const SubTextIcon = chakra(Icon, {
  label: 'listItem-SubTextIcon',
  baseStyle: () => ({
    boxSize: '3.5',

    ...(() => {
      try {
        return styleImports[themeName]?.SubTextIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextSpanName = chakra(Text, {
  label: 'listItem-TextSpanName',
  baseStyle: ({ isVS, theme }: any) => ({
    ...(isVS && { color: 'yellow.400' }),

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.700',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextSpanName?.(isVS);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps & { isVS: boolean }>;

export const FlexSubTextWrapper = chakra(Flex, {
  label: 'listItem-FlexSubTextWrapper',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    fontSize: 'xs',
    color: 'beta.200',
    fontWeight: 'semibold',

    svg: { color: 'whiteAlpha.400' },

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'gray.500',
      fontSize: ['2xs', null, 'xs'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexSubTextWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextCardSubHeading = chakra(Text, {
  label: 'listItem-TextCardSubHeading',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'xs',
  }),
}) as ChakraComponent<'text', TextProps>;

export const TextStartTime = chakra(Text, {
  label: 'listItem-TextStartTime',
  baseStyle: () => ({
    fontWeight: 'semibold',
    fontSize: 'xs',
    color: 'white',
    mr: '2.5',
    lineHeight: 'none',
  }),
}) as ChakraComponent<'text', TextProps>;

export const IconSport = chakra(IconSvg, {
  label: 'listItem-IconSport',
  baseStyle: ({ theme }) => ({
    boxSize: ['10', '6'],
    p: ['2', 'unset'],
    mr: '2.5',
    borderRadius: ['full', 'unset'],
    boxShadow: [
      '0px 1px 0px rgba(255, 255, 255, 0.25), inset 0px 3px 4px rgba(0, 0, 0, 0.35)',
      'unset',
    ],

    bg: ['blackAlpha.300', 'none'],

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'transparent',
      boxShadow: 'none',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.IconSport;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

export const IconChevron = chakra(Icon, {
  label: 'listItem-IconChevron',
  baseStyle: ({ theme }) => ({
    boxSize: '3.5',
    color: 'white',
    ml: ['2'],

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.700',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.IconChevron;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

// --

export const FlexEndWrapper = chakra(Flex, {
  label: 'listItem-FlexEndWrapper',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    ml: 'auto',
    width: ['100%', 'unset'],

    ...(theme.themeName === EThemes.Betgalaxy && {
      '.chakra-badge.state-prior': {
        color: 'white',
        fontWeight: 'semibold',
        fontSize: 'xs',
        px: '0',
      },
    }),

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: '2xs',
      '.chakra-badge.state-prior': {
        fontWeight: 'semibold',
        fontSize: '2xs',
        px: '0',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexEndWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexCardInfoWrapper = chakra(Flex, {
  label: 'listItem-FlexCardInfoWrapper',
  baseStyle: ({ theme }) => ({
    p: '0.5',
    borderRadius: 'sm',
    justifyContent: 'space-between',
    alignItems: 'center',

    '.state-prior': { color: 'white' },

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'blackAlpha.200',
    }),
  }),
});

export const FlexCardInfoTitle = chakra(Text, {
  label: 'listItem-FlexCardInfoTitle',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: '2xs',
  }),
}) as ChakraComponent<'text', TextProps>;

export const TextCardInfoBadge = chakra(Text, {
  label: 'listItem-TextCardInfoBadge',
  baseStyle: ({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '2xs',
    ml: '0.5',
    borderRadius: 'sm',
    textTransform: 'uppercase',
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'beta.400',
      py: '0.5',
      px: '1',
    }),
  }),
}) as ChakraComponent<'text', TextProps>;

export const IconSGM = chakra(SgmLogo, {
  'data-testid': 'listItem-IconSGM',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.IconSGM;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
