import { CSSObject } from '@chakra-ui/react';

export const WrapperFlexOnboarding: CSSObject = {
  w: ['100dvw', null, 'unset'],
  bg: 'beta.900',
};

export const Input: CSSObject = {
  '&&&': { borderRadius: '4px', bg: 'white' },
};
