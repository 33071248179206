import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: (() => {
      if (state === 'ended') return 'red.600';
      if (state === 'inprogress') return 'gamma.400';
      return 'blackAlpha.500';
    })(),
    color: state === 'inprogress' ? 'alpha.700' : 'white',
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
    padding: '3px 4px',
    fontSize: '2xs',
    fontWeight: 'bold',
    borderRadius: 'base',
    textShadow: 'none',
  },
});

export const CountdownFlex: CSSObject = {
  bg: 'transparent',
  h: '4',
  borderRadius: 'base',
};

export const TextTimestamp: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '9px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
};
