import {
  Box,
  chakra,
  FlexProps,
  Flex,
  ChakraComponent,
  ButtonProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './DesktopTransactionsTable.styles.imports';
import { IS_MOBILE_APP } from '../../../../../../../../constants/isMobileApp';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { TTableText } from '../../../types';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export const TableWrapper = chakra(Box, {
  label: 'desktopTransactionsTable-TableWrapper',
  baseStyle: () => ({
    width: '100%',
    display: 'table',
  }),
});

export const TableHeadingsWrapper = chakra(Box, {
  label: 'desktopTransactionsTable-TableHeadingsWrapper',
  baseStyle: () => ({
    display: 'table-row',
    width: '100%',
    ...(() => {
      try {
        return styleImports[themeName]?.TableHeadingsWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TTableFlexItem = {
  isLastColumn?: boolean;
} & FlexProps &
  any;

export const TableItem = chakra<TTableFlexItem>(Box, {
  label: 'desktopTransactionsTable-TableItem',
  baseStyle: ({ theme, isLastColumn }: TTableFlexItem) => ({
    display: 'table-cell',
    px: '4',
    py: '3',
    height: '100%',
    fontSize: 'sm',
    borderRight: !isLastColumn && '1px',
    borderBottom: '1px',
    ...(theme.themeName === EThemes.Wellbet && {
      borderColor: 'gray.200',
      borderRight: 'none',
      borderStyle: 'dashed',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      borderColor: 'gray.100',
      borderRight: 'none',
      _first: {
        borderTopLeftRadius: 'md',
      },
      _last: {
        borderTopRightRadius: 'md',
      },
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TableItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TableHeadingText = chakra('h4', {
  label: 'desktopTransactionsTable-TableHeadingText',
  baseStyle: ({ theme }) => ({
    fontSize: 'xs',
    fontWeight: 'medium',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'gray.600',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TableHeadingText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TableText = chakra('p', {
  label: 'desktopTransactionsTable-TableText',
  shouldForwardProp: (prop) => !['textVariation'].includes(prop),
  baseStyle: ({ textVariation }: any) => ({
    fontSize: 'sm',
    ...(textVariation === 'negative' && {
      color: 'red.500',
    }),
    color: textVariation === 'positive' ? 'green.500' : 'gray.700',

    ...(() => {
      try {
        return styleImports[themeName]?.TableText?.({ textVariation });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TTableText>;

export const DownloadActionLink = chakra('p', {
  label: 'desktopTransactionsTable-DownloadActionLink',
  baseStyle: () => ({
    fontSize: 'sm',
    ml: '10',
    cursor: 'pointer',
    marginLeft: 'auto',
  }),
});

export const PageHeadingWrapper = chakra(Box, {
  label: 'desktopTransactionsTable-PageHeadingWrapper',
  baseStyle: () => ({
    alignItems: 'baseline',
    display: 'table-row',
    width: '100%',
    maxWidth: '1200px',
    p: '1',
  }),
});

export const TransactionWrapper = chakra(Box, {
  label: 'desktopTransactionsTable-TransactionWrapper',
  baseStyle: () => ({
    w: 'full',
    m: '0 auto',
    display: 'table-row',
    height: '24',

    ...(() => {
      try {
        return styleImports[themeName]?.TransactionWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DownloadSpreadsheetButton = chakra(Button, {
  label: 'desktopTransactionsTable-DownloadSpreadsheetButton',
  baseStyle: ({ theme }) => ({
    color: 'blue.500',
    cursor: 'pointer',
    _hover: {
      textDecoration: 'underline',
    },
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'beta.500',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.DownloadSpreadsheetButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const NoTransactionsText = chakra(Flex, {
  label: 'desktopTransactionsTable-NoTransactionsText',
  baseStyle: () => ({
    textAlign: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    alignItems: 'center',
    p: '3',
    pt: ['0', null, '3'],
    justifyContent: 'center',
    mx: 'auto',
    ...(() => {
      try {
        return styleImports[themeName]?.NoTransactionsText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxTransactionsContainer = chakra(Box, {
  label: 'desktopTransactionsTable-BoxTransactionsContainer',
  baseStyle: () => ({
    minH: '30vh',
    ...(!IS_MOBILE_APP && {
      py: '2',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxTransactionsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PageHeading = styled.h5`
  ${({ theme }) => theme.features.account.desktopTransactionsTable.pageHeading};
`;

export const buttonMoreTableProps: ButtonProps = {
  'data-testid': 'buttonMoreTableProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonMoreTableProps;
    } catch (err) {
      return {};
    }
  })(),
};
