import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: [0, null, '3'],
    rowGap: ['12px', null, 'unset'],
  },
  FlexCard: {
    borderRadius: '5px',
    background: 'var(--sterlingparker-beta-500, #30363F)',
    boxShadow:
      '0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
  },
  LinkCardHeader: {
    height: '36px',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    px: ['3.5', '3.5', '3'],
    pt: '2',
    color: 'white',
  },
  TextCardHeading: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#fff',
  },
  TextRunner: {
    fontFamily: 'Roboto',
    fontSize: 'xs',
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'white',
    maxW: ['unset', null, '120px'],
  },
  TextRaceNumber: {
    bg: 'beta.700',
    borderRadius: 'sm',
    color: '#fab735',
    height: '17px',
    lineHeight: '17px',
  },
  GridCardContent: {
    p: '0',
  },
  BoxGridHeaderItem: {
    height: '18px',
    alignSelf: 'stretch',
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: '3px 14px',
    boxShadow: '0 1px 0 0 rgba(255, 255, 255, 0.08)',
    backgroundColor: 'rgba(0, 0, 0, 0.36)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#d6a64a',
    textTransform: 'uppercase',
  },
  FlexGridRunnerItem: {
    height: '44px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.16)',
    px: '13px',
  },
  LinkRaceDetails: {
    height: '40px',
    boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.1)',
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',

    fontFamily: 'CrimsonPro',
    fontSize: '14.5px',
    fontWeight: 900,
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#d6a64a',
    textTransform: 'uppercase',
  },
  TextScratched: {
    color: 'white',
  },
};
