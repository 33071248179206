import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'numb',
    fontSize: 'xs',
    height: '7',
    textTransform: 'uppercase',
    sx: {
      '&&': {
        fontWeight: 'black',
        textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
      },
    },
  },
};
