import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  TBetSlipBetExotics,
  EBetSlipViewMode,
} from '../../../../Services/Betslip.types';
import { useExoticsFlexi } from '../../../../Services/Betslip.hooks';
import { getStrings } from '@/helpers/utils';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { FlexiReview, ReviewStakeText, ReviewStake } from './Forms.styles';
import FormsInput from './FormsInput';

// This component handles showing/calculating an exotic flex % for exotics

const FormsExoticFlexi = ({ bet }: { bet: TBetSlipBetExotics }) => {
  const [{ Generic }] = getStrings();
  const { viewMode } = useAppSelector((state) => state.betSlip);
  const { flexi, handleFlexiChange } = useExoticsFlexi(bet);

  return (
    <Box flex="1">
      {viewMode === EBetSlipViewMode.ReviewingBets && (
        <FlexiReview>
          <ReviewStakeText>{Generic.Flexi}</ReviewStakeText>
          <ReviewStake>{flexi}%</ReviewStake>
        </FlexiReview>
      )}
      {viewMode === EBetSlipViewMode.EditingBets && (
        <FormsInput
          placeholder="0"
          bet={bet}
          fieldName="flexi"
          suffix="%"
          cyLabel="betCardFlexiInput"
          onChange={handleFlexiChange}
          value={flexi}
          hasDataTag={false}
        />
      )}
    </Box>
  );
};

export default FormsExoticFlexi;
