import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  fontFamily: 'Bowlby One',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'base',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'beta.600',
  minW: 'unset',
  bg: 'beta.400',
  borderRadius: 'base',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',

  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'beta.600',
  },
  '&&': {
    svg: {
      color: 'alpha.700',
    },
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'Roboto',
  color: 'beta.400',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
