import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title as TitleExternal } from '@/views/onboarding/styles/onboarding.styles';
import StepIndicator from '@/components/StepIndicator/StepIndicator';
import { titleProps } from '../../styles/DepositLimit.styles';

export default function Title() {
  return (
    <TitleExternal {...titleProps}>
      <FormattedMessage id="onboarding.depositLimit.formTitle" />
      <StepIndicator steps={3} activeStep={2} />
    </TitleExternal>
  );
}
