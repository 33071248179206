import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  pt: '0.5',
  px: '3',
  mb: '0',
  flex: '1',
  fontWeight: 'medium',
  bg: 'beta.100',
  borderRadius: 'xl',
  border: 'none',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 5px var(--bc-colors-beta-200) inset',
};

export const TypeItem: CSSObject = {
  color: 'beta.500',
  fontWeight: 'bold',
  fontSize: 'xs',
  fontFamily: 'body',
};

export const ResultRowWrapper: CSSObject = {
  borderRadius: 'md',
  mb: '1.5',
  px: '1.5',
  py: '2',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'beta.700',
  border: 'none',
  boxShadow: '0px 1px 5px 0px var(--bc-colors-blackAlpha-600) inset',
  _last: {
    mb: ['2', null, '0'],
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  _last: {
    color: 'beta.500',
    fontWeight: 'black',
  },
};
