import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import StepIndicator from '@/components/StepIndicator/StepIndicator';
import { useSignup } from '../../services/SignUp.hooks';
import {
  BoxLoginCTAContainer,
  FlexHeaderWrapper,
  LinkHighlight,
  LinkLogin,
} from '../../styles/SignUp.styles';
import Form from '../Form';
import FormikHOC from '../FormikHOC';
import { WrapperContent } from './styles/Content.styles';

export default function Content() {
  const [
    {
      Onboarding: { Step1 },
    },
  ] = getStrings();

  const {
    setMobilePasswordFocused,
    mobilePasswordFocused,
    placesAutocompleteLoaded,
    handleSignUp,
  } = useSignup();

  return (
    <WrapperContent>
      <FlexHeaderWrapper>
        <FormattedMessage id="onboarding.register.createAccount" />
        <StepIndicator steps={3} activeStep={0} />
      </FlexHeaderWrapper>

      <FormikHOC handleSignUp={handleSignUp}>
        <Form
          setMobilePasswordFocused={setMobilePasswordFocused}
          mobilePasswordFocused={mobilePasswordFocused}
          placesAutocompleteLoaded={placesAutocompleteLoaded}
        />
      </FormikHOC>

      <BoxLoginCTAContainer>
        <LinkLogin to="/login">
          {Step1.Login}{' '}
          <LinkHighlight as="span" data-cy="loginNow">
            {Step1.LoginCTA}
          </LinkHighlight>
        </LinkLogin>
      </BoxLoginCTAContainer>
    </WrapperContent>
  );
}
