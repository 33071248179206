import React from 'react';
import { Global } from '@emotion/react';
import onboarding from '@/assets/questbet/images/backgrounds/onboarding.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          '@media (min-width: 480px)': {
            backgroundPosition: 'center',
            background: `url(${onboarding})`,
            backgroundSize: 'cover',
          },
        },
      }}
    />
  );
}
