import React from 'react';
import bUfo from '../../../../../assets/betgalaxy/images/switch/blue-ufo.svg';
import gUfo from '../../../../../assets/betgalaxy/images/switch/green-ufo.svg';
import { FlexWrapper, ImageLeft, ImageRight } from '../styles/Toggle.styles';
import TemplateToggle from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateToggle, [
  { name: 'wrapper', component: <FlexWrapper /> },
  {
    name: 'leftImage',
    component: <ImageLeft src={bUfo} />,
    config: {
      before: 'textRace',
    },
  },
  {
    name: 'rightImage',
    component: <ImageRight src={gUfo} />,
    config: {
      after: 'textSport',
    },
  },
]);
