import {
  Box,
  BoxProps,
  Center,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Link,
  LinkProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './LocationNotAllowed.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const LocationNotAllowedContainer = chakra(Flex, {
  label: 'locationNotAllowed-Container',
  baseStyle: () => ({
    h: '100vh',
    flexDir: 'column',
    ...(() => {
      try {
        return styleImports[themeName]?.LocationNotAllowedContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkLogo = chakra(Link, {
  label: 'locationNotAllowed-LinkLogo',
  baseStyle: () => ({
    maxW: '160px',
    display: 'inline-block',
  }),
});

export const FlexHeaderContainer = chakra(Flex, {
  label: 'locationNotAllowed-FlexHeaderContainer',
  baseStyle: () => ({
    w: 'full',
    px: '4',
    alignItems: 'center',
  }),
});

export const ContentHeadingText = chakra(Text, {
  baseStyle: () => ({
    label: 'locationNotAllowed-ContentHeadingText',
    textAlign: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.ContentHeadingText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ContentBodyText = chakra(Text, {
  baseStyle: () => ({
    label: 'locationNotAllowed-ContentBodyText',
    fontSize: ['sm', null, 'md'],
    ...(() => {
      try {
        return styleImports[themeName]?.ContentBodyText;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const SubHeader = chakra(Text, {
  label: 'locationNotAllowed-SubHeader',
  baseStyle: () => ({
    fontSize: ['md', null, 'md'],
    ...(() => {
      try {
        return styleImports[themeName]?.SubHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const ContactText = chakra(Link, {
  baseStyle: () => ({
    fontSize: ['sm', null, 'md'],
    fontWeight: 'medium',
    ...(() => {
      try {
        return styleImports[themeName]?.ContactText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexVerbiageContainer = chakra(Flex, {
  label: 'locationNotAllowed-FlexVerbiageContainer',
  baseStyle: () => ({
    maxW: '380px',
    flexDir: 'column',
    alignItems: 'start',
    gap: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexVerbiageContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const CenterWrapper = chakra(Center, {
  label: 'locationNotAllowed-CenterWrapper',
  baseStyle: () => ({
    w: 'full',
    flexDir: 'column',
    mt: ['8', null, '12'],
    maxW: ['380px', null, 'container.lg'],
    mx: 'auto',
    gap: '4',
    px: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.CenterWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextLink = chakra(Link, {
  label: 'locationNotAllowed-TextLink',
  baseStyle: () => ({
    cursor: 'pointer',
    display: 'inline',
    textDecoration: 'underline',
    fontWeight: 'semibold',

    ...(() => {
      try {
        return styleImports[themeName]?.ExternalLink;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'a', LinkProps>;

export const RegistrationContent = chakra('div', {
  label: 'locationNotAllowed-RegistrationContent',
  baseStyle: () => ({
    mt: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.RegistrationContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextHeadingColor = chakra(Text, {
  label: 'locationNotAllowed-TextHeadingColor',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextHeadingColor;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextBodyCopy = chakra(Box, {
  label: 'locationNotAllowed-TextBodyCopy',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextBodyCopy;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;
