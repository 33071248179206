import React from 'react';
import { Global } from '@emotion/react';
import header from '@/assets/betprofessor/images/backgrounds/image-head.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${header})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundAttachment: 'fixed',
        },
      }}
    />
  );
}
