import { Accordion, AccordionIcon, Box } from '@chakra-ui/react';
import React from 'react';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '../../MarketsByMatch/styles/MarketsByMatch.styles';
import {
  TMarketGroupsAccordionProps,
  TMarketsAccordionProps,
} from '../services/MatchDetailPage.types';
import MarketsAccordion from './MarketsAccordion';
import { marketGroupsAccordionStyles } from '../../NewMatchDetailPage/styles/MarketGroupsAccordion/MarketGroupsAccordion.styles';

export default function MarketGroupsAccordion({
  data,
  defaultIndices,
  onMarketChange,
  onSelection,
}: TMarketGroupsAccordionProps) {
  const handleMarketChange: TMarketsAccordionProps['onMarketChange'] = (
    expandedMarkets
  ) => {
    onMarketChange?.(expandedMarkets);
  };

  return (
    <Accordion
      allowMultiple
      defaultIndex={defaultIndices ? [defaultIndices.marketGroup] : undefined}
    >
      {data.map(({ name, markets }, index) => (
        <AccordionItem key={name}>
          <AccordionButton>
            <Box as="span" {...marketGroupsAccordionStyles.accordionItemBox}>
              {name}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <MarketsAccordion
              data={markets}
              defaultIndex={
                defaultIndices && index === defaultIndices.marketGroup
                  ? [defaultIndices.market]
                  : undefined
              }
              onMarketChange={handleMarketChange}
              onSelection={onSelection}
            />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
