import { CSSObject } from '@chakra-ui/react';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 500,
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const TextDescription: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'blackAlpha.400',
  borderStyle: 'solid',
  borderWidth: '1px',
};

/**
 * Form Inputs
 */
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'normal',
    fontSize: 'sm',
  },
};

export const InputWithdrawal: CSSObject = {
  '&&': {
    bg: 'white',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'sm',
    height: '38px',
    color: 'alpha.900',
    _placeholder: {
      color: 'gray.700',
    },
    '&&:hover': {
      bg: 'gray.100',
      borderColor: 'gray.400',
    },
    '&&:focus': {
      bg: 'gray.50',
      borderColor: 'blue.300',
      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'gray.500',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '&&': {
    '.chakra-input__left-addon': {
      bg: 'alpha.900',
      color: 'white',
      fontSize: 'sm',
      height: '38px',
    },
    '.chakra-input': {
      borderLeftRadius: 'none',
      bg: 'white',
      borderColor: 'gray.300',
      borderWidth: '1px',
      color: 'alpha.900',
      borderRightRadius: 'base',
      borderLeft: 'none',
      _hover: {
        bg: 'gray.100',
        borderColor: 'gray.400',
      },
      _focus: {
        bg: 'gray.50',
        borderColor: 'blue.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },
      _placeholder: {
        color: 'gray.700',
      },
    },
  },
};

/**
 * Buttons
 */

export const ButtonWithdrawal: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  textShadow: 'none',
  boxShadow:
    '0px 0px 0px 2px #0C2549 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
  borderRadius: 'base',
  borderWidth: '2px',
  borderColor: 'beta.500',
  mt: '2',
};

export const ButtonIncrease: CSSObject = {};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderColor: 'blackAlpha.400',
  borderStyle: 'solid',
  borderBottom: '1px',
};

export const BankRadio: CSSObject = {
  alignItems: 'start',
  border: 'none',
  bg: 'gray.200',
  '&[data-checked]:before': {
    display: 'flex',
    alignSelf: 'center',
    width: '40%',
    height: '40%',
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
  color: 'white',
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'alpha.700',
  boxShadow: 'none',
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

/**
 * Withdrawal Request Card
 */
export const WithdrawalWrapper: CSSObject = {
  bg: 'white',
  border: '1px solid',
  borderColor: 'gamma.100',
  fontSize: 'sm',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gamma.100',
  color: 'gray.700',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow:
    '0px 0px 0px 2px #0C2549 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
  borderRadius: 'base',
  borderWidth: '2px',
  borderColor: 'beta.500',
  textShadow: 'none',
  fontWeight: 800,
  fontSize: 'xs',
  color: 'white',
  _hover: {
    bg: 'gamma.700',
    color: 'white',
    boxShadow: 'none',
    textShadow: 'none',
  },
  _active: {
    bg: 'gamma.800',
  },
};

export const WithdrawalStatus = (): CSSObject => ({
  borderRadius: 'base',
  px: '2',
});
