import * as goldenrush from './BtnOfferings.styles.goldenrush';
import * as juicybet from './BtnOfferings.styles.juicybet';
import * as puntgenie from './BtnOfferings.styles.puntgenie';
import * as vikingbet from './BtnOfferings.styles.vikingbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  goldenrush,
  juicybet,
  puntgenie,
  vikingbet,
};
