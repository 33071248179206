import { cssVar } from '@chakra-ui/react';
import { BadgesSchema } from './Badges.styles';

const $arrowBg = cssVar('popper-arrow-bg');

export const badgeStyles: BadgesSchema = {
  flexIconWrapper: {
    bg: 'alpha.500',
    h: '8',
    borderRadius: 'md',
    boxShadow: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: '2',
    p: '2',
    maxW: '110px',
  },
  iconBadge: {
    color: 'delta.500',
    filter: 'box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2)',
  },
  iconTitle: {
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.30)',
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '1',
    color: 'white',
    textAlign: 'left',
  },
  textTitle: {
    mb: '2',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  textDescription: {
    fontSize: 'xs',
  },
  tabs: {
    w: 'full',
    mt: '1',
  },
  tabPanels: {
    mt: '2',
  },
  tooltipWrapper: {
    borderRadius: 'md',
    p: '2',
    pr: '4',
    display: 'block',
    position: 'relative',
    bg: 'alpha.500',
    [$arrowBg.variable]: 'var(--bc-colors-alpha-500)',
  },
  tabPanelWrapper: {
    bg: 'alpha.500',
    p: '2',
    borderRadius: 'md',
    color: 'white',
    fontSize: 'xs',
    w: 'full',
  },
  tabBadge: {
    position: 'relative',
    p: '0',
    pr: '1',
    mr: '1',
    border: 'none',

    _selected: {
      border: 'none',
      '&::after': {
        content: "''",
        position: 'absolute',
        left: '0',
        right: '0',
        margin: 'auto',
        bottom: '-12px',
        width: '5',
        height: '5',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid var(--bc-colors-alpha-500)',
        transform: 'rotate(180deg)',
        clear: 'both',
        zIndex: ['unset', null, 'popover'],
      },

      // Adjust the background color of FlexIconWrapper in the selected state
      '& div': {
        bgGradient: 'linear(to-b, delta.400, delta.600)',
      },

      '& path': {
        fill: 'alpha.500',
      },
      '& p': {
        color: 'alpha.500',
        textShadow: 'none',
      },
    },
  },
  moneybackBadge: {
    color: 'white',
  },
  moneybackTitle: {
    color: 'white',
  },
};
