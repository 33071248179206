import React from 'react';
import {
  FlexFeatureCardWrapper,
  FlexFeatureTitleWrapper,
  TextFeatureHeading,
  TextFeatureStyledHeading,
  TextFeatureSubHeading,
} from '../styles/Featured.styles';
import { TTemplate } from '@/helpers/createTemplate';
import Card from '../Card';

import baseTemplate from './template.default';

const template: TTemplate = {
  wrapper: <FlexFeatureTitleWrapper />,
  feature: (
    <>
      <TextFeatureSubHeading>
        Featured{' '}
        <TextFeatureStyledHeading as="span">race</TextFeatureStyledHeading>{' '}
      </TextFeatureSubHeading>
      <TextFeatureHeading>IN OUTER SPACE</TextFeatureHeading>
    </>
  ),
  card: (
    <FlexFeatureCardWrapper>
      <Card />
    </FlexFeatureCardWrapper>
  ),

  ...(baseTemplate as TTemplate),
};

export default template;
