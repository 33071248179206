import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      border: 'none',
      boxShadow: 'none',
      borderBottomRadius: 0,
      textShadow: 'none',
      _hover: {
        boxShadow: '0px -3px 0 0 inset #25FED6',
        bgImage: 'none',
      },
    },
    py: '2',
    bgImage: 'none',
    '&&[data-active="true"]': {
      bg: 'beta.400',
      borderBottom: 'none',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        fontWeight: 'bold',
        fontSize: 'xs',
        color: 'beta.400',
        bg: 'alpha.600',
        textShadow: 'none',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
    },
    '&&&.subTab': {
      color: 'alpha.600',
      _hover: {
        boxShadow: '0px -3px 0 0 inset rgba(62, 62, 62, 1)',
        bgImage: 'none',
      },
    },
  },
};

export const BoxWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  borderRadius: ['none', 'lg'],
  px: '4',
  mx: '0',
  mt: '3',
  mb: '0',
  display: 'flex',
  w: 'full',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  flex: '0 0 auto',
};
