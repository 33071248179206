import {
  BoxProps,
  CenterProps,
  DividerProps,
  FlexProps,
  StackProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './CompetitionBySportV2.styles.imports';
import { TScrollButtonGroupProps } from '@/views/sports/components/ScrollButtonGroup/ScrollButtonGroup';
import { CustomButtonProps } from '@/components/Button/Button';
import { TIconProps } from '@/components/common/IconSvg';

const themeName = getThemeName();

export type CompetitionBySportV2Schema = Partial<{
  containerNav: StackProps;
  scrollButtonGroup: TScrollButtonGroupProps;
  scrollButtonGroupButtonAllLeagues: CustomButtonProps;
  scrollButtonGroupButtonCompetition: CustomButtonProps;
  scrollButtonGroupDivider: DividerProps;
  mainFlexWrapper: FlexProps;
  competitionListBoxWrapper: BoxProps;
  hasNextPageWellOverride: CenterProps;
  hasNextPageButtonOverride: CustomButtonProps;
  hasNextPageButtonIcon: TIconProps;
}>;

export const competitionBySportV2Styles: CompetitionBySportV2Schema = {
  scrollButtonGroupButtonCompetition: {
    'data-testid': 'competitionBySportV2-scrollButtonGroupButtonCompetition',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.scrollButtonGroupButtonCompetition;
      } catch (err) {
        return {};
      }
    })(),
  },
  hasNextPageButtonIcon: {
    'data-testid': 'competitionBySportV2-hasNextPageButtonIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.hasNextPageButtonIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  hasNextPageButtonOverride: {
    'data-testid': 'competitionBySportV2-hasNextPageButtonOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.hasNextPageButtonOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  hasNextPageWellOverride: {
    'data-testid': 'competitionBySportV2-hasNextPageWellOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.hasNextPageWellOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  competitionListBoxWrapper: {
    'data-testid': 'competitionBySportV2-competitionListBoxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.competitionListBoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  mainFlexWrapper: {
    'data-testid': 'competitionBySportV2-mainFlexWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.mainFlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  scrollButtonGroupDivider: {
    'data-testid': 'competitionBySportV2-scrollButtonGroupDivider',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.scrollButtonGroupDivider;
      } catch (err) {
        return {};
      }
    })(),
  },
  scrollButtonGroupButtonAllLeagues: {
    'data-testid': 'competitionBySportV2-scrollButtonGroupButtonAllLeagues',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.scrollButtonGroupButtonAllLeagues;
      } catch (err) {
        return {};
      }
    })(),
  },
  scrollButtonGroup: {
    'data-testid': 'competitionBySportV2-scrollButtonGroup',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.scrollButtonGroup;
      } catch (err) {
        return {};
      }
    })(),
  },
  containerNav: {
    'data-testid': 'competitionBySportV2-containerNav',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionBySportV2Styles
          ?.containerNav;
      } catch (err) {
        return {};
      }
    })(),
  },
};
