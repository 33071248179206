import carbonFiber from '@/assets/sterlingparker/images/backgrounds/carbonFiber.png';
import { WellSchema } from './Well.styles';

export const wellStyles: WellSchema = {
  wrapper: {
    bg: `url("${carbonFiber}")`,
    borderRadius: 'base',
    boxShadow:
      '0 1px rgba(255, 255, 255, .1), 0 2px 5px rgba(0, 0, 0, .15) inset',
    p: '1.5',
  },
};
