import Black from '../../assets/questbet/fonts/Roboto-Black.ttf';
import BlackItalic from '../../assets/questbet/fonts/Roboto-BlackItalic.ttf';
import Bold from '../../assets/questbet/fonts/Roboto-Bold.ttf';
import BoldItalic from '../../assets/questbet/fonts/Roboto-BoldItalic.ttf';

import ExtraLight from '../../assets/questbet/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '../../assets/questbet/fonts/Roboto-ThinItalic.ttf';
import Light from '../../assets/questbet/fonts/Roboto-Light.ttf';
import LightItalic from '../../assets/questbet/fonts/Roboto-LightItalic.ttf';
import Regular from '../../assets/questbet/fonts/Roboto-Regular.ttf';
import Italic from '../../assets/questbet/fonts/Roboto-Italic.ttf';
import SemiBold from '../../assets/questbet/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '../../assets/questbet/fonts/Roboto-MediumItalic.ttf';
import StrippyRegular from '../../assets/questbet/fonts/Strippy-Regular.ttf';

export const fontFace = `
  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${Light}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${LightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Regular}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${Italic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${Bold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${BoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${Black}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${BlackItalic}') format('opentype');
  }
  @font-face {
    font-family: 'strippy';
    src: url('${StrippyRegular}') format('opentype');
  }
`;
