import { CSSObject } from '@chakra-ui/react';
import ntjBg from '@/assets/junglebet/images/backgrounds/ntjBg.png';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexWrapper: CSSObject = {
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  mb: '2',
  bgImage: ntjBg,
  bgSize: 'cover',
  borderBottom: '2px solid',
  borderColor: 'delta.700',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};

export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'base',
  bg: 'delta.300',
  textOverflow: 'ellipsis',
  _hover: {
    bg: 'delta.200',
  },
  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'beta.800',
    mr: '1',
    mt: '1.5',
    height: '3',
    borderRadius: 'base',
    maxW: '10',
  },

  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    mr: '1',
    mt: '1.5',
    height: '3',
    borderRadius: 'base',
    maxW: '10',
  },

  '.chakra-badge.state-ended': {
    bg: 'gamma.400',
    color: 'white',
    mr: '1',
    mt: '1.5',
    height: '3',
    borderRadius: 'base',
    maxW: '10',
  },
};

export const RaceNumber: CSSObject = {
  color: 'delta.50',
};
export const FlexDetails: CSSObject = {
  color: 'delta.50',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'delta.50',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  boxSize: 'full',
  minW: '7',
  color: 'delta.50',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, delta.300, delta.600)',

  _hover: {
    bg: 'delta.200',
  },

  '& > svg': { boxSize: '6' },
};
