import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { keys } from '../../../../api/api.keys';
import {
  queryPunterAccountMyBetsCount,
  queryPunterAccountOverview,
} from '../../../../api/punter/punter';
import { EBetState } from '../../../../api/punter/punter.types';
import { ENewRelicActions } from '../../../../constants/newRelicActions';
import { useAuth } from '../../../../hooks/useAuth';
import { HTTPError } from '../../../../lib/api/types';
import { EBeErrorMessages } from '../../../../lib/Constants';
import { signUserOut } from '../../../../lib/firebase/Authentication';
import { newRelicLog } from '@/lib/newRelic';
import { getUserStoreActions } from '@/store/AuthStore';

export const useHeader = (enabled = true) => {
  const { isLoading, isAuthenticated } = useAuth();
  const location = useLocation();
  const isHome = location.pathname === '/';
  const {
    data,
    isInitialLoading: isAccountLoading,
    refetch,
  } = useQuery(
    [keys.punterAccountOverview, isAuthenticated],
    queryPunterAccountOverview,
    {
      staleTime: 60 * 2000, // 2 minutes
      enabled: !!isAuthenticated && enabled,
    }
  );

  const { setPunterAccountOverview } = getUserStoreActions();

  useEffect(() => {
    if (data) {
      setPunterAccountOverview(data);
    }
  }, [data, setPunterAccountOverview]);

  return {
    isAuthenticated,
    accountBallance: data?.account_balance,
    accountBonusBalance: data?.bonus,
    isLoading: isLoading || isAccountLoading,
    kycDocumentSubmitted: data?.kyc_document_submitted,
    isHome,
    refetch,
  };
};

/**
 * Hook that returns the pending bet total record amount.
 */
export const useMyBetsLength = () => {
  const { isAuthenticated, punterData } = useAuth();

  const { data } = useQuery(
    [keys.punterAccountMyBets, isAuthenticated, EBetState.Pending],
    () => queryPunterAccountMyBetsCount({ states: EBetState.Pending }),
    {
      enabled: !!isAuthenticated,
      onError: async (e) => {
        const err = e as HTTPError;

        if (
          err.response?.data?.detail?.msg === EBeErrorMessages.ACCOUNT_CLOSED
        ) {
          await signUserOut().then(() =>
            newRelicLog(ENewRelicActions.LogOut, punterData?.punter_id, {
              status_code: 200,
            })
          );
        }
      },
      refetchInterval: 60000,
    } // Refetch every 60 seconds
  );

  return data?.total_records ?? 0;
};
