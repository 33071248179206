import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    m: '0',
    mb: '4',
    p: '0.5',
    bg: 'rgba(78, 178, 255, 0.14)',
    border: '2px solid',
    borderColor: 'alpha.500',
    borderRadius: 'lg',
    boxShadow:
      '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  },
  body: {
    color: 'white',
    px: '3',
    pt: '3',
    pb: '0',
  },
};
