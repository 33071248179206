import { CSSObject } from '@chakra-ui/react';

export const FlexSubTextWrapper: CSSObject = {
  color: 'beta.500',
  fontSize: ['2xs', null, 'xs'],
  overflow: ['hidden', 'unset'],
  whiteSpace: ['pre-wrap', 'nowrap'],
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const TextListName: CSSObject = {
  textOverflow: 'unset',
  color: 'inherit',
  overflow: ['hidden', 'unset'],
  whiteSpace: ['pre-wrap', 'nowrap'],
};

export const IconSport: CSSObject = {
  color: 'delta.50',
  boxSize: '7',
  mr: '2.5',
  boxShadow: 'none',
  bg: 'none',
  p: 'none',
};

export const LinkWrapper: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'blackAlpha.300',

  boxShadow: '0px 2px 0px 0px rgba(206, 119, 22, 0.30)',

  h: ['63px', null, '47px'],
  px: '3',
  py: 0,

  gridTemplateRows: 'auto',
  gridTemplateAreas: '"icon details time chevron"',

  _first: { borderTop: 'transparent' },

  '.details': {
    width: '100%',
    overflow: 'hidden',
    pr: '2',
  },
};

export const TextSpanName = (): CSSObject => ({
  color: 'delta.50',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
});

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '0.5',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: '12px',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'white',
    boxSize: '5',
  },
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.500',
};
