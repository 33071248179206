import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  bg: 'alpha.900',
  borderBottomRadius: 'inherit',
  borderTop: '1px solid',
  bgGradient: 'linear(to-b, rgba(194, 223, 255, 0.10), rgba(194, 223, 255, 0.00))', 
  borderTopColor: 'whiteAlpha.300',
  '&&': {
    py: '3',
  },
};

export const SelectionPopupContainer: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.15)',
  mx: [null, null, '2'],
  borderBottomRadius: 'md',
  zIndex: 'modal',
};

export const InfoHeader: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  h: '7',
  px: '2',
  py: '1.5',
  alignItems: 'center',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
};

export const buttonAddToBetslipProps: CustomButtonProps = {
  color: 'blackAlpha.700',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'alpha.900',
  borderBottom: '1px dotted',
  borderColor: 'whiteAlpha.300',
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'white',
};