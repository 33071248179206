import React, { FC, useState, useMemo, useEffect } from 'react';

import Grid from '../Grid/Grid';
import LoadingSpinner from '../LoadingSpinner';

import {
  TabsContentContainer,
  TabsContentWrapper,
} from './styles/TabbedDisplay.styles';

import { TabbedDisplayProps } from './types';

const TabbedDisplay: FC<TabbedDisplayProps> = ({
  tabs,
  content,
  preContent = <></>,
  layout,
  initTab = tabs?.[0],
  loading = false,
  resultsShowing,
}) => {
  const [selectedTab, setSelectedTab] = useState(initTab);

  // update selectedTab with init tab on first render
  // or when initTab changes due to api data based tab headers
  useEffect(() => {
    setSelectedTab(initTab);
  }, [initTab]);

  const tabContent = useMemo(
    () => content[selectedTab],
    [selectedTab, content]
  );

  const tabLayout = useMemo(() => {
    switch (layout) {
      case 'fullWidth':
      default:
        return (
          <Grid column sm={12} md={12} lg={12} width="100%">
            {preContent}
            {loading ? <LoadingSpinner /> : tabContent}
          </Grid>
        );
    }
  }, [layout, tabContent, preContent, loading]);

  return (
    <TabsContentWrapper>
      <TabsContentContainer resultsShowing={resultsShowing}>
        <Grid row>{tabLayout}</Grid>
      </TabsContentContainer>
    </TabsContentWrapper>
  );
};

export default TabbedDisplay;
