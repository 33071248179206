import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.500',
  borderRadius: 'unset',
  px: '0',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  _hover: {
    bg: 'blackAlpha.100',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'white',
  mx: ['2.5', '4'],
  borderRadius: 'base',
  mb: '4',
};

export const Icon: CSSObject = {
  color: 'gamma.800',
  bg: 'gamma.500',
  borderRadius: 'base',
};

export const TextRace: CSSObject = {
  color: 'gray.700',
  fontWeight: 'medium',
};

export const TextNumber: CSSObject = {
  bg: 'black',
  color: 'beta.500',
  h: '5',
  py: '0.5',
  pt: '1',
};

export const BoxHeading: CSSObject = {
  display: 'none',
};

export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    color: ['white', 'beta.900'],
  },
};
