import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '0',
  pb: '0',
  '&&': {
    pt: '2.5',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  bg: 'beta.400',
  color: 'alpha.700',
  fontSize: 'sm',
  mb: '1.5',
  '&&': {
    h: '34px',
    mt: -1,
  },
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'md',
  bg: 'blackAlpha.400',
  boxShadow: '0 1px 0 0 #5e5f6e, inset 0 1px 4px 0 #0000004c',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'whiteAlpha.200',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'beta.400',
  fontWeight: 'extrabold',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'beta.400',
  fontWeight: 'extrabold',
};

export const InputBetSlip: CSSObject = {
  marginTop: '-0.5',
  borderWidth: '2px',
  '&&': {
    '&&': {
      borderColor: 'beta.400',
      bg: 'alpha.800',
    },
  },
  color: 'white',
  pl: '2.75rem',
  borderRadius: 'md',
  fontSize: 'sm',
  ':first-child': {
    pl: '3',
  },
  _hover: {
    '&&': {
      bg: 'alpha.600',
      borderColor: 'beta.400',
    },
    '.chakra-input__left-element': {
      bg: 'epsilon.300',
    },
  },
  _focus: {
    borderColor: 'alpha.500',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.3)',
    '&&': {
      background: 'epsilon.300',
    },
    '&::placeholder': { color: 'white', opacity: '0.8' },
  },
  '&::placeholder': { color: 'white', opacity: '0.5' },
};

export const FlexiReview: CSSObject = {
  pl: '1',
};

export const TreasureChestIcon: CSSObject = {
  color: 'alpha.800',
};
