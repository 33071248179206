import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  p: '0',
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  color: 'alpha.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.40)',
  fontWeight: 'black',
  fontSize: 'sm',
  ...(isPrefix
    ? {
        borderLeftRadius: 'md',
        borderRightRadius: 'none',
      }
    : {
        borderLeftRadius: 'none',
        borderRightRadius: 'md',
      }),
});

export const InputBetSlip: CSSObject = {
  borderColor: '#FFE6B6',
  border: '2px solid',
  pl: '10',
  color: 'white',
  borderRadius: 'md',
  bg: 'alpha.500',
  pos: 'relative',
  ':first-child': {
    pl: '2',
    '&&': {
      borderLeftColor: 'beta.400',
    },
  },
  _hover: {
    '&&': {
      bg: 'alpha.500',
    },
  },
  _focus: {
    borderColor: 'white',
    '&&': {
      bg: 'alpha.500',
    },
  },
  '&::placeholder': { color: 'white', opacity: '0.3' },
  '&&': {
    borderLeftColor: '#FFE6B6',
    borderLeft: '2px',
    bg: 'alpha.700',
  },
};

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'md',
  borderBottomRadius: 'none',
  bg: 'blackAlpha.400',
  textTransform: 'uppercase',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.30) inset',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'alpha.600',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'beta.300',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
};

export const ReviewEst: CSSObject = {
  fontSize: 'md',
  color: 'beta.300',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
};

export const FlexiReview: CSSObject = {
  pl: '0',
};

export const TreasureChestIcon: CSSObject = {
  color: 'beta.400',
};
