import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  mt: ['2', '4'],
  mb: '2',
  pt: '4',
  pb: '0',
  px: '2',
  mx: '0',
  position: 'relative',
  width: 'full',
  background:
    'linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%)',
  borderRadius: 'lg',
  boxShadow: '0px 3px 6px 0px  var(--bc-colors-blackAlpha-500)',
};

export const BoxWrapper: CSSObject = {
  p: '0',
};

export const TextHeading: CSSObject = {
  color: 'beta.800',
  fontFamily: 'heading',
  fontSize: 'lg',
  fontStyle: 'normal',
  fontWeight: 'normal',
  zIndex: 'docked',
  pos: 'relative',
  mb: '3',
};

export const ButtonViewMore: CSSObject = {
  borderTopWidth: '0',
  bgGradient: 'linear(to-b, beta.700, beta.800)',
  color: 'beta.500',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-20)',
  textTransform: 'uppercase',
  fontSize: 'md',
  fontFamily: 'accent',
  borderRadius: 'md',
  letterSpacing: 'wide',
  fontWeight: 'hairline',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  my: '2.5',
  py: '2.5',
  _hover: {
    bgGradient: 'linear(to-b, delta.400, delta.500)',
    color: 'white',
  },
  _focus: {
    bgGradient: 'linear(to-b, beta.900, beta.900)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  _disabled: {
    bg: 'gray.400',
    borderColor: 'gray.600',
    color: 'gray.200',
    textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
};
