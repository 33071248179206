import { CSSObject } from '@chakra-ui/react';

export const ExoticsWrapper: CSSObject = {
  p: '2',
};

export const ExoticsContainer: CSSObject = {
  p: '2',
  border: '1px',
  borderColor: 'gray.200',
  bg: 'gray.50',
  borderRadius: 'base',
};

export const CheckboxBoxed: CSSObject = {
  color: 'gray.700',
  right: 0,
  justifyContent: 'flex-end',
  mr: '3',
};

export const TextBoxed: CSSObject = {
  color: 'gray.600',
  fontSize: 'sm',
};

export const SelectFieldContainer: CSSObject = {
  w: 'unset',
};
