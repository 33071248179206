import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  maxW: ['none', 'lg'],
  '.chakra-checkbox__control': {
    bg: 'white',
    border: '0',
    boxShadow: 'inset 0 2px 4px rgba(49, 57, 77, .82)',
    _focus: {
      boxShadow: 'inset 0 2px 4px rgba(49, 57, 77, .82)',
    },
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _checked: {
      bg: 'alpha.600',
      boxShadow: 'none',
      _hover: {
        bg: 'alpha.400',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
  '.chakra-radio__input + span': {
    bg: 'alpha.50',
    border: '0',
    width: '1rem',
    height: '1rem',
  },
  '.chakra-radio': {
    _checked: {
      '.chakra-radio__input + span': {
        border: '5px',
        borderColor: 'alpha.50',
        boxShadow: 'none',
        _before: {
          color: 'alpha.600',
        },
      },
    },
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__left-addon': {
    bg: 'beta.500',
    borderLeftRadius: 'md',
    color: 'beta.900',
    fontWeight: 'semibold',
    fontSize: 'sm',
    h: '9',
  },
  '.chakra-input': {
    border: '1px',
    borderColor: 'gray.300',
    bg: 'white',
    h: '9',
    fontWeight: 'semibold',
    borderRadius: 'md',
    _hover: {
      bg: 'gray.50',
      color: 'white',
    },
    _active: {
      bg: 'gray.100',
      color: 'white',
    },
  },
  '.chakra-input__group .chakra-input': {
    borderLeftRadius: 'none',
    borderRightRadius: 'md',
    fontSize: 'sm',
  },
  '.chakra-select': {
    border: '1px',
    borderColor: 'gray.400',
    bg: 'gray.50',
    h: '9',
  },
  '.chakra-form__error-message': {
    color: 'red.300',
    fontSize: 'xs',
    mb: 2,
  },
  '.chakra-form__helper-text': {
    color: 'delta.100',
    fontSize: 'xs',
  },
};
