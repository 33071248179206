import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiPostRequest } from '../api';

import { AppDispatch } from '../../../redux/store';
import { RecordUserActionPayload, RecordResetPasswordPayload } from './types';

export const recordUserAction = createAsyncThunk<
  void,
  RecordUserActionPayload,
  { dispatch: AppDispatch }
>('bookie/recordAction', async (payload) => {
  const response = await apiPostRequest<void, RecordUserActionPayload>(
    '/punter/account/record-action',
    payload
  );

  return response;
});

export const recordResetPasswordAction = createAsyncThunk<
  void,
  RecordResetPasswordPayload,
  { dispatch: AppDispatch }
>('bookie/recordResetPassword', async (payload) => {
  const response = await apiPostRequest<void, RecordResetPasswordPayload>(
    '/punter/account/record-reset-password',
    payload
  );

  return response;
});
