import { TextProps, CSSObject } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};

export const FormControlGroup: CSSObject = {
  color: 'alpha.700',
};
