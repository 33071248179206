import { CSSObject } from '@chakra-ui/react';

export const GridItemBetslip: CSSObject = {
  h: '100%',
  '& > div[class*="-FlexBetSlipModalContainer"]': {
    position: [null, null, null, null, 'sticky'],
    top: [null, null, null, null, '60px'],
    height: [null, null, null, null, 'calc(100vh - 145px)'],
  },
};
export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
  maxWidth: ['inherit', '272px'],
};
