import { Box, chakra, Text } from '@chakra-ui/react';
import { styleImports } from './Header.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxHeaderText = chakra(Text, {
  label: 'header-BoxHeaderText',
  baseStyle: () => ({
    fontSize: 'lg',
    textTransform: 'capitalize',
    fontWeight: 'semibold',
    color: 'beta.700',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeaderText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxHeader = chakra(Box, {
  label: 'header-BoxHeader',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
