import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: 'beta.500',

      background: {
        bg: ['beta.500', null, null, `rgba(78, 178, 255, 0.14)`],
        bgRepeat: ['no-repeat', null, null, 'unset'],
        bgSize: ['cover', null, null, 'unset'],
        borderRadius: 'lg',
        px: ['0', null, null, '3'],
        py: ['0', null, null, '2'],
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: '18px',
        mr: '2',
        filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
        color: 'alpha.400',
      },
      headerSX: {
        cursor: 'default',
        color: 'alpha.600',
        fontSize: 'md',
        height: '34px',
        '&:hover': {
          bg: 'transparent',
          color: 'alpha.600',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,
        textTransform: 'uppercase',
        fontSize: 'md',
        fontWeight: 'normal',
        height: '34px',
        color: 'white',
        fontFamily: 'accent',
        transition: 'all, 0.2s linear',
        borderBottom: ['1px solid', null, null, 'unset'],
        boxShadow: [
          '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
          null,
          null,
          'unset',
        ],
        borderBottomColor: ['blackAlpha.200', null, null, 'unset'],

        ':first-child': {
          borderTop: 'none',
          color: 'alpha.400',
          textShadow: '0px 0px 10px #FFBB5E66',
        },
        // ':last-child': {
        //   borderBottom: ['1px solid', null, null, 'unset'],
        //   boxShadow: [
        //     '0px 2px 0px 0px rgba(255, 255, 255, 0.07)',
        //     null,
        //     null,
        //     'unset',
        //   ],
        //   borderBottomColor: ['blackAlpha.100', null, null, 'unset'],
        // },

        py: '3',
        px: ['3', null, null, '2'],
        ml: '0',

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          bg: 'blackAlpha.300',
          color: 'alpha.400',
        },

        ':active': {
          bg: 'blackAlpha.300',
          color: 'alpha.400',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        borderBottom: ['1px solid', null, null, 'unset'],
        boxShadow: [
          '0px 1px 0px 0px rgba(255, 255, 255, 0.02)',
          null,
          null,
          'unset',
        ],
        borderBottomColor: ['blackAlpha.200', null, null, 'unset'],
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
        },

        '> a, .dropDownIcon, a': {
          color: 'alpha.400',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '34px',
            px: ['3', null, null, '2'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        h: '34px',
        p: '0',

        '.dropDownInner': {
          px: '2.5',
          background: 'blackAlpha.300',
          color: 'beta.100',
          textShadow: '0px 0px 8px rgba(78, 178, 255, 0.5)',
          borderLeftWidth: '3px',
          borderLeftColor: 'beta.100',
          'svg:not(.dropDownIcon)': {
            fill: 'beta.100',
            filter: 'drop-shadow(0px 0px 8px rgba(78, 178, 255, 0.5))',
          },
          '&:hover, &:active': {
            '.dropDownInner': {
              color: 'beta.100',
            },
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'beta.100',
          filter: 'drop-shadow(0px 0px 8px rgba(78, 178, 255, 0.5))',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
