import Black from '@/assets/juicybet/fonts/Roboto-Black.ttf';
import BlackItalic from '@/assets/juicybet/fonts/Roboto-BlackItalic.ttf';
import Bold from '@/assets/juicybet/fonts/Roboto-Bold.ttf';
import BoldItalic from '@/assets/juicybet/fonts/Roboto-BoldItalic.ttf';
import ExtraLight from '@/assets/juicybet/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '@/assets/juicybet/fonts/Roboto-ThinItalic.ttf';
import Light from '@/assets/juicybet/fonts/Roboto-Light.ttf';
import LightItalic from '@/assets/juicybet/fonts/Roboto-LightItalic.ttf';
import Regular from '@/assets/juicybet/fonts/Roboto-Regular.ttf';
import Italic from '@/assets/juicybet/fonts/Roboto-Italic.ttf';
import SemiBold from '@/assets/juicybet/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '@/assets/juicybet/fonts/Roboto-MediumItalic.ttf';

import Coiny from '@/assets/juicybet/fonts/Coiny-Regular.ttf';
import Chewy from '@/assets/juicybet/fonts/Chewy-Regular.ttf';

export const fontFace = `
  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${Light}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${LightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Regular}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${Italic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${Bold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${BoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${Black}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${BlackItalic}') format('opentype');
  }

  @font-face {
    font-family: 'Coiny';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Coiny}') format('opentype');;
  }

  @font-face {
    font-family: 'Chewy';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Chewy}') format('opentype');;
  }
`;
