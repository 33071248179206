import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapper: BoxProps = {};
export const BetslipTitleText: CSSObject = {
  color: 'delta.700',
  fontFamily: 'MexicanKnappett',
  fontWeight: 'normal',
  textTransform: 'lowercase',
  fontSize: 'lg',
};
export const BetslipTitle: CSSObject = {
  '& svg': {
    boxSize: '5',
    color: 'delta.700',
  },
};

export const BetLengthIndicator: CSSObject = {
  borderRadius: 'md',
  borderColor: 'delta.700',
  borderWidth: '2px',
  bgGradient: 'linear(180deg, delta.300 22.92%, delta.600 91.67%)',
  color: 'epsilon.300',
  textAlign: 'center',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const CloseIconButton: CSSObject = {
  border: 'none',
  bg: 'transparent',
  boxSize: '6',
  svg: {
    boxSize: '6',
    color: 'delta.700',
    borderRadius: 'full',
    bg: 'transparent',
    border: 'none',
  },
  _hover: {
    bg: 'transparent',
  },
};
export const FlexBetslipHeading: CSSObject = {
  paddingBottom: '13',
  borderBottomColor: 'delta.700',
  borderBottom: '2px',
};
