import { CSSObject } from '@chakra-ui/react';
import { THeaderContainer } from './Header.styles';

export const LinkMarket: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  transition: 'background .2s ease-in-out',
  color: 'blue.500',
  '&:hover, &:active': { color: 'beta.600' },
};

export const HeaderContainer = ({ isClosed }: THeaderContainer): CSSObject => ({
  w: 'fill-available',
  mt: '2',
  pb: '2',
  px: '2',
  color: 'beta.500',
  justifyContent: 'start',
  borderBottom: '2px dashed',
  borderColor: 'gray.200',
  position: isClosed ? 'inherit' : 'relative',
  // adds left/right 'cutouts' to dashed line
  '&::before, &::after': {
    position: 'absolute',
    height: '11px',
    width: '17px',
    background: 'beta.700',
    content: `""`,
    borderRadius: 'full',
    top: isClosed
      ? ['32%', null, null, null, null]
      : ['91%', null, null, null, '90%'],
  },
  '&::before': {
    left: '-3',
  },
  '&::after': {
    right: '-3',
  },
});
