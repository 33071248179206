import React, { FC } from 'react';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { LoginSchema } from '../../services/schemas/loginSchema';
import LoginPageProps from './types';
import { useSignIn } from '../../../../hooks/useSignIn';
import { createTemplate } from '@/helpers/createTemplate';
import template from './templates/template';

const LoginPage: FC<LoginPageProps> = () => {
  const { handleSignIn } = useSignIn();
  const intl = useIntl();

  const handleFormSubmit = async (
    values: LoginSchema,
    { setSubmitting }: FormikHelpers<LoginSchema>
  ) => {
    setSubmitting(true);
    await handleSignIn(values);
    setSubmitting(false);
  };

  return <>{createTemplate(template(intl, handleFormSubmit))}</>;
};

export default LoginPage;
