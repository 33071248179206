import { useAppSelector } from '../../../hooks/useRedux';
import { useNTJ } from '../../../hooks/useNTJ';
import { ELocale } from '../../../redux/ntjFilters.slice';

export const useNTJBanner = () => {
  const { activeLocaleFilters, activeRaceFilters } = useAppSelector(
    (state) => state.ntjFilters
  );

  const { dataSorted } = useNTJ();

  const ntjFiltered = dataSorted
    .filter((race) => {
      if (race?.venue_country === '') return true;
      if (activeLocaleFilters.length === 2) return true;

      if (activeLocaleFilters.includes(ELocale.Aus)) {
        return race?.venue_country === 'AUS' || race?.venue_country === 'NZL';
      }

      if (activeLocaleFilters.includes(ELocale.Intl)) {
        return race?.venue_country !== 'AUS' && race?.venue_country !== 'NZL';
      }

      return true;
    })
    .filter((race) => {
      if (race?.race_type && activeRaceFilters.includes(race?.race_type)) {
        return true;
      }

      return false;
    });

  return {
    ntj: ntjFiltered,
    isLoading: false,
  };
};
