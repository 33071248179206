import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { styleImports } from './RaceRunnerForm.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxWrapper = chakra(Box, {
  baseStyle: () => ({
    label: 'raceRunnerForm-BoxWrapper',
    mb: '0',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexWrapper = chakra(Flex, {
  baseStyle: () => ({
    label: 'raceRunnerForm-FlexWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextFormInfo = chakra(Text, {
  baseStyle: () => ({
    label: 'raceRunnerForm-TextFormInfo',
    fontSize: '2xs',
    mr: '2',

    ...(() => {
      try {
        return styleImports[themeName]?.TextFormInfo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
