import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';

export const betCardErrorFlexProps: CSSObject = {
  borderTopRadius: 'md',
};

export const BetCardVStack: CSSObject = {
  pb: '2',
};

export const BetCardContainer = (): CSSObject => ({
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: 'md',
  border: '2px',
  color: 'alpha.50',
  mb: '-2.5',
  '.state-prior': { color: 'white' },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'alpha.50',
  transition: 'all .2s ease-in-out',
  _hover: {
    // TODO: Update to use theme token
    color: '#4895ac',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'alpha.50',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'alpha.50',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'alpha.50',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'alpha.50',
  },
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, white 0%, gray.300 6.25%, white 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
  mt: '-2',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeButton: CSSObject = {
  h: '35px',
  fontSize: 'xs',
  borderRadius: '7px',
  _first: {
    '&&': {
      borderStartRadius: '7px',
    },
  },
  _last: {
    borderEndRadius: '7px',
  },
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'alpha.700',
  boxShadow: 'md',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BonusBetsButton = (isBonusBet: boolean) => ({
  bgGradient: isBonusBet
    ? 'linear(to-b, beta.900, beta.900)'
    : 'linear(to-b, beta.400, beta.600)',
  boxShadow: isBonusBet
    ? '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset'
    : '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
  color: isBonusBet ? 'white' : 'beta.900',
  borderRadius: 'md',
  border: isBonusBet ? '1px' : 'none',
  borderColor: 'beta.400',
  fontWeight: 'medium',
  fontSize: '2xs',
  h: '6',
  px: '1.5',
  textShadow: 'none',

  '&&': {
    minW: '20',
  },

  '&:hover, &:focus': {
    bgGradient: isBonusBet
      ? 'linear(to-b, beta.900, beta.900)'
      : 'linear(to-b, beta.400, beta.500)',
    boxShadow: 'none',
    border: isBonusBet ? '1px' : 'none',
    borderColor: 'beta.400',
  },

  _active: {
    boxShadow: 'none',
  },

  _disabled: {
    boxShadow: 'none',
    bg: 'blackAlpha.300',
    color: 'alpha.100',
    textTransform: 'uppercase',
    h: '7',
    opacity: '1',
    border: 'none',
    textShadow: 'none',
  },
});

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'alpha.50' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const EventRule: CSSObject = {
  color: 'white',
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'whiteAlpha.700',
  borderRadius: 'base',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};
