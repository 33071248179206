import React from 'react';
import { Form, Formik } from 'formik';
import { ChevronSmallLeft } from '@styled-icons/entypo';

import { Stack } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Subheading,
  SubheadingWrapper,
  HighlightSpan,
  Heading,
  TextDescription,
  InputWrapperWithdrawal,
  DividerWithdrawal,
  InputWithdrawal,
  BackButton,
  buttonAddAccountProps,
} from '../styles/Withdrawal.styles';
import addAccountSchema from '../../services/schemas/addAccountSchema';
import { postNewBankAccount } from '../../services/account.actions';
import { setBankAccounts } from '../../services/account.slices';
import { TBankAccountFormData } from '../../account.types';
import { Button } from '@/components/Button/Button';
import { useLiveChat } from '@/hooks/useLivechat';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

const AddAccount: React.FC<AddAccountProps> = ({ closeAddAccount }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { banks } = useAppSelector((state) => state.punter);
  const { showLiveChat } = useLiveChat();

  const initialValues: TBankAccountFormData = {
    account_name: '',
    bsb: '',
    account_number: '',
    account_nick_name: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (formValues, { setSubmitting }) => {
        setSubmitting(true);
        const bankAccount = await dispatch(
          postNewBankAccount(formValues)
        ).unwrap();

        dispatch(setBankAccounts([bankAccount, ...banks]));
        closeAddAccount();
        setSubmitting(false);
      }}
      validationSchema={addAccountSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <Heading>
            {!!banks.length && (
              <BackButton
                aria-label="back"
                icon={<ChevronSmallLeft />}
                onClick={closeAddAccount}
                variant="ghost"
              />
            )}
            <FormattedMessage id="account.withdraw.addaccount.makeawithdrawal" />
          </Heading>

          <TextDescription>
            <FormattedMessage id="account.withdraw.addaccount.withdrawaldescription" />
          </TextDescription>

          <DividerWithdrawal />

          <InputWrapperWithdrawal>
            <InputWithdrawal
              label={intl.formatMessage({
                id: 'account.withdraw.addaccount.holdernamelabel',
              })}
              name="account_name"
              helperText={intl.formatMessage({
                id: 'account.withdraw.addaccount.holdernamesubfooter',
              })}
              allowInvalidKeys
            />

            <Stack spacing="2" direction="row">
              <InputWithdrawal
                label={intl.formatMessage({
                  id: 'account.withdraw.addaccount.bsblabel',
                })}
                name="bsb"
                sxWrapper={{ flex: 1 }}
                allowInvalidKeys
              />

              <InputWithdrawal
                label={intl.formatMessage({
                  id: 'account.withdraw.addaccount.accnumberlabel',
                })}
                name="account_number"
                sxWrapper={{ flex: 2 }}
                allowInvalidKeys
              />
            </Stack>

            <InputWithdrawal
              label={intl.formatMessage({
                id: 'account.withdraw.addaccount.nicknamelabel',
              })}
              name="account_nick_name"
              helperText={intl.formatMessage({
                id: 'account.withdraw.addaccount.nicknamesubfooter',
              })}
              allowInvalidKeys
            />
          </InputWrapperWithdrawal>

          <Button
            type="submit"
            isFullWidth
            isLoading={isSubmitting}
            {...buttonAddAccountProps}
          >
            <FormattedMessage id="account.withdraw.addaccount.addaccountbutton" />
          </Button>

          <SubheadingWrapper>
            <Subheading>
              <FormattedMessage id="account.withdraw.addaccount.addaccountsubfooter" />
              <HighlightSpan onClick={() => showLiveChat()}>
                <FormattedMessage id="account.withdraw.addaccount.contactus" />
              </HighlightSpan>
            </Subheading>
          </SubheadingWrapper>
        </Form>
      )}
    </Formik>
  );
};

interface AddAccountProps {
  closeAddAccount: () => void;
}

export default AddAccount;
