/* eslint-disable @typescript-eslint/no-use-before-define */
import { GridItem, Icon } from '@chakra-ui/react';
import React from 'react';
import { BackspaceOutline as IconDelete } from '@styled-icons/evaicons-outline';
import { ArrowCircleDown as IconBet } from '@styled-icons/heroicons-outline';
import { DownArrow, UpArrow } from '@styled-icons/boxicons-solid';
import { FormattedMessage } from 'react-intl';
import {
  FlexKeypadWrapper,
  FlexTopBtnWrapper,
  GridWrapper,
  keypadStyles,
} from './styles/Keypad.styles';
import { ButtonKeysSwitched as BtnKeys } from './components/ButtonKeysSwitched';
import { Button } from '@/components/Button/Button';

type OnClickProps = (btn: string) => void;

// Container ----

type KeyboardContainerProps = Partial<
  Pick<KeyboardProps, 'isSubmitDisabled' | 'onClick'>
>;

export default function KeyboardContainer({
  isSubmitDisabled,
  onClick,
}: KeyboardContainerProps) {
  const props = useKeyboard({ onClick });
  return (
    <KeyboardView isSubmitDisabled={isSubmitDisabled ?? false} {...props} />
  );
}

// View ----

type KeyboardProps = {
  btns: string[];
  topBtns: string[];
  btnMap: Record<string | number, string>;
  isSubmitDisabled: boolean;
  onClick: OnClickProps;
};

function KeyboardView({
  btns,
  topBtns,
  btnMap,
  isSubmitDisabled,
  onClick,
}: KeyboardProps) {
  return (
    <FlexKeypadWrapper>
      <FlexTopBtnWrapper>
        {topBtns.map((btn) => (
          <Button
            key={`keyboard-nav-${btn}`}
            onClick={() => onClick(btn)}
            {...keypadStyles.buttonActionProps}
          >
            {['next', 'prev'].includes(btn) ? (
              <Icon
                key={`icon-${btn}`}
                as={btn === 'next' ? DownArrow : UpArrow}
              />
            ) : (
              <>
                <Icon as={DownArrow} />
                <FormattedMessage id="generic.hide" />
              </>
            )}
          </Button>
        ))}
      </FlexTopBtnWrapper>

      <GridWrapper>
        {btns.map((btn) => (
          <GridItem key={`keyboard-${btn}`} gridArea={btnMap[btn]}>
            <BtnKeys
              data-testid={`keypadButton-${btn}`}
              data-cy={`keypadButton${btn}`}
              variant="unstyled"
              onClick={() => onClick(btn)}
              isBatch={['20', '50', '100'].includes(btn)}
              key={`keyboard-${btn}`}
              w="full"
              {...(btn === 'submit' && {
                disabled: isSubmitDisabled,
              })}
            >
              {(() => {
                if (btn === '-') {
                  return <Icon boxSize="6" as={IconDelete} />;
                }
                if (btn === 'submit') {
                  return (
                    <>
                      <Icon boxSize="4" as={IconBet} mr="0.5" />
                      <FormattedMessage id="generic.bet" />
                    </>
                  );
                }
                if (!Number.isNaN(Number(btn)) && Number(btn) > 9) {
                  return `+${btn}`;
                }
                return btn;
              })()}
            </BtnKeys>
          </GridItem>
        ))}
      </GridWrapper>
    </FlexKeypadWrapper>
  );
}

// Controller ----

type UseKeyboardProps = {
  onClick?: OnClickProps;
};

const useKeyboard = ({ onClick }: UseKeyboardProps = {}) => {
  const topBtns = ['next', 'prev', 'hide'];
  const btnMap = {
    0: 'zero',
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    20: 'twenty',
    50: 'fifty',
    100: 'hundred',
    '.': 'decimal',
    '-': 'minus',
    submit: 'submit',
  };

  return {
    btns: Object.keys(btnMap),
    topBtns,
    btnMap,
    onClick: (btn: string) => onClick?.(btn),
  };
};
