import { UpcomingMatchesSchema } from './UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  stackWrapper: {
    px: ['2.5', null, null, '3'],
    color: 'white',
  },
  pageHeader: {
    fontSize: '2xl',
    textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
    fontFamily: 'CreteRound',
    color: 'epsilon.100',
    textTransform: 'capitalize',
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'epsilon.100',
  },
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'gamma.400',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'lg',
    gap: '1',
    color: 'epsilon.100',
    fontWeight: 'normal',
    textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
    fontFamily: 'CreteRound',
    textTransform: 'capitalize',
  },
  headingIcon: {
    color: 'alpha.100',
  },
  propositionButton: {
    flex: '1',
    height: '38px',
    display: 'initial',
    p: '0',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  boxSportWrapper: {
    border: '1px',
    borderColor: 'alpha.600',
  },
};
