import { BoxProps, LinkProps, CSSObject } from '@chakra-ui/react';

export const linkLoginProps: LinkProps = {
  color: 'alpha.600',
  fontSize: '12px',
  fontWeight: 500,
  mb: '4',
  display: 'inline-flex',
  alignItems: 'center',

  sx: {
    svg: {
      boxSize: '10px',
      mr: '1',
      mt: '-1px',
    },
  },
};

export const wrapperProps: BoxProps = {
  justifyContent: ['null', null, 'flex-start'],

  sx: {
    'div:first-child': {
      mt: ['0', '0', '145px'],
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'gray.500',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  mb: '2',

  '+ a': {
    color: '#718096',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    mx: 0,
    display: 'inline',
  },
};

export const ForgotPasswordButton: CSSObject = {
  bg: '#FD62FF',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.45), 0px 0px 0px 2px rgba(254, 153, 255, 0.70) inset',
  fontSize: '13px',
  fontWeight: '400',
  pb: '1',
  fontFamily: 'Sansita One',
  borderColor: 'white',
  color: 'beta.700',
  h: '36px',
  _hover: {
    opacity: '0.4',
  },
  _active: {
    bg: 'alpha.800',
  },
};
