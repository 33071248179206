import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { CheckCircleOutline } from '@styled-icons/material-rounded/CheckCircleOutline';
import { Stop } from '@styled-icons/octicons/Stop';

export const BetApprovalContainer: CSSObject = {
  px: '0',
  pb: '0',
};

export const EstReturnsLabel: CSSObject = {
  bg: 'alpha.600',
  color: 'alpha.900',
  fontWeight: 'extrabold',
  height: '100%',
  alignItems: 'center',
  py: '0,5',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  borderRadius: 'base',
  w: 'full',
  color: 'black',
  justifyContent: 'center',
  h: '44px',
  boxShadow:
    '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
};

export const ApprovedBetContainer: CSSObject = {
  background: 'green.400',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2);',
  borderRadius: 'md',
  w: 'full',
  color: 'black',
  justifyContent: 'center',
};

export const RejectedBetContainer: CSSObject = {
  background: 'red.400',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2);',
  borderRadius: 'md',
  w: 'full',
  color: 'red.900',
  justifyContent: 'center',
};

export const FooterContainer: CSSObject = {
  bg: 'white',
  color: 'beta.600',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'md',
};

export const FooterText: CSSObject = {
  px: '1',
  fontWeight: 'medium',
};

export const BetApprovalText: CSSObject = {
  fontFamily: 'roboto',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: '#1c4532', // TODO: not in theme
  textShadow: '0 1px 1px rgba(255, 255, 255, 0.35)',
};

export const BetPendingApprovalText: CSSObject = {
  fontFamily: 'CrimsonPro',
  fontWeight: 'black',
  fontSize: 'md',
  color: 'blackAlpha.800',
  textShadow: '0 1px 1px rgba(255, 255, 255, 0.35)',
  textTransform: 'uppercase',
};

export const IconApprovedBet = () => (
  <Icon as={CheckCircleOutline} boxSize="5" mr="1" color="green.900" />
);

export const IconRejectedBet = () => (
  <Icon as={Stop} boxSize="4" mr="1.5" color="red.900" />
);

export const BetRejectedText: CSSObject = {
  fontFamily: 'roboto',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'red.900',
  textShadow: '0 1px 1px rgba(255, 255, 255, 0.35)',
};
