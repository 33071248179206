import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'lg',
  bg: 'alpha.400',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {
  color: 'white',
};

export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    borderLeft: '1px solid',
    borderColor: ['transparent', null, 'whiteAlpha.200'],
    pl: ['0', null, '2'],
  },
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
};

export const TextDeductions: CSSObject = {
  color: 'white',
};

export const TextScratchedInfo: CSSObject = {
  color: 'beta.500',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const ScratchedText: CSSObject = {
  color: 'gray.700',
  fontWeight: 'semibold',
};
