import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import ruby from '@/assets/puntgenie/images/switch/ruby.svg';

const FlexWrapper = chakra(Flex, {
  baseStyle: () => ({
    borderRadius: '2xl',
    contain: 'layout',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, null, 'unset'],
    mb: ['1', null, null, '2.5'],
    gap: '2',

    boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
    bg: 'beta.500',
    border: '3px solid',
    borderColor: 'beta.100',
  }),
});

const TextToggle = chakra(Text, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontSize: 'sm',
    fontFamily: 'CinzelDecorative',
    fontWeight: 'black',
    flex: [1, 'unset'],

    borderRadius: 'full',
    textTransform: 'lowercase',
    letterSpacing: '1px',
    py: '1',
    maxW: '110px',
    textAlign: 'center',
    transition: 'all, 0.2s linear',
    color: 'gamma.500',

    // This is just a placeholder
    ...(isActive && {
      color: 'gamma.500',
    }),
    ...(!isActive && {
      color: 'gamma.500',
    }),
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  baseStyle: () => ({
    bg: 'transparent',
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
    '--switch-track-width': 'calc(var(--bc-sizes-9))',

    '.chakra-switch__thumb': {
      bg: 'transparent',
      bgImage: `url(${ruby})`,
      bgPos: 'center',
      bgSize: 'full',
      bgRepeat: 'no-repeat',
      boxSize: '10',
      mt: '-1.5',
      ml: '-1',
    },
    // TODO: #22519A is not in the theme
    '.chakra-switch__track': {
      boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
      bg: '#22519A',
      pr: '4',
      pl: '-1',
      mb: '1',
    },

    _checked: {
      bg: 'transparent',

      '.chakra-switch__track': {
        boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
        bg: '#22519A',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sport
      </TextToggle>
    </FlexWrapper>
  );
}
