import React from 'react';
import QuickAccessMenuV1 from '../QuickAccessMenu.v1';

export const QuickAccessMenu = [
  {
    name: 'comQuickAccessMenu',
    component: <QuickAccessMenuV1 />,
  },
];

export default QuickAccessMenu;
