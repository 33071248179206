import { CSSObject } from '@chakra-ui/react';

export const EstReturnsLabel: CSSObject = {
  bg: 'alpha.800',
  color: 'white',
  fontWeight: 'bold',
  height: '100%',
  alignItems: 'center',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-yellow-400) 0%, var(--bc-colors-yellow-500) 100%)',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'yellow.900',
  justifyContent: 'center',
};

export const ApprovedBetContainer: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-green-300) 0%, var(--bc-colors-green-400) 100%)',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'green.700',
  justifyContent: 'center',
};

export const RejectedBetContainer: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-red-300) 0%, var(--bc-colors-red-500) 100%)',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'red.900',
  justifyContent: 'center',
};

export const FooterContainer: CSSObject = {
  bg: 'alpha.50',
  color: 'alpha.800',
  px: '0',
  h: '6',
  border: '1px',
  fontSize: '2xs',
  borderColor: 'alpha.800',
  overflow: 'hidden',
  borderRadius: 'base',
  boxShadow: 'inset 0px 0px 5px rgba(12, 30, 90, 0.6)',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
};
