const branding = {
  alpha: {
    50: '#EBEFF9',
    100: '#D8E0F3',
    200: '#B1C1E7',
    300: '#89A1DB',
    400: '#426DD56',
    500: '#3B63C3',
    600: '#2F4F9C',
    700: '#233B75',
    800: '#18284E',
    900: '#0C1427',
  },
  beta: {
    50: '#FEFCF8',
    100: '#FDF9F0',
    200: '#FCF3E1',
    300: '#FAEDD3',
    400: '#F9E7C4',
    500: '#F7E1B5',
    600: '#C6B491',
    700: '#94876D',
    800: '#635A48',
    900: '#312D24',
  },
  gamma: {
    50: '#F2EDEA',
    100: '#E6DCD4',
    200: '#CDB8AA',
    300: '#B3957F',
    400: '#9A7155',
    500: '#814E2A',
    600: '#673E22',
    700: '#4D2F19',
    800: '#341F11',
    900: '#1A1008',
  },
  theta: {
    50: '#F3D247',
    100: '#F0B344',
  },
  delta: {
    50: '#FEF8E9',
    100: '#FCF0D3',
    200: '#FAE1A6',
    300: '#F7D722',
    400: '#F5C44D',
    500: '#F2B521',
    600: '#E7A402',
    700: '#CD9000',
    800: '#61480D',
    900: '#302407',
  },
  epsilon: {
    50: '#E8EAF3',
    100: '#D1D4E6',
    200: '#A3AACD',
    300: '#757FB4',
    400: '#47559B',
    500: '#192A82',
    600: '#142268',
    700: '#0F194E',
    800: '#0A1134',
    900: '#05081A',
  },
  eta: {
    600: '#732822',
    700: '#5B201B',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  theta: branding.theta,
  delta: branding.delta,
  epsilon: branding.epsilon,
  eta: branding.eta,
};
