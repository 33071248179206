import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  borderRadius: 'lg',
  color: 'gray.800',
  fontSize: 'xs',
  fontWeight: 'normal',
  letterSpacing: 'tighter',
  lineHeight: '16px',
  mb: '2',
  p: '2.5',
  bg: 'linear-gradient(180deg, var(--bc-colors-gamma-300) 0%, var(--bc-colors-gamma-500) 100%)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const ButtonGetVerified: CSSObject = {
  border: '2px',
  borderRadius: 'md',
  borderColor: 'white',
  w: '154px',
  ml: 'auto',
  px: '2',
  py: '1.5',
  gap: '1.5',
  bg: 'linear-gradient(180deg, var(--bc-colors-gamma-500) 0%, var(--bc-colors-gamma-600) 100%)',
  boxShadow: ' 0px 2px 5px 0px rgba(0, 0, 0, 0.45)',

  fontSize: 'xs',
  fontWeight: 'normal',
  color: 'gamma.900',
  textTransform: 'uppercase',
  textAlign: 'center',
  textShadow: '1px 1px 0px rgba(255, 255, 255, 0.35)',
  fontFamily: 'Sansita One',
  lineHeight: 'normal',

  _hover: {
    bg: 'gamma.900',
    color: 'gamma.500',
  },

  _active: {
    bg: 'orange.700',
  },
};
