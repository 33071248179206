import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};
