import { BoxProps, FlexProps, TextProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './SRMulti.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type SRMultiStylesSchema = Partial<{
  multiContainer: BoxProps;
  multiRunnerContainer: FlexProps;
  runnerWrapper: FlexProps;
  buttonWrapper: FlexProps;
  buttonLabel: Partial<TextProps>;
  propButton: Partial<CustomButtonProps>;
  columnHeadings: TextProps;
  headingLabel: TextProps;
  headingContainer: TextProps;
  contentContainer: BoxProps;
  runnerList: BoxProps;
  headingStacker: FlexProps;
  oddsButtonsWrapper: FlexProps;
}>;

export const srMultiStyles: SRMultiStylesSchema = {
  oddsButtonsWrapper: {
    'data-testid': 'srMulti-oddsButtonsWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.oddsButtonsWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  multiContainer: {
    'data-testid': 'srMulti-multiContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.multiContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  multiRunnerContainer: {
    'data-testid': 'srMulti-multiRunnerContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.multiRunnerContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonWrapper: {
    'data-testid': 'srMulti-buttonWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.buttonWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonLabel: {
    'data-testid': 'srMulti-buttonLabel',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.buttonLabel;
      } catch (err) {
        return {};
      }
    })(),
  },
  propButton: {
    'data-testid': 'srMulti-propButton',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.propButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  columnHeadings: {
    'data-testid': 'srmMulti-columnHeadings',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.columnHeadings;
      } catch (err) {
        return {};
      }
    })(),
  },
  headingLabel: {
    'data-testid': 'srmMulti-headingLabel',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.headingLabel;
      } catch (err) {
        return {};
      }
    })(),
  },
  headingContainer: {
    'data-testid': 'srmMulti-srmHeadingContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.headingContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  contentContainer: {
    'data-testid': 'srmMulti-srmContentContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.contentContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  runnerList: {
    'data-testid': 'srmMulti-srmRunnerList',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.runnerList;
      } catch (err) {
        return {};
      }
    })(),
  },
  headingStacker: {
    'data-testid': 'srmMulti-headingStacker',
    ...(() => {
      try {
        return styleImports[themeName]?.srMultiStyles?.headingStacker;
      } catch (err) {
        return {};
      }
    })(),
  },
};
