import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.5) inset,  0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    sx: {
      '.chakra-modal__close-btn': {
        color: 'white',
      },
    },
  },
  stackWrapper: {
    bg: 'alpha.700',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  },
  heading: { color: 'white' },
  moneyValues: { color: 'beta.500' },
  cashedOutText: {},
  cashedOutQuestion: { color: 'white' },
  declineButton: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  acceptButton: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  doneButton: {},
};
