import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    boxSize: '4',
    sx: {},
  },
  iconButtonScrollRight: {
    bg: 'linear-gradient(180deg, var(--bc-colors-beta-700) 0%, var(--bc-colors-beta-800) 100%)',
    border: 'none',
    borderRadius: 'md',
    color: 'beta.500',
    textShadow: '0px 2px 4px var(--bc-colors-blackAlpha-400)',
    ml: '0',
    mr: '0',
    h: 'full',
    w: '6',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1.5px var(--bc-colors-beta-900) inset, 0px 0px 0px 2.5px var(--bc-colors-whiteAlpha-500) inset',
    _active: {
      '&:after': {
        backgroundImage: 'none',
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    boxSize: '4',
    sx: {},
  },
  iconButtonScrollLeft: {
    bg: 'linear-gradient(180deg, var(--bc-colors-beta-700) 0%, var(--bc-colors-beta-800) 100%)',
    border: 'none',
    borderRadius: 'md',
    color: 'beta.500',
    textShadow: '0px 2px 4px var(--bc-colors-blackAlpha-400)',
    h: 'full',
    w: '6',
    ml: '0',
    mr: '-2',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1.5px var(--bc-colors-beta-900) inset, 0px 0px 0px 2.5px var(--bc-colors-whiteAlpha-500) inset',
    _active: {
      '&:after': {
        backgroundImage: 'none',
      },
    },
  },
  flexWrapper: {
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 2px var(--bc-colors-blackAlpha-500) inset, 0px 0px 0px 1px var(--bc-colors-beta-900) inset',
  },
  buttonGroupSports: {
    bg: ['beta.800'],
    borderRadius: ['0', 'lg'],
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-300), 0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
    display: 'inline-flex',
    alignItems: 'center',
    flex: '1',
    overflowX: 'auto',
    overflowY: 'hidden',
    pl: '1',
    py: '1',
    spacing: '1',
    whiteSpace: 'nowrap',
    sx: { '::-webkit-scrollbar': { display: 'none' } },
    minH: '8',
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    ml: '1',
    gap: 1,
  },
};
