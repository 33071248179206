import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '../../../../lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';

export const Heading: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
};

export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};

export const TextDescription: CSSObject = {
  fontSize: 'xs',
};

export const BankRadioWrapper: CSSObject = {
  py: '3',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
};

export const TextBankAccountDetail: CSSObject = {
  fontSize: 'xs',
};

export const ListContainer: CSSObject = {
  bg: 'white',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'gray.700',
  fontWeight: 'bold',
};

export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  bg: status === EWithdrawalStatus.Pending && 'yellow.400',
  color: status === EWithdrawalStatus.Pending && 'yellow.800',
  textTransform: 'uppercase',
  fontSize: '2xs',
});

export const WithdrawalWrapper: CSSObject = {
  p: '2.5',
  bg: 'alpha.50',
  borderColor: 'alpha.200',
};

export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
  color: 'gray.700',
};
