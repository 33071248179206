import {
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './StatementCard.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type StatementCardSchema = Partial<{
  buttonDownload: ButtonProps;
}>;

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    'data-testid': 'segmented-buttonDownload',

    ...(styleImports?.[themeName]?.statementCardStyles?.buttonDownload ?? {}),
  },
};

export const FlexInner: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    justifyContent: 'space-between',
    py: '5',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexInner;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextMonth = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    fontWeight: 'medium',

    ...(() => {
      try {
        return styleImports[themeName]?.TextMonth;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;
