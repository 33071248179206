import { CSSObject } from '@chakra-ui/react';

export const OddsText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'extrabold',
  color: 'white',
};
export const MultiCardContainer: CSSObject = {
  borderTop: '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  borderBottom: '1px dashed',
  borderTopColor: 'rgba(255, 255, 255, 0.07)',
  mx: '2.5',
  py: '2',
  px: '0.5',

  _last: {
    borderBottom: 'none',
  },
};
export const OddsMultiCard: CSSObject = {
  fontWeight: 'semibold',
  color: 'white',
  fontSize: 'xs',
};
