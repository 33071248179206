import { useMemo } from 'react';

export const useResponsibleGamblingMessage = () => {
  const messageId = useMemo(() => {
    const idPrefix = 'legal.responsible.tagline';
    const taglines: string[] = Array(6)
      .fill(0)
      .map((_, i) => `${idPrefix}${i + 1}`);
    return taglines[Math.floor(Math.random() * taglines.length)];
  }, []);

  return { messageId };
};
