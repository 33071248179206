import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    justify: 'space-between',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    color: 'brand.700',
    fontWeight: 'medium',
    textTransform: 'capitalize',
  },
  headerIconButton: {
    color: 'brand.700',
    fontWeight: 'bold',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerVsBox: {
    color: 'brand.700',
  },
  bodyStack: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: '2',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: '#ECC94B',
    h: '1.25em',
    ml: '1',
    w: 'auto',
    _before: {
      bg: 'blue',
      color: 'blue',
    },
  },

  scrollButtonGroup: {
    variant: 'filter',
    bg: 'alpha.700',
  },
  toMarketButton: {
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bg: 'white',
    borderRadius: 'md',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    // border: '0',
    // overflow: 'hidden',
    _last: {
      borderBottomWidth: '0',
    },
  },
  accordionButton: {
    bgGradient: 'linear(180deg, brand.600 0%, brand.700 100%)',
    boxShadow: '0px -2px 0px 0px rgba(0, 0, 0, 0.15) inset',
    borderRadius: 'md',
    fontSize: 'xs',
    fontWeight: 'bold',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _hover: {
      bgGradient: 'linear(180deg, brand.600 0%, brand.700 100%)',
    },
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      borderBottomRadius: '0',
      _after: { opacity: '1' },
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
    color: 'white',
    fontSize: 'sx',
    fontWeight: 'bold',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'delta.700',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'black',
  },
  marketLayoutAccordionPanel: {
    borderBottomRadius: 'md',
    bg: 'white',
    p: '0',
  },
  cancelButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    px: '3',
  },
  clearButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    ml: '3',
    px: '3',
  },
  headerSkeletonText: {},
  countdown: {
    sx: {
      textTransform: 'uppercase',
      '&&&.state-prior': {
        p: 0,
        lineHeight: 'initial',
      },
    },
  },
  sgmLogoV2: {
    color: 'white',
    w: '8',
    h: 'auto',
  },
  wrapperScrollSgm: { w: '100%', maxW: '100%', gap: '2', mt: '2' },
  scrollButtonGroupV2: {
    flex: 1,
    position: 'relative',
    minH: '34px',
    bg: 'alpha.700',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    w: '660px',
  },
  accordionItemActive: {
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  },
  toMarketButtonV2: {
    color: 'white',
    fontSize: 'xs',
    textTransform: 'uppercase',
    minW: 'fit-content',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',

    sx: {
      '&&': {
        px: 2,
        ml: 0,
      },

      '&[data-active]': {
        color: 'gamma.500',
        boxShadow: 'none',
        bg: 'transparent',
      },

      '.chakra-button__icon': {
        margin: 0,
      },
    },

    _last: { mr: 2 },
  },
  sgmBtn: {
    bgGradient: 'linear(to-b, alpha.600, alpha.700)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
    sx: {
      svg: {
        color: 'white',
        fill: 'white',
      },
      '&&': {
        _active: {
          bgGradient: 'linear(to-b, alpha.600, alpha.700)',
          boxShadow: 'none',
        },
      },
    },
  },
  sgmBtnActive: {
    sx: {
      svg: {
        color: 'alpha.500',
        fill: 'alpha.500',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
      },

      '&&': {
        border: 'none',
        bg: 'alpha.900',
        borderColor: 'transparent',
      },
    },

    _after: { display: 'none' },
  },
};
