import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  mb: '2',
  bgGradient: 'linear(to-b, alpha.800, alpha.900)',
  borderBottom: '1px solid',
  borderColor: 'whiteAlpha.300',
};
