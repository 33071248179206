import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    bg: 'alpha.700',
    boxShadow:
      '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #0C2549 inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset ',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2xs',
    height: '7',
    textTransform: 'uppercase',
    _hover: {
      bg: 'alpha.900',
      boxShadow:
        '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px #EB9518 inset',
    },
    _focus: {
      bg: 'alpha.700',
    },
  },
};
