import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputLeftAddon: {
    fontWeight: 'bold',
    fontSize: 'sm',
    color: 'beta.900',
    bgGradient:
      'linear(to-b, var(--bc-colors-beta-400), var(--bc-colors-beta-500))',
    h: '38px',
    border: 'none',
    borderLeftTopRadius: 'lg',
    borderLeftBottomRadius: 'lg',
  },
  betCardInput: {
    my: '.5',
    borderRightRadius: 'md',
    border: '2px solid',
    borderColor: 'beta.400',
    borderLeft: 'none',
    borderLeftRadius: 'md',
    bg: 'beta.900',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'sm',
    _hover: {
      bg: 'beta.700',
      boxShadow: '0px 3px 8px 0px var(--bc-colors-blackAlpha-500)',
    },
    _focus: {
      bg: 'beta.700',
      borderColor: 'beta.400',
      boxShadow: '0px 3px 8px 0px var(--bc-colors-blackAlpha-500)',
    },
  },
};
