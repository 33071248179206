import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'outline',
    fontSize: '2xs',
    height: '7',
    textTransform: 'uppercase',
  },
};
