import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { StarFill } from '@styled-icons/bootstrap/StarFill';
import { useSearchParams } from 'react-router-dom';
import {
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import { EBetSlipBetSubmissionType } from '@/components/Betslip/Services/Betslip.types';
import { getEventRule } from '@/components/Betslip/Services/Betslip.utils';
import { getMarketByType } from '@/helpers/utils';
import { useOdds } from '@/hooks/useOdds';
import { useAppSelector } from '@/hooks/useRedux';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  TPriceType,
  TRunner,
} from '@/lib/DBModels';
import {
  buttonRunnerPropositionButtonProps,
  favouriteFlexProps,
  favouriteIconProps,
  favouriteTextProps,
} from './styles/RaceRunnerPropButton.styles';
import { Button } from '@/components/Button/Button';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

type TRaceRunnerPropButtonProps = {
  betType: EBetTypesDisplayNames;
  runner: TRunner;
  isFavourite?: boolean;
  isTote?: boolean;
  onClick?(): void;
  isSelected?: boolean;
  displayPrice?: string;
};

export default function RaceRunnerPropButton({
  betType,
  runner,
  isFavourite,
  isTote,
  onClick,
  isSelected,
  displayPrice,
}: TRaceRunnerPropButtonProps) {
  const { setBet } = getBetSlipStoreActions();

  const { RacingWin } = EBetTypesDisplayNames;
  const key = betType === RacingWin ? 'win' : 'place';
  const proposition = runner[`${key}_proposition`];
  const { raceRunnerList } = useAppSelector(({ racing }) => racing);
  const { singlePropositionInBetSlip, removeSinglePropositionFromBetSlip } =
    useBetSlipManageBets();
  const [searchParams] = useSearchParams();
  const { addRaceRunnerBetSlip } = useSingleBetSlip();
  const { venues, raceMeta, markets } = raceRunnerList;
  const market = getMarketByType(markets ?? [], betType);

  let priceType: TPriceType;

  if (market && !market.price_types?.includes('fixed') && !isTote) {
    priceType = 'starting';
  } else if (market?.price_types?.includes('tote_single_mid') && isTote) {
    priceType = 'tote_single_mid';
  } else if (market?.price_types?.includes('tote_single_best') && isTote) {
    priceType = 'tote_single_best';
  } else {
    priceType = 'fixed';
  }
  const { odds, isEnabled } = useOdds(
    runner,
    betType,
    priceType === 'tote_single_mid' || priceType === 'tote_single_best'
  );

  // Is prop in bet slip?
  const { proposition_id: propId } = proposition ?? {};
  const isInBetSlip = !!singlePropositionInBetSlip(propId, priceType);

  const handleClick = () => {
    const venueId = searchParams.get('venueId');
    const venue = venues.find(({ venue_id }) => venue_id === venueId);
    const appendType = isTote ? `-${priceType}` : '';
    const id =
      key === 'win'
        ? `${runner.win_proposition?.proposition_id}${appendType}` ?? ''
        : `${runner.place_proposition?.proposition_id}${appendType}` ?? '';

    setBet({
      id,
      type: 'Single',
      odds: key === 'win' ? runner.win_odds ?? 0 : runner.place_odds ?? 0,
      propId:
        key === 'win'
          ? runner.win_proposition?.proposition_id ?? ''
          : runner.place_proposition?.proposition_id ?? '',
      priceType,
      betType: key,
      misc: {
        ...runner,
        ...venue,
        ...raceMeta,
        runner,
        race: raceMeta,
        venue,
      },
    });

    if (isInBetSlip) {
      removeSinglePropositionFromBetSlip(
        EBetSlipBetSubmissionType.Single,
        propId,
        priceType
      );
    } else {
      const eventRule = getEventRule(raceMeta.number_of_places);

      addRaceRunnerBetSlip(key, runner, venue, raceMeta, eventRule, priceType);
    }
  };

  const renderOdds = () => {
    if (odds === 'SUS' || !odds) {
      return <FormattedMessage id="racing.raceRunnerItem.suspended" />;
    }

    if (odds === 'SP') {
      return <FormattedMessage id="generic.startingPriceAcronym" />;
    }

    if (odds === 'MD') {
      return <FormattedMessage id="generic.toteAcronym" />;
    }
    if (odds === 'BT') {
      return <FormattedMessage id="generic.bestToteAcronym" />;
    }

    return odds.toFixed(2);
  };

  const isFav = isEnabled ? isFavourite : false;

  let dataCY: string;

  if (priceType === 'tote_single_mid' || priceType === 'tote_single_best') {
    dataCY = betType === RacingWin ? 'selectWinTote' : 'selectPlaceTote';
  } else {
    dataCY = betType === RacingWin ? 'selectWin' : 'selectPlace';
  }

  return (
    <Button
      data-cy={dataCY}
      disabled={!isEnabled}
      isInBetSlip={isInBetSlip}
      onClick={onClick ?? handleClick}
      data-active={isSelected ?? isInBetSlip}
      {...buttonRunnerPropositionButtonProps}
    >
      {isFav && runner.status === EGeneralStatus.Open && isEnabled && (
        <Flex {...favouriteFlexProps}>
          <Icon as={StarFill} {...favouriteIconProps} />
          <Text {...favouriteTextProps}>
            <FormattedMessage id="generic.fav" />
          </Text>
        </Flex>
      )}
      <Text sx={{ pt: isFav ? '10px' : '0' }}>
        {displayPrice ?? renderOdds()}
      </Text>
    </Button>
  );
}
