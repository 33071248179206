import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  color: 'beta.800',
  fontWeight: 'semibold',
  fontSize: 'sm',
};
export const TextSubheading: CSSObject = {
  color: 'beta.800',
  fontSize: 'xs',
  fontWeight: 'medium',
};
export const Subheading: CSSObject = {
  color: 'beta.800',
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const FormDivider: CSSObject = {
  width: 'full',
  mb: '4',
  borderBottom: '1.5px dashed',
  borderBottomColor: 'whiteAlpha.500',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1.5px',
    borderColor: 'blackAlpha.50',
    borderStyle: 'dashed',
  },
};
export const DepositLimitGrid: CSSObject = {
  pb: '20',
};
export const ModalSubheading: CSSObject = {
  color: 'beta.800',
  fontWeight: 'bold',
  p: '0',
  '& >span': {
    fontWeight: 'normal',
  },
};
export const DepositButton: CSSObject = {
  bgGradient: 'linear(to-b, beta.700, beta.800)',
  color: 'beta.500',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'accent',
  borderRadius: 'md',
  letterSpacing: 'wide',
  fontWeight: 'hairline',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  my: '2.5',
  _hover: {
    bgGradient: 'linear(to-b, delta.400, delta.500)',
    color: 'white',
  },
  _focus: {
    bgGradient: 'linear(to-b, beta.900, beta.900)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  _disabled: {
    bg: 'gray.400',
    borderColor: 'gray.600',
    color: 'gray.200',
    textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
};

export const CancelButton: CSSObject = {
  variant: 'secondary',
};
export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};
export const DepositLimitContainer: CSSObject = {
  bg: 'white',
  borderRadius: 'md',
  p: '3',
  color: 'gray.600',
  boxShadow: '0px 4px 10px 0px var(--bc-colors-blackAlpha-500)',
};
export const PendingLimitContainer: CSSObject = {
  border: 'none',
  fontWeight: 'bold',
  color: 'beta.800',
};
export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '#frequency-select': {
    bg: 'gray.50',
    boxShadow:
      '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset, 0px 1px 0px 0px var(--bc-colors-whiteAlpha-200) inset',
    color: 'gray.600',
    fontWeight: 'medium',
    fontFamily: 'body',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'md',
    option: {
      color: 'black',
    },
  },
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'beta.800',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
  },
  '.chakra-input__left-addon': {
    bg: 'beta.400',
    borderWidth: '0',
    borderColor: 'beta.400',
    fontWeight: 'bold',
    fontSize: 'sm',
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px var(--bc-colors-blackAlpha-400), 0px 1px 0px 0px var(--bc-colors-whiteAlpha-400) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '2',
    py: '1',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const ModalContentWrapper: CSSObject = {
  p: '0',
};
export const ModalDivider: CSSObject = {
  display: 'none',
};
export const buttonRemoveProps: CSSObject = {
  borderRadius: 'md',
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-beta-700) 0%, var(--bc-colors-beta-800) 100%)',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  color: 'beta.500',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  _hover: {
    bgGradient:
      'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
    color: 'white',
  },
  _active: {
    bgGradient:
      'linear-gradient(to bottom, var(--bc-colors-blackAlpha-600), var(--bc-colors-blackAlpha-600))',
    boxShadow:
      '0px 2px 0px 0px var(--bc-colors-blackAlpha-500) inset, 0px 1px 0px 0px var(--bc-colors-whiteAlpha-200) inset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      opacity: 0.4,
    },
  },
};
export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  fontWeight: 'normal',
  width: 'full',
  _hover: {
    color: 'beta.800',
    borderColor: 'beta.800',
  },
};
export const buttonConfirmModalProps: ButtonProps = {
  variant: 'odds',
  fontWeight: 'normal',
  fontFamily: 'accent',
};
export const inputProps: Partial<TIndex> = {
  border: '2px solid',
  borderRadius: 'sm',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  _hover: {
    bg: 'beta.700',
  },
  _focus: {
    bg: 'beta.700',
    boxShadow: 'none',
  },
  _placeholder: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'sm',
  },
  sxWrapper: {
    '&&': {
      '.chakra-input': {
        color: 'white',
        fontWeight: 'bold',
        borderRightRadius: 'md',
        border: '2px solid',
        borderColor: 'beta.400',
        borderLeft: 'none',
        borderLeftRadius: '0',
        bg: 'beta.900',
        boxShadow: 'none',
        _hover: {
          bg: 'beta.700',
        },
        _focus: {
          bg: 'beta.700',
          boxShadow: 'none',
        },
        _placeholder: {
          fontWeight: 'bold',
          color: 'white',
          fontSize: 'sm',
        },
      },
    },
  },
};

export const LimitLabel: CSSObject = {
  color: 'gray.600',
  fontWeight: 'bold',
  fontFamily: 'body',
};

export const LimitValue: CSSObject = {
  fontWeight: 'medium',
  fontFamily: 'body',
};
