import { css } from 'styled-components';

// TODO: Remove
export const raceMeetingsTableWrapper = css`
  display: flex;
  flex-direction: row;
  padding: 0 var(--bc-space-5);

  ${({ theme }) => theme.device.tablet} {
    padding: 0 var(--bc-space-10);
  }
  ${({ theme }) => theme.device.laptop} {
    padding: 0;
  }
`;

export const raceMeetingsTableColumn = css`
  display: flex;
  flex-direction: column;
  padding-bottom: 7px;
  width: 50%;

  ${({ theme }) => theme.device.tablet} {
    width: 35%;
  }

  ${({ theme }) => theme.device.laptop} {
    width: 25%;
    max-width: 25%;
  }
`;

export const raceMeetingsTableRowScrollable = css`
  overflow-x: auto;
  padding-bottom: 7px;
  width: 50%;
  max-width: 50%;

  ${({ theme }) => theme.device.tablet} {
    width: 65%;
    max-width: 65%;
  }

  ${({ theme }) => theme.device.laptop} {
    width: 75%;
    max-width: 75%;
  }
`;

// TODO: Remove
export const raceMeetingsTableHeadingContainer = css`
  margin-bottom: var(--bc-space-2);
  background-color: transparent !important;
`;

// TODO: Rmoeve
export const raceMeetingsTableHeading = css`
  flex: 1;
  text-align: center;
  font-style: italic;
  font-size: var(--bc-fontSizes-lg);
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.basePalette.blue};
`;

// TODO: Remove
export const raceMeetingsFixedTableHeading = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-style: italic;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: var(--bc-fontSizes-lg);
  white-space: nowrap;

  ${({ theme }) => theme.device.tablet} {
    /* font-size: var(--bc-fontSizes-lg); */
  }
`;

export const raceMeetingsTableRowIcon = css`
  color: ${({ theme }) => theme.palette.primary};
  margin-right: var(--bc-space-2);
  width: var(--bc-space-5);
  height: var(--bc-space-5);
`;

export const fixedRowIcon = css``;

export const fixedRowTitleContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

// TODO: Remove
export const locationName = css`
  font-size: var(--bc-fontSizes-3xs);
  font-style: italic;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

// TODO: Remove
export const innerColumnWrapper = css`
  flex: 1;
  /* Styles the end of each row in last column to have rounded edges */
  &:last-child > div {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const raceTimeContainer = css`
  justify-content: center;
  align-items: center;
  padding: var(--bc-space-2) var(--bc-space-2);
  font-size: var(--bc-fontSizes-sm);
  color: ${({ theme }) => theme.basePalette.blue};
  cursor: pointer;
`;

export const raceTimeContainerNextToStart = css`
  justify-content: center;
  align-items: center;
  padding: var(--bc-space-2) var(--bc-space-2);
  font-size: var(--bc-fontSizes-sm);
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondary};
  background-color: ${({ theme }) => theme.palette.primary};
  box-shadow: inset 0px 0px 10px 0px ${({ theme }) => theme.palette.white};
  border-radius: 5px;
  cursor: pointer;

  span {
    font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  }
`;

// TODO: Remove
export const raceResults = css`
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  font-weight: 400;
  font-size: var(--bc-fontSizes-sm);
  white-space: nowrap;
`;
