import { CSSObject } from '@chakra-ui/react';
import bgHead from '@/assets/sterlingparker/images/backgrounds/bgHead.png';
import bgMaintenanceMobile from '@/assets/sterlingparker/images/backgrounds/bgMaintenanceMobile.png';
import carbonFiber from '@/assets/sterlingparker/images/backgrounds/carbonFiber.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: 'center',
  minH: '100dvh',
  background: `url(${bgMaintenanceMobile}), url(${carbonFiber})`,
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat, repeat',
  backgroundSize: 'cover, 98px 98px',

  '@media (min-width: 371px)': {
    background: `url(${bgHead}), url(${carbonFiber})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat, repeat',
    backgroundSize: '1700px 566px, 98px 98px',
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'flex-start',
  mt: ['0', null, '140px'],
  mx: 'auto',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.md',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'CalistoMT',
  bgGradient: 'linear(to-b, #ffce70, #cc952b)',
  backgroundClip: 'text',
  fontSize: ['5xl', null, '7xl'],
  lineHeight: '0.96',
  letterSpacing: 'tight',
  mt: ['2', null, '0'],
  mb: ['2', null, '3'],
  pb: '1.5',
};

export const ContentBodyText: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  mb: ['6', '4'],
  w: ['280px', 'auto'],
};

export const TextRefresh: CSSObject = {
  fontSize: ['md', null, 'sm'],
  mb: ['6', '4'],
  w: ['280px', 'auto'],
};

export const buttonRefreshProps: CustomButtonProps = {
  height: '38px',
  width: '101px',
  variant: 'outline',
  borderColor: 'white',
  boxShadow: 'none',
  color: 'white',
  py: '5',

  _hover: {
    borderColor: 'alpha.500',
    boxShadow: 'none',
    color: 'alpha.500',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};
