import {
  headerBaseStyle,
  THeaderTheme,
} from '../../base/customComponents/header';
import { TStyleConfig } from '../../base/types';

export const Header: TStyleConfig<THeaderTheme> = {
  baseStyle: {
    ...headerBaseStyle,
    wrapperContent: {
      ...headerBaseStyle.wrapperContent,
    },
    accountBallance: {
      ...headerBaseStyle.accountBallance,
    },
    accountBallanceVerbiage: {
      ...headerBaseStyle.accountBallanceVerbiage,
    },
    menuIcon: {
      ...headerBaseStyle.menuIcon,
    },
    betslip: {
      ...headerBaseStyle.betslip,
    },
    betslipCount: {
      ...headerBaseStyle.betslipCount,
    },
    betslipLabel: {
      ...headerBaseStyle.betslipLabel,
    },
  },
  sizes: {},
  variants: {},
};
