import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: '5px',
    bgGradient: 'linear(to-b, rgba(252, 210, 94, 1), rgba(226, 176, 34, 1))',
    boxShadow: `0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)`,
    minW: '90px',
    gap: 0,
  },
  btnGroupText: {
    fontSize: 'sm',
    bg: 'alpha.700',
    color: 'gamma.400',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    h: '100%',
    lineHeight: '40px',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
    boxShadow:
      '1px 0px 2px 0px rgba(0, 0, 0, 0.25) inset, -1px 0px 2px 0px rgba(0, 0, 0, 0.25) inset',
    w: '35px',
  },
  btnGroupIcon: {
    color: 'alpha.600',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.35))',
    w: '35px',
  },
  name: {
    color: 'white',
  },
};
export default styles;
