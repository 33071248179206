import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import isToday from 'dayjs/plugin/isToday';

const timerStart = dayjs();
dayjs.extend(isToday);

/**
 * hook to calculate the remaining time & whether to show the countdown or not.
 * has a second param which stops the interval.
 * The interval triggers a rerender.
 */
export const useCountdown = (eventTime: string, hasInterval = true) => {
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const msTillStart = dayjs(eventTime).diff(timerStart).valueOf();
  const minutesRemaining =
    secondsRemaining < 0
      ? Math.ceil(secondsRemaining / 60)
      : Math.floor(secondsRemaining / 60);
  const secondsRemainder = secondsRemaining % 60;

  useEffect(() => {
    const getMS = () => {
      const now = dayjs(); // get current time each interval to maintain accuracy
      const delta = now.diff(timerStart); // time elapsed since timerStart
      const msRemaining = msTillStart - delta;

      setSecondsRemaining(Math.floor(msRemaining / 1000));
    };

    getMS();
    const interval = setInterval(getMS, hasInterval ? 1000 : 10_000_000);

    return () => {
      clearInterval(interval);
    };
  }, [hasInterval, msTillStart]);

  const timerWithDate = (format?: string) => {
    const isEventToday = dayjs(eventTime).isToday();
    const formatter = (() => {
      if (format) return format;
      if (isEventToday) return 'HH:mm';
      return 'DD/MM HH:mm';
    })();

    return dayjs(eventTime).format(formatter);
  };

  return {
    timerWithDate,
    minutesRemaining,
    secondsRemaining: secondsRemainder,
    showCountdown: minutesRemaining < 60 && minutesRemaining > -60,
  };
};
