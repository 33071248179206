import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    py: '3px',
    px: '1px',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
    borderRadius: 'md',
    overflow: 'hidden',
    bgGradient: 'linear-gradient(to-b, beta.600 0%, beta.400 100%)',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    mb: 0,
    mx: '0.5',
    _first: {
      borderTopRadius: 'md',
    },
    _last: {
      mb: '0',
      borderBottomRadius: 'md',
    },
    pos: 'relative',
    _notFirst: {
      borderTopColor: 'rgba(0, 0, 0, 0.25)',
      borderTopStyle: 'dotted',
      borderTopWidth: '1px',
    },
    _before: {
      mx: '1',
      border: 'inherit',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
  },
  accordionHeadingText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
    color: 'gamma.300',
    fontFamily: 'accent',
    fontSize: 'sm',
  },
  accordionIcon: {
    color: 'gamma.300',
  },
  accordionPanel: {
    px: '3px',
    py: 0,
    fontSize: 'xs',
  },
};
