/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import Maintenance from '../../../views/Maintenance/Maintenance';

export const template: TTemplate = {
  view: <Maintenance />,
};

export default template;
