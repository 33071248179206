import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px',
      borderTopColor: 'alpha.500',
      p: '2',

      _first: { borderTop: 0 },
    }),

    ...(isSelected && {
      boxShadow: 'none',
    }),

    ...(!!isSelected && {
      boxShadow: 'none',
    }),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',

    _first: {
      color: 'white',
      fontWeight: 'bold',

      'button:hover &': { color: 'white' },

      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      bgGradient: isSelected
        ? 'linear(to-b, blackAlpha.400, blackAlpha.400)'
        : 'linear(to-b, gamma.500, gamma.600)',
      w: 'full',
      borderWidth: !isSelected && '2px',
      borderColor: isSelected ? 'transparent' : 'gamma.400',
      borderRadius: 'md',
      color: isSelected ? 'beta.500' : 'gamma.900',
      fontWeight: 'bold',
      h: '34px',
      textShadow: isSelected && '1px 1px 0px rgba(0, 0, 0, 0.50)',
      boxShadow: isSelected
        ? '0px 0px 5px 2px rgba(0, 0, 0, 0.30) inset'
        : '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',

      transition: 'all, .3s ease-in-out',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      _hover: {
        opacity: '0.4',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
