import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: {
    spacing: '1',
    pb: '2',
    mb: '2',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderBottom: '1px solid',
    borderBottomColor: 'blackAlpha.300',
  },
  link: {
    display: 'inline-flex',
    borderRadius: 'lg',
    border: '2px',
    h: '6',
    px: '2',
    py: '1',
    fontSize: '2xs',
    borderColor: 'beta.100',
    bgGradient: 'linear(180deg, beta.200, beta.400)',
    boxShadow:
      '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 3px #773053 inset',
    color: 'alpha.500',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'body',
    sx: {
      '&.active': {
        bg: 'alpha.600',
        color: 'alpha.400',
        boxShadow:
          '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px -1px 0px 0px rgba(233, 191, 215, 0.50) inset, 0px -2px 0px 4px rgba(0, 0, 0, 0.10) inset',
        border: 'unset',
        lineHeight: 'normal',
      },
    },
    _hover: {
      borderColor: 'beta.400',
      bg: 'beta.200',
      color: 'beta.400',
    },
  },
};
