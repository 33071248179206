import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyResponsibleGamblingWrapper: CSSObject = {
  mx: '-2',
  mb: '-2',
};

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'start',
  pt: '24',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontStyle: 'normal',
  fontSize: 'lg',
  textAlign: 'center',
  mx: '10',
  color: 'white',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  textAlign: 'center',
  mx: '8',
  mt: '2',
  color: 'white',
};
