import { rest } from 'msw';
import { queryCompetitionsEndpoint } from './competitions';
import { queryCompetitions } from './competitions.mocks';
import { TQueryCompetitionsResponse } from './competitions.types';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

export const queryCompetitionsHandlers = [
  rest.get(`${baseURL}${queryCompetitionsEndpoint}`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json<TQueryCompetitionsResponse>(queryCompetitions)
    )
  ),
];
