import { TConfirmedBet } from '../../../../lib/DBModels';

export const getExoticSelections = (bet: TConfirmedBet) =>
  bet.exotic_selections?.map((el) =>
    el.exotic_combos.sort((a, b) => a.runner_number - b.runner_number)
  );

export const getPromoDetails = (bet: TConfirmedBet) => {
  const oddsBoostToken = bet.promo_tokens?.find(
    (token) => token.token_type === 'odds-boost'
  );
  const promoTextId = oddsBoostToken
    ? 'account.myBetsCard.boostedOdds'
    : undefined;

  const originalOdds = oddsBoostToken?.original_odds;
  const originalPayout =
    oddsBoostToken?.original_odds && bet.stake
      ? oddsBoostToken?.original_odds * Number(bet.stake)
      : undefined;
  return {
    promoTextId,
    originalOdds,
    originalPayout,
    oddsBoostToken,
  };
};
