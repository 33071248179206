import { Box, Collapse, useDisclosure } from '@chakra-ui/react';
import React, { Fragment, useMemo, useState } from 'react';
import { TExtendedProposition } from '../../MatchDetailPage/services/MatchDetailPage.types';
import {
  LIMIT,
  MarketLayoutHeader,
  MarketLayoutPagination,
  MarketLayoutRow,
  MarketLayoutTabPanel,
  MarketLayoutTabs,
  TMarketLayoutProps,
} from '../MarketLayout/MarketLayout';
import PropositionButton from '../PropositionButton/PropositionButton';

export type TTableLayoutProps = Pick<
  TMarketLayoutProps,
  'parts' | 'participants' | 'onSelection'
>;

export default function TableLayout({
  parts: rawParts,
  participants,
  onSelection,
}: TTableLayoutProps) {
  const { isOpen, onToggle } = useDisclosure();
  const parts = useMemo(
    () =>
      rawParts.map((part) => {
        const propositions = part.markets
          .flatMap((m) => m.propositions)
          .filter((p): p is TExtendedProposition => !!p)
          .map((p) => {
            const {
              value1,
              value2,
              team_role: role,
            } = p.proposition_info ?? {};
            const isHome = role?.toUpperCase() === 'HOME';
            const propositionName = isHome
              ? `${value1}-${value2}`
              : `${value2}-${value1}`;

            return { ...p, proposition_name: propositionName };
          })
          .sort((a, b) => (a.proposition_name < b.proposition_name ? -1 : 1));

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const participantMap = participants!.reduce(
          (acc, p) => acc.set(p.name, []),
          new Map()
        );

        const draw: TExtendedProposition[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const prop of propositions) {
          const arr = participantMap.get(prop.proposition_info?.participant);
          if (arr !== undefined) arr.push(prop);
          else if (prop.proposition_info?.team_role === 'Draw') {
            draw.push(prop);
          }
        }
        const byParticipant = [
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...participantMap.entries(),
        ].map(([key, props]) => ({
          participant: key as string,
          propositions: props as TExtendedProposition[],
        }));

        if (draw.length) {
          byParticipant.splice(1, 0, {
            participant: 'Draw',
            propositions: draw,
          });
        }

        const { markets, ...rest } = part;
        return { ...rest, propsByParticipant: byParticipant };
      }),
    [participants, rawParts]
  );

  const [currentIdx, setCurrentIdx] = useState(0);
  const shouldRenderPagination = useMemo(() => {
    const part = parts[currentIdx];
    const maxRowCount = Math.max(
      ...part.propsByParticipant.map(({ propositions }) => propositions.length)
    );
    return maxRowCount > LIMIT;
  }, [parts, currentIdx]);

  return (
    <>
      <MarketLayoutTabs
        labels={parts.map(({ title }) => title)}
        onChange={(i) => setCurrentIdx(i)}
      >
        {parts.map(({ title, propsByParticipant }) => {
          const rowCount = propsByParticipant.length
            ? Math.max(
                ...propsByParticipant.map(
                  (participant) => participant.propositions.length
                )
              )
            : 0;

          return (
            <MarketLayoutTabPanel key={title} p="0">
              {Array(rowCount)
                .fill('')
                .slice(0, LIMIT)
                .map((_, index) => (
                  <Fragment key={index}>
                    {index === 0 && (
                      <MarketLayoutRow isFirst>
                        <MarketLayoutHeader>
                          {participants?.[0].name}
                        </MarketLayoutHeader>

                        {propsByParticipant.length === 3 && (
                          <MarketLayoutHeader>Draw</MarketLayoutHeader>
                        )}

                        <MarketLayoutHeader>
                          {participants?.[1].name}
                        </MarketLayoutHeader>
                      </MarketLayoutRow>
                    )}

                    <MarketLayoutRow>
                      {propsByParticipant.map((participant) => {
                        const p = participant.propositions[index];

                        if (!p) {
                          return <Box flex="1" px="2" />;
                        }

                        return (
                          <PropositionButton
                            key={p.proposition_id}
                            flex="1"
                            isActive={p.is_selected}
                            data-active={p.is_selected}
                            isDisabled={p.is_disabled}
                            isInline
                            isSuspended={p.is_suspended}
                            odds={p.return_amount}
                            propositionName={p.proposition_name}
                            onClick={() => onSelection?.(p)}
                          />
                        );
                      })}
                    </MarketLayoutRow>
                  </Fragment>
                ))}

              {shouldRenderPagination &&
                Array(rowCount)
                  .fill('')
                  .slice(LIMIT)
                  .map((_, index) => {
                    const idx = LIMIT + index;

                    return (
                      <Collapse
                        key={idx}
                        in={isOpen}
                        animateOpacity
                        unmountOnExit
                      >
                        <MarketLayoutRow>
                          {propsByParticipant.map((participant) => {
                            const p = participant.propositions[idx];

                            if (!p) {
                              return <Box flex="1" px="2" />;
                            }

                            return (
                              <PropositionButton
                                key={p.proposition_id}
                                flex="1"
                                isActive={p.is_selected}
                                data-active={p.is_selected}
                                isDisabled={p.is_disabled}
                                isInline
                                isSuspended={p.is_suspended}
                                odds={p.return_amount}
                                propositionName={p.proposition_name}
                                onClick={() => onSelection?.(p)}
                              />
                            );
                          })}
                        </MarketLayoutRow>
                      </Collapse>
                    );
                  })}
            </MarketLayoutTabPanel>
          );
        })}
      </MarketLayoutTabs>

      <MarketLayoutPagination
        in={shouldRenderPagination}
        isOpen={isOpen}
        onPageClick={onToggle}
      />
    </>
  );
}
