import { Box, chakra, Flex, keyframes } from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/input';
import { styleImports } from './Deposit.styles.imports';
import loadingspinner from '../../../../assets/core/icons/loadingspinner.svg';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type DepositSchema = Partial<{
  inputCardHolderNameProps: InputProps;
}>;

export const depositStyles: DepositSchema = {
  inputCardHolderNameProps: {
    'data-testid': 'deposit-inputCardHolderNameProps',

    ...styleImports?.[themeName]?.depositStyles?.inputCardHolderNameProps,
  },
};

export const FlexWrapper = chakra(Flex, {
  label: 'DepositFlexWrapper',
  baseStyle: ({ theme }: any) => ({
    bg: 'rgba(0,0,0,.6)',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 'tooltip',
    width: '100%',
    height: '100vh',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,

    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper?.(theme);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerLoader = chakra(Box, {
  label: 'DepositSpinnerLoader',
  baseStyle: () => ({
    bgImage: `url(${loadingspinner})`,
    bgSize: '100%',
    bgRepeat: 'no-repeat',
    border: 'none',
    boxSize: '24',
    animation: `${rotate} 1.65s linear infinite;`,

    ...(() => {
      try {
        return styleImports[themeName]?.SpinnerLoader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
