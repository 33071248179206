import { NavigationSchema } from './Navigation.styles';
import decorator from '@/assets/gigabet/images/sideNav/decorator.png';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',
    pos: 'relative',
    py: ['0', '0', '3.5'],
    bgGradient: [
      'unset',
      null,
      'linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))',
    ],
    borderRadius: 'lg',
    mb: 4,

    _before: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      top: '38px',
      left: '8px',
      width: '14px',
      height: '42px',
      zIndex: '5',
      bg: `url(${decorator})`,
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
    },
    _after: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      bottom: '38px',
      left: '8px',
      width: '14px',
      height: '42px',
      zIndex: '4',
      bg: `url(${decorator})`,
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
    },
  },

  boxWrapperProps: {
    position: 'relative',
    _before: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      top: '42%',
      left: '8px',
      width: '14px',
      height: '42px',
      zIndex: '5',
      bg: `url(${decorator})`,
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
    },
  },
  boxNavigationWrapperProps: {
    overflowX: 'unset',
  },
  navigationBoxProps: {
    mx: '3',
    boxShadow: [
      'unset',
      null,
      '0px 1px 0px 0px rgba(255, 255, 255, 0.3), 0px 0px 0px 2px rgba(255, 202, 36, 1) inset, 0px 0px 0px 4px rgba(37, 39, 57, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.3) inset',
    ],
    p: {
      color: 'white',
      fontFamily: 'Staatliches',
      fontSize: 'md',
    },
  },
};
