import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flextEventSubTitleProps: FlexProps = {
  alignItems: 'center',
};

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  px: '2',
  pt: '2',
  w: 'fill-available',
  borderRadius: 'none',
  color: 'white',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'normal',
  pos: 'relative',
  bottom: '1',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.400',
});

export const LinkMarket: CSSObject = {
  color: 'white',
};

export const TextSubtitle: CSSObject = {
  color: 'gamma.300',
};

export const CountdownTimerBox: CSSObject = {
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  '.chakra-badge.state-inprogress': {
    bg: 'gamma.400',
    color: 'beta.700',
    borderRadius: 'base',
  },

  '.chakra-badge.state-prior': {
    bg: 'gamma.500',
    color: 'gamma.800',
    borderRadius: 'base',
  },

  '.chakra-badge.state-ended': {
    bg: 'red.500',
    color: 'white',
    borderRadius: 'base',
  },
};
