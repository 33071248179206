import { CSSObject } from '@chakra-ui/react';

import toggle from '@/assets/junglebet/images/switch/switch3x.png';
import toggleBg from '@/assets/junglebet/images/switch/toggleBg.png';
import toggleInnerBg from '@/assets/junglebet/images/switch/toggleInnerBg.png';
import vineLeft from '@/assets/junglebet/images/switch/vineLeft.svg';
import vineRight from '@/assets/junglebet/images/switch/vineRight.svg';

export const FlexWrapper: CSSObject = {
  pos: 'relative',
  border: '6px solid',
  borderColor: 'transparent',
  alignItems: 'center',
  height: '85px',
  justifyContent: 'center',
  borderRadius: '2xl',
  my: '4',
  mx: '1.5',
  p: '1.5',
  boxShadow:
    '0px -1px 2px 0px rgba(186, 72, 9, 0.70) inset, 0px 0px 0px 3px #6D2B05 inset, 0px 5px 0px 0px #541E01, 0px 2px 2px 0px #D17917 inset',
  bgImage: `url(${toggleBg})`,
  bgSize: 'cover cover',
  backgroundBlendMode: 'overlay',
  zIndex: '1',
  _before: {
    bgImg: `url(${toggleInnerBg})`,
    h: '65%',
    w: 'calc(100% - 24px)',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    content: '""',
    pos: 'absolute',
    left: '3',
    right: '0',
    top: '15%',
    zIndex: '2',
    borderRadius: 'md',
    boxShadow:
      '0px 5px 4px 0px rgba(0, 0, 0, 0.20), 0px 3px 0px 0px #A87A42, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
  },
};

export const TextToggle = (isActive: boolean): CSSObject => ({
  fontFamily: 'CarterOne',
  fontWeight: 'normal',
  fontSize: 'md',
  textTransform: 'uppercase',
  color: isActive ? 'gamma.400' : 'delta.500',
  textShadow: '0px 2px 0px rgba(255, 255, 255, 0.25)',
  letterSpacing: 'normal',
  px: '3',
  pos: 'relative',
  zIndex: '3',
});

export const Switcher = (): CSSObject => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
  '--switch-track-width': 'calc(var(--bc-sizes-8))',

  width: '60px',
  pos: 'relative',
  '.chakra-switch__track': {
    bgGradient: 'linear(to-b, delta.300, delta.600)',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
    w: '46px',
    h: '29px',
    pos: 'relative',
    zIndex: '3',
    top: '-0.5',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: '3',

    _before: {
      bgImg: `url(${toggle})`,
      boxSize: '10',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-5px',
      right: '0',
      top: '-3px',
    },
  },

  _active: {
    '.chakra-switch__track': {
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    },
  },

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
    },
    '.chakra-switch__track': {
      bgGradient: 'linear(to-b, delta.300, delta.600)',
    },
  },
  _before: {
    bgImg: `url(${vineLeft})`,
    boxSize: '28',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    left: '-48vw',
    right: '0',
    top: '-51px',
    zIndex: '2',
    overflowX: 'hidden',
  },
  _after: {
    bgImg: `url(${vineRight})`,
    boxSize: '28',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: [null, null, '""'],
    pos: 'absolute',
    right: '-47vw',
    top: '-51px',
    zIndex: '2',
    overflowX: 'hidden',
  },
});
