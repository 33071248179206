import { PersonalDetailsSchema } from './PersonalDetails.styles';

export const personalDetailsStyles: PersonalDetailsSchema = {
  wrapperBox: {
    sx: {
      '&&': {
        '.chakra-input': {
          borderLeftRadius: 'md',
          borderLeft: '1px',
          borderColor: 'gray.300',
          bg: 'gray.50',
          color: 'black',
          _hover: {
            bg: 'gray.100',
            borderColor: 'gray.400',
          },
        },
        '.chakra-form__label': {
          color: 'white',
        },
      },
    },
  },
};
