import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'beta.500',
    borderRadius: 'lg',
    border: 'none',
    gap: '3',
    boxShadow: '0px 2px 2px 0px var(--bc-colors-blackAlpha-400)',
  },
  depositIconProps: {
    color: 'beta.800',
    boxSize: '9',
  },
};
