export const colors = {
  alpha: {
    100: '#c4aeff',
    200: '#9e7bff',
    300: '#8153ff',
    400: '#5f2de7',
    500: '#4b1dca',
    600: '#3f0e7d',
    700: '#2b045c',
    800: '#170330',
    900: '#7244ee',
  },
  beta: {
    100: '#ffd2f8',
    200: '#ffacf2',
    300: '#ff82eb',
    400: '#ff67cb',
    500: '#e931aa',
    600: '#cc2192',
    700: '#930a64',
    800: '#41002b',
    900: '#300120',
  },
  gamma: {
    100: '#fff7e0',
    200: '#ffe59e',
    300: '#ffd562',
    400: '#ffc31c',
    500: '#dea913',
    600: '#c2920b',
    700: '#9c7509',
    800: '#7e5f07',
    900: '#4f3b04',
  },
};
