import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'gamma.50', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'gamma.50'] },
};

export const FlexHeadingRow: CSSObject = {};

export const FlexHeading: CSSObject = {
  bgGradient: 'linear(to-b, beta.200, beta.300)',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  color: 'white',
  top: ['12', '84px'],
  borderTopLeftRadius: 'md',
  borderTopRightRadius: 'md',
  position: 'inherit',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '133%',
};

export const IconHeading: CSSObject = {
  boxSize: '1.8rem',
  color: 'alpha.800',
  bg: 'alpha.500',
  borderRadius: 'base',
  p: '1.5',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  mx: '4',
  mb: '2',
  borderRadius: 'md',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: 'md',
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'gamma.50',
};

export const TextRaceResults: CSSObject = {
  color: 'gamma.50',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'alpha.200' : 'transparent',
  borderColor: 'gamma.50',

  _hover: {
    background: ['transparent', null, 'beta.400'],
  },
  '.chakra-badge.state-ended': {
    color: 'white',
  },
  '.chakra-badge.state-inprogress': {
    color: 'alpha.800',
  },
  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.400',
    color: 'white',
  },
});

export const FlexRow: CSSObject = {
  '& > *': {
    borderTop: ['unset', null, '1px'],
    borderRight: ['unset', null, '1px'],
    borderColor: 'gamma.50',
  },

  _last: {
    '& > *': {
      borderBottom: ['unset', null, '1px'],
      borderBottomColor: [null, null, 'beta.300'],
    },
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'gamma.50'],
  borderTop: '1px',
  bg: 'beta.300',
  py: '1.5',
};

export const CenterTH = () => ({
  bg: 'gamma.50',
  color: 'gray.800',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  py: '2.5',
});

export const ButtonChevron: CSSObject = {
  color: '#663101', // no token
  bg: 'alpha.600',
  borderRadius: 'base',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
