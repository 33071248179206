import { useCallback, useEffect, useState } from 'react';
import { getPunterVerificationKey } from '../../services/account.actions';
import { logError } from '@/helpers/utils';
import {
  GREENID_ENVIRONMENT,
  GREENID_PLATFORM_ACCOUNT_ID,
  GREENID_PLATFORM_API_KEY,
} from '../../../../lib/Constants';
import { EGreenIDStatus } from './Config.VerifyIdentity';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { setVerificationStatus } from '../../services/account.slices';

export const useVerifyIdentity = (
  shouldLoad?: boolean,
  isVerified?: boolean,
  callback?: (status: EGreenIDStatus) => void
) => {
  const [verificationKey, setVerificationKey] = useState('');
  // TODO: Verification is currently stored as a boolean value on /punter/account
  // For P2 this should be changed to a verification status to avoid calling /verify which is directly
  // forwarded as a request to GreenID.
  // Once this has been done on BE, this should change to a RQ poll
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isVerified) {
      // Don't call the /verify endpoint if /account - identity_verified is already true
      dispatch(setVerificationStatus(EGreenIDStatus.VERIFIED));
      return;
    }
    (async () => {
      const verification = await getPunterVerificationKey();

      if (verification && !!verification?.verification_token) {
        setVerificationKey(verification.verification_token);
      }

      if (verification.verification_status) {
        const status = verification.verification_status as EGreenIDStatus;
        dispatch(setVerificationStatus(status));
      }
    })().catch(logError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isVerified]);

  const sessionCompleteCallback = useCallback(
    (_sessionID: string | undefined, status: string | undefined) => {
      if (status) {
        callback?.(status as EGreenIDStatus);
        dispatch(setVerificationStatus(status as EGreenIDStatus));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    // Prevent GreenID function invocation before external script has loaded
    if (typeof greenidUI !== 'object') return;

    if (
      !GREENID_ENVIRONMENT ||
      !GREENID_PLATFORM_API_KEY ||
      !GREENID_PLATFORM_ACCOUNT_ID ||
      !verificationKey ||
      !shouldLoad
    ) {
      return;
    }

    greenidUI.setup({
      environment: GREENID_ENVIRONMENT,
      frameId: 'greenID',
      sessionCompleteCallback,
    });

    greenidUI.show(
      GREENID_PLATFORM_ACCOUNT_ID,
      GREENID_PLATFORM_API_KEY,
      verificationKey
    );
  }, [shouldLoad, sessionCompleteCallback, verificationKey]);
};
