import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  mb: '2',
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  border: 'none',
  borderRadius: 'md',
  bg: 'transparent',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  borderTopColor: 'blackAlpha.300',
  borderBottom: 0,
  borderBottomColor: 'transparent',
  color: 'white',
  bg: 'alpha.500',
  h: '36px',

  _hover: { bg: 'alpha.600' },
};

export const IconSport: CSSObject = {
  color: 'white',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: '1px solid',
  borderColor: 'white',
  borderRadius: 'full',
  p: '1',
  color: 'white',
  transition: '.3s ease-in-out',

  _hover: {
    bg: 'gamma.900',
    color: 'white',
  },
};
