import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  pt: '1',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const SmallHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const ImageGamblersHelp: CSSObject = {
  bg: 'white',
  p: '2',
  minW: '184px',
  w: '184px',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  my: '2',
};

export const Link: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  textDecoration: 'underline',

  _hover: {
    textDecoration: 'none',
  },
};

export const SubHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};

export const GridWrapper: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
  maxW: 'container.sm',
  pb: '0',
};

export const ButtonAction: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear(to-b, beta.500, beta.600)',
  color: 'beta.800',
  border: '2px',
  borderColor: 'beta.700',
  borderRadius: 'base',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  display: 'inline-flex',
  fontFamily: 'SkranjiBold',
  fontSize: ['xs', null, 'sm'],
  fontWeight: 'normal',
  h: ['9', null, '8'],
  lineHeight: '4',
  justifyContent: 'center',
  px: '4',
  textAlign: 'center',
  textTransform: 'uppercase',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  whiteSpace: [null, null, 'nowrap'],
  _hover: {
    bgGradient: 'linear(to-b, beta.600, beta.600)',
  },
  _focus: {
    bgGradient: 'linear(to-b, beta.600, beta.600)',
  },
};
