import React from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { Flex, Switch } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import promotionsPreferencesSchema, {
  PromotionsPreferencesSchema,
} from '../services/schemas/promotionsPreferencesSchema';
import { FormWrapper } from './styles/PromotionPreferences.style';
import {
  useMutationPreferences,
  useQueryPromotionPreferences,
} from '@/api/preferences/preferences.hooks';
import { Button } from '@/components/Button/Button';

const Form: React.FC = () => {
  const { mutateAsync } = useMutationPreferences();
  const { data } = useQueryPromotionPreferences();

  const onSubmit = async (values: PromotionsPreferencesSchema) => {
    if (!values) return;

    try {
      await mutateAsync({
        preference_name: 'PunterLuckyDipPreference',
        preference_value: values.preference_value,
        enabled: true,
      });

      toast.success(
        <FormattedMessage id="account.promotionpreferences.success" />
      );
      console.log('Preferences updated successfully');
    } catch (error) {
      toast.error(<FormattedMessage id="account.promotionpreferences.error" />);
      console.error('Failed to update preferences', error);
    }
  };

  return (
    <Formik<PromotionsPreferencesSchema>
      enableReinitialize
      initialValues={{
        preference_name: 'PunterLuckyDipPreference',
        preference_value: data?.[0]?.preference_value ?? 'Enabled',
        enabled: data?.[0]?.enabled ?? true,
      }}
      validationSchema={promotionsPreferencesSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => (
        <FormWrapper>
          <FormikForm>
            <Flex direction="column" flexWrap="wrap">
              <Switch
                display="flex"
                alignItems="center"
                pb="4"
                name="notificationsEnabled"
                isChecked={values.preference_value === 'Enabled'}
                onChange={(e) => {
                  const newValue = e.target.checked ? 'Enabled' : 'Disabled';
                  setFieldValue('preference_value', newValue);
                }}
              >
                <FormattedMessage id="account.promotionpreferences.coinjar" />
              </Switch>
              <Button
                w="148px"
                data-cy="promotionsPreferencesSubmit"
                type="submit"
              >
                <FormattedMessage id="generic.savechanges" />
              </Button>
            </Flex>
          </FormikForm>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default Form;
