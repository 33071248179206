import { chakra, Flex } from '@chakra-ui/react';
import EditorRME from 'rich-markdown-editor';
import { styleImports } from './Promos.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FlexPageWrapper = chakra(Flex, {
  label: 'promos-FlexPageWrapper',
  baseStyle: ({ theme }) => ({
    flexDirection: 'column',
    px: '4',
    ...(theme.themeName === EThemes.Wellbet && {
      px: '2',
      pb: '3',
      mt: '1',
      color: 'black',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexPageWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Editor = chakra<any>(EditorRME, {
  label: 'promos-Editor',
  baseStyle: ({ theme }) => ({
    '&&&': {
      flex: 1,
      w: '100%',
      display: 'block',

      '> *': {
        fontFamily: 'body',
        bg: 'transparent',
        color: 'inherit',
      },

      'h1, h2, h3, h4, h5, h6': { mt: '8', mb: '2', fontWeight: 'medium' },
      h1: { _first: { mt: '0' }, fontSize: '3xl' },
      h2: { fontSize: '2xl' },
      h3: { fontSize: 'md' },
      h4: { fontSize: 'sm' },
      p: { fontSize: 'sm' },

      ...(theme.themeName === EThemes.Betgalaxy && {
        color: 'black',
        a: { color: 'gamma.900', textDecoration: 'underline' },
        p: '4',
      }),
      ...(theme.themeName === EThemes.Questbet && {
        color: 'gray.700',
      }),
      ...(theme.themeName === EThemes.Volcanobet && {
        color: 'white',
      }),
      ...(theme.themeName === EThemes.Sterlingparker && {
        color: 'white',
        a: { color: 'gray.300', textDecoration: 'underline' },
      }),
    },
  }),
});
