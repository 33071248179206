import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  color: 'white',
  py: '1',
  px: '0',
  borderStyle: 'dashed',
  borderColor: 'whiteAlpha.300',
};

export const RunnerName: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'white',
  textTransform: 'capitalize',
};

export const FlexDeductionsContainer: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'white',
  textTransform: 'uppercase',
  flexDir: 'column-reverse',
};

export const TextDeductions: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
};

export const ScratchedText: CSSObject = {
  fontWeight: 'semibold',
  color: 'white',
};

export const TextScratchedInfo: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'white',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  borderColor: 'gray.200',
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1.5',
};

export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
};
