import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'beta.500',
  fontSize: 'lg',
  fontWeight: 'normal',
  fontFamily: 'CarterOne',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextBetCount: CSSObject = {
  fontSize: 'sm',
  fontFamily: 'roboto',
  pl: '0.5',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'blackAlpha.600',
  minW: 'unset',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  borderRadius: 'base',
  border: 'none',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
  transition: 'all .3s ease-in-out',
  _hover: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    color: 'blackAlpha.700',
    bgGradient: 'linear(to-b, beta.500, beta.700)',
  },
  _focus: {
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    color: 'blackAlpha.600',
    boxShadow:
      ' 0px 2px 3px rgba(0, 0, 0, 0.35), inset 0px 0px 3px rgba(255, 255, 255, 0.6), inset 0px 1px 0px rgba(255, 255, 255, 0.3)',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
