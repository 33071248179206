import { chakra } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { styleImports } from './JoinNow.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const Wrapper = chakra('div', {
  label: 'joinNow-Wrapper',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.Wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const TextItem = chakra('p', {
  label: 'joinNow-TextItem',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.TextItem;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const LinkItem = chakra(Link, {
  label: 'joinNow-LinkItem',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.LinkItem;
      } catch (err) {
        return {};
      }
    })(),
  },
});
