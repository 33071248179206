import { CSSObject, keyframes } from '@chakra-ui/react';
import moon from '../../../../../assets/betgalaxy/images/switch/moon.svg';

export const FlexWrapper = {
  borderRadius: 'base',
  contain: 'layout',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: ['1.5', null, null, 'unset'],
  mb: ['1', null, null, '2.5'],
  gap: '2',

  boxShadow: 'md',
  bg: ['betaAlpha.500', null, 'betaAlpha.800'],
};

export const TextToggle = (isActive: boolean) => ({
  fontSize: 'sm',
  p: '2',
  flex: [1, 'unset'],

  borderRadius: 'full',
  textTransform: 'uppercase',
  fontWeight: 'semibold',
  letterSpacing: '1px',
  px: '5',
  py: '1',
  maxW: '110px',
  textAlign: 'center',
  transition: 'all, 0.2s linear',

  ...(isActive && {
    bg: 'blackAlpha.500',
    boxShadow: 'inset 0px 3px 9px rgb(0 0 0 / 30%)',
    color: 'beta.50',
  }),
  ...(!isActive && {
    bg: 'transparent',
    color: 'beta.50',
  }),
});

const pulse = keyframes`
    0% { transform: translateX(0); }
    50% { transform: translateX(25px); }
    100% { transform: translateX(0); }
`;

export const ImageLeft = {
  display: ['none', null, 'block'],
  animation: `${pulse} 10s ease infinite`,
  pos: 'absolute',
  right: '20px',
  bottom: '0',
};

export const ImageRight = {
  display: ['none', null, 'block'],
  pos: 'absolute',
  left: '0',
  bottom: '0',
};

export const Switcher = (): CSSObject => ({
  bg: 'transparent',

  '.chakra-switch__thumb': {
    bgImage: `url(${moon})`,
    bgSize: '100%',
  },
  '.chakra-switch__track': {
    bg: 'blackAlpha.600',
  },

  _checked: {
    bg: 'transparent',

    '.chakra-switch__track': {
      bg: 'blackAlpha.600',
    },
  },
});
