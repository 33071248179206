/* eslint-disable import/no-mutable-exports */
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

export const LazyLottie = (props: { lottieImportPromise: Promise<any> }) => {
  const { lottieImportPromise } = props;
  const [lottie, setLottie] = useState<any>(null);

  useEffect(() => {
    lottieImportPromise
      .then((data) => {
        setLottie(data.default);
      })
      .catch(() => {});
  });

  return <Lottie animationData={lottie} loop />;
};

export default LazyLottie;
