import React from 'react';
import { Img } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logoError from '../../../assets/juicybet/images/logoError.svg';
import logoMaintenance from '../../../assets/juicybet/images/logoMaintenance.svg';
import Title from '../components/Title';
import Description from '../components/Description';
import RefreshBtn from '../components/RefreshBtn';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (isMaintenance: boolean): TTemplate => ({
  wrapper: <FlexPageWrapper isMaintenance={isMaintenance} />,
  content: {
    wrapper: <MaintenanceContainer />,
    logo: (
      <Img
        src={isMaintenance ? logoMaintenance : logoError}
        alt={REACT_APP_THEME_NAME}
        alignSelf="center"
        w={['250px', null, '350px']}
      />
    ),
    title: <Title />,
    desc: <Description />,
    btn: <RefreshBtn />,
    body: (
      <Global styles={{ body: { background: 'var(--bc-colors-gray-400)' } }} />
    ),
  },
});

export default template;
