import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  borderTop: '1px dashed var(--bc-white-alpha-200, rgba(255, 255, 255, 0.08))',
  borderBottom: '1px dashed var(--bc-black-alpha-200, rgba(0, 0, 0, 0.40))',
  h: ['63px', null, '47px'],
  px: '12px',
  py: 0,

  gridTemplateRows: 'auto',
  gridTemplateAreas: '"icon details time chevron"',

  _first: { borderTop: 'transparent' },
  _last: { borderBottom: 'transparent' },

  '.details': {
    width: '100%',
    overflow: 'hidden',
    pr: '8px',
  },
};
export const TextListName: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  mb: ['0', null, '-1px'],
  whiteSpace: 'normal',
};
export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'white' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'var(--bc-volcanobet-epsilon-200, #A8ADAC)',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: '#D6A64A',
  boxSize: '30px',
  mr: '6px',
  boxShadow: 'none',
  bg: 'none',
  p: 'none',
};

export const IconChevron: CSSObject = {
  color: '#D6A64A',
  ml: '2px',
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },

  // fontSize: '2xs',
  '.timestamp': {
    color: 'white',
    fontSize: '12px',
  },
  // '.chakra-badge.state-prior': {
  //   fontWeight: 'semibold',
  //   fontSize: '2xs',
  //   px: '0',
  // },
  // '.chakra-badge.state-inprogress': {
  //   color: 'beta.900',
  //   fontSize: '2xs',
  // },
  // '.chakra-badge.state-ended': {
  //   fontSize: '2xs',
  // },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'blackAlpha.400',
    boxSize: '20px',
  },
};

export const IconSGM: CSSObject = {
  h: 'auto',
  mr: '2',
  w: '8',
  color: 'alpha.500',
};
