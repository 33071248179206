import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};

export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'white',
  border: '2px solid',
  borderColor: 'alpha.50',
  textOverflow: 'ellipsis',
  _hover: {
    bg: 'delta.400',
  },
  '.chakra-badge.state-inprogress': {
    bg: 'alpha.300',
    color: 'alpha.800',
    mr: '1',
    my: 'auto',
    borderRadius: 'base',
    maxW: '10',
  },

  '.chakra-badge.state-prior': {
    bg: 'transparent',
    color: 'white',
    mr: '1',
    my: 'auto',
    borderRadius: 'base',
    maxW: '10',
  },

  '.chakra-badge.state-ended': {
    bg: 'alpha.800',
    color: 'white',
    mr: '1',
    my: 'auto',
    borderRadius: 'base',
    maxW: '10',
  },
};

export const RaceNumber: CSSObject = {
  color: 'alpha.800',
};
export const FlexDetails: CSSObject = {
  color: 'alpha.800',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'alpha.800',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  color: 'alpha.800',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  border: '2px solid',
  borderColor: 'alpha.50',

  _hover: {
    bg: 'delta.400',
  },

  '& > svg': { boxSize: '6' },
};
