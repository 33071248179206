import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['3', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  bgGradient: [
    'linear(to-b, blackAlpha.400, blackAlpha.400)',
    null,
    null,
    'linear(113.55deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.15) 100%)',
  ],
  boxShadow: [
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    null,
    null,
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  ],
  borderRadius: 'md',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: ['transparent', null, isActive ? 'blackAlpha.500' : 'transparent'],
  color: isActive ? 'beta.400' : 'white',
  textShadow: [
    isActive && '0px 0px 8px rgba(37, 254, 214, 0.6)',
    null,
    '0px 2px 4px rgba(0, 0, 0, 0.25)',
  ],
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '11px',
  lineHeight: 'normal',
  py: ['2', '3'],
  pl: isActive ? ['2', '3'] : ['2', '4'],
  pr: ['2', '4'],
  fontFamily: 'accent',
  borderLeft: ['unset', null, isActive && '3px solid'],
  borderLeftColor: ['unset', null, isActive && 'beta.400'],
  borderBottom: [
    isActive && '2px solid',
    null,
    isActive ? 'none' : '1px dashed',
  ],
  borderBottomColor: [isActive && 'beta.400', null, 'rgba(0, 0, 0, 0.4)'],
  borderTop: [null, null, '1px dashed'],
  borderTopColor: [null, null, 'rgba(255, 255, 255, 0.08)'],
  _first: {
    borderTop: 'none',
    borderTopRadius: ['none', null, 'lg'],
  },
  _last: {
    borderBottomRadius: 'lg',
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    bg: !isActive && 'blackAlpha.300',
  },
});

export const TopNavLabelWrapper: CSSObject = {
  mx: '2',
  color: 'white',
  fontFamily: 'accent',
};

export const NavLinkLabel: CSSObject = {
  color: 'beta.400',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  border: 'none',
  borderBottom: '3px solid',
  borderBottomColor: 'beta.400',
  fontSize: 'xs',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '110px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '40px',
  ml: ['2', '5'],
};
