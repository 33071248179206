/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { SelectProps } from '@chakra-ui/select';
import theme from '@chakra-ui/theme';

export const Select = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      color: 'black',

      '&&': {
        border: '2px solid',
        borderRadius: 'md',
        borderColor: 'gamma.400',
        bg: 'alpha.700',
      },

      '&::placeholder': {
        color: 'gamma.600',
      },

      _hover: {
        '&&': {
          border: '2px solid',
          borderColor: 'gamma.400',
          bg: 'alpha.700',
        },
      },

      _focus: {
        '&&': {
          border: '2px solid',
          borderColor: 'gamma.400',
          bg: 'alpha.900',
          boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
        },
      },
    },
    icon: { color: 'black' },
  },
  sizes: {
    lg: { addon: { borderRadius: '4px' }, field: { borderRadius: '4px' } },
    md: {
      addon: { borderRadius: '4px', h: '38px' },
      field: { borderRadius: '4px', fontSize: 'xs', px: '2', h: '38px' },
    },
    sm: { addon: { borderRadius: '4px' }, field: { borderRadius: '4px' } },
  },
  variants: {
    outline: (props: SelectProps) => {
      const { addon, field } = theme.components.Select.variants.outline(
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          bg: 'gray.50',
        },
      };
    },
  },
};
