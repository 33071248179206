import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  borderRadius: 'base',
  background:
    'var(--gradient-grey-light, linear-gradient(225deg, #4B5A6D 0%, #3F4953 100%))',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
  border: 'none',
  p: '1.5',
};

export const CardMainContent: CSSObject = {
  padding: '0',
  pb: '2',
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.500',
};

export const CardBottomContent: CSSObject = {
  padding: '0',
  pt: 0,
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.200',
};

export const DividerCard: CSSObject = {
  bg: 'transparent',
  display: 'none',
};

export const ContentRow: CSSObject = {
  color: 'alpha.600',
  borderColor: 'whiteAlpha.200',
  '> hr': {
    width: '100%',
    borderStyle: 'solid',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'beta.200',
    bg: 'beta.600',
    border: '2px solid',
    borderColor: 'beta.600',
  },
  '&&': {
    px: 0,
  },
};

export const ExoticSelectionText: CSSObject = {
  color: 'alpha.600',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: 500,
};

export const dividerExoticProps: DividerProps = {
  borderTopColor: 'blackAlpha.200',
  borderBottomColor: 'whiteAlpha.200',
  maxW: '300px',
};

export const dividerExoticLastProps: DividerProps = {
  variant: 'dashed',
  w: 'full',
  borderTopColor: 'blackAlpha.200',
  borderBottomColor: 'blackAlpha.200',
};
