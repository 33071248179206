import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  '.chakra-input__left-addon': {
    bg: 'gray.400',
    h: '9',
  },
  '.chakra-input': {
    border: '1px',
    borderColor: 'gray.400',
    bg: 'white',
    h: '9',
    '&:hover': {
      bg: 'gray.50',
    },
    '&:active': {
      bg: 'gray.100',
    },
  },
  '.chakra-select': {
    border: '1px',
    borderColor: 'gray.400',
    bg: 'gray.50',
    h: '9',
  },
};
