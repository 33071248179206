import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bg: 'alpha.700',
  '&&': { px: '3', pb: ['3', null, '2.5'] },
  mt: ['unset', '2'],
  borderTopRadius: 'md',
  overflow: 'visible',
  color: 'white',
  fontSize: '2xl',
  pb: '0',
  borderLeft: '2px',
  borderRight: '2px',
  borderTop: '2px',
  borderStyle: 'solid',
  borderColor: 'beta.500',
  fontFamily: 'Spectral',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  lineHeight: 'normal',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'extrabold',
  fontSize: 'md',
  textTransform: 'uppercase',
  color: 'beta.500',
  fontFamily: 'Spectral',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  p: '3',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  color: 'beta.500',
  m: '2',
  borderRadius: [null, null, null, 'base'],
};

export const WrapperContainer: CSSObject = {
  mt: '-3',
  mb: '3',
  px: ['1.5', '0'],
  bg: 'alpha.700',
  borderLeft: '2px',
  borderRight: '2px',
  borderBottom: '2px',
  borderStyle: 'solid',
  borderColor: 'beta.500',
  borderBottomRadius: 'md',
};
