import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  h: ['75px', null, '95px'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const TextItem = {
  color: '#FFF',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  '@media(max-width: 767px)': {
    pos: 'relative',
    top: 'unset',
  },
};

export const LinkItem = {
  color: 'beta.500',
  fontWeight: 'bold',
};
