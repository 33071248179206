import evenShotBg from '@/assets/questbet/images/backgrounds/evenShotBg.png';
import evenShot from '@/assets/questbet/images/evenShotMascot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  notAvailableText: {
    color: 'alpha.200',
    mt: 0,
  },
  oddsValueSpan: {
    color: 'white',
    sx: {
      '&[data-hasbets="true"]': {
        color: 'alpha.200',
      },
    },
  },
  hasBetSubTitleText: {
    fontWeight: 'normal',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notYetAvailableText: {
    color: 'alpha.200',
    fontWeight: 'bold',
    mt: 0,
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'alpha.200',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'extrabold',
    fontSize: 'lg',
  },
  oddsText: {
    mt: '0',
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    border: '1px',
    borderColor: 'gray.200',
    borderRadius: 'base',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flexDir: 'column',
    bg: `linear-gradient(180deg, #67C1D5 0%, #126172 100%), url(${evenShotBg})`,
    bgBlendMode: 'overlay, normal',
    bgSize: 'cover',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    fontFamily: 'Strippy',
    fontWeight: 'normal',
    color: 'gamma.700',
    textTransform: 'uppercase',
    fontSize: '46px',
    letterSpacing: '9px',
  },
  addToBetslipButton: {
    variant: 'tertiary',
  },
  loginToView: {
    variant: 'tertiary',
  },
  evenShotImage: {
    mt: 4,
    mb: 3,
    w: '146px',
    h: '100px',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
  },
};
