import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  fontFamily: 'accent',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: ['2', null, '0'],
  '&&': { px: ['2', '4'], pb: 0 },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'normal',
  fontSize: 'xl',
  fontFamily: 'Staatliches',
  color: 'white',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  color: 'gamma.200',
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: ['6px', null, null, 'lg'],
  minHeight: '0',
  bg: '#8153FF80',
  color: 'white',
  boxShadow: '0px 0px 0px 1px #FFFFFF1F inset, 0px 4px 10px 0px #0000004D',
  fontFamily: 'accent',
};

export const WrapperContainer: CSSObject = {
  px: ['2', '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
};
