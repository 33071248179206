import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
};

export const Subheading: CSSObject = {
  color: 'white',
  span: {
    color: 'white',
    textDecor: 'underline',
  },
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'gray.300',
  borderStyle: 'dashed',
};

export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'secondary',
  fontWeight: 'normal',
};

export const ButtonWithdrawal: CSSObject = {
  variant: 'secondary',
  fontWeight: 'normal',
};

export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};

export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  my: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, #FFF 0%, #B2B2B2 100%)',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.30)',
    border: 'none',
    _hover: {
      boxShadow: '0px 0px 8px 0px rgba(67, 187, 255, 0.50)',
    },
  },
  '.chakra-input__left-addon': {
    bg: ' transparent',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    bg: 'alpha.700',
    border: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'alpha.600',
      border: 'none',
    },
    _focus: {
      bg: 'beta.800',
      border: '2px',
      borderColor: 'beta.500',
      borderLeft: 'none',
      m: '0',
      h: '42px',
    },
  },
};

export const BackButton: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  color: 'white',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
  h: '6',
  minW: '6',
  mr: '2',
  svg: {
    zIndex: 10,
  },
  _hover: {
    bg: 'unset',
  },
};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    borderColor: 'white',
    _hover: {
      bg: 'blue.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '0',
  p: '0',
  w: '100%',
};

export const ListContainerHeading: CSSObject = {
  color: 'white',
  fontFamily: 'Staatliches',
  fontSize: 'lg',
  fontWeight: 'normal',
  mt: '3',
};

/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};

export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'beta.500',
  color: status === EWithdrawalStatus.Pending && 'blackAlpha.700',
  textTransform: 'uppercase',
  fontSize: '2xs',
});

export const WithdrawalWrapper: CSSObject = {
  p: '2.5',
  bg: 'alpha.500',
  border: 'none',
  borderRadius: 'base',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};

export const buttonWithdrawalProps: CustomButtonProps = {
  variant: 'secondary',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  borderRadius: 'base',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.100',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  boxShadow: 'none',
  _hover: {
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    border: '2px',
    borderColor: 'beta.400',
    boxShadow: '0px 0px 8px 0px rgba(83, 249, 169, 0.40)',
  },
  _active: {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%))',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08) inset',
    color: 'beta.500',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  _disabled: {
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'white',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    opacity: 0.4,
  },
};

export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};

export const WithdrawalCardDetailRequested: CSSObject = {
  color: 'white',
  borderBottom: '1px dashed',
  borderColor: 'alpha.700',
};

export const WithdrawalCardContent: CSSObject = {
  '> div': {
    flexDir: ['column', null, 'row'],
    alignItems: ['flex-start', null, 'unset'],
  },
};
