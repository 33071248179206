import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: '5px',
    bg: 'beta.500',
    boxShadow: `0px 0px 0px 2px #FFA902 inset,0px 0px 0px 4px #744f0a inset,0px 0px 0px 5px #ffd076 inset,0px 3px 10px 0px rgba(0, 0, 0, 0.40)`,
    minW: '90px',
  },
  btnGroupText: {
    fontSize: 'sm',
    color: 'blackAlpha.700',
    fontWeight: 'black',
    fontFamily: 'roboto',
    textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  },
  btnGroupIcon: {
    color: 'blackAlpha.700',
  },
  name: {
    color: 'blackAlpha.700',
  },
};
export default styles;
