import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { createTemplate } from '@/helpers/createTemplate';
import template from './templates/template';

const Overview: FC = () => {
  const intl = useIntl();
  return <>{createTemplate(template(intl))}</>;
};

export default Overview;
