import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '40px',
      background: 'blackAlpha.500',
      color: 'white',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      px: '2',
      'input, span': {
        fontFamily: 'ProximaNova',
        fontWeight: 'normal',
      },
      button: {
        color: 'gamma.300',
      },
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset,0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
      border: 'none',
      borderRadius: 'md',

      _hover: {
        bg: 'alpha.600',
      },
    },
  },
};
