import { css } from 'styled-components';

export const pageHeaderWrapper = css`
  background-color: ${({ theme }) => theme.basePalette.navyBlue};
`;

export const pageHeaderContainer = css`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--bc-space-5) var(--bc-space-5) var(--bc-space-20)
    var(--bc-space-5);
`;

export const pageHeaderInnerContainer = css`
  margin-bottom: var(--bc-space-2);
`;

export const pageHeaderOuterContainer = css`
  margin-top: var(--bc-space-7);
`;

export const pageHeader = css`
  color: white;
  font-size: var(--bc-fontSizes-3xl);
  font-weight: 300;
`;

export const pageHeaderIcon = css``;

export const matchStartTime = css`
  color: white;
  font-size: var(--bc-fontSizes-md);
  font-weight: 300;
  letter-spacing: 1px;
  margin-right: var(--bc-space-6);
`;

// TODO: Remove
export const marketsListContainer = css`
  margin-bottom: var(--bc-space-20);
`;

// TODO: Remove
export const noMarkets = css`
  border-radius: 4px;
  display: flex;
  margin: var(--bc-space-5);
  padding: var(--bc-space-2) var(--bc-space-3) var(--bc-space-4);

  ${({ theme }) => theme.device.laptop} {
    margin: var(--bc-space-5) var(--bc-space-28);
  }
`;

// TODO: Remove
export const noMarketsContent = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: var(--bc-space-2);
  padding-top: var(--bc-space-1);
`;

// TODO: Remove
export const noMarketsText = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-sm);
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const noMarketsIcon = css`
  height: 20px;
  width: 20px;
`;
