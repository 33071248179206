import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  variant: 'odds',
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: '100%',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: 'base',
  borderTopRightRadius: 'base',
  background: 'beta.200',
  borderTop: '1px solid rgba(255, 255, 255, 0.10)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
};

export const favouriteIconProps: CSSObject = {
  color: 'white',
  boxSize: '2',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '8px',
  color: 'white',
  textShadow: 'none',
};
