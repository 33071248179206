import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  color: 'gamma.200',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  h: 'full',
  borderRadius: 'md',
  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.200)',
  color: 'gamma.200',
  zIndex: 'docked',
  borderRadius: 'md',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'gamma.200',
  },
};
export const TextTitle: CSSObject = {
  color: 'gamma.200',
};
