import { CSSObject, keyframes } from '@chakra-ui/react';

import toggle from '../../../../../assets/betprofessor/images/sidenav/toggle.png';

export const FlexWrapper: CSSObject = {
  alignItems: 'center',
  contain: 'layout',
  justifyContent: 'center',
  bgGradient: 'linear(180deg, beta.600, beta.700)',
  boxShadow:
    '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #1A3347, 0px 2px 3px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  p: '2',
  py: ['2', null, null, 'unset'],
  mb: ['1', null, null, 'unset'],
  mx: ['1.5', null, null, '0'],
  borderRadius: 'lg',
};

export const StitchWrapper: CSSObject = {
  m: '0.5',
  display: 'flex',
  border: '2px dashed rgba(0, 0, 0, 0.15)',
  boxSize: 'full',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'lg',
};

export const TextToggle = (isActive: boolean): CSSObject => ({
  color: isActive ? 'alpha.300' : 'blackAlpha.700',
  textShadow: isActive
    ? '0px 0px 15px #6DDA01'
    : '0px 1px 0px rgba(255, 255, 255, 0.15)',
  fontFamily: 'dreadful',
  fontSize: '4xl',
  letterSpacing: 'widest',
  px: '2',
  mx: ['1', null, '0'],
});

export const Switcher = (): CSSObject => ({
  width: '50px',
  '.chakra-switch__track': {
    bg: 'blackAlpha.500',
    boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',

    _before: {
      bgImg: `url(${toggle})`,
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      boxSize: '41px',
      content: '""',
      pos: 'absolute',
      left: '-2',
      right: '0',
      top: '-2',
      animation: `
            ${keyframes`
              from { transform: rotate(0deg) }
              to { transform: rotate(-360deg) }
            `} 0.5s linear`,
    },
  },

  _active: {
    '.chakra-switch__track': {
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    },
  },

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
      _before: {
        animation: `
          ${keyframes`
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          `} 0.7s linear`,
      },
    },
  },
});
