import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '2',
};

export const BoxSectionWrapper: CSSObject = {
  mb: '0',
  mt: '4',
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  borderRadius: ['md'],
  border: 0,
  bg: 'white',
};

export const TextTitle: CSSObject = {
  fontSize: 'sm',
  color: '#2D3748',
};

export const LinkItem: CSSObject = {
  display: 'flex',
  px: '2',
  py: '2',
  alignItems: 'center',
  alignSelf: 'stretch',
  background: 'white',
  borderTopColor: 'white',
  borderBottom: 0,
  borderBottomColor: ['transparent'],

  color: ['gray.700'],

  _hover: { bg: 'alpha.100' },
};

export const IconSport: CSSObject = {
  color: '#D69E2E',
};

export const IconChevron: CSSObject = {
  boxSize: ['4', '5'],
  color: 'gray.700',
};
