import { chakra, Flex, FlexProps, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './RaceRunnerListItem.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

type PropositionHistoryItemDirection = { direction: 'up' | 'down' | 'same' };

type TRunnerListItemWrapper = {
  scratched: boolean;
} & FlexProps &
  any;
export const RunnerListItemWrapper = chakra<TRunnerListItemWrapper>(Flex, {
  label: 'raceRunnerListItem-RunnerListItemWrapper',
  shouldForwardProp: (prop) => !['scratched'].includes(prop),
  baseStyle: () => ({
    flexDirection: 'column',
    borderTop: '1px',
    borderTopColor: 'blackAlpha.100',
    py: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerListItemWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerListItemContainer = chakra(Flex, {
  label: 'raceRunnerListItem-RunnerListItemContainer',
  baseStyle: ({ theme }) => ({
    flexDir: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(theme.themeName === EThemes.Wellbet && {
      flexDir: 'row',
      flex: ['1', null, 'unset'],
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      flex: ['1', null, 'unset'],
    }),
  }),
});

export const RunnerNameAndSilk = chakra(Flex, {
  label: 'raceRunnerListItem-RunnerNameAndSilk',
  baseStyle: () => ({
    flexDir: 'row',
    alignItems: 'center',
    flex: '2',
  }),
});

export const FlexDeductionsContainer = chakra(Flex, {
  label: 'raceRunnerListItem-FlexDeductionsContainer',
  baseStyle: () => ({
    flexDirection: 'column',
    color: 'gray.500',
    maxW: 200,
    mr: 1,
    alignItems: 'flex-end',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexDeductionsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextDeductions = chakra(Text, {
  label: 'raceRunnerListItem-TextDeductions',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextDeductions;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextScratchedInfo = chakra(Text, {
  label: 'raceRunnerListItem-TextScratchedInfo',
  baseStyle: () => ({
    color: 'gray.500',
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.TextScratchedInfo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TRunnerPropositionWrapper = {
  shouldShowPlaceOdds?: boolean;
} & FlexProps &
  any;
export const RunnerPropositionWrapper = chakra<TRunnerPropositionWrapper>(
  Flex,
  {
    label: 'raceRunnerListItem-RunnerPropositionWrapper',
    shouldForwardProp: (prop) => !['shouldShowPlaceOdds'].includes(prop),
    baseStyle: ({ theme, shouldShowPlaceOdds }: any) => ({
      flex: shouldShowPlaceOdds ? 'unset' : '1',
      columnGap: '2',
      justifyContent: 'end',

      ...(theme.themeName === EThemes.Wellbet && {
        columnGap: '1',
      }),
      ...(theme.themeName === EThemes.Betgalaxy && {
        columnGap: '2',
      }),
      ...(() => {
        try {
          return styleImports[themeName]?.RunnerPropositionWrapper;
        } catch (err) {
          return {};
        }
      })(),
    }),
  }
);

export const RunnerScratchedWrapper = chakra<TRunnerPropositionWrapper>(Flex, {
  label: 'raceRunnerListItem-RunnerScratchedWrapper',
  baseStyle: ({ theme }: any) => ({
    flex: '1',
    columnGap: '2',
    justifyContent: 'end',
    fontWeight: 'bold',
    ...(theme.themeName === EThemes.Wellbet && {
      columnGap: '2',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      columnGap: '2',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RunnerScratchedWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerListItemPropositionHistory = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) =>
    theme.features.racing.raceRunnerListItem.runnerListItemPropositionHistory};
`;

export const PropositionHistoryItem = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) =>
    theme.features.racing.raceRunnerListItem.propositionHistoryItem};
`;

type TRunnerName = {
  isScratched: boolean;
} & FlexProps &
  any;
export const RunnerName = chakra<TRunnerName>(Text, {
  label: 'raceRunnerListItem-RunnerName',
  shouldForwardProp: (prop) => !['isScratched'].includes(prop),
  baseStyle: ({ theme, isScratched }: any) => ({
    textDecoration: isScratched ? 'line-through' : undefined,
    fontSize: 'sm',

    '&& > div': {
      display: 'inline-block',
      textDecoration: 'inherit',
      textTransform: 'capitalize',
    },
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'semibold',
      fontSize: ['xs', null, 'sm'],
      color: 'black',
      textTransform: 'capitalize',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RunnerName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GreyhoundRunnerSilk = chakra('img', {
  label: 'raceRunnerListItem-GreyhoundRunnerSilk',
  baseStyle: ({ theme }) => ({
    marginRight: '2',
    maxH: '8',
    minW: '8',
    minH: '8',
    boxShadow: 'lg',
    borderRadius: 'base',

    ...(theme.themeName === EThemes.Betgalaxy && {
      boxSize: '8',
      marginRight: ['1', null, '3'],
    }),
  }),
});

export const HorseHarnessRunnerSilk = chakra('img', {
  label: 'raceRunnerListItem-HorseHarnessRunnerSilk',
  baseStyle: ({ theme }) => ({
    marginRight: '2',
    borderRadius: 'base',
    h: '8',
    p: '1',
    boxShadow: 'md',
    background: 'white',
    aspectRatio: '1 / 1',
    objectFit: 'contain',

    ...(theme.themeName === EThemes.Betgalaxy && {
      boxSize: ['8', null, '8'],
      maxHeight: '8',
      marginRight: ['1', null, '3'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.HorseHarnessRunnerSilk;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ScratchedText = chakra(Text, {
  label: 'raceRunnerListItem-ScratchedText',
  baseStyle: () => ({
    textAlign: 'center',
    fontSize: 'sm',
    color: 'gray.500',

    ...(() => {
      try {
        return styleImports[themeName]?.ScratchedText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PropositionHistoryItemValue = styled.p<PropositionHistoryItemDirection>`
  ${({ theme, direction }) =>
    theme.features.racing.raceRunnerListItem.propositionHistoryItemValue[
      direction
    ]};
`;

export const PropositionHistoryItemArrowIcon = styled.img`
  ${({ theme }) =>
    theme.features.racing.raceRunnerListItem.propositionHistoryItemArrowIcon};
`;

export const TextBarrierNumber = chakra(Text, {
  label: 'raceRunnerListItem-TextBarrierNumber',
  baseStyle: () => ({
    color: 'gray.500',
    fontSize: 'xs',
    ...(() => {
      try {
        return styleImports[themeName]?.TextBarrierNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexFlucsPopupContainer = chakra(Flex, {
  label: 'raceRunnerListItem-FlexFlucsPopupContainer',
  baseStyle: () => ({
    flexDir: ['column', null, 'row'],
    alignItems: ['flex-start', null, 'center'],
    '.badgeCollapse': {
      borderLeft: '1px solid',
      borderColor: ['transparent', null, 'blackAlpha.300'],
      pl: ['0', null, '2'],
    },
    ...(() => {
      try {
        return styleImports[themeName]?.FlexFlucsPopupContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
