import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Flex, Text } from '@chakra-ui/react';
import IconSvg from '@/components/common/IconSvg';
import { TBetForRace } from '@/api/racing/bets/bets.types';
import { centsToDollars, getIconBySportName } from '@/helpers/utils';
import { onRaceBetStyles } from '../styles/OnRaceBets.styles';

type TBlendedBet = {
  bet: TBetForRace;
};

export const BlendedBet = ({ bet }: TBlendedBet) => {
  const intl = useIntl();
  const {
    proposition_info: propInfo,
    bet_stake: betStake,
    potential_return: potentialReturn,
  } = bet;
  const { racingType } = useParams();

  return (
    <Flex key={bet.bet_id} {...onRaceBetStyles.betRow}>
      <IconSvg
        name={getIconBySportName(racingType)}
        {...onRaceBetStyles.categoryIcon}
      />
      <Flex {...onRaceBetStyles.srmBlendedWrapper}>
        {propInfo?.map((sel, index) => (
          <Flex key={sel.proposition_id}>
            <Flex {...onRaceBetStyles.srmBlendedLegContainer}>
              <Text {...onRaceBetStyles.srmBlendedRunnerText}>
                {sel.runner_number}. {sel.name?.toLowerCase()}
              </Text>
            </Flex>
            {index === propInfo.length - 1 && (
              <Text {...onRaceBetStyles.subtitleText} flex="1">
                Stake:{' '}
                <Text as="span" {...onRaceBetStyles.stakeText}>
                  {centsToDollars(betStake)}
                </Text>
              </Text>
            )}
          </Flex>
        ))}
        <Text {...onRaceBetStyles.subtitleText}>
          {intl.formatMessage({
            id: 'generic.potentialWinnings',
          })}
          :{' '}
          <Text as="span" {...onRaceBetStyles.potentialWinningsText}>
            {potentialReturn ? centsToDollars(potentialReturn) : 'N/A'}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
