import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputLeftAddon: {
    borderLeftRadius: 'md',
    bgGradient: 'linear(to-b, delta.400, delta.600)',
    color: 'delta.800',
    fontSize: 'sm',
    fontWeight: 'black',
    h: '9',
    w: 'auto',
    sx: {
      '&&': {
        _groupFocusWithin: {
          bg: 'alpha.50',
          color: 'alpha.700',
        },
      },
    },
  },
  betCardInput: {
    bg: 'delta.800',
    borderColor: 'delta.600',
    borderLeft: 'none',
    color: 'white',
    borderRadius: 'md',
    fontWeight: 'black',
    fontSize: 'md',
    h: '9',
    sx: {
      '&&': {
        _hover: {
          bg: 'delta.800',
        },
        _focus: {
          borderColor: 'alpha.50',
          bg: 'alpha.700',
        },
        '&::placeholder': { color: 'white', opacity: '0.3' },
      },
    },
  },
};
