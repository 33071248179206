import {
  AccordionButtonProps,
  AccordionItemProps,
  AccordionPanelProps,
  BoxProps,
  FlexProps,
  IconButtonProps,
  IconProps,
  SkeletonTextProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './MiniBetSlip.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { CustomButtonProps } from '@/components/Button/Button';
import { defaultStyles } from './MiniBetSlip.stylesDefaults';

const themeName = getThemeName();

export type MiniBetSlipSchema = Partial<{
  // outer
  wrapper: BoxProps;
  slideBox: BoxProps;
  accordionHeaderText: TextProps;
  accordionItem: AccordionItemProps;
  accordionButton: AccordionButtonProps;
  accordionButtonIcon: Partial<IconProps>;

  // Header
  headerStack: StackProps;
  headerText: TextProps;
  headerButton: Partial<IconButtonProps>;

  // accordion
  accordionPanel: AccordionPanelProps;
  selectionFlex: FlexProps;
  selectionMarketName: TextProps;
  selectionPropositionName: TextProps;

  // footer
  footerStack: StackProps;
  footerHStack: StackProps;

  legsText: TextProps;
  legsChunks: TextProps;
  oddsText: TextProps;
  oddsSkeletonLoader: SkeletonTextProps;
  oddsChunks: TextProps;

  buttonsHStack: StackProps;
  clearButton: CustomButtonProps;
  addToSlipButton: CustomButtonProps;
}>;

export const miniBetSlipStyles: MiniBetSlipSchema = {
  ...defaultStyles,
  buttonsHStack: {
    label: 'miniBetSlip-buttonsHStack',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.buttonsHStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  wrapper: {
    label: 'miniBetSlip-wrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  slideBox: {
    label: 'miniBetSlip-slideBox',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.slideBox;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionHeaderText: {
    label: 'miniBetSlip-accordionHeaderText',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.accordionHeaderText;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionItem: {
    label: 'miniBetSlip-accordionItem',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.accordionItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButton: {
    label: 'miniBetSlip-accordionButton',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.accordionButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButtonIcon: {
    label: 'miniBetSlip-accordionButtonIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.accordionButtonIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerStack: {
    label: 'miniBetSlip-headerStack',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.headerStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerText: {
    label: 'miniBetSlip-headerText',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.headerText;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerButton: {
    label: 'miniBetSlip-headerButton',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.headerButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionPanel: {
    label: 'miniBetSlip-accordionPanel',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.accordionPanel;
      } catch (err) {
        return {};
      }
    })(),
  },
  selectionFlex: {
    label: 'miniBetSlip-selectionFlex',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.selectionFlex;
      } catch (err) {
        return {};
      }
    })(),
  },
  selectionMarketName: {
    label: 'miniBetSlip-selectionMarketName',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.selectionMarketName;
      } catch (err) {
        return {};
      }
    })(),
  },
  selectionPropositionName: {
    label: 'miniBetSlip-selectionPropositionName',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles
          ?.selectionPropositionName;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerStack: {
    label: 'miniBetSlip-footerStack',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.footerStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerHStack: {
    label: 'miniBetSlip-footerHStack',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.footerHStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  legsText: {
    label: 'miniBetSlip-legsText',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.legsText;
      } catch (err) {
        return {};
      }
    })(),
  },
  legsChunks: {
    label: 'miniBetSlip-legsChunks',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.legsChunks;
      } catch (err) {
        return {};
      }
    })(),
  },
  oddsText: {
    label: 'miniBetSlip-oddsText',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.oddsText;
      } catch (err) {
        return {};
      }
    })(),
  },
  oddsSkeletonLoader: {
    label: 'miniBetSlip-oddsSkeletonLoader',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.oddsSkeletonLoader;
      } catch (err) {
        return {};
      }
    })(),
  },
  oddsChunks: {
    label: 'miniBetSlip-oddsChunks',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.oddsChunks;
      } catch (err) {
        return {};
      }
    })(),
  },
  clearButton: {
    label: 'miniBetSlip-clearButton',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.clearButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  addToSlipButton: {
    label: 'miniBetSlip-addToSlipButton',
    ...(() => {
      try {
        return styleImports[themeName]?.miniBetSlipStyles?.addToSlipButton;
      } catch (err) {
        return {};
      }
    })(),
  },
};
