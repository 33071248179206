import { CSSObject, keyframes } from '@chakra-ui/react';
import boats from '../../../../../../assets/wellbet/images/homeFeaturedSport/boats.svg';
import waterfade from '../../../../../../assets/wellbet/images/waterfade.png';

const rotate = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(50rad); }
`;

export const FlexWrapper: CSSObject = {
  _after: {
    w: '100%',
    h: ['150%', null, '290%'],
    content: `''`,
    bgImage: `url('${boats}')`,
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    pos: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: '-100px',
    animation: `${rotate} 250s linear infinite`,
  },

  p: '4',
  pl: '4',
  border: 'none',
  h: '300px',
  bgImage: `url(${waterfade})`,
  bgPos: ['center', null, 'bottom'],
  bgRepeat: 'no-repeat',
  bgSize: ['150%', null, '100%'],
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
};

export const TextVS: CSSObject = {
  bg: 'alpha.200',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'gamma.400',
};

export const TextTeam: CSSObject = {
  fontSize: 'md',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: ['200px', null, '300px'],
  m: 'auto',
};

export const puntButtonProps: CSSObject = {
  border: 'none',
  cursor: 'pointer',
  zIndex: 'dropdown',
};
