import { Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { getThemeName } from '@/helpers/getThemeName';
import { TextBoldDescription } from '@/views/Maintenance/styles/Maintenance.styles';
import {
  ContactText,
  ContentBodyText,
  RegistrationContent,
  TextLink,
  TextBodyCopy,
} from '../styles/LocationNotAllowed.styles';

export default function Body() {
  const themeName = getThemeName();
  const { email } = getThemeConfig();

  return (
    <ContentBodyText>
      <FormattedMessage
        id="onboarding.locationnotallowed.subheader"
        values={{
          b: (text: string) => <Text as="b">{text}</Text>,
        }}
      />
      <TextBodyCopy>
        <FormattedMessage
          id="onboarding.locationnotallowed.bodycopy"
          values={{
            bold: (string: string) => (
              <TextBoldDescription as="span">{string}</TextBoldDescription>
            ),
            themeName 
          }}
        />
        <ContactText
          href={`mailto:${email}?subject=${(
            <FormattedMessage id="onboarding.locationnotallowed.locationenquiry" />
          )}${themeName}`}
        >
          {email}
        </ContactText>
      </TextBodyCopy>

      <RegistrationContent>
        <FormattedMessage
          id="onboarding.locationnotallowed.registration"
          values={{
            themeName,
            commissionLink: (text: string) => (
              <TextLink href="https://www.vgccc.vic.gov.au/" target="_blank">
                {text}
              </TextLink>
            ),
            bookmakersLink: (text: string) => (
              <TextLink href="https://vicbookmakers.com.au/" target="_blank">
                {text}
              </TextLink>
            ),
            racingLink: (text: string) => (
              <TextLink
                href="https://www.racingvictoria.com.au/"
                target="_blank"
              >
                {text}
              </TextLink>
            ),
          }}
        />
      </RegistrationContent>
    </ContentBodyText>
  );
}
