import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useAuth } from '../../hooks/useAuth';
import { keys } from '../api.keys';
import {
  mutateMarketing,
  queryMarketingPreferences,
  TMarketingPreferencesParams,
} from './marketing';

export const useMutationMarketing = (
  options?: UseMutationOptions<any, any, TMarketingPreferencesParams>
) => useMutation((data) => mutateMarketing(data), options);

type TQueryHook = {
  key?: any[];
  options?: UseQueryOptions<TMarketingPreferencesParams>;
};

export const useQueryMarketingPreferences = ({
  key = [],
  options = {},
}: TQueryHook = {}) => {
  const { isAuthenticated } = useAuth();
  const query = useQuery(
    [keys.marketingPreferences, isAuthenticated, ...key],
    () => queryMarketingPreferences(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    {
      enabled: !!isAuthenticated,
      ...(options as any),
    }
  );

  return query;
};
