import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { Stop } from '@styled-icons/octicons/Stop';

export const EstReturnsLabel: CSSObject = {
  bg: 'beta.500',
  color: 'delta.500',
  fontWeight: 'bold',
  height: 'full',
  alignItems: 'center',
  px: '1',
  width: 'auto',
  lineHeight: 'normal',
};

export const PendingBetContainer: CSSObject = {
  bg: 'beta.500',
  borderRadius: 'base',
  w: 'full',
  color: 'blackAlpha.700',
  justifyContent: 'center',
  bgGradient: 'unset',
  boxShadow: 'none',
};

export const BetPendingApprovalText: CSSObject = {
  fontSize: 'xs',
};

export const RejectedBetContainer: CSSObject = {
  bg: 'red.500',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'md',
  w: 'full',
  justifyContent: 'center',
  svg: {
    color: 'blackAlpha.700',
  },
};

export const BetRejectedText: CSSObject = {
  fontSize: 'xs',
  color: 'blackAlpha.700',
  fontWeight: 'bold',
};

export const ApprovedBetContainer: CSSObject = {
  color: 'alpha.300',
  bg: 'gamma.700',
  borderRadius: 'md',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  w: 'full',
  justifyContent: 'center',
};

export const BetApprovalText: CSSObject = {
  fontSize: 'xs',
  color: 'alpha.300',
  fontWeight: 'bold',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
};

export const FooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  px: '0',
  h: '6',
  overflow: 'hidden',
  borderRadius: 'base',
  boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.25) inset',
  fontSize: '2xs',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
  color: 'beta.500',
  lineHeight: 'normal',
};

export const IconApprovedBet = () => (
  <Icon as={CheckCircle} boxSize="4" mr="2" />
);

export const IconRejectedBet = () => <Icon as={Stop} boxSize="4" mr="2" />;
