import dayjs from 'dayjs';
import React, { ReactNode } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
  affiliateLocalStorageKey,
  myAffiliatesLocalStorageKey,
} from '@/hooks/usePageAnalytics';
import accountSetupSchema, {
  AccountSetupSchema,
} from '../../services/schemas/accountSetupSchema';
import { TPunterSignup } from '../../../../api/punter/punter.types';

type TFormikHOC = {
  children: ReactNode;
  handleSignUp: (newUser: TPunterSignup) => Promise<void>;
};

export default function FormikHOC({ children, handleSignUp }: TFormikHOC) {
  const handleFormSubmit = async (
    values: AccountSetupSchema,
    { setSubmitting }: FormikHelpers<AccountSetupSchema>
  ) => {
    setSubmitting(true);

    const newUser: TPunterSignup = {
      first_name: values.firstName,
      middle_name: values.middleName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      mobile: values.mobile,
      street_number: values.streetNumber,
      street_name: values.streetName,
      suburb: values.suburb,
      state: values.state,
      post_code: values.postcode,
      date_of_birth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
      email_marketing_enabled: values.promotionAcknowledgement,
      phone_marketing_enabled: values.promotionAcknowledgement,
      post_marketing_enabled: values.promotionAcknowledgement,
      sms_marketing_enabled: values.promotionAcknowledgement,
      affiliate_code:
        window.localStorage.getItem(affiliateLocalStorageKey) ?? undefined,
      myaffiliates_token:
        window.localStorage.getItem(myAffiliatesLocalStorageKey) ?? undefined,
      title: values.title,
      promo_code: values.promoCode?.length ? values.promoCode : undefined,
    };

    await handleSignUp(newUser);
    setSubmitting(false);
  };

  return (
    <Formik<AccountSetupSchema>
      initialValues={{
        email: '',
        password: '',
        ageAcknowledgement: false,
        promotionAcknowledgement: false,
        firstName: '',
        middleName: '',
        lastName: '',
        mobile: '',
        residential: '',
        dateOfBirth: dayjs().toDate(),
        manualAddress: false,
        streetNumber: '',
        streetName: '',
        suburb: '',
        state: '',
        postcode: '',
        title: '',
        promoCode: undefined,
      }}
      validationSchema={accountSetupSchema}
      onSubmit={handleFormSubmit}
    >
      {children}
    </Formik>
  );
}
