import { useEffect, useState } from 'react';
import { useQueryPunterStatements } from '@/api/punter/statements/statements.hooks';
import { TabOptionProps } from '@/components/ToggleAccountView/ToggleAccountView';
import { getThemeName } from '@/helpers/getThemeConfig';

export const useActivityStatements = () => {
  const themeName = getThemeName();

  const [selectedTab, setSelectedTab] = useState<
    TabOptionProps<string | undefined>
  >({
    display: '',
    value: '',
  });

  const { data, isLoading, error } = useQueryPunterStatements();

  useEffect(() => {
    const selectedYear = data?.[data.length - 1]?.year?.toString() ?? '';
    setSelectedTab({
      display: selectedYear,
      value: selectedYear,
    });
  }, [data]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  /* If year is current year only show the months up to current month else all 12 months */
  const months =
    currentYear === Number(selectedTab.value)
      ? Array.from({ length: currentDate.getMonth() + 1 }, (_, i) => i + 1)
      : Array.from({ length: 12 }, (_, i) => i + 1);

  const tabOptions = data
    ?.map((a) => ({
      display: a?.year?.toString() ?? '',
      value: a?.year?.toString() ?? '',
    }))
    .reverse();

  const downloadPDF = (pdf_link: string, month: string, year: number) => {
    const link = document.createElement('a');
    link.href = pdf_link;
    link.target = '_blank';
    link.download = `${themeName}-Statement-${month}-${year}`;
    link.click();
  };

  return {
    data,
    error,
    isLoading,
    months,
    tabOptions,
    selectedTab,
    setSelectedTab,
    downloadPDF,
  };
};
