import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/slambet/images/feature/featSportBg.png';
import featuredMb from '@/assets/slambet/images/feature/featSportBgMb.png';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};
export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  contain: 'paint',
  mb: ['2', null, '3'],
  mt: ['3', null, '5'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  border: 'none',
  borderRadius: 'lg',
  boxShadow:
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  pos: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};
export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};
export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'alpha.600',
  fontSize: 'md',
  fontWeight: 'normal',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'alpha.600',
  fontFamily: 'body',
  boxShadow: 'unset',
};
export const TextMisc: CSSObject = {
  background: 'rgba(78, 178, 255, 0.14)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'base',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  borderBottom: 'none',
  span: { display: 'inline-block', color: 'whiteAlpha.400', mx: '1' },
};
export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontFamily: 'accent',
  textShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
  pt: '1',
};
export const BoxHeadingWrapper: CSSObject = {
  fontFamily: 'Staatliches',
  fontSize: '3xl',
  fontWeight: 'normal',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  color: 'alpha.600',
};
export const puntButtonProps: CSSObject = {
  variant: 'solid',
  borderRadius: 'md',
  fontSize: 'sm',
};
export const HeadingSlogan: CSSObject = {
  fontFamily: 'accent',
  color: 'alpha.400',
  textTransform: 'capitalize',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  alignItems: 'center',
  fontSize: ['3xl', null, '3xl'],
};
