import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    color: 'white',
    fontSize: 'xl',
    fontFamily: 'accent',
    fontWeight: 'normal',
    px: ['2', null, '0'],
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
    px: ['2', null, '0'],
  },
  navOverride: {
    mb: '0',
  },
  divider: {
    mb: '0',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
};
