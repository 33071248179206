import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Text } from '@chakra-ui/react';
import { InfoCircle } from 'styled-icons/fa-solid';
import { TOddsChangedBannerProps } from '../../Services/Betslip.types';
import { OddsChangedContainer } from './OddsChangedBanner.styles';

export const OddsChangedBanner: React.FC<TOddsChangedBannerProps> = ({
  direction,
  isInFooter,
}) => (
  <OddsChangedContainer isInFooter={isInFooter} direction={direction}>
    <Icon mt="1px" boxSize={4} mr={2} as={InfoCircle} />
    <Text>
      <FormattedMessage
        id={`betslip.betslipbetcard.errors.oddsChange.${direction}`}
      />
    </Text>
  </OddsChangedContainer>
);
