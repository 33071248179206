import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import { CheckboxRunnerBoxed } from '../../Action/styles/Action.styles';

export const template = (
  isChecked: boolean,
  isDisabled: boolean,
  selectAllPositions: () => void,
  runnerNumber: number | undefined
): TTemplate => ({
  button: (
    <CheckboxRunnerBoxed
      data-testid={
        runnerNumber !== undefined
          ? `runnerCheckbox-${runnerNumber}`
          : 'fieldCheckbox'
      }
      area-selected={isChecked}
      data-cy={
        runnerNumber !== undefined
          ? `runnerCheckbox-${runnerNumber}`
          : 'fieldCheckbox'
      }
      isChecked={isChecked}
      isDisabled={isDisabled}
      onChange={selectAllPositions}
      variant="unstyled"
    />
  ),
});

export default template;
