import { CSSObject } from '@chakra-ui/react';

export const ButtonShowMore: CSSObject = {
  fontSize: 'xs',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    color: 'gamma.600',
  },

  _active: {
    bg: 'gamma.700',
    color: 'white',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  },
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
};
