import { css } from 'styled-components';

export const pageHeaderWrapper = css`
  background: ${({ theme }) => theme.basePalette.navyBlue};
`;

export const pageHeaderContainer = css``;

export const pageHeaderText = css`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: var(--bc-fontSizes-xl);
  color: ${({ theme }) => theme.palette.primary};
  text-transform: uppercase;
`;

export const contentContainer = css`
  background: transparent;
`;

export const otherLinks = css`
  padding: 16px;
  color: ${({ theme }) => theme.basePalette.brightBlueB};
  text-decoration: none;
  font-size: var(--bc-fontSizes-xs);

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.basePalette.midPurple};
  }
`;
