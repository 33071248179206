import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  divider: {
    mb: '0',
    border: 'none',
    display: 'none',
  },
  hasNextPageWellOverride: {
    w: 'full',
  },
  pageHeaderText: {
    px: ['2', '0'],
  },
};
