import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bg: 'white',
  borderColor: 'alpha.500',
  color: 'alpha.600',
  h: 'full',

  _hover: {
    bg: 'alpha.500',
    color: 'white',
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'white',
};
