import {
  footerBaseStyle,
  TFooterTheme,
} from '../../base/customComponents/footer';
import { TStyleConfig } from '../../base/types';

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: {
    ...footerBaseStyle,
    footer: {
      ...footerBaseStyle.footer,
    },
    stackGap: '1',
    footerBar: {},
    footerLink: {},
    footerLinkTel: {},
    footerMobile: {},
    customFooterLink: {},
    footerLinks: {},
    footerContentContainer: {
      ...footerBaseStyle.footerContentContainer,
    },
    nav: {
      ...footerBaseStyle.nav,
    },
    blurb: {
      ...footerBaseStyle.blurb,
    },
    blurbHeading: {},
    blurbHeadingAge: {
      ...footerBaseStyle.blurbHeadingAge,
    },
    blurbHeadingThink: {},
    blurbContent: {
      ...footerBaseStyle.blurbContent,
    },
    copyright: {
      ...footerBaseStyle.copyright,
    },
    container: {},
    emailUs: {},
    quickLinks: {},
    weAcceptBox: {},
    weAccept: {},
    imageWrapper: {},
    partnerWrapper: {},
    ourPartners: {},
  },
  sizes: {},
  variants: {},
};
