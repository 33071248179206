/* eslint-disable @typescript-eslint/no-use-before-define */
import { Flex, Box, Text, Link, Skeleton, Grid } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as LinkDom } from 'react-router-dom';
import { TNextToJumpResponse } from '@/api/nextToJump/nextToJump.types';
import { useQuerySupportedBets } from '@/api/punter/supportedBets/supportedBets.hooks';
import { useQueryRunnerList } from '@/api/racing/racing.hooks';
import { srcDefaultSilk } from '@/assets/core';
import {
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import {
  EBetSlipBetSubmissionType,
  TBetSlipBet,
} from '@/components/Betslip/Services/Betslip.types';
import { getEventRule } from '@/components/Betslip/Services/Betslip.utils';
import Countdown from '@/components/Countdown/Countdown';
import {
  getIconBySportName,
  getStrings,
  isPendingAndMatchingId,
  propIsSuspended,
} from '@/helpers/utils';
import { useAppSelector } from '@/hooks/useRedux';
import { EGeneralStatus, ERaceType, TPriceType } from '@/lib/DBModels';
import { sortRunners } from '@/views/Home/races/utils';
import {
  getBetSlipStoreActions,
  transformBetForLegacy,
  useBetSlipBets,
} from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { Button } from '@/components/Button/Button';
import {
  raceCardStyles,
  TextRunner,
  ImageRunnerSilk,
  IconCardHeader,
} from './styles/RaceCards.styles';

/**
 * Containers
 */
type CardContainerProps = Omit<TRaceCard, 'bets'>;

const CardReduxContainer = (props: CardContainerProps) => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <Card bets={bets} {...props} />;
};

const CardZustandContainer = (props: CardContainerProps) => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <Card bets={bets} {...props} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? CardZustandContainer
  : CardReduxContainer;

// ----

/**
 * View
 */

type TRaceCard = {
  race: TNextToJumpResponse[0];
  bets: TBetSlipBet[];
};

function Card({ race, bets }: TRaceCard) {
  const { setBet } = getBetSlipStoreActions();

  const { data, isInitialLoading } = useQueryRunnerList({
    key: [race.race_id ?? ''],
    params: { race_id: race.race_id ?? '' },
    options: {
      enabled: !!race.race_id,
    },
  });

  const [
    {
      Generic,
      Racing: {
        RaceRunnerItem: { suspended },
      },
    },
  ] = getStrings();

  const to = `/racing/${race.race_type}/${race.venue_name}/R${race.race_number}?venueId=${race.venue_id}&raceId=${race.race_id}&meetingDate=${race.meeting_date}`;

  const { addRaceRunnerBetSlip } = useSingleBetSlip();
  const { singlePropositionInBetSlip, removeSinglePropositionFromBetSlip } =
    useBetSlipManageBets();
  const supportedBets = useQuerySupportedBets();
  const spBetsAreSupported = supportedBets.data?.starting_price !== false;

  return (
    <Flex {...raceCardStyles.FlexCard} data-cy={`raceCard_${race.race_id}`}>
      <Link {...raceCardStyles.LinkCardHeader} as={LinkDom} to={to}>
        <Flex align="center">
          <IconCardHeader
            data-cy="raceCardIcon"
            name={getIconBySportName(race.race_type)}
          />
          <Text {...raceCardStyles.TextCardHeading} data-cy="raceCardHeading">
            {race.venue_name}
          </Text>
        </Flex>

        <Flex align="center">
          <Countdown eventTime={race.start_time ?? ''} dateFormat="HH:mm" />
          <Text {...raceCardStyles.TextRaceNumber} data-cy="raceCardRaceNumber">
            R{race.race_number}
          </Text>
        </Flex>
      </Link>

      <Grid {...raceCardStyles.GridCardContent}>
        <Box {...raceCardStyles.BoxGridHeaderItem}>{Generic.Runners}</Box>
        <Box {...raceCardStyles.BoxGridHeaderItem}>{Generic.Win}</Box>

        {(() => {
          if (isInitialLoading)
            return [...new Array(10)].map((_, i) => (
              <Skeleton
                key={`card-skel-${i}`}
                sx={{
                  h: '40px',
                  borderRadius: 'none',
                  mb: 'px',
                }}
              />
            ));

          return sortRunners(data ?? [])
            .slice(0, 5)
            .map((r) => {
              const isScratched =
                r?.is_scratched || r?.status === EGeneralStatus.Scratched;
              const isSuspended = propIsSuspended(
                r.win_proposition,
                r.win_odds
              );
              const isInBetslip = !!singlePropositionInBetSlip(
                r.win_proposition?.proposition_id
              );
              const eventRule = getEventRule(race.number_of_places);
              const priceTypes = r.win_proposition?.price_types ?? [];
              const isSPProps =
                (priceTypes.includes('starting') && priceTypes.length === 1) ||
                (priceTypes.includes('starting') &&
                  priceTypes.includes('tote_single_mid') &&
                  priceTypes.length === 2);
              const priceType: TPriceType = isSPProps ? 'starting' : 'fixed';

              return (
                <Fragment key={`rc-${r.race_runner_id}`}>
                  <Flex {...raceCardStyles.FlexGridRunnerItem}>
                    <ImageRunnerSilk
                      data-cy={`raceCardSilk-${r.number}`}
                      src={r.silk_url}
                      fallbackSrc={srcDefaultSilk}
                      isGreyhound={race?.race_type === ERaceType.Greyhound}
                    />
                    <TextRunner
                      data-cy={`raceCardRunnerText-${r.number}`}
                      isSuspended={isScratched}
                      {...raceCardStyles.TextRunner}
                    >
                      <Text {...raceCardStyles.TextRunnerNumber} as="span">
                        {r.number}.
                      </Text>{' '}
                      {r.display_name?.toLocaleLowerCase()}{' '}
                      {!!r.barrier_number && (
                        <Text
                          {...raceCardStyles.TextRunnerNumber}
                          data-cy={`raceCardBarrierNumber-${r.number}`}
                          as="span"
                        >
                          {`(${r.barrier_number})`}
                        </Text>
                      )}
                    </TextRunner>
                  </Flex>

                  <Flex {...raceCardStyles.FlexGridRunnerItem}>
                    {isScratched ? (
                      <Text {...raceCardStyles.TextScratched}>
                        {Generic.Scratched}
                      </Text>
                    ) : (
                      <Button
                        {...raceCardStyles.buttonRaceCardOddsProps}
                        data-cy={`odds-button-${r.display_name}`}
                        isActive={isInBetslip}
                        data-active={isInBetslip}
                        isDisabled={
                          isSuspended ||
                          isPendingAndMatchingId(
                            bets,
                            r.win_proposition?.proposition_id
                          ) ||
                          (priceType === 'starting' && !spBetsAreSupported)
                        }
                        onClick={() => {
                          setBet({
                            id: r.win_proposition?.proposition_id ?? '',
                            type: 'Single',
                            odds: r.win_odds ?? 0,
                            propId: r.win_proposition?.proposition_id ?? '',
                            priceType,
                            betType: 'win',
                            misc: {
                              ...r,
                              ...race,
                              race,
                              runner: r,
                            },
                          });

                          if (isInBetslip) {
                            removeSinglePropositionFromBetSlip(
                              EBetSlipBetSubmissionType.Single,
                              r.win_proposition?.proposition_id ?? ''
                            );
                          } else {
                            addRaceRunnerBetSlip(
                              'win',
                              r,
                              { venue_display_name: race.venue_name },
                              race,
                              eventRule,
                              priceType
                            );
                          }
                        }}
                      >
                        {(() => {
                          if (isSuspended) {
                            return suspended;
                          }

                          if (priceType === 'starting') {
                            return (
                              <FormattedMessage id="generic.startingPriceAcronym" />
                            );
                          }

                          return r.win_odds?.toFixed(2);
                        })()}
                      </Button>
                    )}
                  </Flex>
                </Fragment>
              );
            });
        })()}
      </Grid>

      <Link
        {...raceCardStyles.LinkRaceDetails}
        data-cy={`viewRaceCardLink-${race.venue_name}`}
        to={to}
        as={LinkDom}
      >
        <FormattedMessage id="home.viewracecard" />
      </Link>
    </Flex>
  );
}
