import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import { styleImports } from './SelectionPopup.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FlexWrapper = chakra(Flex, {
  label: 'selectionPopup-FlexWrapper',
  baseStyle: ({ theme }) => ({
    p: ['3', '5'],
    flexDir: ['column', 'row'],
    rowGap: ['2', 'unset'],
    columnGap: ['unset', '4'],

    button: { width: ['100%', 'unset'] },

    ...(theme.themeName === EThemes.Wellbet && {
      px: ['3', null, '2.5'],
      '&&': {
        py: '3',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      p: '3',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexInputWrapper = chakra(Flex, {
  label: 'selectionPopup-FlexInputWrapper',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    flex: '1',
    flexDir: ['column', 'row'],
    rowGap: ['2', 'unset'],
    columnGap: ['unset', '4'],

    '.chakra-form-control': {
      display: 'flex',
      alignItems: 'center',
      mb: 0,
      w: ['100%', null, 'unset'],
    },

    '& label': {
      color: 'currentColor',
      whiteSpace: 'nowrap',
      mb: 0,
      flexBasis: ['45%', 'unset'],
    },

    ...(theme.themeName === EThemes.Wellbet && {
      label: {
        color: 'white',
        fontWeight: 'bold',
      },
      '.chakra-input__left-addon': {
        border: 'none',
        bg: 'gray.300',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      h: '10',
      '.chakra-input__left-addon': {
        border: 'none',
        bg: 'gray.300',
      },
      '.chakra-input__group': {
        borderRadius: 'base',
        border: '1px solid',
        borderColor: 'gray.300',
        w: ['full', null, '200px', null, null, '230px'],
      },

      label: {
        fontWeight: 'bold',
        color: 'white',
      },
      input: {
        backgroundColor: 'white',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexInputWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexButtonWrapper = chakra(Flex, {
  label: 'selectionPopup-FlexButtonWrapper',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      h: '10',
    }),
  }),
});

export const SelectionPopupContainer = chakra(Box, {
  label: 'selectionPopup-SelectionPopupContainer',
  baseStyle: ({ theme }) => ({
    position: ['fixed', null, 'sticky'],
    bottom: '0',
    bg: 'white',
    left: ['0', null, 'unset'],
    w: ['100%', null, 'unset'],
    ...(theme.themeName === EThemes.Wellbet && {
      mt: '4',
      bg: 'linear-gradient(var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      mt: '4',
      bg: 'beta.300',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.SelectionPopupContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const InfoHeader = chakra(Flex, {
  label: 'selectionPopup-InfoHeader',
  baseStyle: ({ theme }) => ({
    justifyContent: 'space-between',
    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.600',
      h: '7',
      px: '2',
      py: '1.5',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'beta.400',
      h: '7',
      px: '2',
      py: '1.5',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.InfoHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const InfoText = chakra(Text, {
  label: 'selectionPopup-InfoText',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'xs',
      color: 'white',
      fontWeight: 'medium',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'xs',
      color: 'white',
      fontWeight: 'medium',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.InfoText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const buttonAddToBetslipProps: CustomButtonProps = {
  'data-testid': 'selectionPopup-buttonAddToBetslipProps',
  h: '10',

  ...(styleImports?.[themeName]?.buttonAddToBetslipProps ?? {}),
};

export const toteMultiListSelections: CSSObject = {
  'data-testid': 'selectionPopup-toteMultiListSelections',
  ...(styleImports?.[themeName]?.toteMultiListSelections ?? {}),
};

export const TextAddToBetslipButton = chakra(Text, {
  label: 'selectionPopup-TextAddToBetslipButton',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextAddToBetslipButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const toteMultiListSelectionsIcon: CSSObject = {
  'data-testid': 'selectionPopup-toteMultiListSelectionsIcon',
  ...(styleImports?.[themeName]?.toteMultiListSelectionsIcon ?? {}),
};
