import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  px: ['0', '4'],
};

export const BoxWrapper: CSSObject = {
  border: 'none',
  mb: '0',
};

export const LinkItem = (
  isFeatured: boolean,
  isFirstItem: number,
  isLastItem: number
) => ({
  p: '2',
  py: '3',
  borderTop: '1px',
  borderTopColor: 'blackAlpha.600',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  color: 'white',
  transition: 'all, .1s ease-in-out',
  borderTopRadius: isFirstItem ? 'md' : 'none',
  borderBottomRadius: isLastItem ? 'md' : 'none',

  _hover: { bgGradient: 'linear(to-b, beta.700, beta.800)' },

  svg: {
    boxSize: '6',
    p: '1',
    color: 'white',
    border: '1px',
    borderColor: 'white',
    borderRadius: 'full',
  },
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};
