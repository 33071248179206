import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/titanbet/images/race-bg.png';

export const RunnerListItemWrapper: CSSObject = {
  p: '3',
  mb: '2',
  borderRadius: 'lg',
  bgImage: `url(${background})`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
};

export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  position: 'absolute',
  pl: '0.5',
};

export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    pl: ['0', null, '2'],
  },
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
};

export const TextDeductions: CSSObject = {
  color: 'white',
};

export const TextScratchedInfo: CSSObject = {
  color: 'alpha.300',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const ScratchedText: CSSObject = {
  color: 'gray.700',
  fontWeight: 'semibold',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  h: '7',
  w: '9',
};
