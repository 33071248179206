import React from 'react';
import { Collapse, Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { srcDefaultSilk } from '../../../../assets/core/index';
import { centsToDollars, getMarketByType } from '@/helpers/utils';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  ERaceType,
  TRunner,
} from '@/lib/DBModels';
import { useRaceMarkets } from '../../services/RaceDetails.hooks';
import RaceRunnerForm from '../RaceRunnerForm/RaceRunnerForm';
import RaceRunnerPropButton from '../RaceRunnerPropButton/RaceRunnerPropButton';
import {
  FlexDeductionsContainer,
  FlexFlucsPopupContainer,
  GreyhoundRunnerSilk,
  HorseHarnessRunnerSilk,
  RunnerListItemContainer,
  RunnerListItemWrapper,
  RunnerName,
  RunnerNameAndSilk,
  RunnerPropositionWrapper,
  RunnerScratchedWrapper,
  TextBarrierNumber,
  TextDeductions,
  TextScratchedInfo,
} from './styles/RaceRunnerListItem.styles';
import { TPunterSupportedBetsResponse } from '@/api/punter/supportedBets/supportedBets.types';
import Flucs from '../Flucs/Flucs';
import Badges from '../Badges/Badges';
import { useAppSelector } from '@/hooks/useRedux';

type TRaceRunnerListItemProps = {
  runner: TRunner;
  shouldShowPlaceOdds?: boolean;
  isInsightsActive?: boolean;
  isFlucsVisible?: boolean;
  isFavourite?: boolean;
  isToteActive?: boolean;
  supportedBets: TPunterSupportedBetsResponse;
};

export default function RaceRunnerListItem(props: TRaceRunnerListItemProps) {
  const {
    runner,
    shouldShowPlaceOdds = true,
    isInsightsActive = true,
    isFlucsVisible = true,
    isFavourite = false,
    isToteActive = false,
    supportedBets,
  } = props;

  const { status } = runner ?? {};
  const isScratched =
    status &&
    [
      EGeneralStatus.Scratched,
      EGeneralStatus.Voided,
      EGeneralStatus.Abandoned,
    ].includes(status);

  const isResulted = status === EGeneralStatus.Settled;
  const { scratch_time: rawScratchTime } = runner;
  const scratchTime = rawScratchTime ? new Date(rawScratchTime) : null;
  const { racingType } = useParams();
  const { markets } = useRaceMarkets();
  const { RacingWin, RacingPlace } = EBetTypesDisplayNames;
  const winMarket = getMarketByType(markets, RacingWin);
  const placeMarket = getMarketByType(markets, RacingPlace);
  const { raceRunnerList } = useAppSelector((state) => state.racing);

  const SilkComponent =
    racingType === ERaceType.Greyhound
      ? GreyhoundRunnerSilk
      : HorseHarnessRunnerSilk;

  const handleError: React.ReactEventHandler<HTMLImageElement> = ({
    currentTarget,
  }) => {
    /* eslint-disable no-param-reassign */
    currentTarget.onerror = null;
    currentTarget.src = srcDefaultSilk;
    /* eslint-enable no-param-reassign */
  };

  const hasWinTOTE =
    (runner.win_proposition?.price_types?.includes('tote_single_mid') ||
      runner.win_proposition?.price_types?.includes('tote_single_best')) &&
    isToteActive;
  const hasPlaceTOTE =
    runner.place_proposition?.price_types?.includes('tote_single_mid') &&
    isToteActive;

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const showTotes: boolean = isLargerThan768 ? true : isToteActive;
  const shouldRenderWinMarkets = isLargerThan768 ? true : !isToteActive;

  const isOpen = raceRunnerList.raceMeta.status === EGeneralStatus.Open;

  const hasWinDeduction = (runner.win_deductions ?? 0) > 0;
  const hasPlaceDeduction = (runner.place_deductions ?? 0) > 0;

  const badgeSection = isFlucsVisible ? (
    <Collapse in={isInsightsActive} animateOpacity>
      <Box className="badgeCollapse">
        <Badges form_data={runner.form_data} />
      </Box>
    </Collapse>
  ) : (
    <Collapse in={isInsightsActive} animateOpacity>
      <Badges form_data={runner.form_data} />
    </Collapse>
  );

  return (
    <RunnerListItemWrapper scratched={isScratched} data-cy="raceRunnerListItem">
      <RunnerListItemContainer>
        <RunnerNameAndSilk>
          <SilkComponent
            src={runner.silk_url ?? srcDefaultSilk}
            onError={handleError}
            data-cy={
              racingType === ERaceType.Greyhound
                ? 'greyhoundRunnerSilk'
                : 'horseHarnessRunnerSilk'
            }
          />
          <Flex flexDir="column">
            <RunnerName as="div" isScratched={isScratched} data-cy="runnerName">
              <FormattedMessage
                id="racing.raceRunnerItem.runnerName"
                values={{
                  number: runner.number,
                  displayName: (
                    <div data-cy="displayName">
                      {runner.display_name?.toLocaleLowerCase()}
                    </div>
                  ),
                  barrierNumber: runner.barrier_number,
                  isHarnessRacing: racingType === ERaceType.Harness,
                  span: (chunks) => (
                    <TextBarrierNumber as="span" data-cy="barrierNumber">
                      {chunks}
                    </TextBarrierNumber>
                  ),
                }}
              />
            </RunnerName>

            {isScratched ? (
              <TextScratchedInfo data-cy="scratchedInfo">
                <FormattedMessage
                  id="racing.raceRunnerItem.scratchedInfo"
                  values={{
                    scratchTime,
                    time: (chunks) => (
                      <time
                        data-cy="scratchedInfoTime"
                        dateTime={rawScratchTime || undefined}
                      >
                        {chunks}
                      </time>
                    ),
                    hasScratchTime: !!rawScratchTime,
                  }}
                />
              </TextScratchedInfo>
            ) : (
              <RaceRunnerForm runner={runner} />
            )}
          </Flex>
        </RunnerNameAndSilk>

        {!isScratched && winMarket ? (
          <RunnerPropositionWrapper shouldShowPlaceOdds={shouldShowPlaceOdds}>
            {shouldRenderWinMarkets && (
              <RaceRunnerPropButton
                isFavourite={isFavourite}
                betType={RacingWin}
                runner={runner}
              />
            )}

            {shouldShowPlaceOdds && placeMarket && shouldRenderWinMarkets && (
              <RaceRunnerPropButton betType={RacingPlace} runner={runner} />
            )}
            {!isResulted &&
              supportedBets?.tote_singles !== false &&
              hasWinTOTE &&
              showTotes && (
                <RaceRunnerPropButton
                  betType={RacingWin}
                  runner={runner}
                  isTote
                />
              )}
            {!isResulted &&
              supportedBets?.tote_singles !== false &&
              hasPlaceTOTE &&
              showTotes && (
                <RaceRunnerPropButton
                  betType={RacingPlace}
                  runner={runner}
                  isTote
                />
              )}
          </RunnerPropositionWrapper>
        ) : (
          <FlexDeductionsContainer>
            {(hasWinDeduction || hasPlaceDeduction) && (
              <>
                <RunnerScratchedWrapper>
                  {hasWinDeduction && (
                    <Text data-cy="winDeductions">
                      W {centsToDollars(runner.win_deductions)}
                    </Text>
                  )}
                  {hasPlaceDeduction && (
                    <Text data-cy="placeDeductions">
                      P {centsToDollars(runner.place_deductions)}
                    </Text>
                  )}
                </RunnerScratchedWrapper>
                <TextDeductions data-cy="deductionsApplied">
                  <FormattedMessage id="racing.raceRunnerItem.deductions" />
                </TextDeductions>
              </>
            )}
          </FlexDeductionsContainer>
        )}
      </RunnerListItemContainer>
      <FlexFlucsPopupContainer>
        {!isScratched && runner.flucs_data && (
          <Collapse in={isFlucsVisible} animateOpacity>
            <Flucs isRaceOpen={isOpen} flucs={runner.flucs_data} />
          </Collapse>
        )}
        {!isScratched &&
          runner &&
          !!runner.form_data?.badges?.length &&
          badgeSection}
      </FlexFlucsPopupContainer>
    </RunnerListItemWrapper>
  );
}
