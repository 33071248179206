import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  borderRadius: 'base',
  bg: 'epsilon.700',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '24px',
  display: 'inline-block',
  fontSize: '2xs',
  h: '5',
};

export const LinkRaceContainer: CSSObject = {
  color: 'white',
  mb: '0',

  _hover: {
    bg: 'blackAlpha.300',
  },

  '.chakra-badge.state-prior': {
    fontWeight: 'semibold',
    fontSize: '2xs',
    px: '0',
  },
  '.chakra-badge.state-inprogress': {
    color: 'beta.900',
    fontSize: '2xs',
  },
  '.chakra-badge.state-ended': {
    fontSize: '2xs',
  },
};

export const TextRaceType: CSSObject = {
  color: 'alpha.500',
  bg: 'epsilon.500',
  h: '34px',
  m: 'auto',
  pt: '1.5',
  fontWeight: 'normal',
  fontSize: 'xl',
  fontFamily: 'Caveman',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
};

export const BoxColumn: CSSObject = {
  bg: 'epsilon.400',
  border: '3px solid',
  borderColor: 'epsilon.200',
  boxShadow: '0px 4px 0px #6E7373, inset 0px 0px 10px rgba(0, 0, 0, 0.6)',
  borderRadius: 'md',
  mb: ['4', 'unset'],
};

export const Icon: CSSObject = {
  boxSize: '5',
};
