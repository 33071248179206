import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  borderRadius: 'base',
  overflow: 'hidden',

  '.chakra-accordion__item': {
    _first: {
      borderTop: '0',
    },

    _last: {
      borderBottom: '0',
    },
  },

  '.chakra-accordion__panel': {
    bg: 'gray.200',
    color: 'gray.600',
  },

  '.chakra-checkbox__control': {
    bg: 'white',

    _checked: {
      bg: 'black',
    },
  },
};

export const AccordionHeader: CSSObject = {
  bgGradient: 'linear(to-b,beta.200, beta.400)',
  color: 'beta.600',
  transitionDuration: 'normal',
  transitionProperty: 'common',
};

export const AccordionHeaderLogOut: CSSObject = {
  borderRadius: 'base',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderWidth: '0',
  _hover: {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
  },
};

export const IconPerson: CSSObject = {
  boxSize: ['5', null, '6'],
  mr: '1.5',
};

export const TextAccordion: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  fontWeight: 'semibold',
  mr: 'auto',
};

export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  bg: 'beta.600',
  borderRadius: 'base',
  boxShadow: '0 4px 5px rgba(0, 0, 0, .1)',
  color: 'white',
  fontSize: 'xs',
  gap: '1',
  p: '2',
};

export const OverviewPromotionsPanel: CSSObject = {
  p: '0',
  '.chakra-form-control': {
    color: 'gray.700',
  },
};
