import { CSSObject } from '@chakra-ui/react';
import raceCardBg from '@/assets/titanbet/images/homeTheming/raceCardBg.png';

export const LinkWrapper: CSSObject = {
  px: 3,
  py: 2,
  mb: 2,
  border: '2px solid',
  borderColor: 'gamma.500',
  borderRadius: 'lg',
  bgImage: `url(${raceCardBg})`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'gamma.300' }),
  fontFamily: 'body',
  fontSize: 'xs',
  fontWeight: 'semibold',
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'gamma.300',
  fontSize: 'xs',
  fontWeight: 'semibold',
  fontFamily: 'body',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'beta.600',
  boxSize: '30px',
  boxShadow:
    '0px 0px 0px 2px #1C1F1F inset, 0px 0px 0px 3px #706F6F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-300))',
  borderRadius: 'lg',
  p: '1',
};

export const IconChevron: CSSObject = {
  color: 'gamma.300',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: 'xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.400',
    boxSize: '5',
  },
};
