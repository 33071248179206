import { Search } from '@styled-icons/bootstrap/Search';
import { CompetitionListSchema } from '@/views/sports/components/CompetitionList/styles/CompetitionList.styles';

export const competitionListStyles: CompetitionListSchema = {
  skeleton: {
    startColor: 'alpha.300',
    endColor: 'alpha.400',
    minW: 'full',
    h: '20',
    borderRadius: 'md',
    flex: 1,
    mt: '8',
  },
  input: {
    name: 'x',
    sxWrapper: { mb: '0' },
    boxShadow: 'none',
    borderRadius: 'base',
    mb: '0',
    border: 'none',
    _hover: {
      '&&': {
        bg: 'blackAlpha.400',
        _placeholder: {
          color: 'white.700',
        },
        border: 'none',
        boxShadow: 'none',
      },
      color: 'gamma.200',
    },
    _focus: {
      '&&': {
        _placeholder: {
          color: 'whiteAlpha.700',
        },
        border: 'none',
        boxShadow: 'none',
      },
      color: 'gamma.200',
      textShadow: 'sm',
    },
    _placeholder: {
      color: 'whiteAlpha.700',
      fontWeight: 'bold',
      fontSize: '2xs',
    },
    sx: {
      '&&': {
        bg: 'blackAlpha.400',
        color: 'white',
        _placeholder: {
          color: 'whiteAlpha.700',
        },
        '&&': {
          border: 'none',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
        },
      },
    },
  },
  inputRightIcon: {
    color: 'beta.500',
    boxSize: '4',
    as: Search,
  },
  accordion: {
    color: 'white',
    mb: '3',
    variant: 'unstyled',
    w: 'full',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    py: '2',
  },
  accordionButton: {
    px: '0',
    pos: 'relative',
    _hover: { bg: 'transparent' },
  },
  hStack: {
    w: 'full',
    alignItems: 'center',
  },
  flex: {
    alignItems: 'center',
    gap: 2,
    flex: 1,
    textAlign: 'left',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  accordionButtonIconSvg: {
    color: 'beta.500',
    fontSize: 'md',
  },
  accordionButtonText: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'accent',
    textTransform: 'uppercase',
  },
  marketTypesFlex: {
    mr: '2',
    gap: '2',
  },
  marketTypesText: {
    w: ['50px', '100px'],
    fontSize: '2xs',
    fontWeight: 'bold',
    color: 'white',
  },
  accordionButtonInnerIcon: {
    pos: 'absolute',
    right: '0',
    boxSize: '6',
    color: 'white',
  },
  accordionPanel: {
    p: '0',
    mb: '3',
  },
};
