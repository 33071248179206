import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { InfoCircleFill } from '@styled-icons/bootstrap/InfoCircleFill';
import { StyledIcon } from '@styled-icons/styled-icon';
import { useSupportedBets } from '@/components/Betslip/Services/Betslip.hooks';
import { useAppSelector } from '@/hooks/useRedux';
import {
  ETabbarBetTypes,
  EBetTypesDisplayNames,
  EGeneralStatus,
} from '@/lib/DBModels';
import { TExoticTab } from '@/views/races/bets/Exotics/Exotics.types';
import { SUB_TABS_DISPLAY_NAME } from '../TabBar';
import { getFeatureFlag } from '@/store/FeatureFlagStore';
import { useAuth } from '@/hooks/useAuth';

export const useDisplayMarkets = () => {
  const { isAuthenticated } = useAuth();
  const { raceNumber } = useParams();
  const [searchParams] = useSearchParams();
  const venueId = searchParams.get('venueId');

  const { supportedBets } = useSupportedBets();

  const {
    raceRunnerList: { markets, venues },
  } = useAppSelector((state) => state.racing);

  const { pathname, search: searchParamsLocation } = useLocation();

  const search = useMemo(() => {
    const s = new URLSearchParams(searchParamsLocation);
    s.delete('propId');
    return `?${s.toString()}`;
  }, [searchParamsLocation]);

  const isSports = pathname.startsWith('/Sports');

  const removeCurrent = pathname.endsWith(raceNumber ?? '')
    ? pathname
    : pathname.split('/').slice(0, -1).join('/');

  const [displayMarkets, setDisplayMarkets] = useState<
    {
      key: string;
      name: EBetTypesDisplayNames;
      to: string;
      icon?: StyledIcon;
      subType?: TExoticTab[];
    }[]
  >([
    {
      key: '',
      name: isSports
        ? EBetTypesDisplayNames.TopMarket
        : EBetTypesDisplayNames.WinPlace,
      to: `${removeCurrent}${search}`,
      icon: InfoCircleFill,
      subType: [],
    },
  ]);

  useEffect(() => {
    if (isSports) return;
    const currentTime = dayjs();

    const marketTypes = markets
      ? markets
          ?.filter((m) => m.status !== EGeneralStatus.Abandoned)
          ?.filter((market) => {
            if (market.market_name?.toLowerCase() === 'blended') {
              const openAtTime = market.open_at_time;
              if (openAtTime) {
                const marketTime = dayjs(openAtTime);
                return currentTime.isAfter(marketTime);
              }
            }

            return true;
          })
          .map((v) => v.market_type)
      : [];

    const isWinMarket =
      marketTypes.length === 1 && marketTypes.includes('Racing Win');

    const sortOrder: { [key: string]: number } = {
      Blended: 1,
      SRMulti: 2,
      EvenShot: 3,
      Exacta: 4,
      Quinella: 5,
      Trifecta: 6,
      FirstFour: 7,
      ToteMulti: 8,
    };

    const sortedMarketTypes = marketTypes.sort((a, b) => {
      if (a === undefined || b === undefined) {
        return 0; // Handle undefined values as equal
      }

      const indexA = sortOrder[a.replaceAll(' ', '')] || Infinity;
      const indexB = sortOrder[b.replaceAll(' ', '')] || Infinity;

      return indexA - indexB;
    });

    setDisplayMarkets([
      {
        key: '',
        name: isWinMarket
          ? EBetTypesDisplayNames.Win
          : EBetTypesDisplayNames.WinPlace,
        to: `${removeCurrent}${search}`,
        icon: InfoCircleFill,
      },
      ...sortedMarketTypes.reduce((acc, cur) => {
        const exoticMarkets = ['Exacta', 'Quinella', 'Trifecta', 'FirstFour'];
        const isEvenShot = cur === 'Even Shot';
        const isBlended = cur === 'Blended';

        if (isEvenShot && supportedBets?.even_shot === false) return acc;
        if (isBlended && supportedBets?.multi === false) return acc;

        if (
          supportedBets &&
          supportedBets.exotics === false &&
          exoticMarkets.includes(cur ?? '')
        ) {
          return acc;
        }

        const { TOTE_MULTI_ENABLED: isToteMultiEnabled } =
          getFeatureFlag() ?? {};

        // Quaddies
        const hasMultiples = acc.find((a) => a.key === 'Multiples');

        let canUserSeeMultiples = false;

        if (
          (isAuthenticated && supportedBets && supportedBets.tote_multi) ||
          !isAuthenticated
        )
          canUserSeeMultiples = true;

        if (
          isToteMultiEnabled &&
          !hasMultiples &&
          [
            EBetTypesDisplayNames.Quaddie,
            EBetTypesDisplayNames.BigSix,
            EBetTypesDisplayNames.RunningDouble,
            EBetTypesDisplayNames.DailyDouble,
            EBetTypesDisplayNames.Treble,
            EBetTypesDisplayNames.EarlyQuaddie,
          ].includes(cur as EBetTypesDisplayNames) &&
          canUserSeeMultiples
        ) {
          let runningDouble = null;

          if (sortedMarketTypes.includes(EBetTypesDisplayNames.RunningDouble)) {
            const venue = venues?.find((v) => v.venue_id === venueId);
            const runningDoubles =
              venue?.race_venue_totes?.totes_by_type[
                EBetTypesDisplayNames.RunningDouble
              ];
            const num = Number(raceNumber?.slice(1));

            runningDouble = runningDoubles
              ?.filter((rd) => rd.legs.includes(num))
              .sort((a, b) => {
                const aI = a.legs[0];
                const bI = b.legs[0];

                return aI - bI;
              })
              .map((rd) => ({
                key: EBetTypesDisplayNames.RunningDouble,
                name: `${
                  SUB_TABS_DISPLAY_NAME[EBetTypesDisplayNames.RunningDouble]
                } (${rd.legs.join(', ')})`,
                to: `${removeCurrent}/${ETabbarBetTypes.RunningDouble}${search}&propId=${rd.proposition_id}`,
                propositionId: rd.proposition_id,
              }));
          }

          const multies = [
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.Quaddie
            )
              ? [
                  {
                    key: EBetTypesDisplayNames.Quaddie,
                    name: EBetTypesDisplayNames.Quaddie,
                    to: `${removeCurrent}/${ETabbarBetTypes.Quaddie}${search}`,
                  },
                ]
              : []),
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.BigSix
            )
              ? [
                  {
                    key: EBetTypesDisplayNames.BigSix,
                    name: EBetTypesDisplayNames.BigSix,
                    to: `${removeCurrent}/${ETabbarBetTypes.BigSix}${search}`,
                  },
                ]
              : []),
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.RunningDouble
            ) && runningDouble
              ? runningDouble
              : []),
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.DailyDouble
            )
              ? [
                  {
                    key: EBetTypesDisplayNames.DailyDouble,
                    name: EBetTypesDisplayNames.DailyDouble,
                    to: `${removeCurrent}/${ETabbarBetTypes.DailyDouble}${search}`,
                  },
                ]
              : []),
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.Treble
            )
              ? [
                  {
                    key: EBetTypesDisplayNames.Treble,
                    name: EBetTypesDisplayNames.Treble,
                    to: `${removeCurrent}/${ETabbarBetTypes.Treble}${search}`,
                  },
                ]
              : []),
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.EarlyQuaddie
            )
              ? [
                  {
                    key: EBetTypesDisplayNames.EarlyQuaddie,
                    name: EBetTypesDisplayNames.EarlyQuaddie,
                    to: `${removeCurrent}/${ETabbarBetTypes.EarlyQuaddie}${search}`,
                  },
                ]
              : []),
          ].sort((a, b) => {
            const quaddieSortOrder: { [key: string]: number } = {
              Quaddie: 1,
              EarlyQuaddie: 2,
              RunningDouble: 3,
              DailyDouble: 4,
              Treble: 5,
              BigSix: 6,
            };

            const indexA =
              quaddieSortOrder[a.name.replaceAll(' ', '')] || Infinity;
            const indexB =
              quaddieSortOrder[b.name.replaceAll(' ', '')] || Infinity;

            return indexA - indexB;
          });

          acc.push({
            key: 'Multiples',
            name: EBetTypesDisplayNames.Multiples,
            to: `${multies[0]?.to}`,
            subType: multies,
          });

          return acc;
        }

        const hasExotic = acc.find((a) => a.key === 'Exotics');

        let canUSerSeeExotics = false;

        if ((isAuthenticated && supportedBets?.exotics) || !isAuthenticated)
          canUSerSeeExotics = true;

        const isExoticBet = [
          EBetTypesDisplayNames.Exacta,
          EBetTypesDisplayNames.Quinella,
          EBetTypesDisplayNames.Trifecta,
          EBetTypesDisplayNames.FirstFour,
        ].includes(cur ?? '');

        if (!hasExotic && isExoticBet && canUSerSeeExotics) {
          const exoticsSubType = [
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.Exacta
            )
              ? [
                  {
                    key: 'Exacta',
                    name: EBetTypesDisplayNames.Exacta,
                    to: `${removeCurrent}/${ETabbarBetTypes.Exacta}${search}`,
                  },
                ]
              : []),
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.Quinella
            )
              ? [
                  {
                    key: 'Quinella',
                    name: EBetTypesDisplayNames.Quinella,
                    to: `${removeCurrent}/${ETabbarBetTypes.Quinella}${search}`,
                  },
                ]
              : []),
            ...(sortedMarketTypes.find(
              (m) => m === EBetTypesDisplayNames.Trifecta
            )
              ? [
                  {
                    key: 'Trifecta',
                    name: EBetTypesDisplayNames.Trifecta,
                    to: `${removeCurrent}/${ETabbarBetTypes.Trifecta}${search}`,
                  },
                ]
              : []),
            ...(sortedMarketTypes.find((m) => m === 'FirstFour')
              ? [
                  {
                    key: 'FirstFour',
                    name: EBetTypesDisplayNames.FirstFour,
                    to: `${removeCurrent}/${ETabbarBetTypes.FirstFour}${search}`,
                  },
                ]
              : []),
          ];

          if (exoticsSubType.length > 0)
            acc.push({
              key: 'Exotics',
              name: EBetTypesDisplayNames.Exotics,
              to: `${exoticsSubType[0]?.to}`,
              subType: exoticsSubType,
            });

          return acc;
        }

        // Create a new variable to store the updated value.
        const betsToExclude = [
          EBetTypesDisplayNames.Quaddie,
          EBetTypesDisplayNames.BigSix,
          EBetTypesDisplayNames.RunningDouble,
          EBetTypesDisplayNames.DailyDouble,
          EBetTypesDisplayNames.Treble,
          EBetTypesDisplayNames.EarlyQuaddie,
          EBetTypesDisplayNames.Exacta,
          EBetTypesDisplayNames.Quinella,
          EBetTypesDisplayNames.Trifecta,
          EBetTypesDisplayNames.FirstFour,
        ];
        let name = cur;
        if (isEvenShot) name = 'EvenShot';
        if (Object.keys(EBetTypesDisplayNames).includes(name as string)) {
          if (
            name === 'FirstFour' ||
            betsToExclude.includes(name as EBetTypesDisplayNames)
          )
            return acc;
          acc.push({
            key: name as string,
            name: EBetTypesDisplayNames[
              name as keyof typeof EBetTypesDisplayNames
            ],
            to: `${removeCurrent}/${
              ETabbarBetTypes[name as keyof typeof ETabbarBetTypes]
            }${search}`,
          });
        }
        return acc;
      }, [] as TExoticTab[]),
    ]);
  }, [
    isSports,
    markets,
    removeCurrent,
    search,
    supportedBets,
    venueId,
    venues,
    raceNumber,
    isAuthenticated,
  ]);
  return { displayMarkets };
};
