import { BoxProps, CSSObject, LinkProps } from '@chakra-ui/react';

export const wrapperProps: BoxProps = {
  sx: {
    'div:first-child': {
      display: 'flex',
      flexDir: 'column',
      color: 'delta.500',
      fontSize: 'xs',
      fontWeight: 'medium',
      alignItems: 'flex-start',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mt: '-1px',
      },
      '.chakra-link': {
        mb: '4',
      },
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'gray.500',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',

  '+ a': {
    color: 'gray.500',
    fontSize: 'sm',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'medium',
    mx: 0,
    display: 'inline',
  },
};
export const ForgotPasswordButton: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  border: '2px solid',
  boxShadow:
    '0px 0px 0px 2px #14134B inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
  color: 'beta.400',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.55)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
  borderRadius: 'md',
  borderWidth: '2px',
  borderColor: 'linear(to-b, beta.300, beta.500)',
  letterSpacing: 'wide',
  w: 'full',
  pt: '0.5',
  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },
};

export const linkLoginProps: LinkProps = {
  color: '#89ADF1',
  fontSize: 'sm',
  fontWeight: 'semibold',
  mb: '4',
  display: 'inline-flex',
  alignItems: 'center',

  sx: {
    svg: {
      boxSize: '10px',
      mr: '1',
      mt: '-1px',
    },
  },
};
