import { CSSObject } from '@chakra-ui/react';

export const SideNavHeading: CSSObject = {
  fontFamily: 'CrimsonPro',
  fontWeight: 'bold',
  fontSize: 'lg',
  textTransform: 'capitalize',
  color: 'alpha.600',
  letterSpacing: 'normal',
  pl: '4',
  pt: '2',
  pb: '2.5',
  lineHeight: '6',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderTopRadius: ['0px', '0px', '0px', '5px'],
  boxShadow: [
    'none',
    'none',
    'none',
    'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
  ],
  bg: [
    'transparent',
    'transparent',
    'transparent',
    'var(--gradient-lighten, linear-gradient(180deg, rgba(194, 223, 255, 0.10) 0%, rgba(194, 223, 255, 0.00) 100%))',
  ],
};
