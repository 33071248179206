/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FormControl, Switch, Text } from '@chakra-ui/react';
import { getStrings } from '@/helpers/utils';
import { getEventRule } from '../../../../components/Betslip/Services/Betslip.utils';

import {
  BoxResultedWrapper,
  FlexEventRule,
  Heading,
  OddsText,
  RaceResultsHeader,
  RunnerDetails,
  RunnerNumber,
  RunnerOdds,
  RunnerOddsContainer,
  RunnerPlace,
  RunnerResultRow,
  WinPlaceHeader,
} from './styles/RaceResults.styles';
import {
  GreyhoundRunnerSilk,
  HorseHarnessRunnerSilk,
  RunnerName,
  RunnerNameAndSilk,
} from '../RaceRunnerListItem/styles/RaceRunnerListItem.styles';

import {
  ERaceType,
  TRaceMarket,
  TRunnerResult,
} from '../../../../lib/DBModels';

import { srcDefaultSilk } from '../../../../assets/core/index';
import { TPunterSupportedBetsResponse } from '@/api/punter/supportedBets/supportedBets.types';

const RaceResults: React.FC<RaceResultsProps> = ({
  runnerResults,
  isGreyhoundSilk,
  numberOfPlaces,
  supportedBets,
  raceMarkets,
}) => {
  const [
    {
      Racing: { RaceResults: Strings },
    },
  ] = getStrings();

  const [showTotes, setShowTotes] = useState(false);
  const { racingType } = useParams();
  const raceType = (racingType as ERaceType) || ERaceType.Horse;

  const winOddsExist = runnerResults.find((runner) => runner.win_odds);
  const placeOddsExist = runnerResults.find((runner) => runner.place_odds);

  const hasTote = runnerResults
    .map(
      (runner) =>
        !!runner?.win_proposition?.dividends?.tote_single_best ||
        !!runner?.win_proposition?.dividends?.tote_single_mid
    )
    .some(Boolean);

  const bestToteMarketAvailable = raceMarkets
    .find((market) => market.market_name === 'Racing Win')
    ?.price_types.includes('tote_single_best');

  const hasBestTote = runnerResults
    .map((runner) => !!runner?.win_proposition?.dividends?.tote_single_best)
    .some(Boolean);

  return (
    <BoxResultedWrapper>
      <Heading>
        <RaceResultsHeader>{Strings.RaceResults}</RaceResultsHeader>

        {supportedBets?.tote_singles !== false && hasTote && (
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="end"
            ml="3"
          >
            <WinPlaceHeader htmlFor="totes" mr="1" textTransform="capitalize">
              <FormattedMessage id="racing.generic.tote" />
            </WinPlaceHeader>
            <Switch
              id="totes"
              size="sm"
              onChange={() => setShowTotes(!showTotes)}
              defaultChecked={showTotes}
            />
          </FormControl>
        )}

        {winOddsExist && (
          <WinPlaceHeader>
            <FormattedMessage
              id={
                showTotes && hasBestTote && bestToteMarketAvailable
                  ? 'racing.generic.winBT'
                  : 'racing.generic.win'
              }
            />
          </WinPlaceHeader>
        )}
        {placeOddsExist && numberOfPlaces > 0 && (
          <WinPlaceHeader>
            <FormattedMessage
              id={
                showTotes && hasBestTote
                  ? 'racing.generic.placeMD'
                  : 'racing.generic.place'
              }
            />
          </WinPlaceHeader>
        )}
      </Heading>
      {runnerResults?.map((runner, index) => (
        <RunnerResultRow
          data-cy={`runnerResultRow-${index}`}
          key={runner.race_runner_id ?? index}
        >
          <RunnerDetails>
            <RunnerPlace data-cy={`runnerPlace-${runner.result_place}`}>
              {Strings.positions[Number(runner.result_place ?? index + 1) - 1]}
            </RunnerPlace>
            <RunnerNameAndSilk>
              {isGreyhoundSilk ? (
                <GreyhoundRunnerSilk
                  data-cy={`runnerSilk-${runner?.runner_number ?? ''}`}
                  src={runner?.silk_url ?? srcDefaultSilk}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = srcDefaultSilk;
                  }}
                />
              ) : (
                <HorseHarnessRunnerSilk
                  data-cy={`runnerSilk-${runner?.runner_number ?? ''}`}
                  src={runner?.silk_url ?? srcDefaultSilk}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = srcDefaultSilk;
                  }}
                />
              )}
              <RunnerName data-cy={`runnerName-${runner?.runner_number ?? ''}`}>
                {`${runner?.runner_number ?? ''}. ${
                  runner?.runner_name?.toLowerCase() ?? ''
                }`}{' '}
                <RunnerNumber
                  as="span"
                  data-cy={`runnerNumber-${runner?.runner_number ?? ''}`}
                >
                  {`${
                    raceType !== ERaceType.Harness
                      ? `(${runner?.barrier_number ?? ''})`
                      : ''
                  }`}
                </RunnerNumber>
              </RunnerName>
            </RunnerNameAndSilk>
          </RunnerDetails>
          <RunnerOddsContainer>
            {runner?.win_odds && runner?.result_place === '1' && (
              <RunnerOdds data-cy={`winOdds-${index}`}>
                {showTotes
                  ? (bestToteMarketAvailable
                      ? runner.win_proposition?.dividends?.tote_single_best
                      : runner.win_proposition?.dividends?.tote_single_mid
                    )?.toFixed(2) ?? ''
                  : runner?.win_odds?.toFixed(2) ?? ''}
              </RunnerOdds>
            )}
            {runner?.place_odds &&
              runner?.result_place !== '4' &&
              numberOfPlaces > 0 && (
                <>
                  {getEventRule(numberOfPlaces) &&
                  numberOfPlaces < Number(runner?.result_place) ? (
                    <FlexEventRule>
                      <Text as="span" fontSize="xs" fontWeight="bold">
                        <FormattedMessage id="racing.raceResults.eventRules.twoPlaceDividends" />
                      </Text>
                    </FlexEventRule>
                  ) : (
                    <RunnerOdds>
                      <OddsText data-cy={`placeOdds-${index}`}>
                        {showTotes
                          ? runner.place_proposition?.dividends?.tote_single_mid?.toFixed(
                              2
                            )
                          : runner?.place_odds?.toFixed(2) ?? ''}
                      </OddsText>
                    </RunnerOdds>
                  )}
                </>
              )}
          </RunnerOddsContainer>
        </RunnerResultRow>
      ))}
    </BoxResultedWrapper>
  );
};

export interface RaceResultsProps {
  runnerResults: TRunnerResult[];
  isGreyhoundSilk: boolean;
  numberOfPlaces: number;
  supportedBets: TPunterSupportedBetsResponse;
  raceMarkets: TRaceMarket[];
}

export default RaceResults;
