import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  color: 'epsilon.100',
  textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
  fontSize: '2xl',
  fontFamily: 'CreteRound',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '100%',
  textTransform: 'capitalize',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
};
