import {
  FlexProps,
  IconProps,
  TabProps,
  TabPanelProps,
  TabPanelsProps,
  TextProps,
  TooltipProps,
} from '@chakra-ui/react';
import { styleImports } from './Badges.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type BadgesSchema = Partial<{
  flexIconWrapper: FlexProps;
  iconBadge: IconProps;
  iconTitle: TextProps;
  textTitle: TextProps;
  textDescription: TextProps;
  tooltipWrapper: Partial<TooltipProps>;
  tabs: TabProps;
  tabPanels: TabPanelsProps;
  tabPanelWrapper: TabPanelProps;
  tabBadge: TabProps;
  moneybackTitle: TextProps;
  moneybackBadge: TextProps;
}>;

export const badgeStyles: BadgesSchema = {
  flexIconWrapper: {
    'data-testid': 'badge-flexIconWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.flexIconWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconBadge: {
    'data-testid': 'badge-iconBadge',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.iconBadge;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconTitle: {
    'data-testid': 'badge-iconTitle',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.iconTitle;
      } catch (err) {
        return {};
      }
    })(),
  },
  textTitle: {
    'data-testid': 'badge-textTitle',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.textTitle;
      } catch (err) {
        return {};
      }
    })(),
  },
  textDescription: {
    'data-testid': 'badge-textDescription',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.textDescription;
      } catch (err) {
        return {};
      }
    })(),
  },
  tooltipWrapper: {
    'data-testid': 'badge-tooltipWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.tooltipWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  tabs: {
    'data-testid': 'badge-tabs',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.tabs;
      } catch (err) {
        return {};
      }
    })(),
  },
  tabPanels: {
    'data-testid': 'badge-tabPanels',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.tabPanels;
      } catch (err) {
        return {};
      }
    })(),
  },
  tabPanelWrapper: {
    'data-testid': 'badge-tabPanelWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.tabPanelWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  tabBadge: {
    'data-testid': 'badge-tabBadge',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.tabBadge;
      } catch (err) {
        return {};
      }
    })(),
  },
  moneybackBadge: {
    'data-testid': 'badge-moneybackBadge',
    boxSize: '6',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.moneybackBadge;
      } catch (err) {
        return {};
      }
    })(),
  },
  moneybackTitle: {
    'data-testid': 'badge-moneybackTitle',
    pt: '0.5',
    ...(() => {
      try {
        return styleImports[themeName]?.badgeStyles?.moneybackTitle;
      } catch (err) {
        return {};
      }
    })(),
  },
};
