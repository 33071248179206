import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    border: '1px solid',
    borderColor: 'rgba(37, 254, 214, 1)',
    boxShadow: '0px 0px 8px 0px rgba(251, 201, 21, 0.5)',
    bgImage: 'none',
    sx: {
      '&&': {
        h: '26px',
      },
    },
  },
};

export const TextRaceAvailability: CSSObject = {
  color: 'white',
};