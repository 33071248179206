import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  borderBottom: '2px solid',
  borderBottomColor: 'alpha.600',
  display: 'flex',
  justifyContent: 'space-between',
  h: '48px',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '6',
  color: 'alpha.600',
};
