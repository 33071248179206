import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FlexInner,
  statementCardStyles,
  TextMonth,
} from './styles/StatementCard.styles';
import { useActivityStatements } from '../../services/ActivityStatements.hooks';
import { Button } from '@/components/Button/Button';

const StatementCard: React.FC<TStatementCardProps> = ({
  pdf_link,
  month,
  year,
}) => {
  const { downloadPDF } = useActivityStatements();
  return (
    <FlexInner data-cy={`statementCard_${month}`}>
      <TextMonth>{month}</TextMonth>
      <Button
        isDisabled={!pdf_link}
        onClick={() => downloadPDF(pdf_link ?? '', month, year)}
        {...statementCardStyles.buttonDownload}
      >
        <FormattedMessage
          id={`account.transactions.${pdf_link ? 'download' : 'noactivity'}`}
        />
      </Button>
    </FlexInner>
  );
};

export type TStatementCardProps = {
  year: number;
  month: string;
  pdf_link?: string;
};

export default StatementCard;
