import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'md',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  color: 'beta.300',
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
