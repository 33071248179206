import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import Countdown from '../Countdown/Countdown';
import { EGeneralStatus } from '../../lib/DBModels';
import {
  ResultedStatusLabel,
  RaceStatusText,
  CountdownTimerContainer,
} from '../../views/races/RaceDetails/styles/RaceDetails.styles';

type TRaceStatusProps = {
  status: string;
  startTime: string;
};

const RaceStatus: React.FC<TRaceStatusProps> = ({ status, startTime }) => {
  const [
    {
      Racing: { RaceDetails: Strings },
    },
  ] = getStrings();

  if (!status || !startTime) return null;

  if (status === EGeneralStatus.Settled) {
    return (
      <ResultedStatusLabel>
        <Text>{Strings.resultedStatus}</Text>
      </ResultedStatusLabel>
    );
  }
  if (status === EGeneralStatus.Closed || status === EGeneralStatus.Abandoned) {
    return (
      <RaceStatusText>
        <FormattedMessage
          id="racing.racedetails.racestatus"
          values={{ status }}
        />
      </RaceStatusText>
    );
  }
  return (
    <CountdownTimerContainer>
      <Countdown eventTime={startTime} />
    </CountdownTimerContainer>
  );
};

export default RaceStatus;
