import { CSSObject } from '@emotion/react';

export const ButtonIncrease: CSSObject = {
  variant: 'odds',
  fontWeight: 'bold',
};

export const buttonIncreaseProps: CSSObject = {
  variant: 'odds',
  fontWeight: 'bold',
};
