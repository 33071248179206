import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  p: '2',
  border: 'none',
  bgGradient: 'linear(to-b, #4b5a6d, #3f4953)', // @TODO colour not in palette
  borderRadius: 'base',
  mx: ['2', '4'],
  mb: '4',
  mt: '2',
  boxShadow:
    '0 4px 4px 0 #00000040, inset 0 1px 0 0 #ffffff26, -1px 0 18px 0 #00000026',
};

export const CheckboxBoxed: CSSObject = {
  justifyContent: 'flex-end',
  mt: '0',
  mr: '1',
  '.chakra-checkbox__control': {
    mb: '1',
  },
  '.chakra-checkbox__label': {
    mt: '-1',
  },
};

export const TextBoxed: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  mr: '3',
};

export const SelectFieldContainer: CSSObject = {
  mb: '2',
  gap: '1',
  w: 'unset',
};
