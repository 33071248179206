import { Box, chakra } from '@chakra-ui/react';
import { styleImports } from './SavedCards.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const TextMaxCards = chakra(Box, {
  label: 'savedCards-TextMaxCards',
  baseStyle: () => ({
    textAlign: 'right',
    fontSize: '2xs',
    mt: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.TextMaxCards;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
