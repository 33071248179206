import { chakra, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { styleImports } from './Group.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const GroupText = chakra(Text, {
  label: 'GroupText',
  baseStyle: () => ({
    fontSize: 'lg',
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.GroupText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextBetCount = chakra(Text, {
  label: 'GroupTextBetCount',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextBetCount;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GroupIcon = chakra(Icon, {
  shouldForwardProp: (prop) => !['expanded'].includes(prop),
  baseStyle: ({ expanded }: any) => ({
    boxSize: '6',
    transform: !expanded && 'scaleY(-1)',
    transition: 'all .3s ease-in-out',
  }),
});

export const GroupChevron = chakra(IconButton, {
  baseStyle: () => ({
    label: 'group-GroupChevron',
    boxSize: '7',
    transition: 'all .3s ease-in-out',

    ...(() => {
      try {
        return styleImports[themeName]?.GroupChevron;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexGroupWrapper = chakra(Flex, {
  label: 'FlexGroupWrapper',
  baseStyle: () => ({
    alignItems: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexGroupWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
