import { CSSObject } from '@chakra-ui/react';

export const WrapperAside: CSSObject = {
  bg: 'linear-gradient(180deg, #2C3A56 0%, #202A3E 100%)',
  bgSize: ['cover, auto', null, '100%, auto'],
  bgPos: ' center 100%, center calc(100% - 82px)',
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  px: [null, null, '40px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['334px', null, 'full'],
  overflow: 'hidden',
};
