import { CSSObject } from '@chakra-ui/react';

export const DatePickerLabel: CSSObject = {
  color: 'var(--bc-gray-500, #718096)',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
};

export const StyledDatePicker: CSSObject = {
  '.react-date-picker__wrapper': {
    h: '40px',
    bg: 'gray.50',
    borderColor: 'gray.100',
    borderRadius: '4px',
    transition: 'all .2s ease-in-out',
    fontSize: 'xs',
    px: '2',

    _hover: {
      bg: 'gray.200',
    },
  },
};
