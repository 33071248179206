import { CSSObject, ButtonProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'rgba(86, 215, 255, 0.1)',
  boxShadow:
    '0px 6px 20px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 4px rgba(80, 78, 243, 0.6) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset',
  backdropFilter: 'blur(4px)',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  borderRadius: '10px',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '3',
  mb: isKeypadVisible && 'unset',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'alpha.700',
  color: 'alpha.700',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  color: 'delta.500',
  fontSize: 'lg',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontFamily: 'Sansita One',
  letterSpacing: 'wide',
  textShadow: '0px 4px 4px #00000040',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  color: 'gamma.400',
  fontSize: 'lg',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontFamily: 'Sansita One',
  letterSpacing: 'wide',
  textShadow: '0px 4px 4px #00000040',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
};

export const LowStakeDivider: CSSObject = {
  opacity: '1',
  borderColor: 'rgba(0, 0, 0, 0.26)',
  borderStyle: 'solid',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.30)',
};

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'solid',
  h: '6',
  fontWeight: 'bold',
  fontSize: '2xs',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  minW: '16',
  px: '2',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      border: 'none',
      opacity: '1',
    },
    '&&': {
      fontSize: '2xs',
    },
  },
};

export const buttonMakeDepositProps: ButtonProps = {
  variant: 'secondary',
  mt: '2',
  fontSize: 'xs',
};

export const LowStakePrompt: CSSObject = {
  color: 'white',
};
