import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/luckystride/images/background/bg.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), linear-gradient(180deg, rgba(29, 69, 84, 1), rgba(23, 50, 61, 1))`,
          backgroundPosition: 'top',
          backgroundRepeat: 'repeat',
          backgroundSize: '1000px',
        },
      }}
    />
  );
}
