import { chakra, Flex } from '@chakra-ui/react';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Placed.styles.imports';

const themeName = getThemeName();

export const BetPlaced = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    px: '3',
    py: '2',
    bg: 'gamma.300',
    mx: '-3',
    mb: '-3',
    borderBottomRadius: 'md',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'black',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),
  }),
});

export const IconBetPlaced = () => {
  try {
    return styleImports[themeName].IconBetPlaced();
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return null;
};
