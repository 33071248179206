import { CSSObject } from '@chakra-ui/react';

export const TabWrapper: CSSObject = {
  borderBottom: '0',
  mt: '4',
  px: '1.5',
  py: '2',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  borderRadius: 'md',
  borderShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};
