import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  color: 'epsilon.400',
  h: 'full',
  bg: 'transparent',
  border: '2px',
  borderColor: 'epsilon.400',

  _hover: {
    color: 'epsilon.500',
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'epsilon.400',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'white',
};
