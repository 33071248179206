import { CSSObject } from '@chakra-ui/react';

export const FlexBetsPlacedContainer: CSSObject = {
  h: '28',
  fontSize: 'md',
  justifyContent: 'center',
  flexDir: 'column',
  gap: '1',
  p: '0',
  borderRadius: 'md',
};
