import { css } from 'styled-components';

export const betSlipCTAWrapper = css`
  top: -var(--bc-sizes-8);
  height: var(--bc-sizes-32);
  background-color: ${({ theme }) => theme.palette.primary};
  box-shadow: inset 0px -15px 10px 0px white, inset 0px 0px 15px 0px white;
  z-index: 97;
`;

export const betSlipCount = css`
  font-size: var(--bc-fontSizes-4xl);
  font-weight: 800;
  color: ${({ theme }) => theme.palette.secondary};
`;

export const betSlipText = css`
  font-size: var(--bc-fontSizes-2xs);
  text-transform: uppercase;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.secondary};
`;
