import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  wrapper: {
    bottom: '0',
    left: '0',
    position: ['fixed', null, null, 'sticky'],
    right: '0',
    zIndex: 'sticky',
  },
  slideBox: {
    bg: 'alpha.50',
    color: 'gray.700',
    mb: '-4',
    borderBottomRadius: 'xl',
  },
  accordionItem: {
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
  },
  accordionHeaderText: {
    bg: 'red.400',
    color: 'white',
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    fontSize: 'xs',
    fontWeight: 'bold',
    gap: '1',
    p: '2',
    _hover: {
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    },
  },
  accordionButtonIcon: {
    color: 'alpha.900',
    fontSize: 'sm',
  },

  headerStack: {
    bg: 'alpha.900',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    py: '2',
  },
  headerText: {
    flex: '1',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
  },
  accordionPanel: {
    fontSize: 'xs',
    maxH: '32',
    overflow: 'auto',
    p: '0',
  },
  selectionFlex: {
    borderColor: 'blackAlpha.300',
    gap: '2',
    py: '2',
    px: '4',
    pos: 'relative',
    _notFirst: {
      borderTopStyle: 'dotted',
      borderTopWidth: '1px',
    },
    _before: {
      border: 'inherit',
      borderColor: 'whiteAlpha.300',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
    color: 'alpha.700',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
    color: 'alpha.700',
  },
  footerStack: {
    borderBottomRadius: 'lg',
    bg: 'alpha.700',
    borderColor: 'whiteAlpha.300',
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    pt: '2',
    px: '4',
    pb: '4',
    mb: '1',
    pos: 'relative',
    _before: {
      bgGradient: 'linear(to-b, rgba(0, 0, 0, 0.00), #000)',
      opacity: '0.2',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '4',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
  },
  legsText: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    color: 'alpha.50',
    textTransform: 'uppercase',
  },
  legsChunks: {
    color: 'beta.500',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
    fontSize: 'xl',
    fontWeight: 'black',
  },
  oddsText: {
    color: 'alpha.50',
    textTransform: 'uppercase',
    flex: '2',
    display: 'flex',
    flexDirection: 'column',
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'beta.500',
    fontSize: 'xl',
    fontWeight: 'black',
    ml: '1',
  },
  clearButton: {
    variant: 'secondary',
    bgImage: 'none',
    color: 'blackAlpha.600',
    textShadow: 'none',
    boxShadow: 'none',
    h: '9',
    fontSize: ['sm', 'md'],
    px: ['1', '3'],
  },
  addToSlipButton: {
    variant: 'secondary',
    h: '9',
    fontSize: ['sm', 'md'],
    px: ['1', '3'],
  },
  buttonsHStack: {
    gap: ['0', '2'],
  },
};
