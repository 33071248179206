import {
  chakra,
  Icon,
  Box,
  Text,
  Divider,
  BoxProps,
  Grid,
} from '@chakra-ui/react';
import { styleImports } from './Footer.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const DeductionsText = chakra(Text, {
  label: 'myBets-Footer-DeductionsText',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'xs',
    color: 'gray.700',
    ...(() => {
      try {
        return styleImports[themeName]?.DeductionsText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const FooterText = chakra(Box, {
  label: 'myBets-Footer-FooterText',
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'bold',
    fontFamily: 'body',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
  }),
});

export const FooterMoreText = chakra(FooterText, {
  label: 'myBets-Footer-FooterMoreText',
  baseStyle: () => ({}),
});

const FooterTextOpen = chakra(Box, {
  label: 'myBets-Footer-FooterTextOpen',
  baseStyle: () => ({
    fontSize: '2xs',
    fontWeight: 'bold',
    fontFamily: 'body',
    textTransform: 'capitalize',
    display: 'flex',
  }),
});

export const FooterMoreOpenText = chakra(FooterTextOpen, {
  label: 'myBets-FooterMoreOpenText',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FooterMoreOpenText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const footerWrapperExpanded: BoxProps = {
  'data-testid': 'footerWrapperExpanded',
  ...(() => {
    try {
      return styleImports[themeName]?.footerWrapperExpanded;
    } catch (err) {
      return {};
    }
  })(),
};

export const FooterBodyText = chakra(FooterText, {
  label: 'myBets-FooterBodyText',
  baseStyle: ({ theme }) => ({
    color: 'gray.400',
    fontSize: '2xs',
    ...(theme.themeName === EThemes.Puntcity && {
      color: 'whiteAlpha.600',
      fontWeight: 'medium',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.FooterBodyText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const MoreIcon = chakra(Icon, {
  label: 'myBets-Footer-MoreIcon',
  baseStyle: () => ({
    boxSize: '8',
  }),
});

export const BoxMoreText = chakra(Box, {
  label: 'myBets-Footer-BoxMoreText',
  alignContent: 'center',
  w: '7',
  cursor: 'pointer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxMoreText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DividerMulti = chakra(Divider, {
  label: 'myBets-Footer-DividerMulti',
  mx: '1.5',
  w: 'unset',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.DividerMulti;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ToteMultiGrid = chakra(Grid, {
  label: 'myBets-Footer-ToteMultiGrid',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.toteMultiGrid;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ToteMultiGridText = chakra(Text, {
  label: 'myBets-Footer-ToteMultiGridText',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.toteMultiGridText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
