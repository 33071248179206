import { Box, Hide } from '@chakra-ui/react';
import React from 'react';
import { BoxVideoContainer } from '../styles/index';

type TVideoProps = {
  sources: {
    src: string | undefined;
    type: string | undefined;
  }[];
};

const Video: React.FC<TVideoProps> = ({ sources }) => (
  <Hide below="md">
    <BoxVideoContainer as="video" loop controls={false} muted autoPlay>
      {sources.map((source, i) => (
        <Box as="source" src={source.src} type={source.type} key={i} />
      ))}
    </BoxVideoContainer>
  </Hide>
);

export default Video;
