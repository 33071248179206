import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonRefresh } from '../styles/Maintenance.styles';

export default function RefreshBtn() {
  return (
    <ButtonRefresh onClick={() => window.location.reload()}>
      <FormattedMessage id="generic.refresh" />
    </ButtonRefresh>
  );
}
