import { chakra, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { Link as LinkDom } from 'react-router-dom';
import { styleImports } from './Login.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const LinkForgotPassword = chakra(LinkDom, {
  baseStyle: ({ theme }) => ({
    fontSize: 'sm',
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'blue.400',
      fontSize: '2xs',
      fontWeight: 'medium',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: 'initial',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.LinkForgotPassword;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkSignup = chakra(LinkDom, {
  baseStyle: ({ theme }) => ({
    fontSize: 'md',
    ...(theme.themeName === EThemes.Wellbet && {
      display: 'none',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: 'initial',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.LinkSignup;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkHighlight = chakra(Text, {
  baseStyle: ({ theme }) => ({
    fontWeight: 'bold',

    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'bold',
    }),
  }),
});

const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: var(--bc-sizes-2) 0 var(--bc-sizes-5);
`;

export const AutoLogoutNotificationText = chakra(Text, {
  baseStyle: ({ theme }) => ({
    display: 'flex',
    color: 'white',
    fontSize: 'sm',
    mb: '5',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'gray.500',
      fontWeight: 'normal',
    }),
  }),
});

const JoinNowContainer = styled.a`
  display: flex;
  justify-content: center;
  margin: var(--bc-sizes-2) 0 var(--bc-sizes-5);
  text-decoration: none;
  font-size: var(--bc-fontSizes-md);
  color: var(--bc-colors-white);
`;

const JoinNowCTA = styled.span`
  font-weight: bold;
  margin-left: var(--bc-sizes-1);
  color: ${({ theme }) => theme.palette.primary};
`;

export { ForgotPasswordContainer, JoinNowContainer, JoinNowCTA };
