import { css } from 'styled-components';

export const inlineLink = css`
  color: ${({ theme }) => theme.palette.primary};
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S};

  &:active {
  }

  &:visited {
  }

  &:hover {
    text-decoration: underline;
  }
`;
