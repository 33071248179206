import { CSSObject } from '@chakra-ui/react';

export const FlexBetslipHeading: CSSObject = {
  color: 'delta.800',
  h: '12',
  borderBottomColor: 'beta.600',
  borderBottom: '2px',
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'black',
  textTransform: 'lowercase',
  fontFamily: 'CinzelDecorative',
  color: 'gamma.600',
};

export const BetLengthIndicator: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
  fontSize: 'xs',
  borderRadius: 'base',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'gamma.600',
};
