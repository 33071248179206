import { CSSObject } from '@chakra-ui/react';

export const FooterText: CSSObject = {
  color: 'beta.500',
  fontWeight: 'medium',
  fontSize: '11px',
  mr: '1',
};

export const FooterContainer: CSSObject = {
  bg: 'beta.50',
  color: 'beta.500',
  fontSize: '11px',
  px: '0',
  border: '2px',
  borderColor: 'beta.500',
  overflow: 'hidden',
  boxShadow: 'inset 0px 0px 5px rgba(12, 30, 90, 0.6)',
};

export const EstReturnsLabel: CSSObject = {
  bg: 'beta.500',
  color: 'white',
  fontWeight: 'semibold',
  height: '100%',
  alignItems: 'center',
  px: '1',
  width: 'auto',
  boxShadow: 'inset 0px 0px 5px rgba(12, 30, 90, 0.6)',
};

export const EstReturnsBlock: CSSObject = {
  bg: 'beta.500',
};

export const CountdownContainer: CSSObject = {
  display: 'none',
};
