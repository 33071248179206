import React from 'react';
import { Flex, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { homeLinkStyles } from '../styles/HomeLinks.styles';
import { IconSport } from '@/views/sports/AllSports/styles/AllSports.styles';
import { useAllSports } from '@/views/sports/AllSports/services/AllSports.hooks';
import { getIconBySportName } from '@/helpers/utils';
import { getSportHomeLinksConfig } from './sportHomeLinksConfig';

export default function HomeLinksForSport() {
  const { sportsGrouped } = useAllSports();
  const { spliceCount, additionalLinks } = getSportHomeLinksConfig();

  const sportsLinks = sportsGrouped
    ?.flatMap((sportGroup) => sportGroup.sports)
    .splice(0, spliceCount);

  const startLinks = additionalLinks.filter((al) => al.insertAt === 'start');
  const endLinks = additionalLinks.filter((al) => al.insertAt === 'end');

  return (
    <Flex {...homeLinkStyles.container}>
      <Flex {...homeLinkStyles.sportsHeader}>
        <FormattedMessage id="generic.sport" />
      </Flex>
      <Flex {...homeLinkStyles.sportBtnsContainer}>
        {startLinks.length > 0 &&
          startLinks.map(({ linkElement }) => linkElement)}

        {sportsLinks?.map((sport) => (
          <LinkBox key={sport?.display_name} {...homeLinkStyles.linkIconBox}>
            <LinkOverlay
              href={`/sports/${sport?.display_name}?sportId=${sport?.sport_id}`}
              {...homeLinkStyles.linkOverlay}
            >
              <IconSport
                name={getIconBySportName(sport?.display_name)}
                {...(homeLinkStyles.sportIcon as any)}
              />
              <Flex {...homeLinkStyles.sportsHeaderTile}>
                {sport?.display_name}
              </Flex>
            </LinkOverlay>
          </LinkBox>
        ))}

        {endLinks.length > 0 && endLinks.map(({ linkElement }) => linkElement)}
      </Flex>
    </Flex>
  );
}
