import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'solid',
  height: '40px',
};

export const FlexWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  borderBottomRadius: 'lg',
  borderBottom: '1px',
  borderColor: 'gamma.500',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
    mt: '0',
  },

  '&&': {
    '.chakra-form__label': {
      color: 'white',
    },
    '.chakra-input__group': {
      border: 'none',
      boxShadow: 'none',
      p: '0',
    },
    '.chakra-input__left-addon': {
      w: '10',
      border: 'none',
      fontWeight: 'bold',
      borderLeftRadius: 'md',
    },
    '.chakra-input': {
      w: 'full',
      borderLeftRadius: 'none',
      bg: 'beta.800',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: '#4F319E',
  borderBottomRadius: 'lg',
  zIndex: '10',
  fontFamily: 'accent',
  mx: ['unset', null, '-4'],
  mb: ['unset', null, '-18px'],
};

export const InfoHeader: CSSObject = {
  borderTop: '4px solid',
  borderColor: 'gamma.500',
  alignItems: 'center',
  h: '9',
  px: '4',
  py: '2',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'gamma.500',
  fontFamily: 'Staatliches',
  textTransform: 'uppercase',
  '&&': {
    fontWeight: 'normal',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'normal',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'alpha.900',
  pos: 'relative',
  borderColor: 'rgba(0, 0, 0, 0.10)',
  _notFirst: {
    borderTopStyle: 'dotted',
    borderTopWidth: '1px',
  },
  _before: {
    border: 'inherit',
    borderColor: 'whiteAlpha.300',
    content: '""',
    h: '1px',
    left: '0',
    pos: 'absolute',
    right: '0',
    top: '0',
  },
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    },
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'gamma.500',
};
