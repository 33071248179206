import { css } from 'styled-components';

export const mainContent = css`
  position: relative;
  padding: var(--bc-space-2);
`;

export const contentCol = css``;

export const contentColExpand = css`
  flex: 1;
`;

export const contentRow = css`
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: var(--bc-space-2);
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const contentRowNoMargin = css`
  margin-bottom: 0;
`;

export const header = css`
  display: flex;
`;

export const heading = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-sm);
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: var(--bc-fontWeights-bold);
  font-style: italic;
`;

export const eventInfo = css`
  margin-bottom: var(--bc-sizes-2);
`;

export const eventInfoMain = css`
  flex-direction: column;
`;

export const startTime = css`
  color: ${({ theme }) => theme.basePalette.lightGrey};
  margin-top: var(--bc-sizes-1);
  margin-right: var(--bc-sizes-1);
  font-weight: var(--bc-fontWeights-bold);
`;

export const bodyText = css`
  font-size: var(--bc-fontSizes-xs);
  color: ${({ theme }) => theme.basePalette.white};
  font-weight: var(--bc-fontWeights-bold);
`;

export const bodyTextBright = css`
  color: ${({ theme }) => theme.basePalette.brightBlue};
`;

export const bodyTextDark = css`
  color: ${({ theme }) => theme.basePalette.navyBlue};
`;

export const bodyTextBottomSpacing = css`
  margin-bottom: var(--bc-space-2);

  &::last-child {
    margin-bottom: 0;
  }
`;

export const sportIcon = css`
  margin-right: var(--bc-space-1);
  height: 20px;
  width: 20px;

  path {
    fill: ${({ theme }) => theme.basePalette.white};
  }
`;

export const odds = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-sm);
  margin-bottom: var(--bc-space-3);
  font-weight: var(--bc-fontWeights-bold);
`;

export const stake = css`
  align-self: flex-end;
  color: ${({ theme }) => theme.basePalette.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: var(--bc-fontWeights-bold);
  text-transform: uppercase;
  font-size: var(--bc-fontSizes-xs);
`;

export const stakeInfo = css`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--bc-space-5);
`;

export const stakeHeading = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-2xs);
  font-family: ${({ theme }) => theme.fontFamily.heading};
  text-transform: uppercase;
  margin-bottom: var(--bc-space-1);
`;

export const betInfo = css`
  align-items: center;
  display: flex;
  margin-top: var(--bc-space-4);
`;

export const betInfoText = css`
  color: ${({ theme }) => theme.basePalette.midBlue};
`;

export const betInfoIcon = css`
  margin-right: var(--bc-space-1-5);
  width: 11px;
`;

export const bottomContent = css`
  background: ${({ theme }) => theme.basePalette.brightBlue};
  padding: var(--bc-space-1) var(--bc-space-1) var(--bc-space-1-5)
    var(--bc-space-1-5);
`;

export const bottomContentResulted = css`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme.basePalette.blue};
`;

export const bottomContentIcon = css`
  height: 10px;
  margin-right: var(--bc-space-1);
  width: 10px;
`;

export const expandToggle = css`
  height: var(--bc-sizes-2-5);
  width: var(--bc-sizes-2-5);
  transition: all, 0.2s ease-in-out;
  position: absolute;
  right: var(--bc-space-2);
  top: var(--bc-space-1-5);
  cursor: pointer;
`;

export const expandToggleInverted = css`
  ${expandToggle};
  transform: rotate(180deg);
`;

export const betPayout = css`
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
`;

export const payoutText = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  font-size: var(--bc-fontSizes-xs);
`;

export const payoutTextSmall = css`
  font-size: var(--bc-fontSizes-2xs);
  font-style: normal;
  font-weight: var(--bc-fontWeights-normal);
  color: ${({ theme }) => theme.basePalette.white};
`;

export const noPayoutText = css`
  color: ${({ theme }) => theme.basePalette.lightGrey};
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: var(--bc-fontWeights-bold);
  font-style: italic;
`;

export const wonContent = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  left: 60%;
  transform: translateY(-50%);
  width: 0;
`;

export const wonText = css`
  color: ${({ theme }) => theme.basePalette.pink};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: var(--bc-fontSizes-xs);
  font-style: italic;
  font-weight: var(--bc-fontWeights-bold);
  line-height: 0.9;
  box-shadow: ${({ theme }) => theme.basePalette.darkShadow};
`;

export const wonTextLarge = css`
  font-size: var(--bc-fontSizes-2xl);
`;
