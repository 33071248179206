import {
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  ChakraComponent,
  Text,
} from '@chakra-ui/react';
import { styleImports } from './Upcoming.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export const BoxSectionWrapper = chakra(Box, {
  label: 'upcoming-BoxSectionWrapper',
  baseStyle: ({ theme }) => ({
    borderRadius: 'base',
    contain: 'paint',
    mt: ['1.5', null, null, '2.5'],
    mx: ['1.5', null, null, 'unset'],

    __first: { mt: '0' },

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
      bg: 'betaAlpha.500',
      boxShadow: 'inset 0px 1px 4px #5171ae, inset 0px 1px 1px #404c94',
    }),

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.600',
      bg: 'white',
      boxShadow: 'md',
      mt: '0',
      mb: '2',
      _first: {
        mb: '-2',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxSectionWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const boxWrapperNTPProps: BoxProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.boxWrapperNTPProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const BoxWrapper = chakra(Box, {
  label: 'upcoming-BoxWrapper',
  baseStyle: ({ theme }) => ({
    p: '2',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.100',
      color: 'alpha.800',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextHeading = chakra(Text, {
  label: 'upcoming-TextHeading',
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'semibold',

    ...(() => {
      try {
        return styleImports[themeName]?.TextHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonViewMore = chakra(Button, {
  label: 'upcoming-ButtonViewMore',
  baseStyle: ({ theme }) => ({
    py: '2',
    fontWeight: 'semibold',
    bg: 'betaAlpha.500',
    w: 'full',
    textTransform: 'none',
    borderRadius: 'none',
    borderTop: '1px',
    borderTopColor: 'whiteAlpha.100',

    _hover: { bg: 'betaAlpha.600' },
    _active: { bg: 'betaAlpha.400' },

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.600',
      color: 'white',
      fontSize: 'xs',
      borderTop: 'none',

      textTransform: 'uppercase',

      _hover: { bg: 'alpha.700' },
      _active: { bg: 'alpha.400' },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonViewMore;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;
