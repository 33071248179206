import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexContainer = (): CSSObject => ({
  alignItems: ['center', null, null, null, 'start'],
  pos: 'relative',
  px: '0',
});

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  w: 120,
};

export const BoxWrapper: BoxProps = {
  mr: '1',
};

export const ButtonGroup: CSSObject = {
  alignItems: 'start',
  gap: 1,
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'white',
  ml: '3',
  svg: {
    boxSize: '6',
  },
});

export const ButtonSignUp: CSSObject = {
  h: '48px',
};

export const ButtonLogIn: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow:
    '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #0C2549 inset, 0px 0px 0px 5px #4d98c5 inset',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
  border: 'unset',
  fontFamily: 'RobotoCondensed',
  fontWeight: 'extrabold',
  fontSize: 'sm',
  textTransform: 'uppercase',
  h: '48px',
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'secondary',
  py: '2.5',
  h: '45px',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'secondary',
  h: 12,
  pl: 2.5,
  pr: 5,
  fontFamily: 'roboto',

  sx: {
    '&:hover, &:focus': {
      bgGradient: 'linear(180deg, alpha.800, alpha.900)',
    },

    '&[aria-expanded="true"]': {
      bg: 'alpha.700',
      boxShadow: '0px 0px 0px 2px var(--bc-colors-beta-500) inset',
    },

    '.span-balance': {
      fontSize: ['sm', null, null, null, 'md'],
      color: 'beta.500',
    },
    '.span-bonus': {
      fontSize: 'xs',
      fontWeight: 'bold',

      svg: {
        mr: '1',
        boxSize: '2.5',
        pos: 'relative',
        bottom: '-px',
      },
    },

    '.chevron-icon': {
      my: 'auto',
      pos: 'absolute',
      top: 0,
      bottom: 0,
      right: 1.5,
      boxSize: 4,
    },
  },
};

export const ButtonGroupBets: CSSObject = {
  gap: 1,

  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },
};

const betButtonStyles: CSSObject = {
  fontFamily: 'roboto',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  h: 12,
  boxShadow:
    '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #0C2549 inset, 0px 0px 0px 5px #4d98c5 inset',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
  fontWeight: 'black',

  '.btn-span': {
    flexDir: 'column',
    gap: 0.5,
    alignSelf: 'center',
    lineHeight: 1,
  },

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.800, alpha.900)',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    boxShadow: 'inset 0px 3px 4px #000000',
  },

  '.chakra-text:first-child': {
    fontSize: '2xs',
    textTransform: 'uppercase',
  },

  '.chakra-text:last-child': {
    fontSize: ['sm', null, null, null, 'md'],
    color: 'beta.500',
  },
};

export const ButtonBets: CSSObject = betButtonStyles;

export const ButtonBetSlip: CSSObject = betButtonStyles;
