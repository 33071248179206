import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  bgGradient: 'linear(to-b, delta.300 22.92%, delta.600 91.67%)',
  borderBottom: '4px solid',
  borderBottomColor: 'delta.700',
  display: 'flex',
  justifyContent: 'space-between',
  mb: '2',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '9',
  color: 'delta.700',
};
