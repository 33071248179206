import { css } from 'styled-components';

// TODO: Remove
export const heading = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-xs);
  margin-bottom: var(--bc-sizes-1);
  text-transform: capitalize;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const bottomContent = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 30px;
`;

// TODO: Remove
export const icon = css`
  height: var(--bc-sizes-5);
  width: var(--bc-sizes-5);

  path {
    fill: ${({ theme }) => theme.basePalette.blue};
  }
`;

// TODO: Remove
export const dateTimeWrapper = css`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

// TODO: Remvoe
export const dateTime = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-size: var(--bc-fontSizes-2xs);
`;
