import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textTransform: 'uppercase',
};
export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'accent',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'gamma.400',
  pos: 'relative',
  bottom: '2px',
};
export const GroupChevron: CSSObject = {
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  borderRadius: 'md',
  color: 'beta.400',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  boxSize: '6',
  minW: 'unset',
  border: 'none',
  transition: 'all .3s ease-in-out',
  _focus: {
    bg: 'blackAlpha.600',
    color: 'alpha.400',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  },
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    color: 'alpha.400',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  },
  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
  ':active, &[data-active="true"]': {
    bg: 'beta.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'alpha.400',
    textShadow: 'unset',
  },
};
export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};
export const GroupIcon: CSSObject = {
  color: 'white',
};
