import { useLocation } from 'react-router-dom';
import { TPageSpecificContent } from './onboarding.types';

export const usePageSpecificContent = () => {
  const { pathname } = useLocation();

  const getPageSpecificContent = (): TPageSpecificContent => {
    switch (pathname) {
      case '/signup':
        return {
          titleId: 'onboarding.step1.pageTitle',
          subtitleId: 'onboarding.step1.pageSubtitle',
          footerInfoId: 'onboarding.step1.login',
          ctaId: 'onboarding.step1.logincta',
          ctaHref: '/login',
          dataCy: 'loginNow',
        };
      case '/login':
        return {
          titleId: 'onboarding.login.title',
          subtitleId: 'onboarding.login.subtitle',
          footerInfoId: 'onboarding.login.footerInfo',
          ctaId: 'onboarding.login.joinNowCTA',
          ctaHref: '/signup',
          dataCy: 'joinNow',
        };
      case '/forgot-password':
        return {
          titleId: 'onboarding.login.title',
          subtitleId: 'onboarding.login.subtitle',
          footerInfoId: 'onboarding.login.footerForgotInfo',
          ctaId: 'onboarding.login.joinNowCTA',
          ctaHref: '/signup',
          dataCy: 'joinNow',
        };
      case '/deposit-limit':
        return {
          titleId: 'onboarding.step1.pageTitle',
          subtitleId: 'onboarding.step1.pageSubtitle',
          footerInfoId: undefined,
          ctaId: undefined,
          ctaHref: undefined,
        };
      default:
        return {
          titleId: 'onboarding.step1.pageTitle',
          subtitleId: 'onboarding.step1.pageSubtitle',
          footerInfoId: undefined,
          ctaId: undefined,
          ctaHref: undefined,
        };
    }
  };

  return getPageSpecificContent();
};
