import { CSSObject } from '@chakra-ui/react';
import saturnine from '../../../../../../../../assets/betgalaxy/icons/saturnine.gif';

export const BoxPaymentProcessing: CSSObject = {
  bgImage: `url(${saturnine})`,
  bgSize: '100%',
  bgRepeat: 'no-repeat',
  border: 'none',
  boxSize: '40',
};
