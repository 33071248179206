import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Link } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import { TFormikValues } from '../types';
import {
  InputContainer,
  TextS,
} from '../../TakeABreak/styles/TakeABreak.styles';
import {
  buttonSubmitExclusionProps,
  CheckboxExclusion,
} from '../styles/LifetimeExclusion.styles';
import { getThemeName } from '@/helpers/getThemeName';
import { Button } from '@/components/Button/Button';

export default function Form() {
  const [
    {
      Account: {
        LifetimeExclusion: {
          confirm,
          confirmStepFunds,
          confirmStepUndone,
          confirmStepImmediately,
        },
      },
    },
  ] = getStrings();

  const { isSubmitting } = useFormikContext<TFormikValues>();

  return (
    <Box maxW="container.xs">
      <TextS>
        <FormattedMessage id="account.betStop.title" />
      </TextS>
      <TextS mb="3">
        <FormattedMessage
          id="account.betStop.description"
          values={{
            cta: (string: string) => (
              <Link
                href="https://www.acma.gov.au/betstop-national-self-exclusion-registertm"
                target="_blank"
                display="inline-block"
                textDecoration="underline"
              >
                {string}
              </Link>
            ),
          }}
        />
      </TextS>

      <TextS sx={{ mb: '3' }}>{confirm}</TextS>
      <InputContainer>
        <Box>
          <CheckboxExclusion name="confirmedFunds">
            {confirmStepFunds.replace('{themeName}', getThemeName())}
          </CheckboxExclusion>
          <CheckboxExclusion name="confirmedImmediate">
            {confirmStepImmediately}
          </CheckboxExclusion>
          <CheckboxExclusion name="confirmedClosed">
            {confirmStepUndone.replace('{themeName}', getThemeName())}
          </CheckboxExclusion>
        </Box>
      </InputContainer>
      <Button
        {...buttonSubmitExclusionProps}
        data-cy="submitExclusion"
        type="submit"
        isLoading={isSubmitting}
      >
        <FormattedMessage id="account.lifetimeexclusion.confirmlifetimeexclusion" />
      </Button>
    </Box>
  );
}
