import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import { TPasswordTooltip, TPasswordRulesIcon } from '../services/SignUp.types';

export const SignupAcknowledgementButton: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  fontSize: 'sm',
  borderRadius: 'base',
  borderColor: 'beta.400',
  color: 'alpha.800',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  fontFamily: 'Fredoka',
  fontWeight: 'black',
  textTransform: 'uppercase',
  mt: ['1', null, 'unset'],
  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    color: 'alpha.800',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  },
  _active: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    border: '2px solid',
    borderColor: '#CDFF98',
    boxShadow: 'none',
    color: 'alpha.800',
    textShadow: 'none',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'alpha.600' : 'beta.800',
  p: '3',

  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-alpha-600) !important'
      : 'var(--bc-colors-beta-800) !important',
  },
});

export const PasswordRule = (): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: 'white',
});

export const BoxManualAddressContainer: CSSObject = {
  bg: 'none',
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
};

export const CheckboxHelpText: CSSObject = {
  textTransform: 'initial',
  fontWeight: 'normal',
  color: 'gray.500',
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);
