import React, { FC } from 'react';
import { getStrings } from '@/helpers/utils';
import { LinkHighlight } from '../Login/legacy/styles/Login.styles';
import OnboardingWrapper from '../../../wrappers/OnboardingWrapper';
import FormikHOC from './components/FormikHOC';
import Form from './components/Form';
import ContentHeading from '../AccountCreation/conditionals/ContentHeading/template';
import {
  BoxLoginCTAContainer,
  FlexHeaderWrapper,
  LinkLogin,
} from './styles/SignUp.styles';
import { useSignup } from './services/SignUp.hooks';
import Aside from '../../../components/Aside/Aside';
import StepIndicator from '../../../components/StepIndicator/StepIndicator';
import { EOnboardingPageType } from '@/wrappers/OnboardingWrapper/OnboardingWrapper.types';

const SignUp: FC = () => {
  const [
    {
      Onboarding: { Step1 },
    },
  ] = getStrings();

  const {
    setMobilePasswordFocused,
    mobilePasswordFocused,
    placesAutocompleteLoaded,
    handleSignUp,
  } = useSignup();

  return (
    <OnboardingWrapper
      aside={<Aside />}
      pageTitle={Step1.PageTitle}
      pageType={EOnboardingPageType.SignUp}
    >
      <FlexHeaderWrapper>
        <ContentHeading />
        <StepIndicator steps={3} activeStep={0} />
      </FlexHeaderWrapper>

      <FormikHOC handleSignUp={handleSignUp}>
        <Form
          setMobilePasswordFocused={setMobilePasswordFocused}
          mobilePasswordFocused={mobilePasswordFocused}
          placesAutocompleteLoaded={placesAutocompleteLoaded}
        />
      </FormikHOC>
      <BoxLoginCTAContainer>
        <LinkLogin to="/login">
          {Step1.Login}{' '}
          <LinkHighlight as="span" data-cy="loginNow">
            {Step1.LoginCTA}
          </LinkHighlight>
        </LinkLogin>
      </BoxLoginCTAContainer>
    </OnboardingWrapper>
  );
};

export default SignUp;
