import React from 'react';
import { Global } from '@emotion/react';
import bgHead from '@/assets/puntgenie/images/backgrounds/bgHead.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          background: `linear-gradient(180deg, var(--bc-colors-epsilon-500) 0%,  var(--bc-colors-epsilon-700) 100%)`,
          backgroundPosition: 'right 0px bottom 120px, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain, cover',
          backgroundAttachment: 'fixed',
          '@media (min-width: 581px)': {
            backgroundImage: `url(${bgHead}), linear-gradient(180deg, var(--bc-colors-epsilon-500) 0%,  var(--bc-colors-epsilon-700) 100%)`,
            backgroundPosition: 'top, center',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundSize: 'contain, cover',
          },
        },
      }}
    />
  );
}
