import evenShotBg from '@/assets/betgalaxy/images/backgrounds/evenShotBg.png';
import evenShot from '@/assets/betgalaxy/images/evenShotMascot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  notAvailableText: {
    color: 'alpha.400',
  },
  oddsValueSpan: {
    color: 'white',
    sx: {
      '&[data-hasbets="true"]': {
        color: 'alpha.400',
      },
    },
  },
  hasBetSubTitleText: {
    fontWeight: 'normal',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notYetAvailableText: {
    color: 'alpha.400',
    fontWeight: 'bold',
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'alpha.400',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'extrabold',
    fontSize: 'lg',
  },
  oddsText: {
    mt: '2',
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
    borderRadius: 'base',
    color: 'white',
    bgColor: 'accent.100',
    bgImage: `url(${evenShotBg})`,
    bgSize: 'cover',
    mt: '2',
    mx: '3',
  },
  headingText: {
    fontFamily: 'Exo',
    fontWeight: 'extrabold',
    color: 'white',
    letterSpacing: '4px',
    textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
    textTransform: 'uppercase',
    fontSize: '32px',
  },
  addToBetslipButton: {
    variant: 'tertiary',
  },
  loginToView: {
    variant: 'tertiary',
  },
  evenShotImage: {
    mt: 4,
    boxSize: '145px',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
};
