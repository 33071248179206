import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'lg',
    mt: 3,
  },
  body: {
    borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
    py: 3,
    px: 0,
    mx: 3,
  },
};
