import { Box, VisuallyHidden } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import desktop from '@/assets/volcanobet/images/banner/desktop.png';
import desktop2x from '@/assets/volcanobet/images/banner/desktop2x.png';
import mobile from '@/assets/volcanobet/images/banner/mobile.png';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <Box
            key="banner-image"
            sx={{
              w: '100%',
              h: ['40vh', null, '80'],
              bgImage: [mobile, desktop, null, desktop2x],
              bgSize: ['cover', null, '105%'],
              bgPosition: 'center',
              bgRepeat: 'no-repeat',
              borderBottom: '4px',
              borderColor: ['transparent', null],
            }}
          >
            <VisuallyHidden>
              <FormattedMessage id="home.carousel.accessbility" />
            </VisuallyHidden>
          </Box>,
        ]}
      />
    </Box>
  );
}
