import { useDispatch } from 'react-redux';
import { useIdleTimer as _useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { AppDispatch } from '../../../redux/store';
import { logError } from '@/helpers/utils';
import {
  clearPunterProfile,
  clearPunterOverview,
  reset,
} from '../../../views/account/services/account.slices';
import { signUserOut } from '../../../lib/firebase/Authentication';
import { ENewRelicActions } from '../../../constants/newRelicActions';
import { useUserData } from '@/store/AuthStore';
import { useAuth } from '@/hooks/useAuth';
import { newRelicLog } from '@/lib/newRelic';

/**
 * This component responsible to logout users after being 20 mins
 * Idle and navigates them to back to login page
 */

export const useIdleTimer = () => {
  const auth = useUserData();
  const { punterData } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const client = useQueryClient();

  const logoutHandler = () => {
    if (!auth) return;
    signUserOut()
      .then(() =>
        newRelicLog(ENewRelicActions.LogOut, punterData?.punter_id, {
          status_code: 200,
        })
      )
      .catch(logError);
    dispatch(clearPunterProfile());
    dispatch(clearPunterOverview());
    dispatch(reset());

    client.invalidateQueries().catch(() => undefined);
    window.setTimeout(() => navigate('/login?autoLogout=true'), 10);
  };

  _useIdleTimer({
    onIdle: () => logoutHandler(),
    timeout: 1000 * 60 * 60 * 12, // 12 hours
    crossTab: true,
    leaderElection: true,
    startManually: !auth,
  });
};
