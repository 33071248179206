import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  px: '1.5',
  py: '1',
  bgGradient: 'linear(to-b, alpha.500, alpha.700)',
  borderRadius: 'base',
  '.chakra-checkbox__label .chakra-text': {
    color: 'white',
  },
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'alpha.200' : 'white',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'base',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'alpha.800' : 'transparent',
  boxShadow: isActive
    ? '0px 1px 2px 0px rgba(255, 255, 255, 0.40), 0px 1px 0px 0px #000 inset'
    : 'none',

  backgroundBlendMode: 'lighten, overlay, normal',

  _hover: {
    bg: isActive ? 'blackAlpha.600' : 'transparent',
    color: 'white',
    boxShadow: 'none',
    textShadow: 'none',
  },
  _focus: {
    bg: isActive ? 'blackAlpha.600' : 'transparent',
    color: isActive ? 'beta.500' : 'white',
  },
});

export const buttonAccountTabOptionProps: CustomButtonProps = {
  color: 'white',
  textShadow: 'none',
  bgImage: 'none',
  boxShadow: 'none',
  bg: 'transparent',

  sx: {
    '&[aria-selected="true"]': {
      bg: 'alpha.800',
      color: 'alpha.200',
      boxShadow:
        '0px 1px 2px 0px rgba(255, 255, 255, 0.40), 0px 1px 0px 0px #000 inset',
      _hover: {
        bg: 'alpha.600',
        color: 'white',
      },
    },

    '&&': {
      borderRadius: 'base',
    },
  },

  h: '5',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1.5',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',

  border: 'none',
  fontFamily: 'Roboto',
};
