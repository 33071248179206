import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: 'xl',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    letterSpacing: 'widest',
    textTransform: 'uppercase',
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: '0',
  },
  hStackWrapper: {
    justifyContent: 'flex-end',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  scrollButtonGroup: {
    variant: 'filter',
    h: '35px',
    bg: 'transparent',
    boxShadow: 'none',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    gap: '1',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  headingIcon: {
    color: 'beta.500',
  },
  propositionButton: {
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    borderColor: 'whiteAlpha.300',
    borderWidth: '1px',
  },
  buttonSport: {
    pr: '30px',
    sx: {
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        border: '1px',
        borderColor: 'beta.500',
        boxSize: '2.5',
        top: '6px',
        right: '2.5',
        borderRadius: 'full',
        pr: '2',
      },
    },
  },
  buttonAllSports: {
    _active: {
      bg: 'beta.500',
      borderColor: 'beta.500',
      color: 'beta.800',
      '&:after': {
        display: 'none',
      },
    },
  },
};
