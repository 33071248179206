import {
  Box,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Grid,
  Image,
  ImageProps,
  LinkProps as ChakraLinkProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { styleImports } from './Featured.styles.imports';
import IconSvg, { TIconSvg } from '../../../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import {
  Button as ChakraButton,
  CustomButtonProps,
} from '@/components/Button/Button';

const themeName = getThemeName();

export type FeaturedRaceSchema = Partial<{
  buttonOddsProps: CustomButtonProps;
  svgMoreRacesArrowProps: any;
  flexWrapperCardHeaderProps: FlexProps;
}>;

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    'data-testid': 'featuredRace-buttonOddsProps',

    ...styleImports?.[themeName]?.featuredRaceStyles?.buttonOddsProps,
  },
  flexWrapperCardHeaderProps: {
    'data-testid': 'featuredRace-flexWrapperCardHeaderProps',
    align: 'center',

    ...styleImports?.[themeName]?.featuredRaceStyles
      ?.flexWrapperCardHeaderProps,
  },
  svgMoreRacesArrowProps: {
    'data-testid': 'featuredRace-moreRacesArrowSvgProps',
    className: 'chevron-icon',
    color: 'beta.400',
    boxSize: 4,
    pb: '2px',

    ...styleImports?.[themeName]?.featuredRaceStyles?.svgMoreRacesArrowProps,
  },
};

/**
 * Featured component
 */
export const FlexFeatureWrapper = chakra(Flex, {
  label: 'featured-FlexFeatureWrapper',
  baseStyle: () => ({
    minH: [null, '350px'],
    mb: ['1.5', null, null, '2.5'],
    mx: ['1.5', null, null, 'unset'],
    borderRadius: 'base',
    contain: 'paint',
    px: ['6', '3'],
    py: ['8', '0'],
    flexDir: ['column', 'row'],

    ...(() => {
      try {
        return styleImports[themeName]?.FlexFeatureWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexFeatureTitleWrapper = chakra(Flex, {
  label: 'featured-FlexFeatureTitleWrapper',
  baseStyle: () => ({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexFeatureTitleWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexFeatureCardWrapper = chakra(Flex, {
  label: 'FlexFeatureCardWrapper',
  baseStyle: () => ({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexFeatureCardWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextFeatureSubHeading = chakra(Text, {
  label: 'TextFeatureSubHeading',
  baseStyle: ({ theme }) => ({
    fontSize: 'sm',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    mb: '1.5',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
      display: 'none',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextFeatureSubHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextFeatureHeading = chakra(Text, {
  label: 'TextFeatureHeading',
  baseStyle: ({ theme }) => ({
    fontSize: '3xl',
    fontWeight: 'black',
    mb: '3.5',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
      display: 'none',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextFeatureHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextFeatureStyledHeading = chakra(Text, {
  label: 'TextFeatureStyledHeading',
  baseStyle: ({ theme }) => ({
    fontSize: '3xl',
    fontWeight: 'black',
    mb: '3.5',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextFeatureStyledHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Card
 */
export const BoxCard = chakra(Box, {
  label: 'featured-BoxCard',
  baseStyle: ({ theme }) => ({
    borderRadius: 'md',
    contain: 'paint',
    flex: 1,

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'betaAlpha.500',
      boxShadow: 'inset 0px 1px 4px #5171ae, inset 0px 1px 1px #404c94',
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxCard;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkCardHeader = chakra(Link, {
  label: 'featured-LinkCardHeader',
  baseStyle: ({ theme }) => ({
    display: 'flex',
    p: '2.5',
    justifyContent: 'space-between',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.50',
      color: 'alpha.800',

      '.chakra-badge.state-prior': { color: 'gray.700' },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      '.chakra-badge.state-prior': { color: 'white' },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkCardHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps>;

export const IconCardHeader = chakra(IconSvg, {
  label: 'IconCardHeader',
  baseStyle: ({ theme }) => ({
    boxSize: '5',
    mr: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.800',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.IconCardHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', TIconSvg>;

export const TextCardHeader = chakra(Text, {
  label: 'TextCardHeader',
  baseStyle: ({ theme }) => ({
    fontWeight: 'semibold',
    fontSize: 'sm',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.800',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextCardHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRaceNumber = chakra(Text, {
  label: 'TextRaceNumber',
  baseStyle: ({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '2xs',
    borderRadius: 'sm',
    minW: '5',
    h: '4',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    ml: '1',
    px: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.800',
      color: 'white',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'gamma.100',
      color: 'gamma.500',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextRaceNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GridCardContent = chakra(Grid, {
  baseStyle: () => ({
    gridAutoRows: ' auto',
    gridTemplateColumns: ' 1fr auto',
    gridTemplateRows: ' 1fr',
    gap: ' 0px 0px',
    gridTemplateAreas: `
      ". ."
    `,
    ...(() => {
      try {
        return styleImports[themeName]?.GridCardContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxGridHeaderItem = chakra(Box, {
  label: 'card-BoxGridHeaderItem',
  baseStyle: ({ theme }) => ({
    py: '1',
    px: '1.5',
    fontSize: '2xs',
    fontWeight: 'semibold',
    textAlign: 'center',

    _first: { textAlign: 'left' },

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.200',
      color: 'alpha.800',
      textTransform: 'uppercase',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'blackAlpha.500',
      textTransform: 'uppercase',
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxGridHeaderItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexGridRunnerItem = chakra(Flex, {
  label: 'card-FlexGridRunnerItem',
  baseStyle: ({ theme }) => ({
    borderBottom: '1px',
    py: '1',
    px: '1.5',
    alignItems: 'center',
    minH: '41px',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'white',
      borderBottom: 'none',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      borderBottomColor: 'blackAlpha.100',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexGridRunnerItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ImageRunnerSilk = chakra(Image, {
  baseStyle: ({ isGreyhound }: any) => ({
    h: '6',
    p: !isGreyhound && '2px',
    aspectRatio: '1 / 1',
    objectFit: 'contain',
    bg: 'white',
    borderRadius: 'base',
    boxShadow: 'lg',
    mr: '1.5',

    ...(() => {
      try {
        return styleImports[themeName]?.ImageRunnerSilk;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'image', ImageProps & { isGreyhound: boolean }>;

export const TextRunner = chakra(Text, {
  baseStyle: ({ isSuspended, theme }: any) => ({
    label: 'featured-TextRunner',
    fontSize: 'sm',
    fontWeight: 'medium',
    textTransform: 'capitalize',
    ...(isSuspended && { textDecor: 'line-through' }),

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.700',
      fontSize: 'xs',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextRunner;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps & { isSuspended: boolean }>;

export const TextRunnerNumber = chakra(Text, {
  baseStyle: () => ({
    label: 'featured-TextRunnerNumber',
    fontSize: 'xs',
    fontWeight: 'normal',
    ...(() => {
      try {
        return styleImports[themeName]?.TextRunnerNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextScratched = chakra(Text, {
  baseStyle: () => ({
    fontSize: '3xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'full',

    ...(() => {
      try {
        return styleImports[themeName]?.TextScratched;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * @deprecated
 */
export const Button = chakra(ChakraButton, {
  baseStyle: ({ theme, isActive }: any) => ({
    borderRadius: 'sm',
    h: '8',
    fontSize: 'xs',
    px: '2.5',
    flex: 1,
    cursor: 'pointer',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.600',
      color: 'white',
      boxShadow: 'md',
      borderRadius: 'sm',
      transition: 'all .3s linear',
      textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',

      '&:hover': {
        bg: 'alpha.800',
        boxShadow: 'lg',
      },

      '&:focus:hover': {
        bg: 'alpha.800',
        color: 'white',
        textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
        boxShadow: 'inset 0px 3px 4px #555',
      },

      ...(isActive && {
        bg: 'alpha.200',
        color: 'alpha.700',
        textShadow: 'none',
        boxShadow: 'inset 0px 3px 4px #499edd',

        _focus: {
          bg: 'alpha.300',
          boxShadow: 'inset 0px 3px 4px #499edd',
        },
        _hover: {
          bg: 'alpha.300',
          boxShadow: 'inset 0px 3px 4px #499edd',
        },
      }),
    }),

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'transparent',
      color: 'beta.50',
      border: '2px',
      borderColor: 'beta.50',

      '&:hover, &:focus': { bg: 'beta.50', color: 'blackAlpha.500' },

      ...(isActive && {
        bg: 'blackAlpha.500',
        color: 'beta.50',
        borderColor: 'blackAlpha.500',

        '&:hover, &:focus': { bg: 'blackAlpha.600', color: 'beta.50' },
      }),
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Button?.(isActive) ?? null;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps & { isActive: boolean }>;

export const LinkRaceDetails = chakra(Link, {
  baseStyle: ({ theme }) => ({
    display: 'block',
    textAlign: 'center',
    fontSize: 'xs',
    fontWeight: 'semibold',
    p: '2',
    mt: 'auto',
    transition: '.3s ease-in-out',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
      bg: 'alpha.600',
      textTransform: 'uppercase',

      _hover: {
        bg: 'alpha.800',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'betaAlpha.500',
      color: 'white',

      _hover: {
        bg: 'betaAlpha.200',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkRaceDetails;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps & ChakraLinkProps>;

export const ImageMascot = chakra(Image, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ImageMascot;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', ImageProps>;

export const HeadingSlogan = chakra(Text, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.HeadingSlogan;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

/**
 * @deprecated use textSloganProps instead
 */
export const SpanSlogan = chakra(Text, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.SpanSlogan;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const textSloganProps: TextProps = {
  'data-testid': 'featured-textSloganProps',

  ...(() => {
    try {
      return styleImports[themeName]?.textSloganProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const FlexFeatureRaceWrapper: FlexProps = {
  'data-testid': 'featured-featureRaceWrapper',

  ...(() => {
    try {
      return styleImports[themeName]?.FlexFeatureRaceWrapper;
    } catch (err) {
      return {};
    }
  })(),
};

export const FlexButtonsWrap = chakra(Flex, {
  label: 'card-FlexButtonsWrap',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexButtonsWrap;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkFeatureCard = chakra(Link, {
  label: 'featured-LinkFeatureCard',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.LinkFeatureCard;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps>;
