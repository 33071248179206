import { CSSObject, TextProps } from '@chakra-ui/react';

export const FormLabelThemed: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
  mt: '2',
};

export const formLabelError: TextProps = {
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  mt: '1.5',

  sx: { svg: { top: 0 } },
};

export const FormControlGroup: CSSObject = {
  mb: '1',
  '.chakra-input__left-addon': {
    borderLeftRadius: 'base',
    fontSize: 'sm',
    fontWeight: 'bold',
    bg: 'beta.600',
    color: 'white',
    h: 'auto',
  },
  '.chakra-input': {
    bg: 'beta.100',
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'bold',
    borderRightRadius: 'base',
  },
  'input[type="email"], input[type="password"]': {
    borderLeftRadius: 'base',
    bg: 'unset',
  },
  '[class$="InputBetSlip"]': {
    borderLeftRadius: 'base',
    borderRadius: 'base',
    bg: 'beta.800',
    color: 'white',
  },
};
