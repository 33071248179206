import { CSSObject } from '@chakra-ui/react';
import overviewBanner from '../../../../../../assets/betgalaxy/images/backgrounds/overviewBanner.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'secondary',
    bg: '#F2C200', // TODO: add colour into theme
    color: '#5B4900', // TODO: add colour into theme
    boxShadow:
      '0px 2px 3px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px #FFF inset',
    fontSize: '2xs',
    h: ['8', null, 'full'],
  },
};

export const FlexAccountDetails: CSSObject = {
  flexDir: ['column', null, 'row'],
  w: 'full',
  gap: '2',
  alignItems: ['unset', null, 'center'],
};

export const FlexAccountName: CSSObject = {
  fontSize: 'xl',
  color: 'alpha.300',
  fontWeight: 'semibold',
};

export const FlexBannerContainer: CSSObject = {
  p: '4',
  bgColor: 'beta.600',
  bgImage: `url(${overviewBanner})`,
  borderRadius: 'base',
  gap: ['2', null, 'unset'],
  backgroundPosition: 'top',
  backgroundSize: 'cover',
};

export const FlexLabel: CSSObject = {
  w: ['full', null, 'unset'],
  flex: '1',
};

export const FlexBalanceBetInfo: CSSObject = {
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px #FFF inset',
  px: '2',
  py: '1',
  bg: 'alpha.500',
  color: 'alpha.900',
  borderRadius: 'base',
  fontSize: '2xs',
  h: ['7', null, 'full'],
  fontWeight: 'extrabold',
  textTransform: 'uppercase',
  alignItems: 'center',
  justifyContent: 'center',
  flex: ['1', null, 'unset'],
};

export const FlexAccountBalances: CSSObject = {
  flexDir: 'row',
  gap: ['8', null, '6'],
  justifyContent: ['space-between', null, 'unset'],
};

export const TextButton: CSSObject = {
  alignSelf: 'center',
};

export const TextAccountBalanceLabel: CSSObject = {
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const TextAccountBalanceBaseUnit = (isBonus: boolean): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: 'xl',
  fontWeight: 'extrabold',
  color: isBonus ? 'alpha.500' : '#F2C200',
});

export const TextAccountBalanceSubUnit = (isBonus: boolean): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'extrabold',
  color: isBonus ? 'alpha.500' : '#F2C200',
  opacity: '0.7',
});

export const TextAccountBalanceCurrencySymbol = (
  isBonus: boolean
): CSSObject => ({
  mt: '0.5',
  alignSelf: 'flex-start',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: isBonus ? 'alpha.500' : '#F2C200',
});

export const FlexButtonContainer: CSSObject = {
  h: ['unset', null, '6'],
  fontSize: '2xs',
  gap: '2',
  flexDir: ['column', null, 'row'],
};
