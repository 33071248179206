import { CenterProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Well.styles.imports';

const themeName = getThemeName();

export type WellSchema = Partial<{
  wrapper: CenterProps;
}>;

export const wellStyles: WellSchema = {
  wrapper: {
    ...(() => {
      try {
        return styleImports[themeName].wellStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
