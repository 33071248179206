import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    border: '3px solid',
    borderColor: 'whiteAlpha.600',
    borderRadius: 'lg',
    px: '3',
    py: '1.5',
    mb: '4',
    sx: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
        gap: '1.5',
      },
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    gap: '1.5',
  },
  breadcrumbLinkItem: {
    textDecoration: 'underline',

    _hover: {
      textDecoration: 'none',
    },

    sx: {
      '&:not([href])': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  display: 'block',
  m: '0',
};
