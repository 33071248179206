export const groupBy = <T>(
  arr: T[],
  keyFunc: (item: T) => string | null | undefined
) => {
  const ret = new Map<string, T[]>();
  const ungrouped = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const elem of arr) {
    const k = keyFunc(elem);

    if (k === null || k === undefined) {
      ungrouped.push(elem);
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!ret.has(k)) ret.set(k, []);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ret.get(k)!.push(elem);
  }

  return [ret, ungrouped];
};
