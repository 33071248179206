import defaultTemplate from './template.default';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

let template = defaultTemplate;
try {
  template = require(`./template.${themeName}`)?.default;
} catch (err) {
  // will use default template
}

const exportVar = template;
export default exportVar;
