import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'beta.500',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  depositIconProps: {
    color: 'alpha.300',
    boxSize: '9',
  },
};
