import coin from '@/assets/goldenbet888/images/toggle/coin.png';
import koi from '@/assets/goldenbet888/images/toggle/koi.png';

export const FlexWrapper = {
  borderRadius: 'lg',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: '1.5',
  mb: '2',
  gap: '0',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  backgroundImage: `url(${koi}), linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-500))`,
  backgroundPosition: '-300px 0px',
  bgSize: '364px',
  bgBlendMode: 'overlay',
  h: '16',
  pos: 'relative',
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'accentMusashi',
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  color: isActive ? 'gamma.500' : 'gamma.200',
  letterSpacing: 'widest',
  px: '3',
});

export const Switcher = () => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) - var(--bc-sizes-1-5))',
  '--switch-track-width': '58px',
  bg: 'transparent',
  pos: 'relative',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${coin})`,
      boxSize: '36px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-3px',
      right: '0',
      top: '1px',
    },
  },
  '.chakra-switch__track': {
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px #F75144',
    bg: '#007549',
    borderRadius: '2em',
    h: '32px',

    _before: {
      bg: 'blackAlpha.500',
      h: '11px',
      w: '32px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: '2em',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset',
      content: '""',
      pos: 'absolute',
      left: '15px',
      top: '12px',
    },
  },
});
