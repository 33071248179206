import api from '../api';
import { TPunterSignup } from '../punter/punter.types';

export const signupEndpoint = 'punter/signup';

export const signup = async (signupDetails: TPunterSignup) =>
  api
    .post(signupEndpoint, signupDetails)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error.response.data.detail.msg as string);
    });
