import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    fontFamily: 'roboto',
    fontSize: '2xs',
    lineHeight: '1.125',
    sx: {
      '&[data-active="true"] > span': {
        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
        '> div': {
          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
        },
      },
      '&&': {
        minW: '70px',
      },
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.47)',
    alignSelf: 'center',
    textTransform: 'capitalize',
    fontWeight: 'extrabold',
  },
  buttonBaseBox: {
    fontSize: 'sm',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
    fontWeight: 'black',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
