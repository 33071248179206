import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    gap: ['2', '2', '3.5'],
    mt: ['0'],
    mb: ['0'],
  },
  FlexCard: {
    p: ['1'],
    borderRadius: ['10px'],
    bg: 'rgba(81, 208, 247, 0.10)',
    boxShadow:
      '0px 6px 20px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 4px rgba(80, 78, 243, 0.6) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset',
    backdropFilter: 'blur(5px)',
    border: '3px solid',
    borderColor: 'beta.500',
    my: ['0'],
  },
  TextCardHeading: {
    color: 'white',
    fontWeight: 'bold',
  },
  TextRaceNumber: {
    borderRadius: 'base',
    bg: 'alpha.600',
    h: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '18px',
    px: '1',
    color: 'gamma.400',
  },
  GridCardContent: {
    p: '0',
  },
  LinkCardHeader: {
    color: 'gamma.400',
  },
  BoxGridHeaderItem: {
    bg: 'rgba(0, 0, 0, 0.20)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 4px -3px rgba(0, 0, 0, 0.30) inset',
    fontSize: '2xs',
    color: 'delta.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    px: '2.5',
  },
  FlexGridRunnerItem: {
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.300',
    boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20)',
    py: 0,
    h: '46px',
  },
  TextRunner: {
    fontSize: 'smxs',
    color: 'white',
    fontWeight: 'semibold',
  },
  TextScratched: {
    fontSize: '8px',
    color: 'white',
    textDecoration: 'line-through',
  },
  TextRunnerNumber: {
    fontSize: 'smxs',
  },
  LinkRaceDetails: {
    bg: 'rgba(68, 72, 210, 0.50)',
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'gamma.400',
    textTransform: 'uppercase',
    borderBottomRadius: 'base',
  },
  buttonRaceCardOddsProps: {
    w: '100%',
    maxW: '56px',
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'bold',
    variant: 'solid',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};
