import React, { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import SVGIcon from '../SVGIcon';
import {
  StyledTextInput,
  TextInputContainer,
  TextInputWrapper,
  TextInputLabel,
  TextInputErrorLabel,
  HidePasswordLine,
  Prefix,
} from './TextInput.styles';

import TextInputProps from './types';

const TextInput: FC<TextInputProps> = ({
  type,
  value,
  placeholder = '',
  label = '',
  onFocus = () => null,
  inputName,
  inputId,
  defaultValue,
  errorText,
  disabled = false,
  dataCy,
  styles,
  prefix = '',
  inputMode = 'text',
  onChange,
  onKeyDown,
  noBottomMargin = false,
  onBlur,
}) => {
  const theme = useTheme();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <TextInputContainer noBottomMargin={noBottomMargin}>
      {label && <TextInputLabel htmlFor={inputId}>{label}</TextInputLabel>}
      <TextInputWrapper>
        {prefix && <Prefix>{prefix}</Prefix>}
        <StyledTextInput
          styles={styles}
          onFocus={onFocus}
          placeholder={placeholder}
          name={inputName}
          id={inputId}
          value={value}
          defaultValue={defaultValue}
          inputMode={inputMode}
          type={isPasswordVisible && type === 'password' ? 'text' : type}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
          data-cy={dataCy}
          onBlur={onBlur}
        />
        {type === 'password' && (
          <>
            <SVGIcon
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              svgName="passwordPeek"
              styles={theme.components.textInput.passwordPeek}
            />
            {isPasswordVisible && <HidePasswordLine />}
          </>
        )}
      </TextInputWrapper>
      {!!errorText && (
        <TextInputErrorLabel>
          <SVGIcon
            svgName="iconAlert"
            styles={theme.components.textInput.error.icon}
          />
          {errorText}
        </TextInputErrorLabel>
      )}
    </TextInputContainer>
  );
};

export default TextInput;
