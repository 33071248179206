import React from 'react';
import { TTemplate } from '../../../../../../helpers/createTemplate';
import Card from '../Card';
import {
  FlexFeatureCardWrapper,
  FlexFeatureWrapper,
  FlexFeatureTitleWrapper,
  TextFeatureSubHeading,
  TextFeatureHeading,
} from '../styles/Featured.styles';
import baseTemplate from './template.default';

const template: TTemplate = {
  wrapper: <FlexFeatureWrapper />,
  feature: (
    <>
      <FlexFeatureTitleWrapper>
        <TextFeatureHeading>Featured</TextFeatureHeading>
        <TextFeatureSubHeading>Race</TextFeatureSubHeading>
      </FlexFeatureTitleWrapper>
    </>
  ),
  card: (
    <FlexFeatureCardWrapper>
      <Card />
    </FlexFeatureCardWrapper>
  ),

  ...(baseTemplate?.first as TTemplate),
};

export default template;
