import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  p: '2',
};
export const LinkItem = (isFeatured: boolean) => ({
  p: '2',
  py: '3',
  bg: isFeatured ? 'beta.500' : 'white',
  color: isFeatured ? 'white' : 'alpha.600',
  transition: 'all, .3s ease-in-out',
  borderBottom: '1px solid',
  borderColor: 'gray.300',

  _hover: {
    bg: 'alpha.100',
  },

  '&+&': {
    borderTop: '1px',
    borderTopColor: isFeatured ? 'whiteAlpha.100' : 'gray.200',
  },

  svg: {
    boxSize: '6',
    p: '1',
    color: isFeatured ? 'unset' : 'alpha.600',
    border: '1px',
    borderColor: 'alpha.600',
    borderRadius: 'full',
  },
});

export const BoxWrapper: CSSObject = {
  borderColor: 'transparent',
};
