import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';
import bg from '@/assets/titanbet/images/account/bannerBg.png';
import bgMb from '@/assets/titanbet/images/account/bannerBgMb.png';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'primary',
    borderRadius: 'md',
  },
};

export const FlexBannerContainer: CSSObject = {
  border: '2px solid',
  borderColor: 'gamma.500',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  borderRadius: 'md',
  bgImage: [`url(${bgMb})`, null, `url(${bg})`],
  bgSize: 'contain',
  color: 'white',
  gap: ['2', null, '0'],
  p: ['2', null, '3'],
  minH: '107px',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.400',
};

export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
  color: 'alpha.200',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  textTransform: 'uppercase',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'body',
  textAlign: ['start', null, 'end'],
};

export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'body',
  fontWeight: 'bold',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  textTransform: 'uppercase',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'gamma.300',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'gamma.300',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'gamma.300',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.200',
  pt: '2',
};

export const TextButton: CSSObject = {
  h: '4',
  fontFamily: 'accent',
  pt: '0.5',
  textTransform: 'capitalize',
  fontSize: 'sm',
  fontWeight: 'normal',
};

export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'alpha.100',
  color: 'gamma.300',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
  lineHeight: 'normal',
};

export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
};
