import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/slambet/icons/mysteryBetLogo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: { variant: 'odds' },
  imageLogo: {
    w: '250px',
    src: mysteryLogo,
  },
  iconMystery: {},
  flexWrapperMysteryLabelView: {
    color: 'white',
    fontFamily: 'accent',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'normal',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
    display: 'inline-flex',
  },
  buttonAddToBetslip: {
    variant: 'solid',
    sx: {
      '&&': {
        borderLeftRadius: 'none',
        ms: '0',
      },
      _disabled: {
        bg: 'blackAlpha.600',
        color: 'alpha.400',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        '&&:hover': {
          bg: 'blackAlpha.600',
          color: 'alpha.400',
        },
      },
    },
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    borderRightRadius: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontFamily: 'accent',
    fontWeight: 'normal',
    sx: {
      '&&': {
        px: [2, 3],
      },
    },
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    px: '3',
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  flexWrapperMysteryPlacedBetView: {
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
    pt: '1.5',
  },
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear(to bottom, gamma.200 0%, gamma.300 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.40) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'base',
    boxSize: '10',
    p: '2',
    fill: 'beta.500',
    cursor: 'pointer',
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    fontSize: 'sm',
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  textPriceInfo: {
    fontSize: 'sm',
    color: 'alpha.400',
    fontFamily: 'accent',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  textRollover: {
    fontSize: 'sm',
    color: 'alpha.400',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textError: {
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontStyle: 'normal',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '10',
    color: 'alpha.400',
  },
  iconRace: { color: 'alpha.700', transform: 'none' },
  textSpanWhite: {
    color: 'white',
  },
  buttonRollOver: {
    variant: 'secondary',
    maxH: '24px',
    borderRadius: 'base',
    sx: {
      _disabled: {
        bg: 'blackAlpha.600',
        color: 'beta.100',
        textShadow: 'none',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        '&&:hover': {
          bg: 'blackAlpha.600',
          color: 'beta.100',
        },
      },
    },
  },
  spanSelectionText: {
    color: 'alpha.400',
  },
  spanPositionType: {
    color: 'alpha.700',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    pb: '0.5',
  },
};
