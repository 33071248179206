import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { CustomButtonProps } from '@/components/Button/Button';

export const BetCardVStack: CSSObject = {
  pb: '1',
};

export const buttonStakeProps: CustomButtonProps = {
  variant: 'unstyled',
  flex: '1',
  fontSize: 'sm',
  p: '1',
  textTransform: 'capitalize',
  h: '9',
  color: 'alpha.500',
  borderRadius: 'md',
  boxShadow:
    '0px 1px 0px 3px rgba(255, 255, 255, 0.30) inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px rgba(0, 0, 0, 0.60) inset',

  bgGradient: 'linear(to-b, beta.500, beta.600)',
  spanProps: {
    fontWeight: 'medium',
    fontSize: 'sm',
    py: '1',
  },

  border: 'none',
  _before: {
    content: '""',
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    border: '1px solid',
    borderColor: 'alpha.500',
    borderRadius: 'inherit',
  },
  _after: {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '1px',
    right: '1px',
    bottom: '1px',
    border: '2px solid black',
    borderRadius: 'inherit',
  },

  _hover: {
    bg: 'beta.400',
  },
  _focus: {
    boxShadow:
      '0px 1px 0px 3px rgba(255, 255, 255, 0.30) inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px rgba(0, 0, 0, 0.60) inset',
  },
  _active: {
    boxShadow: 'none',
    bgGradient: 'linear(#30363F, #21252B)', // @TODO colour not in pallete
  },
  _first: {
    borderStartRadius: 'md',
  },
  _last: {
    borderStartRadius: 'md',
  },
};

export const BetOdds: CSSObject = {
  mb: '2px',
};

export const BetCardContainer = (): CSSObject => ({
  bg: 'epsilon.500',
  borderRadius: 'md',
  color: 'white',

  border: '1px',
  borderColor: 'whiteAlpha.700',
  bgGradient: 'linear(to-b, #4B5A6D, #3F4953)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.20), 0px 0px 8px 0px rgba(255, 255, 255, 0.50) inset',
  '&::before': {
    content: `""`,
    position: 'absolute',
    borderRadius: 'md',
    bgSize: '100%',
    bgRepeat: 'no-repeat',
    top: '-5',
    w: '100%',
    h: '8',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  ml: '1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'alpha.600',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  lineHeight: '4',
});

export const EventSubtitle: CSSObject = {
  color: 'white',
  textTransform: 'capitalize',
  fontSize: 'xs',
  pt: '0.5',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  width: '25%',
  justifyContent: 'space-between',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const BetPlaced: CSSObject = {
  bgImage: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%);',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF;',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'black',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '0.5',
};

export const buttonBetPropositionClosedProps: CustomButtonProps = {
  variant: 'unstyled',
  px: '7',
  zIndex: '1',
  bg: 'alpha.500',
  boxShadow: 'md',
  color: 'black',
  borderRadius: 'md',
  fontSize: 'xs',
  display: 'flex',
  gap: '1',
  spanProps: {
    color: 'white',
    fontFamily: 'roboto',
    fontSize: 'xs',
    fontWeight: 'bold',
    px: '3.5',
    py: '2',
    textTransform: 'uppercase',
  },
  _hover: {
    bg: 'beta.700',
    color: 'white',
    boxShadow: 'md',
  },
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
  color: 'gray.600',
};

export const buttonBonusBetsProps = (
  isBonusBet: boolean,
  isInReview: boolean
): CustomButtonProps => ({
  display: 'flex',
  variant: 'unstyled',
  size: 'xs',
  px: '3',
  alignSelf: 'flex-start',
  textTransform: 'capitalize',
  w: isInReview ? '100%' : 'auto',
  isDisabled: isInReview,
  _hover: {
    bg: isBonusBet ? 'blackAlpha.400' : 'beta.600',
    color: 'white',
    border: isBonusBet ? '1px' : 'none',
    borderColor: 'gray.900',
    textShadow: 'none',
    boxShadow: 'none',
  },

  _active: {
    boxShadow: 'none',
  },

  _disabled: {
    boxShadow: '0 1px 0 0 #5e5f6e, inset 0 1px 4px 0 #0000004c',
    border: 'none',
    bg: 'blackAlpha.300',
    color: 'beta.50',
    opacity: '1',
    '& > span': {
      _first: {
        mr: '1',
      },
    },
  },

  ...((): CustomButtonProps => {
    if (isBonusBet && isInReview)
      return {
        mt: '-3',
        bg: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 'none',
        borderBottomRadius: 'md',

        spanProps: {
          fontFamily: 'roboto',
          textShadow: 'none',
          fontWeight: 'medium',
          fontSize: '2xs',
          py: '2',
          color: '#c2d2e9',
          textTransform: 'uppercase',
        },
        _hover: {
          border: 'none',
        },
      };

    if (isBonusBet)
      return {
        bg: 'alpha.800',
        boxShadow: 'none',
        color: 'alpha.500',
        borderRadius: 'md',
        border: '1px',
        borderColor: 'alpha.500',
        spanProps: {
          fontFamily: 'roboto',
          textShadow: 'none',
          fontWeight: 'medium',
          fontSize: '2xs',
          px: '2',
          py: '5px',
        },
      };

    return {
      bg: 'none',
      boxShadow:
        '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0 2px 3px 0 rgba(0, 0, 0, 0.15)',
      color: 'black',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, alpha.400, alpha.700)',
      spanProps: {
        fontFamily: 'roboto',
        textShadow: 'none',
        fontWeight: 'medium',
        fontSize: '2xs',
        px: '2',
        py: '1.5',
      },
    };
  })(),
});

export const BetPropositionClosedError: CSSObject = {
  bg: 'blackAlpha.500',
  borderRadius: 'base',
  pb: '3.5',
  top: '-1px',
  left: '-1px',
  right: '-1px',
  h: 'calc(100% + 2px)',
  w: 'auto',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5', '&:hover': { color: 'white' } }} />
);

export const EventRule: CSSObject = {
  ml: '2',
};
