import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    py: '4',
    bg: 'accent.600',
    borderRadius: 'base',
    borderColor: 'blackAlpha.700',
    border: '1px',
    gap: '3',
    boxShadow:
      '0px 0px 6px 0px #FFF inset, 0px 0px 6px 0px rgba(255, 255, 255, 0.80) inset, 0px 3px 8px 0px rgba(0, 0, 0, 0.40)',
  },
  depositIconProps: {
    color: 'brand.500',
    boxSize: '9',
  },
};
