import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  Text,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { styleImports } from './NextToJumpBanner.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg from '../../common/IconSvg';

const themeName = getThemeName();

export const restrictedStyles = (restricted: boolean) => ({
  ...(restricted && {
    filter: 'blur(2px)',
    cursor: 'default',
    opacity: '0.7',
  }),
});

/**
 * Wrapper for the NTJ banner
 */

export const FlexWrapper = chakra(Flex, {
  label: 'nextToJump-FlexWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const FlexBannerWrapper = chakra(Flex, {
  label: 'nextToJump-FlexBannerWrapper',
  shouldForwardProp: (prop) => !['isSensitive'].includes(prop),
  baseStyle: ({ theme }) => ({
    pos: 'relative',
    p: '1',
    align: 'center',
    columnGap: '1',
    maxW: '100vw',
    h: '10',

    'body[data-sensitive="true"] &': {
      pointerEvents: 'none',

      '> *': {
        filter: 'blur(2px)',
        cursor: 'default',
        opacity: '0.7',
      },
    },

    ...(theme.themeName === EThemes.Wellbet && {
      px: '4',
      py: '2.5',
      m: 'auto',
      h: '44px',
      w: 'container.xl',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexBannerWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

/**
 * Wrapper for the NTJ banner
 */
export const FlexItemsWrapper = chakra(Flex, {
  label: 'nextToJumpBanner-FlexItemsWrapper',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    flex: 1,
    w: 'full',
    overflowX: 'auto',
    pos: 'relative',
    columnGap: '2',
    // Hide scrollbar
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '& > div:last-of-type': { mr: '12' },

    ...(theme.themeName === EThemes.Betgalaxy && {
      mr: '80px',
      '& > div:last-of-type': { mr: '0' },
    }),

    ...(theme.themeName === EThemes.Wellbet && {
      mr: '0',
      '& > div:last-of-type': { mr: '0' },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexItemsWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * NTJ race item
 */
export const LinkItem = chakra(Link, {
  label: 'nextToJumpBanner-LinkItem',
  baseStyle: ({ theme }: any) => ({
    display: 'flex',
    py: '1',
    pl: '1',
    borderRadius: 'sm',
    alignItems: 'center',
    w: '175px',
    h: '100%',
    position: 'relative',
    transition: 'all, 0.3s linear',

    '.chakra-badge.state-prior': { color: 'white' },

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.600',
      w: '162px',

      _hover: {
        bg: 'alpha.800',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'beta.600',

      _hover: {
        bg: 'beta.700',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps>;

/**
 * Contains the race details
 */
export const FlexDetails = chakra(Flex, {
  label: 'nextToJumpBanner-FlexDetails',
  baseStyle: ({ theme }: any) => ({
    borderRadius: 'base',
    whiteSpace: 'nowrap',
    fontSize: '2xs',
    lineHeight: 'none',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    mr: '0.5',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
      display: 'block',
      maxW: '90px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
      display: 'block',
      maxW: '70px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexDetails;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Race icon styles
 */
export const Icon = chakra(IconSvg, {
  label: 'nextToJumpBanner-Icon',
  baseStyle: ({ theme }: any) => ({
    boxSize: '4',
    mr: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Icon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceNumber = chakra(Text, {
  label: 'nextToJumpBanner-RaceNumber',
  baseStyle: ({ theme }: any) => ({
    whiteSpace: 'nowrap',
    fontSize: '2xs',
    lineHeight: 'none',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    mr: '0.5',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
    }),

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RaceNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Container for the buttons
 */
export const FlexButtonContainer = chakra(Flex, {
  label: 'nextToJumpBanner-FlexButtonContainer',
  baseStyle: ({ theme }) => ({
    position: 'absolute',
    flexDir: 'row-reverse',
    justifyContent: 'space-between',
    gap: '2',
    right: 0,
    top: 0,
    bottom: 0,
    p: '1',
    pl: '8',
    rowGap: '0.5',

    'body[data-sensitive="true"] &': {
      filter: 'blur(2px)',
      cursor: 'default',
      opacity: '0.7',
    },

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'transparent',
      pos: 'relative',
      p: '0',
      pl: '1',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bgGradient: 'linear(to-r, transparent, beta.900 35%, beta.800)',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexButtonContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Container for the buttons
 */
export const ButtonChevron = chakra(IconButton, {
  label: 'nextToJumpBanner-ButtonChevron',
  baseStyle: ({ theme }) => ({
    display: 'inline-flex',
    w: '8',
    px: '1',
    borderRadius: 'sm',
    flex: 1,

    '& > svg': { boxSize: '6' },

    ...(theme.themeName === EThemes.Wellbet && {
      minW: '6',
      w: '4',
      h: 'full',
      bg: 'alpha.600',
      color: 'white',
      p: '0',
      borderRadius: 'sm',

      '& > svg': { boxSize: '5' },

      _hover: {
        bg: 'alpha.700',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'beta.400',
      color: 'white',

      _hover: {
        bg: 'beta.600',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonChevron;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', IconButtonProps>;

export const CountdownTimer = chakra(Flex, {
  label: 'nextToJumpBanner-CountdownTimer',
  baseStyle: () => ({
    '.chakra-badge': {
      position: 'absolute',
      right: '0',
      bottom: '0',
      top: '0',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      h: '100%',
      py: '0',
      px: '2',
      ml: '2',
    },
    '.state-prior': {
      bg: 'blackAlpha.300',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.CountdownTimer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
