import Black from '../../assets/fiestabet/fonts/roboto-black-webfont.woff2';
import BlackItalic from '../../assets/fiestabet/fonts/Roboto-BlackItalic.ttf';
import Bold from '../../assets/fiestabet/fonts/roboto-bold-webfont.woff2';
import BoldItalic from '../../assets/fiestabet/fonts/Roboto-BoldItalic.ttf';

import ExtraLight from '../../assets/fiestabet/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '../../assets/fiestabet/fonts/Roboto-ThinItalic.ttf';
import Light from '../../assets/fiestabet/fonts/Roboto-Light.ttf';
import LightItalic from '../../assets/fiestabet/fonts/Roboto-LightItalic.ttf';
import Regular from '../../assets/fiestabet/fonts/Roboto-Regular.ttf';
import Italic from '../../assets/fiestabet/fonts/Roboto-Italic.ttf';
import SemiBold from '../../assets/fiestabet/fonts/roboto-medium-webfont.woff2';
import SemiBoldItalic from '../../assets/fiestabet/fonts/Roboto-MediumItalic.ttf';

import CreteRoundItalic from '../../assets/fiestabet/fonts/CreteRound-Italic.ttf';
import CreteRoundRegular from '../../assets/fiestabet/fonts/creteround-regular-webfont.woff2';

import UltraRegular from '../../assets/fiestabet/fonts/ultra-regular-webfont.woff2';

import MexicanKnappettRegular from '../../assets/fiestabet/fonts/mexican_knappett-webfont.woff2';

export const fontFace = `
  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${Light}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${LightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Regular}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${Italic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${Bold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${BoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${Black}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${BlackItalic}') format('opentype');
  }

  @font-face {
    font-family: 'CreteRound';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${CreteRoundRegular}') format('opentype');
  }
  @font-face {
    font-family: 'CreteRound';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${CreteRoundItalic}') format('opentype');
  }
  
  @font-face {
    font-family: 'Ultra';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${UltraRegular}') format('opentype');
  }
  
  @font-face {
    font-family: 'MexicanKnappett';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${MexicanKnappettRegular}') format('opentype');
  }
`;
