import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  bg: 'zeta.400',
  borderRadius: 'base',
  mb: '0',
  flex: '1',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear-gradient(180deg, #8D4522 0%, #76391C 100%)',
  color: 'zeta.300',
  fontSize: '2xs',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.35)',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'alpha.600',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '30px',
  bg: 'blackAlpha.300',
  border: 'none',
  color: 'blackAlpha.400',
  fontWeight: 'bold',
  fontSize: 'xs',
  borderRadius: 'md',
  fontFamily: 'SkranjiBold',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'alpha.800',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
  color: 'alpha.500',
};
