import { css } from 'styled-components';
import { ERaceType } from '../../../../lib/DBModels';

// TODO: Remove
export const circularNavButtonWrapper = css`
  display: flex;
  padding: var(--bc-space-5) var(--bc-space-5);

  ${({ theme }) => theme.device.tablet} {
    padding: var(--bc-space-5) var(--bc-space-10);
  }
  ${({ theme }) => theme.device.laptop} {
    padding: var(--bc-space-5) 0;
  }
`;

// TODO: remove
export const circularNavButtonContainer = css`
  margin-right: var(--bc-space-6);
`;

// TODO: Remove
export const raceMeetingsBreadcrumbWrapper = css`
  padding: 0 var(--bc-space-5);

  ${({ theme }) => theme.device.tablet} {
    padding: 0 var(--bc-space-10);
  }
  ${({ theme }) => theme.device.laptop} {
    padding: 0 0;
  }
`;

export const circularNavButton = {
  [ERaceType.Horse]: css`
    width: var(--bc-space-14);
    height: var(--bc-space-14);
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    box-shadow: 0px 0px 15px -4px ${({ theme }) => theme.basePalette.blue};
    background-image: ${({ theme }) => theme.basePalette.blackGradient};

    :hover,
    :focus {
      background: var(--bc-colors-blackAlpha-500);
    }
  `,
  [ERaceType.Greyhound]: css`
    width: var(--bc-space-14);
    height: var(--bc-space-14);
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    box-shadow: 0px 0px 15px -4px ${({ theme }) => theme.basePalette.blue};
    background-image: ${({ theme }) => theme.basePalette.blackGradient};

    :hover,
    :focus {
      background: var(--bc-colors-blackAlpha-500);
    }
  `,
  [ERaceType.Harness]: css`
    width: var(--bc-space-14);
    height: var(--bc-space-14);
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    box-shadow: 0px 0px 15px -4px ${({ theme }) => theme.basePalette.blue};
    background-image: ${({ theme }) => theme.basePalette.blackGradient};

    :hover,
    :focus {
      background: var(--bc-colors-blackAlpha-500);
    }
  `,
};

export const circularNavButtonSelected = css`
  background-image: ${({ theme }) => theme.basePalette.blueGradient120deg};

  :hover,
  :focus {
    background-image: ${({ theme }) => theme.basePalette.blueGradient120deg};
  }
`;

export const circularNavButtonTitle = css`
  text-align: center;
  font-size: var(--bc-fontSizes-2xs);
  font-weight: 700;
  color: white;
  padding: var(--bc-space-2) 0;
`;

export const horseIcon = css`
  color: ${({ theme }) => theme.palette.white};
  width: var(--bc-space-7);
  height: var(--bc-space-7);
`;

export const greyhoundIcon = css`
  color: ${({ theme }) => theme.palette.white};
  width: var(--bc-space-7);
  height: var(--bc-space-7);
`;

export const harnessIcon = css`
  color: ${({ theme }) => theme.palette.white};
  width: var(--bc-space-7);
  height: var(--bc-space-7);
`;

// TODO: remove
export const raceMeetingsList = css`
  margin-bottom: var(--bc-space-20);
`;

export const nextToJumpListWrapper = css`
  padding: 0rem var(--bc-space-5);

  ${({ theme }) => theme.device.tablet} {
    padding: 0rem var(--bc-space-10);
  }
  ${({ theme }) => theme.device.laptop} {
    padding: 0rem 0;
  }
`;
