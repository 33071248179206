import React from 'react';
import { ButtonAction } from '../styles/ResponsibleGambling.styles';

export const SwitchButtonAction = (props: any) => [
  {
    name: 'comButtonAction',
    component: <ButtonAction {...props} />,
  },
];

export default SwitchButtonAction;
