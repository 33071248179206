import { CSSObject } from '@chakra-ui/react';

export const FlexWrapper: CSSObject = {
  bg: 'epsilon.500',
  borderBottom: '3px solid',
  borderColor: 'gamma.500',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  mb: '2',
};

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '44px',
  w: '1390px',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
};

export const LinkItem: CSSObject = {
  bg: 'epsilon.400',
  w: '162px',
  borderRadius: 'md',

  _hover: {
    bg: 'epsilon.500',
  },

  '.chakra-badge.state-inprogress': {
    bg: 'beta.600',
    color: 'beta.900',
    mr: '1',
    mt: '1',
    height: '4',
    borderRadius: 'base',
  },

  '.chakra-badge.state-prior': {
    bg: 'yellow.400',
    color: 'yellow.800',
    mr: '1',
    mt: '1',
    height: '4',
    borderRadius: 'base',
  },

  '.chakra-badge.state-ended': {
    bg: 'red.400',
    color: 'white',
    mr: '1',
    mt: '1',
    height: '4',
    borderRadius: 'base',
  },
};

export const RaceNumber: CSSObject = {
  color: 'white',
};
export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '90px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'white',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  mr: '4',
  gap: '0.5',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  w: '4',
  h: 'full',
  bg: 'epsilon.400',
  color: 'white',
  p: '0',
  borderRadius: 'md',

  '& > svg': { boxSize: '6' },

  _hover: {
    bg: 'epsilon.500',
  },
};
