import { InputProps, InputGroupProps } from '@chakra-ui/react';
import { styleImports } from './BetCardInput.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type BetCardInputSchema = Partial<{
  betCardInputGroup: InputGroupProps;
  betCardInputLeftAddon: any;
  betCardInput: InputProps;
}>;

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputGroup: {
    'data-testid': 'betcard-betCardInputGroup',

    ...(styleImports?.[themeName]?.betCardInputStyles?.betCardInputGroup ?? {}),
  },
  betCardInputLeftAddon: {
    'data-testid': 'betcard-betCardInputLeftAddon',

    ...(styleImports?.[themeName]?.betCardInputStyles?.betCardInputLeftAddon ??
      {}),
  },
  betCardInput: {
    'data-testid': 'betcard-betCardInput',

    ...(styleImports?.[themeName]?.betCardInputStyles?.betCardInput ?? {}),
  },
};
