import { CSSObject } from '@chakra-ui/react';

export const FlexWrapper: CSSObject = {
  bg: 'accent.700',
  borderBottom: '1px solid',
  borderColor: '#2D325A',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  zIndex: '9999',
};

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  w: 'container.xl',
};

export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
};

export const LinkItem: CSSObject = {
  borderRadius: 'base',
  bg: 'accent.400',
  w: '152px',
  gap: '0',
  h: '5',
  px: '4px',
  py: 0,
  pos: 'relative',
  contain: 'paint',
  fontSize: '2xs',
  pr: '0',

  '> *': { zIndex: 9 },

  _hover: {
    bg: 'accent.300',
  },

  '&&': {
    '.chakra-badge': {
      w: '44px',
      borderRightRadius: 'base',
      padding: '3px 4px',
      textAlign: 'center',
      fontSize: '2xs',
      fontFamily: 'roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '17px',
    },
  },
};

export const Icon: CSSObject = {
  color: 'accent.50',
  boxSize: '2.5',
  ml: 0,
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '0',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  w: '5',
  h: '5',
  bg: 'accent.400',
  borderRadius: 'base',
  color: 'white',
  p: '0',

  '& > svg': { boxSize: '3' },

  _hover: {
    bg: 'accent.300',
  },
};
