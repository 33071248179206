import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';
import { CSSObject } from '@emotion/react';
import { Tennisball } from '@styled-icons/ionicons-outline';
import { StyledIcon } from '@styled-icons/styled-icon';
import { getThemeName } from '@/helpers/getThemeName';

const themeName = getThemeName().toLowerCase();

// TODO: consolidate this with the IconSvg
export type TIconProps = {
  name?: string;
  styles?: string;
  anim?: number;
  toString?: () => string;
  as?: StyledIcon;
  color?: string;
  fontSize?: string;
  boxSize?: string;
  pos?: any;
  right?: string;
  sx: CSSObject;
};

export type TIconSvg = {
  name: string;
} & IconProps;

/**
 * We first see if there is a theme specific icon
 * on failure of loading theme specific icon, fallback to the core icon pack.
 */
export default function IconSvg({ name, ...rest }: TIconSvg) {
  const nameSplit = name.split('sports/');
  const iconName = nameSplit.length > 1 ? nameSplit[1] : name;
  const iconNameParse = iconName.replace(/ /g, '_');

  let svg;
  try {
    /**
     * webpack loader prefix added as webpack dynamic imports have a bug
     * importing SVG's as react-components unless explicitly
     * pre-pending the loader.
     *
     * Read more here:
     * https://github.com/facebook/create-react-app/issues/5276#issuecomment-665628393
     */
    svg =
      require(`!!@svgr/webpack?-svgo,+titleProp,+ref!@/assets/${themeName}/icons/${iconNameParse}.svg`)?.default;
  } catch (error) {
    try {
      svg =
        require(`!!@svgr/webpack?-svgo,+titleProp,+ref!@/assets/core/icons/${iconNameParse}.svg`)?.default;
    } catch (err) {
      svg = Tennisball;
    }
  }

  return <Icon as={svg} {...(rest as any)} data-cy={iconNameParse} />;
}
