import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { TBetCloudErrorResponse } from '@/api/api.types';
import { useQuerySportMarketGroup } from '@/api/sportDetailsMarketGroups/sportDetailsMarketGroups.hooks';
import { useQuerySportMarkets } from '@/api/sportDetailsMarkets/sportDetailsMarkets.hooks';
import { TSportMarkets } from '@/api/sportDetailsMarkets/sportDetailsMarkets.types';
import { useQuerySportMatches } from '@/api/sportDetailsMatches/sportDetailsMatches.hooks';
import { TSportMatchesResponse } from '@/api/sportDetailsMatches/sportDetailsMatches.types';

export const useMarketsByMatch = () => {
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get('matchId');

  const { data: sportsMarkets, isInitialLoading: isInitialLoadingMarkets } =
    useQuerySportMarkets(
      { match_id: matchId ?? '' },
      { refetchInterval: 5 * 1000 }
    );

  const { data: marketGroups, isInitialLoading: isInitialLoadingGroups } =
    useQuerySportMarketGroup(
      { match_id: matchId ?? '' },
      { refetchInterval: 5 * 1000 }
    );

  const marketsInGroups = sportsMarkets?.reduce<
    Record<string, TSportMarkets[]>
  >((a, b) => {
    const betOption = b.bet_option;
    const marketsGroup = marketGroups?.filter((grp) =>
      grp.bet_options?.includes(betOption ?? '')
    );

    if (Object.keys(marketsGroup ?? {})?.length) {
      const mergedMarkets = marketsGroup?.reduce<
        Record<string, TSportMarkets[]>
      >((acc, cur) => {
        const marketGroupName = cur?.name ?? '';

        // if event has only 1 market then filter out top markets
        if (sportsMarkets.length === 1 && marketGroupName === 'Top Markets')
          return { ...acc };

        return {
          ...acc,
          [marketGroupName]: [...(a?.[marketGroupName] ?? []), b],
        };
      }, {});

      return {
        ...a,
        ...mergedMarkets,
      };
    }

    return {
      ...a,
      'Other Markets': [...(a?.['Other Markets'] ?? []), b],
    };
  }, {});

  const marketsInGroupsKeysSorted = [
    ...Object.keys(marketsInGroups ?? {}),
  ].sort((a, b) => {
    if (a === 'Other Markets') return 1;
    if (b === 'Other Markets') return -1;
    const aIndex = marketGroups?.findIndex((item) => item.name === a) ?? 0;
    const bIndex = marketGroups?.findIndex((item) => item.name === b) ?? 0;
    return aIndex - bIndex;
  });

  const marketsInGroupsSorted = marketsInGroupsKeysSorted.reduce<
    Record<string, TSportMarkets[]>
  >((a, b) => {
    const mrkIdx = marketGroups?.findIndex((item) => item.name === b) ?? 0;

    const sortedMarkets = [...(marketsInGroups?.[b] ?? [])].sort((aa, bb) => {
      const mkGroup = marketGroups?.[mrkIdx];

      const aaOptIdx = mkGroup?.bet_options?.indexOf(aa.bet_option ?? '') ?? 0;
      const bbOptIdx = mkGroup?.bet_options?.indexOf(bb.bet_option ?? '') ?? 0;

      if (aaOptIdx === bbOptIdx) {
        return (
          (aa.bet_option_sort_order ?? 0) - (bb?.bet_option_sort_order ?? 0)
        );
      }

      /* put unmatched items at the end */
      if (aaOptIdx < 0) return 1;
      if (bbOptIdx < 0) return -1;

      return aaOptIdx - bbOptIdx;
    });

    return {
      ...a,
      [b]: sortedMarkets ?? [],
    };
  }, {});

  return {
    marketsInGroups: marketsInGroupsSorted,
    isInitialLoading: isInitialLoadingMarkets || isInitialLoadingGroups,
  };
};

/**
 * Hook that returns the current match details.
 */
export const useMatchDetails = (
  options?: Omit<
    UseQueryOptions<TSportMatchesResponse, AxiosError<TBetCloudErrorResponse>>,
    'queryKey' | 'queryFn'
  >
) => {
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get('matchId');
  const competitionId = searchParams.get('competitionId');
  const tournamentId = searchParams.get('tournamentId');

  const { data: dataMatches, ...rest } = useQuerySportMatches(
    competitionId
      ? { competition_id: competitionId ?? '' }
      : { tournament_id: tournamentId ?? '' },
    {
      enabled: !!competitionId || !!tournamentId,
      staleTime: 60 * 1000,
      ...options,
    }
  );

  const match = dataMatches?.find((m) => m.match_id === matchId);
  return { data: match, dataMatches, ...rest };
};
