import React from 'react';
import NotAuth from '../components/NotAuthButtons';
import AuthedContent from '../components/Authed';

const NotAuthButtons = [
  {
    name: 'comNotAuthButtons',
    component: <NotAuth />,
  },
];

const Authed = [
  {
    name: 'comAuthedContent',
    component: <AuthedContent />,
  },
];

export default {
  NotAuthButtons,
  Authed,
};
