import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const BoxWrapper: CSSObject = {
  px: ['2', '3'],
};

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: 'none',
  borderRadius: 'md',
  mb: '2',
  border: 'none',
  borderBottomRadius: 'base',

  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',

    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  pb: ['3', '2'],
  bg: 'beta.500',
  borderBottomRadius: 'base',
  px: '2.5',
  ...(isNested && {
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  color: 'white',
  px: '2',
  py: '2.5',
  border: 'none',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: 'sm',

  _hover: {
    bgGradient: 'linear(to-b, beta.700, beta.800)',
  },

  '.chakra-accordion__icon': {
    boxSize: '5',
    border: '1px',
    borderColor: 'white',
    borderRadius: 'full',
    color: 'white',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    borderBottomRadius: 'none',
  },

  ...(isNested && {
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    color: 'white',
    px: '2',

    '.chakra-accordion__icon': {
      boxSize: ['4', '5'],
      border: '1px',
      borderColor: 'white',
      borderRadius: 'full',
      color: 'white',
      transition: '.3s ease-in-out',
    },

    _hover: {
      bgGradient: 'linear(to-b, beta.700, beta.800)',
    },
  }),
});
