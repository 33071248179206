import { CSSObject } from '@chakra-ui/react';
import overviewBannerDesktop from '@/assets/fiestabet/images/backgrounds/overviewBannerDesktop.png';
import overviewBannerMobile from '@/assets/fiestabet/images/backgrounds/overviewBannerMobile.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: { h: 8, fontSize: 'xs' },
};

export const FlexBannerContainer: CSSObject = {
  bg: [`url(${overviewBannerMobile})`, null, `url(${overviewBannerDesktop})`],
  '&&': {
    backgroundSize: 'cover',
    backgroundPosition: ['center top', null, 'right'],
    backgroundRepeat: 'no-repeat',
  },
  bgBlendMode: 'overlay, lighten',
  borderRadius: 'base',
  color: 'white',
  gap: ['2', null, '0'],
  p: ['2', null, '4'],
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1',
};

export const FlexAccountName: CSSObject = {
  color: 'epsilon.500',
  fontSize: ['lg', null, 'xl'],
  fontWeight: 'semibold',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  mb: '0.5',
  textTransform: 'uppercase',
  color: 'epsilon.500',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'gamma.200',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: ['sm', null, 'xs'],
  verticalAlign: 'super',
  color: 'delta.300',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: ['2xl', null, 'xl'],
  color: 'delta.300',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: ['lg', null, 'sm'],
  color: 'delta.300',
  opacity: '0.7',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  border: '2px',
  borderRadius: 'lg',
  borderColor: 'delta.300',
  boxShadow: '0 2px 6px rgba(0, 0, 0, .2)',
  flex: ['1', null, '0'],
  fontSize: 'xs',
  fontWeight: 'normal',
  h: ['9', null, '8'],
  justifyContent: 'center',
  px: '4',
  textAlign: 'center',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  textTransform: 'capitalize',
  whiteSpace: [null, null, 'nowrap'],
  color: 'epsilon.100',
  fontFamily: 'Ultra',
  lineHeight: 'normal',
};
