import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    bgGradient: 'linear(to-b, #8D4522, #76391C)',
    color: 'beta.100',
    fontFamily: 'body',
    fontSize: '2xs',
    fontWeight: 'bold',
    borderRadius: '3px',
    textTransform: 'uppercase',

    sx: {
      '&&': {
        _hover: {
          bgGradient: 'linear(to-b, alpha.600, alpha.600)',
          color: 'beta.100',
        },
      },

      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, #8D4522, #76391C)',
      },
  
      '&[data-active="true"]': {
        bgGradient: 'linear(to-b, alpha.800, alpha.800)',
        color: 'beta.100',
      },
    },
  },
}
