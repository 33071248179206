import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset',
  bg: 'alpha.600',
  boxSize: '9',
  borderRadius: 'lg',
  display: 'flex',
  '& svg': {
    color: 'alpha.200',
    boxSize: '7',
  },
};

export const MatchCardContainer: CSSObject = {
  bg: 'white',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '52px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  w: ['95%', null, '100%'],
  mx: ['2', null, 'unset'],
  px: '1.5',
  py: '1.5',
  _hover: {
    bgGradient: 'linear(to-b, zeta.400, zeta.600)',
    opacity: 0.7,
  },
  '& div:nth-child(3) svg': {
    color: 'alpha.900',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'alpha.900',
    boxSize: '6',
  },
};

export const MatchName: CSSObject = {
  mt: '1.5',
  '& span': {
    fontWeight: 'semibold',
    fontSize: 'sm',
    fontFamily: 'Roboto',
    color: 'alpha.600',
  },
};

export const MatchDate: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'extrabold',
  color: 'beta.400',
  fontFamily: 'Roboto',
};

export const TeamItemOdds: CSSObject = {
  color: 'yellow.900',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const Seperator: CSSObject = {
  color: 'blackAlpha.400',
  mx: '0',
  px: '0',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon"
`,
};

export const TextSpanName = (): CSSObject => ({
  color: 'alpha.600',
});

export const CompetitionName: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'extrabold',
  color: 'beta.400',
  fontFamily: 'Roboto',
};

export const ArrowIcon: CSSObject = {
  boxSize: '7',
  color: 'alpha.600',
  border: '1px',
  borderColor: 'alpha.600',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  '& svg': {
    color: 'alpha.400',
  },
};

export const BodyContainer: CSSObject = {
  py: '1',
};
