import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Image,
  Switch,
  keyframes,
} from '@chakra-ui/react';
import bUfo from '@/assets/betgalaxy/images/switch/blue-ufo.svg';
import gUfo from '@/assets/betgalaxy/images/switch/green-ufo.svg';
import moon from '@/assets/betgalaxy/images/switch/moon.svg';

const FlexWrapper = chakra(Flex, {
  baseStyle: () => ({
    borderRadius: 'base',
    contain: 'layout',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, null, 'unset'],
    mb: ['1', null, null, '2.5'],
    gap: '2',

    boxShadow: 'md',
    bg: ['betaAlpha.500', null, 'betaAlpha.800'],
  }),
});

const TextToggle = chakra(Text, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontSize: 'sm',
    p: '2',
    flex: [1, 'unset'],

    borderRadius: 'full',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    letterSpacing: '1px',
    px: '5',
    py: '1',
    maxW: '110px',
    textAlign: 'center',
    transition: 'all, 0.2s linear',

    ...(isActive && {
      bg: 'blackAlpha.500',
      boxShadow: 'inset 0px 3px 9px rgb(0 0 0 / 30%)',
      color: 'beta.50',
    }),
    ...(!isActive && {
      bg: 'transparent',
      color: 'beta.50',
    }),
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const pulse = keyframes`
    0% { transform: translateX(0); }
    50% { transform: translateX(25px); }
    100% { transform: translateX(0); }
`;

const GreenUfo = chakra(Image, {
  baseStyle: () => ({
    display: ['none', null, 'block'],
    animation: `${pulse} 10s ease infinite`,
    pos: 'absolute',
    right: '20px',
    bottom: '0',
  }),
});

const BlueUfo = chakra(Image, {
  baseStyle: () => ({
    display: ['none', null, 'block'],
    pos: 'absolute',
    left: '0',
    bottom: '0',
  }),
});

const Switcher = chakra(Switch, {
  baseStyle: () => ({
    bg: 'transparent',

    '.chakra-switch__thumb': {
      bgImage: `url(${moon})`,
      bgSize: '100%',
    },
    '.chakra-switch__track': {
      bg: 'blackAlpha.600',
    },

    _checked: {
      bg: 'transparent',

      '.chakra-switch__track': {
        bg: 'blackAlpha.600',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  return (
    <FlexWrapper>
      <GreenUfo src={bUfo} />
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sport
      </TextToggle>

      <BlueUfo src={gUfo} />
    </FlexWrapper>
  );
}
