import React from 'react';
import HomeLinksSplit from '@/views/Home/links/components/HomeLinks/HomeLinksSplit';
import ThemeHome from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeHome, [
  {
    name: 'homeLinks',
    component: <HomeLinksSplit />,
    config: {
      after: 'toggle',
    },
  },
]);
