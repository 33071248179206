import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
    rowGap: '3',
  },
  FlexCard: {
    px: 'px',
    borderRadius: 'lg',
    bg: `rgba(78, 178, 255, 0.14)`,
    boxShadow:
      '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
    pos: 'relative',
    sx: {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
        border: '0px solid transparent',
        borderImage: `url(${slamBorder}) 50`,
        pointerEvents: 'none',
        borderImageWidth: '50px',
      },
    },
  },
  LinkCardHeader: {
    bg: 'transparent',
  },
  TextCardHeading: {
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    lineHeight: 'normal',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    pt: '0.5',
  },
  TextRaceNumber: {
    bg: 'beta.200',
    borderRadius: 'sm',
    color: 'blackAlpha.700',
  },
  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.500',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontFamily: 'body',
    px: '2',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px dashed',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderBottomColor: 'blackAlpha.500',
    px: '2.5',
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
  },
  TextRunnerNumber: {
    sx: {
      _last: {
        color: 'white',
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },
  TextScratched: {
    color: 'white',
  },
  LinkRaceDetails: {
    color: 'alpha.900',
    bgGradient: 'linear(to-b, alpha.300, alpha.700)',
    textTransform: 'capitalize',
    fontSize: 'sm',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 1px 4px rgba(255, 255, 255, 0.2)',
    lineHeight: '27px',
    borderBottomRadius: 'base',
    h: '42px',
    mx: '3px',
    mb: '1',
    _hover: {
      bgGradient: 'linear(to-b, alpha.100, alpha.500)',
    },
  },
  buttonRaceCardOddsProps: {
    variant: 'odds',
    fontFamily: 'accent',
    fontSize: 'sm',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    h: '8',
    w: '46px',
  },
};
export const TextRunner: CSSObject = {
  color: 'white',
};
export const IconCardHeader: CSSObject = {
  color: 'alpha.400',
};
