import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TPunterState } from '../account.types';
import {
  TBank,
  TDepositLimit,
  TFullPunter,
  TPunterAccountOverview,
  TTransaction,
  TWithdrawal,
  TConfirmedBetDTO,
} from '../../../lib/DBModels';
import { EGreenIDStatus } from '../VerifyIdentity/Services/Config.VerifyIdentity';
import { EPunterStatus } from '@/lib/punter/types';

const initialState: TPunterState = {
  punterProfile: null,
  accountOverview: null,
  myBetsData: null,
  myBetsLoading: false,
  transactionsData: [],
  transactionsLoading: false,
  privacyPolicy: '',
  termsConditions: '',
  depositLimit: null,
  banks: [],
  withdrawals: [],
  verificationStatus: undefined,
};

export const isIdentityVerified = (
  verificationStatus: EGreenIDStatus | undefined
) =>
  verificationStatus === EGreenIDStatus.VERIFIED ||
  verificationStatus === EGreenIDStatus.VERIFIED_ADMIN ||
  verificationStatus === EGreenIDStatus.VERIFIED_WITH_CHANGES;

const punterSlice = createSlice({
  name: 'punter',
  initialState,
  reducers: {
    setPunterProfile: (
      state: TPunterState,
      action: PayloadAction<TFullPunter>
    ) => ({ ...state, punterProfile: action.payload }),
    setPunterTransactions: (
      state: TPunterState,
      action: PayloadAction<TTransaction[]>
    ) => ({
      ...state,
      transactionsData: [...state.transactionsData, ...action.payload],
    }),
    setPunterAccountOverview: (
      state: TPunterState,
      action: PayloadAction<TPunterAccountOverview>
    ) => ({ ...state, accountOverview: action.payload }),
    setMyBets: (
      state: TPunterState,
      action: PayloadAction<TConfirmedBetDTO>
    ) => ({
      ...state,
      myBetsData: action.payload,
    }),
    clearPunterProfile: (state: TPunterState) => ({
      ...state,
      punterProfile: null,
    }),
    clearPunterOverview: (state: TPunterState) => ({
      ...state,
      accountOverview: null,
    }),
    clearMyBets: (state: TPunterState) => ({ ...state, accountOverview: null }),
    clearPunterTransactions: (state: TPunterState) => ({
      ...state,
      transactionsData: [],
    }),
    setTermsConditions: (
      state: TPunterState,
      action: PayloadAction<string>
    ) => ({ ...state, termsConditions: action.payload }),
    setPrivacyPolicy: (state: TPunterState, action: PayloadAction<string>) => ({
      ...state,
      privacyPolicy: action.payload,
    }),
    setDepositLimit: (
      state: TPunterState,
      action: PayloadAction<TDepositLimit>
    ) => ({ ...state, depositLimit: action.payload }),
    setBankAccounts: (state: TPunterState, action: PayloadAction<TBank[]>) => ({
      ...state,
      banks: action.payload,
    }),
    setWithdrawals: (
      state: TPunterState,
      action: PayloadAction<TWithdrawal[]>
    ) => ({ ...state, withdrawals: action.payload }),
    setVerificationStatus: (
      state: TPunterState,
      action: PayloadAction<EGreenIDStatus>
    ) => {
      const identityVerified = isIdentityVerified(action.payload);
      return {
        ...state,
        verificationStatus: action.payload,
        accountOverview: {
          ...state.accountOverview,
          identity_verified: identityVerified,
          status: identityVerified
            ? EPunterStatus.Normal
            : state.accountOverview?.status,
        },
      };
    },
    reset: () => initialState,
  },
  extraReducers: {
    'punter/getPunterAccountTransactions/pending': (state: TPunterState) => ({
      ...state,
      transactionsLoading: true,
    }),
    'punter/getPunterAccountTransactions/fulfilled': (state: TPunterState) => ({
      ...state,
      transactionsLoading: false,
    }),
    'punter/getPunterAccountTransactions/rejected': (state: TPunterState) => ({
      ...state,
      transactionsLoading: false,
    }),
    'punter/getMyBets/pending': (state: TPunterState) => ({
      ...state,
      myBetsLoading: true,
    }),
    'punter/getMyBets/fulfilled': (state: TPunterState) => ({
      ...state,
      myBetsLoading: false,
    }),
    'punter/getMyBets/rejected': (state: TPunterState) => ({
      ...state,
      myBetsLoading: false,
    }),
  },
});

// Slice actions
export const {
  setPunterProfile,
  clearPunterProfile,
  setPunterAccountOverview,
  clearPunterOverview,
  setMyBets,
  clearMyBets,
  setPunterTransactions,
  clearPunterTransactions,
  setTermsConditions,
  setPrivacyPolicy,
  setDepositLimit,
  setBankAccounts,
  setWithdrawals,
  setVerificationStatus,
  reset,
} = punterSlice.actions;

// Slice reducer
export default punterSlice.reducer;
