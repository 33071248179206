export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      color: 'black',
    },
  },
  sizes: {
    lg: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
    md: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
  },
  variants: {},
  defaultProps: {},
};
