import React, { FC } from 'react';

import { SpinnerWrapper, Spinner } from './Styles';

import { LoadingSpinnerProps } from './types';

const LoadingSpinner: FC<LoadingSpinnerProps> = (stylingProps) => (
  <SpinnerWrapper {...stylingProps}>
    <Spinner />
  </SpinnerWrapper>
);

export default LoadingSpinner;
