import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextItem, Wrapper } from './styles/Slogan.styles';

export default function Slogan() {
  return (
    <Wrapper>
      <TextItem>
        <FormattedMessage
          id="onboarding.login.subtitle"
          values={{
            Node: (string) => <span>{string}</span>,
            span: (string) => <span>{string}</span>,
          }}
        />
      </TextItem>
    </Wrapper>
  );
}
