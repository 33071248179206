import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

import activeBg from '@/assets/betroyale/images/backgrounds/buttonActiveBg.png';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
  ...((propLength ?? 0) === 2 && {
    gap: '1',
  }),
});

export const ButtonProp = ({ propLength }: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    border: 'none',
    borderTop: '1px dashed',
    borderColor: 'blackAlpha.300',
    bg: 'transparent',
    boxShadow: 'none',

    ...((propLength ?? 0) === 2 && {
      borderTop: 0,
      p: '1',
      _first: {
        pr: '0',
      },
      _last: {
        pl: '0',
      },
    }),
    ...((propLength ?? 0) > 2 && {
      h: '8',

      _first: {
        borderTop: 0,
        pt: '0',
      },
      _last: {
        pb: '0',
      },
    }),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    ...((propLength ?? 0) === 2 && {
      color: 'gray.700',
      fontSize: 'xs',
      fontWeight: 'bold',
      mb: '0.5',
    }),

    ...((propLength ?? 0) > 2 && {
      color: 'gray.700',
      fontSize: 'sm',
      fontWeight: 'semibold',
    }),

    '&.return-amount': {
      bg: 'beta.500',
      width: '100%',
      h: '9',
      borderRadius: 'md',
      filter: 'drop-shadow(2px 2px 5px #00000059)',
      boxShadow:
        '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #744f0a inset, 0px 0px 0px 5px #ffd076 inset',
      color: 'blackAlpha.700',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
      fontWeight: 'black',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',

      _disabled: {
        bg: 'gray.400',
        borderWidth: '2px',
        borderColor: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
        boxShadow:
          '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset',
        border: 'none',
        opacity: '1',

        _hover: {
          bg: 'gray.400',
        },
      },

      _hover: {
        color: 'blackAlpha.600',
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
      },
      _focus: {
        color: 'blackAlpha.600',
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
      },

      ...(isSelected && {
        bg: 'alpha.800',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
        color: 'beta.500',
        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
        border: 'none',

        '&&': {
          bgImg: `url(${activeBg})`,
          textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
          bgSize: '25%',
          border: '2px',
          borderColor: 'black',
        },
      }),

      ...((propLength ?? 0) > 2 && {
        width: 'unset',
        h: '32px',
        ...(isSelected && {
          '&&': {
            bgImg: `url(${activeBg})`,
            bgRepeat: 'no-repeat',
            bgPosition: '0% 12%,18% 11%,90% 91%',
            bgSize: '100%',
          },
        }),
      }),
    },
  },
});
