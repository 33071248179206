import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import betslipBg from '@/assets/juicybet/images/backgrounds/inner.png';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  spanPropositionType: {
    color: 'white',
  },
  spanRaceInfo: {
    color: 'white',
  },
  iconMysteryBetFooter: {
    color: 'alpha.200',
  },
  flexWrapperMysteryBetFooterBetPlaced: {},
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'beta.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    fontWeight: 'bold',
    fontSize: 'xs',
    w: '-webkit-fill-available',
    h: 'auto',
    py: 1.5,
  },
  buttonBonusBetsProps: {
    variant: 'unstyled',
    bg: 'none',
    boxShadow:
      '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0 2px 3px 0 rgba(0, 0, 0, 0.15)',
    color: 'alpha.900',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    spanProps: {
      fontFamily: 'roboto',
      textShadow: 'none',
      fontWeight: 'medium',
      fontSize: '2xs',
      px: '2',
      py: '1.5',
    },
    display: 'flex',
    size: 'xs',
    px: '3',
    alignSelf: 'flex-start',
    textTransform: 'capitalize',
    w: 'auto',
    border: '1px',
    borderColor: 'alpha.400',

    sx: {
      w: 'auto',
      '&[data-ismysterybet="true"]': { w: '-webkit-fill-available' },

      '.chakra-button__icon': {
        display: 'none',
      },

      '&&[data-isbonusbet="true"]': {
        bg: 'alpha.800',
        boxShadow: 'none',
        color: 'white',
        borderRadius: 'md',
        h: '26px',
        border: '1px',
        borderColor: 'alpha.500',
        spanProps: {
          fontFamily: 'roboto',
          textShadow: 'none',
          fontWeight: 'medium',
          fontSize: '2xs',
          px: '2',
          py: '5px',
        },
        _disabled: {
          bg: 'blackAlpha.500',
          color: 'alpha.400',
          border: 'none',
          py: '2',
          textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
          textTransform: 'uppercase',
          w: '100%',
          boxShadow: 'none',
          opacity: '1',
          mt: '-3',
          borderRadius: 'none',
          borderBottomRadius: 'md',
        },
      },
    },
  },
  buttonBetPropositionClosedProps: {
    h: '8',
    color: 'white',
  },
  buttonStakeProps: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    h: '38px',
    color: 'alpha.900',
  },
  betCardSVG: {
    fill: '#FE6260',
  },
};

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};

export const BetCardContainer = (): CSSObject => ({
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderColor: 'alpha.50',
  borderWidth: '3px',
  color: 'alpha.50',
  mb: '-2.5',
  bg: `linear-gradient(180deg, #fe6260 0%, #f84846 100%), url(${betslipBg})`,
  borderRadius: 'md',
  bgRepeat: 'repeat',
  bgSize: '130%',
  backgroundBlendMode: 'overlay',
  '.state-prior': { color: 'white' },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'alpha.50',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'md',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'white',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
});

export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
  '&&': {
    color: 'white',
  },
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, white, white)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const EventRule: CSSObject = {
  color: 'white',
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'whiteAlpha.700',
  borderRadius: 'base',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};

export const BetOdds: CSSObject = {
  mb: '2px',
};

export const buttonBetPropositionClosedProps: CSSObject = {
  variant: 'unstyled',
  bg: 'beta.500',
  borderRadius: 'md',
  textTransform: 'uppercase',
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'xs',
  px: '3',
  py: '2',
};
