import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/fiestabet/images/backgrounds/raceBg.png';

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bgImage: `url(${background})`,
  pb: '0',
  mt: ['unset', '4'],
  borderTopRadius: 'xl',
  overflow: 'visible',
  fontSize: 'md',
  '&&': { px: ['2.5', '4'], pb: '2' },
  color: 'epsilon.100',
  textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
  fontFamily: 'CreteRound',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '100%',
  textTransform: 'capitalize',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'xl',
  textTransform: 'capitalize',
  fontFamily: 'CreteRound',
  color: 'epsilon.100',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.50)',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  color: 'alpha.700',
  m: '2',
  borderRadius: [null, null, null, 'base'],
};

export const WrapperContainer: CSSObject = {
  px: '0',
  bgImage: `url(${background})`,
  borderBottomRadius: 'xl',
  boxShadow: '0px 4px 0px 0px #370F23',
  mb: '3',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
};
