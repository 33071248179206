import { CSSObject } from '@chakra-ui/react';
import menuTile from '../../../assets/volcanobet/images/sidenav/menu-tile1x.png';
import menuTile2x from '../../../assets/volcanobet/images/sidenav/menu-tile.png';
import menuTop from '../../../assets/volcanobet/images/sidenav/menu-top1x.png';
import menuTop2x from '../../../assets/volcanobet/images/sidenav/menu-top.png';
import menuBottom from '../../../assets/volcanobet/images/sidenav/menu-bottom1x.png';
import menuBottom2x from '../../../assets/volcanobet/images/sidenav/menu-bottom.png';

export const GridItemBetslip: CSSObject = {
  h: '100%',
  '& > div[class*="-BetSlipModalContainer"]': {
    bg: `url(${menuTile})`,
    bgRepeat: 'repeat-y',
    bgSize: '100%',
    zIndex: '1',
    width: '100%',
    borderRadius: '3xl',
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '100%',
    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bg: `url(${menuTile2x})`,
      bgSize: '272px',
    },
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '85px',
      zIndex: '-1',
      backgroundImage: `url(${menuTop})`,
      backgroundSize: '272px',
      backgroundRepeat: 'no-repeat',
      '@media (-webkit-min-device-pixel-ratio: 2)': {
        bg: `url(${menuTop2x})`,
        bgSize: '272px',
      },
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-6px',
      left: '0',
      width: '100%',
      height: '85px',
      backgroundImage: `url(${menuBottom})`,
      backgroundSize: '272px',
      backgroundRepeat: 'no-repeat',
      zIndex: '-1',
      '@media (-webkit-min-device-pixel-ratio: 2)': {
        bg: `url(${menuBottom2x})`,
        bgSize: '272px',
        bgRepeat: 'no-repeat',
      },
    },
  },
};

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
  maxWidth: ['inherit', '272px'],
};
