import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '2',
    mb: ['0', '3'],
    gap: '3',
    mx: ['0', null],
    mt: '1',
  },

  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    borderRadius: 'xl',
    _last: {
      mb: '0',
    },
    bg: `linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%)`,
  },

  LinkCardHeader: {
    h: '12',
    color: 'beta.800',
    mx: '0',
  },

  TextCardHeading: {
    fontFamily: 'heading',
    fontSize: 'md',
    fontWeight: 'normal',
    letterSpacing: 'wide',
  },

  TextRaceNumber: {
    fontWeight: 'bold',
    fontFamily: 'body',
    borderRadius: 'base',
    fontSize: '2xs',
    boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
    bg: 'delta.500',
    color: 'delta.900',
    ml: '0.5',
  },

  GridCardContent: {
    fontSize: 'sm',
    p: '0',
  },

  BoxGridHeaderItem: {
    bg: 'beta.200',
    color: 'beta.800',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: 'body',
    px: '1.5',
  },

  FlexGridRunnerItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '0',
    bg: 'transparent',
    pr: '2',
    py: '1',
    mx: '1px',
    borderBottom: '1px dashed var(--bc-colors-whiteAlpha-500)',
    position: 'relative',
    _after: {
      content: '""',
      position: 'absolute',
      left: '0',
      bottom: '0',
      width: 'full',
      borderBottom: '1px',
      borderColor: 'blackAlpha.50',
      borderStyle: 'dashed',
    },
  },

  TextRunner: {
    fontSize: 'xs',
    fontWeight: 'semibold',
    color: 'beta.800',
    fontFamily: 'body',
  },

  TextRunnerNumber: {
    fontWeight: 'bold',
    _last: {
      fontSize: '2xs',
      fontWeight: 'normal',
      pos: 'relative',
      top: '-2px',
    },
  },

  LinkRaceDetails: {
    h: '10',
    lineHeight: '6',
    bgGradient: 'linear(to-b, beta.700, beta.800)',
    color: 'beta.500',
    textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontFamily: 'accent',
    borderRadius: 'md',
    letterSpacing: 'wide',
    fontWeight: 'hairline',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
    mx: '2',
    my: '2.5',
    _hover: {
      bgGradient: 'linear(to-b, delta.400, delta.500)',
      color: 'white',
    },
    _focus: {
      bgGradient: 'linear(to-b, beta.900, beta.900)',
      boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
    },
    _disabled: {
      bg: 'gray.400',
      borderColor: 'gray.600',
      color: 'gray.200',
      textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
      boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
    },
  },

  TextScratched: {
    fontSize: '2xs',
    color: 'beta.800',
    height: '34px',
    lineHeight: '10',
    paddingTop: 'auto',
    paddingBottom: 'auto',
  },

  buttonRaceCardOddsProps: {
    variant: 'odds',
    borderRadius: 'md',
    minW: '12',
    w: 'auto',
    fontSize: 'xs',
    px: '1',
    ml: 'auto',
    h: '34px',
  },
};

export const TextRunner: CSSObject = {
  color: 'beta.800',
  fontFamily: 'body',
  fontSize: 'sx',
};
