import {
  footerBaseStyle,
  TFooterTheme,
} from '../../base/customComponents/footer';
import { TStyleConfig } from '../../base/types';

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: {
    ...footerBaseStyle,
    footer: {
      ...footerBaseStyle.footer,
      pb: '0',
      bg: ['transparent', null, 'transparent'],
      bgSize: 'cover',
      bgPos: 'bottom',
      py: '0',
      color: 'white',
    },
    stackGap: '1',
    footerBar: {
      display: 'flex',
      flexDir: ['column', null, 'row'],
      pr: ['0', null, '0'],
      mb: ['2', null, '8'],
      borderRadius: 'md',
      alignItems: 'center',
      justifyContent: ['center', null, 'unset'],
      '& > img': {
        maxW: '300px',
        h: '80px',
        ml: '0',
      },
    },
    footerLink: {
      color: 'gamma.500',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    footerLinkTel: {
      color: 'alpha.200',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    customFooterLink: {
      color: 'gamma.500',
      textTransform: 'capitalize',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    footerLinks: {
      ml: ['unset', null, 'auto'],
      pb: ['4', null, '0'],
      alignItems: 'center',
      justifyContent: ['space-around', null, 'unset'],
      color: 'beta.200',
      fontWeight: 'medium',
      flexDir: ['row', null, 'unset'],
      display: 'inline-flex',
      mt: ['1', null],
    },
    footerContentContainer: {
      px: ['2.5', 'unset'],
      gap: ['4', '6'],
      ...footerBaseStyle.footerContentContainer,
      bg: [null, null, 'transparent'],
      justifyContent: 'space-between',
      '&': {
        flexDirection: ['column', null, 'column'],
      },
      p: '0',
    },
    nav: {
      ...footerBaseStyle.nav,
      fontSize: 'sm',
      fontWeight: 'medium',
      minW: [null, null, '370px'],
      mt: ['2', 'unset'],
      display: ['flex', 'grid'],
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto',
      columnGap: '28',
      rowGap: '2',
      alignItems: ['center', 'end'],
      gridTemplateAreas: `
        ". ."
      `,

      '& > a:last-of-type': {
        mt: '2',
      },
    },
    blurb: {
      ...footerBaseStyle.blurb,
      flex: 'unset',
      textAlign: 'left',
      color: 'beta.200',
      width: 'unset',
      mb: '0',
      ml: [null, '0'],
      mr: [null, null, '4'],
    },
    blurbHeading: {
      ml: 'unset',
      mt: 'unset',
      color: 'white',
    },
    blurbHeadingAge: {
      ...footerBaseStyle.blurbHeadingAge,
      borderColor: 'beta.200',
      color: 'white',
      border: '4px',
      mb: '2',
      boxSize: '8',
      fontSize: 'xs',
    },
    blurbHeadingThink: {
      fontSize: 'xs',
      color: 'white',
      fontWeight: 'bold',
      mb: '4',
    },
    blurbContent: {
      ...footerBaseStyle.blurbContent,
      color: 'white',
      ml: 'unset',
      mr: [null, null, 'auto'],
    },
    copyright: {
      ...footerBaseStyle.copyright,
      color: 'white',
      textAlign: ['center', 'left'],
      mt: ['7'],
      mb: ['2', 'unset'],
      px: ['8', 'unset'],
      py: '0',
      fontWeight: ['semibold', 'medium'],
    },
    copyrightContainer: {
      color: 'white',
    },
    container: {
      bg: 'transparent',
      borderRadius: ['0', '0', '8'],
      '&&': {
        px: ['2', null],
        py: '4',
      },
      mt: '3',
    },
    weAcceptBox: {
      mb: '0',
      '&&&': { t: '0' },
    },
    emailUs: {
      fontSize: 'md',
      fontWeight: 'bold',
    },
    quickLinks: {
      color: 'white',
      fontSize: ['sm', 'md'],
      textAlign: ['center', 'left'],
      fontWeight: ['semibold', 'bold'],
      p: '0',
    },
    ourPartners: {
      fontSize: ['sm', 'md'],
      fontWeight: ['semibold', null, 'medium'],
    },
    weAccept: {
      fontSize: ['sm', 'md'],
      fontWeight: ['semibold', null, 'medium'],
    },
    footerText: {
      color: 'white',
      fontSize: ['sm', 'md'],
    },
    imageWrapper: {
      gap: '0',
    },
    partnerWrapper: {
      mr: '1',
    },
    footerDownloadImage: {
      borderRadius: 'md',
      border: '2px solid white',
    },
  },
  sizes: {},
  variants: {},
};
