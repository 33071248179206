import React from 'react';
import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import Carousel from '@/components/Carousel';
import promo from '@/assets/juicybet/images/carousel/banner.png';
import promoMobile from '@/assets/juicybet/images/carousel/banner-mobile.png';

export const useBanner = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return [<Image key={2} src={isLargerThan768 ? promo : promoMobile} />];
};

export default function Index() {
  const slides = useBanner();

  return (
    <Box
      sx={{
        mb: ['1.5', null, null, '0'],
        borderRadius: [null, null, null, 'base'],
        contain: 'paint',
      }}
    >
      <Carousel slides={slides} />
    </Box>
  );
}
