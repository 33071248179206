import { CSSObject } from '@chakra-ui/react';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'whiteAlpha.900' },
  flexWrapper: { mt: 0.5 },
};

export const TabContainer: CSSObject = {
  borderBottom: '0',
  px: '1.5',
  py: '1.5',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  color: 'beta.300',
  textShadow: 'none',
  h: '5',
  fontWeight: 'bold',
  fontSize: '2xs',
  ml: '1',
  p: '2',
  borderRadius: 'base',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  boxShadow: 'none',

  _first: {
    ml: '0',
  },

  sx: {
    '&&': {
      bg: 'transparent',
      _hover: { color: 'white', boxShadow: 'none' },
    },
    '&[aria-selected="true"]': {
      color: 'gamma.800',
      textShadow: '1px 0px 0px rgba(255, 255, 255, 0.35)',
      bg: 'gamma.400',
    },
  },
};
