import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  borderColor: 'blackAlpha.300',
  borderRight: 'none',
  borderStyle: 'dashed',
  pl: '0',
  py: '2',
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'green.500' : 'gamma.600',
});

export const TableHeadingText: CSSObject = {
  color: 'gamma.600',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};
