import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  heading: {
    mb: '2',
    color: 'gamma.600',
    fontWeight: '700',
  },
  promotionWrapper: {
    p: '4',
    bg: 'white',
    my: '3',
    boxShadow: 'lg',
    borderRadius: 'md',
  },
  flexWrapper: { gap: '4', pt: '4', justifyContent: 'space-between' },
  termsAndConditions: {
    color: 'gray.700',
    fontSize: 'sm',
    pt: '1',
  },
};
