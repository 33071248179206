import { CSSObject } from '@chakra-ui/react';
import cardSportBG from '@/assets/sugarcastle/images/backgrounds/cardSportBG.png';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '2'],
  mb: ['2', null, '0'],
  borderRadius: 'base',
  border: '2px solid',
  borderColor: 'beta.400',
  boxShadow:
    '0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 2px #443318 inset',
  bgImage: cardSportBG,
  bgRepeat: 'no-repeat',
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
  borderBottom: '1px',
  borderColor: 'blackAlpha.400',
};

export const TextHeading: CSSObject = {
  color: '#443318',
  fontSize: 'sm',
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  mx: '0.5',
  mb: '0.5',
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.50)',
  width: 'calc(100% - 4px)',
  boxShadow: '0px -2px 0px 0px rgba(0, 0, 0, 0.24)',
  _hover: {
    bgGradient: 'none',
    bg: 'gamma.600',
  },
  _active: {
    bg: 'gamma.800',
  },
};
