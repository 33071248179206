/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { getThemeName } from '@/helpers/getThemeName';
import { BASE_API_URL } from '../../lib/Constants';
import { TContentfulResponse } from './contentful.types';

const contentfulEndpoint = '/generic/config/fields.';

const baseURL = BASE_API_URL;

export const queryContentful = async () => {
  const themeName = getThemeName().toLowerCase();
  const identifier = `desktop${themeName}`;

  try {
    const response = await axios({
      method: 'get',
      url: `${contentfulEndpoint}${identifier}`,
      baseURL,
    });
    const res = response.data as TContentfulResponse;
    type TObjectKey = keyof typeof res.items;
    const objKey = identifier as TObjectKey;
    return res?.items?.[0]?.fields?.[objKey] || false;
  } catch {
    return false;
  }
};
