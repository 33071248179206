import { Collapse, useDisclosure } from '@chakra-ui/react';
import React, { Fragment, useMemo, useState } from 'react';
import { TEventInfo } from '@/lib/DBModels';
import {
  LIMIT,
  MarketLayoutHeader,
  MarketLayoutPagination,
  MarketLayoutRow,
  MarketLayoutTabPanel,
  MarketLayoutTabs,
  TMarketLayoutProps,
} from '../MarketLayout/MarketLayout';
import PropositionButton from '../PropositionButton/PropositionButton';

function getPropositionName(propositionInfo?: Partial<TEventInfo> | null) {
  if (propositionInfo?.value1 === 0) return '0';

  if (!propositionInfo?.value1) {
    return;
  }

  const { value1, value2 } = propositionInfo;
  const isPositive = value2 === 1;

  return isPositive ? `+${value1}` : `-${value1}`;
}

export type THandicapLayoutProps = Pick<
  TMarketLayoutProps,
  'parts' | 'participants' | 'onSelection'
>;

export default function HandicapLayout({
  parts,
  participants,
  onSelection,
}: THandicapLayoutProps) {
  const { isOpen, onToggle } = useDisclosure();

  const [currentIdx, setCurrentIdx] = useState(0);
  const shouldRenderPagination = useMemo(() => {
    const part = parts[currentIdx];
    const maxRowCount = Math.max(part.markets.length);
    return maxRowCount > LIMIT;
  }, [currentIdx, parts]);

  const sortedParts = useMemo(
    () =>
      parts.map((p) => ({
        title: p.title,
        markets: p.markets.sort(
          (a, b) =>
            (a.market_info?.value1 ?? 0) * (a.market_info?.value2 ?? 0) -
            (b.market_info?.value1 ?? 0) * (b.market_info?.value2 ?? 0)
        ),
      })),
    [parts]
  );

  return (
    <>
      <MarketLayoutTabs
        labels={parts.map(({ title }) => title)}
        onChange={(i) => setCurrentIdx(i)}
      >
        {sortedParts.map(({ title, markets }) => (
          <MarketLayoutTabPanel key={title}>
            {[...markets].slice(0, LIMIT).map((m, i) => {
              if (!m.propositions?.length) {
                return (
                  <MarketLayoutTabPanel>
                    <MarketLayoutRow isFirst>N/A</MarketLayoutRow>
                  </MarketLayoutTabPanel>
                );
              }

              const p1 = m.propositions.find(
                (p) =>
                  p.proposition_info?.participant === participants?.[0].name
              );
              const p2 = m.propositions.find(
                (p) =>
                  p.proposition_info?.participant === participants?.[1].name
              );

              return (
                <Fragment key={m.market_id}>
                  {!i && (
                    <MarketLayoutRow isFirst>
                      {participants?.map(({ name }) => (
                        <MarketLayoutHeader key={name}>
                          {name}
                        </MarketLayoutHeader>
                      ))}
                    </MarketLayoutRow>
                  )}

                  <MarketLayoutRow>
                    <PropositionButton
                      flex="1"
                      isActive={p1?.is_selected}
                      data-active={p1?.is_selected}
                      isDisabled={p1?.is_disabled}
                      isInline
                      isSuspended={p1?.is_suspended}
                      odds={p1?.return_amount}
                      propositionName={getPropositionName(p1?.proposition_info)}
                      onClick={() => p1 && onSelection?.(p1)}
                    />
                    <PropositionButton
                      flex="1"
                      isActive={p2?.is_selected}
                      isDisabled={p2?.is_disabled}
                      isInline
                      isSuspended={p2?.is_suspended}
                      odds={p2?.return_amount}
                      propositionName={getPropositionName(p2?.proposition_info)}
                      onClick={() => p2 && onSelection?.(p2)}
                    />
                  </MarketLayoutRow>
                </Fragment>
              );
            })}

            {shouldRenderPagination &&
              [...markets].slice(LIMIT).map((m) => {
                const p1 = m.propositions?.find(
                  (p) =>
                    p.proposition_info?.participant === participants?.[0].name
                );
                const p2 = m.propositions?.find(
                  (p) =>
                    p.proposition_info?.participant === participants?.[1].name
                );

                return (
                  <Collapse
                    key={`${m.market_id}-hidden`}
                    in={isOpen}
                    animateOpacity
                    unmountOnExit
                  >
                    <MarketLayoutRow>
                      <PropositionButton
                        flex="1"
                        isActive={p1?.is_selected}
                        data-active={p1?.is_selected}
                        isDisabled={p1?.is_disabled}
                        isInline
                        isSuspended={p1?.is_suspended}
                        odds={p1?.return_amount}
                        propositionName={getPropositionName(
                          p1?.proposition_info
                        )}
                        onClick={() => p1 && onSelection?.(p1)}
                      />
                      <PropositionButton
                        flex="1"
                        isActive={p2?.is_selected}
                        isDisabled={p2?.is_disabled}
                        isInline
                        isSuspended={p2?.is_suspended}
                        odds={p2?.return_amount}
                        propositionName={getPropositionName(
                          p2?.proposition_info
                        )}
                        onClick={() => p2 && onSelection?.(p2)}
                      />
                    </MarketLayoutRow>
                  </Collapse>
                );
              })}
          </MarketLayoutTabPanel>
        ))}
      </MarketLayoutTabs>

      <MarketLayoutPagination
        in={shouldRenderPagination}
        isOpen={isOpen}
        onPageClick={onToggle}
      />
    </>
  );
}
