import * as yup from 'yup';

import { getStrings } from '@/helpers/utils';

const [
  {
    Account: { Withdraw: strings },
  },
] = getStrings();

// Bank info will be used in ABA files
// https://www.cemtexaba.com/aba-format/cemtex-aba-file-format-details
// Account name max length is 32 chars
// Account number is 9 digits max.
// Customers with acct numbers wiht more than 9 digits
// will have to phone customer services
const addAccountSchema = yup
  .object({
    account_name: yup
      .string()
      .required(strings.AddAccount.errorStrings.nameRequired)
      .max(32, strings.AddAccount.errorStrings.accountNameLength),
    bsb: yup
      .string()
      .matches(/^\S+$/, strings.AddAccount.errorStrings.BsbNumberNoSpaces)
      .matches(/^\d{3}(-?|\s?)\d{3}$/, {
        message: strings.AddAccount.errorStrings.bsbFormat,
      })
      .max(6, strings.AddAccount.errorStrings.bsbFormat)
      .required(strings.AddAccount.errorStrings.bsbRequired),
    account_number: yup
      .string()
      .matches(/^\S+$/, strings.AddAccount.errorStrings.AccountNumberNoSpaces)
      .matches(/^[0-9 ]+$/, {
        message: strings.AddAccount.errorStrings.accountNumberFormat,
      })
      .max(9, strings.AddAccount.errorStrings.accountNumberFormat)
      .required(strings.AddAccount.errorStrings.accountNumberRequired),
    account_nick_name: yup.string().notRequired(),
  })
  .required();

export type AddAccountSchemaType = yup.InferType<typeof addAccountSchema>;

export default addAccountSchema;
