import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pb: ['2', '4', '0'],
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  pb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive
    ? 'blackAlpha.400'
    : 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  color: isActive ? 'beta.900' : 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'lg',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  bgBlendMode: 'overlay, normal',

  '&:hover, &:focus': {
    bg: isActive
      ? 'blackAlpha.400'
      : 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
    color: isActive ? 'beta.900' : 'white',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'beta.900',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'lg',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  bgBlendMode: 'overlay, normal',

  '&:hover, &:focus': {
    bg: 'blackAlpha.400',
    color: 'beta.900',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
