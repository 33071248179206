import React from 'react';
import { BoxProps, CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';

const defaultFontColor = 'epsilon.700';
const buttonFocus = {
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
};
const buttonHover = {
  bg: 'alpha.600',
};
const buttonActive = {
  bg: 'alpha.800',
  color: 'alpha.300',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
};

export const StakeCollapse: CSSObject = {
  px: '2',
};

export const BetCardVStack: CSSObject = {
  pb: '0',
};

export const betCardErrorFlexProps = {
  borderTopRadius: 'md',
};

export const BetCardContainer = (): CSSObject => ({
  position: 'relative',
  bgGradient: 'linear(to-b, epsilon.400, epsilon.500)',
  boxShadow:
    '0px 4px 0px 0px #B68452, 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 8px 0px rgba(255, 255, 255, 0.40) inset',
  borderRadius: 'base',
  p: '0',
  color: 'white',
  '&::before': {
    content: `""`,
    position: 'absolute',
    borderRadius: 'md',
    bgSize: '100%',
    bgRepeat: 'no-repeat',
    top: '-5',
    w: '100%',
    h: '8',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: defaultFontColor,
  mr: '-1',
  ml: '1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: defaultFontColor,
  display: 'flex',
  fontWeight: '800',
  fontSize: '16px',
  fontFamily: 'roboto',
};

export const ExoticsFooterContainer: CSSObject = {
  '&&': {
    mb: ['1.5', null, 'unset'],
  },
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  lineHeight: '4',
  flex: '1',
  textTransform: 'capitalize',
  color: defaultFontColor,
  display: 'flex',
  gap: '1',
  ml: '2',
  pr: '2',
  svg: {
    mb: '1.5',
    color: 'beta.800',
  },
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: defaultFontColor,
  fontWeight: 'medium',
  textTransform: 'capitalize',
  ml: '2',
});

export const EventSubtitle: CSSObject = {
  color: defaultFontColor,
  textTransform: 'capitalize',
  fontSize: 'xs',
  ml: '2',
  mt: 'auto',
  fontWeight: '500',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  width: '25%',
  justifyContent: 'space-between',
};

export const ExoticsNumberContainer: CSSObject = {
  color: 'epsilon.800',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'epsilon.800',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgImage: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%);',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF;',
  borderRadius: 'md',
  flexDirection: 'column',
  color: 'black',
  px: '2',
  py: '1.5',
  mb: '2',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'epsilon.800',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '0.5',
};

export const StakeButton: CSSObject = {
  fontSize: '14px',
  fontWeight: '600',
  fontFamily: 'fredoka',
  color: 'blackAlpha.700',
  textTransform: 'uppercase',
  h: '9',
  bg: 'var(--local-gradient-green, linear-gradient(180deg, #6DDA01 0%, #5EBB01 100%))',
  borderRadius: 'md',
  borderColor: 'beta.400',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  _focus: buttonFocus,
  _hover: {
    ...buttonHover,
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  },
  _active: buttonActive,
  _first: {
    borderStartRadius: 'md',
  },
  _last: {
    borderStartRadius: 'md',
  },
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
  color: 'gray.600',
};

export const BonusBetsButton = (isBonusBet: boolean) => ({
  bgGradient: 'linear(180deg, gamma.500, gamma.600)',
  color: isBonusBet ? 'gamma.900' : 'white',
  borderRadius: 'md',
  border: isBonusBet ? '1px' : 'none',
  borderColor: isBonusBet ? 'gamma.700' : '',
  boxShadow: isBonusBet ? '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset' : 'none',
  fontWeight: '500',
  fontSize: '10px',
  lineHeight: '133%',
  h: '6',
  px: '1.5',
  '&&': {
    minW: '20',
  },

  _hover: {
    bg: 'gamma.500',
    color: 'black',
    border: isBonusBet ? '1px solid' : 'none',
    borderColor: 'gamma.700',
    textShadow: 'none',
    boxShadow: isBonusBet
      ? '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset'
      : 'none',
  },

  _focus: {
    boxShadow: isBonusBet
      ? '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset'
      : 'none',
  },

  _active: {
    color: 'black',
    boxShadow: isBonusBet
      ? '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset'
      : 'none',
    border: isBonusBet ? '1px solid' : 'none',
  },

  _disabled: {
    border: 'none',
    bg: 'beta.800',
    color: 'alpha.300',
    opacity: '1',
    boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
    textTransform: 'uppercase',
    textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
  },
});

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgba(0,0,0,0.4)',
  borderRadius: 'base',
  position: 'absolute',
  top: 0,
  left: 0,
  w: '100%',
  h: 'calc(100% + 4px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'alpha.200',
  color: 'alpha.800',
  borderRadius: 'lg',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
  cover: 'layout',
  overflowY: 'visible',
  _focus: buttonFocus,
  _hover: buttonHover,
  _active: buttonActive,
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'epsilon.700' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
  my: '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5', '&:hover': { color: 'white' } }} />
);

export const EventRule: CSSObject = {
  ml: '8',
  color: 'epsilon.800',
};

export const betRecoveryAlertWrapperProps: BoxProps = {
  px: '0',
};
