import { CustomButtonProps } from '@/components/Button/Button';

export const buttonSubmitExclusionProps: CustomButtonProps = {
  height: '38px',
  width: '100%',
  variant: 'solid',
};

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  fontFamily: 'CarterOne',
  fontWeight: 'normal',
};
