import { chakra, Flex } from '@chakra-ui/react';

export const StakeRounded = chakra(Flex, {
  baseStyle: () => ({
    label: 'stakeRoundedBanner-StakeRounded',
    bg: 'yellow.100',
    color: 'yellow.800',
    fontWeight: 'medium',
    w: '98%',
    px: '2.5',
    mb: '1',
    mx: 'auto',
    minH: '12',
    py: '2',
    border: '2px solid',
    borderColor: 'yellow.400',
    borderRadius: 'md',
    alignItems: 'flex-start',
    fontSize: ['sm', null, null, null, 'xs'],
    pos: 'relative',
    top: '1',
  }),
});
