/* eslint-disable @typescript-eslint/no-use-before-define */
import { v4 as uuid } from 'uuid';

import api from '@/api/api';
import {
  BackgroundColor,
  IconTypes,
  InputValidationError,
  ManagerType,
  PaymentMethodInfo,
  PaymentMethodType,
  PrimerNativeButton,
  VaultedCard,
} from '../Deposit';
import injectScript, { injectCSS } from '@/lib/injectScript';
import { PRIMER_ASSETS } from '@/lib/Constants';

declare global {
  interface Window {
    Primer: PrimerType;
  }
}

// Primer types, these are partial copies from the npm version

type PrimerType = {
  createHeadless: (clientToken: string) => Promise<PrimerHeadlessCheckout>;
};

type PrimerHeadlessCheckout = {
  createPaymentMethodManager(
    type: 'PAYMENT_CARD',
    options?: PaymentMethodManagerOptions
  ): Promise<ICardPaymentMethodManager | null>;
  createPaymentMethodManager(
    type: PaymentMethodType.PAYPAL | 'PAYPAL' | 'GOOGLE_PAY' | 'APPLE_PAY',
    options?: PaymentMethodManagerOptions
  ): Promise<INativePaymentMethodManager | null>;
  createVaultManager(): HeadlessVaultManager;
  configure: (options: HeadlessUniversalCheckoutOptions) => void;
  getAssetsManager(): IAssetsManager;
  start: () => void;
};

type IAssetsManager = {
  getPaymentMethodAsset(
    type: PaymentMethodType
  ): Promise<ButtonPaymentMethodAsset | null>;
};

type ButtonPaymentMethodAsset = {
  backgroundColor: BackgroundColor;
  iconUrl: IconTypes;
  paymentMethodName?: string;
};

type HeadlessUniversalCheckoutOptions = {
  card?: any;
  onAvailablePaymentMethodsLoad: (paymentMethods: PaymentMethodInfo[]) => void;
  onBeforePaymentCreate?: (
    data: {
      paymentMethodType?: PaymentMethodType;
    },
    handler: any
  ) => void;
  onPaymentCreationStart?: () => void;
  onCheckoutComplete?: (data: { payment: any }) => void;
  onCheckoutFail?: (
    error: any,
    data: {
      payment?: any;
    },
    handler: any | undefined
  ) => void;
};

type HeadlessVaultManager = {
  fetchVaultedPaymentMethods(): Promise<any[]>;
  deleteVaultedPaymentMethod(id: string): Promise<void>;
  startPaymentFlow(
    id: string,
    data?: {
      cvv?: string;
    }
  ): Promise<void>;
};

type INativePaymentMethodManager = {
  createButton(): IHeadlessPaymentMethodButton;
};

type IHeadlessPaymentMethodButton = {
  render(containerId: string, options: any): Promise<void>;
  setDisabled(disabled: boolean): Promise<void>;
  clean(): void;
  focus(): void;
  blur(): void;
  addEventListener(event: EventTypes, callback: EventListener): void;
};

type PaymentMethodManagerOptions = {
  onCardMetadataChange?: (metadata: CardMetadata) => void;
};

type CardMetadata = {
  type: CardNetwork | null;
  possibleTypes: string[];
  cvvLength: number;
  cardNumberLength: number;
};

type CardNetwork =
  | 'american-express'
  | 'diners-club'
  | 'discover'
  | 'elo'
  | 'hiper'
  | 'hipercard'
  | 'interac'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'mir'
  | 'unionpay'
  | 'private-label'
  | 'visa';

type ICardPaymentMethodManager = {
  createHostedInputs(): {
    cardNumberInput: IHeadlessHostedInput;
    expiryInput: IHeadlessHostedInput;
    cvvInput: IHeadlessHostedInput;
  };
  setCardholderName(cardholderName: string): void;
  removeHostedInputs(): void;
  submit(): Promise<void>;
  validate(): Promise<Validation>;
  reset(): void;
};

type Validation = {
  valid: boolean;
  validationErrors: InputValidationError[];
  error?: string;
};

type IHeadlessHostedInput = {
  render(container: string, options: HeadlessHostedInputOptions): Promise<void>;
  addEventListener(event: EventTypes, callback: EventListener): void;
  focus(): void;
  blur(): void;
  setDisabled(status: boolean): void;
};

type EventListener = (event?: Event) => void;

enum EventTypes {
  CHANGE = 'change',
  ERROR = 'error',
  FOCUS = 'focus',
  BLUR = 'blur',
  CLICK = 'click',
  CLOSE = 'close',
}

type HeadlessHostedInputOptions = {
  placeholder?: string;
  ariaLabel?: string;
  style?: any;
};

// Primer types end

type SessionResponse = {
  client_token: string;
};

export type PrimerInputRefs =
  | 'cardNumber'
  | 'expiry'
  | 'cvv'
  | PaymentMethodType.APPLE_PAY
  | PaymentMethodType.GOOGLE_PAY
  | PaymentMethodType.PAYPAL;

export type PrimerFormErrorsSchema = {
  [id: string]: {
    active: boolean;
    dirty: boolean;
    error: string | null;
    errorCode: string | null;
    submitted: boolean;
    touched: boolean;
    valid: boolean;
  };
};

type InitiatePrimerClassProps = {
  amount: number;
  opted_in_promo?: string | null;
  onCheckoutComplete: () => void;
  onCheckoutFail: () => void;
};

type CreateCreditCardFormElementsProps = {
  inputIDs: {
    cardNumberID: string;
    expiryID: string;
    cvvID: string;
  };
  setOnChangeErrors: (err: PrimerFormErrorsSchema) => void;
  setCardType: (value: string | null) => void;
};

type CreateNativeFormElementsProps = {
  inputIDs: Map<PaymentMethodType, string>;
  nativeButtonOnClick: () => void;
  setNativePmtBtns: (
    value: Map<PaymentMethodType, PrimerNativeButton> | null
  ) => void;
};

type SubmitFormProps = {
  setOnSubmitErrors: (errs: InputValidationError[] | undefined) => void;
};

type SessionPatchSchema = {
  deposit_id: string;
  client_token: string;
  amount: number;
  opted_in_promo?: string | null;
  payment_type: string;
};

export class PrimerService {
  private primer: Promise<PrimerType> | null = null;

  private headless: PrimerHeadlessCheckout | null = null;

  private cardManager: ICardPaymentMethodManager | null = null;

  private vaultManager: HeadlessVaultManager | null = null;

  private vaultPayMethods: VaultedCard[] = [];

  private depositID: string | null = null;

  private clientToken: string | null = null;

  private amount: number | null = null;

  private timerId: NodeJS.Timeout | null = null;

  private availablePayMethods: Promise<PaymentMethodInfo[]>;

  private availablePayMethodsResolver: (
    value: PaymentMethodInfo[] | PromiseLike<PaymentMethodInfo[]>
  ) => void;

  constructor() {
    this.availablePayMethodsResolver = () => {};
    this.availablePayMethods = new Promise((resolve) => {
      this.availablePayMethodsResolver = resolve;
    });

    this.primer = window.Primer
      ? Promise.resolve(window.Primer)
      : Promise.all([
          injectCSS(PRIMER_ASSETS.css),
          injectScript(PRIMER_ASSETS.js),
        ]).then(() => window.Primer);
  }

  /**
   * Function that initiates Primer
   */
  async initiatePrimer({
    amount,
    opted_in_promo,
    onCheckoutComplete,
    onCheckoutFail,
  }: InitiatePrimerClassProps) {
    try {
      this.depositID = uuid();

      // Start session
      this.startTimer(onCheckoutFail);

      const { client_token: clientToken } = await api
        .put<SessionResponse>('/punter/deposit/session', {
          deposit_id: this.depositID,
          amount,
          opted_in_promo: opted_in_promo || null,
        })
        .then((res) => res.data);

      this.amount = amount;
      this.clientToken = clientToken;
      const primer = await this.primer;
      if (primer === null) {
        return;
      }
      this.headless = await primer.createHeadless(clientToken);

      this.headless.configure({
        card: {
          cardholderName: {
            required: true,
          },
        },
        onAvailablePaymentMethodsLoad: (
          paymentMethods: PaymentMethodInfo[]
        ) => {
          console.log('Available payment methods:', paymentMethods);
          this.availablePayMethodsResolver(
            paymentMethods.map((method: PaymentMethodInfo) => ({
              type: method.type,
              managerType: method.managerType,
            })) as PaymentMethodInfo[]
          );
        },
        onCheckoutComplete: () => {
          onCheckoutComplete();
        },
        onCheckoutFail: async (error, data) => {
          console.log('Checkout failed:', error, data);
          if (data.payment?.orderId) {
            await getDepositStatus(data.payment.orderId as string);
          }

          onCheckoutFail();
        },
      });

      // eslint-disable-next-line @typescript-eslint/await-thenable
      await this.headless.start();

      return { depositID: this.depositID };
    } catch (error) {
      console.log('initiatePrimer error', error);
      onCheckoutFail();
    }
  }

  /**
   * Function that returns saved payment methods
   *
   */
  async getSavedPayMethods() {
    if (!this.headless) throw new Error('Headless not initialized');

    try {
      this.vaultManager = this.headless.createVaultManager();
      const vaultPayMethods =
        await this.vaultManager.fetchVaultedPaymentMethods();
      // only support vaulted cards for now
      this.vaultPayMethods = vaultPayMethods
        .filter((method) => method.paymentInstrumentType === 'PAYMENT_CARD')
        .map((card) => ({
          cardId: card.id,
          last4: card.paymentInstrumentData.last4Digits,
          expiryYear: card.paymentInstrumentData.expirationYear,
          expiryMonth: card.paymentInstrumentData.expirationMonth,
          cardType: card.paymentInstrumentData.network,
          cardToken: card.analyticsId,
        }));
    } catch (error) {
      console.log('getSavedPayMethods error', error);
    }

    return {
      vaultedPaymentMethods: this.vaultPayMethods,
    };
  }

  /**
   * Function that returns available payment methods
   */
  async getAvailablePayMethods() {
    if (!this.headless) throw new Error('Headless not initialized');

    return {
      availablePayMethods: await this.availablePayMethods,
    };
  }

  /**
   * Function that creates the form
   */
  async createCreditCardFormElements({
    inputIDs,
    setOnChangeErrors,
    setCardType,
  }: CreateCreditCardFormElementsProps) {
    if (!this.headless) throw new Error('Headless not initialized');

    const paymentMethods = await this.availablePayMethods;

    if (
      paymentMethods.filter(
        (paymentMethod) => paymentMethod.managerType === ManagerType.CARD
      ).length > 0
    ) {
      this.cardManager = await this.headless.createPaymentMethodManager(
        'PAYMENT_CARD',
        {
          onCardMetadataChange(change) {
            const { type } = change as { type: string | null };
            setCardType(type ?? '');
          },
        }
      );

      const { cardNumberInput, expiryInput, cvvInput } =
        this.cardManager?.createHostedInputs() ?? {};
      const { cardNumberID, expiryID, cvvID } = inputIDs;

      await Promise.all([
        cardNumberInput?.render(cardNumberID, {
          ariaLabel: 'Card number',
          style: inputStyle,
        }),
        expiryInput?.render(expiryID, {
          placeholder: 'MM/YY',
          ariaLabel: 'Expiry date',
          style: inputStyle,
        }),
        cvvInput?.render(cvvID, {
          placeholder: 'XXX',
          ariaLabel: 'CVV',
          style: inputStyle,
        }),
      ]);

      cardNumberInput?.addEventListener(EventTypes.CHANGE, (...args: any) => {
        setOnChangeErrors({ 'cardNumber-card': args[0] });
      });
      expiryInput?.addEventListener(EventTypes.CHANGE, (...args: any) => {
        setOnChangeErrors({ 'expiryDate-card': args[0] });
      });
      cvvInput?.addEventListener(EventTypes.CHANGE, (...args: any) => {
        setOnChangeErrors({ 'cvv-card': args[0] });
      });
    }
  }

  async createNativeFormElements({
    inputIDs,
    nativeButtonOnClick,
    setNativePmtBtns,
  }: CreateNativeFormElementsProps) {
    if (!this.headless) throw new Error('Headless not initialized');

    const paymentMethods = await this.availablePayMethods;
    const nativeButtons = new Map<PaymentMethodType, PrimerNativeButton>();
    const nativePromises: Promise<void>[] = [];

    paymentMethods
      .filter(
        (paymentMethod) => paymentMethod.managerType === ManagerType.NATIVE
      )
      .forEach((paymentMethod) => {
        // `type` is a unique ID representing the payment method
        const { type } = paymentMethod;
        // Relevant for PayPal, Apple Pay and Google Pay
        const assetsManager = this.headless?.getAssetsManager();

        const payMethodManagerPromise =
          this.headless?.createPaymentMethodManager(type) as Promise<
            any | null
          >;
        const payMethodAssetsPromise =
          assetsManager?.getPaymentMethodAsset(type);

        nativePromises.push(
          Promise.all([payMethodManagerPromise, payMethodAssetsPromise]).then(
            ([manager, assets]) => {
              const { iconUrl, paymentMethodName, backgroundColor } =
                assets ?? {};
              const button = manager?.createButton();
              button.addEventListener('click', nativeButtonOnClick);
              let renderPromise;
              switch (type) {
                default: {
                  renderPromise = button?.render(
                    inputIDs.get(paymentMethod.type),
                    NativePayInputStyle
                  );
                  break;
                }
              }
              nativeButtons.set(type, {
                iconUrl,
                paymentMethodName,
                backgroundColor,
                type,
                button,
                renderPromise,
              });
            }
          )
        );
      });
    Promise.all(nativePromises)
      .then(() => setNativePmtBtns(nativeButtons))
      .catch((error) => console.log('createNativeFormElements failed:', error));
  }

  /**
   * Function that handles the input name change event
   */
  handleNameChange(value: string) {
    if (!this.headless) throw new Error('Headless not initialized');
    this.cardManager?.setCardholderName(value);
  }

  /**
   * Function that submits the form
   */
  async submitForm({ setOnSubmitErrors }: SubmitFormProps) {
    if (!this.headless) throw new Error('Headless not initialized');

    const { valid, validationErrors } =
      (await this.cardManager?.validate()) ?? {};

    if (valid) return this.cardManager?.submit();

    const bcValidationErrors: InputValidationError[] =
      validationErrors?.map((err: any) => ({
        name: err.name,
        error: err.error,
        message: err.message,
      })) ?? [];

    setOnSubmitErrors(bcValidationErrors);
    throw new Error('form is invalid');
  }

  /**
   * Function that takes payment via saved card
   */
  async startPaymentFlow({ selectedCardID }: { selectedCardID: string }) {
    if (!this.headless) throw new Error('Headless not initialized');

    const indexToSubmit = this.vaultPayMethods.findIndex(
      (card) => card.cardId === selectedCardID
    );

    if (!this.vaultManager) return;

    await this.vaultManager.startPaymentFlow(
      this.vaultPayMethods[indexToSubmit]?.cardId
    );
  }

  /**
   * Function that deletes a card
   */
  deleteSavedCard(id: string) {
    if (!this.headless) throw new Error('Headless not initialized');

    const indexToRemove = this.vaultPayMethods.findIndex(
      (card) => card.cardId === id
    );

    if (!this.vaultManager) return;

    return this.vaultManager.deleteVaultedPaymentMethod(
      this.vaultPayMethods[indexToRemove]?.cardId
    );
  }

  /**
   * Patch the session
   */
  async patchSession(
    data: Pick<SessionPatchSchema, 'payment_type' | 'opted_in_promo'>
  ) {
    if (!this.depositID || !this.clientToken || !this.amount) {
      throw new Error('No ID');
    }

    await api.patch('/punter/deposit/session', {
      deposit_id: this.depositID,
      client_token: this.clientToken,
      amount: this.amount,
      ...data,
    });
  }

  /**
   * Implementing a timer to clear the users session after 15 mins
   */
  private startTimer(onTimeout: () => void) {
    this.clearTimer();
    this.timerId = setTimeout(() => {
      onTimeout();
    }, 15 * 60 * 1000); // 15 minutes
  }

  clearTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }
}

/**
 * API calls
 */
type DepositStatusResponse = 'Pending' | 'Declined' | 'Approved';

async function getDepositStatus(
  orderId: string,
  attempt = 1,
  maxAttempts = 20
): Promise<{ status: DepositStatusResponse }> {
  const { data } = await api.get<{ status: DepositStatusResponse }>(
    `punter/deposit/status`,
    {
      params: { deposit_id: orderId },
    }
  );

  if (data.status !== 'Pending' || attempt >= maxAttempts) {
    return data;
  }

  await new Promise((resolve) => setTimeout(resolve, 1000));
  return getDepositStatus(orderId, attempt + 1, maxAttempts);
}

/**
 * STYLES
 */
const inputStyle = {
  input: {
    base: {
      height: 'auto',
      border: '1px solid rgb(0 0 0 / 10%)',
      borderRadius: '4px',
      padding: '12px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      background: '#FFF',
      fontWeight: 700,
      color: '#2D3748',
      fontSize: '12px',
    } as any,
  },
};
const NativePayInputStyle = {
  style: {
    buttonType: 'plain',
    buttonStyle: 'black',
  },
};
