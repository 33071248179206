import {
  footerBaseStyle,
  TFooterTheme,
} from '../../base/customComponents/footer';
import { TStyleConfig } from '../../base/types';

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: {
    ...footerBaseStyle,
    footer: {
      ...footerBaseStyle.footer,
      pb: '0',
      bg: 'transparent',
      bgSize: 'cover',
      bgPos: 'bottom',
      py: '0',
      color: 'white',
    },
    stackGap: '1',
    footerBar: {
      display: 'flex',
      flexDir: ['column', null, 'row'],
      pr: ['0', null, '3'],
      mb: '3',
      borderRadius: 'md',
      alignItems: 'center',
      justifyContent: ['center', null, 'unset'],
      '& > img': {
        w: ['148px', null, null, '180px'],
        ml: '0',
        mb: ['2', null, '-1'],
      },
    },
    footerLink: {
      color: 'white',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    footerLinkTel: {
      color: 'white',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    customFooterLink: {
      color: 'white',
      textTransform: 'capitalize',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 'base',
      _hover: {
        textDecoration: 'underline',
      },
    },
    footerMobile: {
      borderRadius: 'lg',
      w: '128px',
      h: '38px',
      cursor: 'pointer',
      boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.3)',
      _odd: {
        mr: '2',
      },
    },
    footerLinks: {
      ml: ['unset', null, 'auto'],
      pb: ['2', null, '0'],
      alignItems: 'center',
      justifyContent: ['space-around', null, 'unset'],
      color: 'beta.200',
      fontWeight: 'medium',
      flexDir: ['row', null, 'unset'],
      display: 'inline-flex',
    },
    footerContentContainer: {
      px: ['2.5', 'unset'],
      gap: ['2', null, null, '6'],
      ...footerBaseStyle.footerContentContainer,
      bg: [null, null, 'transparent'],
      justifyContent: 'space-between',
      '&': {
        flexDirection: ['column', null, 'column'],
      },
    },
    nav: {
      ...footerBaseStyle.nav,
      fontSize: 'sm',
      fontWeight: 'medium',
      minW: [null, null, '370px'],
      mt: 'unset',
      display: ['flex', 'grid'],
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto',
      columnGap: '28',
      rowGap: '1',
      alignItems: ['center', 'end'],
      gridTemplateAreas: `
        ". ."
      `,

      '> a': {
        color: 'white',
      },
    },
    blurb: {
      ...footerBaseStyle.blurb,
      flex: 'unset',
      textAlign: 'left',
      color: 'white',
      width: 'unset',
      mb: '0',
      ml: [null, '0'],
      mr: [null, null, '4'],
    },
    blurbHeading: {
      ml: 'unset',
      mt: 'unset',
      color: 'white',
    },
    blurbHeadingAge: {
      ...footerBaseStyle.blurbHeadingAge,
      borderColor: 'white',
      color: 'white',
      border: '4px',
      mb: '2',
      boxSize: '8',
      fontSize: 'xs',
    },
    blurbHeadingThink: {
      fontSize: 'xs',
      color: 'white',
      fontWeight: 'bold',
      mb: '4',
    },
    blurbContent: {
      ...footerBaseStyle.blurbContent,
      ml: 'unset',
      mr: [null, null, 'auto'],
    },
    copyright: {
      ...footerBaseStyle.copyright,
      color: 'white',
      textAlign: ['center', 'left'],
      mt: ['7'],
      px: ['8', 'unset'],
      py: '0',
      fontWeight: ['semibold', 'medium'],
    },
    copyrightContainer: {
      color: 'white',
    },
    container: {
      bg: 'transparent',
      borderRadius: ['0', '0', '8'],
      p: '4',
      mt: '3',
    },
    footerDownloadImage: {
      borderRadius: 'md',
      border: '2px solid white',
      boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.30)',
    },
    emailUs: {
      fontSize: 'md',
      fontWeight: 'bold',
      color: 'white',
    },
    quickLinks: {
      color: ['beta.200', 'white'],
      textTransform: ['uppercase', 'none'],
      textAlign: ['center', 'left'],
      fontSize: ['xs', 'md'],
      fontWeight: 'medium',
    },
    ourPartners: {
      fontSize: ['xs', 'md'],
      color: ['beta.200', 'white'],
      textTransform: ['uppercase', 'none'],
      fontWeight: 'medium',
    },
    weAccept: {
      fontSize: ['xs', 'md'],
      color: ['beta.200', 'white'],
      textTransform: ['uppercase', 'none'],
      fontWeight: 'medium',
    },
    partnerWrapper: {
      mr: 'unset',
      boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.30)',
    },
    weAcceptBox: {
      mb: '2',
    },
  },
  sizes: {},
  variants: {},
};
