import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: `${selected ? 'alpha.900' : 'blackAlpha.400'}`,
  border: 'none',
  mt: '1',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  bg: 'blackAlpha.400',
  borderLeftRadius: `${index === 0 ? '12' : '0'}`,
  borderRightRadius: `${index === numberOfSlides - 1 ? '12' : '0'}`,
});

export const CarouselWrapper: CSSObject = {
  my: '1',
  mt: ['55px', null, '0px'],
  '& .control-dots ': {
    bottom: '0',
    marginY: 'px',
  },
  '& .carousel .slider-wrapper ': {
    w: ['98%', null, '880px'],
  },
};
