import React from 'react';
import { Icon, IconButton, useBoolean } from '@chakra-ui/react';
import { EyeOff, Eye } from '@styled-icons/remix-line';
import Field, { TIndex } from '../Input';

type TPassword = TIndex;

export default function Password({ ...rest }: TPassword) {
  const [isPassword, setIsPassword] = useBoolean(true);

  return (
    <Field
      type={isPassword ? 'password' : 'text'}
      InputRightElement={() => (
        <IconButton
          aria-label="password toggle"
          variant="unstyled"
          onClick={setIsPassword.toggle}
        >
          <Icon
            as={isPassword ? Eye : EyeOff}
            boxSize="5"
            sx={{
              color: 'black',
            }}
          />
        </IconButton>
      )}
      {...rest}
    />
  );
}
