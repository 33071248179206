export const styles = {
  global: {
    html: {},
    body: {
      minH: '100vh',
      bg: 'beta.900',
      fontFamily: 'Roboto',

      /**
       * This is a crap way to hide the live chat button.
       * Theres a way to hide it within beacon itself that we
       * really should do.
       */
      '.hsds-beacon:not(.is-mobile)': {
        '.BeaconFabButtonFrame': { boxShadow: 'none' },
        '[title="Help Scout Beacon - Open"]': {
          display: 'none',
        },
      },
    },
  },
};
