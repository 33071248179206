import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexWrapper: CSSObject = {
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  mb: '2',
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  bgSize: 'cover',
  borderBottom: '2px solid',
  borderColor: 'beta.400',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};

export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  border: '2px solid',
  borderColor: 'beta.400',
  textOverflow: 'ellipsis',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 1px #13274B inset',
  _hover: {
    bg: 'alpha.600',
  },
  '.chakra-badge.state-inprogress': {
    bg: 'gamma.400',
    color: 'alpha.700',
    mr: '1',
    mt: '1',
    height: '3',
    borderRadius: 'base',
    maxW: '10',
    lineHeight: 'normal',
  },

  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    mr: '1',
    mt: '1',
    height: '3',
    borderRadius: 'base',
    maxW: '10',
    lineHeight: 'normal',
  },

  '.chakra-badge.state-ended': {
    bg: 'red.500',
    color: 'white',
    mr: '1',
    mt: '1',
    height: '3',
    borderRadius: 'base',
    maxW: '10',
    lineHeight: 'normal',
  },
};

export const RaceNumber: CSSObject = {
  color: 'white',
  lineHeight: 'normal',
};
export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderRadius: 'unset',
  lineHeight: 'normal',
};

export const Icon: CSSObject = {
  color: 'beta.400',
  filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.55))',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  boxSize: 'full',
  minW: '7',
  color: 'white',
  p: '0',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  border: 'none',
  position: 'relative',
  boxShadow:
    '0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.35)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    bgGradient: 'linear(to-b, gamma.500, gamma.700)',
    boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 1) inset',
    zIndex: 8,
    borderRadius: 'base',
    m: '2px',
  },
  _hover: {
    _after: {
      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    },
    '& > svg': {
      boxSize: '6',
      zIndex: 10,
      color: 'beta.600',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.3)',
    },
  },

  '& > svg': {
    boxSize: '6',
    zIndex: 10,
    color: 'beta.400',
    textShadow: '0px 2px 1px rgba(0, 0, 0, 0.15)',
  },
};
