import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  accordionButton: {
    color: 'white',
    sx: {
      display: 'flex',
      border: '1px',
      borderColor: 'gray.400',
      py: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: 'md',
      bg: 'blackAlpha.50',
      px: '2',
      pl: '4',
      _hover: {
        bg: 'blackAlpha.50',
      },
      _expanded: {
        borderBottomRadius: 'none',
        borderBottom: 'none',
      },
    },
  },
  topLeaguesText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
    color: 'gray.700',
    fontSize: 'sm',
    px: 0,
  },
  accordionIcon: { color: 'gray.700' },
  accordionPanel: {
    bg: 'blackAlpha.50',
    pb: 2,
    pt: 0,
    borderBottomRadius: 'md',
    px: 4,
    border: '1px',
    borderColor: 'gray.400',
    borderTop: 'none',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'beta.200',
    px: '2',
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      color: 'beta.600',
      fontsize: 'sm',
      my: '3',
      fontWeight: 'bold',
    },
  },
  marketGroupsByLetterAccordionItem: {
    bg: 'blackAlpha.50',
    borderWidth: '1px',
    borderColor: 'gray.400',
    mt: '1px',
    boxShadow:
      '0px 0px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    sx: {
      _last: {
        borderBottomRadius: 'md',
        boxShadow:
          '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
      },
      _first: {
        borderTopRadius: 'md',
        mt: '0px',
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderColor: 'gray.400',
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionIcon: {
    color: 'gray.700',
  },
  marketGroupsByLetterAccordionPanel: {
    pb: 2,
    pt: 0,
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'beta.200',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: { _hover: { textDecoration: 'none' } },
  },
  navWrapper: {
    pb: '3',
  },
};
