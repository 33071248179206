import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  borderRadius: 'lg',
  border: '4px solid',
  borderColor: 'beta.600',
  contain: 'layout',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '11px',
  mx: '3px',
  mb: isKeypadVisible && 'unset',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.06)',
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'alpha.600',
  color: 'gamma.600',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'white',
  fontFamily: 'Bungee',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'extrabold',
  color: 'gamma.500',
  fontFamily: 'Bungee',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  h: '12',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'secondary',
  h: '6',
  margin: 'auto',
  mt: '-23px',
  color: 'gamma.500',
  minW: '14',
  border: '2px solid',
  boxShadow: 'none',
  textTransform: 'uppercase',
  px: '2',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&&:hover, &&:active': {
      bg: '#542614',
      boxShadow: 'none',
    },
    '&&': {
      fontSize: '2xs',
      bg: '#7F3E25',
      borderColor: '#E48D09',
    },
  },
};

export const BetslipVStack: CSSObject = {
  gap: '2',
};
