import { Link, LinkProps } from 'react-router-dom';
import { Box, chakra, ChakraComponent, Flex, Text } from '@chakra-ui/react';
import { styleImports } from './NextToJumpItem.styles.imports';
import IconSvg from '../../../../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

export type TLinkContainer = {
  isLast: boolean;
} & LinkProps;

const themeName = getThemeName();

/**
 * Link for the race
 */

export const LinkContainer = chakra(Link, {
  label: 'nextToJump-LinkContainer',
  shouldForwardProp: (prop) => !['isLast'].includes(prop),
  baseStyle: ({ theme, isLast }: any) => ({
    alignItems: 'center',
    py: '2',
    px: '1.5',
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    transition: 'all 0.1s ease-in-out',
    ...(theme.themeName === EThemes.Betgalaxy && {
      border: '1px',
      borderRadius: ['lg', 'unset'],
      borderColor: 'gray.200',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.LinkContainer?.({ isLast });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', TLinkContainer>;

/**
 * Icon for the race
 */
export const Icon = chakra(IconSvg, {
  label: 'nextToJump-Icon',
  baseStyle: ({ theme }: any) => ({
    boxSize: '6',
    mr: '1.5',
    color: 'white',
    bg: 'gray.900',
    borderRadius: 'full',
    p: '1',

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'beta.500',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.Icon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Race name text
 */
export const TextRace = chakra(Text, {
  label: 'nextToJump-TextRace',
  baseStyle: ({ theme }: any) => ({
    fontSize: 'xs',
    textTransform: 'capitalize',
    fontWeight: 'semibold',
    color: 'gray.800',

    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'sm',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TextRace;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Wrapper for the countdown/race number
 */
export const FlexWrapper = chakra(Flex, {
  label: 'nextToJump-FlexWrapper',
  baseStyle: () => ({
    gap: '0.5',
    alignItems: 'center',
    ml: 'auto',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Race text number
 */
export const TextNumber = chakra(Text, {
  label: 'nextToJump-TextNumber',
  baseStyle: () => ({
    fontSize: '2xs',
    borderRadius: 'base',
    py: '1',
    bg: 'gray.900',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '6',
    h: '5',
    display: 'inline-block',
    ...(() => {
      try {
        return styleImports[themeName]?.TextNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Race start time text
 */
export const TextStartTime = chakra(Text, {
  label: 'nextToJump-TextStartTime',
  baseStyle: () => ({
    fontSize: '2xs',
    fontWeight: 'semibold',
    color: 'black',
  }),
});

/**
 * Section heading
 */
export const BoxHeading = chakra(Flex, {
  label: 'nextToJump-BoxHeading',
  baseStyle: ({ theme }: any) => ({
    py: '1.5',
    px: '2',
    fontWeight: 'semibold',
    fontSize: 'sm',
    alignItems: 'center',
    mb: ['1', 'unset'],
    mx: ['-2', 'unset'],
    position: 'sticky',
    display: ['none', 'flex'],

    ...(theme.themeName === EThemes.Betgalaxy && {
      display: 'none',
      border: 'none',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Icon for the section heading
 */
export const IconHeading = chakra(IconSvg, {
  label: 'nextToJump-IconHeading',
  baseStyle: ({ theme }: any) => ({
    mr: '1.5',
    ...(theme.themeName === EThemes.Betgalaxy && {
      boxSize: '7',
      color: 'white',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.IconHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxNextToJumpContainer = chakra(Box, {
  label: 'nextToJump-BoxNextToJumpContainer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxNextToJumpContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
