import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, ButtonProps } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { GridWrapper, SubHeading } from '../styles/ResponsibleGambling.styles';
import {
  TakeActionWrapper,
  BoxTakeActionTable,
  TextTakeAction,
  TakeActionHeading,
} from '../styles/TakeAction.styles';
import { getThemeName } from '@/helpers/getThemeName';
import SwitchButtonAction from '../templates/template';
import { createTemplate } from '@/lib/templateSwitch';
import { EThemesOG, THEME_CONFIG } from '@/constants/themeConfig';
import { getThemeConfig } from '@/helpers/getThemeConfig';

const brand = getThemeConfig().name?.toLocaleLowerCase() as EThemesOG;
const phonenumber =
  THEME_CONFIG[brand].closeAccNumber || '[enter phone number]';

const ButtonActionSwitched = (props: ButtonProps) => (
  <>{createTemplate(SwitchButtonAction(props))}</>
);

export default function TakeAction() {
  const navigate = useNavigate();

  return (
    <TakeActionWrapper>
      <SubHeading>
        <FormattedMessage id="legal.responsible.titles.takeaction" />
      </SubHeading>

      <GridWrapper>
        {[
          {
            key: 'deposit-limit',
            heading: 'legal.responsible.section.takeaction.depositlimit.one',
            body: 'legal.responsible.section.takeaction.depositlimit.two',
            button: 'legal.responsible.section.takeaction.depositlimit.button',
            onClick: () => navigate('/account/deposit-limit'),
          },
          {
            key: 'deactivate',
            heading: 'legal.responsible.section.takeaction.deactivate.one',
            body: 'legal.responsible.section.takeaction.deactivate.two',
            button: 'legal.responsible.section.takeaction.deactivate.button',
            onClick: () =>
              navigate('/account/settings/responsible-gambling/close-account'),
          },
          {
            key: 'lifetime-exclusion',
            heading: 'legal.responsible.section.takeaction.lifeexclusion.one',
            body: 'legal.responsible.section.takeaction.lifeexclusion.two',
            button: 'legal.responsible.section.takeaction.lifeexclusion.button',
            onClick: () =>
              navigate(
                '/account/settings/responsible-gambling/lifetime-exclusion'
              ),
          },
          {
            key: 'break',
            heading: 'legal.responsible.section.takeaction.break.one',
            body: 'legal.responsible.section.takeaction.break.two',
            button: 'legal.responsible.section.takeaction.break.button',
            onClick: () =>
              navigate('/account/settings/responsible-gambling/take-a-break'),
          },
        ].map((section) => (
          <BoxTakeActionTable key={section.key}>
            <TakeActionHeading>
              <FormattedMessage id={section.heading} />
            </TakeActionHeading>

            <TextTakeAction>
              <FormattedMessage
                id={section.body}
                values={{
                  getThemeName: getThemeName(),
                  phonenumber,
                }}
              />
            </TextTakeAction>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 'auto',
              }}
            >
              <ButtonActionSwitched
                data-cy={section.key}
                onClick={section.onClick}
                width="full"
              >
                <FormattedMessage id={section.button} />
              </ButtonActionSwitched>
            </Box>
          </BoxTakeActionTable>
        ))}
      </GridWrapper>
    </TakeActionWrapper>
  );
}
