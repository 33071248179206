import { Flex, chakra, Text } from '@chakra-ui/react';
import { CSSObject } from 'styled-components';
import { Button } from '@/components/Button/Button';
import IconSvg from '../../../../../../common/IconSvg';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Nav.styles.imports';

type TOverrideName =
  | 'ButtonNavItem'
  | 'FlexNav'
  | 'AccountOverviewBtnText'
  | 'AccountOverviewBtnIcon';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};

// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const FlexNav = chakra(Flex, {
  baseStyle: () => ({
    color: 'white',
    bg: 'rgba(0, 0, 0, 0.3)',
    boxShadow:
      '0px 1px 0px rgba(255, 255, 255, 0.08), inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: 'md',

    boxSizing: 'border-box',

    flexDirection: 'row',
    justifyContent: 'flex-start',
    p: '2.5',
    gap: '2.5',

    w: '100%',
    h: '24',

    ...styles?.FlexNav,
  }),
});

export const ButtonNavItem = chakra(Button, {
  baseStyle: () => ({
    flexDir: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    w: '100%',
    h: '100%',
    color: 'gray.100',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
    bg: 'linear-gradient(180deg, #636EC7 0%, #414984 100%)',
    boxShadow:
      '0px 2px 3px rgba(0, 0, 0, 0.35), inset 0px 0px 3px rgba(255, 255, 255, 0.6), inset 0px 1px 0px rgba(255, 255, 255, 0.3)',
    borderRadius: 'md',

    ...styles?.ButtonNavItem,
  }),
});

export const TextAccountOverviewBtn = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    textTransform: 'uppercase',
    fontWeight: 'bold',

    ...styles?.AccountOverviewBtnText,
  }),
});

export const IconAccountOverviewBtn = chakra(IconSvg, {
  baseStyle: () => ({
    boxSize: '10',

    ...styles?.AccountOverviewBtnIcon,
  }),
});
