/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useIntl } from 'react-intl';
import SeoTitle from '@/components/Seo/SeoTitle';
import ThemeHome from './templates/template';
import { createTemplate } from '@/lib/templateSwitch';

export default function Home() {
  const intl = useIntl();
  const home = createTemplate(ThemeHome);

  return (
    <>
      <SeoTitle title={intl.formatMessage({ id: 'home.pagetitle' })} />

      {home}
    </>
  );
}
