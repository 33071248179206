import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTakeBreakProps: CustomButtonProps = {
  height: '38px',
  variant: 'solid',
  lineHeight: 'normal',
};

export const ListItemExclusionItem: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const HeadingInformation: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  mb: '0',
  fontSize: 'sm',
};

export const TextInformation: CSSObject = {
  fontSize: 'xs',
  mb: '3',
  color: 'white',
  '&:first-of-type': {
    fontWeight: 'medium',
  },
};

export const BoxStyledLink: CSSObject = {
  color: 'white',
  textDecoration: 'underline',
};

export const TextM: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
  mb: '0',
};

export const TextS: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontSize: 'xs',
  '&:first-of-type': {
    mb: '2',
  },
};

export const BoxTakeABreak: CSSObject = {
  pb: '0',
  border: 'none',
};

export const TextDate: CSSObject = {
  fontSize: 'md',
  color: 'white',
};

export const TextDateLabel: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextConfirmBreak: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const buttonConfirmBreakProps: CustomButtonProps = {
  height: '38px',
  variant: 'solid',
};

export const BoxTakeABreakWrapper: CSSObject = {
  borderBottom: 'none',
  pb: '0',
};
