export const Checkbox = {
  parts: ['container', 'icon', 'control', 'label'],
  baseStyle: {
    control: {
      _disabled: {
        opacity: '0.4',
      },
      _invalid: {
        bg: 'red.100',
      },
      _hover: {
        bg: 'gray.400',
      },
      _checked: {
        boxShadow: 'none',
        _disabled: {
          borderColor: 'blackAlpha.200',
          bg: 'transparent',
        },
        _hover: {
          bg: 'gray.400',
        },
        _focus: {
          boxShadow: 'none',
        },
        _invalid: {
          bg: 'alpha.400',
        },
        bg: 'gray.100',
        color: 'gray.700',
        border: '0',
      },
    },
    container: {
      w: 'full',
      '&&': {
        alignItems: 'flex-start',
      },
    },
  },
  sizes: {
    lg: {
      label: { fontSize: 'sm' },
      icon: { fontSize: 'xs' },
    },
    md: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    sm: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    xs: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '3xs' },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
