import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'beta.500',
    pl: '2',
  },
  eventMarketName: {
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'beta.500',
    pl: '2',
  },
  eventSubtitle: {
    mb: 0,
  },
  infoExoticsContainer: {
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'beta.500',
    pl: '8',

    sx: {
      '[data-testid="srmInfo-eventMarketName"]': {
        pl: 0,
      },
    },
  },
  placeNumber: {
    fontWeight: 'medium',
    fontSize: 'sm',
    textTransform: 'capitalize',
    color: 'beta.500',
    sx: {
      '> span': { fontSize: 'xs' },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    py: '2',
    px: 3,
    borderBottom: '1px solid rgba(0, 0, 0, 0.30)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
    },
  },
};
