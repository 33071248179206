import useAxios from 'axios-hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TFormikValues, TTakeABreakData } from '../types';
import { signUserOut } from '../../../../lib/firebase/Authentication';
import {
  clearPunterOverview,
  clearPunterProfile,
} from '../../services/account.slices';
import { AppDispatch } from '../../../../redux/store';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';
import { postMobileAppMessage } from '../../../../mobileapp/mobileapp.utils';
import { ENewRelicActions } from '../../../../constants/newRelicActions';
import { newRelicLog } from '@/lib/newRelic';
import { useAuth } from '@/hooks/useAuth';

export const useTakeABreak = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { punterData } = useAuth();

  const [{ loading }, selfExclude] = useAxios<null, TTakeABreakData>(
    {
      method: 'POST',
      url: '/punter/account/self-exclusion',
    },
    { manual: true, useCache: false }
  );

  const doTakeABreak = async ({ period }: TFormikValues) => {
    await selfExclude({
      data: { period },
    });

    await signUserOut().then(() => {
      newRelicLog(ENewRelicActions.LogOut, punterData?.punter_id, {
        status_code: 200,
      });
      dispatch(clearPunterProfile());
      dispatch(clearPunterOverview());
    });

    if (IS_MOBILE_APP) postMobileAppMessage('signout');

    navigate('/signed-out');
  };

  return [doTakeABreak, { loading }] as const;
};
