import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '40px',
      border: '1px',
      borderColor: 'gray.300',
      background: 'gray.50',
      color: 'gray.600',
      borderRadius: 'lg',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      px: '2',
      'input, span': {
        fontFamily: 'roboto',
        fontWeight: 'normal',
      },
      boxShadow: 'none',

      _hover: {
        bg: 'gray.50',
        borderColor: 'gray.300',
      },
      _active: {
        bg: 'gray.200',
        borderColor: 'gray.300',
      },
      _focus: {
        bg: 'gray.200',
        borderColor: 'gray.300',
      },
    },
  },
};
