import { css } from 'styled-components';

// TODO: remove
export const tableWrapper = css`
  width: 100%;
`;

// TODO: remove
export const tableHeadingsWrapper = css`
  margin-bottom: var(--bc-sizes-2);
  width: 100%;
  margin: 0 auto var(--bc-sizes-2) auto;
  max-width: 1200px;
  min-width: 1200px;

  padding: 0 var(--bc-sizes-5);
`;

// TODO: remove
export const tableFlexItem = css`
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-md);
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
`;

// TODO: remove
export const tableHeadingText = css`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary};
  font-size: var(--bc-fontSizes-2xs);
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
`;

// TODO: Remove
export const pageHeadingWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  max-width: 1200px;
  padding: var(--bc-sizes-1);
`;

export const pageHeading = css`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary};
  font-size: var(--bc-fontSizes-xl);
  font-family: ${({ theme }) => theme.fontFamily.heading};
  margin-right: var(--bc-sizes-2);

  ${({ theme }) => theme.device.tablet} {
    margin-right: var(--bc-sizes-5);
  }
`;

export const downloadActionLink = css`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary};
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-left: var(--bc-sizes-10);
`;

export const tableText = css``;
