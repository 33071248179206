import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'blackAlpha.500',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
  },
  depositIconProps: {
    color: 'alpha.300',
    boxSize: '9',
  },
};
