import React from 'react';
import { Image, Link } from '@chakra-ui/react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/buffalobet/images/backgrounds/onboardingLogo.svg';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: (
      <Link
        aria-label="aside logo Mobile"
        as={LinkDom}
        to="/"
        maxW={['58vw', '55vw']}
        alignSelf="center"
        mb={['0', null, '12']}
        mt={['8', '12vw', '8']}
      >
        <Image src={logo} />
      </Link>
    ),
    config: {
      before: 'slogan',
    },
  },
]);
