import { CSSObject } from '@chakra-ui/react';
import clashingSoon from '../../../../../../assets/volcanobet/images/homeFeaturedSport/clashingSoon.png';
import clashingSoon2x from '../../../../../../assets/volcanobet/images/homeFeaturedSport/clashingSoon2x.png';
import cavemenClashing from '../../../../../../assets/volcanobet/images/homeFeaturedSport/cavemenClashing.png';
import cavemenClashing2x from '../../../../../../assets/volcanobet/images/homeFeaturedSport/cavemenClashing2x.png';
import cavemenClashingMobile from '../../../../../../assets/volcanobet/images/homeFeaturedSport/cavemenClashingMobile.png';
import cavemenClashingMobile2x from '../../../../../../assets/volcanobet/images/homeFeaturedSport/cavemenClashingMobile2x.png';

export const FlexWrapper: CSSObject = {
  alignItems: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  border: 'none',
  flexDir: 'column',
  pl: '0',
  py: '0',
};

export const FlexIllustrationWrapper: CSSObject = {
  bgImage: [`url(${cavemenClashingMobile})`, `url(${cavemenClashing})`],
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
  bgPos: ['0px 90px', 'bottom'],
  boxSize: '100%',
  bottom: ['-36', '0'],
  pos: 'absolute',
  display: 'block',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: [`url(${cavemenClashingMobile2x})`, `url(${cavemenClashing2x})`],
  },
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: '2',
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  mx: '1',
  boxShadow:
    '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 1px rgba(255, 255, 255, 0.5)',
  borderRadius: 'base',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.400',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  color: 'alpha.500',
  boxShadow:
    '0px 1px 0px rgba(255, 255, 255, 0.4), inset 0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  minW: '0',
  fontSize: 'xs',
  fontWeight: 'black',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  fontWeight: 'black',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const puntButtonProps: CSSObject = {
  bgGradient: 'linear(to-b, beta.600, beta.600)',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  color: 'blackAlpha.700',
  border: '2px',
  borderColor: 'beta.400',
  borderRadius: ['base', 'md'],
  fontFamily: 'Caveman',
  fontWeight: 'normal',
  fontSize: ['sm', 'xs'],
  py: ['3.5', '2'],

  _hover: {
    '&&': {
      bg: 'beta.700',
      boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.25)',
      borderColor: 'transparent',
    },
  },
};

export const FlexTextWrapper: CSSObject = {
  gap: '1',
};

export const FlexTeamWrapper: CSSObject = {
  maxW: 'full',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: 'full',
  w: 'full',
  bgImage: `url(${clashingSoon})`,
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
  bgPos: 'center',
  h: '16',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${clashingSoon2x})`,
  },
};

// TODO: 6E7373 is not in the theme
export const FlexFeatureCardWrapper: CSSObject = {
  flexDir: 'column',
  boxSize: ['full', '54%'],
  bg: ['transparent', 'epsilon.400'],
  border: '3px solid',
  borderColor: ['transparent', 'epsilon.200'],
  boxShadow: ['transparent', '0px 4px 0px #6E7373'],
  borderRadius: 'md',
  mt: ['0', '4'],
  mb: ['16', '20'],
  p: '4',
};
