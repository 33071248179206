import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/buffalobet/images/homeFeaturedSport/featSport.jpg';
import featuredMb from '@/assets/buffalobet/images/homeFeaturedSport/featSportMb.jpg';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  border: 'none',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'beta.500',
  fontSize: 'md',
  fontWeight: 'normal',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.500',
  fontFamily: 'Staatliches',
  boxShadow: 'unset',
};

export const TextMisc: CSSObject = {
  bg: 'alpha.800',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.40), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'base',
  color: 'beta.400',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  fontWeight: 'extrabold',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontFamily: 'Staatliches',
  fontSize: '3xl',
  fontWeight: 'normal',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  color: 'beta.500',
};

export const puntButtonProps: CSSObject = {
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  borderRadius: 'base',
  color: 'white',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  fontWeight: 'normal',
  h: '9',
};
