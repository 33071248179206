import { TPunterStatement } from './statements.types';

export const MOCK_STATEMENTS: TPunterStatement[] = [
  {
    year: 2023,
    statements: [
      { month: 1, pdf_link: 'bet_cloud_link.pdf' },
      { month: 2, pdf_link: 'bet_cloud_link.pdf' },
      { month: 3, pdf_link: 'bet_cloud_link.pdf' },
    ],
  },
  {
    year: 2022,
    statements: [
      { month: 1, pdf_link: 'bet_cloud_link.pdf' },
      { month: 2, pdf_link: 'bet_cloud_link.pdf' },
      { month: 3, pdf_link: 'bet_cloud_link.pdf' },
    ],
  },
];
