import { CSSObject } from '@chakra-ui/react';

export const EstReturnsLabel: CSSObject = {
  bg: 'alpha.800',
  color: 'white',
  fontWeight: 'bold',
  height: '100%',
  alignItems: 'center',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, yellow.400, yellow.500)',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'yellow.900',
  justifyContent: 'center',
};

export const ApprovedBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, green.300, green.400)',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'green.700',
  justifyContent: 'center',
};

export const RejectedBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, red.300, red.500)',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'red.900',
  justifyContent: 'center',
};

export const FooterContainer: CSSObject = {
  bg: 'alpha.50',
  color: 'alpha.800',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'md',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
};
