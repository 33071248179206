import {
  Collapse,
  Flex,
  FlexProps,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Minus } from '@styled-icons/fa-solid/Minus';
import { Plus } from '@styled-icons/fa-solid/Plus';
import { groupBy, List as _List, ValueIteratee } from 'lodash';
import React from 'react';
import { marketLayoutStyles } from './styles/MarketLayout.styles';
import {
  TSportMarkets,
  TSportsMarketFormat,
} from '@/api/sportDetailsMarkets/sportDetailsMarkets.types';
import { TMatchInfoParticipant } from '@/lib/DBModels';
import { TExtendedProposition } from '../../MatchDetailPage/services/MatchDetailPage.types';
import HandicapLayout from '../HandicapLayout/HandicapLayout';
import InlineLayout from '../InlineLayout/InlineLayout';
import ListLayout from '../ListLayout/ListLayout';
import OverUnderLayout from '../OverUnderLayout/OverUnderLayout';
import PageButton from '../PageButton/PageButton';
import TableLayout from '../TableLayout/TableLayout';
import PlayerLayout from '../PlayerLayout/PlayerLayout';
import PlayerOverUnderLayout from '../PlayerOverUnderLayout/PlayerOverUnderLayout';

// !
export function groupByToArray<T>(
  collection?: _List<T> | null,
  iteratee?: ValueIteratee<T>
) {
  return Object.entries(groupBy(collection, iteratee)).map(([k, v]) => ({
    key: k,
    items: v,
  }));
}

// !
export const LIMIT = 5;

export type TMarketLayoutMarket = Omit<TSportMarkets, 'propositions'> & {
  propositions?: TExtendedProposition[];
};

export type TMarketLayoutPart = {
  title: string;
  markets: TMarketLayoutMarket[];
};

export type TMarketLayoutProps = {
  parts: TMarketLayoutPart[];
  participants?: TMatchInfoParticipant[];
  format?: TSportsMarketFormat;
  onSelection?: (proposition: TExtendedProposition) => void;
};

export default function MarketLayout({
  parts,
  participants,
  format,
  onSelection,
}: TMarketLayoutProps) {
  const propositionCount = Math.max(
    ...parts.map((p) => p.markets.flatMap((m) => m.propositions).length)
  );
  const Component =
    {
      handicap: HandicapLayout,
      inline: InlineLayout,
      list: ListLayout,
      'over-under': OverUnderLayout,
      table: TableLayout,
      'correct-score': TableLayout,
      'player-events': PlayerLayout,
      'player-over-under': PlayerOverUnderLayout,
      1: ListLayout,
      // Leaving these in the code as likely to add them back in
      // 2: InlineLayout,
      // 3: InlineLayout,
    }[format ?? propositionCount] ?? ListLayout;

  return (
    <Component
      parts={parts}
      participants={participants}
      onSelection={onSelection}
    />
  );
}

export function MarketLayoutHeader(props: TextProps) {
  return <Text {...marketLayoutStyles.headerText} {...props} />;
}

export function MarketLayoutRow({
  isFirst,
  ...rest
}: FlexProps & { isFirst?: boolean }) {
  return (
    <Flex
      {...marketLayoutStyles.rowWrapper}
      {...(isFirst && marketLayoutStyles.rowWrapperFirst)}
      {...rest}
    />
  );
}

export function MarketLayoutPagination({
  in: inProp,
  isOpen,
  onPageClick,
}: {
  in?: boolean;
  isOpen?: boolean;
  onPageClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Collapse in={inProp}>
      <MarketLayoutRow
        justifyContent="center"
        {...marketLayoutStyles.rowWrapperWithBtn}
      >
        <PageButton onClick={onPageClick}>
          {isOpen ? (
            <>
              Show Less
              <Icon as={Minus} />
            </>
          ) : (
            <>
              Show All
              <Icon as={Plus} />
            </>
          )}
        </PageButton>
      </MarketLayoutRow>
    </Collapse>
  );
}

export function MarketLayoutTabs({
  children,
  labels,
  onChange,
}: {
  children: React.ReactNode;
  labels: string[];
  onChange?: (index: number) => void;
}) {
  return (
    <Tabs isFitted variant="unstyled" onChange={onChange}>
      {labels.length > 1 && (
        <TabList sx={{ overflowX: 'auto' }}>
          {labels.map((label) => (
            <Tab key={label} {...marketLayoutStyles.tab}>
              {label}
            </Tab>
          ))}
        </TabList>
      )}

      <TabPanels>{children}</TabPanels>
    </Tabs>
  );
}

export function MarketLayoutTabPanel(props: TabPanelProps) {
  return <TabPanel p="0" {...props} />;
}
