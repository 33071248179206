import { CSSObject } from '@chakra-ui/react';

export const betCardErrorFlexProps: CSSObject = {
  borderTopRadius: 'sm',
};

export const BetPropositionClosedError: CSSObject = {
  inset: '0 0 -7px',
};

export const BetCardContainer = (): CSSObject => ({
  bg: 'linear-gradient(180deg, var(--bc-colors-brand-600) 0%, var(--bc-colors-brand-700) 100%)',
  borderTopRadius: 'base',
  color: 'white',
  '&::after': {
    content: `""`,
    position: 'absolute',
    bottom: '-30px',
    left: '0',
    width: '100%',
    height: '31px',
    background:
      'linear-gradient(135deg, var(--bc-colors-brand-700) 5px, transparent 0%),linear-gradient(-135deg, var(--bc-colors-brand-700) 5px, transparent 0%)',
    backgroundSize: '13px 32px',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
};

export const ExoticsNumberContainer: CSSObject = {
  w: '6 ',
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const BetOdds: CSSObject = {
  display: 'none',
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  fontWeight: 'bold',
  color: 'alpha.200',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'alpha.700',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeButton: CSSObject = {
  h: '9',
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-theta-50) 0%, var(--bc-colors-theta-100) 100%)',
  color: 'yellow.900',
  borderRadius: 'sm',
  boxShadow: '0px 4px 5px rgba(241, 186, 69, 0.2)',
  textShadow: 'none',

  _hover: {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-gamma-200) 0%, var(--bc-colors-gamma-300) 100%)',
  },
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'alpha.900',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  _hover: {
    bg: 'alpha.800',
  },
};

export const BonusBetsButton = (isBonusBet: boolean) => ({
  bg: isBonusBet ? 'alpha.800' : 'alpha.400',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
  color: isBonusBet ? 'white' : 'alpha.900',
  borderRadius: 'base',
  fontSize: '2xs',
  h: '6',
  px: '1.5',
  '&&': {
    minW: '20',
  },

  _hover: {
    bg: isBonusBet ? 'alpha.900' : 'alpha.500',
  },
});

export const StakeCollapse: CSSObject = {
  '&&': {
    m: '0',
    mt: '2',
  },
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});
