import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  bg: 'white',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  color: 'epsilon.500',
};

export const DividerCard: CSSObject = {
  borderColor: 'gray.400',
};
