import { TSportMarketsResponse } from './sportDetailsMarkets.types';

export const querySportMarketsMock: TSportMarketsResponse = [
  {
    market_id: 'head-to-head--regular-time',
    market_name: 'Head to Head',
    bet_option: '000.001',
    bet_option_sort_order: 0,
    market_type: 'Head_to_Head',
    event_info: {
      team: null,
      metric: null,
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: 'Regular Time',
      discriminator: null,
    },
  },
  {
    market_id: 'head-to-head--1st-half',
    market_name: 'Head to Head',
    bet_option: '000.002',
    bet_option_sort_order: 0,
    market_type: 'Head_to_Head',
    event_info: {
      team: null,
      metric: null,
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: '1st Half',
      discriminator: null,
    },
  },
  {
    market_id: 'head-to-head--2nd-half',
    market_name: 'Head to Head',
    bet_option: '000.003',
    bet_option_sort_order: 0,
    market_type: 'Head_to_Head',
    event_info: {
      team: null,
      metric: null,
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: '2nd Half',
      discriminator: null,
    },
  },
  {
    market_id: 'correct-score--regular-time',
    market_name: 'Correct Score',
    disclaimer: 'Some disclaimer.',
    bet_option: '000.004',
    bet_option_sort_order: 0,
    market_type: 'CorrectScore_Goals',
    event_info: {
      team: null,
      metric: null,
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: 'Regular Time',
      discriminator: null,
    },
  },
  {
    market_id: 'correct-score--1st-half',
    market_name: 'Correct Score',
    disclaimer: 'Some disclaimer.',
    bet_option: '000.005',
    bet_option_sort_order: 0,
    market_type: 'CorrectScore_Goals',
    event_info: {
      team: null,
      metric: null,
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: '1st Half',
      discriminator: null,
    },
  },
  {
    market_id: 'correct-score--2nd-half',
    market_name: 'Correct Score',
    disclaimer: 'Some disclaimer.',
    bet_option: '000.006',
    bet_option_sort_order: 0,
    market_type: 'CorrectScore_Goals',
    event_info: {
      team: null,
      metric: null,
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: '2nd Half',
      discriminator: null,
    },
  },
  {
    market_id: 'goals-over-under--regular-time',
    market_name: 'Goals Over/Under',
    disclaimer: 'Some disclaimer.',
    bet_option: '000.007',
    bet_option_sort_order: 0,
    market_type: 'OverUnder',
    event_info: {
      team: null,
      metric: 'Goals',
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: 'Regular Time',
      discriminator: null,
    },
  },
  {
    market_id: 'goals-over-under--1st-half',
    market_name: 'Goals Over/Under',
    disclaimer: 'Some disclaimer.',
    bet_option: '000.008',
    bet_option_sort_order: 0,
    market_type: 'OverUnder',
    event_info: {
      team: null,
      metric: 'Goals',
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: '1st Half',
      discriminator: null,
    },
  },
  {
    market_id: 'goals-over-under--2nd-half',
    market_name: 'Goals Over/Under',
    disclaimer: 'Some disclaimer.',
    bet_option: '000.009',
    bet_option_sort_order: 0,
    market_type: 'OverUnder',
    event_info: {
      team: null,
      metric: 'Goals',
      player: null,
      value1: null,
      value2: null,
      team_role: null,
      event_part: '2nd Half',
      discriminator: null,
    },
  },
  {
    market_id: 'half-time-full-time',
    market_name: 'Half Time / Full Time',
    disclaimer: 'Some disclaimer.',
    bet_option: '000.010',
    bet_option_sort_order: 0,
    market_type: 'HalfTimeFullTime',
  },
];
