import {
  chakra,
  FormLabel,
  TextProps,
  FormControl as FormControlChakra,
} from '@chakra-ui/react';
import { styleImports } from './FormControl.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FormLabelThemed = chakra(FormLabel, {
  label: 'FormLabelThemed',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FormLabelThemed;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const formLabelError: TextProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.formLabelError;
    } catch (err) {
      return {};
    }
  })(),
};

export const FormControlGroup = chakra(FormControlChakra, {
  label: 'FormControlGroup',
  baseStyle: () => ({
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.FormControlGroup;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
