import { CSSObject } from '@chakra-ui/styled-system';
import { keyframes } from '@chakra-ui/react';
import token from '@/assets/sterlingparker/images/toggle/toggle.png';

export const FlexWrapper: CSSObject = {
  borderRadius: '5px',
  borderTop: '1px solid rgba(255, 255, 255, 0.15)',
  background: 'linear-gradient(180deg, #30363F 0%, #21252B 100%)',
  boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  height: '60px',
  padding: '13px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '13px',
  mx: '1.5',
  mb: '12px',
  pos: 'relative',

  _before: {
    content: `''`,
    display: 'block',
    pos: 'absolute',
    inset: '6px',
    border: 'solid 1px rgba(0, 0, 0, 0.35)',
    borderRadius: '3px',
    boxShadow: '0 1px 0 0 rgba(255, 255, 255, 0.05)',
    zIndex: 1,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TextToggle = (isActive: boolean): CSSObject => ({
  textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'CrimsonPro',
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: '3.42px',
  color: '#d6a64a',
  textTransform: 'uppercase',
  width: '90px',

  _first: {
    textAlign: 'right',
  },
});

export const Switcher = (): CSSObject => ({
  bg: 'transparent',
  zIndex: 2,

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    '-webkit-tap-highlight-color': 'transparent',

    _before: {
      bgImage: `url(${token})`,
      bgSize: 'contain',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      boxSize: '45px',
      bgColor: 'transparent',
      pos: 'absolute',
      top: 0,
      bottom: '-9px',
      my: 'auto',
      left: '-9px',
      content: '""',
      animation: `
            ${keyframes`
              from { transform: rotateY(0deg) }
              to { transform: rotateY(-360deg) }
            `} 0.5s linear`,
      transformStyle: 'preserve-3d',
    },
  },
  '.chakra-switch__track': {
    boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
    bg: 'beta.800',
  },

  _checked: {
    bg: 'transparent',

    '.chakra-switch__track': {
      boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
      bg: 'beta.800',
    },

    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
      _before: {
        animation: `
          ${keyframes`
            from { transform: rotateY(0deg); }
            to { transform: rotateY(360deg); }
          `} 0.7s linear`,
        transformStyle: 'preserve-3d',
      },
    },
  },
});
