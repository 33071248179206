import { CSSObject } from '@chakra-ui/react';

export const BoxInner: CSSObject = {
  border: '1px',
  borderColor: 'alpha.500',
  borderRadius: 'base',
  overflow: 'hidden',
};

export const LinkWrapper: CSSObject = {
  bg: 'alpha.400',
  borderColor: 'inherit',
  color: 'gamma.200',
  fontWeight: 'bold',
  px: '2',
  py: '4',
};
