import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  color: 'white',
  bg: 'transparent',
  p: '2',
  '.chakra-checkbox__label': {
    color: 'white',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    border: '1px solid',
    borderColor: 'gray.300',
    width: '1rem',
    height: '1rem',
    borderRadius: 'base',
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _hover: {
      bg: 'alpha.100',
      boxShadow: '0px 0px 8px 0px rgba(43, 108, 176, 0.40)',
    },
    _checked: {
      boxShadow: 'none',
      color: 'blue.600',
      _hover: {
        bg: 'alpha.100',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
};
