import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.5) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: 'lg',
    mt: '3',
  },
  body: {
    color: 'white',
    px: '3',
    pt: '3',
    pb: '0',
  },
};
