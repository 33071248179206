import { css } from 'styled-components';

// TODO: Remove
export const mainTextContainer = css`
  margin-bottom: var(--bc-sizes-8);
`;

export const bodyText = css`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M};
  margin-bottom: var(--bc-sizes-4);
  line-height: var(--bc-lineHeights-tall);
`;

// TODO: Remove
export const contactItem = css`
  align-items: center;
  display: flex;
  margin-bottom: var(--bc-sizes-4);
`;

export const contactIcon = css`
  margin-right: var(--bc-sizes-1);
  height: 16px;
  width: 16px;
`;

export const contactLink = css`
  color: ${({ theme }) => theme.basePalette.brightBlue};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.M};
  text-decoration: none;
  cursor: pointer;
`;
