import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { TTransactionProbs } from './types';
import DesktopTransactionsTable from './Components/DesktopTransactionsTable/DesktopTransactionsTable';
import MobileTransactionsTable from './Components/MobileTransactionsTable/MobileTransactionsTable';
import { IS_MOBILE_APP } from '../../../../../constants/isMobileApp';
import { BoxTransactionsContainer } from './Components/DesktopTransactionsTable/styles/DesktopTransactionsTable.styles';

const Transactions: React.FC<TTransactionProbs> = ({ isBonus }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <BoxTransactionsContainer data-cy="transactionTable">
      {isMobile || IS_MOBILE_APP ? (
        <MobileTransactionsTable scrollRef={scrollRef} isBonus={!!isBonus} />
      ) : (
        <DesktopTransactionsTable scrollRef={scrollRef} isBonus={!!isBonus} />
      )}
    </BoxTransactionsContainer>
  );
};

export default Transactions;
