import { CSSObject } from '@chakra-ui/react';
import bgDt from '@/assets/fiestabet/images/homeFeaturedRacing/featureDesktop.png';
import bgMb from '@/assets/fiestabet/images/homeFeaturedRacing/featuredMb.png';
import wood from '@/assets/fiestabet/images/homeFeaturedRacing/woodBg.png';
import paperFlags from '@/assets/fiestabet/images/paperFlags.png';

export const FlexFeatureWrapper: CSSObject = {
  w: '100%',
  minH: [null, '370px'],
  p: '0',
  h: [null, '350px'],
  bgRepeat: 'no-repeat',
  bgSize: ['cover', null, null, null, '100% 100%'],
  bgImage: ['none', `url('${bgDt}')`],
};

export const FlexFeatureCardWrapper: CSSObject = {
  p: '5',
  pl: ['5', null, null, '240px'],
  pt: ['0', 'unset'],
};

export const FlexFeatureTitleWrapper: CSSObject = {
  bgImage: [`url(${bgMb})`, 'none'],
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  bgPosition: 'top center',
  minH: '245px',
  w: '100%',
  h: '366px',
};

export const LinkCardHeader: CSSObject = {
  bg: 'transparent',
  color: 'epsilon.500',

  '.state-inprogress': {
    bg: 'epsilon.400',
    color: 'gamma.700',
    borderRadius: 'base',
    minH: '4',
  },

  '.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
    minH: '4',
  },

  '.state-ended': {
    bg: 'delta.300',
    color: 'delta.800',
    borderRadius: 'base',
    minH: '4',
  },
};

export const IconCardHeader: CSSObject = {
  color: 'epsilon.500',
};

export const TextCardHeader: CSSObject = {
  color: 'epsilon.500',
  fontFamily: 'CreteRound',
  fontWeight: 'normal',
};

export const TextRaceNumber: CSSObject = {
  bg: 'beta.200',
  borderRadius: 'base',
  color: 'beta.700',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'beta.200',
  textTransform: 'uppercase',
  fontWeight: 'semibold',
  boxShadow:
    '0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset, 0px -1px 3px 0px rgba(0, 0, 0, 0.25) inset',
  ':first-child': {
    pl: '3',
  },
  lineHeight: 'normal',
};

export const FlexGridRunnerItem: CSSObject = {
  bg: 'transparent',
  borderBottom: '1px',
  borderBottomColor: 'blackAlpha.500',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'epsilon.500',
  fontWeight: 'semibold',
  'span[data-cy*="raceCardBarrierNumber"]': {
    fontSize: '2xs',
    fontWeight: 'semibold',
    bottom: '2px',
    position: 'relative',
  },
};

export const TextRunnerNumber: CSSObject = {
  _last: {
    color: 'epsilon.500',
    fontSize: '2xs',
    fontWeight: 'semibold',
  },
};

export const Button = (isActive: boolean): CSSObject => ({
  lineHeight: '1',
  borderRadius: 'lg',
  border: '2px',
  h: '10',
  p: '3',
  '.btn-span': { zIndex: 9 },
  textTransform: 'capitalize',
  fontWeight: 'black',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  transition: 'all 0.3s linear 0s',
  boxSizing: 'border-box',
  borderColor: 'epsilon.500',
  bgGradient: 'linear(180deg, beta.200, beta.400)',
  boxShadow:
    '0px 0px 0px 3px #006352 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(255, 255, 255, 0.30) inset',
  color: 'beta.500',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.47)',

  ...(isActive && {
    bg: `alpha.600`,
    bgImage: `url(${paperFlags})`,
    bgSize: 'contain',
    color: 'beta.200',
    boxShadow:
      '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px -1px 0px 0px #B96C91 inset, 0px 2px 7px 0px #210A15 inset',
    textShadow: '0px 2px 2px rgba(0, 0, 0, 0.52)',
    border: 'unset',

    _after: {
      opacity: 0,
    },
  }),
});

export const LinkRaceDetails: CSSObject = {
  color: 'gamma.700',
  bgGradient: 'linear(to-b, gamma.400 0%, gamma.500 100%)',
  boxShadow:
    '0px -2px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 0px 0px #FFB982 inset',
  textTransform: 'uppercase',
  fontFamily: 'Ultra',
  fontWeight: 'normal',
  textShadow: '0px 1px 0px rgba(255, 185, 130, 0.50)',
  lineHeight: 'normal',

  _hover: {
    bg: 'gamma.500',
  },
};

export const BoxCard: CSSObject = {
  my: ['0', null, '1'],
  mb: ['1', null, '0'],
  borderRadius: '2xl',
  boxShadow: '0px 2px 1px 0px #773053 inset, 0px 4px 0px 0px #994300',
  bgImage: wood,
  bgSize: 'cover',
};
