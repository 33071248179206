import {
  Box,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  IconProps,
} from '@chakra-ui/react';
import { styleImports } from './Footer.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type BetslipFooterSchema = Partial<{
  iconClearBet: IconProps;
}>;

export const betslipFooterStyles: BetslipFooterSchema = {
  iconClearBet: {
    'data-testid': 'betslip-footer-iconClearBet',
    boxSize: 4,

    ...styleImports?.[themeName]?.betslipFooterStyles?.iconClearBet,
  },
};

export const KeypadPortal = chakra(Box, {
  baseStyle: () => ({
    w: '100vw',
    height: '35vh',
    ml: ['0', '-55vw', '-55vw', '-55vw', '-75vw'],
    bottom: '0',
    position: 'fixed',
    zIndex: '2',
    bg: 'gamma.300',

    ...(() => {
      try {
        return styleImports[themeName]?.KeypadPortal;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FooterRowContainer = chakra(Flex, {
  label: 'footer-FooterRowContainer',
  baseStyle: () => ({
    flexDir: 'row',
    w: 'full',
    ...(() => {
      try {
        return styleImports[themeName]?.FooterRowContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const FlexResponsibleGamblingBannerWrapper = chakra(Flex, {
  label: 'ResponsibleGamblingBanner',
  baseStyle: () => ({
    boxShadow: '0px -2px 3px rgba(0, 0, 0, 0.2)',
    pt: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexResponsibleGamblingBannerWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;
