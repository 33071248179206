import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: '2',
  pb: ['5', null, '2'],
  bg: 'white',
  borderTopRadius: 'md',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  pb: '0',
  pl: '0',
  overflowX: 'visible',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLinkLabel: CSSObject = {
  bg: 'beta.500',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
  px: '3',
  py: '2',
  borderRadius: 'full',
  boxShadow: 'inset 0px 2px 3px rgba(0, 0, 0, 0.25)',
  '&:hover, &:focus': {
    color: 'gray.700',
    bg: 'gray.400',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: -1.5,
  px: '0',
  pt: '2',
  pb: '2',
  bg: 'white',
  borderTopRadius: 'md',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};
