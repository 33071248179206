import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  bg: 'alpha.300',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
  _hover: {
    bg: 'alpha.200',
  },
  _active: {
    bg: 'alpha.600',
    color: 'white',
    boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.5)',
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'alpha.800',
    border: '2px',
    borderColor: 'gray.50',
    color: 'white',
    borderRadius: 'sm',

    _placeholder: {
      color: 'white',
    },

    '&:active, &:focus, &:visited': {
      bg: 'alpha.900',
      outline: 'none',
      border: 'none',
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    bg: 'gray.50',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '4',
  bgGradient: 'linear(to-b, alpha.300, alpha.700)',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bg: 'blackAlpha.300',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
};
