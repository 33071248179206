import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'alpha.700',
  textTransform: 'capitalize',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  mb: ['2', null, '0px'],
};
