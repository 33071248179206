import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear-gradient(180deg, #FFE0A6 0%, #E4C180 100%)',
  borderRadius: 'base',
  color: 'alpha.900',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '2',
  px: '3',
  py: '3',
};

export const ButtonGetVerified: CSSObject = {
  bg: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  border: '2px',
  borderColor: '#A1741F',
  borderRadius: 'base',
  color: '#634713',
  fontFamily: 'Skranjibold',
  fontSize: 'xs',
  fontWeight: '700',
  height: '40px',
  lineHeight: '6',
  ml: 'auto',
  minW: 'auto',
  px: '2.5',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
  boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
  _hover: {
    bg: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  },
  _focus: {
    bg: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  },
};

export const TextMustBeVerified: CSSObject = {
  color: 'alpha.700',
};
