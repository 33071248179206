/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { LocationNotAllowedContainer } from '../styles/LocationNotAllowed.styles';
import Header from '../Header';
import Content from '../Content';

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  heading: <Header />,
  main: {
    wrapper: (
      <Center
        w="full"
        flexDir="column"
        mt={['8', null, '12']}
        maxW={['380px', null, 'container.sm']}
        mx="auto"
        gap="4"
        px="4"
      />
    ),
    body: <Content />,
  },
};

export default template;
