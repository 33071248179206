import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';

export const betCardErrorFlexProps: CSSObject = {
  borderTopRadius: 'md',
};

export const BetCardContainer = (): CSSObject => ({
  bgGradient: 'linear(180deg, #FFE0A6 25%, #E4C180)',
  borderRadius: 'md',
  color: 'alpha.500',
  boxShadow:
    '0px 6px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px #B68452, 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 8px 0px rgba(255, 255, 255, 0.40) inset',
  'div[class*="ExoticsFooterContainer"]': {
    color: '#7A3B1E',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'alpha.500',
  mr: '-1',
  ml: '1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'alpha.300',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'alpha.500',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'sm',
  lineHeight: '4',
  flex: '1',
  textTransform: 'capitalize',
  color: '#7A3B1E',
  span: {
    color: '#7A3B1E',
    fontSize: 'unset',
  },
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: '#7A3B1E',
  fontWeight: 'semibold',
  textTransform: 'capitalize',
  pt: '0.5',
});

export const EventSubtitle: CSSObject = {
  color: '#7A3B1E',
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'semibold',
  pt: '0.5',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  width: '25%',
  justifyContent: 'space-between',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  pt: '0',
  px: '2',
  pb: '2',
};

export const BetPlaced: CSSObject = {
  bgImage: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%);',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF;',
  borderRadius: 'md',
  flexDirection: 'column',
  color: 'black',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '0.5',
};

export const StakeButton: CSSObject = {
  h: '9',
  fontFamily: 'SkranjiBold',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  border: 'var(--bc-colors-delta-700) 2px solid',
  color: 'rgba(0, 0, 0, 0.64)',
  textShadow: '0px 1px 2px rgba(255, 255, 255, 0.40)',
  borderRadius: 'lg',
  fontWeight: 'medium',
  _hover: {
    bgGradient: 'linear(180deg, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },
  _first: {
    borderStartRadius: 'lg',
  },
  _last: {
    borderStartRadius: 'lg',
  },
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'beta.500',
  boxShadow: 'md',
  color: 'black',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
  _hover: {
    bg: 'beta.700',
    color: 'white',
    boxShadow: 'md',
  },
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
  color: 'gray.600',
};

export const BonusBetsButton = (isBonusBet: boolean) => ({
  bg: isBonusBet ? 'alpha.400' : 'alpha.500',
  boxShadow: isBonusBet
    ? 'inset 0px 0px 4px rgba(0, 0, 0, 0.4)'
    : '0px 1px 0px 0px #C06431 inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
  color: 'zeta.300',
  borderRadius: 'md',
  border: isBonusBet ? '1px' : 'none',
  borderColor: 'alpha.500',
  fontWeight: 'medium',
  fontSize: '2xs',
  h: '6',
  px: '1.5',
  '&&': {
    minW: '20',
  },
  '.chakra-button__icon': {
    mr: '1',
  },

  _hover: {
    bg: isBonusBet ? 'alpha.300' : 'alpha.400',
    color: 'zeta.300',
    border: isBonusBet ? '1px' : 'none',
    borderColor: 'alpha.400',
    textShadow: 'none',
    boxShadow: 'none',
  },

  _active: {
    boxShadow: 'none',
  },

  _disabled: {
    boxShadow: 'none',
    border: 'none',
    bg: '#A68E61',
    color: 'beta.500',
    textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
    textTransform: 'uppercase',
    opacity: '1',
    _hover: {
      bg: '#A68E61',
    },
  },
});

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgba(0,0,0,0.6)',
  borderRadius: 'base',
  px: '5px',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? '#7A3B1E' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5', '&:hover': { color: 'white' } }} />
);
