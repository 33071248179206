import React from 'react';
import {
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  Icon,
  Link,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Link as LinkDom } from 'react-router-dom';
import { CheckmarkOutline } from '@styled-icons/evaicons-outline';
import styled from 'styled-components';
import Input from '../../../../components/FormElements/Input';
import DatePicker from '../../../../components/DatePicker';
import Password from '../../../../components/FormElements/Password';
import { Column } from '@/components/Grid/Styles';
import Select from '../../../../components/FormElements/Select';
import AgeAcknowledgementButton from '../../../../components/SignUp/conditionals/AgeAcknowledgementButton/template';
import DatePickerProps from '../../../../components/DatePicker/types';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { styleImports } from './SignUp.styles.imports';

const themeName = getThemeName();

export type SignUpSchema = Partial<{
  buttonSignUp: CustomButtonProps;
}>;

export const signUpStyles: SignUpSchema = {
  buttonSignUp: {
    'data-testid': 'header-buttonSignUp',

    ...styleImports[themeName]?.signUpStyles?.buttonSignUp,
  },
};

export const ManualAddressContainer = styled.div<{ isManual?: boolean }>`
  display: ${({ isManual }) => (isManual ? 'flex' : 'none')};
  flex-direction: column;
  padding: var(--bc-sizes-1) 0 var(--bc-sizes-2) 0;

  ${Column} ~ ${Column} {
    margin-left: 0;
  }

  ${({ theme }) => theme.device.tablet} {
    ${Column} ~ ${Column} {
      margin-left: var(--bc-sizes-2);
      flex: 1;
    }
  }
`;

export const addressWrapperProps: BoxProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.addressWrapperProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const PasswordRulesContainer = chakra(Box, {
  label: 'signUp-PasswordRulesContainer',
  baseStyle: ({ theme }) => ({
    pl: '4',
    pt: '3',
    pb: ['5', null, 0],
    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: 'initial',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      pl: ['0', null, '4'],
      pt: ['0', null, '3'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.PasswordRulesContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ErrorMessage = chakra(Text, {
  label: 'signUp-ErrorMessage',
  baseStyle: () => ({
    background: 'red.500',
    color: 'white',
    fontSize: 'sm',
    borderRadius: 'sm',
    py: '1',
    px: '2',
    mb: '2',
  }),
});

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => {
  try {
    return (
      styleImports[themeName]?.PasswordRulesIcon?.({
        active,
      }) ?? <Icon as={CheckmarkOutline} mr="1" boxSize="4" />
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Icon as={CheckmarkOutline} mr="1" boxSize="4" />;
};

export const PasswordRule = chakra<TPasswordRule>('span', {
  label: 'signUp-PasswordRule',
  shouldForwardProp: (prop) => !['active'].includes(prop),
  baseStyle: ({ theme, active }: TPasswordRule) => ({
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    flexDirection: 'row',
    opacity: active ? 1 : 0.5,
    mb: '2',
    textAlign: 'left',

    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'semibold',
      opacity: '1',
      color: active ? ['green.400', null, 'white'] : 'yellow.400',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.PasswordRule?.({
          active,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CheckboxHelpText = chakra(Text, {
  label: 'signUp-CheckboxHelpText',
  baseStyle: ({ theme }) => ({
    fontSize: 'xs',

    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: 'initial',
      fontWeight: 'medium',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'normal',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.CheckboxHelpText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkLogin = chakra(LinkDom, {
  label: 'signUp-LinkLogin',
  baseStyle: ({ theme }) => ({
    fontSize: 'md',
    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'semibold',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: 'initial',
    }),
    ...(theme.themeName === EThemes.Betprofessor && {
      textTransform: 'initial',
    }),
    ...(theme.themeName === EThemes.Goldenrush && {
      display: 'none',
    }),
  }),
});

export const LinkHighlight = chakra(Text, {
  label: 'signUp-LinkHighlight',
  baseStyle: () => ({
    fontWeight: 'bold',
  }),
});

export const FlexHeaderWrapper = chakra(Flex, {
  label: 'signUp-FlexHeaderWrapper',
  baseStyle: () => ({
    justifyContent: ['space-between', null, 'flex-start'],
    ...(() => {
      try {
        return styleImports[themeName]?.FlexHeaderWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PasswordTooltip = chakra(Tooltip, {
  label: 'signUp-PasswordTooltip',
  baseStyle: ({ theme, validPassword }: any) => ({
    bg: 'gray.900',
    color: 'white',
    p: '2',
    textAlign: 'center',
    ...(theme.themeName === EThemes.Wellbet && {
      bg: validPassword ? 'alpha.600' : 'beta.800',
      color: 'white',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.PasswordTooltip?.({
          validPassword,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TPasswordTooltip>;

export const DesktopPasswordContainer = chakra(Flex, {
  label: 'signUp-DesktopPasswordContainer',
  baseStyle: () => ({
    display: ['none', null, 'flex'],
    flexDir: 'row',
    gap: '4',
  }),
});

export const MobilePasswordContainer = chakra(Flex, {
  label: 'signUp-MobilePasswordContainer',
  baseStyle: () => ({
    display: ['flex', null, 'none'],
    flexDir: 'column',
  }),
});

export const SignUpInput = chakra(Input, {
  label: 'signUp-SignUpInput',
  baseStyle: () => ({
    borderRadius: 'md',
    flex: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.SignUpInput;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SignUpPassword = chakra(Password, {
  label: 'signUp-SignUpPassword',
  baseStyle: () => ({
    borderRadius: 'md',
    w: 'full',
  }),
});

export const SignUpDatePicker = chakra(DatePicker, {
  label: 'signUp-SignUpDatePicker',
  baseStyle: ({ theme }) => ({
    borderRadius: 'md',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'gray.500',
      fontWeight: 'semibold',
      fontSize: 'xs',
      mb: '1.5',
      '.chakra-input__left-element': {
        display: 'none',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.SignUpDatePicker;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'input', DatePickerProps>;

export const StateSelect = chakra(Select, {
  label: 'signUp-StateSelect',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      borderRadius: 'md',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      borderRadius: 'md',
    }),
  }),
});

export const SignUpButton = chakra(Button, {
  label: 'signUp-SignUpButton',
  baseStyle: ({ theme }) => ({
    mb: '3',
    ...(theme.themeName === EThemes.Betgalaxy && {
      h: '10',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.SignUpButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const SignupAcknowledgementButton = chakra(AgeAcknowledgementButton, {
  label: 'signUp-SignupAcknowledgementButton',
  baseStyle: ({ theme }) => ({
    color: 'white',
    fontSize: 'xs',
    verticalAlign: 'bottom',
    textDecoration: 'underline',
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'blue.400',
      fontWeight: 'medium',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.SignupAcknowledgementButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SignupAcknowledgementLink = chakra(Link, {
  label: 'signUp-SignupAcknowledgementLink',
  baseStyle: ({ theme }) => ({
    color: 'white',
    fontSize: 'xs',
    verticalAlign: 'bottom',
    textDecoration: 'underline',
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'blue.400',
      fontWeight: 'medium',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.SignupAcknowledgementLink;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxLoginCTAContainer = chakra(Link, {
  label: 'signUp-BoxLoginCTAContainer',
  baseStyle: ({ theme }) => ({
    textAlign: 'center',
    mt: '2',
    ...(theme.themeName === EThemes.Wellbet && {
      display: 'none',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxLoginCTAContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SelectSignUp = chakra(Select, {
  label: 'signUp-SelectSignUp',
  baseStyle: () => ({
    borderRadius: 'md',
    ...(() => {
      try {
        return styleImports[themeName]?.SelectSignUp;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxManualAddressContainer = chakra(Box, {
  label: 'signUp-BoxManualAddressContainer',
  baseStyle: ({ theme }) => ({
    mt: '4',
    ...(theme.themeName === EThemes.Wellbet && {
      border: '1px dashed',
      borderColor: 'gray.300',
      borderRadius: 'base',
      px: ['2', null, '4'],
      pt: ['2', null, '4'],
      pb: ['2', null, '0'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxManualAddressContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexStreetDetailsContainer = chakra(Flex, {
  label: 'signUp-FlexStreetDetailsContainer',
  baseStyle: ({ theme }) => ({
    flexDir: ['column', null, 'row'],
    gap: '4',
    ...(theme.themeName === EThemes.Wellbet && {
      gap: '2',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.FlexStreetDetailsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FormStack = (props: StackProps) => {
  try {
    return (
      styleImports[themeName]?.FormStack?.(props) || (
        <Stack {...props} spacing="4" direction={['column', null, 'row']} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Stack {...props} spacing="4" direction={['column', null, 'row']} />;
};
