import { chakra, Box } from '@chakra-ui/react';
import { styleImports } from './account.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const ContainerForms = chakra(Box, {
  label: 'account-ContainerForms',
  baseStyle: ({ theme }) => ({
    maxW: ['100%', '600px'],
    px: 0,
    mx: 0,
    ...(theme.themeName === EThemes.Betgalaxy && {
      py: '4',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      '.chakra-input__left-addon': {
        bg: 'gray.400',
        h: '9',
      },
      '.chakra-input': {
        border: '1px',
        borderColor: 'gray.400',
        bg: 'white',
        h: '9',
        '&:hover': {
          bg: 'gray.50',
        },
        '&:active': {
          bg: 'gray.100',
        },
      },
      '.chakra-select': {
        border: '1px',
        borderColor: 'gray.400',
        bg: 'gray.50',
        h: '9',
      },
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.ContainerForms;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
