import { TextProps, CSSObject } from '@chakra-ui/react';

export const FormControlGroup: CSSObject = {
  'input#email': {
    border: 0,
    bg: 'alpha.300',
  },
  '#title-input[disabled], #firstName-input[disabled], #lastName-input[disabled], #mobile-input[disabled], #email-input[disabled], #address-input[disabled], #city-input[disabled], #state-input[disabled], #dateOfBirth-input[disabled]':
    {
      bg: 'alpha.300',
      borderLeft: '4px solid',
      borderLeftColor: 'beta.400',
      _hover: {
        border: 'none',
      },
    },
};

export const formLabelError: TextProps = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
  bg: 'red.600',
  p: '1',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};

export const FormLabelThemed: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'semibold',
  mt: '2',
};
