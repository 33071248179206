import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'alpha.500',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  p: '3',
  m: 'auto',
};

export const HeaderText: CSSObject = {
  color: 'beta.500',
  fontSize: 'md',
  fontWeight: 'bold',
  p: '0',
  textTransform: 'none',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const CloseButton: CSSObject = {
  display: 'none',
};
