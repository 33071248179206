import { CompetitionBySportV2Schema } from '@/views/sports/CompetitionsBySport/styles/CompetitionBySportV2.styles';

export const competitionBySportV2Styles: CompetitionBySportV2Schema = {
  containerNav: { mb: '3' },
  scrollButtonGroup: {
    mt: '3',
    variant: 'filter',
  },
  scrollButtonGroupDivider: {
    mb: '3',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
  competitionListBoxWrapper: {
    w: 'full',
  },
  scrollButtonGroupButtonCompetition: {
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
  },
  scrollButtonGroupButtonAllLeagues: {
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
  },
};
