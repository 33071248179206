import { CSSObject } from '@chakra-ui/react';

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
};

export const GridItemBetslip: CSSObject = {
  h: '98%',
  '& > div[class*="-BetSlipModalContainer"]': {
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '97%',
    boxShadow:
      '0px 0px 0px 4px #EFFECB inset, 4px 5px 8px 3px rgba(0, 0, 0, 0.15) inset',
  },
};
