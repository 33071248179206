import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    pt: '2',
    pb: '4',
    mb: '0',
  },
  boxWrapper: {
    mx: ['2', null, 'unset'],
    borderRadius: '0',
    pb: 'unset',
  },
  textTitle: {
    mt: '0',
    mb: '2',
    color: 'white',
    fontSize: 'md',
    fontWeight: 'medium',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        p: '2',
        borderBottomRadius: 'md',
        bg: 'epsilon.400',
        color: 'black',
        borderTopRadius: 'base',
        borderTop: '2px',
        borderLeft: '2px',
        borderRight: '2px',
        borderColor: 'epsilon.200',
        flexDir: 'column',
        mt: '4',
      },
    },
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
    mb: '4',
  },
};
