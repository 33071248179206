import { CSSObject } from '@chakra-ui/react';

export const FlexBetslipHeading: CSSObject = {
  bg: 'white',
  h: '12',
  borderBottomColor: 'alpha.700',
  borderBottom: '2px',
  color: [null, null, null, null, 'white'],
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'alpha.700',
};

export const BetLengthIndicator: CSSObject = {
  bg: 'yellow.400',
  color: 'yellow.900',
  fontSize: 'xs',
  borderRadius: 'base',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'alpha.700',
};
