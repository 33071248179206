import { Box, VisuallyHidden } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import promo from '@/assets/vikingbet/images/banner/promo.png';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <Box
            key="banner-image"
            sx={{
              mt: ['60px', null, '0'],
              w: '100%',
              h: ['23vh', null, '14rem'],
              bgImage: [promo],
              bgSize: ['cover', null, '100%'],
              bgPosition: 'center',
              bgRepeat: 'no-repeat',
            }}
          >
            <VisuallyHidden>
              <FormattedMessage id="home.carousel.accessbility" />
            </VisuallyHidden>
          </Box>,
        ]}
      />
    </Box>
  );
}
