import React from 'react';
import { getStrings } from '@/helpers/utils';
import Title from '../../../components/Title';

export default function PageTitle() {
  const [
    {
      Onboarding: { Login },
    },
  ] = getStrings();

  return <Title>{Login.Header}</Title>;
}
