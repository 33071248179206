import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import gradientBorder from '@/assets/luckystride/images/gradient-border.svg';

export const FlexBetSlipModalContainer: CSSObject = {
  boxShadow:
    '0px 2px 20px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 5px 0px rgba(33, 193, 255, 0.25) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset',
  bg: ['alpha.400', null, null, null, 'rgba(0, 209, 255, 0.25)'],
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  borderRadius: 'md',
  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    borderImage: `url(${gradientBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  p: '8px',
  mx: '3px',
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
export const BetSlipContainer: BoxProps = {
  borderRadius: 'base',
};
export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};
export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'body',
  fontSize: 'sm',
};
export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'beta.300',
  fontFamily: 'body',
  fontSize: 'sm',
});
export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};
export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};
export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'odds',
  height: '6',
  width: 'auto',
  margin: 'auto',
  mt: '-4',
  pl: 1,
  pr: 2,
  display: 'flex',
  textTransform: 'uppercase',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
      zIndex: 9,
    },
    '.chakra-icon': {
      pb: '0.5',
      boxSize: '3.5',
    },
  },
};
export const BetslipVStack: CSSObject = {
  gap: '2.5',
};
export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};
export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'primary',
  height: '42px',
  textTransform: 'uppercase',
};
