import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '../../../../../../hooks/useAuth';
import {
  ButtonLogOut,
  TextLogOutBtn,
  IconWrapperLogOutBtn,
} from './Styles/Logout.styles';
import IconSvg from '../../../../../common/IconSvg';

export function LogOut() {
  const { logout } = useAuth();
  return (
    <ButtonLogOut
      variant="link"
      onClick={() => logout()}
      data-cy="link-qa-logout"
    >
      <TextLogOutBtn>
        <FormattedMessage id="qam.nav.logOut" />
      </TextLogOutBtn>
      <IconWrapperLogOutBtn>
        <IconSvg name="qamLogOut" boxSize={4} />
      </IconWrapperLogOutBtn>
    </ButtonLogOut>
  );
}
