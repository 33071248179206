import { CSSObject, FlexProps } from '@chakra-ui/react';

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'beta.400',
  fontWeight: 'bold',
};

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const HeaderContainer = (): CSSObject => ({
  borderBottom: '1px dashed',
  borderColor: 'alpha.500',
  pb: '2',
  w: 'fill-available',
});

export const flexEventProps: FlexProps = {
  pb: '3px',
};
