/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Show, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  buttonBetSlipProps,
  buttonBetsProps,
  ButtonGroupBets,
  TextButton,
  TextButtonNumber,
} from '../styles/Header.styles';
import { Button } from '@/components/Button/Button';
import { useBetsCount } from '@/components/Betslip/Services/Betslip.hooks';
import { getStrings } from '@/helpers/utils';
import { useMyBetsLength } from '../services/Header.hooks';
import QuickAccessMenuTemplate from './QuickAccessMenu/templates/template';
import { createTemplate } from '@/lib/templateSwitch';
import { transformBetForLegacy, useBetSlipBets } from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { TBetSlipBet } from '@/components/Betslip/Services/Betslip.types';
import { setBetSlipOpen } from '@/redux/Betslip.slices';

/**
 * Containers
 */
const AuthReduxContainer = () => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <Authed bets={bets} />;
};

const AuthZustandContainer = () => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <Authed bets={bets} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? AuthZustandContainer
  : AuthReduxContainer;

// ----

/**
 * View
 */
type AuthedProps = {
  bets: TBetSlipBet[];
};

function Authed({ bets: storeBets }: AuthedProps) {
  const dispatch = useAppDispatch();
  const quickAccessMenu = createTemplate(QuickAccessMenuTemplate);
  const navigate = useNavigate();
  const bets = useMyBetsLength();
  const [{ Generic }] = getStrings();
  const { validBetsCount } = useBetsCount({ bets: storeBets });

  return (
    <>
      {/* QAM template */}
      {quickAccessMenu}

      <ButtonGroupBets>
        <Button
          {...buttonBetsProps}
          onClick={() => navigate('/account/my-bets')}
        >
          <Text as="span" className="span-bet">
            {Generic.Bets}
          </Text>
          <Text
            as="span"
            className="span-bet-count"
            data-cy="header-pending-bets"
          >
            {bets}
          </Text>
        </Button>
        <Show below="xl">
          <Button
            {...buttonBetSlipProps}
            data-cy="betSlipCount"
            className="betslipBtn"
            onClick={() => dispatch(setBetSlipOpen(true))}
          >
            <TextButton>
              <FormattedMessage id="header.betslipBtn.desc" />
            </TextButton>
            <TextButtonNumber data-cy="betCount">
              {validBetsCount}
            </TextButtonNumber>
          </Button>
        </Show>
      </ButtonGroupBets>
    </>
  );
}
