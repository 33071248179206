import React from 'react';
import Featured from './components/Featured/Featured';
import RaceCards from './components/RaceCards/RaceCards';
import RaceTiles from './components/RaceTiles/RaceTiles';
import { useCategory } from '../services/Home.hooks';
import { FEATURE_FLAGS } from '../../../constants/featureFlags';

export default function Races() {
  const { showSport } = useCategory();

  if (showSport || !FEATURE_FLAGS.HAS_RACE_VIEW) return null;

  return (
    <>
      <RaceCards />
      <Featured />
      <RaceTiles />
    </>
  );
}
