import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  alignItems: 'center',
  px: '3',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.300',
  h: '16',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
};
