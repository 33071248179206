import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  justifyContent: [null, null, 'center'],
  minW: ['unset', null, '460px'],
  form: {
    '.chakra-form-control': {
      '.chakra-form__label': {
        color: 'gray.500',
        fontSize: 'xs',
      },
    },
    'a[href*="forgot-password"]': {
      color: 'delta.500',
      fontWeight: 'medium',
    },
    '.chakra-button[data-cy*="loginSubmit"]': {
      bgGradient: 'linear(to-b, alpha.400, alpha.500)',
      boxShadow:
        '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
      color: 'blackAlpha.600',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
      textTransform: 'uppercase',
      fontSize: 'sm',
      fontFamily: 'Coiny',
      borderRadius: 'md',
      borderWidth: '2px',
      borderColor: 'white',
      letterSpacing: 'wide',
      pt: '0.5',
      _hover: {
        bgGradient: 'linear(to-b, alpha.600, alpha.600)',
      },
    },
    'button[aria-label*="password toggle"]': {
      top: 'px',
    },
  },
};

export const Title: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: '4',
  mt: [null, null, '9'],
};
