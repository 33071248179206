import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import coin from '@/assets/goldenbet888/images/toggle/coin.png';
import koi from '@/assets/goldenbet888/images/toggle/koi.png';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'lg',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, 'unset'],
    mb: ['1', null, '2.5'],
    gap: '0',
    boxShadow: [
      null,
      null,
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    ],
    backgroundImage: `url(${koi}), linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-500))`,
    backgroundPosition: '-370px 0px',
    bgSize: '364px',
    bgBlendMode: 'overlay',
    h: '16',
    pos: 'relative',
  }),
});

const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'accentMusashi',
    fontWeight: 'normal',
    fontSize: 'lg',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: isActive ? 'gamma.500' : 'gamma.200',
    letterSpacing: 'widest',
    px: '3',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) - var(--bc-sizes-1-5))',
    '--switch-track-width': '58px',
    bg: 'transparent',
    pos: 'relative',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: `url(${coin})`,
        boxSize: '36px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-3px',
        right: '0',
        top: '1px',
      },
    },
    '.chakra-switch__track': {
      boxShadow:
        '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px #F75144',
      bg: '#007549',
      borderRadius: '2em',
      h: '32px',
      _checked: {
        bg: '#007549',
      },

      _before: {
        bg: 'blackAlpha.500',
        h: '11px',
        w: '32px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset',
        content: '""',
        pos: 'absolute',
        left: '15px',
        top: '12px',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </TextToggle>
    </FlexWrapper>
  );
}
