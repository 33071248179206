import React, { Fragment, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { en } from '@/intl/en';
import imports from '../../intl/en.imports';

type TIntl = {
  children: ReactNode;
};

const msgs: Record<string, string> = {
  ...en,
  ...imports.en,
};

export default function Intl({ children }: TIntl) {
  return (
    <IntlProvider
      defaultLocale="en-AU"
      locale="en-AU"
      messages={msgs}
      textComponent={Fragment}
    >
      <>{children}</>
    </IntlProvider>
  );
}
