import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { FileTextFill } from '@styled-icons/bootstrap';
import { TCloseIcon } from './Header.styles';

export const closeIcon: TCloseIcon = {
  boxSize: '6',
  as: CloseCircle,
  color: 'white',
};

export const CloseIconButton: CSSObject = {
  justifyContent: 'end',
};

export const betslipIcon: TCloseIcon = {
  boxSize: '6',
  as: FileTextFill,
  color: 'white',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'white',
  boxSize: '4',
};

export const FlexBetslipHeading: CSSObject = {
  bgColor: 'inherit',
  h: '46px',
  px: '3',
  borderBottomColor: 'blackAlpha.400',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
};

export const BetslipTitle: CSSObject = {
  fontSize: [null, null, null, 'lg', 'lg'],
};

export const BetslipTitleText: CSSObject = {
  fontFamily: 'fredoka',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  textTransform: 'uppercase',
  color: 'white',
  fontSize: '15px',
  fontStyle: 'normal',
  lineHeight: '100%',
};

export const BetLengthIndicator: CSSObject = {
  textAlign: 'center',
  w: '18px',
  h: '18px',
  ml: '1',
  color: 'black',
  pt: '1.5',
  bg: 'var(--local-gradient-green, linear-gradient(180deg, #6DDA01 0%, #5EBB01 100%))',
  fontSize: 'sm',
  borderRadius: 'sm',
  boxShadow:
    '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.25)',
};
