import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  minW: '12',
  w: ['47px', null, '16'],
  _disabled: {
    border: '2px solid',
    borderColor: 'white',
    bg: 'gray.300',
    borderRadius: 'md',
    color: 'blackAlpha.500',
    fontWeight: 'bold',
    fontSize: 'sm',
    boxShadow:
      '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
    pointerEvents: 'none',
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: 'calc(100% + 4px)',
  maxH: 'calc(var(--bc-space-3) + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  borderTop: '1px solid rgba(255, 255, 255, 0.10)',
  borderRight: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'beta.800',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'beta.800',
  textShadow: 'none',
};
