import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TextTitle, FlexConfirmation } from './styles/LifetimeExclusion.styles';

import {
  ContentContainer,
  MainContainer,
  WidthContainer,
} from '@/wrappers/PrimaryWrapper/styles/PrimaryWrapper.styles';
import Footer from '@/components/Footer/Footer';

const LifetimeExclusionConfirmation: React.FC = () => (
  <MainContainer shouldHideOverflow={false}>
    <ContentContainer fullWidthMobile fullWidth>
      <WidthContainer fullWidth>
        <FlexConfirmation>
          <TextTitle>
            <FormattedMessage id="account.lifetimeExclusionConfirmation" />
          </TextTitle>
        </FlexConfirmation>
      </WidthContainer>
    </ContentContainer>

    <Footer />
  </MainContainer>
);

export default LifetimeExclusionConfirmation;
