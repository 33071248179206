import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordTooltip,
  TPasswordRule,
  TPasswordRulesIcon,
} from '../services/SignUp.types';

export const SignupAcknowledgementButton: CSSObject = {
  color: 'gamma.400',
  textDecoration: 'none',
  fontWeight: 'bold',
  span: {
    color: 'beta.500',
  },
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'gamma.400',
  textDecoration: 'none',
  fontWeight: 'bold',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  color: 'blackAlpha.700',
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'green.400' : 'gamma.600',
  color: 'white',
  '& .chakra-tooltip__arrow': {
    display: 'none',
  },
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active ? ['green.400', null, 'white'] : 'yellow.300',
});

export const BoxManualAddressContainer: CSSObject = {
  bg: 'none',
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'normal',
  color: 'gray.600',
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);

export const SignUpInput: CSSObject = {
  borderRadius: 'lg',
  alignSelf: 'stretch',
  h: '38px',
  '&&': {
    bg: 'gray.50',
    boxShadow: 'unset',
    borderWidth: '1px',
    borderStyle: ' solid',
    borderColor: 'gray.300',
    color: 'gray.700',
    fontWeight: '400',
    '&&:hover, &&:focus': {
      bg: 'gray.100',
      borderWidth: '1px',
      borderStyle: ' solid',
      borderColor: 'alpha.200',
      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },
    _placeholder: {
      color: 'gray.400',
    },
  },
};
