export type TNotificationMessage = {
  deepLinkUrl: string;
  meta: {
    global: boolean;
    id: string;
    type: ENotificationType;
  };
  notification: {
    body: string;
    subject: string;
  };
};

export enum ENotificationType {
  WinningBet = 'WinningBet',
  VoidedBet = 'VoidedBet',
  BonusCredit = 'BonusCredit',
}
