import { useQuery } from '@tanstack/react-query';
import { keys } from '../../../../api/api.keys';
import { queryPunterAccountOverview } from '../../../../api/punter/punter';
import { useAuth } from '../../../../hooks/useAuth';

export const useAccountBalance = () => {
  const { isAuthenticated } = useAuth();
  const { data, isInitialLoading } = useQuery(
    [keys.punterAccountOverview, isAuthenticated],
    queryPunterAccountOverview,
    {
      staleTime: 60 * 5000, // 5 minutes
      enabled: !!isAuthenticated,
    }
  );

  return {
    accountBallance: data?.account_balance,
    availableBalance: data?.available_balance,
    accountBonusBalance: data?.bonus,
    isLoading: isInitialLoading,
  };
};
