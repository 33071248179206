import { MarketLayoutStylesSchema } from './MarketLayout.styles';

export const matchDetailsStyles: MarketLayoutStylesSchema = {
  headerText: {
    flex: '1',
    color: 'beta.800',
    align: 'center',
    fontSize: '2xs',
    fontWeight: 'bold',
  },
  rowWrapper: {
    mx: '0.5',
    p: '2',
    alignItems: 'center',
    display: 'flex',
    fontSize: 'xs',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    borderColor: 'transparent',
    borderTopWidth: '0',
    gap: '2',
    pos: 'relative',
    color: 'beta.800',
    _before: {
      borderColor: 'blackAlpha.400',
      borderBottomWidth: '1px',
      boxShadow: '0 1px var(--bc-colors-whiteAlpha-100)',
      content: '""',
      w: 'full',
      left: '0',
      pos: 'absolute',
      top: '-1px',
    },
  },
  rowWrapperFirst: {
    color: 'beta.800',
    borderTopWidth: '1px',
    _before: {
      display: 'none',
    },
  },
  rowWrapperWithBtn: {
    borderColor: 'blackAlpha.300',
    borderTopWidth: '1px',
    bg: 'transparent',
    sx: {
      '&&': {
        button: {
          color: 'beta.800',
          svg: {
            bg: 'delta.500',
            color: 'beta.100',
            borderColor: 'delta.500',
          },
        },
      },
    },
  },
  tab: {
    bg: 'beta.200',
    color: 'beta.800',
    fontSize: 'xs',
    fontWeight: 'extrabold',
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.400',
    _first: { ml: '4px' },
    _last: { mr: '4px' },
    _notFirst: { borderLeftWidth: '1px', borderLeftColor: 'blackAlpha.400' },
    _selected: {
      bg: 'transparent',
      borderBottomColor: 'transparent',
      color: 'beta.800',
    },
  },
};
