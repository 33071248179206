import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  m: '6px',
  mb: 0,
  p: 0,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'var(--bc-colors-blackAlpha-50)',
};
export const CardContainer: CSSObject = {
  boxshadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500),0px 2px 4px 0px var(--bc-colors-whiteAlpha-400) inset,0px -3px 4px 0px var(--bc-colors-blackAlpha-400) inset',
  bg: 'linear-gradient(180deg, var(--bc-colors-beta-700) 0%, var(--bc-colors-beta-800) 100%)',
  borderRadius: 'xl',
  p: '1.5',
  border: 'none',
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  border: 'none',
  mx: '6px',
  borderTopColor: 'var(--bc-colors-blackAlpha-200)',
  borderTopWidth: '1px',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: 'full',
    borderTop: '1px',
    borderColor: 'var(--bc-colors-whiteAlpha-500)',
    borderStyle: 'dashed',
  },
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  display: 'flex',
  '&': {
    px: 0,
  },
  alignItems: 'start',
  '> svg': {
    color: 'beta.500',
    fill: 'beta.500',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px white inset, 0px 2px 5px 0px var(--bc-colors-blackAlpha-500)',
  },
  lineHeight: '18px',
  'p[data-cy="betOdds"]': {
    color: 'white',
  },
};
export const dividerExoticProps: DividerProps = {
  display: 'none',
};
