import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  color: 'beta.500',
  textTransform: 'uppercase',
  fontSize: ['2xl', '4xl'],
  fontFamily: 'Spectral',
  fontWeight: 'semibold',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  textAlign: 'center',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  w: ['288px', null, '420px'],
  textAlign: 'center',
  p: {
    w: '100%',
    fontWeight: 'normal',
  },
};

export const ImageNoRaces: CSSObject = {
  w: ['200px', null, '350px'],
};
