import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logo from '@/assets/fiestabet/images/logo.png';
import maintenanImg from '@/assets/fiestabet/images/maintenance_icon.png';
import errorImg from '@/assets/fiestabet/images/error_img.png';
import Title from '../components/Title';
import Description from '../components/Description';
import RefreshBtn from '../components/RefreshBtn';
import GlobalStyles from '@/layouts/SecondaryLayout/conditionals/GlobalStyles/template';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (isMaintenance: boolean): TTemplate => ({
  wrapper: <FlexPageWrapper />,
  content: {
    wrapper: <MaintenanceContainer />,
    logo: (
      <Img
        src={logo}
        alt={REACT_APP_THEME_NAME}
        alignSelf="center"
        w="40"
        mt="10"
        mb={['24', '10']}
      />
    ),
    title: (
      <Flex direction="column" alignItems="center">
        <Img
          src={isMaintenance ? maintenanImg : errorImg}
          alt={REACT_APP_THEME_NAME}
          alignSelf="center"
          w="40"
          mb="5"
        />
        <Title />
      </Flex>
    ),
    desc: <Description />,
    btn: <RefreshBtn />,
    body: <GlobalStyles />,
  },
});

export default template;
