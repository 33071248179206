import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  borderRight: 'none',
  borderLeft: 'none',
  borderTop: 'none',
  borderColor: 'blackAlpha.300',
  borderBottomWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.28)',
  pl: '0',
  py: '2',
  color: 'white',
  fontWeight: 'bold',
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'green.500' : 'white',
  fontWeight: 'normal',
  ':first-child': {
    fontWeight: 'bold',
  },
});

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const buttonMoreTableProps: CSSObject = {
  color: 'white',
};

export const NoTransactionsText: CSSObject = {
  color: 'white',
};
