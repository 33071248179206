import React from 'react';
import { Global } from '@emotion/core';
import bg from '@/assets/juicybet/images/backgrounds/bgHead.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bg}), linear-gradient(180deg, var(--bc-colors-delta-400), var(--bc-colors-delta-600))`,
          backgroundPosition: 'center bottom -50px, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover, 100% 100%',
          backgroundAttachment: 'fixed',

          '@media (max-width: 479px)': {
            background: `url(${bg}), linear-gradient(180deg, var(--bc-colors-delta-400), var(--bc-colors-delta-600))`,
            backgroundPosition: 'center bottom, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover, 100% 100%',
            backgroundAttachment: 'local',
          },
        },
      }}
    />
  );
}
