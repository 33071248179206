import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  color: 'white',
  '&': {
    a: {
      color: 'white',
      mx: '0',
      textDecoration: 'underline',
    },
    '.chakra-checkbox__control': {
      bg: 'gray.100',
      color: 'gray.700',
      border: '0',
      _disabled: {
        opacity: '0.4',
      },
      _invalid: {
        bg: 'red.100',
      },
      _hover: {
        bg: 'gray.400',
      },
      _checked: {
        boxShadow: 'none',
        _hover: {
          bg: 'gray.400',
        },
        _focus: {
          boxShadow: 'none',
        },
        _invalid: {
          bg: 'alpha.400',
        },
      },
    },
    '.chakra-form__label': {
      color: 'white',
      fontWeight: 'semibold',
      mb: '1.5',
    },
    '.chakra-input': {
      bg: 'white',
      borderColor: 'delta.600',
      borderWidth: '1px',
      color: 'black',
      fontSize: 'sm',
      h: '10',
      _placeholder: {
        color: 'black',
      },
    },
    '.chakra-input__right-element': {
      color: 'black',
      h: '10',
    },
  },
};
