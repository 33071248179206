import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.500',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  p: '2',
  w: 'fill-available',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'epsilon.500' : 'red.500',
});

export const CountdownTimerBox: CSSObject = {
  span: {
    color: 'white',
  },
};
