import { CSSObject } from '@chakra-ui/react';

export const SubHeading: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontSize: 'sm',
};

export const BoxBordered: CSSObject = {
  px: '2',
  textColor: 'black',
  maxW: 'container.md',
  pt: '0',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const SmallHeading: CSSObject = {
  mt: '2',
  color: 'white',
  fontSize: 'sm',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const ImageGamblersHelp: CSSObject = {
  w: '168px',
  minW: '168px',
  bg: 'white',
  padding: '5px',
  borderRadius: 'md',
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr',
  maxW: 'container.sm',
};

export const Link: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  textDecoration: 'underline',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mx: '2',
};

export const ButtonAction: CSSObject = {
  fontFamily: 'CarterOne',
};
