import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'roboto',
  pl: '0.5',
  color: 'beta.500',
  pos: 'relative',
  bottom: '4px',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'white',
  minW: 'unset',
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  borderRadius: 'base',
  border: 'none',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  transition: 'all .3s ease-in-out',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    color: 'blackAlpha.700',
    bgGradient: 'linear(to-b, beta.500, beta.700)',
  },
  _focus: {
    bgGradient: 'linear(to-b, alpha.200, alpha.400)',
    color: 'white',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  },
  _after: {
    opacity: '0',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
