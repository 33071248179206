import { CSSObject } from '@chakra-ui/react';
import { EResponsibleGamblingBannerLocation } from '../services/ResponsibleGamblingBanner.types';

export const TextResponsibleGamblingHeading = (
  bannerLocation: EResponsibleGamblingBannerLocation
): CSSObject => ({
  fontSize: () => {
    if (bannerLocation === EResponsibleGamblingBannerLocation.HomepageCarousel)
      return ['md', null, 'xl'];
    if (bannerLocation === EResponsibleGamblingBannerLocation.BetslipFooter)
      return 'sm';
    return 'lg';
  },
});

export const TextResponsibleGamblingCaption = (
  bannerLocation: EResponsibleGamblingBannerLocation
): CSSObject => ({
  fontSize: () => {
    if (bannerLocation === EResponsibleGamblingBannerLocation.BetslipFooter)
      return 'xs';
    if (bannerLocation === EResponsibleGamblingBannerLocation.HomepageCarousel)
      return ['sm', null, 'md'];
    return 'md';
  },
  mb:
    bannerLocation === EResponsibleGamblingBannerLocation.HomepageCarousel
      ? ['4', null, '0']
      : '0',
});
