import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { CustomButtonProps } from '@/components/Button/Button';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px',
  borderColor: 'blackAlpha.400',
  py: '2',
  w: 'fill-available',
  px: '1',
  color: 'white',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
});

export const FlexBetslipHeading: CSSObject = {
  bg: 'delta.400',
  h: '12',
  borderBottom: [null, null, null, '1px'],
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',

  '&&': {
    borderBottomColor: 'blackAlpha.300',
  },
};

export const BetslipTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'gamma.400',
  fontFamily: 'Chewy',
  textShadow: '0px 1px 5px rgba(0, 0, 0, 0.30)',
  fontSize: 'lg',
};

export const closeIcon: TCloseIcon = {
  boxSize: '8',
  as: CloseCircle,
  color: 'white',
};

export const CloseIconButton: CustomButtonProps = {
  border: 'none',
  bg: 'transparent',

  _hover: {
    border: 'none',
    bg: 'none',
  },
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'gamma.400',
  boxSize: '5',
};

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const BetLengthIndicator: CSSObject = {
  bg: '#FF6362',
  color: 'alpha.50',
  fontSize: 'xs',
  borderRadius: 'base',
  borderWidth: '2px',
  borderColor: 'alpha.50',
  fontFamily: 'roboto',
  fontWeight: 'bold',
  boxSize: '6',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});
