import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: 'md',
  },
  headerIconButton: {
    color: 'white',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'white',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    sx: { '&&&': { color: 'alpha.300' } },
    h: '1.25em',
    ml: '1',
    w: 'auto',
  },
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'transparent',
    boxShadow: 'none',
  },
  scrollButtonGroupV2: {
    variant: 'unstyled',
    flex: 1,
    position: 'relative',
    minH: '34px',
  },
  toMarketButton: {
    borderColor: 'beta.400',
    h: '100%',

    sx: {
      '&[data-active]': {
        boxShadow: 'none',
        color: 'alpha.700',
      },
    },

    _last: { mr: 2 },
  },
  toMarketButtonV2: {
    color: 'white',
    fontSize: 'xs',
    textTransform: 'uppercase',
    minW: 'fit-content',
    fontWeight: 'bold',
    cursor: 'pointer',

    sx: {
      '&&': {
        px: 3.5,
        ml: 0,
        h: 'auto',
      },
      '&[data-active]': {
        color: 'beta.400',
      },

      '.chakra-button__icon': {
        margin: 0,
      },
    },

    _last: { mr: 20 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bg: 'white',
    border: '0',
    borderRadius: 'base',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    overflow: 'hidden',
  },
  accordionButton: {
    bgGradient: 'linear(to-b, alpha.600, alpha.800)',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
    _hover: {
      bgGradient: 'linear(to-b, alpha.700, alpha.700)',
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  sgmBtn: {
    sx: {
      svg: {
        filter: 'drop-shadow(0px 2px 2px rgba(255, 255, 255, .3))',
        color: 'alpha.600',
        fill: 'alpha.600',
      },
    },
    h: '8',
    px: '2',
    mt: '0.5',
    bgGradient: 'linear(to-b, beta.400, beta.500)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30), 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(255, 255, 255, 0.20), 0px -3px 4px 0px rgba(0, 0, 0, 0.20)',
    borderRadius: 'md',
    _hover: {
      svg: {
        filter: 'drop-shadow(0px 1px 1px rgba(255, 255, 255, 0.50))',
        color: 'beta.400',
        fill: 'beta.400',
      },
    },
  },
  sgmBtnActive: {
    boxShadow: '0px 2px 4px 0px #00000040 inset, 0px 1px 0px 0px #FFFFFF1A',

    sx: {
      svg: {
        filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
        color: 'beta.400',
        fill: 'beta.400',
      },
      px: '2',
      mt: '0.5',
      ml: '1',

      '&&': { bg: 'alpha.600' },
    },

    _after: { display: 'none' },
  },
  wrapperScrollSgm: {
    gap: [null, null, 1],
    alignItems: 'center',
  },
  sgmLogoV2: {
    w: '10',
    h: 'auto',
  },
  cancelButton: {
    variant: 'solid',
    px: '3',
  },
  clearButton: {
    variant: 'solid',
    ml: '3',
    px: '3',
  },
};
