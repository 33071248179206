import { useQueries } from '@tanstack/react-query';
import { keys } from '../api/api.keys';
import { queryNTG } from '../api/nextToJump/nextToJump';
import { TNextToGo, TNextToGoParams } from '../api/nextToJump/nextToJump.types';
import { sortByRaceStart } from '@/helpers/sortRaces';
import { ESportType } from '../lib/DBModels';

type TUseNTG = {
  sports?: (ESportType | 'all')[];
  params?: TNextToGoParams;
};

/**
 * NTG polling
 */
export const useNTG = ({ sports, params }: TUseNTG = {}) => {
  const sportTypes = sports || [
    ESportType.Cricket,
    ESportType.Soccer,
    ESportType.Basketball,
  ];

  const queries = useQueries({
    queries: sportTypes.map((t) => {
      const paramValues = Object.values(params ?? {});

      return {
        queryKey: [keys.ntg, ...paramValues, t],
        queryFn: () =>
          queryNTG({
            sport_name: t === 'all' ? undefined : t,
            limit: 5,
            ...params,
          }),
        staleTime: 300000,
        refetchInterval: 60 * 1000,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: 'always' as any,
      };
    }),
  });

  /**
   * TODO:
   * Update TS to 4.9 so we can use satisfies in replace of "as".
   */
  return {
    queries,
    dataSorted: [...(queries.flatMap((q) => q.data) ?? [])].sort((a, b) =>
      sortByRaceStart(a?.match_start_time, b?.match_start_time)
    ) as TNextToGo[],
    isInitialLoading: queries.some((q) => q.isInitialLoading),
  };
};
