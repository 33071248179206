import React from 'react';
import { Hide, Image, Show } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import { FlexWrapper, BoxHeadingWrapper } from '../styles/Featured.styles';
import baseTemplate from './template.default';
import promoHeader from '../../../../../../assets/betroyale/images/homeFeaturedSport/promoHeader.png';
import promoHeaderMobile from '../../../../../../assets/betroyale/images/homeFeaturedSport/promoHeaderMobile.png';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  featureCard: {
    wrapper: <BoxHeadingWrapper />,
    header: (
      <>
        <Show above="lg">
          <Image src={promoHeader} mx="auto" h="120px" />
        </Show>
        <Hide above="lg">
          <Image src={promoHeaderMobile} h="145px" />
        </Hide>
      </>
    ),
    headingVs: <BoxHeadingWrapper />,
    feature: <FeaturedMatch />,
  },

  ...(baseTemplate?.first as TTemplate),
};

export default template;
