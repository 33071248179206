import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ContentHeadingText,
  TextHeadingColor,
} from '../styles/LocationNotAllowed.styles';

export default function Heading() {
  return (
    <ContentHeadingText>
      <FormattedMessage
        id="onboarding.locationnotallowed.header"
        values={{
          b: (string: string) => (
            <TextHeadingColor as="span">{string}</TextHeadingColor>
          ),
        }}
      />
    </ContentHeadingText>
  );
}
