import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapper: BoxProps = {};
export const FlexBetsPlacedContainer: CSSObject = {
  h: '24',
  fontSize: 'md',
  justifyContent: 'center',
  flexDir: 'column',
  gap: '1',
  bg: 'delta.700',
  borderRadius: 'lg',
  margin: '2',
  width: '94%',
};
export const TextBetsPlaced: CSSObject = {
  fontSize: 'lg',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'white',
  width: '95%',
  textAlign: 'center',
};
export const IconBetsPlaced: CSSObject = {
  '&&': {
    display: 'none',
  },
};
