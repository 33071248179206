import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '3',
  py: '2.5',
  m: 'auto',
  h: '44px',
  w: 'container.xl',
  bg: [null, 'epsilon.700', 'blackAlpha.500'],
  borderRadius: [null, null, 'xl'],
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  '& > div:last-of-type': { mr: '0' },
};

export const LinkItem: CSSObject = {
  bg: 'alpha.500',
  w: '162px',
  borderRadius: 'lg',

  _hover: {
    bg: 'alpha.600',
  },

  '.state-ended': {
    borderRightRadius: 'lg',
  },

  '.state-inprogress': {
    bg: 'delta.500',
    color: 'delta.800',
    borderRightRadius: 'lg',
  },

  '.chakra-badge.state-prior': {
    bg: 'delta.500',
    color: 'delta.800',
    borderRightRadius: 'lg',
  },
};

export const RaceNumber: CSSObject = {
  color: 'white',
};
export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '90px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'white',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  w: '4',
  h: 'full',
  bg: 'alpha.500',
  color: 'white',
  p: '0',
  borderRadius: 'md',

  '& > svg': { boxSize: '6' },

  _hover: {
    bg: 'alpha.600',
  },
};
