import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  mb: '3',
  mx: ['-2', '0'],
  px: ['2', '0'],
  mt: '1',
  gap: '1',
  borderEnd: '1px solid',
  borderEndColor: 'gray.200',
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};
