import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'alpha.700',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
    fontWeight: 'normal',
    fontFamily: 'accent',
    color: 'white',
  },
  depositIconProps: {
    color: 'gamma.400',
    boxSize: '9',
  },
};
