import { SRMultiStylesSchema } from './SRMulti.styles';

export const srMultiStyles: SRMultiStylesSchema = {
  multiContainer: {
    mx: '4',
    p: '2',
    bg: '#495564',
    borderRadius: 'md',
    mb: '2',
  },
  multiRunnerContainer: {
    justifyContent: 'space-between',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    p: '3',
    my: '2',
    borderRadius: 'md',
  },
  buttonLabel: {
    color: 'white',
  },
  propButton: {
    w: '50px',
  },
  columnHeadings: {
    w: ['45px', null, '64px'],
    textAlign: 'center',
    fontSize: 'xs',
    color: 'gray.700',
    fontWeight: 'bold',
    mr: '2',
  },
  headingLabel: {
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'bold',
  },
  headingContainer: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'bold',
    marginRight: '1',
  },
  runnerList: {
    borderRadius: 'md',
    bg: 'white',
    px: '2',
    pt: '4',
    pb: '2',
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
  },
  headingStacker: {
    rowGap: '2',
  },
};
