import React, { FC } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Carousel as BCCarousel } from 'react-responsive-carousel';
import {
  Dot,
  DotContainer,
  SlideWrapper,
  CarouselWrapper,
} from './styles/Carousel.styles';
import ResponsibleGamblingBanner from '../ResponsibleGamblingBanner/ResponsibleGamblingBanner';

import { CarouselProps } from './types';
import { EResponsibleGamblingBannerLocation } from '../ResponsibleGamblingBanner/services/ResponsibleGamblingBanner.types';

const Carousel: FC<CarouselProps> = ({
  slides,
  interval = 8000,
  axis = 'horizontal',
  transitionSpeed = 750,
  dataCy = '',
  displayResponsibleGamblingMessage = true,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const slidesToDisplay = displayResponsibleGamblingMessage
    ? [
        ...slides,
        <ResponsibleGamblingBanner
          key="responsible-gambling"
          bannerLocation={EResponsibleGamblingBannerLocation.HomepageCarousel}
        />,
      ]
    : slides;
  return (
    <CarouselWrapper data-cy={dataCy}>
      <BCCarousel
        autoPlay
        infiniteLoop
        interval={interval}
        transitionTime={transitionSpeed}
        axis={axis}
        showIndicators
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        renderIndicator={(handler, selected, index) => {
          const key = `dot_${index}`;
          const dotDataCy = `${dataCy}_${key}`;

          return (
            !isMobile && (
              <DotContainer
                numberOfSlides={slidesToDisplay.length}
                index={index}
              >
                <Dot
                  key={key}
                  data-cy={dotDataCy}
                  onClick={handler}
                  selected={selected}
                />
              </DotContainer>
            )
          );
        }}
        renderItem={(item) => <SlideWrapper>{item}</SlideWrapper>}
      >
        {slidesToDisplay}
      </BCCarousel>
    </CarouselWrapper>
  );
};

export default Carousel;
