import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'beta.200',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  border: 'none',
  px: '2',
  py: '4',
  mb: '2',
  color: 'white',
  _hover: {
    bg: 'alpha.700',
    color: 'white',
  },
  _focus: {
    bg: 'alpha.700',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
