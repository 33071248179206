import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: '5px',
    bgGradient: 'linear(to-b,  #636EC7, #414984)',
    boxShadow: `0px 0px 3px 0px rgba(255, 255, 255, 0.60) inset`,
    minW: '90px',
  },
  btnGroupText: {
    fontSize: 'sm',
    color: 'white',
    fontWeight: 'black',
    fontFamily: 'roboto',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  },
  btnGroupIcon: {
    color: 'white',
  },
  name: {
    color: 'gray.700',
  },
};
export default styles;
