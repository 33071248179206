import { CSSObject, ButtonProps } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  color: 'black',
};

export const BoxTakeActionTable: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 'md',
  p: '2',
  bg: 'gamma.200',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const TextTakeAction: CSSObject = {
  color: 'gamma.900',
  fontWeight: 'medium',
};

export const TakeActionHeading: CSSObject = {
  color: 'gamma.900',
  fontWeight: 'bold',
  fontSize: 'sm',
};

export const buttonActionProps: ButtonProps = {
  fontSize: 'sm',
  h: '43px',
  textShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.35)',
  borderColor: 'white',
};
