import { betRecoveryBetSlipHandler } from '@/api/betslip/betslip.handler';
import { statementsHandler } from '@/api/punter/statements/statements.handler';
import { sportDetailsMarketGroupsHandlers } from '@/api/sportDetailsMarketGroups/sportDetailsMarketGroups.handlers';
import { sportDetailsMarketsHandlers } from '@/api/sportDetailsMarkets/sportDetailsMarkets.handlers';
import { sportDetailsPropositionsHandlers } from '@/api/sportDetailsPropositions/sportDetailsPropositions.handlers';
import { queryCompetitionsHandlers } from '@/api/sports/competitions.handlers';
import { sgmPricingHandler } from '@/api/sports/pricing/sgm.handler';
import { upcomingMatchesHandlers } from '@/api/sports/upcomingMatches.handlers';
import { upcomingMatchesBySportHandlers } from '@/api/sports/upcomingMatchesBySport.handlers';
import { handlerBetSlipSubmitFailureOddsChanged } from '@/components/Betslip/Services/Betslip.mocks';

export const browserHandler = [
  betRecoveryBetSlipHandler,
  handlerBetSlipSubmitFailureOddsChanged,
  ...queryCompetitionsHandlers,
  ...sgmPricingHandler,
  ...sportDetailsMarketGroupsHandlers,
  ...sportDetailsMarketsHandlers,
  ...sportDetailsPropositionsHandlers,
  ...statementsHandler,
  ...upcomingMatchesHandlers,
  ...upcomingMatchesBySportHandlers,
];
