import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { useFilters } from '../../services/Filters.hooks';
import {
  FlexFilterWrapper,
  FlexRaceTypeWrapper,
} from './styles/Filters.styles';
import Location from './Location';
import RaceTypeItem from './RaceTypeItem';

export default function Filters() {
  const { offeredRaces, filters, setFilters, query } = useFilters();

  return (
    <FlexFilterWrapper>
      {(() => {
        if (query.isInitialLoading) return <Skeleton w="140px" h="20px" />;

        return (
          <FlexRaceTypeWrapper>
            {offeredRaces?.map((t) => (
              <RaceTypeItem
                key={`r-t-filters-${t?.replaceAll(' ', '')}`}
                raceType={t}
                onClick={() => setFilters(t)}
                selected={filters.includes(t)}
              />
            ))}
          </FlexRaceTypeWrapper>
        );
      })()}

      <Location />
    </FlexFilterWrapper>
  );
}
