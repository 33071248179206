import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'column',
  },
  racingBtnsContainer: {
    gap: '1.5',
    w: 'full',
    justifyContent: ['space-evenly', null, 'space-around'],
    flexDirection: ['column', null, 'row'],
    pl: ['1', null, '0'],
    pr: ['3', null, '0'],
  },
  sportBtnsContainer: {
    flexDirection: 'row',
    w: ['100dvw', null, 'full'],
    maxW: ['100dvw', null, 'full'],
    justifyContent: ['unset', null, 'space-around'],
    overflowX: ['scroll', null, 'hidden'],
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    mt: '1',
    mb: '3',
    pl: ['1', null, '0'],
    pr: ['3', null, '0'],
  },
  linkIconBox: {
    mx: '1',
    mt: ['1', null, '4'],
    w: 'full',
    borderRadius: 'md',
    border: '2px',
    borderColor: 'alpha.500',
    bg: 'transparent',
    bgGradient: 'linear(to-b, beta.500, beta.600)',
  },
  linkBox: {
    mx: '1',
    mt: ['1', null, '4'],
    w: 'full',
    borderRadius: 'md',
    border: '2px',
    borderColor: 'alpha.500',
    bg: 'transparent',
    bgGradient: 'linear(to-b, beta.500, beta.600)',
  },
  linkOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'base',
    border: '2px',
    borderColor: 'black',
    p: '3',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px 0px 1px 0px rgba(0, 0, 0, 0.25)',
  },
  linkBoxText: {
    color: 'alpha.500',
    textAlign: 'center',
    textShadow: '0px 3px 5px rgba(0, 0, 0, 0.35)',
    fontFamily: 'CrimsonPro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    ml: '2',
  },
  racingIcon: {
    w: '28.5px',
    h: '28.5px',
    color: 'alpha.500',
    filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.35))',
  },
  sportIcon: {
    w: '28.5px',
    h: '28.5px',
    color: 'alpha.500',
    filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.35))',
  },
  racingHeader: {
    display: 'none',
  },
  sportsHeader: {
    display: 'none',
  },
};
