import { Skeleton } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { getIconBySportName } from '@/helpers/utils';
import { formatVenueNameNextToRace } from '../../../lib/RaceNames';
import Countdown from '../../Countdown/Countdown';
import {
  FlexDetails,
  LinkItem,
  FlexItemsWrapper,
  Icon,
  CountdownTimer,
  RaceNumber,
} from '../styles/NextToJumpBanner.styles';
import { useNTJBanner } from '../services/NextToJumpBanner.hooks';

const List = forwardRef((props, ref) => {
  const { ntj, isLoading } = useNTJBanner();

  return (
    <FlexItemsWrapper ref={ref}>
      <AnimatePresence>
        {(() => {
          if (isLoading) {
            return [...new Array(12)].map((_, i) => (
              <Skeleton key={i} sx={{ minW: '145px', h: '7', flex: 1 }} />
            ));
          }

          return ntj?.map((race) => {
            if (race && race.start_time) {
              return (
                <motion.div
                  key={`ntj-banner-${race.race_id}-motion`}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{ height: '100%' }}
                >
                  <LinkItem
                    key={`ntj-banner-${race.race_id}`}
                    to={`/racing/${race.race_type}/${race.venue_name}/R${race.race_number}?venueId=${race.venue_id}&raceId=${race.race_id}&meetingDate=${race.meeting_date}`}
                  >
                    <Icon name={getIconBySportName(race.race_type) ?? ''} />

                    <RaceNumber>{`R${race.race_number}`}</RaceNumber>

                    <FlexDetails>{formatVenueNameNextToRace(race)}</FlexDetails>

                    <CountdownTimer>
                      <Countdown
                        eventTime={race.start_time}
                        dateFormat="HH:mm"
                      />
                    </CountdownTimer>
                  </LinkItem>
                </motion.div>
              );
            }

            return null;
          });
        })()}
      </AnimatePresence>
    </FlexItemsWrapper>
  );
});

export default List;
