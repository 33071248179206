import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  w: ['47px', null, '16'],
  fontSize: ['xs', null, 'sm'],
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 0px 0px 5px #89D255 inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
  _disabled: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'white',
    bg: 'gray.300',
    borderRadius: 'md',
    color: 'blackAlpha.500',
    fontWeight: 'bold',
    fontSize: 'sm',
    boxShadow:
      '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
    cursor: 'not-allowed',
    textShadow: 'unset',
    pointerEvents: 'none',
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: '100%',
  maxH: 'calc(var(--bc-space-3) + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  bgGradient: 'linear(to-r, beta.600, beta.300)',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'orange.700',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'orange.700',
  textShadow: 'none',
};
