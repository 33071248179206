import { withDefaultProps } from '@chakra-ui/react';
import { Footer } from './customComponents/footer';
// import base from '../base';
import { SideNav } from './customComponents/sideNav';
import { Button } from './components/button';
import { styles } from './styles';
import { colors } from './foundations/colors';
import { Header } from './customComponents/header';
import { FormLabel } from './components/formLabel';
import { Input } from './components/input';
import { fontFace } from './fontFace';
import { fonts } from './foundations/fonts';
import { Breadcrumb } from './components/breadcrumb';
import { Checkbox } from './components/checkbox';
import { Container } from './components/container';
import { Select } from './components/select';
import { images } from './images';
import { Skeleton } from './components/skeleton';

export default [
  {
    components: {
      Container,
      Button,
      FormLabel,
      Input,
      Select,
      Breadcrumb,
      Checkbox,

      // Custom components
      Footer,
      SideNav,
      Header,
      Skeleton,
    },
    fonts,
    colors,
    fontFace,
    images,
    styles,
  },
  withDefaultProps({
    defaultProps: {
      size: 'sm',
      variant: 'filled',
    },
    components: ['Input', 'NumberInput', 'PinInput', 'Select', 'Textarea'],
  }),
];
