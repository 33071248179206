import { HomeLinksSchema } from './HomeLinks.styles';
import scrollLeft from '../../../../../../assets/goldenbet888/images/homeTheming/scrollLeft.png';
import scrollRight from '../../../../../../assets/goldenbet888/images/homeTheming/scrollRight.png';
import homeLinkBgMb from '../../../../../../assets/goldenbet888/images/homeTheming/homeLinkBgMb.png';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    pos: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowX: ['scroll', null, 'unset'],
    mt: ['0', null, '3'],
    mb: ['0.5', null, '2'],
    pt: ['7', null, '2'],
    pb: ['9', null, '4'],
    maxW: ['100dvw', null, 'unset'],
    mx: ['0', null, '1'],
    px: ['4', null, '5'],
    sx: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    bg: [
      `url(${homeLinkBgMb})`,
      null,
      'linear-gradient(180deg, #EE342C, #B81B15)',
    ],
    bgPos: ['top center', null, 'unset'],
    bgRepeat: 'no-repeat',
    bgSize: '100% 120px',
    boxShadow: [
      'unset',
      null,
      '0px 5px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #FFC648 inset, 0px -1px 0px 0px rgba(255, 255, 255, 0.2)',
    ],
    borderTop: ['unset', null, '6px solid'],
    borderBottom: ['unset', null, '6px solid'],
    borderTopColor: ['unset', null, '#EE342C'],
    borderBottomColor: ['unset', null, '#B81B15'],
    gap: 1,
    _before: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      top: '-14px',
      left: '-5px',
      h: '100px',
      w: '29px',
      bgImage: `url(${scrollLeft})`,
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      bgPos: 'center',
      zIndex: '1',
    },
    _after: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      top: '-20px',
      right: '-5px',
      h: '111px',
      w: '31px',
      bgImage: `url(${scrollRight})`,
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      bgPos: 'center',
      zIndex: '1',
    },
  },
  container: {
    display: 'flex',
    flexDir: 'row',
    gap: 1,
    mt: '1.5',
  },
  racingBtnsContainer: {
    display: 'contents',
  },
  sportBtnsContainer: {
    display: 'contents',
  },
  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gamma.600',
    boxSize: '22px',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gamma.600',
    boxSize: '22px',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
    mr: 0,
  },
  linkIconBox: {
    minW: 'fit-content',
    px: '9px',
    overflow: 'hidden',
    minH: '42px',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.400, alpha.200)',
    border: '1px solid',
    borderColor: 'alpha.800',
    boxShadow: '0px 0px 0px 1px #ED342C, 0px 0px 0px 1px #ED342C inset',
    _hover: {
      bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    },
    sx: {
      '&&': {
        flex: '1 0 0',
        gap: '1',
        display: {
          base: 'none',
          md: 'flex',
          xl: 'flex',
        },
        '&:nth-child(-n+5)': {
          display: { base: 'flex' },
        },
      },
    },
  },
  linkBox: {
    pl: '9px',
    pr: '1px',
    minH: '42px',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.400, alpha.200)',
    border: '1px solid',
    borderColor: 'alpha.800',
    boxShadow: '0px 0px 0px 1px #ED342C, 0px 0px 0px 1px #ED342C inset',
    overflow: 'hidden',
    _hover: {
      bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    },
  },
  linkOverlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    gap: '2',
  },
  linkBoxText: {
    display: 'none',
  },
  racingHeader: {
    display: 'none',
  },
  sportsHeader: {
    display: 'none',
  },
  sportsHeaderTile: {},
  linkOverlayOverride: {},
  sportIconOverride: {},
};
