import { CSSObject } from '@chakra-ui/react';

export const FlexPageWrapper: CSSObject = {
  px: '4',
  pb: '3',
  mt: '1',
  color: 'white',
  '&&': {
    a: {
      color: 'beta.400',
    },
  },
};
