import { CSSObject } from '@chakra-ui/react';

export const OddsText: CSSObject = {
  fontSize: 'md',
  color: 'black',
};

export const MultiCardContainer: CSSObject = {
  borderBottom: '2px dashed',
  borderColor: 'gray.200',
  _first: {
    borderTop: '2px dashed',
    borderColor: 'gray.200',
    '&::before, &::after': {
      position: 'absolute',
      height: '11px',
      width: '11px',
      background: 'beta.700',
      content: `""`,
      borderRadius: 'full',
      top: ['-12%', null, null, null, null],
    },
    '&::before': {
      left: '-3',
    },
    '&::after': {
      right: '-3',
    },
  },
  _last: {
    borderBottom: 'none',
  },
  '&::before, &::after': {
    position: 'absolute',
    height: '11px',
    width: '11px',
    background: 'beta.700',
    content: `""`,
    borderRadius: 'full',
    top: ['-15%', null, null, null, null],
  },
  '&::before': {
    left: '-3',
  },
  '&::after': {
    right: '-3',
  },
};

export const OddsMultiCard: CSSObject = {
  fontSize: 'md',
  color: 'black',
  fontWeight: 'semibold',
};
