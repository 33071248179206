import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from './RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'gray.400', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'gray.400'] },
};

export const FlexHeading: CSSObject = {
  bg: 'alpha.800',
  color: 'white',
  top: ['12', '84px'],
  position: 'unset',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'alpha.700',
  bg: 'white',
  borderRadius: 'full',
  p: '1.5',
};

export const BoxPageWrapper: CSSObject = {
  mx: ['-4', '-7'],
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const LinkRaceItem = ({
  isActive,
  isToteMultiAvailable,
}: {
  isActive?: boolean;
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'gray.200' : 'transparent',
  color: isActive ? [null, null, 'black'] : [null, null, 'gray.700'],
  borderColor: 'gray.300',
  _hover: {
    background: isToteMultiAvailable
      ? 'gray.300'
      : ['transparent', null, 'gray.300'],
  },
});

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'row-reverse',
  columnGap: '4',
};
