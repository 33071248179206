import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};

export const BoxTakeActionTable: CSSObject = {
  bg: '#fff',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  border: '0',
  p: '2',
};

export const TextTakeAction: CSSObject = {
  color: 'alpha.900',
  fontWeight: '500',
  fontSize: 'xs',
};

export const TakeActionHeading: CSSObject = {
  color: 'alpha.900',
  fontWeight: '700',
  fontSize: 'sm',
};
