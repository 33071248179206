import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'whiteAlpha.900' },
  flexWrapper: {
    flexDir: 'row',
  },
  checkbox: {
    my: 'auto',
  },
};
export const TabContainer: CSSObject = {
  borderBottom: '0',
  bg: 'beta.700',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.2) inset',
  borderRadius: 'md',
  px: '4',
};
export const buttonAccountTabOptionProps: CustomButtonProps = {
  variant: 'tab',
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  fontSize: 'sm',
  borderRadius: 'none',
  fontWeight: 'normal',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  mb: 'none',
  sx: {
    '&[aria-selected="true"]': {
      color: 'alpha.400',
      fontWeight: 'normal',
      border: 'none',
      borderBottom: '3px solid',
      borderBottomColor: 'alpha.400',
      pt: '3px',
    },
  },
  _active: {},
};
