export type TGetFatZebraToken = {
  message: string;
  token: string;
};

export type TFZCustomer = {
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: string;
  city?: string;
  postcode?: string;
  state?: string;
  country: 'AU';
};

export type TFZSCAData = {
  aav: string;
  cavv?: string;
  par?: string;
  sli?: string;
  xid?: string;
  ver?: string;
  directoryServerTxnId?: string;
  threedsVersion?: string;
};

export type TAddCardRequest = {
  token: string;
};

export type TAddCardBody = {
  last_4: string;
  card_holder: string;
  card_token: string;
  card_expiry: string;
  card_type: string;
  alias?: string;
};

export type TResponseCard = {
  card_id: string;
  card_token: string;
  card_expiry: string;
  card_type: string;
  card_bin?: string;
  last_4: string;
};

export type TResponseCards = TResponseCard[];

export type TDepositResponse = {
  successful: boolean;
  response: {
    authorization: string;
    id: string;
    card_number: string;
    card_holder: string;
    card_expiry: string;
    card_token: string;
    card_type: string;
    card_category: string;
    card_subcategory: string;
    amount: number;
    decimal_amount: number;
    successful: boolean;
    message: string;
    reference: string;
    currency: string;
    transaction_id: string;
    settlement_date: string;
    transaction_date: string;
    response_code: string;
    captured: boolean;
    captured_amount: number;
  } | null;
  errors: string[] | null;
  test: boolean;
};

export type TFZPayment = {
  type: string;
  detail: {
    message: string;
    data: {
      transactionId: string;
      responseCode: string;
      message: string;
      amount: number;
      currency: string;
      reference: string;
      cardNumber: string;
      cardHolder: string;
      cardExpiry: string;
      cardType: string;
      verification: string;
    };
    errors: (string | null)[];
  };
};

export type TFZSCAResponse = {
  cavv?: string;
  par?: string;
  sli?: string;
  xid?: string;
  ver?: string;
  directory_server_txn_id?: string;
  threeds_version?: string;
};

export type TFZTokenizationSuccess = {
  detail: {
    message: string;
    data: {
      token: string;
    };
  };
};

export type TFZSCAError = {
  detail?: {
    errors: string[];
    data: {
      errorCode?: string;
    };
  };
};

export type TFZSCASuccess = {
  detail: {
    message: string;
    errors: string[];
    data: TFZSCAData;
  };
};

export type TDepositLimit = {
  deposit_limit: number;
  remaining: number;
};

export enum EDepositMode {
  Quick = 'Quick',
  Standard = 'Standard',
}

export enum EDepositStatus {
  Abandoned = 'Abandoned',
  Approved = 'Approved',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Unknown = 'Unknown',
}

export type TDepositData = {
  amount: number | string;
};

export type TCheckDepositResponse = {
  message: string;
  code: string;
  deposit_id: string;
  verification: string;
  currency?: string;
};

export enum ECardTypes {
  Mastercard = 'mastercard',
  Visa = 'visa',
  AmericanExpress = 'americanexpress',
  // More to add.
}

export type TCardDetails = {
  card_holder: string;
  card_expiry: string;
  card_token: string;
  card_type: ECardTypes;
  card_bin: string;
  last_4: string;
};

export type TMakeDepositData = {
  deposit_id: string;
  status: string;
  card_details?: TCardDetails;
  reason?: string;
  details?: any;
};

export type TMakeDepositResponse = {
  message: string;
  code: string;
  deposit_id: string;
  is_first_deposit?: boolean;
  verification?: string;
  currency?: string;
};

export type TPayData = {
  card_id?: string;
  card_token?: string;
  amount: number;
  deposit_id: string;
  extra_data?: TFZSCAResponse;
  details?: TFZSCASuccess['detail'];
};

export type TPayResponse = {
  message: string;
  is_first_deposit: boolean;
};
