import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontWeight: 'normal',
  fontSize: '2xl',
  textTransform: 'capitalize',
  fontFamily: 'Chewy',
  color: 'gamma.400',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.50)',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  mb: ['1', null, '0'],
};
