import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  display: 'flex',
  borderRadius: 'base',
  bg: 'beta.500',
  color: 'white',
  fontSize: 'xs',
  alignItems: 'center',
  border: '2px',
  borderColor: 'white',
  w: '12',

  sx: {
    '&&': {
      '&:active, &[data-active="true"]': {
        bg: 'beta.200',
        color: 'beta.800',
        boxShadow: 'inset 0px 2px 4px #A97F81',

        '&:hover': {
          color: 'beta.900',
          bg: 'beta.300',
        },
      },
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
      p: '2',
    },
    '&:hover': {
      color: 'white',
      bg: 'beta.800',
    },
    '&:disabled': {
      borderRadius: 'base',
      '&&': {
        boxShadow: 'none',
        color: 'gray.600',
        bg: 'blackAlpha.200',
      },
      pointerEvents: 'none',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: 'calc(100% + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  background: 'alpha.700',
  borderTop: '1px solid rgba(255, 255, 255, 0.10)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
};

export const favouriteIconProps: CSSObject = {
  color: 'white',
  boxSize: '2',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '8px',
  color: 'white',
  textShadow: 'none',
};
