import { SegmentedSchema } from '@/views/sports/components/Segmented/styles/Segmented.styles';

export const segmentedStyles: SegmentedSchema = {
  segmentWrapper: {
    bg: 'blackAlpha.600',
    borderRadius: 'md',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.35) inset',
    p: '0.5',
    spacing: '0.5',
    variant: 'segment',
  },
};
