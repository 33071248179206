import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { keys } from '../api.keys';
import { queryOfferings } from './offerings';
import { TOfferingsResponse, TQueryOfferingsParams } from './offerings.types';

type TQueryHook = {
  key?: any[];
  options?: UseQueryOptions<TOfferingsResponse>;
  params: TQueryOfferingsParams;
};

export const useQueryOfferings = ({
  key = [],
  options = {},
  params,
}: TQueryHook = {}) => {
  const query = useQuery(
    [keys.offerings, ...key, params],
    () => queryOfferings(params),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    {
      staleTime: 60 * 5000, // 5 minutes
      ...(options as any),
    }
  );

  return query;
};
