import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  gap: '1',
  color: 'gamma.500',
};

export const FooterBodyText: CSSObject = {
  color: 'rgba(255, 255, 255, 0.48)',
  fontWeight: 'medium',

  span: {
    color: 'white',
  },
};

export const DeductionsText: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontSize: '2xs',
};

export const MoreIcon: CSSObject = {
  color: 'gamma.500',
};

export const BoxMoreText: CSSObject = {
  mr: '1',
  color: 'gamma.500',
  fontSize: 'xs',
};

export const DividerMulti: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.16)',
  borderWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.28)',
};

export const toteMultiGrid: CSSObject = {
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '6',
  display: 'grid',
};

export const toteMultiGridText: CSSObject = {
  fontSize: 'xs',
  w: 'full',
  color: 'white',
  mb: '2',
  '&.raceNumber': {
    borderBottom: '1px dashed',
    borderColor: '#00000040',
    boxShadow: '0px 1px 0px 0px #FFFFFF1A',
  },
};