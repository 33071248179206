import { CSSObject } from '@chakra-ui/react';

export const GridWrapper: CSSObject = {
  position: 'relative',
  gridTemplateColumns: ['1fr', '300px 460px'],
  gridTemplateRows: ['336px 1fr', '1fr'],
  gridAutoFlow: [null, 'row'],
  gridTemplateAreas: [
    `
        'aside'
        'content'
      `,
    `
        'aside content'
      `,
  ],
  minH: ['full', null, '720px'],
  w: ['100dvw', null, 'unset'],
  boxShadow: [null, null, '0px 8px 30px rgba(0, 0, 0, 0.2)'],
};

export const BoxAside: CSSObject = {
  minH: 'full',
  borderLeftRadius: [null, null, 'lg'],
};

export const CloseButton: CSSObject = {
  position: 'absolute',
  right: ['4', null, '10'],
  top: ['4', null, '10'],
  border: '2px',
  p: '0.5',
  boxSize: '6',
  bg: ['transparent', null, 'white'],
  alignSelf: ['flex-start', null, 'unset'],
  borderColor: ['white', null, 'beta.700'],
  color: ['white', null, 'beta.700'],
};

export const BoxContent = (): CSSObject => ({
  bg: 'white',
  px: ['2', null, '10'],
  pt: ['4', null, '10'],
  pb: ['8', null, '10'],
  h: 'full',
  color: 'gray.500',
  fontWeight: 'semibold',
  fontSize: 'xs',
  display: 'flex',
  flexDir: 'column',
  justifyContent: ['flex-start', null, 'center'],
  borderRightRadius: [null, null, 'lg'],
  borderTop: ['3px solid', 'none', 'none'],
  borderTopColor: ['alpha.700', 'none', 'none'],

  '.chakra-form__label': {
    color: 'gray.500',
    fontWeight: 'semibold',
    fontSize: 'xs',
    mb: '1.5',
  },

  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.500',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'white',
    borderColor: 'gray.300',
    color: 'gray.700',
  },

  '.react-date-picker__inputGroup': {
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: 'sm',
  },

  '.chakra-input, .chakra-select, .react-date-picker__wrapper': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    h: '9',
    _placeholder: {
      fontSize: 'xs',
    },
  },
  '#title-dropdown-select': {
    borderRadius: 'md',
  },
});
