import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    border: '1px',
    borderColor: 'beta.300',
    color: 'beta.300',
    minW: '50px',
    borderRadius: 'sm',
  },
};
