import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import headerLogo from '@/assets/templebet/images/logo/header-logo.png';

export const BoxBallance: CSSObject = {
  bg: 'delta.800',
  border: 'none',
  borderColor: 'transparent',
  borderBottomRightRadius: '0',
  borderBottomLeftRadius: '0',
  '> div:first-of-type': {
    color: 'beta.500',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
  '> div:last-of-type': {
    color: 'white',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
};

export const PopoverBodyQA: CSSObject = {
  border: 'none',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  color: 'beta.500',
  fontWeight: 'normal',
  w: 'full',
  mt: '2',
  sx: {
    '& svg': {
      display: 'none',
    },
  },
};
export const ButtonNavItem = (): CSSObject => ({
  bg: 'white',
  color: 'gray.700',
  borderTop: '1px',
  borderColor: 'gray.300',
  '&& > p': { transition: 'all 0.2s ease-in-out' },
  ':first-child': {
    borderTop: 'none',
  },
  '&:hover, &:active': {
    bg: 'blackAlpha.300',
    color: 'white',
  },
});
export const PopoverHeaderQA: CSSObject = {
  bg: 'delta.800',
  border: 'none',
  borderTopRadius: 'md',
  pb: '0',
  color: 'white',
  lineHeight: 'normal',
};
export const PopoverContentQA: CSSObject = {
  borderRadius: 'lg',
  p: '1',
  bgColor: 'white',
  boxShadow: '2xl',
  maxH: '325px',
  w: ['97vw', '272px'],
  top: '-2',
  _focus: {
    boxShadow: '2xl',
  },
};
export const FlexBallanceItem: CSSObject = {
  color: 'white',
  p: {
    _last: { fontWeight: 'extrabold' },
  },
};
export const TextBalanceValue: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 3px var(--bc-colors-blackAlpha-50)',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};
export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
};
export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  color: 'white',
  textTransform: 'capitalize',
  _hover: {
    color: 'white',
  },
  _active: {
    color: 'white',
  },
  display: 'flex',
  fontSize: 'sm',
  h: 'unset',
  sx: {
    svg: {
      boxSize: '5',
    },
  },
};

export const BoxMascotImage: CSSObject = {
  backgroundImage: `url('${headerLogo}')`,
  display: ['none', null, null, null, 'block'],
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '135px',
  width: '149px',
  position: 'absolute',
  top: '-1',
  left: '-1',
};
