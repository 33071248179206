import { Show } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom, useLocation } from 'react-router-dom';
import { getStrings, getPDF } from '@/helpers/utils';
import { BoxAside, LinkAside } from './styles/SideLinks.styles';

export default function SideLinks() {
  const [
    {
      Account: { Terms },
    },
  ] = getStrings();

  const location = useLocation();

  const links = [
    {
      title: Terms.pageTitle,
      to: getPDF().tc ?? '/help-centre/terms-and-conditions',
    },
    {
      title: Terms.otherLinks.privacy,
      to: getPDF().policy ?? '/help-centre/privacy-policy',
    },
  ];

  return (
    <Show>
      <BoxAside>
        {links.map((l) => (
          <LinkAside
            isActive={l.to === location.pathname}
            as={LinkDom}
            key={`link-${l.to}`}
            to={l.to}
            target="_blank"
          >
            {l.title}
          </LinkAside>
        ))}
      </BoxAside>
    </Show>
  );
}
