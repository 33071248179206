/* eslint-disable @typescript-eslint/no-use-before-define */
import { Flex, Skeleton } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuerySupportedBets } from '@/api/punter/supportedBets/supportedBets.hooks';
import {
  useQueryRaceMeta,
  useQueryRunnerList,
} from '@/api/racing/racing.hooks';
import { srcDefaultSilk } from '@/assets/core';
import {
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import {
  EBetSlipBetSubmissionType,
  TBetSlipBet,
} from '@/components/Betslip/Services/Betslip.types';
import { getEventRule } from '@/components/Betslip/Services/Betslip.utils';
import Countdown from '@/components/Countdown/Countdown';
import {
  getIconBySportName,
  getStrings,
  isPendingAndMatchingId,
  propIsSuspended,
  startPassedEndNotPassed,
} from '@/helpers/utils';
import { useNTJ } from '@/hooks/useNTJ';
import { useAppSelector } from '@/hooks/useRedux';
import { EGeneralStatus, ERaceType, TPriceType, TRunner } from '@/lib/DBModels';
import {
  BoxCard,
  BoxGridHeaderItem,
  Button,
  featuredRaceStyles,
  FlexGridRunnerItem,
  GridCardContent,
  IconCardHeader,
  ImageRunnerSilk,
  LinkCardHeader,
  LinkRaceDetails,
  TextCardHeader,
  TextRaceNumber,
  TextRunner,
  TextRunnerNumber,
  TextScratched,
} from './styles/Featured.styles';
import { sortRunners } from '@/views/Home/races/utils';
import { useQueryCms } from '@/api/cms/cms.hooks';
import { TNextToJump } from '@/api/nextToJump/nextToJump.types';
import {
  getBetSlipStoreActions,
  transformBetForLegacy,
  useBetSlipBets,
} from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { TLinks } from '@/api/cms/cms.types';

/**
 * Containers
 */
const CardReduxContainer = () => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <Card bets={bets} />;
};

const CardZustandContainer = () => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <Card bets={bets} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? CardZustandContainer
  : CardReduxContainer;

// ----

/**
 * View
 */
type CardProps = {
  bets: TBetSlipBet[];
};

function Card({ bets }: CardProps) {
  const { setBet } = getBetSlipStoreActions();

  const [
    {
      Generic,
      Racing: {
        RaceRunnerItem: { suspended },
      },
    },
  ] = getStrings();

  const { data: cms, isLoading: isCmsLoading } = useQueryCms();

  const filteredEvents: TLinks[] =
    cms
      ?.filter((event) => event.is_event && !event.is_sport_event)
      ?.filter((event) => startPassedEndNotPassed(event))
      ?.sort((a: TLinks, b: TLinks) => Number(a?.order) - Number(b?.order)) ??
    [];

  const firstEvent: TLinks = filteredEvents && filteredEvents[0];

  const { data: queryRaceMeta, isloading: isRaceMetaLoading } =
    useQueryRaceMeta(
      {
        race_id: firstEvent?.event_id,
      },
      { enabled: !!firstEvent }
    );

  // if race is not open fallback
  const cmsFeaturedRace =
    queryRaceMeta?.data.status === EGeneralStatus.Open
      ? (queryRaceMeta?.data as TNextToJump)
      : null;

  const { dataSorted } = useNTJ();
  const featuredRace = cmsFeaturedRace ?? dataSorted?.[4];

  const { data, isInitialLoading } = useQueryRunnerList({
    key: [featuredRace?.race_id ?? ''],
    params: { race_id: featuredRace?.race_id ?? '' },
    options: {
      enabled: !!featuredRace?.race_id,
    },
  });

  const to = `/racing/${featuredRace?.race_type}/${featuredRace?.venue_name}/R${featuredRace?.race_number}?venueId=${featuredRace?.venue_id}&raceId=${featuredRace?.race_id}&meetingDate=${featuredRace?.meeting_date}`;

  const { addRaceRunnerBetSlip } = useSingleBetSlip();
  const { singlePropositionInBetSlip, removeSinglePropositionFromBetSlip } =
    useBetSlipManageBets();
  const supportedBets = useQuerySupportedBets();
  const spBetsAreSupported = supportedBets.data?.starting_price !== false;

  if (isInitialLoading || isRaceMetaLoading || isCmsLoading)
    return <Skeleton boxSize="300px" />;

  return (
    <BoxCard>
      <LinkCardHeader to={to}>
        <Flex align="center">
          <IconCardHeader
            data-cy="featuredRaceIcon"
            name={getIconBySportName(featuredRace?.race_type)}
          />
          <TextCardHeader data-cy="featuredRaceHeading">
            {featuredRace?.venue_name}
          </TextCardHeader>
        </Flex>

        <Flex align="center">
          <Countdown
            eventTime={featuredRace?.start_time ?? ''}
            dateFormat="HH:mm"
          />
          <TextRaceNumber data-cy="featuredRaceRaceNumber">
            R{featuredRace?.race_number}
          </TextRaceNumber>
        </Flex>
      </LinkCardHeader>
      <GridCardContent>
        <BoxGridHeaderItem>{Generic.Runners}</BoxGridHeaderItem>
        <BoxGridHeaderItem>{Generic.Win}</BoxGridHeaderItem>

        {(() => {
          if (isInitialLoading)
            return [...new Array(6)].map((_, i) => (
              <Skeleton
                key={`card-skel-${i}`}
                sx={{
                  h: '40px',
                  borderRadius: 'none',
                  mb: 'px',
                }}
              />
            ));

          return sortRunners(data ?? [])
            .slice(0, 5)
            .map((r: TRunner) => {
              const isScratched =
                r?.is_scratched || r?.status === EGeneralStatus.Scratched;
              const isSuspended = propIsSuspended(
                r?.win_proposition,
                r.win_odds
              );
              const isInBetslip = !!singlePropositionInBetSlip(
                r.win_proposition?.proposition_id
              );
              const eventRule = getEventRule(featuredRace?.number_of_places);
              const priceTypes = r.win_proposition?.price_types ?? [];
              const propHasSPOnly =
                priceTypes.includes('starting') && priceTypes.length === 1;
              const priceType: TPriceType = propHasSPOnly
                ? 'starting'
                : 'fixed';

              return (
                <Fragment key={`featured-rc-${r.race_runner_id}`}>
                  <FlexGridRunnerItem>
                    <ImageRunnerSilk
                      data-cy="featuredRaceSilk"
                      src={r.silk_url}
                      fallbackSrc={srcDefaultSilk}
                      isGreyhound={
                        featuredRace?.race_type === ERaceType.Greyhound
                      }
                    />

                    <TextRunner
                      data-cy="featuredRaceRunnerText"
                      noOfLines={1}
                      isSuspended={isScratched}
                    >
                      <TextRunnerNumber
                        data-cy="featuredRaceBarrierNumber"
                        as="span"
                      >
                        {r.number}.
                      </TextRunnerNumber>{' '}
                      {r.display_name?.toLocaleLowerCase()}{' '}
                      {!!r.barrier_number && (
                        <TextRunnerNumber as="span">
                          {`(${r.barrier_number})`}
                        </TextRunnerNumber>
                      )}
                    </TextRunner>
                  </FlexGridRunnerItem>

                  <FlexGridRunnerItem>
                    {isScratched ? (
                      <TextScratched>{Generic.Scratched}</TextScratched>
                    ) : (
                      <Button
                        data-cy="featuredRaceOddsButton"
                        data-active={isInBetslip}
                        isActive={isInBetslip}
                        isDisabled={
                          isSuspended ||
                          isPendingAndMatchingId(
                            bets,
                            r.win_proposition?.proposition_id
                          ) ||
                          (priceType === 'starting' && !spBetsAreSupported)
                        }
                        onClick={() => {
                          setBet({
                            id: r.win_proposition?.proposition_id ?? '',
                            type: 'Single',
                            odds: r.win_odds ?? 0,
                            propId: r.win_proposition?.proposition_id ?? '',
                            priceType,
                            betType: 'win',
                            misc: {
                              ...r,
                              ...featuredRace,
                              ...queryRaceMeta,
                              race: featuredRace,
                              runner: r,
                            },
                          });

                          if (isInBetslip) {
                            removeSinglePropositionFromBetSlip(
                              EBetSlipBetSubmissionType.Single,
                              r.win_proposition?.proposition_id ?? ''
                            );
                          } else {
                            addRaceRunnerBetSlip(
                              'win',
                              r,
                              { venue_display_name: featuredRace?.venue_name },
                              featuredRace,
                              eventRule,
                              priceType
                            );
                          }
                        }}
                        {...featuredRaceStyles.buttonOddsProps}
                      >
                        {(() => {
                          if (isSuspended) {
                            return suspended;
                          }

                          if (priceType === 'starting') {
                            return (
                              <FormattedMessage id="generic.startingPriceAcronym" />
                            );
                          }

                          return r.win_odds?.toFixed(2);
                        })()}
                      </Button>
                    )}
                  </FlexGridRunnerItem>
                </Fragment>
              );
            });
        })()}
      </GridCardContent>

      <LinkRaceDetails data-cy="featuredRaceViewRaceCard" to={to}>
        <FormattedMessage id="home.viewracecard" />
      </LinkRaceDetails>
    </BoxCard>
  );
}
