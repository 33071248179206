import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bg: 'rgba(78, 178, 255, 0.14)',
  border: 'none',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  borderRadius: 'md',
  flex: '1',
};

export const TypeItem: CSSObject = {
  color: 'alpha.400',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: 'base',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'rgba(78, 178, 255, 0.14)',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  '&:last-child': {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  sx: {
    '.dividends': {
      color: 'alpha.400',
      fontWeight: 'black',
    },
  },
};

export const ShowMoreText: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
