import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    bgGradient: 'linear(to-b, #FFD84D, #FBC915)',
    fontFamily: 'accent',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: 'none',
    fontSize: '2xs',
    px: '2',
    borderRadius: 'md',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.30)',
    border: 'none',
    color: 'alpha.600',
    pt: '0',
    _hover: {
      color: 'alpha.800',
    },
    sx: {
      '&&': {
        h: '24px',
        ':active, &[data-active="true"]': {
          color: '#C4AEFF',
          bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
          border: 'none',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.07), 0px 2px 3px 0px rgba(0, 0, 0, 0.30) inset',
          fontWeight: 'bold',
          fontFamily: 'accent',
        },
      },
    },
  },
};

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};
