import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  body: {
    color: 'white',
  },
  container: {
    px: '1',
    mt: '-3',
  },
};
