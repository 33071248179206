import * as betgalaxy from './BetRecoveryAlert.styles.betgalaxy';
import * as betprofessor from './BetRecoveryAlert.styles.betprofessor';
import * as betroyale from './BetRecoveryAlert.styles.betroyale';
import * as goldenrush from './BetRecoveryAlert.styles.goldenrush';
import * as juicybet from './BetRecoveryAlert.styles.juicybet';
import * as puntcity from './BetRecoveryAlert.styles.puntcity';
import * as puntgenie from './BetRecoveryAlert.styles.puntgenie';
import * as questbet from './BetRecoveryAlert.styles.questbet';
import * as vikingbet from './BetRecoveryAlert.styles.vikingbet';
import * as volcanobet from './BetRecoveryAlert.styles.volcanobet';
import * as wellbet from './BetRecoveryAlert.styles.wellbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  vikingbet,
  volcanobet,
  wellbet,
};
