import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'odds',
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontWeight: 'extrabold',
    fontFamily: 'accent',
    fontSize: '2xs',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  buttonBaseBox: {
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
