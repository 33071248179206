import { THomeStyles } from './Home.styles';

const styles: THomeStyles = {
  wrapper: {
    display: 'flex',
    flexDir: 'column',
    gap: {
      base: '2',
      lg: '4',
    },
    px: {
      base: '2',
      lg: '0',
    },
  },
};
export default styles;
