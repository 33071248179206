import { Box, chakra } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Transactions.styles.imports';

const themeName = getThemeName();

export const TransactionsContainer = chakra(Box, {
  label: 'transactions-TransactionContainer',
  baseStyle: () => ({
    margin: '0 auto',
    width: '100%',
    ...(() => {
      try {
        return styleImports[themeName]?.TransactionsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
