import { Box, chakra, Container, IconButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { styleImports } from './Basic.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxHeader = chakra(Box, {
  baseStyle: ({ theme }) => ({
    h: '57px',

    ...(theme.themeName === EThemes.Betgalaxy && {
      h: '58px',
      px: ['2', null, '0'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ContainerWrapper = chakra(Container, {
  baseStyle: () => ({
    maxW: 'container.xl',
    display: 'flex',
    justifyContent: 'space-between',
    h: 'full',
    alignItems: 'center',
  }),
});

export const CloseButton = chakra(IconButton, {
  baseStyle: () => ({
    bg: 'white',
    borderRadius: 'full',
    p: '1',
    boxSize: '10',
    ...(() => {
      try {
        return styleImports[themeName]?.CloseButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkLogo = chakra(Link, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.LinkLogo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
