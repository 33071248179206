import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import SgmLogo from '@/components/SgmLogo/SgmLogo';
import { duotoneSgmLogoStyles } from '@/components/DuotoneSgmLogo/styles/DuotoneSgmLogo.styles';

export type TDuotoneSgmLogo = BoxProps & {
  primaryColor: BoxProps['color'];
  secondaryColor: BoxProps['bg'];
};

export default function DuotoneSgmLogo({
  primaryColor,
  secondaryColor,
  ...rest
}: TDuotoneSgmLogo) {
  return (
    <Box
      _before={{ bg: secondaryColor }}
      {...duotoneSgmLogoStyles.boxWrapper}
      {...rest}
    >
      <SgmLogo color={primaryColor} {...duotoneSgmLogoStyles.sgmLogo} />
    </Box>
  );
}
