import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Icon, Portal, Show } from '@chakra-ui/react';
import { Eye, EyeSlash } from 'styled-icons/fa-solid';
import { ButtonOutsideArea } from '../../styles/Header.styles';
import Balances from './Balances.v1';
import Nav from './Nav';
import {
  PopoverContentQA,
  PopoverHeaderQA,
  TextQuickAccessHello,
  PopoverBodyQA,
  buttonBalanceProps,
} from './styles/QuickAccessMenu.styles';
import { Button } from '@/components/Button/Button';

export default function PopOverPortalV1({
  setIsOpen,
  isOpen,
  showBalance,
  setShowBalance,
}: any) {
  const intl = useIntl();

  return (
    <Portal>
      <Box zIndex="popover" boxSize="full" position="relative">
        <PopoverContentQA>
          <PopoverHeaderQA>
            <TextQuickAccessHello>
              <FormattedMessage id="generic.hello" />
            </TextQuickAccessHello>

            <Button
              {...buttonBalanceProps}
              data-cy={
                showBalance ? 'btn-qa-hide-balance' : 'btn-qa-show-balance'
              }
              showBallance={showBalance}
              onClick={() => {
                setShowBalance.toggle();
                localStorage.setItem('show-balance', String(!showBalance));
              }}
              rightIcon={<Icon as={showBalance ? EyeSlash : Eye} />}
            >
              <FormattedMessage
                id="generic.toggleBalance"
                values={{
                  state: intl.formatMessage({
                    id: !showBalance ? 'generic.show' : 'generic.hide',
                  }),
                }}
              />
            </Button>
          </PopoverHeaderQA>

          <PopoverBodyQA>
            <Balances showBallance={showBalance} onClose={setIsOpen.toggle} />

            <Nav onClose={setIsOpen.toggle} />
          </PopoverBodyQA>
          <Show below="sm">
            <ButtonOutsideArea isOpen={isOpen} onClick={setIsOpen.off} />
          </Show>
        </PopoverContentQA>
      </Box>
    </Portal>
  );
}
