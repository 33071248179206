import { CSSObject } from '@chakra-ui/react';

export const LinkForgotPassword: CSSObject = {
  color: 'beta.600',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const LoginButton: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  boxShadow:
    '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px #89D255 inset',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
  fontWeight: 'base',
  textTransform: 'capitalize',
  fontSize: 'sm',
  fontFamily: 'CarterOne',
  '&&': {
    borderRadius: 'lg',
  },

  _disabled: {
    bg: 'gray.400',
    borderWidth: '2px',
    borderColor: 'gray.300',
    color: 'blackAlpha.500',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset',
    border: 'none',
    opacity: '1',

    _hover: { bg: 'gray.400' },
  },
  _hover: {
    bgGradient: 'linear(to-b, alpha.700, alpha.800)',
    color: 'whiteAlpha.600',
    textShadow: 'none',
  },
  _focus: {
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
    color: 'white',
    textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
    border: 'none',
  },
};
