import { SRMultiStylesSchema } from './SRMulti.styles';

export const srMultiStyles: SRMultiStylesSchema = {
  multiContainer: {
    mx: '4',
    p: '2',
    bg: '#495564',
    borderRadius: 'md',
    mb: '2',
  },
  multiRunnerContainer: {
    justifyContent: 'space-between',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    p: '3',
    my: '2',
    borderRadius: 'md',
  },
  buttonLabel: {
    color: 'white',
  },
  propButton: {
    w: '50px',
  },
  columnHeadings: {
    w: '48px',
    textAlign: 'center',
    fontSize: 'xs',
    color: 'gamma.200',
    fontWeight: 'medium',
  },
  headingLabel: {
    color: 'gamma.200',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  headingContainer: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'gamma.200',
    fontSize: 'xs',
    fontWeight: 'medium',
    marginRight: '1',
  },
  runnerList: {
    bg: 'transparent',
    p: '0',
  },
  headingStacker: {
    rowGap: '2',
  },
};
