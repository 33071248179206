import { CSSObject, FlexProps } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const transactionInfoFlexProps: FlexProps = {
  color: 'white',
};

export const PageHeadingWrapper: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  p: '0',
};

export const TableWrapper: CSSObject = {
  pt: '0',
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'beta.500' : 'white',
  fontWeight: 'normal',
});

export const TransactionWrapper: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  minH: 'auto',
  px: '0',
  py: '2',
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const buttonMoreTableProps: CSSObject = {
  color: 'white',
};

export const NoTransactionsText: CSSObject = {
  color: 'white',
};
