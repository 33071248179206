import thumb from '@/assets/titanbet/images/toggle/thumb.png';
import background from '@/assets/titanbet/images/toggle/background.png';

export const FlexWrapper = {
  borderRadius: 'lg',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid',
  borderColor: 'gamma.500',
  p: '2',
  mx: ['1.5', null, 'unset'],
  mb: ['1', null, '4'],
  gap: '2',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  bgImg: `url(${background})`,
  h: '16',
  pos: 'relative',
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'heading',
  fontWeight: 'black',
  fontSize: 'md',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 10px rgba(255, 185, 83, 0.6)'
    : '0px 1px 0px rgba(255, 255, 255, 0.08)',
  color: isActive ? 'gamma.300' : 'whiteAlpha.500',
  letterSpacing: 'wide',
  px: '3',
});

export const Switcher = () => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
  '--switch-track-width': '46px',
  bg: 'transparent',
  pos: 'relative',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${thumb})`,
      boxSize: '12',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-18px',
      right: '0',
      top: '-2.5',
    },
  },
  '.chakra-switch__track': {
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.6) inset',
    bg: 'blackAlpha.400',
    borderRadius: '5px',

    _before: {
      bg: 'blackAlpha.500',
      h: '7px',
      w: '34px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: '2em',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
      content: '""',
      pos: 'absolute',
      left: '2',
      top: '2.5',
    },
  },
});
