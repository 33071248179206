import * as yup from 'yup';

const promotionsPreferencesSchema = yup
  .object({
    preference_name: yup.string().required(),
    preference_value: yup.string().required(),
    enabled: yup.boolean().optional(),
  })
  .required();

export type PromotionsPreferencesSchema = yup.InferType<
  typeof promotionsPreferencesSchema
>;

export default promotionsPreferencesSchema;
