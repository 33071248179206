import { CSSObject, FlexProps } from '@chakra-ui/react';
import { TTableText } from '@/views/account/Transactions/components/TransactionTable/types';

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'beta.400' : 'white',
});

export const transactionInfoFlexProps: FlexProps = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
  borderBottom: '1px solid',
  borderColor: 'blackAlpha.400',
  borderTop: 'none',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const PageHeadingWrapper: CSSObject = {
  borderBottom: '1px solid',
  borderColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};
