import { CSSObject } from '@chakra-ui/react';
import onboardingWelcome from '../../../assets/betprofessor/images/backgrounds/onboardingWelcome.png';
import onboardingMobile from '../../../assets/betprofessor/images/backgrounds/onboardingMobile.png';

export const TextTitle: CSSObject = {
  fontSize: '4xl',
  textAlign: ['center', 'justify'],
  fontWeight: 'normal',
  lineHeight: '7',
  display: 'block',
  mt: ['3', null, '7'],
  ml: ['unset', null, '10'],
  mb: ['4', '3'],
  flex: [null, 1, 'unset'],
  alignItems: 'flex-start',
  height: ['100%', 'unset'],
  fontFamily: 'Caveman',
  flexDir: 'column',
  w: ['auto', '70%'],
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
};

export const TitleContainer: CSSObject = {
  color: 'white',
  pt: ['1', null, '4'],
};

export const TextTitleSuffixContent: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'transparent',
  bgClip: 'text',
  textShadow: 'none',
  filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.5))',
};

export const FirstWord: CSSObject = {
  textShadow: 'none',
  filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.5))',
};

export const TextSubtitle: CSSObject = {
  fontSize: 'xs',
  lineHeight: '4',
  color: 'white',
  flex: '1',
  mt: 'unset',
  ml: ['2', null, '5'],
  textAlign: 'center',
  w: '260px',
};

export const TextSubtitleBoldWord: CSSObject = {
  color: 'alpha.200',
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  mb: ['0', null, '9'],
  color: 'white',
  textAlign: 'center',
  alignSelf: ['flex-end', null, 'unset'],
  w: ['100%', null, 'unset'],
};

export const TextCTALink: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'bold',
};

export const FlexAsideInfo: CSSObject = {
  flexDir: 'column',
  alignItems: ['center', null, 'flex-start'],
  zIndex: 'overlay',
  pt: ['3', null, '7'],
  textAlign: 'center',
  borderBottom: '3px solid',
  borderColor: ['gamma.500', 'transparent', null],

  img: {
    ml: ['auto', null, '10'],
  },
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  minH: ['336px', null, '100%'],
};

export const FlexCTAContainer: CSSObject = {
  justifyContent: 'center',
  alignSelf: ['center', null, 'unset'],
  mt: ['0', null, '2'],
  width: '100%',
  position: ['absolute', 'inherit'],
  bottom: ['7.5%', 'auto'],
  bg: ['gamma.700', 'transparent'],
  borderTop: ['2px solid', 'none'],
  borderColor: 'gamma.500',
  minH: ['48px', 'unset'],
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    backgroundImage: `url(${onboardingMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    '@media (min-width: 480px)': {
      background: `url('${onboardingWelcome}'), linear-gradient(180deg, var(--bc-colors-gamma-700) 0%, var(--bc-colors-gamma-700) 100%)`,
      backgroundPosition: 'top, bottom',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
};
