import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    borderRadius: 'lg',
    border: 'none',
    gap: '3',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
  },
  depositIconProps: {
    color: 'beta.500',
    boxSize: '9',
  },
};
