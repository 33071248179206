const branding = {
  brand: {
    50: '#EEF8FF',
    100: '#DDF0FE',
    200: '#BBE1FD',
    300: '#98D3FD',
    400: '#76C4FC',
    500: '#54B5FB',
    600: '#4395D0',
    700: '#3376A6',
    800: '#22567B',
    900: '#123751',
  },
  accent: {
    50: '#CBD4F4',
    100: '#ABB9EB',
    200: '#8B9FE3',
    300: '#6A84DA',
    400: '#4A6AD2',
    500: '#2A4FC9',
    600: '#2342A7',
    700: '#1C3585',
    800: '#152764',
    900: '#0E1A42',
  },
  gamma: {
    50: '#FFEFB0',
    100: '#FCE68D',
    200: '#FADD6A',
    300: '#F7D446',
    400: '#F5CB23',
    500: '#F2C200',
    600: '#CCA400',
    700: '#A68600',
    800: '#816700',
    900: '#5B4900',
  },
  theta: {
    50: '#F3D247',
    100: '#F0B344',
  },
};

export const colors = {
  brand: branding.brand,
  accent: branding.accent,
  alpha: branding.brand,
  beta: branding.accent,
  gamma: branding.gamma,
  theta: branding.theta,
  delta: {},
  epsilon: {},
};
