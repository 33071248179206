import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '0',
  pb: '3.5',
  mt: ['-3', null, '-2'],
  px: '1',
  top: ['0px', '9'],
  bg: 'alpha.700',
  position: 'inherit',
};

export const ButtonFilter = () => ({
  color: 'whiteAlpha.600',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'gray.400',
  color: selected ? 'alpha.900' : 'transparent',
  '&&': {
    boxShadow: '0px 1px 0px 0px black inset',
  },
});

export const TextFilterLabel: CSSObject = {
  color: 'whiteAlpha.600',
};
