import { CSSObject } from '@chakra-ui/react';

export const LinkContainer = () => ({
  borderTop: '1px dashed',
  borderTopColor: '#516397',
  borderBottom: '1px dashed',
  borderBottomColor: '#283455',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});
export const BoxNextToJumpContainer: CSSObject = {
  bgGradient: `linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
  mx: '0',
  borderRadius: 'md',
  overflowY: 'hidden',
  mb: '2',
  '> div': {
    _last: {
      a: {
        borderBottom: 0,
      },
    },
  },
};
export const Icon: CSSObject = {
  boxSize: '8',
  color: 'beta.800',
  bg: 'alpha.400',
  border: 'none',
  borderRadius: 'md',
};
export const TextRace: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'normal',
};
export const TextNumber: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'beta.800',
  color: 'alpha.400',
  py: '0',
  pt: '0.5',
  borderRadius: 'base',
  '&&': {
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
  },
};
export const BoxHeading: CSSObject = {
  display: 'none',
};
export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    color: ['white', 'beta.900'],
  },
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    py: '0',
    mr: '0.5',
    color: 'alpha.800',
  },
};
