import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  border: 'none',
  borderRadius: 'md',
  flex: '1',
};
export const TypeItem: CSSObject = {
  color: 'beta.400',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ResultRowWrapper: CSSObject = {
  border: '1px solid',
  borderColor: 'alpha.100',
  borderRadius: 'base',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'blackAlpha.500',
  '&:last-child': {
    mb: '2',
  },
};
export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};
export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  _last: {
    fontWeight: 'black',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(37, 254, 214, 0.7)',
  },
};
