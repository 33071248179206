import { useMediaQuery } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { TData } from '../../../api/banner/banner.types';
import { useQueryBanners } from '../../../api/banner/banner.hooks';
import { useAuth } from '../../../hooks/useAuth';
import { usePunterData } from '@/store/AuthStore';
import { EDisplay, EStatus } from '@/lib/DBModels';
import { useQueryPromotions } from '@/api/punter/deposit/deposit.hooks';
import { TQueryPromotionsResponse } from '@/api/punter/deposit/deposit.types';

export const useHomeBanner = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth();

  const { data: banners, isLoading, isFetched } = useQueryBanners();
  const { data: promotionsData } = useQueryPromotions(
    {
      promo_type: ['money_back', 'treasure_hunt'],
    },
    { enabled: !!isAuthenticated }
  );

  const promotions = {
    data: (promotionsData?.map((pro) => pro.data).flat() ??
      []) as unknown as TQueryPromotionsResponse,
  };

  const punterData = usePunterData();

  const isPromoOffered = (id: string) =>
    promotions?.data.find((promo) => promo.id === id);

  const filteredBanners: TData[] = (banners ?? []).filter((banner: TData) => {
    if (banner.platform === 'app') return false;

    if (banner.display === EDisplay.LoggedIn) {
      if (isAuthenticated) {
        if (punterData?.state && !banner.location.includes(punterData.state)) {
          return false;
        }
        if (punterData?.pc && !banner.punter_category.includes(punterData.pc)) {
          return false;
        }
      }
    }

    if (banner.has_money_back_promo) {
      if (!isAuthenticated) return false;
      if (isPromoOffered(banner.money_back_promo_id ?? '')) {
        const promoToCheck = promotions?.data.find(
          (promo) => promo.id === banner.money_back_promo_id
        );

        return promoToCheck && dayjs().isBefore(promoToCheck?.expiry);
      }
      return false;
    }

    if (banner.has_treasure_hunt_promo) {
      if (!isAuthenticated) return false;
      if (isPromoOffered(banner.treasure_hunt_promo_id ?? '')) {
        const promoToCheck = promotions?.data.find(
          (promo) => promo.id === banner.treasure_hunt_promo_id
        );

        return promoToCheck && dayjs().isBefore(promoToCheck?.expiry);
      }
      return false;
    }

    const isActive = () => {
      if (banner.status === EStatus.Active) return true;
      if (banner.status === EStatus.Draft) return false;
      if (!banner.start_date) return false;

      const hasStarted = dayjs().isAfter(dayjs(new Date(banner.start_date)));
      const hasNotFinished =
        !banner.end_date || dayjs().isBefore(dayjs(new Date(banner.end_date)));
      return hasStarted && hasNotFinished;
    };

    return (
      isActive() &&
      ((isAuthenticated && banner.display === EDisplay.LoggedIn) ||
        (!isAuthenticated && banner.display === EDisplay.LoggedOut) ||
        banner.display === EDisplay.Both)
    );
  });

  const isBannerAvailable = !!filteredBanners.length && isFetched;

  return {
    filteredBanners,
    isAuthLoading,
    isMobile,
    isLoading,
    isFetched,
    isBannerAvailable,
  };
};
