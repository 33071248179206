import {
  TSportPropositionsParams,
  TSportPropositionResponse,
} from './sportDetailsPropositions.types';

export const querySportsPropositionsMock: TSportPropositionResponse = [
  {
    proposition_id: 'head-to-head--regular-time--aston-villa',
    proposition_name: 'Aston Villa',
    return_amount: 1.4,
    market_id: 'head-to-head--regular-time',
    event_info: {
      team_role: 'Home',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'head-to-head--regular-time--newcastle',
    proposition_name: 'Newcastle',
    return_amount: 2.3,
    market_id: 'head-to-head--regular-time',
    event_info: {
      team_role: 'Away',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--newcastle-1-0',
    proposition_name: '1-0',
    return_amount: 2.2,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Newcastle',
      team_role: 'Home',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--newcastle-2-0',
    proposition_name: '2-0',
    return_amount: 2.6,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Newcastle',
      team_role: 'Home',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--newcastle-2-1',
    proposition_name: '2-1',
    return_amount: 2.7,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Newcastle',
      team_role: 'Home',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--newcastle-3-0',
    proposition_name: '3-0',
    return_amount: 2.9,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Newcastle',
      team_role: 'Home',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--draw-0-0',
    proposition_name: '0-0',
    return_amount: 2,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Draw',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--draw-1-1',
    proposition_name: '1-1',
    return_amount: 1.7,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Draw',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--draw-2-2',
    proposition_name: '2-2',
    return_amount: 2.3,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Draw',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--aston-villa-1-0',
    proposition_name: '1-0',
    return_amount: 1.4,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Aston Villa',
      team_role: 'Away',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--aston-villa-2-0',
    proposition_name: '2-0',
    return_amount: 1,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Aston Villa',
      team_role: 'Away',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--aston-villa-2-1',
    proposition_name: '2-1',
    return_amount: 3,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Aston Villa',
      team_role: 'Away',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'correct-score--regular-time--aston-villa-3-0',
    proposition_name: '3-0',
    return_amount: 1.9,
    market_id: 'correct-score--regular-time',
    event_info: {
      team: 'Aston Villa',
      team_role: 'Away',
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'goals-over-under--regular-time--over-1',
    proposition_name: 'Over',
    return_amount: 2.2,
    market_id: 'goals-over-under--regular-time',
    event_info: {
      value1: 1,
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'goals-over-under--regular-time--over-2',
    proposition_name: 'Over',
    return_amount: 2.6,
    market_id: 'goals-over-under--regular-time',
    event_info: {
      value1: 2,
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'goals-over-under--regular-time--over-3',
    proposition_name: 'Over',
    return_amount: 2.7,
    market_id: 'goals-over-under--regular-time',
    event_info: {
      value1: 3,
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'goals-over-under--regular-time--under-1',
    proposition_name: 'Under',
    return_amount: 1.4,
    market_id: 'goals-over-under--regular-time',
    event_info: {
      value1: 1,
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'goals-over-under--regular-time--under-2',
    proposition_name: 'Under',
    return_amount: 1,
    market_id: 'goals-over-under--regular-time',
    event_info: {
      value1: 2,
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'goals-over-under--regular-time--under-3',
    proposition_name: 'Under',
    return_amount: 3,
    market_id: 'goals-over-under--regular-time',
    event_info: {
      value1: 3,
      event_part: 'Regular Time',
    },
  },
  {
    proposition_id: 'half-time-full-time--newcastle-newcastle',
    proposition_name: 'Newcastle / Newcastle',
    return_amount: 2.7,
    market_id: 'half-time-full-time',
  },
  {
    proposition_id: 'half-time-full-time--newcastle-draw',
    proposition_name: 'Newcastle / Draw',
    return_amount: 1.5,
    market_id: 'half-time-full-time',
  },
  {
    proposition_id: 'half-time-full-time--newcastle-aston-villa',
    proposition_name: 'Newcastle / Aston Villa',
    return_amount: 2.4,
    market_id: 'half-time-full-time',
  },
  {
    proposition_id: 'half-time-full-time--draw-draw',
    proposition_name: 'Draw / Draw',
    return_amount: 1.3,
    market_id: 'half-time-full-time',
  },
  {
    proposition_id: 'half-time-full-time--draw-newcastle',
    proposition_name: 'Draw / Newcastle',
    return_amount: 1.4,
    market_id: 'half-time-full-time',
  },
];

export function filterData({ market_id }: TSportPropositionsParams) {
  return querySportsPropositionsMock.filter(
    (m) => m.market_id && market_id.includes(m.market_id)
  );
}
