import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  px: '2',
  border: '2px',
  borderColor: 'gamma.500',
  bg: 'alpha.500',
  borderRadius: 'lg',
  mb: '2',
  flex: '1',
};

export const TypeItem: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: ['base', null, 'md'],
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'whiteAlpha.400',
  '&:last-child': {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'gamma.400',
};
