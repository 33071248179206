import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  pt: '20',
  textAlign: 'center',
  mx: 'auto',
  w: 'full',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontWeight: 'bold',
  fontSize: '20px',
  textTransform: 'uppercase',
  color: 'beta.500',
  fontFamily: 'KHInterference',
  mt: '2',
  mb: '1',
  w: '210px',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
  alignItems: 'center',
  w: '210px',
};
