import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    fontFamily: 'roboto',
    fontSize: '2xs',
    lineHeight: '1.125',
    textTransform: 'none',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    bgGradient: 'linear(to-b, #636EC7, #414984)',
    boxShadow: '0px 0px 3px 0px rgba(255, 255, 255, 0.60) inset',
    borderRadius: 'base',
    sx: {
      '&:active, &[data-active="true"]': {
        border: 'base',
        bg: 'beta.600',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.50) inset',
        _hover: {
          bg: 'beta.700',
          color: 'white',
        },
      },
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  },
  buttonBaseBox: {
    fontSize: 'sm',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
