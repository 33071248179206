import { CSSObject, IconProps, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DepositContainer: CSSObject = {
  mb: 2,
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  h: '10',
  lineHeight: 'normal',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: 1,
  w: 12,
  h: 7,
  bg: 'white',
};

export const RowDivider: CSSObject = {
  borderColor: 'gray.300',
  opacity: 1,
};

export const FlexWrapper: CSSObject = {
  color: 'gray.700',
  py: 3,

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  ml: 3,
  fontWeight: 'medium',
  color: 'white',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: 4,
  p: 1,
};

export const IconButton: CSSObject = {
  h: 8,
  ml: 4,
  minWidth: 8,
};

export const TrashIcon: IconProps = {
  bg: 'none',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  color: 'blackAlpha.700',
  boxSize: '7',
  p: '1',
  borderRadius: 'base',
};

export const BoxInputContainer: CSSObject = {
  mb: 2,

  '.chakra-form-control': {
    mb: 0,
    label: {
      color: 'white',
    },
  },
  '&&': {
    '.chakra-input__left-addon': {
      borderLeftRadius: 'base',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'alpha.900',
      color: 'white',
    },
    '.chakra-input': {
      border: '1px solid',
      borderLeftRadius: 'none',
      bg: 'white',
      color: 'alpha.900',
      fontSize: 'xs',
      fontWeight: 'bold',
      borderRightRadius: 'base',
      borderColor: 'gray.300',
      borderLeft: 'none',
      '&': {
        ':active, :hover': {
          bg: 'gray.100',
          color: 'blackAlpha.800',
          borderColor: 'gray.400',
          _placeholder: {
            color: 'blackAlpha.500',
          },
        },
        _focus: {
          color: 'blackAlpha.800',
          bg: 'gray.50',
          borderColor: 'blue.300',
          boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
        },
        '::placeholder': {
          color: 'blackAlha.500',
          opacity: '1',
        },
      },
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: 2,
};

export const TextSSL: CSSObject = {
  color: 'gray.400',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const IconSSL: CSSObject = {
  mx: 1,
  w: 4,
  h: 4,
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'normal',
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: '#FFB11A',
};
