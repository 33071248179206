import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  _groupFocusWithin: {
    bg: 'epsilon.100',
  },
  bg: 'epsilon.200',
  color: 'black',
  fontSize: 'sm',
  ...(isPrefix
    ? {
        borderLeftRadius: 'md',
        borderRightRadius: 'none',
      }
    : {
        borderLeftRadius: 'none',
        borderRightRadius: 'base',
      }),
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  boxShadow: '0px 1px 0px #021816, inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'epsilon.400',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'alpha.300',
  fontWeight: 'extrabold',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'alpha.300',
  fontWeight: 'extrabold',
};

export const InputBetSlip: CSSObject = {
  bg: 'epsilon.500',
  marginTop: '-0.5',
  borderColor: 'epsilon.300',
  color: 'white',
  pl: '10',
  borderRadius: 'md',
  ':first-child': {
    pl: '3',
  },
  _hover: {
    '&&': {
      bg: 'blackAlpha.200',
    },
    '.chakra-input__left-element': {
      bg: 'epsilon.300',
    },
  },
  _focus: {
    borderColor: 'epsilon.100',
    '&&': {
      background: 'epsilon.300',
    },
    '&::placeholder': { color: 'white', opacity: '0.8' },
  },
  '&::placeholder': { color: 'white', opacity: '0.5' },
};

export const FlexiReview: CSSObject = {
  pl: '1',
};

export const TreasureChestIcon: CSSObject = {
  color: 'alpha.300',
};
