import { create } from 'zustand';
import { TFeatureFlags } from '@/lib/featureFlags';

type ToBoolean<T> = {
  [P in keyof T]: boolean;
};

export interface TFeatureFlagStore {
  featureFlags: ToBoolean<TFeatureFlags> | null;
  actions: {
    setFeatureFlags: (flags: ToBoolean<TFeatureFlags>) => void;
    clearStore: () => Omit<TFeatureFlagStore, 'actions'>;
  };
}

const useFeatureFlagStore = create<TFeatureFlagStore>((set) => ({
  featureFlags: null,
  actions: {
    setFeatureFlags: (featureFlags) => set({ featureFlags }),
    clearStore: () => ({
      featureFlags: null,
    }),
  },
}));

/** EXPORTS */
export const getFeatureFlagStoreActions = () =>
  useFeatureFlagStore.getState().actions;

export function getFeatureFlag() {
  return useFeatureFlagStore.getState().featureFlags;
}

export function useFeatureFlag(name: keyof TFeatureFlags) {
  const featureFlags = useFeatureFlagStore((s) => s.featureFlags);
  return featureFlags?.[name];
}

export const {
  setState: setFeatureFlagStoreState,
  getState: getFeatureFlagStoreState,
} = useFeatureFlagStore;
