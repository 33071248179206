import {
  Box,
  BoxProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './RaceDetails.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type RaceDetailsSchema = Partial<{
  flexExoticsHeadingRight: FlexProps;
  flexIconWrapper: FlexProps;
  buttonStyledGroupedProps: CustomButtonProps;
}>;

export const raceDetailsStyles: RaceDetailsSchema = {
  flexExoticsHeadingRight: {
    'data-testid': 'raceDetails-flexExoticsHeadingRight',
    flexDir: ['column', 'row'],
    gap: '3',
    alignItems: 'center',

    ...styleImports[themeName]?.raceDetailsStyles?.flexExoticsHeadingRight,
  },
  flexIconWrapper: {
    'data-testid': 'raceDetails-flexIconWrapper',

    ...(styleImports[themeName]?.raceDetailsStyles?.flexIconWrapper || {}),
  },
  buttonStyledGroupedProps: {
    'data-testid': 'raceDetails-buttonStyledGroupProps',

    ...(styleImports[themeName]?.raceDetailsStyles?.buttonStyledGroupedProps ||
      {}),
  },
};

export const PageWrapper = chakra('div', {
  label: 'raceDetails-PageWrapper',
  baseStyle: () => ({}),
});

export const PageHeaderWrapper = chakra('div', {
  label: 'raceDetails-PageHeaderWrapper',
  baseStyle: () => ({
    borderTopLeftRadius: [null, null, null, 'lg'],
    borderTopRightRadius: [null, null, null, 'lg'],
  }),
});

export const PageHeaderContainer = chakra('div', {
  label: 'raceDetails-PageHeaderContainer',
  baseStyle: () => ({}),
});

export const PageHeaderInnerContainer = chakra(Flex, {
  label: 'raceDetails-PageHeaderInnerContainer',
  baseStyle: () => ({
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
});

export const PageHeaderOuterContainer = chakra(Flex, {
  label: 'raceDetails-PageHeaderOuterContainer',
  baseStyle: () => ({
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
});

export const TextVenue = chakra(Text, {
  label: 'raceDetails-TextVenue',
  baseStyle: () => ({
    label: 'TextVenue',
    fontSize: '2xl',
  }),
});

export const BreadCrumbWrapper = styled.div`
  ${({ theme }) => theme.pages.raceDetails.BreadCrumbWrapper};
`;

export const PageHeader = styled.h3`
  ${({ theme }) => theme.pages.raceDetails.PageHeader};
`;

export const PageHeaderIcon = styled.div`
  ${({ theme }) => theme.pages.raceDetails.PageHeaderIcon};
`;

export const RaceAndTimerContainer = chakra(Flex, {
  label: 'raceDetails-RaceAndTimerContainer',
  baseStyle: ({ theme }) => ({
    flexDir: ['column', 'column', 'row'],
    maxW: '100%',
    overflowX: 'auto',
    alignItems: ['flex-start', 'column', 'center'],
    p: ['0', '0', '1'],
    gap: ['1', '1', '0'],
    justifyContent: ['flex-start', 'flex-start', 'flex-end'],
    flex: '0 0 auto',

    ...(theme.themeName === EThemes.Betgalaxy && {
      justifyContent: ['flex-start', 'end'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RaceAndTimerContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceName = chakra(Text, {
  label: 'raceDetails-RaceName',
  baseStyle: ({ theme }) => ({
    fontSize: '2xs',
    mr: ['1', null],

    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'semibold',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'bold',
      fontSize: ['xs', null, 'sm'],
      color: 'beta.600',
      textTransform: 'capitalize',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RaceName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRaceConditions = chakra(Text, {
  label: 'raceDetails-TextRaceConditions',
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['2xs', 'xs'],
    fontWeight: 'normal',
    textTransform: 'capitalize',

    _after: {
      content: `""`,
      boxSize: '1',
      borderRadius: 'full',
      bg: 'alpha.400',
      pos: 'relative',
      mx: '1.5',
    },

    _last: {
      _after: {
        display: ['none', 'none', 'block'],
      },
    },

    span: {
      fontWeight: 'black',
      mr: ['2px', '1'],
    },

    b: {
      fontWeight: 'normal',
      textTransform: 'lowercase',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.TextRaceConditions;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonGroupWrapper = chakra(Flex, {
  label: 'raceDetails-ButtonGroupWrapper',
  baseStyle: () => ({
    maxW: '100%',
    '&::-webkit-scrollbar': { display: 'none' },
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonGroupWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceRunnerListWrapper = chakra(Box, {
  label: 'raceDetails-RaceRunnerListWrapper',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      mx: [null, '3', null],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RaceRunnerListWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TRaceRunnerListHeadings = {
  resultsShowing?: boolean;
} & any;

export const RaceRunnerListHeadings = chakra<TRaceRunnerListHeadings>(Flex, {
  label: 'raceDetails-RaceRunnerListHeadings',
  shouldForwardProp: (prop) => !['resultsShowing'].includes(prop),
  baseStyle: ({ theme, resultsShowing }: TRaceRunnerListHeadings) => ({
    flexDirection: 'row',
    userSelect: 'none',

    ...(theme.themeName === EThemes.Wellbet && {
      pb: '2',
      color: 'gray.700',
      fontWeight: 'medium',
      fontSize: 'xs',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      h: ['16', null, 'auto'],
      fontSize: 'xs',
      fontWeight: 'semibold',
      display: 'flex',
      bg: [null, null, 'none'],
      pt: ['0', '2.5', null],
      pl: ['2', null, null, '0'],
      pr: ['0', '2', null, '0'],
      my: ['2', '1.5', null],
      borderRadius: ['none', null, resultsShowing ? 'none' : 'md'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RaceRunnerListHeadings;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceRunnerHeading = styled.span`
  ${({ theme }) => theme.pages.raceDetails.RaceRunnerHeading};
`;

export const RaceRunnerListContainer = chakra(Box, {
  label: 'raceDetails-RaceRunnerListContainer',
  baseStyle: () => ({}),
});

export const RaceRunnerPropositionHeading = styled.span`
  ${({ theme }) => theme.pages.raceDetails.RaceRunnerPropositionHeading};
`;

export const RaceRunnerWinPlaceContainer = chakra(Flex, {
  label: 'raceDetails-RaceRunnerWinPlaceContainer',
  baseStyle: ({ theme }) => ({
    flex: 'unset',
    columnGap: '2',
    cursor: 'pointer',
    ...(theme.themeName === EThemes.Wellbet && {
      columnGap: '1',
      fontSize: ['2xs', null, 'sm'],
      fontWeight: 'medium',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: ['capitalize', null, 'uppercase'],
      color: 'gray.700',
      columnGap: '2',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RaceRunnerWinPlaceContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TRaceRunnerList = {
  resultsShowing?: boolean;
} & any;

export const RaceRunnerList = chakra<TRaceRunnerList>(Box, {
  label: 'raceDetails-RaceRunnerList',
  shouldForwardProp: (prop) => !['resultsShowing'].includes(prop),
  baseStyle: ({ theme }: TRaceRunnerList) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      border: '1px',
      borderColor: 'gray.300',
      borderRadius: ['base', null, 'md'],
      bg: 'gray.100',
      px: '2',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RaceRunnerList;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceRunnerHeadingContainer = chakra(Flex, {
  label: 'raceDetails-RaceRunnerHeadingContainer',
  baseStyle: ({ theme }) => ({
    columnGap: '4',
    flexDirection: 'row',
    alignItems: 'end',
    cursor: 'pointer',
    textTransform: 'uppercase',

    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: ['capitalize', null, null],
      color: 'black',
      fontSize: 'xs',
      fontWeight: 'medium',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RaceRunnerHeadingContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceDetailsHeading = chakra(Text, {
  label: 'raceDetails-RaceDetailsHeading',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      textTransform: 'capitalize',
      fontSize: 'xs',
    }),
    textAlign: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.RaceDetailsHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceResultListWrapper = chakra(Flex, {
  label: 'raceDetails-RaceResultListWrapper',
  baseStyle: () => ({
    flexDirection: ['column', null, 'inherit'],
    gap: ['3', '2', null],
    mb: ['3', '2', null],
    ...(() => {
      try {
        return styleImports[themeName]?.RaceResultListWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceRunnerSortedHeadingContainer = chakra(Flex, {
  label: 'raceDetails-RaceRunnerSortedHeadingContainer',
  baseStyle: ({ theme }) => ({
    flex: '3',

    ...(theme.themeName === EThemes.Wellbet && {
      flex: '15',
    }),
  }),
});

type TRaceRunnerPropositionHeadingContainer = {
  showingPlaceOdds: boolean;
} & FlexProps &
  any;
export const RaceRunnerPropositionHeadingContainer =
  chakra<TRaceRunnerPropositionHeadingContainer>(Flex, {
    label: 'raceDetails-RaceRunnerPropositionHeadingContainer',
    shouldForwardProp: (prop) => !['showingPlaceOdds'].includes(prop),
    baseStyle: ({ theme }: any) => ({
      justifyContent: 'center',
      alignItems: 'center',
      px: '3',
      fontWeight: 'semibold',
      width: ['16', '20'],
      borderRadius: 'md',
      fontSize: 'sm',
      cursor: 'pointer',

      '& + &': { mr: 0 },

      ...(theme.themeName === EThemes.Wellbet && {
        color: 'black',
        fontSize: ['2xs', null, 'sm'],
        '&&': {
          w: '12',
          p: '0',
        },
      }),
      ...(theme.themeName === EThemes.Betgalaxy && {
        width: '12',
        px: '0',
        fontSize: ['2xs', null, 'xs'],
      }),

      ...(() => {
        try {
          return styleImports[themeName]?.RaceRunnerPropositionHeadingContainer;
        } catch (err) {
          return {};
        }
      })(),
    }),
  });

export const RaceHeaderWrapper = chakra(Box, {
  label: 'raceDetails-RaceHeaderWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.RaceHeaderWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const InfoContainer = chakra(Box, {
  label: 'raceDetails-InfoContainer',
  baseStyle: () => ({
    mt: '2',
    mb: '5',
    overflowX: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },

    ...(() => {
      try {
        return styleImports[themeName]?.InfoContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceAlertBanner = styled.div`
  background: grey;

  ${({ theme }) => theme.pages.raceDetails.RaceAlertBanner};
`;

export const RaceAlertBannerContent = styled.div`
  ${({ theme }) => theme.pages.raceDetails.RaceAlertBannerContent};
`;

export const RaceAlertText = styled.span`
  ${({ theme }) => theme.pages.raceDetails.RaceAlertText};
`;

export const RaceStatusText = chakra('span', {
  label: 'raceDetails-RaceStatusText',
  baseStyle: () => ({
    alignItems: 'center',
    borderRadius: '3px',
    textAlign: 'center',
    px: '1',
    py: '3px',
    fontSize: '2xs',
    fontWeight: 'bold',
    bg: 'gray.500',
    color: 'white',

    ...(() => {
      try {
        return styleImports[themeName]?.RaceStatusText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ResultedStatusLabel = chakra(Box, {
  label: 'raceDetails-ResultedStatusLabel',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    background: 'white',
    borderRadius: '3px',
    textAlign: 'center',
    px: '1',
    py: '3px',
    fontSize: '2xs',
    fontWeight: 'bold',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
      bg: 'beta.500',
      fontSize: 'xs',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      borderRadius: 'base',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ResultedStatusLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TitleWrapper = chakra(Flex, {
  label: 'raceDetails-TitleWrapper',
  baseStyle: () => ({
    justifyContent: 'space-between',
    flexDirection: ['column', 'row'],
    alignItems: ['baseline', 'center'],
  }),
});

export const CountdownTimerContainer = chakra(Box, {
  label: 'raceDetails-CountdownTimerContainer',
  baseStyle: ({ theme }) => ({
    lineHeight: '3',
    '& > .state-prior': {
      ...(theme.themeName === EThemes.Betgalaxy && {
        color: 'beta.700',
        verticalAlign: 'baseline',
        fontSize: ['xs', null, 'sm'],
      }),
    },

    '.chakra-badge.state-prior': {
      p: '0',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.CountdownTimerContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceCardHeading = styled.h3`
  ${({ theme }) => theme.pages.raceDetails.RaceCardHeading};
`;

export const ListHeadingsWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.pages.raceDetails.ListHeadingsWrapper};
`;

export const FlexPageHeaderTitleWrapper = chakra(Flex, {
  label: 'raceDetails-FlexPageHeaderTitleWrapper',
  baseStyle: () => ({
    label: 'FlexPageHeaderTitleWrapper',
    justifyContent: 'space-between',
    flexDirection: ['column', 'column', 'row'],
    gap: '1',
    alignItems: ['baseline', 'column', 'center'],
    mb: ['0', '0', '1.5'],

    ...(() => {
      try {
        return styleImports[themeName]?.FlexPageHeaderTitleWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexHeaderLeft = chakra(Flex, {
  label: 'raceDetails-FlexHeaderLeft',
  baseStyle: () => ({
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    lineHeight: 'normal',
    gap: '1',
    flex: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexHeaderLeft;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexHeaderMetaWrapper = chakra(Flex, {
  label: 'raceDetails-FlexHeaderMetaWrapper',
  baseStyle: () => ({
    alignItems: ['baseline', 'center'],
    lineHeight: 'normal',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexHeaderMetaWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextTitle = chakra(Text, {
  label: 'raceDetails-TextTitle',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const ShowMoreText = chakra(Text, {
  label: 'raceDetails-ShowMoreButton',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ShowMoreText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
