import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  color: 'white',
  h: 'full',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset',

  _hover: {
    bgGradient: 'linear(to-b, beta.800, beta.800)',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'white',
};
