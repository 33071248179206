import React from 'react';
import {
  Text,
  Flex,
  VStack,
  TabList,
  Tabs,
  TabPanels,
  useMediaQuery,
  Tooltip,
  Tab,
  Icon,
  TabPanel,
} from '@chakra-ui/react';
import { TFormData } from '@/lib/DBModels';
import { badgeStyles } from './styles/Badges.styles';
import { useBadges } from './services/Badges.hooks';

type TBadges = {
  form_data?: TFormData;
};

export default function Badges({ form_data }: TBadges) {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const { data } = useBadges({ badges: form_data?.badges });
  if (!form_data?.badges) return null;

  const renderMobile = (
    <Tabs {...badgeStyles.tabs}>
      <TabList border="none">
        {data?.map(({ icon, title }) => (
          <Tab key={title} {...badgeStyles.tabBadge}>
            <Flex {...badgeStyles.flexIconWrapper}>
              <Icon as={icon} {...badgeStyles.iconBadge} />
              <Text {...badgeStyles.iconTitle}>{title}</Text>
            </Flex>
          </Tab>
        ))}
      </TabList>

      <TabPanels {...badgeStyles.tabPanels}>
        {data?.map(({ description, title }) => (
          <TabPanel key={title} {...badgeStyles.tabPanelWrapper}>
            <Text {...badgeStyles.textDescription}>{description}</Text>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );

  const renderDesktop = data?.map(({ title, description, icon }) => (
    <Tooltip
      hasArrow
      placement="bottom-start"
      key={title}
      label={
        <VStack alignItems="start">
          <Text {...badgeStyles.textDescription}>{description}</Text>
        </VStack>
      }
      {...badgeStyles.tooltipWrapper}
    >
      <Flex {...badgeStyles.flexIconWrapper}>
        <Icon as={icon} {...badgeStyles.iconBadge} />
        <Text {...badgeStyles.iconTitle}>{title}</Text>
      </Flex>
    </Tooltip>
  ));

  return (
    <Flex gap="1" mt="1">
      {isLargerThan768 ? renderDesktop : renderMobile}
    </Flex>
  );
}
