import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  boxShadow: !isLast && '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});
export const BoxNextToJumpContainer: CSSObject = {
  bg: 'alpha.200',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset',
  mx: '0',
  borderRadius: 'md',
  mb: '2',
};
export const Icon: CSSObject = {
  boxSize: '8',
  color: 'alpha.800',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  border: 'none',
  borderRadius: 'md',
  p: '2',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset',
};
export const TextRace: CSSObject = {
  color: 'gamma.200',
  textTransform: 'uppercase',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'normal',
};
export const TextNumber: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'alpha.700',
  color: 'gamma.400',
  py: '0',
  pt: '0.5',
  borderRadius: 'base',
  '&&': {
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
  },
};
export const BoxHeading: CSSObject = {
  display: 'none',
};
export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    color: ['white', 'beta.900'],
  },
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    py: '0',
    mr: '0.5',
    color: 'alpha.800',
  },
};
