import { FavoritesSchema } from './Favorites.styles';
import favIcon from '@/assets/betprofessor/images/racing/fav.svg';

export const favoriteStyles: FavoritesSchema = {
  wrapper: {
    order: 1,
    gap: '2',
    mt: '3',
    mb: '1',
    flexDirection: ['column', null, 'row'],
    mx: ['2', null, 'unset'],
  },
  container: {
    flex: '1',
    // @TODO Remove this when we get homepage API
    sx: {
      ':not(:has(div + div))': {
        display: 'none',
      },
    },
  },
  headerContainer: {
    mb: '4',
  },
  header: {
    color: 'alpha.400',
    fontFamily: 'PatrickHand',
    textAlign: 'center',
    fontSize: 'sm',
    textTransform: 'uppercase',
    letterSpacing: '4.2px',
  },
  subHeader: {
    fontFamily: 'Fredoka',
    fontSize: 'normal',
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: '0.5px',
    textAlign: 'center',
    lineHeight: '80%',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.15)',
    textTransform: 'uppercase',
    color: '#fff',
    mb: '-2px',
  },
  btn: {
    pt: '3.5',
    borderTop: 'none',
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.100',
    borderLeft: 'none',
    borderRight: 'none',
    _before: {
      // content only supports plain text
      content: `url(${favIcon}) ""`,
      pos: 'absolute',
      bg: 'alpha.800',
      color: 'alpha.300',
      fontSize: '2xs',
      left: 0,
      right: 0,
      top: 0,
      textShadow: 'none',
      border: '1px',
      borderColor: 'blackAlpha.100',
      borderTopRadius: '5px',
    },
  },
};
