import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { getEventRule } from '../../../../../components/Betslip/Services/Betslip.utils';
import {
  RaceDetailsColumns,
  TRaceRunnerSortData,
} from '../../../RaceDetails/types';
import { setRaceRunnerList } from '../../../services/races.slices';
import { EGeneralStatus, TRunner } from '../../../../../lib/DBModels';
import { DeepPartial } from '../../../../../lib/types';

/**
 * TODO:
 * Dont copy what I have done below!
 *
 * The poll hook within src/features/racing/pages/RaceDetails/RaceDetails.tsx
 * should be refactored and dispatch/store the response, once that has
 * been complete we can access the updated data using a useAppSelector hook.
 *
 * This will also need to be ordered, that should be handled within the slice.
 */
export const useTable = () => {
  const dispatch = useAppDispatch();

  const [sortObj, setSortObj] = useState<TRaceRunnerSortData>({
    col: 'number',
    order: 'DESC',
  });

  const { raceRunnerList } = useAppSelector((state) => state.racing);

  const sortScratchedAndNormalRunners = useCallback(
    (
      existingRunners: DeepPartial<TRunner>[],
      sortData: TRaceRunnerSortData
    ) => {
      const nonScratchedRunners = existingRunners.filter(
        (runner) => runner.status !== EGeneralStatus.Scratched
      );
      const scratchedRunners = existingRunners.filter(
        (runner) => runner.status === EGeneralStatus.Scratched
      );

      const newRunnerList = [
        ...(sortData.order === 'DESC'
          ? nonScratchedRunners.sort(
              (a, b) => (a?.[sortData.col] ?? 0) - (b?.[sortData.col] ?? 0)
            )
          : nonScratchedRunners.sort(
              (a, b) => (b?.[sortData.col] ?? 0) - (a?.[sortData.col] ?? 0)
            )),
        ...(sortData.order === 'DESC'
          ? scratchedRunners.sort(
              (a, b) => (a?.[sortData.col] ?? 0) - (b?.[sortData.col] ?? 0)
            )
          : scratchedRunners.sort(
              (a, b) => (b?.[sortData.col] ?? 0) - (a?.[sortData.col] ?? 0)
            )),
      ];
      return newRunnerList;
    },
    []
  );

  // Sorting Function for race runner list
  const sortRunnerList = useCallback(
    (col: RaceDetailsColumns) => {
      if (!raceRunnerList?.raceRunners) return;
      setSortObj({ col, order: sortObj.order === 'ASC' ? 'DESC' : 'ASC' });

      const newState = { ...raceRunnerList };
      newState.raceRunners = sortScratchedAndNormalRunners(
        raceRunnerList?.raceRunners,
        { col, order: sortObj.order === 'ASC' ? 'DESC' : 'ASC' }
      );

      dispatch(setRaceRunnerList(newState));
    },
    [dispatch, raceRunnerList, sortObj, sortScratchedAndNormalRunners]
  );
  const eventRule = getEventRule(raceRunnerList.raceMeta.number_of_places);

  return {
    sortRunnerList,
    sortObj,
    sortScratchedAndNormalRunners,
    eventRule,
  };
};
