import {
  ButtonProps,
  DividerProps,
  FlexProps,
  IconProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './OnRaceBets.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type OnRaceBetsSchema = Partial<{
  wrapper: FlexProps;
  headerWrapper: FlexProps;
  headerText: TextProps;
  headerCountText: TextProps;
  headerExpandButton: Partial<ButtonProps>;
  toggleIcon: Partial<IconProps>;
  betsContainer: FlexProps;
  betRow: FlexProps;
  betRowDivider: FlexProps;
  groupDivider: FlexProps;
  divider: DividerProps;
  betCategoryWrapper: FlexProps;
  betCategory: TextProps;
  betCategoryCount: TextProps;
  betCount: TextProps;
  categoryIcon: Partial<IconProps>;
  groupContainer: FlexProps;
  groupTitleText: TextProps;
  srmBlendedLegContainer: FlexProps;
  srmBlendedRunnerText: TextProps;
  srmMarketText: TextProps;
  subtitleText: TextProps;
  potentialWinningsText: TextProps;
  stakeText: TextProps;
  runnerText: TextProps;
  runnerBarrierText: TextProps;
  exoticsPositionText: TextProps;
  viewMoreButton: ButtonProps;
  viewMoreButtonText: TextProps;
  srmBlendedWrapper: FlexProps;
  flexiText: TextProps;
  betsAndViewMoreButtonWrapper: FlexProps;
  flexiStakeValueText: TextProps;
  flexiStakeText: TextProps;
}>;

export const onRaceBetStyles: OnRaceBetsSchema = {
  betsAndViewMoreButtonWrapper: {
    'data-testid': 'onRaceBet-betsAndViewMoreButtonWrapper',

    flexDir: 'column',

    ...(styleImports?.[themeName]?.onRaceBetStyles
      ?.betsAndViewMoreButtonWrapper ?? {}),
  },
  wrapper: {
    'data-testid': 'onRaceBet-wrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerWrapper: {
    'data-testid': 'onRaceBet-headerWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.headerWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerText: {
    'data-testid': 'onRaceBet-headerText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.headerText;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerCountText: {
    'data-testid': 'onRaceBet-headerCountText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.headerCountText;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerExpandButton: {
    'data-testid': 'onRaceBet-headerExpandButton',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.headerExpandButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  toggleIcon: {
    'data-testid': 'onRaceBet-toggleIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.toggleIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  betsContainer: {
    'data-testid': 'onRaceBet-betsContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.betsContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  betRow: {
    'data-testid': 'onRaceBet-betRow',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.betRow;
      } catch (err) {
        return {};
      }
    })(),
  },
  betRowDivider: {
    'data-testid': 'onRaceBet-betRowDivider',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.betRowDivider;
      } catch (err) {
        return {};
      }
    })(),
  },
  groupDivider: {
    'data-testid': 'onRaceBet-groupDivider',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.groupDivider;
      } catch (err) {
        return {};
      }
    })(),
  },
  divider: {
    'data-testid': 'onRaceBet-divider',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.divider;
      } catch (err) {
        return {};
      }
    })(),
  },
  betCategoryWrapper: {
    'data-testid': 'onRaceBet-betCategoryWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.betCategoryWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  betCategory: {
    'data-testid': 'onRaceBet-betCategory',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.betCategory;
      } catch (err) {
        return {};
      }
    })(),
  },
  betCategoryCount: {
    'data-testid': 'onRaceBet-betCategoryCount',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.betCategoryCount;
      } catch (err) {
        return {};
      }
    })(),
  },
  betCount: {
    'data-testid': 'onRaceBet-betCount',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.betCount;
      } catch (err) {
        return {};
      }
    })(),
  },
  categoryIcon: {
    'data-testid': 'onRaceBet-categoryIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.categoryIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  groupContainer: {
    'data-testid': 'onRaceBet-groupContainer',

    borderBottomWidth: '1px',
    _last: {
      borderBottomWidth: '0px',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.groupContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  groupTitleText: {
    'data-testid': 'onRaceBet-groupTitleText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.groupTitleText;
      } catch (err) {
        return {};
      }
    })(),
  },
  srmBlendedLegContainer: {
    'data-testid': 'onRaceBet-srmBlendedLegContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.srmBlendedLegContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  srmBlendedRunnerText: {
    'data-testid': 'onRaceBet-srmBlendedRunnerText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.srmBlendedRunnerText;
      } catch (err) {
        return {};
      }
    })(),
  },
  srmMarketText: {
    'data-testid': 'onRaceBet-srmMarketText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.srmMarketText;
      } catch (err) {
        return {};
      }
    })(),
  },
  subtitleText: {
    'data-testid': 'onRaceBet-subtitleText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.subtitleText;
      } catch (err) {
        return {};
      }
    })(),
  },
  potentialWinningsText: {
    'data-testid': 'onRaceBet-potentialWinningsText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.potentialWinningsText;
      } catch (err) {
        return {};
      }
    })(),
  },
  runnerText: {
    'data-testid': 'onRaceBet-runnerText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.runnerText;
      } catch (err) {
        return {};
      }
    })(),
  },
  runnerBarrierText: {
    'data-testid': 'onRaceBet-runnerBarrierText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.runnerBarrierText;
      } catch (err) {
        return {};
      }
    })(),
  },
  stakeText: {
    'data-testid': 'onRaceBet-stakeText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.stakeText;
      } catch (err) {
        return {};
      }
    })(),
  },
  exoticsPositionText: {
    'data-testid': 'onRaceBet-exoticsPositionText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.exoticsPositionText;
      } catch (err) {
        return {};
      }
    })(),
  },
  viewMoreButton: {
    'data-testid': 'onRaceBet-viewMoreButton',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.viewMoreButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  viewMoreButtonText: {
    'data-testid': 'onRaceBet-viewMoreButtonText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.viewMoreButtonText;
      } catch (err) {
        return {};
      }
    })(),
  },
  srmBlendedWrapper: {
    'data-testid': 'onRaceBet-srmBlendedWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.srmBlendedWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexiText: {
    'data-testid': 'onRaceBet-flexiText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.flexiText;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexiStakeText: {
    'data-testid': 'onRaceBet-flexiStakeText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.flexiStakeText;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexiStakeValueText: {
    'data-testid': 'onRaceBet-flexiStakeValueText',
    ...(() => {
      try {
        return styleImports[themeName]?.onRaceBetStyles?.flexiStakeValueText;
      } catch (err) {
        return {};
      }
    })(),
  },
};
