import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TextCheckbox: CSSObject = {
  color: 'white',
  mb: '0',
  '& span': {
    color: 'white',
  },
};

export const buttonCloseAccountProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
};
