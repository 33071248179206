import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  footer: {
    pt: '0',
    bg: 'transparent',
    bgSize: 'cover',
    bgPos: 'bottom',
    color: 'white',
  },
  stackGap: '0',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    pr: '0',
    mb: ['4', '8'],
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
    sx: {
      '& > img': {
        maxW: '131px',
        h: '32px',
        ml: '0',
        mt: 0,
      },
    },
  },
  footerMobile: {
    border: '2px',
    borderColor: 'white',
    borderRadius: 'lg',
    w: '130px',
    cursor: 'pointer',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.3)',
  },
  footerText: {
    fontSize: 'sm',
  },
  footerLink: {
    color: 'beta.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: 'sm',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
  },
  footerLinkTel: {
    color: 'beta.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: 'sm',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
  },
  customFooterLink: {
    color: 'beta.500',
    textTransform: 'capitalize',
    cursor: 'pointer',
    display: 'inline-block',
    mb: '1px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: 'sm',
    h: ['unset'],
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
  },
  footerLinks: {
    ml: ['unset', null, 'auto'],
    pb: ['4', null, '0'],
    alignItems: 'center',
    justifyContent: ['space-around', null, 'unset'],
    color: 'white',
    fontWeight: 'medium',
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
    mt: ['3', null],
    gap: '2',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
  },
  footerContentContainer: {
    px: ['2.5', 'unset'],
    gap: ['2', '6'],
    bg: [null, null, 'transparent'],
    justifyContent: 'space-between',
    mb: '2',
    sx: {
      '&': {
        flexDirection: ['column', null, 'column'],
      },
    },
  },
  nav: {
    fontSize: 'sm',
    fontWeight: 'medium',
    minW: [null, null, '370px'],
    mt: ['0'],
    display: ['flex', 'grid'],
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '28',
    rowGap: '0',
    alignItems: ['center', 'end'],
    gridTemplateAreas: `
        ". ."
      `,
    sx: {
      '> button': {
        h: ['unset'],
        fontWeight: 'medium',
      },

      '> a': {
        color: 'white',
        alignItems: 'center',
      },
    },
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', 'left'],
    color: 'white',
    width: 'unset',
    mb: '0',
    ml: [null, '0'],
    mr: [null, null, '4'],
  },
  blurbHeading: {
    ml: 'unset',
    mt: 'unset',
    color: 'white',
  },
  blurbHeadingAge: {
    borderColor: 'blackAlpha.50',
    border: '4px',
    mb: '2',
    boxSize: '8',
    fontSize: 'xs',
  },
  blurbHeadingThink: {
    fontSize: 'xs',
    fontWeight: 'bold',
    mb: '4',
  },
  blurbContent: {
    ml: 'unset',
    mr: [null, null, 'auto'],
  },
  copyright: {
    color: 'white',
    textAlign: ['center', 'left'],
    mt: ['7'],
    px: ['8', 'unset'],
    pb: '2',
    fontWeight: ['semibold', 'medium'],
  },
  container: {
    bg: 'transparent',
    borderRadius: ['0', '0', '8'],
    mt: '3',
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  emailUs: {
    fontSize: 'md',
    fontWeight: 'bold',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
  },
  quickLinks: {
    color: 'beta.500',
    fontSize: '14px',
    textAlign: ['center', 'left'],
    textTransform: 'uppercase',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
    pb: '2',
  },
  ourPartners: {
    color: 'beta.500',
    fontSize: '14px',
    textTransform: 'uppercase',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
    pb: '2',
  },
  weAccept: {
    color: 'beta.500',
    fontSize: '14px',
    textTransform: 'uppercase',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.30)',
    pb: '2',
  },
  weAcceptBox: {
    mb: '2',
  },
  partnerWrapper: {
    mr: 'unset',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.30)',
  },
  footerDownloadImage: {
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.30)',
    border: 'none',
  },
};
