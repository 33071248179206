export const Checkbox = {
  parts: ['container', 'icon', 'control', 'label'],
  baseStyle: {
    control: {
      _disabled: {
        bg: 'transparent',
      },
      _checked: {
        _disabled: {
          borderColor: 'gray.300',
          bg: 'transparent',
        },
        _hover: {
          borderColor: 'gray.300',
          bg: 'gray.50',
        },
        color: 'gray.700',
        borderColor: 'gray.300',
        bg: 'white',
      },
    },
    container: {
      w: 'full',
      alignItems: 'flex-start',
    },
  },
  sizes: {
    lg: {
      label: { fontSize: 'sm' },
      icon: { fontSize: 'xs' },
    },
    md: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    sm: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    xs: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '3xs' },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
