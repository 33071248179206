import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};
export const FlexWrapper: CSSObject = {};
export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};
export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};
export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  color: 'alpha.400',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  textOverflow: 'ellipsis',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    'div[class*="FlexDetails"], p[class*="RaceNumber"],svg[class*="Icon"]': {
      color: 'beta.700',
    },
  },
  '.chakra-badge.state-inprogress': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '.chakra-badge.state-prior': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '.chakra-badge.state-ended': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
};
export const RaceNumber: CSSObject = {
  color: 'alpha.400',
};
export const FlexDetails: CSSObject = {
  color: 'alpha.400',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
export const Icon: CSSObject = {
  color: 'alpha.400',
};
export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};
export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  color: 'alpha.400',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    color: 'beta.700',
  },
  '& > svg': { boxSize: '6', boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.3)' },
};
