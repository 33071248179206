import React from 'react';
import { produce } from 'immer';
import { TTemplate } from '@/helpers/createTemplate';
import welcomeVideo from '@/assets/wellbet/animations/welcomeVideo.webm';
import welcomeVideoFallback from '@/assets/wellbet/animations/welcomeVideoFallback.mp4';
import { TPageSpecificContent } from '@/views/onboarding/services/onboarding.types';
import baseTemplate from './template.default';
import Video from '../components/Video';

const template = (pageSpecificContent: TPageSpecificContent): TTemplate =>
  produce(baseTemplate(pageSpecificContent), (draftTemplate) => ({
    ...draftTemplate,
    video: (
      <Video
        sources={[
          {
            src: welcomeVideo,
            type: 'video/webm',
          },
          {
            src: welcomeVideoFallback,
            type: 'video/mp4',
          },
        ]}
      />
    ),
  }));

export default template;
