import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  mb: '0',
  px: '2.5',
  bg: 'white',
  borderRadius: 'base',
  flex: '1',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceResultsHeader: CSSObject = {
  color: 'gray.700',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.500, alpha.700)',
  color: 'beta.400',
  boxShadow:
    '0px 2px 2px 0px rgba(137, 173, 241, 0.60) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.50)',
  fontSize: '2xs',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'gray.700',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '30px',
  bg: 'gray.300',
  border: 'none',
  borderRadius: 'base',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  color: 'gray.500',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  fontWeight: 'black',
  fontFamily: 'Roboto',
  fontSize: 'xs',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
  alignItems: 'center',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
};
