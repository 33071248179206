import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/bet777/images/feature/featBg.jpg';
import featBgMb from '@/assets/bet777/images/feature/featBgMb.jpg';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    minW: 12,
    fontSize: '15px',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, gamma.300, gamma.500)',
    boxShadow:
      '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
    backdropFilter: 'blur(2.5px)',
  },
  svgMoreRacesArrowProps: {
    color: 'white',
    boxSize: '4',
  },
};

export const BoxCard: CSSObject = {
  p: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPos: ['top', null, 'unset'],
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', 'md'],
    boxShadow: [
      'unset',
      '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    ],
    borderBottomRadius: ['none', 'md'],
    m: ['px', 'unset'],
  },
  bgGradient: [
    'linear(to-b, rgba(43, 4, 92, 0.2), rgba(129, 83, 255, 0.5))',
    null,
    'unset',
  ],
  boxShadow: [
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    'unset',
  ],
  contain: 'unset',
  flex: '2',
  pos: 'relative',
  flexDir: 'column',
  borderRadius: 'md',
  minH: '250px',
  py: '4',
  px: '3',
  mx: '0',
  mb: 0,
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['0', null, '60px'],
  mt: ['40px', null, '0'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: {
    base: `"."`,
    md: `". . ."`,
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  _last: {
    ':not(:only-of-type)': {
      fontSize: '2xs',
      fontWeight: 'normal',
      pos: 'relative',
      top: '-2px',
    },
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'gamma.400',
  fontFamily: 'Staatliches',
  fontSize: '3xl',
  textTransform: 'uppercase',
  w: ['full', null, 'unset'],
  textAlign: ['center', null, 'unset'],
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'beta.400',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: 'lg',
  color: 'white',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'accent',
  bg: 'gamma.400',
  borderRadius: 'sm',
  color: 'blackAlpha.700',
  px: '1',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '50px',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  color: 'white',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(2.5px)',
};

export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
