import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useHeader } from '../../services/Header.hooks';
import { centsToDollars } from '@/helpers/utils';
import { BonusBalanceIcon } from './styles/QuickAccessMenu.styles';

export const TextShowHideBalance = ({
  showBalance,
}: {
  showBalance: boolean;
}) => {
  const { accountBallance, accountBonusBalance } = useHeader();

  return showBalance ? (
    <Flex flexDir="column" alignItems="flex-start">
      <Text as="span" className="span-balance" data-cy="header-account-balance">
        {centsToDollars(accountBallance ?? 0)}
      </Text>
      <Text as="span" className="span-bonus" data-cy="header-bonus-balance">
        <BonusBalanceIcon />
        {centsToDollars(accountBonusBalance ?? 0)}
      </Text>
    </Flex>
  ) : (
    <Text as="span" className="account-text">
      <FormattedMessage id="generic.account" />
    </Text>
  );
};
