import { Box, chakra } from '@chakra-ui/react';
import { styleImports } from './Info.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const InfoExoticsContainer = chakra(Box, {
  label: 'info-InfoExoticsContainer',
  baseStyle: () => ({
    w: '100%',
    px: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.InfoExoticsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
