import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  bg: state === 'inprogress' ? 'beta.500' : 'red.500',
  pb: '0.5',
  '&&': {
    minH: '4',
    h: '4',
    maxH: '4',
    boxShadow: 'none',
    lineHeight: '4',
    fontSize: '9px',
    fontFamily: 'body',
    color: state === 'inprogress' ? 'black' : 'white',
    borderRadius: 'base',
  },
});

export const CountdownFlex: CSSObject = {
  borderRadius: 'base',
};
