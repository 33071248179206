import Black from '@/assets/betblast/fonts/Roboto-Black.ttf';
import BlackItalic from '@/assets/betblast/fonts/Roboto-BlackItalic.ttf';
import Bold from '@/assets/betblast/fonts/Roboto-Bold.ttf';
import BoldItalic from '@/assets/betblast/fonts/Roboto-BoldItalic.ttf';
import ExtraLight from '@/assets/betblast/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '@/assets/betblast/fonts/Roboto-ThinItalic.ttf';
import Light from '@/assets/betblast/fonts/Roboto-Light.ttf';
import LightItalic from '@/assets/betblast/fonts/Roboto-LightItalic.ttf';
import Regular from '@/assets/betblast/fonts/Roboto-Regular.ttf';
import Italic from '@/assets/betblast/fonts/Roboto-Italic.ttf';
import SemiBold from '@/assets/betblast/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '@/assets/betblast/fonts/Roboto-MediumItalic.ttf';
import OrbitronBlack from '@/assets/betblast/fonts/Orbitron-Black.ttf';
import OrbitronRegular from '@/assets/betblast/fonts/Orbitron-Regular.ttf';
import OrbitronMedium from '@/assets/betblast/fonts/Orbitron-Medium.ttf';
import OrbitronExtrabold from '@/assets/betblast/fonts/Orbitron-ExtraBold.ttf';
import OrbitronBold from '@/assets/betblast/fonts/Orbitron-Bold.ttf';
import OrbitronSemibold from '@/assets/betblast/fonts/Orbitron-SemiBold.ttf';

export const fontFace = `
        @font-face {
          font-family: 'Orbitron';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${OrbitronBlack}') format('opentype');
        }
        @font-face {
          font-family: 'Orbitron';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${OrbitronRegular}') format('opentype');
        }
        @font-face {
          font-family: 'Orbitron';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('${OrbitronMedium}') format('opentype');
        }
        @font-face {
          font-family: 'Orbitron';
          font-style: normal;
          font-weight: 800;
          font-display: swap;
          src: url('${OrbitronExtrabold}') format('opentype');
        }
        @font-face {
          font-family: 'Orbitron';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url('${OrbitronSemibold}') format('opentype');
        }
        @font-face {
          font-family: 'Orbitron';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${OrbitronBold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLight}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${Light}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 300;
          font-display: swap;
          src: url('${LightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${Regular}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 400;
          font-display: swap;
          src: url('${Italic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${Bold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 700;
          font-display: swap;
          src: url('${BoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${Black}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 900;
          font-display: swap;
          src: url('${BlackItalic}') format('opentype');
        }
      `;
