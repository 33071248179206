import { css } from 'styled-components';

// TODO: remove
export const heading = css`
  color: ${({ theme }) => theme.palette.primary};
  text-transform: uppercase;
  font-size: var(--bc-fontSizes-xl);
  font-family: ${({ theme }) => theme.fontFamily.heading};
  margin-bottom: 22px;
`;

// TODO: remove
export const subheading = css`
  font-size: var(--bc-fontSizes-xs);
  color: ${({ theme }) => theme.palette.white};
  line-height: 17px;
  margin-bottom: 12px;
`;

export const limitWrapper = css``;

// TODO: remove
export const limitLabel = css`
  color: ${({ theme }) => theme.palette.primary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: var(--bc-fontSizes-md);
`;

export const limitValue = css`
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-md);
  margin-left: 8px;
  line-height: 22px;
  margin-top: 7px;
  margin-bottom: 15px;
`;

export const modalContentWrapper = css``;

export const modalHeading = css`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  color: ${({ theme }) => theme.palette.secondary};
  font-size: var(--bc-fontSizes-md);
  text-align: center;
  margin-bottom: var(--bc-sizes-1);
`;

export const modalSubheading = css`
  text-align: center;
  font-size: var(--bc-fontSizes-2xs);
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin-top: var(--bc-sizes-1);
`;

export const buttonWrapper = css`
  margin-top: var(--bc-sizes-2);
`;

export const toolbarContainer = css`
  // width: 100vw;
  margin-top: -var(--bc-sizes-6);
  margin-bottom: var(--bc-sizes-6);

  ${({ theme }) => theme.device.tablet} {
    margin-top: -var(--bc-sizes-14);
  }
`;
