import React from 'react';
import { Image } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import { BoxHeadingWrapper, FlexWrapper } from '../styles/Featured.styles';
import limits from '@/assets/forsport/images/homeFeaturedSport/limits.png';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  slogan: {
    body: (
      <BoxHeadingWrapper>
        <Image src={limits} m="auto" w={['260px', null, '325px']} />
      </BoxHeadingWrapper>
    ),
    feature: <FeaturedMatch />,
  },
};

export default template;
