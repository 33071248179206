import dayjs from 'dayjs';
import {
  EDepositStatus,
  TCheckDepositResponse,
  TFZCustomer,
  TFZTokenizationSuccess,
  TResponseCard,
} from '@/api/deposit/deposit.types';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { dollarsToCents } from '@/helpers/utils';
import { FatZebra, loadFatZebraScript } from './fatzebra';
import {
  mutateCancelDeposit,
  mutateCompleteDeposit,
  mutateCreateDeposit,
} from '@/api/deposit/deposit';
import { queryPunterAccount } from '@/api/punter/punter';
import { makeResolver } from '@/lib/promiseResolver';
import { TModalFZData } from '../Components/Modal';
import { postMobileAppMessage } from '@/mobileapp/mobileapp.utils';
import { ENewRelicActions } from '@/constants/newRelicActions';
import { trackEvent } from '@/lib/analytics';
import { logPageAction } from '@/lib/newRelic';

/**
 * Checks if a card has expired based on the expiry date.
 * @param expiryDate The expiry date of the card in the format 'YYYY-MM-DD'.
 * @returns True if the card is expired, false otherwise.
 */
export const isExpired = (expiryDate: string) => {
  // Get the current date
  const today = dayjs().format('YYYY-MM-DD');

  // Check if the expiryDate is before today's date
  const isCardExpired = dayjs(expiryDate).isBefore(today, 'day');

  // Return true if the card is expired, false otherwise
  return isCardExpired;
};

export const handleDeposit = async (
  loadAuthTokenTask: Promise<void>,
  selectedCard: TResponseCard,
  inputAmount: number,
  opted_in_promo?: string,
  depositRef?: React.MutableRefObject<TModalFZData>,
  renderIframeContainer?: () => void
) => {
  let deposit: TCheckDepositResponse | null = null;
  let punterId: string | undefined;
  let payInProgress = false;

  try {
    const punterTask = queryPunterAccount();
    await loadAuthTokenTask;
    const amount = Number(dollarsToCents(inputAmount).toFixed(0));
    deposit = await mutateCreateDeposit({
      amount,
      ...(opted_in_promo ? { opted_in_promo } : {}),
    });

    await loadFatZebraScript();

    const fz = new FatZebra();

    const punter = await punterTask;
    punterId = punter.punter_id;
    const customer: TFZCustomer = {
      firstName: punter.first_name,
      lastName: punter.last_name,
      email: punter.email,
      address: `${punter.street_number} ${punter.street_name}`,
      city: punter.suburb,
      postcode: punter.post_code,
      state: punter.state,
      country: 'AU',
    };

    const card = { ...selectedCard };

    if (card.card_token === 'addCard') {
      const tokenizeResolver = makeResolver<TFZTokenizationSuccess>();

      if (depositRef) {
        // eslint-disable-next-line no-param-reassign
        depositRef.current = {
          fz,
          deposit,
          customer,
          amount,
          tokenizeResolver,
        };
      }

      if (renderIframeContainer) renderIframeContainer();

      const token = await tokenizeResolver.promise;
      card.card_token = token.detail.data.token;
      await fz.reload();
    }

    const scaVerification = await fz.verifyCard(
      card.card_token,
      customer,
      amount,
      deposit.currency ?? 'AUD',
      deposit.verification,
      deposit.deposit_id
    );

    payInProgress = true;
    const resp = await mutateCompleteDeposit({
      card_id: card.card_id === 'addCard' ? undefined : card.card_id,
      card_token: card.card_id === 'addCard' ? card.card_token : undefined,
      amount,
      deposit_id: deposit.deposit_id,
      extra_data: scaVerification.detail.data,
      details: scaVerification.detail,
    });
    payInProgress = false;

    if (IS_MOBILE_APP) {
      postMobileAppMessage('deposit_funds', {
        firstTimeDeposit: resp.is_first_deposit,
      });
      return;
    }

    trackEvent(resp.is_first_deposit ? 'firstTimeDeposited' : 'deposited', {
      punterId: punter.punter_id,
    });
  } catch (e: any) {
    if (deposit)
      try {
        let status = e.status || EDepositStatus.Cancelled;
        if (payInProgress && !(e.response?.status in [400, 418]))
          status = EDepositStatus.Unknown;

        await mutateCancelDeposit({
          deposit_id: deposit.deposit_id,
          status,
          reason:
            e.reason || (e.detail?.errors && e.detail?.errors[0]) || String(e),
          details: e.detail || e.response?.data,
        });

        return;
      } catch (err: any) {
        if (err.response?.status !== 400)
          logPageAction(ENewRelicActions.Deposit, {
            event_type: 'cancel_deposit_failure',
            payload: err.response?.data,
            punter_id: punterId,
          });
      }

    throw e;
  }
};
