import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Text, Box } from '@chakra-ui/react';
import PrimaryWrapper from '@/wrappers/PrimaryWrapper';
import PromotionsContainer from './components/Promotions/Promotions';
import SpecialsContainer from './components/Specials/Specials';
import { promosBaseStyles } from './styles/Promos.styles';

export default function Promosv2() {
  const intl = useIntl();

  return (
    <PrimaryWrapper
      pageTitle={intl.formatMessage({
        id: 'account.promos.pageTitle',
      })}
      pageHeader="Promotions & Featured Markets"
    >
      <Box {...promosBaseStyles.container}>
        <Text {...promosBaseStyles.body}>
          <FormattedMessage id="helpcentre.promotions.description" />
        </Text>
        <SpecialsContainer />
        <PromotionsContainer />
      </Box>
    </PrimaryWrapper>
  );
}
