import { CSSObject } from '@chakra-ui/react';
import onboardingWelcome from '../../../assets/betroyale/images/backgrounds/onboardingWelcome.png';
import onboardingMobile from '../../../assets/betroyale/images/backgrounds/onboardingMobile.png';

export const TextTitle: CSSObject = {
  display: 'none',
};

export const TextSubtitle: CSSObject = {
  fontSize: 'sm',
  lineHeight: '4',
  flex: '1',
  mt: ['unset', null, '56'],
  color: 'white',
  textAlign: 'center',
  span: {
    color: 'beta.500',
  },
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  mb: [null, null, '8'],
  mt: '56',
  color: 'white',
  'a span': {
    color: 'beta.500',
  },
};

export const TextCTALink: CSSObject = {
  color: 'alpha.500',
  fontWeight: 'bold',
};

export const FlexAsideInfo: CSSObject = {
  gridArea: '1/1',
  flexDir: 'column',
  alignItems: ['center', 'unset'],
  zIndex: 'overlay',
  m: ['unset', null, '10'],
  mt: ['2', null, '10'],
  color: 'black',
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  minH: ['336px', '682px'],
};

export const FlexCTAContainer: CSSObject = {
  justifyContent: 'center',
  alignSelf: ['center', null, 'unset'],
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    backgroundImage: `url(${onboardingMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '@media (min-width: 480px)': {
      background: `url('${onboardingWelcome}'), var(--bc-colors-alpha-900)`,
      backgroundPosition: 'top, bottom',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
    },
  },
};

export const FirstWord: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-400) 0%, var(--bc-colors-alpha-700) 100%)',
  backgroundClip: 'text',
  fontFamily: 'heading',
};

export const TitleContainer: CSSObject = {
  '&:after': { content: '","' },
  display: ['block', 'initial', 'initial'],
};
