import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'alpha.300', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'alpha.300'] },
};

export const FlexHeadingRow: CSSObject = {
  bgGradient: 'linear(to-b, zeta.400, zeta.600)',
};

export const FlexHeading: CSSObject = {
  color: 'beta.500',
  bgGradient: 'linear(to-b, zeta.400, zeta.600)',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
  top: ['12', '84px'],
  borderTopRadius: 'base',
  position: 'inherit',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'beta.500',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: '4px',
  p: '1.5',
};

export const BoxPageWrapper: CSSObject = {
  mx: ['2', '4'],
  mb: '4',
  borderRadius: 'base',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: 'md',
  p: '0',
  bg: ['none', 'zeta.400'],
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.700',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '133%',
  color: 'alpha.700',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.300',
};

export const TextRaceResults: CSSObject = {
  color: 'alpha.700',
  borderRadius: '3px',
  px: '4px',
  py: '3px',
};

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'alpha.400' : 'transparent',
  borderColor: 'zeta.600',
  _hover: {
    background: ['transparent', null, 'zeta.500'],
  },
  '.chakra-badge.state-inprogress': {
    bg: 'delta.600',
    color: 'gamma.700',
  },
  '.chakra-badge.state-prior': {
    color: 'alpha.700',
    bg: 'blackAlpha.300',
  },
});

export const BadgeRaceItem = (): CSSObject => ({
  borderRadius: '3px',
  bg: 'blackAlpha.400',
});

export const FlexRow: CSSObject = {
  bg: 'zeta.400',
  _last: {
    '& > *': {
      borderBottom: ['unset', null, 'unset'],
    },
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'zeta.600'],
  bg: 'zeta.400',
};

export const CenterTH = () => ({
  bg: 'alpha.800',
  color: 'white',
  textAlign: 'center',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  py: '2.5',
});

export const ButtonChevron: CSSObject = {
  color: 'beta.500',
  bg: 'alpha.500',
  borderRadius: 'base',
  boxSize: '5',
  width: '9',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
