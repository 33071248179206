import evenShotBg from '@/assets/sterlingparker/images/backgrounds/evenshot/bg-pattern.png';
import evenShot from '@/assets/sterlingparker/images/backgrounds/evenshot/evenshot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  notAvailableText: {
    color: 'alpha.500',
  },
  notYetAvailableText: {
    color: 'alpha.500',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: '#FAB735',
    fontWeight: 'bold',
    fontSize: 'xl',
  },
  oddsText: {
    mt: '2',
    mb: '4',
    fontWeight: 'bold',
    fontSize: 'xl',
  },
  flexWrapper: {
    boxSize: 'full',
    bgColor: '#1C1D21',
    color: 'white',
    p: '10',
    bgImage: `url(${evenShotBg})`,
    bgSize: '200px',
    bgRepeat: 'repeat',
    border: 'none',
    bgPos: '0% 0%',
  },
  headingText: {
    fontFamily: 'CrimsonPro',
    fontWeight: 'normal',
    bgClip: 'text',
    bgGradient: 'linear(to-b, #FFCE70, #CC952B)',
    fontSize: '5xl',
    mb: '1',
  },
  addToBetslipButton: {
    variant: 'solid',
  },
  loginToView: {
    variant: 'solid',
  },
  evenShotImage: {
    h: ['170px', '240px'],
    w: ['150px', '220px'],
    my: '6',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
};
