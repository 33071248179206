import { CSSObject } from '@chakra-ui/react';

export const LinkForgotPassword: CSSObject = {
  color: 'alpha.600',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '500',
  pos: 'relative',
  top: '-1',
};

export const LoginButton: CSSObject = {
  mt: '1',
};
