import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const BoxWrapper: CSSObject = {
  px: ['2', '3'],
};

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: 'none',
  border: 0,
  mb: '2',

  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',

    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  py: '2',
  bg: 'alpha.100',
  borderBottomRadius: 'base',
  px: '2',
  ...(isNested && {
    bg: 'alpha.500',
    borderColor: 'alpha.700',
    border: '1px',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  color: 'beta.500',
  px: '2',
  py: '2.5',
  border: 'none',
  transition: 'all 0.2s ease-in-out',
  borderTopRadius: 'base',
  borderBottomRadius: 'none',
  fontWeight: 'bold',
  fontSize: 'sm',

  _hover: {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
  },

  '.chakra-accordion__icon': {
    boxSize: ['4', '5'],
    border: '2px',
    borderColor: 'beta.500',
    borderRadius: 'full',
    color: 'beta.500',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    '.chakra-accordion__icon': {
      boxSize: ['4', '5'],
      borderColor: 'beta.500',
      color: 'beta.500',
      transition: '.3s ease-in-out',
    },
  },

  ...(isNested && {
    _expanded: {
      bgGradient: 'linear(to-b, beta.200, beta.400)',
      borderBottomRadius: 0,
      px: '2',
    },
  }),
});
