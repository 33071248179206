import { css } from 'styled-components';

// TODO: Remove
export const passwordRulesIcon = css``;

// TODO: Remove
export const passwordRule = css`
  color: ${({ theme }) => theme.palette.white};
  margin-bottom: var(--bc-sizes-2);
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S};
`;

// TODO: Remove
export const checkboxHelpText = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

// TODO: Remove
export const legalLink = css`
  color: ${({ theme }) => theme.palette.primary};
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S};

  &:hover {
    text-decoration: underline;
  }
`;
