/* eslint-disable no-void */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { setBonusChanceModal } from '@/redux/Betslip.slices';
import { PROMO_URL } from '@/constants/promo';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { useHeader } from '@/components/Headers/HeaderPrimary/services/Header.hooks';
import { useAppDispatch } from '@/hooks/useRedux';
import { TBetSlipBonusChanceType } from '../../Services/Betslip.types';

const CURRENT_BRAND = getThemeConfig().name?.toLocaleLowerCase();

const LUCKY_DIP_NT_URL = `${PROMO_URL}${CURRENT_BRAND}/luckydip/lottie_${CURRENT_BRAND}.json`;

const LOTTIE_PROMISE = new Promise((res, rej) => {
  fetch(LUCKY_DIP_NT_URL, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((data) => {
      res(data);
    })
    .catch((error) => {
      rej(error);
    });
});

export type TLuckyDipNTProps = {
  bonusChance: TBetSlipBonusChanceType;
  isWin: boolean;
};

function LuckyDipNTComp({ bonusChance, isWin }: TLuckyDipNTProps) {
  const dispatch = useAppDispatch();
  const { game } = bonusChance;

  const [lottieData, setLottieData] = useState<any>(null);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const playedRef = useRef(false);

  const segments: [number, number] = useMemo(() => {
    if (isWin) {
      return [1, 426];
    }
    return [427, 853];
  }, [isWin]);

  const { refetch } = useHeader(animationCompleted);

  useEffect(() => {
    if (!playedRef.current) {
      LOTTIE_PROMISE.then((data) => {
        setLottieData(data);
        playedRef.current = true;
      }).catch((e) => console.error(e));
    }
  }, []);

  useEffect(() => {
    if (animationCompleted) {
      if (isWin)
        dispatch(
          setBonusChanceModal({ ...bonusChance, isVisible: Boolean(game) })
        );
      void refetch();
    }
    // eslint-disable-next-line
  }, [animationCompleted]);

  return (
    <Box w="full" h="auto">
      {lottieData && (
        <Lottie
          loop={!isWin ? 1 : false}
          animationData={lottieData}
          initialSegment={segments}
          onComplete={() => {
            setAnimationCompleted(true);
          }}
        />
      )}
    </Box>
  );
}

export const LuckyDipNT = React.memo(LuckyDipNTComp);
