import { ButtonProps, CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const CancelButton: CSSObject = {
  bg: 'white',
  borderRadius: 'base',
  color: 'gamma.600',
  fontSize: 'xs',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  border: '2px solid',
  borderColor: 'gamma.600',
  fontFamily: 'Roboto',

  '&:hover, &:focus': {
    color: 'white',
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    boxShadow: 'none',
  },
};

export const ButtonSubmit: CSSObject = {
  fontSize: 'xs',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  color: 'white',
  fontFamily: 'Roboto',
  '&:hover, &:focus': {
    bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, var(--bc-colors-gamma-500) 0%, var(--bc-colors-gamma-600) 100%)',
    backgroundBlendMode: 'overlay, normal',
    color: 'white',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
};

export const PendingLimitContainer: CSSObject = {
  color: 'white',
  fontWeight: '700',
};

export const buttonRemoveProps: ButtonProps = {
  fontFamily: 'CarterOne',
  variant: 'solid',
  bgGradient: 'linear(to-b,beta.400, beta.600)',
  color: 'blackAlpha.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  boxShadow:
    ' 0px 0px 0px 3px #FDD101, 0px 0px 0px 2px rgba(0, 0, 0, 0.45) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.50) inset',
  _hover: {
    bgGradient: 'linear(to-b,beta.400, beta.600)',
    color: 'white',
  },
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: 0,
    label: {
      color: 'white',
    },
  },
  '&&': {
    '.chakra-input__left-addon': {
      borderLeftRadius: 'base',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'delta.800',
      color: 'white',
      h: '38px',
    },
    '.chakra-form__error-message': {
      color: 'red.200',
    },
    '.chakra-input': {
      border: '1px solid',
      borderLeftRadius: 'none',
      color: 'alpha.900',
      fontSize: 'xs',
      bg: 'white',
      fontWeight: 'bold',
      borderRightRadius: 'base',
      borderColor: 'gray.300',
      borderLeft: 'none',
      h: '38px',
      ':active, :hover': {
        bg: 'white',
        borderColor: 'blue.300',
        _placeholder: {
          color: 'whiteAlpha.500',
        },
      },
      _focus: {
        bg: 'gray.50',
        borderColor: 'blue.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },
      _placeholder: {
        color: 'blackAlpha.600',
      },
    },
  },
};
