import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexContainer = (isHome: boolean) => ({
  pos: [isHome ? 'absolute' : 'relative', null, 'unset'],
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },

  bg: ['epsilon.700', null, 'transparent'],
  w: ['100%', null, 'unset'],
  p: ['1', null, null, '0'],
  h: ['12', null, '50px'],
  pb: [null, null, '2'],
});

export const ButtonGroup: CSSObject = {
  overflow: 'hidden',
  pb: ['0', null, '2'],
  fontFamily: 'CinzelDecorative',
  fontWeight: 'black',
};

export const ButtonLogIn: CSSObject = {
  fontSize: ['sm', null, 'md'],
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  border: '2px solid',
  borderColor: 'alpha.700',
  // TODO: #426DD6 is not in the theme
  boxShadow: 'inset 0px 0px 0px 2px #426DD6',
  borderRadius: 'lg',
  height: ['9', null, '12'],
  '&:hover, &:active': {
    color: 'alpha.500',
  },
};

export const ButtonSignUp: CSSObject = {
  fontSize: ['sm', null, 'md'],
  borderRadius: 'lg',
  border: '2px solid',
  borderColor: 'delta.700',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  boxShadow: 'inset 0px 0px 0px 2px var(--bc-colors-delta-300)',
  height: ['9', null, '12'],
  color: 'delta.800',
  '&:hover, &:active': {
    color: 'white',
  },
};

export const ButtonBetSlip: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  border: '2px solid',
  borderColor: 'alpha.700',
  // TODO: #426DD6 is not in the theme
  boxShadow: 'inset 0px 0px 0px 2px #426DD6',
  height: ['9', null, '12'],
  borderRadius: 'lg',
  lineHeight: '0.9',

  '.span-bet-count': { fontSize: ['xs', null, 'md'], fontWeight: 'black' },

  '&:hover, &:active': {
    color: 'white',
  },
};

export const ButtonBets: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  border: '2px solid',
  borderColor: 'alpha.700',
  // TODO: #426DD6 is not in the theme
  boxShadow: 'inset 0px 0px 0px 2px #426DD6',
  height: ['9', null, '12'],
  borderRadius: 'lg',
  textTransform: 'uppercase',
  lineHeight: '0.9',

  '.span-bet': {
    textTransform: ['uppercase', null, 'capitalize'],
    fontSize: '2xs',
    fontWeight: 'black',
  },
  '.span-bet-count': { fontSize: ['xs', null, 'md'], fontWeight: 'black' },

  '&:hover, &:active': {
    color: 'white',
  },
};

export const buttonQuickAccessProps: CustomButtonProps = {
  sx: {
    '&&': {
      minW: '28',
      alignItems: 'flex-start',
      animation: '.5s ease-in-out',
      borderRadius: 'lg',
      border: '2px',
      borderColor: 'delta.700',
      bgGradient: 'linear(to-b, delta.400, delta.600)',
      boxShadow: 'inset 0px 0px 0px 2px var(--bc-colors-delta-300)',
      textShadow: 'none',
      height: ['9', null, '12'],
      lineHeight: '0.9',
      px: [2, null, null, null, 5],

      '&:hover': {
        bgPos: 'bottom center',
        boxShadow: 'sm',
      },

      '&[aria-expanded="true"]': {
        bg: 'epsilon.500',
        border: '3px solid white',
        boxShadow: 'none',

        '.chevron-icon': { color: 'delta.500' },
        '.span-balance': { color: 'delta.500' },
        '.span-bonus': { color: 'delta.500' },
        '.account-text': { color: 'delta.500' },
      },

      '.span-balance': {
        color: 'delta.800',
        fontSize: ['xs', null, 'md'],
        fontWeight: 'black',
      },
      '.span-bonus': {
        color: 'delta.800',
        fontSize: ['2xs', null, 'xs'],
      },
      '.account-text': { color: 'delta.800' },
      '.chevron-icon': { color: 'delta.800' },
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'delta.500',
  boxSize: '8',
  w: '8',
  h: '8',
  '& svg': {
    boxSize: '8',
  },
});

export const LinkLogo: CSSObject = {
  w: ['111px', null, '160px'],
  display: ['block', null, 'none'],
};

export const TextButtonNumber: CSSObject = {
  fontSize: ['xs', null, 'md'],
};

export const TextButton: CSSObject = {
  fontSize: '2xs',
};
