import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  px: '2',
  pb: ['6', null, '0'],
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive
    ? 'linear-gradient(180deg, var(--bc-colors-blackAlpha-400) 0%, var(--bc-colors-blackAlpha-400) 100%)'
    : 'linear-gradient(180deg, var(--bc-colors-alpha-400) 0%, var(--bc-colors-alpha-600) 100%)',
  color: isActive ? 'delta.50' : 'white',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'md',
  borderWidth: isActive ? '' : '2px',
  borderColor: isActive ? '' : 'beta.500',
  boxShadow: isActive
    ? '0px 1px 0px 0px #000 inset'
    : '0px 0px 0px 2px #26830B inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
  '&:hover, &:focus': {
    color: 'whiteAlpha.700',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-blackAlpha-400) 0%, var(--bc-colors-blackAlpha-400) 100%)',
  color: 'delta.50',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'md',
  borderWidth: '',
  borderColor: '',
  boxShadow: '0px 1px 0px 0px #000 inset',
  '&:hover, &:focus': {
    color: 'whiteAlpha.700',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
