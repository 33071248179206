import {
  Box,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { styleImport } from './Header.styles.imports';

export const flexEventSubTitleProps: FlexProps = {
  'data-testid': 'betCard-Header-flextEventSubTitleProps',

  ...(() => {
    try {
      return styleImport?.flextEventSubTitleProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const vsWrapper: FlexProps = {
  'data-testid': 'betCard-Header-vsWrapper',

  gap: '0.5', // we want this globally to give space between words

  ...(() => {
    try {
      return styleImport?.vsWrapper;
    } catch (err) {
      return {};
    }
  })(),
};

export const flexEventOddsProps: FlexProps = {
  'data-testid': 'betCard-Header-flexEventOddsProps',
  justifyContent: 'flex-end',
  placeSelf: 'flex-start',
  flex: '0 0 auto',
  flexDirection: 'column',

  ...(() => {
    try {
      return styleImport?.flexEventOddsProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const flexEventProps: FlexProps = {
  'data-testid': 'betCard-Header-flexEventProps',
  w: '100%',
  maxW: '100%',
  overflow: 'hidden',

  ...(() => {
    try {
      return styleImport?.flexEventProps;
    } catch (err) {
      return {};
    }
  })(),
};

export type THeaderContainer = {
  isClosed: boolean;
} & FlexProps &
  any;

export const HeaderContainer = chakra<THeaderContainer>(Flex, {
  label: 'header-HeaderContainer',
  shouldForwardProp: (prop) => !['isClosed'].includes(prop),
  baseStyle: ({ isClosed }: THeaderContainer) => ({
    w: 'full',
    flexDir: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&::before, &::after': {
      top: isClosed
        ? ['32%', null, null, null, null]
        : ['91%', null, null, null, '90%'],
    },
    ...(() => {
      try {
        return styleImport?.HeaderContainer?.({
          isClosed,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CountdownTimerBox = chakra(Box, {
  label: 'CountdownTimerBox',
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    ...(() => {
      try {
        return styleImport?.CountdownTimerBox;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkMarket = chakra(Link, {
  baseStyle: () => ({
    label: 'header-LinkMarket',

    ...(() => {
      try {
        return styleImport?.LinkMarket;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps>;

export const FlexIconPropName = chakra(Flex, {
  baseStyle: () => ({
    label: 'header-FlexIconPropName',
    justifyContent: 'flex-start',
    flex: 1,
    w: '70%',
    maxW: '70%',
    overflow: 'hidden',

    ...(() => {
      try {
        return styleImport?.FlexIconPropName;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const boostedOddsProps: FlexProps = {
  bg: 'black',
  borderRadius: 'md',
  mt: '1',
  width: 'full',
  padding: '1',
  flexDir: 'column',
  alignItems: 'center',
  color: 'white',
  ...(() => {
    try {
      return styleImport?.boostedOddsProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const boostedOddsTextProps: TextProps = {
  fontSize: 'lg',
  fontWeight: 'bold',
  ...(() => {
    try {
      return styleImport?.boostedOddsTextProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const SpanBarrierNumber = chakra(Text, {
  label: 'header-SpanBarrierNumber',
  baseStyle: () => ({
    fontSize: 'sm',
    color: 'gray.600',
    ...(() => {
      try {
        return styleImport?.SpanBarrierNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'span', TextProps>;

export const TextSubtitle = chakra(Text, {
  label: 'betslip-header-TextSubtitle',
  baseStyle: () => ({
    // Global change - we want this to be ellipsis for all bookies
    _last: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    ...(() => {
      try {
        return styleImport?.TextSubtitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const IconOddsChangeArrow = chakra(Icon, {
  shouldForwardProp: (prop) => !['direction'].includes(prop),
  baseStyle: ({ direction }) => ({
    label: 'header-IconOddsChangeArrow',
    h: '2.5',
    w: '5',
    color: direction === 'increase' ? 'green.500' : 'red.500',
    ...(() => {
      try {
        return styleImport?.IconOddsChangeArrow?.(direction) ?? null;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<
  'svg',
  IconProps & { direction?: 'increase' | 'decrease' }
>;

export const FlexInnerHeaderContainer: ChakraComponent<'div', FlexProps> =
  chakra(Flex, {
    baseStyle: () => ({
      label: 'header-FlexInnerHeaderContainer',
      w: 'full',
      maxW: 'full',
      justifyContent: 'space-between',
      alignItems: 'flex-start',

      ...(() => {
        try {
          return styleImport?.FlexInnerHeaderContainer;
        } catch (err) {
          return {};
        }
      })(),
    }),
  });
