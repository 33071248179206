import { AccountWrapperSchema } from './AccountWrapperTwo.styles';
import backgroundImage from '@/assets/juicybet/images/backgrounds/melonPattern.png';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    mx: ['2', null, 'unset'],
    borderRadius: '0',
    pb: 'unset',
  },
  boxHeading: {
    bgImage: `${backgroundImage}`,
    mb: '0',
    pt: '0',
    pb: '5',
  },
  textTitle: {
    mt: '0',
    color: 'gamma.400',
    fontSize: '2xl',
    fontWeight: 'medium',
    fontFamily: 'Chewy',
    textShadow: '0px 1px 4px rgba(0, 0, 0, 0.50)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        p: '4',
        borderRadius: '2xl',
        bgImage: `${backgroundImage}`,
        color: 'white',
        borderX: '3px solid',
        borderTop: '3px solid',
        borderColor: 'alpha.50',
        mt: '4',
        flexDir: 'column',
      },
    },
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
