import { css } from 'styled-components';

export const runnerResultRow = css`
  border-bottom: 1px solid ${({ theme }) => theme.basePalette.midPurple};
  padding: var(--bc-sizes-4) var(--bc-sizes-2);

  &:last-child {
    border-bottom: none;
  }
`;

export const runnerDetails = css``;

export const runnerPlaceContainer = css``;

export const runnerPlace = css`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: center;
  background: ${({ theme }) => theme.basePalette.brightBlue};
  border-radius: 10px;
  margin-bottom: var(--bc-sizes-1);
  padding: var(--bc-sizes-1) 0;
  width: 25px;
`;

export const runnerPlaceText = css`
  color: ${({ theme }) => theme.basePalette.black};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-xs);
  font-weight: 700;
`;

export const runnerOddsContainer = css`
  column-gap: var(--bc-sizes-5);
`;

export const runnerOdds = css`
  align-items: center;
`;

export const runnerBetType = css`
  color: ${({ theme }) => theme.basePalette.brightBlue};
  font-size: var(--bc-fontSizes-2xs);
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  margin-bottom: var(--bc-sizes-1);
`;

export const runnerOddsText = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  font-size: var(--bc-fontSizes-md);
`;
