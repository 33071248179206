import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  px: ['2.5', null, null, '3'],
};

export const BoxWrapper: CSSObject = {
  border: 'none',
};

export const LinkItem = (
  isFeatured: boolean,
  isFirstItem: number,
  isLastItem: number
) => ({
  p: '2',
  py: '3',
  borderTop: '1px solid',
  borderTopColor: 'alpha.500',
  borderLeft: '1px solid',
  borderLeftColor: 'alpha.500',
  borderRight: '1px solid',
  borderRightColor: 'alpha.500',
  borderBottom: isLastItem ? '1px solid' : 0,
  borderBottomColor: isLastItem ? 'alpha.200' : 'transparent',
  bg: isFeatured ? 'white' : 'alpha.400',
  color: isFeatured ? 'black' : 'white',
  transition: 'all, .1s ease-in-out',
  borderTopRadius: isFirstItem ? 'md' : 'none',
  borderBottomRadius: isLastItem ? 'md' : 'none',

  _hover: {
    bg: 'alpha.500',
  },

  svg: {
    boxSize: '6',
    p: '1',
    color: isFeatured ? 'unset' : 'white',
    border: '1px',
    borderColor: 'white',
    borderRadius: 'full',
  },
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};
