import { CSSObject } from '@chakra-ui/react';

export const FlexBannerContainer: CSSObject = {
  // bg: 'whiteAlpha.200',
  borderRadius: 'lg',
  borderColor: 'gamma.500',
  borderWidth: '2px',
  color: 'white',
  boxShadow: ' 0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  gap: ['2', null, '0'],
  p: ['3.5', null, '3'],
  pb: ['4', null, '3.5'],
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '2',
};

export const FlexAccountName: CSSObject = {
  color: 'beta.500',
  fontFamily: 'KHInterference',
  fontSize: ['lg', null, 'xl'],
  fontWeight: 'semibold',
};

export const FlexAccountBalances: CSSObject = {
  gap: '6',
  pl: '0',
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  m: '0',
  letterSpacing: 'tighter',
  textTransform: 'uppercase',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'gamma.400',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: ['sm', null, 'xs'],
  verticalAlign: 'super',
  color: 'beta.500',
  fontFamily: 'KHInterference',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: ['2xl', null, 'lg'],
  fontWeight: 'bold',
  color: 'beta.500',
  fontFamily: 'KHInterference',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: ['xl', null, 'sm'],
  fontWeight: 'bold',
  opacity: '0.7',
  color: 'beta.500',
  fontFamily: 'KHInterference',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  border: '2px',
  borderRadius: 'lg',
  borderColor: 'beta.500',
  boxShadow: '0 2px 6px rgba(0, 0, 0, .2)',
  flex: ['1', null, '0'],
  fontSize: '2xs',
  fontWeight: 'bold',
  h: ['8', null, '6'],
  letterSpacing: 'tighter',
  justifyContent: 'center',
  px: ['3', '2'],
  py: '3',
  textAlign: 'center',
  textShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.35)',
  textTransform: 'uppercase',
  whiteSpace: [null, null, 'nowrap'],
};
