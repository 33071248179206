import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  borderColor: 'alpha.200',
};

export const ExoticSelectionText: CSSObject = {
  color: 'alpha.700',
  fontWeight: 'normal',
};
