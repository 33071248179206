/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { Icon } from '../../styles/Empty.styles';

const template: TTemplate = {
  wrapper: <Center />,
  empty: <Icon name="emptyBetSlip" />,
};

export default template;
