import { Collapse, Box, Flex } from '@chakra-ui/react';
import React, { cloneElement, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { srcDefaultSilk } from '../../../../../assets/core';
import { useAppSelector } from '../../../../../hooks/useRedux';

import RaceRunnerForm from '../../../components/RaceRunnerForm/RaceRunnerForm';
import { EGeneralStatus, ERaceType, TFormData } from '@/lib/DBModels';
import {
  FlexRunnerWrapper,
  Item,
  RacerName,
  RunnerImage,
  TextTableBarrierNumber,
} from './styles/Table.styles';
import Flucs from '@/views/races/components/Flucs/Flucs';
import Badges from '@/views/races/components/Badges/Badges';
import { FlexFlucsPopupContainer } from '@/views/races/components/RaceRunnerListItem/styles/RaceRunnerListItem.styles';

type TTable = {
  children: ReactElement;
  isFlucsVisible?: boolean;
  isInsightsActive?: boolean;
};

export default function Table({
  children,
  isInsightsActive,
  isFlucsVisible,
}: TTable) {
  /**
   * As per the note within the custom hook, we should
   * expose the value within the store after a dispatch as we can
   * access it w/e we need to.
   */

  const { racingType } = useParams();
  const raceType = (racingType as ERaceType) || ERaceType.Horse;

  const {
    raceRunnerList: { raceRunners, raceMeta },
  } = useAppSelector((state) => state.racing);
  const { isBoxed } = useAppSelector((state) => state.exotics);

  const isOpen = raceMeta.status === EGeneralStatus.Open;

  const badgeSection = (formData: TFormData | undefined) =>
    isFlucsVisible ? (
      <Collapse in={isInsightsActive} animateOpacity>
        <Box className="badgeCollapse">
          <Badges form_data={formData} />
        </Box>
      </Collapse>
    ) : (
      <Collapse in={isInsightsActive} animateOpacity>
        <Badges form_data={formData} />
      </Collapse>
    );

  return (
    <>
      {raceRunners?.map((r) => {
        const isScratched = r.status === EGeneralStatus.Scratched;

        return (
          <Item key={r.race_runner_id} isBoxed={isBoxed}>
            <Flex flexDir="row" w="full" justifyContent="space-between">
              <Flex alignItems="start">
                <RunnerImage
                  data-cy="raceRunnerSilk"
                  src={r.silk_url}
                  fallbackSrc={srcDefaultSilk}
                  isGreyhound={raceType === ERaceType.Greyhound}
                />
                <FlexRunnerWrapper>
                  <RacerName
                    data-cy="runnerName"
                    as="div"
                    textDecoration={isScratched ? 'line-through' : ''}
                  >
                    {r.number}.{' '}
                    <div>{String(r.display_name).toLocaleLowerCase()}</div>
                    <TextTableBarrierNumber as="span">
                      {raceType !== ERaceType.Harness &&
                        ` (${r.barrier_number})`}
                    </TextTableBarrierNumber>
                  </RacerName>
                  {!isScratched && <RaceRunnerForm runner={r} />}
                </FlexRunnerWrapper>
              </Flex>

              {!isScratched &&
                cloneElement(children, {
                  runner: r,
                })}
            </Flex>
            {!isScratched && (
              <FlexFlucsPopupContainer>
                <Collapse in={isFlucsVisible} animateOpacity>
                  <Flucs flucs={r.flucs_data ?? {}} isRaceOpen={isOpen} />
                </Collapse>
                {badgeSection(r.form_data)}
              </FlexFlucsPopupContainer>
            )}
          </Item>
        );
      })}
    </>
  );
}
