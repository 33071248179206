import { BoxProps, ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import headerLinkLogo from '@/assets/chromabet/images/header-link-logo.png';

export const ButtonOutsideArea = {
  display: 'none',
};

export const BoxWrapper: BoxProps = {
  display: 'flex',
};

export const FlexContainer = (): CSSObject => ({
  pr: ['2.5', null, null, '0'],
  pl: 0,
  ml: 0,
  mr: 0,
  w: ['100%', null, null, '65%'],
  _before: {
    content: '""',
    position: 'absolute',
    top: 2,
    right: 0,
    // The image only appears in large screen
    width: [0, null, null, null, '122px'],
    height: [0, null, null, null, '64px'],
    backgroundImage: `url('${headerLinkLogo}')`,
    backgroundSize: 'cover',
    zIndex: -1,
  },
});

export const buttonLogInProps: ButtonProps = {
  variant: 'solid',
  height: '46px',
  bg: 'alpha.300',
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  height: '46px',
  bg: 'alpha.300',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'primaryAccess',
  height: '46px',
  p: '2.5',
  sx: {
    color: 'alpha.900',
    '.account-text': {
      fontWeight: 'bold',
      color: 'gamma.400',
    },
    '.btn-span': {
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      mr: '1',
      gap: '0',
      mt: '0.5',
    },
    '.span-balance': {
      fontSize: 'md',
      fontWeight: 'bold',
      lineHeight: '14px',
      color: 'gamma.400',
    },
    '.span-bonus': {
      fontSize: '2xs',
      lineHeight: '10px',
      fontWeight: 'bold',
      color: 'white',
      alignSelf: 'start',
    },
    'svg:not(.chevron-icon)': {
      pos: 'relative',
      top: '0.5',
      mr: '0.5',
    },
    '.chevron-icon': {
      pos: 'absolute',
      right: '1',
      top: '0',
      bottom: '0',
      margin: 'auto 0',
      color: 'white',
    },
    '&&[aria-expanded="true"]': {
      borderBottom: 'none',
      boxShadow: 'none',
      color: 'alpha.600',
      zIndex: 'dropdown',
      '.chevron-icon': { transform: 'rotate(180deg)', color: 'beta.500' },
      '.span-balance': {
        color: 'gamma.400',
      },
      '.span-bonus': {
        color: 'white',
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  variant: 'solid',
  color: 'beta.500',
  bg: 'alpha.300',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
    },
    '.span-bet': {
      fontWeight: 'bold',
      fontSize: '10px',
      color: 'white',
    },
    '.span-bet-count': {
      color: 'yellow.200',
      fontWeight: 'extrabold',
      fontSize: 'lg',
    },
  },
};

export const ButtonBets: CSSObject = {
  bg: 'alpha.300',
  color: 'white',
  h: '46px',
  w: '46px',
  mb: '0',
  '.span-bet': {
    fontSize: '2xs',
    textTransform: 'uppercase',
  },
  '.span-bet-count': {
    fontSize: 'lg',
    color: 'gamma.400',
  },
};

export const buttonBetSlipProps: ButtonProps = {
  variant: 'solid',
  color: 'beta.500',
  mr: '8px',
  borderRadius: 'md',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '0',
    },
    ':hover': {
      '.chakra-text': {
        color: 'alpha.900',
      },
    },
  },
};

export const ButtonBetSlip: CSSObject = {
  ml: '0',
  py: '4',
  bg: 'alpha.300',
  h: '46px',
  w: '46px',
  textTransform: 'uppercase',
};

export const TextButton: CSSObject = {
  fontWeight: 'bold',
  fontSize: '2xs',
  color: 'white',
};

export const TextButtonNumber: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'lg',
  color: 'gamma.400',
};

export const ButtonSignUp: CSSObject = {
  h: '46px',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'alpha.500',
  fontFamily: 'ProximaNova',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, beta.600, beta.600)',
    border: 'none',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(125, 253, 42, 0.7)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};

export const ButtonLogIn: CSSObject = {
  h: '46px',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'alpha.500',
  fontFamily: 'ProximaNova',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, beta.600, beta.600)',
    border: 'none',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(125, 253, 42, 0.7)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'beta.500',
  boxSize: '12',
  ml: '-1',
  '& svg': {
    boxSize: '8',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '1.5',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const buttonQuickAccess: ButtonProps = {
  variant: 'primaryAccess',
};

export const ButtonGroupBets: CSSObject = {
  gap: '1',

  // TODO: Temporary fix, find the root cause later
  '>*:first-of-type:not(:last-of-type)': {
    borderRightRadius: 'md',
  },
  '>*:not(:first-of-type):last-of-type': {
    borderLeftRadius: 'md',
  },
  '.btn-span': {
    gap: '0',
  },
};

export const ButtonGroup: CSSObject = {
  gap: '1',
};

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  ml: '-1',
};
