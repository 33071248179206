import { CSSObject } from '@chakra-ui/react';

export const ModalHeader: CSSObject = {
  color: 'beta.400',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  pb: '2',
  px: '4',
  bg: 'alpha.100',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
};
export const ModalBody: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  pb: '4',
  pt: '1',
  px: '4',
  bg: 'alpha.100',
};
export const ButtonDeposit: CSSObject = {
  mt: '3',
};
