import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  borderRadius: 'md',
  border: '1px',
  borderColor: 'white',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'white' : 'gray.600',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'md',

  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'alpha.600' : 'transparent',

  _hover: {
    bg: isActive ? 'alpha.500' : 'alpha.700',
    color: 'white',
  },
});
