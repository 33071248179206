import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordTooltip,
  TPasswordRule,
  TPasswordRulesIcon,
} from '../services/SignUp.types';

export const SignupAcknowledgementButton: CSSObject = {
  color: 'beta.500',
  textDecoration: 'none',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'beta.500',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  bgGradient: 'beta.500',
  borderRadius: 'base',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  fontSize: 'xs',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  _hover: {
    bg: 'beta.700',
  },
  _active: {
    bg: 'beta.100',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'green.600' : 'alpha.500',
  color: 'white',
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active ? ['green.400', null, 'white'] : 'yellow.300',
});

export const BoxManualAddressContainer: CSSObject = {
  bg: 'none',
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'normal',
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);

export const FlexHeaderWrapper: CSSObject = {
  pr: '8',
};
