export const Breadcrumb = {
  parts: ['link', 'item', 'separator'],
  baseStyle: {
    item: {
      color: 'white',
    },
    link: {
      textAlign: 'center',
      fontSize: 'xs',
      textTransform: 'uppercase',
      display: 'inline-block',
      '&::first-letter': { textTransform: 'capitalize' },
      '&::first-line': { textTransform: 'capitalize' },
      '&:hover': { textDecoration: 'none' },
    },
    separator: {
      color: 'white',
      mx: '2.5',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
