export const en = {
  'sports.allsports.azheading': 'A-Z Sports',
  'sports.matchDetailPage.clearAll': 'Cancel',
  'onboarding.locationnotallowed.header': `oops! it seems you've strayed into the broccoli bunch!`,
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing JuicyBet from outside of Australia.</b>',
  'error.heading': `JuicyBet has gone bananas`,
  'error.description': `<bold>Looks like you’ve hit a slippery situation.</bold>
  Refresh the page, or try again later.`,
  'maintenance.error': `<bold>We're working to peel away the glitches and make everything zesty again.</bold>
  Refresh the page, or try again later.`,
  'maintenance.heading': 'citrusy situation',
  'betslip.betslipmodal.emptybetslipheading': 'Core issue: bet slip empty',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'onboarding.login.subtitle':
    "Welcome to <Node>JuicyBet!</Node> Get ready to place your bets on thrilling races and sports events, all with a fruity twist. <Node>Let's make it juicy!</Node>",
  'main.featured.header': 'Fruit',
  'main.featured.subHeader': 'Frenzy',
  'header.betslipBtn.desc': 'Slip',
};
