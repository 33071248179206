import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  px: '0',
  py: '0',
  my: '3',
  h: '9',
};

export const ButtonFilter = () => ({
  color: 'alpha.300',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'blackAlpha.400' : 'blackAlpha.500',
  color: selected ? 'alpha.400' : 'transparent',
  boxShadow: '0px 1px 0px 0px #000 inset',
});

export const IconRace = ({ selected }: TSelected) => ({
  color: selected ? 'alpha.400' : 'blackAlpha.500',
});

export const Divider: CSSObject = {
  bg: 'whiteAlpha.400',
  display: 'block',
  h: '14px',
  my: 'auto',
  mx: '4',
};

export const TextFilterLabel: CSSObject = {
  color: 'white',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: '0',
};
