import { CSSObject } from '@chakra-ui/react';

export const ScratchedText: CSSObject = {
  fontWeight: 'semibold',
};

export const TextScratchedInfo: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
};

export const RunnerListItemWrapper: CSSObject = {
  py: ['1.5', null, '2'],
  flexDir: 'column',
  px: '0',
  my: '0',
  borderStyle: 'dashed',
  borderColor: 'gray.300',
  p: '2',
  borderRadius: 'md',
};
