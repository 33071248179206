import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bg: 'alpha.500',
  border: 'none',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: 'lg',
  flex: '1',
};

export const TypeItem: CSSObject = {
  color: 'beta.400',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  border: '1px',
  borderColor: 'alpha.400',
  borderRadius: 'md',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'alpha.700',
  boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.40) inset',
  '&:last-child': {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  _last: {
    color: 'beta.500',
    fontWeight: 'black',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.5)',
  },
};
