import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { keys } from '../api.keys';
import { queryNTG, queryNTJ } from './nextToJump';
import {
  TNextToGoParams,
  TNextToGoResponse,
  TNextToJumpParams,
  TNextToJumpResponse,
} from './nextToJump.types';

type TQueryNTGHook = {
  key?: any[];
  options?: UseQueryOptions<TNextToGoResponse>;
  params?: TNextToGoParams;
};

export const useQueryNTG = ({
  key = [],
  options = {},
  params = {},
}: TQueryNTGHook = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const query = useQuery([keys.ntg, ...key], () => queryNTG(params), {
    ...(options as any),
  });

  return query;
};

// ---

type TQueryHook = {
  key?: any[];
  options?: UseQueryOptions<TNextToJumpResponse>;
  params?: TNextToJumpParams;
};

export const useQueryNTJ = ({
  key = [],
  options = {},
  params = {},
}: TQueryHook) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const query = useQuery([keys.ntj, ...key], () => queryNTJ(params), {
    ...(options as any),
  });

  return query;
};

// ---

type TUseQueriesNTJ = {
  params?: TNextToJumpParams;
  options?: UseQueryOptions<TNextToJumpResponse>;
}[];

export const useQueriesNTJ = (queries: TUseQueriesNTJ = []) => {
  const $queries = !queries.length
    ? [{ params: { race_type: undefined } }]
    : queries;

  return useQueries({
    queries: $queries.map((f) => ({
      queryKey: [keys.ntj, Object.values(f.params ?? {})],

      queryFn: () =>
        queryNTJ({
          ...(f.params?.race_type === undefined && {
            limit: 36,
          }),

          ...f.params,
        }),

      ...f.options,
    })),
  });
};
