export const en = {
  'footer.copyright': 'Track Pursuits Pty Ltd t/a BetGalaxy | v{appVersion}',
  'sports.allsports.azheading': 'A-Z Sports',
  'error.heading': 'PUNTER, WE HAVE A PROBLEM',
  'maintenance.heading': 'PUNTER, WE HAVE A PROBLEM',
  'maintenance.error': `We are currently having some technical issues at BetGalaxy. Ground control are looking into the issue as a high priority. We apologise and thank you for your patience.`,
  'account.settings.responsible.lastupdated': 'Published: 30/01/2023',
  'betslip.betslipmodal.emptybetslipheading':
    'Houston, your bet slip is empty!',
  'generic.showmore': 'See All',
};
