import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const PageHeadingWrapper: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  p: '0',
};

export const TableWrapper: CSSObject = {
  pt: '0',
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'green.500' : 'gamma.600',
});

export const TransactionWrapper: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  minH: 'auto',
  px: '0',
  py: '2',
};
