import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logo from '@/assets/templebet/images/logo/logo-mobile.png';
import Heading from '../components/Heading';
import Body from '../components/Body';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: (
      <Img src={logo} alt={REACT_APP_THEME_NAME} w="320px" mt="10" mb="0" />
    ),
    heading: (
      <Flex direction="column" alignItems="center">
        <Heading />
      </Flex>
    ),
    desc: <Body />,
    body: (
      <Global
        styles={{
          body: {
            background:
              'linear-gradient(180deg, var(--bc-colors-alpha-500), var(--bc-colors-alpha-600))',
          },
        }}
      />
    ),
  },
};

export default template;
