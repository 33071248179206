import React from 'react';
import { Link, Skeleton } from '@chakra-ui/react';
import Carousel from '../Carousel';
import { useHomeBanner } from './services/HomeBanner.hooks';
import Banner from '../../views/Home/conditionals/Banner/template';
import { BannerImage, BannerLayerImage } from './HomeBanner.styles';

export default function HomeBanner() {
  const {
    filteredBanners,
    isMobile,
    isAuthLoading,
    isFetched,
    isBannerAvailable,
  } = useHomeBanner();

  if (isAuthLoading) return <Skeleton h="250px" />;

  const images = filteredBanners.map((item) => (
    <Link key={item.id} href={item.media.desktop.link} w="full">
      <BannerImage
        src={
          isMobile ? item.media.mobile.publicUrl : item.media.desktop.publicUrl
        }
      />
      {(item.media.mobile.layerLink || item.media.desktop.layerLink) && (
        <BannerLayerImage
          src={
            isMobile
              ? item.media.mobile.layerLink
              : item.media.desktop.layerLink
          }
        />
      )}
    </Link>
  ));

  return isBannerAvailable && isFetched ? (
    <Carousel slides={images} />
  ) : (
    <Banner />
  );
}
