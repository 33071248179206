/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAuth } from '../../hooks/useAuth';
import { keys } from '../api.keys';
import {
  mutatePunterCashOut,
  queryPunterAccount,
  queryPunterAccountOverview,
  queryPunterCashOutValue,
} from './punter';
import {
  TCashoutRequest,
  TCashoutResponse,
  TPunterAccount,
  TPunterAccountOverview,
  TPunterCashOutValue,
  TPunterCashOutValueParams,
} from './punter.types';
import { TBetCloudErrorResponse } from '../api.types';

type TQueryPunterAccountOverview = {
  key?: any[];
  options?: UseQueryOptions<TPunterAccountOverview>;
};

export const useQueryPunterAccountOverview = ({
  key = [],
  options = {},
}: TQueryPunterAccountOverview = {}) => {
  const { isAuthenticated } = useAuth();

  return useQuery(
    [keys.punterAccountOverview, isAuthenticated, ...key],
    queryPunterAccountOverview,
    {
      staleTime: 60 * 5000,
      enabled: !!isAuthenticated,
      ...(options as any),
    }
  );
};

// ---
type TQueryPunterAccount = {
  key?: string[];
  options?: UseQueryOptions<TPunterAccount, string[]>;
};

export const useQueryPunterAccount = (hook: TQueryPunterAccount = {}) => {
  const { key = [], options = {} } = hook;
  const { isAuthenticated } = useAuth();
  const query = useQuery<TPunterAccount, string[]>(
    [keys.punterAccount, isAuthenticated, ...key],
    () => queryPunterAccount(),
    {
      enabled: !!isAuthenticated,
      ...options,
    }
  );
  return { ...query, isEnabled: !!isAuthenticated };
};

export const useMutationCashOut = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TCashoutResponse>,
      AxiosError<TBetCloudErrorResponse>,
      TCashoutRequest
    >,
    'mutationFn'
  >
) => useMutation(mutatePunterCashOut, options);

export const useQueryPunterCashOutPrice = (
  params: TPunterCashOutValueParams,
  options?: Omit<UseQueryOptions<TPunterCashOutValue>, 'queryKey' | 'queryFn'>
) => {
  const query = useQuery(
    [keys.cashoutValue, params],
    () => queryPunterCashOutValue(params),
    options
  );
  return query;
};
