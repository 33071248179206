import { BoxProps } from '@chakra-ui/react';
import { styleImport } from './Primary.styles.imports';

type TDataTestID = { 'data-testid'?: string };
export type TPrimaryLayoutStyles = {
  grid: BoxProps & TDataTestID;
  ntj: BoxProps & TDataTestID;
  sidenav: BoxProps & TDataTestID;
  main: BoxProps & TDataTestID;
  qam: BoxProps & TDataTestID;
  aside: BoxProps & TDataTestID;
  footer: BoxProps & TDataTestID;
  message: BoxProps & TDataTestID;
};

export const styles: Partial<TPrimaryLayoutStyles> = {
  grid: {
    ...styleImport?.grid,
  },
  ntj: {
    ...styleImport?.ntj,
  },
  sidenav: {
    ...styleImport?.sidenav,
  },
  main: {
    ...styleImport?.main,
  },
  qam: {
    ...styleImport?.qam,
  },
  aside: {
    ...styleImport?.aside,
  },
  footer: {
    ...styleImport?.footer,
  },
  message: {
    ...styleImport?.message,
  },
};
