import {
  Box,
  ButtonProps,
  Center,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Icon,
  ImgProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { styleImports } from './Maintenance.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button, CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

type TFlexWithMaint = FlexProps & { isMaintenance?: boolean };

export const FlexPageWrapper = chakra(Flex, {
  label: 'maintenance-FlexPageWrapper',
  shouldForwardProp: (prop) => !['isMaintenance'].includes(prop),
  baseStyle: ({ isMaintenance }: any) => ({
    h: '100dvh',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexPageWrapper?.(isMaintenance);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TFlexWithMaint>;

export const MaintenanceContainer = chakra(Flex, {
  label: 'maintenance-MaintenanceContainer',
  shouldForwardProp: (prop) => !['isMaintenance'].includes(prop),
  baseStyle: ({ theme, isMaintenance }: any) => ({
    h: '100vh',
    flexDir: 'column',
    ...(theme.themeName === EThemes.Betgalaxy && {
      backgroundImage: `url(${theme.images.maintenanceBg}), linear-gradient(var(--bc-colors-gamma-500) 0%, var(--bc-colors-gamma-500) 100%)`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.MaintenanceContainer?.(isMaintenance);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TFlexWithMaint>;

export const logoImgProps: ImgProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.logoImgProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const Header = chakra(Flex, {
  label: 'maintenance-Header',
  baseStyle: ({ theme }) => ({
    w: 'full',
    px: '4',
    ...(theme.themeName === EThemes.Betgalaxy && {
      h: '90',
      borderBottom: '1px solid',
      borderBottomColor: rgba(`${theme.colors.whiteAlpha[500]}`, 0.07),
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'beta.500',
      h: '16',
    }),
  }),
});

export const BodyText = chakra(Text, {
  label: 'maintenance-',
  baseStyle: () => ({
    w: '6',
    h: '6',
    color: 'white',
  }),
});

export const ContentHeadingText = chakra(Text, {
  label: 'maintenance-ContentHeadingText',
  baseStyle: ({ theme }) => ({
    textAlign: 'center',
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: ['xl', null, '4xl'],
      fontStyle: 'italic',
      color: 'white',
      textTransform: 'uppercase',
      fontWeight: 'black',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ContentHeadingText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ContentBodyText = chakra(Text, {
  label: 'maintenance-ContentBodyText',
  baseStyle: ({ theme }) => ({
    textAlign: 'center',
    fontSize: ['sm', null, 'lg'],
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ContentBodyText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRefresh = chakra(Text, {
  label: 'maintenance-TextRefresh',
  baseStyle: () => ({
    textAlign: ['center', 'left'],
    fontSize: 'md',
    ...(() => {
      try {
        return styleImports[themeName]?.TextRefresh;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const IconContainer = chakra(Flex, {
  label: 'maintenance-IconContainer',
  baseStyle: ({ theme }) => ({
    fontSize: 'md',
    gap: '2',
    alignItems: 'center',
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'gamma.500',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'beta.500',
    }),
  }),
});

export const ContactText = chakra(Text, {
  label: 'maintenance-ContactText',
  baseStyle: ({ theme }) => ({
    fontSize: 'md',
    fontWeight: 'medium',
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'gamma.100',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'beta.500',
    }),
  }),
});

export const MaintenanceText = chakra(Text, {
  label: 'maintenance-',
  baseStyle: ({ theme }) => ({
    fontSize: 'md',
    fontWeight: 'medium',
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'blackAlpha.900',
    }),
  }),
});

export const ContactIcon = chakra(Icon, {
  label: 'maintenance-ContactIcon',
  baseStyle: ({ theme }) => ({
    borderRadius: 'md',
    p: '1.5',
    boxSize: '6',
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'gamma.100',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'beta.500',
      color: 'gray.200',
    }),
  }),
});

export const CenterWrapper = chakra(Center, {
  label: 'maintenance-CenterWrapper',
  baseStyle: () => ({
    h: '100vh',
    w: 'full',
    flexDir: 'column',
    maxW: ['380px', null, 'container.sm'],
    mx: 'auto',
    gap: '4',
    px: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.CenterWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Sign = chakra(Box, {
  label: 'maintenance-Sign',
  baseStyle: () => ({
    w: '100%',
    h: '100%',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    bgPos: 'bottom',

    ...(() => {
      try {
        return styleImports[themeName]?.Sign;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Mascot = chakra(Box, {
  label: 'maintenance-Mascot',
  baseStyle: () => ({
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    bgPos: 'bottom',

    ...(() => {
      try {
        return styleImports[themeName]?.Mascot;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonRefresh = chakra(Button, {
  label: 'maintenance-ButtonRefresh',
  baseStyle: () => ({
    bg: 'transparent',
    color: 'white',
    fontSize: 'xs',
    border: '2px solid',
    borderColor: 'white',
    borderRadius: 'base',
    transition: '.3s ease',
    lineHeight: 'inherit',
    h: '37px',
    w: '20',
    maxW: '20',
    px: '4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 'extrabold',

    '&:focus': { boxShadow: 'none' },
    '&:hover, &:active': {
      bg: 'transparent',
      color: 'white',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonRefresh;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const TextBoldDescription = chakra(Text, {
  label: 'maintenance-TextBoldDescription',
  baseStyle: () => ({
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.TextBoldDescription;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextDescription = chakra(Text, {
  label: 'maintenance-TextDescription',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextDescription;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const buttonRefreshProps: CustomButtonProps = {
  'data-testid': 'maintenance-buttonRefreshProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonRefreshProps;
    } catch (err) {
      return {};
    }
  })(),
};
