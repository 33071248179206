import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { GridItem, Show } from '@chakra-ui/react';
import { DotSingle } from '@styled-icons/entypo/DotSingle';
import { ChevronRight } from 'styled-icons/fa-solid';
import SgmLogo from '@/components/SgmLogo/SgmLogo';
import { getIconBySportName, getStrings } from '@/helpers/utils';
import {
  Icon,
  MatchCardContainer,
  MatchDate,
  MatchName,
  TeamContainer,
  TeamItem,
  TeamItemName,
  TeamItemOdds,
  TeamsContainer,
  Versus,
  VersusText,
  IconContainer,
  MatchDetails,
  ArrowIcon,
  Seperator,
  CompetitionName,
  TextSpanName,
} from './styles/MatchCard.styles';
import { TMatch, TProposition } from '../../../../lib/DBModels';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';
import { DeepPartial } from '../../../../lib/types';
import {
  useSingleBetSlip,
  useBetSlipManageBets,
} from '../../../../components/Betslip/Services/Betslip.hooks';
import Countdown from '../../../../components/Countdown/Countdown';
import { useCountdown } from '../../../../hooks/useCountdown';

type MatchCardProps = {
  match: DeepPartial<TMatch>;
  sportName: string;
  to: string;
  teams: DeepPartial<TProposition>[];
};

export const MatchCard: FC<MatchCardProps> = ({
  match,
  sportName,
  teams,
  to,
}) => {
  const [
    {
      Sports: { MatchCard: Strings },
    },
  ] = getStrings();
  const intl = useIntl();

  const { removeFromBetSlip, singlePropositionInBetSlip } =
    useBetSlipManageBets();
  const { addSportsBetSlip } = useSingleBetSlip();
  const { setBet } = getBetSlipStoreActions();

  const {
    match_name: matchName,
    match_start_time: matchStartTime,
    competition_name: matchCompetition,
  } = match || {};

  const { showCountdown, timerWithDate } = useCountdown(matchStartTime ?? '');

  const onTeamClick = (team: TProposition) => {
    const inBetSlip = singlePropositionInBetSlip(team?.proposition_id);

    setBet({
      id: team?.proposition_id ?? '',
      type: 'Single',
      propId: team?.proposition_id ?? '',
      odds: team.return_amount ?? 0,
      misc: {
        ...team,
        ...match,
        proposition: team,
        match,
      },
    });

    if (inBetSlip) {
      removeFromBetSlip(inBetSlip.request_id);
    } else {
      addSportsBetSlip(match?.competition_name ?? '', team, match);
    }
  };

  return (
    <MatchCardContainer data-cy="matchCard" to={to} as={Link}>
      <IconContainer gridArea="icon">
        <Icon name={getIconBySportName(sportName)} />
      </IconContainer>
      <GridItem gridArea="matchInfo" alignItems="center">
        <MatchName data-cy={`${matchName}`}>
          {matchName?.split(' ').map((n, i) => (
            <TextSpanName
              key={i}
              as="span"
              isVS={n.toLocaleLowerCase() === 'vs'}
            >
              {i === 0 ? n : ` ${n}`}
            </TextSpanName>
          )) || ''}
        </MatchName>

        <MatchDetails>
          <CompetitionName data-cy={`${matchCompetition}`}>
            {matchCompetition || ''}
          </CompetitionName>

          <Seperator as={DotSingle} />

          <MatchDate>{timerWithDate()}</MatchDate>

          {matchStartTime && showCountdown && (
            <>
              <Seperator as={DotSingle} />
              <Countdown eventTime={matchStartTime} />
            </>
          )}

          {match.sgm_available && (
            <Show above="md">
              <Seperator as={DotSingle} />
              <SgmLogo
                color="brand.600"
                h="auto"
                title={intl.formatMessage({ id: 'generic.sameGameMulti' })}
                w="8"
              />
            </Show>
          )}
        </MatchDetails>

        {match.sgm_available && (
          <Show below="md">
            <SgmLogo
              color="brand.600"
              h="auto"
              title={intl.formatMessage({ id: 'generic.sameGameMulti' })}
              w="8"
            />
          </Show>
        )}
      </GridItem>

      <GridItem gridArea="arrowIcon">
        <ArrowIcon as={ChevronRight} />
      </GridItem>

      {!!teams.length && (
        <TeamsContainer>
          {teams.map((team, i) => (
            <TeamContainer key={i}>
              <TeamItemName>{team?.proposition_name ?? ''}</TeamItemName>
              <TeamItem
                key={team.proposition_id ?? i}
                onClick={(e: React.SyntheticEvent) => {
                  e.stopPropagation();
                  e.preventDefault();

                  onTeamClick(team);
                }}
              >
                <TeamItemOdds>
                  {team?.return_amount?.toFixed(2) ?? ''}
                </TeamItemOdds>
              </TeamItem>
            </TeamContainer>
          ))}
          <Versus>
            <VersusText>{Strings.versus}</VersusText>
          </Versus>
        </TeamsContainer>
      )}
    </MatchCardContainer>
  );
};
