import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const closeIcon: TCloseIcon = {
  boxSize: '6',
  as: CloseCircle,
  color: 'white',
};

export const CloseIconButton: CSSObject = {
  justifyContent: 'end',
};

export const FlexBetslipHeading: CSSObject = {
  h: '46px', // no token
  borderBottomColor: 'alpha.600',
  borderBottom: '2px',
  color: [null, null, null, null, 'white'],
};

export const BetslipTitle: CSSObject = {
  fontFamily: 'CrimsonPro',
  fontSize: 'lg',
  fontWeight: 'black',
  color: 'white',
  mt: '1',
  gap: '0',
};

export const BetLengthIndicator: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  fontSize: 'xs',
  borderRadius: 'base',
  color: 'gamma.800',
  letterSpacing: 'wider',
  fontFamily: 'Roboto',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  px: '2',
  py: '1',
  ml: '2',
  boxShadow:
    '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
};
