import { CSSObject } from '@chakra-ui/react';

export const IconSGM: CSSObject = {
  h: 'auto',
  mr: '2',
  w: '8',
  color: 'gamma.500',
};

export const LinkWrapper: CSSObject = {
  zIndex: '5',
  position: 'relative',
  borderTop: '1px',
  borderColor: 'blackAlpha.200',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
  h: ['63px', null, '47px'],
  py: 0,

  gridTemplateRows: 'auto',
  gridTemplateAreas: '"icon details time chevron"',

  _last: {
    borderBottom: '1px',
    borderColor: 'blackAlpha.200',
  },

  '.details': {
    width: '100%',
    overflow: 'hidden',
    pr: '2',
  },
};
export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'normal',
  mb: ['0', null, '-1px'],
  whiteSpace: 'normal',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'white' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'gray.50',
  fontSize: ['2xs', null, 'xs'],
};

export const IconSport: CSSObject = {
  color: 'beta.200',
  boxSize: '7',
  mr: '2.5',
  boxShadow: 'none',
  bg: 'none',
  p: 'none',
};

export const IconChevron: CSSObject = {
  color: 'white',
  ml: '0',
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '2' },
  mr: '0.5',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: 'xs',
    bg: 'transparent',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'white',
    boxSize: '5',
  },
};
