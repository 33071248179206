import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { trackEvent } from '@/lib/analytics';
import { setBonusChanceModal } from '../../../redux/Betslip.slices';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { TPromoName } from './PromoModal.types';
import { keys } from '@/api/api.keys';
import { useFeatureFlag } from '@/store/FeatureFlagStore';

export type TMessage = {
  data?: unknown;
};

export const usePromoModalDisclosure = () => {
  const dispatch = useAppDispatch();
  const [eventTracked, setEventTracked] = useState(false);
  const { bonusChance } = useAppSelector(({ betSlip }) => betSlip);
  const isVisible = bonusChance?.isVisible === true;
  const promo: TPromoName = 'betBoost';
  const client = useQueryClient();
  const hasLD = useFeatureFlag('LUCKY_DIP_ENABLED');

  const handleClose = () => {
    dispatch(setBonusChanceModal(hasLD ? { isLDVisible: true } : null));
    trackEvent('closePromo', { promo });

    client
      .invalidateQueries([keys.punterAccountOverview])
      .catch(() => undefined);
  };

  useEffect(() => {
    if (!eventTracked && isVisible) {
      trackEvent('initiatePromo', { promo: bonusChance?.game });
      setEventTracked(true);
    }
  }, [isVisible, bonusChance?.game, eventTracked]);

  return {
    isOpen: isVisible,
    onClose: handleClose,
  };
};
