import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'secondary',
    px: '2',
    borderRadius: '3px',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
  },
}
