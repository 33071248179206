import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    variant: 'secondary',
    _disabled: {
      py: '1',
      px: '2',
      color: 'gray.800',
      fontSize: '2xs',
      bg: 'white',
      borderRadius: 'base',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.20)',
      fontFamily: 'Roboto',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    },
  },
};

export const FlexInner: CSSObject = {
  borderBottomColor: 'blackAlpha.500',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  minH: '12',
  py: '2',
  '&:last-of-type': {
    borderBottomColor: 'transparent',
  },
};

export const TextMonth: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  color: 'white',
};
