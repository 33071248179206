import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'beta.500',
  gap: '2px',
};

export const toteMultiGrid: CSSObject = {
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '6',
  display: 'grid',
};

export const toteMultiGridText: CSSObject = {
  fontSize: 'xs',
  w: 'full',
  color: 'black',
  mb: '2',
  '&.raceNumber': {
    borderBottom: '1px dashed',
    borderColor: '#00000040',
    boxShadow: '0px 1px 0px 0px #FFFFFF1A',
    fontWeight: 'bold',
    color: 'black',
  },
};

export const ToteMultiGridText: CSSObject = {
  color: 'black',
};