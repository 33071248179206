import {
  ComponentWithAs,
  HStack,
  Link,
  LinkProps,
  StackProps,
} from '@chakra-ui/react';
import React from 'react';
import { navStyles } from './styles/Nav.styles';

export default function Nav(props: StackProps) {
  return <HStack {...navStyles.wrapper} {...props} />;
}

export const NavLink: ComponentWithAs<'a', LinkProps> = (props) => (
  <Link {...navStyles.link} {...props} />
);
