import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'epsilon.400',
  borderTop: '1px dashed',
  borderColor: 'blackAlpha.300',
  color: 'white',
};

export const TextListName: CSSObject = {
  fontSize: ['xs', null, 'sm'],
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'white' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'epsilon.200',
  fontSize: ['2xs', null, 'xs'],
};

export const IconSport: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  color: 'beta.500',
  mr: ['0', '2.5'],

  _hover: {
    color: 'beta.100',
  },
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'alpha.400',
};

export const IconChevron: CSSObject = {
  color: 'white',
};

export const FlexEndWrapper: CSSObject = {
  fontSize: '2xs',
  '.chakra-badge.timestamp': {
    fontSize: '2xs',
  },
  '.chakra-badge.state-prior': {
    fontWeight: 'semibold',
    fontSize: '2xs',
    px: '0',
  },
  '.chakra-badge.state-inprogress': {
    color: 'beta.900',
    fontSize: '2xs',
  },
  '.chakra-badge.state-ended': {
    fontSize: '2xs',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'blackAlpha.400',
  },
};
