import { CSSObject } from '@chakra-ui/react';

export const FlexResponsibleGamblingBannerWrapper: CSSObject = {
  pt: '0',
};

export const FooterRowContainer: CSSObject = {
  color: 'white',
  font: 'Fredoka',
  fontSize: 'md',
  fontStyle: 'normal',
};
