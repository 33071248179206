import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Link, OrderedList } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import { getThemeName } from '@/helpers/getThemeName';
import {
  InformationWrapper,
  TextInformation,
  TextM,
  ListItemExclusionItem,
  BoxTakeABreak,
  buttonTakeBreakProps,
} from '../styles/TakeABreak.styles';
import { Button } from '@/components/Button/Button';

export default function Information() {
  const [
    {
      Account: {
        TakeABreak: {
          information,
          continueButton,
          importantDetailsTitle,
          importantDetailsEmailTitle,
          importantDetailsEmailDesc,
          importantDetailsLoginTitle,
        },
      },
    },
  ] = getStrings();

  const navigate = useNavigate();

  return (
    <InformationWrapper maxW="container.xs">
      <BoxTakeABreak>
        <TextInformation>{information}</TextInformation>
      </BoxTakeABreak>
      <Box sx={{ mb: '4' }}>
        <TextM>
          {importantDetailsTitle.replace(/{themeName}/g, getThemeName())}
        </TextM>
        <OrderedList>
          <ListItemExclusionItem>
            <TextInformation sx={{ fontWeight: 'bold', mb: '0' }}>
              {importantDetailsEmailTitle.replace(
                /{themeName}/g,
                getThemeName()
              )}
            </TextInformation>
            <TextInformation>
              {importantDetailsEmailDesc.replace(
                /{themeName}/g,
                getThemeName()
              )}
            </TextInformation>
          </ListItemExclusionItem>
          <ListItemExclusionItem>
            <TextInformation sx={{ fontWeight: 'bold', mb: '0' }}>
              {importantDetailsLoginTitle}
            </TextInformation>
            <TextInformation>
              <FormattedMessage id="account.takeabreak.importantdetailslogindesc" />
            </TextInformation>
          </ListItemExclusionItem>

          <ListItemExclusionItem>
            <TextInformation sx={{ fontWeight: 'bold', mb: '0' }}>
              <FormattedMessage id="account.betStop.title" />
            </TextInformation>
            <TextInformation>
              <FormattedMessage
                id="account.betStop.description"
                values={{
                  cta: (string: string) => (
                    <Link
                      href="https://www.acma.gov.au/betstop-national-self-exclusion-registertm"
                      target="_blank"
                      display="inline-block"
                      textDecoration="underline"
                    >
                      {string}
                    </Link>
                  ),
                }}
              />
            </TextInformation>
          </ListItemExclusionItem>
        </OrderedList>
      </Box>
      <Button
        {...buttonTakeBreakProps}
        data-cy="takeABreakContinue"
        onClick={() =>
          navigate(`/account/settings/responsible-gambling/take-a-break/form`)
        }
        isFullWidth
      >
        {continueButton}
      </Button>
    </InformationWrapper>
  );
}
