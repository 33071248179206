import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '24px',
  display: 'inline-block',
  fontSize: '2xs',
  h: '5',
  borderRadius: 'base',
  bg: 'alpha.500',
  color: 'white',
  ml: '1',
};

export const LinkRaceContainer: CSSObject = {
  color: 'alpha.500',
  mb: '2',
  borderRadius: 'md',
  bg: 'zeta.400',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 0px 0px #AD8B4C, 0px 2px 1px 0px rgba(255, 255, 255, 0.30) inset',
  h: '45px',

  _hover: {
    bg: 'alpha.300',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px -2px 0px 0px #76391C',
  },

  '.chakra-badge.state-inprogress': {
    bg: 'delta.900',
    color: 'white',
    height: '3',
    borderRadius: 'base',
    fontSize: '2xs',
  },

  '.chakra-badge.state-prior': {
    bg: 'rgba(45, 55, 72, 1)',
    color: 'white',
    height: '3',
    borderRadius: 'base',
    fontSize: '2xs',
  },

  '.chakra-badge.state-ended': {
    bg: 'delta.900',
    color: 'white',
    height: '3',
    borderRadius: 'base',
    fontSize: '2xs',
  },
};

export const TextRaceType: CSSObject = {
  color: 'white',
  bg: 'transparent',
  h: '34px',
  m: 'auto',
  pt: '1.5',
  fontWeight: 'thin',
  fontSize: 'xl',
  fontFamily: 'SkranjiRegular',
  letterSpacing: 'widest',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
};

export const BoxColumn: CSSObject = {
  bg: 'transparent',
  mb: ['4', 'unset'],
};

export const Icon: CSSObject = {
  boxSize: '5',
};
