import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '../../../../../hooks/useQuery';
import { AutoLogoutNotificationText } from '../../legacy/styles/Login.styles';

export default function AutoLogoutNotification() {
  const query = useQuery();
  const autoLogout = query.get('autoLogout');

  if (autoLogout !== 'true') return <></>;
  return (
    <AutoLogoutNotificationText>
      <FormattedMessage id="onboarding.login.autologoutmessage" />
    </AutoLogoutNotificationText>
  );
}
