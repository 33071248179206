import { CSSObject } from '@chakra-ui/react';

export const SubHeading: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontSize: 'sm',
};

export const BoxBordered: CSSObject = {
  px: '2',
  textColor: 'black',
  maxW: 'container.md',
  pt: '0',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const SmallHeading: CSSObject = {
  mt: '2',
  color: 'white',
  fontSize: 'sm',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const ImageGamblersHelp: CSSObject = {
  w: '168px',
  minW: '168px',
  bg: 'white',
  padding: '5px',
  borderRadius: 'md',
};

export const ButtonAction: CSSObject = {
  _after: {
    w: 'full',
    bgGradient: 'linear(to-b, delta.300, delta.600)',
    boxShadow:
      '0px 0px 0px 2px #83090D inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.15)',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: 'md',
    fontWeight: 'bold',
  },
  w: 'full',
  color: 'epsilon.300',
  padding: '2',
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  boxShadow:
    '0px 0px 0px 2px #83090D inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.15)',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  textTransform: 'capitalize',
  letterSpacing: 'wider',
  '&:hover, &:focus': {
    bg: 'delta.300',
    color: 'delta.500',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    border: '2px solid',
    borderColor: 'epsilon.500',
  },
  '&:active': {
    bg: 'epsilon.300',
    boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.45)',
  },
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr',
  maxW: 'container.sm',
};

export const Link: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mx: '2',
};
