/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { InputProps } from '@chakra-ui/input';
import theme from '@chakra-ui/theme';

export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      bg: 'gray.100',
      color: 'black',
      caretColor: 'currentColor',
      border: '1px',
      borderColor: 'gray.300',

      '&::placeholder': { color: 'gray.400' },

      '&:focus, &:active, &:hover': { bg: 'gray.100' },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      color: 'black',
      '&&': { borderRightColor: 'blackAlpha.200' },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: '4px' }, field: { borderRadius: '4px' } },
    md: {
      addon: { borderRadius: '4px', h: '38px' },
      field: { borderRadius: '4px', fontSize: 'xs', px: '2', h: '38px' },
    },
  },
  variants: {
    outline: (props: InputProps) => {
      const { addon, field } = theme.components.Input.variants.outline(
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          bg: 'gray.50',
        },
      };
    },
  },
  defaultProps: {},
};
