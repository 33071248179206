import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import {
  ImageNoRaces,
  NoRacesWrapper,
  TextPrimary,
  TextSecondary,
} from '../styles/NoRaces.styles';
import NoRacesMascot from '@/assets/templebet/images/racing/NoRacesTemplebet.png';

export const template: TTemplate = {
  wrapper: <NoRacesWrapper />,
  image: <ImageNoRaces src={NoRacesMascot} />,
  text: (
    <>
      <TextPrimary>
        <FormattedMessage
          id="racing.errorMessages.noRacesAvailable"
          values={{
            br: <br />,
          }}
        />
      </TextPrimary>
      <TextSecondary>
        <FormattedMessage
          id="racing.errorMessages.noRacesAvailableSubtext"
          values={{
            b: (text: string) => <Text as="b">{text}</Text>,
            p: (text: string) => <Text as="p">{text}</Text>,
          }}
        />
      </TextSecondary>
    </>
  ),
};
export default template;
