import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    borderRadius: 'base',
    border: 'none',
    gap: '2',
    p: '4',
    mb: '2',
    mt: '1',
  },
  depositIconProps: {
    color: '#FFB11A',
    boxSize: '9',
  },
};
