import {
  BoxProps,
  FlexProps,
  GridProps,
  LinkProps,
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Image,
  ImageProps,
  ButtonProps,
} from '@chakra-ui/react';
import { styleImports } from './RaceCards.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg, { TIconSvg } from '@/components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import {
  Button as ChakraButton,
  CustomButtonProps,
} from '@/components/Button/Button';

const themeName = getThemeName();

export type RaceCardSchema = Partial<{
  GridRaceCards: GridProps;
  FlexCard: FlexProps;
  LinkCardHeader: LinkProps;
  TextCardHeading: TextProps;
  TextRaceNumber: TextProps;
  GridCardContent: GridProps;
  FlexGridRunnerItem: FlexProps;
  TextRunner: TextProps;
  TextRunnerNumber: TextProps;
  TextScratched: TextProps;
  LinkRaceDetails: LinkProps;
  BoxGridHeaderItem: BoxProps;
  buttonRaceCardOddsProps: any;
}>;

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    'data-testid': 'raceCard-GridRaceCards',
    gridAutoColumns: 'minmax(0, 1fr)',
    gridAutoFlow: ['row', null, 'column'],
    columnGap: [null, '1', '2.5'],
    rowGap: ['1', 'unset'],
    my: ['1.5', null, null, '2.5'],
    mx: ['1.5', null, null, 'unset'],
    ...(styleImports?.[themeName]?.raceCardStyles?.GridRaceCards ?? {}),
  },
  FlexCard: {
    'data-testid': 'raceCard-FlexCard',
    borderRadius: 'base',
    contain: 'paint',
    flexDir: 'column',
    ...(styleImports?.[themeName]?.raceCardStyles?.FlexCard ?? {}),
  },
  LinkCardHeader: {
    'data-testid': 'raceCards-LinkCardHeader',
    display: 'flex',
    p: '2.5',
    justifyContent: 'space-between',
    _hover: { textDecoration: 'none' },
    ...(styleImports?.[themeName]?.raceCardStyles?.LinkCardHeader ?? {}),
  },
  TextCardHeading: {
    'data-testid': 'raceCard-TextCardHeading',
    fontWeight: 'semibold',
    fontSize: 'sm',
    ...(styleImports?.[themeName]?.raceCardStyles?.TextCardHeading ?? {}),
  },
  TextRunner: {
    'data-testid': 'raceCard-TextRunner',
    maxW: ['auto', null, '150px'],
    sx: {
      '--chakra-line-clamp': 2,
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    ...(styleImports?.[themeName]?.raceCardStyles?.TextRunner ?? {}),
  },
  TextRaceNumber: {
    'data-testid': 'raceCard-TextRaceNumber',
    fontWeight: 'bold',
    fontSize: '2xs',
    borderRadius: 'sm',
    minW: '5',
    h: '4',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    ml: '1',
    px: '1',
    ...(styleImports?.[themeName]?.raceCardStyles?.TextRaceNumber ?? {}),
  },
  GridCardContent: {
    'data-testid': 'raceCard-GridCardContent',
    gridTemplate: '". ." 1fr / 1fr auto',
    gridAutoRows: 'auto',
    py: '1',
    px: '1.5',
    fontSize: '2xs',
    fontWeight: 'semibold',
    textAlign: 'center',

    _first: { textAlign: 'left' },
    ...(styleImports?.[themeName]?.raceCardStyles?.GridCardContent ?? {}),
  },
  FlexGridRunnerItem: {
    'data-testid': 'raceCard-FlexGridRunnerItem',
    py: '1',
    px: '1.5',
    alignItems: 'center',
    minH: '41px',
    ...(styleImports?.[themeName]?.raceCardStyles?.FlexGridRunnerItem ?? {}),
  },
  TextRunnerNumber: {
    'data-testid': 'raceCard-TextRunnerNumber',
    fontSize: 'xs',
    fontWeight: 'normal',

    ...(styleImports?.[themeName]?.raceCardStyles?.TextRunnerNumber ?? {}),
  },
  TextScratched: {
    'data-testid': 'raceCard-TextScratched',
    fontSize: '3xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'full',

    ...(styleImports?.[themeName]?.raceCardStyles?.TextScratched ?? {}),
  },
  LinkRaceDetails: {
    'data-testid': 'raceCard-LinkRaceDetails',
    display: 'block',
    textAlign: 'center',
    fontSize: 'xs',
    fontWeight: 'semibold',
    p: '2',
    mt: 'auto',
    _hover: { textDecoration: 'none' },
    transition: '.3s ease-in-out',

    ...(styleImports?.[themeName]?.raceCardStyles?.LinkRaceDetails ?? {}),
  },
  BoxGridHeaderItem: {
    'data-testid': 'raceCard-BoxGridHeaderItem',
    py: '1',
    px: '1.5',
    fontSize: '2xs',
    fontWeight: 'semibold',
    textAlign: 'center',

    _first: { textAlign: 'left' },

    ...(styleImports?.[themeName]?.raceCardStyles?.BoxGridHeaderItem ?? {}),
  },
  buttonRaceCardOddsProps: {
    'data-testid': 'raceCards-buttonRaceCardOddsProps',
    w: '14',
    ...(styleImports?.[themeName]?.raceCardStyles?.buttonRaceCardOddsProps ??
      {}),
  },
};

export const buttonRaceCardOddsProps: CustomButtonProps = {
  'data-testid': 'raceCards-buttonRaceCardOdds',
  w: '14',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonRaceCardOddsProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const ImageRunnerSilk = chakra(Image, {
  label: 'raceCards-ImageRunnerSilk',
  baseStyle: ({ isGreyhound }: any) => ({
    h: '6',
    aspectRatio: '1 / 1',
    objectFit: 'contain',
    bg: 'white',
    p: !isGreyhound && '2px',
    borderRadius: 'base',
    boxShadow: 'lg',
    mr: '1.5',

    ...(() => {
      try {
        return styleImports[themeName]?.ImageRunnerSilk;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'image', ImageProps & { isGreyhound: boolean }>;

export const TextRunner = chakra(Text, {
  label: 'raceCards-TextRunner',
  shouldForwardProp: (prop) => !['isSuspended'].includes(prop),
  baseStyle: ({ isSuspended, theme }: any) => ({
    fontSize: 'sm',
    fontWeight: 'medium',
    textTransform: 'capitalize',
    ...(isSuspended && { textDecor: 'line-through' }),

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'xs',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextRunner;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps & { isSuspended: boolean }>;

export const IconCardHeader = chakra(IconSvg, {
  label: 'raceCards-IconCardHeader',
  baseStyle: () => ({
    boxSize: '5',
    mr: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.IconCardHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', TIconSvg>;

/**
 * @deprecated
 */
export const Button = chakra(ChakraButton, {
  label: 'raceCards-Button',
  baseStyle: ({ theme, isActive }: any) => ({
    borderRadius: 'sm',
    h: '8',
    fontSize: 'xs',
    px: '2.5',
    flex: 1,

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.600',
      color: 'white',
      boxShadow: 'md',
      borderRadius: 'sm',
      transition: 'all .3s linear',
      textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',

      '&:hover': {
        bg: 'alpha.800',
        boxShadow: 'lg',
      },

      '&:focus:hover': {
        bg: 'alpha.800',
        color: 'white',
        textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
        boxShadow: 'inset 0px 3px 4px #555',
      },

      ...(isActive && {
        bg: 'alpha.200',
        color: 'alpha.700',
        textShadow: 'none',
        boxShadow: 'inset 0px 3px 4px #499edd',

        _focus: {
          bg: 'alpha.300',
          boxShadow: 'inset 0px 3px 4px #499edd',
        },
        _hover: {
          bg: 'alpha.300',
          boxShadow: 'inset 0px 3px 4px #499edd',
        },
      }),
    }),

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'transparent',
      color: 'beta.50',
      border: '2px',
      borderColor: 'beta.50',

      '&:hover, &:focus': { bg: 'beta.50', color: 'blackAlpha.500' },

      ...(isActive && {
        bg: 'blackAlpha.500',
        color: 'beta.50',
        borderColor: 'blackAlpha.500',

        '&:hover, &:focus': { bg: 'blackAlpha.600', color: 'beta.50' },
      }),
    }),

    ...(() => {
      try {
        return (
          styleImports[themeName]?.Button?.({
            isActive,
          }) ?? null
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps & { isActive: boolean }>;
