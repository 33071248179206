import React from 'react';
import Aside from '../../components/Aside/Aside';
import { WrapperOnboarding } from '../../styles/onboarding.styles';
import CloseBtn from '../../components/CloseBtn/CloseBtn';
import Content from '../components/Content';

const AccountVerification = [
  { name: 'wrapper', component: <WrapperOnboarding /> },
  { name: 'aside', component: <Aside /> },
  { name: 'content', component: <Content /> },
  { name: 'closeBtn', component: <CloseBtn /> },
];

export default AccountVerification;
