import { CSSObject } from '@chakra-ui/react';
import { TLinkAsideProps } from './SideLinks.styles';

export const LinkAside = ({ isActive }: TLinkAsideProps): CSSObject => ({
  mx: '1',
  my: '2',
  bg: isActive ? 'gray.300' : 'gamma.500',
  fontWeight: 'bold',
  fontSize: 'sm',
  borderRadius: 'md',
  mr: '2',
  ml: '0',
  color: isActive ? 'gray.600' : 'white',
  boxShadow: isActive
    ? 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)'
    : '0px 2px 5px rgba(0, 0, 0, 0.15)',
});

export const BoxAside: CSSObject = {
  mb: '2',
};
