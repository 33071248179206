import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { TextRefresh } from '../styles/Maintenance.styles';

export default function RefreshText() {
  return (
    <TextRefresh>
      <FormattedMessage
        id="onboarding.refreshpage"
        values={{ b: (string: string) => <Text as="b">{string}</Text> }}
      />
    </TextRefresh>
  );
}
