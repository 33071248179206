import { CSSObject } from '@chakra-ui/react';
import { TDot } from './Carousel.styles';

export const CarouselWrapper: CSSObject = {
  '& .control-dots ': {
    bgGradient: 'linear(134.54deg, #FCBE7C 5.32%, #C98242 94.89%)',
    rounded: 'base',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.4)',
    w: 'fit-content',
    px: '1',
    left: '50%',
    transform: 'translateX(-50%)',
  },
};

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: selected ? 'rgba(38, 38, 38, 1)' : 'blackAlpha.300',
});
