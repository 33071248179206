import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxBallance: CSSObject = {
  bg: 'linear-gradient(180deg, #192A82 0%, #0F194E 100%)',
  color: 'white',
  borderBottomRadius: 'base',
  fontWeight: 'black',
  pt: '0',
  '.chakra-button__icon': {
    display: 'none',
  },
  '.qa-balance': {
    color: 'white',
  },
  '.qa-withdrawable': {
    color: 'white',
  },
};

export const buttonDepositProps: CSSObject = {
  variant: 'secondary',
  w: '100%',
  mt: '2',
};

export const PopoverHeaderQA: CSSObject = {
  bg: '#192A82',
  color: 'white',
  borderStyle: 'none',
  fontSize: 'xs',
  fontWeight: 'bold',
  borderTopRadius: 'base',
};

export const PopoverContentQA: CSSObject = {
  px: '2px',
  pt: '2px',
  bg: 'white',
};

export const ButtonNavItem = (): CSSObject => ({
  bg: 'white',
  color: 'alpha.500',
  textTransform: 'capitalize',
  fontSize: '13px',

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
  },
});

export const BoxMascotImage: CSSObject = {
  pos: 'absolute',
  display: 'none',

  w: ['100px'],
  h: ['75px'],

  left: '-108px',
  bottom: '-4',
  transition: 'all .4s ease-in-out',
  mr: 0,

  _hover: {
    transform: 'rotate(720deg)',
  },
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const FlexBallanceItem: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'medium',
  color: 'white',
};

export const TextValue: CSSObject = {
  fontWeight: 'bold !important',
};
