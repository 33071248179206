import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  borderRadius: '10px',
  border: '3px solid',
  borderColor: 'beta.500',
  background: 'rgba(81, 208, 247, 0.10)',
  boxShadow:
    '0px 6px 20px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 4px rgba(80, 78, 243, 0.50) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.40) inset',
  backdropFilter: 'blur(5px)',
  p: '0.5',
  mb: ['2', null, '4'],
  mt: 0,
};

export const BoxWrapper: CSSObject = {
  h: [null, null, '39'],
  alignItems: 'center',
  display: 'flex',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'smxs',
};

export const ButtonViewMore: CSSObject = {
  bg: 'rgba(68, 72, 210, 0.50)',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'gamma.400',
  textTransform: 'uppercase',

  _hover: {
    bg: 'rgba(68, 72, 210, 0.70)',
  },
};
