/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { useQueryPromotions } from '@/api/punter/deposit/deposit.hooks';
import Banner from '@/components/Banner/Banner';
import { Button } from '@/components/Button/Button';
import {
  bannerHeaderProps,
  bannerTextProps,
  buttonDepositsAvailableProps,
} from '../styles/Form/Form.styles';

/**
 * Promo banner used within formik
 */
export default function PromoBanner() {
  const { setFieldValue, values } = useFormikContext<{
    opted_in_promo: string;
  }>();

  const handleFieldValue = (promoId: string) =>
    setFieldValue('opted_in_promo', values.opted_in_promo ? null : promoId);

  return (
    <BannerMatch
      value={values.opted_in_promo}
      setFieldValue={handleFieldValue}
    />
  );
}

/**
 * Promo banner used within the primer flow
 */
type PromoBannerContainerProps = {
  value: string;
  handleOnClick: Dispatch<SetStateAction<string | undefined>>;
};

export function PromoBannerContainer({
  value,
  handleOnClick,
}: PromoBannerContainerProps) {
  const handleFieldValue = (id: string) =>
    handleOnClick((state) => (state ? undefined : id));

  return <BannerMatch value={value} setFieldValue={handleFieldValue} />;
}

type BannerMatchProps = {
  value: string;
  setFieldValue: (id: string) => void;
};

function BannerMatch({ value, setFieldValue }: BannerMatchProps) {
  const { data: promosData } = useQueryPromotions({
    promo_type: 'deposit_match',
  });
  const {
    percentage,
    max_amount: maxAmount,
    expiry: rawExpiry,
    opt_in_required: optInRequired,
    id: promoId,
  } = promosData?.data[0] ?? {};
  const expiry = rawExpiry ? new Date(rawExpiry) : null;

  const isOpen =
    typeof percentage === 'number' && typeof maxAmount === 'number';

  // Banner will never be "in" if these props are undefined
  const _maxAmount = Number(maxAmount ?? 0) / 100;

  return (
    <Banner
      collapseIn={isOpen}
      sx={{ fontSize: 'sm', alignItems: 'flex-start' }}
    >
      <Text sx={{ ...bannerHeaderProps }}>
        <FormattedMessage id="account.deposit.depositMatchPromoBannerHeader" />
      </Text>
      <Text sx={{ ...bannerTextProps }}>
        <FormattedMessage
          id="account.deposit.depositMatchPromoBannerText"
          values={{
            total: _maxAmount,
            percentage,
            maxAmount: _maxAmount / (percentage ?? 0),
            expiry,
            willExpire: !!expiry,
            p: (chunks) => <>{chunks}</>,
            time: (chunks) => (
              <time dateTime={rawExpiry || undefined}>{chunks}</time>
            ),
          }}
        />
      </Text>

      {optInRequired && (
        <Button
          {...buttonDepositsAvailableProps}
          isActive={!!value}
          isDisabled={!!value}
          onClick={() => setFieldValue(promoId ?? '')}
        >
          <FormattedMessage
            id={`account.deposit.depositMatch.${
              value ? 'activated' : 'activate'
            }`}
          />
        </Button>
      )}
    </Banner>
  );
}
