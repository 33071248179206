import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import {
  FlexFeatureCardWrapper,
  FlexIllustrationWrapper,
  FlexWrapper,
  HeadingSlogan,
  SpanSlogan,
} from '../styles/Featured.styles';
import { FeaturedMatch } from '../components/FeaturedMatch';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  illustration: <FlexIllustrationWrapper />,
  featureCard: {
    wrapper: <FlexFeatureCardWrapper />,
    heading: (
      <>
        <HeadingSlogan>
          <FormattedMessage
            id="promo.sport.featuredSport"
            values={{
              bu: (msg) => <SpanSlogan>{msg}</SpanSlogan>,
            }}
          />
        </HeadingSlogan>
        <FeaturedMatch />
      </>
    ),
  },
};

export default template;
