import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputLeftAddon: {
    transition: 'all .2s ease-in-out',
    bg: 'beta.400',
    color: 'alpha.700',
    fontSize: 'sm',
    '&&': {
      h: '34px',
    },
    fontWeight: 'bold',
  },
  betCardInput: {
    borderWidth: '2px',
    sx: {
      '&&': {
        borderColor: 'beta.400',
        bg: 'alpha.800',
      },
      ':first-child': {
        pl: '3',
      },
      '&::placeholder': { color: 'white', opacity: '0.5' },
    },
    color: 'white',
    borderRadius: 'md',
    fontSize: 'sm',
    _hover: {
      '&&': {
        bg: 'alpha.600',
        borderColor: 'beta.400',
      },
      '.chakra-input__left-element': {
        bg: 'epsilon.300',
      },
    },
    _focus: {
      borderColor: 'alpha.500',
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.3)',
      '&&': {
        background: 'epsilon.300',
      },
      '&::placeholder': { color: 'white', opacity: '0.8' },
    },
  },
};
