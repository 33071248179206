import React from 'react';
import { WrapperFlexOnboarding } from '../styles/onboarding.styles';
import TemplateSignup from './templates/template';
import { createTemplate } from '@/lib/templateSwitch';

export default function SignUp() {
  const template = createTemplate(TemplateSignup);

  return <WrapperFlexOnboarding>{template}</WrapperFlexOnboarding>;
}
