import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  name: {
    color: 'gamma.200',
  },
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(0, 0, 0, 0.25)',
    pb: '2',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
      pb: '0',
    },
  },
};
export default styles;
