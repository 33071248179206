import {
  BoxProps,
  ContainerProps,
  FlexProps,
  TextProps,
} from '@chakra-ui/react';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { styleImports } from './AccountWrapper.styles.imports';

export type AccountWrapperSchema = Partial<{
  boxWrapper: BoxProps;
  boxHeading: BoxProps;
  textTitle: TextProps;
  textHeading: TextProps;
  accountInfoContainer: ContainerProps;
  accountHeaderContainer: ContainerProps;
  titleContainer: BoxProps;
  flexVerticalWrapper: FlexProps;
}>;

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    'data-testid': 'accountWrapper-boxWrapper',
    minH: '100%',
    borderRadius: [null, null, null, 'lg'],
    pb: ['8', '4'],
    ...(styleImports?.accountWrapperStyles?.boxWrapper ?? {}),
  },
  boxHeading: {
    'data-testid': 'accountWrapper-boxHeading',
    pt: ['1', '5'],
    sx: {
      '&[data-hasPageHeader="true"]': {
        h: IS_MOBILE_APP ? 'unset' : 'auto',
        pb: () => {
          if (IS_MOBILE_APP) return '0';
          return ['7', null, '5'];
        },
      },
    },
    ...(styleImports?.accountWrapperStyles?.boxHeading ?? {}),
  },
  textTitle: {
    'data-testid': 'accountWrapper-textTitle',
    fontWeight: 'bold',
    fontSize: '2xl',
    mt: '7',
    ...(styleImports?.accountWrapperStyles?.textTitle ?? {}),
  },
  textHeading: {
    'data-testid': 'accountWrapper-textHeading',
    pt: '12',
    pb: '6',
    fontSize: 'xl',
    fontStyle: 'normal',
    ...(styleImports?.accountWrapperStyles?.textHeading ?? {}),
  },
  accountInfoContainer: {
    'data-testid': 'accountWrapper-accountInfoContainer',
    minH: '90vh',
    display: 'flex',
    gap: ['1', null, '4'],
    flexDirection: ['column', null, 'row'],
    ...(styleImports?.accountWrapperStyles?.accountInfoContainer ?? {}),
  },
  accountHeaderContainer: {
    'data-testid': 'accountWrapper-accountHeaderContainer',
    ...(styleImports?.accountWrapperStyles?.accountHeaderContainer ?? {}),
  },
  titleContainer: {
    'data-testid': 'accountWrapper-titleContainer',
    ...(styleImports?.accountWrapperStyles?.titleContainer ?? {}),
  },
  flexVerticalWrapper: {
    'data-testid': 'accountWrapper-flexVerticalWrapper',
    ...(styleImports?.accountWrapperStyles?.flexVerticalWrapper ?? {}),
  },
};
