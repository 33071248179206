/* eslint-disable import/no-mutable-exports */
import React, { ReactNode } from 'react';
import { GridItem } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  BoxAside,
  BoxContent,
  GridWrapper,
} from '../styles/OnboardingLayout.styles';
import { EOnboardingPageType } from '../OnboardingWrapper.types';
import FormContentTopRight from '../conditionals/FormContentTopRight/template';

const template = (
  children: ReactNode,
  aside?: ReactNode,
  pageType?: EOnboardingPageType
): TTemplate => ({
  wrapper: <GridWrapper />,
  aside: (
    <GridItem gridArea="aside" className="bc-aside-item">
      <BoxAside className="bc-aside">{aside}</BoxAside>
    </GridItem>
  ),
  content: (
    <GridItem gridArea="content" className="bc-content-item">
      <BoxContent className="bc-content" pageType={pageType}>
        <FormContentTopRight />
        {children}
      </BoxContent>
    </GridItem>
  ),
});

export default template;
