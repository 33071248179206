import { LinkItemSchema } from '@/views/sports/components/CompetitionsMenu/styles/LinkItem.styles';

export const linkItemStyles: LinkItemSchema = {
  link: {
    bg: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: '2',
    pl: '3',
    pr: '2',
    _hover: {
      textDecoration: 'none',
    },
    _last: {
      mb: 0,
    },
  },
  textCompetitionName: {
    color: 'delta.600',
    fontFamily: 'accent',
    fontWeight: 'normal',
    fontSize: 'sm',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxW: '140px',
    filter: 'drop-shadow(0px 1px 0px 0px var(--bc-colors-whiteAlpha-500))',
  },
  textMatchCount: {
    px: '1',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
    color: 'delta.600',
  },
};
