import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { centsToDollars } from '@/helpers/utils';
import { useMyBetsLength } from '@/components/Headers/HeaderPrimary/services/Header.hooks';
import VerificationStatusButton from '../VerificationStatus/VerificationStatusButton';
import { useAccountBalance } from '../../services/Overview.hooks';
import {
  bannerStyles,
  FlexAccountBalances,
  FlexAccountDetails,
  FlexAccountName,
  FlexBalanceBetInfo,
  FlexBannerContainer,
  FlexButtonContainer,
  FlexLabel,
  TextButton,
} from './styles/Banner.styles';
import BannerBalance from './BannerBalance';
import { usePunterData } from '@/store/AuthStore';
import { Button } from '@/components/Button/Button';

const Banner: FC = () => {
  const punterProfile = usePunterData();
  const { accountBallance, availableBalance, accountBonusBalance } =
    useAccountBalance();
  const formattedAccountBalance = centsToDollars(accountBallance || 0, true);
  const formattedAccountBonusBalance = centsToDollars(
    accountBonusBalance || 0,
    true
  );
  const formattedAvailableBalance = centsToDollars(availableBalance || 0, true);
  const bets = useMyBetsLength();
  return (
    <FlexBannerContainer flexDir="column">
      <FlexAccountDetails>
        <FlexAccountName>
          {punterProfile?.first_name} {punterProfile?.last_name}
        </FlexAccountName>
        <Flex flex="1">
          <VerificationStatusButton />
        </Flex>
        <FlexAccountBalances>
          <BannerBalance formattedBalance={formattedAccountBalance} />
          <BannerBalance
            formattedBalance={formattedAccountBonusBalance}
            isBonus
          />
        </FlexAccountBalances>
      </FlexAccountDetails>
      <FlexButtonContainer>
        <Button
          as={Link}
          to="/account/deposit"
          data-cy="overviewDepositButton"
          {...bannerStyles.buttonDeposit}
        >
          <TextButton>
            <FormattedMessage id="account.deposit.titles.main" />
          </TextButton>
        </Button>
        <FlexLabel>
          <FlexBalanceBetInfo>
            <FormattedMessage id="account.overview.withdrawable" />:{' '}
            {formattedAvailableBalance}
          </FlexBalanceBetInfo>
          <FlexBalanceBetInfo>
            <FormattedMessage id="account.overview.pendingBets" />: {bets}
          </FlexBalanceBetInfo>
        </FlexLabel>
      </FlexButtonContainer>
    </FlexBannerContainer>
  );
};

export default Banner;
