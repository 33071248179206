/* eslint-disable @typescript-eslint/ban-ts-comment */

// Helper function to compare two sets
function setsAreEqual(set1: Set<string>, set2: Set<string>): boolean {
  if (set1.size !== set2.size) return false;
  // @ts-ignore
  return [...set1].every((item) => set2.has(item as string));
}

export function updateSelections(
  original: { [key: string]: { selections: string[]; selected: string } },
  updated: Map<string, Set<string>>
): {
  state: {
    [key: string]: { selections: string[]; selected: string };
  };
  hasChanges: boolean;
} {
  const result = { ...original };
  let hasChanges = false;

  // Check each key in the updated Map
  updated.forEach((value, key) => {
    if (!result[key]) {
      // If the key doesn't exist in the original, add it
      result[key] = { selections: Array.from(value), selected: '' };
      hasChanges = true;
    } else {
      const originalSelections = new Set(result[key].selections);
      const updatedSelections = new Set(value);

      // Check if the selections are different
      if (!setsAreEqual(originalSelections, updatedSelections)) {
        result[key].selections = Array.from(updatedSelections);
        hasChanges = true;

        // If the previously selected item is no longer in the selections, reset it
        if (!updatedSelections.has(result[key].selected)) {
          result[key].selected = '';
        }
      }
    }
  });

  // Check for keys in the original that are not in the updated Map
  Object.keys(original).forEach((key) => {
    if (!updated.has(key)) {
      delete result[key];
      hasChanges = true;
    }
  });

  return { state: hasChanges ? result : original, hasChanges };
}
