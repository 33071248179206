import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'alpha.500',
  w: '100%',
  mt: ['unset', '4'],
  overflow: 'visible',
  color: 'white',
  pb: '0',
  py: '4',
  boxShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
  borderTopRadius: '7px',
  '&&': { px: ['2', '4'], pb: '2' },
  fontWeight: 'medium',
  fontSize: '21px',
  textTransform: 'uppercase',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontFamily: 'SkranjiBold',
  fontSize: '21px',
  textTransform: 'uppercase',
  color: 'white',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  borderRadius: [null, null, null, 'base'],
  minHeight: '100%',
  mx: ['2', 'unset'],
  mt: ['2', 'unset'],
};

export const WrapperContainer: CSSObject = {
  bgGradient: 'linear(180deg, alpha.500 0%, alpha.600 100%)',
  px: '0',
  borderTopRadius: 'none',
  borderBottomRadius: '7px',
  boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 0px 0px #502714',
  color: 'delta.50',
  mb: '2',
};
