import { CSSObject } from '@chakra-ui/react';

export const MultiCardContainer: CSSObject = {
  mt: '2',
  pb: '0',
  px: '2',
  ':first-child': {
    borderY: '1px solid',
    borderColor: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  },
  ':last-child': {
    borderBottom: 'none',
    boxShadow: 'unset',
  },
  borderBottom: '1px solid',
  borderColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
};

export const OddsMultiCard: CSSObject = {
  fontWeight: 'medium',
  color: 'beta.400',
};
