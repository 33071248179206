import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'beta.500',
      background: {
        bg: ['alpha.400', null, null, 'rgba(0, 209, 255, 0.25)'],
        bgRepeat: ['no-repeat', null, null, 'unset'],
        bgSize: ['cover', null, null, 'unset'],
        borderRadius: ['0px', null, null, 'lg'],
        px: ['0', null, null, '5'],
        py: ['0', null, null, '3'],
        mb: ['0', null, null, '4'],

        // A gradient-border like effect
        boxShadow: [
          null,
          null,
          null,
          '0px 2px 20px 0px rgba(0, 0, 0, 0.4), 0px 0px 5px 0px rgba(33, 193, 255, 0.25) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset',
        ],
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: '18px',
        mr: '2',
      },
      headerSX: {
        cursor: 'default',
        color: 'beta.300',
        fontSize: 'xs',
        fontWeight: '700',
        borderBottom: '1px dashed rgba(0, 0, 0, 0.2)',
        filter: 'drop-shadow(0px 0px 8px rgba(254, 208, 119, 0.5))',
        '&:hover': {
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,
        fontSize: 'sm',
        fontWeight: '600',
        height: '45px',
        color: 'white',
        transition: 'all, 0.2s linear',
        borderTop: '1px dashed',
        borderTopColor: 'whiteAlpha.300',
        borderBottom: '1px dashed',
        borderBottomColor: 'blackAlpha.300',
        ':first-child': {
          borderTop: 'none',
        },
        ':last-child': {
          borderBottom: 'none',
        },
        py: '3',
        px: ['3', null, null, '2'],
        ml: '0',
        '~ .nested': {
          pl: '0',
        },
        svg: {
          color: ['beta.300', null, null, 'alpha.100'],
          filter: [
            'drop-shadow(0px 0px 8px rgba(251, 201, 21, 0.50))',
            null,
            null,
            'drop-shadow(0px 0px 8px rgba(65, 255, 244, 0.5))',
          ],
        },
        _hover: {
          bg: 'rgba(0, 0, 0, 0.24)',
          color: 'beta.300',
          textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
          svg: {
            color: 'beta.300',
          },
        },
        _active: {
          bg: 'rgba(0, 0, 0, 0.24)',
          opacity: '1',
        },
        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },
        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
          borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
        },

        '> a, .dropDownIcon, a': {
          // color: 'alpha.100',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '2'],
            borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        '.dropDownInner': {
          px: '2.5',
          color: 'beta.300',
          bg: 'rgba(0, 0, 0, 0.24)',
          svg: {
            fill: 'beta.300',
            filter: 'drop-shadow(0px 0px 8px rgba(251, 201, 21, 0.50))',
          },
        },
        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'beta.300',
          filter: 'drop-shadow(0px 0px 8px rgba(251, 201, 21, 0.50))',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
