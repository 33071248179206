import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  mx: ['2.5', null, '3'],
  px: '2',
  border: '2px',
  borderColor: 'epsilon.500',
  bg: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  mb: '2',
  flex: '1',
};

export const SelectFieldContainer: CSSObject = {
  w: 'unset',
};

export const CheckboxBoxed: CSSObject = {
  bg: 'white',
  color: 'gray.700',
  right: 0,
  justifyContent: 'flex-end',
  mr: '3',
  w: 'auto',
};

export const TextBoxed: CSSObject = {
  color: 'black',
  fontSize: 'sm',
};
