import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';
import { CustomButtonProps } from '@/components/Button/Button';

export const TableItem: CSSObject = {
  borderColor: 'blackAlpha.400',
  borderRight: 'none',
  color: 'white',
  pl: 0,
  py: 2,
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const TableText = ({ textVariation }: TTableText): CSSObject => ({
  color: textVariation === 'positive' ? 'beta.400' : 'white',
});

export const NoTransactionsText: CSSObject = {
  color: 'white',
};

export const buttonMoreTableProps: CustomButtonProps = {
  variant: 'secondary',
};
