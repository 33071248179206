import astronaut from '../../assets/betgalaxy/images/betslip/betslip-astronaut.png';
import bg from '../../assets/betgalaxy/images/betslip/betslip-footer.png';
import onboardingAstronaut from '../../assets/betgalaxy/images/backgrounds/secondary-layout-astronaut.png';
import maintenanceBg from '../../assets/betgalaxy/images/backgrounds/background.png';

export const images = {
  astronaut,
  bg,
  onboardingAstronaut,
  maintenanceBg,
};
