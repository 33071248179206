import { CSSObject } from '@chakra-ui/react';
import ruby from '../../../../../assets/puntgenie/images/switch/ruby.svg';

export const FlexWrapper = {
  borderRadius: '2xl',
  contain: 'layout',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: ['1.5', null, null, 'unset'],
  mb: ['1', null, null, '2.5'],
  gap: '2',

  boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
  bg: 'beta.500',
  border: '3px solid',
  borderColor: 'beta.100',
};

export const TextToggle = (isActive: boolean) => ({
  fontSize: 'sm',
  fontFamily: 'CinzelDecorative',
  fontWeight: 'black',
  flex: [1, 'unset'],

  borderRadius: 'full',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  py: '1',
  maxW: '110px',
  textAlign: 'center',
  transition: 'all, 0.2s linear',
  color: 'gamma.500',

  // This is just a placeholder
  ...(isActive && {
    color: 'gamma.500',
  }),
  ...(!isActive && {
    color: 'gamma.500',
  }),
});

export const Switcher = (): CSSObject => ({
  bg: 'transparent',
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
  '--switch-track-width': 'calc(var(--bc-sizes-9))',

  '.chakra-switch__thumb': {
    bg: 'transparent',
    bgImage: `url(${ruby})`,
    bgPos: 'center',
    bgSize: 'full',
    bgRepeat: 'no-repeat',
    boxSize: '10',
    mt: '-1.5',
    ml: '-1',
  },
  // TODO: #22519A is not in the theme
  '.chakra-switch__track': {
    boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
    bg: '#22519A',
    pr: '4',
    pl: '-1',
    mb: '1',
  },

  _checked: {
    bg: 'transparent',

    '.chakra-switch__track': {
      boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
      bg: '#22519A',
    },
  },
});
