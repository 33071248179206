import { CSSObject } from '@chakra-ui/react';
import background from '../../../../../../assets/vikingbet/images/backgrounds/accountBanner.png';
import backgroundMB from '../../../../../../assets/vikingbet/images/backgrounds/accountBannerMB.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'secondary',

    sx: {
      '&&': {
        h: 7,
        fontSize: 'xs',
        px: 3,
        lineHeight: '17px',
        mt: '1px',
      },
    },
  },
};

export const FlexBannerContainer: CSSObject = {
  bgImage: [`url(${backgroundMB})`, null, `url(${background})`],
  bgPos: [null, null, 'center'],
  bgRepeat: 'no-repeat',
  bgSize: ['100% 100%', null, '100%'],
  color: 'white',
  px: ['7', null, '4'],
  py: ['6', null, '6'],
  pt: ['5', null, '3'],
  pr: ['7', null, null],
  gap: ['2', null, '0'],
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1',
};

export const FlexAccountName: CSSObject = {
  fontSize: ['lg', null, 'xl'],
  fontFamily: 'Roboto',
  fontWeight: '600',
  color: 'zeta.400',
  letterSpacing: '0.8px',
};

export const FlexAccountBalances: CSSObject = {
  gap: '2',
  paddingTop: '2.5',
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  mb: '0.5',
  textTransform: 'uppercase',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'beta.500',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: 'sm',
  verticalAlign: 'super',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: '2xl',
  color: 'beta.500',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: 'lg',
  opacity: '0.7',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2.5',
};

export const TextButton: CSSObject = {
  h: '4',
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  borderRadius: 'base',
  border: '2px',
  borderColor: 'alpha.600',
  lineHeight: '4',
  flex: ['1', null, '0'],
  fontSize: '2xs',
  fontWeight: '900',
  h: ['9', null, '8'],
  justifyContent: 'center',
  px: '2',
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: [null, null, 'nowrap'],
  bg: 'alpha.700',
};

export const FlexLabel: CSSObject = {
  gap: ['5', null, '2'],
};
