import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading': `<suffix>Yikes!</suffix> 
    Looks like your slip is empty`,
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'onboarding.login.subtitle':
    "Welcome to <Node>OldGill's Betting Bonanza!</Node> Odds so good, even Old Gill can't resist. Place your bets on <Node>thrilling races and sports events.</Node> Let the games begin and get started today!",
  'onboarding.locationnotallowed.header': `this one's just for our special neighbors.`,
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing OldGill from outside of Australia.</b>',
  'error.heading': `we're in a bit of a pickle, partner.`,
  'error.description': `<bold>OldGill is currently down.</bold>
    Refresh the page, or try again later.`,
  'maintenance.heading': `Hold Tight, We're Sweatin' to Fix Things!`,
  'maintenance.error': `<bold>We are investigating the issue.</bold>
    Refresh the page, or try again later.`,
  'racing.errorMessages.noRacesAvailable': 'Sorry Folks!',
  'racing.errorMessages.noRacesAvailableSubtext': `<b>There are no more races available.</b>
  <p>Please consider selecting a different day to try again.</p>`,
  'sports.allsports.azheading': 'A-Z Sports',
  'sports.matchDetailPage.clearAll': 'Cancel',
  'racing.generic.numberRunner': 'Runner',
};
