import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'blackAlpha.300',
  mx: ['-2', null, '0'],
  px: ['2', null, '0'],
  justifyContent: 'space-between',

  display: 'flex',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  py: '2',
  borderRadius: 'md',
  gap: '1',
  minH: ['10', null, 'unset'],
};

export const RacerName: CSSObject = {
  color: 'black',
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
};
