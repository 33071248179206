import screw from '@/assets/buffalobet/images/sideNav/screw.png';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',
    pos: 'relative',
    _before: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      top: '10px',
      left: '9px',
      width: '252px',
      height: '12px',
      zIndex: '1',
      backgroundImage: ['none', null, null, `url(${screw})`],
      backgroundSize: ['inherit', null, null, '252px'],
      backgroundRepeat: 'no-repeat',
    },
    _after: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      bottom: '8px',
      left: '9px',
      width: '252px',
      height: '12px',
      backgroundImage: ['none', null, null, `url(${screw})`],
      backgroundSize: ['inherit', null, null, '252px'],
      backgroundRepeat: 'no-repeat',
      zIndex: '1',
    },
  },
  boxWrapperProps: {
    width: '100%',
    pos: 'relative',
    _before: {
      bg: 'alpha.100',
      h: '3px',
      w: '56px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderBottomRadius: '2em',
      content: '""',
      pos: 'absolute',
      left: '110px',
      display: ['none', null, null, 'block'],
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {},
};
