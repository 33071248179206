import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  flexExoticsHeadingRight: { alignItems: ['flex-end', 'center'] },
  buttonStyledGroupedProps: {
    variant: 'primary',
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'xs',
    boxSize: '9',
    minWidth: '9',
    minHeight: '9',
    p: '2.5',
    fontFamily: 'body',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'blackAlpha.600',
          color: 'alpha.200',
          border: '2px solid',
          borderColor: 'alpha.200',
          boxShadow:
            '0px 2px 4px 0px #0000004D inset, 0px 1px 0px 0px #FFFFFF26',
          _hover: {
            color: 'beta.800',
            bg: 'alpha.500',
            border: '2px solid',
            borderColor: 'alpha.200',
          },
        },
      },
      '&[data-closed="true"][data-active="false"]': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'alpha.500',
        bg: 'unset',
        boxShadow: 'none',
        color: 'alpha.400',
        textShadow: 'none',
        p: '2.5',
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',

  '&&': {
    '.quaddie': {
      borderColor: '#FFC138',
      borderWidth: '2px',
      color: 'white',
      boxShadow: 'none',

      '&[data-active="false"]': {
        boxShadow: 'none',
        bg: 'linear-gradient(180deg, #BA0700, #640502)',
        color: '#FFFFBF',
        p: '14px',
      },

      '&.active': {
        border: '2px solid',
        borderColor: 'red',
        bg: 'blackAlpha.600',
        color: '#FFFFBF',
      },
      '&:hover': {
        bg: 'alpha.600',
      },
    },
  },
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: 'none',
  boxShadow: 'none',
  flexDirection: 'row',
};

export const TextRaceConditions: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    w: '1px',
    h: '4',
    bg: 'whiteAlpha.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: 'green.500',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceStatusText: CSSObject = {
  color: 'orange.800',
  bg: 'orange.400',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  textTransform: 'none',
  whiteSpace: 'nowrap',
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  py: '3',
};

export const RaceRunnerList: CSSObject = {
  bg: 'transparent',
  p: '0',
};

export const TextTitle: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  textTransform: 'normal',
  color: 'gamma.300',
};

export const RaceName: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  mt: '1',
  pb: '1.5',
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  fontSize: 'sm',
  fontFamily: 'SilvanoWestern',
  alignItems: 'center',
  '.chakra-switch__track': {
    background: 'beta.200',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
    '& .chakra-switch__thumb': {
      background: 'alpha.200',
      '&[data-checked]': {
        bg: 'beta.700',
      },
    },
  },
  'div[role] p': {
    fontFamily: 'Roboto',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
  pb: '2',
  alignItems: 'center',
  fontFamily: 'body',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '12',
  },
  svg: {
    color: 'white',
  },
  fontWeight: 'bold',
  fontSize: '2xs',
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '2',
  mb: '0',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  fontFamily: 'accent',
  cursor: 'pointer',
  fontWeight: 'normal',
};
