import React from 'react';
import { Icon, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PiggyBank } from 'styled-icons/fa-solid';
import { useQueryPunterAccountOverview } from '../../../../../api/punter/punter.hooks';
import {
  BoxBallance,
  buttonDepositProps,
  FlexBallanceItem,
  TextKey,
  TextValue,
} from './styles/QuickAccessMenu.styles';
import { centsToDollars, getStrings } from '@/helpers/utils';
import { Button } from '@/components/Button/Button';

type TBalances = {
  showBallance: boolean;
  onClose: () => void;
};

export default function Balances({ showBallance, onClose }: TBalances) {
  const navigate = useNavigate();
  const { data, isLoading } = useQueryPunterAccountOverview();
  const [{ Generic }] = getStrings();

  return (
    <BoxBallance>
      {[
        {
          text: Generic.Balance,
          value: data?.account_balance,
          cy: 'txt-qa-balance',
          className: 'qa-balance',
        },
        {
          text: Generic.Withdrawable,
          value: data?.available_balance,
          cy: 'txt-qa-withdrawable',
          className: 'qa-withdrawable',
        },
      ].map((v) => (
        <FlexBallanceItem key={`qa-balance-${v.cy}`}>
          <TextKey className={v.className}>{v.text}:</TextKey>
          <Skeleton isLoaded={!isLoading}>
            <TextValue className={v.className} data-cy={v.cy}>
              {(() => {
                const balance = v.value ?? 0;

                if (!showBallance) return '•'.repeat(String(balance).length);
                return centsToDollars(balance);
              })()}
            </TextValue>
          </Skeleton>
        </FlexBallanceItem>
      ))}

      <Button
        {...buttonDepositProps}
        onClick={() => {
          onClose();
          navigate('/account/deposit');
        }}
        data-cy="btn-qa-deposit"
        leftIcon={<Icon as={PiggyBank} />}
      >
        {Generic.Deposit}
      </Button>
    </BoxBallance>
  );
}
