import { Image, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/puntcity/images/logo.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link as={LinkDom} to="/" display="flex">
      <Image
        src={logo}
        alt={`${THEME_NAME.puntcity}`}
        sx={{
          py: [null, null, null, '2'],
          m: [null, null, null, 'auto'],
          mb: [null, null, null, null, '2'],
          alignSelf: 'center',
          maxW: ['95px', null, null, '207px'],
          filter: 'drop-shadow(0 0 6px rgba(110,244,255, 0.5))',
        }}
      />
    </Link>
  );
}
