import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const InformationWrapper: CSSObject = {
  maxW: 'full',
  w: 'full',
};

export const TextM: CSSObject = {
  fontSize: 'sm',
  color: 'beta.800',
  fontWeight: 'semibold',
  mb: '1',
};
export const TextS: CSSObject = {
  color: 'beta.800',
  pt: '2',
  m: '0',
  fontSize: 'xs',
  pb: '1',
  fontWeight: 'semibold',
  _first: {
    pt: '0',
  },
};

export const TextCheckbox: CSSObject = {
  color: 'beta.800',
  fontWeight: 'medium',
};

export const BoxStyledLink: CSSObject = {
  color: 'delta.500',
  fontWeight: 'bold',
};

export const TextInformation: CSSObject = {
  color: 'beta.800',
  fontSize: 'xs',
  mb: '2',
  fontWeight: 'normal',
  '&:first-of-type': {
    fontWeight: 'bold',
  },
};

export const HeadingInformation: CSSObject = {
  color: 'beta.800',
  fontWeight: 'bold',
  mb: '1',
  fontSize: 'sm',
};
export const ListItemExclusionItem: CSSObject = {
  color: 'beta.800',
  fontWeight: 'bold',
};
export const BoxTakeABreakWrapper: CSSObject = {
  border: 'none',
};
export const BoxTakeABreak: CSSObject = {
  pb: '0',
  border: 'none',
};
export const buttonTakeBreakProps: CustomButtonProps = {
  w: 'full',
  variant: 'primary',
};
export const TakeABreakContainer: CSSObject = {
  color: 'beta.800',
  border: 'none',
  mb: '0',
  pb: '1',
  fontSize: 'sm',
};
export const TextDateLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  mt: '1',
};
export const TextDate: CSSObject = {
  fontSize: 'md',
  fontWeight: 'bold',
  color: 'delta.500',
  mb: '0',
};
export const TextConfirmBreak: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  color: 'beta.800',
};

export const buttonConfirmBreakProps: CustomButtonProps = {
  w: 'full',
  variant: 'primary',
};
