import { CSSObject } from '@chakra-ui/react';

export const PageHeadingWrapper: CSSObject = {
  p: '0',
  borderColor: 'blackAlpha.300',
  borderTopWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.28)',
};

export const TableWrapper: CSSObject = {
  pt: '0',
};

export const TableItem: CSSObject = {
  borderRight: 'none',
  borderLeft: 'none',
  borderTop: 'none',
  borderColor: 'blackAlpha.300',
  borderBottomWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.28)',
  pl: '0',
  py: '2',
  color: 'white',
  fontWeight: 'bold',
};

export const TableText = () => ({
  color: 'white',
  fontWeight: 'normal',
  ':first-child': {
    fontWeight: 'bold',
  },
});

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const buttonMoreTableProps: CSSObject = {
  color: 'white',
};

export const NoTransactionsText: CSSObject = {
  color: 'white',
};
