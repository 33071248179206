import { CSSObject } from '@chakra-ui/react';

export const ForgotPasswordButton: CSSObject = {
  bg: 'beta.500',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
  _hover: {
    bg: 'beta.700',
  },
  _active: {
    bg: 'beta.100',
  },
};
