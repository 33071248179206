import React from 'react';
import { chakra, Flex, Icon, keyframes, Text } from '@chakra-ui/react';
import { CheckCircle, XCircle } from '@styled-icons/boxicons-solid/';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Footer.styles.imports';

const themeName = getThemeName();

export const FooterText = chakra(Text, {
  label: 'footer-FooterText',
  baseStyle: () => ({
    flex: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.FooterText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FooterContainer = chakra(Flex, {
  label: 'footer-FooterContainer',
  baseStyle: () => ({
    pl: '3',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 'md',
    height: '7',
    ...(() => {
      try {
        return styleImports[themeName]?.FooterContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const EstReturnsLabel = chakra(Flex, {
  label: 'footer-EstReturnsLabel',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.EstReturnsLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const EstReturnsBlock = chakra(Text, {
  label: 'footer-EstReturnsBlock',
  baseStyle: () => ({
    fontSize: '2xs',
  }),
});

export const CountdownContainer = chakra(Flex, {
  label: 'footer-CountdownContainer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.CountdownContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PendingBetContainer = chakra(Flex, {
  label: 'footer-PendingBetContainer',
  baseStyle: () => ({
    bgGradient: 'linear(to-b, yellow.400, yellow.500)',
    boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.5)',
    color: 'white',
    h: '8',
    alignItems: 'center',
    borderRadius: 'base',
    px: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.PendingBetContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ApprovedBetContainer = chakra(Flex, {
  label: 'footer-ApprovedBetContainer',
  baseStyle: () => ({
    bgGradient: 'linear(to-b, green.400, green.500)',
    boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.5)',
    color: 'white',
    minH: '8',
    alignItems: 'center',
    borderRadius: 'base',
    px: '2',
    py: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.ApprovedBetContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RejectedBetContainer = chakra(Flex, {
  baseStyle: () => ({
    label: 'footer-RejectedBetContainer',
    bgGradient: 'linear(to-b, red.400, red.500)',
    boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.5)',
    color: 'white',
    minH: '8',
    alignItems: 'center',
    borderRadius: 'base',
    px: '2',
    py: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.RejectedBetContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetApprovalText = chakra(Text, {
  baseStyle: () => ({
    label: 'footer-BetApprovalText',
    fontSize: 'sm',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.BetApprovalText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetPendingApprovalText = chakra(Text, {
  baseStyle: () => ({
    label: 'footer-BetPendingApprovalText',
    fontSize: 'sm',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.BetPendingApprovalText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetApprovalContainer = chakra(Flex, {
  label: 'BetApprovalContainer',
  baseStyle: () => ({
    flexDir: 'column',
    w: 'full',
    gap: '1',
    px: '2',
    pb: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.BetApprovalContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  33% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const PendingBetIcon = chakra(Icon, {
  baseStyle: () => ({
    animation: `${rotate} 3s linear`,
    animationIterationCount: 'infinite',
    animationDelay: '1s',
  }),
});

export const IconApprovedBet = () => {
  try {
    return (
      styleImports[themeName]?.IconApprovedBet?.() ?? (
        <Icon boxSize="4" mr="2" as={CheckCircle} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Icon boxSize="4" mr="2" as={CheckCircle} />;
};

export const IconRejectedBet = () => {
  try {
    return (
      styleImports[themeName]?.IconRejectedBet?.() ?? (
        <Icon w="4" h="4" mr="2" as={XCircle} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Icon w="4" h="4" mr="2" as={XCircle} />;
};

export const BetRejectedText = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.BetRejectedText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
