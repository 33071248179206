import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TabContainer: CSSObject = {
  border: '0',
};

export const TabWrapper: CSSObject = {
  gap: 1,
  bg: 'white',
  borderRadius: 'lg',
  boxShadow: '0 3px 8px rgba(0, 0, 0, .2)',
  p: '1.5',
  mt: '1',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  sx: {
    '&&': {
      bg: 'transparent',

      _active: {
        textShadow: '0 2px 3px rgba(0, 0, 0, .2)',

        color: 'white',
        bg: 'alpha.600',
        boxShadow: 'none',

        _hover: {
          bg: 'alpha.600',
          color: 'white',
        },
      },
    },
  },

  borderRadius: 'md',
  boxShadow: 'none',
  color: 'gray.600',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: 'none',

  _hover: {
    bg: 'transparent',
    color: 'gray.700',
  },

  _focus: {
    boxShadow: 'none',
  },
};
