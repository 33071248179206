import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/oldgill/images/backgrounds/bg-top.png';
import bottom from '@/assets/oldgill/images/backgrounds/bg-bottomMb.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), linear-gradient(180deg, #075F9F, #075F9F)`,
          backgroundPosition: 'center top 40px, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundAttachment: 'fixed',
          '@media (max-width: 767px)': {
            background: `url(${bottom}), linear-gradient(180deg, #075F9F, #075F9F)`,
            backgroundSize: 'contain',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
          },
        },
      }}
    />
  );
}
