import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '3.5',
  mt: ['-3', null, '-5'],
  px: ['2', '0'],
  top: ['0px', '9'],
  position: 'unset',
};

export const ButtonFilter = ({ selected }: TSelected) => ({
  color: selected ? 'alpha.500' : 'beta.700',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'alpha.500' : 'beta.700',
  color: selected ? 'white' : 'transparent',
});

export const TextFilterLabel: CSSObject = {
  color: 'beta.700',
};
