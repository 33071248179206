import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    border: 'none',
    borderTop: '1px dashed',
    borderColor: '#656E78',
    bg: 'transparent',
    boxShadow: 'none',

    ...((propLength ?? 0) === 2 && {
      p: 0,
      borderTop: 0,
      pt: '15px',
      pb: '8px',
    }),
    ...((propLength ?? 0) > 2 && {
      px: '2',
      py: 0,
      h: '47px',
      minH: '47px',

      _first: { borderTop: 0 },

      _hover: {
        span: {
          _first: { color: 'white' },
        },
        bg: 'epsilon.50',
      },
    }),

    ...(isSelected && {
      boxShadow: 'none',
    }),

    ...(!!isSelected && {
      boxShadow: 'none',
    }),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    ...((propLength ?? 0) === 2 && {
      color: 'var(--bc-alpha-600, #D6A64A)',
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      mb: '2px',
    }),

    ...((propLength ?? 0) > 2 && {
      color: 'var(--bc-white, #FFF)',
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 600,
    }),

    '&.return-amount': {
      borderRadius: '5px',
      border: '2px solid rgba(255, 255, 255, 0.09)',
      background:
        'var(--bc-gradient-gold, linear-gradient(180deg, #FFCE70 0%, #CC952B 100%))',
      boxShadow:
        '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px #FFF inset',
      w: '100%',
      h: '35px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'var(--bc-black-alpha-800, rgba(0, 0, 0, 0.80))',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
      fontSize: '16px',
      fontFamily: 'CrimsonPro',
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: 'normal',
      textTransform: 'uppercase',

      _hover: {
        border: '2px solid rgba(255, 255, 255, 0.09)',
        background: 'var(--bc-alpha-700, #CC952B)',
        boxShadow:
          '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #FFF inset',
      },

      ...(isSelected && {
        borderRadius: '5px',
        background: 'var(--bc-beta-600, #21252B)',
        boxShadow: '0px 3px 3px 3px rgba(0, 0, 0, 0.30) inset',
        textAlign: 'center',
        fontSize: '16px',
        fontFamily: 'CrimsonPro',
        fontStyle: 'normal',
        fontWeight: 900,
        lineHeight: 'normal',
        textTransform: 'uppercase',
        color: '#FFCE70',
      }),
      ...((propLength ?? 0) > 2 && {
        width: 'unset',
        h: '32px',
      }),
    },
  },
});
