import React, { FC } from 'react';

import { ILink } from './styles/InlineLink.styles';

import InlineLinkProps from './types';

const InlineLink: FC<InlineLinkProps> = ({ path, target, children }) => (
  <ILink to={path} target={target ?? ''}>
    {children}
  </ILink>
);

export default InlineLink;
