import { CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const LimitLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const LimitValue: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.16)',
  borderWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.28)',
  my: '2.5',
};

export const DepositLimitContainer: CSSObject = {
  p: '4',
  borderRadius: 'lg',
  bg: 'gamma.100',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const PendingLimitContainer: CSSObject = {
  p: '4',
  fontSize: 'sm',
  color: 'white',
  borderRadius: 'lg',
};

export const ModalDivider: CSSObject = {
  display: 'none',
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalSubheading: CSSObject = {
  m: '0',
  p: '0',
};

export const DepositLimitInputContainer: CSSObject = {
  '#amount-input': {
    px: '2.5',
    py: '3',
    h: '10',
  },

  '#frequency-select': {
    borderRadius: 'lg',
    h: '10',
    fontWeight: 'normal',
    color: 'gray.600',
  },

  '.chakra-input__left-addon': {
    h: '10',
    px: '3.5',
  },

  '.chakra-form__error-message': {
    fontSize: 'xs',
  },
};

export const ButtonWrapper: CSSObject = {
  mt: '3',
};

export const CancelButton: CSSObject = {
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.500',
  color: 'alpha.500',
  textShadow: 'none',

  _hover: {
    bg: 'alpha.50',
  },

  _active: {
    bg: 'alpha.100',
  },
};
