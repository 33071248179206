import {
  chakra,
  ChakraComponent,
  CSSObject,
  Flex,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './SideNavLink.styles.imports';

const themeName = getThemeName();

export const SideNavHeading: ChakraComponent<
  'text',
  TextProps & { sxProps?: CSSObject }
> = chakra(Text, {
  label: 'sideNavLink-SideNavHeading',
  shouldForwardProp: (prop) => !['sxProps'].includes(prop),
  baseStyle: ({ sxProps }: any) => ({
    // These sxProps are currently being passed through from
    // until we migrate all styles to chakra factory
    // see `src/theme/{themeName}/customComponents/sideNav.ts
    // see useStyleConfig('SideNav')
    ...sxProps,
    ...(() => {
      try {
        return styleImports[themeName]?.SideNavHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexSideLinkWrapper = chakra(Flex, {
  label: 'sideNavLink-FlexSideLinkWrapper',
  baseStyle: () => ({
    alignItems: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexSideLinkWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
