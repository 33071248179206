import {
  Box,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Icon,
  Show,
  SkeletonText,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { ArrowRight } from '@styled-icons/fa-solid/ArrowRight';
import { Plus } from '@styled-icons/fa-solid/Plus';
import { camelCase, groupBy, intersection } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Link,
  NavLink as ReactRouterNavLink,
  useNavigate,
} from 'react-router-dom';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { TOfferedSport } from '@/api/offerings/offerings.types';
import { useInfiniteQueryUpcomingMatches } from '@/api/sports/upcomingMatches.hooks';
import {
  TUpcomingMatch,
  TUpcomingMatchProposition,
} from '@/api/sports/upcomingMatches.types';
import { useQueryUpcomingMatchesBySport } from '@/api/sports/upcomingMatchesBySport.hooks';
import {
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import IconSvg from '@/components/common/IconSvg';
import { getIconBySportName, isPendingAndMatchingId } from '@/helpers/utils';
import { useAppSelector } from '@/hooks/useRedux';
import { EGeneralStatus } from '@/lib/DBModels';
import PrimaryWrapper from '@/wrappers/PrimaryWrapper';
import Divider from '../components/Divider/Divider';
import MatchCard, {
  MatchCardBody,
  MatchCardFooter,
  MatchCardTitle,
} from '../components/NewMatchCard/NewMatchCard';
import Nav, { NavLink } from '../components/Nav/Nav';
import PageButton from '../components/PageButton/PageButton';
import PropositionButton from '../components/PropositionButton/PropositionButton';
import ScrollButtonGroup from '../components/ScrollButtonGroup/ScrollButtonGroup';
import Segmented from '../components/Segmented/Segmented';
import Well from '../components/Well/Well';
import { upcomingMatchesStyles } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';
import { Button } from '@/components/Button/Button';

export default function UpcomingSports() {
  const [shouldSortBySport, setShouldSortBySport] = useBoolean();
  const [activeSports, setActiveSports] = useState<string[]>([]);
  const intl = useIntl();
  const navigate = useNavigate();
  const { setBet } = getBetSlipStoreActions();

  // Sports
  const sportsQuery = useQueryOfferings();
  const sports = (sportsQuery.data?.offered_sports ??
    []) as unknown as TOfferedSport[];

  // Matches by date
  const params = { sport_id: activeSports.length ? activeSports : undefined };
  const matchesQuery = useInfiniteQueryUpcomingMatches(params, {
    enabled: !shouldSortBySport,
    getNextPageParam: ({ paging }) => paging?.next_offset,
  });
  const groupedMatches = groupBy(
    matchesQuery.data?.pages.flatMap(({ items }) => items),
    ({ match_start_time }) =>
      new Intl.DateTimeFormat('en-AU').format(new Date(match_start_time ?? ''))
  );
  const matchesByDate = Object.values(groupedMatches).map((value) => ({
    title: intl.formatDate(value[0].match_start_time, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    }),
    matches: value,
    icon: null,
  }));

  // Matches by sport
  const matchesBySportQuery = useQueryUpcomingMatchesBySport(params, {
    enabled: shouldSortBySport,
  });
  const matchesBySport =
    matchesBySportQuery.data?.data.map(({ sport_name, upcoming_matches }) => ({
      title: sport_name,
      matches: upcoming_matches ?? [],
      icon: getIconBySportName(sport_name),
    })) ?? [];

  const data = shouldSortBySport ? matchesBySport : matchesByDate;
  const isLoading =
    (shouldSortBySport && matchesBySportQuery.isLoading) ||
    (!shouldSortBySport && matchesQuery.isLoading);
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = matchesQuery;

  const { bets } = useAppSelector(({ betSlip }) => betSlip);
  const { addSportsBetSlip } = useSingleBetSlip();
  const { singlePropositionInBetSlip, removeFromBetSlip } =
    useBetSlipManageBets();

  const handleSelection = (
    marketName: string,
    proposition: TUpcomingMatchProposition,
    match: TUpcomingMatch
  ) => {
    if (proposition.status !== EGeneralStatus.Open) {
      return;
    }

    const betSlipProposition = singlePropositionInBetSlip(
      proposition.proposition_id
    );

    setBet({
      id: proposition.proposition_id,
      type: 'Single',
      propId: proposition.proposition_id,
      odds: proposition.odds ?? 0,
      misc: {
        ...match,
        ...proposition,
        sportTitle: marketName,
        proposition,
        match,
      },
    });

    if (betSlipProposition) {
      removeFromBetSlip(betSlipProposition.request_id);
    } else {
      addSportsBetSlip(
        marketName,
        { ...proposition, return_amount: proposition.odds },
        match
      );
    }
  };

  const resetActiveSports = () => {
    setActiveSports([]);
  };

  const filterBySport = (sportId: string) => {
    const sportIsActive = activeSports.includes(sportId);

    if (sportIsActive) {
      setActiveSports(activeSports.filter((sport) => sport !== sportId));
      return;
    }

    if (activeSports.length === sports.length - 1) {
      resetActiveSports();
      return;
    }

    const nextActiveSports = intersection(
      sports.map(({ sport_id }) => sport_id as string),
      [...activeSports, sportId]
    );
    setActiveSports(nextActiveSports);
  };

  const renderSorting = () => (
    <HStack {...upcomingMatchesStyles.hStackWrapper}>
      <Text {...upcomingMatchesStyles.sortByText}>
        <FormattedMessage id="generic.sortBy" />
      </Text>
      <Segmented>
        <Button
          isActive={!shouldSortBySport}
          onClick={setShouldSortBySport.off}
          {...upcomingMatchesStyles.segmentedButtonSortByTime}
        >
          <FormattedMessage id="generic.sortBy.time" />
        </Button>
        <Button
          isActive={shouldSortBySport}
          onClick={setShouldSortBySport.on}
          {...upcomingMatchesStyles.segmentedButtonSortBySport}
        >
          <FormattedMessage id="generic.sortBy.sport" />
        </Button>
      </Segmented>
    </HStack>
  );

  // Sort the sports array by sport_name
  const sortedSports = [...sports].sort((a, b) => {
    const sportNameA = a?.display_name || '';
    const sportNameB = b?.display_name || '';

    return sportNameA.localeCompare(sportNameB);
  });

  return (
    <PrimaryWrapper
      pageHeader={
        <Heading {...upcomingMatchesStyles.pageHeader}>Upcoming</Heading>
      }
      pageTitle="Sports"
    >
      <Stack {...upcomingMatchesStyles.stackWrapper}>
        <Box {...upcomingMatchesStyles.boxWrapper}>
          <Nav>
            <NavLink as={ReactRouterNavLink} to="/sports" end>
              <FormattedMessage id="generic.upcoming" />
            </NavLink>
            {/* TODO: should be `/sports/all` when done - may require 301 */}
            <NavLink as={ReactRouterNavLink} to="/sports/All" end>
              <FormattedMessage id="generic.sportAZ" />
            </NavLink>
          </Nav>
          <Show above="lg">{renderSorting()}</Show>
        </Box>

        <ScrollButtonGroup
          isDisabled={!sports.length}
          xCoord={!activeSports.length ? 0 : undefined}
          {...upcomingMatchesStyles.scrollButtonGroup}
        >
          <Button
            isActive={!activeSports.length}
            isDisabled={!sports.length}
            onClick={resetActiveSports}
            {...upcomingMatchesStyles.buttonAllSports}
          >
            <FormattedMessage id="sports.allsports" />
          </Button>

          {sports.length > 0 && (
            <>
              <div />
              <Box {...upcomingMatchesStyles.boxSportWrapper} />
              <div />
            </>
          )}

          {sortedSports.map(({ sport_id, display_name }) => (
            <Button
              key={sport_id}
              isActive={activeSports.includes(sport_id as string)}
              onClick={() => filterBySport(sport_id as string)}
              _last={{ mr: 2 }}
              {...upcomingMatchesStyles.buttonSport}
            >
              {display_name}
            </Button>
          ))}
        </ScrollButtonGroup>

        <Show below="lg">
          <Flex {...upcomingMatchesStyles.flexRenderSortingMobile}>
            {renderSorting()}
          </Flex>
        </Show>

        {isLoading ? (
          <div>
            <SkeletonText {...upcomingMatchesStyles.loadingSkeletonText} />
          </div>
        ) : (
          <Stack {...upcomingMatchesStyles.mainStackWrapper}>
            <Stack
              divider={<Divider />}
              {...upcomingMatchesStyles.mainStackInner}
            >
              {data.map(({ title, icon, matches }) => {
                const firstMatch = matches[0] ?? {};
                const sportUrl = `/sports/${encodeURIComponent(
                  firstMatch.sport_name ?? ''
                )}?sportId=${firstMatch.sport_id}`;

                return (
                  <Stack key={title}>
                    <Heading {...upcomingMatchesStyles.heading}>
                      {icon && (
                        <IconSvg
                          name={icon}
                          {...upcomingMatchesStyles.headingIcon}
                        />
                      )}
                      {title}
                    </Heading>
                    <Stack>
                      {matches.map((match) => {
                        const market = match.main_markets?.[0] ?? {};
                        const propositions = market.propositions ?? [];
                        const pathname = `/sports/${[
                          match.sport_name ?? '',
                          match.competition_name ?? '',
                          match.match_name ?? '',
                        ]
                          .map(encodeURIComponent)
                          .join('/')}`;

                        const search = `?${(
                          ['sport_id', 'competition_id', 'match_id'] as const
                        )
                          .map((key) => `${camelCase(key)}=${match[key]}`)
                          .join('&')}`;

                        const matchUrl = pathname + search;

                        return (
                          <MatchCard key={match.match_id}>
                            <MatchCardBody>
                              <MatchCardTitle
                                onClick={() => navigate(matchUrl)}
                              >
                                {match.match_name}
                              </MatchCardTitle>

                              {propositions.length !== 0 && (
                                <ButtonGroup>
                                  {propositions.map((p) => {
                                    const isActive =
                                      !!singlePropositionInBetSlip(
                                        p.proposition_id
                                      );

                                    const isDisabled =
                                      p.is_suspended ||
                                      p.status === EGeneralStatus.Closed ||
                                      isPendingAndMatchingId(
                                        bets,
                                        p.proposition_id
                                      );

                                    return (
                                      <PropositionButton
                                        key={p.proposition_id}
                                        isActive={isActive}
                                        isDisabled={isDisabled}
                                        isSuspended={p.is_suspended}
                                        odds={
                                          p?.status === EGeneralStatus.Voided
                                            ? undefined
                                            : p?.odds
                                        }
                                        propositionName={p.proposition_name}
                                        onClick={() =>
                                          handleSelection(
                                            market.market_name ?? '',
                                            p,
                                            match
                                          )
                                        }
                                        {...upcomingMatchesStyles.propositionButton}
                                      />
                                    );
                                  })}
                                </ButtonGroup>
                              )}
                            </MatchCardBody>
                            <MatchCardFooter
                              matchStartTime={match.match_start_time}
                              matchUrl={matchUrl}
                              sportName={match.sport_name}
                              competitionName={match.competition_name}
                              marketCount={match.market_count}
                              sgmAvailable={match.sgm_available}
                            />
                          </MatchCard>
                        );
                      })}
                    </Stack>

                    {shouldSortBySport && (
                      <Well>
                        <PageButton as={Link} to={sportUrl}>
                          <FormattedMessage id="generic.seeall" />
                          <Icon as={ArrowRight} />
                        </PageButton>
                      </Well>
                    )}
                  </Stack>
                );
              })}
            </Stack>

            {!shouldSortBySport && hasNextPage && (
              <Well {...upcomingMatchesStyles.pageButtonWellWrapperOverride}>
                <PageButton
                  isLoading={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  <FormattedMessage id="generic.showmore" />
                  <Icon as={Plus} />
                </PageButton>
              </Well>
            )}
          </Stack>
        )}
      </Stack>
    </PrimaryWrapper>
  );
}
