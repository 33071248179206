import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {},
  boxNavigationWrapperProps: {
    px: ['4', null, '0'],
  },
  navigationBoxProps: {},
};
