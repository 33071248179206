import { ButtonProps, CSSObject } from '@chakra-ui/react';
import takeAPuntBG from '@/assets/betroyale/images/backgrounds/takeAPunt.png';
import takeAPuntBGMobile from '@/assets/betroyale/images/backgrounds/takeAPuntMobile.png';

export const FlexTeamWrapper: CSSObject = {
  gap: '12px',
  mt: '4px',
  border: 0,
  flexDir: 'column',
};

export const FlexWrapper: CSSObject = {
  pl: '0',
  pr: '0',
  pb: '0',
  h: ['500px', null, null, '96'],
  bgImage: [`url(${takeAPuntBGMobile})`, null, `url(${takeAPuntBG})`],
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  bgPosition: 'center',
  mt: [0, null, '4'],
  mb: [0, null, '2'],
  border: 0,
  justifyContent: 'flex-end',
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  color: 'rgba(0, 0, 0, 0.70)',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.400',
  fontSize: 'xs',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'lg',
  color: 'beta.500',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'extrabold',
  maxW: ['90dvw', '60dvw', '50dvw', '40dvw', '500px'],
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const TextTeam: CSSObject = {
  color: 'white',
  textShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: 'md',
  fontWeight: 'extrabold',
};

export const BoxHeadingWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'center',
  marginLeft: ['auto', null, null, '45%'],
  my: '1',
  mr: ['auto', null, null, null, '8'],
  maxW: ['450px', null, null, null, '445px'],
  w: ['unset', null, null, null, '445px'],
};

export const puntButtonProps: ButtonProps & any = {
  variant: 'primary',
  border: 'none',
  cursor: 'pointer',
  zIndex: 'dropdown',
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '3',
  pt: '2',
};

export const FlexFeatureCardWrapper: CSSObject = {
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
