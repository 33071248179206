import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  borderRadius: 'base',
  color: 'alpha.900',
  fontSize: 'sm',
  fontWeight: 'medium',
  px: '3',
  py: '4',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.19)',
};

export const ButtonGetVerified: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  borderRadius: 'lg',
  color: 'blackAlpha.600',
  fontFamily: 'Coiny',
  fontSize: 'xs',
  fontWeight: 'base',
  h: '8',
  lineHeight: '6',
  pt: '1',
  ml: 'auto',
  minW: 'auto',
  px: '7',
  textTransform: 'uppercase',
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 3px rgba(0, 0, 0, 0.10) inset',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  overflow: 'hidden',
  border: '2px solid white',
  _hover: {
    bg: 'beta.600',
  },
  _focus: {
    bg: 'beta.600',
  },
};

export const TextMustBeVerified: CSSObject = {
  paddingRight: ['10px', null, null],
};
