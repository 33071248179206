import { CSSObject, TextProps } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};

export const FormLabelThemed: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
  mt: '2',
};

export const FormControlGroup: CSSObject = {
  'div.chakra-input__group > input[data-cy="passwordInput"]': {
    bg: 'gray.50',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
    boxShadow: 'unset',

    _placeholder: {
      color: 'gray.400',
    },
    '&:hover, &:focus': {
      bg: 'gray.100',
    },
  },
};
