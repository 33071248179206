import { css } from 'styled-components';

export const dotsContainer = css`
  margin: var(--bc-sizes-4) 0;
`;

export const carouselWrapper = css``;

// TODO: Remove
export const dot = css`
  width: var(--bc-sizes-2);
  height: var(--bc-sizes-2);
  border-radius: 50%;
  display: inline-block;
  margin: 0 var(--bc-sizes-1);
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  background-color: transparent;

  ${({ theme }) => theme.device.tablet} {
    width: var(--bc-sizes-3);
    height: var(--bc-sizes-3);
  }
`;

// TODO: Remove
export const dotSelected = css`
  width: var(--bc-sizes-2);
  height: var(--bc-sizes-2);
  border-radius: 50%;
  display: inline-block;
  margin: 0 var(--bc-sizes-1);
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  background-color: ${({ theme }) => theme.palette.primary};
  box-shadow: 0 0 10px 1px ${({ theme }) => theme.palette.white};

  ${({ theme }) => theme.device.tablet} {
    width: var(--bc-sizes-3);
    height: var(--bc-sizes-3);
  }
`;

export const slideWrapper = css``;
