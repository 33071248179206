import * as betprofessor from './Aside.styles.betprofessor';
import * as betroyale from './Aside.styles.betroyale';
import * as fiestabet from './Aside.styles.fiestabet';
import * as goldenrush from './Aside.styles.goldenrush';
import * as juicybet from './Aside.styles.juicybet';
import * as puntcity from './Aside.styles.puntcity';
import * as puntgenie from './Aside.styles.puntgenie';
import * as questbet from './Aside.styles.questbet';
import * as vikingbet from './Aside.styles.vikingbet';
import * as volcanobet from './Aside.styles.volcanobet';
import * as wellbet from './Aside.styles.wellbet';
import * as forsport from './Aside.styles.forsport';
import { themeName } from '@/helpers/getThemeConfig';

const exports = {
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  vikingbet,
  volcanobet,
  wellbet,
  forsport,
} as Record<string, any>;

export default exports[themeName];
