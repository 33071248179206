import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  color: 'beta.700',
  fontSize: ['24px', null, null, null, '40px'],
  fontFamily: 'SkranjiBold',
  w: ['auto', null, '686px'],
  textAlign: 'center',
  lineHeight: '100%',
  background: 'linear-gradient(180deg, #FCD236 0%, #E79004 100%)',
  backgroundClip: 'text',
  letterSpacing: '1.68px',
  WebkitTextFillColor: 'transparent',
  filter:
    'drop-shadow(0px 4px 0px #0b0b0b14) drop-shadow(0px 6px 3px rgba(0, 0, 0, 0.35))',
  WebkitBackgroundClip: 'text',
  WebkitTextStrokeWidth: ['1px', null, '2px'],
  WebkitTextStrokeColor: '#A1741F',
  textTransform: 'uppercase',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
};

export const ImageNoRaces: CSSObject = {
  w: '135px',
};
