import { CSSObject } from '@chakra-ui/react';
import background from '../../../../../../assets/betprofessor/images/backgrounds/overviewBg.png';
import backgroundRetina from '../../../../../../assets/betprofessor/images/backgrounds/overviewBg@2x.png';
import backgroundMobile from '../../../../../../assets/betprofessor/images/backgrounds/blockie.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: { h: 8, lineHeight: 'unset', fontSize: 'xs' },
};

export const FlexBannerContainer: CSSObject = {
  bgImage: [`url(${backgroundMobile})`, `url(${background})`],
  bgPos: [null, null, 'center'],
  bgRepeat: 'no-repeat',
  bgSize: '100% 100%',
  borderRadius: 'base',
  color: 'white',
  gap: ['2', null, '0'],
  px: ['2', null, '5'],
  py: ['2', null, '2.5'],
  boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.35)',
  filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.1))',
  overflow: 'hidden',
  minH: '107px',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: [`url(${backgroundMobile})`, `url(${backgroundRetina})`],
  },
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
};

export const FlexAccountName: CSSObject = {
  fontSize: ['lg', null, 'xl'],
  fontFamily: 'Roboto',
  fontWeight: 'semibold',
  color: 'alpha.200',
  letterSpacing: '0.8px',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'beta.500',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: 'sm',
  verticalAlign: 'super',
  color: 'alpha.200',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: '2xl',
  color: 'alpha.200',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: 'lg',
  opacity: '0.7',
  color: 'alpha.200',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
};

export const TextButton: CSSObject = {
  h: '4',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  fontFamily: 'fredoka',
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  borderRadius: 'base',
  border: '2px',
  borderColor: 'white',
  boxShadow: '0px 2px 0px 0px #B1B1B1, 0px 1px 0px 0px #B1B1B1',
  lineHeight: '4',
  flex: ['1', null, '0'],
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: ['9', null, '8'],
  justifyContent: 'center',
  px: '4',
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: [null, null, 'nowrap'],
};
