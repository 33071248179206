export const colors = {
  alpha: {
    100: '#66c8ff',
    200: '#3ea1fd',
    300: '#378cff',
    400: '#0070d7',
    500: '#0046a7',
    600: '#013d90',
    700: '#002f6f',
    800: '#00285f',
    900: '#001436',
  },
  beta: {
    100: '#fff8de',
    200: '#ffedad',
    300: '#ffe073',
    400: '#ffd84d',
    500: '#fbc915',
    600: '#e1b40f',
    700: '#a48207',
    800: '#665104',
    900: '#3a2e02',
  },
};
