import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    fontWeight: 'semibold',
    fontFamily: 'roboto',
    color: 'beta.700',
  },
  depositIconProps: {
    color: 'beta.700',
    boxSize: '9',
  },
};
