import { chakra, List, ListItem } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './styles/PlacesAutocomplete.styles.imports';

const themeName = getThemeName();

export const AutoCompleteContainer = styled.div`
  position: relative;

  ${({ theme }) => theme.components.addressAutoComplete.container};
`;

export const AutoCompleteResultContainer = chakra(List, {
  label: 'AutoCompleteResultContainer',
  baseStyle: () => ({
    position: 'absolute',
    top: '100%',
    right: '0',
    width: '100%',
    px: '2',
    py: '1',
    zIndex: '5',
    bg: 'white',
    border: '1px solid black',
    borderTop: 'none',
    fontSize: 'xs',
    textTransform: 'initial',

    ...(() => {
      try {
        return styleImports[themeName]?.AutoCompleteResultContainer;
      } catch (err) {
        console.log(err);
        return {};
      }
    })(),
  }),
});

export const AutoCompleteResult = chakra(ListItem, {
  baseStyle: () => ({
    display: 'flex',
    py: '1',
    pl: '1',
    cursor: 'pointer',
    color: 'black',
    bg: 'white',
    _hover: {
      color: 'white',
      bg: 'beta.500',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.AutoCompleteResult;
      } catch (err) {
        console.log(err);
        return {};
      }
    })(),
  }),
});

export const spinKeyframe = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

export const AutoCompleteLoadIndicator = styled.div`
  position: absolute;
  bottom: var(--bc-sizes-1);
  right: var(--bc-sizes-2);
  width: var(--bc-sizes-2);
  height: var(--bc-sizes-2);
  animation-name: ${spinKeyframe};
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  ${({ theme }) => theme.components.addressAutoComplete.loadIndicator};
`;
