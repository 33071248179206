import { CompetitionsMatchCardSchema } from './CompetitionsMatchCard.styles';

export const competitionsMatchCardStyles: CompetitionsMatchCardSchema = {
  vsBoxWrapper: {
    position: 'relative',
    p: '1',
    pr: '0',
  },
  vsDivider: {
    opacity: '0.2',
    ml: '20px',
    w: '-webkit-fill-available',
    borderColor: 'blackAlpha.200',
    borderStyle: 'dashed',
    position: 'relative',
    _after: {
      content: '""',
      position: 'absolute',
      left: '0',
      bottom: '0',
      w: 'full',
      borderBottom: '1.5px',
      borderColor: 'blackAlpha.500',
      borderStyle: 'dashed',
    },
  },
  absoluteCenter: {
    px: '2',
    left: '4.5',
    ml: '0',
    fontWeight: 'normal',
    color: 'delta.600',
    fontFamily: 'heading',
    fontSize: 'sm',
  },
  matchCardOverride: {
    mb: '2',
  },
  propositionButtonOverride: {
    w: '100%',
  },
  gridItem: {
    w: '100%',
  },
  flexWrapper: {
    alignItems: 'center',
  },
  flexText: {
    as: 'span',
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    fontFamily: 'heading',
    fontSize: 'md',
    color: 'beta.800',
  },
  gridWrapper: {
    gap: 2,
  },
  propositionButtonOverrideSecondary: {
    variant: 'odds',
    w: ['50px', '100px'],
    px: ['0', null, 'inherit'],
  },
  gridItemTextNoProposition: {
    color: 'beta.700',
    bg: 'transparent',
    w: ['50px', '100px'],
    h: 'full',
    minH: '10',
    textAlign: 'center',
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'base',
    border: '2px',
    borderColor: 'beta.300',
  },
  flexAwayTeam: {
    alignItems: 'center',
  },
  flexAwayTeamText: {
    as: 'span',
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    fontFamily: 'heading',
    fontSize: 'md',
    color: 'beta.800',
  },
};
