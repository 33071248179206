import { CSSObject } from '@chakra-ui/react';

export const TableWrapper: CSSObject = {
  mx: '-2',
  width: 'unset',
  pt: '0',
};

export const TransactionWrapper: CSSObject = {
  borderRadius: 'base',
  bg: 'white',
  borderStyle: 'dashed',
  borderColor: 'gray.200',
  borderBottom: '1px',
  mx: '2',
  px: '2',
  py: '4',
  w: 'unset',
};

export const DayHeading: CSSObject = {
  color: 'white',
};

export const DownloadActionLink: CSSObject = {
  color: 'beta.500',
};

export const PageHeadingWrapper: CSSObject = {
  borderColor: 'gray.200',
};
