import { Box, Icon, Text } from '@chakra-ui/react';

import React from 'react';
import { AlertOn } from '@styled-icons/fluentui-system-regular';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Button/Button';
import { useMatchDetails } from '../services/MarketsByMatch.hooks';

export default function NoMarkets() {
  const { data } = useMatchDetails();
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Icon as={AlertOn} sx={{ boxSize: '5', mr: '1', mb: '2' }} />

      <Text sx={{ mb: '4' }}>
        <FormattedMessage
          id="sports.marketsbymatch.nomarkets"
          values={{ sport: data?.sport_name }}
        />
      </Text>

      <Button
        onClick={() =>
          navigate(`/sports/${data?.sport_name}?sportId=${data?.sport_id}`)
        }
      >
        <FormattedMessage
          id="sports.marketsbymatch.nomarketsbutton"
          values={{ sport: data?.sport_name }}
        />
      </Button>
    </Box>
  );
}
