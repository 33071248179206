import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};

export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  '&&': {
    bg: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'green.500';
        case EGreenIDStatus.PENDING:
          return 'yellow.500';
        default:
          return 'red.500';
      }
    },
    borderRadius: 'base',
    boxShadow: '0 3px 3px rgba(0, 0, 0, .15)',
    borderColor: 'white',
    border: '1px',
    fontSize: '2xs',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    color: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'white';
        default:
          return 'white';
      }
    },
    h: '18px',
    mb: ['1', null, '0'],
    px: '1',
    textTransform: 'none',

    _hover: {
      bg: () => {
        switch (status) {
          case EGreenIDStatus.VERIFIED:
          case EGreenIDStatus.VERIFIED_ADMIN:
          case EGreenIDStatus.VERIFIED_WITH_CHANGES:
            return 'beta.600';
          case EGreenIDStatus.PENDING:
            return 'yellow.600';
          default:
            return 'orange.600';
        }
      },
    },

    _disabled: {
      boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
      opacity: '1',
      bg: 'green.500',
      color: 'white',
      _hover: {
        bg: 'green.700',
      },
    },

    '.chakra-button__icon': {
      ml: '1.5',
      mr: 'unset',
    },

    '.chakra-icon': {
      boxSize: '4',
    },
  },
});

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mt: '0',
  position: 'relative',
  zIndex: 'banner',
};
