import { Collapse, Text, useDisclosure } from '@chakra-ui/react';
import React, { Fragment, useMemo, useState } from 'react';
import {
  LIMIT,
  MarketLayoutHeader,
  MarketLayoutPagination,
  MarketLayoutRow,
  MarketLayoutTabPanel,
  MarketLayoutTabs,
  TMarketLayoutProps,
} from '../MarketLayout/MarketLayout';
import PropositionButton from '../PropositionButton/PropositionButton';

export type TOverUnderLayoutProps = Pick<
  TMarketLayoutProps,
  'parts' | 'onSelection'
>;

export default function OverUnderLayout({
  parts,
  onSelection,
}: TOverUnderLayoutProps) {
  const { isOpen, onToggle } = useDisclosure();

  const [currentIdx, setCurrentIdx] = useState(0);
  const shouldRenderPagination = useMemo(() => {
    const part = parts[currentIdx];
    const maxRowCount = Math.max(part.markets.length);
    return maxRowCount > LIMIT;
  }, [parts, currentIdx]);

  return (
    <>
      <MarketLayoutTabs
        labels={parts.map(({ title }) => title)}
        onChange={(i) => setCurrentIdx(i)}
      >
        {parts.map(({ title, markets: rawMarkets }) => {
          const markets = rawMarkets.sort(
            (a, b) =>
              (Number(a.market_info?.value1) ?? 0) -
              (Number(b.market_info?.value1) ?? 0)
          );

          return (
            <MarketLayoutTabPanel key={title}>
              {[...markets].slice(0, LIMIT).map((m, index) => {
                const propositions = [
                  ...(m.propositions?.length === 1
                    ? [{ proposition_info: { value2: 0 } }]
                    : []),
                  ...(m.propositions ?? []),
                ].sort((a, b) => {
                  const aValue = a.proposition_info?.value2 || Infinity;
                  const bValue = b.proposition_info?.value2 || Infinity;

                  if (aValue < 0 && bValue < 0) return aValue - bValue;
                  if (aValue < 0) return 1;
                  if (bValue < 0) return -1;

                  return aValue - bValue;
                });
                const over = propositions?.[0];
                const under = propositions?.[1];

                return (
                  <Fragment key={m.market_id}>
                    {index === 0 && (
                      <MarketLayoutRow isFirst>
                        <MarketLayoutHeader>Over</MarketLayoutHeader>
                        <MarketLayoutHeader>
                          {m.market_info?.metric}
                        </MarketLayoutHeader>
                        <MarketLayoutHeader>Under</MarketLayoutHeader>
                      </MarketLayoutRow>
                    )}

                    <MarketLayoutRow>
                      <PropositionButton
                        flex="1"
                        isActive={over?.is_selected}
                        data-active={over?.is_selected}
                        isDisabled={over?.is_disabled}
                        isSuspended={over?.is_suspended}
                        odds={over?.return_amount}
                        onClick={() => over && onSelection?.(over)}
                      />

                      <Text
                        align="center"
                        flex="1"
                        fontSize="xs"
                        fontWeight="bold"
                        px="2"
                      >
                        {m.market_info?.value1 ?? '-'}
                      </Text>

                      <PropositionButton
                        flex="1"
                        isActive={under?.is_selected}
                        isDisabled={under?.is_disabled}
                        isSuspended={under?.is_suspended}
                        odds={under?.return_amount}
                        onClick={() => under && onSelection?.(under)}
                      />
                    </MarketLayoutRow>
                  </Fragment>
                );
              })}

              {shouldRenderPagination &&
                [...markets].slice(LIMIT).map((m) => {
                  const propositions = [
                    ...(m.propositions?.length === 1
                      ? [{ proposition_info: { value2: 0 } }]
                      : []),
                    ...(m.propositions ?? []),
                  ].sort((a, b) => {
                    const aValue = a.proposition_info?.value2 || Infinity;
                    const bValue = b.proposition_info?.value2 || Infinity;

                    if (aValue < 0 && bValue < 0) return aValue - bValue;
                    if (aValue < 0) return 1;
                    if (bValue < 0) return -1;

                    return aValue - bValue;
                  });
                  const over = propositions?.[0];
                  const under = propositions?.[1];

                  return (
                    <Collapse
                      key={`${m.market_id}-hidden`}
                      in={isOpen}
                      animateOpacity
                      unmountOnExit
                    >
                      <MarketLayoutRow>
                        <PropositionButton
                          flex="1"
                          isActive={over?.is_selected}
                          data-active={over?.is_selected}
                          isDisabled={over?.is_disabled}
                          isSuspended={over?.is_suspended}
                          odds={over?.return_amount}
                          onClick={() => over && onSelection?.(over)}
                        />

                        <Text
                          align="center"
                          flex="1"
                          fontSize="xs"
                          fontWeight="bold"
                          px="2"
                        >
                          {m.market_info?.value1 ?? '-'}
                        </Text>

                        <PropositionButton
                          flex="1"
                          isActive={under?.is_selected}
                          isDisabled={under?.is_disabled}
                          isSuspended={under?.is_suspended}
                          odds={under?.return_amount}
                          onClick={() => under && onSelection?.(under)}
                        />
                      </MarketLayoutRow>
                    </Collapse>
                  );
                })}
            </MarketLayoutTabPanel>
          );
        })}
      </MarketLayoutTabs>

      <MarketLayoutPagination
        in={shouldRenderPagination}
        isOpen={isOpen}
        onPageClick={onToggle}
      />
    </>
  );
}
