import { CSSObject } from '@chakra-ui/react';
import toggle from '../../../../../assets/questbet/images/toggle/toggle.svg';

export const FlexWrapper = {
  borderRadius: 'base',
  contain: 'layout',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: ['1.5', null, null, 'unset'],
  mb: ['1', null, null, '2.5'],
  gap: '2',

  boxShadow: 'md',
  bg: 'white',
};

export const TextToggle = (isActive: boolean) => ({
  fontSize: 'sm',
  p: '2',
  flex: [1, 'unset'],

  borderRadius: 'full',
  textTransform: 'uppercase',
  fontWeight: 'semibold',
  letterSpacing: '1px',
  px: '5',
  py: '1',
  maxW: '110px',
  textAlign: 'center',
  transition: 'all, 0.2s linear',
  color: 'alpha.700',

  // This is just a placeholder
  ...(isActive && {
    color: 'alpha.700',
  }),
  ...(!isActive && {
    color: 'alpha.700',
  }),
});

export const Switcher = (): CSSObject => ({
  bg: 'transparent',

  '.chakra-switch__thumb': {
    bgImage: `url(${toggle})`,
    bgSize: 'inherit',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    boxShadow: '1px 0px 3px',
  },
  '.chakra-switch__track': {
    boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
    bg: 'beta.500',
  },

  _checked: {
    bg: 'transparent',

    '.chakra-switch__track': {
      boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
      bg: 'beta.500',
    },
  },
});
