import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useQueries } from '@tanstack/react-query';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { keys } from '@/api/api.keys';
import { querySportsPropositions } from '@/api/sportDetailsPropositions/sportDetailsPropositions';
import { useSgmPricingMutation } from '@/api/sports/pricing/sgm.hooks';
import {
  useAddSgmToBetSlip,
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import { Button } from '@/components/Button/Button';
import { isPendingAndMatchingId } from '@/helpers/utils';
import { useDetectScrollBottom } from '@/hooks/useDetectScrollBottom';
import { useMatchMarketGroups } from '@/hooks/useMatchMarketGroups';
import { useAppSelector } from '@/hooks/useRedux';
import { EGeneralStatus, TMatch } from '@/lib/DBModels';
import Loader from '../../MarketsByMatch/components/Loader';
import NoMarkets from '../../MarketsByMatch/components/NoMarkets';
import {
  TExtendedProposition,
  TMarketGroupsAccordionProps,
  TPropositions,
} from '../services/MatchDetailPage.types';
import { SGM_PATH } from '../MatchDetailPage';
import MarketGroupsAccordion from './MarketGroupsAccordion';
import MiniBetSlip from './MiniBetSlip';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

const DEFAULT_INDICES = { marketGroup: 0, market: 0 } as const;
const MIN_SELECTIONS = 2;
const MAX_SELECTIONS = 20;

export default function MatchDetailPage() {
  const match = useOutletContext<TMatch | undefined>();
  const { filter } = useParams();
  const isSgmPage = filter === SGM_PATH;
  const [params] = useSearchParams();
  const intl = useIntl();
  const { isInitialLoading, data: marketGroups } = useMatchMarketGroups(
    { match_id: match?.match_id ?? '' },
    { enabled: !!match?.match_id, refetchInterval: 5_000 }
  );
  const [expandedMarkets, setExpandedMarkets] = useState<string[]>([]);
  const queries = useQueries({
    queries: expandedMarkets.map((marketId) => ({
      queryKey: [keys.sportDetailsPropositions, marketId],
      queryFn: () => querySportsPropositions({ market_id: marketId }),
      refetchInterval: 30_000,
    })),
  });
  const { setBet } = getBetSlipStoreActions();

  // Bet slip
  const { bets } = useAppSelector(({ betSlip }) => betSlip);
  const { singlePropositionInBetSlip, removeFromBetSlip } =
    useBetSlipManageBets();
  const { addSportsBetSlip } = useSingleBetSlip();

  // Mini-bet slip
  const bodyRef = useRef(document.body);
  const isAtBottom = useDetectScrollBottom({ ref: bodyRef });
  const shouldRenderMiniBetSlip = useBreakpointValue({
    base: !isAtBottom,
    lg: true,
  });
  const { mutate, isLoading } = useSgmPricingMutation();
  const [error, setError] = useState<{ message: string } | null>(null);
  const [selections, setSelections] = useState<TExtendedProposition[]>([]);
  const [odds, setOdds] = useState<number>();
  const addSgmToBetSlip = useAddSgmToBetSlip();
  const dialog = useDisclosure();
  const cancelRef = useRef(null);

  const propositionIsSelected = useCallback(
    (propositionId: string) => {
      if (isSgmPage) {
        return (
          selections.findIndex(
            ({ proposition_id }) => proposition_id === propositionId
          ) !== -1
        );
      }
      return !!singlePropositionInBetSlip(propositionId);
    },
    [isSgmPage, selections, singlePropositionInBetSlip]
  );

  const propositionsByMarket: TPropositions[] = useMemo(
    () =>
      queries.map((query) => ({
        data: query.data?.map((proposition) => ({
          ...proposition,
          is_disabled:
            proposition.is_suspended ||
            isPendingAndMatchingId(bets, proposition.proposition_id),
          is_selected: propositionIsSelected(proposition.proposition_id ?? ''),
        })),
        isError: query.isError,
        isLoading: query.isInitialLoading,
      })),
    [bets, propositionIsSelected, queries]
  );

  // Map propositions to markets
  const data = useMemo(
    () =>
      marketGroups?.map((marketGroup) => ({
        ...marketGroup,
        markets: marketGroup.markets.map((market) => {
          if (market.propositions) {
            return market;
          }

          const index = expandedMarkets.indexOf(market.market_id ?? '');
          return {
            ...market,
            propositions: index === -1 ? null : propositionsByMarket[index],
          };
        }),
      })),
    [expandedMarkets, marketGroups, propositionsByMarket]
  );

  const defaultExpandedMarket =
    marketGroups?.[DEFAULT_INDICES.marketGroup]?.markets[DEFAULT_INDICES.market]
      .market_id;

  useEffect(() => {
    if (!defaultExpandedMarket) return;
    setExpandedMarkets([defaultExpandedMarket]);
  }, [defaultExpandedMarket]);

  const addToBetSlip = useCallback(
    (proposition: TExtendedProposition) => {
      if (proposition.status !== EGeneralStatus.Open) {
        return;
      }

      const betSlipProposition = singlePropositionInBetSlip(
        proposition.proposition_id
      );

      setBet({
        id: proposition?.proposition_id ?? '',
        type: 'Single',
        propId: proposition?.proposition_id ?? '',
        odds: proposition?.return_amount ?? 0,
        misc: {
          ...match,
          ...proposition,
          sportTitle: proposition.market_name,
          proposition,
          match,
        },
      });

      if (betSlipProposition) {
        removeFromBetSlip(betSlipProposition.request_id);
      } else {
        addSportsBetSlip(proposition.market_name ?? '', proposition, match);
      }
    },
    [
      addSportsBetSlip,
      match,
      removeFromBetSlip,
      setBet,
      singlePropositionInBetSlip,
    ]
  );

  const addToMiniBetSlip = useCallback(
    (proposition: TExtendedProposition) => {
      const isSelected =
        selections.findIndex(
          ({ proposition_id }) => proposition_id === proposition.proposition_id
        ) !== -1;

      let nextSelections: TExtendedProposition[];

      if (isSelected) {
        const filterer = ({ proposition_id }: TExtendedProposition) =>
          proposition_id !== proposition.proposition_id;
        nextSelections = selections.filter(filterer);
      } else {
        nextSelections = [...selections, proposition];
      }

      if (nextSelections.length === 0) {
        setSelections([]);
        setOdds(undefined);
        return;
      }

      if (nextSelections.length < MIN_SELECTIONS) {
        setSelections(nextSelections);
        setOdds(nextSelections[0].return_amount);
        return;
      }

      if (nextSelections.length > MAX_SELECTIONS) {
        setError({
          message: intl.formatMessage({
            id: 'sports.matchDetailPage.maxSelectionsError',
          }),
        });
        return;
      }

      const propIds = nextSelections
        .filter(
          (prop): prop is { proposition_id: string } => !!prop.proposition_id
        )
        .map((prop) => prop.proposition_id);

      mutate(
        {
          match_id: match?.match_id as string,
          proposition_ids: propIds,
        },
        {
          onSettled: (settledData, settledError) => {
            const { price } = settledData?.data ?? {};

            if (price === null || settledError) {
              setError(
                settledError || {
                  message:
                    params.get('computer') === 'no'
                      ? 'Computer says no.'
                      : intl.formatMessage({
                          id: 'sports.matchDetailPage.selectionError',
                        }),
                }
              );
              return;
            }

            setSelections(nextSelections);
            setOdds(price);
          },
        }
      );
    },
    [intl, match?.match_id, mutate, params, selections]
  );

  const handleAddSelections = () => {
    addSgmToBetSlip(selections, odds, match);

    setBet({
      id: `${match?.match_id}-multi` ?? '',
      type: 'SGMulti',
      selections,
      odds: odds ?? 0,
      misc: {
        ...match,
        match,
      },
    });
  };

  const handleClearSelections = () => {
    dialog.onClose();
    setSelections([]);
  };

  useEffect(() => {
    handleClearSelections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.match_id]);

  const handleMarketChange: TMarketGroupsAccordionProps['onMarketChange'] = (
    nextExpandedMarkets
  ) => {
    if (nextExpandedMarkets.every((id) => expandedMarkets.includes(id))) {
      return;
    }
    setExpandedMarkets(
      Array.from(new Set([...expandedMarkets, ...nextExpandedMarkets]))
    );
  };

  const handleSelection: TMarketGroupsAccordionProps['onSelection'] =
    useCallback(
      (propositionId, marketName) => {
        const proposition = propositionsByMarket
          .flatMap((propositions) => propositions.data)
          .find(({ proposition_id } = {}) => proposition_id === propositionId);

        if (!proposition) {
          return;
        }

        const handler = isSgmPage ? addToMiniBetSlip : addToBetSlip;
        handler({ ...proposition, market_name: marketName });
      },
      [addToBetSlip, addToMiniBetSlip, isSgmPage, propositionsByMarket]
    );

  if (isInitialLoading) {
    return <Loader />;
  }

  if (!data) {
    return <NoMarkets />;
  }

  return (
    <>
      <MarketGroupsAccordion
        defaultIndices={DEFAULT_INDICES}
        data={data}
        onMarketChange={handleMarketChange}
        onSelection={handleSelection}
      />

      {isSgmPage && shouldRenderMiniBetSlip && (
        <>
          <MiniBetSlip
            selections={selections}
            odds={odds}
            isLoading={isLoading}
            error={error}
            onAddSelections={handleAddSelections}
            onClearSelections={dialog.onOpen}
          />
          <AlertDialog
            isOpen={dialog.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={dialog.onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <FormattedMessage id="sports.matchDetailPage.clearSelections" />
                </AlertDialogHeader>
                <AlertDialogBody>
                  <FormattedMessage id="sports.matchDetailPage.clearSelectionsPrompt" />
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={dialog.onClose}>
                    <FormattedMessage id="generic.cancel" />
                  </Button>
                  <Button
                    colorScheme="green"
                    ml="3"
                    onClick={handleClearSelections}
                  >
                    <FormattedMessage id="generic.clear" />
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </>
  );
}
