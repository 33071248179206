import React from 'react';
import {
  Icon,
  Popover,
  PopoverTrigger,
  Show,
  useBoolean,
} from '@chakra-ui/react';
import { ChevronDown } from 'styled-icons/boxicons-solid';
import {
  ButtonOutsideArea,
  buttonQuickAccessProps,
} from '../../styles/Header.styles';
import { BoxMascotImage } from './styles/QuickAccessMenu.styles';
import { Button } from '@/components/Button/Button';
import { TextShowHideBalance } from './TextShowHideBalance';
import PopOverPortalV1 from './PopOverPortal.v1';
import { usePathStartsWith } from '@/hooks/usePathStartsWith';

export const useQuickAccessMenuParams = () => {
  const [isOpen, setIsOpen] = useBoolean(false);
  const ls = localStorage.getItem('show-balance');
  const initShowBalance = ls === null ? true : ls === 'true';
  const [showBalance, setShowBalance] = useBoolean(initShowBalance);
  const hideMascot = usePathStartsWith(
    '/account/settings/responsible-gambling'
  );

  return {
    isOpen,
    setIsOpen,
    showBalance,
    setShowBalance,
    hideMascot,
  };
};

export default function QuickAccessMenuV1() {
  const { isOpen, setIsOpen, showBalance, setShowBalance, hideMascot } =
    useQuickAccessMenuParams();

  return (
    <Popover isOpen={isOpen} onClose={setIsOpen.off} isLazy>
      <>
        {!hideMascot && <BoxMascotImage />}

        <Show below="sm">
          <ButtonOutsideArea isHeader isOpen={isOpen} onClick={setIsOpen.off} />
        </Show>
        <PopoverTrigger>
          <Button
            {...buttonQuickAccessProps}
            rightIcon={<Icon as={ChevronDown} className="chevron-icon" />}
            data-cy="btn-qa-quick-access"
            onClick={setIsOpen.toggle}
          >
            <TextShowHideBalance showBalance={showBalance} />
          </Button>
        </PopoverTrigger>

        <PopOverPortalV1
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          showBalance={showBalance}
          setShowBalance={setShowBalance}
        />
      </>
    </Popover>
  );
}
