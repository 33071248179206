import { CSSObject } from '@chakra-ui/react';

export const GreenIDText: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const GreenIDContainer: CSSObject = {
  '#greenid-container .btn-primary': {
    fontSize: 'xs',
    fontFamily: 'heading',
    bgGradient: 'linear(to-b, alpha.300, alpha.700)',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 'base',
    textColor: 'white',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
    width: 'auto',
    border: 'none',
  },
  '#greenid-source-content': {
    border: 'none !important',
  },
};
