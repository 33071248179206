import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  bg: 'beta.50', // TODO: Check with the designers
  borderRadius: 'md',
};
