import React, { FC, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { logError } from './helpers/utils';

import { GREENID_UI_FORM_URL, GREENID_CONFIG_URL } from './lib/Constants';
import injectScript from './lib/injectScript';

import AppRoutes from './routes/AppRoutes';

import Providers from './components/Providers';
import IdleTimer from './components/IdleTimer/IdleTimer';
import store from './redux/store';
import ErrorBoundaryTemplate from './components/ErrorBoundary/ErrorBoundary';
import { IS_MOBILE_APP } from './constants/isMobileApp';
import { postMobileAppMessage } from './mobileapp/mobileapp.utils';
import { loadUser, onFirebaseUserChange } from './lib/auth';
import Analytics from './components/Analytics/Analytics';
import DepositLimitModal from './components/DepositLimitModal/DepositLimitModal';
import { updateAndPollFeatureFlags } from './lib/featureFlags';
import { Notifications } from './components/Notifications/Notifications';
import { MoM } from './components/MoM/MoM';
import { useErrorHandler } from './App.hooks';
import { HTTPError } from './lib/api/types';

const { FEATURE_FLAG_URL } = window.BETCLOUD_ENV;

(async () => {
  // Inject GreenID verification form scripts
  if (GREENID_CONFIG_URL) await injectScript(GREENID_CONFIG_URL);
  if (GREENID_UI_FORM_URL) await injectScript(GREENID_UI_FORM_URL);
})().catch(logError);

const appErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here

  console.error('Error handler', { error, info });

  if (IS_MOBILE_APP) {
    // Bubble the error into the mobile app so its logged
    postMobileAppMessage('error', {
      url: window.location.pathname,
      error: JSON.stringify(error),
      info,
    });
  }
};

const App: FC = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateAndPollFeatureFlags(FEATURE_FLAG_URL);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-void
    void loadUser();
    const unsubscribe = onFirebaseUserChange();

    return unsubscribe;
  }, []);

  // 👀 This is used by the error boundary. On error - the user clicks 'try again' and this triggers the browser router to reload
  // https://github.com/bvaughn/react-error-boundary#error-recovery
  const [errorKey, setErrorKey] = React.useState(false);

  const { errorHandler } = useErrorHandler();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (err) => errorHandler({ error: err as HTTPError }),
    }),
    mutationCache: new MutationCache({
      onError: (err) => errorHandler({ error: err as HTTPError }),
    }),
  });

  return (
    <Providers store={store} client={queryClient}>
      <Toaster />
      {!IS_MOBILE_APP && <DepositLimitModal />}

      <BrowserRouter>
        <Analytics />
        <IdleTimer />
        <Notifications />
        <MoM />
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryTemplate}
          onError={appErrorHandler}
          onReset={() => setErrorKey(false)}
          resetKeys={[errorKey]}
        >
          <AppRoutes />
        </ErrorBoundary>
      </BrowserRouter>
    </Providers>
  );
};

export default App;
