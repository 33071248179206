import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};

export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  border: '1px',
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: '700',
  fontFamily: 'roboto',
  h: '18px',
  px: '1',
  mb: ['1', null, '0'],
  textTransform: 'none',

  _disabled: {
    fontFamily: 'roboto',
    bg: 'green.500',
    cursor: 'default',
    opacity: '1',
    color: 'white',
    boxShadow: 'none',
    px: '1',
    py: '2',
    mt: '1.5',
    ml: '1',
    border: '1px',
    borderColor: 'white',

    _hover: {
      bg: 'beta.500',
      border: '1px',
      borderColor: 'white',
    },
  },

  '.chakra-button__icon': {
    ml: '1',
  },

  '.chakra-icon': {
    boxSize: '4',
  },

  _after: {
    bgImage: 'none',
    boxShadow: 'none',
    _hover: {
      bg: () => {
        switch (status) {
          case EGreenIDStatus.VERIFIED:
          case EGreenIDStatus.VERIFIED_ADMIN:
          case EGreenIDStatus.VERIFIED_WITH_CHANGES:
            return 'red';
          case EGreenIDStatus.PENDING:
            return 'yellow.600';
          default:
            return 'red.600';
        }
      },
    },
  },
});
