import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Skeleton } from '@chakra-ui/react';
import { useQueryPunterAccountOverview } from '../../../../../../api/punter/punter.hooks';
import {
  FlexBalances,
  ButtonShowHideBalances,
  TextShowHideBalancesBtn,
  IconShowHideBalancesBtn,
  FlexCashBalance,
  TextCashBalanceDollars,
  TextCurrencySymbol,
  TextCashBalanceCents,
  FlexBonusBalance,
  TextBonusBalanceDesc,
  TextBonusBalanceAmount,
} from './Styles/Balances.styles';
import { centsToDollarsNumber } from '@/helpers/utils';

type TBalances = {
  showBalance: boolean;
  setShowBalance: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
};

export default function Balances({ showBalance, setShowBalance }: TBalances) {
  const { data, isLoading } = useQueryPunterAccountOverview();
  const balance = centsToDollarsNumber(data?.account_balance);

  const intl = useIntl();
  const fractionLength = 2;
  const formattedBalance = intl.formatNumber(balance, {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: fractionLength,
    maximumFractionDigits: fractionLength,
  });

  const theDotIndex = formattedBalance.length - fractionLength;
  const currencySymbol = formattedBalance.substring(0, 1);
  const dollarsPart = formattedBalance.substring(1, theDotIndex);
  const centsPart = formattedBalance.substring(theDotIndex);

  const bonusBalance = centsToDollarsNumber(data?.bonus);
  const formattedBonusBalance = intl.formatNumber(bonusBalance, {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <FlexBalances>
      <ButtonShowHideBalances
        data-cy={showBalance ? 'btn-qa-hide-balance' : 'btn-qa-show-balance'}
        onClick={() => {
          setShowBalance.toggle();
          localStorage.setItem('show-balance', String(!showBalance));
        }}
        variant="link"
      >
        <TextShowHideBalancesBtn>
          <FormattedMessage id="qam.balances.btn" />
        </TextShowHideBalancesBtn>
        <IconShowHideBalancesBtn name={showBalance ? 'eyeSlash' : 'eye'} />
      </ButtonShowHideBalances>

      <Skeleton isLoaded={!isLoading} alignItems="center">
        <FlexCashBalance>
          <TextCurrencySymbol>{currencySymbol}</TextCurrencySymbol>

          <TextCashBalanceDollars>{dollarsPart}</TextCashBalanceDollars>

          <TextCashBalanceCents>{centsPart}</TextCashBalanceCents>
        </FlexCashBalance>

        {bonusBalance > 0 && (
          <Flex align="center" justify="center" direction="row">
            <FlexBonusBalance>
              <TextBonusBalanceDesc>
                <FormattedMessage id="qam.balances.bonus" />
              </TextBonusBalanceDesc>

              <TextBonusBalanceAmount>
                {formattedBonusBalance}
              </TextBonusBalanceAmount>
            </FlexBonusBalance>
          </Flex>
        )}
      </Skeleton>
    </FlexBalances>
  );
}
