import { CSSObject, FlexProps } from '@chakra-ui/react';
import { TTableText } from '@/views/account/Transactions/components/TransactionTable/types';

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'delta.600' : 'beta.800',
  fontSize: 'xs',
});

export const transactionInfoFlexProps: FlexProps = {
  color: 'beta.800',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
  p: '1',
  borderBottom: '1.5px dashed',
  borderBottomColor: 'whiteAlpha.500',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1.5px',
    borderColor: 'blackAlpha.50',
    borderStyle: 'dashed',
  },
};

export const PageHeadingWrapper: CSSObject = {
  borderBottom: 'none',
};
