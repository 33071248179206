import { Image, Box, Flex, Link, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { Link as LinkDom } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CardSchema, cardStyles } from './styles/Card.styles';
import IconSvg from '@/components/common/IconSvg';
import { TNextToJump } from '@/api/nextToJump/nextToJump.types';
import {
  getIconBySportName,
  isPendingAndMatchingId,
  propIsSuspended,
} from '@/helpers/utils';
import Countdown from '@/components/Countdown/Countdown';
import RaceNumber from '../RaceNumber/RaceNumber';
import { TRunnerListResponse } from '@/api/racing/racing.types';
import { Button } from '@/components/Button/Button';
import { EGeneralStatus, TPriceType, TRunner } from '@/lib/DBModels';
import {
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import { getEventRule } from '@/components/Betslip/Services/Betslip.utils';
import {
  EBetSlipBetSubmissionType,
  TBetSlipBet,
} from '@/components/Betslip/Services/Betslip.types';
import { srcDefaultSilk } from '@/assets/core';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

// ----
// Card List Item

type CardListItemProps = {
  race: TNextToJump;
  runner: TRunnerListResponse[0];
  bets: TBetSlipBet[];
  spBetsAreSupported: boolean;
  styles?: CardSchema;
};

export function CardListItem({
  race,
  runner,
  bets,
  spBetsAreSupported,
  styles,
}: CardListItemProps) {
  const { setBet } = getBetSlipStoreActions();
  const { addRaceRunnerBetSlip } = useSingleBetSlip();
  const { singlePropositionInBetSlip, removeSinglePropositionFromBetSlip } =
    useBetSlipManageBets();
  const isScratched =
    runner?.is_scratched || runner?.status === EGeneralStatus.Scratched;
  const isSuspended = propIsSuspended(runner.win_proposition, runner.win_odds);
  const isInBetslip = !!singlePropositionInBetSlip(
    runner.win_proposition?.proposition_id
  );
  const eventRule = getEventRule(race.number_of_places);
  const priceTypes = runner.win_proposition?.price_types ?? [];
  const isSPProps =
    (priceTypes.includes('starting') && priceTypes.length === 1) ||
    (priceTypes.includes('starting') &&
      priceTypes.includes('tote_single_mid') &&
      priceTypes.length === 2);
  const priceType: TPriceType = isSPProps ? 'starting' : 'fixed';

  return (
    <>
      <Flex data-isScratched={isScratched} {...cardStyles.listItem}>
        <Image
          src={runner.silk_url}
          fallbackSrc={srcDefaultSilk}
          {...cardStyles.silk}
        />
        <Text {...cardStyles.runner}>
          <Text as="span">{runner.number}.</Text>{' '}
          {runner.display_name?.toLocaleLowerCase()}
          {!!runner.barrier_number && (
            <Text as="span">{` (${runner.barrier_number})`}</Text>
          )}
        </Text>
      </Flex>

      <Flex {...cardStyles.listItem}>
        {isScratched ? (
          <Text {...cardStyles.scratched}>
            <FormattedMessage id="generic.scratched" />
          </Text>
        ) : (
          <Button
            data-cy={`odds-button-${runner.display_name}`}
            isActive={isInBetslip}
            data-active={isInBetslip}
            isDisabled={
              isSuspended ||
              isPendingAndMatchingId(
                bets,
                runner.win_proposition?.proposition_id
              ) ||
              (priceType === 'starting' && !spBetsAreSupported)
            }
            onClick={() => {
              setBet({
                id: runner.win_proposition?.proposition_id ?? '',
                type: 'Single',
                propId: runner.win_proposition?.proposition_id ?? '',
                odds: runner.win_odds ?? 0,
                priceType,
                betType: 'win',
                misc: {
                  ...runner,
                  ...race,
                  runner: runner as TRunner,
                  race,
                },
              });

              if (isInBetslip) {
                removeSinglePropositionFromBetSlip(
                  EBetSlipBetSubmissionType.Single,
                  runner.win_proposition?.proposition_id ?? ''
                );
              } else {
                addRaceRunnerBetSlip(
                  'win',
                  runner,
                  { venue_display_name: race.venue_name },
                  race,
                  eventRule,
                  priceType
                );
              }
            }}
            {...cardStyles.btn}
            {...styles?.btn}
          >
            {(() => {
              if (isSuspended) {
                return <FormattedMessage id="generic.sus" />;
              }

              if (priceType === 'starting') {
                return <FormattedMessage id="generic.startingPriceAcronym" />;
              }

              return runner.win_odds?.toFixed(2);
            })()}
          </Button>
        )}
      </Flex>
    </>
  );
}

// ----
// Header component

type CardHeaderProps = {
  race: TNextToJump;
};

function CardHeader({ race }: CardHeaderProps) {
  const to = `/racing/${race.race_type}/${race.venue_name}/R${race.race_number}?venueId=${race.venue_id}&raceId=${race.race_id}&meetingDate=${race.meeting_date}`;

  return (
    <Link as={LinkDom} to={to} {...cardStyles.linkHeader}>
      <IconSvg
        data-cy="raceCardIcon"
        name={getIconBySportName(race.race_type)}
        {...(cardStyles.headerIcon as any)}
      />
      <Text data-cy="raceCardHeading" {...cardStyles.headerRaceName}>
        {race.venue_name}
      </Text>

      <Flex {...cardStyles.raceIndicators}>
        <Countdown eventTime={race.start_time ?? ''} dateFormat="HH:mm" />
        <RaceNumber
          data-cy="raceCardRaceNumber"
          raceNumber={race.race_number ?? 0}
        />
      </Flex>
    </Link>
  );
}

// ----
// Combined component

type CardProps = {
  children: ReactNode;
  race: TNextToJump;
};

export default function Card({ children, race }: CardProps) {
  return (
    <Box {...cardStyles.wrapper}>
      <CardHeader race={race} />

      <Box {...cardStyles.wrapperContent}>{children}</Box>
    </Box>
  );
}
