import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
};
export const Subheading: CSSObject = {
  color: 'white',
  span: {
    color: 'alpha.400',
  },
};
export const BackButton: CSSObject = {
  h: '6',
  minW: '6',
};
export const DividerWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'odds',
  fontWeight: 'normal',
};
export const ButtonWithdrawal: CSSObject = {
  variant: 'secondary',
  fontWeight: 'normal',
};
export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const AmountInputWrapperWithdrawal: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bg: 'alpha.200',
    border: 'none',
    '#amount-input': {
      bg: 'beta.900',
      border: 'none',
      my: '2px',
      mr: '2px',
      fontSize: 'sm',
      fontWeight: 'bold',
      color: 'white',
      borderLeft: 'none',
      borderLeftRadius: 'none',
      _placeholder: {
        fontWeight: 'bold',
      },
      _hover: {
        bg: 'beta.600',
        border: 'none',
      },
      '&&:focus, &&:active': {
        bg: 'beta.600',
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'alpha.300',
        borderLeft: 'none',
        borderLeftRadius: 'none',
        m: '0',
        h: '42px',
      },
    },
  },
  '.chakra-input__left-addon': {
    bg: 'alpha.200',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    border: '3px solid',
    borderColor: 'white',
    _hover: {
      bg: 'blue.600',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};
export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};
export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};
/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '0',
  p: '0',
  w: '100%',
};
export const ListContainerHeading: CSSObject = {
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'lg',
  mt: '3',
  '&&': {
    fontWeight: 'normal',
  },
};
/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};
export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};
export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'alpha.400',
  color: status === EWithdrawalStatus.Pending && 'alpha.900',
  textTransform: 'uppercase',
  fontSize: '2xs',
});
export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};
export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '2',
  variant: 'odds',
  fontWeight: 'normal',
};
export const ButtonWithdrawalCardCancel: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
  borderRadius: 'md',
  color: 'alpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    color: 'alpha.400',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'gray.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    boxShadow:
      '0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    pointerEvents: 'none',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};
export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};
export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  p: '2',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
};
export const WithdrawalCardContent: CSSObject = {
  color: 'white',
};
export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
};
export const WithdrawalCardDetailRequested: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'blackAlpha.500',
};
export const InputWithdrawal: CSSObject = {
  '&&': {
    bg: 'beta.800',
    border: 'none',
    fontSize: 'sm',
    fontWeight: 'bold',
    borderLeftRadius: 'md',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    boxShadow: 'none',
    _hover: {
      bg: 'beta.500',
      border: 'none',
    },
    '&&:focus, &&:active': {
      bg: 'beta.500',
      borderLeft: '2px',
      borderLeftColor: 'alpha.400',
      boxShadow: 'none',
      m: '0',
      h: '42px',
    },
  },
};
