import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  borderTop: '1px dashed var(--bc-white-alpha-200, rgba(255, 255, 255, 0.08))',
  borderBottom: '1px dashed var(--bc-black-alpha-200, rgba(0, 0, 0, 0.40))',
  h: ['63px', null, '47px'],
  px: '12px',
  py: 0,

  gridTemplateRows: 'auto',
  gridTemplateAreas: '"icon details time chevron"',

  _last: { borderBottom: 'transparent' },

  '.details': {
    width: '100%',
    overflow: 'hidden',
    pr: '8px',
  },
};
export const TextListName: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  mb: ['0', null, '-1px'],
  whiteSpace: 'normal',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'white' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'whiteAlpha.600',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'white',
  boxSize: '30px',
  mr: '6px',
  boxShadow: 'none',
  bg: 'none',
  p: 'none',
};

export const IconChevron: CSSObject = {
  color: 'white',
  ml: '0',
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '0.5',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: '12px',
    bg: 'transparent',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'blackAlpha.400',
    boxSize: '20px',
  },
};
