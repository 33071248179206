import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bg: 'beta.300',
    borderRadius: 'lg',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
    border: '2px solid',
    borderColor: 'gamma.500',
    sx: {
      '.chakra-modal__close-btn': {
        color: 'white',
      },
    },
  },
  stackWrapper: {
    bg: 'alpha.700',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  },
  heading: { color: 'white' },
  moneyValues: { color: 'gamma.300' },
  cashedOutText: {},
  cashedOutQuestion: { color: 'white' },
  declineButton: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  acceptButton: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  doneButton: {},
};
