import { ButtonProps, CSSObject } from '@chakra-ui/react';

export const buttonActionProps: ButtonProps = {
  height: '38px',
};

export const TextTakeAction: CSSObject = {
  color: 'beta.700',
  fontSize: 'xs',
};

export const TakeActionHeading: CSSObject = {
  color: 'beta.700',
  fontSize: 'sm',
};

export const TakeActionWrapper: CSSObject = {
  py: '2',
};

export const BoxTakeActionTable: CSSObject = {
  bg: 'gamma.300',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  border: '0',
  p: '2',
};
