import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  mx: '0',
  borderTop: 'none',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  px: '2',
  py: '2',
  borderRadius: 'md',
  gap: '1',
  bg: 'var(--gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
};

export const RacerName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'gray.500',
};
