import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  fontFamily: 'SkranjiBold',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
};
