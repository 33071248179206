import { CSSObject } from '@chakra-ui/react';

export const FlexWrapper: CSSObject = {
  bgGradient: 'linear(180deg, #8D4522, #76391C)',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  mb: '4',
  borderBottom: '2px solid var(--bc-colors-beta-500)',
};

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  pt: '2.5',
  pb: '2',
  m: 'auto',
  h: '48px',
  w: 'container.xl',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: '32px',
  pt: '1px',
};

export const LinkItem: CSSObject = {
  w: '152px',
  h: '28px',
  py: '2px',
  borderRadius: 'md',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow: '0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset',
  border: '3px solid',
  borderColor: '#3AABCD',
  color: 'blackAlpha.700',
  textOverflow: 'ellipsis',

  _hover: {
    bg: 'alpha.300',
    color: 'blackAlpha.700',
    boxShadow: '0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset',
    border: '3px solid',
    borderColor: '#995737',
  },

  '.chakra-badge': {
    mr: '1',
    mt: '3px',
  },
};

export const RaceNumber: CSSObject = {
  color: 'rgba(0, 0, 0, 0.64)',
};
export const FlexDetails: CSSObject = {
  color: 'rgba(0, 0, 0, 0.64)',
  display: 'block',
  maxW: '63px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'rgba(0, 0, 0, 0.64)',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
  mt: '1px',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  w: '28px',
  h: '28px',
  color: 'rgba(0, 0, 0, 0.64)',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow:
    'inset 0px 0px 0px 1px var(--bc-colors-delta-300), 0px 2px 3px 0px rgba(0, 0, 0, 0.25), 0px -1px 0px 0px #55D7FF',
  borderColor: '#3AABCD',
  borderStyle: 'solid',
  borderWidth: '1px 2px 2px 2px',

  _hover: {
    bg: 'alpha.300',
    color: 'blackAlpha.700',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
    borderWidth: '1px 2px 2px 2px',
    borderColor: '#995737',
  },

  '& > svg': { boxSize: '6' },
};
