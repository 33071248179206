/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { keys } from '../../../../api/api.keys';
import { queryPunterAccountMyBets } from '../../../../api/punter/punter';
import { EBetState } from '../../../../api/punter/punter.types';
import { useAuth } from '../../../../hooks/useAuth';

export type MyBetsFilter = {
  display: string;
  value: EBetState[];
};

/**
 * Hook that gets the my bets api.
 * Includes filtering by state (pending|settled), and pagination.
 *
 * Future:
 * Add a useEffect on the page to add the value to the url.
 * When a user presses back after viewing a bet they wont lose their page.
 */
export const useMyBets = () => {
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<MyBetsFilter>({
    display: 'Pending',
    value: [EBetState.Pending],
  });
  const [cashoutOnly, setCashoutOnly] = useState<boolean | null>(null);

  const { isAuthenticated } = useAuth();
  const limit = 20;

  const {
    data,
    isInitialLoading,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
    [keys.punterAccountMyBets, isAuthenticated, filter, page, !!cashoutOnly],
    ({ pageParam = 0 }) =>
      queryPunterAccountMyBets({
        states: filter.value,
        ...(filter.value.includes(EBetState.Pending) && !!cashoutOnly
          ? { cashout_only: cashoutOnly }
          : {}),
        offset: pageParam === 0 ? 0 : pageParam * limit,
        limit,
      }),
    {
      enabled: !!isAuthenticated,
      getNextPageParam: (_lastPage, pages) => pages.length ?? undefined,
      refetchOnMount: false,
    }
  );

  // 👇 Check if the last pages returns records
  const hasMoreData = data?.pages?.map((el) => el.length ?? 0 - 1).pop();

  return {
    data,
    isLoading: isInitialLoading,
    error,
    filter,
    setFilter,
    cashoutOnly,
    setCashoutOnly,
    page,
    setPage,
    isFetching,
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
    hasMoreData,
  };
};
