import React from 'react';
import { getStrings } from '@/helpers/utils';
import Form from './Components/Form';
import FormikHOC from './Components/FormikHOC';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { FormWrapper } from '../PromotionsNotifications/styles/PromotionsNotifications.styles';

const LifetimeExclusionForm: React.FC = () => {
  const [
    {
      Account: {
        LifetimeExclusion: { pageTitle },
      },
    },
  ] = getStrings();

  return (
    <AccountWrapper pageTitle={pageTitle} pageHeader={pageTitle}>
      <FormikHOC>
        <FormWrapper>
          <Form />
        </FormWrapper>
      </FormikHOC>
    </AccountWrapper>
  );
};

export default LifetimeExclusionForm;
