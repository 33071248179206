import { BoxProps, CSSObject, LinkProps } from '@chakra-ui/react';

export const ForgotPasswordButton: CSSObject = {
  bgGradient: 'linear(180deg, #FACD33, #EC9E0F)',
  border: '2px solid #A1741F',
  color: 'beta.800',
  boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
  borderRadius: 'md',
  fontFamily: 'SkranjiBold',
  fontWeight: '700',
  letterSpacing: 'wide',
  fontSize: '14px',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  textTransform: 'uppercase',
  minH: '38px',
  _hover: {
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: 'beta.800',
  },
};

export const linkLoginProps: LinkProps = {
  color: 'alpha.400',
  fontSize: '12px',
  fontWeight: 500,
  mb: '4',
  display: 'inline-flex',
  alignItems: 'center',

  sx: {
    svg: {
      boxSize: '10px',
      mr: '1',
      mt: '-1px',
    },
  },
};

export const wrapperProps: BoxProps = {
  sx: {
    'div:first-child': {
      display: 'block',
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: '#718096',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '500',
  mb: '2',

  '+ a': {
    color: '#718096',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    mx: 0,
    display: 'inline',
  },
};
