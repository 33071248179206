import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  borderRadius: 'base',
  overflow: 'hidden',
  bg: 'gray.200',

  '.chakra-accordion__item': {
    _first: {
      borderTop: '0',
    },
    _last: {
      borderBottom: '0',
    },
  },

  '.chakra-accordion__panel': {
    color: 'alpha.700',
    bg: 'zeta.200',
  },
};

export const AccordionHeader: CSSObject = {
  bg: 'zeta.400',
  color: 'alpha.700',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  _hover: {
    bg: 'zeta.300',
    color: 'alpha.600',
  },
  _focus: {
    bg: 'zeta.300',
    color: 'alpha.600',
  },
};

export const OverviewPromotionsPanel: CSSObject = {
  bgColor: 'zeta.200',
};

export const AccordionHeaderLogOut: CSSObject = {
  borderRadius: 'base',
  color: 'alpha.700',
  border: 'none',
  _hover: {
    bg: 'zeta.300',
    color: 'alpha.600',
  },
  _focus: {
    bg: 'zeta.300',
    color: 'alpha.600',
  },
};

export const IconPerson: CSSObject = {
  boxSize: ['5', null, '6'],
  mr: '1.5',
  color: 'alpha.700',
  '> path': {
    fill: 'alpha.700',
  },
};

export const TextAccordion: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  fontWeight: 'semibold',
  mr: 'auto',
  color: 'alpha.700',
};

export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  bg: 'alpha.700',
  borderRadius: 'base',
  boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.10)',
  color: 'white',
  fontSize: 'xs',
  gap: '1',
  p: '2',
};

export const OverviewAccordionItem: CSSObject = {
  borderTop: 'none',
};
