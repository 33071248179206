import { CSSObject, FlexProps } from '@chakra-ui/react';
import { TTableText } from '@/views/account/Transactions/components/TransactionTable/types';

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'alpha.400' : 'white',
  fontWeight: 'semibold',
});

export const transactionInfoFlexProps: FlexProps = {
  color: 'white',
  fontWeight: 'semibold',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.200',
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.400',
};

export const PageHeadingWrapper: CSSObject = {
  borderBottom: 'none',
};
