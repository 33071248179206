import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxNavigationWrapperProps: {
    bg: ['transparent', null, null, 'rgb(54, 54, 54)'],
  },
  boxWrapperOuterProps: {
    h: '100%',
    pos: 'relative',
    fontFamily: 'accent',
    textTransform: 'uppercase',
  },
  boxWrapperProps: {
    width: '100%',
    pos: 'relative',
  },
  navigationBoxProps: {
    pos: 'relative',
    px: ['3', null, null, '0'],
  },
};
