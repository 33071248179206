import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/gigabet/images/header/logo.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          alt={THEME_NAME.gigabet}
          mx="auto"
          pb={1}
          maxW={270}
        />
      </Show>
      <Hide above="lg">
        <Image src={logo} alt={THEME_NAME.gigabet} h="10" pt={2} pl={2} />
      </Hide>
    </Link>
  );
}
