import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import {
  FlexWrapper,
  BoxHeadingWrapper,
  FlexFeatureCardWrapper,
  FlexIllustrationWrapper,
} from '../styles/Featured.styles';
import baseTemplate from './template.default';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  illustration: <FlexIllustrationWrapper />,
  featureCard: {
    wrapper: <FlexFeatureCardWrapper />,
    heading: <BoxHeadingWrapper />,
    feature: <FeaturedMatch />,
  },

  ...(baseTemplate?.first as TTemplate),
};

export default template;
