import React, { ReactNode } from 'react';
import { Global } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import { globalStyles } from '../../theme/base/styles';
import { useThemeToggle } from '../../hooks/useThemeToggle';

type TStyleSystem = {
  children: ReactNode;
};

export default function StyleSystem({ children }: TStyleSystem) {
  const theme = useThemeToggle();

  return (
    <ChakraProvider theme={theme}>
      {theme?.fontFace && <Global styles={theme.fontFace} />}
      <Global styles={globalStyles} />
      {children}
    </ChakraProvider>
  );
}
