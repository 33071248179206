import { rgba } from 'polished';
import { css } from 'styled-components';

// TODO: Remove
export const styledButton = css`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  padding: 0;
  width: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// TODO: Remove
export const buttonIcon = css`
  color: ${({ theme }) => theme.basePalette.white};
  height: 30px;
  width: 30px;

  path {
    fill: ${({ theme }) => theme.basePalette.white};
  }
`;

// TODO: Remove
export const buttonIconActive = css`
  color: ${({ theme }) => theme.basePalette.brightBlueB};
  height: 30px;
  width: 30px;
`;

// TODO: Remove
export const buttonName = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-xs);
  text-align: center;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// TODO: Remove
export const iconWrapper = css`
  padding: 14px;
  box-shadow: 0px 0px 6px 0px
    ${({ theme }) => rgba(theme.basePalette.blue, 0.4)};
  border-radius: 100%;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.basePalette.blackGradient};
  width: 58px;

  :hover,
  :focus {
    background: var(--bc-colors-blackAlpha-500);
  }
`;
