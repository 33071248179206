import { CSSObject, ButtonProps } from '@chakra-ui/react';

export const TableItem: CSSObject = {
  borderColor: 'whiteAlpha.300',
  borderRight: 'none',
  borderStyle: 'dashed',
  color: 'white',
  pl: 0,
  py: 2,
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const TableText = ({ textVariation }: any): CSSObject => ({
  color: textVariation === 'positive' ? 'green.500' : 'white',
});

export const ButtonMoreTable: CSSObject = {
  variant: 'primary',
  fontFamily: 'roboto',
  fontSize: '2xs',
  fontWeight: 900,
  height: 6,
  p: '0 10px',
  boxShadow:
    '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #FFF inset;',
  bgGradient: 'linear-gradient(alpha.700, alpha.700)',
  border: 'none',

  _after: {
    borderRadius: '5px',
  },

  _hover: {
    boxShadow: 'none',
    bgGradient: 'linear-gradient(alpha.400, alpha.400)',
  },
};

export const NoTransactionsText: ButtonProps = {
  color: 'white',
  fontSize: 'sm',
};
