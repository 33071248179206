import { CSSObject } from '@chakra-ui/react';
import loader from '../../../../assets/puntcity/images/loader.png';

export const FlexWrapper = (): CSSObject => ({
  bg: 'rgba(18, 55, 81, .7)', // Bespoke
});

export const SpinnerLoader: CSSObject = {
  bgImage: `url(${loader})`,
  boxSize: '28',
};
