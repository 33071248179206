import { CSSObject } from '@chakra-ui/react';

export const GridLayout: CSSObject = {
  rowGap: '0',
};

export const GridSubLayout: CSSObject = {
  gridTemplateColumns: ['1fr', null, null, '270px 1fr', '270px 1fr 290px'],
  gridTemplateRows: [
    'auto auto 1fr',
    null,
    null,
    'auto auto 1fr auto',
    'auto 1fr auto',
  ],
  columnGap: ['2.5', null, null, '4'],
  gridTemplateAreas: [
    `
        "betslip"
        "sidenav"
        "content"
        "footer"
      `,
    null,
    null,
    `
        "sidenav betslip"
        "sidenav content"
        "sidenav content"
        "sidenav footer"
      `,

    `
        "sidenav content betslip"
        "sidenav content betslip"
        "sidenav footer betslip"
      `,
  ],

  'body[data-sensitive="true"] &': {
    gridTemplateColumns: ['1fr', null, null, '270px 1fr'],
    gridTemplateAreas: [
      `
        "betslip"
        "sidenav"
        "content"
        "footer"
      `,
      null,
      null,

      `
        "sidenav betslip"
        "sidenav content"
        "sidenav content"
        "sidenav footer"
      `,
    ],
  },
  maxW: 'container.lg',
};

export const GridItemContent: CSSObject = {
  minH: [null, '1000px'],
};

export const GridItemBetslip: CSSObject = {
  height: [null, null, null, null, 'calc(100vh - 120px)'],

  'body[data-sensitive="true"] &': {
    height: 'unset',
  },
};

export const GridItemToggle: CSSObject = {
  width: [null, null, '270px'],
};

export const GridItemSideNav: CSSObject = {
  h: [null, null, null, null, 'calc(100vh - 120px)'],
};
