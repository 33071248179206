import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  buttonsHStack: {
    gap: ['0', '2'],
  },
  wrapper: {
    bottom: ['10px', null, '0'],
    left: '0',
    position: ['fixed', null, null, 'sticky'],
    right: '0',
    zIndex: 'sticky',
    mx: ['0', null, '-2'],
    mb: '1',
    bg: 'alpha.600',
    borderBottomRadius: ['unset', null, 'lg'],
  },
  slideBox: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    color: 'white',
    mb: '-4',
    borderBottomRadius: ['unset', null, 'lg'],
  },
  accordionItem: {
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
  },
  accordionHeaderText: {
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    bg: 'blackAlpha.300',
    fontSize: 'xs',
    fontWeight: 'bold',
    gap: '1',
    p: '2',
  },
  accordionButtonIcon: {
    fontSize: 'sm',
  },
  headerStack: {
    bg: 'alpha.900',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    py: '2',
  },
  headerText: {
    flex: '1',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
  },
  accordionPanel: {
    fontSize: 'xs',
    maxH: '32',
    overflow: 'auto',
    p: '0',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  },
  selectionFlex: {
    borderColor: 'blackAlpha.300',
    gap: '2',
    p: '2',
    pos: 'relative',
    _notFirst: {
      borderTopWidth: '1px',
      borderTopColor: 'rgba(0, 0, 0, 0.10)',
    },
    _before: {
      border: 'inherit',
      borderColor: 'whiteAlpha.300',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
  },
  footerStack: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    borderColor: 'whiteAlpha.300',
    borderBottomRadius: ['unset', null, 'lg'],
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    p: '2',
    px: ['2', null, '3'],
    pb: '5',
    pos: 'relative',
    _before: {
      bgGradient: 'linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, .2))',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '17px',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
    flexDirection: 'row',
    columnGap: ['unset', null, '3'],
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  },
  legsText: {
    display: 'flex',
    flexDirection: 'column',
    flex: ['1', null, 'unset'],
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  legsChunks: {
    color: 'delta.500',
    fontSize: 'md',
    fontWeight: 'bold',
  },
  oddsText: {
    display: 'flex',
    flexDirection: 'column',
    flex: ['1', null, 'unset'],
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'delta.500',
    fontSize: 'md',
    fontWeight: 'bold',
  },
  clearButton: {
    variant: 'outline',
    textShadow: '0px 1px 1px rgba(247, 215, 31, 0.70)',
    fontFamily: 'CinzelDecorative',
    fontSize: ['sm', null, 'md'],
    px: '3',
    borderRadius: 'lg',
    h: ['9', null, '10'],
    sx: {
      '&': {
        _hover: {
          color: 'white',
          bg: 'alpha.700',
          borderColor: 'alpha.500',
        },
      },
    },
  },
  addToSlipButton: {
    px: '3',
    textTransform: 'uppercase',
    textShadow: '0px 1px 1px rgba(247, 215, 31, 0.70)',
    fontFamily: 'CinzelDecorative',
    fontSize: ['sm', null, 'md'],
    borderRadius: 'lg',
    border: '2px solid',
    borderColor: 'delta.700',
    bgGradient: 'linear(to-b, delta.400, delta.600)',
    boxShadow: 'inset 0px 0px 0px 2px var(--bc-colors-delta-300)',
    height: ['9', null, '10'],
    color: 'delta.800',
    sx: {
      '&:hover, &:active': {
        color: 'white',
      },
    },
  },
};
