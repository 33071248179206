import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'gamma.400',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'lg',
  boxSize: '28px',
  p: 0,

  '& svg': {
    color: 'beta.400',
    boxSize: '28px',
  },

  '+ div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'beta.400',
  p: '5px',
};

export const MatchCardContainer: CSSObject = {
  bg: 'white',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '42px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  w: '100%',
  p: '1.5',

  _hover: {
    bg: 'gray.200',
  },
};

export const MatchName: CSSObject = {
  noOfLines: 'unset',
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'gray.700',
};

export const TeamItemOdds: CSSObject = {
  color: 'yellow.900',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const VersusText: CSSObject = {
  display: 'block',
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'yellow.900',
};

export const Versus: CSSObject = {
  display: 'flex',
  position: 'absolute',
  borderRadius: 'full',
  boxSize: '12',
  bg: 'yellow.600',
  border: '3px solid',
  borderColor: 'yellow.400',
  boxShadow: '0px 1px 20px 0px #00000080',
  color: 'yellow.900',
  left: '50%',
  transform: 'translate(-50%, 0)',
  top: '84px',

  alignItems: 'center',
  justifyContent: 'center',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const TeamItem: CSSObject = {
  border: 'none',
  bg: 'gamma.900',
  '&&': { textColor: 'yellow.900', h: '8' },
  _hover: {
    boxShadow: '0 1px 2px 1px black',
    transform: 'translateY(-3%)',
  },
};

export const TeamItemName: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  mb: '1.5',
  fontWeight: 'semibold',
};

export const Seperator: CSSObject = {
  color: 'gray.400',
  p: 0,
  boxSize: 4,
};
export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon"
`,
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'gamma.500',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'gamma.500',
};

export const ArrowIcon: CSSObject = {
  boxSize: '6',
  color: 'gray.700',
  border: '1px',
  borderColor: 'gamma.700',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
