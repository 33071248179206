import { chakra } from '@chakra-ui/react';
import { styleImports } from './Content.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const WrapperContent = chakra('div', {
  label: 'login-WrapperContent',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.WrapperContent;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const Title = chakra('p', {
  label: 'login-Title',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.Title;
      } catch (err) {
        return {};
      }
    })(),
  },
});
