import { CustomButtonProps } from '@/components/Button/Button';

export const buttonLogInProps: CustomButtonProps = {
  variant: 'solid',
  w: 'full',
  fontFamily: 'CarterOne',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  _hover: {
    bgGradient: 'linear(to-b, alpha.700, alpha.800)',
    color: 'whiteAlpha.700',
  },
};
