import { Box, chakra, Grid, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { styleImports } from './RaceTiles.styles.imports';
import IconSvg from '../../../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const GridRaceItems = chakra(Grid, {
  label: 'raceTiles-GridRaceItems',
  baseStyle: () => ({
    gridAutoColumns: 'minmax(0, 1fr)',
    gridAutoFlow: ['row', 'column'],
    columnGap: '2.5',
    mx: ['1.5', null, null, 'unset'],

    ...(() => {
      try {
        return styleImports[themeName]?.GridRaceItems;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxColumn = chakra(Box, {
  label: 'raceTiles-BoxColumn',
  baseStyle: () => ({
    mb: ['5', 'unset'],

    '& > div:nth-child(n+9)': { display: 'none' },

    ...(() => {
      try {
        return styleImports[themeName]?.BoxColumn;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRaceType = chakra(Text, {
  label: 'raceTiles-TextRaceType',
  baseStyle: ({ theme }) => ({
    fontWeight: 'bold',
    mb: '2',
    fontSize: 'xs',
    textAlign: 'center',
    textTransform: 'uppercase',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
      textShadow: '0px 1px 5px #2c2c2c',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextRaceType;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkRaceContainer = chakra(Link, {
  label: 'raceTiles-LinkRaceContainer',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    px: '2',
    py: '2.5',
    justifyContent: 'flex-start',
    borderRadius: [null, 'md'],
    textDecoration: 'none',
    display: 'flex',
    width: 'full',
    transition: 'all 0.1s ease-in-out',
    gap: '0.5',
    flexBasis: '33.3%',
    mb: ['px', '1'],

    '.chakra-badge': {
      display: 'inline-flex',
      alignItems: 'center',
      ...(theme.themeName === EThemes.Betgalaxy && {
        alignSelf: 'stretch',
      }),

      '&.state-prior': { color: 'white' },
      '&.chakra-badge.state-prior': {
        ...(theme.themeName === EThemes.Wellbet && {
          color: 'gray.700',
        }),
      },
    },

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'white',
      color: 'alpha.700',
      borderRadius: 'sm',
      boxShadow: 'md',
      mb: '1.5',

      transition: 'all .3s ease-in-out',
      _hover: {
        bg: 'alpha.100',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'betaAlpha.500',
      boxShadow: 'inset 0px 1px 4px #5171ae, inset 0px 1px 1px #404c94',
      borderRadius: 'md',
      color: 'white',
      fontWeight: 'semibold',
      px: '2',
      py: '2.5',
      mb: '1',

      transition: '.3s ease-in-out',
      _hover: {
        bg: 'betaAlpha.800',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkRaceContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Icon = chakra(IconSvg, {
  label: 'raceTiles-Icon',
  baseStyle: ({ theme }) => ({
    mr: '1',
    boxSize: '4',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.700',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Icon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRace = chakra(Text, {
  label: 'raceTiles-TextRace',
  baseStyle: ({ theme }) => ({
    fontSize: 'xs',
    textTransform: 'capitalize',
    mr: 'auto',

    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'semibold',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextRace;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRaceNumber = chakra(Text, {
  label: 'raceTiles-TextRaceNumber',
  baseStyle: ({ theme }) => ({
    fontSize: 'sm',
    flex: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      flex: 0,
      py: '1',
      borderRadius: 'base',
      bg: 'alpha.700',
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      minWidth: '24px',
      display: 'inline-block',
      fontSize: '2xs',
      h: '5',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      flex: 0,
      py: '1',
      borderRadius: 'sm',
      bg: 'gamma.100',
      color: 'gamma.500',
      lineHeight: '1',
      fontWeight: 'bold',
      textAlign: 'center',
      minWidth: '6',
      display: 'inline-block',
      fontSize: 'xs',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextRaceNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
