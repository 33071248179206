import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const BoxWrapper: CSSObject = {
  px: '2',
  mt: ['3', null, '0'],
};

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'lg',
  mb: '2',
  border: 'none',
  borderBottomRadius: 'base',

  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',

    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  bg: 'rgba(78, 178, 255, 0.14)',
  borderBottomRadius: 'lg',
  p: '2',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  ...(isNested && {
    p: '2',
    bg: 'rgba(78, 178, 255, 0.14)',
    borderTop: 'none',
  }),
  paddingTop: '18px',
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'rgba(78, 178, 255, 0.14)',
  color: 'white',
  px: '2',
  py: '2.5',
  border: 'none',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 'lg',
  fontWeight: 'normal',
  fontSize: 'sm',
  fontFamily: 'accent',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  display: 'flex',
  justifyContent: 'space-between',

  _hover: {
    bg: 'rgba(78, 178, 255, 0.34)',
  },

  '.chakra-accordion__icon': {
    boxSize: '5',
    color: 'white',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    borderBottomRadius: 'none',
  },

  ...(isNested && {
    bg: 'rgba(78, 178, 255, 0.14)',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    color: 'white',
    fontWeight: 'normal',
    px: '2',

    '.chakra-accordion__icon': {
      boxSize: ['4', '5'],
      transition: '.3s ease-in-out',
    },
  }),
});
