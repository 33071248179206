import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  borderTop: '1px dashed',
  borderColor: 'whiteAlpha.300',
  mx: ['-2', null, '0'],
  px: ['2', null, '0'],
  py: ['unset', null, '1'],
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
};

export const RacerName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
};
