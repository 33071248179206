import { ButtonGroupProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Segmented.styles.imports';

const themeName = getThemeName();

export type SegmentedSchema = Partial<{
  segmentWrapper: ButtonGroupProps;
}>;

export const segmentedStyles: SegmentedSchema = {
  segmentWrapper: {
    'data-testid': 'segmented-segmentWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.segmentedStyles?.segmentWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
