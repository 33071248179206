import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.400',
};

export const DeductionsText: CSSObject = {
  color: 'gray.700',
  fontSize: '2xs',
  fontWeight: '500',
};

export const BoxMoreText: CSSObject = {
  w: '7',
};

export const toteMultiGrid: CSSObject = {
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '6',
  display: 'grid',
};

export const toteMultiGridText: CSSObject = {
  fontSize: 'xs',
  w: 'full',
  color: 'alpha.700',
  mb: '2',
  '&.raceNumber': {
    fontWeight: 'bold',
    borderBottom: '1px dashed',
    borderColor: '#00000040',
    boxShadow: '0px 1px 0px 0px #FFFFFF1A',
  },
};

export const ToteMultiGridText: CSSObject = {
  color: 'alpha.700',
  fontWeight: 'medium',
};
