import { CSSObject } from '@emotion/react';

export const ButtonIncrease: CSSObject = {
  h: '10',
  w: '100%',
};

export const buttonIncreaseProps: CSSObject = {
  variant: 'tertiary',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: 2,
};
