import { CSSObject } from '@chakra-ui/react';

export const TableItem: CSSObject = {
  border: 'none',
  color: 'white',
  pl: 0,
  py: 2,
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
  borderTop: '1px solid',
  borderColor: 'whiteAlpha.300',
  borderStyle: 'dashed',
};

export const TableText = ({ textVariation }: any): CSSObject => ({
  color: textVariation === 'positive' ? 'green.500' : 'white',
});
