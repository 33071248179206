import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  accordionButton: {
    color: 'white',
    sx: {
      display: 'flex',
      py: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      background: 'white',
      px: '2',
      _hover: {
        bg: 'white',
      },
      '&[aria-expanded="false"]': {
        borderBottomRadius: 'md',
      },
    },
  },
  topLeaguesText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
    color: 'gray.700',
    fontSize: 'sm',
    px: 0,
  },
  accordionIcon: { color: 'gray.700' },
  accordionPanel: {
    bg: 'white',
    pb: 2,
    pt: 0,
    borderBottomRadius: 'md',
    px: 2,
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'alpha.500',
    px: '2',
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      color: 'white',
      fontsize: 'sm',
      my: '3',
      fontWeight: 'bold',
    },
  },
  marketGroupsByLetterAccordionItem: {
    bg: 'white',
    borderTopColor: 'delta.300',
    sx: {
      _last: {
        borderBottomRadius: 'md',
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        borderTopRadius: 'md',
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      background: 'white',
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionIcon: {
    color: 'gray. 700',
  },
  marketGroupsByLetterAccordionPanel: {
    bg: 'white',
    pb: 2,
    pt: 0,
    _last: {
      borderRadius: 'md',
    },
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'alpha.400',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: { _hover: { textDecoration: 'none' } },
  },
};
