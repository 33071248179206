import { chakra, Box, BoxProps } from '@chakra-ui/react';
import { styleImports } from './StepIndicator.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type TIndicator = {
  active: boolean;
} & BoxProps &
  any;

export const Indicator = chakra(Box, {
  baseStyle: ({ active }: TIndicator) => ({
    width: '2',
    height: '2',
    background: active ? 'gray.400' : 'gray.300',
    borderRadius: 'full',
    marginTop: '1.5',
    ml: '1.5',
    boxShadow:
      active &&
      '0px 1px 0px rgba(255, 255, 255, 0.2), inset 0px 2px 3px rgba(0, 0, 0, 0.25)',
    ...(() => {
      try {
        return styleImports[themeName]?.Indicator?.({
          active,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});
