import { createAsyncThunk } from '@reduxjs/toolkit';

import { getStrings } from '@/helpers/utils';
import { apiGetRequest } from '../../../lib/api/api';

import { AppDispatch } from '../../../redux/store';
import { TGetNextToJumpCursorInput } from './races.types';
import {
  TRace,
  TRunner,
  TVenue,
  TRaceMeta,
  ERaceType,
  TRaceResults,
  TNextToJumpRace,
  TMarket,
  TRaceMarket,
} from '../../../lib/DBModels';

const [
  {
    Racing: {
      ErrorMessages: {
        getRacingVenueListFailMessage,
        getRaceMetaFailMessage,
        getRaceRunnerListFailMessage,
        getOtherRacesByVenueFailMessage,
        getRaceResultsFailMessage,
        getRaceMarketsFailMessage,
      },
    },
  },
] = getStrings();

export const getRacingVenueList = createAsyncThunk<
  TVenue[] | null,
  { raceType: ERaceType; date: string },
  { dispatch: AppDispatch }
>(
  'racing/getRacingVenueList',
  async ({ raceType, date }): Promise<TVenue[] | null> => {
    try {
      const venues = await apiGetRequest<TVenue[]>(
        `punter/races/venue-list?race_type=${raceType}&meeting_date=${date}`,
        { failMessage: getRacingVenueListFailMessage }
      );

      return venues;
    } catch (err) {
      // potentially throw errors here?
      return null;
    }
  }
);

export const getNextToJumpVenueList = createAsyncThunk<
  TVenue[] | null,
  ERaceType,
  { dispatch: AppDispatch }
>(
  'racing/getNextToJumpVenueList',
  async (raceType): Promise<TVenue[] | null> => {
    try {
      const venues = await apiGetRequest<TVenue[]>(
        `punter/races/next-to-jump?race_type=${raceType}`,
        { failMessage: getRacingVenueListFailMessage }
      );

      return venues;
    } catch (err) {
      // potentially throw errors here?
      return null;
    }
  }
);

export const getNextToJumpCursor = createAsyncThunk<
  TNextToJumpRace[] | null,
  TGetNextToJumpCursorInput,
  { dispatch: AppDispatch }
>(
  'home/getNextToJump',
  async ({
    race_type,
    limit = 15,
    next_start_time,
  }): Promise<TNextToJumpRace[] | null> => {
    try {
      let url = `/punter/races/next-to-jump-cursor${
        race_type ? `?race_type=${race_type}&limit=${limit}` : ''
      }`;

      if (next_start_time) {
        url += `&next_start_time=${next_start_time}`;
      }

      const response = await apiGetRequest<TNextToJumpRace[]>(url, {
        failMessage: 'Failed',
      });

      return response;
    } catch (err) {
      return null;
    }
  }
);

export const getRaceMeta = createAsyncThunk<
  TRaceMeta | null,
  string,
  { dispatch: AppDispatch }
>('racing/getRaceMeta', async (raceId: string): Promise<TRaceMeta | null> => {
  try {
    const raceMeta = await apiGetRequest<TRaceMeta>(
      `punter/races/race-details/race-meta?race_id=${raceId}`,
      { failMessage: getRaceMetaFailMessage }
    );

    return raceMeta;
  } catch (err) {
    // potentially throw errors here?
    return null;
  }
});

const getRaceRunnerListGenerator = (typePrefix = '') =>
  createAsyncThunk<TRunner[] | null, string, { dispatch: AppDispatch }>(
    typePrefix,
    async (raceId: string): Promise<TRunner[] | null> => {
      try {
        const runnerList = await apiGetRequest<TRunner[]>(
          `punter/races/race-details/runner-list?race_id=${raceId}`,
          { failMessage: getRaceRunnerListFailMessage }
        );

        return runnerList;
      } catch (err) {
        // potentially throw errors here?
        return null;
      }
    }
  );
export const getRaceRunnerList = getRaceRunnerListGenerator(
  'racing/getRaceRunnerList'
);
export const getRaceRunnerListPolling = getRaceRunnerListGenerator(
  'racing/getRaceRunnerListPolling'
);

export const getRaceResults = createAsyncThunk<
  TRaceResults | null,
  string,
  { dispatch: AppDispatch }
>(
  'racing/getRaceResults',
  async (raceId: string): Promise<TRaceResults | null> => {
    try {
      const raceResults = await apiGetRequest<TRaceResults>(
        `punter/races/race-details/race-result?race_id=${raceId}`,
        { failMessage: getRaceResultsFailMessage }
      );

      return raceResults;
    } catch (err) {
      // potentially throw errors here?
      return null;
    }
  }
);

export const getMarketsList = createAsyncThunk<
  TRaceMarket[] | null,
  string,
  { dispatch: AppDispatch }
>(
  'racing/getMarkets',
  async (raceId: string): Promise<TRaceMarket[] | null> => {
    try {
      const marketResults = await apiGetRequest<TRaceMarket[]>(
        `punter/races/race-details/markets?race_id=${raceId}`,
        { failMessage: getRaceMarketsFailMessage }
      );

      return marketResults;
    } catch (err) {
      // potentially throw errors here?
      return null;
    }
  }
);

export const getOtherRacesSameVenue = createAsyncThunk<
  TRace[] | null,
  string,
  { dispatch: AppDispatch }
>(
  'racing/getOtherRacesByVenue',
  async (raceId: string): Promise<TRace[] | null> => {
    try {
      const races = await apiGetRequest<TRace[]>(
        `punter/races/race-details/other-races?race_id=${raceId}`,
        { failMessage: getOtherRacesByVenueFailMessage }
      );

      return races;
    } catch (err) {
      // potentially throw errors here?
      return null;
    }
  }
);

export const getRaceMarkets = createAsyncThunk<
  TMarket[] | null,
  string,
  { dispatch: AppDispatch }
>(
  'racing/getRaceMarkets',
  async (raceId: string): Promise<TMarket[] | null> => {
    try {
      const raceMarkets = await apiGetRequest<TMarket[]>(
        `punter/races/race-details/markets?race_id=${raceId}`,
        { failMessage: getRaceMarketsFailMessage }
      );

      return (raceMarkets as TMarket[]) ?? [];
    } catch (err) {
      // potentially throw errors here?
      return null;
    }
  }
);
