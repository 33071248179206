import { CSSObject } from '@chakra-ui/react';

export const HeaderText: CSSObject = {
  color: '#D6A64A',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  textTransform: 'lowercase',
  px: 0,
  ':first-letter': { textTransform: 'uppercase' },
};

export const HeaderContainer: CSSObject = {
  borderBottom: 0,
  p: '12px',
};

export const CloseButton: CSSObject = {
  display: 'none',
};

export const ContentContainer: CSSObject = {
  borderColor: 'transparent',
  p: 0,
};

export const ModalBoxWrapper: CSSObject = {
  bg: 'linear-gradient(225deg, #4B5A6D 0%, #3F4953 100%)',
};
