import { useQuerySportMarketGroup } from '@/api/sportDetailsMarketGroups/sportDetailsMarketGroups.hooks';
import { TSportMarketGroups } from '@/api/sportDetailsMarketGroups/sportDetailsMarketGroups.types';
import { useQuerySportMarkets } from '@/api/sportDetailsMarkets/sportDetailsMarkets.hooks';
import { TSportMarkets } from '@/api/sportDetailsMarkets/sportDetailsMarkets.types';
import { TMarketGroup } from '@/views/sports/MatchDetailPage/services/MatchDetailPage.types';

export const OTHER_MARKETS = 'Other Markets';

export const mapMarketsToGroups = (
  markets: TSportMarkets[],
  marketGroups: TSportMarketGroups[]
): TMarketGroup[] => {
  const mappedGroups = marketGroups
    // Filter out Top Markets group if only one market
    .filter(({ name }) => !(name === 'Top Markets' && markets.length === 1))
    .map(({ name, bet_options }) => ({
      name,
      markets: Array.from(new Set(bet_options)).flatMap((betOption) =>
        markets
          .filter(({ bet_option }) => bet_option === betOption)
          .sort(
            (a, b) =>
              (a.bet_option_sort_order ?? 0) - (b.bet_option_sort_order ?? 0)
          )
      ),
    }));

  // Create group for those w/o a group
  const otherMarketsGroup = {
    name: OTHER_MARKETS,
    markets: markets.filter(({ bet_option }) => {
      const finder = ({ bet_options }: TSportMarketGroups) =>
        bet_options.includes(bet_option ?? '');
      const marketHasGroup = marketGroups.findIndex(finder) !== -1;

      return !marketHasGroup;
    }),
  };

  const result = [...mappedGroups, otherMarketsGroup].filter(
    (group) => group.markets.length
  );
  return result;
};

export type TUseMatchMarketGroupsParams = {
  match_id: string;
};

export type TUseMatchMarketGroupsOptions = {
  enabled?: boolean;
  refetchInterval?: number | false;
};

export function useMatchMarketGroups(
  params: TUseMatchMarketGroupsParams,
  options?: TUseMatchMarketGroupsOptions
) {
  const marketsQuery = useQuerySportMarkets(params, options);
  const marketGroupsQuery = useQuerySportMarketGroup(params, options);

  let data;

  if (marketsQuery.data && marketGroupsQuery.data) {
    data = mapMarketsToGroups(marketsQuery.data, marketGroupsQuery.data);
  }

  const isError = marketsQuery.isError || marketGroupsQuery.isError;
  const isInitialLoading =
    marketsQuery.isInitialLoading ||
    marketGroupsQuery.isInitialLoading ||
    !params.match_id;

  return { data, isError, isInitialLoading };
}
