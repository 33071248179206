import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  px: '2',
  color: 'black',
};

export const BoxTakeActionTable: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'md',
  p: '2',
  bg: 'white',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const TextTakeAction: CSSObject = {
  color: 'gray.700',
  fontWeight: 'medium',
};

export const buttonActionProps: ButtonProps = {
  variant: 'tertiary',
  fontSize: 'sm',
  h: '43px',
};
