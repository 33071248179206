import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '2',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    boxShadow:
      '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
    borderRadius: 'md',
    px: '1',
  },
  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '2px solid',
    borderTopColor: 'whiteAlpha.200',
    pt: '3',
    '.chakra-text': {
      _first: {
        color: 'white',
      },
      _notFirst: {
        borderRadius: 'base',
        bg: 'beta.900',
        color: 'whiteAlpha.700',
        opacity: '0.5',
        border: 'none',
      },
    },
  },
};
export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontFamily: 'accent',
  fontSize: 'sm',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'white',
  },
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
};
export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
    border: '1px solid black',
  },
};
export const AccordionHeaderLogOut: CSSObject = {
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  border: '2px solid',
  borderColor: 'alpha.400',
  boxShadow:
    '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  mt: '-2',
  marginLeft: '0',
  marginRight: '0',
  px: '4',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
export const IconPerson: CSSObject = {
  boxSize: ['5', null, '5'],
  color: 'white',
  mr: '1.5',
};
export const TextAccordion: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  mr: 'auto',
  mt: '0.5',
};
export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  borderRadius: 'base',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  fontSize: 'xs',
  fontWeight: 'bold',
  gap: '1',
  p: '2',
  '.chakra-text': {
    color: 'var(--bc-colors-alpha-700) !important',
  },
  '.chakra-icon': {
    color: 'alpha.700',
  },
  mb: '2',
};
export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  border: 'none',
  bg: 'alpha.500',
  mb: '0',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};
