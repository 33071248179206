import React from 'react';
import { Global } from '@emotion/react';
import bg from '@/assets/betgalaxy/images/backgrounds/secondary-layout-background.jpg';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'left bottom',
        },
      }}
    />
  );
}
