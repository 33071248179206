import { InputProps } from '@chakra-ui/input';
import theme from '@chakra-ui/theme';

export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      color: 'white',
      fontSize: 'sm',
      fontWeight: 'bold',
      caretColor: 'currentColor',

      '&&': {
        border: '2px',
        borderRightRadius: 'md',
        borderColor: 'alpha.200',
        borderLeft: 'none',
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.35)',
        bg: 'beta.800',
      },

      '&::placeholder': {
        color: 'gray.400',
      },

      '&:hover': {
        '&&': {
          bg: 'beta.700',
          border: 'none',
          borderLeft: 'none',
          boxShadow:
            '0px 0px 0px 1px rgba(251, 201, 21, 1) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.4) inset, 0px 0px 8px 0px rgba(251, 201, 21, 0.5)',
        },
      },

      '&:focus, &:active': {
        '&&': {
          border: '2px',
          borderColor: 'alpha.200',
          boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.4) inset',
          bg: 'beta.800',
          borderLeft: 'none',
        },
      },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      fontWeight: 'black',
      fontSize: 'sm',
      color: 'alpha.700',

      '&&': {
        bg: 'alpha.200',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        borderLeftRadius: 'md',
        border: 'none',
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.35)',
      },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
    md: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
  },
  variants: {
    unstyled: (props: InputProps) => {
      const { addon, field } = theme.components.Input.variants.outline(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          '&&': {
            boxShadow: 'none',
            color: 'black',
            bg: 'white',
            borderRadius: 'base',
            h: '40px',
            borderColor: 'white',
            pl: '2.5',
          },
          '&:focus, &:active, &:hover': {
            '&&': {
              bg: 'white',
              borderColor: 'white',
              color: 'black',
            },
          },
        },
      };
    },
  },
  defaultProps: {
    size: 'md',
  },
};
