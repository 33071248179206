import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'black',
  fontSize: 'sm',
  color: '#441200',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  ...(isPrefix
    ? {
        borderLeftRadius: 'md',
        borderRightRadius: 'none',
      }
    : {
        borderLeftRadius: 'none',
        borderRightRadius: 'md',
      }),
  border: 'none',
  mt: '4px',
  ml: '-2px',
  _groupFocusWithin: {
    bg: 'linear-gradient(180deg, var(--bc-colors-beta-400) 0%, #D36A11 100%)',
  },
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'md',
  bg: 'blackAlpha.400',
  boxShadow: '0px 1px 0px #2B6B99, inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'alpha.600',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'gamma.500',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'gamma.500',
  fontWeight: 'black',
};

export const InputBetSlip: CSSObject = {
  ml: '1',
  '&&': {
    borderLeft: '2px',
    borderColor: 'beta.400',
    borderRadius: 'md',
  },
};
