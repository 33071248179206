import React, {
  FC,
  useEffect,
  useState,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import { useField } from 'formik';

import {
  AutoCompleteContainer,
  AutoCompleteResultContainer,
  AutoCompleteResult,
  AutoCompleteLoadIndicator,
} from './PlacesAutocomplete.styles';

import PlacesAutocompleteProps, {
  AutocompleteResult,
  AutoCompleteHookReturn,
} from './types';
import { SignUpInput } from '../../views/onboarding/Signup/styles/SignUp.styles';

/**
 * For maintenance/docs, visit https://github.com/wellyshen/use-places-autocomplete
 */

// Allowed keys for interaction with suggestions dropdown with keyboard
const acceptedKeys = ['ArrowUp', 'ArrowDown', 'Escape', 'Enter'];

const PlacesAutocomplete: FC<PlacesAutocompleteProps> = ({
  label,
  inputName,
  placeholder,
  disabled,
  dataCy,
  onChange,
  initialValue,
  isManual,
}) => {
  const { 2: helpers } = useField(inputName);
  const [currIndex, setCurrIndex] = useState(0);
  const [activeSuggestion, setActiveSuggestion] =
    useState<AutocompleteResult | null>(null);

  const { setValue: setFormikFormValue } = helpers;

  const {
    ready,
    value,
    suggestions: { loading, status, data },
    setValue,
    clearSuggestions,
  }: AutoCompleteHookReturn = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'AU' },
    },
    defaultValue: initialValue,
    debounce: 300,
  });
  const hasSuggestions = status === 'OK';

  const dismissSuggestions = () => {
    setCurrIndex(0);
    setActiveSuggestion(null);
    clearSuggestions();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    if (!e.currentTarget.value) {
      // If user clears address, clear Formik value and force validation error
      setFormikFormValue('');
    }
  };

  const handleDropdownSelect = async (suggestion: AutocompleteResult) => {
    setFormikFormValue(suggestion?.description);
    clearSuggestions();
    const place = await getDetails({
      placeId: suggestion?.place_id,
      fields: ['address_components', 'formatted_address', 'types'],
    });
    // types not available for the place package
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setValue(place.formatted_address, false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    onChange?.(place);
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

    if (activeSuggestion && (e.key === 'Enter' || e.key === 'Escape')) {
      await handleDropdownSelect(activeSuggestion);
      dismissSuggestions();
      return;
    }

    let nextIndex = 0;

    // Set form and autocomplete values on navigating the dropdown with arrow keys
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      // Ensure can't go lower than 0
      nextIndex = currIndex === 0 ? currIndex : currIndex - 1;
    } else if (e.key === 'ArrowDown') {
      // Ensure can't go higher than data array length
      nextIndex = currIndex === data.length - 1 ? currIndex : currIndex + 1;
    }
    setActiveSuggestion(data[nextIndex]);
    setCurrIndex(nextIndex);
  };

  useEffect(() => {
    if (isManual) {
      setValue('');
    }
  }, [isManual, setValue]);

  useEffect(() => {
    if (data.length) {
      setActiveSuggestion(data[currIndex]);
    }
  }, [data, currIndex]);

  return (
    <AutoCompleteContainer>
      <SignUpInput
        name={inputName}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        isDisabled={!ready || disabled}
        placeholder={placeholder}
        label={label}
        type="text"
        data-cy={dataCy}
        autoComplete="address-line1"
      />

      {loading && <AutoCompleteLoadIndicator />}

      {status === 'OK' && (
        <AutoCompleteResultContainer>
          {data.map((suggestion) => (
            <AutoCompleteResult
              key={suggestion.place_id}
              onClick={() => handleDropdownSelect(suggestion)}
            >
              {suggestion.description}
            </AutoCompleteResult>
          ))}
        </AutoCompleteResultContainer>
      )}
    </AutoCompleteContainer>
  );
};

export default PlacesAutocomplete;
