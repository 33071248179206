import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    bg: 'white',
    borderRadius: 'lg',
  },
  BoxGridHeaderItem: {
    bg: 'alpha.200',
    color: 'alpha.800',
  },
  GridCardContent: {
    p: '0',
  },
  LinkCardHeader: {
    bg: 'alpha.100',
    color: 'alpha.800',
    borderTopRadius: 'md',
  },
  LinkRaceDetails: {
    color: 'white',
    bg: 'alpha.600',
    textTransform: 'uppercase',
    _hover: {
      bg: 'alpha.600',
    },
    borderBottomRadius: 'md',
  },
  buttonRaceCardOddsProps: {
    variant: 'solid',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'sm',
    borderRadius: 'sm',
    w: '14',
    h: '9',
    lineHeight: 'normal',
  },
};

export const TextRunner = {
  color: 'alpha.700',
};
