import * as goldenrush from './Home.styles.goldenrush';
import * as juicybet from './Home.styles.juicybet';
import * as vikingbet from './Home.styles.vikingbet';
import * as volcanobet from './Home.styles.volcanobet';
import * as buffalobet from './Home.styles.buffalobet';
import * as bet777 from './Home.styles.bet777';
import { themeName } from '@/helpers/getThemeConfig';

export const styleImports: Partial<Record<string, any>> = {
  goldenrush,
  juicybet,
  vikingbet,
  volcanobet,
  buffalobet,
  bet777,
};

export const styleImport = styleImports[themeName]?.default;
