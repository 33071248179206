import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from '../../MarketsByMatch/components/Loader';
import {
  ButtonProp,
  FlexPropWrapper,
  TextProp,
} from '../../MarketsByMatch/components/Propositions/styles/Propositions.styles';
import { TPropositionsProps } from '../services/MatchDetailPage.types';

export default function Propositions({
  data,
  isLoading,
  onSelection,
}: TPropositionsProps) {
  if (isLoading) {
    return <Loader amount={1} />;
  }

  return (
    <FlexPropWrapper propLength={data?.length}>
      {data?.map(
        ({
          proposition_id,
          proposition_name,
          return_amount,
          is_disabled,
          is_selected = false,
        }) => {
          const handleClick = () => {
            onSelection?.(proposition_id);
          };

          return (
            <ButtonProp
              key={proposition_id}
              data-cy="propositionButton"
              disabled={is_disabled}
              isSelected={is_selected}
              propLength={data.length}
              onClick={handleClick}
            >
              <TextProp propLength={data.length}>{proposition_name}</TextProp>
              <TextProp
                className="return-amount"
                isSelected={is_selected}
                propLength={data.length}
              >
                {return_amount?.toFixed(2) ?? (
                  <FormattedMessage id="generic.na" />
                )}
              </TextProp>
            </ButtonProp>
          );
        }
      )}
    </FlexPropWrapper>
  );
}
