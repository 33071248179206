import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  justifyContent: [null, null, 'center'],
  minW: ['unset', null, '460px'],
  bg: 'rgba(78, 178, 255, 0.14)',
  form: {
    '.chakra-form-control': {
      '.chakra-form__label': {
        color: 'white',
        fontSize: 'xs',
        fontWeight: 'medium',
      },
    },
    'a[href*="forgot-password"]': {
      color: 'alpha.400',
      fontWeight: 'bold',
      fontSize: 'xs',
    },
    '.chakra-button[data-cy*="loginSubmit"]': {
      textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    },
    '.chakra-form__error-message': {
      bg: 'red.600',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
      borderRadius: 'base',
      p: '1',
      color: 'white',
      fontWeight: 'medium',
      fontSize: 'xs',
    },
    'button[aria-label*="password toggle"]': {
      top: '-1px',
      color: 'alpha.400',
    },
    'input:-webkit-autofill, input:-webkit-autofill:focus': {
      transition: 'background-color 0s 600000s, color 0s 600000s',
    },
  },
};
export const Title: CSSObject = {
  color: 'gamma.200',
  fontSize: 'md',
  fontFamily: 'accent',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  mb: '4',
  mt: [null, null, '9'],
  textShadow: '0px 0px 8px #FFFFFF66',
};
