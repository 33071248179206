import { CSSObject } from '@chakra-ui/react';

export const WrapperFlexOnboarding: CSSObject = {
  w: ['100dvw', null, 'unset'],
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  overflowY: 'visible',
  minH: [null, null, '670px'],
  w: ['full', null, '760px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, '10px'],
  bg: 'white',
  boxShadow: [null, null, '0px 8px 30px 0px rgba(0, 0, 0, 0.20)'],
  pos: 'relative',
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['2', null, 'auto'],
  minW: ['auto', null, '460px'],
  '#email': {
    borderRadius: '4px',
    border: '1px solid var(--chakra-gray-300, #CBD5E0)',
    background: 'var(--vikingbet-zeta-50, #FDFAF4)',
    alignSelf: 'stretch',
    h: '38px',
  },
};

export const Title: CSSObject = {
  color: 'var(--bc-gray-500, #718096)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
  mb: '4',
};

export const Input: CSSObject = {
  borderRadius: '4px',
  border: '1px solid var(--chakra-gray-300, #CBD5E0)',
  background: 'var(--vikingbet-zeta-50, #FDFAF4)',
  alignSelf: 'stretch',
  h: '38px',
};
