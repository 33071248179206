import { CSSObject, Flex, chakra, Text } from '@chakra-ui/react';
import { Button } from '@/components/Button/Button';
import IconSvg from '../../../../../../common/IconSvg';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './QuickDeposit.styles.imports';

type TOverrideName =
  | 'FlexQuickDeposit'
  | 'QuickDepositHeader'
  | 'QuickDepositBody'
  | 'QuickDepositFooter'
  | 'ButtonQuickDeposit'
  | 'ButtonCard';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};

// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const FlexQuickDeposit = chakra(Flex, {
  baseStyle: {
    color: 'white',
    bg: 'rgba(0, 0, 0, 0.3)',
    boxShadow:
      '0px 1px 0px rgba(255, 255, 255, 0.08), inset 0px 2px 4px rgba(0, 0, 0, 0.2)',

    flexDirection: 'column',
    justifyContent: 'flex-start',
    p: '2.5',
    gap: '2.5',

    boxSize: 'full',

    borderRadius: 'md',

    ...styles?.FlexQuickDeposit,
  },
});

export const QuickDepositHeader = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    fontWeight: 'bold',

    ...styles?.QuickDepositHeader,
  },
});

export const ButtonCard = chakra(Button, {
  baseStyle: {
    color: 'white',

    ...styles?.ButtonCard,
  },
});

export const QuickDepositBody = chakra(Flex, {
  baseStyle: {
    flexDirection: 'row',
    justifyContent: 'center',

    ...styles?.QuickDepositBody,
  },
});

export const QuickDepositFooter = chakra(Text, {
  baseStyle: {
    color: 'white',

    ...styles?.QuickDepositFooter,
  },
});

export const ButtonQuickDeposit = chakra(Button, {
  baseStyle: {
    bg: 'linear-gradient(180deg, #F3D247 0%, #F0B344 100%)',
    boxShadow:
      '0px 3px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(255, 255, 255, 0.95)',
    borderRadius: 'md',
    w: ['20', '24'],
    h: '12',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'yellow.800',

    ...styles?.ButtonQuickDeposit,
  },
});

export const IconLeftQuickDepositBtn = chakra(IconSvg, {
  baseStyle: {
    bg: 'white',
    borderRadius: 'md',
    p: '1',
    h: '6',
    w: '10',
  },
});

export const IconRightQuickDepositBtn = chakra(IconSvg, {
  baseStyle: {
    boxSize: '5',
    color: 'white',
  },
});

export const TextQuickDepositBtn = chakra(Text, {
  baseStyle: {
    fontSize: 'md',
    mt: '0.5',
    ml: '1.5',
  },
});

export const ButtonCancel = chakra(Button, {
  baseStyle: {
    border: '2px solid #FFFFFF',
    bg: 'rgba(0, 0, 0, 0.3)',
    boxShadow: 'inset 0px 1px 2px rgba(255, 255, 255, 0.95)',
    borderRadius: 'md',
    w: [40, 48],
    h: 10,
    fontSize: 'md',
  },
});

export const ButtonConfirm = chakra(Button, {
  baseStyle: {
    bg: 'linear-gradient(180deg, #F3D247 0%, #F0B344 100%)',
    borderRadius: 'md',
    w: [40, 48],
    h: 10,
    color: 'yellow.800',
    fontSize: 'md',
    boxShadow: 'inset 0px 1px 2px rgba(255, 255, 255, 0.95)',
  },
});
