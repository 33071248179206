import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';

export const SignupAcknowledgementButton: CSSObject = {
  color: 'blue.400',
  textDecoration: 'none',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'blue.400',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  bg: '#FD62FF',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.45), 0px 0px 0px 2px rgba(254, 153, 255, 0.70) inset',
  fontSize: '13px',
  pb: '1',
  fontFamily: 'Sansita One',
  borderColor: 'white',
  color: 'beta.700',
  h: '36px',
  fontWeight: 'normal',
  _hover: {
    opacity: '0.4',
    bg: 'gamma.100',
  },
  _active: {
    bg: 'alpha.800',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'beta.400' : 'alpha.500',

  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-beta-400) !important'
      : 'var(--bc-colors-alpha-500) !important',
  },
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  fontSize: 'xs',
  color: active ? ['alpha.500', null, 'white'] : ['beta.500', null, 'beta.500'],
});

export const SignUpInput: CSSObject = {
  '&.chakra-input': {
    color: 'black',
    border: '1px',
    boxShadow: 'none',
    backgroundColor: 'gamma.50',
    borderColor: 'gamma.200',
    fontSize: 'xs',
    '&:focus, &:hover': {
      backgroundColor: 'gamma.100',
    },
    '&::placeholder': { color: 'gamma.200' },
  },
};

export const SelectSignUp: CSSObject = {
  '&.chakra-select': {
    backgroundColor: 'gamma.50',
    borderColor: 'gamma.200',
    '&:focus, &:hover': {
      backgroundColor: 'gamma.100',
    },
    '&::placeholder': { color: 'gamma.200' },
  },
};

export const BoxManualAddressContainer: CSSObject = {
  bg: 'none',
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'normal',
  color: 'gamma.800',
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);

export const FlexHeaderWrapper: CSSObject = {
  fontSize: 'md',
  fontWeight: 600,
  color: 'gray.500',
  mb: '5',
};

export const SignUpDatePicker: CSSObject = {
  '& *': { fontSize: '12px' },
};
