import { TextProps, BoxProps } from '@chakra-ui/react';
import { styleImports } from './Specials.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type SpecialsStylesSchema = Partial<{
  boxWrapper: BoxProps;
  heading: TextProps;
}>;

export const specialsBaseStyles: SpecialsStylesSchema = {
  boxWrapper: {
    label: 'specials-boxWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.specialsStyles?.boxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  heading: {
    label: 'specials-label',
    ...(() => {
      try {
        return styleImports[themeName]?.specialsStyles?.heading;
      } catch (err) {
        return {};
      }
    })(),
  },
};
