import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/luckystride/images/feature/featureBg.png';
import featBgMb from '@/assets/luckystride/images/feature/featureBgMb.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';
import gradientBorder from '@/assets/luckystride/images/gradient-border.svg';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    borderRadius: 'base',
    w: 12,
    fontSize: 'xs',
    minH: '34px',
    lineHeight: 'normal',
  },
};

export const BoxCard: CSSObject = {
  p: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', 'md'],
    boxShadow: [
      'unset',
      '0px 2px 20px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 5px 0px rgba(33, 193, 255, 0.25) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset',
    ],
    m: 'px',
  },
  boxShadow: [
    '0px 2px 20px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 5px 0px rgba(33, 193, 255, 0.25) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset',
    'unset',
  ],
  bg: 'rgba(0, 209, 255, 0.25)',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'md',
  minH: '200px',
  py: '4',
  px: '3',
  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    borderImage: `url(${gradientBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['1', null, '20px'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  fontFamily: 'accent',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    fontFamily: 'accent',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'beta.300',
  fontFamily: 'accent',
  fontSize: 'lg',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'beta.300',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'accent',
  bg: 'gray.200',
  borderRadius: '3px',
  color: 'gray.600',
  px: '1',

  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '42px',
  bg: 'rgba(0, 209, 255, 0.25)',
  borderRadius: 'base',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
};

export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
