import { BoxProps, CSSObject, LinkProps } from '@chakra-ui/react';

export const wrapperProps: BoxProps = {
  sx: {
    'div:first-child': {
      display: 'block',
      color: 'beta.400',
      fontSize: 'sm',
      fontWeight: 'medium',
      mb: '4',
      alignItems: 'center',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mt: '-1px',
      },
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'gray.500',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',

  '+ a': {
    color: 'alpha.500',
    fontSize: 'sm',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'medium',
    mx: 0,
    display: 'inline',
  },
};

export const linkLoginProps: LinkProps = {
  color: 'beta.400',
  fontSize: 'sm',
  fontWeight: 'semibold',
  mb: '4',
  display: 'inline-flex',
  alignItems: 'center',

  sx: {
    svg: {
      boxSize: '2',
      mr: '1',
      mt: '-1px',
    },
  },
};
export const ForgotPasswordButton: CSSObject = {
  bgGradient: 'linear(180deg, delta.300, delta.600)',
  color: 'epsilon.300',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset, 0px 0px 0px 2px #FFC40B inset',
  borderRadius: 'md',
  fontFamily: 'Ultra',
  fontWeight: 'normal',
  fontSize: 'sm',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  textTransform: 'capitalize',
  _hover: {
    bg: 'delta.300',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    color: 'delta.500',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset, 0px 0px 0px 2px #FFC40B inset',
  },
  _after: {
    bgImage: 'unset',
    boxShadow: 'unset',
    opacity: 'unset',
  },
};
