import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',
    color: 'gamma.100',
    border: '2px solid',
    borderColor: 'gamma.100',
    height: '5',
    _hover: {
      background: 'transparent',
      color: 'gamma.100',
    },
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'beta.700',
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'primary',
  height: '100%',
  fontSize: 'xl',
  _hover: {},
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '100%',
  fontSize: 'xl',
  fontFamily: 'roboto',
  textTransform: 'uppercase',
  fontWeight: 'extrabold',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(to-b, gamma.300, gamma.300)',
  color: 'whiteAlpha.800',
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '100%',
  fontSize: 'xl',
  fontFamily: 'CrimsonPro',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(to-b, gamma.300, gamma.300)',
  color: 'whiteAlpha.800',
};
