import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  p: '2',
  border: 'none',
  bg: 'zeta.400',
  borderRadius: 'base',
  mx: ['2', '4'],
  mb: '4',
  'div:first-child div label': {
    bg: 'unset',
  },
};

export const CheckboxBoxed: CSSObject = {
  color: 'alpha.700',
  right: 0,
  borderColor: 'alpha.700',
  bg: 'alpha.400',
  width: '18px',
  height: '18px',
  mt: '1',
  '.chakra-checkbox__control': {
    width: '18px',
    height: '18px',
    bg: 'alpha.400',
    borderWidth: '1px',
    borderColor: 'alpha.700',
    '&[data-checked]': {
      width: '18px',
      height: '18px',
      bg: 'alpha.400',
      borderColor: 'alpha.700',
    },
    '&[data-checked]:hover': {
      width: '18px',
      height: '18px',
      bg: 'alpha.400',
      borderColor: 'alpha.700',
    },
  },
  '.chakra-checkbox__control:hover': {
    width: '18px',
    height: '18px',
    bg: 'alpha.400',
    border: 'none',
  },
};

export const TextBoxed: CSSObject = {
  color: 'alpha.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const SelectFieldContainer: CSSObject = {
  gap: '1',
  width: '200px',
};
