import { css } from 'styled-components';

export const containerSuccess = css`
  background: ${({ theme }) => theme.basePalette.greenGradient180deg};
  padding: 8px;
  width: 100%;
`;

export const containerError = css`
  background: red;
`;

export const containerNotification = css`
  background: blue;
`;

export const containerWarning = css`
  background: orange;
`;

export const responseText = css`
  color: ${({ theme }) => theme.palette.white};
  margin-left: 8px;
`;

export const iconSuccess = css`
  height: 17px;
  width: 17px;
  color: ${({ theme }) => theme.palette.white};
`;

export const iconWarning = css`
  height: 17px;
  width: 17px;
`;

export const iconNotification = css`
  height: 17px;
  width: 17px;
`;

export const iconError = css`
  height: 17px;
  width: 17px;
`;
