import { CSSObject } from '@chakra-ui/react';
import loadingspinner from '../../../../assets/vikingbet/icons/loadingspinner.svg';

export const FlexWrapper = (): CSSObject => ({
  bg: 'blackAlpha.700',
});

export const SpinnerLoader: CSSObject = {
  bgImage: `url(${loadingspinner})`,
};
