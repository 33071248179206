import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  keyframes,
  Skeleton,
  Switch,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggle from '@/assets/sterlingparker/images/toggle/toggle.png';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'ToggleFlexWrapper',
  baseStyle: {
    alignItems: 'center',
    width: '270px',
    height: '60px',
    contain: 'layout',
    justifyContent: 'center',
    pb: '0.5',

    borderRadius: '5px',
    borderTop: '1px solid rgba(255, 255, 255, 0.15)',
    background: 'linear-gradient(180deg, #30363F 0%, #21252B 100%)',
    boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    gap: '13px',
    mb: '12px',
    pos: 'relative',

    _before: {
      content: `''`,
      display: 'block',
      pos: 'absolute',
      inset: '6px',
      border: 'solid 1px rgba(0, 0, 0, 0.35)',
      borderRadius: '3px',
      boxShadow: '0 1px 0 0 rgba(255, 255, 255, 0.05)',
      zIndex: 1,
    },
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: {
      fontFamily: 'CrimsonPro',
      fontWeight: 'bold',
      fontSize: 'lg',
      textTransform: 'uppercase',
      color: 'alpha.600',
      letterSpacing: '3.42px',
      textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',

      _first: {
        textAlign: 'right',
      },
    },
  });

const Switcher = chakra(Switch, {
  baseStyle: {
    zIndex: '2',

    '.chakra-switch__track': {
      boxShadow:
        'inset 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 0 0 rgba(255, 255, 255, 0.1)',
      bg: 'beta.800',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',

      _before: {
        bgImg: `url(${toggle})`,
        boxSize: '45px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        top: '-1.5',
        left: '-2.5',
        animation: `
            ${keyframes`
              from { transform: rotateY(0deg) }
              to { transform: rotateY(-360deg) }
            `} 0.5s linear`,
        transformStyle: 'preserve-3d',
      },
    },

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
        _before: {
          animation: `
            ${keyframes`
              from { transform: rotateY(0deg); }
              to { transform: rotateY(360deg); }
            `} 0.7s linear`,
          transformStyle: 'preserve-3d',
        },
      },

      '.chakra-switch__track': {
        boxShadow:
          'inset 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 0 0 rgba(255, 255, 255, 0.1)',
        bg: 'beta.800',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={!!category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sports
      </TextToggle>
    </FlexWrapper>
  );
}
