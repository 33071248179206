import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  mb: 0,
  gap: '2',
};

export const buttonIncreaseProps: CustomButtonProps = {
  h: '10',
  variant: 'secondary',
};
