import { CSSObject } from '@chakra-ui/react';

export const TextItem: CSSObject = {
  minWidth: '16',
  width: 'fit-content',
  textTransform: 'capitalize',
  color: 'beta.800',
  fontSize: 'md',
  fontWeight: 'medium',
  margin: '0',
  fontFamily: 'accent',
  _last: {
    fontFamily: 'subHeading',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
};
