import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'gamma.300',
    mb: '2',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'sm',
    color: 'white',
    pt: '2',
    sx: {
      '> span': {
        fontSize: '2xs',
        fontWeight: 'medium',
        position: 'absolute',
        pl: '0.5',
      },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    borderBottom: '1px dashed',
    borderColor: 'blackAlpha.600',
    borderTop: '1px dashed',
    borderTopColor: 'whiteAlpha.200',
    _first: {
      borderTop: 'none',
    },
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
    },
  },
  eventMarketName: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  eventSubtitle: {
    fontSize: 'xs',
    fontWeight: 'bold',
  },
};
