/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Box, Skeleton, Stack, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useQueries } from '@tanstack/react-query';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { useQueryPunterAccount } from '@/api/punter/punter.hooks';
import { TSportMatchesResponse } from '@/api/sportDetailsMatches/sportDetailsMatches.types';
import { useQueryCompetitions } from '@/api/sports/competitions.hooks';
import Futures from '@/views/races/RaceMeetings/Futures/Futures';
import { specialsBaseStyles } from './styles/Specials.styles';
import { querySportsMatches } from '@/api/sportDetailsMatches/sportDetailsMatches';
import { keys } from '@/api/api.keys';
import { useAuth } from '@/hooks/useAuth';

export default function SpecialsContainer() {
  const { matches, isLoading } = useSpecialsView();
  if (!matches.length) return <></>;

  return <Specials matches={matches} isLoading={isLoading} />;
}

type TSpecials = {
  matches: TSportMatchesResponse;
  isLoading: boolean;
};
export function Specials({ matches, isLoading }: TSpecials) {
  if (isLoading)
    return (
      <Stack p="4">
        {Array.from({ length: 3 }, (_, i) => (
          <Skeleton key={i} height="100px" />
        ))}
      </Stack>
    );

  if (!matches.length) return null;

  return (
    <Box p="3" {...specialsBaseStyles.boxWrapper}>
      <Text
        mb="2"
        color="white"
        fontWeight="700"
        {...specialsBaseStyles.heading}
      >
        <FormattedMessage id="helpcentre.promotions.specials" />
      </Text>
      <Futures matches={matches} />
    </Box>
  );
}

const useSpecialsView = () => {
  const { isAuthenticated } = useAuth();

  const { data: punterAccount, isLoading } = useQueryPunterAccount();

  const { data: offerings } = useQueryOfferings({
    params: {
      pc: punterAccount?.pc,
    },
  });

  const futureId = offerings?.offered_sports?.find(
    (sport) => sport?.display_name?.toLowerCase() === 'specials'
  )?.sport_id;

  const queryCompetitions = useQueryCompetitions(
    {
      sport_id: futureId ?? '',
    },
    { enabled: !!futureId && !!isAuthenticated }
  );

  const competitionIds =
    queryCompetitions.data?.data.competitions?.map(
      (comp) => comp?.competition_id
    ) ?? [];

  const queries = useQueries({
    queries: competitionIds.slice(0, 5).map((id) => ({
      queryKey: [keys.sportDetailsMatches, id],
      queryFn: () =>
        querySportsMatches({
          competition_id: id,
        }),
      enabled: !!futureId && !!id && !!isAuthenticated,
      staleTime: 60 * 5000,
    })),
  });

  const matches = queries
    .flatMap((q) => q.data)
    .filter((d) => !!d)
    .slice(0, 5);

  return {
    matches,
    isLoading,
  };
};
