import {
  Icon,
  IconButton,
  InputGroup,
  InputProps,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { EyeOff, Eye } from '@styled-icons/remix-line';
import { Input } from '../styles/onboarding.styles';

const Password = forwardRef<any, InputProps>(({ children, ...rest }, ref) => {
  const $ref = ref as any;
  const [show, setShow] = useBoolean(false);

  return (
    <InputGroup>
      <Input
        data-cy="passwordInput"
        ref={$ref}
        type={show ? 'text' : 'password'}
        {...rest}
      />
      <InputRightElement>
        <IconButton
          aria-label="password toggle"
          variant="unstyled"
          size="sm"
          icon={<Icon as={show ? Eye : EyeOff} boxSize="4" />}
          onClick={setShow.toggle}
        >
          {show ? 'Hide' : 'Show'}
        </IconButton>
      </InputRightElement>
    </InputGroup>
  );
});

export default Password;
