import { CSSObject } from '@chakra-ui/react';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'linear-gradient(180deg, #2C3A56 0%, #202A3E 100%)',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  borderRadius: 'md',
  border: '1px solid',
  borderColor: '#5c6d8e',
  contain: 'layout',
  boxShadow:
    'px 0px 0px 1px rgba(255, 255, 255, 0.08) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.15)',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '11px',
  mx: '3px',
  mb: isKeypadVisible && 'unset',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.06)',
});

export const BetSlipFooterContainer: CSSObject = {
  color: 'gamma.600',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'white',
  fontFamily: 'KHInterference',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'extrabold',
  color: 'beta.500',
  fontFamily: 'KHInterference',
});

export const MakeDepositButton: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
  borderRadius: 'sm',
  h: '9',
  w: 'full',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  _hover: {
    bg: 'alpha.800',
  },
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const BetslipVStack: CSSObject = {
  gap: '2',
};
