import { CSSObject } from '@chakra-ui/react';

export const ButtonSaveChanges: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
  color: 'beta.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontSize: 'sm',
  pt: '0.5',
  textTransform: 'capitalize',
  textShadow: '0px 1px 4px rgba(255, 255, 255, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #FFD69E inset, 0px 0px 0px 3px #BD873E inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, yellow.600 0%, yellow.600 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const FormWrapper: CSSObject = {
  color: 'white',
  bg: 'transparent',
  p: '2',
  '.chakra-checkbox__label': {
    color: 'white',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    border: '1px solid',
    borderColor: 'gray.300',
    width: '1rem',
    height: '1rem',
    borderRadius: 'base',
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _hover: {
      bg: 'alpha.100',
      boxShadow: '0px 0px 8px 0px rgba(43, 108, 176, 0.40)',
    },
    _checked: {
      boxShadow: 'none',
      color: 'blue.600',
      _hover: {
        bg: 'alpha.100',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
};
