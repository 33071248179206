import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'epsilon.500';
      }
      if (state === 'ended') {
        return 'delta.300';
      }
      // 'prior'
      return 'blackAlpha.500';
    },
    color: () => {
      if (state === 'inprogress') {
        return 'gamma.700';
      }
      if (state === 'ended') {
        return 'delta.800';
      }
      // 'prior'
      return 'white';
    },
    borderRadius: 'base',
    px: '1',
    fontFamily: 'roboto',
  },
  '&&': {
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
  },
});
