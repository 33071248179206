import { SelectionStylesSchema } from './Selections.styles';

export const selectionStyles: SelectionStylesSchema = {
  errorWrapper: {
    mx: ['0', null, null, '-2.5'],
    mb: ['0', null, null, '-6'],
    bg: 'beta.200',
    zIndex: 'dropdown',
    position: 'sticky',
    flexDir: 'column',
    alignItems: 'center',
    bottom: '0',
    left: ['0', null, 'unset'],
    w: 'unset',
  },
};
