import { theme } from '@chakra-ui/react';

const branding = {
  brand: theme.colors.gray,
  accent: theme.colors.blackAlpha,
};

export const colors = {
  brand: branding.brand,
  accent: branding.accent,

  alpha: branding.brand,
  beta: branding.accent,
  gamma: branding.accent,
  delta: branding.accent,
  epsilon: branding.accent,
  theta: branding.accent,
};

type TKeys = keyof typeof colors;
type TValues = typeof colors[TKeys];
export type TColors = Record<TKeys, TValues>;
