import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pl: '4',
    pr: '2.5',
    display: 'flex',
    alignItems: 'center',
    sx: {
      py: ['2', null, null, '2.5'],
      pl: ['8px', null, null, '16px'],
      pr: [null, null, null, '10px'],
      display: 'flex',
      alignItems: 'center',
      overflowX: 'unset',
      whiteSpace: 'normal',
      sx: {
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      },
    },
    breadcrumbLinkItem: {
      fontSize: '2xs',
      fontWeight: 'extrabold',
      color: 'white',
      textDecoration: 'none',
      _hover: {
        textDecoration: 'underline',
      },
      sx: {
        '&:not([href])': {
          color: 'gamma.300',
        },
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
