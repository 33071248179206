import React from 'react';
import HomeLinks from '../links/components/HomeLinks/HomeLinks';
import ThemeHome from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeHome, [
  {
    name: 'homeLinks',
    component: <HomeLinks showOnHomeSports />,
    config: {
      after: 'banner',
    },
  },
]);
