import {
  ButtonProps,
  Box,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import styled from 'styled-components';
import Grid from '../../../../components/Grid/Grid';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';
import { Button } from '@/components/Button/Button';
import { styleImports } from './Overview.styles.imports';

const themeName = getThemeName();

export const VerifyTooltip = chakra(Tooltip, {
  label: 'overview-VerifyTooltip',
  baseStyle: () => ({
    bg: 'gray.700',
    color: 'white',
    p: '2',
    textAlign: 'center',
  }),
});

export const FlexItem = chakra(Flex, {
  label: 'overview-FlexItem',
  baseStyle: ({ theme }) => ({
    minH: '20',
    alignItems: 'center',
    borderTop: '1px',
    _first: { borderTopColor: 'transparent' },
    justifyContent: 'space-between',

    ...(theme.themeName === EThemes.Wellbet && {
      borderTopColor: 'gray.200',
      borderStyle: 'dashed',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      borderTopColor: 'gray.200',
      borderStyle: 'dashed',
    }),
  }),
});

export const FlexRightContent = chakra(Flex, {
  label: 'overview-FlexRightContent',
  baseStyle: () => ({
    ml: 'auto',
  }),
});

export const FlexLeftContent = chakra(Flex, {
  label: 'overview-FlexLeftContent',
  baseStyle: () => ({
    flexDirection: ['column', null, 'row'],
  }),
});

export const FlexResponsibleGamblingWrapper = chakra(Flex, {
  label: 'overview-GamblingWrapper',
  baseStyle: () => ({
    borderRadius: 'base',
    border: '1px',
    borderColor: 'gray.200',
    mt: '2',
    textAlign: 'center',
    ...(styleImports[themeName]?.FlexResponsibleGamblingWrapper ?? {}),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const FlexOverviewContentWrapper = chakra(Flex, {
  label: 'overview-FlexContentWrapper',
  baseStyle: () => ({
    flexDir: 'column',
    gap: '2',
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TextTitle = chakra(Text, {
  label: 'overview-TextTitle',
  baseStyle: ({ theme }) => ({
    flex: ['0', null, 'unset'],

    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'semibold',
      w: ['unset', null, '80'],
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'semibold',
      w: ['unset', null, '80'],
    }),
  }),
});

export const TextValue = chakra(Text, {
  label: 'overview-TextValue',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'semibold',
    }),
  }),
});

type TButtonVerified = {
  status: EGreenIDStatus;
  isHeader?: boolean;
} & ButtonProps &
  any;
export const ButtonVerified = chakra<TButtonVerified>(Button, {
  label: 'overview-ButtonVerified',
  shouldForwardProp: (prop) => !['status', 'isHeader'].includes(prop),
  baseStyle: ({ theme, status, isHeader }: TButtonVerified) => ({
    bg: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'green.500';
        case EGreenIDStatus.PENDING:
          return 'yellow.500';
        default:
          return 'red.500';
      }
    },
    borderRadius: 'md',
    color: status === EGreenIDStatus.PENDING ? 'black' : 'white',
    fontSize: 'xs',
    textTransform: 'uppercase',

    _disabled: {
      cursor: 'default',
      opacity: '1',
    },

    _hover: {
      '&&&': {
        bg: () => {
          switch (status) {
            case EGreenIDStatus.VERIFIED:
            case EGreenIDStatus.VERIFIED_ADMIN:
            case EGreenIDStatus.VERIFIED_WITH_CHANGES:
              return 'green.600';
            case EGreenIDStatus.PENDING:
              return 'yellow.600';
            default:
              return 'red.600';
          }
        },
        color: status === EGreenIDStatus.PENDING ? 'black' : 'white',
      },
    },

    svg: { boxSize: '6' },

    ...(theme.themeName === EThemes.Betgalaxy && {
      h: isHeader ? '10' : '8',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      h: '18px',
      border: '1px',
      borderColor: 'white',
      borderRadius: 'base',
      textTransform: 'capitalize',
      p: '1',
      fontSize: '2xs',

      svg: { boxSize: '4' },
    }),
    ...(() => {
      try {
        const { ButtonVerified: ButtonVerifiedComponent } =
          styleImports[themeName];
        return ButtonVerifiedComponent({ status });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const UnverifiedContainer = styled.div`
  ${({ theme }) => theme.pages.accountOverview.unverified.container};
`;

export const UnverifiedTextContainer = styled.div`
  ${({ theme }) => theme.pages.accountOverview.unverified.textContainer};
`;

export const UnverifiedText = styled.span`
  ${({ theme }) => theme.pages.accountOverview.unverified.text};
`;

export const UnverifiedIconContainer = styled.div`
  ${({ theme }) => theme.pages.accountOverview.unverified.iconContainer};
`;

export const UnverifiedIcon = styled.img`
  ${({ theme }) => theme.pages.accountOverview.unverified.icon};
`;

export const VerifiedContainer = styled.div`
  ${({ theme }) => theme.pages.accountOverview.verified.container};
`;

export const VerifiedTextContainer = styled.div`
  ${({ theme }) => theme.pages.accountOverview.verified.textContainer};
`;

export const VerifiedText = styled.span`
  ${({ theme }) => theme.pages.accountOverview.verified.text};
`;

export const VerifiedIconContainer = styled.div`
  ${({ theme }) => theme.pages.accountOverview.verified.iconContainer};
`;

export const VerifiedIcon = styled.img`
  ${({ theme }) => theme.pages.accountOverview.verified.icon};
`;

export const AccountDataRow = chakra(Grid, {
  baseStyle: () => ({
    paddingY: 1,
    minH: '50px',
  }),
});

export const AccountDataRowTitle = styled.span`
  ${({ theme }) => theme.pages.accountOverview.dataRowTitle};
`;

export const BalanceText = styled.span`
  ${({ theme }) => theme.pages.accountOverview.balance.amount};
`;

export const DepositButtonContainer = styled.div`
  ${({ theme }) => theme.pages.accountOverview.balance.depositButtonContainer};
`;

export const BoxOverviewTitle = chakra(Box, {
  label: 'overview-OverviewTitle',
  baseStyle: () => ({
    color: 'white',
    ...(styleImports[themeName]?.BoxOverviewTitle ?? {}),
  }),
});
