import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import IconSvg, { TIconSvg } from '../../../../components/common/IconSvg';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'lg',
    alignItems: 'center',
    justifyContent: 'center',
    mx: ['1.5', null, 'unset'],
    mb: ['1', null, '4'],
    px: '2px',
    boxShadow: 'unset',
    h: '54px',
    bg: 'blackAlpha.500',
  }),
});

const ButtonToggle = chakra(Button, {
  label: 'desktopToggle-ButtonToggle',
  shouldForwardProp: (prop) => !['isActive', 'category'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'accent',
    bg: isActive ? 'delta.300' : 'transparent',
    boxShadow: isActive
      ? '0px 2px 14px 0px rgba(0, 0, 0, 0.45), 0px 6px 10px 0px rgba(42, 84, 158, 0.6) inset'
      : 'none',
    border: '1px solid',
    borderColor: isActive ? '#2D4772' : 'transparent',
    fontWeight: 'bold',
    fontSize: 'sm',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 12px rgba(136, 218, 19, 0.6)'
      : '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: isActive ? 'alpha.500' : 'whiteAlpha.500',
    letterSpacing: 'wide',
    px: '3',
    w: '100%',
    h: '50px',
    _hover: {
      bg: isActive ? 'delta.300' : 'transparent',
    },
  }),
}) as ChakraComponent<
  'div',
  ButtonProps & { isActive: boolean; category: string }
>;

export const Icon = chakra(IconSvg, {
  label: 'sportsList-icon-sport',
  baseStyle: () => ({
    boxSize: '5',
    mr: '2',
  }),
}) as ChakraComponent<'svg', TIconSvg & { isActive: boolean }>;

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;
  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }
  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }
  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  const toggleCategory = (newCategory: string) => {
    setSearchParams({ category: newCategory });
  };

  return (
    <FlexWrapper>
      <ButtonToggle
        type="button"
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
        onClick={() => toggleCategory('racing')}
        category="racing"
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </ButtonToggle>
      <ButtonToggle
        type="button"
        data-cy="sports-toggle-text"
        isActive={category === 'sport'}
        onClick={() => toggleCategory('sport')}
        category="sport"
      >
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </ButtonToggle>
    </FlexWrapper>
  );
}
