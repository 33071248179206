import * as betprofessor from './SignedOut.styles.betprofessor';
import * as betroyale from './SignedOut.styles.betroyale';
import * as goldenrush from './SignedOut.styles.goldenrush';
import * as juicybet from './SignedOut.styles.juicybet';
import * as puntcity from './SignedOut.styles.puntcity';
import * as questbet from './SignedOut.styles.questbet';
import * as vikingbet from './SignedOut.styles.vikingbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  goldenrush,
  juicybet,
  puntcity,
  questbet,
  vikingbet,
};
