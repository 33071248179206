import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  maxW: ['none', 'lg'],
  // Buttons
  '.chakra-button': {
    bg: 'gamma.500',
    color: 'gamma.900',
    overflow: 'hidden',
    pos: 'relative',
    zIndex: '1',
    borderColor: 'white',
    mb: '0.5',
    h: '10',

    _before: {
      boxSize: 'full',
      content: '""',
      left: '0',
      pos: 'absolute',
      top: '0',
      transition: 'inherit',
      zIndex: '-1',
    },

    _hover: {
      opacity: '0.6',
    },

    _active: {
      color: 'white',

      _before: {
        bg: 'beta.500',
      },
    },
  },

  // Control (field wrapper)
  '.chakra-form-control': {
    mb: '3',
    mt: '0',
  },

  // Label
  '.chakra-form__label': {
    color: 'white',
    fontsize: '2xs',
    fontWeight: 'semibold',
    mb: '1.5',
  },

  // Inputs
  '.chakra-input, .chakra-select': {
    bg: 'gamma.100',
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderLeftWidth: '0',
    borderBottomWidth: '1px',
    borderColor: 'gamma.700',
    borderRadius: 'lg',
    color: 'gamma.900',
    fontSize: 'xs',
    fontWeight: 'bold',
    px: '2.5',
    mb: '0.5',

    _focus: {
      bg: 'gamma.100',
    },
  },

  // Radios
  '.chakra-radio__input + span': {
    bg: 'gray.400',
    border: '2px',
    borderColor: 'gray.400',
    m: '0',

    _checked: {
      bg: 'gamma.500',
      borderColor: 'gamma.500',
    },
  },

  '.chakra-radio__label': {
    color: 'white',
  },

  // Addons
  '.chakra-input__left-addon': {
    bg: 'gamma.500',
    borderLeftRadius: 'lg',
    fontSize: 'sm',
    color: 'gamma.900',
    fontWeight: 'semibold',
  },
  '.chakra-input__left-addon + .chakra-input': {
    borderLeftRadius: 'none',
  },

  // Helper text
  '.chakra-form__helper-text': {
    fontSize: 'xs',
    fontWeight: 'medium',
  },
};
