import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '1',
  mb: '2',
  px: ['2', '4'],
  top: ['0px', '9'],
  borderBottomRadius: 'base',
  position: 'initial',
};

export const ButtonFilter = () => ({
  color: 'beta.300',
});

export const Divider: CSSObject = {
  display: 'block',
  color: 'beta.300',
};

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'beta.300',
  color: selected ? 'alpha.700' : 'transparent',
  boxShadow: '0px 1px 1px 0px rgba(65, 22, 65, 0.50) inset',
  mr: '0.4',
});

export const TextFilterLabel: CSSObject = {
  color: 'inherit',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: '1',
};
