import { CSSObject } from '@chakra-ui/react';

const getBGColour = (state: 'inprogress' | 'prior' | 'ended') => {
  if (state === 'prior') {
    return 'blackAlpha.500';
  }
  return state === 'inprogress' ? 'beta.500' : 'alpha.500';
};

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  borderRadius: 'base',
  '&&': {
    bg: getBGColour(state),
    color: state === 'inprogress' ? 'blackAlpha.800' : 'white',
    fontFamily: 'body',
  },
  '&&&&': {
    px: 1,
  },
});

export const CountdownFlex: CSSObject = {
  bg: 'transparent',
  h: '4',
  p: '1',
  py: '3',
  borderRadius: 'base',
};
