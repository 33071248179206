import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgGradient: 'linear(180deg, beta.600, beta.700)',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  borderRadius: 'lg',
  border: 'none',
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  h: '12',
  fontFamily: 'Fredoka',
  fontSize: 'sm',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '3',
  pt: '0',
  mb: isKeypadVisible && 'unset',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const LowStakePrompt: CSSObject = {
  color: 'alpha.300',
  fontFamily: 'roboto',
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'beta.700',
  borderTop: '2px dashed',
  borderColor: 'blackAlpha.200',
  color: 'gamma.600',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  fontFamily: 'fredoka',
  color: 'white',
  fontSize: 'md',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  fontFamily: 'fredoka',
  color: 'alpha.400',
  fontSize: 'md',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  letterSpacing: 'wider',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.45)',
  color: 'blackAlpha.800',
  h: '12',
  w: 'full',
  fontSize: 'sm',
  fontWeight: 'extrabold',
  fontFamily: 'Fredoka',
  textTransform: 'uppercase',
  sx: {
    _disabled: {
      boxShadow: 'none',
      border: 'none',
    },
    _hover: {
      bg: 'alpha.600',
      boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
      border: 'none',
    },
    _active: {
      color: 'alpha.300',
      textShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.45)',
      bg: 'alpha.800',
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    },
  },
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'solid',
  h: '6',
  margin: 'auto',
  mt: '-23px',
  w: '75px',
  sx: {
    '.chakra-button__icon': {
      marginLeft: '-1',
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&&': {
      fontSize: '2xs',
    },
  },
};
