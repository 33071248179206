import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';
import { TBetForRace } from '@/api/racing/bets/bets.types';
import IconSvg from '@/components/common/IconSvg';
import { getIconBySportName, centsToDollars } from '@/helpers/utils';
import { onRaceBetStyles } from '../styles/OnRaceBets.styles';
import { SUB_TABS_DISPLAY_NAME } from '@/components/TabBar/TabBar';
import { calculateCombosForToteMulti } from '@/views/races/bets/Exotics/services/Exotics.utils';
import { validateStake } from '@/components/Betslip/lib';

type TToteMultiBetProps = {
  bet: TBetForRace;
};

export const ToteMultiBet = ({ bet }: TToteMultiBetProps) => {
  const { venueName } = useParams();
  const {
    proposition_info: propositionInfo,
    runner_selection: runnerSelection,
    tote_pool_legs: totePoolLegs,
    bet_stake: betStake,
  } = bet;
  const propInfo = propositionInfo?.[0];
  const { racingType } = useParams();
  const intl = useIntl();

  const combos = calculateCombosForToteMulti(runnerSelection as number[][]);
  const flexiUnmodified = ((betStake / 100 / combos) * 100).toFixed(2);
  const flexi = validateStake(flexiUnmodified);

  return (
    <Flex key={bet.bet_id} {...onRaceBetStyles.betRow}>
      <IconSvg
        name={getIconBySportName(racingType)}
        {...onRaceBetStyles.categoryIcon}
      />
      <Flex direction="column" flex="1">
        <Flex direction="row">
          <Text {...onRaceBetStyles.runnerText} flex="1">
            {SUB_TABS_DISPLAY_NAME[propInfo?.proposition_type]}
          </Text>
          <Text {...onRaceBetStyles.runnerText}>{propInfo?.name}</Text>
        </Flex>
        <Flex direction="row">
          <Text {...onRaceBetStyles.runnerText} flex="1">
            {`${venueName} Races ${totePoolLegs?.join(', ')}`}
          </Text>
          <Text {...onRaceBetStyles.runnerText}>{propInfo?.name}</Text>
        </Flex>
        {totePoolLegs?.map((leg, index) => (
          <Flex key={index}>
            <Text {...onRaceBetStyles.exoticsPositionText}>R{leg}:</Text>
            <Text as="span" {...onRaceBetStyles.subtitleText}>
              {runnerSelection[index].join(', ')}
            </Text>
          </Flex>
        ))}
        <Flex {...onRaceBetStyles.subtitleText}>
          <Text as="span" {...onRaceBetStyles.flexiStakeText}>
            {intl.formatMessage({ id: 'generic.flexi' })}:{' '}
          </Text>{' '}
          <Text {...onRaceBetStyles.flexiStakeValueText}>{flexi}%</Text>
        </Flex>
        <Flex {...onRaceBetStyles.subtitleText}>
          <Text as="span" {...onRaceBetStyles.flexiStakeText}>
            {intl.formatMessage({ id: 'generic.stake' })}:{' '}
          </Text>{' '}
          <Text {...onRaceBetStyles.flexiStakeValueText}>
            {centsToDollars(betStake)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
