import { css } from 'styled-components';

export const unverified = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--bc-sizes-1);
  `,
  textContainer: css``,
  text: css`
    color: ${({ theme }) => theme.basePalette.red};
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: var(--bc-fontSizes-md);
  `,
  iconContainer: css``,
  icon: css`
    height: 12px;
  `,
};

export const verified = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--bc-sizes-1);

    & > div {
      margin-top: 0;
      height: unset;
    }
  `,
  textContainer: css``,
  text: css`
    color: ${({ theme }) => theme.basePalette.green};
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: var(--bc-fontSizes-md);
  `,
  iconContainer: css``,
  icon: css`
    color: ${({ theme }) => theme.basePalette.green};
    height: 22px;
    width: 18px;
  `,
};

// TODO: Remove
export const dataRowTitle = css`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: var(--bc-fontSizes-md);
  color: ${({ theme }) => theme.basePalette.brightBlueB};
  white-space: nowrap;
`;

export const balance = {
  amount: css`
    font-size: var(--bc-fontSizes-md);
    color: ${({ theme }) => theme.basePalette.white};
    font-family: ${({ theme }) => theme.fontFamily.body};
    margin-left: var(--bc-sizes-2);
  `,
  depositButtonContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > div {
      margin-top: 0;
      height: unset;

      & > button {
        min-width: 72px;
      }
    }
  `,
};
