import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const CheckboxExclusion: CSSObject = {
  color: 'white',
  mb: '3',
  display: 'flex',
  alignItems: 'self-start',
  '& > input': {
    '&+span': {
      border: 'epsilon.400',
      borderWidth: '1px',
      bg: 'white',
      _hover: {
        bg: 'whiteAlpha.800',
        border: '1px solid',
        borderColor: 'whiteAlpha.900',
      },
    },
  },

  '&[data-checked] input': {
    '&+span': {
      color: 'gray.700',
      bg: 'white',
      border: 'epsilon.400',
      borderWidth: '1px',

      _hover: {
        bg: 'whiteAlpha.800',
        border: 'epsilon.400',
        borderWidth: '1px',
      },
    },
  },
};

export const FlexConfirmation: CSSObject = {
  bg: 'beta.800',
};

export const TextTitle: CSSObject = {
  color: 'white',
};

export const buttonSubmitExclusionProps: CustomButtonProps = {
  height: '38px',
  width: '100%',
  variant: 'solid',
};

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  height: '38px',
  variant: 'solid',
};
