import { CSSObject } from '@emotion/react';

export const TextTitle: CSSObject = {
  '&&': {
    mb: '2',
    zIndex: 'docked',
    fontSize: 'md',
    fontWeight: 'semibold',
    justifyContent: 'space-between',
    color: 'white',
  },
};

export const TextSpan: CSSObject = {
  flexWrap: 'wrap',
  fontFamily: 'unset',
  alignItems: 'center',
  fontWeight: 'medium',
};
