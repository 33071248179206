import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  justifyContent: [null, null, 'center'],
  form: {
    '.chakra-form-control': {
      '.chakra-form__label': {
        color: 'gray.500',
        fontSize: '12px',
      },
    },
    'a[href*="forgot-password"]': {
      color: 'alpha.400',
      fontWeight: 'medium',
    },
    '.chakra-button[data-cy*="loginSubmit"]': {
      borderRadius: 'md',
      border: '2px solid #A1741F',
      bgGradient: 'linear(180deg, #FACD33, #EC9E0F)',
      boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
      h: '38px',
      span: {
        color: 'beta.800',
        fontFamily: 'SkranjiBold',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
        lineHeight: 'normal',
      },
      _hover: {
        bgGradient: 'linear(180deg, #8C742F, #896B28)',
        boxShadow: '0px 0px 0px 2px #8B7746 inset',
        border: '2px',
        borderColor: '#775826',
      },
    },
    'button[aria-label*="password toggle"]': {
      top: '2px',
    },
  },
};

export const Title: CSSObject = {
  color: 'var(--bc-gray-500, #718096)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
  mb: '4',
  mt: [null, null, '9'],
};
