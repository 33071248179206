import { CSSObject } from '@chakra-ui/react';

export const NoResultsText: CSSObject = {
  color: 'white',
};

export const ButtonShowMore: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'CarterOne',
};
