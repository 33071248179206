import {
  Box,
  chakra,
  Flex,
  Grid,
  IconButton,
  Text as HeadingChakra,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './OnboardingLayout.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EOnboardingPageType } from '../OnboardingWrapper.types';

const themeName = getThemeName();

export const GridWrapper = chakra(Grid, {
  label: 'onboarding-GridWrapper',
  baseStyle: () => ({
    minW: '0px',

    ...(() => {
      try {
        return styleImports[themeName]?.GridWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export type TBoxContent = {
  pageType: EOnboardingPageType;
} & any;

export const BoxContent = chakra<TBoxContent>(Box, {
  label: 'onboarding-BoxContent',
  shouldForwardProp: (prop) => !['pageType'].includes(prop),
  baseStyle: ({ pageType }: TBoxContent) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxContent?.({
          pageType,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxAside = chakra(Box, {
  label: 'onboarding-BoxAside',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxAside;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Style that wraps the page to provide
 * a themed background image
 */
export const BGWrapper = chakra(Flex, {
  label: 'onboarding-BGWrapper',
  baseStyle: () => ({
    flex: 1,
  }),
});

/**
 * Style that wraps the content of the page
 */
export const ContentWrapper = chakra('section', {
  label: 'onboarding-ContentWrapper',
  baseStyle: () => ({
    flex: 1,
  }),
});

/**
 * Style that wraps the title of the page
 */
export const TitleWrapper = chakra('aside', {
  label: 'onboarding-TitleWrapper',
  baseStyle: () => ({
    flex: 1,
  }),
});

export const ContentContainer = chakra('section', {
  label: 'onboarding-ContentContainer',
  baseStyle: () => ({
    minHeight: ['85vh', null, 'auto'],
    flex: [null, null, '1'],
    display: 'flex',
    flexDirection: ['column', null, 'row'],
  }),
});

// Stops the content from stretching to stupid widths on wide screens.
// Needs separate component as background image needs to continue after the width restraint kicks in
export const WidthContainer = styled.div`
  ${({ theme }) => theme.device.tablet} {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
`;

export const Heading = chakra(HeadingChakra, {
  label: 'onboarding-Heading',
  baseStyle: () => ({
    fontWeight: 'bold',
    textTransform: 'lowercase',
    '::first-letter': {
      textTransform: 'uppercase',
    },
  }),
});

type TSubheading = {
  isLarger?: boolean;
} & any;

export const Subheading = chakra<TSubheading>(HeadingChakra, {
  label: 'onboarding-Subheading',
  shouldForwardProp: (prop) => !['isLarger'].includes(prop),
  baseStyle: ({ isLarger }: any) => ({
    maxWidth: ['70%', '100%'],
    width: [null, '280px'],
    fontSize: isLarger ? 'md' : 'sm',
    lineHeight: '6',
    mb: '6',
  }),
});

export const FormContent = chakra('section', {
  label: 'onboarding-FormContent',
  baseStyle: () => ({
    width: ['100%', null, '65%', '55%'],
    transition: 'width 0.1s ease',
  }),
});

export const IconCloseButton = chakra(IconButton, {
  label: 'onboarding-IconCloseButton',
  baseStyle: () => ({
    bg: 'white',
    borderRadius: 'full',
    p: '1',
    boxSize: '10',
    ...(() => {
      try {
        return styleImports[themeName]?.CloseButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
