import api from '../api';
import {
  TAddCardRequest,
  TCheckDepositResponse,
  TDepositData,
  TDepositLimit,
  TGetFatZebraToken,
  TMakeDepositData,
  TMakeDepositResponse,
  TPayData,
  TPayResponse,
  TResponseCard,
  TResponseCards,
} from './deposit.types';

export const queryFatZebraToken = () =>
  api
    .get<TGetFatZebraToken>('/punter/deposit/zebra-token')
    .then((res) => res.data);

export const queryCards = () =>
  api.get<TResponseCards>('/punter/deposit/card').then((res) => res.data);

export const queryDepositLimit = () =>
  api.get<TDepositLimit>('/punter/deposit/limit').then((res) => res.data);

export const mutateAddCard = (req: TAddCardRequest) =>
  api.post<TResponseCard>('/punter/deposit/card', req).then((res) => res.data);

export const mutateDeleteCard = (req: { id: string }) =>
  api
    .delete<void>('/punter/deposit/card', { data: req })
    .then((res) => res.data);

export const mutateCreateDeposit = (req: TDepositData) =>
  api
    .post<TCheckDepositResponse>('/punter/deposit/create', req)
    .then((res) => res.data);

export const mutateCompleteDeposit = (req: TPayData) =>
  api.post<TPayResponse>('/punter/deposit/pay', req).then((res) => res.data);

export const mutateCancelDeposit = (req: TMakeDepositData) =>
  api
    .post<TMakeDepositResponse>('/punter/deposit/update', req)
    .then((res) => res.data);
