import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    bg: 'white',
    borderColor: 'delta.700',
    boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)',
  },
  buttonCashOutDesc: {
    fontSize: '13px',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    color: 'gray.700',
  },
  buttonCashOutAmount: {
    color: 'white',
    bg: 'delta.700',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'white',
};

export const iconLegProps: CSSObject = {
  color: 'gamma.900',
  bg: 'gamma.500',
  borderColor: 'gamma.500',
};

export const RaceTime: CSSObject = {
  color: 'rgba(255, 255, 255, 0.48)',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.500',
  color: 'alpha.700',
};

export const BonusBetText: CSSObject = {
  color: 'beta.500',
};

export const BetReturn: CSSObject = {
  color: 'beta.500',
};

export const BoxBetReturnText: CSSObject = {
  fontSize: '2xs',
  borderLeftRadius: 'base',
  borderColor: 'beta.500',
  fontWeight: 'semibold',
  borderWidth: '1px',
  color: 'beta.100',
  pb: '1',
  pt: '1.5',
  px: '1',
};

export const BoxBetReturnValue: CSSObject = {
  fontSize: '2xs',
  borderRightRadius: 'base',
  ml: '-1',
  borderColor: 'beta.500',
  borderWidth: '1px',
  bg: 'beta.500',
  color: 'beta.800',
  pb: '1',
  pt: '1.5',
  px: '1',
};
