import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: ['center', null, null, 'flex-start'],
    my: '3',
    mx: 'auto',
    px: ['1.5', null, null, '0'],
    w: '100%',
    maxW: ['100dvw', null, '100%'],
    overflowX: 'auto',
    overflowY: 'hidden',
    gap: 1,
  },
  container: {
    display: 'contents',
  },
  racingBtnsContainer: {
    display: 'contents',
  },
  sportBtnsContainer: {
    display: 'contents',
  },
  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'alpha.400',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxSize: '4',
    mr: '0',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'alpha.400',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxSize: '4',
    mr: '0',
  },
  linkIconBox: {
    sx: {
      '&&': {
        flex: '1 0 0',
        gap: '1',
        h: '50px',
        display: {
          base: 'none',
        },
        _first: { display: 'flex' },
        overflow: 'hidden',
        borderRadius: 'md',
        boxShadow:
          '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
        bgGradient: 'linear(to-b, rgba(97, 116, 182, 1), rgba(68, 85, 144, 1))',
        _hover: {
          bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
          boxShadow:
            '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(64, 44, 3, 1) inset',
          'svg, [data-testid="home-links-header-sportsHeaderTile"]': {
            color: 'alpha.800',
            textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
          },
        },
        '&:nth-child(2), &:nth-child(3)': {
          display: { sm: 'flex' },
        },

        '&:nth-child(4), &:nth-child(5)': {
          display: { md: 'flex' },
        },

        '&:nth-child(6)': {
          display: { xl: 'flex' },
        },
      },
    },
  },
  linkBox: {
    sx: {
      '&&': {
        flex: '1 0 0',
        gap: '1',
        h: '50px',
        overflow: 'hidden',
        borderRadius: 'md',
        boxShadow:
          '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
        bgGradient: 'linear(to-b, rgba(97, 116, 182, 1), rgba(68, 85, 144, 1))',
        _hover: {
          bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
          boxShadow:
            '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(64, 44, 3, 1) inset',
          'svg, p': {
            color: 'alpha.800',
            textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
          },
        },
      },
    },
  },
  linkOverlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    w: '100%',
    gap: '2',
  },
  linkBoxText: {
    color: 'alpha.400',
    fontFamily: 'Staatliches',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '11px',
    px: 1,
    whiteSpace: 'nowrap',
    maxW: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  racingHeader: {
    display: 'none',
  },
  sportsHeader: {
    display: 'none',
  },
  sportsHeaderTile: {
    color: 'alpha.400',
    fontFamily: 'Staatliches',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'center',
    w: ['135%', null, '105%', null, null],
    lineHeight: '11px',
    px: 1,
    whiteSpace: 'nowrap',
    maxW: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  linkOverlayOverride: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    w: '100%',
  },
  sportIconOverride: {
    color: 'alpha.400',
    fontFamily: 'Staatliches',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
    w: '56px',
  },
};
