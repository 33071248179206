import React from 'react';
import { CSSObject, Box } from '@chakra-ui/react';
import { useSideNavList } from '../hooks/SideNav.hooks';

import DropDownContainer from '../components/DropDownContainer/DropDownContainer';
import SideNavLink from '../components/SideNavLink/SideNavLink';
import { navigationStyles } from './styles/Navigation.styles';
import ThemeNavigation from './templates/template';
import { createTemplate } from '@/helpers/createTemplate';

type TNavigation = {
  sx?: CSSObject;
};

export default function Navigation({ sx }: TNavigation) {
  const { nav, quickLinks } = useSideNavList();
  const template = createTemplate(ThemeNavigation);

  const nrNavLinks = nav.length;
  const nrQuickLinks = quickLinks.length;

  return (
    <Box {...navigationStyles.boxNavigationWrapperProps} as="nav">
      <Box {...navigationStyles.boxWrapperOuterProps}>
        <Box {...navigationStyles.boxWrapperProps}>
          <Box {...navigationStyles.navigationBoxProps} sx={sx}>
            {template}

            {nav.map((link, i) => {
              if (link.isDropDown)
                return (
                  <DropDownContainer
                    key={`${link.to}-${link.text}`}
                    sx={link.dropDownContainerSX}
                    isOpen={!!link.dropDownExpanded}
                    subs={link.subs}
                  >
                    <SideNavLink isLast={nrNavLinks === i + 1} link={link} />
                  </DropDownContainer>
                );

              return (
                <SideNavLink
                  isLast={nrNavLinks === i + 1}
                  key={`${link.to}-${link.text}`}
                  link={link}
                />
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box {...navigationStyles.boxWrapperOuterProps}>
        <Box {...navigationStyles.boxWrapperProps}>
          <Box {...navigationStyles.navigationBoxProps} sx={sx}>
            {quickLinks.map((link, i) => (
              <SideNavLink
                isLast={nrQuickLinks === i + 1}
                key={`${link.to}-${link.text}`}
                link={link}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
