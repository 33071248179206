import React from 'react';
import { Image, useMediaQuery } from '@chakra-ui/react';
import logoDesktop from '@/assets/chromabet/images/logo-footer.png';
import logoMb from '@/assets/chromabet/images/logo.png';

function LogoImage() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Image
      src={isMobile ? logoMb : logoDesktop}
      mt="3"
      mb="2"
      width={isMobile ? '42%' : '28%'}
    />
  );
}

export default function Index() {
  return <LogoImage />;
}
