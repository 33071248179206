import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
};

export const RunnerDescription: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const BetOdds: CSSObject = {
  color: 'white',
};

export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'green.500';
      case 'pending':
        return 'orange.400';
      default:
        return 'gray.300';
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'white';
      case 'pending':
        return 'orange.800';
      default:
        return 'gray.600';
    }
  },
  py: '0',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
});

export const HeaderSvg: CSSObject = {
  color: 'beta.500',
  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
  boxSize: '5',
};

export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};

export const MultiIcon = () => (
  <IconSvg
    name="multi_betslip"
    className="svgIcon"
    color="beta.500"
    boxSize="5"
    mb="1.5"
  />
);

export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
};

export const headerRowStyles: HeaderRowSchema = {
  headerRowSvg: {
    fill: '#0070d7',
  },
};
