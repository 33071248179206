import { create } from 'zustand';

export type TToteMultiType =
  | 'DailyDouble'
  | 'EarlyQuaddie'
  | 'Quaddie'
  | 'RunningDouble'
  | 'Treble';

export type TTotoeMultiSelection = {
  raceNumber: string;
  selection: Set<number>;
};

type TQuaddiesStore = {
  selections: TTotoeMultiSelection[];
  propositionId: string;
  selectedQuaddieType: TToteMultiType | null;
  actions: {
    clear: () => void;
    clearSelections: () => void;
    setToteSelections: (
      propositionId: string,
      selectedQuaddieType: TToteMultiType,
      selections: TTotoeMultiSelection[]
    ) => void;
    updateSelections: (raceNumber: string, runnerNumber: number) => void;
  };
};

const useQuaddieStore = create<TQuaddiesStore>((set) => ({
  selections: [],
  selectedQuaddieType: null,
  propositionId: '',
  actions: {
    clear: () =>
      set({ selections: [], selectedQuaddieType: null, propositionId: '' }),
    setToteSelections: (propositionId, selectedQuaddieType, selections) => {
      set({ propositionId, selectedQuaddieType, selections });
    },
    clearSelections: () =>
      set((state) => {
        const { selections } = state;
        const newSelections = selections.map((selection) => ({
          ...selection,
          selection: new Set<number>(),
        }));
        return { selections: newSelections };
      }),
    updateSelections: (raceNumber, runnerNumber) =>
      set((state) => {
        const selection = state.selections.find(
          (s) => s.raceNumber === raceNumber
        );
        if (selection) {
          if (selection.selection.has(runnerNumber)) {
            selection.selection.delete(runnerNumber);
          } else {
            selection.selection.add(runnerNumber);
          }
        } else {
          return {
            ...state,
            selections: [
              ...state.selections,
              {
                raceNumber,
                selection: new Set([runnerNumber]),
              },
            ],
          };
        }
        return { ...state, selections: state.selections };
      }),
  },
}));

export const { actions } = useQuaddieStore.getState();

export const useIsToteMultiSelected = (
  raceNumber: string,
  runnerNumber: number
) => {
  const { selections } = useQuaddieStore();
  const selected = selections.find(
    (selection) => raceNumber === selection.raceNumber
  );
  return selected?.selection.has(runnerNumber);
};

export const useGetToteSelections = () => {
  const { selections } = useQuaddieStore();
  return selections;
};

export const useTotePropositionId = () => {
  const { propositionId } = useQuaddieStore();
  return propositionId;
};

export const useToteSelections = () => {
  const { selections } = useQuaddieStore();
  return selections;
};

export const getToteMultiForBetSubmission = () => useQuaddieStore.getState();
