import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    _disabled: {
      pt: '2.5',
      pb: '2',
      bg: 'delta.400',
      fontFamily: 'Roboto',
      color: 'delta.50',
      textTransform: 'uppercase',
      boxShadow: 'none',
      fontSize: 'xs',
      opacity: '1',
      borderRadius: 'none',
      border: 'none',
      '&&': {
        h: '6',
      },
      _hover: {
        bg: 'gray.300',
        color: 'gray.500',
      },
    },

    fontSize: '2xs',
    h: 'auto',
    p: '2',
    bg: 'none',
    color: 'epsilon.300',
    fontFamily: 'Ultra',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    border: '2px solid',
    borderColor: 'epsilon.500',
    bgGradient: 'linear(to-b,delta.300, delta.600)',
    _after: {
      bg: 'none',
      boxShadow: 'none',
      border: 'none',
    },
    _hover: {
      bg: 'delta.300',
      color: 'delta.500',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
      boxShadow:
        '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    },
  },
};

export const FlexInner: CSSObject = {
  borderBottomColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  minH: '12',
  py: '2',
};

export const TextMonth: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
};
