import { css } from 'styled-components';

export const textInput = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-md);
  color: ${({ theme }) => theme.basePalette.darkGrey};

  flex-grow: 1;
  border: none;
  outline: none;
  background: none;

  &::-webkit-input-placeholder {
    /* Edge */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
  }

  &::placeholder {
  }
`;

export const disabled = css``;

export const container = css`
  margin-bottom: var(--bc-sizes-4);
  min-height: 50px;
`;

export const containerNoMargin = css`
  min-height: 50px;
`;

// TODO: Remove
export const wrapper = css`
  background: ${({ theme }) => theme.palette.inputBg};
  border: ${({ theme }) => theme.borderStyles.tertiary};
  border-radius: 7px;
  height: 33px;
  overflow: hidden;
  align-items: center;
`;

export const passwordPeek = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--bc-sizes-2);
  width: 20px;
  height: 15px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.basePalette.brightBlue};
  }
`;

export const hidePasswordLine = css`
  background: ${({ theme }) => theme.basePalette.brightBlue};
`;

export const label = css`
  color: ${({ theme }) => theme.palette.primary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.M};
`;

export const labelErrorState = css``;

export const error = {
  state: css``,
  label: css`
    color: ${({ theme }) => theme.palette.warning};
    font-size: var(--bc-fontSizes-xs);
    padding-top: var(--bc-sizes-1);
    font-family: ${({ theme }) => theme.fontFamily.body};
    align-items: center;
  `,
  icon: css`
    background-size: var(--bc-sizes-3) var(--bc-sizes-3);
    width: var(--bc-sizes-3);
    height: var(--bc-sizes-3);
    margin-right: var(--bc-sizes-1);
    color: ${({ theme }) => theme.palette.warning};
  `,
};

export const prefix = css`
  font-size: var(--bc-fontSizes-md);
  color: ${({ theme }) => theme.basePalette.darkGrey};
  background: ${({ theme }) => theme.palette.inputBg};
  padding-left: 10px;
  margin-right: -var(--bc-sizes-2);
`;
