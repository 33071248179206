import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  bgGradient: 'linear(180deg, beta.600, beta.400)',
  alignItems: 'center',
  px: '6',
  borderBottom: '4px',
  borderBottomColor: 'beta.500',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
};
