export const en = {
  'sports.allsports.azheading': 'A-Z Sports',
  'error.heading': 'Beach closed',
  'maintenance.heading': 'Beach closed',
  'maintenance.error':
    '<bold> We’ve encountered an error </bold> {br} Refresh the page, or try again later.',
  'onboarding.locationnotallowed.header': 'Location not available',
  'account.settings.responsible.lastupdated': 'Published: 20/02/2023',
  'onboarding.step1.pageTitle': "Let's get you started.",
  'onboarding.step1.pageSubtitle':
    'WellBet, the ultimate destination for racing and sports betting. Create an account and start placing bets now!',
  'onboarding.login.subtitle':
    "WellBet, the ultimate destination for racing and sports betting. Let's sign you back in so you can <b>start placing bets now!</b>",
  'generic.showmore': 'See All',
};
