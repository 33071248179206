import { CardSchema } from './Card.styles';

export const cardStyles: CardSchema = {
  wrapper: {
    mb: '2',
    minH: '90px',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    boxShadow:
      '0px 3px 10px 0px rgba(0, 0, 0, 0.30), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px 3px 0px 0px #203A4E',
  },
  wrapperContent: {
    display: 'grid',
    gridAutoRows: 'auto',
    gridTemplateColumns: ' 1fr auto',
    gridTemplateRows: ' 1fr',
    gap: ' 0px 0px',
    gridTemplateAreas: `
      ". ."
    `,
  },

  linkHeader: {
    display: 'flex',
    py: '2',
    px: '2',
    gap: '1',
    alignItems: 'center',
    textDecoration: 'none',

    _hover: { textDecor: 'none' },
  },
  headerIcon: {
    color: 'white',
    boxSize: '6',
  },
  headerRaceName: {
    fontWeight: 'semibold',
    fontSize: 'sm',
    color: 'white',
    mr: 'auto',
  },

  raceIndicators: {
    gap: '1',
    mb: '0.5',
    pr: '0.5',
  },
  listItem: {
    alignItems: 'center',
    p: '1.5',
    borderTop: '1px dashed',
    borderTopColor: 'blackAlpha.300',
    pr: '2.5',

    _odd: {
      pl: '3',
    },

    sx: {
      ':nth-last-child(-n+2)': {
        borderBottom: 'none',
      },
    },
  },

  silk: {
    h: '6',
    objectFit: 'contain',
    bg: 'white',
    borderRadius: 'base',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.30)',
    mr: '2.5',

    sx: {
      aspectRatio: '1/1',
    },
  },

  runner: {
    textTransform: 'capitalize',
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'xs',
    mb: '0.5',

    sx: {
      '[data-isScratched="true"] &': {
        textDecor: 'line-through',
      },

      span: {
        '&:nth-child(2)': {
          fontSize: '2xs',
          pos: 'relative',
          top: '-0.5',
        },
      },
    },
  },

  scratched: {
    fontSize: '3xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'full',
    color: 'white',
    lineHeight: '34px', // matches the height of button
  },

  btn: {
    fontSize: '15px',
    fontFamily: 'Fredoka',
    h: '34px',
    w: 'full',
    maxW: '52px',
  },
};
