import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: selected ? 'beta.500' : 'blackAlpha.400',
  mt: '1',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  borderLeftRadius: index === 0 ? 'md' : '0',
  pl: index === 0 ? '0.5' : '0',
  borderRightRadius: index === numberOfSlides - 1 ? 'md' : '0',
  pr: index === numberOfSlides - 1 ? '0.5' : '0',
});
