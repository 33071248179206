export const colors = {
  alpha: {
    100: '#FFCB45',
    200: '#FBC915',
    300: '#FFAE42',
    400: '#FF9F1C',
    500: '#FF9300',
    600: '#FB8721',
    700: '#F76203',
    800: '#7B4700',
    900: '#6D2600',
  },
  beta: {
    100: '#4EB2FF',
    200: '#45A8F4',
    300: '#468DC1',
    400: '#316993',
    500: '#375469',
    600: '#283F50',
    700: '#1E2F3C',
    800: '#16222C',
    900: '#171D20',
  },
  gamma: {
    100: '#FFF1DC',
    200: '#FFFFFF',
    300: '#B2B2B2',
    400: '#989797',
    500: '#7D7C7C',
    600: '#626161',
    700: '#4F4E4E',
    800: '#3D3C3C',
    900: '#262525',
  },
};
