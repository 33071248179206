import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'accent',
  fontWeight: 'bold',
};

export const LinkItem: CSSObject = {
  color: 'white',
  bgGradient: [
    'linear(to-b, alpha.100, alpha.400)',
    null,
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  ],
  border: 'none',
  borderRadius: 'md',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  h: '36px',
  mb: '1',
  _last: { mb: '0' },

  _hover: { bg: 'alpha.600' },
};

export const IconSport: CSSObject = {
  color: 'beta.500',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'white',
};

export const TextName: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
