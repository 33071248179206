import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  footer: {
    pt: '0',
    bg: 'transparent',
    bgSize: 'cover',
    bgPos: 'bottom',
    color: 'white',
  },
  stackGap: '1',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    pr: ['0', null, '3'],
    mb: '8',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
    sx: {
      '& > img': {
        maxW: '250px',
        h: '80px',
        ml: '0',
      },
    },
  },
  footerLink: {
    color: 'gamma.400',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  footerLinkTel: {
    color: 'gamma.400',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  footerLinkService: {
    color: 'gamma.400',
    fontSize: 'md',
  },
  customFooterLink: {
    color: 'gamma.400',
    textTransform: 'capitalize',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: 'base',
  },
  footerLinks: {
    ml: ['unset', null, 'auto'],
    pb: ['4', null, '0'],
    alignItems: 'center',
    justifyContent: ['space-around', null, 'unset'],
    color: 'gamma.400',
    fontWeight: 'medium',
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
    mt: ['3', null],
  },
  footerContentContainer: {
    px: ['2.5', 'unset'],
    gap: '6',
    bg: [null, null, 'transparent'],
    justifyContent: 'space-between',
    sx: {
      '&': {
        flexDirection: ['column', null, 'column'],
      },
    },
  },
  footerDownloadImage: {
    borderRadius: 'md',
    border: '2px solid white',
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.30)',
  },
  nav: {
    fontSize: 'sm',
    fontWeight: 'medium',
    minW: [null, null, '370px'],
    mt: ['6', 'unset'],
    display: ['flex', 'grid'],
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '28',
    rowGap: '3',
    alignItems: ['center', 'end'],
    gridTemplateAreas: `
      ". ."
    `,

    sx: {
      '> a': {
        color: 'white',
      },
    },
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', 'left'],
    color: 'white',
    width: 'unset',
    mb: '0',
    ml: [null, '0'],
    mr: [null, null, '4'],
  },
  blurbHeading: {
    ml: 'unset',
    mt: 'unset',
    color: 'white',
  },
  blurbHeadingAge: {
    color: 'white',
    border: '4px',
    mb: '2',
    boxSize: '8',
    fontSize: 'xs',
    lineHeight: 'normal',
  },
  blurbHeadingThink: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    mb: '4',
  },
  blurbContent: {
    ml: 'unset',
    mr: [null, null, 'auto'],
    color: 'white',
  },
  copyright: {
    color: 'white',
    textAlign: ['center', 'left'],
    mt: ['7'],
    px: ['8', 'unset'],
    py: '0',
    fontWeight: ['semibold', 'medium'],
  },
  copyrightContainer: {
    color: 'white',
  },
  container: {
    bg: 'transparent',
    borderRadius: ['0', '0', '8'],
    p: '4',
    mt: '3',
  },
  emailUs: {
    fontSize: 'md',
    fontWeight: 'bold',
    color: 'white',
  },
  quickLinks: {
    color: ['gamma.400', 'white'],
    textTransform: ['uppercase', 'none'],
    textAlign: ['center', 'left'],
    fontSize: ['xs', 'md'],
  },
  ourPartners: {
    fontSize: ['xs', 'md'],
    color: ['gamma.400', 'white'],
    textTransform: ['uppercase', 'none'],
  },
  weAccept: {
    fontSize: ['xs', 'md'],
    color: ['gamma.400', 'white'],
    textTransform: ['uppercase', 'none'],
  },
  partnerWrapper: {
    mr: 'unset',
  },
};
