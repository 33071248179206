import { Icon, Menu as ChakraMenu, MenuButton, Portal } from '@chakra-ui/react';

import React, { ReactNode } from 'react';
import { ChevronDown } from '@styled-icons/entypo/ChevronDown';
import { useNavigate } from 'react-router-dom';
import { MenuList, MenuItem, TextTitle, TextSpan } from './styles/Menu.styles';
import { useMatchDetails } from '../../services/MarketsByMatch.hooks';

type TMenu = {
  children: ReactNode;
};

export default function Menu({ children }: TMenu) {
  const { data, dataMatches } = useMatchDetails();
  const otherMatches = dataMatches?.filter(
    (m) => m.match_id !== data?.match_id
  );
  const hasOtherMatches = !!otherMatches?.length;
  const navigate = useNavigate();

  return (
    <ChakraMenu placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={TextTitle}
            sx={{
              display: 'inline-flex',
              ...(hasOtherMatches && {
                cursor: 'pointer',
              }),
            }}
          >
            <TextSpan as="span">
              {children}
              {hasOtherMatches && (
                <Icon
                  as={ChevronDown}
                  sx={{
                    boxSize: '6',
                    ml: '0.5',
                    ...(isOpen && { transform: 'rotate(180deg)' }),
                  }}
                />
              )}
            </TextSpan>
          </MenuButton>

          {hasOtherMatches && (
            <Portal>
              <MenuList>
                {otherMatches?.map((m) => {
                  const compOrTournamentParam = m.tournament_id
                    ? `tournamentId=${m.tournament_id}`
                    : `competitionId=${m.competition_id}`;

                  return (
                    <MenuItem
                      key={`menu-${m.match_id}`}
                      onClick={() =>
                        navigate(
                          `/Sports/${encodeURIComponent(
                            m.sport_name ?? ''
                          )}/${encodeURIComponent(
                            (m.competition_name || m.tournament_name) ?? ''
                          )}/${encodeURIComponent(
                            m.match_name ?? ''
                          )}?sportId=${
                            m.sport_id
                          }&${compOrTournamentParam}&matchId=${m.match_id}`
                        )
                      }
                    >
                      {m.match_name}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Portal>
          )}
        </>
      )}
    </ChakraMenu>
  );
}
