import {
  Box,
  ButtonGroup,
  ButtonGroupProps,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import React, {
  Attributes,
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { scrollButtonGroupStyles } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export type TScrollButtonGroupProps = FlexProps & {
  isDisabled?: IconButtonProps['isDisabled'];
  xCoord?: number;
  variant?: ButtonGroupProps['variant'];
  hasIndicator?: boolean;
  hasScrollBtns?: boolean;
};

export default function ScrollButtonGroup({
  children,
  isDisabled,
  variant,
  xCoord,
  hasIndicator,
  hasScrollBtns = true,
  ...rest
}: TScrollButtonGroupProps) {
  const ref = useRef<HTMLDivElement>(null);
  const refLoaded = useRef(false);

  /** Temp adding this to reduce HF scope */
  const isFalse = false;
  if (isFalse && typeof xCoord === 'number') {
    ref.current?.scroll({ left: xCoord, behavior: 'smooth' });
  }
  /** Temp adding this to reduce HF scope */

  /** Logic for scroll indicator */
  const [indicatorMeta, setIndicatorMeta] = useState({ left: 0, width: 0 });

  const handleTabIndicator = useCallback(
    (target?: HTMLElement) => {
      if (target) {
        const btn = target;
        const left = btn.offsetLeft;
        const { width } = btn?.getBoundingClientRect();
        const container = ref.current;
        const pdl = container?.querySelectorAll('button')?.[0]?.offsetLeft ?? 0;
        setIndicatorMeta({ left: left - pdl, width });
      }
    },
    [setIndicatorMeta]
  );
  /** Logic for scroll indicator */

  /** Logic for scrolling tabs into view */
  const scrollBtnToView = (ele: HTMLElement) => {
    const container = ref.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const btnOffsetLeft = ele.offsetLeft;
    const btnCenterPos = btnOffsetLeft + ele.offsetWidth / 2;
    const newPos = btnCenterPos - containerWidth / 2;

    container.scroll({
      left: Math.max(
        0,
        Math.min(newPos, container.scrollWidth - containerWidth)
      ),
      behavior: 'smooth',
    });
  };
  /** Logic for scrolling tabs into view */

  /** Logic onload */
  useLayoutEffect(() => {
    if (ref.current && refLoaded.current === false) {
      const activeElement = ref.current.querySelector(
        '[data-active]'
      ) as HTMLElement;

      if (activeElement) {
        scrollBtnToView(activeElement);

        if (!indicatorMeta.left && !indicatorMeta.width) {
          setTimeout(() => {
            handleTabIndicator(activeElement);
          }, 500);
        }
        refLoaded.current = true;
      }
    }
  }, [children, handleTabIndicator, indicatorMeta.left, indicatorMeta.width]);
  /** Logic onload */

  const handleClick = (action: 'SCROLL_LEFT' | 'SCROLL_RIGHT') => {
    const { scrollLeft = 0, clientWidth = 0 } = ref.current ?? {};
    const left =
      action === 'SCROLL_LEFT'
        ? scrollLeft - clientWidth
        : scrollLeft + clientWidth;

    ref.current?.scroll({ left, behavior: 'smooth' });
  };

  return (
    <Flex {...scrollButtonGroupStyles.flexWrapper} {...rest}>
      <ButtonGroup
        ref={ref}
        variant={variant}
        {...scrollButtonGroupStyles.buttonGroupSports}
        {...(!hasScrollBtns && { right: 0 })}
      >
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              ...child.props,
              onClick: (e: React.MouseEvent<HTMLElement>) => {
                if (typeof child.props.onClick === 'function') {
                  child.props.onClick(e);
                }
                scrollBtnToView(e.currentTarget as HTMLElement);
                handleTabIndicator(e.currentTarget as HTMLElement);
              },
              onMouseEnter: (e: React.MouseEvent<HTMLElement>) => {
                if (typeof child.props.onMouseEnter === 'function') {
                  child.props.onMouseEnter(e);
                }
                handleTabIndicator(e.currentTarget);
              },
              onMouseLeave: (e: React.MouseEvent<HTMLElement>) => {
                if (typeof child.props.onMouseLeave === 'function') {
                  child.props.onMouseLeave(e);
                }
                const btn = ref.current?.querySelector(
                  'button[data-active]'
                ) as HTMLButtonElement;

                handleTabIndicator(btn);
              },
            } as (Partial<any> & Attributes) | undefined);
          }
          return child;
        })}

        {hasIndicator && (
          <Box
            {...scrollButtonGroupStyles.scrollIndicator}
            {...indicatorMeta}
          />
        )}
      </ButtonGroup>
      <ButtonGroup
        variant={variant}
        {...scrollButtonGroupStyles.buttonGroupScrolls}
      >
        <IconButton
          aria-label="Scroll left"
          icon={<Icon {...scrollButtonGroupStyles.iconButtonScrollLeftSvg} />}
          isDisabled={isDisabled}
          onClick={() => handleClick('SCROLL_LEFT')}
          {...scrollButtonGroupStyles.iconButtonScrollLeft}
        />
        <IconButton
          aria-label="Scroll right"
          icon={<Icon {...scrollButtonGroupStyles.iconButtonScrollRightSvg} />}
          isDisabled={isDisabled}
          onClick={() => handleClick('SCROLL_RIGHT')}
          {...scrollButtonGroupStyles.iconButtonScrollRight}
        />
      </ButtonGroup>
    </Flex>
  );
}
