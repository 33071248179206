import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  px: '2',
  border: 'none',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  borderRadius: 'base',
  mb: '2',
  flex: '1',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const TypeItem: CSSObject = {
  color: 'whiteAlpha.600',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'whiteAlpha.800',
  fontSize: 'sm',
};

export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: ['base', null, 'md'],
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'blackAlpha.200',
  _last: {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};
