/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Box, RadioGroup } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getStrings } from '@/helpers/utils';
import Select from '../../FormElements/Select';
import {
  DepositLimitContainer,
  BodyText,
  RadioText,
  RadioButtonWrapper,
  RadioLimitChoice,
  ButtonSubmit,
  inputProps,
} from '../../../views/onboarding/DepositLimit/styles/DepositLimit.styles';
import depositLimitSchema, {
  DepositLimitSchema,
  depositLimitSchemaNoLimit,
} from '../../../views/onboarding/services/schemas/depositLimitSchema';
import Input from '../../FormElements/Input';
import { AppDispatch } from '@/redux/store';
import { setDepositLimit } from '@/views/onboarding/services/onboarding.actions';

/**
 * Main container for the deposit limit view
 */
export default function ContentContainer() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleFormSubmit = async (
    values: DepositLimitSchema,
    { setSubmitting }: FormikHelpers<DepositLimitSchema>
  ) => {
    setSubmitting(true);

    const punter = await dispatch(setDepositLimit(values)).unwrap();

    if (punter.is_onboarded) navigate('/account/deposit');

    setSubmitting(false);
  };

  return <DepositLimitForm handleSubmit={handleFormSubmit} />;
}

/**
 * Content for the deposit limit screen (form)
 */
export type OptionsSchema = {
  value: string;
  label: string;
  id: string;
};

type ContentProps = {
  handleSubmit: (...props: any) => void;
  extendOptions?: OptionsSchema[];
  options?: OptionsSchema[];
  hidePeriod?: boolean;
};

export function DepositLimitForm({
  handleSubmit,
  extendOptions = [],
  options,
  hidePeriod = false,
}: ContentProps) {
  const [
    {
      Account: {
        DepositLimit: { SetLimit },
      },
      Onboarding: { Step3 },
      Generic,
    },
  ] = getStrings();

  const _options: OptionsSchema[] = options || [
    { value: 'false', label: Step3.RadioOption1, id: 'depositLimitFalse' },
    { value: 'true', label: Step3.RadioOption2, id: 'depositLimitTrue' },
    ...extendOptions,
  ];

  return (
    <>
      <Formik
        initialValues={{
          depositLimit: '',
          limitAmount: '',
          ...(!hidePeriod ? { limitDuration: '' } : {}),
        }}
        validationSchema={(() => {
          if (hidePeriod) return depositLimitSchemaNoLimit;
          return depositLimitSchema;
        })()}
        onSubmit={handleSubmit}
      >
        {({ setValues, setErrors, isSubmitting, values }) => (
          <Form>
            <BodyText heading>{SetLimit.subheading}</BodyText>

            <BodyText>{SetLimit.subheading2}</BodyText>

            <RadioGroup
              onChange={(v) => {
                setValues({ ...values, depositLimit: v });
                setErrors({});
              }}
              value={values.depositLimit}
            >
              <RadioButtonWrapper>
                {_options.map((opt) => (
                  <RadioLimitChoice
                    key={opt.id}
                    size="lg"
                    value={opt.value}
                    data-cy={opt.id}
                  >
                    <RadioText>{opt.label}</RadioText>
                  </RadioLimitChoice>
                ))}
              </RadioButtonWrapper>
            </RadioGroup>

            <Box mb="4">
              <DepositLimitContainer isActive={values.depositLimit === 'true'}>
                <Input
                  name="limitAmount"
                  label={Step3.LimitAmountInputLabel}
                  data-cy="depositLimitAmount"
                  InputLeftAddon="$"
                  {...inputProps}
                />
                {!hidePeriod && (
                  <Select
                    label={Step3.LimitPeriodInputLabel}
                    name="limitDuration"
                    data-cy="depositLimitDuration"
                    placeholder={Generic.SelectPlaceholder}
                  >
                    {Step3.LimitPeriodOptions.map((x) => (
                      <option value={x} key={x}>
                        {x}
                      </option>
                    ))}
                  </Select>
                )}
              </DepositLimitContainer>
            </Box>

            <ButtonSubmit
              type="submit"
              isDisabled={!values.depositLimit || isSubmitting}
              isLoading={isSubmitting}
              data-cy="depositLimitSubmit"
              isFullWidth
            >
              <FormattedMessage id="generic.submit" />
            </ButtonSubmit>
          </Form>
        )}
      </Formik>
    </>
  );
}
