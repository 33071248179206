import Icon from '@chakra-ui/icon';
import { Text } from '@chakra-ui/layout';
import React from 'react';
import { ChevronDown } from 'styled-icons/fa-solid';
import ThemeFilters from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeFilters, [
  {
    name: 'btnContent',
    component: (
      <>
        <Text>Next to race</Text>
        <Icon as={ChevronDown} />
      </>
    ),
  },
]);
