import { TextProps } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  bg: 'red.500',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  px: '2',
  py: '1',
  borderRadius: 'sm',
  mt: '1.5',

  sx: { svg: { top: 0 } },
};
