import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  alignSelf: 'center',
  mt: '0',
};

export const HeaderContainer: CSSObject = {
  pb: '2',
  pt: '4',
  px: '4',
};

export const HeaderText: CSSObject = {
  color: 'alpha.600',
  p: '0',
  textTransform: 'none',
};

export const CloseButton: CSSObject = {
  color: 'gray.700',
  right: '1',
  top: '2',
};

export const ContentContainer: CSSObject = {
  color: 'gray.600',
  pb: '4',
  pt: '1',
  px: '4',
};
