import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DefaultText: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  h: '7',
  w: '12',
};

export const BoxInputContainer: CSSObject = {
  mb: '0',
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const DepositContainer: CSSObject = {
  mb: '3',
};

export const TrashIcon: CSSObject = {
  boxSize: '10',
  p: '2',
  bg: 'transparent',
  borderRadius: 'base',
  color: 'gray.400',
};

export const FlexWrapper: CSSObject = {
  py: '3',
};

export const ButtonDeposit: CSSObject = {
  bg: 'alpha.500',

  '&:hover, &:focus': {
    bg: 'alpha.700',
    boxShadow: 'none',
  },
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: '#ECC94B',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'regular',
  lineHeight: '14.4px',
  color: 'white',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
};
