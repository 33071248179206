import {
  BoxProps,
  ButtonProps,
  FlexProps,
  IconProps,
  ImageProps,
  LinkProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './Favorites.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type FavoritesSchema = Partial<{
  wrapper: FlexProps;
  container: BoxProps;
  headerContainer: BoxProps;
  header: TextProps;
  subHeader: TextProps;
  linkHeader: LinkProps;
  headerIcon: IconProps;
  headerRaceName: TextProps;
  listItem: FlexProps;
  silk: ImageProps;
  runner: TextProps;
  scratched: TextProps;
  btn: ButtonProps;
}>;

export const favoriteStyles: FavoritesSchema = {
  wrapper: {
    'data-testid': 'home-favorites-wrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  container: {
    'data-testid': 'home-favorites-container',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.container;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerContainer: {
    'data-testid': 'home-favorites-headerContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.headerContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  header: {
    'data-testid': 'home-favorites-header',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.header;
      } catch (err) {
        return {};
      }
    })(),
  },
  subHeader: {
    'data-testid': 'home-favorites-subHeader',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.subHeader;
      } catch (err) {
        return {};
      }
    })(),
  },
  linkHeader: {
    'data-testid': 'home-favorites-linkHeader',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.linkHeader;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerIcon: {
    'data-testid': 'home-favorites-headerIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.headerIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerRaceName: {
    'data-testid': 'home-favorites-headerRaceName',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.headerRaceName;
      } catch (err) {
        return {};
      }
    })(),
  },
  listItem: {
    'data-testid': 'home-favorites-listItem',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.listItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  silk: {
    'data-testid': 'home-favorites-silk',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.silk;
      } catch (err) {
        return {};
      }
    })(),
  },
  runner: {
    'data-testid': 'home-favorites-runner',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.runner;
      } catch (err) {
        return {};
      }
    })(),
  },
  scratched: {
    'data-testid': 'home-favorites-scratched',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.scratched;
      } catch (err) {
        return {};
      }
    })(),
  },
  btn: {
    'data-testid': 'home-favorites-btn',
    ...(() => {
      try {
        return styleImports[themeName]?.favoriteStyles?.btn;
      } catch (err) {
        return {};
      }
    })(),
  },
};
