import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  fontFamily: 'CrimsonPro',
  color: 'white',
  fontSize: 'md',
  fontWeight: 'black',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'beta.600',
  minW: 'unset',
  bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  borderRadius: 'base',
  boxShadow:
    '0px 1px 2px 0px #f9f17a inset,  0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #f9f17a inset', // no colour token

  transition: 'all .3s ease-in-out',
  _hover: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    bgGradient: 'linear(to-b, alpha.300, alpha.600)',
  },
  _focus: {
    boxShadow:
      '0px 2px 3px rgba(0, 0, 0, 0.35), inset 0px 0px 3px rgba(255, 255, 255, 0.6), inset 0px 1px 0px rgba(255, 255, 255, 0.3);',
  },

  _after: {
    opacity: 0,
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'Roboto',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
