import { chakra, Flex, Icon, Text, CSSObject } from '@chakra-ui/react';
import { styleImports } from './RaceTypeAvailable.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type RaceTypeAvailableSchema = Partial<{
  raceTypeButton: CSSObject;
}>;

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    'data-testid': 'raceTypeAvailableStyles-raceTypeButton',
    variant: 'solid', 
    ...(styleImports?.[themeName]?.raceTypeAvailableStyles?.raceTypeButton ?? {}),
  },
};

export const FlexRaceAvailableWrapper = chakra(Flex, {
  baseStyle: () => ({
    label: 'raceTypeAvailable-FlexRaceAvailableWrapper',
    px: '4',
    color: 'gray.900',
    my: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexRaceAvailableWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconRaceAvailableInfo = chakra(Icon, {
  baseStyle: () => ({
    label: 'raceTypeAvailable-IconRaceAvailableInfo',
    mr: '1',
    boxSize: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.IconRaceAvailableInfo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRaceAvailability = chakra(Text, {
  baseStyle: () => ({
    label: 'raceTypeAvailable-TextRaceAvailability',
    fontSize: 'xs',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextRaceAvailability;
      } catch (err) {
        return {};
      }
    })(),
  }),
});