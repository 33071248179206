import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      border: '1px',
      bg: 'gray.100',
      borderRadius: 'md',
      outline: 'none',
      height: '38px',
      color: 'black',
      fontSize: 'sm',
      px: '3',
      boxShadow: 'none',

      backgroundColor: 'gray.50',
      borderColor: 'gray.200',
    },
  },
};
