import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'beta.500',
  },
  placeNumber: {
    fontWeight: 'medium',
    fontSize: 'sm',
    color: 'beta.600',
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '2',
    pt: '2',
    _last: {
      borderBottom: 'none',
    },
  },
};
