const branding = {
  alpha: {
    50: '#C3E7FF',
    100: '#91D3FF',
    200: '#66C2FF',
    300: '#34AEFF',
    400: '#1AA5FF',
    500: '#2C3CCA',
    600: '#00578A',
    700: '#0D4C77',
    800: '#0A486B',
    900: '#0C2448',
  },
  // greens
  beta: {
    50: '#FFF3B8',
    100: '#25B872',
    200: '#FFE45C',
    300: '#FFDD31',
    400: '#25B871',
    500: '#53F9A9',
    600: '#53F9A9',
    700: '#74500B',
    800: '#553A07',
    900: '#302104',
  },
  // greys
  epsilon: {
    50: '#FFF3B8',
    100: '#2C3A56',
    200: '#202A3E',
    300: '#FFDD31',
    400: '#EFC80C',
    500: '#53F9AC',
    600: '#3EbE80',
    700: '#354159',
    800: '#202A3E',
    900: '#2534BF',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  epsilon: branding.epsilon,
  gamma: {},
  theta: {},
  delta: {},
};
