import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'beta.800',
  fontSize: 'md',
  fontWeight: 'hairline',
  fontFamily: 'accent',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'body',
  pl: '0.5',
  color: 'beta.800',
  pos: 'relative',
  bottom: '0.5',
  fontWeight: 'bold',
};

export const GroupChevron: CSSObject = {
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  borderRadius: 'md',
  color: 'beta.500',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px var(--bc-colors-blackAlpha-50)',
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
  boxSize: '6',
  minW: 'unset',
  border: 'none',
  _focus: {
    bgGradient:
      'linear(to-b,var(--bc-colors-beta-900),var(--bc-colors-beta-900))',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  _hover: {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
    color: 'white',
  },
  '&&&': {
    '& > span, & > svg': { zIndex: 9, boxSize: '4' },
  },
  ':active, &[data-active="true"]': {
    bgGradient:
      'linear(to-b,var(--bc-colors-beta-900),var(--bc-colors-beta-900))',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
