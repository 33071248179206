import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  fontWeight: 'bold',
  fontFamily: 'body',
  px: 1,
  py: 0.5,
  borderRadius: 'base',
  height: '15px',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
  bgColor: () => {
    if (state === 'inprogress') {
      return 'alpha.500';
    }
    if (state === 'ended') {
      return 'red.500';
    }
    // 'prior'
    return 'alpha.700';
  },
  color: state === 'inprogress' ? 'beta.500' : 'white',
});

export const TextTimestamp: CSSObject = {
  fontSize: '2xs',
};

export const CountdownFlex: CSSObject = {
  bg: 'transparent',
  mr: '0.5',
};
