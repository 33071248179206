import { ReactElement } from 'react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG } from '@/constants/themeConfig';

/**
 * @description helper function to switch between two different versions of component classes.
 * @returns `to` version of the component if the theme is in the themes array,
 * else the `from` version will be returned
 * @param themes themes to switch to the new component
 * @param from component to switch from
 * @param to component to switch to
 * @example const Jsx = componentSwitch([EThemesOG.Sterlingparker], <ButtonSecondary />, <Button />);
 */
export const componentSwitch = (
  themes: EThemesOG[],
  from: ReactElement,
  to: ReactElement
): ReactElement => {
  const themeName = getThemeName();
  return themes.includes(themeName) ? to : from;
};
