import {
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  GridItem,
  GridItemProps,
  Link,
  LinkProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './MatchCard.styles.imports';
import IconSvg from '../../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export const MatchCardContainer = chakra(Link, {
  label: 'matchCard-MatchCardContainer',
  baseStyle: ({ theme }) => ({
    flexDirection: 'column',
    position: 'relative',
    cursor: 'pointer',
    textDecoration: 'unset',
    color: 'unset',
    borderRadius: 'md',
    transition: 'all 0.3s ease-in-out',
    width: 'full',
    m: '0',
    p: '0',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',

    '&:hover': {
      textDecoration: 'unset',
      bg: 'gray.200',
    },

    ...(theme.themeName === EThemes.Betgalaxy && {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '70px 1fr 40px',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `
        "icon matchInfo arrowIcon"
      `,
      w: '100%',
      bg: 'white',
      p: '1.5',
      mb: '2',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.MatchCardContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps>;

export const MatchName = chakra(Text, {
  label: 'matchCard-MatchName',
  baseStyle: ({ theme }) => ({
    noOfLines: '1',

    ...(theme.themeName === EThemes.Betgalaxy && {
      noOfLines: 'unset',
      fontWeight: 'semibold',
      fontSize: ['sm', null, 'md'],
      color: 'beta.800',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.MatchName;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

export const MatchDate = chakra(Text, {
  label: 'matchCard-MatchDate',
  baseStyle: ({ theme }) => ({
    display: 'flex',
    fontSize: 'xs',

    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'xs',
      fontWeight: 'semibold',
      color: 'beta.600',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.MatchDate;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

export const Icon = chakra(IconSvg, {
  label: 'matchCard-Icon',
  baseStyle: ({ theme }) => ({
    boxSize: '9',
    p: '1',
    ml: 'auto',

    ...(theme.themeName === EThemes.Betgalaxy && {
      ml: 'unset',
      color: 'alpha.500',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Icon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TeamsContainer = chakra(Flex, {
  label: 'matchCard-TeamsContainer',
  baseStyle: ({ theme }) => ({
    display: 'none',
    mt: '1',
    ...(theme.themeName === EThemes.Betgalaxy && {
      flexDir: 'row',
      flex: '1',
      display: 'flex',
      mt: '0',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TeamsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TeamContainer = chakra(Flex, {
  label: 'matchCard-TeamContainer',
  baseStyle: () => ({
    flexDir: 'column',
    h: '14',
    flex: '1',
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TeamItem = chakra(Button, {
  label: 'matchCard-TeamItem',
  baseStyle: ({ theme }) => ({
    flex: '1',
    flexDir: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    px: '2',
    py: '1',
    '& ~ &': {
      ml: '2',
    },

    border: '1px solid',
    borderRadius: 'base',
    cursor: 'pointer',

    _hover: {
      boxShadow: '0 1px 2px 1px black',
      transform: 'translateY(-3%)',
    },

    _active: {
      boxShadow: 'none',
      transform: 'none',
    },

    ...(theme.themeName === EThemes.Betgalaxy && {
      border: 'none',
      bg: 'yellow.400',
      '&&': { textColor: 'yellow.900', h: '8' },
      _hover: {
        boxShadow: '0 1px 2px 1px black',
        transform: 'translateY(-3%)',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TeamItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const TeamItemName = chakra('span', {
  label: 'matchCard-TeamItemName',
  baseStyle: ({ theme }) => ({
    color: 'white',
    fontSize: '2xs',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'sm',
      color: 'white',
      mb: '1.5',
      fontWeight: 'semibold',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TeamItemName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Versus = chakra(Box, {
  label: 'matchCard-Versus',
  baseStyle: ({ theme }) => ({
    display: 'none',
    ...(theme.themeName === EThemes.Betgalaxy && {
      display: 'flex',
      position: 'absolute',
      borderRadius: 'full',
      boxSize: '12',
      bg: 'yellow.600',
      border: '3px solid',
      borderColor: 'yellow.400',
      boxShadow: '0px 1px 20px 0px #00000080',
      color: 'yellow.900',
      left: '50%',
      transform: 'translate(-50%, 0)',
      top: '84px',

      alignItems: 'center',
      justifyContent: 'center',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Versus;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const VersusText = chakra(Text, {
  label: 'matchCard-VersusText',
  baseStyle: ({ theme }) => ({
    display: 'none',

    ...(theme.themeName === EThemes.Betgalaxy && {
      display: 'block',
      fontSize: 'md',
      fontWeight: 'semibold',
      color: 'yellow.900',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.VersusText;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

export const TeamItemOdds = chakra('span', {
  label: 'matchCard-TeamItemOdds',
  baseStyle: ({ theme }) => ({
    color: 'white',
    fontSize: '2xs',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'yellow.900',
      fontWeight: 'semibold',
      fontSize: 'sm',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TeamItemOdds;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconContainer = chakra(GridItem, {
  label: 'matchCard-IconContainer',
  baseStyle: () => ({
    bg: 'beta.500',
    borderRadius: 'full',
    alignItems: 'center',
    justifyContent: 'center',
    w: '12',
    h: '12',
    m: '2',
    p: '1.5',

    ...(() => {
      try {
        return styleImports[themeName]?.IconContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', GridItemProps>;

export const BodyContainer = chakra(Flex, {
  label: 'matchCard-BodyContainer',
  baseStyle: ({ theme }) => ({
    w: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',

    ...(theme.themeName === EThemes.Betgalaxy && {
      py: '1',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BodyContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const MatchDetails = chakra(Flex, {
  label: 'matchCard-MatchDetails',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',

    ...(theme.themeName === EThemes.Betgalaxy && {
      py: '0',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.MatchDetails;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ArrowIcon = chakra(Icon, {
  label: 'matchCard-ArrowIcon',
  baseStyle: ({ theme }) => ({
    boxSize: '10',
    py: '1',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'beta.800',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ArrowIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextSpanName = chakra(Text, {
  baseStyle: ({ isVS }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextSpanName?.(isVS);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps & { isVS: boolean }>;

export const Seperator = chakra(Icon, {
  label: 'matchCard-Seperator',
  baseStyle: ({ theme }) => ({
    boxSize: '7',
    mx: '.5',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'gray.400',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Seperator;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const NameContainer = chakra(Flex, {
  label: 'matchCard-NameContainer',
  baseStyle: ({ theme }) => ({
    display: 'grid',
    w: '100%',
    rowGap: '2',
    alignItems: 'center',

    ...(theme.themeName === EThemes.Betgalaxy && {
      gridTemplateColumns: '70px 1fr 40px',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `
      "icon matchInfo arrowIcon"
    `,
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.NameContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const CompetitionName = chakra(Text, {
  label: 'matchCard-CompetitionName',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'xs',
      fontWeight: 'semibold',
      color: 'beta.600',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.CompetitionName;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;
