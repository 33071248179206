import { CSSObject } from '@chakra-ui/react';

export const FlexWrapper: CSSObject = {
  bg: 'alpha.400',
  mb: '4',
  borderBottom: '3px solid',
  borderColor: 'alpha.600',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  pt: '2.5',
  pb: '1',
  m: 'auto',
  h: '14',
  w: 'container.xl',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  '& > div:last-of-type': { mr: '0' },
};

export const LinkItem: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.30)',
  border: '2px solid',
  borderColor: 'delta.700',
  borderRadius: 'md',
  pt: '0.5',
  w: '40',
  h: '9',
  _hover: {
    bg: 'delta.300',
    'div[class*="FlexDetails"], p[class*="RaceNumber"],svg[class*="Icon"]': {
      color: 'delta.500',
    },
  },

  '.state-inprogress': {
    bg: 'epsilon.400',
    color: 'gamma.700',
    mr: '1',
    mt: '2',
    height: '4',
    borderRadius: 'base',
    maxW: '10',
    lineHeight: 'normal',
  },

  '.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    mr: '1',
    mt: '2',
    height: '4',
    borderRadius: 'base',
    maxW: '10',
    lineHeight: 'normal',
  },

  '.state-ended': {
    bg: 'delta.300',
    color: 'delta.800',
    mr: '1',
    mt: '2',
    height: '4',
    borderRadius: 'base',
    maxW: '10',
    lineHeight: 'normal',
  },
};

export const RaceNumber: CSSObject = {
  color: 'epsilon.300',
};
export const FlexDetails: CSSObject = {
  color: 'epsilon.300',
  display: 'block',
  maxW: '70px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'epsilon.300',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '0',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  w: '9',
  h: '9',
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.30)',
  border: '2px solid',
  borderColor: 'delta.700',
  borderRadius: 'md',
  color: 'epsilon.300',
  p: '0',

  '& > svg': { boxSize: '6' },

  _hover: {
    bg: 'delta.300',
    color: 'delta.500',
  },
};

export const CountdownTimer: CSSObject = {
  '.state-prior': {
    bg: 'gamma.600',
    color: 'gamma.100',
  },
};
