import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'white',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        bg: ['beta.700', null, null, 'unset'],
        bgGradient: [
          'unset',
          null,
          null,
          'linear(to-b, epsilon.400, epsilon.500)',
        ],
        boxShadow: 'lg',
        borderRadius: [null, null, null, 'md'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
      },
      drawer: {
        ...base.drawer,
      },
      drawerBody: {
        '> div, a': {
          ml: ['0', null, '0'],

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '22px',
        color: 'epsilon.700',
      },
      headerSX: {
        cursor: 'default',
        color: ['white', null, 'alpha.700'],
        height: '45px',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'epsilon.800',
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'base',
        mb: '1',
        boxShadow: '0px 4px 0px 0px #B68452',
      },
      link: {
        ...base.link,

        fontSize: 'lg',
        fontWeight: 'regular',
        fontFamily: 'PatrickHand',
        height: '43px',
        lineHeight: '1',
        color: 'epsilon.700',
        transition: 'all, 0.2s linear',
        borderBottom: '1px dashed',
        borderBottomColor: 'blackAlpha.300',
        p: '2',
        px: '3',
        ml: '0',

        '~ .nested': {
          pl: '10',
        },

        _hover: {
          bg: '#dab97b',
        },
        _active: {
          bg: 'epsilon.700',
          color: 'white',
          '.chakra-icon': {
            color: 'white',
          },
          '> .icon, .svgIcon, .dropDownIcon': {
            color: 'white',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },
        '.dropDownIcon': {
          fill: 'none',
        },
      },
      linkHeader: {
        mt: '1',
      },
      lastLink: {
        borderBottom: 'none',
      },
      closeButton: {
        alignItems: 'start',
      },
      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: '2',
        },

        '> a, .dropDownIcon, a': {
          color: 'epsilon.700',
          _hover: {
            color: '#dab97b',
          },
        },

        '&:hover, &:active': {
          '> .icon, .svgIcon, .dropDownIcon': {
            color: 'epsilon.800',
          },

          '.dropDownInner': {
            ...base.dropDownInner,
            color: 'epsilon.800',
          },

          '.svgIcon, .icon': {
            color: 'epsilon.800',
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        h: 'unset',
        p: '0',
        color: ['white', null, 'red.700'],

        _hover: {
          '.dropDownInner': {
            bg: 'epsilon.600',
          },
        },

        '.dropDownInner': {
          bg: 'transparent',
        },

        '.chakra-icon, .icon': {
          color: 'epsilon.700',
          fill: 'epsilon.700',
        },

        '> a, .dropDownIcon, a': {
          color: 'epsilon.700',
          fill: 'none',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'epsilon.700',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
