import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TabOptionProps } from '../../../../components/ToggleAccountView/ToggleAccountView';
import { ETransactionTabs } from '../Transactions.types';

export const useAccountTransactions = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type: ETransactionTabs = searchParams.get('type') as ETransactionTabs;

  const tabOptions = [
    {
      display: intl.formatMessage({
        id: 'account.transactions.transactions.pagetitle',
      }),
      value: ETransactionTabs.Transactions,
    },
    {
      display: intl.formatMessage({
        id: 'account.transactions.bonusbets.pagetitle',
      }),
      value: ETransactionTabs.BonusBets,
    },
    {
      display: intl.formatMessage({
        id: 'account.transactions.activitystatements.pagetitle',
      }),
      value: ETransactionTabs.ActivityStatements,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<
    TabOptionProps<ETransactionTabs>
  >(tabOptions.find((tab) => tab.value === type) ?? tabOptions[0]);

  useEffect(() => {
    navigate(`/account/transactions?type=${selectedTab.value.toLowerCase()}`);
  }, [navigate, selectedTab]);

  return {
    tabOptions,
    selectedTab,
    setSelectedTab,
  };
};
