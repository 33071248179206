import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  borderColor: 'gamma.500',
  borderWidth: '2px',
};

export const DividerCard: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.16)',
  borderWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.28)',
};

export const NoResultsText: CSSObject = {
  color: 'white',
};
