import {
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { styleImports } from '../../styles/Withdrawal.styles.imports';

const themeName = getThemeName();

export type TWithdrawalStatus = {
  status: EWithdrawalStatus;
} & any;

export const WithdrawalStatus = chakra<TWithdrawalStatus>(Box, {
  shouldForwardProp: (prop) => !['status'].includes(prop),
  baseStyle: ({ status }: TWithdrawalStatus) => ({
    borderRadius: 'sm',
    fontWeight: 'semibold',
    px: '4',
    py: '1',
    color: 'white',
    bg: () => {
      switch (status) {
        case EWithdrawalStatus.Cancelled:
          return 'gray.500';
        case EWithdrawalStatus.Error:
          return 'red.500';
        case EWithdrawalStatus.Pending:
          return 'yellow.500';
        case EWithdrawalStatus.Processed:
          return 'yellow.900';
        case EWithdrawalStatus.Rejected:
          return 'red.500';
        default:
          return 'green.500';
      }
    },
    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawalStatus?.({ status });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<
  'div',
  BoxProps & { status: EWithdrawalStatus | undefined }
>;

export const WithdrawalWrapper = chakra(Box, {
  label: 'requestWithdrawalCard-WithdrawalWrapper',
  baseStyle: () => ({
    w: 'full',
    bg: 'white',
    pb: '4',
    pt: '1',
    px: '2',
    my: '2',
    boxShadow: 'sm',
    borderRadius: 'md',
    border: '1px solid',
    borderColor: 'gray.200',

    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawalWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const buttonWithdrawalCardCancelProps: CustomButtonProps = {
  'data-testid': 'withdrawalCard-buttonWithdrawalCardCancelProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonWithdrawalCardCancelProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const ButtonWithdrawalCardCancel = chakra(Button, {
  label: 'ButtonWithdrawalCardCancel',
  baseStyle: () => ({
    fontSize: 'sm',
    p: '5',
    w: 'full',
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonWithdrawalCardCancel;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const WithdrawalCardContent = chakra(Box, {
  label: 'withdrawalCardContent',
  baseStyle: () => ({
    pb: '3',
    px: '1',
    w: 'full',
    borderRadius: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawalCardContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const WithdrawalCardButtonContainer = chakra(Box, {
  baseStyle: () => ({
    w: 'full',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const CardTitleWithdrawal = chakra(Flex, {
  baseStyle: () => ({
    w: 'full',
    pb: '2',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(() => {
      try {
        return styleImports[themeName]?.CardTitleWithdrawal;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const WithdrawalCardDetail = chakra(Flex, {
  baseStyle: () => ({
    w: 'full',
    py: '2',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'gray.300',
    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawalCardDetail;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const WithdrawalCardDetailHeading = chakra(Flex, {
  baseStyle: () => ({
    w: 'full',
    py: '2',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'gray.300',
    fontWeight: 'semibold',
    fontSize: 'md',
    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawalCardDetailHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const WithdrawalCardDetailRequested = chakra(Flex, {
  baseStyle: () => ({
    w: 'full',
    py: '2',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'gray.300',
    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawalCardDetailRequested;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const WithdrawalCardDetailNumber = chakra(Flex, {
  baseStyle: () => ({
    w: 'full',
    py: '2',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'gray.300',
    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawalCardDetailNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const WithdrawalFlexContainer = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
  }),
}) as ChakraComponent<'div', FlexProps>;

export const WithdrawalCardErrorContainer = chakra(Box, {
  baseStyle: () => ({
    color: 'red.500',
    border: '1px solid',
    borderColor: 'red.500',
    borderRadius: 'sm',
    p: '2',
    fontSize: 'sm',
    w: 'full',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const RemoveLink = chakra(Box, {
  baseStyle: () => ({
    w: 'full',
    bg: 'red.500',
    cursor: 'pointer',
    color: 'white',
    fontSize: 'md',
    textAlign: 'center',
    fontWeight: 'semibold',
    border: '1px solid',
    borderColor: 'red.500',
    boxShadow: 'sm',
    mt: '2',
    p: '3',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const WithdrawalCardHeading = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.withdrawalCardHeading};
`;

export const WithdrawalCardAmount = styled.p`
  ${({ theme }) => theme.pages.withdrawFunds.withdrawalCardAmount};
`;

export const WithdrawalCardFooter = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.withdrawalCardFooter};
`;

export const WithdrawalCardCancelledContainer = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.withdrawalCardCancelledContainer};
`;
