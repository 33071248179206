import { IconButton } from '@chakra-ui/button';
import React from 'react';
import { CloseCircle } from '@styled-icons/ionicons-outline';
import { useNavigate } from 'react-router';
import { closeBtn } from './styles/CloseBtn.styles';

export default function CloseBtn() {
  const navigate = useNavigate();

  return (
    <IconButton
      aria-label="close"
      icon={<CloseCircle />}
      {...closeBtn}
      onClick={() => navigate('/')}
    />
  );
}
