import volcanobetBg from '@/assets/volcanobet/images/backgrounds/evenshot/volcanobetBg.png';
import evenShot from '@/assets/volcanobet/images/backgrounds/evenshot/evenshot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    my: '4',
    fontWeight: 'bold',
    fontSize: 'lg',
    textTransform: 'capitalize',
  },
  flexWrapper: {
    mx: '4',
    mb: '4',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
    color: 'white',
    flexDir: 'column',
    py: '110px',
    px: '4',
    bgImage: `url(${volcanobetBg}), linear-gradient(180deg, #FBB903 0%, #FF7B03 100%)`,
    bgSize: 'cover',
    w: 'auto',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    color: 'gamma.800',
    textTransform: 'uppercase',
    fontSize: '5xl',
    fontFamily: 'Caveman',
    fontWeight: 'medium',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
  },
  addToBetslipButton: {
    variant: 'secondary',
  },
  loginToView: {
    variant: 'primary',
  },
  evenShotImage: {
    h: '180px',
    w: '180px',
    mt: '6',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  oddsValueSpan: {
    color: 'epsilon.400',
  },
  subHeadingText: {
    color: 'epsilon.400',
    fontSize: 'sm',
  },
  hasBetSubTitleText: {
    color: 'epsilon.400',
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'epsilon.400',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    mb: '1',
  },
  notAvailableText: {
    color: 'epsilon.400',
    fontSize: 'sm',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};
