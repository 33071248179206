import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  // wrapper: {
  //   mx: ['0', null, null, '-2.5'],
  //   mb: ['0', null, null, '-6'],
  //   bottom: ['15px', null, '0'],
  //   left: '0',
  //   position: ['fixed', null, null, 'sticky'],
  //   right: '0',
  //   zIndex: 'sticky',
  //   bg: 'beta.200',
  //   borderBottomRadius: 'md',
  // },
  wrapper: {
    mx: ['0', null, null, '-2.5'],
    mb: ['0', null, null, '-6'],
    bgGradient: 'linear(to-b, beta.400, beta.500)',
    bottom: '0',
    left: '0',
    position: ['fixed', null, null, 'sticky'],
    right: '0',
    zIndex: 'sticky',
    mt: '3',
  },
  slideBox: {
    color: 'white',
    borderTopWidth: '3px',
    borderTopColor: 'alpha.400',
    borderBottomRadius: 'md',
  },
  accordionItem: {
    borderTop: '3px',
    borderColor: 'beta.500',
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
    bgGradient: `linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))`,
  },
  accordionHeaderText: {
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
    gap: '1',
    px: '4',
    py: '3',
    color: 'alpha.400',
    borderTop: '3px',
    borderColor: 'whiteAlpha.400',
  },
  accordionButtonIcon: {
    fontSize: 'sm',
  },
  headerStack: {
    bg: 'alpha.400',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    pb: '2',
    pt: 2.5,
  },
  headerText: {
    flex: '1',
    color: 'beta.600',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
    sx: {
      svg: {
        color: 'beta.600',
      },
    },
  },
  accordionPanel: {
    fontSize: 'xs',
    overflow: 'auto',
    p: '0',
  },
  selectionFlex: {
    bg: 'beta.700',
    gap: '2',
    px: '4',
    py: '3',
    _notLast: {
      borderBottom: '1px dashed',
      borderColor: 'white',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
  },
  footerStack: {
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    py: '2.5',
    px: '4',
    pos: 'relative',
    borderTopColor: 'blackAlpha.300',
    borderBottomRadius: 'md',
    _before: {
      opacity: '0.2',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '4',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
  },
  legsText: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    fontWeight: 'extrabold',
    fontSize: '2xs',
  },
  legsChunks: {
    color: 'alpha.400',
    fontFamily: 'body',
    fontSize: 'xl',
    fontWeight: 'bold',
  },
  oddsText: {
    color: 'white',
    textTransform: 'uppercase',
    flex: '3',
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    fontSize: '2xs',
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'alpha.400',
    fontFamily: 'body',
    fontSize: 'xl',
    fontWeight: 'bold',
  },
  clearButton: {
    variant: 'outline',
    fontWeight: 'normal',
  },
  addToSlipButton: {
    variant: 'odds',
    fontSize: 'sm',
    px: ['1', '3'],
  },
};
