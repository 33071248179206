/* eslint-disable func-names */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView, trackUser } from '@/lib/analytics';
import { EVENT_NAMES, TAnalyticEventNames } from '@/lib/analytics/types';
import { useAuth } from './useAuth';

export const affiliateLocalStorageKey = 'bcAffiliate';
export const myAffiliatesLocalStorageKey = 'bcMyAffiliates';

function parseWindowQueryStrings() {
  const queryString = document?.location?.search ?? '';
  const query: Record<string, string> = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0]) as string] = decodeURIComponent(
      pair[1] || ''
    );
  }

  return query;
}

/*
  Stores data to send with every event 
 */
export function storeEventData() {
  // Store affiliate data
  const query = parseWindowQueryStrings();
  const affiliate = query?.affiliate;

  if (affiliate) {
    window.localStorage.setItem(affiliateLocalStorageKey, affiliate);
    window.localStorage.removeItem(myAffiliatesLocalStorageKey);
  }

  const token = query?.token;

  if (token) {
    window.localStorage.setItem(myAffiliatesLocalStorageKey, token);
    window.localStorage.removeItem(affiliateLocalStorageKey);
  }
}

/*
  Used to track page views via the location change in the react route state
 */
export const usePageAnalytics = () => {
  const { pathname } = useLocation();
  const { punterData } = useAuth();

  // Store the affiliate & event data if provided
  useEffect(() => storeEventData(), []);

  // Track the page view
  useEffect(() => trackPageView(pathname), [pathname]);

  // Track the user
  useEffect(
    () => trackUser(punterData?.punter_id, punterData?.email),
    [punterData?.punter_id, punterData?.email]
  );
};

export function isAnalyticsEvent(value: unknown): value is TAnalyticEventNames {
  return EVENT_NAMES.includes(value as TAnalyticEventNames);
}
