import { CSSObject } from '@chakra-ui/react';

import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  w: '12',
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: 'calc(100% + 2px)',
  maxH: 'calc(var(--bc-space-3) + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  borderTop: '1px solid rgba(255, 255, 255, 0.10)',
  borderRight: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
  background: 'beta.300',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'alpha.500',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'alpha.500',
  textShadow: 'none',
};
