import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = {
  bg: 'transparent',
  h: 'full',
  mt: '1px',
};

export const ButtonDropdown: CSSObject = {
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow:
    'inset 0px 0px 0px 1px var(--bc-colors-delta-300), 0px 2px 3px 0px rgba(0, 0, 0, 0.25), 0px -1px 0px 0px #55D7FF',
  color: 'rgba(0, 0, 0, 0.64)',
  borderColor: '#3AABCD',
  borderStyle: 'solid',
  borderWidth: '1px 2px 2px 2px',
  borderRadius: 'md',
  h: '28px',

  _hover: {
    bg: 'alpha.300',
    color: 'blackAlpha.700',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
    borderWidth: '1px 2px 2px 2px',
    borderColor: '#995737',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'rgba(0, 0, 0, 0.64)',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'rgba(0, 0, 0, 0.64)',
};
