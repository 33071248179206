import * as yup from 'yup';

import { getStrings } from '@/helpers/utils';

const [
  {
    Onboarding: { Step1 },
  },
] = getStrings();

const loginSchema = yup
  .object({
    email: yup
      .string()
      .trim()
      .email(Step1.EmailInputError)
      .required(Step1.EmailInputEmpty),
    password: yup.string().trim().required(Step1.PasswordEmpty),
  })
  .required();

export type LoginSchema = yup.InferType<typeof loginSchema>;

export default loginSchema;
