import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  pt: '1',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  color: 'white',
};

export const SmallHeading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  fontWeight: 'semibold',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const ImageGamblersHelp: CSSObject = {
  bg: 'white',
  p: '2',
  minW: '184px',
  w: '184px',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  my: '2',
};

export const Link: CSSObject = {
  color: 'gamma.300',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecoration: 'none',
  },
};

export const SubHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};

export const GridWrapper: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
  maxW: 'container.sm',
  pb: '0',
};

export const ButtonAction: CSSObject = {
  fontWeight: 'normal',
  pt: '1',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
  color: 'beta.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 4px rgba(255, 255, 255, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #FFD69E inset, 0px 0px 0px 3px #BD873E inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, yellow.600 0%, yellow.600 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
