import { CSSObject } from '@chakra-ui/react';

export const Subheading: CSSObject = {
  fontSize: 'xs',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const PageHeader: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
};

export const CancelButton: CSSObject = {
  textColor: 'alpha.600',
  letterSpacing: 'wider',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  bg: 'white',
  border: '2px',
  borderColor: 'alpha.600',
  '&:hover, &:focus': {
    bg: 'alpha.600',
    textColor: 'white',
  },
};

export const ButtonSubmit: CSSObject = {
  fontWeight: 'bold',
  letterSpacing: 'wider',
  textTransform: 'uppercase',
  bg: 'alpha.600',
  textColor: 'white',
  border: '2px',
  borderColor: 'alpha.600',
  '&:hover, &:focus': {
    bg: 'alpha.700',
    borderColor: 'alpha.700',
  },
};

export const DepositButton: CSSObject = {
  fontSize: 'xs',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'sm',
  bg: 'alpha.500',
  color: 'white',
  '&:hover, &:focus': {
    bg: 'alpha.800',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
  '&:disabled': {
    opacity: '0.5',
  },
};
