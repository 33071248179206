import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    position: [null, null, null, 'relative'],
    borderRadius: '10px',
    borderColor: 'beta.500',
    borderWidth: '3px',
    padding: '1.5',
    boxShadow:
      '0px 6px 20px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 6px rgba(80, 78, 243, 0.40) inset, 0px 0px 0px 3px rgba(0, 0, 0, 0.40) inset',
    background: 'transparent',
    backdropFilter: 'blur(4px)',
    mx: ['2', null, '0', null],
  },
  boxWrapperProps: {
    position: [null, null, null, 'relative'],
  },
  boxNavigationWrapperProps: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4',
    width: '100%',
    background: 'rgba(86, 215, 255, 0.10)',
  },
  navigationBoxProps: {
    boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.25)',
    borderTop: 'none',
    m: '0',
    bgImage: 'transparent',
    bg: 'transparent',

    sx: {
      ':first-child': {
        m: '0',
      },
    },
  },
};
