import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = {
  bg: 'transparent',
  h: 'full',
  mt: '1px',
};

export const ButtonDropdown: CSSObject = {
  bg: 'alpha.500',
  boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.30) inset',
  color: 'white',
  borderColor: 'beta.500',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: 'md',
  h: '28px',

  _hover: {
    bg: 'alpha.800',
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'white',
};
