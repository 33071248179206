import React, { ReactNode } from 'react';
import {
  Badge,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  chakra,
  ChakraComponent,
  Divider,
  DividerProps,
  Flex,
  Icon,
  IconButton as ChakraIconButton,
  IconButtonProps,
  IconProps,
  Image,
  Radio,
  RadioProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { TrashBin } from '@styled-icons/ionicons-sharp/TrashBin';
import { Bin } from '@styled-icons/icomoon/Bin';
import { Trash } from '@styled-icons/bootstrap';
import { ShieldFillCheck } from '@styled-icons/bootstrap/ShieldFillCheck';
import { DeleteBin2 } from '@styled-icons/remix-fill/DeleteBin2';
import { SecurePayment } from '@styled-icons/remix-line/SecurePayment';
import { styleImports } from './Form.styles.imports';
import { EThemesOG } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { CustomButtonProps } from '@/components/Button/Button';
import { TIndex } from '@/components/FormElements/Input';

const themeName = getThemeName();

type TTextRowDivider = {
  children: ReactNode;
};

export const FormBox = chakra(Box, {
  label: 'depositForm-FormBox',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FormBox;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BadgeWrapper = chakra(Badge, {
  label: 'depositForm-BadgeWrapper',
  baseStyle: () => ({
    fontSize: '10',
    bg: 'red.500',
    ml: '3',
    py: '1',
    px: '3',
    h: '5',
    borderRadius: '5',

    ...(() => {
      try {
        return styleImports[themeName]?.BadgeWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DefaultText = chakra(Text, {
  label: 'depositForm-DefaultText',
  baseStyle: () => ({
    fontSize: '18',
    fontWeight: 'bold',
    color: 'currentColor',

    ...(() => {
      try {
        return styleImports[themeName]?.DefaultText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ImageCardLogo = chakra(Image, {
  label: 'depositForm-ImageCardLogo',
  baseStyle: ({ isInRow }: any) => ({
    w: isInRow ? '9' : 'unset',
    ...(() => {
      try {
        return styleImports[themeName]?.ImageCardLogo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxInputContainer = chakra(Box, {
  label: 'depositForm-BoxInputContainer',
  baseStyle: () => ({
    mb: '8',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxInputContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxCardsContainer = chakra(Box, {
  label: 'depositForm-BoxCardsContainer',
  baseStyle: () => ({
    mb: '8',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxCardsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IncreaseAmountContainer = chakra(Flex, {
  label: 'depositForm-IncreaseAmountContainer',
  baseStyle: () => ({
    justifyContent: 'space-between',
    mb: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.IncreaseAmountContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const HeaderWrapper = chakra(Flex, {
  label: 'depositForm-HeaderWrapper',
  baseStyle: () => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
});

export const DepositContainer = chakra(Flex, {
  label: 'depositForm-DepositContainer',
  baseStyle: () => ({
    mb: '5',

    ...(() => {
      try {
        return styleImports[themeName]?.DepositContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TrashIcon = chakra(Icon, {
  label: 'depositForm-TrashIcon',
  baseStyle: () => ({
    boxSize: '5',

    ...(() => {
      try {
        return styleImports[themeName]?.TrashIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconButton = (props: IconButtonProps) => {
  const BaseIconButton: ChakraComponent<'button', IconButtonProps> = chakra(
    ChakraIconButton,
    {
      label: 'depositForm-IconButton',
      baseStyle: {
        color: 'currentColor',
        ml: 'auto',

        ...(() => {
          try {
            return styleImports[themeName]?.IconButton;
          } catch (err) {
            return {};
          }
        })(),
      },
    }
  );

  const trashIcon = () => {
    switch (themeName) {
      case EThemesOG.Wellbet:
        return TrashBin;
      case EThemesOG.Questbet:
        return Bin;
      case EThemesOG.Betroyale:
      case EThemesOG.Volcanobet:
      case EThemesOG.Fiestabet:
      case EThemesOG.Puntgenie:
      case EThemesOG.Sterlingparker:
      case EThemesOG.Sugarcastle:
      case EThemesOG.Oldgill:
        return DeleteBin2;
      default:
        return Trash;
    }
  };
  return <BaseIconButton {...props} icon={<TrashIcon as={trashIcon()} />} />;
};

export const RowDivider = () => {
  const BaseRowDivider: ChakraComponent<'hr', DividerProps> = chakra(Divider, {
    label: 'DepositFormRowDivider',
    baseStyle: {
      ...(() => {
        try {
          return styleImports[themeName]?.RowDivider;
        } catch (err) {
          return {};
        }
      })(),
    },
  });

  const variant = [EThemesOG.Wellbet, EThemesOG.Betroyale].includes(themeName)
    ? 'dashed'
    : 'solid';
  return <BaseRowDivider variant={variant} />;
};

export const TextRowDivider = ({ children }: TTextRowDivider) => (
  <Flex align="center">
    <RowDivider />
    <Text padding="2">{children}</Text>
    <RowDivider />
  </Flex>
);

export const RadioCard = (props: RadioProps) => {
  const BaseRadioCard: ChakraComponent<'input', RadioProps> = chakra(Radio, {
    label: 'depositForm-RadioCard',
    baseStyle: {
      ...(() => {
        try {
          return styleImports[themeName]?.RadioCard;
        } catch (err) {
          return {};
        }
      })(),
    },
  });

  const spacing = themeName === EThemesOG.Betroyale ? 4 : 2;
  return <BaseRadioCard spacing={spacing} {...props} />;
};

export const CardNumber = chakra(Flex, {
  label: 'depositForm-CardNumber',
  baseStyle: {
    alignItems: 'center',
    fontSize: '14',
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.CardNumber;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const TextLastFour: ChakraComponent<'p', TextProps> = chakra(Text, {
  label: 'depositForm-TextLastFour',
  baseStyle: {
    ml: 2,
    ...(() => {
      try {
        return styleImports[themeName]?.TextLastFour;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const TextMaxCards: ChakraComponent<'p', TextProps> = chakra(Text, {
  label: 'depositForm-TextMaxCards',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.TextMaxCards;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const TextSSL = chakra(Text, {
  label: 'depositForm-TextSSL',
  baseStyle: () => ({
    fontSize: '14',
    fontWeight: 'normal',
    textAlign: 'center',
    color: 'currentColor',
    mt: 2,

    ...(() => {
      try {
        return styleImports[themeName]?.TextSSL;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconSSL = (props: IconProps) => {
  const baseStyle = {
    label: 'depositForm-IconSSL',
    mx: 2,
    ...(() => {
      try {
        return styleImports[themeName]?.IconSSL;
      } catch (err) {
        return {};
      }
    })(),
  };

  const icon =
    themeName === EThemesOG.Betroyale || EThemesOG.Volcanobet
      ? ShieldFillCheck
      : SecurePayment;
  return <Icon {...baseStyle} as={icon} {...props} />;
};

type TFlexWrapper = {
  isSavedCard: boolean;
} & BoxProps &
  any;

export const FlexWrapper = chakra<TFlexWrapper>(Flex, {
  label: 'depositForm-FlexWrapper',
  shouldForwardProp: (prop) => !['isSavedCard'].includes(prop),
  baseStyle: ({ isSavedCard }: any) => ({
    py: '6',
    flexDirection: !isSavedCard && 'column',
    alignItems: !isSavedCard ? 'flex-start' : 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const bannerHeaderProps: TextProps = {
  label: 'form-bannerHeaderProps',
  lineHeight: '120%',

  ...styleImports?.[themeName]?.bannerHeaderProps,
};

export const bannerTextProps: TextProps = {
  label: 'form-bannerTextProps',
  lineHeight: '120%',

  ...styleImports?.[themeName]?.bannerTextProps,
};

export const buttonDepositProps: CustomButtonProps = {
  'data-testid': 'buttonDepositProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonDepositProps;
    } catch (err) {
      return {};
    }
  })(),
};

/**
 * @deprecated Use `buttonDepositProps` instead and add your theme in ButtonDepositSwitched
 */
export const ButtonDeposit = chakra(Button, {
  label: 'depositForm-ButtonDeposit',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonDeposit;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const depositInputProps: Partial<TIndex> = {
  'data-test-id': 'deposit-inputProps',
  ...(() => {
    try {
      return styleImports[themeName]?.depositInputProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonDepositsAvailableProps: CustomButtonProps = {
  'data-testid': 'form-buttonDepositsAvailableProps',
  mt: '2',

  ...styleImports[themeName]?.buttonDepositsAvailableProps,
};
