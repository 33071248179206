import { ButtonProps } from '@chakra-ui/react';

export const closeBtn: ButtonProps = {
  variant: 'unstyled',
  pos: 'absolute',
  top: [0, null, '30px'],
  right: [0, null, '34px'],
  boxSize: '10',
  color: 'gamma.300',

  sx: {
    svg: {
      boxSize: ['26px', null, '33px'],
    },
  },
};
