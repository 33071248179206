import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bg: 'beta.500',
  '&&': { px: '4', pb: ['1', null, '2'] },
  mt: ['unset', '2'],
  borderTopRadius: 'xl',
  overflow: 'visible',
  color: 'gamma.600',
  fontWeight: 'bold',
  fontSize: 'md',
  pb: '0',
  borderTop: '3px solid',
  borderLeft: '3px solid',
  borderRight: '3px solid',
  borderColor: 'beta.100',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontSize: 'md',
  textTransform: 'capitalize',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  mx: '2',
  mt: '2',
  mb: '1',
  borderRadius: [null, null, null, 'base'],
};

export const WrapperContainer: CSSObject = {
  px: '2',
  bg: 'beta.500',
  borderBottom: '3px solid',
  borderLeft: '3px solid',
  borderRight: '3px solid',
  borderBottomRadius: 'xl',
  borderColor: 'beta.100',
};
