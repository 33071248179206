import React from 'react';
import { Outlet } from 'react-router-dom';
import { useMaintenance } from '@/hooks/useMaintenance';

/**
 * Maintenance routes only allow users to access the route if the
 * relevant response from the contentful api shows the site is up and running.
 * If it is not, they will be redirected to the maintenance route.
 */
export default function MaintenanceRoute() {
  useMaintenance();
  return <Outlet />;
}
