import { CSSObject } from '@chakra-ui/react';
import background from '../../../../../../assets/goldenrush/images/banner/accountBanner.png';
import backgroundMB from '../../../../../../assets/goldenrush/images/banner/accountBannerMB.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    h: 8,
    fontSize: 'xs',
    px: 3,
    borderRadius: 'lg',
    lineHeight: 'unset',
  },
};

export const FlexBannerContainer: CSSObject = {
  bgImage: [`url(${backgroundMB})`, null, `url(${background})`],
  bgPos: [null, null, 'center'],
  bgRepeat: 'no-repeat',
  bgSize: ['100% 100%', null, '100%'],
  color: 'white',
  px: ['5', null, '5'],
  py: ['5', null, '7'],
  pt: ['5', null, '2'],
  pr: ['5', null, null],
  my: ['2', null, '0'],
  gap: ['2', null, '0'],
  position: 'relative',
  zIndex: '999',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1',
};

export const FlexAccountName: CSSObject = {
  fontSize: ['lg', null, 'xl'],
  fontFamily: 'Roboto',
  fontWeight: 'semibold',
  color: 'gamma.500',
  letterSpacing: '0.8px',
};

export const FlexAccountBalances: CSSObject = {
  gap: '5',
  paddingTop: '2.5',
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  mb: '0.5',
  textTransform: 'uppercase',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'beta.500',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: ['sm', null, 'xs'],
  verticalAlign: 'super',
  color: 'gamma.500',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: 'xl',
  color: 'gamma.500',
  fontWeight: 'extrabold',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: 'lg',
  opacity: '0.7',
  color: 'gamma.500',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2.5',
};

export const TextButton: CSSObject = {
  h: '4',
  fontSize: 'xs',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
  textTransform: 'uppercase',
  fontWeight: '900',
  color: 'white',
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  borderRadius: 'lg',
  borderWidth: '2px',
  borderColor: 'beta.400',
  lineHeight: '4',
  flex: ['1', null, '0'],
  fontSize: '2xs',
  fontWeight: 'black',
  h: ['9', null, '8'],
  justifyContent: 'center',
  px: '2',
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: [null, null, 'nowrap'],
};

export const FlexLabel: CSSObject = {
  gap: ['3', null, '2'],
};
