import { CSSObject, TextProps } from '@chakra-ui/react';
import featuredBg from '@/assets/sterlingparker/images/backgrounds/featured-background.png';

export const FlexFeatureWrapper: CSSObject = {
  bg: `transparent`,
  py: '0',
  minH: [null, '350px'],
  px: 0,
  flexDir: ['column', null, 'row'],
  mb: ['5', null, 'unset'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  p: ['0', null, '5'],
};

export const FlexFeatureTitleWrapper: CSSObject = {
  justifyContent: ['center', null, 'flex-start'],
  flexDir: ['column-reverse', null, 'column'],
  transformStyle: 'preserve-3d',
  pt: ['0', null, '43px'],
  pb: ['0', null, '5px'],
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  zIndex: '5',
  h: ['190px', null, 'auto'],
  minH: ['190px', '275px', null, 'auto'],
  bg: [`url(${featuredBg})`, null, 'unset'],
  bgSize: 'cover',
  bgPos: 'top',
};

export const ImageMascot: CSSObject = {
  display: ['none', null, 'block'],
  boxShadow: '0px 0px 57px rgba(255, 255, 255, 0.02)',
  borderRadius: '100%',
  width: '340px',
  height: '340px',
  flexGrow: 0,
};

export const textSloganProps: TextProps = {
  bgGradient: 'linear(to-b, #ffce70, #cc952b)',
  color: 'transparent',
  bgClip: 'text',
  textShadow: '0px 8px 14px rgba(0, 0, 0, 0.40)',
  fontSize: ['34.5px', null, '43px'],
  fontFamily: 'Crimson Pro',
  fontStyle: 'normal',
  fontWeight: 'medium',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  letterSpacing: '0',
};

export const TextFeatureStyledHeading: CSSObject = {
  color: 'white',
};

export const BoxCard: CSSObject = {
  borderRadius: '5px',
  background: 'var(--sterlingparker-beta-500, #30363F)',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
  maxW: ['unset', null, '308px'],
};
export const LinkCardHeader: CSSObject = {
  height: '36px',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  px: ['3.5', '3.5', '3'],
  pt: '2',
};

export const IconCardHeader: CSSObject = {
  display: 'flex',
  width: '17px',
  height: '17px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
};
export const TextCardHeader: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.2,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#FFF',
};

export const TextRaceNumber: CSSObject = {
  bg: 'beta.700',
  borderRadius: 'sm',
  color: '#fab735',
  height: '17px',
  lineHeight: '17px',
};

export const BoxGridHeaderItem: CSSObject = {
  height: '18px',
  padding: '3px 14px',
  boxShadow: '0 1px 0 0 rgba(255, 255, 255, 0.08)',
  backgroundColor: 'rgba(0, 0, 0, 0.36)',

  fontFamily: 'Roboto',
  fontSize: '10px',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#d6a64a',
  textTransform: 'uppercase',
};

export const ImageRunnerSilk: CSSObject = {
  width: '27px',
  height: '27px',
  borderRadius: '2px',
  mr: '8px',
};

export const FlexGridRunnerItem: CSSObject = {
  height: '44px',
  borderBottom: 'solid 1px rgba(0, 0, 0, 0.16)',
  px: '11px',
};

export const TextRunner: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#fff',
};
export const TextRunnerNumber: CSSObject = {
  '&:nth-child(2)': {
    color: 'var(--chakra-white, #FFF)',
    fontSize: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};
export const Button = (): CSSObject => ({
  fontSize: 'inherit',
  borderRadius: '5px',
  px: 'inherit',
  flex: '1',
  h: '34px',
});

export const LinkRaceDetails: CSSObject = {
  height: '40px',
  boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.1)',
  backgroundColor: 'rgba(255, 255, 255, 0.06)',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',

  fontFamily: 'CrimsonPro',
  fontSize: '16px',
  fontWeight: 900,
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: '#d6a64a',
  textTransform: 'uppercase',
};
