import { CSSObject } from '@emotion/react';

export const CarouselWrapper: CSSObject = {
  my: '1',
  mt: ['70px', null, '0px'],
  '& .control-dots ': {
    bottom: '0',
    marginY: 'px',
  },
  '& .carousel .slider-wrapper ': {
    w: ['99%', null, '809px'],
  },
};
