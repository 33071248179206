import { ErrorBannerSchema } from './ErrorBanner.styles';

export const errorBannerStyles: ErrorBannerSchema = {
  flexWrapper: {
    bg: 'beta.200',
    h: '8',
    w: '72',
    color: 'gray.700',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -8,
    left: '30%',
    zIndex: 9999,
  },
  errorText: {},
};
