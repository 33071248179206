import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  px: 3,
  py: 2,
  mb: 2,
  borderRadius: 'lg',
  bgGradient: `linear(to-b, rgba(97, 116, 182, 1), rgba(68, 85, 144, 1))`,
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
};
export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};
export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'alpha.400',
};
export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'alpha.300', mx: '6px' }),
  fontFamily: 'Staatliches',
  fontSize: 'sm',
});
export const FlexSubTextWrapper: CSSObject = {
  color: 'alpha.400',
  fontSize: 'xs',
  fontWeight: 'medium',
  fontFamily: 'body',
  flex: 1,
};
export const IconSport: CSSObject = {
  color: 'beta.600',
  boxSize: '30px',
  bg: 'alpha.400',
  borderRadius: 'lg',
  p: '1',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
};
export const IconChevron: CSSObject = {
  color: 'alpha.400',
  ml: '0',
  w: 4,
  h: 4,
};
export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: 'xs',
    bg: 'transparent',
  },
};
export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.400',
    boxSize: '5',
  },
};
