import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    bg: 'beta.800',
    w: ['100%', null, '100%'],
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200), 0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
    borderRadius: ['none', 'lg'],
    minH: '8',
    h: 'auto',
    px: '4',
    mx: ['0', null, '0'],
    mb: '2',
    pb: '0',
  },
  link: {
    textDecoration: 'none',
    boxShadow: 'none',
    border: 'none',
    fontSize: 'sm',
    borderRadius: 'none',
    fontFamily: 'accent',
    lineHeight: '2',
    h: '10',
    color: 'white',
    borderBottom: '3px solid',
    borderBottomColor: 'transparent',
    textTransform: 'uppercase',
    px: '2',
    py: '1',
    sx: {
      '&&': {
        pt: '1.5',
      },
      '&.active': {
        border: 'none',
        borderBottom: '3px solid',
        color: 'beta.500',
        borderBottomColor: 'beta.500',
      },
    },
    _hover: {
      color: 'white',
    },
  },
};
