import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: { variant: 'primary' },
};

export const FlexBannerContainer: CSSObject = {
  borderRadius: 'md',
  bg: 'alpha.700',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  color: 'white',
  gap: ['2', null, '0'],
  p: ['2', null, '3'],
  minH: '107px',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
};

export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  color: 'beta.500',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  textTransform: 'uppercase',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'Staatliches',
  textAlign: ['start', null, 'end'],
};

export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  textTransform: 'uppercase',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'beta.500',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'beta.500',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  opacity: '0.7',
  color: 'beta.500',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1',
};

export const TextButton: CSSObject = {
  h: '4',
  fontFamily: 'Staatliches',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.5)',
  fontWeight: 'normal',
};

export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'base',
  border: '2px',
  borderColor: 'alpha.100',
  color: 'white',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
};

export const FlexLabel: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1',
};
