import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'lg',
  bg: 'alpha.200',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset,0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset',
};
export const RunnerName: CSSObject = {
  color: 'gamma.200',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
export const TextBarrierNumber: CSSObject = {
  color: 'gamma.200',
  fontSize: '2xs',
  fontWeight: 'semibold',
  position: 'relative',
  top: '-0.5',
};
export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};
export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    borderLeft: '1px solid',
    borderColor: ['transparent', null, 'whiteAlpha.200'],
    pl: ['0', null, '2'],
    ml: ['0', null, '2'],
    '> div': {
      py: '1',
    },
  },
};
export const RunnerScratchedWrapper: CSSObject = {
  color: 'gamma.200',
};
export const TextDeductions: CSSObject = {
  color: 'gamma.200',
};
export const TextScratchedInfo: CSSObject = {
  color: 'gamma.200',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const ScratchedText: CSSObject = {
  color: 'gamma.200',
  fontWeight: 'semibold',
};
