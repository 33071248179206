import { CSSObject } from '@chakra-ui/react';
import ntjBg from '@/assets/junglebet/images/backgrounds/ntjBg.png';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  py: '1',
  px: '2',
  borderRadius: 'md',
  bgImage: ntjBg,
  bgSize: 'cover',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'beta.500' : 'white',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'md',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'blackAlpha.600' : 'transparent',
  boxShadow: isActive ? '0px 1px 3px 0px rgba(0, 0, 0, 0.83) inset' : 'none',

  _hover: {
    bg: isActive ? 'blackAlpha.600' : 'transparent',
    color: 'white',
  },
  '&:hover, &:focus': {
    bg: 'blackAlpha.600',
  },
});
