import React from 'react';
import { Img } from '@chakra-ui/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logo from '../../../assets/betprofessor/images/logo.svg';
import Title from '../components/Title';
import Description from '../components/Description';
import RefreshBtn from '../components/RefreshBtn';
import RefreshText from '../components/RefreshText';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (isMaintenance: boolean): TTemplate => ({
  wrapper: <FlexPageWrapper isMaintenance={isMaintenance} />,
  content: {
    wrapper: <MaintenanceContainer />,
    logo: (
      <Img
        src={logo}
        alt={REACT_APP_THEME_NAME}
        h={['45px', null, '47px']}
        mt={['80px', null, '0']}
        mb={['2', null, '6']}
      />
    ),
    title: <Title />,
    desc: <Description />,
    text: <RefreshText />,
    btn: <RefreshBtn />,
  },
});

export default template;
