import { Box, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { Receipt } from '@styled-icons/fa-solid';
import { Logout } from '@styled-icons/material-twotone';
import { useNavigate } from 'react-router-dom';
import {
  ButtonNavItem,
  UserIcon,
  SupportIcon,
} from './styles/QuickAccessMenu.styles';
import { useAuth } from '@/hooks/useAuth';
import { useLiveChat } from '@/hooks/useLivechat';

type TNav = { onClose: () => void };

export default function Nav({ onClose }: TNav) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { showLiveChat } = useLiveChat();

  return (
    <Box>
      {[
        {
          icon: <Icon as={Receipt} />,
          text: 'My Bets',
          onClick: () => navigate('/account/my-bets'),
          cy: 'link-qa-my-bets',
        },
        {
          icon: <UserIcon />,
          text: 'My Account',
          onClick: () => navigate('/account/overview'),
          cy: 'link-qa-account-overview',
        },
        {
          icon: <SupportIcon />,
          text: 'Support',
          onClick: () => showLiveChat(),
          cy: 'link-qa-support',
        },
        {
          icon: <Icon as={Logout} />,
          text: 'Log out',
          onClick: () => logout(),
          cy: 'link-qa-logout',
        },
      ].map((data, i) => (
        <ButtonNavItem
          key={`qa-${data.text}`}
          isLastItem={i === 3}
          onClick={() => {
            onClose();
            data.onClick();
          }}
          data-cy={data.cy}
        >
          {data.icon}
          <Text>{data.text}</Text>
        </ButtonNavItem>
      ))}
    </Box>
  );
}
