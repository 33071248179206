/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import {
  ImageNoRaces,
  NoRacesWrapper,
  TextPrimary,
  TextSecondary,
} from '../styles/NoRaces.styles';
import NoRacesMascot from '../../../../../assets/questbet/images/backgrounds/no-races.png';

export const template: TTemplate = {
  wrapper: <NoRacesWrapper />,
  image: <ImageNoRaces src={NoRacesMascot} />,
  text: (
    <>
      <TextPrimary>
        <FormattedMessage id="racing.errorMessages.noRacesAvailable" />
      </TextPrimary>
      <TextSecondary>
        <FormattedMessage id="racing.errorMessages.noRacesAvailableSubtext" />
      </TextSecondary>
    </>
  ),
};

export default template;
