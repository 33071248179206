import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/juicybet/images/backgrounds/melonPattern.png';

export const TitleContainer = (): CSSObject => ({
  bgImage: background,
  w: '100%',
  mt: ['unset', '4'],
  overflow: 'visible',
  borderWidth: '3px',
  borderColor: 'alpha.50',
  borderBottom: 'none',
  boxShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
  borderTopRadius: 'xl',
  '&&': { px: ['2.5', null, null, '3'], pb: ['1', null, '2'] },
  fontSize: '2xl',
  fontFamily: 'Chewy',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.50)',
  color: 'gamma.400',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  mb: '2',
  fontWeight: 'medium',
  fontFamily: 'Chewy',
  fontSize: '21px',
  textTransform: 'capitalize',
  color: 'gamma.400',
  letterSpacing: 'wider',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.50)',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  borderRadius: [null, null, null, 'base'],
  mx: ['2', 'unset'],
  mt: ['2', 'unset'],
};

export const WrapperContainer: CSSObject = {
  bgImage: background,
  px: '0',
  borderTopRadius: 'none',
  borderBottomRadius: 'xl',
  color: 'delta.50',
  borderWidth: '3px',
  borderColor: 'alpha.50',
  borderTop: 'none',
  mb: '2',
  paddingBottom: '3',
};
