import { MyBetsSchema } from '@/views/account/MyBets/styles/MyBets.styles';

export const myBetsStyles: MyBetsSchema = {
  buttonShowMoreProps: {
    variant: 'secondary',
  },
};

export const FlexBetCard: CSSObject = {
  gap: '2',
};
