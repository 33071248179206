import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';
import FadeBg from '@/assets/templebet/images/backgrounds/fadeBgMenu.png';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '2',
    height: 'fit-content',
    pt: '0',
    pb: '1',
    px: '.5',
    bg: `linear-gradient(180deg, var(--bc-colors-beta-200), var(--bc-colors-beta-300)), url(${FadeBg})`,
    backgroundPosition: 'top',
    backgroundBlendMode: 'color-burn',
    boxShadow:
      '0px 1px 1px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px var(--bc-colors-beta-100) inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
    borderRadius: 'lg',
    overflow: 'hidden',
  },
  accordionItem: {
    bg: 'transparent',
    outline: 'none',
    border: 'none',
    mb: '1px',
    mx: '1',
    _first: {
      borderTopRadius: 'md',
      mt: '1',
    },
    _last: {
      mb: '0',
      borderBottomRadius: 'md',
    },
    pos: 'relative',
    _notFirst: {
      borderTopColor: 'blackAlpha.400',
      borderTopStyle: 'dashed',
      borderTopWidth: '1px',
    },
    _before: {
      mx: '1',
      border: 'inherit',
      borderColor: 'var(--bc-colors-whiteAlpha-300)',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
    pt: '2',
    pb: '2',
  },
  accordionHeadingText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    textShadow: '0px 2px 2px var(--bc-colors-blackAlpha-50)',
    color: 'beta.800',
    fontFamily: 'accent',
    fontSize: 'sm',
    lineHeight: 'normal',
  },
  accordionIcon: {
    sx: {
      display: 'none',
    },
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
  },
};
