import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import {
  BetPlaced,
  BetPlacedRow,
  TextPlacedLabel,
} from '../../../Card/styles/BetCard.styles';
import { IconBetPlaced } from '../../../MultiCard/Components/Placed/styles/Placed.styles';

type TMoMCardPlacedBetFooterProps = {
  requestId: string;
  betPlacedAt: string;
};

export function MoMCardPlacedBetFooter({
  requestId,
  betPlacedAt,
}: TMoMCardPlacedBetFooterProps) {
  const date = new Date(betPlacedAt);
  const dateString = date.toLocaleString('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  });

  return (
    <Box w="100%">
      <BetPlaced>
        <BetPlacedRow>
          <TextPlacedLabel>
            <FormattedMessage id="account.mybetscard.betplaced" />
          </TextPlacedLabel>
        </BetPlacedRow>

        <BetPlacedRow>
          <Text data-cy="betPlacedDateTime">{dateString}</Text>
        </BetPlacedRow>

        <BetPlacedRow>
          <TextPlacedLabel>
            <FormattedMessage id="account.mybetscard.betidtext" />
          </TextPlacedLabel>
        </BetPlacedRow>

        <BetPlacedRow>
          <Text data-cy="betPlacedBetId">{requestId}</Text>
          <IconBetPlaced />
        </BetPlacedRow>
      </BetPlaced>
    </Box>
  );
}
