import queryString from 'query-string';
import api from '@/api/api';
import {
  TQueryUpcomingMatchesBySportParams,
  TQueryUpcomingMatchesBySportResponse,
} from './upcomingMatchesBySport.types';

export const upcomingMatchesBySportEndpoint =
  '/punter/sports/upcoming-matches-group-by-sport';

export async function queryUpcomingMatchesBySport(
  params?: TQueryUpcomingMatchesBySportParams
) {
  const res = await api.get<TQueryUpcomingMatchesBySportResponse>(
    upcomingMatchesBySportEndpoint,
    { params, paramsSerializer: queryString.stringify }
  );
  return res;
}
