import { CSSObject, TextProps } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const titleProps: TextProps = {
  display: 'flex',
  justifyContent: ['space-between', null, 'flex-start'],
};

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
  color: 'gray.500',
  lineHeight: '130%',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
  color: 'gray.500',
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
};

export const ButtonSubmit: CSSObject = {
  bgGradient: 'linear(180deg, #FACD33, #EC9E0F)',
  border: '2px solid #A1741F',
  color: 'beta.800',
  boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
  borderRadius: 'md',
  fontFamily: 'SkranjiBold',
  fontWeight: '700',
  letterSpacing: 'wide',
  fontSize: '14px',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  textTransform: 'uppercase',
  minH: '38px',
  _hover: {
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: 'beta.800',
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',
  color: 'gray.500',
  fontSize: '12px',

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    borderLeftRadius: '4px',
    h: '9',
  },
  '.chakra-select': {
    bg: 'zeta.50',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'xs',
    h: '9',
  },
  '.chakra-input': {
    fontSize: 'xs',
    borderRightRadius: '4px',
    bg: 'zeta.50',
    border: '1px solid',
    // borderLeft: 'none',
    borderColor: 'gray.300',
    h: '9',
    _hover: {
      bg: 'gray.100',
    },
  },
};
