/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, BoxProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimerNativeButton } from '../../Deposit';
import { TextRowDivider } from '../styles/Form/Form.styles';

/* eslint-disable react/no-unused-prop-types */
type PrimerNativeButtonContainerProps = {
  isNativeLoading: boolean;
  buttonInfo: PrimerNativeButton;
} & BoxProps;

export const NativePmtBtn = forwardRef(
  ({ buttonInfo }: PrimerNativeButtonContainerProps, ref: any) => {
    const btnId = `${buttonInfo.type}_button_id`;

    return (
      <>
        <Box id={btnId} ref={ref} h="45px" w="full" />
        <TextRowDivider>
          <FormattedMessage id="account.deposit.labels.or" />
        </TextRowDivider>
      </>
    );
  }
);
