import { CSSObject } from '@chakra-ui/react';

export const TextTitle: CSSObject = {
  color: 'white',
};

export const FlexSignedOutText: CSSObject = {
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  bg: 'beta.800',
};
