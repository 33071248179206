import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: [null, '2'],
  bg: ['transparent', 'gray.100'],
  borderRadius: [null, 'lg'],
  border: [null, 'none'],
  borderStyle: 'dashed',
  borderColor: 'blackAlpha.300',
};

export const RunnerName: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'gray.700',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {
  color: 'gray.700',
};

export const FlexDeductionsContainer: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'white',
  textTransform: 'uppercase',
  flexDir: 'column-reverse',
};

export const TextDeductions: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'gray.700',
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'gray.700',
};

export const ScratchedText: CSSObject = {
  fontWeight: 'semibold',
  color: 'gray.700',
};

export const TextScratchedInfo: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'gray.700',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  borderColor: 'gray.200',
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1.5',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.30)',
};
