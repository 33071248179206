import React from 'react';
import { Global } from '@emotion/react';

export default function index() {
  return (
    <Global
      styles={{
        body: {},
      }}
    />
  );
}
