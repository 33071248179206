import { EGeneralStatus, TRunner } from '@/lib/DBModels';

export const sortRunners = (data: TRunner[]) =>
  data
    .sort(
      (a, b) =>
        (a.win_odds ?? 9) - (b.win_odds ?? 9) ||
        (a.number ?? 0) - (b.number ?? 0)
    )
    .sort((a, b) => {
      const aScratched =
        a?.is_scratched || a?.status === EGeneralStatus.Scratched;
      const bScratched =
        b?.is_scratched || b?.status === EGeneralStatus.Scratched;

      if (aScratched === bScratched) {
        return 0;
      }
      return aScratched ? 1 : -1;
    });
