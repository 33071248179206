import { CSSObject } from '@chakra-ui/react';
import { TStyleConfig } from '../types';

export type THeaderTheme = {
  menuIcon: CSSObject;
  headerHeight: string | string[];
  wrapperContent: CSSObject;
  wrapper: CSSObject;
  accountBallance: CSSObject;
  accountBallanceVerbiage: CSSObject;
  logo: CSSObject;
  betslip: CSSObject;
  betslipCount: CSSObject;
  betslipLabel: CSSObject;
};

export const headerBaseStyle: THeaderTheme = {
  headerHeight: '57',
  wrapper: {},
  wrapperContent: {
    alignItems: 'center',
  },
  accountBallance: {},
  accountBallanceVerbiage: {},
  menuIcon: {},
  logo: {},
  betslip: {
    ml: [2, 8],
  },
  betslipCount: {
    lineHeight: 'none',
  },
  betslipLabel: {
    textTransform: 'uppercase',
  },
};

export const Header: TStyleConfig<THeaderTheme> = {
  baseStyle: headerBaseStyle,
  sizes: {},
  variants: {},
};
