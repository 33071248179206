import * as betgalaxy from './Footer.styles.betgalaxy';
import * as betprofessor from './Footer.styles.betprofessor';
import * as goldenrush from './Footer.styles.goldenrush';
import * as juicybet from './Footer.styles.juicybet';
import * as vikingbet from './Footer.styles.vikingbet';
import * as volcanobet from './Footer.styles.volcanobet';
import * as wellbet from './Footer.styles.wellbet';
import * as forsport from './Footer.styles.forsport';
import * as fiestabet from './Footer.styles.fiestabet';
import * as junglebet from './Footer.styles.junglebet';
import * as buffalobet from './Footer.styles.buffalobet';
import * as sugarcastle from './Footer.styles.sugarcastle';
import * as templebet from './Footer.styles.templebet';
import * as oldgill from './Footer.styles.oldgill';
import * as bet777 from './Footer.styles.bet777';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  goldenrush,
  juicybet,
  vikingbet,
  volcanobet,
  wellbet,
  forsport,
  fiestabet,
  junglebet,
  buffalobet,
  sugarcastle,
  templebet,
  oldgill,
  bet777,
};
