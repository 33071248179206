import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {},
  body: {
    pl: '0',
    borderBottom: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.24)',
  },
};
