/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center, Image } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import emptyBetslip from '../../../../../../../assets/questbet/images/emptyBetslip.png';

export const template: TTemplate = {
  wrapper: <Center />,
  empty: <Image src={emptyBetslip} h="138px" mb="2" />,
};

export default template;
