import { TVenue } from '../../../../../api/racing/racing.types';
import { getStrings } from '@/helpers/utils';
import { EGeneralStatus, TRace } from '../../../../../lib/DBModels';

/**
 * Util to find the correct race
 */
export const getCurrentRace = (
  races: TVenue['venue_races'],
  index: number,
  maxCount: number
) => {
  if (maxCount === 1 && races?.length) {
    return races[0];
  }

  return races
    ?.filter((r) => r?.race_number !== undefined)
    .find((r) => r?.race_number === index + 1);
};

/**
 * Util that returns the url of the race
 */
export const getRaceURL = (race: TRace, venue: TVenue, betType: string) =>
  `/racing/${race.race_type}/${venue.venue_display_name}/R${race?.race_number}${
    betType ? `/${betType}` : ''
  }?venueId=${venue.venue_id}&raceId=${race?.race_id}&meetingDate=${
    race?.meeting_date
  }`;

/**
 * Util to parse the data that should be shown
 * within the race items.
 */
export const raceItemContent = (race: TRace) => {
  const [
    {
      Racing: {
        RaceMeetings: { Abandoned, Closed },
      },
    },
  ] = getStrings();

  const raceStatusText =
    race?.status === EGeneralStatus.Abandoned ? Abandoned : Closed;

  const raceResults = race?.results?.reduce((acc, curr, i) => {
    if (i === 0) return curr?.runner_number ?? '';
    // In the event of a deadheat, display / instead of ,
    const separator = Number(curr?.result_place) !== i + 1 ? '/' : ', ';
    return `${acc}${separator}${curr?.runner_number}`;
  }, '');

  return (() => {
    if (race?.status === EGeneralStatus.Abandoned) {
      return {
        component: 'badge',
        content: raceStatusText,
      };
    }

    if (race?.status === EGeneralStatus.Closed) {
      return raceResults
        ? {
            component: 'text',
            prop: 'result',
            content: raceResults,
          }
        : {
            component: 'badge',
            content: raceStatusText,
          };
    }

    if (raceResults) {
      return {
        component: 'text',
        prop: 'result',
        content: raceResults,
      };
    }

    if (race?.start_time) {
      return {
        component: 'countdown',
        content: race?.start_time,
      };
    }

    return undefined;
  })();
};
