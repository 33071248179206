import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  py: ['2', null, '4'],
  px: ['1', null, '4'],
  mx: ['2', null, null, 'unset'],
  minH: '50vh',

  borderBottomRadius: '6px',
  background: 'var(--bc-beta-500, #30363F)',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['6'],
  px: ['2', 0],
  mx: ['-2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderRadius: '4px',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  border: 0,
  bg: 'transparent',
};

export const TextTitle: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  mb: '2',
  mt: '4',
};
export const TextName: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
};

export const LinkItem: CSSObject = {
  display: 'flex',
  p: '3',
  gap: '2',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderTopColor: 'var(--bc-beta-200, #4B5A6D)',
  background: 'var(--bc-beta-300, #3F4953)',

  _hover: {
    background: 'beta.400',
  },
};

export const IconSport: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSize: '6',
  mr: '1',
  color: 'alpha.700',
};

export const IconChevron: CSSObject = {
  boxSize: '6',
  p: '1',
  transition: '.3s ease-in-out',
  color: 'alpha.700',

  _hover: {
    color: 'beta.800',
  },
};
