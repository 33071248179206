import { CSSObject } from '@chakra-ui/react';

export const ExoticsWrapper: CSSObject = {
  px: '0',
};

export const ExoticsContainer: CSSObject = {
  p: '2',
  border: '1px',
  borderColor: 'beta.600',
  bg: 'white',
  borderRadius: 'lg',
};

export const CheckboxBoxed: CSSObject = {
  color: 'gray.700',
  right: 0,
  justifyContent: 'flex-end',
  mr: '3',
  w: 'auto',
};

export const TextBoxed: CSSObject = {
  color: 'gray.700',
  fontSize: 'sm',
};

export const SelectFieldContainer: CSSObject = {
  w: 'unset',
};
