import { Box, Container, Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserData } from './services/PrivateRoute.hooks';

/**
 * Private routes only allow authenticated users
 * to access the view, if the user is unauthenticated they will be redirected
 * to the login route.
 */
export default function PrivateRoute() {
  const { isAuthenticated, isLoading } = useUserData();

  if (isAuthenticated) return <Outlet />;
  if (isAuthenticated === false) return <Navigate to="/login" replace />;
  if (!isLoading && isAuthenticated === undefined) {
    return (
      <Container flex="1" maxW="container.xl">
        <VStack py="10" spacing="4">
          {[...new Array(5)].map((_, i) => (
            <Skeleton
              key={i}
              width="100%"
              height={Math.floor(Math.random() * 100)}
              startColor="brand.400"
              endColor="brand.500"
            />
          ))}
        </VStack>
      </Container>
    );
  }

  return <Box flex="1" />;
}
