import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',

    color: 'beta.500',
    height: '5',
    border: '2px solid',
    borderColor: 'beta.50',

    _hover: {
      '&&': {
        background: 'transparent',
        color: 'gamma.100',
      },
    },
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'delta.700',
  borderTop: '2px',
  borderTopColor: 'beta.50',
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  fontSize: 'xl',
  border: 'none',
  color: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  bg: 'unset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  },
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  fontSize: 'xl',
  border: 'none',
  bg: 'delta.700',
  textTransform: 'uppercase',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'secondary',
  fontFamily: 'body',
  height: '100%',
  fontSize: 'xl',
  bg: 'gamma.300',
  color: 'delta.700',
  border: 'none',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
};
