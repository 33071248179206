import { Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

export default function Loading() {
  return (
    <Stack sx={{ pb: '3' }}>
      {[...new Array(5)].map((_, i) => (
        <Skeleton key={`skel-${i}`} sx={{ h: '9' }} />
      ))}
    </Stack>
  );
}
