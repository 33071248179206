import React from 'react';
import { Text } from '@chakra-ui/react';
import { useSecondsCountDown } from '../../../../../../../hooks/useSecondsCountDown';

export type TSecondsCountDown = {
  totalSeconds: number;
};

export default function SecondsCountDown({ totalSeconds }: TSecondsCountDown) {
  const { seconds } = useSecondsCountDown(totalSeconds);

  if (seconds <= 0) {
    window.location.reload();
  }

  return <Text as="span">{seconds}</Text>;
}
