import { css } from 'styled-components';

// TODO: Remove
export const datePicker = css``;

// TODO: Remove
export const label = css`
  color: ${({ theme }) => theme.palette.primary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.M};
`;

export const icon = css``;

export const text = css``;

export const error = {
  state: css``,
  icon: css`
    background-size: var(--bc-sizes-3) var(--bc-sizes-3);
    width: var(--bc-sizes-3);
    height: var(--bc-sizes-3);
    margin-right: var(--bc-sizes-1);
    color: ${({ theme }) => theme.palette.warning};
  `,
  text: css`
    color: ${({ theme }) => theme.palette.warning};
    font-size: var(--bc-fontSizes-xs);
    padding-top: var(--bc-sizes-1);
    font-family: ${({ theme }) => theme.fontFamily.body};
    align-items: center;
  `,
};

// TODO: Remove
export const inputContainer = css`
  background: ${({ theme }) => theme.palette.inputBg};
  border: ${({ theme }) => theme.borderStyles.tertiary};
  border-radius: 3px;
  height: 33px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-md);
  color: ${({ theme }) => theme.palette.secondary};

  span {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: var(--bc-fontSizes-md);
  }
`;

// TODO: Remove
export const inputText = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-md);
  color: ${({ theme }) => theme.palette.secondary};
`;
