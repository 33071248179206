const branding = {
  alpha: {
    50: '#EFFECB',
    100: '#CAFFA4',
    200: '#B1FF79',
    300: '#A0FF5D',
    400: '#7EE428',
    500: '#4FBC03',
    600: '#439A06',
    700: '#2F6E03',
    800: '#2A6601',
    900: '#1E4A00',
  },
  beta: {
    50: '#FDECEC',
    100: '#FAD9D9',
    200: '#F6B3B3',
    300: '#F18D8C',
    400: '#FF6463',
    500: '#F84443',
    600: '#C33B34',
    700: '#8B2726',
    800: '#5D1A1A',
    900: '#2E0D0D',
  },
  gamma: {
    50: '#FFF9BF',
    100: '#FFF59A',
    200: '#FFF06C',
    300: '#FFED50',
    400: '#FFE81C',
    500: '#F1C300',
    600: '#C29F01',
    700: '#927701',
    800: '#615000',
    900: '#312800',
  },
  delta: {
    50: '#02FF6B',
    100: '#00E35F',
    200: '#00C251',
    300: '#00B249',
    400: '#00A443',
    500: '#088E3F',
    600: '#077735',
    700: '#06692F',
    800: '#044A21',
    900: '#03371A',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  delta: branding.delta,
  epsilon: {},
  theta: {},
};
