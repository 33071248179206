import { CSSObject, TextProps } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: ['center', null, null, null, 'flex-start'],
  px: ['8', null, null, null, '5'],
  color: 'white',
  textAlign: 'center',
  mx: 'auto',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'Hitchcut',
  fontSize: 'lg',
  fontWeight: 'black',
  color: 'white',
  textTransform: 'uppercase',
  mt: '10',
  textShadow: '0px 4px 8px rgba(0, 0, 0, 0.30)',
};

export const BetSlipEmptyImage: CSSObject = {
  mb: '-5',
  ml: '2.5',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  lineHeight: '4',
  mb: '2',
  px: '3',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
  mt: '10',
};

export const BetSlipEmptyResponsibleGamblingWrapper: CSSObject = {
  boxShadow: 'unset',
  mt: '4',
  mb: '-0.5',
  'div:last-child': {
    borderBottomRadius: ['none', null, null, null, 'base'],
  },
};

export const betSlipEmptyHeadingSuffix: TextProps = {
  fontFamily: 'Hitchcut',
  fontWeight: 'semibold',
  fontSize: '4xl',
  color: 'beta.500',
  display: 'block',
  lineHeight: '32px',
};
