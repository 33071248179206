import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: 'none',
  border: 0,
  mb: '2',

  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',

    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  ...(isNested && {}),
  border: '1px',
  borderTop: 0,
  borderColor: 'alpha.200',
  borderBottomRadius: 'base',
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'alpha.50',
  color: 'gray.700',
  border: '1px',
  borderColor: 'alpha.200',
  px: '2',
  py: '2.5',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: 'sm',

  _expanded: {
    color: 'white',
    bg: 'alpha.600',
    borderBottomRadius: 0,
    border: 0,
    p: '2',
  },

  ...(isNested && {
    bg: 'alpha.50',

    _expanded: {
      bg: 'alpha.50',
      color: 'gray.700',
      borderBottomRadius: 0,
      px: '2',
      py: '1.5',
    },
  }),
});
