export const en = {
  'account.withdraw.requests.cancelbutton': 'Cancel Withdraw Request',
  'account.withdraw.addbank': 'Add new Bank account',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'betslip.betslipmodal.emptybetslipheading': 'There’s no gold here!',
  'racing.exotics.selectfield': 'Select all',
  'racing.errorMessages.noRacesAvailable': 'The Gold’s run out.',
  'onboarding.depositLimit.completeSignup': 'Continue',
  'onboarding.login.subtitle':
    "Get ready to <Node>unearth your luck at GoldenRush!</Node> Bet on sports and races with the spirit of a daring prospector. <Node>Let's strike gold together!</Node>",
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': 'Exclude Yourself',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'account.deposit.card.expired': 'Expired card',
  'onboarding.locationnotallowed.header': 'Uncharted Mining Territory',
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing GoldenRush from outside of Australia.</b>',
  'error.heading': `Prospector's Pitfall`,
  'maintenance.error': `<bold>We are investigating the issue.</bold>
  Refresh the page, or try again later.`,
  'error.description': `<bold>GolderRush is currently down.</bold>
  Refresh the page, or try again later.`,
  'maintenance.heading': 'Mine under maintenance',
  'header.betslipBtn.desc': 'Slip',
  'sports.allsports.azheading': 'A-Z Sports',
  'main.featured.header': 'Nugget Rush',
  'main.featured.subHeader': 'Rally',
};
