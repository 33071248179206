import { CSSObject } from '@chakra-ui/react';
import { TLinkRaceItem } from './RaceTable.styles';

export const FlexHeading: CSSObject = {
  bgGradient: 'linear(to-b, alpha.700, alpha.800)',
  color: 'white',
  borderTopRadius: 'lg',
  top: ['12', '84px'],
  borderTopWidth: '2px',
  borderX: '2px',
  borderColor: 'gamma.500',
  mb: 'unset',
};

export const BoxTableWrapper: CSSObject = {
  borderX: '2px',
  borderColor: 'gamma.500',
  borderBottomRadius: 'lg',
  px: 'unset',

  '.state-prior': {
    color: 'beta.500',
  },

  '.state-ended': {
    bg: 'beta.500',
    color: 'alpha.800',
  },
};

export const TextRaceResults: CSSObject = {
  color: 'beta.500',
};

export const FlexHeadingRow: CSSObject = {
  bg: 'beta.500',
  color: 'gray.800',
  fontSize: '2xs',
  fontWeight: 'medium',
  h: '34px',
};

export const LinkRaceItem = ({ isActive }: TLinkRaceItem) => ({
  bg: isActive ? [null, null, 'gamma.400'] : [null, null, 'gamma.400'],
  color: isActive ? [null, null, 'black'] : [null, null, 'gray.700'],
  borderColor: 'alpha.400',

  borderTop: [0, null, '1px'],
  borderTopColor: ['unset', null, 'alpha.400'],
  borderLeft: ['unset', null, '1px'],
  borderLeftColor: ['unset', null, 'alpha.400'],
  borderRight: ['unset', null, 'none'],

  _hover: {
    bg: ['inherit', null, 'gamma.500'],
  },
});

export const CenterTH = () => ({
  bg: 'beta.500',
});

export const TextHeading: CSSObject = {
  color: 'beta.500',
  fontFamily: 'KHInterference',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'gray.200',
  bg: 'gamma.400',
  borderRadius: 'lg',
  p: '1.5',
  textShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
};

export const BoxPageWrapper: CSSObject = {
  mx: '0',
  my: '1',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'beta.500',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.400',
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'row-reverse',
  columnGap: '2',
};

export const ButtonChevron: CSSObject = {
  color: 'white',
  borderRadius: 'full',
  border: '2px',
  borderColor: 'white',
  boxSize: '5',
};

export const BoxVenueName: CSSObject = {
  bg: 'gamma.400',
  borderTop: [0, null, '1px'],
  borderTopColor: ['unset', null, 'alpha.400'],
  borderColor: 'alpha.400',
  borderRight: [null, null, 'none'],
  w: ['100%', null, '110px'],
};

export const FlexRow: CSSObject = {
  mb: ['0', null, 'unset'],
  borderRadius: 'unset',
};
