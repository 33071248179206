import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  px: '2',
  pt: '2',
});

export const SpanBarrierNumber: CSSObject = {
  color: 'inherit',
  fontFamily: 'roboto',
  fontWeight: 'bold',
  lineHeight: 'normal',
  fontSize: '12px',
  mb: 'auto',
};

export const CountdownTimerBox: CSSObject = {
  '.chakra-badge': {
    borderRadius: 'sm',
    color: 'black',
  },
  '.chakra-badge.state-inprogress': {
    color: 'alpha.800',
  },
  '.chakra-badge.state-ended': {
    color: 'white',
  },
};
