import { QuestionSquareFill } from 'styled-icons/bootstrap';
import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryBet from '@/assets/goldenrush/images/mysteryBet.png';

export const mysteryBetStyles: MysteryBetSchema = {
  tooltipIcon: {
    fill: 'alpha.700',
    boxSize: 5,
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  textPriceInfo: {
    color: 'gamma.500',
    fontWeight: 'bold',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    fontWeight: 'bold',
  },
  flexRaceInfo: {
    gap: 1,
    fontWeight: 'bold',
    color: 'white',
  },
  iconRace: {
    color: 'alpha.200',
    boxSize: 6,
  },
  textRollover: {
    color: 'gamma.500',
    fontWeight: 'bold',
  },
  iconMystery: {
    as: QuestionSquareFill,
  },
  flexWrapperMysteryLabelView: {
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
  },
  iconBack: {
    boxSize: 6,
    color: 'alpha.400',
  },
  flexWrapperMysteryRolloverView: {
    gap: [1, 2],
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    px: 3,
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
  },
  buttonRollOver: {
    h: 7,

    _disabled: {
      bg: 'alpha.800',
      bgGradient: 'none',
      color: 'beta.500',
      border: 'none',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
      px: 3,
      _hover: { bg: 'none' },
      _after: { opacity: 0 },
    },
  },
  buttonAddToBetslip: {
    variant: 'secondary',
    px: [2, 2, 3],
    fontSize: ['2xs', 'xs'],
    h: 8,
    textTransform: 'uppercase',
  },
  flexWrapperMysteryBetReviewView: {
    gap: 2,
    p: 2,
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'md',
    color: 'white',
    sx: {
      '&&': {
        px: [2, 3],
      },
    },
    lineHeight: '15px',
    maxH: 10,
    fontSize: ['2xs', 'xs'],
    fontWeight: 'bold',
  },
  flexWrapperMysteryTooltipIcon: {
    bgGradient: 'linear(180deg, gamma.400, gamma.600)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
    borderRadius: 'lg',
    boxSize: 8,
    p: '2',
  },
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 2,
  },
  buttonOdds: { fontFamily: 'roboto', fontWeight: 'black' },
  flexWrapperMysteryBetSelectionView: {
    mx: 4,
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'lg',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    p: 2,
  },
  imageLogo: {
    src: `${mysteryBet}`,
    h: [5, 7],
  },
};
