import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputGroup: {
    rounded: 'md',
    border: '1px solid rgba(187, 135, 37, 1)',
    overflow: 'hidden',
    p: 0.5,
  },
  betCardInputLeftAddon: {
    fontWeight: 'black',
    fontSize: 'sm',
    color: 'gamma.800',
    sx: {
      '&&': {
        bgGradient: 'linear(to-b, gamma.300, gamma.400)',
        border: 'none',
        boxShadow: '0px 2px 3px 1px rgba(0, 0, 0, 0.1)',
        mr: 0.5,
      },
    },
  },
  betCardInput: {
    color: 'gamma.500',
    fontWeight: 'bold',
    boxShadow: '0px 2px 3px 1px rgba(0, 0, 0, 0.1)',
    sx: {
      '&&': {
        borderRightRadius: 'base',
        border: 'none',
        bg: 'gamma.800',
        _hover: {
          '&&': {
            border: 'none',
          },
          borderRightRadius: 'base',
          bg: 'gamma.900',
        },
        _focus: {
          '&&': {
            border: 'none',
          },
          borderRightRadius: 'base',
          bg: 'alpha.700',
        },
      },
    },
  },
};
