import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    _disabled: {
      color: 'alpha.200',
      bg: 'blackAlpha.400',
      boxShadow: 'unset',
    },
  },
};
