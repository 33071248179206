import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',
    color: 'alpha.700',
    border: '2px solid',
    borderRadius: 'base',
    textTransform: 'uppercase',
    borderColor: 'alpha.400',
    height: '5',

    _hover: {
      '&&': {
        background: 'transparent',
        color: 'gamma.100',
      },
    },
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['98%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'alpha.50',
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'primary',
  height: '100%',
  fontSize: 'xl',
  fontFamily: 'roboto',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.42)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
  bg: 'none',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.500, alpha.700)',
  },
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  fontFamily: 'roboto',
  fontSize: 'xl',
  border: 'none',
  bg: 'none',
  color: 'blackAlpha.700',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'unstyled',
  fontFamily: 'roboto',
  fontWeight: 'black',
  height: '100%',
  fontSize: 'xl',
  bg: 'none',
  color: 'blackAlpha.700',
  border: 'none',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.42)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
};
