import { CSSObject } from '@chakra-ui/react';

export const FlexBetsPlacedContainer: CSSObject = {
  h: '28',
  fontSize: 'md',
  justifyContent: 'center',
  flexDir: 'column',
  gap: '1',
  p: '0',
  borderRadius: 'md',
  ml: '1',
  my: '0.5',
  width: 'calc(100% - 8px)',
};

export const TextBetsPlaced: CSSObject = {
  fontSize: 'md',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'white',
  width: '95%',
  textAlign: 'center',
};

export const IconBetsPlaced: CSSObject = {
  '&&': {
    display: 'none',
  },
};
