import { CSSObject } from '@chakra-ui/react';

export const MatchesContainer: CSSObject = {
  mx: ['2', null, null, 0],
  p: ['2'],
  pt: 0,
  flexDir: 'column',

  borderBottomRadius: '6px',
  background: 'var(--bc-beta-500, #30363F)',
  minH: '50vh',
};
