import { Box, VisuallyHidden } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BoxCarouselSlide } from '../../components/Banner/styles/Banner.styles';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <BoxCarouselSlide key="banner-image">
            <VisuallyHidden>
              <FormattedMessage id="home.carousel.accessbility" />
            </VisuallyHidden>
          </BoxCarouselSlide>,
        ]}
      />
    </Box>
  );
}
