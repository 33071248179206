import { CSSObject, keyframes } from '@chakra-ui/react';

export const BetsPlacedVideo: CSSObject = {
  mt: '2',
  mx: '6',
  borderRadius: 'md',
};

export const FlexBetsPlacedContainer: CSSObject = {
  mt: '2',
  mx: '3',
  borderRadius: 'md',
  w: 'unset',
  bg: 'white',
  h: '28',
};

export const IconBetsPlaced: CSSObject = {
  '&&': {
    display: 'none',
  },
};

const loopPulse = keyframes`
  0% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 0.8));
  }
  50% {
    transform: scale(1.2);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 1));
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 0.8));
  }
`;

export const IconThumbsUp: CSSObject = {
  display: 'flex',
  boxSize: '7',
  animation: `${loopPulse} 1s ease-in-out infinite`,
  color: 'alpha.100',
};

export const TextBetsPlaced: CSSObject = {
  fontSize: 'sm',
};
