export const colors = {
  alpha: {
    100: '#eeeeee',
    200: '#d1d1d1',
    300: '#838383',
    400: '#706f6f',
    500: '#605e5e',
    600: '#494848',
    700: '#3d3b3b',
    800: '#312f2f',
    900: '#292929',
  },
  beta: {
    100: '#b2bebe',
    200: '#586262',
    300: '#464d4d',
    400: '#343b3b',
    500: '#262c2c',
    600: '#252929',
    700: '#202424',
    800: '#1c1f1f',
    900: '#19191a',
  },
  gamma: {
    100: '#ffdfaf',
    200: '#ffc878',
    300: '#ffb953',
    400: '#ffa727',
    500: '#c19262',
    600: '#996d40',
    700: '#6d461d',
    800: '#462c06',
    900: '#311e03',
  },
  delta: {
    100: '#50eaff',
    200: '#ff981f',
    300: '#69ff50',
    400: '#ff50e3',
    500: '#fff500',
    600: '#8e07e1',
    700: '#fb2222',
    800: '#7ee2dc',
    900: '#a0ff41',
  },
};
