import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';
import wood from '@/assets/fiestabet/images/backgrounds/woodBg.png';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },

  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    borderRadius: '2xl',
    boxShadow: '0px 2px 1px 0px #773053 inset, 0px 4px 0px 0px #994300',
    bgSize: 'cover',
    bgImage: wood,
  },

  LinkCardHeader: {
    bg: 'transparent',
    color: 'epsilon.500',
  },

  TextCardHeading: {
    color: 'epsilon.500',
    fontFamily: 'CreteRound',
    fontWeight: 'normal',
  },

  TextRaceNumber: {
    bg: 'beta.200',
    borderRadius: 'sm',
    color: 'beta.700',
  },

  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },

  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'beta.200',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    boxShadow:
      '0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset, 0px -1px 3px 0px rgba(0, 0, 0, 0.25) inset',
    lineHeight: 'normal',
  },

  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.500',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  },

  TextRunner: {
    fontSize: 'xs',
    color: 'epsilon.500',
    fontWeight: 'semibold',
  },

  TextRunnerNumber: {
    _last: {
      color: 'epsilon.500',
      fontSize: '2xs',
      fontWeight: 'semibold',
    },
  },

  LinkRaceDetails: {
    color: 'gamma.700',
    bgGradient: 'linear(to-b, gamma.400, gamma.500)',
    boxShadow:
      '0px -2px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 0px 0px #FFB982 inset',
    textTransform: 'uppercase',
    fontFamily: 'Ultra',
    fontWeight: 'normal',
    textShadow: '0px 1px 0px rgba(255, 185, 130, 0.50)',
    lineHeight: 'normal',
    borderBottomRadius: 'xl',

    _hover: {
      bgGradient: 'linear(to-b, gamma.500, gamma.500)',
    },
  },

  buttonRaceCardOddsProps: {
    w: '100%',
    variant: 'solid',
    fontSize: 'sm',
    h: '10',
    fontWeight: 'black',
    fontFamily: 'body',
  },
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'epsilon.500',
  fontWeight: 'semibold',
};
