import { CSSObject } from '@chakra-ui/react';

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RunnerListItemWrapper: CSSObject = {
  borderRadius: 'md',
  p: '2',
  bg: 'var(--gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
  mb: '2',
};

export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {
  color: '#718096', // @TODO colour not in palette
};

export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
};

export const TextDeductions: CSSObject = {
  color: 'white',
};

export const TextScratchedInfo: CSSObject = {
  color: 'white',
  fontSize: '2xs',
};
export const ScratchedText: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
};
