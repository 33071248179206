import { CSSObject } from '@chakra-ui/react';

export const WrapperOnboarding: CSSObject = {
  contain: 'paint',
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  h: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  w: ['100dvw', null, 'unset'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, '10px'],
  bg: 'white',
  boxShadow: [null, null, '0px 8px 30px 0px rgba(0, 0, 0, 0.20)'],
  pos: 'relative',
};

export const Input: CSSObject = {
  '&.chakra-input': {
    backgroundColor: 'gamma.50',
    fontSize: 'xs',
    boxShadow: 'none',
    color: 'black',
    fontWeight: 'normal',
    '&&': {
      h: '38px',
      border: '1px solid',
      borderColor: 'gamma.200',
    },
    '&:focus, &:hover': {
      '&&': {
        backgroundColor: 'gamma.100',
      },
    },

    '&::placeholder': { color: 'gamma.200' },
  },
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  minW: [null, null, '468px'],
};

export const Title: CSSObject = {
  color: 'gray.500',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  mb: '4',
  display: 'flex',
  gap: '2.5',
};
