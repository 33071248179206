import { CSSObject } from '@chakra-ui/react';

export const SideNavHeading: CSSObject = {
  fontFamily: 'PatrickHand',
  fontWeight: 'regular',
  fontSize: 'lg',
  textTransform: 'uppercase',
  color: 'epsilon.700',
  px: '2',
  pb: '2.5',
  lineHeight: 'none',
  height: '40px',
  alignItems: 'end',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.200',
  _hover: {
    borderBottom: '2px dashed',
    borderColor: 'blackAlpha.200',
    color: 'epsilon.800',
    userSelect: 'none',
  },
};
