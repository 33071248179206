import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'extrabold',
    color: 'delta.400',
    textTransform: 'capitalize',
  },
  eventMarketName: {
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  eventSubtitle: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: 'xs',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    color: 'delta.400',
    fontSize: 'sm',
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '2',
    pt: '2',
    _last: {
      borderBottom: 'none',
    },
  },
  infoExoticsContainer: {
    pl: '9',
  },
  sgmLegsContainer: {
    mb: '2',
  },
};
