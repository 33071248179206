import { useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { keys } from '../api/api.keys';
import { queryNTJ } from '../api/nextToJump/nextToJump';
import { TNextToJumpResponse } from '../api/nextToJump/nextToJump.types';
import { getNTJStartTime } from '@/helpers/getNTJStartTime';
import { sortByRaceStart } from '@/helpers/sortRaces';
import { ERaceType } from '../lib/DBModels';

type TInterval = Record<ERaceType, number>;

export const useNTJ = () => {
  const [pollInterval, setPollInterval] = useState<
    TInterval | Partial<TInterval>
  >({});

  const raceTypes = [ERaceType.Horse, ERaceType.Greyhound, ERaceType.Harness];

  const queries = useQueries({
    queries: raceTypes.map((t) => {
      const interval =
        (pollInterval?.[t] ?? 0) > 0 ? pollInterval?.[t] ?? 5000 : 5000;

      return {
        queryKey: [keys.ntj, t],

        queryFn: () =>
          queryNTJ({
            race_type: t as ERaceType | undefined,
            limit: 11,
            ...(interval === 5000 && { t: 5 }),
          }),

        staleTime: 300000,
        refetchInterval: interval,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: 'always' as any,

        onSuccess: (data: TNextToJumpResponse) => {
          const firstRace = data?.[0];

          setPollInterval((state) => {
            const { msRemaining } = getNTJStartTime(
              firstRace?.start_time ?? ''
            );

            return {
              ...state,
              [firstRace?.race_type ?? '']:
                msRemaining > 0 ? Math.floor(msRemaining) : 0,
            };
          });
        },
      };
    }),
  });

  return {
    queries,
    dataSorted: [...(queries.flatMap((q) => q.data) ?? [])].sort((a, b) =>
      sortByRaceStart(a?.start_time, b?.start_time)
    ),
    isLoading: queries.some((q) => q.isInitialLoading),
  };
};
