import { Link } from 'react-router-dom';
import { Box, BoxProps, chakra, ChakraComponent } from '@chakra-ui/react';
import { styleImports } from './Settings.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxInner: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: {
    label: 'settings-BoxInner',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxInner;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const LinkWrapper = chakra(Link, {
  baseStyle: () => ({
    label: 'settings-LinkWrapper',
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    textDecor: 'none',
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    borderTop: '1px',
    borderTopColor: 'blackAlpha.50',

    _first: { borderTop: '0' },

    ...(() => {
      try {
        return styleImports[themeName]?.LinkWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
