import { CSSObject } from '@chakra-ui/react';
import bg from '../../../assets/gigabet/images/onboarding/onboardingBg.png';

export const WrapperFlexOnboarding: CSSObject = {
  bgImage: `url(${bg}), linear-gradient(180deg, var(--bc-colors-beta-800), var(--bc-colors-beta-900))`,
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
      "."
      "."
    `,
    null,
    `
      ". ."
    `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, 'md'],
  bg: 'transparent',
  pos: 'relative',
  w: ['100dvw', null, 'unset'],
  p: ['unset', null, '0px'],
  '.chakra-button[aria-label*="close"]': {
    color: 'alpha.200',
  },
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 30px 0px rgba(0, 0, 0, 0.4)',
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '460px'],
  h: '100%',
  bg: 'beta.400',
  borderRightRadius: [null, null, '10px'],
  '#email': {
    bg: 'beta.700',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    color: 'white',
    fontWeight: 'normal',
    borderRadius: 'lg',
    h: '38px',
    border: 'none',
    ':hover, :focus': {
      bg: 'beta.600',
    },
  },
};

export const Title: CSSObject = {
  color: 'white',
  fontSize: '16',
  fontFamily: 'accent',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  display: 'flex',
  mb: '4',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  fontSize: 'xs',
  '&&&': {
    borderRadius: 'base',
    bg: 'beta.700',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    border: 'none',
    color: 'white',
    fontWeight: 'normal',
    '&&:hover, &&:focus': {
      bg: 'beta.600',
    },
    ' &&:focus': {
      borderLeft: '2px var(--bc-colors-alpha-200) solid',
    },
    _placeholder: {
      color: 'white',
    },
  },
};
