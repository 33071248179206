import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  w: '22rem',
  bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  bgClip: 'text',
  fontFamily: 'Crimson Pro',
  fontSize: '42px',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '32.76px',
  textTransform: 'capitalize',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
};

export const ImageNoRaces: CSSObject = {
  w: '241px',
  mt: '6',
};
