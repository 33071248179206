import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import speaker from '@/assets/puntcity/images/speaker.png';

const FlexWrapper = chakra(Flex, {
  baseStyle: () => ({
    borderRadius: '10px',
    border: '3px solid',
    borderColor: 'beta.500',
    background: 'rgba(86, 215, 255, 0.10)',
    boxShadow:
      '0px 6px 20px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 6px rgba(80, 78, 243, 0.40) inset, 0px 0px 0px 3px rgba(0, 0, 0, 0.40) inset',
    backdropFilter: 'blur(4px)',
    h: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4',
    mb: ['4'],
  }),
});

const TextToggle = chakra(Text, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: () => ({
    fontSize: 'xl',
    color: 'gamma.400',
    fontFamily: 'accent',
    w: '70px',
    pos: 'relative',
    top: '-3px',

    _first: { textAlign: 'right' },
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  baseStyle: () => ({
    '.chakra-switch__track': {
      pos: 'relative',
      bg: 'blackAlpha.600',
      boxShadow:
        '0px 2px 2px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    },

    '.chakra-switch__thumb': {
      bg: `url(${speaker})`,
      bgSize: 'cover',
      boxSize: '50px',
      pos: 'absolute',
      inset: '0',
      bottom: '-1',
      my: 'auto',
      left: '-2.5',

      '&[data-checked]': { left: '-2.5' },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sports
      </TextToggle>
    </FlexWrapper>
  );
}
