import { CSSObject } from '@chakra-ui/react';
import warrior from '../../../../../../assets/questbet/images/backgrounds/warrior.png';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  mb: '2',
  p: ['2', '3', '5'],
  color: 'alpha.700',
  fontWeight: 'medium',
  borderRadius: 'sm',
  border: '2px solid',
  borderColor: 'white',
  boxShadow: ['none', null, 'lg'],

  bgGradient: ['none', null, 'linear(to-t, alpha.300, white)'],
  pos: 'relative',
  height: ['auto', null, '100px'],
  overflow: 'hidden',

  _after: {
    content: `""`,
    display: ['none', null, 'block'],
    w: '100%',
    h: '100%',
    pos: 'absolute',
    right: 0,
    bgPos: 'right',
    bgRepeat: 'no-repeat',
    bgSize: '100%',
    bgImage: `url(${warrior})`,
    bottom: 0,
  },
};

export const FlexHeadingMiscWrapper: CSSObject = {
  zIndex: 'overlay',
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', 'row'],
    alignItems: ['flex-start', 'center'],

    '.timestamp': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
};

export const TextSportName: CSSObject = {
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

export const TextVS: CSSObject = {
  alignSelf: 'center',
  color: 'alpha.500',
};
