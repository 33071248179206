import React from 'react';
import { Icon, Flex, Text } from '@chakra-ui/react';
import { ErrorCircle } from '@styled-icons/boxicons-solid';
import { getStrings } from '@/helpers/utils';
import { EBetSubmissionRejectionReason } from '../../Services/Betslip.types';

// This component shows when a general error occurs, not specific to a individual bet

const GeneralError = () => {
  const [
    {
      BetSlip: {
        betSlipBetCard: { errors: Strings },
      },
    },
  ] = getStrings();

  const localisedErrorMessage =
    Strings[EBetSubmissionRejectionReason.UnknownError];

  return (
    <Flex
      dir="row"
      w="full"
      minH="10"
      p="2"
      my="2"
      alignItems="center"
      backgroundColor="red.400"
    >
      <Icon w="4" h="4" mr="2" as={ErrorCircle} />

      <Text textColor="white">{localisedErrorMessage}</Text>
    </Flex>
  );
};

export default GeneralError;
