import { ErrorBannerSchema } from './ErrorBanner.styles';

export const errorBannerStyles: ErrorBannerSchema = {
  flexWrapper: {
    bg: 'red.500',
    h: '8',
    w: '72',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '30%',
    zIndex: 9999,
  },
  errorText: {
    color: 'white',
  },
};
