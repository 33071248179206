import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  overflow: 'hidden',
  borderRadius: 'md',

  '.chakra-accordion__item': {
    my: '2',
    bg: 'beta.100',
    boxShadow:
      '0px 3px 2px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
    borderRadius: 'lg',
    border: 'none',
  },

  '.chakra-accordion__panel': {
    color: 'beta.800',
    pb: '3',
    px: '3',
    gap: '4',
    '.chakra-text': {
      color: 'beta.800',
      _first: {
        color: 'beta.800',
      },
      _notFirst: {
        borderRadius: 'lg',
        bg: 'beta.200',
        color: 'beta.800',
        border: '1px solid',
        borderColor: 'beta.300',
        fontWeight: 'medium',
        opacity: '50%',
      },
    },
  },
};

export const AccordionHeader: CSSObject = {
  transitionDuration: 'normal',
  transitionProperty: 'common',
  h: '33px',
  mx: '1',
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'beta.800',
  },
  '.chakra-accordion__button': {
    borderBottom: '1px solid',
    borderBottomColor: 'var(--bc-colors-blackAlpha-400)',
    alignItems: 'center',
    pl: '10px',
    'svg[class*="StyledIconBase"]': {
      display: 'none',
    },
    '&[aria-expanded="false"]': {
      borderBottom: 'none',
    },
    _hover: {
      bg: 'none',
    },
  },
};

export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
    borderRadius: 'sm',
    border: '1px solid',
    borderColor: 'gray.400',
    _hover: {
      opacity: 1,
      borderColor: 'beta.800',
    },
    _checked: {
      borderColor: 'beta.800',
    },
  },
};

export const AccordionHeaderLogOut: CSSObject = {
  borderColor: 'transparent',
  boxShadow:
    '0px 3px 2px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
  color: 'white',
  borderRadius: 'lg',
  fontFamily: 'heading',
  fontSize: 'sm',
  pl: '3',
  h: '33px',
  mt: '-2',
  _last: {
    mb: '1',
    mx: '0',
  },
};

export const IconPerson: CSSObject = {
  display: 'none',
};

export const TextAccordion: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  fontFamily: 'heading',
  color: 'beta.800',
  mr: 'auto',
  lineHeight: '20px',
  h: '17px',
};

export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'beta.500',
  borderRadius: 'lg',
  boxShadow: '0px 3px 5px 0px var(--bc-colors-blackAlpha-400)',
  fontSize: 'xs',
  fontWeight: 'medium',
  gap: '1',
  p: '2',
  '.chakra-text': {
    color: 'beta.800',
    fontWeight: 'medium',
    textDecoration: 'none',
  },
  '.chakra-icon': {
    display: 'none',
  },
  svg: {
    display: 'none',
  },
};

export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'beta.800',
  },
  border: 'none',
  bg: 'alpha.500',
  mb: '0',
};
