import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  p: '2',
  border: 'none',
  bg: 'white',
  borderRadius: 'base',
  mx: '4',
  mb: '4',
};

export const CheckboxBoxed: CSSObject = {
  color: 'gray.700',
  right: 0,
  justifyContent: 'flex-end',
  mr: '3',
};

export const TextBoxed: CSSObject = {
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const SelectFieldContainer: CSSObject = {
  mb: '2',
  gap: '1',
  w: 'unset',
};
