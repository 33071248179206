import Black from '../../assets/sterlingparker/fonts/Roboto-Black.ttf';
import BlackItalic from '../../assets/sterlingparker/fonts/Roboto-BlackItalic.ttf';
import Bold from '../../assets/sterlingparker/fonts/Roboto-Bold.ttf';
import BoldItalic from '../../assets/sterlingparker/fonts/Roboto-BoldItalic.ttf';

import ExtraLight from '../../assets/sterlingparker/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '../../assets/sterlingparker/fonts/Roboto-ThinItalic.ttf';
import Light from '../../assets/sterlingparker/fonts/Roboto-Light.ttf';
import LightItalic from '../../assets/sterlingparker/fonts/Roboto-LightItalic.ttf';
import Regular from '../../assets/sterlingparker/fonts/Roboto-Regular.ttf';
import Italic from '../../assets/sterlingparker/fonts/Roboto-Italic.ttf';
import SemiBold from '../../assets/sterlingparker/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '../../assets/sterlingparker/fonts/Roboto-MediumItalic.ttf';

import CrimsonProBold from '@/assets/sterlingparker/fonts/CrimsonPro-Bold.ttf';
import CrimsonProBlack from '@/assets/sterlingparker/fonts/CrimsonPro-Black.ttf';

export const fontFace = `
  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${Light}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${LightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Regular}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${Italic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${Bold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${BoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${Black}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${BlackItalic}') format('opentype');
  }

  @font-face {
    font-family: 'CrimsonPro';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('${CrimsonProBold}') format('opentype');;
  }
  @font-face {
    font-family: 'CrimsonPro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${CrimsonProBlack}') format('opentype');
  }
`;
