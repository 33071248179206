import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import menuTile from '@/assets/volcanobet/images/sidenav/menu-tile.png';
import topVine from '@/assets/volcanobet/images/sidenav/vine-1.png';
import bottomVine from '@/assets/volcanobet/images/sidenav/vine-2.png';
import menuTop from '@/assets/volcanobet/images/sidenav/menu-top1x.png';
import menuTop2x from '@/assets/volcanobet/images/sidenav/menu-top.png';
import menuBottom from '@/assets/volcanobet/images/sidenav/menu-bottom1x.png';
import menuBottom2x from '@/assets/volcanobet/images/sidenav/menu-bottom.png';

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '6'],
};

export const FlexBetSlipModalContainer: CSSObject = {
  maxW: ['100%', '272px'],
  pt: ['1', null, null, null, '7'],
  pb: ['0', null, null, null, '8'],
  bg: `url(${menuTile})`,
  bgRepeat: 'repeat-y',
  bgSize: ['100%', '272px'],
  borderRadius: ['none', null, null, null, 'xl'],
  contain: 'layout',
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '85px',
    zIndex: '-1',
    backgroundImage: `url(${menuTop})`,
    backgroundSize: '272px',
    backgroundRepeat: 'no-repeat',
    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bg: `url(${menuTop2x})`,
      bgSize: '272px',
    },
  },
  _after: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '-7px',
    left: '0',
    width: '100%',
    height: '85px',
    backgroundImage: `url(${menuBottom})`,
    backgroundSize: '272px',
    backgroundRepeat: 'no-repeat',
    zIndex: '-1',
    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bg: `url(${menuBottom2x})`,
      bgSize: '272px',
      bgRepeat: 'no-repeat',
    },
  },
};

export const BetSlipContainer: CSSObject = {
  _before: {
    content: ['none', null, null, null, '""'],
    display: 'block',
    position: 'absolute',
    top: '-8px',
    right: '-18px',
    width: '80px',
    height: '120px',
    backgroundImage: `url(${topVine})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  _after: {
    content: ['none', null, null, null, '""'],
    display: 'block',
    position: 'absolute',
    bottom: '-12px',
    left: '-16px',
    width: '75px',
    height: '122px',
    backgroundImage: `url(${bottomVine})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '0',
  mt: '2',
  mx: '6',
  mb: isKeypadVisible && 'unset',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'epsilon.500',
  color: 'white',
  borderBottomRadius: ['none', null, null, null, 'xl'],
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
};

export const BetslipVStack: CSSObject = {
  spacing: '0',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  h: '6',
  opacity: '1',
  bg: 'epsilon.400',
  borderRadius: 'full',
  fontWeight: 'bold',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  color: 'white',
  minW: '16',
  border: '3px solid',
  borderColor: 'whiteAlpha.500',
  boxShadow: 'none',
  px: '3',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      bg: 'epsilon.400',
      borderColor: 'white',
      boxShadow: 'none',
      color: 'white',
    },
    '&&': {
      fontSize: '2xs',
    },
  },
};
