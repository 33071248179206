import { MarketLayoutStylesSchema } from './MarketLayout.styles';

export const matchDetailsStyles: MarketLayoutStylesSchema = {
  headerText: {},

  rowWrapper: {},
  rowWrapperFirst: {},
  rowWrapperWithBtn: {},

  tab: {},
};
