import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'medium',
  color: 'white',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  h: '7',
  p: '1',
  w: '12',
};

export const RowDivider: CSSObject = {
  border: '1px solid',
  borderColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  fontWeight: 'medium',
  ml: '3',
  color: 'white',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  px: '1',
  py: '0.5',
};

export const IconButton: CSSObject = {
  h: '8',
  minWidth: '8',
  ml: '2.5',
};

export const TrashIcon: CSSObject = {
  bg: 'alpha.300',
  color: 'white',
  boxSize: '7',
  p: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 0px 0px #210A15',
  border: '2px solid',
  borderColor: 'delta.700',
};

export const BoxInputContainer: CSSObject = {
  mb: '2',
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form-control': {
    mb: '0',
  },
  '&&': {
    '.chakra-input__left-addon': {
      borderLeftRadius: 'lg',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'alpha.300',
      color: 'alpha.600',
      borderColor: 'alpha.300',
    },
    '.chakra-input': {
      border: '1px solid',
      borderLeftRadius: 'none',
      bg: 'white',
      color: 'alpha.900',
      fontSize: 'xs',
      fontWeight: 'bold',
      borderRightRadius: 'base',
      borderColor: 'gray.300',
      borderLeft: 'none',
      '&': {
        '::placeholder': {
          color: 'gray.700',
          opacity: '1',
        },
      },
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  h: '44px',
  sx: {
    '.btn_span': {
      zIndex: '9',
    },
  },
};

export const bannerHeaderProps: TextProps = {
  fontWeight: '900',
  fontSize: 'xs',
  color: 'delta.700',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'normal',
  mt: '0.5',
};

export const buttonDepositsAvailableProps: CustomButtonProps = {
  fontSize: 'xs',
};
