import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'lg',
  bg: 'alpha.900',
  boxShadow: '0px 0px 0px 1px #FFFFFF1F inset, 0px 4px 10px 0px #0000004D',
  fontFamily: 'accent',
};
export const RunnerName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'normal',
  position: 'relative',
  top: '-0.5',
};
export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};
export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    borderLeft: '1px solid',
    borderColor: ['transparent', null, 'whiteAlpha.200'],
    pl: ['0', null, '2'],
  },
};
export const RunnerScratchedWrapper: CSSObject = {
  color: 'gamma.200',
};
export const TextDeductions: CSSObject = {
  color: 'gamma.200',
};
export const TextScratchedInfo: CSSObject = {
  color: 'gamma.200',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const ScratchedText: CSSObject = {
  color: 'gamma.200',
  fontWeight: 'semibold',
};
