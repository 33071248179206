import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/vikingbet/images/backgrounds/login-bg.png';
import bgMobile from '@/assets/vikingbet/images/backgrounds/login-bg-mobile.png';
import bgRepeat from '@/assets/vikingbet/images/backgrounds/texture-wood-1x.png';
import divRep from '@/assets/vikingbet/images/backgrounds/login-seperator.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${divRep}), url(${bgMobile}), url(${bgRepeat})`,
    null,
    `url(${bgDesktop}), url(${bgRepeat})`,
  ],
  bgSize: [
    'auto 32px, 100% 80%, auto',
    'auto 32px, 100% 86%, auto',
    '100%, auto',
  ],
  bgPos: 'center calc(100% - 64px), center calc(100% - 76px)',
  bgRepeat: ['repeat-x, no-repeat, repeat', null, 'no-repeat, repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'flex-end',
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['65vw', '70vw', 'full'],
  borderLeftRadius: '10px',
};
