import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { keys } from '../api/api.keys';
import { EPunterStatus } from '@/lib/punter/types';
import { useAppDispatch } from './useRedux';
import { signUserIn } from '../views/onboarding/services/onboarding.actions';
import { LoginSchema } from '../views/onboarding/services/schemas/loginSchema';
import { recordUserAction } from '../lib/api/activityLogging/actions';
import { ACTIVITY_LOG_SUMMARY, ACTIVITY_LOG_TYPE } from '../lib/Constants';
import { trackEvent } from '@/lib/analytics';

export const useSignIn = () => {
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignIn = async (values: LoginSchema) => {
    const punter = await dispatch(signUserIn(values)).unwrap();
    if (!punter) return;
    await dispatch(
      recordUserAction({
        action_type: ACTIVITY_LOG_TYPE.LOGIN,
        action_summary: ACTIVITY_LOG_SUMMARY[ACTIVITY_LOG_TYPE.LOGIN],
      })
    );

    await client.invalidateQueries([keys.auth]);

    trackEvent('login', {
      email: values.email,
    });

    let redirectURL = '/';
    if (
      !punter.identity_verified &&
      punter.status === EPunterStatus.Suspended_KYC
    )
      redirectURL = '/verification';
    navigate(redirectURL);
  };

  return {
    handleSignIn,
  };
};
