import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  '> div:first-child': {
    color: 'var(--bc-gray-500, #718096)',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    mb: '4',
  },
  form: {
    label: {
      color: 'var(--chakra-gray-500, #718096)',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '133%',
    },
    'select[name*="title-dropdown"], #residential-input': {
      h: '38px',
      borderRadius: '4px',
      border: '1px',
      borderColor: 'gray.300',
      background: 'var(--vikingbet-zeta-50, #FDFAF4)',
    },
    '[class*=chakra-checkbox]': {
      alignItems: 'flex-start',
      '&[data-checked]': {
        bg: 'unset',
        borderColor: 'inherit',
        color: 'black',
      },
    },
    'button[aria-label*="password toggle"]': {
      top: '2px',
    },
  },
};

export const Title: CSSObject = {
  color: 'var(--bc-gray-500, #718096)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
  mb: '4',
  mt: [null, null, '9'],
};
