import { keyframes } from '@chakra-ui/react';

import toggleOuter from '@/assets/gigabet/images/toggle/toggleOuter.svg';
import toggleInner from '@/assets/gigabet/images/toggle/toggleInner.svg';
import toggleTick from '@/assets/gigabet/images/toggle/toggleTick.svg';

export const FlexWrapper = {
  borderRadius: 'lg',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: ['1.5', null, 'unset'],
  mb: ['1', null, '4'],
  gap: '32px',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.45), 0px 1px 5px 0px #8297DE inset',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  h: '16',
  pos: 'relative',
};
export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'heading',
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 6px rgba(255, 202, 36, 0.6)'
    : '0px 1px 0px rgba(255, 255, 255, 0.15)',
  color: isActive ? 'alpha.400' : 'blackAlpha.600',
  letterSpacing: '1.8px',
  px: '2.5',
});
export const CustomSwitcher = (isChecked: boolean) => ({
  bg: 'transparent',
  pos: 'relative',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${toggleOuter})`,
      boxSize: '60px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-35px',
      right: '0',
      top: '-30px',
      animation: isChecked
        ? `
            ${keyframes`
              0% { transform: rotate(0deg) scale(1.0); }
              50% { transform: rotate(270deg) scale(1.1); }
              100% { transform: rotate(540deg) scale(1.0); }
            `} 0.4s linear`
        : `
            ${keyframes`
              0% { transform: rotate(540deg) scale(1.0); }
              50% { transform: rotate(270deg) scale(1.1); }
              100% { transform: rotate(0) scale(1.0); }
            `} 0.4s linear`,
      animationFillMode: 'forwards',
      transformOrigin: '34.8px 29.8px',
    },
    _after: {
      bgImg: `url(${toggleInner})`,
      boxSize: '28px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-14px',
      right: '0',
      top: '-14.5px',
      transform: 'scale(1.0)',
      animation: isChecked
        ? `${keyframes`
          0% { transform: scale(1.0); }
          50% { transform: scale(1.1); }
          100% { transform: scale(1.0); }
        `} 0.5s linear`
        : `${keyframes`
            0% { transform: scale(1.0); }
            50% { transform: scale(1.1); }
            100% { transform: scale(1.0); }
          `} 0.5s linear`,
    },
  },
  '.chakra-switch__track': {
    _before: {
      bgImg: `url(${toggleTick})`,
      boxSize: '40px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-20.5px',
      right: '0',
      top: '-20px',
      zIndex: 1,
      transform: 'scale(1.0)',
      animation: isChecked
        ? `
            ${keyframes`
              0% { transform: rotate(0deg) scale(1.0); }
              50% { transform: rotate(-240deg) scale(1.1); }
              100% { transform: rotate(-480deg) scale(1.0); }
            `} .8s ease-in-out`
        : `
                ${keyframes`
              0% { transform: rotate(-480deg) scale(1.0); }
              50% { transform: rotate(-240deg) scale(1.1); }
              100% { transform: rotate(0deg) scale(1.0); }
            `} .8s ease-in-out`,
      animationFillMode: 'forwards',
    },
  },
});
