import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { CustomButtonProps } from '@/components/Button/Button';

export const BetCardVStack: CSSObject = {
  pb: '0',
};

export const StakeCollapse: CSSObject = {
  px: '0',
};

export const ExoticsFooterContainer: CSSObject = {
  px: '0',
  pt: '0',
};

export const ExoticsNumberContainer: CSSObject = {
  minW: '6',
};

export const buttonStakeProps: CustomButtonProps = {
  variant: 'solid',
  flex: '1',
  fontSize: 'xs',
  p: '1',
  textTransform: 'capitalize',
  h: '9',
  borderRadius: 'md',
  fontFamily: 'Fraunces',

  spanProps: {
    fontWeight: 'medium',
    fontSize: 'xs',
    py: '1',
  },
};

export const BetOdds: CSSObject = {
  mb: '2px',
};

export const BetCardContainer = (): CSSObject => ({
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  borderRadius: 'md',
  color: 'white',
  p: '2',
  border: '2px',
  borderColor: '#FFE6B6',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  ml: '1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'beta.400',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  lineHeight: '4',
  mb: '-1',
});

export const EventSubtitle: CSSObject = {
  color: 'white',
  textTransform: 'capitalize',
  fontSize: 'xs',
  pt: '0.5',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  width: '25%',
  justifyContent: 'space-between',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const BetPlaced: CSSObject = {
  bgImage: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%);',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF;',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'black',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: 0.5,
};

export const buttonBetPropositionClosedProps: CustomButtonProps = {
  variant: 'unstyled',
  px: '7',
  zIndex: '1',
  bg: 'red.500',
  boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.25)',
  color: 'black',
  borderRadius: 'md',
  fontSize: 'xs',
  display: 'flex',
  gap: '1',
  spanProps: {
    color: 'white',
    fontFamily: 'roboto',
    fontSize: 'xs',
    fontWeight: 'bold',
    px: '3.5',
    py: '2',
    textTransform: 'uppercase',
  },
  _hover: {
    bg: 'red.700',
    color: 'white',
    boxShadow: 'md',
  },
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
  color: 'gray.600',
};

export const buttonBonusBetsProps = (
  isBonusBet: boolean,
  isInReview: boolean
): CustomButtonProps => ({
  fontFamily: 'body',
  textTransform: 'capitalize',
  w: isInReview ? '100%' : 'auto',
  isDisabled: isInReview,
  textShadow: 'none',
  fontSize: '2xs',
  fontWeight: 'medium',
  h: '6',
  px: '1.5',
  sx: {
    '&&': {
      minW: '20',
    },
  },
  _hover: {
    border: 'none',
    opacity: '1',
  },

  _disabled: {
    border: 'none',
    bg: 'gamma.500',
    color: 'alpha.400',
    opacity: '1',
    textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
    mt: '-3',
    h: '7',
    '& > span': {
      _first: {
        mr: '1',
      },
    },
  },
  ...((): CustomButtonProps => {
    if (isBonusBet && isInReview)
      return {
        bg: 'gamma.500',
        borderTopRadius: 'none',
        borderBottomRadius: 'md',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.30) inset',
        spanProps: {
          fontFamily: 'roboto',
          textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
          fontWeight: 'medium',
          fontSize: '2xs',
          py: '2',
          color: 'alpha.400',
          textTransform: 'uppercase',
        },
        _hover: {
          border: 'none',
        },
      };

    if (isBonusBet)
      return {
        bg: 'alpha.600',
        bgGradient: 'none',
        boxShadow: '0px 0px 2px 0px #FFDD31 inset',
        color: 'white',
        borderRadius: 'md',
        border: '1px',
        borderColor: 'alpha.400',
        sx: {
          '.chakra-button__icon': {
            mr: '0',
            ml: '1',
          },
        },
        spanProps: {
          fontFamily: 'roboto',
          textShadow: 'none',
          px: '2',
          py: '5px',
        },
      };

    return {
      color: 'beta.300',
      borderRadius: 'md',
      border: 'none',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
      bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
      sx: {
        '.chakra-button__icon': {
          mr: '0',
          ml: '1',
        },
      },
      spanProps: {
        fontFamily: 'roboto',
        textShadow: 'none',
        fontWeight: 'medium',
        fontSize: '2xs',
        px: '1',
        py: '1.5',
      },
    };
  })(),
});

export const BetPropositionClosedError: CSSObject = {
  bg: 'blackAlpha.500',
  borderRadius: 'md',
  pb: '3.5',
  top: '-1px',
  left: '-1px',
  right: '-1px',
  h: 'calc(100% + 2px)',
  w: 'auto',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'beta.400' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = () => ({
  '&&': {
    mt: '1',
    px: '0',
    pb: '0',
  },
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5', '&:hover': { color: 'white' } }} />
);

export const EventRule: CSSObject = {
  ml: '2',
};
