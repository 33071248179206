import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logo from '@/assets/fiestabet/images/logo.png';
import hat_img from '@/assets/fiestabet/images/hat.png';
import Heading from '../components/Heading';
import Body from '../components/Body';
import GlobalStyles from '@/layouts/SecondaryLayout/conditionals/GlobalStyles/template';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: <Img src={logo} alt={REACT_APP_THEME_NAME} w="48" my="10" />,
    heading: (
      <Flex direction="column" alignItems="center">
        <Img src={hat_img} alt={REACT_APP_THEME_NAME} w="60" />
        <Heading />
      </Flex>
    ),
    desc: <Body />,
    body: <GlobalStyles />,
  },
};

export default template;
