import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: 'md',
  },
  headerIconButton: {
    color: 'alpha.600',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'white',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    pt: '2',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'alpha.500',
    h: '1.25em',
    ml: '1',
    w: 'auto',
  },
  sgmLogoV2: {
    w: '8',
    h: 'auto',
  },
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'transparent',
    boxShadow: 'none',
    pos: 'relative',
    maxH: '9',
    gap: '4px',
  },
  scrollButtonGroupV2: {
    variant: 'unstyled',
    flex: 1,
    position: 'relative',
    minH: '34px',
    w: '90%',
    gap: 2, 
  },
  toMarketButton: {
    borderColor: 'beta.400',
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bg: 'white',
    border: '0',
    borderRadius: 'base',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    overflow: 'hidden',
  },
  countdown: {
    sx: {
      textShadow: 'none',
      mt: 2,

      '&&&.state-prior': {
        textTransform: 'capitalize',
      },
    },
  },
  accordionButton: {
    bgGradient: 'linear(to-b, alpha.600, alpha.800)',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
    _hover: {
      bgGradient: 'linear(to-b, alpha.700, alpha.700)',
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    px: '3',
  },
  clearButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    ml: '3',
    px: '3',
  },
  sgmBtn: {
    variant: 'solid',
    sx: {
      svg: {
        color: 'beta.300',
        fill: 'beta.300',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
      '&&': {
        _active: {
          bgGradient: 'linear(to-b, alpha.600, alpha.700)',
          boxShadow: 'none',
        },
      },
    },
  },
  toMarketButtonV2: {
    variant: 'unstyled',
    color: 'white',
    textTransform: 'uppercase',
    minW: 'fit-content',
    border: 'none',
    cursor: 'pointer',
    fontSize: 'xs',
    boxShadow: 'none',
    textShadow: 'none',
    fontWeight: 'bold',
    lineHeight: '1.7',
    borderRadius: 'none',

    sx: {
      '&&': {
        px: 3.5,
        ml: 0,
        h: 'auto',
      },

      '&[data-active]': {
        color: 'beta.400',
        boxShadow: 'none',
        bg: 'transparent',
        borderColor: 'red.400',

        _after: { display: 'none', bgImage: 'none' },
      },

      '.chakra-button__icon': {
        margin: 0,
      },
    },

    _last: { mr: 20 },
  },
  sgmBtnActive: {
    sx: {
      svg: {
        color: 'gamma.400',
        fill: 'gamma.400',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
      },

      '&&': {
        border: 'none',
        bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
        boxShadow:
          '0px 2px 2px 0px rgba(0, 0, 0, 0.36),0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
        borderColor: 'transparent',
      },
    },

    _after: {
      content: `''`,
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      inset: 0,
      bgGradient: 'linear(to-b, delta.800, delta.800)',
      zIndex: 8,
      borderRadius: 'md',
      m: '2px',
    },
  },
  wrapperScrollSgm: {
    gap: [null, null, 1],
  },
};
