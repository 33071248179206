import { ButtonProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

type TAccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/**
 * Util to handle color schemes within chakra buttons
 */
export const colorScheme = (props: ButtonProps) => {
  const { colorScheme: c } = props;

  /** Accessible color overrides for less accessible colors. */
  const accessibleColorMap: { [key: string]: TAccessibleColor } = {
    yellow: {
      bg: 'yellow.400',
      color: 'black',
      hoverBg: 'yellow.500',
      activeBg: 'yellow.600',
    },
    cyan: {
      bg: 'cyan.400',
      color: 'black',
      hoverBg: 'cyan.500',
      activeBg: 'cyan.600',
    },
  };

  if (c === 'gray') {
    const bg = mode(`gray.100`, `whiteAlpha.200`)(props);

    return {
      bg,
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) },
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c ?? ''] ?? {};

  return {
    '&&': {
      bg,
      color,
      _hover: { bg: hoverBg, _disabled: { bg } },
      _active: { bg: activeBg },
    },
  };
};
