import React from 'react';
import { Grid } from '@chakra-ui/react';
import { useNTJ } from '../../../../../hooks/useNTJ';
import Card from './Card';
import { raceCardStyles } from './styles/RaceCards.styles';

export default function RaceCards() {
  const { dataSorted } = useNTJ();
  const firstThree = dataSorted.slice(0, 3);

  return (
    <Grid {...raceCardStyles.GridRaceCards}>
      {firstThree.map((r) => {
        if (!Object.keys(r ?? {}).length) return null;

        return <Card key={`r-cards-${r?.race_id}`} race={r ?? {}} />;
      })}
    </Grid>
  );
}
