import * as yup from 'yup';
import dayjs from 'dayjs';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { getStrings } from '@/helpers/utils';
import { EPunterTitles } from '../../../../api/punter/punter.types';

const [
  {
    Onboarding: { Step1, Step2 },
  },
] = getStrings();
const phoneUtil = PhoneNumberUtil.getInstance();

const accountSetupSchema = yup
  .object({
    email: yup
      .string()
      .trim()
      .email(Step1.EmailInputError)
      .max(40, Step2.FieldTooLongErrorText)
      .required(Step1.EmailInputEmpty),
    password: yup
      .string()
      .matches(/^\S*$/, Step1.PasswordNoSpaces)
      .matches(
        /^.*(?=.{8,})(?!.* )(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z])).*$/, // At least 8 characters, at least 1 each uppercase & lowercase, at least 1 number.
        { message: Step1.PasswordInputError }
      )
      .max(40, Step2.FieldTooLongErrorText)
      .required(Step1.PasswordEmpty),
    ageAcknowledgement: yup
      .bool()
      .oneOf([true], Step1.AgeAcknowledgementError)
      .required(Step1.AgeAcknowledgementError),
    promotionAcknowledgement: yup.bool(),
    firstName: yup
      .string()
      .required(Step2.FirstNameErrorText)
      // Regex below:
      // uppercase letters (A-Z), lowercase letters (a-z), apostrophe ('), hyphen/dash (-), and spaces OR
      // any unicode letter eg. accents
      .matches(/^([A-Za-z '-]|[\p{L} '-])*$/u, Step2.ValidNameRequiredErrorText)
      .max(40, Step2.FieldTooLongErrorText),
    middleName: yup
      .string()
      .matches(
        /^([A-Za-z '-]|[\p{L} '-])*$/u,
        'onboarding.step2.validMiddleNameRequiredErrorText'
      )
      .max(40, 'onboarding.step2.fieldTooLongErrorText'),
    lastName: yup
      .string()
      .required(Step2.LastNameErrorText)
      .matches(/^([A-Za-z '-]|[\p{L} '-])*$/u, Step2.ValidNameRequiredErrorText)
      .max(40, Step2.FieldTooLongErrorText),
    mobile: yup
      .string()
      .test({
        name: 'AU Phone Number',
        message: Step2.MobileNumberAUOnlyError,
        test: (mobileNum) => {
          let res = false;
          if (!mobileNum) return res;
          try {
            res = phoneUtil.isValidNumberForRegion(
              phoneUtil.parse(mobileNum, 'AU'),
              'AU'
            );
          } catch (err) {
            return false;
          }
          return res;
        },
        params: { abortEarly: false },
      })
      .required(Step2.MobileRequiredErrorText),
    residential: yup.string().when('manualAddress', {
      is: false,
      then: yup.string().required(Step2.AddressRequiredErrorText),
    }),
    dateOfBirth: yup
      .date()
      .max(dayjs().subtract(18, 'year').toDate(), Step2.DobInputError)
      .required(Step2.DobRequiredErrorText),
    manualAddress: yup.boolean(),
    streetNumber: yup.string().when('manualAddress', {
      is: true,
      then: yup
        .string()
        .required(Step2.StreetNumberRequiredError)
        .max(15, Step2.FieldTooLongErrorText),
    }),
    streetName: yup
      .string()
      .when('manualAddress', {
        is: true,
        then: yup.string().required(Step2.StreetNameRequiredError),
      })
      .max(40, Step2.FieldTooLongErrorText),
    suburb: yup
      .string()
      .when('manualAddress', {
        is: true,
        then: yup.string().required(Step2.SuburbRequiredError),
      })
      .max(40, Step2.FieldTooLongErrorText),
    state: yup.string().when('manualAddress', {
      is: true,
      then: yup.string().required(Step2.StateRequiredError),
    }),
    postcode: yup.string().when('manualAddress', {
      is: true,
      then: yup
        .string()
        .length(4, Step2.PostcodeLengthError)
        .required(Step2.PostcodeRequiredError),
    }),
    title: yup
      .string()
      .required(Step2.TitleRequiredError)
      .oneOf(Object.values(EPunterTitles), 'Invalid title'),
    promoCode: yup.string(),
  })
  .required();

export type AccountSetupSchema = yup.InferType<typeof accountSetupSchema>;

export default accountSetupSchema;
