import api from '@/api/api';
import {
  TQueryCompetitionsResponse,
  TQueryCompetitionsParams,
} from './competitions.types';

export const queryCompetitionsEndpoint =
  '/punter/sports/competitions-tournaments';

export async function queryCompetitions(params?: TQueryCompetitionsParams) {
  const res = await api.get<TQueryCompetitionsResponse>(
    queryCompetitionsEndpoint,
    { params }
  );
  return res;
}
