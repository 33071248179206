import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'secondary',
    px: '2',
  },
}
