import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bg: 'orange.100',
  borderRadius: 'base',
  color: 'gray.800',
  fontSize: 'sm',
  fontWeight: 'bold',
  mb: '2',
  px: '3',
  py: '4',
};

export const ButtonGetVerified: CSSObject = {
  bg: 'orange.500',
  border: '2px',
  borderRadius: 'base',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  h: '6',
  minW: 'auto',
  ml: 'auto',
  px: '2.5',
  textTransform: 'uppercase',

  _hover: {
    bg: 'orange.600',
  },

  _active: {
    bg: 'orange.700',
  },
};
