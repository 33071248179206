import { Box, BoxProps, chakra, Text, TextProps } from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './PrimaryWrapper.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxWrapper = chakra(Box, {
  label: 'primaryWrapper-BoxWrapper',
  baseStyle: ({ theme }) => ({
    minH: '100%',
    borderRadius: [null, null, null, 'lg'],
    // 'contain: paint' removes the ability to correctly set 'position: fixed'
    // on elements, which is necessary for the mobile exotics selection popup
    contain: ['unset', null, 'paint'],
    display: 'flex',
    flexDir: 'column',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'unset',
      color: 'beta.500',
      m: '2',
      borderRadius: [null, null, null, 'base'],
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      mx: ['2', 'unset'],
      color: 'beta.700',
      mt: '2',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxHeading = chakra(Box, {
  label: 'primaryWrapper-BoxHeading',
  baseStyle: ({ theme }) => ({
    mb: '5',

    ...(theme.themeName === EThemes.Betgalaxy && {
      mb: '0',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TTextTitle = {
  raceMeetingsTime: string;
} & TextProps &
  any;

export const TextTitle = chakra<TTextTitle>(Text, {
  label: 'primaryWrapper-TextTitle',
  shouldForwardProp: (prop) => !['raceMeetingsTime'].includes(prop),
  baseStyle: ({ theme, raceMeetingsTime }: any) => ({
    mt: '7',
    fontSize: '2xl',
    textTransform: 'uppercase',

    ...(theme.themeName === EThemes.Betgalaxy && {
      mt: '0',
      fontWeight: 'semibold',
      color: 'beta.700',
      fontSize: ['lg', null, 'xl'],
      textTransform: 'capitalize',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle?.({
          raceMeetingsTime,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export interface ContentContainerProps {
  fullWidthMobile: boolean;
  fullWidth: boolean;
}

export const BGWrapper = chakra<any>('div', {
  label: 'primaryWrapper-BGWrapper',
  shouldForwardProp: (prop) => !['shouldHideOverflow'].includes(prop),
  baseStyle: () => ({
    bg: 'unset',
    flex: 1,
    maxW: '100%',
  }),
});

export const TextHeading = chakra<any>(Text, {
  baseStyle: () => ({
    pt: '12',
    pb: '6',
    fontSize: 'xl',
    fontStyle: 'normal',
  }),
});

export const MainContainer = styled.div<{ shouldHideOverflow: boolean }>`
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;

  ${({ theme, shouldHideOverflow }) =>
    shouldHideOverflow
      ? theme.layouts.mainLayout.mainContainerShouldHideOverflow
      : theme.layouts.mainLayout.mainContainer};
`;

export const WidthContainer = styled.div<{ fullWidth: boolean }>`
  flex-direction: column;
  display: flex;

  ${({ theme }) => theme.device.tablet} {
    width: 100%;
    ${({ fullWidth }) =>
      !fullWidth ? 'max-width: 1200px; margin: 0 auto;' : ''}
  }
`;

type TContentContainer = {
  fullWidthMobile: boolean;
  fullWidth: boolean;
} & BoxProps &
  any;
export const ContentContainer = chakra<TContentContainer>('section', {
  shouldForwardProp: (prop) => !['fullWidthMobile', 'fullWidth'].includes(prop),
  baseStyle: () => ({
    label: 'ContentContainer',
    transition: 'all 0.2s ease-in-out',
  }),
});

export const WrapperContainer = chakra(Box, {
  label: 'primaryWrapper-WrapperContainer',
  baseStyle: ({ theme }) => ({
    flex: '1',
    maxW: 'container.xl',
    minH: 'calc(100vh - 350px)',

    ...(theme.themeName === EThemes.Wellbet && {
      px: '0',
      bg: 'white',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: ['white'],
      px: ['2', 0],
      h: 'full',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.WrapperContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TTitleContainer = {
  raceMeetingsTime: string;
} & BoxProps &
  any;

export const TitleContainer = chakra<TTitleContainer>(Box, {
  shouldForwardProp: (prop) => !['raceMeetingsTime'].includes(prop),
  baseStyle: ({ raceMeetingsTime }: any) => ({
    label: 'primaryWrapper-TitleContainer',
    w: 'full',
    bg: 'white',
    py: '3',
    pl: ['2', null, '3'],
    pr: ['0', '2', '3'],
    mt: ['unset', '2'],
    borderTopRadius: 'md',
    overflow: 'hidden',
    ...(() => {
      try {
        return styleImports[themeName]?.TitleContainer?.({
          raceMeetingsTime,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});
