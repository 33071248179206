import React from 'react';
import { Image } from '@chakra-ui/react';
import footerLogo from '@/assets/betprofessor/images/logo/logo.svg';

const Index = () => (
  <Image
    src={footerLogo}
    sx={{
      minW: ['264px', null, null, '203px'],
      ml: ['2', null, null, 'unset'],
      label: 'FooterLogoImage',
    }}
  />
);

export default Index;
