import { Collapse, useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { TExtendedProposition } from '../../MatchDetailPage/services/MatchDetailPage.types';
import {
  LIMIT,
  MarketLayoutPagination,
  MarketLayoutRow,
  MarketLayoutTabPanel,
  MarketLayoutTabs,
  TMarketLayoutProps,
} from '../MarketLayout/MarketLayout';
import PropositionButton from '../PropositionButton/PropositionButton';

export type TListLayoutProps = Pick<
  TMarketLayoutProps,
  'parts' | 'onSelection'
>;

export default function ListLayout({ parts, onSelection }: TListLayoutProps) {
  const { isOpen, onToggle } = useDisclosure();

  const [currentIdx, setCurrentIdx] = useState(0);
  const shouldRenderPagination = useMemo(() => {
    const part = parts[currentIdx];
    const maxRowCount = Math.max(
      part.markets.flatMap((m) => m.propositions).length
    );
    return maxRowCount > LIMIT;
  }, [parts, currentIdx]);

  return (
    <>
      <MarketLayoutTabs
        labels={parts.map(({ title }) => title)}
        onChange={(i) => setCurrentIdx(i)}
      >
        {parts.map(({ title, markets }, idx) => {
          const propositions = markets
            .flatMap((m) => m.propositions)
            .filter((p): p is TExtendedProposition => !!p);

          if (!propositions.length) {
            return (
              <MarketLayoutTabPanel key={idx}>
                <MarketLayoutRow isFirst>N/A</MarketLayoutRow>
              </MarketLayoutTabPanel>
            );
          }

          return (
            <MarketLayoutTabPanel key={title}>
              {[...propositions].slice(0, LIMIT).map((p, i) => (
                <MarketLayoutRow key={p.proposition_id} isFirst={!i}>
                  {p.proposition_name}

                  <PropositionButton
                    isActive={p.is_selected}
                    data-active={p.is_selected}
                    isDisabled={p.is_disabled}
                    isSuspended={p.is_suspended}
                    minW="16"
                    odds={p.return_amount}
                    onClick={() => onSelection?.(p)}
                  />
                </MarketLayoutRow>
              ))}

              {shouldRenderPagination && (
                <Collapse in={isOpen} animateOpacity unmountOnExit>
                  {[...propositions].slice(LIMIT).map((p) => (
                    <MarketLayoutRow key={p.proposition_id}>
                      {p.proposition_name}

                      <PropositionButton
                        isActive={p.is_selected}
                        data-active={p.is_selected}
                        isDisabled={p.is_disabled}
                        isSuspended={p.is_suspended}
                        minW="16"
                        odds={p.return_amount}
                        onClick={() => onSelection?.(p)}
                      />
                    </MarketLayoutRow>
                  ))}
                </Collapse>
              )}
            </MarketLayoutTabPanel>
          );
        })}
      </MarketLayoutTabs>

      <MarketLayoutPagination
        in={shouldRenderPagination}
        isOpen={isOpen}
        onPageClick={onToggle}
      />
    </>
  );
}
