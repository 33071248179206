import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { borderRadius: 'md' },
  buttonCashOutAmount: {
    variant: 'solid',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, epsilon.600, epsilon.600)',
    color: 'white',

    sx: {
      '&:hover': {
        bgGradient: 'linear(to-b, epsilon.300, epsilon.500)',
      },
    },
  },
  flexOddsBoostBanner: {
    bgGradient: 'linear(to-b, epsilon.400, epsilon.400)',
    borderTop: 'none',
    fontSize: 'md',
    color: 'gamma.500',
  },
  textOddsBoost: {
    color: 'gamma.500',
    fontWeight: 'medium',
  },
  iconCashOut: {
    fill: 'epsilon.400',
  },
  cashoutText: {
    color: 'epsilon.400',
    textShadow: 'none',
  },
  cashoutValue: {
    color: 'epsilon.400',
    textShadow: 'none',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'epsilon.500',
};

export const RaceTime: CSSObject = {
  color: 'gray.500',
  fontWeight: 'medium',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.700',
  color: 'white',
  fontSize: 'sm',
};

export const BonusBetText: CSSObject = {
  color: 'beta.700',
  fontSize: 'sm',
};

export const BetReturn: CSSObject = {
  bg: 'epsilon.50',
  color: 'epsilon.600',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
  border: '1px',
  borderColor: 'epsilon.600',
  boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)',
};

export const BoxBetReturnValue: CSSObject = {
  bg: 'epsilon.600',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'white',
};

export const BoxBetReturnText: CSSObject = {
  p: '1',
  color: 'epsilon.600',
};
