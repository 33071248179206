import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'gamma.600',
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'white',
  minW: 'unset',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'md',
};

export const FlexGroupWrapper: CSSObject = {
  my: '3',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
};
