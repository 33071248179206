import { CSSObject } from '@chakra-ui/react';

export const DatePickerLabel: CSSObject = {
  color: 'var(--bc-gray-500, #718096)',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
};

export const StyledDatePicker: CSSObject = {
  '.react-date-picker__wrapper': {
    h: '43.5px',
    borderRadius: '4px',
    border: '1px solid var(--chakra-gray-300, #CBD5E0)',
    background: 'var(--vikingbet-zeta-50, #FDFAF4)',
    display: 'flex',
    padding: '12px 10px',
    alignItems: 'center',
    gap: '6px',
    alignSelf: 'stretch',
    transition: 'all .2s ease-in-out',
    fontSize: 'xs',
    px: '2',

    _hover: {
      bg: 'gray.200',
    },
  },
};
