import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/puntcity/images/backgrounds/featured-homepage.png';
import bgMobile from '@/assets/puntcity/images/backgrounds/featured-homepage-mobile.png';

export const BoxCard: CSSObject = {
  p: ['1.5'],
  borderRadius: ['10px'],
  bg: 'rgba(81, 208, 247, 0.10)',
  boxShadow:
    '0px 6px 20px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 4px rgba(80, 78, 243, 0.6) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset',
  backdropFilter: 'blur(5px)',
  border: '3px solid',
  borderColor: 'beta.500',
  w: ['265px'],
  mx: ['2', null, 'unset'],
};

export const FlexFeatureWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  bg: [null, null, `url(${bg})`],
  bgSize: [null, null, 'cover'],
  backgroundPositionX: ['30%', null, 'unset'],
  bgRepeat: 'no-repeat',
  borderRadius: 0,
  borderBottom: [null, null, '3px solid'],
  borderBottomColor: [null, null, 'gamma.400'],
  mb: ['8', null, '4'],
  pt: [0, null, '9'],
  pb: [0, null, '9'],
  mx: ['0'],
  px: ['0', null, '31px'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  flex: 0,
};

export const FlexFeatureTitleWrapper: CSSObject = {
  alignItems: ['flex-end', null, 'unset'],
  bg: [`url(${bgMobile})`, null, 'unset'],
  bgSize: ['cover'],
  justifyContent: ['center', null, 'flex-start'],
  mt: [0, null, '6'],
  flex: ['unset', null, 2],
  h: ['190px', '240px', 'unset'],
};

export const HeadingSlogan: CSSObject = {
  textTransform: 'capitalize',
  color: '#6FF4FF',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
  fontSize: ['28px', '40px'],
  maxW: ['8ch', null, 'unset'],
  textAlign: ['center'],
  mr: ['10', null, 'unset'],
  lineHeight: ['1', null, 'unset'],
};

export const ImageMascot: CSSObject = {};

export const TextFeatureStyledHeading: CSSObject = {};

export const LinkCardHeader: CSSObject = {
  '.state-prior': { color: 'white' },
};

export const IconCardHeader: CSSObject = {
  color: 'gamma.500',
  boxSize: '4',
};

export const TextCardHeader: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
};

export const TextRaceNumber: CSSObject = {
  borderRadius: 'base',
  bg: 'alpha.600',
  h: '18px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '18px',
  px: '1',
  color: 'gamma.400',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'rgba(0, 0, 0, 0.20)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 4px -3px rgba(0, 0, 0, 0.30) inset',
  fontSize: '2xs',
  color: 'delta.500',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  px: '2.5',
};

export const ImageRunnerSilk: CSSObject = {
  boxSize: '8',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '1px',
  borderBottomColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20)',
  py: 0,
  h: '46px',
};

export const TextRunner: CSSObject = {
  fontSize: 'smxs',
  color: 'white',
  fontWeight: 'semibold',
};

export const TextScratched: CSSObject = {
  fontSize: '8px',
  color: 'white',
  textDecoration: 'line-through',
};

export const TextRunnerNumber: CSSObject = {
  fontSize: 'smxs',
  '&:nth-child(2)': {
    fontSize: '2xs',
    position: 'relative',
    top: '-0.5',
  },
};

export const Button = (): CSSObject => ({
  borderRadius: 'md',
  fontSize: 'sm',
  fontFamily: 'body',
  fontWeight: 'bold',
});

export const LinkRaceDetails: CSSObject = {
  bg: 'rgba(68, 72, 210, 0.50)',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'gamma.400',
  textTransform: 'uppercase',
  borderBottomRadius: 'base',
};
