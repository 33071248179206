import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bg: 'rgba(114, 68, 238, 1)',
    borderRadius: 'lg',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    border: 'none',
    sx: {
      '.chakra-modal__close-btn': {
        color: 'gamma.400',
      },
      '.chakra-modal__header': {
        fontFamily: 'Staatliches',
        fontWeight: 'normal',
      },
    },
  },
  stackWrapper: {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
  },
  heading: { color: 'white' },
  moneyValues: { color: 'gamma.400' },
  cashedOutText: {},
  cashedOutQuestion: { color: 'white' },
  declineButton: {
    variant: 'outline',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  acceptButton: {
    variant: 'secondary',
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: 'alpha.700',
  },
  doneButton: {},
};
