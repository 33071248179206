import { CSSObject } from '@chakra-ui/react';

export const UnableToCreateMultiWrapper: CSSObject = {
  py: '3',
  bg: 'blackAlpha.300',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 8px 0px rgba(0, 0, 0, 0.25) inset',
  alignItems: 'center',
  borderRadius: 'lg',
  mb: '3',
};

export const MultiCardWrapper: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  borderRadius: 'md',
  color: 'white',
  p: '2',
  border: '2px',
  borderColor: '#FFE6B6',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const FlexEventDetails: CSSObject = {
  gap: '1.5',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '0',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
  px: '0',
  py: '0',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'beta.400',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};

export const EventTitle: CSSObject = {
  color: 'beta.400',
  fontFamily: 'roboto',
};

export const InvalidityBox: CSSObject = {
  color: 'white',
  fontFamily: 'roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '110%',
};
