import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './RaceResults.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxResultedWrapper = chakra(Box, {
  label: 'raceResults-BoxResultedWrapper',
  baseStyle: ({ theme }) => ({
    mb: '5',
    ...(theme.themeName === EThemes.Wellbet && {
      px: '2',
      border: '1px',
      borderColor: 'alpha.200',
      bg: 'alpha.50',
      borderRadius: 'base',
      mb: '2',
      flex: '1',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      mb: '0',
      border: '1px',
      borderColor: 'gray.300',
      borderRadius: ['base', null, 'md'],
      flex: '2 1 auto',
      bg: 'gray.100',
      px: '2',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxResultedWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexEventRule = chakra(Flex, {
  label: 'raceResults-FlexEventRule',
  baseStyle: ({ theme }) => ({
    w: 'unset',
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'gray.500',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      alignItems: 'end',
      pr: '2',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexEventRule;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerResultRow = chakra(Flex, {
  label: 'raceResults-RunnerResultRow',
  baseStyle: ({ theme }) => ({
    fontSize: 'sm',
    alignItems: 'center',
    borderTop: '1px',

    ...(theme.themeName === EThemes.Wellbet && {
      borderStyle: ['solid', null, 'dashed'],
      borderColor: 'blackAlpha.300',
      py: '1.5',
      h: '12',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      borderStyle: 'dashed',
      borderColor: 'gray.300',
      py: ['2', null, '1'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerResultRow;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerDetails = chakra(Flex, {
  label: 'raceResults-RunnerDetails',
  baseStyle: ({ theme }) => ({
    flex: '1',
    alignItems: 'center',
    gap: ['1', null, '3'],
    ...(theme.themeName === EThemes.Wellbet && {
      gap: '1',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerDetails;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerPlace = chakra(Flex, {
  label: 'raceResults-RunnerPlace',
  baseStyle: ({ theme }) => ({
    boxSize: ['8', null, '10'],
    border: '2px',
    borderColor: 'green.600',
    color: 'green.600',
    borderRadius: 'full',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: ['2xs', null, 'xs'],
    fontWeight: 'bold',
    ...(theme.themeName === EThemes.Wellbet && {
      borderRadius: 'base',
      bg: 'gamma.500',
      color: 'yellow.900',
      '&&': {
        h: '6',
      },
      border: 'none',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      boxSize: 'none',
      width: '8',
      height: '6',
      background: 'beta.400',
      bgGradient: 'linear(to-b, beta.500, beta.400)',
      color: 'white',
      border: 'none',
      borderRadius: 'sm',
      boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.8)',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerPlace;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerNumber = chakra(Box, {
  label: 'raceResults-RunnerNumber',
  baseStyle: ({ theme }) => ({
    fontWeight: 'medium',
    fontSize: ['xs', null, 'sm'],
    color: 'beta.600',
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'bold',
      fontSize: 'xs',
      color: 'gray.500',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'bold',
      fontSize: 'xs',
      color: 'gray.500',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerPlaceText = styled.span`
  ${({ theme }) => theme.features.racing.raceResults.runnerPlaceText};
`;

export const RunnerOddsContainer = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    label: 'raceResults-RunnerOddsContainer',
    gap: '5',
    ...(theme.themeName === EThemes.Wellbet && {
      gap: '1',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      gap: '1',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerOddsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerOdds = chakra(Flex, {
  label: 'raceResults-RunnerOdds',
  baseStyle: ({ theme }) => ({
    flexDir: 'column',
    alignItems: 'center',
    border: '2px solid',
    justifyContent: 'center',
    ...(theme.themeName === EThemes.Wellbet && {
      w: '12',
      h: '30px',
      border: 'none',
      bg: 'blackAlpha.200',
      borderRadius: 'base',
      color: 'gray.600',
      fontWeight: 'bold',
      fontSize: 'xs',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      w: '10',
      h: '10',
      border: 'none',
      color: 'gray.800',
      fontWeight: 'bold',
      fontSize: 'xs',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerOdds;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const OddsText = chakra(Text, {
  label: 'raceResults-OddsText',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.OddsText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetType = chakra(Text, {
  label: 'raceResults-BetType',
  baseStyle: () => ({
    fontSize: '2xs',
  }),
});

export const Heading = chakra(Flex, {
  label: 'raceResults-Heading',
  baseStyle: ({ theme }) => ({
    flex: '1',
    gap: '1',
    fontSize: 'xs',
    textTransform: 'uppercase',
    ...(theme.themeName === EThemes.Wellbet && {
      textTransform: 'capitalize',
      color: 'gray.700',
      fontSize: 'xs',
      fontWeight: 'medium',
      py: '2',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      borderTopRadius: ['none', null, 'md'],
      textTransform: [null, null, 'capitalize'],
      color: 'black',
      fontSize: ['2xs', null, 'xs'],
      fontWeight: 'medium',
      pt: '2.5',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Heading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceResultsHeader = chakra(Flex, {
  label: 'raceResults-RaceResultsHeader',
  baseStyle: ({ theme }) => ({
    flex: '1',
    ...(theme.themeName === EThemes.Betgalaxy && {
      pt: '0',
      flex: '1',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.RaceResultsHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const WinPlaceHeader = chakra(Flex, {
  label: 'raceResults-WinPlaceHeader',
  baseStyle: ({ theme }) => ({
    py: '1',
    minW: '12',
    fontSize: '2xs',
    textAlign: 'center',
    justifyContent: 'center',
    ...(theme.themeName === EThemes.Wellbet && {
      py: '0',
      w: '12',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      py: '0',
      w: '10',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.WinPlaceHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
