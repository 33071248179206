import {
  footerBaseStyle,
  TFooterTheme,
} from '../../base/customComponents/footer';
import { TStyleConfig } from '../../base/types';

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: {
    ...footerBaseStyle,
    footer: {
      ...footerBaseStyle.footer,
      pb: '0',
      bg: 'transparent',
      bgSize: 'cover',
      bgPos: 'bottom',
      py: '0',
      color: 'white',
    },
    stackGap: '0',
    footerBar: {
      display: 'flex',
      flexDir: ['column', null, 'row'],
      pr: ['0', null, '3'],
      mb: '8',
      borderRadius: 'md',
      alignItems: 'center',
      justifyContent: ['center', null, 'unset'],
      '& > img': {
        maxW: '250px',
        h: '55px',
        ml: '0',
      },
    },
    footerMobile: {
      border: '2px',
      borderColor: 'white',
      borderRadius: 'lg',
      w: '130px',
      cursor: 'pointer',
      boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.3)',
    },
    footerText: {
      fontSize: 'sm',
    },
    footerLink: {
      color: 'delta.500',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 'sm',
    },
    footerLinkTel: {
      color: 'delta.500',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 'sm',
    },
    customFooterLink: {
      color: 'delta.500',
      textTransform: 'capitalize',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 'sm',
    },
    footerLinks: {
      ml: ['unset', null, 'auto'],
      pb: ['4', null, '0'],
      alignItems: 'center',
      justifyContent: ['space-around', null, 'unset'],
      color: 'white',
      fontWeight: 'medium',
      flexDir: ['row', null, 'unset'],
      display: 'inline-flex',
      mt: ['3', null],
      gap: '2',
    },
    footerContentContainer: {
      px: ['2.5', 'unset'],
      gap: '6',
      ...footerBaseStyle.footerContentContainer,
      bg: [null, null, 'transparent'],
      justifyContent: 'space-between',
      '&': {
        flexDirection: ['column', null, 'column'],
      },
    },
    nav: {
      ...footerBaseStyle.nav,
      fontSize: 'sm',
      fontWeight: 'medium',
      minW: [null, null, '370px'],
      mt: ['0', 'unset'],
      display: ['flex', 'grid'],
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto',
      columnGap: '28',
      rowGap: '2',
      alignItems: ['center', 'end'],
      gridTemplateAreas: `
        ". ."
      `,

      '> a': {
        color: 'white',
      },
    },
    blurb: {
      ...footerBaseStyle.blurb,
      flex: 'unset',
      textAlign: 'left',
      color: 'white',
      width: 'unset',
      mb: '0',
      ml: [null, '0'],
      mr: [null, null, '4'],
    },
    blurbHeading: {
      ml: 'unset',
      mt: 'unset',
      color: 'white',
    },
    blurbHeadingAge: {
      ...footerBaseStyle.blurbHeadingAge,
      borderColor: 'blackAlpha.50',
      border: '4px',
      mb: '2',
      boxSize: '8',
      fontSize: 'xs',
    },
    blurbHeadingThink: {
      fontSize: 'xs',
      fontWeight: 'bold',
      mb: '4',
    },
    blurbContent: {
      ...footerBaseStyle.blurbContent,
      ml: 'unset',
      mr: [null, null, 'auto'],
    },
    copyright: {
      ...footerBaseStyle.copyright,
      color: 'white',
      textAlign: ['center', 'left'],
      mt: ['7'],
      px: ['8', 'unset'],
      py: '0',
      fontWeight: ['semibold', 'medium'],
    },
    container: {
      bg: 'transparent',
      borderRadius: ['0', '0', '8'],
      p: '4',
      mt: '3',
    },
    emailUs: {
      fontSize: 'md',
      fontWeight: 'bold',
    },
    quickLinks: {
      color: 'white',
      fontSize: 'md',
      textAlign: ['center', 'left'],
    },
    ourPartners: {
      fontSize: 'md',
    },
    weAccept: {
      fontSize: 'md',
    },
  },
  sizes: {},
  variants: {},
};
