import { CSSObject } from '@chakra-ui/react';

export const ModalOverlay = (): CSSObject => ({
  bg: 'rgba(18, 55, 81, .7)', // Bespoke
});

export const ModalHeader: CSSObject = {
  color: 'gray.700',
  fontWeight: 'bold',
  pb: '2',
  px: '4',
};

export const ModalBody: CSSObject = {
  color: 'gray.600',
  fontSize: 'sm',
  pb: '4',
  pt: '1',
  px: '4',
};

export const ButtonDeposit: CSSObject = {
  mt: '3',
};
