import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bgGradient: 'linear(to-b, alpha.300, alpha.400)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset',
  },
  stackWrapper: {
    bg: 'alpha.500',
  },
  heading: { color: 'whiteAlpha.700' },
  moneyValues: { color: 'white' },
  cashedOutText: { color: 'white' },
  cashedOutQuestion: { color: 'white' },
  declineButton: {
    variant: 'outline',
  },
  acceptButton: { variant: 'secondary' },
  doneButton: { variant: 'secondary' },
};
