import { CSSObject } from '@chakra-ui/react';
import menuPattern from '@/assets/sugarcastle/images/backgrounds/menuPattern.png';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
};

export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  ml: 0.5,
  borderRadius: 'md',
  gap: ['1', null, '0', null, null],
  px: ['0', null, '0.5', null, null],
  py: ['0', null, '1', null, null],
  '&::-webkit-scrollbar': { display: 'none' },
  boxShadow: [
    null,
    null,
    '0px 0px 0px 2px #FFE6B6 inset, 0px 0px 0px 4px #302410 inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 2px 7px 0px rgba(0, 0, 0, 0.30)',
    null,
    null,
  ],
  bgBlendMode: 'screen',
  bgGradient: [
    null,
    null,
    `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-400) 100%), url(${menuPattern})`,
    null,
    null,
  ],
  bgRepeat: 'repeat',
  bgSize: '150%',
  borderRadius: 'base',
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? ['gamma.700', null, 'blackAlpha.600', null, null] : '',
  bgGradient: isActive
    ? 'gamma.700'
    : [
        'linear(to-b, gamma.500, gamma.700)',
        null,
        'linear(to-b, transparent, transparent)',
        null,
        null,
      ],
  color: isActive
    ? ['gamma.400', null, 'beta.400', null, null]
    : ['beta.200', null, 'beta.600', null, null],
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: ['2xs', null, 'sm', null, null],
  fontFamily: ['Roboto', null, 'Fraunces', null, null],
  px: '2',
  py: '1',
  h: ['auto', null, '37px', null, null],
  mx: ['0', null, '2px', null, null],
  borderRadius: ['base', null, 'none', null, null],
  borderWidth: isActive ? '0px' : ['2px', null, '0px', null, null],
  borderBottomWidth: isActive ? '0px' : ['2px', null, '1px', null, null],
  borderStyle: ['solid', null, 'dashed', null, null],
  borderColor: ['#FFE6B6', null, 'beta.500', null, null],
  letterSpacing: '0.38px',
  boxShadow: isActive
    ? '0px -4px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset'
    : [
        '0px 2px 4px 0px rgba(0, 0, 0, 0.50), 0px 0px 0px 2px #14134B inset',
        null,
        'none',
        null,
        null,
      ],
  '&:hover, &:focus': {
    bg: 'whiteAlpha.500',
    color: 'beta.600',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'gamma.700',
  color: 'gamma.400',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: 'none',
  boxShadow:
    '0px 1px 0px 0px rgba(165, 191, 240, 0.50),0px 2px 4px 0px rgba(0, 0, 0, 0.60) inset',
  borderRadius: 'md',
  fontWeight: 'bold',
  fontSize: '2xs',
  textAlign: 'center',
  fontFamily: 'roboto',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
