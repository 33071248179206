import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'alpha.700',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  p: '3',
  color: 'gamma.200',
  m: 'auto',
};

export const HeaderText: CSSObject = {
  color: 'gamma.600',
  fontSize: 'lg',
  fontFamily: 'accentMusashi',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  p: '0',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const DepositLimitModalSubheading: CSSObject = {
  '&&': {
    color: 'gamma.200',
  },
};

export const CloseButton: CSSObject = {
  display: 'none',
};
