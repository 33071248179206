import { BoxProps, LinkProps, CSSObject } from '@chakra-ui/react';

export const linkLoginProps: LinkProps = {
  color: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'medium',
  mb: '4',
  display: 'inline-flex',
  alignItems: 'center',

  sx: {
    svg: {
      boxSize: '10px',
      mr: '1',
      mt: '-1px',
    },
  },
};

export const wrapperProps: BoxProps = {
  justifyContent: ['null', null, 'flex-start'],
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: '#718096',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',

  '+ a': {
    color: '#718096',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'medium',
    mx: 0,
    display: 'inline',
  },
};
