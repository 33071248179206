import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: '2xl',
    fontFamily: 'Chewy',
    textShadow: '0px 1px 4px rgba(0, 0, 0, 0.50)',
    color: 'gamma.400',
  },
  headerIconButton: {
    color: 'white',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'white',
  },
  bodyStack: {
    color: 'white',
    mt: '0',
    px: ['2.5', null, null, '3'],
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'alpha.500',
    h: '1.25em',
    ml: '1',
    w: 'auto',
  },

  scrollButtonGroup: {
    variant: 'filter',
  },
  toMarketButton: {
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bg: 'white',
    border: '0',
    borderRadius: 'base',
    boxShadow:
      '0 3px 5px rgba(0, 0, 0, .2), 0 1px rgba(255, 255, 255, .15) inset',
    overflow: 'hidden',
  },
  accordionButton: {
    bgGradient: 'linear(to-b, delta.400, delta.600)',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '2px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
    _hover: {
      bgGradient: 'linear(to-b, delta.600, delta.600)',
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    variant: 'solid',
    px: '3',
  },
  clearButton: {
    variant: 'solid',
    ml: '3',
    px: '3',
  },
  countdown: {
    sx: {
      textTransform: 'uppercase',
      '&&&.state-prior': {
        p: 0,
        lineHeight: 'initial',
      },
    },
  },
  sgmLogoV2: {
    color: 'beta.500',
    w: '8',
    h: 'auto',
  },
  wrapperScrollSgm: { w: '100%', maxW: '100%', gap: '2', mt: '2' },
  scrollButtonGroupV2: {
    variant: 'unstyled',
    flex: 1,
    position: 'relative',
    minH: '34px',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },
  accordionItemActive: {
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  },
  toMarketButtonV2: {
    color: 'white',
    fontSize: 'xs',
    textTransform: 'uppercase',
    minW: 'fit-content',
    fontWeight: 'bold',
    cursor: 'pointer',

    sx: {
      '&&': {
        px: 2,
        ml: 0,
      },

      '&[data-active]': {
        color: 'gamma.500',
      },

      '.chakra-button__icon': {
        margin: 0,
      },
    },

    _last: { mr: 2 },
  },
  sgmBtn: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    borderColor: 'white',
    borderStyle: 'solid',
    borderWidth: '2px',
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 4px rgba(0, 0, 0, 0.20) inset',
    sx: {
      svg: {
        color: 'alpha.900',
        fill: 'alpha.900',
      },
    },
  },
  sgmBtnActive: {
    sx: {
      svg: {
        color: 'white',
        fill: 'white',
      },

      '&&': {
        bg: 'alpha.900',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.50) inset',
        borderColor: 'transparent',
      },
    },

    _after: { display: 'none' },
  },
};
