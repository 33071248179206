import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  bg: 'white',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  color: 'epsilon.500',
};

export const DividerCard: CSSObject = {
  borderColor: 'gray.400',
};

export const CardBottomContent: CSSObject = {
  borderColor: 'alpha.400',
  color: 'alpha.600',
};

export const ContentRow: CSSObject = {
  color: 'alpha.600',
  borderColor: 'gray.300',
  '> hr': {
    width: '100%',
    borderStyle: 'solid',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'beta.200',
    bg: 'beta.600',
    border: '2px solid',
    borderColor: 'beta.600',
  },
  '> svg': {
    color: 'beta.600',
  },
  '.chakra-badge.state-inprogress': {
    bg: 'alpha.300',
    color: 'alpha.800',
    borderRadius: 'base',
    maxW: '40px',
  },
  '.chakra-badge.state-prior': {
    bg: 'transparent',
    color: 'white',
    borderRadius: 'base',
    maxW: '40px',
  },
  '.chakra-badge.state-ended': {
    bg: 'alpha.800',
    color: 'white',
    borderRadius: 'base',
    maxW: '42px',
  },
};

export const ExoticSelectionText: CSSObject = {
  color: 'alpha.800',
  fontWeight: 'medium',
};
