import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  keyframes,
  Skeleton,
  Switch,
  SwitchProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { StitchWrapper } from '@/views/Home/components/Toggle/styles/Toggle.styles';
import toggle from '@/assets/juicybet/images/sidenav/toggle.png';
import featuredCardsBg from '@/assets/juicybet/images/backgrounds/inner.png';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: {
    label: 'toggle-FlexWrapper',
    border: '6px solid',
    borderColor: 'alpha.500',
    alignItems: 'center',
    contain: 'layout',
    height: '85px',
    justifyContent: 'center',
    mx: 'auto',
    borderRadius: 'xl',
    mb: '4',
    p: '1',
    boxShadow:
      '0px 0px 0px 4px #EFFECB inset, 0px 0px 10px 0px #CD3A39 inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${featuredCardsBg})`,
    bgRepeat: 'repeat',
    bgSize: '80%',
    backgroundBlendMode: 'overlay',
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'toggle-TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: ({ isActive }: any) => ({
      fontFamily: 'Chewy',
      fontWeight: 'normal',
      fontSize: 'xl',
      textTransform: 'uppercase',
      color: isActive ? 'gamma.400' : 'beta.700',
      textShadow: isActive
        ? '0px 1px 4px rgba(0, 0, 0, 0.50), 0px 0px 30px #FDE832'
        : '0px 1px 0px #FF8B8A',
      letterSpacing: 'normal',
      px: '3',
      pt: '1',
    }),
  });

const Switcher: ChakraComponent<'label', SwitchProps> = chakra(Switch, {
  label: 'toggle-Switcher',
  baseStyle: {
    '.chakra-switch__track': {
      bg: 'red.600',
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      w: '50px',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',

      _before: {
        bgImg: `url(${toggle})`,
        boxSize: '48px',
        filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.30))',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-2.5',
        right: '0',
        top: '-3',
        animation: `
            ${keyframes`
              from { transform: rotate(0deg) }
              to { transform: rotate(-360deg) }
            `} 0.5s linear`,
      },
    },

    _active: {
      '.chakra-switch__track': {
        boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      },
    },

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
        _before: {
          animation: `
            ${keyframes`
              from { transform: rotate(0deg); }
              to { transform: rotate(360deg); }
            `} 0.7s linear`,
        },
      },
      '.chakra-switch__track': {
        bg: 'red.600',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <StitchWrapper>
        <TextToggle
          data-cy="racing-toggle-text"
          isActive={!category || category === 'racing'}
        >
          Racing
        </TextToggle>

        <Switcher
          onChange={() => {
            setSearchParams({
              category: !category || category === 'racing' ? 'sport' : 'racing',
            });
          }}
          isChecked={!!category && category !== 'racing'}
          colorScheme="blackAlpha"
          size="lg"
          data-cy="categorySwitcher"
        />
        <TextToggle
          data-cy="sports-toggle-text"
          isActive={category === 'sport'}
        >
          Sports
        </TextToggle>
      </StitchWrapper>
    </FlexWrapper>
  );
}
