import { chakra, ModalOverlay as ChakraModalOverlay } from '@chakra-ui/react';
import { styleImports } from './Modal.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const ModalOverlay = chakra(ChakraModalOverlay, {
  baseStyle: ({ theme }) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ModalOverlay?.(theme);
      } catch (err) {
        return {};
      }
    })(),
  }),
});
