import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    color: 'white',
    fontSize: 'xl',
    fontFamily: 'Staatliches',
    letterSpacing: '1px',
    fontWeight: 'normal',
    mb: '2',
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  navOverride: {
    mb: '0',
  },
  divider: {
    display: 'none',
  },
};
