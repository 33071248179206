import { useEffect, useState } from 'react';

export const useSecondsCountDown = (totalSeconds: number) => {
  const [seconds, setSeconds] = useState(totalSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return { seconds };
};
