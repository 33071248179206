import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['0', null, 'unset'],
    mb: 4,
    bgGradient: [
      'unset',
      'linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%)',
    ],
    boxShadow: [
      'none',
      '0px 0px 0px 3px var(--bc-colors-beta-300) inset, 0px 0px 0px 6px #FFF1CB inset, 0px 5px 0px 0px var(--bc-colors-beta-300)',
    ],
    border: ['none', '6px solid'],
    borderColor: ['none', '#FFF1CB'],
    padding: ['0', '3'],
    h: 'fit-content',
    borderRadius: 'xl',
  },
  boxHeading: {
    pt: '0',
    mb: 0,
    ...(!IS_MOBILE_APP && {
      sx: {
        '&&': {
          pb: '9',
        },
      },
    }),
    color: 'white',
  },
  textTitle: {
    mt: '0',
    color: 'beta.800',
    fontSize: ['lg', 'xl'],
    fontWeight: 'normal',
    fontFamily: 'accent',
  },
  accountHeaderContainer: {
    pl: '0',
    pr: '0',
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    minH: 'auto',
    sx: {
      '&&': {
        borderBottomRadius: 'md',
        pb: '2.5',
        p: '0',
        display: 'flex',
        gap: ['2', '4'],
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pb: '2',
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: ['auto', null, 'full'],
    h: 'fit-content',
    borderRadius: 'lg',
    px: '3',
    py: '3',
    bg: 'beta.100',
    mx: ['1', '0'],
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
  },
};
