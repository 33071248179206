import { Image, Show, Hide } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/forsport/images/logo-brand.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function Index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          alt={`${THEME_NAME.forsport}`}
          data-testid={`${THEME_NAME.forsport}_desktop`}
          m="auto"
          mb="4"
          w="234px"
          h={{ base: '8', lg: 'auto' }}
        />
      </Show>
      <Hide above="lg">
        <Image
          minW="105px"
          src={logo}
          alt={`${THEME_NAME.forsport}`}
          data-testid={`${THEME_NAME.forsport}_mobile`}
        />
      </Hide>
    </Link>
  );
}
