import React, { ReactNode } from 'react';
import { GiftFill } from '@styled-icons/bootstrap/GiftFill';
import { Box, Collapse, Icon, StyleProps, Text } from '@chakra-ui/react';
import { bannerStyles } from './style/Banner.styles';

type TBanner = {
  children: ReactNode;
  collapseIn?: boolean;
  sx?: StyleProps;
  withIcon?: boolean;
};

export const DepositIcon = (): JSX.Element => (
  <Icon as={GiftFill} {...(bannerStyles.depositIconProps as any)} />
);

export default function Banner({
  children,
  collapseIn = true,
  sx,
  withIcon = true,
}: TBanner) {
  return (
    <Collapse in={collapseIn} animateOpacity>
      <Box {...bannerStyles.bannerBoxProps} {...sx}>
        {withIcon && <DepositIcon />}
        <Text>{children}</Text>
      </Box>
    </Collapse>
  );
}
