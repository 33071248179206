import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  display: ['none', null, 'flex'],
  justifyContent: 'center',
  mb: [null, null, '96'],
};

export const TextItem: CSSObject = {
  maxW: '261px',
  color: 'white',
  textAlign: 'center',
  fontSize: 'xs',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  span: { color: 'beta.400', fontWeight: 'bold' },
};
