import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    bg: 'white',
    borderTopRadius: 'base',
    pt: '2',
    mb: ['6', null, '3'],
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: '0',
      },
    },
  },
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['2', null, 'unset'],
  },
  textTitle: {
    mt: '0',
    mb: IS_MOBILE_APP ? '0' : '2',
    color: 'alpha.700',
    fontSize: 'md',
    fontWeight: 'medium',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        p: '2',
        pt: '0',
        borderRadius: 'md',
        bg: 'white',
        flexDir: 'column',
        ...(IS_MOBILE_APP && {
          pt: '0',
        }),
      },
    },
  },
  titleContainer: {
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
