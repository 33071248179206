import * as sterlingparker from './ErrorBanner.styles.sterlingparker';
import * as juicybet from './ErrorBanner.styles.juicybet';
import * as volcanobet from './ErrorBanner.styles.volcanobet';
import * as vikingbet from './ErrorBanner.styles.vikingbet';
import * as wellbet from './ErrorBanner.styles.wellbet';
import * as questbet from './ErrorBanner.styles.questbet';
import * as puntgenie from './ErrorBanner.styles.puntgenie';
import * as puntcity from './ErrorBanner.styles.puntcity';
import * as junglebet from './ErrorBanner.styles.junglebet';
import * as goldenrush from './ErrorBanner.styles.goldenrush';
import * as fiestabet from './ErrorBanner.styles.fiestabet';
import * as betroyale from './ErrorBanner.styles.betroyale';
import * as betprofessor from './ErrorBanner.styles.betprofessor';
import * as betgalaxy from './ErrorBanner.styles.betgalaxy';
import * as titanbet from './ErrorBanner.styles.titanbet';
import * as gigabet from './ErrorBanner.styles.gigabet';
import * as slambet from './ErrorBanner.styles.slambet';
import * as goldenbet888 from './ErrorBanner.styles.goldenbet888';
import * as bet777 from './ErrorBanner.styles.bet777';
import * as chromabet from './ErrorBanner.styles.chromabet';
import * as templebet from './ErrorBanner.styles.templebet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  sterlingparker,
  juicybet,
  volcanobet,
  vikingbet,
  wellbet,
  questbet,
  puntgenie,
  puntcity,
  junglebet,
  goldenrush,
  fiestabet,
  betroyale,
  betprofessor,
  betgalaxy,
  titanbet,
  gigabet,
  slambet,
  goldenbet888,
  bet777,
  chromabet,
  templebet,
};
