import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  mx: '0',
  borderTop: 'none',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  px: '2',
  py: '2',
  borderRadius: 'lg',
  gap: '1',
  bg: 'alpha.200',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 0px 0px 4px rgba(117, 20, 16, 1) inset',
};

export const RacerName: CSSObject = {
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
  color: 'gamma.200',
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'gamma.200',
};
