import {
  chakra,
  Flex,
  Icon,
  IconButton,
  IconProps,
  Text,
} from '@chakra-ui/react';
import { FileTextFill } from '@styled-icons/bootstrap';
import { CloseCircle } from '@styled-icons/ionicons-outline';
import { StyledIcon } from '@styled-icons/styled-icon';
import { styleImports } from './Header.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type TCloseIcon = { as: StyledIcon } & IconProps;

export const closeIcon: TCloseIcon = {
  ...((): TCloseIcon => {
    const defaultIcon: TCloseIcon = { as: CloseCircle, boxSize: '10' };
    try {
      const icon = styleImports[themeName]?.closeIcon;
      if (!icon || !icon.as) {
        return defaultIcon;
      }
      return icon;
    } catch (err) {
      return defaultIcon;
    }
  })(),
};

export const CloseIconButton = chakra(IconButton, {
  label: 'betslip-header-CloseIconButton',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.CloseIconButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const headerIcon: any = {
  ...((): StyledIcon => {
    const defaultIcon: StyledIcon = FileTextFill;
    try {
      const icon = styleImports[themeName]?.headerIcon;
      if (!icon) {
        return defaultIcon;
      }
      return icon;
    } catch (err) {
      return defaultIcon;
    }
  })(),
};

export const FlexBetslipHeading = chakra(Flex, {
  label: 'header-FlexBetslipHeading',
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    px: '2',
    borderBottom: [null, null, null, '2px', '1px'],
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBetslipHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetslipTitle = chakra(Flex, {
  label: 'BetslipTitle',
  baseStyle: () => ({
    fontSize: 'xl',
    fontWeight: 'bold',
    gap: '1',
    alignItems: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.BetslipTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetLengthIndicator = chakra(Flex, {
  label: 'BetLengthIndicator',
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'alpha.400',
    p: '1',
    ml: '1',
    w: '5',
    h: '5',
    borderRadius: 'full',
    ...(() => {
      try {
        return styleImports[themeName]?.BetLengthIndicator;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetslipHeaderIcon = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '5',
    ...(() => {
      try {
        return styleImports[themeName]?.BetslipHeaderIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetslipTitleText = chakra(Text, {
  label: 'BetslipTitleText',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BetslipTitleText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
