import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { ContentHeadingText } from '../styles/Maintenance.styles';
import { usePathStartsWith } from '../../../hooks/usePathStartsWith';

export default function Title() {
  const isMaintenance = usePathStartsWith('/maintenance');
  const header = isMaintenance ? 'maintenance.heading' : 'error.heading';

  return (
    <ContentHeadingText data-isMaintenance={isMaintenance}>
      <FormattedMessage
        id={header}
        values={{ b: (string: string) => <Text as="b">{string}</Text> }}
      />
    </ContentHeadingText>
  );
}
