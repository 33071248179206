import { HomeLinksSchema } from './HomeLinks.styles';
import melon from '@/assets/juicybet/images/home/melon.png';
import lime from '@/assets/juicybet/images/home/lime.png';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: ['flex-start', null, 'center'],
    mb: '1',
    pb: '1',
    maxW: ['100dvw', null, 'unset'],
    overflowX: ['scroll', null, 'auto'],
    overflowY: 'hidden',
    mr: ['0', '2'],
    px: '2',
    sx: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  container: {
    display: 'flex',
    flexDir: 'column',
    my: '1.5',
  },
  racingBtnsContainer: {
    gap: ['2', null, '3'],
    flexGrow: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    pl: '0',
    mr: ['2', '3'],
  },
  racingIcon: {
    bgImage: `url(${melon})`,
    boxSize: 'full',
    p: '5',
    color: 'gamma.400',
  },
  sportBtnsContainer: {
    flexGrow: 2,
    gap: ['2', null, '3'],
    justifyContent: 'space-between',
    flexDirection: 'row',
    pl: '0',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgImage: `url(${lime})`,
    boxSize: 'full',
    p: '5',
    color: 'alpha.700',
    fontFamily: 'chewy',
    fontWeight: 'light',
    textAlign: 'center',
    lineHeight: '0.75',
    textShadow: '1px 1px rgba(0, 0, 0, 0.2)',
  },
  linkIconBox: {
    boxSize: '69px',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.40))',
  },
  linkBox: {
    boxSize: '69px',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.40))',
  },
  linkOverlay: {},
  linkBoxText: {
    display: 'none',
  },
  racingHeader: {
    display: 'none',
  },
  sportsHeader: {
    display: 'none',
  },
};
