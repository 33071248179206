import { CSSObject } from '@chakra-ui/react';

export const TextCheckbox: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const buttonCloseAccountProps: CSSObject = {
  pt: '1.5',
  fontWeight: 'normal',
  variant: 'solid',
};
