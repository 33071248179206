import { CustomButtonProps } from '@/components/Button/Button';

export const buttonSubmitExclusionProps: CustomButtonProps = {
  variant: 'secondary',
  height: '38px',
  width: '100%',
};

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  variant: 'secondary',
  height: '38px',
};
