/* eslint-disable @typescript-eslint/naming-convention */
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAppDispatch } from './useRedux';
import { signUserOut } from '../lib/firebase/Authentication';
import {
  clearPunterOverview,
  clearPunterProfile,
  reset,
} from '../views/account/services/account.slices';
import { clearBetSlip } from '../redux/Betslip.slices';
import { recordUserAction } from '../lib/api/activityLogging/actions';
import { ACTIVITY_LOG_SUMMARY, ACTIVITY_LOG_TYPE } from '../lib/Constants';
import { IS_MOBILE_APP } from '../constants/isMobileApp';
import { HTTPError } from '../lib/api/types';
import { ENewRelicActions } from '../constants/newRelicActions';
import {
  useAuthIsLoadingData,
  useIsAuthenticated,
  usePunterData,
  useUserData,
} from '@/store/AuthStore';
import { resetAnalytics } from '@/lib/analytics';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

export const useAuth = () => {
  const userData = useUserData();
  const punterData = usePunterData();
  const authIsLoading = useAuthIsLoadingData();
  const isAuthenticated = useIsAuthenticated();
  const { clearBetSlip: _clearBetSlip } = getBetSlipStoreActions();

  const { NODE_ENV } = window.BETCLOUD_ENV;

  const dispatch = useAppDispatch();

  const client = useQueryClient();

  if (IS_MOBILE_APP) {
    return {
      isLoading: false,
      isAuthLoading: false,
      isAuthenticated: window.MobileApp_Token !== undefined,
      punterData: punterData ?? null,
      logout: () => undefined,
    };
  }

  return {
    isLoading: authIsLoading,
    authData: userData ?? null,
    isAuthenticated: authIsLoading
      ? undefined
      : isAuthenticated && !!punterData,
    punterData: punterData ?? null,
    logout: async () => {
      await dispatch(
        recordUserAction({
          action_type: ACTIVITY_LOG_TYPE.LOGOUT,
          action_summary: ACTIVITY_LOG_SUMMARY[ACTIVITY_LOG_TYPE.LOGOUT],
        }) as any
      );

      await signUserOut()
        .then(() => {
          if (window.ZohoHCAsap) {
            window.ZohoHCAsap.Logout();
          }

          resetAnalytics();

          if (window.newrelic) {
            window.newrelic.addPageAction(ENewRelicActions.LogOut, {
              status_code: 200,
              punter_id: punterData?.punter_id,
              success: true,
              environment: NODE_ENV,
            });
          }

          _clearBetSlip();
          dispatch(clearBetSlip());
          dispatch(clearPunterProfile());
          dispatch(clearPunterOverview());
          dispatch(reset());
        })
        .catch((error) => {
          const e = axios.isAxiosError(error) ? (error as HTTPError) : null;

          if (window.newrelic) {
            window.newrelic.addPageAction(ENewRelicActions.LogOut, {
              status_code: e?.response.status ?? '',
              punter_id: punterData?.punter_id,
              error,
              success: false,
              environment: NODE_ENV,
            });
          }
        });

      await client.invalidateQueries();
    },
  };
};
