import { chakra, Box } from '@chakra-ui/react';
import { styleImports } from './Banner.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import desktop from '../../../../../assets/core/images/banner/desktop.svg';
import mobile from '../../../../../assets/core/images/banner/mobile.svg';

const themeName = getThemeName();

export const BoxCarouselSlide = chakra(Box, {
  baseStyle: () => ({
    w: '100%',
    h: ['calc(100vh - 60vh)', null, '215px'],
    bgImage: [`url(${mobile})`, null, `url(${desktop})`],
    bgSize: '100% 100%',
    bgRepeat: 'no-repeat',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxCarouselSlide;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
