import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'alpha.900',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'md',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  border: 'none',
  px: '4',
  py: '3.5',
  mb: '2',
  color: 'white',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
