import { CSSObject } from '@chakra-ui/react';
import tilesBg from '@/assets/junglebet/images/backgrounds/backgroundSport.png';

export const TextRaceNumber: CSSObject = {
  minH: '4',
  fontWeight: 'bold',
  bg: 'delta.700',
  flex: 0,
  borderRadius: 'base',
  px: '1',
  fontSize: '2xs',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-delta-300), var(--bc-colors-delta-600))',
  bgImage: `url(${tilesBg})`,
  bgRepeat: 'repeat',
  bgSize: 'cover',
  boxShadow:
    '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 5px 0px 0px var(--bc-colors-delta-800), 0px 2px 2px 0px #D17917 inset, 0px -2px 1px 0px rgba(178, 78, 5, 0.50) inset',
  color: 'delta.50',
  borderRadius: '2xl',
  mb: '2.5',
  px: '2.5',
  py: '3',
  _hover: {
    bgImage: `none`,
  },
  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'beta.800',
    borderRadius: 'base',
    maxW: '10',
    minH: '4',
  },

  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
    maxW: '10',
    minH: '4',
  },

  '.chakra-badge.state-ended': {
    bg: 'gamma.400',
    color: 'white',
    borderRadius: 'base',
    maxW: '10',
    minH: '4',
  },
};

export const TextRaceType: CSSObject = {
  color: 'beta.500',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.30)',
  fontFamily: 'CarterOne',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'sm',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.20)',
};

export const Icon: CSSObject = {
  color: 'delta.50',
  boxSize: '6',
};
