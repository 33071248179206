import { WellSchema } from './Well.styles';

export const wellStyles: WellSchema = {
  wrapper: {
    bg: 'delta.600',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200), 0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
    p: '1.5',
    py: '2',
  },
};
