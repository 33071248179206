export const en = {
  'betslip.betslipbetcard.stakeprefix': '$',
  'footer.copyright': 'Copyright © {themeName} Pty Ltd | v{appVersion}',
  'betslip.betslipbetcard.errors.insufficientlegsformulti':
    'To build a Multi, please add more selections to increase your potential returns.',
  'betslip.betslipbetcard.errors.propositionclosed': 'This Event is closed',
  'account.currentlimit.heading': 'Deposit Limit',
  'onboarding.login.subtitle':
    'Welcome to <b>BetProfessor</b>, the wackiest place for racing and sports betting. Unlock your account and begin <b>placing your wagers right away!</b>',
  'onboarding.login.footerInfo': 'Need an account? <link>Join now</link>',
  'onboarding.login.footerForgotInfo': 'Need an account? <link>Join now</link>',
  'onboarding.step1.button': 'Join now',
  'onboarding.locationnotallowed.header': 'Sealed shut',
  'maintenance.heading': 'Lab under maintenance',
  'error.heading': 'Experiment failure',
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing BetProfessor from outside of Australia</b>',
  'maintenance.error':
    '<bold>We are currently having some technical issues at BetProfessor.</bold>',
  'header.betslipBtn.desc': 'Slip',
  'sports.allsports.azheading': 'A-Z',
  'main.featured.header': 'Madness Unleashed',
  'sidenav.deposit': 'Deposit',
  'betslip.betslipmodal.types.multi': 'Multi',
  'betslip.betslipmodal.emptybetslipheading':
    'eerily empty, fill up your bet slip',
  'racing.exotics.selectfield': 'Select all',
  'racing.errorMessages.noRacesAvailable': 'Races Gone, Experiments On!',
};
