import * as betgalaxy from './betgalaxy';
import * as betprofessor from './betprofessor';
import * as betroyale from './betroyale';
import * as buffalobet from './buffalobet';
import * as fiestabet from './fiestabet';
import * as forsport from './forsport';
import * as goldenrush from './goldenrush';
import * as juicybet from './juicybet';
import * as junglebet from './junglebet';
import * as oldgill from './oldgill';
import * as puntcity from './puntcity';
import * as puntgenie from './puntgenie';
import * as questbet from './questbet';
import * as sterlingparker from './sterlingparker';
import * as sugarcastle from './sugarcastle';
import * as vikingbet from './vikingbet';
import * as volcanobet from './volcanobet';
import * as wellbet from './wellbet';
import * as titanbet from './titanbet';
import * as slambet from './slambet';
import * as templebet from './templebet';
import * as goldenbet888 from './goldenbet888';
import * as gigabet from './gigabet';
import * as luckystride from './luckystride';
import * as chromabet from './chromabet';
import * as bet777 from './bet777';
import * as betbetbet from './betbetbet';
import * as hyperbet from './hyperbet';
import * as betblast from './betblast';
import * as bet575 from './bet575';
import { themeName } from '@/helpers/getThemeConfig';

const exports = {
  betgalaxy,
  betprofessor,
  betroyale,
  buffalobet,
  fiestabet,
  forsport,
  goldenrush,
  juicybet,
  junglebet,
  oldgill,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  sugarcastle,
  vikingbet,
  volcanobet,
  wellbet,
  titanbet,
  slambet,
  templebet,
  goldenbet888,
  gigabet,
  luckystride,
  chromabet,
  bet777,
  betbetbet,
  hyperbet,
  betblast,
  bet575,
} as Record<string, any>;

export default exports[themeName];
