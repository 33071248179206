import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  p: '2',
  pt: '0',
};

export const BoxWrapper: CSSObject = {
  mb: ['0'],
  borderRadius: 'none',
  background: 'none',
  border: 0,
};

export const LinkItem = (isFeatured: boolean) => ({
  p: '2',
  py: '3',
  bg: isFeatured ? 'beta.500' : 'alpha.50',
  color: isFeatured ? 'white' : 'gray.700',
  transition: 'all, .3s ease-in-out',

  _hover: {
    bg: 'alpha.100',
  },

  '&+&': {
    borderTop: '1px',
    borderTopColor: isFeatured ? 'whiteAlpha.100' : 'gray.200',
  },

  svg: {
    boxSize: '6',
    p: '1',
    color: isFeatured ? 'unset' : 'alpha.700',
    border: '1px',
    borderColor: 'alpha.700',
    borderRadius: 'full',
  },
});
