import * as betgalaxy from './MobileTransactionsTable.styles.betgalaxy';
import * as betprofessor from './MobileTransactionsTable.styles.betprofessor';
import * as betroyale from './MobileTransactionsTable.styles.betroyale';
import * as fiestabet from './MobileTransactionsTable.styles.fiestabet';
import * as goldenrush from './MobileTransactionsTable.styles.goldenrush';
import * as juicybet from './MobileTransactionsTable.styles.juicybet';
import * as junglebet from './MobileTransactionsTable.styles.junglebet';
import * as puntcity from './MobileTransactionsTable.styles.puntcity';
import * as puntgenie from './MobileTransactionsTable.styles.puntgenie';
import * as questbet from './MobileTransactionsTable.styles.questbet';
import * as sterlingparker from './MobileTransactionsTable.styles.sterlingparker';
import * as vikingbet from './MobileTransactionsTable.styles.vikingbet';
import * as volcanobet from './MobileTransactionsTable.styles.volcanobet';
import * as wellbet from './MobileTransactionsTable.styles.wellbet';
import * as sugarcastle from './MobileTransactionsTable.styles.sugarcastle';
import * as oldgill from './MobileTransactionsTable.styles.oldgill';
import * as buffalobet from './MobileTransactionsTable.styles.buffalobet';
import * as slambet from './MobileTransactionsTable.styles.slambet';
import * as goldenbet888 from './MobileTransactionsTable.styles.goldenbet888';
import * as gigabet from './MobileTransactionsTable.styles.gigabet';
import * as chromabet from './MobileTransactionsTable.styles.chromabet';
import * as templebet from './MobileTransactionsTable.styles.templebet';
import * as bet777 from './MobileTransactionsTable.styles.bet777';
import * as titanbet from './MobileTransactionsTable.styles.titanbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  buffalobet,
  oldgill,
  sugarcastle,
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  junglebet,
  slambet,
  goldenbet888,
  gigabet,
  chromabet,
  templebet,
  bet777,
  titanbet,
};
