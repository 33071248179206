import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  borderRadius: 'md',
  mb: '3',
  border: '0',
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',

  ...(isNested && {
    boxShadow:
      '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
    mb: '2',
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  border: 0,
  p: '3',
  m: '5px',
  borderBottomRadius: 'md',
  bgGradient: `linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))`,

  ...(isNested && {
    border: '1px',
    borderTop: 0,
    borderColor: 'blackAlpha.200',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'beta.400',
  h: '32px',
  color: 'white',

  borderRadius: 'md',
  fontWeight: 'bold',
  fontSize: 'xs',
  p: '2',
  border: '1px',
  borderColor: 'transparent',
  borderBottom: '0',

  svg: {
    border: '1px',
    borderColor: 'transparent',
    color: 'white',
    borderRadius: '100%',
    boxSize: '20px',
  },

  _hover: {
    bg: 'beta.700',
  },

  ...(isNested && {
    '&&&': {
      bg: 'beta.400',
      border: '1px',
      borderColor: 'blackAlpha.200',
      color: 'white',

      svg: {
        borderColor: 'transparent',
        color: 'white',
      },

      _expanded: {
        color: 'white',
        borderBottomRadius: 0,
        borderBottom: 0,
      },
    },
  }),

  _expanded: {
    borderBottomRadius: 'none',
    bg: 'beta.400',
    color: 'white',
    borderColor: 'blackAlpha.200',
    borderBottomColor: '#4B5A6D',

    _hover: {
      bg: 'beta.700',
    },

    ...(isNested && {
      bg: 'beta.400',
      color: 'white',
      borderBottomColor: 'transparent',

      _hover: {
        bg: 'beta.700',
      },
    }),
  },
});

export const BoxWrapper: CSSObject = {
  py: '2',
  px: '0',
  borderBottomRadius: '6px',
};
