import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    fontSize: '2xs',
    h: '24px',
    sx: {
      '&[data-active="false"]': {
        px: '2',
      },
      '&[data-active="true"]': {
        borderColor: 'beta.500',
        bg: 'beta.500',
        boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
        color: 'beta.700',
        textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
      },
    },
  },
};
