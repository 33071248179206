import { CSSProperties } from 'react';
import {
  AccordionButtonProps,
  AccordionItemProps,
  AccordionPanelProps,
  BoxProps,
  FlexProps,
  LinkProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './AllLeagues.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

type TAccordionIconProps = {
  name?: string;
  styles?: string;
  anim?: number;
  toString?: () => string;
  color: string;
};

export type AllLeaguesSchema = Partial<{
  headerSportText: TextProps;
  topLeaguesAccordionItem: AccordionItemProps;
  accordionButton: AccordionButtonProps;
  topLeaguesText: TextProps;
  accordionIcon: TAccordionIconProps;
  accordionPanel: AccordionPanelProps;
  accordionPanelBoxWrapper: BoxProps;
  accordionPanelBoxInner: BoxProps;
  accordionPanelBoxInnerLink: FlexProps;
  marketGroupsByLetterHeadingText: TextProps;
  marketGroupsByLetterAccordionItem: AccordionItemProps;
  marketGroupsByLetterAccordionButton: AccordionButtonProps;
  marketGroupsByLetterTitleText: TextProps;
  marketGroupsByLetterCountryFlag: CSSProperties;
  marketGroupsByLetterAccordionIcon: TAccordionIconProps;
  marketGroupsByLetterAccordionPanel: AccordionPanelProps;
  marketGroupsByLetterAccordionPanelBox: BoxProps;
  marketGroupsByLetterAccordionPanelLink: LinkProps;
  boxWrapper: BoxProps;
  navWrapper: StackProps;
}>;

export const allLeaguesStyles: AllLeaguesSchema = {
  headerSportText: {
    'data-testid': 'allLeagues-headerSportText',
    fontSize: '2xl',
    pb: '2',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles?.headerSportText;
      } catch (err) {
        return {};
      }
    })(),
  },
  topLeaguesAccordionItem: {
    'data-testid': 'allLeagues-topLeaguesAccordionItem',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.topLeaguesAccordionItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  boxWrapper: {
    'data-testid': 'allLeagues-boxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles?.boxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  navWrapper: {
    'data-testid': 'allLeagues-navWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles?.navWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterAccordionPanelLink: {
    'data-testid': 'allLeagues-marketGroupsByLetterAccordionPanelLink',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterAccordionPanelLink;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterAccordionPanelBox: {
    'data-testid': 'allLeagues-marketGroupsByLetterAccordionPanelBox',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterAccordionPanelBox;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterAccordionPanel: {
    'data-testid': 'allLeagues-marketGroupsByLetterAccordionPanel',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterAccordionPanel;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterAccordionIcon: {
    'data-testid': 'allLeagues-marketGroupsByLetterAccordionIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterAccordionIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterCountryFlag: {
    'data-testid': 'allLeagues-marketGroupsByLetterCountryFlag',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterCountryFlag;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterTitleText: {
    'data-testid': 'allLeagues-marketGroupsByLetterTitleText',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterTitleText;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterAccordionButton: {
    'data-testid': 'allLeagues-marketGroupsByLetterAccordionButton',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterAccordionButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterAccordionItem: {
    'data-testid': 'allLeagues-marketGroupsByLetterAccordionItem',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterAccordionItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketGroupsByLetterHeadingText: {
    'data-testid': 'allLeagues-marketGroupsByLetterHeadingText',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.marketGroupsByLetterHeadingText;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButton: {
    'data-testid': 'allLeagues-accordionButton',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles?.accordionButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  topLeaguesText: {
    'data-testid': 'allLeagues-topLeaguesText',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles?.topLeaguesText;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionIcon: {
    'data-testid': 'allLeagues-accordionIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles?.accordionIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionPanel: {
    'data-testid': 'allLeagues-accordionPanel',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles?.accordionPanel;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionPanelBoxWrapper: {
    'data-testid': 'allLeagues-accordionPanelBoxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.accordionPanelBoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionPanelBoxInner: {
    'data-testid': 'allLeagues-accordionPanelBoxInner',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.accordionPanelBoxInner;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionPanelBoxInnerLink: {
    'data-testid': 'allLeagues-accordionPanelBoxInnerLink',

    ...(() => {
      try {
        return styleImports[themeName]?.allLeaguesStyles
          ?.accordionPanelBoxInnerLink;
      } catch (err) {
        return {};
      }
    })(),
  },
};
