import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Skeleton,
  Switch,
  SwitchProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { StitchWrapper } from '@/views/Home/components/Toggle/styles/Toggle.styles';
import toggle from '@/assets/templebet/images/toggle/toggle.png';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'toggle-FlexWrapper',
  baseStyle: {
    bg: 'linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%)',
    boxShadow:
      '0px 0px 0px 3px var(--bc-colors-beta-300) inset, 0px 0px 0px 6px #FFF1CB inset, 0px 5px 0px 0px var(--bc-colors-beta-300)',
    borderRadius: '2xl',
    border: '6px solid',
    borderColor: ' #FFF1CB',
    alignItems: 'center',
    height: '68px',
    justifyContent: 'center',
    mx: ['1', null, 'auto'],
    w: [null, null, 'full'],
    mb: '4',
    mt: '0',
    p: '1.5',
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'toggle-TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: ({ isActive }: any) => ({
      fontFamily: 'accent',
      fontWeight: 'hairline',
      fontSize: 'xl',
      textTransform: 'uppercase',
      color: 'beta.800',
      opacity: isActive ? '1' : '.5',
      letterSpacing: 'normal',
      px: '3',
      pt: '1',
      pos: 'relative',
      zIndex: '3',
      alignSelf: 'center',
    }),
  });

const Switcher: ChakraComponent<'label', SwitchProps> = chakra(Switch, {
  label: 'toggle-Switcher',
  baseStyle: {
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
    '--switch-track-width': '44px',
    '.chakra-switch__track': {
      bg: 'beta.800',
      boxShadow: 'inset 0 2px 2px var(--bc-colors-blackAlpha-300)',
      w: '58px',
      h: '30px',
      pos: 'relative',
      zIndex: '3',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: '3',

      _before: {
        bgImg: `url(${toggle})`,
        boxSize: '29.5px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '.3px',
        right: '0',
        top: '.2px',
      },
    },

    _active: {},

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
      },
      '.chakra-switch__track': {
        bg: 'beta.800',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <StitchWrapper>
        <TextToggle
          data-cy="racing-toggle-text"
          isActive={!category || category === 'racing'}
        >
          Racing
        </TextToggle>

        <Switcher
          onChange={() => {
            setSearchParams({
              category: !category || category === 'racing' ? 'sport' : 'racing',
            });
          }}
          isChecked={!!category && category !== 'racing'}
          colorScheme="blackAlpha"
          size="lg"
          data-cy="categorySwitcher"
        />
        <TextToggle
          data-cy="sports-toggle-text"
          isActive={category === 'sport'}
        >
          Sports
        </TextToggle>
      </StitchWrapper>
    </FlexWrapper>
  );
}
