import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/buffalobet/images/backgrounds/bgTop.jpg';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          '@media (max-width: 480px)': {
            background:
              'linear-gradient(180deg, var(--bc-colors-alpha-600), var(--bc-colors-alpha-800))',
          },
        },
      }}
    />
  );
}
