import {
  Badge,
  BadgeProps,
  chakra,
  ChakraComponent,
  CSSObject,
  Flex,
  FlexProps,
  Text,
} from '@chakra-ui/react';
import { styleImports } from './Countdown.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

type TBadgeCountdown = 'prior' | 'ended' | 'inprogress';

export const BadgeCountdown = chakra(Badge, {
  label: 'countdown-BadgeCountdown',
  shouldForwardProp: (prop) => !['state'].includes(prop),
  baseStyle: ({ state }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    bg: state === 'inprogress' ? 'green.500' : 'red.500',
    borderRadius: 'sm',
    fontSize: '2xs',
    lineHeight: 'none',
    py: '0.5',
    px: '1',
    fontWeight: 'bold',
    textTransform: 'lowercase',
    minH: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.BadgeCountdown?.(state);
      } catch (err) {
        return {};
      }
    })(),

    /**
     * State before the race starts.
     * Needs to sit under the theme styles due to specificity.
     */
    ...(state === 'prior' && {
      color: 'gray.900',
      bg: 'unset',
    }),
  }),
}) as ChakraComponent<'div', BadgeProps & { state: TBadgeCountdown }>;

export const TextTimestamp = chakra(Text, {
  label: 'countdown-TextTimestamp',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'xs',
    ...(() => {
      try {
        return styleImports[themeName]?.TextTimestamp;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type CountdownFlexProps = { direction?: string; sxWrapper?: CSSObject };

export const CountdownFlex = chakra(Flex, {
  label: 'countdown-CountdownFlex',
  shouldForwardProp: (prop) => !['direction', 'sxWrapper'].includes(prop),
  baseStyle: ({ direction, sxWrapper }: any) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: ['1', '2'],
    flexDir: direction,
    ...sxWrapper,
    ...(() => {
      try {
        return styleImports[themeName]?.CountdownFlex;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps & CountdownFlexProps>;
