import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {
      boxSize: '4',
    },
  },
  iconButtonScrollRight: {
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    color: 'beta.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    border: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {
      boxSize: '4',
    },
  },
  iconButtonScrollLeft: {
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    color: 'beta.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    border: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  flexWrapper: {
    bg: 'blackAlpha.600',
    borderRadius: 'md',
    boxShadow: '0 1px rgba(255, 255, 255, .05)',
    pos: 'relative',
    minH: '34px',
  },
  buttonGroupSports: {
    display: 'inline-flex',
    alignItems: 'center',
    flex: '1',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    bg: 'blackAlpha.400',
    overflowX: 'auto',
    p: '1',
    h: [null, null, '31px'],
    spacing: '1',
    mt: [null, null, '3px'],
    mr: [null, null, 1],
    whiteSpace: 'nowrap',
    pos: ['relative', null, null, 'absolute'],
    w: ['120px', 'auto', null],
    borderRadius: 'lg',
    inset: 0,
    right: [null, null, null, '38px'],
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    pr: '2px',
    pos: 'relative',
    spacing: '1',
    ml: 'auto',
    gap: 0.5,
    borderRadius: 'lg',
    isAttached: true,
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'beta.500',
    pos: 'absolute',
    bottom: 'px',
    transition: 'all 0.2s ease',
  },
};
