import { CSSObject } from '@chakra-ui/react';
import featuredRace from '@/assets/vikingbet/images/backgrounds/featuredRace.png';
import activeBg from '@/assets/vikingbet/images/backgrounds/btn-active-bg.png';
import bgMb from '@/assets/vikingbet/images/backgrounds/featuredRaceMobile.png';

export const FlexFeatureTitleWrapper: CSSObject = {
  bgImage: [`url(${bgMb})`, null, 'none'],
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
  bgPosition: 'center top',
  minH: ['170px', '260px', '320px'],
  w: '100%',
  flex: 1.5,
  mr: 1,
};

export const FlexFeatureWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  bgImage: ['unset', null, `url(${featuredRace})`],
  bgSize: '100% 100%',
  bgRepeat: 'no-repeat',
  bgPosition: ['0 10%', '0 100%'],
  px: 0,
  pr: [1, 3],
  minH: ['333px', '400px'],
  w: ['98%', '100%'],
  mb: ['2.5', 'unset'],
  mt: [-8, -4, 0],
  ml: [1.5, null, 0],
};

export const FlexFeatureCardWrapper: CSSObject = {
  flex: 1,
};

export const BoxCard: CSSObject = {
  borderRadius: 'md',
  boxShadow:
    '0px 6px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 0px 0px #502714, 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
  mr: [0, null, 4],
};

export const LinkCardHeader: CSSObject = {
  bg: 'zeta.400',
  color: 'alpha.600',

  '.chakra-badge.state-inprogress': {
    bg: 'delta.900',
    color: 'white',
    height: '3',
    borderRadius: 'base',
  },

  '.chakra-badge.state-prior': {
    bg: 'rgba(45, 55, 72, 1)',
    color: 'white',
    height: '3',
    borderRadius: 'base',
  },

  '.chakra-badge.state-ended': {
    bg: 'delta.900',
    color: 'white',
    height: '3',
    borderRadius: 'base',
  },

  _hover: {
    bg: 'zeta.600',
  },
};

export const GridCardContent: CSSObject = {
  bg: 'epsilon.200',
  color: 'alpha.700',
  fontSize: 'sm',
};

export const IconCardHeader: CSSObject = {
  color: 'alpha.600',
};

export const TextCardHeader: CSSObject = {
  fontWeight: 'bold',
};

export const TextRaceNumber: CSSObject = {
  borderRadius: 'base',
  bg: 'alpha.500',
  color: 'white',
  fontWeight: 'bold',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'zeta.600',
  color: 'alpha.700',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  borderY: '#FFE6B7 2px solid',
  ':first-child': {
    pl: '3',
  },
};

export const FlexGridRunnerItem: CSSObject = {
  bg: 'zeta.500',
  borderBottom: '1px dotted',
  borderBottomColor: '#CFB481',
  _last: {
    border: 'none',
  },
  '&:nth-last-child(2)': {
    border: 'none',
  },
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'alpha.600',
  fontWeight: 'bold',
  '> span': {
    color: 'alpha.600',
    fontWeight: 'bold',
  },
};

export const Button = (): CSSObject => ({
  transition: 'all .3s linear',
  lineHeight: '1',
  borderRadius: 'md',
  fontFamily: 'SkranjiBold',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  height: ['9', null, '10'],
  boxSizing: 'border-box',
  fontSize: 'sm',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  border: 'var(--bc-colors-delta-700) 2px solid',
  color: 'rgba(0, 0, 0, 0.64)',
  textShadow: '0px 1px 2px rgba(255, 255, 255, 0.40)',
  maxH: '36px',
  w: '52px',

  '&:hover': {
    bgGradient: 'linear(180deg, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },

  '&:disabled': {
    bg: 'blackAlpha.400',
    color: 'blackAlpha.700',
    boxShadow: 'none',
    border: 'none',
  },

  '&[data-active="true"]': {
    bgImg: `url(${activeBg})`,
    color: 'delta.300',
    bgSize: '100% 100%',
    border: 'none',
    boxShadow: '0px 0px 0px 3px rgba(0, 0, 0, 0.45) inset',

    _hover: {
      bgImg: `url(${activeBg})`,
      color: 'delta.500',
      bgSize: '100% 100%',
      boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.45) inset',
    },
  },
});

export const LinkRaceDetails: CSSObject = {
  color: '#F2D194',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(180deg, alpha.500, alpha.600)',
  boxShadow: '0px 2px 5px alpha.700',
  textTransform: 'uppercase',
  fontFamily: 'SkranjiBold',
  fontWeight: 'normal',
  p: '0.6rem 0.5rem 0.5rem 0.5rem',

  _hover: {
    bgGradient: 'linear(180deg, alpha.600, alpha.700)',
  },
};
