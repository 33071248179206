import api from '@/api/api';
import { TMutateClaimTokenResponse } from './claimToken.types';

export const claimTokenEndpoint = '/punter/promotions/claim_token';

export async function mutateClaimToken(tokenId: string) {
  const res = await api.post<TMutateClaimTokenResponse>(
    `${claimTokenEndpoint}/${tokenId}`,
    undefined
  );
  return res;
}
