import { ButtonProps, CSSObject } from '@chakra-ui/react';
import promoSport from '@/assets/vikingbet/images/backgrounds/promo-sport.png';
import promoSportMobile from '@/assets/vikingbet/images/backgrounds/featuredSportMobile.png';
import shield from '@/assets/vikingbet/images/backgrounds/shield.png';

export const FlexTeamWrapper: CSSObject = {
  gap: '12px',
  mt: '4px',
  border: 0,
};

export const FlexWrapper: CSSObject = {
  border: 'none',
  h: '340px',
  bgImage: [`url(${promoSportMobile})`, null, `url(${promoSport})`],
  borderRadius: '7px',
  bgPos: ['center', null, 'bottom'],
  bgRepeat: 'no-repeat',
  bgSize: ['100%', null, '100% 100%'],
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
};

export const TextVS: CSSObject = {
  borderRadius: '4px',
  bgGradient: 'linear(to-b, epsilon.300, epsilon.600)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  display: 'flex',
  width: '31px',
  height: '31px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'var(--bc-black-alpha-800, rgba(0, 0, 0, 0.80))',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
  fontSize: '13.286px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: 'normal',
};
export const TextMisc: CSSObject = {
  borderRadius: 'base',
  fontSize: '12px',
  h: ['30px'],
  bg: 'blackAlpha.400',
  color: 'epsilon.400',
  display: 'inline-flex',
  alignItems: 'center',
  textShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontWeight: 'extrabold',
  boxShadow:
    '0px 1px 0px 0px rgba(207, 104, 12, 0.40), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
};

export const TextTeam: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: ['14px', null, '18px'],
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
  textTransform: 'none',
};
export const BoxHeadingWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'center',
  marginLeft: ['auto', null, '40%', '45%'],
  my: ['4', null, null, null, 'auto'],
  mr: ['auto', null, null, null, '8'],
  maxW: ['450px', null, null, null, '445px'],
  w: ['unset', null, null, null, '445px'],
};
export const ButtonPunt: ButtonProps & any = {
  borderRadius: 'md',
  border: '2px',
  borderColor: '#CD8716',
  bgGradient: 'linear(to-b, #FCD236, #E79004)',
  boxShadow: 'inset 0px 0px 0px 1px #FAD461',
  p: '12px',
  minH: '44px',
  color: 'beta.800',
  fontFamily: 'SkranjiBold',
  textTransform: 'uppercase',
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const HeaderWrapper: CSSObject = {
  mt: '0.5rem',
  fontSize: ['38px', null, '44px'],
  fontFamily: 'SkranjiBold',
  textAlign: 'center',
  lineHeight: '100%',
  background: 'linear-gradient(180deg, #FCD236 0%, #E79004 100%)',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  WebkitTextStrokeWidth: '3px',
  filter:
    'drop-shadow(0px 4px 0px #0b0b0b14) drop-shadow(0px 6px 3px rgba(0, 0, 0, 0.35))',
  WebkitTextStrokeColor: '#A1741F',
  textTransform: 'uppercase',
  w: ['350px', '450px', null],
};

export const puntButtonProps: CSSObject = {
  variant: 'secondary',
  cursor: 'pointer',
  zIndex: 'dropdown',
  alignItems: 'center',
};

export const FlexIllustrationWrapper: CSSObject = {
  bgImage: `url(${shield})`,
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
  bgPos: 'bottom',
  height: '100%',
  width: ['100%', null, '45%', null],
  bottom: '0',
  left: ['0', null, '2%', null],
  top: ['0', null, '-4%', null],
  display: ['none', null, 'block', null],
  position: ['relative', null, 'absolute', null],
  minH: '36',
  mt: ['4', null, '0', null],
};
