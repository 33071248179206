import { CSSObject } from '@chakra-ui/react';
import bg from '../../../assets/titanbet/images/onboarding/onboardingBg.png';

export const WrapperFlexOnboarding: CSSObject = {
  bgImage: `url(${bg}), linear-gradient(180deg, var(--bc-colors-beta-800), var(--bc-colors-beta-900))`,
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, 'md'],
  bg: 'beta.900',
  boxShadow: [
    null,
    null,
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(193, 146, 98, 1) inset, 0px 0px 0px 3px rgba(27, 30, 31, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  ],
  pos: 'relative',
  w: ['100dvw', null, 'unset'],
  p: ['unset', null, '5px'],
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '460px'],
  h: '100%',
  bg: 'gamma.600',
  '#email': {
    bg: 'alpha.700',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    color: 'white',
    fontWeight: 'normal',
    borderRadius: 'lg',
    h: '38px',
    border: 'none',
    ':hover, :focus': {
      bg: 'alpha.600',
    },
  },
};

export const Title: CSSObject = {
  color: 'gamma.200',
  fontSize: 'xs',
  fontFamily: 'accent',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  display: 'flex',
  mb: '4',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  '&&&': {
    borderRadius: 'base',
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    border: 'none',
    color: 'white',
    fontWeight: 'normal',
    '&&:hover, &&:focus': {
      bg: 'blackAlpha.600',
    },

    _placeholder: {
      color: 'white',
    },
    '+ .chakra-input__right-element': {
      color: 'gamma.200',
    },
  },
};
