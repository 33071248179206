import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { spacing: '1', mb: '2' },
  link: {
    minW: ['unset', null, '24'],
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear(to-b, zeta.400, zeta.600)',
    boxShadow:
      '0px 1px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
    borderRadius: 'base',
    borderWidth: '2px',
    borderColor: 'rgba(255, 255, 255, 0.09)',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    fontSize: '2xs',
    fontWeight: 'bold',
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    color: 'alpha.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
    sx: {
      _hover: {
        bg: 'zeta.100',
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
        color: 'alpha.400',
      },
      '&.active': {
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
        color: 'white',
        fontWeight: 'bold',
        textShadow: 'none',
        border: '2px',
        borderColor: 'blackAlpha.100',
        fontSize: '2xs',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
      },
    },
  },
};
