import { CSSObject } from '@chakra-ui/react';

export const GridRaceCards: CSSObject = {
  gap: ['2', '2', '3.5'],
  mt: '0',
  mb: '0',
};

export const FlexCard: CSSObject = {
  p: '1',
  borderRadius: 'lg',
  bg: 'epsilon.700',
  border: '1px solid',
  borderColor: '#5c6d8e',
  my: '0',
};

export const LinkCardHeader: CSSObject = {
  '.state-prior': { color: 'white' },
};

export const IconCardHeader: CSSObject = {
  color: 'beta.500',
  boxSize: '4',
};

export const TextCardHeading: CSSObject = {
  color: 'white',
  fontFamily: 'KHInterference',
  fontWeight: 'bold',
};

export const TextRaceNumber: CSSObject = {
  borderRadius: 'base',
  bg: 'alpha.600',
  h: '18px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '18px',
  px: '1',
  color: 'gamma.400',
};

export const GridCardContent: CSSObject = {};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'rgba(0, 0, 0, 0.20)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 4px -3px rgba(0, 0, 0, 0.30) inset',
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  px: '2.5',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '1px',
  borderBottomColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20)',
  py: 0,
  h: '46px',
};

export const ImageRunnerSilk: CSSObject = {
  boxSize: '7',
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'semibold',
  fontFamily: 'KHInterference',
};

export const TextScratched: CSSObject = {
  fontSize: '8px',
  color: 'white',
  textDecoration: 'line-through',
};

export const TextRunnerNumber: CSSObject = {
  fontSize: 'smxs',
  '&:nth-child(2)': {
    fontSize: '2xs',
    position: 'relative',
    top: '-0.5',
  },
};

export const LinkRaceDetails: CSSObject = {
  bg: 'linear-gradient(180deg, #263BF4 0%, #2534BF 100%)',
  boxShadow:
    '0px 0px 2px 2px rgba(74, 93, 255, 0.60) inset, 0px 2px 9px 0px rgba(0, 0, 0, 0.35), 0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
  textTransform: 'uppercase',
  borderBottomRadius: 'base',
  fontFamily: 'KHInterference',
  _hover: {
    bg: 'linear-gradient(180deg, #1e2a98 0%, #172075 100%)',
  },
};

export const Button = (): CSSObject => ({
  borderRadius: 'md',
  fontSize: 'sm',
  fontWeight: 'bold',
});
