import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  footer: {
    bg: 'transparent',
    color: 'black',
  },
  stackGap: '1',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    h: 'auto',
    pr: '0',
    mb: ['4', '8'],
    gap: '2',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
    sx: {
      '& > img': {
        maxW: '250px',
        h: '35px',
        ml: '0',
      },
    },
  },
  footerLink: {
    fontWeight: 'bold',
    color: 'alpha.600',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
  },
  footerLinkService: {
    color: 'alpha.600',
    fontSize: 'md',
    fontWeight: 'bold',
  },
  footerLinkTel: {
    fontWeight: 'bold',
    color: 'alpha.600',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
  },
  customFooterLink: {
    fontWeight: 'bold',
    color: 'alpha.600',
    textTransform: 'capitalize',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontSize: 'sm',
  },
  footerLinks: {
    mt: '3',
    ml: ['unset', null, 'auto'],
    pb: ['4', null, '0'],
    alignItems: 'center',
    justifyContent: ['space-around', null, 'unset'],
    color: 'alpha.600',
    fontWeight: 'medium',
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
  },
  footerContentContainer: {
    px: ['8', 'unset'],
    justifyContent: 'space-between',
    sx: {
      '&': {
        flexDirection: 'column',
      },
    },
    textAlign: ['center', 'left'],
  },
  nav: {
    fontSize: 'sm',
    fontWeight: 'medium',
    minW: [null, null, '370px'],
    mt: ['1', '4', 'unset'],
    display: ['flex', 'grid'],
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '28',
    rowGap: ['0', '1'],
    alignItems: ['center', 'end'],
    gridTemplateAreas: `
      ". ."
    `,
    sx: {
      '> a': {
        color: 'inherit',
      },
    },
    color: ['alpha.600', 'inherit'],
  },
  emailUs: {
    fontWeight: ['bold', 'unset'],
    fontSize: ['md', 'unset'],
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', 'left'],
    color: 'black',
    mb: '2',
    ml: [null, '0'],
    mr: [null, null, '4'],
    width: [null, 'auto', '100%'],
  },
  blurbHeading: {
    ml: 0,
  },
  blurbHeadingAge: {
    border: '4px',
    mb: '2',
  },
  blurbHeadingThink: {
    fontSize: 'sm',
    fontWeight: 'bold',
    maxW: '410px',
    mb: '3',
  },
  blurbContent: {
    ml: 'unset',
  },
  copyright: {
    textAlign: ['center', 'left'],
    mt: '1',
    px: ['8', 'unset'],
    fontWeight: ['semibold', 'medium'],
  },
  container: {
    pb: ['15vw', '20vw', null, '5vw'],
    sx: {
      '&&': {
        px: ['0', '2'],
      },
    },
  },
  quickLinks: {
    fontSize: 'md',
    textAlign: ['center', 'left'],
    pb: '0.5',
  },
  quickLinksBoxProps: {
    mt: '3',
    mb: '6',
  },
  ourPartners: {
    fontSize: 'md',
  },
  weAccept: {
    fontSize: 'md',
  },
  weAcceptBox: {
    mb: '2',
  },
};
