import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: 'lg',
  overflow: 'hidden',

  '.chakra-accordion__item': {
    _first: {
      borderTop: '0',
    },

    _last: {
      borderBottom: '0',
    },
  },

  '.chakra-accordion__panel': {
    bg: 'gray.200',
    borderTop: '1px',
    borderTopColor: 'gray.300',
    color: 'gray.700',
  },

  '.chakra-checkbox__control': {
    bg: 'white',

    _checked: {
      bg: 'black',
    },
  },
};

export const AccordionHeader: CSSObject = {
  bg: 'white',
  color: 'alpha.600',
  transitionDuration: 'normal',
  transitionProperty: 'common',
};

export const AccordionHeaderLogOut: CSSObject = {
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  border: 'none',
};

export const IconPerson: CSSObject = {
  boxSize: ['5', null, '6'],
  mr: '1.5',
};

export const TextAccordion: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  fontWeight: 'semibold',
  mr: 'auto',
};

export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  bg: 'alpha.500',
  borderRadius: 'lg',
  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
  color: 'white',
  fontSize: 'xs',
  gap: '1',
  p: '2',
};
