import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { keys } from '../api/api.keys';
import { queryContentful } from '../api/contentful/contentful';
import { logError } from '@/helpers/utils';

/**
 * Hook that polls contentful endpoint to ensure the bookie hasn't brought
 * down the site for maintenance. If so, navigate to maintenance route.
 */
export const useMaintenance = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: downForMaintenance, isInitialLoading } = useQuery(
    [keys.contentful],
    queryContentful,
    {
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      onError(err) {
        logError(err);
      },
    }
  );

  useEffect(() => {
    switch (downForMaintenance) {
      case undefined:
        return;
      case true:
        navigate('/maintenance', { replace: true });
        break;
      case false:
        if (pathname === '/maintenance') {
          navigate('/', { replace: true });
        }
        break;
      default:
    }
  }, [downForMaintenance, navigate, pathname]);

  return {
    downForMaintenance,
    isLoading: isInitialLoading,
  };
};
