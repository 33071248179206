import { CSSObject } from '@chakra-ui/react';
import desktop from '@/assets/fiestabet/images/banner/desktop.png';
import mobile from '@/assets/fiestabet/images/banner/mobile.png';

export const BoxCarouselSlide: CSSObject = {
  w: '100%',
  h: ['35vh', '45vh', '230px', '225px'],
  bgImage: [`url(${mobile})`, null, `url(${desktop})`, `url(${desktop})`],
  bgSize: '100% 100%',
  bgPosition: 'center',
  bgRepeat: 'no-repeat',
  borderBottom: '4px',
  borderColor: 'transparent',
};
