/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import {
  DepositLimitForm,
  OptionsSchema,
} from '@/components/forms/DepositLimit/DepositLimit';
import { useDepositLimitModalController } from './lib/DepositLimit.controller';

/**
 * Modal component
 */
export default function DepositLimitModal() {
  const intl = useIntl();
  const {
    isOpen,
    hasDL,
    acknowledgePrompt,
    handleSubmit,
    existingDepositLimitOptions,
  } = useDepositLimitModalController();

  return (
    <Modal
      isOpen={isOpen}
      onClose={acknowledgePrompt}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({
            id: `depositLimit.modal.title.${hasDL ? 'hasLimit' : 'noLimit'}`,
          })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <DepositLimitContainer
            handleSubmit={handleSubmit}
            options={existingDepositLimitOptions}
            hasDL={hasDL}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

/**
 * Deposit Limit Container
 */
type DepositLimitContainerProps = {
  handleSubmit: (values: any, { setSubmitting }: any) => void;
  options: OptionsSchema[] | undefined;
  hasDL: boolean;
};

function DepositLimitContainer({
  handleSubmit,
  options,
  hasDL,
}: DepositLimitContainerProps) {
  return (
    <DepositLimitForm
      handleSubmit={handleSubmit}
      options={options}
      hidePeriod={hasDL}
    />
  );
}
