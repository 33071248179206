import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  bg: state === 'inprogress' ? 'alpha.600' : 'red.500',
  borderRadius: '3px',
  pb: '0',
  '&&': {
    minH: '17px',
    height: '17px',
    maxH: '17px',
    boxShadow: 'none',
    lineHeight: '17px',
    fontSize: '9px',
    color: state === 'inprogress' ? 'black' : 'white',
  },
});
