import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FormsHStack } from '../../../BetCard/components/Forms/Forms.styles';
import { TSingleSportMoMLeg } from '../types';
import { BetCardSVG, EventTitle } from '../../../Card/styles/BetCard.styles';
import { InfoExoticsContainer } from '../../../BetCard/components/Info/styles/Info.styles';
import { getIconBySportName } from '@/helpers/utils';
import { srmInfoStyles } from '../../../BetCard/components/Info/SRM/styles/SRMInfo.styles';

interface IMoMSingleSportsCardItemProps {
  bet: TSingleSportMoMLeg;
}

export function MoMSingleSportsCardItem({
  bet,
}: IMoMSingleSportsCardItemProps) {
  return (
    <FormsHStack gap={2} mt={2} direction="column">
      <Flex direction="column" gap="2" w="full">
        <Flex w="full">
          <BetCardSVG header name={getIconBySportName(bet.iconType)} />
          <InfoExoticsContainer gap={2}>
            <EventTitle {...srmInfoStyles.runnerDetails}>
              {bet.title}
            </EventTitle>
            <Text {...srmInfoStyles.eventMarketName}>{bet.subTitle}</Text>
            <Text {...srmInfoStyles.eventSubtitle}>{bet.description}</Text>
          </InfoExoticsContainer>
        </Flex>
      </Flex>
    </FormsHStack>
  );
}
