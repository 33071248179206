import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  keyframes,
  Skeleton,
  Switch,
  SwitchProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggle from '@/assets/goldenrush/images/switch/hammerFramed.png';
import sidePiston from '@/assets/goldenrush/images/betslip/side-piston.png';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'toggle-ToggleFlexWrapper',
  baseStyle: {
    mx: 'auto',
    bgGradient: 'linear(to-b, beta.600, beta.400)',
    width: '100%',
    height: '76px',
    justifyContent: 'center',
    p: '2.5',
    borderRadius: 'lg',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
    alignItems: 'center',
    zIndex: '3',
    pos: 'relative',
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      top: '1',
      left: '1',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, beta.400, beta.600)',
      boxShadow: '0px 1px 0px 0px #ED8934',
      zIndex: '3',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '16%',
      zIndex: '4',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      top: '2',
      left: '2',
      borderRadius: 'base',
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
    },
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: ({ isActive }: any) => ({
      fontFamily: 'Bungee',
      fontWeight: '100',
      textTransform: 'uppercase',
      textShadow: isActive
        ? '0px 3px 4px rgba(0, 0, 0, 0.3)'
        : '0px 1px 0px rgba(255, 255, 255, 0.12)',
      color: isActive ? 'gamma.500' : 'rgba(0, 0, 0, 0.50)',
      letterSpacing: '0.01rem',
      px: '3',
      zIndex: '5',

      _before: {
        content: ['none', '""'],
        display: 'block',
        position: 'absolute',
        top: '24px',
        left: '-8px',
        width: '30px',
        height: '85px',
        zIndex: 'docked',
        bg: `url(${sidePiston})`,
        bgRepeat: 'no-repeat',
        bgSize: '23px 35px',
      },

      _after: {
        content: ['none', '""'],
        display: 'block',
        position: 'absolute',
        top: '24px',
        right: '-14px',
        width: '30px',
        height: '85px',
        zIndex: 'docked',
        bg: `url(${sidePiston})`,
        bgRepeat: 'no-repeat',
        bgSize: '23px 35px',
      },
    }),
  });

const Switcher: ChakraComponent<'label', SwitchProps> = chakra(Switch, {
  label: 'Switcher',
  baseStyle: {
    '.chakra-switch__track': {
      bg: 'blackAlpha.500',
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      w: '55px',
      zIndex: '5',
      pos: 'relative',
      borderRadius: 'md',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: '5',

      _before: {
        bgImg: `url(${toggle})`,
        height: '3.5rem',
        width: '3.5rem',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        bottom: '60px',
        left: '-3',
        right: '0',
        top: '-4',
        animation: `
              ${keyframes`
                from { transform: rotate(0deg) }
                to { transform: rotate(-360deg) }
              `} 0.5s ease-out`,
      },
    },

    _active: {
      '.chakra-switch__track': {
        boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      },
    },

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
        _before: {
          animation: `
              ${keyframes`
                from { transform: rotate(0deg); }
                to { transform: rotate(360deg); }
              `} 0.7s ease-out`,
        },
      },
      '.chakra-switch__track': {
        bg: 'blackAlpha.500',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={!!category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sports
      </TextToggle>
    </FlexWrapper>
  );
}
