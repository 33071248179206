import { CSSObject, Flex, chakra, Text } from '@chakra-ui/react';
import { Button } from '@/components/Button/Button';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './GetVerified.styles.imports';

type TOverrideName =
  | 'FlexGetVerified'
  | 'BoxGetVerified'
  | 'GetVerifiedText'
  | 'ButtonGetVerified';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};

// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const GetVerifiedText = chakra(Text, {
  baseStyle: {
    color: 'white',
    fontWeight: 'bold',

    ...styles?.GetVerifiedText,
  },
});

export const FlexGetVerified = chakra(Flex, {
  baseStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    p: '2.5',
    gap: '2.5',
    isolation: 'isolate',
    w: '100%',
    h: '14',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow:
      '0px 3px 8px rgba(0, 0, 0, 0.4), inset 0px 0px 6px rgba(255, 255, 255, 0.4), inset 0px 0px 10px #3B4175',
    borderRadius: 'md',

    ...styles?.FlexGetVerified,
  },
});

export const ButtonGetVerified = chakra(Button, {
  baseStyle: {
    bg: 'alpha.500',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px 1px 0px #FFFFFF',
    borderRadius: 'base',
    color: 'alpha.900',
    fontSize: 'xs',
    textTransform: 'uppercase',
    w: '32',
    h: '6',

    ...styles?.ButtonGetVerified,
  },
});
