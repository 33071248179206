import { DeepPartial } from '@reduxjs/toolkit';
import {
  TBetSlipBetSubmissionExoticsType,
  TPromoToken,
} from '@/components/Betslip/Services/Betslip.types';
import { EPunterStatus } from './punter/types';
import { DeepPartial as TDeepPartial } from './types';

export enum ERaceType {
  Horse = 'Horse Racing',
  Greyhound = 'Greyhounds',
  Harness = 'Harness Racing',
}

export enum EEventType {
  Match = 'Match',
  Race = 'Race',
}

export enum ESportType {
  AFL = 'AFL Football',
  RugbyLeague = 'Rugby League',
  RugbyUnion = 'Rugby Union',
  Soccer = 'Soccer',
  Football = 'American Football',
  Baseball = 'Baseball',
  Basketball = 'Basketball',
  Boxing = 'Boxing',
  Cycling = 'Cycling',
  Volleyball = 'Volleyball',
  Cricket = 'Cricket',
  FieldHockey = 'Field Hockey',
  IceHockey = 'Ice Hockey',
  Tennis = 'Tennis',
  Darts = 'Darts',
  MotorSports = 'Motor Sport',
  Golf = 'Golf',
  Snooker = 'Snooker',
  Politics = 'Politics',
  Exotics = 'Sports Exotics',
  TodaysOffers = 'Todays Offers',
  BoxChallenge = 'Box Challenge',
  RacingExtras = 'Racing Extras',
  WinterOlympics = 'Winter Olympics',
  Entertainment = 'Entertainment',
  Surfing = 'Surfing',
  JockeyChallenge = 'Jockey Challenge',
}

export enum EGeneralStatus {
  Open = 'Open',
  Closed = 'Closed',
  Abandoned = 'Abandoned',
  Settled = 'Settled',
  Voided = 'Voided',
  Scratched = 'Scratched',
  Interim = 'Interim',
  Removed = 'Removed',
}

export enum ETransactionStatus {
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  STAKE = 'Stake',
  WON = 'Won',
  LOST = 'Lost',
  PAID = 'Paid',
  APPROVED = 'Approved',
}

export enum EBetStatus {
  Pending = 'pending',
  Settled = 'settled',
  PartiallySettled = 'partially_settled',
  Voided = 'voided',
  CashedOut = 'cashed_out',
  Rejected = 'rejected',
}

export interface IPagedResponse {
  total_pages: number;
  total_records: number;
}

export type TTransactionsDTO = TDeepPartial<TTransaction>[];

export enum ETransactionType {
  Deposit = 'Deposit',
  Bet = 'Bet',
  Winnings = 'Winnings',
  Bonus = 'Bonus',
  Withdrawal = 'Withdrawal',
  Void = 'Void',
  Adjustment = 'Adjustment',
  Credit = 'Credit',
}

export enum ETransactionSubType {
  Single = 'Single',
  Exotics = 'Exotics',
  Multi = 'Multi',
  Bonus = 'Bonus',
  SGMulti = 'SGMulti',
  SRMulti = 'SRMulti',
  Blended = 'Blended',
  ToteMulti = 'ToteMulti',
}

export type TPropositionDetails = {
  price: number;
  price_type?: TPriceType;
  runner_name: string;
  runner_number: number;
  proposition_name: string;
  proposition_type?: string;
};

export type TTransactionDetails = TDeepPartial<{
  // Bets
  bet_id: string;
  bet_status: EBetStatus;
  market_type: string;
  market_name: string;
  event_type: EEventType;
  bet_odds: number;
  bet_stake: number;

  // Sports
  match_name: string;
  sport_name: string;
  match_start_time?: string;
  odds?: number;

  // Racing singles/exotics
  venue_name: string;
  race_number: string;
  race_start_time?: string;
  race_name: string;
  race_type?: string;

  proposition_details: TPropositionDetails;

  // Exotics
  exotics_sub_type: TBetSlipBetSubmissionExoticsType;
  selections: number[][];
  boxed?: boolean;

  // Multis
  leg_count: number;

  // Withdrawals
  method: string;
  withdrawal_id: string;

  // Manual Adjustment
  reason: string;
  leg_details?: LegDetailsMatch[];

  promos?: TPromoToken;

  // Tote Multi
  tote_multi_type: string;
  legs: number[];
}>;

export type LegDetailsMatch = {
  event_type: EEventType;

  market_type?: string;
  market_name?: string;

  // Race
  race_name?: string;
  race_type?: string;
  race_start_time?: string;
  race_number?: number;
  venue_name?: string;

  // Sport
  sport_name: string;
  match_name?: string;
  match_start_time?: string;
  price_type?: TPriceType;
  leg_num?: number | null;

  proposition_details: {
    runner_number?: number;
    runner_name?: string;
    proposition_name?: string;
    top_selection?: string;
    price: number;
    price_type?: TPriceType;
  };
};

export type TTransaction = TDeepPartial<{
  transaction_id: string;
  transaction_type: ETransactionType;
  status: ETransactionStatus;
  timestamp: string;
  bet_detail_list: string[];
  bet_details: string;
  bet_odds: number;
  bet_stake: number;
  bet_status: string;
  payment_method: string | null;
  amount: number;
  running_balance: number;
  running_restricted_balance: number;
  exotic_selections?: TExoticSelections[];
  sub_type: ETransactionSubType;
  details: TTransactionDetails;
}>;

export type TState = TDeepPartial<{
  venues: TVenue[];
  display_name: string;
  all_offered: boolean;
}>;

export type TVenue = TDeepPartial<{
  venue_id: string;
  venue_name: string;
  venue_country: string;
  venue_display_name: string;
  is_offered?: boolean;
  notification_count?: number;
  has_races?: boolean;
  venue_races: TRace[];
}>;

export type TSimpleRunnerResult = TDeepPartial<{
  result_place: string;
  runner_number: string;
}>;

export type TCountry = TDeepPartial<{
  states: TState[];
  venues: TVenue[];
  display_name: string;
  all_offered: boolean;
}>;

export type TBadge = {
  [badgeName: string]: string;
};

export enum EMarketMoverSchema {
  FireHigh = 'Fire High Animation',
  FireMedium = 'Fire Medium Animation',
  FireLow = 'Fire Low Animation',
  IceHigh = 'Ice High Animation',
  IceMedium = 'Ice Medium Animation',
  IceLow = 'Ice Low Animation',
}

export type TFlucsData = {
  opening_price?: number;
  flucs?: number[];
  market_mover?: EMarketMoverSchema;
};

export type TFormData = {
  race_type?: string;
  trainer_name?: string;
  rider_name?: string;
  last_5_starts?: string;
  handicap_weight?: number;
  harness_handicap?: string;
  badges?: TBadge[];
};

export type TRunner = TDeepPartial<{
  runner_id: string;
  race_runner_id: string;
  display_name: string;
  number: number;
  barrier_number: number;
  win_proposition: TProposition;
  win_odds: number | null;
  place_proposition: TProposition;
  place_odds: number | null;
  silk_url: string;
  status: EGeneralStatus;
  is_scratched: boolean;
  scratch_time: string;
  win_deductions: number | null;
  place_deductions: number | null;
  form_data?: TFormData;
  flucs_data?: TFlucsData;
}>;

export type TRace = TDeepPartial<{
  race_id: string;
  status: EGeneralStatus;
  display_name: string;
  race_type: string;
  start_time: string;
  meeting_date: string;
  runners?: TRunner[];
  race_number: number;
  results: TSimpleRunnerResult[];
  venue_name: string;
  venue_id: string;
  number_of_places?: number;
  active_runner_count: number | null;
}>;

export type TRaceFormData = {
  race_type: string;
  race_distance?: number;
  weather_condition?: string;
  race_class_conditions?: string;
  track_condition?: string;
};

export type TRaceMeta = TDeepPartial<{
  race_id: string;
  display_name: string;
  race_number: number;
  start_time: string;
  meeting_date: string;
  status: EGeneralStatus;
  race_type?: string;
  number_of_places?: number;
  form_data?: TRaceFormData;
}>;

export type TDividends = {
  tote_single_mid?: number;
  tote_single_best?: number;
  other_tote?: number;
};

export type TRunnerResult = TDeepPartial<{
  race_runner_id: string;
  runner_name: string;
  runner_number: string;
  result_place: string;
  barrier_number: string;
  win_odds: number;
  place_odds: number;
  silk_url: string | null;
  win_proposition: {
    dividends?: TDividends;
  };
  place_proposition: {
    dividends?: TDividends;
  };
}>;

// race_id and runner_results props are always returned, but nullable
export type TRaceResults = TDeepPartial<{
  race_id: string | null;
  runner_results: TRunnerResult[] | null;
  exotic_results: TRaceToteResult[] | null;
}>;

export type TRaceToteResult = TDeepPartial<{
  dividend: number;
  type: ERaceBetType;
  selection: number[];
}>;

// ! The following three types should be consolidated into one
export enum ERaceBetType {
  RacingWin = 'Racing Win',
  RacingPlace = 'Racing Place',
  Quinella = 'Quinella',
  Exacta = 'Exacta',
  Trifecta = 'Trifecta',
  FirstFour = 'FirstFour',
  Win = 'Win',
  WinFixed = 'Win (Fixed)',
  PlaceFixed = 'Place (Fixed)',
  SRMulti = 'SRMulti',
  EvenShot = 'Even Shot',
}

// Originally created for <Exotics>
export enum ETabbarBetTypes {
  Exacta = 'exacta',
  Quinella = 'quinella',
  FirstFour = 'firstfour',
  Trifecta = 'trifecta',
  RacingWin = 'racingWin',
  RacingPlace = 'racingPlace',
  SRMulti = 'SRMulti',
  SGMulti = 'SGMulti',
  EvenShot = 'Even Shot',
  Blended = 'Blended',
  Quaddie = 'Quaddie',
  EarlyQuaddie = 'EarlyQuaddie',
  DailyDouble = 'DailyDouble',
  RunningDouble = 'RunningDouble',
  Treble = 'Treble',
  BigSix = 'BigSix',
}

export enum EBetTypes {
  Exacta = 'exacta',
  Quinella = 'quinella',
  FirstFour = 'firstfour',
  Trifecta = 'trifecta',
  RacingWin = 'racingWin',
  RacingPlace = 'racingPlace',
  SRMulti = 'Same Race Multi',
  SGMulti = 'Same Game Multi',
  EvenShot = 'Even Shot',
  Blended = 'Blended',
  MysteryBet = 'Mystery Bet',
}

// Originally created for <Exotics>
export enum EBetTypesDisplayNames {
  Exacta = 'Exacta',
  Quinella = 'Quinella',
  Trifecta = 'Trifecta',
  FirstFour = 'First Four',
  RacingWin = 'Racing Win',
  RacingPlace = 'Racing Place',
  Win = 'Win',
  WinPlace = 'Win/Place',
  TopMarket = 'Top Market',
  SRMulti = 'Same Race Multi',
  SGMulti = 'Same Game Multi',
  Blended = 'Blended',
  EvenShot = 'Even Shot',
  MysteryBet = 'Mystery Bet',
  Quaddie = 'Quaddie',
  EarlyQuaddie = 'EarlyQuaddie',
  DailyDouble = 'DailyDouble',
  RunningDouble = 'RunningDouble',
  Treble = 'Treble',
  BigSix = 'BigSix',
  Multiples = 'Multiples',
  Exotics = 'Exotics',
}

// Race item without runners for next to jump
export type TNextToJumpRace = TDeepPartial<{
  race_id: string;
  race_type: ERaceType;
  start_time: string;
  meeting_date: string;
  race_number: number;
  venue_name: string;
  venue_country: string;
  venue_id: string;
  active_runner_count: number | undefined;
}>;

export type TSport = TDeepPartial<{
  sport_id: string;
  display_name: string;
  sport_name: string;
  icon: string[];
}>;

export type TCompetition = TDeepPartial<{
  competition_id: string;
  sport_id: string;
  competition_name: string;
  tournaments: TTournament[];
  display_name?: string;
  is_offered?: boolean;
}>;

export type TSportCompetitionView = TDeepPartial<{
  sport_id: string;
  sport_name: string;
  sport_icon: string[];
  competitions: TCompetition[];
}>;

export type TTournament = TDeepPartial<{
  tournament_id: string;
  tournament_name: string;
}>;

export type TRaceNumbersForVenueResponse = TDeepPartial<
  Omit<TRace, 'display_name'>
>;

export type TBetType =
  | 'Single'
  | 'Multi'
  | 'SGMulti'
  | 'SRMulti'
  | 'ComboMulti'
  | 'Tote'
  | 'Exotics'
  | 'Blended';

export type TPriceType =
  | 'starting'
  | 'fixed'
  | 'sgm'
  | 'srm'
  | 'tote_single_mid'
  | 'tote_single_best'
  | 'blended'
  | 'even_shot'
  | 'mystery_bet';

export type TMarketInfo = {
  mystery_bet_rollover_enabled?: boolean;
};

export type TMarket = TDeepPartial<{
  market_id?: string;
  market_name?: string;
  market_info?: TMarketInfo;
  disclaimer?: string;
  bet_option?: string;
  bet_option_sort_order?: number;
  market_type?: string;
  status?: EGeneralStatus;
  is_suspended?: boolean;
  propositions?: TProposition[];
  price_types?: TPriceType[];
}>;

export type TRaceMarket = DeepPartial<
  TMarket & {
    status: string;
    exposure: number;
    is_suspended: boolean;
    open_at_time?: string;
  }
>;

export type TPropositionOutcome = 'Winner' | 'Loser' | 'Top2' | 'Top3';

export type TSRMPrices = {
  top_1?: number;
  top_2?: number;
  top_3?: number;
  top_4?: number;
};

export type TProposition = TDeepPartial<{
  proposition_id: string;
  proposition_name: string;
  proposition_type: string;
  return_amount?: number;
  winter_return_amount?: number;
  status: EGeneralStatus;
  is_suspended?: boolean;
  outcome?: TPropositionOutcome;
  sgm_available?: boolean;
  srm_price?: TSRMPrices;
  price_types?: TPriceType[];
  market_id?: string | null;
  proposition_info?: TEventInfo | null;
  sort_order: number | null;
  race_type?: string | null;
  venue_name: string;
  race_name: string;
  race_number?: number;
  runner_number?: number;
  barrier_number?: number;
}>;

export type TMatch = TDeepPartial<{
  match_id: string;
  match_name: string;
  match_start_time: string;
  sport_id: string;
  sport_name: string;
  competition_id: string;
  competition_name: string;
  tournament_id: string;
  tournament_name: string;
  market_count?: number;
  status?: EGeneralStatus;
  is_suspended?: boolean;
  head_to_head_market?: TMarket;
  sgm_available?: boolean;
  match_info: TMatchInfo | null;
}>;

export type TMatchInfo = {
  participants: TMatchInfoParticipant[] | null;
};

export type TMatchInfoParticipant = {
  name: string | null;
  role: string | null;
  logo_url: string | null;
};

export type TMarketGroup = TDeepPartial<{
  market_group_id: string;
  market_group_title: string;
  markets: TMarket[];
}>;

export type TMarketsByMatchView = TDeepPartial<{
  match_id: string;
  display_name: string;
  sport_name: string;
  start_time: string;
  market_groups: TMarketGroup[];
  competition_name: string;
}>;

export type TSports = TDeepPartial<{
  competitions: TCompetition[];
  all_offered: boolean;
  display_name: string;
}>;

export type TMarketingPreferences = TDeepPartial<{
  email_marketing_enabled: boolean;
  sms_marketing_enabled: boolean;
  phone_marketing_enabled: boolean;
  post_marketing_enabled: boolean;
}>;

export type TBasicPunter = TDeepPartial<{
  punter_id: string;
  firebase_uid: string;
  is_onboarded: boolean;
  identity_verified: boolean;
  platform_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  title: string;
}>;

export type TFullPunter = TDeepPartial<
  Omit<TBasicPunter, 'firebase_uid' | 'platform_id'> &
    TMarketingPreferences & {
      email: string;
      mobile: string;
      street_number: string;
      street_name: string;
      suburb: string;
      state: string;
      post_code: string;
      date_of_birth: string;
      deposit_limit_current?: number | null;
      deposit_limit_waiting?: number | null;
      deposit_limit_active?: string | null;
      deposit_limit_frequency?: number | null;
      deposit_limit_frequency_current?: number | null;
      deposit_limit_cooldown_ends?: string | null;
      deposit_limit_last_updated?: string | null;
      pc: string;
    }
>;

export type TDepositLimit = TDeepPartial<
  Pick<
    TFullPunter,
    | 'deposit_limit_current'
    | 'deposit_limit_waiting'
    | 'deposit_limit_active'
    | 'deposit_limit_frequency'
    | 'deposit_limit_frequency_current'
    | 'deposit_limit_cooldown_ends'
    | 'deposit_limit_last_updated'
  >
>;

export type TVerificationModel = TDeepPartial<{
  verification_id: string;
  verification_status: string;
  verification_token: string;
}>;

export type TPunterAccountOverview = TDeepPartial<{
  punter_name: string;
  account_number: string;
  account_balance: number;
  available_balance: number;
  bonus: number;
  power_plays: number;
  spins: number;
  bets: number;
  is_onboarded: boolean;
  identity_verified: boolean;
  status: EPunterStatus;
  self_exclusion_expiry: string;
}>;

export type TBetEventData = {
  venue_name?: string;
  race_name?: string;
  race_number?: number;
  runner_name?: string;
  runner_number?: number;
  runner_barrier_number?: number;
  match_name?: string;
  proposition_name?: string;
  venue_id: string;
  race_meeting_date: string;
  competition_name?: string;
  competition_id?: string;
  sport_id?: string;
  tournament_name?: string;
  tournament_id?: string;
};

export type TExoticSelections = {
  exotic_combos: TExoticCombos[];
};

export type TExoticCombos = {
  runner_number: number;
  runner_name: string;
};

export type TConfirmedBetDTO = IPagedResponse & {
  records: TConfirmedBet[];
};

export type TConfirmedBet = TDeepPartial<{
  bet_id: string;
  bet_legs: TMyBetsMultiBetLegs[];
  event_type: EEventType;
  event_id: string;
  event_title: string;
  event_subtitle: string;
  event_icon: string;
  event_start: string;
  stake: number;
  odds: number;
  is_won: boolean;
  is_settled: boolean;
  is_bonus: boolean;
  created_at: string;
  bet_description: string;
  status: string;
  payout: number;
  event_data: TBetEventData;
  exotic_selections: TExoticSelections[];
  total_deductions?: number;
  market_name: string;
  price_type?: TPriceType;
  promo_tokens: TPromoToken[];
  promotions?: TPromotion[];
  cashout_value?: number;
}>;

export type TPromotion = {
  id: string;
  type: 'money_back' | 'deposit_match' | 'bonus_bet' | 'first_deposit_match';
  bonus_amount: number;
};

export type TMyBetsMultiBetLegs = {
  bet_description: string;
  bet_leg_id: string;
  event_data: TMyBetsRecordsEventData;
  event_icon: string;
  event_id: string;
  event_type: string;
  event_start: string;
  is_won: boolean | null;
  odds: number;
  status: string;
  total_deductions?: number;
  leg_num?: number | null;
  legs: TMyBetsMultiBetLegs[];
};

export type TMyBetsRecordsEventData = {
  venue_name?: string;
  race_name?: string;
  race_number?: number;
  runner_name?: string;
  runner_number?: number;
  runner_barrier_number?: number;
  match_name?: string;
  proposition_name?: string;
  venue_id: string;
  race_meeting_date: string;
  competition_name?: string;
  competition_id?: string;
  sport_id?: string;
  tournament_name?: string;
  tournament_id?: string;
  market_name?: string;
  proposition_type?: string | null;
};

export type TBank = TDeepPartial<{
  id: string;
  bsb: string;
  account_nick_name?: string;
  account_number: string;
  bank_name: string;
  label?: string;
  account_name: string;
}>;

export enum EWithdrawalStatus {
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Processed = 'Processed',
  Error = 'Error',
}

export type TWithdrawal = TDeepPartial<{
  withdrawal_id: string;
  amount_requested: number;
  date_requested: string;
  withdrawal_status: EWithdrawalStatus;
  bank_nickname: string;
  withdrawal_cancel_disabled?: boolean;
}>;

export type TEventInfo = {
  team: string | null;
  metric: string | null;
  player: string | null;
  value1: number | null;
  value2: number | null;
  team_role: string | null;
  event_part: string | null;
  market_sub_group: string | null;
  participant: string | null;
  main_market_type: string | null;
  metric_categories?: string[];
  layout?: string;
};

// Below for banner mng & promotions
export type TDeviceMedia = {
  name: string;
  base64?: string;
  link?: string;
  layerLink?: string;
  publicUrl?: string;
};

export type TMedia = {
  desktop: TDeviceMedia;
  mobile: TDeviceMedia;
  app: TDeviceMedia;
};

export enum EStatus {
  Active = 'Active',
  Draft = 'Draft',
  Scheduled = 'Scheduled',
}

export enum EDisplay {
  Both = 'Both',
  LoggedIn = 'Logged in',
  LoggedOut = 'Logged Out',
}
