import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    color: 'white',
    fontSize: 'md',
    fontFamily: 'accent',
    fontWeight: 'bold',
    mb: '2',
    p: ['3', null, '0'],
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  navOverride: {
    mb: '0',
  },
  divider: {
    display: 'none',
  },
};
