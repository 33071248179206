import { CSSObject, BoxProps } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'gamma.700',
  mb: '1',
  fontSize: '2xs',
  fontWeight: 'semibold',
};
export const FooterMoreOpenText: CSSObject = {
  color: 'gamma.800',
  fontSize: 'xs',
};
export const BoxMoreText: CSSObject = {
  w: '8',
};
export const DividerMulti: CSSObject = {
  borderStyle: 'solid',
  borderColor: 'blackAlpha.300',
  mx: '2',
};
export const footerWrapperExpanded: BoxProps = {
  sx: {
    '& > div': {
      color: 'gamma.700',
    },
  },
};

export const toteMultiGridText: CSSObject = {
  fontSize: 'xs',
  w: 'full',
  color: 'gamma.800',
  mb: '2',
  '&.raceNumber': {
    borderBottom: '1px dashed',
    borderColor: '#00000040',
    boxShadow: '0px 1px 0px 0px #FFFFFF1A',
    fontWeight: 'bold',
  },
};
