import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DefaultText: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  h: '7',
  w: '12',
};

export const BoxInputContainer: CSSObject = {
  mb: '0',
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const DepositContainer: CSSObject = {
  mb: '3',
};

export const TrashIcon: CSSObject = {
  boxSize: '8',
  p: '2',
  bg: 'alpha.700',
  color: 'white',
  borderRadius: 'base',
};

export const FlexWrapper: CSSObject = {
  py: '3',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
};
