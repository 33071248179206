import { CSSObject, keyframes } from '@chakra-ui/react';
import bg from '../../../../../../assets/betgalaxy/images/homeFeaturedSport/galaxy.png';
import bgMobile from '../../../../../../assets/betgalaxy/images/homeFeaturedSport/galaxy-mobile.png';
import goal from '../../../../../../assets/betgalaxy/images/homeFeaturedSport/goal.png';
import kick from '../../../../../../assets/betgalaxy/images/homeFeaturedSport/kick.png';

const float = keyframes`
  0% { background-position: 100% 100%, 100% 100%; }
  50% { background-position: 100% 140%, 100% 100%; }
`;

const pulse = keyframes`
  0% { transform: scale(1.1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1.1); }
`;

export const FlexWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url('${bgMobile}')`, null, `url('${bg}')`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    position: ['fixed', 'absolute'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    animation: `${pulse} 20s ease infinite`,
    zIndex: '-1',
  },

  transformStyle: 'inherit',
  pos: 'relative',
  contain: 'paint',
};

export const FlexIllustrationWrapper: CSSObject = {
  bgPos: '100% 100%, 100% 100%',
  bgRepeat: 'no-repeat',
  bgImage: `url('${kick}'), url('${goal}')`,
  mb: '-5',
  animation: `${float} 3s ease infinite`,
};

export const puntButtonProps: CSSObject = {
  border: 'none',
  cursor: 'pointer',
  zIndex: 'tooltip',
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, #45F3FC, #38C8CF)',
};
