import React from 'react';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import toast from 'react-hot-toast';

import { TNotificationMessage } from '../types';
import { CloseButton } from './CloseButton';

interface INotificationItem extends TNotificationMessage {
  toastId: string;
  onClick(): void;
  titleColor: string;
  icon: string | null;
}

export function NotificationItem({
  notification,
  toastId,
  onClick,
  icon,
  titleColor,
}: INotificationItem) {
  return (
    <Box
      w="330px"
      pr="4"
      fontSize="sm"
      onClick={() => {
        toast.dismiss(toastId);
        onClick();
      }}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Flex>
        {icon && (
          <Image src={icon} w="36px" h="36px" color={titleColor} mr="2" />
        )}
        <Box>
          <Flex justifyContent="space-between">
            <Text fontWeight="bold" color={titleColor}>
              {notification.subject}
            </Text>
            <CloseButton
              onClose={(e: React.MouseEventHandler<HTMLButtonElement>) => {
                e.stopPropagation();
                toast.dismiss(toastId);
              }}
            />
          </Flex>
          <Text color="gray.600">{notification.body}</Text>
        </Box>
      </Flex>
    </Box>
  );
}
