import { ButtonProps, theme } from '@chakra-ui/react';

const primary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
    bg: 'beta.500',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.65)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.200, gamma.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  border: '1px solid',
  borderColor: 'transparent',
  _hover: {
    bgGradient: 'linear(to-b, gamma.500, gamma.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.45)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'gamma.300',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'beta.300',
  boxShadow: '0px 0px 10px 0px rgba(173, 241, 6, 0.7)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    borderColor: 'white',
    boxShadow: 'none',
    color: 'beta.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};

const quickAccess = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'bold',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.08)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  border: '1px solid',
  borderColor: 'transparent',
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
    borderColor: 'rgba(255, 255, 255, 0.65)',
  },
  ':active, &[data-active="true"], &[aria-expanded="true"]': {
    bg: 'white',
    boxShadow: 'none',
    color: 'blackAlpha.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    borderBottomRadius: 'none',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const odds = {
  ...primary,
  fontFamily: 'heading',
  fontWeight: 'normal',
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'whiteAlpha.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    pointerEvents: 'none',
  },
  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    unstyled: () => ({}),
    quickAccess: () => ({
      ...quickAccess,
    }),
    odds: () => ({
      ...odds,
    }),
  },
  defaultProps: { colorScheme: null },
};
