import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  mb: '2',
  overflowY: 'visible',
  contain: 'layout',
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
  borderBottom: '1px dashed var(--bc-black-alpha-200, rgba(0, 0, 0, 0.40))',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontFamily: 'roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
};

export const ButtonViewMore: CSSObject = {
  padding: '9px 8px',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  pb: '-5px',
  color: 'blackAlpha.700',
  borderBottomRadius: 'lg',
  textAlign: 'center',
  fontSize: '16px',
  fontFamily: 'Fredoka',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset',

  _hover: {
    bgGradient: 'none',
    bg: 'alpha.600',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  _active: {
    color: 'alpha.300',
    bg: 'alpha.800',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
  },
};
