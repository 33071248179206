import { CSSObject } from '@chakra-ui/react';
import errorBg from '@/assets/gigabet/images/error/bg.png';

export const LocationNotAllowedContainer: CSSObject = {
  flexDirection: 'column',
  justifyContent: 'center',
  bgImage: `url(${errorBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  '&&': {
    a: {
      color: 'alpha.400',
    },
  },
};
export const CenterWrapper: CSSObject = {
  alignItems: 'center',
  color: 'white',
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  maxW: '580px',
  mb: [null, null, '12'],
  minH: [null, null, '480px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '0'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',
  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },
};
export const ContentHeadingText: CSSObject = {
  color: 'alpha.400',
  fontFamily: 'accent',
  fontSize: ['20px', null, '32px'],
  lineHeight: 'none',
  mb: '2.5',
  mt: '4',
  textTransform: 'capitalize',
};
export const ContentBodyText: CSSObject = {
  fontSize: ['sm', null, 'md'],
  mb: '4',
  textAlign: 'center',
  color: 'white',
  fontWeight: 'semibold',
  '.chakra-link': {
    fontWeight: 'bold',
    color: 'gamma.300',
  },
};
export const TextBodyCopy: CSSObject = {
  lineHeight: '5',
  mt: '4',
  fontWeight: 'normal',
  fontSize: ['xs', null, 'sm'],
};
export const RegistrationContent: CSSObject = {
  fontWeight: 'normal',
  '.chakra-link': {
    textDecoration: 'none',
  },
};
