import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import coreTheme from '../../style/themes/hexbet';

type TTheme = {
  children: ReactNode;
};

/**
 * Provider that supplies the theme of the website
 */
export default function Theme({ children }: TTheme) {
  return <ThemeProvider theme={coreTheme}>{children}</ThemeProvider>;
}
