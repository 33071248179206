import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  pl: '0',
  pr: '2',
  py: ['1', null, '3'],
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  pb: '4',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'blackAlpha.500' : 'alpha.700',
  color: isActive ? 'white' : 'gamma.800',
  letterSpacing: 'wider',
  textShadow: isActive ? 'none' : '0px 1px 0px rgba(255, 255, 255, 0.3)',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: '3px', // no token
  boxShadow: isActive
    ? '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)'
    : '0px 1px 2px 0px #f9f17a inset,  0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #f9f17a inset', // no colour token

  '&:hover, &:focus': {
    bg: isActive ? 'delta.300' : 'alpha.500',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.500',
  color: 'white',
  letterSpacing: 'wider',
  textShadow: 'none',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: '3px', // no token
  boxShadow:
    'px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',

  '&:hover, &:focus': {
    bg: 'delta.300',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
  pr: '2',
};
