import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '../../../../lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
};

export const BackButton: CSSObject = {
  // ! Ensure account.styles are overridden
  '&&': {
    border: '0',
    color: 'white',
    h: '6',
    minW: '6',

    _before: {
      bottom: '0',
      left: '0',
      right: '0',
      top: '0',
    },
  },
};

export const TextDescription: CSSObject = {
  fontSize: 'xs',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'blackAlpha.300',
  borderStyle: 'dashed',
  opacity: '1',
};

export const BankCardContainer: CSSObject = {
  bg: undefined,
  mt: '-3',
};

export const BankRadioWrapper: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  px: '0',
  py: '3',
};

export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
  lineHeight: 'normal',
  mb: '1',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
  gap: '4',
  lineHeight: 'normal',
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
};

export const buttonAddAccountProps: CustomButtonProps = {
  borderRadius: 'base',
  fontSize: 'xs',
};

export const IncreaseAmountWrapper: CSSObject = {
  gap: ['1', null, '2'],
  mb: ['1', null, '2'],
};

export const ListContainer: CSSObject = {
  bg: undefined,
  boxShadow: 'none',
  ml: [null, null, '4'],
  mt: ['2', null, '0'],
  p: '0',
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

export const WithdrawalWrapper: CSSObject = {
  border: '0',
  boxShadow: '0 3px 8px rgba(0, 0, 0, .2)',
  color: 'gray.700',
  m: '0',
  p: '2.5',

  '& + &': {
    mt: '2',
  },
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '0',
  fontSize: 'xs',
  fontWeight: 'bold',
  py: '1.5',

  _first: {
    color: 'gray.500',
    fontSize: '2xs',
  },
};

export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  bg: status === EWithdrawalStatus.Pending && 'yellow.400',
  color: status === EWithdrawalStatus.Pending && 'yellow.800',
  fontSize: '2xs',
  px: '1',
  py: '0.5',
  textTransform: 'uppercase',
});

export const ButtonWithdrawalCardCancel: CSSObject = {
  // TODO: fix theme colours
  bg: '#426dd6',
  border: '2px',
  borderColor: 'alpha.700',
  borderRadius: 'lg',
  fontSize: undefined,
  overflow: 'hidden',
  p: undefined,
  pos: 'relative',
  zIndex: '1',

  // Pseudo-element for smooth gradient transition
  _before: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    borderRadius: 'calc(var(--bc-radii-lg) - 4px)',
    bottom: '2px',
    content: '""',
    left: '2px',
    pos: 'absolute',
    right: '2px',
    top: '2px',
    transition: 'inherit',
    zIndex: '-1',
  },

  _hover: {
    bg: 'alpha.700',
    borderColor: 'alpha.500',

    _before: {
      opacity: '0',
    },
  },

  _active: {
    bg: 'alpha.800',
    borderColor: 'alpha.500',

    _before: {
      opacity: '0',
    },
  },
};
