import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  '&': {
    a: {
      color: 'white',
      mx: '0',
      textDecoration: 'underline',
    },
    '.chakra-checkbox__control': {
      bg: 'gray.100',
      color: 'gray.700',
      border: '0',
      width: '1rem',
      height: '1rem',
      _disabled: {
        opacity: '0.4',
      },
      _invalid: {
        bg: 'red.100',
      },
      _hover: {
        bg: 'alpha.400',
      },
      _checked: {
        boxShadow: 'none',
        bg: 'alpha.700',
        color: 'white',
        _hover: {
          bg: 'alpha.400',
        },
        _focus: {
          boxShadow: 'none',
        },
        _invalid: {
          bg: 'alpha.400',
        },
      },
    },
    '.chakra-form__label': {
      color: 'white',
      fontWeight: 'semibold',
      mb: '1.5',
    },
    '.chakra-input': {
      bg: 'white',
      borderColor: 'delta.600',
      borderWidth: '1px',
      color: 'black',
      fontSize: 'sm',
      h: '10',
      _placeholder: {
        color: 'black',
      },
    },
    '.chakra-input__right-element': {
      color: 'black',
      h: '10',
    },
    '.chakra-stack': {
      mb: '0',
    },
  },
  'button[data-cy="promotionsNotificationsSubmit"]': {
    w: '148px',
    transition: 'all .3s linear',
    lineHeight: '1',
    fontFamily: 'SkranjiBold',
    textTransform: 'uppercase',
    bgGradient: 'linear(180deg, delta.400, delta.600)',
    color: 'gamma.700',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    border: 'var(--bc-colors-delta-700) 2px solid',
    textShadow: '0px 1px 2px rgba(255, 255, 255, 0.40)',
    height: ['9', null, '10'],
    borderRadius: 'md',
    boxSizing: 'border-box',
    fontSize: 'sm',
    maxH: '36px',
  
    _hover: {
      bgGradient: 'linear(to-b, delta.600, delta.800)',
      boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
    },
  
    _focus: {
      boxShadow: '0 2px 5px rgba(0, 0, 0, .15)',
    },
  },
};
