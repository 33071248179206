import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};

export const BoxTakeActionTable: CSSObject = {
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(74, 84, 84, 1) inset, 0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
  border: '0',
  p: '3',
};

export const TextTakeAction: CSSObject = {
  color: 'white',
};
