import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px dashed',
      borderTopColor: 'gray.200',
      p: '2',

      _first: { borderTop: 0 },
    }),

    ...(isSelected && {
      boxShadow: 'none',
    }),
  },
});

export const TextProp = ({ propLength }: TTextProp): CSSObject => ({
  '&&': {
    color: 'white',
    cursor: 'pointer',

    _first: {
      fontWeight: 'bold',
      bg: 'transparent',
      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      borderRadius: 'lg',
      border: '2px',
      '.btn-span': { zIndex: 9 },
      textTransform: 'capitalize',
      fontWeight: 'normal',
      fontSize: 'sm',
      fontFamily: 'accentUltra',
      transition: 'all 0.3s linear 0s',
      borderColor: 'epsilon.500',
      bgGradient: 'linear(to-b, beta.200, beta.400)',
      boxShadow:
        '0px 0px 0px 3px #006352 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(255, 255, 255, 0.30) inset',
      color: 'beta.500',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.47)',
      w: '100%',
      py: '1',

      _hover: {
        bgGradient: 'linear(to-b, beta.100, beta.100)',
        color: 'beta.400',
        border: '2px',
        borderColor: 'epsilon.500',
        boxShadow:
          '0px 0px 0px 3px #006352 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(255, 255, 255, 0.30) inset',

        _after: {
          opacity: 0,
        },
      },

      _disabled: {
        bg: 'blackAlpha.200',
        color: 'gray.600',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },
  },
});
