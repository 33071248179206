import { Checkbox as CheckboxChakra, CheckboxProps } from '@chakra-ui/react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import React from 'react';

type TCheckbox = {
  id: string;
  name: string;
  field?: [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];
} & CheckboxProps;

export default function Checkbox({ field, children, ...rest }: TCheckbox) {
  const [fieldData] = field ?? [];

  return (
    <CheckboxChakra
      inputProps={
        {
          'data-cy': `field-${rest.name}`,
        } as any
      }
      {...(!!fieldData && {
        ...fieldData,
        isChecked: fieldData.value,
      })}
      {...rest}
    >
      {children}
    </CheckboxChakra>
  );
}
