import { CSSObject } from '@chakra-ui/react';

export const BodyText = (): CSSObject => ({
  textTransform: 'initial',
});

export const RadioText: CSSObject = {
  textTransform: 'initial',
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',
  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.400',
    borderRight: 'none',
    h: '9',
  },
  '.chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.400',
    borderRadius: 'base',
    fontSize: 'xs',
  },
  '.chakra-input': {
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'gray.50',
    borderColor: 'gray.400',
    _hover: {
      bg: 'gray.100',
    },
  },
};
