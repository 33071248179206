import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { getThemeName } from '@/helpers/getThemeName';
import Heading from './components/Heading';
import {
  FlexVerbiageContainer,
  ContentBodyText,
  ContactText,
  SubHeader,
  TextLink,
  RegistrationContent,
} from './styles/LocationNotAllowed.styles';

const Content: FC = () => {
  const themeName = getThemeName();
  const { email } = getThemeConfig();

  return (
    <FlexVerbiageContainer>
      <Heading />

      <SubHeader>
        <FormattedMessage
          id="onboarding.locationnotallowed.subheader"
          values={{ themeName }}
        />
      </SubHeader>

      <ContentBodyText>
        <FormattedMessage
          id="onboarding.locationnotallowed.bodycopy"
          values={{ themeName }}
        />
        <ContactText
          href={`mailto:${email}?subject=${(
            <FormattedMessage id="onboarding.locationnotallowed.locationenquiry" />
          )}${themeName}`}
        >
          {email}
        </ContactText>
        .
        <RegistrationContent>
          <FormattedMessage
            id="onboarding.locationnotallowed.registration"
            values={{
              themeName,
              br: <br />,
              commissionLink: (text: string) => (
                <TextLink href="https://www.vgccc.vic.gov.au/" target="_blank">
                  {text}
                </TextLink>
              ),
              bookmakersLink: (text: string) => (
                <TextLink href="https://vicbookmakers.com.au/" target="_blank">
                  {text}
                </TextLink>
              ),
              racingLink: (text: string) => (
                <TextLink
                  href="https://www.racingvictoria.com.au/"
                  target="_blank"
                >
                  {text}
                </TextLink>
              ),
            }}
          />
        </RegistrationContent>
      </ContentBodyText>
    </FlexVerbiageContainer>
  );
};

export default Content;
