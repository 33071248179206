import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  bg: 'beta.800',
  borderBottomRadius: 'md',
  px: [null, '4'],
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  borderTopColor: 'blackAlpha.600',
  borderBottom: 0,
  borderBottomColor: ['transparent'],
  borderTop: '1px',
  color: 'white',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  h: '36px',

  _hover: { bgGradient: 'linear(to-b, beta.700, beta.800)' },
};

export const IconSport: CSSObject = {
  color: 'alpha.500',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: '1px',
  borderColor: 'white',
  borderRadius: 'full',
  p: '1',
  color: 'white',
  transition: '.3s ease-in-out',

  _hover: {
    color: 'gray.200',
  },
};
