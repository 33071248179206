import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTakeBreakProps: CustomButtonProps = {
  height: '38px',
  variant: 'solid',
  lineHeight: 'normal',
};

export const ListItemExclusionItem: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const HeadingInformation: CSSObject = {
  fontWeight: 'medium',
  mb: '0',
  fontSize: 'sm',
  color: 'white',
};

export const TextInformation: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  '&:first-of-type': {
    fontWeight: 'medium',
    pt: 2,
  },
};

export const BoxStyledLink: CSSObject = {
  color: 'blue.500',
  textDecoration: 'underline',
};

export const TextM: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
  mb: '0',
  p: '0',
  pt: '2',
};

export const BoxTakeABreakWrapper: CSSObject = {
  pb: 0,
  border: 'none',
};

export const BoxTakeABreak: CSSObject = {
  border: 'none',
  mb: '4',
  p: '0',
};

export const TakeABreakContainer: CSSObject = {
  color: 'white',
  m: '0',
  pb: '3',
  border: 'none',
};

export const TextS: CSSObject = {
  color: 'white',
  pt: '1',
  m: '0',
  fontSize: '12px',
  pb: '1',
};

export const InputContainer: CSSObject = {
  mb: 0,
  '& select': {
    bg: 'beta.100',
    height: '10',
  },
};

export const TextDateLabel: CSSObject = {
  fontSize: 'sm',
};

export const TextDate: CSSObject = {
  fontSize: 'md',
};

export const TextConfirmBreak: CSSObject = {
  mt: '4',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const buttonConfirmBreakProps: CustomButtonProps = {
  height: '38px',
  variant: 'solid',
};
