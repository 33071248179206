import React from 'react';
import {
  Drawer as DrawerChakra,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useStyleConfig,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { TSideNavTheme } from '../../../theme/base/customComponents/sideNav';
import { useSideNav } from '../hooks/SideNav.hooks';
import Navigation from '../Navigation/Navigation';
import { setIsOpen } from '../../../redux/sideNav.slice';
import DrawerHeader from '../components/DrawerHeader';
import { createTemplate } from '@/helpers/createTemplate';
import template from '../Navigation/templates/template';

export default function Drawer() {
  useSideNav();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.sidenav);
  const styles = useStyleConfig('SideNav') as TSideNavTheme;

  return (
    <DrawerChakra
      isOpen={isOpen}
      onClose={() => dispatch(setIsOpen(false))}
      placement="left"
    >
      <DrawerOverlay />

      <DrawerContent
        sx={{ ...styles.background, ...styles.drawer }}
        label="drawer-DrawerContent"
      >
        <DrawerHeader />
        <DrawerBody sx={styles.drawerBody}>
          <Navigation />
          {createTemplate(template)}
        </DrawerBody>
      </DrawerContent>
    </DrawerChakra>
  );
}
