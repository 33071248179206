import { CSSObject } from '@chakra-ui/react';

export const ButtonDownload: CSSObject = {
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  color: 'alpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  fontFamily: 'accent',
  _disabled: {
    bg: 'alpha.900',
    boxShadow: 'unset',
    color: 'beta.400',
    fontSize: 'xs',
    opacity: '1',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    '&&': {
      h: '6',
    },
    _hover: {
      bg: 'gray.300',
      color: 'gray.500',
    },
  },
  _hover: {
    bg: 'beta.600',
    color: 'alpha.800',
  },
};

export const TextMonth: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const FlexInner: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};
