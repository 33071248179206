import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'white',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '1.5',
  mb: isKeypadVisible && 'unset',
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'alpha.100',
  color: 'alpha.700',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  h: '6',
  bg: 'alpha.500',
  borderRadius: 'full',
  fontWeight: 'bold',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  color: 'white',
  minW: '16',
  border: '3px solid',
  borderColor: 'white',
  px: '3',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      bg: 'alpha.700',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '&&': {
      fontSize: '2xs',
    },
  },
};
