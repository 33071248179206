import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'beta.800',
    fontWeight: 'semibold',
    mt: '0',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    border: '2px solid',
    borderColor: 'beta.400',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%)',
  },
  '.chakra-input__left-addon': {
    bg: ' transparent',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
    maxW: '42px',
  },
  '.chakra-input': {
    borderRadius: 'base',
    bg: 'beta.900',
    border: 'none',
    boxShadow: 'none',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'beta.700',
      border: 'none',
    },
    _focus: {
      bg: 'beta.900',
      boxShadow: 'none',
      border: 'none',
    },
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  borderTopWidth: '1px',
  borderTopColor: 'blackAlpha.300',
  borderTopStyle: 'solid',
  boxShadow: 'rgba(255, 255, 255, 0.15) 0px 1px 0px 0px inset',
  bg: 'rgb(180, 146, 78)',
  '&&': {
    py: '3',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mx: [null, null, '0'],
  zIndex: 'modal',
  borderBottomRadius: 'md',
  bgColor: 'beta.300',
};

export const InfoHeader: CSSObject = {
  px: '2',
  py: '2',
  bg: 'rgb(180, 146, 78)',
  borderTop: '4px solid',
  borderColor: 'beta.600',
  alignItems: 'center',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  fontFamily: 'heading',
  lineHeight: 'normal',
  '&&': {
    fontWeight: 'normal',
  },
  color: 'beta.800',
};

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'odds',
  fontFamily: 'accent',
  _hover: {
    color: 'white',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-delta-400) 0%, var(--bc-colors-delta-500) 100%)',
  },
  textTransform: 'uppercase',
};

export const TextAddToBetslipButton: CSSObject = {
  fontWeight: 'normal',
  color: 'beta.500',
};

export const toteMultiListSelections: CSSObject = {
  color: 'beta.800',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'beta.300',
  borderColor: 'rgba(0, 0, 0, 0.10)',
  pos: 'relative',
  _notFirst: {
    borderTopStyle: 'dashed',
    borderTopWidth: '1px',
  },
  _before: {
    border: 'inherit',
    borderColor: 'whiteAlpha.300',
    content: '""',
    h: '1px',
    left: '0',
    pos: 'absolute',
    right: '0',
    top: '0',
  },
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        color: 'beta.800',
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'beta.800',
};