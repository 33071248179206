import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
  },
  iconButtonScrollRight: {
    fontSize: 'sm',
    p: '1',
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
  },
  iconButtonScrollLeft: {
    fontSize: 'sm',
    p: '1',
  },
  flexWrapper: {
    bg: 'blackAlpha.600',
    borderRadius: 'md',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  buttonGroupSports: {
    borderRadius: 'lg',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    display: 'inline-flex',
    flex: '1',
    overflowX: 'auto',
    p: '1',
    spacing: '1',
    whiteSpace: 'nowrap',
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    borderRightRadius: 'md',
    p: '1',
    pos: 'relative',
    spacing: '1',
    bg: 'gamma.400',
    _before: {
      bgGradient: 'linear-gradient(to-r, rgba(25, 28, 33, 0.00), gamma.400)',
      bottom: '1',
      content: '""',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '100%',
      top: '1',
      w: '12',
      zIndex: '1',
    },
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'epsilon.500',
    pos: 'absolute',
    bottom: 'px',
    transition: 'all 0.2s ease',
  },
};
