import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  headerSportText: {
    fontSize: 'lg',
    textShadow: '0px 2px 4px var(--bc-colors-blackAlpha-400)',
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: 'widest',
  },
  boxWrapper: {
    px: 0,
    mt: '-2',
  },
  accordionButton: {
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      fontSize: 'sm',
    },
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'beta.800',
    fontSize: 'xs',
    fontWeight: 'normal',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      fontFamily: 'accent',
      fontsize: 'lg',
      my: '2',
      mb: '2',
      fontWeight: 'normal',
      color: 'beta.800',
      px: '2',
    },
  },
  topLeaguesAccordionItem: {
    mt: '3',
    mx: ['1.5', null, '0'],
    borderBottomRadius: 'base',
    p: '1px',
    bg: 'beta.100',
    borderRadius: 'md',
    boxShadow:
      '0px 3px 2px 1px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px var(--bc-colors-beta-100) inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    boxShadow:
      '0px 3px 2px 1px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px var(--bc-colors-beta-100) inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
    borderRadius: 'lg',
    bg: `beta.100`,
    mb: '2',
    mx: ['1.5', null, '0'],
    sx: {
      _last: {
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'beta.800',
    sx: {
      display: 'flex',
      p: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: 'unset',
      svg: {
        color: 'beta.800',
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    color: 'beta.800',
    fontFamily: 'heading',
    textTransform: 'capitalize',
    sx: {
      img: {
        marginRight: '2',
        marginLeft: '1',
      },
    },
  },
  marketGroupsByLetterCountryFlag: {
    border: '1px solid',
    borderColor: 'blackAlpha.500',
    boxShadow:
      '0px 1.5px 6px 0px var(--bc-colors-blackAlpha-400), 0px 1.5px 0px 0px var(--bc-colors-whiteAlpha-400) inset',
    background: 'transparent',
    height: '20px',
    width: 'auto',
    borderRadius: '2px',
  },
  marketGroupsByLetterAccordionPanel: {
    px: '2',
    pb: '2',
    mt: '1',
    pt: '0',
    borderBottomRadius: 'base',
    borderRadius: 'md',
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '2px',
    borderColor: 'beta.700',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'beta.800',
    fontSize: 'sm',
    fontWeight: 'normal',
    sx: { _hover: { textDecoration: 'none' } },
  },
  accordionIcon: { color: 'beta.800' },
  accordionPanel: {
    px: '2',
    py: '0',
    borderColor: 'beta.700',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '2px',
    borderColor: 'beta.700',
    px: '2',
  },
  topLeaguesText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    fontFamily: 'subHeading',
    color: 'beta.800',
    fontSize: 'sm',
    px: '0',
  },
  navWrapper: {
    pb: '0',
  },
};
