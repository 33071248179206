import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  color: 'brand.700',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
};

export const BoxHeader: CSSObject = {
  fontWeight: 'normal',
};
