import { Box } from '@chakra-ui/react';
import React from 'react';
import desktop from '@/assets/sugarcastle/images/banner/bannerDesktop.png';
import mobile from '@/assets/sugarcastle/images/banner/bannerMb.png';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['3', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <Box
            key="banner-image"
            sx={{
              w: '100%',
              h: ['32vh', '45vh', '215px'],
              bgImage: [mobile, null, desktop],
              bgSize: ['cover', null, '100% 100%'],
              bgRepeat: 'no-repeat',
              borderBottom: ['2px solid', null, 'unset'],
              borderColor: ['#FFE6B6', null, 'transparent'],
            }}
          />,
        ]}
      />
    </Box>
  );
}
