import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderColor: 'blackAlpha.400',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
    pb: '2',
  },
  btnGroupWrapper: {
    bgGradient: 'linear(to-b, alpha.100, alpha.500)',
    borderRadius: '5px',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
    minW: '90px',
    gap: 'initial',
  },
  btnGroupText: {
    color: 'alpha.400',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    fontSize: 'md',
    textShadow: '0 0 black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'beta.700',
    h: '100%',
    borderTop: '1px solid #626d77',
    borderBottom: '1px solid #626d77',
  },
  btnGroupIcon: {
    color: 'alpha.900',
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.3))',
      h: '5',
      w: '7',
    },
  },
  name: {
    color: 'white',
  },
};
export default styles;
