import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAppSelector } from '@/hooks/useRedux';
import { EPunterStatus } from '@/lib/punter/types';
import { useAuth } from '@/hooks/useAuth';
import { getStrings } from '@/helpers/utils';
import { usePunterData, usePunterAccountOverview } from '@/store/AuthStore';

/**
 * KYC route was created to be applicable to all pages and redirect to user to where they're required to be: either to verify or to complete onboarding.
 */

export default function KYCRoute() {
  const [
    {
      Onboarding: { SignUpErrorMessages },
    },
  ] = getStrings();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, isAuthenticated, logout } = useAuth();

  const punterProfile = usePunterData();
  const punterAccountOverview = usePunterAccountOverview();
  // TODO redux store is still in use so we have two sources of truth.
  // Deprecate redux store in favour of new store
  const accountOverview =
    useAppSelector((state) => state.punter).accountOverview ||
    punterAccountOverview;

  const [hasRouted, setHasRouted] = useState(false);

  useEffect(() => {
    setHasRouted(false);
  }, [location.pathname]);

  useEffect(() => {
    if (hasRouted) {
      return;
    }

    if (isLoading || !isAuthenticated || !accountOverview || !punterProfile) {
      return;
    }

    if (
      isAuthenticated &&
      ['/signed-out', '/exclusion-confirmed', '/forgot-password'].includes(
        location.pathname
      )
    ) {
      navigate('/');
      return;
    }

    if (
      !accountOverview?.identity_verified &&
      accountOverview?.status === EPunterStatus.Suspended_KYC
    ) {
      setHasRouted(true);
      navigate('/verification');
      return;
    }

    if (
      !punterProfile?.is_onboarded &&
      accountOverview?.status === EPunterStatus.Normal
    ) {
      setHasRouted(true);
      navigate('/deposit-limit');
      return;
    }
    if (['/signup', '/login'].includes(location.pathname)) {
      navigate('/');
      return;
    }

    if (accountOverview?.status !== EPunterStatus.Normal) {
      setHasRouted(true);
      toast.error(SignUpErrorMessages.SuspendedOrExcludedOrClosed, {
        id: 'error',
      });
      logout().catch(() => undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRouted, isLoading, isAuthenticated, punterProfile, accountOverview]);

  return <Outlet />;
}
