import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  p: 1,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.400',
};
export const CardContainer: CSSObject = {
  bgGradient: 'linear(to-b, gamma.300, gamma.400)',
  border: 'none',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  p: 2,
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px solid',
  borderColor: 'whiteAlpha.300',
  '.multiDivider': {
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    width: 'unset',
  },
};
export const ExoticSelectionText: CSSObject = {
  color: 'gamma.800',
  fontWeight: 'medium',
};
export const ContentRow: CSSObject = {
  alignItems: 'start',
  '> svg': {
    color: 'gamma.800',
    fill: 'gamma.800',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
};

export const dividerExoticProps: DividerProps = {
  display: 'none',
};

export const dividerExoticLastProps: DividerProps = {
  borderTop: '1px dashed',
  borderTopColor: 'blackAlpha.500',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.300',
};
