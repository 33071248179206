import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { styleImports } from './Results.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const ExoticResultedWrapper = chakra(Box, {
  label: 'results-ExoticResultedWrapper',
  baseStyle: ({ theme }) => ({
    border: ['none', null, '1px solid'],
    borderColor: ['none', null, 'gray.300'],
    borderRadius: ['base', null, 'md'],
    ...(theme.themeName === EThemes.Betgalaxy && {
      border: '1px solid',
      bg: 'gray.100',
      borderColor: 'gray.300',
      flex: '1 1 auto',
      display: 'grid',
      px: '2',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      display: 'flex',
      flexDir: 'column',
      px: '2',
      border: '1px',
      borderColor: 'alpha.200',
      bg: 'alpha.50',
      borderRadius: 'base',
      mb: '2',
      flex: '1',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ExoticResultedWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TypeItem = chakra(Text, {
  baseStyle: ({ theme }) => ({
    label: 'results-TypeItem',
    color: 'white',
    fontWeight: 'medium',
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'gray.600',
      fontWeight: 'bold',
      fontSize: 'xs',
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'gray.600',
      fontWeight: 'bold',
      fontSize: 'xs',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TypeItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ResultRowWrapper = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    label: 'results-ResultsRowWrapper',
    border: ['none', null, '1px solid'],
    borderColor: ['none', null, 'gray.300'],
    ...(theme.themeName === EThemes.Betgalaxy && {
      boxShadow:
        '0px 2px 10px rgba(20, 28, 35, 0.25), inset 0px 1px 0px #FFFFFF',
      borderRadius: ['base', null, 'md'],
      mb: '1',
      px: '1.5',
      py: '1',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      bg: 'linear-gradient(180deg, #F8F8F8 0%, #FFFFFF 100%)',
      '&:last-child': {
        mb: '2',
      },
    }),
    ...(theme.themeName === EThemes.Wellbet && {
      border: 'none',
      borderRadius: ['base', null, 'md'],
      mb: '1',
      p: '1.5',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      bg: 'blackAlpha.200',
      '&:last-child': {
        mb: '2',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ResultRowWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ResultRowExoticWrapper = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    label: 'results-ResultsRowExoticWrapper',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 'md',
    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'sm',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ResultRowExoticWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ResultRowExoticRow = chakra(Flex, {
  baseStyle: () => ({
    label: 'results-ResultsRowExoticRow',
    fontWeight: 'bold',
    color: 'gray.800',
    ...(() => {
      try {
        return styleImports[themeName]?.ResultRowExoticRow;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
