import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  bg: 'linear-gradient(180deg, #263BF4 0%, #2534BF 100%)',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 2px 2px rgba(74, 93, 255, 0.60) inset, 0px 2px 9px 0px rgba(0, 0, 0, 0.35), 0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset',
  color: 'gamma.900',
  p: '2',
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '0',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
  gap: '0',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'white',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  mb: '7',
  borderRadius: 'md',
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.25) inset',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};

export const InvalidityBox: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};
