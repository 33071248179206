import {
  DividerProps,
  FlexProps,
  IconButtonProps,
  LinkProps,
  ModalBodyProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalProps,
  StackProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './MatchesModal.styles.imports';
import { TIconProps } from '@/components/common/IconSvg';
import { TCountdown } from '@/components/Countdown/Countdown';
import { TDuotoneSgmLogo } from '@/components/DuotoneSgmLogo/DuotoneSgmLogo';

const themeName = getThemeName();

export type MatchesModalSchema = Partial<{
  modalWrapper: Partial<ModalProps>;
  modalContent: ModalContentProps;
  modalHeader: ModalHeaderProps;
  closeButton: Partial<IconButtonProps>;
  divider: DividerProps;
  modalBody: ModalBodyProps;
  stackWrapper: StackProps;
  linkWrapper: LinkProps;
  flexSportName: FlexProps;
  iconSportName: TIconProps;
  hStack: StackProps;
  countdown: Partial<TCountdown>;
  duotoneSgmLogoOverride: Partial<TDuotoneSgmLogo>;
  hStackIcon: TIconProps;
}>;

export const matchesModalStyles: MatchesModalSchema = {
  hStackIcon: {
    'data-testid': 'matchesModal-hStackIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.hStackIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  duotoneSgmLogoOverride: {
    'data-testid': 'matchesModal-duotoneSgmLogoOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles
          ?.duotoneSgmLogoOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  countdown: {
    'data-testid': 'matchesModal-countdown',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.countdown;
      } catch (err) {
        return {};
      }
    })(),
  },
  hStack: {
    'data-testid': 'matchesModal-hStack',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.hStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconSportName: {
    'data-testid': 'matchesModal-iconSportName',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.iconSportName;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexSportName: {
    'data-testid': 'matchesModal-flexSportName',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.flexSportName;
      } catch (err) {
        return {};
      }
    })(),
  },
  linkWrapper: {
    'data-testid': 'matchesModal-linkWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.linkWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  stackWrapper: {
    'data-testid': 'matchesModal-stackWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.stackWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  modalBody: {
    'data-testid': 'matchesModal-modalBody',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.modalBody;
      } catch (err) {
        return {};
      }
    })(),
  },
  divider: {
    'data-testid': 'matchesModal-divider',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.divider;
      } catch (err) {
        return {};
      }
    })(),
  },
  closeButton: {
    'data-testid': 'matchesModal-closeButton',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.closeButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  modalHeader: {
    'data-testid': 'matchesModal-modalHeader',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.modalHeader;
      } catch (err) {
        return {};
      }
    })(),
  },
  modalContent: {
    'data-testid': 'matchesModal-modalContent',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.modalContent;
      } catch (err) {
        return {};
      }
    })(),
  },
  modalWrapper: {
    'data-testid': 'matchesModal-modalWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesModalStyles?.modalWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
