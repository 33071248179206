import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
};

export const LinkItem: CSSObject = {
  color: 'white',
  bg: 'alpha.500',
  border: 'none',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  h: '36px',
  mb: ['1', '2'],
  _last: { mb: '0' },

  _hover: { bg: 'alpha.600' },
};

export const IconSport: CSSObject = {
  color: 'beta.500',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'beta.500',
};

export const TextName: CSSObject = {
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
};
