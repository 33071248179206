import { debounce } from 'lodash';

type TNewRelicLogProps = {
  punter_id?: string;
  punter_email?: string;
  status_code?: number | string;
  error?: any | null;
  error_msg?: string;
  requestURL?: string;
  event_type?: string;
  payload?: any;
  additional_id?: string;
};

const { NODE_ENV } = window.BETCLOUD_ENV;

export const logPageAction = (action: string, props: TNewRelicLogProps) => {
  if (window.newrelic) {
    window.newrelic.addPageAction(action, {
      success: !props.error,
      environment: NODE_ENV,
      ...props,
    });
  }
};

export const newRelicLog = debounce(
  (action: string, punterId: string | undefined, props: TNewRelicLogProps) => {
    if (window.newrelic) {
      logPageAction(action, {
        punter_id: punterId,
        ...props,
      });
    }
  },
  3000
);
