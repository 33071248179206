import {
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './IncreaseAmountButton.styles.imports';

const themeName = getThemeName();

export const buttonIncreaseProps: CustomButtonProps = {
  'data-testid': 'increaseAmountButton-buttonIncreaseProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonIncreaseProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const ButtonIncrease = chakra(Button, {
  baseStyle: () => ({
    label: 'increaseAmountButton-ButtonIncrease',

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonIncrease;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const IncreaseAmountWrapper: ChakraComponent<'div', FlexProps> = chakra(
  Flex,
  {
    label: 'IncreaseAmountWrapper',
    baseStyle: {
      gap: '2',
      mb: '1',
      ...(() => {
        try {
          return styleImports[themeName]?.IncreaseAmountWrapper;
        } catch (err) {
          return {};
        }
      })(),
    },
  }
);

export const IncreaseAmountButtonWrapper = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.increaseAmountButtonWrapper}
`;

export const IncreaseAmountButton = styled.button`
  cursor: pointer;
  ${({ theme }) => theme.pages.withdrawFunds.increaseAmountButton}
`;
