import { css } from 'styled-components';

// TODO: Remove
export const container = css`
  align-content: center;
  align-items: center;
  background: ${({ theme }) => theme.basePalette.purple};
  display: flex;
  flex-wrap: nowrap;
  word-wrap: nowrap;
  white-space: nowrap;
  padding: 0;

  ${({ theme }) => theme.device.tablet} {
    padding: 0 var(--bc-sizes-5);
  }
`;

export const wrapper = css`
  max-width: 1200px;
`;

export const link = css`
  color: ${({ theme }) => theme.basePalette.offWhite};
  font-size: var(--bc-fontSizes-xs);
  padding: var(--bc-sizes-2) var(--bc-sizes-5);
  text-decoration: none;
  transition: all, 0.1s linear;
  line-height: var(--bc-lineHeights-tall);
  letter-spacing: 0.2px;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.basePalette.brightBlue};
    color: ${({ theme }) => theme.basePalette.darkGrey};
  }
`;

export const linkActive = css`
  background: ${({ theme }) => theme.basePalette.brightBlue};
  color: ${({ theme }) => theme.basePalette.darkGrey};
`;
