import Black from '@/assets/sugarcastle/fonts/Roboto-Black.ttf';
import BlackItalic from '@/assets/sugarcastle/fonts/Roboto-BlackItalic.ttf';
import Bold from '@/assets/sugarcastle/fonts/Roboto-Bold.ttf';
import BoldItalic from '@/assets/sugarcastle/fonts/Roboto-BoldItalic.ttf';
import ExtraLight from '@/assets/sugarcastle/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '@/assets/sugarcastle/fonts/Roboto-ThinItalic.ttf';
import Light from '@/assets/sugarcastle/fonts/Roboto-Light.ttf';
import LightItalic from '@/assets/sugarcastle/fonts/Roboto-LightItalic.ttf';
import Regular from '@/assets/sugarcastle/fonts/Roboto-Regular.ttf';
import Italic from '@/assets/sugarcastle/fonts/Roboto-Italic.ttf';
import SemiBold from '@/assets/sugarcastle/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '@/assets/sugarcastle/fonts/Roboto-MediumItalic.ttf';

import Imperator from '@/assets/sugarcastle/fonts/Imperator.ttf';
import ImperatorBold from '@/assets/sugarcastle/fonts/Imperator-Bold.ttf';
import Fraunces from '@/assets/sugarcastle/fonts/Fraunces.ttf';
import FrauncesItalic from '@/assets/sugarcastle/fonts/Fraunces-Italic.ttf';

export const fontFace = `
  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${Light}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${LightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Regular}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${Italic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${Bold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${BoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${Black}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${BlackItalic}') format('opentype');
  }

  @font-face {
    font-family: 'Imperator';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Imperator}') format('opentype');
  }

  @font-face {
    font-family: 'Imperator';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url('${ImperatorBold}') format('opentype');
  }

  @font-face {
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Fraunces}') format('opentype');
  }

  @font-face {
    font-family: 'Fraunces';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url('${Fraunces}') format('opentype');
  }

  @font-face {
    font-family: 'Fraunces';
    font-style: black;
    font-weight: 900;
    font-display: swap;
    src: url('${Fraunces}') format('opentype');
  }

  @font-face {
    font-family: 'FrauncesItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${FrauncesItalic}') format('opentype');
  }

  @font-face {
    font-family: 'FrauncesItalic';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url('${FrauncesItalic}') format('opentype');
  }

  @font-face {
    font-family: 'FrauncesItalic';
    font-style: black;
    font-weight: 900;
    font-display: swap;
    src: url('${FrauncesItalic}') format('opentype');
  }
`;
