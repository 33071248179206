import { BoxProps, FlexProps, StackProps, TextProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Selections.styles.imports';

const themeName = getThemeName();

export type SelectionStylesSchema = Partial<{
  selectionWrapper: BoxProps;
  errorWrapper: FlexProps;
  footerWrapper: FlexProps;

  // not yet imported
  runnerContainer: BoxProps;
  runnerText: TextProps;
  clearSelectionText: TextProps;

  expandContainer: BoxProps;

  priceBox: BoxProps;
  priceWrap: FlexProps;
  buttonsWrap: StackProps;
}>;

export const selectionStyles: SelectionStylesSchema = {
  buttonsWrap: {
    'data-testid': 'srmSelection-buttonsWrap',
    gap: ['0', '2'],

    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.buttonsWrap;
      } catch (err) {
        return {};
      }
    })(),
  },
  selectionWrapper: {
    'data-testid': 'srmSelection-selectionWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.selectionWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  errorWrapper: {
    'data-testid': 'srmSelection-errorWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.errorWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerWrapper: {
    'data-testid': 'srmSelection-footerWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.footerWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  runnerContainer: {
    'data-testid': 'srmSelection-runnerContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.footerWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  runnerText: {
    'data-testid': 'srmSelection-runnerText',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.runnerText;
      } catch (err) {
        return {};
      }
    })(),
  },
  clearSelectionText: {
    'data-testid': 'srmSelection-clearSelectionText',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.clearSelectionText;
      } catch (err) {
        return {};
      }
    })(),
  },
  expandContainer: {
    'data-testid': 'srmSelection-expandContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.expandContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceBox: {
    'data-testid': 'srmSelection-priceBox',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.priceBox;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceWrap: {
    'data-testid': 'srmSelection-priceWrap',
    gap: ['2', '12', '12'],
    flexDirection: 'row',
    ...(() => {
      try {
        return styleImports[themeName]?.selectionStyles?.priceWrap;
      } catch (err) {
        return {};
      }
    })(),
  },
};
