/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center, Image } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import empty from '@/assets/sugarcastle/images/betslip/empty-betslip.png';

export const template: TTemplate = {
  wrapper: <Center />,
  empty: <Image src={empty} />,
};

export default template;
