import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  borderRadius: ['md', 'lg'],
  bg: 'beta.800',
  mb: '1',
  mr: '0',
  ml: '0',
  px: ['2', '3'],
  pt: '0',
  mt: '3',
  gap: '1',
  height: 'auto',
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-300), 0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  border: '0',
};
export const TabWrapper: CSSObject = {
  variant: 'tab',
};
export const TabOption = (): CSSObject => ({
  variant: 'tab',
  color: 'white',
  borderBottom: '3px solid',
  textShadow: '0px 2px 4px 0px var(--bc-colors-whiteAlpha-400)',
  '&:hover, &:focus': {
    borderBottom: '3px solid',
  },
});
