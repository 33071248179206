import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { CustomButtonProps } from '@/components/Button/Button';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  spanPropositionType: {
    color: 'white',
  },
  spanRaceInfo: {
    color: 'white',
  },
  iconMysteryBetFooter: {
    color: 'alpha.200',
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    fontFamily: 'roboto',
  },
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'alpha.900',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    fontWeight: 'bold',
    fontSize: 'xs',
    w: '-webkit-fill-available',
    h: 'auto',
    py: 1.5,
  },
  buttonBonusBetsProps: {
    variant: 'unstyled',
    bg: 'none',
    color: 'beta.500',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
    bgGradient: 'linear(to-b, alpha.800, alpha.900)',

    spanProps: {
      fontFamily: 'roboto',
      textShadow: 'none',
      fontWeight: 'medium',
      fontSize: '2xs',
      px: '1',
      py: '1.5',
    },
    display: 'flex',
    size: 'xs',
    px: '3',
    alignSelf: 'flex-start',
    textTransform: 'capitalize',

    sx: {
      '.chakra-button__icon': {
        mr: '0',
        ml: '1',
      },

      '&&[data-isbonusbet="true"]': {
        bg: 'alpha.800',
        boxShadow: 'none',
        color: 'alpha.500',
        borderRadius: 'md',
        border: '1px',
        borderColor: 'alpha.500',
        spanProps: {
          fontFamily: 'roboto',
          textShadow: 'none',
          fontWeight: 'medium',
          fontSize: '2xs',
          px: '2',
          py: '5px',
        },

        _disabled: {
          w: '100%',
          borderTopRadius: 'none',
          border: 'none',
          bg: 'blackAlpha.500',
          color: 'alpha.200',
          opacity: '1',
          textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
          mt: '0',
          '& > span': {
            _first: {
              mr: '1',
            },
          },
        },

        '.chakra-button__icon': {
          mr: '0',
          ml: '1',
        },
      },
    },
  },
  buttonStakeProps: { color: 'blackAlpha.700', minW: '46px' },
  buttonBetPropositionClosedProps: {
    bg: 'beta.500',
    boxShadow: 'md',
    color: 'white',
    borderRadius: 'base',
    fontSize: 'xs',
    px: '3.5',
    h: '8',
    display: 'flex',
    gap: '1',
    _hover: {
      bg: 'beta.700',
      color: 'white',
      boxShadow: 'md',
    },
  },
  betCardSVG: {
    fill: 'alpha.500',
  },
};

export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'blackAlpha.700',
  bg: 'red.300',
  borderRadius: 'md',
  w: '98%',
  mx: 'auto',
  pos: 'relative',
  top: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
  mb: '1',
};

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'blackAlpha.700',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const BetCardContainer = (): CSSObject => ({
  borderTopRadius: 'base',
  color: 'white',
  my: '1',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset',
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'beta.500',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  color: 'beta.500',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'gamma.700',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean): CSSObject => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const IconPropositionClosed = (): React.ReactElement => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const DefaultStakeFlexbox: FlexProps = {
  justifyContent: 'space-around',
};

export const EventRule: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontSize: 'xs',
};

export const buttonBetPropositionClosedProps: CustomButtonProps = {
  variant: 'unstyled',
  px: '7',
  zIndex: '1',
  bg: 'red.500',
  boxShadow: 'md',
  color: 'black',
  borderRadius: 'md',
  fontSize: 'xs',
  display: 'flex',
  gap: '1',
  spanProps: {
    color: 'white',
    fontFamily: 'roboto',
    fontSize: 'xs',
    fontWeight: 'bold',
    px: '3.5',
    py: '2',
    textTransform: 'uppercase',
  },
  _hover: {
    bg: 'red.700',
    color: 'white',
    boxShadow: 'md',
  },
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgba(0,0,0,0.6)',
  borderRadius: 'base',
  px: '1',
  h: '100%',
};

export const BetCardWrapper: CSSObject = {
  '&&': {
    mt: '0',
  },
};

export const FlexBonusBetsWrapper = (): CSSObject => ({
  '&&': {
    mt: '0',
  },
});
