import * as betprofessor from './MyBets.styles.betprofessor';
import * as puntgenie from './MyBets.styles.puntgenie';
import * as sterlingparker from './MyBets.styles.sterlingparker';
import * as vikingbet from './MyBets.styles.vikingbet';
import * as volcanobet from './MyBets.styles.volcanobet';
import * as wellbet from './MyBets.styles.wellbet';
import * as juicybet from './MyBets.styles.juicybet';
import * as betroyale from './MyBets.styles.betroyale';
import * as junglebet from './MyBets.styles.junglebet';
import * as sugarcastle from './MyBets.styles.sugarcastle';
import * as buffalobet from './MyBets.styles.buffalobet';
import * as oldgill from './MyBets.styles.oldgill';
import * as titanbet from './MyBets.styles.titanbet';
import * as slambet from './MyBets.styles.slambet';
import * as gigabet from './MyBets.styles.gigabet';
import * as templebet from './MyBets.styles.templebet';
import * as goldenbet888 from './MyBets.styles.goldenbet888';
import * as bet777 from './MyBets.styles.bet777';
import * as chromabet from './MyBets.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  oldgill,
  betprofessor,
  puntgenie,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  juicybet,
  betroyale,
  junglebet,
  sugarcastle,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  templebet,
  goldenbet888,
  bet777,
  chromabet,
};
