import { CSSObject, TextProps } from '@chakra-ui/react';
import takeAPungBG from '@/assets/juicybet/images/backgrounds/takeAPunt.png';
import mobileTakeAPungBG from '@/assets/juicybet/images/backgrounds/mobileTakeAPunt.png';

export const FlexWrapper: CSSObject = {
  mt: ['2', '5'],
  mb: ['-1.5', '3.5'],
  py: 0,
  h: ['422px', '340px'],
  border: 'none',
  bgImage: [`url(${mobileTakeAPungBG})`, `url(${takeAPungBG})`],
  bgRepeat: 'no-repeat',
  bgSize: '100% 100%',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  contain: 'paint',
};

export const FlexTextWrapper: CSSObject = {
  gap: '0.5',
};

export const FlexTeamWrapper: CSSObject = {
  gap: '2',
  mt: '4px',
  mb: 0,
  border: 0,
  flexDir: 'column',
  justifyContent: 'center',
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, gamma.400, gamma.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  color: 'blackAlpha.600',
};

export const textSloganProps: TextProps = {
  textAlign: 'center',
  fontSize: ['7xl', '7xl'],
  fontFamily: 'Chewy',
  fontWeight: 'normal',
  color: 'alpha.50',
  textShadow: '0px 6px 7px rgba(0, 0, 0, 0.35), 0px 4.5px 0px #439A06;',
  lineHeight: '100%',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.400',
  fontSize: 'xs',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'lg',
  color: 'alpha.300',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'extrabold',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'md',
  textShadow: 'none',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: '100%',
  m: 'auto',
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['3.5', '2'],
};

export const HeadingSlogan: CSSObject = {
  fontFamily: 'Chewy',
  fontWeight: 'normal',
  color: 'alpha.50',
  fontSize: ['4rem', '6xl'],
  textShadow: '0px 6px 7px rgba(0, 0, 0, 0.35), 0px 4.5px 0px #439A06;',
  lineHeight: '75%',
};

export const puntButtonProps: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  boxShadow:
    '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
  borderRadius: 'md',
  fontFamily: 'Coiny',
  color: 'blackAlpha.600',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  borderWidth: '2px',
  borderColor: 'white',
};
