import { CSSObject } from '@chakra-ui/react';
import overviewBanner from '../../../../../../assets/wellbet/images/backgrounds/overviewBanner.jpg';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'secondary',
    sx: {
      '&&': {
        h: 6,
        fontSize: 'xs',
        px: 3,
      },
    },
  },
};

export const FlexAccountDetails: CSSObject = {
  flexDir: ['column', null, 'row'],
  w: 'full',
  gap: '2',
  alignItems: ['unset', null, 'center'],
};

export const FlexAccountName: CSSObject = {
  fontSize: 'xl',
  color: 'alpha.300',
  fontWeight: 'semibold',
};

export const FlexBannerContainer: CSSObject = {
  p: '4',
  bg: `url(${overviewBanner})`,
  borderRadius: 'base',
  gap: ['2', null, 'unset'],
  backgroundPosition: 'top',
  backgroundSize: 'cover',
};

export const FlexLabel: CSSObject = {
  w: ['full', null, 'unset'],
  flex: '1',
};

export const FlexBalanceBetInfo: CSSObject = {
  px: '2',
  py: '1',
  bg: 'transparent',
  color: 'white',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'base',
  fontSize: '2xs',
  h: ['7', null, 'full'],
  fontWeight: 'extrabold',
  textTransform: 'uppercase',
  alignItems: 'center',
  justifyContent: 'center',
  flex: ['1', null, 'unset'],
};

export const FlexAccountBalances: CSSObject = {
  flexDir: 'row',
  gap: ['8', null, '6'],
  justifyContent: ['space-between', null, 'unset'],
};

export const TextButton: CSSObject = {
  alignSelf: 'center',
};

export const TextAccountBalanceLabel: CSSObject = {
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const TextAccountBalanceBaseUnit = (isBonus: boolean): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: 'xl',
  fontWeight: 'extrabold',
  color: isBonus ? 'alpha.300' : 'gamma.500',
});

export const TextAccountBalanceSubUnit = (isBonus: boolean): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'extrabold',
  color: isBonus ? 'alpha.300' : 'gamma.500',
  opacity: '0.7',
});

export const TextAccountBalanceCurrencySymbol = (
  isBonus: boolean
): CSSObject => ({
  mt: '0.5',
  alignSelf: 'flex-start',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: isBonus ? 'alpha.300' : 'gamma.500',
});

export const FlexButtonContainer: CSSObject = {
  h: ['unset', null, '6'],
  fontSize: '2xs',
  gap: '2',
  flexDir: ['column', null, 'row'],
};
