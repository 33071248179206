import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/goldenbet888/images/feature/featSport.png';
import featuredMb from '@/assets/goldenbet888/images/feature/featSportMb.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  border: 'none',
  borderRadius: 'lg',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'gamma.500',
  fontSize: 'md',
  fontWeight: 'normal',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'gamma.500',
  fontFamily: 'accentMusashi',
  boxShadow: 'unset',
};

export const TextMisc: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.900)',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  borderRadius: 'base',
  color: 'gamma.500',
  fontSize: '2xs',
  fontWeight: 'extrabold',
};

export const TextTeam: CSSObject = {
  color: 'gamma.200',
  fontSize: ['xl', null, '2xl'],
  fontWeight: 'normal',
  fontFamily: 'accentStaat',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontFamily: 'accentMusashi',
  fontSize: ['2xl', null, '3xl'],
  fontWeight: 'normal',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  textTransform: 'uppercase',
  color: 'gamma.500',
};

export const puntButtonProps: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  borderRadius: 'base',
  color: 'blackAlpha.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  fontWeight: 'normal',
  h: '9',
  pt: '0.5',
  _hover: {
    bgGradient: 'linear(to-b, gamma.300, gamma.300)',
  },
};
