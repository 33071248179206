import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  pb: '0',
  px: '2',
};

export const FormText: CSSObject = {
  color: 'black',
};

export const ThemedInputAddon = (): CSSObject => ({
  _groupHover: {
    bg: 'gray.400',
  },
  _groupFocusWithin: {
    bg: 'gray.500',
  },
  bg: 'gray.300',
  color: 'black',
});
export const InputBetSlip: CSSObject = {
  bg: 'white',
  marginTop: '-2px',
  borderColor: 'gray.300',
  pl: '10',
  _hover: {
    borderColor: 'gray.400',
    '&&': {
      background: 'white',
    },
  },
  _focus: {
    borderColor: 'gray.500',
    '&&': {
      background: 'white',
    },
  },
};
export const ReviewContainer: CSSObject = {
  border: '2px dashed',
  borderColor: 'gray.200',
};

export const ReviewStakeText: CSSObject = {
  color: 'gray.600',
  fontSize: 'xs',
};

export const ReviewStake: CSSObject = {
  color: 'gray.600',
  fontSize: 'md',
  fontWeight: 'bold',
};

export const ReviewEstText: CSSObject = {
  color: 'gray.600',
  fontSize: 'xs',
};

export const ReviewEst: CSSObject = {
  color: 'black',
  fontSize: 'lg',
  fontWeight: 'bold',
};

export const TreasureChestIcon: CSSObject = {
  color: 'white',
};
