import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    py: '3px',
    px: '1px',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    borderRadius: 'md',
    overflow: 'hidden',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    mb: '1px',
    _first: {
      borderTopRadius: 'md',
    },
    _last: {
      mb: '0',
      borderBottomRadius: 'md',
    },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
    bg: 'alpha.600',
    _hover: { bgGradient: 'linear(to-b, gamma.700, gamma.700)' },
  },
  accordionHeadingText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    textColor: 'white',
    textTransform: 'uppercase',
    fontFamily: 'Staatliches',
    fontSize: 'md',
  },
  accordionIcon: {
    color: 'beta.500',
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
    borderLeftWidth: '3px',
    borderLeftColor: 'beta.500',
  },
};
