import { Link } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FlexResponsibleGamblingSlideContainer,
  FlexTextContainer,
  TextResponsibleGamblingHeading,
  TextResponsibleGamblingCaption,
} from './styles/ResponsibleGamblingBanner.styles';
import { useResponsibleGamblingMessage } from './services/ResponsibleGamblingBanner.hooks';
import { TResponsibleGamblingBanner } from './services/ResponsibleGamblingBanner.types';

export default function ResponsibleGamblingBanner({
  bannerLocation,
}: TResponsibleGamblingBanner) {
  const { messageId } = useResponsibleGamblingMessage();
  return (
    <FlexResponsibleGamblingSlideContainer>
      <FlexTextContainer>
        <TextResponsibleGamblingHeading bannerLocation={bannerLocation}>
          <FormattedMessage id={messageId} />
        </TextResponsibleGamblingHeading>
        <TextResponsibleGamblingCaption bannerLocation={bannerLocation}>
          <FormattedMessage
            id="legal.responsible.caption"
            values={{
              cta: (string: string) => (
                <Link
                  href="https://www.gamblinghelponline.org.au/"
                  target="_blank"
                >
                  {string}
                </Link>
              ),
            }}
          />
        </TextResponsibleGamblingCaption>
      </FlexTextContainer>
    </FlexResponsibleGamblingSlideContainer>
  );
}
