/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Show } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import {
  buttonBetSlipProps,
  buttonLogInProps,
  buttonSignUpProps,
  TextButton,
  TextButtonNumber,
} from '../styles/Header.styles';
import { Button } from '@/components/Button/Button';
import { useBetsCount } from '@/components/Betslip/Services/Betslip.hooks';
import { transformBetForLegacy, useBetSlipBets } from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { TBetSlipBet } from '@/components/Betslip/Services/Betslip.types';
import { setBetSlipOpen } from '@/redux/Betslip.slices';

/**
 * Containers
 */
const NotAuthButtonsV1ReduxContainer = () => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <NotAuthButtonsV1 bets={bets} />;
};

const NotAuthButtonsV1ZustandContainer = () => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <NotAuthButtonsV1 bets={bets} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? NotAuthButtonsV1ZustandContainer
  : NotAuthButtonsV1ReduxContainer;

/**
 * View
 */
type NotAuthButtonsV1Props = {
  bets: TBetSlipBet[];
};

function NotAuthButtonsV1({ bets }: NotAuthButtonsV1Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    {
      Generic: { Login, SignUp },
    },
  ] = getStrings();

  const { validBetsCount } = useBetsCount({ bets });

  return (
    <>
      <Button
        {...buttonLogInProps}
        data-cy="HeaderLogin"
        onClick={() => navigate('/login')}
      >
        {Login}
      </Button>
      <Button
        {...buttonSignUpProps}
        data-cy="HeaderSignUp"
        onClick={() => navigate('/signup')}
      >
        {SignUp}
      </Button>

      <Show below="xl">
        <Button
          {...buttonBetSlipProps}
          data-cy="betSlipCount"
          className="betslipBtn"
          onClick={() => dispatch(setBetSlipOpen(true))}
        >
          <TextButton>
            <FormattedMessage id="header.betslipBtn.desc" />
          </TextButton>
          <TextButtonNumber data-cy="betCount">
            {validBetsCount}
          </TextButtonNumber>
        </Button>
      </Show>
    </>
  );
}
