import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.500',
  gap: '1',
};

export const BoxMoreText: CSSObject = {
  mr: '1',
  fontSize: 'xs',
};
