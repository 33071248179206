import { Flex, chakra, ChakraComponent } from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { styleImports } from './SideLinks.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxAside = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    alignSelf: 'flex-start',
    mb: '8',

    ...(theme.themeName === EThemes.Wellbet && {
      mb: '4',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxAside;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export type TLinkAsideProps = {
  isActive?: boolean;
} & LinkProps;

export const LinkAside = chakra(Link, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ theme, isActive }: any) => ({
    display: 'flex',
    p: '1.5',
    mr: '4',

    ...(theme.themeName === EThemes.Wellbet && {
      bgGradient: isActive
        ? 'linear(to-b, gray.300, gray.300)'
        : 'linear(to-b, alpha.600, alpha.700)',
      color: isActive ? 'gray.600' : 'white',
      fontWeight: 'bold',
      fontSize: ['2xs', null, 'xs'],
      transition: 'background .3s ease-in-out',
      borderRadius: 'md',
      textShadow: isActive ? 'none' : '0px 2px 3px rgba(0, 0, 0, 0.2)',
      px: '3',
      boxShadow: isActive ? 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)' : 'md',
      mr: '1',
      textTransform: 'uppercase',

      _first: { borderTop: 0 },
      '&:hover, &:active': {
        bg: 'linear(to-b, gray.300, alpha.400)',
        boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
        bgSize: '250% 250%',
      },
    }),

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: isActive ? 'beta.700' : 'white',
      fontWeight: 'bold',
      fontSize: ['sm', null, 'md'],
      borderRadius: 'md',
      border: '2px solid',
      borderColor: 'beta.700',
      transition: '.3s ease-in-out',
      color: isActive ? 'white' : 'beta.700',

      '&:hover, &:active': { bg: 'white', color: 'beta.700' },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkAside?.({
          isActive,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', TLinkAsideProps>;
