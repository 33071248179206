import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '40px',
      border: '1px',
      borderColor: 'gray.300',
      background: 'gray.50',
      color: 'gray.600',
      borderRadius: 'md',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      fontFamily: 'body',
      boxShadow: [
        '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
        '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
        'none',
      ],
      px: '2',
      'input, span': {
        fontFamily: 'body',
        fontWeight: 'normal',
      },
      '.react-date-picker__button': {
        color: 'gray.600',
      },
      _hover: {
        bg: 'gray.50',
        borderColor: 'gray.300',
      },
      _active: {
        bg: 'gray.200',
        borderColor: 'gray.300',
      },
      _focus: {
        bg: 'gray.200',
        borderColor: 'gray.300',
      },
    },
  },
};
