import menuTile from '@/assets/vikingbet/images/backgrounds/texture-wood-1x.png';
import menuTile2x from '@/assets/vikingbet/images/backgrounds/texture-wood-2x.png';
import arrowLeft from '@/assets/vikingbet/images/backgrounds/menu-arrow-left.svg';
import arrowRight from '@/assets/vikingbet/images/backgrounds/menu-arrow-right.svg';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    _last: {
      borderTopWidth: ['2px', null, null, '0px', null],
      borderColor: '#643214',
    },
    mt: ['0', '1', null],
  },
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: ['100%', null, null, '270px'],
    px: ['0', null, '0'],
    mt: ['0', null, null, '5'],
    mb: ['1', null, null, '7'],
    bgImage: ['none', null, null, `url(${menuTile})`],
    bgRepeat: 'repeat-y',
    backgroundSize: ['inherit', null, null, '100% 100%'],
    borderRadius: '1.2rem',
    borderY: ['none', null, null, '#C5C4C0 5px solid'],
    borderX: ['none', null, null, '#C5C4C0 8px solid'],
    boxShadow: [
      'none',
      null,
      null,
      '0px 0px 5px 8px rgba(0, 0, 0, 0.40) inset, 0px 5px 0px 0px #757472, 0px 10px 0px 0px #464646, 0px -4px 0px 0px #EDEDEC',
    ],
    zIndex: '5',
    sx: {
      '@media (-webkit-min-device-pixel-ratio: 2)': {
        backgroundImage: ['none', null, null, `url(${menuTile2x})`],
        backgroundSize: ['inherit', null, null, '100%'],
      },
    },
    _before: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      bottom: '50%',
      right: '0px',
      width: '10px',
      height: '36px',
      zIndex: '3',
      backgroundImage: `url(${arrowLeft})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    _after: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      bottom: '50%',
      left: '0px',
      width: '10px',
      height: '36px',
      zIndex: '3',
      backgroundImage: `url(${arrowRight})`,
      backgroundSize: '98% 98%',
      backgroundRepeat: 'no-repeat',
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    boxShadow: [
      'none',
      null,
      null,
      '0px 6px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px #AD8B4C, 0px 2px 1px 0px rgba(255, 255, 255, 0.30) inset',
    ],
  },
};
