import { css } from 'styled-components';

// TODO: Remove
export const pageContent = css`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const pageHeading = css`
  font-size: var(--bc-fontSizes-xl);
  color: ${({ theme }) => theme.palette.primary};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const subheading = css`
  font-size: var(--bc-fontSizes-xs);
  margin-bottom: var(--bc-sizes-4);
`;

export const subheadingWrapper = css`
  margin-top: 14px;
`;

export const increaseAmountWrapper = css`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 15px 0;
`;

export const increaseAmountButtonWrapper = css`
  width: 18%;
`;

export const increaseAmountButton = css`
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  font-size: var(--bc-fontSizes-md);
  line-height: 24px;
  box-shadow: inset 0 0 5px 1px ${({ theme }) => theme.basePalette.white},
    0 0 4px ${({ theme }) => theme.basePalette.white};
  background: ${({ theme }) => theme.basePalette.brightBlue};
  outline: none;
  border: none;
  color: ${({ theme }) => theme.palette.secondary};
  border-radius: 4px;
  width: 100%;
`;

export const backIcon = css`
  height: var(--bc-sizes-4);
  width: var(--bc-sizes-4);
  margin-right: 12px;
  cursor: pointer;
`;

export const headerWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--bc-sizes-6);
`;

export const bsbInputWrapper = css`
  width: 35%;
`;

export const accNumberInputWrapper = css`
  width: 63%;
`;

export const highlightSpan = css`
  color: ${({ theme }) => theme.basePalette.brightBlueB};
`;

export const addBankButton = css`
  border-radius: 4px;
  min-width: var(--bc-sizes-36);
  border: 2px dashed var(--bc-colors-whiteAlpha-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--bc-fontSizes-md);
  cursor: pointer;
  height: var(--bc-sizes-28);

  :hover {
    border: 2px dashed var(--bc-colors-whiteAlpha-700);
  }
`;

export const bankError = css`
  color: ${({ theme }) => theme.palette.warning};
  font-size: var(--bc-fontSizes-xs);
  padding-top: var(--bc-sizes-1);
  font-family: ${({ theme }) => theme.fontFamily.body};
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--bc-sizes-2);
`;

export const errorIcon = css`
  width: var(--bc-sizes-3);
  height: var(--bc-sizes-3);
  margin-right: var(--bc-sizes-1);
  color: ${({ theme }) => theme.palette.warning};
`;

export const bankCardContainer = css`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
  min-width: var(--bc-sizes-36);
  position: relative;
  margin-right: 8px;
  cursor: pointer;
  height: var(--bc-sizes-28);

  :hover {
    border: 4px solid ${({ theme }) => theme.basePalette.medBlue};
    box-shadow: 0px 4px 11px 5px #00000025;
  }
`;

export const bankCardContainerSelected = css`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
  min-width: var(--bc-sizes-36);
  position: relative;
  margin-right: 8px;
  border: 4px solid ${({ theme }) => theme.basePalette.medBlue};
  box-shadow: 0px 4px 11px 5px #00000025;
  cursor: pointer;
`;

export const contentContainer = css`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const hiddenBsb = css`
  font-size: var(--bc-fontSizes-md);
  margin-right: 5px;
`;

export const hiddenBankNumber = css`
  font-size: var(--bc-fontSizes-md);
`;

export const nickname = css`
  font-size: var(--bc-fontSizes-xs);
  margin-top: var(--bc-sizes-1);
`;

export const trashIcon = css`
  height: 12px;
  width: 12px;
  position: absolute;
  right: var(--bc-sizes-1);
  top: var(--bc-sizes-1);
  cursor: pointer;
`;

export const bankIcon = css`
  height: 27px;
`;

export const addBankIcon = css`
  height: 42px;
  margin-bottom: 4px;
  margin-left: 10px;
`;

export const withdrawalCardContent = css`
  display: flex;
  flex-direction: row;
  min-height: 95px;
  width: 100%;
  justify-content: space-between;
  padding: 12px 16px 0 16px;
`;

export const withdrawalCardDetailsContainer = css``;

export const withdrawalCardDetail = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  margin-bottom: 8px;
`;

export const withdrawalCardAmountContainer = css`
  align-self: flex-end;
`;

export const withdrawalCardHeading = css`
  color: ${({ theme }) => theme.basePalette.brightBlue};
  font-family: ${({ theme }) => theme.fontFamily.headingAlt};
  font-size: var(--bc-fontSizes-xs);
`;

export const withdrawalCardAmount = css`
  font-family: ${({ theme }) => theme.fontFamily.headingAlt};
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-md);
  margin-top: 6px;
  text-align: right;
`;

export const withdrawalCardFooter = css`
  display: flex;
`;

export const withdrawalCardButtonContainer = css`
  width: 190px;
  margin-left: 16px;
`;

// TODO: Remove
export const withdrawListHeading = css`
  background: ${({ theme }) => theme.basePalette.white};
  color: ${({ theme }) => theme.basePalette.navyBlue};
  font-family: ${({ theme }) => theme.fontFamily.headingAlt};
  font-size: var(--bc-fontSizes-xs);
  padding: 12px 16px;
`;

export const listContainer = css`
  margin-bottom: 24px;
`;

export const withdrawalCardErrorContainer = css`
  background: #f50057;
  padding: 8px 16px;
  width: 100%;
  margin-top: 11px;
  color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const removeLink = css`
  cursor: pointer;
`;

export const withdrawalCardCancelledContainer = css`
  margin-top: 16px;
`;
