import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  wrapper: {
    bottom: ['15px', null, '0'],
    left: '0',
    position: ['fixed', null, null, 'sticky'],
    right: '0',
    zIndex: 'sticky',
  },
  slideBox: {
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    color: 'white',
    mb: '-4',
    borderBottomRadius: 'base',
  },
  accordionItem: {
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
  },
  accordionHeaderText: {
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    bg: 'gamma.500',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.900',
    fontSize: 'xs',
    fontWeight: 'bold',
    gap: '1',
    p: '2',
    _hover: {
      bg: 'gamma.600',
    },
  },
  accordionButtonIcon: {
    fontSize: 'sm',
  },
  headerStack: {
    bg: 'alpha.900',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    py: '2',
  },
  headerText: {
    flex: '1',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
  },
  accordionPanel: {
    fontSize: 'xs',
    maxH: '32',
    overflow: 'auto',
    p: '0',
  },
  selectionFlex: {
    borderColor: 'alpha.500',
    gap: '2',
    bg: 'alpha.500',
    p: '2',
    pos: 'relative',
    _notFirst: {
      borderTopStyle: 'dotted',
      borderTopWidth: '1px',
    },
    _before: {
      border: 'inherit',
      borderColor: 'whiteAlpha.300',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
  },
  footerStack: {
    bg: 'alpha.500',
    borderColor: 'whiteAlpha.300',
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    p: '2',
    pb: '6',
    pos: 'relative',
    borderBottomRadius: 'base',
    _before: {
      bgGradient: 'linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, .2))',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '17px',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
  },
  legsText: {
    flex: '1',
    display: 'flex',
    flexDir: 'column',
    textTransform: 'uppercase',
  },
  legsChunks: {
    color: 'gamma.500',
    fontSize: 'xl',
    fontWeight: 'black',
  },
  oddsText: {
    flex: '2',
    display: 'flex',
    flexDir: 'column',
    textTransform: 'uppercase',
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'gamma.500',
    fontSize: 'lg',
    fontWeight: 'black',
  },
  clearButton: {
    variant: 'outline',
    px: ['2', '2.5'],
    py: '1',
    borderRadius: 'md',
    fontSize: ['sm', 'md'],
    fontFamily: 'Sansita One',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  },
  addToSlipButton: {
    borderRadius: 'md',
    px: ['2', '2.5'],
    py: '1',
    fontSize: ['sm', 'md'],
    fontFamily: 'Sansita One',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  },
  buttonsHStack: {
    gap: ['0', '2'],
  },
};
