import React from 'react';
import IconSvg from '../../../../common/IconSvg';
import { TSideIcon } from '../../../services/NavIcon.types';

export const DepositIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Deposit888" className="svgIcon" sx={styles.icon} />
);

export const ContactIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="ContactUs888" className="svgIcon" sx={styles.icon} />
);

export const MyAccountIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="MyAccount888" className="svgIcon" sx={styles.icon} />
);

export const SportsIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Sports888" className="svgIcon" sx={styles.icon} />
);

export const ResponsibleGamblingIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="ResponsibleGambling888" className="svgIcon" sx={styles.icon} />
);
