import { TCrumb } from './types';

export const useBreadcrumbs = () => {
  const getHref = (crumb: TCrumb, allCrumbs: TCrumb[], idx: number) => {
    const isRacing = allCrumbs?.[0]?.display?.toLocaleLowerCase() === 'racing';

    /* Last breadcrumb is not clickable */
    if (
      (idx === 0 && allCrumbs.length - 1 === 0) ||
      (idx === 1 && allCrumbs.length - 1 === 1 && isRacing)
    ) {
      return '';
    }

    if (isRacing && idx <= 1) {
      return crumb.custom;
    }

    return crumb.to;
  };

  return { getHref };
};
