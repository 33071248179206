import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '2.5',
  pt: '0',
  mt: ['0.5', null, '0'],
  top: ['0px', '9'],
  mx: ['2', '1.5'],
  borderBottomRadius: 'base',
  position: 'inherit',
};

export const IconRace = (): CSSObject => ({
  ml: '0.5',
  color: 'whiteAlpha.700',
});

export const ButtonFilter = ({ selected }: TSelected) => ({
  color: selected ? 'beta.600' : 'transparent',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'whiteAlpha.700',
  color: selected ? 'beta.800' : 'transparent',
  boxShadow: '0px 1px 0px 0px rgba(84, 30, 1, 0.70) inset',
  mr: '0.5',
});

export const TextFilterLabel: CSSObject = {
  color: 'whiteAlpha.700',
};

export const Divider: CSSObject = {
  bg: 'whiteAlpha.400',
  display: 'block',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: 'unset',
};
