import { CSSObject } from '@chakra-ui/react';
import menuTop from '@/assets/sugarcastle/images/sidenav/sc-menu-top.png';
import menuBottom from '@/assets/sugarcastle/images/sidenav/sc-menu-bottom.png';

export const GridItemBetslip: CSSObject = {
  h: '100%',
  '& > div[class*="-BetSlipModalContainer"]': {
    zIndex: '1',
    width: '100%',
    borderRadius: '3xl',
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '100%',
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '85px',
      zIndex: '-1',
      backgroundImage: `url(${menuTop})`,
      backgroundSize: '272px',
      backgroundRepeat: 'no-repeat',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-6px',
      left: '0',
      width: '100%',
      height: '85px',
      backgroundImage: `url(${menuBottom})`,
      backgroundSize: '272px',
      backgroundRepeat: 'no-repeat',
      zIndex: '-1',
    },
  },
};

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
  maxWidth: ['inherit', '272px'],
};
