import React from 'react';
import {
  ApprovedBetContainer,
  BetApprovalText,
  IconApprovedBet,
} from '../../../BetCard/components/Footer/Footer.styles';

type TBetCardApprovalProps = {
  text: string;
};

export function BetCardApproval({ text }: TBetCardApprovalProps) {
  return (
    <ApprovedBetContainer>
      <IconApprovedBet />
      <BetApprovalText>{text}</BetApprovalText>
    </ApprovedBetContainer>
  );
}
