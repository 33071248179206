import { TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'brand.500',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'regular',
  lineHeight: '14.4px',
  color: 'white',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
};
