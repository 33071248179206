import queryString from 'query-string';
import api from '@/api/api';
import {
  TQueryUpcomingMatchesParams,
  TQueryUpcomingMatchesResponse,
} from './upcomingMatches.types';

export const upcomingMatchesEndpoint =
  '/punter/sports/upcoming-matches-by-time';

export async function queryUpcomingMatches(
  params?: TQueryUpcomingMatchesParams
) {
  const res = await api.get<TQueryUpcomingMatchesResponse>(
    upcomingMatchesEndpoint,
    { params, paramsSerializer: queryString.stringify }
  );
  return res;
}
