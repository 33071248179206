import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  color: 'white',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bg: 'alpha.500',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.50) inset',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'white',
  },
};
export const TextTitle: CSSObject = {
  color: 'white',
};
