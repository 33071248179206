import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'whiteAlpha.900' },
};

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'md',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'beta.500' : 'white',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'md',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'blackAlpha.600' : 'transparent',
  boxShadow: isActive
    ? '0px 1px 0px rgba(255, 255, 255, 0.45), inset 0px 0px 5px rgba(0, 0, 0, 0.45)'
    : 'none',

  backgroundBlendMode: 'lighten, overlay, normal',

  _hover: {
    bg: isActive ? 'blackAlpha.600' : 'transparent',
    color: 'white',
    boxShadow: 'none',
    textShadow: 'none',
  },
  _focus: {
    bg: isActive ? 'blackAlpha.600' : 'transparent',
    color: isActive ? 'beta.500' : 'white',
  },
});

export const buttonAccountTabOptionProps: CustomButtonProps = {
  color: 'white',
  textShadow: 'none',
  bgImage: 'none',
  boxShadow: 'none',
  bg: 'transparent',

  sx: {
    '&[aria-selected="true"]': {
      color: 'beta.500',
      bg: 'alpha.800',
      boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
      _hover: {
        bg: 'alpha.600',
        color: 'white',
      },
    },

    '&&': {
      borderRadius: 'base',
    },
  },

  h: '5',
  fontWeight: 'bold',
  fontSize: '2xs',
  ml: '1',
  px: '2',
  py: '1.5',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',

  border: 'none',
  fontFamily: 'Roboto',
};
