import { DepositSchema } from '@/views/account/Deposit/styles/Deposit.styles';

export const depositStyles: DepositSchema = {
  inputCardHolderNameProps: {
    sx: {
      '&&': {
        bg: 'white',
        h: 10,
        fontSize: 'xs',
        px: 3,
        fontWeight: 'semibold',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      },
    },
  },
};
