import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.30)',
  border: '2px solid',
  borderColor: 'delta.700',
  borderRadius: 'md',
  color: 'epsilon.300',
  h: '9',

  _hover: {
    bg: 'delta.300',
    color: 'delta.500',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  color: 'epsilon.300',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'epsilon.300',
};
