import { FlexProps, TextProps, BoxProps } from '@chakra-ui/react';
import { styleImports } from './SRMInfo.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type SRMInfoSchema = Partial<{
  runnerDetails: TextProps;
  eventMarketName: TextProps;
  eventSubtitle: TextProps;
  placeNumber: TextProps;
  srmContainer: FlexProps;
  infoExoticsContainer: BoxProps;
  sgmLegsContainer: BoxProps;
}>;

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    'data-testid': 'srmInfo-runnerDetails',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.runnerDetails;
      } catch (err) {
        return {};
      }
    })(),
  },
  eventMarketName: {
    'data-testid': 'srmInfo-eventMarketName',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.eventMarketName;
      } catch (err) {
        return {};
      }
    })(),
  },
  eventSubtitle: {
    'data-testid': 'srmInfo-eventSubtitle',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.eventSubtitle;
      } catch (err) {
        return {};
      }
    })(),
  },
  placeNumber: {
    'data-testid': 'srmInfo-placeNumber',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.placeNumber;
      } catch (err) {
        return {};
      }
    })(),
  },
  srmContainer: {
    'data-testid': 'srmInfo-srmContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.srmContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  infoExoticsContainer: {
    'data-testid': 'srmInfo-infoExoticsContainer',
    gap: '2',
    pl: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.infoExoticsContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
  sgmLegsContainer: {
    'data-testid': 'srmInfo-sgmLegsContainer',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.sgmLegsContainer;
      } catch (err) {
        return {};
      }
    })(),
  },
};
