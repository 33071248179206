import { css } from 'styled-components';

export const dropdownWrapper = css`
  position: relative;
`;

export const dropdownOption = css`
  cursor: pointer;
  padding: var(--bc-sizes-2) var(--bc-sizes-1);
  border-top: 1px solid grey;
  background-color: ${({ theme }) => theme.basePalette.navyBlue};
  min-width: var(--bc-sizes-52);
  color: ${({ theme }) => theme.palette.white};

  &:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.basePalette.darkBlue};
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const optionsWrapper = css`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.inputBg};
  width: 100%;
  top: 33px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 1;
`;

export const selectedOptionLabel = css`
  flex: 1;
  font-size: var(--bc-fontSizes-xs);
`;

export const dropdownControlIcon = css`
  width: var(--bc-sizes-4);
  height: var(--bc-sizes-4);
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary};
`;

export const selectedOptionWrapper = css`
  cursor: pointer;
  min-height: 33px;
  background-color: ${({ theme }) => theme.palette.inputBg};
  border: 1px solid ${({ theme }) => theme.basePalette.faintGrey};
  border-radius: 3px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.black};
  display: flex;
  padding: var(--bc-sizes-1);
  align-items: center;
`;
