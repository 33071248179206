import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  mb: '2',
  p: '2.5',
};

export const ButtonGetVerified: CSSObject = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  border: '2px solid',
  borderColor: 'delta.700',
  borderRadius: 'lg',
  color: 'delta.800',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  textShadow: '0px 1px 1px rgba(247, 215, 31, 0.7)',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 2px #F7D722',
  h: '8',
  minW: 'auto',
  ml: 'auto',
  p: '1.5',
  textTransform: 'uppercase',

  _hover: {
    bg: 'delta.300',
  },

  _active: {
    bg: 'delta.400',
  },
};
