import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Flex, Text } from '@chakra-ui/react';
import { TBetForRace } from '@/api/racing/bets/bets.types';
import IconSvg from '@/components/common/IconSvg';
import {
  centsToDollars,
  getIconBySportName,
  getPositionValue,
} from '@/helpers/utils';
import { onRaceBetStyles } from '../styles/OnRaceBets.styles';

type SingleBetProps = {
  bet: TBetForRace;
  isLast: boolean;
};

export const SingleBet = ({ bet, isLast }: SingleBetProps) => {
  const propInfo = bet.proposition_info?.[0];
  const { racingType } = useParams();
  const intl = useIntl();
  const getBetOdds = () => {
    if (bet.price_type === 'starting') return 'SP';
    if (bet.price_type === 'tote_single_mid') return 'MD';
    if (bet.price_type === 'tote_single_best') return 'BT';
    return `@${bet.bet_odds.toLocaleString('en-AU', {
      minimumFractionDigits: 2,
    })}`;
  };

  const isMysteryBet = bet.price_type === 'mystery_bet';

  return (
    <Flex
      key={bet.bet_id}
      {...onRaceBetStyles.betRow}
      {...(!isLast && onRaceBetStyles.betRowDivider)}
    >
      <IconSvg
        name={getIconBySportName(racingType)}
        {...onRaceBetStyles.categoryIcon}
      />
      <Flex direction="column" flex="1">
        <Flex direction="row">
          <Text {...onRaceBetStyles.runnerText}>
            {propInfo?.runner_number}.
          </Text>
          <Text {...onRaceBetStyles.runnerText}>
            {propInfo?.name?.toLowerCase()}
          </Text>
          {!!propInfo?.barrier_number && (
            <Text {...onRaceBetStyles.runnerBarrierText}>
              ({propInfo?.barrier_number})
            </Text>
          )}
        </Flex>
        <Flex>
          <Text {...onRaceBetStyles.subtitleText} flex="1">
            {getBetOdds()} |{' '}
            {isMysteryBet
              ? getPositionValue(propInfo?.proposition_type ?? '')
              : propInfo?.proposition_type ?? ''}
          </Text>
          <Text {...onRaceBetStyles.subtitleText} flex="1">
            Stake:{' '}
            <Text as="span" {...onRaceBetStyles.stakeText}>
              {centsToDollars(bet.bet_stake)}
            </Text>
          </Text>
        </Flex>
        <Text {...onRaceBetStyles.subtitleText}>
          {intl.formatMessage({
            id: 'generic.potentialWinnings',
          })}
          :{' '}
          <Text as="span" {...onRaceBetStyles.potentialWinningsText}>
            {bet.potential_return
              ? centsToDollars(bet.potential_return)
              : 'N/A'}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
