import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'tab',
    boxSize: '8',
    fontSize: 'xs',
    borderRadius: 'md',
    color: 'beta.500',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  ml: ['0', '0'],
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      borderColor: 'epsilon.500',
      borderWidth: '2px',
      color: 'white',
      boxShadow: 'none',
      '&[data-active="false"]': {
        boxShadow:
          '0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #695005 inset',
        bg: 'epsilon.500',
        color: 'epsilon.900',
      },
      '&.active': {
        border: '2px solid',
        borderColor: 'epsilon.500',
        bg: 'epsilon.900',
        color: 'epsilon.500',
      },
      '&:hover': {
        bg: 'epsilon.600',
      },
    },
  },
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'epsilon.100',
  textTransform: 'capitalize',
  fontFamily: 'roboto',
};

export const RaceAndTimerContainer: CSSObject = {
  borderRadius: 'base',
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
};

export const TextRaceConditions: CSSObject = {
  fontFamily: 'body',
  color: 'epsilon.100',
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    pos: 'relative',
    mx: '1.5',
    bg: 'beta.200',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  mx: ['2.5', null, '3'],
};

export const RaceRunnerList: CSSObject = {
  borderRadius: 'md',
  bg: 'white',
  p: '2',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  border: '2px',
  borderColor: 'epsilon.500',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  lineHeight: 'normal',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  py: '2',
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'epsilon.100',
  fontFamily: 'roboto',
};

export const RaceResultListWrapper: CSSObject = {
  gap: ['2', null, '2.5'],
  mb: '2',
  flexDirection: 'column',
};

export const InfoContainer: CSSObject = {
  py: '0',
  mb: '0',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'alpha.400',
    },
  },
  columnGap: '0',
  svg: {
    color: 'white',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'beta.700',
  bg: 'beta.400',
  fontFamily: 'roboto',
  textShadow: 'none',
};

export const RaceStatusText: CSSObject = {
  bg: 'delta.400',
  color: 'delta.800',
  fontFamily: 'roboto',
  textShadow: 'none',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    px: '0',
    w: ['53px', null, '16'],
  },
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'gray.700',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};
