import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    fontSize: '2xs',
    h: 'auto',
    py: '2',
    px: '3',
    textShadow: '0px 1px 0px #FFFFFF73',
    color: 'alpha.800',
    bg: 'alpha.400',
    boxShadow:
      '0px 4px 4px 0px #00000040, 0px 3px 0px 0px #448800, 0px 1px 0px 0px #FFFFFF66 inset, 0px 0px 0px 1px #FFFFFF33 inset',
    _disabled: {
      py: '1',
      px: '2',
      color: 'white',
      bg: 'blackAlpha.700',
      opacity: '1',
      boxShadow: 'none',
      textShadow: 'none',
    },
  },
};

export const FlexInner: CSSObject = {
  borderBottomColor: 'whiteAlpha.300',
  minH: '12',
  py: '2',
  '&:last-of-type': {
    borderBottomColor: 'transparent',
  },
};

export const TextMonth: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  color: 'white',
};
