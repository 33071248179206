import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {},
  headerHeading: {},
  headerIconButton: {},
  headerSkeletonText: {},
  headerVsBox: {},

  bodyStack: {},
  bodyInnerWrapper: {},
  sgmLogo: {},

  scrollButtonGroup: {},
  toMarketButton: {},
  loadingSkeletonText: {},

  accordion: {},
  accordionStack: {},
  accordionItem: {},
  accordionButton: {},

  marketLayoutIconFlex: {},
  marketLayoutTooltip: {},
  marketLayoutIcon: {},
  marketLoadingCenter: {},
  centerLoadingSpinner: {},
  marketLayoutLoadedIcon: {},
  marketLayoutAccordionPanel: {},

  cancelButton: {},
  clearButton: {},
};
