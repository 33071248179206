import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  borderRadius: 'base',
  mb: '2',
  flex: '1.3',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const RaceResultsHeader: CSSObject = {
  color: 'white',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, #D09FF8, #A261D6)',
  color: 'alpha.800',
  fontSize: '2xs',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'whiteAlpha.500',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '30px',
  bg: 'blackAlpha.300',
  border: 'none',
  color: 'blackAlpha.600',
  fontWeight: 'bold',
  fontFamily: 'fredoka',
  fontSize: 'lg',
  borderRadius: 'md',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  color: 'white',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
};
