import React from 'react';
import {
  TBlendedMoMBet,
  TMultiMoMBet,
  TSGMultiMoMBet,
  TSRMultiMoMBet,
  TSingleRacingMoMLeg,
  TSingleSportMoMLeg,
} from '../types';
import { MoMSingleSportsCardItem } from './MoMSingleSportsCardItem';
import { MoMSingleRacingCardItem } from './MoMSingleRacingCardItem';
import { MoMSRMCardItem } from './MoMSRMCardItem';
import { MoMSGMCardItem } from './MoMSGMCardItem';
import { MoMBlendedCardItem } from './MoMBlendedCardItem';

interface IMoMCardItemsProps {
  moMBet: TMultiMoMBet;
}

export function MoMCardItems({ moMBet }: IMoMCardItemsProps) {
  return moMBet.legs.map((bet, i) => {
    switch (bet.type) {
      case 'Blended':
        return (
          <MoMBlendedCardItem
            key={i}
            bet={bet as unknown as TBlendedMoMBet}
            hasHeader
          />
        );
      case 'SGMulti':
        return (
          <MoMSGMCardItem
            key={i}
            bet={bet as unknwon as TSGMultiMoMBet}
            hasHeader
          />
        );
      case 'SingleSports':
        return (
          <MoMSingleSportsCardItem
            key={i}
            bet={bet as unknown as TSingleSportMoMLeg}
          />
        );
      case 'SingleRacing':
        return (
          <MoMSingleRacingCardItem
            key={i}
            bet={bet as unknown as TSingleRacingMoMLeg}
          />
        );
      case 'SRMulti':
        return (
          <MoMSRMCardItem
            key={bet.title}
            bet={bet as unknown as TSRMultiMoMBet}
          />
        );
      default:
        return null;
    }
  });
}
