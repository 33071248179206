import { CSSObject } from '@chakra-ui/react';
import topPiston from '@/assets/goldenrush/images/betslip/top-piston.png';
import sidePiston from '@/assets/goldenrush/images/betslip/side-piston.png';

export const GridItemBetslip: CSSObject = {
  h: '97%',
  '& > div[class*="-FlexBetSlipModalContainer"]': {
    position: [null, null, null, null, 'sticky'],
    top: [null, null, null, null, '60px'],
    height: [null, null, null, null, 'calc(100vh - 135px)'],
  },
  '& > div[class*="-BetSlipModalContainer"]': {
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '97%',
    boxShadow: '0px 0px 0px 4px #E57D24 inset, inset 1px 1px 7px black',

    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-10px',
      right: '-160px',
      width: '100%',
      height: '85px',
      zIndex: 'docked',
      bg: `url(${topPiston})`,
      bgRepeat: 'no-repeat',
      bgSize: '90px 30px',
    },

    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '160px',
      left: '-13px',
      width: '30px',
      height: '85px',
      zIndex: 'docked',
      bg: `url(${sidePiston})`,
      bgRepeat: 'no-repeat',
      bgSize: '28px 47px',
    },
  },
};
