export const EVENT_NAMES = [
  'addToBetSlip',
  'login',
  'startedSignUp',
  'signedUp',
  'betPlaced',
  'firstTimeDeposited',
  'deposited',

  // Promo-related events
  'initiatePromo',
  'claimPromoBonus',
  'claimBoost',
  'closePromo',
  'continuePromo',
  'mutePromo',
  'selectPromoOption',
  'skipPromo',
  'unmutePromo',
] as const;

export type TAnalyticEventNames = typeof EVENT_NAMES[number];

export type TAnalyticsFirebaseParams = {
  [key: string]: string;
};
