export const sportNavLinks = [
  {
    name: 'generic.upcoming',
    url: '/sports',
  },
  {
    name: 'generic.sportAZ',
    url: '/sports/All',
  },
];
