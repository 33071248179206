import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'beta.400';
      }
      if (state === 'ended') {
        return 'orange.300';
      }
      // 'prior'
      return 'blackAlpha.500';
    },
    color: () => {
      if (state === 'inprogress') {
        return 'alpha.600';
      }
      if (state === 'ended') {
        return 'alpha.600';
      }
      // 'prior'
      return 'white';
    },
    borderRadius: 'base',
    px: '1',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    textShadow: 'none',
    boxShadow: () => {
      if (state === 'ended') {
        return '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)';
      }
      return 'none';
    },
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
    textTransform: 'capitalize',
  },
});
