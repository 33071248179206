import {
  Box,
  BoxProps,
  ChakraComponent,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  Text,
  TextProps,
  chakra,
} from '@chakra-ui/react';
import { styleImports } from './BtnOfferings.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxWrapper: ChakraComponent<'div', FlexProps> = chakra(Box, {
  baseStyle: () => ({
    mb: ['1', null, '2', '4'],
    mt: '3',
    mx: ['2', null, null, '0'],
    pos: 'relative',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexScrollWrapper: ChakraComponent<'div', FlexProps> = chakra(
  Flex,
  {
    baseStyle: () => ({
      flexDir: 'row',
      p: '2.5',
      gap: '2',
      overflow: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },

      ...(() => {
        try {
          return styleImports[themeName]?.FlexScrollWrapper;
        } catch (err) {
          return {};
        }
      })(),
    }),
  }
);

export const Btn: ChakraComponent<'button', IconButtonProps> = chakra(
  IconButton,
  {
    baseStyle: () => ({
      mb: '1',
      boxSize: '55px',
      borderRadius: 'md',

      ...(() => {
        try {
          return styleImports[themeName]?.Btn;
        } catch (err) {
          return {};
        }
      })(),
    }),
  }
);

export const BtnScroll: ChakraComponent<
  'button',
  IconButtonProps & { isLeft?: boolean; isVisible: boolean }
> = chakra(IconButton, {
  baseStyle: ({ isLeft, isVisible }: any) => ({
    pos: 'absolute',
    top: '-5',
    bottom: '2',
    marginY: 'auto',
    mr: '2',
    zIndex: '99',
    px: '1',
    minW: 'auto',
    opacity: 0,
    transition: 'all 0.2s ease-in-out',

    ...(isVisible && { opacity: 1 }),

    ...(isLeft
      ? {
          left: 0,
        }
      : {
          right: 0,
        }),

    ...(() => {
      try {
        return styleImports[themeName]?.BtnScroll;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Txt: ChakraComponent<'text', TextProps> = chakra(Text, {
  baseStyle: () => ({
    fontSize: '2xs',
    fontWeight: 'semibold',

    ...(() => {
      try {
        return styleImports[themeName]?.Txt;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Separator: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    borderLeft: '1px dashed',
    borderColor: 'beta.600',
    alignSelf: 'stretch',
    my: '-1',

    ...(() => {
      try {
        return styleImports[themeName]?.Separator;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
