import { CSSObject, keyframes } from '@chakra-ui/react';
import bg from '@/assets/betgalaxy/images/backgrounds/betgalaxy_bg.png';

const verticalMove = keyframes`
  from {
    background-position-y: 50%;
  }

  to {
    background-position-y: 1000%;
  }
`;

export const FlexBetsPlacedContainer: CSSObject = {
  flexDir: 'column',
  bg: 'beta.800',
  fontSize: 'sm',
  fontWeight: 'normal',
  color: 'alpha.500',
  h: '74px',
  my: '0',
  animation: `${verticalMove} 30s linear infinite`,
  backgroundBlendMode: 'overlay',
  backgroundImage: `url(${bg})`,
  backgroundSize: 'cover',
};
