import React from 'react';
import { Outlet } from 'react-router-dom';
import Betslip from '../../../components/Betslip/Betslip';
import NextToJumpBanner from '../../../components/NextToJumpBanner/NextToJumpBanner';
import { TTemplate } from '@/helpers/createTemplate';
import {
  GridHeader,
  GridItemBetslip,
  GridItemContent,
  GridItemFooter,
  GridItemResponsibleGambling,
  GridItemSideNav,
  GridLayout,
  GridSubLayout,
} from '../PrimaryLayout.styles';
import SideNav from '../../../components/SideNav/SideNav';
import Footer from '../../../components/Footer/Footer';
import Toggle from '../../../views/Home/conditionals/Toggle/template.slambet';
import Header from '../../../components/Headers/HeaderPrimary/Header';
import Logo from '../../../components/Headers/HeaderPrimary/conditionals/Logo/template';
import ResponsibleGamblingBanner from '../../../components/ResponsibleGamblingBanner/ResponsibleGamblingBanner';

const template = (largerThan992: boolean, isHome: boolean): TTemplate => ({
  wrapper: <GridLayout />,
  header: (
    <GridHeader gridArea="header">
      <NextToJumpBanner />
    </GridHeader>
  ),
  main: {
    wrapper: <GridSubLayout gridArea="main" />,
    sideNav: {
      wrapper: <GridItemSideNav gridArea="sidenav" />,
      logo: largerThan992 ? <Logo /> : <></>,
      toggle: largerThan992 && isHome ? <Toggle /> : <></>,
      content: <SideNav />,
    },
    outlet: (
      <GridItemContent gridArea="content">
        <Outlet />
      </GridItemContent>
    ),
    betslip: (
      <GridItemBetslip gridArea="betslip">
        <Header />
        <Betslip />
      </GridItemBetslip>
    ),
    footer: (
      <GridItemFooter gridArea="footer">
        <Footer />
      </GridItemFooter>
    ),
  },
  responsibleGamblingMessage: (
    <GridItemResponsibleGambling gridArea="responsibleGamblingMessage">
      <ResponsibleGamblingBanner />
    </GridItemResponsibleGambling>
  ),
});

export default template;
