import {
  Box,
  BoxProps,
  chakra,
  ChakraComponent,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { styleImports } from './RaceMeetings.styles.imports';
import IconSvg from '../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const CircularNavButtonContainer = chakra(Box, {
  label: 'raceMeeting-CircularNavButtonContainer',
  baseStyle: () => ({
    textAlign: 'center',
  }),
});

export type TCircularNavButton = {
  selected: boolean;
} & BoxProps &
  any;
export const CircularNavButton = chakra<TCircularNavButton>(Flex, {
  label: 'raceMeeting-CircularNavButton',
  shouldForwardProp: (prop) => !['selected', 'racingType'].includes(prop),
  baseStyle: ({ theme, selected }: any) => ({
    borderRadius: 'full',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    ...(theme.themeName === EThemes.Wellbet && {
      mr: '4',
      color: selected ? 'beta.500' : 'gray.400',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      mr: '4',
      color: selected ? 'currentColor' : 'gray.500',

      '::before': {
        display: 'inline-block',
        content: `""`,
        boxSize: '6',
        bg: 'currentColor',
        mr: '2',
        borderRadius: 'full',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.CircularNavButton?.({ selected });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export type TIconSelected = {
  selected: boolean;
};
export const IconSelected = chakra(Icon, {
  label: 'raceMeeting-IconSelected',
  shouldForwardProp: (prop) => !['selected'].includes(prop),
  baseStyle: ({ theme, selected }: TIconSelected & any) => ({
    boxSize: '5',
    p: '1',
    borderRadius: 'full',
    mr: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: selected ? 'beta.500' : 'gray.400',
      color: selected ? 'white' : 'transparent',
    }),

    ...(() => {
      try {
        return (
          styleImports[themeName]?.IconSelected?.({
            selected,
          }) ?? null
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', { selected: boolean }>;

export const IconRace = chakra(IconSvg, {
  label: 'raceMeeting-IconRace',
  baseStyle: ({ theme }) => ({
    boxSize: '7',
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'currentColor',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.IconRace;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CircularNavButtonTitle = chakra(Box, {
  label: 'raceMeeting-CircularNavButtonTitle',
  baseStyle: ({ theme }) => ({
    fontSize: '2xs',

    ...(theme.themeName === EThemes.Wellbet && {
      display: 'none',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      display: 'none',
    }),
  }),
});

export const RaceMeetingsBreadcrumbWrapper = chakra(Box, {
  label: 'raceMeeting-RaceMeetingsBreadcrumbWrapper',
  baseStyle: () => ({
    mb: '5',
  }),
});

export const NextToJumpListWrapper = chakra(Box, {
  label: 'raceMeeting-NextToJumpListWrapper',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      mx: ['-2', '-5'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.NextToJumpListWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceMeetingsList = chakra(Box, {
  label: 'raceMeeting-RaceMeetingsList',
  baseStyle: () => ({
    px: ['0', '4'],

    ...(() => {
      try {
        return styleImports[themeName]?.RaceMeetingsList;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
