import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  mx: '0',
  borderTop: 'none',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  px: '2',
  py: '2',
  borderRadius: 'lg',
  gap: '1',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
};

export const RacerName: CSSObject = {
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
  color: 'white',
  fontWeight: 'bold',
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'white',
  position: 'relative',
  top: '-0.5',
  fontWeight: 'normal',
  fontSize: '10px',
};
