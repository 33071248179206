import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';
import koi from '@/assets/goldenbet888/images/sport/koi.png';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'gamma.600',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    py: '3px',
    px: '1px',
    backgroundImage: `url(${koi}), linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-500))`,
    boxShadow:
      '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset',
    borderRadius: 'md',
    overflow: 'hidden',
    backgroundPosition: '-370px 0px',
    bgSize: '364px',
    bgBlendMode: 'overlay',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    mb: '1px',
    mx: '0.5',
    _first: {
      borderTopRadius: 'md',
    },
    _last: {
      mb: '0',
      borderBottomRadius: 'md',
    },
    pos: 'relative',
    _notFirst: {
      borderTopColor: 'rgba(0, 0, 0, 0.25)',
      borderTopStyle: 'dotted',
      borderTopWidth: '1px',
    },
    _before: {
      mx: '1',
      border: 'inherit',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
  },
  accordionHeadingText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
    color: 'gamma.200',
    fontFamily: 'accentMusashi',
    letterSpacing: '1px',
    fontSize: 'sm',
  },
  accordionIcon: {
    color: 'gamma.600',
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
  },
};
