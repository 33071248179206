import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  color: 'gamma.600',
  fontWeight: 'black',
  fontSize: 'xl',
  fontFamily: 'CinzelDecorative',
};

export const TextSecondary: CSSObject = {
  color: 'gamma.600',
};
