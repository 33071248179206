import React from 'react';
import { Box, BoxProps, CSSObject, Icon, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';

export const SignUpDatePicker: CSSObject = {
  zIndex: '4',
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'var(--bc-gray-500, #718096)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
  mb: ['4', null, '5'],
  pt: [null, null, '1'],
  gap: '5px',
};

export const FormStack = (props: StackProps) => (
  <Box
    {...props}
    display={['unset', null, 'flex']}
    gap={['unset', null, '2']}
  />
);

export const SignUpInput: CSSObject = {
  borderRadius: '4px',
};

export const addressWrapperProps: BoxProps = {
  sx: {
    '& .chakra-form-control': {
      mb: '2',
    },
  },

  mb: '4',
};

export const SignupAcknowledgementButton: CSSObject = {
  color: 'alpha.400',
  fontWeight: 'bold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  mb: 0,
  bgColor: 'beta.400',
  color: 'alpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  fontFamily: 'Bowlby One',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  _hover: {
    bg: 'beta.600',
    color: 'alpha.800',
  },
};

export const BoxManualAddressContainer: CSSObject = {
  p: '4',
  pb: '0',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: '4px',
};

export const CheckboxHelpText: CSSObject = {
  color: 'gray.500',
  button: {
    h: 'unset',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: 'alpha.400',
  color: 'white',
  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-alpha.400) !important'
      : 'var(--bc-colors-alpha-400) !important',
  },
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active ? ['alpha.900', null, 'white'] : 'alpha.900',
});

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="0.5" />
);
