import { ButtonProps, Center, Text, chakra } from '@chakra-ui/react';
import { styleImports } from './LifetimeExclusion.styles.imports';
import { Button } from '@/components/Button/Button';

import { getThemeName } from '@/helpers/getThemeConfig';
import Checkbox from '@/components/FormElements/Checkbox';

const themeName = getThemeName();

export const buttonSubmitExclusionProps: ButtonProps = {
  'data-testid': 'buttonSubmitExclusionProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonSubmitExclusionProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonLifeTimeExclusionProps: ButtonProps = {
  'data-testid': 'buttonLifeTimeExclusionProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonLifeTimeExclusionProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const ButtonExclusion = chakra(Button, {
  baseStyle: () => ({
    width: '100%',
    bg: 'red.500',
    color: 'white',

    '&:hover, &:focus, &:active': {
      bg: 'red.600',
      color: 'white',
      boxShadow: 'none',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonExclusion;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextTitle = chakra(Text, {
  baseStyle: () => ({
    color: 'black',
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexConfirmation = chakra(Center, {
  baseStyle: () => ({
    bg: 'white',
    h: '500px',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexConfirmation;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CheckboxExclusion = chakra(Checkbox, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.CheckboxExclusion;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
