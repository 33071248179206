import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};

export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  bg: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
        return 'green.500';
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'beta.400';
      case EGreenIDStatus.PENDING:
        return 'yellow.500';
      default:
        return 'red.500';
    }
  },
  border: '1px',
  borderRadius: 'base',
  boxShadow: '0 3px 3px rgba(0, 0, 0, .15)',
  fontSize: '2xs',
  fontWeight: 'semibold',
  letterSpacing: [null, 'tighter'],
  h: '5',
  mb: ['1', null, '0'],
  px: '1',
  textTransform: 'none',

  _hover: {
    bg: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'beta.600';
        case EGreenIDStatus.PENDING:
          return 'yellow.600';
        default:
          return 'red.600';
      }
    },
  },

  _disabled: {
    bg: 'beta.400',
    boxShadow: '0 3px 3px rgba(0, 0, 0, .15)',
    cursor: 'default',
    opacity: '1',

    _hover: {
      bg: 'beta.400',
    },
  },

  '.chakra-button__icon': {
    ml: '0.5',
  },

  '.chakra-icon': {
    boxSize: '3',
  },
});
