import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logo from '@/assets/buffalobet/images/error/logoErr.svg';
import Title from '../components/Title';
import Description from '../components/Description';
import ButtonRefresh from '../components/ButtonRefresh';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (isMaintenance: boolean): TTemplate => ({
  wrapper: <FlexPageWrapper />,
  content: {
    wrapper: <MaintenanceContainer isMaintenance={isMaintenance} />,
    logo: (
      <Img
        src={logo}
        alt={REACT_APP_THEME_NAME}
        alignSelf="center"
        w="320px"
        mt="2"
        mb="0"
      />
    ),
    title: (
      <Flex direction="column" alignItems="center">
        <Title />
      </Flex>
    ),
    desc: <Description />,
    btn: <ButtonRefresh />,
    body: (
      <Global styles={{ body: { background: 'var(--bc-colors-alpha-500)' } }} />
    ),
  },
});

export default template;
