import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { TCloseIcon } from './Header.styles';

export const closeIcon: TCloseIcon = {
  boxSize: '6',
  as: CloseCircle,
};

export const CloseIconButton: CSSObject = {
  justifyContent: 'end',
};

export const FlexBetslipHeading: CSSObject = {
  color: 'white',
  h: '44px',
  px: '0',
  mx: '6',
  borderBottomColor: 'epsilon.500',
  borderBottom: '2px',
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontFamily: 'caveman',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  color: 'white',
  mt: ['1', '0'],
};

export const BetLengthIndicator: CSSObject = {
  bg: 'gamma.500',
  color: 'alpha.900',
  fontSize: 'xs',
  borderRadius: 'base',
  fontFamily: 'Roboto',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'white',
};

export const BetslipTitleText: CSSObject = {
  pt: '1',
  fontFamily: 'caveman',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};
