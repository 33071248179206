import {
  AccordionButton as ChakraAccordionButton,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel as ChakraAccordionPanel,
  AccordionButtonProps,
  Box,
  chakra,
  ChakraComponent,
  AccordionItemProps,
  AccordionPanelProps,
  Text,
  BoxProps,
} from '@chakra-ui/react';
import { styleImports } from './MarketsByMatch.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

//--
export const TextDisclaimer = chakra(Text, {
  label: 'marketsByMatch-TextDisclaimer',
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'normal',
    ml: '1',
  }),
});

// --
export const TextStartTime = chakra(Text, {
  label: 'marketsByMatch-TextStartTime',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'xs',
  }),
});

//--

export const BoxWrapper = chakra(Box, {
  label: 'marketsByMatch-BoxWrapper',
  baseStyle: () => ({
    px: ['0', '3'],
    pb: ['2', '4'],
    mt: '-1',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

// --

export type TAccordionItem = AccordionItemProps & { isNested?: boolean };
export const AccordionItem = chakra(ChakraAccordionItem, {
  shouldForwardProp: (prop) => !['isNested'].includes(prop),
  label: 'marketsByMatch-AccordionItem',
  baseStyle: ({ isNested }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.AccordionItem?.({ isNested });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TAccordionItem>;

export type TAccordionButton = AccordionButtonProps & { isNested?: boolean };
export const AccordionButton = chakra(ChakraAccordionButton, {
  shouldForwardProp: (prop) => !['isNested'].includes(prop),
  label: 'marketsByMatch-AccordionButton',
  baseStyle: ({ isNested }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.AccordionButton?.({ isNested });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', TAccordionButton>;

export type TAccordionPanel = AccordionPanelProps & { isNested?: boolean };
export const AccordionPanel = chakra(ChakraAccordionPanel, {
  label: 'AccordionPanel',
  shouldForwardProp: (prop) => !['isNested'].includes(prop),
  baseStyle: ({ isNested }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.AccordionPanel?.({ isNested });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TAccordionPanel>;
