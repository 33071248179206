import { CSSObject } from '@chakra-ui/react';

export const wrapperProps: CSSObject = {
  bg: 'alpha.900',
  h: '100%',
  minW: ['unset', null, '460px'],
  display: 'flex',
  justifyContent: 'center',
  borderRightRadius: ['unset', null, 'lg'],
  color: 'white',

  sx: {
    'div:first-child': {
      display: 'flex',
      flexDir: 'column',
      fontSize: 'xs',
      fontWeight: 'medium',
      alignItems: 'flex-start',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mt: '-1px',
      },
      '.chakra-link': {
        mb: '4',
        color: 'gamma.300',
      },
      p: {
        color: 'white',
        fontWeight: 'normal',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      },
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'body',
  fontWeight: 'semibold',
  mb: '2',

  '+ a': {
    display: 'none',
  },
};

export const linkLoginProps: CSSObject = {
  color: 'gamma.300',
};

export const ForgotPasswordButton: CSSObject = {
  fontWeight: 'normal',
  color: 'alpha.700',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  lineHeight: 'normal',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, gamma.200, gamma.200)',
    color: 'alpha.700',
  },
};
