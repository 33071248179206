import { TextProps, BoxProps } from '@chakra-ui/react';
import { styleImports } from './Promos.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type PromosStylesSchema = Partial<{
  body: TextProps;
  container: BoxProps;
}>;

export const promosBaseStyles: PromosStylesSchema = {
  body: {
    label: 'promos-body',
    px: '4',
    py: '2',
    color: 'white',
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.promosStyles?.body;
      } catch (err) {
        return {};
      }
    })(),
  },
  container: {
    label: 'promos-container',
    ...(() => {
      try {
        return styleImports[themeName]?.promosStyles?.container;
      } catch (err) {
        return {};
      }
    })(),
  },
};
