import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { sendResetPasswordLink } from '../../../services/onboarding.actions';
import { recordResetPasswordAction } from '@/lib/api/activityLogging/actions';
import { getStrings } from '@/helpers/utils';
import InlineLink from '@/components/InlineLink';
import forgotPasswordSchema, {
  ForgotPasswordSchema,
} from '../../../services/schemas/forgotPasswordSchema';
import { AppDispatch } from '@/redux/store';
import { ACTIVITY_LOG_SUMMARY, ACTIVITY_LOG_TYPE } from '@/lib/Constants';
import {
  ForgotPasswordButton,
  TextForgotPasswordSuccess,
} from '../../styles/ForgotPassword.styles';
import ContentHeading from '../../conditionals/ContentHeading/template';
import Input from '@/components/FormElements/Input';

export default function Content() {
  const dispatch: AppDispatch = useDispatch();

  const [
    {
      Onboarding: { ForgotPassword: ForgotPasswordStrings },
      Generic,
    },
  ] = getStrings();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [sentAddress, setSentAddress] = useState('');

  const handleFormSubmit = async (
    { email }: ForgotPasswordSchema,
    { setSubmitting }: FormikHelpers<ForgotPasswordSchema>
  ) => {
    setSubmitting(true);
    await sendResetPasswordLink(email).then(async (success) => {
      setEmailSuccess(success);
      setSentAddress(email);
      await dispatch(
        recordResetPasswordAction({
          action_type: ACTIVITY_LOG_TYPE.RESET_PASSWORD,
          action_summary:
            ACTIVITY_LOG_SUMMARY[ACTIVITY_LOG_TYPE.RESET_PASSWORD],
          email,
          firebase_project_name:
            window.BETCLOUD_ENV.REACT_APP_FIREBASE_PROJECTID || '',
        })
      );
    });
    setSubmitting(false);
  };

  return (
    <>
      {emailSuccess ? (
        <>
          <TextForgotPasswordSuccess>
            {ForgotPasswordStrings.SuccessText}
            {sentAddress}
          </TextForgotPasswordSuccess>

          <InlineLink path="/login">
            {ForgotPasswordStrings.LoginLink}
          </InlineLink>
        </>
      ) : (
        <>
          <ContentHeading />

          <Formik<ForgotPasswordSchema>
            initialValues={{
              email: '',
            }}
            validationSchema={forgotPasswordSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleFormSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              setErrors,
              isSubmitting,
              touched,
              errors,
            }) => (
              <>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  error={
                    touched.email && errors.email ? errors.email : undefined
                  }
                  label={Generic.Email}
                  onChange={(e) => {
                    handleChange(e);
                    setErrors({});
                  }}
                  data-cy="emailTextInput"
                  isFormik={false}
                  allowInvalidKeys
                />

                <ForgotPasswordButton
                  data-cy="forgotPasswordButton"
                  onClick={() => handleSubmit()}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  isFullWidth
                >
                  <FormattedMessage id="onboarding.forgotpassword.button" />
                </ForgotPasswordButton>
              </>
            )}
          </Formik>
        </>
      )}
    </>
  );
}
