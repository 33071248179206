import React from 'react';
import { Global } from '@emotion/react';
import bg from '@/assets/volcanobet/images/backgrounds/main.png';
import bgMobile from '@/assets/volcanobet/images/backgrounds/mainMobile.png';
import footer from '@/assets/volcanobet/images/backgrounds/footer.png';
import footerDesktop from '@/assets/volcanobet/images/backgrounds/footer-desktop.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${footer}), url(${bgMobile})`,
          backgroundPosition: 'right 0px bottom -165px',
          backgroundRepeat: 'no-repeat, repeat',
          backgroundSize: '100%',

          '@media (min-width: 480px)': {
            backgroundImage: `url(${footerDesktop}), url(${bg})`,
            backgroundPosition: 'center bottom 30px, top',
            backgroundSize: '1750px ,1500px',
            backgroundRepeat: 'repeat-x',
            backgroundColor: '#663101',
          },
        },
      }}
    />
  );
}
