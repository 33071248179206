import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  alignItems: 'center',
  borderBottom: '2px solid',
  borderBottomColor: 'rgba(125, 253, 42, 1)',
  h: '50px',
  bgGradient: 'linear(to-b, alpha.800, alpha.900)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.2)',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '6',
  color: 'beta.500',
};
