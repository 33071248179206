import { CSSObject } from '@chakra-ui/react';

export const BoxStyledLink: CSSObject = {
  color: 'gamma.600',
  fontWeight: 'bold',
};

export const BoxTakeABreak: CSSObject = {
  border: 'none',
  mb: '0',
};

export const TextS: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  _first: {
    fontSize: 'sm',
  },
};

export const TextM: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  mb: '3',
};

export const ListItemExclusionItem: CSSObject = {
  mb: '3',
};

export const TextInformation: CSSObject = {
  fontSize: 'xs',
  'p:nth-of-type(1)': {
    fontWeight: 'bold',
  },
  'p:nth-of-type(2)': {
    fontWeight: 'normal',
  },
};

export const BoxTakeABreakWrapper: CSSObject = {
  border: 'none',
  'p:nth-of-type(1)': {
    fontWeight: 'semibold',
  },
};

export const InputContainer: CSSObject = {
  fontWeight: 'medium',
  '#period-select': {
    border: 'none',
    color: 'gamma.200',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  },
  '.chakra-select__icon-wrapper': {
    color: 'gamma.600',
  },
  option: {
    color: 'black',
  },
};

export const TakeABreakContainer: CSSObject = {
  fontSize: 'sm',
  border: 'none',
  mb: '1',
};

export const TextDateLabel: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const TextDate: CSSObject = {
  fontWeight: 'bold',
  color: 'gamma.600',
  fontSize: 'md',
};

export const TextConfirmBreak: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
};
