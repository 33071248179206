import {
  Box,
  ButtonProps,
  chakra,
  ChakraComponent,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { styleImports } from './Filters.styles.imports';
import Checkbox, { TCheckbox } from '../../../../FormElements/Checkbox';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

/**
 * Wrapper for the button and filter dropdown
 */
export const BoxWrapper = chakra(Box, {
  baseStyle: ({ theme }) => ({
    pos: 'relative',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'transparent',
      h: 'full',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Button style
 */
export const ButtonDropdown = chakra(Button, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ theme }: any) => ({
    display: 'inline-flex',
    pos: 'relative',
    fontSize: 'xs',
    fontWeight: 'bold',
    p: '1',
    textTransform: 'uppercase',
    border: '2px',
    borderRadius: 'sm',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.600',
      borderColor: 'alpha.500',
      color: 'white',
      h: 'full',

      _hover: {
        bg: 'alpha.800',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'beta.600',
      color: 'white',
      borderColor: 'beta.400',
      h: 'full',

      _hover: { bg: 'beta.300' },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonDropdown;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps & { isActive: boolean }>;

/**
 * Dropdown that contains the filters
 */
export const BoxDropdown = chakra(Box, {
  baseStyle: ({ theme }) => ({
    p: '4',
    borderRadius: 'sm',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'alpha.600',
      zIndex: 'docked',
      h: 'full',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: rgba(theme.colors.beta[500] as string, 0.98),
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxDropdown;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Filters titles
 */
export const TextTitle = chakra(Text, {
  baseStyle: ({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 'sm',
    mb: '2',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

/**
 * Filter checkbox styles
 */
export const CheckboxFilters = chakra(Checkbox, {
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
      fontWeight: 'bold',

      '.chakra-checkbox__control': {
        bg: 'white',
        color: 'black',
        border: 'transparent',
        '&:hover, &:focus': { bg: 'white' },
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.CheckboxFilters;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'input', TCheckbox>;
