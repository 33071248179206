import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'alpha.500',
  h: '100%',
  maxH: '100dvh',
  borderRadius: 'lg',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '8px',
  mx: '3px',
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipContainer: BoxProps = {
  borderRadius: 'base',
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.100',
  fontFamily: 'Roboto',
  fontSize: 'sm',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'beta.500',
  fontFamily: 'Roboto',
  fontSize: 'sm',
});

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'secondary',
  height: '6',
  width: '74px',
  margin: 'auto',
  mt: '-5',
  w: 'auto',
  pl: 1,
  pr: 2,
  fontWeight: 'normal',
};

export const BetslipVStack: CSSObject = {
  gap: '2.5',
};

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  height: '42px',
  sx: {
    '&&': {
      boxShadow:
        '0px 0px 0px 1px #000 inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset',
      fontWeight: 'normal',
    },
  },
};
