import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'gray.300',
  borderStyle: 'dashed',
};

export const TextDescription: CSSObject = {
  color: 'white',
};

/**
 * Form Inputs
 */
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
};

export const InputWithdrawal: CSSObject = {
  bg: 'gray.50',
  border: '1px solid',
  borderColor: 'gray.300',
  borderRadius: 'sm',
  fontSize: 'sm',
  height: '38px',

  _placeholder: {
    color: 'beta.700',
  },
};

/**
 * Buttons
 */
export const buttonAddAccountProps: CustomButtonProps = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    color: 'gamma.600',
  },
  _focus: {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    color: 'gamma.600',
  },

  _active: {
    bg: 'gamma.700',
    color: 'white',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  },
};

export const ButtonWithdrawal: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    color: 'gamma.600',
  },

  _active: {
    bg: 'gamma.700',
    color: 'white',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  },
};

export const ButtonIncrease: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    color: 'gamma.600',
  },

  _active: {
    bg: 'gamma.700',
    color: 'white',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const BankRadio: CSSObject = {
  alignItems: 'start',
  border: 'none',
  bg: 'gray.400',
  boxShadow: 'inset 0px 2px 4px rgba(49, 57, 77, 0.82)',

  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'alpha.400',
    _hover: {
      bg: 'alpha.400',
    },
  },

  '&[data-checked]:before': {
    display: 'flex',
    alignSelf: 'center',
    width: '40%',
    height: '40%',
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '0',
  pt: '0',
  w: ['100%', null, '330px'],
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

/**
 * Withdrawal Request Card
 */
export const WithdrawalWrapper: CSSObject = {
  bg: 'white',
  color: 'white',
  fontSize: 'sm',
  mt: ['4', null, '0'],
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
  border: 'none',
};

export const WithdrawalCardDetailHeading: CSSObject = {
  fontSize: 'sm',
  color: 'gray.700',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  color: 'gray.700',

  '.withdrawName': {
    fontSize: 'xs',
  },
};

export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  color: 'gray.500',
  fontSize: 'xs',
};

export const WithdrawalCardDetailRequested: CSSObject = {
  fontWeight: '700',
  color: 'gray.700',
  fontSize: 'xs',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  borderRadius: 'base',
  border: '2px solid',
  borderColor: 'beta.400',
  bg: 'beta.600',
  color: 'blackAlpha.600',
  textShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.35)',
  boxShadow:
    '0px 4px 5px 0px rgba(0, 0, 0, 0.25), 0px 4px 5px 0px rgba(0, 0, 0, 0.15) inset',
  fontWeight: 'black',
  textTransform: 'uppercase',

  '&&': {
    fontSize: 'xs',
  },

  '&:hover, &:active': {
    bg: 'beta.700',
    color: 'blackAlpha.600',
    boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.25)',
    borderColor: 'transparent',
  },
};

export const WithdrawalStatus = ({ status }: any): CSSObject => ({
  borderRadius: 'base',
  px: '2',
  color: 'yellow.800',
  textTransform: 'uppercase',
  fontSize: 'xs',
  bg: () => {
    switch (status) {
      case EWithdrawalStatus.Cancelled:
        return 'gray.500';
      case EWithdrawalStatus.Error:
        return 'red.500';
      case EWithdrawalStatus.Pending:
        return 'yellow.500';
      case EWithdrawalStatus.Processed:
        return 'yellow.400';
      case EWithdrawalStatus.Rejected:
        return 'red.500';
      default:
        return 'green.500';
    }
  },
});

export const Subheading: CSSObject = {
  color: 'white',
};
