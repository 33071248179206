import RobotoBlack from '@/assets/betroyale/fonts/Roboto-Black.ttf';
import RobotoBlackItalic from '@/assets/betroyale/fonts/Roboto-BlackItalic.ttf';
import RobotoBold from '@/assets/betroyale/fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from '@/assets/betroyale/fonts/Roboto-BoldItalic.ttf';

import RobotoExtraLight from '@/assets/betroyale/fonts/Roboto-Thin.ttf';
import RobotoExtraLightItalic from '@/assets/betroyale/fonts/Roboto-ThinItalic.ttf';
import RobotoLight from '@/assets/betroyale/fonts/Roboto-Light.ttf';
import RobotoLightItalic from '@/assets/betroyale/fonts/Roboto-LightItalic.ttf';
import RobotoRegular from '@/assets/betroyale/fonts/Roboto-Regular.ttf';
import RobotoItalic from '@/assets/betroyale/fonts/Roboto-Italic.ttf';
import RobotoSemiBold from '@/assets/betroyale/fonts/Roboto-Medium.ttf';
import RobotoSemiBoldItalic from '@/assets/betroyale/fonts/Roboto-MediumItalic.ttf';

import RobotoCondensedLight from '@/assets/betroyale/fonts/RobotoCondensed-Light.ttf';
import RobotoCondensedLightItalic from '@/assets/betroyale/fonts/RobotoCondensed-LightItalic.ttf';
import RobotoCondensedItalic from '@/assets/betroyale/fonts/RobotoCondensed-Italic.ttf';
import RobotoCondensedRegular from '@/assets/betroyale/fonts/RobotoCondensed-Regular.ttf';
import RobotoCondensedBold from '@/assets/betroyale/fonts/RobotoCondensed-Bold.ttf';
import RobotoCondensedBoldItalic from '@/assets/betroyale/fonts/RobotoCondensed-BoldItalic.ttf';

import SpectralBold from '@/assets/betroyale/fonts/Spectral-Bold.ttf';
import SpectralBoldItalic from '@/assets/betroyale/fonts/Spectral-BoldItalic.ttf';
import SpectralExtraBold from '@/assets/betroyale/fonts/Spectral-ExtraBold.ttf';
import SpectralExtraBoldItalic from '@/assets/betroyale/fonts/Spectral-ExtraBoldItalic.ttf';

import SpectralLight from '@/assets/betroyale/fonts/Spectral-Light.ttf';
import SpectralLightItalic from '@/assets/betroyale/fonts/Spectral-LightItalic.ttf';
import SpectralRegular from '@/assets/betroyale/fonts/Spectral-Regular.ttf';
import SpectralItalic from '@/assets/betroyale/fonts/Spectral-Italic.ttf';
import SpectralSemiBold from '@/assets/betroyale/fonts/Spectral-Medium.ttf';
import SpectralSemiBoldItalic from '@/assets/betroyale/fonts/Spectral-MediumItalic.ttf';

export const fontFace = `
  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${RobotoExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${RobotoExtraLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${RobotoLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${RobotoLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${RobotoRegular}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${RobotoItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${RobotoSemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${RobotoSemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${RobotoBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${RobotoBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${RobotoBlack}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${RobotoBlackItalic}') format('opentype');
  }

  @font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${RobotoCondensedLight}') format('opentype');
  }
  @font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${RobotoCondensedLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${RobotoCondensedRegular}') format('opentype');
  }
  @font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${RobotoCondensedItalic}') format('opentype');
  }

  @font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${RobotoCondensedBold}') format('opentype');
  }
  @font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${RobotoCondensedBoldItalic}') format('opentype');
  }
  
  // Spectral family
  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${SpectralLight}') format('opentype');
  }
  @font-face {
    font-family: 'Spectral';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${SpectralLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${SpectralRegular}') format('opentype');
  }
  @font-face {
    font-family: 'Spectral';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${SpectralItalic}') format('opentype');
  }

  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${SpectralSemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'Spectral';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${SpectralSemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${SpectralBold}') format('opentype');
  }
  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('${SpectralExtraBold}') format('opentype');
  }
  @font-face {
    font-family: 'Spectral';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${SpectralBoldItalic}') format('opentype');
  }
  @font-face {
    font-family: 'Spectral';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('${SpectralExtraBoldItalic}') format('opentype');
  }

`;
