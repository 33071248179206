import { css } from 'styled-components';

export const container = css``;

export const titleContainer = css``;

export const subtitleContainer = css``;

export const textContainer = css``;

export const title = css``;

export const subtitle = css``;

export const text = css``;

export const backgroundImage = css``;

export const overlayImage = css``;
