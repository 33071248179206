import { useState, useEffect, useRef } from 'react';

const useInterval = (
  callback: () => Promise<void>,
  delay: number | null
): [boolean] => {
  const [loading, setLoading] = useState(false);
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = async () => {
      setLoading(true);
      await callback?.();
      setLoading(false);
    };
  }, [callback]);

  useEffect(() => {
    const intervalAction = () => {
      savedCallback.current?.();
    };
    if (delay !== null) {
      const id = setInterval(intervalAction, delay);
      return () => clearInterval(id);
    }
  }, [delay]);

  return [loading];
};

export default useInterval;
