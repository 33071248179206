import React from 'react';
import { ListSectionProps } from './types';
import {
  BoxSectionWrapper,
  BoxSportWrapper,
  LinkItem,
  TextTitle,
  IconSport,
  TextName,
  IconChevron,
} from '../../AllSports/styles/AllSports.styles';

export default function ListSection({ sportsGrouping }: ListSectionProps) {
  return (
    <BoxSectionWrapper>
      <TextTitle>{sportsGrouping?.letter?.toUpperCase()}</TextTitle>

      <BoxSportWrapper>
        {sportsGrouping?.sports?.map((sport) => (
          <LinkItem
            key={sport?.sport_id}
            to={`/sports/${encodeURIComponent(
              sport?.display_name ?? ''
            )}?sportId=${sport?.sport_id}`}
            data-cy={`sport-selection-${sport?.display_name}`}
          >
            <IconSport
              name={
                String(`sports/${sport?.display_name ?? ''}`)
                  .replace('/ /g', '_')
                  .toLowerCase() ?? ''
              }
            />

            <TextName>{sport?.display_name ?? ''}</TextName>

            <IconChevron />
          </LinkItem>
        ))}
      </BoxSportWrapper>
    </BoxSectionWrapper>
  );
}
