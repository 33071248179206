import React from 'react';
import { Global } from '@emotion/core';
import bg from '@/assets/goldenrush/images/backgrounds/bgHead.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bg}), linear-gradient(180deg, #4E2A1D, #4E2A1D)`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundAttachment: 'fixed',
        },
      }}
    />
  );
}
