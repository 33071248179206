import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  mx: '0',
  px: '2',
  borderRadius: 'md',
  bg: 'white',
  p: '2.5',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px #000 inset',
  border: '2px',
  borderColor: '#FFE6B6',
  flex: '1',
};

export const SelectFieldContainer: CSSObject = {
  mb: '2',
  gap: '1',
  w: 'unset',
};

export const CheckboxBoxed: CSSObject = {
  bg: 'white',
  color: 'gray.700',

  justifyContent: 'flex-end',
  mt: '0',
  mr: '1',
  '.chakra-checkbox__control': {
    mt: '2',
    mb: '1',
  },
  '.chakra-checkbox__label': {
    mt: '-1',
  },
};

export const TextBoxed: CSSObject = {
  color: 'black',
  fontSize: 'sm',
};
