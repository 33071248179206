import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
};
export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  borderBottomLeftRadius: 'none',
  borderBottomRightRadius: 'none',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  textTransform: 'uppercase',
  mb: '1',
};
export const EachWayText: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
};
export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px solid',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
};
export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  color: 'white',
  fontFamily: 'accent',
};
export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'beta.400',
  fontWeight: 'bold',
  fontFamily: 'accent',
};
export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'accent',
};
export const ReviewEst: CSSObject = {
  fontSize: 'lg',
  color: 'beta.400',
  fontFamily: 'accent',
  fontWeight: 'bold',
};
export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  my: 'auto',
  fontWeight: 'bold',
  fontSize: 'sm',
  color: 'beta.500',
  fontFamily: 'accent',
  bottom: '0',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  border: 'none',
  borderLeftRadius: isPrefix ? 'base' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'base',
  h: '40px',
});
export const InputBetSlip: CSSObject = {
  border: 'none',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  h: '38px',
  pl: '2.8rem',
  m: '0.5',
  ':first-child': {
    pl: '2',
  },
  '&&': {
    borderRightRadius: 'base',
    borderLeftRadius: 'base',
    bg: 'alpha.700',
  },
  _hover: {
    bg: 'alpha.800',
    color: 'alpha.200',
  },
  _focus: {
    bg: 'alpha.800',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.3)',
  },
};
export const FlexiReview: CSSObject = {
  pl: '8',
};
export const FlexReviewExotic: CSSObject = {
  textAlign: 'left',
  "[class$='ReviewStake']": {
    color: 'alpha.200',
  },
};
