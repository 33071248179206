import { CSSObject } from '@chakra-ui/react';

export const SubHeading: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontSize: 'sm',
};

export const BoxBordered: CSSObject = {
  px: '2',
  textColor: 'black',
  maxW: 'container.md',
  pt: '0',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const SmallHeading: CSSObject = {
  mt: '2',
  color: 'white',
  fontSize: 'sm',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const ImageGamblersHelp: CSSObject = {
  w: '168px',
  minW: '168px',
  bg: 'white',
  padding: '5px',
  borderRadius: 'md',
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr',
  maxW: 'container.sm',
  gap: '2.5',
};

export const Link: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mx: '2',
};

export const ButtonAction: CSSObject = {
  h: '38px',
  px: '2.5',
  py: '2.5',
  color: 'beta.400',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  border: 'none',
  position: 'relative',
  boxShadow:
    '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontFamily: 'Fraunces',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.55)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    bgGradient: 'linear(to-b, gamma.500, gamma.700)',
    boxShadow: '0px 0px 0px 2px rgba(20, 19, 75, 1) inset',
    zIndex: 8,
    borderRadius: 'md',
    m: '2px',
    opacity: 1,
  },
  _hover: {
    _after: {
      bgGradient: 'linear(to-b, gamma.700, gamma.700)',
    },
  },
  ':active, &[data-active="true"]': {
    bgGradient: 'linear(to-b, gamma.700, gamma.700)',
    boxShadow:
      '0px 1px 0px 0px rgba(165, 191, 240, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.6) inset',
    color: '#9F9CFD',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    _after: {
      display: 'none',
    },
  },
  _disabled: {
    pointerEvent: 'none',
    opacity: 0.4,
    boxShadow:
      '0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.35)',
    _after: {
      borderRadius: 'md',
      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    },
  },
};
