import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  name: {
    color: 'black',
  },
  grid: {
    pb: '0',
  },
  row: {
    pb: '2',
    borderBottomWidth: '1px',
    borderColor: 'blackAlpha.400',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
  },
};
export default styles;
