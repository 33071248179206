import evenshot from '@/assets/buffalobet/images/backgrounds/evenshot.jpg';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    mb: '4',
    fontWeight: 'normal',
    fontSize: 'lg',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    textTransform: 'capitalize',
    fontFamily: 'Staatliches',
  },
  flexWrapper: {
    mx: '0',
    bg: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    flexDir: 'column',
    color: 'white',
    p: '4',
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: ['26px', '30px'],
    fontFamily: 'Staatliches ',
    fontWeight: 'normal',
    textAlign: 'center',
    color: 'beta.500',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  addToBetslipButton: {
    variant: 'primary',
    sx: {
      '&&': {
        bgGradient: 'linear(180deg, alpha.600 0%, alpha.800 100%)',
        _hover: {
          bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
          color: 'beta.900',
        },
      },
    },
  },
  loginToView: {
    variant: 'primary',
    sx: {
      '&&': {
        bgGradient: 'linear(180deg, alpha.600 0%, alpha.800 100%)',
        _hover: {
          bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
          color: 'beta.900',
        },
      },
    },
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'beta.500',
    fontSize: 'lg',
    fontWeight: 'extrabold',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  evenShotImage: {
    boxSize: '292px',
    my: '6',
    borderRadius: 'lg',
    filter: 'drop-shadow(0px 4px 10px gba(0, 0, 0, 0.30))',
    backgroundImage: `url(${evenshot})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  oddsValueSpan: {
    color: 'beta.500',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  subHeadingText: {
    fontSize: 'sm',
    color: 'white',
  },
  notAvailableText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'md',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25',
  },
  comeBackLaterText: {
    color: 'white',
    fontSize: 'md',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25',
  },
  notYetAvailableText: {
    color: 'white',
    fontSize: 'md',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25',
  },
};
