import { FlucsSchema } from './Flucs.styles';

export const flucsStyles: FlucsSchema = {
  wrapper: {
    pos: 'relative',
    alignItems: 'center',
  },
  innerWrapper: {
    alignItems: 'center',
    bg: 'gamma.100',
    width: 'fit-content',
    borderRadius: 'base',
    px: '1',
    py: '2',
    my: '1',
    mr: ['1', '3'],
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: 'xs',
  },
  priceTitle: {
    as: 'p',
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: ['2xs', 'xs'],
    mr: '1',
  },
  priceText: {
    color: 'red.500',
    mr: ['1', '2'],
    fontSize: ['2xs', 'xs'],
    display: 'flex',
    alignItems: 'center',
  },
  priceTextIncreased: {
    color: 'green.600',
  },
  priceIcon: {
    color: 'red.500',
    ml: '1',
  },
  priceIconIncreased: {
    color: 'green.600',
    transform: 'rotate(180deg)',
  },
  lottieWrapper: {
    alignItems: 'center',
    boxSize: '10',
    borderLeft: '1px solid',
    borderColor: ['transparent', null, 'blackAlpha.300'],
    ml: '1',
  },
  tooltipWrapper: {
    borderRadius: 'md',
    bg: '#ED8936',
    p: '3',
    display: 'block',
    position: 'relative',
  },
};
