import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'white',
  minW: 'unset',
  bg: 'beta.400',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  transition: 'all .3s ease-in-out',
  _hover: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    color: 'white',
    bg: 'beta.300',
  },
  _focus: {
    boxShadow:
      ' 0px 2px 3px rgba(0, 0, 0, 0.35), inset 0px 0px 3px rgba(255, 255, 255, 0.6), inset 0px 1px 0px rgba(255, 255, 255, 0.3);',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
};
