import { CSSObject, TextProps } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
  bg: 'red.600',
  p: '1',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px var(--bc-colors-blackAlpha-400), 0px 1px 0px 0px var(--bc-colors-blackAlpha-400) inset',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};
export const FormLabelThemed: CSSObject = {
  color: 'beta.800',
  fontSize: 'xs',
  fontWeight: 'semibold',
  mt: '2',
  fontFamily: 'body',
};

export const FormControlGroup: CSSObject = {
  '.chakra-select__wrapper': {
    '.chakra-select': {
      fontSize: 'xs',
      background: 'gray.50',
      fontWeight: 'bold',
      color: 'gray.600',
      border: '1px solid',
      borderColor: 'gray.300',
      borderRadius: 'md',
      boxShadow: [
        '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset, 0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
        null,
        'none',
      ],
    },
  },
  mb: ['0', null, '3'],
  '>*:not(style)~*:not(style)': {
    mb: ['2', null, '0'],
  },
};
