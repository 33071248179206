import useAxios from 'axios-hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { signUserOut } from '../../../../lib/firebase/Authentication';
import {
  clearPunterOverview,
  clearPunterProfile,
  reset,
} from '../../services/account.slices';
import { AppDispatch } from '../../../../redux/store';
import { TLifeTimeExclusionData } from '../types';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';
import { postMobileAppMessage } from '../../../../mobileapp/mobileapp.utils';
import { ENewRelicActions } from '../../../../constants/newRelicActions';
import { newRelicLog } from '@/lib/newRelic';
import { useAuth } from '@/hooks/useAuth';

export const useLifetimeExclusion = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { punterData } = useAuth();
  const navigate = useNavigate();
  const client = useQueryClient();

  const [{ loading }, lifetimeExclusion] = useAxios<
    null,
    TLifeTimeExclusionData
  >(
    {
      method: 'POST',
      url: '/punter/account/self-exclusion',
    },
    { manual: true, useCache: false }
  );

  const mutate = async () => {
    await lifetimeExclusion({
      data: { period: -1 },
    });

    await signUserOut().then(() => {
      newRelicLog(ENewRelicActions.LogOut, punterData?.punter_id, {
        status_code: 200,
      });
      dispatch(clearPunterProfile());
      dispatch(clearPunterOverview());
      dispatch(reset());
    });

    if (IS_MOBILE_APP) postMobileAppMessage('signout');

    await client.invalidateQueries();

    navigate('/exclusion-confirmed');
  };

  return [mutate, { loading }] as const;
};
