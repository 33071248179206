import { CSSObject } from '@chakra-ui/react';

export const OddsChangedContainer = (isInFooter: boolean): CSSObject => ({
  display: isInFooter ? 'flex' : 'none',
  px: '2.5',
  my: '1',
  minH: '12',
  py: '2',
  mx: '-2',
  alignItems: 'center',
  fontSize: ['sm', null, null, null, 'xs'],
});
