import { useMemo } from 'react';
import { Warning } from '@styled-icons/entypo/Warning';
import { TBadge } from '@/lib/DBModels';

type TUseBadges = {
  badges?: TBadge[];
};

export const useBadges = ({ badges }: TUseBadges) => {
  const data = useMemo(
    () =>
      badges
        ?.map((badge) => {
          const badgeName = Object.keys(badge)[0];
          const badgeValue = badge[badgeName];
          const badgeNameFormatted = badgeName
            .replaceAll(' ', '-')
            .toLowerCase();

          let icon;
          try {
            icon =
              require(`!!@svgr/webpack?-svgo,+titleProp,+ref!@/assets/core/badges/${badgeNameFormatted}.svg`)?.default;
          } catch (error) {
            icon = Warning;
          }

          return {
            title: badgeName,
            description: badgeValue,
            icon,
          };
        })
        .slice(0, 3),
    [badges]
  );

  return { data };
};
