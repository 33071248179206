/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { IntlShape } from 'react-intl';
import { FormikHelpers } from 'formik';
import { TTemplate } from '@/helpers/createTemplate';
import OnboardingWrapper from '../../../../../wrappers/OnboardingWrapper';
import { EOnboardingPageType } from '../../../../../wrappers/OnboardingWrapper/OnboardingWrapper.types';
import FormHOC from '../../components/FormHOC/FormHOC';
import SignUpCTA from '../components/SignUpCTA';
import { LoginSchema } from '../../../services/schemas/loginSchema';
import AutoLogoutNotification from '../../components/AutoLogoutNotification/AutoLogoutNotification';
import Form from '../../components/Form/Form';
import ContentHeading from '../conditionals/ContentHeading/template';
import Aside from '../../../../../components/Aside/Aside';

const template = (
  intl: IntlShape,
  handleFormSubmit: (
    values: LoginSchema,
    { setSubmitting }: FormikHelpers<LoginSchema>
  ) => Promise<void>
): TTemplate => ({
  wrapper: (
    <OnboardingWrapper
      aside={<Aside />}
      pageTitle={intl.formatMessage({ id: 'onboarding.login.pagetitle' })}
      pageType={EOnboardingPageType.Login}
    />
  ),
  contentHeading: <ContentHeading />,
  form: (
    // TODO: explore extracting this to its own component
    <FormHOC onSubmit={handleFormSubmit}>
      <Form />
    </FormHOC>
  ),
  autoLogoutText: <AutoLogoutNotification />,
  signupLink: <SignUpCTA />,
});

export default template;
