import { CSSObject } from '@chakra-ui/react';
import bg from '../../../../../../assets/puntgenie/images/homeFeaturedSports/sport-bg.png';
import bandit from '../../../../../../assets/puntgenie/images/homeFeaturedSports/sport-mascot.png';

export const FlexWrapper: CSSObject = {
  flexDir: ['column-reverse', null, null, 'row'],
  // Have to override all the styles in the base component :-(
  px: '4',
  py: '0',
  pt: '3',
  pl: ['2', null, '4'],
  pr: ['2', null, 0],
  mb: ['3', null, '4'],
  mt: '5',
  mx: ['2', null, 'unset'],
  border: 'none',
  bgPos: 'bottom',
  bgImage: `url('${bg}')`,
  bgRepeat: 'no-repeat',
  bgSize: 'contain',
  alignItems: 'center',
  justifyContent: 'flex-start',
  pos: 'relative',
  contain: 'paint',
  minH: ['250px'],
  borderRadius: 'none',
  borderBottom: '3px solid',
  borderBottomColor: 'delta.400',
};

export const FlexIllustrationWrapper: CSSObject = {
  bgImage: `url(${bandit})`,
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
  bgPos: 'bottom',
  height: '100%',
  width: ['100%', null, null, '75%'],
  bottom: '0',
  right: ['0', null, null, '-15%'],
  display: 'block',
  position: ['relative', null, null, 'absolute'],
  minH: '36',
  mt: ['4', null, null, '0'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  flexDir: 'column',
};

export const FlexTeamWrapper: CSSObject = {
  gap: '1',
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'delta.800',
  boxShadow: 'none',
  justifyContent: 'center',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px rgba(255, 255, 255, 0.4), inset 0px 2px 4px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'transparent',
  bgClip: 'text',
  textShadow: 'none',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'md',
  textShadow: 'none',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: 'inherit',
  m: 'inherit',
  w: 'auto',
};

export const FlexTextWrapper: CSSObject = {
  mt: '2',
};

export const HeadingSlogan: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  fontFamily: 'CinzelDecorative',
  fontWeight: 'black',
  color: 'white',
  textTransform: 'uppercase',
  fontSize: ['2xl', null, '4xl'],
  lineHeight: '1',
  textShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
  zIndex: 'docked',
};

export const SpanSlogan: CSSObject = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'transparent',
  bgClip: 'text',
  pl: '2',
};

export const puntButtonProps: CSSObject = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  border: '2px',
  borderColor: 'delta.700',
  borderRadius: 'lg',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.35), inset 0px 0px 0px 2px #F7D722',
  color: 'delta.800',
  fontFamily: 'CinzelDecorative',
  textTransform: 'lowercase',
  textShadow: '0px 1px 1px rgba(247, 215, 31, 0.7)',
  fontWeight: 'extrabold',

  _hover: {
    bgGradient: 'linear(to-b, delta.400, delta.400)',
    borderColor: 'delta.400',
    boxShadow: 'none',
  },

  _active: {
    bgGradient: 'linear(to-b, epsilon.500, epsilon.500)',
    borderColor: 'white',
    borderWidth: '3px',
    color: 'delta.500',
    textShadow: 'none',
  },
};
