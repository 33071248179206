import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { getIconBySportName } from '@/helpers/utils';
import { ButtonToggle, Icon } from '../../styles/Toggle.styles';

export default function ButtonSwitch() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  const toggleCategory = (newCategory: string) => {
    setSearchParams({ category: newCategory });
  };

  return (
    <>
      <ButtonToggle
        type="button"
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
        onClick={() => toggleCategory('racing')}
        category="racing"
      >
        <Icon name={getIconBySportName('horse racing')} />
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </ButtonToggle>
      <ButtonToggle
        type="button"
        data-cy="sports-toggle-text"
        isActive={category === 'sport'}
        onClick={() => toggleCategory('sport')}
        category="sport"
      >
        <Icon name={getIconBySportName('basketball')} />
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </ButtonToggle>
    </>
  );
}
