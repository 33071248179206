import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'xs',
    color: 'white',
    sx: {
      '> span': { fontSize: '2xs', fontWeight: 'medium' },
    },
  },
  eventMarketName: {
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  eventSubtitle: {
    fontSize: 'sm',
    fontWeight: 'medium',
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '1',
    pt: '1',
    pb: '2',
    borderBottom: '1px solid',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    borderBottomColor: 'blackAlpha.300',
    _first: {
      borderTop: '1px solid',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      borderTopColor: 'blackAlpha.300',
    },
  },
};
