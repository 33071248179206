import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.400',
  py: '2',
  w: 'fill-available',
  px: '1',
  mx: '1',
  borderRadius: 'base',
  color: 'white',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});
