import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  fontFamily: 'accent',
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'gray.300',
  borderRadius: 'sm',
  color: 'gray.600',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'rgba(0, 209, 255, 0.25)',
  color: 'white',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
  mb: '2.5',
  transition: 'all .2s ease-in-out',
  svg: {
    color: 'beta.300',
  },
  _hover: {
    color: 'beta.300',
    bg: 'alpha.600',
    // Change color for TextRace, Icon
    'p:first-of-type': {
      color: 'beta.300',
    },
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  color: 'beta.300',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
  fontSize: 'sm',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.100',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
