import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: {
    borderRadius: 'md',
    variant: 'secondary',
    height: 7,
    lineHeight: 7,
  },
  buttonCashOutAmount: {
    borderRadius: 'md',
    variant: 'primary',
    height: 7,
    lineHeight: 7,
  },
  iconRefresh: { color: 'beta.500', zIndex: 10 },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'gamma.900',
    border: 'none',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, gamma.400, gamma.500)',
  },
  iconOddsBoost: {
    fill: 'gamma.900',
  },
  textOddsBoost: {
    color: 'gamma.900',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.400',
};

export const RaceTime: CSSObject = {
  color: 'gray.400',
  mt: '1',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.400',
  color: 'white',
};

export const BonusBetText: CSSObject = {
  color: 'beta.400',
  fontWeight: '600',
};

export const BetReturn: CSSObject = {
  bg: 'white',
  color: 'white',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
  border: '1px',
  borderColor: 'delta.700',
};

export const BoxBetReturnValue: CSSObject = {
  bg: 'delta.700',
  py: '0.5',
  p: '1',
  fontWeight: '700',
};

export const BoxBetReturnText: CSSObject = {
  p: '1',
  color: 'gray.700',
  fontWeight: '700',
};
