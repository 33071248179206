import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  bgGradient: 'linear(to-b, alpha.700, alpha.800)',
  alignItems: 'center',
  borderBottom: '2px solid',
  borderColor: 'gamma.400',
  h: '16',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
  color: 'gamma.400',
};
