export const en = {
  'onboarding.locationnotallowed.header': 'Lost in the Wilderness',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing Junglebet from outside of Australia.',
  'error.heading': 'expedition on hold',
  'error.description': `<bold>Adventure is still out there.</bold>
  Refresh the page, or try again later.`,
  'maintenance.heading': 'Tending to the digital ecosystem',
  'maintenance.error': `<bold>JungleBet is undergoing maintenance.</bold>
  Refresh the page, or try again later.`,
  'racing.errorMessages.noRacesAvailable': 'Raceless Trails',
  'racing.errorMessages.noRacesAvailableSubtext': `<b>There are no more races available.</b>
  <p>Please consider selecting a different day to try again.</p>`,
  'main.featured.header': 'jungle',
  'main.featured.jam': 'jam',
  'main.featured.rumble': 'rumble',
  'promo.racing.featuredRace': 'Featured <bu>race</bu>',
  'onboarding.login.subtitle':
    'Step into the thrill at <span>JungleBet.</span> Fast track your betting adventure through lush odds and wild wins. <span>Join today!</span>',
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading': 'A Jungle of Possibilities',
  'betSlip.potentialReturns': 'Est. Payout:',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'betslip.betslipbetcard.errors.insufficientlegsformulti':
    'To build a Multi, please add more selections to increase your potential returns.',
  'generic.showmore': 'See All',
  'sports.allsports.azheading': 'A-Z Sports',
};
