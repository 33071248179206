import { DeepPartial } from '../../lib/types';
import api from '../api';

export const marketingPreferencesEndPoint = 'punter/marketing';

export type TMarketingPreferencesParams = DeepPartial<{
  email_marketing_enabled: boolean;
  sms_marketing_enabled: boolean;
}>;

// post marketing preferences
export const mutateMarketing = (body?: TMarketingPreferencesParams) =>
  api
    .post<TMarketingPreferencesParams>(marketingPreferencesEndPoint, body)
    .then((res) => res.data);

// get
export const queryMarketingPreferences = (
  params?: TMarketingPreferencesParams
) =>
  api
    .get<TMarketingPreferencesParams>(marketingPreferencesEndPoint, { params })
    .then((res) => res.data);
