import { WellSchema } from './Well.styles';

export const wellStyles: WellSchema = {
  wrapper: {
    bg: 'beta.700',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.15) inset',
    borderRadius: 'md',
    p: '1.5',
    py: '2',
  },
};
