import { MarketLayoutStylesSchema } from './MarketLayout.styles';

export const matchDetailsStyles: MarketLayoutStylesSchema = {
  headerText: {
    flex: '1',
    align: 'center',
    fontSize: '2xs',
    fontWeight: 'bold',
    color: 'gray.700',
  },
  rowWrapper: {
    px: '4',
    py: '2',
    alignItems: 'center',
    display: 'flex',
    fontSize: 'xs',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    borderColor: 'transparent',
    borderTopWidth: undefined,
    gap: '2',
    pos: 'relative',

    _before: {
      borderColor: 'blackAlpha.400',
      borderBottomWidth: '1px',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      w: 'full',
      left: '0',
      pos: 'absolute',
      top: '-1px',
    },
  },
  rowWrapperFirst: {
    borderTopWidth: '1px',
  },
  rowWrapperWithBtn: {
    borderTop: '1px',
    borderTopColor: 'rgba(255, 255, 255, 0.1)',
  },

  tab: {
    bg: 'gamma.400',
    borderBottom: '1px',
    borderColor: 'gamma.600',
    color: 'alpha.500',
    fontSize: '2xs',
    fontWeight: 'bold',
    _notFirst: { borderLeftWidth: '1px' },
    _selected: {
      bg: 'gamma.600',
      borderBottomColor: 'transparent',
      color: 'gamma.200',
    },
  },
};
