import {
  chakra,
  Box,
  BoxProps,
  Text,
  Image,
  Flex,
  ImageProps,
} from '@chakra-ui/react';
import { styleImports } from './Table.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

type TItem = {
  isBoxed: boolean;
} & BoxProps &
  any;
export const Item = chakra<TItem>(Box, {
  label: 'table-Item',
  shouldForwardProp: (prop) => !['isBoxed'].includes(prop),
  baseStyle: ({ theme, isBoxed }: any) => ({
    display: 'grid',
    gap: '2',
    alignItems: 'center',
    gridTemplateColumns: isBoxed ? '1fr auto' : ['unset', '1fr auto'],
    gridTemplateRows: isBoxed ? 'unset' : ['1fr auto', 'unset'],
    py: ['3', 'unset'],
    p: '1',
    minH: ['unset', '12'],
    borderTop: '1px',
    borderTopColor: '#E6EBEA',

    ...(theme.themeName === EThemes.Wellbet && {
      display: 'flex',
      borderTop: '1px dashed',
      borderColor: 'blackAlpha.300',
      mx: '0',
      minH: ['10', null, 'unset'],
      justifyContent: 'space-between',
      flexDir: 'column',
      alignItems: 'start',
      mt: '1',
      mb: '2',
      p: '2',
      px: '2',
      py: '2',
      borderRadius: 'md',
      gap: '1',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.Item;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RacerName = chakra(Text, {
  label: 'table-RacerName',
  baseStyle: ({ theme }) => ({
    fontWeight: 'semibold',
    fontSize: 'sm',
    whiteSpace: 'nowrap',

    div: {
      display: 'inline-block',
      textTransform: 'capitalize',
      textDecoration: 'inherit',
    },
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'black',
      fontSize: 'xs',
      whiteSpace: ['unset', null, 'nowrap'],
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: ['xs', null, 'sm'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RacerName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TRunnerImage = {
  isGreyhound: boolean;
} & ImageProps &
  any;
export const RunnerImage: TRunnerImage = chakra(Image, {
  label: 'table-RunnerImage',
  shouldForwardProp: (prop) => !['isGreyhound'].includes(prop),
  baseStyle: ({ isGreyhound }: any) => ({
    h: '8',
    mr: ['1', '2'],
    p: !isGreyhound && '1',
    borderRadius: 'base',
    bg: 'white',
    border: '1px',
    borderColor: 'gray.200',
    boxShadow: 'md',
    aspectRatio: '1 / 1',
    objectFit: 'contain',

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerImage;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexRunnerWrapper = chakra(Flex, {
  label: 'table-FlexRunnerWrapper',
  baseStyle: () => ({
    flexDir: 'column',
    w: 'full',
  }),
});

export const TextTableBarrierNumber = chakra(Text, {
  label: 'table-TextTableBarrierNumber',
  baseStyle: () => ({
    color: 'gray.500',
    fontSize: ['2xs', 'xs'],

    ...(() => {
      try {
        return styleImports[themeName]?.TextTableBarrierNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
