import styled, { CSSProp } from 'styled-components';
import { ArrowIosBackOutline } from '@styled-icons/evaicons-outline';
import {
  chakra,
  Flex,
  Grid,
  ButtonProps,
  Image,
  ImageProps,
  Text as TextChakra,
  Link as LinkChakra,
  ChakraComponent,
  FlexProps,
} from '@chakra-ui/react';
import { styleImports } from './ResponsibleGambling.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export const IconArrow = styled(ArrowIosBackOutline)`
  height: 18px;
  margin-right: var(--bc-sizes-2);
  cursor: pointer;
  ${({ theme }) => theme.pages.deposit.ColourPrimary};
`;

export const Heading = styled.h1<{ css?: CSSProp }>`
  font-size: var(--bc-fontSizes-xl);
  font-style: italic;
  ${({ theme }) => theme.pages.deposit.ColourPrimary};
  ${(props) => ({ ...(props.css as Record<string, string | any>) })};
`;

export const TextTitle = chakra(TextChakra, {
  label: 'ResponsiblePageTextTitle',
  baseStyle: () => ({
    mb: '1',
    fontSize: 'xl',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SubHeading = chakra(TextChakra, {
  label: 'ResponsiblePageSubHeading',
  baseStyle: ({ theme }) => ({
    mb: '1',
    fontSize: 'xl',
    fontWeight: 'semibold',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.700',
      fontSize: 'md',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.SubHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SmallHeading = chakra(TextChakra, {
  label: 'ResponsiblePageSmallHeading',
  baseStyle: ({ theme }) => ({
    mb: '1',
    fontSize: 'lg',
    fontWeight: 'semibold',
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'gray.700',
      fontSize: 'md',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.SmallHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ImageGamblersHelp = chakra(Image, {
  label: 'ResponsiblePageImageGamblersHelp',
  baseStyle: ({ theme }) => ({
    objectFit: 'cover',

    ...(theme.themeName === EThemes.Wellbet && {
      w: '300px',
      minW: '300px',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ImageGamblersHelp;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'img', ImageProps>;

export const FlexResponsibleGamblingWrapper = chakra(Flex, {
  label: 'ResponsiblePageGamblingWrapper',
  baseStyle: () => ({
    borderRadius: 'base',
    border: '1px',
    borderColor: 'gray.200',
    my: '4',
    textAlign: 'center',
    maxW: 'container.sm',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexResponsibleGamblingWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const Box = styled.div<{ css?: CSSProp }>`
  ${(props) => ({ ...(props.css as Record<string, string | any>) })};
`;

// TODO: Remove
export const BoxBorder = styled.div<{ css?: CSSProp }>`
  ${(props) => ({ ...(props.css as Record<string, string | any>) })};
  border-color: rgba(225, 225, 225, 0.3);
`;

export const BoxBordered = chakra(Box, {
  label: 'ResponsibleGamblingBoxBordered',
  baseStyle: () => ({
    pt: '6',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxBordered;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexContainer = chakra(Flex, {
  label: 'ResponsibleGamblingFlexContainer',
  baseStyle: () => ({
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    px: ['0', '5'],
    mx: '0',
  }),
});

export const PageInfoWrapper = chakra(Flex, {
  label: 'ResponsiblePageInfoWrapper',
  baseStyle: () => ({
    flexDirection: ['column', null, 'row'],
    mx: '0',
  }),
});

export const PageInfo = chakra(TextChakra, {
  label: 'ResponsiblePagePageInfo',
  baseStyle: () => ({
    fontSize: 'lg',
    fontWeight: 'normal',
    px: '0',
    mb: '2',

    ...(() => {
      try {
        return styleImports[themeName]?.PageInfo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Text = chakra(TextChakra, {
  label: 'ResponsiblePageText',
  baseStyle: () => ({
    fontSize: 'md',

    ...(() => {
      try {
        return styleImports[themeName]?.Text;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * @deprecated The function should not be used or extended further,
 * use buttonActionProps instead.
 */
export const ButtonAction = chakra(Button, {
  label: 'ResponsiblePageButtonAction',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonAction;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const GridWrapper = chakra(Grid, {
  label: 'ResponsiblePageGridWrapper',
  baseStyle: () => ({
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: '1fr',
    gap: '5',
    gridAutoFlow: 'row',
    pb: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.GridWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PageWrapper = styled.div`
  max-width: 330px;
  margin: 0 auto;

  ${({ theme }) => theme.device.tablet} {
    max-width: unset;
  }
`;

export const Link = chakra(LinkChakra, {
  label: 'ResponsiblePageLink',
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 'sm',

    _hover: { textDecoration: 'underline' },

    ...(() => {
      try {
        return styleImports[themeName]?.Link;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const boxImageWrapperProps = {
  'data-testid': 'boxImageWrapperProps',
  pt: ['2', '0'],
  pr: ['0', '4'],
  ...(() => {
    try {
      return styleImports[themeName]?.boxImageWrapperProps;
    } catch (err) {
      return {};
    }
  })(),
};
