import { CSSObject } from '@chakra-ui/react';
import { TTextProp } from './Propositions.styles';

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    '&.return-amount': {
      bg: isSelected && 'linear-gradient(180deg, #636EC7 0%, #414984 100%)',
      color: isSelected && 'white',

      _hover: {
        bg: isSelected ? 'beta.200' : 'beta.700',
        color: 'white',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },
  },
});
