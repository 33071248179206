import { CSSObject } from '@chakra-ui/react';

export const OddsText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'extrabold',
};

export const MultiCardContainer: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'blackAlpha.300',
  pb: '0',
  px: '2',
};

export const OddsMultiCard: CSSObject = {
  fontWeight: 'medium',
};
