import { TRunnerList } from '@/api/racing/racing.types';
import { ERaceType } from '../lib/DBModels';

export const raceNameMapping: Record<ERaceType, string> = {
  [ERaceType.Horse]: 'Thoroughbreds',
  [ERaceType.Greyhound]: 'Greyhounds',
  [ERaceType.Harness]: 'Harnesses',
};

/**
 * Order of races when mapped
 */
export const orderedRaceType = {
  [ERaceType.Horse]: 1,
  [ERaceType.Greyhound]: 2,
  [ERaceType.Harness]: 3,
};

/**
 * Sort raceTypes based on orderedRaceType
 */
export const sortByRaceType = (
  a: ERaceType | undefined,
  b: ERaceType | undefined
) => {
  if (!a || !b) return 0;
  return orderedRaceType[a] - orderedRaceType[b];
};

export const sortByWinOdds = (
  a: TRunnerList | undefined,
  b: TRunnerList | undefined
) => {
  if (!a?.win_odds || !b?.win_odds) return 0;
  return a.win_odds - b.win_odds;
};

/**
 * Sort by start time
 */
export const sortByRaceStart = (a: string | undefined, b: string | undefined) =>
  new Date(a ?? '').getTime() - new Date(b ?? '').getTime();
