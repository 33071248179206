import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    px: '2.5',
    mx: '5px',
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    borderColor: 'blackAlpha.400',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
  },
};
export default styles;