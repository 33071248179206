import { ImageProps } from '@chakra-ui/react';
import { styleImport } from './Logo.styles.imports';

type TDataTestID = { 'data-testid'?: string };
export type TLogoStyles = {
  img: ImageProps;
} & TDataTestID;

export const styles: Partial<TLogoStyles> = {
  img: { 'data-testid': 'main-logo', ...styleImport?.img },
};
