import { Image, Show, Hide } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/volcanobet/images/logo.svg';
import logoMobile from '@/assets/volcanobet/images/mobile_logo.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function Index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          alt={`${THEME_NAME.volcanobet}`}
          data-testid={`${THEME_NAME.volcanobet}_desktop`}
          mx="1"
          mb="4"
          w="264px"
          h={{ base: '8', lg: 'auto' }}
          filter="drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))"
        />
      </Show>
      <Hide above="lg">
        <Image
          minW="105px"
          src={logoMobile}
          alt={`${THEME_NAME.volcanobet}`}
          data-testid={`${THEME_NAME.volcanobet}_mobile`}
        />
      </Hide>
    </Link>
  );
}
