import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: ['2', null, '0'],
  '&&': { px: ['2', '4'], pb: ['1', null, '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'normal',
  fontSize: 'lg',
  fontFamily: 'accentMusashi',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  color: 'gamma.200',
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: [null, null, null, 'lg'],
  minHeight: '0',
  bg: ['transparent', null, 'alpha.600'],
  color: 'gamma.200',
  boxShadow: [
    'unset',
    null,
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  ],
};

export const WrapperContainer: CSSObject = {
  px: ['2', null, '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
  mb: '3',
};
