import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  color: 'gamma.400',
  fontSize: 'lg',
  w: '220px',
  textAlign: 'center',
  fontWeight: 'bold',
  lineHeight: '20px',
  textTransform: 'uppercase',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: '13px',
  w: '250px',
};

export const ImageNoRaces: CSSObject = {
  w: '296px',
};
