import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logoTop from '../../../assets/betroyale/images/logos/logoTop.png';
import logoCenter from '../../../assets/betroyale/images/logos/logoCenter.png';
import Title from '../components/Title';
import Description from '../components/Description';
import ButtonRefresh from '../components/ButtonRefresh';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (isMaintenance: boolean): TTemplate => ({
  wrapper: <FlexPageWrapper isMaintenance={isMaintenance} />,
  content: {
    wrapper: <MaintenanceContainer />,
    logo: (
      <Flex flexDir="column" alignItems="center">
        <Img src={logoTop} alt={REACT_APP_THEME_NAME} alignSelf="center" />
        <Img
          src={logoCenter}
          alt={REACT_APP_THEME_NAME}
          alignSelf="center"
          w="51px"
          h="91px"
          mt={['40', null, '40']}
        />
      </Flex>
    ),
    title: <Title />,
    desc: <Description />,
    btn: <ButtonRefresh />,
    body: (
      <Global styles={{ body: { background: 'var(--bc-colors-gray-400)' } }} />
    ),
  },
});

export default template;
