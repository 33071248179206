import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
};

export const TopNavWrapper: CSSObject = {
  mt: '2',
  gap: '1.5',
  pb: ['4', '2', null],
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'alpha.700' : 'alpha.500',
  color: isActive ? 'beta.400' : 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1.5',
  borderRadius: 'base',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
  boxShadow: isActive
    ? '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)'
    : '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 3px #003541 inset,0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
  '&:hover, &:focus': {
    bg: isActive && 'delta.100',
    color: isActive ? 'beta.900' : 'whiteAlpha.600',
  },
  '&:hover': {
    color: 'whiteAlpha.600',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'alpha.700',
  color: 'beta.400',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1.5',
  borderRadius: 'base',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  '&:hover, &:focus': {
    bg: 'delta.100',
    color: 'beta.900',
  },
  '&:hover': {
    color: 'whiteAlpha.600',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
