import { BadgeProps, CSSObject } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import {
  BadgeCountdown,
  CountdownFlex,
  TextTimestamp,
} from './Countdown.styles';

export type TCountdown = {
  eventTime: string;
  dateFormat?: string;
  hasTimestamp?: boolean;
  timestampFormat?: string;
  timestampFormatPrior?: string;
  timestampFormatEnded?: string;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  sxWrapper?: CSSObject;
} & BadgeProps;

export default function Countdown({
  eventTime,
  dateFormat,
  hasTimestamp,
  timestampFormat,
  timestampFormatPrior,
  timestampFormatEnded,
  direction,
  sxWrapper,
  ...rest
}: TCountdown) {
  const { minutesRemaining, secondsRemaining, showCountdown, timerWithDate } =
    useCountdown(eventTime);

  const state = (() => {
    if (!showCountdown) return 'prior';
    if (minutesRemaining <= 4) return 'ended';
    return 'inprogress';
  })();

  return (
    <CountdownFlex
      className="countdown-flex"
      direction={direction}
      sxWrapper={sxWrapper}
    >
      {hasTimestamp && (
        <TextTimestamp data-cy="textTimestamp" className="timestamp">
          {timerWithDate(
            (() => {
              if (timestampFormatPrior && state === 'prior') {
                return timestampFormatPrior;
              }
              if (timestampFormatEnded && state === 'ended') {
                return timestampFormatEnded;
              }
              if (timestampFormat) {
                return timestampFormat;
              }
              return undefined;
            })()
          )}
        </TextTimestamp>
      )}

      {((hasTimestamp && showCountdown) || !hasTimestamp) && (
        <BadgeCountdown
          data-cy="activeRace"
          state={state}
          className={`state-${state}`}
          {...rest}
        >
          {(() => {
            if (showCountdown) {
              if (minutesRemaining > 4 || minutesRemaining <= -1) {
                return `${minutesRemaining}m`;
              }

              if (!!minutesRemaining && minutesRemaining <= 4) {
                return `${minutesRemaining}m ${secondsRemaining}s`;
              }

              return `${secondsRemaining}s`;
            }

            return dayjs(eventTime).format(dateFormat || 'DD/MM/YY H:mm a');
          })()}
        </BadgeCountdown>
      )}
    </CountdownFlex>
  );
}
