/* eslint-disable import/no-mutable-exports */
import React from 'react';

import { TTemplate } from '@/helpers/createTemplate';
import LazyLottie from './lazyLottie';

const lottiePromise = import(
  '../../../../../../assets/betroyale/animations/betslip_success.json'
);

export const template = (): TTemplate => ({
  wrapper: <LazyLottie lottieImportPromise={lottiePromise} />,
});

export default template;
