import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'numb',
    fontFamily: 'roboto',
    fontSize: '2xs',
    px: 1,
    lineHeight: '1.125',
    textTransform: 'none',
    w: '16',
    sx: {
      '&:active, &[data-active="true"]': {
        color: 'beta.400',
        textShadow: '0px 1px 2px rgba(0, 0, 0, 0.50)',
        fontWeight: 'extrabold',
        bg: 'alpha.600',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    textTransform: 'capitalize',
    m: 'auto',
    fontWeight: 'extrabold',
    lineHeight: 'none',
    fontSize: '2xs',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  },
  buttonBaseBox: {
    fontSize: 'xs',
    fontWeight: 'black',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
