import { CSSObject } from '@chakra-ui/react';
import wood from '@/assets/fiestabet/images/backgrounds/woodBg.png';

export const BoxSectionWrapper: CSSObject = {
  bgImage: wood,
  boxShadow: '0px 2px 1px 0px #773053 inset, 0px 4px 0px 0px #994300',
  borderRadius: '2xl',
  mt: '0',
  mb: '3',
  _first: {
    mb: '12',
  },
};

export const BoxWrapper: CSSObject = {
  bg: 'transparent',
  pl: '2.5',
  pt: '3',
  pb: '0',
};

export const TextHeading: CSSObject = {
  color: 'epsilon.500',
  fontFamily: 'CreteRound',
  fontSize: 'sm',
  fontWeight: 'normal',
};

export const ButtonViewMore: CSSObject = {
  fontSize: 'xs',
  color: 'gamma.700',
  bgGradient: 'linear(to-b, gamma.400, gamma.500)',
  boxShadow:
    '0px -2px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 0px 0px #FFB982 inset',
  textTransform: 'uppercase',
  fontFamily: 'Ultra',
  fontWeight: 'normal',
  textShadow: '0px 1px 0px rgba(255, 185, 130, 0.50)',
  borderTop: 'none',
  lineHeight: 'normal',
  _hover: {
    bgGradient: 'linear(to-b, gamma.500, gamma.500)',
  },
  _active: { bgGradient: 'linear(to-b, gamma.500, gamma.500)' },
};
