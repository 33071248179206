import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  alignSelf: 'center',
  mt: '0',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px #000 inset',
  border: '2px solid',
  borderColor: '#FFE6B6',
};

export const HeaderContainer: CSSObject = {
  pb: '2',
  pt: '4',
  px: '4',
};

export const HeaderText: CSSObject = {
  p: '0',
  textTransform: 'none',
};

export const CloseButton: CSSObject = {
  right: '1',
  top: '2',
};

export const ContentContainer: CSSObject = {
  pb: '4',
  pt: '1',
  px: '4',
};
