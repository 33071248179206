import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
  // TODO: Fix below with token values
  _groupFocusWithin: {
    bgGradient: 'linear(to-b, #3648d9, #16269e)',
  },
  bgGradient: 'linear(to-b, #5264EF, #3445C9)',
  color: 'white',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.45)',
  fontSize: 'sm',
  fontWeight: 'black',
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px #2B6B99, 0px 1px 4px 0px rgba(0, 0, 0, 0.30) inset',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'alpha.600',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'delta.500',
  fontWeight: 'black',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: '22px',
  color: 'delta.500',
  fontWeight: 'black',
  textShadow: '1px 1px 4px rgba(0, 0, 0, 0.15)',
};

export const InputBetSlip: CSSObject = {
  bg: '#1E2873',
  marginTop: '-2px',
  borderColor: '#3445C9',
  pl: '10',
  color: 'white',
  borderRadius: 'md',
  borderLeft: 'none',

  _hover: {
    '&&': {
      bg: '#1E2873',
      borderColor: '#3445C9',
    },
  },

  _focus: {
    '&&': {
      bg: '#1E2873',
      borderColor: '#3648d9',
    },
  },

  '&::placeholder': { color: 'white', opacity: '0.3' },
};

export const TreasureChestIcon: CSSObject = {
  color: 'delta.500',
};
