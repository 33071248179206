import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Stack } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import {
  BoxStyledLink,
  Loading,
  TextM,
  TextS,
} from '../../TakeABreak/styles/TakeABreak.styles';
import { getStrings } from '@/helpers/utils';
import InlineLink from '../../../../components/InlineLink';
import { TFormikValues } from '../types';
import Password from '../../../onboarding/components/Password';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';
import FormControl from '../../../../components/FormElements/HOCs/FormControl';
import Checkbox from '@/components/FormElements/Checkbox';
import {
  buttonCloseAccountProps,
  TextCheckbox,
} from '../styles/CloseAccount.styles';
import { Button } from '@/components/Button/Button';

export default function Form() {
  const [
    {
      Account: { CloseAccount: CloseAccountStrings },
    },
  ] = getStrings();

  const { isSubmitting, setFieldValue } = useFormikContext<TFormikValues>();
  const intl = useIntl();
  const { email } = getThemeConfig();

  return (
    <Box maxW="container.xs">
      <TextM>{CloseAccountStrings.text1}</TextM>

      <Box mb="4">
        <TextS>
          {CloseAccountStrings.text2}{' '}
          <InlineLink path="/account/settings/promotions-notifications">
            {CloseAccountStrings.clickHere}
          </InlineLink>
        </TextS>
        <TextS>
          {CloseAccountStrings.text3}{' '}
          <InlineLink path="/account/settings/responsible-gambling/lifetime-exclusion">
            {CloseAccountStrings.clickHere}
          </InlineLink>
        </TextS>
      </Box>

      <Stack mb="4">
        <Checkbox name="confirmedReopen" alignItems="flex-start">
          <TextCheckbox>
            {`${CloseAccountStrings.confirmOpenAccounts}`}
            <BoxStyledLink as="span">{`${email}.`}</BoxStyledLink>
          </TextCheckbox>
        </Checkbox>

        <Checkbox name="confirmedBalance" alignItems="flex-start">
          <TextCheckbox>
            {`${CloseAccountStrings.confirmPendingBalance}`}
            <BoxStyledLink as="span">{`${email}.`}</BoxStyledLink>
          </TextCheckbox>
        </Checkbox>

        <Checkbox name="confirmedPendingBets">
          <TextCheckbox>{CloseAccountStrings.confirmPendingBets}</TextCheckbox>
        </Checkbox>
      </Stack>

      {!IS_MOBILE_APP && (
        <FormControl
          id="current-password"
          label={intl.formatMessage({
            id: 'account.closeaccount.currentpassword',
          })}
        >
          <Password
            placeholder="Enter your password..."
            name="password"
            onChange={(e) => setFieldValue('password', e.target.value)}
          />
        </FormControl>
      )}

      <Button
        {...buttonCloseAccountProps}
        data-cy="closeAccountButton"
        isFullWidth
        type="submit"
        disabled={isSubmitting}
      >
        {!isSubmitting && CloseAccountStrings.closeButton}
        {isSubmitting && <Loading />}
      </Button>
    </Box>
  );
}
