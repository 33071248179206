import { CSSObject } from '@chakra-ui/react';

export const BoxCard: CSSObject = {
  p: '1.5',
  borderRadius: '10px',
  w: '265px',
  mx: ['2', null, 'unset'],
  bg: 'epsilon.700',
  border: '1px solid',
  borderColor: '#5c6d8e',
};

export const FlexFeatureWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  borderRadius: 0,
  borderBottom: [null, null, '3px solid'],
  borderBottomColor: [null, null, 'gamma.400'],
  mb: ['8', null, '4'],
  pt: [0, null, '9'],
  pb: [0, null, '9'],
  mx: ['0'],
  px: ['0', null, '31px'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  flex: 0,
};

export const FlexFeatureTitleWrapper: CSSObject = {
  alignItems: ['flex-end', null, 'unset'],
  justifyContent: ['center', null, 'flex-start'],
  flex: ['unset', null, 2],
  h: ['190px', '240px', 'unset'],
};

export const HeadingSlogan: CSSObject = {
  color: 'white',
  fontFamily: 'KHInterference',
  fontWeight: 'bold',
};

export const ImageMascot: CSSObject = {};

export const TextFeatureStyledHeading: CSSObject = {};

export const LinkCardHeader: CSSObject = {
  '.state-prior': { color: 'white' },
};

export const IconCardHeader: CSSObject = {
  color: 'beta.500',
  boxSize: '4',
};

export const TextCardHeader: CSSObject = {
  color: 'white',
  fontFamily: 'KHInterference',
  fontWeight: 'bold',
};

export const TextRaceNumber: CSSObject = {
  borderRadius: 'base',
  bg: 'alpha.600',
  h: '18px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '18px',
  px: '1',
  color: 'gamma.400',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'rgba(0, 0, 0, 0.20)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 4px -3px rgba(0, 0, 0, 0.30) inset',
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  px: '2.5',
};

export const ImageRunnerSilk: CSSObject = {
  boxSize: '8',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '1px',
  borderBottomColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20)',
  py: 0,
  h: '46px',
  span: {
    fontFamily: 'KHInterference',
  },
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'semibold',
  fontFamily: 'KHInterference',
};

export const TextScratched: CSSObject = {
  fontSize: '8px',
  color: 'white',
  textDecoration: 'line-through',
};

export const TextRunnerNumber: CSSObject = {
  fontSize: 'smxs',
  '&:nth-child(2)': {
    fontSize: '2xs',
    position: 'relative',
    top: '-0.5',
  },
};

export const Button = (): CSSObject => ({
  borderRadius: 'md',
  fontSize: 'sm',
  fontFamily: 'body',
  fontWeight: 'bold',
});

export const LinkRaceDetails: CSSObject = {
  bg: 'linear-gradient(180deg, #263BF4 0%, #2534BF 100%)',
  boxShadow:
    '0px 0px 2px 2px rgba(74, 93, 255, 0.60) inset, 0px 2px 9px 0px rgba(0, 0, 0, 0.35), 0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
  textTransform: 'uppercase',
  borderBottomRadius: 'base',
  fontFamily: 'KHInterference',
  _hover: {
    bg: 'linear-gradient(180deg, #1e2a98 0%, #172075 100%)',
  },
};
