import { Box, chakra, keyframes } from '@chakra-ui/react';
import styled from 'styled-components';
import { LoadingSpinnerProps } from './types';

export const SpinnerWrapper = styled.div<LoadingSpinnerProps>`
  display: flex;
  width: ${({ width = '100%' }) => width};
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0' }) => margin};

  ${({ theme }) => theme.components.loadingSpinner.spinnerWrapper};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = chakra(Box, {
  baseStyle: () => ({
    borderRadius: '50%',
    animation: `${rotate} 1s linear infinite`,
    alignSelf: 'center',
    boxSize: '9',

    border: '3px solid',
    borderColor: 'beta.500',
    borderTop: '3px solid transparent',
  }),
});
