import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'rgba(0, 209, 255, 0.25)',
  px: 3,
  py: 2,
  mx: 2,
  mb: 2,
  borderRadius: 'md',
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};
export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  fontSize: 'sm',
  ...(isVS && { color: 'beta.300', mx: '1' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'beta.300',
  fontSize: '2xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'beta.600',
  boxSize: '32px',
  bg: 'linear-gradient(180deg, var(--bc-colors-beta-300), var(--bc-colors-beta-400))',
  borderRadius: 'md',
  p: '1.5',
};

export const IconChevron: CSSObject = {
  color: 'beta.300',
  fontWeight: 'extrabold',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: '2xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.400',
    boxSize: '5',
  },
};
