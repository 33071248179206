import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {},
  },
  iconButtonScrollRight: {
    fontSize: 'sm',
    p: '1',
    bg: 'linear-gradient(180deg, rgba(254,213,127,1) 0%, rgba(194,142,44,1) 100%)',
    border: 'none',
    minW: '6',
    color: 'black',
    borderRadius: 'md',
    borderLeftRadius: 'none',
    ml: '0.5',
    cursor: 'pointer',
    _hover: {
      color: '#30363F',
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {},
  },
  iconButtonScrollLeft: {
    fontSize: 'sm',
    p: '1',
    bg: 'linear-gradient(180deg, rgba(254,213,127,1) 0%, rgba(194,142,44,1) 100%)',
    border: 'none',
    minW: '6',
    color: 'black',
    borderRadius: 'md',
    borderRightRadius: 'none',
    cursor: 'pointer',
    _hover: {
      color: '#1A1C1F',
    },
  },
  flexWrapper: {
    bg: 'blackAlpha.600',
    borderRadius: 'md',
    boxShadow: '0 1px rgba(255, 255, 255, .05)',
    pos: 'relative',
    minH: '34px',
  },
  buttonGroupSports: {
    display: 'inline-flex',
    flex: '1',
    overflowX: 'auto',
    p: '1',
    spacing: '1',
    whiteSpace: 'nowrap',
    pos: 'absolute',
    inset: 0,
    right: [null, null, null, '72px'],
    sx: {
      '::-webkit-scrollbar': { display: 'none' },
      button: {
        '-webkit-tap-highlight-color': 'transparent',
      },
    },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    p: '1',
    pos: 'relative',
    spacing: '1',
    ml: 'auto',
    bg: '#1A1C20',
    borderRadius: 'md',
    _before: {
      bgGradient: 'linear-gradient(to-r, rgba(25, 28, 33, 0), #191c21)',
      bottom: '1',
      content: '""',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '100%',
      top: '1',
      w: '12',
      zIndex: '1',
    },
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'alpha.500',
    pos: 'absolute',
    bottom: 'px',
    transition: 'all 0.2s ease',
  },
};
