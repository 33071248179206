import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  mb: '2',
  py: '0',
  px: ['2', '4'],
  top: ['0px', '9'],
  borderBottomRadius: 'base',
  position: 'initial',
};

export const ButtonFilter = () => ({
  color: 'zeta.400',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'zeta.300',
  color: selected ? 'alpha.700' : 'transparent',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  mr: '0.4',
});

export const IconRace = () => ({
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.35))',
  strokeWidth: '2px',
  stroke: 'rgba(255, 255, 255, 0.09)',
});

export const TextFilterLabel: CSSObject = {
  color: 'inherit',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
};
