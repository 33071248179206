export const styles = {
  global: {
    html: {},
    body: {
      minH: '100vh',
      bg: 'gray.200',
      fontFamily: 'body',

      /**
       * This is a crap way to hide the live chat button.
       * There's a way to hide it within beacon itself that we
       * really should do.
       */
      '.hsds-beacon:not(.is-mobile)': {
        '.BeaconFabButtonFrame': { boxShadow: 'none' },
        '[title="Help Scout Beacon - Open"]': {
          display: 'none',
        },
      },
    },
  },
};
