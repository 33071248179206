import { LinkItemSchema } from '@/views/sports/components/CompetitionsMenu/styles/LinkItem.styles';

export const linkItemStyles: LinkItemSchema = {
  link: {
    display: 'flex',
    justifyContent: 'space-between',
    py: '2',
    pl: '3',
    pr: '2',
    borderBottomWidth: '1px',
    borderBottomColor: 'alpha.800',
    _hover: {
      textDecoration: 'none',
    },
  },
  textCompetitionName: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxW: '140px',
  },
  textMatchCount: {
    fontWeight: 'bold',
    px: '1',
  },
};
