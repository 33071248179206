import { FlexProps, TextProps } from '@chakra-ui/react';
import { styleImports } from './ErrorBanner.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type ErrorBannerSchema = Partial<{
  flexWrapper: FlexProps;
  errorText: TextProps;
}>;

export const errorBannerStyles: ErrorBannerSchema = {
  flexWrapper: {
    'data-testid': 'errorBanner-flexWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.errorBannerStyles?.flexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  errorText: {
    'data-testid': 'errorBanner-errorText',
    ...(() => {
      try {
        return styleImports[themeName]?.errorBannerStyles?.errorText;
      } catch (err) {
        return {};
      }
    })(),
  },
};
