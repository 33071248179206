/**
 * This function formats the string stake value after keypress
 * and before submit.
 */
export const validateStake = (value: string) => {
  const val = value.replace(/[^0-9.]/g, '');
  const parts = val.split('.');

  let newValue =
    (parts?.length ?? 0) > 1 ? `${parts?.[0]}.${parts?.[1].slice(0, 2)}` : val;

  if (newValue?.[0] === '.') newValue = `0${newValue}`;

  if (
    (newValue?.length ?? 0) > 1 &&
    newValue?.[0] === '0' &&
    newValue?.[1] !== '.'
  ) {
    newValue = newValue.substring(1);
  }

  return newValue?.replace('.00', '');
};
