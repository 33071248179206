import { CSSObject } from '@chakra-ui/react';

export const FlexSideLinkWrapper: CSSObject = {
  textAlign: 'left',
};

export const SideNavHeading: CSSObject = {
  fontFamily: 'Bungee',
  fontSize: ['md', 'lg'],
  fontWeight: 'thin',
  textTransform: 'uppercase',
  color: 'gamma.400',
  lineHeight: 'none',
  height: 'auto',
  alignItems: 'end',
  bg: 'unset',
  boxShadow: 'unset',
  pl: '0',
  _hover: {
    color: 'gamma.400',
  },
};
