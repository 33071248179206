import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'white',
  mx: ['2', '4'],
  borderRadius: 'base',
  mb: '4',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
};

export const Icon: CSSObject = {
  color: 'delta.50',
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  borderRadius: 'base',
  boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.2)',
};

export const TextRace: CSSObject = {
  color: 'gray.700',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'medium',
};

export const TextNumber: CSSObject = {
  bg: 'beta.700',
  color: 'white',
  h: '4',
  py: '0',
  pt: '0.5',
  borderRadius: 'base',
};

export const BoxHeading: CSSObject = {
  display: 'none',
};

export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    color: ['white', 'beta.900'],
  },
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    py: '0',
    mr: '0.5',
    color: 'alpha.800',
  },
};
