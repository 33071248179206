import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  display: ['none', null, 'flex'],
  justifyContent: 'center',
};

export const TextItem: CSSObject = {
  maxW: '261px',
  color: 'white',
  textAlign: 'center',
  fontSize: '13px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  span: { color: 'beta.500', fontWeight: 'bold' },
};

export const LinkItem: CSSObject = {};
