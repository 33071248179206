import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { pb: 1.5 },
  buttonCashOutAmount: { pb: 1.5 },
  iconRefresh: { pt: '1px' },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'alpha.800',
    border: 'none',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
  },
  iconOddsBoost: {
    fill: 'alpha.800',
  },
  textOddsBoost: {
    color: 'alpha.800',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.400',
};

export const RaceTime: CSSObject = {
  color: 'gray.400',
  mt: '1',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.500',
  color: 'white',
};

export const BonusBetText: CSSObject = {
  color: 'beta.500',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  color: 'beta.400',
  bg: 'alpha.900',
  borderColor: 'alpha.900',
};

export const BetReturn: CSSObject = {
  bg: 'white',
  color: 'alpha.900',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: '2xs',
  border: '1px',
  borderColor: 'alpha.500',
};

export const BoxBetReturnValue: CSSObject = {
  bg: 'alpha.500',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'white',
  textShadow: '0px 1px 0px rgba(0, 0, 0, 0.45)',
};

export const BoxBetReturnText: CSSObject = {
  pl: '1',
  py: '1',
  color: 'alpha.800',
};
