import { SpecialsStylesSchema } from './Specials.styles';

export const specialsStyles: SpecialsStylesSchema = {
  heading: {
    color: 'white',
    fontWeight: 'semibold',
  },
  boxWrapper: {
    pl: '0',
  },
};
