import { CSSObject } from '@chakra-ui/react';

export const EventsClosedContainer: CSSObject = {
  borderRadius: 'md',
  p: '2',
  alignItems: 'center',
  border: '2px solid',
  borderColor: '#cea045',
  bg: 'yellow.200',
};
