import evenShotBg from '@/assets/fiestabet/images/backgrounds/evenShotBg.png';
import evenShot from '@/assets/fiestabet/images/evenShotMascot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  hasBetSubTitleText: {
    fontWeight: 'normal',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notAvailableText: {
    mt: 0,
  },
  notYetAvailableText: {
    color: 'gamma.300',
    fontWeight: 'bold',
    mt: 0,
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'gamma.300',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    mb: '1',
  },
  oddsText: {
    mb: '4',
    mt: 0,
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    border: '2px',
    borderColor: 'epsilon.500',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flexDir: 'column',
    bgImage: `url(${evenShotBg})`,
    bgSize: 'cover',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    fontFamily: 'accentUltra',
    fontWeight: 'normal',
    color: 'alpha.300',
    textShadow: '0px 4px 0px #210A15',
    textTransform: 'uppercase',
    fontSize: '4xl',
    mb: '1',
  },
  addToBetslipButton: {
    variant: 'solid',
  },
  loginToView: {
    variant: 'solid',
  },
  evenShotImage: {
    h: '136px',
    w: '150px',
    mt: '5',
    mb: '4',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
};
