import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  w: '22rem',
  color: 'alpha.400',
  fontFamily: 'dreadful',
  fontSize: '61px',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '90%',
  textTransform: 'capitalize',
  textShadow: '0px 1px 24px rgba(108, 215, 1, 0.60)',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
};

export const ImageNoRaces: CSSObject = {
  w: '241px',
};
