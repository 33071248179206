import { CSSObject } from '@chakra-ui/react';

export const BoxColumn: CSSObject = {
  mb: ['2'],
};

export const TextRaceNumber: CSSObject = {
  h: '18px',
  lineHeight: '18px',
  color: 'gamma.400',
  fontWeight: 'bold',
  fontSize: '2xs',
  bg: 'alpha.600',
  flex: 0,
  borderRadius: 'base',
  px: '1',
};

export const Icon: CSSObject = {
  color: 'gamma.500',
  boxSize: '6',
  mr: '1',
};

export const LinkRaceContainer: CSSObject = {
  borderRadius: '10px',
  border: '3px solid',
  borderColor: 'beta.500',
  background: 'rgba(81, 208, 247, 0.10)',
  boxShadow:
    '0px 6px 20px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 4px rgba(80, 78, 243, 0.50) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.40) inset',
  backdropFilter: 'blur(5px)',
  mb: ['2'],
  py: 0,
  h: '47px',
  alignItems: 'center',
  pr: '3',
};

export const TextRaceType: CSSObject = {
  color: '#6FF4FF',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
  fontSize: 'xl',
  textTransform: 'capitalize',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontSize: 'smxs',
  fontWeight: 'bold',
  mr: 'auto',
};
