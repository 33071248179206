import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  gap: '1',
  color: 'beta.500',
};

export const FooterBodyText: CSSObject = {
  color: 'rgba(255, 255, 255, 0.48)',
};

export const DeductionsText: CSSObject = {
  color: 'white',
};

export const MoreIcon: CSSObject = {
  color: 'blue',
  svg: {
    color: 'red',
  },
};

export const BoxMoreText: CSSObject = {
  mr: '1',
  color: 'beta.500',
  fontSize: 'xs',
};

export const DividerMulti: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.16)',
  borderWidth: '0.5px',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.28)',
};
