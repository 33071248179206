import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    pt: '2',
    pb: '3',
    mb: '0',
  },
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['2', null, 'unset'],
  },
  textTitle: {
    mt: '0',
    mb: IS_MOBILE_APP ? '0' : '2',
    color: 'gamma.600',
    fontSize: 'md',
    fontWeight: 'medium',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        bg: 'beta.500',
        border: '3px solid',
        borderColor: 'beta.100',
        borderRadius: 'lg',
        color: 'gamma.600',
        mt: '-1px',
        flexDir: 'column',
        ...(IS_MOBILE_APP && {
          pt: '0',
        }),
      },
    },
  },
  titleContainer: {
    ...(IS_MOBILE_APP && {
      p: '2',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
