import { CSSObject } from '@chakra-ui/react';

export const TableItem: CSSObject = {
  borderColor: 'gray.300',
  borderRight: 'none',
  borderStyle: 'dashed',
};

export const TableHeadingText: CSSObject = {
  color: 'gray.500',
};

export const DownloadSpreadsheetButton: CSSObject = {
  color: 'beta.500',
};
