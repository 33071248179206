import { PersonalDetailsSchema } from './PersonalDetails.styles';

export const personalDetailsStyles: PersonalDetailsSchema = {
  betslipPromoWrapper: {
    sx: {
      '.chakra-switch__label': {
        color: 'white',
        fontSize: 'xs',
        fontWeight: 'semibold',
      },
    },
  },
};
