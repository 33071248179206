import { CSSObject } from '@chakra-ui/react';
import bg from '../../../../../../assets/questbet/images/backgrounds/get-verified.png';

export const FlexGetVerifiedContainer: CSSObject = {
  px: '3',
  py: '4',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'base',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  alignItems: 'center',
  bg: 'beta.400',
  bgImage: `url(${bg})`,
  maxH: ['unset', null, '46px'],
  mb: '2',
};

export const TextMustBeVerified: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  textShadow: '0px 3px 2px rgba(0, 0, 0, 0.25)',
};

export const ButtonGetVerified: CSSObject = {
  color: 'white',
  bg: 'alpha.500',
  borderRadius: 'base',
  border: '2px',
  borderColor: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  px: '2.5',
  py: '1',
  ml: 'auto',
  minW: 'unset',
};
