import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  bg: 'beta.100',
  borderRadius: 'lg',
  mb: '0',
  mx: '0',
  flex: '1',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 5px var(--bc-colors-beta-200) inset',
};

export const RunnerResultRow: CSSObject = {
  borderX: '0',
  borderTop: '0',
  py: '3',
  px: '1',
  borderColor: 'whiteAlpha.500',
  borderBottomWidth: '1px',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1px',
    borderColor: 'blackAlpha.50',
    borderStyle: 'dashed',
  },
  _first: {
    borderTop: '1px solid',
  },
  _last: {
    borderBottom: 'none',
    _after: {
      display: 'none',
    },
  },
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'md',
  color: 'beta.500',
  textTransform: 'uppercase',
  fontSize: '2xs',
  fontFamily: 'body',
  borderColor: 'alpha.500',
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1',
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-delta-500) 0%, var(--bc-colors-delta-600) 100%)',
  boxShadow: '0px 3px 6px 0px var(--bc-colors-epsilon-500)',
  '&&': {
    h: '8',
    w: '10',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'normal',
  color: 'beta.800',
  fontSize: '2xs',
  position: 'relative',
  bottom: '3px',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  minW: '9',
  p: '2',
  border: 'none',
  borderRadius: 'md',
  fontFamily: 'body',
  fontSize: 'sm',
  fontWeight: 'black',
  textTransform: 'capitalize',
  bg: 'gray.400',
  color: 'gray.200',
  boxShadow:
    '0px 0px 4px 0px var(--bc-colors-blackAlpha-400) inset, 0px 1px 0px 0px var(--bc-colors-whiteAlpha-300)',
  pointerEvents: 'none',
};

export const Heading: CSSObject = {
  mt: '0',
  pt: '1',
  fontWeight: 'medium',
  fontSize: 'xl',
  textTransform: 'capitalize',
  color: 'beta.800',
  fontFamily: 'accent',
  borderColor: 'whiteAlpha.500',
  borderBottomWidth: '1px',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1px',
    borderColor: 'blackAlpha.50',
    borderStyle: 'dashed',
  },
  '.chakra-form-control': {
    flexDirection: 'row-reverse',
  },
};

export const WinPlaceHeader: CSSObject = {
  color: 'beta.800',
  alignItems: 'center',
  fontFamily: 'body',
  minH: '8',
  fontWeight: 'bold',
  fontSize: '2xs',
  textTransform: 'capitalize',
  _first: {
    fontSize: 'xs',
    flexDirection: 'row-reverse',
  },
};

export const FlexEventRule: CSSObject = {
  pt: '0',
  color: 'beta.800',
};

export const RaceResultsHeader: CSSObject = {
  pt: '1',
  pl: '1',
  fontSize: 'md',
};
