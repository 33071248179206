import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';

import { bannerTextProps } from '../styles/Form/Form.styles';
import Banner from '@/components/Banner/Banner';

export function CardBanner() {
  return (
    <Banner withIcon={false} sx={{ fontSize: 'sm', alignItems: 'flex-start' }}>
      <Text sx={{ ...bannerTextProps }}>
        <FormattedMessage id="account.deposit.creditCardMessage" />
      </Text>
    </Banner>
  );
}
