import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  display: 'flex',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  fontSize: 'xs',
  alignItems: 'center',
  border: '2px',
  borderColor: 'alpha.700',
  w: '12',
  sx: {
    '&&': {
      '&:active, &[data-active="true"]': {
        bgGradient: 'linear(to-b, gamma.600, gamma.600)',
        borderColor: 'transparent',
        boxShadow: 'inset 0px 2px 0px 1px rgba(0, 0, 0, 0.3)',
      },
      boxShadow: 'inset 0px 0px 0px 1px #426DD6',
      p: '2',
      '&:hover': {
        bgGradient: 'linear(to-b, gamma.600, gamma.600)',
        borderColor: 'transparent',
      },
    },
    '&:hover': {
      bgGradient: 'linear(to-b, alpha.700, alpha.700)',
      borderColor: 'alpha.500',
    },
    '&:disabled': {
      borderRadius: 'base',
      '&&': {
        boxShadow: 'none',
        color: 'gray.600',
        bg: 'blackAlpha.200',
      },
      pointerEvents: 'none',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: 'calc(100% + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  borderTop: '1px solid rgba(0, 0, 0, 0.10)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
  borderLeft: '1px solid rgba(0, 0, 0, 0.10)',
  background: 'delta.400',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'alpha.700',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '8px',
  color: 'alpha.700',
  textShadow: 'none',
};
