import React from 'react';
import { TRequestedWithdrawalCardProps } from '../../account.types';
import { createTemplate } from '@/helpers/createTemplate';
import template from './templates/template';

const RequestedWithdrawalCard: React.FC<TRequestedWithdrawalCardProps> = ({
  withdrawalRequest,
  handleCancelWithdrawal,
}) => (
  <>{createTemplate(template(withdrawalRequest, handleCancelWithdrawal))}</>
);

export default RequestedWithdrawalCard;
