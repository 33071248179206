import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Stack,
  Box,
  Flex,
  Text,
  Icon,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@/components/Button/Button';
import { TConfirmedBet } from '@/lib/DBModels';
import { centsToDollars } from '@/helpers/utils';
import { useMutationCashOut } from '@/api/punter/punter.hooks';
import { CashoutStatus, TCashoutResponse } from '@/api/punter/punter.types';
import { getIconAndColor } from './services/CashOutModal.utils';
import { cashOutModalStyles } from './styles/CashOutModal.styles';
import { keys } from '@/api/api.keys';

type TCashOutModal = {
  bet?: TConfirmedBet;
  isOpen: boolean;
  onClose: () => void;
};

export default function CashOutModal({ bet, isOpen, onClose }: TCashOutModal) {
  const [cashoutResponse, setCashoutResponse] = useState<
    TCashoutResponse | undefined
  >(undefined);
  const queryClient = useQueryClient();

  const mutation = useMutationCashOut();

  useEffect(() => {
    setCashoutResponse(undefined);

    Promise.all([
      queryClient.invalidateQueries([keys.punterAccountMyBets]),
    ]).catch(() => undefined);
  }, [isOpen, mutation.isError, queryClient]);

  const handleAccept = () => {
    mutation.mutate(
      {
        amount: (cashoutResponse?.new_cashout_value || bet?.cashout_value) ?? 0,
        bet_id: bet?.bet_id ?? '',
      },
      { onSuccess: (res) => setCashoutResponse(res.data) }
    );
  };

  const cashoutStatus = cashoutResponse?.status || CashoutStatus.Error;
  const { icon: IconComponent, color } = getIconAndColor(cashoutStatus);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg="none"
        backdropFilter="auto"
        backdropInvert="10%"
        backdropBlur="2px"
      />
      <ModalContent {...cashOutModalStyles.modalContent}>
        <ModalHeader {...cashOutModalStyles.moneyValues} pb="0">
          <FormattedMessage id="account.mybets.cashout.CashOut" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack direction="row" {...cashOutModalStyles.stackWrapper}>
            {cashoutResponse &&
            (cashoutStatus === CashoutStatus.Success ||
              cashoutStatus === CashoutStatus.OddsChangeAutoAccept) ? (
              <Box>
                <Text {...cashOutModalStyles.heading}>
                  <FormattedMessage id="account.mybets.cashout.CashedOut" />
                </Text>
                <Text {...cashOutModalStyles.moneyValues}>
                  {centsToDollars(cashoutResponse.new_cashout_value, true)}
                </Text>
              </Box>
            ) : (
              <>
                <Box>
                  <Text {...cashOutModalStyles.heading}>
                    <FormattedMessage id="generic.stake" />
                  </Text>
                  <Text {...cashOutModalStyles.moneyValues}>
                    {centsToDollars(bet?.stake, true)}
                  </Text>
                </Box>
                <Box>
                  <Text {...cashOutModalStyles.heading}>
                    <FormattedMessage id="account.mybets.cashout.EstReturn" />
                  </Text>
                  <Text {...cashOutModalStyles.moneyValues}>
                    {bet?.odds && bet?.stake
                      ? centsToDollars(Number(bet?.stake) * bet?.odds, true)
                      : 'N/A'}
                  </Text>
                </Box>
                <Box>
                  <Text {...cashOutModalStyles.heading}>
                    <FormattedMessage id="account.mybets.cashout.CashOut" />
                  </Text>
                  <Text {...cashOutModalStyles.moneyValues}>
                    {cashoutResponse &&
                      (cashoutStatus === CashoutStatus.OddsChangeAutoAccept ||
                        cashoutStatus ===
                          CashoutStatus.OddsChangeRequireAccept) && (
                        <Icon
                          boxSize="4"
                          mr="1"
                          color={color}
                          as={IconComponent}
                        />
                      )}

                    {centsToDollars(
                      cashoutResponse?.new_cashout_value || bet?.cashout_value,
                      true
                    )}
                  </Text>
                </Box>
              </>
            )}
          </Stack>

          {cashoutResponse ? (
            <Flex
              sx={{
                bg: '#222',
                p: '4',
                my: '3',
                borderRadius: 'md',
                alignItems: 'center',
                gap: '4',
                borderLeft: '4px',
                borderLeftColor: color,
              }}
            >
              <Icon boxSize="8" color={color} as={IconComponent} />
              <Text {...cashOutModalStyles.cashedOutText}>
                <FormattedMessage
                  id={`account.mybets.cashout.${cashoutResponse?.status}`}
                />
              </Text>
            </Flex>
          ) : (
            <Text {...cashOutModalStyles.cashedOutQuestion}>
              <FormattedMessage id="account.mybets.cashout.CashOutQuestion" />
              {centsToDollars(bet?.cashout_value, true)}?
            </Text>
          )}
        </ModalBody>

        <ModalFooter pt="0">
          {cashoutResponse &&
          cashoutResponse?.status !== CashoutStatus.OddsChangeRequireAccept ? (
            <Button onClick={onClose} {...cashOutModalStyles.doneButton}>
              <FormattedMessage id="account.mybets.cashout.Done" />
            </Button>
          ) : (
            <>
              <Button {...cashOutModalStyles.declineButton} onClick={onClose}>
                <FormattedMessage id="account.mybets.cashout.Decline" />
              </Button>
              <Button
                onClick={handleAccept}
                {...cashOutModalStyles.acceptButton}
              >
                <FormattedMessage id="account.mybets.cashout.Accept" />
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
