import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';

import { TStyleConfig } from '../../base/types';
import navBg from '@/assets/juicybet/images/backgrounds/inner.png';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'white',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        bg: 'transparent',
        borderRadius: [null, null, null, 'md'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
        w: 'auto',
        m: [null, null, null, '5px 0px'],
        mb: [null, null, null, '9px'],
        '&&': {
          marginTop: '0',
          marginBottom: '1',
          bg: [
            `linear-gradient(180deg, var(--bc-colors-beta-500) 0%, var(--bc-colors-beta-500) 100%), url(${navBg})`,
            null,
            null,
            null,
          ],
          backgroundBlendMode: 'overlay',
        },
      },
      drawer: {
        ...base.drawer,
        bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${navBg})`,
        backgroundBlendMode: 'overlay',
      },
      drawerBody: {
        '> div, a': {
          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '5',
        color: 'white',
        fill: 'white',
      },
      headerSX: {
        fontFamily: 'Chewy',
        cursor: 'default',
        color: 'gamma.400',
        height: '45px',
        pt: '1',
        '&:hover': {
          borderColor: 'blackAlpha.400',
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
      },
      lastLink: {
        borderBottom: 'none',
      },
      link: {
        ...base.link,

        _first: {
          borderTop: 'none',
        },

        fontSize: 'lg',
        fontWeight: 'normal',
        fontStyle: 'normal',
        height: ['55px', '51px'],
        color: 'white',
        transition: 'all, 0.2s linear',
        fontFamily: 'Chewy',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        textTransform: 'capitalize',
        borderBottom: '1px dashed',
        borderBottomColor: 'blackAlpha.200',
        borderTop: '1px dashed',
        borderTopColor: 'whiteAlpha.200',

        px: '3',
        py: '2',
        ml: '0',

        ':hover': {
          borderBottomColor: 'transparent',
          bg: 'blackAlpha.300',
        },

        ':active': {
          borderBottomColor: 'transparent',
          bg: 'alpha.500',
        },

        '.icon, .svgIcon': {
          color: 'blackAlpha.500',
          fill: 'blackAlpha.500',
          bgGradient: 'linear(to-b, #00F909 0%, #00BE07 100%)',
          boxShadow: '0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
          boxSize: '8',
          borderRadius: 'md',
          border: '2px solid white',
          p: '1',
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
          color: 'white',
          transition: 'all, 0.2s linear',
        },
      },

      dropDownContainerClosed: {
        '> a, .dropDownIcon, a': {
          color: 'white',
        },

        '&:hover, &:active': {
          color: 'white',

          '.dropDownInner': {
            ...base.dropDownInner,
            px: '4',
            color: 'white',
          },

          '.svgIcon, .icon': {
            color: 'white',
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',
        color: 'white',

        _hover: {
          '.dropDownInner': {
            bg: 'blackAlpha.400',
          },
        },

        '.dropDownInner': {
          bg: 'blackAlpha.300',
          color: 'gamma.400',
        },

        '> a, .dropDownIcon, a': {
          color: 'gamma.400',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'gamma.400',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
