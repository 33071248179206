import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
  bg: 'gray.200',
  border: 'none',
  '&[data-checked], &[data-checked]:hover': {
    bg: 'alpha.600',
    borderWidth: '7px',
    borderStyle: 'solid',
    borderColor: 'gray.200',
    color: 'alpha.600',
  },
};

export const ButtonSubmit: CSSObject = {
  color: 'blackAlpha.700',
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    h: '9',
    color: 'gray.700',
  },
  '.chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    fontSize: 'xs',
  },
  '#limitAmount-input': {
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'gray.50',
    borderColor: 'gray.300',
    color: 'gray.600',
    '&&:hover, &&:focus': {
      bg: 'gray.50',
    },
  },
};
