import api from '../api';
import { TLocationResponse } from './location.types';

export const locationEndpoint = 'generic/is-australian';

export type LocationQueryResp = {
  isAustralian?: boolean;
  networkErr?: boolean;
};

export const queryLocation = (): LocationQueryResp =>
  api
    .get<TLocationResponse>(locationEndpoint)
    .then(() => ({ isAustralian: true, networkErr: false }))
    .catch((error) => {
      if (
        error.code &&
        (error.code === 'ECONNABORTED' ||
          error.code === 'ETIMEOUT' ||
          error.code === 'ERR_NETWORK' ||
          error.code === 'ERR_CANCELED' ||
          error.code === 'ERR_NAME_NOT_RESOLVED')
      ) {
        return { isAustralian: true, networkErr: false };
      }
      const errMsg = error.toJSON && error.toJSON().message === 'Network Error';
      if (errMsg) return { networkErr: true };
      if (error.response?.status === 403) return { isAustralian: false };
      return { isAustralian: !!error.response };
    });
