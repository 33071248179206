import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import background from '@/assets/fiestabet/images/backgrounds/betslip-bg-box3x.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const BetCardContainer = (): CSSObject => ({
  backgroundImage: `url(${background})`,
  borderRadius: 'base',
  color: 'alpha.500',
  boxShadow: '0px 2px 0px 0px #0C6B5A',
  mt: '1.5',
  bgRepeat: 'round',
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  ml: '1',
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'beta.500',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  pt: '0.5',
});

export const EventSubtitle: CSSObject = {
  color: 'white',
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  pt: '0.5',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'white',
  ml: '7',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const ExoticsNumberContainer: CSSObject = {
  w: '6',
  fontWeight: 'bold',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(to bottom, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%);',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF;',
  borderRadius: 'sm',
  flexDirection: 'column',
  color: 'black',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'normal',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '0.5',
};

export const StakeButton: CSSObject = {
  h: '9',
  fontFamily: 'SkranjiBold',
  bgGradient: 'linear(to bottom, delta.400, delta.600)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  border: 'var(--bc-colors-delta-700) 2px solid',
  color: 'rgba(0, 0, 0, 0.64)',
  textShadow: '0px 1px 2px rgba(255, 255, 255, 0.40)',
  borderRadius: 'lg',
  fontWeight: 'medium',
  _hover: {
    bgGradient: 'linear(to bottom, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },
  _first: {
    borderStartRadius: 'lg',
  },
  _last: {
    borderStartRadius: 'lg',
  },
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'beta.500',
  boxShadow: 'md',
  color: 'black',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
  _hover: {
    bg: 'beta.700',
    color: 'white',
    boxShadow: 'md',
  },
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
  fontWeight: '500',
  color: 'gray.600',
  fontsize: '2xs',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
  color: 'gray.600',
  fontsize: '2xs',
  fontWeight: 'bold',
};

export const buttonBonusBetsProps = (
  isBonusBet: boolean,
  isInReview: boolean
): CustomButtonProps => ({
  display: 'flex',
  variant: 'unstyled',
  size: 'xs',
  px: '3',
  alignSelf: 'flex-start',
  textTransform: 'capitalize',
  w: isInReview ? '100%' : 'auto',
  isDisabled: isInReview,
  _hover: {
    bg: isBonusBet ? 'blackAlpha.400' : 'beta.600',
    color: 'white',
    border: isBonusBet ? '1px' : 'none',
    textShadow: 'none',
    boxShadow: 'none',
  },

  _active: {
    boxShadow: 'none',
  },

  _disabled: {
    border: 'none',
    bg: 'blackAlpha.500',
    color: 'alpha.200',
    opacity: '1',
    textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
    mt: '-10px',
    h: '7',
    '& > span': {
      _first: {
        mr: '1',
      },
    },
  },

  ...((): CustomButtonProps => {
    if (isBonusBet && isInReview)
      return {
        mt: '-3',
        bg: 'blackAlpha.300',
        borderRadius: 'none',
        borderBottomRadius: 'md',
        sx: {
          '.chakra-button__icon': {
            color: 'beta.200',
          },
        },
        spanProps: {
          fontFamily: 'roboto',
          textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
          fontWeight: 'medium',
          fontSize: '2xs',
          py: '2',
          color: 'beta.200',
          textTransform: 'uppercase',
        },
        _hover: {
          border: 'none',
        },
      };

    if (isBonusBet)
      return {
        bg: 'beta.500',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset',
        color: 'beta.100',
        borderRadius: 'md',
        border: '1px',
        borderColor: 'beta.300',
        sx: {
          '.chakra-button__icon': {
            mr: '0',
            ml: '1',
          },
        },
        spanProps: {
          fontFamily: 'roboto',
          textShadow: 'none',
          fontWeight: 'medium',
          fontSize: '2xs',
          px: '0.5',
          py: '1',
        },
      };

    return {
      bg: 'none',
      color: 'beta.700',
      borderRadius: 'md',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 0px 0px rgba(0, 0, 0, 0.25)',
      bgGradient: 'linear(to-b, beta.200, beta.400)',
      sx: {
        '.chakra-button__icon': {
          mr: '0',
          ml: '1',
        },
      },
      spanProps: {
        fontFamily: 'roboto',
        textShadow: 'none',
        fontWeight: 'medium',
        fontSize: '2xs',
        px: '1',
        py: '1.5',
      },
    };
  })(),
});

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgba(255, 223, 125, 0.5)',
  borderRadius: 'base',
  px: '2',
  h: 'calc(100% + 2px)',
};
export const EventRule: CSSObject = {
  color: 'white',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'beta.500' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5', '&:hover': { color: 'white' } }} />
);
export const buttonStakeProps: CustomButtonProps = {
  variant: 'secondary',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 3px #89141D inset',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontWeight: 'bold',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
  _after: {
    content: `''`,
    bgGradient: 'unset',
    boxShadow: 'unset',
    transition: 'unset',
    borderRadius: 'unset',
  },
};
export const buttonBetPropositionClosedProps: CustomButtonProps = {
  variant: 'unstyled',
  px: '7',
  zIndex: '1',
  bg: 'delta.600',
  boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.25)',
  color: 'black',
  borderRadius: 'md',
  fontSize: 'xs',
  display: 'flex',
  gap: '1',
  spanProps: {
    color: 'epsilon.100',
    fontFamily: 'roboto',
    fontSize: 'xs',
    fontWeight: 'bold',
    px: '3.5',
    py: '2',
    textTransform: 'uppercase',
  },
  _hover: {
    bg: 'delta.700',
    color: 'epsilon.100',
    boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.25)',
  },
};
