import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: ['2.5', null, null, '3'],
  pb: '4',
};

export const BoxSectionWrapper: CSSObject = {
  mb: '4',
  px: '0',
  mt: '0',

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderRadius: 'lg',
  bg: 'transparent',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  border: 'unset',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'sm',
  mt: '2',
};

export const TextName: CSSObject = {
  color: 'alpha.900',
  fontWeight: 'bold',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  bg: 'white',
  border: 'none',
  mb: '1px',
  _last: { mb: '0' },
  _hover: {
    bg: 'whiteAlpha.500',
  },
};

export const IconSport: CSSObject = {
  color: 'alpha.400',
};

export const IconChevron: CSSObject = {
  boxSize: ['4', '5'],
  border: '1px solid',
  borderColor: 'alpha.900',
  borderRadius: 'full',
  p: '1',
  color: 'alpha.900',
  transition: 'inherit',
};
