import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  h: ['50px', null, '80px'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'accent',
};

export const TextItem: CSSObject = {
  color: ['white', null, 'alpha.400'],
  textAlign: 'center',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'normal',
};

export const LinkItem: CSSObject = {
  color: ['beta.500', null, 'alpha.400'],
  fontWeight: 'bold',
};
