import React from 'react';
import { PersonOutline } from '@styled-icons/evaicons-outline/PersonOutline';
import { InformationCircle } from '@styled-icons/ionicons-outline/InformationCircle';
import {
  Flex,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Text,
  Icon,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Accordion,
  AccordionHeader,
  AccordionHeaderLogOut,
  FlexContactToChange,
  IconPerson,
  TextAccordion,
  OverviewAccordionItem,
  OverviewPromotionsPanel,
} from './styles/OverviewAccordion.styles';
import PunterDetail from '../PunterDetail/PunterDetail';
import { getThemeName } from '@/helpers/getThemeName';
import NotificationsForm from '../../../PromotionsNotifications/Form';
import PreferencesForm from '../../../PromotionPreferences/Form';
import { useLogout } from '../../../../../components/SideNav/hooks/SideNav.hooks';
import { useLiveChat } from '@/hooks/useLivechat';
import { ReactComponent as LogOutSVG } from '../../../../../assets/core/icons/logout.svg';
import { ReactComponent as MegaphoneSVG } from '../../../../../assets/core/icons/megaphone.svg';
import { ReactComponent as PromoPrefSVG } from '../../../../../assets/core/icons/promPref.svg';
import { usePunterData } from '@/store/AuthStore';
import { getFeatureFlag } from '@/store/FeatureFlagStore';

const OverviewAccordion: React.FC = () => {
  const intl = useIntl();
  const themeName = getThemeName();
  const { showLiveChat } = useLiveChat();
  const punterProfile = usePunterData();
  const { PUNTER_LUCKY_DIP_PREFERENCE: isPunterLuckyEnabled } =
    getFeatureFlag() ?? {};

  const logout = useLogout();
  if (!punterProfile) return <></>;

  return (
    <Flex flexDir="column" gap="2" mt="2">
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem key="key">
          <AccordionHeader>
            <AccordionButton p="2">
              <IconPerson as={PersonOutline} />
              <TextAccordion>
                <FormattedMessage id="account.overview.details" />
              </TextAccordion>
              <AccordionIcon />
            </AccordionButton>
          </AccordionHeader>

          <AccordionPanel display="flex" flexDir="column" gap="2" p="2">
            <Flex gap="2" flexDir={['column', null, 'row']}>
              {punterProfile.title && (
                <PunterDetail
                  label={intl.formatMessage({ id: 'account.overview.title' })}
                  info={punterProfile.title}
                  sxWrapper={{ maxW: '80px' }}
                />
              )}
              <PunterDetail
                label={intl.formatMessage({ id: 'account.overview.firstName' })}
                info={punterProfile.first_name}
              />
              {punterProfile.middle_name && (
                <PunterDetail
                  label={intl.formatMessage({
                    id: 'account.overview.middleName',
                  })}
                  info={punterProfile.middle_name}
                />
              )}
              <PunterDetail
                label={intl.formatMessage({ id: 'account.overview.lastName' })}
                info={punterProfile.last_name}
              />
            </Flex>
            <PunterDetail
              label={intl.formatMessage({ id: 'account.overview.email' })}
              info={punterProfile.email}
            />
            <PunterDetail
              label={intl.formatMessage({ id: 'account.overview.mobile' })}
              info={punterProfile.mobile}
            />
            <PunterDetail
              label={intl.formatMessage({
                id: 'account.overview.residentialAddress',
              })}
              info={`${punterProfile.street_number} ${punterProfile.street_name}, ${punterProfile.suburb}, ${punterProfile.state} ${punterProfile.post_code}`}
            />
            <PunterDetail
              label={intl.formatMessage({ id: 'account.overview.dateOfBirth' })}
              info={dayjs(punterProfile.date_of_birth).format('DD/MM/YYYY')}
            />
            <FlexContactToChange>
              <Icon boxSize="5" as={InformationCircle} />
              <Text>
                <FormattedMessage
                  id="account.overview.toChange"
                  values={{
                    bu: (string: string) => (
                      <Text
                        as="span"
                        sx={{
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => showLiveChat()}
                      >
                        {string}
                      </Text>
                    ),
                    themeName,
                  }}
                />
              </Text>
            </FlexContactToChange>
          </AccordionPanel>
        </AccordionItem>

        <OverviewAccordionItem>
          <AccordionHeader>
            <AccordionButton p="2">
              <IconPerson as={MegaphoneSVG} />
              <TextAccordion>
                <FormattedMessage id="account.overview.marketingPreferences" />
              </TextAccordion>
              <AccordionIcon />
            </AccordionButton>
          </AccordionHeader>
          <OverviewPromotionsPanel>
            <NotificationsForm />
          </OverviewPromotionsPanel>
        </OverviewAccordionItem>

        {isPunterLuckyEnabled && (
          <OverviewAccordionItem>
            <AccordionHeader>
              <AccordionButton p="2">
                <IconPerson as={PromoPrefSVG} />
                <TextAccordion>
                  <FormattedMessage id="account.overview.promotionalPreferences" />
                </TextAccordion>
                <AccordionIcon />
              </AccordionButton>
            </AccordionHeader>
            <OverviewPromotionsPanel>
              <PreferencesForm />
            </OverviewPromotionsPanel>
          </OverviewAccordionItem>
        )}
      </Accordion>

      <AccordionHeaderLogOut onClick={logout}>
        <IconPerson as={LogOutSVG} />
        <TextAccordion>
          <FormattedMessage id="account.overview.logout" />
        </TextAccordion>
      </AccordionHeaderLogOut>
    </Flex>
  );
};

export default OverviewAccordion;
