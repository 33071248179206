import { css } from 'styled-components';

export const tab = css`
  font-size: var(--bc-fontSizes-xs);
  padding: var(--bc-sizes-2) var(--bc-sizes-5);
  cursor: pointer;
  letter-spacing: 0.2px;
  transition: all 0.1s linear;
  white-space: nowrap;
  line-height: var(--bc-lineHeights-tall);
  color: ${({ theme }) => theme.basePalette.offWhite};
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.basePalette.brightBlue};
    color: ${({ theme }) => theme.basePalette.darkGrey};
  }
`;

export const selectedTab = css`
  color: ${({ theme }) => theme.basePalette.darkGrey};
  background-color: ${({ theme }) => theme.palette.primary};
`;

export const tabsWrapper = css`
  background-color: ${({ theme }) => theme.palette.secondary};
  padding: 0 var(--bc-sizes-5);
  ${({ theme }) => theme.device.laptop} {
    padding: 0;
  }
`;

export const tabsContainer = css`
  overflow-x: auto;
`;

export const tabsContentWrapper = css``;

// TODO: Remove
export const tabsContentContainer = css`
  padding-top: var(--bc-sizes-5);
`;
