import { ButtonProps, CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};
export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};
export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};
export const DepositLimitGrid: CSSObject = {
  pb: '20',
};
export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};
export const DepositButton: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const CancelButton: CSSObject = {
  variant: 'secondary',
};
export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};
export const DepositLimitContainer: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  p: '4',
  color: 'white',
  fontWeight: '700',
};
export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};
export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '#frequency-select': {
    bg: 'blackAlpha.400',
    shadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-select__icon-wrapper': {
    color: 'alpha.400',
  },
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.100, alpha.500)',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, alpha.100, alpha.500)',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    bg: 'beta.700',
    border: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'beta.600',
      border: 'none',
    },
    _focus: {
      bg: 'beta.600',
      boxShadow: 'none',
      border: '2px solid',
      borderColor: 'alpha.300',
      m: '0',
      h: '42px',
    },
  },
};
export const ModalContentWrapper: CSSObject = {
  p: '0',
};
export const ModalDivider: CSSObject = {
  display: 'none',
};
export const buttonRemoveProps: CSSObject = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.400',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(255, 255, 255, 0.5)',
  _hover: {
    bg: 'alpha.400',
    border: 'none',
    boxShadow: 'none',
  },
  _active: {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      opacity: 0.4,
    },
  },
};
export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  fontWeight: 'normal',
  width: 'full',
};
export const buttonConfirmModalProps: ButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  width: '50%',
};
