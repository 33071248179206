import React from 'react';
import Aside from '../../components/Aside/Aside';
import Content from '../components/Content/Content';
import { WrapperOnboarding } from '../../styles/onboarding.styles';
import CloseBtn from '../../components/CloseBtn/CloseBtn';

export const TemplateSignup = [
  { name: 'wrapper', component: <WrapperOnboarding /> },
  { name: 'aside', component: <Aside /> },
  { name: 'content', component: <Content /> },
  { name: 'closeBtn', component: <CloseBtn /> },
];

export default TemplateSignup;
