import { TextProps, CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';
import { TFormControl } from '@/components/FormElements/HOCs/FormControl';

export const titleProps: TextProps = {
  display: 'flex',
  justifyContent: ['space-between', null, 'flex-start'],
  gap: '1.5',
};

export const inputProps: Partial<TFormControl> = {
  sxWrapper: {
    mb: '8px',
  },
};

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
  color: 'gray.500',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  textTransform: 'unset',
  color: 'gray.500',
};

export const RadioLimitChoice: CSSObject = {
  mb: 0,
  alignSelf: 'flex-start',
};

export const RadioButtonWrapper: CSSObject = {
  gap: '12px',
};

export const DepositLimitContainer: CSSObject = {
  p: '16px',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: '4px',
};
