export const Select = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      color: 'black',

      '&&': { '&:focus, &:active, &:hover': { bg: 'white' } },
    },
    icon: { color: 'black' },
  },
  sizes: {
    lg: { field: { borderRadius: 'sm' } },
    md: { field: { borderRadius: 'sm' } },
    sm: { field: { borderRadius: 'sm' } },
  },
};
