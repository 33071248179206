import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  CenterWrapper,
  LocationNotAllowedContainer,
} from '../styles/LocationNotAllowed.styles';
import { Mascot } from '../../Maintenance/styles/Maintenance.styles';
import Content from '../Content';

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  heading: <Mascot m="0 auto" />,
  main: {
    wrapper: <CenterWrapper />,
    body: <Content />,
  },
};

export default template;
