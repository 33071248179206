import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'beta.600',
  bgSize: 'cover',
  bgRepeat: 'repeat',
  h: '100%',
  maxH: '100dvh',
  borderRadius: 'lg',
  pos: 'relative',
  boxShadow:
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
  '&:before': {
    content: ['none', null, '""'],
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '8px',
  mx: '3px',
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
export const BetSlipContainer: BoxProps = {
  borderRadius: 'base',
};
export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};
export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
};
export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'normal',
  color: 'alpha.400',
  fontFamily: 'accent',
  fontSize: 'sm',
});
export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};
export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};
export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '6',
  width: 'auto',
  margin: 'auto',
  mt: '-4',
  pl: 1,
  pr: 2,
  display: 'flex',
  color: 'beta.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.4)',
  fontSize: '2xs',
  fontWeight: 'black',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.100, alpha.500)',
  textTransform: 'uppercase',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300, alpha.700)',
  },
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3.5',
    },
  },
};
export const BetslipVStack: CSSObject = {
  gap: '2.5',
};
export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};
export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  height: '42px',
  sx: {
    '&&': {
      fontWeight: 'normal',
    },
  },
};
