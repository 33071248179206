import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  color: 'gamma.200',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const TextSubheading: CSSObject = {
  color: 'gamma.200',
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const Subheading: CSSObject = {
  color: 'gamma.200',
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'blackAlpha.400',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};
export const DepositLimitGrid: CSSObject = {
  pb: '20',
};
export const ModalSubheading: CSSObject = {
  color: 'gamma.200',
  p: '0',
};
export const DepositButton: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const CancelButton: CSSObject = {
  variant: 'secondary',
};
export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};
export const DepositLimitContainer: CSSObject = {
  bg: 'gamma.200',
  fontFamily: 'body',
  borderRadius: 'md',
  p: '4',
  color: 'alpha.800',
  fontWeight: '700',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
};
export const PendingLimitContainer: CSSObject = {
  border: 'none',
  fontWeight: '700',
};
export const LimitLabel: CSSObject = {
  color: 'alpha.800',
};
export const LimitValue: CSSObject = {
  fontWeight: 'medium',
};
export const ModalContentWrapper: CSSObject = {
  p: '0',
};
export const ModalDivider: CSSObject = {
  display: 'none',
};

export const buttonRemoveProps: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.500 0%, gamma.600 100%)',
  bg: 'gamma.600',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  color: 'blackAlpha.700',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.300 100%)',
    bg: 'gamma.300',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    color: 'blackAlpha.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.500',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvent: 'none',
    bgGradient: 'linear(180deg, gamma.500 0%, gamma.600 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  fontWeight: 'normal',
  width: 'full',
};
export const buttonConfirmModalProps: ButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  width: '50%',
};
export const inputProps: Partial<TIndex> = {
  sx: {
    '&&': {
      fontWeight: 'bold',
      bg: 'gamma.800',
      border: 'none',
      borderRightRadius: 'base',
      fontSize: 'sm',
      '&&:hover': {
        border: 'none',
        bg: 'gamma.900',
      },
      '&&:focus': {
        border: 'none',
        bg: 'alpha.700',
        '&&:hover': {
          bg: 'alpha.700',
        },
      },
    },
  },
};

export const DepositLimitModalContentWrapper: CSSObject = {
  '&&': {
    color: 'gamma.200',
  },
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '#frequency-select': {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    color: 'gamma.200',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-form__error-message': {
    bg: 'orange.300',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'orange.800',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
