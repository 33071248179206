import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  borderRadius: 'unset',
  px: '0',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  _hover: {
    bg: 'blackAlpha.100',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'zeta.400',
  boxShadow:
    '0px 1px 0px 0px #38190B inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px rgba(255, 255, 255, 0.15) inset;',
  mx: ['2', '4'],
  borderRadius: 'base',
  mb: '4',
};

export const Icon: CSSObject = {
  color: 'beta.500',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
};

export const TextRace: CSSObject = {
  color: 'alpha.600',
  fontWeight: 'medium',
};

export const TextNumber: CSSObject = {
  bg: 'epsilon.600',
  color: 'white',
  h: '5',
  py: '0.5',
  pt: '1',
};

export const BoxHeading: CSSObject = {
  display: 'none',
};

export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    bg: 'delta.600',
    color: 'gamma.700',
  },
};
