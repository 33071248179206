import { Flex } from '@chakra-ui/react';
import React from 'react';
import { centsToDollars } from '@/helpers/utils';
import { ETransactionSubType, TTransaction } from '@/lib/DBModels';
import { useTransaction } from '../../Services/Transactions.hooks';
import {
  TableText,
  transactionDateTimeFlexProps,
  transactionInfoFlexProps,
  TransactionWrapper,
} from './styles/MobileTransactionsTable.styles';
import MultiLegDetails from '../DesktopTransactionsTable/components/MultiLegDetails';
import SgmDetails from '../DesktopTransactionsTable/components/SgmDetails';
import BlendedDetails from '../DesktopTransactionsTable/components/BlendedDetails';
import SrmDetails from '../DesktopTransactionsTable/components/SrmDetails';
import { ToteMultiDetails } from '../DesktopTransactionsTable/components/ToteMultiDetails';

type TTransactionItemProps = {
  trans: TTransaction;
  index: number;
};

const TransactionItem: React.FC<TTransactionItemProps> = ({ trans, index }) => {
  const {
    transactionPolarity,
    transDate,
    transDateTime,
    transRefundVoid,
    formattedTransactionType,
    formattedTransactionDetails,
  } = useTransaction(trans);

  return (
    <TransactionWrapper key={trans.transaction_id}>
      <Flex
        justifyContent="space-between"
        flex="1"
        w="full"
        {...transactionDateTimeFlexProps}
      >
        <Flex flexDir="column">
          <TableText fontWeight="bold">{transDate}</TableText>
          <TableText>{transDateTime}</TableText>
        </Flex>
        <Flex flexDir="column" textAlign="right">
          <TableText align="right" fontWeight="bold">
            {formattedTransactionType}
          </TableText>
          <TableText
            data-cy="transactionAmount"
            align="right"
            fontWeight="bold"
            textVariation={transRefundVoid ? 'neutral' : transactionPolarity}
            textAlign="right"
          >
            {trans?.amount && trans?.amount > 0 ? '+' : null}
            {centsToDollars(trans?.amount)}
          </TableText>
          <TableText align="right">
            {centsToDollars(trans?.running_balance ?? 0)}
          </TableText>
        </Flex>
      </Flex>
      <Flex flexDir="column" {...transactionInfoFlexProps}>
        {trans.sub_type !== ETransactionSubType.Multi &&
          formattedTransactionDetails?.map((detail, i) => (
            <TableText
              data-cy={`transactionDetails-${index}-${i}`}
              fontSize={detail?.includes('Bet ID') ? 'xs' : 'sm'}
              key={`transactionDetails-${index}-${i}`}
            >
              {detail}
            </TableText>
          ))}
        {trans.sub_type === ETransactionSubType.ToteMulti && (
          <ToteMultiDetails transaction={trans} />
        )}
        {trans.sub_type === ETransactionSubType.Multi && (
          <MultiLegDetails details={trans} />
        )}
        {trans.sub_type === ETransactionSubType.SGMulti && (
          <SgmDetails transaction={trans} />
        )}
        {trans.sub_type === ETransactionSubType.SRMulti && (
          <SrmDetails transaction={trans} />
        )}
        {trans.sub_type === ETransactionSubType.Blended && (
          <BlendedDetails transaction={trans} />
        )}
      </Flex>
    </TransactionWrapper>
  );
};

export default TransactionItem;
