import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    position: [null, null, null, 'relative'],
  },
  boxWrapperProps: {
    position: [null, null, null, 'relative'],
  },
  boxNavigationWrapperProps: {
    display: 'flex',
    flexDirection: 'column',
    gap: ['0', '0', '0', '4'],
    width: ['100%', '100%', '100%', '272px'],
    background: [
      'var(--gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
      null,
      null,
      'transparent',
    ],
  },
  navigationBoxProps: {
    borderRadius: ['0px', '0px', '0px', '5px'],
    boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.25)',
    borderTop: '1px solid rgba(255, 255, 255, 0.15)',
    background: [
      'transparent',
      null,
      null,
      'var(--gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
    ],

    _first: {
      borderRadius: ['0px', '0px', '0px', '5px'],
    },
  },
};
