import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapper: BoxProps = {};

export const TransactionWrapper: CSSObject = {
  color: 'white',
};

export const TableText = () => ({
  color: 'white',
});
