import { DuotoneSgmLogoSchema } from '@/components/DuotoneSgmLogo/styles/DuotoneSgmLogo.styles';

export const duotoneSgmLogoStyles: DuotoneSgmLogoSchema = {
  boxWrapper: {
    w: '10',
    pos: 'relative',
    _before: {
      bottom: '17.5%',
      content: '""',
      pos: 'absolute',
      right: '7.5%',
      top: '17.5%',
      w: '72.5%',
    },
  },
  sgmLogo: {
    boxSize: 'auto',
    display: 'block',
    pos: 'relative',
    color: 'gamma.300',
  },
};
