import { css } from 'styled-components';

// TODO: Remvoe
export const runnerListItemWrapper = css`
  border-top: 1px solid ${({ theme }) => theme.palette.transparentBlue};
  padding: var(--bc-sizes-3);
`;

// TODO: Remoce
export const runnerListItemWrapperScratched = css`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.transparentBlue};
  background-color: var(--bc-colors-blackAlpha-500);
  padding: var(--bc-sizes-3);
`;

// TODO: Remove
export const runnerListItemContainer = css`
  align-items: center;
  justify-content: space-between;
`;

// TODO: Remove
export const runnerNameAndSilk = css`
  align-items: center;
  flex: 2;
`;

// TODO: Remove
export const runnerPropositionWrapper = css`
  flex: 1;
  column-gap: var(--bc-sizes-2);
`;

// TODO: Remove
export const runnerPropositionWrapperHalf = css`
  flex: 0.5;
  column-gap: var(--bc-sizes-2);
`;

// TODO: Remove
export const runnerPropositionButton = css`
  flex: 1;
  padding: var(--bc-sizes-2);
  align-items: center;
  background: var(--bc-colors-delta-500);
  border-radius: var(--bc-radii-md);
  justify-content: center;
  color: white;
  font-size: var(--bc-fontSizes-md);
  font-weight: var(--bc-fontWeights-bold);
  transition: all, 0.1s linear;
  cursor: pointer;
  box-shadow: 0 var(--bc-sizes-1) 0 0 var(--bc-colors-epsilon-500);

  &:hover {
    background: var(--bc-colors-epsilon-600);
  }

  &:disabled {
    box-shadow: none;
    cursor: default;
  }

  &:disabled:hover {
    background: var(--bc-colors-delta-500);
  }

  &:active {
    box-shadow: 0 var(--bc-sizes-0-5) 0 0 var(--bc-colors-epsilon-500);
    transform: translateY(4%);
  }
`;

// TODO: Remove
export const runnerPropositionButtonClicked = css`
  background: var(--bc-colors-accent-500);
  color: var(--bc-colors-epsilon-500);

  &:hover {
    background: var(--bc-colors-accent-500);
  }
`;

export const runnerPlaceProposition = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: var(--bc-sizes-2) 0;
  margin-left: var(--bc-sizes-1);
  font-size: var(--bc-fontSizes-md);
  font-weight: var(--bc-fontWeights-normal);
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--bc-colors-epsilon-500);
  border: 1px solid ${({ theme }) => theme.basePalette.midPurple};
  border-radius: var(--bc-radii-md);
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.palette.btnHover};
  }
`;

export const runnerListItemPropositionHistory = css`
  margin-top: var(--bc-sizes-2);
  width: 60%;
`;

export const propositionHistoryItem = css`
  text-align: left;
  align-items: center;
  align-items: center;
  flex: 1;
`;

// TODO: Remvoe
export const runnerName = css`
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-md);
  font-weight: 600;
`;

// TODO: Remove
export const greyhoundRunnerSilk = css`
  margin-right: var(--bc-sizes-2);
  border-radius: var(--bc-sizes-1);
  max-height: var(--bc-sizes-10);
`;

// TODO: Remove
export const horseHarnessRunnerSilk = css`
  margin-right: var(--bc-sizes-2);
  border-radius: 8px;
  max-height: var(--bc-sizes-9);
  padding: 2px 4px;
  background: ${({ theme }) => theme.palette.white};
`;

// TODO: REmove
export const scratchedText = css`
  flex: 2;
  text-align: center;
  font-size: var(--bc-fontSizes-md);
  font-weight: 300;
  color: ${({ theme }) => theme.basePalette.blue};
`;

export const propositionHistoryItemValue = {
  down: css`
    font-size: var(--bc-fontSizes-xs);
    color: ${({ theme }) => theme.basePalette.faintGrey};
  `,
  same: css`
    font-size: var(--bc-fontSizes-xs);
    color: ${({ theme }) => theme.palette.white};
  `,
  up: css`
    font-size: var(--bc-fontSizes-xs);
    color: ${({ theme }) => theme.palette.primary};
  `,
};

export const propositionHistoryItemIcon = {
  up: css`
    width: var(--bc-sizes-2);
    height: var(--bc-sizes-2);
    margin-left: var(--bc-sizes-0-5);

    transform: rotate(180deg);
    color: ${({ theme }) => theme.palette.primary};
  `,
  down: css`
    width: var(--bc-sizes-2);
    height: var(--bc-sizes-2);
    margin-left: var(--bc-sizes-0-5);
    color: ${({ theme }) => theme.basePalette.faintGrey};
  `,
  same: css`
    width: var(--bc-sizes-2);
    height: var(--bc-sizes-2);
    margin-left: var(--bc-sizes-0-5);
    color: transparent;
  `,
};

export const propositionHistoryItemArrowIcon = css`
  width: var(--bc-sizes-4);
  height: var(--bc-sizes-4);
  margin-right: var(--bc-sizes-1);
`;
