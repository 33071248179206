export const colors = {
  alpha: {
    100: '#1BD5FF',
    200: '#00BFEA',
    300: '#225CA1',
    400: '#284985',
    500: '#1D3461',
    600: '#1A2F56',
    700: '#121F3F',
    800: '#0E1C37',
    900: '#0A1221',
  },
  beta: {
    100: '#FFBA95',
    200: '#FF965D',
    300: '#FF6E20',
    400: '#F36417',
    500: '#F95D3C',
    600: '#F95D3C',
    700: '#DC4221',
    800: '#FAC83D',
    900: '#354A03',
  },
};
