import React from 'react';
import {
  CenterWrapper,
  MaintenanceContainer,
  Sign,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import Content from '../components/Content/Content';

const template = (): TTemplate => ({
  wrapper: <div />,
  center: {
    wrapper: <MaintenanceContainer />,
    main: {
      wrapper: <CenterWrapper />,
      body: <Content />,
      extras: <Sign />,
    },
  },
});

export default template;
