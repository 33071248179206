import { AspectRatio, Box, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import banner from '@/assets/puntgenie/images/banner/banner.png';
import logoutBanner from '@/assets/puntgenie/images/banner/logoutBanner.png';
import mobilebanner from '@/assets/puntgenie/images/banner/mobilebanner.png';
import logoutMobileBanner from '@/assets/puntgenie/images/banner/logoutBannerMobile.png';

import Carousel from '@/components/Carousel';
import { useAuth } from '@/hooks/useAuth';

export default function Index() {
  const [isSmallerThan480] = useMediaQuery('(max-width: 580px)');
  const { isAuthenticated } = useAuth();

  return (
    <Box
      sx={{
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          ...(isSmallerThan480
            ? [
                <AspectRatio key="smaller-than-slider" w="100%" ratio={1}>
                  <Box
                    sx={{
                      bgImage: [
                        isAuthenticated ? mobilebanner : logoutMobileBanner,
                      ],
                      bgSize: ['cover'],
                      bgPos: ['center'],
                      bgRepeat: 'no-repeat',
                      borderBottom: '4px',
                      borderColor: ['transparent', null, 'white'],
                    }}
                  />
                </AspectRatio>,
              ]
            : [
                <Box
                  key="larger-than-slider"
                  sx={{
                    w: 'full',
                    h: ['250px'],
                    bgImage: [
                      isAuthenticated ? mobilebanner : logoutMobileBanner,
                      isAuthenticated ? banner : logoutBanner,
                    ],
                    bgSize: ['cover', '100%'],
                    bgPos: ['center', 'center bottom'],
                    bgRepeat: 'no-repeat',
                    borderBottom: '4px',
                    borderColor: ['transparent', null, 'white'],
                  }}
                />,
              ]),
        ]}
      />
    </Box>
  );
}
