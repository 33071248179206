import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { MatchesModalSchema } from '@/views/sports/components/MatchesModal/styles/MatchesModal.styles';

export const matchesModalStyles: MatchesModalSchema = {
  modalWrapper: { size: 'lg', isCentered: true, scrollBehavior: 'inside' },
  modalContent: {
    bgGradient: 'linear(to-b, zeta.400, zeta.600)',
    color: 'gray.600',
    overflow: 'hidden',
  },
  modalHeader: {
    alignItems: 'center',
    borderBottom: '1px',
    borderColor: 'blackAlpha.200',
    display: 'flex',
    gap: '4',
    justifyContent: 'space-between',
    p: '4',
    color: 'alpha.600',
  },
  closeButton: {
    bg: 'alpha.600',
    color: 'zeta.400',
    isRound: true,
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: { bg: 'alpha.700' },
  },
  divider: { as: 'div', borderColor: 'blackAlpha.200', h: '1px' },
  modalBody: {
    p: '0',
    maxH: '400px',
    sx: {
      '::-webkit-scrollbar': {
        bgGradient: 'linear(to-b, zeta.400, zeta.600)',
        w: '6px',
      },
      '::-webkit-scrollbar-thumb': {
        bg: 'blackAlpha.500',
        borderRadius: 'xl',
      },
    },
  },
  stackWrapper: { spacing: '0' },
  linkWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '3',
    justifyContent: 'space-between',
    p: '3',
    _hover: { bg: 'gray.100', textDecoration: 'none' },
  },
  flexSportName: {
    align: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    gap: '1',
    color: 'alpha.600',
  },
  iconSportName: { fontSize: '2xl', color: 'alpha.600' },
  hStack: { spacing: '1' },
  countdown: {
    color: 'inherit',
    fontSize: '9px',
    h: '17px',
    pt: '0',
    sx: {
      '&&': {}, // Reset
      ':not(.state-prior)': { color: 'white' },
    },
  },
  duotoneSgmLogoOverride: {
    h: 'auto',
    w: '9',
    sx: {
      svg: {
        color: 'alpha.700',
      },
    },
  },
  hStackIcon: {
    as: ChevronRight,
    color: 'alpha.400',
    fontSize: '2xl',
  },
};
