import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  px: 3,
  py: 2,
  mx: 2,
  mb: 2,
  borderRadius: 'lg',
  bg: 'alpha.900',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(2.5px)',
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'alpha.100' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'gamma.300',
  fontSize: 'xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'alpha.600',
  boxSize: '32px',
  background:
    'var(--gradients-gradient-gamma-300-500, linear-gradient(180deg, #FFD562 0%, #DEA913 100%)) !important',

  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12)',
  backdropFilter: 'blur(2.5px)',
  borderRadius: 'md',
  p: '1',
};

export const IconChevron: CSSObject = {
  color: 'white',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: 'xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.200',
    boxSize: '5',
  },
};
