import React, { ReactElement } from 'react';
import { Show } from '@chakra-ui/react';
import Switch from '../components/Switch/Switch';
import TextRacing from '../components/TextRacing/TextRacing';
import TextSport from '../components/TextSport/TextSport';
import { FlexWrapper } from '../styles/Toggle.styles';

const Wrapper = ({ children }: { children?: ReactElement }) => (
  <Show below="md">
    <FlexWrapper>{children}</FlexWrapper>
  </Show>
);

export const TemplateToggle = [
  { name: 'wrapper', component: <Wrapper /> },
  { name: 'textRace', component: <TextRacing /> },
  { name: 'switch', component: <Switch /> },
  { name: 'textSport', component: <TextSport /> },
];

export default TemplateToggle;
