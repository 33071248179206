import { SelectionStylesSchema } from './Selections.styles';

export const selectionStyles: SelectionStylesSchema = {
  errorWrapper: {
    zIndex: 'dropdown',
    position: ['fixed', null, null, 'sticky'],
    flexDir: 'column',
    alignItems: 'center',
    bottom: '0',
    left: ['0', null, null, 'unset'],
    w: ['100%', null, null, 'unset'],
    mx: ['auto', null, null, '-2.5'],
  },
};
