import { chakra, ChakraComponent, Flex, FlexProps } from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './TabbedDisplay.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

const TabsWrapper = styled.div`
  ${({ theme }) => theme.components.tabbedDisplay.tabsWrapper};
`;

const Tab = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.components.tabbedDisplay.tab};

  ${({ selected, theme }) =>
    selected && theme.components.tabbedDisplay.selectedTab};
`;

const TabsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  ${({ theme }) => theme.components.tabbedDisplay.tabsContainer};
`;

export const TabsContentWrapper = chakra('div', {
  label: 'tabbedDisplay-TabsContentWrapper',
  baseStyle: ({ theme }) => ({
    borderBottomRadius: 'md',

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'white',
    }),
  }),
});

const TabsContentContainer = chakra(Flex, {
  label: 'tabbedDisplay-TabsContentContainer',
  shouldForwardProp: (prop) => !['resultsShowing'].includes(prop),
  baseStyle: ({ theme, resultsShowing }: any) => ({
    w: '100%',
    maxW: '1200px',
    mx: 'auto',
    my: '0',
    pt: '5',
    ...(theme.themeName === EThemes.Betgalaxy && {
      pt: resultsShowing ? '0' : '2',
      pb: '4',
      borderRadius: 'base',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TabsContentContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps & { resultsShowing?: boolean }>;

export { TabsWrapper, TabsContainer, TabsContentContainer, Tab };
