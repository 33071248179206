import React, { ReactNode } from 'react';
import { Text, Box, Flex, Show, Container } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { accountWrapperStyles } from '../styles/AccountWrapperTwo.styles';
import TopNav from '../../../components/Navigation/AccountNavigation';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';

const template = (
  pageHeader?: ReactNode,
  pageHeaderChildren?: ReactNode,
  children?: any
): TTemplate => ({
  wrapper: <Box {...accountWrapperStyles.boxWrapper} />,
  pageHeader: (
    <Flex flexDir="column" gap="2">
      <Show above="sm">
        <Breadcrumb />
      </Show>
    </Flex>
  ),
  accountInfoContainer: (
    <Container
      {...accountWrapperStyles.accountInfoContainer}
      maxW="container.xl"
    >
      <Box
        {...accountWrapperStyles.boxHeading}
        data-hasPageHeader={!!pageHeader}
      >
        <Container
          {...accountWrapperStyles.accountHeaderContainer}
          maxW="container.xl"
        >
          <Box>{!IS_MOBILE_APP && <TopNav />}</Box>
        </Container>
      </Box>
      <Flex {...accountWrapperStyles.flexVerticalWrapper} data-id="ABABNA">
        {pageHeader && (
          <Box {...accountWrapperStyles.titleContainer}>
            <Text {...accountWrapperStyles.textTitle} as="h1">
              {pageHeader}
            </Text>
            {pageHeaderChildren}
          </Box>
        )}
        {children}
      </Flex>
    </Container>
  ),
});

export default template;
