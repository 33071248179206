import React from 'react';
import { useIntl } from 'react-intl';
import { Text, ModalContent } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { getStrings, formatStake } from '@/helpers/utils';
import {
  ButtonDeposit,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
} from './styles/InfoModal.styles';
import { TFormikValues } from '../Form';
import { TSetFieldValue } from '@/hooks/useComplexState';
import { useFeatureFlag } from '@/store/FeatureFlagStore';

export type TInfoModalState = {
  isConfirmationModalOpen: boolean;
  isPaymentSuccess: boolean;
};

type TInfoModalProps = {
  state: TInfoModalState;
  setFieldValue: TSetFieldValue<TInfoModalState>;
};

export default function InfoModal({ state, setFieldValue }: TInfoModalProps) {
  const intl = useIntl();

  const { isConfirmationModalOpen, isPaymentSuccess } = state;

  const [
    {
      Account: { Deposit },
    },
  ] = getStrings();

  const {
    values: { amount },
    resetForm,
  } = useFormikContext<TFormikValues>();

  const hasNewDeposit = useFeatureFlag('PRIMER_ENABLED');

  return (
    <Modal
      isOpen={isConfirmationModalOpen}
      onClose={() => setFieldValue('isConfirmationModalOpen', true)}
      closeOnOverlayClick={false}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isPaymentSuccess
            ? intl.formatMessage({
                id: 'account.deposit.confirmation.complete',
              })
            : intl.formatMessage({
                id: 'account.deposit.confirmation.failed',
              })}
        </ModalHeader>
        <ModalBody>
          <Text>
            {isPaymentSuccess
              ? Deposit.Confirmation.CompleteMessage.replace(
                  '%',
                  `$${formatStake(Number(amount))}`
                )
              : Deposit.Confirmation.FailedMessage.replace(
                  '%',
                  `$${formatStake(Number(amount))}`
                )}
          </Text>
          <ButtonDeposit
            onClick={() => {
              if (hasNewDeposit) {
                setFieldValue('isConfirmationModalOpen', false);
                resetForm();
              } else {
                window.location.reload();
              }
            }}
          >
            {isPaymentSuccess
              ? Deposit.Confirmation.Done
              : Deposit.Confirmation.PleaseTryAgain}
          </ButtonDeposit>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
