import { ComponentWithAs } from '@chakra-ui/react';
import React from 'react';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { pageButtonStyles } from '@/views/sports/components/PageButton/styles/PageButton.styles';

const PageButton: ComponentWithAs<'button', CustomButtonProps> = (props) => (
  <Button {...pageButtonStyles.button} {...props} />
);

export default PageButton;
