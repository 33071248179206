import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bgGradient: 'linear(to-b, alpha.700, alpha.700)',
  '&&': { px: ['2.5', '4'], pb: '2' },
  mt: ['unset', '2'],
  overflow: 'visible',
  color: 'gamma.500',
  fontSize: 'md',
  pb: '0',
  fontFamily: 'Damion',

  // Attempt at border styling
  borderTopRadius: '10px',
  borderTopWidth: '3px',
  borderX: '3px solid',
  borderColor: '#FE62FF',
  backdropFilter: 'blue(4px)',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  mb: '2',
  fontWeight: 'medium',
  fontSize: '32px',
  textTransform: 'capitalize',
  color: 'gamma.500',
  fontFamily: 'Damion',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  m: '2',
  borderRadius: [null, null, null, 'base'],
};

export const WrapperContainer: CSSObject = {
  px: '0',
  bgGradient: 'linear(to-b, alpha.700, alpha.800)',

  // Attempt at border styling
  borderBottomRadius: '10px',
  borderBottomWidth: '3px',
  borderX: '3px solid',
  borderColor: '#FE62FF',
};
