import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'zeta.400',
  fontSize: '14px',
  textTransform: 'uppercase',
  fontFamily: 'SkranjiRegular',
  fontWeight: 'light',
  pt: '1px',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  minW: 'unset',
  color: 'rgba(0, 0, 0, 0.64)',
  borderRadius: '4px',
  border: '2px',
  borderColor: '#CD8716',
  bgGradient: 'linear(to-b, #FCD236, #E79004)',
  transition: 'all .3s ease-in-out',
  _hover: {
    color: 'rgba(0, 0, 0, 0.64)',
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: '#775826',
  },
  _focus: {
    color: 'rgba(0, 0, 0, 0.64)',
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: '#775826',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '2',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'roboto',
};
