import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  bg: 'alpha.400',
  color: 'beta.400',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bg: 'alpha.100',
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'alpha.400',
  color: 'beta.400',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'beta.400',
  },
};
export const TextTitle: CSSObject = {
  color: 'white',
};
