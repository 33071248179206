import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ChevronRight, Dot } from '@styled-icons/bootstrap';
import { TNextToGo } from '../../../../../api/nextToJump/nextToJump.types';
import { getIconBySportName } from '@/helpers/utils';
import Countdown from '../../../../../components/Countdown/Countdown';
import {
  LinkWrapper,
  IconSport,
  TextListName,
  TextSpanName,
  FlexSubTextWrapper,
  IconChevron,
  FlexEndWrapper,
  SubTextIcon,
  IconSGM,
} from './styles/ListItem.styles';

type TListItem = {
  match: TNextToGo;
  section?: string;
};

export default function ListItem({ match, section }: TListItem) {
  const intl = useIntl();
  const matchNameSplit = match.match_name?.split(' ');
  const compOrTournamentParam = match.tournament_id
    ? `tournamentId=${match.tournament_id}`
    : `competitionId=${match.competition_id}`;

  return (
    <LinkWrapper
      data-cy="matchListItem"
      to={`/Sports/${encodeURIComponent(
        match.sport_name ?? ''
      )}/${encodeURIComponent(
        match.competition_name ?? ''
      )}/${encodeURIComponent(match.match_name ?? '')}?sportId=${
        match.sport_id
      }&${compOrTournamentParam}&matchId=${match.match_id}`}
    >
      <IconSport
        data-cy="matchListItemIcon"
        name={getIconBySportName(match.sport_name)}
        gridArea="icon"
      />

      <Box className="details" gridArea="details">
        <TextListName data-cy="matchListItemName">
          {matchNameSplit?.map((n, i) => (
            <TextSpanName
              key={`match-name-${n}-${match.match_id}-${section}`}
              as="span"
              isVS={n.toLocaleLowerCase() === 'vs'}
              data-isVS={n.toLocaleLowerCase() === 'vs'}
            >
              {i === 0 ? n : ` ${n}`}
            </TextSpanName>
          ))}
        </TextListName>

        <FlexSubTextWrapper>
          <Text data-cy="matchListItemSportName">{match.sport_name}</Text>
          <SubTextIcon as={Dot} />
          <Text data-cy="matchListItemCompetitionName">
            {match.competition_name ?? match.tournament_name}
          </Text>
        </FlexSubTextWrapper>
      </Box>

      <FlexEndWrapper gridArea="time">
        {match.sgm_available && (
          <IconSGM
            title={intl.formatMessage({ id: 'generic.sameGameMulti' })}
          />
        )}

        <Countdown eventTime={match.match_start_time ?? ''} hasTimestamp />
      </FlexEndWrapper>

      <IconChevron
        as={ChevronRight}
        gridArea="chevron"
        sx={{ boxSize: '3.5' }}
      />
    </LinkWrapper>
  );
}
