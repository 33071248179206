import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'alpha.700',
  borderRadius: 'sm',
  color: 'gamma.400',
  ml: '0.5',
  lineHeight: '16.5px',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.2)',
};

export const LinkRaceContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  fontFamily: 'accentMusashi',
  color: 'gamma.200',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'md',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  letterSpacing: '1px',
};

export const TextRace: CSSObject = {
  fontFamily: 'accentMusashi',
  color: 'gamma.200',
  fontWeight: 'normal',
  fontSize: 'md',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
  letterSpacing: '1px',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'gamma.200',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
