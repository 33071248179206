/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Icon, Show, Spacer } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { getStrings } from '@/helpers/utils';
import { EBetSlipViewMode, TBetSlipBet } from '../../../Services/Betslip.types';
import { useBetsCount } from '../../../Services/Betslip.hooks';
import {
  BetLengthIndicator,
  BetslipHeaderIcon,
  BetslipTitle,
  BetslipTitleText,
  closeIcon,
  CloseIconButton,
  FlexBetslipHeading,
  headerIcon,
} from './styles/Header.styles';
import { transformBetForLegacy, useBetSlipBets } from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { setBetSlipOpen } from '@/redux/Betslip.slices';

/**
 * Containers
 */
const HeaderReduxContainer = () => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <Header bets={bets} />;
};

const HeaderZustandContainer = () => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <Header bets={bets} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? HeaderZustandContainer
  : HeaderReduxContainer;

// ----

/**
 * View
 */
type HeaderProps = {
  bets: TBetSlipBet[];
};

const Header = ({ bets }: HeaderProps) => {
  const { viewMode } = useAppSelector((state) => state.betSlip);
  const { allBetsPlaced, betsCount } = useBetsCount({ bets });
  const dispatch = useAppDispatch();
  const [{ BetSlip: Strings }] = getStrings();

  const betSlipTitle = () => {
    if (allBetsPlaced) return Strings.betSlipReceiptHeading;
    if (viewMode === EBetSlipViewMode.ReviewingBets)
      return Strings.betSlipReviewHeading;
    return Strings.betSlipHeading;
  };

  return (
    <FlexBetslipHeading>
      <BetslipTitle data-cy="betSlipHeading">
        <BetslipHeaderIcon as={headerIcon} />
        <BetslipTitleText>{betSlipTitle()}</BetslipTitleText>
        <BetLengthIndicator>{betsCount}</BetLengthIndicator>
      </BetslipTitle>
      <Spacer />
      <Show below="xl">
        <CloseIconButton
          variant="ghost"
          aria-label="Close Betslip"
          icon={<Icon {...(closeIcon as any)} />}
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            dispatch(setBetSlipOpen(false));
          }}
          data-cy="closeBetSlipButton"
        />
      </Show>
    </FlexBetslipHeading>
  );
};
