import { CSSObject, TextProps } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
  bg: 'red.600',
  p: '1',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};
export const FormLabelThemed: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  mt: '2',
  fontFamily: 'ProximaNova',
  lineHeight: 'normal',
};

export const FormControlGroup: CSSObject = {
  'div.chakra-input__group .chakra-input__right-element': {
    button: {
      fill: 'white',
      color: 'white',
    },
  },
  'div.chakra-input__group > input[data-cy="passwordInput"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    border: 'none',
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'xs',
    _placeholder: {
      color: 'white',
      fontFamily: 'accent',
      fontSize: 'xs',
    },
    _hover: {
      bg: 'blackAlpha.600',
      color: 'white',
      fontFamily: 'accent',
      fontSize: 'xs',
    },
    _focus: {
      bg: 'blackAlpha.600',
      color: 'white',
      fontFamily: 'accent',
      fontSize: 'xs',
    },
  },
  '.chakra-form__error-message': {
    bg: 'red.600',
    textTransform: 'capitalize',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    minH: '20px',
    h: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    pr: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
  '#title-input[disabled], #firstName-input[disabled], #lastName-input[disabled], #mobile-input[disabled], #email-input[disabled], #address-input[disabled], #city-input[disabled], #state-input[disabled], #dateOfBirth-input[disabled]':
    {
      border: 'none',
      borderRadius: 'base',
      bg: 'blackAlpha.400',
      opacity: 0.5,
      color: 'whiteAlpha.700',
      fontSize: 'xs',
      p: 3,
      fontFamily: 'accent',
    },
};
