import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  '&&': {
    fontSize: heading ? 'sm' : 'xs',
    fontWeight: heading ? 'semibold' : 'normal',
    color: 'white',
    lineHeight: '1.3',
  },
});

export const RadioText: CSSObject = {
  '&&': {
    fontSize: 'xs',
    mb: '2',
    fontWeight: 'medium',
    color: 'white',
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'whiteAlpha.500',
  borderRadius: 'base',
  p: '4',
  '.chakra-input__group': {
    borderRadius: 'md',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },
  '.chakra-input__left-addon': {
    bg: 'alpha.700',
    fontSize: 'xs',
    fontWeight: 'semibold',
    border: 'none',
    h: '9',
    color: 'gamma.200',
    pl: '4',
    pr: '2',
    pt: 'px',
  },
  '.chakra-select': {
    bg: 'alpha.700',
    fontSize: 'xs',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-select__icon-wrapper': {
    color: 'gamma.200',
  },
  '#limitAmount-input': {
    fontSize: 'xs',
    fontWeight: 'medium',
    bg: 'alpha.700',
    color: 'white',
    border: 'none',
    h: '9',
    px: '0',
    '&&:hover, &&:focus': {
      bg: 'alpha.600',
      border: 'none',
    },
  },
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: ['4', null, '5'],
  pt: [null, null, '1'],
  gap: '2',
};

export const ButtonSubmit: CSSObject = {
  bgGradient: 'linear(to-b, beta.400 0%, beta.600 100%)',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.500',
  color: 'beta.100',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #FFC878 inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.600 0%, beta.600 100%)',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
    color: 'gamma.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    border: 'none',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    border: 'none',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

export const RadioLimitChoice: CSSObject = {
  bg: 'white',
  border: 'none',
  mb: '2',
  '&[data-checked]': {
    bg: 'alpha.400',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'alpha.400',
  },
  '&[data-checked]:hover': {
    bg: 'alpha.400',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'alpha.400',
  },
};
