import { Image, Link, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logoDesktop from '@/assets/junglebet/images/backgrounds/login-logo.png';
import logoMobile from '@/assets/junglebet/images/logoMB.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

const ImageContainer = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Link
      as={LinkDom}
      to="/"
      maxW={['45vw', '55vw']}
      alignSelf="center"
      mb="auto"
      mt={['4vw', '2vw']}
    >
      <Image w="200px" src={isMobile ? logoMobile : logoDesktop} />
    </Link>
  );
};

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: <ImageContainer />,
    config: {
      before: 'slogan',
    },
  },
]);
