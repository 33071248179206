import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '3',
  mt: ['-3', null, '-6'],
  top: ['0px', '9'],
  mx: ['4', '1.5'],
  borderBottomRadius: 'base',
  position: 'unset',
};

export const IconRace: CSSObject = {
  ml: '0.5',
};

export const ButtonFilter = ({ selected }: TSelected) => ({
  color: selected ? 'alpha.300' : 'white',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'alpha.300' : 'white',
  color: selected ? 'black' : 'transparent',
  boxShadow: 'none',
  mr: '0.5',
});

export const TextFilterLabel: CSSObject = {
  color: 'white',
};

export const Divider: CSSObject = {
  display: 'block',
  bg: 'whiteAlpha.400',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: 'unset',
};
