import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  alignItems: 'center',
  bgGradient: 'linear(to-b, delta.800, delta.900)',
  borderBottom: '2px solid',
  borderColor: 'beta.500',
  h: '16',
};
export const IconCircleOutline: CSSObject = {
  boxSize: '8',
  color: 'beta.500',
};
