import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  px: ['2', '4'],
  mt: '0',
};

export const BoxWrapper: CSSObject = {
  border: 'unset',
};

export const LinkItem = (isFirstItem: number) => ({
  p: '2',
  py: '3',
  borderTop: '1px',
  borderTopColor: isFirstItem ? 'transparent' : 'blackAlpha.400',
  color: 'alpha.600',
  transition: 'all, .1s ease-in-out',

  _hover: {
    bg: 'blackAlpha.300',
  },

  svg: {
    boxSize: '6',
    p: '1',
    color: 'alpha.600',
    border: '1px',
    borderColor: 'alpha.600',
    borderRadius: 'full',
  },
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};
