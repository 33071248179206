import { EThemesOG } from '@/constants/themeConfig';
import * as juicybet from './PersonalDetails.styles.juicybet';
import * as betroyale from './PersonalDetails.styles.betroyale';
import * as junglebet from './PersonalDetails.styles.junglebet';
import * as vikingbet from './PersonalDetails.styles.vikingbet';
import * as oldgill from './PersonalDetails.styles.oldgill';
import * as sugarcastle from './PersonalDetails.styles.sugarcastle';
import * as buffalobet from './PersonalDetails.styles.buffalobet';
import * as gigabet from './PersonalDetails.styles.gigabet';
import * as chromabet from './PersonalDetails.styles.chromabet';
import * as puntcity from './PersonalDetails.styles.puntcity';
import * as goldenbet888 from './PersonalDetails.styles.goldenbet888';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  goldenbet888,
  puntcity,
  juicybet,
  junglebet,
  betroyale,
  vikingbet,
  oldgill,
  sugarcastle,
  buffalobet,
  gigabet,
  chromabet,
};
