import {
  BadgeProps,
  BoxProps,
  FlexProps,
  HeadingProps,
  LinkProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './NewMatchCard.styles.imports';
import { TDuotoneSgmLogo } from '@/components/DuotoneSgmLogo/DuotoneSgmLogo';
import { TIconProps } from '@/components/common/IconSvg';
import { TCountdown } from '@/components/Countdown/Countdown';

const themeName = getThemeName();

export type NewMatchCardSchema = Partial<{
  boxWrapper: BoxProps;
  bodyStack: StackProps;
  titleHeading: HeadingProps;
  footerFlex: FlexProps;
  footerStack: StackProps;
  footerStackDivider: BoxProps;
  footerSportNameFlex: FlexProps;
  footerSportNameIconSvg: Partial<TIconProps>;
  footerCompetitionNameText: TextProps;
  footerCountDown: Pick<TCountdown, 'sxWrapper'> & BadgeProps;
  footerDuotoneSgmLogo: TDuotoneSgmLogo;
  footerMatchLink: LinkProps;
  footerMatchLinkIcon: Partial<TIconProps>;
}>;

export const newMatchCardStyles: NewMatchCardSchema = {
  footerMatchLinkIcon: {
    'data-testid': 'newMatchCard-footerMatchLinkIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.footerMatchLinkIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerMatchLink: {
    'data-testid': 'newMatchCard-footerMatchLink',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.footerMatchLink;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerDuotoneSgmLogo: {
    'data-testid': 'newMatchCard-footerDuotoneSgmLogo',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles
          ?.footerDuotoneSgmLogo;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerCountDown: {
    'data-testid': 'newMatchCard-footerCountDown',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.footerCountDown;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerCompetitionNameText: {
    'data-testid': 'newMatchCard-footerCompetitionNameText',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles
          ?.footerCompetitionNameText;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerSportNameIconSvg: {
    'data-testid': 'newMatchCard-footerSportNameIconSvg',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles
          ?.footerSportNameIconSvg;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerSportNameFlex: {
    'data-testid': 'newMatchCard-footerSportNameFlex',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.footerSportNameFlex;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerStackDivider: {
    'data-testid': 'newMatchCard-footerStackDivider',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.footerStackDivider;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerStack: {
    'data-testid': 'newMatchCard-footerStack',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.footerStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  footerFlex: {
    'data-testid': 'newMatchCard-footerFlex',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.footerFlex;
      } catch (err) {
        return {};
      }
    })(),
  },
  titleHeading: {
    'data-testid': 'newMatchCard-titleHeading',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.titleHeading;
      } catch (err) {
        return {};
      }
    })(),
  },
  bodyStack: {
    'data-testid': 'newMatchCard-bodyStack',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.bodyStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  boxWrapper: {
    'data-testid': 'newMatchCard-BoxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.newMatchCardStyles?.boxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
