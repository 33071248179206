import { chakra, Flex } from '@chakra-ui/react';
import { styleImports } from './OddsChangedBanner.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { TOddsChangedBannerProps } from '../../Services/Betslip.types';

const themeName = getThemeName();

export const OddsChangedContainer = chakra<TOddsChangedBannerProps>(Flex, {
  label: 'oddChangedBanner-OddsChangedContainer',
  shouldForwardProp: (prop) => !['isInFooter', 'direction'].includes(prop),
  baseStyle: ({ isInFooter, direction }: TOddsChangedBannerProps) => ({
    bg: direction === 'increase' ? 'green.100' : 'yellow.100',
    color: direction === 'increase' ? 'green.800' : 'yellow.800',
    fontSize: 'sm',
    fontWeight: 'medium',
    ...(() => {
      try {
        return styleImports[themeName]?.OddsChangedContainer?.(
          isInFooter,
          direction
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
});
