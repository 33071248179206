import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  m: '0',
  p: '0',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  my: '-2',
  py: '2',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'blackAlpha.600' : 'beta.100',
  bgGradient: isActive ? '' : 'linear(to-b,beta.200, beta.400)',
  borderRadius: 'base',
  boxShadow: isActive
    ? '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px -1px 0px 0px rgba(233, 191, 215, 0.50) inset, 0px -2px 0px 4px rgba(0, 0, 0, 0.10) inset'
    : '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px #773053 inset',
  color: isActive ? 'alpha.400' : 'alpha.500',
  fontSize: '2xs',
  fontWeight: 'bold',
  letterSpacing: 'wider',
  minW: 'fit-content',
  px: '2',
  py: '1',
  textShadow: isActive ? undefined : '0 2px 3px rgba(0, 0, 0, .2)',
  textTransform: 'uppercase',
  border: isActive ? '' : '2px solid',
  borderColor: 'beta.100',

  _hover: isActive
    ? undefined
    : {
        bg: 'beta.100',
      },

  _focus: {
    boxShadow: isActive
      ? 'inset 0 2px 4px rgba(0, 0, 0, .2)'
      : '0 2px 5px rgba(0, 0, 0, .15)',
  },

  _active: {
    boxShadow: isActive
      ? 'inset 0 2px 4px rgba(0, 0, 0, .2)'
      : '0 2px 5px rgba(0, 0, 0, .15)',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.600',
  color: 'alpha.400',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px -1px 0px 0px rgba(233, 191, 215, 0.50) inset, 0px -2px 0px 4px rgba(0, 0, 0, 0.10) inset',
  fontSize: '2xs',
  fontWeight: 'bold',
  letterSpacing: 'wider',
  minW: 'fit-content',
  px: '2',
  py: '1',
  textTransform: 'uppercase',
  borderColor: 'beta.100',
};

export const TopNavLabelWrapper: CSSObject = {
  gap: '1',
  my: '-2',
  px: '2',
  py: '2',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
};
