/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useReward } from 'react-rewards';
import { Fade } from '@chakra-ui/react';
import template from './templates/template';
import { createTemplate } from '@/helpers/createTemplate';

type TPlacedProps = {
  bgAnimationRef: RefObject<HTMLDivElement>;
  iconAnimationRef: RefObject<SVGSVGElement>;
};

function PlacedView({ bgAnimationRef, iconAnimationRef }: TPlacedProps) {
  return (
    <Fade in>
      {createTemplate(template(bgAnimationRef, iconAnimationRef))}
      <span id="confetti" />
    </Fade>
  );
}

const usePlaced = (): TPlacedProps & { shouldShow: boolean } => {
  const [shouldShow, setShouldShow] = useState(false);
  const { reward } = useReward('confetti', 'confetti');
  const bgAnimationRef = useRef<HTMLDivElement>(null);
  const iconAnimationRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    reward();
    // Pauses animation(s) after 30 seconds
    setTimeout(() => {
      if (bgAnimationRef.current && iconAnimationRef.current) {
        bgAnimationRef.current.style.animationPlayState = 'paused';
        iconAnimationRef.current.style.animationPlayState = 'paused';
      }
    }, 30000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShouldShow(true);
    }, 500);
  }, []);

  return {
    bgAnimationRef,
    iconAnimationRef,
    shouldShow,
  };
};

const Placed = () => {
  const values = usePlaced();
  if (!values.shouldShow) return null;
  return <PlacedView {...values} />;
};

export default Placed;
