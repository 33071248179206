import * as betprofessor from './Menu.styles.betprofessor';
import * as betroyale from './Menu.styles.betroyale';
import * as fiestabet from './Menu.styles.fiestabet';
import * as puntcity from './Menu.styles.puntcity';
import * as puntgenie from './Menu.styles.puntgenie';
import * as questbet from './Menu.styles.questbet';
import * as sterlingparker from './Menu.styles.sterlingparker';
import * as wellbet from './Menu.styles.wellbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  wellbet,
};
