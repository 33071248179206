import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
  pt: '2',
};

export const BoxSectionWrapper: CSSObject = {
  mt: '2',
  mb: '4',
  borderRadius: 'base',
  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  color: 'gray.700',
  bg: 'white',
  border: 'none',
  mb: '1px',
  _last: {
    mb: '0',
  },
};

export const IconSport: CSSObject = {
  color: 'beta.400',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: '1px',
  borderColor: 'white',
  borderRadius: 'full',
  p: '1',
  color: 'white',
  transition: '.3s ease-in-out',

  _hover: {
    color: 'gray.200',
  },
};
