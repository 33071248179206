import { TSportMarketGroupsResponse } from './sportDetailsMarketGroups.types';

export const querySportMarketGroupsMock: TSportMarketGroupsResponse = [
  {
    name: 'Top Markets',
    bet_options: [
      '000.001',
      '000.002',
      '000.003',
      '000.004',
      '000.005',
      '000.006',
    ],
    market_group_id: 'top-markets',
  },
  {
    name: 'Win',
    bet_options: ['000.007', '000.008', '000.009', '000.010'],
    market_group_id: 'win',
  },
  {
    name: 'Handicap',
    bet_options: [],
    market_group_id: 'handicap',
  },
  {
    name: 'Points',
    bet_options: [],
    market_group_id: 'points',
  },
  {
    name: 'Score',
    bet_options: [],
    market_group_id: 'score',
  },
  {
    name: 'Player',
    bet_options: [],
    market_group_id: 'player',
  },
  {
    name: 'Margin',
    bet_options: [],
    market_group_id: 'margin',
  },
  {
    name: 'Double Time',
    bet_options: [],
    market_group_id: 'double-time',
  },
];
