import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'white',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        bgGradient: [
          'linear(to-b, alpha.500, alpha.600)',
          null,
          null,
          'linear(to-b, white, white)',
        ],
        boxShadow: 'lg',
        borderRadius: [null, null, null, 'md'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
        mb: [null, null, null, '3'],
      },
      drawer: {
        ...base.drawer,
      },
      drawerBody: {
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '20px',
        color: ['white', null, null, 'alpha.700'],
        fill: ['white', null, null, 'alpha.700'],
      },
      headerSX: {
        fontWeight: 'extrabold',
        letterSpacing: 'widest',
        cursor: 'default',
        color: ['white', null, null, 'alpha.700'],
        height: '45px',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,

        fontSize: 'sm',
        height: '43px',
        color: ['white', null, null, 'alpha.700'],
        filter: [
          'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2))',
          null,
          null,
          'none',
        ],
        transition: 'all, 0.2s linear',
        borderLeftWidth: '3px',
        borderLeftColor: 'transparent',

        borderBottomWidth: '1px',
        borderBottomColor: 'transparent',
        p: '4',
        ml: '0',

        zIndex: ['docked', null, null, 'auto'],
        position: ['relative', null, null, 'initial'],
        '~ .nested': {
          pl: '10',
        },

        ':hover': {
          borderLeftColor: 'alpha.600',
          borderBottomColor: 'transparent',
          bg: ['transparent', null, 'alpha.50'],
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },
        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['4', null, '4'],
        },

        '> a, .dropDownIcon, a': {
          color: ['white', null, null, 'alpha.700'],
        },

        '&:hover, &:active': {
          color: 'alpha.700',

          '.dropDownInner': {
            ...base.dropDownInner,
            px: '4',
            color: ['white', null, 'alpha.700'],
          },

          '.svgIcon, .icon': {
            color: ['white', null, 'alpha.700'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',
        color: ['white', null, 'red.700'],

        _hover: {
          '.dropDownInner': {
            bg: 'alpha.600',
          },
        },

        '.dropDownInner': {
          bg: 'alpha.600',
          color: 'white',
          borderLeftWidth: '3px',
          borderColor: 'alpha.600',
        },

        '> a, .dropDownIcon, a': {
          color: 'white',
          fill: 'none',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'white',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
