import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '1.5',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  borderRadius: 'base',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'blackAlpha.700' : 'beta.300',
  textShadow: isActive ? '0px 1px 0px rgba(255, 255, 255, 0.35)' : 'none',
  h: '5',
  fontSize: '2xs',
  px: '3',
  py: '1',
  borderRadius: 'base',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'gamma.400' : 'transparent',
  boxShadow: 'none',
  fontWeight: 'bold',
  p: '2',
  overflow: 'hidden',
  '&:focus, &:hover': {
    color: isActive ? 'blackAlpha.700' : 'white',
    boxShadow: 'none',
    bg: isActive ? 'gamma.400' : 'blackAlpha.50',
    textShadow: 'none',
  },

  _hover: {
    color: 'white',
  },
});
