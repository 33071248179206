import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  pl: '0',
  pr: '2',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  mt: ['2', null, '0'],
  pb: ['6', null, '2'],
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive
    ? 'blackAlpha.500'
    : 'linear-gradient(180deg, #FFE0A6 0%, #E5C281 100%)',
  color: isActive ? 'white' : 'alpha.700',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: '3px',
  boxShadow: isActive
    ? '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)'
    : '0px 1px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
  '&:hover, &:focus': {
    bg: isActive ? 'delta.100' : 'zeta.100',
    color: isActive ? 'beta.900' : 'alpha.400',
  },
  '&:hover': {
    bg: 'zeta.100',
    color: 'blackAlpha.700',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.500',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: '3px',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  '&:hover, &:focus': {
    bg: 'delta.100',
    color: 'beta.900',
  },
  '&:hover': {
    bg: 'zeta.100',
    color: 'blackAlpha.700',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  pl: '0',
  pr: '2',
};
