import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  minW: '12',
  maxH: '8',
  w: ['47px', null, '14'],
  fontSize: 'sm',
  fontWeight: 'black',
  fontFamily: 'body',
  _disabled: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'white',
    bg: 'gray.300',
    borderRadius: 'md',
    color: 'blackAlpha.400',
    maxH: '8',
    fontWeight: 'black',
    fontSize: 'sm',
    boxShadow:
      '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
    cursor: 'not-allowed',
    textShadow: 'unset',
    pointerEvents: 'none',
    _after: {
      display: 'none',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  h: '3',
  width: 'calc(100% + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopRadius: 'base',
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderLeft: '1px solid',
  borderColor: 'rgba(0, 0, 0, 0.10)',
  background: 'delta.600',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'epsilon.500',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'epsilon.500',
  textShadow: 'none',
};
