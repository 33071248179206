import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  footer: {
    color: 'alpha.900',
    px: 4,
    maxW: 'container.xl',
    mx: 'auto',
  },
  stackGap: '1',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    pr: '0',
    h: '70px',
    w: 'full',
    mb: ['6', null, '0'],
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
    sx: {
      '& > img': {
        maxW: '200px',
        h: '35px',
        ml: '0',
      },
    },
  },
  footerLink: {
    color: 'yellow.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'inline',
  },
  footerLinkTel: {
    color: 'yellow.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'inline',
  },
  customFooterLink: {
    color: 'yellow.500',
    textTransform: 'capitalize',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'inline',
    fontSize: 'sm',
    pb: '0.5',
  },
  footerLinks: {
    ml: ['unset', null, 'auto'],
    mt: ['4', null, '4'],
    pb: ['4', null, '0'],
    alignItems: 'end',
    color: 'white',
    fontWeight: 'medium',
    justifyContent: ['space-around', null, 'unset'],
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
  },
  footerLinkService: {
    color: 'yellow.500',
  },
  footerContentContainer: {
    px: ['2', 'unset'],
    gap: ['2', '6'],
    color: 'white',
    justifyContent: 'flex-start',
  },
  nav: {
    fontSize: 'sm',
    minW: [null, null, '370px'],
    mt: ['2', 'unset'],
    display: ['flex', 'grid'],
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '10',
    rowGap: '1',
    alignItems: ['center', 'end'],
    gridTemplateAreas: `
        ". ."
      `,
    sx: {
      '> a': {
        color: 'inherit',
      },
    },
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', 'left'],
    mb: '4',
    width: 'unset',
    ml: [null, '0'],
    mr: [null, null, '4'],
  },
  blurbHeadingAge: {
    mb: '2',
  },
  blurbHeadingThink: {
    fontSize: 'md',
    fontWeight: 'bold',
    maxW: '410px',
    mb: '3',
  },
  blurbContent: {
    ml: 'unset',
    mr: 'auto',
  },
  copyright: {
    textAlign: 'left',
    mt: ['6'],
    fontWeight: 'light',
    color: 'white',
  },
  container: {
    sx: {
      '&&': {
        px: ['0', '2', null, '4'],
      },
    },
  },
  blurbHeading: {
    ml: 'unset',
    mt: '2',
    color: 'white',
  },
  quickLinks: {
    fontSize: 'sm',
    textAlign: ['center', 'left'],
    pb: ['0', '1'],
  },
  ourPartners: {
    fontSize: 'sm',
    pb: '2',
  },
  weAccept: {
    fontSize: 'sm',
    pb: '2',
  },
  weAcceptBox: {
    mb: '2',
  },
  emailUs: {
    fontSize: 'sm',
    color: 'white',
  },
  LinkText: {
    sx: {
      '&': {
        fontSize: ['xs', 'sm', null],
        fontWeight: 'normal',
      },
    },
  },
};
