import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'beta.500',
      background: {
        bg: ['alpha.700', null, null, 'rgba(54, 54, 54)'],
        bgRepeat: ['no-repeat', null, null, 'unset'],
        bgSize: ['cover', null, null, 'unset'],
        borderRadius: ['0px', null, null, 'lg'],
        mb: ['0', null, null, '4'],
      },
      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          // '> .dropDownInner, a[class*="dropDownInner"]': {
          //   _first: {
          //     cursor: 'initial',
          //   },
          // },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: '18px',
        mr: '2',
      },
      headerSX: {
        cursor: 'default',
        color: 'beta.400',
        fontSize: 'xs',
        fontWeight: '700',
        border: 'none',
        filter: [
          'drop-shadow(0px 0px 8px rgba(125, 253, 42, 0.7))',
          null,
          null,
          'none',
        ],
        '&:hover': {
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
      },
      linkSX: {
        ...base.linkSX,
        mb: '0',
      },
      // SideNavLink
      link: {
        ...base.link,
        borderRadius: 'md',
        fontSize: 'sm',
        fontWeight: '600',
        height: '7',
        transition: 'all, 0.2s linear',
        w: ['97%', null, null, '246px'],

        // Select all SideNavLink except Quick Links (with p tag)
        '&:not(p)': {
          height: '45px',
          boxShadow: [
            'none',
            null,
            null,
            '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
          ],
          borderLeft: ['none', null, null, '1px'],
          borderTop: '1px',
          borderRight: ['none', null, null, '1px'],
          borderBottom: ['none', null, null, '1px'],
          borderStyle: ['dashed', null, null, 'solid'],
          borderColor: [
            'rgba(255, 255, 255, 0.088)',
            null,
            null,
            'rgba(255, 255, 255, 0.04)',
          ],
          bgGradient: [
            'none',
            null,
            null,
            'linear(to-b, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.04))',
          ],
          my: '4px',
          px: ['0', null, null, '3'],
          _hover: {
            border: '1px solid',
            borderColor: 'beta.400',
          }
        },
        pl: ['0', null, null, '0'],
        '~ .nested': {
          pl: '0',
        },
        svg: {
          color: ['beta.500', null, null, 'beta.400'],
          filter: [
            'drop-shadow(0px 0px 8px rgba(125, 253, 42, 0.7))',
            null,
            null,
            'drop-shadow(0px 0px 8px rgba(37, 254, 214, 0.6))',
          ],
        },
        _active: {
          bg: 'blackAlpha.500',
        },
        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },
        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['0', null, null, '3'],
        },
        h: '45px',

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            pl: ['0', null, null, '3'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        '.dropDownInner': {
          px: '3',
          bg: 'rgba(0, 0, 0, 0.24)',
          color: ['beta.500', null, null, 'beta.400'],
          'svg:not(.dropDownIcon)': {
            fill: ['beta.500', null, null, 'beta.400'],
          },
        },
        '.dropDownIcon': {
          fill: 'none',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
