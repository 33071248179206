import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { keys } from '../../../api/api.keys';
import { useQueryRaceMarkets } from '../../../api/racing/racing.hooks';
import { useAppDispatch } from '../../../hooks/useRedux';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  TRaceMarket,
} from '../../../lib/DBModels';
import { setMarkets } from './races.slices';

export const useRaceMarkets = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { betType } = useParams();
  const [searchParams] = useSearchParams();
  const raceId = searchParams.get('raceId');

  const { data } = useQueryRaceMarkets({
    params: { race_id: raceId ?? '' },
    options: {
      staleTime: 60 * 5000, // 5 minutes
      refetchInterval: 5000, // polling every 5 sec
      enabled: !!raceId,
      onSuccess: async (newData) => {
        /*
         * When we are currently showing an exotics page that is set to hidden -> navigate to home.
         * We currently have no way to filter out closed & hidden races from the meeting table,
         * until then we have to show something to the user, so we are always showing the win/place market.
         * in this case we are not navigating home when the user is viewing the win/place market whilst it's been set to hidden.
         */
        if (
          betType &&
          newData.every(
            (m) => m.market_type?.toLocaleLowerCase() !== betType.toLowerCase()
          )
        ) {
          await queryClient.invalidateQueries([keys.ntj]);
          navigate('/');
        }
        /* For old components using slice */
        dispatch(setMarkets(newData));
      },
    },
  });

  const exoticMarket = {
    isSuspended: !!data?.some(
      (m: TRaceMarket) =>
        m.market_type?.toLowerCase() === betType &&
        m.propositions?.every((p) => p.is_suspended)
    ),
    isClosed: data?.some(
      (m: TRaceMarket) =>
        m.market_type?.toLowerCase() === betType &&
        m.status === EGeneralStatus.Closed
    ),
  };

  const winMarket = {
    isHidden: !data?.some(
      (m: TRaceMarket) => m.market_type === EBetTypesDisplayNames.RacingWin
    ),
    isClosed: !!data?.some(
      (m: TRaceMarket) =>
        m.market_type === EBetTypesDisplayNames.RacingWin &&
        m.status === EGeneralStatus.Closed
    ),
    isSuspended: !!data?.some(
      (m: TRaceMarket) =>
        m.market_type === EBetTypesDisplayNames.RacingWin &&
        m.propositions?.every((p) => p.is_suspended)
    ),
  };

  const placeMarket = {
    isHidden: !data?.some(
      (m: TRaceMarket) => m.market_type === EBetTypesDisplayNames.RacingPlace
    ),
    isClosed: !!data?.some(
      (m: TRaceMarket) =>
        m.market_type === EBetTypesDisplayNames.RacingPlace &&
        m.status === EGeneralStatus.Closed
    ),
    isSuspended: !!data?.some(
      (m: TRaceMarket) =>
        m.market_type === EBetTypesDisplayNames.RacingPlace &&
        m.propositions?.every((p) => p.is_suspended)
    ),
  };

  return {
    markets: data ?? [],
    exoticMarket,
    placeMarket,
    winMarket,
  };
};
