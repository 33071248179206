import { CSSObject } from '@chakra-ui/react';
import betslipBg from '@/assets/juicybet/images/backgrounds/inner.png';

export const MultiCardWrapper: CSSObject = {
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderColor: 'alpha.50',
  borderWidth: '3px',
  bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${betslipBg})`,
  borderRadius: 'md',
  bgRepeat: 'repeat',
  bgSize: '130%',
  backgroundBlendMode: 'overlay',
  p: '2',
  color: 'white',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
  gap: '0',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'alpha.700',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  py: '3',
  color: 'white',
  background: 'blackAlpha.300',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 8px 0px rgba(0, 0, 0, 0.25) inset',
  fontSize: 'xs',
  fontWeight: 'medium',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'base',
  mb: '3',
  '.chakra-icon': {
    color: 'gamma.400',
  },
};
