/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HourglassBottom } from 'styled-icons/boxicons-solid';
import { Icon } from '@chakra-ui/react';
import { NavigateFunction } from 'react-router-dom';
import { TTemplate } from '@/helpers/createTemplate';
import { EGreenIDStatus } from '../../../../VerifyIdentity/Services/Config.VerifyIdentity';
import { ButtonVerified, VerifyTooltip } from '../../../styles/Overview.styles';
import { statusIcon, statusText } from '../../../services/Overview.utils';

const template = (
  navigate: NavigateFunction,
  verificationStatus?: EGreenIDStatus,
  isHeader?: boolean
): TTemplate => ({
  wrapper: (
    <VerifyTooltip
      hasArrow
      label={<FormattedMessage id="account.overview.mustverify" />}
    />
  ),
  content: (
    <ButtonVerified
      data-cy={`${isHeader ? `header` : `overview`}GetVerifiedButton`}
      status={verificationStatus}
      onClick={() => navigate('/account/settings/verify-identity')}
      rightIcon={
        <Icon
          as={statusIcon(verificationStatus)}
          color={
            statusIcon(verificationStatus) === HourglassBottom
              ? 'black'
              : 'inherit'
          }
        />
      }
      disabled={
        verificationStatus && verificationStatus !== EGreenIDStatus.IN_PROGRESS
      }
      isHeader={isHeader}
    >
      {statusText(verificationStatus)}
    </ButtonVerified>
  ),
});

export default template;
