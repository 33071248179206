import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flexEventContainerProps: FlexProps = {
  gap: '1',
};

export const flexEventProps: FlexProps = {
  gap: '1',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};

export const MultiCardWrapper: CSSObject = {
  borderRadius: 'md',
  p: '2',
  color: 'white',
  mt: '0',

  border: '1px',
  borderColor: 'whiteAlpha.700',
  bgGradient: 'linear(to-b, #4B5A6D, #3F4953)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.20), 0px 0px 8px 0px rgba(255, 255, 255, 0.50) inset',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'alpha.600',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  bg: 'transparent',
  border: '1px dashed rgba(255, 255, 255, 0.16)',
  mb: '6',
  color: 'gamma.100',
  alignItems: 'flex-start',
  fontWeigth: 'medium',
  gap: '1',
};

export const EventTitle: CSSObject = {
  color: 'alpha.600',
};
