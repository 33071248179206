import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bg: 'alpha.200',
  border: 'none',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset',
  borderRadius: 'md',
  flex: '1',
};
export const TypeItem: CSSObject = {
  color: 'gamma.400',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ResultRowWrapper: CSSObject = {
  border: '1px solid',
  borderColor: 'alpha.100',
  borderRadius: 'base',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'blackAlpha.500',
  '&:last-child': {
    mb: '2',
  },
};
export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};
export const ResultRowExoticRow: CSSObject = {
  color: 'gamma.200',
  fontSize: 'sm',
  fontWeight: 'medium',
  _last: {
    fontWeight: 'black',
    color: 'gamma.400',
  },
};
