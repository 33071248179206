import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Stop } from '@styled-icons/octicons/Stop';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';

export const BetApprovalContainer: CSSObject = {
  px: '0',
  pb: '0',
};

export const EstReturnsLabel: CSSObject = {
  color: 'beta.600',
  bg: 'beta.400',
  fontWeight: 'extrabold',
  height: '100%',
  alignItems: 'center',
  py: '0.5',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  borderRadius: 'md',
  h: '10',
  color: 'blackAlpha.700',
  bg: 'beta.400',
  justifyContent: 'center',
  boxShadow: 'none',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
};

export const ApprovedBetContainer: CSSObject = {
  background: 'green.400',
  bgGradient: 'none',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'md',
  color: 'blackAlpha.700',
  justifyContent: 'center',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
};

export const RejectedBetContainer: CSSObject = {
  background: 'red.500',
  bgGradient: 'none',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'md',
  color: 'blackAlpha.700',
  justifyContent: 'center',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
};

export const FooterContainer: CSSObject = {
  bg: 'beta.600',
  color: 'white',
  border: '1px solid',
  borderColor: 'beta.400',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'base',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
};

export const IconRejectedBet = () => <Icon as={Stop} boxSize="4" mr="2" />;
export const IconApprovedBet = () => (
  <Icon as={CheckCircle} boxSize="4" mr="2" />
);

export const BetRejectedText: CSSObject = {
  fontFamily: 'roboto',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'blackAlpha.700',
  textShadow: '0 1px 1px rgba(255, 255, 255, 0.25)',
};

export const BetApprovalText: CSSObject = {
  fontFamily: 'roboto',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'blackAlpha.700',
  textShadow: '0 1px 1px rgba(255, 255, 255, 0.25)',
};
