/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useField, useFormikContext } from 'formik';
import { RadioGroup, Skeleton } from '@chakra-ui/react';
import {
  useMutateDeleteCard,
  useQueryCards,
} from '@/api/deposit/deposit.hooks';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { getStrings } from '@/helpers/utils';
import {
  BadgeWrapper,
  BoxCardsContainer,
  CardNumber,
  FlexWrapper,
  IconButton,
  ImageCardLogo,
  RadioCard,
  RowDivider,
  TextLastFour,
} from '../styles/Form/Form.styles';
import { TextMaxCards } from './styles/SavedCards.styles';
import { isExpired } from '../../lib/deposit';
import visa from '@/assets/core/icons/visa.svg';
import mastercard from '@/assets/core/icons/mastercard.svg';
import { VaultedCard } from '../../Deposit';

type SavedCardsContainerProps = {
  setStep?: Dispatch<SetStateAction<number>>;
};

export default function SavedCardsContainer({
  setStep,
}: SavedCardsContainerProps) {
  const [searchParams] = useSearchParams();
  const intl = useIntl();

  const [lastSelectedCardToken, setLastSelectedCardToken] = useLocalStorage(
    'last_selected_card_token',
    'addCard'
  );

  const { setFieldValue, submitForm, isSubmitting } = useFormikContext();

  const [
    { value: selectedCard },
    // eslint-disable-next-line no-empty-pattern
    {
      /* error */
    },
    { setValue: setSelectedCard },
  ] = useField({
    name: 'card',
  });

  const queryCards = useQueryCards({
    options: {
      select(cards) {
        const addCard = {
          card_id: 'addCard',
          card_type: '',
          card_token: 'addCard',
          card_expiry: '',
          last_4: 'Add new card',
        };
        return [
          ...cards.map(({ last_4, ...rest }) => ({
            ...rest,
            last_4: `•••• ${last_4}`,
          })),
          addCard,
        ];
      },
      onError() {
        toast.error(
          intl.formatMessage({
            id: 'account.deposit.toasts.unabletogetcards',
          })
        );
      },
      onSuccess() {
        const qsCard = searchParams.get('card');
        const qsAmount = searchParams.get('amount');
        if (!IS_MOBILE_APP || !qsCard || !qsAmount) return;

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleCardChange(qsCard);
        // eslint-disable-next-line no-void
        void setFieldValue('amount', +qsAmount);
        // eslint-disable-next-line no-void
        void submitForm();
      },
    },
  });
  const cards: CardNormalizedSchema =
    queryCards.data?.map((card) => ({
      card_id: card.card_id,
      last_4: card.last_4,
      card_expiry: card.card_expiry,
      card_type: card.card_type,
      card_token: card.card_token,
    })) ?? [];
  const isCardsLoading = queryCards.isLoading;

  if (lastSelectedCardToken !== selectedCard.card_token) {
    const card = cards.find((x) => x.card_token === lastSelectedCardToken);
    if (card) setSelectedCard(card);
  }

  const handleCardChange = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const card = cards.find((crd) => crd.card_id === id)!;
    setSelectedCard(card);
    setLastSelectedCardToken(card.card_token);

    if (id !== 'addCard') {
      setStep?.((step) => (step !== 1 ? 1 : step));
    }
  };

  const { mutateAsync: deleteCard, isLoading: isDeleteCardInProgress } =
    useMutateDeleteCard();

  const handleDelete = (cardId: string) =>
    deleteCard({ id: cardId })
      .then(() => {
        toast.success(
          intl.formatMessage({
            id: 'account.deposit.toasts.cardremovesuccessful',
          })
        );
        setSelectedCard(cards[cards.length - 1]);
        setLastSelectedCardToken('addCard');
      })
      .catch(() =>
        toast.error(
          intl.formatMessage({
            id: 'account.deposit.toasts.unabletoremovecard',
          })
        )
      );

  return (
    <Cards
      cards={cards}
      handleDelete={handleDelete}
      handleCardChange={handleCardChange}
      selectedCard={selectedCard}
      isSubmitting={isSubmitting}
      isCardsLoading={isCardsLoading}
      isDeleteCardInProgress={isDeleteCardInProgress}
    />
  );
}

/**
 *
 */
const normalizeCard = (card: VaultedCard) => ({
  card_id: card.cardId,
  last_4: `•••• ${card.last4}`,
  card_expiry: `${card.expiryYear}-${card.expiryMonth}-01`,
  card_type: card.cardType,
  card_token: card.cardToken,
});

type PrimerCardsContainerProps = {
  isCardsLoading: boolean;
  vaultCards: VaultedCard[];
  setVaultCards: Dispatch<SetStateAction<VaultedCard[]>>;
  selectedCardID?: string;
  handleCardChange: (id: string) => void;
  deleteCard: ((id: string) => Promise<any>) | undefined;
  setSelectedCardID: Dispatch<SetStateAction<string>>;
  hideTopDivider: boolean;
};

export function PrimerCardsContainer({
  isCardsLoading,
  vaultCards,
  handleCardChange,
  selectedCardID,
  setVaultCards,
  deleteCard,
  setSelectedCardID,
  hideTopDivider,
}: PrimerCardsContainerProps) {
  const [deletingCardID, setDeletingCardID] = useState<string>('');

  const addCard = {
    card_id: 'addCard',
    card_type: '',
    card_token: 'addCard',
    card_expiry: '',
    last_4: 'Add new card',
  };

  const cards = vaultCards.map(normalizeCard);
  const selectedCard =
    cards.find((card) => card.card_id === selectedCardID) ?? addCard;

  const handleDelete = (id: string) => {
    try {
      setDeletingCardID(id);

      deleteCard?.(id)
        .then(() => {
          const indexToRemove = vaultCards.findIndex(
            (card) => card.cardId === id
          );
          if (
            window.localStorage.getItem('last_selected_card_token') ===
            vaultCards[indexToRemove].cardId
          ) {
            window.localStorage.setItem('last_selected_card_token', 'addCard');
            setSelectedCardID('addCard');
          }
          setVaultCards((_cards) => {
            const newVaultCards = [..._cards];
            newVaultCards.splice(indexToRemove, 1);
            return newVaultCards;
          });
        })
        .catch((err: unknown) => console.log('err', err))
        .finally(() => setDeletingCardID(''));
    } catch (error) {
      setDeletingCardID(id);

      // handle errors
    }
  };

  return (
    <Cards
      cards={[...(cards ?? []), addCard]}
      handleDelete={handleDelete}
      handleCardChange={handleCardChange}
      selectedCard={selectedCard}
      isSubmitting={false}
      isCardsLoading={isCardsLoading}
      isDeleteCardInProgress={false}
      deletingCardID={deletingCardID}
      hideTopDivider={hideTopDivider}
    />
  );
}

/**
 * CARDS UI
 */
type CardNormalizedSchema = {
  card_id: string;
  last_4: string;
  card_expiry: string;
  card_type: string;
  card_token: string; // deprecated with Primer
}[];

type CardsProps = {
  cards: CardNormalizedSchema;
  handleDelete: (id: string) => void;
  handleCardChange: (id: string) => void;
  selectedCard: CardNormalizedSchema[0];
  isSubmitting: boolean;
  isCardsLoading: boolean;
  isDeleteCardInProgress: boolean;
  deletingCardID?: string;
  hideTopDivider?: boolean;
};

export function Cards({
  cards,
  handleCardChange,
  selectedCard,
  isSubmitting,
  isCardsLoading,
  isDeleteCardInProgress,
  handleDelete,
  deletingCardID,
  hideTopDivider,
}: CardsProps) {
  const {
    Deposit: { Card },
  } = getStrings()[0].Account;

  useEffect(() => {
    if (selectedCard.card_id === 'addCard' && cards.length === 4) {
      handleCardChange(cards[0].card_id);
    }
  }, [cards, handleCardChange, selectedCard.card_id]);

  return (
    <BoxCardsContainer>
      {(() => {
        if (isCardsLoading) {
          return [...new Array(4)].map((_, i) => (
            <Skeleton
              key={i}
              sx={{ minW: '145px', h: '10', mb: '3', bg: 'gray.400' }}
            />
          ));
        }

        return (
          <RadioGroup
            onChange={handleCardChange}
            value={selectedCard.card_id}
            defaultValue="addCard"
          >
            {cards.map((data, i) => (
              <div key={data.card_id}>
                {(hideTopDivider && i === 0) || <RowDivider />}
                <FlexWrapper
                  isSavedCard={data.card_id !== 'addCard'}
                  key={data.card_id}
                >
                  <RadioCard
                    data-cy={`savedCard${i}`}
                    value={data.card_id}
                    isDisabled={
                      isSubmitting ||
                      (data.card_id !== 'addCard' &&
                        isExpired(data.card_expiry)) ||
                      (data.card_id === 'addCard' && cards.length === 4)
                    }
                  >
                    <CardNumber>
                      {data.last_4 !== 'Add new card' && (
                        <ImageCardLogo
                          src={
                            data.card_type.toLocaleLowerCase() === 'mastercard'
                              ? mastercard
                              : visa
                          }
                          isInRow
                        />
                      )}

                      <TextLastFour
                        ml={data.last_4 === 'Add new card' ? 0 : undefined}
                      >
                        {data.last_4}
                      </TextLastFour>
                    </CardNumber>
                  </RadioCard>
                  {data.last_4 !== 'Add new card' &&
                    isExpired(data.card_expiry) && (
                      <BadgeWrapper variant="solid">
                        {Card.Expired}
                      </BadgeWrapper>
                    )}

                  {data.last_4 !== 'Add new card' && (
                    <IconButton
                      variant="unstyled"
                      aria-label="Delete card"
                      onClick={() => handleDelete(data.card_id)}
                      isLoading={
                        isCardsLoading ||
                        isDeleteCardInProgress ||
                        deletingCardID === data.card_id
                      }
                      isDisabled={isSubmitting}
                    />
                  )}
                </FlexWrapper>
              </div>
            ))}

            <RowDivider />

            <TextMaxCards>You may only have up to 3 cards</TextMaxCards>
          </RadioGroup>
        );
      })()}
    </BoxCardsContainer>
  );
}
