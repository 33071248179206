import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  color: 'white',
  textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
  fontSize: ['xl', null, '2xl'],
  pb: '1',
  fontFamily: 'Spectral',
  fontWeight: 'normal',
  textTransform: 'capitalize',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  mb: ['4px', null, '0px'],
};
