import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'blackAlpha.600' : 'alpha.700',

  color: isActive ? 'beta.500' : 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'base',
  borderWidth: isActive ? '' : '2px',
  borderColor: isActive ? '' : 'beta.500',
  boxShadow: isActive
    ? '0px 1px 0px 0px #000 inset'
    : '0px 0px 0px 2px #0C2549 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
  '&:hover, &:focus': {
    color: isActive ? 'gray.700' : 'white',
    bg: isActive
      ? 'gray.400'
      : 'linear-gradient(180deg, var(--bc-colors-gamma-700) 0%, var(--bc-colors-gamma-800) 100%)',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.600',
  color: 'beta.500',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 1px 0px 0px #000 inset',
  '&:hover, &:focus': {
    color: 'gray.700',
    bg: 'gray.400',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
