import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: '',

      background: {
        bg: ['alpha.600', null, 'unset'],
      },

      drawer: {
        ...base.drawer,
      },
      drawerBody: {},
      icon: {
        ...base.icon,
        ':not(.dropDownIcon)': {
          color: ['beta.500', null, 'beta.800'],
          fill: ['beta.500', null, 'beta.800'],
        },
      },
      headerSX: {
        pointerEvents: 'none',
      },
      dropDownInner: {
        ...base.dropDownInner,
        position: 'relative',
        borderBottom: ['1px solid', null, '1px dashed'],
        borderColor: ['whiteAlpha.200', null, 'blackAlpha.400'],
        _after: {
          content: '""',
          position: 'absolute',
          left: '0',
          bottom: '0',
          width: 'full',
          borderBottom: '1px',
          borderColor: ['blackAlpha.400', null, 'whiteAlpha.200'],
          borderStyle: ['solid', null, 'dashed'],
        },
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,
        fontFamily: 'accent',
        fontSize: 'sm',
        fontWeight: 'hairline',
        height: '40px',
        color: ['white', null, 'beta.800'],
        textShadow: [
          '0px 4px 4px rgba(0, 0, 0, 0.25)',
          null,
          '0px 1px 0px rgba(255, 255, 255, 0.4)',
        ],
        transition: 'all, 0.2s linear',
        p: '2',
        position: 'relative',
        borderBottom: ['1px solid', null, '1px dashed'],
        borderColor: ['whiteAlpha.200', null, 'blackAlpha.400'],
        _after: {
          content: '""',
          position: 'absolute',
          left: '0',
          bottom: '0',
          width: 'full',
          borderBottom: '1px',
          borderColor: ['blackAlpha.400', null, 'whiteAlpha.200'],
          borderStyle: ['solid', null, 'dashed'],
        },
        ':hover': {
          bg: ['blackAlpha.200', null, 'transparent'],
          color: ['alpha.200', null, 'beta.600'],
          '.icon, .svgIcon': {
            color: ['alpha.200', null, 'beta.600'],
            fill: ['alpha.200', null, 'beta.600'],
          },
          '.dropDownIcon': {
            color: ['alpha.200', null, 'beta.600'],
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: ['beta.500', null, 'beta.800'],
          fill: 'transparent',
          transition: 'all, 0.2s linear',
        },
      },
      lastLink: {
        borderBottom: 'none',
        _after: {
          borderBottom: 'none',
        },
      },
      dropDownContainerClosed: {},
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        h: '40px',
        '.dropDownInner': {
          px: '2',
          color: ['beta.500', null, 'delta.600'],
          'svg:not(.dropDownIcon)': {
            fill: ['beta.500', null, 'delta.600'],
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: ['beta.500', null, 'delta.600'],
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
