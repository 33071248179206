/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { uuid } from 'uuidv4';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';
import { useExoticsBetSlip } from '../../../../../components/Betslip/Services/Betslip.hooks';

import {
  EBetTypes,
  EGeneralStatus,
  TRunner,
} from '../../../../../lib/DBModels';
import {
  setSelected,
  setValidSelectionAmount,
  setFlexi,
  setTotalStake,
  setIsBoxed,
} from '../../../../../redux/Exotics.slices';
import { TSelected } from '../Exotics.types';
import { TBetSlipBetSubmissionExoticsType } from '../../../../../components/Betslip/Services/Betslip.types';
import {
  calculateExoticCombinations,
  countDecimals,
  getAmountOfPlaces,
} from '../../../../../components/Betslip/Services/Betslip.utils';
import { getStrings } from '@/helpers/utils';
import { keys } from '../../../../../api/api.keys';
import { queryRaceMarkets } from '../../../../../api/racing/racing';
import { roundDownStake } from './Exotics.utils';
import { useRaceMarkets } from '../../../services/RaceDetails.hooks';
import { getToteMultiForBetSubmission } from '@/store/ToteMultis';
import { validateStake } from '@/components/Betslip/lib';

export const parseBetType = (
  betType: string
): TBetSlipBetSubmissionExoticsType => {
  const enumIndex = Object.values(TBetSlipBetSubmissionExoticsType)
    .map((val) => val.toLowerCase())
    .indexOf(betType);
  return Object.values(TBetSlipBetSubmissionExoticsType)[enumIndex];
};

export const useBoxed = () => {
  const dispatch = useAppDispatch();
  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const { selected } = useAppSelector((state) => state.exotics);

  const toggleIsBoxed = (isBoxed: boolean) => {
    // Any non-boxed selected runners should become boxed when toggling between boxed/non-boxed
    if (isBoxed) {
      const selectedRunners: string[] = [];
      Object.values(selected).map((place) =>
        place.map((runner) => selectedRunners.push(runner))
      );
      const uniqueSelectedRunners = selectedRunners.filter(
        (val, ind, arr) => arr.indexOf(val) === ind
      );

      // The amount of places per bet type is validated in Betslip.utils.ts
      // calculateExoticCombinations so adding all four places here is fine.
      const newSelection: Record<number, string[]> = {
        1: uniqueSelectedRunners,
        2: uniqueSelectedRunners,
        3: uniqueSelectedRunners,
        4: uniqueSelectedRunners,
      };
      dispatch(setSelected(newSelection));
    }
    dispatch(setIsBoxed(isBoxed));
  };

  // Unless the race is open, this will return false.
  const isAvailable = raceRunnerList.raceMeta.status !== EGeneralStatus.Open;

  return { toggleIsBoxed, isAvailable };
};

export const useSelection = (runner?: TRunner | null) => {
  const dispatch = useAppDispatch();

  const { betType } = useParams();

  const { selected, validSelectionAmount, totalStake, flexi } = useAppSelector(
    (state) => state.exotics
  );

  const positions: string[] = [
    ...new Array(
      (() => {
        if (betType === EBetTypes.Exacta) return 2;
        if (betType === EBetTypes.Quinella) return 2;
        if (betType === EBetTypes.Trifecta) return 3;
        return 4;
      })()
    ).fill(''),
  ];

  const isChecked = () =>
    positions.reduce<boolean>((acc: boolean, v, i: number) => {
      if (!acc) return acc;

      const index = i + 1;

      return selected[index].includes(String(runner?.number ?? 0));
    }, true);

  const selectAllPositions = () => {
    const newSelection = {
      ...selected,
      ...positions.reduce(
        (acc: Record<number, string[]>, v: any, i: number) => {
          const index = i + 1;

          if (isChecked()) {
            acc[index] = selected[index].filter(
              (val) => val !== String(runner?.number)
            );
          }

          if (!selected[index].includes(String(runner?.number ?? 0))) {
            acc[index] = [...selected[index], String(runner?.number ?? 0)].sort(
              (a, b) => a.localeCompare(b, undefined, { numeric: true })
            );
          }

          return acc;
        },
        {}
      ),
    };

    dispatch(setSelected(newSelection));
  };

  const selectSinglePosition = (index: number, isSelected: boolean) => {
    const newSelection = {
      ...selected,
      [index]: isSelected
        ? selected[index].filter((val) => val !== String(runner?.number))
        : [...selected[index], String(runner?.number ?? 0)].sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true })
          ),
    };
    dispatch(setSelected(newSelection));
  };

  const resetSelections = () => {
    const newSelection = { 1: [], 2: [], 3: [], 4: [] };
    dispatch(setSelected(newSelection));
  };

  return {
    selectAllPositions,
    selectSinglePosition,
    resetSelections,
    isChecked,
    positions,
    validSelectionAmount,
    flexi,
    setFlexi,
    totalStake,
    setTotalStake,
    betType,
  };
};

export const useSelectionPopup = () => {
  const dispatch = useAppDispatch();
  const { setBet } = getBetSlipStoreActions();
  const { addToBetSlip } = useExoticsBetSlip();
  const [searchParams] = useSearchParams();
  const { betType } = useParams();
  const { raceId, venueId } = {
    raceId: searchParams.get('raceId'),
    venueId: searchParams.get('venueId'),
  };
  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const [{ Generic }] = getStrings();

  const { selected, validSelectionAmount, totalStake, flexi } = useAppSelector(
    (state) => state.exotics
  );
  const [prevBetType, setPrevBetType] = useState('');
  const { toggleIsBoxed } = useBoxed();

  useEffect(() => {
    if (!betType || betType === prevBetType) {
      return;
    }
    const exoticType = parseBetType(betType);
    toggleIsBoxed(exoticType === TBetSlipBetSubmissionExoticsType.Quinella);
    setPrevBetType(betType);
  }, [betType, toggleIsBoxed, prevBetType, setPrevBetType]);

  useEffect(() => {
    if (totalStake) {
      dispatch(
        setFlexi(+((totalStake / validSelectionAmount) * 100).toFixed(2))
      );
    }
  }, [dispatch, validSelectionAmount, totalStake]);

  useEffect(() => {
    if (!betType) {
      return;
    }
    const exoticType = parseBetType(betType);
    const runnersAsNumbers = Object.values(selected).map((strArray) =>
      strArray.map((str) => Number(str))
    );
    dispatch(
      setValidSelectionAmount(
        calculateExoticCombinations(runnersAsNumbers, exoticType).length
      )
    );
  }, [betType, selected, dispatch]);

  const handleTotalStakeChange = (tStake: string) => {
    if (!tStake) {
      dispatch(setTotalStake(undefined));
      dispatch(setFlexi(undefined));
      return;
    }

    const stakeInput = tStake === '' ? NaN : Number(tStake);

    const stake =
      countDecimals(stakeInput) >= 3
        ? parseFloat(stakeInput.toString().slice(0, -1))
        : stakeInput;

    dispatch(setTotalStake(stake));
    dispatch(setFlexi(+((stake / validSelectionAmount) * 100).toFixed(2)));
  };

  const handleFlexiChange = (newFlexi: string) => {
    if (newFlexi) {
      dispatch(setFlexi(undefined));
      dispatch(setTotalStake(undefined));
      return;
    }
    let validValue = validateStake(newFlexi);

    const hasDecimal = validValue.includes('.');
    if (hasDecimal) {
      if (validValue.at(-1) === '.') {
        validValue = validValue.slice(0, -1);
      } else if ((flexi?.toString() ?? '').length < validValue.length) {
        validValue = flexi?.toString() ?? '';
      }
    }

    const val = Number(validValue);
    dispatch(setFlexi(val));
    dispatch(setTotalStake(+((val * validSelectionAmount) / 100).toFixed(2)));
  };

  const roundStake = () =>
    dispatch(
      setTotalStake(roundDownStake(totalStake ?? 0, validSelectionAmount))
    );

  const { data: raceMarketsData } = useQuery(
    [keys.raceMarkets, raceId],
    () => queryRaceMarkets({ race_id: raceId ?? '' }),
    {
      staleTime: 60 * 5000, // 5 minutes
    }
  );

  const handleBetSlipAddition = (stake?: string) => {
    if (!raceRunnerList?.raceMeta?.race_id) {
      toast.error(Generic.ErrorOccurred);
      return;
    }

    const race = raceRunnerList?.raceMeta;

    const raceMarket = raceMarketsData?.find(
      (market) => market.market_type?.toLowerCase() === betType
    );

    const raceVenue = raceRunnerList?.venues?.find(
      (venue) => venue?.venue_id === venueId
    );

    if (!raceMarket || !betType || !raceVenue) {
      toast.error(Generic.ErrorOccurred);
      return;
    }

    const exoticType = parseBetType(betType);
    const amountOfPlaces = getAmountOfPlaces(exoticType);
    const runnersAsNumbers = Object.values(selected)
      .map((strArray) => strArray.map((str) => Number(str)))
      .slice(0, amountOfPlaces);

    // TODO: Check this might need a ref...
    const id = uuid();
    setBet({
      id,
      type: 'Exotics',
      subType: exoticType,
      propId: raceMarket.propositions?.[0].proposition_id ?? '',
      selection: runnersAsNumbers,
      stake: String(stake),
      misc: {
        ...race,
        ...raceMarket,
        ...raceVenue,
        race,
        venue: raceVenue,
        market: raceMarket,
      },
    });

    addToBetSlip(
      race,
      raceMarket,
      raceVenue,
      exoticType,
      runnersAsNumbers,
      validSelectionAmount,
      totalStake || NaN
    );

    // Reset Flexi & stake values after adding to betslip
    dispatch(setTotalStake(undefined));
    dispatch(setFlexi(undefined));
  };

  const handleBetSlipAdditionToteMulti = (
    stake?: string,
    raceNumbersForToteMulti?: string[]
  ) => {
    const {
      selectedQuaddieType,
      propositionId,
      selections: toteMultiSelection,
    } = getToteMultiForBetSubmission();
    const selection = toteMultiSelection
      .sort((a, b) => (a.raceNumber.localeCompare(b.raceNumber) ? 1 : -1))
      .map((sel) => Array.from(sel.selection));

    const race = raceRunnerList?.raceMeta;

    const raceMarket = raceMarketsData?.find(
      (market) => market.market_type?.toLowerCase() === betType?.toLowerCase()
    );

    const raceVenue = raceRunnerList?.venues?.find(
      (venue) => venue?.venue_id === venueId
    );

    if (!raceMarket || !betType || !raceVenue) {
      toast.error(Generic.ErrorOccurred);
      return;
    }

    // TODO: Check this might need a ref...
    const id = uuid();

    const bet = {
      id,
      type: 'Exotics',
      subType: selectedQuaddieType as string,
      propId: propositionId,
      selection,
      raceNumbersForToteMulti,
      stake: String(stake),
      misc: {
        ...race,
        ...raceMarket,
        ...raceVenue,
        race,
        venue: raceVenue,
        market: raceMarket,
      },
    };
    setBet(bet);
    return bet;
  };

  const handleClearSelections = () => {
    dispatch(
      setSelected({
        1: [],
        2: [],
        3: [],
        4: [],
      })
    );
  };

  return {
    totalStake,
    handleTotalStakeChange,
    flexi,
    handleFlexiChange,
    handleBetSlipAddition,
    handleBetSlipAdditionToteMulti,
    handleClearSelections,
    roundStake,
  };
};

export const useSelectField = () => {
  const dispatch = useAppDispatch();
  const { exoticMarket } = useRaceMarkets();
  const { selected } = useAppSelector((state) => state.exotics);
  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const [fieldSelectedList, setFieldSelectedList] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    const validRunners = raceRunnerList.raceRunners.filter(
      (runner) => runner.status === EGeneralStatus.Open
    );
    setFieldSelectedList(
      Object.values(selected).map(
        (sel) => sel.length === validRunners.length && validRunners.length > 0
      )
    );
  }, [selected, raceRunnerList]);

  const selectField = (index: number) =>
    dispatch(
      setSelected({
        ...selected,
        [index]: raceRunnerList.raceRunners
          .filter((runner) => runner.status === EGeneralStatus.Open)
          .map((runner) => runner.number?.toString()),
      } as TSelected)
    );

  const deselectField = (index: number) =>
    dispatch(
      setSelected({
        ...selected,
        [index]: [],
      } as TSelected)
    );

  const selectBoxedField = () => {
    const runnerNumbers: string[] = raceRunnerList.raceRunners
      .filter((runner) => runner.status === EGeneralStatus.Open)
      .map((runner) => runner.number?.toString() || '');
    dispatch(
      setSelected({
        1: runnerNumbers,
        2: runnerNumbers,
        3: runnerNumbers,
        4: runnerNumbers,
      })
    );
  };

  const deselectBoxedField = () =>
    dispatch(
      setSelected({
        1: [],
        2: [],
        3: [],
        4: [],
      } as TSelected)
    );

  const toggleFieldSelection = (index: number) =>
    fieldSelectedList[index]
      ? deselectField(index + 1)
      : selectField(index + 1);

  const toggleBoxedFieldSelection = () =>
    fieldSelectedList.some((place) => !place)
      ? selectBoxedField()
      : deselectBoxedField();

  return {
    fieldSelectedList,
    boxedFieldSelectedList: fieldSelectedList.every((list) => list),
    toggleFieldSelection,
    toggleBoxedFieldSelection,
    isDisabled:
      raceRunnerList.raceRunners.filter(
        (runner) => runner.status === EGeneralStatus.Open
      ).length === 0 ||
      exoticMarket.isClosed ||
      exoticMarket.isSuspended,
  };
};
