import { RaceCardSchema } from './RaceCards.styles';
import cardBg from '@/assets/sugarcastle/images/backgrounds/cardBg.png';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },
  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    borderRadius: 'base',
    border: '2px solid',
    borderColor: 'beta.400',
    boxShadow:
      '0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 2px #443318 inset',
    bgImage: cardBg,
    bgSize: 'cover',
    px: '0.5',
  },
  LinkCardHeader: {
    bg: 'transparent',
    color: 'beta.600',
    p: '2',
  },
  TextCardHeading: {
    color: 'beta.600',
    fontFamily: 'Fraunces',
    fontSize: 'sm',
    fontWeight: 'semibold',
  },
  TextRaceNumber: {
    bg: 'beta.500',
    borderRadius: 'base',
    color: 'beta.300',
  },
  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    px: 0,
    pt: 0,
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'beta.600',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow:
      '0px 1px 0px 0px rgba(0, 0, 0, 0.30), 0px 4px 4px -3px rgba(0, 0, 0, 0.30) inset',
    lineHeight: 'normal',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.500',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.35)',
    sx: {
      ':nth-last-child(-n+2)': {
        borderBottom: 'none',
        boxShadow: 'none',
      },
    },
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'beta.600',
    fontWeight: 'bold',
  },
  TextRunnerNumber: {
    fontWeight: 'bold',
  },
  LinkRaceDetails: {
    color: 'white',
    mb: '0.5',
    bgGradient: 'linear(to-b, gamma.500, gamma.700)',
    boxShadow: '0px -2px 0px 0px rgba(0, 0, 0, 0.24)',
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontFamily: 'Fraunces',
    fontWeight: 'bold',
    textShadow: '0px 1px 0px rgba(255, 185, 130, 0.50)',
    lineHeight: 'normal',
    letterSpacing: 'widest',
    _hover: {
      bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    },
  },
  buttonRaceCardOddsProps: {
    variant: 'odds',
  },
};

export const IconCardHeader: CSSObject = {
  color: 'beta.600',
  boxSize: '4',
};
