import {
  AbsoluteCenterProps,
  BoxProps,
  DividerProps,
  FlexProps,
  GridItemProps,
  GridProps,
  TextProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './CompetitionsMatchCard.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type CompetitionsMatchCardSchema = Partial<{
  vsBoxWrapper: BoxProps;
  vsDivider: DividerProps;
  absoluteCenter: AbsoluteCenterProps;
  matchCardOverride: BoxProps;
  propositionButtonOverride: CustomButtonProps;
  propositionButtonOverrideSecondary: CustomButtonProps;
  gridWrapper: GridProps;
  gridItem: GridItemProps;
  flexWrapper: FlexProps;
  flexText: TextProps;
  gridItemTextNoProposition: TextProps;
  flexAwayTeam: FlexProps;
  flexAwayTeamText: TextProps;
}>;

export const competitionsMatchCardStyles: CompetitionsMatchCardSchema = {
  flexAwayTeamText: {
    'data-testid': 'competitionsMatchCard-FlexAwayTeamText',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.flexAwayTeamText;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexAwayTeam: {
    'data-testid': 'competitionsMatchCard-FlexAwayTeam',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.flexAwayTeam;
      } catch (err) {
        return {};
      }
    })(),
  },
  gridItemTextNoProposition: {
    'data-testid': 'competitionsMatchCard-GridItemTextNoProposition',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.gridItemTextNoProposition;
      } catch (err) {
        return {};
      }
    })(),
  },
  propositionButtonOverrideSecondary: {
    'data-testid': 'competitionsMatchCard-PropositionButtonOverrideSecondary',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.propositionButtonOverrideSecondary;
      } catch (err) {
        return {};
      }
    })(),
  },
  gridWrapper: {
    'data-testid': 'competitionsMatchCard-GridWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.gridWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexText: {
    'data-testid': 'competitionsMatchCard-FlexText',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles?.flexText;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexWrapper: {
    'data-testid': 'competitionsMatchCard-FlexWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.flexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  gridItem: {
    'data-testid': 'competitionsMatchCard-GridItem',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles?.gridItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  propositionButtonOverride: {
    'data-testid': 'competitionsMatchCard-PropositionButtonOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.propositionButtonOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  matchCardOverride: {
    'data-testid': 'competitionsMatchCard-MatchCardOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.matchCardOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  vsBoxWrapper: {
    'data-testid': 'competitionsMatchCard-BoxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.vsBoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  vsDivider: {
    'data-testid': 'competitionVSDivider-VSDivider',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles?.vsDivider;
      } catch (err) {
        return {};
      }
    })(),
  },
  absoluteCenter: {
    'data-testid': 'competitionsMatchCard-AbsoluteCenter',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMatchCardStyles
          ?.absoluteCenter;
      } catch (err) {
        return {};
      }
    })(),
  },
};
