import React from 'react';
import { getStrings } from '@/helpers/utils';
import {
  PasswordRule,
  PasswordRulesContainer,
  PasswordRulesIcon,
} from '../styles/SignUp.styles';
import { usePasswordRules } from '../services/SignUp.hooks';

type TPasswordRules = {
  show?: boolean;
};

export default function PasswordRules({ show }: TPasswordRules) {
  /**
   * TODO:
   * Refactor all this to a custom hook
   */
  const [
    {
      Onboarding: { Step1 },
    },
  ] = getStrings();

  const {
    passwordRule1Valid,
    passwordRule2Valid,
    passwordRule3Valid,
    passwordRule4Valid,
  } = usePasswordRules();

  if (!show) return <></>;

  return (
    <PasswordRulesContainer>
      <PasswordRule active={passwordRule1Valid}>
        <PasswordRulesIcon active={passwordRule1Valid} />
        {Step1.PasswordRule1}
      </PasswordRule>

      <PasswordRule active={passwordRule2Valid}>
        <PasswordRulesIcon active={passwordRule2Valid} />
        {Step1.PasswordRule2}
      </PasswordRule>

      <PasswordRule active={passwordRule3Valid}>
        <PasswordRulesIcon active={passwordRule3Valid} />
        {Step1.PasswordRule3}
      </PasswordRule>

      <PasswordRule active={passwordRule4Valid}>
        <PasswordRulesIcon active={passwordRule4Valid} />
        {Step1.PasswordRule4}
      </PasswordRule>
    </PasswordRulesContainer>
  );
}
