import {
  EBetSlipBetSubmissionType,
  EBetSubmissionRejectionReason,
  TBetSlipBetSubmissionExoticsType,
} from '@/components/Betslip/Services/Betslip.types';
import { getThemeName } from '@/helpers/getThemeName';

const STRINGS = {
  Generic: {
    Home: 'Home',
    WinPlace: 'Win/Place',
    FixedWin: 'Fixed Win',
    FixedPlace: 'Fixed Place',
    FixedEachWay: 'Fixed Each Way',
    PickDate: 'Pick a Date',
    Close: 'Close',
    Email: 'Email',
    Bet: 'Bet',
    Bets: 'Bets',
    Password: 'Password',
    TermsConditions: 'Terms & Conditions',
    PrivacyPolicy: 'Privacy Policy',
    ResponsibleGambling: 'Responsible Gambling',
    ResponsibleGamblingTools: 'Responsible Gambling Tools',
    And: 'and',
    Policy: 'Policy',
    RestrictedFuture: 'Date cannot be in the future',
    RestrictedPast: 'Date cannot be in the past',
    Logout: 'Logout',
    Version: 'Version',
    SaveChanges: 'Save Changes',
    Verified: 'Verified',
    Unverified: 'Unverified',
    Markets: ' Markets',
    Race: 'Race',
    SeeAll: 'See All',
    Bonus: 'Bonus: ',
    loading: 'Loading...',
    Required: 'Required',
    HelpLine: '1800 858 858',
    GamblersHelp: 'Gambler’s Help',
    GamblingVic: 'Responsible Gambling Victoria',
    ErrorOccurred: 'An error occurred. Please try again',
    SelectPlaceholder: 'Please Select',
    Login: 'Log In',
    Join: 'Join Now',
    SignUp: 'Sign up',
    Stake: 'Stake',
    EmptyStake: '0.00',
    Flexi: 'Flexi',
    Scratched: 'Scratched',
    Runners: 'Runners',
    Win: 'Win',
    Hello: 'Hello',
    HideBalance: 'Hide balance',
    Balance: 'Balance',
    Withdrawable: 'Withdrawable',
    Deposit: 'Deposit',
    RateLimitError: 'Please wait a few moments and try again',
  },
  Onboarding: {
    Step1: {
      PageTitle: '| Signup',
      Header: 'Welcome, Let’s get you set up!',
      Welcome: `Welcome to ${getThemeName()}`,
      Subheader: 'Please enter your details.',
      EmailInputPlaceholder: 'eg. john@email.com',
      EmailInputError: 'Email requirements not met',
      EmailInputEmpty: 'An email address is needed',
      PasswordInputLabel: 'Create a password',
      PasswordInputPlaceholder: '••••••••',
      PasswordInputError: 'Password requirements not met',
      PasswordNoSpaces: 'Password cannot contain any spaces',
      PasswordEmpty: 'A password is needed',
      PasswordRule1: 'Includes lower and uppercase',
      PasswordRule2: 'Includes at least 1 number',
      PasswordRule3: 'Password is more than 8 characters in length',
      PasswordRule4: "Password doesn't contain spaces",
      AgeAcknowledgement:
        'I acknowledge that I am over 18 and have read, understood and agree to be bound by the',
      AgeAcknowledgementError:
        'Sorry, you need to agree before you can create an account',
      PromotionAcknowledgement: `I agree to receive promotional material from ${getThemeName()} via email, phone and other means`,
      Button: 'Create My Account',
      Login: 'Already have an account?',
      LoginCTA: 'Log In',
    },
    Step2: {
      PageTitle: '| Verification',
      Header: "Let's get you verified!",
      Subheader:
        'This info will allow us to make sure you are a real person and lets us check that you are OK to start betting with us.',
      TitleInputLabel: 'Title',
      TitleOptions: ['Mr', 'Miss', 'Ms', 'Dr', 'Other'],
      TitleRequiredError: 'Title is required',
      FieldTooLongErrorText: 'Field is too long',
      FirstNameInputLabel: 'First Name',
      FirstNameErrorText: 'First Name is required',
      LastNameInputLabel: 'Last Name',
      LastNameErrorText: 'Last Name is required',
      ValidNameRequiredErrorText: 'Valid name is required',
      MobileInputLabel: 'Mobile',
      MobilePlaceholder: 'eg. 0412345678',
      MobileRequiredErrorText: 'Mobile is required',
      MobileNumberOnlyError: 'Mobile field can only contain numbers',
      MobileNumberAUOnlyError: 'Must be an Australian mobile number.',
      AddressInputLabel: 'Residential Address',
      AddressInputPlaceholder: '* Address must match your ID',
      AddressInputManualPlaceholder: 'Manual Entry',
      AddressRequiredErrorText: 'Residential Address is required',
      AddressInputManualEntry: 'Can’t see your address? Enter it manually',
      StreetNumberInputLabel: 'Street number',
      StreetNumberRequiredError: 'Street number is required',
      StreetNameInputLabel: 'Street name',
      StreetNameRequiredError: 'Street name is required',
      SuburbInputLabel: 'Suburb',
      SuburbRequiredError: 'Suburb is required',
      StateInputLabel: 'State',
      StateRequiredError: 'State is required',
      PostcodeInputLabel: 'Postcode',
      PostcodeRequiredError: 'Postcode is required',
      PostcodeLengthError: 'Postcode must be 4 digits',
      DobInputLabel: 'Date of Birth',
      DobInputError:
        'Sorry, you need to be over 18 years of age to use this platform',
      DobRequiredErrorText: 'Date of Birth is required',
      VerificationErrorText:
        "Sorry, we couldn't verify you. Please try again later.",
      SkipVerificationOption: 'Please skip for now and manually verify me',
      VerificationError: 'There was an error verifying you',
      Button: 'Get Verified',
      PromoCodeLabel: 'Code',
    },
    Step3: {
      PageTitle: '| Deposit Limit',
      Header: 'Congrats! You are ready to go',
      Subheader: 'Would you like to set up a deposit limit?',
      RadioOption1:
        'No, I have read and understand the benefits and do not want to set a Deposit Limit',
      RadioOption2: 'Yes, I would like to set a Deposit Limit',
      LimitAmountInputLabel: 'Limit amount',
      LimitPeriodInputLabel: 'Limit period',
      LimitPeriodOptions: ['7 Days', '14 Days', '28 Days'],
      LimitAmountRequiredText:
        'As you have chosen to add a deposit limit, your limit amount is required',
      LimitDurationRequiredText:
        'As you have chosen to add a deposit limit, your limit duration is required',
      LimitNumbersOnlyError: 'Deposit limit can only contain numbers',
      DepositLimitError: 'There was an error creating your deposit limit',
      Button: 'Continue',
    },
    LocationNotAllowed: {
      PageTitle: '| Location not allowed',
      Header: 'We Apologise',
      Subheader: `You seem to be using ${getThemeName()} from a location we do not currently support.`,
      BodyCopy:
        "If you are in a country that we are licensed to operate in and you think there's an error, please contact us at ",
      OurOffices: 'Our Offices',
      LocationEnquiry: 'Location Enquiry - ',
    },
    FirebaseErrorMessages: {
      ErrorCreatingAccount: 'There was an issue creating your account:',
      WrongCredentials:
        "Your credentials don't seem to be correct. Please try again.",
      EmailInUse: 'This email address is already in use.',
      UserDisabled: 'Your account has been disabled.',
      UnspecifiedError:
        'An unspecified error occurred. Please try again later.',
      UserNotFound: 'Unable to find your account.',
      TooManyRequests:
        'Access to this account has been temporarily disabled due to many failed login attempts.',
    },
    SignUpErrorMessages: {
      ContactCustomerService: 'Error: Contact Customer Service',
      EmailExists: 'This email address is already in use.',
      StreetNumberRequired:
        'Please enter an address that contains a street number.',
      SuspendedOrExcludedOrClosed: `We were unable to complete your ID verification, please login to try again.
      If the issue persists please contact service@${getThemeName().toLowerCase()}.com.au`,
    },
    ForgotPassword: {
      PageTitle: '| Forgot Password',
      Header: 'Forgot Password',
      BodyText:
        'Please enter your email below and we will send you a link to reset your password.',
      SuccessText: 'Thank you. We’ve sent a reset link to ',
      LoginLink: 'Click here to login',
      Button: 'Send reset link',
    },
    Login: {
      PageTitle: '| Login',
      Header: 'Welcome Back',
      ForgotPassword: 'Forgot password?',
      Button: 'Login',
      NotSignedIn: 'You are not signed in.',
      AutoLogoutMessage:
        'You have been automatically signed out due to inactivity. Please sign in again.',
      Signup: "Don't have an account?",
      SignupCTA: 'Join Now',
      Error_Temporary_Exclusion:
        'Sorry... Your account has been locked until DATE',
      Error_Suspended_KYC:
        'We are unable to verify your identity. Please contact customer service to continue',
      Error_Closed_Account:
        'Your account has been closed. Please contact support to reactivate the account',
      Error_Permanent_Exclusion: 'Your account has been permanently excluded',
      Verification_In_Progress:
        'Thank you for your verificaton submission, your verification is currently in progress, please come back later to access your account.',
    },
  },
  BetSlip: {
    betSlipHeading: 'Bet Slip',
    betSlipReviewHeading: 'Review & Confirm',
    betSlipReceiptHeading: 'Bet Receipt',
    reuseSelectionsButton: 'Reuse Selections',
    potentialReturns: 'Est. payout',
    estPayout: 'Est. payout',
    negativeError: 'Negative stake',
    useBonusBet: 'Bonus bet',
    eachWay: 'Each Way',
    bonusBetApplied: 'Bonus Applied',
    eventRules: {
      ruleHeading: 'Event Rules: ',
      noPlace: 'No place betting available on this race',
      twoPlaceDividends: 'Only two place dividends paid',
      threePlaceDividends: 'Three place dividends paid',
    },
    emptyBetsModal: {
      warningHeading: 'Warning',
      emptyModalMessage: 'You must enter a stake to place a bet',
      modalButton: 'Ok',
    },
    modal: {
      stakeAllSingles: 'Stake all singles',
    },
    betSlipBetCard: {
      stake: 'STAKE',
      bal: 'Bal',
      placed: 'Bet ID: ',
      placedDate: 'Bet placed on: ',
      emptyStakeError: 'Stake required',
      negativeValueError: 'Negative value',
      invalidStake: 'Invalid Stake',
      emptyFlexError: 'Flexi required',
      betIDLabel: 'Bet ID:',
      fixedPriceWinLabel: 'Win (Fixed Price)',
      fixedPricePlaceLabel: 'Place (Fixed Price)',
      winLabel: 'Win',
      combos: 'Combos',
      reviewStakeMessage: 'Stake',
      stakePerCombo: 'Stake per combo',
      partialBetApprovalInfo: '% has been ',
      betApprovalInfo: 'Your bet has been ',
      unfortunateReject: 'Unfortunately, your bet has been rejected.',
      approved: 'approved.',
      rejected: 'rejected.',
      pendingApproval: 'Pending approval',
      thisEventIsClosed: 'Betting has closed on your selection.',
      Multis: {
        estReturns: 'Est. Return',
        estNoReturns: '$0.00',
        legMulti: 'Leg Multi',
        leg: 'Leg',
      },
      errors: {
        // To Do. Confirm these values
        [EBetSubmissionRejectionReason.OddsChange]:
          'Your odds have changed. Please review your selections before confirming.',
        [EBetSubmissionRejectionReason.InsufficentFund]:
          'Your balance is too low. Lower your stake or make a deposit to cover your bets.',
        [EBetSubmissionRejectionReason.PropositionClosed]:
          'Betting has closed on your selection.',
        [EBetSubmissionRejectionReason.HighRisk]:
          'Your bet has not been accepted.',
        [EBetSubmissionRejectionReason.AccountClosed]:
          'We were unable to process your request. Contact support for further assistance.',
        [EBetSubmissionRejectionReason.ManualRejection]:
          'Unfortunately, your bet has been rejected.',
        [EBetSubmissionRejectionReason.UnknownError]:
          'We were unable to process your request. Contact support for further assistance.',
        [EBetSubmissionRejectionReason.UnsupportedBetType]:
          'Unable to place this bet type.',
        [EBetSubmissionRejectionReason.NegativeStake]: 'Invalid Stake.',
        [EBetSubmissionRejectionReason.PlaceBetInvalidFieldSize]:
          'Betting is no longer available on your selection.',
        [EBetSubmissionRejectionReason.DuplicateRequest]:
          'Bet Rejected, duplicate identified.',
        [EBetSubmissionRejectionReason.BetslipRejection]:
          'We were unable to process your request. Contact support for further assistance.',
        [EBetSubmissionRejectionReason.AlreadyProcessedRequest]:
          'We were unable to process your request. Contact support if the issue persists.',
        [EBetSubmissionRejectionReason.SelectionExpansionRejection]:
          'We were unable to process your request. Contact support if the issue persists.',
        [EBetSubmissionRejectionReason.InsufficientLegsForMulti]:
          'Insufficient legs to place a Multi.',
        [EBetSubmissionRejectionReason.ExceededMaximumLegsForMulti]:
          'A Multi Bet must not exceed 20 legs.',
        [EBetSubmissionRejectionReason.MultipleLegsInEventForMulti]:
          'Your selections cannot be combined into a Multi.',
        [EBetSubmissionRejectionReason.InvalidProposition]:
          'Betting is no longer available on your selection.',
        [EBetSubmissionRejectionReason.UnavailableBetType]:
          'Your bet has not been accepted.',
        [EBetSubmissionRejectionReason.MaxStakeExceeded]:
          'Your bet has exceeded the maximum stake limit of {max_stake_limit}',
        [EBetSubmissionRejectionReason.MultiNotAllowed]:
          'One of more of your selections are not allowed in a multi',
        [EBetSubmissionRejectionReason.InvalidPriceType]:
          'Betting is no longer available on your selection.',
        [EBetSubmissionRejectionReason.FailMinimumStake]:
          'Minimum bet size is $1',
        [EBetSubmissionRejectionReason.BonusNotAllowed]:
          'Bonus bets not accepted on this market',
        [EBetSubmissionRejectionReason.ExceededMaximumBetsForEvenShot]:
          'Sorry, you have already placed Even Shot bet on this race',
        [EBetSubmissionRejectionReason.EvenShotOddsChange]:
          'Sorry, this Even Shot proposition is no longer available. Please refresh or try a different race',
        [EBetSubmissionRejectionReason.InsufficentBonusFund]:
          'Sorry, your Bonus Balance is too low to place this bet',
        [EBetSubmissionRejectionReason.MysteryBetOddsChange]:
          'Sorry, this Mystery Bet proposition is no longer available. Please refresh or try a different race',
        [EBetSubmissionRejectionReason.MysteryBetRolloverOddsChange]:
          'Sorry, this Mystery Bet proposition is no longer available. Please refresh or try a different race',
        [EBetSubmissionRejectionReason.ToteMultiUnavailable]:
          'Betting is no longer available on your selection.',
      },
    },
    betSlipModal: {
      genericError: 'An unknown error occurred. Please try again',
      boxed: 'Boxed',
      types: {
        [EBetSlipBetSubmissionType.Single]: 'Singles',
        [EBetSlipBetSubmissionType.Exotics]: 'Exotics',
        [EBetSlipBetSubmissionType.EachWay]: 'Each Way',
        [EBetSlipBetSubmissionType.Multi]: 'Multis',
        [EBetSlipBetSubmissionType.ComboMulti]: 'Combo Multis',
      },
      exoticTypes: {
        [TBetSlipBetSubmissionExoticsType.FirstFour]: 'First 4',
        [TBetSlipBetSubmissionExoticsType.Quinella]: 'Quinella',
        [TBetSlipBetSubmissionExoticsType.Exacta]: 'Exacta',
        [TBetSlipBetSubmissionExoticsType.Trifecta]: 'Trifecta',
      },
      totalStake: 'Total stake',
      bet: 'Bet',
      confirmBets: 'Confirm Bets',
      placeBets: 'Place bet',
      placedBetsConfirmation: 'Your bets have been placed.',
      myBets: 'My Bets',
      updateConfirmBets: 'Update & Confirm',
      login: 'Login & Bet',
      editBets: 'Edit Bets',
      done: 'Done',
      clearBets: 'Clear',
      makeADeposit: 'Make a deposit',
      lowerStakeHeading: 'Your balance is too low',
      lowerStakeDescription: 'Lower your stake or deposit',
      emptyBetSlipHeading: 'Your bet slip is empty',
      emptyBetSlipSubheading: 'Have a punt and gamble responsibly!',
      emptyMultiBets:
        'Add more single bets to create a multi, or combine with a same game multi and increase your potential win',
    },
  },
  Home: {
    PageTitle: '| Home',
    NextToJumpHeading: 'Next to Jump',
    UpcomingSportsHeading: 'Upcoming Sports',
    HorsesHeading: 'Thoroughbreds',
    GreyhoundsHeading: 'Greyhounds',
    HarnessHeading: 'Harness',
    viewRaceCard: 'View Race Card',
  },
  Maintenance: {
    ContentHeaderBetGalaxy: 'Punter, we have a problem',
    ContentHeaderWellBet: 'Give us a minute!',
    OpeningTagline: 'We are currently having some technical issues at ',
    ClosingTaglineBetGalaxy:
      '. Ground control are looking into the issue as a high priority. We apologise and thank you for your patience.',
    ClosingTaglineWellBet:
      '. Our technical teams are looking for a solution as a high priority. We apologise and thank you for your patience.',
    OurOffices: 'Our Offices',
    Investigation:
      'We are currently investigating the issue. Please try again soon.',
  },
  Account: {
    PersonalDetails: {
      PageTitle: '| Personal Details',
      Header: 'Personal Details',
      Button: 'Save Changes',
      FirstNameInputLabel: 'First Name',
      FirstNameErrorText: 'First Name is required',
      LastNameInputLabel: 'Last Name',
      LastNameErrorText: 'Last Name is required',
      EmailInputLabel: 'Email',
      MobileInputLabel: 'Mobile',
      MobilePlaceholder: 'eg. 0412345678',
      MobileRequiredErrorText: 'Mobile is required',
      MobileNumberOnlyError: 'Mobile field can only contain numbers',
      AddressInputLabel: 'Residential Address',
      AddressInputPlaceholder: '* Address must match your ID',
      AddressInputManualPlaceholder: 'Manual Entry',
      AddressRequiredErrorText: 'Residential Address is required',
      AddressInputManualEntry: "Can't see your address? Enter it manually",
      DobInputLabel: 'Date of Birth',
      DobInputError:
        'Sorry, you need to be over 18 years of age to use this platform',
      DobRequiredErrorText: 'Date of Birth is required',
      StreetNumberInputLabel: 'Street number',
      StreetNumberRequiredError: 'Street number is required',
      StreetNameInputLabel: 'Street name',
      StreetNameRequiredError: 'Street name is required',
      SuburbInputLabel: 'Suburb',
      SuburbRequiredError: 'Suburb is required',
      StateInputLabel: 'State',
      StateRequiredError: 'State is required',
      PostcodeInputLabel: 'Postcode',
      PostcodeRequiredError: 'Postcode is required',
      PostcodeLengthError: 'Postcode must be 4 digits',
      SubmitSuccess: 'Personal details successfully updated.',
      SubmitError: 'There was an error updating your personal details',
    },
    UpdateEmail: {
      PageTitle: '| Update Email',
      Header: 'Update your email',
      EmailRequired: 'Your email address is required',
      SuccessMessage: 'Updated email successfully.',
      FailMessage: 'There was an issue updating your email',
    },
    VerifyIdentity: {
      PageTitle: '| Verify Identity',
      Header: 'Verify Your Identity',
      Failed: 'Unable to verify account',
      StatusInProgress: 'Your identity verification is in progress.',
      StatusPending: 'Your identity verification is pending manual review.',
      StatusComplete: 'Your identity is verified.',
      StatusFailed: 'Unable to verify your identity. Please',
      StatusFailedLink: 'contact support',
    },
    Overview: {
      MyAccount: 'My Account',
      PageTitle: '| Account Overview',
      VerificationStatus: 'Verification Status',
      GetVerified: 'Get Verified',
      AccountBalance: 'Account Balance: ',
      AccountNumber: 'Account Number: ',
      Email: 'Email:',
      Deposit: 'Deposit',
      WithdrawableBalance: 'Withdrawable Balance: ',
      Withdraw: 'Withdraw',
      BonusBalance: 'Bonus Balance: ',
      MustVerify: 'You must verify your account in order to make a withdrawal',
      Bonus: 'Bonus',
      Account: 'Account',
      Verification: {
        Verified: 'Verified',
        Pending: 'Pending',
        Unverified: 'Unverified',
      },
    },
    MyBets: {
      PageTitle: '| My Bets',
      PendingView: 'Pending',
      ResultedView: 'Resulted',
      NoBetsText: 'You have no % bets',
      pending: 'pending',
      resulted: 'resulted',
      Loading: 'Loading...',
      ShowMore: 'Show more',
      NoBets: 'You have no more bets to display',
    },
    MyBetsCard: {
      BetIdText: 'Bet ID:',
      PotentialReturns: 'Est. Return',
      RaceAbbv: 'R',
      NoReturn: 'No Return',
      Won: 'Won',
      Lost: 'Lost',
      Stake: 'Stake',
      BetPlaced: 'Bet Placed On:',
      More: 'More',
      Less: 'Less',
      LegMulti: 'Leg Multi',
      Legs: 'Legs',
      BonusApplied: 'Bonus Applied',
      Badges: {
        BonusBet: 'Bonus Bet',
        Deductions: 'Deductions applied',
      },
    },
    SignedOut: {
      Title: 'The user is signed out',
    },
    CloseAccount: {
      title: 'Account Closure',
      clickHere: 'click here.',
      text1: 'You may close your account from this screen.',
      text2:
        'If you only wish to change your marketing preferences (including unsubscribing) please ',
      text3:
        'If you wish to close your account because you no longer gamble safely, please ',
      confirmOpenAccounts:
        'You agree not to open any further accounts while your account is closed. You may re-open your account at any time by contacting our Customer Service team at ',
      confirmPendingBets:
        'Any pending bets will stand and any pending withdrawals will be actioned.',
      confirmPendingBalance:
        'Any balance that remains in your account can be withdrawn by contacting our Customer Service team at ',
      confirmPassword: 'Confirm account closure with password',
      closeButton: 'Close account',
      errorMessage: 'Please try again',
      validationErrorMessage: 'Please complete the field to continue',
      passwordErrorMessage: 'Please check your password and try again.',
    },
    MakeAClaim: {
      title: 'Make a Claim',
      explanation:
        'Our claims process is designed to encourage the fast and efficient resolution of your issue at the first point of contact. While we will always aim to provide you with awesome customer service, we recognise that you may wish to express dissatisfaction with our products, services, staff or procedures.\n' +
        'While managing your complaint, we will aim to tailor any proposed resolutions to provide a fair and reasonable outcome to all parties involved. Once accepted, we will aim to deliver our mutually agreed resolution to you within 48 hours.\n' +
        'If your enquiry is related to feedback or staff compliments, you can contact us on our Customer Service line 1800 456 789.\n' +
        'If you are looking for a missing Bonus Bet, please contact us on our Customer Service line 1800 456 789.',
      contactUs: 'How to contact us:',
      contactUsDesc: 'Email us at: ',
      resolveClaims: 'How we resolve claims?',
      resolveClaimsDesc:
        'By email: We will respond to your complaint within 48 hours and provide you with an indication of how long it will take to resolve.',
      howWeResolve: 'How we resolve claims?',
      howWeResolveDesc:
        '- Your first and last name\n' +
        '- Account identification number\n' +
        '- Is this the first time you’re contact {themeName}? Y/N\n' +
        '- Complaint description (please include a bet receipt, operator name or market [if applicable])\n' +
        '- Preferred resolution\n' +
        '\n' +
        'This will allow us to investigate and deliver a response to you in a timely manner.',
    },
    LifetimeExclusion: {
      continueButton: 'Continue',
      pageTitle: 'Lifetime Exclusion',
      information:
        'If you are considering limiting your betting, you have the option to take a ',
      informationShortBreak: 'break from 1 day ',
      informationText2: 'up to ',
      informationLongBreak: '6 months.',
      informationText3:
        'If you have decided to not bet for a longer term, we provide the option for a lifetime exclusion. A lifetime exlusion is permanent and cannot be undone. All of your {themeName} accounts will be closed permanently. You will also not be able to set up a new account in the future. Your exclusion will start immediately upon confirmation.',
      informationText4:
        'By continuing, you will no longer be able to bet with {themeName} or have an active account with {themeName}.',
      importantDetailsTitle: 'Important Details',
      importantDetailsBalanceTitle: '{themeName} balance',
      importantDetailsBalanceDesc:
        'Any funds you have in your {themeName} account will be automatically transferred to your last active withdrawal method. If you have never withdrawn before or wish to withdraw these funds to an alternative account, please contact Customer Service on 1800 888 888.',
      importantDetailsAlertsTitle: 'Emails and alerts',
      importantDetailsAlertsDesc:
        'You will no longer receive emails and other alerts from {themeName}. These will cease within 24 hours of commencement of your break confirmation.',
      confirmLifetimeExclusion: 'Confirm Lifetime Exclusion',
      confirm: 'By pressing on “Continue”, I understand that:',
      confirmStepFunds:
        'All funds in my {themeName} account will be transferred to my last payment method',
      confirmStepImmediately: 'The exclusion will take effect immediately',
      confirmStepUndone:
        'The exclusion is permanent and all of my accounts with {themeName} will be closed for life',
      errorToast: 'Please try again',
      validationErrorMessage: 'Please complete the field to continue',
    },
    TakeABreak: {
      pageTitle: 'Take A Break',
      information:
        'You can choose to take a break from 1 day to 6 months and give yourself some time away from betting. Your break will start immediately once confirmed.',
      importantDetailsTitle: 'Important Details',
      importantDetailsEmailTitle: 'Emails and alerts',
      importantDetailsEmailDesc:
        'You will no longer receive emails and other alerts from {themeName}. These will cease within 24 hours of commencement of your break confirmation.',
      importantDetailsLoginTitle: 'Login',
      importantDetailsLoginDesc:
        'You will be able to log back in to your account as soon as your break has finished.',
      continueButton: 'Continue',
      confirmButton: 'Confirm break',
      breakStartDate: 'Break start date:',
      limitPeriod: 'Limit period',
      confirm: 'By pressing on “Confirm Break”, I understand that:',
      confirmStepPendingBets:
        'All funds in my {themeName} account will be transferred to my last payment method',
      confirmStepImmediately: 'My break will take effect immediately',
      confirmStepUndone: 'The break cannot be undone',
      today: 'Today',
      dayPeriod: 'In X day',
      dayPeriodPlural: 'In X days',
      errorToast: 'Please try again',
      validationErrorMessage: 'Please complete the field to continue',
      period24: '24 hrs',
      period48: '48 hrs',
      period72: '72 hrs',
      periodWeek: '1 week',
      periodFortnight: '2 weeks',
      period1Month: '1 month',
      period3Months: '3 months',
      period6Months: '6 months',
      periodFormat: '(in X)',
    },
    Settings: {
      pageTitle: 'Settings',
      accountVerification: 'Account Verification',
      promotionsNotifications: 'Marketing Preferences',
      personalDetails: 'Personal Details',
      updateEmail: 'Update Email',
      responsible: 'Responsible Gambling',
      terms: 'Terms & Conditions',
      privacy: 'Privacy Policy',
      makeAClaim: 'Make a Claim',
    },
    PromotionsNotifications: {
      pageTitle: 'Marketing Preferences',
      notificationsEnabled: 'Yes, I would like to receive promotional offers',
      via: 'via',
      email: 'Email',
      sms: 'SMS',
      phone: 'Phone',
      post: 'Post',
      SubmitSuccess: 'Marketing Preferences successfully updated!',
      SubmitError: 'Error updating marketing preferences...',
    },
    Transactions: {
      pageTitle: 'Transactions',
      downloadAction: 'Download Spreadsheet',
      noTransactions: 'You have no more transactions to display',
      loading: 'Loading',
      showMore: 'Show More',
      downloadErrorMessage:
        "Something went wrong, couldn't download transactions...",
      tableHeadings: ['Date', 'Type', 'Details', 'Amount', 'Balance'],
    },
    BonusBets: {
      pageTitle: 'Bonus Bets',
      downloadAction: 'Download Bonus Bets Transaction History',
      noBonusBets: 'You have no more Bonus Bets to display',
      downloadErrorMessage:
        "Something went wrong, couldn't download bonus bets transactions...",
    },
    Promos: {
      pageTitle: 'Promos',
    },
    Terms: {
      pageTitle: 'Terms & Conditions',
      otherLinks: {
        privacy: 'Privacy Policy',
        responsibleGambling: 'Responsible Gambling',
        depositLimits: 'Deposit Limits',
      },
    },
    Privacy: {
      pageTitle: 'Privacy Policy',
      otherLinks: {
        terms: 'Terms & Conditions',
        responsibleGambling: 'Responsible Gambling',
        depositLimits: 'Deposit Limits',
      },
    },
    DepositLimit: {
      depositLimit: 'Deposit Limit',
      SetLimit: {
        heading: 'Set a Deposit Limit',
        subheading:
          'A Deposit Limit allows you to limit the amount of money that you are able to deposit into your account within a chosen time frame.  Setting a Deposit Limit helps you gamble responsibly and stay in control of the amount you spend.',
        subheading2:
          'If you wish to change your existing Deposit Limit, you can do so at any time.  Increasing your limit does require a 7 day cooling-off period before it will take effect.  Lowering your limit, however will come into effect immediately.',
        inputPlaceHolder: 'Enter a deposit limit amount',
        depositLimitLabel: 'Limit amount',
        limitPeriodLabel: 'Limit period',
        formSubText: 'You can deposit ',
        formSubTextBold: 'up to % every & days',
        setLimitButtonText: 'Set Deposit Limit',
        limitUpdated: 'Your deposit limit has been successfully updated.',
        error:
          'There was an error updating your deposit limit. Please try again.',
      },
      CurrentLimit: {
        heading: 'Deposit Limit Set',
        depositLimitLabel: 'Deposit limit',
        limitPeriodLabel: 'Limit period',
        limitPeriodUntil: 'The limit period cannot be changed until ',
        remainingMaxLimitLabel: 'Remaining max limit',
        increaseLimitAfter: 'Limit expiry',
        coolingOffEnds: 'Cooling off term ends',
        days: '% day(s)',
        dollars: '$',
        updateLimitHeading: 'Update Deposit Limit',
        canDecreaseSubheading:
          'You can decrease your deposit limit within the 7 day restriction period.',
        newLimitLabel: 'New deposit limit',
        saveLimitButtonText: 'Set new deposit limit',
        coolingOffStatement: 'Your limit will increase to % on the &',
      },
      UpdateConfirmModal: {
        title: 'Update Deposit Limit',
        subheading1Increasing:
          'Your update will take effect after the 7 day cooling-off period. You will then be able to deposit up to $% every & days.',
        subheading1Decreasing:
          'You will be able to deposit up to $% every & days. This will come into effect immediately.',
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      limitPeriodOptions: ['7 Days', '14 Days', '28 Days'],
      increaseUpdateConfirmed:
        'Deposit Limit updated. This change will come into effect in 7 days.',
      decreaseUpdateConfirmed:
        'Deposit Limit updated. This change will come into effect immediately.',
      errorStrings: {
        requiredAmount: 'Deposit limit amount is required',
        cannotIncrease: 'Deposit limit cannot be increased until %',
        requiredPeriod: 'Deposit limit period is required',
        numbersOnly: 'Deposit limit can only contain numbers',
        sameValue: 'Amount entered is the same as your current deposit limit',
      },
    },
    Withdraw: {
      pageTitle: 'Withdraw',
      withdrawFundsTitle: 'Withdraw Funds',
      addbank: 'Add new',
      bankCard: {
        acc: 'ACC',
        bsb: 'BSB',
        hiddenBsb: '*** ***',
        hiddenAccNumber: '*****',
      },
      withdrawalAmountLabel: 'Amount',
      withdrawalBalanceLabel: 'Available Balance: ',
      requestWithdrawalButton: 'Withdraw',
      withdrawalSuccess: 'Withdrawal request successful',
      withdrawSubFooter1:
        'Withdrawals may take up to 1-3 days to reach your account',
      withdrawSubFooter2: 'Only turned over funds can be withdrawn.',
      withdrawalCancelled: 'Request successfully cancelled',
      errorStrings: {
        amountRequired: 'Withdraw amount is required',
        bankRequired: 'Bank account is required',
        minimumAmount: 'Withdraw amount must be more than $10',
        amountExceeds:
          'Your withdrawal request exceeds your current withdrawable balance of ',
        exceededWithDrawableAmount:
          'Your withdrawal request exceeds your current withdrawable balance. Please update your request or contact support for further assistance.',
        cannotWithdrawError:
          'You cannot withdraw funds until they’ve been turned over',
        integer: 'Withdraw amount can only contain numbers',
      },
      AddAccount: {
        heading: 'Add Bank Account',
        holderNameLabel: 'Account Holder Name',
        makeAWithdrawal: 'Make a withdrawal',
        withdrawalDescription:
          'Requests may take up to 1-3 days to reach your account. Only turned over funds can be withdrawn.',
        holderNameSubFooter:
          "Bank account must be in registered account holder's name",
        bankNameLabel: 'Bank Name',
        bsbLabel: 'BSB Number',
        accNumberLabel: 'Account Number',
        nicknameLabel: 'Nickname (Optional)',
        nicknameSubFooter:
          'Add a nickname to help distinguish this bank account from others',
        addAccountButton: 'Add New Account',
        addAccountSubFooter:
          'Please note: Funds can only be withdrawn to Australian bank accounts. If you require assistance, ',
        contactUs: 'contact us.',
        errorStrings: {
          nameRequired: 'Account Holders name is required',
          bankNameRequired: 'Bank name is required',
          bsbRequired: 'BSB is required',
          bsbFormat: 'Must be a valid Australian BSB Number',
          accountNumberRequired: 'Account Number is required',
          accountNumberFormat: 'Must be a valid Australian Account Number',
          genericError: 'There was an error adding this bank account',
          AccountNumberNoSpaces: 'Account Number cannot contain spaces',
          BsbNumberNoSpaces: 'BSB Number cannot contain spaces',
          accountNameLength: 'Account Holder Name too long',
        },
      },
      Requests: {
        heading: 'Requests',
        cancelButton: 'Cancel',
        status: {
          withdraw: 'WITHDRAW',
          dash: ' - ',
          processing: 'Processing',
          unsuccessful: 'Unsuccessful',
        },
        requestUnsuccessful:
          'Request unsuccessful please contact customer support.',
        remove: 'Remove',
      },
      IncreaseAmountButton: {
        ten: '+$10',
        twentyFive: '+$25',
        fifty: '+$50',
        hundred: '+$100',
        twoHundred: '+$200',
        fiveHundred: '+$500',
      },
    },
    Deposit: {
      Titles: {
        Main: 'Make a deposit',
      },
      Labels: {
        CreditDeposit: 'Credit / Debit Card Deposit',
        SelectCard: 'Select a Card',
        Amount: 'Amount',
        SecuredWithSSL: 'Secured with SSL encryption technology.',
        minimumDepositAmount: 'The minimum deposit amount is $10',
      },
      Card: {
        Expired: 'Expired',
        SaveCard: 'Save card details',
      },
      Buttons: {
        Deposit: 'Deposit now',
        AddNewCard: 'Add New Card',
        AddCardAndDeposit: 'Add Card and deposit',
        MaxCards: 'You may only have up to * cards',
      },
      Validation: {
        MinAmount: 'Please enter a deposit amount of at least $10',
        MaxAmount: 'Your deposit limit has been reached.',
      },
      Confirmation: {
        Done: 'Done',
        PleaseTryAgain: 'Please, Try Again',
        Success: 'Success!',
        Complete: 'Deposit Complete',
        CompleteMessage: 'Deposit of % was successfully added to your account.',
        Failed: 'Deposit Failed',
        FailedMessage: 'Your deposit of % was not successful.',
      },
      Toasts: {
        PaymentSuccessful: 'Successfully deposited your funds',
        PaymentUnsuccessful:
          'Payment was unsuccessful, you have not been charged',
        CardRemoveSuccessful: 'Card successfully deleted',
        UnableToProcessCard:
          'We were unable to process your card, please try again',
        UnableToDepositFunds: 'We were unable deposit your funds',
        SomethingWentWrong: 'Something went wrong, please try again',
        UnableToGetCards: 'Unable to get your saved cards',
        UnableToRemoveCard: 'Unable to remove your saved card',
        UnableToAddCard: 'Unable to add a new card',
        FZErrors: {
          '002': 'Unable to verify 3DS enrolment status with you issuer.',
          '003': '3DS authentication is unavailable with your issuer.',
          '004': 'Your issuer deems this transaction as risky.',
          '005': 'Your frictionless authentication has been rejected.',
          '006':
            'Unsuccessful authentication due to failed OTP (one time password) challenge.',
          '007':
            'You are enrolled for 3DS, but authentication is not available with your issuer.',
          '01': 'Contact your card issuer for more information',
          '02': 'Contact your card issuer for more information',
          '03': 'Check your merchant details provided',
          '04': 'Contact your card issuer for more information',
          '05': 'Contact your card issuer for more information',
          '06': 'Contact your card issuer for more information',
          '07': 'Contact your card issuer for more information',
          '12': 'Contact your card issuer for more information',
          '13': 'Invalid amount, contact your card issuer for more information',
          '14': 'Invalid card number, contact your card issuer for more information',
          '15': 'Contact your card issuer for more information',
          '19': 'Payment failed, please try again',
          '22': 'Payment failed, please try again',
          '23': 'Contact your card issuer for more information',
          '25': 'Contact your card issuer for more information',
          '30': 'Contact your card issuer for more information',
          '31': 'Your bank is not supported, contact your card issuer for more information',
          '33': 'Card has expired, please use a different card',
          '34': 'Card has expired, please use a different card',
          '35': 'Contact your card issuer for more information',
          '36': 'Contact your card issuer for more information',
          '37': 'Contact your card issuer for more information',
          '38': 'Please use another card',
          '39': 'Contact your card issuer for more information',
          '41': 'Contact your card issuer for more information',
          '42': 'Contact your card issuer for more information',
          '43': 'Contact your card issuer for more information',
          '44': 'Contact your card issuer for more information',
          '51': 'Insufficient funds',
          '52': 'Contact your card issuer for more information',
          '53': 'Contact your card issuer for more information',
          '54': 'Expired card, please use a different card',
          '55': 'Payment failed, please try again',
          '56': 'Contact your card issuer for more information',
          '57': 'Contact your card issuer for more information',
          '59': 'Contact your card issuer for more information',
          '60': 'Contact your card issuer for more information',
          '61': 'Please try another card',
          '62': 'Contact your card issuer for more information',
          '75': 'Please use another card',
          '82': 'Payment failed, please try again',
          '90': 'Payment failed, please try again',
          '91': 'Payment failed, please try again',
          '92': 'Payment failed, please try again',
          '93': 'Contact your card issuer for more information',
          '94': 'Contact your card issuer for more information',
          '96': 'Payment failed, please try again',
          '99': 'Payment failed, please try again',
        },
      },
      IncreaseAmountButton: {
        ten: '+10',
        fifty: '+50',
        hundred: '+100',
        fiveHundred: '+500',
        thousand: '+1000',
      },
    },
  },
  Sports: {
    ErrorMessages: {
      getCompetitionsFailMessage: "Couldn't load competitions for this sport",
      getMarketsFailMessage: "Couldn't load markets for this match",
      getMatchesFailMessage: "Couldn't load matches for this competition",
    },
    AllSports: {
      AZHeading: 'All Sports A-Z',
      TopSports: 'Top Sports',
      SportsListTitle: 'Sports A-Z',
    },
    CompetitionsBySport: {
      FeaturedCompetitionsTitle: 'Featured',
      AllCompetitionsTitle: 'All',
    },
    MarketsByMatch: {
      pageTitleFallback: 'Markets',
      noMarkets:
        'There are no markets for this event. Please check % for other active markets.',
      noMarketsButton: 'Go to',
      okayButtonText: 'Okay',
      matchClosed: 'Match % is now closed',
    },
    MatchCard: {
      versus: 'VS',
    },
    Propositions: {
      seeMore: 'See more',
      seeLess: 'See less',
    },
  },
  Racing: {
    ErrorMessages: {
      getRacingVenueListFailMessage: "Couldn't load venues",
      getRaceMetaFailMessage: "Couldn't load race meta data",
      getRaceRunnerListFailMessage: "Couldn't load race runner list",
      getOtherRacesByVenueFailMessage:
        "Couldn't load other races for this venue",
      getRaceResultsFailMessage: "Couldn't load race results",
      getRaceMarketsFailMessage: "Couldn't load race markets",
    },
    Exotics: {
      Exotics: 'Exotics',
      RaceResults: 'Race Results',
      addToBetSlip: 'Add to Bet Slip',
      flexi: 'Flexi',
      totalStake: 'Total Stake',
      betType: 'Bet Type',
      dividend: 'Dividend',
      dividends: 'Dividends',
      boxed: 'Boxed',
      clearSelection: 'Clear Selection',
      combos: 'Combos',
      selectField: 'Select Field',
    },
    RaceDetails: {
      pageTitle: '| Race Details',
      runnerHeading: 'Runners',
      fixedHeading: 'Fixed',
      winHeading: 'Win',
      placeHeading: 'Place',
      raceStatusPrefix: 'Race',
      resultedStatus: 'Resulted',
      raceCardHeading: 'Race Card',
      marketTabs: {
        winPlace: 'Win/Place',
        raceResults: 'Win/Place',
      },
      resultsTabs: {
        results: 'Results',
      },
      eventRules: {
        ruleHeading: 'Event Rules: ',
        noPlace: 'No place betting available on this race',
        twoPlaceDividends: 'Only two place dividends paid',
        threePlaceDividends: 'Three place dividends paid',
      },
    },
    RaceResults: {
      RaceResults: 'Runners',
      win: 'Win',
      place: 'Place',
      positions: ['1st', '2nd', '3rd', '4th'],
    },
    RaceRunnerItem: {
      scratchedNoCaps: 'Scratched',
      scratched: 'SCRATCHED',
      suspended: 'SUS',
      deductions: 'Deductions applied',
    },
    RaceMeetings: {
      pageTitle: '| Race Meetings',
      Horses: 'Horses',
      Greys: 'Greys',
      Harness: 'Harness',
      NoRaces: 'No Races',
      Abandoned: 'ABND',
      Closed: 'CLSD',
    },
  },
  ContactUs: {
    Title: 'Contact Us',
    Support: 'Support - ',
    PhoneLocal: '(Local)',
    Chat: 'Live Chat',
    PhoneInternational: '(International)',
  },
  Legal: {
    Responsible: {
      heading: 'Responsible Gambling',
      subHeading:
        'We are committed to protecting and helping our customers stay in control of their gambling. We offer various support options that suit different needs.',
      titles: {
        findOutMore: 'Find out more',
        getInTouch: 'Get in touch',
        takeAction: 'Take Action',
      },
      section: {
        findOutMore: {
          one: 'Chat with a professional from Gambler’s Help',
          two: 'Unsure about your gambling habits?',
          three:
            ' Have a chat over the phone, face-to-face or online with the friendly staff at Gambler’s Help. It’s free, confidential, professional and available 24/7.',
        },
        takeAction: {
          depositLimit: {
            one: 'Set a deposit limit',
            two: 'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
            button: 'Set a deposit limit',
          },
          longTerm: {
            one: 'Long Term Exclusion',
            two: 'Stop betting by suspending your account for over 6 months or any other period up to a maximum of 5 years.',
            button: 'Long Term Exclusion',
          },
          deactivate: {
            one: 'Close my account',
            two: 'You can choose to close your account by deactivating it.',
            button: 'Close Account',
          },
          lifeExclusion: {
            one: 'Lifetime Exclusion',
            two: `A lifetime exclusion is permanent and cannot be undone. All of your accounts with ${getThemeName()} will be closed permanently. You will not be able to set up a new account in the future.`,
            button: 'Lifetime Exclusion',
          },
          break: {
            one: 'Take a Break',
            two: 'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
            button: 'Take a Break',
          },
        },
      },
    },
  },
  Footer: {
    heading: '18+',
    subheading: 'Must be ',
    enquiries: 'For any enquiries email us at',
    support: 'Support - ',
    bodyMain2PhoneNumber: '1800 262 376',
    footerTopText1: `${getThemeName()}  gambling operations are governed by our Responsible Gambling Code of Conduct. Think! About your choices. Call Gambler’s Help or Gambling Help on 1800 858 858 or visit`,
    footertopText2:
      'Call Gambler’s Help Youthline on 1800 262 376. Gamble Responsibly.',
    footerBottomText:
      'In relation to South Australian residents, our gambling operations are also governed by the South Australian Responsible Gambling Code of Practice. Think of the people who need your support. Gamble Responsibly.',

    newFooterText1: `${getThemeName()} are committed to the support of responsible gambling. All bets are accepted by ${getThemeName()} who are licensed in Victoria by the Victorian Commission for Gambling and Liquor.`,
    newFooterText3:
      'Think of the people who need your support. Gamble Responsibly.',
    newFooterText4:
      'Please gamble responsibly and if you need help call Gambling Help services on',
    ourPartners: 'Our partners',
    weAccept: 'We accept',
    quickLinks: 'Quick links',
    bodyLink1: 'https://www.gamblershelp.com.au',
    bodyLink2: 'https://www.gamblinghelponline.org.au',
    bodySecondary: 'Licensed and regulated by Racing Victoria.',
    copyright: 'Copyright © {themeName} Pty Ltd | v',
  },
  SideNav: {
    home: 'Home',
    allSports: 'All Sports',
    myAccount: 'My Account',
    contactUs: 'Contact Us',
    racing: 'Racing',
    sportAZ: 'Sports',
    quickLinks: 'QUICK LINKS',
    nextToJump: 'Next To Jump',
    viewAll: 'View All',
    sports: 'Sports',
    deposit: 'Deposit Funds',
    promotions: 'Promos',
    withdraw: 'Withdraw Funds',
    depositLimits: 'Deposit Limits',
    responsible: 'Responsible Gambling Tools',
    logout: 'Logout',
    login: 'Login',
    join: 'Join',
    Support: 'Support - ',
  },
};

export default STRINGS;
