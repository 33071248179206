import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { spacing: '1' },
  link: {
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'base',
    border: '2px',
    pos: 'relative',
    h: ['8', null, null, '6'],
    px: '2.5',
    fontSize: '2xs',
    borderColor: 'beta.400',
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
    color: 'white',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'body',
    lineHeight: 'normal',
    sx: {
      '&.active': {
        bg: 'alpha.700',
        color: 'beta.400',
        textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
        border: 'unset',
      },
      svg: {
        color: 'gamma.500',
      },
    },
    _hover: {
      borderColor: 'beta.400',
      bg: 'alpha.700',
      color: 'alpha.600',
    },
  },
};
