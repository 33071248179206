import { QuestionSquareFill } from 'styled-icons/bootstrap';
import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryBet from '@/assets/betgalaxy/images/mysteryBet.png';

export const mysteryBetStyles: MysteryBetSchema = {
  buttonMyBets: {
    fontSize: ['xs', 'sm'],
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  textPriceInfo: {
    color: 'theta.50',
    fontWeight: 'bold',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    fontWeight: 'bold',
  },
  flexRaceInfo: {
    fontWeight: 'bold',
    color: 'white',
  },
  iconRace: {
    color: 'beta.100',
    boxSize: 6,
  },
  textRollover: {
    color: 'theta.50',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  iconMystery: {
    as: QuestionSquareFill,
  },
  flexWrapperMysteryLabelView: {
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
  },
  iconBack: {
    boxSize: 8,
    color: 'alpha.500',
  },
  flexWrapperMysteryRolloverView: {
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    px: 3,
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
  },
  buttonRollOver: {
    h: '26px',
    _disabled: {
      bg: 'beta.700',
      color: 'theta.50',
      border: 'none',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
      px: 3,
      _hover: { bg: 'none' },
    },
  },
  buttonAddToBetslip: {
    px: [2, 2, 3],
    fontSize: ['2xs', 'xs'],
    bg: 'brand.500',
    bgGradient: 'none',
    color: 'brand.900',
    h: 8,
  },
  flexWrapperMysteryBetReviewView: {
    mx: [0, 4],
    gap: '2',
    my: '2',
    bg: 'beta.500',
    borderRadius: 'lg',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    p: '2',
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'md',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    sx: {
      '&&': {
        px: [2, 3],
      },
    },
    lineHeight: '15px',
    maxH: 8,
    fontSize: ['2xs', 'xs'],
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'theta.50',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: 'md',
    boxSize: 8,
    p: '2',
    color: 'white',
    cursor: 'pointer',
  },
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 2,
  },
  buttonOdds: {
    bgGradient: 'linear(180deg, #F1F1F1 0%, #FDFDFD 100%)',
    color: 'black',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.70) inset',
  },
  flexWrapperMysteryBetSelectionView: {
    mx: [0, 4],
    gap: '2',
    my: '2',
    bg: 'beta.500',
    borderRadius: 'lg',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    p: '2',
  },
  imageLogo: {
    src: `${mysteryBet}`,
    w: ['200px', '234px'],
    pos: 'relative',
    top: 0.5,
  },
};
