import {
  ButtonProps,
  chakra,
  CheckboxProps,
  Flex,
  FlexProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './MyBetsToggle.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button, CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type MyBetsToggleSchema = Partial<{
  textCashOut: TextProps;
  flexWrapper: FlexProps;
  checkbox: CheckboxProps;
}>;

export const myBetsToggleStyles: MyBetsToggleSchema = {
  flexWrapper: {
    'data-testid': 'myBetsToggle-flexWrapper',
    ml: 'auto',
    mr: '2',

    ...(styleImports?.[themeName]?.myBetsToggleStyles?.flexWrapper ?? {}),
  },
  checkbox: {
    'data-testid': 'myBetsToggle-checkbox',
    flexDir: 'row-reverse',
    gap: '2',
    alignItems: 'center',

    ...(styleImports?.[themeName]?.myBetsToggleStyles?.checkbox ?? {}),
  },
  textCashOut: {
    'data-testid': 'myBetsToggle-textCashOut',
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'semibold',

    ...(styleImports?.[themeName]?.myBetsToggleStyles?.textCashOut ?? {}),
  },
};

export const TabContainer = chakra(Flex, {
  label: 'MyBetsTabContainer',
  baseStyle: () => ({
    width: '100%',
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.300',

    ...(() => {
      try {
        return styleImports[themeName]?.TabContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TabWrapper = chakra(Flex, {
  label: 'MyBetsTabWrapper',
  baseStyle: () => ({
    justifyContent: 'flex-start',
    overflowX: 'auto',
    width: '100%',
    margin: '0 auto',

    ...(() => {
      try {
        return styleImports[themeName]?.TabWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TTabOption = {
  isActive: boolean;
} & ButtonProps &
  any;
export const TabOption = chakra<TTabOption>(Button, {
  label: 'MyBetsTabOption',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    transition: 'all .1s ease-in-out',
    whiteSpace: 'nowrap',
    bg: 'transparent',
    borderRadius: '0',

    '&:hover, &:active': {
      background: 'blackAlpha.50',
      color: 'blackAlpha.900',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.TabOption?.({
          isActive,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const buttonAccountTabOptionProps: CustomButtonProps = {
  'data-testid': 'myAccountToggle-tabBetOptionProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonAccountTabOptionProps;
    } catch (err) {
      return {};
    }
  })(),
};
