import * as goldenrush from './StepIndicator.styles.goldenrush';
import * as juicybet from './StepIndicator.styles.juicybet';
import * as sterlingparker from './StepIndicator.styles.sterlingparker';
import * as vikingbet from './StepIndicator.styles.vikingbet';
import * as templebet from './StepIndicator.styles.templebet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  goldenrush,
  juicybet,
  sterlingparker,
  vikingbet,
  templebet,
};
