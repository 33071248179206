import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';

import { AppDispatch } from '../../../redux/store';
import {
  setPrivacyPolicy,
  setPunterAccountOverview,
  setPunterProfile,
  setPunterTransactions,
  setTermsConditions,
  setMyBets,
  setDepositLimit,
  setBankAccounts,
  setWithdrawals,
} from './account.slices';

import { dollarsToCents, getStrings } from '@/helpers/utils';
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
} from '../../../lib/api/api';
import {
  signUserOut,
  updateUserEmail,
  verifyUsersPassword,
} from '../../../lib/firebase/Authentication';

import { TBankAccountFormData, TWithdrawFormData } from '../account.types';
import {
  TFullPunter,
  TVerificationModel,
  TPunterAccountOverview,
  TTransactionsDTO,
  TDepositLimit,
  TMarketingPreferences,
  TBank,
  TWithdrawal,
  TConfirmedBetDTO,
  TTransaction,
} from '../../../lib/DBModels';
import { AuthErrorCode, FirebaseAuthError } from '../../../lib/firebase/types';
import { PersonalDetailsSchema } from './schemas/personalDetailsSchema';
import { DepositLimitSchema } from './schemas/depositLimitSchema';
import { Nullable } from '../../../lib/HelperTypes';
import { DepositLimitData } from '../../onboarding/services/onboarding.types';
import { PromotionsNotificationsSchema } from './schemas/promotionsNotificationsSchema';
import { handlePunterStatus } from '../../../lib/punter/PunterStatus';
import { getUserStoreActions } from '@/store/AuthStore';
import { TPunterAccount } from '@/api/punter/punter.types';

const [
  {
    Account: {
      PersonalDetails,
      UpdateEmail,
      PromotionsNotifications,
      Transactions,
      Withdraw,
      VerifyIdentity,
      DepositLimit,
    },
    Onboarding: { FirebaseErrorMessages },
  },
] = getStrings();

export const updateUserDetails = createAsyncThunk<
  boolean,
  PersonalDetailsSchema,
  { dispatch: AppDispatch }
>('punter/updateDetails', async (updateForm, thunkApi) => {
  try {
    const updatedPunter = await apiPostRequest<
      TFullPunter,
      Omit<
        TFullPunter,
        | 'email'
        | 'email_marketing_enabled'
        | 'sms_marketing_enabled'
        | 'phone_marketing_enabled'
        | 'post_marketing_enabled'
      >
    >(
      '/punter/account',
      {
        first_name: updateForm.firstName,
        last_name: updateForm.lastName,
        mobile: updateForm.mobile,
        identity_verified: updateForm.identityVerified,
        is_onboarded: updateForm.isOnboarded,
        post_code: updateForm.postcode as string,
        state: updateForm.state as string,
        street_name: updateForm.streetName as string,
        street_number: updateForm.streetNumber as string,
        suburb: updateForm.suburb as string,
        date_of_birth: dayjs(updateForm.dateOfBirth).format('YYYY-MM-DD'),
      },
      {
        successMessage: PersonalDetails.SubmitSuccess,
        failMessage: PersonalDetails.SubmitError,
      }
    );

    thunkApi.dispatch(setPunterProfile(updatedPunter));

    return true;
  } catch (e) {
    return false;
  }
});

export const updateUserMarketingPreferences = createAsyncThunk<
  boolean,
  PromotionsNotificationsSchema,
  { dispatch: AppDispatch }
>('punter/updateUserMarketingPreferences', async (updateForm, thunkApi) => {
  try {
    const updatedPunter = await apiPostRequest<
      TFullPunter,
      TMarketingPreferences
    >(
      '/punter/account',
      {
        email_marketing_enabled:
          updateForm.notificationsEnabled === false ? false : updateForm.email,
        sms_marketing_enabled:
          updateForm.notificationsEnabled === false ? false : updateForm.sms,
        phone_marketing_enabled:
          updateForm.notificationsEnabled === false ? false : updateForm.phone,
        post_marketing_enabled:
          updateForm.notificationsEnabled === false ? false : updateForm.post,
      },
      {
        successMessage: PromotionsNotifications.SubmitSuccess,
        failMessage: PromotionsNotifications.SubmitError,
      }
    );

    thunkApi.dispatch(setPunterProfile(updatedPunter));

    return true;
  } catch (e) {
    return false;
  }
});

export const updateEmailAddress = createAsyncThunk<
  boolean,
  { newEmail: string; existingProfile: TFullPunter; existingPassword: string },
  { dispatch: AppDispatch }
>(
  'punter/updateEmail',
  async ({ newEmail, existingProfile, existingPassword }, thunkApi) => {
    try {
      const validCreds = await verifyUsersPassword(
        existingProfile?.email ?? '',
        existingPassword
      );

      if (!validCreds) {
        toast.error(FirebaseErrorMessages.WrongCredentials);

        return false;
      }

      // Firebase update needs to happen first, as emails have to be unique
      await updateUserEmail(newEmail);
    } catch (e) {
      const error = e as FirebaseAuthError;

      // Throw error if firebase fails
      switch (error.code) {
        default:
          toast.error(FirebaseErrorMessages.UnspecifiedError);
          break;
        case AuthErrorCode.EMAIL_EXISTS:
          toast.error(FirebaseErrorMessages.EmailInUse);
          break;
      }

      return false;
    }

    try {
      const updatedPunter = await apiPostRequest<TFullPunter, TFullPunter>(
        '/punter/account',
        {
          ...existingProfile,
          email: newEmail,
        },
        {
          successMessage: UpdateEmail.SuccessMessage,
          failMessage: UpdateEmail.FailMessage,
        }
      );

      thunkApi.dispatch(setPunterProfile(updatedPunter));
    } catch (e) {
      if (existingProfile?.email) {
        await updateUserEmail(existingProfile?.email); // Rollback email change if error
      }

      return false;
    }

    return true;
  }
);

export const getPunterVerificationKey = async (): Promise<TVerificationModel> =>
  apiGetRequest<TVerificationModel>('/punter/account/verify', {
    failMessage: VerifyIdentity.Failed,
  });

export const getPunterAccountOverview = createAsyncThunk<
  TPunterAccountOverview | null,
  undefined,
  { dispatch: AppDispatch }
>('punter/getPunterAccountOverview', async (_, thunkApi) => {
  try {
    const accountOverview = await apiGetRequest<TPunterAccountOverview>(
      '/punter/account/overview'
    );

    // This checks for the disabled & excluded punters.
    // Throws a error & toast if not normal
    handlePunterStatus(accountOverview);

    thunkApi.dispatch(setPunterAccountOverview(accountOverview));

    return accountOverview;
  } catch (e) {
    await signUserOut();

    return null;
  }
});

export const getMyBets = createAsyncThunk<TConfirmedBetDTO, undefined>(
  'punter/getMyBets',
  async (_, thunkApi) => {
    const myBetsData = await apiGetRequest<TConfirmedBetDTO>(
      '/punter/account/my-bets'
    );

    thunkApi.dispatch(setMyBets(myBetsData));

    return myBetsData;
  }
);

export const getPunterAccountTransactions = createAsyncThunk<
  TTransactionsDTO,
  { limit: number; offset?: number; isBonus?: boolean },
  { dispatch: AppDispatch }
>(
  'punter/getPunterAccountTransactions',
  async ({ limit, offset, isBonus }, thunkApi) => {
    const accountTransactions = await apiGetRequest<TTransactionsDTO>(
      `/punter/transactions?limit=${limit}${offset ? `&offset=${offset}` : ''}${
        isBonus ? `&is_bonus=${isBonus}` : ''
      }`
    );

    if (accountTransactions.length) {
      thunkApi.dispatch(
        setPunterTransactions(accountTransactions as TTransaction[])
      );
    }

    return accountTransactions;
  }
);

export const getPunterAccountTransactionsDownload = createAsyncThunk<
  string,
  undefined,
  { dispatch: AppDispatch }
>('punter/getPunterAccountTransactionsDownload', async () => {
  const accountTransactions = await apiGetRequest<string>(
    '/punter/transactions/download-all',
    { failMessage: Transactions.downloadErrorMessage }
  );
  return accountTransactions;
});

export const getTermsConditions = createAsyncThunk<
  { terms_of_service: string },
  undefined,
  { dispatch: AppDispatch }
>('punter/getTermsConditions', async (_, thunkApi) => {
  const response = await apiGetRequest<{ terms_of_service: string }>(
    '/punter/general/terms'
  );
  thunkApi.dispatch(setTermsConditions(response.terms_of_service));

  return response;
});

export const getPrivacyPolicy = createAsyncThunk<
  { privacy_policy: string },
  undefined,
  { dispatch: AppDispatch }
>('punter/getPrivacyPolicy', async (_, thunkApi) => {
  const response = await apiGetRequest<{ privacy_policy: string }>(
    '/punter/general/privacy'
  );
  thunkApi.dispatch(setPrivacyPolicy(response.privacy_policy));

  return response;
});

export const getDepositLimit = createAsyncThunk<
  TDepositLimit,
  undefined,
  { dispatch: AppDispatch }
>('punter/getDepositLimit', async (_, thunkApi) => {
  const response = await apiGetRequest<TDepositLimit>(
    '/punter/account/deposit-limit'
  );

  const { setDepositLimit: setDLimit } = getUserStoreActions();
  setDLimit(response);

  thunkApi.dispatch(setDepositLimit(response));

  return response;
});

export const postDepositLimit = createAsyncThunk<
  TFullPunter | void,
  DepositLimitSchema,
  { dispatch: AppDispatch }
>('punter/postDepositLimit', async (depositLimitForm, thunkAPI) => {
  const punter = await apiPostRequest<TFullPunter, Nullable<DepositLimitData>>(
    '/punter/account/deposit-limit',
    {
      amount: depositLimitForm.amount
        ? dollarsToCents(depositLimitForm.amount ?? 0)
        : null,
      frequency: depositLimitForm.frequency
        ? parseInt(depositLimitForm.frequency, 10)
        : null,
    },
    {
      successMessage: DepositLimit.SetLimit.limitUpdated,
      failMessage: DepositLimit.SetLimit.error,
    }
  );

  if (punter) {
    const { setPunterData } = getUserStoreActions();
    setPunterData(punter as TPunterAccount);
    thunkAPI.dispatch(
      setDepositLimit({
        deposit_limit_active: punter.deposit_limit_active,
        deposit_limit_current: punter.deposit_limit_current,
        deposit_limit_waiting: punter.deposit_limit_waiting,
        deposit_limit_frequency_current: punter.deposit_limit_frequency_current,
        deposit_limit_cooldown_ends: punter.deposit_limit_cooldown_ends,
        deposit_limit_last_updated: punter.deposit_limit_last_updated,
      })
    );
    getDepositLimit();
    return punter;
  }
});

export const postNewBankAccount = createAsyncThunk<
  TBank,
  TBankAccountFormData,
  { dispatch: AppDispatch }
>('punter/postNewBankAccount', async (newBankForm) => {
  const bankAccount = await apiPostRequest<TBank, TBankAccountFormData>(
    '/punter/bank',
    {
      account_name: newBankForm.account_name,
      account_nick_name: newBankForm.account_nick_name,
      account_number: newBankForm.account_number,
      bsb: newBankForm.bsb,
    },
    {
      failMessage: Withdraw.AddAccount.errorStrings.genericError,
    }
  );

  return bankAccount;
});

export const getBankAccounts = createAsyncThunk<
  TBank[],
  undefined,
  { dispatch: AppDispatch }
>('punter/getBankAccounts', async (_, thunkAPI) => {
  const bankAccounts = await apiGetRequest<TBank[]>('/punter/bank');

  thunkAPI.dispatch(setBankAccounts(bankAccounts));

  return bankAccounts;
});

export const postWithdrawRequest = createAsyncThunk<
  TWithdrawal,
  TWithdrawFormData,
  { dispatch: AppDispatch }
>('punter/postWithdrawRequest', async (newWithdrawalForm, thunkAPI) => {
  const withdrawal = await apiPostRequest<TWithdrawal, TWithdrawFormData>(
    '/punter/account/withdraw',
    {
      amount: dollarsToCents(newWithdrawalForm.amount || 0),
      bank_id: newWithdrawalForm.bank_id,
    },
    { failMessage: 'Error' },
    true
  );

  await thunkAPI.dispatch(getPunterAccountOverview());

  return withdrawal;
});

export const deleteBank = createAsyncThunk<
  string,
  { id: string },
  { dispatch: AppDispatch }
>('punter/deleteWithdrawRequest', async (deleteBankData) => {
  await apiDeleteRequest<string, { id: string }>(
    '/punter/bank',
    {
      id: deleteBankData.id,
    },
    { failMessage: 'Error' }
  );

  return deleteBankData.id;
});

export const viewWithdrawalRequests = createAsyncThunk<
  TWithdrawal[],
  undefined,
  { dispatch: AppDispatch }
>('punter/viewWithdrawalRequests', async (_, thunkAPI) => {
  const requests = await apiGetRequest<TWithdrawal[]>(
    '/punter/account/requested-withdrawals'
  );

  thunkAPI.dispatch(setWithdrawals(requests));

  return requests;
});

export const deleteWithdrawRequest = createAsyncThunk<
  string,
  { request_id: string },
  { dispatch: AppDispatch }
>('punter/deleteWithdrawRequest', async (deleteRequestData, thunkAPI) => {
  await apiDeleteRequest<string, { request_id: string }>(
    `/punter/account/withdrawal/${deleteRequestData.request_id}`,
    { request_id: deleteRequestData.request_id }
  );

  await thunkAPI.dispatch(getPunterAccountOverview());

  return deleteRequestData.request_id;
});
