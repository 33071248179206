import {
  Button,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  Grid,
} from '@chakra-ui/react';
import { styleImports } from './Keypad.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type KeypadSchema = Partial<{
  buttonActionProps: CustomButtonProps;
}>;

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    'data-testid': 'keypad-buttonActionProps',

    variant: 'unstyled',
    _first: {
      mr: 1,
    },
    _last: {
      ml: 'auto',
    },
    svg: { boxSize: '2.5' },

    sx: {
      '&&': {
        px: 2,
        py: 0.5,
        fontSize: 'xs',
        fontWeight: 'semibold',
        textTransform: 'uppercase',
      },
      '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    },

    ...(styleImports?.[themeName]?.keypadStyles?.buttonActionProps ?? {}),
  },
};

export const buttonKeysProps: CustomButtonProps = {
  'data-testid': 'keypad-buttonKeysProps',

  ...(styleImports?.[themeName]?.buttonKeysProps ?? {}),
};

export const buttonDoneKeyProps: CustomButtonProps = {
  'data-testid': 'keypad-buttonDoneKeyProps',

  ...(styleImports?.[themeName]?.buttonDoneKeyProps ?? {}),
};

export const buttonBatchKeysProps: CustomButtonProps = {
  'data-testid': 'keypad-buttonBatchKeysProps',

  ...(styleImports?.[themeName]?.buttonBatchKeysProps ?? {}),
};

export const FlexKeypadWrapper = chakra(Flex, {
  label: 'keypad-FlexKeypadWrapper',
  baseStyle: () => ({
    flexDir: 'column',
    maxH: '240px',
    height: '100%',
    bg: 'beta.700',
    position: 'fixed',
    bottom: 0,
    w: 'full',
    zIndex: 'popover',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexKeypadWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexTopBtnWrapper = chakra(Flex, {
  label: 'keypad-FlexTopBtnWrapper',
  baseStyle: () => ({
    p: '1',
    pb: '0',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexTopBtnWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GridWrapper = chakra(Grid, {
  label: 'keypad-GridWrapper',
  baseStyle: () => ({
    height: '100%',
    p: '1',

    gridTemplateColumns: '1fr 1fr 1fr 95px',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    gap: '1',
    gridTemplateAreas: `
    "one two three twenty"
    "four five six fifty"
    "seven eight nine hundred"
    "decimal zero minus submit"
  `,

    ...(() => {
      try {
        return styleImports[themeName]?.GridWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonKeys = chakra(Button, {
  shouldForwardProp: (prop) => !['isBatch'].includes(prop),
  baseStyle: ({ isBatch }: any) => ({
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
    border: '1px',
    borderLeftColor: 'whiteAlpha.300',
    borderTopColor: 'whiteAlpha.300',
    borderRightColor: 'whiteAlpha.100',
    borderBottomColor: 'whiteAlpha.100',
    borderRadius: 'md',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
    contain: 'paint',
    color: 'white',
    flex: '1',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    fontSize: 'xl',
    fontWeight: 'semibold',

    bg: 'linear-gradient(230.86deg, #383F71 -0.23%, #525CA4 98.14%)',

    ...(isBatch && {
      bg: 'linear-gradient(230.86deg, #DD7C39 -0.23%, #F98A3E 98.14%)',
      boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
    }),

    '&.keypad-btn-done': {
      textTransform: 'uppercase',
      bg: 'linear-gradient(230.86deg, #4C73A2 -0.23%, #6FA6E7 98.14%)',
      fontSize: 'md',
    },

    _hover: {
      color: 'white',
    },

    _active: {
      color: 'white',
      boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25), inset 0 0 10px -5px black',
      borderColor: 'blackAlpha.300',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonKeys?.(isBatch);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps & { isBatch: boolean }>;
