import React from 'react';
import { Outlet } from 'react-router-dom';
import { GridItem } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { GridLayout } from '../styles/SecondaryLayout.styles';

const template: TTemplate = {
  wrapper: <GridLayout />,
  main: (
    <GridItem gridArea="main" as="main">
      <Outlet />
    </GridItem>
  ),
};

export default template;
