export const en = {
  'home.haveAPunt': 'Have a punt',
  'sports.allsports.azheading': 'A-Z',
  'header.betslipBtn.desc': 'Slip',
  'error.heading': 'The genie’s out of the bottle!',
  'maintenance.heading': 'The genie’s out of the bottle!',
  'maintenance.error':
    '<span>An error has made itself known.</span> Refresh the page, or try again later.',
  'onboarding.locationnotallowed.header': 'You wish.',
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing PuntGenie from outside of Australia</b>',
  'onboarding.login.subtitle':
    'Welcome to <b>PuntGenie</b>, the ultimate destination for racing and sports betting. Unlock your mystical account and begin  <b>placing your wagers right away!</b>',
  'onboarding.login.title': '<Suffix>Ready to</Suffix> unleash the genie?',
  'onboarding.register.createAccount': 'Create an account',
  'promo.racing.featuredRace': 'Upcoming <bu>desert derby</bu>',
  'promo.sport.featuredSport': 'Crossing <bu>swords</bu>',
  'racing.errorMessages.noRacesAvailable': 'no races are currently available.',
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'legal.responsible.section.takeaction.depositlimit.two':
    'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.depositlimit.button': 'Set limit',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.deactivate.button': 'Shut My Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': 'Exclude Yourself',
  'legal.responsible.section.takeaction.lifeexclusion.two':
    'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.lifeexclusion.button': 'Set Exclusion',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.break.two':
    'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.section.takeaction.break.button': 'Suspend My Account',
  'betslip.betslipmodal.emptybetslipheading': 'Your bet slip is empty',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'racing.exotics.selectfield': 'Select all',
};
