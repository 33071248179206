import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TPlatformOfferings, TGeneralState } from './Home.types';

const initialState: TGeneralState = {
  offerings: {
    offered_sports: [],
    offered_race_types: [],
  },
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setGeneralOfferings: (
      state: TGeneralState,
      action: PayloadAction<TPlatformOfferings>
    ) => ({ ...state, offerings: action.payload }),
  },
});

// Slice actions
export const { setGeneralOfferings } = generalSlice.actions;

// Slide reducer
export default generalSlice.reducer;
