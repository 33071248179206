import { initializeApp } from 'firebase/app';
import { getAuth, signOut as firebaseSignOut, User } from 'firebase/auth';
import { ENewRelicActions } from '@/constants/newRelicActions';
import { signIn, onAuthChangeListener } from '@/lib/firebase/Authentication';
import {
  AuthErrorCode,
  AuthUser,
  FirebaseAuthError,
} from '@/lib/firebase/types';
import { getStrings } from '@/helpers/utils';
import { apiGetRequest } from '@/lib/api/api';
import { TPunterAccountOverview } from '@/lib/DBModels';
import { handlePunterStatus } from '@/lib/punter/PunterStatus';
import {
  getUserStoreActions,
  getUserStoreState,
  setUserStoreState,
} from '../store/AuthStore';
import { HTTPError } from '@/lib/api/types';
import firebaseConfig from '@/lib/firebase/config';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { queryPunterAccount } from '@/api/punter/punter';
import { TPunterAccount } from '@/api/punter/punter.types';

const [
  {
    Onboarding: { FirebaseErrorMessages },
  },
] = getStrings();

// Firebase auth
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export async function signout() {
  try {
    await firebaseSignOut(auth);
  } catch (e) {
    console.error(e);
  }
}

export async function signin(
  email: string,
  password: string,
  setError: (error: string) => void
) {
  const { NODE_ENV } = window.BETCLOUD_ENV;
  const punterEmail = email.toLowerCase();
  const { setPunterAccountOverview } = getUserStoreActions();

  // try to sign up
  try {
    await signIn(punterEmail, password);

    if (window.newrelic) {
      window.newrelic.addPageAction(ENewRelicActions.Login, {
        success: true,
        status_code: 200,
        punter_email: punterEmail,
        environment: NODE_ENV,
      });
    }
  } catch (e) {
    const error = e as FirebaseAuthError;

    if (window.newrelic) {
      window.newrelic.addPageAction(ENewRelicActions.Login, {
        error,
        success: false,
        punter_email: punterEmail,
        environment: NODE_ENV,
      });
    }

    // Throw error if firebase fails
    switch (error.code) {
      default:
        setError(FirebaseErrorMessages.UnspecifiedError);
        break;
      case AuthErrorCode.INVALID_EMAIL:
      case AuthErrorCode.INVALID_PASSWORD:
        setError(FirebaseErrorMessages.WrongCredentials);
        break;
      case AuthErrorCode.USER_DISABLED:
        setError(FirebaseErrorMessages.UserDisabled);
        break;
      case AuthErrorCode.USER_NOT_FOUND:
        setError(FirebaseErrorMessages.UserNotFound);
        break;
    }

    return null;
  }

  try {
    const accountOverview = await apiGetRequest<TPunterAccountOverview>(
      '/punter/account/overview'
    );

    // This checks for the disabled & excluded punters.
    // Throws a error & toast if not normal
    handlePunterStatus(accountOverview);

    setPunterAccountOverview(accountOverview);

    return accountOverview;
  } catch (e) {
    // Sign out if error getting account from BE or
    // if the punter is excluded. See handlePunterStatus

    const err = e as HTTPError;

    if (err.response?.data?.detail?.msg) {
      setError(err.response?.data?.detail?.msg);
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-void
    void signout();

    return null;
  }
}

function getFirebaseUser(): Promise<User> {
  return new Promise((res) => {
    const { currentUser } = getAuth();
    if (currentUser) {
      res(currentUser);
    }
    const unsubscribe = onAuthChangeListener((user) => {
      unsubscribe();
      // TODO: if a user isn't logged in, how long do we wait?
      res(user as User);
    });
  });
}

export async function loadUser(): Promise<void> {
  const firebaseUser = await getFirebaseUser();
  const { punterData } = getUserStoreState();
  const { setIsLoading } = getUserStoreActions();

  try {
    let user: AuthUser = null;
    if (!IS_MOBILE_APP) {
      user = firebaseUser;
    }

    let punter: TPunterAccount | null = null;
    if (window.MobileApp_Token !== undefined || !punterData) {
      punter = await queryPunterAccount();
    }

    setUserStoreState((prevState) => ({
      ...prevState,
      userData: user,
      punterData: punter,
      isAuthenticated: IS_MOBILE_APP
        ? window.MobileApp_Token !== undefined
        : !!user,
    }));
  } catch (e) {
    console.error('Error getting user credentials', e);
  } finally {
    setIsLoading(false);
  }
}

export function onFirebaseUserChange(): () => void {
  const unsubscribe = auth.onAuthStateChanged(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-void
    void loadUser();
  });
  return unsubscribe;
}
