import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bg: '#2C3A56',
  '&&': { px: ['2.5', '4'], pb: '1' },
  mt: ['unset', '2'],
  overflow: 'visible',
  color: 'gamma.900',
  fontSize: 'md',
  pb: '0',
  borderTopRadius: 'base',
  borderTopWidth: '2px',
  borderX: '2px solid',
  borderColor: 'beta.500',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  mb: '2',
  fontWeight: 'medium',
  fontFamily: 'KHInterference',
  fontSize: 'xl',
  textTransform: 'capitalize',
  color: 'beta.500',
  letterSpacing: 'wider',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
  mx: ['2', 'unset'],
  mt: ['2', 'unset'],
};

export const WrapperContainer: CSSObject = {
  px: '0',
  bg: 'linear-gradient(180deg, #2C3A56 0%, #202A3E 100%)',
  borderBottomRadius: 'base',
  borderBottomWidth: '2px',
  borderX: '2px solid',
  borderColor: 'beta.500',
};
