import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  _last: {
    mb: '4',
  },
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (): CSSObject => ({
  borderLeftRadius: 'md',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'delta.800',
  fontSize: 'sm',
  fontWeight: 'black',
  _groupFocusWithin: {
    bg: 'alpha.50',
    color: 'alpha.700',
  },
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'md',
  bg: 'blackAlpha.400',
  boxShadow: '0px 1px 0px #2B6B99, inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'alpha.600',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'delta.500',
  fontWeight: 'extrabold',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'delta.500',
  fontWeight: 'extrabold',
};

export const InputBetSlip: CSSObject = {
  bg: 'delta.800',
  marginTop: '-0.5',
  borderColor: 'delta.600',
  pl: '10',
  color: 'white',
  borderRadius: 'md',
  _hover: {
    '&&': {
      background: 'delta.800',
    },
  },
  _focus: {
    borderColor: 'alpha.50',
    '&&': {
      background: 'alpha.700',
    },
  },
  '&::placeholder': { color: 'white', opacity: '0.3' },
};

export const TreasureChestIcon: CSSObject = {
  color: 'delta.500',
};
