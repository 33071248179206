import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import IconSvg, { TIconSvg } from '../../../../components/common/IconSvg';
import { getIconBySportName } from '@/helpers/utils';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'lg',
    alignItems: 'center',
    justifyContent: 'center',
    mx: ['1.5', null, 'unset'],
    mb: ['1', null, '4'],
    px: '2px',
    boxShadow: 'unset',
    h: '54px',
    bgGradient: 'linear(to-b, beta.700, beta.800)',
  }),
});

const ButtonToggle = chakra(Button, {
  label: 'desktopToggle-ButtonToggle',
  shouldForwardProp: (prop) => !['isActive', 'category'].includes(prop),
  baseStyle: ({ isActive, category }: any) => ({
    fontFamily: 'heading',
    bg: isActive ? 'transparent' : 'beta.500',
    boxShadow: isActive
      ? '0px 0px 10px 0px rgba(0, 0, 0, 0.50) inset'
      : '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px #4C7493 inset',
    fontWeight: 'normal',
    fontSize: 'md',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 12px rgba(255, 164, 28, 0.6)'
      : '0px 2px 8px rgba(0, 0, 0, 0.35)',
    color: isActive ? 'alpha.400' : 'white',
    letterSpacing: 'wide',
    px: '3',
    w: '100%',
    h: '50px',
    _hover: {
      bg: isActive ? 'transparent' : 'beta.500',
      boxShadow: isActive
        ? 'none'
        : '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px #4C7493 inset',
    },
    ...(category === 'racing' && {
      borderRightRadius: '0',
    }),
    ...(category === 'sport' && {
      borderLeftRadius: '0',
    }),
  }),
}) as ChakraComponent<
  'div',
  ButtonProps & { isActive: boolean; category: string }
>;

export const Icon = chakra(IconSvg, {
  label: 'sportsList-icon-sport',
  baseStyle: () => ({
    boxSize: '5',
    mr: '2',
  }),
}) as ChakraComponent<'svg', TIconSvg & { isActive: boolean }>;

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;
  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }
  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }
  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  const toggleCategory = (newCategory: string) => {
    setSearchParams({ category: newCategory });
  };

  return (
    <FlexWrapper>
      <ButtonToggle
        type="button"
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
        onClick={() => toggleCategory('racing')}
        category="racing"
      >
        <Icon
          name={getIconBySportName('horse racing')}
          isActive={!category || category === 'racing'}
        />
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </ButtonToggle>
      <ButtonToggle
        type="button"
        data-cy="sports-toggle-text"
        isActive={category === 'sport'}
        onClick={() => toggleCategory('sport')}
        category="sport"
      >
        <Icon name={getIconBySportName('basketball')} />
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </ButtonToggle>
    </FlexWrapper>
  );
}
