import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './BreadcrumbTwo.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: 'base',
    pl: '4',
    pr: '10px',
    h: '38px',
    display: 'flex',
    alignItems: 'center',
    sx: {
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbLinkItem: {
    fontSize: '10px',
    fontWeight: '700',
    color: 'white',
    textDecoration: 'none',
    sx: {
      '&:not([href])': {
        color: 'alpha.400',
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
