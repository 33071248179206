import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const inputProps: Partial<TIndex> = {
  sxWrapper: {
    '&&': {
      '&&': {
        '.chakra-input__left-addon': {
          h: '38px',
          borderRightRadius: 'none',
          borderLeftRadius: 'md',
        },
        '.chakra-input': {
          h: '38px',
          borderRightRadius: 'md',
          borderLeftRadius: 'none',
          boxShadow: 'none',
          _focus: {
            color: 'alpha.900',
            bgColor: 'gray.50',
            border: '2px solid',
            borderColor: 'alpha.400',
            boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
          },
          _hover: {
            color: 'alpha.900',
            border: '1px solid',
            borderColor: 'gray.400',
            bgColor: 'gray.100',

            '&::placeholder': {
              color: 'gray.400',
            },
          },
        },
      },
    },
  },
};

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  width: '100%',
  borderTop: 'none',
  mb: '4',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const CancelButton: CSSObject = {
  bg: 'white',
  borderRadius: 'base',
  color: 'gamma.600',
  fontSize: 'xs',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  border: '2px solid',
  borderColor: 'gamma.600',
  fontFamily: 'Roboto',

  '&:hover, &:focus': {
    color: 'white',
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    boxShadow: 'none',
  },
};

export const ButtonSubmit: CSSObject = {
  fontSize: 'xs',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  color: 'white',
  fontFamily: 'Roboto',
  '&:hover, &:focus': {
    bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, var(--bc-colors-gamma-500) 0%, var(--bc-colors-gamma-600) 100%)',
    backgroundBlendMode: 'overlay, normal',
    color: 'white',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
};

export const PendingLimitContainer: CSSObject = {
  color: 'white',
  fontWeight: '700',
};

export const buttonRemoveProps: ButtonProps = {
  h: '10',
  fontWeight: 'black',
  variant: 'secondary',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  color: 'beta.600',
  fontFamily: 'Fraunces',
  textTransform: 'uppercase',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.30)',
  sx: {
    '&&': {
      _hover: {
        bgGradient: 'linear(to-b, beta.500, beta.500)',
      },
    },
  },
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: 0,
    label: {
      color: 'white',
    },
  },
  '&&': {
    '.chakra-input__left-addon': {
      borderLeftRadius: 'base',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'alpha.700',
      color: 'white',
    },
    '.chakra-input': {
      border: '1px solid',
      borderLeftRadius: 'none',
      color: 'alpha.900',
      fontSize: 'xs',
      bg: 'white',
      fontWeight: 'bold',
      borderRightRadius: 'base',
      borderColor: 'gray.300',
      borderLeft: 'none',
      ':active, :hover': {
        bg: 'gray.100',
        color: 'gray.700',
        borderColor: 'gray.400',
      },
      _focus: {
        color: 'white',
        bg: 'gray.50',
        borderColor: 'blue.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },
      _placeholder: {
        color: 'gray.700',
      },
    },
  },
};

export const DepositLimitContainer: CSSObject = {
  p: '4',
  borderRadius: 'lg',
  bg: 'transparent',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'beta.300',
};

export const DepositButton: CSSObject = {
  h: '10',
  color: 'beta.600',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  border: 'none',
  position: 'relative',
  boxShadow:
    '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
  textTransform: 'uppercase',
  fontWeight: 'black',
  fontFamily: 'Fraunces',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.3)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    boxShadow: '0px 0px 0px 2px rgba(68, 51, 24, 1) inset',
    zIndex: 8,
    borderRadius: 'md',
    m: '2px',
    opacity: 1,
  },
  _hover: {
    _after: {
      bgGradient: 'linear(to-b, beta.500, beta.500)',
    },
  },
  _disabled: {
    pointerEvent: 'none',
    opacity: 0.4,
    boxShadow:
      '0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.35)',
    _after: {
      borderRadius: 'md',
      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    },
  },
};

export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  flex: '1',
};

export const buttonConfirmModalProps: ButtonProps = {
  variant: 'secondary',
  flex: '1',
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};
