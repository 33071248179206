import { CSSObject } from '@chakra-ui/react';
import { rgba } from 'polished';

export const ModalOverlay = (theme: any): CSSObject => ({
  bg: rgba(theme.colors.gamma[800] as string, 0.7),
});

export const ModalHeader: CSSObject = {
  color: 'gray.700',
  fontWeight: 'bold',
  px: 4,
  pb: 2,
};

export const ModalBody: CSSObject = {
  color: 'gray.600',
  fontSize: 'sm',
  pt: 1,
  px: 4,
  pb: 4,
};

/**
 * Most of these styles are copied over from account button styles - it would
 * be nice to import them over instead, but properties on `CSS_Object` are
 * inaccessible.
 * @see {@link https://github.com/chakra-ui/chakra-ui/issues/6261}
 */
export const ButtonDeposit: CSSObject = {
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  '&&': {
    color: 'alpha.600',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
    fontFamily: 'accent',
  },
  mt: 3,

  '&, &:hover, &:focus': {
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
    color: 'white',
    textShadow: '0 2px 3px rgba(0, 0, 0, 0.3)',
  },

  '&:hover, &:focus': {
    bg: 'beta.600',
    color: 'alpha.800',
  },
};
