import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextSubtitle, TextSubtitleBoldWord } from '../styles/index';

type TSubtitleProps = {
  id: string;
};

const Title: React.FC<TSubtitleProps> = ({ id }) => (
  <TextSubtitle>
    <FormattedMessage
      id={id}
      values={{
        b: (string: string) => (
          <TextSubtitleBoldWord as="span">{string}</TextSubtitleBoldWord>
        ),
      }}
    />
  </TextSubtitle>
);

export default Title;
