import React, { useState } from 'react';
import { groupBy } from 'lodash';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  Text,
} from '@chakra-ui/react';
import { countryToAlpha2 } from 'country-to-iso';
import CountryFlag from 'react-country-flag';
import {
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PrimaryWrapper from '@/wrappers/PrimaryWrapper';
import Nav, { NavLink } from '../components/Nav/Nav';
import { useQueryCompetitions } from '@/api/sports/competitions.hooks';
import { allLeaguesStyles } from './styles/AllLeagues.styles';
import { BoxHeaderText } from '../AllSports/components/Header/styles/Header.styles';

export default function AllLeagues() {
  const { search } = useLocation();
  const { sportName } = useParams();
  const [searchParams] = useSearchParams();
  const { sportId } = { sportId: searchParams.get('sportId') };
  const [flagError, setFlagError] = useState<string[]>([]);

  const queryCompetitions = useQueryCompetitions({ sport_id: sportId ?? '' });

  const sortedData = [
    ...(queryCompetitions.data?.data.competitions || []),
  ].sort((a, b) => {
    const countryA = a?.country || '';
    const countryB = b?.country || '';

    return countryA.localeCompare(countryB);
  });

  // Define the type for marketGroupsByLetter
  type MarketGroupsByLetter = {
    [key: string]: typeof sortedData;
  };
  const marketGroupsByLetter: MarketGroupsByLetter = {};

  sortedData.forEach((item) => {
    const firstLetter = item.country?.[0]?.toUpperCase() || 'O'; // Use 'O' if country is null or undefined
    if (!marketGroupsByLetter[firstLetter]) {
      marketGroupsByLetter[firstLetter] = [];
    }
    marketGroupsByLetter[firstLetter].push(item);
  });

  const isTopLeagueExist = queryCompetitions.data?.data.competitions?.some(
    (item) => Boolean(item.is_top_league)
  );

  const encodedSportName = encodeURIComponent(sportName ?? '');

  return (
    <PrimaryWrapper
      fullWidth
      pageTitle={queryCompetitions.data?.data.sport_name || ''}
      pageHeader={
        <BoxHeaderText>{queryCompetitions.data?.data.sport_name}</BoxHeaderText>
      }
    >
      <Box px="4" mt="3" {...allLeaguesStyles.boxWrapper}>
        <Nav pb="2" {...allLeaguesStyles.navWrapper}>
          <NavLink
            as={ReactRouterNavLink}
            to={`/sports/${encodedSportName}${search}`}
            end
          >
            <FormattedMessage id="sports.competitionsBySport.events" />
          </NavLink>
          <NavLink
            as={ReactRouterNavLink}
            to={`/sports/${encodedSportName}/All${search}`}
            end
          >
            <FormattedMessage
              id="sports.competitionsBySport.all"
              values={{ name: sportName }}
            />
          </NavLink>
        </Nav>

        <Accordion allowMultiple defaultIndex={[0]}>
          {isTopLeagueExist && (
            <AccordionItem
              border="none"
              {...allLeaguesStyles.topLeaguesAccordionItem}
            >
              <>
                <AccordionButton {...allLeaguesStyles.accordionButton}>
                  <Text {...allLeaguesStyles.topLeaguesText}>
                    <FormattedMessage id="generic.topLeagues" />
                  </Text>
                  <AccordionIcon {...allLeaguesStyles.accordionIcon} />
                </AccordionButton>
                <AccordionPanel {...allLeaguesStyles.accordionPanel}>
                  <Box {...allLeaguesStyles.accordionPanelBoxWrapper}>
                    {queryCompetitions?.data?.data.competitions
                      ?.filter((comp) => !!comp.is_top_league)
                      .map(({ competition_name, competition_id }) => {
                        const to = `/sports/${sportName}/${encodeURIComponent(
                          competition_name
                        )}${search}&competitionId=${competition_id}`;

                        return (
                          <Box
                            key={`${competition_id}-topLeagues`}
                            {...allLeaguesStyles.accordionPanelBoxInner}
                          >
                            <Link
                              as={ReactRouterLink}
                              to={to}
                              {...allLeaguesStyles.accordionPanelBoxInnerLink}
                            >
                              {competition_name}{' '}
                            </Link>
                          </Box>
                        );
                      })}
                  </Box>
                </AccordionPanel>
              </>
            </AccordionItem>
          )}

          {Object.entries(marketGroupsByLetter).map(
            ([letter, competitions]) => {
              const countryGroup = groupBy(competitions, ({ country }) =>
                country === null ? 'other' : country
              );

              return (
                <div key={letter}>
                  <Text {...allLeaguesStyles.marketGroupsByLetterHeadingText}>
                    {letter}
                  </Text>
                  {Object.entries(countryGroup).map(([country]) => {
                    const countryCode =
                      countryToAlpha2(countryGroup[country][0].country ?? '') ||
                      '';

                    return (
                      <AccordionItem
                        key={letter}
                        {...allLeaguesStyles.marketGroupsByLetterAccordionItem}
                      >
                        <AccordionButton
                          {...allLeaguesStyles.marketGroupsByLetterAccordionButton}
                        >
                          <Text
                            {...allLeaguesStyles.marketGroupsByLetterTitleText}
                          >
                            {(countryCode ||
                              !flagError.includes(countryCode)) && (
                              <CountryFlag
                                countryCode={countryCode}
                                style={
                                  allLeaguesStyles.marketGroupsByLetterCountryFlag
                                }
                                onError={() =>
                                  setFlagError((err) => [...err, countryCode])
                                }
                                svg
                              />
                            )}
                            {countryGroup[country][0].country ?? 'Other'}
                          </Text>
                          <AccordionIcon
                            {...allLeaguesStyles.marketGroupsByLetterAccordionIcon}
                          />
                        </AccordionButton>
                        <AccordionPanel
                          {...allLeaguesStyles.marketGroupsByLetterAccordionPanel}
                        >
                          <Box
                            {...allLeaguesStyles.marketGroupsByLetterAccordionPanelBox}
                          >
                            {countryGroup[country].map((comp) => {
                              const to = `/sports/${sportName}/${encodeURIComponent(
                                comp.competition_name
                              )}${search}&competitionId=${comp.competition_id}`;

                              return (
                                <Box key={comp.competition_id} mb="2">
                                  <Link
                                    as={ReactRouterLink}
                                    to={to}
                                    {...allLeaguesStyles.marketGroupsByLetterAccordionPanelLink}
                                  >
                                    {comp.competition_name}
                                  </Link>
                                </Box>
                              );
                            })}
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </div>
              );
            }
          )}
        </Accordion>
      </Box>
    </PrimaryWrapper>
  );
}
