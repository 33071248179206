import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    pt: '0',
    mb: 0,
    color: 'white',
    ...(!IS_MOBILE_APP && {
      pb: ['8', null, '5'],
    }),
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: ['5', null, '2'],
        pt: '1',
      },
    },
  },
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['2', null, 'unset'],
    mb: 4,
  },
  textTitle: {
    mt: '0',
    color: 'white',
    fontSize: 'md',
    fontWeight: 'medium',
    ...(IS_MOBILE_APP && {
      fontSize: 'md',
    }),
  },
  accountHeaderContainer: {
    pl: '0',
    sx: {
      '&&': {
        px: '0',
        bg: 'beta.800',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderRadius: 'md',
        pb: '2.5',
        bg: 'beta.800',
        flexDir: 'column',
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pt: '4',
    pb: ['1', null, '4'],
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
