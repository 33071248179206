import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  mt: '1',
  mb: '0',
  pb: '1',
  gap: ['1', '2'],

  '.chakra-button': {
    bg: 'beta.500',
    color: 'beta.700',
  },
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'secondary',
  lineHeight: 'normal',
};
