import {
  EThemes,
  EThemesOG,
  THEME_CONFIG,
  TThemeConfig,
} from '@/constants/themeConfig';

const { REACT_APP_THEME, REACT_APP_IS_PROD } = window.BETCLOUD_ENV;

/**
 * Returns the theme name variable
 */
export const getThemeName = () => {
  const themeName =
    REACT_APP_IS_PROD !== 'true' &&
    ((window.MobileApp_Theme as unknown as EThemesOG) ??
      (localStorage.getItem('themeName') as unknown as EThemesOG | undefined));

  return themeName || (REACT_APP_THEME as unknown as EThemesOG);
};

export const getThemeConfig = (): TThemeConfig => {
  const themeName =
    REACT_APP_IS_PROD !== 'true' &&
    ((window.MobileApp_Theme as unknown as EThemesOG) ??
      (localStorage.getItem('themeName') as unknown as EThemesOG | undefined));

  return THEME_CONFIG[
    (themeName || (REACT_APP_THEME as unknown as EThemesOG)) ?? EThemesOG.Base
  ];
};

/**
 * Helper to get the theme name from the enum
 */
export const themeToEnum = (themeName?: EThemes) => {
  const storageThemeName =
    REACT_APP_IS_PROD !== 'true' ? localStorage.getItem('themeName') : null;
  const keys = Object.keys(EThemes);
  const values = Object.values(EThemes);

  const find = keys.findIndex(
    (themes) =>
      themes.toLocaleLowerCase() ===
      (themeName ?? (storageThemeName || REACT_APP_THEME))
  );

  return values[find] as unknown as EThemes;
};

export const themeName = getThemeName() as string;
