import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const PageHeadingWrapper: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'gray.300',
  p: 0,
};

export const TableWrapper: CSSObject = {
  pt: '0',
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'neutral' ? 'beta.500' : 'white',
});

export const TransactionWrapper: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'gray.300',
  minH: 'auto',
  px: 0,
  py: 2,
};
