import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';

export const GridItemContent: CSSObject = {
  mt: ['0', '0', '0', '0', '4'],
  ...(IS_MOBILE_APP && { pb: '150px' }),
};

export const GridSubLayout: CSSObject = {
  gridTemplateColumns: ['1fr', null, null, '270px 1fr', '270px 1fr 290px'],
  gridTemplateRows: [
    'auto auto 1fr',
    null,
    null,
    'auto auto 1fr auto',
    'auto 1fr auto',
  ],
  columnGap: '2.5',
  gridTemplateAreas: [
    `
        "betslip"
        "sidenav"
        "content"
        "footer"
      `,
    null,
    null,
    `
        "sidenav betslip"
        "sidenav content"
        "sidenav content"
        "sidenav footer"
      `,

    `
        "sidenav content betslip"
        "sidenav content betslip"
        "sidenav footer betslip"
      `,
  ],

  'body[data-sensitive="true"] &': {
    gridTemplateRows: '',
    gridTemplateColumns: '',
    gridTemplateAreas: '',
  },
};

export const GridHeader: CSSObject = {
  bgGradient: 'linear(180deg, beta.400, beta.500)',
  boxShadow: 'lg',
  borderBottom: '2px solid',
  borderColor: 'alpha.50',
};

export const GridItemBetslip: CSSObject = {
  position: [null, null, null, null, 'sticky'],
  top: [null, null, null, null, '54px'],
  height: [null, null, null, null, 'calc(100vh - 135px)'],
  bg: 'transparent',
};

export const GridItemToggle: CSSObject = {
  position: [null, null, 'sticky'],
  top: [null, null, null, null, '54px'],
  width: [null, null, '270px'],
};

export const GridItemSideNav: CSSObject = {
  overflowY: 'visible',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};
