import { CSSObject } from '@chakra-ui/react';

export const SubHeading: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontSize: 'sm',
};

export const BoxBordered: CSSObject = {
  px: '2',
  textColor: 'black',
  maxW: 'container.md',
  pt: '0',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const SmallHeading: CSSObject = {
  mt: '2',
  color: 'white',
  fontSize: 'sm',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const ImageGamblersHelp: CSSObject = {
  w: '168px',
  minW: '168px',
  bg: 'white',
  padding: '5px',
  borderRadius: 'md',
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr',
  maxW: 'container.sm',
  gap: '2.5',
};

export const Link: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mx: '2',
};

export const ButtonAction: CSSObject = {
  fontFamily: 'accent',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  color: 'alpha.600',
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  _hover: {
    bg: 'beta.600',
    color: 'alpha.800',
  },
};
