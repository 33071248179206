import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  body: {
    color: 'gray.700',
  },
  container: {
    px: '3',
    mt: '-4',
  },
};
