import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/betroyale/images/backgrounds/desktopBg.png';
import backgroundAtRetina from '@/assets/betroyale/images/backgrounds/desktopBgAtRetina.png';
import backgroundMobile from '@/assets/betroyale/images/backgrounds/mobileBg.png';

import { CustomButtonProps } from '@/components/Button/Button';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: `url(${background})`,
  bgRepeat: 'no-repeat',
  bgSize: ['100% 100%', '100% 100%'],

  '@media (max-width: 480px)': {
    bgImage: `url(${backgroundMobile})`,
    bgSize: '125% 125%',
  },

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${backgroundAtRetina})`,
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  mt: ['0', null, '12'],
  mx: 'auto',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.lg',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  img: {
    mb: ['6', null, '5'],
  },
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Spectral',
  fontSize: ['4xl', null, '5xl'],
  mt: '4',
  mb: ['2.5', null, '6'],
  textTransform: 'uppercase',
  fontWeight: 'semibold',
  color: 'beta.500',
  lineHeight: '0.96',
  textAlign: 'center',
  w: ['350px', '750px'],
};

export const ContentBodyText: CSSObject = {
  fontSize: ['sm', null, 'md'],
  mb: ['5', '4'],
  w: ['225px', '370px'],
  wordWrap: 'break-word',
  b: {
    color: 'gamma.500',
  },
  '&[data-ismaintenance="false"]': {
    w: ['220px', '370px'],
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'beta.500',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};

export const buttonRefreshProps: CustomButtonProps = {};
