import { CSSObject } from '@chakra-ui/react';

export const BetslipHeaderIcon: CSSObject = {
  color: 'white',
};

export const FlexBetslipHeading: CSSObject = {
  minH: ['88px', null, null, '53px', '50px'],
  borderBottomColor: 'betaAlpha.500',
  borderBottom: '1px',
};

export const BetslipTitle: CSSObject = {
  fontSize: [null, null, null, 'lg', 'lg'],
};

export const BetLengthIndicator: CSSObject = {
  ml: '2',
  color: 'alpha.900',
  bg: 'yellow.400',
  fontSize: 'sm',
};
