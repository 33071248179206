import { useEffect } from 'react';
import {
  getBetSlipStoreActions,
  transformBetForLegacy,
  useBetSlipBets,
  useMoMBet,
} from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import {
  EBetSlipBetSubmissionType,
  TBetSlipBet,
  TBetSlipBetSRMulti,
} from '../Betslip/Services/Betslip.types';
import { serializeBetsForAPI } from '../Betslip/Services/Betslip.utils';
import api from '@/api/api';
import { buildMOMBet } from '../Betslip/components/Modal/MoM/utils';

export function MoM() {
  const moMBet = useMoMBet();
  const betsNew = useBetSlipBets() ?? {};

  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );

  const { setMoMBet, setMoMError } = getBetSlipStoreActions();
  useEffect(() => {
    if (FEATURE_FLAGS.REACT_APP_MOM_ENABLED && bets.length > 0) {
      if (
        moMBet &&
        (moMBet?.betRequestStatus !== null ||
          moMBet?.betSubmissionStatus !== null)
      )
        return;
      const filteredBets = bets
        .filter((bet) => bet.type !== 'Multi')
        .map((bet) => {
          if (bet?.type === EBetSlipBetSubmissionType.SRMulti) {
            const b = bet as unknown as TBetSlipBetSRMulti;
            return {
              ...b,
              selection: b.selection.reduce(
                (acc: number[][], cur: TSelection[]) => {
                  const sel = cur.map((item) => item.runner_number);
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  acc.push(sel);
                  return acc;
                },
                [] as number[][]
              ),
            };
          }
          return bet;
        });
      api
        .post('/punter/bet-slip/v3/build', {
          channel: 'DesktopBrowser',
          bets: serializeBetsForAPI(filteredBets).map(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            ({ request_id = '', ...bet }, i) => ({
              request_id,
              bet_details: { ...bet, leg_num: i },
            })
          ),
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (response.data.multi_unavailable_reason) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setMoMError(response.data.multi_unavailable_reason as string);
            setMoMBet(null);
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const mOMBet = buildMOMBet(response.data);
          setMoMError(null);
          setMoMBet(mOMBet);
        })
        .catch(() => {
          setMoMError('MultiUnavailable');
          setMoMBet(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bets.length]);

  return null;
}
