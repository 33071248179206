import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mx: [null, null, '4'],
  zIndex: 'modal',
};

export const InfoHeader: CSSObject = {
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
};

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  textTransform: 'uppercase',
};

export const TextAddToBetslipButton: CSSObject = {
  fontWeight: 'black',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'beta.700',
  borderBottom: '1px dashed',
  borderColor: 'white',
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none', 
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-3px',
  width: '24px',
  height: '24px',
  color: 'alpha.400',
};