import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
  },
  eventMarketName: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
  },
  eventSubtitle: {
    fontSize: 'sm',
    fontWeight: 'medium',
    color: 'white',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'sm',
    color: 'white',
    sx: {
      '> span': { fontSize: '2xs', fontWeight: 'medium' },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '1',
    pt: '1',
    pb: '2',
    borderTop: '1px dashed',
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    borderTopColor: 'rgba(255, 255, 255, 0.07)',
  },
};
