import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
    pb: 3,
    pr: [2, 0, null, null],
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: ['lg', null, 'xl'],
    fontWeight: 'normal',
    color: 'white',
    fontFamily: 'accent',
    lineHeight: '19px',
    px: [2, null, 0],
    pb: [2, null, 0],
    pt: 0,
  },
  headerIconButton: {
    color: 'alpha.400',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'alpha.400',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'alpha.400',
    h: '1.1em',
    ml: '2',
    mb: '0.5',
    w: 'auto',
  },
  scrollButtonGroup: {
    h: '10',
    variant: 'filter',
    boxShadow: 'none',
    ml: '0',
    w: 'full',
  },
  wrapperScrollSgm: { 
    w: '100%', 
    maxW: '100%', 
    gap: '2',
  },
  toMarketButton: {
    border: 'none',
    boxShadow: 'none',
    fontSize: 'sm',
    textShadow: 'none',
    fontWeight: 'normal',
    borderRadius: 'none',
    fontFamily: 'accent',
    h: '9',
    textTransform: 'uppercase',
    color: 'white',
    borderBottom: '3px solid',
    borderBottomColor: 'transparent',
    px: '3',
    ml: '8px',
    _hover: {
      color: 'alpha.400',
    },
    _active: {
      color: 'alpha.400',
      fontWeight: 'normal',
      borderBottom: '3px solid',
      borderBottomColor: 'alpha.400',
    },
    sx: {
      _first: {
        pl: '2',
      },
      '&[data-active]': {
        _after: {
          display: 'none',
        },
      },
    },
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    gap: '3',
    mx: ['2', null, '0'],
  },
  accordionStack: {
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bg: 'beta.200',
    border: 'none',
    borderRadius: 'md',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.35)',
    overflow: 'hidden',
  },
  accordionButton: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
    ml: '4px',
    w: '99%',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
  },
  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'alpha.400',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    px: '3',
  },
  clearButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    ml: '3',
    px: '3',
  },
  sgmLogoV2: {
    color: 'alpha.400',
    w: ['3.5em', null, null, '3em'],
    h: ['1.3em', null, null, '1.4em'],
    filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.30))',
  },
  sgmBtn: {
    px: [2.5, null, null, 4],
    sx: {
      'svg[class*="StyledIconBase"]': {
        color: '#FBC915',
        textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
        boxSize: ['18px', null, null, 5],
      },
      '&&': {
        _active: {
          bgGradient: 'linear(to-b, beta.800, beta.800)',
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30)',
        },
      },
    },
  },
  sgmBtnActive: {
    sx: {
      'svg[class*="StyledIconBase"]': {
        boxSize: ['18px', null, null, 5],
      },
      '&&': {
        bgGradient: 'linear(to-b, beta.800, beta.800)',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30)',
        _hover: {
          color: 'alpha.400',
        }
      },
    },

    _after: { display: 'none' },
  },
  scrollButtonGroupV2: {
    h: '10',
    variant: 'filter',
    bg: 'transparent',
    position: 'relative',
  },
  accordionItemActive: {
    boxShadow:
      '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
    bgGradient: `linear(to-b, beta.300, beta.400)`,
  },
};
