import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  bg: 'alpha.600',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  boxSize: '10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'beta.400',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bg: 'white',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  w: '100%',
  h: '64px',
  position: 'relative',
  borderRadius: 'base',
  zIndex: '0',
  _after: {
    content: '""',
    position: 'absolute',
    inset: 0,
    bg: 'white',
    boxShadow: ' 0px 0px 0px 2px rgba(0, 0, 0, 1) inset',
    m: '2px',
    borderRadius: 'base',
    zIndex: '-1',
  },
  _hover: {
    bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    _after: {
      bg: 'gray.200',
    },
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'gray.500',
  mt: '1.5',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'gray.500',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'beta.500',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'alpha.700',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'gray.500',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'gray.600',
  border: '2px',
  borderColor: 'gray.600',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
