import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  h: '9',
  position: 'inherit',
  '&&': {
    p: 0,
    pb: '1',
  },
};

export const ButtonFilter = () => ({
  color: 'alpha.300',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'beta.400' : 'alpha.800',
  color: selected ? 'alpha.800' : 'transparent',
  boxShadow: '0px 1px 0px 0px #000 inset',
});

export const IconRace = ({ selected }: TSelected) => ({
  color: selected ? 'beta.300' : 'alpha.800',
  filter: selected
    ? ' drop-shadow(0px 1px 1px #004072)'
    : 'drop-shadow(0px 1px 1px rgba(255, 255, 255, 0.30))',
});

export const Divider: CSSObject = {
  bg: 'alpha.800',
  display: 'block',
  h: '7',
  my: 'auto',
  mx: '4',
};

export const TextFilterLabel: CSSObject = {
  color: 'alpha.800',
  fontFamily: 'body',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: '0',
};
