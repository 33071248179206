import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/slambet/images/feature/featRaceBg.png';
import featBgMb from '@/assets/slambet/images/feature/featRaceBgMb.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';
import slamBorderSmall from '@/assets/slambet/images/backgrounds/gradientBorderSmall.svg';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    fontFamily: 'accent',
    fontSize: 'sm',
    h: '8',
    w: '46px',
    borderRadius: 'md',
  },
  svgMoreRacesArrowProps: {
    color: 'white',
    boxSize: '5',
  },
};
export const BoxCard: CSSObject = {
  p: '2',
};
export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', null, 'lg'],
    boxShadow: [
      'unset',
      null,
      '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
    ],
    m: ['5px', null, '0'],
  },
  boxShadow: [
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
    null,
    'unset',
  ],
  bg: 'rgba(78, 178, 255, 0.14)',
  flex: '2',
  transformStyle: 'preserve-3d',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'lg',
  minH: '250px',
  py: '4',
  px: '3',
  pos: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['0', null, '60px'],
  color: 'white',
  fontStyle: 'accent',
};
export const TextFeatureHeading: CSSObject = {
  display: 'none',
};
export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};
export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};
export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};
export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};
export const HeadingSlogan: CSSObject = {
  color: 'alpha.600',
  fontFamily: 'accent',
  fontSize: '2xl',
  textTransform: 'capitalize',
};
export const LinkRaceDetails: CSSObject = {
  display: 'none',
};
export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'alpha.600',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};
export const TextCardHeader: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  pt: '1',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
};
export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};
export const TextRaceNumber: CSSObject = {
  fontFamily: 'roboto',
  bg: 'beta.200',
  borderRadius: 'sm',
  color: 'blackAlpha.700',
  px: '1',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};
export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '50px',
  bg: `rgba(78, 178, 255, 0.14)`,
  borderRadius: ['base', 'lg'],
  boxShadow:
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  pos: 'relative',
  px: '1',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorderSmall}) 30`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};
export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
