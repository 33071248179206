import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  display: ['none', null, 'block'],
  flex: [null, null, 1],
  pt: [null, null, '223px'],
};

export const TextItem: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',

  span: { color: 'alpha.400', fontWeight: 'bold' },
};

export const LinkItem: CSSObject = {};
