import { CSSObject } from '@chakra-ui/react';

export const TextItem: CSSObject = {
  textTransform: 'capitalize',
  fontFamily: 'accent',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'normal',
  pt: 0,
};
