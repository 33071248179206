import { CSSObject } from '@chakra-ui/react';

export const NoRacesWrapper: CSSObject = {
  minH: '70vh',
};

export const ImageNoRaces: CSSObject = {
  width: ['100%', '35%'],
};

export const TextPrimary: CSSObject = {
  color: 'alpha.300',
  textShadow: '0px 4px 0px #210A15',
  fontFamily: 'Ultra',
  fontSize: ['3xl', '4xl'],
  textTransform: 'uppercase',
  fontWeight: 'normal',
  textAlign: 'center',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  w: ['72', null, '420px'],
  fontSize: 'sm',
  textAlign: 'center',
  p: {
    w: '100%',
    fontWeight: 'normal',
  },
};
