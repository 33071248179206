import { CSSObject } from '@chakra-ui/react';

export const StartTime: CSSObject = {
  fontFamily: 'body',
  fontSize: '2xs',
  fontWeight: 'bold',
  px: '2',
  py: '1',
  borderRadius: 'base',
  height: '100%',
  color: 'white',
  bg: 'alpha.700',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
};
