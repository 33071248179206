import React from 'react';
import { Text } from '@chakra-ui/react';
import { TTemplate } from '../../../../../../helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import {
  BoxHeadingWrapper,
  FlexWrapper,
  HeadingSlogan,
  FlexFeatureCardWrapper,
} from '../styles/Featured.styles';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  featureCard: {
    wrapper: <FlexFeatureCardWrapper />,
    heading: (
      <>
        <BoxHeadingWrapper>
          <HeadingSlogan>
            <Text>Featured Match</Text>
          </HeadingSlogan>
        </BoxHeadingWrapper>
      </>
    ),
    feature: <FeaturedMatch />,
  },
};
export default template;
