import { Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import logo from '@/assets/fiestabet/images/onboarding/logoOnb.png';
import logoMb from '@/assets/fiestabet/images/onboarding/logoMobile.png';

const LogoAside = () => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  return (
    <Image
      src={isMobile ? logoMb : logo}
      w={['44vw', '150px', '205px']}
      mt="3"
    />
  );
};

export default function index() {
  return <LogoAside />;
}
