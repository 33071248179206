import { css } from 'styled-components';

export const wrapper = css`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: var(--bc-sizes-0-5);
  padding-top: var(--bc-sizes-0-5);
`;

export const buttonContainer = css`
  display: flex;
  flex: 1 1 25%;
  margin-bottom: var(--bc-sizes-1);
`;

// TODO: Remove
export const button = css`
  background: ${({ theme }) => theme.basePalette.darkBlue};
  color: ${({ theme }) => theme.basePalette.brightBlue};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--bc-sizes-1);
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  border: none;
  border-radius: 5px;
  height: var(--bc-sizes-6);
  cursor: pointer;
`;

export const buttonLabel = css``;

export const deleteIcon = css`
  width: var(--bc-sizes-4);
  height: var(--bc-sizes-4);
`;
