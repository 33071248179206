import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'alpha.600',
    borderRadius: 'base',
    border: 'none',
    gap: '3',
  },
  depositIconProps: {
    color: '#ECC94B',
    boxSize: '9',
  },
};
