import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'onboarding.register.createAccount': 'Create An Account',
  'generic.selectplaceholder': 'Please Select...',
  'generic.sportAZ': 'A-Z',
  'betslip.betslipmodal.emptybetslipheading': 'Where’s the treasure?!',
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your racing and sports selections',
  'sports.allsports.azheading': 'A-Z',
  'header.betslipBtn.desc': 'Slip',
  'maintenance.heading': `Restoring Order`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'error.heading': "Temple's Closed",
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'onboarding.locationnotallowed.header': 'You stepped on a trap...',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing TempleBet from outside of Australia.',
  'racing.errorMessages.noRacesAvailable': 'No races are currently available',
  'account.overview.email': 'Email address',
  'account.deposit.titles.main': 'Make A Deposit',
  'account.deposit.labels.selectdeposit': 'Credit / Debit Card Deposit',
  'account.withdraw.withdrawfundstitle': 'Withdrawal',
  'account.withdraw.requests.heading': 'Withdrawal Requests',
  'account.withdraw.requests.cancelbutton': 'Cancel Withdrawal Request',
  'account.withdraw.addbank': 'Add New Bank Account',
  'account.depositlimit.depositlimit': 'Your deposit limit',
  'account.currentlimit.heading': 'Set a deposit limit',
  'account.depositlimit.setlimit.heading': 'Deposit Limit',
  'account.currentlimit.savelimitbuttontext': 'Update Deposit Limit',
  'account.currentlimit.removelimitbuttontext': 'Remove limit',
  'account.currentlimit.depositlimitlabel': 'Deposit limit',
  'account.currentlimit.limitperiodlabel': 'Restriction period',
  'account.closeaccount.title': 'Close Your Account',
  'legal.responsible.section.takeaction.break.two':
    'If you are considering limiting your betting, you have the option to take a short break (1 day and up to 30 days) or a a longer term break (6 months and up to 5 years).',
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'legal.responsible.section.takeaction.longterm.one': 'Close Account',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': 'Exclude Yourself',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.depositlimit.button': 'Set Limit',
  'legal.responsible.section.takeaction.deactivate.button': 'Close My Account',
  'legal.responsible.section.takeaction.lifeexclusion.button': 'Set Exclusion',
  'legal.responsible.section.takeaction.break.button': 'Suspend My Account',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
};
