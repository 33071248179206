import { CSSObject } from '@chakra-ui/react';

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'beta.800',
};

export const ButtonShowMore: CSSObject = {
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-beta-700) 0%, var(--bc-colors-beta-800) 100%)',
  boxShadow:
    ' 0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  color: 'beta.500',
  fontFamily: 'accent',
  h: '10',
  lineHeight: 'none',
  minW: '8',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'bold',
  borderRadius: 'md',
  textShadow: '0px 4px 4px var(--bc-colors-gammaAlpha-200)',
  _hover: {
    color: 'white',
    bgGradient:
      'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  },
  _active: {
    color: 'beta.500',
    bgGradient:
      'linear-gradient(to bottom, var(--bc-colors-beta-900), var(--bc-colors-beta-900))',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  },
  _focus: {
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  },
};

export const FlexBetCard: CSSObject = {
  rowGap: '2',
  w: 'full',
};
