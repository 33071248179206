import { CustomButtonProps } from '@/components/Button/Button';

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  w: 'full',
  variant: 'primary',
};

export const buttonSubmitExclusionProps: CustomButtonProps = {
  w: 'full',
  variant: 'primary',
};
