import api from '../api';
import {
  TSportMarketGroupsResponse,
  TSportMarketGroupsParams,
} from './sportDetailsMarketGroups.types';

export const sportMarketGroupsEndpoint =
  'punter/sports/sport-details/market-groups';

export const querySportMarketGroups = (params: TSportMarketGroupsParams) =>
  api
    .get<TSportMarketGroupsResponse>(sportMarketGroupsEndpoint, { params })
    .then((res) => res.data);
