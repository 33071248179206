import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  keyframes,
  Skeleton,
  Switch,
  SwitchProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { StitchWrapper } from '@/views/Home/components/Toggle/styles/Toggle.styles';
import toggle from '@/assets/betprofessor/images/sidenav/toggle.png';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'toggle-FlexWrapper',
  baseStyle: {
    alignItems: 'center',
    contain: 'layout',
    width: '272px',
    height: '60px',
    justifyContent: 'center',
    mx: 'auto',
    bgGradient: 'linear(180deg, beta.600, beta.700)',
    borderRadius: 'lg',
    mb: '4',
    p: '1.5',
    boxShadow:
      '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #1A3347, 0px 2px 3px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: ({ isActive }: any) => ({
      fontFamily: 'Dreadful',
      fontWeight: 'normal',
      fontSize: '32px',
      textTransform: 'uppercase',
      color: isActive ? 'alpha.300' : 'blackAlpha.700',
      textShadow: isActive
        ? '0px 0px 15px #6DDA01'
        : '0px 1px 0px rgba(255, 255, 255, 0.15)',
      letterSpacing: 'normal',
      px: '2.5',
      pt: '1',
    }),
  });

const Switcher: ChakraComponent<'label', SwitchProps> = chakra(Switch, {
  label: 'Switcher',
  baseStyle: {
    '.chakra-switch__track': {
      bg: 'blackAlpha.500',
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      w: '53px',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',

      _before: {
        bgImg: `url(${toggle})`,
        boxSize: '41px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-1.5',
        right: '0',
        top: '-2',
        animation: `
            ${keyframes`
              from { transform: rotate(0deg) }
              to { transform: rotate(-360deg) }
            `} 0.5s linear`,
      },
    },

    _active: {
      '.chakra-switch__track': {
        boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      },
    },

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
        _before: {
          animation: `
            ${keyframes`
              from { transform: rotate(0deg); }
              to { transform: rotate(360deg); }
            `} 0.7s linear`,
        },
      },
      '.chakra-switch__track': {
        bg: 'blackAlpha.500',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <StitchWrapper>
        <TextToggle
          data-cy="racing-toggle-text"
          isActive={!category || category === 'racing'}
        >
          Racing
        </TextToggle>

        <Switcher
          onChange={() => {
            setSearchParams({
              category: !category || category === 'racing' ? 'sport' : 'racing',
            });
          }}
          isChecked={!!category && category !== 'racing'}
          colorScheme="blackAlpha"
          size="lg"
          data-cy="categorySwitcher"
        />
        <TextToggle
          data-cy="sports-toggle-text"
          isActive={category === 'sport'}
        >
          Sports
        </TextToggle>
      </StitchWrapper>
    </FlexWrapper>
  );
}
