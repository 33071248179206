import React from 'react';
import { Img } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logo from '../../../assets/volcanobet/images/logo.svg';
import Title from '../components/Title';
import Description from '../components/Description';
import RefreshBtn from '../components/RefreshBtn';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (): TTemplate => ({
  wrapper: <FlexPageWrapper />,
  content: {
    wrapper: <MaintenanceContainer />,
    logo: (
      <Img src={logo} alt={REACT_APP_THEME_NAME} alignSelf="center" w="224px" />
    ),
    title: <Title />,
    desc: <Description />,
    btn: <RefreshBtn />,
    body: (
      <Global styles={{ body: { background: 'var(--bc-colors-gray-400)' } }} />
    ),
  },
});

export default template;
