import {
  ButtonProps,
  chakra,
  ChakraComponent,
  Icon,
  Box,
  Flex,
} from '@chakra-ui/react';
import { styleImports } from './Filters.styles.imports';
import IconSvg from '../../../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

export type TSelected = {
  selected: boolean;
};

const themeName = getThemeName();

/**
 * Wrapper for the filters
 */
export const FlexFilterWrapper = chakra(Flex, {
  label: 'filters-FlexFilterWrapper',
  baseStyle: ({ theme }: any) => ({
    alignItems: 'center',
    py: ['3', '5'],
    px: ['unset', '3'],
    position: ['sticky'],
    top: '0',
    zIndex: 'sticky',
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'white',
      py: ['3', '4'],
      px: '4',
      mx: '-2',
      top: '-px',
      position: 'unset',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexFilterWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Wrapper for the race type filters
 */
export const FlexRaceTypeWrapper = chakra(Flex, {
  label: 'filters-FlexRaceTypeWrapper',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    columnGap: '2.5',
    mr: ['auto', 'unset'],
    ...(theme.themeName === EThemes.Betgalaxy && {
      mr: 'unset',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexRaceTypeWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Icon (check) styles for the filters
 */
export const IconCheck = chakra(Icon, {
  label: 'filters-IconCheck',
  shouldForwardProp: (prop) => !['selected'].includes(prop),
  baseStyle: ({ theme, selected }: TSelected & any) => ({
    boxSize: '4',
    p: '1',
    borderRadius: 'full',
    mr: '1',

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: selected ? 'gamma.100' : 'gray.400',
      color: selected ? 'white' : 'transparent',
    }),
    ...(() => {
      try {
        return (
          styleImports[themeName]?.IconCheck?.({
            selected,
          }) ?? null
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', { selected: boolean }>;

/**
 * Icon race type styles for the filters
 */
export const IconRace = chakra(IconSvg, {
  label: 'filters-IconRace',
  shouldForwardProp: (prop) => !['selected'].includes(prop),
  baseStyle: ({ theme, selected }: TSelected & any) => ({
    boxSize: '5',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: selected ? 'gamma.100' : 'gray.400',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.IconRace?.({
          selected,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', { selected: boolean }>;

/**
 * Button styles for location filters
 */
export const ButtonFilter = chakra(Button, {
  label: 'filters-ButtonFilter',
  shouldForwardProp: (prop) => !['selected'].includes(prop),
  baseStyle: ({ theme, selected }: TSelected & any) => ({
    display: 'flex',
    fontSize: 'xs',
    fontWeight: 'semibold',
    alignItems: 'center',
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: selected ? 'gamma.100' : 'gray.400',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonFilter?.({
          selected,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', { selected: boolean } & ButtonProps>;

/**
 * Divider style between race type and filters
 */
export const Divider = chakra(Box, {
  label: 'filters-Divider',
  baseStyle: ({ theme }) => ({
    display: ['none', 'block'],
    width: '1px',
    bg: 'gray.200',
    mx: '2.5',
    ...(theme.themeName === EThemes.Wellbet && {
      display: 'block',
      color: 'gray.300',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.Divider;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextFilterLabel = chakra(Box, {
  label: 'filters-TextFilterLabel',
  baseStyle: () => ({
    color: 'gray.400',

    ...(() => {
      try {
        return styleImports[themeName]?.TextFilterLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
