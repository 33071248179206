import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: '0',
  '&&': { px: ['0', null, null, '4'], pb: ['0', '1', null, '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: 0,
  fontWeight: 'normal',
  fontSize: 'xl',
  textTransform: 'uppercase',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
  mx: '0',
  px: ['10px', null, null, '0'],
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: ['md', null, null, 'base'],
  minHeight: '0',
  boxShadow: [
    'unset',
    null,
    '0px 1px 5px 0px rgba(130, 151, 222, 1) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.45)',
  ],
  bgGradient: [
    'unset',
    null,
    'linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))',
  ],
};

export const WrapperContainer: CSSObject = {
  px: ['0', null, null, '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
  mb: '3',
};
