import { CSSObject } from '@chakra-ui/react';

export const OddsText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'extrabold',
};

export const MultiCardContainer: CSSObject = {
  ':first-child': {
    borderY: '1px solid',
    borderColor: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  },
  ':last-child': {
    borderBottom: 'none',
    boxShadow: 'unset',
  },
  borderBottom: '1px solid',
  borderColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
};

export const OddsMultiCard: CSSObject = {
  fontWeight: 'normal',
};
