import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: ['2.5', null, null, '3'],
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '4'],
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'delta.200',
  borderRadius: 'md',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  bg: 'white',

  _hover: { bg: 'gray.200' },
};

export const IconSport: CSSObject = {
  color: 'alpha.100',
};

export const IconChevron: CSSObject = {
  boxSize: ['4', '5'],
  border: '1px solid',
  borderColor: 'gray.700',
  borderRadius: 'full',
  p: '1',
  color: 'gray.700',
  transition: 'inherit',

  _hover: {
    color: 'gray.700',
  },
};

export const TextName: CSSObject = {
  color: 'gray.700',
  fontWeight: 'semibold',
};
