import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { primary } from '@/theme/betprofessor/components/button';
import takeAPungBG from '@/assets/betprofessor/images/backgrounds/takeapunt.jpg';

export const FlexTeamWrapper: CSSObject = {
  gap: '12px',
  mt: '4px',
  border: 0,
};

export const FlexWrapper: CSSObject = {
  pl: '0',
  pr: '0',
  pb: '0',
  h: ['350px', null, null, null, '300px'],
  minH: ['320px', null, null, null, '300px'],
  bgImage: `url(${takeAPungBG})`,
  bgSize: ['840px 280px', 'cover'],
  bgRepeat: 'no-repeat',
  bgPos: [
    'bottom 3.5rem left -22.75rem',
    null,
    null,
    'bottom left -5rem',
    'center',
  ],
  mt: [0, null, '16px'],
  border: 0,
  justifyContent: 'flex-end',
};

export const TextVS: CSSObject = {
  borderRadius: '4px',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  display: 'flex',
  width: '31px',
  height: '31px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'var(--bc-black-alpha-800, rgba(0, 0, 0, 0.80))',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
  fontSize: '13.286px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: 'normal',
};
export const TextMisc: CSSObject = {
  fontSize: ['12px', null, '14px'],
  h: ['30px'],
  bg: 'blackAlpha.500',
  color: 'alpha.200',
  display: 'inline-flex',
  alignItems: 'center',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
};

export const TextTeam: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  textShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: ['14px', null, '18px'],
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
  textTransform: 'none',
};
export const BoxHeadingWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'center',
  marginLeft: ['auto', null, null, '45%'],
  my: ['1', null, null, null, 'auto'],
  mr: ['auto', null, null, null, '8'],
  maxW: ['450px', null, null, null, '445px'],
  w: ['unset', null, null, null, '445px'],
};
export const puntButtonProps: ButtonProps & any = {
  variant: 'primary',
  border: 'none',
  cursor: 'pointer',
  zIndex: 'dropdown',
  ...primary,
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '3',
  pt: '2',
};

export const FlexFeatureCardWrapper: CSSObject = {
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const HeaderWrapper: CSSObject = {
  color: 'alpha.400',
  textShadow: '0px 1px 24px rgba(108, 215, 1, 0.60)',
  fontFamily: 'dreadful',
  fontSize: ['52px', null, null, '80px'],
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 0.9,
  textTransform: 'uppercase',
  textAlign: 'center',
};
