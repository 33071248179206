import React from 'react';
import {
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './Propositions.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export type TFlexPropWrapper = FlexProps & { propLength?: number };
export const FlexPropWrapper = chakra(Flex, {
  label: 'propositions-FlexPropWrapper',
  shouldForwardProp: (prop) => !['propLength'].includes(prop),
  baseStyle: ({ propLength }: any) => ({
    ...(propLength > 2
      ? {
          flexDir: 'column',
          mx: '-2',
        }
      : {
          flexDir: 'row',
          gap: '1.5',
        }),
  }),
}) as ChakraComponent<'div', TFlexPropWrapper>;

export type TButtonProp = ButtonProps & {
  propLength?: number;
  isSelected: boolean;
};
function CustomButton(props: ButtonProps) {
  return <Button variant="unstyled" {...props} />;
}
export const ButtonProp = chakra(CustomButton, {
  label: 'propositions-ButtonProp',
  shouldForwardProp: (prop) => !['propLength', 'isSelected'].includes(prop),
  baseStyle: ({ propLength, isSelected }: any) => ({
    display: 'flex',
    color: 'beta.700',
    textTransform: 'none',
    fontSize: 'sm',
    fontWeight: 'medium',
    px: '2',
    py: '2',
    flex: 1,
    transition: 'all 0.2s ease-in-out',
    backgroundBlendMode: 'soft-light, normal',

    ...(propLength > 2
      ? {
          '.btn-span': { w: 'full' },
          borderRadius: 'none',
          borderBottom: '1px',
          borderBottomStyle: 'dashed',
          borderBottomColor: 'blackAlpha.200',
          bg: 'none',

          _last: {
            borderBottomColor: 'transparent',
          },

          _hover: {
            bg: `
              linear-gradient(0deg, #FFFFFF, #FFFFFF),
              linear-gradient(180deg, #636EC7 0%, #414984 100%)
            `,
          },
        }
      : {
          '.btn-span': { flexDir: 'column' },
          bg: 'linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 6.25%, #FFFFFF 100%)',
          boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.08)',
          flexDir: 'column',
          fontSize: 'xs',
          border: '1px',
          borderColor: 'gray.300',
          fontWeight: 'bold',

          _hover: {
            color: 'white',
            bg: `
              linear-gradient(0deg, #FFFFFF, #FFFFFF),
              linear-gradient(180deg, #636EC7 0%, #414984 100%)
            `,
          },

          ...(isSelected && {
            bg: 'linear-gradient(180deg, #636EC7 0%, #414984 100%)',
            color: 'white',

            _hover: { color: 'white' },
          }),
        }),

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonProp?.({
          propLength,
          isSelected,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', TButtonProp>;

export type TTextProp = TextProps & {
  propLength?: number;
  isSelected?: boolean;
  isInBS?: boolean;
};
function CustomText(props: TextProps) {
  return <Text as="span" {...props} />;
}
export const TextProp = chakra(CustomText, {
  label: 'propositions-TextProp',
  shouldForwardProp: (prop) => !['propLength', 'isSelected'].includes(prop),
  baseStyle: ({ propLength, isSelected }: any) => ({
    _first: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxW: '100%',
    },

    ...(propLength > 2
      ? {
          _last: {
            display: 'inline-block',
            fontWeight: 'semibold',
            px: '2',
            py: '2.5',
            border: '1px',
            borderColor: 'gray.300',
            borderRadius: 'md',
            boxShadow: '0px 4px 5px rgba(92, 102, 179, 0.15)',
            bg: 'linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 6.25%, #FFFFFF 100%)',
            ml: 'auto',
            lineHeight: 'none',
            minW: '62px',
            transition: 'all 0.2s ease-in-out',
          },
        }
      : {
          _last: {
            fontSize: 'sm',
          },
        }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextProp?.({
          propLength,
          isSelected,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'span', TTextProp>;
