import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'alpha.400',
};

export const RunnerDescription: CSSObject = {
  color: 'gray.400',
};

export const HeaderSvg: CSSObject = {
  bg: 'transparent',
  color: 'alpha.400',
  boxSize: '8',
  p: '1',
};

export const BetMultiIcon: CSSObject = {
  bg: 'transparent',
  boxSize: '10',
  color: 'alpha.400',
  p: '1',
};

export const BetOdds: CSSObject = {
  color: 'gray.700',
};

export const TextBarrierNumber: CSSObject = {
  color: 'alpha.400',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  bg: () => {
    switch (status) {
      case 'won':
        return 'beta.300';
      case 'lost':
        return 'delta.400';
      case 'pending':
        return 'gamma.400';
      default:
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'beta.700';
      case 'lost':
        return 'delta.50';
      case 'pending':
        return 'gamma.700';
      default:
    }
  },
});

export const headerRowStyles: HeaderRowSchema = {
  headerRowSvg: {
    fill: 'white',
  },
};
