import { getThemeName } from '@/helpers/getThemeConfig';
import { FEATURE_FLAGS } from './featureFlags';

const themeName = getThemeName();

export const PROMO_URL = (() => {
  if (FEATURE_FLAGS.IS_DEV)
    return 'https://assets.dev.betcloud.dev/mini-games/';
  if (FEATURE_FLAGS.IS_QAT)
    return 'https://assets.qat.betcloud.dev/mini-games/';
  if (FEATURE_FLAGS.IS_PERF)
    return 'https://assets.perf.betcloud.dev/mini-games/';
  if (FEATURE_FLAGS.IS_BAT)
    return 'https://assets.bat.betcloud.dev/mini-games/';
  if (FEATURE_FLAGS.IS_IN_STAGING)
    return 'https://assets.staging.release.betcloud.dev/mini-games/';
  if (FEATURE_FLAGS.IS_STAGING)
    return 'https://assets.staging.betcloud.dev/mini-games/';
  if (FEATURE_FLAGS.IS_HOTFIX)
    return 'https://assets.hotfix.betcloud.dev/mini-games/';
  return `https://assets.${themeName}.com.au/mini-games/`;
})();
