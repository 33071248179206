import api from '../api';
import {
  TPunterAccount,
  TPunterAccountMyBets,
  TPunterAccountMyBetsCount,
  TPunterAccountMyBetsProps,
  TPunterAccountOverview,
  TPunterBetRequestProps,
  TPunterAccountMyBetsCountProps,
  TPunterBetRequests,
  TZohoToken,
  TPunterCashOutValue,
  TPunterCashOutValueParams,
  TCashoutResponse,
  TCashoutRequest,
} from './punter.types';

export const punterAccountEndpoint = 'punter/account';
export const queryPunterAccount = () =>
  api.get<TPunterAccount>(punterAccountEndpoint).then((res) => res.data);

// --

export const punterAccountOverview = '/punter/account/overview';
export const queryPunterAccountOverview = () =>
  api
    .get<TPunterAccountOverview>(punterAccountOverview)
    .then((res) => res.data);

// --

export const punterAccountMyBets = '/punter/account/my-bets';
export const queryPunterAccountMyBets = (params: TPunterAccountMyBetsProps) => {
  const newParams = new URLSearchParams();

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(params)) {
    if (!Array.isArray(value)) {
      newParams.append(key, String(value));
    } else {
      for (const val of value) {
        newParams.append(key, String(val));
      }
    }
  }
  /* eslint-enable no-restricted-syntax */

  return api
    .get<TPunterAccountMyBets>(punterAccountMyBets, { params: newParams })
    .then((res) => res.data);
};

// --
export const punterAccountMyBetsCount = '/punter/account/my-bets-counts';
export const queryPunterAccountMyBetsCount = (
  params: TPunterAccountMyBetsCountProps
) =>
  api
    .get<TPunterAccountMyBetsCount>(punterAccountMyBetsCount, { params })
    .then((res) => res.data);

export const punterBetRequests = '/punter/account/bet-requests';
export const queryPunterBetRequests = (params: TPunterBetRequestProps) =>
  api
    .get<TPunterBetRequests>(punterBetRequests, {
      params,
    })
    .then((res) => res.data);

export const punterMarketsEndpoint = 'punter/races/race-details/markets';

export const punterOtherRacesEndpoint = 'punter/races/race-details/other-races';

export const punterVenueListEndpoint = 'punter/races/venue-list';

export const punterRaceMetaEndpoint = 'punter/races/race-details/race-meta';

export const punterRunnerListEndpoint = 'punter/races/race-details/runner-list';

export const punterRaceResultEndpoint = 'punter/races/race-details/race-result';

export const queryZohoToken = () =>
  api
    .get<TZohoToken>('/punter/account/zoho-token?is_mobile=false')
    .then((res) => res.data);

export const punterCashOutValueEndpoint = '/punter/cashout/value';
export const queryPunterCashOutValue = (params: TPunterCashOutValueParams) =>
  api
    .get<TPunterCashOutValue>(punterCashOutValueEndpoint, {
      params,
    })
    .then((res) => res.data);

export const punterCashOutEndpoint = '/punter/cashout';
export async function mutatePunterCashOut(body: TCashoutRequest) {
  const res = await api.post<TCashoutResponse>(punterCashOutEndpoint, body);
  return res;
}
