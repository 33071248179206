import * as yup from 'yup';

import { getStrings } from '@/helpers/utils';

const [
  {
    Onboarding: { Step1 },
  },
] = getStrings();

const forgotPasswordSchema = yup
  .object({
    email: yup
      .string()
      .trim()
      .email(Step1.EmailInputError)
      .required(Step1.EmailInputEmpty),
  })
  .required();

export type ForgotPasswordSchema = yup.InferType<typeof forgotPasswordSchema>;

export default forgotPasswordSchema;
