import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  borderRadius: 'base',
  px: '1',
  fontFamily: 'roboto',
  bg: () => {
    if (state === 'prior') {
      return 'transparent';
    }
    if (state === 'inprogress') {
      return 'gamma.600';
    }
    if (state === 'ended') {
      return 'gamma.500';
    }
  },
  color: () => {
    if (state === 'inprogress') {
      return 'beta.800';
    }
    if (state === 'ended') {
      return 'beta.800';
    }
    return 'blackAlpha.500';
  },
});

export const TextTimestamp: CSSObject = {
  fontSize: '2xs',
};
