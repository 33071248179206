import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  p: '2.5',
  bg: 'white',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  mb: '2',
  flex: '1',
};

export const SelectFieldContainer: CSSObject = {
  w: 'unset',
  gap: '1',
  mr: '0',
  'button[data-cy="fieldCheckbox"]': {
    mr: '0',
  },
};

export const CheckboxBoxed: CSSObject = {
  color: 'gray.700',
  right: 0,
  justifyContent: 'flex-end',
  alignItems: 'center',
  mr: '3',
  my: 'auto',
  w: 'auto',
  '.chakra-checkbox__control': {
    bg: 'white',
    '&[data-checked]': {
      color: 'gray.700',
      borderColor: 'gray.300',
      borderWidth: '1px',
      bg: 'white',
      _hover: {
        bg: 'gray.50',
        borderColor: 'gray.300',
      },
    },
    _hover: {
      bg: 'gray.50',
      borderColor: 'gray.300',
    },
  },
};

export const TextBoxed: CSSObject = {
  color: 'black',
  fontSize: 'sm',
};
