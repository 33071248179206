import { CSSObject } from '@chakra-ui/react';
import magicLamp from '../../../../../../assets/puntgenie/images/header/magicLamp.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxBallance: CSSObject = {
  bgSize: 'auto',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '-1',
  fontWeight: 'black',
  fontSize: 'sm',
  color: 'delta.500',
  bgGradient: 'linear(to-b, epsilon.500, epsilon.700)',
  borderBottomRadius: 'xl',
};

export const buttonDepositProps: CustomButtonProps = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  border: '2px',
  borderColor: 'delta.700',
  boxShadow: 'inset 0px 0px 0px 2px #F7D722',
  color: 'delta.800',
  borderRadius: 'lg',
  fontFamily: 'CinzelDecorative',
  textTransform: 'lowercase',
  fontSize: 'sm',
  fontWeight: 'black',
  w: 'full',

  sx: {
    '&&': {
      p: '0',
    },

    '& svg': {
      textShadow: '0px 1px 5px #2c2c2c',
    },

    _hover: { bgGradient: 'linear(to-b, delta.600, delta.700)' },
    _active: { bgGradient: 'linear(to-b, delta.600, delta.700)' },
  },
};

export const ButtonNavItem = ({ isLastItem }: any): CSSObject => ({
  bg: 'transparent',
  borderColor: 'transparent',
  fontWeight: 'medium',

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&&': {
    borderBottomWidth: '1px',
    borderBottomColor: isLastItem ? 'transparent' : 'gray.200',
  },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.50',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${magicLamp})`,
  bgSize: ['66%', null, null, null, '100%'],
  bgPos: 'absolute',
  bgRepeat: 'no-repeat',

  boxSize: '32',
  top: '6',
  left: ['50px', null, null, null, '0'],
  display: ['none', null, null, null, 'block'],
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'epsilon.500',
  color: 'white',
  borderStyle: 'none',
};

export const FlexBallanceItem: CSSObject = {
  p: {
    _last: { fontWeight: 'extrabold' },
  },
};

export const TextBalanceValue: CSSObject = {
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'black',
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  fontSize: 'xs',
  fontWeight: 'black',
  display: 'flex',
};

export const PopoverBodyQA: CSSObject = {
  bg: 'white',
};

export const PopoverContentQA: CSSObject = {
  border: ['none', '3px solid white'],
  boxShadow: 'inset 0px 0px 0px 2px var(--bc-colors-delta-300)',
  borderRadius: 'xl',
  zIndex: 'popover',
  top: '-2',
  fontFamily: 'Roboto',
  color: 'epsilon.500',
};
