import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: '10px',
  bg: 'gray.100',
};

export const RunnerName: CSSObject = {
  color: 'gray.700',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {
  color: 'whiteAlpha.500',
};

export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'gray.700',
};

export const TextDeductions: CSSObject = {
  color: 'gray.700',
};

export const TextScratchedInfo: CSSObject = {
  color: 'gray.700',
  fontSize: '2xs',
};
export const ScratchedText: CSSObject = {
  color: 'gray.700',
  fontWeight: 'semibold',
};
