import React from 'react';
import { Global } from '@emotion/core';
import bg from '@/assets/vikingbet/images/backgrounds/bg-head.png';
import bgMobile from '@/assets/vikingbet/images/backgrounds/bg-head-mobile.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bgMobile}), linear-gradient(180deg, #15669B, #125B8A)`,
          backgroundPosition: 'center top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover, 768px',
          backgroundAttachment: 'fixed',
          '@media (min-width: 768px)': {
            backgroundImage: `url(${bg}), linear-gradient(180deg, #15669B, #125B8A)`,
            backgroundPosition: 'center top, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%, 100%',
            backgroundAttachment: 'fixed',
          },
        },
      }}
    />
  );
}
