import { Formik } from 'formik';
import React, { ReactNode } from 'react';
import { useSignIn } from '@/hooks/useSignIn';
import loginSchema, {
  LoginSchema,
} from '../../../services/schemas/loginSchema';

type TFormHOC = {
  children: ReactNode;
  onSubmit?: any;
};

export default function FormHOC({ children, onSubmit }: TFormHOC) {
  const { handleSignIn } = useSignIn();

  return (
    <Formik<LoginSchema>
      initialValues={{ email: '', password: '' }}
      validationSchema={loginSchema}
      onSubmit={onSubmit || handleSignIn}
    >
      {children}
    </Formik>
  );
}
