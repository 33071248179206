import { CompetitionBySportV2Schema } from '@/views/sports/CompetitionsBySport/styles/CompetitionBySportV2.styles';

export const competitionBySportV2Styles: CompetitionBySportV2Schema = {
  containerNav: { mb: '2' },
  scrollButtonGroup: {
    mb: '2',
    bg: 'alpha.600',
    variant: 'filter',
  },
  scrollButtonGroupDivider: {
    mb: '2',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
  competitionListBoxWrapper: {
    w: 'full',
  },
};
