import { useQuerySupportedBets } from '@/api/punter/supportedBets/supportedBets.hooks';
import { getMarketByType, isPendingAndMatchingId } from '@/helpers/utils';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  TPriceType,
  TRunner,
} from '@/lib/DBModels';
import { useAppSelector } from './useRedux';

type TUseOddsResult = {
  odds?: number | 'SP' | 'MD' | 'SUS' | 'BT' | null;
  isEnabled: boolean;
  isTote?: boolean;
};

export function useOdds(
  runner: TRunner,
  betType: EBetTypesDisplayNames,
  isTote: boolean
): TUseOddsResult {
  const { bets } = useAppSelector(({ betSlip }) => betSlip);
  const { raceRunnerList } = useAppSelector(({ racing }) => racing);
  const { raceMeta, markets } = raceRunnerList;
  const market = getMarketByType(markets ?? [], betType);

  let priceType: TPriceType;

  if (market && !market.price_types?.includes('fixed') && !isTote) {
    priceType = 'starting';
  } else if (market?.price_types?.includes('tote_single_mid') && isTote) {
    priceType = 'tote_single_mid';
  } else if (market?.price_types?.includes('tote_single_best') && isTote) {
    priceType = 'tote_single_best';
  } else {
    priceType = 'fixed';
  }

  const key = betType === EBetTypesDisplayNames.RacingWin ? 'win' : 'place';
  const proposition = runner[`${key}_proposition`];

  let odds: number | 'SP' | 'MD' | 'SUS' | 'BT' | null;

  if (priceType === 'starting') {
    odds = 'SP';
  } else if (priceType === 'tote_single_mid') {
    odds = 'MD';
  } else if (priceType === 'tote_single_best') {
    odds = 'BT';
  } else {
    odds = runner[`${key}_odds`] ?? 1;
  }

  const { data } = useQuerySupportedBets();

  // Closed races
  if (raceMeta?.status !== EGeneralStatus.Open) {
    return { odds: odds ?? 'SUS', isEnabled: false };
  }

  // Closed markets
  if (!market || market.status === EGeneralStatus.Closed) {
    return { odds: odds ?? 'SUS', isEnabled: false };
  }

  // Closed runners
  if (runner.status !== EGeneralStatus.Open) {
    return { odds: odds ?? 'SUS', isEnabled: false };
  }

  // Closed and pending bets
  if (
    proposition?.status !== EGeneralStatus.Open ||
    isPendingAndMatchingId(bets, proposition.proposition_id, isTote)
  ) {
    return { odds: odds ?? 'SUS', isEnabled: false };
  }

  // SP
  if (priceType === 'starting') {
    const spBetsAreSupported = data?.starting_price !== false;
    const isEnabled = !proposition.is_suspended && spBetsAreSupported;

    return { odds: isEnabled ? odds : 'SUS', isEnabled };
  }

  if (priceType === 'tote_single_mid') {
    const isSupported = data?.tote_singles !== false;

    return { odds: 'MD', isEnabled: isSupported };
  }

  if (priceType === 'tote_single_best') {
    const isSupported = data?.tote_singles !== false;

    return { odds: 'BT', isEnabled: isSupported };
  }

  const isEnabled = !proposition.is_suspended;
  return { odds: isEnabled ? odds : 'SUS', isEnabled };
}
