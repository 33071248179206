import useAxios from 'axios-hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  signUserOut,
  verifyUsersPassword,
} from '../../../../lib/firebase/Authentication';
import {
  clearPunterOverview,
  clearPunterProfile,
  reset,
} from '../../services/account.slices';
import { AppDispatch } from '../../../../redux/store';
import { TCloseAccountResponse } from '../types';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';
import { postMobileAppMessage } from '../../../../mobileapp/mobileapp.utils';
import { ENewRelicActions } from '../../../../constants/newRelicActions';
import { newRelicLog } from '@/lib/newRelic';
import { useUserData } from '@/store/AuthStore';
import { useAuth } from '@/hooks/useAuth';

export const useCloseAccount = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const authUser = useUserData();
  const { punterData } = useAuth();
  const client = useQueryClient();

  const [{ loading }, mutate] = useAxios<TCloseAccountResponse, null>(
    {
      method: 'POST',
      url: '/punter/account/close',
    },
    { manual: true, useCache: false }
  );

  const doCloseAccount = async (password: string) => {
    if (!IS_MOBILE_APP) {
      if (!authUser?.email) {
        return false;
      }

      const passwordValid = await verifyUsersPassword(
        authUser?.email,
        password
      );

      if (!passwordValid) return false;
    }

    await mutate({});

    await signUserOut().then(() => {
      newRelicLog(ENewRelicActions.LogOut, punterData?.punter_id, {
        status_code: 200,
      });
      dispatch(clearPunterProfile());
      dispatch(clearPunterOverview());
      dispatch(reset());
    });

    await client.invalidateQueries();

    if (IS_MOBILE_APP) postMobileAppMessage('signout');

    navigate('/signed-out');

    return true;
  };

  return [doCloseAccount, { loading }] as const;
};
