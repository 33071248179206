import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: (() => {
      if (state === 'ended') return 'alpha.800';
      if (state === 'inprogress') return 'alpha.300';
      return 'blackAlpha.500';
    })(),
    color: state === 'inprogress' ? 'alpha.800' : 'white',
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
    padding: '3px 4px',
    fontSize: '2xs',
    fontWeight: 'bold',
    borderRadius: 'base',
    textShadow: 'none',
  },
});

export const TextTimestamp = {
  fontWeight: 'semibold',
  fontSize: '2xs',
};
