import { SRMultiStylesSchema } from './SRMulti.styles';

export const srMultiStyles: SRMultiStylesSchema = {
  buttonLabel: {
    color: 'gray.700',
  },
  propButton: {
    w: '50px',
  },
  columnHeadings: {
    w: '48px',
    textAlign: 'center',
    fontSize: 'xs',
    color: 'gray.700',
    fontWeight: 'medium',
  },
  headingLabel: {
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  headingContainer: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'medium',
    marginRight: '1',
  },
  runnerList: {
    borderRadius: 'base',
    bg: 'white',
    p: '2.5',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  headingStacker: {
    rowGap: '2',
  },
};
