import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
  h: '10',
  sx: {
    ':hover': {
      color: 'whiteAlpha.700',
    },
  },
};

export const IncreaseAmountWrapper: CSSObject = {
  mb: 0,
  gap: '1.5',
};
