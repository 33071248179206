/* eslint-disable @typescript-eslint/no-use-before-define */
import { Show } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useBetsCount } from '../../../Betslip/Services/Betslip.hooks';
import {
  ButtonBetSlip,
  TextButton,
  TextButtonNumber,
} from '../styles/Header.styles';
import { transformBetForLegacy, useBetSlipBets } from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { TBetSlipBet } from '@/components/Betslip/Services/Betslip.types';
import { setBetSlipOpen } from '@/redux/Betslip.slices';

/**
 * Containers
 */
const BetSlipBannerReduxContainer = () => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <BetSlipBanner bets={bets} />;
};

const BetSlipBannerZustandContainer = () => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <BetSlipBanner bets={bets} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? BetSlipBannerZustandContainer
  : BetSlipBannerReduxContainer;

/**
 * View
 */

type BetSlipBannerProps = {
  bets: TBetSlipBet[];
};

function BetSlipBanner({ bets }: BetSlipBannerProps) {
  const { validBetsCount } = useBetsCount({ bets });
  const dispatch = useAppDispatch();

  return (
    <Show below="xl">
      <ButtonBetSlip
        data-cy="betSlipCount"
        className="betslipBtn"
        onClick={() => dispatch(setBetSlipOpen(true))}
      >
        <TextButton>
          <FormattedMessage id="header.betslipBtn.desc" />
        </TextButton>
        <TextButtonNumber data-cy="betCount" className="textButtonNumber">
          {validBetsCount}
        </TextButtonNumber>
      </ButtonBetSlip>
    </Show>
  );
}
