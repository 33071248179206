import { BorderStyles } from '../../../types';

// TODO: Update theme colour values
const defBorderStyles: BorderStyles = {
  primary: '1px solid #',
  secondary: '1px solid #',
  tertiary: '1px solid #',
  error: '1px solid #',
  disabled: '1px solid #',
};

export default defBorderStyles;
