import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import {
  BetSlipEmptyContainer,
  BetSlipEmptyHeading,
  betSlipEmptyHeadingSuffix,
  BetSlipEmptyResponsibleGamblingWrapper,
  BetSlipEmptySubheading,
  BetSlipEmptyWrapper,
  BoxHeadingIconContainer,
} from './styles/Empty.styles';
import BetSlipTemplate from './EmptyBetslipIcon/templates/template';
import { createTemplate } from '@/helpers/createTemplate';
import ResponsibleGamblingBanner from '../../../../ResponsibleGamblingBanner/ResponsibleGamblingBanner';
import { EResponsibleGamblingBannerLocation } from '../../../../ResponsibleGamblingBanner/services/ResponsibleGamblingBanner.types';

// To Do. Refactor styles

const Empty: React.FC = () => (
  <BetSlipEmptyWrapper>
    <BetSlipEmptyContainer>
      <BoxHeadingIconContainer>
        <BetSlipEmptyHeading>
          <FormattedMessage
            id="betslip.betslipmodal.emptybetslipheading"
            values={{
              suffix: (msg) => (
                <Text {...betSlipEmptyHeadingSuffix}>{msg}</Text>
              ),
            }}
          />
        </BetSlipEmptyHeading>

        {createTemplate(BetSlipTemplate)}
      </BoxHeadingIconContainer>

      <BetSlipEmptySubheading>
        <FormattedMessage id="betslip.betslipmodal.emptybetslipsubheading" />
      </BetSlipEmptySubheading>
    </BetSlipEmptyContainer>
    <BetSlipEmptyResponsibleGamblingWrapper>
      <ResponsibleGamblingBanner
        bannerLocation={EResponsibleGamblingBannerLocation.BetslipFooter}
      />
    </BetSlipEmptyResponsibleGamblingWrapper>
  </BetSlipEmptyWrapper>
);

export default Empty;
