import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'whiteAlpha.900' },
  flexWrapper: {
    flexDir: 'row',
  },
  checkbox: {
    my: 'auto',
  },
};
export const TabContainer: CSSObject = {
  borderBottom: '0',
  bg: ['beta.800', null, 'beta.700'],
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200), 0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  borderRadius: 'md',
  px: '4',
};
export const buttonAccountTabOptionProps: CustomButtonProps = {
  variant: 'tab',
  color: 'white',
  pb: '0',
  bg: 'none',
  sx: {
    '&[aria-selected="true"]': {
      color: 'beta.500',
      fontWeight: 'normal',
      border: 'none',
      borderBottom: '3px solid',
      borderBottomColor: 'beta.500',
    },
  },
};
