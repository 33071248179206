import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  '> div': {
    maxW: 'full',
  },
  bg: 'transparent',
  p: '0',
  '.chakra-checkbox__label': {
    color: 'beta.800',
    fontWeight: 'medium',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    borderRadius: 'sm',
    border: '1px solid',
    borderColor: 'gray.400',
    _hover: {
      opacity: 1,
      borderColor: 'beta.800',
    },
    _checked: {
      borderColor: 'beta.800',
    },
  },
};
