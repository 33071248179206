import { CSSObject, keyframes } from '@chakra-ui/react';
import astronaut from '../../../../../../assets/betgalaxy/images/homeFeaturedRacing/astronaut.svg';
import galaxy from '../../../../../../assets/betgalaxy/images/homeFeaturedRacing/galaxy.png';

const pulse = keyframes`
    0% { transform: scale(1.1); }
    50% { transform: scale(1.5); }
    100% { transform: scale(1.1); }
`;

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: `url(${galaxy})`,
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    pos: ['unset', 'absolute'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    animation: `${pulse} 10s ease infinite`,
    zIndex: '-1',
  },

  flex: '2',
  border: '3px solid',
  borderColor: 'beta.500',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: `url(${astronaut})`,
    display: ['none', null, 'block'],
    bgRepeat: 'no-repeat',
    bgSize: '85%',
    bgPos: 'bottom',
    pos: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
  },

  justifyContent: 'flex-start',
  pt: ['0', null, '7'],
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
};

export const TextFeatureSubHeading: CSSObject = {
  color: 'white',
  fontSize: ['2xl', null, '3xl'],
  letterSpacing: '6px',
};

export const TextFeatureHeading: CSSObject = {
  color: 'white',
  fontSize: ['md', null, '2xl'],
  fontWeight: 'normal',

  letterSpacing: '9px',
  borderTop: '2px solid',
  borderBottom: '2px solid',
  borderColor: 'white',
  zIndex: '-1',
};

export const TextFeatureStyledHeading: CSSObject = {
  fontSize: ['2xl', null, '3xl'],
  color: 'alpha.400',
};
