import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logoDesktop from '@/assets/sugarcastle/images/logos/logoSC.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image src={logoDesktop} alt={THEME_NAME.fiestabet} mx="auto" pb={3} />
      </Show>
      <Hide above="lg">
        <Image src={logoDesktop} alt={THEME_NAME.fiestabet} pt={1.5} />
      </Hide>
    </Link>
  );
}
