import { CSSObject } from '@chakra-ui/react';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    fontFamily: 'body',
    textTransform: 'capitalize',
    w: 'auto',
    bgGradient: 'linear(to-b, alpha.700, alpha.800)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
    textShadow: 'none',
    color: 'gamma.400',
    borderRadius: 'md',
    fontSize: '2xs',
    fontWeight: 'medium',
    border: 'none',
    h: '6',
    px: '1.5',

    sx: {
      '&&': {
        minW: '20',
      },

      '&&[data-isbonusbet="true"]': {
        bgGradient: 'linear(to-b, alpha.800, alpha.800)',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
        textShadow: 'none',
        color: 'alpha.50',

        _disabled: {
          w: '100%',
          bg: 'blackAlpha.400',
          color: 'gamma.400',
          textShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.20)',
          fontSize: '2xs',
          fontWeight: 'medium',
          textTransform: 'uppercase',
          boxShadow: '0px 1px 0px 0px #4395D0',
        },
      },
    },
  },
  buttonBetPropositionClosedProps: {},
  buttonStakeProps: {
    variant: 'secondary',
    fontSize: '13px',
    width: '45px',

    _focus: {
      borderWidth: '2px',
      borderColor: 'white',
    },
  },
  spanRaceInfo: {
    color: 'white',
  },
  iconMysteryBetFooter: {
    color: 'gamma.900',
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    lineHeight: 'normal',
    w: '100%',
    borderRadius: 'md',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'delta.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    px: 3,
    py: 1.5,
    fontSize: 'xs',
  },
  flexWrapperMysteryBetFooterBetReview: {
    fontSize: 'xs',
    w: '100%',
    borderRadius: 'md',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'delta.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    py: 1.5,
  },
  iconBetCardSvgMysteryBet: {
    sx: {
      '&&': {
        color: 'gamma.900',
        mr: 0,
      },
    },
  },
};

export const BetCardContainer = (): CSSObject => ({
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  borderWidth: '2px',
  borderColor: 'whiteAlpha.800',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  color: 'gamma.900',
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'gamma.900',
  mr: '-1',
};

export const ExoticsNumberContainer: CSSObject = {
  w: '6 ',
};

export const InfoBetOdds: CSSObject = {
  color: 'gamma.900',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'gamma.900',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'gamma.900',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  fontWeight: 'medium',
  color: 'gamma.900',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const EventRule: CSSObject = {
  color: 'gamma.900',
};

export const BetCardVStack: CSSObject = {
  pb: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'gray.600',
  px: '2',
  fontSize: '2xs',
  fontWeight: 'medium',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeCollapse: CSSObject = {
  '&&': {
    m: '0',
    mt: '2',
  },
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'gamma.900' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const BetPropositionClosedError: CSSObject = {
  bg: 'whiteAlpha.500',
  borderRadius: 'base',
  position: 'absolute',
  top: '-2px',
  left: '-2px',
  w: 'calc(100% + 4px)',
  h: 'calc(100% + 4px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: 'docked',
};
