import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/goldenbet888/images/backgrounds/onboardingAside.png';
import bgMobile from '@/assets/goldenbet888/images/backgrounds/onboardingAsideMb.png';

export const WrapperAside: CSSObject = {
  bg: [`url(${bgMobile})`, null, `url(${bgDesktop}), rgba(84, 3, 1, 1)`],
  bgSize: ['cover', null, 'contain'],
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['gamma.600', null, 'unset'],
  h: ['50vw', '50vw', 'full'],
  borderLeftRadius: [null, null, '10px'],
  borderRightRadius: ['unset'],
  pt: ['4', null, '6'],
  flex: 1,
  a: {
    img: {
      pos: 'absolute',
      maxW: ['0', null, '210px'],
      top: ['100px', '120px', '125px'],
      left: ['200px', '150px', '150px'],
      transform: 'translate(-50%, -50%)',
    },
  },
  fontFamily: 'body',
};
