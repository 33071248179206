import {
  Flex,
  chakra,
  CSSObject,
  Text,
  ChakraComponent,
  ButtonProps,
} from '@chakra-ui/react';
import IconSvg from '../../../../../../common/IconSvg';
import { Button } from '@/components/Button/Button';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Balances.styles.imports';

type TOverrideName =
  | 'TextCashBalanceCents'
  | 'TextCurrencySign'
  | 'ButtonShowHideBalances'
  | 'FlexBalances'
  | 'TextShowHideBalancesBtn'
  | 'IconShowHideBalancesBtn'
  | 'FlexCashBalance'
  | 'TextCashBalanceDollars'
  | 'FlexBonusBalance'
  | 'TextBonusBalanceDesc'
  | 'TextBonusBalanceAmount';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};

// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const ButtonShowHideBalances: ChakraComponent<'div', ButtonProps> =
  chakra(Button, {
    baseStyle: () => ({
      bg: 'transparent',
      color: 'beta.800',
      textTransform: 'uppercase',
      gap: '1.5',
      w: 'fit-content',

      ...styles?.ButtonShowHideBalances,
    }),
  });

export const TextShowHideBalancesBtn = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'extrabold',
    letterSpacing: 'widest',

    ...styles?.TextShowHideBalancesBtn,
  }),
});

export const IconShowHideBalancesBtn = chakra(IconSvg, {
  baseStyle: () => ({
    boxSize: '4',

    ...styles?.IconShowHideBalancesBtn,
  }),
});

export const FlexBalances = chakra(Flex, {
  baseStyle: () => ({
    flexDirection: 'column',
    color: 'beta.800',
    alignItems: 'center',

    ...styles?.FlexBalances,
  }),
});

export const FlexCashBalance = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5',
    flexDirection: 'row',
    color: 'beta.800',
    fontWeight: 'extrabold',
    boxSize: 'full',

    ...styles?.FlexCashBalance,
  }),
});

export const TextCurrencySymbol = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xl',
    marginBottom: '2',

    ...styles?.TextCurrencySign,
  }),
});

export const TextCashBalanceDollars = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xx-large',

    ...styles?.TextCashBalanceDollars,
  }),
});

export const TextCashBalanceCents = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'md',
    marginTop: '2',

    ...styles?.TextCashBalanceCents,
  }),
});

export const FlexBonusBalance = chakra(Flex, {
  baseStyle: () => ({
    gap: '2',
    px: '3',
    py: '1',
    flexDirection: 'row',
    bg: 'beta.700',
    boxShadow: '0px 1px 0px #FFFFFF, inset 0px 1px 5px rgba(0, 0, 0, 0.4)',
    borderRadius: '3xl',
    fontWeight: 'bold',
    letterSpacing: 'widest',
    fontSize: 'sm',
    ...styles?.FlexBonusBalance,
  }),
});

export const TextBonusBalanceDesc = chakra(Text, {
  baseStyle: () => ({
    color: 'white',
    textTransform: 'uppercase',
    ...styles?.TextBonusBalanceDesc,
  }),
});

export const TextBonusBalanceAmount = chakra(Text, {
  baseStyle: () => ({
    color: 'yellow.400',
    ...styles?.TextBonusBalanceAmount,
  }),
});
