import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  m: '6px',
  mb: 0,
  p: 0,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.4)',
};
export const CardContainer: CSSObject = {
  border: 'none',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'md',
  bg: 'alpha.900',
  p: 1.5,
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  mx: '6px',
  'hr[data-testid*="dividerExoticLastProps"]': {
    borderBottom: '1px dashed',
    borderBottomColor: 'rgba(0, 0, 0, 0.4)',
    mx: 0,
  },
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  display: 'flex',
  '&': {
    px: 0,
  },
  alignItems: 'start',
  '> svg': {
    color: 'beta.400',
    fill: 'beta.400',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  lineHeight: '18px',
};
export const dividerExoticProps: DividerProps = {
  display: 'none',
};
