import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
  isOpen: boolean;
};

const initialState: TInitialState = {
  isOpen: false,
};

const sidenavSlice = createSlice({
  name: 'sidenav',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean | undefined>) => ({
      isOpen: action?.payload || !state.isOpen,
    }),
  },
});

export const { setIsOpen } = sidenavSlice.actions;
export default sidenavSlice.reducer;
