import { CSSObject } from '@chakra-ui/react';
import gill from '@/assets/oldgill/images/switch/gill.png';

export const FlexWrapper = {
  borderRadius: 'md',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: ['1.5', null, 'unset'],
  mb: ['2', null, '3'],
  mt: '1',
  gap: '2',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  h: '80px',
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'Bowlby One',
  fontWeight: 'extrabold',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  color: isActive ? 'beta.400' : 'white',
  letterSpacing: 'widest',
  px: '2',
});

export const Switcher = (): CSSObject => ({
  bg: 'transparent',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${gill})`,
      boxSize: '20',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-5',
      right: '0',
      top: '-5',
    },
    '&[data-checked]': { left: '1.5' },
  },
  '.chakra-switch__track': {
    boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
    bg: 'blackAlpha.600',
    h: '39px',
    w: '66px',
  },
});
