import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import {
  EBetSlipBetSubmissionType,
  EBetSubmissionConfirmationStatus,
  TBetSlipBet,
  TBetSlipBetEachWay,
  TBetSlipBetMulti,
  TBetSlipBetSGMulti,
  TBetSlipBetSingle,
  TPromoToken,
} from '@/components/Betslip/Services/Betslip.types';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  ERaceType,
  TMarket,
  TProposition,
} from '@/lib/DBModels';
import STRINGS from '../localisation/Strings/en';
import { getThemeName } from './getThemeName';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { FIREBASE_PROJECTID } from '@/lib/Constants';
import { TLinks } from '@/api/cms/cms.types';
import pdf from './pdf/template';

dayjs.extend(utc);
dayjs.extend(tz);

export const getStrings = (): [typeof STRINGS] => [STRINGS];

export const getIconBySportName = (sportName: string | undefined) => {
  if (!sportName || sportName === '' || sportName === undefined) {
    return 'sports/default';
  }
  return `sports/${sportName.replace('/ /g', '_').toLowerCase()}`;
};

export const getDisplayNameByRaceType = (
  raceType: ERaceType
): string | undefined => {
  const [{ Home: Strings }] = getStrings();
  switch (raceType) {
    case ERaceType.Greyhound:
      return Strings.GreyhoundsHeading;
    case ERaceType.Horse:
      return Strings.HorsesHeading;
    case ERaceType.Harness:
      return Strings.HarnessHeading;
    default:
      return undefined;
  }
};

export const centsToDollarsNumber = (cents: number | undefined): number => {
  if (cents === undefined) return 0;
  return cents / 100;
};

export const centsToDollars = (
  cents: number | undefined | null,
  withCurrencySymbol = true
) => {
  if (cents === 0) {
    if (withCurrencySymbol) return '$0.00';
    return '0.00';
  }
  if (!cents) return '$0.00';
  const dollars = cents / 100;
  let dollarString: string;
  if (withCurrencySymbol) {
    dollarString = dollars.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD',
    });
  } else {
    dollarString = dollars.toLocaleString('en-AU', {
      minimumFractionDigits: 2,
    });
  }
  return dollarString;
};

export const dollarsToCents = (dollars: string | number) => {
  let cents = 0;
  if (typeof dollars === 'string') {
    let tempDollars = dollars;
    // if its a string, check first index for $ to remove, otherwise parse to number
    if (dollars[0] === '$')
      tempDollars = tempDollars.substring(1, tempDollars.length);

    cents += Number(tempDollars) * 100;
  } else {
    cents += dollars * 100;
  }
  return Math.round(cents);
};

export const formatStake = (stake: number | string) =>
  Number(stake)
    .toLocaleString('en-AU', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'AUD',
    })
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/** Orders race types to always be in order of Horse, Greyhound, Harness regardless if some are missing */
export const sortedRaceOfferings = (
  offeredRaceTypes: (ERaceType | undefined)[] | undefined
) => {
  if (!offeredRaceTypes) return [];

  const orderedRaceType = {
    [ERaceType.Horse]: 1,
    [ERaceType.Greyhound]: 2,
    [ERaceType.Harness]: 3,
  };
  const offeredTypes = [...offeredRaceTypes];

  return offeredTypes
    .filter((a) => !!a)
    .map((a) => a as ERaceType)
    .sort((a, b) => orderedRaceType[a] - orderedRaceType[b]);
};

// This returns the odds. Not all bet type support odds hence we only show single odds right now
export const betOdds = (
  bet?: TBetSlipBet,
  showBonusChance?: boolean,
  oddsChanged?: boolean,
  oddsBoostToken?: TPromoToken | undefined
): string | null => {
  // For all types that support odds - return below
  if (bet?.type === EBetSlipBetSubmissionType.Single) {
    const betWOdds = bet as TBetSlipBetSingle;

    if (oddsBoostToken && !showBonusChance) {
      return oddsBoostToken.boosted_odds?.toFixed(2) ?? '';
    }

    if (oddsChanged) {
      if (bet.confirmation?.haveOddsIncreasedAfterSubmit) {
        return bet.odds?.toFixed(2) ?? '';
      }
      const newOdds = betWOdds?.confirmation?.changed_odds?.find(
        (change) => change.proposition_id === betWOdds.proposition_id
      )?.new_odds;
      return `${newOdds?.toFixed(2)}`;
    }

    return `${betWOdds?.odds?.toFixed(2)}`;
  }

  if (bet?.type === EBetSlipBetSubmissionType.EachWay) {
    const betWOdds = bet as TBetSlipBetEachWay;

    return `${betWOdds?.win_odds?.toFixed(2)} / ${betWOdds?.place_odds?.toFixed(
      2
    )}`;
  }

  if (bet?.type === EBetSlipBetSubmissionType.SGMulti) {
    const betWOdds = bet as TBetSlipBetSGMulti;

    if (oddsChanged) {
      const newOdds = betWOdds.confirmation?.changed_odds?.[0].new_odds;
      return `${newOdds?.toFixed(2)}`;
    }

    return `${betWOdds?.odds?.toFixed(2)}`;
  }

  return null;
};

export const logError = (error: Error | string | unknown): void => {
  // eslint-disable-next-line no-console
  console.warn(error);
};

// Typescript helper to force either one of two optional props to be passed
export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export const renderDateMMDDYYYY = (date: Dayjs) => date.format('DD/MM/YYYY');

export const isPendingAndMatchingId = (
  bets: TBetSlipBet[],
  propositionId?: string,
  isTote?: boolean
) => {
  const hasSingleBet = bets
    .filter(
      (bet) =>
        bet.type === EBetSlipBetSubmissionType.Single &&
        bet.confirmation?.status === EBetSubmissionConfirmationStatus.Pending
    )
    .some(
      (bet) =>
        bet.proposition_id === propositionId &&
        (isTote
          ? bet.price_type === 'tote_single_mid'
          : bet.price_type !== 'tote_single_mid')
    );

  if (hasSingleBet) {
    return true;
  }

  // Multi
  return bets
    .filter(
      (bet) =>
        bet.type === EBetSlipBetSubmissionType.Multi &&
        bet.confirmation?.status === EBetSubmissionConfirmationStatus.Pending
    )
    .some((bet) => {
      const multiBet = bet as TBetSlipBetMulti;
      return multiBet.legs.some((leg) => leg.proposition_id === propositionId);
    });
};

export const getPDF = () => ({
  policy: pdf.PrivacyPolicy,
  tc: pdf.TermsAndConditions,
});

const projectId = FIREBASE_PROJECTID;

export const getURLBasedOnLocation = () => {
  const brandName = getThemeName().toLowerCase();

  if (FEATURE_FLAGS.IS_DEV) {
    // Return local URL
    return 'dev.betcloud.dev/dev';
  }
  if (FEATURE_FLAGS.IS_QAT) {
    // Return qat URL
    return 'qat.betcloud.dev/qat';
  }
  if (FEATURE_FLAGS.IS_IN_STAGING) {
    // Return qat URL
    return `staging.release.betcloud.dev/${projectId
      ?.split('-')[0]
      .toLowerCase()}`;
  }
  if (FEATURE_FLAGS.IS_STAGING) {
    // Return qat URL
    return 'staging.betcloud.dev/staging';
  }
  if (FEATURE_FLAGS.IS_HOTFIX) {
    // Return qat URL
    return 'hotfix.betcloud.dev/hotfix';
  }
  if (FEATURE_FLAGS.IS_PERF) {
    // Return perf URL
    return 'perf.betcloud.dev/perf';
  }
  if (FEATURE_FLAGS.IS_BAT) {
    // Return bat URL
    return 'bat.betcloud.dev/bat';
  }
  // Default PROD URL if none of the conditions match
  return `${brandName}.com.au/${brandName}`;
};

export const getMarketByType = (
  markets: TMarket[],
  marketType: EBetTypesDisplayNames
) => markets.find(({ market_type }) => market_type === marketType);

export const marketHasSPOnly = (market: TMarket) => {
  const priceTypes = market.price_types ?? [];
  return priceTypes.includes('starting');
};

export const propIsSuspended = (prop?: TProposition, odds?: number | null) =>
  (prop?.is_suspended && prop.status === EGeneralStatus.Open) ||
  ((!odds || odds <= 1) &&
    (prop?.status === EGeneralStatus.Closed ||
      prop?.status === EGeneralStatus.Abandoned ||
      prop?.status === EGeneralStatus.Settled));

export const getSportTeamName = (name: string, side: 'home' | 'away') => {
  const nameSplit = name.includes(' vs ')
    ? name.split(' vs ')
    : name.split(' v ');

  return side === 'home' ? nameSplit[0] : nameSplit[1];
};

export const startPassedEndNotPassed = (event: TLinks) => {
  const now = dayjs();
  const timeZone = dayjs.tz.guess();

  if (!event.start_date && !event.end_date) {
    // If start_date or end_date is not provided, consider the event valid
    return true;
  }

  // Convert UTC start_date and end_date to local time
  const startDateTime = event.start_date
    ? dayjs.utc(event.start_date).tz(timeZone)
    : null;
  const endDateTime = event.end_date
    ? dayjs.utc(event.end_date).tz(timeZone)
    : null;

  const startPassed = startDateTime ? startDateTime.isBefore(now) : false;
  const endNotPassed = endDateTime ? endDateTime.isAfter(now) : false;

  if (!endDateTime) {
    return startPassed;
  }

  if (!startDateTime) {
    return endNotPassed;
  }
  return startPassed && endNotPassed;
};

export const getPositionValue = (
  position: string | undefined
): string | undefined => {
  const POSITION_MAP: Record<string, string> = {
    Top1: 'Win',
    Top2: 'Top 2',
    Top3: 'Top 3',
    Top4: 'Top 4',
  };
  return POSITION_MAP[position ?? ''];
};
