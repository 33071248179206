import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 500,
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  '& > span': { color: 'beta.400' },
};

export const TextDescription: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'blackAlpha.400',
  borderStyle: 'solid',
  borderWidth: '1px',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

/**
 * Form Inputs
 */
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'normal',
    fontSize: 'sm',
  },
};

export const InputWithdrawal: CSSObject = {
  '&&': {
    bg: 'white',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'sm',
    height: '38px',
    color: 'alpha.900',
    _placeholder: {
      color: 'gray.700',
    },
    '&&:hover': {
      bg: 'gray.100',
      borderColor: 'gray.400',
    },
    '&&:focus': {
      bg: 'gray.50',
      borderColor: 'blue.300',
      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'gray.500',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '&&': {
    '.chakra-input__left-addon': {
      bg: 'alpha.600',
      color: 'white',
      fontSize: 'sm',
      height: '38px',
      border: 'none',
      borderLeftRadius: 'md',
    },
    '.chakra-input__group': {
      w: '75%',
    },
    '.chakra-input': {
      borderLeftRadius: 'none',
      bg: 'white',
      borderColor: 'gray.300',
      borderWidth: '1px',
      color: 'alpha.900',
      borderRightRadius: 'md',
      borderLeft: 'none',
      _hover: {
        bg: 'gray.100',
        borderColor: 'gray.400',
      },
      _focus: {
        bg: 'gray.50',
        borderColor: 'blue.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },
      _placeholder: {
        color: 'gray.700',
      },
    },
  },
};

/**
 * Buttons
 */

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderColor: 'blackAlpha.400',
  borderStyle: 'solid',
  borderBottom: '1px',
};

export const BankRadio: CSSObject = {
  alignItems: 'start',
  border: 'none',
  bg: 'gray.200',
  '&[data-checked]:before': {
    display: 'flex',
    alignSelf: 'center',
    width: '40%',
    height: '40%',
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
  color: 'white',
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  w: '100%',
  p: '0',
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

/**
 * Withdrawal Request Card
 */
export const WithdrawalWrapper: CSSObject = {
  bg: 'white',
  border: '1px solid',
  borderColor: 'gamma.100',
  fontSize: 'sm',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gamma.100',
  color: 'gray.700',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  bg: 'alpha.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  borderRadius: 'base',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 800,
  fontSize: 'xs',
  color: 'beta.400',
  fontFamily: 'accent',
  _hover: {
    bg: 'alpha.500',
  },
  _active: {
    bg: 'alpha.500',
  },
};

export const WithdrawalStatus = (): CSSObject => ({
  borderRadius: 'base',
  px: '2',
});

export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'secondary',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  fontFamily: 'accent',
};

export const BackButton: CSSObject = {
  _hover: {
    bg: 'transparent',
  },
};

export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};

export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '1.5',
  variant: 'secondary',
};
