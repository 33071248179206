import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: { fontSize: '2xs', p: '2' },
};

export const FlexInner: CSSObject = {
  borderBottomColor: 'gray.300',
  minH: '12',
  py: '2',
};

export const TextMonth: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  color: 'white',
};
