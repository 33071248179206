import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bg: 'white',
  '&&': { px: '2', py: [2, 2, 3], pb: ['1', null, '2'] },
  mt: ['unset', '2'],
  borderTopRadius: 'base',
  overflow: 'visible',
  color: 'alpha.700',
  fontSize: 'md',
  pb: '0',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'alpha.700',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  bg: 'unset',
  color: 'beta.500',
  m: '2',
  borderRadius: [null, null, null, 'base'],
};

export const WrapperContainer: CSSObject = {
  px: '0',
  bg: 'white',
};
