import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { spacing: '1', pb: '1' },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    lineHeight: 'tall',
    bgGradient: 'linear(to-b, #E3E3E3 0%, #F2F2F2 100%)',
    boxShadow:
      '0px 2px 0px 0px #FFF, 0px 2px 5px 0px rgba(21, 24, 56, 0.35), 0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
    color: 'beta.700',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.20)',
    fontWeight: 'bold',
    fontSize: '2xs',
    sx: {
      '&.active': {
        bg: 'accent.500',
        color: 'white',
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
        svg: {
          color: 'white',
        },
      },
    },
  },
};
