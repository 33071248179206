import React, { ReactElement } from 'react';
import { Show } from '@chakra-ui/react';
import ButtonToggle from '../components/ButtonSwitch/ButtonToggle';
import { FlexWrapper } from '../styles/Toggle.styles';

const Wrapper = ({ children }: { children?: ReactElement }) => (
  <Show below="md">
    <FlexWrapper>{children}</FlexWrapper>
  </Show>
);

export const TemplateToggle = [
  { name: 'wrapper', component: <Wrapper /> },
  { name: 'buttonToggle', component: <ButtonToggle /> },
];

export default TemplateToggle;
