import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textTransform: 'uppercase',
  fontSize: ['2xl', '38px'],
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  textAlign: 'center',
  color: 'beta.400',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  w: ['288px', null, '420px'],
  textAlign: 'center',
  p: {
    w: '100%',
    fontWeight: 'normal',
  },
  fontFamily: 'accent',
};

export const ImageNoRaces: CSSObject = {
  w: ['300px', null, '500px'],
  borderRadius: 'lg',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.30))',
  mb: '4',
};
