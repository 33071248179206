import {
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from '@chakra-ui/react';
import { Close } from '@styled-icons/ionicons-solid/Close';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { getIconBySportName } from '@/helpers/utils';
import IconSvg from '@/components/common/IconSvg';
import Countdown from '@/components/Countdown/Countdown';
import DuotoneSgmLogo from '@/components/DuotoneSgmLogo/DuotoneSgmLogo';
import { matchesModalStyles } from '@/views/sports/components/MatchesModal/styles/MatchesModal.styles';

export type TMatchesModalMatch = {
  matchId: string;
  matchName: string;
  matchStartTime?: string;
  matchUrl: string;
  sportName?: string;
  sgmAvailable?: boolean;
};

export type TMatchesModalProps = Omit<ModalProps, 'children'> & {
  title: string;
  matches: TMatchesModalMatch[];
};

export default function MatchesModal({
  title,
  matches,
  onClose,
  ...rest
}: TMatchesModalProps) {
  return (
    <Modal onClose={onClose} {...matchesModalStyles.modalWrapper} {...rest}>
      <ModalOverlay />
      <ModalContent {...matchesModalStyles.modalContent}>
        <ModalHeader {...matchesModalStyles.modalHeader}>
          {title}

          <IconButton
            aria-label="Close"
            icon={<Close />}
            {...matchesModalStyles.closeButton}
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody {...matchesModalStyles.modalBody}>
          <Stack
            divider={<Divider {...matchesModalStyles.divider} />}
            {...matchesModalStyles.stackWrapper}
          >
            {matches.map((m) => (
              <Link
                key={m.matchId}
                as={ReactRouterLink}
                to={m.matchUrl}
                {...matchesModalStyles.linkWrapper}
                onClick={onClose}
              >
                <Flex {...matchesModalStyles.flexSportName}>
                  <IconSvg
                    name={getIconBySportName(m.sportName)}
                    {...matchesModalStyles.iconSportName}
                  />
                  {m.matchName}
                </Flex>
                <HStack {...matchesModalStyles.hStack}>
                  {m.matchStartTime && (
                    <Countdown
                      eventTime={m.matchStartTime}
                      {...matchesModalStyles.countdown}
                    />
                  )}
                  {m.sgmAvailable && (
                    <DuotoneSgmLogo
                      primaryColor="alpha.500"
                      secondaryColor="beta.600"
                      {...matchesModalStyles.duotoneSgmLogoOverride}
                    />
                  )}

                  <Icon {...matchesModalStyles.hStackIcon} />
                </HStack>
              </Link>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
