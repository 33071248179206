import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'gray.400', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'gray.400'] },
};

export const FlexRow: CSSObject = {
  '& > a': {
    borderTop: ['unset', null, '1px'],
    borderTopColor: [null, null, 'gray.200'],
  },
};

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'beta.200' : 'transparent',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: 'none',
  borderBottomColor: isToteMultiAvailable ? '#5C6DA4' : 'whiteAlpha.200',
  borderRightColor: isToteMultiAvailable ? '#5C6DA4' : 'whiteAlpha.200',
  _hover: {
    bg: 'blackAlpha.300',
  },
  _last: {
    borderRight: 'none',
  },
});