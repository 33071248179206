import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'gamma.800',
  fontWeight: 'semibold',
  svg: {
    fill: 'gamma.300',
  },
};
export const RunnerDescription: CSSObject = {
  color: 'gamma.800',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const BetOdds: CSSObject = {
  color: 'gamma.800',
};
export const TextBarrierNumber: CSSObject = {
  color: 'gamma.800',
  fontSize: '2xs',
  fontWeight: 'medium',
  position: 'relative',
  top: '-1',
};
export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'green.500';
      case 'pending':
        return 'orange.400';
      default:
        return 'alpha.700';
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'white';
      case 'pending':
        return 'orange.800';
      default:
        return 'gamma.400';
    }
  },
  boxShadow: () => {
    switch (status) {
      case 'won':
        return '0px 2px 4px 0px rgba(0, 0, 0, 0.25)';
      default:
        return '0px 2px 3px 0px rgba(0, 0, 0, 0.2)';
    }
  },
  py: '0',
});
export const HeaderSvg: CSSObject = {
  color: 'gamma.800',
  boxSize: '5',
};
export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};
export const MultiIcon = () => (
  <IconSvg
    name="MultiBet"
    className="svgIcon"
    color="gamma.300"
    boxSize="5"
    mb="1.5"
  />
);
export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
  color: 'gamma.800',
};
export const headerRowStyles: HeaderRowSchema = {
  originalOdds: {
    color: 'gamma.800',
  },
};
