import React, {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';

export type TTemplate = { [key: string]: JSX.Element | TTemplate };

export const createTemplate = (template: TTemplate = {}): ReactNode => {
  const { wrapper } = template;
  const wrapperElement = (
    isValidElement(wrapper) ? wrapper : <div className="fallback-wrapper" />
  ) as ReactElement<any>;

  const children = Object.entries(template ?? {}).reduce<ReactNode[]>(
    (acc, [key, value]) => {
      if (key === 'wrapper') {
        return acc;
      }

      if (
        value !== null &&
        !Array.isArray(value) &&
        !isValidElement(value) &&
        typeof value === 'object'
      ) {
        acc.push(createTemplate(value as TTemplate));
        return acc;
      }

      acc.push(value);
      return acc;
    },
    []
  );

  return cloneElement(wrapperElement, {}, ...children);
};
