import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Collapse,
  Box,
  Flex,
  FormControl,
  HStack,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import {
  FlexRunnerWrapper,
  RacerName,
  RunnerImage,
  TextTableBarrierNumber,
} from '../components/Table/styles/Table.styles';
import RaceRunnerForm from '../../components/RaceRunnerForm/RaceRunnerForm';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  ERaceType,
  TFormData,
} from '@/lib/DBModels';
import { srcDefaultSilk } from '@/assets/core';
import { useQueryRunnerList } from '@/api/racing/racing.hooks';
import { TRunnerList } from '@/api/racing/racing.types';
import {
  RaceDetailsHeading,
  RaceRunnerHeadingContainer,
  RaceRunnerList,
  RaceRunnerListWrapper,
} from '../../RaceDetails/styles/RaceDetails.styles';
import {
  FlexDeductionsContainer,
  RunnerListItemWrapper,
  RunnerScratchedWrapper,
  TextDeductions,
  TextScratchedInfo,
  FlexFlucsPopupContainer,
} from '../../components/RaceRunnerListItem/styles/RaceRunnerListItem.styles';
import Flucs from '../../components/Flucs/Flucs';
import Badges from '../../components/Badges/Badges';
import { useAppSelector } from '@/hooks/useRedux';
import { centsToDollars } from '@/helpers/utils';
import RaceRunnerPropButton from '../../components/RaceRunnerPropButton/RaceRunnerPropButton';
import { mutateBlendedPricing } from '@/api/racing/racing';
import { srMultiStyles } from '../SRMulti/styles/SRMulti.styles';
import {
  TSelections,
  useSelections,
} from './components/Selections/services/Blended.hooks';
import BlendedSelections from './components/Selections/Selections';
import { isScratchedRunner } from '../SRMulti/utils';
import { keys } from '@/api/api.keys';

const SRM_COLUMN_HEADINGS = ['Win'];

export default function Blended() {
  const { racingType } = useParams();
  const raceType = (racingType as ERaceType) || ERaceType.Horse;
  const [searchParams] = useSearchParams();
  const { raceId, venueId } = {
    raceId: searchParams.get('raceId'),
    venueId: searchParams.get('venueId'),
  };
  const [showFlucs, setShowFlucs] = useState(true);
  const [showBadges, setShowBadges] = useState(true);
  const [showError, setShowError] = useState(false);
  const { RacingWin } = EBetTypesDisplayNames;
  const [payload, setPayload] = useState<{
    validBet: boolean;
    price: number;
  } | null>(null);
  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const [selectedRunner, setSelectedRunner] = useState<TSelections | null>(
    null
  );
  const { mutateAsync, isLoading } = useMutation(
    [keys.blended, raceId, venueId],
    (proposition_ids: string[]) =>
      mutateBlendedPricing({
        race_id: raceId as string,
        proposition_ids,
      }),
    {
      onSuccess(res) {
        const {
          data: { valid_bet: validBet, price },
        } = res;
        if (!validBet) {
          setShowError(true);
        } else {
          setPayload({ validBet, price: price as number });
        }
      },
    }
  );

  const { data: runners } = useQueryRunnerList({
    key: [raceId ?? ''],
    params: { race_id: raceId ?? '' },
    options: {
      enabled: !!raceId,
    },
  });

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { selections, addSelection, removeSelection, clearSelections } =
    useSelections();

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showError, setShowError]);

  const sortedRunners = useMemo(() => {
    const res = runners
      ?.sort((a, b) => ((a.number as number) > (b.number as number) ? 1 : -1))
      .reduce(
        (acc, cur) => {
          if (!isScratchedRunner(cur.status as EGeneralStatus)) {
            acc.valid.push(cur);
          } else acc.scratched.push(cur);

          return acc;
        },
        { valid: [], scratched: [] } as {
          valid: TRunnerList[];
          scratched: TRunnerList[];
        }
      );
    return [...(res?.valid ?? []), ...(res?.scratched ?? [])];
  }, [runners]);

  const onClickHander = useCallback(
    async (selection: TSelections) => {
      const isRunnerIdPresent = selections.selections.some(
        (obj) => obj.runnerId === selection.runnerId
      );

      if (isRunnerIdPresent) removeSelection(selection.runnerId);

      try {
        setSelectedRunner(selection);
        // only call for more than 2 selection
        if (
          selections.selections.find((s) => s.runnerId === selection.runnerId)
        ) {
          if (selections.selections.length > 2) {
            const filteredSelections = selections.selections.filter(
              (s) => s.runnerId !== selection.runnerId
            );
            const props = filteredSelections.map((fs) => fs.propositionId);
            await mutateAsync(props);
          }
          removeSelection(selection.runnerId);
        } else {
          // only call for more than one selection
          let add = false;
          if (selections.selections.length > 0) {
            const props = selections.selections.map((s) => s.propositionId);
            const {
              data: { valid_bet: validBet },
            } = await mutateAsync([...props, selection.propositionId]);

            if (validBet) add = true;
          }
          if (add || selections.selections.length === 0)
            addSelection(selection);
        }
      } catch (e) {
        // TODO: replace with error timer
      } finally {
        setSelectedRunner(null);
      }
    },
    [removeSelection, addSelection, selections.selections, mutateAsync]
  );

  const getRunnerByNumber = (runnerNumber: number) =>
    sortedRunners?.find((runner) => runner?.number === runnerNumber);

  const isOpen = raceRunnerList.raceMeta.status === EGeneralStatus.Open;
  const winOdds: number[] = raceRunnerList.raceRunners
    .filter((status) => status.status !== EGeneralStatus.Scratched)
    .map((runner) => runner.win_odds ?? 0);
  const favouritePrice = Math.min(...winOdds);

  const price = selections.selections.length <= 1 ? 0 : payload?.price;

  const uniqueRunnerIds = new Set();
  const filteredSelections = selections.selections.filter((entry) => {
    if (uniqueRunnerIds.has(entry.runnerId)) {
      return false;
    }
    uniqueRunnerIds.add(entry.runnerId);
    return true;
  });

  const badgeSection = (flucsData: TFormData | undefined) =>
    showFlucs ? (
      <Collapse in={showBadges} animateOpacity>
        <Box className="badgeCollapse">
          <Badges form_data={flucsData} />
        </Box>
      </Collapse>
    ) : (
      <Collapse in={showBadges} animateOpacity>
        <Badges form_data={flucsData} />
      </Collapse>
    );

  return (
    <RaceRunnerListWrapper>
      <RaceRunnerList>
        <HStack justifyContent="space-between" pr="2" pb="3">
          <Text {...srMultiStyles.headingLabel}>
            <FormattedMessage id="racing.generic.numberRunner" />
          </Text>
          <HStack>
            <Stack flexDir={['column', null, 'row']}>
              <HStack>
                <RaceRunnerHeadingContainer>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    ml="3"
                  >
                    <RaceDetailsHeading
                      htmlFor="flucs"
                      mr="1"
                      textTransform="capitalize"
                    >
                      <FormattedMessage id="racing.generic.flucs" />
                    </RaceDetailsHeading>
                    <Switch
                      id="flucs"
                      size="sm"
                      onChange={() => setShowFlucs((prevState) => !prevState)}
                      defaultChecked={showFlucs}
                    />
                  </FormControl>
                </RaceRunnerHeadingContainer>
                <RaceRunnerHeadingContainer>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    ml="3"
                  >
                    <RaceDetailsHeading
                      htmlFor="badges"
                      mr="1"
                      textTransform="capitalize"
                    >
                      <FormattedMessage id="racing.generic.insights" />
                    </RaceDetailsHeading>
                    <Switch
                      id="badges"
                      size="sm"
                      onChange={() => setShowBadges((prevState) => !prevState)}
                      defaultChecked={showFlucs}
                    />
                  </FormControl>
                </RaceRunnerHeadingContainer>
              </HStack>
              <HStack justifyContent="flex-end">
                {SRM_COLUMN_HEADINGS.map((title) => (
                  <Text key={title} {...srMultiStyles.columnHeadings}>
                    {title}
                  </Text>
                ))}
              </HStack>
            </Stack>
          </HStack>
        </HStack>
        {sortedRunners?.map((runner) => {
          const { scratch_time: scratchTime, ...r } = runner;
          const isScratched = isScratchedRunner(r.status as EGeneralStatus);
          const propPrice = r.win_odds;
          const displayPrice = propPrice ? propPrice.toFixed(2) : 'N/A';

          const disabled = displayPrice === 'N/A';

          return (
            <RunnerListItemWrapper key={r.race_runner_id}>
              <Flex alignItems="flex-start" w="full">
                <RunnerImage
                  data-cy="raceRunnerSilk"
                  src={r.silk_url}
                  fallbackSrc={srcDefaultSilk}
                  isGreyhound={raceType === ERaceType.Greyhound}
                />
                <FlexRunnerWrapper w="full">
                  <HStack
                    justifyContent="space-between"
                    w="full"
                    align="flex-start"
                  >
                    <VStack alignItems="flex-start">
                      <RacerName
                        data-cy="runnerName"
                        as="div"
                        textDecoration={isScratched ? 'line-through' : ''}
                      >
                        {r.number}.{' '}
                        <div>{String(r.display_name).toLocaleLowerCase()}</div>{' '}
                        {!!r.barrier_number && (
                          <TextTableBarrierNumber as="span">
                            ({r.barrier_number})
                          </TextTableBarrierNumber>
                        )}
                      </RacerName>
                      {!isScratched && <RaceRunnerForm runner={r} />}
                    </VStack>

                    {!isScratched && (
                      <Flex gap="2">
                        <Flex {...srMultiStyles.buttonWrapper}>
                          <RaceRunnerPropButton
                            disabled={disabled}
                            betType={RacingWin}
                            onClick={() =>
                              onClickHander({
                                runner_name: r.display_name as string,
                                runner_number: r.number as number,
                                runnerId: r.race_runner_id as string,
                                barrier_number: r.barrier_number as number,
                                propositionId:
                                  r.win_proposition?.proposition_id,
                                odds: r.win_odds,
                              })
                            }
                            isSelected={
                              Boolean(
                                selections.selections.find(
                                  (s) => s.runnerId === r.race_runner_id
                                )
                              ) || selectedRunner?.runnerId === r.race_runner_id
                            }
                            runner={r}
                            displayPrice={displayPrice}
                            isFavourite={
                              displayPrice === favouritePrice.toFixed(2)
                            }
                          >
                            {displayPrice}
                          </RaceRunnerPropButton>
                        </Flex>
                      </Flex>
                    )}
                  </HStack>
                  {isScratched && (
                    <TextScratchedInfo data-cy="scratchedInfo">
                      <FormattedMessage
                        id="racing.raceRunnerItem.scratchedInfo"
                        values={{
                          scratchTime: scratchTime
                            ? new Date(scratchTime)
                            : null,
                          time: (chunks) => (
                            <time
                              data-cy="scratchedInfoTime"
                              dateTime={scratchTime || undefined}
                            >
                              {chunks}
                            </time>
                          ),
                          hasScratchTime: !!scratchTime,
                        }}
                      />
                    </TextScratchedInfo>
                  )}
                </FlexRunnerWrapper>
                {isScratched &&
                  (runner.win_deductions || runner.place_deductions) && (
                    <FlexDeductionsContainer>
                      <>
                        <RunnerScratchedWrapper>
                          {runner.win_deductions && (
                            <Text data-cy="winDeductions">
                              {centsToDollars(runner.win_deductions)}
                            </Text>
                          )}
                          {runner.place_deductions ? (
                            <Text data-cy="placeDeductions">
                              {centsToDollars(runner.place_deductions)}
                            </Text>
                          ) : (
                            <Text>-</Text>
                          )}
                        </RunnerScratchedWrapper>
                        <TextDeductions data-cy="deductionsApplied">
                          <FormattedMessage id="racing.raceRunnerItem.deductions" />
                        </TextDeductions>
                      </>
                    </FlexDeductionsContainer>
                  )}
              </Flex>
              {!isScratched && (
                <FlexFlucsPopupContainer>
                  <Collapse in={showFlucs} animateOpacity>
                    <Flucs flucs={r.flucs_data ?? {}} isRaceOpen={isOpen} />
                  </Collapse>
                  {badgeSection(r.form_data)}
                </FlexFlucsPopupContainer>
              )}
            </RunnerListItemWrapper>
          );
        })}
        {selections.selections.length !== 0 && (
          <BlendedSelections
            disabled={isLoading}
            clearSelections={clearSelections}
            selections={filteredSelections}
            isLoading={isLoading}
            price={price}
            getRunnerByNumber={getRunnerByNumber}
            venueId={venueId}
            displayErrorBanner={showError}
            setDisplayErrorBanner={() => {
              setShowError(false);
            }}
          />
        )}
      </RaceRunnerList>
    </RaceRunnerListWrapper>
  );
}
