import { CSSObject } from '@chakra-ui/react';
import BB from '@/assets/buffalobet/images/switch/BB.png';
import sideLeft from '@/assets/buffalobet/images/switch/sideLeft.png';
import sideRight from '@/assets/buffalobet/images/switch/sideRight.png';

export const FlexWrapper = {
  borderRadius: 'md',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: 'unset',
  gap: '2',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  bg: 'alpha.500',
  h: '16',
  pos: 'relative',
  _before: {
    bgImg: `url(${sideLeft})`,
    h: '44px',
    w: '18px',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    left: '-3px',
    right: '0',
    top: '11px',
  },
  _after: {
    bgImg: `url(${sideRight})`,
    h: '44px',
    w: '18px',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    right: '-4px',
    top: '11px',
  },
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 8px rgba(251, 201, 21, 0.50)'
    : '0px 0px 8px rgba(67, 187, 255, 0.50)',
  color: isActive ? 'beta.500' : 'white',
  letterSpacing: 'widest',
  px: '3',
});

export const Switcher = (): CSSObject => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
  '--switch-track-width': '46px',
  bg: 'transparent',
  pos: 'relative',
  _before: {
    bg: 'alpha.100',
    h: '3px',
    w: '56px',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    borderBottomRadius: '2em',
    content: '""',
    pos: 'absolute',
    top: '-18px',
    left: '-3px',
  },
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${BB})`,
      boxSize: '12',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-18px',
      right: '0',
      top: '-2',
    },
  },
  '.chakra-switch__track': {
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.50) inset, 0px 0px 0px 1px rgba(28, 102, 202, 0.40)',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    _before: {
      bg: 'blackAlpha.500',
      h: '7px',
      w: '34px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: '2em',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
      content: '""',
      pos: 'absolute',
      left: '2',
      top: '2.5',
    },
  },
});
