import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ContentBodyText,
  TextBoldDescription,
  TextDescription,
} from '../styles/Maintenance.styles';
import { usePathStartsWith } from '@/hooks/usePathStartsWith';

export default function Description() {
  const isMaintenance = usePathStartsWith('/maintenance');
  const messageId = isMaintenance ? 'maintenance.error' : 'error.description';

  return (
    <ContentBodyText data-isMaintenance={isMaintenance}>
      <FormattedMessage
        id={messageId}
        values={{
          bold: (string: string) => (
            <TextBoldDescription as="span">{string}</TextBoldDescription>
          ),
          span: (string: string) => (
            <TextDescription as="span">{string}</TextDescription>
          ),
          br: <br />,
        }}
      />
    </ContentBodyText>
  );
}
