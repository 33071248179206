import { CSSObject } from '@chakra-ui/react';

export const FlexBetsPlacedContainer: CSSObject = {
  h: ['36', null, null, '28'],
  fontSize: 'md',
  justifyContent: 'center',
  flexDir: 'column',
  gap: '1',
};

export const IconBetsPlaced: CSSObject = {
  '&&': {
    display: 'none',
  },
};

export const IconThumbsUp: CSSObject = {
  display: 'flex',
  boxSize: '7',
};

export const TextBetsPlaced: CSSObject = {
  fontSize: 'sm',
};
