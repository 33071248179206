import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontWeight: 500,
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: '12px',
  fontWeight: 500,
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: '12px',
};

export const DepositButton: CSSObject = {};

export const DepositLimitContainer: CSSObject = {
  borderRadius: 'lg',
  background: 'gray.200',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  padding: '16px',
  gap: '4px',
  mb: '0',
};

export const inputProps: Partial<TIndex> = {
  propsLabel: {
    color: 'white',
  },
  sxWrapper: {
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
    '.chakra-input__left-addon': {
      bg: 'beta.100',
      borderColor: 'beta.100',
      color: 'white',
      fontSize: '14px',
      fontWeight: 700,
    },
  },
};

export const buttonRemoveProps: ButtonProps = {
  variant: 'secondary',
};

// ---

export const ModalSubheading: CSSObject = {
  px: 0,
  mt: 0,
  color: 'white',

  fontSize: '14px',
  fontFamily: 'Roboto',
  fontWeight: 700,

  span: {
    fontWeight: 'medium',
  },
};

export const ModalContentWrapper: CSSObject = {
  px: '12px',
  pb: '12px',
  mt: '-10px',
};

export const ButtonWrapper: CSSObject = {
  mt: '12px',
  '> button': { flex: 1 },
};

export const buttonConfirmModalProps: ButtonProps = {};

export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
};

export const PendingLimitContainer: CSSObject = {
  mt: '14px',
  mb: '12px',
  bg: '#38A169',
  color: 'white',
  borderRadius: '5px',
  border: 'none',
  fontSize: '12px',
  padding: '6px',

  'div, p': { color: 'white' },
};

export const LimitLabel: CSSObject = {
  color: 'gray.600',
};

export const LimitValue: CSSObject = {
  color: 'gray.600',
};

export const DepositLimitInputContainer: CSSObject = {
  mt: '12px',
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.300',
  pt: '10px',

  '.chakra-form-control': {
    _last: {
      mt: 0,
      mb: '12px',
    },

    '.chakra-form__label': {
      color: 'white',
    },
  },
};
