import { CSSObject } from '@chakra-ui/react';

export const ModalHeader: CSSObject = {
  color: 'alpha.400',
  fontWeight: 'bold',
  pb: '2',
  px: '4',
  bg: 'beta.500',
};
export const ModalBody: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  pb: '4',
  pt: '1',
  px: '4',
  bg: 'beta.500',
};
export const ButtonDeposit: CSSObject = {
  mt: '3',
};
