import React from 'react';
import { Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/Button/Button';
import { betCardStyles } from '../../../BetCard/styles/BetCard.styles';
import { useBonusBetIcon } from '@/hooks/useIcon';
import {
  EstReturnsBlock,
  EstReturnsLabel,
  FooterContainer,
  FooterText,
} from '../../../BetCard/components/Footer/Footer.styles';

interface IBetCardFooterProps {
  isBonusBetApplied: boolean;
  esimatedReturns: string;
  shouldShowBonusButton: boolean;
  onClick(): void;
}

export function BetCardFooter({
  isBonusBetApplied,
  esimatedReturns,
  shouldShowBonusButton,
  onClick,
}: IBetCardFooterProps) {
  const { bonusBetIcon } = useBonusBetIcon(isBonusBetApplied);

  return (
    <Flex justifyContent={shouldShowBonusButton ? 'space-between' : 'flex-end'}>
      {shouldShowBonusButton && (
        <Button
          width="auto"
          data-cy="useBonusBetsBtn"
          isBonusBet={isBonusBetApplied}
          data-isbonusbet={isBonusBetApplied}
          leftIcon={bonusBetIcon}
          onClick={onClick}
          {...betCardStyles.buttonBonusBetsProps}
        >
          <FormattedMessage
            id={
              isBonusBetApplied
                ? 'account.mybetscard.bonusapplied'
                : 'betslip.usebonusbet'
            }
          />
        </Button>
      )}
      <FooterContainer>
        <Flex alignItems="center">
          <FooterText mx="1">
            <FormattedMessage id="betSlip.potentialReturns" />
          </FooterText>
        </Flex>

        <EstReturnsLabel>
          <EstReturnsBlock as="span">
            {esimatedReturns !== 'N/A' ? (
              esimatedReturns
            ) : (
              <FormattedMessage id="generic.na" />
            )}
          </EstReturnsBlock>
        </EstReturnsLabel>
      </FooterContainer>
    </Flex>
  );
}
