import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  accordionButton: {
    color: 'gray.700',
    sx: {
      display: 'flex',
      p: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: 'base',
      background: 'white',
      fontSize: 'sm',
      boxShadow:
        '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',

      _hover: {
        background: 'gray.200',
      },

      _expanded: {
        borderBottomRadius: 'none',
      },
    },
  },
  topLeaguesText: { flex: '1', textAlign: 'left', fontWeight: 'semibold' },
  accordionIcon: { color: 'gray.700' },
  accordionPanel: {
    bg: 'white',
    pb: '2',
    pt: '0',
    px: '2',
    borderBottomRadius: 'base',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'alpha.500',
    px: '2',
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: 'sm',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      color: 'white',
      fontsize: 'md',
      mt: '4',
      mb: '2',
      fontWeight: 'bold',
    },
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    sx: {
      _last: {
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'gray.700',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    sx: {
      display: 'flex',
      px: '2',
      py: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopWidth: '1px',
      borderTopColor: 'beta.500',
      background: 'white',
      borderRadius: 'unset',

      _hover: {
        background: 'gray.200',
      },

      '&&&': {
        _expanded: {
          borderBottomLeftRadius: 'none',
          borderBottomRightRadius: 'none',
        },
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionIcon: {
    color: 'gray.700',
  },
  marketGroupsByLetterAccordionPanel: {
    bg: 'white',
    px: '2',
    pb: 4,
    pt: '0',
    borderBottomRadius: 'md',
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'alpha.500',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: { _hover: { textDecoration: 'none' } },
  },
};
