import { Global } from '@emotion/react';
import React from 'react';

export default function Index() {
  return (
    <Global
      styles={{
        body: {
          background:
            'no-repeat fixed linear-gradient(to bottom, var(--bc-colors-alpha-100), var(--bc-colors-alpha-200))',
        },
      }}
    />
  );
}
