import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  pt: '1',
};
export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
  fontWeight: 'medium',
};
export const PageInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  color: 'white',
};
export const SmallHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
  fontWeight: 'medium',
};
export const Text: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  color: 'white',
};
export const ImageGamblersHelp: CSSObject = {
  bg: 'white',
  p: '2',
  minW: '184px',
  w: '184px',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px 0px #00000033',
};
export const FlexResponsibleGamblingWrapper: CSSObject = {
  my: '2',
};
export const Link: CSSObject = {
  color: 'beta.400',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecoration: 'none',
  },
};
export const SubHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
  fontWeight: 'medium',
};
export const GridWrapper: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
  maxW: 'container.sm',
  pb: '0',
};
export const ButtonAction: CSSObject = {
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'alpha.500',
  fontFamily: 'ProximaNova',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, beta.600, beta.600)',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(125, 253, 42, 0.7)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
