import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.35)' },
  buttonCashOutAmount: {
    variant: 'primary',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
    bgGradient: 'linear(to-b, beta.800, beta.700)',
    color: 'white',

    sx: {
      '&:hover, &:focus': {
        bgGradient: 'linear(to-b, beta.700, beta.800)',
        color: 'white',
      },
    },
  },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'alpha.400',
    border: 'none',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.800, beta.800)',
  },
  iconOddsBoost: {
    fill: 'alpha.400',
  },
  textOddsBoost: {
    color: 'alpha.400',
  },
  iconCashOut: {
    fill: 'alpha.800',
    textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  },
  cashoutValue: {
    color: 'alpha.800',
    textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.200',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const RaceTime: CSSObject = {
  color: 'whiteAlpha.700',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.400',
  color: 'beta.800',
  fontSize: 'sm',
};

export const BonusBetText: CSSObject = {
  color: 'beta.400',
  fontSize: '13px',
};

export const BetReturn: CSSObject = {
  bg: 'beta.700',
  color: 'whiteAlpha.800',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: '2xs',
  border: '1px',
  borderColor: 'beta.800',
  boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)',
};

export const BoxBetReturnValue: CSSObject = {
  bg: 'beta.800',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'white',
};

export const BoxBetReturnText: CSSObject = {
  pl: '1',
  py: '1',
  color: 'whiteAlpha.800',
};

export const iconLegProps: IconProps = {
  boxSize: '18px',
};
