import { CSSObject } from '@chakra-ui/react';

export const RunnerDetails: CSSObject = {
  color: 'alpha.700',
};

export const HeaderSvg: CSSObject = {
  borderRadius: 'full',
  bg: 'transparent',
  color: 'beta.500',
  boxSize: '8',
  p: '1',
};

export const BetMultiIcon: CSSObject = {
  bg: 'transparent',
  boxSize: '10',
  color: 'beta.500',
  p: '1',
};

export const BetOdds: CSSObject = {
  color: 'gray.700',
};
