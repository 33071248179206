import { LinkProps, TextProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './LinkItem.styles.imports';

const themeName = getThemeName();

export type LinkItemSchema = Partial<{
  link: LinkProps;
  textCompetitionName: TextProps;
  textMatchCount: TextProps;
}>;

export const linkItemStyles: LinkItemSchema = {
  textMatchCount: {
    'data-testid': 'linkItem-textMatchCount',

    ...(() => {
      try {
        return styleImports[themeName]?.linkItemStyles?.textMatchCount;
      } catch (err) {
        return {};
      }
    })(),
  },
  textCompetitionName: {
    'data-testid': 'linkItem-textCompetitionName',

    ...(() => {
      try {
        return styleImports[themeName]?.linkItemStyles?.textCompetitionName;
      } catch (err) {
        return {};
      }
    })(),
  },
  link: {
    'data-testid': 'linkItem-link',

    ...(() => {
      try {
        return styleImports[themeName]?.linkItemStyles?.link;
      } catch (err) {
        return {};
      }
    })(),
  },
};
