import {
  AccordionButtonProps,
  AccordionItemProps,
  AccordionPanelProps,
  AccordionProps,
  BadgeProps,
  BoxProps,
  CenterProps,
  FlexProps,
  IconButtonProps,
  IconProps,
  SkeletonTextProps,
  StackProps,
  TextProps,
  TooltipProps,
} from '@chakra-ui/react';
import { LoaderSizeMarginProps } from 'react-spinners/helpers/props';

import { styleImports } from './NewMatchDetailsPage.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { CustomButtonProps } from '@/components/Button/Button';
import { TScrollButtonGroupProps } from '@/views/sports/components/ScrollButtonGroup/ScrollButtonGroup';
import { TCountdown } from '@/components/Countdown/Countdown';

const themeName = getThemeName();

export type MatchDetailsStylesSchema = Partial<{
  // page header
  headerFlex: FlexProps;
  headerHeading: TextProps;
  headerIconButton: Partial<IconButtonProps>;
  headerSkeletonText: SkeletonTextProps;
  headerVsBox: BoxProps;
  countdown: Pick<TCountdown, 'sxWrapper'> & BadgeProps;

  // wrapper children
  bodyStack: StackProps;
  bodyInnerWrapper: BoxProps;
  sgmLogo: IconProps;
  sgmLogoV2: IconProps;

  wrapperScrollSgm: FlexProps;
  scrollButtonGroup: TScrollButtonGroupProps;
  scrollButtonGroupV2: TScrollButtonGroupProps;
  toMarketButton: CustomButtonProps;
  toMarketButtonV2: CustomButtonProps;
  loadingSkeletonText: SkeletonTextProps;

  // accordion
  accordion: AccordionProps;
  accordionStack: StackProps;
  accordionItem: AccordionItemProps;
  accordionItemActive: AccordionItemProps;
  accordionButton: AccordionButtonProps;

  marketLayoutIconFlex: FlexProps;
  marketLayoutTooltip: Partial<TooltipProps>;
  marketLayoutIcon: IconProps;

  marketLoadingCenter: CenterProps;
  centerLoadingSpinner: Partial<LoaderSizeMarginProps>;

  marketLayoutLoadedIcon: Partial<IconProps>;
  marketLayoutAccordionPanel: AccordionPanelProps;

  sgmBtn: CustomButtonProps;
  sgmBtnActive: CustomButtonProps;
  cancelButton: CustomButtonProps;
  clearButton: CustomButtonProps;
}>;

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  // page header
  headerFlex: {
    'data-testid': 'matchDetailsPage-headerFlex',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.headerFlex;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerHeading: {
    'data-testid': 'matchDetailsPage-headerHeading',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.headerHeading;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerIconButton: {
    'data-testid': 'matchDetailsPage-headerIconButton',
    boxSize: '6',
    sx: { svg: { w: '100%' } },
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.headerIconButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerSkeletonText: {
    'data-testid': 'matchDetailsPage-headerSkeletonText',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.headerSkeletonText;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerVsBox: {
    'data-testid': 'matchDetailsPage-headerVsBox',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.headerVsBox;
      } catch (err) {
        return {};
      }
    })(),
  },
  countdown: {
    'data-testid': 'matchDetailsPage-countdown',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.countdown;
      } catch (err) {
        return {};
      }
    })(),
  },

  // wrapper children
  bodyStack: {
    'data-testid': 'matchDetailsPage-bodyStack',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.bodyStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  bodyInnerWrapper: {
    'data-testid': 'matchDetailsPage-bodyInnerWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.bodyInnerWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  sgmLogo: {
    'data-testid': 'matchDetailsPage-sgmLogo',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.sgmLogo;
      } catch (err) {
        return {};
      }
    })(),
  },
  sgmLogoV2: {
    'data-testid': 'matchDetailsPage-sgmLogoV2',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.sgmLogoV2;
      } catch (err) {
        return {};
      }
    })(),
  },

  wrapperScrollSgm: {
    'data-testid': 'matchDetailsPage-wrapperScrollSgm',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.wrapperScrollSgm;
      } catch (err) {
        return {};
      }
    })(),
  },
  scrollButtonGroup: {
    'data-testid': 'matchDetailsPage-scrollButtonGroup',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.scrollButtonGroup;
      } catch (err) {
        return {};
      }
    })(),
  },
  scrollButtonGroupV2: {
    'data-testid': 'matchDetailsPage-scrollButtonGroupV2',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.scrollButtonGroupV2;
      } catch (err) {
        return {};
      }
    })(),
  },
  toMarketButton: {
    'data-testid': 'matchDetailsPage-toMarketButton',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.toMarketButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  toMarketButtonV2: {
    'data-testid': 'matchDetailsPage-toMarketButtonV2',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.toMarketButtonV2;
      } catch (err) {
        return {};
      }
    })(),
  },
  loadingSkeletonText: {
    'data-testid': 'matchDetailsPage-loadingSkeletonText',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.loadingSkeletonText;
      } catch (err) {
        return {};
      }
    })(),
  },

  // accordion
  accordion: {
    'data-testid': 'matchDetailsPage-accordion',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.accordion;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionStack: {
    'data-testid': 'matchDetailsPage-accordionStack',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.accordionStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionItem: {
    'data-testid': 'matchDetailsPage-accordionItem',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.accordionItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionItemActive: {
    'data-testid': 'matchDetailsPage-accordionItemActive',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.accordionItemActive;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButton: {
    'data-testid': 'matchDetailsPage-accordionButton',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.accordionButton;
      } catch (err) {
        return {};
      }
    })(),
  },

  marketLayoutIconFlex: {
    'data-testid': 'matchDetailsPage-marketLayoutIconFlex',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles
          ?.marketLayoutIconFlex;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketLayoutTooltip: {
    'data-testid': 'matchDetailsPage-marketLayoutTooltip',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.marketLayoutTooltip;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketLayoutIcon: {
    'data-testid': 'matchDetailsPage-marketLayoutIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.marketLayoutIcon;
      } catch (err) {
        return {};
      }
    })(),
  },

  marketLoadingCenter: {
    'data-testid': 'matchDetailsPage-marketLoadingCenter',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.marketLoadingCenter;
      } catch (err) {
        return {};
      }
    })(),
  },
  centerLoadingSpinner: {
    'data-testid': 'matchDetailsPage-centerLoadingSpinner',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles
          ?.centerLoadingSpinner;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketLayoutLoadedIcon: {
    'data-testid': 'matchDetailsPage-marketLayoutLoadedIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles
          ?.marketLayoutLoadedIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketLayoutAccordionPanel: {
    'data-testid': 'matchDetailsPage-marketLayoutAccordionPanel',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles
          ?.marketLayoutAccordionPanel;
      } catch (err) {
        return {};
      }
    })(),
  },
  sgmBtn: {
    'data-testid': 'matchDetailsPage-sgmBtn',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.sgmBtn;
      } catch (err) {
        return {};
      }
    })(),
  },
  sgmBtnActive: {
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.sgmBtnActive;
      } catch (err) {
        return {};
      }
    })(),
  },
  cancelButton: {
    'data-testid': 'matchDetailsPage-cancelButton',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.cancelButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  clearButton: {
    'data-testid': 'matchDetailsPage-clearButton',
    ...(() => {
      try {
        return styleImports[themeName]?.matchDetailsStyles?.clearButton;
      } catch (err) {
        return {};
      }
    })(),
  },
};
