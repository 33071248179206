const branding = {
  alpha: {
    50: '#F4ECE9',
    100: '#E8DAD3',
    200: '#D1B5A7',
    300: '#BB8F7A',
    400: '#A46A4E',
    500: '#8D4522',
    600: '#76391C',
    700: '#502714',
    800: '#3D1E0F',
    900: '#1C0E07',
  },
  beta: {
    50: '#FFFAEB',
    100: '#FEF5D6',
    200: '#FDEBAD',
    300: '#FCE185',
    400: '#F7DF49',
    500: '#FACD33',
    600: '#EC9E0F',
    700: '#A1741F',
    800: '#634713',
    900: '#37280A',
  },
  gamma: {
    50: '#E8F0F5',
    100: '#D0E0EB',
    200: '#A1C2D7',
    300: '#73A3C3',
    400: '#4485AF',
    500: '#15669B',
    600: '#125B8A',
    700: '#104B72',
    800: '#0C3A57',
    900: '#0A2C42',
  },
  delta: {
    50: '#EBFAFE',
    100: '#D7F4FD',
    200: '#AFE9FB',
    300: '#86DEF9',
    400: '#53D3FA',
    500: '#36C8F5',
    600: '#3EB0D3',
    700: '#009FD7',
    800: '#0084B9',
    900: '#1E6981',
  },
  epsilon: {
    50: '#F9F9F9',
    100: '#F2F2F1',
    200: '#E5E4E3',
    300: '#D7D7D5',
    400: '#CAC9C7',
    500: '#BDBCB9',
    600: '#979694',
    700: '#71716F',
    800: '#4C4B4A',
    900: '#262625',
  },
  zeta: {
    50: '#FDFAF4',
    100: '#FCF5E8',
    200: '#F9E9C9',
    300: '#F9DFAE',
    400: '#FFE0A6',
    500: '#EECB8D',
    600: '#E5C281',
    700: '#AD8B4C',
    800: '#8C713F',
    900: '#554526',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  delta: branding.delta,
  epsilon: branding.epsilon,
  zeta: branding.zeta,
};
