import { DeepPartial } from '../../lib/types';

export type TSignUp = DeepPartial<{
  email: string;
  password: string;
  ageAcknowledgement: boolean;
  promotionAcknowledgement: boolean;
  firstName: string;
  lastName: string;
  mobile: string;
  residential: string;
  dateOfBirth: string;
  manualAddress: boolean;
  streetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  postcode: string;
}>;

export enum ESignUpError {
  PLATFORM_NOT_EXISTS = 'PLATFORM_NOT_EXISTS',
  CONTACT_CUSTOMER_SERVICE = 'CONTACT_CUSTOMER_SERVICE',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  STREET_NUMBER_IS_REQUIRED = 'STREET_NUMBER_IS_REQUIRED',
  BETSTOP_REJECTION = 'BETSTOP_REJECTION',
}
