import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  variant: 'odds',
  boxSize: '9',
  lineHeight: 'normal',
  fontSize: 'xs',
  w: 'auto',
  p: '2',
  minWidth: '12',
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.3',
  width: '100%',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '0px',
  borderTopLeftRadius: 'md',
  borderTopRightRadius: 'md',
  borderTop: '1px solid var(--bc-colors-whiteAlpha-200)',
  borderRight: '1px solid var(--bc-colors-whiteAlpha-200)',
  borderLeft: '1px solid var(--bc-colors-whiteAlph-200)',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  textTransform: 'uppercase',
  bg: 'beta.900',
  bgImage: 'none',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1),0px -1px 0px 0px rgba(0, 0, 0, 0.05) inset',
  sx: {
    'svg[class*="StyledIconBase"]': {
      display: 'none',
    },
  },
};

export const favouriteIconProps: CSSObject = {
  display: 'none',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '9px',
  color: 'beta.500',
  textShadow: 'none',
};
