import { SegmentedSchema } from '@/views/sports/components/Segmented/styles/Segmented.styles';

export const segmentedStyles: SegmentedSchema = {
  segmentWrapper: {
    bg: 'blackAlpha.500',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '0.5',
    spacing: '0.5',
    variant: 'segment',
    fontFamily: 'accent',
    h: '25px',
  },
};
