import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonGroup: CSSObject = {
  overflow: 'hidden',
};

export const BoxWrapper: CSSObject = {
  mt: [0, '44px'],
};

export const ButtonLogIn: CSSObject = {
  borderColor: 'alpha.500',
  color: 'alpha.500',

  '&:hover, &:active': {
    bg: 'alpha.500',
    color: 'alpha.900',
  },
};

export const ButtonSignUp: CSSObject = {
  bg: 'alpha.500',
  color: 'alpha.900',

  '&:hover, &:active': {
    bg: 'alpha.400',
    color: 'alpha.900',
  },
};

export const ButtonBetSlip: CSSObject = {
  bg: 'alpha.500',
  color: 'alpha.900',

  '&:hover, &:active': {
    bg: 'alpha.400',
    color: 'alpha.900',
  },
};

export const ButtonBets: CSSObject = {
  bg: 'linear-gradient(180deg, rgba(70, 78, 139, 0.44) 0%, rgba(70, 78, 139, 0.8) 100%)',
  color: 'white',

  '.span-bet-count': { fontSize: 'md' },

  '&:hover, &:active': {
    bg: 'linear-gradient(180deg, rgba(70, 78, 139, 0.44) 0%, rgba(70, 78, 139, 0.8) 100%)',
    color: 'white',
  },
};

export const buttonQuickAccessProps: CustomButtonProps = {
  sx: {
    '&&': {
      height: '45px',
      minW: '100px',
      bg: 'linear-gradient(180deg, rgba(70, 78, 139, 0.44) 0%, rgba(70, 78, 139, 0.8) 100%)',
      bgSize: '100% 100%',
      alignItems: 'flex-start',
      animation: '.5s ease-in-out',

      '&:active': { bg: 'beta.600' },

      '&:hover': {
        bgPos: 'bottom center',
        bgSize: '250% 250%',
        boxShadow: 'sm',
      },

      '&[aria-expanded="true"]': {
        bg: 'white',

        '.chevron-icon': { color: 'beta.500' },
        '.span-balance': { color: 'beta.400' },
        '.account-text': { color: 'beta.500' },
      },

      '&:after': { bg: 'white' },

      '.btn-span': { flexDir: 'column', alignItems: 'flex-start' },
      '.span-balance': { color: 'yellow.400', fontSize: 'md' },
      '.span-bonus': { color: 'alpha.500', fontSize: 'sm' },
      '.account-text': { color: 'white' },
      '.chevron-icon': { color: 'white' },
    },
  },
};
