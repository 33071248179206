import React from 'react';
import { Global } from '@emotion/react';
import bg from '@/assets/betgalaxy/images/backgrounds/background.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bg}), linear-gradient(var(--bc-colors-gamma-500) 0%, var(--bc-colors-gamma-500) 100%)`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundSize: 'cover, cover',
          backgroundAttachment: 'fixed',
          '@media (min-width: 1366px)': {
            backgroundPosition: 'top, center',
          },
          '@media (max-width: 1016px)': {
            backgroundPosition: 'top, center',
          },
          '@media (max-width: 390px)': {
            backgroundPosition: 'top, center',
          },
        },
      }}
    />
  );
}
