import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const primary = {
  bgGradient: 'linear(to-b,  #636EC7, #414984)',
  fontWeight: 'bold',
  borderRadius: 'base',
  lineHeight: '1',
  color: 'white',
  textTransform: 'uppercase',

  _hover: {
    bgSize: '250% 250%',
  },

  '&:active, &[data-active="true"]': {
    bg: 'beta.600',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.50) inset',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'xs' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      bgGradient: 'linear(to-b, theta.50, theta.100)',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.65) inset',
      color: 'yellow.900',

      ':active, &[data-active="true"]': {
        bgGradient: 'linear(to-b, beta.700, beta.700)',
        color: 'white',
        textShadow: 'unset',
      },

      _hover: {
        bgSize: '250% 250%',
      },

      _disabled: {
        pointerEvents: 'none',
      },

      ...(props.colorScheme && colorScheme(props)),
    }),
    tertiary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      bg: 'brand.500',
      color: 'brand.900',
      textTransform: 'uppercase',

      borderRadius: 'base',
      '&:active, &[data-active="true"]': {
        bg: 'beta.500',
        borderColor: 'beta.300',
        boxShadow: 'inset 0px 2px 3px rgba(0, 0, 0, 0.25)',
        color: 'white',
        _hover: {
          bg: 'beta.300',
          border: '1px',
        },
      },

      _hover: {
        bg: 'brand.300',
      },

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'white',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'md',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bgGradient: 'linear(to-b,  #636EC7, #414984)',
      },
    }),
    filter: {
      border: '2px',
      borderColor: 'beta.100',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '6',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',

      _hover: {
        color: 'yellow.300',
      },

      _active: {
        border: 'none',
        color: 'beta.600',
        bgGradient: 'linear(to-b, theta.50, theta.100)',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      textTransform: 'uppercase',

      _hover: {
        color: 'beta.50',
      },

      _active: {
        bg: 'beta.50',
        borderRadius: 'base',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
        fontSize: '2xs',
        fontWeight: 'black',
      },
    },
    tab: () => ({
      bgGradient: 'linear(to-b, #E3E3E3, #F2F2F2)',
      display: 'inline-flex',
      color: 'beta.700',
      textTransform: 'uppercase',
      fontWeight: 'semibold',
      fontSize: '2xs',
      px: '2',
      py: '3',
      border: '1px',
      borderColor: 'blackAlpha.200',
      boxShadow:
        '0px 1px 0px #FFFFFF, 0px 2px 5px rgba(21, 24, 56, 0.35), inset 0px 2px 0px rgba(255, 255, 255, 0.4)',
      minW: 'fit-content',
      h: '6',
      borderRadius: '2xl',
      '&+button': { ml: '1' },
      _first: { ml: ['2', 'unset'] },
      _last: { mr: ['2', 'unset'] },
      _hover: {
        borderColor: 'transparent',
        boxShadow:
          '0px 1px 0px rgba(255, 255, 255, 0.5), 0px 2px 3px rgba(21, 24, 56, 0.4)',
      },
      '&:active, &[data-active="true"]': {
        bg: 'beta.500',
        borderColor: 'beta.300',
        boxShadow: 'inset 0px 2px 3px rgba(0, 0, 0, 0.25)',
        color: 'white',
        _hover: {
          bg: 'beta.300',
          border: '1px',
        },
      },
    }),
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      _hover: {
        color: 'alpha.400',
      },
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
      },
    },
    odds: {
      borderRadius: 'md',
      bgGradient: 'linear(180deg, #FFFFFF 0%, #EEEEEE 6.25%, #FFFFFF 100%)',
      boxShadow: '0px 4px 5px rgba(92, 102, 179, 0.15)',
      border: '1px',
      borderColor: 'gray.300',
      color: 'black',
      height: '8',
      width: '12',
      fontSize: 'xs',
      p: '0',
      '&:active, &[data-active="true"]': {
        bg: 'beta.500',
        borderColor: 'accent.500',
        color: 'white',
        boxShadow: '0px 4px 5px rgba(92, 102, 179, 0.15)',
      },
      '&:disabled': {
        boxShadow: 'none',
        cursor: 'default',
        color: 'gray.500',
        border: '2px solid',
        borderColor: 'gray.300',
        borderRadius: 'sm',
        bg: 'none',
      },
      '&:disabled:hover': {
        bg: 'none',
      },
    },
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
    }),
  },
  defaultProps: {
    size: 'sm',
    colorScheme: null,
  },
};
