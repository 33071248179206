import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '.react-date-picker__wrapper': {
    h: '40px',
    bg: 'gray.50',
    borderColor: 'gray.100',
    borderRadius: '4px',
    transition: 'all .2s ease-in-out',
    fontSize: 'xs',
    px: '2',
    'input, span': {
      fontFamily: 'roboto',
      fontWeight: 'normal',
    },

    _hover: {
      bg: 'gray.200',
    },
  },
};
