import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
    mb: '3',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: ['lg', 'xl'],
    fontWeight: 'normal',
  },
  headerIconButton: {
    color: 'white',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'alpha.400',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'alpha.400',
    h: '1.1em',
    ml: '2',
    mb: '0.5',
    w: 'auto',
  },
  sgmLogoV2: {
    color: 'alpha.400',
    w: '8',
    h: 'auto',
  },
  scrollButtonGroup: {
    variant: 'filter',
    boxShadow: 'none',
    bg: 'none',
    maxH: '9',
    pos: 'relative',
  },
  wrapperScrollSgm: {
    gap: '2',
    alignItems: 'center',
    display: 'flex',
    w: 'full',
  },
  toMarketButton: {
    sx: {
      '&[data-active], :active': {
        _after: {
          display: 'none',
        },
      },
    },
    _after: {
      bgImage: 'none',
    },
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bg: 'rgba(78, 178, 255, 0.14)',
    border: 'none',
    borderRadius: 'md',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    overflow: 'hidden',
  },
  accordionButton: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
    _hover: {
      bg: 'rgba(78, 178, 255, 0.45)',
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    px: '3',
  },
  clearButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    ml: '3',
    px: '3',
  },
  countdown: {
    sx: {
      '&&&.state-prior': {
        textTransform: 'uppercase',
        p: 1,
        lineHeight: 'initial',
      },
    },
  },
  scrollButtonGroupV2: {
    variant: 'unstyled',
    flex: 1,
    position: 'relative',
    minH: '34px',
  },
  accordionItemActive: {
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  },
  toMarketButtonV2: {
    variant: 'unstyled',
    color: 'white',
    textTransform: 'uppercase',
    minW: 'fit-content',
    border: 'none',
    cursor: 'pointer',
    fontSize: 'sm',
    boxShadow: 'none',
    textShadow: 'none',
    fontWeight: 'normal',
    lineHeight: '1.7',
    borderRadius: 'none',
    fontFamily: 'Staatliches',

    sx: {
      '&&': {
        px: 2,
        ml: 0,
        h: 'auto',
      },

      '&[data-active]': {
        color: 'alpha.400',
        boxShadow: 'none',
        bg: 'transparent',

        _after: { display: 'none', bgImage: 'none' },
      },

      '.chakra-button__icon': {
        margin: 0,
      },
    },

    _last: { mr: 20 },
  },
  sgmBtn: {
    bgGradient: 'linear(to-b, alpha.300, alpha.700)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.30) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    sx: {
      svg: {
        color: 'alpha.900',
        fill: 'alpha.900',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
      '&&': {
        _active: {
          bgGradient: 'linear(to-b, alpha.600, alpha.700)',
          boxShadow: 'none',
        },
      },
    },
  },
  sgmBtnActive: {
    sx: {
      svg: {
        color: 'alpha.400',
        fill: 'alpha.400',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
      },

      '&&': {
        border: 'none',
        bg: 'blackAlpha.600',
        boxShadow:
          'box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
        borderColor: 'transparent',
      },
    },

    _after: { display: 'none' },
  },
};
