import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TextToggle } from '../../styles/Toggle.styles';

export default function TextSport() {
  const [searchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  return (
    <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
      <FormattedMessage id="sport" defaultMessage="Sport" />
    </TextToggle>
  );
}
