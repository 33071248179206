import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  mb: '1',
};

export const BackButton: CSSObject = {
  boxSize: '5',
  minW: '5',
  svg: { boxSize: '5' },
};

export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
};

export const DividerWithdrawal: CSSObject = {
  display: 'none',
};

export const BankCardContainer: CSSObject = {
  bg: 'transparent',
};

export const BankRadioWrapper: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'whiteAlpha.300',
  py: '12px',
  px: 0,
  h: '58px',

  _first: {
    borderTop: '1px dashed',
    borderTopColor: 'whiteAlpha.300',
  },
};

export const BankRadio: CSSObject = {
  alignSelf: 'flex-start',
  mr: '2',

  '+ .chakra-radio__label': { ml: 2 },
};

export const TextBankAccountName: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  mb: '1',
};

export const buttonAddAccountProps: CustomButtonProps = {
  fontSize: 'sm',
  px: '6px',
};

export const FlexBankDetailsContainer: CSSObject = {
  color: 'white',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  gap: '4',
};

export const ButtonWithdrawal: CSSObject = {
  mt: '2',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '900',
};

export const InputWrapperWithdrawal: CSSObject = {
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.300',
  pt: '10px',

  '.chakra-form__label': { color: 'white' },
  '.chakra-form__helper-text': {
    color: 'white',
    fontSize: '13px',
    fontWeight: 500,
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
  '.chakra-form__label': { color: 'white' },
  '.chakra-input__left-addon': {
    color: 'white',
    bg: 'beta.100',
    fontSize: '14px',
    fontWeight: 700,
    borderColor: 'beta.100',
  },
};

export const Subheading: CSSObject = {
  color: 'white',
  span: {
    color: 'white',
  },
};

export const ButtonAddAccount: CSSObject = {
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 900,
};

export const ListContainer: CSSObject = {
  bg: 'none',
  mt: 0,
  p: 0,
  ml: [null, null, null, '4'],
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

export const WithdrawalWrapper: CSSObject = {
  mt: '2',
  borderRadius: '4px',
  bg: 'gray.200',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  px: '10px',

  '> div': {
    p: 0,

    '> div': {
      borderBottom: '1px dashed',
      borderBottomColor: 'gray.300',

      fontSize: '12px',
      fontWeight: 700,
      color: 'gray.700',
    },

    button: {
      fontFamily: 'Roboto',
      fontWeight: 900,
      fontSize: '12px',
      p: 0,
    },
  },
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  mt: '10px',
};

export const IncreaseAmountWrapper: CSSObject = {
  '> button': {
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '900',
    h: '38px',
  },
};
