import { CSSObject } from '@chakra-ui/react';

const pseudo = {
  pos: 'absolute',
  content: `''`,
  top: '0',
  bottom: '0',
  w: '10',
  zIndex: '9',
  transition: 'all 0.2s ease-in-out',
};

export const BoxWrapper: CSSObject = {
  bg: 'beta.500',
  borderRadius: 'xl',
  border: '3px solid',
  borderColor: 'beta.100',
  boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.6)',
  contain: 'paint',

  '::after': {
    ...pseudo,
    right: '0',
    bgGradient: 'linear(to-r, transparent, beta.500)',
    opacity: 1,
  },

  '::before': {
    ...pseudo,
    left: 0,
    bgGradient: 'linear(to-l, transparent, beta.500)',
    opacity: 0,
  },

  '&.hasLeftButton': {
    '::after': { opacity: 0 },
    '::before': { opacity: 1 },
  },
};

export const FlexScrollWrapper: CSSObject = {};

export const Btn: CSSObject = {
  border: '2px',
  borderColor: 'alpha.600',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15), inset 0px 0px 0px 4px #426DD6',
  color: 'delta.500',
  svg: {
    boxSize: '7',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const Txt: CSSObject = {
  color: 'gamma.500',
};
