import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  color: 'gray.700',
  p: '1',
  bg: ['transparent', 'gray.100'],
  borderRadius: [null, 'lg'],
  border: [null, 'none'],
  borderStyle: 'dashed',
  borderColor: 'blackAlpha.300',
  mb: '2',
};

export const RunnerName: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'gray.700',
  textTransform: 'capitalize',
};

export const FlexDeductionsContainer: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'alpha.700',
  textTransform: 'uppercase',
  flexDir: 'column-reverse',
};

export const TextDeductions: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
};

export const ScratchedText: CSSObject = {
  fontWeight: 'semibold',
  color: 'alpha.700',
};

export const TextScratchedInfo: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'alpha.700',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  borderColor: 'gray.200',
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1.5',
};
