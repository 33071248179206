import React from 'react';
import {
  Box,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  IconButtonProps,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Menu } from '@styled-icons/boxicons-regular';
import { styleImports } from './Header.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG } from '@/constants/themeConfig';
import { Button, CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export const buttonSignUpProps: ButtonProps = {
  'data-testid': 'header-buttonSignUpProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonSignUpProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonLogInProps: ButtonProps = {
  'data-testid': 'header-buttonLogInProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonLogInProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonQuickAccessProps: CustomButtonProps = {
  'data-testid': 'header-buttonQuickAccessProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonQuickAccessProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonBetsProps: CustomButtonProps = {
  'data-testid': 'header-buttonBetsProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonBetsProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonBetSlipProps: CustomButtonProps = {
  'data-testid': 'header-ButtonBetSlipProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonBetSlipProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const BoxWrapper = chakra(Box, {
  label: 'header-BoxWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexContainer = chakra(Flex, {
  shouldForwardProp: (prop) => !['isHome'].includes(prop),
  label: 'header-FlexContainer',
  baseStyle: ({ isHome }: any) => ({
    mx: 'auto',
    maxW: 'container.xl',
    h: '64px',
    px: ['2.5', null, null, '4'],
    alignItems: 'center',
    justifyContent: 'space-between',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexContainer?.(isHome);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps & { isHome: boolean }>;

// --

export const FlexLeftGroup = chakra(Flex, {
  baseStyle: () => ({
    label: 'header-FlexLeftGroup',
    gap: '1.5',
    alignItems: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexLeftGroup;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkLogo = chakra(Link, {
  label: 'header-LinkLogo',
  baseStyle: () => ({
    maxW: '160px',
    display: 'inline-block',

    ...(() => {
      try {
        return styleImports[themeName]?.LinkLogo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const ChakraIconButton = (props: IconButtonProps) => (
  <IconButton
    // aria-label="menu toggle"
    variant="unstyled"
    icon={<Icon as={Menu} />}
    {...props}
  />
);
export const IconButtonMenu = chakra(ChakraIconButton, {
  label: 'header-IconButtonMenu',
  baseStyle: ({ isHome }: any) => ({
    minW: '0',
    minH: '0',
    boxSize: '6',
    bg: 'white',
    borderRadius: 'sm',
    lineHeight: 'none',
    p: '0',

    ...(() => {
      try {
        return styleImports[themeName]?.IconButtonMenu?.(isHome) ?? null;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps & { isHome: boolean }>;
// --

// ---
export const FlexButtonGroup = chakra(Flex, {
  label: 'header-ButtonGroup',
  baseStyle: () => ({
    gap: '2',
    alignItems: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonGroup;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const ChakraButtonLogIn = (props: ButtonProps) => (
  /**
   * To toggle variant use the getThemeName function
   */
  <Button {...props} variant="outline" />
);

/**
 * @deprecated The function should not be used or extended further,
 * use ButtonLogInProps instead.
 */
export const ButtonLogIn = chakra(ChakraButtonLogIn, {
  label: 'header-ButtonLogIn',
  baseStyle: () => ({
    px: '2',
    py: '2.5',
    h: '37px',

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonLogIn;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

const ChakraButtonSignUp = (props: ButtonProps) => <Button {...props} />;

/**
 * @deprecated The function should not be used or extended further,
 * use buttonSignUpProps instead.
 */
export const ButtonSignUp = chakra(ChakraButtonSignUp, {
  label: 'header-ButtonSignUp',
  baseStyle: () => ({
    px: '2',
    py: '2.5',
    h: '37px',

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonSignUp;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

const ChakraButtonBetSlip = (props: ButtonProps) => <Button {...props} />;

/**
 * @deprecated The function should not be used or extended further,
 * use ButtonBetSlipProps instead.
 */
export const ButtonBetSlip = chakra(ChakraButtonBetSlip, {
  label: 'header-ButtonBetSlip',
  baseStyle: () => ({
    px: '0',
    py: '2.5',
    pos: 'relative',
    flexDir: 'column',
    w: '43px',
    h: '45px',

    '.btn-span': {
      flexDir: 'column',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonBetSlip;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const TextButton = chakra(Text, {
  label: 'header-TextButton',
  baseStyle: () => ({
    fontSize: '3xs',

    ...(() => {
      try {
        return styleImports[themeName]?.TextButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const TextButtonNumber = chakra(Text, {
  label: 'header-TextButtonNumber',
  baseStyle: () => ({
    display: 'block',
    fontSize: 'md',

    ...(() => {
      try {
        return styleImports[themeName]?.TextButtonNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const ButtonGroupBets = (props: ButtonGroupProps) => {
  const baseStyle = {
    label: 'header-ButtonGroupBets',
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonGroupBets;
      } catch (err) {
        return {};
      }
    })(),
  };

  const isAttached = ![
    EThemesOG.Betroyale,
    EThemesOG.Volcanobet,
    EThemesOG.Puntgenie,
    EThemesOG.Sterlingparker,
    EThemesOG.Puntcity,
    EThemesOG.Goldenrush,
    EThemesOG.Juicybet,
    EThemesOG.Vikingbet,
    EThemesOG.Buffalobet,
  ].includes(themeName);
  return <ButtonGroup sx={baseStyle} isAttached={isAttached} {...props} />;
};

const ChakraButtonBets = (props: ButtonProps) => <Button {...props} />;

/**
 * @deprecated The function should not be used or extended further,
 * use buttonBetsProps instead.
 */
export const ButtonBets = chakra(ChakraButtonBets, {
  label: 'header-ButtonBets',
  baseStyle: () => ({
    px: '0',
    py: '2.5',
    pos: 'relative',
    flexDir: 'column',
    w: '45px',
    h: '45px',

    '.btn-span': {
      flexDir: 'column',
    },

    '.span-bet': {
      fontSize: '2xs',
      textTransform: 'capitalize',
      fontWeight: 'bold',
    },
    '.span-bet-count': {
      fontWeight: 'black',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonBets;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

// --
export const SkeletonButtonGroup = chakra(Skeleton, {
  label: 'SkeletonButtonGroup',
  baseStyle: () => ({
    h: '8',
    w: '200px',

    ...(() => {
      try {
        return styleImports[themeName]?.SkeletonButtonGroup;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonOutsideArea = chakra(Button, {
  shouldForwardProp: (prop) => !['isOpen', 'isHeader'].includes(prop),
  baseStyle: ({ isOpen, isHeader }: any) => ({
    label: 'header-ButtonOutsideArea',
    ...(isHeader && { position: 'absolute' }),
    bg: 'transparent',
    display: isOpen ? 'flex' : 'none',
    w: 'full',
    h: isHeader ? 'full' : '100vh',
    left: '0',
    /**
     * The z-index of Chakra popovers are not attached to a token - instead
     * they're a magic number of 10. To ensure this element appears just under
     * the popover, it has been set to 9. Ideally though, this element shouldn't
     * be needed, as Chakra handles this use-case out of the box.
     * @see {@link https://chakra-ui.com/docs/components/popover}
     */
    zIndex: 9,

    _hover: {
      bg: 'transparent',
    },

    _active: {
      bg: 'transparent',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonOutsideArea;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<
  'button',
  ButtonProps & { isOpen: boolean; isHeader?: boolean }
>;
