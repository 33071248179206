import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/gigabet/images/feature/featSportBg.png';
import featuredMb from '@/assets/gigabet/images/feature/featSportBgMb.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};
export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  border: '2px solid',
  borderColor: 'alpha.400',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  pos: 'relative',
  mb: ['2', null, '4'],
  mt: ['4', null, '6'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  borderRadius: 'lg',
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 3px #20273F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.2) inset',
};
export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};
export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};
export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'alpha.400',
  fontSize: 'md',
  fontWeight: 'normal',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'alpha.400',
  fontFamily: 'Staatliches',
  boxShadow: 'unset',
};
export const TextMisc: CSSObject = {
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow:
    '0px 1px 5px 0px rgba(130, 151, 222, 1) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.45)',
  borderRadius: 'base',
  color: 'white',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  borderBottom: 'none',
  span: { display: 'inline-block', color: 'blackAlpha.300', mx: '1' },
};
export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: '2xl',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
};
export const BoxHeadingWrapper: CSSObject = {
  fontFamily: 'Staatliches',
  fontSize: '3xl',
  fontWeight: 'normal',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  color: 'alpha.600',
};
export const puntButtonProps: CSSObject = {
  variant: 'secondary',
  pt: '1',
  borderRadius: 'md',
  fontSize: 'sm',
};
export const HeadingSlogan: CSSObject = {
  fontFamily: 'accent',
  color: 'alpha.400',
  textTransform: 'capitalize',
  alignItems: 'center',
  fontSize: ['3xl', null, '3xl'],
};
