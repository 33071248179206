import { Box, chakra } from '@chakra-ui/react';

export const MobileAppFullScreenModal = chakra(Box, {
  baseStyle: () => ({
    bg: 'white',
    p: 4,
    zIndex: 999,
    pos: 'absolute',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    height: '100%',
    width: '100%',
    '> iframe': {
      bottom: 0,
      top: 0,
      right: 0,
      left: 0,
      margin: 'auto auto',
      pos: 'absolute',
      width: '100%',
      height: '100%',
    },
  }),
});
