import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LocationRoute from '../components/Routes/LocationRoute/LocationRoute';

import LoginPage from '../views/onboarding/Login';
import LocationNotAllowed from '../views/LocationNotAllowed';
import DepositLimit from '../views/onboarding/DepositLimit';
import AccountVerification from '../views/onboarding/AccountVerification';
import ForgotPassword from '../views/onboarding/ForgotPassword';

import Overview from '../views/account/Overview';
import Withdrawal from '../views/account/Withdrawal';
import Deposit from '../views/account/Deposit';
import AccountDepositLimit from '../views/account/DepositLimit';
import Transactions from '../views/account/Transactions/Transactions';
import MyBets from '../views/account/MyBets';

// Settings and sub pages
import Settings from '../views/account/Settings';
import UpdateEmail from '../views/account/UpdateEmail';
import VerifyIdentity from '../views/account/VerifyIdentity';
import PersonalDetails from '../views/account/PersonalDetails';
import PromotionsNotifications from '../views/account/PromotionsNotifications';
import ResponsibleGambling from '../views/account/ResponsibleGambling/ResponsibleGambling';
import CloseAccount from '../views/account/CloseAccount';
import {
  TakeABreakInformation,
  TakeABreakForm,
} from '../views/account/TakeABreak';
import {
  LifetimeExclusionForm,
  LifetimeExclusionInformation,
} from '../views/account/LifetimeExclusion';

import AllSports from '../views/sports/AllSports';
import MatchDetailPage from '@/views/sports/MatchDetailPage/MatchDetailPage';
import MatchDetails from '@/views/sports/MatchDetailPage/components/MatchDetails';
import RaceDetails from '../views/races/RaceDetails';
import RaceMeetings from '../views/races/RaceMeetings/RaceMeetings';

import SignedOut from '../views/account/SignedOut/Components/SignedOut';

import PrivateRoute from '../components/Routes/PrivateRoute/PrivateRoute';
import OnboardedRoute from '../components/Routes/OnboardedRoute/OnboardedRoute';
import ScrollToTop from '../components/Routes/ScrollToTop/ScrollToTop';

import PWA from '../conditionals/MobileApp';
import PrimaryLayout from '../layouts/PrimaryLayout/PrimaryLayout';
import SecondaryLayout from '../layouts/SecondaryLayout/SecondaryLayout';
import Promos from '../views/helpCentre/Promos/Promos';
import SignUp from '../views/onboarding/Signup';
import MaintenanceRoute from '../components/Routes/MaintenanceRoute/MaintenanceRoute';
import KYCRoute from '@/components/Routes/KYCRoute/KYCRoute';
import Terms from '../views/helpCentre/Terms/Terms';
import Maintenance from '../views/Maintenance/Maintenance';
import Privacy from '../views/helpCentre/Privacy/Privacy';
import { FEATURE_FLAGS } from '../constants/featureFlags';
import Home from '../views/Home/Home';
import LifetimeExclusionConfirmation from '@/views/account/LifetimeExclusion/LifetimeExclusionConfirmation';
import NewMatchDetailPage from '@/views/sports/NewMatchDetailPage/NewMatchDetailPage';
import UpcomingMatches from '@/views/sports/UpcomingMatches/UpcomingMatches';
import CompetitionsBySportV2 from '@/views/sports/CompetitionsBySport/CompetitionsBySportV2';
import AllLeagues from '@/views/sports/AllLeagues/AllLeagues';
import MatchesV2 from '@/views/sports/Matches/MatchesV2';
import Promosv2 from '@/views/helpCentre/PromoV2/Promos';

const AppRoutes = () => (
  <>
    <ScrollToTop />
    <PWA />
    <Routes>
      <Route element={<MaintenanceRoute />}>
        <Route element={<KYCRoute />}>
          <Route path="/maintenance" element={<Maintenance />} />
          <Route
            path="/location-not-allowed"
            element={<LocationNotAllowed />}
          />

          <Route element={<PrimaryLayout />}>
            <Route element={<LocationRoute />}>
              <Route path="/" element={<Home />} />

              {/* Sports Routes */}
              {FEATURE_FLAGS.HAS_SPORTS_VIEW && (
                <>
                  <Route path="/sports">
                    <Route index element={<UpcomingMatches />} />

                    <Route
                      path=":sportName"
                      element={<CompetitionsBySportV2 />}
                    />

                    <Route
                      path=":sportName/:competitionName"
                      element={<MatchesV2 />}
                    />

                    <>
                      <Route path="all" element={<AllSports version={2} />} />
                      <Route path=":sportName/all" element={<AllLeagues />} />
                      <Route
                        path=":sportName/:competitionName/:matchName"
                        element={<NewMatchDetailPage />}
                      />
                      <Route
                        path=":sportName/:competitionName/:matchName/sgm"
                        element={<NewMatchDetailPage sgm />}
                      />
                    </>
                  </Route>
                </>
              )}

              {/* Racing Routes */}

              {FEATURE_FLAGS.HAS_RACE_VIEW && (
                <Route path="/racing">
                  <Route path=":raceMeetingsTime" element={<RaceMeetings />} />
                  <Route
                    path=":racingType/:venueName/:raceNumber"
                    element={<RaceDetails />}
                  />
                  <Route
                    path=":racingType/:venueName/:raceNumber/:betType"
                    element={<RaceDetails />}
                  />

                  <Route
                    index
                    element={<Navigate to="Next To Jump" replace />}
                  />

                  <Route
                    path="futures/:matchName"
                    element={<MatchDetailPage />}
                  >
                    <Route index element={<MatchDetails />} />
                  </Route>
                </Route>
              )}

              <Route path="/help-centre">
                <Route index element={<Navigate to="/" replace />} />
                <Route path="terms-and-conditions" element={<Terms />} />
                <Route path="privacy-policy" element={<Privacy />} />
                <Route element={<PrivateRoute />}>
                  <Route path="promos" element={<Promos />} />
                </Route>
                {FEATURE_FLAGS.HAS_NEW_PROMOS && (
                  <Route path="promotions" element={<Promosv2 />} />
                )}
              </Route>

              <Route element={<PrivateRoute />}>
                <Route element={<OnboardedRoute />}>
                  <Route path="/account/overview" element={<Overview />} />
                  <Route path="/account/my-bets" element={<MyBets />} />
                  <Route path="/account/deposit" element={<Deposit />} />
                  <Route path="/account/withdrawal" element={<Withdrawal />} />
                  <Route
                    path="/account/deposit-limit"
                    element={<AccountDepositLimit />}
                  />
                  <Route
                    path="/account/transactions"
                    element={<Transactions />}
                  />
                  <Route path="/account/settings" element={<Settings />} />
                  <Route
                    path="/account/settings/personal-details"
                    element={<PersonalDetails />}
                  />
                  <Route
                    path="/account/settings/promotions-notifications"
                    element={<PromotionsNotifications />}
                  />
                  <Route
                    path="/account/settings/update-email"
                    element={<UpdateEmail />}
                  />
                  <Route
                    path="/account/settings/verify-identity"
                    element={<VerifyIdentity />}
                  />
                  <Route path="/account" element={<Overview />} />
                </Route>
              </Route>
            </Route>
            {/* End of LocationRoute */}

            <Route element={<PrivateRoute />}>
              <Route element={<OnboardedRoute />}>
                <Route
                  path="/account/settings/responsible-gambling/close-account"
                  element={<CloseAccount />}
                />
                <Route
                  path="/account/settings/responsible-gambling/take-a-break"
                  element={<TakeABreakInformation />}
                />
                <Route
                  path="/account/settings/responsible-gambling/take-a-break/form"
                  element={<TakeABreakForm />}
                />
                <Route
                  path="/account/settings/responsible-gambling/lifetime-exclusion"
                  element={<LifetimeExclusionInformation />}
                />
                <Route
                  path="/account/settings/responsible-gambling/lifetime-exclusion/form"
                  element={<LifetimeExclusionForm />}
                />
              </Route>
            </Route>

            <Route path="/signed-out" element={<SignedOut />} />
            <Route
              path="/exclusion-confirmed"
              element={<LifetimeExclusionConfirmation />}
            />

            <Route
              path="/account/settings/responsible-gambling"
              element={<ResponsibleGambling />}
            />

            <Route path="/*" element={<Navigate to="/" />} />
          </Route>

          <Route element={<SecondaryLayout />}>
            <Route element={<LocationRoute />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/signup" element={<SignUp />} />

              <Route element={<PrivateRoute />}>
                <Route path="/verification" element={<AccountVerification />} />
                <Route path="/deposit-limit" element={<DepositLimit />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  </>
);

export default AppRoutes;
