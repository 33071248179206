import { PersonalDetailsSchema } from './PersonalDetails.styles';

export const personalDetailsStyles: PersonalDetailsSchema = {
  wrapperBox: {
    p: '2',
    bg: 'alpha.500',
    borderRadius: 'md',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    sx: {
      '&&': {
        '.chakra-form__label': {
          color: 'white',
        },
        '.chakra-input': {
          borderRadius: 'md',
          bg: 'alpha.800',
          border: 'none',
          color: 'whiteAlpha.700',
          boxShadow: 'none',
          fontWeight: 'normal',
          opacity: '0.5',
        },
      },
    },
  },
};
