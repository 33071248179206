/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Text, Box, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useNTJ } from '@/hooks/useNTJ';
import { sortByRaceType, sortByWinOdds } from '@/helpers/sortRaces';
import { EGeneralStatus, ERaceType } from '@/lib/DBModels';
import { TNextToJump } from '@/api/nextToJump/nextToJump.types';
import Card, { CardListItem } from '../Card/Card';
import { useQueryRunnerList } from '@/api/racing/racing.hooks';
import { useQuerySupportedBets } from '@/api/punter/supportedBets/supportedBets.hooks';
import { favoriteStyles } from './styles/Favorites.styles';
import { transformBetForLegacy, useBetSlipBets } from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { TBetSlipBet } from '@/components/Betslip/Services/Betslip.types';
import { useAppSelector } from '@/hooks/useRedux';

export default function Favorites() {
  const { queries, isLoading } = useNTJ();
  const [searchParams] = useSearchParams();
  if (searchParams?.get('category')?.toLowerCase() === 'sport' || isLoading) {
    return <></>;
  }

  return (
    <Flex {...favoriteStyles.wrapper}>
      {queries
        .sort((a, b) =>
          sortByRaceType(a.data?.[0]?.race_type, b.data?.[0]?.race_type)
        )
        .filter((q) => q.data && q.data.length)
        .map((q) => q.data)
        .map((races) => races?.filter((race) => !!race.race_type))
        .map((q, i) => {
          const firstThree = q?.splice(0, 3) || [];
          return (
            <>
              <Box key={i} {...favoriteStyles.container}>
                <Box {...favoriteStyles.headerContainer}>
                  <Text {...favoriteStyles.header}>
                    <FormattedMessage id="generic.favorites" />
                  </Text>
                  <Text {...favoriteStyles.subHeader}>
                    {(() => {
                      const raceType = q?.[0].race_type;
                      if (raceType === ERaceType.Horse) return 'Thoroughbreds';
                      if (raceType === ERaceType.Harness) return 'Harness';
                      return raceType;
                    })()}
                  </Text>
                </Box>
                {firstThree.map((race, idx) => (
                  <Item key={`race-${idx}`} race={race ?? {}} />
                ))}
              </Box>
            </>
          );
        })}
    </Flex>
  );
}

/**
 * Containers
 */
type ItemContainerProps = Omit<ItemViewProps, 'bets'>;

const ItemReduxContainer = (props: ItemContainerProps) => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <ItemView bets={bets} {...props} />;
};

const ItemZustandContainer = (props: ItemContainerProps) => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <ItemView bets={bets} {...props} />;
};

const Item = FEATURE_FLAGS.HAS_NEW_BS
  ? ItemZustandContainer
  : ItemReduxContainer;

// ----

/**
 * View
 */
type ItemViewProps = {
  race: TNextToJump;
  bets: TBetSlipBet[];
};

function ItemView({ race, bets }: ItemViewProps) {
  const { data: dataRunner } = useQueryRunnerList({
    key: [race.race_id ?? ''],
    params: { race_id: race.race_id ?? '' },
    options: {
      enabled: !!race.race_id,
    },
  });
  const { data: dataSP } = useQuerySupportedBets();

  const runner =
    dataRunner
      ?.filter((racer) => racer?.status !== EGeneralStatus.Scratched)
      ?.filter((racer) =>
        racer?.win_proposition?.price_types?.find((type) => type !== 'starting')
      )
      ?.sort(sortByWinOdds)?.[0] || {};

  if (!runner?.win_proposition || !runner?.status) {
    return <></>;
  }

  return (
    <Card race={race}>
      {dataRunner?.length && (
        <CardListItem
          race={race}
          runner={runner}
          bets={bets}
          spBetsAreSupported={dataSP?.starting_price !== false}
          styles={{
            btn: { ...favoriteStyles.btn },
          }}
        />
      )}
    </Card>
  );
}
