import { CSSObject } from '@chakra-ui/react';
import horse from '../../../../../../assets/wellbet/images/homeFeaturedRacing/horse.png';
import featured from '../../../../../../assets/wellbet/images/homeFeaturedRacing/featured.svg';
import waterfade from '../../../../../../assets/wellbet/images/waterfade.png';

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: `url(${featured})`,
    bgRepeat: 'no-repeat',
    bgSize: '40%',
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
  },

  bgImage: `url(${waterfade})`,
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: `url(${horse})`,
    bgRepeat: 'no-repeat',
    bgSize: ['40%', null, '50%'],
    bgPos: 'bottom',
    pos: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
  },

  justifyContent: 'flex-start',
  pt: ['130px', null, '7'],
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
};
