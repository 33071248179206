export const en = {
  sport: 'Sports',
  'home.viewracecard': 'View race card',
  'header.betslipBtn.desc': 'Slip',
  'helpcentre.policy.association':
    "{themeName} is a member of the Northern Territory Bookmakers' Association and adheres to the Victorian Bookmakers Association Responsible Gambling Code of Conduct. A copy of the Code of Conduct can be downloaded from the <website>https://vicbookmakers.com.au/responsible-gambling/</website>",
  'helpcentre.policy.getHelp':
    '<link>https://www.gamblinghelponline.org.au</link>',
  'error.heading': 'Causeway Closed',
  'error.description':
    '<span>PuntCity is currently down.</span> Refresh the page, or try again later.',
  'maintenance.error':
    '<span>We are investigating the issue.</span> Refresh the page, or try again later.',
  'maintenance.heading': 'Citywide Maintenance',
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing Punt City from outside of Australia</b>',
  'onboarding.step1.pageSubtitle':
    'Punt City, the ultimate destination for racing and sports betting. Create an account and start placing bets now!',
  'onboarding.login.subtitle':
    'Welcome to <span>PuntCity</span>, where high-tech meets high stakes. Step into an electrifying paradise of <span>thrilling sports betting</span>.',
  'account.settings.responsible.lastupdated': 'Published: 14/04/2023',
  'betslip.betslipmodal.emptybetslipheading': 'Betless in the city',
  'betslip.betslipmodal.emptybetslipsubheading':
    'To get started, add your racing and sports selections.',
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'legal.responsible.section.takeaction.depositlimit.two':
    'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.depositlimit.button': 'Set limit',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.deactivate.button': 'Shut My Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': 'Exclude Yourself',
  'legal.responsible.section.takeaction.lifeexclusion.two':
    'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.lifeexclusion.button': 'Set Exclusion',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.break.two':
    'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.section.takeaction.break.button': 'Suspend My Account',
  'racing.errorMessages.noRacesAvailable': 'End of the line. Ride’s over.',
  'sports.allsports.azheading': 'A-Z Sports',
  'onboarding.locationnotallowed.header': 'Wrong City',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
};
