import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  'button[data-cy="updateDepositLimitButton"]': {
    bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
    borderRadius: 'md',
    color: 'alpha.800',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    _hover: {
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
      boxShadow:
        '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
      color: 'alpha.400',
      textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
    },
    ':active, &[data-active="true"]': {
      bg: 'blackAlpha.500',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
      color: 'alpha.400',
      textShadow: 'unset',
    },
    _disabled: {
      bg: 'blackAlpha.400',
      color: 'gray.300',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      boxShadow:
        '0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
      pointerEvents: 'none',
    },

    '&&&': {
      '& > span, & > svg': { zIndex: 9 },
    },
  },
};
