/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { cloneElement, ReactElement, isValidElement } from 'react';

type TemplateFallbackProps = {
  children?: ReactElement;
};

export function TemplateFallback({ children }: TemplateFallbackProps) {
  return <>{children}</>;
}

type PositionSchema =
  | { before: string; after?: never }
  | { before?: never; after: string };

type TemplateSchema = {
  name: string;
  component: ReactElement;
  config?: PositionSchema;
};

/**
 * Note: for switching different versions of components
 * do useTemplateSwitch.
 *
 * This will be exported from the shared repo (also tidied up).
 * Temporarily adding it here until the shared repo is ready to be
 * merged in.
 */
export function createTemplate(template: TemplateSchema[] = []) {
  let wrapper: ReactElement = <TemplateFallback />;

  const children = template.reduce<ReactElement[]>((acc, cur) => {
    if (cur.name === 'wrapper' && isValidElement(cur.component)) {
      wrapper = cur.component;
      return acc;
    }

    return [...acc, cur.component];
  }, []);

  return cloneElement(wrapper, {}, ...children);
}

export function combineTemplate(
  template: TemplateSchema[],
  themeImports: TemplateSchema[] = []
) {
  const initTemplate = [...template, ...themeImports];

  const newTemplate = initTemplate.reduce((arr, cur) => {
    const index = arr.findIndex((ele) => ele.name === cur.name);

    if (index !== -1) {
      arr[index] = cur;
      return arr;
    }

    return [...arr, cur];
  }, [] as TemplateSchema[]);

  const orderTemplate = newTemplate.reduce<TemplateSchema[]>(
    (acc, component) => {
      if (component.config?.before) {
        const beforeIndex = acc.findIndex(
          ({ name }) => name === component?.config?.before
        );
        beforeIndex >= 0
          ? acc.splice(beforeIndex, 0, component)
          : acc.push(component);
      } else if (component.config?.after) {
        const afterIndex = acc.findIndex(
          ({ name }) => name === component?.config?.after
        );
        afterIndex >= 0
          ? acc.splice(afterIndex + 1, 0, component)
          : acc.push(component);
      } else {
        acc.push(component);
      }
      return acc;
    },
    []
  );

  return orderTemplate;
}
