import { CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  width: 'full',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'alpha.600',
  p: '0',
};

export const DepositButton: CSSObject = {
  _disabled: {
    color: 'rgba(0, 0, 0, 0.24)',
    bg: 'gray.300',
    opacity: '1',
    boxShadow:
      '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
    border: '2px solid white',
  },
};

export const buttonRemoveProps: CSSObject = {
  variant: 'solid',
};

export const DepositLimitContainer: CSSObject = {
  bg: 'white',
  borderRadius: 'lg',
  p: '4',
  color: 'gray.600',
  fontWeight: 'bold',
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: 'bold',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '& .chakra-form__label': {
    color: 'white',
  },
  '&& .chakra-input__left-addon': {
    bg: 'alpha.300',
    fontSize: 'xs',
    h: '9',
    borderLeftRadius: 'base',
    color: 'alpha.800',
  },
  '& .chakra-select': {
    bg: 'zeta.100',
    borderRadius: 'base',
    fontSize: 'xs',
    h: '9',
    color: 'gray.600',
  },
  '&& .chakra-input': {
    borderRightRadius: 'base',
    fontSize: 'xs',
    h: '9',
    border: '1px',
    borderLeft: 'none',
    borderColor: 'gray.300',
    _placeholder: {
      color: 'gray.700',
    },
  },
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
  '.chakra-button': {
    bg: 'transparent',
    padding: '3',
    borderWidth: '2px',
    borderColor: 'alpha.700',
    width: '50%',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  '.chakra-button[data-cy="confirmDepositConfirmation"]': {
    bg: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  },
  '.chakra-button[data-cy="confirmDepositConfirmation"]:hover': {
    bg: 'beta.600',
  },
};
