import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/sugarcastle/images/homeFeaturedSport/featSport.png';
import featuredMb from '@/assets/sugarcastle/images/homeFeaturedSport/featSportMb.png';
import promoText from '@/assets/sugarcastle/images/homeFeaturedSport/promoText.png';

export const FlexWrapper: CSSObject = {
  p: '0',
  pl: '0',
  h: ['430px', null, '300px'],
  border: 'none',
  bg: [`url(${promoText}), url(${featuredMb})`, null, `url(${featureDesktop})`],
  bgPos: [
    'center top 30px, center bottom -140px',
    'center top 30px, center bottom -240px',
    'bottom',
  ],
  '&&': {
    bgRepeat: 'no-repeat',
  },
  bgSize: ['100%', null, '100%'],
  alignItems: 'center',
  justifyContent: 'flex-start',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['0', null, '2'],
  mt: '1',
  flexDir: ['column', null, 'row'],
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: '3',
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  color: 'beta.600',
  fontSize: '13px',
  fontWeight: 'black',
  borderRadius: 'sm',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.600',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.40), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'md',
  color: 'beta.300',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  fontWeight: 'extrabold',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: ['14px', '18px'],
  fontWeight: 'extrabold',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: ['fit-content', null, '380px'],
  mx: ['auto', 'unset'],
  ml: ['auto', '10%'],
  mt: ['2', '4'],
  display: 'none',
};

export const HeadingSlogan: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  fontFamily: 'Ultra',
  color: 'alpha.400',
  textTransform: 'uppercase',
  fontSize: ['22px', null, '30px'],
  lineHeight: '1',
  textShadow: '0px 3px 0px #5C0508, 0px 4px 4px rgba(0, 0, 0, 0.25)',
  pt: '3',
};

export const SpanSlogan: CSSObject = {
  color: 'delta.500',
  fontSize: ['34px', null, '46px'],
  pl: '2',
};

export const puntButtonProps: CSSObject = {
  maxW: '100%',
  w: 'unset',
  border: '2px solid',
  borderColor: 'beta.400',
  boxShadow:
    '0px 0px 0px 2px #14134B inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
  borderRadius: 'lg',
  color: 'beta.400',
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
  fontSize: '13px',
};

export const FlexTextWrapper: CSSObject = {
  position: ['unset', null, 'absolute'],
  width: ['unset', null, '60%'],
  mt: ['72px', null, 'unset'],
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: '3',
  mt: 2,
};
