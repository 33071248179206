import { CSSObject } from '@chakra-ui/react';
import cardsBg from '@/assets/junglebet/images/backgrounds/backgroundSport.png';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: ['0', '3'],
    gap: '3',
  },
  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    borderRadius: '2xl',
    boxShadow:
      '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 5px 0px 0px var(--bc-colors-delta-800), 0px 2px 2px 0px #D17917 inset, 0px -2px 1px 0px rgba(178, 78, 5, 0.50) inset',
    bg: `url(${cardsBg})`,
    bgRepeat: 'repeat',
    bgSize: 'cover',
  },
  LinkCardHeader: {
    color: 'white',
    py: '2',
  },
  TextCardHeading: {
    fontFamily: 'CarterOne',
    fontSize: 'sm',
    fontWeight: 'normal',
    letterSpacing: 'wide',
    color: 'delta.50',
    lineHeight: 'normal',
  },
  TextRaceNumber: {
    fontSize: '9px',
    bg: 'delta.700',
    borderRadius: 'base',
  },
  GridCardContent: {
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'delta.50',
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: 'normal',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 4px -3px rgba(0, 0, 0, 0.30) inset',
  },
  FlexGridRunnerItem: {
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    boxShadow: '0px 1px 0px 0px rgba(206, 119, 22, 0.30)',
    px: '3',
    _last: {
      border: 'none',
    },
  },
  TextRunner: {
    textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    fontSize: 'xs',
    color: 'delta.50',
    fontWeight: 'semibold',
  },
  TextRunnerNumber: {
    color: 'delta.50',
    fontSize: 'xs',
    fontWeight: 'bold',
  },
  LinkRaceDetails: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset',
    color: 'beta.500',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'capitalize',
    fontSize: 'md',
    fontFamily: 'CarterOne',
    fontWeight: 'normal',
    borderBottomRadius: '2xl',
    letterSpacing: 'wide',
    _hover: {
      bg: 'blackAlpha.500',
    },
    _focus: {
      bg: 'blackAlpha.500',
    },
    _disabled: {
      bg: 'gray.300',
      borderColor: 'gray.600',
      color: 'blackAlpha.200',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
      boxShadow:
        '0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.60) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
    },
  },
  TextScratched: {
    fontSize: '2xs',
    color: 'delta.50',
  },
  buttonRaceCardOddsProps: {
    variant: 'solid',
    fontSize: 'sm',
    w: '14',
    sx: {
      ':hover': {
        color: 'whiteAlpha.700',
      },
    },
  },
};

export const TextRunner: CSSObject = {
  color: 'delta.50',
};
