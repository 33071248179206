import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { TQueryCompetitions } from '@/api/sports/competitions.types';
import LinkItem from './LinkItem';
import { competitionsMenuStyles } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

type TCompetitionsMenu = {
  competitions: TQueryCompetitions[];
};
export default function CompetitionsMenu({ competitions }: TCompetitionsMenu) {
  const { sportName } = useParams();
  const groups = groupBy(competitions, ({ country }) =>
    country === null ? 'others' : country
  );
  const isTopLeagueExist = competitions.some((item) =>
    Boolean(item.is_top_league)
  );
  const intl = useIntl();

  return (
    <Accordion
      defaultIndex={[1]}
      allowMultiple
      {...competitionsMenuStyles.accordion}
    >
      <AccordionItem {...competitionsMenuStyles.accordionItem}>
        <AccordionButton {...competitionsMenuStyles.accordionButton}>
          <Text {...competitionsMenuStyles.accordionHeadingText}>
            <FormattedMessage
              id="sports.competitionsBySport.all"
              values={{ name: sportName }}
            />
          </Text>
        </AccordionButton>
      </AccordionItem>

      {isTopLeagueExist && (
        <AccordionItem {...competitionsMenuStyles.accordionItem}>
          <AccordionButton {...competitionsMenuStyles.accordionButton}>
            <Text {...competitionsMenuStyles.accordionHeadingText}>
              <FormattedMessage id="generic.topLeagues" />
            </Text>
            <AccordionIcon {...competitionsMenuStyles.accordionIcon} />
          </AccordionButton>

          <AccordionPanel {...competitionsMenuStyles.accordionPanel}>
            {competitions
              .filter((league) => !!league.is_top_league)
              .sort((a, b) => {
                if (a.sort_order === b.sort_order) {
                  return a.competition_name.localeCompare(b.competition_name);
                }
                return (a.sort_order ?? Infinity) - (b.sort_order ?? Infinity);
              })
              .map((competition) => (
                <LinkItem
                  key={competition.competition_id}
                  competition={competition}
                />
              ))}
          </AccordionPanel>
        </AccordionItem>
      )}

      {Object.entries(groups)
        .sort()
        .map(([country]) => (
          <AccordionItem
            key={groups[country][0].competition_id}
            {...competitionsMenuStyles.accordionItem}
          >
            <AccordionButton {...competitionsMenuStyles.accordionButton}>
              <Text {...competitionsMenuStyles.accordionHeadingText}>
                {groups[country][0].country ??
                  intl.formatMessage({ id: 'generic.countryOthers' })}
              </Text>
              <AccordionIcon {...competitionsMenuStyles.accordionIcon} />
            </AccordionButton>
            <AccordionPanel {...competitionsMenuStyles.accordionPanel}>
              {[...(groups[country] ?? [])]
                .sort((a, b) => {
                  if (a.sort_order === b.sort_order) {
                    return a.competition_name.localeCompare(b.competition_name);
                  }
                  return (
                    (a.sort_order ?? Infinity) - (b.sort_order ?? Infinity)
                  );
                })
                .map((competition) => (
                  <LinkItem
                    key={competition.competition_id}
                    competition={competition}
                  />
                ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
}
