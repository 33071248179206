import { CSSObject, FlexProps } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 2px 4px 0px var(--bc-colors-whiteAlpha-500) inset, 0px -3px 4px 0px var(--bc-colors-blackAlpha-500) inset',
  bg: 'alpha.400',
  borderRadius: 'md',
  p: '2',
  color: 'white',
  my: '0',
  gap: '0',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'body',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  p: '2',
  mb: '2',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.400',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
  gap: '0',
  py: '0',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'alpha.700',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  py: '3',
  color: 'beta.800',
  background: 'transparent',
  fontSize: 'xs',
  fontWeight: 'medium',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'base',
  border: '1.5px dashed var(--bc-colors-blackAlpha-400)',
  mb: '3',
  '.chakra-icon': {
    color: 'beta.800',
  },
};

export const flexEventProps: FlexProps = {
  sx: {
    "[class$='EventTitle']": {
      fontWeight: 'bold',
      fontSize: 'xs',
    },
  },
};

export const MultiCardInputContainer: CSSObject = {
  mb: '2',
};
