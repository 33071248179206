import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';
import bg from '@/assets/junglebet/images/backgrounds/clothTexture.png';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    boxShadow: 'md',
    borderRadius: 'md',
    overflow: 'hidden',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
  },
  accordionButton: {
    pl: '3',
    pr: '2',
    bgImage: `url(${bg}), linear-gradient(180deg, var(--bc-colors-delta-100) 0%, var(--bc-colors-delta-200) 100%)`,
    borderBottomWidth: '1px',
    borderBottomColor: 'delta.300',
    _hover: {
      bg: 'blackAlpha.400',
    },
  },
  accordionHeadingText: {
    flex: '1',
    fontSize: 'sm',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'delta.700',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  accordionIcon: {
    color: 'delta.700',
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
    bg: 'delta.200',
    color: 'delta.700',
    _last: {
      border: 'none',
    },
  },
};
