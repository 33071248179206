import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  bgGradient: 'linear(180deg, #FFE0A6 30%, #E4C180)',
  boxShadow:
    '0px 6px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px #B68452, 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 8px 0px rgba(255, 255, 255, 0.40) inset',
  borderRadius: 'base',
  p: '2',
  color: 'alpha.600',
  mt: '0',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'zeta.400',
  alignSelf: 'baseline',
  fontSize: '23px',
  h: [null, null, '23px'],
};

export const UnableToCreateMultiWrapper: CSSObject = {
  color: 'zeta.400',
  bg: 'rgba(0, 0, 0, 0.16)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 8px 0px rgba(0, 0, 0, 0.25) inset',
  p: '12px',
  gap: '1',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};
