import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  p: ['1', '2.5'],
};

export const BoxWrapper: CSSObject = {
  border: 'none',
  contain: 'unset',
  mb: '0',
};

export const LinkItem = (isFeatured: boolean, isFirstItem: number) => ({
  px: '2',
  py: '1.5',
  h: '9',
  bg: isFeatured ? 'white' : 'white',
  color: isFeatured ? 'white' : 'gamma.700',
  transition: 'all, .1s ease-in-out',
  borderTop: '1px',
  borderTopColor: isFeatured ? 'whiteAlpha.100' : 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20)',

  _hover: {
    bg: 'gray.200',
  },
  ...(isFirstItem && { borderTop: 0 }),
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: '1px',
  borderColor: 'gray.700',
  borderRadius: 'full',
  p: '1',
  transition: '.3s ease-in-out',
  color: 'gray.700',

  _hover: {
    color: 'gray.800',
  },
};
