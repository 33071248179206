/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { footerStyles } from '../styles/Footer.styles';
import {
  BlurbTextView,
  DownloadLinksView,
  AgeRequirementView,
  OurPartnersView,
  WeAcceptView,
  QuickLinksView,
  EmailUsView,
} from '../Footer';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { APP_VERSION } from '@/lib/Constants';
import Logo from '../conditionals/Logo/template';
import ThemeFooter from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeFooter, [
  {
    name: 'wrapper',
    component: <Container maxW="container.xl" {...footerStyles.container} />,
  },
  {
    name: 'logo',
    component: (
      <Box maxW="container.xl" {...footerStyles.footerBar}>
        <Logo />
        <DownloadLinksView />
      </Box>
    ),
  },
  {
    name: 'email',
    component: <EmailUsView />,
    config: {
      after: 'logo',
    },
  },
  {
    name: 'footer',
    component: (
      <Flex mb="2" {...footerStyles.footerContentContainer}>
        <Box {...footerStyles.blurb}>
          <BlurbTextView />
        </Box>
        <Flex
          flexDir={['column', null, 'row']}
          gap="4"
          mb={[2, 'unset']}
          textAlign={['center', 'left']}
        >
          <OurPartnersView />
          <WeAcceptView />
        </Flex>
        <QuickLinksView />
      </Flex>
    ),
  },
  {
    name: 'copyright',
    component: (
      <Flex
        flexDir={['column', 'row']}
        gap={['0', '4']}
        color="gray.700"
        alignItems={[null, 'baseline']}
        justifyContent="flex-start"
        mb="20"
      >
        <Flex {...footerStyles.copyrightContainer}>
          <Text {...footerStyles.copyright}>
            <FormattedMessage
              id="footer.copyrightNoTheme"
              values={{
                themeName: getThemeConfig().name,
                appVersion: APP_VERSION,
                abn: 'ABN 316 08 137 440',
              }}
            />
          </Text>
        </Flex>
        <AgeRequirementView />
      </Flex>
    ),
  },
]);
