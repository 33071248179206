const branding = {
  alpha: {
    50: '#C2EAF2',
    100: '#B0E2EC',
    200: '#8BD2E1',
    300: '#67C1D5',
    400: '#42B1CA',
    500: '#1EA1BE',
    600: '#188198',
    700: '#126172',
    800: '#0C404C',
    900: '#062026',
  },
  beta: {
    50: '#F6C3C4',
    100: '#F3B4B6',
    200: '#EF9699',
    300: '#EA787B',
    400: '#E65A5E',
    500: '#E13C41',
    600: '#C73337',
    700: '#AE2A2E',
    800: '#942124',
    900: '#7B181B',
  },
  gamma: {
    50: '#E1E1E1',
    100: '#D0CFCF',
    200: '#ADACAC',
    300: '#8B8A89',
    400: '#686766',
    500: '#464443',
    600: '#383636',
    700: '#2A2928',
    800: '#1C1B1B',
    900: '#0E0E0D',
  },
  theta: {
    50: '#F3D247',
    100: '#F0B344',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  theta: branding.theta,
  delta: {},
  epsilon: {},
};
