import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'normal',
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};
export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'body',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'beta.300',
  pos: 'relative',
  bottom: '2px',
};
export const GroupChevron: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  borderRadius: 'md',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
  color: 'gamma.400',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  boxSize: '6',
  minW: 'unset',
  border: 'none',
  transition: 'none',
  _hover: {
    color: 'gamma.400',
    bgGradient: 'linear(to-b, alpha.200, alpha.300)',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
    border: 'none',
  },
  '&&&': {
    '& > span, & > svg': { zIndex: 9, transition: 'none' },
  },
  ':active, &[data-active="true"]': {
    color: 'gamma.400',
    bgGradient: 'linear(to-b, alpha.200, alpha.300)',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
    border: 'none',
  },
};
export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};
export const GroupIcon: CSSObject = {
  color: 'white',
};
