import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  border: 'unset',
  borderRadius: 'lg',
  overflow: 'hidden',

  '.chakra-accordion__item': {
    _first: {
      borderTop: '0',
    },

    _last: {
      borderBottom: '0',
    },
  },

  '.chakra-accordion__panel': {
    bg: 'gamma.300',
    color: 'gray.300',
  },

  '.chakra-checkbox__control': {
    bg: 'white',

    _checked: {
      bg: 'black',
    },
  },
};

export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
};

export const AccordionHeaderLogOut: CSSObject = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  border: 'unset',
  borderRadius: 'lg',
};

export const IconPerson: CSSObject = {
  boxSize: ['5', null, '6'],
  mr: '1.5',
  filter: 'brightness(0) saturate(100%) invert(100%)',
};

export const TextAccordion: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  fontWeight: 'semibold',
  mr: 'auto',
};

export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  bg: 'alpha.800',
  borderRadius: 'lg',
  boxShadow: '0 4px 5px rgba(0, 0, 0, .1)',
  color: 'white',
  fontSize: 'xs',
  gap: '1',
  p: '2',
};
