import { Box } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LinkHighlight, LinkSignup } from '../styles/Login.styles';

export default function SignUpCTA() {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <LinkSignup to="/signup">
        <FormattedMessage
          id="onboarding.login.signup"
          values={{
            cta: (string) => (
              <LinkHighlight data-cy="joinNow" as="span">
                {string}
              </LinkHighlight>
            ),
          }}
        />
      </LinkSignup>
    </Box>
  );
}
