import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },
  FlexCard: {
    my: '1',
    border: 'none',
    borderRadius: 'lg',
    bgGradient: `linear(to-b, rgba(97, 116, 182, 0.7), rgba(68, 85, 144, 0.7))`,
    boxShadow:
      '0px 1px 5px 0px rgba(130, 151, 222, 1) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.45)',
  },
  LinkCardHeader: {
    bg: 'transparent',
  },
  TextCardHeading: {
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    lineHeight: 'normal',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    pt: '0.5',
  },
  TextRaceNumber: {
    bg: 'beta.800',
    borderRadius: 'sm',
    color: 'alpha.400',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    lineHeight: '16px',
  },
  GridCardContent: {
    color: 'white',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.500',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'medium',
    lineHeight: 'normal',
    fontFamily: 'body',
    px: '2',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px dashed',
    borderTop: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    borderTopColor: 'rgba(255, 255, 255, 0.07)',
    px: '2.5',
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'semibold',
  },
  TextRunnerNumber: {
    sx: {
      _last: {
        color: 'white',
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },
  TextScratched: {
    color: 'white',
  },
  LinkRaceDetails: {
    lineHeight: '27px',
    h: '42px',
    bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
    borderRadius: 'md',
    color: 'alpha.800',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    _hover: {
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
      boxShadow:
        '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
      color: 'alpha.400',
      textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
    },
    m: 2.5,
  },
  buttonRaceCardOddsProps: {
    variant: 'odds',
    fontFamily: 'accent',
    fontSize: 'xs',
    h: '8',
    w: '46px',
  },
};
export const TextRunner: CSSObject = {
  color: 'white',
};
export const IconCardHeader: CSSObject = {
  color: 'alpha.400',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
