import { BoxProps } from '@chakra-ui/react';

export const wrapper: BoxProps = {
  px: 0,
};

export const InfoExoticsContainer: CSSObject = {
  bg: 'transparent',
  pl: 8,
};
