import { CSSObject } from '@chakra-ui/react';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    border: '1px',
    borderColor: 'beta.300',
    color: 'beta.300',
    minW: '50px',
    borderRadius: 'sm',
  },
};

export const FlexKeypadWrapper: CSSObject = {
  bg: 'alpha.900',
};

export const ButtonKeys = (isBatch: boolean): CSSObject => ({
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
  boxShadow: 'inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  ...(isBatch && {
    bg: 'linear-gradient(230.86deg, #DD7C39 -0.23%, #F98A3E 98.14%)',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
  }),
  '&.keypad-btn-done': {
    textTransform: 'uppercase',
    bg: 'linear-gradient(180deg, var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
    fontSize: 'md',
  },
});
