import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear(to-b, beta.500, beta.600)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  minh: '63px',
  mb: '2',
  px: '2',
  py: '2',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'alpha.400',
  justifyContent: 'space-between',
};

export const ButtonGetVerified: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  color: 'black',
  borderRadius: 'base',
  boxShadow:
    '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #FFF inset',
  display: 'inline-flex',
  fontFamily: 'CrimsonPro',
  fontSize: 'md',
  fontWeight: 'black',
  textShadow: '0 1px 0 rgba(255, 255, 255, 0.45)',
  h: ['9', null, '8'],
  lineHeight: '4',
  justifyContent: 'center',
  px: '2.5',
  textAlign: 'center',
  textTransform: 'uppercase',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  whiteSpace: [null, null, 'nowrap'],
  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.700, alpha.700)',
    color: 'blackAlpha.800',
    textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.45)',
    boxShadow:
      '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #FFF inset',
  },
  _active: {
    boxShadow: '0px 3px 0px 4px rgba(0, 0, 0, 0.30) inset',
    textShadow: 'none',
    bg: 'beta.600',
  },

  '.btn-span': {
    _active: {
      bgGradient: 'linear(to-b, alpha.400, alpha.700)',
      textShadow: 'none',
      bgClip: 'text',
    },
  },
};
