import { EMarketMoverSchema } from '@/lib/DBModels';
import redFire from '@/assets/core/animations/redFire.json';
import orangeFire from '@/assets/core/animations/orangeFire.json';
import yellowFire from '@/assets/core/animations/yellowFire.json';

export const getMarketMoverAnimation = (marketMover?: EMarketMoverSchema) => {
  if (marketMover === EMarketMoverSchema.FireHigh) return redFire;
  if (marketMover === EMarketMoverSchema.FireMedium) return orangeFire;
  if (marketMover === EMarketMoverSchema.FireLow) return yellowFire;
  return null;
};
