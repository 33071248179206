import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  borderBottom: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  mb: '0',
  px: '4',
  mt: '2',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '9',
};
