import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const button = {
  fontFamily: 'KHInterference',
  fontSize: 'sm',
  textTransform: 'uppercase',
  h: '9',
  '&&': {
    borderRadius: 'md',
  },
};

const primary = {
  ...button,
  bg: 'linear-gradient(347deg, #25B871 9.35%, #53F9A9 90.66%)',
  color: 'blackAlpha.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  fontWeight: 'black',

  _disabled: {
    bg: 'gray.400',
    borderWidth: '2px',
    borderColor: 'gray.300',
    color: 'blackAlpha.400',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    border: 'none',
    opacity: '1',

    _hover: { bg: 'gray.400' },
  },

  '&[data-active="true"]': {
    bg: 'beta.500',
    color: 'blackAlpha.700',
    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
    border: 'none',
  },

  _hover: {
    bg: 'linear-gradient(347deg, #0cb865 9.35%, #38ba7b 90.66%)',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  },
  _focus: {
    bg: 'linear-gradient(347deg, #0cb865 9.35%, #38ba7b 90.66%)',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  },
};

const secondary = {
  ...button,
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: 'none',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
  fontWeight: 'black',

  '&:hover, &:focus': {
    bg: 'beta.400',
  },
  '&:active': {
    bg: 'beta.800',
    boxShadow: 'inset 0px 3px 0px rgba(0, 0, 0, 0.2)',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    boxShadow: 'inset 0px 3px 4px #000000',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      border: '2px',
      borderColor: 'white',
      color: 'white',
      fontWeight: 'bold',
      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.20))',

      '&:active, &:hover': {
        bg: 'whiteAlpha.300',
        borderColor: 'white',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
    }),
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
