import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Switcher } from '../../styles/Toggle.styles';

export default function Switch() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  return (
    <Switcher
      onChange={() => {
        setSearchParams({
          category: !category || category === 'racing' ? 'sport' : 'racing',
        });
      }}
      isChecked={category && category !== 'racing'}
      colorScheme="blackAlpha"
      size="lg"
      data-cy="categorySwitcher"
    />
  );
}
