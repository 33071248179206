import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    boxShadow: 'md',
    borderRadius: 'md',
    overflow: 'hidden',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
  },
  accordionButton: {
    pl: '3',
    pr: '2',
    bgGradient: 'linear(to-b, beta.200, beta.300)',

    borderBottomWidth: '1px',
    borderBottomColor: 'beta.500',
    borderTopWidth: '1px',
    borderTopColor: 'beta.100',
    _hover: { bgGradient: 'linear(to-b, beta.200, beta.300)' },
  },
  accordionHeadingText: {
    flex: '1',
    fontSize: 'sm',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  accordionIcon: {
    color: 'alpha.500',
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
    bg: 'beta.600',

    _last: {
      border: 'none',
    },
  },
};
