import {
  ButtonProps,
  chakra,
  ChakraComponent,
  Radio,
  RadioProps,
  Stack,
  Text,
  Flex,
  TextProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './DepositLimit.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { TBodyTextProps } from '../types';
import { Button } from '@/components/Button/Button';
import { TFormControl } from '@/components/FormElements/HOCs/FormControl';

const themeName = getThemeName();

export const titleProps: TextProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.titleProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const inputProps: Partial<TFormControl> = {
  ...(() => {
    try {
      return styleImports[themeName]?.inputProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const Subheader = styled.h3`
  display: flex;
  flex-direction: row;
  margin-bottom: var(--bc-sizes-5);
  align-items: center;
  position: relative;
  padding-left: var(--bc-sizes-6);
  min-height: var(--bc-sizes-5);
  text-transform: uppercase;

  ${({ theme }) => theme.pages.depositLimit.subHeader.text};
`;

export const SubheaderIcon = styled.div`
  position: absolute; // Using absolute positioning to ensure header text doesn't wrap below icon
  top: 0;
  left: 0;
  width: var(--bc-sizes-5);
  height: var(--bc-sizes-5);
  background-repeat: no-repeat;
  background-size: contain;

  ${({ theme }) => theme.pages.depositLimit.subHeader.icon};
`;

export const BodyText = chakra<TBodyTextProps>(Text, {
  label: 'onboarding-depositLimit-BodyText',
  shouldForwardProp: (prop) => !['heading'].includes(prop),
  baseStyle: ({ heading }: any) => ({
    mb: '4',
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: 'base',
    textTransform: 'initial',
    '&:last-of-type': {
      mb: '5',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.BodyText?.({
          heading,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RadioText = chakra(Text, {
  label: 'onboarding-depositLimit-RadioText',
  baseStyle: () => ({
    fontSize: 'md',
    fontWeight: 'normal',

    ...(() => {
      try {
        return styleImports[themeName]?.RadioText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RadioButtonWrapper = chakra(Stack, {
  label: 'onboarding-depositLimit-RadioButtonWrapper',
  baseStyle: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',

    ...(() => {
      try {
        return styleImports[themeName]?.RadioButtonWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RadioLimitChoice = chakra(Radio, {
  label: 'onboarding-depositLimit-RadioLimitChoice',
  baseStyle: () => ({
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.RadioLimitChoice;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'input', RadioProps>;

export const ButtonSubmit = chakra(Button, {
  label: 'onboarding-depositLimit-ButtonSubmit',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonSubmit;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const DepositLimitContainer = chakra(Stack, {
  label: 'onboarding-depositLimit-DepositLimitContainer',
  baseStyle: ({ isActive }: any) => ({
    display: isActive ? 'flex' : 'none',
    flexDir: 'column',
    flex: 1,
    mt: '5',
    ...(() => {
      try {
        return styleImports[themeName]?.DepositLimitContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexHeaderWrapper = chakra(Flex, {
  label: 'onboarding-depositLimit-FlexHeaderWrapper',
  baseStyle: () => ({
    justifyContent: ['space-between', null, 'flex-start'],
    ...(() => {
      try {
        return styleImports[themeName]?.FlexHeaderWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
