import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'delta.600',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200), 0px 3px 5px 0px var(--bc-colors-blackAlpha-500) inset',
    borderRadius: 'md',
    my: '2',
    px: '4',
    sx: {
      button: {
        textShadow: '0px 1px 0px var(--bc-colors-whiteAlpha-400)',
        mb: '0',
        pb: '0',
        fontFamily: 'accent',
        fontWeight: 'normal',
        fontSize: 'sm',
        bg: 'none',
        filter: 'drop-shadow(0px 2px 4px 0px var(--bc-colors-whiteAlpha-400))',
        borderBottom: '2px solid',
        borderBottomColor: 'transparent',
        ':active, &[data-active="true"]': {
          color: 'beta.500',
          fontWeight: 'normal',
          border: 'none',
          borderBottom: '2px solid',
          borderBottomColor: 'beta.500',
        },
      },
    },
  },
  heading: {
    mb: '2',
    color: 'beta.800',
    fontWeight: '700',
  },
  promotionWrapper: {
    p: '0',
    bg: 'beta.500',
    my: '3',
    boxShadow: 'lg',
    borderRadius: 'md',
  },
  flexWrapper: {
    gap: '4',
    py: '4',
    px: '4',
    justifyContent: 'space-between',
    color: 'white',
    bg: 'delta.500',
    boxShadow: '0px 3px 3px 0px var(--bc-colors-blackAlpha-400)',
  },
  termsAndConditions: {
    color: 'white',
    fontSize: 'sm',
    pt: '1',
    fontWeight: 'bold',
  },
};
