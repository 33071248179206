import { AxiosError } from 'axios';
import api from '@/api/api';
import { TError, TEvenShotParams, TEvenShotResponse } from './evenShot.types';

export const queryEvenShotURL = '/punter/pricing/even-shot';

export const queryEvenShot = (params: TEvenShotParams) =>
  api
    .get<TEvenShotResponse>(queryEvenShotURL, { params })
    .then((res) => res.data)
    .catch((error: AxiosError<TError>) => {
      // eslint-disable-next-line
      throw error.response?.data.detail;
    });
