import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  mt: '-2',
  pt: '0',
  pb: ['2', '4'],
  px: ['0', '4'],
  minH: '50vh',
};

export const BoxWrapper: CSSObject = {
  mb: '0',
  borderRadius: 'lg',
  background: 'none',
  border: 0,
};

export const BoxSectionWrapper: CSSObject = {
  mb: '2',
  px: ['2', '0'],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderRadius: 'base',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  border: 0,
  bg: 'transparent',
};

export const TextSport: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
};

export const LinkItem = (_: boolean, isFirstItem: boolean) => ({
  display: 'flex',
  bg: 'white',
  px: '2',
  py: '1.5',
  h: '9',
  alignItems: 'center',
  gap: '6px',
  alignSelf: 'stretch',
  borderTop: '1px',
  borderTopColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20)',

  ...(isFirstItem && { borderTop: 0 }),
  _hover: {
    bg: 'gray.200',
  },
});

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: '1px',
  borderColor: 'alpha.700',
  borderRadius: 'full',
  p: '1',
  transition: '.3s ease-in-out',
  color: 'alpha.700',

  _hover: {
    color: 'beta.800',
  },
};
