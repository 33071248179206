/* eslint-disable no-nested-ternary */
import { useQueryOfferings } from '@/api/punter/general/offerings/offerings';
import { useQueryPunterAccount } from '@/api/punter/punter.hooks';
import { SportsGrouping } from '../types';

export const useAllSports = () => {
  const {
    data: dataAccount,
    isInitialLoading: isInitialLoadingAccount,
    isLoading,
    isEnabled,
  } = useQueryPunterAccount();
  const { data: dataOfferings, isInitialLoading: isInitialLoadingOfferings } =
    useQueryOfferings({
      options: {
        enabled: isEnabled ? !isLoading : true,
      },
      params: { pc: dataAccount?.pc },
    });

  // Sort alphabetically
  const sportsSorted = (dataOfferings?.offered_sports ?? []).sort(
    (a, b) => a.display_name?.localeCompare(b.display_name ?? '') ?? 0
  );

  /**
   * Group sports alphabetically
   *
   * TODO:
   * This is copy paste from older code and could do with some love
   */
  const sportsGrouped = sportsSorted?.reduce<SportsGrouping[]>((acc, curr) => {
    const letter = curr?.display_name ? curr?.display_name[0] : '';
    const currentGrouping = acc.find((ele) => ele.letter === letter);
    if (!currentGrouping || !curr) {
      return [...acc, { letter, sports: [curr] }];
    }

    currentGrouping?.sports?.push(curr);
    return acc;
  }, []);

  return {
    sportsGrouped,
    isInitialLoading: isInitialLoadingAccount || isInitialLoadingOfferings,
  };
};
