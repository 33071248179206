import { CSSObject } from '@chakra-ui/react';

export const GridItemBetslip: CSSObject = {
  h: '98%',
  '& > div[class*="-BetSlipModalContainer"]': {
    zIndex: '1',
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
  },
};

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
  maxWidth: ['inherit', '272px'],
};
