import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, gamma.500, gamma.700)',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
  },
  depositIconProps: {
    color: 'beta.400',
    boxSize: '9',
  },
};
