import { rest } from 'msw';
import { sportMarketGroupsEndpoint } from './sportDetailsMarketGroups';
import { querySportMarketGroupsMock } from './sportDetailsMarketGroups.mocks';
import { TSportMarketGroupsResponse } from './sportDetailsMarketGroups.types';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

export const sportDetailsMarketGroupsHandlers = [
  rest.get(`${baseURL}/${sportMarketGroupsEndpoint}`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json<TSportMarketGroupsResponse>(querySportMarketGroupsMock)
    )
  ),
];
