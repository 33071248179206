import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { chakra, CSSObject, Flex, Text } from '@chakra-ui/react';
import { rgba } from 'polished';
import IconSvg from '../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';

export const tileLinkContainerStyles = (themeName: any): CSSObject => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr 1fr',
  gap: '0',
  gridTemplateAreas: `
      "sportName sportName"
      "raceNumber raceNumber"
      "icon timer"`,

  py: '3',
  px: '2',
  textDecoration: 'none',
  transition: 'all .1s ease-in-out',
  borderRadius: 'md',
  minWidth: '157px',

  ...(themeName === 'wellbet' && {
    bg: 'white',
    p: '3',
    _hover: { bg: 'gray.100' },
  }),
  ...(themeName === 'betgalaxy' && {
    color: 'white',
    p: '3',
    minWidth: '147px',
    mb: '5px',
    _hover: { bg: 'beta.400' },
  }),
});

export const SportsTileContainer = chakra(Link, {
  baseStyle: ({ theme }) => ({
    ...tileLinkContainerStyles(theme.themeName),
    ...(theme.themeName === EThemes.Wellbet && {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: `
      "raceNumber raceNumber raceNumber"
      "sportName sportName sportName"
      "timer timer timer"`,
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      gridTemplateColumns: '1fr 1fr 1fr',
      bg: rgba(`${theme.colors.beta[500]}`, 0.5),
      gridTemplateAreas: `
      "raceNumber raceNumber raceNumber"
      "sportName sportName sportName"
      "timer timer timer"`,
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      gridTemplateColumns: '1fr 1fr 1fr',
      bg: rgba(`${theme.colors.beta[500]}`, 0.5),
      gridTemplateAreas: `
      "raceNumber raceNumber raceNumber"
      "sportName sportName sportName"
      "timer timer timer"`,
    }),
  }),
});

export const headingStyles = (themeName: any) => ({
  fontSize: 'xs',
  mb: '1',
  display: 'block',
  whiteSpace: 'nowrap',
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  ...(themeName === 'wellbet' && {
    fontSize: 'sm',
    fontWeight: 'bold',
  }),
  ...(themeName === 'betgalaxy' && {
    fontSize: 'sm',
    fontWeight: 'semibold',
  }),
});

export const SportsHeading = chakra(Text, {
  baseStyle: ({ theme }) => ({
    ...headingStyles(theme.themeName),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'beta.700',
      mt: '1',
      fontSize: 'xs',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'gamma.100',
    }),
  }),
});

export const TopContent = chakra(Flex, {
  baseStyle: () => ({
    maxW: 'full',
    flexDir: 'column',
    flex: '1',
  }),
});

export const subheadingStyles = (themeName: any) => ({
  fontSize: 'xs',
  whiteSpace: 'nowrap',
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  ...(themeName === 'wellbet' && {
    fontWeight: 'bold',
  }),
  ...(themeName === 'betgalaxy' && {
    fontWeight: 'bold',
  }),
});

export const SportsSubheading = chakra(Text, {
  baseStyle: ({ theme }) => ({
    ...subheadingStyles(theme.themeName),
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'beta.500',
      fontSize: 'sm',
      alignSelf: 'center',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'sm',
    }),
  }),
});

export const iconStyles = (themeName: any) => ({
  boxSize: '6',
  ...(themeName === 'betgalaxy' && {
    boxSize: '10',
    alignSelf: 'center',
    ml: '1',
  }),
});

export const SportsIcon = chakra(IconSvg, {
  baseStyle: ({ theme }) => ({
    ...iconStyles(theme.themeName),
    ...(theme.themeName === EThemes.Wellbet && {
      display: 'none',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      display: 'none',
    }),
  }),
});

export const BottomContent = styled.div`
  ${({ theme }) => theme.features.home.eventTile.bottomContent};
`;

export const SportsDateTimeWrapper = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      flex: '1',
    }),
  }),
});

export const SportsDateTime = chakra(Text, {
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: '2xs',
      fontWeight: 'medium',
      alignSelf: 'center',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'xs',
      fontWeight: 'medium',
      alignSelf: 'center',
      mr: '1',
    }),
  }),
});
