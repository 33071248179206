import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  borderRadius: 'md',
  contain: 'paint',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  mb: '3',
  border: '0',

  ...(isNested && {
    boxShadow: 'unset',
    mb: '2',
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  border: 0,
  p: '4',
  borderBottomRadius: 'md',
  bgGradient: 'linear(to-b, #696969, #696969)',

  ...(isNested && {
    border: '1px',
    borderTop: 0,
    borderColor: 'blackAlpha.400',
    bgGradient: 'linear(to-b, #696969, #696969)',
    boxShadow:
      '0px 3px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: '#696969',
  color: 'gamma.200',
  px: '2',
  py: '2.5',
  border: '1px solid',
  borderColor: 'blackAlpha.400',
  borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 'lg',
  fontWeight: 'normal',
  fontSize: 'sm',
  fontFamily: 'accentStaat',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  justifyContent: 'space-between',

  _hover: {
    bg: 'alpha.500',
  },

  '.chakra-accordion__icon': {
    boxSize: '5',
    color: 'white',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    borderBottomRadius: 'none',
  },

  ...(isNested && {
    bg: '#696969',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'gamma.200',
    fontWeight: 'normal',
    px: '2',

    _hover: {
      bg: 'alpha.600',
    },

    '.chakra-accordion__icon': {
      boxSize: ['4', '5'],
      transition: '.3s ease-in-out',
    },
  }),
});

export const BoxWrapper: CSSObject = {
  py: '2',
  px: '0',
  borderBottomRadius: '6px',
};
