import { NewMatchCardSchema } from '@/views/sports/components/NewMatchCard/styles/NewMatchCard.styles';

export const newMatchCardStyles: NewMatchCardSchema = {
  boxWrapper: {
    bg: 'beta.300',
    borderRadius: 'base',
    boxShadow:
      '0 3px 5px rgba(0, 0, 0, .2), 0 1px rgba(255, 255, 255, .15) inset',
    overflow: 'hidden',
  },
  bodyStack: {
    p: 2,
  },
  titleHeading: {
    cursor: 'pointer',
    as: 'h5',
    fontFamily: 'roboto',
    fontSize: 'sm',
  },
  footerFlex: {
    as: 'footer',
    bg: 'beta.200',
    boxShadow: '0 -2px rgba(0, 0, 0, .15), 0 -1px rgba(255, 255, 255, .25)',
    fontSize: '2xs',
    fontWeight: 'semibold',
    px: '2',
    py: '1',
    alignItems: 'center',
    gap: '1',
  },
  footerStack: {
    direction: 'row',
  },
  footerStackDivider: {
    height: '2.5',
    borderColor: 'alpha.600',
  },
  footerSportNameFlex: {
    align: 'center',
    gap: '1',
    maxW: '85%',
    w: 'fit-content',
    mr: '1.5',
  },
  footerSportNameIconSvg: {
    color: 'alpha.600',
    fontSize: 'sm',
  },
  footerCompetitionNameText: {
    sx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 'initial',
    },
  },
  footerCountDown: {
    pt: '0',

    sx: {
      '&&&.state-prior': {
        p: 0,
        lineHeight: 'initial',
        textTransform: 'uppercase',
      },
    },
    sxWrapper: {
      mr: 'auto',
    },
  },
  footerDuotoneSgmLogo: {
    h: 'auto',
    primaryColor: 'alpha.500',
    secondaryColor: 'beta.600',
    w: '10',
  },
  footerMatchLink: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: 'initial',
  },
  footerMatchLinkIcon: {
    color: 'alpha.600',
    fontSize: 'sm',
  },
};
