import { ButtonProps, CSSObject } from '@chakra-ui/react';
import mascot from '../../../../../../assets/goldenrush/images/header/imageMascot.png';

export const buttonBalanceProps: ButtonProps = {
  variant: 'unstyled',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'normal',
  fontSize: 'sm',
  display: 'flex',
  pt: '2px',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
  border: 'none',
  maxH: '29px',
  '> button': {
    '> span': {
      fontWeight: 'semibold',
      fontSize: 'sm',
    },
    '> .chakra-button__icon': {
      top: '-1px',
      position: 'relative',
      svg: {
        w: '15px',
        h: '15px',
      },
    },
  },
};

export const BoxBallance: CSSObject = {
  bg: 'alpha.500',
  padding: '2',
  borderBottomRightRadius: 'md',
  borderBottomLeftRadius: 'md',
  '> div:first-of-type': {
    color: 'gamma.500',
    '> p': {
      fontSize: 'md',
      fontWeight: 'normal',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
  '> div:last-of-type': {
    color: 'alpha.50',
    '> p': {
      fontSize: 'md',
      fontWeight: 'normal',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
};

export const FlexBallanceItem: CSSObject = {
  mb: '2',
  p: {
    fontSize: 'sm',
    fontWeight: 'extrabold',
  },
};

export const PopoverBodyQA: CSSObject = {
  bg: 'white',
};

export const buttonDepositProps: ButtonProps = {
  w: '100%',
  sx: {
    '.chakra-button__icon': { mt: '-1' },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  color: 'alpha.600',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderTop: '1px',
  borderTopColor: 'gray.200',
  ':first-child': {
    borderTop: 'none',
  },
  bg: 'white',
  maxH: '41px',
  '.chakra-icon': {
    boxSize: '5',
  },

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.300',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImg: `url(${mascot})`,
  bgRepeat: 'no-repeat',
  bgSize: '100% 100%',
  top: '-16px',
  display: ['none', null, null, null, 'block'],
  pos: 'absolute',
  h: '116px',
  w: '259px',
  left: '-100px',
  zIndex: '0',
  'body[data-sensitive="true"] &': {
    display: 'none',
  },
};

export const PopoverContentQA: CSSObject = {
  border: ['none', '3px'],
  h: ['100%', null],
  borderRadius: ['none', 'lg'],
  borderColor: 'white',
  top: '-7px',
};
