import React from 'react';
import {
  Box,
  Flex,
  Modal as BCModal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { getStrings } from '@/helpers/utils';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { setBetSlipViewMode } from '../../../../redux/Betslip.slices';
import { EBetSlipViewMode } from '../../Services/Betslip.types';
import { ClearBetsModalHeader, ClearModalButton } from './EmptyBetModal.styles';

const EmptyBetsModal: React.FC<TEmptyBetsModalProps> = ({ isOpen = false }) => {
  const [
    {
      BetSlip: { emptyBetsModal: Strings },
    },
  ] = getStrings();
  const dispatch = useAppDispatch();
  const keepAll = () => {
    dispatch(setBetSlipViewMode(EBetSlipViewMode.EditingBets));
  };
  return (
    <BCModal
      isOpen={isOpen}
      onClose={keepAll}
      closeOnOverlayClick
      blockScrollOnMount
      isCentered
    >
      <ModalOverlay />
      <ModalContent data-cy="clearBetsModal">
        <ModalBody>
          <ClearBetsModalHeader>{Strings.warningHeading}</ClearBetsModalHeader>
          <Box textAlign="left" fontSize="md" mb="6">
            {Strings.emptyModalMessage}
          </Box>
          <Flex gap="2" pb="3" flexDirection="row-reverse">
            <ClearModalButton onClick={keepAll} data-cy="closeEmptyBetsModal">
              {Strings.modalButton}
            </ClearModalButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </BCModal>
  );
};
type TEmptyBetsModalProps = {
  isOpen?: boolean;
};
export default EmptyBetsModal;
