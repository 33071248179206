import { CSSObject } from '@chakra-ui/react';

export const OddsChangedContainer = (
  isInFooter: boolean,
  direction: string
): CSSObject => ({
  display: isInFooter ? 'none' : 'flex',
  minH: '12',
  p: '2.5',
  mb: '1',
  mx: isInFooter ? '0' : '2',
  alignItems: 'center',
  justifyItems: 'center',
  borderRadius: 'lg',
  border: '2px solid',
  borderColor: direction === 'increase' ? 'green.800' : 'yellow.800',
});
