import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  footer: {
    pt: '0',
    bg: 'transparent',
    bgSize: 'cover',
    bgPos: 'bottom',
    color: 'white',
  },
  stackGap: '1',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    mb: ['4', '8'],
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
    sx: {
      '& > img': {
        w: '180px',
        ml: '0',
        mb: ['2', null, '-1'],
      },
    },
  },
  footerLink: {
    color: 'beta.400',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  footerLinkTel: {
    color: 'beta.400',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  footerLinkService: {
    color: 'beta.400',
    fontSize: 'md',
  },
  customFooterLink: {
    color: 'beta.400',
    textTransform: 'capitalize',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: 'base',
  },
  footerMobile: {
    borderRadius: 'lg',
    w: '128px',
    h: '38px',
    cursor: 'pointer',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.3)',
    _odd: {
      mr: '2',
    },
  },
  footerLinks: {
    ml: ['unset', null, 'auto'],
    pb: ['2', null, '0'],
    alignItems: 'center',
    justifyContent: ['space-around', null, 'unset'],
    color: 'beta.200',
    fontWeight: 'medium',
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
  },
  footerContentContainer: {
    px: 2,
    gap: ['2', null, null, '6'],
    bg: [null, null, 'transparent'],
    justifyContent: 'space-between',
    sx: {
      '&': {
        flexDirection: 'column',
      },
    },
  },
  nav: {
    fontSize: 'sm',
    fontWeight: 'medium',
    minW: [null, null, '370px'],
    mt: 'unset',
    display: ['flex', 'grid'],
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '28',
    rowGap: '1',
    alignItems: ['center', 'end'],
    gridTemplateAreas: `
      ". ."
    `,
    sx: {
      '> a': {
        color: 'white',
      },
    },
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', 'left'],
    color: 'white',
    width: 'unset',
    mb: '0',
    ml: [null, '0'],
    mr: [null, null, '4'],
  },
  blurbHeading: {
    ml: 'unset',
    mt: 'unset',
    color: 'white',
  },
  blurbHeadingAge: {
    borderColor: 'white',
    color: 'white',
    border: '4px',
    mb: '2',
    boxSize: '8',
    fontSize: 'xs',
  },
  blurbHeadingThink: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    mb: '3',
  },
  blurbContent: {
    ml: 'unset',
    mr: [null, null, 'auto'],
  },
  copyright: {
    color: 'white',
    textAlign: ['center', 'left'],
    mb: '2',
    px: ['8', 'unset'],
    py: '0',
    fontWeight: ['semibold', 'medium'],
  },
  copyrightContainer: {
    color: 'white',
    px: 2,
  },
  container: {
    bg: 'transparent',
    mt: ['0', '3'],
    mb: '24',
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  footerDownloadImage: {
    borderRadius: 'md',
    border: '2px solid white',
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.30)',
  },
  emailUs: {
    fontSize: 'md',
    fontWeight: 'bold',
    color: 'white',
    px: 2,
  },
  quickLinks: {
    color: ['beta.400', 'white'],
    textTransform: ['uppercase', 'none'],
    textAlign: ['center', 'left'],
    fontSize: ['xs', 'md'],
    fontWeight: 'medium',
  },
  quickLinksBoxProps: {
    mb: ['3', '8'],
  },
  ourPartners: {
    fontSize: ['xs', 'md'],
    color: ['beta.400', 'white'],
    textTransform: ['uppercase', 'none'],
    fontWeight: 'medium',
  },
  weAccept: {
    fontSize: ['xs', 'md'],
    color: ['beta.400', 'white'],
    textTransform: ['uppercase', 'none'],
    fontWeight: 'medium',
  },
  partnerWrapper: {
    mr: 'unset',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.30)',
  },
  weAcceptBox: {
    mb: '2',
  },
};
