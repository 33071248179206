import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  gap: 2.5,
  mb: 0,
  mx: 0,
  h: 'fit-content',

  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
  },

  '&.tabBar': {
    bg: 'beta.400',
    borderRadius: 'lg',
    h: 'fit-content',

    '&:has(+ .subTabBar)': {
      borderBottomRadius: 'none',
    },
  },

  '&.subTabBar': {
    bg: 'alpha.600',
    borderBottomRadius: 'lg',
    borderTopRadius: 'none',
    mt: 0,
    h: 'fit-content',
  },
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'quaddiesTab',
  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      border: 'none',
    },
    py: '2',
    '&&[data-active="true"]': {
      bg: 'alpha.600',
      borderBottom: 'none',
      h: '34px',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        bg: 'beta.700',
        color: 'alpha.600',
        fontWeight: 'bold',
        fontSize: 'xs',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
    },
    '&.subTab': {
      color: 'gray.700',
    },
  },
};


export const buttonWinPlaceSingleProps: CustomButtonProps = {
  variant: 'unstyled',
  boxShadow: 'none',
  fontSize: 'xs',
  textShadow: 'none',
  fontWeight: 'bold',
  borderRadius: 'none',
  fontFamily: 'body',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '3',
  py: '2',
  ml: '2',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'beta.400',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',

  _before: {
    content: "' '",
    height: '5px',
    width: '2px',
    backgroundColor: 'white',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },

  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'white',
    position: 'absolute',
    top: '5px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};