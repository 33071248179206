import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'betslip.betslipmodal.emptybetslipheading': 'Add some colour to your betslip',
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your racing and sports selections.',
  'header.betslipBtn.desc': 'Slip',
  'sports.allsports.azheading': 'Sports',
  'generic.sportAZ': 'A-Z',
  'onboarding.locationnotallowed.header': 'Neon color block',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing Chromabet from outside of Australia',
  'onboarding.locationnotallowed.bodycopy': `<bold>Chromabet</bold> is only available to customers from Australia. If you are based in Australia and believe that you are seeing this page in error, please contact us at `,
  'maintenance.heading': 'Color adjustment in progress',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'error.heading': 'Lights out. Refresh for a fix.',
};
