import { BoxProps, FlexProps, TextProps } from '@chakra-ui/react';
import { styleImports } from './Promotions.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type PromotionsStylesSchema = Partial<{
  flexTabWrapper: FlexProps;
  heading: TextProps;
  promotionWrapper: BoxProps;
  flexWrapper: FlexProps;
  termsAndConditions: TextProps;
  boxWrapper: BoxProps;
  filteredPromotionsWrapper: BoxProps;
}>;

export const promotionsBaseStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    'data-testid': 'promotions-flexTabWrapper',
    gap: 2,

    ...styleImports[themeName]?.promotionsStyles?.flexTabWrapper,
  },
  heading: {
    'data-testid': 'promotions-heading',

    ...styleImports[themeName]?.promotionsStyles?.heading,
  },
  promotionWrapper: {
    'data-testid': 'promotions-promotionWrapper',

    ...styleImports[themeName]?.promotionsStyles?.promotionWrapper,
  },
  flexWrapper: {
    ...(() => {
      try {
        return styleImports[themeName]?.promotionsStyles?.flexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  termsAndConditions: {
    ...(() => {
      try {
        return styleImports[themeName]?.promotionsStyles?.termsAndConditions;
      } catch (err) {
        return {};
      }
    })(),
  },
  boxWrapper: {
    label: 'promotions-boxWrapper',
    p: '4',
    pb: '0',
    ...(() => {
      try {
        return styleImports[themeName]?.promotionsStyles?.boxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  filteredPromotionsWrapper: {
    label: 'promotions-filteredPromotionsWrapper',
    p: '4',
    pt: '1',
    mt: '-2',
    cursor: 'pointer',
    ...(() => {
      try {
        return styleImports[themeName]?.promotionsStyles
          ?.filteredPromotionsWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
