/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  SkeletonText,
  Skeleton,
  SkeletonCircle,
  Stack,
  useBreakpointValue,
  useDisclosure,
  Icon,
  Show,
} from '@chakra-ui/react';
import { ChevronDown } from '@styled-icons/ionicons-solid/ChevronDown';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  NavigateFunction,
  NavLink as ReactRouterNavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { uuid } from 'uuidv4';
import { RadioButtonOff, CheckmarkCircle2 } from '@styled-icons/evaicons-solid';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';
import { keys } from '@/api/api.keys';
import { useQuerySportMarketGroup } from '@/api/sportDetailsMarketGroups/sportDetailsMarketGroups.hooks';
import { useQuerySportsMarketLayouts } from '@/api/sportDetailsMarkets/sportDetailsMarkets.hooks';
import { useQuerySportMatches } from '@/api/sportDetailsMatches/sportDetailsMatches.hooks';
import { querySportProps } from '@/api/sportDetailsProps/sportDetailsProps';
import { useSgmPricingMutation } from '@/api/sports/pricing/sgm.hooks';
import {
  useAddSgmToBetSlip,
  useBetSlipManageBets,
  useSingleBetSlip,
} from '@/components/Betslip/Services/Betslip.hooks';
import { Button } from '@/components/Button/Button';
import Countdown from '@/components/Countdown/Countdown';
import SgmLogo from '@/components/SgmLogo/SgmLogo';
import matchUrlResolver from '@/helpers/matchUrlResolver';
import { isPendingAndMatchingId } from '@/helpers/utils';
import { useDetectScrollBottom } from '@/hooks/useDetectScrollBottom';
import { useAppSelector } from '@/hooks/useRedux';
import { EGeneralStatus } from '@/lib/DBModels';
import PrimaryWrapper from '@/wrappers/PrimaryWrapper';
import MarketLayout, {
  TMarketLayoutProps,
} from '../components/MarketLayout/MarketLayout';
import MatchClosedDialog from '../components/MatchClosedDialog/MatchClosedDialog';
import MatchesModal from '../components/MatchesModal/MatchesModal';
import Nav, { NavLink } from '../components/Nav/Nav';
import ScrollButtonGroup from '../components/ScrollButtonGroup/ScrollButtonGroup';
import MiniBetSlip from '../MatchDetailPage/components/MiniBetSlip';
import { TExtendedProposition } from '../MatchDetailPage/services/MatchDetailPage.types';
import { groupBy } from '@/lib/utils';
import {
  TSportMarkets,
  TSportsMarketFormat,
} from '@/api/sportDetailsMarkets/sportDetailsMarkets.types';
import { matchDetailsStyles } from './styles/NewMatchDetailsPage/NewMatchDetailsPage.styles';
import { useFeatureFlag } from '@/store/FeatureFlagStore';

const MIN_SELECTIONS = 2;
const MAX_SELECTIONS = 20;
const TOP_MARKETS = 'Top Markets';

const subGroupConfig = [
  '(Halves)',
  '(Quarters)',
  '(Innings)',
  '(Cumulative Innings)',
  '(1st Set)',
  '(2nd Set)',
  '(3rd Set)',
  '(4th Set)',
  '(5th Set)',
];

const marketTypeConfig: Record<string, TSportsMarketFormat> = {
  BC_Head_to_Head: 'inline',
  BC_Win_Draw_Win: 'inline',
  BC_CorrectScore_Games_Set: 'table',
  BC_CorrectScore_Goals: 'table',
  BC_CorrectScore_Points: 'table',
  BC_CorrectScore_Sets: 'table',
  BC_Handicap_Events_Ace: 'handicap',
  BC_Handicap_Corners: 'handicap',
  BC_Handicap_Behinds: 'handicap',
  BC_Handicap_BreakOfServe: 'handicap',
  BC_Handicap_DoubleFault: 'handicap',
  BC_Handicap_Shots: 'handicap',
  BC_Handicap_ShotsOnTarget: 'handicap',
  BC_Handicap_Points_ParticularServiceGame: 'handicap',
  BC_Handicap_Points_Match: 'handicap',
  BC_Handicap_TotalGames: 'handicap',
  BC_Handicap_Goals: 'handicap',
  BC_Handicap_ThreeWay_Goal: 'handicap',
  BC_Handicap_Events_HomeRuns: 'handicap',
  BC_Handicap_TotalPoints: 'handicap',
  BC_Handicap_Runs: 'handicap',
  BC_Handicap_TotalSets: 'handicap',
  BC_Handicap_TotalRuns: 'handicap',
  BC_Winner_AllQuarters: 'inline',
  BC_Winner_BetweenOvers: 'inline',
  BC_Winner_BothHalves: 'inline',
  BC_OverUnder: 'over-under',
  BC_OverUnder_Ace: 'over-under',
  BC_OverUnder_AttemptedOnePointConversion: 'over-under',
  BC_OverUnder_AttemptedTwoPointConversion: 'over-under',
  BC_OverUnder_Behind: 'over-under',
  BC_OverUnder_Boundaries: 'over-under',
  BC_OverUnder_BookingPoint: 'over-under',
  BC_OverUnder_BreaksOfServe: 'over-under',
  BC_OverUnder_BreaksOfServe_Set: 'over-under',
  BC_OverUnder_CardCount: 'over-under',
  BC_OverUnder_Checkouts: 'over-under',
  BC_OverUnder_Catches: 'over-under',
  BC_OverUnder_Corner: 'over-under',
  BC_OverUnder_Cumulative_Run: 'over-under',
  BC_OverUnder_Cumulative_TotalRuns: 'over-under',
  BC_OverUnder_DoubleFaults: 'over-under',
  BC_OverUnder_DoubleFaults_Set: 'over-under',
  BC_OverUnder_FieldGoal: 'over-under',
  BC_OverUnder_FiftyScored: 'over-under',
  BC_OverUnder_Fours: 'over-under',
  BC_OverUnder_FreeKick: 'over-under',
  BC_OverUnder_Games: 'over-under',
  BC_OverUnder_Games_Set: 'over-under',
  BC_OverUnder_GoalKick: 'over-under',
  BC_OverUnder_Goal: 'over-under',
  BC_OverUnder_Hits: 'over-under',
  BC_OverUnder_HattrickTaken: 'over-under',
  BC_OverUnder_HomeRun: 'over-under',
  BC_OverUnder_HundredScored: 'over-under',
  BC_OverUnder_Interception: 'over-under',
  BC_OverUnder_Knockdowns: 'over-under',
  BC_OverUnder_MaxFieldGoalYard: 'over-under',
  BC_OverUnder_Minutes: 'over-under',
  BC_OverUnder_MissedFieldGoal: 'over-under',
  BC_OverUnder_MissedOnePointConversion: 'over-under',
  BC_OverUnder_MissedTwoPointConversion: 'over-under',
  BC_OverUnder_Offside: 'over-under',
  BC_OverUnder_OnePointConversion: 'over-under',
  BC_OverUnder_Points_Set: 'over-under',
  BC_OverUnder_Pass: 'over-under',
  BC_OverUnder_Points_Match: 'over-under',
  BC_OverUnder_Points_ParticularServiceGame: 'over-under',
  BC_OverUnder_Rounds: 'over-under',
  BC_OverUnder_RunOuts: 'over-under',
  BC_OverUnder_Runs: 'over-under',
  BC_OverUnder_Sack: 'over-under',
  BC_OverUnder_Safety: 'over-under',
  BC_OverUnder_ScoreAtFallOfWicket: 'over-under',
  BC_OverUnder_Seconds: 'over-under',
  BC_OverUnder_Set_Ace: 'over-under',
  BC_OverUnder_Sets: 'over-under',
  BC_OverUnder_Shot: 'over-under',
  BC_OverUnder_ShotOnTarget: 'over-under',
  BC_OverUnder_SignificantStrikes: 'over-under',
  BC_OverUnder_Steals: 'over-under',
  BC_OverUnder_Sixes: 'over-under',
  BC_OverUnder_Substitutions: 'over-under',
  BC_OverUnder_Stumpings: 'over-under',
  BC_OverUnder_Tackle: 'over-under',
  BC_OverUnder_Takedowns: 'over-under',
  BC_OverUnder_ThrowIn: 'over-under',
  BC_OverUnder_TieBreaks: 'over-under',
  BC_OverUnder_TotalPoints: 'over-under',
  BC_OverUnder_TotalRuns: 'over-under',
  BC_OverUnder_TouchDownDefenceOrReturn: 'over-under',
  BC_OverUnder_TouchDownPass: 'over-under',
  BC_OverUnder_TouchDownRush: 'over-under',
  BC_OverUnder_TouchDownYard: 'over-under',
  BC_OverUnder_TouchDown: 'over-under',
  BC_OverUnder_Try: 'over-under',
  BC_OverUnder_TwoPointConversion: 'over-under',
  BC_OverUnder_WicketsTaken: 'over-under',
  BC_Player_OverUnder_Events: 'over-under',
  BC_Player_OverUnder_Events_Set: 'over-under',
  BC_Player_OverUnder_Ace: 'over-under',
  BC_Player_OverUnder_Ace_Set: 'over-under',
  BC_Player_OverUnder_BreaksOfServe: 'over-under',
  BC_Player_OverUnder_Set_BreaksOfServe: 'over-under',
  BC_Player_OverUnder_DoubleFaults: 'over-under',
  BC_Player_OverUnder_Set_DoubleFaults: 'over-under',
  BC_Player_OverUnder_Games: 'over-under',
  BC_Player_OverUnder_Games_Set: 'over-under',
  BC_Player_OverUnder_Points: 'over-under',
  BC_Player_OverUnder_Set_Points: 'over-under',
  BC_Player_OverUnder_Sets: 'over-under',
  BC_Player_OverUnder_WinstieBreaks: 'over-under',
  BC_Team_OverUnder_AttemptedOnePointConversion: 'over-under',
  BC_Team_OverUnder_AttemptedTwoPointConversion: 'over-under',
  BC_Team_OverUnder_Behind: 'over-under',
  BC_Team_OverUnder_BookingPoint: 'over-under',
  BC_Team_OverUnder_CardCount: 'over-under',
  BC_Team_OverUnder_Catches: 'over-under',
  BC_Team_OverUnder_Corner: 'over-under',
  BC_Team_OverUnder_DropGoal: 'over-under',
  BC_Team_OverUnder_FieldGoal: 'over-under',
  BC_Team_OverUnder_Fours: 'over-under',
  BC_Team_OverUnder_FreeKick: 'over-under',
  BC_Team_OverUnder_GoalKick: 'over-under',
  BC_Team_OverUnder_Goal: 'over-under',
  BC_Team_OverUnder_HomeRun: 'over-under',
  BC_Team_OverUnder_Interception: 'over-under',
  BC_Team_OverUnder_MaxFieldGoalYard: 'over-under',
  BC_Team_OverUnder_MissedFieldGoal: 'over-under',
  BC_Team_OverUnder_MissedOnePointConversion: 'over-under',
  BC_Team_OverUnder_MissedTwoPointConversion: 'over-under',
  BC_Team_OverUnder_Offside: 'over-under',
  BC_Team_OverUnder_OnePointConversion: 'over-under',
  BC_Team_OverUnder_Pass: 'over-under',
  BC_Team_OverUnder_RunOuts: 'over-under',
  BC_Team_OverUnder_Run: 'over-under',
  BC_Team_OverUnderCumulative_Run: 'over-under',
  BC_Team_OverUnder_Sack: 'over-under',
  BC_Team_OverUnder_SackMade: 'over-under',
  BC_Team_OverUnder_Safety: 'over-under',
  BC_Team_OverUnder_Shot: 'over-under',
  BC_Team_OverUnder_ShotOnTarget: 'over-under',
  BC_Team_OverUnder_Sixes: 'over-under',
  BC_Team_OverUnder_Tackle: 'over-under',
  BC_Team_OverUnder_ThrowIn: 'over-under',
  BC_Team_OverUnderCumulative_HomeRun: 'over-under',
  BC_Team_OverUnder_TotalPoints: 'over-under',
  BC_Team_OverUnder_TotalRuns: 'over-under',
  BC_Team_OverUnderCumulative_TotalRuns: 'over-under',
  BC_Team_OverUnder_TouchDownPass: 'over-under',
  BC_Team_OverUnder_TouchDownRush: 'over-under',
  BC_Team_OverUnder_TouchDownYard: 'over-under',
  BC_Team_OverUnder_TouchDown: 'over-under',
  BC_Team_OverUnder_TouchDownDefenceOrReturn: 'over-under',
  BC_Team_OverUnder_Try: 'over-under',
  BC_Team_OverUnder_TwoPointConversion: 'over-under',
  BC_Team_OverUnder_WicketsLost: 'over-under',
  BC_Team_OverUnder_WicketsTaken: 'over-under',
  BC_OverUnder_Wides: 'over-under',
  BC_Winner_FromBehind: 'inline',
  BC_Winner_ParticularServiceGame: 'inline',
  BC_Winner_Set: 'inline',
  BC_Player_DoubleDoubleOrTripleDouble_DoubleDouble: 'player-events',
  BC_Player_NumberOfEvents_Blocks: 'player-events',
  BC_Player_NumberOfEvents_AssistsRebounds: 'player-events',
  BC_Player_NumberOfEvents_ThreePoints: 'player-events',
  BC_Player_NumberOfEvents_Assists: 'player-events',
  BC_Player_NumberOfEvents_PointsAssistsRebounds: 'player-events',
  BC_Player_NumberOfEvents_TotalPoints: 'player-events',
  BC_Player_NumberOfEvents_Steal: 'player-events',
  BC_Player_NumberOfEvents_Rebounds: 'player-events',
  BC_Player_NumberOfEvents_PointsRebounds: 'player-events',
  BC_Player_NumberOfEvents_BlocksSteals: 'player-events',
  BC_Player_NumberOfEvents_PointsAssists: 'player-events',
  BC_Player_DoubleDoubleOrTripleDouble_TripleDouble: 'player-events',
  BC_Player_OverUnder_BlocksSteals: 'player-over-under',
  BC_Player_OverUnder_ThreePoints: 'player-over-under',
  BC_Player_OverUnder_Steals: 'player-over-under',
  BC_Player_OverUnder_Blocks: 'player-over-under',
  BC_Player_OverUnder_Assists: 'player-over-under',
  BC_Player_OverUnder_TotalPoints: 'player-over-under',
  BC_Player_OverUnder_Rebounds: 'player-over-under',
  BC_Player_OverUnder_PointsAssists: 'player-over-under',
  BC_Player_OverUnder_AssistsRebounds: 'player-over-under',
  BC_Player_OverUnder_PointsRebounds: 'player-over-under',
  BC_Player_OverUnder_PointsAssistsRebounds: 'player-over-under',
} as const;

const validLayouts: Record<string, TSportsMarketFormat> = {
  inline: 'inline',
  table: 'table',
  'correct-score': 'table',
  list: 'list',
  'handicap-2': 'handicap',
  'handicap-3': 'handicap',
  'over-under': 'over-under',
  'player-events': 'player-events',
  'player-over-under': 'player-over-under',
};

const eventPartOrder = [
  'Normal Time',
  'Whole Match',
  '1st Half',
  '2nd Half',
  'No Half',
  'Either Half',
  'Each Half',
  '1st Quarter',
  '2nd Quarter',
  '3rd Quarter',
  '4th Quarter',
  'No Quarter',
  'All Quarters',
  '1st Innings',
  '2nd Innings',
  '3rd Innings',
  '4th Innings',
  '5th Innings',
  '6th Innings',
  '7th Innings',
  '8th Innings',
  'After 1st Innings',
  'After 2nd Innings',
  'After 3rd Innings',
  'After 4th Innings',
  'After 5th Innings',
  'After 6th Innings',
  'After 7th Innings',
  'After 8th Innings',
  'Set 1',
  'Set 2',
  'Set 3',
  'Set 4',
  'Set 5',
  'Set 1 Game 1',
  'Set 1 Game 2',
  'Set 1 Game 3',
  'Set 1 Game 4',
  'Set 1 Game 5',
  'Set 1 Game 6',
  'Set 1 Game 7',
  'Set 2 Game 1',
  'Set 2 Game 2',
  'Set 2 Game 3',
  'Set 2 Game 4',
  'Set 2 Game 5',
  'Set 2 Game 6',
  'Set 2 Game 7',
  'Set 3 Game 1',
  'Set 3 Game 2',
  'Set 3 Game 3',
  'Set 3 Game 4',
  'Set 3 Game 5',
  'Set 3 Game 6',
  'Set 4 Game 1',
  'Set 4 Game 2',
  'Set 4 Game 3',
  'Set 4 Game 4',
  'Set 4 Game 5',
  'Set 4 Game 6',
  'Set 5 Game 1',
  'Set 5 Game 2',
  'Set 5 Game 3',
  'Set 5 Game 4',
  'Set 5 Game 5',
  'Set 5 Game 6',
  '1st Period',
  '2nd Period',
  '3rd Period',
  '1st Over',
  '1st Over of 1st Innings',
  '1st Over of 2nd Innings',
  'Between Overs 1-6',
  'Round 1',
  'First 10 Mins',
].reduce((acc, s, i) => {
  acc[s] = i;
  return acc;
}, {} as Record<string, number>);

export type TMatchDetailPageProps = {
  sgm?: boolean;
};

type TSubFilterProps = {
  subFilters: string[];
  onClick: (filter: string) => void;
  activeFilter: string;
};

type TBtnSGMProps = {
  navigate: NavigateFunction;
  pathname: string;
  basePathname: string;
  search: string;
};

export default function MatchDetailPage({ sgm }: TMatchDetailPageProps) {
  const [expandedIndices, setExpandedIndices] = useState<
    Record<string, number[]>
  >({});
  const [expandOnSuccess, setExpandOnSuccess] = useState(false);
  const queryClient = useQueryClient();
  const [queryKeys, setQueryKeys] = useState<string[]>([]);
  const matchesModal = useDisclosure();
  const intl = useIntl();
  const hasSportV3 = useFeatureFlag('SPORTS_V3_ENABLED');

  // Routing
  const { pathname, search } = useLocation();
  const basePathname = sgm ? pathname.split('/').slice(0, -1).join('/') : '.';
  const navigate = useNavigate();
  const { competitionName, matchName } = useParams();
  const [searchParams] = useSearchParams();
  const sportId = searchParams.get('sportId');
  const competitionId = searchParams.get('competitionId');
  const matchId = searchParams.get('matchId');
  const marketGroupId = searchParams.get('marketGroupId');

  // Mini-bet slip
  const bodyRef = useRef(document.body);
  const isAtBottom = useDetectScrollBottom({ ref: bodyRef });
  const shouldRenderMiniBetSlip = useBreakpointValue({
    base: !isAtBottom,
    lg: true,
  });
  const sgmPricingMutation = useSgmPricingMutation();
  const [error, setError] = useState<{ message: string } | null>(null);
  const [selections, setSelections] = useState<TExtendedProposition[]>([]);
  const [odds, setOdds] = useState<number>();
  const addSgmToBetSlip = useAddSgmToBetSlip();
  const dialog = useDisclosure();
  const refObject = useRef(null);

  /** TODO: Move this into a container */
  const { marketGroupsQuery, matches, match, matchClosedDialog } =
    useMatchDetails();

  const { participants } = match?.match_info ?? {};
  const topMarketsIsActive =
    marketGroupsQuery.data?.find(
      (group) => group.market_group_id === marketGroupId
    )?.name === TOP_MARKETS;

  const defaultIndexCount = topMarketsIsActive ? undefined : 5;

  useEffect(() => {
    if (!marketGroupsQuery.data || marketGroupId) {
      return;
    }

    const defaultMarketGroup = marketGroupsQuery.data[0];
    const to = `${pathname}${search}&marketGroupId=${defaultMarketGroup.market_group_id}`;

    navigate(to, { replace: true });
  }, [marketGroupId, marketGroupsQuery.data, navigate, pathname, search]);

  const marketLayoutsQuery = useQuerySportsMarketLayouts(
    {
      match_id: matchId as string,
      ...(marketGroupId !== 'all' && {
        market_group_id: marketGroupId as string,
      }),
    },
    {
      enabled: !!matchId && !!marketGroupId,
      onSuccess: (marketLayouts) => {
        const nextQueryKeys = marketLayouts
          .slice(0, defaultIndexCount)
          .map(({ key }) => key);

        if (nextQueryKeys.every((key) => queryKeys.includes(key))) {
          return;
        }

        setQueryKeys(Array.from(new Set([...queryKeys, ...nextQueryKeys])));
      },
      select: (markets) => {
        const [groups, ungrouped] = groupBy(
          markets,
          (m) => m.market_info?.market_sub_group
        );

        const arr = [
          ...[
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore This works but TS throws an error
            ...groups.entries(),
          ]
            .map(([k, v]) => {
              const firstMarket = v[0];
              const title = k ?? firstMarket.market_name;

              const format = hasSportV3
                ? validLayouts[firstMarket?.market_info?.layout ?? '']
                : marketTypeConfig[firstMarket.market_type ?? ''];

              return {
                key: k,
                title,
                markets: v,
                format:
                  ['player-events', 'player-over-under'].includes(format) &&
                  !hasSportV3
                    ? 'list'
                    : format,
                disclaimer: firstMarket.disclaimer,
                sortOrder: firstMarket.bet_option_sort_order ?? Infinity,
              };
            })
            .sort((a, b) => a.sortOrder - b.sortOrder),
          ...(ungrouped as TSportMarkets[])
            .map((m) => ({
              key: m.market_id,
              title: m.market_name,
              markets: [m],
              format: undefined,
              disclaimer: m.disclaimer,
              ungrouped: true,
              sortOrder: m.bet_option_sort_order,
            }))
            .sort(
              (a, b) => (a.sortOrder ?? Infinity) - (b.sortOrder ?? Infinity)
            ),
        ];
        return arr;
      },
    }
  );

  useEffect(() => {
    if (
      !marketGroupsQuery.data ||
      !marketLayoutsQuery.data ||
      expandedIndices[marketGroupId as string]
    ) {
      return;
    }

    const defaultIndices = Array(
      defaultIndexCount ?? marketLayoutsQuery.data.length
    )
      .fill('')
      .map((_, i) => i);

    setExpandedIndices({
      ...expandedIndices,
      [marketGroupId as string]: defaultIndices,
    });
  }, [
    defaultIndexCount,
    expandedIndices,
    marketGroupId,
    marketGroupsQuery.data,
    marketLayoutsQuery.data,
  ]);

  const propositionQueries = useQueries({
    queries:
      queryKeys.map((key) => {
        const index =
          marketLayoutsQuery.data?.findIndex((layout) => layout.key === key) ??
          -1;

        const marketIds = marketLayoutsQuery.data?.[index]?.markets
          .map((m) => m.market_id as string)
          .join();

        return {
          queryKey: [keys.sportDetailsProps, marketIds],
          queryFn: () => querySportProps({ market_ids: marketIds as string }),
          enabled: !!marketIds,
          onSuccess: () => {
            if (!expandOnSuccess) return;
            // Just in case
            if (index === -1) return;
            if (expandedIndices[marketGroupId as string].includes(index))
              return;

            setExpandOnSuccess(false);
            setExpandedIndices({
              ...expandedIndices,
              [marketGroupId as string]: [
                ...expandedIndices[marketGroupId as string],
                index,
              ],
            });
          },
          refetchOnWindowFocus: false,
          refetchInterval: 20000,
        };
      }) ?? [],
  });

  const { bets } = useAppSelector(({ betSlip }) => betSlip);
  const { addSportsBetSlip } = useSingleBetSlip();
  const { setBet } = getBetSlipStoreActions();

  const { singlePropositionInBetSlip, removeFromBetSlip } =
    useBetSlipManageBets();

  const propositionIsSelected = useCallback(
    (propositionId: string) => {
      if (sgm) {
        return (
          selections.findIndex(
            ({ proposition_id }) => proposition_id === propositionId
          ) !== -1
        );
      }
      return !!singlePropositionInBetSlip(propositionId);
    },
    [selections, sgm, singlePropositionInBetSlip]
  );

  const data = useMemo(() => {
    const propositions = propositionQueries
      .flatMap((query) => query.data?.flat() ?? [])
      .map((p) => ({
        ...p,
        is_disabled:
          p.is_suspended ||
          (isPendingAndMatchingId(bets, p.proposition_id) && !sgm),
        is_selected: propositionIsSelected(p.proposition_id ?? ''),
      }));

    const layoutMap = marketLayoutsQuery.data?.map((layout) => {
      const markets = layout.markets.map((m) => ({
        ...m,
        propositions: propositions
          .filter((p) => p.market_id === m.market_id)
          .map((p) => ({ ...p, market_name: m.market_name })),
      }));
      const [groups, ungrouped] = groupBy(
        markets,
        (m) => m.market_info?.event_part
      );

      const sortedGroup = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore This works but TS throws an error
        ...groups.entries(),
      ]
        .map(([k, v]) => [k, v, eventPartOrder[k] ?? 9999])
        .sort((a, b) => a[2] - b[2]) as [string, TSportMarkets[]][];

      const parts = [
        ...sortedGroup.map(([k, v]) => ({
          title: k,
          markets: v
            .map((m) => ({
              ...m,
              propositions: m.propositions,
            }))
            .sort(
              (a, b) =>
                (a.bet_option_sort_order ?? Infinity) -
                (b.bet_option_sort_order ?? Infinity)
            ),
        })),
        ...(ungrouped as unknown as TSportMarkets[]).map((m) => ({
          title: null,
          markets: [
            {
              ...m,
              propositions: m.propositions,
            },
          ].sort(
            (a, b) =>
              (a.bet_option_sort_order ?? Infinity) -
              (b.bet_option_sort_order ?? Infinity)
          ),
        })),
      ];

      const hasSubTitle =
        parts.length === 1 &&
        !layout.ungrouped &&
        !['whole match', 'normal time'].includes(
          parts[0].title?.toLocaleLowerCase() ?? ''
        );
      const { markets: layoutMarkets, ...rest } = layout;

      return {
        ...rest,
        parts,
        subtitle: hasSubTitle ? `(${parts[0].title})` : '',
        ...(hasSubTitle && {
          title: rest.title
            .split(' ')
            .filter((txt) => !subGroupConfig.includes(txt))
            .join(' '),
        }),
      };
    });
    return layoutMap;
  }, [
    bets,
    marketLayoutsQuery.data,
    propositionIsSelected,
    propositionQueries,
    sgm,
  ]);

  const loadingPropositionQueries = useMemo(
    () =>
      queryKeys.filter((key) => {
        const marketIds = marketLayoutsQuery.data
          ?.find((layout) => layout.key === key)
          ?.markets.map((m) => m.market_id as string)
          .join();

        if (!marketIds) {
          return false;
        }

        const queryState = queryClient.getQueryState([
          keys.sportDetailsProps,
          marketIds,
        ]);
        return queryState?.status === 'loading';
      }),
    [marketLayoutsQuery, queryClient, queryKeys]
  );

  const isLoading =
    marketGroupsQuery.isLoading ||
    marketLayoutsQuery.isLoading ||
    loadingPropositionQueries.length > 1;

  const [subFilter, setSubFilter] = useState('');

  const chunks = useMemo(() => {
    let chunkData = data;
    if (!chunkData?.length) return [];

    if (subFilter) {
      chunkData = chunkData.filter((chunk) =>
        chunk.parts.some((part) =>
          part.markets.some(
            (market) =>
              market.market_info?.metric_categories?.includes(subFilter) ||
              market.market_info?.team === subFilter
          )
        )
      );
    }

    const midIndex = Math.ceil(chunkData.length / 2);
    const arr = hasSportV3
      ? [chunkData, []]
      : [chunkData.slice(0, midIndex), chunkData.slice(midIndex)];
    return arr.filter(({ length }) => length);
  }, [data, hasSportV3, subFilter]);

  /** Fetches any missing props */
  useEffect(() => {
    if (subFilter) {
      const missingPropsKeys = chunks
        .flatMap((chunk) => chunk)
        .filter((chunk) =>
          chunk.parts.some((part) =>
            part.markets.some(
              (mrkt) =>
                !(mrkt.propositions ?? []).length &&
                (mrkt.market_info?.metric_categories?.includes(subFilter) ||
                  mrkt.market_info?.team === subFilter)
            )
          )
        )
        .map((chunk) => chunk.key);

      if (missingPropsKeys.some((key) => !queryKeys.includes(key))) {
        setQueryKeys(Array.from(new Set([...queryKeys, ...missingPropsKeys])));
      }
    }
  }, [chunks, queryKeys, subFilter]);
  /** Fetches any missing props */

  const validMarketGroups = data?.filter((group) => {
    const groupedMarkets = group.parts.flatMap((part) => part.markets);
    return groupedMarkets.length > 1;
  });

  const categories =
    (validMarketGroups?.length ?? 0) >= 5
      ? validMarketGroups
          ?.filter((dta) =>
            dta.parts.some((part) =>
              part.markets.some(
                (mrk) => mrk.market_info?.metric_categories?.length
              )
            )
          )
          .flatMap((dta) =>
            dta.parts.flatMap((part) =>
              part.markets.flatMap((mrk) => mrk.market_info?.metric_categories)
            )
          )
          .filter((cat) => !!cat)
      : [];

  const teams = [
    ...((validMarketGroups?.length ?? 0) >= 5 ? validMarketGroups ?? [] : []),
  ]
    .flatMap((dta) =>
      dta.parts.flatMap((part) =>
        part.markets.flatMap((mrk) => ({
          team: mrk.market_info?.team,
          role: mrk.market_info?.team_role,
        }))
      )
    )
    .filter((itm) => !!itm.team)
    .sort((a, b) => {
      if (a.role === 'Home' && b.role !== 'Home') return -1;
      if (a.role !== 'Home' && b.role === 'Home') return 1;
      return 0;
    })
    .map((itm) => itm.team);

  const subFilters = Array.from(
    new Set([...(teams ?? []), ...(categories ?? [])])
  ) as string[];

  const handleChange: AccordionProps['onChange'] = (expandedIndex) => {
    const nextExpandedIndices =
      typeof expandedIndex === 'number' ? [expandedIndex] : expandedIndex;

    // On close
    if (
      nextExpandedIndices.length <
      expandedIndices[marketGroupId as string].length
    ) {
      setExpandedIndices({
        ...expandedIndices,
        [marketGroupId as string]: nextExpandedIndices,
      });
      return;
    }

    const nextQueryKeys = nextExpandedIndices
      .map((index) => marketLayoutsQuery.data?.[index]?.key)
      .filter((key): key is string => !!key);

    if (nextQueryKeys.every((key) => queryKeys.includes(key))) {
      setExpandedIndices({
        ...expandedIndices,
        [marketGroupId as string]: nextExpandedIndices,
      });
      return;
    }

    setExpandOnSuccess(true);
    setQueryKeys(Array.from(new Set([...queryKeys, ...nextQueryKeys])));
  };

  const addToBetSlip = useCallback(
    (proposition: TExtendedProposition) => {
      if (proposition.status !== EGeneralStatus.Open) {
        return;
      }

      const betSlipProposition = singlePropositionInBetSlip(
        proposition.proposition_id
      );

      setBet({
        id: proposition?.proposition_id ?? '',
        type: 'Single',
        propId: proposition?.proposition_id ?? '',
        odds: proposition?.return_amount ?? 0,
        misc: {
          ...match,
          ...proposition,
          sportTitle: proposition.market_name,
          proposition,
          match,
        },
      });

      if (betSlipProposition) {
        removeFromBetSlip(betSlipProposition.request_id);
      } else {
        addSportsBetSlip(proposition.market_name ?? '', proposition, match);
      }
    },
    [
      addSportsBetSlip,
      match,
      removeFromBetSlip,
      setBet,
      singlePropositionInBetSlip,
    ]
  );

  const addToMiniBetSlip = useCallback(
    (proposition: TExtendedProposition) => {
      const isSelected =
        selections.findIndex(
          ({ proposition_id }) => proposition_id === proposition.proposition_id
        ) !== -1;

      let nextSelections: TExtendedProposition[];

      if (isSelected) {
        const filterer = ({ proposition_id }: TExtendedProposition) =>
          proposition_id !== proposition.proposition_id;
        nextSelections = selections.filter(filterer);
      } else {
        nextSelections = [...selections, proposition];
      }

      if (nextSelections.length === 0) {
        setSelections([]);
        setOdds(undefined);
        return;
      }

      if (nextSelections.length < MIN_SELECTIONS) {
        setSelections(nextSelections);
        setOdds(nextSelections[0].return_amount);
        return;
      }

      if (nextSelections.length > MAX_SELECTIONS) {
        setError({
          message: intl.formatMessage({
            id: 'sports.matchDetailPage.maxSelectionsError',
          }),
        });
        return;
      }

      const propIds = nextSelections
        .filter(
          (prop): prop is { proposition_id: string } => !!prop.proposition_id
        )
        .map((prop) => prop.proposition_id);

      sgmPricingMutation.mutate(
        {
          match_id: match?.match_id as string,
          proposition_ids: propIds,
        },
        {
          onSettled: (settledData, settledError) => {
            const { price } = settledData?.data ?? {};

            if (price === null || settledError) {
              setError(
                settledError || {
                  message:
                    searchParams.get('computer') === 'no'
                      ? 'Computer says no.'
                      : intl.formatMessage({
                          id: 'sports.matchDetailPage.selectionError',
                        }),
                }
              );
              return;
            }

            setSelections(nextSelections);
            setOdds(price);
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, match?.match_id, searchParams, selections, sgmPricingMutation.mutate]
  );

  const handleAddSelections = () => {
    addSgmToBetSlip(selections, odds, match);

    setBet({
      id: uuid(),
      type: 'SGMulti',
      selections,
      odds: odds ?? 0,
      misc: {
        ...match,
        match,
      },
    });
  };

  const handleClearSelections = () => {
    dialog.onClose();
    setSelections([]);
  };

  useEffect(() => {
    handleClearSelections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.match_id]);

  const handleSelection: TMarketLayoutProps['onSelection'] = useCallback(
    (proposition: TExtendedProposition) => {
      const handler = sgm ? addToMiniBetSlip : addToBetSlip;
      handler(proposition);
    },
    [addToBetSlip, addToMiniBetSlip, sgm]
  );

  useEffect(() => {
    const handleBackButton = () => {
      navigate('/sports');
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  return (
    <PrimaryWrapper
      pageHeader={
        <Flex {...matchDetailsStyles.headerFlex}>
          <Heading
            {...matchDetailsStyles.headerHeading}
            flexWrap="wrap"
            columnGap="1"
          >
            {[
              ...(participants?.length === 2
                ? [participants[0].name, 'vs', participants[1].name]
                : [matchName]),
            ].map((txt, i, arr) => {
              const isLast = i + 1 === arr.length;

              return (
                <Fragment key={`header-txt-${txt}`}>
                  <Box
                    as="span"
                    {...(txt === 'vs' && matchDetailsStyles.headerVsBox)}
                    {...(isLast &&
                      arr.length > 1 && {
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '1',
                        whiteSpace: 'nowrap',
                      })}
                  >
                    {txt}

                    {isLast && (
                      <Flex columnGap="1" display="inline-flex">
                        {matches.length > 1 && (
                          <IconButton
                            aria-label="See All Matches"
                            icon={<ChevronDown />}
                            isDisabled={!matches.length}
                            onClick={matchesModal.onOpen}
                            {...matchDetailsStyles.headerIconButton}
                          />
                        )}

                        {match?.match_start_time && (
                          <Countdown
                            eventTime={match.match_start_time}
                            dateFormat="D MMM HH:mm"
                            {...matchDetailsStyles.countdown}
                          />
                        )}
                      </Flex>
                    )}
                  </Box>
                </Fragment>
              );
            })}
          </Heading>

          {hasSportV3 && match?.sgm_available && (
            <Show below="md">
              <Box>
                <BtnSGM
                  navigate={navigate}
                  pathname={pathname}
                  basePathname={basePathname}
                  search={search}
                />
              </Box>
            </Show>
          )}
        </Flex>
      }
      pageTitle={matchName}
    >
      <Stack {...matchDetailsStyles.bodyStack}>
        {!hasSportV3 && (
          <Box {...matchDetailsStyles.bodyInnerWrapper}>
            <Nav>
              <NavLink
                as={ReactRouterNavLink}
                to={`${basePathname}${search}`}
                end
              >
                <FormattedMessage id="generic.allMarkets" />
              </NavLink>

              {match?.sgm_available && (
                <NavLink
                  as={ReactRouterNavLink}
                  to={`${basePathname}/sgm${search}`}
                  end
                >
                  <FormattedMessage id="generic.sameGameMulti" />
                  <SgmLogo {...matchDetailsStyles.sgmLogo} />
                </NavLink>
              )}
            </Nav>
          </Box>
        )}

        <Flex
          {...(hasSportV3
            ? matchDetailsStyles.wrapperScrollSgm
            : { display: 'unset' })}
        >
          <ScrollButtonGroup
            id="scroll-btn-group"
            className="market-group-scroller"
            {...matchDetailsStyles.scrollButtonGroup}
            {...(hasSportV3 && matchDetailsStyles.scrollButtonGroupV2)}
            hasIndicator={hasSportV3}
            hasScrollBtns={false}
          >
            {marketGroupsQuery.data?.map(({ name, market_group_id }) => (
              <Button
                key={market_group_id}
                isActive={market_group_id === marketGroupId}
                onClick={() => {
                  setSubFilter('');
                  navigate(
                    `${pathname}?sportId=${sportId}&competitionId=${competitionId}&matchId=${matchId}&marketGroupId=${market_group_id}`
                  );
                }}
                {...matchDetailsStyles.toMarketButton}
                {...(hasSportV3 && matchDetailsStyles.toMarketButtonV2)}
              >
                {name}
              </Button>
            ))}
          </ScrollButtonGroup>

          {hasSportV3 && match?.sgm_available && (
            <Show above="md">
              <BtnSGM
                navigate={navigate}
                pathname={pathname}
                basePathname={basePathname}
                search={search}
              />
            </Show>
          )}
        </Flex>

        {hasSportV3 && !!subFilters.length && (
          <SubFilters
            subFilters={subFilters}
            activeFilter={subFilter}
            onClick={(filter) =>
              setSubFilter((state) => (state === filter ? '' : filter))
            }
          />
        )}

        {isLoading ? (
          <LoadingState hasSportV3={hasSportV3 || false} />
        ) : (
          <div>
            <Accordion
              allowMultiple
              index={expandedIndices[marketGroupId as string]}
              onChange={handleChange}
              {...matchDetailsStyles.accordion}
            >
              {chunks.map((marketLayouts) => (
                <Stack
                  key={marketLayouts[0].key}
                  {...matchDetailsStyles.accordionStack}
                  {...(hasSportV3 && { w: 'full' })}
                >
                  {marketLayouts.map(
                    ({ key, title, parts, format, subtitle }, idx) => {
                      const marketIds = parts
                        .flatMap((p) =>
                          p.markets.map((m) => m.market_id as string)
                        )
                        .join();

                      const queryState = queryClient.getQueryState([
                        keys.sportDetailsProps,
                        marketIds,
                      ]);
                      const propositionsAreLoading =
                        queryState?.status === 'loading';

                      return (
                        <AccordionItem
                          key={key}
                          isDisabled={propositionsAreLoading}
                          {...matchDetailsStyles.accordionItem}
                          {...(expandedIndices?.[
                            marketGroupId as string
                          ]?.includes(idx) &&
                            matchDetailsStyles.accordionItemActive)}
                        >
                          <h2>
                            <AccordionButton
                              {...matchDetailsStyles.accordionButton}
                            >
                              <Flex
                                {...matchDetailsStyles.marketLayoutIconFlex}
                                as="span"
                              >
                                {title} {subtitle}
                              </Flex>

                              {propositionsAreLoading ? (
                                <Center
                                  {...matchDetailsStyles.marketLoadingCenter}
                                >
                                  <PulseLoader
                                    size="2"
                                    {...matchDetailsStyles.centerLoadingSpinner}
                                  />
                                </Center>
                              ) : (
                                <AccordionIcon
                                  {...matchDetailsStyles.marketLayoutLoadedIcon}
                                />
                              )}
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            {...matchDetailsStyles.marketLayoutAccordionPanel}
                          >
                            <MarketLayout
                              parts={parts}
                              participants={participants ?? undefined}
                              format={format}
                              onSelection={handleSelection}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      );
                    }
                  )}
                </Stack>
              ))}
            </Accordion>
          </div>
        )}
      </Stack>
      {sgm && shouldRenderMiniBetSlip && (
        <>
          <MiniBetSlip
            selections={selections}
            odds={odds}
            isLoading={sgmPricingMutation.isLoading}
            error={error}
            onAddSelections={handleAddSelections}
            onClearSelections={dialog.onOpen}
          />

          <AlertDialog
            isOpen={dialog.isOpen}
            leastDestructiveRef={refObject}
            onClose={dialog.onClose}
            autoFocus={false}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader ref={refObject}>
                  <FormattedMessage id="sports.matchDetailPage.clearSelections" />
                </AlertDialogHeader>
                <AlertDialogBody>
                  <FormattedMessage id="sports.matchDetailPage.clearSelectionsPrompt" />
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    {...matchDetailsStyles.cancelButton}
                    onClick={dialog.onClose}
                  >
                    <FormattedMessage id="generic.cancel" />
                  </Button>
                  <Button
                    {...matchDetailsStyles.clearButton}
                    onClick={handleClearSelections}
                  >
                    <FormattedMessage id="generic.clear" />
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
      <MatchClosedDialog
        isOpen={matchClosedDialog.isOpen}
        matchName={matchName as string}
        onClose={() => {
          navigate('/sports');
          matchClosedDialog.onClose();
        }}
      />
      <MatchesModal
        isOpen={matchesModal.isOpen}
        title={`${competitionName} - Upcoming Matches`}
        matches={matches.map((m) => ({
          matchId: m.match_id as string,
          matchName: m.match_name as string,
          matchStartTime: m.match_start_time,
          matchUrl: matchUrlResolver(m),
          sportName: m.sport_name,
          sgmAvailable: m.sgm_available,
        }))}
        onClose={matchesModal.onClose}
      />
    </PrimaryWrapper>
  );
}

function SubFilters({ subFilters, activeFilter, onClick }: TSubFilterProps) {
  if (!subFilters.length) return null;

  return (
    <ScrollButtonGroup {...matchDetailsStyles.scrollButtonGroup}>
      {['all', ...subFilters].map((filter) => (
        <Button
          key={filter}
          isActive={activeFilter === (filter === 'all' ? '' : filter)}
          onClick={() => onClick(filter === 'all' ? '' : filter)}
          {...matchDetailsStyles.toMarketButton}
        >
          {filter}
        </Button>
      ))}
    </ScrollButtonGroup>
  );
}

function BtnSGM({ navigate, pathname, basePathname, search }: TBtnSGMProps) {
  const isActive = pathname.includes('sgm');
  return (
    <Button
      onClick={() => {
        navigate(
          isActive ? `${basePathname}${search}` : `${basePathname}/sgm${search}`
        );
      }}
      {...matchDetailsStyles.sgmBtn}
      {...(isActive && matchDetailsStyles.sgmBtnActive)}
    >
      <SgmLogo {...matchDetailsStyles.sgmLogoV2} />
      <Icon as={isActive ? CheckmarkCircle2 : RadioButtonOff} size="5" />
    </Button>
  );
}

function LoadingState({ hasSportV3 }: { hasSportV3: boolean }) {
  if (!hasSportV3)
    return (
      <Box>
        <SkeletonText
          noOfLines={2}
          {...matchDetailsStyles.loadingSkeletonText}
        />
      </Box>
    );

  return (
    <Box>
      {[...new Array(3)].map((a, idx) => (
        <Box
          key={`skel-sport-loader-${idx}`}
          borderWidth="2px"
          borderColor="gray.200"
          borderRadius="lg"
          boxShadow="md"
          transform={{ 1: 'scale(0.98)', 2: 'scale(0.96)' }[idx] ?? 'scale(1)'}
          opacity={{ 1: 0.5, 2: 0.2 }[idx] ?? 8}
          mt={idx === 2 ? '1' : '2'}
        >
          <Flex borderBottomWidth="2px" borderColor="gray.200" px="4" py="2">
            <Skeleton height="5" width="45%" />
            <SkeletonCircle size="5" ml="auto" />
          </Flex>

          <Box p="4">
            <Flex flexDir="row" mb="3">
              {[...new Array(3)].map((_, i) => (
                <Skeleton key={i} flex="1" h="6" sx={{ '&+&': { ml: '2' } }} />
              ))}
            </Flex>

            {[...new Array(5)].map((_, i) => (
              <Skeleton key={i} mt="1.5" h="5" w="100%" />
            ))}

            <Flex justifyContent="center" mt="3">
              <Skeleton w="150px" h="6" />
            </Flex>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

/** Controller */
const useMatchDetails = () => {
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get('matchId') as string;
  const competitionId = searchParams.get('competitionId') as string;
  const matchClosedDialog = useDisclosure();

  /** Queries */
  const matchesQuery = useQuerySportMatches(
    { competition_id: competitionId },
    { enabled: !!competitionId, refetchInterval: 20000 }
  );

  const marketGroupsQuery = useQuerySportMarketGroup(
    { match_id: matchId },
    {
      enabled: !!matchId && !!matchesQuery.data?.length,
      select: (marketGroups) =>
        marketGroups.filter((group) => group.market_group_id),
    }
  );
  const marketGroupData = marketGroupsQuery.data;
  /** Queries */

  const matches = matchesQuery.data ?? [];
  const match = matches.find((m) => m.match_id === matchId);
  const { market_count } = match ?? {};

  /** Hook to check the status of the match to show/hide the closed model */
  useEffect(() => {
    const { isInitialLoading, error } = matchesQuery;
    const { status } = error?.response ?? {};
    const isClosed = (!isInitialLoading && !match) || status === 404;

    if (isClosed) matchClosedDialog.onOpen();
  }, [match, matchClosedDialog, matchesQuery]);

  const hasSingularOther =
    marketGroupData?.length === 1 &&
    marketGroupData[0].name?.toLocaleLowerCase() === 'other';

  return {
    marketGroupsQuery:
      (market_count ?? 51) <= 50 &&
      !marketGroupData?.some((i) => i.name.toLocaleLowerCase() === 'all')
        ? {
            ...marketGroupsQuery,
            data: [
              {
                market_group_id: 'all',
                name: 'All',
                bet_options: marketGroupData?.flatMap((d) => d.bet_options),
              },
              ...(hasSingularOther ? [] : marketGroupData ?? []),
            ],
          }
        : marketGroupsQuery,
    matchesQuery,
    matches,
    match,
    matchClosedDialog,
  };
};
