import { useIntl } from 'react-intl';
import { centsToDollars, getPositionValue, getStrings } from '@/helpers/utils';
import { TBetSlipBetSubmissionExoticsType } from '@/components/Betslip/Services/Betslip.types';
import {
  calculateExoticCombinations,
  calculateExoticFlexi,
} from '@/components/Betslip/Services/Betslip.utils';
import {
  EBetTypes,
  EEventType,
  TConfirmedBet,
  TMyBetsMultiBetLegs,
} from '@/lib/DBModels';
import {
  calculateCombosForToteMulti,
  isBoxed,
  isToteMulti,
} from '@/views/races/bets/Exotics/services/Exotics.utils';
import { formatDayDateMonthTime } from '@/lib/Time';
import { SUB_TABS_DISPLAY_NAME } from '@/components/TabBar/TabBar';
import { validateStake } from '@/components/Betslip/lib';

export const useGetRunnerDetails = (
  bet: TConfirmedBet,
  leg?: TMyBetsMultiBetLegs
) => {
  const {
    runner_name: runnerName,
    runner_number: runnerNumber,
    runner_barrier_number: barrierNumber,
    proposition_name: propositionName,
  } = bet.event_data || {};

  const [
    {
      BetSlip: { betSlipModal: BetslipStrings },
      Account: { MyBetsCard: Strings },
    },
  ] = getStrings();
  const intl = useIntl();

  const exoticSelections = bet.exotic_selections;

  // Multi leg (i.e. each viewable leg in dropdown)
  if (leg) {
    const {
      runner_name: legRunnerName,
      runner_number: legRunnerNumber,
      runner_barrier_number: legBarrierNumber,
      proposition_name: legPropositionName,
    } = leg.event_data || {};
    return leg.event_type === EEventType.Race
      ? [
          `${legRunnerNumber}. ${legRunnerName?.toLowerCase()}`,
          ` (${legBarrierNumber})`,
        ]
      : [legPropositionName ?? ''];
  }

  const hasRollover = bet?.bet_legs?.some((l) => l.leg_num === 2);

  if (bet.price_type === 'mystery_bet' && hasRollover)
    return [
      `${intl.formatMessage({
        id: 'account.myBetsCard.msyteryBetHeaderWithRollover',
      })}`,
    ];

  const totalBetLegs = bet?.bet_legs?.reduce(
    (count, bet_leg) =>
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      count +
      (bet_leg.bet_description === 'Blended' || !bet_leg.legs
        ? 1
        : bet_leg.legs.length ?? 0),
    0
  );

  if (bet.bet_legs) {
    return [`${totalBetLegs} ${Strings.LegMulti}`];
  }

  if (bet.event_type === EEventType.Race) {
    // Exotic
    if (exoticSelections) {
      // // 👇 Simple version for selections EG: (1, 2, 7, 10)
      const selections = exoticSelections.map((place) =>
        place.exotic_combos
          .sort((a, b) => a.runner_number - b.runner_number)
          .map((pl) => pl.runner_number)
      );

      const runnerSelections = exoticSelections?.map((exoticCombos) =>
        exoticCombos?.exotic_combos?.map(
          (selection) => selection?.runner_number
        )
      );

      const calculateCombinations = calculateExoticCombinations(
        runnerSelections,
        bet.bet_description as TBetSlipBetSubmissionExoticsType
      );
      const flexi = calculateExoticFlexi(
        bet?.stake ? Number(bet.stake) / 100 : 0,
        calculateCombinations.length
      );
      const description =
        isBoxed(selections) && bet.bet_description?.toLowerCase() !== 'quinella'
          ? `${BetslipStrings.boxed} ${bet.bet_description}`
          : bet.bet_description;
      return [`${description} ${flexi}%`];
    }
    // Single racing

    if (bet.tote_multi_selections) {
      const selections = bet.tote_multi_selections?.map((o) =>
        o.leg_selection.map((ls) => ls.runner_number)
      ) as number[][];
      const combos = calculateCombosForToteMulti(selections);
      const flexiUnmodified = ((bet.stake / 100 / combos) * 100).toFixed(2);
      const flexi = validateStake(flexiUnmodified);
      return [`${SUB_TABS_DISPLAY_NAME[bet.bet_description]} ${flexi}%`];
    }
    return [
      `${runnerNumber}. ${runnerName?.toLowerCase()}`,
      ` (${barrierNumber})`,
    ];
  }

  // Single sports
  return [propositionName ?? ''];
};

export const useRunnerDescription = (
  bet: TConfirmedBet,
  leg?: TMyBetsMultiBetLegs,
  isGroupedLeg?: boolean
) => {
  const intl = useIntl();

  // SGM _bet_
  if (bet.bet_description === EBetTypes.SGMulti && !leg) {
    return [
      bet.event_title,
      intl.formatMessage(
        { id: 'generic.stakeWithValue' },
        { stake: centsToDollars(bet.stake) }
      ),
    ];
  }

  // SGM _leg_
  if (bet.bet_description === EBetTypes.SGMulti && leg) {
    return [leg?.event_data.market_name];
  }

  if (bet.bet_description === EBetTypes.SRMulti)
    return [
      `${bet.event_data?.venue_name} R${
        bet.event_data?.race_number
      } - ${intl.formatMessage(
        { id: 'generic.stakeWithValue' },
        { stake: centsToDollars(bet.stake) }
      )}`,
    ];

  // SRM _bet_
  if (bet.bet_description === EBetTypes.SRMulti && isGroupedLeg) {
    return [
      bet.event_title,
      intl.formatMessage(
        { id: 'generic.stakeWithValue' },
        { stake: centsToDollars(bet.stake) }
      ),
    ];
  }

  if (bet.bet_description === EBetTypes.SRMulti && !isGroupedLeg) {
    return ['', getPositionValue(leg?.bet_description)];
  }

  // Blended _bet_
  if (bet.bet_description === EBetTypes.Blended && !leg) {
    return [
      bet.event_title,
      intl.formatMessage(
        { id: 'generic.stakeWithValue' },
        { stake: centsToDollars(bet.stake) }
      ),
    ];
  }

  // Multi leg
  if (leg) {
    const evenDetails =
      leg.event_type === EEventType.Race
        ? `${leg.event_data.venue_name} R${leg.event_data.race_number}`
        : `${leg.event_data.match_name}`;

    const desc =
      leg.event_type === 'Match'
        ? leg?.event_data?.market_name?.toLowerCase()
        : leg.bet_description?.toLowerCase();

    return [
      `${evenDetails} ${leg.legs === null ? `- ${desc}` : ''}`,
      bet.bet_description !== EBetTypes.SRMulti &&
      bet.bet_description !== EBetTypes.Blended &&
      !isGroupedLeg
        ? formatDayDateMonthTime(leg.event_start)
        : undefined,
    ];
  }

  // Exotic/multi bet
  if (bet.exotic_selections || bet.bet_legs) {
    return [
      intl.formatMessage(
        { id: 'generic.stakeWithValue' },
        { stake: centsToDollars(bet.stake) }
      ),
    ];
  }

  if (isToteMulti(bet.bet_description as string)) {
    return [
      `${intl.formatMessage({ id: 'generic.stake' })} ${centsToDollars(
        bet.stake
      )}`,
    ];
  }

  // Single bet
  return [
    bet.event_type === 'Race'
      ? bet.bet_description
      : bet.market_name?.toLowerCase(),
    intl.formatMessage(
      { id: 'generic.stakeWithValue' },
      { stake: centsToDollars(bet.stake) }
    ),
  ];
};

export const getOdds = (bet: TConfirmedBet, leg?: TMyBetsMultiBetLegs) => {
  const { odds } = leg ?? bet;
  return !odds || odds === 0 ? '' : odds.toFixed(2);
};

export const getStatus = (bet?: TConfirmedBet, leg?: TMyBetsMultiBetLegs) => {
  const { status, is_won: isWon } = (leg || bet) ?? {};
  if (status === 'pending') return status;
  if (status === 'voided') return status;
  return isWon ? 'won' : 'lost';
};
