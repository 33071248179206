import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bg: 'rgba(26, 165, 255, 0.15)',
  borderColor: 'whiteAlpha.200',
  color: 'white',
  h: 'full',
  borderRadius: 'md',
  _hover: {
    bg: 'rgba(26, 165, 255, 0.40)',
    borderColor: 'whiteAlpha.200',
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'alpha.700',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'white',
};
