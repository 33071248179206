import { MarketAccordionItemSchema } from './MarketsAccordionItem.styles';

export const marketAccordionItemStyles: MarketAccordionItemSchema = {
  accordionBox: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    textAlign: 'left',
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  disclaimerFlex: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'inline-flex',
    ml: '2',
  },
  disclaimerIcon: {
    boxSize: '2',
  },
};
