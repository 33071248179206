import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/slambet/images/close-filter.svg';

const odds = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.100 0%, alpha.500 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'alpha.400',
    textShadow: '0px 0px 8px rgba(255, 159, 28, 0.5)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'gamma.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    opacity: '1',
    '&&:hover': {
      bg: 'blackAlpha.400',
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const primary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
  borderRadius: 'md',
  color: 'beta.700',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.100 0%, beta.100 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.100',
    border: 'none',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
      color: 'beta.700',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.400',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(255, 255, 255, 0.5)',
  _hover: {
    bg: 'alpha.400',
  },
  _active: {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      opacity: 0.4,
    },
  },
};

const quickAccess = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.100 0%, alpha.500 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  border: '2px solid',
  borderColor: 'alpha.100',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 0px 0px 1px rgba(54, 19, 0, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.4) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.600 0%, alpha.600 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'white',
    boxShadow: 'unset',
    border: '2px solid white',
    color: 'beta.600',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.100 0%, alpha.500 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const bets = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.100 0%, alpha.500 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  border: '2px solid',
  borderColor: 'alpha.100',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 0px 0px 1px rgba(54, 19, 0, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.4) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.600 0%, alpha.600 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    border: '2px solid',
    borderColor: 'blackAlpha.600',
    color: 'alpha.400',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.100 0%, alpha.500 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    quickAccess: () => ({
      ...quickAccess,
    }),
    bets: () => ({
      ...bets,
    }),
    odds: () => ({
      ...odds,
    }),
    tab: {
      boxShadow: 'none',
      border: 'none',
      fontSize: 'sm',
      textShadow: 'none',
      fontWeight: 'normal',
      borderRadius: 'none',
      fontFamily: 'accent',
      h: '10',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '2',
      pb: '1',
      _hover: {
        color: 'alpha.400',
      },
      '&:active, &[data-active="true"]': {
        color: 'alpha.400',
        fontWeight: 'normal',
        textShadow: '0px 0px 8px rgba(255, 159, 28, 0.5)',
        border: 'none',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.400',
      },
    },
    filter: {
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'extrabold',
      h: '7',
      minW: 'auto',
      p: '2',
      textTransform: 'uppercase',
      border: '2px',
      borderColor: 'alpha.400',
      bg: 'transparent',
      _hover: {
        color: 'alpha.400',
      },
      _active: {
        bgGradient: 'linear(to-b, alpha.100, alpha.500)',
        color: 'alpha.900',
        border: 'none',
        '&:after': {
          border: 'none',
          content: '""',
          position: 'absolute',
          display: 'block',
          boxSize: '14px',
          top: '6.4px',
          right: '2',
          borderRadius: 'full',
          pr: '2',
          backgroundImage: `url(${CloseButton})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '2',
      textTransform: 'uppercase',
      bg: 'transparent',
      _hover: {
        color: 'gamma.800',
      },
      _active: {
        bgGradient: 'linear(to-b, gamma.200, gamma.300)',
        color: 'gamma.800',
      },
    },
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
