import React from 'react';
import { Global } from '@emotion/react';
import pattern from '@/assets/sugarcastle/images/backgrounds/pattern.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          background: `url(${pattern}), #14134B`,
          backgroundPosition: '5px center, center',
          backgroundRepeat: 'repeat',
          backgroundSize: '490px 490px',
          backgroundAttachment: 'fixed',
          '@media (max-width: 767px)': {
            background: `url(${pattern}), #14134B`,
            backgroundSize: '325px 325px',
            backgroundPosition: '5px center',
            backgroundRepeat: 'repeat',
            backgroundAttachment: 'fixed',
          },
        },
      }}
    />
  );
}
