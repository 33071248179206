import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  border: '2px solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'beta.500',
  boxSize: ['8', '10'],
  bg: 'linear-gradient(180deg,var(--bc-colors-beta-700) 0%,var(--bc-colors-beta-800) 100%)',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300),0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset,0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset',
  borderRadius: 'lg',
  p: '2',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'beta.500',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bg: 'beta.100',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset,0px 0px 0px 5px var(--bc-colors-beta-200) inset',
  borderRadius: 'md',
  w: '100%',
  h: '64px',
  _hover: {
    color: 'beta.800',
    '.chakra-text': {
      span: {
        color: 'black',
      },
    },
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'md',
  color: 'beta.800',
  mt: '1.5',
  fontFamily: 'heading',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'beta.500',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'beta.500',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'beta.500',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'beta.500',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'beta.800',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
  h: '18px',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
