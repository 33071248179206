import { CSSObject } from '@chakra-ui/react';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: '0',
  '&&': { px: '4', pb: ['1', null, '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'normal',
  fontSize: 'xl',
  textTransform: 'uppercase',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Staatliches',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: 'lg',
  minHeight: '0',
  bg: ['transparent', null, 'rgba(78, 178, 255, 0.14)'],
  pos: 'relative',
  border: 'none',
  boxShadow: [
    'unset',
    null,
    '0px 0px 0px 4px rgba(18, 23, 30, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  ],
  '&:before': {
    content: ['none', null, '""'],
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};

export const WrapperContainer: CSSObject = {
  px: ['2', null, '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
  mb: '3',
};
