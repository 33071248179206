import * as goldenrush from './PunterDetail.styles.goldenrush';
import * as juicybet from './PunterDetail.styles.juicybet';
import * as puntcity from './PunterDetail.styles.puntcity';
import * as vikingbet from './PunterDetail.styles.vikingbet';
import * as volcanobet from './PunterDetail.styles.volcanobet';
import * as forsport from './PunterDetail.styles.forsport';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  goldenrush,
  juicybet,
  puntcity,
  vikingbet,
  volcanobet,
  forsport,
};
