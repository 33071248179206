import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  pos: 'relative',
  zIndex: '1',
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '38px',
      background: 'alpha.700',
      color: 'white',
      borderRadius: 'lg',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      px: '2',
      'input, span': {
        fontFamily: 'roboto',
        fontWeight: 'normal',
      },
      button: {
        color: 'beta.500',
      },
      boxShadow: 'none',

      _hover: {
        bg: 'alpha.600',
      },
    },
  },
};
