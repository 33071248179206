import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {},
  stackWrapper: {
    bg: 'gray.300',
  },
  heading: { color: 'gray.500' },
  moneyValues: { color: 'gray.600' },
  cashedOutText: {},
  cashedOutQuestion: { color: 'gray.600' },
  declineButton: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  acceptButton: {
    variant: 'solid',
    borderRadius: 'md',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  doneButton: {},
};
