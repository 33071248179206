import React from 'react';
import { useIntl } from 'react-intl';
import PrimaryWrapper from '../../../wrappers/PrimaryWrapper';
import { Editor, FlexPageWrapper } from './styles/Promos.styles';
import { usePromos } from './services/Promos.hooks';

const Promos: React.FC = () => {
  const intl = useIntl();

  const { promos } = usePromos();

  return (
    <PrimaryWrapper
      pageTitle={intl.formatMessage({
        id: 'account.promos.pageTitle',
      })}
      pageHeader={intl.formatMessage({
        id: 'account.promos.pageTitle',
      })}
    >
      <FlexPageWrapper>
        <Editor readOnly value={promos} />
      </FlexPageWrapper>
    </PrimaryWrapper>
  );
};

export default Promos;
