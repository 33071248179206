import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};
export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['0.5', null, '0'],
};
export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
};
export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
};
export const LinkItem: CSSObject = {
  color: 'white',
  bgGradient: `linear(to-b, beta.300, beta.400)`,
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px #20273F inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.2) inset',
  border: '2px solid',
  borderColor: 'alpha.300',
  borderRadius: 'lg',
  h: '10',
  mb: ['1', '2'],
  _first: { borderTop: '2px solid', borderColor: 'alpha.300' },
  _last: { mb: '0' },
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    color: 'alpha.800',
    svg: {
      color: 'alpha.800',
    },
  },
};
export const IconSport: CSSObject = {
  color: 'alpha.400',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'alpha.400',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
export const TextName: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
};
