import { BoxProps } from '@chakra-ui/react';
import { WellSchema } from './Well.styles';

export const wrapper: BoxProps = {};
export const wellStyles: WellSchema = {
  wrapper: {
    bg: 'delta.700',
    borderRadius: 'base',
    boxShadow:
      '0 1px rgba(255, 255, 255, .1), 0 2px 5px rgba(0, 0, 0, .15) inset',
    p: '1.5',
  },
};
