import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  borderRadius: 'md',
  contain: 'paint',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  mb: '3',
  border: '0',

  ...(isNested && {
    boxShadow: 'unset',
    mb: '2',
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  border: 0,
  p: '2',
  borderBottomRadius: 'md',
  bg: '#3F4953',

  ...(isNested && {
    border: '1px',
    borderTop: 0,
    borderColor: 'blackAlpha.200',
    bg: '#30363F',
    boxShadow:
      '0px 3px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset;',
    py: 0,
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'beta.300',
  h: '32px',
  color: 'white',

  borderRadius: 'md',
  fontWeight: 'semibold',
  fontSize: 'sm',
  p: '2',
  border: '1px',
  borderColor: 'transparent',

  svg: {
    border: '1px',
    borderColor: 'alpha.600',
    color: 'alpha.600',
    borderRadius: '100%',
    boxSize: '20px',
  },

  _hover: {
    bg: 'beta.400',
  },

  ...(isNested && {
    '&&&': {
      bg: 'gamma.200',
      border: '1px',
      borderColor: 'blackAlpha.200',
      color: 'white',

      svg: {
        borderColor: 'white',
        color: 'white',
      },

      _expanded: {
        color: 'white',
        borderBottomRadius: 0,
        borderBottom: 0,
      },
    },
  }),

  _expanded: {
    borderBottomRadius: 'none',
    bg: 'beta.300',
    color: 'white',
    borderColor: 'transparent',
    borderBottomColor: '#4B5A6D',

    _hover: {
      bg: 'beta.400',
    },

    ...(isNested && {
      bg: 'gamma.200',
      color: 'white',
      borderBottomColor: 'transparent',

      _hover: {
        bg: 'gamma.300',
      },
    }),
  },
});

export const BoxWrapper: CSSObject = {
  mx: ['2', null, null, '0'],
  px: ['2', null, null, '4'],
  py: ['2'],
  borderBottomRadius: '6px',
  bg: 'beta.500',
};
