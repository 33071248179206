import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: {},
  checkbox: {
    mt: 0.5,
  },
};

export const TabContainer: CSSObject = {
  borderBottom: '0',
  px: '1.5',
  h: '33px',
  alignItems: 'center',
  bg: 'gray.300',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const TabWrapper: CSSObject = {
  gap: '0.5',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  variant: 'tab',

  boxShadow: 'none',
  bg: 'transparent',
  sx: {
    '&[aria-selected="true"]': {
      bg: 'alpha.700',
    },
  },
};
