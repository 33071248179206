import { chakra, Flex } from '@chakra-ui/react';
import { styleImports } from './EventsClosedBanner.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const EventsClosedContainer = chakra(Flex, {
  label: 'eventsClosedBanner-EventsClosedContainer',
  baseStyle: () => ({
    bg: 'yellow.100',
    color: 'yellow.800',
    fontSize: 'sm',
    fontWeight: 'medium',
    display: 'flex',
    ...(() => {
      try {
        return styleImports[themeName]?.EventsClosedContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
