import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import pinata from '@/assets/fiestabet/images/switch/toggleIcon.png';
import oldBoard from '@/assets/fiestabet/images/switch/toggleBg.png';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'lg',
    h: '16',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, null, 'unset'],
    mb: '4',
    gap: '2',
    boxShadow: [
      null,
      null,
      '0px 4px 0px 0px #5C0508, 0px 2px 1px 0px rgba(220, 67, 36, 0.47) inset',
    ],
    bg: 'alpha.700',
    bgImage: `url(${oldBoard})`,
    bgSize: 'contain',
  }),
});

const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'accentMexican',
    fontSize: 'md',
    p: '2',
    flex: 'unset',
    borderRadius: 'full',
    textTransform: 'lowercase',
    fontWeight: 'normal',
    letterSpacing: 'widest',
    px: '2',
    py: '1',
    textAlign: 'center',
    transition: 'all, 0.2s linear',
    color: isActive ? 'beta.300' : 'epsilon.500',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    bg: 'transparent',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: `url(${pinata})`,
        boxSize: '54px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-3',
        right: '0',
        top: '-4',
      },
    },
    '.chakra-switch__track': {
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.30) inset',
      bg: 'epsilon.500',
      w: '50px',
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sports
      </TextToggle>
    </FlexWrapper>
  );
}
