import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, beta.500, beta.600)',
    borderRadius: 'base',
    border: 'none',
    gap: '3',
  },
  depositIconProps: {
    color: 'alpha.700',
    boxSize: '9',
  },
};
