import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  bg: 'white',
  borderRadius: 'md',
  mb: '0',
  flex: '1',
  borderWidth: '2px',
  borderColor: 'beta.400',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear-gradient(180deg, gamma.400 0%, gamma.600 100%)',
  color: 'alpha.700',
  fontSize: '2xs',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'alpha.600',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '30px',
  bg: 'gray.400',
  borderWidth: '2px',
  borderColor: 'gray.300',
  color: 'blackAlpha.500',
  fontWeight: 'black',
  borderRadius: 'lg',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  boxShadow:
    '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset',
  border: 'none',
  opacity: '1',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'alpha.800',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
  color: 'alpha.500',
};
