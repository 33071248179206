import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const { REACT_APP_MOCKING } = window.BETCLOUD_ENV;

/**
 * MSW allows us to intercept requests in the browser
 */
if (REACT_APP_MOCKING === 'true') {
  // eslint-disable-next-line
  const { worker } = require('./__mocks__/browser');
  worker.start();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
