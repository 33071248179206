import React, { ReactElement } from 'react';
import { Show } from '@chakra-ui/react';
import { FlexWrapper, StitchWrapper } from '../styles/Toggle.styles';
import TemplateToggle from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

const Wrapper = ({ children }: { children?: ReactElement }) => (
  <Show below="md">
    <FlexWrapper>
      <StitchWrapper>{children}</StitchWrapper>
    </FlexWrapper>
  </Show>
);

export default combineTemplate(TemplateToggle, [
  { name: 'wrapper', component: <Wrapper /> },
]);
