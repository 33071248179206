import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

import activeBg from '@/assets/goldenrush/images/backgrounds/image-shine-1.png';
import activeBg1 from '@/assets/goldenrush/images/backgrounds/image-shine.png';
import activeBg2 from '@/assets/goldenrush/images/backgrounds/image-shine-2.png';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
  ...((propLength ?? 0) === 2 && {
    gap: '1',
  }),
});

export const ButtonProp = ({ propLength }: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    border: 'none',
    borderTop: '2px dashed',
    borderColor: 'blackAlpha.300',
    bg: 'transparent',
    boxShadow: 'none',

    ...((propLength ?? 0) === 2 && {
      borderTop: 0,
      p: '1',
      _first: {
        pr: '0',
      },
      _last: {
        pl: '0',
      },
    }),
    ...((propLength ?? 0) > 2 && {
      h: '8',

      _first: {
        borderTop: 0,
        pt: '0',
      },
      _last: {
        pb: '0',
      },
    }),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    ...((propLength ?? 0) === 2 && {
      color: 'alpha.600',
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      mb: '2px',
    }),

    ...((propLength ?? 0) > 2 && {
      color: 'alpha.600',
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 600,
    }),

    '&.return-amount': {
      w: '100%',
      bgGradient: 'linear(to-b, #30BA00 0%, alpha.200 0.01%, alpha.400 100%)',
      boxShadow:
        '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
      color: 'white',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
      fontWeight: 'black',
      textTransform: 'uppercase',
      borderRadius: '7px',
      h: '35px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'sm',

      _disabled: {
        bg: '#A0AEC0',
        borderWidth: '2px',
        borderColor: '#CBD5E0',
        color: 'blackAlpha.400',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
        boxShadow:
          '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset',
        border: 'none',
        opacity: '1',

        _hover: { bg: '#A0AEC0' },
      },

      _hover: {
        bg: 'alpha.500',
      },
      _focus: {
        bg: 'alpha.500',
        boxShadow:
          '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
        color: 'white',
        textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
        border: 'none',
      },

      ...(isSelected && {
        bg: 'beta.800',
        boxShadow:
          '0px 0px 0px 2px #E57D24 inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.50) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
        color: 'gamma.500',
        textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
        border: 'none',

        '&&': {
          bgImg: `url(${activeBg}), url(${activeBg1}), url(${activeBg2})`,
          bgRepeat: 'no-repeat',
          bgPosition: '0% 12%,18% 11%,90% 91%',
          bgSize: ['29%, 16%, 24%', '5%, 8%, 12%'],
        },
      }),

      ...((propLength ?? 0) > 2 && {
        width: 'unset',
        h: '32px',
        ...(isSelected && {
          '&&': {
            bgImg: `url(${activeBg}), url(${activeBg}), url(${activeBg})`,
            bgRepeat: 'no-repeat',
            bgPosition: '0% 12%,18% 11%,90% 91%',
            bgSize: '29%, 16%, 24%',
          },
        }),
      }),
    },
  },
});
