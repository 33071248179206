import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  borderRadius: 'lg',
  background:
    'var(--bc-gradient-gold, linear-gradient(180deg, #FFCE70 0%, #CC952B 100%))',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset',
  display: 'flex',
  width: '40px',
  height: '40px',
  margin: 0,
  justifyContent: 'center',
  alignItems: 'center',
  gap: '13px',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'var(--bc-alpha-800, #644915);',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'auto 1fr auto',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  w: '100%',
  px: '12px',
  py: '0',
  columnGap: '8px',
  h: '64px',
  borderRadius: '4px',
  background: 'var(--bc-beta-300, #3F4953)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',

  _hover: {
    bg: 'beta.400',
  },
};

export const MatchName: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  mb: '-5px',
};

export const MatchDate: CSSObject = {
  fontSize: '10px',
};

export const TeamItemOdds: CSSObject = {
  color: 'yellow.900',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const VersusText: CSSObject = {
  display: 'block',
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'epsilon.500',
};

export const Versus: CSSObject = {
  display: 'flex',
  position: 'absolute',
  borderRadius: 'full',
  boxSize: '12',
  bg: 'yellow.600',
  border: '3px solid',
  borderColor: 'yellow.400',
  boxShadow: '0px 1px 20px 0px #00000080',
  color: 'yellow.900',
  left: '50%',
  transform: 'translate(-50%, 0)',
  top: '84px',

  alignItems: 'center',
  justifyContent: 'center',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const TeamItem: CSSObject = {
  border: 'none',
  bg: 'yellow.400',
  '&&': { textColor: 'yellow.900', h: '8' },
  _hover: {
    boxShadow: '0 1px 2px 1px black',
    transform: 'translateY(-3%)',
  },
};

export const TeamItemName: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  mb: '1.5',
  fontWeight: 'semibold',
};

export const Seperator: CSSObject = {
  color: 'gray.300',
  mx: '0',
  px: '0',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon"
`,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TextSpanName = (isVS: boolean): CSSObject => ({});

export const CompetitionName: CSSObject = {
  fontSize: '10px',
};

export const ArrowIcon: CSSObject = {
  boxSize: '20px',
  color: '#D6A64A',
  border: '1px',
  borderColor: '#D6A64A',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  color: 'var(--bc-beta-100, #8192AA)',
  textAlign: 'center',
  fontSize: '10px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  mb: '-8px',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
