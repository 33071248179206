import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  p: '0',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  px: '1',
  pt: '0',
  h: 'auto',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'beta.500' : 'gamma.600',
  bgGradient: !isActive && 'linear-gradient(to-b, gamma.500, gamma.600)',
  backgroundClip: 'border-box',
  borderRadius: 'base',
  borderWidth: '2px',
  borderColor: isActive ? 'beta.500' : 'rgba(255, 255, 255, 0.4)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  color: isActive ? 'beta.700' : 'gamma.900',
  alignText: 'left',
  fontSize: '2xs',
  lineHeight: '14px',
  fontWeight: 'bold',
  letterSpacing: ['wide', 'wider'],
  minW: 'fit-content',
  px: '1.5',
  py: '0.5',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  textTransform: 'uppercase',

  _hover: isActive
    ? undefined
    : {
        opacity: '0.4',
      },

  _focus: {
    boxShadow: isActive
      ? 'inset 0 2px 4px rgba(0, 0, 0, .2)'
      : '0 2px 5px rgba(0, 0, 0, .15)',
  },

  _active: {
    bg: isActive ? undefined : 'alpha.600',
    boxShadow: isActive
      ? 'inset 0 2px 4px rgba(0, 0, 0, .2)'
      : '0 2px 5px rgba(0, 0, 0, .15)',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'beta.500',
  backgroundClip: 'border-box',
  borderRadius: 'base',
  borderWidth: '2px',
  borderColor: 'beta.500',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  color: 'beta.700',
  alignText: 'left',
  fontSize: '2xs',
  lineHeight: '14px',
  fontWeight: 'bold',
  letterSpacing: ['wide', 'wider'],
  minW: 'fit-content',
  px: ['1.5', '1.5'],
  py: '0.5',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  textTransform: 'uppercase',

  _focus: {
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, .2)',
  },

  _active: {
    bg: undefined,
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, .2)',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  p: '0',
};
