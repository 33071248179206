import api from '@/api/api';
import { TBetForRace, TBetsForRaceParams } from './bets.types';

export const betsForRaceURL = '/punter/account/bets-for-race';

export const queryBetsForRace = (params: TBetsForRaceParams) => {
  const newParams = new URLSearchParams();

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(params)) {
    if (!Array.isArray(value)) {
      newParams.append(key, String(value));
    } else {
      for (const val of value) {
        newParams.append(key, String(val));
      }
    }
  }
  /* eslint-enable no-restricted-syntax */

  return api
    .get<TBetForRace[]>(betsForRaceURL, { params: newParams })
    .then((res) => res.data);
};
