import { SRMultiStylesSchema } from './SRMulti.styles';

export const srMultiStyles: SRMultiStylesSchema = {
  multiContainer: {
    mx: '4',
    p: '2',
    bg: '#495564',
    borderRadius: 'md',
    mb: '3',
  },
  multiRunnerContainer: {
    justifyContent: 'space-between',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    p: '3',
    my: '3',
    borderRadius: 'md',
  },
  buttonLabel: {
    color: 'white',
  },
  propButton: {
    w: '50px',
  },
  columnHeadings: {
    w: '50px',
    textAlign: 'center',
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
  },
  headingLabel: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
  },
  headingContainer: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'medium',
    marginRight: '1',
  },
  runnerList: {
    borderRadius: 'base',
    bgGradient: 'linear(232deg, #3f4953, #4b5a6d)', // @TODO colour not in palette
    p: '2',
    boxShadow:
      '0 4px 4px 0 #00000040, inset 0 1px 0 0 #ffffff26, -1px 0 18px 0 #00000026',
  },
};
