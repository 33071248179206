import { rest } from 'msw';
import { upcomingMatchesEndpoint } from './upcomingMatches';
import { makeData } from './upcomingMatches.mocks';
import { TQueryUpcomingMatchesResponse } from './upcomingMatches.types';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

export const upcomingMatchesHandlers = [
  rest.get(`${baseURL}${upcomingMatchesEndpoint}`, (req, res, ctx) => {
    const { searchParams } = req.url;
    const limit = Number(searchParams.get('limit'));

    return res(
      ctx.status(200),
      ctx.json<TQueryUpcomingMatchesResponse>(makeData({ limit }))
    );
  }),
];
