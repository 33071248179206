import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 'xl',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  navOverride: {
    mb: '0',
  },
  divider: {
    mb: '0',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
};
