export const FlexWrapper = {
  pos: 'relative',
  mt: '1',
  p: '2',
  mx: ['2', null, 'unset'],
  mb: ['1', null, '4'],
  borderRadius: '5px',
  border: '1px solid rgba(255, 255, 255, 0.10)',
  height: '60px',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'alpha.400',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',

  '.sub-wrapper': {
    w: '100%',
    h: '100%',
    px: '2px',
    bg: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.12), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
  },
};

export const TextToggle = (isActive: boolean) => ({
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  color: isActive ? 'alpha.400' : 'white',
  letterSpacing: 'widest',
  px: '3',
});

export const ButtonToggle = (isActive: boolean) => ({
  w: '100%',
  h: '38px',
  px: '12px',
  my: '2px',
  borderRadius: '3px',
  fontWeight: 'bold',
  fontSize: 'normal',
  textTransform: 'uppercase',
  border: isActive ? '1px solid rgba(255, 255, 255, 0.10)' : 'none',
  letterSpacing: 'wide',
  textShadow: isActive ? '0px 0px 12px rgba(13, 252, 255, 1)' : '',
  bg: isActive
    ? 'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))'
    : 'transparent',
  color: isActive ? 'beta.300' : 'whiteAlpha.500',
  _hover: {
    bg: isActive
      ? 'linear(to-b, rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.12))'
      : 'transparent',
  },

  '> svg': {
    display: 'none',
  }
});
