import { CSSObject } from '@chakra-ui/react';
import coin from '../../../../../assets/betroyale/images/switch/coin.png';

export const FlexWrapper = {
  borderRadius: 'md',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: ['1.5', null, 'unset'],
  mb: ['2', null, '3'],
  mt: '1',
  gap: '2',
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px rgba(255, 255, 255, 0.10) inset',
  bg: 'rgba(26, 165, 255, 0.15)',
  h: '14',
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'Spectral',
  fontWeight: 'extrabold',
  fontSize: 'md',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 4px rgba(102, 194, 255, 0.51)'
    : '0px 3px 4px rgba(0, 0, 0, 0.25)',
  color: isActive ? 'alpha.400' : 'beta.500',
  letterSpacing: 'wider',
  px: '2',
});

export const Switcher = (): CSSObject => ({
  bg: 'transparent',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${coin})`,
      boxSize: '12',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-3',
      right: '0',
      top: '-3',
    },
  },
  '.chakra-switch__track': {
    boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
    bg: 'blackAlpha.600',
  },
});
