import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  columnGap: '12px',
  order: 4,
};

export const BoxColumn: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  rowGap: '6px',
  mb: ['20px', null, '12px'],
};

export const TextRaceNumber: CSSObject = {
  h: '1.075rem',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1',
  borderRadius: '3px',
  backgroundColor: 'beta.800',
  color: 'white',

  fontFamily: 'Roboto',
  fontSize: '9px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.2,
  letterSpacing: 'normal',
  textAlign: 'center',
};

export const LinkCardHeader: CSSObject = {
  pr: '4',
};

export const LinkRaceContainer: CSSObject = {
  height: '47px',
  gap: '4px',
  py: '14px',
  pl: '12px',
  pr: '18px',
  borderRadius: '5px',
  WebkitBackdropFilter: 'blur(5px)',
  backdropFilter: 'blur(5px)',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.30), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px 3px 0px 0px #203A4E',
  bgGradient: 'linear(to-b, beta.600, beta.700)',

  '&&': {
    mb: 0,
  },
};
export const TextRaceType: CSSObject = {
  fontFamily: 'PatrickHand',
  fontSize: '1.125rem',
  fontWeight: 500,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.35rem',
  letterSpacing: '3.6px',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: '#fff',
  mb: '-2px',
};
export const TextRace: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 1.2,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#fff',
};

export const Icon: CSSObject = {
  boxSize: '21px',
  color: 'white',
  mr: 0,
};
