import { TRaceMarket, TRaceMeta } from '../../lib/DBModels';
import api from '../api';
import {
  TBlendedPricingParams,
  TMutateBlendedPricingResponse,
  TQueryRaceVenueListParams,
  TRaceMarketsParams,
  TRaceMetaParams,
  TRunnerListParams,
  TRunnerListResponse,
  TVenueResponse,
} from './racing.types';

/**
 * Race Markets
 */
export const racingMarketsEndpoint = 'punter/races/race-details/markets';
export const queryRaceMarkets = (params: TRaceMarketsParams) =>
  api
    .get<TRaceMarket[]>(racingMarketsEndpoint, { params })
    .then((res) => res.data);

/**
 * Venue list
 */
export const racingVenueList = 'punter/races/venue-list';
export const queryRaceVenueList = (params: TQueryRaceVenueListParams) =>
  api.get<TVenueResponse>(racingVenueList, { params }).then((res) => res.data);

/**
 * Race details
 */
export const raceDetailsRunnerList = 'punter/races/race-details/runner-list';
export const queryRunnerList = (params: TRunnerListParams) =>
  api
    .get<TRunnerListResponse>(raceDetailsRunnerList, { params })
    .then((res) => res.data);

export const raceMetaEndpoint = 'punter/races/race-details/race-meta';
export async function queryRaceMeta(params: TRaceMetaParams) {
  const res = await api.get<TRaceMeta>(raceMetaEndpoint, {
    params,
  });
  return res;
}

export const blendedEndpoint = '/punter/pricing/blended';
export const mutateBlendedPricing = async (params: TBlendedPricingParams) =>
  api.post<TMutateBlendedPricingResponse>(blendedEndpoint, params);
