import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'bold',
  color: 'white',
  lineHeight: '12px',
  mb: 0,
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
  fontWeight: 'normal',
};

export const ImageNoRaces: CSSObject = {
  w: '285px',
};
