import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'xl',
  fontWeight: 'normal',
  color: 'gamma.500',
  textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
  fontFamily: 'Angkor',
  textTransform: 'capitalize',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
