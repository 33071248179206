import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    bg: 'alpha.500',
    borderTopRadius: 'base',
    mb: '0',
    borderBottom: 'none',
    pl: '2',
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: ['2', null, '1'],
      },
    },
  },
  boxWrapper: {
    mx: ['2', null, 'unset'],
    borderRadius: '0',
    pb: 'unset',
  },
  textTitle: {
    mt: '0',
    mb: '2',
    color: 'white',
    fontSize: 'md',
    fontWeight: 'medium',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderRadius: 'md',
        bg: 'alpha.500',
        color: 'white',
        boxShadow:
          '0px 6px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 0px 0px #A08656, 0px 0px 0px 0px rgba(255, 255, 255, 0.20) inset',
        flexDir: 'column',
      },
    },
  },
  titleContainer: {
    px: '2',
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
    mt: '4',
  },
};
