import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgSize: 'cover',
  bgRepeat: 'repeat',
  h: '100%',
  maxH: '100dvh',
  borderRadius: 'lg',
  p: '0',
  bg: ['rgba(129, 83, 255, 1)', 'rgba(129, 83, 255, 0.5)'],
  boxShadow: [
    'unset',
    null,
    null,
    '0px 3px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  ],
  backdropFilter: 'blur(8px)',
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
  marginTop: '1',
};
export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '8px',
  mx: '3px',
  borderTop: ['unset', null, '1px solid'],
  borderTopColor: ['unset', null, 'rgba(255, 255, 255, 0.01)'],
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
export const BetSlipContainer: BoxProps = {
  boxShadow: 'none',
  border: 'none',
  bg: ['#5429C8', 'transparent', null, null],
};
export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};
export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'body',
  fontSize: 'sm',
};
export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'gamma.400',
  fontFamily: 'body',
  fontSize: 'sm',
});
export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};
export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};
export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '6',
  width: 'auto',
  margin: 'auto',
  mt: '-4',
  pl: 1,
  pr: 2,
  display: 'flex',
  color: 'alpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  fontSize: '2xs',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.300, gamma.400)',
  textTransform: 'uppercase',
  border: 'none',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(5px)',
  _hover: {
    bgGradient: 'linear(to-b, gamma.200, gamma.200)',
    boxShadow:
      '0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
    backdropFilter: 'blur(5px)',
    border: 'none',
    color: 'alpha.600',
  },
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3.5',
    },
  },
};
export const BetslipVStack: CSSObject = {
  gap: '2.5',
};
export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};
export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  color: 'gamma.400',
  height: '42px',
  sx: {
    '&&': {
      fontWeight: 'bold',
      fontSize: 'xs',
      pt: '0.5',
      svg: {
        pb: '1',
      },
    },
  },
};
