import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '38px',
      background: 'beta.700',
      color: 'white',
      borderRadius: 'lg',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      border: 'none',
      px: '2',
      'input, span': {
        fontFamily: 'roboto',
        fontWeight: 'normal',
      },
      button: {
        color: 'alpha.400',
      },
      boxShadow: 'none',
      _hover: {
        bg: 'beta.600',
        border: 'none',
      },
      _active: {
        border: 'none',
      },
      _focus: {
        border: 'none',
      },
    },
  },
};
