import React, { forwardRef, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { TBetSlipBet } from '../../../../Services/Betslip.types';
import { useBetFormField } from '../../../../Services/Betslip.hooks';
import { FormText, ThemedInputAddon, InputBetSlip } from './Forms.styles';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
// import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks/useRedux';
import { setBetIDFocused } from '../../../../../../redux/Betslip.slices';

// This components wraps the standard text input with handling to:
// - show the mobile keypad when the element is focused
// - show text when the betslip is in review or when is submitted
// - show errors when the entire betslip is in error
// - do not allow editing when the bet has been placed via confirmation.status property

type FormsInputProps = {
  fieldName: string;
  bet: TBetSlipBet;
  cyLabel: string;
  placeholder: string;
  errorMessage?: string;
  onChange: (event: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onWheel?: (e: React.WheelEvent<HTMLInputElement>) => void;
  value: string;
  prefix?: string;
  suffix?: string;
  keypadPrefix?: string;
  width?: string;
  hasDataTag?: boolean;
};

const FormsInput = forwardRef<HTMLInputElement, FormsInputProps>(
  (
    {
      fieldName,
      bet,
      onChange,
      onFocus,
      onBlur,
      cyLabel,
      placeholder,
      errorMessage,
      value,
      prefix,
      suffix,
      width,
      hasDataTag = true,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const { isReviewing, onTextInputFocus, showFieldError } = useBetFormField(
      bet,
      fieldName
    );

    return (
      <Box minH="8" w={width}>
        {isReviewing ? (
          <FormText>
            {(() => {
              const string = [];
              if (prefix) string.push(prefix);
              string.push(value);
              if (suffix) string.push(suffix);
              return string.join('');
            })()}
          </FormText>
        ) : (
          <InputBetSlip
            ref={ref || inputRef}
            role="group"
            name={fieldName}
            type="text"
            sxWrapper={{ mb: 0 }}
            placeholder={placeholder}
            error={showFieldError ? errorMessage : undefined}
            onFocus={(e) => {
              dispatch(setBetIDFocused(bet.request_id));
              onTextInputFocus();
              onFocus?.(e);
            }}
            autoComplete="off"
            aria-label={fieldName}
            onChange={(e) => {
              e.preventDefault();
              onChange(e.currentTarget.value);
            }}
            onBlur={onBlur}
            onWheel={(e) => e.currentTarget.blur()}
            value={value}
            data-cy={cyLabel}
            {...(hasDataTag && { 'data-input': 'betslip-input' })}
            inputMode="none"
            id={bet.id ?? bet.request_id}
            isFormik={false}
            {...{
              ...(prefix && {
                InputLeftElement: () => (
                  <ThemedInputAddon isPrefix>{prefix}</ThemedInputAddon>
                ),
              }),
              ...(suffix && {
                InputRightElement: () => (
                  <ThemedInputAddon>{suffix}</ThemedInputAddon>
                ),
              }),
            }}
          />
        )}
      </Box>
    );
  }
);

export default FormsInput;
