/* eslint-disable import/no-mutable-exports */
import { Flex } from '@chakra-ui/react';
import { IntlShape } from 'react-intl';
import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import AccountWrapper from '../../../../wrappers/AccountWrapper/AccountWrapper';
import OverviewContent from '../OverviewContent';
import { TextTitle } from '../styles/Overview.styles';

export const template = (intl: IntlShape): TTemplate => ({
  wrapper: (
    <AccountWrapper
      pageTitle={intl.formatMessage({ id: 'account.overview.pagetitle' })}
      pageHeader={
        <Flex as="span" flexDirection="column">
          <TextTitle sx={{ mr: '1' }}>
            {intl.formatMessage({ id: 'account.overview.myAccount' })}
          </TextTitle>
        </Flex>
      }
    />
  ),
  content: <OverviewContent />,
});

export default template;
