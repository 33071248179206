import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'secondary',
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
    px: '2',
    borderRadius: 'md',
    sx: {
      '&&': {
        h: '24px',
      },
      '&[data-active="true"]': {
        color: 'beta.400',
        textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
        bg: 'alpha.700',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    
        _after: {
          opacity: 0,
        },
      },
    },
  },
}
