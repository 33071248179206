import { FlexProps, BoxProps, TextProps, IconProps } from '@chakra-ui/react';
import { styleImport } from './PlayerLayout.styles.imports';

type TDataTestID = { 'data-testid'?: string };

export type TPlayerLayoutStylesSchema = {
  grid: BoxProps & TDataTestID;
  gridSingular: BoxProps;
  row: BoxProps & TDataTestID;

  name: TextProps & TDataTestID;
  nameWrapper: BoxProps;
  propWrapper: BoxProps;

  btnGroupWrapper: FlexProps;
  btnGroupIcon: Omit<IconProps, 'css' | 'as'>;
  btnGroupText: TextProps;
};

export const playerLayoutStyles: Partial<TPlayerLayoutStylesSchema> = {
  // Layout
  grid: {
    'data-testid': 'playerOverUnderLayout-grid',

    display: 'flex',
    flexDir: 'column',
    rowGap: '1.5',
    py: '1.5',

    ...styleImport?.grid,
  },
  gridSingular: {
    ...styleImport?.gridSingular,
  },
  row: {
    'data-testid': 'playerOverUnderLayout-row',

    display: 'flex',
    columnGap: '2',
    overflowX: 'auto',

    sx: {
      '::-webkit-scrollbar': { display: 'none' },
    },

    ...styleImport?.row,
  },

  propWrapper: {
    gap: '1',
    pr: '2',
    ml: 'auto',

    ...styleImport?.propWrapper,
  },

  nameWrapper: {
    'data-testid': 'playerOverUnderLayout-nameWrapper',
    alignSelf: 'center',
    minW: ['130px', 'unset'],

    ...styleImport?.nameWrapper,
  },

  // Rest
  name: {
    'data-testid': 'playerOverUnderLayout-name',

    whiteSpace: 'nowrap',
    fontSize: 'xs',
    fontWeight: 'bold',
    pl: '2',
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    sx: { whiteSpace: 'nowrap' },

    ...styleImport?.name,
  },

  btnGroupWrapper: {
    'data-testid': 'playerOverUnderLayout-btnGroupWrapper',

    order: 2,
    alignItems: 'center',
    gap: 2,

    ...styleImport?.btnGroupWrapper,
  },
  btnGroupIcon: {
    'data-testid': 'playerOverUnderLayout-btnGroupIcon',

    h: '5',
    w: '5',
    minW: '5',
    cursor: 'pointer',

    ...styleImport?.btnGroupIcon,
  },
  btnGroupText: {
    fontSize: 'md',
    flex: 1,
    textAlign: 'center',
    userSelect: 'none',

    ...styleImport?.btnGroupText,
  },
};
