import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  pt: '18',
  textAlign: 'center',
  mx: 'auto',
  w: 'full',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'xl',
  lineHeight: '1',
  color: 'gamma.300',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  w: '203px',
  my: '0',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'sm',
  color: 'white',
  my: '1.5',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};
