import { ButtonProps, CSSObject } from '@chakra-ui/react';

export const TableItem: CSSObject = {
  borderColor: 'blackAlpha.400',
  borderRight: 'none',
  borderStyle: 'dashed',
  color: 'white',
  pl: 0,
  py: 2,
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const TableText = ({ textVariation }: any): CSSObject => ({
  color: textVariation === 'positive' ? 'beta.500' : 'white',
});

export const NoTransactionsText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const buttonMoreTableProps: ButtonProps = {
  p: '0 10px',
  variant: 'secondary',
  height: 6,
};
