import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/bet575/images/background/bg.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), linear-gradient(180deg, var(--bc-colors-alpha-600), var(--bc-colors-alpha-800))`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundBlendMode: 'overlay, normal',
          '@media (max-width: 767px)': {
            background: `linear-gradient(180deg, var(--bc-colors-alpha-600), var(--bc-colors-alpha-800))`,
          },
        },
      }}
    />
  );
}
