import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  py: '2',
  borderRadius: 'md',
  gap: '1',
  borderTop: '1px dashed',
  borderColor: 'blackAlpha.300',
  minH: ['10', null, 'unset'],
  bg: 'beta.700',
};

export const RacerName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'whiteAlpha.500',
};
