import { CSSObject } from '@chakra-ui/react';
import qblogo from '../../../assets/questbet/images/header/logo.png';
import maintanence from '../../../assets/questbet/images/backgrounds/onboarding.png';
import bgWater from '../../../assets/questbet/images/backgrounds/fishBackgroundDesktop.png';

export const Mascot: CSSObject = {
  w: '150px',
  h: '30px',
  mx: 'auto',
  mt: '10',
  mb: '0',
  bgImage: `url(${qblogo})`,
  zIndex: '1',
};

export const MaintenanceContainer = (): CSSObject => ({
  bgImage: `url(${maintanence})`,
  bgSize: ['cover', null, '100% 100%'],
  bgPos: 'center',
  bgRepeat: 'no-repeat',
});

export const CenterWrapper: CSSObject = {
  my: ['0', '28'],
  w: '100%',
  justifyContent: 'start',
  bgImage: `url(${bgWater})`,
  bgSize: 'cover',
  bgPos: 'center',
  bgRepeat: 'no-repeat',
  borderRadius: [null, 'xl'],
  boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
};

export const ContentHeadingText: CSSObject = {
  color: 'white',
  fontFamily: 'Strippy',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: ['2xl', null, '3xl'],
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2))',
};

export const ContentBodyText: CSSObject = {
  color: 'white',
  fontFamily: 'body',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 'md',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2))',
  '& > span': {
    fontSize: 'sm',
  },
};
