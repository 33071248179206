import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  p: 0,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.400',
};

export const CardContainer: CSSObject = {
  bgGradient: 'linear(145deg, beta.300, beta.200)',
  boxShadow:
    '0px 0px 0px 1px #4A5454 inset, 0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'md',
  border: 'none',
  p: 1.5,
};

export const DividerCard: CSSObject = {
  border: 'none',
};

export const CardBottomContent: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'whiteAlpha.300',
  '.multiDivider': {
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    width: 'unset',
  },
};

export const ExoticSelectionText: CSSObject = {
  color: 'white',
};

export const ContentRow: CSSObject = {
  alignItems: 'start',
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
};

export const dividerExoticProps: DividerProps = {
  borderTop: '1px dashed',
  borderTopColor: 'blackAlpha.500',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.300',
  w: 'full',
};

export const dividerExoticLastProps: DividerProps = {
  borderTop: '1px dashed',
  borderTopColor: 'blackAlpha.500',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.300',
};
