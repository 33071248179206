import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  '&&': {
    fontSize: heading ? 'sm' : 'xs',
    fontWeight: heading ? 'semibold' : 'normal',
    color: 'white',
    lineHeight: '1.3',
  },
});
export const RadioText: CSSObject = {
  '&&': {
    fontSize: 'xs',
    mb: '2',
    fontWeight: 'medium',
    color: 'white',
  },
};
export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'whiteAlpha.500',
  borderRadius: 'base',
  p: '4',
  '.chakra-input__group': {
    borderRadius: 'md',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },
  '.chakra-input__left-addon': {
    bg: 'blackAlpha.400',
    fontSize: 'xs',
    fontWeight: 'semibold',
    border: 'none',
    h: '9',
    color: 'alpha.400',
    pl: '4',
    pr: '2',
    pt: 'px',
  },
  '.chakra-select': {
    bg: 'blackAlpha.400',
    fontSize: 'xs',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-select__icon-wrapper': {
    color: 'alpha.400',
  },
  '#limitAmount-input': {
    fontSize: 'xs',
    fontWeight: 'medium',
    bg: 'blackAlpha.400',
    color: 'white',
    border: 'none',
    h: '9',
    px: '0',
    '&&:hover, &&:focus': {
      bg: 'blackAlpha.400',
      border: 'none',
    },
  },
};
export const FlexHeaderWrapper: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: ['4', null, '5'],
  pt: [null, null, '1'],
  gap: '2',
};
export const ButtonSubmit: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const RadioLimitChoice: CSSObject = {
  bg: 'white',
  border: 'none',
  mb: '2',
  '&[data-checked]': {
    bg: 'blue.400',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'blue.400',
  },
  '&[data-checked]:hover': {
    bg: 'blue.400',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'blue.400',
  },
};
