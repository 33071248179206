import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'epsilon.500',
};

export const RunnerDescription: CSSObject = {
  color: 'gray.500',
  fontWeight: 'bold',
};

export const BetOdds: CSSObject = {
  color: 'gray.700',
};

export const TextBarrierNumber: CSSObject = {
  color: 'gray.500',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  bg: () => {
    switch (status) {
      case 'won':
        return 'beta.700';
      case 'lost':
        return 'gray.400';
      case 'voided':
        return 'gray.400';
      case 'cancelled':
        return 'gray.400';
      case 'pending':
        return 'yellow.400';
      default:
    }
  },
});

export const HeaderSvg: CSSObject = {
  color: 'epsilon.500',
  boxSize: '5',
};

export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};

export const MultiIcon = () => (
  <IconSvg
    name="MultiIconVolcanobet"
    className="svgIcon"
    boxSize="6"
    mb="1.5"
  />
);

export const headerRowStyles: HeaderRowSchema = {
  mysteryBetIcon: {
    fill: 'beta.700',
  },
};
