import { Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import logo from '@/assets/questbet/images/logo.svg';
import mobileLogo from '@/assets/questbet/images/mobile_logo.svg';

export default function Index() {
  const [largerThan992] = useMediaQuery('(min-width: 992px)');
  if (largerThan992) {
    return <Image src={logo} maxW="165px" ml="2" />;
  }

  return <Image src={mobileLogo} ml="2" />;
}
