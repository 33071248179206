import { UpcomingMatchesSchema } from './UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  stackWrapper: {
    px: ['2.5', null, null, '3'],
    pb: '4',
    color: 'white',
  },
  pageHeader: {
    px: '1',
    fontSize: 'md',
    fontStyle: 'normal',
    fontWeight: 'medium',
    fontFamily: 'Roboto',
    lineHeight: 'normal',
    color: 'alpha.700',
    textTransform: 'capitalize',
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    lineHeight: '13.3px',
    textTransform: 'uppercase',
    color: 'gray.700',
  },
  scrollButtonGroup: {
    bg: 'alpha.700',
    variant: 'filter',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    gap: '1.5',
    color: 'brand.700',
    fontSize: 'md',
    fontWeight: 'medium',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
  },
  headingIcon: {
    color: 'gamma.600',
  },
  propositionButton: {
    flex: '1',
    height: '35px',
    display: 'initial',
    p: '0',
    boxShadow: '0px 3px 4px 0px #7dc5fb inset',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  boxSportWrapper: {
    borderColor: 'whiteAlpha.300',
    borderWidth: '1px',
  },
};
