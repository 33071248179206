import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'home.haveAPunt': 'View match card',
  'header.betslipBtn.desc': 'Slip',
  'onboarding.locationnotallowed.header': 'Someone left the gate open...',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing Buffalobet from outside of Australia.',
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.heading': `The Buffalo’s on break`,
  'error.heading': `A wild error appeared!`,
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'betslip.betslipmodal.emptybetslipheading': 'Ready to place some bets?',
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your racing and sports selections.',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed.',
  'sports.allsports.azheading': 'Sports',
  'generic.sportAZ': 'A-Z',
  'generic.showmore': 'Show all',
  'account.withdraw.requests.cancelbutton': 'Cancel withdrawal request',
  'account.withdraw.requests.heading': 'Withdrawal Request',
};
