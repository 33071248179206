import { css } from 'styled-components';

// TODO: Remove
export const spinner = css`
  border: 3px solid ${({ theme }) => theme.palette.primary};
  border-top: 3px solid transparent;

  width: var(--bc-sizes-9);
  height: var(--bc-sizes-9);
`;

export const spinnerWrapper = css``;
