export const colors = {
  alpha: {
    100: '#6F6F6F',
    200: '#4E4E50',
    300: '#393C3F',
    400: '#39393D',
    500: '#323237',
    600: '#272730',
    700: '#222222',
    800: '#1C242D',
    900: '#001336',
  },
  beta: {
    100: '#DFFF8F',
    200: '#C7FF3C',
    300: '#ADF106',
    400: '#8FC608',
    500: '#79A806',
    600: '#648B05',
    700: '#517104',
    800: '#425B03',
    900: '#354A03',
  },
  gamma: {
    100: '#FFC17E',
    200: '#FFA84A',
    300: '#FA9F3D',
    400: '#EC902D',
    500: '#D57D1D',
    600: '#B6650C',
    700: '#753E03',
    800: '#643503',
    900: '#572F04',
  },
  delta: {
    100: '#B6D9FF',
    200: '#86BFFF',
    300: '#489FFF',
    400: '#2B8BF5',
    500: '#0E79EE',
    600: '#055EC0',
    700: '#054EA0',
    800: '#134072',
    900: '#092E57',
  },
};
