const branding = {
  alpha: {
    50: '#E9BFD7',
    100: '#D59CBC',
    200: '#D691B8',
    300: '#D587B3',
    400: '#B96C91',
    500: '#773053',
    600: '#521A35',
    700: '#370F23',
    800: '#210A15',
    900: '#180810',
  },
  beta: {
    50: '#CFFFF6',
    100: '#90FFEB',
    200: '#50FFDF',
    300: '#37E7C6',
    400: '#08B898',
    500: '#006352',
    600: '#004F42',
    700: '#003B31',
    800: '#002821',
    900: '#001410',
  },
  gamma: {
    50: '#FFE5D2',
    100: '#FFC99F',
    200: '#FFB277',
    300: '#FF9E54',
    400: '#FF8A2F',
    500: '#DA5F00',
    600: '#AC4C01',
    700: '#743402',
    800: '#542500',
    900: '#371800',
  },
  delta: {
    50: '#FFD2C9',
    100: '#FFA795',
    200: '#FF836A',
    300: '#FC603F',
    400: '#DC4324',
    500: '#CF191F',
    600: '#A60E14',
    700: '#83090D',
    800: '#5C0508',
    900: '#350103',
  },
  epsilon: {
    50: '#FFFAEB',
    100: '#FFF2C9',
    200: '#FFE287',
    300: '#FFDF7D',
    400: '#F3C365',
    500: '#FFC40B',
    600: '#E3AD0D',
    700: '#C5970D',
    800: '#977409',
    900: '#695005',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  theta: branding.theta,
  delta: branding.delta,
  epsilon: branding.epsilon,
  eta: branding.eta,
};
