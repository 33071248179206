import { Flex, Skeleton } from '@chakra-ui/react';
import React from 'react';

type TLoading = {
  maxCount: number;
};

export default function Loading({ maxCount }: TLoading) {
  return (
    <Flex flexDir="column" rowGap="px">
      {[...new Array(10)].map((__, index) => (
        <Flex key={`skel-row-${index}`} sx={{ columnGap: 'px' }}>
          {[...new Array(maxCount + 1)].map((_, i) => (
            <Skeleton
              key={`skel-col-${i}`}
              boxSize="45px"
              sx={{
                boxSize: '45px',
                flex: '1',
                borderRadius: 'none',
                ...(i === 0 && { w: '88px', minW: '88px' }),
              }}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
}
