import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '3.5',
  mt: ['-3', null, '-2'],
  mx: ['-4', '-5'],
  px: ['2', '0'],
  bg: 'white',
  top: ['0px', '9'],
  position: 'unset',
};

export const ButtonFilter = ({ selected }: TSelected) => ({
  color: selected ? 'alpha.600' : 'gray.400',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'alpha.600' : 'gray.400',
  color: selected ? 'white' : 'transparent',
});
