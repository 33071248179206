import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
    fontSize: '2xs',
    px: '2',
    borderRadius: 'md',
    '&:active, &[data-active="true"]': {
      '&&': {
        color: 'delta.50',
      },
    },
    sx: {
      '&&': {
        h: '24px',
      },
    },
  },
}

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'gray.700',
};
