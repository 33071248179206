import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'outline',
    fontSize: '2xs',
    height: '7',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.15) inset',
  },
};
