import { IS_MOBILE_APP } from '../../constants/isMobileApp';
import { TAnalyticEventNames } from './types';

// eslint-disable-next-line no-multi-assign
const dataLayer = (window.dataLayer = window.dataLayer || []);

export function trackEvent(
  name: TAnalyticEventNames,
  params?: Record<string, string | boolean | number | undefined>
) {
  dataLayer.push({
    event: name,
    timestamp: new Date().getTime(),
    ...params,
  });
}

export function trackPageView(url: string, punterId?: string) {
  if (IS_MOBILE_APP || !url) return;

  dataLayer.push({
    event: 'page_view',
    url,
    punterId,
  });
}

export function trackUser(
  punterId: string | null | undefined,
  punterEmail: string | null | undefined
) {
  if (IS_MOBILE_APP) return;

  if (punterId && punterEmail) {
    dataLayer.push({
      punterId,
      punterEmail,
    });
  }
}

export function resetAnalytics() {
  dataLayer.push({
    punterId: undefined,
    punterEmail: undefined,
  });

  if (!window.analytics) return;

  try {
    window.analytics.reset();
  } catch (e) {
    console.error('[useAnalytics] Error calling logout user', e);
  }
}
