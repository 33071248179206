import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  my: '1',
  gap: '1.5',
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  fontFamily: 'accent',
  pt: '0.5',
};
