import navBg from '@/assets/juicybet/images/backgrounds/inner.png';

import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: '100%',
    mt: ['1', null, null, '4'],
    mb: ['1', null, null, '0'],
    bg: ['transparent', null, null, 'alpha.50'],
    px: '1',
    pt: '1',
    borderRadius: ['none', null, null, 'xl'],
    border: ['unset', null, null, '6px solid'],
    borderColor: ['unset', null, null, 'alpha.500'],
    sx: {
      '&': {
        backgroundSize: `auto, 85%`,
      },
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    borderRadius: ['0px', '0px', '0px', 'base'],
    boxShadow: [
      'none',
      null,
      null,
      '0px 0px 10px 0px #CD3A39 inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.12) inset',
    ],
    sx: {
      '&&': {
        marginTop: '0',
        marginBottom: '1',
        bg: [
          'transparent',
          null,
          null,
          `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${navBg})`,
        ],
        backgroundBlendMode: 'overlay',
      },
    },
  },
};
