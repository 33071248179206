import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  borderTop: '1px',
  borderTopColor: 'blackAlpha.300',
};
export const TextListName: CSSObject = {
  fontSize: ['xs', null, 'sm'],
};
export const TextSpanName = (): CSSObject => ({
  color: 'white',
});
export const FlexSubTextWrapper: CSSObject = {
  color: 'beta.500',
  fontSize: ['2xs', null, 'xs'],
};
export const IconSport: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  color: 'white',
};
export const SubTextIcon: CSSObject = {
  fill: 'white',
};
export const IconChevron: CSSObject = {
  color: 'white',
};

export const FlexEndWrapper: CSSObject = {
  fontSize: '2xs',
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'blackAlpha.800',
    borderRadius: 'sm',
    maxW: '10',
  },

  '.chakra-badge.state-prior': {
    bg: 'transparent',
    color: 'white',
    borderRadius: 'sm',
    maxW: '10',
  },

  '.chakra-badge.state-ended': {
    bg: 'alpha.500',
    color: 'white',
    borderRadius: 'sm',
    maxW: '10',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const IconSGM: CSSObject = {
  h: 'auto',
  mr: '2',
  w: '8',
  color: 'white',
};
