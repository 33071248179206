import { CSSObject } from '@chakra-ui/react';

export const OddsText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'extrabold',
  color: 'white',
};

export const MultiCardContainer: CSSObject = {
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
  _first: {
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.20)',
    borderTop: '1px dashed',
    borderColor: 'blackAlpha.300',
  },
  _last: {
    borderBottom: 'none',
    boxShadow: 'none',
  },
};

export const OddsMultiCard: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'white',
};
