import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  borderColor: 'blackAlpha.400',
  borderRight: 'none',
  pl: 0,
  py: 2,
  color: 'white',
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
  p: '2',
  pb: '4',
};

export const TableText = ({ textVariation }: TTableText): CSSObject => ({
  color: textVariation === 'positive' ? 'green.500' : 'white',
});

export const NoTransactionsText: CSSObject = {
  color: 'white',
};
