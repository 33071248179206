import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  color: 'alpha.400',
  borderRadius: 'md',
  p: '2',
  bg: 'gray.100',
  mb: '2',
};

export const RunnerName: CSSObject = {
  fontWeight: 'semibold',
  fontSize: '12px',
  color: 'alpha.900',
  textTransform: 'capitalize',
  lineHeight: '133%',
};

export const FlexDeductionsContainer: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'alpha.500',
  textTransform: 'uppercase',
  flexDir: 'column-reverse',
};

export const TextDeductions: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'alpha.500',
};

export const ScratchedText: CSSObject = {
  color: 'alpha.800',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
};

export const TextScratchedInfo: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'gray.500',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  borderColor: 'alpha.500',
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1.5',
};

export const RunnerPropositionWrapper: CSSObject = {
  columnGap: '2',
  '.chakra-button': {
    display: 'flex',
    maxWidth: '14',
    height: '9',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 'sm',
    fontWeight: 'black',
    color: '#255505',
  },
};

export const GreyhoundRunnerSilk: CSSObject = {
  borderRadius: '6px',
  background: 'white',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.30)',
  padding: '2px',
};

export const TextBarrierNumber: CSSObject = {
  color: 'alpha.900',
  fontWeight: 700,
  lineHeight: '120%',
};
