import React from 'react';
import {
  chakra,
  MenuList as ChakraMenuList,
  MenuItem as chakraMenuItem,
  ChakraComponent,
  MenuItemProps,
  Text,
  forwardRef,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './Menu.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

const CustomText = forwardRef((props: TextProps, ref) => (
  <Text {...props} ref={ref as any} />
));
export const TextTitle = chakra(CustomText, {
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'xl',
    mb: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextSpan = chakra(Text, {
  baseStyle: () => ({
    display: 'inline-flex',
    gap: '2',
    alignItems: 'end',

    ...(() => {
      try {
        return styleImports[themeName]?.TextSpan;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

export const MenuList = chakra(ChakraMenuList, {
  baseStyle: () => ({}),
});

export const MenuItem = chakra(chakraMenuItem, {
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'semibold',
    transition: 'all 0.2s ease-in-out',
    maxW: '300px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minW: '0',
    overflow: 'hidden',
    display: 'block',
  }),
}) as ChakraComponent<'button', MenuItemProps>;
