import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',
    color: 'epsilon.200',
    fontFamily: 'roboto',
    minW: '59px',
    height: '5',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    border: '2px solid',
    borderColor: 'epsilon.200',

    _hover: {
      '&&': {
        background: 'transparent',
        color: 'gamma.100',
      },
    },
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: '-webkit-fill-available',
  left: '0',
  bg: 'alpha.700',
  borderTop: '2px',
  borderTopColor: 'alpha.800',
  borderBottomRadius: 'md',
};

export const buttonKeysProps: CustomButtonProps = {
  fontFamily: 'roboto',
  height: '100%',
  fontSize: 'xl',
  border: 'none',
  color: 'delta.800',
  fontWeight: 'black',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.40)',
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  bg: 'unset',
  _hover: {
    bgGradient: 'linear(to-b, delta.400, delta.700)',
  },
};

export const buttonDoneKeyProps: CustomButtonProps = {
  fontFamily: 'roboto',
  textTransform: 'uppercase',
  height: '45px',
  fontSize: 'xl',
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  color: 'beta.500',
  border: 'none',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.50)',
  sx: {
    svg: {
      boxSize: '6',
    },
  },
};

export const buttonBatchKeysProps: CustomButtonProps = {
  fontFamily: 'roboto',
  height: '45px',
  fontSize: 'xl',
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  color: 'beta.500',
  border: 'none',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.50)',
};
