import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  bg: 'rgba(0, 209, 255, 0.25)',
  color: 'white',
  boxShadow:
    '0px 0px 0px 1px rgba(0, 209, 255, 0.25) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bg: 'alpha.300',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 209, 255, 0.25) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
  },
};

export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'white',
  },
};

export const TextTitle: CSSObject = {
  color: 'white',
};
