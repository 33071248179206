import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'beta.800',
  borderTopRadius: 'md',
  w: '100%',
  mt: ['unset', '4'],
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  pb: '0',
  py: '4',
  '&&': { px: '4', pb: ['1', null, '2'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
};

export const WrapperContainer: CSSObject = {
  px: ['2', null, '0'],
  pb: '4',
  bg: 'beta.800',
  borderTopRadius: 'none',
  borderBottomRadius: 'md',
  color: 'delta.50',
  mb: '3',
  // Needs discussion with design
  // boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.25)',
};
