import React from 'react';
import { Layers } from '@styled-icons/evaicons-solid';
import {
  chakra,
  ChakraComponent,
  Flex,
  Text,
  Icon,
  IconProps,
  TextProps,
} from '@chakra-ui/react';
import IconSvg from '../../../../../../../../components/common/IconSvg';
import { getThemeName } from '@/helpers/getThemeConfig';
import { TBetReturn } from '../HeaderRow.types';
import { styleImports } from './HeaderRow.styles.imports';

const themeName = getThemeName();

export type HeaderRowSchema = Partial<{
  textOriginalOdds: TextProps;
  originalOdds: TextProps;
  mysteryBetIcon: IconProps;
  headerRowSvg: any;
}>;

export const headerRowStyles: HeaderRowSchema = {
  textOriginalOdds: {
    'data-testid': 'bottomRow-originalOdds',
    fontSize: 'xs',
    opacity: '0.5',
    textDecoration: 'line-through',
    alignSelf: 'center',

    ...(styleImports?.[themeName]?.headerRowStyles?.originalOdds ?? {}),
  },
  mysteryBetIcon: {
    boxSize: '4',
    fill: 'beta.500',

    ...(styleImports?.[themeName]?.headerRowStyles?.mysteryBetIcon ?? {}),
  },
  headerRowSvg: {
    h: 'auto',
    ml: '1',
    w: '8',
    fill: 'white',

    ...(styleImports?.[themeName]?.headerRowStyles?.headerRowSvg ?? {}),
  },
};

export const HeaderContainer = chakra(Flex, {
  label: 'headerRow-HeaderContainer',
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }),
});

export const dotSeperatorProps: TextProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.dotSeperatorProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const RunnerDetails = chakra(Text, {
  label: 'headerRow-RunnerDetails',
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'medium',
    textTransform: 'capitalize',
    color: 'gray.900',

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerDetails;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RunnerDescription = chakra(Text, {
  label: 'headerRow-RunnerDescription',
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'medium',
    color: 'gray.600',

    ...(() => {
      try {
        return styleImports[themeName]?.RunnerDescription;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetOdds = chakra(Text, {
  label: 'headerRow-BetOdds',
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'bold',
    marginLeft: 'auto',
    alignSelf: 'flex-start',

    ...(() => {
      try {
        return styleImports[themeName]?.BetOdds;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetMultiIcon = chakra(Icon, {
  label: 'headerRow-BetMultiIcon',
  baseStyle: () => ({
    bg: 'beta.500',
    boxSize: '8',
    p: '.5',
    borderRadius: 'full',
    color: 'white',

    ...(() => {
      try {
        return styleImports[themeName]?.BetMultiIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', IconProps>;

export const BetReturn = chakra<TBetReturn>(Flex, {
  label: 'headerRow-BetReturn',
  shouldForwardProp: (prop) => !['status'].includes(prop),
  baseStyle: ({ status }: any) => ({
    px: '1',
    py: '0.5',
    fontSize: '2xs',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginLeft: 'auto',
    alignSelf: 'flex-start',
    bg: () => {
      switch (status) {
        case 'won':
          return 'green.500';
        case 'lost':
          return 'gray.300';
        case 'voided':
          return 'gray.300';
        case 'cancelled':
          return 'gray.300';
        case 'pending':
          return 'yellow.400';
        default:
      }
    },
    color: status === 'pending' ? 'yellow.900' : 'white',
    borderRadius: 'base',
    ...(() => {
      try {
        return styleImports[themeName]?.BetReturn?.(status);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const HeaderSvg = chakra(IconSvg, {
  label: 'headerRow-HeaderSvg',
  baseStyle: () => ({
    boxSize: '10',

    ...(() => {
      try {
        return styleImports[themeName]?.HeaderSvg;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextBarrierNumber = chakra(Text, {
  label: 'headerRow-TextBarrierNumber',
  baseStyle: () => ({
    color: 'gray.600',
    fontSize: 'xs',
    ...(() => {
      try {
        return styleImports[themeName]?.TextBarrierNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const MultiIcon = () => {
  try {
    return (
      styleImports[themeName]?.MultiIcon?.() ?? <BetMultiIcon as={Layers} />
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <BetMultiIcon as={Layers} />;
};

export const TextDescription: ChakraComponent<'span', TextProps> = chakra(
  Text,
  {
    baseStyle: () => ({
      label: 'headerRow-TextDescription',
      ...(() => {
        try {
          return styleImports[themeName]?.TextDescription;
        } catch (err) {
          return {};
        }
      })(),
    }),
  }
);
