import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useQueryHomePage } from '../api/punter/content/homepage.hooks';
import { EPolicyType } from '../api/punter/content/homepage.types';

export const usePolicies = () => {
  const intl = useIntl();

  const { data } = useQueryHomePage();

  const policyLinks = Object.values(EPolicyType).reduce<Record<string, string>>(
    (acc, policyType) => {
      acc[policyType] =
        data?.find((pdf) => pdf.content_info?.related_val === policyType)
          ?.content_info?.uri ?? '';

      return acc;
    },
    {}
  );

  const openPDF = (url: string) => {
    if (!url) {
      toast.error(intl.formatMessage({ id: 'generic.nopolicyfound' }));

      return;
    }

    window.open(url, '_blank');
  };

  return { policyLinks, openPDF };
};
