import evenShotBg from '@/assets/wellbet/images/backgrounds/evenShotBg.png';
import evenShot from '@/assets/wellbet/images/evenShotMascot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  notAvailableText: {
    color: 'gamma.300',
    mt: 0,
  },
  oddsValueSpan: {
    color: 'white',
    sx: {
      '&[data-hasbets="true"]': {
        color: 'gamma.300',
      },
    },
  },
  hasBetSubTitleText: {
    fontWeight: 'normal',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notYetAvailableText: {
    color: 'gamma.300',
    fontWeight: 'bold',
    mt: 0,
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'gamma.300',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'extrabold',
    fontSize: 'lg',
  },
  oddsText: {
    mt: '0',
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    border: '1px',
    borderColor: 'gray.200',
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
    borderRadius: 'base',
    color: 'white',
    bgColor: 'alpha.800',
    bgImage: `url(${evenShotBg})`,
    bgSize: 'cover',
    mb: 2,
  },
  headingText: {
    fontFamily: 'luckiest-guy',
    color: 'gamma.300',
    textShadow: '0px 3px 5px rgba(0, 0, 0, 0.30)',
    fontSize: '47px',
  },
  addToBetslipButton: {
    variant: 'tertiary',
  },
  loginToView: {
    variant: 'tertiary',
  },
  evenShotImage: {
    mt: 4,
    boxSize: '135px',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
};
