import { Image } from '@chakra-ui/react';
import React from 'react';
import logo from '@/assets/betroyale/images/logo.svg';

export default function index() {
  return (
    <Image
      src={logo}
      w={['200px', null, 'full']}
      h={['unset']}
      mt={['10px', null, 'unset']}
    />
  );
}
