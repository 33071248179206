import { CSSObject } from '@chakra-ui/react';

export const TabWrapper: CSSObject = {
  bg: 'white',
  borderRadius: 'base',
  p: '1.5',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  bg: isActive ? 'gamma.700' : 'transparent',
  borderRadius: 'base',
  boxShadow: 'none',
  color: isActive ? 'white' : 'epsilon.500',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: isActive ? '0 2px 3px rgba(0, 0, 0, .2)' : 'none',

  _hover: {
    bg: isActive ? 'alpha.400' : 'transparent',
    boxShadow: 'none',
  },

  _active: {
    bg: isActive ? 'alpha.400' : 'transparent',
    boxShadow: 'none',
    color: isActive ? 'white' : 'delta.100',
  },
});
