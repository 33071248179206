import { cssVar } from '@chakra-ui/react';
import { BadgesSchema } from './Badges.styles';

const $arrowBg = cssVar('popper-arrow-bg');

export const badgeStyles: BadgesSchema = {
  flexIconWrapper: {
    bg: 'linear-gradient(180deg, var(--bc-colors-delta-500) 0%, var(--bc-colors-delta-600) 100%)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: '1',
    h: '31px',
    p: '1',
    maxW: '75px',
    _hover: {
      bg: 'linear-gradient(180deg, #0070D7 0%, #0046A7 100%)',
    },
    _active: {
      background: 'delta.800',
    },
  },
  iconBadge: {
    color: 'beta.500',
    textShadow: '0px 2px 0px var(--bc-colors-blackAlpha-400)',
    boxSize: '4',
  },
  iconTitle: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    lineHeight: '1',
    color: 'beta.500',
    textShadow: '0px 2px 0px var(--bc-colors-blackAlpha-400)',
    textAlign: 'left',
    _active: {
      color: 'white',
    },
  },
  textTitle: {
    textTransform: 'uppercase',
    fontWeight: 'extrabold',
    fontSize: 'sm',
    color: 'gray.700',
  },
  textDescription: {
    fontSize: 'xs',
    color: 'gray.700',
    fontWeight: 'normal',
  },
  tabs: {
    w: 'full',
    mt: '1',
  },
  tabPanels: {
    mt: '2',
  },
  tooltipWrapper: {
    borderRadius: 'md',
    p: '2',
    pr: '4',
    display: 'block',
    position: 'relative',
    bg: 'white',
    [$arrowBg.variable]: 'var(--bc-colors-alpha-500)',
  },
  tabPanelWrapper: {
    bg: 'white',
    p: '2',
    borderRadius: 'md',
    fontSize: 'xs',
    w: 'full',
  },
  tabBadge: {
    position: 'relative',
    p: '0',
    pr: '1',
    mr: '1',
    border: 'none',
    color: 'alpha.500',
    _selected: {
      border: 'none',
      '&::after': {
        content: "''",
        position: 'absolute',
        left: '0',
        right: '0',
        margin: 'auto',
        bottom: '-12px',
        width: '5',
        height: '5',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid white',
        transform: 'rotate(180deg)',
        clear: 'both',
        zIndex: ['unset', null, 'popover'],
      },
      '& div': {
        background: 'linear(to-b, delta.400, delta.600)',
      },
      '& path': {
        fill: 'beta.500',
      },
    },
  },
};
