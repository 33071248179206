import { Box, VisuallyHidden } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import promo from '@/assets/goldenrush/images/banner/promo.png';
import promoMobile from '@/assets/goldenrush/images/banner/promoMobile.png';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <Box
            key="banner-image"
            sx={{
              w: '100%',
              h: ['350px', '220px', '240px'],
              bgImage: [promoMobile, promo],
              bgSize: ['cover', null, '100%'],
              bgPos: 'center bottom',
              bgRepeat: 'no-repeat',
            }}
          >
            <VisuallyHidden>
              <FormattedMessage id="home.carousel.accessbility" />
            </VisuallyHidden>
          </Box>,
        ]}
      />
    </Box>
  );
}
