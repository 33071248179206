import { IconButton, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { CloseCircleOutline } from '@styled-icons/evaicons-outline/CloseCircleOutline';
import { useAppDispatch } from '../../../hooks/useRedux';
import { TSideNavTheme } from '../../../theme/base/customComponents/sideNav';
import Logo from '../conditionals/Logo/template';
import { setIsOpen } from '../../../redux/sideNav.slice';
import {
  DrawerHeaderContainer,
  IconCircleOutline,
} from './styles/DrawerHeader/DrawerHeader.styles';

export default function DrawerHeader() {
  const styles = useStyleConfig('SideNav') as TSideNavTheme;
  const dispatch = useAppDispatch();

  return (
    <DrawerHeaderContainer>
      <Logo />
      <IconButton
        aria-label="close navigation"
        icon={<IconCircleOutline as={CloseCircleOutline} />}
        onClick={() => dispatch(setIsOpen(false))}
        variant="unstyled"
        color={styles.readableColor}
        sx={{
          ...styles.closeButton,
          '&:hover, &:active': { bg: 'transparent' },
        }}
      />
    </DrawerHeaderContainer>
  );
}
