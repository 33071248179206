import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  borderRadius: 'md',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset',
  boxSize: '10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'alpha.800',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset',
  borderRadius: 'md',
  w: '100%',
  h: '64px',
  _hover: {
    bg: 'alpha.500',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'md',
  color: 'gamma.200',
  mt: '1.5',
  fontFamily: 'accentStaat',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'gamma.200',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'gamma.500',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'gamma.200',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'gamma.200',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'gamma.500',
  border: '2px',
  borderColor: 'gamma.500',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
