import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import accountBg from '@/assets/junglebet/images/backgrounds/accountBg.png';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    pb: '0',
    mx: ['2', null, 'unset'],
    boxShadow:
      '0px 5px 0px 0px #541E01, 0px -2px 1px 0px rgba(178, 78, 5, 0.50) inset',
    borderRadius: 'md',
  },
  boxHeading: {
    bgImage: accountBg,
    bgSize: '150% 430%',
    borderTopRadius: 'base',
    pt: '2',
    mb: '4',
    borderBottom: 'none',
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: ['2', null, '1'],
      },
    },
  },
  textTitle: {
    mt: '0',
    mb: '2',
    fontSize: 'lg',
    fontWeight: '800',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'CarterOne',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        p: '2',
        pt: '0',
        borderRadius: 'md',
        bgImage: accountBg,
        bgSize: 'contain',
        bgRepeat: 'round',
        boxShadow: 'unset',
        ...(IS_MOBILE_APP && {
          pt: '0',
        }),
        flexDir: 'column',
      },
    },
  },
  titleContainer: {
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
    borderRadius: 'lg',
    px: '2',
  },
};
