import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  borderTopLeftRadius: 'md',
  borderBottomLeftRadius: 'md',
  bg: 'beta.500',
  color: 'gamma.800',
  fontSize: 'sm',
  fontWeight: 'black',
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gamma.800',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'beta.500',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewEst: CSSObject = {
  fontSize: '22px',
  color: 'beta.500',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
};

export const InputBetSlip: CSSObject = {
  marginTop: '-2px',
  pl: '10',
  color: 'beta.500',
  borderRadius: 'md',
  bg: 'beta.600',
  borderWidth: '2px',
  borderLeftColor: 'alpha.500',
  borderRightColor: 'alpha.500',
  borderTopColor: 'alpha.400',
  borderBottomColor: 'alpha.700',
  fontSize: 'sm',
  ':first-child': {
    pl: '3',
    borderLeft: '2px',
  },
  _hover: {
    '&&': {
      bg: 'blackAlpha.200',
    },
    '.chakra-input__left-element': {
      bg: 'epsilon.300',
    },
  },
  _focus: {
    borderColor: 'beta.500',
    '&&': {
      background: 'epsilon.300',
    },
    '&::placeholder': { color: 'white', opacity: '0.8' },
  },
  '&::placeholder': { color: 'white', opacity: '0.5' },
};

export const TreasureChestIcon: CSSObject = {
  color: 'beta.500',
};
