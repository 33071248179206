import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  alignItems: 'center',
  borderBottom: 'none',
  h: '16',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
  color: 'white',
};
