import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema, TLinkRaceItem } from './RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'alpha.600', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'alpha.600'] },
};

export const FlexHeading: CSSObject = {
  bgGradient: 'linear-gradient(180deg, #0A486B 0%, #0C2448 100%)',
  color: 'white',
  position: 'inherit',
  top: ['12', '84px'],
  borderTopStartRadius: 'base',
  borderTopEndRadius: 'base',
  '&&': {
    svg: {
      borderRadius: 'md',
      bgGradient: 'linear(180deg, beta.400, beta.600)',
    },
  },
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'gamma.600',
  bg: 'white',
  borderRadius: 'full',
  p: '1.5',
};

export const BoxPageWrapper: CSSObject = {
  mt: '0',
  mb: '2',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'beta.500',
  borderRadius: 'md',
  bg: 'white',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.600',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const LinkRaceItem = ({
  isActive,
  isToteMultiAvailable,
}: {
  isActive: TLinkRaceItem;
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? [null, null, 'alpha.50'] : [null, null, 'white'],
  color: isActive ? [null, null, 'black'] : [null, null, 'gray.700'],
  borderColor: 'gray.300',

  _hover: {
    bg: 'gray.100',
  },
});

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomRadius: 'md',
};

export const CenterTH = () => ({
  bg: 'alpha.600',
  color: 'white',
});

export const ButtonChevron: CSSObject = {
  color: 'alpha.700',
  bg: 'beta.500',
  borderRadius: 'base',
  width: '10',
  '&&': {
    svg: {
      bg: 'unset',
      color: 'blackAlpha.700',
    },
  },
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BoxVenueName: CSSObject = {
  borderRight: ['unset', null, '1px'],
  borderColor: ['unset', null, 'gray.300'],
  borderTop: ['unset', null, '1px'],
  borderTopColor: ['unset', null, 'gray.300'],
  pr: '1',
};
