import React, { useMemo, useState } from 'react';
import {
  Box,
  Collapse,
  Flex,
  FormControl,
  HStack,
  Switch,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FlexRunnerWrapper,
  RacerName,
  RunnerImage,
  TextTableBarrierNumber,
} from '../components/Table/styles/Table.styles';
import RaceRunnerForm from '../../components/RaceRunnerForm/RaceRunnerForm';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  ERaceType,
  TFormData,
} from '@/lib/DBModels';
import { srcDefaultSilk } from '@/assets/core';
import SRMSelections from './components/Selections/Selections';
import { useSelections } from './components/Selections/services/Selections.hooks';
import { buttonData } from './services/SRMulti.config';
import {
  useQueryRaceMarkets,
  useQueryRunnerList,
} from '@/api/racing/racing.hooks';
import { srMultiStyles } from './styles/SRMulti.styles';
import { TRunnerList } from '@/api/racing/racing.types';
import { isScratchedRunner } from './utils';
import {
  RaceRunnerHeadingContainer,
  RaceRunnerListWrapper,
} from '../../RaceDetails/styles/RaceDetails.styles';
import {
  FlexDeductionsContainer,
  RunnerListItemWrapper,
  RunnerScratchedWrapper,
  TextDeductions,
  TextScratchedInfo,
  FlexFlucsPopupContainer,
} from '../../components/RaceRunnerListItem/styles/RaceRunnerListItem.styles';
import Flucs from '../../components/Flucs/Flucs';
import Badges from '../../components/Badges/Badges';
import { useAppSelector } from '@/hooks/useRedux';
import { centsToDollars } from '@/helpers/utils';
import RaceRunnerPropButton from '../../components/RaceRunnerPropButton/RaceRunnerPropButton';

const SRM_COLUMN_HEADINGS = ['Win', 'Top 2', 'Top 3', 'Top 4'];

export default function SRMulti() {
  const { racingType } = useParams();
  const raceType = (racingType as ERaceType) || ERaceType.Horse;
  const [searchParams] = useSearchParams();
  const { raceId, venueId } = {
    raceId: searchParams.get('raceId'),
    venueId: searchParams.get('venueId'),
  };
  const [showFlucs, setShowFlucs] = useState(true);
  const [showBadges, setShowBadges] = useState(true);
  const { RacingWin } = EBetTypesDisplayNames;
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const {
    handleToggleSelection,
    clearSelections,
    displaySelections,
    selections,
    price,
    addButtonState,
    displayErrorBanner,
    setDisplayErrorBanner,
  } = useSelections();

  const { data: runners } = useQueryRunnerList({
    key: [raceId ?? ''],
    params: { race_id: raceId ?? '' },
    options: {
      enabled: !!raceId,
    },
  });

  const sortedRunners = useMemo(() => {
    const res = runners
      ?.sort((a, b) => ((a.number as number) > (b.number as number) ? 1 : -1))
      .reduce(
        (acc, cur) => {
          if (!isScratchedRunner(cur.status as EGeneralStatus)) {
            acc.valid.push(cur);
          } else acc.scratched.push(cur);

          return acc;
        },
        { valid: [], scratched: [] } as {
          valid: TRunnerList[];
          scratched: TRunnerList[];
        }
      );
    return [...(res?.valid ?? []), ...(res?.scratched ?? [])];
  }, [runners]);

  const { data: markets } = useQueryRaceMarkets({
    params: { race_id: raceId ?? '' },
  });

  const winMarket = markets?.find(
    (market) => market.market_name?.toLowerCase() === 'racing win'
  );

  const mergedData = sortedRunners?.map((runner) => {
    const matchingRunner = winMarket?.propositions?.find(
      (prop) => prop.proposition_id === runner.win_proposition?.proposition_id
    );

    return { ...runner, ...matchingRunner };
  });

  const getRunnerByNumber = (runnerNumber: number) =>
    mergedData?.find((runner) => runner?.number === runnerNumber);

  const { raceRunnerList } = useAppSelector((state) => state.racing);

  const isOpen = raceRunnerList.raceMeta.status === EGeneralStatus.Open;
  const winOdds: number[] = raceRunnerList.raceRunners
    .filter((status) => status.status !== EGeneralStatus.Scratched)
    .map((runner) => runner.win_odds ?? 0);
  const favouritePrice = Math.min(...winOdds);

  const badgeSection = (flucsData: TFormData | undefined) =>
    showFlucs ? (
      <Collapse in={showBadges} animateOpacity>
        <Box className="badgeCollapse">
          <Badges form_data={flucsData} />
        </Box>
      </Collapse>
    ) : (
      <Collapse in={showBadges} animateOpacity>
        <Badges form_data={flucsData} />
      </Collapse>
    );

  return (
    <RaceRunnerListWrapper>
      <Box {...srMultiStyles.contentContainer}>
        <Box {...srMultiStyles.runnerList}>
          <HStack justifyContent="space-between" pr="2" pb="3">
            <Text {...srMultiStyles.headingLabel}>
              <FormattedMessage id="racing.generic.numberRunner" />
            </Text>
            <HStack>
              <Flex
                {...srMultiStyles.headingStacker}
                flexDir={['column', null, 'row']}
              >
                <HStack>
                  <RaceRunnerHeadingContainer>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      ml="3"
                    >
                      <Text
                        {...srMultiStyles.headingContainer}
                        htmlFor="flucs"
                        mr="1"
                        textTransform="capitalize"
                      >
                        <FormattedMessage id="racing.generic.flucs" />
                      </Text>
                      <Switch
                        id="flucs"
                        size="sm"
                        onChange={() => setShowFlucs((prevState) => !prevState)}
                        defaultChecked={showFlucs}
                      />
                    </FormControl>
                  </RaceRunnerHeadingContainer>
                  <RaceRunnerHeadingContainer>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      ml="3"
                    >
                      <Text
                        {...srMultiStyles.headingContainer}
                        htmlFor="badges"
                        mr="1"
                        textTransform="capitalize"
                      >
                        <FormattedMessage id="racing.generic.insights" />
                      </Text>
                      <Switch
                        id="badges"
                        size="sm"
                        onChange={() =>
                          setShowBadges((prevState) => !prevState)
                        }
                        defaultChecked={showFlucs}
                      />
                    </FormControl>
                  </RaceRunnerHeadingContainer>
                </HStack>
                {!isMobile && (
                  <HStack data-cy="columnHeadingWrapper" mt="0 !important">
                    {SRM_COLUMN_HEADINGS.map((title) => (
                      <Text key={title} {...srMultiStyles.columnHeadings}>
                        {title}
                      </Text>
                    ))}
                  </HStack>
                )}
              </Flex>
            </HStack>
          </HStack>
          {mergedData?.map((runner) => {
            const { scratch_time: scratchTime, ...r } = runner;
            const isScratched = isScratchedRunner(r.status as EGeneralStatus);
            return (
              <RunnerListItemWrapper key={r.race_runner_id}>
                <Flex alignItems="flex-start" w="full">
                  <RunnerImage
                    data-cy="raceRunnerSilk"
                    src={r.silk_url}
                    fallbackSrc={srcDefaultSilk}
                    isGreyhound={raceType === ERaceType.Greyhound}
                  />
                  <FlexRunnerWrapper>
                    <Flex
                      direction={!isMobile ? 'row' : 'column'}
                      justifyContent="space-between"
                      w="full"
                      align="flex-start"
                    >
                      <VStack alignItems="flex-start">
                        <RacerName
                          data-cy="runnerName"
                          as="div"
                          textDecoration={isScratched ? 'line-through' : ''}
                        >
                          {r.number}.{' '}
                          <div>
                            {String(r.display_name).toLocaleLowerCase()}
                          </div>{' '}
                          <TextTableBarrierNumber as="span">
                            ({r.barrier_number})
                          </TextTableBarrierNumber>
                        </RacerName>
                        {!isScratched && <RaceRunnerForm runner={r} />}
                      </VStack>
                      {!isScratched && (
                        <VStack ml={isMobile ? '-10' : 0} my={isMobile ? 2 : 0}>
                          {!!isMobile && (
                            <HStack
                              data-cy="columnHeadingWrapper"
                              mt="0 !important"
                            >
                              {SRM_COLUMN_HEADINGS.map((title) => (
                                <Text
                                  key={title}
                                  {...srMultiStyles.columnHeadings}
                                >
                                  {title}
                                </Text>
                              ))}
                            </HStack>
                          )}
                          <Flex gap="2" {...srMultiStyles.oddsButtonsWrapper}>
                            {buttonData.map((data, i) => {
                              const isSelected =
                                !!r.number &&
                                !!selections[i].find(
                                  (item) => item.runner_number === r.number
                                );
                              const propPrice = r.srm_price?.[data.priceKey];
                              const displayPrice = propPrice
                                ? propPrice.toFixed(2)
                                : 'N/A';

                              return (
                                <Flex
                                  key={data.index}
                                  {...srMultiStyles.buttonWrapper}
                                >
                                  <RaceRunnerPropButton
                                    disabled={displayPrice === 'N/A'}
                                    betType={RacingWin}
                                    onClick={() =>
                                      addButtonState === 'loading'
                                        ? undefined
                                        : handleToggleSelection(data.index, {
                                            runner_number: r.number,
                                            runner_name: r.display_name,
                                            barrier_number: r.barrier_number,
                                          })
                                    }
                                    isSelected={isSelected}
                                    runner={r}
                                    displayPrice={displayPrice}
                                    isFavourite={
                                      displayPrice ===
                                        favouritePrice.toFixed(2) && i === 0
                                    }
                                  >
                                    {displayPrice}
                                  </RaceRunnerPropButton>
                                </Flex>
                              );
                            })}
                          </Flex>
                        </VStack>
                      )}
                    </Flex>
                    {isScratched && (
                      <TextScratchedInfo data-cy="scratchedInfo">
                        <FormattedMessage
                          id="racing.raceRunnerItem.scratchedInfo"
                          values={{
                            scratchTime: scratchTime
                              ? new Date(scratchTime)
                              : null,
                            time: (chunks) => (
                              <time
                                data-cy="scratchedInfoTime"
                                dateTime={scratchTime || undefined}
                              >
                                {chunks}
                              </time>
                            ),
                            hasScratchTime: !!scratchTime,
                          }}
                        />
                      </TextScratchedInfo>
                    )}
                  </FlexRunnerWrapper>
                  {!(!isScratched && winMarket) && (
                    <FlexDeductionsContainer>
                      {(runner.win_deductions || runner.place_deductions) && (
                        <>
                          <RunnerScratchedWrapper>
                            {runner.win_deductions && (
                              <Text data-cy="winDeductions">
                                {centsToDollars(runner.win_deductions)}
                              </Text>
                            )}
                            {runner.place_deductions ? (
                              <Text data-cy="placeDeductions">
                                {centsToDollars(runner.place_deductions)}
                              </Text>
                            ) : (
                              <Text>-</Text>
                            )}
                          </RunnerScratchedWrapper>
                          <TextDeductions data-cy="deductionsApplied">
                            <FormattedMessage id="racing.raceRunnerItem.deductions" />
                          </TextDeductions>
                        </>
                      )}
                    </FlexDeductionsContainer>
                  )}
                </Flex>
                {!isScratched && (
                  <FlexFlucsPopupContainer>
                    <Collapse in={showFlucs} animateOpacity>
                      <Flucs flucs={r.flucs_data ?? {}} isRaceOpen={isOpen} />
                    </Collapse>
                    {badgeSection(r.form_data)}
                  </FlexFlucsPopupContainer>
                )}
              </RunnerListItemWrapper>
            );
          })}

          {displaySelections && (
            <SRMSelections
              clearSelections={clearSelections}
              selections={selections}
              addButtonState={addButtonState}
              price={price}
              getRunnerByNumber={getRunnerByNumber}
              winMarket={winMarket}
              venueId={venueId}
              displayErrorBanner={displayErrorBanner}
              setDisplayErrorBanner={setDisplayErrorBanner}
            />
          )}
        </Box>
      </Box>
    </RaceRunnerListWrapper>
  );
}
