export const colors = {
  alpha: {
    50: '#D3ECFF',
    100: '#B3DEFF',
    200: '#8ECEFF',
    300: '#229FFF',
    400: '#0680DE',
    500: '#0067B2',
    600: '#075F9F',
    700: '#00528D',
    800: '#004072',
    900: '#012B4C',
  },
  beta: {
    50: '#FFF3D2',
    100: '#FFE69B',
    200: '#FFDD76',
    300: '#FFD351',
    400: '#FFC61E',
    500: '#EDB718',
    600: '#D8A714',
    700: '#B1880B',
    800: '#906E06',
    900: '#5E4701',
  },
};
