/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Hide, Image } from '@chakra-ui/react';
import { LinkLogo } from '../../Headers/Basic/styles/Basic.styles';
import Logo from '../../Headers/Basic/conditionals/Logo/template';
import { TTemplate } from '@/helpers/createTemplate';
import {
  FlexAsideInfo,
  GridAsideWrapper,
  FlexTitleImageWrapper,
} from '../styles/index';
import Subtitle from '../components/Subtitle';
import CTA from '../CTA';
import title from '../../../assets/betprofessor/images/mascots/title.png';
import { TPageSpecificContent } from '@/views/onboarding/services/onboarding.types';

const template = (pageSpecificContent: TPageSpecificContent): TTemplate => ({
  wrapper: <GridAsideWrapper />,
  asideInfo: {
    wrapper: <FlexAsideInfo />,
    logo: {
      wrapper: <LinkLogo to="/" />,
      logo: <Logo />,
    },
    title: (
      <FlexTitleImageWrapper>
        <Image
          w="210px"
          m="auto"
          mr={['20px', 'auto']}
          mt={['20px', 'auto']}
          src={title}
        />
      </FlexTitleImageWrapper>
    ),
    subtitle: (
      <>
        <Hide below="sm">
          <Subtitle id="onboarding.login.subtitle" />
        </Hide>
      </>
    ),
    cta: (
      <CTA
        messageId={pageSpecificContent.footerInfoId}
        href={pageSpecificContent.ctaHref}
        dataCy={pageSpecificContent.dataCy}
      />
    ),
  },
});

export default template;
