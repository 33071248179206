import { BoxProps, IconProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Banner.styles.imports';

const themeName = getThemeName();

export type BannerSchema = Partial<{
  bannerBoxProps: BoxProps;
  depositIconProps: IconProps;
}>;

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    'data-testid': 'banner-bannerBoxProps',

    display: 'flex',
    bg: 'yellow.100',
    border: '2px solid black',
    borderRadius: 'md',
    p: '4',
    mb: '6',
    gap: '2',
    alignItems: 'center',

    ...styleImports?.[themeName]?.bannerStyles?.bannerBoxProps,
  },
  depositIconProps: {
    'data-testid': 'banner-depositIconProps',
    color: 'black',
    boxSize: '9',

    ...styleImports[themeName]?.bannerStyles?.depositIconProps,
  },
};
