const branding = {
  brand: {
    50: '#DFF9FB',
    100: '#CDF6F8',
    200: '#BBF2F6',
    300: '#A9EFF3',
    400: '#62E2E9',
    500: '#3EDCE4',
    600: '#2AA1A7',
    700: '#1A7479',
    800: '#10575A',
    900: '#0B484B',
  },
  accent: {
    50: '#CFD3F5',
    100: '#9499C5',
    200: '#8086B6',
    300: '#6D74A8',
    400: '#596199',
    500: '#464E8B',
    600: '#3B4175',
    700: '#242848',
    800: '#1F223D',
    900: '#141526',
  },
  gamma: {
    50: '#5FA5E6',
    100: '#5697D3',
    200: '#4D89C0',
    300: '#447AAD',
    400: '#3B6C9A',
    500: '#061727',
    600: '#05121F',
    700: '#040E17',
    800: '#020910',
    900: '#02070C',
  },
  delta: {
    50: '#FDD5D5',
    100: '#FBBEBE',
    200: '#FAA6A6',
    300: '#F45F5F',
    400: '#F12F2F',
    500: '#ED0000',
    600: '#BE0000',
    700: '#8E0000',
    800: '#5F0000',
    900: '#470000',
  },
  theta: {
    50: '#F3D247',
    100: '#F0B344',
  },
};

export const colors = {
  brand: branding.brand,
  accent: branding.accent,
  alpha: branding.brand,
  beta: branding.accent,
  gamma: branding.gamma,
  delta: branding.delta,
  theta: branding.theta,
  epsilon: {},
};
