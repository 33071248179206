import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgSize: 'cover',
  bgRepeat: 'repeat',
  h: '100%',
  maxH: '100dvh',
  borderRadius: 'lg',
  bg: 'alpha.400',
  boxShadow:
    '0px 2px 14px 0px rgba(0, 0, 0, 0.45), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
  marginTop: '1',
};
export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '8px',
  mx: '3px',
  borderTop: ['unset', null, '1px solid'],
  borderTopColor: ['unset', null, 'alpha.100'],
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
export const BetSlipContainer: BoxProps = {
  borderRadius: 'base',
};
export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};
export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
};
export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'gamma.400',
  fontFamily: 'accent',
  fontSize: 'sm',
});
export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};
export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};
export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '6',
  width: 'auto',
  margin: 'auto',
  mt: '-4',
  pl: 1,
  pr: 2,
  display: 'flex',
  color: 'alpha.500',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  fontSize: 'xs',
  fontWeight: 'bold',
  borderRadius: 'md',
  fontFamily: 'accent',
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  textTransform: 'uppercase',
  _hover: {
    bgGradient: 'linear(to-b, beta.600, beta.600)',
  },
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3.5',
    },
  },
};
export const BetslipVStack: CSSObject = {
  gap: '2.5',
};
export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};
export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  textTransform: 'uppercase',
  fontSize: 'xs',
  height: '42px',
  sx: {
    '&&': {
      fontWeight: 'bold',
    },
  },
};
