import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  border: '0',
};

export const TabWrapper: CSSObject = {
  bgGradient: 'linear(to-b,gamma.400, gamma.500)',
  borderRadius: 'base',
  p: '1.5',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  bg: isActive ? 'alpha.500' : 'transparent',
  borderRadius: 'base',
  boxShadow: 'none',
  color: isActive ? 'white' : 'gray.600',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: isActive ? '0 2px 3px rgba(0, 0, 0, .2)' : 'none',

  _hover: {
    boxShadow: 'none',
    bg: isActive ? 'alpha.500' : 'transparent',
    color: isActive ? 'white' : 'gray.700',
  },

  _active: {
    bg: isActive ? 'alpha.500' : 'transparent',
    boxShadow: 'none',
    color: isActive ? 'white' : 'gray.600',
  },
});
