import { CSSObject, TextProps } from '@chakra-ui/react';

export const betSlipEmptyHeadingSuffix: TextProps = {
  as: 'span',
  fontFamily: 'accent',
  fontWeight: 'semibold',
  fontSize: 'md',
  color: 'beta.500',
  textTransform: 'capitalize',
  display: 'block',
};

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  pt: '16',
  px: '10',
  textAlign: 'center',
  mx: 'auto',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'accent',
  fontWeight: 'extrabold',
  fontSize: '21px',
  mt: '2',
  mb: '1',
  color: 'beta.500',
  textTransform: 'uppercase',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  my: '2',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};
