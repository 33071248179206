import { BoxProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Divider.styles.imports';

const themeName = getThemeName();

export type DividerSchema = Partial<{
  boxWrapper: BoxProps;
}>;

export const dividerStyles: DividerSchema = {
  boxWrapper: {
    'data-testid': 'divider-boxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.dividerStyles?.boxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
