import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapper: BoxProps = {};

export const StartTime: CSSObject = {
  h: '5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3px 4px',
  borderRadius: '3px',
  background: 'rgba(0, 0, 0, 0.36)',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '9px',
  fontStyle: 'normal',
  fontWeight: '800',
  lineHeight: '120%',
};
