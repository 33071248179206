import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import fruit from '@/assets/juicybet/images/header/fruit.png';

export const buttonDepositProps: CustomButtonProps = {
  w: 'full',
  mt: '2',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  sx: {
    '.chakra-button__icon': { mt: '-1' },
  },
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'xs',
};

export const PopoverContentQA: CSSObject = {
  bg: 'white',
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'beta.400',
  color: 'white',
  border: 'none',
  mt: '1',
  mx: '1',
  borderTopRadius: 'base',
};

export const BoxBallance: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  px: '2',
  pb: '2',
  pt: '0',
  mx: '1',
  borderBottomRadius: 'base',
  '> div:first-of-type': {
    color: 'beta.700',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
        fontWeight: 'black',
      },
    },
  },
  '> div:last-of-type': {
    color: 'white',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
        fontWeight: 'black',
      },
    },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  bg: 'white',
  color: 'alpha.800',
  borderTop: '1px',
  borderColor: 'gray.200',
  ':first-child': {
    borderTop: 'none',
  },
  '&& > p': { transition: 'all 0.2s ease-in-out' },
  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.100',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${fruit})`,
  bgSize: '100%',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '-1',
  display: ['none', null, null, null, 'block'],
  w: '64',
  h: '36',
  pos: 'absolute',
  bottom: '-20',
  right: '4',
};
