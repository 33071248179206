import { Skeleton } from '@chakra-ui/react';
import React from 'react';

export default function Loading() {
  return (
    <>
      {[...new Array(5)].map((_, i) => (
        <Skeleton key={i} h={i * 5} mt="1" speed={0.6} />
      ))}
    </>
  );
}
