import { BoxProps, ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonOutsideArea = {
  display: 'none',
};

export const FlexContainer = (): CSSObject => ({
  px: ['2.5', null, null, '0'],
});

export const BoxWrapper: BoxProps = {
  pr: '0',
};

export const buttonLogInProps: ButtonProps = {
  variant: 'solid',
  height: '46px',
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  height: '46px',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'quickAccess',
  height: '46px',
  p: '2.5',
  color: 'alpha.900',
  sx: {
    ':hover': {
      '.span-balance': {
        color: 'alpha.900',
      },
      '.span-bonus': {
        color: 'alpha.900',
      },
      '.chevron-icon': {
        color: 'alpha.900',
      },
    },
    '.btn-span': {
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      mr: '1',
      gap: '0',
      mt: '0.5',
    },
    '.span-balance': {
      fontSize: 'md',
      lineHeight: '14px',
      color: 'alpha.900',
    },
    '.span-bonus': {
      fontFamily: 'body',
      fontSize: '2xs',
      lineHeight: '10px',
      fontWeight: 'extrabold',
      color: 'alpha.900',
      alignSelf: 'start',
    },

    'svg:not(.chevron-icon)': {
      pos: 'relative',
      top: '0.5',
      mr: '0.5',
    },
    '.chevron-icon': {
      pos: 'absolute',
      right: '1.5',
      top: '0',
      bottom: '0',
      margin: 'auto 0',
      color: 'alpha.900',
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  variant: 'secondary',
  height: '46px',
  padding: '0px 5px',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '1.5',
    },
    '.span-bet': {
      fontWeight: 'bold',
      fontSize: 'xs',
    },
    '.span-bet-count': {
      fontWeight: 'bold',
      fontSize: 'lg',
      color: 'alpha.900',
    },
  },
};

export const buttonBetSlipProps: ButtonProps = {
  variant: 'bets',
  color: 'alpha.900',
  height: '46px',
  padding: '0px 5px',

  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '1.5',
    },
    ':hover': {
      '.chakra-text': {
        color: 'alpha.900',
      },
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'alpha.100',
  boxSize: '8',
  ml: '-1',

  '& svg': {
    boxSize: '8',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '1.5',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const buttonQuickAccess: ButtonProps = {
  variant: 'secondary',
};

export const ButtonGroupBets: CSSObject = {
  gap: '1',

  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },

  '>*:first-of-type:not(:last-of-type)': {
    borderRightRadius: 'md',
  },
  '>*:not(:first-of-type):last-of-type': {
    borderLeftRadius: 'md',
  },
};

export const ButtonBetSlip: CSSObject = {
  ml: '0',
};

export const ButtonGroup: CSSObject = {
  gap: '1',
};

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
};

export const TextButton: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'xs',
  fontFamily: 'Staatliches',
  color: 'alpha.900',
};

export const TextButtonNumber: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'lg',
  color: 'alpha.900',
};
