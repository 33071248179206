import {
  EBetSubmissionConfirmationStatus,
  TBetSlipBet,
} from '../../Services/Betslip.types';

export default function isBetRecoveryEligible(bet: TBetSlipBet | undefined) {
  const betIsBetRecoveryEligible =
    bet?.confirmation?.is_betslip_rejection !== true &&
    bet?.confirmation?.promo_token_type === 'recovery' &&
    bet?.confirmation.status === EBetSubmissionConfirmationStatus.Placed;
  return betIsBetRecoveryEligible;
}
