import { SRMultiStylesSchema } from './SRMulti.styles';

export const srMultiStyles: SRMultiStylesSchema = {
  buttonLabel: {
    color: 'white',
  },
  propButton: {
    w: '50px',
  },
  columnHeadings: {
    w: '48px',
    textAlign: 'center',
    fontSize: '2xs',
    color: 'white',
    fontWeight: 'bold',
    mt: '-7px',
  },
  headingLabel: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
    mt: '3px',
  },
  headingContainer: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'medium',
    marginRight: '1',
  },
  runnerList: {
    bg: 'transparent',
    p: '0',
  },
  headingStacker: {
    rowGap: '2',
  },
};
