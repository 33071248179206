import React from 'react';
import BtnOfferings from '../components/BtnOfferings/BtnOfferings';
import ThemeHome from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeHome, [
  {
    name: 'btnNav',
    component: <BtnOfferings />,
    config: { after: 'banner' },
  },
]);
