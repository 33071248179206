import { CSSObject } from '@chakra-ui/react';
import mascot from '../../../../../../assets/betprofessor/images/header/mascot.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const PopoverContentQA: CSSObject = {
  borderRadius: 'base',
  border: 'white',
  bg: 'white',
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'beta.900',
  borderColor: 'transparent',
  color: 'white',
  borderTopRadius: 'base',
  px: '2',
  pb: '0',
  mx: '2px',
  mb: '0',
  mt: '2px',
};

export const BoxBallance: CSSObject = {
  bg: 'beta.900',
  color: 'gamma.500',
  fontWeight: 'normal',
  borderBottomRadius: 'base',
  mx: '2px',
  mt: '0',
};

export const FlexBallanceItem: CSSObject = {
  p: {
    fontSize: 'sm',
    fontWeight: 'medium',
  },
};

export const PopoverBodyQA: CSSObject = {
  bg: 'white',
};

export const buttonDepositProps: CustomButtonProps = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'alpha.800',
  fontFamily: 'Fredoka',
  height: '37px',
  lineHeight: 'none',
  minW: '44px',
  pos: 'relative',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'bold',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  w: 'full',
  mt: '1',

  sx: {
    '.chakra-button__icon': { display: 'none' },

    '.btn-span': {
      textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
    },

    '&:hover, &:focus, &:active': {
      bgGradient: 'linear(to-b, alpha.100, alpha.100)',
      textShadow: 'none',
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
      color: 'alpha.800',
    },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  color: 'epsilon.700',
  borderColor: 'gray.200',
  borderTop: '1px solid',

  p: {
    transition: 'all 0.2s ease-in-out',
    fontWeight: 'medium',
  },

  svg: {
    boxSize: '4',
    mr: '0.5',
  },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'epsilon.100',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImg: `url(${mascot})`,
  bgRepeat: 'no-repeat',
  bgSize: '73%',
  bottom: '-2',
  display: ['none', null, null, null, 'block'],
  h: '65px',
  right: '135px',
  w: '120px',
  position: 'absolute',

  'body[data-sensitive="true"] &': {
    display: 'none',
  },
};

export const TextValue: CSSObject = {
  '&&': {
    fontWeight: 'black',
  },
};
