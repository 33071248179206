import { Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

type TLoader = {
  amount?: number;
};

export default function Loader({ amount }: TLoader = { amount: 4 }) {
  return (
    <Stack spacing="1px" sx={{ w: 'full' }}>
      {[...new Array(amount)].map((_, i) => (
        <Skeleton
          key={`skel-markets${i}`}
          sx={{ minH: '53px', flex: 1, w: 'full' }}
        />
      ))}
    </Stack>
  );
}
