import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'xs',
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '2',
    pt: '2',
    _last: {
      borderBottom: 'none',
    },
  },
};
