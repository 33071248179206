import { CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'alpha.600',
  p: '0',
};

export const DepositButton: CSSObject = {
  fontSize: 'xs',
  bgGradient: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  color: 'alpha.800',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
  borderWidth: '2px',
  borderColor: '#A1741F',
  textTransform: 'uppercase',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  '&:hover, &:focus': {
    bgGradient: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
    color: 'alpha.600',
  },
  _disabled: {
    bg: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  },
  '&:disabled:hover': {
    bg: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  },
  _active: {
    bg: 'beta.700',
    color: 'alpha.600',
    boxShadow:
      '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const CancelButton: CSSObject = {
  border: '2px solid',
  borderColor: 'alpha.600',
  fontSize: 'sm',
  fontFamily: 'Skranji',
  color: 'alpha.800',
  fontWeight: '700',
  bg: 'red',
  '&:hover': {
    bg: 'beta.700',
    color: 'blackAlpha.600',
    borderColor: 'transparent',
  },

  _active: {
    bg: 'beta.700',
  },
};

export const ButtonSubmit: CSSObject = {
  fontSize: 'sm',
  fontFamily: 'Caveman',
  fontWeight: 'normal',
  border: '2px solid',
  borderColor: 'beta.400',
  bg: 'beta.600',
  textTransform: 'uppercase',
  color: 'blackAlpha.600',
  textShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.35)',
  boxShadow:
    '0px 4px 5px 0px rgba(0, 0, 0, 0.25), 0px 4px 5px 0px rgba(0, 0, 0, 0.15) inset',

  _hover: {
    bg: 'beta.700',
    color: 'blackAlpha.600',
    borderColor: 'transparent',
  },
  _active: {
    bg: 'beta.700',
  },
};

export const DepositLimitContainer: CSSObject = {
  bg: 'zeta.200',
  borderRadius: 'base',
  p: '4',
  color: 'gray.600',
  fontWeight: '700',
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '& .chakra-form__label': {
    color: 'white',
  },
  '& .chakra-input__left-addon': {
    bg: 'beta.500',
    fontSize: 'xs',
    h: '9',
    borderLeftRadius: 'base',
    color: 'alpha.800',
  },
  '& .chakra-select': {
    bg: 'zeta.100',
    borderRadius: 'base',
    fontSize: 'xs',
    h: '9',
    color: 'gray.600',
  },
  '& .chakra-input': {
    borderRightRadius: 'base',
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'zeta.100',
    h: '9',
    _hover: {
      bg: 'zeta.200',
    },
    _focus: {
      bg: 'zeta.200',
    },
    _placeholder: {
      color: 'gray.700',
    },
  },
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
  '.chakra-button': {
    bg: 'transparent',
    padding: '12px',
    borderWidth: '2px',
    borderColor: 'alpha.700',
    width: '50%',
    fontFamily: 'SkranjiBold',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  '.chakra-button[data-cy="confirmDepositConfirmation"]': {
    bg: 'linear-gradient(180deg, #FACD33 0%, #EC9E0F 100%)',
  },
  '.chakra-button[data-cy="confirmDepositConfirmation"]:hover': {
    bg: 'beta.600',
  },
};
