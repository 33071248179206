import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  px: '2',
  border: 'none',
  bgGradient: 'linear(242deg, #3f4953, #4b5a6d)', // @TODO colour not in palette
  borderRadius: 'base',
  mb: '2',
  flex: '1',
  boxShadow:
    '0 4px 4px 0 #00000040, inset 0 1px 0 0 #ffffff26, -1px 0 18px 0 #00000026',
};

export const TypeItem: CSSObject = {
  color: 'gray.300',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: ['base', null, 'md'],
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'gamma.200',
  _last: {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: '#1a202c',
};
