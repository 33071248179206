import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: ['lg', 'xl'],
  color: 'alpha.600',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  mb: ['4px', null, '0px'],
  px: '2',
};
