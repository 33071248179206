import React from 'react';
import { getStrings } from '@/helpers/utils';
import Information from './Components/Information';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';

const LifetimeExclusionInformation: React.FC = () => {
  const [
    {
      Account: {
        LifetimeExclusion: { pageTitle },
      },
    },
  ] = getStrings();

  return (
    <AccountWrapper pageTitle={pageTitle} pageHeader={pageTitle}>
      <Information />
    </AccountWrapper>
  );
};

export default LifetimeExclusionInformation;
