import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';
import activeBg from '@/assets/goldenrush/images/backgrounds/image-shine-1.png';

const primary: CSSObject = {
  bgGradient: 'linear(180deg, #30BA00 0%, #00CAF6 0.01%, #006E86 100%)',
  boxShadow:
    '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
  fontWeight: 'black',
  textTransform: 'uppercase',
  fontSize: 'sm',
  borderRadius: '7px',

  _disabled: {
    bg: 'gray.400',
    borderWidth: '2px',
    borderColor: 'gray.300',
    color: 'blackAlpha.500',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset',
    border: 'none',
    opacity: '1',

    _hover: { bg: 'gray.400' },
  },

  '&[data-active="true"]': {
    bg: 'beta.800',
    boxShadow:
      '0px 0px 0px 2px #E57D24 inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.50) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
    color: 'gamma.500',
    textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
    border: 'none',

    '&&': {
      bgImg: `url(${activeBg}), url(${activeBg}), url(${activeBg})`,
      bgRepeat: 'no-repeat',
      bgPosition: '0% 12%,18% 11%,90% 91%',
      bgSize: '29%, 16%, 24%',
    },

    _after: {
      opacity: 0,
    },
  },

  _hover: {
    bg: 'alpha.500',
  },
  _focus: {
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
    color: 'white',
    textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
    border: 'none',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md', h: '34px', lineHeight: '34px' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      borderRadius: '7px !important',
      color: 'white',
      bgGradient: 'linear(to-b, beta.600, beta.400)',
      boxShadow:
        '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',

      '&:active, &:hover': {
        bg: 'beta.700',
        boxShadow:
          '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
      },
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderRadius: '7px !important',
      border: '2px',
      borderColor: '#E57D24',
      color: 'white',
      fontWeight: 'bold',
      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.20))',

      '&:active, &:hover': {
        bgGradient: 'linear(to-b, beta.400, beta.500)',
        bgClip: 'text',
        borderColor: 'white',
      },
    }),
    tab: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),

      bg: 'alpha.500',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
      textTransform: 'uppercase',
      fontSize: 'xs',
      boxShadow:
        '0px 0px 0px 3px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
      h: '24px',
      px: '2',
      py: '3',
      color: 'white',
      textAlign: 'center',
      fontFamily: 'roboto',
      fontStyle: 'normal',
      fontWeight: '900',
      minW: 'fit-content',
      '&': {
        mb: '0.5',
        border: '2px',
        borderColor: 'beta.400',
        borderRadius: 'lg',
      },
      '&:hover, &:focus': {
        bg: 'alpha.500',
      },
      '&[data-closed="true"]': {
        bg: 'alpha.500',
        color: 'whiteAlpha.400',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        boxShadow:
          '0px 0px 0px 2px #152E36 inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
        borderColor: 'alpha.700',
      },
      '&:active, &[data-active="true"]': {
        mt: '0.5',
        bg: 'blackAlpha.500',
        color: 'beta.400',
        border: 'none',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',

      '&&': {
        '&:disabled': {
          bg: 'beta.500',
          color: 'white',
        },
      },
    }),
    ghost: {
      ...primary,
      bg: undefined,
      boxShadow: undefined,
      textShadow: undefined,

      _hover: {
        bg: 'alpha.50',
      },

      _focus: undefined,

      _active: {
        bg: 'alpha.100',
      },
    },
    filter: {
      border: '2px',
      borderColor: 'whiteAlpha.300',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '6',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',
      _hover: {
        color: 'beta.700',
      },
      _active: {
        bgGradient: 'linear(to-b, alpha.500, alpha.600)',
        color: 'white',
        border: 'none',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        bg: 'gamma.500',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      _hover: {
        '&&': {
          borderBottom: '3px solid',
          borderColor: 'beta.400',

          ':active, &[data-active="true"]': {
            border: 'none',
          },
        },
      },
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
