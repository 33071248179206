import { CSSObject } from '@chakra-ui/react';

export const GridItemBetslip: CSSObject = {
  h: '98%',
  '& > div[class*="-BetSlipModalContainer"]': {
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '100%',
  },
};
