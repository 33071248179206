import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  pt: '1',
};

export const TextTitle: CSSObject = {
  fontSize: 'sm',
  mb: '2',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
};

export const SmallHeading: CSSObject = {
  fontSize: 'sm',
  mb: '2',
};

export const Text: CSSObject = {
  fontSize: 'xs',
};

export const ImageGamblersHelp: CSSObject = {
  bg: 'white',
  p: '2',
  minW: '184px',
  w: '184px',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  my: '2',
};

export const Link: CSSObject = {
  color: 'gamma.300',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecoration: 'none',
  },
};

export const SubHeading: CSSObject = {
  fontSize: 'sm',
  mb: '2',
};

export const GridWrapper: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
  maxW: 'container.sm',
  pb: '0',
};

// export const ButtonAction: CSSObject = {
//   borderRadius: 'base',
//   bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
//
//   fontFamily: 'accent',
//   fontSize: 'sm',
//   textTransform: 'capitalize',
//   textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
//   border: 'none',
//   boxShadow:
//     '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
//   _hover: {
//     bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
//     textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
//     color: 'beta.900',
//     boxShadow:
//       '0px 2px 8px 0px rgba(0, 0, 0, 0.20) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.50) inset',
//   },
//   _active: {
//     bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
//     boxShadow:
//       '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px -1px 0px 0px rgba(255, 255, 255, 0.16) inset',
//     color: 'beta.500',
//     textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
//   },
//   _disabled: {
//     bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
//     boxShadow:
//       '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
//
//     textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
//     opacity: 0.4,
//   },
// };
