import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/titanbet/images/race-bg.png';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  mx: '0',
  borderTop: 'none',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '3',
  px: '3',
  py: '3',
  borderRadius: 'lg',
  gap: '1',
  bgImage: `url(${background})`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
};

export const RacerName: CSSObject = {
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
  color: 'white',
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'white',
  fontWeight: 'normal',
  fontSize: '2xs',
  position: 'relative',
  top: '-4px',
};
