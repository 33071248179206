/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Outlet } from 'react-router-dom';
import { GridItem } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { GridLayout, HeaderGridItem } from '../styles/SecondaryLayout.styles';
import Header from '../../../components/Headers/Basic/Basic';

const template: TTemplate = {
  wrapper: <GridLayout />,
  header: (
    <HeaderGridItem gridArea="header">
      <Header />
    </HeaderGridItem>
  ),
  main: (
    <GridItem gridArea="main" as="main">
      <Outlet />
    </GridItem>
  ),
};

export default template;
