import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  display: ['none', null, 'flex'],
  justifyContent: 'center',
  mt: [null, null, 'auto'],
};

export const TextItem: CSSObject = {
  maxW: '250px',
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'normal',
  span: { color: 'gamma.400', fontWeight: 'bold' },
  display: 'none',
};
