import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'onboarding.locationnotallowed.header': "Seems you've strayed off course.",
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing BetStride from outside of Australia.',
  'maintenance.heading': 'Taking a step back for maintenance',
  'error.heading': 'Looks like we stumbled',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'betslip.betslipmodal.emptybetslipheading': 'Stride ahead, fill the gap.',
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your racing and sports selections.',
};
