import * as yup from 'yup';

const promotionsNotificationsSchema = yup
  .object({
    notificationsEnabled: yup.boolean().required(),
    email: yup.boolean().required(),
    sms: yup.boolean().required(),
    phone: yup.boolean().optional(),
    post: yup.boolean().optional(),
  })
  .required();

export type PromotionsNotificationsSchema = yup.InferType<
  typeof promotionsNotificationsSchema
>;

export default promotionsNotificationsSchema;
