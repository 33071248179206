import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },
  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    borderRadius: '2xl',
    borderWidth: '3px',
    borderColor: 'beta.200',
    boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
    bg: 'beta.500',
  },
  LinkCardHeader: {
    color: 'gamma.500',
    p: '1.5',

    sx: {
      '.chakra-badge.state-prior': { color: 'gray.700' },
    },
  },
  TextRaceNumber: {
    bg: 'gamma.900',
    borderRadius: 'sm',
    color: 'white',
  },
  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
  },
  FlexGridRunnerItem: {
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    _last: {
      borderBottom: 'none',
    },
    sx: {
      ':nth-last-child(2)': {
        borderBottom: 'none',
      },
    },
  },
  TextRunnerNumber: {
    fontWeight: 'semibold',
    color: 'gamma.500',
    fontSize: 'xs',
  },
  LinkRaceDetails: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '2xs',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderBottomRadius: 'xl',

    _hover: {
      bgGradient: 'linear(to-b, alpha.600, alpha.700)',
      color: 'white',
    },
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.200',
    color: 'gamma.500',
    textTransform: 'uppercase',
  },
  buttonRaceCardOddsProps: {
    borderRadius: 'md',
    fontSize: 'sm',
    fontFamily: 'body',
    fontWeight: 'bold',
    w: '12',
    h: '8',
  },
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'gamma.500',
  fontWeight: 'semibold',
};

export const Button = (): CSSObject => ({
  color: 'white',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  transition: 'all .3s linear',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  border: '2px',
  borderColor: 'alpha.700',
  boxShadow: 'inset 0px 0px 0px 2px #426DD6',
  lineHeight: '1',
  fontSize: 'sm',
  fontFamily: 'body',
  fontWeight: 'bold',
  w: '12',
  h: '8',
});
