/* eslint-disable no-return-assign */
import { useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { Box, Hide, useMediaQuery } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccountSetupSchema } from '../../services/schemas/accountSetupSchema';
import { AU_DATE_FORMAT } from '@/lib/Constants';
import { getStrings } from '@/helpers/utils';
import {
  FormStack,
  PasswordTooltip,
  SignUpButton,
  SignUpDatePicker,
  signUpStyles,
} from '../styles/SignUp.styles';
import PasswordRules from './PasswordRules';
import FormControl from '../../../../components/FormElements/HOCs/FormControl';
import Address from './Address';
import Agreements from './Agreements';
import Password from '../../components/Password';
import { Input } from '../../styles/onboarding.styles';
import Select from '../../../../components/FormElements/Select';
import { EPunterTitles } from '@/api/punter/punter.types';

type TSignUpForm = {
  setMobilePasswordFocused: (val: boolean) => void;
  mobilePasswordFocused: boolean;
  placesAutocompleteLoaded: boolean;
};

/**
 * Hybrid Formik/Uncontrolled approach needed due to fix Chrome & CRA
 * not being able to handle autofill on a controlled component.
 *
 * Issue is logged here.
 * https://github.com/facebook/react/issues/1159.
 *
 * Any questions please reach out to Mark.
 */
export default function Form({
  setMobilePasswordFocused,
  mobilePasswordFocused,
  placesAutocompleteLoaded,
}: TSignUpForm) {
  const breakpoint = '(min-width: 768px)';
  const [largerThan768] = useMediaQuery(breakpoint);
  const inputRefs = useRef<Record<string, HTMLInputElement>>({});

  const intl = useIntl();

  const [
    {
      Onboarding: { Step1, Step2 },
      Generic,
    },
  ] = getStrings();

  const {
    isSubmitting,
    touched,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    setValues,
    submitForm,
    setSubmitting,
  } = useFormikContext<AccountSetupSchema>();

  const handleSetPromoCodeValue = (val: string) => {
    const allowedCharactersPattern = /^[a-zA-Z0-9!@#$%^&*()_+=[\]{}|\\/?.~-]+$/;
    const sanitizedValue = val
      .toUpperCase()
      .split('')
      .filter((char) => allowedCharactersPattern.test(char))
      .join('');
    setFieldValue('promoCode', sanitizedValue);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSubmitting(true);

        const keys = Object.keys(inputRefs.current);
        const data = keys.reduce(
          (a, b) => ({
            ...a,
            ...(!!inputRefs.current[b].value && {
              [b]: inputRefs.current[b].value,
            }),
          }),
          {}
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setValues({ ...values, ...data }, true);

        setTimeout(() => {
          submitForm().catch(() => undefined);
        }, 500);
      }}
    >
      <FormStack>
        <FormControl
          id="email-input"
          label={Generic.Email}
          error={!!touched.email && errors.email ? errors.email : undefined}
        >
          <Input
            ref={(el: HTMLInputElement) => (inputRefs.current.email = el)}
            name="email"
            autoComplete="email"
            id="email"
            type="email"
            placeholder={Step1.EmailInputPlaceholder}
            data-cy="signupEmail"
            onFocus={(e) => {
              setFieldValue('email', e.target.value);
              setFieldTouched('email', false);
            }}
            onBlur={(e) => {
              setFieldTouched('email', true);
              setFieldValue('email', e.target.value);
            }}
          />
        </FormControl>

        <PasswordTooltip
          hasArrow
          label={<PasswordRules show />}
          placement="right-end"
          closeOnClick={false}
          validPassword={(touched.password && !errors.password) ?? false}
          isDisabled={!largerThan768}
        >
          <Box w="100%">
            <FormControl
              id="password-input"
              label={Step1.PasswordInputLabel}
              error={
                !!touched.password && errors.password
                  ? errors.password
                  : undefined
              }
            >
              <Password
                name="password"
                ref={(el: HTMLInputElement) =>
                  (inputRefs.current.password = el)
                }
                placeholder={Step1.PasswordInputPlaceholder}
                autoComplete="new-password"
                data-cy="signupPassword"
                onFocus={(e) => {
                  setFieldValue('password', e.target.value);
                  setFieldTouched('password', false);
                  setMobilePasswordFocused(true);
                }}
                onBlur={(e) => {
                  setFieldTouched('password', true);
                  setFieldValue('password', e.target.value);
                  setMobilePasswordFocused(false);
                }}
              />
            </FormControl>
          </Box>
        </PasswordTooltip>

        <Hide breakpoint={breakpoint}>
          <PasswordRules show={mobilePasswordFocused || false} />
        </Hide>
      </FormStack>

      {/* Name */}
      <FormStack>
        <FormControl
          id="title-dropdown"
          label={intl.formatMessage({ id: 'onboarding.step2.titleInputLabel' })}
          error={!!touched.title && errors.title ? errors.title : undefined}
          sxWrapper={{ w: ['100%', '30%'], borderRadius: 'md' }}
        >
          <Select
            name="title-dropdown"
            placeholder={intl.formatMessage({
              id: 'account.personalDetails.titlePlaceholder',
            })}
            onChange={(e) =>
              setFieldValue('title', e.target.value as EPunterTitles)
            }
            sxWrapper={{ m: 0 }}
          >
            {Object.keys(EPunterTitles).map((el, i) => (
              <option value={el} key={i}>
                {el}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl
          id="firstName-input"
          label={Step2.FirstNameInputLabel}
          error={
            !!touched.firstName && errors.firstName
              ? errors.firstName
              : undefined
          }
        >
          <Input
            ref={(el: HTMLInputElement) => (inputRefs.current.firstName = el)}
            placeholder={Step2.FirstNameInputLabel}
            data-cy="verificationFirstName"
            autoComplete="given-name"
            onFocus={(e) => {
              setFieldValue('firstName', e.target.value);
              setFieldTouched('firstName', false);
            }}
            onBlur={(e) => {
              setFieldTouched('firstName', true);
              setFieldValue('firstName', e.target.value);
            }}
          />
        </FormControl>
      </FormStack>
      <FormStack>
        <FormControl
          id="middleName-input"
          label={intl.formatMessage({
            id: 'onboarding.step2.middleNameInputLabel',
          })}
          error={
            !!touched.middleName && errors.middleName
              ? errors.middleName
              : undefined
          }
        >
          <Input
            ref={(el: HTMLInputElement) => (inputRefs.current.middleName = el)}
            placeholder={intl.formatMessage({
              id: 'onboarding.step2.middleNameInputPlaceholder',
            })}
            data-cy="verificationMiddleName"
            autoComplete="given-name"
            onFocus={(e) => {
              setFieldValue('middleName', e.target.value);
              setFieldTouched('middleName', false);
            }}
            onBlur={(e) => {
              setFieldTouched('middleName', true);
              setFieldValue('middleName', e.target.value);
            }}
          />
        </FormControl>

        <FormControl
          id="lastName-input"
          label={Step2.LastNameInputLabel}
          error={
            !!touched.lastName && errors.lastName ? errors.lastName : undefined
          }
        >
          <Input
            ref={(el: HTMLInputElement) => (inputRefs.current.lastName = el)}
            placeholder={Step2.LastNameInputLabel}
            data-cy="verificationLastName"
            autoComplete="family-name"
            onFocus={(e) => {
              setFieldValue('lastName', e.target.value);
              setFieldTouched('lastName', false);
            }}
            onBlur={(e) => {
              setFieldTouched('lastName', true);
              setFieldValue('lastName', e.target.value);
            }}
          />
        </FormControl>
      </FormStack>
      {/* Name */}

      {/* Mobile */}
      <FormControl
        id="mobile-input"
        label={Step2.MobileInputLabel}
        error={!!touched.mobile && errors.mobile ? errors.mobile : undefined}
      >
        <Input
          ref={(el: HTMLInputElement) => (inputRefs.current.mobile = el)}
          type="tel"
          placeholder={Step2.MobilePlaceholder}
          data-cy="verificationMobile"
          autoComplete="tel"
          onFocus={(e) => {
            setFieldValue('mobile', e.target.value);
            setFieldTouched('mobile', false);
          }}
          onBlur={(e) => {
            setFieldTouched('mobile', true);
            setFieldValue('mobile', e.target.value);
          }}
        />
      </FormControl>
      {/* Mobile */}

      <Address
        ref={inputRefs}
        placesAutocompleteLoaded={placesAutocompleteLoaded}
      />

      <SignUpDatePicker
        name="dateOfBirth"
        data-cy="datePicker"
        label={Step2.DobInputLabel}
        format={AU_DATE_FORMAT}
        maxDate={dayjs().toDate()} // No future dates
        clearIcon={null}
        value={values.dateOfBirth}
        autoComplete="bday"
        errorText={
          touched.dateOfBirth && errors.dateOfBirth
            ? String(errors.dateOfBirth)
            : undefined
        }
        signup
      />

      <FormControl
        id="promoCode-input"
        label={Step2.PromoCodeLabel}
        error={
          !!touched.promoCode && errors.promoCode ? errors.promoCode : undefined
        }
      >
        <Input
          ref={(el: HTMLInputElement) => (inputRefs.current.promoCode = el)}
          data-cy="promoCode"
          value={values.promoCode?.toUpperCase()}
          onChange={(e) => {
            handleSetPromoCodeValue(e.target.value);
          }}
          onFocus={(e) => {
            handleSetPromoCodeValue(e.target.value);
            setFieldTouched('promoCode', false);
          }}
          onBlur={(e) => {
            setFieldTouched('promoCode', true);
            handleSetPromoCodeValue(e.target.value);
          }}
        />
      </FormControl>

      <Agreements />

      <SignUpButton
        {...signUpStyles.buttonSignUp}
        isFullWidth
        isLoading={isSubmitting}
        data-cy="signupSubmit"
        type="submit"
      >
        <FormattedMessage id="onboarding.step1.button" />
      </SignUpButton>
    </form>
  );
}
