import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  borderTopRadius: '7px',
  borderBottomRadius: '10px',
  bgGradient: 'linear(to-b, zeta.400, #E4C180)',
  mb: '2',
  mt: '0',
  overflowY: 'visible',
  contain: 'layout',
  color: '#7A3B1E',
  boxShadow:
    '0px 6px 4px 0px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
  color: '#7A3B1E',
  borderBottom: '1px dashed var(--bc-black-alpha-300, rgba(0, 0, 0, 0.16))',
};

export const TextHeading: CSSObject = {
  color: '#7A3B1E',
  fontSize: '14px',
  fontFamily: 'roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
};

export const ButtonViewMore: CSSObject = {
  bg: 'transparent',
  bgGradient: 'linear(to-b, #8F4623, #75381B)',
  color: 'zeta.400',
  borderBottomRadius: 'lg',
  border: 'none',
  textAlign: 'center',
  fontSize: 'bold',
  fontFamily: 'SkranjiBold',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.20)',
  boxShadow:
    '0px -2px 0px 0px #38190B inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px rgba(255, 255, 255, 0.15) inset',

  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.700)',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  },
};
