import { Global } from '@emotion/react';
import React from 'react';
import { createTemplate } from '@/helpers/createTemplate';
import { usePageSpecificContent } from '@/views/onboarding/services/onboarding.hooks';
import { GlobalStyles } from './styles/index';
import template from './templates/template';

export default function Aside() {
  const pageSpecificContent = usePageSpecificContent();
  return (
    <>
      <Global styles={GlobalStyles} />
      {createTemplate(template(pageSpecificContent))}
    </>
  );
}
