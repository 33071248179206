import { CSSObject } from '@chakra-ui/react';
import dinoRiders from '../../../../../../assets/volcanobet/images/homeFeaturedRacing/dinoRiders.png';
import dinoRiders2x from '../../../../../../assets/volcanobet/images/homeFeaturedRacing/dinoRiders2x.png';
import featuredRace from '../../../../../../assets/volcanobet/images/homeFeaturedRacing/featuredRace.png';
import featuredRace2x from '../../../../../../assets/volcanobet/images/homeFeaturedRacing/featuredRace2x.png';

export const FlexFeatureWrapper: CSSObject = {
  bgImage: `url(${dinoRiders})`,
  bgSize: '100%',
  bgRepeat: 'no-repeat',
  bgPosition: ['0 10%', '0 100%'],
  p: '0',
  minH: ['333px', '400px'],
  w: ['98%', '100%'],
  mb: ['2.5', 'unset'],
  mt: ['2', '0'],

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${dinoRiders2x})`,
  },
};

export const FlexFeatureCardWrapper: CSSObject = {
  pos: 'relative',
  top: ['0', '-12'],
  pr: ['1', null],
};

export const FlexFeatureTitleWrapper: CSSObject = {
  zIndex: '5',
  h: ['56', 'auto'],
  minH: ['52', 'auto'],

  bgPos: ['center 10%', 'top 12% left 10%'],
  bgRepeat: 'no-repeat',
  bgSize: '90%',
  bgImage: `url('${featuredRace}')`,
  mb: '-5',
  pr: '44',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url('${featuredRace2x}')`,
  },
};

export const ImageMascot: CSSObject = {
  pos: ['relative', null, 'absolute'],
  width: '34%',
  top: ['17px', '120px', '25px', '60px'],
  left: ['-55%', null, '30px'],
};

export const BoxCard: CSSObject = {
  border: '3px solid',
  borderColor: 'epsilon.200',
  borderRadius: 'md',
  boxShadow: '0px 4px 0px #6E7373, inset 0px 0px 10px rgba(0, 0, 0, 0.6)',
};

export const LinkCardHeader: CSSObject = {
  bg: 'epsilon.400',
  color: 'white',

  '.chakra-badge.state-prior': { color: 'gray.700' },
};

export const GridCardContent: CSSObject = {
  bg: 'epsilon.200',
  color: 'alpha.700',
  fontSize: 'sm',
};

export const IconCardHeader: CSSObject = {
  color: 'white',
};

export const TextCardHeader: CSSObject = {
  fontWeight: 'normal',
};

export const TextRaceNumber: CSSObject = {
  bg: 'epsilon.700',
  borderRadius: 'base',
  color: 'beta.500',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'epsilon.200',
  color: 'epsilon.600',
  textTransform: 'uppercase',
  fontWeight: 'semibold',
};

export const FlexGridRunnerItem: CSSObject = {
  bg: 'epsilon.400',
  borderBottom: '1px dashed',
  borderColor: 'delta.400',
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const Button = ({ isActive }: any): CSSObject => ({
  transition: 'all .3s linear',
  lineHeight: '1',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  boxSizing: 'border-box',
  fontSize: 'sm',

  '&:hover': {
    bg: 'alpha.500',
    color: 'gamma.600',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    color: 'gray.600',
    boxShadow: 'none',
  },

  ...(isActive && {
    bg: 'gamma.700',
    color: 'white',
    border: 'none',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',

    _hover: {
      bg: 'gamma.600',
      color: 'white',
      boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
    },
  }),
});

export const LinkRaceDetails: CSSObject = {
  color: 'beta.500',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  bg: 'epsilon.500',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15);',
  textTransform: 'uppercase',
  fontFamily: 'Caveman',
  fontWeight: 'normal',

  _hover: {
    color: 'beta.100',
  },
};
