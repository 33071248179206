import { DepositSchema } from '@/views/account/Deposit/styles/Deposit.styles';

export const depositStyles: DepositSchema = {
  inputCardHolderNameProps: {
    sx: {
      '&&&&': {
        height: 'auto',
        border: '1px solid rgb(0 0 0 / 10%)',
        borderRadius: '4px',
        padding: '12px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        background: '#FFF',
        fontWeight: 700,
        color: '#2D3748',
        fontSize: '12px',
      },
    },
  },
};
