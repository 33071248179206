import { InputProps } from '@chakra-ui/input';
import theme from '@chakra-ui/theme';

export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      border: '1px solid',
      color: 'alpha.900',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
      fontSize: 'sm',
      fontWeight: 'bold',
      caretColor: 'currentColor',

      // Can be made less specific as soon as we delete some rogue overrides, require this temporarily. Note below.
      '&&': {
        borderRightRadius: 'md',
        borderColor: 'alpha.500',
        borderLeft: 'none',
        bg: 'white',
      },

      '&::placeholder': {
        color: 'alpha.900',
      },

      '&:hover': {
        '&&': {
          bg: 'alpha.100',
        },
      },

      '&:focus, &:active': {
        // Here too
        '&&': {
          boxShadow:
            '0px -1px 12px 0px rgba(0, 0, 0, 0.25) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
          borderColor: 'alpha.500',
          bg: 'alpha.100',
        },
      },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      fontWeight: 'black',
      fontSize: 'sm',
      color: '#441200',

      // Here too
      '&&': {
        bg: 'linear-gradient(180deg, #79E024 0%, #55C108 100%)',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
        borderLeftRadius: 'md',
        border: 'none',
        _groupFocusWithin: {
          boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
        },
      },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
    md: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
  },
  variants: {
    // TODO: With the addition of variant(s) like below we can avoid using specificty rules like above in the baseStyle with '&&', however this comes with some caveats:
    // 1. Custom variants like the one below cant be used within defaultProps, by looks of it.
    // 2. If we do use variants, every Input requires the variant prop, even if pointing to non-existant variant, alternatively it fall back to the the filled chakra variant, defined: node_modules/@chakra-ui/theme/src/components/input.ts. As such, we will have to guarantee this before deleting '&&' from all inputs.
    // secondary: {
    //   field: {
    //     bg: 'green',
    //   },
    //   addon: {
    //     bg: 'green',
    //   }
    // },
    unstyled: (props: InputProps) => {
      const { addon, field } = theme.components.Input.variants.outline(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          '&&': {
            boxShadow: 'none',
            color: 'black',
            bg: 'white',
            borderRadius: 'base',
            h: '40px',
            borderColor: 'white',
            pl: '2.5',
          },
          '&:focus, &:active, &:hover': {
            '&&': {
              bg: 'white',
              borderColor: 'white',
              color: 'black',
            },
          },
        },
      };
    },
  },
  defaultProps: {
    size: 'md',
  },
};
