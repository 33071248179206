import { SRMultiStylesSchema } from './SRMulti.styles';

export const srMultiStyles: SRMultiStylesSchema = {
  multiContainer: {
    mx: '4',
    p: '2',
    bg: '#495564',
    borderRadius: 'md',
    mb: '2',
  },
  multiRunnerContainer: {
    justifyContent: 'space-between',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    p: '3',
    my: '2',
    borderRadius: 'md',
  },
  buttonLabel: {
    color: 'white',
  },
  propButton: {
    w: '50px',
  },
  columnHeadings: {
    w: ['48px', null, '49px'],
    textAlign: 'center',
    fontSize: 'xs',
    color: 'gray.700',
    fontWeight: 'medium',
  },
  headingLabel: {
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  headingContainer: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'medium',
    marginRight: '1',
  },
  runnerList: {
    p: '2',
    border: '1px',
    borderColor: 'beta.600',
    bg: 'white',
    borderRadius: 'lg',
  },
  headingStacker: {
    rowGap: '2',
  },
};
