import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  borderRadius: 'lg',
  color: 'white',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset',
  boxSize: '10',
  display: 'flex',
  justifyContent: 'center',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'white',
  boxSize: '8',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bg: 'white',
  alignItems: 'center',
  gridTemplateColumns: '58px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  w: '100%',
  h: '64px',
  _hover: {
    bg: 'gray.200',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'semibold',
  fontSize: ['sm', null, 'md'],
  color: 'alpha.700',
  mt: '1.5',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'beta.400',
};

export const TeamItemOdds: CSSObject = {
  color: 'yellow.900',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const VersusText: CSSObject = {
  display: 'block',
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'epsilon.500',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'beta.600',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",
`,
  color: 'alpha.700',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'alpha.700',
  fontSize: 'sm',
  ...(isVS && { color: 'alpha.700' }),
});

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'beta.400',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'alpha.700',
  border: '2px',
  borderColor: 'alpha.700',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
