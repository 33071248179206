import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  headerSportText: {
    fontSize: 'md',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    color: 'beta.300',
    fontFamily: 'Fraunces',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  boxWrapper: {
    px: 0,
  },
  accordionButton: {
    color: 'gray.700',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      bgGradient: 'linear(to-b, alpha.600, alpha.800)',
      fontSize: 'sm',
      boxShadow:
        '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',

      _hover: {
        bgGradient: 'linear(to-b, alpha.700, alpha.700)',
      },
    },
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      color: 'white',
      fontsize: 'md',
      my: '2',
      fontWeight: 'bold',
    },
  },
  topLeaguesAccordionItem: {
    borderBottomRadius: 'base',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    sx: {
      _last: {
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'white',
    sx: {
      display: 'flex',
      p: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      bgGradient: 'linear(to-b, alpha.600, alpha.800)',
      borderRadius: 'unset',

      _hover: {
        bgGradient: 'linear(to-b, alpha.700, alpha.700)',
      },
    },
    _last: {
      boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
    color: 'white',
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionPanel: {
    bg: 'white',
    px: '2',
    pb: '3',
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'beta.400',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'semibold',
    sx: { _hover: { textDecoration: 'none' } },
  },
  accordionIcon: { color: 'white' },
  accordionPanel: {
    bg: 'white',
    px: '2',
    py: '3',
    borderBottomRadius: 'md',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'beta.400',
    px: '2',
  },
  topLeaguesText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
    color: 'white',
    fontSize: 'sm',
    px: 0,
  },
};
