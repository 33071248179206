import { CSSObject } from '@chakra-ui/react';

export const DatePickerLabel: CSSObject = {
  color: 'gray.500',
  fontSize: 'xs',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
};

export const StyledDatePicker: CSSObject = {
  pos: 'relative',
  zIndex: '1',
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '38px',
      borderRadius: 'lg',
      border: '1px solid',
      borderLeft: '1px',
      borderColor: 'gray.300',
      background: 'gray.50',
      display: 'flex',
      px: '3',
      py: '2.5',
      alignItems: 'center',
      gap: '1.5',
      alignSelf: 'stretch',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      boxShadow: 'none',
      color: 'gray.800',
      w: 'full',

      _hover: {
        bg: 'gray.200',
      },

      _focus: {
        bg: 'gray.200',
      },
    },
  },
};
