import { CSSObject } from '@chakra-ui/react';
import room from '../../../assets/puntgenie/images/backgrounds/image-room.png';

export const TextTitleSuffixContent: CSSObject = {
  display: 'inline-block',
  color: 'white',
};

export const TextTitle: CSSObject = {
  fontSize: ['22px', null, '27px'],
  textTransform: 'uppercase',
  fontWeight: 'semibold',
  lineHeight: '1.2',
  display: 'block',
  mb: ['0', '4'],
  mx: 'auto',
  maxW: ['277px', '183px', 'unset'],
  flex: ['1', null, 'unset'],
  fontFamily: 'CinzelDecorative',
  textAlign: ['center', 'left'],
  color: 'delta.400',
  bgGradient: 'linear(to-b, #F5C44D, #E7A402)',
  bgClip: 'text',
};

export const TextSubtitle: CSSObject = {
  fontSize: 'sm',
  flex: '1',
  mt: ['unset'],
  color: 'white',

  span: {
    color: 'delta.400',
  },
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  color: 'white',
};

export const TextCTALink: CSSObject = {
  color: 'delta.400',
  fontWeight: 'bold',
};

export const FlexAsideInfo: CSSObject = {
  gridArea: '1/1',
  flexDir: 'column',
  alignItems: ['start', null, 'unset'],
  zIndex: 'overlay',
  m: ['unset', null, '10'],
  mt: ['2', null, '10'],
  color: 'black',
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  minH: ['336px', '682px'],
};

export const FlexCTAContainer: CSSObject = {
  justifyContent: 'center',
  alignSelf: ['center', null, 'unset'],
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    backgroundImage: `url(${room})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
    zIndex: '1',
    '@media (max-width: 480px)': {
      backgroundPosition: 'top, bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '.bc-content-item': { marginTop: '70px' },
    },

    ':after': {
      content: `""`,
      position: 'absolute',
      left: '0',
      top: '0',
      background: '#142268c8',
      height: '100%',
      width: '100%',
      zIndex: '-1',
    },
  },
};
