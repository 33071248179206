import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  color: 'white',
  fontWeight: 'medium',
  height: 'auto',
  border: 'none',
  fontSize: 'md',
  '#menu-button-30': {
    marginBottom: '2',
  },
  '& p:nth-child(1)': {
    color: 'white',
    fontSize: 'md',
    fontWeight: 'medium',
    '& svg': {
      boxSize: '5',
    },
  },
};

export const FlexHeadingMiscWrapper: CSSObject = {
  zIndex: 'overlay',
  '& svg:nth-child(1)': {
    boxSize: '4',
    color: 'white',
  },
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', 'row'],
    color: 'alpha.200',
    alignItems: ['flex-start', 'center'],

    '.timestamp': {
      fontSize: '9px',
      color: 'white',
      fontWeight: 'bold',
      borderRadius: 'base',
      padding: '3px 4px',
      marginLeft: '1',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
  border: 'none',
  bg: 'white',
};

export const TextSportName: CSSObject = {
  '&&&&': {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'medium',
    marginLeft: '4px',
  },
};

export const TextVS: CSSObject = {
  alignSelf: 'center',
  color: 'white',
};
