import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { px: 2.5 },
  buttonCashOutAmount: { px: 2.5 },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'beta.500',
    border: 'none',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    fontFamily: 'Spectral',
  },
  iconOddsBoost: {
    fill: 'beta.500',
  },
  textOddsBoost: {
    color: 'beta.500',
  },
  iconCashOut: {
    fill: 'blackAlpha.700',
  },
  cashoutValue: {
    color: 'blackAlpha.700',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.500',
};

export const RaceTime: CSSObject = {
  color: 'gray.400',
  mt: '1',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.500',
  color: 'white',
};

export const BonusBetText: CSSObject = {
  color: 'beta.500',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  color: 'alpha.400',
  borderColor: 'alpha.900',
};

export const BetReturn: CSSObject = {
  bg: 'white',
  color: 'alpha.900',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: '2xs',
  border: '1px',
  borderColor: 'alpha.600',
};

export const BoxBetReturnValue: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'white',
};

export const BoxBetReturnText: CSSObject = {
  pl: '1',
  py: '1',
  color: 'alpha.900',
};
