export const colors = {
  alpha: {
    100: '#5F89D3',
    200: '#4F73B4',
    300: '#405F97',
    400: '#2D4877',
    500: '#25395E',
    600: '#1E3153',
    700: '#1C2E4D',
    800: '#132037',
    900: '#001336',
  },
  beta: {
    100: '#FFDD94',
    200: '#FFCE64',
    300: '#FFBF36',
    400: '#FFB71D',
    500: '#EEA200',
    600: '#CA8A02',
    700: '#A67203',
    800: '#825903',
    900: '#6B4A03',
  },
  gamma: {
    100: '#31D100',
    200: '#0080EF',
    300: '#CE00EF',
    400: '#FF480E',
    500: '#130EFF',
    600: '#FF0ECA',
    700: '#0EFFE2',
    800: '#B2FF0E',
    900: '#572F04',
  },
};
