import { TBetSlipBetMulti } from '../../../Services/Betslip.types';

export const checkMultiValidity = (bet: TBetSlipBetMulti | undefined) => {
  const { legs } = bet ?? {};

  // Return true if:
  // set of unique event IDs contains at least 2 elements, and
  // there are 20 or less legs, and
  // every leg is from a different event, and
  // no SP bets were added
  return (
    new Set(legs?.map((prop) => prop.event_id)).size > 1 &&
    (legs?.length ?? 0) <= 20 &&
    new Set(legs?.map((prop) => prop.event_id)).size === legs?.length &&
    legs?.every(({ price_type }) => !price_type || price_type === 'fixed')
  );
};

export const calculateMultiOdds = (bet: TBetSlipBetMulti) =>
  bet.legs.reduce(
    (acc, curr) => (!curr.eventClosed ? acc * curr.odds : acc),
    1
  ) || 0;
