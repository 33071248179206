import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  heading: {
    mb: '2',
    color: 'white',
    fontWeight: '700',
  },
  promotionWrapper: {
    p: '4',
    bg: 'white',
    my: '3',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'md',
  },
  flexWrapper: { gap: '4', pt: '4', justifyContent: 'space-between' },
  termsAndConditions: {
    color: 'gray.700',
    fontSize: 'sm',
    pt: '1',
  },
  boxWrapper: {
    pl: '0',
  },
  filteredPromotionsWrapper: {
    p: '0',
    pt: 1.5,
  },
};
