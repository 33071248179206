import { CSSObject } from '@chakra-ui/react';

export const BoxInner: CSSObject = {
  overflow: 'hidden',
  marginTop: '4',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const LinkWrapper: CSSObject = {
  borderBottom: '1px',
  borderBottomColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  color: 'white',
  fontWeight: 'bold',
  px: '2',
  py: '4',
};
