/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Text } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  BoxHeadingWrapper,
  FlexIllustrationWrapper,
  FlexWrapper,
  TextHeading,
  TextSubHeading,
} from '../styles/Featured.styles';
import { FeaturedMatch } from '../components/FeaturedMatch';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  slogan: {
    body: (
      <BoxHeadingWrapper>
        <TextHeading>
          Bend <Text as="span">it</Text>
        </TextHeading>
        <TextSubHeading>
          {String(`'Round the belt!`)
            .split('')
            .map((l) => (
              <Text as="span" key={`featured-sport-${l}`}>
                {l}
              </Text>
            ))}
        </TextSubHeading>
      </BoxHeadingWrapper>
    ),
    feature: <FeaturedMatch />,
  },
  mascot: <FlexIllustrationWrapper />,
};

export default template;
