import React from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';
import { useVerifyIdentity } from '@/views/account/VerifyIdentity/Services/Hooks.VerifyIdentity';
import { isVerified } from '@/views/account/Overview/services/Overview.utils';
import { GreenIDContainer } from '@/views/account/VerifyIdentity/styles/VerifyIdentity.styles';
import { useAppSelector } from '@/hooks/useRedux';
import { GREENID_CSS_URL } from '@/lib/Constants';
import { getStrings } from '@/helpers/utils';
import { useAuth } from '@/hooks/useAuth';
import { EGreenIDStatus } from '@/views/account/VerifyIdentity/Services/Config.VerifyIdentity';

export default function Content() {
  const [
    {
      Account: { VerifyIdentity: VerifyIdentityStrings },
    },
  ] = getStrings();

  const { accountOverview, verificationStatus } = useAppSelector(
    (state) => state.punter
  );

  const { logout } = useAuth();

  useVerifyIdentity(true, undefined, () => {
    if (
      verificationStatus === EGreenIDStatus.PENDING ||
      verificationStatus === EGreenIDStatus.LOCKED_OUT
    ) {
      toast.error(VerifyIdentityStrings.StatusFailed, {
        id: 'error',
      });
      logout().catch(() => undefined);
    }
  });

  if (isVerified(verificationStatus)) {
    if (!accountOverview?.is_onboarded) return <Navigate to="/deposit-limit" />;

    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href={GREENID_CSS_URL}
        />
      </Helmet>

      {!isVerified(verificationStatus) && <GreenIDContainer id="greenID" />}
    </>
  );
}
