import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  px: '0',
  py: '0',
  pt: '1',
  pb: '3',
  h: '9',
  position: 'inherit',
};

export const IconRace: CSSObject = {
  ml: '0.5',
};

export const ButtonFilter = () => ({
  color: 'alpha.600',
  filter: 'drop-shadow(0px 1px 1px rgba(255, 255, 255, 0.30))',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'alpha.600',
  color: selected ? 'gamma.300' : 'transparent',
  boxShadow: '0px 1px 0px 0px #000 inset',
  mr: '0.5',
});

export const TextFilterLabel: CSSObject = {
  color: 'alpha.700',
};

export const Divider: CSSObject = {
  display: 'block',
  bg: 'alpha.600',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: 'unset',
};
