import { CSSObject } from '@chakra-ui/react';
import background from '../../../assets/vikingbet/images/backgrounds/maintenanceBackground.png';
import backgroundAtRetina from '../../../assets/vikingbet/images/backgrounds/maintenanceBackgroundMB.png';
import backgroundError from '../../../assets/vikingbet/images/backgrounds/errorbackground.png';
import backgroundAtRetinaError from '../../../assets/vikingbet/images/backgrounds/errorMB.png';

export const FlexPageWrapper = (isMaintenance: boolean): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: `url(${isMaintenance ? background : backgroundError})`,
  bgRepeat: 'no-repeat',
  bgSize: ['100%', '100% 100%'],

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${
      isMaintenance ? backgroundAtRetina : backgroundAtRetinaError
    })`,
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  mt: ['0', null, '12'],
  mx: 'auto',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.lg',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  img: {
    mb: ['40', null, '4'],
  },
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'SkranjiBold',
  fontSize: ['4xl', null, '6xl'],
  lineHeight: ['2.3rem', null, '3.6rem'],
  mt: '4',
  mb: ['2.5', null, '4'],
  textTransform: 'uppercase',
  bg: 'linear-gradient(180deg, #FCD236 0%, #E79004 100%)',
  backgroundClip: 'text',
  fontWeight: '700',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  b: {
    bgGradient: 'linear(to-b, alpha.500, gamma.500)',
    bgClip: 'text',
    textShadow: 'none',
    fontWeight: 'normal',
  },
};

export const ContentBodyText: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  mb: ['0', '4'],
  w: ['280px', '329px'],
  wordWrap: 'break-word',
  '&[data-ismaintenance="false"]': {
    w: ['185px', '400px'],
  },
};

export const ButtonRefresh: CSSObject = {
  borderColor: 'white',
  boxShadow: 'none',
  color: 'white',
  mt: ['5', null, null],
  _hover: {
    borderColor: 'alpha.500',
    boxShadow: 'none',
    color: 'alpha.500',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};
