import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapper: BoxProps = {};
export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'Ultra',
  fontSize: '2xl',
  color: 'delta.400',
  textShadow: '0px 2px 0px var(--bc-colors-delta-600)',
  textTransform: 'uppercase',
  lineHeight: '110%',
  fontWeight: 'normal',
  textAlign: 'center',
  mx: '9',
  mb: '1',
};
export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};
export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  textAlign: 'center',
  mx: '8',
  mt: '2',
  color: 'delta.700',
  width: '82%',
};
export const BetSlipEmptyImage: CSSObject = {
  mr: '8',
  width: '80%',
};
