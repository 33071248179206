import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1',
  },

  container: { display: 'contents' },
  racingBtnsContainer: { display: 'contents' },
  sportBtnsContainer: { display: 'contents' },

  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    boxSize: '26px',
    mr: 0,
    filter: 'drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.30))',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    boxSize: '26px',
    filter: 'drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.30))',
    mr: 0,
  },

  linkIconBox: {
    flex: '1 0 0',
    minW: '0',
    h: '57px',
    borderRadius: 'lg',
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    alignItems: 'center',
    justifyContent: 'center',

    display: {
      base: 'none',
      xl: 'flex',
    },
    _last: { display: 'flex' },

    sx: {
      '&:nth-child(1), &:nth-child(2)': {
        display: { sm: 'flex' },
      },

      '&:nth-child(3), &:nth-child(4)': {
        display: { md: 'flex' },
      },
    },

    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      mx: 'auto',
      h: '3px',
      w: '25px',
      bg: '#66C8FF',
      borderBottomRadius: '2px',
    },
  },

  linkBox: {
    display: 'flex',
    flex: '1 0 0',
    minW: '0',
    h: '57px',
    borderRadius: 'lg',
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    alignItems: 'center',
    justifyContent: 'center',

    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      mx: 'auto',
      h: '3px',
      w: '25px',
      bg: '#66C8FF',
      borderBottomRadius: '2px',
    },
  },

  linkOverlay: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1',
    pb: '1',
    pt: '2',
    px: '2',
    justifyContent: 'center',
    alignItems: 'center',
  },

  linkBoxText: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'xs',
    textTransform: 'uppercase',
    maxW: '70px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  racingHeader: { display: 'none' },
  sportsHeader: { display: 'none' },

  sportsHeaderTile: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'xs',
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'center',
    maxW: '70px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  linkOverlayOverride: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'xs',
    textTransform: 'uppercase',
    w: '58px',
  },
  sportIconOverride: {
    color: 'white',
    textAlign: 'center',
    w: '56px',
  },
};
