import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ERaceType } from '../lib/DBModels';

enum EGame {
  Racing = 'racing',
  Sport = 'sport',
}

export enum ELocale {
  Aus = 'aus',
  Intl = 'intl',
}

export enum EFilters {
  Racing = EGame.Racing,
  Sport = EGame.Sport,
  Thoroughbred = ERaceType.Horse,
  Greyhounds = ERaceType.Greyhound,
  Harness = ERaceType.Harness,
  Aus = ELocale.Aus,
  Intl = ELocale.Intl,
}

type TInitialState = {
  filters: Record<EFilters, boolean>;
  activeFilters: EFilters[];
  activeLocaleFilters: ELocale[];
  activeRaceFilters: ERaceType[];
};

const cachedFilters = localStorage.getItem('NTJFilters');

const cachedFiltersParse = (
  cachedFilters ? JSON.parse(cachedFilters) : {}
) as TInitialState['filters'];
// Sports are being hidden P1
delete cachedFiltersParse[EFilters.Sport];

const defaultFilters = {
  [EFilters.Racing]: true,
  [EFilters.Sport]: false,
  [EFilters.Thoroughbred]: true,
  [EFilters.Thoroughbred]: true,
  [EFilters.Greyhounds]: true,
  [EFilters.Harness]: true,
  [EFilters.Aus]: true,
  [EFilters.Intl]: true,
};

const $filters = cachedFilters ? cachedFiltersParse : defaultFilters;

const activeFilters = Object.keys($filters).filter(
  (k) => $filters?.[k as unknown as EFilters]
) as unknown as EFilters[];

const activeLocaleFilters = (filters: EFilters[]) =>
  filters.filter((f) =>
    Object.values(ELocale).includes(f as unknown as ELocale)
  ) as unknown as ELocale[];

const activeRaceFilters = (filters: EFilters[]) =>
  filters.includes(EFilters.Racing)
    ? (filters.filter((f) =>
        Object.values(ERaceType).includes(f as unknown as ERaceType)
      ) as unknown as ERaceType[])
    : [];

const initialState: TInitialState = {
  filters: $filters,
  activeFilters,
  activeLocaleFilters: activeLocaleFilters(activeFilters),
  activeRaceFilters: activeRaceFilters(activeFilters),
};

const ntjFiltersSlice = createSlice({
  name: 'ntjFilters',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<Record<EFilters, boolean>>) => {
      const data: TInitialState = {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

      if (
        !data.filters?.[EFilters.Racing]
        // && !data.filters?.[EFilters.Sport]
      ) {
        data.filters[EFilters.Racing] = true;
        data.filters[EFilters.Sport] = true;
      }

      if (!data.filters?.[EFilters.Aus] && !data.filters?.[EFilters.Intl]) {
        data.filters[EFilters.Aus] = true;
        data.filters[EFilters.Intl] = true;
      }

      if (
        !data.filters?.[EFilters.Thoroughbred] &&
        !data.filters?.[EFilters.Greyhounds] &&
        !data.filters?.[EFilters.Harness]
      ) {
        data.filters[EFilters.Thoroughbred] = true;
        data.filters[EFilters.Greyhounds] = true;
        data.filters[EFilters.Harness] = true;
      }

      localStorage.setItem('NTJFilters', JSON.stringify(data.filters));

      const setActiveFilters = Object.keys(data.filters ?? {}).filter(
        (k) => data.filters?.[k as unknown as EFilters]
      ) as unknown as EFilters[];

      data.activeFilters = setActiveFilters;
      data.activeLocaleFilters = activeLocaleFilters(setActiveFilters);
      data.activeRaceFilters = activeRaceFilters(setActiveFilters);

      return data;
    },
    resetFilters: () => initialState,
  },
});

export const { setFilter, resetFilters } = ntjFiltersSlice.actions;
export default ntjFiltersSlice.reducer;
