import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexContainer = (): CSSObject => ({
  alignItems: ['center', null, null, null, 'start'],
  pos: 'relative',
  px: '0',
});

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  w: 100,
};

export const BoxWrapper: BoxProps = {
  pr: '1',
  bgGradient: ['linear(to-b, gamma.500, gamma.700)', 'unset'],
  borderBottom: ['2px solid', 'unset'],
  borderColor: ['beta.400', 'transparent'],
  mb: '2',
};

export const ButtonGroup: CSSObject = {
  alignItems: 'start',
  gap: 1,
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'white',
  ml: '3',
  svg: {
    boxSize: '6',
  },
});

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'tertiary',
  h: '12',
  minW: '20',
  fontWeight: 'extrabold',
  fontFamily: 'Fraunces',
  fontSize: 'md',
};

export const buttonLogInProps: CustomButtonProps = {
  variant: 'tertiary',
  h: '48px',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'solid',
  h: 12,
  pl: 2.5,
  pr: 5,
  w: 'fit-content',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  border: 'none',
  position: 'relative',
  boxShadow:
    '0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.35)',
  _after: {
    content: `''`,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    inset: 0,
    bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
    boxShadow: '0px 0px 0px 2px rgba(19, 39, 75, 1) inset',
    zIndex: 8,
    borderRadius: 'md',
    m: '2px',
  },
  _hover: {
    bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    _after: {
      bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    },
    '& > svg': {
      boxSize: '6',
      zIndex: 10,
      color: 'beta.600',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.3)',
    },
  },
  sx: {
    '&[aria-expanded="true"]': {
      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
      _after: {
        bg: 'alpha.500',
      },
    },

    '.btn-span': {
      flexDir: 'column',
      gap: 0.5,
      alignSelf: 'center',
      lineHeight: 1.2,
    },

    '.span-balance': {
      fontSize: ['sm', null, null, null, 'md'],
      color: 'beta.400',
      fontFamily: 'Fraunces',
      textShadow: '0px 1px 2px rgba(2, 2, 2, 0.55)',
    },
    '.span-bonus': {
      fontSize: 'xs',
      fontWeight: 'bold',
      color: 'white',
      textShadow: '0px 1px 2px rgba(2, 2, 2, 0.55)',

      svg: {
        mr: '1',
        boxSize: '2.5',
        pos: 'relative',
        bottom: '-px',
        textShadow: '0px 1px 2px rgba(2, 2, 2, 0.55)',
      },
    },

    '.chevron-icon': {
      my: 'auto',
      pos: 'absolute',
      top: 0,
      bottom: 0,
      right: 1.5,
      boxSize: 4,
      color: 'white',
      textShadow: '0px 1px 2px rgba(2, 2, 2, 0.55)',
    },
  },
};

export const ButtonGroupBets: CSSObject = {
  gap: 1,

  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },
  '>*:first-of-type:not(:last-of-type)': {
    borderRightRadius: 'md',
  },
  '>*:not(:first-of-type):last-of-type': {
    borderLeftRadius: 'md',
  },
};

const betButtonStyles: CustomButtonProps = {
  variant: 'secondary',

  sx: {
    '&&': {
      h: 12,
      minW: 12,
      fontWeight: 'black',
      fontSize: 'xs',
    },
    '.btn-span': {
      flexDir: 'column',
      gap: 0.5,
      alignSelf: 'center',
      lineHeight: 1,
    },
    '.span-bet-count': {
      fontSize: 'md',
    },
  },
};

export const TextButton: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'black',
};

export const buttonBetsProps: CustomButtonProps = betButtonStyles;

export const buttonBetSlipProps: CustomButtonProps = betButtonStyles;

export const ButtonOutsideArea = {
  display: 'none',
};
