import React, { useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Box, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { keys } from '@/api/api.keys';
import { queryEvenShot } from '@/api/racing/evenShot/evenShot';
import { evenShotStyles } from './styles/EvenShot.styles';
import { useAuth } from '@/hooks/useAuth';
import { EGeneralStatus, TRaceMarket, TRaceMeta } from '@/lib/DBModels';
import {
  useAddEvenShotToBetSlip,
  useBetSlipManageBets,
} from '@/components/Betslip/Services/Betslip.hooks';
import { EBetSlipBetSubmissionType } from '@/components/Betslip/Services/Betslip.types';
import { queryBetsForRace } from '@/api/racing/bets/bets';
import { TEvenShotErrorResponse } from '@/api/racing/evenShot/evenShot.types';
import { Button } from '@/components/Button/Button';
import { TProposition } from '@/api/racing/bets/bets.types';
import { queryPunterBetRequests } from '@/api/punter/punter';
import { EBetRequestStatus } from '@/api/punter/punter.types';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

type TEvenShot = {
  race: TRaceMeta;
  market?: TRaceMarket;
};

export default function EvenShot({ race, market }: TEvenShot) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const raceId = searchParams.get('raceId');
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth();
  const isRaceOpen = race.status === EGeneralStatus.Open;
  const { setBet } = getBetSlipStoreActions();

  // Even Shot Call
  const { data, isLoading, isFetching } = useQuery(
    [keys.evenShot, !!raceId, !!race.status],
    () => queryEvenShot({ race_id: raceId ?? '' }),
    {
      retry: false,
      onError: (error: TEvenShotErrorResponse) => {
        if (error.user_msg) {
          setErrorCode(error?.code ?? '');
        }
      },
    }
  );

  //  ~ DayJS
  dayjs.extend(relativeTime);
  const currentTime = dayjs();
  const marketTime = market?.open_at_time;
  const isTimePassed = dayjs(marketTime).isBefore(currentTime);
  const marketOfferedDelta =
    race.start_time && marketTime
      ? (new Date(race.start_time).getTime() - new Date(marketTime).getTime()) /
        (1000 * 60)
      : undefined;

  const statuses = ['pending', 'settled'];
  // Bets on race
  const { data: betsForRace } = useQuery(
    [keys.betsForRace, !!raceId, !!isAuthenticated],
    () =>
      queryBetsForRace({
        race_id: raceId ?? '',
        price_type: 'even_shot',
        status: statuses,
      }),
    { enabled: !!isAuthenticated }
  );

  const { data: betRequest } = useQuery(
    [keys.betRequests, !!isAuthenticated, !!raceId],
    () => queryPunterBetRequests({ states: 'Pending' }),
    { enabled: !!isAuthenticated }
  );

  const hasPendingBet = betRequest?.records?.find(
    (bet) =>
      bet?.status === EBetRequestStatus.Pending &&
      bet?.event_id === raceId &&
      bet?.price_type === 'even_shot'
  );

  // Betslip
  const { addEvenShotToBetSlip } = useAddEvenShotToBetSlip();
  const { removeSinglePropositionFromBetSlip, singlePropositionInBetSlip } =
    useBetSlipManageBets();

  const isInBs = singlePropositionInBetSlip(data?.proposition_id ?? '');

  // Formatted
  const formattedPropositionType = (name?: string) =>
    name?.replace(/(\d+)/g, ' $1') ?? '';

  const marketName = `${formattedPropositionType(
    data?.proposition_type
  )} - Even Shot`;

  const isHarnessRace = race.race_type === 'harness';
  const runnerName = `${data?.runner_number}. ${data?.runner_name} ${
    data?.barrier_number && !isHarnessRace && `(${data?.barrier_number})`
  } `;

  const hasBets = !!betsForRace?.length;
  const evenShotBet = betsForRace?.find(
    (bet) => bet.price_type === 'even_shot'
  );
  const evenShotProposition =
    evenShotBet?.proposition_info && evenShotBet?.proposition_info[0];

  function formatBetRunnerName(proposition: TProposition) {
    const {
      runner_number: runnerNumber,
      name,
      barrier_number: barrierNumber,
    } = proposition;
    return `${runnerNumber}. ${name} ${
      !isHarnessRace ? `(${barrierNumber})` : ''
    }`;
  }

  const handleClick = () => {
    if (!isAuthenticated && !isAuthLoading) {
      navigate('/login', { replace: true });
      return;
    }

    setBet({
      id: `${data?.proposition_id}-es` ?? '',
      type: 'Single',
      odds: data?.price ?? 0,
      propId: data?.proposition_id ?? '',
      priceType: 'even_shot',
      misc: {
        race,
        runner: data,
        sportTitle: marketName,
        subTitle: runnerName,
      },
    });

    if (isInBs) {
      removeSinglePropositionFromBetSlip(
        EBetSlipBetSubmissionType.Single,
        data?.proposition_id ?? ''
      );
    } else {
      addEvenShotToBetSlip(data, marketName, runnerName, race);
    }
  };

  const renderContent = () => {
    if (isLoading || isFetching)
      return (
        <Stack mb="5" alignItems="center">
          <Skeleton height="20px" w="50px" />
          <Skeleton height="20px" w="250px" />
        </Stack>
      );

    if (!isAuthenticated && !isAuthLoading && !isLoading)
      return (
        <Button
          onClick={handleClick}
          isLoading={isLoading}
          data-active={!!isInBs}
          {...evenShotStyles.loginToView}
        >
          <FormattedMessage id="generic.loginToView" />
        </Button>
      );

    if (!isTimePassed)
      return (
        <>
          <Text {...evenShotStyles.notYetAvailableText}>
            <FormattedMessage
              id={
                marketOfferedDelta && marketOfferedDelta > 0
                  ? 'racing.raceDetails.evenShotTime'
                  : 'racing.evenShot.UnavailableTimeTitleBackup'
              }
              values={{
                time: marketOfferedDelta,
              }}
            />
          </Text>
          <Text {...evenShotStyles.comeBackLaterText}>
            <FormattedMessage id="racing.raceDetails.comeBackLater" />
          </Text>
        </>
      );

    if (isTimePassed && !isLoading && errorCode && isAuthenticated)
      return (
        <>
          <Text {...evenShotStyles.notAvailableText}>
            <FormattedMessage id="racing.raceDetails.evenShotNotAvailable" />
          </Text>
          <Text>
            <FormattedMessage id="racing.raceDetails.selectAnotherRace" />
          </Text>
        </>
      );

    if (hasBets || hasPendingBet) {
      const betRunnerName = formatBetRunnerName(evenShotProposition ?? {});

      return (
        <>
          <Text {...evenShotStyles.betPlacedText}>
            <FormattedMessage
              id={
                hasPendingBet
                  ? 'racing.generic.betAwaitingApproval'
                  : 'racing.generic.betPlaced'
              }
            />
          </Text>
          {!hasPendingBet && hasBets && (
            <Text {...evenShotStyles.oddsText}>
              {formattedPropositionType(
                evenShotProposition?.proposition_type ?? ''
              )}{' '}
              | {betRunnerName} | <FormattedMessage id="racing.generic.odds" />:{' '}
              <Text {...evenShotStyles.oddsValueSpan}>
                {evenShotBet?.bet_odds?.toFixed(2)}
              </Text>
            </Text>
          )}
        </>
      );
    }

    if (isLoading && errorCode && isAuthenticated)
      return (
        <>
          <Text {...evenShotStyles.notAvailableText}>
            <FormattedMessage id="racing.raceDetails.evenShotNotAvailable" />
          </Text>
          <Text>
            <FormattedMessage id="racing.raceDetails.selectAnotherRace" />
          </Text>
        </>
      );

    if (isAuthenticated && !isAuthLoading && !hasBets) {
      const propositionType = formattedPropositionType(
        data?.proposition_type ?? ''
      );

      return (
        <>
          <Text {...evenShotStyles.oddsText}>
            {propositionType} | {runnerName} |{' '}
            <FormattedMessage id="racing.generic.odds" />:{' '}
            <Text data-hasbets={hasBets} {...evenShotStyles.oddsValueSpan}>
              {data?.price?.toFixed(2)}
            </Text>
          </Text>
          <Button
            onClick={handleClick}
            isLoading={isLoading}
            isDisabled={!isRaceOpen}
            data-active={!!isInBs}
            {...evenShotStyles.addToBetslipButton}
          >
            <FormattedMessage id="generic.addtoBetSlip" />
          </Button>
        </>
      );
    }
  };

  return (
    <Flex {...evenShotStyles.flexWrapper}>
      <Text {...evenShotStyles.headingText}>
        <FormattedMessage id="racing.evenShot.heading" />
      </Text>
      <Text
        sx={{ fontWeight: hasBets ? 'bold' : 'normal' }}
        {...evenShotStyles.subHeadingText}
      >
        <FormattedMessage
          id={
            hasBets || hasPendingBet
              ? 'racing.evenShot.betPlaced.heading'
              : 'racing.evenShot.slogan'
          }
        />
      </Text>
      {(hasBets || hasPendingBet) && (
        <Text {...evenShotStyles.hasBetSubTitleText}>
          <FormattedMessage id="racing.evenShot.betPlaced.subHeading" />
        </Text>
      )}

      <Box {...evenShotStyles.evenShotImage} />

      {renderContent()}
    </Flex>
  );
}
