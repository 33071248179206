import { CSSObject, TextProps } from '@chakra-ui/react';

export const RunnerDetails: CSSObject = {
  color: 'white',
};

export const BetReturn = (): CSSObject => ({
  bg: 'gamma.500',
  px: '1',
  py: '1.5',
  lineHeight: '0.5',
});

export const TextBarrierNumber: CSSObject = {
  color: 'white',
};

export const RunnerDescription: CSSObject = {
  color: 'gray.400',
  fontWeight: 'bold',
};

export const TextDescription: CSSObject = {
  color: 'gray.400',
  fontWeight: 'bold',
};

export const dotSeperatorProps: TextProps = {
  color: 'beta.500',
};

export const HeaderSvg: CSSObject = {
  boxSize: '6',
  color: 'gray.400',
};

export const BetMultiIcon: CSSObject = {
  bg: 'gamma.500',
  boxSize: '6',
  color: 'gray.400',
};

export const BetOdds: CSSObject = {
  color: 'white',
};
