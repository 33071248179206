/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { LottieRefCurrentProps } from 'lottie-react';
import { PROMO_URL } from '@/constants/promo';

export class Animation {
  private _isWin: boolean;
  private _brand: string;
  private _animation: string;
  private _config: LuckyDipConfig | null = null;

  constructor(options: AnimationProps) {
    this._isWin = options.isWin;
    this._brand = options.brand;
    this._animation = options.animation;
    this._config = animationConfig[options.animation];
  }

  private async fetchAnimation<T>(): Promise<T> {
    const animationPath = this._config?.getURL(this._brand) ?? '';
    const ani = await fetch(animationPath).then((res) => res.json());
    return ani as T;
  }

  static calculateNewEndYValues() {
    const minEndY = -800;
    const maxEndY = -26;
    const minDistance = 150;

    let endYValue1 = 0;
    let endYValue2 = 0;
    let endYValue3 = 0;

    do {
      endYValue1 =
        Math.floor(Math.random() * (maxEndY - minEndY + 1)) + minEndY;
      endYValue2 =
        Math.floor(Math.random() * (maxEndY - minEndY + 1)) + minEndY;
      endYValue3 =
        Math.floor(Math.random() * (maxEndY - minEndY + 1)) + minEndY;
    } while (
      Math.abs(endYValue1 - endYValue2) < minDistance ||
      Math.abs(endYValue1 - endYValue3) < minDistance ||
      Math.abs(endYValue2 - endYValue3) < minDistance
    );

    const newVals = {
      endYValue1,
      endYValue2,
      endYValue3,
    };
    return newVals;
  }

  private modifyLottieData(lottieData: TAnimationResponse) {
    let endYValue1 = 0;
    let endYValue2 = 0;
    let endYValue3 = 0;
    const possibleWinningValues = [-550, -720, -26, -200, -370];

    if (!this._isWin) {
      // 1 in 3 chance
      const nearMiss = Math.floor(Math.random() * 3) === 0;
      if (nearMiss) {
        const getRandomIndex = () =>
          Math.floor(Math.random() * possibleWinningValues.length);

        const randomIndex1 = getRandomIndex();
        let randomIndex2 = getRandomIndex();
        while (randomIndex2 === randomIndex1) {
          randomIndex2 = getRandomIndex();
        }
        const randomValue1 = possibleWinningValues[randomIndex1];
        const randomValue2 = possibleWinningValues[randomIndex2];
        endYValue1 = randomValue1;
        endYValue2 = randomValue1;
        endYValue3 = randomValue2;
      } else {
        const result = Animation.calculateNewEndYValues();
        endYValue1 = result.endYValue1;
        endYValue2 = result.endYValue2;
        endYValue3 = result.endYValue3;
      }
    } else {
      const randomIndex = Math.floor(
        Math.random() * possibleWinningValues.length
      );
      const selectedValue = possibleWinningValues[randomIndex];

      endYValue1 = selectedValue;
      endYValue2 = selectedValue;
      endYValue3 = selectedValue;
    }

    lottieData.assets.forEach((asset) => {
      if (asset.layers) {
        asset.layers.forEach((layer) => {
          if (
            layer.nm === 'icons_1' ||
            layer.nm === 'icons_2' ||
            layer.nm === 'icons_3'
          ) {
            const positionKeyframes = layer.ks.p.k;
            if (
              Array.isArray(positionKeyframes) &&
              positionKeyframes.length > 0
            ) {
              const originalEndY =
                positionKeyframes[positionKeyframes.length - 1].s[1];
              const newYValue =
                layer.nm === 'icons_1'
                  ? endYValue1
                  : layer.nm === 'icons_2'
                  ? endYValue2
                  : endYValue3;

              const deltaY = newYValue - originalEndY;

              positionKeyframes.forEach((keyframe) => {
                keyframe.s[1] += deltaY;
              });
            }
          }
        });
      }
    });

    lottieData.assets.forEach((asset) => {
      if (asset.layers) {
        const frameName = animationConfig[this._animation].loseLayerName;

        asset.layers.forEach((layer) => {
          if (layer.nm === frameName) {
            if (!this._isWin) {
              layer.op = layer.ip;
            }
          }
        });
      }
    });

    return lottieData;
  }

  public getInitFrames() {
    return animationConfig[this._animation].frames.init;
  }

  public getResultFrames() {
    const resultString = this._isWin ? 'win' : 'lose';
    return animationConfig[this._animation].frames[resultString];
  }

  /**
   * Function specifically for Lucky Dip to get and mutate the data.
   * This could be handled via an init function in the controller depending
   * on animation, however, some race conditions were happening which I
   * didn't have time to debug thoroughly.
   */
  async initAnimation() {
    const animation = await this.fetchAnimation<TAnimationResponse>();
    const modifiedAnimation = this.modifyLottieData(animation);
    return modifiedAnimation;
  }
}

// --- config
const animationConfig: AnimationMapSchema = {
  luckydip: {
    getURL: (brand: string) =>
      `${PROMO_URL}${brand}/luckydip/lottie_${brand}.json`,
    loseLayerName: 'element-strike.png',
    frames: {
      init: [0, 169],
      win: [170, 199],
      lose: [200, 219],
    },
  },
};

// --- types
type LuckyDipConfig = {
  getURL: (prop: string) => string;
  loseLayerName: string;
  frames: {
    init: [number, number];
    win: [number, number];
    lose: [number, number];
  };
};

type AnimationMapSchema = Record<string, LuckyDipConfig>;

export type LottieOnEnterFrameSchema = {
  currentTime: number;
  direction: number;
  totalTime: number;
  type: 'enterFrame';
};

export type OnEnterFrameProps = {
  event: LottieOnEnterFrameSchema;
  element: LottieRefCurrentProps | null;
  onWin?: () => void;
  onLose?: () => void;
};

type AnimationProps = {
  isWin: boolean;
  brand: string;
  animation: string;
};

// --- api response
export type TAnimationResponse = {
  v: string;
  meta: Meta;
  fr: number;
  ip: number;
  op: number;
  w: number;
  h: number;
  nm: string;
  ddd: number;
  assets: Asset[];
  layers: Layer2[];
  markers: any[];
};

type Meta = {
  g: string;
  a: string;
  k: string;
  d: string;
  tc: string;
};

type Asset = {
  id: string;
  w?: number;
  h?: number;
  u?: string;
  p?: string;
  e?: number;
  layers?: Layer[];
};

type Layer = {
  ddd: number;
  ind: number;
  ty: number;
  nm: string;
  refId?: string;
  sr: number;
  ks: Ks;
  ao: number;
  w?: number;
  h?: number;
  ip: number;
  op: number;
  st: number;
  bm: number;
  cl?: string;
  tm?: Tm;
};

type Ks = {
  o: O;
  r: R;
  p: P;
  a: A;
  s: S;
};

type O = {
  a: number;
  k: number;
  ix: number;
};

type R = {
  a: number;
  k: number;
  ix: number;
};

type P = {
  a: number;
  k: any[];
  ix: number;
};

type A = {
  a: number;
  k: number[];
  ix: number;
};

type S = {
  a: number;
  k: any[];
  ix: number;
};

type Tm = {
  a: number;
  k: number;
  ix: number;
};

type Layer2 = {
  ddd: number;
  ind: number;
  ty: number;
  nm: string;
  cl?: string;
  refId: string;
  sr: number;
  ks: Ks2;
  ao: number;
  ip: number;
  op: number;
  st: number;
  bm: number;
  w?: number;
  h?: number;
};

type Ks2 = {
  o: O2;
  r: R2;
  p: P2;
  a: A2;
  s: S2;
};

type O2 = {
  a: number;
  k: any;
  ix: number;
};

type R2 = {
  a: number;
  k: number;
  ix: number;
};

type P2 = {
  a: number;
  k: number[];
  ix: number;
};

type A2 = {
  a: number;
  k: number[];
  ix: number;
};

type S2 = {
  a: number;
  k: any[];
  ix: number;
};
