import { css } from 'styled-components';

export const betContainer = css`
  background-color: var(--bc-colors-blackAlpha-200);
  box-shadow: inset 0px 0px 15px 0px var(--bc-colors-blackAlpha-500);
  border-radius: 7px;
  padding: var(--bc-sizes-2) var(--bc-sizes-3);
  margin-bottom: var(--bc-sizes-4);
  display: flex;
  flex-direction: column;
`;

export const betErrorWrapper = css`
  min-height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--bc-sizes-1) var(--bc-sizes-2) 0 var(--bc-sizes-2);
  background-color: ${({ theme }) => theme.palette.warning};
  width: 100%;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
`;

export const betErrorContainer = css`
  display: flex;
`;

export const betErrorText = css`
  margin-bottom: var(--bc-sizes-0-5);
`;

export const betErrorIcon = css`
  width: var(--bc-sizes-2);
  height: var(--bc-sizes-2);
  margin-right: var(--bc-sizes-1);
`;

export const betConfirmationContainer = css`
  min-height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--bc-sizes-2);
  background-color: ${({ theme }) => theme.palette.primary};
  width: 100%;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  margin-bottom: var(--bc-sizes-4);
`;

export const betConfirmationText = css`
  color: ${({ theme }) => theme.palette.secondary};
  font-weight: 500;
`;

export const betConfirmationIcon = css`
  width: var(--bc-sizes-2);
  height: var(--bc-sizes-2);
  margin-right: var(--bc-sizes-1);
  color: ${({ theme }) => theme.palette.secondary};
`;

export const betSlipBetCardStakeInput = css`
  width: 100%;
`;

export const betCardSportIcon = css``;

export const betCardRemoveIcon = css`
  width: var(--bc-sizes-3);
  height: var(--bc-sizes-3);
  color: ${({ theme }) => theme.palette.white};
  margin-left: var(--bc-sizes-2);
  opacity: 0.3;
  cursor: pointer;
`;

export const betHeadingContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--bc-sizes-1);
  align-items: center;
`;

export const betHeadingLeft = css`
  font-weight: 700;
  font-size: var(--bc-fontSizes-xs);
  font-style: italic;
`;

export const betHeadingRight = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const betOddsText = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 300;
`;

export const betInfoContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const betInfo = css`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary};
`;

export const betMarketName = css`
  font-weight: 700;
  font-size: var(--bc-fontSizes-md);
  font-style: italic;
`;

export const betRaceAndLocationName = css``;

export const betFormContainer = css`
  display: flex;
  flex-direction: column;
`;

export const betFormHeadingContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const betFormStakeHeading = css`
  width: 33%;
  margin-bottom: var(--bc-sizes-1);
  font-weight: 600;
  font-size: var(--bc-fontSizes-2xs);
`;

export const betFormInputsWrapper = css`
  display: flex;
  flex-direction: row;
`;

export const betFormBetTypeContainer = css`
  flex: 1;
  margin-right: var(--bc-sizes-1);
`;

export const betWinTypeText = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 400;
  align-self: center;
  color: ${({ theme }) => theme.palette.white};
  margin-top: var(--bc-sizes-3);
`;

export const betFormStakeContainer = css`
  width: 33%;
`;

export const betFooterContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const potentialReturnsText = css`
  font-size: var(--bc-fontSizes-sm);
  font-weight: 400;
`;

export const inReviewBetTypeText = css`
  font-size: var(--bc-fontSizes-md);
  margin-bottom: var(--bc-sizes-8);
`;

export const inReviewBetStakeText = css`
  font-size: var(--bc-fontSizes-md);
  margin-bottom: var(--bc-sizes-8);
`;
