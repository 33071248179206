import { CSSObject } from '@chakra-ui/react';
import maintenanceBg from '../../../assets/betgalaxy/images/backgrounds/background.png';

export const LocationNotAllowedContainer: CSSObject = {
  backgroundImage: `url(${maintenanceBg}), linear-gradient(var(--bc-colors-gamma-500) 0%, var(--bc-colors-gamma-500) 100%)`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

export const FlexHeaderContainer: CSSObject = {
  h: '90',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.700',
};

export const ContentHeadingText: CSSObject = {
  fontSize: ['xl', null, '4xl'],
  fontStyle: 'italic',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 'black',
};

export const ContentBodyText: CSSObject = {
  color: 'white',
};

export const SubHeader: CSSObject = {
  color: 'white',
};

export const ContactText: CSSObject = {
  color: 'gamma.100',
};
