import { CSSObject } from '@chakra-ui/react';
import background from '../../../../../../assets/sterlingparker/images/backgrounds/overviewBg.png';
import backgroundRetina from '../../../../../../assets/sterlingparker/images/backgrounds/overviewBg@2x.png';
import backgroundMobile from '../../../../../../assets/sterlingparker/images/backgrounds/overviewBgMobile.png';
import backgroundMobileRetina from '../../../../../../assets/sterlingparker/images/backgrounds/overviewBgMobile@2x.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    sx: {
      '&&': {
        h: 8,
        fontSize: 'sm',
        px: 3,
        lineHeight: '15px',
      },
    },
  },
};

export const FlexBannerContainer: CSSObject = {
  bgImage: [`url(${backgroundMobile})`, `url(${background})`],
  bgPos: [null, null, 'center'],
  bgRepeat: 'no-repeat',
  bgSize: '100% 100%',
  borderRadius: 'base',
  color: 'white',
  gap: ['2', null, '0'],
  px: ['4', null, '2.5'],
  py: ['4', null, '2.5'],
  boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.35)',
  filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.1))',
  overflow: 'hidden',
  minH: '106px',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: [`url(${backgroundMobileRetina})`, `url(${backgroundRetina})`],
  },
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '2',
};

export const FlexAccountName: CSSObject = {
  fontSize: ['lg', null, 'xl'],
  fontFamily: 'Roboto',
  fontWeight: 'semibold',
  color: 'alpha.500',
  letterSpacing: '0.8px',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '-2'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  mb: '0.5',
  textTransform: 'uppercase',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'beta.500',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: 'xs',
  verticalAlign: 'super',
  color: 'alpha.500',
});

export const FlexLabel: CSSObject = {
  w: ['100%', null, 'auto'],
};

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: 'xl',
  color: 'alpha.500',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: 'md',
  opacity: '0.7',
  color: 'alpha.500',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  alignItems: 'center',
  gap: '2',
};

export const TextButton: CSSObject = {
  h: '4',
  lineHeight: '13px',

  _active: {
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
    textShadow: 'none',
    bgClip: 'text',
  },
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  borderRadius: 'base',
  border: '2px',
  borderColor: 'white',
  lineHeight: '4',
  flex: ['1', null, '0'],
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: ['9', null, '30px'],
  justifyContent: 'center',
  px: '1.5',
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: [null, null, 'nowrap'],
};
