import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '4'],
  mb: ['2', null, 'unset'],
  mt: 'unset',
  fontFamily: 'accent',
  borderRadius: 'lg',
  bg: 'rgb(54, 54, 54)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'alpha.500',
  mr: '0',
  textAlign: 'center',
  fontSize: 'xs',
  fontWeight: 'semibold',
  textTransform: 'uppercase',
  width: 'full',
  _hover: {
    opacity: 0.9,
  },
};
