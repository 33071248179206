import React from 'react';
import { Show } from '@chakra-ui/react';
import Toggler from '@/views/Home/conditionals/Toggle/template.bet777';

export const TemplateToggle = [
  { name: 'wrapper', component: <Show below="lg" /> },
  { name: 'toggle', component: <Toggler /> },
];

export default TemplateToggle;
