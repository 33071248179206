import {
  footerBaseStyle,
  TFooterTheme,
} from '../../base/customComponents/footer';
import { TStyleConfig } from '../../base/types';

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: () => ({
    ...footerBaseStyle,
    footer: {
      ...footerBaseStyle.footer,
      color: 'alpha.900',
      px: 4,
      maxW: 'container.xl',
      mx: 'auto',
    },
    stackGap: '1',
    footerBar: {
      display: 'flex',
      flexDir: ['column', null, 'row'],
      pr: '0',
      h: '70px',
      w: 'full',
      mb: ['6', null, '0'],
      alignItems: 'center',
      justifyContent: ['center', null, 'unset'],
      '& > img': {
        maxW: '200px',
        h: '35px',
        ml: '0',
      },
    },
    footerLink: {
      color: 'yellow.500',
      textTransform: 'lowercase',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      display: 'inline',
    },
    footerLinkTel: {
      color: 'yellow.500',
      textTransform: 'lowercase',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      display: 'inline',
    },
    customFooterLink: {
      color: 'yellow.500',
      textTransform: 'capitalize',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      display: 'inline',
      fontSize: 'sm',
      pb: '0.5',
    },
    footerLinks: {
      ml: ['unset', null, 'auto'],
      mt: ['4', null, '4'],
      pb: ['4', null, '0'],
      alignItems: 'end',
      color: 'white',
      fontWeight: 'medium',
      justifyContent: ['space-around', null, 'unset'],
      flexDir: ['row', null, 'unset'],
      display: 'inline-flex',
    },
    footerContentContainer: {
      px: ['8', 'unset'],
      ...footerBaseStyle.footerContentContainer,
      color: 'white',
      justifyContent: 'space-between',
      '&': {
        flexDirection: ['column-reverse', null, 'row-reverse'],
      },
    },
    nav: {
      ...footerBaseStyle.nav,
      fontSize: 'sm',
      minW: [null, null, '370px'],
      mt: ['8', 'unset'],
      display: ['flex', 'grid'],
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto',
      columnGap: '10',
      rowGap: '4',
      alignItems: ['center', 'end'],
      gridTemplateAreas: `
          ". ."
        `,

      '> a': {
        color: 'inherit',
      },
    },
    blurb: {
      ...footerBaseStyle.blurb,
      flex: 'unset',
      textAlign: 'left',
      mb: '4',
      ml: [null, '0'],
      mr: [null, null, '4'],
    },
    blurbHeadingAge: {
      ...footerBaseStyle.blurbHeadingAge,
      mb: '2',
    },
    blurbHeadingThink: {
      fontSize: 'md',
      fontWeight: 'bold',
      maxW: '410px',
      mb: '4',
    },
    blurbContent: {
      ...footerBaseStyle.blurbContent,
      ml: 'unset',
      mr: 'auto',
    },
    copyright: {
      ...footerBaseStyle.copyright,
      textAlign: 'left',
      mt: ['7'],
      pt: '6',
      pb: '2',
      fontWeight: 'light',
      color: 'white',
    },
    container: {
      '&&': {
        px: ['0', '2', null, '4'],
      },
    },
  }),
  sizes: {},
  variants: {},
};
