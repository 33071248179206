import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { useInfiniteQueryTransactions } from '@/api/transactions/transactions.hooks';
import { TableTextType } from '../types';
import {
  EBetStatus,
  EEventType,
  ETransactionSubType,
  ETransactionType,
  TTransaction,
} from '@/lib/DBModels';
import { formatDateMonthYear } from '@/lib/Time';
import { centsToDollars } from '@/helpers/utils';

dayjs.extend(utc);
dayjs.extend(tz);

export const useWinningsStake = (trans: TTransaction) => {
  const intl = useIntl();
  return trans.transaction_type === ETransactionType.Winnings
    ? ` • ${intl.formatMessage({ id: 'generic.stake' })}: ${centsToDollars(
        trans.details?.bet_stake
      )}`
    : '';
};

export const useTransaction = (trans: TTransaction) => {
  const intl = useIntl();
  const winningsStake = useWinningsStake(trans);

  const getFormattedTransactionDetails = () => {
    const isRace = trans.details?.event_type === EEventType.Race;
    let marketName = trans.details?.market_name;
    const priceType = trans.details?.proposition_details?.price_type;
    const propositionType =
      trans.details?.proposition_details?.proposition_type;

    const isWinMarket =
      trans.details?.market_type?.toLowerCase() === 'racing win';

    if (marketName && priceType === 'tote_single_mid') {
      marketName = intl.formatMessage({
        id: isWinMarket ? 'generic.toteWinMarket' : 'generic.totePlaceMarket',
      });
    }

    if (marketName && priceType === 'mystery_bet')
      marketName = `Mystery Bet | ${propositionType}`;

    if (marketName && priceType === 'tote_single_best') {
      marketName = intl.formatMessage({
        id: isWinMarket
          ? 'generic.bestToteWinMarket'
          : 'generic.bestTotePlaceMarket',
      });
    }

    if (marketName && priceType === 'starting') {
      marketName = intl.formatMessage(
        { id: 'generic.marketNameWithSP' },
        { marketName }
      );
    }

    if (trans.transaction_type === ETransactionType.Adjustment) {
      return [trans.details?.reason];
    }

    if (
      trans.transaction_type === ETransactionType.Deposit ||
      trans.transaction_type === ETransactionType.Withdrawal ||
      (trans.transaction_type === ETransactionType.Credit &&
        trans.details?.method !== 'Treasure Hunt Promotion' &&
        (trans.details?.method !== 'Money Back' ||
          (trans.details?.method === 'Money Back' &&
            trans.amount &&
            trans.amount < 0)))
    ) {
      const formattedWithdrawalDeposit = `${
        trans.details?.method
      } - ${intl.formatMessage({ id: 'account.transactions.id' })} ${
        trans.transaction_id
      }`;
      return [formattedWithdrawalDeposit];
    }

    const startTime = isRace
      ? trans.details?.race_start_time
      : trans.details?.match_start_time;

    const eventDateTime = `${intl.formatMessage({
      id: 'account.transactions.eventDateTime',
    })}: ${dayjs
      .utc(startTime)
      .tz(dayjs.tz.guess())
      .format('DD/MM/YYYY HH:mm:ss (z)')}`;

    /** @example Nottingham - R2 */
    const formattedEventDetail =
      trans.details?.event_type === EEventType.Race
        ? `${trans.details?.race_type} - ${trans.details?.venue_name} - R${trans.details?.race_number}`
        : [trans.details?.sport_name, trans.details?.match_name]
            .filter(Boolean)
            .join(' - ');

    /** @example 6. Surrey Noir @2.90 */
    const getFormattedPropositionDetail = () => {
      const propDetails = trans.details?.proposition_details;
      const proposition =
        trans.details?.event_type === EEventType.Race
          ? `${propDetails?.runner_number}. ${propDetails?.runner_name}`
          : propDetails?.proposition_name;
      const hasBoostedOdds = trans.details?.promos?.token_type === 'odds-boost';

      if (
        trans?.details?.bet_status?.toLowerCase() === EBetStatus.Rejected &&
        propDetails?.price_type === 'mystery_bet'
      )
        return undefined;
      return `${[proposition, propDetails?.price?.toFixed(2)]
        .filter(Boolean)
        .join(' @')}${winningsStake} ${
        hasBoostedOdds ? ' (Boosted Odds)' : ''
      }`;
    };

    /** @example Bet ID: c8842d4d-59cf-4d0c-9833-679fc7312a8e */
    const formattedId = trans.details?.bet_id
      ? `${intl.formatMessage({ id: 'account.transactions.betId' })}: ${
          trans.details.bet_id
        }`
      : `${intl.formatMessage({ id: 'account.transactions.id' })}: ${
          trans.transaction_id
        };`;

    const { bet_odds: betOdds } = trans.details;
    const formattedExoticMarketDetail = `${
      trans.details?.boxed
        ? `${intl.formatMessage({ id: 'account.transactions.exotics.boxed' })} `
        : ''
    }${trans.details?.exotics_sub_type} (${
      trans.details?.boxed
        ? `${trans.details?.selections?.[0]}`
        : `${trans.details?.selections?.join(' | ')}`
    }) ${betOdds ? `@${betOdds}` : ''}${winningsStake}`;

    const formattedMultiLegCount = `${
      trans.details?.leg_details?.length
    } ${intl.formatMessage({
      id: 'account.transactions.legs',
    })}`;

    function getPriceTypeString(type: string) {
      switch (type) {
        case 'even_shot':
          return `Even Shot - ${trans.details?.proposition_details?.proposition_type}`;
        case 'tote_single_mid':
          return null;
        case 'mystery_bet':
          return intl.formatMessage({ id: 'generic.mysteryBet' });
        default:
          return trans.details?.market_name || null;
      }
    }

    if (trans.details?.method === 'Money Back') {
      return [
        formattedEventDetail,
        intl.formatMessage({ id: 'generic.moneyBack' }),
        eventDateTime,
        priceType !== 'even_shot' ? marketName : null,
        getFormattedPropositionDetail(),
        formattedId,
      ];
    }

    if (trans.details?.method === 'Treasure Hunt Promotion') {
      return [
        formattedEventDetail,
        'Treasure Hunt Promotion',
        eventDateTime,
        priceType !== 'even_shot' ? marketName : null,
        getFormattedPropositionDetail(),
        formattedId,
      ];
    }

    switch (trans?.sub_type) {
      case ETransactionSubType.Exotics:
        return [
          formattedEventDetail,
          formattedExoticMarketDetail,
          eventDateTime,
          formattedId,
        ];
      case ETransactionSubType.Multi:
        return [formattedMultiLegCount, formattedId];

      /**
       * @example
       *
       * Nottingham - R2
       * Racing Win
       * 6. Surrey Noir @ 2.90 * Stake: $10.00
       * Bet ID: c8842d4d-59cf-4d0c-9833-679fc7312a8e
       */
      case ETransactionSubType.Single:
        return [
          formattedEventDetail,
          getPriceTypeString(priceType ?? ''),
          eventDateTime,
          priceType !== 'even_shot' ? marketName : null,
          getFormattedPropositionDetail(),
          formattedId,
        ].filter(Boolean);
      default:
        return [];
    }
  };

  const getFormattedTransactionType = () => {
    const extraInfo: (string | undefined)[] = [];
    if (
      // Winning bets have a transaction type of 'Winnings',
      // so any transaction of type 'Bet' with a positive amount
      // should be assumed to be a refund of some sort
      trans.transaction_type === ETransactionType.Bet &&
      trans.amount &&
      trans.amount > 0
    ) {
      // extraInfo initially had a number of possible entries,
      // hence the .join and appending below. That's no longer the case
      // but I'm leaving this logic here in case more info ends up being suffixed
      extraInfo.push(
        intl.formatMessage({ id: 'account.transactions.type.refund' })
      );
    }
    return `${intl.formatMessage({
      id: `account.transactions.type.${trans.transaction_type?.toLowerCase()}`,
    })}${extraInfo.length ? ` (${extraInfo.join(' - ')})` : ''}`;
  };

  const transactionPolarity: TableTextType =
    trans?.amount && trans?.amount >= 0 ? 'positive' : 'negative';
  const transDate = formatDateMonthYear(trans?.timestamp);
  const transDateTime = dayjs(trans?.timestamp).format('HH:mm:ss (z)');
  const transRefundVoid = trans?.transaction_type === ETransactionType.Void;

  return {
    transactionPolarity,
    transDate,
    transDateTime,
    transRefundVoid,
    formattedTransactionType: getFormattedTransactionType(),
    formattedTransactionDetails: getFormattedTransactionDetails(),
  };
};

export const useTransactions = (isBonus: boolean) => {
  const limit = 20;

  const {
    data,
    isInitialLoading,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQueryTransactions({
    params: {
      limit,
      is_bonus: isBonus,
    },
    options: {
      getNextPageParam: (_lastPage, pages) => pages.length ?? undefined,
      staleTime: 60 * 1000,
      refetchOnMount: false,
    },
  });

  // 👇 Check if the last pages returns records
  const hasMoreData = data?.pages?.map((el) => el.length ?? 0 - 1).pop();

  // Downloading of transactions is disabled for P1.
  //
  // const handleDownloadTransactions = async () => {
  //   const data = await getTransactions();
  //   if (!data) return;

  //   setProcessingDownload(true);
  //   const contentType = 'text/csv';

  //   const dateTime = dayjs().format('DD/MM/YYYY_HH:mm:ss');
  //   const fileName = `Transactions_${dateTime}`;

  //   // Create blob link to download
  //   const csvFile = window.URL.createObjectURL(
  //     new Blob([data], { type: contentType })
  //   );
  //   const link = document.createElement('a');
  //   link.href = csvFile;
  //   link.setAttribute('download', `${fileName}.csv`);

  //   // Append to html link element page
  //   document.body.appendChild(link);

  //   // Start download
  //   link.click();

  //   // Clean up and remove the link
  //   link.parentNode?.removeChild(link);

  //   setProcessingDownload(false);
  // };

  return {
    data,
    isInitialLoading,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    hasMoreData,
  };
};
