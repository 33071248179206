import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';
import rune from '@/assets/vikingbet/images/backgrounds/rune.png';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    display: 'inline-block',
    fontFamily: 'SkranjiBold',
    bgGradient: 'linear(180deg, delta.400, delta.600)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    border: '2px',
    borderColor: 'delta.700',
    color: 'gamma.700',
    textShadow: '0px 1px 2px rgba(255, 255, 255, 0.40)',
    borderRadius: 'lg',
    fontWeight: 'medium',
    fontSize: '2xs',
    _hover: {
      opacity: 0.5,
    },
    _disabled: {
      bg: 'blackAlpha.200',
      borderColor: 'transparent',
      boxShadow: 'none',
      color: 'gray.600',
      cursor: 'not-allowed',
      fontSize: '2xs',
      fontWeight: 'normal',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
      _before: { opacity: '0' },
      _hover: {
        opacity: 1,
      },
    },
    sx: {
      '&': {
        minW: '4.5rem',
      },
      '&:active, &[data-active="true"]': {
        bgImage: `url(${rune})`,
        borderX: '2px',
        borderColor: 'black',
        border: 'none',
        boxShadow:
          '0px 2px 0px 1px rgba(0, 0, 0, 0.55) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 0px 0px rgba(0, 0, 0, 0.55) inset',
        bgRepeat: 'no-repeat',
        bgSize: 'cover',
        bgPosition: 'center',
        color: 'delta.300',
        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.45)',
        _before: { opacity: '0' },
        _hover: {
          opacity: 1,
        },
      },
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    zIndex: 5,
    mt: '0.5',
  },
  buttonBaseBox: {
    fontSize: 'sm',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
