import { OnRaceBetsSchema } from './OnRaceBets.styles';

export const onRaceBetStyles: OnRaceBetsSchema = {
  wrapper: {
    mb: '2',
    bgGradient: 'linear(to-b, alpha.200, alpha.400)',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset,0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    border: 'node',
    borderRadius: 'md',
    flexDirection: 'column',
    py: '2',
  },
  headerWrapper: {
    px: '2.5',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    mx: '1',
  },
  headerText: {
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accentStaat',
    color: 'gamma.200',
  },
  headerCountText: {
    ml: '1',
    fontSize: '2xs',
    fontWeight: 'bold',
    color: 'alpha.700',
    bg: 'gamma.500',
    p: '0.5',
    lineHeight: 'normal',
    borderRadius: '3px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    mb: '1.5',
  },
  headerExpandButton: {
    variant: 'unstyled',
    ml: 'auto',
    color: 'gamma.500',
    boxSize: '7',
    minW: 'unset',
  },
  toggleIcon: {
    boxSize: '4',
  },
  betsContainer: {
    flexDirection: 'column',
  },
  betCategoryWrapper: {
    alignItems: 'center',
    mt: '2',
  },
  betCategory: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'alpha.700',
  },
  betCategoryCount: {
    fontSize: 'xs',
    fontWeight: 'semibold',
    color: 'alpha.700',
    ml: '1',
  },
  divider: {
    borderWidth: 'px',
    opacity: '1',
    borderColor: 'blackAlpha.400',
    mt: '1.5',
  },
  betRow: {
    py: '2',
    px: '2',
    gap: '2',
    bg: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    borderRadius: 'base',
    mb: '1',
    _last: {
      mb: '0',
    },
  },
  betRowDivider: {
    bg: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    borderRadius: 'base',
    mb: '1',
    p: '2',
    _last: {
      mb: '0',
    },
  },
  groupDivider: {
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.400',
  },
  categoryIcon: {
    color: 'gamma.500',
    boxSize: '5',
  },
  groupContainer: {
    flexDir: 'column',
    py: 2,
    px: '3',
    border: 'none',
    boxShadow: 'none',
  },
  groupTitleText: {
    fontFamily: 'accentStaat',
    color: 'gamma.200',
    fontSize: 'sm',
    fontWeight: 'normal',
    mb: '1',
  },
  runnerText: {
    color: 'gamma.200',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  runnerBarrierText: {
    color: 'gamma.200',
    fontSize: '2xs',
    ml: '0.5',
  },
  stakeText: {
    color: 'gamma.500',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  potentialWinningsText: {
    color: 'gamma.500',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  subtitleText: {
    color: 'gamma.200',
    fontSize: 'xs',
    fontWeight: 'normal',
  },
  exoticsPositionText: {
    color: 'gamma.200',
    fontSize: 'xs',
    fontWeight: 'normal',
    w: 9,
  },
  viewMoreButton: {
    alignItems: 'center',
    justifyContent: 'center',
    mb: -2,
    borderTopRadius: 0,
    height: 8,
    lineHeight: 'normal',
  },
  viewMoreButtonText: {
    fontWeight: 'black',
    fontSize: 'sm',
    textTransform: 'uppercase',
  },
  srmBlendedLegContainer: {
    flexDir: 'row',
    flex: '1',
  },
  srmBlendedRunnerText: {
    width: 32,
    fontWeight: 'bold',
    color: 'gamma.200',
    fontSize: 'xs',
    textTransform: 'capitalize',
  },
  srmMarketText: {
    fontWeight: 'bold',
    color: 'gamma.200',
    fontSize: 'xs',
  },
  srmBlendedWrapper: {
    flexDir: 'column',
    flex: 1,
  },
  flexiText: {
    fontWeight: 'normal',
    color: 'gamma.200',
    fontSize: 'xs',
  },
  flexiStakeText: {
    width: '16',
    color: 'gamm.200',
  },
  flexiStakeValueText: {
    color: 'gamma.200',
    fontWeight: 'normal',
  },
};
