import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  borderColor: 'transparent',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '2',
    bg: 'rgba(78, 178, 255, 0.14)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: 'md',
  },
  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '2px solid',
    borderTopColor: 'whiteAlpha.200',
    pt: '3',
    '.chakra-text': {
      _first: {
        color: 'white',
        fontWeight: 'medium',
      },
      _notFirst: {
        borderRadius: 'base',
        bg: 'blackAlpha.400',
        color: 'whiteAlpha.700',
        opacity: '0.5',
        border: 'none',
      },
    },
  },
};
export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontFamily: 'accent',
  fontSize: 'sm',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'white',
  },
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
};
export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
  },
};
export const AccordionHeaderLogOut: CSSObject = {
  bg: 'rgba(78, 178, 255, 0.14)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  border: 'none',
  mt: '-2',
  px: '2',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
export const IconPerson: CSSObject = {
  boxSize: ['5', null, '5'],
  color: 'white',
  mr: '1.5',
};
export const TextAccordion: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  mr: 'auto',
  mt: '0.5',
};
export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'beta.700',
  borderRadius: 'base',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  fontSize: 'xs',
  fontWeight: 'bold',
  gap: '1',
  p: '2',
  '.chakra-text': {
    color: 'var(--bc-colors-white) !important',
  },
  '.chakra-icon': {
    color: 'white',
  },
};
export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  border: 'none',
  mb: '0',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};
