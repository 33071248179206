import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const primary = {
  color: 'white',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  transition: 'all .3s linear',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  border: '2px',
  borderColor: 'alpha.700',
  boxShadow: 'inset 0px 0px 0px 2px #426DD6',
  lineHeight: '1',
  fontSize: 'sm',
  p: '1.5',

  '&:hover': {
    bg: 'alpha.700',
    borderColor: 'alpha.500',
    boxShadow: 'lg',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    color: 'gray.600',
    borderColor: 'gray.800',
    boxShadow: 'lg',
    pointerEvents: 'none',
  },

  _focus: {
    boxShadow: '0 2px 5px rgba(0, 0, 0, .15)',
  },

  '&:active, &[data-active="true"]': {
    bg: 'gamma.600',
    border: 'none',

    color: 'white',
    textShadow: 'none',
    boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',

    _focus: {
      boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
    },
    _hover: {
      boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
    },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      label: 'btn-variant-solid',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      label: 'btn-variant-primary',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      label: 'btn-variant-secondary',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      border: '2px',
      borderColor: 'alpha.700',
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      boxShadow: 'inset 0px 0px 0px 2px #426DD6',
      alignItems: 'center',

      _hover: {
        bgGradient: 'linear(to-b, alpha.600, alpha.700)',
      },

      _active: {
        bg: 'gamma.600',
        borderColor: 'transparent',
        boxShadow: 'inset 0 3px 3px 3px rgba(0, 0, 0, .3)',
        color: 'white',
        textShadow: 'none',
        _after: { opacity: '0' },
      },

      _disabled: {
        bg: 'blackAlpha.200',
        borderColor: 'gray.800',
        boxShadow: 'none',
        color: 'gray.600',
        cursor: 'not-allowed',
        fontSize: 'xs',
        fontWeight: 'semibold',
        textShadow: 'none',
        _after: { opacity: '0' },
      },

      ...(props.colorScheme && colorScheme(props)),
    }),
    tertiary: (props: ButtonProps) => ({
      color: 'delta.800',
      textShadow: '0px 1px 1px rgba(247, 215, 31, 0.70)',
      fontFamily: 'CinzelDecorative',
      fontWeight: 'black',
      fontSize: 'sm',
      minW: '28',
      alignItems: 'flex-start',
      animation: '.5s ease-in-out',
      borderRadius: 'lg',
      border: '2px',
      borderColor: 'delta.700',
      bgGradient: 'linear(to-b, delta.400, delta.600)',
      boxShadow: 'inset 0px 0px 0px 2px var(--bc-colors-delta-300)',
      height: ['9', null, '12'],
      lineHeight: '0.9',
      px: [2, null, null, null, 5],

      _hover: { bgGradient: 'linear(to-b, delta.400, delta.400)' },

      '&:active, &[data-active="true"]': {
        bg: 'gamma.600',
        border: 'none',

        color: 'white',
        textShadow: 'none',
        boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',

        _focus: {
          boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
        },
        _hover: {
          boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
        },
      },

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      label: 'btn-variant-outline',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'white',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'full',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    unstyled: () => ({
      label: 'btn-variant-unstyled',
      height: 'auto',
      fontWeight: 'inherit',
    }),
    ghost: {
      ...primary,
      label: 'btn-variant-ghost',
      bg: undefined,
      boxShadow: undefined,
      textShadow: undefined,

      _hover: {
        bg: 'alpha.50',
      },

      _focus: undefined,

      _active: {
        bg: 'alpha.100',
      },
    },
    filter: {
      border: '2px',
      borderColor: 'whiteAlpha.300',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',
      _hover: {
        color: 'beta.300',
      },
      _active: {
        border: 'none',
        bgGradient: 'linear(to-b, delta.400, delta.500)',
        color: 'delta.900',
      },
    },
    segment: {
      label: 'btn-variant-segment',
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',
      _hover: {
        color: 'alpha.500',
      },
      _active: {
        bgGradient: 'linear(225deg, delta.500, delta.500)',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
    tab: {
      color: 'white',
      bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
      fontWeight: 'semibold',
      fontSize: '2xs',
      textTransform: 'uppercase',
      borderRadius: 'lg',
      px: '2',
      py: '1',
      minW: 'fit-content',
      h: '6',
      transition: 'all .2s ease-in-out',

      boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
      '&:hover, &:focus': {
        color: 'white',
        bg: 'linear-gradient(180deg, var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
      },

      '&:active, &[data-active="true"]': {
        color: 'beta.900',
        bg: 'blackAlpha.400',

        '&:hover, &:focus': {
          color: 'gray.700',
          bg: 'blackAlpha.500',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
