import {
  chakra,
  ChakraComponent,
  ButtonProps,
  Modal as ChakraModal,
  ModalProps,
  ModalOverlay as ChakraModalOverlay,
  ModalOverlayProps,
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps,
  ModalBody as ChakraModalBody,
  ModalBodyProps,
} from '@chakra-ui/react';
import React from 'react';
import { styleImports } from './InfoModal.styles.imports';
import { EThemesOG } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';

const themeName = getThemeName();

export const Modal = (props: ModalProps) => {
  const size =
    themeName === EThemesOG.Betroyale || EThemesOG.Volcanobet ? 'xs' : 'lg';
  return <ChakraModal size={size} {...props} />;
};

export const ModalOverlay: ChakraComponent<'div', ModalOverlayProps> = chakra(
  ChakraModalOverlay,
  {
    label: 'DepositInfoModalOverlay',
    baseStyle: ({ theme }) => ({
      ...(() => {
        try {
          return styleImports[themeName]?.ModalOverlay?.(theme);
        } catch (err) {
          return {};
        }
      })(),
    }),
  }
);

export const ModalHeader: ChakraComponent<'header', ModalHeaderProps> = chakra(
  ChakraModalHeader,
  {
    label: 'DepositInfoModal',
    baseStyle: {
      fontSize: 'md',

      ...(() => {
        try {
          return styleImports[themeName]?.ModalHeader;
        } catch (err) {
          return {};
        }
      })(),
    },
  }
);

export const ModalBody: ChakraComponent<'div', ModalBodyProps> = chakra(
  ChakraModalBody,
  {
    label: 'DepositInfoModalBody',
    baseStyle: {
      pt: '0.5',

      ...(() => {
        try {
          return styleImports[themeName]?.ModalBody;
        } catch (err) {
          return {};
        }
      })(),
    },
  }
);

export const ButtonDeposit = chakra(Button, {
  label: 'DepositInfoButtonDeposit',
  baseStyle: () => ({
    w: '100%',
    mt: '5',

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonDeposit;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;
