import { CSSObject } from '@chakra-ui/react';

export const HeaderText: CSSObject = {
  color: 'gray.700',
  fontSize: 'md',
  p: '0',
  textTransform: 'none',
};

export const HeaderContainer: CSSObject = {
  pb: '2',
  pt: '4',
  px: '4',
};

export const CloseButton: CSSObject = {
  display: 'none',
};

export const ContentContainer: CSSObject = {
  color: 'gray.600',
  pb: '4',
  pt: '1',
  px: '4',
};

export const ModalBoxWrapper: CSSObject = {
  borderRadius: 'xl',
};
