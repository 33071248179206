import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  fontFamily: 'Spectral',
  fontSize: 'md',
  fontWeight: 'extrabold',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
  color: 'gamma.300',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'white',
  minW: 'unset',
  bgGradient: 'linear(180deg, beta.400 0%, beta.600 100%)',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.30), 0px 1px 0px 0px rgba(255, 255, 255, 0.35) inset',
  transition: 'all .3s ease-in-out',
  _hover: {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.30), 0px 1px 0px 0px rgba(255, 255, 255, 0.35) inset',
    bg: 'beta.700',
  },

  '& svg': {
    color: 'rgba(0, 0, 0, 0.64)',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.40)',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  fontWeight: 'bold',
  color: 'white',
};
