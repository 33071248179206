import { CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
};

export const DepositButton: CSSObject = {
  fontSize: 'xs',
};
