import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NoResultsText } from '../styles/MyBets.styles';

type TNoResults = {
  filterName: string;
};

export default function NoResults({ filterName }: TNoResults) {
  return (
    <NoResultsText>
      <FormattedMessage
        id="account.mybets.nobetstext"
        values={{ filterName: filterName.toLowerCase() }}
      />
    </NoResultsText>
  );
}
