import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/templebet/images/racing/MYSTERYBET.svg';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: {
    variant: 'odds',
    h: '34px',
    sx: {
      span: {
        color: 'beta.500',
        textShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-100)',
      },
    },
  },
  imageLogo: {
    w: '250px',
    src: mysteryLogo,
    my: 1,
  },
  iconMystery: {},
  flexWrapperMysteryLabelView: {
    color: 'white',
    fontFamily: 'BebasNeue',
    textShadow: '0px 2px 4px var(--bc-colors-whiteAlpha-400)',
    fontWeight: 'normal',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
  },
  buttonAddToBetslip: {
    variant: 'primary',
    h: '34px',
    fontSize: 'sm',
    sx: {
      '&&': {
        borderLeftRadius: 'none',
        ms: '0',
      },
      _disabled: {
        bg: 'beta.900',
        color: 'beta.500',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.25)',
        '&&:hover': {
          bg: 'beta.900',
          color: 'beta.500',
        },
      },
    },
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    h: '34px',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    borderRightRadius: 'none',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-50), 0px 3px 5px 0px var(--bc-colors-blackAlpha-50) inset',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontFamily: 'heading',
    fontWeight: 'normal',
    sx: {
      '&&': {
        px: [2, 3],
      },
    },
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    boxShadow:
      'boxShadow: 0px 1px 0px 0px var(--bc-colors-whiteAlpha-50), 0px 1px 5px 0px var(--bc-colors-blackAlpha-400) inset',
    px: '3',
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
  },
  flexWrapperMysteryPlacedBetView: {
    fontFamily: 'BebasNeue',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
    fontSize: 'sm',
  },
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: ['auto', 'auto', 'full'],
    gap: '2',
    my: '1',
    mt: '2',
    mx: ['1', '1', 'auto'],
    bg: 'delta.700',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-50), 0px 1px 5px 0px var(--bc-colors-blackAlpha-400) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'delta.700',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-50),0px 1px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-delta-500) 0%, var(--bc-colors-delta-600) 100%)',
    borderRadius: 'md',
    boxSize: '10',
    p: '2',
    cursor: 'pointer',
    h: '33px',
    sx: {
      svg: {
        color: 'beta.500',
        fill: 'beta.500',
        textShadow: '0px 1px 0px var(--bc-colors-whiteAlpha-400)',
      },
    },
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  spanRollOverText: {
    fontFamily: 'BebasNeue',
  },
  flexRaceInfo: {
    fontSize: 'sm',
    color: 'white',
    fontFamily: 'BebasNeue',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textPriceInfo: {
    fontSize: 'sm',
    color: 'beta.500',
    fontFamily: 'BebasNeue',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textRollover: {
    fontSize: 'sm',
    color: 'beta.500',
    fontFamily: 'heading',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textError: {
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px var(--bc-colors-blackAlpha-400)',
    fontStyle: 'normal',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '10',
    color: 'beta.500',
    height: '34px',
  },
  iconRace: { color: 'delta.200', mb: '1.5' },
  textSpanWhite: {
    color: 'white',
  },
  buttonRollOver: {
    variant: 'secondary',
    h: '6',
    boxShadow:
      '0px 4px 6px 0px var(--bc-colors-blackAlpha-500),0px 0px 0px 1px #ffda2f inset,0px 0px 0px 2px #20273f inset,0px 0px 0px 3px var(--bc-colors-whiteAlpha-50) inset',
    color: 'beta.500',
    _disabled: {
      bg: 'rgba(0, 22, 53, 1)',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.25)',
    },
  },
  spanSelectionText: {
    color: 'beta.500',
  },
  spanPositionType: {
    color: 'beta.500',
  },
  textSlash: {
    color: 'whiteAlpha.400',
  },
};
