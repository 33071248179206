import { useEffect, useReducer, useRef } from 'react';

export function useTimeout(callback: () => void, delay: number | null) {
  const [state, reset] = useReducer(() => ({}), {});
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay !== 'number') {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(id);
  }, [delay, state]);

  return { reset };
}
