import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  mb: '2',
  px: 0,
  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  border: 'none',
  borderRadius: 'md',
  bg: 'transparent',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const BoxPageWrapper: CSSObject = {
  px: ['2.5', null, null, '4'],
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.600',
  color: 'white',
  bg: 'alpha.500',
  h: '36px',

  '&&': {
    borderTop: '1px solid',
    borderTopColor: 'whiteAlpha.300',
  },

  _first: {
    borderTopColor: 'transparent',
  },

  _hover: { bg: 'alpha.600' },
};

export const IconSport: CSSObject = {
  color: 'gamma.500',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'white',
  transition: '.3s ease-in-out',
};
