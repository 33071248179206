import React from 'react';
// import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { tabs } from '@/views/account/MyBets/services/MyBets.config';
import { MyBetsFilter } from '@/views/account/MyBets/services/MyBets.hooks';

import {
  buttonAccountTabOptionProps,
  // myBetsToggleStyles,
  TabContainer,
  TabWrapper,
} from './styles/MyBetsToggle.styles';
import { Button } from '@/components/Button/Button';

const MyBetsToggle: React.FC<TMyBetsToggleProps> = ({
  selectedOption,
  onChange,
  // setCashoutOnly,
}) => (
  <TabContainer>
    <TabWrapper role="tablist">
      {tabs.map((option) => (
        <Button
          key={option.display}
          data-cy={`myBets-${option.display}`}
          onClick={() => onChange(option)}
          role="tab"
          {...(selectedOption.value.every(
            (status, i) => status === option.value[i]
          ) && {
            isActive: true,
            'aria-selected': true,
          })}
          {...buttonAccountTabOptionProps}
        >
          {option.display}
        </Button>
      ))}

      {/* Hiding cash out filter for now until we turn the feature flag back on.
      {selectedOption.display === 'Pending' && (
        <Flex {...myBetsToggleStyles.flexWrapper}>
          <Checkbox
            onChange={(e) => setCashoutOnly(e.target.checked)}
            {...myBetsToggleStyles.checkbox}
          >
            <Text {...myBetsToggleStyles.textCashOut}>Cash Out Only</Text>
          </Checkbox>
        </Flex>
      )} */}
    </TabWrapper>
  </TabContainer>
);

export type TMyBetsToggleProps = {
  selectedOption: MyBetsFilter;
  onChange: (betView: MyBetsFilter) => void;
  setCashoutOnly: (e: boolean) => void;
};

export default MyBetsToggle;
