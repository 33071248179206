import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  bg: 'epsilon.500',
  borderTopRadius: 'base',
  p: '2',
  color: 'white',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'epsilon.200',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  border: '1px dashed',
  borderColor: 'epsilon.200',
  color: 'epsilon.200',
  mb: '7',
  bg: 'none',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};
