import React from 'react';
import { Check } from '@styled-icons/fa-solid';
import { ButtonFilter, IconCheck, IconRace } from './styles/Filters.styles';
import { getIconBySportName } from '@/helpers/utils';
import { ERaceType } from '../../../../../lib/DBModels';

type TRaceTypeItem = {
  selected: boolean;
  onClick: () => void;
  raceType: ERaceType;
};

export default function RaceTypeItem({
  selected,
  onClick,
  raceType,
}: TRaceTypeItem) {
  return (
    <ButtonFilter variant="unstyled" selected={selected} onClick={onClick}>
      <IconCheck as={Check} selected={selected} />

      <IconRace name={getIconBySportName(raceType)} selected={selected} />
    </ButtonFilter>
  );
}
