import React, { FC } from 'react';
import { useLocation } from '../../components/Routes/LocationRoute/services/LocationRoute.hooks';
import { createTemplate } from '@/helpers/createTemplate';
import template from './templates/template';

const LocationNotAllowed: FC = () => {
  useLocation(false);

  return <>{createTemplate(template)}</>;
};

export default LocationNotAllowed;
