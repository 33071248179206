import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';

export const SignUpDatePicker: CSSObject = {
  zIndex: 'popover',
};

export const SignupAcknowledgementButton: CSSObject = {
  color: 'alpha.400',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'cyan',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  bgGradient: 'linear(180deg, #FACD33, #EC9E0F)',
  border: '2px solid #A1741F',
  color: 'beta.800',
  boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
  borderRadius: 'md',
  fontFamily: 'SkranjiBold',
  fontWeight: '700',
  letterSpacing: 'wide',
  fontSize: '14px',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  textTransform: 'uppercase',
  minH: '38px',
  _hover: {
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: 'beta.800',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: 'gamma.400',
  p: '3',
  borderRadius: 'base',
  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-gamma-400) !important'
      : 'var(--bc-colors-gamma-400) !important',
  },
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active ? 'white' : 'black',
});

export const BoxManualAddressContainer: CSSObject = {
  color: 'black',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
  'input, #state-select': {
    h: '9',
    borderRadius: '4px',
    border: '1px solid var(--chakra-gray-300, #CBD5E0)',
    background: 'var(--vikingbet-zeta-50, #FDFAF4)',
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    gap: '6px',
  },
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'normal',
  button: {
    h: 'unset',
  },
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);
