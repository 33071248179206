import { CSSObject } from '@chakra-ui/react';
import freshFruitsDesktop from '../../../../../../assets/juicybet/images/backgrounds/freshFruitsDesktop.png';
import freshFruits from '../../../../../../assets/juicybet/images/backgrounds/freshFruits.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    h: 8,
    fontSize: 'xs',
    px: 3,
    borderRadius: 'md',
    lineHeight: '18px',
  },
};

export const FlexBannerContainer: CSSObject = {
  bgImage: [
    `url(${freshFruits}), linear-gradient(180deg, #FFE81C 0%, #F1C300 100%)`,
    null,
    `url(${freshFruitsDesktop}), linear-gradient(180deg, #FFE81C 0%, #F1C300 100%)`,
  ],
  bgPos: ['top right', null, 'bottom right'],
  bgRepeat: 'no-repeat',
  bgSize: ['150px auto, cover', null, '320px auto, cover'],
  color: 'white',
  px: ['5', null, '4'],
  py: ['5', null, '5'],
  pt: ['5', null, '3'],
  pr: '5',
  my: '2',
  gap: ['2', null, '0'],
  boxShadow:
    '0px 0px 4px 5px rgba(0, 0, 0, 0.15) inset, 0px 0px 0px 3px #EFFECB inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: 'lg',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1',
};

export const FlexAccountName: CSSObject = {
  fontSize: ['lg', null, 'xl'],
  fontFamily: 'Roboto',
  fontWeight: '600',
  color: 'gamma.800',
  letterSpacing: '0.8px',
};

export const FlexAccountBalances: CSSObject = {
  gap: '2',
  paddingTop: '2.5',
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  mb: '0.5',
  textTransform: 'uppercase',
  color: 'gamma.800',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  color: 'beta.500',
  display: 'block',
  fontWeight: 'black',
  lineHeight: 'none',
  textShadow: '0 2px 5px rgba(0, 0, 0, .25)',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  fontSize: 'sm',
  verticalAlign: 'super',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  fontSize: '2xl',
  color: 'beta.500',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  fontSize: 'lg',
  opacity: '0.7',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2.5',
};

export const TextButton: CSSObject = {
  h: '4',
};

export const FlexBalanceBetInfo: CSSObject = {
  alignItems: 'center',
  borderRadius: 'md',
  border: '2px',
  borderColor: 'beta.500',
  lineHeight: ['3', null, '5'],
  flex: ['1', null, '0'],
  fontSize: '2xs',
  fontWeight: 'base',
  h: ['9', null, '8'],
  justifyContent: 'center',
  px: '2',
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: [null, null, 'nowrap'],
  color: 'beta.500',
  fontFamily: 'Coiny',
  pt: '0.5',
};

export const FlexLabel: CSSObject = {
  gap: ['3', null, '2'],
};
