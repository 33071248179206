import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/Button/Button';
import {
  BetPropositionClosedError,
  IconPropositionClosed,
} from '../../../Card/styles/BetCard.styles';
import { betCardStyles } from '../../../BetCard/styles/BetCard.styles';

type TBetCardPropClosedProps = {
  onClick: () => void;
};

export function BetCardPropClosed({ onClick }: TBetCardPropClosedProps) {
  return (
    <BetPropositionClosedError>
      <Button
        onClick={onClick}
        {...betCardStyles.buttonBetPropositionClosedProps}
      >
        <IconPropositionClosed />
        <FormattedMessage id="betslip.betslipbetcard.errors.propositionclosed" />
      </Button>
    </BetPropositionClosedError>
  );
}
