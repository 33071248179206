import { CSSObject } from '@chakra-ui/react';
import background from '../../../assets/betprofessor/images/backgrounds/location-unavailable-desktop.png';
import backgroundAtRetina from '../../../assets/betprofessor/images/backgrounds/location-unavailable-desktop@2x.png';
import fishBackground from '../../../assets/betprofessor/images/backgrounds/fish.png';
import fishAtRetina from '../../../assets/betprofessor/images/backgrounds/fish@2x.png';
import chomp from '../../../assets/betprofessor/images/backgrounds/chomp-head.png';
import chompAtRetina from '../../../assets/betprofessor/images/backgrounds/chomp-head@2x.png';

export const FlexPageWrapper = (isMaintenance: boolean): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: `url(${background})`,
  bgColor: 'beta.900',
  bgRepeat: 'no-repeat',
  bgSize: ['cover', '80%'],
  bgPosition: 'center',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${backgroundAtRetina})`,
  },

  _after: {
    w: '100%',
    content: `''`,
    bgImage: `url(${isMaintenance ? chomp : fishBackground})`,
    bgPos: 'bottom',
    bgRepeat: 'no-repeat',
    bgSize: ['278px', '368px'],
    pos: 'absolute',
    bottom: isMaintenance ? '-8dvh' : '0%',
    height: isMaintenance ? ['370px', '600px'] : ['352px', '422px'],

    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bgImage: `url(${isMaintenance ? chompAtRetina : fishAtRetina})`,
    },
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'flex-start',
  mt: ['0', null, '140px'],
  mx: 'auto',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.md',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Dreadful',
  fontSize: '6xl',
  lineHeight: ['70%', null, '10'],
  mt: ['9', null, '4'],
  mb: ['9', null, '4'],
  color: 'alpha.200',
  textShadow: '0px 0px 9px #89E72C',

  b: {
    bgGradient: 'linear(to-b, alpha.500, gamma.500)',
    bgClip: 'text',
    textShadow: 'none',
    fontWeight: 'normal',
  },
};

export const ContentBodyText: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  mb: '0',
  w: ['280px', 'auto'],
};

export const TextRefresh: CSSObject = {
  fontSize: ['md', null, 'sm'],
  mb: ['3', '4'],
  w: ['280px', 'auto'],
};

export const ButtonRefresh: CSSObject = {
  borderColor: 'white',
  boxShadow: 'none',
  color: 'white',

  _hover: {
    borderColor: 'alpha.500',
    boxShadow: 'none',
    color: 'alpha.500',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};
