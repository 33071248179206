import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: [0, null, 3],
    columnGap: '3.5',
  },
  FlexCard: {
    mb: ['1', null, '0'],
    borderRadius: 'md',
    boxShadow:
      '0px 6px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 0px 0px #502714, 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
    bg: 'zeta.400',
  },
  LinkCardHeader: {
    bg: 'zeta.400',
    color: 'alpha.600',
    fontWeight: 'bold',
    borderTopRadius: 'md',
    _hover: {
      bg: 'zeta.600',
    },
  },
  TextCardHeading: {
    fontWeight: 'semibold',
    color: '#7A3B1E',
  },
  TextRaceNumber: {
    borderRadius: 'base',
    bg: 'alpha.500',
    color: 'white',
  },
  GridCardContent: {
    bg: 'epsilon.200',
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'zeta.600',
    color: '#7A3B1E',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderY: '#FFE6B7 2px solid',
  },
  FlexGridRunnerItem: {
    bg: '#f9d79c',
    borderBottom: '1px dotted',
    borderBottomColor: '#CFB481',

    _last: {
      border: 'none',
    },
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'alpha.600',
    fontWeight: 'bold',
  },
  LinkRaceDetails: {
    color: '#F2D194',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    textTransform: 'uppercase',
    fontFamily: 'SkranjiBold',
    fontWeight: 'normal',
    p: '0.6rem 0.5rem 0.5rem 0.5rem',
    boxShadow:
      '0px 1px 0px 0px #38190B inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px rgba(255, 255, 255, 0.15) inset',
    borderBottomRadius: 'sm',
    _hover: {
      bgGradient: 'linear(180deg, alpha.600, alpha.700)',
    },
  },
  buttonRaceCardOddsProps: {
    variant: 'solid',
    fontSize: 'sm',
    color: 'rgba(0, 0, 0, 0.64)',
    w: '14',
  },
};

export const TextRunner: CSSObject = {
  color: 'alpha.600',
  fontSize: 'xs',
  fontWeight: 'semibold',
};
