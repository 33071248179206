import { CSSObject, TextProps } from '@chakra-ui/react';

export const betSlipEmptyHeadingSuffix: TextProps = {
  as: 'span',
  fontFamily: 'accent',
  fontWeight: 'semibold',
  fontSize: 'md',
  color: 'beta.500',
  textTransform: 'capitalize',
  display: 'block',
};

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  pt: '6',
  px: '3',
  textAlign: 'center',
  mx: 'auto',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'Imperator',
  fontWeight: 'normal',
  fontSize: 'xl',
  mt: '2',
  mb: '1',
  textTransform: 'uppercase',
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  bgClip: 'text',

  filter:
    'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25)) drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  mb: '0',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};
