import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  maxW: '600px',
  '.chakra-input__left-addon': {
    bg: 'beta.100',
    borderLeftRadius: 'base',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'sm',
    border: 'none',
  },

  '.chakra-input': {
    border: 'none',
    bg: 'white',
    color: 'gray.700',
    fontWeight: 'bold',
    padding: '12px',
  },

  // Radios
  '.chakra-radio__input + span': {
    bg: 'beta.100',
    border: '2px',
    borderColor: 'beta.100',
    m: '0',

    _hover: {
      bg: 'beta.200',
      borderColor: 'beta.100',
    },
  },
};
