/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMediaQuery } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useQueries } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { keys } from '../../../../../api/api.keys';
import { queryRaceVenueList } from '../../../../../api/racing/racing';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { sortByRaceType } from '@/helpers/sortRaces';
import { ELocale } from '../../../../../redux/ntjFilters.slice';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  TRace,
} from '../../../../../lib/DBModels';

/**
 * Hook to return the day and date formatting
 */
export const useRaceConfig = () => {
  const dayStart = dayjs().startOf('day');
  const { raceMeetingsTime } = useParams();

  return {
    text: raceMeetingsTime,
    format: (() => {
      if (raceMeetingsTime === 'Today') {
        const raceMeetingDate = dayStart.toISOString();
        return dayjs(raceMeetingDate).format('YYYY-MM-DD');
      }

      if (raceMeetingsTime === 'Yesterday') {
        return dayStart.subtract(1, 'day').format('YYYY-MM-DD');
      }

      if (raceMeetingsTime === 'Tomorrow') {
        return dayStart.add(1, 'day').format('YYYY-MM-DD');
      }

      let count = 0;
      let day = dayStart.clone();
      while (day.format('dddd') !== raceMeetingsTime) {
        day = day.add(1, 'day');
        count += 1;
        if (count > 3) {
          break;
        }
      }
      return dayStart.add(count, 'day').format('YYYY-MM-DD');
    })(),
  };
};

/**
 * Hook to query the table data
 */
export const useQueryRaceTable = () => {
  const { format } = useRaceConfig();
  const { filters } = useAppSelector((state) => state.raceTable);
  const { activeLocaleFilters } = useAppSelector((state) => state.ntjFilters);
  const [largerThan768] = useMediaQuery('(min-width: 768px)');

  const queries = useQueries({
    queries: filters.map((f) => ({
      queryKey: [keys.venueList, f, format],

      queryFn: () =>
        queryRaceVenueList({
          race_type: f,
          meeting_date: format,
        }),

      enabled: !!f && !!format,
    })),
  });

  /**
   * Parse the queries so we know what query is for what raceType and
   * sort based on raceType.
   */
  const queryParse = [
    ...queries.map((q, i) => ({
      type: filters[i],
      query: q,
    })),
  ].sort((a, b) => sortByRaceType(a.type, b.type));

  const sortedRaces = (r: TRace[]) =>
    r.sort(
      (a, b) =>
        new Date(a?.start_time ?? '').getTime() -
        new Date(b?.start_time ?? '').getTime()
    );

  /**
   * Mobile UI displays different data than desktop.
   * Only return the first NTJ race.
   */
  const queriesMobile = queryParse.map((q) => ({
    ...q,
    query: {
      ...q.query,
      data: q.query.data?.map((d) => ({
        ...d,
        venue_races: [
          sortedRaces(d.venue_races as TRace[]).filter(
            (r) => r?.status === EGeneralStatus.Open
          )[0] ?? sortedRaces(d.venue_races as TRace[])[0],
        ],
      })),
    },
  }));

  const queriesForViewport = largerThan768 ? queryParse : queriesMobile;

  /**
   * filtering data depending on selected locale selection
   */
  const queriesLocaleFiltered = queriesForViewport.map((q) => ({
    ...q,
    query: {
      ...q.query,
      data: [...(q.query.data ?? [])].filter((v) => {
        if (v?.venue_country === '') return true;

        if (
          activeLocaleFilters.includes(ELocale.Aus) &&
          activeLocaleFilters.includes(ELocale.Intl)
        ) {
          return true;
        }

        if (activeLocaleFilters.includes(ELocale.Aus)) {
          return v?.venue_country === 'AUS' || v?.venue_country === 'NZL';
        }

        if (activeLocaleFilters.includes(ELocale.Intl)) {
          return v?.venue_country !== 'AUS' && v?.venue_country !== 'NZL';
        }

        return true;
      }),
    },
  }));

  const query = [
    ...(activeLocaleFilters.length !== 2
      ? queriesLocaleFiltered
      : queriesForViewport),
  ].map((q) => ({
    ...q,
    query: {
      ...q.query,
      data: [...(q.query.data ?? [])].sort((a, b) => {
        // We order based on the following criteria:
        // 1. priority_venue: number - (lower numbers take priority, equal priorities get ordered alphabetically)
        // 2. AUS venues - venues within Aus are ordered alphabetically by venue name
        // 3. NZL venues - as above
        // 4. Everything else - ordered alphabetically by country name
        if (a.priority_venue && b.priority_venue) {
          if (a.priority_venue !== b.priority_venue) {
            return a.priority_venue - b.priority_venue;
          }
          return a.venue_name?.localeCompare(b.venue_name ?? '');
        }

        if (a.priority_venue || b.priority_venue) {
          return a.priority_venue ? -1 : 1;
        }

        if (a.venue_country === b.venue_country) {
          return a.venue_name?.localeCompare(b.venue_name ?? '');
        }
        if (a.venue_country === 'AUS' || b.venue_country === 'AUS') {
          return a.venue_country === 'AUS' ? -1 : 1;
        }
        if (a.venue_country === 'NZL' || b.venue_country === 'NZL') {
          return a.venue_country === 'NZL' ? -1 : 1;
        }
        return a.venue_country?.localeCompare(b.venue_country ?? '');
      }),
    },
  }));

  const map = new Map<string, Set<string>>();

  query.forEach((q) => {
    const nameSet = new Set<string>();
    q.query.data.forEach((d) => {
      const toteTypes = d?.race_venue_totes?.totes_by_type;
      if (toteTypes) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        Object.keys(toteTypes).forEach((toteType) => {
          nameSet.add(toteType);
        });
      }
    });
    nameSet.delete(EBetTypesDisplayNames.RunningDouble);
    map.set(q.type, nameSet);
  });

  return {
    query,
    selections: map,
  };
};
