import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  mt: '-2',
  px: [null, '4'],
};

export const BoxSectionWrapper: CSSObject = {
  my: '4',
  px: '0',

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'blackAlpha.300',
  borderRadius: 'lg',
  border: 'none',
  bg: 'transparent',
  boxShadow: ' 0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  bg: 'white',
  h: '9',
  mb: '1px',
  border: 'none',
  _last: {
    mb: 0,
  },
};

export const IconSport: CSSObject = {
  color: 'alpha.400',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'alpha.700',
  transition: '.3s ease-in-out',
};

export const TextName: CSSObject = {
  color: 'alpha.700',
};
