import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: ['lg', '4xl'],
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'beta.500',
  fontFamily: 'KHInterference',
};

export const BoxHeader: CSSObject = {
  px: ['3', '6'],
  bgColor: '#212B3F',
  bgSize: '100% 100%',
  bgPos: 'center',
  borderRadius: 'base',
  contain: 'paint',
  h: ['44px', '100px'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};
