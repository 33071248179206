import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner';
import { getStrings } from '@/helpers/utils';
import { TTransaction } from '@/lib/DBModels';
import { useTransactions } from '../../Services/Transactions.hooks';
import { TransactionsTableProps } from '../../types';
import {
  buttonMoreTableProps,
  NoTransactionsText,
  TableHeadingsWrapper,
  TableHeadingText,
  TableItem,
  TableWrapper,
} from './styles/DesktopTransactionsTable.styles';
import Loading from '../../../../../MyBets/Components/Loading';
import Error from '../../../../../MyBets/Components/Error';
import TransactionItem from './TransactionItem';
import { Button } from '@/components/Button/Button';

const DesktopTransactionsTable: React.FC<TransactionsTableProps> = ({
  scrollRef,
  isBonus,
}) => {
  const [
    {
      Account: { Transactions: Strings, BonusBets: BBStrings },
    },
  ] = getStrings();
  const {
    data,
    isInitialLoading,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    hasMoreData,
  } = useTransactions(isBonus);

  if (error) return <Error />;

  if (isInitialLoading) return <Loading />;

  return (
    <Box sx={{ overflowX: 'auto' }}>
      {/* Download transactions is disabled for P1.
        Lazy loading/pagination wasn't working correctly so transactions have been changed to
        to a React Query infinite query. When reimplementing downloading transactions,
        some amendments to the onClick below will probably be necessary.
      */}
      {/* <Flex sx={{ justifyContent: 'flex-end', mb: '4' }}>
        {!isBonus && (
          <DownloadSpreadsheetButton
            data-cy="DownloadTransactiontButton"
            variant="ghost"
            onClick={handleDownloadTransactions}
            disabled={processingDownload}
          >
            {Strings.downloadAction}
          </DownloadSpreadsheetButton>
        )}
      </Flex> */}

      {isInitialLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <TableWrapper ref={scrollRef}>
            <TableHeadingsWrapper>
              {Strings.tableHeadings.map((header, i) => (
                <TableItem
                  key={header.replace('/ /g', '-')}
                  flex={i === 3 ? 2 : 1}
                  sx={{
                    py: '2',
                    borderRight: 'none',
                  }}
                >
                  <TableHeadingText textAlign={i > 2 ? 'end' : 'start'}>
                    {header}
                  </TableHeadingText>
                </TableItem>
              ))}
            </TableHeadingsWrapper>
            {data?.pages &&
              data?.pages.map((r) =>
                r?.map((trans, i) => (
                  <TransactionItem
                    index={i}
                    trans={trans as TTransaction}
                    key={i}
                  />
                ))
              )}
          </TableWrapper>

          {/* Load More Infinite */}
          <Flex
            role="navigation"
            aria-label="pagination"
            sx={{
              py: '4',
              flexWrap: 'wrap',
              gap: '1',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {hasMoreData !== 0 ? (
              <Button
                data-cy="showMoreButton"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetching}
                variant="primary"
                {...buttonMoreTableProps}
              >
                {isFetching ? Strings.loading : Strings.showMore}
              </Button>
            ) : (
              <NoTransactionsText>
                {isBonus ? BBStrings.noBonusBets : Strings.noTransactions}
              </NoTransactionsText>
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default DesktopTransactionsTable;
