import { create } from 'zustand';
import { User } from 'firebase/auth';
import {
  TPunterAccount,
  TPunterAccountOverview,
} from '@/api/punter/punter.types';
import { TDepositLimit } from '@/lib/DBModels';

export interface IUserStore {
  userData: User | null;
  punterData: TPunterAccount | null;
  punterAccountOverview: TPunterAccountOverview | null;
  depositLimit: TDepositLimit | null;
  authIsLoading: boolean;
  isAuthenticated: boolean;
  actions: {
    setUserData: (userData: IUserStore['userData']) => void;
    setDepositLimit: (depositLimit: IUserStore['depositLimit']) => void;
    setPunterData: (punterData: IUserStore['punterData']) => void;
    setPunterAccountOverview: (
      punterAccountOverview: IUserStore['punterAccountOverview']
    ) => void;
    setIsLoading: (isLoading: IUserStore['authIsLoading']) => void;
    setIsAuthenticated: (
      isAuthenticated: IUserStore['isAuthenticated']
    ) => void;
    clearStore: () => Omit<IUserStore, 'actions'>;
  };
}

const useUserStore = create<IUserStore>((set) => ({
  userData: null,
  punterData: null,
  punterAccountOverview: null,
  depositLimit: null,
  authIsLoading: true,
  isAuthenticated: false,
  actions: {
    setUserData: (userData) =>
      set(() => ({
        userData,
      })),
    setPunterData: (punterData) =>
      set(() => ({
        punterData,
      })),
    setPunterAccountOverview: (punterAccountOverview) =>
      set(() => ({ punterAccountOverview })),
    setIsLoading: (authIsLoading) => set(() => ({ authIsLoading })),
    setIsAuthenticated: (isAuthenticated) => set(() => ({ isAuthenticated })),
    setDepositLimit: (depositLimit) => set(() => ({ depositLimit })),
    clearStore: () => ({
      userData: null,
      punterData: null,
      punterAccountOverview: null,
      authIsLoading: false,
      isAuthenticated: false,
      depositLimit: null,
    }),
  },
}));

export function useUserStoreActions() {
  return useUserStore((state) => state.actions);
}

export function usePunterData() {
  return useUserStore((state) => state.punterData);
}

export function usePunterAccountOverview() {
  return useUserStore((state) => state.punterAccountOverview);
}

export function useUserData() {
  return useUserStore((state) => state.userData);
}
export function useDepositLimit() {
  return useUserStore((state) => state.depositLimit);
}

export function useAuthIsLoadingData() {
  return useUserStore((state) => state.authIsLoading);
}

export function useIsAuthenticated() {
  return useUserStore((state) => state.isAuthenticated);
}

export function getUserStoreActions() {
  return useUserStore.getState().actions;
}

export const { setState: setUserStoreState, getState: getUserStoreState } =
  useUserStore;
