import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  bg: 'white',
  borderRadius: 'md',
  mb: '2',
  flex: '1',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  border: '2px solid',
  borderColor: 'beta.600',
  boxSizing: 'border-box',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'gamma.400',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  fontSize: '2xs',
  fontSstyle: 'normal',
  lineHeight: '12px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.35)',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontSize: 'xs',
  color: 'alpha.900',
  fontWeight: 'bold',
};

export const RunnerOddsContainer: CSSObject = {
  color: 'blackAlpha.500',
  fontFamily: 'Roboto',
  fontWeight: 'black',
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '48px',
  h: '32px',
  borderRadius: 'md',
  border: '2px solid white',
  background: 'gray.300',
  boxShadow:
    '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'alpha.800',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
  color: 'alpha.500',
};
