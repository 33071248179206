import { CSSObject } from '@chakra-ui/react';
import featuredCardsBg from '@/assets/juicybet/images/backgrounds/inner.png';

export const BoxSectionWrapper: CSSObject = {
  color: 'gamma.500',
  bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${featuredCardsBg})`,
  bgRepeat: 'repeat',
  bgSize: '40%',
  backgroundBlendMode: 'overlay',

  border: '3px solid',
  borderColor: 'alpha.50',
  borderRadius: 'xl',
  borderWidth: '3px',

  boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
  mt: ['1', null, '2'],
  mb: ['2', '3.5'],
  px: ['0', '3'],

  _first: {
    mb: '-2',
    color: 'white',
    fontFamily: 'Chewy',
    fontSize: 'md',
    textShadow: '0px 2px 6px rgba(0, 0, 0, 0.30)',
  },
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
  color: 'alpha.700',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'Coiny',
  borderRadius: 'md',
  borderWidth: '2px',
  borderColor: 'white',
  letterSpacing: 'wide',
  mx: 'auto',
  my: '2.5',
  w: '96%',
  display: 'flex',

  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },

  _focus: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },

  _disabled: {
    bg: 'gray.300',
    borderColor: 'gray.600',
    color: 'blackAlpha.200',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.60) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  },
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontFamily: 'Chewy',
  textShadow: '0px 2px 6px rgba(0, 0, 0, 0.30)',
  fontSize: 'md',
};

export const BoxWrapper: CSSObject = {
  boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.25)',
};
