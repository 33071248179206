import React from 'react';
import { Img } from '@chakra-ui/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logo from '../../../assets/puntgenie/images/logo.svg';
import Title from '../components/Title';
import Description from '../components/Description';
import RefreshBtn from '../components/RefreshBtn';
import GlobalStyles from '../../../layouts/SecondaryLayout/conditionals/GlobalStyles/template';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (): TTemplate => ({
  wrapper: <FlexPageWrapper />,
  content: {
    wrapper: <MaintenanceContainer />,
    logo: (
      <Img src={logo} alt={REACT_APP_THEME_NAME} mb="4" mx="auto" w="177px" />
    ),
    title: <Title />,
    desc: <Description />,
    btn: <RefreshBtn />,
  },
  body: <GlobalStyles />,
});

export default template;
