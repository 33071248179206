import * as betroyale from './SideLinks.styles.betroyale';
import * as fiestabet from './SideLinks.styles.fiestabet';
import * as goldenrush from './SideLinks.styles.goldenrush';
import * as juicybet from './SideLinks.styles.juicybet';
import * as puntcity from './SideLinks.styles.puntcity';
import * as puntgenie from './SideLinks.styles.puntgenie';
import * as questbet from './SideLinks.styles.questbet';
import * as vikingbet from './SideLinks.styles.vikingbet';
import * as volcanobet from './SideLinks.styles.volcanobet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  vikingbet,
  volcanobet,
};
