import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    color: 'gamma.200',
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mb: 4,
    bg: ['transparent', null, 'alpha.600'],
    boxShadow: [
      'unset',
      null,
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    ],
    borderRadius: 'md',
    pos: 'relative',
  },
  boxHeading: {
    pt: '0',
    mb: 0,
    ...(!IS_MOBILE_APP && {
      pb: '9',
    }),
    color: 'white',
  },
  textTitle: {
    mt: '0',
    color: 'gamma.200',
    fontSize: 'lg',
    fontWeight: 'normal',
    fontFamily: 'accentMusashi',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderBottomRadius: 'md',
        pb: '2.5',
        display: 'flex',
        gap: ['0', null, '4'],
        px: ['0', null, '3.5'],
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pb: '2',
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: ['unset', null, 'full'],
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 'md',
    px: '3',
    py: '3',
    bg: 'alpha.200',
    mt: '2',
    mx: ['2', null, '0'],
  },
};

export const BoxHeading: CSSObject = {
  pt: '0',
  mb: 0,
  ...(!IS_MOBILE_APP && {
    pb: '9',
  }),
  color: 'white',
};
