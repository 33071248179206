import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'beta.500',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  borderRadius: 'lg',
  border: '3px solid',
  borderColor: 'beta.100',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '1.5',
  mb: isKeypadVisible && 'unset',
  bg: 'beta.500',
});

export const BetSlipFooterContainer: CSSObject = {
  // TODO: Fix theme colour
  bg: '#DBC188',
  color: 'gamma.600',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'extrabold',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  h: '6',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'full',
  fontWeight: 'bold',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  color: 'white',
  minW: '16',
  border: '3px solid',
  borderColor: 'beta.300',
  boxShadow: 'none',
  px: '3',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, #3B63C3 0%, #2F4F9C 100%)',
      backgroundBlendMode: 'overlay, normal',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
      color: 'white',
    },
    '&&': {
      fontSize: '2xs',
    },
  },
};
