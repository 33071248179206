import { CSSObject } from '@chakra-ui/react';

export const FlexBetslipHeading: CSSObject = {
  h: '12',
  mx: '3',
  boxShadow: '0px 1px 0px 0px rgba(61, 59, 211, 0.40)',
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'white',
};

export const BetLengthIndicator: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  color: 'blackAlpha.700',
  fontSize: 'xs',
  borderRadius: 'base',
  borderWidth: '2px',
  borderColor: 'whiteAlpha.800',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  boxSize: '22px',
  fontWeight: 'bold',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'delta.500',
};

export const BetslipTitleText: CSSObject = {
  color: 'delta.500',
  fontFamily: 'Sansita One',
  fontSize: ['sm', null, null, 'lg'],
  textTransform: 'uppercase',
  fontWeight: 'normal',
  pb: '1',
};

export const CloseIconButton: CSSObject = {
  justifyContent: 'end',
  border: 'none',
  bg: 'none',
  boxSize: '6',
  svg: {
    boxSize: '6',
    bg: 'delta.500',
    borderRadius: 'full',
  },
  _hover: {
    border: 'none',
    bg: 'none',
  },
};
