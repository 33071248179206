import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { FlexInner, TextLabel, TextInfo } from './styles/PunterDetail.styles';

type TPunterDetailProps = {
  label: string;
  info: string | undefined;
  sxWrapper?: CSSObject;
};

const PunterDetail: React.FC<TPunterDetailProps> = ({
  label,
  info,
  sxWrapper,
}) => (
  <FlexInner sx={sxWrapper}>
    <TextLabel>{label}</TextLabel>
    <TextInfo>{info}</TextInfo>
  </FlexInner>
);

export default PunterDetail;
