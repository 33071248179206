import * as betgalaxy from './QuickAccessMenu.styles.betgalaxy';
import * as goldenrush from './QuickAccessMenu.styles.goldenrush';
import * as juicybet from './QuickAccessMenu.styles.juicybet';
import * as vikingbet from './QuickAccessMenu.styles.vikingbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  goldenrush,
  juicybet,
  vikingbet,
};
