import { CSSObject } from '@chakra-ui/react';

export const EventsClosedContainer: CSSObject = {
  borderRadius: 'md',
  p: '2',
  alignItems: 'center',
  bg: 'white',
  color: 'gray.700',
  svg: {
    color: 'blue.500',
  },
};
