import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  mb: '0',
  px: '2',
  bg: 'alpha.500',
  borderRadius: 'lg',
  flex: '1',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const RaceResultsHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'Staatliches',
  textTransform: 'uppercase',
};

export const RunnerResultRow: CSSObject = {
  borderTop: '1px dashed rgba(255, 255, 255, 0.15)',
  borderBottom: '1px dashed rgba(0, 0, 0, 0.15)',
  py: '1.5',
  h: '12',
  _last: {
    borderBottom: 'none',
  },
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  boxShadow: '0px 0px 8px 0px rgba(251, 201, 21, 0.50)',
  color: 'beta.800',
  textShadow: '0px 2px 0px rgba(255, 255, 255, 0.50)',
  textTransform: 'uppercase',
  padding: '2',
  fontSize: '2xs',
  '&&': {
    h: '6',
    w: '8',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'normal',
  fontSize: '2xs',
  color: 'white',
  pos: 'relative',
  bottom: '2px',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '30px',
  bg: 'blackAlpha.400',
  border: 'none',
  borderRadius: 'md',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.20) inset',
  color: 'gray.300',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  fontWeight: 'black',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
  alignItems: 'center',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
  color: 'white',
};
