import { CSSObject } from '@chakra-ui/react';
import wblogo from '../../../assets/wellbet/images/header/wblogo.svg';
import maintanence from '../../../assets/wellbet/images/backgrounds/image-water.png';
import sign from '../../../assets/wellbet/images/maintenance/image-sign.png';

export const CenterWrapper: CSSObject = {
  mt: '20',
  justifyContent: 'space-between',
};

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'gray.200',
  bgImage: `url(${maintanence})`,
  bgSize: ['cover', null, '100% 100%'],
  bgPos: 'center',
  bgRepeat: 'no-repeat',
});

export const ContentHeadingText: CSSObject = {
  fontSize: ['2xl', null, '5xl'],
  color: 'white',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))',
  fontWeight: 'black',
};

export const ContentBodyText: CSSObject = {
  m: 'auto',
  fontSize: ['md', null, '2xl'],
  fontWeight: 'normal',
  color: 'white',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))',
};

export const Mascot: CSSObject = {
  w: '170px',
  h: '80px',
  m: 'auto',
  bgImage: `url(${wblogo})`,
};

export const Sign: CSSObject = {
  bgImage: `url(${sign})`,
};
