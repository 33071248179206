import { CSSObject } from '@chakra-ui/react';

export const TabWrapper: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  borderRadius: 'base',
  p: '1.5',
  mt: '2',
  height: '33px',
  overflow: 'unset',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  bg: isActive ? 'alpha.700' : 'transparent',
  borderRadius: 'base',
  boxShadow: isActive ? '0px 1px 0px 0px rgba(255, 255, 255, 0.45)' : 'none',
  color: isActive ? 'white' : 'alpha.800',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: isActive ? '0px 1px 0px 0px rgba(0, 0, 0, 0.35)' : 'none',
  textTransform: 'uppercase',
  _hover: {
    bg: isActive ? 'alpha.400' : 'transparent',
    boxShadow: 'none',
    color: 'alpha.400',
  },

  _active: {
    bg: isActive ? 'alpha.700' : 'transparent',
    boxShadow: 'none',
    color: isActive ? 'alpha.400' : 'delta.100',
  },

  _focus: {
    bg: 'alpha.700',
    boxShadow: 'unset',
  },
});
