import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapper: BoxProps = {};

export const StartTime: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'alpha.700',
  px: '1',
  py: '0.5',
  borderRadius: '3px',
  bg: 'blackAlpha.300',
};
