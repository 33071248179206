import dayjs from 'dayjs';

/**
 * Util that calculates the NTJ values for countdown
 */
export const getNTJStartTime = (startTime: string) => {
  const timerStart = dayjs();
  const $startTime = startTime;
  const msTillStart = dayjs($startTime).diff(timerStart).valueOf();
  const now = dayjs(); // get current time each interval to maintain accuracy
  const delta = now.diff(timerStart); // time elapsed since timerStart
  const msRemaining = msTillStart - delta;

  const secondsRemaining = Math.floor(msRemaining / 1000);

  const minutesRemaining =
    secondsRemaining < 0
      ? Math.ceil(secondsRemaining / 60)
      : Math.floor(secondsRemaining / 60);
  const secondsRemainder = secondsRemaining % 60;

  return { minutesRemaining, secondsRemainder, msRemaining };
};
