import { Box, chakra, Text } from '@chakra-ui/react';
import { styleImports } from './VerifyIdentity.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const GreenIDText = chakra(Text, {
  label: 'verifyIdentity-GreenIDText',
  baseStyle: ({ theme }) => ({
    fontSize: 'md',
    fontWeight: 'bold',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.700',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.GreenIDText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const GreenIDContainer = chakra(Box, {
  label: 'verifyIdentity-GreenIDContainer',
  baseStyle: ({ theme }) => ({
    minHeight: '700px',
    position: 'relative',
    '#greenid-container': {
      bg: 'inherit',
      _focusVisible: {
        outline: 'none',
      },
      a: {
        color: 'gamma.500',

        ...(theme.themeName === EThemes.Wellbet && {
          color: 'gray.700',
        }),
      },
    },

    '#greenid-intro-content': {
      pb: '5',
      h1: {
        display: 'none',
      },
      p: {
        fontFamily: 'body',
        fontSize: 'xs',
        my: '2',
      },
    },

    'button#greenid-option-list-toggle': {
      mt: '0 !important',
    },

    '#greenid-container label': {
      color: 'gamma.500',
      fontFamily: 'body',
      fontSize: 'sm',

      ...(theme.themeName === EThemes.Wellbet && {
        color: 'gray.700',
      }),
    },

    '#greenid-container h2': {
      color: 'gamma.500',
      fontSize: 'xs',
      mb: '5',

      ...(theme.themeName === EThemes.Wellbet && {
        color: 'gray.700',
      }),
    },

    '#greenid-container .uploadifive-queue-item': {
      fontFamily: 'body',
      fontSize: 'sm',
    },

    '#greenid-container .greenid-alert': {
      fontFamily: 'body',
      fontSize: 'sm',
    },

    '#greenid-container .greenid-helpicon': {
      color: 'gamma.500',

      ...(theme.themeName === EThemes.Wellbet && {
        color: 'gray.700',
      }),
    },

    '#greenid-container .greenid-source-header .greenid-source-title .glyphicon':
      {
        color: 'gamma.500',
      },

    '#greenid-container .btn-primary': {
      border: 'none',
      width: '100%',
      height: '8',
      alignItems: 'center',
    },

    '.greenid-sr-only': {
      display: 'none',
    },

    '.greenid-alert-info': {
      marginBottom: '3',
      color: 'gamma.500',
      lineHeight: 'none',
    },

    '#greenid-wait-modal': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '&:focus-visible': {
        outline: 'none',
      },

      '.modal-dialog': {
        textAlign: 'center',
        borderRadius: '2',
      },

      '.modal-body': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '#greenid-modal-text': {
          color: 'black',
          fontSize: 'md',
          fontFamily: 'body',

          '&:focus-visible': {
            outline: 'none',
          },
        },
      },
    },

    '#greenid-source-content': {
      position: 'relative',
      height: 'initial !important',

      '.greenid-alert.greenid-alert-danger': {
        fontSize: 'xs',
        lineHeight: 'base',
        color: 'red',
      },
    },

    ...(() => {
      try {
        return styleImports[themeName]?.GreenIDContainer;
      } catch (err) {
        return {};
      }
    })(),

    '#greenid-source-header': {
      mb: '5',

      '#greenid-source-title': {
        '#greenid-source-title-heading': {
          mb: '2',
          fontFamily: 'heading',

          fontSize: 'md',
        },

        'a.greenid-modal-launch': {
          position: 'absolute',
          top: '0',
          right: '0',
          padding: '5',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',

          'span.glyphicon-question-sign': {
            width: '5',
            height: '5',
            display: 'block',
            backgroundRepeat: 'no-repeat',
          },
        },
      },

      '.greenid-modal': {
        display: 'none',
        position: 'relative',

        '.modal-header': {
          fontSize: '3',
          fontFamily: 'heading',
          marginBottom: '3',
        },

        '.modal-body': {
          fontSize: 'xs',
          fontFamily: 'body',
          marginBottom: '3',
          lineHeight: 'none',
        },

        '.greenid-modal-close.greenid-x': {
          position: 'absolute',
          top: '0',
          right: '0',
          background: 'none',
          border: 'none',
          color: 'black',
          padding: '4',
        },
      },

      '#greenid-source-list-container': {
        '.greenid-modal-launch': {
          width: '100%',
          marginBottom: '3',
          fontSize: 'sm',
          textAlign: 'center',
          lineHeight: '8',
          display: 'block',
        },

        '.dropdown-menu': {
          display: 'none',
        },

        '#greenid-sources': {
          marginBottom: '3',

          li: {
            fontFamily: 'body',
            fontSize: 'xs',
            marginBottom: '1',

            a: {
              textDecoration: 'underline',
            },
          },
        },
      },
    },

    '#greenid-source-body': {
      p: {
        fontFamily: 'body',
        color: 'primary !important',
        fontSize: 'xs',
        marginBottom: '3',

        strong: {
          fontSize: 'sm',
          fontWeight: 'bold',
          color: 'primary',
        },
      },

      '.row': {
        '.form-group': {
          label: {
            fontFamily: 'heading',
            fontSize: 'md',
            mx: '0',

            span: {
              fontFamily: 'heading',
              fontSize: 'md',
            },
          },

          'input[type="text"]': {
            width: '100%',
            px: '2',
            py: '0',

            '&:focus-visible': {
              outline: 'none',
            },
          },

          select: {
            width: '100%',
            mb: '3',
            px: '2',
            py: '0',
          },
        },

        '.greenid-has-error': {
          '.help-block': {
            color: 'red.500',
          },
        },
      },

      '.dd-container': {
        '.dd-select': {
          '.dd-selected-text, .dd-option-text': {},
        },
      },

      '.checkbox': {
        mb: '2',

        label: {
          fontFamily: 'body',
          fontSize: 'xs',
          display: 'flex',
          marginBottom: '2',
        },

        'input[type="checkbox"]': {
          font: 'inherit',
          color: 'black',
          width: '3',
          height: '3',
          border: 'var(--bc-sizes-0-5) solid',
          borderColor: 'white',
          transform: 'translateY(-var(--bc-sizes-0-5))',

          display: 'inline-grid',
          placeContent: 'center',
          bg: 'white',
          marginRight: '1',
        },

        'input[type="checkbox"]::before': {
          width: '2',
          height: '2',
          clipPath:
            'polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)',
          transform: 'scale(0)',
          transformOrigin: 'bottom left',
          transition: '120ms transform ease-in-out',
          bg: 'black',
        },

        'input[type="checkbox"]:checked::before': {
          transform: 'scale(1)',
        },

        'input[type="checkbox"]:disabled': {
          cursor: 'not-allowed',
        },

        '&.greenid-has-error': {
          '.help-block': {
            color: 'red',
          },
        },
      },

      '.greenid-certoption': {
        mb: '2',
      },

      '#greenid-u .greenid-u-select-container .greenid-u-select-option .greenid-u-select-option-words, .certselect .words':
        {
          fontFamily: 'body',
          fontSize: 'sm',
        },

      '.greenid-bride-groom-label': {
        fontFamily: 'heading',
        fontSize: 'sm',
        marginBottom: '2',
      },

      '#greenid-u .greenid-u-select-container .greenid-u-select-option .greenid-u-select, .certselect .greenid-certlink':
        {
          width: '5',
          height: '5',
          ml: '2',
        },

      '.certselect': {
        mb: '2',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '2',
      },

      '#greenid-medicaredvs-multiline-name-toggle': {
        display: 'block',
        mb: '5',
        fontFamily: 'body',
        textDecoration: 'underline',
        fontSize: 'sm',
      },

      '.greenid-radio-certchange .greenid-certoption': {
        '.radio-inline': {
          display: 'flex',
          flexDirection: 'row',

          label: {
            fontFamily: 'body',
            fontSize: 'sm',
          },
        },
      },

      '.greenid-radio-certchange': {
        mb: '2',

        '.radio-inline': {
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'body',
          fontSize: 'sm',
          marginBottom: '2',

          'input[type="radio"]': {
            mr: '2',
          },
        },
      },

      '#greenid-u': {
        '.greenid-u-select-container': {
          '.greenid-u-select-option': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '2',
          },
        },
      },
    },
  }),
});
