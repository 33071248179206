import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: '5px',
    borderWidth: '1px',
    borderColor: 'rgba(255, 255, 255, 0.09)',
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
    boxShadow: `0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset,
      0px 3px 4px 0px rgba(0, 0, 0, 0.35),
      0px 0px 4px 0px #FFF inset
      `,
    minW: '90px',
  },
  btnGroupText: {
    fontSize: 'sm',
    color: 'blackAlpha.800',
    fontWeight: 'black',
    fontFamily: 'roboto',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  },
  btnGroupIcon: {
    color: 'black',
  },
};
export default styles;
