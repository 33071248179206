import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/oldgill/images/backgrounds/onboardingAsideBg.png';
import bgMobile from '@/assets/oldgill/images/backgrounds/onboardingAsideBgMb.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${bgMobile}), var(--bc-colors-alpha-600)`,
    null,
    `url(${bgDesktop}), var(--bc-colors-alpha-600)`,
  ],
  bgSize: ['100% 100%, auto', null, '100%, auto'],
  bgPos: 'center top',
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: ['space-between', null, 'flex-start'],
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['370px', '550px', null, 'full'],
  borderLeftRadius: [null, 'md'],
  pt: ['4', null, '0'],
  flex: 1,
};
