import { CSSObject } from '@chakra-ui/react';
import slamBorder from '@/assets/slambet/images/backgrounds/gradientBorderSmall.svg';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'beta.200',
  borderRadius: 'sm',
  color: 'blackAlpha.700',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
export const LinkRaceContainer: CSSObject = {
  borderRadius: 'lg',
  bg: `rgba(78, 178, 255, 0.14)`,
  boxShadow:
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'rgba(78, 178, 255, 0.45)',
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
  pos: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 20`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const TextRaceType: CSSObject = {
  fontFamily: 'accent',
  color: 'white',
  fontSize: 'md',
  textTransform: 'capitalize',
  fontWeight: 'normal',
};
export const TextRace: CSSObject = {
  fontFamily: 'accent',
  color: 'white',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  pt: '0.5',
};
export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.400',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
