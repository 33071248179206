import { chakra, Grid, GridItem } from '@chakra-ui/react';
import { rgba } from 'polished';
import { styleImports } from './SecondaryLayout.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const GridLayout = chakra(Grid, {
  label: 'secondaryLayout-GridLayout',
  baseStyle: () => ({
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      "header"
      "main"
    `,
    minH: '100dvh',
    height: 'auto',

    ...(() => {
      try {
        return styleImports[themeName]?.GridLayout;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const HeaderGridItem = chakra(GridItem, {
  label: 'HeaderGridItem',
  baseStyle: ({ theme }) => ({
    position: 'sticky',
    top: '0px',
    zIndex: 'tooltip',

    ...(theme.themeName === EThemes.Betgalaxy && {
      zIndex: 'docked',
      position: 'relative',

      borderBottom: '1px solid',
      borderBottomColor: rgba(`${theme.colors.white}`, 0.07),
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.HeaderGridItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
