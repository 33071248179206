import { css } from 'styled-components';

export const betSlipModalWrapper = css`
  height: 100%;
  position: fixed;
`;

// TODO: remove
export const betSlipModalContainer = css`
  background-image: ${({ theme }) => theme.basePalette.navyBlueGradient};
  color: ${({ theme }) => theme.palette.white};
  height: 100%;
  height: -webkit-fill-available;
  width: 85vw;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.device.tablet} {
    width: 45vw;
  }

  ${({ theme }) => theme.device.laptop} {
    width: 25vw;
    max-width: 25vw;
  }
`;

export const betSlipErrorContainer = css`
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 var(--bc-sizes-5);
  margin-top: var(--bc-sizes-2);
  background-color: ${({ theme }) => theme.palette.warning};
`;

export const betSlipErrorText = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 600;
  font-style: italic;
`;

export const betSlipErrorIcon = css`
  width: var(--bc-sizes-4);
  height: var(--bc-sizes-4);
  margin-right: var(--bc-sizes-2);
  color: white;
`;

export const betSlipConfirmationContainer = css`
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 var(--bc-sizes-5);
  margin-top: var(--bc-sizes-2);
  background-image: ${({ theme }) => theme.basePalette.greenGradient180deg};
`;

export const betSlipConfirmationText = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 600;
  font-style: italic;
`;

export const betSlipConfirmationIcon = css`
  width: var(--bc-sizes-4);
  height: var(--bc-sizes-4);
  margin-right: var(--bc-sizes-2);
  color: white;
`;

export const betSlipHeadingContainer = css`
  padding: 0 var(--bc-sizes-4);
  margin-bottom: var(--bc-sizes-4);
  display: flex;
  align-items: center;
`;

export const betSlipHeading = css`
  font-size: var(--bc-fontSizes-2xl);
  font-weight: 300;
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
`;

export const betSlipAccountBalance = css`
  text-align: right;
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-md);
  font-family: ${({ theme }) => theme.fontFamily.heading};
  letter-spacing: var(--bc-letterSpacings-tight);
  margin-left: var(--bc-sizes-2);
`;

export const betSlipCloseIcon = css`
  width: var(--bc-sizes-4);
  height: var(--bc-sizes-4);
  position: absolute;
  top: var(--bc-sizes-2);
  right: var(--bc-sizes-5);
  cursor: pointer;
`;

export const betSlipContentWrapper = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const betSlipContentContainer = css`
  padding: 0 var(--bc-sizes-4);
  flex: 1;
`;

export const betSlipContentHeading = css`
  font-size: var(--bc-fontSizes-md);
  color: ${({ theme }) => theme.basePalette.blue};
  font-style: italic;
  font-weight: 600;
  margin-bottom: var(--bc-sizes-2);
`;

export const betSlipFooterContainer = css`
  padding: var(--bc-sizes-4);
  color: ${({ theme }) => theme.palette.secondary};
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const betSlipFooterTextContainer = css``;

export const betSlipStakeTextContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: var(--bc-sizes-1);
`;

export const betSlipStakeText = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 600;
  width: 40%;
  color: ${({ theme }) => theme.palette.secondary};
`;

export const betSlipStakeValue = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 400;
  color: ${({ theme }) => theme.palette.black};
`;

export const betSlipReturnsTextContainer = css`
  display: flex;
  align-items: center;
`;

export const betSlipReturnsText = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 600;
  width: 40%;
  color: ${({ theme }) => theme.palette.secondary};
`;

export const betSlipReturnsValue = css`
  font-size: var(--bc-fontSizes-xs);
  font-weight: 400;
  color: ${({ theme }) => theme.palette.black};
`;

export const betSlipActionsContainer = css`
  display: flex;
  flex-direction: row;
  margin-top: var(--bc-sizes-5);
  justify-content: space-between;
`;

export const betSlipEditAction = css`
  align-self: flex-end;
  text-decoration: underline;
  font-size: var(--bc-fontSizes-xs);
  font-weight: 600;
  color: ${({ theme }) => theme.palette.secondary};
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const clearBetSlipButton = css`
  width: 49%;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.palette.secondary};
  border-radius: var(--bc-sizes-5);
  min-height: var(--bc-sizes-10);
  color: ${({ theme }) => theme.palette.secondary};
  font-weight: 800;
  font-style: italic;
  cursor: pointer;
`;

export const submitBetSlipButton = css`
  width: 49%;
  border: none;
  border-radius: var(--bc-sizes-5);
  min-height: var(--bc-sizes-10);
  background-color: ${({ theme }) => theme.palette.secondary};
  color: ${({ theme }) => theme.palette.white};
  font-weight: 800;
  box-shadow: inset 0 0 15px 0 var(--bc-colors-whiteAlpha-600);
  font-style: italic;
  cursor: pointer;
`;

// TODO: Remove
export const betSlipEmptyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

// TODO: Remove
export const betSlipEmptyHeading = css`
  margin: var(--bc-sizes-5) 0 var(--bc-sizes-2) 0;
  font-size: var(--bc-fontSizes-md);
  font-weight: 700;
  font-style: italic;
  color: ${({ theme }) => theme.basePalette.blue};
`;

// TODO: Remove
export const betSlipEmptySubheading = css`
  font-size: var(--bc-fontSizes-xs);
  margin-bottom: var(--bc-sizes-24);
`;

// TODO: Remove
export const betSlipEmptyIcon = css`
  width: var(--bc-sizes-24);
  height: var(--bc-sizes-24);
  margin-top: var(--bc-sizes-24);
`;

export const betSlipEmptyMultiContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--bc-sizes-5);
  box-shadow: inset 0px 0px 15px 0px var(--bc-colors-blackAlpha-800);
  background-color: var(--bc-colors-blackAlpha-400);
  border-radius: 7px;
`;

export const betSlipEmptyMultiText = css`
  font-size: var(--bc-fontSizes-xs);
  color: ${({ theme }) => theme.palette.primary};
  flex: 1;
  font-weight: 500;
`;

export const betSlipEmptyMultiIcon = css`
  width: var(--bc-sizes-8);
  height: var(--bc-sizes-8);
  color: ${({ theme }) => theme.palette.primary};
  margin-right: var(--bc-sizes-5);
`;
