import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './PunterDetail.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FlexInner: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: {
    flex: '1',
    flexDir: 'column',
    gap: '1.5',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexInner;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const TextLabel: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: {
    color: 'gray.600',
    fontSize: 'xs',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextLabel;
      } catch (err) {
        return {};
      }
    })(),
  },
});

export const TextInfo: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: {
    bg: 'gray.50',
    color: 'gray.600',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'xs',
    p: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.TextInfo;
      } catch (err) {
        return {};
      }
    })(),
  },
});
