import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {
      boxSize: '4',
    },
  },
  iconButtonScrollRight: {
    bgGradient: 'linear(to-b, alpha.600, alpha.800)',
    color: 'beta.400',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    border: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {
      boxSize: '4',
    },
  },
  iconButtonScrollLeft: {
    bgGradient: 'linear(to-b, alpha.600, alpha.800)',
    color: 'beta.400',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    border: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  flexWrapper: {
    bg: 'delta.600',
    borderRadius: 'md',
    boxShadow: '0 1px rgba(255, 255, 255, .05)',
    minH: '34px',
  },
  buttonGroupSports: {
    display: 'inline-flex',
    alignItems: 'center',
    flex: '1',
    bg: 'alpha.700',
    overflowX: 'auto',
    p: '1',
    h: [null, null, '31px'],
    spacing: '1',
    mt: [null, null, '3px'],
    whiteSpace: 'nowrap',
    pos: 'relative',
    w: ['120px', 'auto', null],
    borderRadius: 'lg',
    inset: 0,
    sx: {
      '::-webkit-scrollbar': { display: 'none' },
      button: {
        '-webkit-tap-highlight-color': 'transparent',
      },
    },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    pr: '2px',
    pos: 'relative',
    spacing: '1',
    ml: 'auto',
    gap: 0.5,
    isAttached: true,
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'beta.400',
    pos: 'absolute',
    bottom: '0',
    transition: 'all 0.2s ease',
  },
};
