import { CSSObject } from '@chakra-ui/react';
import background from '../../../assets/betprofessor/images/backgrounds/location-unavailable-desktop.png';
import backgroundAtRetina from '../../../assets/betprofessor/images/backgrounds/location-unavailable-desktop@2x.png';
import franBackground from '../../../assets/betprofessor/images/backgrounds/fran.png';
import franBackgroundAtRetina from '../../../assets/betprofessor/images/backgrounds/fran@2x.png';

export const LocationNotAllowedContainer: CSSObject = {
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: `url(${background})`,
  bgColor: 'beta.900',
  bgRepeat: 'no-repeat',
  bgSize: ['cover', '80%'],
  bgPosition: 'center',
  lineHeight: '4',
  overflow: 'hidden',
  position: 'relative',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${backgroundAtRetina})`,
  },

  _after: {
    w: '100%',
    content: `''`,
    bgImage: `url(${franBackground})`,
    bgPos: 'bottom',
    bgRepeat: 'no-repeat',
    bgSize: ['278px', '428px'],
    pos: 'absolute',
    bottom: '0%',
    height: ['352px', '422px'],

    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bgImage: `url(${franBackgroundAtRetina})`,
    },
  },
};

export const CenterWrapper: CSSObject = {
  bg: 'transparent',
  borderRadius: [null, null, 'xl'],
  color: 'white',
  flex: ['1', null, '0'],
  gap: '0',
  justifyContent: 'flex-start',
  mt: ['0', null, '12'],
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.md',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
};

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Dreadful',
  fontSize: '6xl',
  lineHeight: ['70%', null, '10'],
  mt: ['9', null, '4'],
  mb: ['9', null, '4'],
  color: 'alpha.200',
  textShadow: '0px 0px 9px #89E72C',
};

export const ContentBodyText: CSSObject = {
  fontSize: ['md', 'lg'],
  lineHeight: 'tall',
  textAlign: 'center',
  filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.5))',

  b: {
    display: 'block',
    fontSize: 'md',
    lineHeight: 'base',
    mb: '4',
  },

  '.chakra-link': {
    fontSize: ['2xs', 'xs'],
    fontWeight: 'bold',
  },
};

export const ContactText: CSSObject = {
  '&&': {
    fontSize: ['sm', null, 'lg'],
  },
};

export const TextBodyCopy: CSSObject = {
  lineHeight: '5',
  fontSize: ['sm', null, 'lg'],
};

export const TextHeadingColor: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  bgClip: 'text',
  textShadow: 'none',
  display: 'flex',
};

export const RegistrationContent: CSSObject = {
  fontSize: ['xs', null],

  '.chakra-link': {
    fontSize: ['xs', null],
    fontWeight: 'bold',
  },
};
