import { css } from 'styled-components';

export const container = css``;

export const spacer = css`
  width: var(--bc-sizes-8);
`;

export const parentContainer = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.transparentBlue};
  padding: var(--bc-sizes-3) 0;

  &:hover,
  &:focus {
    background: ${({ theme }) => `${theme.basePalette.purple}50`};
  }
`;

export const childContainer = css``;

export const icon = css`
  margin: 0 var(--bc-sizes-2) 0 var(--bc-sizes-1);
  width: var(--bc-sizes-6);
  height: var(--bc-sizes-6);

  path {
    fill: ${({ theme }) => theme.basePalette.brightBlue};
  }
`;

export const title = css`
  font-size: var(--bc-fontSizes-md);
  font-style: italic;
  color: white;
`;

export const titleLink = css`
  font-size: var(--bc-fontSizes-md);
  font-style: italic;
  color: white;
`;
