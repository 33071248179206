import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  borderRadius: 'unset',
  mb: 'unset',
  px: 'none',
  mx: '2',
  w: 'auto',
  _hover: {
    bg: 'gamma.600',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  borderRadius: 'md',
  border: '2px',
  borderColor: 'whiteAlpha.700',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
};

export const Icon: CSSObject = {
  bgGradient: 'linear(to-b, alpha.700, alpha.800)',
  color: 'gamma.400',
  borderRadius: 'base',
};

export const TextRace: CSSObject = {
  color: 'alpha.800',
  fontWeight: 'medium',
};

export const TextNumber: CSSObject = {
  bg: 'alpha.600',
  color: 'gamma.400',
  h: '4',
  py: '0.5',
};

export const BoxHeading: CSSObject = {
  display: 'none',
  border: 'none',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'alpha.700',
  bg: 'white',
  borderRadius: 'full',
  p: '1.5',
};
