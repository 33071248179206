import React from 'react';
import { IntlShape } from 'react-intl';
import ResponsibleGamblingBanner from '../../../../components/ResponsibleGamblingBanner/ResponsibleGamblingBanner';
import { TTemplate } from '@/helpers/createTemplate';
import AccountWrapper from '../../../../wrappers/AccountWrapper/AccountWrapper';
import Banner from '../components/Banner/Banner';
import GetVerified from '../components/GetVerified/GetVerified';
import OverviewAccordion from '../components/OverviewAccordion/OverviewAccordion';
import {
  FlexResponsibleGamblingWrapper,
  BoxOverviewTitle,
} from '../styles/Overview.styles';

const template = (intl: IntlShape): TTemplate => ({
  wrapper: (
    <AccountWrapper
      pageTitle={intl.formatMessage({ id: 'account.overview.pagetitle' })}
    />
  ),
  content: (
    <>
      <GetVerified />
      <BoxOverviewTitle>
        {intl.formatMessage({ id: 'account.overview' })}
      </BoxOverviewTitle>
      <Banner />
      <FlexResponsibleGamblingWrapper>
        <ResponsibleGamblingBanner />
      </FlexResponsibleGamblingWrapper>
      <OverviewAccordion />
    </>
  ),
});

export default template;
