import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  bg: 'white',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  color: 'epsilon.500',
};

export const DividerCard: CSSObject = {
  borderColor: 'gray.400',
};

export const CardBottomContent: CSSObject = {
  borderColor: 'alpha.400',
  color: 'alpha.600',
};

export const ContentRow: CSSObject = {
  color: 'alpha.600',
  borderColor: 'alpha.300',
  '> hr': {
    width: '100%',
    borderStyle: 'solid',
    borderColor: 'gray.300',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'beta.800',
    bg: 'beta.400',
    border: '2px',
    borderColor: 'beta.400',
  },
};

export const ExoticSelectionText: CSSObject = {
  color: 'alpha.600',
};
