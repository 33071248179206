import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
};

export const ButtonSubmit: CSSObject = {
  bg: '#FD62FF',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.45), 0px 0px 0px 2px rgba(254, 153, 255, 0.70) inset',
  fontSize: '13px',
  pb: '1',
  fontFamily: 'Sansita One',
  fontWeight: '400',
  borderColor: 'white',
  color: 'beta.700',
  h: '36px',
  _hover: {
    opacity: '0.4',
  },
  _active: {
    bg: 'alpha.800',
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',

  '&&': {
    '& .chakra-input': {
      border: '1px solid',
      color: 'gamma.900',
      fontWeight: 'bold',
      borderRightRadius: 'md',
      borderColor: 'beta.500',
      bg: 'white',
      borderLeft: 'none',
      boxShadow: 'none',
      '&:focus, &:hover': {
        backgroundColor: 'beta.600',
      },
      '&::placeholder': { color: 'gamma.900' },
    },

    '& .chakra-input__left-addon': {
      borderLeftRadius: '4px',
      bg: 'gamma.500',
      color: 'black',
      fontSize: 'xs',
    },
  },
};
