import React, { ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { IS_MOBILE_APP } from '../../../../../constants/isMobileApp';
import { BetSlipFooterMotion } from '../Modal.styles';

type TFooterHOC = {
  children: ReactNode;
};

export default function BetCardHOC({ children }: TFooterHOC) {
  if (IS_MOBILE_APP)
    return <BetSlipFooterMotion>{children}</BetSlipFooterMotion>;

  return (
    <AnimatePresence exitBeforeEnter>
      <BetSlipFooterMotion
        as={motion.div}
        initial={{ opacity: 0, y: 90 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {children}
      </BetSlipFooterMotion>
    </AnimatePresence>
  );
}
