import { CSSObject } from '@chakra-ui/react';

import featuredRaceBg from '../../../../../../assets/betprofessor/images/backgrounds/featured-prof.png';
import featuredMobileRaceBg from '../../../../../../assets/betprofessor/images/backgrounds/featured-prof-mobile.png';

export const GridRaceCards: CSSObject = {
  my: '0',
  mb: [0, null, '3'],
  rowGap: ['12px', null, 'unset'],
};

export const LinkCardHeader: CSSObject = {
  px: '4',
};

export const FlexFeatureWrapper: CSSObject = {
  bg: `transparent`,
  minH: ['390px', null, null, '390px'],
  px: 0,
  order: 2,
  mt: '3',
  flexDir: ['column', null, null, 'row'],
  alignItems: 'start',
  bgImage: [
    `url('${featuredMobileRaceBg}')`,
    null,
    null,
    `url('${featuredRaceBg}')`,
  ],
  bgSize: ['380px 191px', null, null, '100%'],
  bgRepeat: 'no-repeat',
  bgPosition: ['top right', 'unset', null, 'center bottom'],
  mb: '2',
};

export const TextCardHeader: CSSObject = {
  color: 'white',
  ml: '1',
};

export const BoxCard: CSSObject = {
  pb: '1',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  justifyContent: ['center', null, null, 'flex-start'],
  flexDir: ['column-reverse', null, null, 'column'],
  pt: ['0', null, null, '43px'],
  pb: ['0', null, null, '5px'],
  pos: 'relative',
  flex: '1.5',
  w: ['60%', null, null, 'unset'],
  h: ['190px', null, 'auto'],
  minH: ['190px', null, 'auto'],
  ml: ['auto', null, null, 'inherit'],
};

export const HeadingSlogan: CSSObject = {
  color: 'alpha.400',
  fontSize: '7xl',
  fontFamily: 'dreadful',
  fontWeight: 400,
  textShadow: '0px 1px 24px rgba(108, 215, 1, 0.60)',
  textAlign: ['center', null, null, 'unset'],
  pb: ['2', null, null, 'inherit'],
  mt: ['-2', null, null, 'inherit'],
  pl: ['1', null, null, 'inherit'],

  lineHeight: ['0.75', null, null, 'inherit'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  mr: ['0', null, null, null, '10'],
  mt: ['-8', null, null, '0', '2'],
  alignItems: 'unset',
  height: '100%',
  w: ['100%', null, null, null, 'unset'],
  flex: '0.95',
  mb: '6',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'blackAlpha.300',
  color: 'white',
  px: '3',
  textTransform: 'uppercase',
  boxShadow: 'inset 0 7px 8px -10px #000, inset 0 -7px 8px -10px #000',
};

export const FlexGridRunnerItem: CSSObject = {
  px: '2.5',
  py: '7',
  ml: '1',
  mx: '0',
  my: '0',
  mt: '0',
  height: '40px',
  color: 'white',
  borderBottom: '1px dashed var(--chakra-black-alpha-300, rgba(0, 0, 0, 0.16))',
  ':nth-child(odd)': {
    pl: '4',
  },
  ':nth-last-child(-n+2)': {
    borderBottom: 'none',
  },
  '.chakra-button': {
    mr: '1',
  },
};

export const LinkRaceDetails: CSSObject = {
  mt: 'auto',
  textTransform: 'uppercase',
  color: 'alpha.800',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  borderBottomRadius: 'base',
  boxShadow:
    '0px 6px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 0px 0px #480, 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
  fontFamily: 'Fredoka',
  fontWeight: 'bold',
};

export const Button = (): CSSObject => ({
  fontSize: '15px',
  borderRadius: '5px',
  px: 'inherit',
  flex: '1',
  h: '34px',
  w: '3.25rem',
  maxW: '52px',
  fontFamily: 'Fredoka',
});

export const IconCardHeader: CSSObject = {
  color: 'white',
  w: '3.5',
  h: '3.5',
};

export const TextCardHeading: CSSObject = {
  color: 'white',
  ml: '1',
};

export const TextRaceNumber: CSSObject = {
  bg: 'beta.800',
  borderRadius: 'base',
  color: 'white',
  h: '4',
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontFamily: 'roboto',
  fontWeight: 'semibold',
  fontSize: '0.75rem',
};

export const TextRunnerNumber: CSSObject = {
  fontWeight: 'bold',
};
