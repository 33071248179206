import { CSSObject } from '@chakra-ui/react';

export const BoxTakeActionTable: CSSObject = {
  bgGradient: 'linear(180deg, alpha.300 0%, alpha.900 100%)',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  border: '0',
  p: '3',
};

export const TextTakeAction: CSSObject = {
  fontWeight: 'medium',
};
