import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TFormikValues } from '../types';
import { useCloseAccount } from '../Services/Hooks.CloseAccount';
import { getStrings } from '@/helpers/utils';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const [
    {
      Account: {
        CloseAccount: {
          passwordErrorMessage,
          errorMessage,
          validationErrorMessage,
        },
      },
    },
  ] = getStrings();

  const [mutate] = useCloseAccount();

  return (
    <Formik<TFormikValues>
      initialValues={{
        password: '',
        confirmedBalance: false,
        confirmedReopen: false,
        confirmedPendingBets: false,
      }}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        password: IS_MOBILE_APP ? Yup.string() : Yup.string().required(),
        confirmedBalance: Yup.boolean().isTrue(validationErrorMessage),
        confirmedReopen: Yup.boolean().isTrue(validationErrorMessage),
        confirmedPendingBets: Yup.boolean().isTrue(validationErrorMessage),
      })}
      onSubmit={async (val, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          const passwordOK = await mutate(val.password);

          if (!passwordOK) {
            resetForm({
              values: { ...val, password: '' },
            });
            toast.error(passwordErrorMessage);
          }
        } catch (e) {
          toast.error(errorMessage);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
