import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { TCloseIcon } from './Header.styles';

export const closeIcon: TCloseIcon = {
  boxSize: '6',
  as: CloseCircle,
};

export const CloseIconButton: CSSObject = {
  bgGradient: 'none',
  border: 'none',
  justifyContent: 'end',
  _hover: {
    color: 'blackAlpha.400',
    bg: 'none',
  },
};

export const FlexBetslipHeading: CSSObject = {
  color: 'white',
  h: '44px',
  px: '3',
  py: '1',
  paddingBottom: '0px',
  borderBottomColor: 'blackAlpha.400',
  borderBottom: '2px',
  borderBottomStyle: 'dashed',
  '@media (max-width: 1279px)': {
    px: '2',
  },
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontFamily: 'caveman',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  color: 'white',
  mt: ['1', '0'],
};

export const BetLengthIndicator: CSSObject = {
  color: '#7A3B1E',
  fontSize: 'xs',
  borderRadius: 'base',
  fontFamily: 'Roboto',
  bgGradient: 'linear(to-b, #FCD236, #E79004)',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  fontWeight: '900',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'zeta.500',
  w: '4',
  h: '4',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
};

export const BetslipTitleText: CSSObject = {
  color: 'zeta.500',
  fontFamily: 'SkranjiRegular',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: 'md',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
};
