import { ButtonProps } from '@chakra-ui/react';

export const closeBtn: ButtonProps = {
  variant: 'unstyled',
  position: 'absolute',
  top: ['52vw', null, '24px'],
  right: [0, null, '33px'],
  color: 'delta.600',
  sx: {
    svg: {
      boxSize: 'var(--bc-space-6)',
      color: 'delta.600',
      fill: 'none',
      stroke: 'delta.600',
      strokeWidth: 2,
    },
  },
};
