import { CSSObject } from '@chakra-ui/react';
import { TStyleConfig } from '../types';

export type TFooterTheme = {
  footer: CSSObject;
  footerBar?: CSSObject;
  footerText?: CSSObject;
  footerLinks?: CSSObject;
  footerLink?: CSSObject;
  footerLinkTel?: CSSObject;
  footerDownloadImage?: CSSObject;
  customFooterLink?: CSSObject;
  footerContentContainer: CSSObject;
  partnerWrapper?: CSSObject;
  partnerImages?: CSSObject;
  imageWrapper?: CSSObject;
  stackGap: string;
  nav: CSSObject;
  blurb: CSSObject;
  blurbHeading: CSSObject;
  blurbHeadingAge: CSSObject;
  blurbHeadingThink: CSSObject;
  blurbContent: CSSObject;
  emailUs: CSSObject;
  copyright: CSSObject;
  copyrightContainer?: CSSObject;
  appVersionContainer?: CSSObject;
  container: CSSObject;
  quickLinks: CSSObject;
  ourPartners: CSSObject;
  weAccept: CSSObject;
  weAcceptBox: CSSObject;
  footerMobile: CSSObject;
};

export const footerBaseStyle: TFooterTheme = {
  footer: {
    label: 'footer',
    py: 6,
  },
  footerContentContainer: {
    label: 'footerContentContainer',
    flexDir: ['column', 'unset'],
    mb: ['8', 'unset'],
  },
  footerBar: {
    label: 'footerBar',
    display: 'none',
  },
  footerLink: {
    label: 'footerLink',
    display: 'none',
  },
  footerLinkTel: {
    label: 'footerLinkTel',
    display: 'none',
  },
  customFooterLink: {
    label: 'customFooterLink',
  },
  footerMobile: {
    label: 'footerMobile',
  },
  footerLinks: {
    label: 'footerLinks',
    display: 'none',
  },
  stackGap: '3',
  nav: {
    label: 'nav',
    alignItems: 'stretch',
    order: ['2', 'unset'],
    '> a': { fontSize: 'sm' },
  },
  blurb: {
    label: 'blurb',
    textAlign: ['center', 'right'],
    fontSize: 'sm',
    order: ['1', 'unset'],
    ml: [null, 'auto'],
    mb: ['8', null, 'unset'],
    mr: '5',
    width: [null, 'auto', '45%'],
    pr: [null, '7'],
  },
  blurbHeading: {
    label: 'blurbHeading',
    ml: [null, 'auto'],
    mt: '5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', 'unset'],
  },
  blurbHeadingAge: {
    label: 'blurbHeadingAge',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    borderColor: 'whiteAlpha.50',
    border: '1px',
    borderRadius: '50%',
    height: '10',
    width: '10',
    mr: '2',
  },
  blurbHeadingThink: {
    label: 'blurbHeadingThink',
    fontWeight: 'black',
    fontSize: 'md',
    pt: '2',
  },
  blurbContent: {
    label: 'blurbContent',
    mx: '0',
    px: ['2', '0'],
    ml: [null, null],
    mr: ['inherit'],
    mt: ['4', null, '0'],
    textAlign: ['center', 'left'],
  },
  emailUs: {
    label: 'emailUs',
    display: 'flex',
    justifyContent: ['center', null, 'unset'],
    textAlign: ['center', 'left'],
    flexDirection: ['column', null, 'row'],
    mb: ['5', null, '0'],
  },
  partnerWrapper: {
    label: 'partnerWrapper',
    width: '12',
    height: '7',
    bg: 'white',
    mr: [null, null, '2'],
    borderRadius: '2',
  },
  partnerImages: {
    label: 'partnerImages',
    height: '100%',
    padding: '1',
    margin: 'auto',
  },
  imageWrapper: {
    label: 'imageWrapper',
    justifyContent: ['center', 'left'],
    flexWrap: 'wrap',
    gap: '1',
    pt: ['0', null, '1'],
  },
  copyright: {
    label: 'copyRight',
    fontWeight: 'bold',
    fontSize: 'xs',
    textAlign: 'right',
  },
  copyrightContainer: {
    label: 'copyRightContainer',
    justifyContent: ['center', 'left'],
  },
  footerDownloadImage: {
    label: 'footerDownloadImage',
  },
  container: {
    label: 'footerContainer',
  },
  quickLinks: {
    label: 'quickLinks',
    fontWeight: 'bold',
    fontSize: 'lg',
    pb: '2',
  },
  ourPartners: {
    label: 'ourPartners',
    fontWeight: 'bold',
    fontSize: 'lg',
    pb: '2',
  },
  weAccept: {
    label: 'weAccept',
    fontWeight: 'bold',
    fontSize: 'lg',
    pb: '2',
  },
  weAcceptBox: {
    label: 'weAcceptBox',
    mb: '6',
  },
};

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: footerBaseStyle,
  sizes: {},
  variants: {},
};
