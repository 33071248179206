import { BoxProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const wrapper: BoxProps = {};
export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  variant: 'secondary',
};

export const buttonSubmitExclusionProps: CustomButtonProps = {
  variant: 'secondary',
  width: '100%',
};
