import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/buffalobet/images/close-filter.svg';

const primary = {
  borderRadius: 'base',
  border: '2px',
  borderColor: 'beta.400',
  bgGradient: 'linear(180deg, alpha.600 0%, alpha.800 100%)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  boxShadow:
    '0px 0px 0px 1px #000 inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.50) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    color: 'beta.900',

    _after: {
      opacity: 0,
    },
  },
  _active: {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.500',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',

    _after: {
      opacity: 0,
    },
  },
  _disabled: {
    bgGradient: 'linear(180deg, alpha.600 0%, alpha.800 100%)',
    boxShadow:
      '0px 1px 0px 3px rgba(255, 255, 255, 0.50) inset, 0px 0px 0px 3px #000 inset',
    color: 'white',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    opacity: 0.4,
    borderColor: 'beta.400',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },

  _after: {
    content: `''`,
    position: 'absolute',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(180deg, alpha.600 0%, alpha.800 100%)',
    transition: 'all .2s ease-in-out',
    m: '1px',
    opacity: 0.8,
  },
};

const secondary = {
  borderRadius: 'base',
  bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    color: 'beta.900',
    boxShadow:
      '0px 2px 8px 0px rgba(0, 0, 0, 0.20) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.50) inset',

    _after: {
      opacity: 0,
    },
  },
  _active: {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px -1px 0px 0px rgba(255, 255, 255, 0.16) inset',
    color: 'beta.500',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',

    _after: {
      opacity: 0,
    },
  },
  _disabled: {
    bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'white',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },

  _after: {
    content: `''`,
    position: 'absolute',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
    transition: 'all .2s ease-in-out',
    m: '1px',
    opacity: 0.8,
  },
};

const outline = {
  borderRadius: 'base',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.100',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  _hover: {
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    border: '2px',
    borderColor: 'beta.400',
    boxShadow: '0px 0px 8px 0px rgba(83, 249, 169, 0.40)',
  },
  _active: {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%))',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08) inset',
    color: 'beta.500',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  _disabled: {
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'white',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    opacity: 0.4,
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    tab: {
      boxShadow: 'none',
      border: 'none',
      fontSize: 'sm',
      textShadow: 'none',
      fontWeight: 'normal',
      borderRadius: 'none',
      fontFamily: 'Staatliches',
      h: '10',
      color: 'white',
      borderBottom: '4px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '2',
      pb: '1',
      _hover: {
        color: 'beta.500',
      },
      '&:active, &[data-active="true"]': {
        color: 'beta.500',
        fontWeight: 'normal',
        textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
        border: 'none',
        borderBottom: '4px solid',
        borderBottomColor: 'beta.500',
      },
    },
    odds: {
      borderRadius: 'base',
      bgGradient: 'linear(to-b, beta.400, beta.500)',
      color: 'beta.800',
      fontFamily: 'Staatliches',
      fontWeight: 'normal',
      fontSize: 'sm',
      textTransform: 'capitalize',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.60)',
      boxShadow:
        '0px 0px 0px 1px rgba(255, 255, 255, 0.30) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.30)',
      _hover: {
        bgGradient: 'linear(to-b, alpha.200 0%, alpha.400 100%)',
        boxShadow:
          '0px 0px 0px 1px rgba(255, 255, 255, 0.30) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.30)',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        color: 'white',
        border: 'none',

        _after: { opacity: 0 },
      },
      '&[data-closed="true"]': {
        bg: 'transparent',
        border: '2px',
        borderColor: 'blackAlpha.300',
        boxShadow: 'none',
        color: 'white',
        textShadow: '0px 2px 3px rgba(0, 0, 0, 0.30)',
        _after: { opacity: 0 },
        _hover: {
          _after: { opacity: 0.2 },
        },
      },
      '&:active, &[data-active="true"]': {
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
        color: 'beta.500',
        textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
        border: 'none',

        _after: { opacity: 0 },
      },
      _disabled: {
        bg: 'blackAlpha.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.20) inset',
        color: 'gray.300',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        opacity: 1,
        pointerEvents: 'none',
        _after: { opacity: 0 },
      },

      '&&&': {
        '& > svg, & > span': { zIndex: 9 },
      },

      _after: {
        content: `''`,
        position: 'absolute',
        inset: 0,
        zIndex: 8,
        bgGradient: 'linear(to-b, beta.400, beta.500)',
        transition: 'all .2s ease-in-out',
        m: '1px',
        opacity: 0.8,
      },
    },
    filter: {
      border: '2px',
      borderColor: 'beta.500',
      borderRadius: 'base',
      color: 'white',
      boxShadow: '0px 0px 8px 0px rgba(251, 201, 21, 0.50)',
      fontSize: '2xs',
      fontWeight: 'extrabold',
      h: '7',
      minW: 'auto',
      p: '2',
      textTransform: 'uppercase',
      bg: 'transparent',
      _hover: {
        color: 'beta.500',
      },
      _active: {
        bg: 'beta.500',
        borderColor: 'beta.500',
        color: 'beta.800',
        boxShadow: 'none',
        '&:after': {
          content: '""',
          position: 'absolute',
          display: 'block',
          boxSize: '14px',
          top: '4.4px',
          right: '2',
          borderRadius: 'full',
          pr: '2',
          backgroundImage: `url(${CloseButton})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',
      bg: 'transparent',

      _hover: {
        color: 'beta.800',
      },

      _active: {
        bg: 'beta.500',
        boxShadow: '0px 0px 8px 0px rgba(251, 201, 21, 0.50)',
        color: 'beta.800',
      },
    },
    unstyled: () => ({}),
  },
  defaultProps: {},
};
