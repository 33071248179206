import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    fontFamily: 'Montserrat',
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    display: 'flex',
    border: '2px solid black',
    borderRadius: 'md',
    p: '4',
    mb: '5',
    gap: '2',
    alignItems: 'center',
  },
  depositIconProps: {
    color: 'gamma.900',
    boxSize: '9',
  },
};
