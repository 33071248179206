/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center, Image } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import empty from '../../../../../../../assets/betroyale/images/betslip/emptyslip.png';

export const template: TTemplate = {
  wrapper: <Center />,
  empty: <Image src={empty} h="100%" m="4" />,
};

export default template;
