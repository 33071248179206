import { rest } from 'msw';
import { upcomingMatchesBySportEndpoint } from './upcomingMatchesBySport';
import { queryUpcomingMatchesBySportMock } from './upcomingMatchesBySport.mocks';
import { TQueryUpcomingMatchesBySportResponse } from './upcomingMatchesBySport.types';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

export const upcomingMatchesBySportHandlers = [
  rest.get(`${baseURL}${upcomingMatchesBySportEndpoint}`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json<TQueryUpcomingMatchesBySportResponse>(
        queryUpcomingMatchesBySportMock
      )
    )
  ),
];
