import menuTile from '@/assets/volcanobet/images/sidenav/menu-tile1x.png';
import menuTileRetina from '@/assets/volcanobet/images/sidenav/menu-tile.png';
import menuTop from '@/assets/volcanobet/images/sidenav/menu-top1x.png';
import menuTop2x from '@/assets/volcanobet/images/sidenav/menu-top.png';
import menuBottom from '@/assets/volcanobet/images/sidenav/menu-bottom1x.png';
import menuBottom2x from '@/assets/volcanobet/images/sidenav/menu-bottom.png';
import topVine from '@/assets/volcanobet/images/sidenav/vine-1.png';
import bottomVine from '@/assets/volcanobet/images/sidenav/vine-2.png';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: ['100%', null, null, '272px'],
    pt: ['0', null, '2'],
    pl: '0',
    pr: '0',
    mt: ['1', null, '7'],
    mb: ['2', null, '16'],
    bg: ['none', null, null, `url(${menuTile})`],
    bgRepeat: 'repeat-y',
    backgroundSize: ['inherit', null, null, '272px 100%'],
    borderRadius: 'xl',
    zIndex: '5',
    sx: {
      '@media (-webkit-min-device-pixel-ratio: 2)': {
        backgroundImage: ['none', null, null, `url(${menuTileRetina})`],
        backgroundSize: ['inherit', null, null, '272px'],
      },
    },
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-18px',
      width: '272px',
      height: '85px',
      zIndex: '-1',
      backgroundImage: ['none', null, null, `url(${menuTop})`],
      backgroundSize: ['inherit', null, null, '272px'],
      backgroundRepeat: 'no-repeat',
      '@media (-webkit-min-device-pixel-ratio: 2)': {
        backgroundImage: ['none', null, null, `url(${menuTop2x})`],
      },
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-27px',
      width: '272px',
      height: '85px',
      backgroundImage: ['none', null, null, `url(${menuBottom})`],
      backgroundSize: ['inherit', null, null, '272px'],
      backgroundRepeat: 'no-repeat',
      zIndex: '-1',
      '@media (-webkit-min-device-pixel-ratio: 2)': {
        backgroundImage: ['none', null, null, `url(${menuBottom2x})`],
      },
    },
  },
  navigationBoxProps: {
    _before: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      top: '-27px',
      right: '-19px',
      width: '80px',
      height: '120px',
      zIndex: '3',
      backgroundImage: `url(${topVine})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    _after: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      bottom: '-32px',
      left: '-16px',
      width: '75px',
      height: '122px',
      zIndex: '3',
      backgroundImage: `url(${bottomVine})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
  boxNavigationWrapperProps: {
    overflowX: 'visible',
  },
};
