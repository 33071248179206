/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Outlet } from 'react-router-dom';
import ResponsibleGamblingBanner from '../../../components/ResponsibleGamblingBanner/ResponsibleGamblingBanner';
import Betslip from '../../../components/Betslip/Betslip';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Headers/HeaderPrimary/Header';
import NextToJumpBanner from '../../../components/NextToJumpBanner/NextToJumpBanner';
import SideNav from '../../../components/SideNav/SideNav';
import { TTemplate } from '@/helpers/createTemplate';
import {
  GridHeader,
  GridItemContent,
  GridItemResponsibleGambling,
  GridLayout,
  GridSubLayout,
} from '../PrimaryLayout.styles';

const template = (): TTemplate => ({
  wrapper: <GridLayout />,
  header: (
    <GridHeader gridArea="header">
      <NextToJumpBanner />
      <Header />
    </GridHeader>
  ),
  main: {
    wrapper: <GridSubLayout gridArea="main" />,
    sideNav: <SideNav />,
    outlet: (
      <GridItemContent gridArea="content">
        <Outlet />
      </GridItemContent>
    ),
    betslip: <Betslip />,
  },
  footer: <Footer />,
  responsibleGamblingMessage: (
    <GridItemResponsibleGambling gridArea="responsibleGamblingMessage">
      <ResponsibleGamblingBanner />
    </GridItemResponsibleGambling>
  ),
});

export default template;
