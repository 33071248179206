import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  mb: '0',
  gap: '2',
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'solid',
  fontFamily: 'body',
  fontWeight: 'bold',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
};
