import { CSSObject } from '@chakra-ui/react';
import featuredCardsBg from '@/assets/juicybet/images/backgrounds/inner.png';
import featuredRaceBg from '@/assets/juicybet/images/backgrounds/featuredRaceBg.png';

const CARD_MARGIN = '2';

export const GridCardContent: CSSObject = {
  mx: CARD_MARGIN,
};

export const IconCardHeader: CSSObject = {
  color: 'gamma.400',
};

export const FlexFeatureWrapper: CSSObject = {
  px: 0,
  py: 0,
  flexDir: ['column', 'row'],
  mr: ['unset', '4'],
};

export const HeadingSlogan: CSSObject = {
  mt: ['7', null, '12'],
  color: '#FCE72E',
  fontSize: ['4xl', null, '6xl'],
  fontFamily: 'Chewy',
  fontWeight: 'normal',
  textShadow: [
    '0px 4px 5px rgba(0, 0, 0, 0.35), 0px 3px 0px #B09E00',
    '0px 7px 8px rgba(0, 0, 0, 0.35), 0px 5px 0px #B09E00',
  ],
};

export const FlexFeatureCardWrapper: CSSObject = {
  pr: ['unset', '5'],
  mr: ['1.5', 'unset'],
};

export const FlexFeatureTitleWrapper: CSSObject = {
  minH: ['56', null, 'auto'],
  mx: ['5', 'unset'],
  mt: ['-5', 'unset'],
  mb: ['-1.5', 'unset'],
  contain: 'cover',
  flex: '2',
  bgRepeat: 'no-repeat',
  bgSize: ['100% 100%', '110% 100%'],
  bgImage: `url('${featuredRaceBg}')`,
  justifyContent: 'start',
};

export const TextCardHeader: CSSObject = {
  fontWeight: 'bold',
  fontFamily: 'Chewy',
  textShadow: '0px 2px 6px rgba(0, 0, 0, 0.30)',
  fontSize: 'md',
  letterSpacing: 'wide',
  color: 'white',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.300',

  _last: {
    border: 'none',
  },

  '&:nth-last-child(2)': {
    border: 'none',
  },
};

export const TextRunner: CSSObject = {
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'semibold',
};

export const TextRunnerNumber: CSSObject = {
  fontWeight: 'semibold',

  '&:last-child': {
    color: 'white',
    fontSize: '2xs',
  },
};

export const BoxCard: CSSObject = {
  my: ['0', null, '1'],
  mb: ['1', null, '0'],
  border: '3px solid',
  borderColor: 'alpha.50',
  width: '264px',
  borderRadius: 'xl',
  boxShadow:
    '0px 0px 4px 5px rgba(0, 0, 0, 0.15) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
  bg: `linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%), url(${featuredCardsBg})`,
  bgRepeat: 'repeat',
  bgSize: '95%',
  backgroundBlendMode: 'overlay',
};

export const TextRaceNumber: CSSObject = {
  bg: 'alpha.800',
  borderRadius: 'sm',
  color: 'white',
  fontSize: '9px',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'beta.600',
  px: '1.5',
  color: 'gamma.400',
  textShadow: '0px 2px 2px rgba(0, 0, 0, 0.20)',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  boxShadow: 'inset 0 7px 8px -10px #000, inset 0 -7px 8px -10px #000',
  _first: {
    textAlign: 'start',
    borderLeftRadius: 'base',
  },
  ':nth-child(2)': {
    textAlign: 'end',
    borderRightRadius: 'base',
  },
};

export const Button = (): CSSObject => ({
  borderRadius: 'md',
  fontFamily: 'roboto',
  color: 'alpha.900',
  minW: '52px',
  fontSize: 'sm',
  px: '1',
});

export const LinkRaceDetails: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
  color: 'alpha.700',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'Coiny',
  borderRadius: 'md',
  borderWidth: '2px',
  borderColor: 'white',
  letterSpacing: 'wide',
  mx: CARD_MARGIN,
  my: '2.5',

  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },

  _focus: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },

  _disabled: {
    bg: 'gray.300',
    borderColor: 'gray.600',
    color: 'blackAlpha.200',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.60) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  },
};
