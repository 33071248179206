import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/titanbet/images/header/logo.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          alt={THEME_NAME.titanbet}
          mx="auto"
          pb={2}
          maxW={170}
        />
      </Show>
      <Hide above="lg">
        <Image src={logo} alt={THEME_NAME.titanbet} h="14" />
      </Hide>
    </Link>
  );
}
