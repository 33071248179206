import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderColor: 'blackAlpha.300',
  color: 'white',
  px: '2',

  _hover: {
    bg: 'whiteAlpha.200',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'white',
  borderRadius: 'md',
  mx: ['2', '3'],
  mb: ['2', '3'],
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  border: '2px',
  borderColor: 'epsilon.500',
};

export const Icon: CSSObject = {
  bgGradient: 'linear(to-b, gamma.400, gamma.500)',
  color: 'alpha.500',
  borderRadius: 'base',
};

export const TextRace: CSSObject = {
  color: 'gray.700',
  fontWeight: 'medium',
};

export const TextNumber: CSSObject = {
  bg: 'beta.200',
  color: 'beta.700',
  p: '1',
  w: 'fit-content',
  borderRadius: 'base',
};

export const BoxHeading: CSSObject = {
  display: 'none',
};

export const IconHeading: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'full',
  boxSize: '7',
  p: '1.5',
};
