import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    '&:disabled': { bg: 'transparent' },

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px',
      borderTopColor: 'blackAlpha.200',
      p: '2',

      _first: { borderTop: 0 },
    }),
    ...(!!isSelected && {}),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',

    _first: {
      color: 'gamma.200',
      fontWeight: 'bold',

      'button:hover &': { color: 'gamma.200' },

      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      bgGradient: isSelected
        ? 'linear(to-b, blackAlpha.500, blackAlpha.500)'
        : 'linear(to-b, gamma.500, gamma.600)',
      p: 2,
      w: 'full',
      borderRadius: 'md',
      color: isSelected ? 'gamma.500' : 'gamma.900',
      fontWeight: 'black',
      textShadow: isSelected
        ? 'unset'
        : '0px 1px 0px rgba(255, 255, 255, 0.30)',
      boxShadow: isSelected
        ? '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)'
        : '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset',
      border: 'none',

      transition: 'all, .3s ease-in-out',

      _hover: {
        bg: 'gamma.300',
        boxShadow:
          'box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset',
        color: 'gamma.900',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
