import * as betgalaxy from './HeaderRow.styles.betgalaxy';
import * as betprofessor from './HeaderRow.styles.betprofessor';
import * as betroyale from './HeaderRow.styles.betroyale';
import * as fiestabet from './HeaderRow.styles.fiestabet';
import * as puntcity from './HeaderRow.styles.puntcity';
import * as puntgenie from './HeaderRow.styles.puntgenie';
import * as questbet from './HeaderRow.styles.questbet';
import * as sterlingparker from './HeaderRow.styles.sterlingparker';
import * as vikingbet from './HeaderRow.styles.vikingbet';
import * as volcanobet from './HeaderRow.styles.volcanobet';
import * as wellbet from './HeaderRow.styles.wellbet';
import * as goldenrush from './HeaderRow.styles.goldenrush';
import * as juicybet from './HeaderRow.styles.juicybet';
import * as forsport from './HeaderRow.styles.forsport';
import * as junglebet from './HeaderRow.styles.junglebet';
import * as oldgill from './HeaderRow.styles.oldgill';
import * as sugarcastle from './HeaderRow.styles.sugarcastle';
import * as buffalobet from './HeaderRow.styles.buffalobet';
import * as titanbet from './HeaderRow.styles.titanbet';
import * as slambet from './HeaderRow.styles.slambet';
import * as gigabet from './HeaderRow.styles.gigabet';
import * as templebet from './HeaderRow.styles.templebet';
import * as goldenbet888 from './HeaderRow.styles.goldenbet888';
import * as bet777 from './HeaderRow.styles.bet777';
import * as chromabet from './HeaderRow.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  goldenrush,
  juicybet,
  forsport,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  templebet,
  goldenbet888,
  bet777,
  chromabet,
};
