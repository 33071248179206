import React from 'react';
import { Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { homeLinkStyles } from './styles/HomeLinks.styles';
import { IconSport } from '@/views/sports/AllSports/styles/AllSports.styles';
import { useSideNavList } from '@/components/SideNav/hooks/SideNav.hooks';

export default function HomeLinksForRace() {
  const { nav } = useSideNavList();
  const racingLinks = nav.filter((link) => link.to?.startsWith('/Racing'));

  return (
    <Flex {...homeLinkStyles.container}>
      <Flex {...homeLinkStyles.racingHeader}>
        <FormattedMessage id="generic.racing" />
      </Flex>
      <Flex {...homeLinkStyles.racingBtnsContainer}>
        {racingLinks.map((link) => (
          <LinkBox key={link.to} {...homeLinkStyles.linkBox}>
            <LinkOverlay href={link.to} {...homeLinkStyles.linkOverlay}>
              <IconSport
                name={link.svg}
                {...(homeLinkStyles.racingIcon as any)}
              />
              <Text {...homeLinkStyles.linkBoxText}>{link.text}</Text>
            </LinkOverlay>
          </LinkBox>
        ))}
      </Flex>
    </Flex>
  );
}
