import { CSSObject } from '@chakra-ui/react';

export const FlexBetslipHeading: CSSObject = {
  h: '12',
  borderBottomColor: 'beta.500',
  borderBottom: '3px solid',
  color: [null, null, null, null, 'white'],
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'beta.500',
  fontFamily: 'KHInterference',
};

export const BetLengthIndicator: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
  bg: 'transparent',
};

export const BetslipHeaderIcon: CSSObject = {
  display: 'none',
};

export const CloseIconButton: CSSObject = {
  justifyContent: 'end',
  border: 'none',
  bg: 'none',
  boxSize: '6',
  svg: {
    boxSize: '6',
    color: 'beta.500',
    borderRadius: 'full',
  },
  _hover: {
    border: 'none',
    bg: 'none',
  },
};
