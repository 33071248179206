import { Box, chakra } from '@chakra-ui/react';
import { styleImports } from './PromotionsPreferences.styles.imports';

import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FormWrapper = chakra(Box, {
  label: 'promotionPreferences-FormWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FormWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
