import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  borderColor: '#FFE6B6',
  bg: 'white',
  borderWidth: '2px',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px #000 inset',
};

export const ExoticSelectionText: CSSObject = {
  color: 'gray.400',
  fontWeight: '500',
};

export const DividerCard: CSSObject = {
  borderColor: 'gray.300',
};

export const ContentRow: CSSObject = {
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'alpha.400',
    bg: 'black',
    border: '2px solid black',
  },
};

export const dividerExoticProps: DividerProps = {
  w: '100%',
};