import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/junglebet/images/backgrounds/login-bg.png';
import bgMobile from '@/assets/junglebet/images/backgrounds/login-bg-mobile.png';
import bgRepeat from '@/assets/junglebet/images/backgrounds/texture-wood-1x.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${bgMobile}), url(${bgRepeat})`,
    null,
    `url(${bgDesktop}), url(${bgRepeat})`,
  ],
  bgPos: ['initial', 'center calc(100% - 51px),center', null, 'initial'],
  bgRepeat: ['no-repeat, repeat', null, 'no-repeat, repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'flex-end',
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['363px', '70vw', 'full'],
  borderLeftRadius: ['unset', null, 'md'],
  '&&': {
    bgSize: ['100%', null, null],
  },
};
