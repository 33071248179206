import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: ['1', null, '4'],
  mx: ['2', null, null, 'unset'],
  minH: '50vh',

  borderBottomRadius: '6px',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['4'],
  px: ['2', 0],
  mx: ['-2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  border: 0,
  bg: 'white',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  mb: '2',
  mt: '4',
};
export const TextName: CSSObject = {
  color: 'gray.700',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
};

export const LinkItem: CSSObject = {
  display: 'flex',
  px: '3',
  py: '2',
  gap: '2',
  alignItems: 'center',
  alignSelf: 'stretch',
  background: 'white',
  borderTopColor: 'rgba(164, 82, 20, 1)',
  borderBottom: 0,
  borderBottomColor: ['transparent'],
  _hover: {
    background: 'gamma.100',
  },
};

export const IconSport: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSize: '6',
  mr: '1',
  color: 'alpha.700',
};

export const IconChevron: CSSObject = {
  boxSize: '6',
  p: '1',
  transition: '.3s ease-in-out',
  color: 'gray.700',

  _hover: {
    color: 'beta.800',
  },
};
