import { CSSObject } from '@chakra-ui/react';
import backgroundSport from '@/assets/junglebet/images/backgrounds/backgroundSport.png';

export const BoxSectionWrapper: CSSObject = {
  borderRadius: 'lg',
  bg: `url(${backgroundSport})`,
  mt: ['3', '4'],
  boxShadow:
    '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 5px 0px 0px #541E01, 0px 2px 2px 0px #D17917, 0px -2px 1px 0px rgba(178, 78, 5, 0.50) inset',
};

export const BoxWrapper: CSSObject = {
  padding: '3',
  color: 'delta.50',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
};

export const TextHeading: CSSObject = {
  color: 'delta.50',
  fontFamily: 'CarterOne',
  fontWeight: 'normal',
};

export const ButtonViewMore: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'beta.500',
  border: 'none',
  textAlign: 'center',
  fontSize: 'bold',
  fontFamily: 'CarterOne',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'title',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset',

  _hover: {
    bg: 'blackAlpha.500',
  },
};
