import { CSSObject } from '@chakra-ui/react';
import featuredRace from '@/assets/goldenrush/images/backgrounds/featureRaceHome.png';
import featuredRaceMobile from '@/assets/goldenrush/images/backgrounds/featureRaceHomeMobile.png';

export const FlexFeatureTitleWrapper: CSSObject = {
  bgImage: [`url(${featuredRaceMobile})`, 'none'],
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  bgPosition: 'top center',
  minH: '245px',
  w: '100%',
  h: '366px',
};

export const FlexFeatureWrapper: CSSObject = {
  bgImage: ['none', `url(${featuredRace})`],
  bgSize: '100% 100%',
  bgRepeat: 'no-repeat',
  bgPosition: '0 100%',
  p: '0',
  minH: ['333px', '400px'],
  w: ['99%', '98%', '99%', '100%'],
  mb: ['6', '2.5'],
  mt: ['-4', '0'],
  mx: 'auto',
};

export const FlexFeatureCardWrapper: CSSObject = {
  pr: ['1', '10'],
  pl: ['1', '230px'],
  pb: ['0', '4'],
};

export const BoxCard: CSSObject = {
  position: 'relative',
  width: ['100%', null, null, '100%'],
  mt: ['0', null, '5'],
  mb: ['1', null, '0'],
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  borderRadius: 'lg',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
  p: '9px',
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '1',
    right: '1',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    zIndex: '3',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    boxShadow: '0px 1px 0px 0px #ED8934',
  },
  _after: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '2',
    left: '2',
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    zIndex: '4',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
  },
};

export const LinkCardHeader: CSSObject = {
  color: 'white',
  zIndex: '5',
  pos: 'relative',

  '.chakra-badge.state-inprogress': {
    bg: 'beta.400',
    color: 'gamma.800',
    height: '3',
    borderRadius: 'base',
    maxW: '40px',
    py: '0',
  },

  '.chakra-badge.state-prior': {
    bg: 'alpha.200',
    color: 'blackAlpha.700',
    height: '3',
    borderRadius: 'base',
    maxW: '40px',
    py: '0',
  },

  '.chakra-badge.state-ended': {
    bg: 'gamma.500',
    color: 'gamma.800',
    height: '3',
    borderRadius: 'base',
    maxW: '40px',
    py: '0',
  },

  _hover: {
    bg: 'alpha.600',
  },
};

export const GridCardContent: CSSObject = {
  bg: 'epsilon.200',
  color: 'white',
  fontSize: 'sm',
  zIndex: '5',
  pos: 'relative',
};

export const IconCardHeader: CSSObject = {
  color: 'beta.400',
};

export const TextCardHeader: CSSObject = {
  fontWeight: 'bold',
};

export const TextRaceNumber: CSSObject = {
  borderRadius: 'base',
  bg: 'alpha.500',
  color: 'white',
  fontWeight: 'bold',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'alpha.700',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 'semibold',
  ':first-child': {
    pl: '3',
  },
  lineHeight: 'normal',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '1px dotted',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  _last: {
    border: 'none',
  },
  '&:nth-last-child(2)': {
    border: 'none',
  },
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  '> span': {
    color: 'white',
    fontWeight: 'bold',
  },
};

export const Button = (): CSSObject => ({
  transition: 'all .3s linear',
  zIndex: '5',
  borderRadius: 'lg',
});

export const LinkRaceDetails: CSSObject = {
  bgColor: 'alpha.700',
  color: 'beta.400',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
  fontFamily: 'Bungee',
  fontWeight: 'normal',
  p: '0.6rem 0.5rem 0.5rem 0.5rem',
  zIndex: '5',
  pos: 'relative',
  borderBottomRadius: 'base',
  boxShadow: '0px -1px 5px 1px rgba(0, 0, 0, 0.30) inset',
  borderTop: '1px solid #2C6070',

  _hover: {
    bgGradient: 'linear(180deg, alpha.600, alpha.700)',
  },
};
