import { Box, chakra, Text } from '@chakra-ui/react';
import { EThemes } from '@/constants/themeConfig';

export const BoxHeadingWrapper = chakra(Box, {
  baseStyle: () => ({}),
});

export const TextHeading = chakra(Text, {
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Wellbet && {
      display: 'flex',
      minH: '53px',
      bg: 'black',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      mb: '5',
      borderRadius: 'md',
      fontSize: '2xl',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontStyle: 'italic',
      p: '4',
      textAlign: 'center',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: ['2xl', null, '4xl'],
      color: 'gamma.50',
      textTransform: 'uppercase',
      fontStyle: 'italic',
      fontWeight: 'extrabold',
      textAlign: ['left', null, 'center'],
      width: ['50%', null, 'unset'],
      mb: '4',
    }),
  }),
});

export const TextSubtitle = chakra(Text, {
  baseStyle: ({ theme }) => ({
    mt: '2',

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'md',
      mb: '5',
      fontWeight: 'semibold',
    }),
  }),
});
