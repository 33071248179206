import { Link, LinkProps } from 'react-router-dom';
import { chakra, Flex, Box } from '@chakra-ui/react';
import { styleImports } from './TopNav.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const TopNavContainer = chakra(Flex, {
  label: 'TopNavContainer',
  baseStyle: ({ theme }) => ({
    width: '100%',
    px: '4',
    mb: '-9',
    mt: '7',

    ...(theme.themeName === EThemes.Wellbet && {
      mt: '0',
      px: '0',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TopNavContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TopNavWrapper = chakra(Flex, {
  label: 'topNav-TopNavWrapper',
  baseStyle: ({ theme }) => ({
    width: '100%',
    maxW: '1200px',
    mx: 'auto',
    my: '0',
    justifyContent: 'flex-start',
    overflowX: 'auto',

    ...(theme.themeName === EThemes.Wellbet && {
      gap: '1',
      '&::-webkit-scrollbar': { display: 'none' },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      gap: '2',
      px: '1',
      pb: '2',
      pt: '0.5',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TopNavWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TNavLink = {
  isActive: boolean;
} & LinkProps &
  any;
export const NavLink = chakra<TNavLink>(Link, {
  label: 'topNav-NavLink',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ theme, isActive }: any) => ({
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
    transition: 'all .1s ease-in-out',
    whiteSpace: 'nowrap',
    bg: isActive ? 'currentColor' : 'unset',
    color: isActive ? 'blackAlpha.200' : 'currentColor',
    py: '4',
    px: '3',
    fontSize: 'sm',
    fontWeight: 'bold',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: isActive
        ? 'gray.300'
        : 'linear-gradient(180deg, var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
      color: isActive ? 'gray.600' : 'white',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '2xs',
      px: '2',
      py: '1',
      borderRadius: 'base',
      boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',

      '&:hover, &:focus': {
        color: isActive ? 'gray.700' : 'white',
        bg: isActive
          ? 'gray.400'
          : 'linear-gradient(180deg, var(--bc-colors-alpha-700) 0%, var(--bc-colors-alpha-800) 100%)',
      },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: isActive
        ? 'beta.500'
        : 'linear-gradient(180deg, #E3E3E3 0%, #F2F2F2 100%)',

      color: isActive ? 'white' : 'beta.700',
      textTransform: 'uppercase',
      fontWeight: 'semibold',
      fontSize: 'xs',
      py: '2',
      px: '3',
      border: '1px solid',
      borderColor: isActive ? 'beta.500' : 'gray.300',
      borderRadius: 'full',
      transition: 'all .2s ease-in-out',

      boxShadow: isActive
        ? 'inset 0px 2px 3px rgba(0, 0, 0, 0.25)'
        : '0px 1px 0px #FFFFFF, 0px 2px 5px rgba(21, 24, 56, 0.35), inset 0px 2px 0px rgba(255, 255, 255, 0.4)',

      '&:hover, &:focus': {
        color: isActive ? 'white' : 'beta.700',
        bg: isActive ? 'beta.300' : 'white',
        boxShadow: isActive
          ? 'inset 0px 2px 3px rgba(0, 0, 0, 0.25)'
          : '0px 1px 0px rgba(255, 255, 255, 0.5), 0px 2px 5px rgba(21, 24, 56, 0.4)',
      },
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.NavLink?.(isActive);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const NavLinkLabel = chakra(Box, {
  label: 'NavLinkLabel',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.NavLinkLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

// FIX: Temp solution to wider styling refactor
export const TopNavLabelWrapper = chakra(Flex, {
  label: 'topNav-TopNavLabelWrapper',
  baseStyle: ({ theme }) => ({
    width: '100%',
    maxW: '1200px',
    mx: 'auto',
    my: '0',
    justifyContent: 'flex-start',
    overflowX: 'auto',

    ...(theme.themeName === EThemes.Wellbet && {
      gap: '1',
      px: '2',
      '&::-webkit-scrollbar': { display: 'none' },
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      gap: '2',
      px: '1',
      pb: '2',
      pt: '0.5',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TopNavLabelWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
