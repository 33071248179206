import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import { Mascot } from '../../Maintenance/styles/Maintenance.styles';
import GlobalStyles from '../../../layouts/SecondaryLayout/conditionals/GlobalStyles/template';
import Content from '../Content';

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  heading: <Mascot />,
  main: {
    wrapper: <CenterWrapper />,
    body: (
      <>
        <GlobalStyles />
        <Content />
      </>
    ),
  },
};

export default template;
