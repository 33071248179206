import { CSSObject } from '@chakra-ui/react';
import onboardingWelcome from '../../../assets/volcanobet/images/backgrounds/onboardingWelcome.png';
import onboardingMobile from '../../../assets/volcanobet/images/backgrounds/onboardingMobile.png';

export const TextTitle: CSSObject = {
  fontSize: '4xl',
  textAlign: ['center', 'justify'],
  fontWeight: 'normal',
  lineHeight: '7',
  display: 'block',
  mt: ['3', null, '7'],
  ml: ['unset', null, '10'],
  mb: ['4', '3'],
  flex: [null, 1, 'unset'],
  alignItems: 'flex-start',
  height: ['100%', 'unset'],
  fontFamily: 'Caveman',
  flexDir: 'column',
  w: ['auto', '70%'],
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
};

export const TitleContainer: CSSObject = {
  color: 'white',
};

export const TextTitleSuffixContent: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'transparent',
  bgClip: 'text',
  textShadow: 'none',
  filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.5))',
};

export const FirstWord: CSSObject = {
  textShadow: 'none',
  filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.5))',
};

export const TextSubtitle: CSSObject = {
  fontSize: 'xs',
  lineHeight: '4',
  color: 'white',
  flex: '1',
  mt: ['unset', null, '2'],
  ml: ['2', null, '10'],
  textAlign: 'left',
  w: '220px',
};

export const TextSubtitleBoldWord: CSSObject = {
  color: 'gamma.500',
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  mb: ['0', null, '4'],
  color: 'white',
  pb: '8',
  textAlign: 'center',
  mt: [null, null, '96'],
};

export const TextCTALink: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'bold',
  mb: ['6', null, '4'],
};

export const FlexAsideInfo: CSSObject = {
  flexDir: 'column',
  alignItems: ['center', null, 'flex-start'],
  zIndex: 'overlay',
  mt: ['3', null, '10'],
  textAlign: 'center',
  borderBottom: '3px solid',
  borderColor: ['gamma.500', 'transparent', null],

  img: {
    ml: ['3', '-3', '10'],
  },
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  minH: ['336px', null, '100%'],
};

export const FlexCTAContainer: CSSObject = {
  justifyContent: 'center',
  alignSelf: ['center', null, 'unset'],
  width: '100%',
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    backgroundImage: `url(${onboardingMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    '@media (min-width: 480px)': {
      background: `url('${onboardingWelcome}'), linear-gradient(180deg, #67335E 0%, #67335E 100%)`,
      backgroundPosition: 'top, bottom',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
};
