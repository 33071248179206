import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { ErrorCircle } from '@styled-icons/fluentui-system-filled/ErrorCircle';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';

import { CashoutStatus } from '@/api/punter/punter.types';

export const getIconAndColor = (status: CashoutStatus) => {
  switch (status) {
    case CashoutStatus.Success:
    case CashoutStatus.OddsChangeAutoAccept:
      return { icon: CheckCircleFill, color: 'green.500' };
    case CashoutStatus.OddsChangeRequireAccept:
      return { icon: DownArrow, color: 'red.500' };
    case CashoutStatus.Error:
    case CashoutStatus.CustomerNotEligible:
    case CashoutStatus.BetNotEligible:
    case CashoutStatus.BetDoesntExist:
    case CashoutStatus.BetAlreadySettled:
    case CashoutStatus.CashoutDisabled:
      return { icon: ErrorCircle, color: 'red.500' };
    default:
      return { icon: ErrorCircle, color: 'gray.500' };
  }
};
