import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  border: '2px',
  borderColor: 'gamma.500',
  bg: 'alpha.500',
  borderRadius: 'lg',
  mb: '2',
  flex: '1',
  color: 'white',
};

export const FlexEventRule: CSSObject = {
  color: 'white',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '1',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, gamma.300, gamma.400)',
  color: 'alpha.900',
  fontSize: '2xs',
  '&&': {
    h: '6',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'white',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '30px',
  border: 'none',
  bg: 'gray.400',
  borderRadius: 'lg',
  color: 'gray.600',
  fontWeight: 'bold',
  fontSize: 'sm',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
};
