import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordTooltip,
  TPasswordRule,
  TPasswordRulesIcon,
} from '../services/SignUp.types';

export const SignupAcknowledgementButton: CSSObject = {
  fontWeight: 'semibold',
  textDecoration: 'none',
  h: 'auto',
  mb: 'px',
  span: {
    color: 'beta.400',
  },
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'blue.500',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'beta.400' : 'gamma.400',
  p: '3',

  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-beta-400) !important'
      : 'var(--bc-colors-gamma-400) !important',
  },
});

export const PasswordRulesContainer: CSSObject = {
  p: '0',
};

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  color: active ? 'white' : 'gamma.700',
  fontSize: 'xs',
  opacity: '1',

  _last: {
    mb: '0',
  },
});

export const BoxManualAddressContainer: CSSObject = {
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: ['2', null, '4'],
};

export const CheckboxHelpText: CSSObject = {
  '&&': {
    fontWeight: 'normal',
    fontSize: 'xs',
  },
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    direction={['column', null, 'row']}
    mb={['4', null, '0']}
    spacing={['0', null, '2']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="0.5" />
);

export const SignUpDatePicker: CSSObject = {
  '.react-date-picker__inputGroup': {
    color: 'gray.700',
    fontWeight: 'normal',
  },
  '.react-date-picker__calendar': {
    zIndex: '10',
  },
};

export const SignUpButton: CSSObject = {
  _after: {
    bg: 'none',
    boxShadow: 'none',
  },
  bgGradient: 'linear(to-b,delta.300,delta.600)',
  color: 'epsilon.300',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
  _hover: {
    bg: 'delta.300',
    color: 'delta.500',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    border: '2px solid',
    borderColor: 'epsilon.500',
  },
};
