import {
  AccordionButtonProps,
  AccordionItemProps,
  AccordionPanelProps,
  AccordionProps,
  FlexProps,
  SkeletonProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './CompetitionList.styles.imports';
import { TIndex } from '@/components/FormElements/Input';
import { TIconProps } from '@/components/common/IconSvg';

const themeName = getThemeName();

export type CompetitionListSchema = Partial<{
  skeleton: SkeletonProps;
  input: TIndex;
  inputRightIcon: TIconProps;
  accordion: AccordionProps;
  accordionItem: AccordionItemProps;
  accordionButton: AccordionButtonProps;
  hStack: StackProps;
  flex: FlexProps;
  accordionButtonIconSvg: TIconProps;
  accordionButtonText: TextProps;
  marketTypesFlex: FlexProps;
  marketTypesText: TextProps;
  accordionButtonInnerIcon: TIconProps;
  accordionPanel: AccordionPanelProps;
}>;

export const competitionListStyles: CompetitionListSchema = {
  accordionPanel: {
    'data-testid': 'competitionList-accordionPanel',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.accordionPanel;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButtonInnerIcon: {
    'data-testid': 'competitionList-accordionButtonInnerIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles
          ?.accordionButtonInnerIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketTypesText: {
    'data-testid': 'competitionList-marketTypesText',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.marketTypesText;
      } catch (err) {
        return {};
      }
    })(),
  },
  marketTypesFlex: {
    'data-testid': 'competitionList-marketTypesFlex',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.marketTypesFlex;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButtonText: {
    'data-testid': 'competitionList-accordionButtonText',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles
          ?.accordionButtonText;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButtonIconSvg: {
    'data-testid': 'competitionList-accordionButtonIconSvg',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles
          ?.accordionButtonIconSvg;
      } catch (err) {
        return {};
      }
    })(),
  },
  flex: {
    'data-testid': 'competitionList-flex',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.flex;
      } catch (err) {
        return {};
      }
    })(),
  },
  hStack: {
    'data-testid': 'competitionList-hStack',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.hStack;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButton: {
    'data-testid': 'competitionList-accordionButton',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.accordionButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionItem: {
    'data-testid': 'competitionList-accordionItem',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.accordionItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordion: {
    'data-testid': 'competitionList-accordion',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.accordion;
      } catch (err) {
        return {};
      }
    })(),
  },
  inputRightIcon: {
    'data-testid': 'competitionList-inputRightIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.inputRightIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  input: {
    'data-testid': 'competitionList-input',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.input;
      } catch (err) {
        return {};
      }
    })(),
  },
  skeleton: {
    'data-testid': 'competitionList-skeleton',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionListStyles?.skeleton;
      } catch (err) {
        return {};
      }
    })(),
  },
};
