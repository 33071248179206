import React from 'react';
import { Image, Show, Hide } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import logo from '@/assets/betprofessor/images/logo/h4x.png';
import logoMobile from '@/assets/betprofessor/images/logo/logo117.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function Index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          alt={`${THEME_NAME.betprofessor}`}
          data-testid={`${THEME_NAME.betprofessor}_desktop`}
          mx="1"
          mb="1"
          w="270px"
          h={{ base: '8', lg: 'auto' }}
          filter="drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))"
        />
      </Show>
      <Hide above="lg">
        <Image
          mt="1"
          minW="117px"
          w="full"
          src={logoMobile}
          alt={`${THEME_NAME.betprofessor}`}
          data-testid={`${THEME_NAME.betprofessor}_mobile`}
        />
      </Hide>
    </Link>
  );
}
