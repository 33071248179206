import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import navBackgound from '@/assets/fiestabet/images/sideNav/navBg.png';

import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'white',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        boxShadow: [
          'unset',
          null,
          null,
          '0px 5px 7px 0px rgba(0, 0, 0, 0.50) inset, 0px -5px 7px 0px rgba(0, 0, 0, 0.25) inset',
        ],
        borderRadius: [null, null, null, '2xl'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
        w: 'auto',
        mx: ['0', null, null, '3.5'],
        px: ['0', null, null, '2'],
        bgImage: `url(${navBackgound})`,
        bgSize: 'cover',
        bgRepeat: 'repeat',
      },
      drawer: {
        ...base.drawer,
      },
      drawerBody: {
        '> div, a': {
          ml: ['0', null, '2'],

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '6',
        color: ['white', null, null, 'delta.500'],
        fill: ['white', null, null, 'delta.500'],
      },
      headerSX: {
        cursor: 'default',
        height: '5',
        pt: '6',
        pb: ['4', null, null, '2'],
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
          bg: 'transparent',
          boxShadow: 'unset',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
        px: '1.5',
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,
        fontFamily: 'Ultra',
        fontSize: 'sm',
        fontWeight: 'normal',
        height: ['12', null, null, '10'],
        color: 'delta.700',
        transition: 'all, 0.2s linear',
        borderBottom: '1px dashed',
        // no token for this
        borderBottomColor: 'rgba(131, 9, 13, 0.27)',
        lineHeight: 'normal',
        py: '4',
        px: ['2', null, null, '1.5'],
        ml: '0',
        '~ .nested': {
          pl: '10',
        },

        ':hover': {
          // no token for this
          bg: 'rgba(197, 151, 13, 0.74)',
          boxShadow:
            '0px 3px 3px 0px rgba(0, 0, 0, 0.10) inset, 0px -4px 3px 0px rgba(0, 0, 0, 0.10) inset',
        },

        '.icon, .svgIcon': {
          transition: 'all, 0.2s linear',
          color: 'delta.700',
          fill: 'delta.700',
        },

        '.dropDownIcon': {
          fill: 'none',
          color: 'delta.700',
        },
      },

      dropDownContainerClosed: {
        '> a, .dropDownIcon, a': {
          color: 'delta.700',
        },

        '&:hover, &:active': {
          color: 'delta.700',

          '.dropDownInner': {
            ...base.dropDownInner,
            h: ['12', null, null, '10'],
            px: '1.5',
            color: 'delta.700',
          },

          '.svgIcon, .icon': {
            color: 'delta.700',
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        h: 'unset',
        p: '0',
        color: 'epsilon.200',

        _hover: {
          '.dropDownInner': {
            bg: 'delta.700',
            color: 'epsilon.200',
          },
        },
        '&&': {
          '.dropDownInner': {
            bg: 'delta.700',
            color: 'epsilon.200',
          },

          '> a, .dropDownIcon, a': {
            color: 'epsilon.200',
          },

          '.icon, .svgIcon': {
            color: 'epsilon.200',
            fill: 'epsilon.200',
          },
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
