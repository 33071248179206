import { PromosStylesSchema } from './Promos.styles';
import background from '@/assets/titanbet/images/race-bg.png';

export const promosStyles: PromosStylesSchema = {
  container: {
    m: '0',
    mb: '4',
    p: '0.5',
    bg: 'alpha.500',
    borderRadius: 'lg',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
    bgImage: `url(${background})`,
  },
  body: {
    color: 'white',
    px: '3',
    pt: '3',
    pb: '0',
  },
};
