import { Box, ButtonProps, chakra, Text } from '@chakra-ui/react';
import { styleImports } from './TakeAction.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const buttonActionProps: ButtonProps = {
  'data-testid': 'buttonActionProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonActionProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const TakeActionWrapper = chakra(Box, {
  label: 'TakeActionWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TakeActionWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxTakeActionTable = chakra(Box, {
  label: 'BoxTakeActionTable',
  baseStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    border: '1px',
    borderColor: 'blackAlpha.300',
    borderRadius: '4',
    p: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxTakeActionTable;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextTakeAction = chakra(Text, {
  label: 'TextTakeAction',
  baseStyle: () => ({
    mb: '2',
    fontSize: 'xs',
    ...(() => {
      try {
        return styleImports[themeName]?.TextTakeAction;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TakeActionHeading = chakra(Text, {
  label: 'TakeActionHeading',
  baseStyle: () => ({
    mb: '1',
    fontSize: 'sm',
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.TakeActionHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
