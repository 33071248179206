import { Global } from '@emotion/react';
import React from 'react';

export default function Index() {
  return (
    <Global
      styles={{
        body: {
          backgroundColor: 'var(--bc-colors-gray-300)',
        },
      }}
    />
  );
}
