import React, { forwardRef, FunctionComponent } from 'react';
import { InputProps, Input as InputChakra } from '@chakra-ui/react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import InputHOC from './InputHOC';

export type TInput = {
  id: string;
  name: string;
  InputLeftAddon?: string;
  InputRightAddon?: string;
  InputRightElement?: FunctionComponent;
  InputLeftElement?: FunctionComponent;
  allowInvalidKeys?: boolean;
  field?: [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];
} & InputProps;

/**
 * The actual Input component from Chakra.
 * This is wrapped with the HOC like component which adds
 * Icons/Elements to the Input.
 *
 * The field prop comes from the FormikHOC component which is dynamically
 * added.
 */
const Input = forwardRef(
  (
    {
      InputLeftElement,
      InputRightElement,
      InputLeftAddon,
      InputRightAddon,
      field,
      allowInvalidKeys,
      ...rest
    }: TInput,
    ref?: any
  ) => {
    const [fieldData] = field ?? [];
    const invalidKeys = ['e', 'E', '+', '-'];

    return (
      <InputHOC
        InputLeftElement={InputLeftElement}
        InputRightElement={InputRightElement}
        InputLeftAddon={InputLeftAddon}
        InputRightAddon={InputRightAddon}
        size={rest.size}
      >
        <InputChakra
          type="text"
          ref={ref}
          onWheel={(e) => e.currentTarget.blur()}
          onKeyDown={(e) =>
            !allowInvalidKeys &&
            invalidKeys.includes(e.key) &&
            e.preventDefault()
          }
          data-cy={`field-${rest.name}`}
          {...fieldData}
          {...rest}
        />
      </InputHOC>
    );
  }
);

export default Input;
