import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/oldgill/images/homeFeaturedSport/featSport.png';
import featuredMb from '@/assets/oldgill/images/homeFeaturedSport/featSportMb.png';

export const FlexTextWrapper: CSSObject = {
  pb: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['400px', '270px'],
  border: 'none',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'center',
  bgRepeat: 'no-repeat',
  bgSize: 'contain',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['0', '2'],
  pl: '0',
  pt: ['0', '10'],
  mt: ['4', '0'],
  flexDir: ['column', 'row'],
  '.fallback-wrapper': {
    mt: [20, 24],
    display: 'flex',
    w: 'full',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  maxW: 'unset',
  gap: '1',
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1.5',
};

export const TextVS: CSSObject = {
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  color: 'alpha.600',
  fontSize: '13px',
  fontWeight: 'black',
  borderRadius: 'base',
};

export const TextMisc: CSSObject = {
  bg: 'alpha.800',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.40), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'md',
  color: 'beta.400',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  fontWeight: 'extrabold',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'extrabold',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  display: 'none',
};

export const puntButtonProps: CSSObject = {
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  borderRadius: 'lg',
  color: 'alpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  h: '10',
};
