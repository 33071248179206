import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  accordionButton: {
    color: 'white',
    bg: 'alpha.500',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    sx: {
      display: 'flex',
      px: '2',
      py: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      fontSize: 'sm',

      _hover: {
        background: 'alpha.600',
      },
      _focus: {
        boxShadow:
          '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
      },
    },
  },
  topLeaguesText: { flex: '1', textAlign: 'left', fontWeight: 'semibold' },
  accordionIcon: { color: 'white' },
  accordionPanel: {
    bg: 'alpha.500',
    px: '2',
    py: '3',
    borderBottomRadius: 'md',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'gamma.500',
    px: '2',
  },
  accordionPanelBoxInner: {
    mb: '2',
    _last: {
      mb: '0',
    },
  },
  accordionPanelBoxInnerLink: {
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'sm',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      color: 'white',
      fontsize: 'md',
      my: '3',
      fontWeight: 'bold',
    },
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
    sx: {
      _last: {
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'white',
    bg: 'alpha.500',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopWidth: '1px',
      borderTopColor: '#353f4e',
      borderRadius: 'unset',

      _hover: {
        bg: 'alpha.600',
      },
      '&&&': {
        _expanded: {
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
          borderBottomLeftRadius: 'none',
          borderBottomRightRadius: 'none',
        },
        _focus: {
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
        },
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionIcon: {
    color: 'white',
  },
  marketGroupsByLetterAccordionPanel: {
    bg: 'alpha.500',
    pb: 4,
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'gamma.500',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'white',
    fontSize: 'sm',
    sx: { _hover: { textDecoration: 'none' } },
  },
};
