import { ButtonProps, theme } from '@chakra-ui/react';

const primary = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'blackAlpha.700',
  fontFamily: 'heading',
  lineHeight: 'normal',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  border: '1px solid',
  borderColor: 'whiteAlpha.500',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  boxShadow: '0px 0px 12px 0px rgba(136, 218, 19, 0.6)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300, alpha.300)',
    borderColor: 'transparent',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.400',
    borderColor: 'transparent',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
    color: 'alpha.400',
    textShadow: '0px 0px 12px rgba(136, 218, 19, 0.6)',
  },
  _disabled: {
    bg: 'gray.600',
    color: 'blackAlpha.700',
    borderColor: 'whiteAlpha.200',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&&:hover': {
      bg: 'gray.600',
      color: 'blackAlpha.700',
      borderColor: 'whiteAlpha.200',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  borderRadius: 'base',
  border: '1px solid',
  borderColor: 'whiteAlpha.400',
  color: 'blackAlpha.700',
  fontFamily: 'heading',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  boxShadow: '0px 0px 8px 0px rgba(255, 176, 40, 0.6)',
  _hover: {
    bgGradient: 'linear(to-b, beta.300, beta.300)',
    boxShadow: '0px 0px 8px 0px rgba(255, 176, 40, 0.6)',
    borderColor: 'transparent',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
    color: 'beta.400',
    border: 'none',
    textShadow: '0px 0px 8px rgba(255, 176, 40, 0.6)',
  },
  _disabled: {
    bg: 'gray.600',
    color: 'blackAlpha.700',
    borderColor: 'whiteAlpha.200',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&&:hover': {
      borderColor: 'whiteAlpha.200',
      bg: 'gray.600',
      color: 'blackAlpha.700',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'base',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.500',
  color: 'white',
  fontFamily: 'heading',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    borderColor: 'alpha.300',
    textShadow: '0px 0px 12px rgba(136, 218, 19, 0.6)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
    color: 'alpha.400',
    border: 'none',
    textShadow: '0px 0px 12px rgba(136, 218, 19, 0.6)',
  },
  _disabled: {
    borderColor: 'whiteAlpha.500',
    color: 'whiteAlpha.400',
    '&&:hover': {
      borderColor: 'whiteAlpha.500',
      color: 'whiteAlpha.400',
      textShadow: 'none',
    },
  },
};

const quickAccess = {
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  borderRadius: 'base',
  border: '1px solid',
  borderColor: 'whiteAlpha.400',
  color: 'blackAlpha.700',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'extrabold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  boxShadow: '0px 0px 8px 0px rgba(255, 176, 40, 0.6)',
  _hover: {
    bgGradient: 'linear(to-b, beta.300, beta.300)',
    boxShadow: '0px 0px 8px 0px rgba(255, 176, 40, 0.6)',
    borderColor: 'transparent',
  },
  ':active, &[data-active="true"], &[aria-expanded="true"]': {
    bgGradient: 'linear(to-b, white, white)',
    color: 'gray.700',
    borderColor: 'whiteAlpha.400',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.6)',
    borderBottomRadius: 'none',
  },
  _disabled: {
    bg: 'gray.600',
    color: 'blackAlpha.700',
    borderColor: 'whiteAlpha.200',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&&:hover': {
      borderColor: 'whiteAlpha.200',
      bg: 'gray.600',
      color: 'blackAlpha.700',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const bets = {
  ...primary,
  fontFamily: 'accent',
  fontWeight: 'bold',
};

const odds = {
  borderRadius: 'base',
  bg: 'transparent',
  border: '1px solid',
  borderColor: 'delta.700',
  color: 'alpha.500',
  fontFamily: 'heading',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 0px 12px rgba(136, 218, 19, 0.6)',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  _hover: {
    borderColor: 'alpha.400',
    color: 'alpha.400',
    textShadow: '0px 0px 12px rgba(136, 218, 19, 0.6)',
    boxShadow: '0px 0px 12px 0px rgba(136, 218, 19, 0.6)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
    color: 'beta.500',
    border: 'none',
    textShadow: '0px 0px 8px rgba(255, 176, 40, 0.6)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    border: 'none',
    color: 'gray.300',
    boxShadow: 'none',
    textShadow: 'none',
    '&&:hover': {
      bg: 'blackAlpha.400',
      border: 'none',
      color: 'gray.300',
      boxShadow: 'none',
      textShadow: 'none',
    },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    quickAccess: () => ({
      ...quickAccess,
    }),
    bets: () => ({
      ...bets,
    }),
    odds: () => ({
      ...odds,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
