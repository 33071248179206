import { CSSObject } from '@chakra-ui/react';

export const CarouselWrapper: CSSObject = {
  '& .control-dots ': {
    bottom: '0',
    marginY: 'px',
  },
  '& .carousel .slider-wrapper ': {
    w: ['99%', null, '810px'],
  },
};
