import React from 'react';
import Featured from './components/Featured/Featured';
import Offerings from './components/Offerings/Offerings';
import Upcoming from './components/Upcoming/Upcoming';
import { useCategory } from '../services/Home.hooks';
import { useQueryOfferings } from '../../../api/offerings/offerings.hooks';
import Loading from './components/Loading';
import { FEATURE_FLAGS } from '../../../constants/featureFlags';

export default function Sports() {
  const { showRacing } = useCategory();
  const { data, isInitialLoading } = useQueryOfferings();

  if (isInitialLoading) return <Loading />;
  if (
    !FEATURE_FLAGS.HAS_SPORTS_VIEW ||
    showRacing ||
    !data?.offered_sports?.length
  )
    return null;

  return (
    <>
      <Upcoming />
      <Featured />
      <Offerings />
    </>
  );
}
