import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mx: '0',
    rowGap: 'unset',

    sx: {
      '&&&': {
        gap: ['3', null, null, '4'],
      },
    },
  },

  FlexCard: {
    borderRadius: 'lg',
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  },

  LinkCardHeader: {
    bg: 'transparent',
    color: 'beta.400',
  },

  TextCardHeading: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },

  TextRaceNumber: {
    bg: 'gray.300',
    borderRadius: 'sm',
    color: 'gray.600',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },

  BoxGridHeaderItem: {
    bg: 'alpha.600',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: '#004FB9',
    lineHeight: 'normal',
  },

  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    px: '2.5',
  },

  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },

  TextRunnerNumber: {
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    sx: {
      _last: {
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },

  TextScratched: {
    color: 'white',
  },

  LinkRaceDetails: {
    color: 'white',
    bgGradient: 'linear(to-b, alpha.600, alpha.800)',
    border: '2px solid',
    borderColor: 'beta.400',
    boxShadow:
      '0px 0px 0px 1px #000 inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset',
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    lineHeight: 'taller',
    borderRadius: 'base',
    h: '42px',
    _hover: {
      bg: 'beta.500',
      border: '2px solid',
      borderColor: 'beta.500',
      boxShadow:
        '0px 0px 0px 1px #000 inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.50) inset',
      color: 'beta.900',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    },
    m: 2.5,
  },

  buttonRaceCardOddsProps: {
    w: '100%',
    fontSize: '15px',
    variant: 'odds',
    h: '8',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};

export const IconCardHeader: CSSObject = {
  color: 'alpha.100',
};
