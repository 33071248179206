import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'gamma.200',
  fontSize: 'md',
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'roboto',
  pl: '0.5',
  color: 'gamma.400',
  pos: 'relative',
  bottom: '4px',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'alpha.600',
  minW: 'unset',
  bgGradient: 'linear(to-b, gamma.300, gamma.400)',
  borderRadius: 'base',
  border: 'none',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient: 'linear(to-b, gamma.400, gamma.500)',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset',
  },
  _after: {
    opacity: '0',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
