import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  color: 'white',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xl',
  pl: '1',
  pt: '1',
  fontFamily: 'CarterOne',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  textTransform: 'uppercase',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  mb: ['4px', null, '0px'],
};
