import {
  Box,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Image,
  Text,
  TextProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './Empty.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg from '../../../../../common/IconSvg';

const themeName = getThemeName();

export const BetSlipEmptyWrapper = chakra(Flex, {
  label: 'BetSlipEmptyWrapper',
  baseStyle: () => ({
    flexDirection: 'column',
    paddingInlineStart: '0',
    paddingInlineEnd: '0',
    padding: '0',
    flex: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipEmptyWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipEmptyContainer = chakra(Flex, {
  label: 'BetSlipEmptyContainer',
  baseStyle: () => ({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipEmptyContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipEmptyImage = chakra(Image, {
  label: 'BetSlipEmptyImage',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipEmptyImage;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipEmptyHeading = chakra(Text, {
  label: 'BetSlipEmptyHeading',
  baseStyle: () => ({
    mt: '5',
    mb: '2',
    fontWeight: '700',
    fontSize: 'md',

    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipEmptyHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const betSlipEmptyHeadingSuffix: TextProps = {
  'data-testid': 'BetSlipEmptyHeadingSuffix',

  ...(() => {
    try {
      return styleImports[themeName]?.betSlipEmptyHeadingSuffix;
    } catch (err) {
      return {};
    }
  })(),
};

export const BetSlipEmptySubheading = chakra(Text, {
  label: 'BetSlipEmptySubheading',
  baseStyle: () => ({
    mb: '24',
    fontSize: 'xs',

    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipEmptySubheading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Icon = chakra(IconSvg, {
  label: 'EmptyIcon',
  baseStyle: ({ theme }) => ({
    boxSize: '24',
    mt: '24',

    ...(theme.themeName === EThemes.Betgalaxy && {
      name: 'sports/soccer',
    }),
  }),
});

export const BoxHeadingIconContainer = chakra(Box, {
  label: 'EmptyBoxHeadingIconContainer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeadingIconContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipEmptyMultiContainer = styled.div`
  ${({ theme }) =>
    theme.features.betslip.betSlipModal.betSlipEmptyMultiContainer};
`;

export const BetSlipEmptyMultiText = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipEmptyMultiText};
`;

export const BetSlipEmptyResponsibleGamblingWrapper = chakra(Flex, {
  label: 'BetSlipEmptyResponsibleGamblingWrapper',
  baseStyle: () => ({
    boxShadow: '0px -2px 3px rgba(0, 0, 0, 0.2)',
    mx: '-1.5',
    mb: '-1.5',
    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipEmptyResponsibleGamblingWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;
