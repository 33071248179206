/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Hide } from '@chakra-ui/react';
import { LinkLogo } from '../../Headers/Basic/styles/Basic.styles';
import Logo from '../../Headers/Basic/conditionals/Logo/template';
import { TTemplate } from '@/helpers/createTemplate';
import { FlexAsideInfo, GridAsideWrapper } from '../styles/index';
import { TPageSpecificContent } from '@/views/onboarding/services/onboarding.types';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import CTA from '../CTA';

const template = (pageSpecificContent: TPageSpecificContent): TTemplate => ({
  wrapper: <GridAsideWrapper />,
  asideInfo: {
    wrapper: <FlexAsideInfo />,
    logo: {
      wrapper: <LinkLogo to="/" />,
      logo: <Logo />,
    },
    title: <Title id={pageSpecificContent.titleId} />,
    subtitle: (
      <Hide below="md">
        <Subtitle id={pageSpecificContent.subtitleId} />
      </Hide>
    ),
    cta: (
      <CTA
        messageId={pageSpecificContent.footerInfoId}
        href={pageSpecificContent.ctaHref}
        dataCy={pageSpecificContent.dataCy}
      />
    ),
  },
});

export default template;
