import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'alpha.700',
  fontSize: 'sm',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'gray.300',
  borderStyle: 'dashed',
};

/**
 * Form Inputs
 */
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'gray.500',
  },
  '.chakra-form__helper-text': {
    color: 'gray.500',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
};

export const InputWithdrawal: CSSObject = {
  bg: 'gray.50',
  border: '1px solid',
  borderColor: 'gray.300',
  borderRadius: 'sm',
  fontSize: 'sm',
  height: '38px',
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
  '.chakra-form__label': {
    color: 'gray.500',
  },
  '.chakra-form__helper-text': {
    color: 'gray.500',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input__left-addon': {
    bg: 'gray.400',
    color: 'gray.800',
    fontSize: 'sm',
    height: '38px',
  },
};

/**
 * Buttons
 */
export const buttonAddAccountProps: CustomButtonProps = {
  bg: 'alpha.500',
  borderRadius: 'sm',
  height: '38px',
  _hover: {
    bg: 'alpha.500',
    color: 'white',
  },
  _active: {
    color: 'white',
    bg: 'alpha.100',
    boxShadow: 'none',
  },
};

export const ButtonWithdrawal: CSSObject = {
  bg: 'alpha.500',
  borderRadius: 'sm',
  height: '38px',
  ':hover': {
    bg: 'alpha.500',
    color: 'white',
  },
  ':active': {
    color: 'white',
    bg: 'alpha.100',
    boxShadow: 'none',
  },
};

export const ButtonIncrease: CSSObject = {
  border: '2px solid',
  borderColor: 'alpha.500',
  borderRadius: 'sm',
  bg: 'transparent',
  color: 'alpha.500',
  fontSize: 'sm',
  boxShadow: 'none',
  ':hover': {
    bg: 'alpha.500',
    color: 'white',
  },
  ':active': {
    color: 'white',
    bg: 'alpha.100',
    boxShadow: 'none',
  },
};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const BankRadio: CSSObject = {
  alignItems: 'start',
  border: 'none',
  bg: 'gray.400',
  '&[data-checked]:before': {
    display: 'flex',
    alignSelf: 'center',
    width: '40%',
    height: '40%',
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'gray.500',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'white',
  boxShadow: 'none',
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

/**
 * Withdrawal Request Card
 */
export const WithdrawalWrapper: CSSObject = {
  bg: 'gray.50',
  border: '1px solid',
  borderColor: 'alpha.200',
  fontSize: 'sm',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'alpha.200',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  bg: 'transparent',
  border: '2px solid',
  borderColor: 'alpha.500',
  borderRadius: 'sm',
  color: 'alpha.500',
  fontSize: 'sm',
  boxShadow: 'none',
  ':hover': {
    bg: 'alpha.500',
    color: 'white',
  },
  ':active': {
    color: 'white',
    bg: 'alpha.100',
    boxShadow: 'none',
  },
};

export const WithdrawalStatus = (): CSSObject => ({
  borderRadius: 'md',
  px: '2',
});
