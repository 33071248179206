/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { keys } from '../../api.keys';
import { queryHomePage } from './homepage';
import { THomePage } from './homepage.types';

type TQueryHook = {
  key?: any[];
  options?: UseQueryOptions<THomePage>;
};

export const useQueryHomePage = ({
  key = [],
  options = {},
}: TQueryHook = {}) => {
  const query = useQuery([keys.homepage, ...key], () => queryHomePage(), {
    ...(options as any),
  });

  return query;
};
