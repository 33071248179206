import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flextEventSubTitleProps: FlexProps = {
  alignItems: 'center',
};

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.400',
  p: '2',
  w: 'fill-available',
  borderRadius: 'none',
  color: 'white',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  pos: 'relative',
  bottom: 'px',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'beta.500' : 'red.400',
});

export const LinkMarket: CSSObject = {
  color: 'white',
};

export const CountdownTimerBox: CSSObject = {
  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'beta.800',
    borderRadius: 'base',
  },

  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
  },

  '.chakra-badge.state-ended': {
    bg: 'gamma.400',
    color: 'white',
    borderRadius: 'base',
  },
};
