import styled from 'styled-components';
import { chakra, Icon } from '@chakra-ui/react';
import { styleImports } from './DatePicker.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const StyledDatePicker = chakra('div', {
  baseStyle: ({ theme }) => ({
    label: 'datePicker-StyledDatePicker',
    width: '100%',
    mb: '4',

    '.react-date-picker': {
      display: 'block',
      cursor: 'pointer',
      color: 'black',
    },

    '.react-date-picker__wrapper': {
      border: '1px',
      borderColor: 'transparent',
      bg: 'gray.100',
      borderRadius: 'md',
      outline: 'none',
      height: '33px',
      color: 'black',
      fontSize: 'sm',
      px: '3',

      _hover: {
        bg: 'gray.200',
      },

      ...(theme.themeName === EThemes.Wellbet && {
        bg: 'gray.50',
        border: '1px',
        borderColor: 'gray.300',
        h: '9',
      }),
    },

    '.react-date-picker__inputGroup__input': {
      '&:invalid': {
        background: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },

    '.react-date-picker__inputGroup__divider': {
      cursor: 'auto',
    },

    '.react-date-picker__button': {
      paddingRight: 'var(--bc-sizes-1)',
      '&:focus': {
        outline: 'none',
      },
    },

    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'semibold',
      fontSize: 'sm',
      color: 'white',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.StyledDatePicker;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const DatePickerText = styled.p`
  ${({ theme }) => theme.components.datePicker.text};
`;

type TDatePickerLabel = {
  signup?: boolean;
} & any;

export const DatePickerLabel = chakra<TDatePickerLabel>('label', {
  shouldForwardProp: (prop) => !['signup'].includes(prop),
  baseStyle: ({ theme, signup }: TDatePickerLabel) => ({
    display: 'block',
    mb: '1',
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'gray.500',
      fontWeight: 'semibold',
      fontSize: 'xs',
      mb: '1.5',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: signup ? 'white' : 'gray.800',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.DatePickerLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DatePickerIcon = chakra(Icon, {
  baseStyle: ({ theme }) => ({
    boxSize: '6',
    ...(theme.themeName === EThemes.Wellbet && {
      boxSize: '4',
      color: 'gray.400',
    }),
  }),
});

const DatePickerErrorIcon = styled.div`
  display: flex;
  width: var(--bc-sizes-2);
  height: var(--bc-sizes-2);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: var(--bc-sizes-1);

  ${({ theme }) => theme.components.datePicker.error.icon};
`;

const DatePickerErrorText = styled.span`
  margin: var(--bc-sizes-1) 0;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.components.datePicker.error.text};
`;

export { DatePickerText, DatePickerErrorIcon, DatePickerErrorText };
