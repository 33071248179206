import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
};

export const ButtonSubmit: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  fontSize: 'sm',
  borderRadius: 'base',
  borderColor: 'beta.400',
  color: 'alpha.800',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  fontFamily: 'Fredoka',
  fontWeight: 'black',
  textTransform: 'uppercase',
  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    color: 'alpha.800',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  },
  _active: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    border: '2px solid',
    borderColor: '#CDFF98',
    boxShadow: 'none',
    color: 'alpha.800',
    textShadow: 'none',
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '2',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',
  pt: '2',

  '.chakra-form-control': {
    mt: '0',
  },

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    h: '9',
  },
  '.chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'xs',
  },
  '.chakra-input': {
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'gray.50',
    borderColor: 'gray.300',
    _hover: {
      bg: 'gray.100',
    },
  },
};
