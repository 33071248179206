import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  pb: '0',
  mx: 2,
  px: 0,
  w: 'calc(100% - 16px)',
};

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  borderBottomLeftRadius: 'none',
  borderBottomRightRadius: 'none',
  bg: 'alpha.900',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  textTransform: 'uppercase',
  mb: '1',
};

export const EachWayText: CSSObject = {
  color: 'black',
  fontWeight: 'medium',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'gamma.200',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'gamma.600',
  fontWeight: 'black',
};

export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'gamma.200',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'gamma.400',
  fontWeight: 'black',
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'bold',
  fontSize: 'sm',
  color: 'gamma.800',
  bgGradient: 'linear(to-b, gamma.300, gamma.400)',
  boxShadow: '0px 0px 0px 2.7px rgba(255, 255, 255, 0.35) inset',
  borderRight: isPrefix ? '2px solid' : 'none',
  borderLeft: isPrefix ? 'none' : '2px solid',
  borderRightColor: 'rgba(255, 255, 255, 0.35)',
  borderLeftRadius: isPrefix ? 'base' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'base',
  h: '38px',
  ml: isPrefix ? '2px' : '0',
  mr: isPrefix ? '0' : '2px',
});

export const InputBetSlip: CSSObject = {
  border: 'none',
  color: 'gamma.500',
  fontSize: 'sm',
  fontWeight: 'bold',
  h: '38px',
  pl: '2.8rem',
  ':first-child': {
    pl: '2',
  },
  boxShadow: '0px 2px 3px 1px rgba(0, 0, 0, 0.1) inset',
  '&&': {
    borderRightRadius: 'base',
    border: 'none',
    borderLeftRadius: 'md',
    bg: 'gamma.800',
    _hover: {
      '&&': {
        border: 'none',
      },
      borderRightRadius: 'base',
      borderLeftRadius: 'md',
      bg: 'gamma.900',
    },
    _focus: {
      '&&': {
        border: 'none',
      },
      borderRightRadius: 'base',
      borderLeftRadius: 'md',
      bg: 'alpha.700',
    },
  },
};

export const FlexiReview: CSSObject = {
  pl: '8',
  "[class$='ReviewStake']": {
    color: 'gamma.800',
  },
};

export const FlexReviewExotic: CSSObject = {
  textAlign: 'left',
  "[class$='ReviewStake']": {
    color: 'gamma.800',
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'gamma.400',
};
