import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { TBetCloudErrorResponse } from '../../api.types';
import { mutateSgmPricing } from './sgm';
import { TMutateSgmPricingBody, TMutateSgmPricingResponse } from './sgm.types';

export const useSgmPricingMutation = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TMutateSgmPricingResponse>,
      AxiosError<TBetCloudErrorResponse>,
      TMutateSgmPricingBody
    >,
    'mutationFn'
  >
) => useMutation(mutateSgmPricing, options);
