import { CustomButtonProps } from '@/components/Button/Button';

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  height: '38px',
  variant: 'secondary',
  lineHeight: 'normal',
};

export const buttonSubmitExclusionProps: CustomButtonProps = {
  h: '10',
  variant: 'secondary',
  w: '100%',
};
