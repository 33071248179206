import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, alpha.500, #FF7B03)',
    borderRadius: 'base',
    border: 'none',
    gap: '3',
  },
  depositIconProps: {
    color: 'gamma.800',
    boxSize: '9',
  },
};
