import { DeepPartial } from '@reduxjs/toolkit';
import { TPromoToken } from '@/components/Betslip/Services/Betslip.types';
import { EEventType, TPriceType, TProposition } from '@/lib/DBModels';
import { EPunterStatus } from '@/lib/punter/types';

export type TBasicPunter = DeepPartial<{
  punter_id: string;
  firebase_uid: string;
  is_onboarded: boolean;
  identity_verified: boolean;
  platform_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  title: string;
}>;

export type TPunterSignup = DeepPartial<{
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  password: string;
  mobile: string;
  street_number: string;
  street_name: string;
  suburb: string;
  state: string;
  post_code: string;
  date_of_birth: string;
  email_marketing_enabled: boolean;
  phone_marketing_enabled: boolean;
  post_marketing_enabled: boolean;
  sms_marketing_enabled: boolean;
  affiliate_code: string;
  appsflyer_id: string;
  myaffiliates_token: string;
  title: string;
  promo_code: string;
}>;

export type TPunterAccount = DeepPartial<
  Omit<TBasicPunter, 'firebase_uid' | 'platform_id'> & {
    email: string;
    mobile: string;
    street_number: string;
    street_name: string;
    suburb: string;
    state: string;
    post_code: string;
    date_of_birth: string;
    deposit_limit_current?: number | null;
    deposit_limit_waiting?: number | null;
    deposit_limit_active?: string | null;
    deposit_limit_frequency?: number | null;
    deposit_limit_cooldown_ends?: string | null;
    deposit_limit_last_updated?: string | null;
    deposit_limit_frequency_current?: string | null;
    email_marketing_enabled: boolean;
    sms_marketing_enabled: boolean;
    phone_marketing_enabled: boolean;
    post_marketing_enabled: boolean;
    pc: string;
  }
>;

// --

export type TPunterAccountOverview = DeepPartial<{
  punter_name: string;
  account_number: string;
  account_balance: number;
  available_balance: number;
  bonus: number;
  power_plays: number;
  spins: number;
  bets: number;
  is_onboarded: boolean;
  status: EPunterStatus;
  self_exclusion_expiry: string;
  kyc_document_submitted: boolean | null;
  deposit_limit_reprompt_at: string | null;
}>;

// --
export enum EBetState {
  Pending = 'pending',
  Settled = 'settled',
  Voided = 'voided',
  Cancelled = 'cancelled',
  CashedOut = 'cashed_out',
}

export type TPunterAccountMyBetsProps = {
  states: EBetState[];
  limit?: number;
  offset?: number;
  cashout_only?: boolean | null;
};

export type TPunterAccountMyBetsCountProps = {
  states: EBetState;
};

export type TPunterBetRequestProps = {
  limit?: number;
  states?: string;
  offset?: number;
  requests?: string;
};

export type TPunterAccountMyBets = DeepPartial<TMyBetsRecords>[];

export type TPunterAccountMyBetsCount = DeepPartial<{
  total_pages: number;
  total_records: number;
}>;

export type TPunterBetRequests = {
  total_pages: number;
  total_records: number;
  records: TPunterBetRequest[];
};

export type TMyBetsRecords = {
  bet_id: string;
  bet_legs: TMyBetsMultiBetLegs[];
  event_type: EEventType;
  event_id: string;
  event_title: string;
  event_subtitle: string;
  event_icon: string;
  event_start: string;
  stake: number;
  odds: number;
  is_won: boolean;
  is_settled: boolean;
  created_at: string;
  bet_description: string;
  status: string;
  payout: number;
  event_data: TMyBetsRecordsEventData;
  exotic_selections: {
    exotic_combos: {
      runner_number: number;
      runner_name: string;
    }[];
  }[];
  price_type?: TPriceType;
  cashout_value?: number;
};

export type TMyBetsMultiBetLegs = {
  bet_description: string;
  bet_leg_id: string;
  event_data: TMyBetsRecordsEventData;
  event_icon: string;
  event_id: string;
  event_type: string;
  event_start: string;
  is_won: boolean | null;
  odds: number;
  status: string;
  total_deductions?: number;
  legs: TMyBetsMultiBetLegs[];
};

export type TMyBetsRecordsEventData = {
  venue_name?: string;
  race_name?: string;
  race_number?: number;
  runner_name?: string;
  runner_number?: number;
  runner_barrier_number?: number;
  barrier_number?: number;
  match_name?: string;
  proposition_name?: string;
  proposition_type?: string;
  venue_id: string;
  race_meeting_date: string;
  competition_name?: string;
  competition_id?: string;
  sport_id?: string;
  tournament_name?: string;
  tournament_id?: string;
};

export type TPunterBetRequest = {
  request_id: string;
  created_at: string;
  requested_odds: number;
  requested_stake: number;
  status: EBetRequestStatus;
  actioned_at: string;
  actioned_by: string;
  event_id: string;
  event_start: string;
  proposition_odds: number;
  approved_odds: number;
  approved_stake: number;
  bet_status: EBetStatus;
  selection: unknown;
  bet_legs: TMyBetsMultiBetLegs[];
  price_type?: TPriceType;
  promo_tokens: TPromoToken[];
  proposition_info?: TProposition[];
  event_data: TMyBetsRecordsEventData;
};

export enum EBetStatus {
  Pending = 'pending',
  Settled = 'settled',
  Voided = 'voided',
  CashedOut = 'cashed_out',
}

export enum EBetRequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  ReducedStake = 'ReducedStake',
  Rejected = 'Rejected',
}

export enum EPunterTitles {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Ms = 'Ms',
  Dr = 'Dr',
  Other = 'Other',
}

export type TZohoToken = {
  token: string;
};

export type TPunterCashOutValueParams = {
  bet_id: string;
};

export type TPunterCashOutValue = {
  bet_id: string;
  cashout_value: number;
  status?: CashoutStatus;
};

export type TCashoutRequest = {
  bet_id: string;
  amount: number;
};

export enum CashoutStatus {
  Success = 'success',
  Error = 'error',
  OddsChangeAutoAccept = 'oddsChangeAutoAccept',
  OddsChangeRequireAccept = 'oddsChangeRequireAccept',
  CustomerNotEligible = 'CustomerNotEligible',
  BetNotEligible = 'BetNotEligible',
  BetDoesntExist = 'BetDoesntExist',
  BetAlreadySettled = 'BetAlreadySettled',
  CashoutDisabled = 'CashoutDisabled',
}

export type TCashoutResponse = {
  status?: CashoutStatus;
  /**
   * The status of the request
   * @example success
   * @enum [ success, error, oddsChangeAutoAccept, oddsChangeRequireAccept, CustomerNotEligible, BetNotEligible, BetDoesntExist, BetAlreadySettled, CashoutDisabled ]
   */
  title?: string;

  /**
   * How much the user requested to cash out at
   * @example 10000
   */
  requested_cashout_value?: number;

  /**
   * The value the bet would currently pay out at on a cash out
   * @example 9000
   */
  new_cashout_value?: number;
};
