import { CSSObject } from '@chakra-ui/react';
import astronaut from '../../../../../../assets/betgalaxy/images/header/astronaut.svg';
import universe from '../../../../../../assets/betgalaxy/images/header/quickAccessMenu/universe.png';
import moon from '../../../../../../assets/betgalaxy/images/header/quickAccessMenu/moon.png';

export const BoxBalance: CSSObject = {
  bg: 'gray.50',
  color: 'gray.800',
};

export const ButtonNavItem: CSSObject = {
  color: 'gray.800',

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.50',
  },
};

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${astronaut})`,
  bgSize: '100%',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  zIndex: '-1',

  display: ['none', null, 'block'],

  w: '63px',
  h: '63px',
};

export const PopoverContentQA: CSSObject = {
  bg: `#3A4376 url(${universe})`,
  bgSize: 'cover',
  bgPos: 'center bottom',
};

export const PopoverFooterQA: CSSObject = {
  bg: `url(${moon})`,
  bgPos: 'center bottom',
  bgRepeat: 'no-repeat',
};
