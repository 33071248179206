import { CSSObject } from '@chakra-ui/react';

export const GridItemBetslip: CSSObject = {
  position: [null, null, null, null, 'sticky'],
  top: [null, null, null, null, '115px'],
  height: [null, null, null, null, 'calc(100vh - 145px)'],
};

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
  maxWidth: ['inherit', '272px'],
};
