import { css } from 'styled-components';

export const contentWrapper = css`
  width: 100%;
  padding: var(--bc-sizes-12) var(--bc-sizes-5) var(--bc-sizes-20)
    var(--bc-sizes-5);

  ${({ theme }) => theme.device.tablet} {
    padding: var(--bc-sizes-12) var(--bc-sizes-10) var(--bc-sizes-20)
      var(--bc-sizes-10);
  }

  ${({ theme }) => theme.device.laptop} {
    padding: var(--bc-sizes-12) 0 var(--bc-sizes-20) 0;
  }
`;

export const contentContainer = css`
  justify-content: space-between;
  row-gap: var(--bc-sizes-5);

  ${({ theme }) => theme.device.tablet} {
    row-gap: 0;
    column-gap: var(--bc-sizes-5);
  }

  ${({ theme }) => theme.device.laptop} {
    column-gap: var(--bc-sizes-12);
  }
`;

// TODO: Remove
export const contentColumn = css`
  width: 100%;

  ${({ theme }) => theme.device.tablet} {
    width: 50%;
  }
`;

// TODO: Remove
export const contentHeading = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-style: italic;
  font-size: var(--bc-fontSizes-lg);
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

// TODO: Remove
export const contentHeadingWrapper = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--bc-sizes-3);
`;

// TODO: Remove
export const viewAllLink = css`
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.basePalette.brightBlue};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// TODO: Remove
export const nextToJumpContainer = css`
  align-items: center;
  display: flex;
  overflow-x: auto;
  column-gap: var(--bc-sizes-5);

  ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    overflow-x: visible;
  }
`;

// TODO: Remove
export const featuredEventsContainer = css``;

// TODO: Remove
export const buttonGroupContainer = css`
  margin-bottom: 24px;
  overflow-x: auto;
  width: 100%;
  padding-top: 4px;
  padding-left: 4px;
`;

export const buttonGroupWrapper = css`
  margin: 0 auto;
`;

// TODO: Remove
export const viewToggleWrapper = css`
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
`;
