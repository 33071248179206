import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useMutationSRMPricing } from '@/api/racing/pricing/srm.hooks';

export type TSelection = {
  runner_number: number;
  runner_name: string;
  barrier_number?: number;
};

export const useSelections = () => {
  const [searchParams] = useSearchParams();
  const raceId = searchParams.get('raceId');
  const { mutateAsync } = useMutationSRMPricing();
  const [price, setPrice] = useState<number | undefined>();
  const [displayErrorBanner, setDisplayErrorBanner] = useState(false);

  // When the user makes a new selection, we also store the previous state in case we need to reinstate due to invalid selection.
  const [selections, setSelections] = useState<{
    prevSelections: TSelection[][];
    currentSelections: TSelection[][];
  }>({
    prevSelections: [[], [], [], []],
    currentSelections: [[], [], [], []],
  });
  const [addButtonState, setAddButtonState] = useState<
    'loading' | 'enabled' | 'disabled'
  >('disabled');

  const clearSelections = () => {
    setSelections({
      prevSelections: [[], [], [], []],
      currentSelections: [[], [], [], []],
    });
    setAddButtonState('disabled');
    setPrice(undefined);
  };

  const mutateSelections = useCallback(
    async (updatedSelections: TSelection[][]) => {
      await mutateAsync({
        race_id: raceId ?? '',
        selection: updatedSelections.map((s) =>
          s.map((item) => item.runner_number)
        ),
      })
        .then((e) => {
          setPrice(e.data.price);
          setAddButtonState('enabled');
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            setSelections((sel) => ({
              prevSelections: [[], [], [], []],
              currentSelections: sel.prevSelections,
            }));
            setDisplayErrorBanner(true);

            setAddButtonState('enabled');
          }
        });
    },
    [
      mutateAsync,
      setPrice,
      setAddButtonState,
      setSelections,
      setDisplayErrorBanner,
      raceId,
    ]
  );

  useEffect(() => {
    if (displayErrorBanner) {
      const timeout = setTimeout(() => {
        setDisplayErrorBanner(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [displayErrorBanner]);

  /**
   *
   * @param idx column position of that array
   * @param number is the runner number
   * @returns
   */
  const handleAddSelection = useCallback(
    async (idx: number, newSelection: TSelection) => {
      if (!selections) return;
      // TODO: a regular spread ([...selections]) causes an error when adding a runner directly
      // Create a copy of the selections to avoid directly modifying the state
      // If new selection already exists at a different index, remove it during cloning
      // after adding a SRM to the betslip. Using lodash’s deep clone method for now, may need to switch out
      const updatedSelections = _.cloneDeep(selections.currentSelections).map(
        (sel) =>
          sel.find((s) => s.runner_number === newSelection.runner_number)
            ? sel.filter((s) => s.runner_number !== newSelection.runner_number)
            : sel
      );
      // Add the number to the corresponding selection array based on the index
      updatedSelections[idx]?.push(newSelection);
      const populatedPositions = updatedSelections.filter(
        (arr) => !!arr.length
      );
      if (
        populatedPositions.length === 1 &&
        populatedPositions[0].length === 1
      ) {
        const newSel: TSelection[][] = [[], [], [], []];
        newSel[idx]?.push(newSelection);
        setSelections((sel) => ({
          prevSelections: sel.currentSelections,
          currentSelections: newSel,
        }));
        return;
      }
      setAddButtonState('loading');
      setSelections((sel) => ({
        prevSelections: sel.currentSelections,
        currentSelections: updatedSelections,
      }));
      await mutateSelections(updatedSelections);
    },
    [selections, setSelections, setAddButtonState, mutateSelections]
  );

  const handleRemoveSelection = useCallback(
    async (idx: number, number: number) => {
      if (!selections || !selections.currentSelections[idx]) return;

      const updatedSelections = [...selections.currentSelections];
      updatedSelections[idx] = selections.currentSelections[idx]?.filter(
        (el) => el.runner_number !== number
      );

      const populatedPositions = updatedSelections.filter(
        (arr) => !!arr.length
      );

      // If the updated selections contain <=1 runners we avoid making the API call and disable the add button
      if (
        !populatedPositions.length ||
        (populatedPositions.length === 1 && populatedPositions[0].length === 1)
      ) {
        setSelections({
          prevSelections: [[], [], [], []],
          currentSelections: updatedSelections,
        });
        setAddButtonState('disabled');
        setPrice(undefined);
        return;
      }

      setAddButtonState('loading');
      if (updatedSelections) {
        setSelections((sel) => ({
          prevSelections: sel.currentSelections,
          currentSelections: updatedSelections,
        }));
        await mutateSelections(updatedSelections);
      }
    },
    [selections, setSelections, setAddButtonState, mutateSelections]
  );

  const handleToggleSelection = (idx: number, runner: TSelection) =>
    selections.currentSelections[idx].find(
      (sel) => sel.runner_number === runner.runner_number
    )
      ? handleRemoveSelection(idx, runner.runner_number)
      : handleAddSelection(idx, runner);

  return {
    handleToggleSelection,
    clearSelections,
    selections: selections.currentSelections,
    price,
    displaySelections: selections.currentSelections.some(
      (innerArray) => innerArray.length > 0
    ),
    addButtonState,
    displayErrorBanner,
    setDisplayErrorBanner,
  };
};
