import { CSSObject } from '@chakra-ui/react';

export const UnableToCreateMultiWrapper: CSSObject = {
  py: '3',
  color: 'white',
  background: 'var(--chakra-black-alpha-300, rgba(0, 0, 0, 0.16))',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 8px 0px rgba(0, 0, 0, 0.25) inset',
  '.chakra-icon': {
    color: 'white',
  },
  alignItems: 'start',
  borderRadius: 'lg',
  mb: '3',
};

export const MultiCardWrapper: CSSObject = {
  bgGradient: 'linear(to-b, epsilon.400, epsilon.500)',
  boxShadow:
    '0px 4px 0px 0px #B68452, 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 8px 0px rgba(255, 255, 255, 0.40) inset',
  borderRadius: 'base',
  px: '2',
  pt: '2',
  pb: '0',
  mt: '0',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
  py: '0',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'epsilon.200',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};

export const EventTitle: CSSObject = {
  color: 'epsilon.700',
};

export const InvalidityBox: CSSObject = {
  color: 'white',
  fontFamily: 'roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '110%',
};
