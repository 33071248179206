import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive
    ? 'gray.300'
    : 'linear-gradient(180deg, var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
  color: isActive ? 'gray.600' : 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'base',
  boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',

  '&:hover, &:focus': {
    color: isActive ? 'gray.700' : 'white',
    bg: isActive
      ? 'gray.400'
      : 'linear-gradient(180deg, var(--bc-colors-alpha-700) 0%, var(--bc-colors-alpha-800) 100%)',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'gray.300',
  color: 'gray.600',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'base',
  boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',

  '&:hover, &:focus': {
    color: 'gray.700',
    bg: 'gray.400',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
