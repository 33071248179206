import { CSSObject } from '@chakra-ui/react';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', null, '3'],
  mt: '0',
  borderRadius: 'lg',
  bg: `rgba(78, 178, 255, 0.14)`,
  boxShadow:
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  pos: 'relative',
  px: '3',
  py: '1',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const BoxWrapper: CSSObject = {
  pt: '2.5',
  px: '0.5',
};
export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
};
export const ButtonViewMore: CSSObject = {
  mb: '2.5',
  h: '42px',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
