import { ModalContentProps } from '@chakra-ui/react';
import { IframeHTMLAttributes } from 'react';

export type PromoModalSchema = Partial<{
  oddsBoostModalContent: ModalContentProps;
  oddsBoostIFrame: IframeHTMLAttributes<any>;
}>;

export const promoModalStyles: PromoModalSchema = {
  oddsBoostModalContent: {
    w: 'full',
    h: 'full',
    m: '0',
    borderRadius: '0',
    maxW: '100dvw',
    maxH: '100dvh',
    bg: 'none',
  },
  oddsBoostIFrame: {
    width: '100%',
    height: '100%',
  },
};
