/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Text,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  VStack,
  Flex,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Settings } from '@styled-icons/feather/Settings';
import { useDispatch } from 'react-redux';
import { IconTrigger } from './styles/ThemeToggle.styles';
import Select from '../FormElements/Select';
import { EThemesOG } from '@/constants/themeConfig';
import { useThemeToggle } from './services/ThemeToggle.hooks';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import { IS_MOBILE_APP } from '../../constants/isMobileApp';
import { Button } from '@/components/Button/Button';
import { getThemeName } from '@/helpers/getThemeConfig';
import { setBonusChanceModal } from '@/redux/Betslip.slices';

export default function ThemeToggle() {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const { handleChange } = useThemeToggle();

  const themeName = getThemeName();

  if (!FEATURE_FLAGS.NOT_IN_PROD || IS_MOBILE_APP) return null;

  /**
   * This forces the error boundary for QA purposes
   */
  if (hasError) throw new Error('💥 CABOOM 💥');

  return (
    <>
      <Popover placement="top-start">
        <PopoverTrigger>
          <IconTrigger as={Settings} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">Theme Toggler</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Select
              name="theme-toggle"
              isFormik={false}
              label={`Current Theme: ${themeName}`}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              value={themeName.toLowerCase()}
            >
              {Object.keys(EThemesOG)
                .filter(
                  (el) =>
                    el !== 'Base' && el !== 'Bonuskingdom' && el !== 'Payday'
                )
                .sort((e1, e2) => e1.localeCompare(e2))
                .map((el, i) => (
                  <option value={el.toLowerCase()} key={i}>
                    {el}
                  </option>
                ))}
            </Select>

            <Box mb="2">
              <Text>Please select the theme you want to switch over.</Text>

              <VStack>
                <Flex my="2" w="full" alignItems="center">
                  <Text fontSize="xs" flex="1">
                    Betslip v2 flag status:
                  </Text>

                  <Switch
                    isChecked={FEATURE_FLAGS.HAS_NEW_BS}
                    onChange={() => {
                      localStorage.setItem(
                        'hasNewBetslip',
                        String(!FEATURE_FLAGS.HAS_NEW_BS)
                      );
                      window.location.reload();
                    }}
                  />
                </Flex>
              </VStack>
            </Box>
            <VStack>
              <Button onClick={() => setHasError(true)} isFullWidth>
                Throw error
              </Button>

              <Button
                onClick={() =>
                  dispatch(
                    setBonusChanceModal({
                      game: 'deposit-match',
                      variables: {
                        max_amount: 5000,
                        percentage: 0.5,
                      },
                      isVisible: true,
                    })
                  )
                }
                isFullWidth
              >
                Show deposit match
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
