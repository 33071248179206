import { Box } from '@chakra-ui/react';
import React from 'react';
import { raceNumberStyles } from './styles/RaceNumber.styles';

type RaceNumberProps = {
  raceNumber: number;
};

export default function RaceNumber({ raceNumber }: RaceNumberProps) {
  return <Box {...raceNumberStyles.wrapper}>R{raceNumber}</Box>;
}
