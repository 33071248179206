import React from 'react';
import { CSSObject, Image } from '@chakra-ui/react';
import mascot from '../../../../../../assets/volcanobet/images/header/mascot.png';
import bodyQABg from '../../../../../../assets/volcanobet/images/nav/bodyQABg.png';
import manIcon from '../../../../../../assets/volcanobet/images/nav/man.svg';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonBalanceProps: CSSObject = {
  variant: 'unstyled',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'normal',
  fontSize: 'sm',
  display: 'flex',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'md',
  fontWeight: 'bold',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'epsilon.400',
  borderColor: 'epsilon.300',
  color: 'white',
};

export const BoxBallance: CSSObject = {
  bg: 'rgba(0, 0, 0, 0.16)',
  color: 'white',
  boxShadow:
    '0px 1px 0px rgb(157 53 53 / 70%), inset 0px 1px 5px rgb(0 0 0 / 40%)',
  borderRadius: 'base',
  padding: '2',
  margin: '2',
  bgImage: `url(${bodyQABg})`,
  backgroundSize: 'cover',
};

export const FlexBallanceItem: CSSObject = {
  p: {
    fontSize: 'sm',
    fontWeight: 'extrabold',
  },
};

export const PopoverBodyQA: CSSObject = {
  bg: 'epsilon.400',
};

export const buttonDepositProps: CustomButtonProps = {
  bg: 'alpha.700',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'sm',
  border: '2px solid',
  borderColor: 'alpha.400',
  w: 'full',

  sx: {
    '.chakra-button__icon': { mt: '-0.5' },

    _hover: { bg: 'alpha.800' },
    _active: { bg: 'alpha.500' },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  color: 'white',
  borderColor: 'epsilon.300',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderTop: '1.5px solid',

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'epsilon.300',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImg: `url(${mascot})`,
  bgRepeat: 'no-repeat',
  bgSize: '145%',
  bottom: '-2',
  display: ['none', null, null, null, 'block'],
  pos: 'absolute',
  h: '63px',
  w: '120px',
  left: '7px',

  'body[data-sensitive="true"] &': {
    display: 'none',
  },
};

export const UserIcon = () => (
  <Image src={manIcon} mr={1} width={4} height={4} color="white" />
);

export const PopoverContentQA: CSSObject = {
  border: '3px solid',
  h: ['335px', '328px'],
  borderRadius: 'xl',
  borderColor: 'epsilon.300',
  bg: 'epsilon.400',
};
