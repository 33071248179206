import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import { TTemplate } from '../../../helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logoTop from '../../../assets/junglebet/images/logos/logoTop.png';
import logoCenter from '../../../assets/junglebet/images/logos/logoCenter.png';
import Heading from '../components/Heading';
import Body from '../components/Body';

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: (
      <Flex flexDir="column" alignItems="center">
        <Img src={logoTop} w="160px" mb={['0', null, '3']} />
        <Img
          src={logoCenter}
          h="260px"
          w={['450px', null, null]}
          mt={['10', null, '0']}
        />
      </Flex>
    ),
    heading: <Heading />,
    desc: <Body />,
  },
};

export default template;
