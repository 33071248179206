import { CSSObject } from '@chakra-ui/react';
import bg from '../../../assets/puntcity/images/backgrounds/bg.jpg';

export const TextTitle: CSSObject = {
  fontSize: ['38px', null, '52px'],
  fontFamily: 'accent',
  display: 'flex',
  mt: ['6', null, '8'],
  mx: ['auto', null, 'unset'],
  flex: ['1', null, 'unset'],
  textTransform: 'uppercase',
};

export const TextSubtitle: CSSObject = {
  fontSize: 'xs',
  lineHeight: '4',
  flex: '1',
  mt: ['unset', null, '3'],
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  mb: ['6', null, '4'],
  color: 'whiteAlpha.800',
};

export const TextCTALink: CSSObject = {
  color: 'alpha.300',
  fontWeight: 'bold',
};

export const FlexAsideInfo: CSSObject = {
  gridArea: '1/1',
  flexDir: 'column',
  alignItems: ['start', null, 'unset'],
  zIndex: 'overlay',
  m: ['unset', null, '10'],
  mt: ['2', null, '10'],
  color: 'white',
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  minH: ['336px', '682px'],
};

export const FlexCTAContainer: CSSObject = {
  justifyContent: 'center',
  alignSelf: ['center', null, 'unset'],
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
  },
};
