import { Text } from '@chakra-ui/react';
import React from 'react';
import { getStrings } from '@/helpers/utils';
import { FlexPageWrapper } from '../../account/TermsConditions/styles/TermsConditions.styles';
import PrimaryWrapper from '../../../wrappers/PrimaryWrapper';
import SideLinks from '../components/SideLinks/SideLinks';

export default function Terms() {
  const [
    {
      Account: { Terms: Strings },
    },
  ] = getStrings();

  return (
    <PrimaryWrapper
      pageTitle={Strings.pageTitle}
      pageHeader={
        <>
          <Text sx={{ fontSize: '2xl', mb: '2' }}>{Strings.pageTitle}</Text>
          <Text sx={{ fontSize: 'xs', fontWeight: 'normal' }}>
            Published: 20/10/2022
          </Text>
        </>
      }
    >
      <FlexPageWrapper>
        <SideLinks />
      </FlexPageWrapper>
    </PrimaryWrapper>
  );
}
