import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    boxSize: '30px',
    minWidth: '8',
    minHeight: '7',
    fontSize: 'xs',
    borderRadius: 'md',
    sx: {
      '&&': {
        h: '10',
        minW: '10',
        w: 'auto',
      },
    },
  },
};

export const FlexOdds: CSSObject = {
  bg: 'gray.400',
  alignItems: 'center',
  justifyContent: 'center',
  px: '1',
  py: ['1', null, '2'],
  minW: '10',
  borderRadius: 'lg',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  mr: '0',
  h: '30px',
  w: '12',
  borderWidth: '2px',
  borderColor: 'gray.300',
  color: 'blackAlpha.500',
  fontWeight: 'black',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  boxShadow:
    '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  border: 'none',
  opacity: '1',

  _hover: { bg: 'gray.400' },
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
};

export const HStackActions: CSSObject = {
  gap: '1',
  alignItems: ['flex-end', 'center'],
};
