import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
    mx: '0.5',
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
      pb: '0',
    },
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: 'md',
    bg: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1))',
    boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 1px 0px rgba(255, 255, 255, 0.6) inset`,
    minW: '90px',
    px: '2',
  },
  btnGroupText: {
    fontSize: 'xs',
    color: 'beta.400',
    fontWeight: 'extrabold',
    fontFamily: 'accent',
    textShadow: '0px 1px 4px rgba(0, 0, 0, 0.5)',
    h: 'full',
    lineHeight: '3.2',
    bg: 'blackAlpha.500',
    px: '2',
  },
  btnGroupIcon: {
    color: 'white',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  name: {
    color: 'white',
  },
};
export default styles;
