import { CSSObject } from '@chakra-ui/react';

export const GridSubLayout: CSSObject = {
  gridTemplateColumns: ['1fr', null, null, '270px 1fr', '270px 1fr 290px'],
  gridTemplateRows: [
    'auto auto 1fr',
    null,
    null,
    'auto auto 1fr auto',
    'auto 1fr auto',
  ],
  columnGap: '2.5',
  gridTemplateAreas: [
    `
        "betslip"
        "sidenav"
        "content"
        "footer"
      `,
    null,
    null,
    `
        "sidenav betslip"
        "sidenav content"
        "sidenav content"
        "sidenav footer"
      `,

    `
        "sidenav content betslip"
        "sidenav content betslip"
        "sidenav footer betslip"
      `,
  ],

  'body[data-sensitive="true"] &': {
    gridTemplateRows: '',
    gridTemplateColumns: '',
    gridTemplateAreas: '',
  },
};

export const GridHeader: CSSObject = {
  bgGradient: 'linear(180deg, alpha.700, alpha.800)',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  borderBottom: '2px solid',
  borderColor: 'beta.500',
  pb: '2',
  position: 'sticky',
};

export const GridItemBetslip: CSSObject = {
  position: [null, null, null, null, 'sticky'],
  top: [null, null, null, null, '54px'],
  height: [null, null, null, null, 'calc(100vh - 120px)'],
};

export const GridItemToggle: CSSObject = {
  position: [null, null, 'sticky'],
  top: [null, null, null, null, '54px'],
  width: [null, null, '270px'],
};

export const GridItemSideNav: CSSObject = {
  position: [null, null, 'sticky'],
  top: [null, null, null, null, '45px'],
};
