import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, FormControl, Switch, Text } from '@chakra-ui/react';
import { useAppSelector } from '@/hooks/useRedux';
import { getStrings } from '@/helpers/utils';
import { useSupportedBets } from '@/components/Betslip/Services/Betslip.hooks';
import {
  RaceDetailsHeading,
  raceDetailsStyles,
  RaceRunnerHeadingContainer,
  RaceRunnerListHeadings,
} from '../../RaceDetails/styles/RaceDetails.styles';
import { useRaceMarkets } from '../../services/RaceDetails.hooks';
import Table from '../components/Table/Table';

import Action from './components/Action/Action';
import SelectionPopup from './components/SelectionPopup/SelectionPopup';
import {
  CheckboxBoxed,
  ExoticsContainer,
  ExoticsWrapper,
  SelectFieldContainer,
  SwitchBoxed,
  TextBoxed,
} from './styles/Exotics.styles';
import {
  useBoxed,
  useSelectField,
  useSelection,
} from './services/Exotics.hooks';
import { createTemplate } from '@/helpers/createTemplate';
import template from './components/BoxedButton/templates/template';
import { Button } from '@/components/Button/Button';
import { exoticsActionStyles } from './components/Action/styles/Action.styles';
import ToteMultiSelectionPopUp from './components/SelectionPopup/ToteMultiSelectionPopUp';
import { useIsToteMulti } from './services/Exotics.utils';

/**
 * using a memo component here as the parent polls and
 * rerenders the whole view.
 */
type TExoticsProps = {
  sortRunnerList?: (x: string) => void;
};

const Exotics: FC<TExoticsProps> = ({ sortRunnerList = () => {} }) => {
  const [
    {
      Racing: { RaceDetails, Exotics: ExoticStrings },
    },
  ] = getStrings();

  const isToteMulti = useIsToteMulti();

  const { toggleIsBoxed, isAvailable } = useBoxed();
  const { isBoxed } = useAppSelector((state) => state.exotics);
  const [showInsights, setShowInsights] = useState(true);
  const [showFlucs, setShowFlucs] = useState(true);

  const { positions, betType } = useSelection();
  const { supportedBets } = useSupportedBets();
  const { exoticMarket } = useRaceMarkets();
  const {
    fieldSelectedList,
    boxedFieldSelectedList,
    toggleFieldSelection,
    toggleBoxedFieldSelection,
    isDisabled,
  } = useSelectField();

  if (supportedBets && supportedBets.exotics === false) {
    // Restricted punters shouldn't see the table. ExoticsWrapper is here to display the page gracefully
    return <ExoticsWrapper />;
  }

  return (
    <>
      <ExoticsWrapper>
        <ExoticsContainer>
          <RaceRunnerListHeadings>
            <RaceRunnerHeadingContainer
              onClick={() => sortRunnerList('barrier_number')}
              mr="auto"
            >
              <RaceDetailsHeading>
                {RaceDetails.runnerHeading}
              </RaceDetailsHeading>
            </RaceRunnerHeadingContainer>
            <Flex {...raceDetailsStyles.flexExoticsHeadingRight}>
              <Flex mb={['2', '0']} justifyContent="end">
                <RaceRunnerHeadingContainer>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                  >
                    <Switch
                      id="insights"
                      size="sm"
                      onChange={() => setShowFlucs(!showFlucs)}
                      defaultChecked={showFlucs}
                    />
                    <TextBoxed
                      htmlFor="insights"
                      ml="1"
                      textTransform="capitalize"
                    >
                      <FormattedMessage id="racing.generic.flucs" />
                    </TextBoxed>
                  </FormControl>
                </RaceRunnerHeadingContainer>
                <RaceRunnerHeadingContainer ml="2">
                  <FormControl
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    ml="2"
                  >
                    <Switch
                      id="insights"
                      size="sm"
                      onChange={() => setShowInsights(!showInsights)}
                      defaultChecked={showInsights}
                    />
                    <TextBoxed
                      htmlFor="insights"
                      ml="1"
                      textTransform="capitalize"
                    >
                      <FormattedMessage id="racing.generic.insights" />
                    </TextBoxed>
                  </FormControl>
                </RaceRunnerHeadingContainer>
              </Flex>

              {!isToteMulti && (
                <Flex
                  justifyContent="end"
                  data-testid="exotics-FlexSelectField"
                >
                  {/* Quinellas are automatically boxed, so toggle shouldn't be displayed */}
                  {betType !== 'quinella' && (
                    <>
                      <SwitchBoxed
                        isChecked={isBoxed}
                        onChange={() => toggleIsBoxed(!isBoxed)}
                        isDisabled={isAvailable}
                      >
                        <TextBoxed>{ExoticStrings.boxed}</TextBoxed>
                      </SwitchBoxed>
                      <CheckboxBoxed
                        isChecked={isBoxed}
                        data-cy="boxedCheckbox"
                        isDisabled={isAvailable}
                        onChange={() => toggleIsBoxed(!isBoxed)}
                        data-testid="boxed"
                      >
                        <TextBoxed>{ExoticStrings.boxed}</TextBoxed>
                      </CheckboxBoxed>
                    </>
                  )}
                  <SelectFieldContainer>
                    {isBoxed ? (
                      <>
                        <TextBoxed>
                          <FormattedMessage id="racing.exotics.selectfield" />
                        </TextBoxed>
                        {createTemplate(
                          template(
                            boxedFieldSelectedList,
                            isDisabled,
                            toggleBoxedFieldSelection
                          )
                        )}
                      </>
                    ) : (
                      <>
                        {positions.map((v, i: number) => (
                          <Button
                            {...exoticsActionStyles.buttonExoticsActionProps}
                            key={i}
                            isSelected={fieldSelectedList[i]}
                            data-active={fieldSelectedList[i]}
                            isDisabled={isDisabled}
                            onClick={() => toggleFieldSelection(i)}
                          >
                            <Text
                              fontSize={exoticMarket.isSuspended ? 'xs' : 'sm'}
                            >
                              {(() => {
                                if (exoticMarket.isSuspended)
                                  return <FormattedMessage id="generic.sus" />;

                                return i + 1;
                              })()}
                            </Text>
                          </Button>
                        ))}
                      </>
                    )}
                  </SelectFieldContainer>
                </Flex>
              )}
            </Flex>
          </RaceRunnerListHeadings>

          <Table isInsightsActive={showInsights} isFlucsVisible={showFlucs}>
            <Action />
          </Table>
        </ExoticsContainer>
      </ExoticsWrapper>
      {!isToteMulti && <SelectionPopup />}
      {isToteMulti && <ToteMultiSelectionPopUp />}
    </>
  );
  /**
   * The add to betslip popup will need validation.
   * Another custom hook to check what betType they're on and if their
   * selection meets the rule.
   */
};

export default Exotics;
