import React from 'react';
import IconSvg from '../../../../common/IconSvg';
import { TSideIcon } from '../../../services/NavIcon.types';

export const HomeIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="home" className="svgIcon" sx={styles.icon} />
);

export const DepositIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="deposit" className="svgIcon" sx={styles.icon} />
);

export const PromoIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="promotions" className="svgIcon" sx={styles.icon} />
);

export const ContactIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="contactUs" className="svgIcon" sx={styles.icon} />
);

export const MyAccountIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="my_account" className="svgIcon" sx={styles.icon} />
);

export const ResponsibleGamblingIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="ResponsibleGambling" className="svgIcon" sx={styles.icon} />
);
