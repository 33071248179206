import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/bet777/images/racing/mystery-bet.png';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: {
    variant: 'odds',
    bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  },
  imageLogo: {
    w: '250px',
    src: mysteryLogo,
  },
  iconMystery: {
    mb: '1',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    fontWeight: 'bold',
  },
  flexWrapperMysteryLabelView: {
    color: 'white',
    fontFamily: 'heading',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'normal',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
    sx: {
      '&&': {
        svg: {
          mb: '0.5',
        },
      },
    },
  },
  buttonAddToBetslip: {
    variant: 'solid',
    sx: {
      '&&': {
        borderLeftRadius: 'none',
        ms: '0',
        fontSize: 'sm',
        fontWeight: 'normal',

        _disabled: {
          opacity: 1,
          bg: 'blackAlpha.500',
          boxShadow:
            '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
          color: 'alpha.200',
        },
      },
    },
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    borderRightRadius: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontFamily: 'heading',
    fontWeight: 'normal',
    sx: {
      '&&': {
        px: [2, 3],
        svg: {
          mb: '0.5',
        },
      },
    },
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    px: '3',
    color: 'gamma.200',
    overflow: 'hidden',
    mx: 'auto',
  },
  flexWrapperMysteryPlacedBetView: {
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
    gap: '1.5',
  },
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'alpha.100',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'base',
    boxSize: '10',
    p: '2',
    color: 'beta.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    cursor: 'pointer',
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    fontSize: 'xs',
    color: 'white',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  textPriceInfo: {
    fontSize: 'sm',
    color: 'gamma.400',
    fontFamily: 'heading',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textRollover: {
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    fontSize: 'xs',
    textColor: 'gamma.400',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  textError: {
    color: 'gamma.500',
    fontFamily: 'heading',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontStyle: 'normal',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '10',
    color: 'white',
  },
  iconRace: { color: 'beta.300', mb: '2' },
  textSpanWhite: {
    color: 'beta.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    _last: {
      color: 'gamma.200',
    },
  },
  buttonRollOver: {
    variant: 'secondary',
    mt: '0',
    h: '7',
    fontSize: 'sm',
    fontWeight: 'normal',

    sx: {
      _disabled: {
        pointerEvents: 'none',
        opacity: 1,
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'gamma.400',
        textShadow: 'none',
      },
    },
  },
  buttonMyBets: {
    fontSize: 'sm',
    fontWeight: 'normal',
  },
  spanSelectionText: {
    color: 'gamma.500',
  },
  spanPositionType: {
    color: 'beta.300',
  },
};
