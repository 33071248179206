import {
  getAuth,
  createUserWithEmailAndPassword,
  UserCredential,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  onAuthStateChanged,
  Unsubscribe,
  signInAnonymously,
  updateEmail,
  browserLocalPersistence,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import firebaseConfig from './config';

import { AuthUser } from './types';

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// Set persistence one time for the auth instance instead of on each sign-in/sign-up attempt
(async () => {
  await auth.setPersistence(
    window.Cypress ? { type: 'LOCAL' } : browserLocalPersistence
  );
})().catch((e) =>
  console.warn('Authentication Persistence could not be set correctly.', { e })
);

export const onAuthChangeListener = (
  callback: (user: AuthUser) => void
): Unsubscribe => onAuthStateChanged(auth, callback);

/** Gets the current authed user's profile */
export const getAuthUser = (): AuthUser => auth.currentUser;

export const signInAnon = async (): Promise<UserCredential> =>
  signInAnonymously(auth);

/** Sign up with Email and Password */
export const signUpWithEmail = async (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(auth, email, password);

/** Sign in with Email and Password */
export const signIn = async (
  email: string,
  password: string
): Promise<UserCredential> => signInWithEmailAndPassword(auth, email, password);

/** Signs a user out */
export const signUserOut = async (): Promise<void> => signOut(auth);

/** Resets user's Password */
export const resetPassword = async (email: string): Promise<void> =>
  sendPasswordResetEmail(auth, email);

/** Changes the signed in user's password */
export const changePassword = async (newPassword: string): Promise<void> => {
  if (!auth.currentUser) return;
  await updatePassword(auth.currentUser, newPassword);
};

/** Re-authenticates the current user. Used for after a user changes password for example. */
export const reAuthenticateUser = async (
  email: string
): Promise<UserCredential | undefined> => {
  if (!auth.currentUser) return;

  const credential = EmailAuthProvider.credentialWithLink(
    email,
    window.location.href
  );
  return reauthenticateWithCredential(auth.currentUser, credential);
};

export const updateUserEmail = async (newEmail: string): Promise<void> => {
  if (!auth.currentUser) return;
  await updateEmail(auth.currentUser, newEmail);
};

export const verifyUsersPassword = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    if (!auth.currentUser) return false;

    const credential = EmailAuthProvider.credential(email, password);
    const result = await reauthenticateWithCredential(
      auth.currentUser,
      credential
    );

    return result !== null;
  } catch (error) {
    return false;
  }
};
