export const en = {
  'account.signedout.title': 'Your account has been closed',
  'account.signedout.subtitle':
    'Sayonara samurai, may your journey be filled with honor and adventure.',
  'account.mybetscard.bonusapplied': 'Bonus Applied!',
  'account.settings.responsible.lastupdated': 'Published: 09/03/2023',
  'onboarding.locationnotallowed.header': 'Location not available',
  'betslip.betslipmodal.emptybetslipheading': 'Your quest is on hold',
  'onboarding.step1.pageTitle': 'Ready to embark on your journey?',
  'onboarding.login.title': 'Continue your journey',
  'onboarding.step1.pageSubtitle':
    'Enter the arena of QuestBet, where only the bravest of bettors emerge victorious. Create your account and take on the quest for riches and glory today.',
  'onboarding.login.subtitle':
    'Enter back into the arena of QuestBet, where only the bravest of bettors emerge victorious.',
  'onboarding.register.createAccount': 'Create an account',
  'maintenance.heading': 'YOU HAVE ENCOUNTERED A SIDE QUEST',
  'error.heading': 'YOU HAVE ENCOUNTERED A SIDE QUEST',
  'maintenance.error':
    "We've encountered an error {br} <span>Refresh the page, or try again later.</span>",
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'sports.allsports.azheading': 'A-Z Sports',
};
