import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'transparent',
  borderBottom: '1px solid',
  borderColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  _last: {
    borderBottom: 'none',
    boxShadow: 'unset',
  },
};

export const TextListName: CSSObject = {
  fontSize: ['xs', null, 'sm'],
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'white' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'epsilon.500',
  fontSize: ['2xs', null, 'xs'],
};

export const IconSport: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  color: 'beta.200',
};

export const IconChevron: CSSObject = {
  color: 'white',
};

export const FlexEndWrapper: CSSObject = {
  fontSize: '2xs',
  '.timestamp': {
    fontWeight: 'bold',
    fontSize: '2xs',
    px: '0',
    color: 'white',
  },
  '.state-inprogress': {
    bg: 'epsilon.400',
    color: 'gamma.700',
    borderRadius: 'base',
    lineHeight: 'normal',
  },

  '.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
    lineHeight: 'normal',
  },

  '.state-ended': {
    bg: 'delta.300',
    color: 'delta.800',
    borderRadius: 'base',
    lineHeight: 'normal',
  },
};

export const IconSGM: CSSObject = {
  h: 'auto',
  mr: '2',
  w: '8',
  color: 'beta.200',
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'white',
  },
};
