import { CSSObject, IconProps, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DepositContainer: CSSObject = {
  mb: 2,
};

export const buttonDepositProps: CustomButtonProps = {
  h: '10',
  variant: 'secondary',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: 1,
  w: 12,
  h: 7,
  bg: 'white',
};

export const RowDivider: CSSObject = {
  borderColor: 'blackAlpha.400',
  opacity: 1,
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const FlexWrapper: CSSObject = {
  color: 'gray.700',
  py: 3,

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  ml: 3,
  fontWeight: 'medium',
  color: 'white',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: 4,
  p: 1,
};

export const IconButton: CSSObject = {
  h: 8,
  ml: 4,
  minWidth: 8,
};

export const TrashIcon: IconProps = {
  bg: 'none',
  bgGradient: 'linear(to-b, #EFC80C, #EB9518)',
  boxShadow:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.31), 0px 1px 0px 0px rgba(0, 0, 0, 0.35) inset',
  color: 'blackAlpha.700',
  boxSize: '7',
  p: '1',
  borderRadius: 'base',
};

export const BoxInputContainer: CSSObject = {
  mb: 2,

  '.chakra-form-control': {
    mb: 0,
    label: {
      color: 'white',
    },
  },
  '&&': {
    '.chakra-input__left-addon': {
      h: '38px',
      borderLeftRadius: 'md',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'alpha.700',
      color: 'white',
    },
    '&&': {
      '.chakra-input': {
        h: '38px',
        border: '1px solid',
        borderLeftRadius: 'none',
        bg: 'white',
        color: 'gray.700',
        fontSize: 'xs',
        fontWeight: 'bold',
        borderRightRadius: 'md',
        borderColor: 'gray.300',
        borderLeft: 'none',
        boxShadow: 'none',
        '&&': {
          ':active, :hover': {
            bg: 'gray.100',
            color: 'blackAlpha.800',
            borderColor: 'gray.400',
            _placeholder: {
              color: 'gray.700',
            },
          },
          _focus: {
            color: 'blackAlpha.800',
            bg: 'gray.50',
            borderColor: 'blue.300',
          },
          '::placeholder': {
            color: 'gray.700',
            opacity: '1',
          },
        },
      },
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: 2,
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const IconSSL: CSSObject = {
  mx: 1,
  w: 4,
  h: 4,
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'beta.400',
};

export const bannerTextProps: TextProps = {
  fontSize: '11px',
  lineHeight: '13.2px',
  color: 'white',
  mt: '0.5',
};

export const FormBox: CSSObject = {
  maxW: '550px',
  'div.chakra-collapse div': {
    bgGradient: 'linear(to-b, gamma.500, gamma.700)',
    border: 'none',
    color: 'beta.400',
    fontWeight: '500',
  },
  'div.chakra-collapse div svg': {
    color: 'beta.400',
  },
};

export const RadioCard: CSSObject = {
  border: 'none',
  bg: 'gray.200',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'gray.200',
    color: 'alpha.500',
    _hover: {
      bg: 'gray.200',
    },
  },
};

export const buttonDepositsAvailableProps: CustomButtonProps = {
  fontSize: 'xs',
  w: 'fit-content',
};
