import { BoxProps, ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonOutsideArea = {
  display: 'none',
};
export const FlexContainer = (): CSSObject => ({
  px: ['2.5', null, null, '0'],
});
export const BoxWrapper: BoxProps = {
  pr: '0',
};
export const buttonLogInProps: ButtonProps = {
  variant: 'solid',
  height: '46px',
};
export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  height: '46px',
};
export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'quickAccess',
  height: '46px',
  p: '2.5',
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
  sx: {
    svg: {
      mr: '2px',
    },
    '.span-balance, .span-bonus': {
      fontFamily: 'Staatliches',
      color: 'alpha.400',
    },
    '&[aria-expanded="true"]': {
      '.span-balance, .span-bonus, .chakra-button__icon': {
        color: 'beta.800',
      },
    },
    _hover: {
      bgGradient: 'linear(to-b, alpha.400, alpha.500)',
      '.span-balance, .span-bonus, .chakra-button__icon': {
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        fontFamily: 'Staatliches',
        color: 'alpha.800',
      },
    },
  },
};
export const buttonBetsProps: ButtonProps = {
  variant: 'solid',
  height: '46px',
  padding: '0px 5px',
  pt: '12px',
  pb: '13px',
  fontWeight: 'normal',
  fontFamily: 'accent',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '1.5',
    },
    '.span-bet': {
      fontSize: '2xs',
      fontFamily: 'Staatliches',
      color: 'alpha.100',
    },
    '.span-bet-count': {
      fontSize: 'lg',
      fontFamily: 'Staatliches',
      color: 'alpha.400',
    },
    _hover: {
      bgGradient: 'linear(to-b, alpha.400, alpha.500)',
      '.span-bet, .span-bet-count': {
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        fontFamily: 'Staatliches',
        color: 'alpha.800',
      },
    },
  },
};
export const buttonBetSlipProps: ButtonProps = {
  variant: 'solid',
  height: '46px',
  padding: '0px 5px',
  pt: '12px',
  pb: '13px',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '1.5',
    },
  },
};
export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'beta.500',
  boxSize: '8',
  ml: '-1',
  '& svg': {
    boxSize: '8',
    color: 'alpha.400',
  },
});
export const FlexLeftGroup: CSSObject = {
  gap: '1.5',
  justifyContent: 'flex-start',
  alignItems: 'center',
};
export const buttonQuickAccess: ButtonProps = {
  variant: 'secondary',
};
export const ButtonGroupBets: CSSObject = {
  gap: '1',
  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },
  button: {
    fontFamily: 'Staatliches',
    color: 'alpha.400',
    textShadow: '0px 2px 3pxrgba(0, 0, 0, 0.3)',
    boxShadow:
      '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
    rounded: '6px !important',
    '.span-bet': {
      color: 'alpha.100',
    },
    p: {
      _first: {
        color: 'alpha.100',
      },
    },
  },
};
export const ButtonBetSlip: CSSObject = {
  ml: '0',
};
export const ButtonGroup: CSSObject = {
  gap: '1',
};
export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  ml: '-2',
  img: {
    pt: 1,
    pb: 1,
  },
};
export const TextButton: CSSObject = {
  fontWeight: 'normal',
  fontFamily: 'accent',
  fontSize: '2xs',
  color: 'alpha.400',
};
export const TextButtonNumber: CSSObject = {
  fontWeight: 'normal',
  fontFamily: 'accent',
  fontSize: 'lg',
  color: 'alpha.400',
};
