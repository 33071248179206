export const Container = {
  baseStyle: {
    '&': {
      px: ['2', null, null, '4'],
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {
    size: 'sm',
  },
};
