import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&': {
    bg: () => {
      if (state === 'prior') {
        return 'transparent';
      }
      if (state === 'inprogress') {
        return '#ECC94B ';
      }
      if (state === 'ended') {
        return '#F56565';
      }
    },
    color: () => {
      if (state === 'inprogress') {
        return '#5F370E';
      }
      if (state === 'ended') {
        return 'white ';
      }
      return 'accent.600';
    },
  },
});
