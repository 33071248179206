import styled, { css } from 'styled-components';

import { GridProps, GridSizes } from './types';

const getWidthStyling = (size: GridSizes) => css`
  flex: 0 0 ${(100 / 12) * size}%; // Formula for col width is 100% width / num cols (in this case 12) * the size you want (the number prop passed through)
  max-width: ${(100 / 12) * size}%;
`;

export const Row = styled.div<GridProps>`
  display: flex;
  flex-flow: row wrap;
  max-width: ${({ maxWidth }) => `${maxWidth}` || 'var(--bc-sizes-3xl)'};
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  ${({ fitContent = false }) => (fitContent ? 'height: fit-content' : '')};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : '')};
  ${({ expanded }) => (expanded ? 'max-width: none' : '')};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
  ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};
  ${({ flex }) => (flex ? `flex: ${flex}` : '')};
  ${({ padding }) => (padding ? `padding: ${padding}rem` : '')};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop}rem` : '')};

  ${({ theme }) => theme.ui.grid.gridRow};
`;

const rowMarginStyles = () => css`
  padding-left: var(--bc-sizes-2);
  padding-right: var(--bc-sizes-2);

  & > ${Row} {
    margin-left: -var(--bc-sizes-2);
    margin-right: -var(--bc-sizes-2);
  }
`;

export const Column = styled.div<GridProps>`
  flex-flow: column;
  flex: ${({ flex }) => (flex ? `${flex}` : '1 1 0px')};
  box-sizing: border-box;
  ${({ width }) => width && `width: ${width}`};

  ${({ theme }) => theme.ui.grid.gridColumn};

  ${({ rowMargin }) => rowMargin && rowMarginStyles()};

  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
  ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop}rem` : '')};

  ${({ theme }) => theme.device.minDevice} {
    ${({ sm }) => (sm ? getWidthStyling(sm) : '')};
    ${({ hiddenSM }) => (hiddenSM ? 'display: none' : 'display: flex')};
  }

  ${({ theme }) => theme.device.tablet} {
    ${({ md }) => (md ? getWidthStyling(md) : '')};
    ${({ hiddenMD }) => (hiddenMD ? 'display: none' : 'display: flex')};
  }

  ${({ theme }) => theme.device.laptop} {
    ${({ lg }) => (lg ? getWidthStyling(lg) : '')};
    ${({ hiddenLG }) => (hiddenLG ? 'display: none' : 'display: flex')};
  }
`;
