import Axios from 'axios';
import { configure } from 'axios-hooks';
import React, { ReactNode } from 'react';
import { BASE_API_URL, FIREBASE_PROJECTID } from '../../lib/Constants';
import { getRequestId } from '@/lib/requestId';

export const axios = Axios.create({ baseURL: BASE_API_URL });

if (globalThis.crypto) {
  axios.interceptors.request.use((config) => {
    /* eslint-disable no-param-reassign, @typescript-eslint/no-non-null-assertion */
    config.headers!['x-betcloud-request-id'] = getRequestId();
    return config;
  });
}

// Sets the default headers on all request types
axios.defaults.headers.common.FirebaseProject = FIREBASE_PROJECTID as string;

configure({ axios });

type TAxiosProvider = {
  children: ReactNode;
};

export default function AxiosProvider({ children }: TAxiosProvider) {
  return <>{children}</>;
}
