import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  fontWeight: 'semibold',
  fontFamily: 'Chewy',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'roboto',
  pl: '0.5',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'white',
  minW: 'unset',
  bg: 'beta.500',
  borderRadius: 'base',
  border: 'none',
  boxShadow:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.30), 0px 1px 0px 0px rgba(255, 255, 255, 0.35) inset',
  transition: 'all .3s ease-in-out',
  _hover: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    color: 'white',
    bg: 'beta.600',
  },
  _focus: {
    boxShadow:
      ' 0px 2px 3px rgba(0, 0, 0, 0.35), inset 0px 0px 3px rgba(255, 255, 255, 0.6), inset 0px 1px 0px rgba(255, 255, 255, 0.3);',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
