export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      bg: 'gray.50',
      color: 'black',
      caretColor: 'currentColor',
      border: '1px',
      borderColor: 'gray.300',
      borderRadius: 'lg',

      _placeholder: { color: 'gray.400' },

      _hover: { bg: 'gray.100', borderColor: 'gray.400' },

      _focus: {
        bg: 'gray.50',
        border: '2px',
        borderColor: 'beta.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },

      _active: {
        bg: 'gray.50',
        border: '2px',
        borderColor: 'beta.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      color: 'black',
      '&&': { borderRightColor: 'blackAlpha.200' },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
    md: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
  },
  variants: {},
  defaultProps: {},
};
