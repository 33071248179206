export const colors = {
  alpha: {
    100: '#ff5c54',
    200: '#ed342b',
    300: '#ba0700',
    400: '#ba1c16',
    500: '#b51a14',
    600: '#a70005',
    700: '#7a0000',
    800: '#730400',
    900: '#640502',
  },
  beta: {
    100: '#58ffeb',
    200: '#00d4bb',
    300: '#00ba74',
    400: '#018b57',
    500: '#017b4d',
    600: '#026b44',
    700: '#025636',
    800: '#02422a',
    900: '#023622',
  },
  gamma: {
    100: '#ffffe1',
    200: '#ffffbf',
    300: '#ffd675',
    400: '#f8ba46',
    500: '#ffc138',
    600: '#ffb000',
    700: '#9f730f',
    800: '#743f00',
    900: '#442601',
  },
};
