import { CSSObject } from '@chakra-ui/react';

export const BetApprovalContainer: CSSObject = {
  px: '0',
  pb: '0',
};

export const EstReturnsLabel: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  color: 'beta.900',
  fontWeight: 'black',
  fontSize: '2xs',
  height: '100%',
  alignItems: 'center',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'beta.900',
  justifyContent: 'center',
};

export const ApprovedBetContainer: CSSObject = {
  bg: 'alpha.100',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'alpha.600',
  justifyContent: 'center',
};

export const RejectedBetContainer: CSSObject = {
  bg: 'red.500',
  boxShadow:
    '0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4)',
  borderRadius: 'base',
  w: 'full',
  color: 'red.900',
  justifyContent: 'center',
};

export const FooterContainer: CSSObject = {
  bg: 'beta.900',
  color: 'white',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'base',
  borderColor: 'beta.500',
  borderWidth: '1px',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
};
