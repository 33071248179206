import React from 'react';
import { Text } from '@chakra-ui/react';
import { getIconBySportName } from '@/helpers/utils';
import Countdown from '../../../../../components/Countdown/Countdown';
import { useMatchDetails } from '../../services/MarketsByMatch.hooks';
import Menu from '../Menu/Menu';
import {
  BoxHeaderWrapper,
  BoxDot,
  FlexHeadingMiscWrapper,
  FlexMiscContentWrapper,
  IconClock,
  IconSport,
  TextSportName,
  TextVS,
  textTeamNameProps,
} from './styles/Header.styles';

export default function Header() {
  const { data: dataMatch } = useMatchDetails();
  const matchNameSplit = dataMatch?.match_name?.split(/( vs )/i);

  return (
    <BoxHeaderWrapper>
      <Menu>
        {matchNameSplit?.map((n, i) => {
          if (n.toLocaleLowerCase() === ' vs ') {
            return <TextVS as="span">vs</TextVS>;
          }

          return (
            <Text as="span" key={`match-${n}`} {...textTeamNameProps}>
              {i === 0 ? n : ` ${n}`}
            </Text>
          );
        })}
      </Menu>

      <FlexHeadingMiscWrapper>
        <FlexMiscContentWrapper>
          <IconSport name={getIconBySportName(dataMatch?.sport_name)} />
          <TextSportName>{dataMatch?.competition_name}</TextSportName>
        </FlexMiscContentWrapper>

        <BoxDot />

        <FlexMiscContentWrapper>
          <IconClock />
          <Countdown
            eventTime={dataMatch?.match_start_time ?? ''}
            dateFormat="HH:mm"
            timestampFormat="HH:mm, ddd D MMM"
            hasTimestamp
          />
        </FlexMiscContentWrapper>
      </FlexHeadingMiscWrapper>
    </BoxHeaderWrapper>
  );
}
