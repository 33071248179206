import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  iconRefresh: { pb: 0.5 },
  flexWrapper: {},
  buttonCashOutDesc: {
    borderRadius: 'lg',
    variant: 'tertiary',
    lineHeight: 'unset',
    fontFamily: 'roboto',
    minW: 'unset',
    px: 2,
    py: 0.5,
  },
  buttonCashOutAmount: { borderRadius: 'lg', py: 0.5 },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'alpha.500',
    border: '2px solid',
    borderColor: 'alpha.700',
  },
  iconOddsBoost: {
    fill: 'alpha.500',
  },
  textOddsBoost: {
    color: 'alpha.500',
  },
  iconCashOut: {
    fill: 'gamma.600',
    mb: '0.5',
  },
  cashoutText: {
    color: 'gamma.600',
  },
  cashoutValue: {
    color: 'gamma.600',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.500',
};

export const RaceTime: CSSObject = {
  color: 'gray.500',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'alpha.500',
};

export const BonusBetText: CSSObject = {
  color: 'alpha.500',
};

export const BetReturn: CSSObject = {
  bg: 'alpha.700',
  border: '1px',
  borderColor: 'alpha.700',
  borderRadius: 'base',
  color: 'white',
  fontSize: '2xs',
  overflow: 'hidden',
};

export const BoxBetReturnText: CSSObject = {
  bg: 'alpha.100',
  color: 'alpha.700',
  fontWeight: 'medium',
  p: '1',
};

export const BoxBetReturnValue: CSSObject = {
  ml: '-1',
  p: '1',
};
