import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import menuPattern from '@/assets/sugarcastle/images/backgrounds/menuPattern.png';

import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'white',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        bgBlendMode: 'overlay',
        bgGradient: ``,
        bgRepeat: 'repeat',
        bgSize: 'contain',
        bg: [
          `linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-500) 100%), url(${menuPattern})`,
          null,
          null,
          'unset',
          null,
        ],
        maxH: 'inherit',
        w: 'auto',
        m: [null, null, null, '5px 0px'],
        mb: [null, null, null, '9px'],
        pos: 'relative',
        display: 'block',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': { display: 'none' },
        _before: {
          content: '""',
          display: ['none', null, null, 'block'],
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
          top: '2',
          left: '2',
          zIndex: '3',
        },
        _after: {
          content: '""',
          display: ['none', null, null, 'block'],
          position: 'absolute',
          bottom: '16%',
          zIndex: '4',
          width: 'calc(100% - 20px)',
          height: 'calc(100% - 20px)',
          top: '2.5',
          left: '2.5',
          bgBlendMode: 'overlay',
          bgGradient: `linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-500) 100%), url(${menuPattern})`,
          bgRepeat: 'repeat',
          bgSize: 'contain',
          boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.25) inset',
        },
      },
      drawer: {
        ...base.drawer,
      },
      drawerBody: {
        '> div, a': {
          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '5',
        color: 'white',
        fill: 'white',
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.55))',
      },
      headerSX: {
        cursor: 'default',
        textTransform: 'capitalize',
        fontFamily: 'Imperator',
        color: 'beta.400',
        letterSpacing: 'wider',
        '&:hover': {
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,
        pos: 'relative',
        zIndex: '5',
        fontSize: 'sm',
        fontWeight: 'bold',
        height: ['55px', '45px'],
        color: 'beta.300',
        textShadow: ['unset', null, null, '0px 1px 2px rgba(0, 0, 0, 0.50)'],
        transition: 'all, 0.2s linear',
        borderLeftWidth: '3px',
        borderLeftColor: 'transparent',
        fontFamily: 'Fraunces',
        textTransform: 'uppercase',

        borderBottom: '1px solid',
        borderBottomColor: 'beta.400',

        p: ['1', null, null, '2'],
        ml: '0',
        '~ .nested': {
          pl: '10',
        },

        ':hover, :active': {
          bg: 'rgba(26, 55, 110, 0.50)',
        },

        '.icon, .svgIcon': {
          transition: 'all, 0.2s linear',
          color: 'beta.300',
          fill: 'beta.300',
          path: {
            transition: 'all, 0.2s linear',
          },
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: '2',
          borderBottom: '1px solid',
          borderBottomColor: 'beta.400',
        },

        '> a, .dropDownIcon, a': {
          color: 'beta.300',
        },

        '&:hover, &:active': {
          '&.chakra-icon': {
            color: 'delta.50',
          },

          // @NB - Delete styling in here to remove 'dancing' top events btn
          '.dropDownInner': {},

          '.svgIcon, .icon': {
            color: 'delta.50',
          },
        },
      },
      dropDownContainerOpen: {
        h: 'unset',
        p: '0',
        _hover: {
          '.dropDownInner': {
            bg: 'blackAlpha.300',
          },
        },

        '.dropDownInner': {
          color: 'beta.300',
          bgGradient: 'linear(to-b, gamma.500, gamma.700)',
          boxShadow: '0px 4px 4px 0px rgba(159, 156, 253, 0.36) inset',
          borderLeft: 'unset',
          '.chakra-icon, .icon': {
            color: 'beta.300',
            svg: {
              fill: 'beta.300',
            },
          },
        },

        '> a, .dropDownIcon, a': {
          color: 'beta.300',
          svg: {
            fill: 'beta.300',
          },
        },

        '.svgIcon, .icon': {
          color: 'beta.300',
          fill: 'beta.300',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
