import {
  chakra,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  ChakraComponent,
  Box,
  BoxProps,
  PopoverFooter,
  CSSObject,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg from '../../../../../common/IconSvg';
import { styleImports } from './QuickAccessMenu.styles.imports';

type TOverrideName =
  | 'PopoverContentQA'
  | 'PopoverHeaderQA'
  | 'PopoverBodyQA'
  | 'PopoverFooterQA'
  | 'BoxMascotImage'
  | 'BonusBalanceIcon';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};

// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const PopoverContentQA = chakra(PopoverContent, {
  baseStyle: () => ({
    w: ['100vw', 'md'],
    h: ['90vh', 'auto'],
    top: '-0.5',
    border: 'transparent',
    borderRadius: ['0', 'md'],

    ...styles?.PopoverContentQA,
  }),
});

export const PopoverHeaderQA = chakra(PopoverHeader, {
  baseStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '3',
    border: 'transparent',

    ...styles?.PopoverHeaderQA,
  }),
});

export const PopoverBodyQA = chakra(PopoverBody, {
  baseStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '3',

    ...styles?.PopoverBodyQA,
  }),
});

export const PopoverFooterQA = chakra(PopoverFooter, {
  baseStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '3',
    border: 'transparent',

    ...styles?.PopoverFooterQA,
  }),
});

export const BoxMascotImage = chakra(Box, {
  baseStyle: () => ({
    mr: '2',
    position: 'relative',

    ...styles?.BoxMascotImage,
  }),
}) as ChakraComponent<'div', BoxProps>;

export const BonusBalanceIcon = chakra(IconSvg, {
  baseStyle: () => ({
    ...styles?.BonusBalanceIcon,
  }),
});
