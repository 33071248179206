import { CSSObject } from '@chakra-ui/react';
import bg from '../../../../../../assets/questbet/images/homeFeaturedSport/bg.png';
import rhs from '../../../../../../assets/questbet/images/homeFeaturedSport/rhs.png';
import lhs from '../../../../../../assets/questbet/images/homeFeaturedSport/lhs.png';

export const FlexWrapper: CSSObject = {
  '&:after, &:before': {
    w: '75%',
    h: '75%',
    top: ['-25%', null, '-15%'],
    content: `''`,
    bgPos: 'center',
    bgSize: 'contain',
    pos: 'absolute',
    zIndex: '-1',
    bgRepeat: 'no-repeat',
  },
  _after: {
    bgImage: `url(${lhs})`,
    left: '-30%',
  },

  _before: {
    bgImage: `url(${rhs})`,
    right: '-30%',
  },

  p: '0',
  pl: '0',
  h: '340px',
  bgImage: `url(${bg})`,
  bgColor: 'alpha.400',
  bgPos: ['center', null, 'bottom'],
  bgRepeat: 'no-repeat',
  bgSize: ['cover', null, '100%'],
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  border: '4px solid',
  borderColor: 'white',
  mb: '2',
  mt: '4',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: 'column',
  gap: '1',
};

export const TextVS: CSSObject = {
  bg: 'beta.500',
  color: 'white',
  boxShadow: 'none',
};

export const TextMisc: CSSObject = {
  bg: 'alpha.700',
  color: 'white',
};

export const TextTeam: CSSObject = {
  color: 'alpha.800',
  fontSize: 'md',
  textShadow: 'none',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: ['200px', null, '300px'],
  m: 'auto',
};

export const HeadingSlogan: CSSObject = {
  fontFamily: 'strippy',
  color: 'white',
  textTransform: 'uppercase',
  fontSize: ['40px', null, '95px'],
  lineHeight: '1',
};

export const SpanSlogan: CSSObject = {
  fontFamily: 'strippy',
  color: 'beta.500',
  fontSize: ['20px', null, '35px'],
  lineHeight: '1',
  letterSpacing: ['5px', null, '15px'],
};
