import goldenrushBg from '@/assets/goldenrush/images/backgrounds/evenshot/goldenrushBg.png';
import evenShot from '@/assets/goldenrush/images/backgrounds/evenshot/evenshot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsText: {
    my: '4',
    fontWeight: 'bold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    mx: '4',
    mb: '4',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px',
    borderColor: 'beta.500',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
    color: 'white',
    flexDir: 'column',
    p: '4',
    bgImage: `url(${goldenrushBg}), linear-gradient(180deg, #A5540E 0%, #F28B33 100%)`,
    bgSize: 'cover',
    w: 'auto',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    color: 'beta.700',
    fontSize: '3xl',
    fontFamily: 'Angkor',
    w: '495px',
    textAlign: 'center',
    fontWeight: 'normal',
    lineHeight: '100%',
    bgGradient: 'linear(180deg, gamma.500 0%, gamma.600 100%)',
    backgroundClip: 'text',
    letterSpacing: 'wide',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    textTransform: 'uppercase',
  },
  addToBetslipButton: {
    variant: 'secondary',
    textTransform: 'uppercase',
    fontSize: 'sm',
    h: '44px',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
    sx: {
      '&[data-active="true"]': {
        bg: 'beta.700',
        boxShadow:
          '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
      },
    },
  },
  loginToView: {
    variant: 'secondary',
    textTransform: 'uppercase',
    fontSize: 'sm',
    h: '44px',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
    sx: {
      '&[data-active="true"]': {
        bg: 'beta.700',
        boxShadow:
          '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
      },
    },
  },
  evenShotImage: {
    h: '140px',
    w: '150px',
    mt: '6',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  oddsValueSpan: {
    color: 'gamma.300',
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'gamma.300',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  notYetAvailableText: {
    color: 'gamma.300',
    fontSize: 'sm',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};
