import React from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { Button } from '@/components/Button/Button';
import SwitchButtonAction from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';
import { buttonActionProps } from '../styles/TakeAction.styles';

export default (props: any) => {
  const combinedProps: ButtonProps = { ...buttonActionProps, ...props };
  return combineTemplate(SwitchButtonAction(props), [
    {
      name: 'comButtonAction',
      component: <Button {...combinedProps} />,
    },
  ]);
};
