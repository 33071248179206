import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import koi from '@/assets/goldenbet888/images/betslip/koi.png';

export const FlexBetSlipModalContainer: CSSObject = {
  backgroundImage: `url(${koi}), linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-500))`,
  backgroundPosition: '-370px 0px',
  bgSize: '364px',
  bgBlendMode: 'overlay',
  h: '100%',
  maxH: '100dvh',
  borderRadius: 'lg',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '8px',
  mx: '3px',
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipContainer: BoxProps = {
  borderRadius: 'base',
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: 'sm',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'gamma.500',
  fontFamily: 'Roboto',
  fontSize: 'sm',
});

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  height: '6',
  margin: 'auto',
  mt: '-5',
  w: 'auto',
  pl: 1,
  pr: 2,
  fontWeight: 'normal',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '1',
    },
    '.chakra-icon': {
      boxSize: '3',
    },

    '&&': {
      fontSize: 'xs',
      fontFamily: 'accentStaat',
      textTransform: 'uppercase',
      color: 'gamma.200',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',

      '&:hover, &:active': {
        bgGradient: 'linear(to-b, beta.500, beta.500)',
      },
    },
  },
};

export const BetslipVStack: CSSObject = {
  gap: '2.5',
};

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  height: '42px',
  sx: {
    '&&': {
      fontWeight: 'normal',
    },
  },
};
