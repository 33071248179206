import { useIntl } from 'react-intl';
import {
  EBetSlipViewMode,
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
} from '@/components/Betslip/Services/Betslip.types';
import {
  TBlendedMoMMultiBet,
  TSGMoMMultiBet,
  TSRMMoMMultiBet,
  TSingleRacingMoMMultiBet,
  TSingleSportMoMMultiBet,
  TSRMultiMoMBet,
  TBuiltBlendedBet,
  TBuiltSGMBet,
  TBuildSRMBet,
  TSingleSportMoMLeg,
  TSingleRacingMoMLeg,
  TBuildMultiBet,
  EBetBuilderError,
} from './types';
import { TMoMSchema } from '@/store/BetSlipStore';
import { centsToDollarsNumber, formatStake, getStrings } from '@/helpers/utils';

function getLegCountForMoMBet(
  legs: (
    | TBlendedMoMMultiBet
    | TSGMoMMultiBet
    | TSRMMoMMultiBet
    | TSingleRacingMoMMultiBet
    | TSingleSportMoMMultiBet
  )[]
) {
  return legs.reduce((acc, leg) => {
    let { type = 'Single' as string } = leg;
    if (type === 'Single' && 'prop_info' in leg) {
      if ('sport_name' in leg.prop_info && leg.prop_info.sport_name)
        type = 'SingleSport';
      if ('race_type' in leg.prop_info && leg.prop_info.race_type)
        type = 'SingleRacing';
    }
    switch (type) {
      case 'Blended':
      case 'SingleRacing':
      case 'SingleSport':
        return acc + 1;
      case 'SGMulti':
        return acc + (leg as TSGMoMMultiBet).legs.length;
      case 'SRMulti':
        return acc + (leg as TSRMMoMMultiBet).selection.flat().length;
      default:
        return acc;
    }
  }, 0);
}

function buildBlendedBet(bet: TBlendedMoMMultiBet): TBuiltBlendedBet {
  const { legs } = bet;
  return {
    type: 'Blended',
    title: 'Blended',
    description: '',
    iconType: legs[0].prop_info.race_type as string,
    subTitle: `${legs[0].prop_info.venue_name} R${legs[0].prop_info.race_number}`,
    odds: bet.odds,
    legs: legs.map(
      ({
        prop_info: { prop_name, runner_number, barrier_number },
        proposition_id,
      }) => ({
        propName: `${runner_number}. ${prop_name} ${
          barrier_number === 0 ? '' : `(${barrier_number})`
        }`,
        propositionId: proposition_id,
        propositionClosed: false,
      })
    ),
  };
}

function buildSGMBet(bet: TSGMoMMultiBet): TBuiltSGMBet {
  const { legs } = bet;
  return {
    type: 'SGMulti',
    title: `${legs.length} Leg`,
    description: `${legs[0].prop_info.sport_name}`,
    subTitle: `${legs[0].prop_info.event_name}`,
    iconType: '',
    oddsIncreased: false,
    odds: bet.odds,
    legs: legs.map((leg) => ({
      propName: leg.prop_info.prop_name,
      marketName: leg.prop_info.market_name,
      propositionId: leg.proposition_id,
      propositionClosed: false,
    })),
  };
}

function buildSRMLegsFromSelectionAndPropInfo(
  selection: TSRMMoMMultiBet['selection'],
  propInfo: TSRMMoMMultiBet['prop_info']
) {
  const legs: TSRMultiMoMBet['legs'] = [];
  propInfo.forEach((pi) => {
    selection.forEach((sel, i) => {
      if (sel.includes(pi.runner_number as number))
        legs.push({
          propName: `${pi.runner_number}. ${pi.prop_name} ${
            pi.barrier_number === 0 ? '' : `(${pi.barrier_number})`
          }`,
          winPosition: i + 1,
        });
    });
  });
  return legs;
}

function buildSRMBet(bet: TSRMMoMMultiBet): TBuildSRMBet {
  const { selection, prop_info: propInfo } = bet;

  return {
    type: 'SRMulti',
    title: `${propInfo.length} Leg`,
    description: '',
    subTitle: `${propInfo[0].event_name} R${propInfo[0].race_number}`,
    iconType: propInfo[0].race_type ?? '',
    raceId: bet.race_id,
    selection: bet.selection,
    odds: bet.odds,
    legs: buildSRMLegsFromSelectionAndPropInfo(selection, propInfo),
  };
}

const MAP_PROPOSITION_TYPE_TO_STRING = {
  Win: 'Fixed Win',
  Place: 'Fixed Place',
};

function buildSingleSportBet(leg: TSingleSportMoMMultiBet): TSingleSportMoMLeg {
  return {
    type: 'SingleSports',
    title: leg.prop_info.prop_name,
    description: leg.prop_info.event_name,
    subTitle: leg.prop_info.market_name,
    iconType: leg.prop_info.sport_name ?? '',
    odds: leg.odds,
    isClosed: false,
    legs: {
      propName: leg.prop_info.prop_name,
      propositionId: leg.proposition_id,
      priceType: leg.price_type,
    },
  };
}

function buildSingleRaceBet(
  leg: TSingleRacingMoMMultiBet
): TSingleRacingMoMLeg {
  const title = `${leg.prop_info.runner_number}. ${leg.prop_info.prop_name} ${
    leg.prop_info.barrier_number === 0
      ? ''
      : `(${leg.prop_info.barrier_number})`
  }`;

  const winPlaceText =
    MAP_PROPOSITION_TYPE_TO_STRING[
      leg?.prop_info
        ?.proposition_type as keyof typeof MAP_PROPOSITION_TYPE_TO_STRING
    ] ?? '';

  return {
    type: 'SingleRacing',
    title,
    subTitle: winPlaceText,
    description: `${leg.prop_info.venue_name} R${leg.prop_info.race_number}`,
    iconType: leg.prop_info.race_type ?? '',
    odds: leg.odds,
    isClosed: false,
    legs: {
      propName: title,
      marketName: leg.prop_info.market_name,
      eventName: `${leg.prop_info.venue_name} R${leg.prop_info.race_number}`,
      propositionId: leg.proposition_id,
      priceType: leg.price_type,
    },
  };
}

function buildMultiBet(
  legs: (
    | TBlendedMoMMultiBet
    | TSGMoMMultiBet
    | TSRMMoMMultiBet
    | TSingleRacingMoMMultiBet
    | TSingleSportMoMMultiBet
  )[]
): TBuildMultiBet {
  return {
    type: 'Multi',
    title: `${getLegCountForMoMBet(legs)} Leg Multi`,
    description: '',
    subTitle: '',
    iconType: 'multi_betslip',
    legs: legs
      .map((leg) => {
        let { type = 'Single' as string } = leg;
        if (type === 'Single' && 'prop_info' in leg) {
          if ('sport_name' in leg.prop_info && leg.prop_info.sport_name)
            type = 'SingleSport';
          if ('race_type' in leg.prop_info && leg.prop_info.race_type)
            type = 'SingleRace';
        }

        switch (type) {
          case 'SingleSport':
            return buildSingleSportBet(leg as TSingleSportMoMMultiBet);
          case 'SingleRace':
            return buildSingleRaceBet(leg as TSingleRacingMoMMultiBet);
          case 'Blended':
            return buildBlendedBet(leg as unknown as TBlendedMoMMultiBet);
          case 'SGMulti':
            return buildSGMBet(leg as unknown as TSGMoMMultiBet);
          case 'SRMulti':
            return buildSRMBet(leg as unknown as TSRMMoMMultiBet);
          default: {
            return null;
          }
        }
      })
      .filter(Boolean) as TBuildMultiBet['legs'],
  };
}

export function buildMOMBet(res: { bets: any[] }): TMoMSchema | null {
  const builderGeneratedBet = res.bets.filter(
    (bet: any) => bet.builder_generated
  )[0];

  if (!builderGeneratedBet) {
    return null;
  }

  // extract common data;
  const commonBetData = {
    betPlacedAt: new Date().toISOString(),
    isClosed: false,
    requestId: builderGeneratedBet.request_id as string,
    odds: builderGeneratedBet.bet_details.odds as number,
    betOddsChange: null,
    stake: '',
    isBonusBet: false,
    builderGeneratedBet: true,
    betSlipStatus: EBetSlipViewMode.EditingBets,
    betSubmissionStatus: null,
    betRequestStatus: null,
    betRejectionReason: null,
    maxStakeLimit: null,
    oddsIncreased: false,
  };

  switch (builderGeneratedBet.bet_details.type) {
    case 'Blended': {
      return {
        ...commonBetData,
        ...buildBlendedBet(
          builderGeneratedBet.bet_details as TBlendedMoMMultiBet
        ),
      };
    }
    case 'SGMulti': {
      return {
        ...commonBetData,
        ...buildSGMBet(builderGeneratedBet.bet_details as TSGMoMMultiBet),
      };
    }
    case 'Multi': {
      return {
        ...commonBetData,
        ...buildMultiBet(
          builderGeneratedBet.bet_details.legs as (
            | TBlendedMoMMultiBet
            | TSGMoMMultiBet
            | TSRMMoMMultiBet
            | TSingleRacingMoMMultiBet
            | TSingleSportMoMMultiBet
          )[]
        ),
      };
    }

    default: {
      return null;
    }
  }
}

export function useMapErrorToReason(error: EBetBuilderError | null): string {
  const intl = useIntl();

  switch (error) {
    case 'InsufficientLegsForMulti':
      return intl.formatMessage({
        id: 'betslip.betslipbetcard.errors.insufficientlegsformulti',
      });
    case 'ExceededMaximumLegsForMulti':
      return intl.formatMessage({
        id: 'betslip.betslipbetcard.errors.exceededmaximumlegsformulti',
      });
    default:
      return intl.formatMessage({
        id: 'betslip.betslipbetcard.errors.multiplelegsineventformulti',
      });
  }
}

export function shouldShowErrorBannerForBet(
  betStatus: EBetSubmissionConfirmationStatus | null,
  betRejectionReason: EBetSubmissionRejectionReason | null,
  maxStakeLimit: number | null
) {
  if (!betStatus || !betRejectionReason) return null;
  const shouldIgnoreError =
    betRejectionReason === EBetSubmissionRejectionReason.ManualRejection ||
    betRejectionReason === EBetSubmissionRejectionReason.OddsChange;

  if (shouldIgnoreError) return null;

  const { errors } = getStrings()[0].BetSlip.betSlipBetCard;

  if (betStatus === EBetSubmissionConfirmationStatus.Rejected) {
    return maxStakeLimit
      ? errors[betRejectionReason].replace(
          /{max_stake_limit}/g,
          formatStake(centsToDollarsNumber(maxStakeLimit))
        )
      : errors[betRejectionReason];
  }

  return null;
}
