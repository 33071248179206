import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Skeleton,
  Switch,
  SwitchProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { StitchWrapper } from '@/views/Home/components/Toggle/styles/Toggle.styles';
import toggle from '@/assets/junglebet/images/switch/switch3x.png';
import toggleBg from '@/assets/junglebet/images/switch/toggleBg.png';
import toggleInnerBg from '@/assets/junglebet/images/switch/toggleInnerBg.png';
import toggleLeaves from '@/assets/junglebet/images/switch/toggleLeaves.png';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'toggle-FlexWrapper',
  baseStyle: {
    pos: 'relative',
    border: '6px solid',
    borderColor: 'transparent',
    alignItems: 'center',
    height: '85px',
    justifyContent: 'center',
    mx: 'auto',
    borderRadius: '2xl',
    mb: '4',
    p: '1.5',
    boxShadow:
      '0px -1px 2px 0px rgba(186, 72, 9, 0.70) inset, 0px 0px 0px 3px #6D2B05 inset, 0px 5px 0px 0px #541E01, 0px 2px 2px 0px #D17917 inset',
    bgImage: `url(${toggleBg})`,
    bgSize: 'cover cover',
    backgroundBlendMode: 'overlay',
    zIndex: '1',
    _before: {
      bgImg: `url(${toggleInnerBg})`,
      h: '65%',
      w: 'calc(100% - 24px)',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'cover',
      content: '""',
      pos: 'absolute',
      left: '3',
      right: '0',
      top: '15%',
      zIndex: '2',
      borderRadius: 'md',
      boxShadow:
        '0px 5px 4px 0px rgba(0, 0, 0, 0.20), 0px 3px 0px 0px #A87A42, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
    },
    _after: {
      bgImg: `url(${toggleLeaves})`,
      h: '110%',
      w: '130%',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-39px',
      right: '0',
      top: '-23px',
      zIndex: '2',
    },
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'toggle-TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: ({ isActive }: any) => ({
      fontFamily: 'CarterOne',
      fontWeight: 'normal',
      fontSize: 'md',
      textTransform: 'uppercase',
      color: isActive ? 'gamma.400' : 'delta.500',
      textShadow: '0px 2px 0px rgba(255, 255, 255, 0.25)',
      letterSpacing: 'normal',
      px: '3',
      pt: '1',
      pos: 'relative',
      zIndex: '3',
    }),
  });

const Switcher: ChakraComponent<'label', SwitchProps> = chakra(Switch, {
  label: 'toggle-Switcher',
  baseStyle: {
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
    '--switch-track-width': 'calc(var(--bc-sizes-8))',

    '.chakra-switch__track': {
      bgGradient: 'linear(to-b, delta.300, delta.600)',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
      w: '46px',
      h: '29px',
      pos: 'relative',
      zIndex: '3',
      top: '-0.5',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: '3',

      _before: {
        bgImg: `url(${toggle})`,
        boxSize: '10',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-5px',
        right: '0',
        top: '-1',
      },
    },

    _active: {
      '.chakra-switch__track': {
        boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      },
    },

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
      },
      '.chakra-switch__track': {
        bgGradient: 'linear(to-b, delta.300, delta.600)',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <StitchWrapper>
        <TextToggle
          data-cy="racing-toggle-text"
          isActive={!category || category === 'racing'}
        >
          Racing
        </TextToggle>

        <Switcher
          onChange={() => {
            setSearchParams({
              category: !category || category === 'racing' ? 'sport' : 'racing',
            });
          }}
          isChecked={!!category && category !== 'racing'}
          colorScheme="blackAlpha"
          size="lg"
          data-cy="categorySwitcher"
        />
        <TextToggle
          data-cy="sports-toggle-text"
          isActive={category === 'sport'}
        >
          Sports
        </TextToggle>
      </StitchWrapper>
    </FlexWrapper>
  );
}
