import { CSSObject } from '@chakra-ui/react';

export const BoxInner: CSSObject = {
  border: '1px',
  borderColor: 'delta.200',
  borderRadius: 'base',
  overflow: 'hidden',
  mt: '4',
};

export const LinkWrapper: CSSObject = {
  bg: 'delta.400',
  borderColor: 'inherit',
  color: 'white',
  fontWeight: 'bold',
  px: '2',
  py: '4',
};
