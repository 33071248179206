import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  pt: '18',
  textAlign: 'center',
  mx: 'auto',
  w: 'full',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'xl',
  color: 'beta.500',
  fontFamily: 'CarterOne',
  textTransform: 'capitalize',
  textShadow: '0px 3px 0px rgba(0, 0, 0, 0.33)',
  w: '180px',
  mt: '0',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  mt: '0',
  mb: '5',
  px: '8',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
  justifyContent: 'center',
  alignItems: 'center',
};
