import { TPrimaryLayoutStyles } from './primary.styles';

/**
 * Interactive version of this grid can be found here
 * https://codepen.io/markBC/pen/GRboVmm
 */

const gutterMobile = '2';
const gutterWeb = '4';

const styles: Partial<TPrimaryLayoutStyles> = {
  grid: {
    display: 'grid',
    gridTemplateColumns: {
      base: 'repeat(3, 1fr)',
      lg: 'auto repeat(2, 1fr)',
      xl: '1fr minmax(auto, 824px) 1fr',
    },
    gridTemplateRows: {
      base: 'auto 1fr repeat(3, auto)',
      sm: 'repeat(2, auto) 1fr repeat(3, auto)',
      lg: 'repeat(2, auto) 1fr repeat(3, auto)',
      xl: 'repeat(2, auto) 1fr repeat(2, auto)',
    },
    gap: {
      base: gutterMobile,
      lg: gutterWeb,
    },
  },

  ntj: {
    gridArea: {
      base: '5 / 2 / 6 / 3',
      sm: '1 / 1 / 2 / 4',
      lg: '1 / 1 / 2 / 4',
      xl: '1 / 1 / 2 / 4',
    },
  },

  sidenav: {
    gridArea: {
      base: '5 / 1 / 6 / 2',
      sm: '6 / 1 / 7 / 2',
      lg: '2 / 1 / 5 / 2',
      xl: '2 / 1 / 5 / 2',
    },

    pl: {
      lg: gutterWeb,
    },

    justifySelf: {
      lg: 'end',
    },

    /**
     * TODO: TEMP
     * Move this into the sidenav styles
     * Logo done ✅
     */
    sx: {
      '& > div': {
        maxW: '272px',

        '.logo-toggle-wrap': {
          display: {
            base: 'none',
            lg: 'flex',
          },
          flexDir: 'column',
        },
      },
    },
  },

  main: {
    gridArea: {
      base: '2 / 1 / 3 / 4',
      sm: '3 / 1 / 4 / 4',
      lg: '3 / 2 / 4 / 4',
      xl: '2 / 2 / 4 / 3',
    },

    pr: {
      lg: gutterWeb,
      xl: 0,
    },
  },

  qam: {
    gridArea: {
      base: '1 / 1 / 2 / 4',
      sm: '2 / 1 / 3 / 4',
      lg: '2 / 3 / 3 / 4',
      xl: '2 / 3 / 3 / 4',
    },

    pr: {
      lg: gutterWeb,
    },

    maxW: {
      xl: '288px',
    },
  },

  aside: {
    gridArea: {
      base: '5 / 3 / 6 / 4',
      sm: '6 / 3 / 7 / 4',
      lg: '6 / 3 / 7 / 4',
      xl: '3 / 3 / 5 / 4',
    },

    pr: {
      xl: gutterWeb,
    },
  },

  footer: {
    gridArea: {
      base: '3 / 1 / 4 / 4',
      sm: '4 / 1 / 5 / 4',
      lg: '4 / 2 / 5 / 4',
      xl: '4 / 2 / 5 / 3',
    },

    pr: {
      lg: gutterWeb,
      xl: 'unset',
    },
  },

  message: {
    gridArea: {
      base: '4 / 1 / 5 / 4',
      sm: '5 / 1 / 6 / 4',
      lg: '5 / 1 / 6 / 4',
      xl: '5 / 1 / 6 / 4',
    },

    mb: {
      base: '-2',
      lg: '-4',
      xl: 'unset',
    },
  },
};
export default styles;
