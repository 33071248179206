import { Skeleton } from '@chakra-ui/react';
import React from 'react';

export default function Loading() {
  return (
    <>
      {[...new Array(5)].map((_, i) => (
        <Skeleton
          key={`sports-upcoming-skel-${i}`}
          sx={{
            h: '12',
            mt: '1px',
          }}
        />
      ))}
    </>
  );
}
