import { CSSObject } from '@chakra-ui/react';
import pinata from '@/assets/fiestabet/images/switch/toggleIcon.png';
import oldBoard from '@/assets/fiestabet/images/switch/toggleBg.png';

export const FlexWrapper = {
  borderRadius: 'lg',
  h: '16',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: '1.5',
  mb: '3',
  gap: '2',
  boxShadow:
    '0px 4px 0px 0px #5C0508, 0px 2px 1px 0px rgba(220, 67, 36, 0.47) inset',
  bgImage: `url(${oldBoard})`,
  bgSize: '100% 105%',
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'accentMexican',
  fontSize: 'md',
  p: '2',
  flex: 'unset',
  borderRadius: 'full',
  textTransform: 'lowercase',
  fontWeight: 'normal',
  letterSpacing: 'widest',
  px: '2',
  py: '1',
  textAlign: 'center',
  transition: 'all, 0.2s linear',
  color: isActive ? 'beta.300' : 'epsilon.500',
});

export const Switcher = (): CSSObject => ({
  bg: 'transparent',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${pinata})`,
      boxSize: '54px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-3',
      right: '0',
      top: '-4',
    },
  },
  '.chakra-switch__track': {
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.30) inset',
    bg: 'epsilon.500',
    w: '50px',
  },
});
