import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  bg: state === 'inprogress' ? 'alpha.500' : 'red.500',
  borderRadius: 'base',
  '&&': {
    minH: '4',
    height: '4',
    maxH: '4',
    boxShadow: 'none',
    lineHeight: '4',
    fontSize: '9px',
    color: state === 'inprogress' ? 'alpha.800' : 'white',
  },
});

export const CountdownFlex: CSSObject = {
  borderRadius: 'base',
};

export const TextTimestamp: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '9px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
};
