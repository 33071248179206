import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  chakra,
  Fade,
  Flex,
  HStack,
  Icon,
  IconButton,
  SkeletonText,
  SlideFade,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownCircle } from '@styled-icons/boxicons-regular/ChevronDownCircle';
import { CloseCircle } from '@styled-icons/remix-fill/CloseCircle';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@/components/Button/Button';
import { useRerender } from '@/hooks/useRerender';
import { useTimeout } from '@/hooks/useTimeout';
import { TMiniBetSlipProps } from '../services/MatchDetailPage.types';
import { miniBetSlipStyles } from '../../NewMatchDetailPage/styles/MiniBetSlip/MiniBetSlip.styles';

export default function MiniBetSlip({
  selections,
  odds,
  isLoading,
  error,
  onAddSelections,
  onClearSelections,
}: TMiniBetSlipProps) {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const toast = useDisclosure();
  const [toastKey, rerenderToast] = useRerender();
  const [toastTimeoutDelay, setToastTimeoutDelay] = useState<number | null>(
    null
  );
  const toastTimeout = useTimeout(toast.onClose, toastTimeoutDelay);

  useEffect(() => {
    if (!error) return;
    if (toast.isOpen) {
      rerenderToast();
    } else {
      toast.onOpen();
      setToastTimeoutDelay(3_000);
    }
    toastTimeout.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, rerenderToast, toast.onOpen, toastTimeout.reset]);

  const handleAddSelections = () => {
    onAddSelections?.();
  };

  const handleClearSelections = () => {
    setIsExpanded(false);
    onClearSelections?.();
  };

  const handleChange: AccordionProps['onChange'] = (expandedIndex) => {
    setIsExpanded(!expandedIndex);
  };

  if (!selections.length) {
    return null;
  }

  return (
    <Box {...miniBetSlipStyles.wrapper} as="section">
      <SlideFade in unmountOnExit>
        <Box {...miniBetSlipStyles.slideBox}>
          <Accordion allowToggle onChange={handleChange}>
            <AccordionItem {...miniBetSlipStyles.accordionItem}>
              <chakra.h2 {...miniBetSlipStyles.accordionHeaderText}>
                <AccordionButton {...miniBetSlipStyles.accordionButton}>
                  <Icon
                    {...miniBetSlipStyles.accordionButtonIcon}
                    as={ChevronDownCircle}
                    transform={isExpanded ? 'scaleY(-1)' : undefined}
                  />
                  {isExpanded ? (
                    <FormattedMessage id="sports.matchDetailPage.hideSelections" />
                  ) : (
                    <FormattedMessage id="sports.matchDetailPage.viewSelections" />
                  )}
                </AccordionButton>
                <Fade key={toastKey} in={toast.isOpen}>
                  <HStack
                    aria-atomic
                    role="status"
                    transform="translateX(-50%)"
                    {...miniBetSlipStyles.headerStack}
                  >
                    <Text {...miniBetSlipStyles.headerText}>
                      {error?.message}
                    </Text>
                    <IconButton
                      {...miniBetSlipStyles.headerButton}
                      aria-label={intl.formatMessage({
                        id: 'generic.close',
                      })}
                      icon={<Icon as={CloseCircle} />}
                      variant="unstyled"
                      onClick={toast.onClose}
                    />
                  </HStack>
                </Fade>
              </chakra.h2>
              <AccordionPanel as="dl" {...miniBetSlipStyles.accordionPanel}>
                {selections.map(
                  ({ proposition_id, proposition_name, market_name }) => (
                    <Flex
                      key={proposition_id}
                      {...miniBetSlipStyles.selectionFlex}
                    >
                      <chakra.dt {...miniBetSlipStyles.selectionMarketName}>
                        {market_name}
                      </chakra.dt>
                      <chakra.dd
                        {...miniBetSlipStyles.selectionPropositionName}
                      >
                        {proposition_name}
                      </chakra.dd>
                    </Flex>
                  )
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Stack
            as="footer"
            borderTopWidth={isExpanded ? '1px' : undefined}
            _before={{
              opacity: !isExpanded ? '0' : undefined,
            }}
            {...miniBetSlipStyles.footerStack}
          >
            <HStack {...miniBetSlipStyles.footerHStack}>
              <Text as="span" {...miniBetSlipStyles.legsText}>
                <FormattedMessage
                  id="sports.matchDetailPage.legs"
                  values={{
                    legs: selections.length,
                    strong: (chunks) => (
                      <chakra.strong {...miniBetSlipStyles.legsChunks}>
                        {chunks}
                      </chakra.strong>
                    ),
                  }}
                />
              </Text>
              <Text as="span" {...miniBetSlipStyles.oddsText}>
                <FormattedMessage
                  id="sports.matchDetailPage.odds"
                  values={{
                    odds: odds?.toFixed(2),
                    hasOdds: !!odds,
                    strong: (chunks) =>
                      isLoading ? (
                        <chakra.span fontSize="md">
                          <SkeletonText
                            noOfLines={1}
                            {...miniBetSlipStyles.oddsSkeletonLoader}
                          />
                        </chakra.span>
                      ) : (
                        <chakra.strong {...miniBetSlipStyles.oddsChunks}>
                          {chunks}
                        </chakra.strong>
                      ),
                  }}
                />
              </Text>
            </HStack>
            <HStack>
              <Button
                onClick={handleClearSelections}
                {...miniBetSlipStyles.clearButton}
              >
                <FormattedMessage id="sports.matchDetailPage.clearAll" />
              </Button>
              <Button
                disabled={selections.length < 2}
                onClick={handleAddSelections}
                {...miniBetSlipStyles.addToSlipButton}
              >
                <FormattedMessage id="sports.matchDetailPage.addToBetSlip" />
              </Button>
            </HStack>
          </Stack>
        </Box>
      </SlideFade>
    </Box>
  );
}
