import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { MatchesModalSchema } from '@/views/sports/components/MatchesModal/styles/MatchesModal.styles';

export const matchesModalStyles: MatchesModalSchema = {
  modalWrapper: { size: 'lg', isCentered: true, scrollBehavior: 'inside' },
  modalContent: { color: 'gray.600', overflow: 'hidden' },
  modalHeader: {
    alignItems: 'center',
    borderBottom: '1px',
    borderColor: 'gray.200',
    display: 'flex',
    gap: '4',
    justifyContent: 'space-between',
    p: '4',
    color: 'gray.700',
    fontSize: 'xl',
    fontWeight: 'semibold',
  },
  closeButton: {
    bg: 'gray.700',
    color: 'white',
    isRound: true,
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: { bg: 'gray.800' },
  },
  divider: { as: 'div', borderColor: 'gray.200' },
  modalBody: { p: '0', maxH: '400px' },
  stackWrapper: { spacing: '0' },
  linkWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '3',
    justifyContent: 'space-between',
    p: '3',
    _hover: { bg: 'gray.100', textDecoration: 'none' },
  },
  flexSportName: {
    align: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    gap: '1',
  },
  iconSportName: {
    fontSize: '2xl',
    color: '#D69E2E',
    sx: {
      p: '0.5',
      mr: '0.5',
    },
  },
  hStack: { spacing: '1' },
  countdown: {
    color: 'white',
    fontSize: '9px',
    h: '17px',
    pt: '2px',
    bg: 'blackAlpha.600',
    borderRadius: 'base',
    sx: {
      '&&': {}, // Reset
      ':not(.state-prior)': { color: 'white' },
    },
  },
  duotoneSgmLogoOverride: {
    h: 'auto',
    w: '9',
  },
  hStackIcon: {
    as: ChevronRight,
    color: 'gray.400',
    fontSize: '2xl',
  },
};
