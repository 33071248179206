import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  p: '2',
  bg: 'white',
  borderRadius: 'md',
  mx: '4',
  mb: '4',
  border: '2px',
  borderColor: 'beta.400',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
};

export const CheckboxBoxed: CSSObject = {
  justifyContent: 'flex-end',
  mt: '0',
  mr: '4',
  '.chakra-checkbox__control': {
    w: '18px',
    h: '18px',
    border: '1 px',
    borderColor: 'alpha.700',
    borderRadius: 'sm',
    bg: 'alpha.300',
    color: 'alpha.50',
    _hover: {
      bg: 'alpha.700',
      borderColor: '#213441',
      borderRadius: 'sm',
    },
  },
};

export const TextBoxed: CSSObject = {
  color: 'alpha.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const SelectFieldContainer: CSSObject = {
  gap: '1',
  w: 'unset',
};
