import { Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import logoDesktop from '@/assets/junglebet/images/logos/logoDesktop3x.png';
import logoMb from '@/assets/junglebet/images/logos/logo3x.png';

const LogoImage = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return <Image src={isMobile ? logoMb : logoDesktop} mt="3" mb="2" />;
};
export default function index() {
  return <LogoImage />;
}
