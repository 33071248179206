import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['2', null, 'unset'],
    mb: 4,
    bg: ['transparent', null, 'rgba(78, 178, 255, 0.14)'],
    boxShadow: [
      'unset',
      null,
      '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
    ],
    borderRadius: 'md',
    pos: 'relative',
    sx: {
      '&:before': {
        content: ['none', null, '""'],
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
        border: '0px solid transparent',
        borderImage: `url(${slamBorder}) 50`,
        pointerEvents: 'none',
        borderImageWidth: '50px',
      },
    },
  },
  boxHeading: {
    pt: '0',
    mb: 0,
    ...(!IS_MOBILE_APP && {
      pb: '9',
    }),
    color: 'white',
  },
  textTitle: {
    mt: '0',
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderBottomRadius: 'md',
        pb: '2.5',
        display: 'flex',
        gap: ['0', null, '4'],
        px: ['0', null, '3.5'],
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pb: '2',
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: 'md',
    px: '3',
    py: '3',
    bg: 'rgba(78, 178, 255, 0.14)',
    mt: '2',
  },
};
export const BoxHeading: CSSObject = {
  pt: '0',
  mb: 0,
  ...(!IS_MOBILE_APP && {
    pb: '9',
  }),
  color: 'white',
};
