import { CSSObject } from '@chakra-ui/react';

export const HeaderText: CSSObject = {
  color: 'white',
  fontSize: 'md',
  p: '0',
  textTransform: 'none',
};

export const HeaderContainer: CSSObject = {
  pb: '2',
  pt: '4',
  px: '4',
  position: 'relative',
  zIndex: '4433',
};

export const CloseButton: CSSObject = {
  display: 'none',
};

export const ContentContainer: CSSObject = {
  color: 'alpha.500',
  pb: '4',
  pt: '1',
  px: '4',
};

export const ModalBoxWrapper: CSSObject = {
  borderRadius: 'xl',
  width: '323px',
  height: 'auto',
  position: 'relative',
  zIndex: 'docked',
  mx: 'auto',
  px: '3',
  pt: '2.5',
  pb: '3',
  boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(180deg, beta.600, beta.400)',
  '&:before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    top: '1',
    left: '1',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    boxShadow: '0px 1px 0px 0px #ED8934',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    top: '2',
    left: '2',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
  },
};
