import React from 'react';
import { getStrings } from '@/helpers/utils';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import Form from './Form';

interface PromotionsNotificationsProps {}

const PromotionsNotifications: React.FC<PromotionsNotificationsProps> = () => {
  const [
    {
      Account: { PromotionsNotifications: strings },
    },
  ] = getStrings();

  return (
    <AccountWrapper
      pageTitle={strings.pageTitle}
      pageHeader={strings.pageTitle}
    >
      <Form />
    </AccountWrapper>
  );
};

export default PromotionsNotifications;
