import { CSSObject } from '@emotion/react';

export const TextTitle: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  mb: '2',
  alignItems: 'center',
  gap: '6px',
};

export const TextSpan: CSSObject = {
  svg: {
    boxSize: '16px',
    alignSelf: 'center',
    ml: 0,
  },
};
