import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TabContainer: CSSObject = {
  borderBottom: '0',
  px: '1.5',
  py: '2',
  bg: 'white',
  borderRadius: 'md',
  borderShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  sx: {
    '&&': {
      _active: {
        color: 'white',
        bg: 'gamma.700',
        boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.55)',

        _hover: {
          bg: 'gamma.800',
        },
      },

      bg: 'transparent',
    },
  },

  color: 'epsilon.500',
  textShadow: 'none',
  h: '5',
  fontWeight: 'bold',
  fontSize: '2xs',
  ml: '1',
  p: '2',
  borderRadius: 'sm',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  boxShadow: 'none',

  _hover: {
    bg: 'gamma.600',
    color: 'white',
  },

  _first: {
    ml: '0',
  },
};
