import _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
  TBetSlipBet,
  TBetSlipBetExotics,
} from '../../../../../components/Betslip/Services/Betslip.types';
import { EBetTypesDisplayNames } from '@/lib/DBModels';

export const isBoxed = (selections: number[][]) =>
  !!selections.length &&
  !selections
    .map((place, index) => {
      if (index === 0) {
        return;
      }
      return _.isEqual(place, selections[index - 1]);
    })
    .includes(false);

// Exotic typeguard - only exotic bets have validSelectionAmount
export const isExotic = (bet?: TBetSlipBet): bet is TBetSlipBetExotics =>
  !!(bet as TBetSlipBetExotics)?.validSelectionAmount;

export const roundDownStake = (
  stake: number | string,
  validSelectionAmount: number
) => {
  const modulo =
    (Math.round(Number(stake) + Number.EPSILON) * 100) % validSelectionAmount;
  return modulo ? Number(stake) - modulo / 100 : Number(stake);
};

export function useIsToteMulti() {
  const { betType } = useParams();
  return [
    EBetTypesDisplayNames.Quaddie,
    EBetTypesDisplayNames.EarlyQuaddie,
    EBetTypesDisplayNames.BigSix,
    EBetTypesDisplayNames.DailyDouble,
    EBetTypesDisplayNames.Treble,
    EBetTypesDisplayNames.RunningDouble,
  ].includes(betType as unknown as EBetTypesDisplayNames);
}

export function calculateCombosForToteMulti(selections: number[][]) {
  return selections.reduce(
    (acc, curr) => acc.flatMap((combo) => curr.map((item) => [...combo, item])),
    [[]] as number[][]
  ).length;
}

export function isToteMulti(betType: string) {
  return [
    EBetTypesDisplayNames.Quaddie,
    EBetTypesDisplayNames.EarlyQuaddie,
    EBetTypesDisplayNames.BigSix,
    EBetTypesDisplayNames.DailyDouble,
    EBetTypesDisplayNames.Treble,
    EBetTypesDisplayNames.RunningDouble,
  ].includes(betType as unknown as EBetTypesDisplayNames);
}
