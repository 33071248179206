/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { TTransactionsDTO } from '../../lib/DBModels';
import { keys } from '../api.keys';
import { queryTransactions } from './transactions';
import { TTransactionsRequestProps } from './transactions.types';

type TQueryTransactionsHook = {
  key?: any[];
  options?: UseInfiniteQueryOptions<TTransactionsDTO>;
  params: TTransactionsRequestProps;
};

export const useInfiniteQueryTransactions = ({
  key = [],
  options = {},
  params,
}: TQueryTransactionsHook) => {
  const paramValues = Object.values(params);
  const query = useInfiniteQuery(
    [keys.transactions, ...paramValues, ...key],
    (param) => {
      const offset = !param.pageParam
        ? 0
        : param.pageParam * (params.limit ?? 20);
      return queryTransactions({ ...params, offset });
    },
    {
      ...(options as any),
    }
  );
  return query;
};
