import { CSSObject } from '@chakra-ui/react';
import overviewBannerDesktop from '@/assets/sugarcastle/images/backgrounds/overviewBannerDesktop.png';
import overviewBannerMobile from '@/assets/sugarcastle/images/backgrounds/overviewBannerMobile.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'secondary',
    h: 8,
    fontSize: 'xs',
  },
};

export const FlexAccountDetails: CSSObject = {
  flexDir: ['column', null, 'row'],
  w: 'full',
  gap: ['1', null, '2'],
  alignItems: ['unset', null, 'center'],
};

export const FlexAccountName: CSSObject = {
  fontSize: 'xl',
  color: 'white',
  fontWeight: 'semibold',
  filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5))',
};

export const FlexBannerContainer: CSSObject = {
  mt: ['6', null, '0'],
  p: '3',
  bg: [`url(${overviewBannerMobile})`, null, `url(${overviewBannerDesktop})`],
  '&&': {
    backgroundSize: 'cover',
    backgroundPosition: ['center top', null, 'right'],
    backgroundRepeat: 'no-repeat',
  },
  borderRadius: 'md',
  gap: ['2', null, 'unset'],
  borderColor: '#FFE6B6',
  borderWidth: '2px',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px #000 inset',
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const FlexLabel: CSSObject = {
  w: ['full', null, 'unset'],
  flex: '1',
};

export const FlexBalanceBetInfo: CSSObject = {
  px: '3',
  py: '3',
  bg: 'transparent',
  color: 'white',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'md',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  fontFamily: 'Fraunces',
  fontSize: '2xs',
  h: '8',
  fontWeight: 'extrabold',
  textTransform: 'uppercase',
  alignItems: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  flex: ['1', null, 'unset'],
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.4)',
};

export const FlexAccountBalances: CSSObject = {
  flexDir: 'row',
  gap: ['8', null, '6'],
  justifyContent: ['space-between', null, 'unset'],
  filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5))',
};

export const TextButton: CSSObject = {
  alignSelf: 'center',
};

export const TextAccountBalanceLabel: CSSObject = {
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  letterSpacing: 'wider',
  color: 'white',
};

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: 'xl',
  fontWeight: 'extrabold',
  color: 'beta.300',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'extrabold',
  color: 'beta.300',
  opacity: '0.7',
});

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  mt: '0.5',
  mr: '0.5',
  alignSelf: 'flex-start',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'beta.300',
});

export const FlexButtonContainer: CSSObject = {
  h: ['unset', null, '6'],
  fontSize: '2xs',
  gap: '2',
  flexDir: ['column', null, 'row'],
  filter: 'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.3))',
};
