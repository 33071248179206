import React from 'react';
import { Flex, Img, Show } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logo from '@/assets/oldgill/images/logo.png';
import Title from '../components/Title';
import Description from '../components/Description';
import RefreshBtn from '../components/RefreshBtn';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (isMaintenance: boolean): TTemplate => ({
  wrapper: <FlexPageWrapper />,
  content: {
    wrapper: <MaintenanceContainer isMaintenance={isMaintenance} />,
    logo: (
      <Img
        src={logo}
        alt={REACT_APP_THEME_NAME}
        alignSelf="center"
        w="40"
        mt="10"
        mb={['44', '36']}
      />
    ),
    title: (
      <Flex direction="column" alignItems="center">
        <Title />
      </Flex>
    ),
    desc: <Description />,
    btn: <RefreshBtn />,
    body: (
      <Show above="sm">
        <Global
          styles={{
            body: {
              background: 'var(--bc-colors-beta-500)',
            },
          }}
        />
      </Show>
    ),
  },
});

export default template;
