import React from 'react';
import { Global } from '@emotion/core';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          background: 'linear-gradient(180deg, #1E2334 0%, #191D2D 100%)',
        },
      }}
    />
  );
}
