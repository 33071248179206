import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import {
  ContentContainer,
  MainContainer,
  WidthContainer,
} from '../../../../wrappers/PrimaryWrapper/styles/PrimaryWrapper.styles';
import {
  TextTitle,
  TextSub,
  BoxSignedOut,
  FlexSignedOutText,
} from './styles/SignedOut.styles';
import PageDocumentTitle from '../../../../components/PageDocumentTitle';
import Footer from '../../../../components/Footer/Footer';

const SignedOut: React.FC = () => {
  const [
    {
      Account: {
        SignedOut: { Title },
      },
    },
  ] = getStrings();

  return (
    <MainContainer shouldHideOverflow={false}>
      <ContentContainer fullWidthMobile fullWidth>
        <WidthContainer fullWidth>
          <PageDocumentTitle pageTitle={Title} />
          <BoxSignedOut>
            <FlexSignedOutText>
              <TextTitle>
                <FormattedMessage id="account.signedout.title" />
              </TextTitle>
              <TextSub>
                <FormattedMessage id="account.signedout.subtitle" />
              </TextSub>
            </FlexSignedOutText>
          </BoxSignedOut>
        </WidthContainer>
      </ContentContainer>

      <Footer />
    </MainContainer>
  );
};

export default SignedOut;
