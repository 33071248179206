import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  columnGap: 'unset',
  mx: 'unset',

  gridAutoFlow: {
    base: 'row',
    md: 'column',
  },

  gap: {
    base: '3',
    lg: '4',
  },
};

export const BoxColumn: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  gap: '2',
  mb: 0,
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'gray.300',
  borderRadius: 'sm',
  color: 'gray.600',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  mb: '0',
  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'alpha.400',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  fontFamily: 'Staatliches',
  color: 'white',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  fontSize: 'md',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  mb: 0,
};

export const TextRace: CSSObject = {
  fontFamily: 'Staatliches',
  color: 'white',
  fontWeight: 'normal',
  fontSize: 'sm',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.100',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
