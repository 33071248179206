import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  border: 'none',
  color: 'white',
  h: 'full',
  fontSize: '2xs',
  px: '1.5',
  borderRadius: 'md',
  w: '28',
  boxShadow:
    '0px 0px 0px 1px var(--bc-colors-beta-500) inset, 0px 0px 0px 2px var(--bc-colors-alpha-800) inset',

  _hover: {
    bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
    color: 'white',
  },
  _focus: {
    boxShadow:
      '0px 0px 0px 1px var(--bc-colors-beta-500) inset, 0px 0px 0px 2px var(--bc-colors-alpha-800) inset',
  },

  '*': {
    transition: 'all .2s ease-in-out',
  },

  svg: {
    pos: 'relative',
    top: '1px',
  },

  '&[aria-expanded="true"]': {
    svg: {
      transform: 'rotate(180deg)',
    },
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  color: 'beta.100',
  zIndex: 'docked',
  h: 'full',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset',
};
export const TextTitle: CSSObject = {
  color: 'beta.100',
};
export const CheckboxFilters: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'beta.100',
  },
};
