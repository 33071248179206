import { chakra, Flex, Text } from '@chakra-ui/react';
import { styleImports } from './ResultsTableHeader.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const FlexWrapper = chakra(Flex, {
  baseStyle: () => ({
    label: 'resultsTableHeader-FlexWrapper',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
});

export const TextItem = chakra(Text, {
  baseStyle: ({ theme }) => ({
    label: 'resultsTableHeader-TextItem',
    fontSize: 'xs',
    textTransform: 'uppercase',
    py: '2',
    ...(theme.themeName === EThemes.Wellbet && {
      textTransform: 'capitalize',
      color: 'gray.700',
      fontSize: 'xs',
      fontWeight: 'medium',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'black',
      fontWeight: 'medium',
      textTransform: 'capitalize',
      py: '0',
      pt: '2.5',
      pb: ['2.5', null, '0'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
