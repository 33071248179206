import React from 'react';
import { Icon, Spacer } from '@chakra-ui/react';
import { CloseCircle, InformationCircle } from '@styled-icons/ionicons-outline';
import { FormattedMessage } from 'react-intl';
import {
  BoxPaymentFailed,
  BoxPaymentProcessing,
  BoxPaymentSuccessful,
  FlexPaymentStateBody,
  FlexPaymentStateFooter,
  FlexPaymentStateHeader,
  FlexPaymentStateWrapper,
  IconButtonClose,
  TextPaymentFailed,
  TextPaymentFailedDesc,
  TextPaymentNotificationFooter,
  TextPaymentProcessing,
  TextPaymentSuccessful,
} from './Styles/PaymentStateNotification.styles';
import SecondsCountDown from '../SecondsCountDown/SecondsCountDown';

type TPaymentStateNotificationProps = {
  isProcessing: boolean;
  isSuccessful: boolean;
};

// This notification caters for processing, success and failure states of the payment
export function PaymentStateNotification({
  isProcessing,
  isSuccessful,
}: TPaymentStateNotificationProps) {
  return (
    <FlexPaymentStateWrapper>
      <FlexPaymentStateHeader>
        {!isProcessing && (
          <IconButtonClose
            icon={<Icon as={CloseCircle} boxSize="10" />}
            aria-label="Close"
            onClick={() => {
              window.location.reload();
            }}
          />
        )}
      </FlexPaymentStateHeader>
      <Spacer />
      <FlexPaymentStateBody>
        {isProcessing && (
          <>
            <BoxPaymentProcessing />
            <TextPaymentProcessing>
              <FormattedMessage id="qam.quickDeposit.processing" />
            </TextPaymentProcessing>
          </>
        )}
        {isSuccessful && (
          <>
            <BoxPaymentSuccessful />
            <TextPaymentSuccessful>
              <FormattedMessage id="qam.quickDeposit.successful" />
            </TextPaymentSuccessful>
          </>
        )}
        {!isProcessing && !isSuccessful && (
          <>
            <BoxPaymentFailed />
            <TextPaymentFailed>
              <FormattedMessage id="qam.quickDeposit.failed" />
            </TextPaymentFailed>
            <TextPaymentFailedDesc>
              <FormattedMessage id="qam.quickDeposit.failed.desc" />
            </TextPaymentFailedDesc>
          </>
        )}
      </FlexPaymentStateBody>
      <Spacer />
      <FlexPaymentStateFooter>
        <Icon as={InformationCircle} boxSize="10" />
        <TextPaymentNotificationFooter>
          <FormattedMessage
            id={
              isProcessing
                ? 'qam.quickDeposit.processing.footer'
                : 'qam.quickDeposit.completed.footer'
            }
            values={{
              timer: () => <SecondsCountDown totalSeconds={5} />,
            }}
          />
        </TextPaymentNotificationFooter>
      </FlexPaymentStateFooter>
    </FlexPaymentStateWrapper>
  );
}
