import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    maxH: '8',
    fontSize: 'sm',
    fontWeight: 'black',
    fontFamily: 'Fredoka',
    _disabled: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'white',
      bg: 'gray.300',
      borderRadius: 'md',
      color: 'blackAlpha.400',
      maxH: '8',
      fontWeight: 'black',
      fontSize: 'sm',
      boxShadow:
        '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
      cursor: 'not-allowed',
      textShadow: 'unset',
      _hover: {
        bg: 'gray.300',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        color: 'blackAlpha.400',
        borderColor: 'white',
      },
      _after: {
        display: 'none',
      },
      sx: {
        '&&': {
          h: '8',
          w: 'auto',
        },
      },
    },
  },
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  gap: ['1', null, '2'],
};

export const HStackActions: CSSObject = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

export const CheckboxRunnerBoxed: CSSObject = {
  boxSize: '34px',
  borderRadius: 'sm',
  bg: 'blackAlpha.200',
  alignItems: 'center',
  justifyContent: 'center',
  '.chakra-checkbox__control': {
    mb: '2px',
    mt: '0.6rem',
    borderRadius: 'xs',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    border: '1px solid white',
    color: 'white',
    svg: {
      mb: '1px',
      _active: {
        color: 'blackAlpha.600',
      },
      _hover: {
        color: 'blackAlpha.600',
      },
    },
  },
};

type TSelectableButton = { isSelected: boolean };
export const Button = ({ isSelected }: TSelectableButton): CSSObject => ({
  minW: ['30px', null, '48px'],
  minH: ['30px', null, '32px'],
  textAlign: 'center',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.45)',
  fontFamily: 'Fredoka',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 'regular',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  ...(isSelected && {
    color: 'alpha.300',
    bg: 'alpha.800',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    _active: {
      color: 'alpha.300',
      bg: 'alpha.800',
    },
    '&:hover, &:focus': {
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    },
  }),
});

export const FlexOdds: CSSObject = {
  justifyContent: 'center',
  minW: ['38px', null, '14'],
  minH: ['26px', null, '32px'],
  bg: 'blackAlpha.300',
  borderRadius: 'md',
  color: 'blackAlpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  fontFamily: 'Fredoka',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  textTransform: 'uppercase',
  p: '2',
  mr: ['-1.5', null, '1'],
};
