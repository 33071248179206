import React from 'react';
import { Show } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import Toggler from '@/views/Home/conditionals/Toggle/template.bet777';

export const template: TTemplate = {
  wrapper: (
    <Show above="lg">
      <Toggler />
    </Show>
  ),
};

export default template;
