import { CSSObject } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { borderRadius: 'lg', px: 2.5 },
  buttonCashOutAmount: { borderRadius: 'lg', px: 2.5 },
  flexOddsBoostBanner: {
    fontWeight: 'md',
    color: 'white',
    border: 'none',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  },
  iconOddsBoost: {
    fill: 'white',
  },
  textOddsBoost: {
    color: 'white',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.300',
};

export const RaceTime: CSSObject = {
  color: 'gray.400',
  fontWeight: 'medium',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.300',
  color: 'white',
  fontSize: 'sm',
};

export const BonusBetText: CSSObject = {
  color: 'beta.300',
  fontSize: 'sm',
};

export const BetReturn: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  color: 'epsilon.600',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
  border: '1px',
  borderColor: '#e0812f', // TODO: color not in theme
  overflow: 'hidden',
  '> div': {
    gap: '0px',
  },
};

export const BoxBetReturnValue: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'beta.900',
};

export const BoxBetReturnText: CSSObject = {
  p: '1',
  color: 'white',
  bg: 'beta.900',
};
