import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  px: '2',
};

export const PageInfoWrapper: CSSObject = {
  w: ['100%', null, 'container.md'],
};

export const ButtonAction: CSSObject = {
  w: '60',
};

export const Link: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mx: '2',
};
