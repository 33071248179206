import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bg: 'beta.100',
    borderRadius: 'lg',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 255, 255, 1) inset, 0px 0px 0px 3px rgba(200, 178, 119, 1) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.15)',
  },
  heading: { color: 'beta.800' },
  moneyValues: { color: 'beta.800' },
  cashedOutQuestion: { color: 'beta.800' },
  acceptButton: { fontFamily: 'RiverAdventurer', fontSize: '20px' },
  declineButton: {
    variant: 'outline',
    fontFamily: 'RiverAdventurer',
    fontSize: '20px',
  },
};
