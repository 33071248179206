import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  pl: '5',
  pr: '3',
  py: '4',
  h: '65px',
  borderBottom: 'none',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
};
