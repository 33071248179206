import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  borderColor: 'alpha.50',
  color: 'alpha.800',
  h: 'full',
  borderRadius: 'md',
  _hover: {
    bg: 'delta.400',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'alpha.800',
};
