import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px',
  borderColor: 'blackAlpha.400',
  py: '2',
  w: 'fill-available',
  px: '1',
  color: 'white',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});

export const CountdownTimerBox: CSSObject = {
  '.state-ended': {
    bg: 'beta.400',
    color: 'red.500',
  },
};
