import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  keyframes,
  Skeleton,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggleOuter from '@/assets/gigabet/images/toggle/toggleOuter.svg';
import toggleInner from '@/assets/gigabet/images/toggle/toggleInner.svg';
import toggleTick from '@/assets/gigabet/images/toggle/toggleTick.svg';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'lg',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    p: '2',
    mx: ['1.5', null, 'unset'],
    mb: ['1', null, '4'],
    gap: '2',
    boxShadow: [
      null,
      null,
      '0px 4px 8px 0px rgba(0, 0, 0, 0.45), 0px 1px 5px 0px #8297DE inset',
    ],
    bgGradient:
      'linear(180deg, rgba(97, 116, 182, 0.70), rgba(68, 85, 144, 0.70))',
    h: '16',
    pos: 'relative',
  }),
});
const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'lg',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 6px rgba(255, 202, 36, 0.6)'
      : '0px 1px 0px rgba(255, 255, 255, 0.15)',
    color: isActive ? 'alpha.400' : 'blackAlpha.600',
    letterSpacing: '1.8px',
    px: '2.5',
    cursor: 'pointer',
    transition: 'color 0.3s',
    _hover: {
      color: 'alpha.400',
      textShadow: '0px 0px 6px rgba(255, 202, 36, 0.6)',
    },
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra('label', {
  label: 'desktopToggle-Switcher',
  shouldForwardProp: (prop) => !['isChecked'].includes(prop),
  baseStyle: ({ isChecked }: any) => ({
    bg: 'transparent',
    pos: 'relative',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: `url(${toggleOuter})`,
        boxSize: '60px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-35px',
        right: '0',
        top: '-30px',
        animation: isChecked
          ? `
            ${keyframes`
              0% { transform: rotate(0deg) scale(1.0); }
              50% { transform: rotate(270deg) scale(1.1); }
              100% { transform: rotate(540deg) scale(1.0); }
            `} 0.4s linear`
          : `
            ${keyframes`
              0% { transform: rotate(540deg) scale(1.0); }
              50% { transform: rotate(270deg) scale(1.1); }
              100% { transform: rotate(0) scale(1.0); }
            `} 0.4s linear`,
        animationFillMode: 'forwards',
        transformOrigin: '34.8px 29.8px',
      },
      _after: {
        bgImg: `url(${toggleInner})`,
        boxSize: '28px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-14px',
        right: '0',
        top: '-14.5px',
        transform: 'scale(1.0)',
        animation: isChecked
          ? `${keyframes`
          0% { transform: scale(1.0); }
          50% { transform: scale(1.1); }
          100% { transform: scale(1.0); }
        `} 0.5s linear`
          : `${keyframes`
            0% { transform: scale(1.0); }
            50% { transform: scale(1.1); }
            100% { transform: scale(1.0); }
          `} 0.5s linear`,
      },
    },
    '.chakra-switch__track': {
      _before: {
        bgImg: `url(${toggleTick})`,
        boxSize: '40px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-20.5px',
        right: '0',
        top: '-20px',
        zIndex: 1,
        transform: 'scale(1.0)',
        animation: isChecked
          ? `
            ${keyframes`
              0% { transform: rotate(0deg) scale(1.0); }
              50% { transform: rotate(-240deg) scale(1.1); }
              100% { transform: rotate(-480deg) scale(1.0); }
            `} .8s ease-in-out`
          : `
            ${keyframes`
              0% { transform: rotate(-480deg) scale(1.0); }
              50% { transform: rotate(-240deg) scale(1.1); }
              100% { transform: rotate(0deg) scale(1.0); }
            `} .8s ease-in-out`,
        animationFillMode: 'forwards',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;
  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }
  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }
  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  const toggleCategory = (newCategory: string) => {
    setSearchParams({ category: newCategory });
  };

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
        onClick={() => toggleCategory('racing')}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>
      <Switcher isChecked={category && category !== 'racing'}>
        <span className="chakra-switch__track" />
        <span className="chakra-switch__thumb" />
      </Switcher>
      <TextToggle
        data-cy="sports-toggle-text"
        isActive={category === 'sport'}
        onClick={() => toggleCategory('sport')}
      >
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </TextToggle>
    </FlexWrapper>
  );
}
