import {
  chakra,
  ChakraComponent,
  DrawerContent,
  GridItem,
  GridItemProps,
} from '@chakra-ui/react';
import { styleImports } from './Betslip.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const GridItemBetslip = chakra(GridItem, {
  label: 'GridItemBetslip',
  shouldForwardProp: (prop) => !['headerHeight'].includes(prop),
  baseStyle: ({ theme }) => ({
    minW: '0px',
    ...(theme.themeName === EThemes.Betgalaxy && {
      '& > div[class*="-BetSlipModalContainer"]': {
        top: [null, null, null, null, '0.5'],
        maxH: [null, null, null, null, `100vh`],
      },
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.GridItemBetslip;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', GridItemProps>;

export const ModalDrawerContent = chakra(DrawerContent, {
  label: 'betslip-ModalDrawerContent',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ModalDrawerContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
