import { Box, chakra } from '@chakra-ui/react';
import { EThemes } from '@/constants/themeConfig';

export const BoxCheckboxContainer = chakra(Box, {
  label: 'agreements-BoxCheckboxContainer',
  baseStyle: ({ theme }) => ({
    ...((theme.themeName === EThemes.Wellbet ||
      theme.themeName === EThemes.Puntgenie ||
      theme.themeName === EThemes.Betroyale ||
      theme.themeName === EThemes.Volcanobet) && {
      '.chakra-checkbox': {
        alignItems: 'flex-start',
      },
    }),

    ...(theme.themeName === EThemes.Sterlingparker && {
      '> div': {
        _first: { mb: '2' },
      },
    }),
  }),
});
