import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    fontFamily: 'roboto',
    fontSize: '2xs',
    lineHeight: '1.125',
    textTransform: 'none',
    px: 0,
    py: 1.5,
    h: '44px',

    sx: {
      '.btn-span': { zIndex: 9 },
    },

    _hover: {
      bg: 'alpha.500',

      _after: {
        opacity: 0,
      },
    },

    _after: {
      content: `''`,
      position: 'absolute',
      inset: 0,
      zIndex: 8,
      bgGradient: 'linear(180deg, #30BA00 0%, #00CAF6 0.01%, #006E86 100%)',
      boxShadow:
        '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
      transition: 'all .4s ease-in-out',
      m: '1px',
      borderRadius: '7px',
      opacity: 0.8,
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  },
  buttonBaseBox: {
    fontSize: 'sm',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
