import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  heading: {
    mb: '2',
    color: 'white',
    fontWeight: '700',
  },
  promotionWrapper: {
    p: '4',
    my: '3',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    position: 'relative',
    zIndex: 0,
    _after: {
      position: 'absolute',
      inset: 0,
      bg: 'white',
      content: `''`,
      borderRadius: 'base',
      m: '2px',
      zIndex: -1,
    },
  },
  flexWrapper: { gap: '4', pt: '4', justifyContent: 'space-between' },
  termsAndConditions: {
    color: 'gray.700',
    fontSize: 'sm',
    pt: '1',
  },
};
