import React, { FC, useState } from 'react';
import { ChevronDown, ChevronUp } from 'styled-icons/evil';
import { Icon } from '@chakra-ui/react';
import {
  ERaceBetType,
  ETabbarBetTypes,
  TRaceToteResult,
} from '../../../../../../lib/DBModels';
import ExoticResultsRow from '../ResultsRow';
import ResultsTableHeader from '../ResultsTableHeader/ResultsTableHeader';
import { ExoticResultedWrapper } from './styles/Results.styles';
import { TToteMultiResults } from '@/views/races/RaceDetails/types';
import ToteMultiResultRow from '../ToteMultiRow';
import { ShowMoreText } from '@/views/races/RaceDetails/styles/RaceDetails.styles';
import { toteMultiListSelectionsIcon } from '../SelectionPopup/styles/SelectionPopup.styles';

type ExoticResultsProps = {
  results: TRaceToteResult[];
  multiResults?: TToteMultiResults[];
};

export const ExoticResults: FC<ExoticResultsProps> = ({
  results,
  multiResults,
}) => {
  const [expanded, setExpanded] = useState(false);
  const exoticSortOrder = [
    ERaceBetType.Quinella,
    ERaceBetType.Exacta,
    ERaceBetType.Trifecta,
    ERaceBetType.FirstFour,
  ];

  const toteMultiSortOrder = [
    ETabbarBetTypes.Quaddie,
    ETabbarBetTypes.EarlyQuaddie,
    ETabbarBetTypes.Treble,
    ETabbarBetTypes.DailyDouble,
    ETabbarBetTypes.RunningDouble,
    ETabbarBetTypes.BigSix,
  ];

  const filteredAndSortedResults = results
    .map((r) => {
      // Sets Quinella result to first Exacta result. Quinellas are normalized in BE. eg. will display 2,3 instead of 3,2. Might still have issues with Dead Heats when multiple Exacta results exist
      if (r.type === ERaceBetType.Quinella) {
        const exacta = results.find((res) => res.type === ERaceBetType.Exacta);
        if (exacta) {
          return { ...r, selection: exacta.selection };
        }
      }
      return { ...r };
    })
    .sort(
      (a, b) =>
        (a.type ? exoticSortOrder.indexOf(a.type) : -1) -
        (b.type ? exoticSortOrder.indexOf(b.type) : -1)
    );

  const filteredToteMultiResults = multiResults
    ?.map((r) => ({ ...r }))
    .sort(
      (a, b) =>
        (a.type ? toteMultiSortOrder.indexOf(a.type as ETabbarBetTypes) : -1) -
        (b.type ? toteMultiSortOrder.indexOf(b.type as ETabbarBetTypes) : -1)
    );

  return (
    <ExoticResultedWrapper>
      <ResultsTableHeader />
      {filteredAndSortedResults.map((result) => (
        <ExoticResultsRow key={result.dividend} {...result} />
      ))}
      {expanded &&
        filteredToteMultiResults?.map((result) => (
          <ToteMultiResultRow key={result.dividend} {...result} />
        ))}

      {filteredToteMultiResults.length !== 0 && (
        <ShowMoreText
          type="button"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Icon
            as={expanded ? ChevronUp : ChevronDown}
            {...toteMultiListSelectionsIcon}
          />
          {expanded ? 'Show less' : 'Show Multiples'}
        </ShowMoreText>
      )}
    </ExoticResultedWrapper>
  );
};
