import { getURLBasedOnLocation } from '@/helpers/utils';
import { TLinks } from './cms.types';

export const queryCMS = async (): Promise<TLinks[]> => {
  const response = await fetch(
    `https://assets.${getURLBasedOnLocation()}/cms/side_nav.json?time=${new Date().getTime()}`
  );
  const data = await response.json();
  return data;
};
