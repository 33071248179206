import { CSSObject, TextProps } from '@chakra-ui/react';
import Men from '@/assets/sterlingparker/images/backgrounds/men-promo.png';
import Particles from '@/assets/sterlingparker/images/backgrounds/particles.png';

export const FlexTextWrapper: CSSObject = {
  gap: '2',
};

export const textSloganProps: TextProps = {
  bgGradient: 'linear(to-b, #ffce70, #cc952b)',
  color: 'transparent',
  bgClip: 'text',
  textShadow: '0px 8px 14px rgba(0, 0, 0, 0.40)',
  fontSize: ['34.5px', null, '43px'],
  fontFamily: 'Crimson Pro',
  fontStyle: 'normal',
  fontWeight: 500,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  letterSpacing: '0',
};

export const FlexTeamWrapper: CSSObject = {
  gap: '2',
  mt: '4px',
  mb: 0,
  border: 0,
  flexDir: 'column',
  justifyContent: 'center',
};

export const FlexWrapper: CSSObject = {
  h: ['300px', null, '280px'],
  bgImage: [`url(${Particles})`, null, `url(${Men}), url(${Particles})`],
  bgSize: ['cover', null, '50%, 100%'],
  bgRepeat: 'no-repeat',
  bgPos: ['center', null, 'right center, center'],
  mt: [0, null, '16px'],
  border: 0,
};

export const TextVS: CSSObject = {
  borderRadius: '4px',
  background:
    'var(--bc-gradient-gold, linear-gradient(180deg, #FFCE70 0%, #CC952B 100%))',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  display: 'flex',
  width: '31px',
  height: '31px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'var(--bc-black-alpha-800, rgba(0, 0, 0, 0.80))',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
  fontSize: '13.286px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: 'normal',
};
export const TextMisc: CSSObject = {
  fontSize: ['12px', null, '14px'],
  h: ['30px'],
  bg: 'blackAlpha.500',
  color: 'alpha.500',
  display: 'inline-block',
  alignItems: 'center',
  borderRadius: '4px',
  boxShadow: '0 1px 0 0 #fff6, inset 0 2px 4px 0 #00000040',
  maxW: ['90dvw', '60dvw', '50dvw', '40dvw', '500px'],
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const TextTeam: CSSObject = {
  color: 'white',
  textShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: ['14px', null, '18px'],
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
  textTransform: 'none',
  maxW: ['90dvw', '60dvw', '50dvw', '40dvw', '500px'],
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
export const BoxHeadingWrapper: CSSObject = {
  marginLeft: ['auto', null, 0],
  maxW: '400px',
  w: '100%',
};
export const puntButtonProps: CSSObject = {
  border: 'none',
  cursor: 'pointer',
  zIndex: 'dropdown',
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: 3,
};
