import { CSSObject, TextProps } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const closeIcon: TCloseIcon = {
  boxSize: '6',
  as: CloseCircle,
  color: 'white',
};

export const CloseIconButton: CSSObject = {
  justifyContent: 'end',
};

export const FlexBetslipHeading: CSSObject = {
  h: '46px', // no token
  borderBottomColor: 'blackAlpha.400',
  borderBottom: '1px',
  borderStyle: 'dashed',
  color: 'white',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',

  '+ .chakra-fade': {
    mt: '-1px',
  },
};

export const BetslipTitle: CSSObject = {
  fontFamily: 'Bowlby One',
  fontSize: 'sm',
  fontWeight: 'base',
  color: 'white',
  mt: '1',
  gap: '1',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  '&&': {
    svg: {
      color: 'beta.400',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    },
  },
};

export const BetLengthIndicator: CSSObject = {
  bg: 'beta.400',
  fontSize: 'xs',
  borderRadius: 'base',
  color: 'alpha.700',
  letterSpacing: 'wider',
  fontFamily: 'Roboto',
  textTransform: 'uppercase',
  fontWeight: 'extrabold',
  px: '2',
  py: '1',
  ml: '2',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const BetslipTitleText: TextProps = {
  textTransform: 'uppercase',
  fontFamily: 'Bowlby One',
};
