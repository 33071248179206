/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  CenterWrapper,
  Header,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import Logo from '../../../components/Headers/HeaderPrimary/conditionals/Logo/template';
import Content from '../components/Content/Content';

const template = (): TTemplate => ({
  wrapper: <div />,
  center: {
    wrapper: <MaintenanceContainer />,
    header: (
      <Header>
        <Logo />
      </Header>
    ),
    main: {
      wrapper: <CenterWrapper />,
      body: <Content />,
    },
  },
});

export default template;
