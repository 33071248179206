import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { keys } from '../../api/api.keys';
import Logo from '../../components/Footer/conditionals/Logo/template';
import { FlexHeaderContainer } from './styles/LocationNotAllowed.styles';

const Header: FC = () => {
  const client = useQueryClient();
  return (
    <FlexHeaderContainer>
      <Box
        cursor="pointer"
        onClick={async () => {
          await client.invalidateQueries([keys.location]).catch();
        }}
      >
        <Logo />
      </Box>
    </FlexHeaderContainer>
  );
};

export default Header;
