import { CSSObject } from '@emotion/react';

export const TextTitle: CSSObject = {
  fontFamily: ['unset', 'unset', 'strippy'],
  textTransform: 'uppercase',
  '&&': {
    mb: '2',
    zIndex: 'docked',
    fontSize: ['md', '24px'],
    fontWeight: 'medium',
    justifyContent: 'space-between',
  },
};

export const TextSpan: CSSObject = {
  flexWrap: 'wrap',
  fontFamily: 'unset',
  alignItems: 'center',
};
