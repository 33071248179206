import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderColor: 'blackAlpha.400',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
    pb: '2',
  },
  btnGroupWrapper: {
    bgGradient: 'linear(to-b, beta.700, beta.800)',
    borderRadius: '5px',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxShadow:
      '0px 3px 4px 0px rgba(0, 0, 0, 0.15),  0px 0px 0px 1px rgba(57, 40, 5, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
    minW: '90px',
    gap: 'initial',
  },
  btnGroupText: {
    color: 'beta.500',
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'xs',
    textShadow: '0 0 black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'delta.800',
    h: '100%',
    borderTop: '1px solid #392805',
    borderBottom: '1px solid #392805',
  },
  btnGroupIcon: {
    color: 'beta.500',
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.3))',
      h: '5',
      w: '7',
    },
  },
  name: {
    color: 'beta.800',
  },
};
export default styles;
