import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ChevronDownCircle } from '@styled-icons/boxicons-regular/ChevronDownCircle';
import { uuid } from 'uuidv4';
import { Button } from '@/components/Button/Button';
import { useSRMultiBet } from '@/components/Betslip/Services/Betslip.hooks';
import { selectionStyles } from './styles/Selections.styles';
import { useAppSelector } from '@/hooks/useRedux';
import ErrorBanner from '../ErrorBanner/ErrorBanner';
import { miniBetSlipStyles } from '@/views/sports/NewMatchDetailPage/styles/MiniBetSlip/MiniBetSlip.styles';
import { TSelection } from './services/Selections.hooks';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

type TSRMSelections = {
  selections: TSelection[][];
  clearSelections: () => void;
  price: number | undefined;
  addButtonState: 'disabled' | 'loading' | 'enabled';
  getRunnerByNumber(runnerNumber: number): any;
  winMarket: any;
  venueId: string | null;
  displayErrorBanner: boolean;
  setDisplayErrorBanner(val: boolean): void;
};
export default function SRMSelections({
  selections,
  clearSelections,
  price,
  addButtonState,
  getRunnerByNumber,
  winMarket,
  venueId,
  displayErrorBanner,
  setDisplayErrorBanner,
}: TSRMSelections) {
  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const { addToBetSlip } = useSRMultiBet();
  const { setBet } = getBetSlipStoreActions();

  const venueObj = raceRunnerList?.venues?.find(
    (venue) => venue?.venue_id === venueId
  );

  const raceMeta = raceRunnerList?.raceMeta;

  const isMarketAvailable =
    !!winMarket && !!venueObj && !!raceMeta && !!selections;

  if (!selections?.length || !isMarketAvailable) return null;

  return (
    <Flex {...selectionStyles.errorWrapper}>
      <Box {...miniBetSlipStyles.slideBox} w="full" position="relative">
        {displayErrorBanner && (
          <ErrorBanner setDisplayErrorBanner={setDisplayErrorBanner} />
        )}
        <Accordion allowToggle defaultIndex={0}>
          <AccordionItem {...miniBetSlipStyles.accordionItem}>
            {({ isExpanded }) => (
              <>
                <chakra.h2 {...miniBetSlipStyles.accordionHeaderText}>
                  <AccordionButton {...miniBetSlipStyles.accordionButton}>
                    <Icon
                      {...miniBetSlipStyles.accordionButtonIcon}
                      as={ChevronDownCircle}
                      transform={isExpanded ? 'scaleY(-1)' : undefined}
                    />
                    <Box as="span">
                      <FormattedMessage
                        id={`racing.srm.${
                          isExpanded ? `hideSelections` : `viewSelections`
                        }`}
                      />
                    </Box>
                  </AccordionButton>
                </chakra.h2>
                <AccordionPanel
                  as="dl"
                  pb={4}
                  {...miniBetSlipStyles.accordionPanel}
                >
                  {selections.map((index, i) =>
                    index?.map((r: TSelection | undefined) => {
                      const pos = i === 0 ? 'Win' : `Top ${i + 1}`;
                      const runner = getRunnerByNumber(r?.runner_number ?? 0); // Assuming `getRunnerByNumber` is a valid function
                      if (!runner) return;

                      return (
                        <Flex key={i} {...miniBetSlipStyles.selectionFlex}>
                          <chakra.dt {...miniBetSlipStyles.selectionMarketName}>
                            {runner?.number}. {runner?.display_name} (
                            {runner?.barrier_number})
                          </chakra.dt>
                          <chakra.dd
                            {...miniBetSlipStyles.selectionPropositionName}
                          >
                            <span>{pos}</span>
                          </chakra.dd>
                        </Flex>
                      );
                    })
                  )}
                </AccordionPanel>
                <Stack
                  as="footer"
                  borderTopWidth={isExpanded ? '1px' : undefined}
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                  _before={{
                    opacity: !isExpanded ? '0' : undefined,
                  }}
                  {...miniBetSlipStyles.footerStack}
                >
                  <Flex {...selectionStyles.priceWrap}>
                    <Box {...selectionStyles.priceBox}>
                      <Text {...miniBetSlipStyles.oddsText}>
                        <FormattedMessage
                          id="sports.matchDetailPage.legs"
                          values={{
                            legs: selections.reduce((a, c) => a + c.length, 0),
                            strong: (chunks) => (
                              <chakra.strong {...miniBetSlipStyles.legsChunks}>
                                {chunks}
                              </chakra.strong>
                            ),
                          }}
                        />
                      </Text>
                    </Box>
                    <Box {...selectionStyles.priceBox}>
                      <Text {...miniBetSlipStyles.oddsText}>
                        <FormattedMessage
                          id="sports.matchDetailPage.odds"
                          values={{
                            odds: price?.toFixed(2),
                            hasOdds: !!price,
                            strong(chunks) {
                              return (
                                <chakra.strong
                                  {...miniBetSlipStyles.legsChunks}
                                >
                                  {addButtonState === 'loading'
                                    ? 'N/A'
                                    : chunks}
                                </chakra.strong>
                              );
                            },
                          }}
                        />
                      </Text>
                    </Box>
                  </Flex>
                  <HStack gap={2} {...miniBetSlipStyles.buttonsHStack}>
                    <Button
                      {...miniBetSlipStyles.clearButton}
                      cursor="pointer"
                      onClick={clearSelections}
                    >
                      <FormattedMessage id="srm.clearSelections" />
                    </Button>

                    <Button
                      isDisabled={
                        addButtonState === 'disabled' ||
                        selections.reduce((a, c) => a + c.length, 0) <= 1
                      }
                      isLoading={addButtonState === 'loading'}
                      {...miniBetSlipStyles.addToSlipButton}
                      onClick={() => {
                        setBet({
                          id: uuid(),
                          type: 'SRMulti',
                          selection: selections,
                          raceId: raceMeta.race_id ?? '',
                          odds: Number(price),
                          misc: {
                            ...raceMeta,
                            ...venueObj,
                            ...winMarket,
                            race: raceMeta,
                            venue: venueObj,
                            market: winMarket,
                          },
                        });

                        addToBetSlip({
                          race: raceMeta,
                          placedSelections: selections,
                          venue: venueObj,
                          market: winMarket,
                          selectionOdds: Number(price),
                        });
                      }}
                    >
                      <FormattedMessage id="racing.generic.addToBetSlip" />
                    </Button>
                  </HStack>
                </Stack>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
    </Flex>
  );
}
