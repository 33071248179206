import { Box, Skeleton } from '@chakra-ui/react';
import React from 'react';

export default function Loader() {
  return (
    <Box sx={{ p: '2' }}>
      <Skeleton sx={{ h: '44px' }} />
    </Box>
  );
}
