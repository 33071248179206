import { CSSObject } from '@chakra-ui/react';
import menuTile from '../../../assets/vikingbet/images/backgrounds/texture-wood-1x.png';
import menuTile2x from '../../../assets/vikingbet/images/backgrounds/texture-wood-2x.png';

export const GridItemBetslip: CSSObject = {
  h: '98%',
  '& > div[class*="-BetSlipModalContainer"]': {
    position: 'relative',
    borderRadius: '1.2rem',
    borderY: '#C5C4C0 5px solid',
    borderX: '#C5C4C0 8px solid',
    boxShadow:
      '0px 0px 5px 5px rgba(0, 0, 0, 0.40) inset, 0px 5px 0px 0px #757472, 0px 10px 0px 0px #464646, 0px -4px 0px 0px #EDEDEC',
    bg: `url(${menuTile})`,
    bgRepeat: 'repeat-y',
    bgSize: '100% 100%',
    zIndex: '5',
    width: '100%',
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '100%',
    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bg: `url(${menuTile2x})`,
      bgSize: '100%',
    },
  },
};

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
  maxWidth: ['inherit', '272px'],
};
