import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { TIndex } from '@/components/FormElements/Input';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'secondary',
  fontWeight: 'normal',
};

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderTop: '1px dashed',
  borderTopColor: 'blackAlpha.500',
  borderBottom: '1px dashed',
  borderBottomColor: 'whiteAlpha.300',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
  bg: 'orange.400',
  color: 'orange.800',
};

export const BoxCardsContainer: CSSObject = {
  mb: '2',
};

export const BoxInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
  },
  '.chakra-input__left-addon': {
    bg: ' transparent',
    h: 'auto',
    border: 'none',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  '&& .chakra-input': {
    bg: 'gamma.800',
    border: 'none',
    fontSize: 'sm',
    m: 'px',
    fontWeight: 'bold',
    borderLeft: 'none',
    borderLeftRadius: 'none',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'gamma.900',
      border: 'none',
    },
    _focus: {
      bg: 'alpha.700',
      border: 'none',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const RadioCard: CSSObject = {
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    borderColor: 'white',
    _hover: {
      bg: 'blue.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'alpha.500',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'alpha.800',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
  fontWeight: 'normal',
};

export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  color: 'alpha.800',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
};

export const depositInputProps: Partial<TIndex> = {
  sxWrapper: {
    '&&': {
      '.chakra-input': {
        bg: 'gamma.800',
        border: 'none',
        _hover: {
          bg: 'gamma.900',
          border: 'none',
        },
        _focus: {
          bg: 'alpha.700',
          border: 'none',
        },
      },
    },
  },
};
