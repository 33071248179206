import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    display: 'flex',
    variant: 'unstyled',
    size: '2xs',
    px: '1.5',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    border: '1px',
    borderColor: 'alpha.400',
    bg: 'none',
    bgGradient: 'linear(to-b, alpha.200, alpha.400)',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    borderRadius: 'md',
    color: 'white',
    spanProps: {
      fontFamily: 'accent',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      fontWeight: 'normal',
      fontSize: '11px',
      px: '0',
      py: '1',
    },

    _disabled: {
      boxShadow: 'none',
      border: 'none',
      bg: 'blackAlpha.300',
      color: 'beta.50',
      opacity: '1',
      '& > span': {
        _first: {
          color: 'green.300',
          mr: '1',
        },
      },
    },

    sx: {
      '.chakra-button__icon': {
        display: 'none',
      },

      '&&[data-isbonusbet="true"]': {
        _disabled: {
          w: '100%',
          mt: '-4',
          bg: 'blackAlpha.600',
          borderRadius: 'base',
          boxShadow: 'none',

          '.chakra-button__icon': {
            display: 'block',
            color: 'green.300',
            mr: '0.5',
          },

          spanProps: {
            fontFamily: 'roboto',
            fontWeight: 'medium',
            fontSize: '2xs',
            pt: '0.5',
            textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
            textTransform: 'uppercase',
          },
        },

        bg: 'alpha.800',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'beta.500',
        borderRadius: 'md',
        h: '26px',
        spanProps: {
          fontFamily: 'Staatliches',
          textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
          fontWeight: 'normal',
          fontSize: '11px',
          px: '2',
          pt: '0.5',
        },
      },
    },
  },
  flexWrapperMysteryBetFooterBetPlaced: {},
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    w: '-webkit-fill-available',
    color: 'beta.500',
    h: 'auto',
    py: 1.5,
  },
  spanBetOdds: {
    fontSize: 'sm',
    fontWeight: 'normal',
  },
  spanRaceInfo: {
    fontWeight: 'normal',
  },
  flexRaceInfoOverride: {
    fontSize: 'sm',
  },
  buttonBetPropositionClosedProps: {
    variant: 'unstyled',
    bg: 'orange.500',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    borderRadius: 'base',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'xs',
    px: '3',
    py: '2',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  buttonStakeProps: {
    fontFamily: 'Staatliches',
    fontSize: 'sm',
    fontWeight: 'normal',
    h: '42px',
    color: 'white',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    maxW: '47px',
  },
  betCardSVG: {
    fill: 'alpha.400',
  },
};

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};

export const BetCardContainer = (): CSSObject => ({
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  color: 'alpha.50',
  mb: '-2.5',
  bg: 'alpha.400',
  borderRadius: 'md',
  '.state-prior': { color: 'white' },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'alpha.50',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'white',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'beta.500',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'white',
  },
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '8',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, white, white)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'beta.500' : 'white',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const EventRule: CSSObject = {
  color: 'white',
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgb(0,112,215,0.7)',
  borderRadius: 'md',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};

export const BetOdds: CSSObject = {
  mb: '2px',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '10',
};
