import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  TypeItem,
  ResultRowWrapper,
  ResultRowExoticRow,
  ResultRowExoticWrapper,
} from './Results/styles/Results.styles';
import { centsToDollars } from '@/helpers/utils';
import { TToteMultiResults } from '@/views/races/RaceDetails/types';
import { SUB_TABS_DISPLAY_NAME } from '@/components/TabBar/TabBar';

export default function ToteMultiResultRow(result: TToteMultiResults) {
  const { dividend, type, selection, legs } = result;
  return (
    <ResultRowWrapper>
      <Box>
        <TypeItem>{SUB_TABS_DISPLAY_NAME[type] ?? type}</TypeItem>
      </Box>
      <ResultRowExoticWrapper>
        <Box>
          <ResultRowExoticRow data-cy="toteMultiResult">
            {legs?.map((sel, i) => (
              <Text key={i} w="30px">{`R${sel}${
                i < legs.length - 1 ? ', ' : ''
              }`}</Text>
            ))}
          </ResultRowExoticRow>
          <ResultRowExoticRow data-cy="exoticsResult">
            {selection?.map((sel, i) => (
              <Text w="30px" key={sel}>
                {sel}
                {i < selection.length - 1 ? ', ' : ''}
              </Text>
            ))}
          </ResultRowExoticRow>
        </Box>
        <ResultRowExoticRow data-cy="exoticsDividend" className="dividends">
          {centsToDollars(dividend * 100, false)}
        </ResultRowExoticRow>
      </ResultRowExoticWrapper>
    </ResultRowWrapper>
  );
}
