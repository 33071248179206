export const links = [
  { name: 'Overview', href: '/account/overview', dataCyValue: 'overviewLink' },
  { name: 'My Bets', href: '/account/my-bets', dataCyValue: 'myBetsLink' },
  { name: 'Deposit', href: '/account/deposit', dataCyValue: 'depositLink' },
  {
    name: 'Withdrawal',
    href: '/account/withdrawal',
    dataCyValue: 'withdrawalLink',
  },
  {
    name: 'Deposit Limit',
    href: '/account/deposit-limit',
    dataCyValue: 'depositLimitLink',
  },
  {
    name: 'Transactions',
    href: '/account/transactions?type=transactions',
    dataCyValue: 'transactionsLink',
  },
  {
    name: 'Manage Account',
    href: '/account/settings/responsible-gambling',
    dataCyValue: 'manageAccountLink',
  },
  { name: 'Settings', href: '/account/settings', dataCyValue: 'settingsLink' },
];
