import { css } from 'styled-components';

export const header = css`
  background: ${({ theme }) => theme.palette.secondary};
  padding: var(--bc-sizes-0-5);
`;

export const heading = css`
  color: ${({ theme }) => theme.basePalette.white};
  text-align: center;
  padding: var(--bc-sizes-2);
  width: 100%;
`;

export const subItem = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-style: italic;

  &:hover,
  &:focus {
    background: ${({ theme }) => `${theme.basePalette.purple}50`};
  }
`;

export const subItemIcon = css`
  path {
    fill: ${({ theme }) => theme.basePalette.brightBlue};
  }
`;
