import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'odds',
  height: '38px',
};
export const FlexWrapper: CSSObject = {
  borderBottomRadius: 'md',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};
export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, alpha.100 0%, alpha.500 100%)',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bg: ' transparent',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  // '.chakra-input': {
  //   bg: 'beta.900',
  //   border: 'none',
  //   boxShadow: 'none',
  //   m: '2px',
  //   fontSize: 'sm',
  //   fontWeight: 'bold',
  //   color: 'white',
  //   _placeholder: {
  //     fontWeight: 'bold',
  //   },
  //   _hover: {
  //     bg: 'beta.600',
  //     border: 'none',
  //   },
  //   _focus: {
  //     bg: 'beta.500',
  //     boxShadow: 'none',
  //     border: 'none',
  //     // m: '0',
  //   },
  // },
};
export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  mx: [0, null, '-3'],
  mb: [0, null, '-6'],
  bgGradient: `linear(to-b, beta.300, beta.400)`,
  borderBottomRadius: ['none', null, 'md'],
  zIndex: '10',
};
export const InfoHeader: CSSObject = {
  h: '9',
  alignItems: 'center',
  px: '4',
  py: '3',
  borderTop: '3px solid',
  borderTopColor: 'alpha.400',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.300',
};
export const InfoText: CSSObject = {
  _first: {
    fontSize: 'sm',
    color: 'alpha.400',
    fontFamily: 'accent',
    fontWeight: 'normal',
  },
  _last: {
    fontSize: 'sm',
    color: 'alpha.400',
    fontFamily: 'accent',
    fontWeight: 'normal',
  },
};
export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'normal',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'beta.700',
  borderBottom: '1px dashed',
  borderColor: 'white',

  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-3px',
  width: '24px',
  height: '24px',
  color: 'gray.800',
};