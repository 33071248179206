import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryOfferings } from '../../../../api/offerings/offerings.hooks';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { sortByRaceType } from '@/helpers/sortRaces';
import { ERaceType } from '../../../../lib/DBModels';
import { setFilter, toggleFilter } from '../../../../redux/raceTable.slice';

export const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get('raceType')?.replace('+', ' ') as
    | ERaceType
    | undefined;

  const dispatch = useAppDispatch();
  const query = useQueryOfferings();

  const { filters: raceTableFilters } = useAppSelector(
    (state) => state.raceTable
  );

  const offeredRaces = (() =>
    query.data?.offered_race_types
      ? ([...query.data.offered_race_types]
          .filter((t) => !!t)
          .sort(sortByRaceType) as ERaceType[])
      : undefined)();

  /**
   * On init get the chosen raceType
   */
  useEffect(() => {
    if (param && !raceTableFilters.length) {
      dispatch(setFilter(param));
      searchParams.delete('raceType');
      setSearchParams(searchParams);
    }

    if (!param && !raceTableFilters.length) {
      dispatch(setFilter(offeredRaces?.[0] ?? ERaceType.Horse));
    }
  }, [
    dispatch,
    offeredRaces,
    param,
    raceTableFilters.length,
    searchParams,
    setSearchParams,
  ]);

  return {
    query,
    offeredRaces,
    filters: raceTableFilters,
    setFilters: (filter: ERaceType) => dispatch(toggleFilter(filter)),
  };
};
