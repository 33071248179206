import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  color: 'black',
};

export const BoxTakeActionTable: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  bg: 'white',
  p: '2',
  border: '0',
};

export const TextTakeAction: CSSObject = {
  color: 'gamma.600',
  fontWeight: 'medium',
};

export const TakeActionHeading: CSSObject = {
  color: 'gamma.600',
};
