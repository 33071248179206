import { CSSObject } from '@chakra-ui/react';

export const GridItemBetslip: CSSObject = {
  h: '100%',
  '& > div[class*="-BetSlipModalContainer"]': {
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '100%',
    borderWidth: '2px',
    borderColor: 'beta.500',
  },
};

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
  borderRadius: 'lg',
  maxWidth: ['inherit', '272px'],
};
