import { CSSObject } from '@chakra-ui/react';

export const LoginButton: CSSObject = {
  bg: 'alpha.500',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
  fontSize: 'xs',
  _hover: {
    bg: 'alpha.700',
  },
  _active: {
    bg: 'alpha.800',
  },
};

export const LinkSignup: CSSObject = {
  display: 'none',
};

export const LinkForgotPassword: CSSObject = {
  color: 'alpha.500',
  fontSize: 'xs',
  fontWeight: 'medium',
  fontFamily: 'Roboto',
};
