import { Box } from '@chakra-ui/react';
import React from 'react';
import desktop from '@/assets/junglebet/images/banner/bannerDesktop.png';
import mobile from '@/assets/junglebet/images/banner/bannerMb.png';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <Box
            key="banner-image"
            sx={{
              w: '100%',
              h: ['35vh', null, '235px'],
              bgImage: [mobile, null, desktop],
              bgSize: '100% 100%',
              bgRepeat: 'no-repeat',
              borderBottom: '1',
              borderColor: ['transparent', null, 'white'],
            }}
          />,
        ]}
      />
    </Box>
  );
}
