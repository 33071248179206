import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'secondary',
  pt: '1',
  color: 'alpha.700',
  sx: {
    '&&': {
      _disabled: {
        opacity: 0.4,
        pointerEvents: 'none',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        '&&': {
          _hover: {
            bgGradient: 'linear(to-b, gamma.300, gamma.500)',
            opacity: 0.4,
            pointerEvents: 'none',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          },
        },
      },
    },
  },
};
export const DepositContainer: CSSObject = {
  mb: '2',
};
export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};
export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};
export const RowDivider: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.4) ',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  borderStyle: 'dashed',
};
export const FlexWrapper: CSSObject = {
  py: '3',
  '.chakra-radio': {
    mr: 'auto',
  },
};
export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};
export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};
export const BoxCardsContainer: CSSObject = {
  mb: '2',
};
export const BoxInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bg: 'alpha.200',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bg: 'beta.400',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
    fontSize: 'sm',
    fontFamily: 'accent',
  },
  '.chakra-input': {
    fontFamily: 'accent',
    _placeholder: {
      fontWeight: 'bold',
      fontSize: 'sm',
      fontFamily: 'accent',
    },
    _hover: {
      fontFamily: 'accent',
    },
    _focus: {
      fontFamily: 'accent',
    },
  },
};
export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};
export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};
export const RadioCard: CSSObject = {
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    borderColor: 'white',
    _hover: {
      bg: 'blue.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const bannerHeaderProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'bold',
  lineHeight: '16px',
  color: 'gamma.400',
};
export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontFamily: 'accent',
  color: 'white',
  mt: '0.5',
};
export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  pt: '1',
  color: 'alpha.700',
  fontWeight: 'normal',
};
export const IconButton: CSSObject = {
  ml: '2.5',
  w: '7',
  h: '9',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  borderRadius: 'base',
  color: 'alpha.700',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
};
