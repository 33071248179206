import { InputProps } from '@chakra-ui/input';
import theme from '@chakra-ui/theme';

export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      color: 'white',
      fontSize: 'sm',
      fontWeight: 'bold',
      caretColor: 'currentColor',
      fontFamily: 'ProximaNova',

      '&&&': {
        boxShadow: 'none',
        border: '2px',
        borderRightRadius: 'base',
        borderColor: 'beta.400',
        borderLeft: 'none',
        bg: 'beta.800',
      },

      '&::placeholder': {
        color: 'white',
      },

      '&:hover': {
        '&&': {
          bg: 'beta.700',
          borderLeft: 'none',
          color: 'white',
        },
      },

      '&:focus, &:active': {
        '&&': {
          bg: 'beta.900',
          borderLeft: 'none',
          color: 'beta.400',
        },
      },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      fontWeight: 'bold',
      fontSize: 'sm',
      fontFamily: 'ProximaNova',

      '&&': {
        mr: 0,
        bg: 'beta.400',
        color: 'beta.900',
        textShadow: 'none',
        borderLeftRadius: 'base',
        border: 'none',
      },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
    md: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
  },
  variants: {
    unstyled: (props: InputProps) => {
      const { addon, field } = theme.components.Input.variants.outline(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          '&&': {
            boxShadow: 'none',
            color: 'black',
            bg: 'white',
            borderRadius: 'base',
            h: '40px',
            borderColor: 'white',
            pl: '2.5',
          },
          '&:focus, &:active, &:hover': {
            '&&': {
              bg: 'white',
              borderColor: 'white',
              color: 'black',
            },
          },
        },
      };
    },
  },
  defaultProps: {
    size: 'md',
  },
};
