import React from 'react';
import { TExtendedProposition } from '../../MatchDetailPage/services/MatchDetailPage.types';
import {
  MarketLayoutRow,
  MarketLayoutTabPanel,
  MarketLayoutTabs,
  TMarketLayoutProps,
} from '../MarketLayout/MarketLayout';
import PropositionButton from '../PropositionButton/PropositionButton';

export type TInlineLayoutProps = Pick<
  TMarketLayoutProps,
  'parts' | 'onSelection'
>;

export default function InlineLayout({
  parts,
  onSelection,
}: TInlineLayoutProps) {
  return (
    <MarketLayoutTabs labels={parts.map(({ title }) => title)}>
      {parts.map(({ title, markets }, i) => {
        const propositions = markets
          .flatMap((m) => m.propositions)
          .filter((p): p is TExtendedProposition => !!p);

        if (!propositions.length) {
          return (
            <MarketLayoutTabPanel key={i}>
              <MarketLayoutRow isFirst>N/A</MarketLayoutRow>
            </MarketLayoutTabPanel>
          );
        }

        return (
          <MarketLayoutTabPanel key={title}>
            <MarketLayoutRow isFirst>
              {propositions.map((p) => (
                <PropositionButton
                  key={p.proposition_id}
                  flex="1"
                  isActive={p.is_selected}
                  data-active={p.is_selected}
                  isDisabled={p.is_disabled}
                  isSuspended={p.is_suspended}
                  odds={p.return_amount}
                  propositionName={
                    p.proposition_info?.participant ?? p.proposition_name
                  }
                  onClick={() => onSelection?.(p)}
                />
              ))}
            </MarketLayoutRow>
          </MarketLayoutTabPanel>
        );
      })}
    </MarketLayoutTabs>
  );
}
