import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};

export const BoxTakeActionTable: CSSObject = {
  bg: 'white',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  border: '0',
  p: '2',
};

export const TextTakeAction: CSSObject = {
  color: 'alpha.600',
};

export const TakeActionHeading: CSSObject = {
  color: 'alpha.600',
};
