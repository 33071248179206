import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },
  btnGroupWrapper: {
    color: '#FFE6B6',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    border: 'none',
    position: 'relative',
    boxShadow:
      '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 'sm',
    px: '1',
    gap: '1',

    _after: {
      content: `''`,
      position: 'absolute',
      inset: 0,
      zIndex: 8,
      bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
      boxShadow: '0px 0px 0px 2px rgba(19, 39, 75, 1) inset',
      borderRadius: 'md',
      m: '2px',
      opacity: 1,
    },

    _hover: {
      _after: {
        bg: 'gamma.600',
        borderRadius: 'md',
      },
    },

    sx: {
      '&&&': {
        borderRadius: '7px',
        '& > span, & > svg': { zIndex: 9 },
      },
    },
  },
  btnGroupText: {
    fontSize: 'sm',
    color: '#FFE6BE',
    zIndex: 'overlay',
    fontWeight: 'black',
    fontFamily: 'roboto',
  },
  btnGroupIcon: {
    color: 'beta.300',
  },
  name: {
    color: 'black',
  },
};
export default styles;
