import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  m: '6px',
  mb: 0,
  p: 0,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.4)',
};
export const CardContainer: CSSObject = {
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset',
  borderRadius: 'md',
  bg: 'beta.600',
  p: 1.5,
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  mx: '6px',
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  display: 'flex',
  '&': {
    px: 0,
  },
  alignItems: 'start',
  '> svg': {
    color: 'white',
    fill: 'white',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
  'p[data-cy="betOdds"]': {
    color: 'alpha.400',
  },
};
export const dividerExoticProps: DividerProps = {
  display: 'none',
};
