import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TExoticState } from '../views/races/bets/Exotics/Exotics.types';

const initialState: TExoticState = {
  selected: { 1: [], 2: [], 3: [], 4: [] },
  isBoxed: false,
  validSelectionAmount: 0,
  totalStake: undefined,
  flexi: undefined,
};

const exotics = createSlice({
  name: 'exotics',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<TExoticState['selected']>) => ({
      ...state,
      selected: action.payload,
    }),
    setIsBoxed: (state, action: PayloadAction<TExoticState['isBoxed']>) => ({
      ...state,
      isBoxed: action.payload,
    }),
    setValidSelectionAmount: (
      state,
      action: PayloadAction<TExoticState['validSelectionAmount']>
    ) => ({
      ...state,
      validSelectionAmount: action.payload,
    }),
    setTotalStake: (
      state,
      action: PayloadAction<TExoticState['totalStake']>
    ) => ({
      ...state,
      totalStake: action.payload,
    }),
    setFlexi: (state, action: PayloadAction<TExoticState['flexi']>) => ({
      ...state,
      flexi: action.payload,
    }),
    clearExoticsStore: () => initialState,
  },
});

// Slice actions
export const {
  setSelected,
  setIsBoxed,
  setValidSelectionAmount,
  setTotalStake,
  setFlexi,
  clearExoticsStore,
} = exotics.actions;

// Slide reducer
export default exotics.reducer;
