import { CSSObject } from '@chakra-ui/react';
import mascot from '@/assets/vikingbet/images/header/helm.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonBallance: CSSObject = {
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'normal',
  fontSize: 'sm',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
  border: 'none',
  maxH: '29px',
  '> button': {
    top: '-7px',
    '> span': {
      fontWeight: 'semibold',
      fontSize: 'sm',
    },
    '> .chakra-button__icon': {
      top: '-1px',
      position: 'relative',
      svg: {
        w: '15px',
        h: '15px',
      },
    },
  },
};

export const BoxBallance: CSSObject = {
  bg: 'alpha.500',
  padding: '2',
  borderBottomRightRadius: 'md',
  borderBottomLeftRadius: 'md',
  '> div:first-of-type': {
    color: 'beta.500',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
  '> div:last-of-type': {
    color: 'zeta.300',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
};

export const FlexBallanceItem: CSSObject = {
  p: {
    fontSize: 'sm',
    fontWeight: 'extrabold',
  },
};

export const PopoverBodyQA: CSSObject = {
  bg: 'epsilon.400',
};

export const ButtonDeposit: CSSObject = {
  fontFamily: 'SkranjiBold',
  borderRadius: 'lg',
  border: '2px',
  borderColor: '#CD8716',
  bgGradient: 'linear(to-b, #FCD236, #E79004)',
  boxShadow: 'inset 0px 0px 0px 1px #FAD461',
  color: 'rgba(0, 0, 0, 0.64)',
  fontWeight: 'semibold',
  fontSize: 'md',
  minH: '44px',
  textTransform: 'uppercase',
  '.chakra-button__icon': { mt: '-1' },

  _hover: {
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: '#775826',
  },
  _active: {
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: '#775826',
  },
};

export const ButtonNavItem = (): CSSObject => ({
  color: 'alpha.600',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderTop: '1.5px',
  borderTopColor: 'gray.200',
  bg: 'white',
  maxH: '41px',
  '.chakra-icon': {
    w: '5',
    h: '5',
  },

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'epsilon.100',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImg: `url(${mascot})`,
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  top: '0.5',
  display: ['none', null, null, null, 'block'],
  pos: 'absolute',
  h: '57px',
  w: '105px',
  left: '-10px',
  'body[data-sensitive="true"] &': {
    display: 'none',
  },
};

export const PopoverContentQA: CSSObject = {
  border: '3px solid',
  h: ['100%', null],
  borderRadius: 'lg',
  borderColor: 'white',
  top: '-7px',
  px: '0',
  pt: '0',
  w: ['96vw', '272px'],
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  w: 'full',
  mt: '2',
  h: '44px',
  sx: {
    '&&': {
      _hover: {
        bgGradient: 'linear(to-b, alpha.800, alpha.900)',
        boxShadow:
          '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #0C2549 inset, 0px 0px 0px 5px #4d98c5 inset',
      },
    },
    '& svg': {
      display: 'none',
    },
  },
};
