import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logoTop from '../../../assets/betroyale/images/logos/logoTop.png';
import logoCenter from '../../../assets/betroyale/images/logos/logoCenter.png';
import Heading from '../components/Heading';
import Body from '../components/Body';

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: (
      <Flex flexDir="column" alignItems="center">
        <Img src={logoTop} />
        <Img src={logoCenter} w="51px" h="91px" mt={['40', null, '40']} />
      </Flex>
    ),
    heading: <Heading />,
    desc: <Body />,
  },
};

export default template;
