import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  mb: '2',
  p: 0,
  border: 0,
  color: 'gamma.600',
  fontWeight: 'medium',
};

export const FlexHeadingMiscWrapper: CSSObject = {
  '&&&&': {
    fontSize: 'xs',

    '.timestamp': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  },
};

export const TextSportName: CSSObject = {
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

export const BoxDot: CSSObject = {
  border: 'none',
};
