import React from 'react';
import { Image, Link } from '@chakra-ui/react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/bet777/images/backgrounds/onboardingLogo.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: (
      <Link
        aria-label="aside logo Mobile"
        as={LinkDom}
        to="/"
        maxW={['58vw', '55vw']}
        alignSelf="center"
        mb={['0', null, 'auto']}
        mt={['32', '48', '40']}
      >
        <Image src={logo} maxW={[0, null, '300px']} marginTop={7} />
      </Link>
    ),
    config: {
      before: 'slogan',
    },
  },
]);
