import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mt: '1',
    mb: [0, null, '3'],
    rowGap: ['12px', null, 'unset'],
    columnGap: '3',
    order: 3,
  },

  FlexCard: {
    borderRadius: 'lg',
    minH: '334px',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    boxShadow:
      '0px 6px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
  },

  BoxGridHeaderItem: {
    bg: 'blackAlpha.300',
    color: 'white',
    px: '3',
    textTransform: 'uppercase',
    boxShadow: 'inset 0 7px 8px -10px #000, inset 0 -7px 8px -10px #000',
  },

  FlexGridRunnerItem: {
    px: '2.5',
    py: '7',
    ml: '1',
    mx: '0',
    my: '0',
    mt: '0',
    height: '2.125rem',
    color: 'white',
    borderBottom:
      '1px dashed var(--chakra-black-alpha-300, rgba(0, 0, 0, 0.16))',
  },

  GridCardContent: {
    p: '0',
    gridTemplateColumns: '1fr 0.25fr',
  },

  LinkRaceDetails: {
    mt: 'auto',
    textTransform: 'uppercase',
    color: 'alpha.800',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    fontFamily: 'Fredoka',
    fontWeight: 'bold',
    borderBottomRadius: 'lg',
  },

  LinkCardHeader: {
    px: '4',
    color: 'white',
  },

  TextCardHeading: {
    color: 'white',
    ml: '0',
  },

  TextRaceNumber: {
    bg: 'beta.800',
    borderRadius: 'base',
    color: 'white',
    h: '4',
    lineHeight: '120%',
  },

  TextRunner: {
    color: 'white',
    fontFamily: 'roboto',
    fontWeight: 'semibold',
    fontSize: '0.75rem',
  },

  TextRunnerNumber: {
    fontWeight: 'bold',
  },

  buttonRaceCardOddsProps: {
    w: '100%',
    maxW: '52px',
    h: '34px',
    fontSize: '15px',
    fontFamily: 'Fredoka',
    variant: 'solid',
  },
};
