/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useFormikContext } from 'formik';
import {
  BoxInputContainer,
  depositInputProps,
  FormBox,
  IncreaseAmountContainer,
} from './styles/Form/Form.styles';
import { getStrings } from '@/helpers/utils';
import IncreaseAmountButton from './IncreaseAmountButton/IncreaseAmountButton';
import Input from '@/components/FormElements/Input';
import { TResponseCard } from '@/api/deposit/deposit.types';
import SubmitBtn from './SubmitBtn/SubmitBtn';
import PromoBanner from './PromoBanner/PromoBanner';
import { useFeatureFlag } from '@/store/FeatureFlagStore';

export type TFormikValues = {
  card: TResponseCard;
  amount?: number | string;
  opted_in_promo?: string;
};

export default function Form() {
  const {
    Deposit: { Labels },
  } = getStrings()[0].Account;

  const { setFieldValue, setTouched, touched, errors, values, isSubmitting } =
    useFormikContext<{
      amount: number;
    }>();

  const hasNewDeposit = useFeatureFlag('PRIMER_ENABLED');

  return (
    <FormBox>
      <PromoBanner />

      <BoxInputContainer>
        <Input
          name="amount"
          data-cy="depositAmountInput"
          InputLeftAddon="$"
          label={Labels.Amount}
          placeholder={Labels.minimumDepositAmount}
          type="number"
          inputMode="decimal"
          size="md"
          isDisabled={isSubmitting}
          onFocus={() => setTouched({ amount: true })}
          error={
            !!touched.amount && !!errors.amount ? errors.amount : undefined
          }
          {...depositInputProps}
        />
      </BoxInputContainer>

      <IncreaseAmountContainer>
        <IncreaseAmountButton
          onChange={(value: number) => {
            setFieldValue('amount', value);
            setTimeout(() => {
              setTouched({ amount: true }, true);
            }, 100);
          }}
          currentInputValue={values.amount || 0}
          isDisabled={isSubmitting}
        />
      </IncreaseAmountContainer>

      <SubmitBtn
        isLoading={isSubmitting}
        isDisabled={!touched.amount || !!errors.amount}
        txt={(() => {
          if (hasNewDeposit) return 'Select card';
          return undefined;
        })()}
      />
    </FormBox>
  );
}
