import { HomeLinksSchema } from './HomeLinks.styles';
import HomeLinkBackground from '@/assets/slambet/images/backgrounds/HomeLinkBackground.png';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: ['center', null, null, 'flex-start'],
    my: '3',
    mx: 'auto',
    px: ['1.5', null, null, '1'],
    w: '100%',
    maxW: ['100dvw', null, '100%'],
    overflowX: 'auto',
    overflowY: 'hidden',
    gap: 1,
  },
  container: {
    display: 'contents',
  },
  racingBtnsContainer: {
    display: 'contents',
  },
  sportBtnsContainer: {
    display: 'contents',
  },
  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'alpha.900',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxSize: '4',
    mr: '0',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'alpha.900',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxSize: '4',
    mr: '0',
  },
  linkIconBox: {
    flex: '1 0 0',
    gap: '1',
    h: '50px',
    display: {
      base: 'none',
      xl: 'flex',
    },
    _last: { display: 'flex' },
    overflow: 'hidden',
    borderRadius: 'md',
    boxShadow:
      '0px 4px 4px 0px rgba(255, 255, 255, 0.3) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.4) inset',
    bg: `url(${HomeLinkBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25))',
    sx: {
      '&:nth-child(1), &:nth-child(2)': {
        display: { sm: 'flex' },
      },

      '&:nth-child(3), &:nth-child(4)': {
        display: { md: 'flex' },
      },
    },
  },
  linkBox: {
    flex: '1 0 0',
    gap: '1',
    h: '50px',
    overflow: 'hidden',
    borderRadius: 'md',
    boxShadow:
      '0px 4px 4px 0px rgba(255, 255, 255, 0.3) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.4) inset',

    bg: `url(${HomeLinkBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25))',
  },
  linkOverlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    w: '100%',
    gap: '2',
    _hover: {
      bg: 'alpha.500',
      boxShadow:
        '0px 4px 6px 0px rgba(0, 0, 0, 0.25), 0px 2px 2px 0px rgba(0, 0, 0, 0.4) inset, 0px -1px 1px 0px rgba(255, 255, 255, 0.5) inset',
      color: 'alpha.900',
      textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.3)',
    },
  },
  linkBoxText: {
    color: 'alpha.900',
    fontFamily: 'Staatliches',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '10px',
  },
  racingHeader: {
    display: 'none',
  },
  sportsHeader: {
    display: 'none',
  },
  sportsHeaderTile: {
    color: 'alpha.900',
    fontFamily: 'Staatliches',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'center',
    w: ['135%', null, '105%', null, null],
    lineHeight: '10px',
  },
  linkOverlayOverride: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    w: '100%',
  },
  sportIconOverride: {
    color: 'alpha.900',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    textAlign: 'center',
    w: '56px',
  },
};
