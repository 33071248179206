import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  bg: 'linear-gradient(180deg, #F8F8F8 0%, #FFFFFF 100%)',
  borderRadius: 'md',
  contain: 'paint',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  mb: '3',
  border: '0',

  ...(isNested && {
    boxShadow: 'unset',
    mb: '2',
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  border: 0,
  p: '2',
  borderBottomRadius: 'md',

  ...(isNested && {
    border: '1px',
    borderTop: 0,
    borderColor: 'blackAlpha.200',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  borderRadius: 'md',
  fontWeight: 'semibold',
  fontSize: 'sm',
  p: '3',
  border: '1px',
  borderColor: 'transparent',

  _hover: {
    bg: 'gray.50',
  },

  ...(isNested && {
    '&&&': {
      border: '1px',
      borderColor: 'blackAlpha.200',

      _expanded: {
        borderBottomRadius: 0,
        borderBottom: 0,
      },
    },
  }),

  _expanded: {
    borderBottomRadius: 'none',
    bg: 'beta.500',
    color: 'white',
    borderColor: 'transparent',

    _hover: {
      bg: 'beta.400',
    },

    ...(isNested && {
      bg: 'betaAlpha.800',

      _hover: {
        bg: 'betaAlpha.700',
      },
    }),
  },
});
