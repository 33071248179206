import React from 'react';
import { WrapperFlexOnboarding } from '../styles/onboarding.styles';
import TemplateForgotPassword from './templates/template';
import { createTemplate } from '@/lib/templateSwitch';

export default function ForgotPasswordNew() {
  const template = createTemplate(TemplateForgotPassword);

  return <WrapperFlexOnboarding>{template}</WrapperFlexOnboarding>;
}
