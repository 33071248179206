import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (): CSSObject => ({
  transition: 'all .2s ease-in-out',
  borderTopLeftRadius: 'base',
  borderBottomLeftRadius: 'base',
  _groupFocusWithin: {
    bg: 'alpha.300',
  },
  bg: 'alpha.50',
  color: 'alpha.800',
  fontSize: 'sm',
  fontWeight: 'black',
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  boxShadow: '0px 1px 0px #4395D0, inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'uppercase',
};

export const EachWayText: CSSObject = {
  color: 'black',
  fontWeight: 'medium',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'alpha.800',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'gamma.500',
  fontWeight: 'black',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewEst: CSSObject = {
  fontSize: '22px',
  color: 'gamma.500',
  fontWeight: 'black',
};

export const InputBetSlip: CSSObject = {
  bg: 'alpha.800',
  marginTop: '-2px',
  borderColor: 'alpha.50',
  pl: '10',
  color: 'white',
  borderRadius: 'sm',
  _hover: {
    '&&': {
      background: 'alpha.600',
    },
  },
  _focus: {
    borderColor: 'alpha.300',
    '&&': {
      background: 'alpha.900',
    },
  },
  '&::placeholder': { color: 'white', opacity: '0.3' },
};

export const TreasureChestIcon: CSSObject = {
  color: 'gamma.500',
};
