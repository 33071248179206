/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect } from 'react';
import { extendTheme } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from './useRedux';
import { setTheme } from '../redux/theme.slice';
import { EThemesOG } from '@/constants/themeConfig';
import { themeToEnum } from '@/helpers/getThemeConfig';
import { setThemeAlpha } from '@/helpers/setThemeAlpha';
import baseTheme from '../theme/base';
import { TCustomTheme } from '../theme/theme.types';
import imports from '../theme/theme.imports';

const { REACT_APP_IS_PROD } = window.BETCLOUD_ENV;

/**
 * Hook that allows us to toggle the theme if
 * we're not in production
 */
export const useThemeToggle = () => {
  const { themeName } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const storageThemeName = localStorage.getItem('themeName') as
    | EThemesOG
    | undefined;

  /**
   * If we're not in production and local storage
   * contains a theme, set that theme.
   */
  useEffect(() => {
    if (REACT_APP_IS_PROD !== 'true' && storageThemeName) {
      dispatch(setTheme(storageThemeName));
    }
  }, [dispatch, storageThemeName]);

  /**
   * Loading of the theme
   */
  const theme = themeName === EThemesOG.Base ? [] : imports.default;
  const extendedTheme = extendTheme(...baseTheme, ...theme);

  return {
    ...extendedTheme,
    colors: {
      ...extendedTheme.colors,
      ...setThemeAlpha(extendedTheme.colors),
    },
    themeName: themeToEnum(),
  } as unknown as TCustomTheme;
};
