import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';
import { usePunterData } from '@/store/AuthStore';

export const useOnboardedUser = () => {
  const punterProfile = usePunterData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!punterProfile?.is_onboarded && !IS_MOBILE_APP) {
      navigate('/deposit-limit', { replace: true });
    }
  }, [navigate, punterProfile?.is_onboarded]);

  return { isOnboarded: punterProfile?.is_onboarded };
};
