import api from '../../api';
import {
  TQueryPromotionsParams,
  TQueryPromotionsResponse,
} from './deposit.types';

export const promotionsEndpoint = '/punter/promotions';

export async function queryPromotions(params: TQueryPromotionsParams) {
  const res = await api.get<TQueryPromotionsResponse>(promotionsEndpoint, {
    params,
  });
  return res;
}
