import { CSSObject } from '@chakra-ui/react';
import { rgba } from 'polished';
import loader from '../../../../assets/betroyale/images/loader.png';

export const FlexWrapper = (theme: any): CSSObject => ({
  bg: rgba(theme.colors.gamma[800] as string, 0.7),
});

export const SpinnerLoader: CSSObject = {
  bgImage: `url(${loader})`,
};
