import { css } from 'styled-components';

export const backIcon = css`
  width: var(--bc-sizes-5);
  height: var(--bc-sizes-5);
  margin-right: var(--bc-sizes-4);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const competitionsBySportHeadingContainer = css`
  margin-top: var(--bc-sizes-6);
`;

export const competitionsBySportContainer = css``;

export const competitionsBySportHeading = css`
  margin-bottom: var(--bc-sizes-6);
`;

export const competitionListHeadingContainer = css`
  background: ${({ theme }) => theme.palette.secondary};
  padding: var(--bc-sizes-0-5);
`;

export const competitionListHeading = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-md);
  text-transform: uppercase;
  border-top: 1px solid ${({ theme }) => theme.palette.transparentBlue};
  width: 100%;
  text-align: center;
  padding: var(--bc-sizes-2);
`;

export const competitionFeaturedListItem = css`
  font-size: var(--bc-fontSizes-md);
  font-style: italic;
  color: white;
  border-bottom: 1px solid ${({ theme }) => theme.palette.transparentBlue};
  background-color: ${({ theme }) => theme.palette.transparentDarkBlue};
  padding: var(--bc-sizes-3) var(--bc-sizes-6) var(--bc-sizes-3)
    var(--bc-sizes-9);
`;

export const competitionListItemIcon = css`
  margin-right: var(--bc-sizes-2);
  height: var(--bc-sizes-6);
  width: var(--bc-sizes-6);

  path {
    fill: ${({ theme }) => theme.basePalette.brightBlue};
  }
`;

export const sportName = css`
  font-size: var(--bc-fontSizes-3xl);
  font-style: italic;
  font-weight: 700;
  color: ${({ theme }) => theme.basePalette.blue};
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const competitionListItemName = css``;
