import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    mx: ['2', null, 'unset'],
    borderRadius: '0',
  },
  boxHeading: {
    borderTopRadius: 'base',
    mb: '0',
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: ['2', null, '1'],
      },
    },
    pt: '1',
  },
  textTitle: {
    mt: '0',
    mb: '2',
    color: 'gamma.500',
    fontSize: 'md',
    fontWeight: 'medium',
    textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
    textTransform: 'capitalize',
    fontFamily: 'Angkor',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        mb: '5',
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        py: '0',
        borderRadius: 'md',
        bgGradient: 'linear(to-b, alpha.500, alpha.600)',
        border: '3px solid #E57D24',
        color: 'gamma.500',
        fontSize: 'lg',
        fontWeight: 'normal',
        flexDir: 'column',
      },
    },
  },
  titleContainer: {
    pl: '0.5',
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
