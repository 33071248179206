import { BoxProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './DuotoneSgmLogo.styles.imports';

const themeName = getThemeName();

export type DuotoneSgmLogoSchema = Partial<{
  boxWrapper: BoxProps;
  sgmLogo: any; // TODO: type props properly
}>;

export const duotoneSgmLogoStyles: DuotoneSgmLogoSchema = {
  sgmLogo: {
    'data-testid': 'duotoneSgmLogo-sgmLogo',

    ...(() => {
      try {
        return styleImports[themeName]?.duotoneSgmLogoStyles?.sgmLogo;
      } catch (err) {
        return {};
      }
    })(),
  },
  boxWrapper: {
    'data-testid': 'duotoneSgmLogo-boxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.duotoneSgmLogoStyles?.boxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
