import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
    gap: '3',
  },
  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    border: '3px solid',
    borderColor: 'epsilon.200',
    borderRadius: 'md',
    boxShadow: '0px 4px 0px #6E7373, inset 0px 0px 10px rgba(0, 0, 0, 0.6)',
    bg: 'epsilon.400',
  },
  LinkCardHeader: {
    bg: 'epsilon.400',
    color: 'white',
    _hover: {
      bg: 'epsilon.500',
    },
  },
  TextCardHeading: {
    fontWeight: 'normal',
  },
  TextRaceNumber: {
    bg: 'epsilon.700',
    borderRadius: 'base',
    color: 'beta.500',
  },
  GridCardContent: {
    bg: 'epsilon.200',
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },
  FlexGridRunnerItem: {
    bg: 'epsilon.400',
    borderBottom: '1px dashed',
    borderBottomColor: 'delta.400',
    _last: {
      border: 'none',
    },
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'semibold',
  },
  LinkRaceDetails: {
    color: 'beta.500',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    bg: 'epsilon.500',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15);',
    textTransform: 'uppercase',
    fontFamily: 'Caveman',
    fontWeight: 'normal',
    _hover: {
      color: 'beta.100',
      bg: 'epsilon.600',
    },
  },
  BoxGridHeaderItem: {
    bg: 'epsilon.200',
    color: 'epsilon.600',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
  },
  buttonRaceCardOddsProps: {
    variant: 'solid',
    w: '14',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};
