import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'delta.600';
      }
      if (state === 'ended') {
        return 'delta.900';
      }
      // 'prior'
      return 'alpha.300';
    },
    color: () => {
      if (state === 'inprogress') {
        return 'gamma.700';
      }
      if (state === 'ended') {
        return 'white';
      }
      // 'prior'
      return 'alpha.700';
    },
    borderRadius: 'base',
    px: '1',
    fontFamily: 'roboto',
  },
  '&&': {
    h: 4,
    maxH: 4,
    minH: 4,
    lineHeight: 4,
  },
});

export const TextTimestamp: CSSObject = {
  fontSize: '2xs',
};
