import * as sterlingparker from './EvenShot.styles.sterlingparker';
import * as vikingbet from './EvenShot.styles.vikingbet';
import * as fiestabet from './EvenShot.styles.fiestabet';
import * as puntcity from './EvenShot.styles.puntcity';
import * as junglebet from './EvenShot.styles.junglebet';
import * as betroyale from './EvenShot.styles.betroyale';
import * as juicybet from './EvenShot.styles.juicybet';
import * as goldenrush from './EvenShot.styles.goldenrush';
import * as questbet from './EvenShot.styles.questbet';
import * as volcanobet from './EvenShot.styles.volcanobet';
import * as puntgenie from './EvenShot.styles.puntgenie';
import * as betprofessor from './EvenShot.styles.betprofessor';
import * as betgalaxy from './EvenShot.styles.betgalaxy';
import * as wellbet from './EvenShot.styles.wellbet';
import * as sugarcastle from './EvenShot.styles.sugarcastle';
import * as oldgill from './EvenShot.styles.oldgill';
import * as buffalobet from './EvenShot.styles.buffalobet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  sterlingparker,
  vikingbet,
  fiestabet,
  puntcity,
  junglebet,
  betroyale,
  juicybet,
  goldenrush,
  questbet,
  volcanobet,
  puntgenie,
  betprofessor,
  betgalaxy,
  wellbet,
  sugarcastle,
  oldgill,
  buffalobet,
};
