import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TData } from './promotions.types';
import { queryPromotions } from './promotions';
import { keys } from '../api.keys';

type TQueryPromotionHook = {
  key?: string[];
  options?: UseQueryOptions<TData[], string[]>;
};

export const useQueryPromotions = (hook: TQueryPromotionHook = {}) => {
  const { key = [], options = {} } = hook;

  const query = useQuery<TData[], string[]>(
    [keys.banners, ...key],
    () => queryPromotions(),
    {
      ...options,
    }
  );

  return query;
};
