export const Checkbox = {
  parts: ['container', 'icon', 'control', 'label'],
  baseStyle: {
    control: {
      alignSelf: 'self-start',
      border: 'epsilon.400',
      borderWidth: '1px',
      bg: 'white',
      _disabled: {
        borderColor: 'blackAlpha.200',
        bg: 'transparent',
      },
      _hover: {
        bg: 'rgba(255,255,255, 0.8)',
        border: '1px solid var(--bc-colors-whiteAlpha-900)',
      },
      _checked: {
        _disabled: {
          borderColor: 'blackAlpha.200',
          bg: 'transparent',
        },
        _hover: {
          bg: 'rgba(255,255,255, 0.8)',
          border: 'epsilon.400',
          borderWidth: '1px',
        },
        color: 'gray.700',
        bg: 'white',
        border: 'epsilon.400',
        borderWidth: '1px',
      },
    },
    label: {
      color: 'white',
    },
    container: {
      w: 'full',
      alignItems: 'flex-start',
    },
  },
  sizes: {
    lg: {
      label: { fontSize: 'sm' },
      icon: { fontSize: 'xs' },
    },
    md: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    sm: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    xs: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '3xs' },
    },
  },
  defaultProps: {
    size: 'md',
    width: '100%',
  },
};
