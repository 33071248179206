import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import {
  BoxHeadingWrapper,
  FlexWrapper,
  textSloganProps,
  HeadingSlogan,
} from '../styles/Featured.styles';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  slogan: {
    wrapper: <BoxHeadingWrapper />,
    body: (
      <BoxHeadingWrapper>
        <HeadingSlogan>
          <FormattedMessage id="main.featured.header" />
        </HeadingSlogan>
        <Text {...textSloganProps}>
          <FormattedMessage id="main.featured.subHeader" />
        </Text>
      </BoxHeadingWrapper>
    ),
    feature: <FeaturedMatch />,
  },
};

export default template;
