import { CSSObject } from '@chakra-ui/react';

export const TextTitle: CSSObject = {
  color: 'gamma.600',
  fontWeight: 'medium',
  fontSize: 'sm',
};

export const SubHeading: CSSObject = {
  fontWeight: 'bold',
  color: 'gamma.600',
  fontSize: 'sm',
};

export const BoxBordered: CSSObject = {
  color: 'black',
  maxW: 'container.md',
  pt: '0',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  color: 'gamma.600',
  fontWeight: 'md',
};

export const SmallHeading: CSSObject = {
  fontWeight: 'bold',
  mt: '2',
  color: 'gamma.600',
  fontSize: 'sm',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  color: 'gamma.600',
  fontWeight: 'medium',
};

export const ImageGamblersHelp: CSSObject = {
  w: ['auto', '168px'],
  minW: '168px',
  bg: 'white',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  px: ['10', '2'],
  py: ['2', '1'],
  border: '0',
};

export const ButtonAction: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'md',
  border: '2px',
  borderColor: 'alpha.700',
  // TODO: #426DD6 is not in the theme
  boxShadow: 'inset 0px 0px 0px 2px #426DD6',

  _hover: {
    bg: 'alpha.500',
  },

  _focus: {
    bg: 'alpha.500',
  },

  _active: {
    bg: 'gamma.600',
  },
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr',
  maxW: 'container.sm',
};

export const Link: CSSObject = {
  color: 'gamma.600',
  fontSize: 'xs',
};
