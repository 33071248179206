import { configureStore } from '@reduxjs/toolkit';

import punterReducer from '../views/account/services/account.slices';
import sportsReducer from '../views/sports/services/sports.slices';
import betSlipReducer from './Betslip.slices';
import generalReducer from '../views/HomeDeprecated/services/Home.slices';
import racingReducer from '../views/races/services/races.slices';
import exoticsReducer from './Exotics.slices';
import themeSlice from './theme.slice';
import sidenav from './sideNav.slice';
import ntjFilters from './ntjFilters.slice';
import raceTable from './raceTable.slice';

export const reducer = {
  punter: punterReducer,
  sports: sportsReducer,
  betSlip: betSlipReducer,
  general: generalReducer,
  racing: racingReducer,
  exotics: exoticsReducer,
  theme: themeSlice,
  sidenav,
  ntjFilters,
  raceTable,
};

const store = configureStore({ reducer });

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
