import { CSSObject } from '@emotion/react';

export const AutoCompleteResult: CSSObject = {
  _hover: {
    bg: 'beta.500',
    color: 'black',
  },
};

export const AutoCompleteContainer: CSSObject = {};
