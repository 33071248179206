import { v4 as uuid } from 'uuid';
import {
  EBetSlipBetSubmissionType,
  EBetSlipViewMode,
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
  EChannel,
  EEventRule,
  EOddsChangeDirection,
  TBetSlipBet,
  TBetSlipBetAPIInput,
  TBetSlipBetEachWay,
  TBetSlipBetExotics,
  TBetSlipBetMulti,
  TBetSlipBetSGMulti,
  TBetSlipBetSingle,
  TBetSlipBetSRMulti,
  TBetSlipBetSubmissionExoticsType,
  TBetSlipMultiLeg,
  TBlenededBetMulti,
  TPromoToken,
  TPromoTokenType,
} from './Betslip.types';
import { dollarsToCents } from '@/helpers/utils';
import { checkMultiValidity } from '../components/MultiCard/Services/MultiBet.utils';
import { EBetRequestStatus } from '../../../api/punter/punter.types';
import { TMoMSchema } from '@/store/BetSlipStore';
import { isToteMulti } from '@/views/races/bets/Exotics/services/Exotics.utils';

export const validateBet = (bet: TBetSlipBet) => {
  // We can validate each bet here and add any errors we need.
  // This provides scope to validate multibet`s and other types in the future.
  const errors = [];

  // Check the state is set else push an error
  if (Number(bet.stake) < 0) errors.push('stake');

  // For single bets - calc the return potential
  if (bet.type === EBetSlipBetSubmissionType.Single) {
    const { odds, rollovers } = bet as TBetSlipBetSingle;

    const isMysteryBet =
      bet.price_type === 'mystery_bet' && !!rollovers?.length;

    let returnAmount = bet.is_bonus_bet
      ? (odds - 1) * Number(bet.stake)
      : odds * Number(bet.stake);

    if (isMysteryBet)
      returnAmount =
        odds * (bet.is_bonus_bet ? odds - 1 : odds) * Number(bet.stake);

    // eslint-disable-next-line no-param-reassign
    bet.potential_returns = Number(bet.stake) > 0 ? returnAmount : 0;
  }

  // For EW bets - calc the return potential
  if (bet.type === EBetSlipBetSubmissionType.EachWay) {
    const eachWayBet = bet as TBetSlipBetEachWay;

    const returnAmountWin = bet.is_bonus_bet
      ? (eachWayBet.win_odds - 1) * Number(bet.stake)
      : eachWayBet.win_odds * Number(bet.stake);

    const returnAmountPlace = bet.is_bonus_bet
      ? (eachWayBet.place_odds - 1) * Number(bet.stake)
      : eachWayBet.place_odds * Number(bet.stake);

    // eslint-disable-next-line no-param-reassign
    bet.potential_returns =
      Number(bet.stake) > 0 ? returnAmountWin + returnAmountPlace : 0;
  }

  if (bet.type === EBetSlipBetSubmissionType.Multi) {
    const multiBet = bet as TBetSlipBetMulti;

    const multiOdds =
      multiBet.legs.reduce(
        (acc, curr) => (!curr.eventClosed ? acc * curr.odds : acc),
        1
      ) || 0;
    const multiReturnAmount = bet.is_bonus_bet
      ? (multiOdds - 1) * Number(bet.stake)
      : multiOdds * Number(bet.stake);

    if (!checkMultiValidity(multiBet)) {
      // eslint-disable-next-line no-param-reassign
      bet.stake = '';
    }
    // eslint-disable-next-line no-param-reassign
    bet.potential_returns = Number(bet.stake) > 0 ? multiReturnAmount : 0;
  }

  // For SGMs - calc the return potential
  if (
    bet.type === EBetSlipBetSubmissionType.SGMulti ||
    bet.type === EBetSlipBetSubmissionType.SRMulti ||
    bet.type === EBetSlipBetSubmissionType.Blended
  ) {
    const { odds } = bet as TBetSlipBetSGMulti;

    if (odds !== undefined) {
      const returnAmount = bet.is_bonus_bet
        ? (odds - 1) * Number(bet.stake)
        : odds * Number(bet.stake);

      // eslint-disable-next-line no-param-reassign
      bet.potential_returns = Number(bet.stake) > 0 ? returnAmount : 0;
    }
  }

  return { ...bet, errors };
};

/*
  This method validates all the best and adds any errors needed to each bet.
  We also calculate the returnPotential for single bets
 */
export const validateBets = (bets: TBetSlipBet[]) =>
  bets?.map((bet) => validateBet(bet));

/* Takes a list of bets and transforms the type to the API input */
export const serializeBetsForAPI = (
  bets: TBetSlipBet[]
): (TBetSlipBetAPIInput | null)[] =>
  bets?.map((bet) => {
    // Pass
    if (bet.type === EBetSlipBetSubmissionType.Single) {
      const singleBet = bet as TBetSlipBetSingle;

      const isMysteryBet = singleBet.price_type === 'mystery_bet';

      const apiInput: TBetSlipBetAPIInput = {
        request_id: bet.request_id,
        proposition_id: singleBet.proposition_id,
        odds: singleBet.odds || undefined,
        stake: dollarsToCents(singleBet.stake),
        is_bonus_bet: singleBet.is_bonus_bet,
        type: singleBet.type,
        channel: singleBet.channel || EChannel.WEB,
        price_type: singleBet.price_type,
        race_id: isMysteryBet ? singleBet.race_id ?? '' : undefined,
        rollovers:
          isMysteryBet && !!singleBet.rollovers?.length
            ? singleBet.rollovers?.map((r) => ({ race_id: r.race_id }))
            : undefined,
      };

      return apiInput;
    }

    // Pass
    if (bet.type === EBetSlipBetSubmissionType.Exotics) {
      const exoticBet = bet as TBetSlipBetExotics;

      const isToteMultiBet = isToteMulti(exoticBet.sub_type);

      const apiInput: TBetSlipBetAPIInput = {
        request_id: bet.request_id,
        proposition_id: exoticBet.proposition_id,
        selection: exoticBet.selection,
        sub_type: exoticBet.sub_type,
        stake: dollarsToCents(exoticBet.stake),
        is_bonus_bet: exoticBet.is_bonus_bet,
        type: isToteMultiBet ? 'ToteMulti' : exoticBet.type,
        channel: exoticBet.channel || EChannel.WEB,
      };

      return apiInput;
    }

    // NA
    if (bet.type === EBetSlipBetSubmissionType.EachWay) {
      const eachWayBet = bet as TBetSlipBetEachWay;

      const apiInput: TBetSlipBetAPIInput = {
        request_id: bet.request_id,
        win_proposition_id: eachWayBet.win_proposition_id,
        place_proposition_id: eachWayBet.place_proposition_id,
        runner_id: eachWayBet.runner_id,
        win_odds: eachWayBet.win_odds,
        place_odds: eachWayBet.place_odds,
        field_size: eachWayBet.field_size,
        stake: dollarsToCents(eachWayBet.stake),
        is_bonus_bet: eachWayBet.is_bonus_bet,
        type: eachWayBet.type,
        channel: eachWayBet.channel || EChannel.WEB,
      };

      return apiInput;
    }

    // TODO:
    if (bet.type === EBetSlipBetSubmissionType.Multi) {
      const multiBet = bet as TBetSlipBetMulti;
      const multiLegs: TBetSlipMultiLeg[] = multiBet.legs
        .filter((l) => !l.eventClosed)
        .map((leg) => ({
          proposition_id: leg.proposition_id ?? '',
          odds: leg.odds,
        }));

      const apiInput: TBetSlipBetAPIInput = {
        request_id: bet.request_id,
        legs: multiLegs,
        stake: dollarsToCents(bet.stake),
        is_bonus_bet: bet.is_bonus_bet,
        type: EBetSlipBetSubmissionType.Multi,
        channel: bet.channel || EChannel.WEB,
      };

      return apiInput;
    }

    // Pass
    if (bet.type === EBetSlipBetSubmissionType.SRMulti) {
      const srmBet = bet as TBetSlipBetSRMulti;

      const apiInput: TBetSlipBetAPIInput = {
        request_id: bet.request_id,
        race_id: srmBet.race_id,
        selection: srmBet.selection,
        odds: srmBet.odds,
        stake: dollarsToCents(srmBet.stake),
        is_bonus_bet: srmBet.is_bonus_bet,
        type: srmBet.type,
        channel: srmBet.channel || EChannel.WEB,
      };

      return apiInput;
    }

    // Pass
    if (bet.type === EBetSlipBetSubmissionType.SGMulti) {
      const sgmBet = bet as TBetSlipBetSGMulti;

      const apiInput: TBetSlipBetAPIInput = {
        request_id: bet.request_id,
        stake: dollarsToCents(sgmBet.stake),
        is_bonus_bet: sgmBet.is_bonus_bet,
        channel: sgmBet.channel || EChannel.WEB,
        odds: sgmBet.odds,
        legs: sgmBet.selections
          .filter(
            (selection): selection is { proposition_id: string } =>
              !!selection.proposition_id
          )
          .map(({ proposition_id }) => ({ proposition_id, odds: 0 })),
        type: sgmBet.type,
      };

      return apiInput;
    }

    // Pass
    if (bet.type === EBetSlipBetSubmissionType.Blended) {
      const blendedBet = bet as TBlenededBetMulti;

      const apiInput: TBetSlipBetAPIInput = {
        request_id: bet.request_id,
        stake: dollarsToCents(blendedBet.stake),
        is_bonus_bet: blendedBet.is_bonus_bet,
        channel: blendedBet.channel || EChannel.WEB,
        odds: blendedBet.odds,
        legs: blendedBet.selection.map(({ propositionId }) => ({
          proposition_id: propositionId,
        })),
        type: blendedBet.type,
      };

      return apiInput;
    }

    return null;
  });

/* Calculates the flexi % for exotics */
export const calculateExoticFlexi = (
  stake: number,
  totalCombos: number
): number => +((stake / totalCombos) * 100).toFixed(2);

/* Formats a place number into the correct string representation */
export const getPlaceFromNumber = (place: number): string => {
  if (place === 1) return `1st`;
  if (place === 2) return `2nd`;
  if (place === 3) return `3rd`;
  return `${place}th`;
};

/* Returns the total combos for an exotic */
export const calculateExoticCombinations = (
  combos: number[][],
  exoticType: TBetSlipBetSubmissionExoticsType
): number[][] => {
  const relevantEntries = () => {
    switch (exoticType) {
      case TBetSlipBetSubmissionExoticsType.Exacta:
      case TBetSlipBetSubmissionExoticsType.Quinella:
        return [combos[0], combos[1]];
      case TBetSlipBetSubmissionExoticsType.Trifecta:
        return [combos[0], combos[1], combos[2]];
      case TBetSlipBetSubmissionExoticsType.FirstFour:
        return combos;
      default:
        return [];
    }
  };

  // Find Cartesian product of all total combos, i.e. list of all possible
  // combinations
  const totalCombos = relevantEntries().reduce<number[][]>(
    (results, entries) =>
      results
        .map((result) => entries.map((entry) => [...result, entry]))
        .reduce((subResults, result) => [...subResults, ...result], []),
    [[]]
  );

  // Filter out any combinations containing the same runner in more than one position
  // e.g. [5, 5] or [1, 2, 1]
  const validCombos = totalCombos.filter(
    (combo) => combo.length === new Set(combo).size
  );

  // Quinella bet types should remove any excess combinations where the same runners
  // are present but in different positions, e.g. [1, 2] & [2, 1]
  if (exoticType === TBetSlipBetSubmissionExoticsType.Quinella) {
    const filteredCombos = validCombos.filter((element, index, array) => {
      let duplicate = false;
      array.slice(0, index).forEach((el) => {
        if (el.includes(element[0]) && el.includes(element[1])) {
          duplicate = true;
        }
      });
      return !duplicate;
    });
    return filteredCombos;
  }
  return validCombos;
};

/* Returns the total stake per combo in cents */
export const calculateStakePerCombo = (
  stake: number,
  combos: number
): number => {
  if (stake <= 0) return 0;
  return stake / combos;
};

/* Given a bet type and a stake calculate the potential returns */
export const calculatePotentialReturns = (
  stake: number,
  bet: TBetSlipBet
): number => {
  if (bet.type === EBetSlipBetSubmissionType.Single) {
    const betSingle = bet as TBetSlipBetSingle;
    return stake * betSingle.odds;
  }
  return 0;
};

/*
  This generator returns a new bet that can be used for various different bet types
  The purpose of this is to avoid having to set these common properties every time
  (basically everything in TBetSlipBetSubmission type). We exclude event info and let the caller set this
*/
export const generateNewBetSlipBet = (
  type: EBetSlipBetSubmissionType,
  stake?: number
): Omit<
  TBetSlipBet,
  | 'event_market_name'
  | 'event_icon'
  | 'event_subtitle'
  | 'event_title'
  | 'event_start_time'
> => ({
  request_id: uuid(),
  type,
  is_bonus_bet: false,
  potential_returns: 0,
  stake: stake !== undefined && stake > 0 ? stake : NaN,
  added_at: new Date().toISOString(),
  errors: [],
  event_id: '',
  gameplay: null,
  channel: EChannel.WEB,
});

/* Returns amount of places that should be displayed in betslip */
export const getAmountOfPlaces = (
  exoticsType?: TBetSlipBetSubmissionExoticsType
): number => {
  switch (exoticsType) {
    case TBetSlipBetSubmissionExoticsType.Exacta:
    case TBetSlipBetSubmissionExoticsType.Quinella:
      return 2;
    case TBetSlipBetSubmissionExoticsType.Trifecta:
      return 3;
    case TBetSlipBetSubmissionExoticsType.FirstFour:
      return 4;
    default:
      return 0;
  }
};

export const countDecimals = (number: number) => {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;

  const str = number.toString();

  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1] || 0;
  }

  if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return str.split('-')[1] || 0;
};

// Utility methods to check odds increase/decrease
// Note the betslip can contain both increases and decreases,
// hence these have not been merged into one method
export const checkOddsDecreased = (
  bets?: TBetSlipBet[],
  bet?: TBetSlipBet,
  moMBet?: TMoMSchema
) =>
  bets?.some(
    (b) =>
      b.confirmation?.rejection_reason ===
      EBetSubmissionRejectionReason.OddsChange
  ) ||
  bet?.confirmation?.rejection_reason ===
    EBetSubmissionRejectionReason.OddsChange ||
  moMBet?.betOddsChange === EOddsChangeDirection.Decrease;

export const checkOddsIncreased = (
  bets?: TBetSlipBet[],
  bet?: TBetSlipBet,
  moMBet?: TMoMSchema
) =>
  bets?.some(
    (b) =>
      (b.confirmation?.changed_odds ||
        b.confirmation?.haveOddsIncreasedAfterSubmit) &&
      (b.confirmation?.status === EBetSubmissionConfirmationStatus.Placed ||
        b.confirmation?.status ===
          EBetSubmissionConfirmationStatus.ReducedStake)
  ) ||
  (bet?.confirmation?.changed_odds &&
    (bet.confirmation?.status === EBetSubmissionConfirmationStatus.Placed ||
      bet.confirmation?.status ===
        EBetSubmissionConfirmationStatus.ReducedStake)) ||
  bet?.confirmation?.haveOddsIncreasedAfterSubmit ||
  moMBet?.betOddsChange === EOddsChangeDirection.Increase;

export const getEventRule = (numberOfPlaces: number | undefined) =>
  numberOfPlaces === 2 ? EEventRule.TwoPlaceDividends : undefined;

export const getEventRuleText = (eventRule?: EEventRule | undefined) => {
  switch (eventRule) {
    case EEventRule.NoPlace:
      return 'racing.racedetails.eventrules.noplace';
    case EEventRule.TwoPlaceDividends:
      return 'racing.racedetails.eventrules.twoplacedividends';
    case EEventRule.ThreePlaceDividends:
      return 'racing.racedetails.eventrules.threeplacedividends';
    default:
      return '';
  }
};

export const betErrorVisibility = (bet: TBetSlipBet) =>
  bet.confirmation?.status === EBetSubmissionConfirmationStatus.Rejected;

export const findConfirmationStatus = (
  requestStatus: EBetRequestStatus | undefined
): EBetSubmissionConfirmationStatus => {
  switch (requestStatus) {
    case EBetRequestStatus.Approved:
      return EBetSubmissionConfirmationStatus.Placed;
    case EBetRequestStatus.ReducedStake:
      return EBetSubmissionConfirmationStatus.ReducedStake;
    case EBetRequestStatus.Rejected:
      return EBetSubmissionConfirmationStatus.Rejected;
    default:
      return EBetSubmissionConfirmationStatus.Pending;
  }
};

export const getViewSpecificBetCount = (
  viewMode: EBetSlipViewMode,
  bets: TBetSlipBet[] | undefined
) =>
  viewMode === EBetSlipViewMode.ReviewingBets
    ? bets?.filter((bet) => Number(bet.stake) > 0).length
    : bets?.length;

export const getBetHasBeenPlaced = (bet: TBetSlipBet | undefined) =>
  bet?.confirmation?.status === EBetSubmissionConfirmationStatus.Placed ||
  bet?.confirmation?.status === EBetSubmissionConfirmationStatus.ReducedStake;

export const getRejectedMultiBet = (bets: TBetSlipBet[]) =>
  bets?.find(
    (bet) =>
      bet.type === EBetSlipBetSubmissionType.Multi &&
      bet.confirmation?.rejection_reason ===
        EBetSubmissionRejectionReason.PropositionClosed
  ) as TBetSlipBetMulti;

export const getPromoToken = (
  promoTokens: TPromoToken[] | undefined,
  tokenType: TPromoTokenType
) => promoTokens?.find(({ token_type }) => token_type === tokenType);
