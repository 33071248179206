import { css } from 'styled-components';

export const listContainer = css`
  display: none;

  ${({ theme }) => theme.device.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

// TODO: Remove
export const mobileListContainer = css`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  column-gap: var(--bc-sizes-1);

  ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const icon = css`
  margin-right: var(--bc-sizes-2);
  width: var(--bc-sizes-5);

  path {
    fill: ${({ theme }) => theme.basePalette.blue};
  }
`;

// TODO: Remove
export const raceNumber = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-sm);
  flex: 1;
`;

// TODO: Remove
export const startTime = css`
  color: ${({ theme }) => theme.basePalette.blue};
  text-align: right;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: var(--bc-fontSizes-xs);
`;
