import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: '120%',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'black',
  minW: 'unset',
  bgImage:
    'linear-gradient(to bottom, var(--bc-colors-alpha-500), var(--bc-colors-gamma-500));',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  transition: 'all .3s ease-in-out',
  _hover: {
    color: 'black',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
  _focus: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1',
};

export const TextBetCount: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  lineHeight: '120%',
};
