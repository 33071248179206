import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'beta.300',
  fontSize: 'sm',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textTransform: 'uppercase',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'accent',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'beta.300',
  pos: 'relative',
  bottom: '2px',
};
export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'beta.500',
  minW: 'unset',
  bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
  borderRadius: 'md',
  border: 'none',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  transition: 'all .3s ease-in-out',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, beta.400 0%, beta.400 100%)',
  },
  _focus: {
    bgGradient: 'linear(to-b, alpha.500, alpha.700)',
    color: 'beta.300',
    textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.35) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  },
  _after: {
    display: 'none',
  },
};
export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};
export const GroupIcon: CSSObject = {
  color: 'white',
};
