import { CSSObject } from '@chakra-ui/react';
import wood from '@/assets/fiestabet/images/backgrounds/woodBg.png';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '5',
  display: 'inline-block',
  fontSize: '2xs',
  h: '5',
  bg: 'beta.200',
  borderRadius: 'base',
  color: 'beta.700',
  ml: '0.5',
};

export const LinkRaceContainer: CSSObject = {
  bgImage: wood,
  bgSize: 'cover',
  color: 'epsilon.500',
  borderRadius: '2xl',
  boxShadow: '0px 2px 1px 0px #773053 inset, 0px 4px 0px 0px #3F1027',
  mb: '2.5',
  h: '12',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient: 'linear(to-b, alpha.700, alpha.700)',
    boxShadow: '0px 4px 4px 0px #210A15 inset, 0px 4px 0px 0px #521A35',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },

  '.state-inprogress': {
    bgGradient: 'epsilon.400',
    color: 'gamma.700',
    borderRadius: 'base',
  },

  '.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
  },

  '.state-ended': {
    bg: 'delta.300',
    color: 'delta.800',
    borderRadius: 'base',
  },
};

export const TextRaceType: CSSObject = {
  color: 'delta.700',
  fontFamily: 'MexicanKnappett',
  fontSize: 'xl',
  textTransform: 'capitalize',
  letterSpacing: 'wide',
  fontWeight: 'normal',
};

export const TextRace: CSSObject = {
  fontFamily: 'CreteRound',
  fontWeight: 'normal',
  fontSize: 'sm',
};

export const Icon: CSSObject = {
  boxSize: '6',
  color: 'epsilon.500',
  mr: '0.5',
};
