import { Image } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/wellbet/images/header/wblogo.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Image
        src={logo}
        alt={`${THEME_NAME.wellbet}`}
        sx={{
          pb: ['0', null, '5'],
          w: '170px',
          m: 'auto',
        }}
      />
    </Link>
  );
}
