import { CSSObject } from '@chakra-ui/react';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    borderRadius: 'md',
    bgGradient: 'linear(180deg, alpha.500, alpha.600)',
    color: 'white',
    border: '2px',
    borderColor: 'alpha.700',
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  left: [null, null, '0'],
  bg: 'zeta.500',
  width: '-webkit-fill-available',
  mr: '2',
  mb: '1',
  borderBottomRadius: 'md',
};

export const GridWrapper: CSSObject = {
  bg: 'alpha.800',
  borderBottomRadius: 'md',
};

export const FlexTopBtnWrapper: CSSObject = {
  pb: '1',
};

export const ButtonKeys = (isBatch: boolean): CSSObject => ({
  color: 'white',
  bgGradient: 'linear(180deg, alpha.500, alpha.600)',
  boxShadow: '0px 0.94015px 0px 0px rgba(255, 255, 255, 0.40) inset',
  textShadow: '0px 2px 3px rgba(255, 255, 255, 0.30)',
  borderRadius: 'md',
  border: 'none',
  bg: 'none',

  ...(isBatch && {
    bgGradient: 'linear(180deg, beta.500, beta.600)',
    textShadow: '0px 2px 3px rgba(255, 255, 255, 0.30)',
    color: 'alpha.700',
    fontWeight: 'bold',
  }),
  '&.keypad-btn-done': {
    _disabled: {
      bgGradient: 'linear(180deg, alpha.300, alpha.600)',
      color: 'alpha.700',
    },
    textTransform: 'uppercase',
    bgGradient: 'linear(180deg, beta.500, beta.600)',
    fontSize: 'md',
    color: 'alpha.700',
  },
});
