import React from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { Stack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { updateUserMarketingPreferences } from '../services/account.actions';
import promotionsNotificationsSchema, {
  PromotionsNotificationsSchema,
} from '../services/schemas/promotionsNotificationsSchema';
import Checkbox from '@/components/FormElements/Checkbox';
import { FormWrapper } from './styles/PromotionsNotifications.styles';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import {
  useMutationMarketing,
  useQueryMarketingPreferences,
} from '@/api/marketing/marketing.hooks';
import { useAppDispatch } from '@/hooks/useRedux';
import { usePunterData } from '@/store/AuthStore';
import { Button } from '@/components/Button/Button';

const Form: React.FC = () => {
  const punterProfile = usePunterData();

  const dispatch = useAppDispatch();
  const { mutateAsync } = useMutationMarketing();

  const { data } = useQueryMarketingPreferences();

  const onSubmit = async (values: PromotionsNotificationsSchema) => {
    if (!values) return;
    await dispatch(
      updateUserMarketingPreferences({ ...values, post: false, phone: false })
    );

    await mutateAsync({
      sms_marketing_enabled: values.sms,
      email_marketing_enabled: values.email,
    });
  };

  return (
    <Formik<PromotionsNotificationsSchema>
      enableReinitialize
      initialValues={{
        notificationsEnabled: [
          data?.email_marketing_enabled,
          data?.sms_marketing_enabled,
          punterProfile?.phone_marketing_enabled,
          punterProfile?.post_marketing_enabled,
        ].some((marketingPref) => marketingPref === true),
        email: data?.email_marketing_enabled || false,
        sms: data?.sms_marketing_enabled || false,
        phone: false, // punterProfile?.phone_marketing_enabled || false,
        post: false, // punterProfile?.post_marketing_enabled || false,
      }}
      validationSchema={promotionsNotificationsSchema}
      onSubmit={onSubmit}
    >
      {({ setValues, values }) => (
        <FormWrapper>
          <FormikForm>
            <Checkbox
              name="notificationsEnabled"
              onChange={(e) => {
                const { checked } = e.target;

                setValues({
                  notificationsEnabled: checked,
                  email: checked,
                  sms: checked,
                  phone: checked,
                  post: checked,
                });
              }}
            >
              <FormattedMessage id="account.promotionsnotifications.notificationsenabled" />
            </Checkbox>

            <Stack
              spacing={[1, 4]}
              direction={['column', 'row']}
              pl="4"
              sx={{ '> *': { w: 'auto' } }}
              mb="4"
            >
              <Checkbox
                name="email"
                onChange={(e) => {
                  const { checked } = e.target;
                  setValues({
                    ...values,
                    email: checked,
                  });
                }}
              >
                <FormattedMessage id="account.promotionsnotifications.email" />
              </Checkbox>
              <Checkbox
                name="sms"
                onChange={(e) => {
                  const { checked } = e.target;
                  setValues({
                    ...values,
                    sms: checked,
                  });
                }}
              >
                <FormattedMessage id="account.promotionsnotifications.sms" />
              </Checkbox>
              {FEATURE_FLAGS.FOR_P2 && (
                <>
                  <Checkbox
                    name="phone"
                    isDisabled={!values.notificationsEnabled}
                  >
                    <FormattedMessage id="account.promotionsnotifications.phone" />
                  </Checkbox>
                  <Checkbox
                    name="post"
                    isDisabled={!values.notificationsEnabled}
                  >
                    <FormattedMessage id="account.promotionsnotifications.post" />
                  </Checkbox>
                </>
              )}
            </Stack>

            <Button data-cy="promotionsNotificationsSubmit" type="submit">
              <FormattedMessage id="generic.savechanges" />
            </Button>
          </FormikForm>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default Form;
