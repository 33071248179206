import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  m: 0,
  borderRadius: 'lg',
  bg: 'rgba(129, 83, 255, 0.50);',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(2.5px)',

  '&&&': { mb: 0 },
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'heading',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const ButtonViewMore: CSSObject = {
  color: 'alpha.700',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.15)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'heading',
  fontWeight: 'normal',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  lineHeight: 'normal',
  borderBottomRadius: 'base',
  h: '42px',
  _hover: {
    bgGradient: 'linear(to-b, gamma.200, gamma.200)',
    color: 'alpha.700',
  },
};
