import { CSSObject, TextProps } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  color: 'white',
  fontWeight: 'medium',
  height: 'auto',
  border: 'none',
  pb: 0,
  mx: 1,
  svg: {
    color: 'white',
    mr: '1',
  },
};

export const textTeamNameProps: TextProps = {
  fontSize: 'md',
};

export const FlexHeadingMiscWrapper: CSSObject = {
  zIndex: '1',
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', null, 'row'],
    alignItems: ['flex-start', null, 'center'],

    svg: {
      pt: '0',
    },
    '.timestamp': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      color: 'white',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
  bg: 'white',
  border: 'none',
};

export const TextSportName: CSSObject = {
  color: 'white',
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};
