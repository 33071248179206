import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',
    color: 'white',
    border: '2px solid',
    borderColor: 'beta.500',
    borderRadius: 'sm',
    height: '5',
    fontFamily: 'body',
    fontWeight: 'normal',
    py: '0',
    px: '3',
    margin: '0',
    minWidth: 'auto',
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['calc(100% + 12px)', null, 'calc(102% + 12px)', 'calc(100% + 12px)'],
  left: [null, null, '0'],
  bg: 'beta.900',
  ml: -1.5,
  mb: -1.5,
  borderBottomRadius: '6',
  borderTop: '2px solid',
  borderColor: 'beta.500',
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  pt: '0',
  pb: '0',
  color: 'beta.500',
  fontWeight: 'normal',
  fontSize: 'lg',
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'primary',
  height: '100%',
  pt: '0',
  pb: '0',
  color: 'beta.500',
  fontWeight: 'normal',
  fontSize: 'lg',
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'solid',
  height: '100%',
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'extrabold',
  bgImage:
    'linear-gradient(to bottom, var(--bc-colors-beta-700), var(--bc-colors-beta-800))',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(57, 40, 5, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
  color: 'beta.500',
  width: 'auto',
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
};
