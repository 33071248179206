import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, gamma.400, gamma.500)',
    display: 'flex',
    borderRadius: 'md',
    p: '4',
    my: '5',
    gap: '2',
    alignItems: 'center',
    border: 'none',
    mt: '0',
  },
  depositIconProps: {
    color: 'delta.700',
    boxSize: '9',
  },
};
