import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '5',
  bg: 'alpha.500',
  borderRadius: 'sm',
  color: 'white',
  ml: '0.5',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'rgba(26, 165, 255, 0.15)',
  color: 'white',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'alpha.700',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.30)',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'rgba(26, 165, 255, 0.40)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },

  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'blackAlpha.800',
    borderRadius: 'sm',
    maxW: '10',
  },

  '.chakra-badge.state-prior': {
    bg: 'transparent',
    color: 'white',
    borderRadius: 'sm',
    maxW: '10',
  },

  '.chakra-badge.state-ended': {
    bg: 'alpha.500',
    color: 'white',
    borderRadius: 'sm',
    maxW: '10',
  },
};

export const TextRaceType: CSSObject = {
  color: 'beta.500',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.40)',
  fontFamily: 'Spectral',
  fontSize: 'lg',
  textTransform: 'capitalize',
  letterSpacing: 'wide',
  fontWeight: 'bold',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'sm',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'beta.500',
};
