import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  mb: '0',
  gap: '1',
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  borderRadius: 'base',
  h: '34px',
  lineHeight: 'normal',
};
