import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/sterlingparker/images/backgrounds/onboarding-desktop.png';
import bgMobile from '@/assets/sterlingparker/images/backgrounds/onboarding-mobile.png';
import bgRepeat from '@/assets/sterlingparker/images/backgrounds/bg-repeat.png';
import divRep from '@/assets/sterlingparker/images/backgrounds/div-repeat.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${divRep}), url(${bgMobile}), url(${bgRepeat})`,
    null,
    `url(${bgDesktop}), url(${bgRepeat})`,
  ],
  bgSize: ['auto 23px, cover, auto', null, '100%, auto'],
  bgPos: 'center calc(100% - 53px), center calc(100% - 82px)',
  bgRepeat: ['repeat-x, no-repeat, repeat', null, 'no-repeat, repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'flex-end',
  px: [null, null, '40px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['330px', null, 'full'],
  borderLeftRadius: ['0', '0', '10px'],
};
