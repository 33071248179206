import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';

export const betCardErrorFlexProps: CSSObject = {
  borderTopRadius: 'md',
};

export const BetPropositionClosedError: CSSObject = {
  inset: '0 0 -7px',
};

export const BetCardContainer = (): CSSObject => ({
  bg: 'alpha.600',
  borderTopRadius: 'base',
  color: 'white',
  '&::after': {
    content: `""`,
    position: 'absolute',
    bottom: '-30px',
    left: '0',
    width: '100%',
    height: '31px',
    background:
      'linear-gradient(135deg, var(--bc-colors-alpha-600) 5px, transparent 0%),linear-gradient(-135deg, var(--bc-colors-alpha-600) 5px, transparent 0%)',
    backgroundSize: '13px 32px',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  color: 'alpha.200',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'alpha.700',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeButton: CSSObject = {
  h: '9',
  bg: 'alpha.300',
  color: 'alpha.800',
  borderRadius: 'sm',
  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #95DDE8',
  textShadow: 'none',

  _hover: {
    bg: 'alpha.500',
    boxShadow:
      '0px 2px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
  },
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'alpha.700',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
  _hover: {
    bg: 'alpha.800',
  },
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BonusBetsButton = (isBonusBet: boolean) => ({
  bg: isBonusBet ? 'alpha.800' : 'beta.400',
  boxShadow: isBonusBet
    ? 'inset 0px 0px 5px rgba(0, 0, 0, 0.4)'
    : '0px 2px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
  color: 'white',
  borderRadius: 'base',
  border: isBonusBet ? '1px' : 'none',
  borderColor: isBonusBet ? 'alpha.900' : 'none',
  fontSize: '2xs',
  h: '6',
  px: '1.5',
  '&&': {
    minW: '20',
  },

  _hover: {
    bg: isBonusBet ? 'alpha.900' : 'beta.300',
  },

  _active: {
    boxShadow: 'none',
  },
  _disabled: {
    boxShadow: 'none',
  },
});

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
