import { BoxProps, LinkProps, CSSObject } from '@chakra-ui/react';

export const linkLoginProps: LinkProps = {
  color: 'alpha.600',
  fontSize: '12px',
  fontWeight: 500,
  mb: '4',
  display: 'inline-flex',
  alignItems: 'center',

  sx: {
    svg: {
      boxSize: '10px',
      mr: '1',
      mt: '-1px',
    },
  },
};

export const wrapperProps: BoxProps = {
  justifyContent: ['null', null, 'flex-start'],

  sx: {
    'div:first-child': {
      mt: ['0', '0', '145px'],
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'gray.500',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  mb: '2',

  '+ a': {
    color: '#718096',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    mx: 0,
    display: 'inline',
  },
};
