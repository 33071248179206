import React from 'react';
import { Box } from '@chakra-ui/react';

import { MatchCard } from '@/views/sports/components/MatchCard/MatchCard';
import { TSportMatchesResponse } from '@/api/sportDetailsMatches/sportDetailsMatches.types';
import { postMobileAppMessage } from '../../../../mobileapp/mobileapp.utils';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';

export type TFutures = {
  matches: TSportMatchesResponse;
};
export default function Futures({ matches }: TFutures) {
  if (!matches) return null;

  return (
    <Box p="1">
      {matches?.map((match) => {
        const compOrTournamentParam = match?.tournament_id
          ? `tournamentId=${match.tournament_id}`
          : `competitionId=${match.competition_id}`;

        const to = `/racing/futures/${match.match_name}?sportId=${match.sport_id}&${compOrTournamentParam}&matchId=${match.match_id}`;
        return (
          <Box
            onClick={() => {
              if (IS_MOBILE_APP) {
                postMobileAppMessage('navigate_to_match', match);
              }
            }}
            mb="1"
            key={match.match_id}
          >
            <MatchCard to={to} sportName="racing" match={match} teams={[]} />
          </Box>
        );
      })}
    </Box>
  );
}
