import { GridItem, Show, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { TSideNavTheme } from '../../theme/base/customComponents/sideNav';
import Drawer from './Drawer/Drawer';
import Navigation from './Navigation/Navigation';
import { IS_MOBILE_APP } from '../../constants/isMobileApp';

function SideNav() {
  const styles = useStyleConfig('SideNav') as TSideNavTheme;

  if (IS_MOBILE_APP) return null;

  return (
    <GridItem gridArea="sidenav" sx={{ label: 'SideNavContainer' }}>
      <Show below="lg">
        <Drawer />
      </Show>

      <Show above="lg">
        <Navigation
          sx={{
            w: styles.sidenavWidth,
            ...styles.background,
          }}
        />
      </Show>
    </GridItem>
  );
}

export default SideNav;
