import React from 'react';
import { WrapperFlexOnboarding } from '../styles/onboarding.styles';
import TemplateDepositLimit from './templates/template';
import { createTemplate } from '@/lib/templateSwitch';

export default function DepositLimitNew() {
  const template = createTemplate(TemplateDepositLimit);

  return <WrapperFlexOnboarding>{template}</WrapperFlexOnboarding>;
}
