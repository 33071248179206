import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    fontFamily: 'Coiny',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow:
      '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.10) inset',
    fontSize: '2xs',
    h: 'auto',
    letterSpacing: 'wider',
    textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.50)',
    borderRadius: 'md',
    _hover: {
      bg: 'beta.600',
    },

    _disabled: {
      fontFamily: 'Roboto',
      bg: 'beta.700',
      color: 'beta.300',
      opacity: '1',
      px: '3px',
      py: '1',
      borderRadius: '3px',
      boxShadow: 'none',
      border: 'none',

      _hover: {
        boxShadow: 'none',
        bg: 'alpha.600',
      },
    },
  },
};

export const FlexInner: CSSObject = {
  borderBottomColor: 'whiteAlpha.300',
  minH: '12',
  py: '2',
};

export const TextMonth: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
