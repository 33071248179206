import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TThemeName } from '../theme/theme.types';
import { IS_MOBILE_APP } from '../constants/isMobileApp';

const { REACT_APP_THEME } = window.BETCLOUD_ENV;

type TInitialState = {
  themeName: TThemeName;
};

const initialThemeName = () => {
  if (IS_MOBILE_APP && window.MobileApp_Theme !== undefined) {
    return window.MobileApp_Theme;
  }

  return (REACT_APP_THEME as TThemeName | undefined) ?? 'base';
};

const initialState: TInitialState = {
  themeName: initialThemeName(),
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<TInitialState['themeName']>) => ({
      ...state,
      themeName: action.payload,
    }),
    resetTheme: () => initialState,
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
