import {
  Box,
  chakra,
  ChakraComponent,
  Divider,
  Flex,
  FlexProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './Modal.styles.imports';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BetSlipModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000060;
  z-index: 98;
  position: absolute;
  top: 0;

  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipModalWrapper};
`;

export const FlexBetSlipModalContainer = chakra(Flex, {
  label: 'modal-FlexBetSlipModalContainer',
  baseStyle: () => ({
    flexDir: 'column',
    maxW: ['full', null, null, null, '290px'],
    w: ['full', null, '320px', '320px', '290px'],
    borderRadius: [null, null, null, null, 'md'],
    contain: 'paint',
    h: ['full'],
    flex: 1,
    maxH: ['100dvh', null, null, null, 'unset'],
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBetSlipModalContainer;
      } catch (err) {
        return {};
      }
    })(),

    width: '500px',
  }),
}) as ChakraComponent<'div', FlexProps>;

export const BetSlipContainer = chakra(Box, {
  label: 'modal-BetSlipContainer',
  baseStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    boxSize: 'full',
    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipFooterContainer = chakra(Box, {
  label: 'modal-BetSlipFooterContainer',
  baseStyle: () => ({
    py: '4',
    // If changing padding here the negative margins in OddsChangeBanner will need to be updated
    px: '2',
    pb: 4,
    w: 'full',
    position: ['relative', null, null, 'sticky'],
    bottom: [null, null, null, '0'],
    zIndex: 'sticky',
    display: 'flex',
    flexDir: 'column',
    justifyContent: 'end',

    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipFooterContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export type TBetslipFooterRow = {
  type: 'totalStake' | 'estReturn';
} & any;

export const BetSlipFooterMotion = chakra(Box, {
  label: 'modal-BetSlipFooterMotion',
  baseStyle: () => ({
    position: ['relative', null, null, 'sticky'],
    bottom: '0',
    zIndex: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipFooterMotion;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipFooterRowTitle = chakra<TBetslipFooterRow>(Text, {
  label: 'modal-BetSlipFooterRowTitle',
  shouldForwardProp: (prop) => !['type'].includes(prop),
  baseStyle: () => ({
    flex: '1',
    fontSize: 'sm',
    fontWeight: '600',

    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipFooterRowTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipFooterRowValue = chakra<TBetslipFooterRow>(Text, {
  label: 'modal-BetSlipFooterRowValue',
  shouldForwardProp: (prop) => !['type'].includes(prop),
  baseStyle: ({ type }: TBetslipFooterRow) => ({
    flex: '1',
    fontSize: 'sm',
    textAlign: 'right',
    fontWeight: '400',

    ...(() => {
      try {
        return styleImports[themeName]?.BetSlipFooterRowValue?.({
          type,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const buttonClearBetsProps: CustomButtonProps = {
  'data-testid': 'modal-buttonClearBetsProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonClearBetsProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  label: 'modal-buttonConfirmBetsProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonConfirmBetsProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const BetslipVStack = chakra(VStack, {
  label: 'BetslipVStack',
  baseStyle: () => ({
    w: 'full',
    spacing: '4',
    alignItems: 'start',
    ...(() => {
      try {
        return styleImports[themeName]?.BetslipVStack;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ReuseSelectionsButton = chakra(Button, {
  baseStyle: () => ({
    ml: '1',
    h: ['10', null, '8'],
    boxShadow: 'none',
  }),
});

export const buttonMakeDepositProps: CustomButtonProps = {
  label: 'modal-buttonMakeDepositProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonMakeDepositProps;
    } catch (err) {
      return {};
    }
  })(),
};

/**
 * @deprecated Use `buttonMakeDepositProps` instead and add your theme into ButtonMakeDepositSwitched
 */
export const MakeDepositButton = chakra(Button, {
  baseStyle: () => ({
    h: '10',
    boxShadow: 'none',
    fontSize: 'xs',
    mt: '3.5',
    ...(() => {
      try {
        return styleImports[themeName]?.MakeDepositButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const EditBetsButton = chakra(Button, {
  baseStyle: () => ({
    h: '10',
    mr: '1',
    mt: '1',
    mx: '0',
    fontSize: 'xs',
    border: '2px solid',
  }),
});

export const LowStakeWrapper = chakra(Flex, {
  baseStyle: () => ({
    w: 'full',
    justifyContent: 'center',
    alignItems: 'center',
    flexDir: 'column',
  }),
});

export const LowStakePrompt = chakra(Flex, {
  baseStyle: () => ({
    flexDir: 'column',
    w: 'full',
    justifyContent: 'center',
    alignItems: 'center',
    py: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.LowStakePrompt;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LowStakeDivider = chakra(Divider, {
  baseStyle: () => ({
    mb: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.LowStakeDivider;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexBetGroupContainer = chakra(Flex, {
  label: 'modal-FlexBetGroupContainer',
  baseStyle: ({ isKeypadVisible }: any) => ({
    flex: '1',
    p: '2',
    overflowY: 'auto',
    mb: isKeypadVisible && ['112px', null, 'unset'],

    ...(() => {
      try {
        return styleImports[themeName]?.FlexBetGroupContainer?.(
          isKeypadVisible
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetSlipErrorContainer = styled.div`
  margin-bottom: var(--bc-space-2);
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipErrorContainer};
`;

export const BetSlipErrorText = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipErrorText};
`;

export const BetSlipConfirmationContainer = styled.div`
  margin-bottom: var(--bc-space-2);
  ${({ theme }) =>
    theme.features.betslip.betSlipModal.betSlipConfirmationContainer};
`;

export const BetSlipConfirmationText = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipConfirmationText};
`;

export const BetSlipHeadingContainer = styled.div`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipHeadingContainer};
`;

export const BetSlipHeading = styled.h3`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipHeading};
`;

export const BetSlipAccountBalance = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipAccountBalance};
`;

export const BetSlipContentWrapper = styled.div`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipContentWrapper}
`;

export const BetSlipContentContainer = styled.div`
  overflow-y: auto;

  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipContentContainer};
`;

export const BetSlipContentHeading = styled.h3`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipContentHeading};
`;

export const BetSlipFooterTextContainer = styled.div`
  ${({ theme }) =>
    theme.features.betslip.betSlipModal.betSlipFooterTextContainer};
`;

export const StakeTextContainer = styled.div`
  ${({ theme }) =>
    theme.features.betslip.betSlipModal.betSlipStakeTextContainer};
`;

export const StakeText = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipStakeText};
`;

export const StakeValue = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipStakeValue};
`;

export const ReturnsTextContainer = styled.div`
  ${({ theme }) =>
    theme.features.betslip.betSlipModal.betSlipReturnsTextContainer};
`;

export const ReturnsText = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipReturnsText};
`;

export const ReturnsValue = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipReturnsValue};
`;

export const BetSlipActionsContainer = styled.div`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipActionsContainer};
`;

export const BetSlipEmptyContainer = styled.div`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipEmptyContainer};
`;

export const BetSlipEmptyHeading = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipEmptyHeading};
`;

export const BetSlipEmptySubheading = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipEmptySubheading};
`;

export const BetSlipEmptyMultiContainer = styled.div`
  ${({ theme }) =>
    theme.features.betslip.betSlipModal.betSlipEmptyMultiContainer};
`;

export const BetSlipEmptyMultiText = styled.p`
  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipEmptyMultiText};
`;

export const SubmitBetSlipButton = styled.button`
  ${({ theme }) => theme.features.betslip.betSlipModal.submitBetSlipButton};
`;

export const ClearBetSlipButton = styled.button`
  ${({ theme }) => theme.features.betslip.betSlipModal.clearBetSlipButton};
`;

export const BetSlipEditAction = styled.button`
  cursor: pointer;
  background: none;
  border: none;

  ${({ theme }) => theme.features.betslip.betSlipModal.betSlipEditAction};
`;
