import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  w: '56px',
  mx: '1',
  fontFamily: 'Fredoka',
  fontSize: 'lg',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  _disabled: {
    bg: 'blackAlpha.600',
    color: 'blackAlpha.900',
    fontWeight: 600,
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow: 'none',
    opacity: '0.5',
    pointerEvents: 'none',
  },
  sx: {
    '&:active, &[data-active="true"]': {
      color: 'alpha.300',
      bg: 'alpha.800',
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
      '&:disabled &:hover': {
        boxShadow: 'none',
      },
      '&:hover, &:focus': {
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
      },
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: '100%',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  background: 'alpha.800',
  borderTop: '1px solid rgba(255, 255, 255, 0.10)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
};

export const favouriteIconProps: CSSObject = {
  color: 'alpha.300',
  boxSize: '2',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'alpha.300',
  textShadow: 'none',
};
