import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/titanbet/images/race-bg.png';

export const ExoticResultedWrapper: CSSObject = {
  p: '3',
  borderRadius: 'lg',
  flex: '1',
  bgImage: `url(${background})`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
  border: 'none',
};

export const TypeItem: CSSObject = {
  color: 'beta.100',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  borderRadius: 'md',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 0px 0px 1px #4A5454 inset, 0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
  '&:last-child': {
    mb: '2',
  },
  border: 'none',
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'gamma.300',
  fontSize: 'sm',
  fontWeight: 'black',
};
