import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import {
  FlexWrapper,
  BoxHeadingWrapper,
  HeaderWrapper,
} from '../styles/Featured.styles';
import baseTemplate from './template.default';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  featureCard: {
    wrapper: <BoxHeadingWrapper />,
    header: (
      <HeaderWrapper>
        <FormattedMessage id="main.featured.header" />
      </HeaderWrapper>
    ),
    headingVs: <BoxHeadingWrapper />,
    feature: <FeaturedMatch />,
  },

  ...(baseTemplate?.first as TTemplate),
};

export default template;
