import React from 'react';
import {
  Box,
  Button as ChakraBtn,
  ButtonProps,
  forwardRef,
  TextProps,
} from '@chakra-ui/react';

export type CustomButtonProps = {
  spanProps?: TextProps;
} & ButtonProps;

export const Button = forwardRef(
  ({ children, spanProps, ...props }: CustomButtonProps, ref?: any) => (
    <ChakraBtn ref={ref} {...props}>
      <Box
        as="span"
        className="btn-span"
        alignSelf="stretch"
        justifySelf="stretch"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="4px"
        {...spanProps}
      >
        {children}
      </Box>
    </ChakraBtn>
  )
);
