import React from 'react';
import { Image } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import { BoxHeadingWrapper, FlexWrapper } from '../styles/Featured.styles';
import bendit from '../../../../../../assets/wellbet/images/homeFeaturedSport/bendit.png';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  slogan: {
    wrapper: <BoxHeadingWrapper />,
    body: (
      <BoxHeadingWrapper>
        <Image src={bendit} m="auto" />
      </BoxHeadingWrapper>
    ),
    feature: <FeaturedMatch />,
  },
};

export default template;
