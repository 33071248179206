import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  borderRadius: 'base',
  bg: 'linear-gradient(var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
  color: 'white',
  fontSize: 'xs',
  display: 'flex',
  alignItems: 'center',
  w: '12',
  sx: {
    '&&': {
      '&:active, &[data-active="true"]': {
        boxShadow: 'inset 0px 3px 4px var(--bc-colors-alpha-400)',
        bg: 'alpha.200',
        color: 'alpha.700',
        '&:hover': {
          color: 'alpha.800',
          bg: 'alpha.300',
        },
      },
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
      p: '2',
    },
    '&:hover': {
      color: 'white',
      bg: 'linear-gradient(180deg, var(--bc-colors-alpha-700) 0%, var(--bc-colors-alpha-800) 100%)',
    },
    '&:disabled': {
      borderRadius: 'base',
      '&&': {
        boxShadow: 'none',
        color: 'gray.600',
        bg: 'blackAlpha.200',
      },
      pointerEvents: 'none',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: '100%',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  background: 'gamma.400',
  border: '1px solid rgba(255, 255, 255, 0.10)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
};

export const favouriteIconProps: CSSObject = {
  color: 'gamma.900',
  boxSize: '2',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '8px',
  color: 'gamma.900',
  textShadow: 'none',
};
