import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { chakra, Flex, Skeleton, Text } from '@chakra-ui/react';
import { rgba } from 'polished';
import { styleImports } from './NextToJumpList.styles.imports';
import IconSvg from '../../../../../components/common/IconSvg';
import { tileLinkContainerStyles } from '../../SportsTile/SportsTile.styles';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const ListContainer = styled.div<{ forceVerticalList?: boolean }>`
  ${({ theme }) => theme.features.home.nextToJumpList.listContainer};
  display: ${({ forceVerticalList = false }) =>
    forceVerticalList ? 'flex' : 'none'};
  flex-direction: ${({ forceVerticalList = false }) =>
    forceVerticalList ? 'column' : 'row'};
`;

export const MobileListContainer = chakra(Flex, {
  label: 'nextToJumpList-MobileListContainer',
  baseStyle: ({ theme }) => ({
    justifyContent: 'flex-start',
    columnGap: '1',
    display: [null, null, 'none'],
    alignItems: 'stretch',

    ...(theme.themeName === EThemes.Wellbet && {
      flexDir: 'column',
      width: '100%',
      mx: '4',
      mb: '4',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      flexDir: 'column',
      width: '100%',
      mb: '4',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.MobileListContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceLinkContainer = chakra(Link, {
  label: 'nextToJumpList-RankLinkContainer',
  baseStyle: ({ theme }) => ({
    alignItems: 'center',
    py: '3',
    px: '4',
    mb: '1',
    justifyContent: 'flex-start',
    borderRadius: 'md',
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    transition: 'all 0.1s ease-in-out',
    gap: '0.5',

    ...(theme.themeName === EThemes.Wellbet && {
      bg: 'white',
      px: '1.5',
      py: '2.5',
      color: 'beta.500',
      mx: '2',
      mb: '1.5',
      w: 'auto',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: rgba(`${theme.colors.beta[500]}`, 0.5),
      color: 'white',
      fontWeight: 'semibold',
      px: '3',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RaceLinkContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Icon = chakra(IconSvg, {
  label: 'nextToJumpList-Icon',
  baseStyle: ({ theme }) => ({
    mr: '2',
    boxSize: '5',

    ...(theme.themeName === EThemes.Wellbet && {
      boxSize: '8',
      mr: '3',
      color: 'white',
      bg: 'beta.500',
      p: '2',
      borderRadius: 'full',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.Icon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextRace = chakra(Text, {
  label: 'nextToJumpList-TextRace',
  baseStyle: ({ theme }) => ({
    fontSize: 'sm',
    mr: '2',
    textTransform: 'capitalize',

    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'semibold',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextRace;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const RaceNumber = chakra(Text, {
  label: 'nextToJumpList-RaceNumber',
  baseStyle: ({ theme }) => ({
    fontSize: 'sm',
    flex: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      flex: 0,
      py: '1',
      borderRadius: 'base',
      bg: 'black',
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      minWidth: '24px',
      display: 'inline-block',
      mr: '1',
      fontSize: '2xs',
      h: '5',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      flex: 0,
      py: '1',
      borderRadius: 'sm',
      bg: 'gamma.100',
      color: 'gamma.500',
      lineHeight: '1',
      fontWeight: 'bold',
      textAlign: 'center',
      minWidth: '6',
      display: 'inline-block',
      mr: '1',
      fontSize: 'xs',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.RaceNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const StartTime = chakra(Text, {
  label: 'nextToJumpList-StartTime',
  baseStyle: ({ theme }) => ({
    ml: 'auto',
    mr: '0.5',

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: '2xs',
      fontWeight: 'medium',
      color: 'black',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: '2xs',
      fontWeight: 'medium',
      alignSelf: 'center',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.StartTime;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const NextToJumpTileContainer = chakra(Link, {
  label: 'nextToJumpList-NextToJumpTileContainer',
  baseStyle: ({ theme }) => ({
    ...tileLinkContainerStyles(theme.themeName),
    gridTemplateAreas: `
      "raceName raceName"
      "icon raceNumber"
      "icon timer"
    `,
    ...(theme.themeName === EThemes.Wellbet && {
      gridTemplateColumns: '44px 1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `
        "icon raceName raceName timer raceNumber"
      `,
      gap: '2',
      mx: '1',
      mb: '1',
      border: '1px',
      borderColor: 'gray.200',
      p: '1.5',
      alignItems: 'center',
      width: '100%',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      gridTemplateColumns: '30px 1fr 1fr 55px 55px',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `
        "icon raceName raceName raceNumber timer"
      `,
      gap: '2',
      mb: '1',
      p: '1.5',
      alignItems: 'center',
      width: '100%',
      h: '38px',
      bg: rgba(`${theme.colors.beta[500]}`, 0.5),
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.NextToJumpTileContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SkeletonLoader = chakra(Skeleton, {
  baseStyle: () => ({
    h: '44px',
  }),
});
