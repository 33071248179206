import evenShotBg from '@/assets/puntcity/images/backgrounds/evenShotBg.png';
import evenShot from '@/assets/puntcity/images/evenShotMascot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  oddsValueSpan: {
    color: 'white',
    sx: {
      '&[data-hasbets="true"]': {
        color: 'white',
      },
    },
  },
  hasBetSubTitleText: {
    fontWeight: 'normal',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notAvailableText: {
    mt: 0,
  },
  notYetAvailableText: {
    color: 'gamma.300',
    fontWeight: 'bold',
    mt: 0,
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'gamma.400',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'extrabold',
    fontSize: 'lg',
  },
  oddsText: {
    mt: '0',
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'md',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    border: '2px',
    borderColor: 'gamma.500',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flexDir: 'column',
    bgColor: 'alpha.500',
    bgImage: `url(${evenShotBg})`,
    bgSize: 'cover',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    fontFamily: 'Sansita One',
    fontWeight: 'normal',
    color: 'delta.500',
    textTransform: 'capitalize',
    fontSize: '47px',
  },
  addToBetslipButton: {
    variant: 'secondary',
  },
  loginToView: {
    variant: 'secondary',
  },
  evenShotImage: {
    position: 'relative',
    top: 3.5,
    boxSize: '180px',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
  },
};
