import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  maxW: ['none', 'lg'],
  mt: '1',

  // Buttons
  '.chakra-button': {
    // TODO: fix theme colours
    bg: '#426dd6',
    border: '2px',
    borderColor: 'alpha.700',
    borderRadius: 'lg',
    overflow: 'hidden',
    pos: 'relative',
    zIndex: '1',

    // Pseudo-element for smooth gradient transition
    _before: {
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      borderRadius: 'calc(var(--bc-radii-lg) - 4px)',
      bottom: '2px',
      content: '""',
      left: '2px',
      pos: 'absolute',
      right: '2px',
      top: '2px',
      transition: 'inherit',
      zIndex: '-1',
    },

    _hover: {
      bg: 'alpha.700',
      borderColor: 'alpha.500',

      _before: {
        opacity: '0',
      },
    },

    _active: {
      bg: 'alpha.800',
      borderColor: 'alpha.500',

      _before: {
        opacity: '0',
      },
    },
  },

  // Control (field wrapper)
  '.chakra-form-control': {
    mb: '3',
    mt: '0',
  },

  // Label
  '.chakra-form__label': {
    color: 'inherit',
    fontWeight: 'semibold',
    mb: '1.5',
  },

  // Inputs
  '.chakra-input, .chakra-select': {
    bg: 'white',
    borderRadius: 'lg',
    boxShadow: '0 3px 8px rgba(0, 0, 0, .2)',
    color: 'gray.600',
    fontSize: 'xs',
    h: '9',
    px: '2.5',
    _disabled: {
      opacity: '0.6',
    },
  },

  // Radios
  '.chakra-radio__input + span': {
    bg: 'gamma.500',
    border: '2px',
    borderColor: 'gamma.500',
    m: '0',

    _hover: {
      bg: 'gamma.600',
      borderColor: 'gamma.600',
    },
  },

  // Addons
  '.chakra-input__left-addon': {
    bg: 'gamma.600',
    color: 'white',
    borderLeftRadius: 'lg',
    fontSize: 'xs',
    fontWeight: 'bold',
    h: '9',
  },
  '.chakra-input__left-addon + .chakra-input': {
    borderLeftRadius: 'none',
  },

  // Helper text
  '.chakra-form__helper-text': {
    color: 'inherit',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
};
