import { css } from 'styled-components';

// TODO: Remove
export const container = css`
  position: relative;

  #greenid-container {
    background-color: inherit;

    &:focus-visible {
      outline: none;
    }
  }

  #greenid-intro-content {
    color: ${({ theme }) => theme.basePalette.white};
    padding-bottom: var(--bc-sizes-5);

    h1 {
      display: none;
    }

    p {
      font-family: ${({ theme }) => theme.fontFamily.body};
      font-size: var(--bc-fontSizes-xs);
      margin: var(--bc-sizes-2) 0;
    }
  }

  button#greenid-option-list-toggle {
    margin-top: 0 !important;
  }

  #greenid-container label {
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: var(--bc-fontSizes-s);
  }

  #greenid-container h2 {
    color: ${({ theme }) => theme.palette.primary};
    font-size: var(--bc-fontSizes-xs);
    margin-bottom: var(--bc-sizes-5);
  }

  #greenid-container .uploadifive-queue-item {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: var(--bc-fontSizes-s);
  }


  #greenid-container .greenid-alert {
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: var(--bc-fontSizes-s);
  }

  #greenid-container .btn-primary {
    ${({ theme }) => theme.components.button.button};
    border: none;
    width: 100%;
  }

  #greenid-container .btn-default {
    ${({ theme }) => theme.components.button.emptyButton};
    width: 100%;
  }

  #greenid-container .btn-primary:active {
    ${({ theme }) => theme.components.button.button};
  }


  .greenid-sr-only {
    display: none;
  }

  .greenid-alert-info {
    margin-bottom: var(--bc-sizes-3);
    color: ${({ theme }) => theme.palette.primary};
    line-height: var(--bc-sizes-4);
  }

  #greenid-wait-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:focus-visible {
      outline: none;
    }

    .modal-dialog {
      background: white;
      text-align: center;
      box-shadow: ${({ theme }) => theme.palette.containerShadow};
      border-radius: var(--bc-sizes-2);
    }

    .modal-body {
      display: flex;
      flex-direction: column;

      #greenid-modal-text {
        color: ${({ theme }) => theme.basePalette.black};
        font-size: var(--bc-fontSizes-md);
        font-family: ${({ theme }) => theme.fontFamily.body};

        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  #greenid-source-content {
    position: relative;
    height: initial !important;

    .greenid-alert.greenid-alert-danger {
      font-size: var(--bc-fontSizes-xs);
      line-height: var(--bc-lineHeights-base);
      color: ${({ theme }) => theme.palette.warning};
    }


    .greenid-alert-glyphicon.glyphicon-remove-circle {
      color: white;
      width: var(--bc-fontSizes-sm);
      height: var(--bc-fontSizes-sm);
      border-radius: 100%;
      display: block;
      margin-bottom: var(--bc-sizes-1);
      background-size: contain;
    }

    #greenid-source-header {
      margin-bottom: var(--bc-sizes-5);

      #greenid-source-list-container {
        display: flex;
      }

      #greenid-source-title {
        #greenid-source-title-heading {
          margin-bottom: var(--bc-sizes-2);
          font-family: ${({ theme }) => theme.fontFamily.heading};

          color: ${({ theme }) => theme.basePalette.white};
          font-size: var(--bc-fontSizes-md);
        }

        a.greenid-modal-launch {
          position: absolute;
          top: 0;
          right: 0;
          padding: var(--bc-sizes-5);
          color: ${({ theme }) => theme.basePalette.white};
          display: flex;
          flex-direction: row;
          align-items: center;

          span.glyphicon-question-sign {
            width: var(--bc-sizes-5);
            height: var(--bc-sizes-5);
            display: block;
            background-repeat: no-repeat;
          }

          span.glyphicon-question-sign:before {
            content: '';
          }
        }

        .greenid-modal {
          display: none;
          position: relative;

          .modal-header {
            font-size: var(--bc-fontSizes-xs);
            font-family: ${({ theme }) => theme.fontFamily.heading};
            color: ${({ theme }) => theme.palette.primary};
            margin-bottom: var(--bc-sizes-3);
          }

          .modal-body {
            font-size: var(--bc-fontSizes-xs);
            font-family: ${({ theme }) => theme.fontFamily.body};
            color: ${({ theme }) => theme.basePalette.black};
            margin-bottom: var(--bc-sizes-3);
            line-height: var(--bc-sizes-4);

            a {
              color: ${({ theme }) => theme.basePalette.white};
            }
          }

          .greenid-modal-close.greenid-x {
            position: absolute;
            top: 0;
            right: 0;
            background: none;
            border: none;
            color: var(--bc-colors-black);
            padding: var(--bc-sizes-4);
          }
        }

        #greenid-source-list-container {
          .greenid-modal-launch {
            ${({ theme }) => theme.components.button.emptyButton};
            width: 100%;
            margin-bottom: var(--bc-sizes-3);
            font-size: var(--bc-fontSizes-sm);
            text-align: center;
            line-height: var(--bc-sizes-8);
            display: block;
          }

          .dropdown-menu {
            display: none;
          }

          .dropdown-menu h2 {
            color: ${({ theme }) => theme.basePalette.black};
          }

          #greenid-sources {
            margin-bottom: var(--bc-sizes-3);

            li {
              font-family: ${({ theme }) => theme.fontFamily.body};
              color: white;
              font-size: var(--bc-fontSizes-xs);
              margin-bottom: var(--bc-sizes-1);

              a {
                text-decoration: underline;
              }
            }
          }
        }

      }

      #greenid-source-body {
        p {
          font-family: ${({ theme }) => theme.fontFamily.body};
          color: ${({ theme }) => theme.palette.primary} !important;
          font-size: var(--bc-fontSizes-xs);
          margin-bottom: var(--bc-sizes-3);

          strong {
            font-size: var(--bc-fontSizes-sm);
            font-weight: bold;
            color: ${({ theme }) => theme.palette.primary};
          }
        }

        .row {
          margin-bottom: var(--bc-sizes-2);

          .form-group {
            label {
              color: ${({ theme }) => theme.palette.primary};
              width: 100%;
              display: block;
              font-family: ${({ theme }) => theme.fontFamily.heading};
              font-size: var(--bc-fontSizes-md);
              margin: var(--bc-sizes-3) 0;

              span {
                font-family: ${({ theme }) => theme.fontFamily.heading};
                font-size: var(--bc-fontSizes-md);
              }
            }

            input[type="text"] {
              ${({ theme }) => theme.components.textInput.textInput};
              width: 100%;
              margin-bottom: var(--bc-sizes-2);
              padding: 0 var(--bc-sizes-2);

              &:focus-visible {
                outline: none;
              }
            }

            select {
              ${({ theme }) => theme.components.select.select};
              width: 100%;
              margin-bottom: var(--bc-sizes-3);
              padding: 0 var(--bc-sizes-2);
            }
          }

          .greenid-has-error {
            .help-block {
              color: ${({ theme }) => theme.palette.warning};
            }
          }
        }

        .dd-container {
          .dd-select {
            .dd-selected-text,
            .dd-option-text {
              color: ${({ theme }) => theme.basePalette.purple};
            }
          }
        }

        .checkbox {
          margin-bottom: var(--bc-sizes-2);

          label {
            color: white;
            font-family: ${({ theme }) => theme.fontFamily.body};
            font-size: var(--bc-fontSizes-xs);
            display: flex;
            margin-bottom: var(--bc-sizes-2);
          }

          input[type="checkbox"] {
            /* Add if not using autoprefixer */
            -webkit-appearance: none;
            /* Remove most all native input styles */
            appearance: none;
            /* For iOS < 15 */
            background-color: var(--form-background);
            /* Not removed via appearance */
            margin: 0;

            font: inherit;
            color: ${({ theme }) => theme.palette.black};
            width: var(--bc-sizes-3);
            height: var(--bc-sizes-3);
            border: var(--bc-sizes-0-5) solid ${({ theme }) =>
              theme.basePalette.white};
            transform: translateY(-var(--bc-sizes-0-5));

            display: inline-grid;
            place-content: center;
            background: ${({ theme }) => theme.basePalette.white};
            margin-right: var(--bc-sizes-1);
          }

          input[type="checkbox"]::before {
            content: "";
            width: var(--bc-sizes-2);
            height: var(--bc-sizes-2);
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: bottom left;
            transition: 120ms transform ease-in-out;
            background-color: ${({ theme }) => theme.basePalette.black};
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          input[type="checkbox"]:disabled {
            --form-control-color: var(--form-control-disabled);

            color: var(--form-control-disabled);
            cursor: not-allowed;
          }

          &.greenid-has-error {
            .help-block {
              color: ${({ theme }) => theme.palette.warning};
            }
          }
        }

        .greenid-certoption {
          margin-bottom: var(--bc-sizes-2);
        }

        #greenid-u .greenid-u-select-container .greenid-u-select-option .greenid-u-select-option-words,
        .certselect .words {
          font-family: ${({ theme }) => theme.fontFamily.body};
          color: ${({ theme }) => theme.basePalette.white};
          font-size: var(--bc-fontSizes-sm);
        }

        .greenid-bride-groom-label {
          font-family: ${({ theme }) => theme.fontFamily.heading};
          color: ${({ theme }) => theme.palette.primary};
          font-size: var(--bc-fontSizes-sm);
          margin-bottom: var(--bc-sizes-2);
        }

        #greenid-u .greenid-u-select-container .greenid-u-select-option .greenid-u-select,
        .certselect .greenid-certlink {
          width: var(--bc-sizes-5);
          height: var(--bc-sizes-5);
          margin-left: var(--bc-sizes-2);

          &:disabled {
            background-color: ${({ theme }) => theme.palette.primary};
          }
        }

        .certselect {
          margin-bottom: var(--bc-sizes-2);
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: var(--bc-sizes-2);

          .greenid-certReset {
            ${({ theme }) => theme.components.button.emptyButton};
          }
        }

        #greenid-medicaredvs-multiline-name-toggle {
          display: block;
          margin-bottom: var(--bc-sizes-5);
          font-family: ${({ theme }) => theme.fontFamily.body};
          color: ${({ theme }) => theme.basePalette.white};
          text-decoration: underline;
          font-size: var(--bc-fontSizes-sm);
        }

        .greenid-radio-certchange
        .greenid-certoption {
          .radio-inline {
            display: flex;
            flex-direction: row;

            label {
              font-family: ${({ theme }) => theme.fontFamily.body};
              color: ${({ theme }) => theme.basePalette.white};
              font-size: var(--bc-fontSizes-sm);
            }
          }
        }

        .greenid-radio-certchange {
          margin-bottom: var(--bc-sizes-2);

          .radio-inline {
            display: flex;
            flex-direction: row;
            font-family: ${({ theme }) => theme.fontFamily.body};
            color: ${({ theme }) => theme.basePalette.white};
            font-size: var(--bc-fontSizes-sm);
            margin-bottom: var(--bc-sizes-2);

            input[type="radio"] {
              margin: 0 var(--bc-sizes-2) 0 0;
            }
          }
        }

        #greenid-u {
          .greenid-u-select-container {
            .greenid-u-select-option {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: var(--bc-sizes-2);
            }
          }

          .greenid-u-reset {
            ${({ theme }) => theme.components.button.emptyButton};
          }
        }
      }

    }
`;
