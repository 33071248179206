import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'tertiary',
  sx: {
    '&&': {
      h: 'unset',
    },
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, 'none'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'alpha.700',
    border: '2px',
    borderColor: 'alpha.100',
    color: 'white',
    borderRightRadius: 'lg',
    fontWeight: 'semibold',

    _placeholder: {
      color: 'white',
    },

    '&:hover, &:active, &:focus, &:visited': {
      bg: 'alpha.800',
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    bg: 'alpha.100',
    borderLeftRadius: 'lg',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '4',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'lg',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bg: 'blackAlpha.300',
  borderTopRadius: 'lg',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
};

export const TextAddToBetslipButton: CSSObject = {
  filter: 'drop-shadow(0px 1px 1px rgba(247, 215, 31, 0.7))',
  _active: {
    filter: 'none',
  },
};
