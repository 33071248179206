import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bgGradient: 'linear-gradient(180deg, #F9D451 0%, #DA911A 100%)',
  borderRadius: 'base',
  color: 'alpha.600',
  fontSize: 'sm',
  fontWeight: 'medium',
  px: '3',
  py: '4',
  position: 'relative',
  zIndex: '999',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const ButtonGetVerified: CSSObject = {
  bgGradient:
    'linear-gradient(180deg, #30BA00 0%, #00CAF6 0.01%, #006E86 100%)',
  borderRadius: 'lg',
  color: 'white',
  fontFamily: 'Caveman',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  h: '7',
  lineHeight: '6',
  ml: 'auto',
  minW: 'auto',
  px: '7',
  textTransform: 'uppercase',
  boxShadow:
    '0px 0px 0px 3px #E57D24, 0px 0px 0px 2px #003541 inset, 0px 0px 0px 3px rgba(0, 209, 255, 0.70) inset',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
  overflow: 'hidden',
  _hover: {
    bg: 'alpha.800',
  },
  _focus: {
    bg: 'alpha.800',
  },
};

export const TextMustBeVerified: CSSObject = {
  paddingRight: ['10px', null, null],
};
