import {
  chakra,
  Flex,
  Badge,
  Text,
  DividerProps,
  ChakraComponent,
  Divider,
} from '@chakra-ui/react';
import { styleImports } from './MyBetsCard.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const CardContainer = chakra('div', {
  label: 'myBetsCard-CardContainer',
  baseStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textDecoration: 'unset',
    borderRadius: 'md',
    border: '1px',
    borderColor: 'gray.200',

    '&:hover': {
      textDecoration: 'unset',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.CardContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CardMainContent = chakra<any>('div', {
  label: 'myBetsCard-CardMainContent',
  shouldForwardProp: (prop) => !['isWon'].includes(prop),
  baseStyle: () => ({
    borderTopRadius: 'lg',
    position: 'relative',
    p: '1.5',

    ...(() => {
      try {
        return styleImports[themeName]?.CardMainContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ContentRow = chakra(Flex, {
  label: 'myBetsCard-ContentRow',
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '&:last-child': {
      marginBottom: '0',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.ContentRow;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const dividerExoticProps: DividerProps = {
  'data-testid': 'dividerExoticProps',
  ...(() => {
    try {
      return styleImports[themeName]?.dividerExoticProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const dividerExoticLastProps: DividerProps = {
  'data-testid': 'dividerExoticLastProps',
  ...(() => {
    try {
      return styleImports[themeName]?.dividerExoticLastProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const ExoticSelectionText = chakra(Text, {
  label: 'myBetsCard-ExoticSelectionText',
  baseStyle: () => ({
    color: 'gray.600',
    fontWeight: 'normal',

    ...(() => {
      try {
        return styleImports[themeName]?.ExoticSelectionText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const StatusBadge = chakra(Badge, {
  label: 'myBetsCard-StatusBadge',
  baseStyle: () => ({
    bg: 'gray.500',
    color: 'white',
    borderRadius: 'sm',
    py: '1',
    px: '2',
    top: '2',
    right: '5',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  }),
});

export const BodyText = chakra('span', {
  label: 'myBetsCard-BodyText',
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'bold',
    fontFamily: 'body',
    textTransform: 'capitalize',
  }),
});

export const PayoutText = chakra('span', {
  label: 'myBetsCard-PayoutText',
  baseStyle: () => ({
    fontSize: 'xs',
    fontFamily: 'heading',
    fontStyle: 'italic',
  }),
});

export const WonText = chakra('span', {
  label: 'myBetsCard-WonText',
  baseStyle: () => ({
    fontFamily: 'heading',
    fontSize: 'xs',
    fontStyle: 'italic',
    fontWeight: 'bold',
    lineHeight: '7',
  }),
});

export const CardBottomContent = chakra(Flex, {
  label: 'myBetsCard-CardBottomContent',
  baseStyle: () => ({
    position: 'relative',
    borderBottomRadius: 'lg',

    ...(() => {
      try {
        return styleImports[themeName]?.CardBottomContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DividerCard: ChakraComponent<'div', DividerProps> = chakra(
  Divider,
  {
    label: 'myBetsCard-DividerCard',
    baseStyle: () => ({
      mx: '1.5',
      w: 'unset',
      ...(() => {
        try {
          return styleImports[themeName]?.DividerCard;
        } catch (err) {
          return {};
        }
      })(),
    }),
  }
);
