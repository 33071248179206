import React, { Fragment, useRef, useState } from 'react';
import { Sword } from '@styled-icons/remix-fill';
import { Box, Icon, Skeleton } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ChevronRight, ChevronLeft } from '@styled-icons/bootstrap';
import { useNavigate } from 'react-router-dom';
import { useQueryOfferings } from '../../../../api/offerings/offerings.hooks';
import {
  BoxWrapper,
  Btn,
  BtnScroll,
  FlexScrollWrapper,
  Separator,
  Txt,
} from './styles/BtnOfferings.styles';
import IconSvg from '../../../../components/common/IconSvg';
import { getIconBySportName } from '@/helpers/utils';
import { ERaceType } from '../../../../lib/DBModels';

export default function BtnOfferings() {
  const { data, isInitialLoading } = useQueryOfferings();
  const refWrapper = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  return (
    <BoxWrapper className={showLeftButton ? 'hasLeftButton' : ''}>
      <FlexScrollWrapper
        ref={refWrapper}
        onScroll={() => {
          const { scrollLeft, clientWidth, scrollWidth } =
            refWrapper.current ?? {};

          const scrollPercentage =
            ((scrollLeft ?? 0) / ((scrollWidth ?? 0) - (clientWidth ?? 0))) *
            100;

          setShowLeftButton(scrollPercentage > 50);
          setShowRightButton(scrollPercentage < 50);
        }}
      >
        {(() => {
          if (isInitialLoading) {
            return [...new Array(10)].map((_, i) => (
              <Box key={`skel-load-btns-${i}`} h="84px">
                <Skeleton boxSize="55px" />
              </Box>
            ));
          }

          return (
            <>
              {[...((data?.offered_race_types ?? []) as string[])]
                ?.sort((a, b) => a?.localeCompare(b ?? ''))
                ?.reverse()
                ?.map((race) => (
                  <Box
                    key={`${race}-btn-offerings-race`}
                    sx={{ textAlign: 'center' }}
                  >
                    <Btn
                      aria-label="btn-race"
                      icon={<IconSvg name={getIconBySportName(race)} />}
                      onClick={() => navigate(`/Racing/Today?raceType=${race}`)}
                    />
                    <Txt>
                      <FormattedMessage
                        id={(() => {
                          if (race === ERaceType.Horse)
                            return 'racing.racemeetings.horses';
                          if (race === ERaceType.Harness)
                            return 'racing.racemeetings.harness';
                          return 'racing.racemeetings.greys';
                        })()}
                      />
                    </Txt>
                  </Box>
                ))}
              {[...(data?.offered_sports ?? [])]
                ?.sort((a, b) =>
                  (a?.display_name ?? '')?.localeCompare(b?.display_name ?? '')
                )
                ?.map((sport, i) => (
                  <>
                    {i === 0 && (
                      <Fragment key="all-sport-btn">
                        <Separator />
                        <Box sx={{ textAlign: 'center' }}>
                          <Btn
                            aria-label="all-sport"
                            icon={<Icon as={Sword} />}
                            onClick={() => navigate('/sports')}
                          />

                          <Txt>All Sports</Txt>
                        </Box>
                      </Fragment>
                    )}

                    <Box
                      key={`${sport?.sport_id}-btn-offerings-race`}
                      sx={{ textAlign: 'center' }}
                    >
                      <Btn
                        aria-label="btn-sport"
                        icon={
                          <IconSvg
                            name={getIconBySportName(sport?.display_name)}
                          />
                        }
                        onClick={() =>
                          navigate(
                            `/sports/${sport?.display_name}?sportId=${sport?.sport_id}`
                          )
                        }
                      />
                      <Txt>{sport?.display_name}</Txt>
                    </Box>
                  </>
                ))}
            </>
          );
        })()}
      </FlexScrollWrapper>

      <BtnScroll
        aria-label="scrollBtnLeft"
        icon={<Icon as={ChevronLeft} />}
        onClick={() => {
          const { scrollLeft, clientWidth } = refWrapper.current ?? {};

          refWrapper?.current?.scroll({
            left: (scrollLeft ?? 0) - (clientWidth ?? 0),
            behavior: 'smooth',
          });
        }}
        isVisible={showLeftButton}
        isLeft
      />
      <BtnScroll
        aria-label="scrollBtnRight"
        icon={<Icon as={ChevronRight} />}
        onClick={() => {
          const { scrollLeft, clientWidth } = refWrapper.current ?? {};

          refWrapper?.current?.scroll({
            left: (scrollLeft ?? 0) + (clientWidth ?? 0),
            behavior: 'smooth',
          });
        }}
        isVisible={showRightButton}
      />
    </BoxWrapper>
  );
}
