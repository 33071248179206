import React from 'react';
import NotAuthButtonsV1 from '../components/NotAuthButtons.v1';
import AuthedV1 from '../components/Authed.v1';
import { combineTemplate } from '@/lib/templateSwitch';
import DefaultTemplate from './template.default';

const NotAuthButtons = combineTemplate(DefaultTemplate.NotAuthButtons, [
  {
    name: 'comNotAuthButtons',
    component: <NotAuthButtonsV1 />,
  },
]);

const Authed = combineTemplate(DefaultTemplate.Authed, [
  {
    name: 'comAuthedContent',
    component: <AuthedV1 />,
  },
]);

export default {
  NotAuthButtons,
  Authed,
};
