import { EThemesOG, THEME_CONFIG } from '@/constants/themeConfig';

const { REACT_APP_THEME, REACT_APP_IS_PROD } = window.BETCLOUD_ENV;

/**
 * @deprecated Do not use this
 */
export const getThemeName = () => {
  const themeName =
    REACT_APP_IS_PROD !== 'true' &&
    ((window.MobileApp_Theme as unknown as EThemesOG) ??
      (localStorage.getItem('themeName') as unknown as EThemesOG | undefined));

  return (
    THEME_CONFIG?.[
      (themeName || (REACT_APP_THEME as unknown as EThemesOG)) ?? EThemesOG.Base
    ]?.name ?? 'base'
  );
};
