import { CSSObject } from '@chakra-ui/react';

export const IconPerson: CSSObject = {
  boxSize: '5',
  mr: '1',
  color: 'beta.600',
};

export const TextAccordion: CSSObject = {
  flex: '1',
  textAlign: 'left',
  fontWeight: 'medium',
  color: 'beta.600',
  fontSize: 'sm',
};

export const FlexContactToChange: CSSObject = {
  bg: 'beta.500',
  p: '2',
  boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.10)',
  borderRadius: 'base',
  w: 'full',
  color: 'white',
  fontSize: 'xs',
  gap: '2',
  alignItems: 'center',
};

export const Accordion: CSSObject = {
  bg: 'gray.200',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  overflow: 'hidden',
  position: 'relative',
  zIndex: '1',
  '.chakra-accordion__item': {
    _first: {
      borderTop: '0',
    },
    _last: {
      borderBottom: '0',
    },
  },
};

export const AccordionHeader: CSSObject = {
  bg: 'white',
  borderBottom: '1px',
  borderBottomColor: 'gray.300',
  color: 'beta.600',
  fontSize: 'sm',
  fontWeight: 'semibold',
  textAlign: 'left',
  '.chakra-accordion__icon': {
    color: 'beta.600',
  },
};

export const AccordionHeaderLogOut: CSSObject = {
  borderRadius: 'base',
  border: 'none',
};

export const OverviewAccordionItem: CSSObject = {
  border: 'none',
};

export const OverviewPromotionsPanel: CSSObject = {
  p: '0',
  bg: 'gray.200',

  '.chakra-checkbox__control': {
    borderRadius: 'xs',
    border: '1px',
    borderColor: 'black',
  },
};
