import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';
import background from '@/assets/juicybet/images/backgrounds/melonPattern.png';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: 'xl',
    px: '3',
    h: '9',
    display: 'flex',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: 'alpha.50',
    bgImage: `url(${background})`,
    alignItems: 'center',
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.30) inset',
    sx: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
        gap: '1.5',
      },
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    textDecorationColor: 'white',
    gap: '1.5',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'white',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
