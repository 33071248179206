import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  color: 'alpha.700',
  borderRadius: 'md',
  p: '2',
  bg: 'var(--chakra-white-alpha-700, rgba(255, 255, 255, 0.64))',
  mb: '2',
};

export const RunnerName: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'alpha.700',
  textTransform: 'capitalize',
};

export const FlexDeductionsContainer: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'alpha.500',
  textTransform: 'uppercase',
  flexDir: 'column-reverse',
};

export const TextDeductions: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'alpha.500',
};

export const ScratchedText: CSSObject = {
  fontWeight: 'semibold',
  color: 'alpha.500',
};

export const TextScratchedInfo: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'alpha.500',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  borderColor: 'alpha.500',
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1.5',
};

export const RunnerPropositionWrapper: CSSObject = {
  columnGap: '1',
  maxW: '14',
};

export const TextBarrierNumber: CSSObject = {
  color: 'alpha.400',
};
