import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  heading: {
    mb: '2',
    color: 'white',
    fontWeight: '700',
  },
  promotionWrapper: {
    pb: '0',
    bg: '#4B5A6D',
    my: '3',
    boxShadow: 'lg',
    borderRadius: 'sm',
  },
  flexWrapper: { p: '4', gap: '4', py: '4', justifyContent: 'space-between' },
  termsAndConditions: { color: 'white' },
};
