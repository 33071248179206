import { CSSObject } from '@chakra-ui/react';

export const SideNavHeading: CSSObject = {
  fontWeight: 'normal',
};

export const FlexSideLinkWrapper: CSSObject = {
  pl: ['2', '2', '1', null],
  filter: 'drop-shadow(0px 4px 4px 0px var(--bc-colors-blackAlpha-500))',
};
