import React, { ReactNode } from 'react';
import {
  QueryClientProvider,
  QueryClientProviderProps,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IS_MOBILE_APP } from '../../constants/isMobileApp';

type TQuery = {
  children: ReactNode;
  client: QueryClientProviderProps['client'];
};

/**
 * React Query Provider
 */
export default function Query({ children, client }: TQuery) {
  return (
    <QueryClientProvider client={client}>
      {children}
      {!IS_MOBILE_APP && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}
