import React from 'react';
import { getStrings } from '@/helpers/utils';
import Form from './Components/Form';
import FormikHOC from './Components/FormikHOC';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { FormWrapper } from '../PromotionsNotifications/styles/PromotionsNotifications.styles';

const CloseAccount: React.FC = () => {
  const [
    {
      Account: {
        CloseAccount: { title },
      },
    },
  ] = getStrings();

  return (
    <AccountWrapper pageTitle={title} pageHeader={title}>
      <FormikHOC>
        <FormWrapper>
          <Form />
        </FormWrapper>
      </FormikHOC>
    </AccountWrapper>
  );
};

export default CloseAccount;
