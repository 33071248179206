/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useSelection } from '../../services/Exotics.hooks';
import { TAction } from '../../Exotics.types';
import { useAppSelector } from '@/hooks/useRedux';
import { EGeneralStatus } from '@/lib/DBModels';
import {
  ActionsContainer,
  exoticsActionStyles,
  FlexOdds,
  HStackActions,
} from './styles/Action.styles';
import { useRaceMarkets } from '../../../../services/RaceDetails.hooks';
import { createTemplate } from '@/helpers/createTemplate';
import template from '../BoxedButton/templates/template';
import { Button } from '@/components/Button/Button';
import { actions, useIsToteMultiSelected } from '@/store/ToteMultis';
import { useIsToteMulti } from '../../services/Exotics.utils';

export default function Action({ runner }: TAction) {
  const { selected, isBoxed } = useAppSelector((state) => state.exotics);
  const { raceNumber } = useParams();
  const { updateSelections } = actions;

  const isToteMulti = useIsToteMulti();

  const toteMultiSelected = useIsToteMultiSelected(
    raceNumber as string,
    runner?.number ?? 0
  );

  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const { exoticMarket } = useRaceMarkets();
  const { selectAllPositions, selectSinglePosition, isChecked, positions } =
    useSelection(runner);

  const isDisabled =
    raceRunnerList?.raceMeta?.status !== EGeneralStatus.Open ||
    runner?.status !== EGeneralStatus.Open ||
    exoticMarket.isClosed ||
    exoticMarket.isSuspended;

  if (!runner) return null;

  if (isBoxed) {
    return (
      <HStackActions>
        {!isDisabled && (
          <FlexOdds>
            <Text>{runner.win_odds?.toFixed(2)}</Text>
          </FlexOdds>
        )}
        {createTemplate(
          template(isChecked(), isDisabled, selectAllPositions, runner.number)
        )}
      </HStackActions>
    );
  }

  return (
    <HStackActions>
      {!isDisabled && !isToteMulti && (
        <FlexOdds>{runner.win_odds?.toFixed(2)}</FlexOdds>
      )}
      <ActionsContainer>
        {isToteMulti && (
          <Button
            {...exoticsActionStyles.buttonExoticsActionProps}
            area-selected={toteMultiSelected ? 'true' : 'false'}
            isSelected={toteMultiSelected}
            onClick={() => updateSelections(raceNumber, runner.number)}
            data-active={toteMultiSelected}
          >
            <Text fontSize="sm">S-TAB</Text>
          </Button>
        )}
        {!isToteMulti &&
          positions.map((v, i: number) => {
            const index = i + 1;
            const isSelected = selected[index].includes(
              String(runner?.number ?? 0)
            );

            return (
              <Button
                {...exoticsActionStyles.buttonExoticsActionProps}
                area-selected={isSelected ? 'true' : 'false'}
                key={i}
                data-cy={`selectPlace-${i + 1}`}
                isSelected={isSelected}
                isDisabled={isDisabled}
                onClick={() => selectSinglePosition(index, isSelected)}
                data-active={isSelected}
              >
                <Text fontSize={exoticMarket.isSuspended ? 'xs' : 'sm'}>
                  {(() => {
                    if (exoticMarket.isSuspended)
                      return <FormattedMessage id="generic.sus" />;

                    return index;
                  })()}
                </Text>
              </Button>
            );
          })}
      </ActionsContainer>
    </HStackActions>
  );
}
