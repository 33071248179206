import React, { ReactNode } from 'react';
import { BoxHeadingWrapper, TextHeading, TextSubtitle } from './Title.styles';

type TTitle =
  | {
      children: ReactNode;
      title?: never;
      subTitle?: never;
    }
  | {
      title: ReactNode;
      subTitle: ReactNode;
      children?: never;
    };

/**
 * By default this excepts a children prop, but passing in
 * title | subtitle will force this prop to accept them and reject children.
 * It just keeps it easier for developers to understand at quick glance.
 */
export default function Title({ children, title, subTitle }: TTitle) {
  return (
    <BoxHeadingWrapper>
      <TextHeading>{children ?? title}</TextHeading>
      {subTitle && <TextSubtitle>{subTitle}</TextSubtitle>}
    </BoxHeadingWrapper>
  );
}
