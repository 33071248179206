import React, { forwardRef } from 'react';
import {
  Box,
  chakra,
  ChakraComponent,
  CSSObject,
  Flex,
  FlexProps,
  HStack,
  IconProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './Forms.styles.imports';
import Input, { TIndex } from '../../../../../FormElements/Input';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type FormSchema = Partial<{
  flexBoostedOddsWrapper: FlexProps;
  iconArrowUp: CSSObject;
  textBoostedOdds: TextProps;
  iconTreasureChest: IconProps;
}>;

export const formStyles: FormSchema = {
  flexBoostedOddsWrapper: {
    'data-testid': 'srmInfo-flexBoostedOddsWrapper',
    flexDir: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'blackAlpha.300',
    height: '6',
    mx: '-1',
    mt: '1',
    mb: '-2',
    gap: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.flexBoostedOddsWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconArrowUp: {
    'data-testid': 'srmInfo-iconArrowUp',
    backgroundColor: 'green.300',
    borderRadius: '50%',
    // This should be blackAlpha 300, but the background gives the icon a green tint so it needs an explicit hexcode.
    color: '#265475',
    padding: '0.5',
    boxSize: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.iconArrowUp;
      } catch (err) {
        return {};
      }
    })(),
  },
  textBoostedOdds: {
    'data-testid': 'srmInfo-textBoostedOdds',
    color: 'white',
    fontSize: '2xs',
    ...(() => {
      try {
        return styleImports[themeName]?.srmInfoStyles?.textBoostedOdds;
      } catch (err) {
        return {};
      }
    })(),
  },
  iconTreasureChest: {
    'data-testid': 'srmInfo-treasureChestIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.TreasureChestIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
};

export const FormsHStack = chakra(HStack, {
  label: 'FormsHStack',
  baseStyle: () => ({
    spacing: '2',
    px: '3',
    pb: '2',
    alignItems: 'start',
    w: 'full',
    ...(() => {
      try {
        return styleImports[themeName]?.FormsHStack;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FormText = chakra(Text, {
  label: 'FormText',
  baseStyle: () => ({
    my: '2',
    fontSize: 'md',
  }),
});

export const EachWayText = chakra(Text, {
  label: 'EachWayText',
  baseStyle: () => ({
    fontSize: 'xs',
    ...(() => {
      try {
        return styleImports[themeName]?.EachWayText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const StakeInput = chakra(Flex, {
  label: 'forms-StakeInput',
  baseStyle: () => ({
    flex: '2',
  }),
});

type InputAddonType = { isPrefix?: boolean } & FlexProps & any;

export const ThemedInputAddon = chakra<InputAddonType>(Flex, {
  label: 'betslip-ThemedInputAddon',
  shouldForwardProp: (prop) => !['isPrefix'].includes(prop),
  baseStyle: ({ isPrefix }: any) => ({
    alignItems: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    borderTopLeftRadius: 'sm',
    borderBottomLeftRadius: 'sm',
    transition: '.2s',
    textColor: 'blackAlpha.900',
    height: '9',
    width: '20',
    justifyContent: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.ThemedInputAddon?.(isPrefix) ?? null;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', InputAddonType>;

const CustomInput = forwardRef((props: TIndex, ref: any) => (
  <Input {...props} ref={ref as any} />
));
export const InputBetSlip = chakra(CustomInput, {
  // if changing label please see
  // src/components/FormElements/HOCs/FormControl.styles.betprofessor.ts
  label: 'forms-InputBetSlip',
  baseStyle: () => ({
    height: '9',
    fontSize: 'md',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.InputBetSlip;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ReviewContainer = chakra(Flex, {
  label: 'forms-ReviewContainer',
  baseStyle: () => ({
    textAlign: 'center',
    width: '100%',
    flexDir: 'column',
    py: '2',
    px: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.ReviewContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxReviewStakeContainer = chakra(Box, {
  label: 'forms-BoxReviewStakeContainer',
  baseStyle: () => ({
    borderBottom: '2px dashed',
    borderColor: 'gray.200',
    pb: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxReviewStakeContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ReviewStakeText = chakra(Text, {
  label: 'forms-ReviewStakeText',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ReviewStakeText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type ReviewStakeProps = {
  oddsHaveBeenBoosted: boolean;
} & TextProps &
  any;

export const ReviewStake = chakra<ReviewStakeProps>(Text, {
  label: 'forms-ReviewStake',
  shouldForwardProp: (prop) => !['oddsHaveBeenBoosted'].includes(prop),
  baseStyle: ({ oddsHaveBeenBoosted }: ReviewStakeProps) => ({
    // TODO: once we have designs, move any new styles to theme specific files
    ...(oddsHaveBeenBoosted && {
      opacity: 0.5,
      textDecoration: 'line-through',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.ReviewStake;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ReviewEstText = chakra(Text, {
  label: 'forms-ReviewEstText',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ReviewEstText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type ReviewEstProps = {
  oddsHaveBeenBoosted: boolean;
} & TextProps &
  any;

export const ReviewEst = chakra<ReviewEstProps>(Text, {
  label: 'forms-ReviewEst',
  shouldForwardProp: (prop) => !['oddsHaveBeenBoosted'].includes(prop),
  baseStyle: ({ oddsHaveBeenBoosted }: ReviewEstProps) => ({
    // TODO: once we have designs, move any new styles to theme specific files
    ...(oddsHaveBeenBoosted && {
      opacity: 0.5,
      textDecoration: 'line-through',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.ReviewEst;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexiReview = chakra(Flex, {
  label: 'forms-FlexiReview',
  baseStyle: () => ({
    pr: '1',
    width: '100%',
    flexDir: 'column',
    pl: '9',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexiReview;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexReviewExotic = chakra(Flex, {
  label: 'forms-FlexReviewExotic1',
  baseStyle: () => ({
    pr: '1',
    textAlign: 'right',
    width: '100%',
    flexDir: 'column',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexReviewExotic;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
