import { Box } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useNTG } from '../../../../../hooks/useNTG';
import ListItem from '../ListItem/ListItem';
import Loading from '../Loading';
import {
  BoxSectionWrapper,
  BoxWrapper,
  TextHeading,
  ButtonViewMore,
} from '../Upcoming/styles/Upcoming.styles';

export default function Offerings() {
  const { queries } = useNTG();
  const navigate = useNavigate();

  return (
    <>
      {queries?.map((query) => {
        const { data, isInitialLoading } = query;
        if (!data?.length) return null;
        const sportName = data[0]?.sport_name;
        const sportId = data[0]?.sport_id;

        return (
          <BoxSectionWrapper key={`offering-${sportName}`}>
            <BoxWrapper>
              <TextHeading>{sportName}</TextHeading>
            </BoxWrapper>

            <Box>
              {(() => {
                if (isInitialLoading) return <Loading />;

                return [...(data ?? [])]
                  ?.slice(0, 5)
                  ?.map((m) => <ListItem key={m.match_id} match={m} />);
              })()}
            </Box>

            <ButtonViewMore
              variant="unstyled"
              onClick={() =>
                navigate(`/sports/${sportName}?sportId=${sportId}`)
              }
            >
              <FormattedMessage id="generic.viewMore" />
            </ButtonViewMore>
          </BoxSectionWrapper>
        );
      })}
    </>
  );
}
