import Black from '@/assets/bet575/fonts/Roboto-Black.ttf';
import BlackItalic from '@/assets/bet575/fonts/Roboto-BlackItalic.ttf';
import Bold from '@/assets/bet575/fonts/Roboto-Bold.ttf';
import BoldItalic from '@/assets/bet575/fonts/Roboto-BoldItalic.ttf';
import ExtraLight from '@/assets/bet575/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '@/assets/bet575/fonts/Roboto-ThinItalic.ttf';
import Light from '@/assets/bet575/fonts/Roboto-Light.ttf';
import LightItalic from '@/assets/bet575/fonts/Roboto-LightItalic.ttf';
import Regular from '@/assets/bet575/fonts/Roboto-Regular.ttf';
import Italic from '@/assets/bet575/fonts/Roboto-Italic.ttf';
import SemiBold from '@/assets/bet575/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '@/assets/bet575/fonts/Roboto-MediumItalic.ttf';
import KHInterferenceRegular from '@/assets/bet575/fonts/KHInterference-Regular.otf';
import KHInterferenceLight from '@/assets/bet575/fonts/KHInterference-Light.otf';
import KHInterferenceBold from '@/assets/bet575/fonts/KHInterference-Bold.otf';

export const fontFace = `
        @font-face {
          font-family: 'KHInterference';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${KHInterferenceRegular}') format('opentype');
        }
        @font-face {
          font-family: 'KHInterference';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${KHInterferenceLight}') format('opentype');
        }
        @font-face {
          font-family: 'KHInterference';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${KHInterferenceBold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLight}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${Light}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 300;
          font-display: swap;
          src: url('${LightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${Regular}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 400;
          font-display: swap;
          src: url('${Italic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${Bold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 700;
          font-display: swap;
          src: url('${BoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${Black}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 900;
          font-display: swap;
          src: url('${BlackItalic}') format('opentype');
        }
      `;
