import { CSSObject } from '@chakra-ui/react';

export const ButtonDeposit: CSSObject = {
  bg: 'alpha.500',

  '&:hover, &:focus': {
    bg: 'alpha.700',
    boxShadow: 'none',
  },
};
