import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  borderStyle: 'dashed',
  borderBottomColor: 'whiteAlpha.300',
  display: 'flex',
  justifyContent: 'space-between',
  mb: '2',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
};
