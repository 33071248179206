import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: '#5B7CBB',
  borderTopRadius: 'base',
  mt: ['unset', '4'],
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  border: '2px',
  borderColor: '#FFE6B6',
  borderBottom: 'none',
  py: '4',
  pb: '0',
  w: 'unset',
  '&&': { px: ['2.5', '3'], pb: ['1', null, '0'], mx: ['2', '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'bold',
  fontSize: 'md',
  textTransform: 'uppercase',
  color: 'beta.300',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Fraunces',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
};

export const WrapperContainer: CSSObject = {
  px: ['2.5', null, '3'],
  pb: '4',
  bgGradient: 'linear(to-b, #5B7CBB, #5B7CBB)',
  borderTopRadius: 'none',
  borderBottomRadius: 'base',
  border: '2px',
  borderColor: '#FFE6B6',
  borderTop: 'none',
  color: 'delta.50',
  mb: '3',
  mx: ['2', '0'],
};
