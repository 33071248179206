import React from 'react';
import {
  EmptyMultiIcon,
  InvalidityBox,
  UnableToCreateMultiWrapper,
} from '../../../MultiCard/Components/MultiCard.styles';

interface IEmptyMoMProps {
  reason: string;
}

export function EmptyMoM({ reason }: IEmptyMoMProps) {
  return (
    <UnableToCreateMultiWrapper>
      <EmptyMultiIcon boxSize="5" name="emptyMultiIcon" />
      <InvalidityBox>{reason}</InvalidityBox>
    </UnableToCreateMultiWrapper>
  );
}
