import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { getStrings } from '@/helpers/utils';
import Grid from '../../../components/Grid/Grid';
import { GreenIDContainer, GreenIDText } from './styles/VerifyIdentity.styles';
import { useVerifyIdentity } from './Services/Hooks.VerifyIdentity';
import { GREENID_CSS_URL } from '../../../lib/Constants';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { EGreenIDStatus } from './Services/Config.VerifyIdentity';
import { isVerified } from '../Overview/services/Overview.utils';
import { useAppSelector } from '../../../hooks/useRedux';

const VerifyIdentity: FC = () => {
  const [
    {
      Account: { VerifyIdentity: VerifyIdentityStrings },
    },
  ] = getStrings();

  useVerifyIdentity(true); // 👀 this handles loading in green ID
  const { verificationStatus } = useAppSelector((state) => state.punter);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href={GREENID_CSS_URL}
        />
      </Helmet>

      <AccountWrapper
        pageTitle={VerifyIdentityStrings.PageTitle}
        pageHeader={VerifyIdentityStrings.Header}
      >
        <Grid row>
          <Grid column sm={12} md={6}>
            {isVerified(verificationStatus) && (
              <GreenIDText>{VerifyIdentityStrings.StatusComplete}</GreenIDText>
            )}

            {verificationStatus === EGreenIDStatus.IN_PROGRESS && (
              <GreenIDText>
                {VerifyIdentityStrings.StatusInProgress}
              </GreenIDText>
            )}

            {verificationStatus === EGreenIDStatus.PENDING && (
              <GreenIDText>{VerifyIdentityStrings.StatusPending}</GreenIDText>
            )}

            {!isVerified(verificationStatus) && (
              <GreenIDContainer id="greenID" />
            )}
          </Grid>
        </Grid>
      </AccountWrapper>
    </>
  );
};

export default VerifyIdentity;
