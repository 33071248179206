import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/sterlingparker/images/logoDesktop.svg';
import logoMobile from '@/assets/sterlingparker/images/logoMobile.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function Index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          alt={`${THEME_NAME.sterlingparker}`}
          mx="1"
          mb="22px"
          mt="1"
          w="auto"
          h="70px"
          pos="relative"
          left="-0.5"
          top="1.5"
        />
      </Show>
      <Hide above="lg">
        <Image
          src={logoMobile}
          alt={`${THEME_NAME.sterlingparker}`}
          position="relative"
          top="1"
          left="-1.5"
          w="auto"
          h="9"
        />
      </Hide>
    </Link>
  );
}
