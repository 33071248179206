import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/sugarcastle/images/backgrounds/onboardingAside.png';
import bgMobile from '@/assets/sugarcastle/images/backgrounds/onboardingAsideMb.png';

export const WrapperAside: CSSObject = {
  bg: [`url(${bgMobile}), #13274B`, null, `url(${bgDesktop}), #13274B`],
  bgSize: ['100% 100%, auto', '100% 100%, auto', '100% , auto'],
  bgPos: 'center calc(100%), center calc(100% - 76px)',
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['85vw', '70vw', 'full'],
  borderLeftRadius: [null, null, '10px'],
  borderRightRadius: ['unset'],
  pt: ['6', null, '44'],
  flex: 1,
};
