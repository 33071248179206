import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import {
  BoxReviewStakeContainer,
  ReviewContainer,
  ReviewEst,
  ReviewEstText,
  ReviewStake,
  ReviewStakeText,
} from '../../../BetCard/components/Forms/Forms.styles';

interface IReviewBetDetailsProps {
  stake: string;
  estimatedReturn: string;
}

export function ReviewBetDetails({
  stake,
  estimatedReturn,
}: IReviewBetDetailsProps) {
  return (
    <ReviewContainer>
      <BoxReviewStakeContainer>
        <ReviewStakeText>
          <FormattedMessage id="generic.stake" />
        </ReviewStakeText>
        <ReviewStake>{stake}</ReviewStake>
      </BoxReviewStakeContainer>
      <Box pt="2">
        <ReviewEstText>
          <FormattedMessage id="betSlip.potentialReturns" />
        </ReviewEstText>
        <Flex justifyContent="center" flexDirection="column">
          <ReviewEst>{estimatedReturn}</ReviewEst>
        </Flex>
      </Box>
    </ReviewContainer>
  );
}
