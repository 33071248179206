import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};

export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  '&&&': {
    bg: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'green.500';
        case EGreenIDStatus.PENDING:
          return 'yellow.500';
        default:
          return 'red.500';
      }
    },
    borderRadius: 'base',
    boxShadow: '0 3px 3px rgba(0, 0, 0, .15)',
    border: '1px white solid',
    fontSize: '2xs',
    fontFamily: 'roboto',
    fontWeight: 'black',
    color: 'white',
    h: '18px',
    mb: ['1', null, '0'],
    px: '1',
    textTransform: 'none',
    textShadow: 'none',
    gap: '0',
  },

  _after: {
    content: `''`,
    backgroundImage: 'red.500',
  },

  _hover: {
    '&&&': {
      bg: () => {
        switch (status) {
          case EGreenIDStatus.VERIFIED:
          case EGreenIDStatus.VERIFIED_ADMIN:
          case EGreenIDStatus.VERIFIED_WITH_CHANGES:
            return 'green.600';
          case EGreenIDStatus.PENDING:
            return 'yellow.600';
          default:
            return 'red.600';
        }
      },
      color: 'white',
    },
    color: 'red',
  },

  _disabled: {
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
    cursor: 'default',
    opacity: '1',

    _hover: {
      bg: 'green.500',
    },
  },

  '.chakra-icon': {
    boxSize: '4',
  },
});
