import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: '100%',
    border: [null, null, null, '3px solid'],
    borderColor: [null, null, null, 'beta.100'],
    borderRadius: 'xl',
    pl: ['2', '0'],
    _last: {
      mt: '4',
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {},
};
