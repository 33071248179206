import React from 'react';
import dayjs from 'dayjs';
import { Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useNTG } from '../../../../../../hooks/useNTG';
import { ESportType } from '../../../../../../lib/DBModels';
import {
  FlexMiscWrapper,
  FlexTeamWrapper,
  FlexTextWrapper,
  puntButtonProps,
  TextMisc,
  TextTeam,
  TextVS,
} from '../styles/Featured.styles';
import { Button } from '@/components/Button/Button';
import { useQuerySportMatches } from '@/api/sportDetailsMatches/sportDetailsMatches.hooks';
import { queryCMS } from '@/api/cms/cms';
import { keys } from '@/api/api.keys';
import { TLinks } from '@/api/cms/cms.types';
import { startPassedEndNotPassed } from '@/helpers/utils';

export const FeaturedMatch = () => {
  const navigate = useNavigate();
  const { dataSorted: data } = useNTG({
    sports: [ESportType.Soccer],
  });

  const { data: cms } = useQuery([keys.cms], () => queryCMS());

  const filteredEvents: TLinks[] =
    cms
      ?.filter(
        (event) => event.is_event === true && event.is_sport_event === true
      )
      ?.filter((event) => startPassedEndNotPassed(event))
      ?.sort((a: TLinks, b: TLinks) => Number(a?.order) - Number(b?.order)) ??
    [];

  const customEvent = filteredEvents && filteredEvents[0];

  const matchesQuery = useQuerySportMatches(
    {
      competition_id: customEvent?.competition_id,
    },
    { enabled: !!customEvent }
  );

  const featuredCustomEvent = matchesQuery?.data?.find(
    (match) => match.match_id === customEvent?.event_id
  );

  const featuredMatch = featuredCustomEvent ?? data?.[0];

  const matchNameSplit = featuredMatch?.match_name?.split(/( vs | v )/i);
  const startTime = featuredMatch?.match_start_time;
  const compOrTournamentParam = featuredMatch?.tournament_id
    ? `tournamentId=${featuredMatch?.tournament_id}`
    : `competitionId=${featuredMatch?.competition_id}`;
  const to = `/sports/${encodeURIComponent(
    featuredMatch?.sport_name ?? ''
  )}/${encodeURIComponent(
    featuredMatch?.competition_name ?? ''
  )}/${encodeURIComponent(featuredMatch?.match_name ?? '')}?sportId=${
    featuredMatch?.sport_id
  }&${compOrTournamentParam}&matchId=${featuredMatch?.match_id}`;

  return (
    <FlexTextWrapper>
      <FlexTeamWrapper>
        {matchNameSplit?.map((n, i) => {
          if ([' vs ', ' v '].includes(n.toLocaleLowerCase())) {
            return <TextVS>vs</TextVS>;
          }

          return (
            <TextTeam key={`featured-name-${n}-${featuredMatch?.match_id}`}>
              {i === 0 ? n : ` ${n}`}
            </TextTeam>
          );
        })}
      </FlexTeamWrapper>

      <TextMisc sx={{ mb: '1' }}>
        {featuredMatch?.competition_name ?? featuredMatch?.tournament_name}
      </TextMisc>

      <FlexMiscWrapper>
        <TextMisc>
          {dayjs(startTime).format('DD MMM')} <Text as="span">|</Text>{' '}
          {dayjs(startTime).format('HH:mm')}
        </TextMisc>
        <Button {...puntButtonProps} onClick={() => navigate(to)}>
          <FormattedMessage id="home.haveAPunt" />
        </Button>
      </FlexMiscWrapper>
    </FlexTextWrapper>
  );
};
