import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import { CustomButtonProps } from '@/components/Button/Button';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';

export const SignUpDatePicker: CSSObject = {
  zIndex: 'popover',
};

export const SignupAcknowledgementButton: CSSObject = {
  color: 'alpha.300',
  fontWeight: 'semibold',
  textDecoration: 'none',
  textTransform: 'capitalize',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'alpha.300',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword
    ? 'var(--bc-colors-green-500) !important'
    : 'var(--bc-colors-red-500) !important',
  borderRadius: 'md',
  fontWeight: 'medium',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-accent-400)',
  color: 'white',
  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-green-500) !important'
      : 'var(--bc-colors-red-500) !important',
  },
});

export const PasswordRulesContainer: CSSObject = {
  borderRadius: 'md',
  fontFamily: 'body',
  fontWeight: 'medium',
  fontSize: 'xs',
  p: 'var(--bc-space-2) var(--bc-space-4)',
};

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active
    ? ['green.500', 'green.500', 'white']
    : ['red.500', 'white', null],
});

export const BoxManualAddressContainer: CSSObject = {
  color: 'black',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'beta.800',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
  'input, #state-select': {
    h: '9',
    borderRadius: 'sm',
    border: '1px',
    borderColor: 'beta.800',
    background: 'gray.50',
    display: 'flex',
    padding: '2.5',
    alignItems: 'center',
    gap: '1.5',
  },
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  fontFamily: 'body',
  color: 'beta.800',
  button: {
    h: 'unset',
    color: 'delta.500',
  },
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['0', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);

export const SignUpInput: CSSObject = {
  borderRadius: 'lg',
  alignSelf: 'stretch',
  h: '38px',
  '&&': {
    bg: 'gray.50',
    boxShadow: 'unset',
    borderWidth: '1px',
    borderStyle: ' solid',
    borderColor: 'gray.300',
    color: 'gray.700',
    fontWeight: '400',
    '&&:hover, &&:focus': {
      bg: 'gray.100',
      borderWidth: '1px',
      borderStyle: ' solid',
    },
    _placeholder: {
      color: 'gray.400',
    },
  },
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'beta.800',
  fontSize: 'xl',
  fontFamily: 'heading',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  mb: '2',
};

export const SignUpButton: CustomButtonProps = {
  mt: '2',
  mb: '0',
};
