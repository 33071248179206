import React from 'react';
import { Global } from '@emotion/react';
import pattern from '@/assets/goldenbet888/images/backgrounds/dragon.png';
import lantern from '@/assets/goldenbet888/images/backgrounds/lantern.png';
import squiggly from '@/assets/goldenbet888/images/backgrounds/squiggly.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${lantern}), url(${squiggly}), url(${squiggly}), url(${pattern}), linear-gradient(180deg, var(--bc-colors-alpha-300), var(--bc-colors-alpha-900))`,
          backgroundPosition:
            'top, left top, right top, top center, top center',
          backgroundRepeat: 'no-repeat, repeat-y, repeat-y, repeat',
          backgroundSize: '100vw 80vh, 20px 590px, 20px 590px, 320px, 100vw',
          '@media (max-width: 767px)': {
            backgroundImage: `url(${pattern}), linear-gradient(180deg, var(--bc-colors-alpha-300), var(--bc-colors-alpha-900))`,
            backgroundSize: '325px',
            backgroundPosition: 'top center,top center',
            backgroundRepeat: 'repeat',
          },
        },
      }}
    />
  );
}
