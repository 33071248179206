import { CSSObject } from '@chakra-ui/react';

export const LocationNotAllowedContainer: CSSObject = {
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100vh',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  lineHeight: '4',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgGradient: 'linear(to-b, delta.400, delta.600)',
  },
};

export const CenterWrapper: CSSObject = {
  bg: 'transparent',
  borderRadius: [null, null, 'xl'],
  color: 'white',
  flex: ['1', null, '0'],
  gap: '0',
  justifyContent: 'center',
  mt: ['0', null, '12'],
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.md',
  px: ['8', null, '28'],
  py: ['3', null, '8'],
};

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Chewy',
  fontSize: ['4xl', null, '6xl'],
  lineHeight: '1.2',
  mt: ['1.5', null, '4'],
  mb: ['2.5', null, '4'],
  filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,.3))',
  fontWeight: 'base',
  textTransform: 'capitalize',
  color: 'gamma.400',
  w: ['360px', null, '800px'],
  px: ['2.5', null, null],
};

export const ContentBodyText: CSSObject = {
  fontSize: ['md', 'lg'],
  lineHeight: 'tall',
  textAlign: 'center',
  fontWeight: 'normal',
  b: {
    display: 'block',
    fontSize: 'md',
    lineHeight: 'base',
    mb: '4',
  },

  '.chakra-link': {
    fontSize: ['2xs', 'xs'],
    fontWeight: 'bold',
  },
};

export const ContactText: CSSObject = {
  '&&': {
    fontSize: ['sm', null, 'lg'],
  },
};

export const TextBodyCopy: CSSObject = {
  lineHeight: '5',
  fontSize: ['sm', null, 'lg'],
  mt: '4',
  fontWeight: 'normal',
};

export const TextHeadingColor: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  bgClip: 'text',
  textShadow: 'none',
  display: 'flex',
};

export const RegistrationContent: CSSObject = {
  fontSize: ['xs', null],
  w: [null, null, '591px'],
  '.chakra-link': {
    fontSize: ['xs', null],
    fontWeight: 'bold',
  },
};
