import React from 'react';
import Aside from '../../components/Aside/Aside';
import Content from '@/components/forms/DepositLimit/DepositLimit';
import {
  WrapperContent,
  WrapperOnboarding,
} from '../../styles/onboarding.styles';
import CloseBtn from '../../components/CloseBtn/CloseBtn';
import Title from '../components/Title/Title';

export const TemplateDepositLimit = [
  { name: 'wrapper', component: <WrapperOnboarding /> },
  { name: 'aside', component: <Aside /> },
  {
    name: 'content',
    component: (
      <WrapperContent>
        <Title />
        <Content />
      </WrapperContent>
    ),
  },
  { name: 'closeBtn', component: <CloseBtn /> },
];

export default TemplateDepositLimit;
