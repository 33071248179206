import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { Box, Link, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { getStrings, renderDateMMDDYYYY } from '@/helpers/utils';
import Select from '../../../../components/FormElements/Select';

import { getBreakPeriods } from '../Services/Utils.TakeABreak';
import { TFormikValues } from '../types';
import { getThemeName } from '@/helpers/getThemeName';
import {
  buttonConfirmBreakProps,
  InputContainer,
  TakeABreakContainer,
  TextConfirmBreak,
  TextDate,
  TextDateLabel,
} from '../styles/TakeABreak.styles';
import Checkbox from '@/components/FormElements/Checkbox';
import { Button } from '@/components/Button/Button';

export default function Form() {
  const [
    {
      Account: { TakeABreak: TakeABreakStrings },
      Generic,
    },
  ] = getStrings();

  const { isSubmitting } = useFormikContext<TFormikValues>();

  const availablePeriods = useMemo(() => getBreakPeriods(), []);
  const today = renderDateMMDDYYYY(dayjs());

  return (
    <Box sx={{ maxW: 'container.xs' }}>
      <TakeABreakContainer>
        <Text as="strong">
          <FormattedMessage id="account.betStop.title" />
        </Text>

        <Text mt="1">
          <FormattedMessage
            id="account.betStop.description"
            values={{
              cta: (string: string) => (
                <Link
                  href="https://www.acma.gov.au/betstop-national-self-exclusion-registertm"
                  target="_blank"
                  display="inline-block"
                  textDecoration="underline"
                >
                  {string}
                </Link>
              ),
            }}
          />
        </Text>
      </TakeABreakContainer>
      <TakeABreakContainer>
        <TextDateLabel>{TakeABreakStrings.breakStartDate}</TextDateLabel>

        <TextDate>
          {TakeABreakStrings.today}, {today}
        </TextDate>
      </TakeABreakContainer>
      <Box mb="4">
        <InputContainer>
          <Select
            label={TakeABreakStrings.limitPeriod}
            name="period"
            data-cy="period"
            placeholder={Generic.SelectPlaceholder}
          >
            {availablePeriods.map((x, i) => (
              <option value={x.period} key={i.toString()}>
                {x.label}
              </option>
            ))}
          </Select>

          <TextConfirmBreak>{TakeABreakStrings.confirm}</TextConfirmBreak>

          <Checkbox name="confirmedPending">
            {TakeABreakStrings.confirmStepPendingBets.replace(
              /{themeName}/g,
              getThemeName()
            )}
          </Checkbox>

          <Checkbox name="confirmedImmediate">
            {TakeABreakStrings.confirmStepImmediately}
          </Checkbox>

          <Checkbox name="confirmedUndone">
            {TakeABreakStrings.confirmStepUndone}
          </Checkbox>
        </InputContainer>
      </Box>
      <Button
        {...buttonConfirmBreakProps}
        data-cy="confirmBreakButton"
        type="submit"
        isLoading={isSubmitting}
        isFullWidth
      >
        {!isSubmitting && TakeABreakStrings.confirmButton}
      </Button>
    </Box>
  );
}
