import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bgGradient: 'linear(to-b, rgba(97, 116, 182, 1), rgba(68, 85, 144, 1))',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  p: '3',
};
export const HeaderText: CSSObject = {
  color: 'alpha.400',
  fontSize: 'md',
  fontWeight: 'normal',
  p: '0',
  textTransform: 'none',
  fontFamily: 'accent',
};
export const HeaderContainer: CSSObject = {
  p: '0',
};
export const CloseButton: CSSObject = {
  display: 'none',
};
