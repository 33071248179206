import { CSSObject } from '@chakra-ui/react';
import { HeaderRowSchema } from './HeaderRow.styles';

export const HeaderSvg: CSSObject = {
  borderRadius: 'full',
  color: 'white',
  bg: 'beta.500',
  boxSize: '10',
  p: '1.5',
};

export const headerRowStyles: HeaderRowSchema = {
  headerRowSvg: {
    fill: 'white',
  },
};
