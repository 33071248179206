import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  border: '2px solid',
  borderColor: 'beta.500',
  borderRadius: 'md',
  boxShadow:
    '0px 1px 0px 1px rgba(255, 255, 255, 0.50) inset,0px 0px 0px 1px #000 inset',
  boxSize: '10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'beta.500',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bg: 'alpha.500',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset,0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: 'md',
  w: '100%',
  h: '64px',
  _hover: {
    bg: 'alpha.600',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'md',
  color: 'white',
  mt: '1.5',
  fontFamily: 'Staatliches',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'alpha.100',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'beta.500',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'alpha.100',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'alpha.100',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'white',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
