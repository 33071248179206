import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { mutatePricing } from './srm';
import { TSRMPricingResponse, TSRMultiPricingParams } from './srm.types';

export const useMutationSRMPricing = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TSRMPricingResponse>,
      AxiosError<unknown>,
      TSRMultiPricingParams
    >,
    'mutationFn'
  >
) => useMutation(mutatePricing, options);
