import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'alpha.500',
  px: 3,
  py: 2,
  mx: 2,
  mb: 2,
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'beta.500' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'alpha.100',
  fontSize: 'xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'beta.500',
  boxSize: '32px',
  boxShadow:
    '0px 0px 0px 1px #000 inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.50) inset',
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-300), var(--bc-colors-alpha-500))',
  border: '2px solid',
  borderColor: 'beta.500',
  borderRadius: 'md',
  p: '1',
};

export const IconChevron: CSSObject = {
  color: 'white',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: 'xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.200',
    boxSize: '5',
  },
};
