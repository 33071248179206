import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  width: 'unset',
  borderTopRadius: '6px',
  bg: 'var(--bc-beta-500, #30363F)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
  mt: ['unset', '4'],
  pb: '0',
  py: '4',
  '&&': { px: ['2', '4'], pb: ['1', null, '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
};

export const WrapperContainer: CSSObject = {
  border: 0,
  bg: 'beta.500',
  px: '0',
  pb: '4',
  mb: '3',
};
