import { CSSObject } from '@chakra-ui/react';

const baseFontFamily = 'body';
const baseFontWeight = 'bold';
const baseTextColor = 'gray.800';

export const GreenIDContainer: CSSObject = {
  '#greenid-intro-content #greenid-intro-words .lead': {
    _last: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'medium',
      fontSize: 'sm',
      color: 'gamma.600',
    },
  },

  '#greenid-container h2': {
    color: 'gamma.600',
  },

  '#greenid-container .btn-primary': {
    fontSize: 'xs',
    fontFamily: 'heading',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    border: '1px solid',
    borderColor: 'alpha.700',
    // TODO: #426DD6 is not in the theme
    boxShadow: 'inset 0px 0px 0px 2px #426DD6',
    borderRadius: 'lg',
    textColor: 'white',
    fontWeight: 'black',
    textTransform: 'uppercase',
    width: 'auto',

    '&:focus, &:hover': {
      backgroundColor: 'alpha.700',
      border: '1px solid',
      borderColor: 'alpha.500',
    },
  },

  '#greenid-source-content': {
    border: 'none !important',
  },

  // Source title styles
  '#greenid-source-content #greenid-source-header #greenid-source-title #greenid-source-title-heading':
    {
      fontFamily: baseFontFamily,
      fontWeight: `${baseFontWeight} !important`,
      color: baseTextColor,
    },

  // Form label styles
  '#greenid-source-body .row .form-group .control-label': {
    fontFamily: baseFontFamily,
    fontWeight: baseFontWeight,
    color: baseTextColor,
  },

  // Help icon styles
  '#greenid-source-body #greenid-FieldsForm .help-block': {
    fontFamily: baseFontFamily,
    fontWeight: baseFontWeight,
    color: baseTextColor,
  },

  '#greenid-source-content #greenid-source-header #greenid-source-title .greenid-modal-launch':
    {
      px: 0,
    },

  '#greenid-FieldsForm .control-label, #greenid-FieldsForm .greenid-helpicon, #greenid-source-title #greenid-source-title-heading, #greenid-source-title .greenid-modal-launch, #greenid-source-body .control-label':
    {
      color: baseTextColor,
    },
};
