import { Box, chakra, Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemes } from '@/constants/themeConfig';
import { styleImports } from './styles/Matches.styles.imports';

const themeName = getThemeName();

export const BackIcon = styled.div`
  cursor: pointer;

  ${({ theme }) => theme.pages.matches.backIcon};
`;

export const MatchesHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--bc-sizes-6);

  ${({ theme }) => theme.pages.matches.matchesHeadingContainer};
`;

export const MatchesHeading = styled.div`
  font-size: var(--bc-fontSizes-md);
  margin-left: var(--bc-sizes-1);

  ${({ theme }) => theme.pages.matches.matchesHeading};
`;

export const MatchesContainer = chakra(Flex, {
  label: 'MatchesContainer',
  baseStyle: ({ theme }) => ({
    flexWrap: 'wrap',
    flexDir: ['column', 'row'],
    rowGap: '2',
    columnGap: '5',
    px: [0, '3.5'],

    ...(theme.themeName === EThemes.Wellbet && {
      p: '5',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      pb: '4',
    }),
    ...(theme.themeName === EThemes.Volcanobet && {
      pb: '4',
    }),
    ...(theme.themeName === EThemes.Fiestabet && {
      pb: '4',
    }),
    ...(theme.themeName === EThemes.Puntgenie && {
      px: '2',
    }),
    ...(theme.themeName === EThemes.Vikingbet && {
      p: '4',
      pt: '0',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.MatchesContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxPageWrapper = chakra(Box, {
  baseStyle: () => ({
    p: '5',
    borderBottomRadius: 'lg',
    minH: '60vh',
  }),
});
