import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  sx: {
    '&&': {
      h: 10,
    },
  },
};

export const FlexWrapper: CSSObject = {
  bgGradient: 'linear(to-b, zeta.400, zeta.600)',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'alpha.700',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'alpha.400',
    border: '2px',
    borderColor: 'alpha.700',
    color: 'white',
    borderRightRadius: 'lg',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    fontWeight: 'semibold',
    _placeholder: {
      color: 'alpha.700',
    },
    '&:active, &:focus, &:visited': {
      color: 'white',
      bg: 'alpha.600',
      border: '2px',
      borderColor: 'alpha.700',
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    fontWeight: 'extrabold',
    bg: 'alpha.700',
    borderLeftRadius: 'lg',
    color: 'white',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const SelectionPopupContainer: CSSObject = {
  my: [null, null, '4'],
  mx: [null, null, '4'],
  mt: '4',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'base',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bgGradient: 'linear(to-b, zeta.400, zeta.600)',
  h: '7',
  px: '2',
  py: '1.5',
  _hover: {
    bgGradient: 'linear(to-b, #D89212, #D89212)',
  },
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'alpha.600',
  fontWeight: 'medium',
};

export const TextAddToBetslipButton: CSSObject = {
  letterSpacing: 'wider',
  w: '240px',
  fontWeight: 'normal',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  pos: 'relative',
  borderColor: 'blackAlpha.300',
  _notFirst: {
    borderTopStyle: 'dotted',
    borderTopWidth: '1px',
  },
  _before: {
    border: 'inherit',
    borderColor: 'whiteAlpha.300',
    content: '""',
    h: '1px',
    left: '0',
    pos: 'absolute',
    right: '0',
    top: '0',
  },
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'alpha.800',
};
