import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  borderWidth: '2px',
  borderColor: 'whiteAlpha.800',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  color: 'gamma.900',
  p: '2',
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  gap: '0',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'gamma.400',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  mb: '7',
  borderRadius: 'md',
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.25) inset',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};

export const InvalidityBox: CSSObject = {
  color: 'gamma.400',
  fontSize: 'xs',
  fontWeight: '500',
};
