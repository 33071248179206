import { Search } from '@styled-icons/bootstrap/Search';
import { CompetitionListSchema } from '@/views/sports/components/CompetitionList/styles/CompetitionList.styles';

export const competitionListStyles: CompetitionListSchema = {
  skeleton: {
    startColor: 'beta.300',
    endColor: 'beta.400',
    minW: 'full',
    h: '20',
    borderRadius: 'md',
    flex: 1,
    mt: '8',
  },
  input: {
    name: 'x',
    sxWrapper: { mb: '0' },
    bg: 'delta.800',
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
    borderColor: 'transparent',
    borderRadius: 'md',
    color: 'whiteAlpha.700',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '2xs',
    mb: '0',
    mx: ['1.5', '0', '0'],
    _hover: {
      bg: 'delta.800',
    },
    _focus: {
      boxShadow: 'xl',
      color: 'beta.500',
      textShadow: 'sm',
      bg: 'delta.800',
    },
  },
  inputRightIcon: {
    color: 'beta.500',
    boxSize: '4',
    as: Search,
  },
  accordion: {
    color: 'beta.800',
    mb: '3',
    variant: 'unstyled',
    w: 'full',
    px: ['1', '0', '0'],
    mt: '2',
    borderTopWidth: '1px',
    borderTopColor: 'blackAlpha.400',
    borderStyle: 'dashed',
    position: 'relative',
    _after: {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: 'full',
      borderTopWidth: '1px',
      borderTopColor: 'whiteAlpha.500',
      borderStyle: 'dashed',
    },
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    py: '1',
    px: '0',
    borderTop: '1px dashed',
    borderTopColor: 'blackAlpha.300',
    borderTopWidth: '1px',
    borderStyle: 'dashed',
    position: 'relative',
    _after: {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: 'full',
      borderTopWidth: '1px',
      borderTopColor: 'whiteAlpha.500',
      borderStyle: 'dashed',
    },
    boxShadow: 'none',
    _first: {
      borderTop: 'none',
    },
    _last: {
      borderBottom: 'none',
    },
  },
  accordionButton: {
    px: '1',
    pos: 'relative',
    _hover: { bg: 'transparent' },
  },
  hStack: {
    w: 'full',
    alignItems: 'center',
  },
  flex: {
    alignItems: 'center',
    gap: 2,
    flex: 1,
    textAlign: 'left',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  accordionButtonIconSvg: {
    sx: {
      color: 'beta.00',
      fontSize: 'md',
      boxSize: '4',
    },
  },
  accordionButtonText: {
    color: 'beta.800',
    fontSize: ['xs', 'md'],
    fontFamily: 'accent',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  marketTypesFlex: {
    mr: '2',
    gap: '2',
  },
  marketTypesText: {
    w: ['50px', '100px'],
    fontSize: '2xs',
    fontWeight: 'bold',
  },
  accordionButtonInnerIcon: {
    sx: {
      pos: 'absolute',
      right: '0',
      boxSize: '6',
      color: 'beta.800',
    },
  },
  accordionPanel: {
    p: '0',
    mb: '3',
  },
};
