import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';
import { TRaceToteResult, ERaceBetType } from '../../../../../lib/DBModels';
import {
  TypeItem,
  ResultRowWrapper,
  ResultRowExoticRow,
  ResultRowExoticWrapper,
} from './Results/styles/Results.styles';
import { centsToDollars } from '@/helpers/utils';

export default function ExoticResultsRow(result: TRaceToteResult) {
  const { dividend, type, selection } = result;
  return (
    <ResultRowWrapper>
      <Box>
        <TypeItem>
          {(type === ERaceBetType.FirstFour && (
            <FormattedMessage id="racing.raceresults.exotics.firstfour" />
          )) ||
            type}
        </TypeItem>
      </Box>
      <ResultRowExoticWrapper>
        <ResultRowExoticRow data-cy="exoticsResult">
          {selection?.map((sel) => sel).join(', ')}
        </ResultRowExoticRow>
        <ResultRowExoticRow data-cy="exoticsDividend">
          {centsToDollars(dividend, false)}
        </ResultRowExoticRow>
      </ResultRowExoticWrapper>
    </ResultRowWrapper>
  );
}
