import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: 'md',
    bg: 'beta.400',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    textTransform: 'uppercase',
  },
  btnGroupText: {
    fontSize: 'xs',
    color: 'alpha.600',
    fontWeight: 'black',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  },
  btnGroupIcon: {
    color: 'alpha.600',
  },
  name: {
    color: 'gray.700',
  },
};
export default styles;
