import React from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { Icon, Text, Tooltip, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { InfoCircleFill } from '@styled-icons/bootstrap/InfoCircleFill';
import { useSelection } from '@/views/races/bets/Exotics/services/Exotics.hooks';
import {
  BoxWrapper,
  buttonTabBarProps,
  buttonWinPlaceSingleProps,
  TabButtonWrapper,
} from './styles/TabBar.styles';
import { useDisplayMarkets } from './services/TabBar.hooks';
import { badgeStyles } from '@/views/races/components/Badges/styles/Badges.styles';
import { TPunterSupportedBetsResponse } from '@/api/punter/supportedBets/supportedBets.types';
import { Button } from '@/components/Button/Button';
import { EBetTypesDisplayNames } from '@/lib/DBModels';
import { getFeatureFlag } from '@/store/FeatureFlagStore';

/**
 * Not all races return exotics.
 * We will need to query if this race does.
 */

type TTabBar = {
  supportedBets: TPunterSupportedBetsResponse;
};

const multiples = [
  EBetTypesDisplayNames.Quaddie,
  EBetTypesDisplayNames.BigSix,
  EBetTypesDisplayNames.RunningDouble,
  EBetTypesDisplayNames.DailyDouble,
  EBetTypesDisplayNames.Treble,
  EBetTypesDisplayNames.EarlyQuaddie,
];

const exotics = [
  EBetTypesDisplayNames.Exacta,
  EBetTypesDisplayNames.Quinella,
  EBetTypesDisplayNames.Trifecta,
  'firstfour',
];

export const SUB_TABS_DISPLAY_NAME = {
  [EBetTypesDisplayNames.Quaddie]: 'Quaddie',
  [EBetTypesDisplayNames.BigSix]: 'Big Six',
  [EBetTypesDisplayNames.RunningDouble]: 'Running Double',
  [EBetTypesDisplayNames.DailyDouble]: 'Daily Double',
  [EBetTypesDisplayNames.Treble]: 'Treble',
  [EBetTypesDisplayNames.EarlyQuaddie]: 'Early Quaddie',
};

export default function TabBar({ supportedBets }: TTabBar) {
  const { displayMarkets } = useDisplayMarkets();
  const [searchParams] = useSearchParams();
  const propId = searchParams.get('propId');

  const { betType } = useParams();
  const { resetSelections } = useSelection();

  const isMultiples = multiples.some(
    (e) => e.toLowerCase() === betType?.toLowerCase()
  );
  const isExotics = exotics.some(
    (e) => e.toLowerCase() === betType?.toLowerCase()
  );

  const { TOTE_MULTI_ENABLED: isToteMultiEnabled } = getFeatureFlag() ?? {};

  let multiplesTabs = null;
  if (isToteMultiEnabled)
    multiplesTabs = displayMarkets.find((m) => m.key === 'Multiples');

  const exoticsTabs = displayMarkets.find((m) => m.key === 'Exotics');

  // eslint-disable-next-line no-nested-ternary
  const subTabs = isExotics ? exoticsTabs : isMultiples ? multiplesTabs : null;

  return (
    <>
      <BoxWrapper className="tabBar">
        {displayMarkets.length === 1 && displayMarkets[0].name === 'Win/Place' ? (
          <TabButtonWrapper>
            <Button {...buttonWinPlaceSingleProps}>
              {displayMarkets[0].name}
            </Button>
          </TabButtonWrapper>
        ) : (
          displayMarkets.map((t) => {
            let isActive =
              betType?.toLowerCase().replaceAll(' ', '') ===
                t.key.toLowerCase().replaceAll(' ', '') ||
              (!betType && !t.key);

            if (
              exotics.some((e) => e.toLowerCase() === betType?.toLowerCase()) &&
              t.key === 'Exotics'
            ) {
              isActive = true;
            }

            if (
              multiples.some((m) => m.toLowerCase() === betType?.toLowerCase()) &&
              t.key === 'Multiples'
            ) {
              isActive = true;
            }

            const isToteSupported = supportedBets?.tote_singles !== false;
            const modifiedName = t.name.toLowerCase().replace(/\s/g, '-');

            return (
              <TabButtonWrapper
                key={t.to}
                className={betType !== 'SRMulti' && `${modifiedName}-wrapper`}
              >
                <Button
                  key={t.to}
                  data-cy={`tabBarMarkets-${t.name}`}
                  as={NavLink}
                  to={t.to}
                  onClick={() => resetSelections()}
                  data-active={isActive}
                  end
                  className={betType !== 'SRMulti' && modifiedName}
                  {...buttonTabBarProps}
                >
                  {t.name}
                  {t.icon && isToteSupported && (
                    <Tooltip
                      hasArrow
                      closeDelay={2000}
                      shouldWrapChildren
                      right="2"
                      mt="1"
                      label={
                        <VStack alignItems="start">
                          <Text {...badgeStyles.textDescription}>
                            <FormattedMessage id="racing.raceDetails.winTabInfo" />
                          </Text>
                        </VStack>
                      }
                      {...badgeStyles.tooltipWrapper}
                      placement="bottom-start"
                    >
                      <Icon as={InfoCircleFill} />
                    </Tooltip>
                  )}
                </Button>
              </TabButtonWrapper>
            );
          })
        )}
      </BoxWrapper>
      {subTabs?.subType && (
        <BoxWrapper className="subTabBar">
          {subTabs?.subType?.map((t) => {
            let isActive = betType?.toLowerCase() === t.key.toLowerCase();

            if (betType === EBetTypesDisplayNames.RunningDouble) {
              isActive = propId === t.propositionId;
            }
            return (
              <TabButtonWrapper key={t.to}>
                <Button
                  key={t.to}
                  data-cy={`tabBarMarkets-${t.name}`}
                  as={NavLink}
                  to={t.to}
                  onClick={() => resetSelections()}
                  data-active={isActive}
                  end
                  className="subTab"
                  {...buttonTabBarProps}
                >
                  {SUB_TABS_DISPLAY_NAME[
                    t.name as keyof typeof SUB_TABS_DISPLAY_NAME
                  ] ?? t.name}
                </Button>
              </TabButtonWrapper>
            );
          })}
        </BoxWrapper>
      )}
    </>
  );
}
