import {
  headerBaseStyle,
  THeaderTheme,
} from '../../base/customComponents/header';
import { TStyleConfig } from '../../base/types';

const headerHeight = '16';

export const Header: TStyleConfig<THeaderTheme> = {
  baseStyle: {
    ...headerBaseStyle,
    headerHeight,
    wrapper: {
      ...headerBaseStyle.wrapper,
      bg: 'beta.500',
      px: ['2', null, null, 'unset'],
      borderBottom: '1px solid',
      borderBottomColor: 'beta.400',
      '.buttonGroup': { display: ['none', 'unset'] },
    },
    wrapperContent: {
      ...headerBaseStyle.wrapperContent,
      justifyContent: 'space-between',
    },
    accountBallance: {
      ...headerBaseStyle.accountBallance,
      fontSize: 'sm',
      fontWeight: 'semibold',
      color: 'white',
      display: 'inline-flex',
      columnGap: '1',
      alignItems: 'center',
      lineHeight: '1',
    },
    accountBallanceVerbiage: {
      ...headerBaseStyle.accountBallanceVerbiage,
      fontWeight: 'extrabold',
    },
    menuIcon: {
      ...headerBaseStyle.menuIcon,
      color: 'beta.400',
      bg: 'white',
    },
    betslip: {
      ...headerBaseStyle.betslip,
      bg: ['unset', 'alpha.500'],
      p: ['1', '2'],
      flexDirection: ['column', 'row'],
      ml: [null, '2'],
      borderRadius: 'full',
    },
    betslipCount: {
      ...headerBaseStyle.betslipCount,
      fontWeight: 'semibold',
      fontSize: 'sm',
      boxSize: '6',
      minW: '6',
      minH: '6',
      lineHeight: '6',
      bg: ['alpha.500', 'alpha.700'],
      color: ['white', 'white'],
      borderRadius: 'full',
      mr: ['unset', '1'],
      mb: ['1', 'unset'],
    },
    betslipLabel: {
      ...headerBaseStyle.betslipLabel,
      color: 'white',
      fontSize: 'xs',
      fontWeight: 'bold',
      mr: [0, '2'],
      textTransform: ['capitalize', null, 'uppercase'],
    },
  },
  sizes: {},
  variants: {},
};
