import { CSSObject } from '@chakra-ui/react';

export const TextBoxed: CSSObject = {
  fontSize: 'sm',
};

export const CheckboxBoxed: CSSObject = {
  right: 0,
  justifyContent: 'flex-end',
  mr: '3',
};

export const ExoticsContainer: CSSObject = {
  border: '1px solid',
  borderColor: 'gray.300',
  borderRadius: ['base', null, 'md'],
  bg: 'gray.100',
  mx: ['0', '4'],
  px: '2',
};

export const ExoticsWrapper: CSSObject = {
  py: '2',
  bg: 'white',
};

export const SelectFieldContainer: CSSObject = {
  mr: ['0', null, '3'],
};
