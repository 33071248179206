import { AccordionIcon, Box, Flex, Icon } from '@chakra-ui/react';
import { InfoCircle } from '@styled-icons/fa-solid';
import React from 'react';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  TextDisclaimer,
} from '../../MarketsByMatch/styles/MarketsByMatch.styles';
import {
  TMarketsAccordionItemProps,
  TPropositionsProps,
} from '../services/MatchDetailPage.types';
import Propositions from './Propositions';
import { marketAccordionItemStyles } from '../../NewMatchDetailPage/styles/MarketsAccordionItem/MarketsAccordionItem.styles';

export default function MarketsAccordionItem({
  marketName,
  disclaimer,
  propositions,
  onSelection,
}: TMarketsAccordionItemProps) {
  const handleSelection: TPropositionsProps['onSelection'] = (
    propositionId
  ) => {
    onSelection?.(propositionId, marketName);
  };

  return (
    <AccordionItem isNested>
      <AccordionButton isNested>
        <Box as="span" {...marketAccordionItemStyles.accordionBox}>
          {marketName}
          {disclaimer && (
            <Flex {...marketAccordionItemStyles.disclaimerFlex}>
              <Icon
                as={InfoCircle}
                {...marketAccordionItemStyles.disclaimerIcon}
              />
              <TextDisclaimer>{disclaimer}</TextDisclaimer>
            </Flex>
          )}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel isNested>
        <Propositions
          data={propositions?.data}
          isError={!!propositions?.isError}
          isLoading={!!propositions?.isLoading}
          onSelection={handleSelection}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}
