import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'alpha.700',
  },
  eventMarketName: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'alpha.700',
  },
  eventSubtitle: {
    fontSize: 'sm',
    fontWeight: 'medium',
    color: 'alpha.700',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'sm',
    color: 'alpha.700',
    sx: {
      '> span': { fontSize: '2xs', fontWeight: 'medium' },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '1',
    pt: '1',
    pb: '2',
    borderBottom: '1px dashed',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    borderBottomColor: 'rgba(0, 0, 0, 0.4)',
    _first: {
      borderTop: '1px dashed',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      borderTopColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
};
