import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/puntgenie/images/logo.svg';
import logoMobile from '@/assets/puntgenie/images/logoMobile.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image
          src={logo}
          sx={{
            py: ['0', null, '3'],
            m: 'auto',
            alignSelf: 'center',
          }}
          alt={`${THEME_NAME.puntgenie}`}
        />
      </Show>
      <Hide above="lg">
        <Image src={logoMobile} alt={`${THEME_NAME.puntgenie}`} />
      </Hide>
    </Link>
  );
}
