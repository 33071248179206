/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Box } from '@chakra-ui/react';

import { TTemplate } from '@/helpers/createTemplate';
import LazyLottie from './lazyLottie';

const lottiePromise = import(
  '../../../../../../assets/buffalobet/animations/buffalobet_lottie.json'
);

export const template = (): TTemplate => ({
  wrapper: (
    <Box mx="2" mt="2" borderRadius="lg">
      <LazyLottie lottieImportPromise={lottiePromise} />
    </Box>
  ),
});

export default template;
