import React from 'react';
import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Editor } from '@/views/helpCentre/Promos/styles/Promos.styles';
import { TData } from '@/api/promotions/promotions.types';
import { getThemeName } from '@/helpers/getThemeName';

type TPromotionModal = {
  promotion?: TData;
  isOpen: boolean;
  onClose: () => void;
};

export default function PromotionModal({
  promotion,
  isOpen,
  onClose,
}: TPromotionModal) {
  if (!promotion) return null;

  const themeName = getThemeName();

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent overflow="hidden" maxH="500px">
          <ModalHeader>{promotion.title}</ModalHeader>
          <ModalCloseButton onClick={() => onClose()} />
          <Divider />
          <ModalBody overflow="scroll">
            <Editor
              readOnly
              style={{ color: '#333' }}
              value={promotion.terms_and_conditions ?? ''}
            />

            <Text fontSize="sm">
              <FormattedMessage
                id="helpcentre.promotions.modal.terms"
                values={{ bookieName: themeName }}
              />
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
