import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
};

export const Heading: CSSObject = {
  fontSize: 'sm',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'gray.300',
  borderStyle: 'dashed',
};

export const TextDescription: CSSObject = {
  color: 'white',
};

/**
 * Form Inputs
 */
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
};

export const InputWithdrawal: CSSObject = {
  borderRadius: 'sm',
  fontSize: 'sm',
  height: '38px',
  '&&': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
  },
  _placeholder: {
    color: 'gray.700',
  },
  _focus: {
    bg: 'zeta.100',
  },
};

/**
 * Buttons
 */

export const ButtonWithdrawal: CSSObject = {
  mt: '1',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'alpha.700',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  borderWidth: '2px',
  borderColor: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    color: 'alpha.700',
  },

  _active: {
    bg: 'beta.600',
    color: 'alpha.700',
    boxShadow:
      '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.10) inset',
  },
};

export const ButtonIncrease: CSSObject = {
  fontFamily: 'Roboto',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'alpha.700',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.30)',
  boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
  lineHeight: '12px',
  textTransform: 'uppercase',
  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    color: 'alpha.700',
    boxShadow:
      '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  _active: {
    bg: 'beta.600',
    color: 'alpha.700',
    boxShadow:
      '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  },
};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const BankRadio: CSSObject = {
  alignItems: 'start',
  border: 'none',
  '&[data-checked]': {
    boxShadow: 'none',
    _hover: {
      bg: 'gray.400',
    },
  },

  '&[data-checked]:before': {
    display: 'flex',
    alignSelf: 'center',
    width: '40%',
    height: '40%',
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '1.5rem',
  pt: '0',
  w: ['100%', null, '330px'],
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

/**
 * Withdrawal Request Card
 */
export const WithdrawalWrapper: CSSObject = {
  bg: 'white',
  color: 'gray.700',
  fontSize: 'sm',
  mt: ['4', null, '0'],
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'base',
  border: 'none',
};

export const WithdrawalCardDetailHeading: CSSObject = {
  fontSize: 'sm',
  color: 'gray.700',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  color: 'gray.700',

  '.withdrawName': {
    fontSize: 'xs',
  },
};

export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  color: 'gray.500',
  fontSize: 'xs',
};

export const WithdrawalCardDetailRequested: CSSObject = {
  fontWeight: '700',
  color: 'gray.700',
  fontSize: 'xs',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  borderRadius: 'base',
  border: '2px solid',
  borderColor: 'white',
  bg: 'linear-gradient(to-b, beta.400, beta.500)',
  color: 'rgba(0, 0, 0, 0.50)',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.10) inset',
  fontWeight: 'black',
  textTransform: 'uppercase',

  '&&': {
    fontSize: 'xs',
  },

  '&:hover, &:active': {
    bg: 'beta.600',
    color: 'blackAlpha.600',
    boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.25)',
    borderColor: 'transparent',
  },
};

export const WithdrawalStatus = ({ status }: any): CSSObject => ({
  borderRadius: 'base',
  px: '2',
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  bg: () => {
    switch (status) {
      case EWithdrawalStatus.Cancelled:
        return 'gray.500';
      case EWithdrawalStatus.Error:
        return 'red.500';
      case EWithdrawalStatus.Pending:
        return 'yellow.500';
      case EWithdrawalStatus.Processed:
        return 'gamma.400';
      case EWithdrawalStatus.Rejected:
        return 'red.500';
      default:
        return 'green.500';
    }
  },
});

export const Subheading: CSSObject = {
  color: 'white',
};
