import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  keyframes,
  Skeleton,
  Switch,
  SwitchProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggle from '@/assets/vikingbet/images/switch/menu-toggle.png';
import toggleBg from '@/assets/vikingbet/images/backgrounds/texture-wood-1x.png';
import arrowLeft from '@/assets/vikingbet/images/backgrounds/menu-arrow-left.svg';
import arrowRight from '@/assets/vikingbet/images/backgrounds/menu-arrow-right.svg';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'ToggleFlexWrapper',
  baseStyle: {
    alignItems: 'center',
    bg: `url(${toggleBg}) center no-repeat`,
    backgroundSize: ['inherit', null, null, '270px 100%'],
    borderRadius: '1rem',
    borderY: '#C5C4C0 4px solid',
    borderX: '#C5C4C0 8px solid',
    boxShadow:
      '0px 0px 4px 5px rgba(0, 0, 0, 0.40) inset, 0px 4px 0px 0px #757472, 0px 9px 0px 0px #464646, 0px -4px 0px 0px #EDEDEC',
    contain: 'layout',
    width: '269px',
    height: '62px',
    justifyContent: 'center',
    mx: 'auto',
    mb: '7',
    px: '6',
    _before: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      bottom: '16%',
      // transform: 'translateY(-50%)',
      right: '0px',
      width: '10px',
      height: '36px',
      zIndex: '3',
      backgroundImage: `url(${arrowLeft})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    _after: {
      content: ['none', null, null, '""'],
      display: 'block',
      position: 'absolute',
      bottom: '16%',
      // transform: 'translateY(-50%)',
      left: '0px',
      width: '10px',
      height: '36px',
      zIndex: '3',
      backgroundImage: `url(${arrowRight})`,
      backgroundSize: '98% 98%',
      backgroundRepeat: 'no-repeat',
    },
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: {
      fontFamily: 'SkranjiRegular',
      fontWeight: '100',
      fontSize: '1.1rem',
      textTransform: 'uppercase',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.45)',
      color: '#F2D194',
      letterSpacing: 'wide',
      px: '3',
      pt: '1',
    },
  });

const Switcher: ChakraComponent<'label', SwitchProps> = chakra(Switch, {
  label: 'Switcher',
  baseStyle: {
    '.chakra-switch__track': {
      bg: 'blackAlpha.500',
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      w: '50px',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',

      _before: {
        bgImg: `url(${toggle})`,
        height: '3.5rem',
        width: '3.5rem',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        bottom: '60px',
        left: '-15px',
        right: '0',
        top: '-12px',
        animation: `
            ${keyframes`
              from { transform: rotate(0deg) }
              to { transform: rotate(-360deg) }
            `} 0.5s ease-out`,
      },
    },

    _active: {
      '.chakra-switch__track': {
        boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      },
    },

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
        _before: {
          animation: `
            ${keyframes`
              from { transform: rotate(0deg); }
              to { transform: rotate(360deg); }
            `} 0.7s ease-out`,
        },
      },
      '.chakra-switch__track': {
        bg: 'blackAlpha.500',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={!!category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sports
      </TextToggle>
    </FlexWrapper>
  );
}
