import { LinkItemSchema } from '@/views/sports/components/CompetitionsMenu/styles/LinkItem.styles';

export const linkItemStyles: LinkItemSchema = {
  link: {
    bg: 'gamma.600',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    my: '1px',
    py: '2',
    px: '3',
    _hover: {
      textDecoration: 'none',
    },
    _first: {
      mt: '0',
    },
    _last: {
      my: '0',
    },
  },
  textCompetitionName: {
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxW: '140px',
    color: 'gamma.100',
    fontWeight: 'medium',
  },
  textMatchCount: {
    fontSize: '3xs',
    color: 'gamma.100',
    fontWeight: 'bold',
  },
};
