import { CSSObject } from '@chakra-ui/react';

export const TextTitle: CSSObject = {
  color: 'alpha.700',
  fontSize: 'md',
};

export const BoxBordered: CSSObject = {
  px: '2',
  textColor: 'black',
  maxW: 'container.md',
};

export const PageInfo: CSSObject = {
  fontSize: 'sm',
  color: 'gray.700',
};

export const Text: CSSObject = {
  fontSize: 'sm',
  color: 'gray.700',
};

export const ButtonAction: CSSObject = {
  w: 'full',
};

export const Link: CSSObject = {
  color: 'alpha.600',
  fontSize: 'xs',
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr',
  maxW: 'container.sm',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mx: '2',
};
