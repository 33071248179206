import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  name: {
    color: 'beta.800',
    fontWeight: 'bold',
  },
  row: {
    pb: '1',
    mx: '0.5',
    borderBottom: '1px solid',
    borderColor: 'blackAlpha.400',
    _last: {
      borderBottom: 'none',
    },
  },
  grid: {
    pb: '0',
  },
};
export default styles;
