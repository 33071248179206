import { CSSObject } from '@chakra-ui/react';

export const HeaderText: CSSObject = {
  color: 'alpha.700',
  fontSize: 'md',
  p: '0',
  textTransform: 'none',
};

export const HeaderContainer: CSSObject = {
  pb: '2',
  pt: '4',
  px: '4',
};

export const CloseButton: CSSObject = {
  display: 'none',
};

export const ContentContainer: CSSObject = {
  color: 'alpha.500',
  pb: '4',
  pt: '1',
  px: '4',
};

export const ModalBoxWrapper: CSSObject = {
  borderRadius: 'xl',
  bg: 'linear-gradient(180deg, #FFE0A6 0%, #E4C180 100%)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};
