import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
    columnGap: '3.5',
  },

  FlexCard: {
    position: 'relative',
    width: '100%',
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    bgGradient: 'linear(to-b, beta.600, beta.400)',
    borderRadius: 'lg',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
    p: '2',
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '1',
      right: '1',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      zIndex: '3',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, beta.400, beta.600)',
      boxShadow: '0px 1px 0px 0px #ED8934',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '2',
      left: '2',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      zIndex: '4',
      borderRadius: 'base',
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
    },
  },

  LinkCardHeader: {
    color: 'white',
    zIndex: '5',
    _hover: {
      bg: 'alpha.600',
    },
  },

  TextCardHeading: {
    fontWeight: 'semibold',
    zIndex: '5',
  },

  TextRaceNumber: {
    borderRadius: 'base',
    bg: '#224049',
    color: 'white',
    zIndex: '5',
  },

  GridCardContent: {
    fontSize: 'sm',
    zIndex: '5',
    p: '0',
  },

  BoxGridHeaderItem: {
    bg: 'alpha.700',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    lineHeight: 'normal',
  },

  FlexGridRunnerItem: {
    bg: 'zeta.500',
    borderBottom: '1px dotted',
    borderBottomColor: 'rgba(0, 0, 0, 0.25)',

    _last: {
      border: 'none',
    },
  },

  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'semibold',
    zIndex: '5',
  },

  TextRunnerNumber: {},

  LinkRaceDetails: {
    color: 'beta.400',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    bgColor: 'alpha.700',
    letterSpacing: 'wide',
    textTransform: 'uppercase',
    fontFamily: 'Bungee',
    fontWeight: 'normal',
    p: '0.5rem',
    zIndex: '5',
    borderBottomRadius: 'base',
    boxShadow: '0px -2px 5px 1px rgba(0, 0, 0, 0.30) inset',
    borderTop: '1px solid #2C6070',

    _hover: {
      bgGradient: 'linear(180deg, alpha.600, alpha.700)',
    },
  },

  buttonRaceCardOddsProps: {
    zIndex: '5',
    borderRadius: 'lg',
    w: '14',
    h: '9',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};
