import {
  chakra,
  ChakraComponent,
  Input as ChakraInput,
  InputProps,
} from '@chakra-ui/react';
import { styleImports } from './onboarding.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

/**
 * Remove this
 */
export const Input = chakra(ChakraInput, {
  label: 'onboarding-Input',
  baseStyle: () => ({
    borderRadius: 'md',

    '& + .chakra-input__right-element': { color: 'gray.900' },
    ...(() => {
      try {
        return styleImports[themeName]?.Input;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'input', InputProps>;

/**
 * Wrapper for the new layout
 */
export const WrapperFlexOnboarding = chakra('div', {
  label: 'onboarding-WrapperFlexOnboarding',
  baseStyle: () => ({
    display: 'flex',
    boxSize: '100%',
    ...(() => {
      try {
        return styleImports[themeName]?.WrapperFlexOnboarding;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Wrapper for the new layout
 */
export const WrapperOnboarding = chakra('div', {
  label: 'onboarding-WrapperOnboarding',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.WrapperOnboarding;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Wrapper for the content
 */
export const WrapperContent = chakra('div', {
  label: 'onboarding-WrapperContent',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.WrapperContent;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Content Title
 */
export const Title = chakra('p', {
  label: 'onboarding-Title',
  baseStyle: {
    ...(() => {
      try {
        return styleImports[themeName]?.Title;
      } catch (err) {
        return {};
      }
    })(),
  },
});
