import { BoxProps } from '@chakra-ui/react';
import { WellSchema } from './Well.styles';

export const wrapper: BoxProps = {};
export const wellStyles: WellSchema = {
  wrapper: {
    bg: 'brand.900',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.15) inset',
    p: '1.5',
  },
};
