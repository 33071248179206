import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
    boxShadow:
      '0px 3px 10px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 4px rgba(0, 0, 0, 1) inset',
    border: '2px solid',
    borderColor: '#FFE6B6',
    sx: {
      '.chakra-modal__close-btn': {
        color: 'white',
      },
    },
  },
  stackWrapper: {
    bg: 'alpha.700',
  },
  heading: { color: 'white' },
  moneyValues: { color: 'white' },
  cashedOutText: {},
  cashedOutQuestion: { color: 'white' },
  declineButton: {
    variant: 'outline',
    borderColor: 'white',
    fontFamily: 'heading',
  },
  acceptButton: { variant: 'secondary' },
  doneButton: {},
};
