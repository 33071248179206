import { SpecialsStylesSchema } from './Specials.styles';

export const specialsStyles: SpecialsStylesSchema = {
  heading: {
    color: 'white',
    fontWeight: 'semibold',
  },
  boxWrapper: {
    borderTop: '1px dashed rgba(255, 255, 255, 0.1)',
    borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
    py: 3,
    px: 0,
    mx: 3,
  },
};
