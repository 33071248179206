import {
  BoxProps,
  FlexProps,
  HeadingProps,
  SkeletonTextProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';

import { styleImports } from './UpcomingMatches.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { CustomButtonProps } from '@/components/Button/Button';
import { TScrollButtonGroupProps } from '@/views/sports/components/ScrollButtonGroup/ScrollButtonGroup';
import { TIconProps } from '@/components/common/IconSvg';

const themeName = getThemeName();

export type UpcomingMatchesSchema = Partial<{
  pageButtonWellWrapperOverride: BoxProps;
  pageHeader: HeadingProps;
  stackWrapper: StackProps;
  boxWrapper: BoxProps;
  sortByText: TextProps;
  hStackWrapper: StackProps;
  segmentedButtonSortByTime: CustomButtonProps;
  segmentedButtonSortBySport: CustomButtonProps;
  boxSportWrapper: BoxProps;
  scrollButtonGroup: TScrollButtonGroupProps;
  buttonAllSports: CustomButtonProps;
  buttonSport: CustomButtonProps;
  flexRenderSortingMobile: FlexProps;
  loadingSkeletonText: SkeletonTextProps;
  mainStackWrapper: StackProps;
  mainStackInner: StackProps;
  heading: HeadingProps;
  headingIcon: TIconProps;
  propositionButton: CustomButtonProps;
}>;

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageButtonWellWrapperOverride: {
    'data-testid': 'upcomingMatches-pageButtonWellWrapperOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles
          ?.pageButtonWellWrapperOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  propositionButton: {
    'data-testid': 'upcomingMatches-propositionButton',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles
          ?.propositionButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  headingIcon: {
    'data-testid': 'upcomingMatches-headingIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.headingIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  heading: {
    'data-testid': 'upcomingMatches-heading',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.heading;
      } catch (err) {
        return {};
      }
    })(),
  },
  mainStackInner: {
    'data-testid': 'upcomingMatches-mainStackInner',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.mainStackInner;
      } catch (err) {
        return {};
      }
    })(),
  },
  mainStackWrapper: {
    'data-testid': 'upcomingMatches-mainStackWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.mainStackWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  loadingSkeletonText: {
    'data-testid': 'upcomingMatches-loadingSkeletonText',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles
          ?.loadingSkeletonText;
      } catch (err) {
        return {};
      }
    })(),
  },
  flexRenderSortingMobile: {
    'data-testid': 'upcomingMatches-flexRenderSortingMobile',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles
          ?.flexRenderSortingMobile;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonAllSports: {
    'data-testid': 'upcomingMatches-buttonAllSports',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.buttonAllSports;
      } catch (err) {
        return {};
      }
    })(),
  },
  buttonSport: {
    'data-testid': 'upcomingMatches-buttonSport',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.buttonSport;
      } catch (err) {
        return {};
      }
    })(),
  },
  scrollButtonGroup: {
    'data-testid': 'upcomingMatches-scrollButtonGroup',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles
          ?.scrollButtonGroup;
      } catch (err) {
        return {};
      }
    })(),
  },
  boxSportWrapper: {
    'data-testid': 'upcomingMatches-boxSportWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.boxSportWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  segmentedButtonSortByTime: {
    'data-testid': 'upcomingMatches-segmentedButtonSortByTime',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles
          ?.segmentedButtonSortByTime;
      } catch (err) {
        return {};
      }
    })(),
  },
  segmentedButtonSortBySport: {
    'data-testid': 'upcomingMatches-segmentedButtonSortBySport',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles
          ?.segmentedButtonSortBySport;
      } catch (err) {
        return {};
      }
    })(),
  },
  hStackWrapper: {
    'data-testid': 'upcomingMatches-hStackWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.hStackWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  sortByText: {
    'data-testid': 'upcomingMatches-sortByText',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.sortByText;
      } catch (err) {
        return {};
      }
    })(),
  },
  boxWrapper: {
    'data-testid': 'upcomingMatches-boxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.boxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  stackWrapper: {
    'data-testid': 'upcomingMatches-stackWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.stackWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  pageHeader: {
    'data-testid': 'upcomingMatches-pageHeader',

    ...(() => {
      try {
        return styleImports[themeName]?.upcomingMatchesStyles?.pageHeader;
      } catch (err) {
        return {};
      }
    })(),
  },
};
