import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  px: '3',
  py: '4',
  borderRadius: 'base',
  alignItems: 'center',
  bg: 'linear-gradient(180deg, #007DC6 0%, #00578A 100%)',
  maxH: ['unset', null, '46px'],
  mb: '2',
};

export const TextMustBeVerified: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ButtonGetVerified: CSSObject = {
  color: 'blackAlpha.700',
  bg: 'beta.500',
  boxShadow:
    '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #744f0a inset, 0px 0px 0px 5px #ffd076 inset',
  borderRadius: 'base',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  px: '2.5',
  py: '1',
  ml: 'auto',
  minW: 'unset',
  h: '6',
  _hover: {
    bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
  },
  _focus: {
    bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
  },
};
