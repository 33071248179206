import { TRunner } from '../../../../../lib/DBModels';

type LabelNameType = 'D' | 'O' | 'J';

export const getLabelName = ({ form_data }: TRunner): LabelNameType => {
  const raceType = form_data?.race_type?.toLowerCase();

  switch (raceType) {
    case 'harness racing':
      return 'D';
    case 'greyhounds':
      return 'O';
    default:
      return 'J';
  }
};
