import { useReducer } from 'react';

export enum EActionTypes {
  ADD_SELECTION = 'ADD_SELECTION',
  REMOVE_SELECTION = 'REMOVE_SELECTION',
  CLEAR_SELECTIONS = 'CLEAR_SELECTIONS',
}

type TAddAction = {
  type: EActionTypes.ADD_SELECTION;
  payload: TSelections;
};

type TRemoveAction = {
  type: EActionTypes.REMOVE_SELECTION;
  payload: {
    runnerId: string;
  };
};

type TClearAction = {
  type: EActionTypes.CLEAR_SELECTIONS;
};

type Action = TAddAction | TRemoveAction | TClearAction;

export type TSelections = {
  runner_number: number;
  runner_name: string;
  barrier_number?: number;
  runnerId: string;
  propositionId: string;
  odds: number;
};

type TState = {
  selections: TSelections[];
};

function selectionsReducer(state: TState, action: Action): TState {
  switch (action.type) {
    case EActionTypes.ADD_SELECTION: {
      return {
        ...state,
        selections: [...state.selections, action.payload],
      };
    }
    case EActionTypes.REMOVE_SELECTION: {
      return {
        ...state,
        selections: state.selections.filter(
          (s) => s.runnerId !== action.payload.runnerId
        ),
      };
    }
    case EActionTypes.CLEAR_SELECTIONS: {
      return {
        ...state,
        selections: [],
      };
    }
    default: {
      return state;
    }
  }
}

export function useSelections() {
  const [state, dispatch] = useReducer(selectionsReducer, { selections: [] });

  return {
    addSelection(runner: TSelections) {
      dispatch({
        type: EActionTypes.ADD_SELECTION,
        payload: runner,
      });
    },
    removeSelection(runnerId: string) {
      dispatch({
        type: EActionTypes.REMOVE_SELECTION,
        payload: {
          runnerId,
        },
      });
    },
    clearSelections() {
      dispatch({
        type: EActionTypes.CLEAR_SELECTIONS,
      });
    },
    selections: state,
  };
}
