import {
  footerBaseStyle,
  TFooterTheme,
} from '../../base/customComponents/footer';
import { TStyleConfig } from '../../base/types';

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: {
    ...footerBaseStyle,
    footer: {
      ...footerBaseStyle.footer,
      pb: '0',
      bg: 'transparent',
      bgSize: 'cover',
      bgPos: 'bottom',
      py: '0',
      color: 'white',
    },
    stackGap: '1',
    footerBar: {
      display: 'flex',
      flexDir: ['column', null, 'row'],
      pr: ['0', null, '3'],
      mb: ['3', '8'],
      borderRadius: 'md',
      alignItems: 'center',
      justifyContent: ['center', null, 'unset'],
    },
    footerLink: {
      color: 'beta.500',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
    },
    footerLinkTel: {
      color: 'beta.500',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
    },
    customFooterLink: {
      color: 'beta.500',
      textTransform: 'capitalize',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontSize: 'sm',
      pos: 'relative',
      bottom: 'px',
      h: 'auto',
    },
    footerLinks: {
      mt: '3',
      ml: ['unset', null, 'auto'],
      pb: ['4', null, '0'],
      alignItems: 'center',
      justifyContent: ['space-around', null, 'unset'],
      color: 'white',
      fontWeight: 'medium',
      flexDir: ['row', null, 'unset'],
      display: 'inline-flex',
    },
    footerContentContainer: {
      px: ['2.5', 'unset'],
      gap: '6',
      ...footerBaseStyle.footerContentContainer,
      bg: [null, null, 'transparent'],
      justifyContent: 'space-between',
      '&': {
        flexDirection: ['column', null, 'column'],
      },
      mb: '0',
    },
    nav: {
      ...footerBaseStyle.nav,
      fontSize: 'sm',
      fontWeight: 'medium',
      minW: [null, null, '370px'],
      display: ['flex', 'grid'],
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto',
      columnGap: '28',
      alignItems: ['center', 'end'],
      gridTemplateAreas: `
        ". ."
      `,

      '> a': {
        color: 'delta.800',
      },
      button: {
        h: 'auto',
        '.chakra-text': {
          fontWeight: 'medium',
        },
      },
    },
    blurb: {
      ...footerBaseStyle.blurb,
      flex: 'unset',
      textAlign: 'left',
      color: 'white',
      width: 'unset',
      mb: '0',
      ml: [null, '0'],
      mr: [null, null, '4'],
    },
    blurbHeading: {
      ml: 'unset',
      mt: ['2', 'unset'],
      color: 'gray.400',
    },
    blurbHeadingAge: {
      ...footerBaseStyle.blurbHeadingAge,
      borderColor: 'delta.800',
      border: '4px',
      mb: '2',
      boxSize: '8',
      fontSize: 'xs',
      color: 'delta.800',
      lineHeight: 'normal',
    },
    blurbHeadingThink: {
      fontSize: 'xs',
      fontWeight: 'bold',
      mb: '4',
      color: 'delta.800',
    },
    blurbContent: {
      ...footerBaseStyle.blurbContent,
      color: 'delta.800',
      ml: 'unset',
      mr: [null, null, 'auto'],
    },
    copyright: {
      ...footerBaseStyle.copyright,
      color: 'delta.800',
      textAlign: ['center', 'left'],
      mt: ['7'],
      px: ['8', 'unset'],
      py: '0',
      fontWeight: ['semibold', 'medium'],
    },
    container: {
      p: '4',
      mt: '3',
    },
    emailUs: {
      fontSize: 'md',
      fontWeight: 'bold',
      color: 'delta.800',
    },
    footerDownloadImage: {
      border: '2px',
      borderColor: 'white',
      borderRadius: 'md',
      filter: 'drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.3))',
    },
    partnerWrapper: {
      mr: 'unset',
    },
    quickLinks: {
      color: 'delta.800',
      textTransform: ['uppercase', 'none'],
      textAlign: ['center', 'left'],
      fontSize: ['xs', 'md'],
      fontWeight: 'semibold',
    },
    ourPartners: {
      fontSize: ['xs', 'md'],
      color: 'delta.800',
      textTransform: ['uppercase', 'none'],
      fontWeight: 'semibold',
    },
    weAccept: {
      fontSize: ['xs', 'md'],
      color: 'delta.800',
      textTransform: ['uppercase', 'none'],
      fontWeight: 'semibold',
    },
    weAcceptBox: {
      mb: ['0', '2'],
    },
  },
  sizes: {},
  variants: {},
};
