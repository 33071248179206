import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonShowMoreProps: CustomButtonProps = {
  pt: '1',
};

export const FlexBetCard: CSSObject = {
  rowGap: '2',
  w: '100%',
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};

export const ButtonShowMore: CSSObject = {
  border: '0px',
  bg: 'rgba(26,32,42,255)',
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  color: 'white',
  fontFamily: 'accent',
  h: '10',
  lineHeight: 'none',
  minW: '100%',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'bold',
  borderRadius: 'lg',
  _hover: {
    color: 'white',
    bgGradient:
      'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
  },
  '&::after': {
    height: '16px',
    width: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'beta.400',
    color: 'rgba(26,32,42,1)',
    fontWeight: 'bold',
    content: `"+"`,
    borderRadius: 'sm',
    marginLeft: '10px',
  },
};
