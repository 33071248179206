import { FlexProps, StackProps } from '@chakra-ui/react';
import { styleImports } from './Nav.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type NavSchema = Partial<{
  wrapper: StackProps;
  link: FlexProps;
}>;

export const navStyles: NavSchema = {
  wrapper: {
    'data-testid': 'allSports-nav-wrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.navStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },

  link: {
    'data-testid': 'allSports-nav-link',

    ...(() => {
      try {
        return styleImports[themeName]?.navStyles?.link;
      } catch (err) {
        return {};
      }
    })(),
  },
};
