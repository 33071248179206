import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    bgGradient: 'linear(180deg, delta.400 0%, delta.500 100%)',
    boxShadow:
      '0px 2px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
    color: 'delta.900',
    borderRadius: 'md',
    border: 'none',
    borderColor: 'delta.500',
    fontWeight: 'medium',
    fontSize: '2xs',
    h: '6',
    px: '1.5',
    textShadow: 'none',

    sx: {
      '&&': {
        minW: '20',
      },

      '&&[data-isbonusbet="true"]': {
        bg: 'delta.800',
        boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.4)',
        color: 'delta.500',
        borderRadius: 'md',
        border: '1px',
        _disabled: {
          boxShadow: 'none',
          bg: 'blackAlpha.300',
          color: 'alpha.300',
          textTransform: 'uppercase',
          h: '7',
          opacity: '1',
          border: 'none',
        },
      },
    },
  },
  buttonBetPropositionClosedProps: {
    h: '8',
  },
  buttonStakeProps: {
    variant: 'tertiary',
    minW: 8,
    sx: { '&&': { h: 8 } },
  },
  spanPropositionType: { color: 'white' },
  spanRaceInfo: {
    color: 'white',
  },
  iconMysteryBetFooter: {
    color: 'alpha.200',
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    lineHeight: 'normal',
    w: '100%',
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'delta.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    px: 3,
    py: 1.5,
    fontSize: 'xs',
  },
  flexWrapperMysteryBetFooterBetReview: {
    fontSize: 'xs',
    w: '100%',
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'delta.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    py: 1.5,
  },
  betCardSVG: {
    fill: 'delta.800',
  },
};

export const BetCardContainer = (): CSSObject => ({
  // TODO: Fix theme colours
  background: 'linear-gradient(180deg, #3B63C4 0%, #2C4B96 100%)',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: 'md',
  color: 'white',
  mb: '-2.5',

  '.state-prior': { color: 'white' },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'delta.400',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  color: 'alpha.100',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  // TODO: Fix theme colours
  bg: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
