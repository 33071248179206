import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  textTransform: 'uppercase',
  color: 'yellow.900',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const SelectionPopupContainer: CSSObject = {
  zIndex: 'docked',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  borderColor: 'blackAlpha.300',
  pos: 'relative',
  _notFirst: {
    borderTopStyle: 'dotted',
    borderTopWidth: '1px',
  },
  _before: {
    border: 'inherit',
    borderColor: 'whiteAlpha.300',
    content: '""',
    h: '1px',
    left: '0',
    pos: 'absolute',
    right: '0',
    top: '0',
    _before: {
      borderColor: 'red',
    },
  },

  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    },
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-7px',
  width: '24px',
  height: '24px',
  color: 'black',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  mt: '-3px',
};

export const InfoHeader: CSSObject = {
  h: '9',
  alignItems: 'center',
  px: '4',
  py: '3',
  bg: 'blackAlpha.300',
  boxShadow: 'rgba(255, 255, 255, 0.15) 0px 1px 0px 0px',
};

export const FlexWrapper: CSSObject = {
  _before: {
    bgGradient: 'linear(to-b, rgba(0, 0, 0, 0.00), #000)',
    opacity: '0.2',
    bottom: '65px',
    content: '""',
    h: '4',
    left: '0',
    pointerEvents: 'none',
    pos: 'absolute',
    right: '0',
    transitionDuration: 'normal',
    transitionProperty: 'common',
  },
  rowGap: ['7', 'unset'],
  borderTop: '1px solid',
  borderColor: 'whiteAlpha.300',
};