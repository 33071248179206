import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: {
    px: 2.5,
  },
  buttonCashOutAmount: { px: 2 },
  flexOddsBoostBanner: {
    bgGradient: 'linear(to-b, alpha.400, alpha.600)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 1px #13274B inset',
    borderTop: 'none',
    fontSize: 'md',
    color: 'beta.400',
    fontWeight: 'bold',
    fontFamily: 'Fraunces',
  },
  iconOddsBoost: {
    fill: 'beta.400',
  },
  textOddsBoost: {
    color: 'beta.400',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.500',
};

export const RaceTime: CSSObject = {
  color: 'gray.400',
  mt: '1',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'beta.400',
  color: 'white',
};

export const BonusBetText: CSSObject = {
  color: 'beta.400',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  color: 'alpha.400',
  borderColor: 'alpha.900',
  bg: 'gray.700',
};

export const BetReturn: CSSObject = {
  bg: 'white',
  color: 'alpha.900',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: '2xs',
  border: '1px',
  borderColor: 'alpha.600',
};

export const BoxBetReturnValue: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'white',
};

export const BoxBetReturnText: CSSObject = {
  pl: '1',
  py: '1',
  color: 'gray.700',
};
