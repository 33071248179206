import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  my: '1',
  gap: '1',
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'secondary',
  fontWeight: 'normal',
  lineHeight: 'normal',
  boxShadow:
    '0px 4px 10px 0px rgba(0,0,0,0.3), 0px 0px 0px 1px rgba(55, 140, 255, 0.5)',
};
