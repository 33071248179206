import { CSSObject, TextProps } from '@chakra-ui/react';
import takeAPungBG from '@/assets/goldenrush/images/backgrounds/takeAPunt.png';
import mobileTakeAPungBG from '@/assets/goldenrush/images/backgrounds/mobileTakeAPunt.png';

export const FlexWrapper: CSSObject = {
  pl: 0,
  mt: ['4', '5'],
  h: ['423px', '288px'],
  border: 'none',
  bgImage: [`url(${mobileTakeAPungBG})`, `url(${takeAPungBG})`],
  bgRepeat: 'no-repeat',
  bgSize: '100% 100%',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: '3',
  my: ['2.5', '3'],
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, gamma.400, gamma.600)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(255, 255, 255, 0.50) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  color: 'beta.900',
};

export const textSloganProps: TextProps = {
  bgGradient: 'linear(to-b, gamma.400, gamma.600)',
  position: 'relative',
  textAlign: 'center',
  bgClip: 'text',
  fontSize: ['5xl', '76px'],
  fontFamily: 'Angkor',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  letterSpacing: ['8px', '15px'],
  _after: {
    content: '""',
    position: 'absolute',
    w: ['70%', '80%'],
    h: '0.5',
    bgGradient: 'linear(to-b, gamma.400, gamma.600)',
    boxShadow: '0px 0.75px 0.75px 0px rgba(255, 255, 255, 0.60) inset',
    bottom: ['-1', '0'],
    left: ['13%', '7%'],
  },
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.500',
  fontSize: 'xs',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'lg',
  color: 'gamma.500',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'extrabold',
  maxW: ['90dvw', '60dvw', '50dvw', '40dvw', '500px'],
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'md',
  textShadow: 'none',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: '100%',
  m: 'auto',
};

export const FlexMiscWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['3.5', '2'],
};

export const HeadingSlogan: CSSObject = {
  fontFamily: 'Angkor',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: ['xl', '4xl'],
  letterSpacing: ['1', '2'],
  bgGradient: 'linear(to-b, gamma.400, gamma.600)',
  backgroundClip: 'text',
};
