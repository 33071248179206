import { css } from 'styled-components';

// TODO: Remove
export const eventsContainer = css`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  margin-bottom: var(--bc-sizes-10);
  column-gap: var(--bc-sizes-5);
  padding: var(--bc-sizes-1) 0 var(--bc-sizes-1) var(--bc-sizes-1);
  margin-top: -4px;
  margin-left: -4px;
`;
