import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { updateEmailAddress } from '../services/account.actions';
import { getStrings } from '@/helpers/utils';
import Grid from '../../../components/Grid/Grid';
import { Button } from '../../../components/Button/Button';
import TextInput from '../../../components/TextInput';
import updateEmailSchema, {
  UpdateEmailSchema,
} from '../services/schemas/updateEmailSchema';
import { UpdateEmailContainer } from './Styles';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { useAppDispatch } from '../../../hooks/useRedux';
import { usePunterData, useUserData } from '@/store/AuthStore';

const UpdateEmail: FC = () => {
  const authUser = useUserData();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const punterProfile = usePunterData();
  const [
    {
      Generic,
      Account: { UpdateEmail: UpdateEmailStrings },
    },
  ] = getStrings();

  const handleFormSubmit = async (
    values: UpdateEmailSchema,
    { setSubmitting }: FormikHelpers<UpdateEmailSchema>
  ) => {
    if (!punterProfile) return;

    setSubmitting(true);

    const success = await dispatch(
      updateEmailAddress({
        newEmail: values.email,
        existingPassword: values.password,
        existingProfile: punterProfile,
      })
    ).unwrap();

    if (success) navigate('/account/settings');

    setSubmitting(false);
  };

  return (
    <AccountWrapper
      pageTitle={UpdateEmailStrings.PageTitle}
      pageHeader={UpdateEmailStrings.Header}
    >
      <Grid row>
        <Grid column sm={12} md={6}>
          <UpdateEmailContainer>
            <Formik<UpdateEmailSchema>
              initialValues={{ email: authUser?.email || '', password: '' }}
              validationSchema={updateEmailSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleFormSubmit}
            >
              {({
                handleChange,
                handleSubmit,
                isSubmitting,
                errors,
                values,
              }) => (
                <>
                  <TextInput
                    type="email"
                    inputId="email"
                    defaultValue={values.email}
                    errorText={errors.email}
                    label={Generic.Email}
                    onChange={handleChange}
                    dataCy="updateEmail"
                  />

                  <TextInput
                    type="password"
                    inputId="password"
                    defaultValue={values.password}
                    errorText={errors.password}
                    label={Generic.Password}
                    onChange={handleChange}
                  />

                  <Button
                    isFullWidth
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    data-cy="updateEmailSubmit"
                  >
                    {Generic.SaveChanges}
                  </Button>
                </>
              )}
            </Formik>
          </UpdateEmailContainer>
        </Grid>
        <Grid column md={6} hiddenSM />
      </Grid>
    </AccountWrapper>
  );
};

export default UpdateEmail;
