import { Hide } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './conditionals/Logo/template';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { setIsOpen } from '../../../redux/sideNav.slice';
import { useHeader } from './services/Header.hooks';
import {
  BoxWrapper,
  FlexContainer,
  LinkLogo,
  FlexButtonGroup,
  IconButtonMenu,
  FlexLeftGroup,
  SkeletonButtonGroup,
} from './styles/Header.styles';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';

import HeaderTemplate from './templates/template';
import { createTemplate } from '@/lib/templateSwitch';

export default function Header() {
  const notAuthButtons = createTemplate(HeaderTemplate.NotAuthButtons);
  const authed = createTemplate(HeaderTemplate.Authed);

  const { isAuthenticated, isLoading, isHome } = useHeader();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isOpen } = useAppSelector((state) => state.sidenav);

  if (IS_MOBILE_APP) return null;
  const isHomePage = location.pathname === '/';

  return (
    <BoxWrapper>
      <FlexContainer isHome={isHome}>
        <FlexLeftGroup>
          <Hide above="lg">
            <IconButtonMenu
              onClick={() => dispatch(setIsOpen(!isOpen))}
              isHome={isHomePage}
            />
          </Hide>

          <LinkLogo to="/">
            <Logo />
          </LinkLogo>
        </FlexLeftGroup>

        <FlexButtonGroup>
          {(() => {
            if (isLoading)
              return (
                <SkeletonButtonGroup
                  aria-busy="true"
                  aria-live="polite"
                  role="status"
                  speed={0.7}
                />
              );
            if (isAuthenticated) return <>{authed}</>;
            return <>{notAuthButtons}</>;
          })()}
        </FlexButtonGroup>
      </FlexContainer>
    </BoxWrapper>
  );
}
