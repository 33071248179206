import React from 'react';
import { Text, Hide, Show } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '../../../../../../helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import {
  BoxHeadingWrapper,
  FlexWrapper,
  HeadingSlogan,
  FlexFeatureCardWrapper,
  FlexIllustrationWrapper,
  textSloganProps,
} from '../styles/Featured.styles';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  illustration: <FlexIllustrationWrapper />,
  featureCard: {
    wrapper: <FlexFeatureCardWrapper />,
    heading: (
      <>
        <BoxHeadingWrapper>
          <HeadingSlogan>
            <FormattedMessage id="main.featured.header" />
          </HeadingSlogan>
          <Text {...textSloganProps}>
            <Show above="md">
              <FormattedMessage id="main.featured.jam" />
            </Show>
            <Hide above="md">
              <FormattedMessage id="main.featured.rumble" />
            </Hide>
          </Text>
        </BoxHeadingWrapper>
      </>
    ),
    feature: <FeaturedMatch />,
  },
};

export default template;
