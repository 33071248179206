import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  border: '2px solid',
  borderColor: 'beta.400',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 1px #13274B inset',
  color: 'white',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bg: 'alpha.600',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
};
export const TextTitle: CSSObject = {
  color: 'white',
};
