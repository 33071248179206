import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  color: 'gray.700',
  fontSize: 'xs',
  mt: '5',
  '.chakra-checkbox__control': {
    borderColor: 'gray.400',
  },
  '&&': {
    '.chakra-checkbox__control[data-checked]': {
      bg: 'white',
      borderColor: 'gray.700',
    },
  },
};
