import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/gigabet/images/background/bg.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), linear-gradient(180deg, #0C1C3C 0%, #0C1B38 100%)`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          '@media (max-width: 767px)': {
            background: `rgba(49, 61, 104, 1)`,
          },
        },
      }}
    />
  );
}
