import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  color: 'alpha.600',
  bg: 'white',
  boxShadow: 'md',
  mt: '0',
  mb: '2',
  _first: {
    mb: '-2',
  },
};

export const BoxWrapper: CSSObject = { bg: 'alpha.50', color: 'alpha.800' };

export const ButtonViewMore: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
  fontSize: 'xs',
  borderTop: 'none',

  textTransform: 'uppercase',

  _hover: { bg: 'alpha.700' },
  _active: { bg: 'alpha.400' },
};
