import navInnerBg from '@/assets/junglebet/images/backgrounds/clothTexture.png';
import navBg from '@/assets/junglebet/images/backgrounds/woodTexture.png';
import vineLeft from '@/assets/junglebet/images/backgrounds/leafLeft.png';
import vineRight from '@/assets/junglebet/images/backgrounds/leafRight.png';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: '100%',
    mt: ['0', null, null, '4'],
    mb: ['4', null, null, '0'],
    bg: ['transparent', null, null, `url(${navBg})`],
    py: ['0', null, null, '3.5'],
    px: ['2', null, null, '3.5'],
    borderRadius: ['none', null, null, '2xl'],
    border: ['unset', null, null, '6px solid'],
    borderColor: ['unset', null, null, 'transparent'],
    boxShadow: [
      'none',
      null,
      null,
      '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px -1px 2px 0px rgba(186, 72, 9, 0.70) inset, 0px 0px 0px 3px #6D2B05 inset, 0px 5px 0px 0px #541E01, 0px 2px 2px 0px #D17917 inset',
    ],
    sx: {
      '&': {
        backgroundSize: `100% 120%`,
      },
    },
    _before: {
      bgImg: `url(${vineLeft})`,
      h: '60',
      w: '32',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-60px',
      right: '0',
      top: '-5px',
      zIndex: '-1',
      overflowX: 'hidden',
      display: ['none', null, null, 'block'],
    },
    _after: {
      bgImg: `url(${vineRight})`,
      h: '60',
      w: '32',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      right: '-60px',
      top: '-5px',
      zIndex: '-1',
      overflowX: 'hidden',
      display: ['none', null, null, 'block'],
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    sx: {
      '&&': {
        px: '0.5',
        borderRadius: ['0px', '0px', '0px', 'md'],
        boxShadow: [
          'none',
          null,
          null,
          '0px 5px 4px 0px rgba(0, 0, 0, 0.20), 0px 3px 0px 0px #A87A42, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
        ],
        marginTop: '0',
        marginBottom: '0.5',
        bg: [
          'transparent',
          null,
          null,
          `linear-gradient(180deg, var(--bc-colors-delta-100), var(--bc-colors-delta-200)), url(${navInnerBg})`,
        ],
        backgroundBlendMode: 'overlay',
      },
    },
  },
};
