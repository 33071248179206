import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'delta.500';
      }
      if (state === 'ended') {
        return 'red.400';
      }
      // 'prior'
      return 'transparent';
    },
    color: () => {
      if (state === 'inprogress') {
        return 'delta.800';
      }
      if (state === 'ended') {
        return 'white';
      }
      // 'prior'
      return 'gray.700';
    },
  },
});
