import React from 'react';
import { getStrings } from '@/helpers/utils';
import Title from '../../components/Title';

export default function PageTitle() {
  const [
    {
      Onboarding: { Step3 },
    },
  ] = getStrings();

  return <Title>{Step3.Subheader}</Title>;
}
