import api from '../api';
import {
  TNextToGoParams,
  TNextToGoResponse,
  TNextToJumpParams,
  TNextToJumpResponse,
} from './nextToJump.types';

/**
 * Next to jump (Races)
 */
export const ntjEndpoint = '/punter/races/next-to-jump';

export const queryNTJ = (params?: TNextToJumpParams) =>
  api.get<TNextToJumpResponse>(ntjEndpoint, { params }).then((res) => res.data);

/**
 * Next To Go (Sport Next to Jump)
 */
export const ntgEndpoint = '/punter/sports/next-to-jump-cursor';

export const queryNTG = (params?: TNextToGoParams) =>
  api.get<TNextToGoResponse>(ntgEndpoint, { params }).then((res) => res.data);
