import React from 'react';
import { HourglassBottom } from '@styled-icons/boxicons-solid/';
import { getStrings } from '@/helpers/utils';
import {
  BetPendingApprovalText,
  PendingBetContainer,
  PendingBetIcon,
} from './Footer.styles';

export const PendingBet = () => {
  const [{ BetSlip: Strings }] = getStrings();
  return (
    <PendingBetContainer>
      <PendingBetIcon w="4" h="4" mr="2" as={HourglassBottom} />
      <BetPendingApprovalText>
        {Strings.betSlipBetCard.pendingApproval}
      </BetPendingApprovalText>
    </PendingBetContainer>
  );
};
