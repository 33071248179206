import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import { TPasswordTooltip, TPasswordRulesIcon } from '../services/SignUp.types';

export const SignupAcknowledgementButton: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  bg: 'beta.600',
  border: '2px solid',
  borderColor: 'beta.400',
  color: 'blackAlpha.600',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'base',
  fontFamily: 'Caveman',
  fontWeight: 'normal',
  letterSpacing: 'wide',
  fontSize: 'sm',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  _hover: {
    bg: 'beta.700',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'beta.700' : 'epsilon.500',
  p: '3',
  borderRadius: 'base',
  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-beta-700) !important'
      : 'var(--bc-colors-epsilon-500) !important',
  },
});

export const PasswordRule = (): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: 'white',
});

export const BoxManualAddressContainer: CSSObject = {
  bg: 'none',
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'normal',
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);
