import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonOutsideArea = {
  boxShadow: 'none',
  border: 'none',
};

export const FlexContainer = (isHome: boolean) => ({
  bg: isHome
    ? 'transparent'
    : [
        'linear-gradient(180deg, var(--bc-colors-delta-400), var(--bc-colors-delta-600))',
        'transparent',
      ],
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },

  w: ['100%', null, 'unset'],
  h: ['auto', null, null, null],
  py: '3',
  px: ['2', null, null, '0'],
  pb: '0',
});

export const ButtonGroup: CSSObject = {
  pb: ['0', null, '4'],
  pos: 'relative',
  gap: '1',
};

export const ButtonGroupBets: CSSObject = {
  gap: '1',
};

export const buttonSignUpProps: ButtonProps = {
  px: ['2', '4'],
  h: ['46px', null, null, null, '34px'],
};

export const buttonLogInProps: ButtonProps = {
  variant: 'secondary',

  px: ['2', '4'],
  h: ['46px', null, null, null, '34px'],
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'secondary',
  h: '46px',
  pl: '3',
  pr: ['1', '2'],
  color: 'orange.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.55)',
  bgGradient: 'linear(to-b, gamma.400, gamma.500)',

  sx: {
    '&': {
      _hover: {
        color: 'orange.700',
        bgGradient: 'linear(to-b, gamma.600, gamma.600)',
      },
    },
    '.btn-span': {
      flexDir: 'column',
      gap: 0,
      alignSelf: 'center',
      lineHeight: 1,
    },

    '.span-balance': {
      mb: '0.5',
    },

    '.span-bonus': {
      fontFamily: 'body',
      fontSize: '11px',
      fontWeight: 'bold',

      svg: {
        pos: 'relative',
        bottom: '-1px',
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  boxSize: '46px',
  px: 0,
  color: 'blackAlpha.700',

  sx: {
    '.btn-span': {
      flexDir: 'column',
      gap: 0,
      alignSelf: 'center',
      lineHeight: 1,
    },

    '.span-bet': {
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: '11px',
    },

    '.span-bet-count': {
      fontSize: '18px',
    },
  },
};

export const buttonBetSlipProps: ButtonProps = {
  boxSize: '46px',
  px: 0,

  sx: {
    '&&': {
      ml: 0,
    },

    '.btn-span': {
      flexDir: 'column',
      gap: 0,
      alignSelf: 'center',
      lineHeight: 1,

      p: {
        fontSize: '18px',

        _first: {
          fontFamily: 'body',
          fontWeight: 'bold',
          fontSize: '11px',
        },
      },
    },
  },
};

export const ButtonBetSlip: CSSObject = {
  color: 'white',
  border: '2px solid',
  boxShadow: 'md',
  transition: 'all .3s ease-in-out',
  height: ['37px', null, '45px'],
  fontSize: ['2xs', null, 'xs'],

  '.span-bet-count': { fontSize: ['xs', null, 'md'] },

  '&:hover, &:active': {
    color: 'white',
  },
};

export const ButtonBets: CSSObject = {
  color: 'white',
  boxShadow: 'lg',
  transition: 'all .3s ease-in-out',
  height: ['37px', null, '45px'],
  borderRadius: 'sm',
  textTransform: 'uppercase',

  '.span-bet': {
    textTransform: ['uppercase', null, 'capitalize'],
    fontSize: ['2xs', null, 'xs'],
  },
  '.span-bet-count': { fontSize: ['xs', null, 'md'] },

  '&:hover, &:active': {
    color: 'white',
  },
};

export const ButtonQuickAccess: CSSObject = {
  '&&': {
    minW: '100px',
    alignItems: 'flex-start',
    animation: '.5s ease-in-out',
    borderRadius: 'sm',
    border: '2px solid',
    borderColor: 'white',
    bg: 'delta.300',
    color: 'gamma.900',
    boxShadow: 'lg',
    textShadow: 'none',
    height: ['37px', null, '45px'],

    '&:hover': {
      bgPos: 'bottom center',
      boxShadow: 'sm',
    },

    '&[aria-expanded="true"]': {
      bg: 'delta.300',

      '.chevron-icon': { color: 'gamma.900' },
      '.span-balance': { color: 'gamma.900' },
      '.account-text': { color: 'gamma.900' },
    },

    '&:after': { bg: 'delta.300' },

    '.span-balance': { color: 'gamma.900', fontSize: ['xs', null, 'md'] },
    '.span-bonus': {
      color: 'gamma.900',
      fontSize: ['2xs', null, 'sm'],
    },
    '.account-text': { color: 'gamma.900' },
    '.chevron-icon': { color: 'gamma.900' },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'black',
  boxSize: 'auto',
  ml: '-1.5',
  mr: '-3.5',

  '& svg': {
    zIndex: '9999',
    boxSize: '7',
    color: ['alpha.900', 'gamma.500'],
  },
});

export const LinkLogo: CSSObject = {
  w: ['unset', null, '160px'],
  display: ['block', null, null, 'none'],
};

export const FlexButtonGroup: CSSObject = {
  pb: ['0', null, '2'],
  pos: 'relative',
};

export const TextButton: CSSObject = {
  color: 'blackAlpha.700',
};

export const TextButtonNumber: CSSObject = {
  fontSize: ['xs', null, 'md'],
  color: 'blackAlpha.700',
};
