import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  '&&': {
    fontSize: heading ? 'sm' : 'xs',
    fontWeight: heading ? 'medium' : 'normal',
    color: 'gray.500',
    lineHeight: '1.3',
  },
});

export const RadioText: CSSObject = {
  '&&': {
    fontSize: 'xs',
    mb: '2',
    fontWeight: 'normal',
  },
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
  bg: 'gray.200',
};

export const DepositLimitContainer: CSSObject = {
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  mt: '3',
  p: '4',

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    h: '9',
    color: 'gray.700',
    borderLeftRadius: 'md',
  },
  '.chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    fontSize: 'xs',
  },
  '#limitAmount-input': {
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'gray.50',
    borderColor: 'gray.300',
    color: 'gray.600',
    borderLeftRadius: 'none',
    '&&:hover, &&:focus': {
      bg: 'gray.50',
    },
  },
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: ['4', null, '5'],
  pt: [null, null, '1'],
  gap: '2',
};

export const ButtonSubmit: CSSObject = {
  _after: {
    bg: 'none',
    boxShadow: 'none',
  },
  bgGradient: 'linear(to-b,delta.300,delta.600)',
  color: 'epsilon.300',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
  _hover: {
    bg: 'delta.300',
    color: 'delta.500',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    border: '2px solid',
    borderColor: 'epsilon.500',
  },
};
