import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  p: '2',
  ml: ['1', '3', null, null],
  mr: '3',
  mb: '3',
  background: 'white',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  border: '2px solid',
  borderColor: 'beta.600',
  boxSizing: 'border-box',
};

export const CheckboxBoxed: CSSObject = {
  color: 'alpha.700',
  justifyContent: 'flex-end',
  borderColor: 'gray.300',
  bg: 'gray.50',
  width: '20px',
  height: '20px',
  mb: '2',
  mr: '2.5',
  mt: '2.5',
  '.chakra-checkbox__control': {
    width: '20px',
    height: '20px',
    bg: 'gray.50',
    borderWidth: '1px',
    borderColor: 'gray.300',
    '&[data-checked]': {
      width: '20px',
      height: '20px',
      bg: 'gray.50',
      color: 'gray.700',
      borderColor: 'gray.300',
    },
  },
};

export const TextBoxed: CSSObject = {
  color: 'alpha.900',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'semibold',
};

export const SelectFieldContainer: CSSObject = {
  mb: '2',
  gap: '1',
  width: 'fit-content',
  '.chakra-button': {
    display: 'flex',
    maxWidth: '8',
    height: '9',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 'sm',
    fontWeight: 'black',
    color: '#255505',
  },
};
