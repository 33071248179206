import {
  BoxProps,
  ButtonProps,
  FlexProps,
  ImageProps,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './Footer.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type FooterSchema = Partial<{
  container: FlexProps;
  footer: FlexProps;
  // fix
  footerContentContainer: any;
  // fix
  footerBar: any;
  footerLink: TextProps;
  footerLinkService: TextProps;
  footerLinkTel: TextProps;
  customFooterLink: TextProps;
  footerText: TextProps;
  footerMobile: BoxProps;
  footerLinks: BoxProps;
  // fix
  nav: any;
  blurb: FlexProps;
  blurbHeading: TextProps;
  blurbHeadingAge: TextProps;
  blurbHeadingThink: TextProps;
  blurbContent: TextProps;
  emailUs: TextProps;
  partnerWrapper: BoxProps;
  partnerImages: ImageProps;
  imageWrapper: FlexProps;
  copyright: TextProps;
  copyrightContainer: FlexProps;
  quickLinks: TextProps;
  ourPartners: TextProps;
  weAccept: TextProps;
  weAcceptBox: BoxProps;
  LinkText: TextProps;
  stackGap: string;
  footerDownloadImage: ImageProps;
  // Blurbs
  BoxBlurbContentContainer: BoxProps;
  quickLinksBoxProps: BoxProps;
  BoxPartnersAcceptContainer: BoxProps;
  ButtonPdf: ButtonProps;
  BlurbContentWrapper: BoxProps;
  appVersion: TextProps;
  FooterPartnerBox: BoxProps;
  FooterPartnerInnerBox: BoxProps;
}>;

export const footerStyles: FooterSchema = {
  container: {
    'data-testid': 'footerStyles-container',
    ...(styleImports?.[themeName]?.footerStyles?.container ?? {}),
  },
  footer: {
    'data-testid': 'footerStyles-footer',
    pt: 6,
    pb: 28,

    ...(styleImports?.[themeName]?.footerStyles?.footer ?? {}),
  },
  footerContentContainer: {
    'data-testid': 'footerStyles-footerContentContainer',
    label: 'footerContentContainer',
    flexDir: ['column', null, 'row', null],
    mb: ['8', 'unset'],
    ...(styleImports?.[themeName]?.footerStyles?.footerContentContainer ?? {}),
  },
  footerBar: {
    'data-testid': 'footerStyles-footerBar',
    ...(styleImports?.[themeName]?.footerStyles?.footerBar ?? {}),
  },
  footerLink: {
    'data-testid': 'footerStyles-footerLink',
    color: 'yellow.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'inline',
    _hover: {
      textDecoration: 'underline',
    },
    ...(styleImports?.[themeName]?.footerStyles?.footerLink ?? {}),
  },
  footerLinkService: {
    'data-testid': 'footerStyles-footerLinkService',
    color: 'beta.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'inline',
    _hover: {
      textDecoration: 'underline',
    },
    ...(styleImports?.[themeName]?.footerStyles?.footerLinkService ?? {}),
  },
  footerLinkTel: {
    'data-testid': 'footerStyles-footerLinkTel',
    display: 'none',
    ...(styleImports?.[themeName]?.footerStyles?.footerLinkTel ?? {}),
  },
  footerText: {
    'data-testid': 'footerStyles-footerText',
    ...(styleImports?.[themeName]?.footerStyles?.customFooterLink ?? {}),
  },
  customFooterLink: {
    'data-testid': 'footerStyles-customFooterLink',
    ...(styleImports?.[themeName]?.footerStyles?.customFooterLink ?? {}),
  },
  footerMobile: {
    'data-testid': 'footerStyles-footerMobile',
    ...(styleImports?.[themeName]?.footerStyles?.footerMobile ?? {}),
  },
  footerLinks: {
    'data-testid': 'footerStyles-footerLinks',
    ...(styleImports?.[themeName]?.footerStyles?.footerLinks ?? {}),
  },
  nav: {
    'data-testid': 'footerStyles-nav',
    alignItems: 'stretch',
    order: ['2', 'unset'],
    '> a': { fontSize: 'sm' },
    ...(styleImports?.[themeName]?.footerStyles?.nav ?? {}),
  },
  blurb: {
    'data-testid': 'footerStyles-blurb',
    textAlign: ['center', 'right'],
    fontSize: 'sm',
    order: ['1', 'unset'],
    ml: [null, 'auto'],
    mb: ['8', null, 'unset'],
    mr: '5',
    width: [null, 'auto', '45%'],
    pr: [null, '7'],
    ...(styleImports?.[themeName]?.footerStyles?.blurb ?? {}),
  },
  blurbHeading: {
    'data-testid': 'footerStyles-blurbHeading',
    ml: [null, 'auto'],
    mt: '5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', 'unset'],
    ...(styleImports?.[themeName]?.footerStyles?.blurbHeading ?? {}),
  },
  blurbHeadingAge: {
    'data-testid': 'footerStyles-blurbHeadingAge',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    borderColor: 'whiteAlpha.50',
    border: '1px',
    borderRadius: '50%',
    height: '10',
    width: '10',
    mr: '2',
    ...(styleImports?.[themeName]?.footerStyles?.blurbHeadingAge ?? {}),
  },
  blurbHeadingThink: {
    'data-testid': 'footerStyles-blurbHeadingThink',
    fontWeight: 'black',
    fontSize: 'md',
    pt: '2',
    ...(styleImports?.[themeName]?.footerStyles?.blurbHeadingThink ?? {}),
  },
  blurbContent: {
    'data-testid': 'footerStyles-blurbContent',
    mx: '0',
    px: ['2', '0'],
    ml: [null, null],
    mr: ['inherit'],
    mt: ['4', null, '0'],
    textAlign: ['center', 'left'],
    ...(styleImports?.[themeName]?.footerStyles?.blurbContent ?? {}),
  },
  emailUs: {
    'data-testid': 'footerStyles-emailUs',
    display: 'flex',
    justifyContent: ['center', null, 'unset'],
    textAlign: ['center', 'left'],
    flexDirection: ['column', null, 'row'],
    mb: ['5', null, '0'],
    fontSize: 'md',
    ...(styleImports?.[themeName]?.footerStyles?.emailUs ?? {}),
  },
  partnerWrapper: {
    'data-testid': 'footerStyles-partnerWrapper',
    width: '12',
    height: '7',
    bg: 'white',
    mr: [null, null, '2'],
    borderRadius: '2',
    ...(styleImports?.[themeName]?.footerStyles?.partnerWrapper ?? {}),
  },
  partnerImages: {
    'data-testid': 'footerStyles-partnerImages',
    height: '100%',
    padding: '1',
    margin: 'auto',
    ...(styleImports?.[themeName]?.footerStyles?.partnerImages ?? {}),
  },
  imageWrapper: {
    'data-testid': 'footerStyles-imageWrapper',
    justifyContent: ['center', 'left'],
    flexWrap: 'wrap',
    gap: '1',
    pt: ['0', null, '1'],
    ...(styleImports?.[themeName]?.footerStyles?.imageWrapper ?? {}),
  },
  copyright: {
    'data-testid': 'footerStyles-copyRight',
    fontWeight: 'bold',
    fontSize: 'xs',
    textAlign: 'right',
    ...(styleImports?.[themeName]?.footerStyles?.copyright ?? {}),
  },
  copyrightContainer: {
    'data-testid': 'footerStyles-copyRightContainer',
    justifyContent: ['center', 'left'],
    ...(styleImports?.[themeName]?.footerStyles?.copyrightContainer ?? {}),
  },
  quickLinks: {
    'data-testid': 'footerStyles-quickLinks',
    fontWeight: 'bold',
    fontSize: 'lg',
    pb: '2',
    ...(styleImports?.[themeName]?.footerStyles?.quickLinks ?? {}),
  },
  ourPartners: {
    'data-testid': 'footerStyles-ourPartners',
    fontWeight: 'bold',
    fontSize: 'lg',
    pb: '2',
    ...(styleImports?.[themeName]?.footerStyles?.ourPartners ?? {}),
  },
  weAccept: {
    'data-testid': 'footerStyles-weAccept',
    fontWeight: 'bold',
    fontSize: 'lg',
    pb: '2',
    ...(styleImports?.[themeName]?.footerStyles?.weAccept ?? {}),
  },
  weAcceptBox: {
    'data-testid': 'footerStyles-weAcceptBox',
    mb: '6',
    ...(styleImports?.[themeName]?.footerStyles?.weAcceptBox ?? {}),
  },
  stackGap: '3',
  LinkText: {
    'data-testid': 'footerStyles-LinkText',
    fontSize: 'sm',
    fontWeight: 'bold',
    ...(styleImports?.[themeName]?.footerStyles?.LinkText ?? {}),
  },
  footerDownloadImage: {
    'data-testid': 'footerStyles-footerDownloadImage',
    border: '2px',
    borderColor: 'white',
    borderRadius: 'md',
    filter: 'drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.3))',
    ...(styleImports?.[themeName]?.footerStyles?.footerDownloadImage ?? {}),
  },
  BoxBlurbContentContainer: {
    'data-testid': 'blurb-BoxBlurbContentContainer',
    ...(styleImports?.[themeName]?.footerStyles?.BoxBlurbContentContainer ??
      {}),
  },
  quickLinksBoxProps: {
    'data-testid': 'blurb-QuickLinksContainer',
    mb: '6',
    ...(styleImports?.[themeName]?.footerStyles?.quickLinksBoxProps ?? {}),
  },
  BoxPartnersAcceptContainer: {
    'data-testid': 'blurb-BoxPartnersAcceptContainer',
    ...(styleImports?.[themeName]?.footerStyles?.BoxPartnersAcceptContainer ??
      {}),
  },
  ButtonPdf: {
    'data-testid': 'blurb-ButtonPdf',
    display: 'flex',
    fontSize: 'sm',
    justifyContent: 'start',
    _hover: {
      textDecoration: 'underline',
    },
    ...(styleImports?.[themeName]?.footerStyles?.ButtonPdf ?? {}),
  },
  BlurbContentWrapper: {
    'data-testid': 'blurb-BlurbContentWrapper',
    ...(styleImports?.[themeName]?.footerStyles?.BlurbContentWrapper ?? {}),
  },
  appVersion: {
    'data-testid': 'blurb-AppVersion',
    ...(styleImports?.[themeName]?.footerStyles?.appVersion ?? {}),
  },
  FooterPartnerBox: {
    'data-testid': 'footerStyles-FooterPartnerBox',
    ...(styleImports?.[themeName]?.footerStyles?.FooterPartnerBox ?? {}),
  },
  FooterPartnerInnerBox: {
    'data-testid': 'footerStyles-FooterPartnerInnerBox',
    ...(styleImports?.[themeName]?.footerStyles?.FooterPartnerInnerBox ?? {}),
  },
};
