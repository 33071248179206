import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  color: 'white',
  p: '2',
  '.chakra-checkbox__label': {
    color: 'white',
  },
  '.chakra-form-control label': {
    color: 'white',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    border: '0',
    width: '1rem',
    height: '1rem',
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _hover: {
      bg: 'alpha.400',
    },
    _checked: {
      boxShadow: 'none',
      bg: 'alpha.500',
      color: 'white',
      _hover: {
        bg: 'alpha.400',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
  button: {
    h: '10',
    borderRadius: 'md',
    bg: 'beta.400',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    color: 'alpha.600',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: 'sm',

    '&:hover': {
      bg: '#D8A714 !important',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset !important',
      color: '#004072 !important',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.30) !important',
    },

    '&[data-active="false"]': {
      bg: 'beta.400',
      boxShadow:
        '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    },

    '&[data-active="true"]': {
      bg: 'alpha.600',
      color: 'beta.400',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
      '&&:hover': {
        bg: 'alpha.800',
        color: 'alpha.200',
      },
    },
  },
};
