import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  '.chakra-radio__input + span': {
    bg: 'gray.200',
    borderColor: 'gray.200',
  },
  '.chakra-radio[data-checked] .chakra-radio__input + span': {
    bg: 'gamma.500',
    borderColor: 'gamma.500',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__left-addon, .chakra-input__right-addon': {
    bg: 'gray.400',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  '.chakra-input': {
    bg: 'white',
    border: '1px',
    borderColor: 'gray.400',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  '.chakra-form__error-message': {
    fontSize: 'xs',
    mb: 2,
  },
};
