import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  px: ['2.5', null, null, '3'],
  py: '2',
  pt: '0',
  h: '9',
};

export const ButtonFilter = () => ({
  color: 'alpha.300',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'alpha.600',
  color: selected ? 'alpha.300' : 'transparent',
  boxShadow: '0px -1px 1px 0px #B96C91 inset',
});

export const IconRace = () => ({
  filter: 'drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25))',
});

export const Divider: CSSObject = {
  bg: 'alpha.300',
  display: 'block',
  h: '7',
  my: 'auto',
  mx: '4',
};

export const TextFilterLabel: CSSObject = {
  color: 'inherit',
  fontFamily: 'Montserrat',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: '0',
};
