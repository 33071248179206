import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/titanbet/images/onboarding/onboardingAside.png';
import bgMobile from '@/assets/titanbet/images/onboarding/onboardingAsideMb.png';

export const WrapperAside: CSSObject = {
  bg: [`url(${bgMobile})`, null, `url(${bgDesktop}), rgba(41, 44, 44, 1)`],
  bgSize: ['cover', null, '100%, 100%'],
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['beta.500', null, 'unset'],
  h: ['50vw', '55vw', 'full'],
  borderLeftRadius: [null, null, '2px'],
  borderRightRadius: ['unset'],
  pt: ['4', null, '6'],
  flex: 1,
};
