import * as betroyale from './Placed.styles.betroyale';
import * as puntcity from './Placed.styles.puntcity';
import * as questbet from './Placed.styles.questbet';
import * as sterlingparker from './Placed.styles.sterlingparker';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betroyale,
  puntcity,
  questbet,
  sterlingparker,
};
