import {
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  ChakraComponent,
  Divider,
  DividerProps,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  IconButtonProps,
  IconProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './Withdrawal.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import Input from '../../../../components/FormElements/Input';
import { Button, CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export const Heading = chakra(Text, {
  label: 'withdrawal-Heading',
  baseStyle: () => ({
    fontSize: '2xl',
    fontWeight: 'semibold',
    mb: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.Heading;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const BackButton: ChakraComponent<'button', IconButtonProps> = chakra(
  IconButton,
  {
    baseStyle: {
      label: 'withdrawal-BackButton',
      mr: '1',
      ...(() => {
        try {
          return styleImports[themeName]?.BackButton;
        } catch (err) {
          return {};
        }
      })(),
    },
  }
);

export const DividerWithdrawal = chakra(Divider, {
  label: 'withdrawal-DividerWithdrawal',
  baseStyle: () => ({
    mb: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.DividerWithdrawal;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', DividerProps>;

export const TextBankAccountName = chakra(Text, {
  label: 'withdrawal-TextBankAccountName',
  baseStyle: () => ({
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextBankAccountName;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextDescription = chakra(Text, {
  label: 'withdrawal-TextDescription',
  baseStyle: () => ({
    mb: '3',
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.TextDescription;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const PageContent = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.pageContent};
`;

export const PageHeading = styled.h4`
  ${({ theme }) => theme.pages.withdrawFunds.pageHeading};
`;

export const Subheading = chakra(Text, {
  label: 'withdrawal-Subheading',
  baseStyle: () => ({
    fontSize: 'xs',
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.Subheading;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const SubheadingWrapper = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.subheadingWrapper};
`;

export const BSBWrapper = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.bsbInputWrapper};
`;

export const AccNumberWrapper = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.accNumberInputWrapper};
`;

export const HighlightSpan = styled.span`
  cursor: pointer;
  ${({ theme }) => theme.pages.withdrawFunds.highlightSpan};
`;

export const HeaderWrapper = chakra(Box, {
  label: 'withdrawal-HeaderWrapper',
  baseStyle: () => ({
    display: 'block',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const InputWrapperWithdrawal = chakra(Box, {
  label: 'withdrawal-InputWrapperWithdrawal',
  baseStyle: () => ({
    flex: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.InputWrapperWithdrawal;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const InputWithdrawal = chakra(Input, {
  label: 'withdrawal-InputWithdrawal',
  baseStyle: () => ({
    flex: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.InputWithdrawal;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const AmountInputWrapperWithdrawal = chakra(Box, {
  label: 'withdrawal-AmountInputWrapperWithdrawal',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.AmountInputWrapperWithdrawal;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const buttonAddAccountProps: CustomButtonProps = {
  'data-testid': 'withdrawal-buttonAddAccountProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonAddAccountProps;
    } catch (err) {
      return {};
    }
  })(),
};

/**
 * @deprecated
 * Use buttonAddAccountProps instead
 */
export const ButtonAddAccount = chakra(Button, {
  label: 'withdrawal-ButtonAddAccount',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonAddAccount;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const buttonWithdrawalProps: CustomButtonProps = {
  'data-testid': 'withdrawal-buttonWithdrawalProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonWithdrawalProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const ButtonWithdrawal = chakra(Button, {
  label: 'withdrawal-ButtonWithdrawal',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonWithdrawal;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const BankError = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.bankError};
`;

export const BankCardContainer = chakra(RadioGroup, {
  label: 'withdrawal-BankCardContainer',
  baseStyle: () => ({
    w: 'full',
    bg: 'white',
    width: '100%',
    cursor: 'pointer',
    ...(() => {
      try {
        return styleImports[themeName]?.BankCardContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'input', RadioGroupProps>;

export const BankRadioWrapper = chakra(Flex, {
  label: 'withdrawal-BankRadioWrapper',
  baseStyle: () => ({
    w: 'full',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: '5',
    px: '2',
    borderBottom: '1px solid',
    borderColor: 'gray.200',
    ...(() => {
      try {
        return styleImports[themeName]?.BankRadioWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const BankRadio = chakra(Radio, {
  label: 'withdrawal-BankRadio',
  baseStyle: () => ({
    borderBottom: '1px solid',
    borderColor: 'gray.200',
    mr: '3',
    ...(() => {
      try {
        return styleImports[themeName]?.BankRadio;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'input', RadioProps>;

export const BankDeleteIcon = chakra(Icon, {
  label: 'withdrawal-BankDeleteIcon',
  baseStyle: () => ({
    boxSize: '6',
    right: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.BankDeleteIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', IconProps>;

export const FlexBankDetailsContainer = chakra(Flex, {
  label: 'withdrawal-FlexBankDetailsContainer',
  baseStyle: () => ({
    gap: '2',
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBankDetailsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TextBankAccountLabel = chakra(Text, {
  label: 'withdrawal-TextBankAccountLabel',
  baseStyle: () => ({
    mr: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.TextBankAccountLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextBankAccountDetail = chakra(Text, {
  label: 'withdrawal-TextBankAccountDetail',
  baseStyle: () => ({
    fontWeight: 'normal',
    ml: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.TextBankAccountDetail;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.contentContainer};
`;

export const HiddenBsb = styled.span`
  ${({ theme }) => theme.pages.withdrawFunds.hiddenBsb};
`;

export const HiddenBankNumber = styled.span`
  ${({ theme }) => theme.pages.withdrawFunds.hiddenBankNumber};
`;

export const Nickname = styled.p`
  ${({ theme }) => theme.pages.withdrawFunds.nickname};
`;

export const WithdrawListHeading = chakra(Flex, {
  label: 'withdrawal-WithdrawListHeading',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.WithdrawListHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const ListContainer = chakra(Box, {
  label: 'withdrawal-ListContainer',
  baseStyle: () => ({
    w: ['100%', null, '300px'],
    boxShadow: 'sm',
    p: '3',
    mt: '3',
    bg: 'gray.100',
    ...(() => {
      try {
        return styleImports[themeName]?.ListContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const ListContainerHeading = chakra(Text, {
  label: 'withdrawal-ListContainerHeading',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ListContainerHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const FlexWithdrawalWrapper = chakra(Flex, {
  label: 'withdrawal-FlexWithdrawalWrapper',
  baseStyle: () => ({
    w: 'full',
    justifyContent: 'space-between',
    flexDir: ['column', null, 'row'],
    ...(() => {
      try {
        return styleImports[themeName]?.FlexWithdrawalWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;
