import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FlexNav,
  ButtonNavItem,
  IconAccountOverviewBtn,
  TextAccountOverviewBtn,
} from './Styles/Nav.styles';
import { useLiveChat } from '@/hooks/useLivechat';

type TNav = { onClose: () => void };

export default function Nav({ onClose }: TNav) {
  const navigate = useNavigate();
  const { showLiveChat } = useLiveChat();

  return (
    <FlexNav>
      {[
        {
          onClick: () => navigate('/account/overview'),
          text: 'qam.nav.accountOverview',
          icon: 'qamAccountOverview',
          cy: 'link-qa-account-overview',
        },
        {
          onClick: showLiveChat,
          text: 'qam.nav.support',
          icon: 'qamSupport',
          cy: 'link-qa-support',
        },
        {
          onClick: () => navigate('/account/my-bets'),
          text: 'qam.nav.myBets',
          icon: 'qamMyBets',
          cy: 'link-qa-my-bets',
        },
      ].map((data) => (
        <ButtonNavItem
          key={`qa-${data.text}`}
          onClick={() => {
            onClose();
            data.onClick();
          }}
          data-cy={data.cy}
        >
          <IconAccountOverviewBtn name={data.icon} />
          <TextAccountOverviewBtn>
            <FormattedMessage id={data.text} />
          </TextAccountOverviewBtn>
        </ButtonNavItem>
      ))}
    </FlexNav>
  );
}
