import React from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  BoxDropdown,
  BoxWrapper,
  ButtonDropdown,
  CheckboxFilters,
  TextTitle,
} from './styles/Filters.styles';
import { availableFilters } from './services/Filters.config';
import { useFilters } from './services/Filters.hooks';
import ThemeFilters from './templates/template';
import { createTemplate } from '@/lib/templateSwitch';

export default function Filters() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [setFilter, { filters }] = useFilters();
  const template = createTemplate(ThemeFilters);

  return (
    <BoxWrapper>
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <ButtonDropdown
            variant="unstyled"
            onClick={onToggle}
            isActive={isOpen}
          >
            {template}
          </ButtonDropdown>
        </PopoverTrigger>

        <PopoverContent
          sx={{ bg: 'transparent', border: 0, left: '1', top: '-1', w: 'auto' }}
        >
          <BoxDropdown>
            <Stack direction="row" spacing="5">
              {availableFilters.map((af) => (
                <Box key={af.title}>
                  <TextTitle>{af.title}</TextTitle>

                  <Stack spacing="2">
                    {af.filters.map((f) => (
                      <CheckboxFilters
                        key={`filter-${f.name}`}
                        name={typeof f.name === 'string' ? f.name : ''}
                        isFormik={false}
                        sxWrapper={{ mb: '0' }}
                        isChecked={filters?.[f.name]}
                        onChange={() => setFilter(f.name, !filters?.[f.name])}
                      >
                        {f.label}
                      </CheckboxFilters>
                    ))}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </BoxDropdown>
        </PopoverContent>
      </Popover>
    </BoxWrapper>
  );
}
