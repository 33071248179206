export const Breadcrumb = {
  parts: ['link', 'item', 'separator'],
  baseStyle: {
    item: {
      color: 'white',
    },
    link: {
      color: 'alpha.600',
      textAlign: 'center',
      fontSize: '10px',
      display: 'inline-block',
      fontWeight: '700',
      textTransform: 'uppercase',
      textDecoration: 'underline',
    },
    separator: {
      color: 'epsilon.500',
      mx: '2',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
