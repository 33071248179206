import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { keys } from '@/api/api.keys';
import { TBetCloudErrorResponse } from '@/api/api.types';
import { querySportsMatches } from './sportDetailsMatches';
import {
  TSportMatchesParams,
  TSportMatchesResponse,
} from './sportDetailsMatches.types';

export const useQuerySportMatches = (
  params: TSportMatchesParams,
  options?: Omit<
    UseQueryOptions<TSportMatchesResponse, AxiosError<TBetCloudErrorResponse>>,
    'queryKey' | 'queryFn'
  >
) => {
  const query = useQuery(
    [keys.sportDetailsMatches, params],
    () => querySportsMatches(params),
    options
  );
  return query;
};
