import { EBetState } from '../../../../api/punter/punter.types';

export const tabs = [
  {
    value: [EBetState.Pending],
    display: 'Pending',
  },
  {
    value: [
      EBetState.Settled,
      EBetState.Voided,
      EBetState.Cancelled,
      EBetState.CashedOut,
    ],
    display: 'Resulted',
  },
];
