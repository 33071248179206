import React, { FC } from 'react';
import {
  BannerContainer,
  TitleContainer,
  SubtitleContainer,
  TextContainer,
  Title,
  Subtitle,
  Text,
  BackgroundImage,
  OverlayImage,
} from './Styles';

const Banner: FC = () => (
  <BannerContainer>
    <TitleContainer>
      <Title />
    </TitleContainer>
    <SubtitleContainer>
      <Subtitle />
    </SubtitleContainer>
    <TextContainer>
      <Text />
    </TextContainer>
    <BackgroundImage />
    <OverlayImage />
  </BannerContainer>
);

export default Banner;
