import { CSSObject } from '@chakra-ui/react';

export const MobileListContainer: CSSObject = {
  flexDir: 'column',
  width: '100%',
  mx: '4',
  mb: '4',
};

export const RaceLinkContainer: CSSObject = {
  bg: 'white',
  px: '1.5',
  py: '2.5',
  color: 'beta.500',
  mx: '2',
  mb: '1.5',
  w: 'auto',
};

export const Icon: CSSObject = {
  boxSize: '8',
  mr: '3',
  color: 'white',
  bg: 'beta.500',
  p: '2',
  borderRadius: 'full',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
};

export const RaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  borderRadius: 'base',
  bg: 'black',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  mr: '1',
  fontSize: '2xs',
  h: '5',
};

export const StartTime: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'black',
};

export const NextToJumpTileContainer: CSSObject = {
  gridTemplateColumns: '44px 1fr 1fr 1fr 1fr',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon raceName raceName timer raceNumber"
  `,
  gap: '2',
  mx: '1',
  mb: '1',
  border: '1px',
  borderColor: 'gray.200',
  p: '1.5',
  alignItems: 'center',
  width: '100%',
};
