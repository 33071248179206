import { CSSObject } from '@chakra-ui/react';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'whiteAlpha.900' },
};

export const TabContainer: CSSObject = {
  borderBottom: '0',
  mt: '2.5',
  px: '1.5',
  py: '2',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  borderRadius: 'md',
  borderShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  color: 'gamma.900',
  textShadow: 'none',
  h: '5',
  fontWeight: 'bold',
  fontSize: '2xs',
  ml: '1',
  px: '2',
  py: '1',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  boxShadow: 'none',

  _hover: {
    bg: 'transparent',
    color: 'alpha.600',
  },

  _focus: {
    boxShadow: 'none',
  },

  sx: {
    '&&': {
      borderRadius: 'base',
      bg: 'transparent',
    },

    '&[aria-selected="true"]': {
      color: 'white',
      textShadow: '0px 2px 3px rgba(0, 0, 0, 0.20)',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.45)',
      bg: 'alpha.600',
    },
  },
};

export const TabWrapper: CSSObject = {
  height: '21px',
};
