import { Image } from '@chakra-ui/react';
import React from 'react';
import logo from '@/assets/puntgenie/images/logo.svg';

export default function index() {
  return (
    <Image
      src={logo}
      mx="auto"
      sx={{
        maxW: ['160px'],
        mb: ['6'],
        mt: ['11'],
      }}
    />
  );
}
