/* eslint-disable no-nested-ternary */
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { isPast, parseISO, format } from 'date-fns';
import toast from 'react-hot-toast';
import { keys } from '@/api/api.keys';
import api from '@/api/api';
import { useQueryPunterAccountOverview } from '@/api/punter/punter.hooks';
import { DepositLimitData } from '@/views/onboarding/services/onboarding.types';
import { centsToDollars, dollarsToCents } from '@/helpers/utils';

export const useDepositLimitModalController = () => {
  const intl = useIntl();

  const [manualClose, setManualClose] = useState(false);

  const { isLoading: isLoadingOV, data: dataOV } =
    useQueryPunterAccountOverview();
  const { isLoading: isLoadingDL, data: dataDL } = useQuery(
    [keys.depositLimit, dataOV],
    () =>
      api
        .get<DepositLimitResponse>('/punter/account/deposit-limit')
        .then((res) => res.data),
    {
      enabled: !!dataOV,
    }
  );

  const hasDL = !!dataDL?.deposit_limit_current;

  /** Show/Hide logic */
  const repromptDate = new Date(
    dataOV?.deposit_limit_reprompt_at ?? new Date()
  );

  const isOpen = (() => {
    if (dataOV?.deposit_limit_reprompt_at === null) return false;
    if (manualClose === true) return false;
    if (isLoadingDL || isLoadingOV) return false;
    if (isPast(repromptDate)) return true;
    return false;
  })();
  /** Show/Hide logic */

  /** API */
  const mutateReprompt = () =>
    api
      .post('/punter/account/deposit-limit/reprompt-acknowledge', null)
      .catch(() => undefined);

  const mutateDepositLimit = (body: Nullable<DepositLimitData>) =>
    api.post<DepositLimitMutationResponse>(
      '/punter/account/deposit-limit',
      body
    );
  /** API */

  /** Business Logic */
  const acknowledgePrompt = () => {
    setManualClose(true);
    mutateReprompt().catch(() => undefined);
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setSubmitting(true);

    const depositLimit = String(values.depositLimit);
    const isSettingLimit = depositLimit === 'true';

    const body = {
      amount: isSettingLimit
        ? dollarsToCents(String(values.limitAmount))
        : null,
      frequency: isSettingLimit
        ? hasDL
          ? dataDL.deposit_limit_frequency_current
          : parseInt(String(values.limitDuration).trim().replace(' ', ''), 10)
        : null,
    };

    const noDepositLimitSet = !hasDL && depositLimit === 'false';

    if (!noDepositLimitSet && ['true', 'false'].includes(depositLimit)) {
      mutateDepositLimit(body)
        .then((res) => {
          const msg = (() => {
            const isLess = !hasDL
              ? true
              : dollarsToCents(values.limitAmount as string) <
                (dataDL?.deposit_limit_current ?? 0);

            if (depositLimit === 'true' && isLess) {
              return intl.formatMessage({
                id: 'depositLimit.modal.toast.success.simple',
              });
            }

            const parsedDate = parseISO(res.data?.deposit_limit_cooldown_ends);
            const formattedDate = format(parsedDate, 'dd/MM/yyyy');
            const formattedTime = format(parsedDate, 'HH:mm');

            return intl.formatMessage(
              {
                id: 'depositLimit.modal.toast.success.props',
              },
              {
                formattedDate,
                formattedTime,
              }
            );
          })();

          // If they don't have a limit and they're not setting one, no toast.
          if (!(!hasDL && depositLimit === 'false')) {
            toast.success(msg);
          }
        })
        .catch(() => {
          toast.error(
            intl.formatMessage({ id: 'depositLimit.modal.toast.fail.simple' })
          );
        });
    }

    acknowledgePrompt();
    setSubmitting(false);
  };
  /** Business Logic */

  /** Config */
  const existingDepositLimitOptions = hasDL
    ? [
        {
          value: 'keep',
          label: intl.formatMessage(
            {
              id: 'depositLimit.modal.radio.limit.keep',
            },
            {
              limit: centsToDollars(dataDL?.deposit_limit_current).replace(
                '.00',
                ''
              ),
              frequency: dataDL?.deposit_limit_frequency_current,
            }
          ),
          id: 'depositLimitKeep',
        },
        {
          value: 'true',
          label: intl.formatMessage({
            id: 'depositLimit.modal.radio.limit.change',
          }),
          id: 'depositLimitChange',
        },
        {
          value: 'false',
          label: intl.formatMessage({
            id: 'depositLimit.modal.radio.limit.remove',
          }),
          id: 'depositLimitRemove',
        },
      ]
    : undefined;
  /** Config */

  return {
    isOpen,
    hasDL,
    acknowledgePrompt,
    handleSubmit,
    existingDepositLimitOptions,
  };
};

/** Types */

type DepositLimitResponse = {
  deposit_limit_current: number;
  deposit_limit_frequency_current: number;
  deposit_limit_waiting: number;
  deposit_limit_frequency_waiting: number;
  deposit_limit_cooldown_ends: string;
  deposit_limit_active: string;
  deposit_limit_last_updated: string;
};

type DepositLimitMutationResponse = {
  is_onboarded: boolean;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  street_number: string;
  street_name: string;
  suburb: string;
  state: string;
  post_code: string;
  date_of_birth: string;
  deposit_limit_current: number;
  deposit_limit_waiting: number;
  deposit_limit_active: string;
  deposit_limit_frequency_current: number;
  deposit_limit_frequency_waiting: number;
  deposit_limit_cooldown_ends: string;
};

type Nullable<T> = { [K in keyof T]: T[K] | null };
