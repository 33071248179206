import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  color: 'gamma.500',
  bg: 'beta.500',
  borderWidth: '3px',
  borderColor: 'beta.100',
  borderRadius: '2xl',
  boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
  mt: '0',
  mb: '2',
  _first: {
    mb: '-2',
  },
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  borderTop: 'none',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  textTransform: 'uppercase',
  fontSize: '2xs',

  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.700)',
    color: 'white',
  },
};
