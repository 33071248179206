import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px dashed',
      borderTopColor: 'gray.200',
      p: '2',

      _first: { borderTop: 0 },
    }),

    ...(isSelected && {
      boxShadow: 'none',
    }),

    ...(!!isSelected && {
      boxShadow: 'none',
    }),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',

    _first: {
      color: 'gray.700',
      fontWeight: 'bold',

      'button:hover &': { color: 'gray.700' },

      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      bg: isSelected ? 'beta.100' : 'beta.500',
      p: 2,
      w: 'full',
      border: isSelected ? 'unset' : '2px solid',
      borderColor: isSelected ? 'beta.200' : 'white',
      borderRadius: 'sm',
      color: isSelected ? 'beta.800' : 'white',
      fontWeight: 'black',
      textShadow: isSelected ? 'unset' : '0px 2px 3px rgba(0, 0, 0, 0.3)',
      boxShadow: isSelected
        ? 'inset 0px 2px 4px #A97F81'
        : 'inset 0 -15px 15px -7px rgb(0 0 0 / 15%), 0 2px 5px rgb(0 0 0 / 15%)',

      transition: 'all, .3s ease-in-out',

      _hover: {
        bg: isSelected ? 'beta.200' : 'beta.700',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
