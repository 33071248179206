import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'md',
  bg: 'beta.700',
};

export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '133%',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {
  color: 'whiteAlpha.500',
};

export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
};

export const TextDeductions: CSSObject = {
  color: 'white',
};

export const TextScratchedInfo: CSSObject = {
  color: 'white',
  fontSize: '2xs',
};
export const ScratchedText: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
};
