import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  color: 'white',
  fontWeight: 'medium',
  height: 'auto',
  border: 'none',
  ml: '1.5',

  '& .state-inprogress': {
    color: 'black',
  },
  svg: {
    pt: '1',
  },
};

export const FlexHeadingMiscWrapper: CSSObject = {
  zIndex: 'overlay',
  '.chakra-badge.state-inprogress': {
    color: 'black',
  },
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', 'row'],
    alignItems: ['flex-start', 'center'],

    '.timestamp': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
  bg: 'whiteAlpha.400',
  border: 'none',
};

export const TextSportName: CSSObject = {
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

export const TextVS: CSSObject = {
  alignSelf: 'end',
  color: 'white',
};
