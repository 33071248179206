import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: 'body',
    fontSize: '2xs',
    px: '2',
    py: '0',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    borderBottom: 'none',
    _hover: {
      color: 'white',
    },
    sx: {
      '&[data-active="true"]': {
        bgGradient: 'linear(to-b, alpha.700, alpha.700)',
        color: 'whiteAlpha.700',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.07), 0px 2px 3px 0px rgba(0, 0, 0, 0.30) inset',
        _hover: {
          color: 'whiteAlpha.700',
        },
      },
    },
  },
}

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};
