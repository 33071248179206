import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  pt: ['0', null, '4', null, null],
  px: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  mt: ['2', null, '0', null, null],
  px: ['2', null, '0', null, null],
  pb: ['4', null, '0', null, null],
  ml: 0.5,
  bgGradient: [null, null, 'linear(to-b, #0067B2, #00528D)', null, null],
  borderRadius: ['none', null, 'md', null, null],
  gap: ['1', null, '0', null, null],
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bgGradient: isActive
    ? 'linear(to-b, alpha.900, alpha.900)'
    : [
        'linear(to-b, beta.400, beta.400)',
        null,
        'linear(to-b, transparent, transparent)',
        null,
        null,
      ],
  color: isActive
    ? ['alpha.200', null, 'beta.400', null, null]
    : ['alpha.600', null, 'white', null, null],
  textTransform: ['uppercase', null, 'capitalize', null, null],
  fontFamily: 'Bowlby One',
  fontWeight: 'normal',
  fontSize: ['2xs', null, 'sm', null, null],
  py: ['2', null, '3', null, null],
  pl: isActive ? ['2', null, '3', null, null] : ['2', null, '4', null, null],
  pr: ['2', null, '4', null, null],
  borderBottomWidth: ['none', null, '1px', null, null],
  borderBottomStyle: 'dashed',
  borderColor: 'alpha.800',
  borderLeft: ['unset', null, isActive && '3px solid'],
  borderLeftColor: ['unset', null, isActive && 'beta.500'],
  borderBottom: [isActive && null, null, '1px dashed'],
  borderBottomColor: [isActive && 'beta.500', null, 'blackAlpha.500'],
  borderTop: ['none', null, isActive ? 'none' : '1px dashed'],
  borderTopColor: ['none', null, isActive ? 'unset' : 'whiteAlpha.200'],
  borderRadius: ['base', null, 'none', null, null],
  boxShadow: [
    '0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset',
    null,
    'none',
    null,
    null,
  ],
  _first: {
    borderTopRadius: ['base', null, 'md', null, null],
    borderTop: 'none',
  },
  _last: {
    borderBottomRadius: ['base', null, 'md', null, null],
    borderBottom: 'none',
  },
  transition: 'all 0.3s ease-in-out',
  '&:hover, &:focus': {
    color: isActive ? 'alpha.600' : 'alpha.800',
    bg: isActive ? 'beta.400' : 'beta.400',
    borderColor: isActive ? 'beta.400' : 'beta.400',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'alpha.800',
  color: 'alpha.200',
  textShadow: '0px -1px 0px rgba(0, 0, 0, 0.25)',
  border: 'none',
  boxShadow:
    '0px 1px 2px 0px rgba(255, 255, 255, 0.40),0px 1px 0px 0px #000 inset',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: 'xs',
  textAlign: 'center',
  fontFamily: 'roboto',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '90px',
  px: '1',
  ml: '2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  pt: ['0', null, '4', null, null],
  px: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};
