import { CSSObject } from '@chakra-ui/react';

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  px: ['0', '2'],
  mb: [null, '2'],
  bg: ['transparent', 'gray.100'],
  borderRadius: [null, 'lg'],
  border: [null, 'none'],
  borderStyle: 'dashed',
  borderColor: 'blackAlpha.300',
};

export const RunnerName: CSSObject = {
  color: 'gray.700',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {};

export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'gray.700',
};

export const TextDeductions: CSSObject = {
  color: 'gray.700',
};

export const TextScratchedInfo: CSSObject = {
  color: 'gray.700',
  fontSize: '2xs',
};
export const ScratchedText: CSSObject = {
  color: 'gray.700',
  fontWeight: 'semibold',
};

export const GreyhoundRunnerSilk: CSSObject = {
  borderRadius: 'base',
  background: 'white',
  filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))',
};
