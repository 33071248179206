import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  HeadingSlogan,
  ImageMascot,
  SpanSlogan,
} from '../../../styles/Featured.styles';
import { useAuth } from '../../../../../../../../hooks/useAuth';
import camel from '../../../../../../../../assets/puntgenie/images/homeFeaturedRacing/racing-camel.png';

export const FeaturedBanner = () => {
  const { isAuthenticated } = useAuth();
  return (
    <>
      <HeadingSlogan sx={{ ...(!isAuthenticated && { height: '50%' }) }}>
        <FormattedMessage
          id="promo.racing.featuredRace"
          values={{
            bu: (msg) => <SpanSlogan>{msg}</SpanSlogan>,
          }}
        />
      </HeadingSlogan>
      {isAuthenticated && <ImageMascot src={camel} />}
    </>
  );
};
