import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/goldenbet888/images/logo888.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image src={logo} alt={THEME_NAME.goldenbet888} ml={-1} pb={1} />
      </Show>
      <Hide above="lg">
        <Image src={logo} alt={THEME_NAME.goldenbet888} ml={1} h="16" />
      </Hide>
    </Link>
  );
}
