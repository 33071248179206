import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    bgGradient: 'linear(to-b, #FC603F, #A60E14)',
    color: 'epsilon.300',
    fontFamily: 'body',
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    sx: {
      '&&': {
        borderRadius: '8px',
        _hover: {
          bgGradient: 'linear(to-b, alpha.600, alpha.600)',
          color: 'beta.100',
        },
      },
      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, #FC603F, #A60E14)',
        boxShadow:
          '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px #773053 inset',
        h: '24px',
      },

      '&[data-active="true"]': {
        border: 'transparent',
        bgGradient: 'linear(to-b, alpha.800, alpha.800)',
        boxShadow:
          '0px 2px 7px 0px #210A15 inset, 0px -1px 0px 0px #B96C91 inset',
        color: 'beta.100',
        h: '24px',
      },
    },
  },
};

export const FlexHeadingRow: CSSObject = {
  bg: 'delta.700',
};

export const FlexHeading: CSSObject = {
  bgGradient: 'linear-gradient(to-b, alpha.400, alpha.600)',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  color: 'white',
  top: ['12', '84px'],
  borderTopRadius: 'sm',
  position: 'inherit',
  mb: 'unset',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '133%',
};

export const IconHeading: CSSObject = {
  boxSize: '1.8rem',
  color: 'delta.50',
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  borderRadius: 'md',
  p: '1.5',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  p: '1',
  mx: ['2', '4'],
  mb: '2',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: 'md',
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'gray.500',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = () => ({
  borderColor: 'gray.300',

  _last: {
    borderRight: 'none',
  },
  _hover: {
    background: 'gray.300',
  },
  '.chakra-badge.state-ended': {
    color: 'white',
  },
  '.chakra-badge.state-inprogress': {
    color: 'beta.800',
  },
  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
  },
});

export const FlexRow: CSSObject = {
  bg: 'beta.300',
  borderRadius: 'unset',
  mb: 'unset',
  borderStyle: ['dotted', 'solid'],
  '& > *': {
    borderTop: ['unset', null, '1px'],
    borderRight: ['unset', null, '1px'],
    borderColor: 'gamma.50',
  },

  _last: {
    '& > *': {
      borderBottom: ['unset', null, '1px'],
      borderBottomColor: [null, null, 'beta.300'],
    },
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'gray.300'],
  borderTop: '1px',
  bg: 'white',
  py: '1.5',
};

export const CenterTH = () => ({
  bg: 'delta.700',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  py: '2.5',
});

export const ButtonChevron: CSSObject = {
  color: 'blackAlpha.700',
  bg: 'beta.50',
  borderRadius: 'base',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
