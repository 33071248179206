import { CSSObject } from '@chakra-ui/react';

export const GridLayout: CSSObject = {
  background: [null, null, 'beta.900'],
  width: ['100dvw', null, '760px'],
  minH: '100dvh',
  borderRadius: [null, null, 'lg'],
  display: 'flex',
  alignItems: [null, null, 'center'],
  mx: 'auto',
};
