import { CSSObject } from '@chakra-ui/react';
import background from '../../../assets/sugarcastle/images/backgrounds/maintenanceBackground@2x.png';
import backgroundAtRetina from '../../../assets/sugarcastle/images/backgrounds/maintenanceBackgroundMB@2x.png';
import backgroundError from '../../../assets/sugarcastle/images/backgrounds/errorbackground@2x.png';
import backgroundAtRetinaError from '../../../assets/sugarcastle/images/backgrounds/errorMB@2x.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexPageWrapper = (isMaintenance: boolean): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: [
    `url(${!isMaintenance ? backgroundAtRetinaError : backgroundAtRetina})`,
    null,
    `url(${!isMaintenance ? backgroundError : background})`,
  ],
  bgRepeat: 'no-repeat',
  bgSize: 'cover',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: [
      `url(${!isMaintenance ? backgroundAtRetinaError : backgroundAtRetina})`,
      null,
      `url(${!isMaintenance ? backgroundError : background})`,
    ],
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  gap: '0',
  h: '100vh',
  justifyContent: 'center',
  mx: 'auto',
  mb: [null, null, '12'],
  maxW: 'container.lg',
  px: ['4', null, '28'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  img: {
    pos: 'absolute',
    top: '7vh',
  },
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Imperator',
  fontSize: ['5xl', null, '6xl'],
  lineHeight: 'normal',
  mt: '4',
  mb: ['4', null, '6'],
  textTransform: 'uppercase',
  fontWeight: 'base',
  textShadow:
    '0px 3.2258036136627197px 3.2258036136627197px rgba(0, 0, 0, 0.25)',
  bgGradient: 'linear(to-b, #FFE5B8 0%, #876433 92.55%)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  '&[data-ismaintenance="true"]': {
    w: [null, null, '875px'],
    fontSize: ['4xl', null, '6xl'],
  },
};

export const ContentBodyText: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  mb: ['7', '10'],
  w: ['256px', '270px'],
  wordWrap: 'break-word',
  b: {
    color: 'gamma.500',
  },
  '&[data-ismaintenance="false"]': {
    w: ['264px', '267px'],
  },
};

export const ButtonRefresh: CSSObject = {
  borderRadius: '7px !important',
  color: 'white',
  bg: 'linear-gradient(180deg, #F28B33 0%, #A5540E 100%)',
  boxShadow:
    '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',

  '&:active, &:hover': {
    bg: 'beta.700',
    boxShadow:
      '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'lg',
  color: 'beta.400',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['3.5', null, '5'],
};

export const buttonRefreshProps: CustomButtonProps = {
  variant: 'solid',
  px: '2',
  py: '5',
  w: '24',
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  boxShadow:
    '0px 0px 0px 4px gamma.700 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
  color: 'beta.400',
  fontFamily: 'Fraunces',
};
