import React from 'react';
import { Global } from '@emotion/react';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          bg: 'gray.300',
          backgroundPosition: 'top',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          '@media (min-width: 768px)': {
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          },
        },
      }}
    />
  );
}
