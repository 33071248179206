import { Box, Text, Flex, chakra } from '@chakra-ui/react';
import { styleImports } from './SignedOut.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const TextTitle = chakra(Text, {
  label: 'signedOut-TextTitle',
  baseStyle: () => ({
    color: 'black',
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextSub = chakra(Text, {
  baseStyle: () => ({
    color: 'black',
    ...(() => {
      try {
        return styleImports[themeName]?.TextSub;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxSignedOut = chakra(Box, {
  baseStyle: () => ({
    bg: 'white',
    h: '500px',
  }),
});

export const FlexSignedOutText = chakra(Flex, {
  baseStyle: () => ({
    p: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexSignedOutText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
