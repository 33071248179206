import { Image } from '@chakra-ui/react';
import React from 'react';
import footerLogo from '@/assets/sterlingparker/images/logoFooter.svg';

export default function index() {
  return (
    <Image
      src={footerLogo}
      maxW={['264px', '264px', '264px', '203px']}
      mt={['0', '0', '0', '3']}
    />
  );
}
