/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Hide } from '@chakra-ui/react';
import { LinkLogo } from '../../Headers/Basic/styles/Basic.styles';
import Logo from '../../Headers/Basic/conditionals/Logo/template';
import { TTemplate } from '../../../helpers/createTemplate';
import { FlexAsideInfo } from '../styles/index';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import CTA from '../CTA';
import { TPageSpecificContent } from '@/views/onboarding/services/onboarding.types';

const template = (pageSpecificContent: TPageSpecificContent): TTemplate => ({
  asideInfo: {
    wrapper: <FlexAsideInfo />,
    logo: {
      wrapper: <LinkLogo to="/" />,
      logo: <Logo />,
    },
    title: <Title id="onboarding.login.title" />,
    subtitle: (
      <>
        <Hide below="md">
          <Subtitle id="onboarding.login.subtitle" />
        </Hide>
      </>
    ),
    cta: (
      <CTA
        messageId={pageSpecificContent.footerInfoId}
        href={pageSpecificContent.ctaHref}
        dataCy={pageSpecificContent.dataCy}
      />
    ),
  },
});

export default template;
