import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: selected ? 'alpha.300' : 'blackAlpha.600',
  border: selected ? '1px' : 'none',
  borderColor: 'cyan.300',
  mt: '1',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  bgGradient: 'linear(180deg, alpha.500, alpha.600)',
  borderLeftRadius: index === 0 ? '12' : '0',
  pl: index === 0 ? '0.5' : '0',
  borderRightRadius: index === numberOfSlides - 1 ? '12' : '0',
  pr: index === numberOfSlides - 1 ? '0.5' : '0',
});

export const CarouselWrapper: CSSObject = {
  '& .control-dots ': {
    bottom: '2',
    marginY: 'px',
  },
  '& .carousel .slider-wrapper ': {
    w: ['99%', null, '809px'],
  },
};
