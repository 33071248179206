import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    bg: 'beta.500',
    borderTopRadius: 'md',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
    pt: ['2', null, '0'],
    pb: ['5', null, '0'],
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: ['6', null, '1'],
      },
    },
  },
  boxWrapper: {
    mx: ['2', null, 'unset'],
    borderRadius: '0',
  },
  textTitle: {
    mt: '0',
    mb: '2',
    color: 'white',
    fontSize: 'md',
    fontWeight: 'medium',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        px: ['2', null, '4'],
        borderRadius: 'md',
        bg: 'beta.500',
        color: 'white',
        boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.25)',
        flexDir: 'column',

        '& .chakra-form__label': {
          color: 'white',
        },

        '& .chakra-checkbox__label': {
          color: 'white',
        },
      },
    },
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
