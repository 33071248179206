import { rest } from 'msw';
import { sportPropositionsEndpoint } from './sportDetailsPropositions';
import { filterData } from './sportDetailsPropositions.mocks';
import { TSportPropositionResponse } from './sportDetailsPropositions.types';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

export const sportDetailsPropositionsHandlers = [
  rest.get(`${baseURL}/${sportPropositionsEndpoint}`, (req, res, ctx) => {
    const { searchParams } = req.url;
    const marketId = searchParams.getAll('market_id');

    return res(
      ctx.status(200),
      ctx.json<TSportPropositionResponse>(filterData({ market_id: marketId }))
    );
  }),
];
