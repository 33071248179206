import { CSSObject, keyframes } from '@chakra-ui/react';
import onboardingAstronaut from '../../../assets/betgalaxy/images/backgrounds/secondary-layout-astronaut.png';
import { EOnboardingPageType } from '../OnboardingWrapper.types';
import { TBoxContent } from './OnboardingLayout.styles';

export const GridWrapper: CSSObject = {
  gridTemplateColumns: ['1fr', null, null, '516px 1fr'],
  gridTemplateRows: ['auto 1fr', '1fr'],
  gridAutoFlow: [null, 'row'],
  gridTemplateAreas: [
    `
          'aside'
          'content'
        `,
    `
          'content aside'
        `,
  ],
  minH: 'full',
  maxW: 'container.xl',
  mx: 'auto',
};

export const BoxAside: CSSObject = {
  display: ['none', null, 'block'],
  backgroundImage: `url(${onboardingAstronaut})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  minH: ['unset', 'full'],
  animation: `${keyframes`
      0% {
        transform: translate(0,0,0);
      }

      50% {
        transform: translate3d(0,-20px,0);
      }
    `} 6s ease-in-out infinite`,
  pt: ['6', '28'],
  pb: ['6', 'unset'],
};

export const BoxContent = ({ pageType }: TBoxContent): CSSObject => ({
  maxW: '500px',
  my: ['0', null, pageType === EOnboardingPageType.Login ? '44' : '7'],
  mx: 'auto',
  ml: [null, null, null, '4'],
  px: ['4', null, '6'],
  py: ['4', null, '7'],
  color: 'white',
  textTransform: 'uppercase',
  border: ['none', null, '2px solid'],
  borderColor: 'white',
  borderRadius: 'xl',

  '.chakra-form__label': {
    color: 'white',
    fontWeight: 'semibold',
  },
});
