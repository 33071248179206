import { BoxProps, ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonOutsideArea = {
  display: 'none',
};

export const FlexContainer = (): CSSObject => ({
  px: ['2.5', null, null, '0'],
  h: '74px',
  pt: 2,
});

export const BoxWrapper: BoxProps = {
  bgGradient: ['linear(to-b, alpha.300, alpha.400)', 'unset'],
  borderBottom: ['2px solid', 'unset'],
  borderColor: ['beta.400', 'transparent'],
};

export const buttonLogInProps: ButtonProps = {
  variant: 'secondary',
  height: '46px',
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  height: '46px',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'secondary',
  borderRadius: 'base',
  color: 'alpha.800',
  height: '46px',
  p: '3',
  sx: {
    '.btn-span': {
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      mr: '1',
    },
    '.span-balance': {
      fontSize: 'sm',
      lineHeight: '14px',
    },
    '.span-bonus': {
      fontFamily: 'body',
      fontSize: 'xs',
      lineHeight: '10px',
      fontWeight: 'extrabold',
    },

    'svg:not(.chevron-icon)': {
      pos: 'relative',
      top: '0.5',
      mr: '0.5',
    },
    '.chevron-icon': {
      pos: 'absolute',
      right: '1.5',
      top: '0',
      bottom: '0',
      margin: 'auto 0',
    },

    '&&[aria-expanded="true"]': {
      borderBottomRadius: '0',
      bg: 'white',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.2)',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
      color: 'rgba(1, 74, 126, 1)',

      zIndex: 'dropdown',
      '.chevron-icon': { transform: 'rotate(180deg)' },

      '&:after': {
        content: `''`,
        display: 'inline-block',
        h: '2',
        left: 0,
        right: 0,
        bottom: '-1.5',
        position: 'absolute',
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  variant: 'secondary',
  color: 'alpha.800',
  height: '46px',
  borderRadius: 'base',
  p: '3',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '2',
    },
    '.span-bet': {
      fontWeight: 'black',
      fontSize: '2xs',
      fontFamily: 'body',
    },
    '.span-bet-count': {
      fontWeight: 'normal',
      fontSize: 'md',
    },
  },
};

export const buttonBetSlipProps: ButtonProps = {
  variant: 'secondary',
  color: 'alpha.800',
  height: '46px',
  borderRadius: 'base',
  p: '3',

  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'black',
      gap: '2',
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'white',
  boxSize: '7',
  ml: '-1',

  '& svg': {
    boxSize: '7',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '1.5',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const buttonQuickAccess: ButtonProps = {
  variant: 'secondary',
};

export const ButtonGroupBets: CSSObject = {
  gap: '1.5',

  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },

  '>*:first-of-type:not(:last-of-type)': {
    borderRightRadius: 'base',
  },
  '>*:not(:first-of-type):last-of-type': {
    borderLeftRadius: 'base',
  },
};

export const ButtonBetSlip: CSSObject = {
  ml: '0',
};

export const ButtonGroup: CSSObject = {
  gap: '1.5',
};

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
};

export const TextButton: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'black',
  fontFamily: 'body',
};

export const TextButtonNumber: CSSObject = {
  fontSize: 'md',
  fontWeight: 'normal',
};
