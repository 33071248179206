import { CSSObject, ButtonProps } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
  color: 'white',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  my: '2.5',
};

export const DepositLimitContainer: CSSObject = {
  p: '4',
};

export const PendingLimitContainer: CSSObject = {
  p: '4',
  color: 'white',
};

export const ModalDivider: CSSObject = {
  display: 'none',
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalSubheading: CSSObject = {
  m: '0',
  p: '0',
};

export const ButtonWrapper: CSSObject = {
  mt: '3',
};

export const CancelButton: CSSObject = {
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.500',
  color: 'alpha.500',
  textShadow: 'none',

  _hover: {
    bg: 'alpha.50',
  },

  _active: {
    bg: 'alpha.100',
  },
};

export const buttonRemoveProps: CSSObject = {
  variant: 'solid',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  color: 'epsilon.300',
};

export const DepositButton: CSSObject = {
  color: 'epsilon.300',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  _hover: {
    bg: 'delta.300',
    color: 'delta.500',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 3px #83090D inset',
  },
  _after: {
    bgGradient: 'linear(to-b, delta.300, delta.600)',
    borderColor: 'epsilon.500',
    color: 'epsilon.300',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 3px #83090D inset',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
    opacity: '1',
  },
};

export const DepositLimitInputContainer: CSSObject = {
  mt: '2',
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'gray.500',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '&&': {
    '.chakra-input__left-addon': {
      bg: 'alpha.300',
      color: 'alpha.600',
      fontSize: 'sm',
      border: 'none',
      borderLeftRadius: 'lg',
    },
    '.chakra-input': {
      borderLeftRadius: 'none',
      bg: 'white',
      borderColor: 'gray.300',
      borderWidth: '1px',
      color: 'alpha.900',
      borderRightRadius: 'lg',
      borderLeft: 'none',
      _hover: {
        bg: 'gray.100',
        borderColor: 'gray.400',
      },
      _focus: {
        bg: 'gray.50',
        borderColor: 'blue.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },
      _placeholder: {
        color: 'gray.700',
      },
    },
  },
};

export const inputProps: Partial<TIndex> = {
  fontWeight: 'bold',
  sxWrapper: {
    '&&': {
      '.chakra-input__left-addon': {
        bg: 'alpha.300',
        px: '4',
        fontWeight: 'bold',
      },
      '.chakra-input': {
        bg: 'white',
        boxShadow: 'none',
        _placeholder: {
          color: 'gray.700',
          fontWeight: 'bold',
        },
      },
    },
  },
};

export const buttonConfirmModalProps: ButtonProps = {
  fontSize: 'xs',
};

export const buttonRemoveModalProps: ButtonProps = {
  fontSize: 'xs',
  p: 'auto',
};
