import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Indicator } from './styles/StepIndicator.styles';

interface StepIndicatorProps {
  steps: number;
  activeStep?: number;
}

const StepIndicator = ({ steps, activeStep = -1 }: StepIndicatorProps) => (
  <Flex>
    {Array.from(Array(steps).keys()).map((step, index) => (
      <Indicator key={`step-${index}`} active={activeStep === index} />
    ))}
  </Flex>
);

export default StepIndicator;
