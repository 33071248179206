import { AxiosError } from 'axios';
import api from '@/api/api';
import {
  TError,
  TMysteryBetParams,
  TMysteryBetResponse,
} from './mysteryBet.types';

export const mysteryBetEndPoint = '/punter/pricing/mystery-bet';
export const queryMysteryBet = (params: TMysteryBetParams) =>
  api
    .get<TMysteryBetResponse>(mysteryBetEndPoint, { params })
    .then((res) => res.data)
    .catch((error: AxiosError<TError>) => {
      // eslint-disable-next-line
      throw error.response?.data.detail;
    });
