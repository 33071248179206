import { CSSObject } from '@emotion/react';

export const TextTitle: CSSObject = {
  '&&': {
    mb: '2',
    zIndex: 'docked',
    fontSize: ['sm', 'md'],
    fontWeight: 'medium',
    justifyContent: 'space-between',
  },
};

export const TextSpan: CSSObject = {
  flexWrap: 'wrap',
  fontFamily: 'unset',
  alignItems: 'center',
};
