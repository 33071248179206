import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },
  FlexCard: {
    overflow: 'hidden',
    my: '1',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, alpha.300, alpha.400)',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    h: 'fit-content',
  },
  LinkCardHeader: {
    bg: 'transparent',
    color: 'beta.400',
  },
  TextCardHeading: {
    color: 'white',
    fontFamily: 'Bowlby One',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  },
  TextRaceNumber: {
    bg: 'alpha.200',
    borderRadius: 'base',
    color: 'alpha.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'alpha.400',
    color: 'beta.400',
    textShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  TextRunnerNumber: {
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  LinkRaceDetails: {
    color: 'alpha.600',
    bg: 'beta.400',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    textTransform: 'uppercase',
    fontSize: 'xs',
    fontFamily: 'Bowlby One',
    fontWeight: 'normal',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
    lineHeight: 'normal',
    mt: '-1px',
    _hover: {
      bgGradient: 'beta.500',
    },
    borderBottomRadius: 'sm',
  },
  TextScratched: {
    fontSize: '2xs',
    color: 'white',
  },
  buttonRaceCardOddsProps: {
    variant: 'secondary',
    fontSize: 'xs',
    fontFamily: 'body',
    fontWeight: 'black',
    w: '41px',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};
