import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'white',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'xs',
    color: 'white',
    sx: {
      '> span': { fontSize: '2xs', fontWeight: 'medium' },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    pt: '2',
    pb: '2',
    borderBottom: '1px dashed rgba(0, 0, 0, 0.1)',
    borderTop: '1px dashed rgba(255, 255, 255, 0.35)',
  },
  eventMarketName: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  eventSubtitle: {
    fontSize: 'xs',
    fontWeight: 'bold',
  },
};
