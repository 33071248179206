import * as betgalaxy from './ResponsibleGamblingBanner.styles.betgalaxy';
import * as betprofessor from './ResponsibleGamblingBanner.styles.betprofessor';
import * as vikingbet from './ResponsibleGamblingBanner.styles.vikingbet';
import * as volcanobet from './ResponsibleGamblingBanner.styles.volcanobet';
import * as forsport from './ResponsibleGamblingBanner.styles.forsport';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  vikingbet,
  volcanobet,
  forsport,
};
