import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  pb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive
    ? 'blackAlpha.400'
    : 'linear-gradient(180deg, #5DE500 0%, #47AF00 100%)',
  color: isActive ? 'blackAlpha.600' : 'alpha.700',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'md',
  border: '2px solid white',
  boxShadow: isActive
    ? '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)'
    : '0px 1px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
  '&:focus': {
    bg: 'blackAlpha.400',
    color: isActive ? 'beta.900' : 'alpha.400',
  },
  '&:hover': {
    bg: 'alpha.700',
    color: 'blackAlpha.700',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'blackAlpha.600',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'md',
  border: '2px solid white',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  '&:focus': {
    bg: 'blackAlpha.400',
    color: 'beta.900',
  },
  '&:hover': {
    bg: 'alpha.700',
    color: 'blackAlpha.700',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
