import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputLeftAddon: {
    fontFamily: 'accent',
  },
  betCardInput: {
    fontFamily: 'accent',
    fontSize: 'sm',
  },
};
