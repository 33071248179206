import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    bg: 'alpha.700',
    borderTopRadius: 'base',
    pt: '2',
    mt: ['2', '0', null],
    mb: '0',
    h: '30px',
    sx: {
      '&[data-hasPageHeader="true"]': {
        pt: ['0', null, '2'],
      },
    },
  },
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['2', null, 'unset'],
  },
  textTitle: {
    mt: '0',
    mb: '2',
    fontSize: 'md',
    fontWeight: '800',
    color: 'beta.500',
    textTransform: 'uppercase',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderRadius: 'md',
        py: '2',
        mt: '2',
        bg: 'alpha.700',
        borderColor: 'beta.500',
        borderStyle: 'solid',
        borderWidth: '2px',
        flexDir: 'column',
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};
