import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
};

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'uppercase',
  mb: '1',
};

export const EachWayText: CSSObject = {
  color: 'black',
  fontWeight: 'medium',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'gamma.400',
  fontWeight: 'black',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: '22px',
  color: 'gamma.400',
  fontWeight: 'black',
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'black',
  fontSize: 'sm',
  color: 'alpha.900',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  border: 'none',
  mt: '1',
  ml: '-0.5',
  _groupFocusWithin: {
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  },
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
});

export const InputBetSlip: CSSObject = {
  border: '2px solid',
  color: 'alpha.900',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  fontSize: 'sm',
  fontWeight: 'bold',
  ml: '1',
  '&&': {
    borderRightRadius: 'md',
    borderColor: 'alpha.400',
    borderLeft: '2px solid',
    borderLeftColor: 'alpha.500',
    borderLeftRadius: 'md',
    bg: 'white',
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'gamma.400',
};
