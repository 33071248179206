/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { Box, Checkbox, Collapse } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { forwardRef } from 'react';
import PlacesAutocomplete from '../../../../components/PlacesAutocomplete/PlacesAutocomplete';
import { getStrings } from '@/helpers/utils';
import { AccountSetupSchema } from '../../services/schemas/accountSetupSchema';
import { AU_STATES } from '../../../../lib/Constants';
import {
  addressWrapperProps,
  BoxManualAddressContainer,
  CheckboxHelpText,
  FlexStreetDetailsContainer,
  FormStack,
  SelectSignUp,
  SignUpInput,
} from '../styles/SignUp.styles';
import { useAddress } from '../services/SignUp.hooks';

type TAddress = {
  placesAutocompleteLoaded: boolean;
};

const Address = forwardRef<Record<string, HTMLInputElement> | any, TAddress>(
  ({ placesAutocompleteLoaded }, ref) => {
    const [
      {
        Onboarding: { Step2 },
        Generic,
      },
    ] = getStrings();
    const {
      setValues,
      setTouched,
      values,
      setFieldTouched,
      isSubmitting,
      setErrors,
    } = useFormikContext<AccountSetupSchema>();

    const { setAddressError } = useAddress();

    const $ref = ref as React.MutableRefObject<
      Record<string, HTMLInputElement | HTMLSelectElement>
    >;

    return (
      <Box mb="4" {...addressWrapperProps}>
        {placesAutocompleteLoaded && (
          <PlacesAutocomplete
            inputName="residential"
            label={Step2.AddressInputLabel}
            placeholder={
              values.manualAddress
                ? Step2.AddressInputManualPlaceholder
                : Step2.AddressInputPlaceholder
            }
            onChange={(e) => {
              // Auto-populate fields with breakdown of google suggested result
              const subpremise = e.address_components.find((x) =>
                x.types.includes('subpremise')
              );
              const streetNumber = e.address_components.find((x) =>
                x.types.includes('street_number')
              );
              const joinedStreetNumbers =
                subpremise && streetNumber
                  ? `${subpremise.long_name}/${streetNumber.long_name}`
                  : undefined;
              const streetName = e.address_components.find((x) =>
                x.types.includes('route')
              );
              const suburb = e.address_components.find((x) =>
                x.types.includes('locality')
              );
              const state = e.address_components.find((x) =>
                x.types.includes('administrative_area_level_1')
              );
              const postcode = e.address_components.find((x) =>
                x.types.includes('postal_code')
              );

              let manualAddress = false;
              if (
                !streetNumber ||
                !streetName ||
                !suburb ||
                !state ||
                !postcode
              ) {
                setAddressError(true);
                manualAddress = true;
              }

              setValues({
                ...values,
                streetNumber:
                  joinedStreetNumbers || streetNumber?.short_name || '',
                streetName: streetName?.long_name || '',
                suburb: suburb?.long_name || '',
                state: state?.short_name || '',
                postcode: postcode?.long_name || '',
                residential: e.formatted_address,
                manualAddress,
              });
            }}
            dataCy="verificationAddress"
            isManual={values.manualAddress}
            disabled={values.manualAddress}
          />
        )}

        <Checkbox
          name="manualAddress"
          data-cy="verificationAddressManual"
          size="lg"
          isChecked={values.manualAddress}
          onChange={() => {
            setValues({
              ...values,
              manualAddress: !values.manualAddress,
              residential: '',
              streetNumber: '',
              streetName: '',
              suburb: '',
              state: '',
              postcode: '',
            });
            setErrors({ residential: '' });
            if (values.manualAddress) {
              setAddressError(false);
              setTouched(
                {
                  streetNumber: false,
                  streetName: false,
                  suburb: false,
                  state: false,
                  postcode: false,
                },
                true
              );
            }
          }}
          isDisabled={isSubmitting}
        >
          <CheckboxHelpText>{Step2.AddressInputManualEntry}</CheckboxHelpText>
        </Checkbox>

        <Collapse in={values.manualAddress} animateOpacity>
          <BoxManualAddressContainer>
            <FlexStreetDetailsContainer>
              <SignUpInput
                allowInvalidKeys
                type="text"
                ref={(el: HTMLInputElement) => ($ref.current.streetNumber = el)}
                data-cy="verificationManualStreetNumber"
                onFocus={() => setFieldTouched('streetNumber', false)}
                name="streetNumber"
                label={Step2.StreetNumberInputLabel}
                sxWrapper={{ flex: 1 }}
              />
              <SignUpInput
                allowInvalidKeys
                type="text"
                ref={(el: HTMLInputElement) => ($ref.current.streetName = el)}
                data-cy="verificationManualStreetName"
                name="streetName"
                onFocus={() => setFieldTouched('streetName', false)}
                label={Step2.StreetNameInputLabel}
                sxWrapper={{ flex: 2 }}
              />
            </FlexStreetDetailsContainer>

            <SignUpInput
              allowInvalidKeys
              type="text"
              ref={(el: HTMLInputElement) => ($ref.current.suburb = el)}
              data-cy="verificationManualSuburb"
              name="suburb"
              label={Step2.SuburbInputLabel}
              onFocus={() => setFieldTouched('suburb', false)}
            />

            <FormStack>
              <SelectSignUp
                data-cy="verificationManualState"
                onFocus={() => setFieldTouched('state', false)}
                placeholder={Generic.SelectPlaceholder}
                name="state"
                label={Step2.StateInputLabel}
              >
                {AU_STATES.map((state) => (
                  <option value={state} key={`option-${state}`}>
                    {state}
                  </option>
                ))}
              </SelectSignUp>
              <SignUpInput
                allowInvalidKeys
                type="text"
                ref={(el: HTMLInputElement) => ($ref.current.postcode = el)}
                data-cy="verificationManualPostcode"
                name="postcode"
                onFocus={() => setFieldTouched('postcode', false)}
                label={Step2.PostcodeInputLabel}
              />
            </FormStack>
          </BoxManualAddressContainer>
        </Collapse>
      </Box>
    );
  }
);

export default Address;
