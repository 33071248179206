import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';
import betslipBgTexture from '@/assets/templebet/images/backgrounds/betslips-bg-testure.png';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'odds',
    my: '0',
    borderRadius: 'md',
    px: '3',
    textShadow: '0px 4px 4px var(--bc-colors-gammaAlpha-200)',
    _hover: {
      backgroundImage:
        'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
      color: 'white',
    },
    _active: {
      color: 'beta.500',
      bg: 'beta.900',
    },
  },
};

export const FlexBannerContainer: CSSObject = {
  bg: `linear-gradient(180deg, var(--bc-colors-beta-100), var(--bc-colors-beta-200)), url(${betslipBgTexture})`,
  borderRadius: 'md',
  bgSize: '100% auto',
  backgroundPosition: 'top',
  backgroundBlendMode: 'darken',
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200),0px 1px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  gap: ['2', null, '0'],
  p: ['2', null, '3'],
  minH: '141px',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
};

export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'heading',
  fontWeight: 'normal',
  color: 'delta.700',
  textTransform: 'uppercase',
};

export const FlexAccountBalances: CSSObject = {
  gap: ['2', '2', '8'],
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'heading',
  textAlign: ['start', null, 'end'],
  color: 'beta.800',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'heading',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  justifyContent: ['flex-start', null, 'flex-end'],
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'delta.700',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'delta.700',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'delta.700',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  flexWrap: 'wrap',
  gap: '2',
  pt: '2',
  borderTop: '1px dashed rgba(0, 0, 0, 0.1)',
  width: 'full',
  mb: '4',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: 'full',
    border: '1px dashed rgba(255, 255, 255, 0.35)',
    borderBottom: 'none',
  },
};

export const TextButton: CSSObject = {
  h: '4',
  fontFamily: 'accent',
  fontWeight: 'normal',
};

export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'beta.800',
  color: 'delta.700',
  fontFamily: 'RiverAdventurer',
  fontSize: 'sm',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '2',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
};

export const FlexLabel: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1',
};
