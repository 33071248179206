import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  h: ['45px', null, '95px'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const TextItem = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const LinkItem = {
  color: 'gamma.400',
  fontWeight: 'bold',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};
