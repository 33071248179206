/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Text,
  Flex,
  useDisclosure,
  Icon,
  Collapse,
} from '@chakra-ui/react';
import React, { memo, useState, useMemo } from 'react';
import { ChevronUp, ChevronDown } from '@styled-icons/boxicons-regular';
import { groupBy } from '@/lib/utils';
import {
  MarketLayoutTabPanel,
  MarketLayoutPagination,
  MarketLayoutTabs,
  TMarketLayoutMarket,
  TMarketLayoutProps,
  LIMIT,
} from '../MarketLayout/MarketLayout';
import PropositionButton from '../PropositionButton/PropositionButton';
import { TExtendedProposition } from '../../MatchDetailPage/services/MatchDetailPage.types';
import { playerLayoutStyles } from './styles/PlayerLayout.styles';

/** Types */
type TPlayerOverUnderViewProps = {
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  labels: string[];
  markets: [string, TMarketLayoutMarket[]][];
  propSet: Record<string, number>;
  onSelection?: ((proposition: TExtendedProposition) => void) | undefined;
  updatePropSet: (props: TUpdatePropSetProps) => void;

  shouldShowMore: boolean;
  isShowingMore: boolean;
  showMoreToggle: () => void;
};

type TPlayerOverUnderLayout = Pick<
  TMarketLayoutProps,
  'parts' | 'participants' | 'onSelection'
>;

type TUsePlayerOverUnder = Pick<TMarketLayoutProps, 'parts'>;

type TBtnGroupProps = {
  value?: number | null;
  onClickDown: () => void;
  onClickUp: () => void;
  isDownDisabled: boolean;
  isUpDisabled: boolean;
};

type TUpdatePropSetProps = {
  action: 'increment' | 'decrement';
  player: string;
  marketLength: number;
};

type TListItemProps = {
  player: string;
  label: string;
  marketsLength: number;
  onClickUpDown: (props: TUpdatePropSetProps) => void;
  propSet: Record<string, number>;
  onClickProp: ((proposition: TExtendedProposition) => void) | undefined;
  markets: TMarketLayoutMarket[];
};

/** View */
function PlayerOverUnderView({
  labels,
  setTabIndex,
  markets,
  propSet,
  updatePropSet,
  onSelection,
  shouldShowMore,
  isShowingMore,
  showMoreToggle,
}: TPlayerOverUnderViewProps) {
  return (
    <>
      <MarketLayoutTabs labels={labels} onChange={setTabIndex}>
        {labels.map((label) => (
          <MarketLayoutTabPanel key={label}>
            <Box {...playerLayoutStyles.grid}>
              {[...markets].slice(0, LIMIT).map((m) => {
                const player = m[0];
                const _markets = m[1];

                return (
                  <ListItem
                    key={`${player}-${label}`}
                    player={player}
                    label={label}
                    marketsLength={markets.length}
                    onClickUpDown={updatePropSet}
                    propSet={propSet}
                    onClickProp={onSelection}
                    markets={_markets}
                  />
                );
              })}

              {shouldShowMore && (
                <Collapse in={isShowingMore} animateOpacity unmountOnExit>
                  {[...markets].slice(LIMIT).map((m) => {
                    const player = m[0];
                    const _markets = m[1];

                    return (
                      <ListItem
                        key={`${player}-${label}-hidden`}
                        player={player}
                        label={label}
                        marketsLength={markets.length}
                        onClickUpDown={updatePropSet}
                        propSet={propSet}
                        onClickProp={onSelection}
                        markets={_markets}
                      />
                    );
                  })}
                </Collapse>
              )}
            </Box>
          </MarketLayoutTabPanel>
        ))}
      </MarketLayoutTabs>

      <MarketLayoutPagination
        in={shouldShowMore}
        isOpen={isShowingMore}
        onPageClick={showMoreToggle}
      />
    </>
  );
}

/** BtnGroup */
function BtnGroup({
  value,
  onClickDown,
  onClickUp,
  isDownDisabled,
  isUpDisabled,
}: TBtnGroupProps) {
  return (
    <Flex {...playerLayoutStyles.btnGroupWrapper}>
      <Icon
        as={ChevronDown}
        onClick={onClickDown}
        {...playerLayoutStyles.btnGroupIcon}
        {...(isDownDisabled && { cursor: 'not-allowed', opacity: 0.3 })}
      />
      <Text {...playerLayoutStyles.btnGroupText}>{value}</Text>
      <Icon
        as={ChevronUp}
        onClick={onClickUp}
        {...playerLayoutStyles.btnGroupIcon}
        {...(isUpDisabled && { cursor: 'not-allowed', opacity: 0.3 })}
      />
    </Flex>
  );
}

function ListItem({
  player,
  label,
  marketsLength,
  onClickUpDown,
  propSet,
  onClickProp,
  markets,
}: TListItemProps) {
  const market = markets[propSet[player] ?? 0];
  const props = market?.propositions ?? [];
  const propBtnProps = { player, marketLength: markets.length };

  const propsFilled = [
    ...(props?.length === 1 ? [{ proposition_info: { value2: 0 } }] : []),
    ...(props ?? []),
  ].sort((a, b) => {
    const aValue = a.proposition_info?.value2 || Infinity;
    const bValue = b.proposition_info?.value2 || Infinity;

    if (aValue === -1 && bValue !== -1) return -1;
    if (bValue === -1 && aValue !== -1) return 1;
    return aValue - bValue;
  });

  return (
    <Box key={`${player}-${label}`} {...playerLayoutStyles.row}>
      <Box {...playerLayoutStyles.nameWrapper}>
        <Text {...playerLayoutStyles.name}>{player}</Text>
      </Box>

      <Flex
        {...playerLayoutStyles.propWrapper}
        {...(marketsLength === 1 && {
          ml: 'auto',
        })}
      >
        {propsFilled?.map((p, i) => (
          <PropositionButton
            key={`${player}-${label}-${p.proposition_id}`}
            isActive={p.is_selected}
            isDisabled={p.is_disabled}
            isInline
            isSuspended={p.is_suspended}
            odds={p.return_amount}
            onClick={() => p && onClickProp?.(p)}
            order={i + 1 === 2 ? 3 : i + 1}
            minW="60px"
            px="0"
          />
        ))}

        <BtnGroup
          value={market.market_info?.value1}
          onClickDown={() =>
            onClickUpDown({
              action: 'increment',
              ...propBtnProps,
            })
          }
          onClickUp={() =>
            onClickUpDown({
              action: 'decrement',
              ...propBtnProps,
            })
          }
          isDownDisabled={
            propSet[player] === undefined || propSet[player] === 0
          }
          isUpDisabled={
            markets.length === 1 || propSet[player] === markets.length - 1
          }
        />
      </Flex>
    </Box>
  );
}

/** Controller */
const usePlayerOverUnder = ({
  parts,
}: TUsePlayerOverUnder): Omit<TPlayerOverUnderViewProps, 'onSelection'> => {
  // Tabs
  const labels = parts.map(({ title }) => title);
  const [tabIndex, setTabIndex] = useState(0);

  // Markets
  const { markets } = parts[tabIndex];
  const [grouped, ungrouped] = groupBy(markets, (m) => m.market_info?.player);
  const groupedMarkets = useMemo(
    () =>
      [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...[...grouped.entries()].map((m) => m),
        ...((ungrouped ?? []) as TMarketLayoutMarket[]),
      ] as TPlayerOverUnderViewProps['markets'],
    [grouped, ungrouped]
  );
  let newMarkets = groupedMarkets;

  /**
   * Ensures that Over/Under markets only include two propositions each.
   * If a market exceeds this limit, it creates "pseudo" markets to group
   * the propositions according to their Over/Under category.
   */
  if (
    groupedMarkets.some((data) =>
      data[1].some((mrk) => (mrk.propositions?.length ?? 0) > 2)
    )
  ) {
    const groupedPropositionsValue1 = groupedMarkets.reduce<
      Record<string, [string, TExtendedProposition[]][]>
    >((pre, acc) => {
      const playerName = acc[0];
      const playerMarkets = acc[1];

      return {
        ...pre,
        [playerName]: playerMarkets.flatMap((mkt) => {
          const [groupedPropositions] = groupBy(mkt?.propositions ?? [], (p) =>
            String(p.proposition_info?.value1 ?? 0)
          );
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return [...groupedPropositions.entries()].map((m) => m);
        }),
      };
    }, {});

    newMarkets = groupedMarkets.map((data) => {
      const playerName = data[0];
      const playerMarket = data[1][0];
      const groupedPropositions = groupedPropositionsValue1[playerName];

      return [
        playerName,
        groupedPropositions.map(([value1, propositions]) => ({
          ...playerMarket,
          market_info: {
            ...(playerMarket.market_info as any),
            value1,
          },
          propositions,
        })),
      ];
    });
  }
  /** End of pseudo market creation */

  const sortedMarkets = [...newMarkets]
    .sort((a, b) => {
      const keyA = a?.[0];
      const keyB = b?.[0];
      return keyA?.localeCompare(keyB);
    })
    .sort((a, b) => {
      const valA = a?.[1]?.[0]?.market_info?.team_role;
      const valB = b?.[1]?.[0]?.market_info?.team_role;
      if (valA === 'Home' && valB !== 'Home') return -1;
      if (valA !== 'Home' && valB === 'Home') return 1;
      return 0;
    })
    .map((data) => {
      const mks = [...(data[1] ?? [])].sort((a, b) => {
        const aFeatured = a.bet_option?.endsWith('.featured');
        const bFeatured = b.bet_option?.endsWith('.featured');

        if (aFeatured && !bFeatured) return -1;
        if (!aFeatured && bFeatured) return 1;

        if (aFeatured === bFeatured) {
          if (
            (a.market_info?.value1 || Infinity) <
            (b.market_info?.value1 || Infinity)
          ) {
            return -1;
          }
          if (
            (a.market_info?.value1 || Infinity) >
            (b.market_info?.value1 || Infinity)
          ) {
            return 1;
          }
        }

        return (
          (a.market_info?.value1 || Infinity) -
          (b.market_info?.value1 || Infinity)
        );
      });

      data[1] = mks;
      return data;
    })
    .map((data) => {
      const filteredPropositions = data[1].filter((arr) =>
        arr.propositions?.every((p) => p.is_suspended === false)
      );

      if (filteredPropositions.length !== data[1].length) {
        data[1] = filteredPropositions;
      }

      return data;
    });

  // Props
  const propSetInit = sortedMarkets.reduce<Record<string, number>>(
    (prev, curr) => {
      const playerName = curr[0];
      const marketMiddleIdx = Math.floor(curr[1].length / 2);
      return {
        ...prev,
        [playerName]: marketMiddleIdx,
      };
    },
    {}
  );
  const [propSet, setPropSet] = useState<Record<string, number>>(propSetInit);
  const updatePropSet = ({
    action,
    player,
    marketLength,
  }: TUpdatePropSetProps) => {
    setPropSet((state) => {
      if (action === 'decrement') {
        const nextCount = (state[player] ?? 0) + 1;
        const isEnd = marketLength === nextCount;

        return {
          ...state,
          [player]: isEnd ? state[player] : (state[player] ?? 0) + 1,
        };
      }

      const prevCount = (state[player] ?? 0) - 1;
      const isStart = prevCount < 0;

      return {
        ...state,
        [player]: isStart ? state[player] : (state[player] ?? 0) - 1,
      };
    });
  };

  // Pagination
  const { isOpen: isShowingMore, onToggle: showMoreToggle } = useDisclosure();

  return {
    labels,
    setTabIndex,
    markets: sortedMarkets,
    propSet,
    updatePropSet,
    shouldShowMore: Math.max(sortedMarkets.length) > LIMIT,
    isShowingMore,
    showMoreToggle,
  };
};

/** Container */
function PlayerOverUnderLayout({ parts, onSelection }: TPlayerOverUnderLayout) {
  const values = usePlayerOverUnder({ parts });
  return <PlayerOverUnderView {...values} onSelection={onSelection} />;
}
export default memo(PlayerOverUnderLayout);
