import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '0.5',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'beta.500',
  borderRadius: 'base',
  color: 'white',
  ml: '0.5',
};

export const LinkRaceContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  color: 'white',
  borderRadius: 'base',
  border: '2px solid',
  borderColor: 'beta.400',
  boxShadow: '0px 0px 0px 2px #13274B inset',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },

  '.state-inprogress': {
    bg: 'gamma.400',
    color: 'alpha.700',
    borderRadius: 'base',
  },

  '.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
  },

  '.state-ended': {
    bg: 'red.600',
    color: 'white',
    borderRadius: 'base',
  },
};

export const TextRaceType: CSSObject = {
  fontFamily: 'Imperator',
  color: 'beta.400',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'lg',
  textTransform: 'capitalize',
  letterSpacing: 'wider',
};

export const TextRace: CSSObject = {
  fontFamily: 'Fraunces',
  color: 'beta.400',
  fontWeight: 'semibold',
  fontSize: 'sm',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'beta.400',
  filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.55))',
};

export const GridRaceItems: CSSObject = {
  mx: '2',
};
