import { CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'whiteAlpha.600',
  p: '0',
};

export const DepositButton: CSSObject = {
  borderRadius: '7px !important',
  color: 'white',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  boxShadow:
    '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',

  '&:active, &:hover': {
    bg: 'beta.700',
    boxShadow:
      '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  },
  _disabled: {
    color: 'white',
  },
};

export const DepositLimitContainer: CSSObject = {
  bg: 'white',
  borderRadius: 'base',
  p: '4',
  color: 'gray.600',
  fontWeight: 'medium',
};

export const LimitLabel: CSSObject = {
  fontWeight: 'bold',
};

export const LimitValue: CSSObject = {
  fontWeight: 'medium',
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: 'bold',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '& .chakra-form__label': {
    color: 'white',
  },
  '& .chakra-input__left-addon': {
    bg: 'beta.500',
    fontSize: 'xs',
    h: '9',
    borderLeftRadius: 'md',
    color: 'alpha.800',
  },
  '& .chakra-select': {
    bg: 'zeta.100',
    borderRadius: 'base',
    fontSize: 'xs',
    h: '9',
    color: 'gray.600',
  },
  '& .chakra-input': {
    borderRightRadius: 'md',
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'white',
    h: '9',
    border: '1px',
    borderColor: 'gray.300',
    boxShadow: 'unset',
    color: 'gray.700',
    _hover: {
      bg: 'zeta.200',
    },
    _focus: {
      bg: 'zeta.200',
    },
    _placeholder: {
      color: 'gray.700',
    },
  },
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
  position: 'relative',
  zIndex: '9999',
  '.chakra-button': {
    padding: '12px',
    width: '50%',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  '.chakra-button[data-cy="cancelDepositConfirmation"]': {
    bg: 'transparent',
    boxShadow:
      '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
  },
};

export const inputProps: Partial<TIndex> = {
  sxWrapper: {
    '&&': {
      '.chakra-input': {
        bg: 'white',
      },
    },
  },
};
