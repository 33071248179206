import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  borderRadius: '6px',
  bg: 'rgba(26, 165, 255, 0.15)',
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px rgba(255, 255, 255, 0.10) inset',
  backdropFilter: 'blur(2.5px)',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '1.5',
  mb: isKeypadVisible && 'unset',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterMotion: CSSObject = {
  bg: 'alpha.700',
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'gamma.50',
  color: 'gamma.700',
  my: '2',
  '&&': {
    mr: '1',
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'beta.500',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  h: '6',
  bg: 'beta.500',
  borderRadius: 'md',
  fontWeight: 'black',
  margin: 'auto',
  mt: '-28px',
  position: 'relative',
  lineHeight: 'normal',
  minW: '16',
  px: '1',
  textShadow: 'none',
  gap: '2',
  color: 'blackAlpha.700',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '-1',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      bg: 'beta.400',
    },
    '&&': {
      fontSize: '2xs',
      fontFamily: 'roboto',
      boxShadow:
        '0px 0px 0px 0px rgba(255, 255, 255, 0.15) inset, 0px 3px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 4px 0px rgba(255, 255, 255, 0.4) inset',

      _after: {
        bgGradient: 'linear(to-b, beta.200, beta.300)',
        opacity: 1,
      },
    },
  },
};
