import { CSSObject } from '@chakra-ui/react';

export const GridWrapper: CSSObject = {
  position: 'relative',
  gridTemplateColumns: ['1fr', '300px 460px'],
  gridTemplateRows: ['336px 1fr', '1fr'],
  gridAutoFlow: [null, 'row'],
  gridTemplateAreas: [
    `
        'aside'
        'content'
      `,
    `
        'aside content'
      `,
  ],
  minH: ['full', null, '720px'],
};

export const BoxAside: CSSObject = {
  minH: 'full',
  borderLeftRadius: [null, null, 'lg'],
};

export const CloseButton: CSSObject = {
  position: 'absolute',
  right: ['2', null, '10'],
  top: '4',
  border: '2px',
  borderColor: 'gray.400',
  color: 'gray.400',
  p: '0.5',
  boxSize: '6',
  bg: ['transparent', null, 'white'],
  alignSelf: ['flex-start', null, 'unset'],
};

export const BoxContent = (): CSSObject => ({
  bg: 'white',
  px: ['2', null, '10'],
  pt: [null, null, '10'],
  pb: [null, null, '10'],
  h: 'full',
  color: 'gray.500',
  fontWeight: 'semibold',
  fontSize: 'xs',
  display: 'flex',
  flexDir: 'column',
  borderRightRadius: 'lg',

  '.chakra-form__label': {
    color: 'gray.500',
    fontWeight: 'semibold',
    fontSize: 'xs',
    mb: '1.5',
  },

  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.500',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'white',
    borderColor: 'gray.300',
    color: 'gray.700',
  },

  '.react-date-picker__inputGroup': {
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: 'sm',
  },

  '.chakra-input, .chakra-select, .react-date-picker__wrapper': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    h: '9',
    _placeholder: {
      fontSize: 'xs',
    },
  },
});
