import {
  Box,
  chakra,
  Checkbox,
  Container as ContainerChakra,
  Flex,
  Switch,
  Text,
} from '@chakra-ui/react';
import { styleImports } from './Exotics.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const Container = chakra(ContainerChakra, {
  label: 'exotics-Container',
  baseStyle: () => ({}),
});

export const ExoticsWrapper = chakra(Box, {
  label: 'exotics-ExoticsWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ExoticsWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ExoticsContainer = chakra(Box, {
  label: 'exotics-ExoticsContainer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ExoticsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CheckboxBoxed = chakra(Checkbox, {
  label: 'exotics-CheckboxBoxed',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.CheckboxBoxed;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SwitchBoxed = chakra(Switch, {
  label: 'exotics-SwitchBoxed',
  baseStyle: () => ({
    display: 'none',
    ...(() => {
      try {
        return styleImports[themeName]?.SwitchBoxed;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextBoxed = chakra(Text, {
  label: 'exotics-TextBoxed',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextBoxed;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const SelectFieldContainer = chakra(Flex, {
  label: 'exotics-SelectFieldContainer',
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: ['1', null, '2'],
    ...(() => {
      try {
        return styleImports[themeName]?.SelectFieldContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
