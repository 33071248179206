const branding = {
  alpha: {
    50: '#CDFF98',
    100: '#A2FF3E',
    200: '#80FF00',
    300: '#75EB00',
    400: '#6DDA01',
    500: '#5EBB01',
    600: '#448800',
    700: '#387001',
    800: '#264B00',
    900: '#132500',
  },
  beta: {
    50: '#EDF6FC',
    100: '#D8EDFA',
    200: '#B7DBF5',
    300: '#94C8EF',
    400: '#70B6EA',
    500: '#4CA4E5',
    600: '#3F7198',
    700: '#2E5470',
    800: '#203A4E',
    900: '#13222E',
  },
  gamma: {
    50: '#F6EEFD',
    100: '#EDDCFB',
    200: '#DCB9F8',
    300: '#CA96FA',
    400: '#B973F1',
    500: '#A750ED',
    600: '#8C3DCB',
    700: '#491475',
    800: '#350F55',
    900: '#1C082C',
  },
  epsilon: {
    50: '#FFFBF2',
    100: '#FFF8E6',
    200: '#F8ECCD',
    300: '#F4E2B6',
    400: '#F3DDA6',
    500: '#F5C595',
    600: '#B68452',
    700: '#815520',
    800: '#5E3F18',
    900: '#34220D',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  theta: {},
  delta: {},
  epsilon: branding.epsilon,
  eta: {},
};
