import React from 'react';
import { Icon } from '@chakra-ui/react';
import { Home } from 'styled-icons/boxicons-solid';
import { PiggyBank, Dice } from 'styled-icons/fa-solid';
import { PersonCircle } from '@styled-icons/bootstrap';
import IconSvg from '../../../../common/IconSvg';
import { getThemeName } from '@/helpers/getThemeConfig';
import { TSideIcon } from '../../../services/NavIcon.types';
import { styleImports } from './NavIcon.styles.imports';

const themeName = getThemeName();

export const HomeIcon = ({ styles }: TSideIcon) => {
  try {
    return (
      styleImports[themeName]?.HomeIcon?.({ styles }) ?? (
        <Icon as={Home} className="icon" sx={styles.icon} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Icon as={Home} className="icon" sx={styles.icon} />;
};

export const DepositIcon = ({ styles }: TSideIcon) => {
  try {
    return (
      styleImports[themeName]?.DepositIcon?.({ styles }) ?? (
        <Icon as={PiggyBank} className="icon" sx={styles.icon} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Icon as={PiggyBank} className="icon" sx={styles.icon} />;
};

export const PromoIcon = ({ styles }: TSideIcon) => {
  try {
    return (
      styleImports[themeName]?.PromoIcon?.({ styles }) ?? (
        <IconSvg name="todays_offers" className="svgIcon" sx={styles.icon} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <IconSvg name="todays_offers" className="svgIcon" sx={styles.icon} />;
};

export const ContactIcon = ({ styles }: TSideIcon) => {
  try {
    return (
      styleImports[themeName]?.ContactIcon?.({ styles }) ?? (
        <IconSvg name="contact_us" className="svgIcon" sx={styles.icon} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <IconSvg name="contact_us" className="svgIcon" sx={styles.icon} />;
};

export const MyAccountIcon = ({ styles }: TSideIcon) => {
  try {
    return (
      styleImports[themeName]?.MyAccountIcon?.({
        styles,
      }) ?? <Icon as={PersonCircle} className="icon" sx={styles.icon} />
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Icon as={PersonCircle} className="icon" sx={styles.icon} />;
};

export const ResponsibleGamblingIcon = ({ styles }: TSideIcon) => {
  try {
    return (
      styleImports[themeName]?.ResponsibleGamblingIcon?.({ styles }) ?? (
        <Icon as={Dice} className="icon" sx={styles.icon} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <Icon as={Dice} className="icon" sx={styles.icon} />;
};
export const SportsIcon = ({ styles }: TSideIcon) => {
  try {
    return (
      styleImports[themeName]?.SportsIcon?.({ styles }) ?? (
        <IconSvg name="all_sports" className="svgIcon" sx={styles.icon} />
      )
    );
  } catch (err) {
    // Return default component below if no themed one exists
  }
  return <IconSvg name="all_sports" className="svgIcon" sx={styles.icon} />;
};
