import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  maxW: ['none', 'lg'],

  // Control (field wrapper)
  '.chakra-form-control': {
    mb: '3',
    mt: '0',
  },

  // Label
  '.chakra-form__label': {
    color: 'gray.500',
    fontWeight: 'semibold',
    mb: '1.5',
  },

  // Inputs
  '.chakra-input, .chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'base',
    color: 'gray.600',
    fontSize: 'xs',
    h: '9',
    px: '2.5',
  },

  // Radios
  '.chakra-radio__input + span': {
    bg: 'gray.200',
    m: '0',

    _checked: {
      bg: 'alpha.500',
      border: '4px solid',
      borderColor: 'gray.200',
      color: 'alpha.500',
    },
  },

  // Addons
  '.chakra-input__left-addon': {
    border: '1px',
    borderColor: 'gray.300',
    borderLeftRadius: 'base',
    fontSize: 'xs',
    h: '9',
  },
  '.chakra-input__left-addon + .chakra-input': {
    borderLeftRadius: 'none',
  },

  // Helper text
  '.chakra-form__helper-text': {
    fontSize: 'xs',
    fontWeight: 'medium',
  },
};
