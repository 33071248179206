import {
  ImageProps,
  TextProps,
  LinkProps,
  BoxProps,
  FlexProps,
  IconProps,
  ButtonProps,
} from '@chakra-ui/react';
import { styleImports } from './Card.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type CardSchema = Partial<{
  wrapper: BoxProps;
  wrapperContent: BoxProps;
  linkHeader: LinkProps;
  headerIcon: IconProps;
  headerRaceName: TextProps;
  raceIndicators: FlexProps;
  listItem: FlexProps;
  silk: ImageProps;
  runner: TextProps;
  scratched: TextProps;
  btn: ButtonProps;
}>;

export const cardStyles: CardSchema = {
  wrapper: {
    'data-testid': 'home-card-wrapper',
    ...(() => {
      /**
       * This will not be a long term solution, but the structure
       * aligns with the schema way of working we're moving towards.
       */
      try {
        return styleImports[themeName]?.cardStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  wrapperContent: {
    'data-testid': 'home-card-wrapperContent',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.wrapperContent;
      } catch (err) {
        return {};
      }
    })(),
  },

  linkHeader: {
    'data-testid': 'home-card-linkHeader',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.linkHeader;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerIcon: {
    'data-testid': 'home-card-headerIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.headerIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  headerRaceName: {
    'data-testid': 'home-card-headerRaceName',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.headerRaceName;
      } catch (err) {
        return {};
      }
    })(),
  },
  raceIndicators: {
    'data-testid': 'home-card-raceIndicators',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.raceIndicators;
      } catch (err) {
        return {};
      }
    })(),
  },
  listItem: {
    'data-testid': 'home-card-listItem',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.listItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  silk: {
    'data-testid': 'home-card-silk',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.silk;
      } catch (err) {
        return {};
      }
    })(),
  },
  runner: {
    'data-testid': 'home-card-runner',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.runner;
      } catch (err) {
        return {};
      }
    })(),
  },
  scratched: {
    'data-testid': 'home-card-scratched',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.scratched;
      } catch (err) {
        return {};
      }
    })(),
  },

  btn: {
    'data-testid': 'home-card-btn',
    ...(() => {
      try {
        return styleImports[themeName]?.cardStyles?.btn;
      } catch (err) {
        return {};
      }
    })(),
  },
};
