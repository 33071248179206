import { CSSObject } from '@chakra-ui/react';

export const OddsText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'extrabold',
  color: 'white',
};

export const MultiCardContainer: CSSObject = {
  borderTop: '1px',
  borderColor: '#31A6C9',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  gap: '0',

  _last: {
    boxShadow: 'none',
  },
};

export const OddsMultiCard: CSSObject = {
  fontWeight: 'semibold',
  color: 'white',
};
