import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/betroyale/images/backgrounds/bg-top.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), linear-gradient(180deg, var(--bc-colors-alpha-800), var(--bc-colors-alpha-900))`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundAttachment: 'fixed',
        },
      }}
    />
  );
}
