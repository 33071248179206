import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  color: 'alpha.500',
  borderRadius: 'base',
  border: 'none',
};

export const DividerCard: CSSObject = {
  border: 'none',
};

export const CardBottomContent: CSSObject = {
  border: '1px dashed',
  borderColor: 'blackAlpha.300',

  '.multiDivider': {
    color: 'red',
  },
};

export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
