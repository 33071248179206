import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
};

export const BoxWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  borderRadius: ['none', 'lg'],
  px: '4',
  mx: ['-2', '0'],
  mt: '2',
  mb: '0',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};
