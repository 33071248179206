import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Flex, Link } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { TPunterSignup } from '../../../../api/punter/punter.types';
import { AppDispatch } from '../../../../redux/store';
import { signup } from '../../../../api/signup/signup';
import { getStrings, logError } from '@/helpers/utils';
import {
  getPrivacyPolicy,
  getTermsConditions,
} from '../../../account/services/account.actions';
import { AccountSetupSchema } from '../../services/schemas/accountSetupSchema';
import { LoginSchema } from '../../services/schemas/loginSchema';
import { useSignIn } from '../../../../hooks/useSignIn';
import { ESignUpError } from '../../../../api/signup/signup.types';
import injectScript from '../../../../lib/injectScript';
import { GOOGLE_PLACES_SCRIPT_URL } from '../../../../lib/Constants';
import { getLoadedScripts } from './SignUp.utils';
import { useMutationMarketing } from '../../../../api/marketing/marketing.hooks';
import { trackEvent } from '@/lib/analytics';

export const useSignup = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { mutateAsync } = useMutationMarketing();
  const [showResponsible, setShowResponsible] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { handleSignIn } = useSignIn();
  const [mobilePasswordFocused, setMobilePasswordFocused] = useState(false);
  const [placesAutocompleteLoaded, setPlacesAutocompleteLoaded] =
    useState(false);

  useEffect(() => {
    const scriptList = getLoadedScripts();
    if (scriptList.includes(GOOGLE_PLACES_SCRIPT_URL)) {
      setPlacesAutocompleteLoaded(true);
      return;
    }
    (async () => {
      await injectScript(GOOGLE_PLACES_SCRIPT_URL);
      setPlacesAutocompleteLoaded(true);
    })().catch(logError);
    trackEvent('startedSignUp');
  }, []);

  const [
    {
      Onboarding: { SignUpErrorMessages },
    },
  ] = getStrings();

  useEffect(() => {
    (async () => {
      await dispatch(getPrivacyPolicy());
      await dispatch(getTermsConditions());
    })().catch(logError);
  }, [dispatch]);

  const handleSignUp = async (newUser: TPunterSignup) => {
    try {
      await signup(newUser);
      if (newUser.email && newUser.password) {
        const signInValues: LoginSchema = {
          email: newUser.email,
          password: newUser.password,
        };
        await handleSignIn(signInValues);
        await mutateAsync({
          email_marketing_enabled: newUser.email_marketing_enabled,
          sms_marketing_enabled: newUser.sms_marketing_enabled,
        });
        trackEvent('signedUp', {
          email: newUser.email,
        });
      }
    } catch (e: any) {
      const error = e.message as ESignUpError;

      const toastStyle = {
        alignItems: 'baseline',
        minWidth: '630px',
      };
      // Check if the screen width is less than a certain threshold for mobile
      if (window.innerWidth < 600) {
        toastStyle.minWidth = 'unset';
      }

      const betStopError = (
        <Flex flexDir="column">
          <FormattedMessage
            id="onboarding.signupErrorMessages.betstopDescription"
            values={{
              betstop: (string: string) => (
                <Link
                  href="https://www.betstop.gov.au"
                  target="_blank"
                  display="contents"
                  color="red.500"
                >
                  {string}
                </Link>
              ),
              gamblingHelpOnline: (string: string) => (
                <Link
                  href="https://www.gamblinghelponline.org.au"
                  target="_blank"
                  display="contents"
                  color="red.500"
                >
                  {string}
                </Link>
              ),
              phone: (string: string) => (
                <Link
                  href="tel:1800858858"
                  target="_blank"
                  display="contents"
                  textDecoration="underline"
                  color="red.500"
                >
                  {string}
                </Link>
              ),
            }}
          />
        </Flex>
      );

      switch (error) {
        case ESignUpError.EMAIL_EXISTS:
          toast.error(SignUpErrorMessages.EmailExists);
          break;
        case ESignUpError.STREET_NUMBER_IS_REQUIRED:
          toast.error(SignUpErrorMessages.StreetNumberRequired);
          break;
        case ESignUpError.BETSTOP_REJECTION:
          toast.error(betStopError, { style: toastStyle });
          break;
        default:
          toast.error(SignUpErrorMessages.ContactCustomerService);
          break;
      }
    }
  };

  return {
    handleSignUp,
    setShowPrivacy,
    setShowResponsible,
    setShowTerms,
    setMobilePasswordFocused,
    showPrivacy,
    showResponsible,
    showTerms,
    mobilePasswordFocused,
    placesAutocompleteLoaded,
  };
};

export const useAddress = () => {
  const [addressError, setAddressError] = useState(false);

  const { setTouched } = useFormikContext<AccountSetupSchema>();

  const intl = useIntl();

  useEffect(() => {
    if (addressError) {
      toast.error(
        intl.formatMessage({
          id: 'onboarding.signuperrormessages.addresslookuppartial',
        })
      );
      setTouched(
        {
          streetNumber: true,
          streetName: true,
          suburb: true,
          state: true,
          postcode: true,
        },
        true
      );
    }
  }, [addressError, setTouched, intl]);

  return {
    addressError,
    setAddressError,
  };
};

export const usePasswordRules = () => {
  const { values } = useFormikContext<AccountSetupSchema>();
  const [passwordRule1Valid, setPasswordRule1Valid] = useState(false);
  const [passwordRule2Valid, setPasswordRule2Valid] = useState(false);
  const [passwordRule3Valid, setPasswordRule3Valid] = useState(false);
  const [passwordRule4Valid, setPasswordRule4Valid] = useState(false);

  /** side effect to listen to password value and run regex check */
  useEffect(() => {
    const localPasswordRegex = values.password;

    setPasswordRule1Valid(
      /^.*((?=.*[a-z]){1})((?=.*[A-Z])).*$/.test(localPasswordRegex)
    ); // At least 1 upper and lowercase.
    setPasswordRule2Valid(/^.*(?=.*\d).*$/.test(localPasswordRegex)); // At least 1 number.
    setPasswordRule3Valid(/^.*(?=.{8,}).*$/.test(localPasswordRegex)); // At least 8 characters.
    setPasswordRule4Valid(
      !!localPasswordRegex && !localPasswordRegex.includes(' ')
    ); // No spaces.
  }, [values.password]);

  return {
    passwordRule1Valid,
    passwordRule2Valid,
    passwordRule3Valid,
    passwordRule4Valid,
  };
};
