import React from 'react';
import { Button } from '@/components/Button/Button';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner';
import {
  PageHeadingWrapper,
  TableWrapper,
  FooterWrapper,
} from './styles/MobileTransactionsTable.styles';
import { TransactionsTableProps } from '../../types';
import { useTransactions } from '../../Services/Transactions.hooks';
import {
  NoTransactionsText,
  buttonMoreTableProps,
} from '../DesktopTransactionsTable/styles/DesktopTransactionsTable.styles';
import Error from '../../../../../MyBets/Components/Error';
import Loading from '../../../../../MyBets/Components/Loading';
import { getStrings } from '@/helpers/utils';
import { TTransaction } from '../../../../../../../lib/DBModels';
import TransactionItem from './TransactionItem';

// TODO: Merge mobile and desktop transaction tables into a single component
// Past designs meant these components were built separately, now that both
// mobile and desktop behave similarly they should be merged into one
const MobileTransactionsTable: React.FC<TransactionsTableProps> = ({
  scrollRef,
  isBonus,
}) => {
  const [
    {
      Account: { Transactions: Strings, BonusBets: BBStrings },
    },
  ] = getStrings();

  const {
    data,
    isInitialLoading,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    hasMoreData,
  } = useTransactions(isBonus);

  if (error) return <Error />;

  if (isInitialLoading) return <Loading />;

  return (
    <>
      <PageHeadingWrapper>
        {/* {!isBonus && (
          <DownloadSpreadsheetButton
            data-cy="DownloadTransactiontButton"
            variant="ghost"
            onClick={handleDownloadTransactions}
            disabled={processingDownload}
          >
            {Strings.downloadAction}
          </DownloadSpreadsheetButton>
        )} */}
      </PageHeadingWrapper>

      {isInitialLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <TableWrapper ref={scrollRef}>
            {data?.pages &&
              data?.pages.map((r) =>
                r?.map((trans, i) => (
                  <TransactionItem
                    index={i}
                    trans={trans as TTransaction}
                    key={i}
                  />
                ))
              )}
          </TableWrapper>
          <FooterWrapper>
            {hasMoreData !== 0 ? (
              <Button
                data-cy="showMoreButton"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetching}
                {...buttonMoreTableProps}
              >
                {isFetching ? Strings.loading : Strings.showMore}
              </Button>
            ) : (
              <NoTransactionsText>
                {isBonus ? BBStrings.noBonusBets : Strings.noTransactions}
              </NoTransactionsText>
            )}
          </FooterWrapper>
        </>
      )}
    </>
  );
};

export default MobileTransactionsTable;
