import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import {
  BankCardContainer,
  BankRadioWrapper,
  BankRadio,
  TextBankAccountName,
  FlexBankDetailsContainer,
  TextBankAccountDetail,
} from '../styles/Withdrawal.styles';
import { getStrings } from '@/helpers/utils';
import { TBank } from '../../../../lib/DBModels';
import { useAppSelector } from '../../../../hooks/useRedux';

const BankAccountCard: React.FC<BankAccountCardProps> = ({
  onClick,
  bankId,
}) => {
  const [
    {
      Account: { Withdraw: strings },
    },
  ] = getStrings();

  const { banks } = useAppSelector((state) => state.punter);

  // Bank account deletion is disabled for P1 - see COS-2220
  // const handleDelete = (bank: TBank) => {
  //   if (!bank?.id) {
  //     toast.error(Generic.ErrorOccurred);
  //     return;
  //   }
  //   dispatch(deleteBank({ id: bank.id }));
  //   dispatch(
  //     setBankAccounts(banks.filter((bankInState) => bankInState.id !== bank.id))
  //   );
  // };

  return (
    <BankCardContainer value={bankId}>
      {banks.map((bank, i) => (
        <BankRadioWrapper key={i} w="full" onClick={() => onClick(bank ?? '')}>
          <BankRadio value={bank.id}>
            <TextBankAccountName>
              {bank.account_name}
              {bank.account_nick_name ? ` (${bank.account_nick_name})` : ''}
            </TextBankAccountName>
            <FlexBankDetailsContainer>
              <Flex>
                <Text fontWeight="bold">{strings.bankCard.bsb}</Text>
                <TextBankAccountDetail as="span">
                  {bank.bsb}
                </TextBankAccountDetail>
              </Flex>
              <Flex>
                <Text fontWeight="bold">{strings.bankCard.acc}</Text>
                <TextBankAccountDetail as="span">
                  {strings.bankCard.hiddenAccNumber}{' '}
                  {bank.account_number?.slice(-4)}
                </TextBankAccountDetail>
              </Flex>
            </FlexBankDetailsContainer>
          </BankRadio>
          {/* <BankDeleteIcon
            data-cy="bankDeleteIcon"
            as={Trash}
            onClick={() => handleDelete(bank)}
          /> */}
        </BankRadioWrapper>
      ))}
    </BankCardContainer>
  );
};
interface BankAccountCardProps {
  onClick: (e: TBank) => void;
  bankId: string;
}

export default BankAccountCard;
