import { Box, BoxProps, chakra } from '@chakra-ui/react';
import { styleImports } from './Navigation.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type NavigationSchema = Partial<{
  boxWrapperOuterProps: BoxProps;
  boxWrapperProps: BoxProps;
  boxNavigationWrapperProps: BoxProps;
  navigationBoxProps: BoxProps;
}>;

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    'data-testid': 'navigation-boxWrapperOuterProps',
    ...(styleImports?.[themeName]?.navigationStyles?.boxWrapperOuterProps ??
      {}),
  },
  boxWrapperProps: {
    'data-testid': 'navigation-boxWrapperProps',
    ...(styleImports?.[themeName]?.navigationStyles?.boxWrapperProps ?? {}),
  },
  boxNavigationWrapperProps: {
    'data-testid': 'navigation-boxNavigationWrapperProps',
    overflowX: 'hidden',
    ...(styleImports?.[themeName]?.navigationStyles
      ?.boxNavigationWrapperProps ?? {}),
  },
  navigationBoxProps: {
    'data-testid': 'navigation-navigationBoxProps',
    ...(styleImports?.[themeName]?.navigationStyles?.navigationBoxProps ?? {}),
  },
};

export const BoxWrapperOuter = chakra(Box, {
  label: 'navigation-BoxWrapperOuter',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapperOuter;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxWrapper = chakra(Box, {
  label: 'navigation-BoxWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxNavigationWrapper = chakra(Box, {
  label: 'BoxNavigationWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxNavigationWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const NavigationBox = chakra(Box, {
  label: 'navigation-NavigationBox',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.NavigationBox;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
