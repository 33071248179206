import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Text, VisuallyHidden, Icon } from '@chakra-ui/react';
import { QuestionCircleFill } from '@styled-icons/bootstrap/QuestionCircleFill';
import SgmLogo from '@/components/SgmLogo/SgmLogo';
import { formatDayDateMonthTime } from '@/lib/Time';
import { ContentRow } from '../../styles/MyBetsCard.styles';
import {
  EBetTypes,
  EBetTypesDisplayNames,
  TConfirmedBet,
} from '@/lib/DBModels';
import { centsToDollars, getIconBySportName } from '@/helpers/utils';
import {
  BetOdds,
  BetReturn,
  dotSeperatorProps,
  HeaderSvg,
  MultiIcon,
  RunnerDescription,
  RunnerDetails,
  TextBarrierNumber,
  headerRowStyles,
  TextDescription,
} from './styles/HeaderRow.styles';
import {
  getOdds,
  getStatus,
  useGetRunnerDetails,
  useRunnerDescription,
} from './HeaderRow.hooks';
import { TMyBetsMultiBetLegs } from '@/api/punter/punter.types';
import { getEventHref, getEventStart } from '../BottomRow/BottomRow.hooks';
import { LinkVenueName } from '../BottomRow/styles/BottomRow.styles';
import SrmLogo from '@/components/SrmLogo/SrmLogo';
import { getPromoDetails } from '@/views/account/MyBets/services/MyBets.utils';
import { isToteMulti } from '@/views/races/bets/Exotics/services/Exotics.utils';

const POSITION_MAP = {
  Top1: 'Win',
  Top2: 'Top 2',
  Top3: 'Top 3',
  Top4: 'Top 4',
};

// This component is used for viewing 1) individual multi legs and 2) bets
// in the MyBets view
// If leg param is populated, it's a multi leg
// Otherwise it's a bet
const HeaderRow: React.FC<THeaderRow> = ({ bet, isResulted, leg, index }) => {
  const intl = useIntl();

  const isGroupedLeg = !!bet?.bet_legs?.[index ?? 0].legs?.length;
  const isMoM = bet.bet_legs?.[index ?? 0].legs;

  const runnerDetails = useGetRunnerDetails(bet, leg);
  const runnerDescription = useRunnerDescription(bet, leg, isGroupedLeg);
  const odds = getOdds(bet, leg);
  const status = getStatus(bet, leg);
  const { originalOdds } = getPromoDetails(bet);

  const betLegDescription = bet?.bet_legs?.[index ?? 0].bet_description;

  const isSgmBet = isGroupedLeg
    ? betLegDescription === EBetTypes.SGMulti && isGroupedLeg
    : bet.bet_description === EBetTypes.SGMulti && !leg;
  const isSgmLeg = isGroupedLeg
    ? betLegDescription === EBetTypes.SGMulti && !isGroupedLeg
    : bet.bet_description === EBetTypes.SGMulti && leg;

  const isSrmBet = isGroupedLeg
    ? betLegDescription === EBetTypes.SRMulti && isGroupedLeg
    : bet.bet_description === EBetTypes.SRMulti && !leg;

  const isSrmLeg = isGroupedLeg
    ? betLegDescription === EBetTypes.SRMulti
    : bet.bet_description === EBetTypes.SRMulti && leg;

  const isBlendedBet = isGroupedLeg
    ? betLegDescription === EBetTypes.Blended && isGroupedLeg
    : bet.bet_description === EBetTypes.Blended && !leg;

  const isBlendedLeg = isGroupedLeg
    ? betLegDescription === EBetTypes.Blended && isGroupedLeg
    : bet.bet_description === EBetTypes.Blended && leg;

  const isMysteryBet =
    bet.bet_description === EBetTypes.MysteryBet &&
    bet.price_type === 'mystery_bet';

  const eventData = bet?.bet_legs?.[index ?? 0]?.event_data;
  const isMysteryRollover = bet?.bet_legs?.[index ?? 0].leg_num === 2;

  const isToteMultiBet = isToteMulti(bet.bet_description as string);

  const mysteryBetContent = isMysteryBet && eventData && (
    <>
      {isMysteryRollover && 'Rollover /'}{' '}
      {POSITION_MAP[eventData.proposition_type ?? 'Top1']} /{' '}
      {`R${eventData.race_number} ${eventData.venue_name}`}
      {!leg
        ? ` - ${intl.formatMessage({ id: 'generic.stake' })} ${centsToDollars(
            bet?.stake
          )}`
        : ''}
    </>
  );

  const eventStart = getEventStart(bet);
  const eventHasStarted = dayjs().isAfter(
    dayjs(new Date(eventStart ?? new Date()))
  );
  const eventPath = getEventHref(bet);
  const hasRollover = bet?.bet_legs?.some((l) => l.leg_num === 2);

  const renderIcon = () => {
    if (isMysteryBet && !leg && hasRollover)
      return (
        <Icon as={QuestionCircleFill} {...headerRowStyles.mysteryBetIcon} />
      );

    if (isSgmBet || isSrmBet || isBlendedBet) {
      return (
        <HeaderSvg
          name={getIconBySportName(leg?.event_icon || bet.event_icon)}
        />
      );
    }

    if (isSgmLeg) {
      return null;
    }

    if (!leg && bet?.bet_legs) {
      return <MultiIcon />;
    }

    return (
      <HeaderSvg name={getIconBySportName(leg?.event_icon || bet.event_icon)} />
    );
  };

  const renderOdds = () => {
    if (bet.price_type === 'starting' && !isResulted) {
      return (
        <BetOdds data-cy="betOdds">
          <FormattedMessage id="account.myBetsCard.atStartingPriceAbbrev" />
        </BetOdds>
      );
    }
    if (bet.price_type === 'tote_single_mid' && !isResulted) {
      return (
        <BetOdds data-cy="betOdds">
          <FormattedMessage id="account.myBetsCard.atToteAbbrev" />
        </BetOdds>
      );
    }

    if (bet.price_type === 'tote_single_best' && !isResulted) {
      return (
        <BetOdds data-cy="betOdds">
          <FormattedMessage id="account.myBetsCard.atBestToteAbbrev" />
        </BetOdds>
      );
    }

    if (odds) {
      return <BetOdds data-cy="betOdds">{`@${odds}`}</BetOdds>;
    }

    return null;
  };

  const renderBetReturn = () => {
    if (isMoM) return;

    if (leg) {
      return (
        <BetReturn data-cy="myBetsCardLegBetReturn" status={status}>
          {status.toUpperCase()}
        </BetReturn>
      );
    }

    const moneyBackBet = bet.promotions?.find(
      (promo) => promo.type === 'money_back'
    );

    if (
      bet.status === 'settled' ||
      bet.status === 'cancelled' ||
      bet.status === 'voided'
    ) {
      return (
        <Flex gap="2">
          <BetReturn data-cy="myBetsCardBetReturn" status={status}>
            <FormattedMessage
              id={
                (bet.is_won && 'account.mybetscard.won') ||
                (bet.status === 'voided' && 'account.mybetscard.void') ||
                (bet.status === 'cancelled' &&
                  'account.mybetscard.cancelled') ||
                'account.mybetscard.noreturn'
              }
              values={
                (bet.is_won && {
                  winnings: centsToDollars(bet.payout),
                  PrePromoPayout: () => null,
                }) ||
                undefined
              }
            />
          </BetReturn>
          {moneyBackBet && moneyBackBet.bonus_amount && (
            <BetReturn data-cy="myBetsCardBetReturnMoneyBack" status="pending">
              <FormattedMessage
                id="account.mybetscard.bonus"
                values={{
                  winnings: centsToDollars(moneyBackBet.bonus_amount),
                }}
              />
            </BetReturn>
          )}
        </Flex>
      );
    }

    return null;
  };

  return (
    <ContentRow flex="1" gap="1.5">
      {renderIcon()}
      <Flex direction="column" flex="1">
        <RunnerDetails data-cy="myBetsCardRunnerDetails">
          {(() => {
            switch (bet.bet_description) {
              case 'Same Race Multi': {
                if (leg) {
                  return runnerDetails[0];
                }
                return (
                  <Flex>
                    <FormattedMessage
                      id="betSlip.betSlipModal.srmHeader"
                      values={{
                        legCount: bet.bet_legs?.length ?? 0,
                        span: (chunks) => (
                          <VisuallyHidden>{chunks}</VisuallyHidden>
                        ),
                      }}
                    />
                    <SrmLogo {...headerRowStyles.headerRowSvg} />
                  </Flex>
                );
              }
              case 'Same Game Multi': {
                if (leg) {
                  return runnerDetails[0];
                }
                return (
                  <Flex>
                    <FormattedMessage
                      id="betSlip.betSlipModal.sgmHeader"
                      values={{
                        legCount: bet.bet_legs?.length ?? 0,
                        span: (chunks) => (
                          <VisuallyHidden>{chunks}</VisuallyHidden>
                        ),
                      }}
                    />
                    <SgmLogo {...headerRowStyles.headerRowSvg} />
                  </Flex>
                );
              }
              case 'Blended': {
                if (leg) {
                  return runnerDetails[0];
                }
                return (
                  <Flex>
                    <FormattedMessage id="betSlip.betSlipModal.blendedHeader" />
                  </Flex>
                );
              }
              case 'Multi': {
                if (!leg) {
                  return runnerDetails[0];
                }

                if (leg) {
                  switch (leg.bet_description) {
                    case 'Same Race Multi': {
                      return (
                        <Flex>
                          <FormattedMessage
                            id="betSlip.betSlipModal.srmHeader"
                            values={{
                              legCount: bet.bet_legs?.length ?? 0,
                              span: (chunks) => (
                                <VisuallyHidden>{chunks}</VisuallyHidden>
                              ),
                            }}
                          />
                          <SrmLogo {...headerRowStyles.headerRowSvg} />
                        </Flex>
                      );
                    }
                    case 'Same Game Multi': {
                      return (
                        <Flex>
                          <FormattedMessage
                            id="betSlip.betSlipModal.sgmHeader"
                            values={{
                              legCount: leg.legs?.length ?? 0,
                              span: (chunks) => (
                                <VisuallyHidden>{chunks}</VisuallyHidden>
                              ),
                            }}
                          />
                          <SgmLogo {...headerRowStyles.headerRowSvg} />
                        </Flex>
                      );
                    }
                    case 'Blended': {
                      return (
                        <Flex>
                          <FormattedMessage id="betSlip.betSlipModal.blendedHeader" />
                        </Flex>
                      );
                    }
                    default: {
                      return runnerDetails[0];
                    }
                  }
                }
                return (
                  <Flex>
                    <FormattedMessage
                      id="betSlip.betSlipModal.multiHeader"
                      values={{
                        legCount: bet.bet_legs?.length ?? 0,
                        span: (chunks) => (
                          <VisuallyHidden>{chunks}</VisuallyHidden>
                        ),
                      }}
                    />
                  </Flex>
                );
              }
              case EBetTypesDisplayNames.Quaddie:
              case EBetTypesDisplayNames.EarlyQuaddie:
              case EBetTypesDisplayNames.BigSix:
              case EBetTypesDisplayNames.DailyDouble:
              case EBetTypesDisplayNames.Treble:
              case EBetTypesDisplayNames.RunningDouble: {
                return <Flex>{runnerDetails[0]}</Flex>;
              }
              default: {
                break;
              }
            }
            if (runnerDetails[0]) return runnerDetails[0];
            return null;
          })()}
          {runnerDetails[1] && !isMoM && isToteMultiBet && (
            <TextBarrierNumber data-cy="myBetsCardBarrierNumber" as="span">
              {runnerDetails[1]}
            </TextBarrierNumber>
          )}
        </RunnerDetails>
        {leg && POSITION_MAP[leg?.event_data.proposition_type] && (
          <TextBarrierNumber>
            {POSITION_MAP[leg?.event_data.proposition_type]}
          </TextBarrierNumber>
        )}
        <RunnerDescription>
          <TextDescription as="span" data-cy="myBetsCardBetType">
            {(isSrmBet || isBlendedBet) && !eventHasStarted ? (
              <LinkVenueName to={eventPath}>
                {runnerDescription[0]}
              </LinkVenueName>
            ) : (
              <>
                {isSgmBet && runnerDescription[0]}
                {isSgmLeg && runnerDescription[0]}
                {isSrmBet && runnerDescription[0]}
                {isBlendedBet && runnerDescription[0]}
                {!isSrmLeg || (isBlendedBet && runnerDescription[0])}
                {!isSgmBet &&
                  !isSgmLeg &&
                  !isSrmBet &&
                  !isSrmLeg &&
                  !isBlendedBet &&
                  !isBlendedLeg &&
                  !isMysteryBet &&
                  runnerDescription[0]}
                {isMysteryBet && mysteryBetContent}
              </>
            )}
          </TextDescription>

          {runnerDescription[1] && (
            <>
              {runnerDescription[0] && (
                <Text {...dotSeperatorProps} as="span">
                  {' '}
                  •{' '}
                </Text>
              )}

              <Text as="span" data-cy="myBetsCardStake">
                {runnerDescription[1]}
              </Text>
            </>
          )}

          {(isSgmBet || isSrmBet || isBlendedBet) &&
            (() => {
              if (leg) {
                return (
                  <p>
                    <time dateTime={leg.event_star}>
                      {formatDayDateMonthTime(leg.event_start)}
                    </time>
                  </p>
                );
              }
              if (bet.bet_description === 'Multi') return null;
              return (
                <p>
                  <time dateTime={bet.event_start}>
                    {formatDayDateMonthTime(bet.event_start)}
                  </time>
                </p>
              );
            })()}
        </RunnerDescription>
      </Flex>
      <Flex flexDir="column" alignSelf="flex-start" gap="0.5" h="9">
        <Flex gap="0.5">
          {!!originalOdds && (
            // TODO: put these in a factory once we have designs
            <Text {...headerRowStyles.textOriginalOdds}>
              @{originalOdds?.toFixed(2)}
            </Text>
          )}
          {renderOdds()}
        </Flex>
        {renderBetReturn()}
        {bet.status === 'cashed_out' && (
          <Flex
            data-testid="headerRow-cashedOut"
            sx={{
              bg: '#8192AA',
              color: '#333',
              mb: '2',
              px: '2',
              py: '0.5',
              borderRadius: 'md',
              fontSize: 'xs',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            Cashed OUT {centsToDollars(bet.payout, true)}
          </Flex>
        )}
      </Flex>
    </ContentRow>
  );
};

export type THeaderRow = {
  bet: TConfirmedBet;
  isResulted?: boolean;
  leg?: TMyBetsMultiBetLegs;
  index?: number;
};

export default HeaderRow;
