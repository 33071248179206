import { WellSchema } from './Well.styles';

export const wellStyles: WellSchema = {
  wrapper: {
    bg: 'blackAlpha.500',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
    p: '1.5',
    py: '2',
  },
};
