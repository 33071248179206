import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    border: 'none',
    borderTop: '1px dashed',
    borderColor: 'gray.200',
    bg: 'transparent',
    boxShadow: 'none',

    _first: {
      borderTop: 'none',
    },
    _last: {
      borderTop: 'none',
    },

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      p: '2',

      _first: { borderTop: 0 },

      _hover: {
        span: {
          _first: { color: 'epsilon.500' },
        },
        bg: 'epsilon.50',
      },
    }),

    ...(isSelected && {
      boxShadow: 'none',
    }),

    ...(!!isSelected && {
      boxShadow: 'none',
    }),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',
    color: 'epsilon.500',

    _first: {
      fontWeight: 'bold',
      bg: 'transparent',
      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      bgGradient: isSelected
        ? 'linear(to-b, gamma.700, gamma.700)'
        : 'linear(to-b, alpha.500, gamma.500)',
      p: 2,
      w: 'full',
      borderRadius: 'md',
      color: isSelected ? 'white' : 'gamma.800',
      fontWeight: 'black',
      textShadow: isSelected ? 'unset' : '0px 4px 4px rgba(0, 0, 0, 0.15)',
      boxShadow: isSelected
        ? 'inset 0px 1px 0px 2px rgba(0, 0, 0, 0.3)'
        : 'none',

      transition: 'all, .3s ease-in-out',

      _hover: {
        bg: isSelected ? 'gamma.600' : 'alpha.500',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },
  },
});
