import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',
    color: 'white',
    border: '2px solid',
    borderColor: 'beta.500',
    height: '5',
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'alpha.800',
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'primary',
  height: '100%',
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'primary',
  height: '100%',
};
