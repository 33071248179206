import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'black',
    borderRadius: 'base',
    border: '2px solid',
    borderColor: 'alpha.600',
    gap: '3',
  },
  depositIconProps: {
    color: 'alpha.600',
    boxSize: '9',
  },
};
