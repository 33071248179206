import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'gray.600', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'gray.600'] },
};

export const FlexHeadingRow: CSSObject = {
  bg: 'beta.500',
  borderBottom: '1px',
  borderBottomColor: 'gray.300',
};

export const FlexHeading: CSSObject = {
  pt: '1.5',
  pb: '2',
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  borderTopRadius: 'md',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'beta.200',
  bg: 'alpha.500',
  borderRadius: 'base',
  p: '1',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  mx: ['2.5', '3'],
  mb: ['2.5', '3'],
  bg: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  borderWidth: '2px',
  borderColor: 'epsilon.500',
  borderRadius: 'md',
  _last: {
    mb: '0',
  },
};

export const BoxTableWrapper: CSSObject = {
  borderBottomRadius: 'md',
  p: '0',
  borderTopWidth: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.400',
};

export const TextHeading: CSSObject = {
  color: 'beta.500',
  ontSize: 'sm',
  ontWeight: 'semibold',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'gray.600',
};

export const TextRaceResults: CSSObject = {
  color: 'blackAlpha.500',
};

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'beta.100' : 'transparent',
  borderColor: 'gray.400',
  _hover: {
    background: isToteMultiAvailable
      ? 'beta.100'
      : ['transparent', null, 'gray.300'],
  },
});

export const FlexRow: CSSObject = {
  bg: 'white',
  _last: {
    '& > *': {
      borderBottom: ['unset', null, 'unset'],
    },
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderTop: 'none',
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'gray.400'],
  bg: 'white',
  py: '1.5',
};

export const CenterTH = () => ({
  bg: 'beta.500',
  color: 'white',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 'medium',
  py: '2.5',
});

export const ButtonChevron: CSSObject = {
  color: 'alpha.600',
  bg: 'alpha.300',
  borderRadius: 'base',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BadgeRaceNumber: CSSObject = {
  bg: 'beta.200',
  color: 'beta.700',
  py: '1',
  borderRadius: 'base',
};
