import { CSSObject } from '@chakra-ui/react';
import bgDt from '@/assets/sugarcastle/images/featured/featuredDesktop.png';
import bgMb from '@/assets/sugarcastle/images/featured/featuredMb.png';
import cardBg from '@/assets/sugarcastle/images/featured/cardBg.png';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    borderRadius: 'md',
    minW: 12,
  },
};

export const FlexFeatureWrapper: CSSObject = {
  w: '100%',
  minH: [null, '370px'],
  p: '0',
  h: [null, '350px'],
  bgRepeat: 'no-repeat',
  bgSize: ['cover', null, null, null, '100% 100%'],
  bgImage: ['none', `url('${bgDt}')`],
};

export const FlexFeatureCardWrapper: CSSObject = {
  p: '5',
  pl: ['5', null, null, '200px'],
  pt: ['0', 'unset'],
};

export const FlexFeatureTitleWrapper: CSSObject = {
  bgImage: [`url(${bgMb})`, 'none'],
  bgSize: 'contain',
  bgRepeat: 'no-repeat',
  bgPosition: 'bottom center',
  minH: '185px',
  w: '100%',
};

export const LinkCardHeader: CSSObject = {
  bg: 'transparent',

  '.state-inprogress': {
    bg: 'gamma.400',
    color: 'alpha.700',
    borderRadius: 'base',
  },

  '.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
  },

  '.state-ended': {
    bg: 'red.500',
    color: 'white',
    borderRadius: 'base',
  },
};

export const IconCardHeader: CSSObject = {
  color: 'beta.600',
};

export const TextCardHeader: CSSObject = {
  color: 'beta.600',
  fontFamily: 'Fraunces',
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const TextRaceNumber: CSSObject = {
  bg: 'beta.500',
  borderRadius: 'base',
  color: 'beta.300',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'beta.600',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  boxShadow:
    '0px 1px 0px 0px rgba(0, 0, 0, 0.30), 0px 3px 4px -3px rgba(0, 0, 0, 0.30) inset',
  ':first-child': {
    pl: '3',
  },
  lineHeight: 'normal',
};

export const FlexGridRunnerItem: CSSObject = {
  bg: 'transparent',
  borderBottom: '1px',
  borderBottomColor: 'blackAlpha.500',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'beta.600',
  fontWeight: 'bold',
  'span[data-cy*="raceCardBarrierNumber"]': {
    fontSize: '2xs',
    fontWeight: 'bold',
    bottom: '2px',
    position: 'relative',
  },
};

export const TextRunnerNumber: CSSObject = {
  _last: {
    color: 'epsilon.500',
    fontSize: '2xs',
    fontWeight: 'semibold',
  },
};

export const LinkRaceDetails: CSSObject = {
  color: 'white',
  mx: '0.5',
  mb: '0.5',
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  boxShadow: '0px -2px 0px 0px rgba(0, 0, 0, 0.24)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
  textShadow: '0px 1px 0px rgba(255, 185, 130, 0.50)',
  lineHeight: 'normal',
  _hover: {
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  },
};

export const BoxCard: CSSObject = {
  my: ['0', null, '1'],
  mb: ['1', null, '0'],
  borderRadius: 'base',
  border: '2px solid',
  borderColor: 'beta.400',
  boxShadow:
    '0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 2px #443318 inset',
  bgImage: cardBg,
  bgSize: 'cover',
};
