import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  borderTop: '1px dashed',
  color: 'gamma.500',

  '&&': {
    borderColor: 'blackAlpha.300',
  },
};

export const TextListName: CSSObject = {
  fontSize: ['xs', null, 'sm'],
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'gamma.500',
  ...(isVS && { color: 'gamma.800' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'gamma.400',
  fontSize: ['2xs', null, 'xs'],
};

export const IconSport: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'alpha.400',
};

export const IconChevron: CSSObject = {
  color: 'gamma.500',
};

export const FlexEndWrapper: CSSObject = {
  fontSize: '2xs',
  '.chakra-badge.state-prior': {
    fontWeight: 'semibold',
    fontSize: '2xs',
    px: '0',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'alpha.400',
  },
};
