import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'odds',
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'accent',
    fontSize: '2xs',
    color: 'white',
  },
  buttonBaseBox: {
    fontSize: 'xs',
    fontWeight: 'extrabold',
    fontFamily: 'accent',
    color: 'beta.400',
    textShadow: '0px 1px 4px rgba(0, 0, 0, 0.5)',
    mt: '-0.5',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
