import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxHeading: {
    pt: [null, null, '1', null, null],
    mb: [null, null, '0', null, null],
    h: '100%',
  },
  boxWrapper: {
    mx: ['2', null, 'unset'],
    borderRadius: '0',
    pb: 'unset',
  },
  textTitle: {
    mt: '0',
    mb: '2',
    color: 'beta.300',
    fontSize: 'md',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'Fraunces',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        p: '4',
        pt: '5',
        display: 'flex',
        borderBottomRadius: 'base',
        bgGradient: 'linear(to-b, alpha.500, alpha.600)',
        color: 'white',
        boxShadow:
          '0px 0px 0px 2px #FFE6B6 inset,0px 0px 0px 4px #302410 inset,0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset,0px 2px 7px 0px rgba(0, 0, 0, 0.30)',
        flexDirection: ['column', null, 'row'],
        borderRadius: 'md',
      },
    },
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    px: [null, null, '3', null, null],
    pt: ['0', null, '1'],
    pb: [null, null, '4', null, null],
    mt: ['8', null, '0'],
    w: '100%',
  },
};
