import { CSSObject } from '@chakra-ui/react';

export const FlexGroupWrapper: CSSObject = {
  mb: '1',
  my: '2',
};

export const GroupText: CSSObject = {
  color: 'white',
  fontFamily: 'fredoka',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: '120%',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.15)',
};

export const TextBetCount: CSSObject = {
  fontFamily: 'roboto',
  fontSize: 'xs',
  lineHeight: '140%',
  verticalAlign: 'bottom',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  minW: 'unset',
  color: 'black',
  bg: 'var(--local-gradient-green, linear-gradient(180deg, #6DDA01 0%, #5EBB01 100%))',
  boxShadow:
    '0px 2px 2px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
};
