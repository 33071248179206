import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  wrapper: {
    bottom: '0',
    left: '0',
    position: 'sticky',
    right: '0',
    zIndex: 'sticky',
  },
  slideBox: {
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    color: 'white',
    mb: '-4',
    borderBottomRadius: 'base',
  },
  accordionItem: {
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
  },
  accordionHeaderText: {
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    bgGradient: 'linear(to-b, zeta.400, zeta.600)',
    color: 'alpha.600',
    fontSize: 'xs',
    fontWeight: 'bold',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    gap: '1',
    p: '2',
  },
  accordionButtonIcon: {
    fontSize: 'sm',
  },
  headerStack: {
    bg: 'alpha.900',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    py: '2',
  },
  headerText: {
    flex: '1',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
  },
  accordionPanel: {
    fontSize: 'xs',
    maxH: '32',
    overflow: 'auto',
    p: '0',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  },
  selectionFlex: {
    borderColor: 'blackAlpha.300',
    gap: '2',
    p: '2',
    pos: 'relative',
    _notFirst: {
      borderTopStyle: 'dotted',
      borderTopWidth: '1px',
    },
    _before: {
      border: 'inherit',
      borderColor: 'whiteAlpha.300',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
  },
  footerStack: {
    borderBottomRadius: 'base',
    bg: 'alpha.700',
    borderColor: 'whiteAlpha.300',
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    p: '2',
    pb: '6',
    pos: 'relative',
    _before: {
      bgGradient: 'linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, .2))',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '17px',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
  },
  legsText: {
    flex: '0.5',
    display: 'flex',
    flexDir: 'column',
    textTransform: 'uppercase',
  },
  legsChunks: {
    color: 'beta.300',
    fontSize: ['md', 'xl'],
    fontWeight: 'black',
  },
  oddsText: {
    flex: '2',
    display: 'flex',
    flexDir: 'column',
    textTransform: 'uppercase',
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'beta.300',
    fontWeight: 'black',
    fontSize: ['md', 'xl'],
  },
  clearButton: {
    variant: 'outline',
    h: ['8', '9'],
    px: '2',
    py: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'md',
    fontFamily: 'SkranjiBold',
    fontSize: ['xs', 'sm'],
    border: '2px',
    borderColor: 'white',
    fontWeight: 'bold',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  addToSlipButton: {
    h: ['8', '9'],
    py: '1',
    px: 2,
    lineHeight: 'normal',
    fontFamily: 'SkranjiBold',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
    fontWeight: 'bold',
    fontSize: ['xs', 'sm'],
    color: 'beta.800',
    boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.25) inset',
    border: '2px',
    borderColor: 'beta.700',
    bgGradient: 'linear(to-b, beta.500, beta.600)',
    textTransform: 'uppercase',
    _hover: {
      opacity: '0.5',
    },
  },
  buttonsHStack: {
    gap: ['0', '2'],
  },
};
