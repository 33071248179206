import React from 'react';
import { CSSObject, Image } from '@chakra-ui/react';
import ninja from '../../../../../../assets/questbet/images/header/ninja.png';
import coins from '../../../../../../assets/questbet/images/nav/coins.svg';
import ninjauser from '../../../../../../assets/questbet/images/nav/ninjauser.svg';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'xs',
};

export const BoxBallance: CSSObject = {
  bg: 'gray.50',
  color: 'gray.800',
};

export const buttonDepositProps: CSSObject = {
  bg: 'alpha.700',
  color: 'white',
  borderRadius: 'sm',
  boxShadow: 'md',
  textShadow: '0px 1px 3px #2c2c2c',
  fontSize: 'sm',
  w: 'full',

  sx: {
    '&&': {
      p: '0',
    },

    '& svg': {
      textShadow: '0px 1px 5px #2c2c2c',
    },

    _hover: { bg: 'alpha.800', color: 'white' },
    _active: { bg: 'alpha.500', color: 'white' },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  color: 'gray.800',

  '&& > p': { transition: 'all 0.2s ease-in-out' },

  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.50',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${ninja})`,
  bgSize: '100%',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '-1',

  display: ['none', null, 'block'],

  w: ['10', '10', '24'],
  h: ['10', '10', '65px'],
};

export const DepositIcon = () => <Image src={coins} width={4} height={4} />;

export const UserIcon = () => (
  <Image src={ninjauser} mr={1} width={4} height={4} />
);
