import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'md',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextTitle: CSSObject = {
  color: 'gamma.600',
  mt: '2',
};

export const LinkItem: CSSObject = {
  borderTopColor: 'gray.300',
  borderBottom: 0,
  borderBottomColor: 'transparent',
  color: 'gray.700',
  bg: 'white',

  _hover: { bg: 'alpha.100' },
};

export const IconSport: CSSObject = {
  color: 'alpha.600',
};

export const IconChevron: CSSObject = {
  boxSize: ['4', '6'],
  border: '1px',
  borderColor: 'alpha.700',
  borderRadius: 'full',
  p: '1',
  mr: '2',
  color: 'alpha.700',
  transition: 'inherit',

  _hover: {
    color: 'alpha.800',
    borderColor: 'alpha.800',
  },
};

export const TextName: CSSObject = {
  color: 'alpha.600',
};
