import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  px: '2',
  color: 'black',
};

export const BoxTakeActionTable: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '2',
  bg: 'white',
};

export const TextTakeAction: CSSObject = {
  color: 'gray.700',
  fontWeight: 'medium',
};
