import React from 'react';
import { getStrings } from '@/helpers/utils';
import { FlexWrapper, TextItem } from './styles/ResultsTableHeader.styles';

const ResultsTableHeader: React.FC = () => {
  const [{ Racing: Strings }] = getStrings();

  return (
    <FlexWrapper>
      {/* Temporarily hidden
      <TextItem>{Strings.Exotics.betType}</TextItem> */}
      <TextItem>{Strings.Exotics.Exotics}</TextItem>
      <TextItem>{Strings.Exotics.dividends}</TextItem>
    </FlexWrapper>
  );
};

export default ResultsTableHeader;
