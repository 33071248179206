import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TextTitle,
  FirstWord,
  TitleContainer,
  TextTitleSuffixContent,
} from '../styles/index';

type TTitleProps = {
  id: string;
};

const Title: React.FC<TTitleProps> = ({ id }) => (
  <TextTitle>
    <FormattedMessage
      id={id}
      values={{
        Prefix: (word: string) => (
          <TitleContainer>
            <FirstWord>{word}</FirstWord>
          </TitleContainer>
        ),
        Suffix: (words: string) => (
          <TextTitleSuffixContent>{words}</TextTitleSuffixContent>
        ),
      }}
    />
  </TextTitle>
);

export default Title;
