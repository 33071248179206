import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonOutsideArea = {
  boxShadow: 'none',
  _after: {
    backgroundImage: 'none',
  },
};

export const FlexContainer = (): CSSObject => ({
  px: ['2.5', null, null, '0'],
});

export const buttonLogInProps: ButtonProps = {
  variant: 'secondary',
  height: '44px',
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  height: '44px',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'secondary',

  height: '44px',
  p: '2.5',
  sx: {
    '.btn-span': {
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'black',
    },
    '.span-balance': {
      fontSize: '18px',
      lineHeight: '14px',
    },
    '.span-bonus': {
      fontSize: 'sm',
      lineHeight: '10px',
    },

    'svg:not(.chevron-icon)': {
      pos: 'relative',
      top: '0.5',
      mr: '0.5',
    },
    '.chevron-icon': {
      pos: 'absolute',
      right: '1.5',
      top: '0',
      bottom: '0',
      margin: 'auto 0',
    },

    '&&[aria-expanded="true"]': {
      borderBottomRadius: '0',
      borderTop: '2px',
      borderBottom: 'none',
      borderColor: 'white',
      bgGradient: 'linear(to-b, alpha.400, alpha.700)',
      boxShadow: 'none',

      color: 'beta.600',

      zIndex: 'dropdown',
      '.chevron-icon': { transform: 'rotate(180deg)' },

      '&:after': {
        content: `''`,
        display: 'inline-block',
        h: '2',
        left: 0,
        right: 0,
        bottom: '-1.5',
        position: 'absolute',
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  height: '44px',
  padding: '0px 5px',
  pt: '9px',
  pb: '13px',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'black',
    },
    '.span-bet': {
      fontSize: 'sm',
    },
    '.span-bet-count': {
      fontSize: '20px',
    },
  },
};

export const buttonBetSlipProps: ButtonProps = {
  height: '44px',
  padding: '0px 5px',
  pt: '9px',
  pb: '13px',

  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'black',
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'white',
  boxSize: '7',
  ml: '-1',

  '& svg': {
    boxSize: '7',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '1.5',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const buttonQuickAccess: ButtonProps = {
  variant: 'secondary',
};

export const ButtonGroupBets: CSSObject = {
  gap: '0.5',

  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },
};

export const ButtonBetSlip: CSSObject = {
  ml: '0',
};

export const ButtonGroup: CSSObject = {
  gap: '1',
};

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
};

export const TextButton: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'black',
};

export const TextButtonNumber: CSSObject = {
  fontSize: '20px',
  fontWeight: 'black',
};
