import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: {},
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  sx: {
    fontWeight: '600',
    py: '4',
    px: '3',
    borderBottom: '4px solid',
    borderBottomColor: 'transparent',
    color: 'beta.500',
    borderRadius: 'none',

    '&&': {
      bg: 'transparent',
    },

    '&[aria-selected="true"]': {
      borderBottomColor: 'gamma.500',
    },
  },
};
