import { useEffect } from 'react';
import { useMutationClaimToken } from '@/api/punter/claimToken/claimToken.hooks';
import { TPunterBetRequest } from '@/api/punter/punter.types';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setBonusChanceModal } from '@/redux/Betslip.slices';
import {
  isExotic,
  roundDownStake,
} from '@/views/races/bets/Exotics/services/Exotics.utils';
import { TBetSlipBet } from '../../Services/Betslip.types';
import { useFeatureFlag } from '@/store/FeatureFlagStore';

export function useClaimTokenOnApproval(betRequest?: TPunterBetRequest) {
  const hasLD = useFeatureFlag('LUCKY_DIP_ENABLED');
  const dispatch = useAppDispatch();
  const mutation = useMutationClaimToken({
    onSuccess: ({ data }) => {
      dispatch(
        setBonusChanceModal({
          ...data.gameplay,
          isVisible: !hasLD,
          isLDVisible: hasLD,
        })
      );
    },
  });

  const betBoostToken = betRequest?.promo_tokens?.find(
    ({ can_claim } = {}) => can_claim
  );

  useEffect(() => {
    if (!betBoostToken?.id) return;
    dispatch(setBonusChanceModal(null));
    mutation.mutate(betBoostToken.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betBoostToken?.id]);
}

export const useDefaultStake = (bet: TBetSlipBet | undefined) => {
  const { focusedBet } = useAppSelector((state) => state.betSlip);

  const defaultStake = ['5', '10', '20', '50', '100'];

  const getUpdateValue = (value: string) => {
    const newValue =
      (Number(value) ?? 0) +
      (Object.is(bet?.stake, NaN) ? 0 : Number(bet?.stake));

    // Exotics must round down to the highest valid stake, given the amount of combinations
    // Max stake button is to be removed, so this exotic logic ignores that possibility
    if (isExotic(bet)) {
      const roundedStake = roundDownStake(
        Number(newValue),
        bet.validSelectionAmount
      );
      return {
        stake: roundedStake.toString(),
        hasBeenRounded: roundedStake !== Number(newValue),
      };
    }

    return {
      stake: newValue.toString(),
      hasBeenRounded: false,
    };
  };

  return {
    isOpen: bet?.request_id === focusedBet,
    defaultStake,
    getUpdateValue,
  };
};
