import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TTransaction } from '@/lib/DBModels';
import { TableText } from '../styles/DesktopTransactionsTable.styles';
import { useWinningsStake } from '@/views/account/Transactions/components/TransactionTable/Services/Transactions.hooks';

export type TBlendedDetailsProps = {
  transaction: TTransaction;
};

export default function BlendedDetails({ transaction }: TBlendedDetailsProps) {
  const {
    bet_id: betId,
    match_name: matchName,
    match_start_time: matchStartTime,
    bet_odds: betOdds = 0,
    leg_details: legDetails = [],
    race_number: raceNumber,
    venue_name: venueName,
    race_type: raceType,
  } = transaction.details ?? {};
  const winningsStake = useWinningsStake(transaction);

  return (
    <>
      <FormattedMessage
        id="account.transactions.blended"
        values={{
          legCount: legDetails.length,
          odds: betOdds?.toFixed(2),
          hasOdds: !!betOdds,
        }}
      />
      {winningsStake}
      <TableText>{matchName}</TableText>
      <TableText
        sx={{ pb: 2 }}
      >{`${raceType} - ${venueName} - ${raceNumber}`}</TableText>
      <TableText sx={{ pb: 2 }}>
        <FormattedMessage
          id="account.transactions.eventDateTimeWithValue"
          values={{
            value: dayjs(matchStartTime).format('DD/MM/YYYY HH:mm:ss (z)'),
            time: (chunks) => <time dateTime={matchStartTime}>{chunks}</time>,
          }}
        />
      </TableText>
      {legDetails.map(
        (
          { proposition_details: { runner_number, runner_name } },
          matchIndex
        ) => (
          <Box key={matchIndex}>
            <TableText>
              {runner_number}. {runner_name}
            </TableText>
          </Box>
        )
      )}
      <TableText sx={{ pt: 2 }}>
        <FormattedMessage
          id="account.transactions.betIdWithValue"
          values={{ value: betId }}
        />
      </TableText>
    </>
  );
}
