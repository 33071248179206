import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    mt: '2',
    mb: '3',
  },
  FlexCard: {
    boxShadow: 'inset 0px 1px 4px #5171ae, inset 0px 1px 1px #404c94',
    bg: 'rgba(70, 78, 139, 0.50)',
    color: 'white',
  },
  BoxGridHeaderItem: {
    textTransform: 'uppercase',
    color: 'white',
    bg: 'blackAlpha.400',
    width: 'full',
  },
  LinkCardHeader: {
    color: 'white',
    p: '1.5',
    sx: {
      '.chakra-badge.state-prior': { color: 'gray.700' },
    },
  },
  TextRaceNumber: {
    bg: 'gamma.900',
    borderRadius: 'sm',
    color: 'white',
  },
  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    p: 0,
  },
  FlexGridRunnerItem: {
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    _last: {
      borderBottom: 'none',
    },
    sx: {
      ':nth-last-child(2)': {
        borderBottom: 'none',
      },
    },
  },
  TextRunnerNumber: {
    fontWeight: 'semibold',
    color: 'white',
    fontSize: 'xs',
  },
  LinkRaceDetails: {
    bg: 'rgba(70, 78, 139, 0.80)',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '2xs',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderBottomRadius: 'md',

    _hover: {
      bg: 'rgba(70, 78, 139, 0.80)',
      color: 'white',
    },
  },
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'semibold',
};

export const buttonRaceCardOddsProps: CustomButtonProps = {
  variant: 'solid',
  fontFamily: 'body',
  fontWeight: 'bold',
  fontSize: 'sm',
  borderRadius: 'sm',
  w: '14',
  h: '8',
  lineHeight: 'normal',
};
