import { chakra, Flex, Text } from '@chakra-ui/react';
import { styleImports } from './MyBets.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button, CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type MyBetsSchema = Partial<{
  buttonShowMoreProps: CustomButtonProps;
}>;

export const myBetsStyles: MyBetsSchema = {
  buttonShowMoreProps: {
    'data-testid': 'myBets-buttonShowMoreProps',

    ...styleImports?.[themeName]?.myBetsStyles?.buttonShowMoreProps,
  },
};

export const ButtonShowMore = chakra(Button, {
  label: 'MyBetsShowMore',
  baseStyle: () => ({
    px: '2',
    py: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonShowMore;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexBetCard = chakra(Flex, {
  label: 'myBets-FlexBetCard',
  baseStyle: () => ({
    flexDirection: 'column',
    rowGap: '4',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexBetCard;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const NoResultsText = chakra(Text, {
  label: 'NoResultsText',
  baseStyle: () => ({
    fontSize: 'xs',
    textAlign: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.NoResultsText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
