import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import Card from '../Card';
import {
  FlexFeatureWrapper,
  FlexFeatureTitleWrapper,
  FlexFeatureCardWrapper,
  HeadingSlogan,
  SpanSlogan,
  ImageMascot,
} from '../styles/Featured.styles';
import baseTemplate from './template.default';
import ninja from '../../../../../../assets/betroyale/images/homeFeaturedRacing/banner.png';

const template: TTemplate = {
  wrapper: <FlexFeatureWrapper />,
  feature: (
    <FlexFeatureTitleWrapper>
      <HeadingSlogan>
        Featured
        <SpanSlogan>race</SpanSlogan>
      </HeadingSlogan>
      <ImageMascot src={ninja} />
    </FlexFeatureTitleWrapper>
  ),
  card: (
    <FlexFeatureCardWrapper>
      <Card />
    </FlexFeatureCardWrapper>
  ),

  ...(baseTemplate?.first as TTemplate),
};

export default template;
