import { CSSObject, TextProps } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const closeIcon: TCloseIcon = {
  boxSize: '6',
  as: CloseCircle,
  color: 'white',
};

export const FlexBetslipHeading: CSSObject = {
  h: '12',
  px: '2.5',
  borderBottomColor: 'beta.400',
  borderBottom: '1px',
  boxShadow: '0px 1px 0px 0px #446097',
};

export const BetslipTitle: CSSObject = {
  color: 'beta.500',
  fontFamily: 'Spectral',
  fontWeight: 'extrabold',
  fontSize: 'lg',
  lineHeight: 1.3,
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  '& svg': {
    boxSize: '5',
    color: 'beta.400',
    filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))',
  },
};

export const BetslipTitleText: TextProps = {
  fontFamily: 'Imperator',
  fontSize: 'lg',
  mb: '0',
  color: 'beta.400',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.50)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const BetLengthIndicator: CSSObject = {
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  color: 'beta.400',
  fontSize: 'xs',
  borderRadius: 'base',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 1px 1px 0px #2E5B75 inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'gamma.700',
};

export const CloseIconButton: CSSObject = {
  border: 'none',
  bg: 'transparent',
  boxSize: '6',
  minW: 6,
  svg: {
    boxSize: '6',
    color: 'white',
    borderRadius: 'full',
    bg: 'transparent',
    border: 'none',
  },
  _hover: {
    bg: 'transparent',
  },
  _after: { opacity: 0 },
};
