import {
  chakra,
  Flex,
  Icon,
  Text,
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import { styleImports } from './OverviewAccordion.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const IconPerson = chakra(Icon, {
  label: 'overview-IconPerson',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.IconPerson;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextAccordion = chakra(Text, {
  label: 'overview--TextAccordion',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextAccordion;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexContactToChange = chakra(Flex, {
  label: 'overview-FlexContactToChange',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexContactToChange;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Accordion = chakra(ChakraAccordion, {
  label: 'overview-Accordion',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.Accordion;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const OverviewAccordionItem = chakra(AccordionItem, {
  label: 'overview-AccordionItem',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.OverviewAccordionItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const OverviewPromotionsPanel = chakra(AccordionPanel, {
  label: 'overview-PromotionsPanel',
  baseStyle: () => ({
    display: 'flex',
    flexDir: 'column',
    gap: '2',
    p: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.OverviewPromotionsPanel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const AccordionHeader = chakra(Flex, {
  label: 'overview-AccordionHeader',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.AccordionHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const AccordionHeaderLogOut = chakra(AccordionHeader, {
  label: 'overview-AccordionHeaderLogOut',
  baseStyle: () => ({
    alignItems: 'center',
    border: '1px',
    borderColor: 'gray.200',
    cursor: 'pointer',
    p: '2',
    _hover: {
      bg: 'blackAlpha.50',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.AccordionHeaderLogOut;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
