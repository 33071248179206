import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import logoDesktop from '@/assets/betgalaxy/images/logo.svg';
import logoMobile from '@/assets/betgalaxy/images/logoMobile.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <>
      <Show above="lg">
        <Image src={logoDesktop} alt={`${THEME_NAME.betgalaxy}`} />
      </Show>

      <Hide above="lg">
        <Image src={logoMobile} alt={`${THEME_NAME.betgalaxy}`} />
      </Hide>
    </>
  );
}
