import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  w: '12',
  variant: 'numb',
  height: '34px',
  _disabled: {
    '&&': {
      bg: 'gray.300',
      boxShadow:
        '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
      color: 'gray.500',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
      fontWeight: 'black',
      pointerEvents: 'none',
    },
  },
  sx: {
    '&:active, &[data-active="true"]': {
      color: 'beta.400',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.50)',
      fontWeight: 'extrabold',
      bg: 'alpha.600',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  h: '3',
  width: '100%',
  maxH: 'calc(var(--bc-space-3) + 4px)',
  mr: '-1px',
  ml: '-1px',
  position: 'absolute',
  top: '0',
  borderTopRadius: 'base',
  bg: 'alpha.400',
  boxShadow: '0px 3px 4px 0px rgba(255, 255, 255, 0.25) inset',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'white',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textShadow: 'none',
};
