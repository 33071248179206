import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'beta.600',
  border: '2px solid',
  borderColor: 'alpha.500',
  boxShadow:
    '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  p: '3',
  mt: '0',
  alignSelf: 'center',
};
export const HeaderText: CSSObject = {
  color: 'alpha.400',
  fontSize: 'md',
  fontWeight: 'normal',
  p: '0',
  textTransform: 'none',
  fontFamily: 'accent',
};
export const HeaderContainer: CSSObject = {
  p: '0',
};
export const CloseButton: CSSObject = {
  display: 'none',
};
