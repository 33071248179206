import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flexEventProps: FlexProps = {
  gap: '0',
  pb: '0',
};

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px solid rgba(0, 0, 0, 0.30)',
  pt: '1.5',
  pb: '2',
  w: 'fill-available',
  borderRadius: 'none',
  color: 'white',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  gap: '1px',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'beta.400',
  fontWeight: 'bold',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});

export const LinkMarket: CSSObject = {
  color: 'beta.400',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const CountdownTimerBox: CSSObject = {
  span: {
    color: 'white',
  },
};
