import { CSSObject } from '@chakra-ui/react';

export const ExoticsWrapper: CSSObject = {
  px: ['2', '4'],
};

export const ExoticsContainer: CSSObject = {
  p: '2',
  boxShadow:
    '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
  bg: 'white',
  borderRadius: 'md',
  'div:first-child div label': {
    bg: 'unset',
  },
};

export const CheckboxBoxed: CSSObject = {
  color: 'gray.700',
  right: 0,
  justifyContent: 'flex-end',
  mr: '3',
  borderColor: 'gray.300',
  '.chakra-checkbox__control': {
    bg: 'white',
    '&[data-checked]': {
      color: 'gray.700',
      borderColor: 'gray.300',
      borderWidth: '1px',
      bg: 'white',
      _hover: {
        bg: 'gray.50',
      },
    },
    _hover: {
      bg: 'gray.50',
    },
  },
};

export const TextBoxed: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const SelectFieldContainer: CSSObject = {
  gap: ['1', null, '2'],
};
