import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';
import sideNavDesktop from '../../../assets/puntgenie/images/backgrounds/sideNavDesktop.png';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'delta.500',
      background: {
        bg: ['epsilon.700', null, null, 'beta.500'],
        bgImage: `url(${sideNavDesktop})`,
        bgSize: 'cover',
        bgRepeat: 'no-repeat',
        borderRadius: [null, null, null, 'lg'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
        w: ['270px', null, null, 'auto'],
        boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
      },
      drawer: {
        ...base.drawer,
      },
      icon: {
        ...base.icon,
        boxSize: '20px',
        color: ['white', null, null, 'gamma.500'],
        fill: ['white', null, null, 'gamma.500'],
      },
      headerSX: {
        cursor: 'default',
        color: ['white', null, null, 'gamma.500'],
        fontWeight: 'bold',
        fontFamily: 'CinzelDecorative',
        height: '45px',
        borderColor: 'transparent',
        '&:hover': {
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
        // Last item of sidenav main
        borderBottomColor: 'transparent',
      },
      link: {
        ...base.link,

        fontSize: 'sm',
        fontWeight: 'semibold',
        height: '43px',
        color: ['white', null, null, 'gamma.500'],
        transition: 'all, 0.2s linear',

        borderBottom: '1px dashed',
        borderBottomColor: ['transparent', null, null, 'blackAlpha.300'],
        p: '0',
        ml: '2',
        w: '94%',

        _hover: {
          bg: [null, null, null, 'blackAlpha.300'],
        },

        '~ .nested': {
          pl: '10',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          color: ['white', null, null, 'gamma.500'],
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['0', null, null, '2'],
          borderBottom: '1px dashed',
          borderBottomColor: ['transparent', null, null, 'blackAlpha.300'],
        },

        '> a, .dropDownIcon, a': {
          color: ['white', null, 'gamma.500'],
        },

        '&:hover, &:active': {
          color: 'alpha.700',

          '.dropDownInner': {
            ...base.dropDownInner,
            px: '4',
            color: ['white', null, null, 'gamma.500'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: 0,

        _hover: {
          '.dropDownInner': {
            bg: ['epsilon.400', null, null, 'blackAlpha.200'],
          },
        },

        '.dropDownInner': {
          bg: ['epsilon.400', null, null, 'gamma.400'],
          color: 'white',
          border: 'none',
          mt: '-1px',
          h: 12,
          ml: [-1.5, 0],
          w: '100%',
          px: 2,

          '.dropDownIcon': {
            color: 'white',
          },

          '> .icon, .svgIcon': {
            fill: 'white',
          },

          _hover: {
            bg: ['epsilon.400', null, null, 'gamma.400'],
          },
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
