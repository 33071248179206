import { rest } from 'msw';
import {
  EBetSlipBetSubmissionType,
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
  TBetSlipBet,
  TBetSlipBetSRMulti,
  TBetSubmissionConfirmation,
} from './Betslip.types';
import { EEventType, TPunterAccountOverview } from '../../../lib/DBModels';
import { generateNewBetSlipBet } from './Betslip.utils';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

export const handlerBetSlipAccountOverviewBalanceAvail =
  rest.get<TPunterAccountOverview>(
    `${baseURL}/punter/account/overview`,
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          punter_name: 'Bet Slip Test',
          account_number: '123 456 789',
          account_balance: 20000,
          available_balance: 0,
          bonus: 0,
          power_plays: 0,
          spins: 0,
          bets: 0,
          is_onboarded: true,
          status: 'Normal',
          self_exclusion_expiry: null,
        })
      )
  );

export const handlerBetSlipSubmitSuccess = rest.post<TBetSlipBet[]>(
  `${baseURL}/punter/bet-slip/v2/submit`,
  (req, res, ctx) => {
    const returnedConfirmations = [...req.body].map((bet) => {
      const confirmation: TBetSubmissionConfirmation = {
        request_id: bet.request_id,
        status: EBetSubmissionConfirmationStatus.Placed,
      };
      return confirmation;
    });

    return res(ctx.status(200), ctx.json(returnedConfirmations));
  }
);

export const handlerBetSlipSubmitFailureOddsChanged = rest.post<TBetSlipBet[]>(
  `${baseURL}/punter/bet-slip/v2/submit`,
  (req, res, ctx) => {
    const returnedConfirmations = [...req.body].map((bet) => {
      const confirmation: TBetSubmissionConfirmation = {
        request_id: bet.request_id,
        status: EBetSubmissionConfirmationStatus.Rejected,
        rejection_reason: EBetSubmissionRejectionReason.OddsChange,
        changed_odds: [
          {
            proposition_id: req.body[0].proposition_id ?? '',
            new_odds: 200,
          },
        ],
      };
      return confirmation;
    });

    return res(ctx.status(200), ctx.json(returnedConfirmations));
  }
);

export const mockSRMBet: TBetSlipBetSRMulti = {
  ...generateNewBetSlipBet(EBetSlipBetSubmissionType.SRMulti, 4000),
  race_id: '00101010101010101',
  selection: [[], [5, 10], [5, 10], [2, 5]],
  event_icon: 'Greyhounds',
  event_type: EEventType.Race,
  event_market_name: '',
  event_start_time: '2023-06-27T01:33:00Z',
  event_title: '4 Leg Same Race Multi',
  event_subtitle: `Scooby Doo R4`,
  odds: 500,
  event_data: {
    race_meeting_date: '2023-06-27T01:33:00Z',
    race_number: 5,
    venue_name: 'Displas',
    venue_id: '3333333',
  },
  event_id: '3333993939393993',
};
