import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  borderColor: 'gray.300',
  bg: 'white',
};

export const ExoticSelectionText: CSSObject = {
  color: 'alpha.700',
  fontWeight: '500',
};

export const ContentRow: CSSObject = {
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'epsilon.500',
    bg: 'gamma.700',
    border: '2px solid',
    borderColor: 'gamma.700',
  },
};
