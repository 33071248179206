import { SegmentedSchema } from '@/views/sports/components/Segmented/styles/Segmented.styles';

export const segmentedStyles: SegmentedSchema = {
  segmentWrapper: {
    bg: 'delta.600',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200), 0px 1px 5px 0px var(--bc-colors-blackAlpha-400) inset',
    p: '1',
    spacing: '0.5',
    variant: 'segment',
  },
};
