import { Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { getThemeName } from '@/helpers/getThemeName';
import {
  ContactText,
  ContentBodyText,
  TextLink,
} from '../styles/LocationNotAllowed.styles';

export default function PCBody() {
  const themeName = getThemeName();
  const { email } = getThemeConfig();

  return (
    <ContentBodyText>
      <FormattedMessage
        id="onboarding.locationnotallowed.subheader"
        values={{
          b: (text: string) => <Text as="b">{text}</Text>,
        }}
      />
      <FormattedMessage
        id="onboarding.locationnotallowed.bodycopyntone"
        values={{ themeName }}
      />
      <ContactText
        href={`mailto:${email}?subject=${(
          <FormattedMessage id="onboarding.locationnotallowed.locationenquiry" />
        )}${themeName}`}
      >
        {email}
      </ContactText>
      .{' '}
      <FormattedMessage
        id="onboarding.locationnotallowed.bodycopynttwo"
        values={{ themeName }}
      />
      <FormattedMessage
        id="onboarding.locationnotallowed.nt"
        values={{
          link: (string: string) => (
            <TextLink
              href="https://industry.nt.gov.au/boards-and-committees/racing-commission/sports-bookmakers-and-betting-exchange-operators"
              target="_blank"
            >
              {string}
            </TextLink>
          ),
        }}
      />
    </ContentBodyText>
  );
}
