import React from 'react';
import { Close } from 'styled-icons/evaicons-solid';
import { IconButton } from '@chakra-ui/react';

interface ICloseButtonProps {
  onClose(e: React.MouseEventHandler<HTMLButtonElement>): void;
}

export function CloseButton({ onClose }: ICloseButtonProps) {
  return (
    <IconButton
      aria-label="close"
      variant="unstyled"
      sx={{
        position: 'relative',
        top: '-4px',
        right: '-12px',
      }}
      icon={<Close width="24px" />}
      onClick={onClose}
    />
  );
}
