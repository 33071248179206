import { CSSObject } from '@chakra-ui/react';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  iconBetCardSvgMysteryBet: {
    sx: {
      '&&': {
        color: 'beta.500',
        mr: 0,
      },
    },
  },
  iconMysteryBetFooter: {
    mx: 1,
    boxSize: 'xs',
  },
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'accent.700',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'theta.50',
    fontWeight: 'semibold',
    fontSize: 'xs',
    w: '-webkit-fill-available',
    h: 'auto',
    py: 1.5,
  },
  buttonBonusBetsProps: {
    textTransform: 'capitalize',
    borderRadius: 'md',
    _disabled: {
      opacity: 0,
      visibility: 'hidden',
      boxShadow:
        '0px 3px 4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
    },
    sx: {
      '&&[data-isbonusbet="true"]': {
        bgColor: 'green.50',
        color: 'green.500',
        boxShadow: 'inset 0px 0px 6px rgba(25, 105, 62, 0.6)',
        _disabled: {
          opacity: 1,
          visibility: 'inherit',
          boxShadow: 'inset 0px 0px 6px rgba(25, 105, 62, 0.6)',
        },
      },
    },
  },
  buttonStakeProps: {
    variant: 'secondary',
    lineHeight: 1,
    borderRadius: 'sm',
    fontSize: 'sm',
  },
};

export const FormsHStack: CSSObject = {
  pb: '0',
  px: '2',
};

export const FormText: CSSObject = {
  color: 'black',
};

export const ThemedInputAddon: CSSObject = {
  _groupHover: {
    bg: 'gray.400',
  },
  _groupFocusWithin: {
    bg: 'gray.500',
  },
  bg: 'gray.300',
  color: 'black',
};

export const InputBetSlip: CSSObject = {
  bg: 'white',
  marginTop: '-2px',
  borderColor: 'gray.300',
  pl: '10',
  _hover: {
    borderColor: 'gray.400',
    '&&': {
      background: 'white',
    },
  },
  _focus: {
    borderColor: 'gray.500',
    '&&': {
      background: 'white',
    },
  },
};

export const ReviewContainer: CSSObject = {
  border: '2px dashed',
  borderColor: 'gray.200',
};

export const ReviewStakeText: CSSObject = {
  color: 'gray.600',
  fontSize: 'xs',
};

export const ReviewStake: CSSObject = {
  color: 'gray.600',
  fontSize: 'md',
  fontWeight: 'bold',
};

export const ReviewEstText: CSSObject = {
  color: 'gray.600',
  fontSize: 'xs',
};

export const ReviewEst: CSSObject = {
  color: 'black',
  fontSize: 'lg',
  fontWeight: 'bold',
};

export const BetCardContainer = (): CSSObject => ({
  bg: 'white',
  borderTopRadius: 'base',
  '&::after': {
    content: `""`,
    position: 'absolute',
    bottom: '-30px',
    left: '0',
    width: '100%',
    height: '31px',
    background:
      'linear-gradient(135deg,white 5px, transparent 0%),linear-gradient(-135deg,white 5px, transparent 0%)',
    backgroundSize: '13px 32px',
  },
});

export const BetOdds: CSSObject = {
  display: 'none',
};

export const InfoBetOdds: CSSObject = {
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontFamily: 'heading',
  flex: '1',
  fontSize: 'sm',
  fontWeight: 'medium',
  color: 'beta.600',
  textTransform: 'capitalize',
};

export const EventSubtitle: CSSObject = {
  color: 'beta.500',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};

export const ExoticsPlaceContainer: CSSObject = {
  pl: '1',
};

export const IconButtonThemed: CSSObject = {
  w: '0',
  minWidth: '6',
  justifyContent: 'right',
  ml: '0',
};

export const ExoticsNumberContainer: CSSObject = {
  color: 'gray.600',
  fontWeight: 'bold',
};

export const BetPlaced: CSSObject = {
  bg: 'white',
  pt: '2',
  flexDirection: 'column',
  color: 'gray.400',
  borderTop: '2px dashed',
  borderColor: 'gray.200',
};

export const BetCardSVG = (header: boolean): CSSObject => ({
  color: 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '2',
});

export const ExoticsFooterContainer: CSSObject = {
  color: 'black',
  pt: '0',
};

export const EventRule: CSSObject = {
  color: 'gray.600',
  alignItems: 'center',
};

export const EventMarketName = (isExtraInfo: boolean): CSSObject => ({
  fontSize: 'xs',
  display: !isExtraInfo && 'none',
  color: 'gray.600',
  fontWeight: 'normal',
  mr: isExtraInfo && '0',
  textTransform: 'capitalize',
});
