import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/buffalobet/images/feature/featBg.jpg';
import featBgMb from '@/assets/buffalobet/images/feature/featBgMb.jpg';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    borderRadius: 'base',
    minW: 12,
    fontSize: '15px',
  },
};

export const BoxCard: CSSObject = {
  p: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', 'md'],
    boxShadow: [
      'unset',
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    ],
    m: 'px',
  },
  boxShadow: [
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    'unset',
  ],
  bg: 'linear-gradient(180deg, #013D90 15%, #011D51 100%)',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'md',
  minH: '250px',
  py: '4',
  px: '3',
  mx: '0',
  mb: 0,
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['0', null, '60px'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: {
    base: `"."`,
    md: `". . ."`,
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'beta.400',
  fontFamily: 'Staatliches',
  fontSize: '3xl',
  textTransform: 'uppercase',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'alpha.100',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: 'lg',
  color: 'white',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'roboto',
  bg: 'gray.300',
  borderRadius: 'sm',
  color: 'gray.600',
  px: '1',

  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '50px',
  bg: 'alpha.500',
  borderRadius: 'base',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
