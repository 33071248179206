import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'outline',
    borderRadius: 'md',
    px: '4',
    h: '7',
    fontSize: 'xs',
    fontWeight: 'bold',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
  },
};
