import React from 'react';
import { TabContainer, TabWrapper } from './styles/ToggleAccountView.styles';
import { Button } from '@/components/Button/Button';
import { buttonAccountTabOptionProps } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';

export type TabOptionProps<T> = {
  display: string;
  value: T;
};

type ToggleProps<T> = {
  selectedTab: TabOptionProps<T>;
  onChange: (tab: TabOptionProps<T>) => void;
  tabs?: TabOptionProps<T>[];
};

export default function ToggleAccountView<T>({
  tabs,
  onChange,
  selectedTab,
}: ToggleProps<T>) {
  return (
    <TabContainer>
      <TabWrapper role="tablist">
        {tabs &&
          tabs.map((option, i) => (
            <Button
              key={`tab-${i}`}
              data-cy={`accountView-${option.display}`}
              onClick={() => onChange(option)}
              role="tab"
              {...(selectedTab.value === option.value && {
                isActive: true,
                'aria-selected': true,
              })}
              {...buttonAccountTabOptionProps}
            >
              {option.display}
            </Button>
          ))}
      </TabWrapper>
    </TabContainer>
  );
}
