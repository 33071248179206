import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textTransform: 'uppercase',
  fontSize: ['2xl', '26px'],
  fontWeight: 'bold',
  fontFamily: 'accent',
  textAlign: 'center',
  color: 'beta.400',
  textShadow: '0px 0px 8px rgba(37, 254, 214, 0.7)',
};
export const TextSecondary: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  w: ['288px', null, '420px'],
  textAlign: 'center',
  fontFamily: 'accent',
  fontWeight: 'semibold',
  p: {
    w: '100%',
    fontWeight: 'normal',
  },
};
export const ImageNoRaces: CSSObject = {
  w: ['300px', null, '490px'],
  borderRadius: 'lg',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.30))',
  mb: '4',
  outline: '1px solid #6DFF2810',
};
