import { CSSObject } from '@chakra-ui/react';

export const ExoticsWrapper: CSSObject = {
  mx: ['2', null, '4'],
};

export const ExoticsContainer: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  px: '2',
  py: '0',
};

export const CheckboxBoxed: CSSObject = {
  justifyContent: 'flex-end',
  mt: '1',
  '.chakra-checkbox__control': {
    mt: '2.5',
    mb: '1',
    borderRadius: 'xs',
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    border: '1px solid white',
    color: 'white',
    _hover: {
      color: 'blackAlpha.600',
    },
    _focus: {
      border: 'none',
      color: 'blackAlpha.600',
    },
    _checked: {
      bgGradient: 'linear(to-b, beta.600, beta.700)',
      border: '1px solid white',
      color: 'white',
    },
    svg: {
      mb: '1px',
    },
  },
  '.chakra-checkbox__label': {
    mt: '0',
  },
};

export const SelectFieldContainer: CSSObject = {
  alignItems: 'center',
  w: 'unset',
};

export const TextBoxed: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  minW: '58px',
};
