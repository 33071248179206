import React from 'react';
import { useIntl } from 'react-intl';
import { Flex, Text } from '@chakra-ui/react';
import { TBetForRace } from '@/api/racing/bets/bets.types';
import { onRaceBetStyles } from '../styles/OnRaceBets.styles';
import { EBetSlipBetSubmissionType } from '@/components/Betslip/Services/Betslip.types';
import { SingleBet } from './SingleBet';
import { SameRaceMultiBet } from './SameRaceMultiBet';
import { ExoticsBet } from './ExoticsBet';
import { BlendedBet } from './Blended';
import { ToteMultiBet } from './ToteMultiBet';

type TGroup = {
  bets: TBetForRace[];
  betType: EBetSlipBetSubmissionType;
};

export const Group = ({ bets, betType }: TGroup) => {
  const intl = useIntl();
  return (
    <Flex {...onRaceBetStyles.groupContainer}>
      <Text {...onRaceBetStyles.groupTitleText}>
        {intl.formatMessage({
          id: `betslip.betslipmodal.types.${betType.toLowerCase()}`,
        })}{' '}
        ({bets.length})
      </Text>
      {bets.map((bet, index) => {
        switch (betType) {
          case EBetSlipBetSubmissionType.Single:
            return <SingleBet bet={bet} isLast={index === bets.length - 1} />;
          case EBetSlipBetSubmissionType.SRMulti:
            return <SameRaceMultiBet bet={bet} />;
          case EBetSlipBetSubmissionType.Exotics:
            return <ExoticsBet bet={bet} />;
          case EBetSlipBetSubmissionType.Blended:
            return <BlendedBet bet={bet} />;
          case EBetSlipBetSubmissionType.ToteMulti:
            return <ToteMultiBet bet={bet} />;
          default:
            return null;
        }
      })}
    </Flex>
  );
};
