import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  color: 'alpha.500',
  mx: '0',
  borderTop: 'none',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  px: '2',
  py: '2',
  borderRadius: 'md',
  gap: '1',
  bg: 'gray.100',
};

export const RacerName: CSSObject = {
  color: 'alpha.900',
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'alpha.900',
};
