import { CSSObject } from '@chakra-ui/react';

export const TabOption = ({ isActive }: any): CSSObject => ({
  fontWeight: '600',
  py: '4',
  px: '3',
  borderBottom: '4px solid',
  borderBottomColor: isActive ? 'gamma.500' : 'transparent',
  color: 'beta.500',

  _hover: {
    bg: 'transparent',
  },

  _active: {
    bg: 'transparent',
  },
});
