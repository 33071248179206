import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.700)',
  color: 'white',
  borderRadius: 'lg',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'white',
};

export const MatchCardContainer: CSSObject = {
  bg: 'white',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow: 'lg',
  border: '1px',
  borderColor: 'transparent',
  w: '100%',
  p: '1.5',

  _hover: {
    bg: 'delta.50',
  },
};

export const MatchName: CSSObject = {
  noOfLines: 'unset',
  fontWeight: 'semibold',
  fontSize: ['sm', null, 'md'],
  color: 'gray.700',
};

export const MatchDate: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  color: 'alpha.600',
};

export const TeamItemOdds: CSSObject = {
  color: 'yellow.900',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const VersusText: CSSObject = {
  display: 'block',
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'yellow.900',
};

export const Versus: CSSObject = {
  display: 'flex',
  position: 'absolute',
  borderRadius: 'full',
  boxSize: '12',
  bg: 'yellow.600',
  border: '3px solid',
  borderColor: 'yellow.400',
  boxShadow: '0px 1px 20px 0px #00000080',
  color: 'yellow.900',
  left: '50%',
  transform: 'translate(-50%, 0)',
  top: '84px',

  alignItems: 'center',
  justifyContent: 'center',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const TeamItem: CSSObject = {
  border: 'none',
  bg: 'gamma.900',
  '&&': { textColor: 'yellow.900', h: '8' },
  _hover: {
    boxShadow: '0 1px 2px 1px black',
    transform: 'translateY(-3%)',
  },
};

export const TeamItemName: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  mb: '1.5',
  fontWeight: 'semibold',
};

export const Seperator: CSSObject = {
  color: 'gray.400',
};
export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon"
`,
};

export const CompetitionName: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  color: 'alpha.600',
};

export const ArrowIcon: CSSObject = {
  boxSize: '7',
  color: 'alpha.600',
  border: '2px',
  borderColor: 'alpha.600',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
