import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';
import bg from '@/assets/slambet/images/account/bannerBg.png';
import bgMb from '@/assets/slambet/images/account/bannerBgMb.png';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'primary',
    borderRadius: 'md',
    pb: '1.5',
    fontWeight: 'normal',
  },
};
export const FlexBannerContainer: CSSObject = {
  boxShadow:
    '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  bgImage: [`url(${bgMb})`, null, `url(${bg})`],
  bgSize: 'cover',
  color: 'white',
  gap: '0',
  p: ['3.5', null, '3'],
  minH: '107px',
  pos: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    border: '0px solid transparent',
    borderImage: `url(${slamBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};
export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
};
export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
  color: 'alpha.400',
  textTransform: 'uppercase',
};
export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};
export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};
export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'body',
  textAlign: ['start', null, 'end'],
};
export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'body',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'alpha.400',
});
export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'alpha.400',
});
export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'alpha.400',
});
export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
  borderTop: '1px solid',
  borderTopColor: 'whiteAlpha.300',
  pt: '2',
};
export const TextButton: CSSObject = {
  h: '4',
  fontFamily: 'accent',
  pt: 'px',
  textTransform: 'capitalize',
};
export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'alpha.400',
  color: 'gamma.200',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
};
export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
};
