import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    display: 'flex',
    variant: 'odds',
    h: '22px',
    px: '1.5',
    spanProps: {
      textTransform: 'uppercase',
    },
  },
  buttonBetPropositionClosedProps: {
    variant: 'unstyled',
    bg: 'orange.500',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    borderRadius: 'base',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'xs',
    px: '3',
    py: '2',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  buttonStakeProps: {
    variant: 'odds',
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'extrabold',
    h: '36px',
    maxW: '47px',
    borderRadius: 'base',
  },
  flexWrapperMysteryRollover: {
    pb: '0',
    gap: '1',
  },
  flexWrapperMysteryBetFooterBetReview: {
    bg: 'alpha.800',
    borderRadius: 'md',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'bold',
    textShadow: '0px 0px 8px rgba(255, 210, 123, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5',
    p: '0.5',
    textTransform: 'uppercase',
    sx: {
      _last: {
        bg: 'alpha.700',
        textShadow: 'none',
        justifyContent: 'flex-start',
      },
    },
  },
  spanRaceInfo: {
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  iconMysteryBetFooter: {
    mb: '0',
    mr: '2',
    color: 'alpha.100',
  },
  iconBetCardSvgMysteryBet: {
    mb: '2',
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    lineHeight: 'normal',
    p: '1',
    bg: 'beta.900',
    gap: '1',
    sx: {
      svg: {
        mr: '0',
        alignSelf: 'flex-start',
        mt: '0.5',
        ml: '0.5',
        transform: 'unset',
      },
    },
  },
  flexRaceInfoOverride: {
    lineHeight: 'normal',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  spanBetOdds: {
    color: 'beta.300',
    lineHeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  spanPropositionType: {
    color: 'alpha.100',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
};
export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};
export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'alpha.50',
  },
};
export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'bold',
  fontSize: 'sm',
};
export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'white',
  },
};
export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '8',
};
export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};
export const FlexPlacedContainer: CSSObject = {
  p: '2',
};
export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, gray.200, gray.200)',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 1) inset',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};
export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
};
export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  fontFamily: 'accent',
};
export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};
export const TextPlacedLabel: CSSObject = {
  flex: '1',
};
export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
export const EventRule: CSSObject = {
  color: 'white',
};
export const BetPropositionClosedError: CSSObject = {
  bg: 'rgb(0,112,215,0.7)',
  borderRadius: 'md',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};
export const BetOdds: CSSObject = {
  mb: '2px',
};
export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};
export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '10',
};
