import { CSSObject } from '@chakra-ui/react';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgGradient: 'linear(to-b, delta.400, delta.600)',
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  mt: ['0', null, '12'],
  mx: 'auto',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.lg',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  img: {
    mb: ['4', null, '5'],
  },
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Chewy',
  fontSize: ['4xl', null, '6xl'],
  lineHeight: '1.2',
  mt: '4',
  mb: ['2.5', null, '4'],
  textTransform: 'capitalize',
  fontWeight: 'normal',
  color: 'gamma.400',
};

export const ContentBodyText: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  mb: ['5', '4'],
  w: ['283px', '540px'],
  wordWrap: 'break-word',
  lineHeight: '1.3',
  b: {
    color: 'gamma.400',
  },
  '&[data-ismaintenance="false"]': {
    w: ['303px', '292px'],
  },
};

export const ButtonRefresh: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
  letterSpacing: 'wide',
  pt: '0.5',
  color: 'blackAlpha.600',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  '&:active, &:hover': {
    bgGradient: 'linear(to-b, beta.700, beta.700)',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'gamma.500',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};
