import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { TBlendedMoMBet } from '../types';
import { FormsHStack } from '../../../BetCard/components/Forms/Forms.styles';
import { BetCardSVG } from '../../../Card/styles/BetCard.styles';
import { getIconBySportName } from '@/helpers/utils';
import { InfoExoticsContainer } from '../../../BetCard/components/Info/styles/Info.styles';
import { srmInfoStyles } from '../../../BetCard/components/Info/SRM/styles/SRMInfo.styles';

interface IMoMBlendedCardItemProps {
  bet: TBlendedMoMBet;
  hasHeader?: boolean;
}

export function MoMBlendedCardItem({
  bet,
  hasHeader = false,
}: IMoMBlendedCardItemProps) {
  return (
    <FormsHStack gap={2} mt={2} direction="column">
      <Box>
        {hasHeader && (
          <Flex direction="column" gap="2" w="full">
            <Flex w="full">
              <BetCardSVG header name={getIconBySportName(bet.iconType)} />
              <InfoExoticsContainer gap={2}>
                <Text {...srmInfoStyles.runnerDetails}>{bet.title}</Text>
                <Text {...srmInfoStyles.eventMarketName}>{bet.subTitle}</Text>
                <Text {...srmInfoStyles.eventSubtitle}>{bet.description}</Text>
              </InfoExoticsContainer>
            </Flex>
          </Flex>
        )}
        <Flex direction="column" mt={2}>
          <Box {...srmInfoStyles.infoExoticsContainer}>
            {bet.legs.map((leg) => (
              <Text key={leg.propName} {...srmInfoStyles.eventSubtitle} mb={2}>
                {leg.propName}
              </Text>
            ))}
          </Box>
        </Flex>
      </Box>
    </FormsHStack>
  );
}
