import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'onboarding.login.subtitle':
    'Become a member of <span> SugarCastle </span>. This vintage-inspired betting platform takes you on a journey to an era of style, sophistication, and thrilling <span>sports and race events.</span>',
  'onboarding.locationnotallowed.header': 'wrong turn on the way to the castle',
  'error.heading': 'A Dapper Dilemma',
  'error.description': `<bold>SugarCastle is currently down.</bold>
  Refresh the page, or try again later.`,
  'maintenance.heading': 'sugarcastle is Undergoing Renovations',
  'maintenance.error': `<bold>We are investigating the issue.</bold>
  Refresh the page, or try again later.`,
  'racing.errorMessages.noRacesAvailable': 'Party’s over',
  'racing.errorMessages.noRacesAvailableSubtext': `<b>There are no more races available.</b>
  <p>Please consider selecting a different day to try again.</p>`,
  'betslip.betslipmodal.emptybetslipheading': 'The Party Starts with Your Bet!',
  'betSlip.potentialReturns': 'Est. Payout',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'header.betslipBtn.desc': 'Slip',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing Sugarcastle from outside of Australia.',
  'sports.allsports.azheading': 'A-Z Sports',
  'betslip.betslipbetcard.stakeprefix': '$',
};
