import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

import menuTile from '@/assets/sugarcastle/images/sidenav/sc-menu-mid.png';
import menuTop from '@/assets/sugarcastle/images/sidenav/sc-menu-top.png';
import menuPattern from '@/assets/sugarcastle/images/backgrounds/menuPattern.png';

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
};

export const FlexBetSlipModalContainer: CSSObject = {
  w: ['103%', '320px', null, null, 'auto'],
  mr: [null, null, null, null, '15px'],
  padding: [null, '10px', null, null, '15px'],
  h: ['100%', '99%', null, null, null],
  pos: 'relative',
  borderRadius: 'none',
  bg: ['none', `url(${menuTile})`, null, null, null],
  bgRepeat: 'repeat-y',
  backgroundSize: ['inherit', '272px 100%', null, null],
  pb: ['0px', '9px', null, null, null],
  pl: [null, null, null, null, '10px'],
  zIndex: '5',
  clipPath: [
    null,
    'polygon(0 18px, 13px 18px, 13px 0, calc(100% - 13px) 0, calc(100% - 15px) 19px, 272px 19px, 272px calc(100% - 21px), calc(100% - 16px) calc(100% - 20px), calc(100% - 12px) 100%, 13px 100%, 12px calc(100% - 21px), 0 calc(100% - 22px), 0 24px)',
    null,
    null,
    null,
  ],
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '273px',
    top: '6px',
    height: '25px',
    left: '0px',
    backgroundImage: ['none', `url(${menuTop})`, null, null, null],
    backgroundSize: ['inherit', null, null, '272px', null],
    backgroundRepeat: 'no-repeat',
    zIndex: '10',
  },
  _after: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: [null, '9px', null, null, null],
    left: [null, '-9px', null, null, null],
    width: '281px',
    height: '25px',
    backgroundImage: ['none', `url(${menuTop})`, null, null, null],
    backgroundSize: ['inherit', null, null, '272px'],
    transform: 'rotate(180deg)',
    backgroundRepeat: 'no-repeat',
    zIndex: '10',
  },
};

export const BetSlipContainer: CSSObject = {
  zIndex: '5',
  bg: `linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-500) 100%), url(${menuPattern})`,
  bgBlendMode: 'overlay',
  bgGradient: ``,
  bgRepeat: 'repeat',
  bgSize: 'contain',
  pt: '1',
  w: 'calc(100% + 2px)',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  p: '0',
  pt: '2',
  mt: '0',
  mx: '2',
  mb: isKeypadVisible && 'unset',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  color: 'white',
  borderBottomRadius: ['none', null, null, null, 'xl'],
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'Fraunces',
  fontSize: 'sm',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'beta.400',
  fontSize: 'sm',
});

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'unstyled',
  width: '74px',
  maxW: '100%',
  height: '7',
  borderRadius: 'base',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset',
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  color: 'beta.400',
  px: '4',
  m: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  border: 'none',
  fontSize: '2xs',
  fontWeight: 'black',
  textAlign: 'center',
  textTransform: 'uppercase',
  display: 'flex',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
  },
};

export const BetslipVStack: CSSObject = {
  spacing: '0',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  height: '10',
  w: '100%',
  fontFamily: 'Fraunces',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'tertiary',
  height: '10',
  w: '100%',
  fontFamily: 'Fraunces',
};
