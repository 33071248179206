import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  px: '0',
  pt: '2',
};

export const BoxWrapper: CSSObject = {
  mb: ['0'],
  borderRadius: 'none',
  background: 'none',
  border: 0,
};
