import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: {},
  buttonCashOutAmount: { variant: 'tertiary', fontSize: 'sm' },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'white',
    border: 'none',
    borderRadius: 'md',
    bgGradient:
      'linear-gradient(180deg, rgba(70, 78, 139, 1) 0%, rgba(70, 78, 139, 1) 100%)',
  },
  iconOddsBoost: {
    fill: 'white',
  },
  textOddsBoost: {
    color: 'white',
  },
};
