import { TextProps, CSSObject } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  mt: '1.5',

  sx: { svg: { top: 0 } },
};

export const FormControlGroup: CSSObject = {
  color: 'alpha.900',
  label: {
    color: 'white',
  },
  'div.chakra-input__group > input[data-cy="passwordInput"]': {
    bg: 'gray.50',
    borderLeft: '1px',
    borderColor: 'gray.300',
    color: 'alpha.900',
    _placeholder: {
      color: 'gray.700',
    },
    _hover: {
      bg: 'gray.100',
      borderColor: 'gray.400',
    },
    _focus: {
      bg: 'gray.50',
      borderColor: 'blue.300',
      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },
  },
  'input#email': {
    borderRadius: 'md',
    borderLeft: '1px',
    borderColor: 'gray.300',
    bg: 'gray.50',
    color: 'gray.700',
    _hover: {
      bg: 'gray.50',
    },
  },
  'div.chakra-input__group > input[data-cy="field-x"]': {
    bg: 'blackAlpha.600',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
    borderRadius: 'md',
    border: 'none',
    textShadow: 'none',
    _focus: {
      color: 'whiteAlpha.500',
    },
  },
};
