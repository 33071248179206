import React from 'react';
import {
  Box,
  chakra,
  ChakraComponent,
  Icon,
  IconProps,
  Text,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { ChevronRight } from '@styled-icons/fa-solid';
import { ChevronWithCircleRight } from '@styled-icons/entypo';
import { styleImports } from './AllSports.styles.imports';
import IconSvg, { TIconSvg } from '../../../../components/common/IconSvg';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG } from '@/constants/themeConfig';

const themeName = getThemeName();

export const BoxPageWrapper = chakra(Box, {
  label: 'allSport-BoxPageWrapper',
  baseStyle: () => ({
    px: [null, '3.5'],
    pb: '2',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxPageWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxSectionWrapper = chakra(Box, {
  label: 'sportsList-section-wrapper',
  baseStyle: () => ({
    mb: '3.5',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxSectionWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextTitle = chakra(Text, {
  label: 'sportsList-sport-title',
  baseStyle: () => ({
    mb: '2',
    fontWeight: 'bold',
    color: 'black',
    fontSize: 'md',

    ...(() => {
      try {
        return styleImports[themeName]?.TextTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxSportWrapper = chakra(Box, {
  label: 'sportsList-sport-wrapper',
  baseStyle: () => ({
    borderRadius: 'md',
    border: '1px',
    borderColor: 'gamma.200',
    bg: 'white',
    contain: 'paint',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxSportWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LinkItem = chakra(Link, {
  label: 'sportsList-link',
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    px: '2',
    py: '2.5',
    borderTop: '1px',
    borderTopColor: 'gamma.200',
    transition: 'background ease-in-out .2s',

    _first: { borderTop: 0 },

    _hover: {
      bg: 'gray.200',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.LinkItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'a', LinkProps>;

export const IconSport = chakra(IconSvg, {
  label: 'sportsList-icon-sport',
  baseStyle: () => ({
    boxSize: '5',
    color: 'gray.800',
    mr: '2',

    ...(() => {
      try {
        return styleImports[themeName]?.IconSport;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', TIconSvg>;

const ChakraIcon = (props: any) => (
  <Icon
    as={themeName === EThemesOG.Wellbet ? ChevronWithCircleRight : ChevronRight}
    {...props}
  />
);
export const IconChevron = chakra(ChakraIcon, {
  label: 'sportsList-icon-chevron',
  baseStyle: () => ({
    boxSize: '3',
    color: 'gray.800',
    ml: 'auto',

    ...(() => {
      try {
        return styleImports[themeName]?.IconChevron;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', IconProps>;

export const TextName = chakra(Text, {
  label: 'sportsList-text-name',
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'sm',

    ...(() => {
      try {
        return styleImports[themeName]?.TextName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
