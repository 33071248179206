import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DepositContainer: CSSObject = {
  mb: 2,
};

export const buttonDepositProps: CustomButtonProps = {
  boxShadow:
    '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #7F6514 inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.50) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
  color: 'blackAlpha.700',
  fontFamily: 'CarterOne',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  fontWeight: 'normal',
  variant: 'secondary',
  h: '44px',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: 1,
  w: 12,
  h: 7,
  bg: 'white',
};

export const RowDivider: CSSObject = {
  borderColor: 'gray.300',
  opacity: 1,
};

export const FlexWrapper: CSSObject = {
  color: 'gray.700',
  py: 3,

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  ml: 3,
  fontWeight: 'medium',
  color: 'white',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: 4,
  p: 1,
};

export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  color: 'blackAlpha.700',
  boxShadow:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.31), 0px 1px 0px 0px rgba(0, 0, 0, 0.35) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  },
};

export const BoxInputContainer: CSSObject = {
  mb: 2,

  '.chakra-form-control': {
    mb: 0,
    label: {
      color: 'white',
    },
  },
  '&&': {
    '.chakra-input__left-addon': {
      borderLeftRadius: 'base',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'delta.800',
      color: 'white',
      h: '38px',
    },
    '.chakra-input': {
      border: '1px solid',
      borderLeftRadius: 'none',
      bg: 'gray.50',
      color: 'delta.800',
      fontSize: 'xs',
      fontWeight: 'bold',
      borderRightRadius: 'base',
      borderColor: 'gray.300',
      borderLeft: 'none',
      h: '38px',
      _hover: {
        bg: 'gray.50',
        borderColor: 'gray.300',
      },
      _focus: {
        bg: 'gray.50',
        borderColor: 'blue.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },
      _placeholder: {
        color: 'gray.700',
      },
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: 2,
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const IconSSL: CSSObject = {
  mx: 1,
  w: 4,
  h: 4,
};

export const bannerTextProps: TextProps = {
  fontSize: '11px',
  lineHeight: '13.2px',
  color: 'delta.300',
  mt: '0.5',
  fontWeight: '400',
};

export const FormBox: CSSObject = {
  'div.chakra-collapse div': {
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    border: 'none',
    color: 'delta.700',
    fontWeight: '500',
  },
  'div.chakra-collapse div svg': {
    color: 'delta.700',
  },
};

export const RadioCard: CSSObject = {
  bg: 'gray.200',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'gray.300',
  },
};
