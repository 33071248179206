import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from '@chakra-ui/react';
import { useMaintenance } from '../../hooks/useMaintenance';
import GlobalStyles from './conditionals/GlobalStyles/template';
import template from './templates/template';
import { createTemplate } from '@/helpers/createTemplate';
import ThemeToggle from '../../components/ThemeToggle/ThemeToggle';
import PromoModalContainer from '../../components/PromoModal/PromoModal';

const sensitiveViews = 'responsible-gambling';
const homeURL = '/';

export default function PrimaryLayout() {
  const location = useLocation();
  const isSensitive = location.pathname.includes(sensitiveViews);
  const { downForMaintenance } = useMaintenance();

  /**
   * FIXME:
   * This actually doesn't scale very well. As this breakpoint can
   * be different for different themes.
   * I think we need to move the template logic into a hook that
   * can load different templates with the correct viewport's setup.
   */
  const [largerThan992] = useMediaQuery('(min-width: 992px)');

  const isHome = location.pathname === homeURL;

  /**
   * This really should be its own template. Or remove
   * the wrapper all together for the maintenance view.
   */
  if (downForMaintenance) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <body {...(isSensitive && { 'data-sensitive': 'true' })} />
      </Helmet>
      <GlobalStyles />
      <ThemeToggle />
      <PromoModalContainer />

      {createTemplate(template(largerThan992, isHome))}
    </>
  );
}
