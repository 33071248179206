import React from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { Stop } from '@styled-icons/octicons/Stop';
import {
  betCardErrorFlexProps,
  betCardErrorFlexTextProps,
} from '../../../Card/styles/BetCard.styles';

type TBetCardError = {
  errorMsg: string;
};

export function BetCardError({ errorMsg }: TBetCardError) {
  return (
    <Flex
      px="3"
      py="2"
      w="full"
      backgroundColor="red.400"
      {...betCardErrorFlexProps}
    >
      <Icon w="4" h="4" mr="2" as={Stop} />

      <Text textColor="white" {...betCardErrorFlexTextProps}>
        {errorMsg}
      </Text>
    </Flex>
  );
}
