import React from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from './services/LocationRoute.hooks';

/**
 * Location routes only allow users to access the route if they're
 * from a whitelisted country, if they are not, they will be redirected
 * to the location-not-allowed route.
 */
export default function LocationRoute() {
  useLocation();
  return <Outlet />;
}
