import { CashOutModalSchema } from './CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bg: 'zeta.400',
  },
  stackWrapper: {
    bg: 'zeta.600',
  },
  heading: {
    color: 'alpha.500',
  },
  moneyValues: {
    color: 'alpha.700',
  },
  cashedOutQuestion: {
    color: 'alpha.700',
  },
  declineButton: {},
  acceptButton: { variant: 'solid' },
  doneButton: { variant: 'solid' },
};
