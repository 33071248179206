import { SpecialsStylesSchema } from './Specials.styles';

export const specialsStyles: SpecialsStylesSchema = {
  label: {
    color: 'alpha.700',
  },
  boxWrapper: {
    borderBottom: '1px',
    borderColor: 'blackAlpha.400',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    px: '0',
    mx: '4',
  },
};
