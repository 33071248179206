import React from 'react';
import { getStrings } from '@/helpers/utils';
import Title from '../../components/Title';

export default function PageTitle() {
  const [
    {
      Onboarding: { Step1 },
    },
  ] = getStrings();

  return <Title title={Step1.Welcome} subTitle="" />;
}
