import { ChakraComponent, Image, ImageProps, chakra } from '@chakra-ui/react';

export const BannerImage: ChakraComponent<'img', ImageProps> = chakra(Image, {
  label: 'carousel-BannerImage',
  baseStyle: () => ({
    h: 'auto',
  }),
});

export const BannerLayerImage: ChakraComponent<'img', ImageProps> = chakra(
  Image,
  {
    label: 'carousel-BannerLayerImage',
    baseStyle: () => ({
      h: 'auto',
      w: 'full',
      left: '0',
      zIndex: 'docked',
      pos: 'absolute',
    }),
  }
);
