import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { keys } from '../../api.keys';
import { queryPromotions } from './deposit';
import {
  TQueryPromotionsParams,
  TQueryPromotionsResponse,
} from './deposit.types';

export const useQueryPromotions = (
  params: TQueryPromotionsParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<TQueryPromotionsResponse>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery(
    [keys.promotions, params],
    async () => {
      if (Array.isArray(params.promo_type)) {
        return Array.from(
          (
            await Promise.all(
              params.promo_type.map((promo_type) =>
                queryPromotions({ promo_type })
              )
            )
          ).values()
        );
      }
      return queryPromotions(params);
    },
    options
  );
