import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flextEventSubTitleProps: FlexProps = {
  alignItems: 'center',
};

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px solid',
  borderColor: 'rgba(0, 0, 0, 0.15)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  py: '2',
  w: 'fill-available',
  px: '1',
  mx: '1',
  borderRadius: 'base',
  borderBottomRadius: 'none',
  color: 'beta.500',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});

export const LinkMarket: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'white',
};
export const CountdownTimerBox: CSSObject = {
  '.chakra-badge': {
    borderRadius: 'sm',
    color: 'black',
    bg: 'beta.500',
  },
  '.chakra-badge.state-inprogress': {
    color: 'alpha.800',
  },
  '.chakra-badge.state-ended': {
    color: 'white',
  },
  '.countdown-flex': {
    bg: 'unset',
  },
};
