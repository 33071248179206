import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: `${selected ? 'alpha.500' : 'blackAlpha.400'}`,
  border: 'none',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  bg: 'beta.500',
  borderTopLeftRadius: `${index === 0 ? 'lg' : '0'}`,
  borderTopRightRadius: `${index === numberOfSlides - 1 ? 'lg' : '0'}`,
  pl: `${index === 0 ? '1' : '0'}`,
  pr: `${index === numberOfSlides - 1 ? '1' : '0'}`,
});

export const CarouselWrapper: CSSObject = {
  '& .control-dots ': {
    bottom: '0',
    marginY: 'px',
  },
  '& .carousel .slider-wrapper ': {
    w: ['99%', null, '815px'],
  },
};
