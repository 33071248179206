import React from 'react';
import { ChevronRight } from '@styled-icons/evil';
import { Icon, Text } from '@chakra-ui/react';
import { BoxInner, LinkWrapper } from './Settings.styles';

import { getStrings } from '@/helpers/utils';

import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { usePunterData } from '@/store/AuthStore';

const Settings: React.FC = () => {
  const [
    {
      Account: { Settings: strings },
    },
  ] = getStrings();
  const punterProfile = usePunterData();

  const routes: { path: string; title: string }[] = [
    {
      path: '/account/settings/personal-details',
      title: strings.personalDetails,
    },
    ...(!punterProfile?.identity_verified
      ? [
          {
            path: '/account/settings/verify-identity',
            title: strings.accountVerification,
          },
        ]
      : []),
    {
      path: '/account/settings/responsible-gambling',
      title: strings.responsible,
    },
    {
      path: '/account/settings/promotions-notifications',
      title: strings.promotionsNotifications,
    },

    // Hide for p0 - error on updateing email on BE
    // { path: '/account/settings/update-email', title: strings.updateEmail },
  ];

  return (
    <AccountWrapper
      pageTitle={strings.pageTitle}
      pageHeader={strings.pageTitle}
    >
      <BoxInner>
        {routes.map((route) => (
          <LinkWrapper data-cy={route.title} key={route.path} to={route.path}>
            <Text>{route.title}</Text>

            <Icon as={ChevronRight} boxSize="6" />
          </LinkWrapper>
        ))}
      </BoxInner>
    </AccountWrapper>
  );
};

export default Settings;
