import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  px: 2,
  py: 1,
  mx: 3.5,
  mb: 2,
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  _last: {
    mb: 3.5,
  },
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'gamma.500',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'gamma.200',
  fontSize: 'sm',
  ...(isVS && { color: 'gamma.600', mx: '1' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'gamma.200',
  fontSize: '11px',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'alpha.800',
  boxSize: '32px',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  bg: 'linear-gradient(180deg, var(--bc-colors-gamma-500), var(--bc-colors-gamma-600))',
  borderRadius: 'md',
  p: '1.5',
};

export const IconChevron: CSSObject = {
  color: 'gamma.500',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'gamma.200',
    fontSize: '2xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.200',
    boxSize: '5',
  },
};
