import { CSSObject } from '@chakra-ui/react';

export const TextLabel: CSSObject = {
  color: undefined,
};

export const TextInfo: CSSObject = {
  borderRadius: 'lg',
  borderColor: 'gamma.700',
  color: 'gray.400',
  bg: 'gamma.100',
};
