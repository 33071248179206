/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import {
  WithdrawalCardContent,
  WithdrawalCardDetail,
  WithdrawalFlexContainer,
  WithdrawalStatus,
  WithdrawalWrapper,
  ButtonWithdrawalCardCancel,
} from '../styles/RequestedWithdrawalCard.styles';
import { centsToDollars } from '@/helpers/utils';
import { EWithdrawalStatus, TWithdrawal } from '../../../../../lib/DBModels';

const template = (
  withdrawalRequest: TWithdrawal,
  handleCancelWithdrawal: (id: string | undefined) => void
): TTemplate => ({
  wrapper: <WithdrawalWrapper flexDir="column" />,
  content: (
    <WithdrawalCardContent>
      <WithdrawalCardDetail>
        <Text>
          {dayjs(withdrawalRequest?.date_requested).format('DD/MM/YYYY')}
        </Text>
        <Text>
          {dayjs(withdrawalRequest?.date_requested).format('hh:mm A')}
        </Text>
      </WithdrawalCardDetail>
      <WithdrawalCardDetail>
        <Text>
          <FormattedMessage id="account.withdraw.requests.status.withdraw" />
        </Text>
        <WithdrawalStatus
          status={withdrawalRequest?.withdrawal_status ?? undefined}
        >
          {withdrawalRequest?.withdrawal_status ?? ''}
        </WithdrawalStatus>
      </WithdrawalCardDetail>
      <WithdrawalCardDetail>
        <Text>{withdrawalRequest?.bank_nickname}</Text>
        <Text>{`-${centsToDollars(withdrawalRequest?.amount_requested)}`}</Text>
      </WithdrawalCardDetail>
    </WithdrawalCardContent>
  ),
  cancelButton: (
    <WithdrawalFlexContainer>
      {withdrawalRequest?.withdrawal_status === EWithdrawalStatus.Pending &&
        !withdrawalRequest.withdrawal_cancel_disabled && (
          <ButtonWithdrawalCardCancel
            onClick={() =>
              handleCancelWithdrawal(withdrawalRequest?.withdrawal_id)
            }
          >
            <FormattedMessage id="account.withdraw.requests.cancelbutton" />
          </ButtonWithdrawalCardCancel>
        )}
    </WithdrawalFlexContainer>
  ),
});

export default template;
