import React from 'react';
import { BoxHeader, BoxHeaderText } from './styles/Header.styles';

type THeader = {
  title: string;
};
export default function Header({ title }: THeader) {
  return (
    <BoxHeader>
      <BoxHeaderText>{title}</BoxHeaderText>
    </BoxHeader>
  );
}
