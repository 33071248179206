import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bg: 'beta.300',
  border: 'none',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
  borderRadius: 'md',
  flex: '1',
};
export const TypeItem: CSSObject = {
  color: 'alpha.400',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: 'base',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'beta.700',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.2) inset',
  '&:last-child': {
    mb: '2',
  },
};
export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};
export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  _last: {
    color: 'white',
    fontWeight: 'black',
  },
};
