import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: 'md',
    bg: 'rgba(70, 78, 139, 0.5)',
    px: '3',
    mb: '2',
    h: '8',
    display: 'flex',
    alignItems: 'center',
    sx: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
      },
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    gap: '1.5',
  },
  breadcrumbLinkItem: {
    textDecoration: 'none',
    color: 'white',
    _hover: {
      textDecoration: 'underline',
    },
    sx: {
      '&:not([href])': {
        textDecoration: 'none',
        color: 'white',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
