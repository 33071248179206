import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    bgGradient: 'linear(to-b, alpha.300, alpha.700)',
    boxShadow: '0 2px 5px rgba(0, 0, 0, .15)',
    fontSize: '2xs',
    h: 'auto',
    letterSpacing: 'wider',
    p: '2',
    textShadow: '0 2px 3px rgba(0, 0, 0, .3)',

    _hover: {
      bg: 'alpha.900',
    },

    _disabled: {
      bg: 'blackAlpha.400',
      color: 'delta.800',
      opacity: '1',
      px: '1.5',
      py: '1',
      textShadow: 'none',

      _hover: {
        boxShadow: 'none',
      },
    },
  },
};

export const FlexInner: CSSObject = {
  borderBottomColor: 'whiteAlpha.300',
  minH: '12',
  py: '2',
};

export const TextMonth: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
