import {
  BoxProps,
  CenterProps,
  DividerProps,
  LinkProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './MatchesV2.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export type MatchesV2Schema = Partial<{
  pageHeaderText: TextProps;
  mainBoxWrapper: BoxProps;
  navOverride: StackProps;
  navLinkOverride: LinkProps;
  divider: DividerProps;
  hasNextPageWellOverride: CenterProps;
  nextPageButtonOverride: CustomButtonProps;
}>;

export const matchesV2Styles: MatchesV2Schema = {
  nextPageButtonOverride: {
    'data-testid': 'matchesV2-nextPageButtonOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesV2Styles?.nextPageButtonOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  hasNextPageWellOverride: {
    'data-testid': 'matchesV2-hasNextPageWellOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesV2Styles
          ?.hasNextPageWellOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  divider: {
    'data-testid': 'matchesV2-divider',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesV2Styles?.divider;
      } catch (err) {
        return {};
      }
    })(),
  },
  navLinkOverride: {
    'data-testid': 'matchesV2-navLinkOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesV2Styles?.navLinkOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  navOverride: {
    'data-testid': 'matchesV2-navOverride',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesV2Styles?.navOverride;
      } catch (err) {
        return {};
      }
    })(),
  },
  mainBoxWrapper: {
    'data-testid': 'matchesV2-mainBoxWrapper',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesV2Styles?.mainBoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  pageHeaderText: {
    'data-testid': 'matchesV2-pageHeaderText',

    ...(() => {
      try {
        return styleImports[themeName]?.matchesV2Styles?.pageHeaderText;
      } catch (err) {
        return {};
      }
    })(),
  },
};
