import menuPattern from '../../../assets/vikingbet/images/backgrounds/menu-pattern.png';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';
import mobileBackgound from '@/assets/vikingbet/images/backgrounds/texture-wood-1x.png';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'delta.500',
      background: {
        pt: ['0', null, null, '6'],
        pb: ['0', null, null, '5'],
        position: 'relative',
        bgImage: [`url(${mobileBackgound})`, null, null, 'none'],
        bgSize: 'cover',
        bgRepeat: 'no-repeat',
        borderRadius: [null, null, null, 'lg'],
        alignSelf: [null, null, null, 'center'],
        overflow: 'hidden',
        '&&': {
          bg: 'linear-gradient(180deg, var(--bc-colors-zeta-400) 0%, var(--bc-colors-zeta-500) 100%)',
        },
        w: '213px',
        boxShadow:
          '0px 6px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px #AD8B4C, 0px 2px 1px 0px rgba(255, 255, 255, 0.30) inset',
        m: ['0 0 0 0', null, null, '20px 15px 20px 20px'],
        _before: {
          content: ['none', null, null, '""'],
          display: 'block',
          position: 'absolute',
          top: '-0.5',
          left: '0px',
          width: '221px',
          height: '26px',
          zIndex: '3',
          backgroundImage: `url(${menuPattern})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
        _after: {
          content: ['none', null, null, '""'],
          display: 'block',
          position: 'absolute',
          bottom: '1',
          right: '0px',
          width: '221px',
          height: '26px',
          zIndex: '3',
          backgroundImage: `url(${menuPattern})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
      },
      drawer: {
        ...base.drawer,
      },
      icon: {
        ...base.icon,
        boxSize: '20px',
        color: '#7A3B1E', // @TODO: colour not in theme
        fill: '#7A3B1E',
        mr: '2',
        mb: '1',
      },
      headerSX: {
        cursor: 'default',
        color: ['white', null, null, '#7A3B1E'],
        fontWeight: 'semibold',
        fontFamily: 'heading',
        height: '45px',
        borderColor: 'transparent',
        '&:hover': {
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
        // Last item of sidenav main
        borderBottomColor: 'transparent',
      },
      link: {
        ...base.link,
        fontFamily: 'SkranjiRegular',
        textTransform: 'uppercase',
        fontSize: 'sm',
        fontWeight: 'normal',
        height: '43px',
        color: 'alpha.600',
        transition: 'all, 0.2s linear',

        borderBottom: '1px dashed',
        borderBottomColor: ['transparent', null, null, 'blackAlpha.300'],
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
        p: '0',
        ml: '2',
        w: '94%',

        _hover: {
          color: [null, null, null, 'alpha.300'],
          '> .chakra-icon, .icon, .svgIcon': {
            color: [null, null, null, 'alpha.300'],
            fill: [null, null, null, 'alpha.300'],
          },
        },

        '~ .nested': {
          pl: '10',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          color: ['white', null, null, 'alpha.600'],
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          color: 'alpha.600',
          pl: ['0', null, null, '2'],
          borderBottom: '1px dotted',
          borderBottomColor: ['transparent', null, null, 'blackAlpha.300'],
        },

        '> a, .dropDownIcon, a': {
          color: 'alpha.500',
        },

        '&:hover, &:active': {
          color: 'alpha.700',

          '.dropDownInner': {
            ...base.dropDownInner,
            px: '4',
            color: 'alpha.300',
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',

        _hover: {
          '.dropDownInner': {
            bg: 'blackAlpha.200',
          },
        },

        '.dropDownInner': {
          color: 'gamma.400',

          '.dropDownIcon': {
            color: 'gamma.400',
          },

          svg: {
            color: 'gamma.400',
            fill: 'gamma.400',
          },
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
