import { CSSObject } from '@chakra-ui/react';
import activeBg from '../../../../../../assets/juicybet/images/backgrounds/btn-active-bg.png';

import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({ propLength }: TButtonProp): CSSObject => ({
  '&&': {
    height: 'auto',
    bg: 'unset',
    boxShadow: 'unset',
    border: 'none',
    ...((propLength ?? 0) === 2 && {
      py: 3,
      px: 0,
      _first: { pl: 1 },
      _last: { pr: 1 },
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px dotted',
      borderTopColor: 'blackAlpha.500',
      p: '2',
      _first: { borderTop: 0 },
      _last: { boxShadow: 'none' },

      _hover: {
        span: {
          _first: {
            color: 'alpha.600',
          },
        },
        bg: 'blackAlpha.300',
      },
    }),
    _last: {
      borderBottomRadius: 'base',
    },
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',
    color: 'alpha.800',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    bgRepeat: 'repeat',
    '&.return-amount': {
      bgImage: isSelected
        ? `url(${activeBg})`
        : 'linear-gradient(180deg, var(--bc-colors-alpha-400) 0%, var(--bc-colors-alpha-500) 100%)',
      boxShadow:
        '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
      p: 2,
      w: 'full',
      borderRadius: 'md',
      color: isSelected ? 'beta.700' : '#255505',
      borderWidth: '2px',
      borderStyle: ' solid',
      borderColor: 'white',
      fontWeight: 'extrabold',
      textShadow: isSelected
        ? '0px 1px 0px rgba(255, 255, 255, 0.37)'
        : '0px 1px 0px rgba(255, 255, 255, 0.45)',
      transition: 'all, .3s ease-in-out',
      backgroundSize: '60%',
      _hover: {
        bg: 'alpha.700',
        color: 'rgba(0, 0, 0, 0.50)',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
      },

      _disabled: {
        border: 'none',
        borderRadius: 'base',
        '&&': {
          opacity: '1',
          boxShadow: 'none',
          color: 'alpha.400',
          bg: 'blackAlpha.200',
          fontSize: 'xs',
        },
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },
  },
});
