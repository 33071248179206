import {
  Box,
  BoxProps,
  chakra,
  Flex,
  FlexProps,
  Icon,
  Text,
} from '@chakra-ui/react';
import { styleImports } from './MultiCard.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg from '../../../../common/IconSvg';

type BetOddsProps = {
  soddsChanged: boolean;
} & BoxProps &
  any;

const themeName = getThemeName();

export const flexEventContainerProps: FlexProps = {
  'data-testid': 'multiCard-flexEventContainerProps',
  flex: 1,
  width: '75%',
  maxW: '75%',

  ...(() => {
    try {
      return styleImports[themeName]?.flexEventContainerProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const flexEventProps: FlexProps = {
  'data-testid': 'multiCard-flexEventProps',
  overflow: 'hidden',
  overflowText: 'ellipsis',
  whiteSpace: 'nowrap',

  ...(() => {
    try {
      return styleImports[themeName]?.flexEventProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const MultiLegContainer = chakra(Flex, {
  label: 'multiCard-MultiLegContainer',
  baseStyle: () => ({
    overflow: 'hidden',
    gap: '1',
    flexDir: 'column',
  }),
});

export const HeaderContainer = chakra(Flex, {
  label: 'multiCard-HeaderContainer',
  baseStyle: () => ({
    w: 'full',
    alignItems: 'center',
    gap: '1.5',
    borderRadius: 'base',
    px: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.HeaderContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const EventTitle = chakra(Text, {
  label: 'multiCard-EventTitle',
  baseStyle: () => ({
    fontSize: 'sm',
    fontFamily: 'heading',
    fontWeight: 'bold',
    flex: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.EventTitle;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetOdds = chakra<BetOddsProps>(Text, {
  label: 'multiCard-BetOdds',
  shouldForwardProp: (prop) => !['oddsChanged'].includes(prop),
  baseStyle: () => ({
    fontSize: 'md',
    fontWeight: 'bold',
  }),
});

export const MultiCardInputContainer = chakra(Flex, {
  label: 'multiCard-MultiCardInputContainer',
  baseStyle: () => ({
    w: 'full',
    marginTop: '0',
    alignItems: 'start',
    flexDir: 'column',
    gap: '2',
    px: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.MultiCardInputContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetCardSVG = chakra(IconSvg, {
  label: 'multiCard-BetCardSVG',
  baseStyle: () => ({
    width: '4',
    height: '4',
    mr: '1',
    color: 'black',
  }),
});

export const WrapContainer = chakra(Flex, {
  label: 'multiCard-WrapContainer',
  baseStyle: () => ({
    w: 'full',
  }),
});

export const InfoContainer = chakra(Flex, {
  label: 'multiCard-InfoContainer',
  baseStyle: () => ({
    w: 'full',
    flexFlow: 'column',
  }),
});

export const MultiCardWrapper = chakra(Flex, {
  label: 'multiCard-MultiCardWrapper',
  baseStyle: () => ({
    w: 'full',
    flexDir: 'column',
    py: '3',
    gap: '2',
    bg: 'white',
    color: 'beta.500',
    mb: '3',
    position: 'relative',
    mt: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.MultiCardWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const UnableToCreateMultiWrapper = chakra(Flex, {
  label: 'multiCard-UnableToCreateMultiWrapper',
  baseStyle: () => ({
    w: 'full',
    p: '1.5',
    gap: '2',
    borderRadius: 'md',
    bg: 'white',
    color: 'gray.900',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: 'xs',
    ...(() => {
      try {
        return styleImports[themeName]?.UnableToCreateMultiWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const MultiCardsContainer = chakra(Flex, {
  label: 'multiCard-MultiCardsContainer',
  baseStyle: () => ({
    overflow: 'hidden',
    flexDir: 'column',
    py: '1.5',
    px: '2',
    gap: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.MultiCardsContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const EmptyMultiIcon = chakra(IconSvg, {
  label: 'multiCard-EmptyMultiIcon',
  baseStyle: () => ({
    color: 'beta.500',
    ...(() => {
      try {
        return styleImports[themeName]?.EmptyMultiIcon;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const MultiIconSVG = chakra(Icon, {
  label: 'multiCard-MultiIconSVG',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.MultiIconSVG;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexEventDetails = chakra(Flex, {
  label: 'multiCard-FlexEventDetails',

  baseStyle: () => ({
    flexDir: 'row',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexEventDetails;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const InvalidityBox = chakra(Box, {
  label: 'multiCard-InvalidityBox',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.InvalidityBox;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
