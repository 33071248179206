import React, { useCallback } from 'react';
import {
  AspectRatio,
  Box,
  Modal as BCModal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  EDepositStatus,
  TCheckDepositResponse,
  TFZCustomer,
  TFZTokenizationSuccess,
} from '@/api/deposit/deposit.types';
import { ModalOverlay } from './styles/Modal/Modal.styles';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { MobileAppFullScreenModal } from './Modal.styles';
import { FatZebra } from '../lib/fatzebra';
import { PromiseResolver } from '@/lib/promiseResolver';

export type TModalFZData = {
  fz: FatZebra;
  deposit: TCheckDepositResponse;
  amount: number;
  customer: TFZCustomer;
  tokenizeResolver: PromiseResolver<TFZTokenizationSuccess>;
};

type TModalProps = {
  data: TModalFZData;
  setIsLightBoxOpen: (value: boolean) => void;
};

export default function Modal({ data, setIsLightBoxOpen }: TModalProps) {
  const callback = useCallback(() => {
    try {
      data.fz
        .renderPaymentsPage(
          'fatZebraPayNow',
          data.customer,
          data.amount,
          data.deposit.currency ?? 'AUD',
          data.deposit.verification,
          data.deposit.deposit_id
        )
        .then((x) => data.tokenizeResolver.resolve(x))
        .catch((e) =>
          data.tokenizeResolver.reject({
            status: EDepositStatus.Declined,
            reason: 'tokenization failed',
            detail: e.detail,
          })
        )
        .finally(() => {
          setIsLightBoxOpen(false);
        });
    } catch (e) {
      data.tokenizeResolver.reject({
        status: EDepositStatus.Abandoned,
        reason: String(e),
      });
      setIsLightBoxOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (IS_MOBILE_APP) {
    return (
      <>
        <MobileAppFullScreenModal id="fatZebraPayNow" ref={callback} />
      </>
    );
  }

  return (
    <BCModal
      isOpen
      onClose={() => {
        data.tokenizeResolver.reject({
          status: EDepositStatus.Abandoned,
          reason: 'User cancelled',
        });
        setIsLightBoxOpen(false);
      }}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody sx={{ pt: '10' }}>
          <AspectRatio ratio={[1 / 1.3, 1.2 / 1]} pos="relative">
            <Box
              id="fatZebraPayNow"
              ref={callback}
              sx={{
                pos: 'absolute',
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
                '> iframe': {
                  bottom: 0,
                  top: 0,
                  right: 0,
                  left: 0,
                  margin: 'auto auto',
                  pos: 'absolute',
                  width: '100%',
                  height: '100%',
                },
              }}
            />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </BCModal>
  );
}
