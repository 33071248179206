import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    mt: '1',
    mb: '3',
  },

  TextScratched: {
    color: 'white',
  },

  GridCardContent: {
    p: '0',
  },

  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    boxShadow:
      '0px 2px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px rgba(255, 255, 255, 0.10) inset',
    bg: 'rgba(26, 165, 255, 0.15)',
  },

  LinkCardHeader: {
    bg: 'rgba(26, 165, 255, 0.15)',
    color: 'beta.500',
  },

  TextCardHeading: {
    fontWeight: 'extrabold',
    fontFamily: 'Spectral',
    textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    color: 'beta.500',
    fontSize: 'md',
    lineHeight: 1,
    pl: '1',
  },

  TextRaceNumber: {
    bg: 'alpha.500',
    borderRadius: 'sm',
    color: 'white',
  },

  BoxGridHeaderItem: {
    bg: 'blackAlpha.300',
    color: 'beta.500',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Roboto',
    pt: '1.5',
  },

  FlexGridRunnerItem: {
    bg: 'rgba(26, 165, 255, 0.15)',
    borderBottom: '1px solid',
    borderBottomColor: 'blackAlpha.300',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  },

  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  },

  TextRunnerNumber: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'semibold',
    textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  },

  LinkRaceDetails: {
    display: 'flex',
    color: 'beta.500',
    bgGradient: 'linear(to-b, alpha.600, alpha.700)',
    textTransform: 'uppercase',
    fontFamily: 'Spectral',
    fontSize: 'sm',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.30)',
    fontWeight: 'extrabold',
    letterSpacing: 'wide',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomRadius: 'md',
    boxShadow: '0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
    h: '10',
  },

  buttonRaceCardOddsProps: {
    w: '100%',
    fontSize: '15px',
    variant: 'solid',
    h: '8',
    lineHeight: 'normal',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};
