import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const TextRaceConditions: CSSObject = {
  fontFamily: 'body',
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    pos: 'relative',
    mx: '1.5',
    bg: 'beta.500',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: ['1.5', null],
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mx: ['0', '0'],
};

export const RaceHeaderWrapper: CSSObject = {
  '& .state-inprogress': {
    color: 'black',
  },
  ml: ['0', '0'],
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: '12px',
  color: 'white',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  bg: 'white',
  mx: ['2', '4'],
  borderRadius: 'md',
  borderColor: 'beta.400',
  borderWidth: '2px',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'alpha.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'tab',
    boxSize: '30px',
    minWidth: '7',
    minHeight: '7',
    bgGradient: 'linear(to-b, alpha.200, alpha.400)',
    boxShadow:
      '0px 0px 0px 2px #152E36 inset, 0px 0px 0px 3px #00CAF6 inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',

    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        mt: '0',
        bgGradient: 'linear(to-b, alpha.500, alpha.600)',
        boxShadow:
          '0px 1px 2px 0px rgba(0, 0, 0, 0.50) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
        border: '2px',
        borderColor: 'alpha.800',
        color: 'blackAlpha.600',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.10)',
      },
    },
  },
};

export const TextTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
};

export const ResultedStatusLabel: CSSObject = {
  bg: 'alpha.200',
  color: 'alpha.700',
  borderRadius: 'base',
};

export const RaceStatusText: CSSObject = {
  color: 'beta.300',
  borderRadius: 'base',
  bg: 'beta.700',
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  mb: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  width: '16',
  px: '1',
  svg: {
    color: 'black',
  },
};

export const RaceResultListWrapper: CSSObject = {
  gap: '2',
  mx: ['2', '4'],
  my: '2',
  flexDirection: 'column',
};

export const CountdownTimerContainer: CSSObject = {
  borderRadius: 'base',

  '.chakra-badge.state-prior': {
    px: '2',
    py: '1',
    bg: 'blackAlpha.400',
    color: 'white',
  },
};

export const ButtonGroupWrapper: CSSObject = {
  ml: ['0', '0'],
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      border: '2px solid',
      borderColor: '#F28B33',
      bgGradient: 'linear(to-b, #F28B33, #D56C13)',
      boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.40),0px 0px 0px 2px #4E2A1D inset,0px 0px 0px 3px #F6BA85 inset',
      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, #F28B33, #D56C13)',
        _hover: {
          bg: 'beta.600',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px #4E2A1D inset',
          color: 'beta.900',
          textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
        },
      },
      '&.active': {
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'beta.400',
        color: 'beta.400',
        bg: 'beta.700',
        _hover: {
          bg: 'beta.600',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px #4E2A1D inset',
          color: 'beta.900',
          textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
        },
      },
      '&:hover': {
        bg: 'blackAlpha.500',
        boxShadow: 'none',
      },
    },
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'alpha.300',
    },
  },
  svg: {
    color: 'white',
  },
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'alpha.800',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};