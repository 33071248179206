import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  display: ['none', null, 'flex'],
  justifyContent: 'center',
  mt: [null, null, '96'],
};
export const TextItem: CSSObject = {
  display: 'none',
};
