import { DeepPartial } from '../../lib/types';
import api from '../api';

export const promotionsPreferencesEndPoint = 'punter/account/preference';

export type TPromotionPreferencesParams = DeepPartial<{
  preference_name: 'PunterLuckyDipPreference';
  preference_value: string;
  enabled?: boolean;
}>;

export type TPromotionPreferencesParamsArray = TPromotionPreferencesParams[];

// post
export const mutatePreferences = (body?: TPromotionPreferencesParams) =>
  api
    .post<TPromotionPreferencesParams>(promotionsPreferencesEndPoint, body)
    .then((res) => res.data);

// get
export const queryPromotionPreferences = (
  params?: Omit<TPromotionPreferencesParamsArray, 'preference_name'>
) =>
  api
    .get<TPromotionPreferencesParamsArray>(promotionsPreferencesEndPoint, {
      params: {
        ...params,
        preference_name: 'PunterLuckyDipPreference',
      },
    })
    .then((res) => res.data);
