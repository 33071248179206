import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  h: ['75px', null, '95px'],
  bgGradient: 'linear(180deg, rgba(249, 212, 81, 0.00) 0%, gamma.400 23.96%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const TextItem = {
  color: 'beta.800',
  textAlign: 'center',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
};

export const LinkItem = {
  color: 'beta.500',
  fontWeight: 'bold',
};
