export const colors = {
  alpha: {
    100: '#ffeea0',
    200: '#ffe363',
    300: '#ffda2f',
    400: '#ffca24',
    500: '#d79200',
    600: '#a77403',
    700: '#533905',
    800: '#402c03',
    900: '#2d1e02',
  },
  beta: {
    100: '#c5d2ff',
    200: '#8fa2e4',
    300: '#6174b6',
    400: '#445590',
    500: '#394571',
    600: '#313d68',
    700: '#263159',
    800: '#192140',
    900: '#0f152e',
  },
};
