import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Link } from '@chakra-ui/react';
import { Link as LinkDOM } from 'react-router-dom';
import { ChevronLeft } from '@styled-icons/fa-solid';
import { Title as TitleExternal } from '@/views/onboarding/styles/onboarding.styles';
import { linkLoginProps } from '../../styles/ForgotPassword.styles';

export default function Title() {
  return (
    <Box>
      <Link as={LinkDOM} to="/login" {...linkLoginProps}>
        <ChevronLeft />
        <FormattedMessage id="onboarding.forgotpassword.loginCta" />
      </Link>

      <TitleExternal>
        <FormattedMessage id="onboarding.forgotpassword.title" />
      </TitleExternal>
    </Box>
  );
}
