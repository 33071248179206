import { CSSObject } from '@chakra-ui/react';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: {},
  flexWrapper: { mt: 1 },
};

export const TabContainer: CSSObject = {
  border: 'unset',
  px: '1',
};

export const TabWrapper: CSSObject = {
  height: '8',
  overflowX: 'unset',
  p: '1',
  borderRadius: 'lg',
  gap: '1',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const buttonAccountTabOptionProps: CustomButtonProps = {
  _active: {
    bg: 'gamma.900',
    color: 'white',
    textShadow: '0 2px 3px rgba(0, 0, 0, .2)',
    boxShadow: 'none',

    _hover: {
      opacity: 'unset',
      bg: 'gamma.900',
      color: 'white',
    },
  },
  border: 'unset',
  bg: 'transparent',
  borderRadius: 'lg',
  fontFamily: 'Montserrat',
  boxShadow: 'none',
  color: 'gamma.900',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: 'none',

  _hover: {
    opacity: '0.6',
    border: 'unset',
    boxShadow: 'none',
    bg: 'transparent',
    color: 'gamma.900',
  },

  _focus: {
    boxShadow: 'none',
  },
};
