import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    borderRadius: 'md',
    overflow: 'hidden',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    my: '1px',
    _first: {
      mt: '0',
    },
    _last: {
      mb: '0',
    },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
    bgGradient: 'linear(to-b, gamma.400, gamma.500)',

    _hover: {
      bgGradient: 'linear(to-b, gamma.400, gamma.500)',
    },
  },
  accordionHeadingText: {
    color: 'alpha.600',
    flex: '1',
    fontSize: 'sm',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  accordionIcon: {
    color: 'alpha.500',
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
    color: 'gamma.100',

    _last: {
      border: 'none',
    },
  },
};
