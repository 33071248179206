import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  spanPropositionType: {
    color: 'white',
  },
  spanRaceInfo: {
    color: 'white',
  },
  iconMysteryBetFooter: {
    color: 'alpha.200',
  },
  flexWrapperMysteryBetFooterBetPlaced: {},
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    fontWeight: 'bold',
    fontSize: 'xs',
    w: '-webkit-fill-available',
    h: 'auto',
    py: 1.5,
  },
  buttonBonusBetsProps: {
    textTransform: 'capitalize',
    sx: {
      '&&': {
        minW: '20',
      },

      '&&[data-isbonusbet="true"]': {
        bgGradient: 'linear(to-b, beta.900, beta.900)',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset',
        color: 'white',
        borderRadius: 'md',
        border: '1px',

        _disabled: {
          boxShadow: 'none',
          bg: 'blackAlpha.300',
          color: 'alpha.100',
          textTransform: 'upperca            se',
          h: '7',
          opacity: '1',
          border: 'none',
          textShadow: 'none',
        },
      },
    },

    bgGradient: 'linear(to-b, beta.400, beta.600)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
    color: 'beta.900',
    borderRadius: 'md',
    border: 'none',
    borderColor: 'beta.400',
    fontWeight: 'medium',
    fontSize: '2xs',
    h: '6',
    px: '1.5',
    textShadow: 'none',
  },
  buttonBetPropositionClosedProps: {
    bg: 'alpha.700',
    boxShadow: 'md',
    color: 'white',
    borderRadius: 'base',
    fontSize: 'xs',
    px: '3.5',
    h: '8',
    display: 'flex',
    gap: '1',
  },
  buttonStakeProps: {
    fontSize: 'xs',
  },
  betCardSVG: {
    fill: 'alpha.600',
  },
};

export const BetCardVStack: CSSObject = {
  pb: '2',
};

export const BetCardContainer = (): CSSObject => ({
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: 'md',
  border: '2px',
  color: 'alpha.50',
  mb: '-2.5',
  '.state-prior': { color: 'white' },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'alpha.50',
  transition: 'all .2s ease-in-out',
  _hover: {
    // TODO: Update to use theme token
    color: '#4895ac',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'alpha.50',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'alpha.50',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'alpha.50',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'alpha.50',
  },
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, white 0%, gray.300 6.25%, white 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
  mt: '-2',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'alpha.50' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const EventRule: CSSObject = {
  color: 'white',
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'whiteAlpha.700',
  borderRadius: 'base',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};
