import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  mt: '2',
  mb: '4',
  px: 0,

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderRadius: 'md',
  border: 'none',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
};

export const TextTitle: CSSObject = {
  color: 'alpha.700',
};

export const LinkItem: CSSObject = {
  borderTop: '2px',
  borderTopColor: 'white',
  borderX: 'none',
  color: ['gray.700'],
  bg: 'gray.50',
  borderBottom: '1px',
  borderBottomColor: 'gray.300',

  _hover: { bg: 'gray.100' },
};

export const BoxPageWrapper: CSSObject = {
  px: '2',
};

export const IconSport: CSSObject = {
  color: 'alpha.500',
};

export const IconChevron: CSSObject = {
  boxSize: ['4', '5'],
  border: '1px solid',
  borderColor: 'alpha.700',
  borderRadius: 'full',
  p: '1',
  color: 'alpha.700',
  transition: '.3s ease-in-out',

  _hover: {
    bg: 'alpha.700',
    color: 'white',
  },
};
