import React from 'react';
import { Global } from '@emotion/react';
import mainBg from '@/assets/junglebet/images/backgrounds/backgroundMain.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${mainBg})`,
          backgroundPosition: 'center -300px',
          backgroundRepeat: 'repeat',
          backgroundSize: '800px auto',
          maxWidth: '100dvw',
          width: '100dvw',
          overflowX: 'hidden',
        },
      }}
    />
  );
}
