import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/templebet/images/onboarding/onboardingBg2.png';

export const WrapperFlexOnboarding: CSSObject = {
  bgImage: `url(${bg}), linear-gradient(180deg, var(--bc-colors-alpha-500), var(--bc-colors-alpha-600))`,
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  border: 'none',
  borderRadius: [null, null, '10px'],
  boxShadow: [null, null, '0px 6px 25px 0px var(--bc-colors-blackAlpha-50)'],
  pos: 'relative',
  w: ['100dvw', null, '760px'],
  p: '1px',
  fontFamily: 'accent',
  borderWidth: ['0px', null, '2px'],
  borderColor: 'beta.300',
  '.chakra-input': {
    bg: 'gray.50',
    borderRadius: 'md',
    color: 'gray.600',
    borderColor: 'gray.300',
    fontWeight: 'medium',
    fontFamily: 'body',
    fontSize: 'xs',
    boxShadow: [
      '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
      null,
      'none',
    ],
    '&:focus, &:active, &:hover': {
      color: 'gray.600',
      borderColor: 'gray.300',
      bg: 'gray.50',
    },
  },
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '500px'],
  h: '100%',
  borderRightRadius: [null, null, '12px'],
  color: 'beta.300',
  bg: ['alpha.400', null, '#00d1ff40'],
};

export const Title: CSSObject = {
  color: 'beta.800',
  fontSize: 'md',
  fontFamily: 'heading',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  display: 'flex',
  mb: '2',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  fontSize: 'sm',
  fontFamily: 'body',
  border: '1px solid',
  borderColor: 'gray.300',
  boxShadow: [
    '0px 1px 5px 0px var(--bc-colors-blackAlpha-300) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
    null,
    'none',
  ],
  '&&&': {
    borderRadius: 'md',
    color: 'gray.600',
    borderColor: 'gray.300',
    fontWeight: 'normal',
    bg: 'gray.50',
    '&&:hover, &&:focus': {
      opacity: 0.95,
    },
    _placeholder: {
      color: 'gray.600',
      fontWeight: 'medium',
      fontFamily: 'body',
    },
  },
};
