import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/titanbet/images/feature/featRaceBg.png';
import featBgMb from '@/assets/titanbet/images/feature/featRaceBgMb.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';
import raceCardBg from '@/assets/titanbet/images/homeTheming/raceCardBg.png';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'solid',
    fontFamily: 'body',
    fontSize: 'xs',
    h: '8',
    w: '46px',
    borderRadius: 'md',
  },
  svgMoreRacesArrowProps: {
    color: 'gamma.300',
    boxSize: '5',
  },
};

export const BoxCard: CSSObject = {
  p: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', null, 'lg'],
    boxShadow: [
      'unset',
      null,
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(193, 146, 98, 1) inset, 0px 0px 0px 5px rgba(27, 30, 31, 1) inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
    ],
    m: ['1', null, '0'],
  },
  boxShadow: [
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(193, 146, 98, 1) inset, 0px 0px 0px 5px rgba(27, 30, 31, 1) inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
    null,
    'unset',
  ],
  bg: '#303D3D',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'md',
  minH: '250px',
  py: '4',
  px: '3',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  pos: 'relative',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['0', null, '60px'],
  color: 'white',
  fontStyle: 'accent',
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};

export const TextRunner: CSSObject = {
  color: 'gamma.300',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextRunnerNumber: CSSObject = {
  color: 'gamma.300',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'white',
  fontFamily: 'accent',
  fontSize: '2xl',
  textTransform: 'capitalize',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'gamma.300',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: '16px',
  color: 'gamma.300',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  pt: '1',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'roboto',
  bg: 'gray.300',
  borderRadius: 'sm',
  color: 'gray.600',
  px: '1',

  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '50px',
  bgImage: `url(${raceCardBg})`,
  borderRadius: ['base', 'lg'],
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  border: '2px solid',
  borderColor: 'gamma.500',
};

export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
