import { css } from 'styled-components';

// TODO: Remove
export const PageHeaderWrapper = css`
  background-color: ${({ theme }) => theme.basePalette.navyBlue};
`;

// TODO: Remove
export const PageHeaderContainer = css`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--bc-space-10) 0 var(--bc-space-14) var(--bc-space-5);

  ${({ theme }) => theme.device.laptop} {
    padding: var(--bc-space-10) 0 var(--bc-space-20) var(--bc-space-5);
  }
`;

// TODO: Remove
export const PageHeaderInnerContainer = css`
  ${({ theme }) => theme.device.laptop} {
    padding: 0;
  }
`;

export const PageHeaderOuterContainer = css``;

export const BreadCrumbWrapper = css`
  padding: 0 var(--bc-space-5);
  ${({ theme }) => theme.device.laptop} {
    padding: 0;
  }
`;

export const PageHeader = css`
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-space-10);
  font-weight: 300;
`;

export const PageHeaderIcon = css``;

// TODO: Remove
export const RaceAndTimerContainer = css`
  color: ${({ theme }) => theme.palette.white};
  justify-content: space-between;
  align-items: center;
  padding: var(--bc-space-2) var(--bc-space-5) var(--bc-space-2) 0;

  ${({ theme }) => theme.device.tablet} {
    justify-content: flex-start;
  }

  ${({ theme }) => theme.device.laptop} {
    padding: var(--bc-space-2) 0;
  }
`;

// TODO: Remove
export const RaceName = css`
  font-size: var(--bc-fontSizes-sm);
  margin: 0 var(--bc-space-5) 0 0;
  min-width: var(--bc-space-56);

  ${({ theme }) => theme.device.tablet} {
    min-width: var(--bc-space-60);
  }
`;

// TODO: Remove
export const ButtonGroupWrapper = css`
  padding: var(--bc-space-5) 0;
  overflow-x: auto;
  width: 100%;
  // Transform due to first button element having margin left to stop box shadow being cut off
  // Temporarily using rem pending refactor as unable to use chakra theme spacing for negative values
  transform: translateX(-2rem);

  ${({ theme }) => theme.device.laptop} {
    padding: var(--bc-space-5) 0;
  }
`;

// TODO: Remove
export const StyledGroupedButton = css`
  margin-right: var(--bc-space-2);
  padding: var(--bc-space-2);
  /*
  This padding right offsets the italic font styling by 2px
  and keeps the text better aligned to center of container
  */
  padding-right: var(--bc-space-3);
  border-radius: 50%;
  min-width: var(--bc-space-10);
  max-width: var(--bc-space-10);
  min-height: var(--bc-space-10);
  max-height: var(--bc-space-10);
  border: 2px solid ${({ theme }) => theme.basePalette.midPurple};
  background-color: ${({ theme }) => theme.basePalette.purple};
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-md);
  font-weight: 600;
  font-style: italic;
  text-align: center;
  transition: all, 0.1s linear;
  font-family: ${({ theme }) => theme.fontFamily.body};

  &:first-child {
    margin-left: var(--bc-space-5);
  }

  &:hover,
  &:focus {
    -webkit-box-shadow: 0 0 6px 0 ${({ theme }) => theme.basePalette.brightBlue};
    box-shadow: 0 0 6px 0 ${({ theme }) => theme.basePalette.brightBlue};
  }
`;

export const SelectedStyledGroupedButton = css`
  border: 2px solid ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.basePalette.purple};
  -webkit-box-shadow: inset 0px 0px 10px 1px
      ${({ theme }) => theme.palette.white},
    0px 0px 10px 1px ${({ theme }) => theme.palette.white};
  box-shadow: inset 0px 0px 15px 1px ${({ theme }) => theme.palette.white},
    0px 0px 10px 1px ${({ theme }) => theme.palette.white};

  &:hover,
  &:focus {
    -webkit-box-shadow: inset 0px 0px 10px 1px
        ${({ theme }) => theme.palette.white},
      0px 0px 10px 1px ${({ theme }) => theme.palette.white};
    box-shadow: inset 0px 0px 15px 1px ${({ theme }) => theme.palette.white},
      0px 0px 10px 1px ${({ theme }) => theme.palette.white};
  }
`;

// TODO: Remove
export const RaceRunnerListWrapper = css`
  margin-left: var(--bc-space-3);
`;

// TODO: Remove
export const RaceRunnerListHeadings = css`
  display: flex;
  flex-direction: row;
  margin-bottom: var(--bc-space-4);
  user-select: none;
  padding: 0 var(--bc-space-3-5) 0 var(--bc-space-3-5);
`;

export const ListHeadingsWrapper = css``;

export const RaceRunnerHeading = css`
  color: ${({ theme }) => theme.palette.primary};
  text-transform: uppercase;
  font-size: var(--bc-fontSizes-xs);
  cursor: pointer;
`;

// TODO: Remove
export const RaceRunnerPropositionHeading = css`
  color: ${({ theme }) => theme.palette.primary};
  text-transform: uppercase;
  font-size: var(--bc-fontSizes-xs);
  cursor: pointer;
`;

export const RaceRunnerListContainer = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.transparentBlue};
  margin-bottom: var(--bc-space-20);
`;

// TODO: Remove
export const RaceRunnerHeadingContainer = css`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

// TODO: Remove
export const RaceRunnerSortedHeadingContainer = css`
  display: flex;
  flex: 3;
`;

// TODO: Remvoe
export const RaceRunnerPropositionHeadingContainer = css`
  flex: 0.92;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  margin-right: var(--bc-space-2);

  & + & {
    margin-right: 0;
  }
`;

// TODO: Remove
export const RaceRunnerPropositionHeadingContainerNoRightMarg = css`
  flex: 0.96;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  margin-right: 0 var(--bc-space-5);

  & + & {
    margin-right: 0;
  }
`;

export const RaceRunnerSortIcon = {
  ASC: css`
    margin-left: var(--bc-space-1);
    width: var(--bc-space-2);
    height: var(--bc-space-2);
    color: ${({ theme }) => theme.palette.primary};
  `,
  DESC: css`
    margin-left: var(--bc-space-1);
    transform: rotate(180deg);
    width: var(--bc-space-2);
    height: var(--bc-space-2);
    color: ${({ theme }) => theme.palette.primary};
  `,
};

export const RaceAlertBanner = css`
  background: ${({ theme }) => theme.basePalette.lightOrange};
  display: flex;
  justify-content: center;
  border: 4px solid ${({ theme }) => theme.basePalette.orange};
  border-bottom: none;
  border-top: none;
  padding: var(--bc-space-2) var(--bc-space-5);
`;

export const RaceAlertBannerContent = css`
  align-items: center;
  display: flex;
  max-width: 1200px;
  width: 100%;
`;

export const RaceAlertText = css`
  color: ${({ theme }) => theme.basePalette.navyBlue};
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  font-weight: bold;
`;

export const RaceAlertIcon = css`
  width: 18px;
  margin-right: var(--bc-space-1);
`;

export const RaceStatusText = css`
  color: var(--bc-colors-whiteAlpha-700);
  font-size: var(--bc-fontSizes-xs);
`;

export const RaceStatusTextAlert = css`
  color: ${({ theme }) => theme.basePalette.yellow};
`;

// TODO: Remove
export const ResultedStatusLabel = css`
  align-items: center;
  background: ${({ theme }) => theme.basePalette.white};
  border-radius: 3px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
`;

export const ResultedStatusText = css`
  color: ${({ theme }) => theme.basePalette.black};
`;

export const RaceCardHeading = css`
  color: ${({ theme }) => theme.basePalette.white};
  font-size: var(--bc-fontSizes-md);
  // font-family: ${({ theme }) => theme.fontFamily.bodyAlt};
  font-weight: 300;
  margin-bottom: var(--bc-space-5);
  margin-left: var(--bc-space-3-5);
  font-style: italic;
`;
