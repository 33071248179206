import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: ['center', null, null, null, 'flex-start'],
  py: '16',
  px: ['8', null, null, null, '5'],
  color: 'white',
  textAlign: 'center',
  mx: 'auto',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'Caveman',
  fontSize: '3xl',
  fontWeight: 'normal',
  color: 'gamma.500',
  lineHeight: '80%',
  textTransform: 'uppercase',
};

export const BetSlipEmptyImage: CSSObject = {
  h: 'full',
  w: '28',
  mb: '0',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
  mb: '2',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};

export const BetSlipEmptyResponsibleGamblingWrapper: CSSObject = {
  mt: '4',
  mx: '0',
  mb: '0',
  'div:last-child': {
    borderBottomRadius: ['none', null, null, null, 'xl'],
  },
};
