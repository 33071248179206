import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import fish from '@/assets/goldenbet888/images/mascot/fishMascot.png';

export const BoxBallance: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.900)',
  mx: '0.5',
  borderBottomRadius: 'base',
  '> div:first-of-type': {
    color: 'gamma.400',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
      },
    },
  },
  '> div:last-of-type': {
    color: 'gamma.200',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
      },
    },
  },
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  w: 'full',
  mt: '2',
  sx: {
    '& svg': {
      mb: '1',
    },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  bg: 'gamma.200',
  color: 'alpha.800',
  borderTop: '1px',
  borderColor: 'gamma.400',

  '&& > p': { transition: 'all 0.2s ease-in-out' },
  ':first-child': {
    borderTop: 'none',
  },
  '&:hover, &:active': {
    bg: 'gamma.300',
  },
});

export const PopoverHeaderQA: CSSObject = {
  bg: 'alpha.300',
  mx: '0.5',
  mt: '0.5',
  border: 'none',
  borderTopRadius: 'base',
  color: 'gamma.200',
  lineHeight: 'normal',
};

export const PopoverContentQA: CSSObject = {
  bg: 'gamma.200',
  boxShadow: '2xl',
  top: '-2',
  _focus: {
    boxShadow: '2xl',
  },
};

export const FlexBallanceItem: CSSObject = {
  color: 'gamma.200',

  p: {
    _last: { fontWeight: 'extrabold' },
  },
};

export const TextBalanceValue: CSSObject = {
  color: 'alpha.500',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'sm',
  h: 'unset',
};

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${fish})`,
  bgSize: '100%',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '-5',
  display: ['none', null, null, null, 'block'],
  w: '48',
  h: '24',
  pos: 'absolute',
  top: '-4',
  left: '-10',
};
