import {
  AccordionButtonProps,
  AccordionItemProps,
  AccordionPanelProps,
  AccordionProps,
  TextProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './CompetitionsMenu.styles.imports';
import { TIconProps } from '@/components/common/IconSvg';

const themeName = getThemeName();

export type CompetitionsMenuSchema = Partial<{
  accordion: AccordionProps;
  accordionItem: AccordionItemProps;
  accordionButton: AccordionButtonProps;
  accordionHeadingText: TextProps;
  accordionIcon: TIconProps;
  accordionPanel: AccordionPanelProps;
}>;

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordionPanel: {
    'data-testid': 'competitionsMenu-accordionPanel',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMenuStyles?.accordionPanel;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionIcon: {
    'data-testid': 'competitionsMenu-accordionIcon',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMenuStyles?.accordionIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionHeadingText: {
    'data-testid': 'competitionsMenu-accordionHeadingText',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMenuStyles
          ?.accordionHeadingText;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionButton: {
    'data-testid': 'competitionsMenu-accordionButton',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMenuStyles?.accordionButton;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordionItem: {
    'data-testid': 'competitionsMenu-accordionItem',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMenuStyles?.accordionItem;
      } catch (err) {
        return {};
      }
    })(),
  },
  accordion: {
    'data-testid': 'competitionsMenu-accordion',

    ...(() => {
      try {
        return styleImports[themeName]?.competitionsMenuStyles?.accordion;
      } catch (err) {
        return {};
      }
    })(),
  },
};
