import { Box, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { Warning } from 'styled-icons/entypo';

export default function Error() {
  return (
    <Box sx={{ py: '2', textAlign: 'center' }}>
      <Icon as={Warning} sx={{ boxSize: '5', mb: '3' }} />

      <Text sx={{ fontSize: 'xs', mb: '5' }}>
        An error has occurred, please try again.
      </Text>
    </Box>
  );
}
