/* eslint-disable */
export const getRequestId = (function () {
  const e = () => {
    const e = [
      /Headless/.test(navigator.userAgent),
      !!(
        navigator.webdriver ||
        Object.prototype.hasOwnProperty.call(navigator, 'webdriver')
      ),
      !window.chrome,
      navigator.plugins.length === 0,
      !(navigator.plugins instanceof PluginArray) ||
        navigator.plugins.length === 0 ||
        window.navigator.plugins[0].toString() !== '[object Plugin]',
      !navigator.languages || navigator.languages.length === 0,
      !!window.t,
      () => {
        const e = [
          '__webdriver_evaluate',
          '__selenium_evaluate',
          '__webdriver_script_function',
          '__webdriver_script_func',
          '__webdriver_script_fn',
          '__fxdriver_evaluate',
          '__driver_unwrapped',
          '__webdriver_unwrapped',
          '__driver_evaluate',
          '__selenium_unwrapped',
          '__fxdriver_unwrapped',
          'webdriver',
          '__driver_evaluate',
          '__webdriver_evaluate',
          '__selenium_evaluate',
          '__fxdriver_evaluate',
          '__driver_unwrapped',
          '__webdriver_unwrapped',
          '__selenium_unwrapped',
          '__fxdriver_unwrapped',
          '_Selenium_IDE_Recorder',
          '_selenium',
          'calledSelenium',
          '_WEBDRIVER_ELEM_CACHE',
          'ChromeDriverw',
          'driver-evaluate',
          'webdriver-evaluate',
          'selenium-evaluate',
          'webdriverCommand',
          'webdriver-evaluate-response',
          '__webdriverFunc',
          '__webdriver_script_fn',
          '__$webdriverAsyncExecutor',
          '__lastWatirAlert',
          '__lastWatirConfirm',
          '__lastWatirPrompt',
          '$chrome_asyncScriptInfo',
          '$cdc_asdjflasutopfhvcZLmcfl_',
        ];
        const r = [
          '_phantom',
          '__nightmare',
          '_selenium',
          'callPhantom',
          'callSelenium',
          '_Selenium_IDE_Recorder',
        ];
        for (const n in r) {
          const t = r[n];
          if (window[t]) {
            return true;
          }
        }
        for (const i in e) {
          const _ = e[i];
          if (window['document'][_]) {
            return true;
          }
        }
        for (const a in window['document']) {
          if (a.match(/\$[a-z]dc_/) && window['document'][a]['i']) {
            return true;
          }
        }
        if (
          window['external'] &&
          window['external'].toString() &&
          window['external'].toString()['indexOf']('Sequentum') != -1
        )
          return true;
        if (window['document']['documentElement']['getAttribute']('selenium'))
          return true;
        if (window['document']['documentElement']['getAttribute']('webdriver'))
          return true;
        if (window['document']['documentElement']['getAttribute']('driver'))
          return true;
        return false;
      },
    ];
    let n = 0;
    e.forEach((e, r) => {
      n = n | (((typeof e === 'function' ? e() : e) ? 1 : 0) << r);
    });
    return n;
  };
  function a(r, n) {
    let t = new Uint8Array(n);
    for (let e = 0; e < n; e++) {
      t[e] = Number(r & 0xffn);
      r = r >> 8n;
    }
    return t;
  }
  const r = [];
  for (let e = 0; e < 256; ++e) {
    r.push((e + 256).toString(16).slice(1));
  }
  const o = (e) =>
    r[e[0]] +
    r[e[1]] +
    r[e[2]] +
    r[e[3]] +
    '-' +
    r[e[4]] +
    r[e[5]] +
    '-' +
    r[e[6]] +
    r[e[7]] +
    '-' +
    r[e[8]] +
    r[e[9]] +
    '-' +
    r[e[10]] +
    r[e[11]] +
    r[e[12]] +
    r[e[13]] +
    r[e[14]] +
    r[e[15]];
  const u = (e) => {
    const r = a(e, 8);
    let n = 0;
    for (let e = 0; e < 8; e++) {
      n = n ^ r[e];
      for (let e = 0; e < 8; e++) {
        n = (n >>> 1) ^ (n & 1 ? 3988292384 : 0);
      }
    }
    return n;
  };
  const n = (e) => {
    const r = Math.round(new Date().valueOf() / 6e4);
    const n = (e + 1227) << 8;
    const t = BigInt(r ^ n ^ 28242669);
    const i = crypto.getRandomValues(new Uint8Array(16));
    const _ = a((BigInt(u(t)) + (t << 32n)) ^ BigInt(r), 8);
    for (let e = 0; e < 8; e++) {
      i[e << 1] = _[e];
    }
    return o(i);
  };
  return n.bind(null, e());
})();
