import { FC } from 'react';
import { usePageAnalytics } from '../../hooks/usePageAnalytics';

/*
  This component watches the auth status and page status
 */
const Analytics: FC = () => {
  usePageAnalytics();
  return null;
};

export default Analytics;
