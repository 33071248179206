import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px',
  // TODO: Use theme token
  borderColor: '#31A6C9',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  p: '2',
  w: 'fill-available',
  borderRadius: 'none',
  color: 'white',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
  pt: '2px',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'gamma.900',
  fontWeight: 'medium',
};

export const TextSubtitle: CSSObject = {
  color: 'gamma.900',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'delta.200' : 'red.300',
});

export const LinkMarket: CSSObject = {
  color: 'gamma.900',
  '&:hover, &:active': { color: 'gamma.900' },
};

export const CountdownTimerBox: CSSObject = {
  '.chakra-badge': {
    color: 'black',
  },
  '.chakra-badge.state-inprogress': {
    color: 'beta.500',
    bg: 'beta.800',
  },
  '.chakra-badge.state-ended': {
    bg: 'alpha.300',
    color: 'alpha.900',
  },
};
