import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';
import { CustomButtonProps } from '@/components/Button/Button';

export const TableItem: CSSObject = {
  borderColor: 'blackAlpha.400',
  borderRight: 'none',
  pl: 0,
  py: 2,
};

export const BoxTransactionsContainer: CSSObject = {
  color: 'white',
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const TableText = ({ textVariation }: TTableText): CSSObject => ({
  color: textVariation === 'positive' ? 'alpha.300' : 'white',
});

export const NoTransactionsText: CSSObject = {
  color: 'white',
};

export const TableHeadingsWrapper: CSSObject = {
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const buttonMoreTableProps: CustomButtonProps = {
  variant: 'solid',
  fontFamily: 'CarterOne',
  mt: '3',
};
