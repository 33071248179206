import styled, { keyframes } from 'styled-components';
import { Spinner3 } from '@styled-icons/evil';
import { Box, chakra, ListItem, Text as ChakraText } from '@chakra-ui/react';
import { styleImports } from './TakeABreak.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button, CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export const buttonConfirmBreakProps: CustomButtonProps = {
  'data-testid': 'buttonConfirmBreakProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonConfirmBreakProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonTakeBreakProps: CustomButtonProps = {
  'data-testid': 'buttonTakeBreakProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonTakeBreakProps;
    } catch (err) {
      return {};
    }
  })(),
};

const Text = chakra(ChakraText, {
  baseStyle: () => ({
    lineHeight: 'base',
    mb: '1',
  }),
});

export const TakeABreakContainer = chakra<any>(Box, {
  label: 'TakeABreakContainer',
  baseStyle: () => ({
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.200',
    pb: '4',
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.TakeABreakContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextS = chakra(Text, {
  baseStyle: () => ({
    label: 'takeABreak-TextS',
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.TextS;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextM = chakra(Text, {
  label: 'takeABreak-TextM',
  baseStyle: () => ({
    fontSize: 'md',
    fontWeight: 'bold',
    mb: '6',
    ...(() => {
      try {
        return styleImports[themeName]?.TextM;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * @deprecated The function should not be used or extended further,
 * use buttonTakeBreakProps instead, and add your theme into ButtonTakeBreakSwitched or ButtonLifeTimeExclusionSwitched
 */
export const ButtonSecondary = chakra(Button, {
  label: 'TakeABreakButtonSecondary',
  baseStyle: () => ({
    bg: 'red.500',
    color: 'white',
    my: '2',
    '&:hover, &:focus': {
      bg: 'red.600',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonSecondary;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ListItemExclusionItem = chakra(ListItem, {
  label: 'ListItemExclusionItem',
  baseStyle: () => ({
    fontSize: 'sm',
    ...(() => {
      try {
        return styleImports[themeName]?.ListItemExclusionItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BottomBorderBox = chakra(Box, {
  baseStyle: () => ({
    marginBottom: '5',
    paddingBottom: '5',
    borderBottom: 'solid 1px',
  }),
});

export const Subheading = chakra(Text, {
  label: 'Subheading',
  baseStyle: () => ({
    fontWeight: 'bold',
    mb: '2',
    fontSize: 'md',
  }),
});

export const InformationWrapper = chakra(Box, {
  label: 'InformationWrapper',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      py: '4',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.InformationWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(Spinner3)`
  animation: ${rotate} 2s linear infinite;
  width: 20px;
  height: 20px;
  ${({ theme }) => theme.pages.deposit.ColourPrimary};
`;

export const HeadingInformation = chakra(Text, {
  label: 'takeABreak-HeadingInformation',
  baseStyle: ({ theme }) => ({
    fontSize: 'md',
    fontWeight: 'bold',
    mb: '4',

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'alpha.700',
      fontWeight: 'semibold',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.HeadingInformation;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextInformation = chakra(Text, {
  label: 'TextInformation',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'sm',
    }),

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'sm',
      mb: '3',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextInformation;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxStyledLink = chakra(Box, {
  label: 'takeABreak-BoxStyledLink',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxStyledLink;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxTakeABreak = chakra(Box, {
  label: 'BoxTakeABreak',
  baseStyle: () => ({
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.200',
    pb: '4',
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxTakeABreak;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextDate = chakra(Text, {
  label: 'TextDate',
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextDate;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextDateLabel = chakra(Text, {
  label: 'TextDateLabel',
  baseStyle: () => ({
    fontSize: 'md',
    ...(() => {
      try {
        return styleImports[themeName]?.TextDateLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextConfirmBreak = chakra(Text, {
  label: 'takeABreak-TextConfirmBreak',
  baseStyle: () => ({
    fontSize: 'md',
    fontWeight: 'bold',
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.TextConfirmBreak;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * @deprecated The function should not be used or extended further,
 * use buttonConfirmBreakProps instead.
 */
export const ButtonTakeABreak = chakra(Button, {
  label: 'ButtonTakeABreak',
  baseStyle: () => ({
    colorScheme: 'red',
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonTakeABreak;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxTakeABreakWrapper = chakra(Box, {
  label: 'BoxTakeABreakWrapper',
  baseStyle: () => ({
    borderBottom: '1px',
    borderBottomColor: 'blackAlpha.200',
    pb: '4',
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxTakeABreakWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const InputContainer = chakra('div', {
  baseStyle: () => ({
    label: 'takeABreak-InputContainer',

    ...(() => {
      try {
        return styleImports[themeName]?.InputContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
