import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { MatchesModalSchema } from '@/views/sports/components/MatchesModal/styles/MatchesModal.styles';

export const matchesModalStyles: MatchesModalSchema = {
  modalWrapper: { size: 'lg', isCentered: true, scrollBehavior: 'inside' },
  modalContent: { color: 'gray.600', overflow: 'hidden', bg: 'beta.400' },
  modalHeader: {
    alignItems: 'center',
    display: 'flex',
    gap: '4',
    justifyContent: 'space-between',
    p: '4',
    color: 'white',
    borderColor: '#254f6e',
    borderBottomWidth: '1px',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
  },
  closeButton: {
    bg: 'whiteAlpha.600',
    color: '#316993',
    isRound: true,
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: { bg: 'gray.800' },
  },
  divider: {
    as: 'div',
    borderColor: '#254f6e',
    border: 'none',
    borderBottomWidth: '1px',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
  },
  modalBody: { p: '0', maxH: '400px' },
  stackWrapper: { spacing: '0' },
  linkWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '3',
    justifyContent: 'space-between',
    p: '3',
    _hover: { bg: 'beta.300', textDecoration: 'none' },
  },
  flexSportName: {
    align: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    gap: '1',
    color: 'white',
  },
  iconSportName: { fontSize: '2xl', color: 'alpha.400' },
  hStack: { spacing: '1' },
  countdown: {
    fontSize: '9px',
    h: '16px',
    py: '0',
    sx: {
      '&&': {
        bg: 'rgba(0, 0, 0, 0.48) !important',
        color: '#FF9F1C !important',
      }, // Reset
      ':not(.state-prior)': { color: 'white' },
    },
  },
  duotoneSgmLogoOverride: {
    h: 'auto',
    w: '9',
    sx: {
      '&': {
        svg: {
          color: 'alpha.300',
        },
      },
    },
  },
  hStackIcon: {
    as: ChevronRight,
    color: 'white',
    fontSize: 'md',
  },
};
