import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  maxW: ['full', null, null, null, '290px'],
  borderRadius: [null, null, null, null, 'lg'],
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '0',
  px: '2.5',
  py: '1.5',
  mb: isKeypadVisible && 'unset',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'beta.400',
  color: 'white',
  borderBottomRadius: ['none', null, null, null, 'md'],
  borderTop: '1px solid rgba(255, 255, 255, 0.08)',
  boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.25)',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  fontFamily: 'Roboto',
  color: 'alpha.700',
  fontSize: 'sm',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'extrabold',
  fontFamily: 'Roboto',
  color: 'alpha.700',
  fontSize: 'sm',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  h: '44px',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  height: '44px',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  height: '28px',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  borderRadius: 'md',
  fontWeight: 'black',
  margin: 'auto',
  mt: '-27px',
  position: 'relative',
  lineHeight: 'normal',
  color: 'beta.400',
  minW: '16',
  border: '2px solid',
  borderColor: 'white',
  px: '2',
  textShadow: 'none',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',

  sx: {
    svg: {
      fontWeight: 'black',
    },
    '.chakra-button__icon': {
      marginInlineEnd: '-1',
      zIndex: 9,
    },
    '.btn-span': {
      lineHeight: '24px',
    },
    '.chakra-icon': {
      boxSize: '3',
      mr: '2',
    },
    '&:hover, &:active': {
      bg: 'alpha.300',
    },
    '&&': {
      fontSize: '2xs',
      fontFamily: 'roboto',

      _after: {
        bgGradient: 'linear(to-b, beta.200, beta.300)',
        opacity: 1,
      },
    },
  },
};
