import { CSSObject } from '@chakra-ui/react';

export const TextCheckbox: CSSObject = {
  color: 'white',
};
export const buttonCloseAccountProps: CSSObject = {
  pt: '1.5',
  fontWeight: 'bold',
  variant: 'solid',
  textTransform: 'uppercase',
  fontSize: 'xs',
};
