import { RaceNumberSchema } from './RaceNumber.styles';

export const raceNumberStyles: RaceNumberSchema = {
  wrapper: {
    fontWeight: 'bold',
    fontSize: '2xs',
    minW: '5',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    px: '1',
    bg: 'beta.800',
    borderRadius: 'base',
    color: 'white',
    h: '4',
    lineHeight: '120%',
  },
};
