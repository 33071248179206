import queryString from 'query-string';
import api from '../api';
import {
  TSportPropositionResponse,
  TSportPropositionsParams,
} from './sportDetailsPropositions.types';

// To satisfy max URL length of 2,083 chars in Chrome and IE
const MAX_MARKET_ID_PARAMS = 40;

export const sportPropositionsEndpoint =
  'punter/sports/sport-details/propositions';

/**
 * @deprecated use querySportProps instead
 */
export const querySportsPropositions = (params: TSportPropositionsParams) =>
  api
    .get<TSportPropositionResponse>(sportPropositionsEndpoint, {
      params: {
        ...params,
        market_id: Array.isArray(params.market_id)
          ? params.market_id.slice(0, MAX_MARKET_ID_PARAMS)
          : params.market_id,
      },
      paramsSerializer: queryString.stringify,
    })
    .then((res) => res.data);
