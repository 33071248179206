import { CSSObject } from '@chakra-ui/react';
import { rgba } from 'polished';

export const ModalOverlay = (theme: any): CSSObject => ({
  bg: rgba(theme.colors.gamma[800] as string, 0.7),
});

export const ModalHeader: CSSObject = {
  color: 'gray.700',
  fontWeight: 'bold',
  px: 4,
  pb: 2,
};

export const ModalBody: CSSObject = {
  color: 'gray.600',
  fontSize: 'sm',
  pt: 1,
  px: 4,
  pb: 4,
};

/**
 * Most of these styles are copied over from account button styles - it would
 * be nice to import them over instead, but properties on `CSS_Object` are
 * inaccessible.
 * @see {@link https://github.com/chakra-ui/chakra-ui/issues/6261}
 */
export const ButtonDeposit: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  mt: 3,

  '&, &:hover, &:focus': {
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
    color: 'white',
    textShadow: '0 2px 3px rgba(0, 0, 0, 0.3)',
  },

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, gamma.700, gamma.800)',
  },
};
