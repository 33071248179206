import { CSSObject } from '@chakra-ui/react';

export const TabWrapper: CSSObject = {
  bg: 'linear-gradient(180deg, #FFE0A6 0%, #E4C180 100%)',
  borderRadius: 'base',
  p: '1.5',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  bg: isActive
    ? 'linear-gradient(180deg, #8D4522 0%, #76391C 100%)'
    : 'transparent',
  borderRadius: 'base',
  boxShadow: 'none',
  color: isActive ? 'white' : 'alpha.800',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: 'auto',
  px: '2',
  py: '1',
  textShadow: isActive ? '0px 1px 0px 0px rgba(0, 0, 0, 0.35)' : 'none',
  textTransform: 'uppercase',
  _hover: {
    bg: isActive ? 'alpha.400' : 'transparent',
    boxShadow: 'none',
    color: 'alpha.800',
  },

  _active: {
    bg: isActive ? 'alpha.400' : 'transparent',
    boxShadow: 'none',
    color: isActive ? 'alpha.800' : 'delta.100',
  },
});
