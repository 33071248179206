import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import logo from '@/assets/sterlingparker/images/mysteryBet/logo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  buttonMyBets: {
    fontSize: ['xs', 'sm'],
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  tooltipIcon: {
    fill: 'white',
  },
  flexWrapperPlacedBetViewGroup: {},
  flexWrapperMysteryPlacedBetView: {},
  spanRollOverText: { color: 'alpha.500' },
  textPriceInfo: {
    color: 'alpha.500',
    fontWeight: 'bold',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    fontWeight: 'bold',
  },
  flexRaceInfo: {
    gap: 1,
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
  },
  iconRace: {
    color: 'alpha.500',
    boxSize: 6,
  },
  textRollover: {
    color: 'alpha.500',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  iconMystery: {},
  flexWrapperMysteryLabelView: {
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
  },
  iconBack: {
    boxSize: 8,
    color: 'alpha.500',
  },
  flexWrapperMysteryRolloverView: {
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    px: 2,
    color: 'white',
    mx: 'auto',
  },
  buttonRollOver: {
    fontFamily: 'roboto',
    fontSize: 'xs',

    _hover: {
      border: 'none',
    },
    _disabled: {
      bg: 'beta.700',
      color: 'alpha.500',
      border: 'none',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
      px: 3,
      _after: { opacity: 0 },
    },
  },
  buttonAddToBetslip: {
    px: 2,
    fontSize: ['xs', 'sm'],
  },
  flexWrapperMysteryBetReviewView: {
    mx: 4,
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'lg',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    p: '2',
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'md',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    fontSize: 'xs',
    sx: {
      '&&': {
        px: 3,
      },
    },
    lineHeight: '15px',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear(225deg, beta.200, beta.300)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'md',
    boxSize: '34px',
    p: '2',
    color: 'white',
    cursor: 'pointer',
  },
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 1,
  },
  buttonOdds: { variant: 'primary' },
  flexWrapperMysteryBetSelectionView: {
    mx: 4,
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'lg',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    p: '2',
  },
  imageLogo: {
    src: `${logo}`,
    w: ['195px', '234px'],
    pos: 'relative',
    bottom: -1,
    right: -1,
  },
};
