import React, { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { useIntl } from 'react-intl';
import { formatDateToConditional } from '@/lib/Time';
import { TUpcomingMatch } from '@/api/sports/upcomingMatches.types';
import { getIconBySportName } from '@/helpers/utils';
import IconSvg from '@/components/common/IconSvg';
import CompetitionsMatchCard from '../CompetitionsMatchCard/CompetitionsMatchCard';
import Input from '@/components/FormElements/Input';
import { competitionListStyles } from '@/views/sports/components/CompetitionList/styles/CompetitionList.styles';

type TEvent = {
  events: TUpcomingMatch[];
  isLoading: boolean;
  marketTypes?: string[];
  marketCount?: number;
  shouldDisplayMarketHeadings?: boolean;
};

export default function CompetitionList({
  events,
  marketTypes,
  marketCount,
  isLoading,
  shouldDisplayMarketHeadings = true,
}: TEvent) {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredEvents = events.filter((item) =>
    item?.match_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groups = groupBy(filteredEvents, ({ match_start_time }) =>
    new Intl.DateTimeFormat('en-AU').format(new Date(match_start_time ?? ''))
  );

  const defaultAccordionIndex = Array.from(
    { length: events.length },
    (_, index) => index
  );

  if (isLoading) {
    return (
      <div>
        {[...new Array(12)].map((_, i) => (
          <Skeleton key={i} {...competitionListStyles.skeleton} />
        ))}
      </div>
    );
  }

  return (
    <>
      <Input
        allowInvalidKeys
        name="x"
        isFormik={false}
        placeholder={intl.formatMessage({ id: 'generic.teamOrEventSearch' })}
        {...competitionListStyles.input}
        InputRightElement={() => (
          <Icon {...competitionListStyles.inputRightIcon} />
        )}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(e.target.value)
        }
      />
      {!!filteredEvents.length && (
        <Accordion
          defaultIndex={defaultAccordionIndex}
          allowMultiple
          {...competitionListStyles.accordion}
        >
          {Object.entries(groups).map(([date]) => (
            <AccordionItem
              key={`${groups[date][0].competition_id}-${date}`}
              {...competitionListStyles.accordionItem}
            >
              <AccordionButton {...competitionListStyles.accordionButton}>
                <HStack {...competitionListStyles.hStack}>
                  <Flex {...competitionListStyles.flex}>
                    <IconSvg
                      name={getIconBySportName(groups[date][0].sport_name)}
                      {...competitionListStyles.accordionButtonIconSvg}
                    />
                    <Text {...competitionListStyles.accordionButtonText}>
                      {formatDateToConditional(
                        groups[date][0].match_start_time ?? ''
                      )}
                    </Text>
                  </Flex>
                </HStack>

                {marketTypes && shouldDisplayMarketHeadings && (
                  <Flex {...competitionListStyles.marketTypesFlex}>
                    {marketTypes.map((type) => (
                      <Text
                        key={type}
                        {...competitionListStyles.marketTypesText}
                      >
                        {type}
                      </Text>
                    ))}
                  </Flex>
                )}

                <AccordionIcon
                  {...competitionListStyles.accordionButtonInnerIcon}
                />
              </AccordionButton>
              <AccordionPanel {...competitionListStyles.accordionPanel}>
                {groups[date].map((event) => (
                  <CompetitionsMatchCard
                    event={event}
                    key={event?.competition_id}
                    marketCount={marketCount}
                    isSoccer={event?.sport_name?.toLowerCase() === 'soccer'}
                  />
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </>
  );
}
