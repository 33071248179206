/* eslint-disable import/no-mutable-exports */
import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import Logo from '../conditionals/Logo/template';

import { footerStyles } from '../styles/Footer.styles';
import { CopyrightView, BlurbContentView, BlurbTextView } from '../Footer';

export const ThemeFooter = [
  {
    name: 'wrapper',
    component: <Container maxW="container.xl" {...footerStyles.container} />,
  },
  {
    name: 'logo',
    component: (
      <Box maxW="container.xl" {...footerStyles.footerBar}>
        <Logo />
      </Box>
    ),
  },
  {
    name: 'footer',
    component: (
      <Flex mb="2" {...footerStyles.footerContentContainer}>
        <Box {...footerStyles.blurbContent}>
          <BlurbContentView />
        </Box>
        <Box {...footerStyles.blurb}>
          <BlurbTextView />
        </Box>
      </Flex>
    ),
  },
  { name: 'copyright', component: <CopyrightView /> },
];

export default ThemeFooter;
