import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {
    width: '100%',
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    px: ['0', null, null, '0'],
  },
};
