import { CSSObject } from '@chakra-ui/react';

export const EstReturnsLabel: CSSObject = {
  bg: 'epsilon.400',
  color: 'white',
  fontWeight: 'extrabold',
  height: '100%',
  alignItems: 'center',
  py: '0,5',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bg: 'epsilon.200',
  borderRadius: 'base',
  w: 'full',
  color: 'black',
  justifyContent: 'center',
};

export const ApprovedBetContainer: CSSObject = {
  background: 'beta.500',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2);',
  borderRadius: 'sm',
  w: 'full',
  color: 'black',
  justifyContent: 'center',
};

export const RejectedBetContainer: CSSObject = {
  background: 'red.400',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2);',
  borderRadius: 'sm',
  w: 'full',
  color: 'red.900',
  justifyContent: 'center',
};

export const FooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'white',
  px: '0',
  h: '6',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'base',
  boxShadow: 'inset 0px 0px 5px rgba(12, 30, 90, 0.6)',
};

export const FooterText: CSSObject = {
  px: '1',
  fontWeight: 'medium',
};
