import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
};
export const RunnerDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const BetOdds: CSSObject = {
  color: 'white',
};
export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
};
export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'green.500';
      case 'pending':
        return 'orange.400';
      default:
        return 'beta.200';
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'white';
      case 'pending':
        return 'orange.800';
      default:
        return 'blackAlpha.700';
    }
  },
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  py: '0',
});
export const HeaderSvg: CSSObject = {
  color: 'alpha.400',
  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
  boxSize: '5',
};
export const BetMultiIcon: CSSObject = {
  boxSize: '5',
  p: '2px',
  '&&': {
    color: 'white',
    fill: 'white',
  },
  background: '#cc952b',
};

export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
  '> a': {
    ml: '0',
  },
};
export const headerRowStyles: HeaderRowSchema = {
  originalOdds: {
    color: 'gray.100',
  },
  headerRowSvg: {
    fill: '#3074A8',
  },
};
