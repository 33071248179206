import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  '> div:first-child': {
    color: 'gray.500',
    fontSize: 'md',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'semibold',
    mb: '4',
  },
  form: {
    label: {
      color: 'gray.500',
      fontSize: 'xs',
      fontWeight: 'semibold',
    },
    // @TODO Fix/Remove this with global inputs
    'select[name*="title-dropdown"], #state-select, #suburb-input, #postcode-input, #residential-input, #streetName-input, #streetNumber-input':
      {
        h: '38px',
        borderRadius: 'lg',
        border: '1px',
        borderColor: 'gray.300',
        background: 'gray.50',
        color: 'gray.500',
        boxShadow: 'none',
        fontWeight: '400',
      },
    '[class*=chakra-checkbox]': {
      alignItems: 'flex-start',
      '&[data-checked]': {
        bg: 'unset',
        borderColor: 'inherit',
        color: 'black',
        svg: {
          color: 'white',
        },
      },
      '& .chakra-checkbox__control': {
        bg: 'alpha.300',
        borderColor: 'alpha.400',
      },
    },
    'button[aria-label*="password toggle"]': {
      top: '0.5',
    },
  },
};

export const Title: CSSObject = {
  color: 'gray.500',
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  mb: '4',
  mt: [null, null, '9'],
};
