export const en = {
  'account.settings.responsible.lastupdated': 'Published: 13/04/2023',
  'maintenance.error':
    '<span>We are investigating the issue.</span> Refresh the page, or try again later.',
  'error.description':
    '<span>FiestaBet is currently down.</span> Refresh the page, or try again later.',
  'error.heading': "You've Hit a Salsa Slip",
  'maintenance.heading': 'Spicing Up Our Site',
  'onboarding.locationnotallowed.header': "Ay Caramba! You're Off the Map!",
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing FiestaBet from outside of Australia.</b>',
  'onboarding.step1.pageSubtitle':
    "At <b>FiestaBet</b>, we're turning every bet into a celebration, and we've got a piñata full of thrilling opportunities waiting just for you. <b>Join today!</b>",
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'legal.responsible.section.takeaction.depositlimit.two':
    'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.depositlimit.button': 'Set limit',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.deactivate.button': 'Shut My Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': 'Exclude Yourself',
  'legal.responsible.section.takeaction.lifeexclusion.two':
    'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.lifeexclusion.button': 'Set Exclusion',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.break.two':
    'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.section.takeaction.break.button': 'Suspend My Account',
  'promo.racing.featuredRace': 'Featured <bu>race</bu>',
  'promo.sport.startparty': 'Sombrero <bu>Showdown</bu>',
  'onboarding.login.title': 'Welcome back to the party!',
  'sports.allsports.azheading': 'A-Z Sports',
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading': 'Empty Piñata',
  'betSlip.potentialReturns': 'Est. Payout',
  'betslip.betslipbetcard.errors.insufficientlegsformulti':
    'To build a Multi, please add more selections to increase your potential returns.',
  'betslip.betslipmodal.placedbetsconfirmation': 'Animation Confirmation',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'racing.errorMessages.noRacesAvailable': 'Piñata Party {br} Standstill',
  'racing.errorMessages.noRacesAvailableSubtext': `<b>There are no more races available.</b>
  <p>Please consider selecting a different day to try again.</p>`,
  'onboarding.depositLimit.completeSignup': 'Continue',
  'account.withdraw.addbank': 'Add new bank account',
  'account.withdraw.requests.heading': 'Withdrawal Request',
};
