import { CSSObject } from '@chakra-ui/react';
import woodTexture from '@/assets/junglebet/images/backgrounds/woodBg.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: `linear-gradient(180deg, var(--bc-colors-delta-300), var(--bc-colors-delta-600)), url(${woodTexture})`,
  boxShadow:
    '0px -1px 2px 0px rgba(186, 72, 9, 0.70) inset, 0px 0px 0px 3px #6D2B05 inset, 0px 5px 0px 0px #541E01, 0px 2px 2px 0px #D17917 inset',
  bgSize: '100% 150%',
  backgroundPositionY: 'center',
  backgroundBlendMode: 'overlay',
  h: '100%',
  maxH: ['100dvh', null, '100%'],
  borderRadius: '2xl',
  border: '6px solid',
  borderColor: 'transparent',
};

export const LowStakePrompt: CSSObject = {
  color: 'white',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '2.5',
  mb: isKeypadVisible && 'unset',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'transparent ',
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'normal',
  color: 'white',
  fontFamily: 'CarterOne',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontSize: 'md',
  fontWeight: 'normal',
  color: 'beta.500',
  fontFamily: 'CarterOne',
});

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  h: '10',
  fontFamily: 'CarterOne',
  fontWeight: 'normal',
  sx: {
    ':hover': {
      color: 'whiteAlpha.700',
    },
  },
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  h: '6',
  borderRadius: 'full',
  fontWeight: 'black',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  minW: '16',
  px: '2',
  textShadow: 'none',
  gap: '2',
  color: 'blackAlpha.600',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '-1',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      bg: 'beta.400',
      color: 'blackAlpha.700',
    },
    '&&': {
      bg: 'beta.500',
      fontSize: '2xs',
      fontFamily: 'roboto',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
    },
  },
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  height: '10',
  fontFamily: 'CarterOne',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  sx: {
    ':hover': {
      color: 'whiteAlpha.700',
    },
  },
};

export const BetslipVStack: CSSObject = {
  gap: '2',
};
