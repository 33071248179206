import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textTransform: 'uppercase',
  fontSize: ['2xl', '2xl', '3xl'],
  fontWeight: 'normal',
  fontFamily: 'accent',
  textAlign: 'center',
  color: 'beta.800',
};
export const TextSecondary: CSSObject = {
  color: 'beta.800',
  textAlign: 'center',
  p: {
    w: '100%',
    fontWeight: 'normal',
    fontFamily: 'body',
    fontSize: 'md',
  },
};
export const ImageNoRaces: CSSObject = {
  w: ['100%', '70%'],
  borderRadius: 'lg',
  filter:
    'drop-shadow(0px 1px 0px 0px var(--bc-colors-whiteAlpha-600),0px 4px 6px 0px var(--bc-colors-blackAlpha-500) inset)',
  mb: '4',
};
