import { CSSObject } from '@chakra-ui/react';
import onboardingWelcome from '../../../assets/questbet/images/backgrounds/onboardingWelcome.png';
import onboardingMobile from '../../../assets/questbet/images/backgrounds/onboardingMobile.png';

export const TextTitle: CSSObject = {
  fontSize: ['lg', null, '28px'],
  fontWeight: 'semibold',
  lineHeight: ['18px', null, '7'],
  display: 'flex',
  mt: ['2.5', null, '8'],
  maxW: ['183px', null, 'unset'],
  ml: ['6', null, 'unset'],
  flex: ['1', null, 'unset'],
};

export const TextSubtitle: CSSObject = {
  fontSize: 'xs',
  lineHeight: '4',
  flex: '1',
  mt: ['unset', null, '3'],
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  mb: ['4', null, '8'],
  color: 'white',
};

export const TextCTALink: CSSObject = {
  color: 'alpha.200',
  fontWeight: 'bold',
};

export const FlexAsideInfo: CSSObject = {
  gridArea: '1/1',
  flexDir: 'column',
  alignItems: ['start', null, 'unset'],
  zIndex: 'overlay',
  m: ['unset', null, '10'],
  mt: ['2', null, '10'],
  color: 'black',
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  minH: ['336px', '682px'],
};

export const FlexCTAContainer: CSSObject = {
  justifyContent: 'center',
  alignSelf: ['center', null, 'unset'],
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    backgroundImage: `url(${onboardingMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '@media (min-width: 480px)': {
      background: `url('${onboardingWelcome}'), linear-gradient(180deg, #7B354F 0%, #7B354F 100%)`,
      backgroundPosition: 'top, bottom',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  },
};
