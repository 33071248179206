import {
  chakra,
  Box,
  IconButton,
  Flex,
  Text,
  CSSObject,
} from '@chakra-ui/react';
import paymentTick from '../../../../../../../../assets/core/icons/paymentTick.svg';
import paymentCross from '../../../../../../../../assets/core/icons/paymentX.svg';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './PaymentStateNotification.styles.imports';

type TOverrideName = 'BoxPaymentProcessing';

let styles: Partial<Record<TOverrideName, CSSObject>> = {};

// Get the theme-specific style props
try {
  const themeName = getThemeName();
  styles = styleImports[themeName];
} catch (err) {
  // Do nothing
}

export const FlexPaymentStateWrapper = chakra(Flex, {
  baseStyle: {
    bg: 'rgba(0,0,0,.8)',
    position: 'absolute',
    zIndex: 'tooltip',
    boxSize: 'full',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    flexDirection: 'column',
    borderRadius: 'md',
  },
});

export const FlexPaymentStateHeader = chakra(Flex, {
  baseStyle: {
    flexDirection: 'row',
    w: '100%',
    h: '28',
    justifyContent: 'end',
  },
});

export const FlexPaymentStateBody = chakra(Flex, {
  baseStyle: {
    boxSize: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4',
  },
});

export const FlexPaymentStateFooter = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    w: '100%',
    h: '28',
    color: 'white',
    pb: '4',
  },
});

export const BoxPaymentProcessing = chakra(Box, {
  baseStyle: {
    ...styles?.BoxPaymentProcessing,
  },
});

export const BoxPaymentSuccessful = chakra(Box, {
  baseStyle: {
    bgImage: `url(${paymentTick})`,
    bgSize: '100%',
    bgRepeat: 'no-repeat',
    border: 'none',
    boxSize: '40',
  },
});

export const BoxPaymentFailed = chakra(Box, {
  baseStyle: {
    bgImage: `url(${paymentCross})`,
    bgSize: '100%',
    bgRepeat: 'no-repeat',
    border: 'none',
    boxSize: '40',
  },
});

export const IconButtonClose = chakra(IconButton, {
  baseStyle: {
    bg: 'transparent',
    p: '10',

    _hover: {
      bg: 'transparent',
    },
  },
});

export const TextPaymentProcessing = chakra(Text, {
  baseStyle: {
    color: 'alpha.500',
    fontSize: 'lg',
    fontWeight: 'bold',
  },
});

export const TextPaymentSuccessful = chakra(Text, {
  baseStyle: {
    color: 'green.500',
    fontSize: 'lg',
    fontWeight: 'bold',
  },
});

export const TextPaymentFailed = chakra(Text, {
  baseStyle: {
    color: 'red.400',
    fontSize: 'lg',
    fontWeight: 'bold',
  },
});

export const TextPaymentFailedDesc = chakra(Text, {
  baseStyle: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
});

export const TextPaymentNotificationFooter = chakra(Text, {
  baseStyle: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
});
