/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */

import { create } from 'zustand';
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash';
import {
  EEventType,
  TMarket,
  TMatch,
  TPriceType,
  TProposition,
  TRace,
  TRunner,
  TVenue,
} from '@/lib/DBModels';
import {
  EBetSlipBetSubmissionType,
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
  EChannel,
  EEventRule,
  TBetSlipBet,
  TMultiLeg,
  TPromoToken,
} from '@/components/Betslip/Services/Betslip.types';
import {
  calculateExoticCombinations,
  validateBet,
} from '@/components/Betslip/Services/Betslip.utils';
import { DeepPartial } from '@/lib/types';
import { TExtendedProposition } from '@/views/sports/MatchDetailPage/services/MatchDetailPage.types';
import { TMysteryBetData } from '@/api/racing/mysteryBet/mysteryBet.types';
import {
  TBlendedMoMBet,
  TMultiMoMBet,
  TSGMultiMoMBet,
} from '@/components/Betslip/components/Modal/MoM/types';
import { isToteMulti } from '@/views/races/bets/Exotics/services/Exotics.utils';

/**
 * TODO:
 * Handle SP/BT/MD
 * Remove bet logic on card update
 *
 * Above types removed
 */

export type BetSlipStoreSchema = {
  bets: {
    [key: string]: FullBetSchema;
  } | null;
  // TODO: create type for MoM bet
  momBet: TMoMSchema | null;
  momError: string | null;
  actions: BetSlipStoreActionSchema;
};

export type BetSlipStoreActionSchema = {
  setBet: (payload: BetSchema) => void;
  updateBet: (
    payload:
      | { id: string; values: Partial<FullBetSchema> }
      | ((bets: BetSlipStoreSchema['bets']) => BetSlipStoreSchema['bets'])
  ) => void;
  removeBet: (
    payload:
      | string
      | string[]
      | ((bets: BetSlipStoreSchema['bets']) => BetSlipStoreSchema['bets'])
  ) => void;
  setMoMBet: (payload: TMoMSchema | null) => void;
  setMoMError: (payload: string | null) => void;
  updateMoMBet: (payload: Partial<TMoMSchema> | null) => void;
  clearBetSlip: () => void;
};

const useBetSlipStore = create<BetSlipStoreSchema>((set) => ({
  isBetSlipOpen: false,
  bets: null,
  momBet: null,
  momError: null,
  actions: {
    setBet: (bet) =>
      set((state) => {
        const stateBets = { ...(state.bets === null ? {} : state.bets) };

        const betKeysThatArePending = Object.keys(stateBets).filter(
          (key) =>
            !stateBets[key].confirmation ||
            stateBets[key].confirmation?.status ===
              EBetSubmissionConfirmationStatus.Pending
        );

        const bets = betKeysThatArePending.reduce(
          (acc, cur) => {
            acc[cur] = stateBets[cur];
            return acc;
          },
          {} as {
            [key: string]: FullBetSchema;
          }
        );

        if (bets[bet.id]) {
          delete bets[bet.id];
        } else {
          // TODO: Remove the uuid from here
          bets[bet.id] = { ...bet, requestId: uuid() };
        }

        return { bets };
      }),
    updateBet: (input) =>
      set((state) => {
        if (typeof input === 'function') {
          const newBets = input(state.bets);
          return { ...state, bets: newBets };
        }

        const bets = { ...state.bets };
        const bet = bets[input.id];

        // FIXME:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bets[input.id] = { ...bet, ...input.values };

        return { bets };
      }),
    removeBet: (input) =>
      set((state) => {
        if (typeof input === 'function') {
          const newBets = input(state.bets);
          return { ...state, bets: newBets };
        }

        const bets = { ...state.bets };

        if (Array.isArray(input)) {
          input.forEach((id) => {
            if (bets[id]) delete bets[id];
          });
        } else if (bets[input]) {
          delete bets[input];
        }

        return { bets };
      }),
    setMoMBet: (payload) => {
      set(() => ({
        momBet: payload,
      }));
    },
    clearBetSlip: () => {
      set(() => ({
        bets: {},
      }));
    },
    updateMoMBet: (payload) => {
      if (payload === null) {
        set(() => ({
          momBet: null,
        }));
      } else {
        set((state) => {
          if (payload.requestId === state.momBet?.requestId) {
            return {
              ...state.momBet,
              ...payload,
            };
          }
          if (state.momBet === null) {
            return {};
          }
          return {
            momBet: {
              ...state.momBet,
              ...payload,
            } as TMoMSchema,
          };
        });
      }
    },
    setMoMError: (payload) => {
      set(() => ({
        momError: payload,
      }));
    },
  },
}));

/** EXPORTS */
export const useBetSlipBets = () => useBetSlipStore(({ bets }) => bets);
export const useMoMBet = () => useBetSlipStore(({ momBet }) => momBet);
export const useMoMBetError = () => useBetSlipStore(({ momError }) => momError);

export const getBetSlipStoreActions = () => useBetSlipStore.getState().actions;

export const {
  setState: setBetSlipStoreState,
  getState: getBetSlipStoreState,
} = useBetSlipStore;
/** EXPORTS */

/** TYPES */
// TODO: Change case
type BetMiscSchema = {
  // Race
  potential_returns: number;
  event_icon: string;
  event_type: string;
  event_market_name: string;
  event_start_time: string;
  event_title: string;
  event_subtitle: string;
  race_type: string;
  race_meeting_date: string;
  race_number: number;
  start_time: string;
  venue_name: string;
  // venue_display_name: string; This doesn't actually exist its venue_name
  venue_id: string;
  barrier_number: number;
  meeting_date: string;
  runner_id: string;
  event_id: string;
  eventRule: string;
  race_runner_id: string;
  race_id: string;

  // Sport
  competition_id: string;
  match_name: string;
  competition_name: string;
  sport_name: string;
  sport_id: string;

  // TBC
  proposition_id: string;
  proposition_name: string;
  number: number;
  display_name: string;
  number_of_places: number;

  // WIP
  runner: TRunner;
  venue: TVenue;
  race: TRace;
  match: TMatch;
  proposition: TProposition;
  market: TMarket;

  sportTitle?: string; // TODO: better way to do this?
  subTitle?: string; // TODO: better way to do this?
};

export type TMoMSchema = TMultiMoMBet | TSGMultiMoMBet | TBlendedMoMBet;

type BetConfirmationSchema = {
  status: EBetSubmissionConfirmationStatus;
  promo_tokens: TPromoToken[];
};

type CommonSchema = {
  id: string;
  misc?: DeepPartial<BetMiscSchema>;
  confirmation?: BetConfirmationSchema & Record<string, unknown>;
  stake?: string;
};
type BetSchema = CommonSchema &
  (
    | SingleSchema
    | MultiSchema
    | SrmSchema
    | SgmSchema
    | ExoticsSchema
    | BlendedSchema
    | MysterySchema
    | ToteMultiSchema
  );

// TODO: this is unnecessary as we have CommonSchema
export type FullBetSchema = BetSchema & {
  requestId: string;
  stake?: string;
  isBonusBet?: boolean;
};

type SingleSchema = {
  type: 'Single';
  odds: number;
  propId: string;
  priceType?: TPriceType; // Race has priceType, sport does not.
  betType?: 'win' | 'place';
};

type TRollover = {
  race_id: string;
};

type MysterySchema = {
  type: 'Single';
  odds: number;
  betType?: 'win' | 'place';
  rollovers?: TRollover[];
  priceType?: TPriceType; // Race has priceType, sport does not.
  mystery_bet?: TMysteryBetData;
  event_market_name?: string;
};

type MultiSchema = {
  type: 'Multi';
  // legs: { propId: string; odds: number }[];
  legs: TMultiLeg[];
};
export type SrmSchema = {
  type: 'SRMulti';
  odds: number;
  raceId: string;
  // selections { propType: number }[][]
  selection: {
    runner_number: number;
    runner_name: string;
    barrier_number?: number;
  }[][];
};
export type SgmSchema = {
  type: 'SGMulti';
  odds: number;
  // legs: { propId: string }[]; // TODO: This is what we submit
  selections: TExtendedProposition[];
};
type ExoticsSchema = {
  type: 'Exotics';
  propId: string;
  selection: (number | undefined)[][];
  subType: string;
  stake?: string;
  raceNumbersForToteMulti?: string[];
};

type ToteMultiSchema = {
  type: 'ToteMulti';
  propId: string;
  selection: (number | undefined)[][];
  subType: string;
  stake?: string;
};
export type BlendedSchema = {
  type: 'Blended';
  // legs: { propId: string }[]; // TODO: This is what we submit
  selection: {
    runner_number: number;
    runner_name: string;
    barrier_number?: number;
    runnerId: string;
    propositionId: string;
    odds: number;
  }[];
  odds: number;
};

/** Keep As Is */
export type BetSubmitSchema = {
  channel: EChannel;
  is_bonus_bet: boolean;
  request_id: string;
  stake: number;
} & (
  | {
      type: EBetSlipBetSubmissionType.Single;
      odds: number;
      proposition_id: string;
      price_type?: string; // Only races have price_type
    }
  | {
      type: EBetSlipBetSubmissionType.Multi;
      legs: { proposition_id: string; odds: number }[];
    }
  | {
      type: EBetSlipBetSubmissionType.SGMulti;
      odds: number;
      legs: { proposition_id: string; odds: number }[];
    }
  | {
      type: EBetSlipBetSubmissionType.ComboMulti;
      odds: number;
      combos: {
        proposition_id: string;
        odds: number;
        field_size?: number;
        price_type?: string;
      }[];
    }
  | {
      type: EBetSlipBetSubmissionType.SRMulti;
      odds: number;
      race_id: string;
      selection: (number | undefined[])[];
    }
  | {
      type: EBetSlipBetSubmissionType.Exotics;
      proposition_id: string;
      selection: (number | undefined[])[];
      sub_type: string; // (exacta/quinella/etc)
    }
  | {
      type: EBetSlipBetSubmissionType.Blended;
      legs: { proposition_id: string }[];
      odds: number;
    }
  | {
      type: EBetSlipBetSubmissionType.ToteMulti;
      proposition_id: string;
      selection: number[][];
      sub_type: string;
    }
);

/**
 * TODO:
 * TEMP - will be moved out of this file & tidied up
 * */
export const transformBetForLegacy = (bet: FullBetSchema) => {
  const isSingle = bet.type === 'Single';
  const isSingleRace = isSingle && bet.priceType;
  const isSingleSport = isSingle && !bet.priceType;
  const isExotic = bet.type === 'Exotics';
  const isSRM = bet.type === 'SRMulti';
  const isBlended = bet.type === 'Blended';
  const isSGM = bet.type === 'SGMulti';
  const isEvenShot = isSingle && bet.priceType === 'even_shot';

  // Transform
  const transformedBet = {
    ...bet,
    proposition_id: isSingle || isExotic ? bet.propId : undefined,
    odds: isSingle || isSRM || isBlended || isSGM ? bet.odds : undefined,
    stake: bet.stake || '',
    potential_returns: 0,
    is_bonus_bet: bet.isBonusBet || false,
    request_id: bet.requestId,
    added_at: new Date().toISOString(),
    event_icon:
      isSingleSport || isSGM ? bet.misc?.sport_name : bet.misc?.race?.race_type,
    event_type: isSingleSport || isSGM ? EEventType.Match : EEventType.Race,
    event_market_name: isSingleRace
      ? (() => {
          type Schema = Record<'win' | 'place', Record<string, string>>;
          const nameMapping: Schema = {
            win: {
              starting: 'generic.winSP',
              tote_single_mid: 'generic.winTote',
              tote_single_best: 'generic.winTote',
              mystery_bet: 'generic.mysteryBet',
              default: 'generic.fixedWin',
            },
            place: {
              starting: 'generic.placeSP',
              tote_single_mid: 'generic.placeTote',
              tote_single_best: 'generic.placeTote',
              default: 'generic.fixedPlace',
            },
          };

          return bet?.betType
            ? nameMapping[bet?.betType ?? 'win']?.[
                bet.priceType ?? 'default'
              ] ?? nameMapping[bet?.betType ?? 'win'].default
            : nameMapping.win.default;
        })()
      : bet.misc?.sportTitle || bet.misc?.match?.competition_name,
    event_start_time:
      isSingleSport || isSGM
        ? bet.misc?.event_start_time
        : bet.misc?.race?.start_time,
    event_title:
      isSingleSport || isSGM
        ? bet.misc?.proposition_name
        : `${bet.misc?.runner?.number} ${bet.misc?.runner?.display_name}`,
    event_subtitle:
      isSingleSport || isSGM
        ? bet.misc?.match_name
        : `${bet.misc?.race?.venue_name} R${bet.misc?.race?.race_number}`,

    event_data:
      isSingleSport || isSGM
        ? {
            competition_id: bet.misc?.match?.competition_id,
            match_name: bet.misc?.match?.match_name,
            competition_name: bet.misc?.match?.competition_name,
            sport_id: bet.misc?.match?.sport_id,
          }
        : {
            race_meeting_date: bet.misc?.race?.meeting_date,
            race_number: bet.misc?.race?.race_number,
            venue_name: bet.misc?.race?.venue_name,
            venue_id: bet.misc?.race?.venue_id,
            barrier_number: bet.misc?.runner?.barrier_number,
          },

    ...(isSingleSport || isSGM
      ? {}
      : {
          race_type: bet.misc?.race?.race_type,
          meeting_date: bet.misc?.race?.meeting_date,
          runner_id: bet.misc?.runner?.race_runner_id,
          event_id: bet.misc?.race?.race_id,
          race_id: bet.misc?.race?.race_id,
          eventRule:
            bet.misc?.race?.number_of_places === 2
              ? EEventRule.TwoPlaceDividends
              : undefined,
          price_type: bet.type === 'Single' ? bet.priceType : undefined,
        }),

    ...(isExotic
      ? (() => {
          // TODO: Refactor: This is a copy and paste
          const isBoxed =
            !!bet.selection.length &&
            !bet.selection
              .map((place, index) => {
                if (index === 0) return;
                return isEqual(place, bet.selection[index - 1]);
              })
              .includes(false);

          const validSelectionAmount = calculateExoticCombinations(
            bet.selection as number[][],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            bet.subType as unknown as any
          ).length;
          // TODO: Refactor: This is a copy and paste

          const isToteMultiBet = isToteMulti(bet.subType as string);

          return {
            event_title:
              isBoxed && bet.subType !== 'Quinella' && !isToteMultiBet
                ? `Boxed ${bet.subType}`
                : bet.subType,
            sub_type: bet.subType,
            validSelectionAmount,
          };
        })()
      : {}),

    ...(isEvenShot
      ? {
          event_market_name: bet.misc?.sportTitle, // TODO: update naming
          event_title: bet.misc?.subTitle,
          event_subtitle: `${bet.misc?.race?.display_name} R${bet.misc?.race?.race_number}`,
        }
      : {}),

    confirmation: bet.confirmation,
  };
  // Transform

  // TODO: refactor - this comes from legacy code
  const validated = validateBet(transformedBet as unknown as TBetSlipBet);
  transformedBet.potential_returns = validated.potential_returns ?? 0;

  // console.log('bet.misc', bet.misc);
  return transformedBet;
};

/**
 * TODO: Move this to lib.
 *
 * This is used for the updateBet functionality - we want to update/remove
 * bets depending if they have been actioned of not.
 */
export const updateNonActionedBets = (store: BetSlipStoreSchema['bets']) => {
  const _bets = { ...store };
  const _ks = Object.keys(_bets);

  const nonActionedBets = _ks.reduce<FullBetSchema[]>((acc, cur) => {
    const _bet = _bets[cur];
    const { Pending } = EBetSubmissionConfirmationStatus;
    const { OddsChange, InsufficentFund, PropositionClosed } =
      EBetSubmissionRejectionReason;
    const { Multi } = EBetSlipBetSubmissionType;
    const isMulti = _bet.type === Multi;

    if (
      !_bet.confirmation ||
      _bet.confirmation.status === Pending ||
      [
        OddsChange,
        InsufficentFund,
        ...(isMulti ? [PropositionClosed] : []),
        // TODO: Correctly update types for confirmation
        // @ts-expect-error
      ].includes(_bet?.confirmation?.rejection_reason)
    ) {
      return [...acc, _bet];
    }

    return acc;
  }, []);

  _ks.forEach((k) => {
    const bet = _bets[k];
    const index = nonActionedBets.findIndex((bt) => bt.id === bet.id);

    if (index === -1) {
      delete _bets[k];
    } else if (_bets[k].type === 'Multi' && !bet.confirmation) {
      // Weirdness with the type not recognizing its a multi.
      // @ts-ignore
      _bets[k].legs = bet.legs?.filter((leg) =>
        nonActionedBets.some(
          (b) => (b.type === 'Single' && b.propId) === leg.propId
        )
      );
    }
  });

  return _bets;
};
