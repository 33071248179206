import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  color: 'white',
  bg: 'alpha.700',
  p: '2',
  '.chakra-checkbox__label': {
    color: 'white',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    border: '0',
    width: '1rem',
    height: '1rem',
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _hover: {
      bg: 'alpha.400',
    },
    _checked: {
      boxShadow: 'none',
      bg: 'alpha.500',
      color: 'white',
      _hover: {
        bg: 'alpha.400',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
};
