import { useState, useEffect, useCallback } from 'react';

/**
 * Hook to detect if window bottom has scrolled to bottom of element
 *
 * Usage:
 * const isAtBottom = useDetectScrollBottom({
    ref: elementRef,
    scrollOffset: -50, // Optional offset param - defaults to 0
  });
 */

export const useDetectScrollBottom = ({
  ref,
  scrollOffset = 0,
}: {
  ref: React.RefObject<HTMLElement>;
  scrollOffset?: number;
}) => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const { bottom } = ref.current.getBoundingClientRect();
      setIsAtBottom(bottom + scrollOffset === window.innerHeight);
    }
  }, [ref, scrollOffset]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return isAtBottom;
};
