import { CSSObject } from '@chakra-ui/react';
import { rgba } from 'polished';

export const ModalOverlay = (theme: any): CSSObject => ({
  bg: rgba(theme.colors.epsilon[600] as string, 0.7),
});

export const ModalHeader: CSSObject = {
  color: 'alpha.600',
  fontWeight: 'bold',
  pb: '2',
  px: '4',
};

export const ModalBody: CSSObject = {
  color: 'gray.600',
  fontSize: 'sm',
  pb: '4',
  pt: '1',
  px: '4',
};

export const ButtonDeposit: CSSObject = {
  // TODO: fix theme colours
  bg: '#426dd6',
  border: '2px',
  borderColor: 'alpha.700',
  borderRadius: 'lg',
  fontFamily: 'CinzelDecorative',
  fontSize: 'sm',
  fontWeight: 'black',
  h: '10',
  mt: '3',
  overflow: 'hidden',
  pos: 'relative',
  textTransform: 'none',
  zIndex: '1',

  // Pseudo-element for smooth gradient transition
  _before: {
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    borderRadius: 'calc(var(--bc-radii-lg) - 4px)',
    bottom: '2px',
    content: '""',
    left: '2px',
    pos: 'absolute',
    right: '2px',
    top: '2px',
    transition: 'inherit',
    zIndex: '-1',
  },

  _hover: {
    bg: 'alpha.700',
    borderColor: 'alpha.500',

    _before: {
      opacity: '0',
    },
  },

  _active: {
    bg: 'alpha.800',
    borderColor: 'alpha.500',

    _before: {
      opacity: '0',
    },
  },
};
