import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  alignSelf: 'center',
  mt: '0',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset',
  '.chakra-divider': {
    display: 'none',
  },
};

export const HeaderContainer: CSSObject = {
  pb: '2',
  pt: '4',
  px: '4',
};

export const HeaderText: CSSObject = {
  color: 'white',
  p: '0',
  textTransform: 'none',
};

export const CloseButton: CSSObject = {
  color: 'white',
  right: '1',
  top: '2',
  fontFamily: 'Bowlby One',
  boxShadow:
    '0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
};

export const ContentContainer: CSSObject = {
  color: 'white',
  pb: '4',
  pt: '1',
  px: '4',
  pl: '0',
};
