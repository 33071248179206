import { CSSObject, TextProps } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const closeIcon: TCloseIcon = {
  boxSize: '6',
  as: CloseCircle,
  color: 'white',
};

export const FlexBetslipHeading: CSSObject = {
  bg: 'rgba(26, 165, 255, 0.15)',
  h: '12',
  borderBottomColor: 'rgba(0, 0, 0, 0.20)',
  borderBottom: '1px',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.19)',
};

export const BetslipTitle: CSSObject = {
  color: 'beta.500',
  fontFamily: 'Spectral',
  fontWeight: 'extrabold',
  fontSize: 'lg',
  lineHeight: 1.3,
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  '& svg': {
    boxSize: '5',
    color: 'beta.500',
    filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))',
  },
};

export const BetslipTitleText: TextProps = {
  fontFamily: 'Spectral',
  fontSize: 'lg',
  mb: ['0', null, '0.5'],
};

export const BetLengthIndicator: CSSObject = {
  bg: 'beta.500',
  color: 'blackAlpha.700',
  fontSize: 'xs',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'gamma.700',
};
