import toast from 'react-hot-toast';
import { HTTPError } from './lib/api/types';

type TError = {
  error: HTTPError;
};

enum EStatus {
  noCookie = 400,
  noPermission = 403,
  expired = 401,
  rateLimit = 429,
}

export const useErrorHandler = () => {
  const errorHandler = ({ error }: TError) => {
    // eslint-disable-next-line prefer-destructuring
    if (!error.response) return;
    const status = error.response.status ?? EStatus.rateLimit;

    switch (status) {
      case EStatus.rateLimit:
        toast.error('Please wait a few moments and try again');
        break;
      default:
        break;
    }
  };

  return {
    errorHandler,
  };
};
