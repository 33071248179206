import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { EFilters, setFilter } from '../../../../../redux/ntjFilters.slice';

/**
 * Hook to handle the filters
 */
export const useFilters = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.ntjFilters);

  return [
    (name: EFilters, value: boolean) => {
      dispatch(setFilter({ [name]: value }));
    },
    { filters },
  ] as const;
};
