import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/betroyale/images/backgrounds/featureBg.png';
import activeBg from '@/assets/betroyale/images/backgrounds/buttonActiveBg.png';

export const FlexFeatureWrapper: CSSObject = {
  bg: `transparent`,
  py: '0',
  minH: ['620px', null, '450px'],
  px: 0,
  flexDir: ['column', null, 'row', 'row'],
  bgImage: ['unset', null, bg, bg],
  bgSize: 'contain',
  bgPosition: 'center center',
  bgRepeat: 'no-repeat',
};

export const FlexFeatureCardWrapper: CSSObject = {
  p: ['0', null, '5'],
  pt: ['0', null, 'unset', 'unset'],
};

export const FlexFeatureTitleWrapper: CSSObject = {
  justifyContent: ['flex-end', null, 'flex-start'],
  flexDir: ['column-reverse', null, 'column'],
  transformStyle: 'preserve-3d',
  pt: ['0', null, '4'],
  pos: 'relative',
  flex: '1.5',
  zIndex: '5',
  minH: ['252px', null, 'auto'],
  bgImage: [bg, null, 'unset', 'unset'],
  bgSize: 'cover',
  bgPosition: 'center center',
  bgRepeat: 'no-repeat',
};

export const ImageMascot: CSSObject = {
  pos: 'absolute',
  maxW: '75%',
  top: '16',
  clip: ['rect(0px,450px,190px,0px)', null, 'unset', 'unset'],
};
export const HeadingSlogan: CSSObject = {
  display: 'flex',
  ml: ['unset', null, '75%'],
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'extrabold',
  fontFamily: 'Spectral',
  textAlign: 'center',
  color: ['white', null, 'beta.500', 'beta.500'],
  textTransform: 'capitalize',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.40)',
  fontSize: ['40px', null, '40px'],
  lineHeight: '1',
  mt: ['4', null, '0', '0'],
};

export const SpanSlogan: CSSObject = {
  color: ['white', null, 'beta.500', 'beta.500'],
  ml: '2',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.40)',
};

export const TextFeatureStyledHeading: CSSObject = {
  color: 'white',
};

export const BoxCard: CSSObject = {
  mb: ['14', null, '0'],
  mt: ['0', null, '3'],
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px rgba(255, 255, 255, 0.10) inset',
  bg: 'rgba(26, 165, 255, 0.15)',
};

export const LinkCardHeader: CSSObject = {
  bg: 'rgba(26, 165, 255, 0.15)',
  color: 'beta.500',

  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'blackAlpha.800',
    borderRadius: 'sm',
    maxW: '10',
  },

  '.chakra-badge.state-prior': {
    bg: 'transparent',
    color: 'white',
    borderRadius: 'sm',
    maxW: '10',
  },

  '.chakra-badge.state-ended': {
    bg: 'alpha.500',
    color: 'white',
    borderRadius: 'sm',
    maxW: '10',
  },
};

export const IconCardHeader: CSSObject = {
  color: 'beta.500',
};

export const TextCardHeader: CSSObject = {
  fontWeight: 'extrabold',
  fontFamily: 'Spectral',
  fontSize: 'md',
};

export const TextRaceNumber: CSSObject = {
  bg: 'alpha.500',
  borderRadius: 'sm',
  color: 'white',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'blackAlpha.300',
  color: 'beta.500',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
  ':first-child': {
    pl: '3',
  },
};

export const FlexGridRunnerItem: CSSObject = {
  bg: 'rgba(26, 165, 255, 0.15)',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};

export const Button = (): CSSObject => ({
  '&:disabled': {
    bg: 'blackAlpha.200',
    color: 'gray.600',
    border: '2px solid',
    borderColor: 'gray.800',
    boxShadow: 'lg',
  },

  '&[data-active="true"]': {
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
    color: 'beta.500',
    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
    border: 'none',

    '&&': {
      bgImg: `url(${activeBg})`,
      bgRepeat: 'no-repeat',
      bgPosition: '0% 12%,18% 11%,90% 91%',
      bgSize: '100%',
    },
  },
});

export const LinkRaceDetails: CSSObject = {
  display: 'flex',
  color: 'beta.500',
  bgGradient: 'linear(to-b, alpha.600, alpha.700)',
  textTransform: 'uppercase',
  fontFamily: 'Spectral',
  fontSize: 'sm',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.30)',
  fontWeight: 'extrabold',
  letterSpacing: 'wide',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
  h: '10',

  _hover: {
    bg: 'alpha.600',
  },
};
