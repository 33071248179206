import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: ['center', null, null, null, 'flex-start'],
  px: ['8', null, null, null, '5'],
  color: 'white',
  textAlign: 'center',
  mx: 'auto',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'CrimsonPro',
  fontSize: '2xl',
  fontWeight: 'black',
  color: 'alpha.500',
  lineHeight: '101%',
  textTransform: 'uppercase',
  mt: '0',
};

export const BetSlipEmptyImage: CSSObject = {
  h: 'full',
  w: '48',
  mb: '-5',
  ml: '2.5',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'alpha.500',
  lineHeight: '4',
  mb: '2',
  px: '3',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};

export const BetSlipEmptyResponsibleGamblingWrapper: CSSObject = {
  mt: '4',
  mb: '-0.5',
  'div:last-child': {
    borderBottomRadius: ['none', null, null, null, 'base'],
  },
};
