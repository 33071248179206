import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useIntl } from 'react-intl';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

import PrimaryWrapper from '../../../wrappers/PrimaryWrapper';
import SportsListSection from '../components/ListSection';

import { AllSportsProps } from './types';
import { useAllSports } from './services/AllSports.hooks';
import { BoxPageWrapper } from './styles/AllSports.styles';
import Loading from '../components/Loading';
import Header from './components/Header/Header';
import Nav, { NavLink } from '../components/Nav/Nav';
import { sportNavLinks } from './services/AllSports.config';

const AllSports: React.FC<AllSportsProps> = ({ version }) => {
  const intl = useIntl();
  const { sportsGrouped, isInitialLoading } = useAllSports();

  return (
    <PrimaryWrapper
      pageTitle={intl.formatMessage({ id: 'sports.allsports.sportslisttitle' })}
      pageHeader={
        <Header
          title={intl.formatMessage({ id: 'sports.allsports.azheading' })}
        />
      }
    >
      <BoxPageWrapper>
        {version === 2 && (
          <Nav>
            {sportNavLinks.map((link) => (
              <NavLink
                as={ReactRouterNavLink}
                key={link.name}
                to={link.url}
                end
              >
                {intl.formatMessage({ id: link.name })}
              </NavLink>
            ))}
          </Nav>
        )}

        <AnimatePresence exitBeforeEnter>
          {(() => {
            if (isInitialLoading) return <Loading />;

            return sportsGrouped?.map((item, i) => (
              <motion.div
                key={item.letter}
                initial={{ translateY: -40, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  ...(i < 20 && { delay: i * 0.2 }),
                }}
              >
                <SportsListSection sportsGrouping={item} />
              </motion.div>
            ));
          })()}
        </AnimatePresence>
      </BoxPageWrapper>
    </PrimaryWrapper>
  );
};

export default AllSports;
