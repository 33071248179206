import { ButtonProps, CSSObject, ImgProps } from '@chakra-ui/react';
import bgMaint from '../../../assets/puntcity/images/backgrounds/maintenance.png';
import bgMaintMobile from '../../../assets/puntcity/images/backgrounds/maintenanceMobile.png';
import bgError from '../../../assets/puntcity/images/backgrounds/error.png';
import bgErrorMobile from '../../../assets/puntcity/images/backgrounds/errorMobile.png';

export const logoImgProps: ImgProps = {
  alignSelf: 'center',
  mt: ['24', null, '12'],
  h: ['120px', null, '80px'],
};

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
});

export const MaintenanceContainer = (isMaintenance: boolean): CSSObject => ({
  bg: isMaintenance
    ? [`url(${bgMaintMobile})`, null, `url(${bgMaint})`]
    : [`url(${bgErrorMobile})`, null, `url(${bgError})`],
  bgRepeat: 'no-repeat',
  bgPosition: ['center'],
  bgSize: ['474px 947px', null, 'cover'],
  borderRadius: [null, null, 'xl'],
  boxShadow: [null, null, '0 8px 30px rgba(0, 0, 0, .2)'],
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0 1 auto'],
  gap: '0',
  h: undefined,
  alignItems: 'center',
  justifyContent: 'start',
  maxW: 'container.md',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['null', null, '6'],
  pt: '12',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Sansita One',
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '95%',
  textTransform: 'capitalize',
  bgClip: 'text',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  pb: '3',
  mt: ['60', null, '0'],
  pr: '1',
};

export const ContentBodyText: CSSObject = {
  fontFamily: 'Montserrat',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  mb: '4',

  span: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'extrabold',
    mb: '0.5',
  },
};

export const TextDescription: CSSObject = {
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '18px',
  color: 'delta.500',
};

export const ButtonRefresh: CSSObject = {
  _hover: {
    bg: 'alpha.500',
  },
};

export const buttonRefreshProps: ButtonProps = {
  w: '80px',
  h: '40px',
  fontSize: '13px',
  fontWeight: '400',
};
