import { chakra, ModalHeader } from '@chakra-ui/react';
import { EThemes } from '@/constants/themeConfig';
import { Button } from '@/components/Button/Button';

export const ClearBetsModalHeader = chakra(ModalHeader, {
  baseStyle: ({ theme }) => ({
    textAlign: 'left',
    textTransform: 'uppercase',
    px: 0,
    ...(theme.themeName === EThemes.Wellbet && {
      color: 'beta.500',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'black',
    }),
  }),
});
export const ClearModalButton = chakra(Button, {
  baseStyle: () => ({
    width: '20',
  }),
});
