import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  px: '2',
  border: '1px',
  borderColor: 'gray.200',
  bg: 'gray.50',
  borderRadius: 'base',
  mb: '2',
  flex: '1',
};

export const FlexEventRule: CSSObject = {
  color: 'gray.500',
};

export const RunnerResultRow: CSSObject = {
  borderStyle: ['solid', null, 'dashed'],
  borderColor: 'blackAlpha.300',
  py: '1.5',
  h: '12',
};

export const RunnerDetails: CSSObject = {
  gap: '1',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bg: 'yellow.400',
  color: 'yellow.900',
  '&&': {
    h: '6',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'gray.500',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '30px',
  border: 'none',
  bg: 'blackAlpha.200',
  borderRadius: 'base',
  color: 'gray.600',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
};
