import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  wrapper: {
    mt: '3',
    mx: ['0', null, '-3'],
    bottom: ['15px', null, '0'],
    left: '0',
    position: ['fixed', null, 'sticky'],
    right: '0',
    zIndex: 'sticky',
  },
  slideBox: {
    bg: 'alpha.800',
    color: 'white',
    mb: '-4',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    borderBottomRadius: 'md',
    bottom: -8,
  },
  accordionItem: {
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
  },
  accordionHeaderText: {
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    fontSize: 'xs',
    fontWeight: 'bold',
    gap: '1',
    p: '2',
    color: 'alpha.700',
    _hover: {
      bgGradient: 'linear(to-b, beta.500, beta.500)',
    },
  },
  accordionButtonIcon: {
    fontSize: 'sm',
  },
  headerStack: {
    bg: 'alpha.900',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    py: '2',
  },
  headerText: {
    flex: '1',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
  },
  accordionPanel: {
    fontSize: 'xs',
    maxH: '32',
    overflow: 'auto',
    p: '0',
  },
  selectionFlex: {
    borderColor: 'rgba(0, 0, 0, 0.10)',
    gap: '2',
    p: '2',
    pos: 'relative',
    _notFirst: {
      borderTopStyle: 'dotted',
      borderTopWidth: '1px',
    },
    _before: {
      border: 'inherit',
      borderColor: 'whiteAlpha.300',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
  },
  footerStack: {
    bgGradient:
      'linear(to-b, rgba(194, 223, 255, 0.10) 0%, rgba(194, 223, 255, 0.00) 100%)',
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    py: '2.5',
    px: ['2', null, '4'],
    pos: 'relative',
    borderTopColor: 'blackAlpha.300',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    _before: {
      bgGradient: 'linear(to-b, rgba(0, 0, 0, 0.00), #000)',
      opacity: '0.2',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '4',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
  },
  legsText: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
  },
  legsChunks: {
    color: 'beta.400',
    fontSize: 'xl',
    fontWeight: 'black',
  },
  oddsText: {
    color: 'white',
    textTransform: 'uppercase',
    flex: '2',
    display: 'flex',
    fontWeight: 'extrabold',
    flexDirection: 'column',
    fontSize: ['xs', null, 'sm'],
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'beta.400',
    fontSize: 'xl',
    fontWeight: 'black',
  },
  clearButton: {
    variant: 'outline',
    mr: '2',
    px: '2.5',
    py: '1',
    borderRadius: 'lg',
    fontFamily: 'Bowlby One',
    fontSize: ['xs', null, 'sm'],
    h: 9,
  },
  addToSlipButton: {
    variant: 'primary',
    h: '9',
    fontSize: ['xs', null, 'sm'],
    sx: {
      '&&': {
        ml: ['1', null, '0'],
      },
    },
  },
};
