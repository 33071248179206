import React, { PropsWithChildren } from 'react';
import { Show, Flex } from '@chakra-ui/react';
import ButtonSwitch from '../components/ButtonSwitch/ButtonSwitch';
import { FlexWrapper } from '../styles/Toggle.styles';

const Wrapper = ({ children }: PropsWithChildren<any>) => (
  <Show below="md">
    <FlexWrapper>
      <Flex className="sub-wrapper">{children}</Flex>
    </FlexWrapper>
  </Show>
);

export const TemplateToggle = [
  { name: 'wrapper', component: <Wrapper /> },
  { name: 'buttonSwitch', component: <ButtonSwitch /> },
];

export default TemplateToggle;
