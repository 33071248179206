import React from 'react';
import { WrapperAside } from '../styles/Aside.styles';
import JoinNow from '../components/JoinNow/JoinNow';
import Slogan from '../components/Slogan/Slogan';

export const TemplateAside = [
  { name: 'wrapper', component: <WrapperAside /> },
  { name: 'slogan', component: <Slogan /> },
  { name: 'joinNow', component: <JoinNow /> },
];

export default TemplateAside;
