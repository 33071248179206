import { CSSObject } from '@chakra-ui/react';

export const TextLabel: CSSObject = {
  paddingLeft: '1px',
  color: undefined,
};

export const TextInfo: CSSObject = {
  bg: 'gray.50',
  borderColor: 'alpha.200',
  color: 'alpha.700',
  p: '2.5',
};
