import { CSSObject } from '@chakra-ui/react';
import bg from '../../../assets/puntgenie/images/backgrounds/bg.jpg';
import bgAtRetina from '../../../assets/puntgenie/images/backgrounds/bg@2x.jpg';

export const FlexPageWrapper = (): CSSObject => ({
  alignItems: 'center',
  color: 'white',
  h: undefined,
  justifyContent: 'center',
  minH: '100dvh',
  pos: 'relative',

  _before: {
    bg: `url(${bg}) center/cover no-repeat`,
    boxSize: 'full',
    content: '""',
    left: '0',
    opacity: '0.5',
    pos: 'absolute',
    top: '0',
    zIndex: '-1',

    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bg: `url(${bgAtRetina}) center/cover no-repeat`,
    },
  },

  _after: {
    bgGradient: 'linear(to-b, transparent, epsilon.700)',
    boxSize: 'full',
    content: '""',
    left: '0',
    pos: 'absolute',
    top: '0',
    zIndex: '-1',
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  display: 'block',
  flexDir: undefined,
  fontSize: 'sm',
  h: undefined,
  maxW: '60ch',
  mx: 'auto',
  p: '4',
  textAlign: 'center',
  w: 'full',
});

export const ContentHeadingText: CSSObject = {
  bgClip: 'text',
  bgGradient: 'linear-gradient(to-b, delta.400, delta.600)',
  fontFamily: 'CinzelDecorative',
  fontSize: ['3xl', null, '4xl'],
  fontWeight: 'black',
  lineHeight: 'none',
  mb: '2.5',
  textAlign: undefined,
  textFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

export const ContentBodyText: CSSObject = {
  fontSize: undefined,
  mb: '4',
  textAlign: undefined,

  span: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'extrabold',
  },
};

export const ButtonRefresh: CSSObject = {
  boxShadow: 'none',
  display: undefined,
  textShadow: 'none',

  _hover: {
    bg: 'whiteAlpha.50',
  },

  _active: {
    bg: 'whiteAlpha.100',
  },
};
