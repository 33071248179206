import { Box, ButtonProps, chakra, keyframes } from '@chakra-ui/react';
import { styleImports } from './TabBar.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

const wiggle = keyframes`
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-4deg);
  }
  40% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  60% {
    transform: rotate(3deg);
  }
  70% {
    transform: rotate(-2deg);
  }
  80% {
    transform: rotate(1deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const sgmUpDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-31px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const BoxWrapper = chakra(Box, {
  label: 'tabBar-BoxWrapper',
  baseStyle: ({ theme }) => ({
    overflowX: ['auto', null, 'visible'],
    display: 'flex',
    flexWrap: 'nowrap',
    mt: '5',
    mb: '-9',
    mx: '-4',
    '&::-webkit-scrollbar': { display: 'none' },

    ...(theme.themeName === EThemes.Betgalaxy && {
      mx: ['-2', '0'],
      mt: ['1', '2'],
      mb: '-5',
      h: '10',
      bg: 'white',
      gap: ['1', '2.5'],
    }),

    '.same-race-multi': {
      transition: 'all .50s',
      animation: `${wiggle} 1s linear`,
      animationDelay: '1s',
      animationIterationCount: 2,
      animationFillMode: 'forwards',
      zIndex: 2,
    },

    '.same-race-multi-wrapper': {
      transition: 'all .50s',
      animation: ['none', null, `${sgmUpDown} 1s ease-in-out`],
      animationDelay: '1s',
      animationIterationCount: 2,
      zIndex: 2,
    },

    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TabButtonWrapper = chakra(Box, {
  label: 'tabBar-TabButtonWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TabButtonWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const buttonTabBarProps: CustomButtonProps = {
  'data-testid': 'tabBar-buttonTabBarProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonTabBarProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonWinPlaceSingleProps: CustomButtonProps = {
  'data-testid': 'tabBar-buttonWinPlaceSingleProps',
  variant: 'tab',
  py: '0',
  border: 'none  !important',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonWinPlaceSingleProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonTabBarMeetingsProps: ButtonProps = {
  'data-testid': 'buttonTabBarMeetingsProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonTabBarMeetingsProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const tickForToteMulti = {
  'data-testid': 'tickForToteMulti',
  ...(() => {
    try {
      return styleImports[themeName]?.tickForToteMulti;
    } catch (err) {
      return {};
    }
  })(),
};
