import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  color: 'white',
  bg: 'rgba(26, 165, 255, 0.15)',
  mt: '2',
  mb: '2',
  boxShadow:
    '0px 2px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px rgba(255, 255, 255, 0.10) inset',
  px: '0.5',
};

export const BoxWrapper: CSSObject = {
  bg: 'transparent',
  h: '10',
  display: 'flex',
  alignItems: 'center',
};

export const ButtonViewMore: CSSObject = {
  display: 'flex',
  color: 'beta.500',
  bg: 'unset',
  pt: '3',
  bgGradient: 'linear(to-b, alpha.600, alpha.700)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  textTransform: 'uppercase',
  fontFamily: 'Spectral',
  fontSize: 'sm',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.30)',
  fontWeight: 'extrabold',
  letterSpacing: 'wide',
  alignItems: 'center',
  justifyContent: 'center',
  h: '10',
  my: '0.5',
  _hover: {
    bg: 'alpha.600',
  },
  _active: { bg: 'alpha.400' },
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontFamily: 'Spectral',
  lineHeight: 'normal',
  fontSize: 'md',
  fontWeight: 'bold',
};
