import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    px: '2',
    pt: '1',
    pb: '1.5',
    h: '9',
    mb: '4',
    display: 'flex',
    alignItems: 'center',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    sx: {
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    textDecoration: 'none',
    sx: {
      '&:first-of-type': {
        textDecoration: 'underline',
        textDecorationColor: 'blackAlpha.700',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'alpha.800',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'alpha.800',
};
