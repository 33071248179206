import { CSSObject } from '@chakra-ui/react';

export const GridItemBetslip: CSSObject = {
  h: 'calc(100vh - 144px)',
  '& > div[class*="-BetSlipModalContainer"]': {
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '100%',
  },
  position: 'relative',
};

export const ModalDrawerContent: CSSObject = {
  bg: 'transparent',
};
