import { CSSObject } from '@chakra-ui/react';

export const PageHeadingWrapper: CSSObject = {
  border: 'none',
};

export const TableWrapper: CSSObject = {
  bg: 'gray.200',
  gap: '1',
  mx: '-2',
  width: 'unset',
};

export const TransactionWrapper: CSSObject = {
  borderRadius: 'base',
  bg: 'white',
  borderBottom: 'none',
  mx: '2',
  p: '2',
  w: 'unset',
};
