import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@/components/FormElements/Checkbox';
import {
  CheckboxHelpText,
  SignupAcknowledgementButton,
} from '../styles/SignUp.styles';
import { getStrings } from '@/helpers/utils';
import Modal from './Modal';
import RGPolicy from '../../../helpCentre/RGPolicy/components/Policy';
import { BoxCheckboxContainer } from './Agreements.styles';
import { usePolicies } from '../../../../hooks/usePolicies';

export default function Agreements() {
  const { isOpen: isOpenRG, onClose: onCloseRG } = useDisclosure();
  const navigate = useNavigate();

  const {
    policyLinks: { termsAndConditions, responsibleGambling },
    openPDF,
  } = usePolicies();

  const [
    {
      Onboarding: { Step1 },
      Generic,
    },
  ] = getStrings();

  return (
    <>
      <Modal
        title={Generic.ResponsibleGambling}
        isOpen={isOpenRG}
        onClose={onCloseRG}
      >
        <RGPolicy />
      </Modal>

      <BoxCheckboxContainer>
        <Checkbox name="ageAcknowledgement" data-cy="signupTerms" size="lg">
          <CheckboxHelpText>
            {Step1.AgeAcknowledgement}{' '}
            <SignupAcknowledgementButton
              variant="unstyled"
              onClick={() => openPDF(termsAndConditions)}
            >
              {Generic.TermsConditions}
            </SignupAcknowledgementButton>
            ,{' '}
            <SignupAcknowledgementButton
              variant="unstyled"
              onClick={() => navigate('/help-centre/privacy-policy')}
            >
              {Generic.PrivacyPolicy}
            </SignupAcknowledgementButton>{' '}
            {Generic.And}{' '}
            <SignupAcknowledgementButton
              variant="unstyled"
              onClick={() => openPDF(responsibleGambling)}
            >
              {Generic.ResponsibleGambling}
            </SignupAcknowledgementButton>{' '}
            {Generic.Policy}
          </CheckboxHelpText>
        </Checkbox>

        <Checkbox name="promotionAcknowledgement" size="lg">
          <CheckboxHelpText>{Step1.PromotionAcknowledgement}</CheckboxHelpText>
        </Checkbox>
      </BoxCheckboxContainer>
    </>
  );
}
