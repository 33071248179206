export const Breadcrumb = {
  parts: ['link', 'item', 'separator'],
  baseStyle: {
    item: {
      color: 'whiteAlpha.500',
    },
    link: {
      color: 'white',
      textAlign: 'center',
      fontSize: '2xs',
      display: 'inline-block',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textDecoration: 'none',
      _last: { textDecoration: 'underline' },
      '&:hover': { textDecoration: 'none' },
    },
    separator: {
      color: 'blackAlpha.700',
      mx: '2',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
