import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'alpha.600',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontWeight: 500,
};

export const RunnerDescription: CSSObject = {
  color: 'gamma.100',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: 700,
};

export const HeaderSvg: CSSObject = {
  boxSize: '22px',
  color: 'alpha.600',
  alignSelf: 'flex-start',
  mr: '1',
};

export const BetMultiIcon: CSSObject = {
  bg: 'alpha.700',
  color: 'white',
  p: '0.5',
  boxSize: '22px',
  alignSelf: 'flex-start',
  mr: '1',
};

export const BetOdds: CSSObject = {
  color: 'alpha.600',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontWeight: 700,
};

export const TextBarrierNumber: CSSObject = {
  color: 'gamma.100',
  fontSize: '2xs',
  fontFamily: 'Roboto',
  fontWeight: 500,
  verticalAlign: 'middle',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  bg: () => {
    switch (status) {
      case 'won':
        return 'green.600';
      case 'lost':
        return 'gray.500';
      case 'voided':
        return 'gray.500';
      case 'cancelled':
        return 'gray.500';
      case 'pending':
        return 'alpha.600';
      default:
    }
  },
});

export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
};

export const headerRowStyles: HeaderRowSchema = {
  mysteryBetIcon: {
    fill: 'alpha.600',
  },
  headerRowSvg: {
    fill: '#424D59',
  },
};
