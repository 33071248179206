import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBetPropositionClosedProps: {},
  buttonStakeProps: {},
};

export const BetCardContainer = (): CSSObject => ({
  bg: 'linear-gradient(180deg, #263BF4 0%, #2534BF 100%)',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 2px 2px rgba(74, 93, 255, 0.60) inset, 0px 2px 9px 0px rgba(0, 0, 0, 0.35), 0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset',
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
};

export const ExoticsNumberContainer: CSSObject = {
  w: '6 ',
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'beta.500',
  fontFamily: 'KHInterference',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const EventRule: CSSObject = {
  color: 'gamma.900',
};

export const BetCardVStack: CSSObject = {
  pb: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'gray.600',
  px: '2',
  fontSize: '2xs',
  fontWeight: 'medium',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const buttonBonusBetsProps = (
  isBonusBet: boolean,
  isInReview: boolean
): CustomButtonProps => ({
  bgGradient: 'linear(to-b, alpha.800, alpha.800)',
  fontFamily: 'body',
  textTransform: 'capitalize',
  w: isInReview ? '100%' : 'auto',
  isDisabled: isInReview,
  textShadow: 'none',
  color: isBonusBet ? 'alpha.50' : 'gamma.400',
  borderRadius: 'md',
  fontSize: '2xs',
  fontWeight: 'medium',
  border: 'none',
  h: '6',
  px: '1.5',
  sx: {
    '&&': {
      minW: '20',
    },
  },
  _hover: {
    bgGradient: 'linear(to-b, alpha.800, alpha.800)',
    border: 'none',
    opacity: '1',
  },

  _disabled: {
    bg: 'blackAlpha.400',
    color: 'gamma.400',
    textShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.20)',
    fontSize: '2xs',
    fontWeight: 'medium',
    textTransform: 'uppercase',
    // TODO: Update to use token
    boxShadow: '0px 1px 0px 0px #4395D0',
  },
});

export const StakeCollapse: CSSObject = {
  '&&': {
    m: '0',
    mt: '2',
  },
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const BetPropositionClosedError: CSSObject = {
  bg: 'blackAlpha.700',
  borderRadius: 'base',
  position: 'absolute',
  top: '-2px',
  left: '-2px',
  w: 'calc(100% + 4px)',
  h: 'calc(100% + 4px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: 'docked',
};

export const BonusBetsButton = (isBonusBet: boolean) => ({
  bgGradient: isBonusBet
    ? 'linear(to-b, beta.900, beta.900)'
    : 'linear(to-b, beta.400, beta.500)',
  boxShadow: isBonusBet
    ? '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset'
    : '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
  color: isBonusBet ? 'white' : 'beta.900',
  borderRadius: 'md',
  border: isBonusBet ? '1px' : 'none',
  borderColor: 'beta.400',
  fontWeight: 'medium',
  fontSize: '2xs',
  h: '6',
  px: '1.5',
  textShadow: 'none',

  '&&': {
    minW: '20',
  },

  '&:hover, &:focus': {
    bgGradient: isBonusBet
      ? 'linear(to-b, beta.900, beta.900)'
      : 'linear(to-b, beta.400, beta.500)',
    boxShadow: 'none',
    border: isBonusBet ? '1px' : 'none',
    borderColor: 'beta.400',
  },

  _active: {
    boxShadow: 'none',
  },

  _disabled: {
    boxShadow: 'none',
    bg: 'blackAlpha.300',
    color: 'alpha.100',
    textTransform: 'uppercase',
    h: '7',
    opacity: '1',
    border: 'none',
    textShadow: 'none',
  },
});
