import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAccountTabOptionProps: CustomButtonProps = {
  color: 'gamma.900',
  textShadow: 'none',
  bgImage: 'none',
  boxShadow: 'none',

  sx: {
    '&[aria-selected="true"]': {
      color: 'white',
      textShadow: '0px 2px 3px rgba(0, 0, 0, 0.20)',
      bg: 'alpha.800',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.45)',
      _hover: {
        bg: 'alpha.600',
        color: 'white',
      },
    },

    '&&': {
      borderRadius: 'base',
    },
  },

  h: '5',
  fontWeight: 'bold',
  fontSize: '2xs',
  ml: '1',
  px: '2',
  py: '1.5',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',

  border: 'none',
  fontFamily: 'Roboto',
};

export const TabWrapper: CSSObject = {
  height: '21px',
};

export const TabContainer: CSSObject = {
  borderBottom: '0',
  mt: '4',
  px: '1.5',
  py: '2',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  borderRadius: 'md',
  borderShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
};
