import React from 'react';
import { ButtonProps } from '@chakra-ui/button';
import { getStrings } from '@/helpers/utils';
import {
  buttonDepositProps,
  IconSSL,
  TextSSL,
} from '@/views/account/Deposit/Components/styles/Form/Form.styles';
import { Button } from '@/components/Button/Button';

type SubmitBtnProps = {
  isLoading?: boolean;
  txt?: string;
} & ButtonProps;

export default function SubmitBtn({
  isLoading,
  txt,
  ...props
}: SubmitBtnProps) {
  const {
    Deposit: { Labels, Buttons },
  } = getStrings()[0].Account;

  return (
    <>
      <Button
        {...buttonDepositProps}
        data-cy="depositNowButton"
        type="submit"
        isLoading={isLoading}
        isFullWidth
        {...props}
      >
        {txt || Buttons.Deposit}
      </Button>

      <TextSSL>
        <IconSSL />
        {Labels.SecuredWithSSL}
      </TextSSL>
    </>
  );
}
