import { css } from 'styled-components';

// TODO: Remove
export const listContainer = css``;

// TODO: Remove
export const linkText = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-md);
  font-weight: 600;
`;

// TODO: Remove
export const rightArrow = css`
  height: 12px;
  width: 12px;
`;
