import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    w: '100%',
  },
  buttonCashOutDesc: {
    w: '100%',
    height: '29px',
    sx: {
      '.cashoutValue': {
        color: 'beta.500',
      },
      '&:active, &:hover': {
        '.cashoutValue': {
          color: 'beta.900',
        },
      },
    },
  },
  buttonCashOutAmount: {
    variant: 'primary',
    height: '29px',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    _hover: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
      color: 'beta.900',
    },
  },
  flexOddsBoostBanner: {
    bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
    color: 'alpha.700',
  },
  textOddsBoost: { color: 'alpha.700', fontFamily: 'Staatliches' },
  vstackBetsWrapper: {
    w: '100%',
    ml: 'inherit',
  },
  buttonGroupWrapper: {
    w: '100%',
  },
  iconCashOut: {
    fill: 'currentColor',
    mb: '2.5px',
  },
  cashoutText: {
    color: 'currentColor',
    lineHeight: 'normal',
  },
  cashoutValue: {
    lineHeight: 'normal',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  ml: '26px',
};

export const RaceTime: CSSObject = {
  color: 'whiteAlpha.700',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
  ml: '26px',
};

export const BonusBetCheckMark: CSSObject = {
  '&&': {
    display: 'none',
  },
};

export const BonusBetText: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'Staatliches',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  bg: 'alpha.800',
  borderRadius: 'md',
  boxShadow:
    '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  px: '2',
  pt: '1.5',
  pb: '1',
  lineHeight: 'normal',
};

export const BetReturn: CSSObject = {
  bgGradient: 'linear(to-b, #FFF 0%, #B2B2B2 100%)',
  color: 'alpha.700',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
  boxShadow: '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
};

export const BoxBetReturnValue: CSSObject = {
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
};

export const BoxBetReturnText: CSSObject = {
  pl: '1',
  py: '1',
};

export const iconLegProps: IconProps = {
  boxSize: '18px',
  color: 'orange.500',
  bg: 'white',
  borderColor: 'white',
};
