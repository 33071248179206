import { NavigationSchema } from './Navigation.styles';
import slamBorder from '@/assets/slambet/images/backgrounds/slamBorder.svg';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',
  },
  boxWrapperProps: {
    width: '100%',
  },
  boxNavigationWrapperProps: {
    borderRadius: 'lg',
    pos: 'relative',
    boxShadow: [
      'unset',
      null,
      null,
      '0px 0px 0px 4px #12171E inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
    ],
    sx: {
      '&:before': {
        content: ['none', null, '""'],
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
        border: '0px solid transparent',
        borderImage: `url(${slamBorder}) 50`,
        pointerEvents: 'none',
        borderImageWidth: '50px',
      },
    },
  },
  navigationBoxProps: {
    m: '0',
    borderBottom: ['unset', null, null, '1px solid'],
    boxShadow: [
      'unset',
      null,
      null,
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    ],
    borderBottomColor: ['unset', null, null, 'blackAlpha.500'],
  },
};
