import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';
import raceCardBg from '@/assets/titanbet/images/homeTheming/raceCardBg.png';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },
  FlexCard: {
    my: '1',
    px: 'px',
    border: '2px solid',
    borderColor: 'gamma.500',
    borderRadius: 'lg',
    bgImage: `url(${raceCardBg})`,
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  },

  LinkCardHeader: {
    bg: 'transparent',
  },

  TextCardHeading: {
    color: 'gamma.300',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    lineHeight: 'normal',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    pt: '0.5',
  },

  TextRaceNumber: {
    bg: 'gray.300',
    borderRadius: 'sm',
    color: 'gray.600',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    alignItems: 'self-end',
  },

  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },

  BoxGridHeaderItem: {
    bg: 'blackAlpha.500',
    color: 'gamma.300',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontFamily: 'body',
    px: '2',
    fontSize: '8px',
    boxShadow: '0px 1px 0px 0px #FFFFFF0D',
  },

  FlexGridRunnerItem: {
    position: 'relative',
    bg: 'transparent',
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.500',
    px: '2.5',
    _after: {
      content: `''`,
      position: 'absolute',
      width: 'auto',
      height: 'calc(100% + 2px)',
      inset: 0,
      borderBottom: '1px dashed',
      borderBottomColor: 'rgba(255, 255, 255, 0.07)',
      zIndex: 8,
    },
  },

  TextRunner: {
    fontSize: 'xs',
    color: 'gamma.300',
    fontWeight: 'bold',
  },

  TextRunnerNumber: {
    sx: {
      _last: {
        color: 'white',
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },

  TextScratched: {
    color: 'white',
  },

  LinkRaceDetails: {
    color: 'beta.800',
    bgGradient: 'linear(to-b, gamma.300, gamma.400)',
    border: '2px solid',
    borderColor: '#FFD69E',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #BD873E inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
    textTransform: 'capitalize',
    fontSize: 'sm',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 1px 4px rgba(255, 255, 255, 0.2)',
    lineHeight: '27px',
    borderRadius: 'md',
    transition: 'all .3s ease-in-out',
    h: '42px',
    _hover: {
      bgGradient: 'linear(to-b, yellow.600, yellow.600)',
    },
    m: 2.5,
  },

  buttonRaceCardOddsProps: {
    variant: 'solid',
    fontFamily: 'body',
    fontSize: 'xs',
    h: '8',
    w: '46px',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};

export const IconCardHeader: CSSObject = {
  color: 'gamma.300',
};
