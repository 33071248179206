import { chakra, Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './IncreaseAmountButton.styles.imports';
import { Button, CustomButtonProps } from '@/components/Button/Button';

import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const buttonIncreaseProps: CustomButtonProps = {
  'data-testid': 'buttonIncreaseProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonIncreaseProps;
    } catch (err) {
      return {};
    }
  })(),
};

/**
 * @deprecated Use `buttonIncreaseProps` instead and add your theme in ButtonIncreaseSwitched
 */
export const IncreaseButton = chakra(Button, {
  baseStyle: () => ({
    '& + &': {
      ml: '2',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.IncreaseButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IncreaseAmountWrapper = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    label: 'IncreaseAmountWrapper',
    flexDir: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: '4',
    ...(theme.themeName === EThemes.Wellbet && {
      mb: '0',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.IncreaseAmountWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IncreaseAmountButtonWrapper = styled.div`
  ${({ theme }) => theme.pages.withdrawFunds.increaseAmountButtonWrapper}
`;

export const IncreaseAmountButton = styled.button`
  cursor: pointer;
  ${({ theme }) => theme.pages.withdrawFunds.increaseAmountButton}
`;
