import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { homeLinkStyles } from './styles/HomeLinks.styles';
import HomeLinksForRace from '@/views/Home/links/components/HomeLinks/HomeLinksForRace';
import HomeLinksForSport from '@/views/Home/links/components/HomeLinks/SportHomeLinks/HomeLinksForSport';

/**
 * @constructor this component will show race links on race page and sport links on sport page
 */
export default function HomeLinksSplit() {
  const { data: offerings } = useQueryOfferings();
  const [searchParams] = useSearchParams();

  const { category } = {
    category: searchParams.get('category'),
  };
  const isRacing = !category || category === 'racing';

  return (
    <Flex {...homeLinkStyles.wrapper} data-isRacing={isRacing}>
      {searchParams?.get('category')?.toLowerCase() === 'sport'
        ? FEATURE_FLAGS.HAS_SPORTS_VIEW &&
          offerings?.offered_sports?.length && <HomeLinksForSport />
        : FEATURE_FLAGS.HAS_RACE_VIEW &&
          offerings?.offered_race_types?.length && <HomeLinksForRace />}
    </Flex>
  );
}
