import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FormBox: CSSObject = {
  mt: '1',
};

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  borderRadius: 'base',
  boxShadow: '0 3px 8px rgba(0, 0, 0, .2)',
  h: '7',
  p: '1',
  w: '12',
};

export const RowDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  borderStyle: 'dashed',
  opacity: '1',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  px: '1',
  py: '0.5',
};

export const IconButton: CSSObject = {
  // ! Ensure account.styles are overridden
  '&&': {
    border: '0',
    color: 'white',
    h: '8',
    minWidth: '8',
    ml: '2.5',

    _before: {
      bottom: '0',
      left: '0',
      right: '0',
      top: '0',
    },
  },
};

export const BoxInputContainer: CSSObject = {
  mb: ['1', null, '2'],

  '.chakra-form-control': {
    mb: '0',
  },
};

export const BoxCardsContainer: CSSObject = {
  mb: '2',
};

export const IncreaseAmountContainer: CSSObject = {
  mb: ['1', null, '2'],
};

export const TextSSL: CSSObject = {
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'delta.500',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'regular',
  lineHeight: '14.4px',
  color: 'white',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
};
