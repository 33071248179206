import {
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMatchDetails } from '../../services/MarketsByMatch.hooks';

export default function ModalRedirect() {
  const { isInitialLoading, data, error } = useMatchDetails({
    refetchInterval: 60 * 1000,
    retry: false,
  });
  const navigate = useNavigate();
  /**
   * TODO: Type errors globally
   */
  const errorResponse = (error as any)?.response;

  return (
    <Modal
      isOpen={(() => {
        if (!isInitialLoading && !data) return true;
        if (errorResponse?.status === 404) return true;
        return false;
      })()}
      onClose={() => undefined}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="sports.marketsbymatch.matchclosedTitle" />
        </ModalHeader>

        <ModalBody>
          <Center flexDirection="column">
            <Text mb={4}>
              <FormattedMessage
                id="sports.marketsbymatch.matchclosed"
                values={{ match: data?.match_name }}
              />
            </Text>
          </Center>
        </ModalBody>

        <ModalFooter>
          <Button onClick={() => navigate(-1)}>
            <FormattedMessage
              id="sports.marketsbymatch.nomarketsbutton"
              values={{ sport: ' have a punt!' }}
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
