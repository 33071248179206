import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'unstyled',
    fontFamily: 'body',
    border: '2px',
    borderColor: 'alpha.300',
    color: 'white',
    height: '5',
    borderRadius: 'base',
  },
};
export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'alpha.800',
  borderTop: '2px solid',
  borderTopColor: 'beta.300',
};
export const buttonKeysProps: CustomButtonProps = {
  fontWeight: 'normal',
  pt: '0.5',
  fontSize: 'lg',
  variant: 'secondary',
  height: '100%',
  sx: {
    svg: {
      pos: 'relative',
      bottom: '2px',
    },
  },
};
export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'primary',
  fontWeight: 'normal',
  pt: '0.5',
  fontSize: 'lg',
  height: '100%',
};
export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'primary',
  fontWeight: 'normal',
  pt: '0.5',
  fontSize: 'lg',
  height: '100%',
};
