import { rgba } from 'polished';

import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import rockBackground from '../../../assets/volcanobet/images/backgrounds/rock-bg.png';

import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'alpha.500',
      borderBottom: 'white',
      bottomBorderRadius: base.borderBottomRadius,
      background: {
        bgColor: 'transparent',
        bgImage: [`url(${rockBackground})`, null, null, 'none'],
        bgSize: 'cover',
        boxShadow: 'lg',
        borderRadius: [null, null, null, 'md'],
        alignSelf: [null, null, null, 'flex-start'],
        overflow: 'hidden',
        mb: [null, null, null, '3'],
        w: 'calc(100% - 24px)',
        ml: [null, null, null, '2'],
      },
      drawer: {
        ...base.drawer,
      },
      drawerBody: {
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              borderColor: 'transparent',
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
      },
      icon: {
        ...base.icon,
        boxSize: '22px',
        color: ['white', null, null, 'alpha.500'],
        mb: ['2px', null],
      },
      headerSX: {
        fontFamily: 'Caveman',
        fontWeight: 'normal',
        fontSize: 'xl',
        letterSpacing: 'widest',
        cursor: 'default',
        color: 'alpha.500',
        height: '45px',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
        px: '4',
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,

        fontSize: 'sm',
        fontWeight: 'semibold',
        lineHeight: '133%',
        height: '42px',
        color: 'white',
        filter: [
          'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2))',
          null,
          null,
          'none',
        ],
        transition: 'all, 0.2s linear',
        borderLeftWidth: '3px',
        borderLeftColor: 'transparent',

        borderBottomWidth: '1px',
        borderBottom: '1px dashed',
        borderBottomColor: ['transparent', null, null, 'blackAlpha.300'],
        p: '2',
        ml: ['0', null, null, '2'],

        zIndex: ['docked', null, null, 'auto'],
        position: ['relative', null, null, 'initial'],
        '~ .nested': {
          pl: '10',
        },

        ':hover': {
          borderLeftColor: 'delta.400',
          borderBottomColor: 'transparent',
          bg: ['delta.600'],
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },
        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: '2',
        },

        '> a, .dropDownIcon, a': {
          color: 'white',
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',
        color: ['white', null, 'red.700'],

        _hover: {
          '.dropDownInner': {
            bg: 'delta.600',
          },
        },

        '.dropDownInner': {
          bg: 'delta.600',
          color: 'white',
          borderLeftWidth: '3px',
          borderColor: 'delta.300',
        },

        '> a, .dropDownIcon, a': {
          color: 'white',
          fill: 'none',
        },

        '> .icon, .dropDownIcon': {
          color: 'white',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
