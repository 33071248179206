import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { TBetslipFooterRow } from './Modal.styles';

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  pt: 0,
  mb: isKeypadVisible && 10,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const FlexBetSlipModalContainer: CSSObject = {
  // Note: astronaut icon has been removed with a view to reintroducing it later as an animation.
  // Refer to previous commits to reuse conditional rendering logic where appropriate
  bg: ['beta.500', null, null, null, 'betaAlpha.300'],
  backgroundPosition: 'bottom, center',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: 'cover, cover',
  backgroundBlendMode: 'normal, normal',
  color: 'white',
  boxShadow: [
    'none',
    null,
    'inset 0px 1px 4px #3e4fb5, inset 0px 1px 1px #4053b8',
  ],
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'beta.700',
  zIndex: '3',
};

export const EditBetsButton: CSSObject = {
  bg: 'none',
  color: 'white',
  borderColor: 'gamma.100',

  '&:hover, &:active': {
    bg: 'gamma.100',
    color: 'white',
  },
};

export const ReuseSelectionsButton: CSSObject = {
  h: '10',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  color: 'white',
  borderRadius: 'full',
  _hover: {
    color: 'beta.500',
    bgGradient: 'linear(to-b, beta.100, beta.100)',
  },
};

export const BetSlipFooterMotion: CSSObject = {
  bg: 'beta.700',
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'end',
};

export const BetSlipFooterRowTitle: CSSObject = {
  color: 'beta.100',
  fontWeight: 'bold',
  fontSize: 'md',
};

export const BetSlipFooterRowValue = ({
  type,
}: TBetslipFooterRow): CSSObject => ({
  color: type === 'totalStake' ? 'white' : 'alpha.500',
  fontWeight: type === 'totalStake' ? 'medium' : 'bold',
  fontSize: 'md',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  h: '10',
  bgGradient: 'linear(to-b, yellow.200, yellow.500)',
  boxShadow:
    '0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.65)',
  color: 'blackAlpha.900',
  borderRadius: 'sm',
  sx: {
    _hover: {
      color: 'blackAlpha.900',
      bgGradient: 'linear(to-b, yellow.400, yellow.600)',
    },
    '&&': {
      fontSize: 'xs',
    },
  },
};

export const LowStakePrompt: CSSObject = {
  color: 'white',
};

export const buttonClearBetsProps: CustomButtonProps = {
  h: '6',
  w: '20',
  bgGradient: 'linear(to-b, yellow.200, yellow.500)',
  borderRadius: 'md',
  fontWeight: 'medium',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  minW: '16',
  px: '3',
  textShadow: 'none',
  gap: '2',
  boxShadow:
    '0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.65)',
  color: 'blackAlpha.900',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '-1',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    _hover: {
      color: 'blackAlpha.900',
      bgGradient: 'linear(to-b, yellow.400, yellow.600)',
    },
    '&&': {
      fontSize: '2xs',
      fontFamily: 'roboto',
    },
  },
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'secondary',
  h: '10',
  borderRadius: 'sm',
};
