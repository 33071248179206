import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

const authedButtonStyles: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  fontFamily: 'Fredoka',
  h: '44px',
  lineHeight: 'none',
  minW: '44px',
  pos: 'relative',
  textTransform: 'uppercase',
  w: undefined,
  fontSize: 'lg',
  fontWeight: 'bold',
  borderRadius: 'md',

  '&:hover, &:focus, &:active': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },

  '.chakra-text': {
    _first: {
      fontSize: 'xs',
    },

    _last: {
      fontSize: 'md',
      textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
    },
  },
};

const unauthedButtonStyles: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  fontFamily: 'Fredoka',
  h: '44px',
  lineHeight: 'none',
  minW: '44px',
  pos: 'relative',
  textTransform: 'uppercase',
  w: undefined,
  fontSize: 'sm',
  fontWeight: 'bold',
  borderRadius: 'md',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',

  '&:hover, &:focus, &:active': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },
};

export const FlexContainer = (): CSSObject => ({
  alignItems: 'center',
  pos: 'relative',
  pr: ['2.5', null, null, '0'],
});

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
};

export const ButtonGroup: CSSObject = {
  gap: '1',
  pt: '1',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  fontFamily: 'Fredoka',
  h: '44px',
  lineHeight: 'none',
  minW: '44px',
  pos: 'relative',
  textTransform: 'uppercase',
  w: undefined,
  fontSize: 'lg',
  fontWeight: 'bold',
  borderRadius: 'md',

  alignItems: 'flex-start',
  px: '2',
  sx: {
    '&&': {
      '&:hover, &:focus, &:active': {
        bgGradient: 'linear(to-b, alpha.100, alpha.100)',
        textShadow: 'none',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
        color: 'alpha.800',
      },

      _expanded: {
        borderRadius: 'base',
        boxShadow: '0px 3px 0px 0px #2e546f',
        bg: 'beta.900',
        border: '2px solid',
        borderColor: 'beta.700',
        borderBottom: 'none',
        borderBottomRadius: 'none',

        '.span-balance': {
          color: 'alpha.200',
          textShadow: 'none',
        },

        '.account-text': {
          color: 'gamma.300',
          textShadow: 'none',
          fontSize: 'md',
        },

        '.chakra-button__icon svg': {
          color: 'white',
          fontWeight: 'black',
        },

        '.span-bonus': {
          color: 'gamma.300',
        },

        _after: {
          bottom: 'auto',
          content: ['normal', null, null, null, '""'],
          h: '100%',
          left: 'auto',
        },
      },
    },

    '.span-bonus': {
      fontFamily: 'Roboto',
      color: 'alpha.800',
      fontWeight: 'black',
      fontSize: 'xs',
      mt: [null, null, null, null, '0.5'],
    },

    '.chakra-button__icon svg': {
      color: 'blackAlpha.600',
      fontWeight: 'black',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.25)',
    },

    _focus: {
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 7px rgba(0, 0, 0, 0.25)',
    },
  },
};

export const ButtonGroupBets: CSSObject = {
  gap: '0.5',
  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: '0',
  },
};

export const ButtonBets: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  fontFamily: 'Fredoka',
  h: '44px',
  w: '44px',
  lineHeight: 'none',
  borderRadius: 'md',
  flexDirection: 'column',

  '&&&': {
    borderColor: 'beta.400',
    borderRadius: 'md',
  },

  '.betslipBtn': {
    borderColor: 'beta.400',
    borderRadius: 'md',
  },

  '.btn-span': {
    gap: '0',
    flexDirection: 'column',
  },

  '.span-bet': {
    fontSize: 'xs',
    fontWeight: 'bold',
    textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  },

  '.span-bet-count': {
    fontSize: 'md',
    fontWeight: 'bold',
    textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  },

  ':hover, :active': {
    '&&': {
      bgGradient: 'linear(to-b, alpha.200, alpha.200)',
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
      borderColor: 'transparent',
    },
  },
};

export const ButtonBetSlip: CSSObject = {
  ...authedButtonStyles,
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  fontFamily: 'Fredoka',
  h: '44px',
  lineHeight: 'none',
  minW: '44px',
  pos: 'relative',
  textTransform: 'uppercase',
  w: undefined,
  fontSize: 'lg',
  fontWeight: 'bold',
  borderRadius: 'md',
  flexDirection: 'column',

  '&&&': {
    borderRadius: 'md',
  },

  '.btn-span': {
    flexDirection: 'column',
    gap: '0',
  },

  _hover: {
    '&&': {
      bg: 'alpha.200',
      borderColor: 'transparent',
    },
  },

  _active: {
    bg: 'alpha.500',
    boxShadow: '0 2px 0 #bc5a00',
  },
};

export const ButtonLogIn: CSSObject = {
  ...unauthedButtonStyles,
  border: 'none',
};

export const ButtonSignUp: CSSObject = unauthedButtonStyles;

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'white',
  boxSize: '8',
  w: '8',
  h: '8',
  '& svg': {
    h: '8',
    w: '7',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '0',
  pt: ['2', null],
};

export const TextButton: CSSObject = {
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
};
