export const FormLabel = {
  baseStyle: {
    mb: '2',
    fontSize: 'sm',
    color: 'black',
    fontWeight: 'medium',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
