import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading':
    'A titan is nothing without power',
  'sports.allsports.azheading': 'Sports',
  'maintenance.heading': `Restoring Order`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'error.heading': 'Echoes Of Errors!',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'onboarding.locationnotallowed.header': 'Wandering beyond borders',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing TitanBet from outside of Australia.',
};
