import { CSSObject } from '@chakra-ui/react';
import volleyball from '@/assets/forsport/images/volleyball.svg';

export const FlexWrapper: CSSObject = {
  borderRadius: '10px',
  backdropFilter: 'blur(4px)',
  h: '60px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4',
  mx: '2',
  mb: '2',
  order: '-1',
};

export const TextToggle = () => ({
  fontSize: 'xl',
  color: 'gamma.400',
  fontFamily: 'accent',
  w: '70px',
  pos: 'relative',
  top: '-3px',

  _first: { textAlign: 'right' },
});

export const Switcher = (): CSSObject => ({
  '.chakra-switch__track': {
    pos: 'relative',
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 2px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },

  '.chakra-switch__thumb': {
    bg: `url(${volleyball})`,
    bgSize: 'cover',
    boxSize: '50px',
    pos: 'absolute',
    inset: '0',
    bottom: '-1',
    my: 'auto',
    left: '-2.5',

    '&[data-checked]': { left: '-2.5' },
  },
});
