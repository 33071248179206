import React from 'react';
import { CSSObject, Icon, Stack, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';
import { SignUpSchema } from '@/views/onboarding/Signup/styles/SignUp.styles';

export const signUpStyles: SignUpSchema = {
  buttonSignUp: {
    variant: 'solid',
    bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
    boxShadow: '0px 0px 0px 2px rgba(19, 39, 75, 1) inset',
    borderRadius: 'md',
    fontSize: 'sm',
    fontFamily: 'heading',
    border: '2px solid',
    borderColor: 'beta.400',
    w: 'full',
    h: '10',
    py: 1,
    zIndex: '0',
    sx: {
      _hover: {
        bgGradient: 'linear(to-b, gamma.600, gamma.600)',
        borderRadius: 'md',
      },
      _after: {
        opacity: '0',
      },
      span: {
        zIndex: '0',
      },
    },
  },
};

export const SignUpDatePicker: CSSObject = {
  zIndex: 'popover',
  '.react-date-picker__calendar': {
    zIndex: '10',
  },
};

export const SignupAcknowledgementButton: CSSObject = {
  color: 'beta.400',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const SignupAcknowledgementLink: CSSObject = {
  color: 'beta.400',
  fontWeight: 'semibold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: 'alpha.400',
  p: '3',
  borderRadius: 'base',
  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-alpha-400) !important'
      : 'var(--bc-colors-alpha-400) !important',
  },
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active ? 'white' : 'alpha.700',
});

export const BoxManualAddressContainer: CSSObject = {
  color: 'black',
  fontSize: '2xs',
  fontWeight: 'medium',
  p: '0',
  lineHeight: 'unset',
  mt: '1.5',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  px: ['2', null, '4'],
  pt: ['2', null, '4'],
  pb: ['2', null, '0'],
  '#state-select': {
    h: '9',
    borderRadius: 'lg',
    borderWidth: '1px',
    borderStyle: ' solid',
    borderColor: 'gray.300',
    background: 'gray.50',
    display: 'flex',
    padding: '2.5',
    alignItems: 'center',
    gap: '1.5',
  },
};

export const SignUpInput: CSSObject = {
  '&.chakra-input': {
    h: '9',
    borderRadius: 'lg',
    borderWidth: '1px',
    borderStyle: ' solid',
    borderColor: 'gray.300',
    background: 'gray.50',
    display: 'flex',
    padding: '2.5',
    alignItems: 'center',
    boxShadow: 'none',
    color: 'gray.700',
    fontWeight: '400',
    gap: '1.5',
    '&&:hover, &&:focus': {
      bg: 'gray.100',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray.400',
    },
    '&&:focus': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'blue.300',

      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },
    '&::placeholder': { color: 'gray.400', fontWeight: 'normal' },
  },
};

export const CheckboxHelpText: CSSObject = {
  fontWeight: 'normal',
  color: 'gray.500',
  button: {
    h: 'unset',
  },
};

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);
