import { CSSObject, keyframes } from '@chakra-ui/react';
import toggle from '@/assets/sugarcastle/images/toggle/toggle.png';
import toggleBg from '@/assets/sugarcastle/images/toggle/toggleBgMobile.png';

export const FlexWrapper: CSSObject = {
  bg: `url(${toggleBg}) center no-repeat`,
  width: '100%',
  height: '101px',
  justifyContent: 'center',
  p: '2.5',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
  alignItems: 'center',
  zIndex: '3',
  bgSize: 'contain',
  mx: 'auto',
  mt: ['2', null, '0'],
  mb: 2,
};

export const TextToggle = (isActive: boolean): CSSObject => ({
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: isActive ? 'beta.300' : 'gamma.400',
  letterSpacing: '0.01rem',
  px: '3',
  zIndex: '5',
  fontSize: 'lg',
});

export const Switcher = (): CSSObject => ({
  '.chakra-switch__track': {
    bg: 'alpha.500',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.30) inset',
    w: '50px',
    zIndex: '5',
    pos: 'relative',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: '5',

    _before: {
      bgImg: `url(${toggle})`,
      height: '4rem',
      width: '4rem',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      bottom: '60px',
      left: '-3.5',
      right: '0',
      top: '-5',
      animation: `
                  ${keyframes`
                    from { transform: rotate(0deg) }
                    to { transform: rotate(-360deg) }
                  `} 0.5s ease-out`,
    },
  },

  _active: {
    '.chakra-switch__track': {
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    },
  },

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
      _before: {
        animation: `
                  ${keyframes`
                    from { transform: rotate(0deg); }
                    to { transform: rotate(360deg); }
                  `} 0.7s ease-out`,
      },
    },
  },
});
