import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'delta.500',
  fontSize: 'md',
  fontWeight: 'normal',
  fontFamily: 'Sansita One',
  textTransform: 'uppercase',
  letterSpacing: 'wide',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'blackAlpha.700',
  fontWeight: 'normal',
  minW: 'unset',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  borderWidth: '2px',
  borderColor: 'whiteAlpha.800',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  transition: 'all .3s ease-in-out',
  _hover: {
    opacity: '0.4',
    bgGradient: 'linear(to-b, gamma.600, gamma.600)',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
};
