export const Checkbox = {
  parts: ['container', 'icon', 'control', 'label'],
  baseStyle: {
    control: {
      bg: 'white',
      border: '1px',
      borderColor: 'gamma.500',
      _hover: {
        bg: 'gray.100',
        borderColor: 'gamma.600',
      },
      _checked: {
        '&&': {
          bg: 'white',
        },
        borderColor: 'gamma.500',
        color: 'gray.700',
        _hover: {
          bg: 'gray.100',
          borderColor: 'gamma.600',
        },
      },
    },
    container: {
      w: 'full',
      alignItems: 'flex-start',
    },
  },
  sizes: {
    lg: {
      label: { fontSize: 'sm' },
      icon: { fontSize: 'xs' },
    },
    md: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    sm: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    },
    xs: {
      label: { fontSize: 'xs' },
      icon: { fontSize: '3xs' },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
