import toggle from '@/assets/templebet/images/toggle/toggle.png';

export const FlexWrapper = {
  bg: 'linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%)',
  boxShadow:
    '0px 0px 0px 2.5px var(--bc-colors-beta-300) inset, 0px 0px 0px 3px var(--bc-colors-beta-100) inset, 0px 4px 0px 0px var(--bc-colors-beta-300)',
  borderRadius: 'xl',
  border: '4px solid',
  borderColor: 'beta.100',
  alignItems: 'center',
  height: '16',
  width: 'full',
  justifyContent: 'center',
  mx: 'auto',
  mb: '2',
  mt: '2',
  p: '1.5',
};
export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'accent',
  fontWeight: 'hairline',
  fontSize: '2xl',
  textTransform: 'uppercase',
  color: 'beta.800',
  opacity: isActive ? '1' : '.5',
  letterSpacing: 'normal',
  px: '3',
  pt: '1',
  pos: 'relative',
  zIndex: '3',
  alignSelf: 'center',
});
export const Switcher = () => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
  '--switch-track-width': '44px',
  '.chakra-switch__track': {
    bg: 'beta.800',
    boxShadow: 'inset 0 2px 2px var(--bc-colors-blackAlpha-300)',
    w: '58px',
    h: '30px',
    pos: 'relative',
    zIndex: '3',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: '3',

    _before: {
      bgImg: `url(${toggle})`,
      boxSize: '29.5px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '.3px',
      right: '0',
      top: '.2px',
    },
  },

  _active: {},

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
    },
    '.chakra-switch__track': {
      bg: 'beta.800',
    },
  },
});
