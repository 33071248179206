import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    '&:disabled': { bg: 'transparent' },

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px',
      borderTopColor: 'blackAlpha.200',
      p: '2',

      _first: { borderTop: 0 },
    }),
    ...(isSelected && {}),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'xs',

    _first: {
      'button:hover &': { color: 'gamma.200' },

      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },

    '&.return-amount': {
      bgImage: isSelected
        ? 'linear-gradient(180deg, var(--bc-colors-blackAlpha-500) 0%, var(--bc-colors-blackAlpha-500) 100%)'
        : 'linear-gradient(180deg, var(--bc-colors-gamma-300) 0%, var(--bc-colors-gamma-500) 100%)',
      color: isSelected ? 'gamma.300' : 'alpha.600',
      p: 2,
      w: 'full',
      borderRadius: 'base',
      fontFamily: 'Staatliches',
      fontWeight: 'normal',
      textShadow: isSelected
        ? 'unset'
        : 'rgba(255, 255, 255, 0.35) 0px 1px 0px',
      boxShadow: isSelected
        ? 'rgba(0, 0, 0, 0.35) 0px 2px 4px 0px inset, rgba(255, 255, 255, 0.15) 0px 1px 0px 0px'
        : 'rgba(0, 0, 0, 0.3) 0px 4px 10px 0px, rgba(255, 255, 255, 0.12) 0px 0px 0px 1px inset',
      border: 'none',

      transition: 'all, .3s ease-in-out',

      _hover: {
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
