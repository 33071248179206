import React from 'react';
import { StyledIcon } from '@styled-icons/styled-icon';
import { Box, Icon, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { homeLinkStyles } from '@/views/Home/links/components/HomeLinks/styles/HomeLinks.styles';

export type AdditionalLinkProps = {
  to: string;
  text?: string;
  svg?: StyledIcon;
};

export const AdditionalLink = ({ to, text, svg }: AdditionalLinkProps) => (
  <LinkBox
    key={to}
    {...homeLinkStyles.linkIconBox}
    {...homeLinkStyles.linkIconBoxOverride}
  >
    <LinkOverlay
      href={`${to}`}
      {...homeLinkStyles.linkOverlay}
      {...homeLinkStyles.linkOverlayOverride}
    >
      {text && (
        <Box
          {...homeLinkStyles.sportIcon}
          {...homeLinkStyles.sportIconOverride}
        >
          {text}
        </Box>
      )}
      {svg && (
        <Icon
          as={svg}
          {...homeLinkStyles.sportIcon}
          {...homeLinkStyles.sportIconOverride}
        />
      )}
    </LinkOverlay>
  </LinkBox>
);
