import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/junglebet/images/backgrounds/backgroundErrorState.png';

export const LocationNotAllowedContainer: CSSObject = {
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  minW: '100dvw',
  bgImage: `url(${background})`,
  bgRepeat: 'no-repeat',
  bgPosition: 'center',
  lineHeight: '4',
  bgSize: ['100% 100%', '100%'],
};

export const CenterWrapper: CSSObject = {
  bg: 'transparent',
  borderRadius: [null, null, 'xl'],
  color: 'white',
  flex: ['1', null, '0'],
  gap: '0',
  justifyContent: 'center',
  minH: [null, null, '668px'],
  maxW: 'container.md',
  px: ['4', null, '20'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  mt: '0',
};

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Grimblocks',
  fontSize: ['6xl', null, '8xl'],
  lineHeight: '1',
  mt: ['6', null, '0'],
  mb: ['2.5', null, '4'],
  fontWeight: 'normal',
  textTransform: 'uppercase',
  color: 'beta.500',
  w: ['386px', null, '750px'],
  filter:
    'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.35)) drop-shadow(0px 4px 0px #A17600)',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
};

export const ContentBodyText: CSSObject = {
  fontSize: ['md', 'lg'],
  lineHeight: 'tall',
  textAlign: 'center',
  filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.5))',

  b: {
    display: 'block',
    fontSize: 'md',
    lineHeight: 'base',
    mb: '4',
  },

  '.chakra-link': {
    fontSize: ['2xs', 'xs'],
    fontWeight: 'bold',
  },
};

export const ContactText: CSSObject = {
  '&&': {
    fontSize: ['sm', null, 'lg'],
  },
};

export const TextBodyCopy: CSSObject = {
  lineHeight: '5',
  fontSize: ['sm', null, 'lg'],
  mt: '4',
};

export const TextHeadingColor: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  bgClip: 'text',
  textShadow: 'none',
  display: 'flex',
};

export const RegistrationContent: CSSObject = {
  fontSize: ['xs', null],
  w: [null, null, '591px'],
  '.chakra-link': {
    fontSize: ['xs', null],
    fontWeight: 'bold',
  },
};
