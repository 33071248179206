import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: ['2', '4'],
  pb: '4',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, zeta.400 0%, zeta.600 100%)',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  border: 'unset',
};

export const TextTitle: CSSObject = {
  color: 'white',
};

export const TextName: CSSObject = {
  color: 'alpha.600',
};

export const LinkItem: CSSObject = {
  borderTopColor: 'alpha.500',
  borderBottom: 0,
  borderBottomColor: 'transparent',
  borderTop: '1px solid',
  _hover: {
    bg: 'blackAlpha.300',
  },
  '&:first-of-type': {
    textDecoration: 'none',
    borderTop: 0,
  },
};

export const IconSport: CSSObject = {
  color: 'alpha.600',
};

export const IconChevron: CSSObject = {
  boxSize: ['4', '5'],
  border: '1px solid',
  borderColor: 'alpha.600',
  borderRadius: 'full',
  p: '1',
  color: 'alpha.600',
  transition: 'inherit',
};
