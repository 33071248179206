import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { Stop } from '@styled-icons/octicons/Stop';

export const EstReturnsLabel: CSSObject = {
  bg: 'alpha.800',
  color: 'white',
  fontWeight: 'bold',
  height: '100%',
  alignItems: 'center',
  px: '1',
  width: 'auto',
};

export const PendingBetContainer: CSSObject = {
  bg: 'orange.300',
  borderRadius: 'base',
  w: 'full',
  color: 'orange.900',
  justifyContent: 'center',
};

export const BetApprovalText: CSSObject = {
  fontSize: 'xs',
};

export const BetPendingApprovalText: CSSObject = {
  fontSize: 'xs',
};

export const BetRejectedText: CSSObject = {
  fontSize: 'xs',
};

export const ApprovedBetContainer: CSSObject = {
  bg: 'alpha.200',
  color: 'alpha.800',
  borderRadius: 'base',
  w: 'full',
  justifyContent: 'center',
  boxShadow: 'none',
};

export const RejectedBetContainer: CSSObject = {
  bg: 'beta.400',
  color: 'beta.900',
  borderRadius: 'base',
  w: 'full',
  justifyContent: 'center',
  boxShadow: 'none',
};

export const FooterContainer: CSSObject = {
  bg: 'alpha.100',
  color: 'alpha.700',
  px: '0',
  h: '6',
  border: '1px',
  fontSize: '2xs',
  borderColor: 'alpha.800',
  overflow: 'hidden',
  borderRadius: 'base',
  boxShadow: 'inset 0px 0px 5px rgba(12, 30, 90, 0.6)',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
};

export const IconApprovedBet = () => (
  <Icon as={CheckCircle} boxSize="4" mr="2" />
);

export const IconRejectedBet = () => <Icon as={Stop} boxSize="4" mr="2" />;
