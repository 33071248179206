import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
  color: 'white',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const BackButton: CSSObject = {
  h: '6',
  minW: '6',
};

export const TextDescription: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'blackAlpha.400',
  borderStyle: 'solid',
  opacity: '1',
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
};

export const BankRadioWrapper: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  px: '0',
  py: '3',
};

export const BankRadio: CSSObject = {
  bg: 'red',
};

export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
  lineHeight: 'normal',
  mb: '1',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
  gap: '4',
  lineHeight: 'normal',
  color: 'white',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
};

export const buttonAddAccountProps: CustomButtonProps = {
  fontSize: 'xs',
  _after: {
    bg: 'transparent',
    border: '2px',
    borderColor: 'alpha.500',
    borderRadius: 'base',
    color: 'alpha.500',
    textShadow: 'none',

    _hover: {
      bg: 'alpha.50',
    },

    _active: {
      bg: 'alpha.100',
    },
  },
};

export const IncreaseAmountWrapper: CSSObject = {
  gap: '1',
  mb: '1',
};

export const ButtonIncrease: CSSObject = {
  bg: 'gamma.500',

  _hover: {
    bg: 'gamma.600',
  },

  _active: {
    bg: 'gamma.700',
  },
};

export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  ml: [null, null, '4'],
  mt: ['2', null, '0'],
  p: '0',
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

export const WithdrawalWrapper: CSSObject = {
  bg: 'gray.200',
  border: '0',
  boxShadow: 'none',
  color: 'gray.700',
  m: '0',
  p: '2.5',

  '& + &': {
    mt: '2',
  },
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  fontSize: 'xs',
  fontWeight: 'bold',
  py: '1.5',

  _first: {
    color: 'gray.500',
    fontSize: '2xs',
  },
};

export const WithdrawalStatus = (): CSSObject => ({
  fontSize: '2xs',
  px: '1',
  py: '0.5',
  textTransform: 'uppercase',
});

export const ButtonWithdrawalCardCancel: CSSObject = {
  fontSize: 'xs',
  p: undefined,
  _after: {
    bg: 'none',
    boxShadow: 'none',
  },
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
  border: '2px solid',
  borderColor: 'epsilon.500',
  borderRadius: 'md',
  color: 'epsilon.300',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  _hover: {
    bg: 'delta.300',
    color: 'delta.500',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    border: '2px solid',
    borderColor: 'epsilon.500',
  },
};

export const ButtonWithdrawal: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  color: 'epsilon.300',
  textShadow: 'none',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
  border: '2px solid',
  borderColor: 'epsilon.500',
  borderRadius: 'md',
  fontSize: 'sm',
  mt: '2',
  _after: {
    bg: 'none',
    boxShadow: 'none',
  },
  _hover: {
    bg: 'delta.300',
    color: 'delta.500',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px #83090D inset',
    border: '2px solid',
    borderColor: 'epsilon.500',
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '1',
  fontWeight: 'bold',
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'gray.500',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '&&': {
    '.chakra-input__left-addon': {
      bg: 'alpha.300',
      color: 'alpha.600',
      fontSize: 'sm',
      border: 'none',
      fontWeight: 'bold',
      borderLeftRadius: 'lg',
      px: '4',
    },
    '.chakra-input': {
      borderLeftRadius: 'none',
      bg: 'white',
      borderColor: 'gray.300',
      borderWidth: '1px',
      color: 'alpha.900',
      borderRightRadius: 'lg',
      borderLeft: 'none',
      _placeholder: {
        color: 'gray.700',
        fontWeight: 'bold',
      },
    },
  },
};

export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form-control': {
    '.chakra-input': {
      bg: 'gray.50',
      color: 'gray.700',
      _focus: {
        border: '1px',
        borderColor: 'gray.300',
      },
      _hover: {
        border: '1px',
        borderColor: 'gray.300',
      },
    },
    '.chakra-form__label': {
      color: 'white',
      fontSize: '13px',
      fontWeight: 'semibold',
      lineHeight: '133%',
    },
    '.chakra-form__helper-text': {
      color: 'white',
      fontSize: 'xs',
      fontWeight: 'medium',
    },
  },
};

export const InputWithdrawal: CSSObject = {
  p: '2',
  '.chakra-checkbox__control': {
    borderColor: 'gray.400',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '&&': {
    '.chakra-checkbox__control[data-checked]': {
      bg: 'white',
      borderColor: 'gray.700',
    },
  },
};
