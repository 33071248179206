/* eslint-disable import/no-mutable-exports */
import React from 'react';

import { TTemplate } from '@/helpers/createTemplate';
import { BetsPlacedVideo } from '@/components/Betslip/components/Modal/Placed/styles/Placed.styles';
import LazyLottie from './lazyLottie';

const lottiePromise = import(
  '../../../../../../assets/goldenrush/animations/betslipSuccess.json'
);

export const template = (): TTemplate => ({
  wrapper: <BetsPlacedVideo />,
  lottie: <LazyLottie lottieImportPromise={lottiePromise} />,
});

export default template;
