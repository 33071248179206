import { Image, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/forsport/images/logo-brand.svg';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

const ImageContainer = () => (
  <Link
    as={LinkDom}
    to="/"
    maxW={['45vw', '55vw']}
    alignSelf="center"
    mb="auto"
    mt={['4vw', '2vw']}
  >
    <Image src={logo} />
  </Link>
);

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: <ImageContainer />,
    config: {
      before: 'slogan',
    },
  },
]);
