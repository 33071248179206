import React from 'react';
import { Img } from '@chakra-ui/react';
import { Global } from '@emotion/core';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logo from '../../../assets/juicybet/images/logoLocationNotAllowed.svg';
import Heading from '../components/Heading';
import Body from '../components/Body';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: (
      <Img
        src={logo}
        alt={REACT_APP_THEME_NAME}
        h={['250px', null, '400px']}
        mb={['10', null, '0']}
      />
    ),
    heading: <Heading />,
    desc: <Body />,
    body: (
      <Global styles={{ body: { background: 'var(--bc-colors-gray-400)' } }} />
    ),
  },
};

export default template;
