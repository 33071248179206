import { CSSObject } from '@chakra-ui/react';

const baseFontFamily = 'body';
const baseFontWeight = 'bold';
const baseTextColor = 'gray.800';

export const GreenIDText: CSSObject = {
  color: 'white',
  mt: '2',
};

export const GreenIDContainer: CSSObject = {
  '#greenid-intro-content #greenid-intro-words .lead': {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'medium',
    fontSize: 'xs',
    color: 'white',
  },

  '#greenid-container .btn-primary': {
    fontSize: 'xs',
    fontFamily: 'heading',
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 'base',
    textColor: 'white',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
    width: 'auto',
    border: 'none',

    '&:focus': {
      backgroundColor: 'gamma.600',
    },
  },

  '#greenid-source-content': {
    border: 'none !important',
    bg: 'white',
    p: '2',
  },

  // Source title styles
  '#greenid-source-content #greenid-source-header #greenid-source-title #greenid-source-title-heading':
    {
      fontFamily: baseFontFamily,
      fontWeight: `${baseFontWeight} !important`,
      color: baseTextColor,
    },

  // Form label styles
  '#greenid-source-body .row .form-group .control-label': {
    fontFamily: baseFontFamily,
    fontWeight: baseFontWeight,
    color: baseTextColor,
  },

  // Help icon styles
  '#greenid-source-body #greenid-FieldsForm .help-block': {
    fontFamily: baseFontFamily,
    fontWeight: baseFontWeight,
    color: baseTextColor,
  },

  '#greenid-source-content #greenid-source-header #greenid-source-title .greenid-modal-launch':
    {
      px: 0,
    },

  '#greenid-FieldsForm .control-label, #greenid-FieldsForm .greenid-helpicon, #greenid-source-title #greenid-source-title-heading, #greenid-source-title .greenid-modal-launch, #greenid-source-body .control-label':
    {
      color: 'gray.800',
    },
};
