import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  _hover: {
    bg: 'blackAlpha.100',
  },
  '.chakra-badge.state-inprogress': {
    h: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 4px',
    borderRadius: 'base',
    background: 'alpha.800',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '2xs',
    fontStyle: 'normal',
    fontWeight: 'extrabold',
    lineHeight: '5',
  },

  '.chakra-badge.state-prior': {
    h: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 4px',
    borderRadius: '3px',
    background: 'alpha.300',
    color: 'alpha.800',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '2xs',
    fontStyle: 'normal',
    fontWeight: 'extrabold',
    lineHeight: '5',
  },

  '.chakra-badge.state-ended': {
    h: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 4px',
    borderRadius: 'base',
    background: 'alpha.300',
    color: 'alpha.800',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '2xs',
    fontStyle: 'normal',
    fontWeight: 'extrabold',
    lineHeight: '5',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  mx: ['2.5', '4'],
  borderRadius: 'md',
  mb: '4',
  border: '2px solid',
  borderColor: 'beta.600',
};

export const Icon: CSSObject = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  borderRadius: 'base',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  path: {
    fill: 'gamma.400',
  },
};

export const TextRace: CSSObject = {
  color: 'alpha.900',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'normal',
};

export const TextNumber: CSSObject = {
  display: 'flex',
  padding: '3px 4px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'base',
  bg: 'alpha.800',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '5',
};

export const BoxHeading: CSSObject = {
  display: 'none',
};

export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    bg: 'delta.600',
    color: 'gamma.700',
  },
};
