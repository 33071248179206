import { camelCase } from 'lodash';
import { TMatch } from '@/lib/DBModels';

export default function matchUrlResolver(match: TMatch) {
  const pathname = `/sports/${[
    match.sport_name ?? '',
    match.competition_name ?? '',
    match.match_name ?? '',
  ]
    .map(encodeURIComponent)
    .join('/')}`;

  const search = `?${(['sport_id', 'competition_id', 'match_id'] as const)
    .map((key) => `${camelCase(key)}=${match[key]}`)
    .join('&')}`;

  const matchUrl = pathname + search;
  return matchUrl;
}
