import React, { useMemo } from 'react';
import { Icon } from '@chakra-ui/react';
import { ChevronUp } from '@styled-icons/evaicons-solid';
import { ChevronUp as ChevronThinUp } from '@styled-icons/evil';
import { CheckboxCircle } from '@styled-icons/remix-fill/CheckboxCircle';

import { GroupIcon } from '@/components/Betslip/components/Modal/Group/styles/Group.styles';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg from '@/components/common/IconSvg';
import { EThemesOG } from '@/constants/themeConfig';

export type TOptionalIcon = JSX.Element | undefined;
const themeName = getThemeName();

export const useBonusBetIcon = (
  isBonusBet: boolean
): { bonusBetIcon: TOptionalIcon } => {
  const hideBonusIcon = [
    EThemesOG.Volcanobet,
    EThemesOG.Betprofessor,
    EThemesOG.Sterlingparker,
  ].includes(themeName);

  const bonusBetIcon = useMemo(() => {
    if (hideBonusIcon) {
      return undefined;
    }
    if (isBonusBet) {
      return <Icon boxSize="4" as={CheckboxCircle} />;
    }
    return <IconSvg name="bonusBetIcon" boxSize="3" />;
  }, [isBonusBet, hideBonusIcon]);

  return { bonusBetIcon };
};

export const useChevronIcon = (
  expanded: boolean
): { chevronIcon: TOptionalIcon } => {
  const useThinChevon = themeName === 'betprofessor';
  const icon = useMemo(
    () => (
      <GroupIcon
        as={useThinChevon ? ChevronThinUp : ChevronUp}
        expanded={expanded}
      />
    ),
    [expanded, useThinChevon]
  );
  return { chevronIcon: icon };
};
