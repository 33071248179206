import { InputProps } from '@chakra-ui/input';
import theme from '@chakra-ui/theme';

export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      bg: 'gray.100',
      color: 'black',
      caretColor: 'currentColor',
      border: '1px',
      borderColor: 'gray.200',

      '&::placeholder': { color: 'blackAlpha.500' },

      '&&': { '&:focus, &:active, &:hover': { bg: 'gray.200' } },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      color: 'black',
      '&&': { borderRightColor: 'blackAlpha.200' },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
    md: { addon: { borderRadius: 'sm' }, field: { borderRadius: 'sm' } },
  },
  variants: {
    unstyled: (props: InputProps) => {
      const { addon, field } = theme.components.Input.variants.outline(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          boxShadow: 'none',
          '&&': {
            color: 'black',
            bg: 'white',
            borderRadius: 'base',
            h: '40px',
            borderColor: 'white',
            pl: '2.5',
          },
          '&:focus, &:active, &:hover': {
            '&&': {
              bg: 'white',
              borderColor: 'white',
              color: 'black',
            },
          },
        },
      };
    },
  },
  defaultProps: {},
};
