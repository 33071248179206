import { CSSObject } from '@chakra-ui/react';

export const TextItem: CSSObject = {
  textTransform: 'capitalize',
  fontFamily: 'accentStaat',
  color: 'gamma.200',
  fontSize: 'sm',
  fontWeight: 'medium',
  lineHeight: 'normal',
  pb: '1',
};
