import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2xs',
    px: '2',
    borderRadius: 'md',
    sx: {
      '&&': {
        h: '24px',
      },
      '&[data-active="true"]': {
        bgGradient: 'linear(to-b, alpha.800, alpha.800)',
        color: 'alpha.300',
        boxShadow:
          '0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      },
    },
  },
}
export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'gray.700',
};