import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TextCheckbox: CSSObject = {
  color: 'white',
  mb: '0',
  mt: '0.5',
};

export const buttonCloseAccountProps: CustomButtonProps = {
  variant: 'secondary',
  lineHeight: 'normal',
};
