import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'unstyled',
    sx: {
      '&&': {
        fontWeight: 'bold',
        color: 'beta.500',
      },
      svg: {
        bg: 'beta.500',
        p: '0.5',
        color: 'blackAlpha.500',
        border: '1px solid',
        borderColor: 'beta.500',
        borderRadius: 'sm',
        height: '4',
        width: '4',
        _focus: {
          borderColor: 'transparent',
        },
      },
      _hover: {
        color: 'alpha.300',
        svg: {
          bg: 'alpha.300',
          borderColor: 'alpha.300',
        },
      },
      _active: {
        color: 'alpha.300',
        svg: {
          bg: 'alpha.300',
          borderColor: 'alpha.300',
        },
      },
    },
  },
};
