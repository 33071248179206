import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  color: 'white',
  h: 'full',
  fontSize: '2xs',
  px: '1.5',
  borderRadius: 'md',
  w: '28',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(2.5px)',

  _hover: {
    bgGradient: 'linear(to-b, alpha.200, alpha.200)',
    boxShadow:
      '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
    color: 'alpha.700',
  },

  '*': {
    transition: 'all .2s ease-in-out',
  },

  svg: {
    pos: 'relative',
    top: '1px',
  },

  '&[aria-expanded="true"]': {
    svg: {
      transform: 'rotate(180deg)',
    },
  },
};

export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(2.5px)',
  color: 'beta.100',
  zIndex: 'docked',
  h: 'full',
  borderRadius: 'md',
};

export const TextTitle: CSSObject = {
  color: 'beta.100',
};

export const CheckboxFilters: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'beta.100',
  },
};
