import React from 'react';
import { Global } from '@emotion/react';
import head from '@/assets/fiestabet/images/backgrounds/headerBg.png';
import pattern from '@/assets/fiestabet/images/backgrounds/bgPattern.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          background: `url(${head}), url(${pattern})`,
          backgroundPosition: 'center top, center',
          backgroundRepeat: 'no-repeat, repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: '100% 570px,225px 308px',
          '@media (max-width: 767px)': {
            background: `url(${pattern})`,
            backgroundSize: '325px 418px',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'repeat',
          },
          '@media (max-width: 480px)': {
            background: `url(${pattern})`,
            backgroundSize: '325px 418px',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'repeat',
          },
          '@media (max-width: 400px)': {
            background: `url(${pattern})`,
            backgroundSize: '325px 418px',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'repeat',
          },
        },
      }}
    />
  );
}
