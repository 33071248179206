import { CSSObject } from '@chakra-ui/react';

export const OddsChangedContainer = (
  isInFooter: boolean,
  direction: string
): CSSObject => ({
  display: isInFooter ? 'none' : 'flex',
  minH: '12',
  p: '2.5',
  mb: '1',
  mx: '2',
  alignItems: 'center',
  justifyItems: 'center',
  borderRadius: 'md',
  color: direction === 'increase' ? 'green.500' : 'gray.700',
  bg: 'white',
  svg: {
    color: direction === 'increase' ? 'green.500' : 'blue.500',
  },
});
