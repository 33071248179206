export const colors = {
  alpha: {
    100: '#4D4E4D',
    200: '#454645',
    300: '#3E3E3E',
    400: '#363636',
    500: '#2E2C2C',
    600: '#252121',
    700: '#242725',
    800: '#1A1D1B',
    900: '#101110',
  },
  beta: {
    100: '#A7FEFF',
    200: '#95FF50',
    300: '#0DFCFF',
    400: '#25FED6',
    500: '#7DFD2A',
    600: '#59BC18',
    700: '#38800A',
    800: '#2A6205',
    900: '#1D4502',
  },
  gamma: {
    100: '#FFFEE9',
    200: '#FFFCC5',
    300: '#FFF991',
    400: '#FFF550',
    500: '#F1E737',
    600: '#D4CA25',
    700: '#AEA50F',
    800: '#8C8409',
    900: '#6A6405',
  },
};
