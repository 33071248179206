import { NewMatchCardSchema } from '@/views/sports/components/NewMatchCard/styles/NewMatchCard.styles';

export const newMatchCardStyles: NewMatchCardSchema = {
  boxWrapper: {
    borderRadius: 'md',
    border: '1px',
    borderColor: 'brand.200',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    overflow: 'hidden',
  },
  bodyStack: {
    px: 2,
    py: 2.5,
    bg: 'brand.50',
  },
  titleHeading: {
    cursor: 'pointer',
    as: 'h5',
    fontFamily: 'Roboto',
    fontSize: 'sm',
    color: 'gray.700',
  },
  footerFlex: {
    as: 'footer',
    bg: 'brand.800',
    boxShadow:
      '0px -2px 0px 0px rgba(0, 0, 0, 0.15), 0px -1px 0px 0px rgba(255, 255, 255, 0.25)',
    fontSize: '2xs',
    fontWeight: 'semibold',
    px: '2',
    py: '1',
    alignItems: 'center',
    gap: '1',
  },
  footerStack: {
    direction: 'row',
    sx: {
      '.state-prior': { color: 'white', fontFamily: 'Roboto' },
    },
  },
  footerStackDivider: {
    height: '2.5',
    borderColor: '#ECC94B',
  },
  footerSportNameFlex: {
    align: 'center',
    gap: '1',
    maxW: '85%',
    w: 'fit-content',
    mr: '2',
  },
  footerSportNameIconSvg: {
    color: '#ECC94B',
    fontSize: 'sm',
  },
  footerCompetitionNameText: {
    sx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 'initial',
    },
  },
  footerCountDown: {
    pt: '0',

    sx: {
      '&&&.state-prior': {
        color: 'white',
        p: 0,
        lineHeight: 'initial',
        textTransform: 'uppercase',
        bg: 'none',
      },
    },
    sxWrapper: {
      mr: 'auto',
    },
  },
  footerDuotoneSgmLogo: {
    h: 'auto',
    primaryColor: '#ECC94B',
    secondaryColor: '#744210',
    w: '9',
  },
  footerMatchLink: {
    fontSize: '2xs',
    fontWeight: 'bold',
    alignItems: 'center',
    display: 'flex',
    lineHeight: 'initial',
  },
  footerMatchLinkIcon: {
    color: '#ECC94B',
    fontSize: 'xs',
  },
};
