import { chakra, Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { EThemes } from '@/constants/themeConfig';
import TextInputProps from './types';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './styles/TextInput.styles.imports';

const themeName = getThemeName();

export const StyledTextInput = styled.input<TextInputProps>`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.basePalette.white};
  padding: 0.5em 1em;
  border-radius: 0.2em;
  border: 1px solid ${({ theme }) => theme.borderStyles.tertiary};
  outline: none;

  ${({ theme }) => theme.components.textInput.textInput};

  ${({ styles: propStyles }) => propStyles};

  ${({ errorText, theme }) =>
    !!errorText && `${theme.components.textInput.error.state}`};

  &:disabled {
    cursor: not-allowed;

    ${({ theme }) => theme.components.textInput.disabled};
  }
`;

export const TextInputContainer = styled.div<{ noBottomMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: var(--bc-sizes-1);

  ${({ theme, noBottomMargin }) =>
    noBottomMargin
      ? theme.components.textInput.containerNoMargin
      : theme.components.textInput.container};
`;

export const TextInputWrapper = chakra(Flex, {
  baseStyle: () => ({
    position: 'relative',
    width: '100%',
    borderRadius: 'md',
    bg: 'gray.100',
    height: '33px',
    overflow: 'hidden',
    alignItems: 'center',
    ...(() => {
      try {
        return styleImports[themeName]?.TextInputWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextInputLabel = chakra('label', {
  baseStyle: ({ theme }) => ({
    mb: '1',

    ...(theme.themeName === EThemes.Wellbet && {
      fontSize: 'sm',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontSize: 'sm',
      fontWeight: 'semibold',
    }),
    ...(theme.themeName === EThemes.Betroyale && {
      fontSize: 'sm',
      fontWeight: 'semibold',
      color: 'gray.500',
    }),
  }),
});

export const TextInputErrorLabel = styled.span`
  margin: var(--bc-sizes-1) 0;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.components.textInput.error.label};
`;

export const TextInputErrorIcon = styled.div`
  display: flex;
  width: var(--bc-sizes-2);
  height: var(--bc-sizes-2);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: var(--bc-sizes-1);

  ${({ theme }) => theme.components.textInput.error.icon};
`;

export const HidePasswordLine = styled.div`
  border-radius: 5px;
  position: absolute;
  background: black;
  transform: rotate(20deg) translateY(-50%);
  top: 50%;
  right: var(--bc-sizes-2);
  height: 1.5px;
  width: 25px;
  pointer-events: none;

  ${({ theme }) => theme.components.textInput.hidePasswordLine};
`;

export const Prefix = styled.span`
  ${({ theme }) => theme.components.textInput.prefix};
`;
