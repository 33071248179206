import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  mx: '0',
  borderTop: 'none',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  px: '3',
  py: '3',
  borderRadius: 'lg',
  gap: '0',
  bg: 'beta.100',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 5px var(--bc-colors-beta-200) inset',
};

export const RacerName: CSSObject = {
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
  color: 'beta.800',
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'beta.800',
  fontSize: '2xs',
  position: 'relative',
  bottom: '3px',
};
