import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export const ButtonVerified = (status: EGreenIDStatus): CSSObject => ({
  h: '18px',
  border: '1px',
  borderColor: 'white',
  borderRadius: 'base',
  textTransform: 'capitalize',
  p: '1',
  fontSize: '2xs',
  display: 'flex',
  fontWeight: 'bold',
  boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
  color: 'white',
  bg: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'green.500';
      case EGreenIDStatus.PENDING:
        return 'yellow.500';
      default:
        return 'orange.500';
    }
  },
  _hover: {
    '&&&': {
      bg: () => {
        switch (status) {
          case EGreenIDStatus.VERIFIED:
          case EGreenIDStatus.VERIFIED_ADMIN:
          case EGreenIDStatus.VERIFIED_WITH_CHANGES:
            return 'green.600';
          case EGreenIDStatus.PENDING:
            return 'yellow.600';
          default:
            return 'orange.600';
        }
      },
      color: 'white',
    },
  },
  svg: { boxSize: '4' },
});
