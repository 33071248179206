/**
 * Feature flags that can be used across the whole site.
 *
 * If a feature is in active development but not ready to release, we can
 * scope it so that its only visible locally using `isInDevelopment` variable.
 *
 * Feature flags should be cleaned up as soon as the feature is complete.
 */
const { REACT_APP_IS_PROD, REACT_APP_HAS_NEW_BETSLIP, REACT_APP_MOM_ENABLED } =
  window.BETCLOUD_ENV;

const isInLocal = window.location.hostname.includes('localhost');
const isInDev = window.location.hostname === 'dev.betcloud.dev';
const isInQat = window.location.hostname === 'qat.betcloud.dev';
const isInPerf = window.location.hostname === 'perf.betcloud.dev';
const isInBat = window.location.hostname === 'bat.betcloud.dev';
const isStaging = window.location.hostname === 'staging.betcloud.dev';
const isHotfix = window.location.hostname === 'hotfix.betcloud.dev';
const isInStaging = [
  'staging.release.betcloud.dev',
  'staging.hotfix.betcloud.dev',
].includes(window.location.hostname);

export const FEATURE_FLAGS = {
  FOR_P2: false,
  HAS_EXOTICS: isInLocal,
  HAS_VOIDED: true,
  HAS_SPORTS_VIEW: true,
  HAS_RACE_VIEW: true,
  NOT_IN_PROD: REACT_APP_IS_PROD !== 'true',
  HAS_BONUS: true,
  IS_QAT: isInQat,
  IS_DEV: isInDev || isInLocal,
  IS_PERF: isInPerf,
  IS_BAT: isInBat,
  IS_IN_STAGING: isInStaging,
  IS_STAGING: isStaging,
  IS_HOTFIX: isHotfix,
  SGM_IS_ENABLED:
    isInLocal ||
    isInDev ||
    isInQat ||
    isInPerf ||
    isInStaging ||
    isStaging ||
    isHotfix ||
    window.location.hostname.includes('betprofessor.com.au') ||
    window.location.hostname.includes('volcanobet.com.au') ||
    window.location.hostname.includes('puntgenie.com.au') ||
    window.location.hostname.includes('sterlingparker.com.au') ||
    window.location.hostname.includes('junglebet.com.au') ||
    window.location.hostname.includes('puntcity.com.au') ||
    window.location.hostname.includes('fiestabet.com.au') ||
    window.location.hostname.includes('vikingbet.com.au') ||
    window.location.hostname.includes('goldenrush.com.au') ||
    window.location.hostname.includes('juicybet.com.au') ||
    window.location.hostname.includes('betroyale.com.au') ||
    window.location.hostname.includes('wellbet.com.au') ||
    window.location.hostname.includes('questbet.com.au') ||
    window.location.hostname.includes('betgalaxy.com.au'),
  SRM_IS_ENABLED: true,
  CASHOUT_ENABLED: isInLocal || isInDev || isInQat,
  HAS_NEW_PROMOS: true,
  MYSTERY_BET_IS_ENABLED: isInLocal || isInDev || isInQat,
  HAS_NEW_BS: (() => {
    const ls = window.localStorage.getItem('hasNewBetslip');
    if (ls) return !REACT_APP_IS_PROD && ls === 'true';
    return REACT_APP_HAS_NEW_BETSLIP === 'true';
  })(),
  REACT_APP_MOM_ENABLED: REACT_APP_MOM_ENABLED === 'true',
} as const;
