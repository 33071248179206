import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _first: {
    mt: '3',
  },

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const LinkItem: CSSObject = {
  color: 'white',
  bgGradient: 'linear(to-b, alpha.500, alpha.700)',
  border: 'none',
  h: '36px',
  mb: '1px',
  _last: { mb: '0' },

  _hover: { bgGradient: 'linear(to-b, alpha.600, alpha.700)' },
};

export const IconSport: CSSObject = {
  color: 'beta.400',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'white',
  transition: '.3s ease-in-out',

  _hover: {
    color: 'gray.200',
  },
};
