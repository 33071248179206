import { CSSObject } from '@chakra-ui/react';
import menuTile from '@/assets/vikingbet/images/backgrounds/texture-wood-1x.png';
import menuTile2x from '@/assets/vikingbet/images/backgrounds/texture-wood-2x.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
};

export const FlexBetSlipModalContainer: CSSObject = {
  borderRadius: ['none', null, null, null, 'xl'],
  contain: 'layout',
  maxH: ['100dvh', null, '100%'],
  maxW: ['full', null, null, null, '272px'],
};

export const BetSlipContainer: CSSObject = {
  h: '100%',
  position: 'relative',
  borderRadius: '1.2rem',
  borderY: '#C5C4C0 5px solid',
  borderX: '#C5C4C0 8px solid',
  boxShadow:
    '0px 0px 5px 5px rgba(0, 0, 0, 0.40) inset, 0px 5px 0px 0px #757472, 0px 10px 0px 0px #464646, 0px -4px 0px 0px #EDEDEC',
  bg: `url(${menuTile})`,
  bgRepeat: 'repeat-y',
  bgSize: '100% 100%',
  zIndex: '5',
  width: '100%',
  top: [null, null, null, null, 0],
  maxH: [null, null, null, null, `100%`],
  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bg: `url(${menuTile2x})`,
    bgSize: '100%',
  },
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '0',
  mt: '2',
  mx: '2',
  mb: isKeypadVisible && 'unset',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'transparent',
  color: 'white',
  borderBottomRadius: ['none', null, null, null, 'xl'],
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'light',
  color: 'zeta.400',
  fontFamily: 'SkranjiRegular',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  fontFamily: 'SkranjiRegular',
  color: 'delta.400',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
});

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'secondary',
  lineHeight: 'normal',
};

export const BetslipVStack: CSSObject = {
  spacing: '0',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'secondary',
  height: '12',
  w: '100%',
  fontFamily: 'SkranjiBold',
  lineHeight: 'normal',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  h: '6',
  textTransform: 'uppercase',
  opacity: '1',
  bg: '#3C2014',
  borderRadius: 'base',
  fontWeight: 'bold',
  margin: 'auto',
  mt: '-23px',
  position: 'relative',
  lineHeight: 'normal',
  color: 'delta.400',
  minW: '16',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 2px 0px 0px rgb(90, 68, 58)',
  px: '3',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&:hover, &:active': {
      bg: '#3C2014',
      boxShadow: 'none',
      color: 'white',
    },
    '&&': {
      fontSize: '2xs',
    },
  },
};
