import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';
import activeBg from '@/assets/sugarcastle/images/backgrounds/image-shine-1.png';

const primary: CSSObject = {
  color: '#FFE6B6',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  border: 'none',
  position: 'relative',
  boxShadow:
    '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 'sm',

  '&&&': {
    borderRadius: '7px',
    '& > span, & > svg': { zIndex: 9 },
  },

  _after: {
    content: `''`,
    position: 'absolute',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
    boxShadow: '0px 0px 0px 2px rgba(19, 39, 75, 1) inset',
    borderRadius: 'md',
    m: '2px',
    opacity: 1,
  },

  _disabled: {
    borderRadius: 'md',
    bg: 'gray.300',
    borderWidth: '2px',
    borderColor: 'white',
    color: 'blackAlpha.400',
    textShadow: 'none',
    boxShadow:
      '0px 0px 0px 3px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
    opacity: '1',
    _after: { opacity: 0 },
    pointerEvents: 'none',
  },

  '&[data-active="true"]': {
    bg: 'gamma.600',
    boxShadow:
      '0px 0px 0px 2px #d9bc8e inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36)',
    color: 'gamma.400',
    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    border: '2px',
    borderColor: 'gamma.700',

    '&&': {
      bgImg: `url(${activeBg}), url(${activeBg}), url(${activeBg})`,
      bgRepeat: 'no-repeat',
      bgPosition: '0% 12%,76% 76%,90% 91%',
      bgSize: '29%, 18%, 24%',
    },

    _after: {
      opacity: 0,
    },
  },

  _hover: {
    _after: {
      bg: 'gamma.600',
      borderRadius: 'md',
    },
  },

  _focus: {
    bgGradient: 'linear(180deg, #48659c 0%, #233455 100%)',
    boxShadow:
      '0px 0px 0px 2px #13274B inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md', h: '34px', lineHeight: '34px' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      px: '2.5',
      py: '2.5',
      color: 'beta.600',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
      border: 'none',
      position: 'relative',
      boxShadow:
        '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
      textTransform: 'uppercase',
      fontWeight: 'black',
      fontFamily: 'Fraunces',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.3)',
      _after: {
        content: `''`,
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        inset: 0,
        bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
        boxShadow: '0px 0px 0px 2px rgba(68, 51, 24, 1) inset',
        zIndex: 8,
        borderRadius: 'md',
        m: '2px',
        opacity: 1,
      },
      _hover: {
        _after: {
          bgGradient: 'linear(to-b, beta.500, beta.500)',
        },
      },
      _disabled: {
        pointerEvent: 'none',
        opacity: 0.4,
        boxShadow:
          '0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.35)',
        _after: {
          borderRadius: 'md',
          bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
        },
      },

      '& > span, & > svg': { zIndex: 9 },
    }),
    tertiary: {
      px: '2.5',
      py: '2.5',
      color: 'beta.400',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
      border: 'none',
      position: 'relative',
      boxShadow:
        '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontFamily: 'Fraunces',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.55)',
      _after: {
        content: `''`,
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        inset: 0,
        bgGradient: 'linear(to-b, gamma.500, gamma.700)',
        boxShadow: '0px 0px 0px 2px rgba(20, 19, 75, 1) inset',
        zIndex: 8,
        borderRadius: 'md',
        m: '2px',
        opacity: 1,
      },
      _hover: {
        _after: {
          bgGradient: 'linear(to-b, gamma.700, gamma.700)',
        },
      },
      ':active, &[data-active="true"]': {
        bgGradient: 'linear(to-b, gamma.700, gamma.700)',
        boxShadow:
          '0px 1px 0px 0px rgba(165, 191, 240, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.6) inset',
        color: '#9F9CFD',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        _after: {
          display: 'none',
        },
      },
      _disabled: {
        pointerEvent: 'none',
        opacity: 0.4,
        bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
        boxShadow:
          '0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.35)',
        _after: {
          borderRadius: 'md',
          bgGradient: 'linear(to-b, gamma.500, gamma.700)',
        },
      },

      '& > span, & > svg': { zIndex: 9 },
    },
    odds: {
      px: '2.5',
      py: '2.5',
      color: '#FFE6B6',
      borderRadius: 'md',
      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
      border: 'none',
      position: 'relative',
      boxShadow:
        '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontFamily: 'body',
      fontSize: 'sm',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      _after: {
        content: `''`,
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        inset: 0,
        bgGradient: 'linear(to-b, #89ADF1, alpha.600)',
        boxShadow: '0px 0px 0px 2px rgba(19, 39, 75, 1) inset',
        zIndex: 8,
        borderRadius: 'md',
        m: '2px',
        opacity: 1,
      },
      _hover: {
        _after: {
          bgGradient: 'linear(to-b, gamma.600, gamma.600)',
        },
      },
      ':active, &[data-active="true"]': {
        bg: 'gamma.600',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.44), 0px 0px 0px 2px rgba(217, 188, 143, 1) inset, 0px 2px 2px 0px rgba(255, 255, 255, 0.36),  0px -1px 0px 0px rgba(255, 255, 255, 0.44), 0px -2px 2px 0px rgba(255, 255, 255, 0.36)',
        color: 'gamma.400',
        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
        fontWeight: 'bold',
        border: '2px',
        borderColor: 'gamma.700',

        '&&': {
          bgImg: `url(${activeBg}), url(${activeBg}), url(${activeBg})`,
          bgRepeat: 'no-repeat',
          bgPosition: '0% 12%,76% 76%,90% 91%',
          bgSize: '29%, 18%, 24%',
        },

        _after: {
          display: 'none',
        },
      },
      _disabled: {
        opacity: 1,
        bgGradient: 'linear(to-b, gray.300, gray.300)',
        color: 'blackAlpha.400',
        border: '2px',
        borderColor: 'white',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.44), 0px 2px 2px 0px rgba(0, 0, 0, 0.36)',
        textShadow: 'none',
        _after: {
          display: 'none',
        },
      },

      '& > span, & > svg': { zIndex: 9 },
    },
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderRadius: 'md',
      border: '2px',
      borderColor: 'white',
      color: 'white',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.4)',
      fontWeight: 'bold',

      '&:active, &:hover': {
        bg: 'rgba(255, 255, 255, 0.16)',
      },
    }),
    tab: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),

      bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
      border: 'none',
      boxShadow:
        '0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
      borderRadius: 'base',
      position: 'relative',
      textTransform: 'uppercase',
      textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
      h: '6',
      px: '2',
      py: '3',
      color: 'beta.200',
      textAlign: 'center',
      fontFamily: 'roboto',
      fontWeight: 'bold',
      fontSize: 'sm',
      minW: 'fit-content',
      zIndex: 8,
      _after: {
        content: `''`,
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        inset: 0,
        bgGradient: 'linear(to-b, gamma.500, gamma.700)',
        boxShadow: '0px 0px 0px 2px rgba(20, 19, 75, 1) inset',
        zIndex: -1,
        borderRadius: 'base',
        m: '2px',
      },
      '&:hover, &:focus': {
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.5)',
        _after: {
          boxShadow: '0px 0px 0px 2px rgba(19, 39, 75, 1) inset',
          bgGradient: 'linear(to-b, gamma.700, gamma.700)',
        },
      },
      '&[data-closed="true"]': {
        bg: 'gray.300',
        boxShadow:
          '0px 0px 0px 3px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.34), 0px 2px 2px 0px rgba(0, 0, 0, 0.36)',
        border: '2px',
        borderColor: 'white',
        color: 'blackAlpha.400',
        textShadow: 'none',
        _after: {
          display: 'none',
        },
      },
      '&:active, &[data-active="true"]': {
        bg: 'gamma.700',
        color: '#9F9CFD',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border: 'none',
        boxShadow:
          '0px 1px 0px 0px rgba(165, 191, 240, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.6) inset',
        _after: {
          display: 'none',
        },
      },
      '&[data-closed="true"][data-active="true"]': {
        bg: 'gray.300',
        boxShadow:
          '0px 1px 6px 0px rgba(0, 0, 0, 0.6) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.8) inset',
        color: 'blackAlpha.400',
        after: {
          display: 'none',
        },
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',

      '&&': {
        '&:disabled': {
          bg: 'beta.500',
          color: 'white',
        },
      },
    }),
    ghost: {
      ...primary,
      bg: undefined,
      boxShadow: undefined,
      textShadow: undefined,

      _hover: {
        bg: 'alpha.50',
      },

      _focus: undefined,

      _active: {
        bg: 'alpha.100',
      },
    },
    filter: {
      border: '2px',
      borderColor: 'gamma.400',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '6',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',
      _hover: {
        color: 'beta.300',
      },
      _active: {
        bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
        border: 'none',
        color: 'beta.600',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        bgGradient: 'linear(to-b, beta.300, beta.500)',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'alpha.600',
      },
    },
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      ':active, &[data-active="true"]': {
        color: 'beta.400',
        fontWeight: 'normal',
        borderBottomColor: 'beta.400',
        _hover: {
          '&&': {
            border: 'none',
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
