import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'md',
  my: '2',
  p: '2',
  color: 'white',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'beta.500',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  mb: '7',
  p: '2',
  gap: '3',
  borderRadius: 'base',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 6px 0px rgba(0, 0, 0, 0.20) inset',
  bg: 'rgba(0, 0, 0, 0.20)',
  color: 'white',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
  '.chakra-input__group': {
    mb: '0',
  },
};

export const EventTitle: CSSObject = {
  color: 'beta.500',
};
