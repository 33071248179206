import { OnRaceBetsSchema } from './OnRaceBets.styles';

export const onRaceBetStyles: OnRaceBetsSchema = {
  betsAndViewMoreButtonWrapper: {
    borderBottomRadius: 'sm',
    p: 3,
  },
  betsContainer: { flexDirection: 'column' },
  wrapper: {
    border: 'none',
    mb: '2',
    bg: 'white',
    borderRadius: 'base',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    bgGradient: 'linear(to-b, alpha.500, alpha.700)',
    borderTopRadius: 'base',
    py: 2,
    px: 3,
    color: 'white',
    zIndex: 2,
  },
  headerText: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'lowercase',
    sx: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  },
  headerCountText: {
    ml: '1',
    fontSize: 'base',
    fontWeight: 'semibold',
    color: 'white',
  },
  headerExpandButton: {
    variant: 'outline',
    ml: 'auto',
    boxSize: '6',
    minW: 6,
    p: 0,
    border: 'none',
  },
  toggleIcon: {
    boxSize: '6',
    color: 'white',
  },
  betCategoryWrapper: {
    alignItems: 'center',
    mt: '2',
  },
  betCategory: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'alpha.700',
  },
  betCategoryCount: {
    fontSize: 'xs',
    fontWeight: 'semibold',
    color: 'alpha.700',
    ml: '1',
  },
  divider: {
    borderWidth: 'px',
    opacity: '1',
    borderColor: 'blackAlpha.400',
    mt: '1.5',
  },
  betRow: {
    bg: 'alpha.50',
    p: 2,
    borderRadius: 'base',
    gap: '1',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  },
  betRowDivider: {
    bg: 'alpha.50',
    p: 2,
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  },
  groupDivider: {
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.400',
  },
  categoryIcon: {
    color: 'alpha.500',
    boxSize: '6',
  },
  groupContainer: {
    flexDir: 'column',
    py: 2,
    borderBottomWidth: '0px',
    gap: 1,
  },
  groupTitleText: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  runnerText: {
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 'extrabold',
    textTransform: 'capitalize',
    pb: '0.5',
  },
  runnerBarrierText: {
    color: 'gray.700',
    fontSize: 'xs',
  },
  stakeText: {
    color: 'alpha.500',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  potentialWinningsText: {
    color: 'alpha.500',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  subtitleText: {
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'normal',
    pb: '0.5',
  },
  exoticsPositionText: {
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'bold',
    w: 9,
  },
  viewMoreButton: {
    variant: 'primary',
  },
  viewMoreButtonText: {
    fontWeight: 'black',
    fontSize: 'sm',
    textTransform: 'uppercase',
  },
  srmBlendedLegContainer: {
    flexDir: 'row',
    flex: '1',
  },
  srmBlendedRunnerText: {
    width: 32,
    fontWeight: 'bold',
    color: 'gray.700',
    fontSize: 'xs',
    textTransform: 'capitalize',
    pb: '0.5',
  },
  srmMarketText: {
    fontWeight: 'bold',
    color: 'gray.700',
    fontSize: 'xs',
  },
  srmBlendedWrapper: {
    flexDir: 'column',
    flex: 1,
  },
  flexiText: {
    fontWeight: 'bold',
    color: 'gray.600',
    fontSize: 'xs',
  },
  flexiStakeText: {
    width: '16',
    color: 'black',
  },
  flexiStakeValueText: {
    color: 'alpha.400',
    fontWeight: 'bold',
  },
};
