import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};
export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  border: 'none',
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: '700',
  fontFamily: 'accent',
  h: '5',
  pl: '0.5',
  pr: '1.5',
  mb: '1',
  textTransform: 'uppercase',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  color: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'alpha.600';
      case EGreenIDStatus.PENDING:
        return 'orange.900';
      default:
        return 'white';
    }
  },
  bg: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'gamma.400';
      case EGreenIDStatus.PENDING:
        return 'orange.400';
      default:
        return 'red.500';
    }
  },
  _hover: {
    bg: 'gamma.400',
    color: 'alpha.900',
    border: 'none',
  },
  _disabled: {
    border: 'none',
    boxShadow: 'none',
    bgGradient: 'none',
    fontFamily: 'accent',
    cursor: 'default',
    opacity: '1',
    color: 'alpha.600',
    bg: 'gamma.400',
    '&&:hover': {
      bg: 'gamma.400',
      color: 'alpha.600',
      border: 'none',
    },
    _after: { opacity: 0 },
  },
  '.chakra-button__icon': {
    ml: '1',
    mr: '0.5',
  },
  '.chakra-icon': {
    boxSize: '3',
    bg: 'alpha.600',
    borderRadius: 'full',
    color: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'gamma.400';
        default:
          return 'white';
      }
    },
    m: '1px',
    p: '1px',
    boxShadow: 'none',
  },
  '.btn-span': {
    textShadow: 'none',
    fontWeight: 'bold',
    lineHeight: 'normal',
    pb: 'px',
  },
});
export const BoxOverviewTitle: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  fontFamily: 'Staatliches',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  pb: '2',
  pl: '1',
};
