import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexWrapper: CSSObject = {
  border: '1px solid rgba(255, 255, 255, 0.07)',
  bg: 'beta.800',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};

export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};

export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  color: 'white',
  boxShadow: '0px 0px 0px 1px #4A5454 inset, 0px 2px 8px 0px #00000040',
  textOverflow: 'ellipsis',
  _hover: {
    bg: 'beta.700',
  },
  '.chakra-badge.state-inprogress': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '.chakra-badge.state-prior': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '.chakra-badge.state-ended': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const RaceNumber: CSSObject = {
  color: 'white',
};
export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'white',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  color: 'gamma.200',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  boxShadow: '0px 0px 0px 1px #4A5454 inset, 0px 2px 8px 0px #00000040',
  _hover: {
    bg: 'beta.700',
  },

  '& > svg': { boxSize: '6' },
};
