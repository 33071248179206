import { CSSObject } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
  py: '2',
  w: 'fill-available',
  px: '1',
  mx: '1',
  borderRadius: 'base',
  color: 'white',
  '&::before, &::after': {
    position: 'absolute',
    height: '8px',
    width: '17px',
    background: 'white',
    content: `""`,
    borderRadius: 'full',
    top: '94%',
  },
  '&::before': {
    left: '-4',
  },
  '&::after': {
    right: '-4',
  },
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'alpha.200',
};

export const TextSubtitle: CSSObject = {
  color: 'alpha.200',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});
