import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  bg: 'alpha.600',
  borderTopRadius: 'base',
  p: '2',
  color: 'white',
  '&::after': {
    content: `""`,
    position: 'absolute',
    bottom: '-30px',
    left: '0',
    width: '100%',
    height: '31px',
    background:
      'linear-gradient(135deg, var(--bc-colors-alpha-600) 5px, transparent 0%),linear-gradient(-135deg, var(--bc-colors-alpha-600) 5px, transparent 0%)',
    backgroundSize: '13px 32px',
  },
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'alpha.700',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  border: '1px dashed',
  borderColor: 'gray.400',
  mb: '7',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};
