import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  overflow: 'hidden',
  borderRadius: 'md',

  '.chakra-accordion__item': {
    mb: '2',
    bg: 'beta.200',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
    borderRadius: 'md',
  },

  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '2px solid',
    borderTopColor: 'whiteAlpha.200',
    pt: '3',
    '.chakra-text': {
      _first: {
        color: 'white',
        fontSize: 'sm',
      },
      _notFirst: {
        borderRadius: 'base',
        bg: 'alpha.800',
        color: 'whiteAlpha.700',
        opacity: '0.5',
        border: 'none',
      },
    },
  },
};

export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontFamily: 'accent',
  fontSize: 'sm',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',

  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'gamma.300',
  },

  _hover: {
    bg: 'alpha.700',
    color: 'white',
  },
  _focus: {
    bg: 'alpha.700',
    color: 'white',
  },
};

export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',

  '.chakra-checkbox__control': {
    borderRadius: 'xs',
    border: '1px solid black',
  },
};

export const AccordionHeaderLogOut: CSSObject = {
  bg: 'beta.200',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  border: 'none',
  mt: '-2',
  _hover: {
    bg: 'alpha.700',
    color: 'white',
  },
  _focus: {
    bg: 'alpha.700',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};

export const IconPerson: CSSObject = {
  boxSize: ['5', null, '5'],
  color: 'white',
  mr: '1.5',
};

export const TextAccordion: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  mr: 'auto',
  mt: '1.5',
};

export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bgGradient: 'linear(to-b, gamma.200, gamma.300)',
  borderRadius: 'base',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  fontSize: 'xs',
  fontWeight: 'bold',
  gap: '1',
  p: '2',
  '.chakra-text': {
    color: 'var(--bc-colors-beta-400) !important',
  },
  '.chakra-icon': {
    color: 'beta.400',
  },
};

export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  border: 'none',
  bg: 'alpha.500',
  mb: '0',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};
