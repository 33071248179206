import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { spacing: '1' },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'beta.400',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    borderRadius: 'base',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    fontSize: '2xs',
    fontWeight: 'bold',
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    color: 'alpha.600',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    sx: {
      '&&': { textDecoration: 'auto' },
      '&.active': {
        bg: 'alpha.800',
        boxShadow:
          '0px 1px 2px 0px rgba(255, 255, 255, 0.40), 0px 1px 0px 0px #000 inset',
        color: 'alpha.200',
        textShadow: '0px -1px 0px rgba(0, 0, 0, 0.25)',
        fontWeight: 'bold',
        fontSize: '2xs',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
        svg: {
          color: 'beta.800',
        },
      },
    },
  },
};
