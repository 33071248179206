import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { spacing: '1' },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
    borderRadius: 'base',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    fontSize: '2xs',
    fontWeight: 'bold',
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    color: 'white',
    _before: {
      bgGradient: 'linear(to-b, beta.500, beta.600)',
      borderRadius: 'calc(var(--bc-radii-base) - 1px)',
      bottom: '1px',
      boxShadow: '0 0 0 2px rgba(0, 0, 0, .6) inset',
      content: '""',
      left: '1px',
      pos: 'absolute',
      right: '1px',
      top: '1px',
      zIndex: '-1',
    },
    _after: {
      borderRadius: 'calc(var(--bc-radii-base) - 1px)',
      bottom: '1px',
      boxShadow: '0 1px 0 2px rgba(255, 255, 255, .3) inset',
      content: '""',
      left: '1px',
      mixBlendMode: 'overlay',
      pos: 'absolute',
      right: '1px',
      top: '1px',
    },
    _hover: { color: 'alpha.500' },
    sx: {
      '&.active': {
        bg: 'blackAlpha.500',
        boxShadow:
          '0 -1px rgba(255, 255, 255, .25) inset, 0 2px 5px rgba(0, 0, 0, .4) inset',
        color: 'alpha.500',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
      },
    },
  },
};
