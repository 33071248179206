import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { errorBannerStyles } from './ErrorBanner.styles';

type TErrorBanner = {
  setDisplayErrorBanner: (val: boolean) => void;
};

export default function ErrorBanner({ setDisplayErrorBanner }: TErrorBanner) {
  return (
    <Flex {...errorBannerStyles.flexWrapper}>
      <Text
        {...errorBannerStyles.errorText}
        onClick={() => setDisplayErrorBanner(false)}
      >
        This selection cannot be combined.
      </Text>
    </Flex>
  );
}
