import { ButtonProps } from '@chakra-ui/react';

export const closeBtn: ButtonProps = {
  variant: 'unstyled',
  position: 'absolute',
  top: [0, null, '10px'],
  right: [0, null, '14px'],
  boxSize: '10',
  color: 'green',
  sx: {
    svg: {
      boxSize: ['20px', null, '20px'],
      color: 'beta.300',
      fill: 'beta.300',
      stroke: 'alpha.300',
    },
  },
};
