import React from 'react';
import { useIntl } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import {
  BoxBordered,
  FlexResponsibleGamblingWrapper,
  PageInfo,
  PageInfoWrapper,
  TextTitle,
} from './styles/ResponsibleGambling.styles';
import FindOutMore from './Components/FindOutMore';
import ResponsibleGamblingBanner from '../../../components/ResponsibleGamblingBanner/ResponsibleGamblingBanner';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import TakeAction from './Components/TakeAction';
import { useUserData } from '@/store/AuthStore';

export default function ResponsibleGambling() {
  const authUser = useUserData();
  const intl = useIntl();

  const [
    {
      Legal: { Responsible },
    },
  ] = getStrings();

  /**
   * TODO: Toggle wrapper based on auth state
   */
  const pageTitle = intl.formatMessage({ id: 'generic.responsiblegambling' });
  return (
    <AccountWrapper pageTitle={pageTitle}>
      <BoxBordered>
        <TextTitle>{Responsible.section.findOutMore.one}</TextTitle>
        <PageInfoWrapper>
          <PageInfo>{Responsible.subHeading}</PageInfo>
        </PageInfoWrapper>
      </BoxBordered>

      <FindOutMore />

      <FlexResponsibleGamblingWrapper>
        <ResponsibleGamblingBanner />
      </FlexResponsibleGamblingWrapper>

      {authUser && <TakeAction />}
    </AccountWrapper>
  );
}
