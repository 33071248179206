import {
  ButtonGroupProps,
  FlexProps,
  IconProps,
  ImageProps,
  keyframes,
  TextProps,
} from '@chakra-ui/react';
import { styleImport } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';
import mysteryBg from '@/assets/core/images/mysteryBetBg.png';

export const slideLeftTop = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: -940px -940px; }
`;

export const rotateBackground = keyframes`
  0%, 100% { transform: rotate(0deg); }
  50% { transform: rotate(360deg); }
`;

export type MysteryBetSchema = Partial<{
  iconMystery: any;
  buttonMyBets: CustomButtonProps;
  buttonMysteryLabel: CustomButtonProps;
  flexWrapperMysteryRolloverView: FlexProps;
  flexWrapperMysteryPlacedBetView: FlexProps;
  flexWrapperMysteryBetSelectionView: FlexProps;
  flexWrapperMysteryBetReviewView: FlexProps;
  flexWrapperMysteryErrorView: FlexProps;
  flexWrapperMysteryTooltipIcon: FlexProps;
  imageMysteryBet: ImageProps;
  flexRaceInfo: FlexProps;
  textPriceInfo: TextProps;
  textRollover: TextProps;
  textError: TextProps;
  iconBack: any;
  iconRace: any;
  textSpanWhite: TextProps;
  configsMysteryRolloverViewMotion: any;
  motionRightToLeft: any;
  motionLeftToRight: any;
  configsBetOptionsMotion: any;
  imageLogo: ImageProps;
  buttonOdds: CustomButtonProps;
  flexWrapperLogoOdds: FlexProps;
  flexWrapperOdds: FlexProps;
  buttonGroupAddToBetslip: ButtonGroupProps;
  buttonAddToBetslip: CustomButtonProps;
  buttonRollOver: CustomButtonProps;
  flexWrapperAddToBetslip: FlexProps;
  flexWrapperMysteryLabelView: FlexProps;
  textSlash: TextProps;
  spanSelectionText: TextProps;
  spanPositionType: TextProps;
  spanRollOverText: TextProps;
  flexWrapperPlacedBetViewGroup: FlexProps;
  flexWrapperLogoViewBetButton: FlexProps;
  tooltipIcon: IconProps;
  flexWrapperWithTooltip: FlexProps;
  flexWrapperRollover: FlexProps;
}>;

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperRollover: {
    'data-testid': 'mysteryBet-flexWrapperRollover',
    mt: 2,

    ...styleImport?.mysteryBetStyles?.flexWrapperRollover,
  },
  flexWrapperWithTooltip: {
    'data-testid': 'mysteryBet-flexWrapperWithTooltip',

    ...styleImport?.mysteryBetStyles?.flexWrapperWithTooltip,
  },
  flexWrapperLogoViewBetButton: {
    'data-testid': 'mysteryBet-flexWrapperLogoViewBetButton',
    gap: '2',
    m: 'auto',
    flexDir: 'row',
    alignItems: 'center',

    ...styleImport?.mysteryBetStyles?.flexWrapperLogoViewBetButton,
  },
  flexWrapperPlacedBetViewGroup: {
    'data-testid': 'mysteryBet-flexWrapperPlacedBetViewGroup',
    gap: 3,
    flexWrap: 'wrap',

    ...styleImport?.mysteryBetStyles?.flexWrapperPlacedBetViewGroup,
  },
  spanRollOverText: {
    'data-testid': 'mysteryBet-spanRollOverText',

    ...styleImport?.mysteryBetStyles?.spanRollOverText,
  },
  spanPositionType: {
    'data-testid': 'mysteryBet-spanPositionType',

    ...styleImport?.mysteryBetStyles?.spanPositionType,
  },
  spanSelectionText: {
    'data-testid': 'mysteryBet-spanSelectionText',

    ...styleImport?.mysteryBetStyles?.spanSelectionText,
  },
  textSlash: {
    'data-testid': 'mysteryBet-textSlash',
    as: 'span',
    px: 1,

    ...styleImport?.mysteryBetStyles?.textSlash,
  },
  flexWrapperMysteryLabelView: {
    'data-testid': 'mysteryBet-flexWrapperMysteryLabelView',
    display: 'inline-block',

    ...styleImport?.mysteryBetStyles?.flexWrapperMysteryLabelView,
  },
  flexWrapperAddToBetslip: {
    'data-testid': 'mysteryBet-flexWrapperAddToBetslip',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2',
    flexDir: ['column', null, 'row'],

    ...styleImport?.mysteryBetStyles?.flexWrapperAddToBetslip,
  },
  buttonRollOver: {
    'data-testid': 'mysteryBet-buttonRollOver',
    textTransform: 'uppercase',

    sx: {
      '&:disabled': {
        pointerEvents: 'none',
      },
    },

    ...styleImport?.mysteryBetStyles?.buttonRollOver,
  },
  buttonAddToBetslip: {
    'data-testid': 'mysteryBet-buttonAddToBetslip',
    fontSize: 'xs',

    ...styleImport?.mysteryBetStyles?.buttonAddToBetslip,
  },
  buttonGroupAddToBetslip: {
    'data-testid': 'mysteryBet-buttonGroupAddToBetslip',

    ...styleImport?.mysteryBetStyles?.buttonGroupAddToBetslip,
  },
  flexWrapperOdds: {
    'data-testid': 'mysteryBet-flexWrapperOdds',

    ...styleImport?.mysteryBetStyles?.flexWrapperOdds,
  },
  flexWrapperLogoOdds: {
    'data-testid': 'mysteryBet-flexWrapperLogoOdds',
    flexDirection: ['column', 'row'],
    alignItems: 'center',

    ...styleImport?.mysteryBetStyles?.flexWrapperLogoOdds,
  },
  buttonOdds: {
    'data-testid': 'mysteryBet-buttonOdds',

    ...styleImport?.mysteryBetStyles?.buttonOdds,
  },
  iconMystery: {
    'data-testid': 'mysteryBet-iconMystery',
    boxSize: 4,

    ...styleImport?.mysteryBetStyles?.iconMystery,
  },
  buttonMyBets: {
    'data-testid': 'mysteryBet-buttonMyBets',
    w: 'fit-content',
    fontSize: ['xs', 'sm'],

    ...styleImport?.mysteryBetStyles?.buttonMyBets,
  },
  imageLogo: {
    'data-testid': 'mysteryBet-imageLogo',
    alt: 'Mystery Bet Logo',
    pos: 'relative',

    ...styleImport?.mysteryBetStyles?.imageLogo,
  },
  buttonMysteryLabel: {
    'data-testid': 'mysteryBet-buttonMysteryLabel',
    pointerEvents: 'none',
    fontSize: 'xs',

    ...styleImport?.mysteryBetStyles?.buttonMysteryLabel,
  },
  flexWrapperMysteryRolloverView: {
    'data-testid': 'mysteryBet-flexWrapperMysteryRolloverView',
    fontSize: 'xs',
    overflow: 'hidden',
    display: 'inline-block',
    lineHeight: 2,

    ...styleImport?.mysteryBetStyles?.flexWrapperMysteryRolloverView,
  },

  flexWrapperMysteryPlacedBetView: {
    'data-testid':
      'mysteryBet-flexWrapperMysteryRolloverView->flexWrapperMysteryPlacedBetView',
    overflow: 'hidden',
    display: 'inline-block',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 2,

    ...styleImport?.mysteryBetStyles?.flexWrapperMysteryPlacedBetView,
  },

  flexWrapperMysteryBetSelectionView: {
    'data-testid': 'mysteryBet-flexWrapperMysteryBetSelectionView',

    alignItems: 'center',
    justifyContent: 'center',
    sx: {
      '&&': {
        backgroundImage: mysteryBg,
      },
    },
    animation: `${slideLeftTop} 10s linear infinite`,
    overflow: 'hidden',

    ...styleImport?.mysteryBetStyles?.flexWrapperMysteryBetSelectionView,
  },

  flexWrapperMysteryBetReviewView: {
    'data-testid': 'mysteryBet-flexWrapperMysteryBetReviewView',

    sx: {
      '&&': {
        backgroundImage: mysteryBg,
      },
    },
    animation: `${slideLeftTop} 10s linear infinite`,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',

    ...styleImport?.mysteryBetStyles?.flexWrapperMysteryBetReviewView,
  },
  flexWrapperMysteryErrorView: {
    'data-testid': 'mysteryBet-MysteryErrorViewWrapper',
    bgImage: mysteryBg,
    animation: `${slideLeftTop} 10s linear infinite`,
    overflow: 'hidden',

    ...styleImport?.mysteryBetStyles?.flexWrapperMysteryErrorView,
  },
  flexWrapperMysteryTooltipIcon: {
    'data-testid': 'mysteryBet-flexWrapperMysteryTooltipIcon',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    ...styleImport?.mysteryBetStyles?.flexWrapperMysteryTooltipIcon,
  },
  imageMysteryBet: {
    'data-testid': 'mysteryBet-imageMysteryBet',

    ...styleImport?.mysteryBetStyles?.imageMysteryBet,
  },
  flexRaceInfo: {
    'data-testid': 'mysteryBet-flexRaceInfo',
    textTransform: 'uppercase',
    display: 'inline-block',
    ml: 1,

    ...styleImport?.mysteryBetStyles?.flexRaceInfo,
  },
  textPriceInfo: {
    'data-testid': 'mysteryBet-textPriceInfo',
    display: 'inline-block',

    ...styleImport?.mysteryBetStyles?.textPriceInfo,
  },
  textRollover: {
    'data-testid': 'mysteryBet-textRollover',
    textTransform: 'uppercase',
    display: 'inline-block',

    ...styleImport?.mysteryBetStyles?.textRollover,
  },
  textError: {
    'data-testid': 'mysteryBet-textError',

    ...styleImport?.mysteryBetStyles?.textError,
  },
  iconBack: {
    'data-testid': 'mysteryBet-iconBack',
    cursor: 'pointer',
    m: 'auto',
    transition: '.3s ease-out',

    _hover: {
      transform: 'translateX(-5px)',
    },

    ...styleImport?.mysteryBetStyles?.iconBack,
  },
  iconRace: {
    'data-testid': 'mysteryBet-iconRace',
    sx: {
      boxSize: 4,
      pb: 0,
    },
    transform: 'translateX(-2px) translateY(2px)',

    ...styleImport?.mysteryBetStyles?.iconRace,
  },
  textSpanWhite: {
    'data-testid': 'mysteryBet-textSpanWhite',

    ...styleImport?.mysteryBetStyles?.textSpanWhite,
  },
  configsMysteryRolloverViewMotion: {
    'data-testid': 'mysteryBet-configsMysteryRolloverViewMotion',
    initial: { translateY: 100 },
    animate: { translateY: 0 },
    transition: { duration: 1 },

    ...styleImport?.mysteryBetStyles?.configsMysteryRolloverViewMotion,
  },
  motionRightToLeft: {
    'data-testid': 'mysteryBet-motionRightToLeft',
    initial: { translateY: 50 },
    animate: { translateY: 0 },
    exit: { translateY: -50 },
    transition: { duration: 0.4 },

    ...styleImport?.mysteryBetStyles?.motionRightToLeft,
  },
  motionLeftToRight: {
    'data-testid': 'mysteryBet-motionLeftToRight',
    initial: { translateY: -50 },
    animate: { translateY: 0 },
    exit: { translateY: 50 },
    transition: { duration: 0.4 },

    ...styleImport?.mysteryBetStyles?.motionLeftToRight,
  },
  configsBetOptionsMotion: {
    'data-testid': 'mysteryBet-configsBetOptionsMotion',
    initial: { scale: 1 },
    animate: { scale: 1 },

    ...styleImport?.mysteryBetStyles?.configsBetOptionsMotion,
  },
  tooltipIcon: {
    'data-testid': 'mysteryBet-tooltipIcon',

    ...styleImport?.mysteryBetStyles?.tooltipIcon,
  },
};
