import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    fontFamily: 'CarterOne',
    _disabled: {
      pt: '2.5',
      pb: '2',
      bg: 'delta.700',
      boxShadow: 'unset',
      color: 'delta.100',
      fontSize: 'xs',
      fontFamily: 'Roboto',
      '&&': {
        h: '6',
      },
      _hover: {
        bg: 'gray.300',
        color: 'gray.500',
      },
    },
  },
};

export const TextMonth: CSSObject = {
  color: 'white',
  fontSize: 'sm',
};

export const FlexInner: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};
