import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  pb: ['2', null, '4'],
  px: ['2', null, '4'],
  mx: ['0', null, null, 'unset'],
  minH: '50vh',

  borderTopRadius: 0,
  borderBottomRadius: '6px',
};

export const BoxWrapper: CSSObject = {
  mb: ['0'],
  borderRadius: 'none',
  background: 'none',
  border: 0,
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderRadius: '4px',
  border: 0,
  bg: 'transparent',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  mb: '2',
};
export const TextSport: CSSObject = {
  color: 'white',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LinkItem = (_: boolean, isFirstItem: boolean) => ({
  display: 'flex',
  padding: '0 8px',
  height: '30px',
  alignItems: 'center',
  gap: '6px',
  alignSelf: 'stretch',
  borderTop: '1px',
  borderTopColor: 'beta.200',
  background: 'beta.300',

  _hover: {
    background: 'beta.400',
  },

  ...(isFirstItem && { borderTop: 0 }),
});

export const IconSport: CSSObject = {
  display: 'flex',
  width: '24px',
  height: '24px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2.5',
  mr: 0,
  color: '#CC952B',
};
export const IconChevron: CSSObject = {
  boxSize: ['20px', '5'],
  border: '1px',
  borderColor: '#CC952B',
  borderRadius: 'full',
  p: '1',
  transition: '.3s ease-in-out',
  color: '#CC952B',

  _hover: {
    color: 'beta.800',
  },
};
