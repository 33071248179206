import { CSSObject, keyframes } from '@chakra-ui/react';
import banner from '../../../../../../assets/volcanobet/images/sports/banner.png';

const verticalMove = keyframes`
  0% { background-position-y: top; }
  50% { background-position-y: bottom; }
  100% { background-position-y: top; }
`;

export const BoxHeaderText: CSSObject = {
  fontSize: ['lg', '4xl'],
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'Caveman',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: 'none',
};

export const BoxHeader: CSSObject = {
  bgImage: `url(${banner})`,
  animation: `${verticalMove} 30s ease-in-out infinite`,
  px: ['3', '6'],
  py: ['3', '9'],
  bgColor: 'epsilon.500',
  bgSize: 'cover',
  bgPos: 'center',
  color: 'alpha.500',
  borderRadius: 'base',
  contain: 'paint',
  textTransform: 'uppercase',
  fontFamily: 'Caveman',
  boxShadow:
    '0px 1px 0px rgba(255, 255, 255, 0.05), inset 0px 0px 4px rgba(0, 0, 0, 0.4), inset 0px 1px 8px rgba(0, 0, 0, 0.35)',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
};
