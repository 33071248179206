import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },
  FlexCard: {
    my: ['0', null, '1'],
    mb: ['1', null, '0'],
    bg: 'white',
  },
  LinkCardHeader: {
    bg: 'white',
    color: 'alpha.700',
  },
  TextRaceNumber: {
    bg: 'alpha.500',
    borderRadius: 'sm',
    color: 'white',
  },
  GridCardContent: {
    bg: 'alpha.200',
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'alpha.50',
    color: 'alpha.800',
  },
  FlexGridRunnerItem: {
    bg: 'white',
    borderBottom: '1px',
    borderBottomColor: 'gray.100',
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'alpha.700',
    fontWeight: 'normal',
  },
  LinkRaceDetails: {
    color: 'white',
    bg: 'alpha.500',
    textTransform: 'uppercase',
    _hover: {
      bg: 'alpha.600',
    },
    borderBottomRadius: 'md',
  },
  buttonRaceCardOddsProps: {
    variant: 'solid',
    borderRadius: 'md',
    fontSize: 'xs',
    fontFamily: 'body',
    fontWeight: 'bold',
    w: '14',
    h: '8',
    border: '2px solid white',
    lineHeight: 'normal',
  },
};
