import React from 'react';
import { Envelope } from 'styled-icons/bootstrap';
import { Flex } from '@chakra-ui/react';
import {
  CenterWrapper,
  ContactIcon,
  ContactText,
  Header,
  IconContainer,
  MaintenanceContainer,
} from '../styles/Maintenance.styles';
import Logo from '../../../components/Headers/HeaderPrimary/conditionals/Logo/template';
import { TTemplate } from '@/helpers/createTemplate';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import Content from '../components/Content/Content';

const { email } = getThemeConfig();

const template = (): TTemplate => ({
  wrapper: <div />,
  center: {
    wrapper: <MaintenanceContainer />,
    header: (
      <Header>
        <Logo />
      </Header>
    ),
    main: {
      wrapper: <CenterWrapper />,
      body: <Content />,
      extras: (
        <Flex maxW="380px" flexDir="column" alignItems="start" gap="4">
          <IconContainer>
            <ContactIcon as={Envelope} p="0.5" />
            <ContactText>{email}</ContactText>
          </IconContainer>
        </Flex>
      ),
    },
  },
});

export default template;
