import React from 'react';
import { TBetSlipBetMulti } from '../../Services/Betslip.types';
import {
  EmptyMultiIcon,
  InvalidityBox,
  UnableToCreateMultiWrapper,
} from './Components/MultiCard.styles';
import { useInvalidityReason } from './Services/MultiBet.hooks';
import { checkMultiValidity } from './Services/MultiBet.utils';

type MultiBetCardProps = {
  bet: TBetSlipBetMulti;
};

const EmptyMulti: React.FC<MultiBetCardProps> = ({ bet }) => {
  const validMulti = checkMultiValidity(bet);
  const invalidityReason = useInvalidityReason(bet);

  return (
    <>
      {!validMulti && (
        <UnableToCreateMultiWrapper>
          <EmptyMultiIcon boxSize="5" name="emptyMultiIcon" />
          <InvalidityBox>{invalidityReason}</InvalidityBox>
        </UnableToCreateMultiWrapper>
      )}
    </>
  );
};

export default EmptyMulti;
