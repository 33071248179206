import { CSSObject } from '@chakra-ui/react';

export const FlexWrapper: CSSObject = {
  bg: 'beta.800',
  borderBottom: '3px solid',
  borderColor: 'alpha.400',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
};

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '44px',
  maxW: ['100vw', null, null, null, 'var(--bc-sizes-container-lg)'],
  w: '100%',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
};

export const LinkItem: CSSObject = {
  borderRadius: '5px',
  bgGradient: 'linear(to-b, beta.700, beta.700)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset',
  w: '152px',
  gap: '3px',
  px: '4px',
  py: 0,
  pos: 'relative',
  contain: 'paint',

  '> *': { zIndex: 9 },

  _hover: {
    _after: { opacity: 0 },
  },

  _after: {
    content: `''`,
    pos: 'absolute',
    inset: 0,
    bgGradient: 'linear(to-b, beta.600, beta.700)',
    transition: 'all .2s ease-in-out',
  },

  '&&': {
    '.chakra-badge': {
      pos: 'static',
      borderRadius: '3px',
      padding: '3px 4px',
      color: 'white',
      textAlign: 'center',
      fontSize: '9px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      ml: 0,
      lineHeight: '17px',
    },
    '.chakra-badge.state-inprogress': {
      color: 'alpha.800',
      bg: 'alpha.500',
    },
  },
};

export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: 0,
  flex: 1,
  fontWeight: 'bold',
};

export const Icon: CSSObject = {
  color: 'white',
  ml: 0,
};

export const RaceNumber: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: '9px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  borderRadius: 'base',
  background: 'beta.800',
  display: 'flex',
  width: '19px',
  height: '17px',
  padding: '3px 4px',
  justifyContent: 'center',
  alignItems: 'center',

  order: 4,
  mr: 0,
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '0.5',
  mr: 0,
};

export const ButtonChevron: CSSObject = {
  display: 'flex',
  minW: '6',
  boxSize: '6',
  padding: '0px 4px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.06) inset',
  color: 'white',
  fontSize: 'xs',
  fontStyle: 'normal',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  svg: {
    p: '1',
  },
};
