import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '24px',
  display: 'inline-block',
  fontSize: '2xs',
  borderRadius: 'base',
  bg: '#224049',
  color: 'white',
  ml: '1',
  zIndex: '5',
  pos: 'relative',
  lineHeight: 'normal',
};

export const LinkRaceContainer: CSSObject = {
  position: 'relative',
  width: ['100%', null, null, '100%'],
  my: ['0', null, '2'],
  mb: ['1', null, '0'],
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  borderRadius: 'lg',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
  py: '2.5',
  px: '4',
  h: '57px',
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '1',
    right: '1',
    width: 'calc(100% - 8px)',
    height: '49px',
    zIndex: '3',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    boxShadow: '0px 1px 0px 0px #ED8934',
  },
  _after: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '2',
    left: '2',
    width: 'calc(100% - 16px)',
    height: '41px',
    zIndex: '4',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
  },
  _hover: {
    _after: {
      bg: 'alpha.800',
    },
  },

  '.chakra-badge.state-inprogress': {
    bg: 'beta.400',
    color: 'gamma.800',
    borderRadius: 'base',
    maxW: '40px',
    zIndex: '5',
    pos: 'relative',
  },

  '.chakra-badge.state-prior': {
    bg: 'alpha.200',
    color: 'blackAlpha.700',
    borderRadius: 'base',
    maxW: '40px',
    zIndex: '5',
    pos: 'relative',
  },

  '.chakra-badge.state-ended': {
    bg: 'gamma.500',
    color: 'gamma.800',
    borderRadius: 'base',
    maxW: '40px',
    zIndex: '5',
    pos: 'relative',
  },
};

export const TextRaceType: CSSObject = {
  color: 'gamma.500',
  bg: 'transparent',
  h: '34px',
  m: 'auto',
  pt: '1.5',
  fontWeight: 'thin',
  fontSize: 'xl',
  fontFamily: 'Bungee',
  letterSpacing: 'wide',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
  zIndex: '5',
  pos: 'relative',
  color: 'white',
};

export const BoxColumn: CSSObject = {
  bg: 'transparent',
  mb: ['4', 'unset'],
};

export const Icon: CSSObject = {
  boxSize: '5',
  zIndex: '5',
  pos: 'relative',
  color: 'beta.400',
};
