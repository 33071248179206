import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/fiestabet/images/backgrounds/betslip-background3x.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
};

export const FlexBetSlipModalContainer: CSSObject = {
  bg: '#970406',
  w: ['103%', null, '320px', '320px', '103%'],
  boxShadow: '2px 4px 0px 0px #710008, -2px 4px 0px 0px #B43F17',
  padding: [0, '15px'],
  h: '99%',
  paddingBottom: '9px',
};

export const BetSlipContainer: CSSObject = {
  backgroundImage: `url(${background})`,
  boxShadow: '-1px 3px 6px 6px #00000030 inset',
  bgRepeat: 'round',
  paddingTop: '37px',
  '@media (max-width: 1279px)': {
    h: '100%',
    position: 'relative',
    borderRadius: '1.2rem',
    borderY: '#C5C4C0 5px solid',
    borderX: '#C5C4C0 8px solid',
    boxShadow:
      '0px 0px 5px 5px rgba(0, 0, 0, 0.40) inset, 0px 5px 0px 0px #757472, 0px 10px 0px 0px #464646, 0px -4px 0px 0px #EDEDEC',
    bgRepeat: 'repeat-y',
    bgSize: '100% 100%',
    zIndex: '5',
    width: '100%',
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bgSize: '100%',
    },
  },
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '0',
  mt: '2',
  mx: '2',
  mb: isKeypadVisible && 'unset',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterContainer: CSSObject = {
  bg: 'alpha.600',
  color: 'white',
  borderBottomRadius: ['none', null, null, null, 'xl'],
  '&&': {
    p: '3',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'alpha.50',
  fontFamily: 'CreteRound',
  lineHeight: '86.687%',
  fontSize: 'sm',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'extrabold',
  color: 'epsilon.500',
  lineHeight: '86.687%',
  fontSize: 'sm',
});
export const buttonClearBetsProps: CustomButtonProps = {
  height: '7',
  width: '74px',
  borderRadius: 'lg',
  fontWeight: 'bold',
  margin: 'auto',
  mt: '-6',
  position: 'relative',
  lineHeight: 'normal',
  color: 'epsilon.500',
  minW: '16',
  border: '3px solid',
  borderColor: 'gamma.50',
  w: 'auto',
  px: '3',
  textShadow: 'none',

  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '1',
    },
    '.chakra-icon': {
      boxSize: '3',
    },

    '&&': {
      fontSize: '2xs',
      fontFamily: 'roboto',
      textTransform: 'uppercase',
      boxShadow: 'none',
      bg: 'beta.500',

      '&:hover, &:active': {
        bgGradient: 'linear(to-b, beta.500, beta.500)',
        border: '3px solid',
        borderColor: 'gamma.50',
        height: '7',
      },
    },
  },
  _after: {
    content: `''`,
    boxShadow: 'unset',
    transition: 'unset',
    borderRadius: 'unset',
    bg: 'unset',
  },
};
export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
};

export const BetslipVStack: CSSObject = {
  spacing: '0',
};

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};
