import Black from '@/assets/luckystride/fonts/Roboto-Black.ttf';
import BlackItalic from '@/assets/luckystride/fonts/Roboto-BlackItalic.ttf';
import Bold from '@/assets/luckystride/fonts/Roboto-Bold.ttf';
import BoldItalic from '@/assets/luckystride/fonts/Roboto-BoldItalic.ttf';
import ExtraLight from '@/assets/luckystride/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '@/assets/luckystride/fonts/Roboto-ThinItalic.ttf';
import Light from '@/assets/luckystride/fonts/Roboto-Light.ttf';
import LightItalic from '@/assets/luckystride/fonts/Roboto-LightItalic.ttf';
import Regular from '@/assets/luckystride/fonts/Roboto-Regular.ttf';
import Italic from '@/assets/luckystride/fonts/Roboto-Italic.ttf';
import SemiBold from '@/assets/luckystride/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '@/assets/luckystride/fonts/Roboto-MediumItalic.ttf';

import ProximaNovaAltBold from '@/assets/luckystride/fonts/ProximaNova-Alt-Bold.otf';
import ProximaNovaAltLight from '@/assets/luckystride/fonts/ProximaNova-Alt-Light.otf';
import ProximaNovaAltThin from '@/assets/luckystride/fonts/ProximaNova-Alt-Thin.otf';
import ProximaNovaBlack from '@/assets/luckystride/fonts/ProximaNova-Black.otf';
import ProximaNovaBold from '@/assets/luckystride/fonts/ProximaNova-Bold.otf';
import ProximaNovaExtrabold from '@/assets/luckystride/fonts/ProximaNova-Extrabold.otf';
import ProximaNovaThin from '@/assets/luckystride/fonts/ProximaNova-Thin.otf';
import ProximaNovaRegular from '@/assets/luckystride/fonts/ProximaNova-Regular.otf';

export const fontFace = `
        @font-face {
          font-family: 'ProximaNovaAlt';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${ProximaNovaAltBold}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNovaAlt';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${ProximaNovaAltLight}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNovaAlt';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ProximaNovaAltThin}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${ProximaNovaBlack}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 800;
          font-display: swap;
          src: url('${ProximaNovaExtrabold}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${ProximaNovaBold}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${ProximaNovaRegular}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ProximaNovaThin}') format('opentype');
        }
        
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLight}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${Light}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 300;
          font-display: swap;
          src: url('${LightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${Regular}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 400;
          font-display: swap;
          src: url('${Italic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${Bold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 700;
          font-display: swap;
          src: url('${BoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${Black}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 900;
          font-display: swap;
          src: url('${BlackItalic}') format('opentype');
        }
      `;
