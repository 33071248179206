import { CSSObject } from '@chakra-ui/react';

export const RacerName: CSSObject = {
  whiteSpace: ['unset', null, 'nowrap'],
};

export const Item: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'space-between',
  mx: ['-2', null, '0'],
  minH: ['10', null, 'unset'],

  alignItems: 'start',
  mt: '1',
  mb: '2',
  p: '2',
  px: '0',
  pl: ['2', null, 'unset'],
  pr: '4',
  py: 'unset',
  bg: ['transparent', 'gray.100'],
  borderRadius: 'md',
  gap: '1',

  borderColor: 'gray.300',
  borderTop: '1px dashed',
  h: 'auto',
};
