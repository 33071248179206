import {
  FlexProps,
  IconProps,
  LinkBoxProps,
  LinkOverlayProps,
  TextProps,
} from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { ScrollableFlexProps } from '@/components/SchemaTypes';
import { styleImports } from './HomeLinks.styles.imports';

const themeName = getThemeName();

export type HomeLinksSchema = Partial<{
  wrapper: FlexProps;
  container: FlexProps;
  racingBtnsContainer: FlexProps;
  sportBtnsContainer: ScrollableFlexProps;
  linkOverlay: LinkOverlayProps;
  linkOverlayOverride: LinkOverlayProps;
  linkBox: LinkBoxProps;
  linkIconBox: LinkBoxProps;
  linkIconBoxOverride: LinkBoxProps;
  linkBoxText: TextProps;
  sportIcon: IconProps;
  sportIconOverride: IconProps;
  racingIcon: IconProps;
  sportsHeader: TextProps;
  racingHeader: TextProps;
  sportsHeaderTile: TextProps;
}>;

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    'data-testid': 'home-links-wrapper',

    ...(styleImports?.[themeName]?.homeLinkStyles?.wrapper ?? {}),
  },
  container: {
    'data-testid': 'home-links-container',

    ...(styleImports?.[themeName]?.homeLinkStyles?.container ?? {}),
  },
  racingBtnsContainer: {
    'data-testid': 'home-links-racingBtnsContainer',

    ...(styleImports?.[themeName]?.homeLinkStyles?.racingBtnsContainer ?? {}),
  },
  sportBtnsContainer: {
    'data-testid': 'home-links-sportBtnsContainer',

    ...(styleImports?.[themeName]?.homeLinkStyles?.sportBtnsContainer ?? {}),
  },
  linkOverlay: {
    'data-testid': 'home-links-linkOverlay',

    ...(styleImports?.[themeName]?.homeLinkStyles?.linkOverlay ?? {}),
  },
  linkOverlayOverride: {
    'data-testid': 'home-links-linkOverlayOverride',

    ...(styleImports?.[themeName]?.homeLinkStyles?.linkOverlayOverride ?? {}),
  },
  linkIconBox: {
    'data-testid': 'home-links-linkIconBox',

    ...(styleImports?.[themeName]?.homeLinkStyles?.linkIconBox ?? {}),
  },
  linkIconBoxOverride: {
    'data-testid': 'home-links-linkIconBoxOverride',

    ...(styleImports?.[themeName]?.homeLinkStyles?.linkIconBoxOverride ?? {}),
  },
  linkBox: {
    'data-testid': 'home-links-linkBox',

    ...(styleImports?.[themeName]?.homeLinkStyles?.linkBox ?? {}),
  },
  linkBoxText: {
    'data-testid': 'home-links-linkBoxText',

    ...(styleImports?.[themeName]?.homeLinkStyles?.linkBoxText ?? {}),
  },
  racingIcon: {
    'data-testid': 'home-links-racingIcon',

    ...(styleImports?.[themeName]?.homeLinkStyles?.racingIcon ?? {}),
  },
  sportIcon: {
    'data-testid': 'home-links-sportIcon',

    ...(styleImports?.[themeName]?.homeLinkStyles?.sportIcon ?? {}),
  },
  sportIconOverride: {
    'data-testid': 'home-links-sportIconOverride',

    ...(styleImports?.[themeName]?.homeLinkStyles?.sportIconOverride ?? {}),
  },
  sportsHeader: {
    'data-testid': 'home-links-header-sportsHeader',

    ...(styleImports?.[themeName]?.homeLinkStyles?.sportsHeader ?? {}),
  },
  racingHeader: {
    'data-testid': 'home-links-header-racingHeader',

    ...(styleImports?.[themeName]?.homeLinkStyles?.racingHeader ?? {}),
  },
  sportsHeaderTile: {
    'data-testid': 'home-links-header-sportsHeaderTile',
    display: 'none',

    ...(styleImports?.[themeName]?.homeLinkStyles?.sportsHeaderTile ?? {}),
  },
};
