import {
  CheckShield,
  HourglassBottom,
  ShieldX,
} from 'styled-icons/boxicons-solid';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { CheckmarkOutline } from '@styled-icons/evaicons-outline/CheckmarkOutline';
import { getStrings } from '@/helpers/utils';
import { EThemesOG } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

const [
  {
    Account: { Overview: OverviewStrings },
  },
] = getStrings();

export const isVerified = (verificationStatus: EGreenIDStatus | undefined) =>
  verificationStatus === EGreenIDStatus.VERIFIED ||
  verificationStatus === EGreenIDStatus.VERIFIED_ADMIN ||
  verificationStatus === EGreenIDStatus.VERIFIED_WITH_CHANGES;

export const statusText = (verificationStatus: EGreenIDStatus | undefined) => {
  switch (verificationStatus) {
    case EGreenIDStatus.VERIFIED:
    case EGreenIDStatus.VERIFIED_ADMIN:
    case EGreenIDStatus.VERIFIED_WITH_CHANGES:
      return OverviewStrings.Verification.Verified;
    case EGreenIDStatus.PENDING:
      return OverviewStrings.Verification.Pending;
    default:
      return OverviewStrings.Verification.Unverified;
  }
};

export const statusIcon = (verificationStatus: EGreenIDStatus | undefined) => {
  const themeName = getThemeName();
  switch (verificationStatus) {
    case EGreenIDStatus.VERIFIED:
    case EGreenIDStatus.VERIFIED_ADMIN:
    case EGreenIDStatus.VERIFIED_WITH_CHANGES:
      if (themeName === EThemesOG.Betgalaxy) {
        return CheckShield;
      }
      return CheckmarkOutline;
    case EGreenIDStatus.PENDING:
      return HourglassBottom;
    default:
      if (themeName === EThemesOG.Betgalaxy) {
        return ShieldX;
      }
      return CloseOutline;
  }
};
