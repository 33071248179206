import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';
import slamBorder from '@/assets/slambet/images/backgrounds/iconBg.svg';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  boxShadow: !isLast && '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'rgba(78, 178, 255, 0.14)',
  mx: '0',
  borderRadius: 'md',
  mb: '2',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
};

export const Icon: CSSObject = {
  boxSize: '8',
  p: '2',
  color: 'alpha.500',
  borderRadius: 'md',
  pos: 'relative',
  backgroundImage: `url(${slamBorder})`,
  backgroundRepeat: 'no-repeat',
  bgSize: 'contain',
};

export const TextRace: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'normal',
};

export const TextNumber: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'beta.200',
  color: 'blackAlpha.700',
  py: '0',
  pt: '0.5',
  borderRadius: 'base',
  '&&': {
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
  },
};

export const BoxHeading: CSSObject = {
  display: 'none',
};

export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    color: ['white', 'beta.900'],
  },
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    py: '0',
    mr: '0.5',
    color: 'alpha.800',
  },
};
