import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from '@/api/api';
import { keys } from '@/api/api.keys';

/**
 * Types
 */
type OfferingsResponse = {
  offered_sports: {
    sport_id: string;
    display_name: string;
  }[];
  offered_race_types: string[];
};

type OfferingsProps = {
  pc?: string;
};

/**
 * Axios
 */
const queryOfferings = (params?: OfferingsProps) =>
  api
    .get<OfferingsResponse>('punter/general/offerings', { params })
    .then((res) => res.data);

/**
 * Query
 */

type UseQueryProps = {
  options?: Omit<UseQueryOptions<OfferingsResponse>, 'queryKey' | 'queryFn'>;
  params?: OfferingsProps;
};

export const useQueryOfferings = ({ options, params }: UseQueryProps) =>
  useQuery([keys.offeringsNew, params], () => queryOfferings(params), options);
