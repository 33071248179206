/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useBetSlipFooter,
  useBetSlipSubmit,
} from '../../../Services/Betslip.hooks';
import { formatStake, getStrings } from '@/helpers/utils';
import {
  EBetSlipBetSubmissionType,
  EBetSlipViewMode,
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
  TBetSlipBet,
} from '../../../Services/Betslip.types';
import {
  BetSlipFooterContainer,
  BetSlipFooterRowTitle,
  BetSlipFooterRowValue,
  buttonClearBetsProps,
  buttonConfirmBetsProps,
  buttonMakeDepositProps,
  LowStakeDivider,
  LowStakePrompt,
  LowStakeWrapper,
} from '../Modal.styles';
import {
  betslipFooterStyles,
  FlexResponsibleGamblingBannerWrapper,
  FooterRowContainer,
} from './styles/Footer.styles';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  removeAllBets,
  setBetSlipOpen,
  setBetSlipViewMode,
} from '@/redux/Betslip.slices';
import { checkOddsDecreased } from '../../../Services/Betslip.utils';
import { OddsChangedBanner } from '../../OddsChangedBanner/OddsChangedBanner';
import EventsClosedBanner from '../../EventsClosedBanner/EventsClosedBanner';
import ResponsibleGamblingBanner from '../../../../ResponsibleGamblingBanner/ResponsibleGamblingBanner';
import { EResponsibleGamblingBannerLocation } from '../../../../ResponsibleGamblingBanner/services/ResponsibleGamblingBanner.types';
import FooterHOC from './FooterHOC';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import {
  getBetSlipStoreActions,
  transformBetForLegacy,
  useBetSlipBets,
  useMoMBet,
} from '@/store/BetSlipStore';
import { Button } from '@/components/Button/Button';

/**
 * Containers
 */
const FooterReduxContainer = () => {
  const bets = useAppSelector((state) => state.betSlip.bets);
  return <Footer bets={bets} />;
};

const FooterZustandContainer = () => {
  const betsNew = useBetSlipBets() ?? {};
  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  return <Footer bets={bets} />;
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? FooterZustandContainer
  : FooterReduxContainer;

/**
 * View
 */
type FooterProps = {
  bets: TBetSlipBet[];
};

const Footer = ({ bets }: FooterProps) => {
  const [{ BetSlip: Strings }] = getStrings();
  const intl = useIntl();
  const { handleSubmit } = useBetSlipSubmit();
  const { removeBet, updateMoMBet } = getBetSlipStoreActions();
  const moMBet = useMoMBet();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    clickEditBets,
    submitButtonTitle,
    noEstReturns,
    isConfirmButtonDisabled,
    viewMode,
    submissionLoading,
    hasSufficientFunds,
    hasEditableBets,
    hasClearableBets,
  } = useBetSlipFooter({ bets });

  const { displayOddsChangeBanner, displayEventsClosedBanner } = useAppSelector(
    (state) => state.betSlip
  );

  const clearAll = () => {
    removeBet((state) => {
      const _bets = { ...(state ?? {}) };
      const keys = Object.keys(_bets);

      keys.forEach((key) => {
        if (
          _bets[key].confirmation?.status !==
          EBetSubmissionConfirmationStatus.Pending
        ) {
          delete _bets[key];
        }
      });

      return _bets;
    });

    if (
      moMBet?.betSubmissionStatus !== EBetSubmissionConfirmationStatus.Pending
    ) {
      updateMoMBet(null);
    }

    dispatch(removeAllBets());
    dispatch(setBetSlipViewMode(EBetSlipViewMode.EditingBets));
  };

  // Bets that are either confirmed or awaiting manual approval should be omitted from the total stake/potential returns amount
  // The outliers are bets with a) an odds change, b) insufficient funds, or c) multi bets with some props that have closed,
  //  as they have a confirmation object but are still editable/submittable
  const relevantBets = bets?.filter(
    (bet) =>
      !bet.confirmation ||
      bet?.confirmation?.rejection_reason ===
        EBetSubmissionRejectionReason.OddsChange ||
      bet?.confirmation?.rejection_reason ===
        EBetSubmissionRejectionReason.InsufficentFund ||
      (bet?.confirmation?.rejection_reason ===
        EBetSubmissionRejectionReason.PropositionClosed &&
        bet.type === EBetSlipBetSubmissionType.Multi)
  );

  const shouldIncludeMoMBet =
    moMBet?.betSubmissionStatus === null ||
    moMBet?.betRequestStatus === null ||
    moMBet?.betSubmissionStatus === EBetSubmissionConfirmationStatus.Pending;

  const totalStake =
    (relevantBets.reduce(
      (total, bet) => total + (Number(bet.stake) > 0 ? Number(bet.stake) : 0),
      0
    ) || 0) + (shouldIncludeMoMBet ? Number(moMBet?.stake) || 0 : 0);

  const potentialReturns =
    (relevantBets.reduce(
      (total, bet) => total + (bet.potential_returns ?? 0),
      0
    ) || 0) + (shouldIncludeMoMBet ? Number(moMBet?.stake) * moMBet?.odds : 0);

  const totalRows = [
    {
      title: Strings.betSlipModal.totalStake,
      value: `${formatStake(totalStake)}`,
      cyLabel: 'betSlipModalTotalStake',
      type: 'totalStake',
    },
    {
      title: intl.formatMessage({
        id: 'betSlip.potentialReturns',
      }),
      value: noEstReturns ? 'N/A' : `${formatStake(potentialReturns)}`,
      cyLabel: 'betSlipModalPotentialReturns',
      type: 'potentialReturns',
    },
  ];

  const oddsDecreased = checkOddsDecreased(
    bets,
    undefined,
    moMBet ?? undefined
  );

  // If there are no editable and no clearable bets, display nothing
  // Otherwise, display clear bets button when punter is a) already in editing stage, or b) has no editable bets
  const displayClearBetsButton =
    viewMode === EBetSlipViewMode.EditingBets || !hasEditableBets;
  const clearEditBetsComponent =
    !hasEditableBets && !hasClearableBets ? (
      <></>
    ) : (
      <Button
        {...buttonClearBetsProps}
        onClick={displayClearBetsButton ? clearAll : clickEditBets}
        data-cy={
          displayClearBetsButton ? 'clearBetSlipButton' : 'editBetSlipButton'
        }
        leftIcon={
          <Icon
            as={displayClearBetsButton ? CloseOutline : Edit}
            {...(betslipFooterStyles.iconClearBet as any)}
          />
        }
      >
        {displayClearBetsButton
          ? `${Strings.betSlipModal.clearBets}`
          : `${Strings.betSlipModal.editBets}`}
      </Button>
    );

  if (!hasEditableBets && !hasClearableBets) {
    return <></>;
  }

  return (
    <FooterHOC>
      {/* This is the portal we render the keypad into on mobile  */}
      <FlexResponsibleGamblingBannerWrapper>
        <ResponsibleGamblingBanner
          bannerLocation={EResponsibleGamblingBannerLocation.BetslipFooter}
        />
      </FlexResponsibleGamblingBannerWrapper>
      <BetSlipFooterContainer>
        <>
          {clearEditBetsComponent}
          {displayOddsChangeBanner && (
            <>
              {oddsDecreased && (
                <OddsChangedBanner isInFooter direction="decrease" />
              )}
              {/* {oddsIncreased && (
                <OddsChangedBanner isInFooter direction="increase" />
              )} */}
            </>
          )}

          {displayEventsClosedBanner && <EventsClosedBanner />}

          <Flex flexDirection="column" mt="2" mb="3">
            {totalRows.map((row) => (
              <FooterRowContainer key={row.title}>
                <BetSlipFooterRowTitle type={row.type}>
                  {row.title}
                </BetSlipFooterRowTitle>
                <BetSlipFooterRowValue type={row.type} data-cy={row.cyLabel}>
                  {row.value}
                </BetSlipFooterRowValue>
              </FooterRowContainer>
            ))}
          </Flex>
        </>

        {hasSufficientFunds || viewMode === EBetSlipViewMode.EditingBets ? (
          <Flex w="full" justifyContent="space-between" dir="row" gap="1">
            <Button
              {...buttonConfirmBetsProps}
              onClick={handleSubmit}
              isLoading={submissionLoading}
              data-cy="submitBetSlipButton"
              isFullWidth
              isDisabled={isConfirmButtonDisabled}
            >
              {submitButtonTitle()}
            </Button>
          </Flex>
        ) : (
          <>
            <LowStakePrompt>
              <LowStakeDivider mb="3" />
              <Text fontWeight="bold" fontSize="lg">
                <FormattedMessage id="betslip.betslipmodal.lowerstakeheading" />
              </Text>
              <Text>
                <FormattedMessage id="betslip.betslipmodal.lowerstakedescription" />
              </Text>
            </LowStakePrompt>
            <LowStakeWrapper>
              <Button
                {...buttonMakeDepositProps}
                onClick={() => {
                  dispatch(setBetSlipOpen(false));
                  navigate('/account/deposit');
                }}
                data-cy="makeDepositButton"
                isFullWidth
              >
                <FormattedMessage id="betslip.betslipmodal.makeadeposit" />
              </Button>
            </LowStakeWrapper>
          </>
        )}
      </BetSlipFooterContainer>
    </FooterHOC>
  );
};
