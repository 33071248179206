import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  pt: '1',
};
export const TextTitle: CSSObject = {
  color: 'beta.800',
  fontSize: 'sm',
  mb: '2',
};
export const PageInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  color: 'beta.800',
};
export const SmallHeading: CSSObject = {
  color: 'beta.800',
  fontSize: 'sm',
  mb: '2',
};
export const Text: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  color: 'beta.800',
};
export const ImageGamblersHelp: CSSObject = {
  bg: 'white',
  p: '2',
  mt: ['1', null, '0'],
  maxW: '417px',
  maxH: '72px',
  borderRadius: 'lg',
  boxShadow:
    '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px var(--bc-colors-whiteAlpha-600) inset, 0px 3px 6px 0px var(--bc-colors-blackAlpha-500)',
};
export const FlexResponsibleGamblingWrapper: CSSObject = {
  my: '2',
  maxW: 'full',
};
export const Link: CSSObject = {
  color: 'delta.500',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecoration: 'none',
  },
};
export const SubHeading: CSSObject = {
  color: 'beta.800',
  fontSize: 'sm',
  mb: '2',
};
export const GridWrapper: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
  pb: '0',
  maxW: 'full',
};

export const ButtonAction: CSSObject = {
  bgGradient: 'linear(to-b, beta.700, beta.800)',
  color: 'beta.500',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'accent',
  borderRadius: 'md',
  letterSpacing: 'wide',
  fontWeight: 'hairline',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  mx: '0',
  my: '1',
  _hover: {
    bgGradient: 'linear(to-b, delta.400, delta.500)',
    color: 'white',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  },
  _focus: {
    bgGradient: 'linear(to-b, beta.900, beta.900)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  _disabled: {
    bgGradient: 'linear(to-b, gray.400, gray.400)',
    borderColor: 'gray.600',
    color: 'gray.200',
    textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  '&::after': {
    bg: 'transparent',
  },
};
