import * as yup from 'yup';

import { getStrings } from '@/helpers/utils';

const [
  {
    Onboarding: { Step3 },
  },
] = getStrings();

const depositLimitSchema = yup
  .object({
    depositLimit: yup.string().oneOf(['false', 'true', 'keep'], '').required(),
    limitAmount: yup
      .string()
      .matches(/^\d+$/, { message: Step3.LimitNumbersOnlyError })
      .when('depositLimit', {
        is: 'true',
        then: yup.string().required(Step3.LimitAmountRequiredText),
      }),
    limitDuration: yup.string().when('depositLimit', {
      is: 'true',
      then: yup
        .string()
        .oneOf(Step3.LimitPeriodOptions)
        .required(Step3.LimitDurationRequiredText),
    }),
  })
  .required();

export const depositLimitSchemaNoLimit = yup
  .object({
    depositLimit: yup.string().oneOf(['false', 'true', 'keep'], '').required(),
    limitAmount: yup
      .string()
      .matches(/^\d+$/, { message: Step3.LimitNumbersOnlyError })
      .when('depositLimit', {
        is: 'true',
        then: yup.string().required(Step3.LimitAmountRequiredText),
      }),
  })
  .required();

export type DepositLimitSchema = yup.InferType<typeof depositLimitSchema>;

export default depositLimitSchema;
