import { PersonalDetailsSchema } from './PersonalDetails.styles';

export const personalDetailsStyles: PersonalDetailsSchema = {
  wrapperBox: {
    bg: 'white',
    p: '2',
    sx: {
      '&&': {
        '.chakra-form__label': {
          color: 'black',
        },
        '.chakra-input': {
          borderLeftRadius: 'md',
          borderLeft: '1px',
          borderColor: 'gray.300',
          bg: 'white',
          color: 'gamma.700',
          boxShadow: 'none',
          fontWeight: 'normal',
          _hover: {
            bg: 'gray.100',
            borderColor: 'gray.400',
          },
        },
      },
    },
  },
};
