import { CSSObject } from '@chakra-ui/react';

export const TextSub: CSSObject = {
  color: 'gray.500',
  width: '300px',
  fontSize: 'sm',
};

export const FlexSignedOutText: CSSObject = {
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
};
