import { chakra } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { styleImports } from './InlineLink.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const ILink = chakra(Link, {
  baseStyle: () => ({
    label: 'inlineLink-ILink',
    mx: '1',

    ...(() => {
      try {
        return styleImports[themeName]?.ILink;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
