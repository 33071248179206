import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px dashed',
      borderTopColor: 'gray.200',
      p: '2',

      _first: { borderTop: 0 },
    }),

    ...(isSelected && {
      boxShadow: 'none',
    }),

    ...(!!isSelected && {
      boxShadow: 'none',
    }),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',

    _first: {
      color: 'gamma.500',
      fontWeight: 'bold',

      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
        _first: {
          color: 'gamma.500',
        },
      }),
    },

    '&.return-amount': {
      bgGradient: isSelected
        ? 'linear(to-b, gamma.500, gamma.600)'
        : 'linear(to-b, alpha.500, alpha.600)',
      p: 2,
      w: 'full',
      borderRadius: 'lg',
      border: '2px',
      borderColor: 'alpha.700',
      color: 'white',
      fontWeight: 'black',
      textShadow: isSelected ? 'unset' : '0px 2px 3px rgba(0, 0, 0, 0.3)',
      boxShadow: isSelected
        ? 'inset 0px 2px 0px 2px rgba(0, 0, 0, 0.3)'
        : 'inset 0px 0px 0px 2px #426DD6',
      transition: 'all, .3s ease-in-out',

      _hover: {
        bgGradient: isSelected
          ? 'linear(to-b, gamma.600, gamma.600)'
          : 'linear(to-b, alpha.700, alpha.700)',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
