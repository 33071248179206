import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  px: '3',
  py: '4',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'base',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  alignItems: 'center',
  bg: 'linear-gradient(180deg, var(--bc-colors-gamma-400) 0%, var(--bc-colors-gamma-500) 100%, var(--bc-colors-gamma-600) 100%)',
  maxH: ['unset', null, '46px'],
  mb: '2',
};

export const TextMustBeVerified: CSSObject = {
  color: 'gamma.900',
  fontSize: 'sm',
  fontWeight: 'bold',
};

export const ButtonGetVerified: CSSObject = {
  color: 'white',
  bgGradient: 'linear(to-b, brand.600, brand.700)',
  borderRadius: 'base',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'extrabold',
  px: '2.5',
  py: '1',
  ml: 'auto',
  minW: 'unset',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
  h: '8',
};
