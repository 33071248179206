export const en = {
  'footer.copyright': 'Volcano (Aust) Pty Ltd t/a VolcanoBet | v{appVersion}',
  'sports.allsports.azheading': 'A-Z',
  'home.haveAPunt': 'Have a punt',
  'betslip.betslipmodal.emptybetslipheading': 'No bets, rock empty!',
  'account.currentlimit.heading': 'Deposit Limit',
  'account.depositlimit.setlimit.subheading':
    'Helps you to set a budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'account.currentlimit.depositlimitlabel': 'Deposit limit',
  'account.settings.responsible.lastupdated': 'Published: 30/03/2023',
  'account.withdraw.addbank': 'Add new Bank Account',
  'account.withdraw.requests.heading': 'Request',
  'error.heading': 'Was that a <b>tremor?!</b>',
  'maintenance.heading': `We're down for <b>maintenance</b>`,
  'maintenance.error':
    '<bold>Best to steer clear while we investigate the issue.</bold> <span>Refresh the page, or try again later.</span>',
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing VolcanoBet from outside Australia.</b>',
  'onboarding.login.title':
    '<Prefix>Embark on a </Prefix><Suffix>volcanic adventure</Suffix>',
  'onboarding.login.subtitle':
    'Welcome to <b>VolcanoBet</b>, the ultimate destination for racing and sports betting. Unlock your account and begin <b>placing your wagers right away!</b>',
  'onboarding.step1.pageTitle':
    '<Prefix>Embark on a </Prefix><Suffix>volcanic adventure</Suffix>',
  'onboarding.step1.pageSubtitle':
    'Welcome to <b>VolcanoBet</b>, the ultimate destination for racing and sports betting. Unlock your account and begin <b>placing your wagers right away!</b>',
  'onboarding.step1.button': 'Join Now',
  'onboarding.register.createAccount': 'Create an account',
  'onboarding.depositLimit.completeSignup': 'Continue',
  'onboarding.locationnotallowed.header':
    '<b>Find a volcano</b> closer to home.',
  'racing.errorMessages.noRacesAvailable': 'No races today, hunt postponed.',
  'betslip.betslipbetcard.errors.propositionclosed': 'This Event is closed',
  'header.betslipBtn.desc': 'Slip',
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'legal.responsible.section.takeaction.depositlimit.two':
    'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.depositlimit.button': 'Set limit',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.deactivate.button': 'Shut My Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': 'Exclude Yourself',
  'legal.responsible.section.takeaction.lifeexclusion.two':
    'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.lifeexclusion.button': 'Set Exclusion',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.break.two':
    'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.section.takeaction.break.button': 'Suspend My Account',
  'account.withdraw.requests.cancelbutton': 'Cancel withdrawal request',
  'account.updateconfirmmodal.title': 'Update deposit limit',
  'betslip.betslipbetcard.stakeprefix': '$',
};
