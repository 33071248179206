import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'odds',
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontWeight: 'extrabold',
    fontFamily: 'body',
    fontSize: '8px',
  },
  buttonBaseBox: {
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
  },
  textBase: {
    textAlign: 'center',
    color: 'alpha.800',
  },
};
