/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Icon } from '@chakra-ui/react';
import IconSvg, { TIconSvg } from './IconSvg.webpack';

export * from './IconSvg.webpack';

const FAIcon = (props: TIconSvg) => {
  const { name } = props;
  const _name = name
    .split('sports/')?.[1]
    ?.toLocaleLowerCase()
    ?.replace(/ /g, '-');

  return React.createElement(
    Icon,
    props,
    null,
    React.createElement('i', { className: `fa-kit fa-${_name}` })
  );
};

export default (props: TIconSvg) => {
  const isSport = String(props.name ?? '').includes('sports/');
  if (isSport) return FAIcon(props);
  return IconSvg(props);
};
