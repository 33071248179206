import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'gamma.500',
    alignItems: 'center',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
    mb: '2.5',
  },
  depositIconProps: {
    color: 'alpha.500',
    boxSize: '8',
    my: 'auto',
  },
};
