import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '../../../../lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  alignItems: 'center',
  color: 'white',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'medium',
  mb: '1',
};

export const BackButton: CSSObject = {
  h: '6',
  minW: '6',
};

export const TextDescription: CSSObject = {
  fontSize: '11px',
  color: 'white',
  fontWeight: 'medium',
};

export const DividerWithdrawal: CSSObject = {
  display: 'none',
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
};

export const BankRadioWrapper: CSSObject = {
  borderBottom: '2px solid',
  borderBottomColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.25)',
  px: '0',
  py: '3',
  pl: '2',
};

export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
  lineHeight: 'normal',
  mb: '1',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
  gap: '4',
  lineHeight: 'normal',
  color: 'white',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',

  '&&': {
    '.chakra-input__left-addon': {
      bg: 'gamma.500',
      px: '4',
      fontWeight: 'bold',
      h: '40px',
    },
    '.chakra-input': {
      bg: 'white',
      boxShadow: 'none',
      h: '40px',
      _hover: {
        bg: 'beta.200',
      },
      _placeholder: {
        color: 'gamma.900',
      },
    },
  },
};

export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'secondary',
  borderRadius: 'base',
  fontSize: 'xs',
};

export const IncreaseAmountWrapper: CSSObject = {
  gap: '1',
  mb: '1',
};

export const ButtonIncrease: CSSObject = {
  bg: 'gamma.500',

  _hover: {
    bg: 'gamma.600',
  },

  _active: {
    bg: 'gamma.700',
  },
};

export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  ml: [null, null, '4'],
  mt: '5',
  p: '0',
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

export const WithdrawalWrapper: CSSObject = {
  bg: 'white',
  borderColor: 'delta.200',
  boxShadow: 'none',
  color: 'gray.700',
  m: '0',
  p: '2.5',

  '&&': {
    fontSize: 'xs',
  },

  '& + &': {
    mt: '2',
  },
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  color: 'gray.700',

  '.withdrawName': {
    fontSize: 'xs',
  },

  _first: {
    color: 'gray.500',
    fontSize: '2xs',
  },
};

export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  bg: status === EWithdrawalStatus.Pending && 'delta.300',
  color: status === EWithdrawalStatus.Pending && 'gamma.900',
  fontSize: '2xs',
  px: '1',
  py: '0.5',
  textTransform: 'uppercase',
});

export const ButtonWithdrawalCardCancel: CSSObject = {
  fontSize: undefined,
  p: undefined,
  bg: 'beta.500',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.45), 0px 0px 0px 2px rgba(254, 186, 255, 0.70) inset',
  borderColor: 'white',

  span: {
    color: 'beta.700',
  },
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const InputWithdrawal: CSSObject = {
  '&&': {
    '.chakra-input__left-addon': {
      bg: 'gamma.500',
      px: '4',
      fontWeight: 'bold',
    },
    '.chakra-input': {
      bg: 'white',
      boxShadow: 'none',
      _hover: {
        bg: 'beta.200',
      },
      _placeholder: {
        color: 'gamma.900',
      },
    },
  },
};

export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form-control': {
    '.chakra-input': {
      bg: 'gamma.100',
      _focus: {
        bg: 'gamma.200',
        border: '2px',
        borderColor: 'gamma.500',
      },
      _hover: {
        bg: 'gamma.100',
        border: '1px',
        borderColor: 'gamma.500',
      },
    },
    '.chakra-form__label': {
      color: 'white',
      fontSize: '13px',
      fontWeight: 'semibold',
      lineHeight: '133%',
    },
    '.chakra-form__helper-text': {
      color: 'white',
      fontSize: 'xs',
      fontWeight: 'medium',
    },
  },
};

export const WithdrawalCardDetailHeading: CSSObject = {
  fontSize: 'sm',
  color: 'gray.700',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};
