import { TLogoStyles } from './Logo.styles';

const styles: Partial<TLogoStyles> = {
  img: {
    display: {
      base: 'none',
      lg: 'block',
    },
  },
};
export default styles;
