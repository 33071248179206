import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  bg: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  border: 'none',
};

export const ExoticSelectionText: CSSObject = {
  color: 'gray.400',
  fontWeight: '500',
};

export const DividerCard: CSSObject = {
  borderColor: 'gray.300',
};

export const ContentRow: CSSObject = {
  '> svg.chakra-icon': {
    borderRadius: 'none',
    bg: 'transparent',
    color: 'alpha.400',
    fill: 'alpha.400',
    boxSize: '8',
    p: '1',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'beta.400',
    bg: 'alpha.800',
    border: '2px solid',
    borderColor: 'alpha.800',
  },
};
