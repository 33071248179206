import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'xl',
  fontWeight: 'normal',
  fontFamily: 'accent',
  color: 'beta.800',
  ml: ['2', null, '0'],
  mb: ['2.5', null, '3'],
};
export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'beta.800',
};
