import { css } from 'styled-components';

// TODO: Remove this
export const container = css`
  background: transparent;
  border-radius: var(--bc-radii-sm);
  box-shadow: inset 0 3px 20px -1px var(--bc-colors-blackAlpha-500);
  margin-bottom: var(--bc-sizes-4);
  padding: 5px;
  width: 100%;
`;

// TODO: Remove
export const wrapper = css`
  position: relative;
  width: 100%;
`;

// TODO: Remove
export const toggleOption = css`
  align-items: center;
  color: ${({ theme }) => theme.basePalette.white};
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  font-size: var(--bc-fontSizes-md);
  padding: 5px 0;
  transition: color, 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

// TODO: Remove
export const selectedMask = css`
  box-shadow: inset 0 0 2px ${({ theme }) => theme.basePalette.white},
    0 0 4px ${({ theme }) => theme.basePalette.white};
  background: ${({ theme }) => theme.basePalette.brightBlue};
  transition: all, 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const selectedToggleOption = css`
  color: ${({ theme }) => theme.basePalette.navyBlue};
  transition: color 0.2s linear;
`;

export const toggleOptionIcon = css`
  color: ${({ theme }) => theme.basePalette.white};
  margin-right: 5px;
  height: 20px;
  transition: color 0.2s linear;
`;

export const selectedToggleOptionIcon = css`
  color: ${({ theme }) => theme.basePalette.navyBlue};
`;
