import * as yup from 'yup';
import dayjs from 'dayjs';

import { getStrings } from '@/helpers/utils';

const [
  {
    Account: { DepositLimit: strings },
  },
] = getStrings();

const depositLimitSchema = yup
  .object({
    amount: yup
      .string()
      .nullable(true)
      .matches(/^\d+$/, { message: strings.errorStrings.numbersOnly })
      .test(
        'cannot increase limit within cooling off time',
        strings.errorStrings.cannotIncrease,
        (limitAmount, testContext) => {
          const { coolingOffEnds, currentLimit } = testContext.options
            .context as { coolingOffEnds: string; currentLimit: number };

          const formLimitAmount = parseInt(limitAmount || '0', 10);

          if (formLimitAmount === currentLimit) {
            return testContext.createError({
              message: strings.errorStrings.sameValue,
            });
          }

          if (
            formLimitAmount > currentLimit &&
            dayjs().isBefore(coolingOffEnds)
          ) {
            return testContext.createError({
              message: strings.errorStrings.cannotIncrease.replace(
                '%',
                dayjs(coolingOffEnds).format('DD/MM/YY hh:mm A')
              ),
            });
          }
          return true;
        }
      ),
    frequency: yup.string().nullable(true).oneOf([null, '1', '7', '14', '28']),
  })
  .required();

export type DepositLimitSchema = yup.InferType<typeof depositLimitSchema>;

export default depositLimitSchema;
