import { CSSObject } from '@chakra-ui/react';

export const ExoticsWrapper: CSSObject = {
  px: '4',
  pb: '2',
};

export const ExoticsContainer: CSSObject = {
  p: '2',
  border: '2px',
  borderColor: 'gamma.500',
  bg: 'alpha.500',
  borderRadius: 'lg',
};

export const CheckboxBoxed: CSSObject = {
  justifyContent: 'flex-end',
  right: 0,
  mr: '3',
  '.chakra-checkbox__control': {
    bg: 'alpha.700',
    color: 'gamma.400',
    borderColor: 'gamma.400',
    border: '1px solid',
    mr: '1.5',
    _hover: {
      bg: 'alpha.800',
    },
  },
  _checked: {
    bg: 'alpha.700',

    '.chakra-checkbox__control': {
      border: '1px',
      borderColor: 'gamma.400',
      bg: 'alpha.700',
    },
  },

  '.chakra-checkbox__label': {
    marginInlineStart: '0',
  },
};

export const TextBoxed: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const SelectFieldContainer: CSSObject = {
  gap: '1',
  w: 'unset',
};
