import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  color: 'beta.300',
  fontWeight: 'medium',
  height: 'auto',
  border: 'none',
  ml: '1.5',
  fontFamily: 'Fraunces',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
  fontSize: 'md',

  '& .state-inprogress': {
    color: 'black',
  },
  svg: {
    pt: '1',
    pos: 'relative',
    bottom: '0.5',
  },
};

export const FlexHeadingMiscWrapper: CSSObject = {
  zIndex: 'overlay',
  '.chakra-badge.state-inprogress': {
    color: 'black',
  },
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', 'row'],
    alignItems: ['flex-start', 'center'],

    '.timestamp': {
      bg: 'rgba(0, 0, 0, 0.36)',
      borderRadius: 'base',
      color: 'white',
      fontWeight: 'bold',
      fontFamily: 'body',
      p: '1',
      fontSize: '2xs',
      mb: '0.5',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
  bg: 'whiteAlpha.400',
  border: 'none',
};

export const TextSportName: CSSObject = {
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

export const TextVS: CSSObject = {
  alignSelf: 'end',
  color: 'white',
};
