import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'beta.500', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'beta.500'] },
};

export const FlexHeadingRow: CSSObject = {};

export const FlexHeading: CSSObject = {
  color: 'white',
  top: ['12', '84px'],
  position: 'unset',
  mb: '0',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  textTransform: 'uppercase',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'beta.500',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  border: '2px',
  borderColor: 'beta.500',
  borderRadius: 'md',
  boxShadow:
    '0px 1px 0px 1px rgba(255, 255, 255, 0.50) inset, 0px 0px 0px 1px #000 inset',
};

export const BoxPageWrapper: CSSObject = {
  m: '0',
  mb: '4',
  p: '0.5',
  bg: 'alpha.500',
  borderRadius: 'lg',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

export const BoxTableWrapper: CSSObject = {
  p: '0',
  bg: 'alpha.500',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'beta.500',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextRaceResults: CSSObject = {
  color: 'white',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = () => ({
  bg: ['transparent', null, '#0046A7'],
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
  _hover: {
    background: 'blackAlpha.300',
  },
  _last: {
    borderRight: 'none',
  },
});

export const FlexRow: CSSObject = {
  mb: '0',
  borderRadius: 'none',

  _last: {
    borderBottom: 'none',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  bg: '#00429D',
  py: '1.5',
  borderColor: 'unset',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderRight: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
};

export const CenterTH = () => ({
  bg: 'alpha.400',
  color: 'white',
  textAlign: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'extrabold',
  lineHeight: 'normal',
  py: '2.5',
  borderBottom: '1px',
  borderColor: 'whiteAlpha.200',
});

export const ButtonChevron: CSSObject = {
  color: 'beta.500',
  bg: 'alpha.400',
  borderRadius: 'base',
  boxSize: '7',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BadgeRaceNumber: CSSObject = {
  bg: 'gray.300',
  color: 'gray.600',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
