import React from 'react';
import { BoxHeader, ContainerWrapper, LinkLogo } from './styles/Basic.styles';
import Logo from './conditionals/Logo/template';
import CloseButton from '../../../wrappers/OnboardingWrapper/components/CloseButton';

export default function Basic() {
  return (
    <BoxHeader>
      <ContainerWrapper>
        <LinkLogo to="/">
          <Logo />
        </LinkLogo>

        <CloseButton />
      </ContainerWrapper>
    </BoxHeader>
  );
}
