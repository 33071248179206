import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'lg',
    my: 2,
    px: '4',
    color: 'white',
    textTransform: 'uppercase',
    sx: {
      button: {
        fontFamily: 'accent',
        fontWeight: 'normal',
        fontSize: 'sm',
        ':active, &[data-active="true"]': {
          color: 'alpha.400',
          fontWeight: 'normal',
          border: 'none',
          borderBottom: '2px solid',
          borderBottomColor: 'alpha.400',
          pt: '2',
        },
      },
    },
  },
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'sm',
    mt: '-2',
    borderTopColor: '#1d3b52',
    borderTopWidth: '1px',
    boxShadow: '0 1px #3c607c inset',
    paddingTop: 3,
    mb: 3,
  },
  promotionWrapper: {
    bg: 'rgba(78, 178, 255, 0.14)',
    my: '3',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: 'md',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
      'img + div': {
        flexDirection: ['column', 'row'],
      },
      p: {
        display: 'block',
        fontSize: 'xs',
        u: {
          marginLeft: '3px',
        },
      },
      a: {
        button: {
          ml: 0,
          w: ['100%', 'auto'],
        },
      },
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    mx: '1px',
  },
  termsAndConditions: {
    color: 'white',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
