import React from 'react';
import { Global } from '@emotion/react';
import background from '@/assets/templebet/images/backgrounds/background.svg';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${background}), linear-gradient(var(--bc-colors-delta-800) 0%, var(--bc-colors-delta-900) 100%)`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          '@media (max-width: 480px)': {
            background:
              'linear-gradient(180deg, var(--bc-colors-delta-800), var(--bc-colors-delta-900))',
          },
        },
      }}
    />
  );
}
