import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'primary',
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    m: '-1',
    fontSize: '2xs',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  },
  buttonBaseBox: {
    fontSize: 'sm',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
