import React from 'react';
import { BoxProps, CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  iconMysteryBetFooter: {
    color: 'alpha.200',
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    fontFamily: 'roboto',
  },
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    fontFamily: 'Fredoka',
    fontSize: 'xs',
    w: '-webkit-fill-available',
    h: 'auto',
    py: 1.5,
  },
  buttonBonusBetsProps: {
    textTransform: 'capitalize',
    bgGradient: 'linear(180deg, gamma.500, gamma.600)',
    color: 'white',
    borderRadius: 'md',
    border: 'none',
    boxShadow: 'none',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '133%',
    h: '6',
    px: '1.5',

    _disabled: {
      border: 'none',
      bg: 'beta.800',
      color: 'alpha.300',
      opacity: '1',
      boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
      textTransform: 'uppercase',
      textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
    },

    sx: {
      '&&': {
        minW: '20',
        '&&[data-isbonusbet="true"]': {
          borderRadius: 'md',
          border: '1px',
          borderColor: 'gamma.700',
          boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.40) inset',
        },
      },
    },
  },
  buttonStakeProps: { fontFamily: 'fredoka' },
  buttonBetPropositionClosedProps: {
    h: '8',
    borderRadius: 'lg',
  },
};

const defaultFontColor = 'epsilon.700';

export const StakeCollapse: CSSObject = {
  px: '2',
};

export const BetCardVStack: CSSObject = {
  pb: '0',
};

export const BetCardContainer = (): CSSObject => ({
  position: 'relative',
  bgGradient: 'linear(to-b, epsilon.400, epsilon.500)',
  boxShadow:
    '0px 4px 0px 0px #B68452, 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px 0px 8px 0px rgba(255, 255, 255, 0.40) inset',
  borderRadius: 'base',
  p: '0',
  color: 'white',
  '&::before': {
    content: `""`,
    position: 'absolute',
    borderRadius: 'md',
    bgSize: '100%',
    bgRepeat: 'no-repeat',
    top: '-5',
    w: '100%',
    h: '8',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: defaultFontColor,
  mr: '-1',
  ml: '1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: defaultFontColor,
  display: 'flex',
  fontWeight: '800',
  fontSize: '16px',
  fontFamily: 'roboto',
};

export const ExoticsFooterContainer: CSSObject = {
  '&&': {
    mb: ['1.5', null, 'unset'],
  },
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  lineHeight: '4',
  flex: '1',
  textTransform: 'capitalize',
  color: defaultFontColor,
  display: 'flex',
  gap: '1',
  ml: '2',
  pr: '2',
  svg: {
    mb: '1.5',
    color: 'beta.800',
  },
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: defaultFontColor,
  fontWeight: 'medium',
  textTransform: 'capitalize',
  ml: '2',
});

export const EventSubtitle: CSSObject = {
  color: defaultFontColor,
  textTransform: 'capitalize',
  fontSize: 'xs',
  ml: '2',
  mt: 'auto',
  fontWeight: '500',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  width: '25%',
  justifyContent: 'space-between',
};

export const ExoticsNumberContainer: CSSObject = {
  color: 'epsilon.800',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'epsilon.800',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgImage: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%);',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF;',
  borderRadius: 'md',
  flexDirection: 'column',
  color: 'black',
  px: '2',
  py: '1.5',
  mb: '2',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'epsilon.800',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '0.5',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
  color: 'gray.600',
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgba(0,0,0,0.4)',
  borderRadius: 'base',
  position: 'absolute',
  top: 0,
  left: 0,
  w: '100%',
  h: 'calc(100% + 4px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'epsilon.700' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
  gap: '0',
  my: '0',
  p: '2',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5', '&:hover': { color: 'white' } }} />
);

export const EventRule: CSSObject = {
  ml: '8',
  color: 'epsilon.800',
};

export const betRecoveryAlertWrapperProps: BoxProps = {
  px: '0',
};
