/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { TTemplate } from '@/helpers/createTemplate';
import { Button } from '@/components/Button/Button';
import { exoticsActionStyles } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

const template = (
  isChecked: boolean,
  isDisabled: boolean,
  selectAllPositions: () => void,
  runnerNumber: number | undefined
): TTemplate => ({
  button: (
    <Button
      {...exoticsActionStyles.buttonExoticsActionProps}
      data-testid={
        runnerNumber !== undefined
          ? `runnerCheckbox-${runnerNumber}`
          : 'fieldCheckbox'
      }
      key={!runnerNumber ? 'fieldCheckbox' : ''}
      area-selected={isChecked}
      data-active={isChecked}
      data-cy={
        runnerNumber !== undefined
          ? `runnerCheckbox-${runnerNumber}`
          : 'fieldCheckbox'
      }
      isSelected={isChecked}
      isDisabled={isDisabled}
      onClick={selectAllPositions}
      gap="2"
    />
  ),
});

export default template;
