import { CSSObject } from '@chakra-ui/react';
import { StatementCardSchema } from '@/views/account/Transactions/components/ActivityStatements/components/StatementCard/styles/StatementCard.styles';

export const statementCardStyles: StatementCardSchema = {
  buttonDownload: {
    variant: 'solid',
    pt: '1',
  },
};

export const FlexInner: CSSObject = {
  borderBottomColor: 'blackAlpha.500',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  minH: '12',
  py: '2',
  '&:last-of-type': {
    borderBottomColor: 'transparent',
  },
};

export const TextMonth: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  color: 'white',
};
