import { CSSObject } from '@chakra-ui/react';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'alpha.700',
};

export const HeaderSvg: CSSObject = {
  borderRadius: 'full',
  color: 'alpha.700',
  boxSize: '8',
  p: '1',
};

export const BetMultiIcon: CSSObject = {
  bg: 'alpha.700',
  color: 'white',
  p: '1',
};

export const headerRowStyles: HeaderRowSchema = {
  mysteryBetIcon: {
    fill: 'blue.400',
  },
};
