import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flextEventSubTitleProps: FlexProps = {
  alignItems: 'center',
};
export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  px: '2',
  pt: '2',
  w: 'fill-available',
  borderRadius: 'none',
  color: 'white',
});
export const FlexIconPropName: CSSObject = {
  gap: '2',
};
export const SpanBarrierNumber: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'medium',
  pos: 'relative',
  bottom: 'px',
};
export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.400',
});
export const LinkMarket: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontFamily: 'accent',
};
