import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    fontFamily: 'roboto',
    fontSize: '2xs',
    lineHeight: '1.125',
    textTransform: 'none',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    border: '2px',
    borderColor: 'white',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
    sx: {
      minW: '20',

      '&:active, &[data-active="true"]': {
        color: 'beta.800',
        textShadow: 'none',
        border: 'none',
        bg: 'beta.100',
        boxShadow: '0px 2px 4px 0px #A97F81 inset',
        _hover: {
          bg: 'beta.200',
        },
      },
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  },
  buttonBaseBox: {
    fontSize: 'sm',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
