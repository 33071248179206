/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { InputProps } from '@chakra-ui/input';
import theme from '@chakra-ui/theme';

export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      fontFamily: 'accent',
      color: 'white',
      fontWeight: 'bold',
      caretColor: 'currentColor',

      '&': {
        fontSize: 'sm',
        border: '2px solid',
        borderRightRadius: 'md',
        borderColor: 'beta.500',
        bg: 'blackAlpha.600',
        borderLeft: 'none',
      },

      '&::placeholder': {
        color: 'gray.300',
      },

      _hover: {
        '&': {
          border: '2px solid',
          borderRightRadius: 'md',
          borderColor: 'beta.300',
          borderLeft: 'none',
          boxShadow: '0px 0px 8px 0px rgba(255, 176, 40, 0.6)',
        },
      },
      _focus: {
        '&': {
          border: '2px solid',
          borderColor: 'alpha.500',
          borderLeft: 'none',
          boxShadow: '0px 0px 12px 0px rgba(136, 218, 19, 0.6)',
        },
      },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
    addon: {
      fontWeight: 'bold',
      fontSize: 'md',
      color: 'gray.700',
      '&': {
        fontFamily: 'accent',
        bg: 'beta.500',
        borderLeftRadius: 'md',
        border: 'none',
        mr: '0',
        _groupFocusWithin: {
          bg: 'alpha.500',
          boxShadow: '0px 0px 12px 0px rgba(136, 218, 19, 0.6)',
        },
      },
    },
  },
  sizes: {
    lg: { addon: { borderRadius: '4px' }, field: { borderRadius: '4px' } },
    md: {
      addon: { borderRadius: '4px', h: '38px' },
      field: { borderRadius: '4px', fontSize: 'xs', px: '2', h: '38px' },
    },
  },
  variants: {
    unstyled: (props: InputProps) => {
      const { addon, field } = theme.components.Input.variants.outline(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props as any
      );

      return {
        addon,
        field: {
          ...field,
          boxShadow: 'none',
          '&': {
            color: 'black',
            bg: 'white',
            borderRadius: 'base',
            h: '40px',
            borderColor: 'white',
            pl: '2.5',
          },
          '&:focus, &:active, &:hover': {
            '&': {
              bg: 'white',
              borderColor: 'white',
              color: 'black',
            },
          },
        },
      };
    },
  },
  defaultProps: {},
};
