import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { homeLinkStyles } from './styles/HomeLinks.styles';
import HomeLinksForRace from '@/views/Home/links/components/HomeLinks/HomeLinksForRace';
import HomeLinksForSport from '@/views/Home/links/components/HomeLinks/SportHomeLinks/HomeLinksForSport';

/**
 * @constructor this component will show both race and sport links on race page only by default,
 * sport page won't have any links unless showOnHomeSports is set to true i.e: <HomeLinks showOnHomeSports />
 */
export default function HomeLinks({ showOnHomeSports = false }) {
  const { data: offerings } = useQueryOfferings();
  const [searchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  if (
    searchParams?.get('category')?.toLowerCase() === 'sport' &&
    !showOnHomeSports
  ) {
    return <></>;
  }

  const isRacing = !category || category === 'racing';

  return (
    <Flex {...homeLinkStyles.wrapper} data-isRacing={isRacing}>
      {FEATURE_FLAGS.HAS_RACE_VIEW && offerings?.offered_race_types?.length && (
        <HomeLinksForRace />
      )}
      {FEATURE_FLAGS.HAS_SPORTS_VIEW && offerings?.offered_sports?.length && (
        <HomeLinksForSport />
      )}
    </Flex>
  );
}
