import * as yup from 'yup';

import { getStrings, centsToDollars, dollarsToCents } from '@/helpers/utils';

const [
  {
    Account: { Withdraw: strings },
  },
] = getStrings();

const withdrawFundsSchema = yup
  .object({
    amount: yup
      .number()
      .min(10, strings.errorStrings.minimumAmount)
      .required(strings.errorStrings.amountRequired)
      .test(
        'maximum does not exceed withdraw balance',
        strings.errorStrings.cannotWithdrawError,
        (amount, testContext) => {
          const { withdrawBalance } = testContext.options.context as {
            withdrawBalance: number;
            accountBalance: number;
          };

          if (amount && dollarsToCents(amount) > withdrawBalance) {
            return testContext.createError({
              message: `${strings.errorStrings.amountExceeds}${centsToDollars(
                withdrawBalance
              )}`,
            });
          }

          return true;
        }
      ),
    bank_id: yup.string().required(strings.errorStrings.bankRequired),
  })
  .required();

export type WithdrawFundsSchemaType = yup.InferType<typeof withdrawFundsSchema>;

export default withdrawFundsSchema;
