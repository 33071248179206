import { cssVar } from 'polished';
import { Palette, BasePalette } from '../../../types';

// Add more color type declarations in BasePalette before using here
export const basePalette: BasePalette = {
  white: 'var(--bc-colors-white)',
  offWhite: 'var(--bc-colors-yellow-200)',
  black: 'var(--bc-colors-black)',
  blue: cssVar('--bc-colors-blue-500', '#79bdff') as string,
  brightBlue: cssVar('--bc-colors-gamma-500', '#54E8F1') as string,
  brightBlueB: 'var(--bc-colors-gamma-400)',
  lightBlue: cssVar('--bc-colors-gamma-300', '#87eff5') as string,
  lightBlueFaint: 'var(--bc-colors-gamma-200)',
  midBlue: 'var(--bc-colors-epsilon-400)',
  medBlue: 'var(--bc-colors-accent-500)',
  darkBlue: 'var(--bc-colors-epsilon-600)',
  navyBlue: 'var(--bc-colors-epsilon-500)',
  lightPurple: 'var(--bc-colors-purple-600)',
  midPurple: 'var(--bc-colors-brand-500)',
  purple: 'var(--bc-colors-delta-500)',
  lightGrey: 'var(--bc-colors-gray-50)',
  darkGrey: 'var(--bc-colors-blackAlpha-900)',
  faintGrey: 'var(--bc-colors-blackAlpha-200)',
  red: 'var(--bc-colors-red-500)',
  green: 'var(--bc-colors-green-500)',
  orange: 'var(--bc-colors-orange-400)',
  lightOrange: 'var(--bc-colors-orange-100)',
  yellow: 'var(--bc-colors-yellow-500)',
  pink: 'var(--bc-colors-pink-200)',

  brightBlueGradient: `linear-gradient(180deg, ${cssVar(
    '--bc-colors-gamma-300',
    '#87eff5'
  )} 1%, ${cssVar('--bc-colors-gamma-500', '#54E8F1')} 90%)`,
  purpleLinearGradient: `linear-gradient(90deg, ${cssVar(
    '--bc-colors-brand-400',
    '#67208f'
  )} 0%, ${cssVar('--bc-colors-brand-600', '#4d0676')} 100%)`,
  blueBurstGradient180deg: `linear-gradient(180deg, ${cssVar(
    '--bc-colors-gamma-400',
    '#76edf4'
  )} 23%, ${cssVar('--bc-colors-gamma-600', '#4cd1d9')} 100%)`,
  greenGradient180deg: `linear-gradient(180deg, ${cssVar(
    '--bc-colors-green-400',
    '#48BB78'
  )} 0%, ${cssVar('--bc-colors-green-600', '#2F855A')} 100%)`,
  blueGradient120deg: `linear-gradient(120deg, ${cssVar(
    '--bc-colors-accent-400',
    '#6dd9ff'
  )} 13%, ${cssVar('--bc-colors-accent-900', '#0059A7')} 100%)`,
  blackGradient: `linear-gradient(180deg, ${cssVar(
    '--bc-colors-blackAlpha-400',
    'rgba(0, 0, 0, 0.24)'
  )} 30%, transparent 100%)`,
  navyBlueGradient: `linear-gradient(90deg, ${cssVar(
    '--bc-colors-epsilon-300',
    '#171C31'
  )} 0%, ${cssVar('--bc-colors-epsilon-200', '#273575')} 100%)`,
  darkShadow: '0 2px 24px 0 rgba(0,0,0,0.50)',
};

export const hexBetPalette: Palette = {
  /* --------- Brand --------- */
  primary: basePalette.brightBlue,
  secondary: basePalette.purple,
  tertiary: '',
  quaternary: '',

  /* ----------- UI ---------- */

  // Buttons
  btnPrimary: basePalette.blueBurstGradient180deg,
  btnSecondary: '',
  btnDeactivated: '',
  btnSuccess: '',
  btnError: '',
  btnWarning: '',
  btnHover: '#12283B',
  btnGrey: '',
  inputBg: basePalette.lightGrey,

  /* --------- Layout --------- */
  // Background
  bgPrimary: '',
  bgSecondary: basePalette.white,
  bgGradientPrimary: basePalette.purpleLinearGradient,
  bgGradientSecondary: basePalette.navyBlueGradient,
  containerShadow: basePalette.darkShadow,

  /* --------- Universal --------- */
  // Transparent colors
  transparentBlue: 'rgba(156, 242, 246, 0.20)',
  transparentDarkBlue: 'rgba(33,11,112,0.33)',

  // Neutral
  black: basePalette.black,
  white: basePalette.white,

  // Semantic
  danger: '',
  success: basePalette.green,
  warning: basePalette.red,
  info: '',
};
