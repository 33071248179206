import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { TRaceMarket, TRaceMeta } from '../../lib/DBModels';
import { keys } from '../api.keys';
import {
  queryRaceMarkets,
  queryRaceMeta,
  queryRaceVenueList,
  queryRunnerList,
} from './racing';
import {
  TQueryRaceVenueListParams,
  TRaceMarketsParams,
  TRaceMetaParams,
  TRunnerListParams,
  TRunnerListResponse,
  TVenueResponse,
} from './racing.types';

type TQueryVenueListHook = {
  key?: any[];
  options?: UseQueryOptions<TVenueResponse>;
  params?: TQueryRaceVenueListParams;
};

export const useQueryVenueList = ({
  key = [],
  options = {},
  params = {},
}: TQueryVenueListHook = {}) => {
  const query = useQuery(
    [keys.venueList, ...key],
    () => queryRaceVenueList(params),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    {
      ...(options as any),
    }
  );

  return query;
};

/**
 * Race details runner list hook
 */
type TQueryRunnerListHook = {
  key?: any[];
  options?: UseQueryOptions<TRunnerListResponse>;
  params: TRunnerListParams;
};

export const useQueryRunnerList = ({
  key = [],
  options = {},
  params,
}: TQueryRunnerListHook) => {
  const query = useQuery(
    [keys.runnerList, ...key],
    () => queryRunnerList(params),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    {
      ...(options as any),
    }
  );

  return query;
};

/**
 * Race markets hook
 */
type TQueryMarketsHook = {
  key?: any[];
  options?: UseQueryOptions<TRaceMarket[]>;
  params: TRaceMarketsParams;
};

export const useQueryRaceMarkets = ({
  key = [],
  options = {},
  params,
}: TQueryMarketsHook) => {
  const query = useQuery(
    [keys.raceMarkets, ...key, ...Object.values(params)],
    () => queryRaceMarkets(params),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    {
      ...(options as any),
      refetchInterval: 60_000,
    }
  );

  return query;
};

export const useQueryRaceMeta = (
  params: TRaceMetaParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<TRaceMeta>>,
    'queryKey' | 'queryFn'
  >
) => useQuery([keys.promotions, params], () => queryRaceMeta(params), options);
