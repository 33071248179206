import React from 'react';
import { centsToDollars } from '@/helpers/utils';
import { ETransactionSubType, TTransaction } from '@/lib/DBModels';
import { useTransaction } from '../../Services/Transactions.hooks';
import {
  TableItem,
  TableText,
  TransactionWrapper,
} from './styles/DesktopTransactionsTable.styles';
import MultiLegDetails from './components/MultiLegDetails';
import SgmDetails from './components/SgmDetails';
import SrmDetails from './components/SrmDetails';
import BlendedDetails from './components/BlendedDetails';
import { ToteMultiDetails } from './components/ToteMultiDetails';

type TTransactionItemProps = {
  trans: TTransaction;
  index: number;
};

const TransactionItem: React.FC<TTransactionItemProps> = ({ trans, index }) => {
  const {
    transactionPolarity,
    transDate,
    transDateTime,
    transRefundVoid,
    formattedTransactionType,
    formattedTransactionDetails,
  } = useTransaction(trans);

  return (
    <TransactionWrapper key={trans.transaction_id ?? index}>
      <TableItem>
        <TableText fontWeight="bold" data-cy="transactionDate">
          {transDate}
        </TableText>
        <TableText fontSize="xs" data-cy="transactionTime">
          {transDateTime}
        </TableText>
      </TableItem>
      <TableItem>
        <TableText data-cy="transactionType" fontWeight="bold">
          {formattedTransactionType}
        </TableText>
      </TableItem>
      <TableItem flex={2}>
        {trans.sub_type !== ETransactionSubType.Multi &&
          formattedTransactionDetails.map((detail, i) => (
            <TableText
              data-cy={`transactionDetails-${index}`}
              fontSize={detail?.includes('Bet ID') ? 'xs' : ''}
              key={`transactionDetails-${index}-${i}`}
            >
              {detail}
            </TableText>
          ))}
        {trans.sub_type === ETransactionSubType.Multi && (
          <MultiLegDetails details={trans} />
        )}
        {trans.sub_type === ETransactionSubType.SGMulti && (
          <SgmDetails transaction={trans} />
        )}
        {trans.sub_type === ETransactionSubType.SRMulti && (
          <SrmDetails transaction={trans} />
        )}
        {trans.sub_type === ETransactionSubType.Blended && (
          <BlendedDetails transaction={trans} />
        )}
        {trans.sub_type === ETransactionSubType.ToteMulti && (
          <ToteMultiDetails transaction={trans} />
        )}
      </TableItem>
      <TableItem>
        <TableText
          data-cy="transactionAmount"
          fontWeight="bold"
          textVariation={transRefundVoid ? 'neutral' : transactionPolarity}
          textAlign="end"
        >
          {trans?.amount && trans?.amount > 0 ? '+' : null}
          {centsToDollars(trans?.amount)}
        </TableText>
      </TableItem>
      <TableItem isLastColumn>
        <TableText data-cy="transactionBalance" textAlign="end">
          {trans?.running_balance
            ? centsToDollars(trans?.running_balance)
            : '$0'}
        </TableText>
      </TableItem>
    </TransactionWrapper>
  );
};

export default TransactionItem;
