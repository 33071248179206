import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  color: 'alpha.400',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    color: 'beta.700',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, beta.500, beta.400)',
  color: 'alpha.400',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'alpha.400',
  },
};
export const TextTitle: CSSObject = {
  color: 'alpha.400',
};
