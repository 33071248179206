import { CSSObject } from '@chakra-ui/react';

export const GridLayout: CSSObject = {
  width: ['full', null, '760px'],
  background: [null, null, 'gray.200'],
  minH: '100dvh',
  display: 'flex',
  alignItems: ['null', null, 'center'],
  mx: 'auto',
  '.bc-content, .bc-aside': {
    boxShadow: [null, null, '0px 8px 30px rgba(0, 0, 0, 0.2)'],
  },
  '.bc-content': {
    paddingTop: '4',
    justifyContent: ['flex-start', null, 'center'],
  },
};

export const HeaderGridItem: CSSObject = {
  zIndex: 'docked',
  position: 'absolute',
  w: ['100%', null, '760px'],
};
