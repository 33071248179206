import { CSSObject } from '@chakra-ui/react';

export const TextFormInfo: CSSObject = {
  color: 'white',
  minW: ['unset', null, '75px'],
};

export const FlexWrapper: CSSObject = {
  minW: ['unset', null, '300px'],
};
