import { css } from 'styled-components';

export const mainContainer = css`
  background: #560783;
  background-image: ${({ theme }) => theme.palette.bgGradientPrimary};
  ${({ theme }) => theme.palette.bgGradientPrimary};
  background-position-y: -200px;
  background-size: cover;
  min-height: 100vh;
`;

export const mainContainerSideNavVisible = css`
  background: #560783;
  background-image: ${({ theme }) => theme.palette.bgGradientPrimary};
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
`;

export const contentContainer = css`
  background-attachment: fixed;
  padding-top: 0;
  font-family: ${({ theme }) => theme.fontFamily.body};

  ${({ theme }) => theme.device.tablet} {
    flex: 1;
    background: none;
    padding: var(--bc-space-14) var(--bc-space-16);
    min-height: auto;
  }
`;

export const headingContainer = css`
  background-size: cover;
  min-height: 180px;
  justify-content: center;
  padding: 0 var(--bc-space-8);

  ${({ theme }) => theme.device.tablet} {
    background: none;
    justify-content: flex-start;
    padding-right: var(--bc-space-10);
  }
`;

export const heading = css`
  color: ${({ theme }) => theme.palette.primary};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  margin: var(--bc-space-6) 0 var(--bc-space-2) 0;

  ${({ theme }) => theme.device.tablet} {
    width: 350px;
  }
`;

export const subheading = css`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S};
  line-height: var(--bc-space-4);
  margin-bottom: var(--bc-space-6);

  ${({ theme }) => theme.device.tablet} {
    width: 280px;
  }
`;

export const subheadingLarge = css`
  font-size: ${({ theme }) => theme.fontScale.M};
`;
