import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  borderColor: 'beta.500',
  bg: 'white',
  borderWidth: '2px',
};

export const ExoticSelectionText: CSSObject = {
  color: 'gray.400',
  fontWeight: '500',
};

export const DividerCard: CSSObject = {
  borderColor: 'gray.300',
};

export const ContentRow: CSSObject = {
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'alpha.400',
    bg: 'black',
    border: '2px solid black',
  },
};
