import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: 'xl',
    fontWeight: 'normal',
    fontFamily: 'SkranjiBold',
    textTransform: 'uppercase',
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    px: ['2', null, null, '4'],
    py: '2',
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'blackAlpha.500',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    gap: '1',
    fontSize: 'xl',
    fontFamily: 'SkranjiBold',
    color: 'white',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  headingIcon: {
    color: 'beta.200',
    sx: {},
  },
  boxSportWrapper: {
    borderColor: 'alpha.600',
    borderWidth: '1px',
  },
  propositionButton: {
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  buttonAllSports: {
    _active: {
      bg: 'beta.500',
      borderColor: 'beta.500',
      color: 'alpha.600',
      '&:after': {
        display: 'none',
      },
    },
  },
  buttonSport: {
    borderColor: 'beta.500',
    color: 'white',
  },
};
