import React from 'react';
import {
  Input as InputChakra,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { betCardInputStyles } from './styles/BetCardInput.styles';

interface IBetCardInputProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  name: string;
  placeHolder: string;
  value: string;
}

export function BetCardInput({
  onChange,
  name,
  placeHolder,
  value,
}: IBetCardInputProps) {
  return (
    <InputGroup {...betCardInputStyles.betCardInputGroup}>
      <InputLeftAddon {...betCardInputStyles.betCardInputLeftAddon}>
        $
      </InputLeftAddon>
      <InputChakra
        onChange={onChange}
        name={name}
        placeholder={placeHolder}
        value={value}
        {...betCardInputStyles.betCardInput}
      />
    </InputGroup>
  );
}
