import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import { Box, Spacer, VStack } from '@chakra-ui/react';
import {
  EBetSlipBetSubmissionType,
  TBetSlipTypeGroupProps,
} from '../../../Services/Betslip.types';
import {
  FlexGroupWrapper,
  GroupChevron,
  GroupText,
  TextBetCount,
} from './styles/Group.styles';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { getViewSpecificBetCount } from '../../../Services/Betslip.utils';
import { useChevronIcon } from '@/hooks/useIcon';

// This renders a group of bets and allows collapsing

const Group: React.FC<TBetSlipTypeGroupProps> = ({ bets, type, children }) => {
  const { viewMode } = useAppSelector((state) => state.betSlip);
  const intl = useIntl();

  const [expanded, setExpanded] = useState(true);
  const { chevronIcon } = useChevronIcon(expanded);

  if (bets?.length === 0 && type !== EBetSlipBetSubmissionType.Multi)
    return null;

  const betCount = getViewSpecificBetCount(viewMode, bets);

  return (
    <Box w="full">
      <FlexGroupWrapper>
        <GroupText data-cy={`BetCategory${type}`}>
          {intl.formatMessage({
            id: `betslip.betslipmodal.types.${type.toLowerCase()}`,
          })}
          {type !== EBetSlipBetSubmissionType.Multi && (
            <TextBetCount as="span"> ({betCount})</TextBetCount>
          )}
        </GroupText>

        <Spacer />
        <GroupChevron
          onClick={() => setExpanded(!expanded)}
          aria-label={`${expanded ? 'Collapse' : 'Expand'} ${intl.formatMessage(
            { id: `betslip.betslipmodal.types.${type.toLowerCase()}` }
          )}`}
          icon={chevronIcon}
          variant="solid"
        />
      </FlexGroupWrapper>

      {expanded && (
        <VStack w="full" spacing="4" alignSelf="start">
          {children}
        </VStack>
      )}
    </Box>
  );
};

export default Group;
