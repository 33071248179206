import { CSSObject } from '@chakra-ui/react';

export const myBetsStyles: MyBetsSchema = {
  buttonShowMoreProps: {
    variant: 'secondary',
  },
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};

export const FlexBetCard: CSSObject = {};
