import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  copyrightContainer: { px: 1 },
  footer: {
    pt: 0,
    bg: 'transparent',
    bgSize: 'cover',
    bgPos: 'bottom',
    color: 'white',
  },
  stackGap: '1',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
    '& > img': {
      h: '40px',
      ml: '-1.5',
    },
  },
  footerLink: {
    color: 'alpha.600',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  footerLinkTel: {
    color: 'white',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
  },
  footerMobile: {
    borderRadius: 'lg',
    w: '130px',
    cursor: 'pointer',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.3)',
  },
  customFooterLink: {
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
    fontSize: 'xs',
    color: 'alpha.600',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    h: 'unset',
    pb: '1px',
  },
  footerLinks: {
    ml: ['unset', null, 'auto'],
    pb: '0',
    alignItems: 'center',
    justifyContent: ['space-around', null, 'unset'],
    color: 'white',
    fontWeight: 'medium',
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
    mt: ['18px', null, '0'],
    gap: '0.5',
  },
  footerContentContainer: {
    px: 1,
    gap: ['2', '6'],
    mb: 'unset',
    bg: [null, null, 'transparent'],
    justifyContent: 'space-between',
    sx: {
      '&': {
        flexDirection: ['column', null, 'column'],
      },
    },
  },
  nav: {
    fontSize: 'sm',
    minW: [null, null, '370px'],
    mt: ['2', 'unset'],
    display: ['flex', 'grid'],
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '10',
    rowGap: '2',
    alignItems: ['center', 'end'],
    gridTemplateAreas: `
        ". ."
      `,
    sx: {
      '> a': {
        color: 'inherit',
        h: 'unset',
      },
      '> button': {
        h: 'unset',
      },
    },
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', 'left'],
    color: 'white',
    width: 'unset',
    mb: '0',
    ml: [null, '0'],
    mr: [null, null, '0'],
    pr: '0',
  },
  blurbHeading: {
    ml: 'unset',
    mt: 'unset',
    color: 'whiteAlpha.700',
  },
  blurbHeadingAge: {
    color: 'white',
    borderColor: 'blackAlpha.50',
    border: '2px',
    mb: '2',
    boxSize: '8',
    fontSize: 'sm',
    fontFamily: 'accent',
    fontWeight: 'normal',
  },
  blurbHeadingThink: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    mb: '3',
  },
  blurbContent: {
    ml: 'unset',
    px: ['2', null, '0'],
    fontSize: ['xs', null, null, 'xs'],
    lineHeight: 'tall',
  },
  copyright: {
    color: 'white',
    textAlign: ['center', 'left'],
    mb: ['2', 'unset'],
    px: ['8', 'unset'],
    py: '0',
    fontWeight: ['semibold', 'medium'],
  },
  container: {
    bg: 'transparent',
    gap: '4',
    display: 'flex',
    flexDir: 'column',
    sx: {
      '&': {
        px: 'unset',
      },
    },
    mt: '6',
  },
  emailUs: {
    px: 1,
    fontSize: 'xs',
    fontWeight: 'bold',
  },
  quickLinks: {
    textAlign: ['center', 'left'],
    color: 'alpha.600',
    textTransform: 'uppercase',
    fontSize: 'xs',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    pb: '0.5',
  },
  weAcceptBox: {
    mb: '0',
  },
  weAccept: {
    color: 'alpha.600',
    textTransform: 'uppercase',
    fontSize: 'xs',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  },
  imageWrapper: {
    gap: '0',
  },
  partnerWrapper: {
    mr: '1',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.30)',
  },
  ourPartners: {
    color: 'alpha.600',
    textTransform: 'uppercase',
    fontSize: 'xs',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
  },
  footerLinkService: {
    color: 'alpha.600',
    fontSize: 'xs',
  },
  ButtonPdf: {
    fontWeight: 'normal',
  },
  LinkText: {
    fontWeight: 'normal',
  },
};
