import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/vikingbet/images/mb-logo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  buttonMyBets: { variant: 'solid', fontSize: ['xs', 'sm'] },
  flexWrapperOdds: {
    gap: 2,
    alignItems: 'center',
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: { variant: 'solid', px: '2', color: '#005773', fontSize: 'md' },
  imageLogo: {
    w: ['205px', '250px'],
    src: mysteryLogo,
  },
  iconMystery: {},
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow: 'sm',
    px: '3',
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
    fontSize: 'xs',
  },
  flexWrapperMysteryPlacedBetView: {},
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'auto',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow: 'sm',
    p: '2',
    mx: '4',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    w: 'auto',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
    mx: '4',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear-gradient(to-b, #FACD33, #EC9E0F)',
    borderRadius: 'md',
    boxSize: '37px',
    p: '2',
    color: 'alpha.700',
    cursor: 'pointer',
  },
  imageMysteryBet: {
    w: '5',
    color: 'red',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    textTransform: 'uppercase',
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    gap: '1',
  },
  textPriceInfo: {
    textTransform: 'uppercase',
    fontSize: 'xs',
    color: 'beta.500',
    fontWeight: 'bold',
  },
  textRollover: {
    textTransform: 'uppercase',
    fontSize: 'xs',
    color: 'beta.500',
    fontWeight: 'bold',
  },
  textError: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '10',
    color: 'beta.500',
  },
  iconRace: { color: 'delta.300' },
  textSpanWhite: {
    color: 'white',
  },
  buttonGroupAddToBetslip: {
    mr: '2',
    alignItems: 'center',
  },
  buttonAddToBetslip: {
    variant: 'secondary',
    sx: {
      '&&': {
        h: '10',
        fontSize: 'xs',
      },
    },
  },
  flexWrapperMysteryLabelView: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  buttonRollOver: {
    variant: 'secondary',
    sx: {
      '&&': {
        h: '10',
        fontFamily: 'roboto',
        fontSize: 'xs',
      },
    },
  },
};
