import faker from '@faker-js/faker';
import { TQueryCompetitionsResponse } from './competitions.types';

export const queryCompetitions: TQueryCompetitionsResponse = {
  sport_icons: null,
  sport_id: 'x',
  sport_name: 'x',
  competitions: Array(20)
    .fill('')
    .map(() => ({
      sport_id: faker.datatype.uuid(),
      competition_name: faker.lorem.words(1),
      competition_id: faker.datatype.uuid(),
      match_count: faker.datatype.number(20),
      tournaments: Array(faker.datatype.number(5))
        .fill('')
        .map(() => ({
          tournament_id: faker.datatype.uuid(),
          tournament_name: faker.lorem.words(1),
          market_count: faker.datatype.number(20),
        })),
      country: Math.random() < 0.5 ? null : faker.address.country(),
      is_top_league: faker.datatype.boolean(),
    })),
  // Adding one entry with country undefined
};
