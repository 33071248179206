import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Text, Box } from '@chakra-ui/react';
import { FormsHStack } from '../../../BetCard/components/Forms/Forms.styles';
import { BetCardSVG } from '../../../Card/styles/BetCard.styles';
import { getIconBySportName } from '@/helpers/utils';
import { InfoExoticsContainer } from '../../../BetCard/components/Info/styles/Info.styles';
import { srmInfoStyles } from '../../../BetCard/components/Info/SRM/styles/SRMInfo.styles';
import { TSRMultiMoMBet } from '../types';

interface IMoMSRMCardItemProps {
  bet: TSRMultiMoMBet;
}

export function MoMSRMCardItem({ bet }: IMoMSRMCardItemProps) {
  return (
    <FormsHStack gap={2} mt={2} direction="column">
      <Box>
        <Flex direction="column" gap="2" w="full">
          <Flex w="full">
            <BetCardSVG header name={getIconBySportName(bet.iconType)} />
            <InfoExoticsContainer gap={2}>
              <Text {...srmInfoStyles.runnerDetails}>{bet.title}</Text>
              <Text {...srmInfoStyles.eventMarketName}>{bet.subTitle}</Text>
              <Text {...srmInfoStyles.eventSubtitle}>{bet.description}</Text>
            </InfoExoticsContainer>
          </Flex>
        </Flex>
        <Flex direction="column" mt={2}>
          <Box {...srmInfoStyles.infoExoticsContainer}>
            {bet.legs.map((leg) => (
              <Box key={leg.propName} pb={2}>
                <Text {...srmInfoStyles.eventMarketName}>{leg.propName}</Text>
                <Text {...srmInfoStyles.eventSubtitle}>
                  <FormattedMessage
                    id={
                      leg.winPosition === 1
                        ? 'racing.srm.win'
                        : 'racing.srm.top'
                    }
                    values={{
                      number: leg.winPosition,
                    }}
                  />
                </Text>
              </Box>
            ))}
          </Box>
        </Flex>
      </Box>
    </FormsHStack>
  );
}
