import { Image, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/goldenrush/images/backgrounds/onboardingLogo.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: (
      <Link
        aria-label="aside logo Mobile"
        as={LinkDom}
        to="/"
        maxW="55vw"
        alignSelf="center"
        my="auto"
        display={[null, null, 'none']}
      >
        <Image src={logo} />
      </Link>
    ),
    config: {
      before: 'slogan',
    },
  },
]);
