/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';

import { getAuthUser, signUserOut } from '../firebase/Authentication';

import { HTTPError, ToastMessageOptions } from './types';
import { axios } from '../../components/Providers/Axios';
import { getStrings } from '@/helpers/utils';
import { IS_MOBILE_APP } from '../../constants/isMobileApp';
import { LOCAL_ERROR_MESSAGES, LocationFailMessage } from '../Constants';
import { postMobileAppMessage } from '../../mobileapp/mobileapp.utils';

const [
  {
    Onboarding: {
      Login: { NotSignedIn: NotSignedInErrorMessage },
    },
    Generic: { RateLimitError },
  },
] = getStrings();

axios.interceptors.request.use(async (config) => {
  if (IS_MOBILE_APP) {
    if (window.MobileApp_Token !== undefined) {
      // If the app has injected a bearer token from native - use this
      config.headers!.Authorization = `Bearer ${window.MobileApp_Token}`;
    }

    // As we are on mobile app send the below header which bypass location checks on BE
    config.headers!['x-betcloudapp'] = '8yohi2kljSishjks';

    return config;
  }

  const authUser = getAuthUser();

  if (authUser === null) return config;

  const idToken = await authUser.getIdToken(false);
  const bearerToken = idToken ? `Bearer ${idToken}` : '';

  config.headers!.Authorization = bearerToken;

  return config;
});

export const apiGetRequest = async <T>(
  url: string,
  messageOptions?: ToastMessageOptions
): Promise<T> => {
  try {
    const response = await axios.get<T>(url);

    if (messageOptions?.successMessage) {
      toast.success(messageOptions.successMessage);
    }

    return response.data;
  } catch (error) {
    const e = error as HTTPError;

    if (e.response.status === 429) {
      toast.error(RateLimitError);
    }

    if (
      messageOptions?.failMessage &&
      e.response.data.detail.msg !== LocationFailMessage.Forbidden &&
      e.response.data.detail.msg !== LocationFailMessage.NotAcceptable
    ) {
      toast.error(
        `${messageOptions.failMessage}: ${e.response.data.detail.user_msg}`
      );
    }

    throw error;
  }
};

export const apiPostRequest = async <T, K>(
  url: string,
  data: K,
  messageOptions?: ToastMessageOptions | null,
  handleAuthFailure = false // When specified we will show a toast & log the user out.
): Promise<T> => {
  try {
    const response = await axios.post<T, AxiosResponse<T, K>, K>(url, data);

    if (messageOptions?.successMessage) {
      toast.success(messageOptions.successMessage);
    }

    return response.data;
  } catch (error) {
    const e = error as HTTPError;

    if (e.response.status === 429) {
      toast.error(RateLimitError);
    }

    if (IS_MOBILE_APP) {
      postMobileAppMessage('error', {
        url: window.location.pathname,
        error: JSON.stringify(e),
        response: JSON.stringify(e?.response?.data),
        info: 'Network Error',
      });
    }

    if (handleAuthFailure && e.response.status === 401) {
      toast.error(NotSignedInErrorMessage);

      await signUserOut();
    } else if (
      messageOptions?.failMessage &&
      e.response.data.detail.msg !== LocationFailMessage.Forbidden &&
      e.response.data.detail.msg !== LocationFailMessage.NotAcceptable
    ) {
      toast.error(
        LOCAL_ERROR_MESSAGES[e.response.data.detail.user_msg] ??
          LOCAL_ERROR_MESSAGES[e.response.data.detail.msg] ??
          `${messageOptions.failMessage}: ${e.response.data.detail.user_msg}`
      );
    }

    throw error;
  }
};

export const apiDeleteRequest = async <T, K>(
  url: string,
  data: K,
  messageOptions?: ToastMessageOptions
): Promise<T> => {
  try {
    const response = await axios.delete<T, AxiosResponse<T, K>, K>(url, {
      data,
    });

    if (messageOptions?.successMessage) {
      toast.success(messageOptions.successMessage);
    }

    return response.data;
  } catch (error) {
    const e = error as HTTPError;

    if (
      messageOptions?.failMessage &&
      e.response.data.detail.msg !== LocationFailMessage.Forbidden &&
      e.response.data.detail.msg !== LocationFailMessage.NotAcceptable
    ) {
      toast.error(
        `${messageOptions.failMessage}: ${e.response.data.detail.user_msg}`
      );
    }

    throw error;
  }
};
