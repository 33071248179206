import { CSSObject } from '@chakra-ui/react';
import error from '../../../assets/oldgill/images/backgrounds/errorBg.png';
import errorMaintenance from '../../../assets/oldgill/images/backgrounds/maintenanceBg.png';
import errorAtRetina from '../../../assets/oldgill/images/backgrounds/error@2x.png';
import errorAtRetinaMaintenance from '../../../assets/oldgill/images/backgrounds/maintenance@2x.png';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '120dvh',
});

export const MaintenanceContainer = (isMaintenance: boolean): CSSObject => ({
  alignItems: 'center',
  bg: isMaintenance
    ? `url(${errorMaintenance}) center/cover no-repeat`
    : `url(${error}) center/cover no-repeat`,
  borderRadius: [null, null, 'xl'],
  boxShadow: [null, null, '0 8px 30px rgba(0, 0, 0, .2)'],
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0 1 auto'],
  gap: '0',
  h: undefined,
  justifyContent: 'start',
  maxW: 'container.md',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',

  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bg: isMaintenance
      ? `url(${errorAtRetinaMaintenance}) center/cover no-repeat`
      : `url(${errorAtRetina}) center/cover no-repeat`,
  },

  img: {
    mb: '270px',
  },
});

export const ContentHeadingText: CSSObject = {
  w: ['278px', '468px'],
  color: 'beta.400',
  fontFamily: 'Hitchcut',
  fontSize: ['3xl', null, '4xl'],
  lineHeight: 'none',
  mb: '4.5',
  mt: '4',
  textTransform: 'uppercase',
  textShadow: '0px 4px 8px rgba(0, 0, 0, 0.30)',
  '&[data-ismaintenance="true"]': {
    w: ['278px', '500px'],
  },
};

export const ContentBodyText: CSSObject = {
  fontSize: 'sm',
  mb: '100px',

  span: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'extrabold',
    mb: '0.5',
  },
};

export const ButtonRefresh: CSSObject = {
  borderRadius: 'md',
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  color: 'alpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: 'sm',
  border: 'unset',
  _hover: {
    bg: 'beta.600',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    color: 'alpha.800',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.30)',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'beta.400',
};
