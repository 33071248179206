import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  px: '0',
  mt: '-1',
};

export const BoxWrapper: CSSObject = {
  border: 'none',
};
