import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'transparent',
  px: 3,
  py: 2,
  borderBottom: '1px',
  borderColor: 'blackAlpha.400',
  _last: {
    borderBottom: 'none',
  },
};

export const TextListName: CSSObject = {
  color: 'beta.600',
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.600',
};

export const TextSpanName = (): CSSObject => ({
  color: 'beta.600',
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'alpha.500',
  fontSize: 'xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'beta.600',
  boxSize: '30px',
  boxShadow: 'none',
  bg: 'none',
  p: 'none',
};

export const IconChevron: CSSObject = {
  color: 'beta.600',
  ml: '0',
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '0.5',
  '.countdown-flex, .timestamp': {
    color: 'beta.600',
    fontSize: 'xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'white',
    boxSize: '5',
  },
};
