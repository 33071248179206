import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';
import bg from '@/assets/gigabet/images/account/bannerBg.png';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'odds',
    fontWeight: 'normal',
  },
};
export const FlexBannerContainer: CSSObject = {
  borderRadius: 'md',
  bgImage: [null, null, `url(${bg})`],
  bgSize: 'cover',
  bg: ['beta.700'],
  color: 'white',
  gap: '0',
  p: ['2', null, '3'],
  minH: '107px',
};
export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px dashed',
  borderBottomColor: '#1C233F',
};
export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
  color: 'alpha.400',
  textTransform: 'uppercase',
  textShadow: '0px 0px 6px rgba(255, 202, 36, 0.6)',
};
export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: ['3', null, '2'],
};
export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};
export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'body',
  textAlign: ['start', null, 'end'],
};
export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'body',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 0px 6px rgba(255, 202, 36, 0.6)',
};
export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'alpha.400',
});
export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'alpha.400',
});
export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'alpha.400',
});
export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
  borderTop: '1px dashed',
  borderTopColor: '#414C74',
  pt: '2',
};
export const TextButton: CSSObject = {
  h: '4',
  fontFamily: 'accent',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
};
export const FlexBalanceBetInfo: CSSObject = {
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  borderRadius: 'md',
  border: '1px',
  borderColor: '#7784b0',
  color: 'alpha.400',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
};
export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
};
