import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: 'sm',
    fontWeight: 'normal',
    color: 'gamma.300',
    fontFamily: 'accent',
  },
  headerIconButton: {
    color: 'gamma.300',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'beta.100',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'beta.500',
    h: '1.1em',
    ml: '2',
    mb: '0.5',
    w: 'auto',
  },
  sgmLogoV2: {
    w: '8',
    h: 'auto',
  },
  wrapperScrollSgm: { w: '100%', maxW: '100%', gap: '2', mt: '2' },
  scrollButtonGroup: {
    h: '9',
    variant: 'filter',
    boxShadow: 'none',
    ml: ['0', null, null, '-1.5'],
    w: 'full',
    gap: 2,
  },
  toMarketButton: {
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    gap: 3,
  },
  accordionStack: {
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bg: 'beta.200',
    border: 'none',
    borderRadius: 'md',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.35)',
    overflow: 'hidden',
  },
  accordionButton: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'accent',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'gamma.300',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    px: '3',
  },
  clearButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    ml: '3',
    px: '3',
  },
  countdown: {
    sx: {
      '&&&.state-prior': {
        textTransform: 'uppercase',
        p: 1,
        lineHeight: 'initial',
      },
    },
  },
  scrollButtonGroupV2: {
    variant: 'unstyled',
    flex: 1,
    position: 'relative',
    minH: '34px',
    bg: 'blackAlpha.400',
    maxW: ['full', null, null, '90%'],
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },
  accordionItemActive: {
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  },
  toMarketButtonV2: {
    variant: 'unstyled',
    color: 'alpha.300',
    textTransform: 'uppercase',
    minW: 'fit-content',
    border: 'none',
    cursor: 'pointer',
    fontSize: '11px',
    boxShadow: 'none',
    textShadow: 'none',
    fontWeight: 'normal',
    lineHeight: '1.7',
    borderRadius: 'none',
    fontFamily: 'accent',

    sx: {
      span: {
        pt: '2',
      },
      '&&': {
        px: 2,
        ml: 0,
        h: '9',
        borderBottom: '2px',
        borderColor: 'transparent',
      },

      '&[data-active]': {
        color: 'gamma.300',
        boxShadow: 'none',
        bg: 'transparent',
        borderColor: 'gamma.300',

        _after: { display: 'none', bgImage: 'none' },
      },

      '.chakra-button__icon': {
        margin: 0,
      },
    },

    _last: { mr: 20 },
  },
  sgmBtn: {
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #FFD69E inset, 0px 0px 0px 3px #BD873E inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
    sx: {
      svg: {
        color: 'beta.800',
        fill: 'beta.800',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.2)',
      },
      '&&': {
        _active: {
          bgGradient: 'linear(to-b, alpha.600, alpha.700)',
          boxShadow: 'none',
        },
      },
    },
  },
  sgmBtnActive: {
    sx: {
      svg: {
        color: 'gamma.300',
        fill: 'gamma.300',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
      },

      '&&': {
        border: 'none',
        bg: 'blackAlpha.600',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.30) inset',
        borderColor: 'transparent',
      },
    },

    _after: { display: 'none' },
  },
};
