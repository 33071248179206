import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TextCheckbox: CSSObject = {
  color: 'gamma.600',
};

export const buttonCloseAccountProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
};
