import React from 'react';
import { Icon, Stack, StackProps, CSSObject } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import { TPasswordRulesIcon } from '../services/SignUp.types';

export const FormStack = (props: StackProps) => (
  <Stack
    {...props}
    spacing={['0', null, '2']}
    mb={['4', null, '0']}
    direction={['column', null, 'row']}
  />
);

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="1.5" />
);

export const SignUpButton: CSSObject = {
  bgGradient: 'linear(to-b, alpha.600, alpha.700)',
  borderRadius: 'base',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  fontSize: 'xs',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  _hover: {
    bg: 'alpha.800',
  },
};

export const FlexHeaderWrapper: CSSObject = {
  pr: '12',
};
