import { css } from 'styled-components';

export const subHeader = {
  text: css`
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: ${({ theme }) => theme.fontScale.XL};
    height: var(--bc-sizes-6);
    padding-left: var(--bc-sizes-9);
  `,
  icon: css`
    background-size: var(--bc-sizes-6) var(--bc-sizes-6);
    height: var(--bc-sizes-6);
    width: var(--bc-sizes-6);
  `,
};

// TODO: remove
export const bodyText = css`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S};
  line-height: var(--bc-lineHeights-base);
`;

export const formContainer = css`
  margin-top: var(--bc-sizes-5);
`;
