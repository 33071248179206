import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { isVerified } from '../../services/Overview.utils';
import {
  ButtonGetVerified,
  FlexGetVerifiedContainer,
  TextMustBeVerified,
} from './styles/GetVerified.styles';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { usePunterData } from '@/store/AuthStore';

const GetVerified: React.FC = () => {
  const punterProfile = usePunterData();
  const { verificationStatus } = useAppSelector((state) => state.punter);
  const punterPreviouslyVerified = punterProfile?.identity_verified;
  const navigate = useNavigate();

  if (punterPreviouslyVerified || isVerified(verificationStatus)) {
    return null;
  }

  return (
    <FlexGetVerifiedContainer>
      <TextMustBeVerified>
        <FormattedMessage
          id="account.overview.verification.mustBeVerified"
          values={{
            u: (string: string) => (
              <Text as="span" textDecoration="underline">
                {string}
              </Text>
            ),
          }}
        />
      </TextMustBeVerified>
      <ButtonGetVerified
        variant="unstyled"
        onClick={() => navigate('/account/settings/verify-identity')}
      >
        Get Verified
      </ButtonGetVerified>
    </FlexGetVerifiedContainer>
  );
};

export default GetVerified;
