import { TLogoStyles } from './Logo.styles';
import logoDesktop from '@/assets/bet777/images/logo.png';
// import logoMb from '@/assets/bet777/images/logoMb.png';

const styles: Partial<TLogoStyles> = {
  img: {
    src: logoDesktop,
  },
};
export default styles;
