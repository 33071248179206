import { CSSObject } from '@chakra-ui/react';

export const FlexBetslipHeading: CSSObject = {
  color: 'delta.800',
  h: '12',
  borderBottomColor: 'rgba(0, 0, 0, 0.15)',
  borderBottom: '1px',
  pt: '1',
  px: '11px',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'lowercase',
  fontFamily: 'Bungee',
  color: 'gamma.500',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const BetLengthIndicator: CSSObject = {
  // TODO: Upate to leverage theme token
  color: '#7A3C25',
  fontSize: '2xs',
  borderRadius: 'base',
  bg: 'gamma.500',
  fontFamily: 'roboto',
  fontWeight: 'bold',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'gamma.500',
  boxSize: '4',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const CloseIconButton: CSSObject = {
  bg: '#FFC635',
  color: 'alpha.500',
  borderRadius: 'full',
  svg: {
    boxSize: '6',
  },
};
