import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TRaceMarket } from '../../../lib/DBModels';

import { getRacingVenueList, getRaceRunnerListPolling } from './races.actions';
import { RaceDetailsState } from '../RaceDetails/types';

import RacingState from './races.types';

const initialState: RacingState = {
  venueListLoading: false,
  raceRunnerListPolling: false,
  raceRunnerList: {
    venues: [],
    otherRaces: [],
    raceMeta: {},
    raceRunners: [],
    runnerResults: [],
    markets: [],
    exoticResults: [],
    toteMultiResults: [],
  },
};

const racingSlice = createSlice({
  name: 'racing',
  initialState,
  reducers: {
    setRaceRunnerList: (
      state: RacingState,
      action: PayloadAction<RaceDetailsState>
    ) => ({
      ...state,
      raceRunnerList: action.payload,
    }),
    setMarkets: (state: RacingState, action: PayloadAction<TRaceMarket[]>) => ({
      ...state,
      raceRunnerList: {
        ...state.raceRunnerList,
        markets: action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRacingVenueList.pending, (state: RacingState) => ({
        ...state,
        venueListLoading: true,
      }))
      .addCase(getRacingVenueList.fulfilled, (state: RacingState) => ({
        ...state,
        venueListLoading: false,
      }))
      .addCase(getRacingVenueList.rejected, (state: RacingState) => ({
        ...state,
        venueListLoading: true,
      }))
      .addCase(getRaceRunnerListPolling.pending, (state: RacingState) => ({
        ...state,
        raceRunnerListPolling: true,
      }))
      .addCase(getRaceRunnerListPolling.fulfilled, (state: RacingState) => ({
        ...state,
        raceRunnerListPolling: false,
      }))
      .addCase(getRaceRunnerListPolling.rejected, (state: RacingState) => ({
        ...state,
        raceRunnerListPolling: true,
      }));
  },
});

// Slice actions
export const { setRaceRunnerList, setMarkets } = racingSlice.actions;

// Slide reducer
export default racingSlice.reducer;
