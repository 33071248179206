import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonOutsideArea = {
  display: 'none',
};

export const FlexContainer = (): CSSObject => ({
  alignItems: ['center', null, null, null, 'start'],
  pos: 'relative',
  px: '0',
  bgGradient: ['linear(to-b, delta.300, delta.600)', 'unset', 'unset', 'unset'],
  borderBottom: ['3px solid', 'unset', 'unset', 'unset'],
  borderColor: 'delta.700',
});

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  w: '97px',
  mr: '4px',
};

export const ButtonGroup: CSSObject = {
  alignItems: 'start',
  justifyContent: 'end',
  w: 'full',
};

export const buttonLogInProps: CustomButtonProps = {
  variant: 'secondary',
  h: ['auto', null, '12'],
  fontSize: ['xs', null, 'md'],
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  h: ['auto', null, '12'],
  fontSize: ['xs', null, 'md'],
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'solid',
  boxShadow:
    '0px 0px 0px 3px #006352 inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.15)',
  h: 12,
  pl: 2.5,
  pr: 2,
  mt: 3,

  sx: {
    '&:hover, &:focus': {
      bg: 'beta.100',
      boxShadow:
        '0px 0px 0px 3px #006352 inset, 0px 2px 1px 0px rgba(0, 0, 0, 0.15)',
      color: 'beta.400',
      '.span-balance': {
        color: 'beta.400',
      },

      '.span-bonus': {
        color: 'beta.400',
        svg: {
          path: {
            fill: 'beta.400',
          },
        },
      },
    },

    _active: {
      boxShadow: 'inset 0px 1px 0px rgba(255, 255, 255, 0.8)',
    },

    '&[aria-expanded="true"]': {
      bgGradient: 'linear(to-b, delta.300, delta.600)',
      boxShadow:
        '0px 0px 0px 3px #5C0508 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(255, 255, 255, 0.30) inset',
      color: 'delta.700',
      textShadow: '0 2px 4px rgba(0, 0, 0, .25)',
      borderColor: 'epsilon.100',
      '.span-balance': {
        color: 'delta.700',
      },

      '.span-bonus': {
        color: 'delta.700',
        svg: {
          path: {
            fill: 'delta.700',
          },
        },
      },
    },

    '.span-balance': {
      color: 'beta.500',
      fontFamily: 'accentUltra',
      fontSize: 'sm',
    },

    '.span-bonus': {
      color: 'beta.500',
      fontFamily: 'Roboto',
      fontWeight: 'extrabold',
      fontSize: 'xs',
      svg: {
        path: {
          fill: 'beta.500',
        },
      },
    },
  },
  _after: {
    bgImage: 'unset',
    borderRadius: 'base',
  },
};

export const ButtonGroupBets: CSSObject = {
  gap: 1,
  mt: 3,
  mr: ['1', null, '0'],
  '*:first-of-type:not(:last-of-type)': {
    borderRightRadius: 'lg',
  },
  '*:last-of-type:not(:first-of-type)': {
    borderLeftRadius: 'lg',
  },
  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },
};

const betButtonStyles: CustomButtonProps = {
  variant: 'secondary',
  h: ['auto', null, '12'],
  textShadow: ' 0px 1px 1px rgba(0, 0, 0, 0.30)',
  textTransform: 'uppercase',
  p: '2',
  sx: {
    '&:hover, &:focus': {
      bg: 'delta.300',
      boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.15)',
      color: 'delta.500',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',
    },

    _active: {
      bg: 'delta.300',
      boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.15)',
    },

    '.btn-span': {
      flexDir: 'column',
      gap: 0.5,
      alignSelf: 'center',
      lineHeight: 1,
    },

    '.chakra-text:first-child': {
      fontSize: '2xs',
    },

    '.chakra-text:last-child': {
      fontSize: 'md',
    },
    _after: {
      bgImage: 'unset',
    },
  },
};

export const buttonBetsProps: CustomButtonProps = betButtonStyles;

export const buttonBetSlipProps: CustomButtonProps = betButtonStyles;

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'delta.700',

  '& svg': {
    boxSize: '7',
  },
});
