import { CSSObject } from '@chakra-ui/react';
import mobileBackgound from '@/assets/vikingbet/images/backgrounds/texture-wood-1x.png';

export const DrawerHeaderContainer: CSSObject = {
  borderBottom: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  mb: '0',
  px: '4',
  mt: ['0', null, '2', null, null],
  pt: '2',
  bgImage: [`url(${mobileBackgound})`, null, null, 'none'],
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '7',
  color: 'white',
};
