import {
  Box,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  Image,
  ImageProps,
  keyframes,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './Featured.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

const shake = keyframes`
  0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
`;

// ----

export const FlexWrapper = chakra(Flex, {
  label: 'featured-FlexWrapper',
  baseStyle: () => ({
    py: ['2', '5'],
    pl: ['2', null, '14'],
    pr: ['2', null, 0],
    justifyContent: ['center', null, 'unset'],
    border: '3px solid',
    borderColor: 'betaAlpha.500',
    borderRadius: 'base',
    mt: ['1.5', null, null, '2.5'],
    mx: ['1.5', null, null, 'unset'],

    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexTextWrapper = chakra(Flex, {
  label: 'featured-FlexTextWrapper',
  baseStyle: () => ({
    flexDir: 'column',
    alignItems: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexTextWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxHeadingWrapper = chakra(Box, {
  label: 'featured-BoxHeadingWrapper',
  baseStyle: () => ({
    label: 'heading',
    maxW: '300px',
    m: 'auto',
    textAlign: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxHeadingWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexTeamWrapper = chakra(Flex, {
  label: 'featured-FlexTeamWrapper',
  baseStyle: () => ({
    flexDir: ['column', 'row'],
    alignItems: 'center',
    my: '2.5',
    gap: ['1.5', '4'],
    maxW: '320px',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexTeamWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexMiscWrapper = chakra(Flex, {
  baseStyle: () => ({
    label: 'featured-FlexMiscWrapper',
    gap: '1.5',
    alignItems: 'center',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexMiscWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexIllustrationWrapper = chakra(Flex, {
  label: 'featured-IllustrationWrapper',
  baseStyle: () => ({
    flex: 1,
    display: ['none', null, 'flex'],

    ...(() => {
      try {
        return styleImports[themeName]?.FlexIllustrationWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

// ----

export const TextHeading = chakra(Text, {
  label: 'featured-TextHeading',
  baseStyle: () => ({
    fontSize: '6xl',
    fontWeight: 'black',
    letterSpacing: 'widest',
    textShadow: '0 2px 3px rgba(0, 0, 0, .5)',
    color: 'alpha.400',
    borderBottom: '2px',
    borderBottomColor: 'white',
    textTransform: 'uppercase',

    span: { color: 'white' },
  }),
});

export const TextSubHeading = chakra(Text, {
  label: 'featured-SubHeading',
  baseStyle: () => ({
    fontSize: 'xs',
    textShadow: '0 2px 3px rgba(0, 0, 0, .5)',
    color: 'white',
    borderBottom: '2px',
    borderBottomColor: 'white',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    py: '2',
    fontWeight: 'medium',
  }),
});

export const TextTeam = chakra(Text, {
  label: 'featured-TextTeam',
  baseStyle: () => ({
    fontSize: 'sm',
    color: 'white',
    fontWeight: 'black',
    textShadow: '0 2px 3px rgba(0, 0, 0, .5)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    flex: '1 1 auto',

    _first: { textAlign: 'right' },
    _last: { textAlign: 'left' },

    ...(() => {
      try {
        return styleImports[themeName]?.TextTeam;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextVS = chakra(Text, {
  label: 'featured-TextVS',
  baseStyle: () => ({
    fontSize: 'sm',
    color: 'alpha.900',
    fontWeight: 'black',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
    display: 'inline-flex',
    boxSize: '8',
    maxW: '8',
    minW: '8',
    textTransform: 'uppercase',
    textAlign: 'center',
    // bg: 'linear-gradient(180deg, #45F3FC 0%, #38C8CF 100%)',
    bgGradient: 'linear(to-b, beta.500, beta.600)',
    borderRadius: 'full',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 3px 6px rgba(255, 255, 255, 0.6)',
    lineHeight: 'none',

    ...(() => {
      try {
        return styleImports[themeName]?.TextVS;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextMisc = chakra(Text, {
  label: 'featured-TextMisc',
  baseStyle: () => ({
    fontSize: ['2xs', 'sm'],
    fontWeight: 'black',
    textTransform: 'uppercase',
    color: 'alpha.500',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    bg: 'blackAlpha.500',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 'sm',
    px: ['2.5', '3'],
    py: ['1.5', '2'],
    borderBottom: '1px',
    borderBottomColor: 'whiteAlpha.200',
    lineHeight: 'none',
    maxW: ['90dvw', '60dvw', '50dvw', '40dvw', '500px'],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    span: { display: 'inline-block', color: 'whiteAlpha.300', mx: '1' },

    ...(() => {
      try {
        return styleImports[themeName]?.TextMisc;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const puntButtonProps: ButtonProps = {
  'data-testid': 'featured-puntButtonProps',
  transition: 'all 0.2s ease-in-out',
  animation: `${shake} 5s ease infinite`,
  _hover: { animation: 'none' },
  ...(() => {
    try {
      return styleImports[themeName]?.puntButtonProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const HeadingSlogan = chakra(Text, {
  label: 'featured-HeadingSlogan',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.HeadingSlogan;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

/**
 * @deprecated use textSloganProps instead
 */
export const SpanSlogan = chakra(Text, {
  label: 'featured-SpanSlogan',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.SpanSlogan;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'p', TextProps>;

export const textSloganProps: TextProps = {
  'data-testid': 'featured-textSloganProps',

  ...(() => {
    try {
      return styleImports[themeName]?.textSloganProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const ImageMascot: ChakraComponent<'div', ImageProps> = chakra(Image, {
  label: 'featured-ImageMascot',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ImageMascot;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexFeatureCardWrapper = chakra(Flex, {
  label: 'FlexFeatureCardWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexFeatureCardWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const HeaderWrapper = chakra(Box, {
  label: 'featured-HeaderWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.HeaderWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
