import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/goldenrush/images/backgrounds/onboardingAsideBg.png';
import bgMobile from '@/assets/goldenrush/images/backgrounds/onboardingAsideBgMb.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${bgMobile}), var(--bc-colors-gamma-400)`,
    null,
    `url(${bgDesktop}), var(--bc-colors-gamma-400)`,
  ],
  bgSize: ['100% auto, auto', null, '100%, auto'],
  bgPos: 'center top',
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  px: [null, null, '10px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['340px', '440px', 'full'],
  borderLeftRadius: 'md',
  pt: ['6', null, '56'],
  flex: 1,
};
