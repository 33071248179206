import { BoxProps, TextProps } from '@chakra-ui/react';
import { styleImport } from './PlayerLayout.styles.imports';

type TDataTestID = { 'data-testid'?: string };

export type TPlayerLayoutStylesSchema = {
  grid: BoxProps & TDataTestID;
  gridSingular: BoxProps;
  row: BoxProps & TDataTestID;
  collapse: BoxProps & TDataTestID;

  nameWrapper: BoxProps;
  name: TextProps & TDataTestID;
};

export const playerLayoutStyles: Partial<TPlayerLayoutStylesSchema> = {
  // Layout
  grid: {
    'data-testid': 'playerLayout-grid',

    display: 'flex',
    flexDir: 'column',
    rowGap: '1.5',
    py: '1.5',

    ...styleImport?.grid,
  },
  gridSingular: {
    ...styleImport?.gridSingular,
  },
  row: {
    'data-testid': 'playerLayout-row',

    display: 'flex',
    columnGap: '2',
    overflowX: 'auto',

    sx: {
      '::-webkit-scrollbar': { display: 'none' },
    },

    ...styleImport?.row,
  },
  collapse: {
    'data-testid': 'playerLayout-collapse',

    rowGap: 1.5,
    display: 'flex',
    flexDirection: 'column',
  },

  nameWrapper: {
    'data-testid': 'playerLayout-nameWrapper',

    alignSelf: 'center',

    sx: {
      '.overflow &': {
        minW: ['130px', null, 'unset'],
        mr: ['unset', null, 'auto'],
      },
      '.no-overflow &': {
        minW: '130px',
        mr: 'auto',
      },
    },

    ...styleImport?.nameWrapper,
  },

  // Rest
  name: {
    'data-testid': 'playerLayout-name',

    whiteSpace: 'nowrap',
    fontSize: 'xs',
    fontWeight: 'bold',
    pl: '2',
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    sx: { whiteSpace: 'nowrap' },

    ...styleImport?.name,
  },
};
