import { FC, useEffect } from 'react';

import { APP_NAME } from '../../lib/Constants';

import PageTitleProps from './types';

/**
 * Component which controls the `<title />` meta tag inside the `<head />` tag.
 */
const PageDocumentTitle: FC<PageTitleProps> = ({ pageTitle }) => {
  useEffect(() => {
    document.title = '';
    document.title = `${APP_NAME} ${pageTitle}`;
  }, [pageTitle]);

  return null;
};

export default PageDocumentTitle;
