import React from 'react';
import { useIntl } from 'react-intl';
import { centsToDollars, getStrings } from '@/helpers/utils';
import {
  BetRejectedText,
  IconRejectedBet,
  RejectedBetContainer,
} from './Footer.styles';
import { TBetApprovalProps } from '../../../../Services/Betslip.types';
import { EBetRequestStatus } from '../../../../../../api/punter/punter.types';

export const RejectedBet: React.FC<TBetApprovalProps> = ({
  betRequest,
  bet,
}) => {
  const [{ BetSlip: Strings }] = getStrings();

  const intl = useIntl();

  const rejectedAmount =
    betRequest && betRequest.approved_stake && betRequest.requested_stake
      ? betRequest.requested_stake - betRequest.approved_stake
      : 0;

  const rejectionText = () => {
    // To account for MBLs
    if (bet?.confirmation?.reduced_stake) {
      return `${Strings.betSlipBetCard.partialBetApprovalInfo.replace(
        '%',
        centsToDollars(bet?.confirmation?.reduced_stake)
      )}
      ${Strings.betSlipBetCard.rejected}`;
    }

    // To account for Pending bets
    switch (betRequest?.status) {
      case EBetRequestStatus.ReducedStake:
        return intl.formatMessage(
          { id: 'betSlip.betSlipBetCard.rejected' },
          { amount: centsToDollars(rejectedAmount) }
        );

      case EBetRequestStatus.Rejected:
        return Strings.betSlipBetCard.unfortunateReject;
      default:
        break;
    }
  };

  return (
    <RejectedBetContainer>
      <IconRejectedBet />
      <BetRejectedText>{rejectionText()}</BetRejectedText>
    </RejectedBetContainer>
  );
};
