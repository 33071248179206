import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonMoreTableProps: CustomButtonProps = {
  variant: 'secondary',
};

export const TableItem: CSSObject = {
  borderColor: 'whiteAlpha.300',
  color: 'white',
  borderRight: 'none',
  borderStyle: 'dashed',
  pl: 0,
  py: 2,
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'beta.500' : 'white',
});

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const ButtonMoreTable: CSSObject = {
  fontSize: 'xs',
  bgGradient: 'linear-gradient(180deg, #FCD236 0%, #E79004 100%)',
  color: 'alpha.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',

  '&:hover, &:focus': {
    bg: 'beta.600',
    color: 'alpha.700',
  },

  _active: {
    bg: 'beta.600',
    color: 'alpha.700',
    boxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.3)',
  },
};
