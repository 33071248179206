import { Box } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNTG } from '../../../../../hooks/useNTG';
import ListItem from '../ListItem/ListItem';
import Loading from '../Loading';
import {
  BoxSectionWrapper,
  BoxWrapper,
  boxWrapperNTPProps,
  TextHeading,
} from './styles/Upcoming.styles';

export default function Upcoming() {
  const { dataSorted: data, isInitialLoading } = useNTG({ sports: ['all'] });

  return (
    <BoxSectionWrapper sx={{ mb: '-2' }} {...boxWrapperNTPProps}>
      <BoxWrapper>
        <TextHeading>
          <FormattedMessage id="home.nextToPlay" />
        </TextHeading>
      </BoxWrapper>

      <Box data-cy="nextToPlay">
        {(() => {
          if (isInitialLoading) return <Loading />;

          return [...(data ?? [])]
            ?.slice(0, 5)
            ?.map((m) => (
              <ListItem
                key={`sports-upcoming-${m?.match_id}`}
                match={m}
                section="next"
              />
            ));
        })()}
      </Box>
    </BoxSectionWrapper>
  );
}
