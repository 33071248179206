import { MarketLayoutStylesSchema } from './MarketLayout.styles';

export const matchDetailsStyles: MarketLayoutStylesSchema = {
  headerText: {
    flex: '1',
    align: 'center',
    fontSize: '2xs',
    fontWeight: 'bold',
  },

  rowWrapper: {
    px: '4',
    py: '2',
    alignItems: 'center',
    display: 'flex',
    fontSize: 'xs',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    borderColor: 'transparent',
    borderTopWidth: undefined,
    gap: '2',
    pos: 'relative',
    color: 'white',

    _before: {
      borderColor: 'blackAlpha.400',
      borderBottomWidth: '1px',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      w: 'full',
      left: '0',
      pos: 'absolute',
      top: '-1px',
    },
  },
  rowWrapperFirst: {
    borderTopWidth: '1px',
    // color: 'white',

    _before: {
      display: 'none',
    },
  },
  rowWrapperWithBtn: {
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  },

  tab: {
    bg: 'alpha.500',
    borderBottomWidth: '1px',
    borderColor: 'white',
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'bold',
    _selected: {
      bg: 'alpha.700',
      borderBottomColor: 'transparent',
      color: 'white',
    },
  },
};
