import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const inputProps: Partial<TIndex> = {
  w: '60%',
  sxWrapper: {
    '&&': {
      '.chakra-input': {
        _focus: {
          color: 'alpha.900',
        },
        _hover: {
          color: 'alpha.900',

          '&::placeholder': {
            color: 'gray.400',
          },
        },
      },
    },
  },
};

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  width: '100%',
  borderTop: 'none',
  mb: '4',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const CancelButton: CSSObject = {
  bg: 'white',
  borderRadius: 'base',
  color: 'gamma.600',
  fontSize: 'xs',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  border: '2px solid',
  borderColor: 'gamma.600',
  fontFamily: 'Roboto',

  '&:hover, &:focus': {
    color: 'white',
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    boxShadow: 'none',
  },
};

export const ButtonSubmit: CSSObject = {
  fontSize: 'xs',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  color: 'white',
  fontFamily: 'Roboto',
  '&:hover, &:focus': {
    bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, var(--bc-colors-gamma-500) 0%, var(--bc-colors-gamma-600) 100%)',
    backgroundBlendMode: 'overlay, normal',
    color: 'white',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
};

export const PendingLimitContainer: CSSObject = {
  color: 'white',
  fontWeight: '700',
};

export const buttonRemoveProps: ButtonProps = {
  variant: 'secondary',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: 0,
    label: {
      color: 'white',
    },
  },
  '&&': {
    '.chakra-input__left-addon': {
      borderLeftRadius: 'md',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'alpha.600',
      color: 'white',
      border: 'none',
    },
    '.chakra-input': {
      border: '1px solid',
      borderLeftRadius: 'none',
      color: 'alpha.900',
      fontSize: 'xs',
      bg: 'white',
      fontWeight: 'bold',
      borderRightRadius: 'md',
      borderColor: 'gray.300',
      borderLeft: 'none',
      ':active, :hover': {
        bg: 'gray.100',
        color: 'white',
        borderColor: 'gray.400',
        _placeholder: {
          color: 'gray.700',
        },
      },
      _focus: {
        color: 'white',
        bg: 'gray.50',
        borderColor: 'blue.300',
        boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
      },
      _placeholder: {
        color: 'gray.700',
      },
    },
  },
};

export const DepositButton: CSSObject = {
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  color: 'alpha.600',
  fontFamily: 'accent',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  _hover: {
    bg: 'beta.600',
    color: 'alpha.800',
  },
  _disabled: {
    _hover: {
      bg: 'beta.600',
      color: 'alpha.800',
    },
  },
};

export const buttonConfirmModalProps: ButtonProps = {
  bg: 'beta.400',
  color: 'alpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  fontFamily: 'accent',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  _hover: {
    bg: 'beta.600',
    color: 'alpha.800',
  },
};

export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  ml: '2',
};
