import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/puntcity/images/backgrounds/onboardingBg.png';
import bgMobile from '@/assets/puntcity/images/backgrounds/onboardingBgMobile.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${bgMobile}), url(${bgMobile}), var(--bc-colors-alpha-800)`,
    null,
    `url(${bgDesktop}), url(${bgDesktop}), var(--bc-colors-alpha-800)`,
  ],
  bgSize: ['cover, auto', null, '100%, auto'],
  bgPos: ' center 100%, center calc(100% - 82px)',
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  px: [null, null, '40px'],
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.500', null, 'unset'],
  h: ['334px', null, 'full'],
  overflow: 'hidden',
};
