import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ERaceType } from '../lib/DBModels';

type TInitialState = {
  filters: ERaceType[];
};

const initialState: TInitialState = {
  filters: [],
};

const raceTableSlice = createSlice({
  name: 'raceTable',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<ERaceType>) => {
      const split = action.payload.split(',') as ERaceType[];

      return {
        ...state,
        filters: [...state.filters, ...split],
      };
    },
    toggleFilter: (state, action: PayloadAction<ERaceType>) => ({
      ...state,
      filters: state.filters.includes(action.payload)
        ? state.filters.filter((t) => t !== action.payload)
        : [...state.filters, action.payload],
    }),
    overrideFilter: (state, action: PayloadAction<ERaceType>) => ({
      ...state,
      filters: [action.payload as ERaceType],
    }),
    resetFilters: () => initialState,
  },
});

export const { setFilter, toggleFilter, resetFilters, overrideFilter } =
  raceTableSlice.actions;
export default raceTableSlice.reducer;
