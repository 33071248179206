import { Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

export default function Loading() {
  return (
    <VStack sx={{ px: '4', alignItems: 'stretch' }}>
      {[...new Array(10)].map((_, i) => (
        <Skeleton key={`ntj-skel-${i}`} sx={{ h: '8' }} />
      ))}
    </VStack>
  );
}
