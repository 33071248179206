import React from 'react';
import { Link, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { TQueryCompetitions } from '@/api/sports/competitions.types';
import { linkItemStyles } from '@/views/sports/components/CompetitionsMenu/styles/LinkItem.styles';

type TLinkItem = {
  competition: TQueryCompetitions;
};

export default function LinkItem({ competition }: TLinkItem) {
  const { pathname, search } = useLocation();
  const {
    competition_name: competitionName,
    competition_id: competitionId,
    match_count: matchCount,
  } = competition;

  const linkTo = `${pathname}/${encodeURIComponent(
    competitionName
  )}${search}&competitionId=${competitionId}`;

  return (
    <Link as={ReactRouterLink} to={linkTo} {...linkItemStyles.link}>
      <Text {...linkItemStyles.textCompetitionName}>
        {competitionName.replace(/\s\d{4}(\/\d{4})?/g, '')}
      </Text>
      <Text {...linkItemStyles.textMatchCount}>{matchCount}</Text>
    </Link>
  );
}
