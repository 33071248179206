import { themeName } from '@/helpers/getThemeConfig';
import * as buffalobet from './Logo.styles.buffalobet';
import * as bet777 from './Logo.styles.bet777';

const styleImports: Partial<Record<string, any>> = {
  buffalobet,
  bet777,
};

export const styleImport = styleImports[themeName]?.default;
