import {
  Box,
  BoxProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Grid,
  GridProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import styleImports from './Aside.styles.imports';

export const TextTitle = chakra(Text, {
  label: 'aside-TextTitle',
  baseStyle: styleImports?.TextTitle,
}) as ChakraComponent<'p', TextProps>;

export const TextSubtitle = chakra(Text, {
  label: 'aside-TextSubtitle',
  baseStyle: styleImports?.TextSubtitle,
}) as ChakraComponent<'p', TextProps>;

export const TextSubtitleBoldWord = chakra(Text, {
  label: 'aside-SubtitleBoldWord',
  baseStyle: () => ({
    fontWeight: 'bold',
    m: '0',
    ...styleImports?.TextSubtitleBoldWord,
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextCTAInfo = chakra(Text, {
  label: 'aside-TextCTAInfo',
  baseStyle: styleImports?.TextCTAInfo,
}) as ChakraComponent<'p', TextProps>;

export const TextCTALink = chakra(Text, {
  label: 'aside-TextCTALink',
  baseStyle: styleImports?.TextCTALink,
}) as ChakraComponent<'p', TextProps>;

export const FlexAsideInfo = chakra(Flex, {
  label: 'aside-FlexAsideInfo',
  baseStyle: styleImports?.FlexAsideInfo,
}) as ChakraComponent<'div', FlexProps>;

export const GridAsideWrapper = chakra(Grid, {
  label: 'aside-GridAsideWrapper',
  baseStyle: styleImports?.GridAsideWrapper,
}) as ChakraComponent<'div', GridProps>;

export const BoxVideoContainer = chakra(Box, {
  label: 'aside-BoxVideoContainer',
  baseStyle: styleImports?.BoxVideoContainer,
}) as ChakraComponent<'div', BoxProps>;

export const FlexCTAContainer = chakra(Flex, {
  label: 'aside-FlexCTAContainer',
  baseStyle: styleImports?.FlexCTAContainer,
}) as ChakraComponent<'div', FlexProps>;

export const GlobalStyles = styleImports?.GlobalStyles;

export const FirstWord = chakra('span', {
  label: 'aside-FirstWord',
  baseStyle: styleImports?.FirstWord,
}) as ChakraComponent<'span', TextProps>;

export const TitleContainer = chakra('span', {
  label: 'aside-TitleContainer',
  baseStyle: styleImports?.TitleContainer,
}) as ChakraComponent<'span', TextProps>;

export const TextTitleSuffixContent = chakra('span', {
  label: 'aside-TextTitleSuffix',
  baseStyle: styleImports?.TextTitleSuffixContent,
}) as ChakraComponent<'span', TextProps>;

export const FlexTitleImageWrapper = chakra(Flex, {
  label: 'aside-FlexTitleImageWrapper',
  baseStyle: () => ({
    justifyContent: 'center',
    alignItems: 'center',
    pos: ['absolute', 'inherit'],
    right: ['0px', 'auto'],
    top: ['80px', 'auto'],
    h: ['80%', '140px'],
    w: '100%',
  }),
}) as ChakraComponent<'div', FlexProps>;
