import { CSSObject, IconProps, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'beta.500',
};

export const DepositContainer: CSSObject = {
  mb: 2,
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  sx: {
    _hover: {
      color: 'alpha.800',
      bg: 'beta.600',
    },
  },
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: 1,
  w: 12,
  h: 7,
  bg: 'white',
};

export const RowDivider: CSSObject = {
  borderColor: 'blackAlpha.400',
  opacity: 1,
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
};

export const FlexWrapper: CSSObject = {
  color: 'gray.700',
  py: 3,

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  ml: 3,
  fontWeight: 'medium',
  color: 'white',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: 4,
  p: 1,
};

export const IconButton: CSSObject = {
  h: 8,
  ml: 4,
  minWidth: 8,
};

export const TrashIcon: IconProps = {
  bg: 'none',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  color: 'alpha.800',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  boxSize: '7',
  p: '1',
  borderRadius: 'base',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
};

export const BoxInputContainer: CSSObject = {
  mb: 2,

  '.chakra-form-control': {
    mb: 0,
    label: {
      color: 'white',
    },
  },
  '&&': {
    '.chakra-input__left-addon': {
      borderLeftRadius: 'md',
      fontSize: 'sm',
      fontWeight: 'bold',
      bg: 'alpha.600',
      color: 'white',
      border: 'none',
    },
    '.chakra-input': {
      border: '1px solid',
      borderLeftRadius: 'none',
      bg: 'white',
      color: 'alpha.900',
      fontSize: 'xs',
      fontWeight: 'bold',
      borderRightRadius: 'md',
      borderColor: 'gray.300',
      borderLeft: 'none',
      '&': {
        ':active, :hover': {
          bg: 'gray.100',
          color: 'blackAlpha.800',
          borderColor: 'gray.400',
          _placeholder: {
            color: 'gray.700',
          },
        },
        _focus: {
          color: 'blackAlpha.800',
          bg: 'gray.50',
          borderColor: 'blue.300',
          boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
        },
        '::placeholder': {
          color: 'gray.700',
          opacity: '1',
        },
      },
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: 2,
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const IconSSL: CSSObject = {
  mx: 1,
  w: 4,
  h: 4,
};

export const bannerTextProps: TextProps = {
  fontSize: '11px',
  lineHeight: '13.2px',
  color: 'white',
  mt: '0.5',
};

export const FormBox: CSSObject = {
  'div.chakra-collapse div': {
    bg: 'linear-gradient(180deg, #007DC6 0%, #00578A 100%)',
    border: 'none',
    color: 'beta.500',
    fontWeight: '500',
  },
  'div.chakra-collapse div svg': {
    color: 'beta.500',
  },
};

export const RadioCard: CSSObject = {
  bg: 'gray.200',
  border: 'none',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'gray.200',
    color: 'alpha.400',
  },
};

export const BoxCardsContainer: CSSObject = {
  mb: '2',
  '.chakra-text': {
    color: 'white',
  },
};
