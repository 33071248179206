import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = {
  borderRadius: ['md', 'lg'],
  minHeight: '0',
  boxShadow: [
    'none',
    '0px 5px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 4px #FFF1CB inset, 0px 0px 0px 6px #C8A257 inset, 0px 0px 0px 8px #FFF1CB inset, 0px 4px 0px 0px #C8A257',
  ],
  bgGradient:
    'linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%)',
  px: ['0', '2', '4'],
  pt: ['0', null, '1'],
  pb: '5',
  mx: ['0', '0', '0.5'],
};

export const WrapperContainer: CSSObject = {
  px: ['0', null, null],
  pb: '2',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
  mb: '0',
};

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bg: 'transparent',
  pt: '0',
  '&&': {
    px: '0',
    pb: '0',
  },
  mt: ['unset', '0'],
  borderTopRadius: 'base',
  overflow: 'visible',
  fontWeight: 'medium',
  pb: '0',
  color: 'beta.800',
  fontFamily: 'accent',
});

export const TextTitle = (): CSSObject => ({
  mt: ['1', '0'],
  ml: ['1', '0'],
  fontWeight: 'medium',
  fontSize: 'xl',
  textTransform: 'capitalize',
  color: 'beta.800',
  fontFamily: 'accent',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};
