import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '2'],
  mb: ['2', null, '0'],
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontFamily: 'Bowlby One',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const ButtonViewMore: CSSObject = {
  bg: 'beta.400',
  mr: '0',
  color: 'alpha.600',
  textAlign: 'center',
  fontSize: 'sm',
  fontFamily: 'Bowlby One',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  width: 'full',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  _hover: {
    bgGradient: 'none',
    bg: 'beta.500',
  },
};
