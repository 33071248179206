import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bg: 'white',
  borderRadius: 'md',
  mb: '2',
  flex: '1',
  boxShadow:
    '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
};

export const TypeItem: CSSObject = {
  color: 'gray.600',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: ['base', null, 'md'],
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  bg: 'gray.100',
  '&:last-child': {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'gray.500',
};
