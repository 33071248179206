import { ButtonProps, chakra, Flex, Text } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Banner.styles.imports';

const themeName = getThemeName();

export type BannerSchema = Partial<{
  buttonDeposit: ButtonProps;
}>;

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    'data-testid': 'banner-buttonDeposit',
    px: '2',
    py: '1',
    borderRadius: 'base',
    w: ['full', null, 'unset'],
    textTransform: 'uppercase',
    fontWeight: 'extrabold',

    ...(styleImports?.[themeName]?.bannerStyles?.buttonDeposit ?? {}),
  },
};

export const FlexAccountDetails = chakra(Flex, {
  label: 'banner-FlexAccountDetails',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexAccountDetails;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexAccountName = chakra(Flex, {
  label: 'banner-FlexAccountName',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexAccountName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexBannerContainer = chakra(Flex, {
  label: 'banner-FlexBannerContainer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBannerContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexLabel = chakra(Flex, {
  label: 'banner-FlexLabel',
  baseStyle: () => ({
    gap: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexBalanceBetInfo = chakra(Flex, {
  label: 'banner-FlexBalanceBetInfo',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBalanceBetInfo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexAccountBalances = chakra(Flex, {
  label: 'banner-FlexAccountBalances',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexAccountBalances;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexBannerBalanceInner = chakra(Flex, {
  label: 'banner-FlexBannerBalanceInner',
  baseStyle: () => ({
    flexDir: 'column',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBannerBalanceInner;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextButton = chakra(Text, {
  label: 'banner-TextButton',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextAccountBalanceLabel = chakra(Text, {
  label: 'banner-TextAccountBalanceLabel',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextAccountBalanceLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexAccountBalanceWrapper = chakra(Flex, {
  label: 'banner-FlexAccountBalanceWrapper',
  baseStyle: () => ({
    alignItems: 'baseline',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexAccountBalanceWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextAccountBalanceBaseUnit = chakra(Text, {
  label: 'banner-TextAccountBalanceBaseUnit',
  shouldForwardProp: (prop) => !['isBonus'].includes(prop),
  baseStyle: ({ isBonus }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextAccountBalanceBaseUnit?.(isBonus);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextAccountBalanceSubUnit = chakra(Text, {
  label: 'banner-TextAccountBalanceSubUnit',
  shouldForwardProp: (prop) => !['isBonus'].includes(prop),
  baseStyle: ({ isBonus }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextAccountBalanceSubUnit?.(isBonus);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextAccountBalanceCurrencySymbol = chakra(Flex, {
  label: 'banner-TextAccountBalanceCurrencySymbol',
  shouldForwardProp: (prop) => !['isBonus'].includes(prop),
  baseStyle: ({ isBonus }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextAccountBalanceCurrencySymbol?.(
          isBonus
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FlexButtonContainer = chakra(Flex, {
  label: 'banner-FlexButtonContainer',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexButtonContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
