import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapperProps: BoxProps = {
  bg: 'rgba(78, 178, 255, 0.14)',
  h: '100%',
  minW: ['100%', null, null, '460px'],
  display: 'flex',
  justifyContent: 'center',
  sx: {
    'div:first-child': {
      display: 'flex',
      flexDir: 'column',
      fontSize: 'xs',
      fontWeight: 'medium',
      alignItems: 'flex-start',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mb: '1',
      },
      p: {
        color: 'gamma.200',
        fontFamily: 'accent',
        fontWeight: 'normal',
      },
    },
  },
};
export const TextForgotPasswordSuccess: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',
  '+ a': {
    display: 'none',
  },
};
export const linkLoginProps: CSSObject = {
  mb: '4',
  color: 'alpha.400',
  textTransform: 'unset',
  fontSize: 'xs',
};
export const ForgotPasswordButton: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
