import { Image, Link, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logoDesktop from '@/assets/templebet/images/onboarding/onboardingLogo.png';
import logoMobile from '@/assets/templebet/images/onboarding/onboardingLogoMb.svg';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

const ImageContainer = () => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  return (
    <Link
      as={LinkDom}
      to="/"
      maxW={['65vw', '55vw']}
      alignSelf="center"
      mt={['0', '0', '15%']}
    >
      <Image w="100%" src={isMobile ? logoMobile : logoDesktop} />
    </Link>
  );
};

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: <ImageContainer />,
    config: {
      before: 'slogan',
    },
  },
]);
