import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
};

export const Subheading: CSSObject = {
  color: 'white',
};

export const DividerWithdrawal: CSSObject = {
  borderColor: 'gray.300',
  borderStyle: 'dashed',
};

export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

/**
 * Form Inputs
 */
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
};

export const InputWithdrawal: CSSObject = {
  bg: 'gray.200',
  border: '1px solid',
  borderColor: 'gray.300',
  borderRadius: 'sm',
  fontSize: 'sm',
  height: '38px',

  '.chakra-input__left-addon': {
    height: '38px',
    borderLeftRadius: 'base',
    fontSize: 'sm',
    fontWeight: 'bold',
    bg: 'beta.600',
    color: 'white',
  },

  _placeholder: {
    color: 'beta.700',
  },
};

/**
 * Buttons
 */
export const buttonAddAccountProps: CustomButtonProps = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  h: '38px',
  lineHeight: 'none',
  pos: 'relative',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  mt: '1',

  _hover: {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },
  _focus: {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },

  _active: {
    bgGradient: 'linear(to-b, alpha.800, alpha.800)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'alpha.300',
    textShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.45)',
  },
};

export const ButtonWithdrawal: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  h: '38px',
  lineHeight: 'none',
  pos: 'relative',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },

  _active: {
    bgGradient: 'linear(to-b, alpha.800, alpha.800)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'alpha.300',
    textShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.45)',
  },
};

export const ButtonIncrease: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  h: '38px',
  lineHeight: 'none',
  pos: 'relative',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },

  _active: {
    bgGradient: 'linear(to-b, alpha.800, alpha.800)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'alpha.300',
    textShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.45)',
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mt: '2',
};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
};

export const BankRadio: CSSObject = {
  alignItems: 'start',
  border: 'none',
  bg: 'gray.400',
  boxShadow: 'inset 0px 2px 4px rgba(49, 57, 77, 0.82)',

  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'beta.300',
    _hover: {
      bg: 'beta.400',
    },
  },

  '&[data-checked]:before': {
    display: 'flex',
    alignSelf: 'center',
    width: '40%',
    height: '40%',
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '0',
  pt: '0',
  w: ['100%', null, '330px'],
};

export const ListContainerHeading: CSSObject = {
  display: 'none',
};

/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'gray.700',
  fontWeight: 'bold',
};

export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  bg: status === EWithdrawalStatus.Pending && 'yellow.400',
  color: status === EWithdrawalStatus.Pending && 'yellow.800',
  textTransform: 'uppercase',
  fontSize: '2xs',
});

export const WithdrawalWrapper: CSSObject = {
  p: '2.5',
  bg: 'beta.100',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
};

export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
  color: 'gray.700',
};

export const IncreaseAmountWrapper: CSSObject = {
  mb: '2',
};
