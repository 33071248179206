import React from 'react';
import { Global } from '@emotion/react';
import bgHeader from '@/assets/puntcity/images/backgrounds/bg-header.png';
import bgHeaderMobile from '@/assets/puntcity/images/backgrounds/bg-header-mobile.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `linear-gradient(180deg, #3634AB 0%, #1F1E84 100%)`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto',
          backgroundAttachment: 'fixed',

          '&::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '600px',
            zIndex: '-9',
            background: `url(${bgHeaderMobile})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '768px auto',
            backgroundPosition: 'center -125px',
          },

          '@media (min-width: 480px)': {
            '&::before': { backgroundPosition: 'center -70px' },
          },

          '@media (min-width: 769px)': {
            backgroundImage: `url(${bgHeader}), linear-gradient(180deg, #3634AB 0%, #1F1E84 100%)`,
            backgroundPosition: 'center top, center',
            backgroundSize: 'cover, auto',
            backgroundAttachment: 'fixed',

            '&::before': { display: 'none' },
          },

          '@media (min-width: 992px)': {
            backgroundSize: '150%, 100%, auto',
          },

          '@media (min-width: 1280px)': {
            backgroundSize: '100%, 100%, auto',
          },
        },
      }}
    />
  );
}
