import { CSSObject } from '@chakra-ui/react';

export const CardContainer: CSSObject = {
  bg: 'zeta.400',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  color: 'epsilon.500',
};

export const DividerCard: CSSObject = {
  borderColor: 'red.400',
  borderWidth: '1px',
};

export const CardBottomContent: CSSObject = {
  borderColor: 'alpha.400',
  color: 'alpha.600',
};

export const ContentRow: CSSObject = {
  color: 'alpha.600',
  borderColor: 'alpha.300',
  '> hr': {
    width: '100%',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    color: 'alpha.600',
    bg: 'none',
    border: 'none',
  },
  '&:last-child': {
    borderBottom: '1px dashed rgba(0, 0, 0, 0.16)',
    pb: '2',
    div: {
      gap: 0,
      '&[data-cy="myBetsCardBetReturn"]': {
        marginBottom: '10px',
      },
    },
  },
};

export const ExoticSelectionText: CSSObject = {
  color: 'alpha.600',
};
