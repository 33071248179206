import { CSSObject } from '@chakra-ui/react';
import redBackground from '../../../assets/questbet/images/backgrounds/red-background.png';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';

export const LocationNotAllowedContainer: CSSObject = {
  // bg colour of image
  bg: '#E03956',

  ...(!IS_MOBILE_APP && {
    backgroundImage: `url(${redBackground})`,
    bgSize: 'contain',
    bgPos: 'bottom',
    bgRepeat: 'no-repeat',
    h: ['100vh', null, '668px'],
  }),
  width: ['full', null, '760px'],
  borderRadius: [null, null, 'lg'],
  boxShadow: [null, null, '0px 8px 30px rgba(0, 0, 0, 0.2)'],
  position: [null, null, 'absolute'],
  margin: [null, null, 'auto'],
  right: [null, null, '0'],
  left: [null, null, '0'],
  top: [null, null, '0'],
  bottom: [null, null, '0'],
};

export const ContentHeadingText: CSSObject = {
  fontSize: ['4xl', null, '5xl'],
  color: 'white',
  fontWeight: '400',
  fontFamily: 'Strippy',
  textAlign: 'center',
  textTransform: 'uppercase',
};

export const FlexVerbiageContainer: CSSObject = {
  alignItems: 'center',
  maxW: '600px',
  zIndex: '1',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))',
};

export const ContactText: CSSObject = {
  fontWeight: 'bold',
};

export const SubHeader: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: ['lg', null, 'xl'],
  textAlign: 'center',
};

export const ContentBodyText: CSSObject = {
  color: 'white',
  fontSize: ['sm', null, 'md'],
  textAlign: 'center',
};

export const CenterWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  mt: ['4', '4', '4'],
  maxW: ['380px', null, 'container.lg'],
  mx: 'auto',
  gap: '4',
  px: '4',
  ...(IS_MOBILE_APP && {
    bgImage: `url(${redBackground})`,
    bgSize: 'contain',
    bgPos: 'bottom',
    bgRepeat: 'no-repeat',
    flex: '1',
    maxW: 'full',
    h: '100vh',
    justifyContent: 'start',
  }),
};
