import { CSSObject, TextProps } from '@chakra-ui/react';

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
  letterSpacing: 'tight',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  h: '7',
  p: '1',
  w: '12',
};

export const RowDivider: CSSObject = {
  borderColor: 'gray.300',
  borderStyle: 'dashed',
  opacity: '1',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  fontWeight: 'medium',
  ml: '3',
  color: 'white',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  px: '1',
  py: '0.5',
};

export const IconButton: CSSObject = {
  h: '8',
  minWidth: '8',
  ml: '2.5',
  color: 'white',
};

export const BoxInputContainer: CSSObject = {
  mb: '1',

  '.chakra-form-control': {
    mb: '0',
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'normal',
  letterSpacing: 'tight',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const bannerHeaderProps: TextProps = {
  fontWeight: '900',
  fontSize: '11px',
  lineHeight: '13.2px',
  color: 'gamma.900',
};

export const bannerTextProps: TextProps = {
  fontSize: '11px',
  lineHeight: '13.2px',
  color: 'white',
  fontWeight: '500',
  mt: '0.5',
};
