import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};

export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  h: '18px',
  border: '1px',
  borderColor: 'white',
  '&&': {
    borderRadius: 'base',
  },
  textTransform: 'capitalize',
  p: '1',
  fontSize: '2xs',
  display: 'flex',
  flexDirection: 'row-reverse',
  fontWeight: 'bold',
  boxShadow: 'none',
  mb: ['1', '0'],
  color: 'white',
  textShadow: 'none',
  bg: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'green.500';
      case EGreenIDStatus.PENDING:
        return 'yellow.500';
      default:
        return 'red.500';
    }
  },
  _hover: {
    '&&&': {
      bg: () => {
        switch (status) {
          case EGreenIDStatus.VERIFIED:
          case EGreenIDStatus.VERIFIED_ADMIN:
          case EGreenIDStatus.VERIFIED_WITH_CHANGES:
            return 'green.600';
          case EGreenIDStatus.PENDING:
            return 'yellow.600';
          default:
            return 'red.500';
        }
      },
      color: 'white',
    },
  },

  '.chakra-button__icon': {
    mr: 0,
    ml: '0.25rem',
  },
  _disabled: {
    cursor: 'default',
    opacity: '1',
    boxShadow: 'none',
    background: 'green.600',
    color: 'white',
    textShadow: 'none',
    border: '1px',
    borderColor: 'white',
  },

  svg: { boxSize: '4' },
});
