import { PersonalDetailsSchema } from './PersonalDetails.styles';

export const personalDetailsStyles: PersonalDetailsSchema = {
  wrapperBox: {
    sx: {
      '&': {
        '.chakra-input': {
          borderLeftRadius: 'md',
          borderLeft: '1px',
          borderColor: 'alpha.500',
        },
      },
    },
  },
};
