import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { TBetCloudErrorResponse } from '@/api/api.types';
import { mutateClaimToken } from './claimToken';
import { TMutateClaimTokenResponse } from './claimToken.types';

export const useMutationClaimToken = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TMutateClaimTokenResponse>,
      AxiosError<TBetCloudErrorResponse>,
      string
    >,
    'mutationFn'
  >
) => useMutation(mutateClaimToken, options);
