import { CSSObject } from '@chakra-ui/react';
import raceCardBg from '@/assets/titanbet/images/homeTheming/raceCardBg.png';

export const GridRaceItems: CSSObject = {
  px: '4px',
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'gray.300',
  borderRadius: 'sm',
  color: 'gray.600',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const LinkRaceContainer: CSSObject = {
  border: '2px solid',
  borderColor: 'gamma.500',
  borderRadius: 'lg',
  bgImage: `url(${raceCardBg})`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'alpha.400',
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  fontFamily: 'accent',
  color: 'gamma.300',
  fontSize: 'md',
  textTransform: 'capitalize',
  fontWeight: 'normal',
};

export const TextRace: CSSObject = {
  fontFamily: 'accent',
  color: 'gamma.300',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  pt: '0.5',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'gamma.300',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};

export const BoxColumn: CSSObject = {
  mb: ['0', null, '5'],
  mt: ['4', null, '2'],
};
