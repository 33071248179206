import React, { useState } from 'react';
import { Divider, Flex, Box, Text, GridItem } from '@chakra-ui/react';
import { ChevronDown } from '@styled-icons/fa-solid';
import { FormattedMessage } from 'react-intl';
import {
  CardBottomContent,
  ContentRow,
  StatusBadge,
  ExoticSelectionText,
  dividerExoticProps,
  dividerExoticLastProps,
} from '../../styles/MyBetsCard.styles';
import { EBetTypes, TConfirmedBet } from '../../../../../../../lib/DBModels';
import { getStrings } from '@/helpers/utils';
import {
  DeductionsText,
  FooterBodyText,
  FooterMoreOpenText,
  MoreIcon,
  BoxMoreText,
  DividerMulti,
  footerWrapperExpanded,
  ToteMultiGrid,
  ToteMultiGridText,
} from './styles/Footer.styles';
import { formatDateTimeWZone } from '../../../../../../../lib/Time';
import { getPlaceFromNumber } from '../../../../../../../components/Betslip/Services/Betslip.utils';
import { getExoticSelections } from '../../../../services/MyBets.utils';
import HeaderRow from '../HeaderRow/HeaderRow';
import BottomRow from '../BottomRow/BottomRow';

const Footer: React.FC<TFooter> = ({ bet }) => {
  const [
    {
      Account: { MyBetsCard: Strings },
    },
  ] = getStrings();
  const [isExpanded, setIsExpanded] = useState(false);
  const exoticSelections = getExoticSelections(bet);
  const checkLegDeduction = bet.bet_legs?.map((d) => !!d.total_deductions);
  const { tote_multi_selections: toteMultiSelections } = bet;
  const legHasDeduction = (leg: boolean) => leg === true;

  return (
    <CardBottomContent
      sx={{
        flexDirection: 'column',
      }}
      tabIndex={0}
    >
      <Box className={isExpanded ? 'expanded' : 'collapsed'}>
        <FooterMoreOpenText
          px="1.5"
          py="2"
          data-cy={`betCardFooter-${bet.bet_id}`}
          alignItems="center"
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            e.preventDefault();
            if (e.key === 'Enter') setIsExpanded(!isExpanded);
          }}
        >
          <BoxMoreText
            data-cy={`betCardFooter-${
              isExpanded ? Strings.Less : Strings.More
            }`}
          >
            {isExpanded ? Strings.Less : Strings.More}
          </BoxMoreText>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              boxSize: '2',
              transition: 'all, 0.2s ease-in-out',
              cursor: 'pointer',
              transform: `${isExpanded ? 'rotate(180deg)' : 'rotate(0)'}`,
            }}
          >
            <MoreIcon as={ChevronDown} />
          </Flex>
          <Flex flexDirection="row" gap="2">
            {bet.total_deductions && (
              <StatusBadge>
                {' '}
                {checkLegDeduction?.some(legHasDeduction)
                  ? Strings.Badges.Deductions
                  : Strings.Badges.Deductions}
              </StatusBadge>
            )}
          </Flex>
        </FooterMoreOpenText>
        {isExpanded &&
          bet.bet_legs?.map((leg, idx) => (
            <>
              <ContentRow p="1.5" key={idx} flexDir="column">
                <Flex flexDir="column" w="full">
                  <HeaderRow bet={bet} leg={leg} index={idx} />
                  {bet.bet_description !== EBetTypes.SGMulti ||
                    (bet.bet_description !== EBetTypes.Blended && (
                      <BottomRow
                        bet={bet}
                        leg={leg}
                        isResulted={leg.status !== 'pending'}
                      />
                    ))}
                </Flex>
                {leg?.legs?.map((zep, index1) => (
                  <Flex ml="1" key={idx} sx={{ w: 'full', pl: '7' }}>
                    <ContentRow p="1.5" flexDir="column">
                      <Flex flexDir="column" w="full">
                        <HeaderRow bet={leg as any} leg={zep} index={index1} />
                        {bet.bet_description !== EBetTypes.SGMulti ||
                          (bet.bet_description !== EBetTypes.Blended && (
                            <BottomRow
                              bet={bet}
                              leg={zep}
                              isResulted={zep.status !== 'pending'}
                            />
                          ))}
                      </Flex>
                    </ContentRow>
                  </Flex>
                ))}
              </ContentRow>
              <DividerMulti className="multiDivider" />
            </>
          ))}
        {isExpanded && exoticSelections && (
          <>
            <ContentRow
              flexDir="column"
              gap="1.5"
              p="1.5"
              justifyContent="start"
              alignItems="start"
            >
              {exoticSelections?.map((sel, index) => (
                <>
                  <Flex key={index} flexDir="row" fontSize="xs">
                    <ExoticSelectionText fontWeight="bold" w="9">
                      {getPlaceFromNumber(index + 1)}
                    </ExoticSelectionText>

                    <Flex flexDir="column" gap="1">
                      {sel.map((num) => (
                        <ExoticSelectionText
                          key={`${index} ${num.runner_name}`}
                          textTransform="capitalize"
                        >
                          {num.runner_number}. {num.runner_name.toLowerCase()}
                        </ExoticSelectionText>
                      ))}
                    </Flex>
                  </Flex>

                  {index !== exoticSelections.length - 1 && (
                    <Divider
                      w="container.xs"
                      ml="0"
                      variant="dashed"
                      {...dividerExoticProps}
                    />
                  )}
                </>
              ))}
            </ContentRow>
            <Divider mx="1.5" w="unset" {...dividerExoticLastProps} />
          </>
        )}
        {isExpanded && toteMultiSelections?.length !== 0 && (
          <ToteMultiGrid>
            {toteMultiSelections?.map((tms, i) => (
              <GridItem w="100%" key={i}>
                <ContentRow p="1.5" flexDir="column">
                  <ToteMultiGridText className="raceNumber">
                    {`R${tms.leg}`}
                  </ToteMultiGridText>
                  {tms.leg_selection?.map((leg, index) => (
                    <ToteMultiGridText key={index}>
                      {`${leg.runner_number}. ${leg.runner_name} ${
                        leg.runner_barrier_number === 0
                          ? ''
                          : `(${leg.runner_barrier_number})`
                      }`}
                    </ToteMultiGridText>
                  ))}
                </ContentRow>
              </GridItem>
            ))}
          </ToteMultiGrid>
        )}
        {isExpanded && (
          <Box p="1.5" {...footerWrapperExpanded}>
            {(bet.event_type !== 'Match' ||
              bet.bet_legs?.some((leg) => leg.event_type !== 'Match')) && (
              <DeductionsText>
                <FormattedMessage id="account.mybetscard.badges.deductionsmayapply" />
              </DeductionsText>
            )}
            <FooterBodyText>
              {Strings.BetPlaced}
              &nbsp;
              <Text data-cy="myBetsCardCreatedAt" as="span" ml="auto">
                {formatDateTimeWZone(bet?.created_at)}
              </Text>
            </FooterBodyText>
            <FooterBodyText>
              {Strings.BetIdText}
              &nbsp;
              <Text data-cy="myBetsCardId" as="span" ml="auto">
                {bet.bet_id}
              </Text>
            </FooterBodyText>
          </Box>
        )}
      </Box>
    </CardBottomContent>
  );
};

export type TFooter = {
  isResulted?: boolean;
  bet: TConfirmedBet;
};

export default Footer;
