import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  // TODO: Fix theme colours
  background: 'linear-gradient(180deg, #3B63C4 0%, #2C4B96 100%)',
  borderRadius: 'md',
  p: '2',
  color: 'white',
  mt: '0',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'gamma.600',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'gamma.600',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  border: '1px dashed',
  borderColor: 'gamma.300',
  color: 'gamma.600',
  mb: '7',
  bg: 'none',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};
