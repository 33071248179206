import React from 'react';
import {
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Icon,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Text,
} from '@chakra-ui/react';
import { Scroll, User } from '@styled-icons/fa-solid';
import { ChatBubblesQuestion } from '@styled-icons/fluentui-system-filled';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG } from '@/constants/themeConfig';
import IconSvg from '../../../../../common/IconSvg';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { styleImports } from './QuickAccessMenu.styles.imports';

const themeName = getThemeName();

export const buttonDepositProps: CustomButtonProps = {
  'data-testid': 'qam-buttonDepositProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonDepositProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonBalanceProps: CustomButtonProps = {
  'data-testid': 'qam-buttonBalanceProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonBalanceProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const PopoverContentQA = chakra(PopoverContent, {
  label: 'qam-PopoverContentQA',
  baseStyle: () => ({
    w: ['100vw', '272px'],
    top: '-1',
    border: 'transparent',
    bg: 'transparent',
    boxShadow: 'dark-lg',
    contain: 'paint',
    borderRadius: ['0', 'md'],
    ...(() => {
      try {
        return styleImports[themeName]?.PopoverContentQA;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PopoverHeaderQA = chakra(PopoverHeader, {
  label: 'qam-PopoverHeaderQA',
  baseStyle: () => ({
    display: 'flex',
    px: '2.5',
    fontSize: 'xs',
    fontWeight: 'medium',
    justifyContent: 'space-between',
    bg: 'white',
    ...(() => {
      try {
        return styleImports[themeName]?.PopoverHeaderQA;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PopoverBodyQA = chakra(PopoverBody, {
  label: 'qam-PopoverBodyQA',
  baseStyle: () => ({
    p: 0,
    bg: 'white',

    ...(() => {
      try {
        return styleImports[themeName]?.PopoverBodyQA;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

// ---
export const BoxBallance = chakra(Box, {
  label: 'qam-BoxBallance',
  baseStyle: () => ({
    label: 'qam-BoxBallance',
    p: '2.5',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxBallance;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const FlexBallanceItem = chakra(Flex, {
  label: 'qam-FlexBallanceItem',
  baseStyle: () => ({
    justifyContent: 'space-between',
    _first: { mb: '0.5' },

    p: {
      fontSize: 'sm',
      fontWeight: 'semibold',
      _last: { fontWeight: 'medium' },
    },

    ...(() => {
      try {
        return styleImports[themeName]?.FlexBallanceItem;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TextKey = chakra(Text, {
  label: 'qam-TextKey',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextKey;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextValue = chakra(Text, {
  label: 'qam-TextValue',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextValue;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

// --
const ChakraButtonNavItem = (props: ButtonProps) => (
  <Button {...props} variant="unstyled" />
);

type TButtonNavItem = {
  isLastItem: boolean;
} & ButtonProps &
  any;

export const ButtonNavItem = chakra<TButtonNavItem>(ChakraButtonNavItem, {
  label: 'qam-ButtonNavItem',
  shouldForwardProp: (prop) => prop !== 'isLastItem',
  baseStyle: ({ isLastItem }: any) => ({
    display: 'flex',
    px: '2.5',
    fontSize: 'sm',
    h: '45px',
    w: 'full',
    alignItems: 'center',
    borderTop: '1px',
    borderColor: 'gray.200',
    borderBottomColor: isLastItem ? 'transparent' : 'gray.200',
    fontWeight: 'medium',
    borderRadius: '0',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    transition: 'all, .2s ease-in-out',

    svg: {
      boxSize: '4',
      mr: '1',
    },

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonNavItem?.({
          isLastItem,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', TButtonNavItem>;

export const BoxMascotImage = chakra(Box, {
  label: 'qam-BoxMascotImage',
  baseStyle: () => ({
    mr: '2',
    position: 'relative',

    ...(() => {
      try {
        return styleImports[themeName]?.BoxMascotImage;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const UserIcon = () => {
  try {
    return styleImports[themeName]?.UserIcon?.() ?? <Icon as={User} />;
  } catch (err) {
    return <Icon as={User} />;
  }
};

export const SupportIcon = () => (
  <Icon as={themeName === EThemesOG.Questbet ? Scroll : ChatBubblesQuestion} />
);

export const TextQuickAccessHello = chakra(Box, {
  label: 'qam-TextQuickAccessHello',
  baseStyle: () => ({
    fontSize: 'xs',
    ...(() => {
      try {
        return styleImports[themeName]?.TextQuickAccessHello;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BoxProps>;

export const BonusBalanceIcon = () => {
  try {
    return (
      styleImports[themeName]?.BonusBalanceIcon?.() ?? (
        <IconSvg name="presentforMark" />
      )
    );
  } catch (err) {
    return <IconSvg name="presentforMark" />;
  }
};
