import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  flex: '1',
  bg: 'white',
  px: '2.5',
  borderRadius: 'base',
  border: '2px',
  borderColor: '#FFE6B6',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px #000 inset',
};

export const TypeItem: CSSObject = {
  color: 'gray.700',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ResultRowWrapper: CSSObject = {
  border: 'none',
  bg: 'gray.100',
  borderRadius: 'base',
  px: '1.5',
  py: '1',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  mb: '1',
  _last: {
    mb: '2',
  },
};

export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};

export const ResultRowExoticRow: CSSObject = {
  color: 'gray.500',
};
