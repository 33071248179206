import { css } from 'styled-components';

export const buttonContainer = css`
  // margin-top: var(--bc-sizes-12);
  height: 50px;
`;

export const button = css`
  background-image: ${({ theme }) => theme.palette.btnPrimary};
  box-shadow: inset -1px -1px 10px 2px ${({ theme }) => theme.palette.white};
  border-radius: 15px;
  height: 30px;
  color: ${({ theme }) => theme.palette.secondary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  align-items: center;
  font-size: var(--bc-fontSizes-xs);
`;

export const emptyButton = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: 15px;
  height: 30px;
  color: ${({ theme }) => theme.palette.primary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  align-items: center;
`;

export const outlineButton = css`
  background-color: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.secondary};
  border-radius: 15px;
  height: 30px;
  color: ${({ theme }) => theme.palette.secondary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  align-items: center;
  font-size: var(--bc-fontSizes-xs);
`;

export const secondaryButton = css`
  background: ${({ theme }) => theme.basePalette.navyBlueGradient};
  border-radius: 15px;
  height: 30px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-style: italic;
  align-items: center;
  font-size: var(--bc-fontSizes-xs);
`;

export const buttonLoader = css`
  border: 3px solid ${({ theme }) => theme.palette.secondary};
  border-top: 3px solid transparent;
`;
