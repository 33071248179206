import { CSSObject } from '@chakra-ui/react';

export const EventsClosedContainer: CSSObject = {
  px: '2.5',
  mb: '1',
  minH: '12',
  py: '2',
  mx: '-4',
  alignItems: 'center',
  fontSize: ['sm', null, null, null, 'xs'],
};
