import { CSSObject, keyframes } from '@chakra-ui/react';
import toggle from '@/assets/goldenrush/images/switch/hammerFramed.png';
import sidePiston from '@/assets/goldenrush/images/betslip/side-piston.png';

export const FlexWrapper: CSSObject = {
  position: 'relative',
  width: '95%',
  minH: '76px',
  mt: ['0', null, '5'],
  mb: ['1', null, '0'],
  mx: 'auto',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  borderRadius: 'lg',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
  p: '9px',
  justifyContent: 'center',
  alignItems: 'center',
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '1',
    right: '1',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    zIndex: '3',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.400, beta.600)',
    boxShadow: '0px 1px 0px 0px #ED8934',
  },
  _after: {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '2',
    left: '2',
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    zIndex: '4',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
  },
};

export const TextToggle = (isActive: boolean): CSSObject => ({
  fontFamily: 'Bungee',
  fontSize: '2xl',
  textShadow: isActive
    ? '0px 3px 4px rgba(0, 0, 0, 0.3)'
    : '0px 1px 0px rgba(255, 255, 255, 0.12)',
  color: isActive ? 'gamma.500' : 'rgba(0, 0, 0, 0.50)',
  letterSpacing: '0.01rem',
  px: '2',
  mx: ['1', null, '0'],
  zIndex: '5',

  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '24px',
    left: '-8px',
    width: '30px',
    height: '85px',
    zIndex: 'docked',
    bg: `url(${sidePiston})`,
    bgRepeat: 'no-repeat',
    bgSize: '23px 35px',
  },

  _after: {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '24px',
    right: '-14px',
    width: '30px',
    height: '85px',
    zIndex: 'docked',
    bg: `url(${sidePiston})`,
    bgRepeat: 'no-repeat',
    bgSize: '23px 35px',
  },
});

export const Switcher = (): CSSObject => ({
  width: '50px',
  zIndex: '5',
  pos: 'relative',
  mx: '3',
  '.chakra-switch__track': {
    bg: 'blackAlpha.500',
    boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    w: '55px',
    zIndex: '5',
    pos: 'relative',
    borderRadius: 'md',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',

    _before: {
      bgImg: `url(${toggle})`,
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      height: '4.25rem',
      width: '4rem',
      content: '""',
      pos: 'absolute',
      bottom: '60px',
      left: '-18px',
      right: '0',
      top: '-21px',
      animation: `
            ${keyframes`
              from { transform: rotate(0deg) }
              to { transform: rotate(-360deg) }
            `} 0.5s ease-out`,
    },
  },

  _active: {
    '.chakra-switch__track': {
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    },
  },

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
      _before: {
        animation: `
            ${keyframes`
              from { transform: rotate(0deg); }
              to { transform: rotate(360deg); }
            `} 0.7s ease-out`,
      },
    },
  },
});
