import { css } from 'styled-components';

// TODO: Remove
export const container = css`
  box-shadow: inset 0 0 5px 1px ${({ theme }) => theme.basePalette.white},
    0 0 4px -1px ${({ theme }) => theme.basePalette.black};
  background: ${({ theme }) => theme.basePalette.brightBlue};
  padding: var(--bc-sizes-1) var(--bc-sizes-2);
  border-radius: var(--bc-radii-sm);
  display: flex;
  column-gap: var(--bc-sizes-1);
  min-width: var(--bc-sizes-14);
  min-height: var(--bc-sizes-6);
  max-width: var(--bc-sizes-14);
  justify-content: center;
  align-items: center;
`;

// TODO: Remove
export const containerCompact = css`
  padding-bottom: var(--bc-sizes-1);
  padding-top: var(--bc-sizes-1);
`;

// TODO: Remove
export const timerText = css`
  color: ${({ theme }) => theme.basePalette.navyBlue};
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
