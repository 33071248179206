import { CSSObject, BoxProps } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'beta.500',
  mb: '1',
  fontSize: '2xs',
  fontWeight: 'semibold',
};

export const FooterMoreOpenText: CSSObject = {
  color: 'beta.500',
};

export const BoxMoreText: CSSObject = {
  w: '8',
};

export const DividerMulti: CSSObject = {
  borderStyle: 'dashed',
  borderColor: 'blackAlpha.300',
  mx: '2',
};

export const footerWrapperExpanded: BoxProps = {
  sx: {
    '& > div': {
      color: 'white',
    },
  },
};
