import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { keys } from '@/api/api.keys';
import { querySportMarketGroups } from './sportDetailsMarketGroups';
import {
  TSportMarketGroupsParams,
  TSportMarketGroupsResponse,
} from './sportDetailsMarketGroups.types';

export const useQuerySportMarketGroup = (
  params: TSportMarketGroupsParams,
  options?: Omit<
    UseQueryOptions<TSportMarketGroupsResponse>,
    'queryKey' | 'queryFn'
  >
) => {
  const query = useQuery(
    [keys.sportDetailsMarketGroups, params],
    () => querySportMarketGroups(params),
    options
  );
  return query;
};
