import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { keys } from '@/api/api.keys';
import { queryFatZebraToken } from '@/api/deposit/deposit';
import {
  TFZCustomer,
  TFZSCAError,
  TFZSCASuccess,
  TFZTokenizationSuccess,
} from '@/api/deposit/deposit.types';
import { FAT_ZEBRA_JS, FAT_ZEBRA_USERNAME } from '@/lib/Constants';
import injectScript from '@/lib/injectScript';
import { PromiseResolver, makeResolver } from '@/lib/promiseResolver';
import { ENewRelicActions } from '@/constants/newRelicActions';
import { logPageAction } from '@/lib/newRelic';
import { useFeatureFlag } from '@/store/FeatureFlagStore';

export const loadFatZebraScript = () =>
  window.FatZebra
    ? Promise.resolve(window.FatZebra)
    : injectScript(FAT_ZEBRA_JS);

export const unloadFatZebraScript = () => {
  delete window.FatZebra;
  const fzSoundBirdScript = document.getElementById('songbird-script');

  if (fzSoundBirdScript) {
    fzSoundBirdScript.remove();
  }

  const node = document.querySelector('iframe#fz-hl');
  if (node) {
    node.parentNode?.removeChild(node);
  }
};

export class FatZebra {
  private fz: typeof window.FatZebra;

  private resolver: {
    tokenization?: PromiseResolver<TFZTokenizationSuccess>;
    sca?: PromiseResolver<TFZSCASuccess>;
  };

  private init() {
    const fz = new window.FatZebra({ username: FAT_ZEBRA_USERNAME });
    fz.on('fz.tokenization.success', (e: TFZTokenizationSuccess) => {
      const resolver = this.resolver.tokenization;
      if (resolver) {
        this.resolver.tokenization = undefined;
        resolver.resolve(e);
      }
    });

    fz.on('fz.tokenization.error', (e: any) => {
      const resolver = this.resolver.tokenization;
      if (resolver) {
        this.resolver.tokenization = undefined;
        resolver.reject(e);
      }
    });

    fz.on('fz.sca.success', (e: TFZSCASuccess) => {
      const resolver = this.resolver.sca;
      if (resolver) {
        this.resolver.sca = undefined;
        resolver.resolve(e);
      }
    });

    fz.on('fz.sca.error', (e: TFZSCAError) => {
      const resolver = this.resolver.sca;
      if (resolver) {
        this.resolver.sca = undefined;
        resolver.reject(e);
      }
    });

    fz.on('fz.payment.success', (e: { detail: unknown }) => {
      logPageAction(ENewRelicActions.Deposit, {
        event_type: 'fz.payment.success',
        payload: e.detail,
      });
    });

    fz.on('fz.payment.error', (e: { detail: unknown }) => {
      logPageAction(ENewRelicActions.Deposit, {
        event_type: 'fz.payment.error',
        payload: e.detail,
      });
    });

    this.fz = fz;
  }

  constructor() {
    this.resolver = {};
    this.init();
  }

  async reload() {
    unloadFatZebraScript();
    await loadFatZebraScript();
    this.resolver = {};
    this.init();
  }

  renderPaymentsPage(
    containerId: string,
    customer: TFZCustomer,
    amount: number,
    currency: string,
    verification: string,
    reference: string
  ) {
    const tokenResolver = makeResolver<TFZTokenizationSuccess>();
    this.resolver.tokenization = tokenResolver;

    this.fz.renderPaymentsPage({
      containerId,
      customer,
      paymentIntent: {
        payment: { amount, currency, reference },
        verification,
      },
      options: { tokenizeOnly: true },
    });

    return tokenResolver.promise;
  }

  verifyCard(
    token: string,
    customer: TFZCustomer,
    amount: number,
    currency: string,
    verification: string,
    reference: string
  ) {
    const sca = makeResolver<TFZSCASuccess>();
    this.resolver.sca = sca;

    this.fz.verifyCard({
      customer,
      paymentIntent: {
        payment: { amount, currency, reference },
        verification,
      },
      paymentMethod: {
        type: 'card_on_file',
        data: {
          token,
        },
      },
    });

    return sca.promise;
  }
}

export const useFatZebraToken = () => {
  const client = useQueryClient();
  const hasNewDeposit = useFeatureFlag('PRIMER_ENABLED');

  const [token] = useState(async () => {
    if (!hasNewDeposit) {
      const res = await client.fetchQuery(
        [keys.depositGetFatZebraToken],
        queryFatZebraToken,
        {
          staleTime: 60 * 5000,
        }
      );

      localStorage.setItem('fz-access-token', res.token);
    }
  });

  return token;
};
