import { faker } from '@faker-js/faker';
import { EGeneralStatus } from '@/lib/DBModels';
import {
  TQueryUpcomingMatchesParams,
  TQueryUpcomingMatchesResponse,
} from './upcomingMatches.types';

export function makeData({
  limit,
}: TQueryUpcomingMatchesParams): TQueryUpcomingMatchesResponse {
  return {
    items: Array(limit || 50)
      .fill('')
      .map(() => ({
        match_id: faker.datatype.uuid(),
        match_name: faker.commerce.productName(),
        match_start_time: faker.date.recent().toISOString(),
        sport_id: faker.datatype.uuid(),
        sport_name: faker.commerce.productName(),
        competition_id: faker.datatype.uuid(),
        competition_name: faker.commerce.productName(),
        market_count: faker.datatype.number(100),
        main_markets: [
          {
            market_id: faker.datatype.uuid(),
            market_name: faker.commerce.productName(),
            status: faker.helpers.arrayElement(Object.values(EGeneralStatus)),
            propositions: [
              {
                proposition_id: faker.datatype.uuid(),
                proposition_name: faker.animal.cat(),
                odds: faker.datatype.float({ max: 100 }),
                status: faker.helpers.arrayElement(
                  Object.values(EGeneralStatus)
                ),
                is_suspended: faker.datatype.boolean(),
              },
              {
                proposition_id: faker.datatype.uuid(),
                proposition_name: faker.animal.dog(),
                odds: faker.datatype.float({ max: 100 }),
                status: faker.helpers.arrayElement(
                  Object.values(EGeneralStatus)
                ),
                is_suspended: faker.datatype.boolean(),
              },
            ],
          },
        ],
        sgm_available: faker.datatype.boolean(),
      })),
    paging: { next_offset: faker.date.recent().toISOString() },
  };
}
