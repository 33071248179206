import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderColor: 'epsilon.200',
  borderStyle: 'dashed',
  opacity: '1',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};

export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  color: 'gamma.800',
};

export const TextMaxCards: CSSObject = {
  color: 'delta.100',
};

export const BoxInputContainer: CSSObject = {
  mb: '1',

  '.chakra-form-control': {
    mb: '0',
  },

  '& .chakra-input': {
    _placeholder: {
      color: 'beta.700',
      fontSize: 'xs',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '1',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'gamma.800',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'regular',
  lineHeight: '14.4px',
  color: 'white',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
};
