import { CSSObject, keyframes } from '@chakra-ui/react';

import toggle from '@/assets/vikingbet/images/switch/menu-toggle.png';
import toggleBg from '@/assets/vikingbet/images/backgrounds/texture-wood-1x.png';
import arrowLeft from '@/assets/vikingbet/images/backgrounds/menu-arrow-left.svg';
import arrowRight from '@/assets/vikingbet/images/backgrounds/menu-arrow-right.svg';

export const FlexWrapper: CSSObject = {
  marginTop: [4, 0],
  mx: 1,
  alignItems: 'center',
  bg: `url(${toggleBg}) center no-repeat`,
  backgroundSize: ['100%', null, null, '270px 100%'],
  borderRadius: '1.2rem',
  borderY: '#C5C4C0 5px solid',
  borderX: '#C5C4C0 8px solid',
  boxShadow:
    '0px 0px 5px 6px rgba(0, 0, 0, 0.40) inset, 0px 5px 0px 0px #757472, 0px 10px 0px 0px #464646, 0px -4px 0px 0px #EDEDEC',
  contain: 'layout',
  height: '70px',
  justifyContent: 'center',
  mb: '7',
  px: '6',
  _before: {
    content: ['none', null, null, '""'],
    display: 'block',
    position: 'absolute',
    bottom: '16%',
    right: '0px',
    width: '14px',
    height: '44px',
    zIndex: '3',
    backgroundImage: `url(${arrowLeft})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  _after: {
    content: ['none', null, null, '""'],
    display: 'block',
    position: 'absolute',
    bottom: '16%',
    left: '0px',
    width: '14px',
    height: '44px',
    zIndex: '3',
    backgroundImage: `url(${arrowRight})`,
    backgroundSize: '98% 98%',
    backgroundRepeat: 'no-repeat',
  },
};

export const TextToggle = (): CSSObject => ({
  fontFamily: 'SkranjiRegular',
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: '0px 4px 4px black',
  color: '#F2D194',
  letterSpacing: '1%',
  px: '2.5',
  pt: '1',
});

export const Switcher = (): CSSObject => ({
  '.chakra-switch__track': {
    bg: 'blackAlpha.500',
    boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',

    _before: {
      bgImg: `url(${toggle})`,
      height: '3.5rem',
      width: '3.5rem',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      bottom: '60px',
      left: '-15px',
      right: '0',
      top: '-12px',
      animation: `
              ${keyframes`
                from { transform: rotate(0deg) }
                to { transform: rotate(-360deg) }
              `} 0.5s ease-out`,
    },
  },

  _active: {
    '.chakra-switch__track': {
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    },
  },

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
      _before: {
        animation: `
              ${keyframes`
                from { transform: rotate(0deg); }
                to { transform: rotate(360deg); }
              `} 0.7s ease-out`,
      },
    },
    '.chakra-switch__track': {
      bg: 'blackAlpha.500',
    },
  },
});
