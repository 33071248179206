import React, { ReactNode } from 'react';
import { Box, CSSObject, Collapse } from '@chakra-ui/react';
import { TListItem } from '../../SideNav.types';
import SideNavLink from '../SideNavLink/SideNavLink';

type TDropDownContainer = {
  sx: CSSObject | undefined;
  isOpen: boolean;
  children: ReactNode;
  subs?: TListItem[];
};

const DropDownContainer: React.FC<TDropDownContainer> = ({
  sx,
  isOpen,
  subs,
  children,
}) => (
  <>
    <Box sx={sx}>{children}</Box>

    <Collapse in={isOpen} animateOpacity>
      {subs &&
        subs.map((link) => (
          <SideNavLink key={`${link.to}-${link.text}`} link={link} />
        ))}
    </Collapse>
  </>
);

export default DropDownContainer;
