import { useLocation } from 'react-router';

export const usePathStartsWith = (pathStartToMatch: string | string[]) => {
  const location = useLocation();

  if (typeof pathStartToMatch === 'string') {
    return location.pathname.startsWith(pathStartToMatch);
  }
  return !!pathStartToMatch.find((path) => location.pathname.startsWith(path));
};
