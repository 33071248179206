/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import {
  WithdrawalCardContent,
  WithdrawalCardDetailHeading,
  WithdrawalCardDetail,
  WithdrawalCardDetailNumber,
  WithdrawalCardDetailRequested,
  WithdrawalFlexContainer,
  WithdrawalStatus,
  WithdrawalWrapper,
  ButtonWithdrawalCardCancel,
} from '../styles/RequestedWithdrawalCard.styles';
import { centsToDollars } from '@/helpers/utils';
import { EWithdrawalStatus, TWithdrawal } from '../../../../../lib/DBModels';

/**
 * FIXME: This process for creating templates is outdated. We want to move any logic into reusable components.
 */
const template = (
  withdrawalRequest: TWithdrawal,
  handleCancelWithdrawal: (id: string | undefined) => void
): TTemplate => ({
  wrapper: <WithdrawalWrapper flexDir="column" backgroundColor="white" />,
  content: (
    <WithdrawalCardContent>
      <WithdrawalCardDetailHeading borderStyle="dashed">
        <FormattedMessage id="account.withdraw.requests.heading" />
      </WithdrawalCardDetailHeading>
      <WithdrawalCardDetailNumber
        justifyContent="flex-start"
        gap="2"
        fontSize="xs"
        fontWeight="bold"
        color="gray.500"
        borderStyle="dashed"
      >
        <Text>
          {dayjs(withdrawalRequest?.date_requested).format('DD/MM/YYYY')}
        </Text>
        <Text> | </Text>
        <Text>
          {dayjs(withdrawalRequest?.date_requested).format('hh:mm A')}
        </Text>
      </WithdrawalCardDetailNumber>
      <WithdrawalCardDetail borderStyle="dashed">
        <Text fontWeight="semibold" className="withdrawName">
          <FormattedMessage id="account.withdraw.requests.status.withdraw" />
        </Text>
        <WithdrawalStatus
          status={withdrawalRequest?.withdrawal_status ?? undefined}
        >
          <FormattedMessage
            id={`account.withdraw.requests.status.${withdrawalRequest?.withdrawal_status?.toLowerCase()}`}
          />
        </WithdrawalStatus>
      </WithdrawalCardDetail>
      <WithdrawalCardDetailRequested borderStyle="dashed" fontSize="xs">
        <Text fontWeight="semibold">{withdrawalRequest?.bank_nickname}</Text>
        <Text fontWeight="semibold">{`-${centsToDollars(
          withdrawalRequest?.amount_requested
        )}`}</Text>
      </WithdrawalCardDetailRequested>
    </WithdrawalCardContent>
  ),
  cancelButton: (
    <WithdrawalFlexContainer>
      {withdrawalRequest?.withdrawal_status === EWithdrawalStatus.Pending &&
        !withdrawalRequest.withdrawal_cancel_disabled && (
          <ButtonWithdrawalCardCancel
            px="5"
            py="2"
            fontSize="sm"
            w="full"
            onClick={() =>
              handleCancelWithdrawal(withdrawalRequest?.withdrawal_id)
            }
          >
            <FormattedMessage id="account.withdraw.requests.cancelbutton" />
          </ButtonWithdrawalCardCancel>
        )}
    </WithdrawalFlexContainer>
  ),
});

export default template;
