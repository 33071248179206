export const colors = {
  alpha: {
    50: '#ECEDFB',
    100: '#DADAF6',
    200: '#B4B6ED',
    300: '#8F91E4',
    400: '#696DDB',
    500: '#4448D2',
    600: '#3641AF',
    700: '#3634AB',
    800: '#1F1E84',
    900: '#19226D',
  },
  beta: {
    50: '#FFE6F4',
    100: '#FFD0EB',
    200: '#F9BDE0',
    300: '#FD9CC3',
    400: '#F282AF',
    500: '#FE62FF',
    600: '#E7529D',
    700: '#851C86',
    800: '#702670',
    900: '#411641',
  },
  gamma: {
    50: '#EEFAFE',
    100: '#DCF6FD',
    200: '#B9ECFC',
    300: '#A9EBFF',
    400: '#91F6FD',
    500: '#51D0F7',
    600: '#37BAE3',
    700: '#70BAE8',
    800: '#33ACE9',
    900: '#134A5C',
  },
  delta: {
    50: '#FFF6DF',
    100: '#FFF4D7',
    200: '#FFE8AE',
    300: '#FFDD86',
    400: '#FFD15D',
    500: '#FFC635',
    600: '#CC9E2A',
    700: '#997720',
    800: '#664F15',
    900: '#33280B',
  },
  theta: {
    50: '#FFF',
    100: '#FFF',
    200: '#FFF',
    300: '#FFF',
    400: '#FFF',
    500: '#FFF',
    600: '#FFF',
    700: '#FFF',
    800: '#FFF',
    900: '#FFF',
  },
  eta: {
    50: '#FFF',
    100: '#FFF',
    200: '#FFF',
    300: '#FFF',
    400: '#FFF',
    500: '#FFF',
    600: '#FFF',
    700: '#FFF',
    800: '#FFF',
    900: '#FFF',
  },
};
