const branding = {
  alpha: {
    50: '#FFF9EB',
    100: '#FEF1D7',
    200: '#FDE2AE',
    300: '#FCD486',
    400: '#FFCE70',
    500: '#F9B635',
    600: '#D6A64A',
    700: '#CC952B',
    800: '#644915',
    900: '#32250B',
  },
  beta: {
    50: '#C2D2E9',
    100: '#8192AA',
    200: '#4B5A6D',
    300: '#3F4953',
    400: '#3E454D',
    500: '#30363F',
    600: '#21252B',
    700: '#1C1D21',
    800: '#131619',
    900: '#0A0B0D',
  },
  gamma: {
    50: '#D2E4F8',
    100: '#A7B6C6',
    200: '#7D8894',
    300: '#656E78',
    400: '#484E56',
    500: '#434343',
    600: '#363636',
    700: '#282828',
    800: '#1C1C1C',
    900: '#0D0D0D',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  theta: {},
  delta: {},
  epsilon: {},
  eta: {},
};
