const branding = {
  alpha: {
    50: '#ABF0FF',
    100: '#4EDFFF',
    200: '#00CAF6',
    300: '#009ABC',
    400: '#006E86',
    500: '#2E6474',
    600: '#275563',
    700: '#1F444F',
    800: '#152E36',
    900: '#0F2127',
  },
  beta: {
    50: '#FFE0C5',
    100: '#FFCDA2',
    200: '#F6BA85',
    300: '#F09E57',
    400: '#F28B33',
    500: '#D56C13',
    600: '#A5540E',
    700: '#9B4F0E',
    800: '#773B24',
    900: '#4E2A1D',
  },
  gamma: {
    50: '#FFF3D1',
    100: '#FF#DB8',
    200: '#FF379D',
    300: '#FFE081',
    400: '#F9D451',
    500: '#FFCB2D',
    600: '#DA911A',
    700: '#B17514',
    800: '#81530A',
    900: '#4E3205',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  delta: {},
  epsilon: {},
  theta: {},
};
