import { CSSObject, keyframes } from '@chakra-ui/react';

import toggle from '../../../../../assets/volcanobet/images/toggle/toggle.png';
import toggleBg from '../../../../../assets/volcanobet/images/toggle/toggle-bg.png';
import toggleBgAtRetina from '../../../../../assets/volcanobet/images/toggle/toggle-bg@2x.png';

export const FlexWrapper: CSSObject = {
  alignItems: 'center',
  bgImg: ['none', null, null, `url(${toggleBg})`],
  bgPos: 'center',
  bgRepeat: 'no-repeat',
  bgSize: 'contain',
  contain: 'layout',
  justifyContent: 'center',
  bg: ['epsilon.400', null, null, 'none'],
  px: '6',
  py: ['6', null, 'unset'],
  mb: ['1', null, 'unset'],
  mx: ['1.5', null, null, '0'],
  border: ['3px solid', null, null, 'none'],
  borderColor: 'epsilon.200',
  borderRadius: 'lg',

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImg: ['none', null, null, `url(${toggleBgAtRetina})`],
  },
};

export const TextToggle = (): CSSObject => ({
  color: 'alpha.500',
  fontFamily: 'Caveman',
  fontSize: '2xl',
  letterSpacing: 'widest',
  px: '2',
  mx: ['1', null, '0'],
});

export const Switcher = (): CSSObject => ({
  width: '50px',
  '.chakra-switch__track': {
    bg: 'blackAlpha.500',
    boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
  },

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',

    _before: {
      bgImg: `url(${toggle})`,
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      height: '4.25rem',
      width: '4.5rem',
      content: '""',
      pos: 'absolute',
      bottom: '60px',
      left: '-6',
      right: '0',
      top: '-20px',
      animation: `
            ${keyframes`
              from { transform: rotate(0deg) }
              to { transform: rotate(-360deg) }
            `} 0.5s ease-out`,
    },
  },

  _active: {
    '.chakra-switch__track': {
      boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
    },
  },

  _checked: {
    '.chakra-switch__thumb': {
      transitionDuration: '0.7s',
      _before: {
        animation: `
            ${keyframes`
              from { transform: rotate(0deg); }
              to { transform: rotate(360deg); }
            `} 0.7s ease-out`,
      },
    },
  },
});
