import {
  Badge,
  BadgeProps,
  Box,
  Center,
  CenterProps,
  chakra,
  ChakraComponent,
  Flex,
  IconButton,
  IconButtonProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { styleImports } from './RaceTable.styles.imports';
import IconSvg from '@/components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type RaceTableSchema = Partial<{
  iconSvgMoneyBackProps: any; // TODO: type properly
  afterLinkRaceItemProps: any; // TODO: type properly
}>;

export const raceTableStyles: RaceTableSchema = {
  afterLinkRaceItemProps: {
    'data-testid': 'raceTable-LinkRaceItem',
    content: `""`,
    w: '50%',
    h: '4px',
    borderTopRadius: 'lg',
    position: 'absolute',
    bottom: 0,
    top: 'auto',
    bg: 'alpha.100',

    ...(styleImports?.[themeName]?.raceTableStyles?.afterLinkRaceItemProps ??
      {}),
  },
  iconSvgMoneyBackProps: {
    'data-testid': 'raceTable-IconSvgMoneyBack',

    ...(styleImports?.[themeName]?.raceTableStyles?.iconSvgMoneyBackProps ??
      {}),
  },
};

/**
 * Wrapper for Heading and table
 */
export const BoxPageWrapper = chakra(Box, {
  label: 'raceTable-BoxPageWrapper',
  baseStyle: ({ theme }) => ({
    ...(theme.themeName === EThemes.Betgalaxy && {
      mb: ['2', null, 'unset'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.BoxPageWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Heading for each table
 */
export const FlexHeading = chakra(Flex, {
  label: 'raceTable-FlexHeading',
  baseStyle: ({ theme }) => ({
    px: ['1', '2'],
    py: '1.5',
    alignItems: 'center',
    pos: 'sticky',
    zIndex: 'docked',
    mb: ['1', 'unset'],

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'beta.500',
      color: 'white',
      borderBottomRadius: [null, null, 'md'],
      borderTopRadius: 'md',
      top: ['43px', '52px'],
      px: ['3', null, '2'],
      mb: ['0', null, 'unset'],
      position: 'unset',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.FlexHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconHeading = chakra(IconSvg, {
  label: 'raceTable-IconHeading',
  baseStyle: ({ theme }) => ({
    borderRadius: 'full',
    boxSize: '8',
    mr: '1.5',

    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.IconHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextHeading = chakra(Text, {
  label: 'raceTable-TextHeading',
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'semibold',
    ...(() => {
      try {
        return styleImports[themeName]?.TextHeading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Wrapper for the venue name and country that is sticky
 */
export const BoxTableWrapper = chakra(Box, {
  label: 'raceTable-BoxTableWrapper',
  baseStyle: ({ theme }: any) => ({
    px: ['2', 'unset'],

    overflowX: 'scroll',

    // Hide scrollbar
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    ...(theme.themeName === EThemes.Betgalaxy && {
      border: ['1px', null, 'unset'],
      borderColor: ['gray.200', null, 'unset'],
      borderTop: [0, null, 'unset'],
      borderBottomRadius: ['md', null, 'unset'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxTableWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Table heading
 */
export type TTableHeading = {
  isFirst: boolean;
  initWidth: number;
} & CenterProps;

export const CenterTH = chakra(Center, {
  label: 'raceTable-CenterTH',
  shouldForwardProp: (prop) => !['isFirst', 'initWidth'].includes(prop),
  baseStyle: ({ isFirst, initWidth }: any) => ({
    py: '2',
    px: '2',
    bg: 'white',
    flex: 1,
    fontSize: '2xs',
    fontWeight: 'semibold',
    color: 'black',

    ...(!isFirst && {
      flexBasis: [null, initWidth],
      maxWidth: [null, initWidth],
      minW: [null, '65px'],
    }),

    ...(isFirst && {
      bg: 'white',
      w: [null, '110px'],
      minW: [null, '110px'],
      pos: 'sticky',
      left: 0,
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.CenterTH?.({
          isFirst,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TTableHeading>;

/**
 * Each venue heading row
 */
export const FlexHeadingRow = chakra(Flex, {
  label: 'raceTable-FlexHeadingRow',
  baseStyle: () => ({
    display: ['none', null, 'flex'],
    ...(() => {
      try {
        return styleImports[themeName]?.FlexHeadingRow;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

/**
 * Each venue row
 */
export const FlexRow = chakra(Flex, {
  label: 'raceTable-FlexRow',
  baseStyle: ({ theme }: any) => ({
    borderRadius: ['md', 'unset'],
    mb: ['1', null, 'unset'],
    contain: ['paint', null, 'unset'],
    pos: ['relative', null, 'unset'],

    _last: { '& > *': { borderBottom: '1px', borderBottomColor: 'gray.200' } },

    ...(theme.themeName === EThemes.Betgalaxy && {
      borderRadius: ['unset', null, 'unset'],
      borderTop: ['1px', null, 'unset'],
      borderTopColor: ['gray.200', null, 'unset'],
      mx: ['-2', 'unset'],
      mb: [0, null, 'unset'],

      _last: {
        '& > *': {
          borderBottom: ['unset', null, '1px'],
          borderBottomColor: [null, null, 'gray.200'],
        },
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexRow;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

/**
 * Wrapper for the venue name and country that is sticky
 */
export const BoxVenueName = chakra(Flex, {
  label: 'raceTable-BoxVenueName',
  baseStyle: ({ theme }) => ({
    zIndex: ['unset', 2],
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '2',
    pr: 'unset',
    bg: 'white',
    w: [null, '110px'],
    minW: [null, '110px'],
    pos: [null, 'sticky'],
    left: 0,
    flex: [1, 'unset'],
    borderRight: [null, null, '1px'],
    borderTop: '1px',
    borderTopColor: 'gray.200',
    borderColor: [null, null, 'gray.200'],
    ...(theme.themeName === EThemes.Wellbet && {
      borderTopColor: ['unset', null, 'gray.300'],
      borderLeft: ['unset', null, '1px'],
      borderLeftColor: ['unset', null, 'gray.300'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxVenueName;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Venue name Text
 */
export const TextVenueName = chakra(Text, {
  label: 'raceTable-TextVenueName',
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: ['medium', 'semibold'],
    color: ['gray.800', 'black'],
    mb: '.5',
    ...(() => {
      try {
        return styleImports[themeName]?.TextVenueName;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

/**
 * Venue country Text
 */
export const TextVenueCountry = chakra(Text, {
  label: 'raceTable-TextVenueCountry',
  baseStyle: ({ theme }: any) => ({
    fontSize: '2xs',
    fontWeight: 'bold',
    color: 'gray.400',
    '&&': {
      display: ['none', 'block'],
    },

    ...(theme.themeName === EThemes.Betgalaxy && {
      '&&': {
        display: 'block',
      },
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TextVenueCountry;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps>;

/**
 * Race Items wrapper
 */

export type TLinkRaceItem = {
  isActive: boolean;
  hasContent: boolean;
  initWidth: number;
  hasPromo: boolean;
  isToteMultiAvailable: boolean;
} & LinkProps;

export const LinkRaceItem = chakra(Link, {
  label: 'raceTable-LinkRaceItem',
  shouldForwardProp: (prop) =>
    !['isActive', 'hasContent', 'initWidth', 'hasPromo'].includes(prop),
  baseStyle: ({
    theme,
    isActive,
    hasContent,
    initWidth,
    isToteMultiAvailable,
  }: any) => ({
    zIndex: ['unset', 1],
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['flex-end', null, 'center'],
    bg: isActive ? [null, null, 'white'] : [null, null, 'gray.50'],
    borderWidth: '1px 1px 0 0',
    borderColor: 'gray.200',
    transition: 'background .2s ease-in-out',
    pr: ['1.5', null, '0'],
    columnGap: ['1', null, '0'],

    pos: ['absolute', null, 'relative'],
    top: [0, null, 'unset'],
    bottom: [0, null, 'unset'],
    left: [0, null, 'unset'],
    right: [0, null, 'unset'],

    flex: [null, null, '1'],
    flexBasis: [null, null, initWidth],
    maxW: [null, null, initWidth],
    minW: [null, null, '65px'],

    _hover: {
      bg: 'gray.100',
    },

    ...(!hasContent && {
      display: ['none', 'flex'],
    }),

    ...(theme.themeName === EThemes.Betgalaxy && {
      borderTop: [0, null, '1px'],
      borderTopColor: ['unset', null, 'gray.200'],
      borderRight: [null, null, '1px'],
      borderColor: [null, null, 'gray.200'],
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkRaceItem?.({
          isActive,
          isToteMultiAvailable,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', TLinkRaceItem>;

/**
 * Race Item
 */
export const BadgeRaceItem = chakra(Badge, {
  label: 'raceTable-BadgeRaceItem',
  baseStyle: ({ state }: any) => ({
    fontSize: '2xs',
    color: 'white',
    lineHeight: 'none',
    py: '0.5',
    bg: (() => {
      if (state === 'abandoned' || state === 'closed') {
        return 'gray.500';
      }

      if (state === 'result') {
        return 'black';
      }

      return 'gray.500';
    })(),
    ...(() => {
      try {
        return styleImports[themeName]?.BadgeRaceItem?.({
          state,
        });
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BadgeProps & { state?: string }>;

/**
 * Mobile race number badge
 */
export const BadgeRaceNumber = chakra(Badge, {
  label: 'raceTable-BadgeRaceNumber',
  baseStyle: () => ({
    fontSize: '2xs',
    color: 'white',
    lineHeight: 'none',
    py: '0.5',
    bg: 'gray.900',
    display: ['inline-block', null, 'none'],
    ...(() => {
      try {
        return styleImports[themeName]?.BadgeRaceNumber;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BadgeProps>;

/**
 * Race results text results
 */
export const TextRaceResults = chakra(Text, {
  label: 'raceTable-TextRaceResults',
  baseStyle: ({ theme }: any) => ({
    fontSize: '2xs',
    fontWeight: 'bold',
    color: 'gray.600',

    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: [null, null, 'gray.900'],
      color: [null, null, 'white'],
      px: [null, null, '1'],
      py: [null, null, '.5'],
      borderRadius: [null, null, 'sm'],
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TextRaceResults;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', BadgeProps & { state?: string }>;

/**
 * Wrapper for the scrolling buttons
 */
export const FlexButtonWrapper = chakra(Flex, {
  label: 'raceTable-FlexButtonWrapper',
  baseStyle: ({ theme }) => ({
    display: ['none', 'flex'],

    ...(theme.themeName === EThemes.Betgalaxy && {
      ml: 'auto',
      flexDir: 'row-reverse',
      columnGap: '4',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.FlexButtonWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

/**
 * Buttons for scrolling
 */
export const ButtonChevron = chakra(IconButton, {
  label: 'raceTable-ButtonChevron',
  baseStyle: ({ theme }) => ({
    display: 'inline-flex',
    w: '7',
    px: '1',
    flex: 1,
    minWidth: 'unset',
    borderRadius: 'none',
    h: '4',

    '& > svg': { boxSize: '3' },

    ...(theme.themeName === EThemes.Wellbet && {
      color: 'white',
      borderRadius: 'full',
      border: '2px',
      borderColor: 'white',
      boxSize: '5',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'white',
      borderRadius: 'full',
      border: '2px',
      borderColor: 'white',
      boxSize: '5',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ButtonChevron;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'button', IconButtonProps>;
