import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'alpha.600', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'alpha.600'] },
};

export const FlexHeadingRow: CSSObject = {
  bgGradient: 'linear(to-b, zeta.400, zeta.600)',
};

export const FlexHeading: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  height: '42px',
  padding: '4px 8px',
};

export const IconHeading: CSSObject = {
  borderRadius: 'md',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
  display: 'flex',
  justifyContent: 'center',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  alignItems: 'center',
  boxSize: '7',
  p: '1',
  color: 'yellow',
};

export const BoxPageWrapper: CSSObject = {
  mx: '3',
  mb: '3',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.600',
  bg: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  overflow: 'hidden',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: 'md',
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'beta.500',
  fontSize: 'xs',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: '133%',
  color: 'alpha.700',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.600',
  fontSize: '2xs',
};

export const TextRaceResults: CSSObject = {
  color: 'alpha.800',
  borderRadius: 'base',
  px: '4px',
  py: '3px',
  fontWeight: 'medium',
};

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'beta.600' : 'transparent',
  borderColor: 'alpha.400',
  _hover: {
    background: ['transparent', null, 'gray.300'],
  },
  '.chakra-badge.state-inprogress': {
    h: '4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 4px',
    borderRadius: 'base',
    background: 'alpha.800',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '2xs',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },

  '.chakra-badge.state-prior': {
    h: '4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 4px',
    borderRadius: 'base',
    background: 'rgba(0, 0, 0, 0.36)',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '2xs',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },

  '.chakra-badge.state-ended': {
    h: '4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 4px',
    borderRadius: 'base',
    background: 'alpha.300',
    color: 'alpha.800',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '2xs',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
});

export const BadgeRaceItem = (): CSSObject => ({
  borderRadius: 'base',
  bg: 'blackAlpha.500',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  h: '5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3px 4px',
});

export const FlexRow: CSSObject = {
  bg: 'white',
  _last: {
    '& > *': {
      borderBottom: ['unset', null, 'unset'],
    },
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'zeta.600'],
  bg: 'white',
};

export const CenterTH = () => ({
  py: '2.5',
  background: 'alpha.200',
  borderBottom: '1px solid',
  borderColor: 'gray.300',
  color: 'alpha.800',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'normal',
});

export const ButtonChevron: CSSObject = {
  color: 'white',
  bg: 'alpha.700',
  borderRadius: 'base',
  boxSize: '5',
  width: '9',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
