import { CSSObject } from '@chakra-ui/react';

export const TextTitle: CSSObject = {
  color: 'white',
};

export const TextDescription: CSSObject = {
  fontSize: 'xs',
  mb: '7',
  color: 'white',
};

export const TextHeading: CSSObject = {
  fontSize: 'xs',
  mb: '4',
  color: 'white',
};

export const TextNoResults: CSSObject = {
  color: 'white',
};
