import {
  Box,
  Button,
  Flex,
  GridItem,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as LinkDom } from 'react-router-dom';
import ThemeFooter from './templates/template';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { APP_VERSION } from '@/lib/Constants';
import { getThemeConfig, getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG, THEME_CONFIG } from '@/constants/themeConfig';
import { usePolicies } from '@/hooks/usePolicies';
import { useLiveChat } from '@/hooks/useLivechat';

import AndroidImage from '@/assets/core/images/androidButton.svg';
import iosImage from '@/assets/core/images/iosButton.svg';
import RacingNSW from '@/assets/core/icons/RacingNSW.svg';
import Logo2 from '@/assets/core/icons/Logo2.svg';
import RWWA from '@/assets/core/icons/RWWA.svg';
import TasRacing from '@/assets/core/icons/TasRacing.svg';
import ThoroughBred from '@/assets/core/icons/ThoroughbredPark.svg';
import QRacingQueensland from '@/assets/core/icons/QRacingQueensland.svg';
import visa from '@/assets/core/icons/visa.svg';
import mastercard from '@/assets/core/icons/mastercard.svg';

import { footerStyles } from './styles/Footer.styles';
import { useAuth } from '@/hooks/useAuth';
import { createTemplate } from '@/lib/templateSwitch';

export default function FooterContainer() {
  const footer = createTemplate(ThemeFooter);

  if (IS_MOBILE_APP) return null;

  return (
    <GridItem gridArea="footer">
      <Box {...footerStyles.footer} as="footer">
        {footer}
      </Box>
    </GridItem>
  );
}

// Views
export function AgeRequirementView() {
  return (
    <Box {...footerStyles.blurbHeading}>
      <Text {...footerStyles.blurbHeadingAge} mb="1">
        18+
      </Text>

      <Text {...footerStyles.blurbHeadingThink}>
        <FormattedMessage id="footer.subheading" />
      </Text>
    </Box>
  );
}

export function AppVersionView() {
  return (
    <Text {...footerStyles.appVersion}>
      <FormattedMessage
        id="footer.appversion"
        values={{
          appVersion: APP_VERSION,
        }}
      />
    </Text>
  );
}

export function CopyrightView() {
  return (
    <Flex {...footerStyles.copyrightContainer}>
      <Text {...footerStyles.copyright}>
        <FormattedMessage
          id="footer.copyright"
          values={{
            themeName: getThemeConfig().name,
            appVersion: APP_VERSION,
          }}
        />
      </Text>
    </Flex>
  );
}

export function CopyrightViewLegal() {
  return (
    <Flex {...footerStyles.copyrightContainer}>
      <Text {...footerStyles.copyright}>
        <FormattedMessage
          id="footer.copyright"
          values={{
            themeName: getThemeConfig().legalName,
            appVersion: APP_VERSION,
          }}
        />
      </Text>
    </Flex>
  );
}

export function DownloadLinksView() {
  const appleDownloadLink =
    window?.BETCLOUD_ENV?.REACT_APP_THEME_META_APPLEURL || '#';

  return (
    <Flex {...footerStyles.footerLinks}>
      <Flex {...footerStyles.footerMobile}>
        <Link href={appleDownloadLink} target="_blank">
          <Image src={iosImage} {...footerStyles.footerDownloadImage} />
        </Link>
      </Flex>
      <Flex {...footerStyles.footerMobile} ml="1">
        <Link
          href={`https://play.google.com/store/apps/details?id=com.${getThemeName().toLowerCase()}`}
          target="_blank"
        >
          <Image src={AndroidImage} {...footerStyles.footerDownloadImage} />
        </Link>
      </Flex>
    </Flex>
  );
}

export function DownloadLinksViewIOS() {
  const appleDownloadLink =
    window?.BETCLOUD_ENV?.REACT_APP_THEME_META_APPLEURL || '#';

  return (
    <Flex {...footerStyles.footerLinks}>
      <Flex {...footerStyles.footerMobile}>
        <Link href={appleDownloadLink} target="_blank">
          <Image src={iosImage} {...footerStyles.footerDownloadImage} />
        </Link>
      </Flex>
    </Flex>
  );
}

export function EmailUsView() {
  const { email } = getThemeConfig();
  const intl = useIntl();
  return (
    <Flex {...footerStyles.emailUs}>
      <Text>
        <FormattedMessage
          id="footer.enquiries"
          values={{
            link: (string) => (
              <Link
                {...footerStyles.footerLinkService}
                href={`mailto:${email}?subject=${intl.formatMessage(
                  { id: 'footer.support' },
                  {
                    themeName: getThemeName(),
                  }
                )}`}
              >
                {string}
              </Link>
            ),
            email,
          }}
        />
      </Text>
    </Flex>
  );
}

export function OurPartnersView() {
  const themeName = getThemeName();
  return (
    <Box {...footerStyles.weAcceptBox}>
      <Text {...footerStyles.ourPartners}>
        <FormattedMessage id="footer.ourpartners" />
      </Text>
      <Flex {...footerStyles.imageWrapper}>
        <Box {...footerStyles.partnerWrapper}>
          <Image {...footerStyles.partnerImages} src={RacingNSW} />
        </Box>
        <Box {...footerStyles.partnerWrapper}>
          <Image {...footerStyles.partnerImages} src={Logo2} />
        </Box>
        <Box {...footerStyles.partnerWrapper}>
          <Image {...footerStyles.partnerImages} src={RWWA} />
        </Box>
        {themeName !== EThemesOG.Questbet && (
          <Box {...footerStyles.partnerWrapper}>
            <Image {...footerStyles.partnerImages} src={TasRacing} />
          </Box>
        )}

        <Box {...footerStyles.partnerWrapper}>
          <Image {...footerStyles.partnerImages} src={ThoroughBred} />
        </Box>
        <Box {...footerStyles.partnerWrapper}>
          <Image {...footerStyles.partnerImages} src={QRacingQueensland} />
        </Box>
      </Flex>
    </Box>
  );
}

export function QuickLinksView() {
  const { showLiveChat } = useLiveChat();
  const { isAuthenticated } = useAuth();

  const {
    policyLinks: { termsAndConditions, responsibleGambling },
    openPDF,
  } = usePolicies();

  return (
    <Box {...footerStyles.quickLinksBoxProps}>
      <Text {...footerStyles.quickLinks}>
        <FormattedMessage id="footer.quicklinks" />
      </Text>
      <VStack gap={footerStyles.stackGap} {...footerStyles.nav}>
        <Button
          variant="unstyled"
          onClick={() => openPDF(responsibleGambling)}
          {...footerStyles.ButtonPdf}
        >
          <Text>
            <FormattedMessage id="generic.responsiblegambling" />
          </Text>
        </Button>
        <Button
          {...footerStyles.ButtonPdf}
          variant="unstyled"
          onClick={() => openPDF(termsAndConditions)}
        >
          <Text {...footerStyles.LinkText}>
            <FormattedMessage id="generic.termsconditions" />
          </Text>
        </Button>
        <Link target="_blank" as={LinkDom} to="/account/deposit">
          <Text {...footerStyles.LinkText}>
            <FormattedMessage id="sidenav.deposit" />
          </Text>
        </Link>
        <Link target="_blank" as={LinkDom} to="/account/overview">
          <Text {...footerStyles.LinkText}>
            <FormattedMessage id="account.overview.myAccount" />
          </Text>
        </Link>
        <Link target="_blank" as={LinkDom} to="/help-centre/privacy-policy">
          <Text {...footerStyles.LinkText}>
            <FormattedMessage id="generic.privacyPolicy" />
          </Text>
        </Link>

        <Button
          variant="unstyled"
          {...footerStyles.LinkText}
          as={Link}
          onClick={() => showLiveChat()}
        >
          <FormattedMessage id="contactus.title" />
        </Button>

        {isAuthenticated && (
          <Link target="_blank" as={LinkDom} to="/help-centre/promos">
            <Text {...footerStyles.LinkText}>
              <FormattedMessage id="generic.promoPolicyTandC" />
            </Text>
          </Link>
        )}
      </VStack>
    </Box>
  );
}

export function WeAcceptView() {
  return (
    <Box {...footerStyles.weAcceptBox}>
      <Text {...footerStyles.weAccept}>
        <FormattedMessage id="footer.weaccept" />
      </Text>
      <Flex {...footerStyles.imageWrapper}>
        <Box {...footerStyles.partnerWrapper}>
          <Image {...footerStyles.partnerImages} src={mastercard} />
        </Box>
        <Box {...footerStyles.partnerWrapper}>
          <Image {...footerStyles.partnerImages} src={visa} />
        </Box>
      </Flex>
    </Box>
  );
}

export function BlurbContentView() {
  return (
    <Box {...footerStyles.BoxBlurbContentContainer}>
      <Box {...footerStyles.BoxPartnersAcceptContainer}>
        <OurPartnersView />
        <WeAcceptView />
      </Box>
      <QuickLinksView />
    </Box>
  );
}

export function BlurbTextView() {
  const brand = (getThemeConfig().name ?? '').toLocaleLowerCase() as EThemesOG;
  const phonenumber =
    THEME_CONFIG[brand]?.closeAccNumber || '[enter phone number]';

  const {
    policyLinks: { responsibleGambling },
    openPDF,
  } = usePolicies();

  return (
    <>
      <Text mt="3">
        <FormattedMessage
          id="footer.footerparagraph"
          values={{
            themeName: getThemeConfig().name,
            rglink: (string: string) => (
              <Button
                variant="unstyled"
                onClick={() => openPDF(responsibleGambling)}
                {...footerStyles.customFooterLink}
              >
                {string}
              </Button>
            ),
            telLink: (string: string) => (
              <Link href={`tel:${string}`} {...footerStyles.footerLinkTel}>
                {string}
              </Link>
            ),
            link: (string: string) => (
              <Link
                href={`http://${string}`}
                target="_blank"
                {...footerStyles.footerLink}
              >
                {string}
              </Link>
            ),
          }}
        />
      </Text>

      <Text mt="5">
        <FormattedMessage
          id="footer.footerbottomtext"
          values={{
            link: (string: string) => (
              <Link
                href="https://www.sa.gov.au/topics/business-and-trade/gambling/codes-of-practice"
                target="_blank"
                {...footerStyles.footerLink}
              >
                {string}
              </Link>
            ),
          }}
        />
      </Text>

      <Text mt="5">
        <FormattedMessage
          id="footer.footerbottomtextSaferTools"
          values={{
            link: (string: string) => (
              <Link
                as={LinkDom}
                to="/account/settings/responsible-gambling"
                {...footerStyles.footerLink}
              >
                {string}
              </Link>
            ),
            email: (string: string) => (
              <Link
                href={`mailto:help@${getThemeName()}.com.au`}
                target="_blank"
                {...footerStyles.footerLink}
              >
                {string}
              </Link>
            ),
            bookie: brand,
            phonenumber,
          }}
        />
      </Text>

      <Text mt="5">
        <FormattedMessage
          id="footer.betStop"
          values={{
            cta: (string: string) => (
              <Link
                href="https://betstop.gov.au/"
                target="_blank"
                {...footerStyles.footerLink}
              >
                {string}
              </Link>
            ),
          }}
        />
      </Text>
    </>
  );
}
