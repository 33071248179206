import { Box, chakra, Flex, Icon, keyframes, Text } from '@chakra-ui/react';
import { styleImports } from './Placed.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg from '../../../../../common/IconSvg';

const themeName = getThemeName();

const loopPulse = keyframes`
  0% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 0.8));
  }
  50% {
    transform: scale(1.2);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 1));
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 4px rgba(62, 220, 228, 0.8));
  }
`;

export const FlexBetsPlacedContainer = chakra(Flex, {
  label: 'placed-FlexBetsPlacedContainer',
  baseStyle: () => ({
    flexDir: 'row',
    w: 'full',
    h: '10',
    p: '2',
    my: '2',
    alignItems: 'center',
    bg: 'gamma.800',
    textColor: 'white',
    ...(() => {
      try {
        return styleImports[themeName]?.FlexBetsPlacedContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconBetsPlaced = chakra(Icon, {
  baseStyle: ({ theme }) => ({
    color: 'alpha.400',
    boxSize: '6',
    ...(theme.themeName === EThemes.Betgalaxy && {
      color: 'alpha.500',
      textShadow: '0px 0px 4px rgba(62, 220, 228, 0.8)',
      boxSize: '8',
      animation: `${loopPulse} 1s ease-in-out infinite`,
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.IconBetsPlaced;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const IconThumbsUp = chakra(IconSvg, {
  baseStyle: () => ({
    display: 'none',
    ...(() => {
      try {
        return styleImports[themeName]?.IconThumbsUp;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetsPlacedVideo = chakra(Box, {
  label: 'placed-BetsPlacedVideo',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BetsPlacedVideo;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextBetsPlaced = chakra(Text, {
  label: 'TextBetsPlaced',
  baseStyle: ({ theme }) => ({
    mt: '2',
    fontWeight: 'semibold',
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'bold',
      fontSize: 'sm',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.TextBetsPlaced;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
