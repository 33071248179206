import { BoxProps } from '@chakra-ui/react';
import { styleImports } from './RaceNumber.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type RaceNumberSchema = Partial<{
  wrapper: BoxProps;
}>;

export const raceNumberStyles: RaceNumberSchema = {
  wrapper: {
    'data-testid': 'home-raceNumber-wrapper',
    ...(() => {
      /**
       * This will not be a long term solution, but the structure
       * aligns with the schema way of working we're moving towards.
       */
      try {
        return styleImports[themeName]?.raceNumberStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
