import { CSSObject } from '@chakra-ui/react';
import leaves from '@/assets/junglebet/images/homeFeaturedRacing/featureLeaves.png';
import vine from '@/assets/junglebet/images/switch/vineRight.svg';

export const ModalDrawerContent: CSSObject = {
  background: 'transparent',
};

export const GridItemBetslip: CSSObject = {
  h: '98%',
  pos: 'relative',
  '& > div[class*="-BetSlipModalContainer"]': {
    top: [null, null, null, null, 0],
    maxH: [null, null, null, null, `100%`],
    h: '97%',
    boxShadow:
      '0px 0px 0px 4px #EFFECB inset, 4px 5px 8px 3px rgba(0, 0, 0, 0.15) inset',
  },
  _before: {
    bgImg: `url(${leaves})`,
    h: '300px',
    w: '120%',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    left: '-30px',
    right: '0',
    top: '-5px',
    zIndex: '-2',
    display: ['none', null, null, 'block'],
  },
  _after: {
    bgImg: `url(${vine})`,
    h: '80px',
    w: '35%',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    right: '-38px',
    top: '-18px',
    zIndex: '1',
    display: ['none', null, null, 'block'],
  },
};
