import React, { FC, ReactNode } from 'react';

import GlobalStyles from '../../layouts/PrimaryLayout/conditionals/GlobalStyles/template';
import SeoTitle from '../../components/Seo/SeoTitle';
import { createTemplate } from '@/helpers/createTemplate';
import template from './templates/template';

const AccountWrapper: FC<MainLayoutProps> = ({
  children,
  pageHeader,
  pageHeaderChildren,
  pageTitle,
}) => (
  <>
    <GlobalStyles />
    <SeoTitle title={pageTitle ?? ''} />

    {createTemplate(template(pageHeader, pageHeaderChildren, children))}
  </>
);

export interface MainLayoutProps {
  fullWidthMobile?: boolean;
  fullWidth?: boolean;
  featureImage?: boolean;
  pageTitle: string; // Sets the document title of the page
  pageHeader?: ReactNode; // Sets the header of the page
  pageHeaderChildren?: ReactNode;
}

export default AccountWrapper;
