/* eslint-disable @typescript-eslint/no-unused-expressions, func-names, no-param-reassign, no-multi-assign, no-cond-assign, @typescript-eslint/no-shadow, no-sequences */
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from './useAuth';
import injectScript from '@/lib/injectScript';
import { keys } from '@/api/api.keys';
import { queryZohoToken } from '@/api/punter/punter';
import { sleep } from '@/lib/Time';

const { ZOHO_LIVECHAT_URL } = window.BETCLOUD_ENV;

function clearZoho() {
  if (window.ZohoHCAsap) {
    window.ZohoHCAsap.Logout();
  }

  delete window.ZOHOGC_CHAT;
  delete window.zohohcasapwebwidgetClosePopups;
  delete window.ZohoHCAsap;
  delete window.ZohoHCAsapSettings;
  delete window.ZohoHCAsap__asyncalls;
  delete window.ZohoHCAsapReadyStatus;
}

export const useLiveChat = () => {
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  const showLiveChat = () => {
    // eslint-disable-next-line no-void
    void (async () => {
      clearZoho();
      if (isAuthenticated) {
        const data = await queryClient.fetchQuery(
          [keys.punterGetZohoToken, isAuthenticated],
          queryZohoToken,
          {
            staleTime: 5 * 60 * 1000,
          }
        );

        window.ZohoHCAsapSettings = {
          userInfo: { jwtToken: data?.token },
          hideLauncherIcon: true,
        };
      }
      await injectScript(ZOHO_LIVECHAT_URL);

      for (let i = 0; i < 5; i += 1) {
        if (window.ZohoHCAsap?.Action) break;

        // eslint-disable-next-line no-await-in-loop
        await sleep(200);
      }

      if (window.ZohoHCAsap?.Action) window.ZohoHCAsap.Action('open');
      else throw new Error('Error opening live chat');
    })();
  };

  return { showLiveChat };
};
