import { CSSObject } from '@chakra-ui/react';
import locationBackground from '../../../assets/wellbet/images/backgrounds/image-water.png';
import sign from '../../../assets/wellbet/images/maintenance/image-sign.png';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';

export const LocationNotAllowedContainer: CSSObject = {
  bg: 'gray.200',
  bgImage: `url(${sign}), url(${locationBackground})`,
  bgSize: ['contain, cover', 'auto, cover', 'auto, 100% 100%'],
  bgPos: 'bottom center, center',
  bgRepeat: 'no-repeat',
  ...(IS_MOBILE_APP && {
    bg: '#349BB0',
  }),
};

export const CenterWrapper: CSSObject = {
  ...(IS_MOBILE_APP && {
    bgImage: `url(${sign}), url(${locationBackground})`,
    backgroundSize: 'contain, cover',
    bgPos: 'bottom center, top',
    bgRepeat: 'no-repeat',
    flex: '1',
    maxW: 'full',
    h: '100vh',
    justifyContent: 'start',
  }),
};

export const ContentHeadingText: CSSObject = {
  fontSize: ['2xl', null, '5xl'],
  color: 'white',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))',
  fontWeight: 'black',
};

export const ContentBodyText: CSSObject = {
  m: 'auto',
  fontSize: ['md', null, '2xl'],
  fontWeight: 'normal',
  color: 'white',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))',
};

export const SubHeader: CSSObject = {
  m: 'auto',
  fontSize: ['md', null, '2xl'],
  fontWeight: 'normal',
  color: 'white',
  filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))',
};

export const ContactText: CSSObject = {
  fontSize: ['md', null, '2xl'],
};

export const FlexVerbiageContainer: CSSObject = {
  maxW: ['380px', null, 'container.md'],
  alignItems: 'center',
  textAlign: 'center',
};
