import * as sterlingparker from './NewMatchCard.styles.sterlingparker';
import * as betprofessor from './NewMatchCard.styles.betprofessor';
import * as volcanobet from './NewMatchCard.styles.volcanobet';
import * as puntgenie from './NewMatchCard.styles.puntgenie';
import * as junglebet from './NewMatchCard.styles.junglebet';
import * as fiestabet from './NewMatchCard.styles.fiestabet';
import * as puntcity from './NewMatchCard.styles.puntcity';
import * as betgalaxy from './NewMatchCard.styles.betgalaxy';
import * as betroyale from './NewMatchCard.styles.betroyale';
import * as juicybet from './NewMatchCard.styles.juicybet';
import * as goldenrush from './NewMatchCard.styles.goldenrush';
import * as vikingbet from './NewMatchCard.styles.vikingbet';
import * as questbet from './NewMatchCard.styles.questbet';
import * as wellbet from './NewMatchCard.styles.wellbet';
import * as sugarcastle from './NewMatchCard.styles.sugarcastle';
import * as oldgill from './NewMatchCard.styles.oldgill';
import * as buffalobet from './NewMatchCard.styles.buffalobet';
import * as titanbet from './NewMatchCard.styles.titanbet';
import * as gigabet from './NewMatchCard.styles.gigabet';
import * as slambet from './NewMatchCard.styles.slambet';
import * as templebet from './NewMatchCard.styles.templebet';
import * as goldenbet888 from './NewMatchCard.styles.goldenbet888';
import * as bet777 from './NewMatchCard.styles.bet777';
import * as chromabet from './NewMatchCard.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  sterlingparker,
  betprofessor,
  volcanobet,
  puntgenie,
  junglebet,
  fiestabet,
  puntcity,
  betgalaxy,
  betroyale,
  juicybet,
  goldenrush,
  vikingbet,
  questbet,
  wellbet,
  sugarcastle,
  oldgill,
  buffalobet,
  titanbet,
  gigabet,
  slambet,
  templebet,
  goldenbet888,
  bet777,
  chromabet,
};
