import styled from 'styled-components';
import {
  Box,
  chakra,
  ChakraComponent,
  CloseButton as ChakraCloseButton,
  CloseButtonProps,
  Flex,
} from '@chakra-ui/react';
import { styleImports } from './Modal.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

/**
 * Z-index is hard-coded because we don't have access to Chakra's theme object,
 * due to this being a legacy (Styled) component.
 */
export const ModalPageContainer = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1400;
  position: fixed;
  display: flex;
  // align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ theme }) => theme.components.modal.modalPageContainer}
`;

export const ModalBoxWrapper = chakra<any>('div', {
  shouldForwardProp: (prop) => !['xSmall', 'small', 'medium'].includes(prop),
  baseStyle: ({ medium, small, xSmall }: any) => ({
    display: 'flex',
    position: 'relative',
    flexDir: 'column',
    marginTop: '24',
    bg: 'white',
    width: 'md',
    height: '70vh',
    borderRadius: 'md',
    h: 'fit-content',

    ...(medium && {
      width: '80',
    }),

    ...(small && {
      width: '60',
    }),

    ...(xSmall && {
      width: '60',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.ModalBoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ContentContainer = chakra(Box, {
  baseStyle: () => ({
    overflow: 'auto',

    ...(() => {
      try {
        return styleImports[themeName]?.ContentContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const HeaderText = chakra('h5', {
  baseStyle: () => ({
    fontSize: 'md',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    px: '2',
    fontStyle: 'normal',

    ...(() => {
      try {
        return styleImports[themeName]?.HeaderText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const HeaderContainer = chakra(Flex, {
  baseStyle: () => ({
    flexDirection: 'row',
    alignItems: 'center',
    px: '2',
    pb: '2.5',
    pt: '5',
    position: 'relative',
    justifyContent: 'left',

    ...(() => {
      try {
        return styleImports[themeName]?.HeaderContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CloseButton: ChakraComponent<'button', CloseButtonProps> = chakra(
  ChakraCloseButton,
  {
    baseStyle: {
      pos: 'absolute',
      right: '0',
      top: '2.5',

      ...(() => {
        try {
          return styleImports[themeName]?.CloseButton;
        } catch (err) {
          return {};
        }
      })(),
    },
  }
);
