import { CSSObject } from '@chakra-ui/react';

export const NoRacesWrapper: CSSObject = {
  bg: 'alpha.50',
};

export const TextPrimary: CSSObject = {
  color: 'gray.700',
};

export const TextSecondary: CSSObject = {
  color: 'gray.500',
};
