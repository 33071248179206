import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import { FeaturedMatch } from '../components/FeaturedMatch';
import {
  BoxHeadingWrapper,
  FlexWrapper,
  HeadingSlogan,
  SpanSlogan,
} from '../styles/Featured.styles';

const template: TTemplate = {
  wrapper: <FlexWrapper />,
  slogan: {
    wrapper: <BoxHeadingWrapper />,
    body: (
      <Flex direction="column">
        <HeadingSlogan>
          <FormattedMessage
            id="promo.sport.startparty"
            values={{
              bu: (msg) => <SpanSlogan>{msg}</SpanSlogan>,
            }}
          />
        </HeadingSlogan>
        <FeaturedMatch />
      </Flex>
    ),
  },
};

export default template;
