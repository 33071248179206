import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputLeftAddon: {
    borderLeftRadius: 'md',
    bgGradient: 'linear(to-b, alpha.100, alpha.500)',
    color: 'beta.900',
    fontSize: 'sm',
    fontWeight: 'bold',
    h: '10',
    w: 'auto',
    border: 'none',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.3)',
    sx: {
      '&&': {
        _groupFocusWithin: {
          bg: 'alpha.50',
          color: 'alpha.700',
        },
      },
    },
  },
  betCardInput: {
    bg: 'beta.700',
    border: '2px solid',
    borderColor: 'alpha.300',
    borderLeft: 'none',
    color: 'white',
    borderRadius: 'md',
    fontWeight: 'bold',
    fontSize: 'sm',
    h: '10',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.3)',
    sx: {
      '&&': {
        _hover: {
          bg: 'beta.600',
          borderColor: 'alpha.300',
        },
        _focus: {
          borderColor: 'alpha.300',
          bg: 'beta.500',
          boxShadow:
            '0px 4px 7px 0px rgba(0, 0, 0, 0.25) inset, 0px -4px 7px 0px rgba(0, 0, 0, 0.25) inset',
        },
        '&::placeholder': { color: 'gray.400' },
      },
    },
  },
};
