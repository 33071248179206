import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  borderBottom: '0',
};

export const TabWrapper: CSSObject = {
  gap: '1',
  p: '2',
  bg: 'gray.200',
  borderRadius: 'md',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'white' : 'gray.600',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'md',

  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'alpha.600' : 'transparent',
  boxShadow: isActive
    ? '0px 1px 0px rgba(255, 255, 255, 0.45), inset 0px 0px 5px rgba(0, 0, 0, 0.45)'
    : 'none',

  _hover: {
    bg: isActive ? 'alpha.500' : 'alpha.700',
    color: 'white',
  },
});
