import { CSSObject } from '@chakra-ui/react';
import onboardingWelcome from '@/assets/fiestabet/images/onboarding/asideOnb.png';
import onboardingMobile from '@/assets/fiestabet/images/onboarding/asideMobile.png';

export const TextTitle: CSSObject = {
  display: 'none',
};

export const TextSubtitle: CSSObject = {
  fontSize: 'xs',
  lineHeight: '4',
  flex: '1',
  mt: ['unset', '4rem', '4rem'],
  color: 'epsilon.200',
};

export const TextSubtitleBoldWord: CSSObject = {
  color: 'delta.300',
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  mb: ['8', null, '8'],
  mt: '56',
  color: 'white',
  'a span': {
    color: 'beta.500',
  },
  pb: '1',
  pt: '3',
};

export const TextCTALink: CSSObject = {
  '&&': {
    color: 'epsilon.500',
    fontWeight: 'bold',
  },
};

export const FlexAsideInfo: CSSObject = {
  gridArea: '1/1',
  flexDir: 'column',
  alignItems: ['center', null, 'center'],
  zIndex: 'overlay',
  m: ['unset', null, '10'],
  mt: ['2', null, '10'],
  color: 'black',
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  minH: ['336px', '682px'],
};

export const FlexCTAContainer: CSSObject = {
  justifyContent: 'center',
  alignSelf: ['center', null, 'unset'],
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    backgroundImage: `url(${onboardingMobile}), linear-gradient(180deg, var(--bc-colors-alpha-700) 0%, var(--bc-colors-alpha-700) 100%)`,
    backgroundPosition: 'top, bottom',
    backgroundSize: '100% 80%',
    backgroundRepeat: 'no-repeat',

    '@media (min-width: 769px)': {
      background: `url('${onboardingWelcome}'), linear-gradient(180deg, var(--bc-colors-alpha-700) 0%, var(--bc-colors-alpha-700) 100%)`,
      backgroundPosition: 'top, bottom',
      backgroundRepeat: 'no-repeat',
    },

    '@media (min-width: 426px)': {
      backgroundSize: 'contain',
    },
  },
};
