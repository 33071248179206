import { FormattedMessage } from 'react-intl';
import { Box, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { getThemeName } from '@/helpers/getThemeName';

export default function Policy() {
  const themeName = getThemeName();

  return (
    <Box fontSize="lg" lineHeight="tall" flex="1">
      <Text mb="8">
        {themeName} is aware that gambling is a form of entertainment to a large
        majority of people, however {themeName} is conscious of those who can
        become vulnerable to problem gambling. {themeName} is therefore
        committed to provide a responsible gambling service, where clients have
        access to online tools so they can exclude themselves or limit their
        betting activities. Our betting operations are governed by this policy
        and a code of practice.
      </Text>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          What is Responsible Gambling?
        </Text>

        <Box mb="2">
          <Text mb="1">Responsible gambling for individuals means:</Text>

          <UnorderedList>
            <ListItem>
              They may gamble for pleasure and entertainment but are aware of
              their likelihood of losing and understand the associated risks,
            </ListItem>
            <ListItem>
              They exercise control over their gambling activity, and
            </ListItem>
            <ListItem>
              Responsible gambling occurs in balance with other activities in
              their lives and is not causing problems or harm for themselves or
              others.
            </ListItem>
          </UnorderedList>
        </Box>

        <Box mb="2">
          <Text mb="1">
            Responsible gambling for the broader community, including gambling
            providers, governments, and sporting associations, requires:
          </Text>

          <UnorderedList>
            <ListItem>
              Shared responsibility for generating awareness of the risks
              associated with gambling,
            </ListItem>
            <ListItem>
              Creating and promoting environments that prevent or minimise
              problem gambling; and
            </ListItem>
            <ListItem>
              Being responsive to community concerns around gambling.
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box mb="8">
        <Box mb="6">
          <Text fontWeight="semibold" mb="2">
            What is Problem Gambling?
          </Text>
          <Text>
            Problem gambling occurs when a person spends more than they can
            afford, betting for a lot longer. After gambling the person feels
            anxious, guilty or just uncomfortable with the situation.
          </Text>
        </Box>

        <Box mb="6">
          <Text mb="1">
            The negative consequences of problem gambling may include:
          </Text>

          <UnorderedList>
            <ListItem>
              The punter suffering excessive financial losses relative to the
              gamblers means (including unpaid household bills, credit card
              debts or loans);
            </ListItem>
            <ListItem>
              Adverse personal effects on the punter (such as anxiety,
              depression or stress) and/or their family;
            </ListItem>

            <ListItem>
              Adverse effects on employment and work performance (including
              missing work or having trouble concentrating at work);
            </ListItem>
            <ListItem>
              Losing the ability to maintain a healthy and balanced lifestyle
              due to spending an excessive amount of time gambling;
            </ListItem>
            <ListItem>
              Regretting the amount of time lost or wasted due to gambling; and
              Other costs which are borne by the community.
            </ListItem>
          </UnorderedList>
        </Box>

        <Box mb="6">
          <Text mb="1">
            To assist with gambling responsibly {themeName} can help you by:
          </Text>

          <UnorderedList>
            <ListItem>
              Implementing Pre-commitment Limits – we can set a weekly deposit
              limit for you – please e-mail us and we can set this up for you
            </ListItem>
            <ListItem>
              Temporarily suspending your account if you feel it is getting the
              better of you at a certain moment in time
            </ListItem>
          </UnorderedList>
        </Box>

        <Box>
          <Text mb="1">
            Below as some tips for ensuring your gambling does not start
            affecting you negatively:
          </Text>

          <UnorderedList>
            <ListItem>
              Separate work and gambling – don’t gamble at work
            </ListItem>
            <ListItem>
              Take a note of the length of your gambling sessions
            </ListItem>
            <ListItem>
              Do not look at gambling as an alternative source of income
            </ListItem>
            <ListItem>
              If you’re behind, don’t chase, it will only get worse
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Client Care Principles and Responsible Gambling
        </Text>

        <Text mb="6">
          We aim to consistently promote responsible gambling practices.
          Gambling should be an enjoyable form of entertainment which does not
          create problems. The aim is to achieve a balanced approach in the
          provision of wagering services, taking account of the interests of our
          clients, the community at large and us as the wagering operator.
        </Text>

        <Box mb="6">
          <Text mb="1">
            Responsible gambling involves informing clients on their rights and
            responsibilities as gamblers and ensuring that our company delivers
            gambling products and services in ways that benefit clients. We are
            committed to sharing responsibility, with communities and Government
            bodies, for helping problem gamblers to identify their problem and
            seek assistance. Some of the ways that we do this are:
          </Text>

          <UnorderedList>
            <ListItem>
              Allowing our clients to set a pre-commitment limit;
            </ListItem>
            <ListItem>
              Allowing our clients to self-exclude (either temporarily or
              permanently) from betting with us. Providing Responsible Gambling
              information and messages to our clients;
            </ListItem>
            <ListItem>
              Engage our staff about Responsible Gambling training;
            </ListItem>
            <ListItem>
              and Referring our clients who are at risk of problem gambling to
              gambling-related support services.
            </ListItem>
          </UnorderedList>
        </Box>

        <Box>
          <Text mb="1">We aim to achieve the following outcomes:</Text>

          <UnorderedList>
            <ListItem>
              Minimising the extent of gambling related harm to individuals and
              the broader community;
            </ListItem>
            <ListItem>
              Enabling clients to make informed decisions about their gambling
              practices;
            </ListItem>
            <ListItem>
              Enabling people adversely affected by gambling to have access to
              timely and appropriate assistance and information;
            </ListItem>
            <ListItem>
              Promoting a shared understanding between individuals, communities,
              the gambling industry and Government of responsible gambling
              practices;
            </ListItem>
            <ListItem>
              and Ensuring the gambling industry provides safe and supportive
              environments for the delivery of gambling products and services.
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Gambling Self-Assessment
        </Text>

        <Box mb="6">
          <Text mb="1">
            The following questionnaire may help you to identify tendencies in
            your behaviour which are often linked to problem gambling:
          </Text>

          <UnorderedList>
            <ListItem>
              Have you ever ignored your job in order to gamble?
            </ListItem>
            <ListItem>
              Have you felt unhappy with your personal life due to gambling?
            </ListItem>
            <ListItem>
              Does your gambling affect your perception of others, or how others
              may perceive you?
            </ListItem>
            <ListItem>
              Has your gambling left you with a deep sense of regret?
            </ListItem>
            <ListItem>
              Have you ever tried to meet your financial obligations by
              gambling?
            </ListItem>
            <ListItem>
              Has gambling affected your motivation to succeed?
            </ListItem>
            <ListItem>
              Do you feel obliged to gamble in order to recoup lost money?
            </ListItem>
            <ListItem>
              Do you consider a win as motivation to spend more?
            </ListItem>
            <ListItem>
              Have you ever spent your last available dollar on gambling?
            </ListItem>
            <ListItem>
              Have you ever borrowed money in order to gamble?
            </ListItem>
            <ListItem>Have you ever sold assets in order to gamble?</ListItem>
            <ListItem>
              Have you felt reluctant to use money set aside for gambling on
              everyday purchases?
            </ListItem>
            <ListItem>
              Has gambling negatively impacted on the way that you treat
              yourself or others?
            </ListItem>
            <ListItem>
              Do you find yourself gambling beyond the time you had planned to
              stop?
            </ListItem>
            <ListItem>
              Have you ever gambled to avoid dealing with personal problems?
            </ListItem>
            <ListItem>
              Have you ever committed (or considered committing) a criminal
              offence in order to gamble?
            </ListItem>
            <ListItem>Does your gambling affect your sleep?</ListItem>
            <ListItem>
              Do the frustrations of your personal life encourage you to gamble?
            </ListItem>
            <ListItem>
              Have you celebrated good fortune by spending excessive time
              gambling?
            </ListItem>
            <ListItem>
              Have you considered harming yourself as a result of gambling?
            </ListItem>
          </UnorderedList>
        </Box>

        <Text>
          Studies show that people who have answered Yes to seven or more of the
          above questions show tendencies representative of problem gambling.
          For further assistance, or if you wish to know more, please contact
          one of the organisations listed below.
        </Text>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Minors
        </Text>

        <Text mb="1">
          As a responsible operator, we are committed to actively taking steps
          to exclude minors from placing a wager using our betting platforms.
          Our clients are identified through Veda or through the Customer
          Identification form. Our services are to be used by persons who are at
          least 18 years old.
        </Text>

        <Box>
          <Text mb="1">Filtering Options</Text>

          <Text mb="1">
            Clients can actively prevent underage persons from entering the
            online sports betting software (minimum legal age to gamble is 18
            years), by downloading one of the following filter options:
          </Text>

          <UnorderedList>
            <ListItem>
              <Link target="_blank" href="http://www.cyberpatrol.com">
                http://www.cyberpatrol.com
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="http://www.gamblock.com">
                http://www.gamblock.com
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="http://www.safekids.com">
                http://www.safekids.com
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="http://www.surfcontrol.com">
                http://www.surfcontrol.com
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="http://www.solidoak.com">
                http://www.solidoak.com
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="http://www.netnanny.com">
                http://www.netnanny.com
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="http://www.betfilter.com">
                http://www.betfilter.com
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="http://www.rgmanager.com">
                http://www.rgmanager.com
              </Link>
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Where to Get Help
        </Text>

        <Text mb="1">
          You can call the Gambling Helpline on 1800 858 858 or the Financial
          Counselling Helpline on 1800 007 007 or visit:
        </Text>

        <UnorderedList mb="1">
          <ListItem>
            <Link href="https://www.gamblinghelponline.org.au" target="_blank">
              https://www.gamblinghelponline.org.au
            </Link>
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="helpcentre.policy.getHelp"
              values={{
                link: (string: string) => (
                  <Link href={string} target="_blank">
                    {string}
                  </Link>
                ),
              }}
            />
          </ListItem>
        </UnorderedList>

        <Text>
          Or alternatively you can contact {themeName}’s Responsible Gambling
          Support Service through{' '}
          <Link href={`mailto:support@${themeName}.com.au`} target="_blank">
            support@{themeName}.com.au
          </Link>{' '}
          for other gambling support providers in your area.
        </Text>
      </Box>

      <Box mb="8">
        <Link href="/account/settings/responsible-gambling" fontWeight="bold">
          Self-Exclusion
        </Link>

        <Text mb="6">
          If you feel that you are losing control of your play and would like
          help in restricting your access to our betting platforms, we offer you
          the option to self-exclude from betting with us. Self-exclusion can be
          temporary or permanent. If you would like to self exclude, this option
          is available in the My Account section of the website. Alternatively,
          please contact our Responsible Gambling Support Service by email at{' '}
          <Link href={`mailto:support@${themeName}.com.au`} target="_blank">
            support@{themeName}.com.au
          </Link>
        </Text>

        <Text mb="6" fontWeight="bold">
          Please note: A request for permanent account closure will be treated
          the same as a request for self exclusion.
        </Text>

        <Text mb="6">
          Some jurisdictions such as South Australia have Barring Orders
          available. Please contact our Responsible Gambling Support Service if
          you would like further information about this.
        </Text>

        <Text mb="6">
          You may also wish to self-exclude from any other providers that you
          have accounts with.
        </Text>

        <Text>
          Please contact{' '}
          <Link href={`mailto:support@${themeName}.com.au`} target="_blank">
            support@{themeName}.com.au
          </Link>{' '}
          if you would like {themeName} to assist in excluding you from other
          operators.
        </Text>
      </Box>

      <Box mb="8">
        <Box mb="6">
          <Text fontWeight="semibold" mb="2">
            Pre-Commitment
          </Text>

          <Text>
            As part of our responsibility towards responsible gambling, we have
            a pre-commitment limit mechanism which allows you to set your daily
            maximum deposit amount.
          </Text>
        </Box>

        <Box mb="6">
          <Link href="/deposit-limit" target="_blank" fontWeight="semibold">
            Set Your Pre-Commitment Limit
          </Link>

          <Text mt="2">
            To set your pre-commitment limit, simply complete the following
            steps: Log into your {themeName} account Click on the My Account
            option which appears in the top right hand side of the webpage The
            account setting page will appear, click on the Responsible Gambling
            option. Click on Deposit Limit and then enter in the amount that you
            would like to set for your deposit limit.
          </Text>

          <Text>
            The bet limit will apply immediately and there will be a waiting
            period of 7 days if you wish to raise the deposit limit. You may
            also wish to set a pre-commitment limit on your other betting
            accounts.
          </Text>
        </Box>

        <Box>
          <Text fontWeight="semibold" mb="2">
            Change or Remove Your Pre-Commitment Limit
          </Text>

          <Text>
            To change or remove your pre-commitment limit, simply complete the
            following steps: Log into your {themeName} account Click on the My
            Account option which appears in the top right hand side of the
            webpage The account setting page will appear, click on the
            Responsible Gambling option. To remove limit, click the Do Not Wish
            to Set a Deposit Limit box To change your deposit limit enter in the
            amount that you would like to set for your deposit limit. Please
            note if it is more than the amount already selected it will take 7
            days to be updated. Please note that the following minimum waiting
            periods apply: The bet limit will apply immediately and raising the
            deposit limit will take 7 days.
          </Text>
        </Box>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Activity Statements
        </Text>
        <Text mb="8">
          Activity statements are available to request any time by contacting{' '}
          <Link href={`mailto:support@${themeName}.com.au`} target="_blank">
            support@{themeName}.com.au
          </Link>
        </Text>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Codes of Practice
        </Text>
        <Text mb="4">
          Certain States and regulatory authorities have enacted Codes of
          Practice and other protocols governing the conduct of wagering in
          their jurisdiction. Codes of Practice are voluntary codes of
          self-regulation and are to be read in conjunction with the relevant
          legislation, regulation and rules of the racing codes and sports
          betting authorities in each State and Territory.
        </Text>

        <Text mb="4">
          {themeName} is a member of the Victorian Bookmakers' Association and
          adheres to the Victorian Bookmakers' Association Responsible Gambling
          Code of Conduct. A copy of the Code of Conduct can be downloaded from
          the{' '}
          <Link
            href="https://vicbookmakers.com.au/responsible-gambling/"
            target="_blank"
          >
            VBA website
          </Link>
          .
        </Text>

        <FormattedMessage
          id="helpcentre.policy.association"
          values={{
            themeName,
            website: (string: string) => (
              <Link href={string} target="_blank">
                {string}
              </Link>
            ),
          }}
        />
        <Text mb="2">
          The following Codes of Practice published by the South Australian
          Independent Gambling Authority have been adopted by {themeName} in
          relation to its South Australian members.
        </Text>
        <UnorderedList>
          <ListItem>
            <Link
              href="https://www.cbs.sa.gov.au/resources/authorised-betting-operations-gambling-code-practice#"
              target="_blank"
            >
              Authorised betting operations gambling code of practice
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://www.cbs.sa.gov.au/resources/inducements-and-complimentary-gambling-products-gambling-administration-guidelines"
              target="_blank"
            >
              Inducements and complimentary gambling products - Gambling
              Administration Guidelines
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://www.cbs.sa.gov.au/resources/activity-statements-authorised-betting-operators-gambling-administration-guidelines"
              target="_blank"
            >
              Activity statements and gambling account detailed transaction
              history - Gambling Administration Guidelines
            </Link>
          </ListItem>
        </UnorderedList>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Regulatory Compliance
        </Text>

        <Text>
          All Bookmakers will abide by the rules and regulations of the
          Government and the controlling bodies in the jurisdiction in which
          they propose to do business. All Bookmakers must be issued with a bona
          fide licence from the controlling body in their jurisdiction and
          operate under the authority and within the scope of that licence.
        </Text>
      </Box>

      <Box mb="8">
        <Text fontWeight="semibold" mb="2">
          Probity
        </Text>

        <Text>
          The respective Bookmaker Associations in each jurisdiction shall
          ensure the financial and character bona fides for all Bookmakers for
          whom they provide fidelity guarantees or, where applicable, ensure
          that obligations to Government Authorities are met.
        </Text>
      </Box>

      <Box>
        <Text fontWeight="semibold" mb="2">
          Gambling Help Online
        </Text>

        <Text mb="1">1800 858 858</Text>
        <Text mb="1">
          <Link href="https://www.gamblinghelponline.org.au" target="_blank">
            https://www.gamblinghelponline.org.au
          </Link>
        </Text>
        <Text>
          Confidential, professional, free help, 24 hours a day, 7 days a week.
          Talk to someone who understands your issues.
        </Text>
      </Box>
    </Box>
  );
}
