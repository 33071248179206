import { CSSObject } from '@chakra-ui/react';
import background from '../../../assets/goldenrush/images/backgrounds/maintenanceBackground.png';
import backgroundAtRetina from '../../../assets/goldenrush/images/backgrounds/maintenanceBackgroundMB.png';
import backgroundError from '../../../assets/goldenrush/images/backgrounds/errorbackground.png';
import backgroundAtRetinaError from '../../../assets/goldenrush/images/backgrounds/errorMB.png';

export const FlexPageWrapper = (isMaintenance: boolean): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: `url(${isMaintenance ? backgroundError : background})`,
  bgRepeat: 'no-repeat',
  bgSize: ['100% 100%', '100% 100%'],

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${
      isMaintenance ? backgroundAtRetinaError : backgroundAtRetina
    })`,
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  mt: ['0', null, '12'],
  mx: 'auto',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.lg',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  img: {
    mb: ['6', null, '5'],
  },
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Angkor',
  fontSize: ['4xl', null, '6xl'],
  lineHeight: ['2.3rem', null, '3.6rem'],
  mt: '4',
  mb: ['2.5', null, '4'],
  textTransform: 'uppercase',
  fontWeight: '400',
  color: 'gamma.500',
};

export const ContentBodyText: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  mb: ['5', '4'],
  w: ['256px', '270px'],
  wordWrap: 'break-word',
  b: {
    color: 'gamma.500',
  },
  '&[data-ismaintenance="false"]': {
    w: ['220px', '267px'],
  },
};

export const ButtonRefresh: CSSObject = {
  borderRadius: '7px !important',
  color: 'white',
  bg: 'linear-gradient(180deg, #F28B33 0%, #A5540E 100%)',
  boxShadow:
    '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',

  '&:active, &:hover': {
    bg: 'beta.700',
    boxShadow:
      '0px 0px 0px 2px #03C3EE inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #EC9245 inset',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'gamma.500',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};
