import { Box } from '@chakra-ui/react';
import React from 'react';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { propositionButtonStyles } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export type TPropositionButtonProps = CustomButtonProps & {
  propositionName?: string | number | null;
  odds?: number;
  isInline?: boolean;
  isSuspended?: boolean;
};

const SpanComponent: React.FC = ({ children, ...spanProps }) => (
    <Box
      as="span"
      data-testid="propositionButton-buttonBaseSpanProps"
      className='buttonBaseSpanProps'
      {...spanProps}
    >
      {children}
    </Box>
  );

export default function PropositionButton({
  propositionName,
  odds,
  isInline,
  isSuspended,
  isDisabled,
  isActive,
  ...rest
}: TPropositionButtonProps) {
  const isNA = !odds?.toFixed(2);

  return (
    <Button
      data-active={isActive}
      role="group"
      spanProps={{
        display: isInline ? 'flex' : 'block',
        ...propositionButtonStyles.buttonBaseSpanProps,
      }}
      isDisabled={isDisabled || isNA}
      {...propositionButtonStyles.buttonBase}
      {...rest}
      {...(isNA && {
        variant: 'unstyled',
        paddingLeft: '16px',
        paddingRight: '16px',
      })}
    >
       {!isNA && (
        <SpanComponent
          {...propositionButtonStyles.buttonBaseSpanProps}
        >
          {propositionName}
        </SpanComponent>
      )}
      <Box {...propositionButtonStyles.buttonBaseBox}>
        {(() => {
          if (isSuspended) return 'SUS';
          if (isNA) return 'N/A';
          return odds?.toFixed(2);
        })()}
      </Box>
    </Button>
  );
}
