import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  keyframes,
  Skeleton,
  Switch,
  SwitchProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggle from '@/assets/sugarcastle/images/toggle/toggle.png';
import toggleBg from '@/assets/sugarcastle/images/toggle/sc-selector.png';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'toggle-ToggleFlexWrapper',
  baseStyle: {
    alignItems: 'center',
    bg: `url(${toggleBg}) center no-repeat`,
    bgSize: 'cover',
    contain: 'layout',
    width: '100%',
    height: '76px',
    justifyContent: 'center',
    mx: 'auto',
    mb: '1',
    p: '2.5',
    zIndex: '3',
    pos: 'relative',
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: ({ isActive }: any) => ({
      fontFamily: 'Fraunces',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: isActive ? 'beta.300' : 'gamma.400',
      letterSpacing: '0.01rem',
      px: '3',
      zIndex: '5',
    }),
  });

const Switcher: ChakraComponent<'label', SwitchProps> = chakra(Switch, {
  label: 'Switcher',
  baseStyle: {
    '.chakra-switch__track': {
      bg: 'alpha.500',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.30) inset',
      w: '50px',
      zIndex: '5',
      pos: 'relative',
    },

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: '5',

      _before: {
        bgImg: `url(${toggle})`,
        height: '4.5rem',
        width: '4.5rem',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        bottom: '60px',
        left: '-5',
        right: '0',
        top: '-6',
        animation: `
                ${keyframes`
                  from { transform: rotate(0deg) }
                  to { transform: rotate(-360deg) }
                `} 0.5s ease-out`,
      },
    },

    _active: {
      '.chakra-switch__track': {
        boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, .3)',
      },
    },

    _checked: {
      '.chakra-switch__thumb': {
        transitionDuration: '0.7s',
        _before: {
          animation: `
                ${keyframes`
                  from { transform: rotate(0deg); }
                  to { transform: rotate(360deg); }
                `} 0.7s ease-out`,
        },
      },
      '.chakra-switch__track': {
        bg: 'alpha.500',
      },
    },
  },
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={!!category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sports
      </TextToggle>
    </FlexWrapper>
  );
}
