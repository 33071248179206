import { CSSObject } from '@chakra-ui/react';
import { TStyleConfig, TStyleOptions } from '../types';

export type TSideNavTheme = {
  borderBottom: string;
  borderBottomRadius: string;
  readableColor: string;
  sidenavWidth: string;
  background: CSSObject;
  drawer: CSSObject;
  drawerBody: CSSObject;
  icon: CSSObject;
  link: CSSObject;
  linkHeader?: CSSObject;
  linkSX: CSSObject;
  headerSX: CSSObject;
  dropDownInner: CSSObject;
  dropDownContainerOpen: CSSObject;
  dropDownContainerClosed: CSSObject;
  closeButton: CSSObject;
  lastLink?: CSSObject;
};

const sidenavWidth = '270px';

export const sideNavBaseStyle = ({ theme }: TStyleOptions) => ({
  background: {
    bg: 'gray.900',
  },
  borderBottom: theme.colors.whiteAlpha[200],
  borderBottomRadius: theme.radii.lg,
  sidenavWidth,
  drawer: {
    maxW: ['100%', sidenavWidth, null, null],
  },
  drawerBody: {
    p: 0,
  },
  readableColor: 'white',
  icon: {
    boxSize: '5',
    mr: '2',
    color: 'white',
  },
  closeButton: {
    h: '12',
  },
  link: {
    label: 'SideNavLink',
    alignItems: 'center',
    display: 'flex',
    fontSize: 'sm',
    h: '53px',
    px: 4,
    w: 'full',
    justifyContent: 'space-between',
    color: 'white',
    ':hover': {
      fontStyle: 'none',
      textDecoration: 'none',
    },
  },
  linkHeader: {},
  lastLink: {},
  dropDownContainerOpen: {
    h: '50px',
    p: '1',
    svg: { fill: 'white' },
  },
  dropDownContainerClosed: {},
  linkSX: {},
  headerSX: {},
  dropDownInner: {
    h: '43px',
    px: 2,
    justifyContent: 'space-between',
  },
});

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: sideNavBaseStyle,
  sizes: {},
  variants: {},
};
