import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'delta.600',
};

export const RunnerDescription: CSSObject = {
  color: 'gray.400',
};

export const HeaderSvg: CSSObject = {
  borderRadius: 'full',
  bg: 'transparent',
  color: 'delta.600',
  boxSize: '8',
  p: '1',
};

export const BetMultiIcon: CSSObject = {
  bg: 'transparent',
  boxSize: '10',
  color: 'delta.600',
  p: '1',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  bg: () => {
    switch (status) {
      case 'won':
        return 'green.400';
      case 'lost':
        return 'delta.600';
      case 'pending':
        return 'beta.400';
      default:
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'white';
      case 'lost':
        return 'delta.100';
      case 'pending':
        return 'alpha.800';
      default:
    }
  },
});

export const BetOdds: CSSObject = {
  color: 'gray.700',
};

export const TextBarrierNumber: CSSObject = {
  color: 'delta.600',
};

export const headerRowStyles: HeaderRowSchema = {
  mysteryBetIcon: {
    fill: 'alpha.400',
  },
  headerRowSvg: {
    fill: 'white',
  },
};
