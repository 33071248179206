import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: {
    bg: 'alpha.600',
    px: 2.5,
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
    color: 'white',
  },
  buttonCashOutAmount: { px: 2 },
  flexOddsBoostBanner: {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    border: 'none',
    fontSize: 'md',
    color: 'zeta.500',
    fontWeight: 'bold',
    fontFamily: 'SkanjiRegular',
  },
  iconOddsBoost: {
    fill: 'zeta.500',
  },
  textOddsBoost: {
    color: 'zeta.500',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.600',
};

export const RaceTime: CSSObject = {
  color: 'blackAlpha.600',
  fontWeight: 'medium',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'gamma.500',
  color: 'white',
  fontSize: 'sm',
};

export const BonusBetText: CSSObject = {
  color: 'gamma.500',
  fontSize: 'sm',
};

export const BetReturn: CSSObject = {
  bg: 'epsilon.50',
  color: 'epsilon.600',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
  overflow: 'hidden',
};

export const BoxBetReturnValue: CSSObject = {
  bg: 'alpha.300',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'alpha.700',
  display: 'flex',
  alignItems: 'center',
};

export const BoxBetReturnText: CSSObject = {
  p: '1',
  color: 'white',
  bg: 'alpha.700',
};

export const iconLegProps: IconProps = {
  boxSize: '20px',
};
