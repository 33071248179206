import { BoxProps } from '@chakra-ui/react';
import { styleImports } from './MarketGroupsAccordion.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type MarketGroupsAccordionSchema = Partial<{
  accordionItemBox: BoxProps;
}>;

export const marketGroupsAccordionStyles: MarketGroupsAccordionSchema = {
  accordionItemBox: {
    label: 'marketGroupsAccordion-accordionItemBox',
    ...(() => {
      try {
        return styleImports[themeName]?.marketGroupsAccordionStyles
          ?.accordionItemBox;
      } catch (err) {
        return {};
      }
    })(),
  },
};
