/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Collapse, Flex, Text } from '@chakra-ui/react';
import React, { memo, useMemo, useState } from 'react';
import { groupBy } from '@/lib/utils';
import {
  LIMIT,
  MarketLayoutPagination,
  MarketLayoutTabPanel,
  MarketLayoutTabs,
  TMarketLayoutMarket,
  TMarketLayoutProps,
} from '../MarketLayout/MarketLayout';
import PropositionButton from '../PropositionButton/PropositionButton';
import { TEventInfo } from '@/lib/DBModels';
import { TExtendedProposition } from '../../MatchDetailPage/services/MatchDetailPage.types';
import { playerLayoutStyles } from './styles/PlayerLayout.styles';

// --- types
type TPlayerLayoutView = {
  labels: string[];
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  shouldShowMore: boolean;
  isShowingMore: boolean;
  showMoreToggle: () => void;
  markets: [string, TMarketLayoutMarket[]][];
  onSelection?: ((proposition: TExtendedProposition) => void) | undefined;
};

type TPlayerLayoutProps = Pick<
  TMarketLayoutProps,
  'parts' | 'participants' | 'onSelection'
>;

type TUsePlayerViewProps = Pick<TMarketLayoutProps, 'parts'>;

type TListItemProps = {
  player: string;
  label: string;
  markets: TMarketLayoutMarket[];
  onClickProp: ((proposition: TExtendedProposition) => void) | undefined;
};

// --- view
function PlayerLayoutView({
  labels,
  setTabIndex,
  shouldShowMore,
  isShowingMore,
  showMoreToggle,
  markets,
  onSelection,
}: TPlayerLayoutView) {
  const isSinglePropGrid = !markets.some((m) => m?.[1]?.length > 1);
  const hasOverflow = markets
    .map((m) => m?.[1]?.flatMap((mr) => mr.propositions ?? []))
    .some((p) => p.length > 2);

  return (
    <>
      <MarketLayoutTabs labels={labels} onChange={setTabIndex}>
        {labels.map((label) => (
          <MarketLayoutTabPanel
            key={label}
            className={hasOverflow ? 'overflow' : 'no-overflow'}
          >
            <Box
              {...playerLayoutStyles.grid}
              {...(isSinglePropGrid && playerLayoutStyles.gridSingular)}
            >
              {[...markets].slice(0, LIMIT).map((m) => {
                const player = m[0];
                const _markets = m[1];

                return (
                  <ListItem
                    key={`${player}-${label}`}
                    player={player}
                    label={label}
                    markets={_markets}
                    onClickProp={onSelection}
                  />
                );
              })}

              {shouldShowMore && (
                <Collapse in={isShowingMore} animateOpacity unmountOnExit>
                  <Box {...playerLayoutStyles.collapse}>
                    {[...markets].slice(LIMIT).map((m) => {
                      const player = m[0];
                      const _markets = m[1];

                      return (
                        <ListItem
                          key={`${player}-${label}`}
                          player={player}
                          label={label}
                          markets={_markets}
                          onClickProp={onSelection}
                        />
                      );
                    })}
                  </Box>
                </Collapse>
              )}
            </Box>
          </MarketLayoutTabPanel>
        ))}
      </MarketLayoutTabs>

      <MarketLayoutPagination
        in={shouldShowMore}
        isOpen={isShowingMore}
        onPageClick={showMoreToggle}
      />
    </>
  );
}

function ListItem({ player, label, markets, onClickProp }: TListItemProps) {
  return (
    <Box {...playerLayoutStyles.row}>
      <Box {...playerLayoutStyles.nameWrapper}>
        <Text {...playerLayoutStyles.name}>{player}</Text>
      </Box>
      <Flex>
        {markets?.map((ma) =>
          [...(ma.propositions ?? [])]
            .sort(
              (a, b) =>
                (a.proposition_info?.value1 ?? Infinity) -
                (b.proposition_info?.value1 ?? Infinity)
            )
            .map((p, i, arr) => (
              <PropositionButton
                key={`${player}-${label}-${p.proposition_id}`}
                isActive={p.is_selected}
                isDisabled={p.is_disabled}
                isInline
                isSuspended={p.is_suspended}
                odds={p.return_amount}
                propositionName={getPropositionName(p.proposition_info)}
                onClick={() => p && onClickProp?.(p)}
                minW="70px"
                sx={{ '&&&': { px: 0 } }}
                {...(Boolean(i) && { ml: '1.5' })}
                {...(arr.length === i + 1 && {
                  mr: '2',
                })}
              />
            ))
        )}
      </Flex>
    </Box>
  );
}

// --- controller
const usePlayerLayout = ({
  parts,
}: TUsePlayerViewProps): Omit<TPlayerLayoutView, 'onSelection'> => {
  const [tabIndex, setTabIndex] = useState(0);
  const { isOpen: isShowingMore, onToggle: showMoreToggle } = useDisclosure();
  const labels = parts.map(({ title }) => title);

  const { markets } = parts[tabIndex];
  const [grouped, ungrouped] = groupBy(markets, (m) => m.market_info?.player);
  const groupedMarkets = [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...[...grouped.entries()].map((m) => m),
    ...((ungrouped ?? []) as TMarketLayoutMarket[]),
  ] as TPlayerLayoutView['markets'];
  const sortedMarkets = [...groupedMarkets]
    .sort((a, b) => {
      const keyA = a?.[0];
      const keyB = b?.[0];
      return keyA?.localeCompare(keyB);
    })
    .sort((a, b) => {
      const valA = a?.[1]?.[0]?.market_info?.team_role;
      const valB = b?.[1]?.[0]?.market_info?.team_role;
      if (valA === 'Home' && valB !== 'Home') return -1;
      if (valA !== 'Home' && valB === 'Home') return 1;
      return 0;
    });

  const shouldShowMore = useMemo(() => {
    const maxRowCount = Math.max(sortedMarkets.length);
    return maxRowCount > LIMIT;
  }, [sortedMarkets]);

  return {
    labels,
    setTabIndex,
    shouldShowMore,
    isShowingMore,
    showMoreToggle,
    markets: sortedMarkets,
  };
};

function getPropositionName(propositionInfo?: Partial<TEventInfo> | null) {
  if (propositionInfo?.value1 === 0) return '0';
  if (!propositionInfo?.value1) return;

  const { value1, value2 } = propositionInfo;
  const isPositive = value2 === 1;

  return isPositive ? `${value1}+` : `${value1}-`;
}

// --- container
function PlayerLayout({ parts, onSelection }: TPlayerLayoutProps) {
  const values = usePlayerLayout({ parts });
  return <PlayerLayoutView {...values} onSelection={onSelection} />;
}
export default memo(PlayerLayout);
