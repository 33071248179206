import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  borderColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.20)',
  borderRight: 'none',
};

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const TableText = ({ textVariation }: TTableText): CSSObject => ({
  color: textVariation === 'positive' ? 'green.400' : 'white',
});

export const NoTransactionsText: CSSObject = {
  color: 'white',
};

export const buttonMoreTableProps: CustomButtonProps = {
  variant: 'solid',
  mt: '3',
  w: 'fit-content',
};
