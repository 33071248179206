import { rest } from 'msw';
import { sportMarketsEndpoint } from './sportDetailsMarkets';
import { querySportMarketsMock } from './sportDetailsMarkets.mocks';
import { TSportMarketsResponse } from './sportDetailsMarkets.types';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

export const sportDetailsMarketsHandlers = [
  rest.get(`${baseURL}/${sportMarketsEndpoint}`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json<TSportMarketsResponse>(querySportMarketsMock))
  ),
];
