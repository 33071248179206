import { CSSObject } from '@chakra-ui/react';

export const CompetitionsBySportContainer: CSSObject = {
  p: '2',
  pt: '0',
};

export const BoxWrapper: CSSObject = {
  borderColor: 'transparent',
};

export const LinkItem = (isFeatured: boolean) => ({
  p: '2',
  py: '3',
  mb: '1',
  borderRadius: 'md',
  bg: isFeatured ? 'white' : 'gray.50',
  color: isFeatured ? 'white' : 'gray.700',
  transition: 'all, .1s ease-in-out',

  _hover: {
    bg: 'gray.100',
  },

  '&+&': {
    borderTop: '1px',
    borderTopColor: isFeatured ? 'whiteAlpha.100' : 'gray.200',
  },

  svg: {
    boxSize: '6',
    p: '1',
    color: isFeatured ? 'unset' : 'alpha.700',
    border: '1px',
    borderColor: 'alpha.700',
    borderRadius: 'full',
  },
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};
