/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Center } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';

export const template: TTemplate = {
  wrapper: <Center />,
};

export default template;
