import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
};

export const TopNavWrapper: CSSObject = {
  gap: '1',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive
    ? 'blackAlpha.500'
    : 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-gamma-500) 100%)',
  color: isActive ? 'white' : 'gamma.800',
  letterSpacing: 'wider',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',

  '&:hover, &:focus': {
    bg: isActive ? 'delta.300' : 'alpha.500',
  },
});

export const NavLinkLabel: CSSObject = {
  bg: 'blackAlpha.500',
  color: 'white',
  letterSpacing: 'wider',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2xs',
  px: '2',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',

  '&:hover, &:focus': {
    bg: 'delta.300',
  },
};

export const TopNavLabelWrapper: CSSObject = {
  mt: '0',
  px: '0',
};
