import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  svg: {
    color: 'beta.500',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    fill: 'beta.700',
  },
};
export const RunnerDescription: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'bold',
  'span:nth-child(2)': {
    color: 'var(--bc-colors-whiteAlpha-400)',
  },
};
export const BetOdds: CSSObject = {
  color: 'white',
};
export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
};
export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'green.500';
      case 'pending':
        return 'orange.400';
      case 'lost':
        return 'red.500';
      default:
        return 'beta.200';
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'white';
      case 'pending':
        return 'orange.800';
      case 'lost':
        return 'delta.900';
      default:
        return 'delta.900';
    }
  },
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
  py: '0',
});
export const HeaderSvg: CSSObject = {
  filter: 'drop-shadow(0px 2px 4px 0px var(--bc-colors-blackAlpha-400))',
  boxSize: '4',
  mt: '.5',
};
export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};
export const MultiIcon = () => (
  <IconSvg
    name="multi_betslip"
    className="svgIcon"
    color="gamma.300"
    boxSize="5"
    mb="1.5"
  />
);
export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
};
export const headerRowStyles: HeaderRowSchema = {
  originalOdds: {
    color: 'gray.100',
  },
};
