import {
  ButtonProps,
  chakra,
  ChakraComponent,
  CSSObject,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  StackProps,
  Text,
  TextProps,
  BoxProps,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './BottomRow.styles.imports';

const themeName = getThemeName();

export type BottomRowSchema = Partial<{
  flexWrapper: FlexProps;
  buttonCashOutDesc: ButtonProps;
  buttonCashOutAmount: ButtonProps;
  iconRefresh: IconProps;
  iconOddsBoost: CSSObject;
  iconCashOut: CSSObject;
  flexOddsBoostBanner: FlexProps;
  textOddsBoost: TextProps;
  vstackBetsWrapper: StackProps;
  buttonGroupWrapper: ButtonProps;
  cashoutText: TextProps;
  cashoutValue: TextProps;
  venueRaceWrapper: BoxProps;
}>;

export const bottomRowStyles: BottomRowSchema = {
  iconRefresh: {
    'data-testid': 'bottomRow-iconRefresh',
    boxSize: 4,
    ml: -1,

    ...(styleImports?.[themeName]?.bottomRowStyles?.iconRefresh ?? {}),
  },
  buttonCashOutAmount: {
    'data-testid': 'bottomRow-buttonCashOutAmount',
    variant: 'secondary',
    borderRadius: 'base',
    height: 6,
    minH: 6,
    fontSize: 'xs',
    px: 1.5,
    py: 1,
    gap: 'unset',
    textTransform: 'uppercase',

    ...(styleImports?.[themeName]?.bottomRowStyles?.buttonCashOutAmount ?? {}),
  },
  buttonCashOutDesc: {
    'data-testid': 'bottomRow-buttonCashOutDesc',
    borderRadius: 'base',
    height: 7,
    minH: 7,
    fontSize: 'xs',
    px: 1.5,
    py: 1,
    gap: 'unset',
    textTransform: 'uppercase',
    w: '100%',

    ...(styleImports?.[themeName]?.bottomRowStyles?.buttonCashOutDesc ?? {}),
  },
  flexWrapper: {
    'data-testid': 'bottomRow-flexWrapper',
    alignItems: 'center',
    w: '100%',

    ...(styleImports?.[themeName]?.bottomRowStyles?.flexWrapper ?? {}),
  },
  flexOddsBoostBanner: {
    'data-testid': 'bottomRow-flexOddsBoostBanner',
    borderRadius: 'base',
    w: 'full',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    h: 7,
    gap: 1,

    ...(styleImports?.[themeName]?.bottomRowStyles?.flexOddsBoostBanner ?? {}),
  },
  iconOddsBoost: {
    'data-testid': 'bottomRow-iconOddsBoost',
    fill: 'alpha.700',
    boxSize: 3.5,

    ...(styleImports?.[themeName]?.bottomRowStyles?.iconOddsBoost ?? {}),
  },
  textOddsBoost: {
    'data-testid': 'bottomRow-textOddsBoost',
    fontSize: 'sm',
    textTransform: 'uppercase',

    ...(styleImports?.[themeName]?.bottomRowStyles?.textOddsBoost ?? {}),
  },
  iconCashOut: {
    'data-testid': 'bottomRow-iconCashOut',
    boxSize: 6,
    mr: '-1',
    fill: 'white',

    ...(styleImports?.[themeName]?.bottomRowStyles?.iconCashOut ?? {}),
  },
  vstackBetsWrapper: {
    'data-testid': 'bottomRow-vstackBetsWrapper',
    ml: 'auto',
    w: '100%',
    ...(styleImports?.[themeName]?.bottomRowStyles?.vstackBetsWrapper ?? {}),
  },
  buttonGroupWrapper: {
    'data-testid': 'bottomRow-buttonGroupWrapper',
    ...(styleImports?.[themeName]?.bottomRowStyles?.buttonGroupWrapper ?? {}),
  },
  cashoutText: {
    'data-testid': 'bottomRow-cashoutText',
    color: 'white',
    ...(styleImports?.[themeName]?.bottomRowStyles?.cashoutText ?? {}),
  },
  cashoutValue: {
    'data-testid': 'bottomRow-cashoutValue',
    color: 'white',
    ...(styleImports?.[themeName]?.bottomRowStyles?.cashoutValue ?? {}),
  },
  venueRaceWrapper: {
    'data-testid': 'bottomRow-venueRaceWrapper',
    direction: 'column',
    ...(styleImports?.[themeName]?.bottomRowStyles?.venueRaceWrapper ?? {}),
  },
};

export const LinkVenueName = chakra(Link, {
  label: 'bottomRow-LinkVenueName',
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.LinkVenueName;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', LinkProps>;

export const RaceTime = chakra(Text, {
  label: 'bottomRow-RaceTime',
  baseStyle: () => ({
    fontSize: 'xs',
    textTransform: 'capitalize',
    color: 'gray.600',

    ...(() => {
      try {
        return styleImports[themeName]?.RaceTime;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BonusBetCheckMark = chakra(Icon, {
  label: 'bottomRow-BonusBetCheckMark',
  baseStyle: () => ({
    boxSize: '4',
    p: '0.5',
    borderRadius: 'full',
    bg: 'beta.500',
    color: 'white',

    ...(() => {
      try {
        return styleImports[themeName]?.BonusBetCheckMark;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BonusBetText = chakra(Text, {
  label: 'bottomRow-BonusBetText',
  baseStyle: () => ({
    color: 'beta.500',
    fontSize: 'xs',
    fontWeight: 'bold',

    ...(() => {
      try {
        return styleImports[themeName]?.BonusBetText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BetReturn = chakra(Flex, {
  label: 'bottomRow-BetReturn',
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'green.500',

    ...(() => {
      try {
        return styleImports[themeName]?.BetReturn;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxBetReturnValue = chakra(Flex, {
  label: 'bottomRow-BoxBetReturnValue',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.BoxBetReturnValue;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxBetReturnText = chakra(Flex, {
  label: 'bottomRow-BoxBetReturnText',
  baseStyle: () => ({
    pr: '1',
    ...(() => {
      try {
        return styleImports[themeName]?.BoxBetReturnText;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const iconLegProps: any = {
  'data-testid': 'bottomRow-iconLegProps',
  ...(() => {
    try {
      return styleImports[themeName]?.iconLegProps;
    } catch (err) {
      return {};
    }
  })(),
};
