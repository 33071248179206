import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['3', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  bg: 'alpha.500',
  border: '1px solid',
  borderColor: '#1e69d3',
  borderRadius: 'lg',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'alpha.700' : 'alpha.500',
  color: isActive ? 'beta.500' : 'white',
  textShadow: isActive
    ? '0px 0px 8px rgba(251, 201, 21, 0.50)'
    : '0px 0px 8px rgba(67, 187, 255, 0.50)',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  fontSize: 'md',
  lineHeight: 'normal',
  py: ['2', '3'],
  pl: isActive ? ['2', '3'] : ['2', '4'],
  pr: ['2', '4'],
  fontFamily: 'Staatliches',
  borderLeft: ['unset', null, isActive && '3px solid'],
  borderLeftColor: ['unset', null, isActive && 'beta.500'],
  borderBottom: [isActive && '2px solid', null, '1px dashed'],
  borderBottomColor: [isActive && 'beta.500', null, 'blackAlpha.500'],
  _first: {
    borderTopRadius: ['none', null, '8px'],
  },
  _last: {
    borderBottomRadius: '8px',
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    bg: !isActive && 'alpha.600',
  },
});

export const NavLinkLabel: CSSObject = {
  color: 'beta.500',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  border: 'none',
  borderBottom: '4px solid',
  borderBottomColor: 'beta.500',
  fontSize: 'sm',
  textAlign: 'center',
  fontFamily: 'Staatliches',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '110px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '40px',
  ml: ['2', '5'],
};

export const TopNavLabelWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  w: ['95%', '98%'],
  bg: 'blackAlpha.400',
  mx: '2',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10),0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  borderRadius: 'lg',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};
