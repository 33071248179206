import { css } from 'styled-components';

export const container = css`
  color: ${({ theme }) => theme.palette.white};
  font-size: var(--bc-fontSizes-xs);
  font-family: ${({ theme }) => theme.fontFamily.body};
  line-height: var(--bc-lineHeights-base);

  a {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const checkboxAndHelperContainer = css``;

export const checkbox = css`
  border: ${({ theme }) => theme.borderStyles.tertiary};
  background: ${({ theme }) => theme.palette.white};
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
`;

export const checkmarkDefault = css`
  left: 4px;
  top: 1px;
`;

export const checkmarkChecked = css``;

export const helpTextContainer = css``;

export const error = {
  state: css`
    border: 1px solid ${({ theme }) => theme.palette.warning}; // need to revisit why borderStyles doesn't work here
    background: ${({ theme }) => theme.palette.white};
    width: 15px;
    height: 15px;
  `,
  text: css`
    color: ${({ theme }) => theme.palette.warning};
    font-size: var(--bc-fontSizes-xs);
    padding-top: var(--bc-sizes-1);
    font-family: ${({ theme }) => theme.fontFamily.body};
    align-items: center;
  `,
  textIcon: css`
    background-size: var(--bc-sizes-3) var(--bc-sizes-3);
    width: var(--bc-sizes-3);
    height: var(--bc-sizes-3);
    margin-right: var(--bc-sizes-1);
    color: ${({ theme }) => theme.palette.warning};
  `,
};
