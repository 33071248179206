// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PromiseResolver<T, TError = any> = {
  resolve: (value: T) => void;
  reject: (error: TError) => void;
  promise: Promise<T>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeResolver<T, TError = any>() {
  const resolver = {} as unknown as PromiseResolver<T, TError>;
  const promise = new Promise<T>((resolve, reject) => {
    resolver.resolve = resolve;
    resolver.reject = reject;
  });

  resolver.promise = promise;

  return resolver;
}
