import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  borderRadius: 'base',
  bg: 'alpha.600',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '24px',
  display: 'inline-block',
  fontSize: '2xs',
  h: '5',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'white',
  color: 'alpha.600',
  borderRadius: 'md',
  boxShadow: 'md',
  mb: '1.5',

  transition: 'all .3s ease-in-out',
  _hover: {
    bg: 'alpha.50',
  },

  '.chakra-badge': {
    '&.chakra-badge.state-prior': {
      color: 'gray.700',
    },
  },
};

export const TextRaceType: CSSObject = {
  color: 'alpha.700',
};

export const TextRace: CSSObject = {
  fontWeight: 'semibold',
};
