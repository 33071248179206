import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};

export const BoxTakeActionTable: CSSObject = {
  bg: 'alpha.500 ',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  border: '0',
  p: '3',
};

export const TextTakeAction: CSSObject = {
  color: 'white',
};
