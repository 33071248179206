import { rest } from 'msw';
import { sgmPricingEndpoint } from './sgm';
import { TMutateSgmPricingBody } from './sgm.types';

const baseURL = window.BETCLOUD_ENV.REACT_APP_API_URL;

/**
 * Respond with:
 *
 * a) null on 5th selection, otherwise
 * b) length of `proposition_ids` * pi
 */
export const sgmPricingHandler = [
  rest.post<TMutateSgmPricingBody>(
    `${baseURL}${sgmPricingEndpoint}`,
    (req, res, ctx) => {
      const { proposition_ids: propIds = [] } = req.body;
      const price =
        propIds.length === 5
          ? null
          : parseFloat((propIds.length * Math.PI).toFixed(2));

      return res(ctx.status(200), ctx.json({ price }));
    }
  ),
];
