import { CSSObject } from '@chakra-ui/react';
import woodTexture from '../../../../assets/vikingbet/images/backgrounds/texture-wood-1x.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexContainer = (isHome: boolean) => ({
  pos: [isHome ? 'absolute' : 'relative', null, 'unset'],
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },

  bg: ['transparent', null, 'transparent'],
  w: ['100%', null, 'unset'],
  p: ['1', null, null, '0'],
  h: ['12', null, '50px'],
  pb: [null, null, '2'],
});

export const ButtonGroup: CSSObject = {
  overflow: 'hidden',
  pb: ['0', null, '4'],
};

export const ButtonLogIn: CSSObject = {
  fontSize: ['sm', null, 'md'],
  fontFamily: 'SkranjiBold',
  textTransform: 'uppercase',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow:
    'inset 0px 0px 0px 1px var(--bc-colors-delta-300), 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  border: 'var(--bc-colors-delta-600) 2px solid',
  color: 'rgba(0, 0, 0, 0.64)',
  borderRadius: 'lg',
  height: ['9', null, '12'],
  '&:hover, &:active': {
    bgGradient: 'linear(180deg, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },
};

export const ButtonSignUp: CSSObject = {
  fontFamily: 'SkranjiBold',
  fontSize: ['sm', null, 'md'],
  textTransform: 'uppercase',
  borderRadius: 'lg',
  border: '2px',
  borderColor: '#CD8716',
  bgGradient: 'linear(to-b, #FCD236, #E79004)',
  boxShadow: 'inset 0px 0px 0px 1px #FAD461',
  height: ['9', null, '12'],
  color: 'beta.800',
  '&:hover, &:active': {
    bgGradient: 'linear(180deg, #8C742F, #896B28)',
    boxShadow: '0px 0px 0px 2px #8B7746 inset',
    border: '2px',
    borderColor: '#775826',
  },
};

export const ButtonBetSlip: CSSObject = {
  fontFamily: 'SkranjiBold',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-300)',
  border: 'var(--bc-colors-delta-600) 2px solid',
  color: 'rgba(0, 0, 0, 0.64)',
  height: ['9', null, '12'],
  borderRadius: 'lg',
  lineHeight: '0.9',

  '.span-bet-count': { fontSize: ['xs', null, 'md'], fontWeight: 'black' },

  '&:hover, &:active': {
    bgGradient: 'linear(to-b, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },
};

export const ButtonBets: CSSObject = {
  fontFamily: 'SkranjiBold',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow:
    'inset 0px 0px 0px 1px var(--bc-colors-delta-300), 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  border: 'var(--bc-colors-delta-600) 2px solid',
  color: 'rgba(0, 0, 0, 0.64)',
  height: ['9', null, '12'],
  borderRadius: 'lg',
  textTransform: 'uppercase',
  lineHeight: '0.9',
  w: ['45px', null, '49px'],

  '.span-bet': {
    color: 'blackAlpha.700',
    fontSize: ['2xs', null, 'xs'],
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  },

  '.span-bet-count': {
    color: 'blackAlpha.700',
    fontSize: ['xs', null, 'md'],
    fontWeight: 'bold',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  },

  '&:hover, &:active': {
    bgGradient: 'linear(180deg, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },
};

export const buttonQuickAccessProps: CustomButtonProps = {
  sx: {
    '&&': {
      fontFamily: 'SkranjiBold',
      minW: '28',
      alignItems: 'flex-start',
      animation: '.5s ease-in-out',
      borderRadius: 'lg',
      border: '2px',
      borderColor: '#CD8716',
      bgGradient: 'linear(to-b, #FCD236, #E79004)',
      boxShadow:
        'inset 0px 0px 0px 1px #FAD461, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      height: ['9', null, '12'],
      lineHeight: '0.9',
      pl: 2.5,
      pr: 5,

      '&:hover': {
        bgGradient: 'linear(180deg, #8C742F, #896B28)',
        boxShadow: '0px 0px 0px 2px #8B7746 inset',
        border: '2px',
        borderColor: '#775826',
      },

      '&[aria-expanded="true"]': {
        bgImage: `url(${woodTexture})`,
        border: '3px solid alpha.400',
        boxShadow: 'none',

        '.chevron-icon': { color: 'beta.500' },
        '.span-balance': { color: 'beta.500' },
        '.span-bonus': { color: 'beta.500' },
        '.account-text': { color: 'beta.500' },
      },

      '.span-balance': {
        color: 'beta.800',
        fontSize: ['xs', null, '16px'],
        fontWeight: 'black',
      },
      '.span-bonus': {
        color: 'beta.800',
        fontSize: ['2xs', null, 'xs'],
      },
      '.account-text': { color: 'beta.800' },
      '.chevron-icon': { color: 'beta.800' },
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'white',
  boxSize: '8',
  w: '8',
  h: '8',
  '& svg': {
    boxSize: '8',
  },
  _hover: {
    color: 'alpha.500',
  },
});

export const LinkLogo: CSSObject = {
  w: ['111px', null, '160px'],
  display: ['block', null, null, 'none'],
};

export const TextButtonNumber: CSSObject = {
  fontSize: ['xs', null, 'md'],
};

export const TextButton: CSSObject = {
  fontSize: '2xs',
  textTransform: 'uppercase',
};

export const ButtonOutsideArea = {
  boxShadow: 'none',
  border: 'none',
  h: 'auto',
};
