import { ButtonProps, CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};

export const DepositButton: CSSObject = {
  fontWeight: 'normal',
  pt: '1.5',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
  color: 'beta.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 4px rgba(255, 255, 255, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #FFD69E inset, 0px 0px 0px 3px #BD873E inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, yellow.600 0%, yellow.600 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const CancelButton: CSSObject = {
  variant: 'secondary',
};

export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};

export const DepositLimitContainer: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  p: '4',
  color: 'white',
  fontWeight: '700',
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    bg: 'beta.700',
    border: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'beta.400',
      border: 'none',
    },
    _focus: {
      bgGradient: 'linear(to-b, beta.800, beta.500)',
      boxShadow: 'none',
      border: '2px solid',
      borderColor: 'gamma.300',
      m: '0',
      h: '42px',
    },
  },
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalDivider: CSSObject = {
  display: 'none',
};

export const buttonRemoveProps: CSSObject = {
  fontWeight: 'normal',
  pt: '0.5',
  bgGradient: 'linear(to-b, beta.400 0%, beta.600 100%)',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.500',
  color: 'beta.100',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #FFC878 inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.600 0%, beta.600 100%)',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
    color: 'gamma.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    border: 'none',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    border: 'none',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  fontWeight: 'normal',
  width: 'full',
};

export const buttonConfirmModalProps: ButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  width: '50%',
  pt: '1',
};
