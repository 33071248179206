import { CSSObject } from '@chakra-ui/react';

export const BoxColumn: CSSObject = {
  mb: ['2'],
};

export const TextRaceNumber: CSSObject = {
  h: '18px',
  lineHeight: '18px',
  color: 'gamma.400',
  fontWeight: 'bold',
  fontSize: '2xs',
  bg: 'alpha.600',
  flex: 0,
  borderRadius: 'base',
  px: '1',
};

export const Icon: CSSObject = {
  color: 'white',
  boxSize: '6',
  mr: '1',
};

export const LinkRaceContainer: CSSObject = {
  borderRadius: '10px',
  border: '1px solid',
  borderColor: '#5C6D8E',
  background: 'rgba(81, 208, 247, 0.10)',
  mb: '2',
  py: 0,
  h: '47px',
  alignItems: 'center',
  pr: '3',
};

export const TextRaceType: CSSObject = {
  color: '#DAFF3E',
  fontSize: 'md',
  textTransform: 'capitalize',
  fontFamily: 'KHInterference',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  mr: 'auto',
  fontFamily: 'KHInterference',
};
