import React from 'react';
import {
  buttonIncreaseProps,
  IncreaseAmountWrapper,
} from './styles/IncreaseAmountButton.styles';
import { getStrings } from '@/helpers/utils';
import { Button } from '@/components/Button/Button';

const IncreaseAmount: React.FC<TIncreaseAmountProps> = ({
  onChange,
  currentInputValue,
  isDisabled,
}) => {
  const [
    {
      Account: {
        Deposit: { IncreaseAmountButton: strings },
      },
    },
  ] = getStrings();

  const handleNumberPress = (plusAmount: number) => {
    const newVal = (currentInputValue ?? 0) + plusAmount;

    onChange(newVal);
  };

  const ButtonData = [
    {
      label: strings.ten,
      onClick: () => handleNumberPress(10),
      key: '10',
    },
    {
      label: strings.fifty,
      onClick: () => handleNumberPress(50),
      key: '50',
    },
    {
      label: strings.hundred,
      onClick: () => handleNumberPress(100),
      key: '100',
    },
    {
      label: strings.fiveHundred,
      onClick: () => handleNumberPress(500),
      key: '500',
    },
    {
      label: strings.thousand,
      onClick: () => handleNumberPress(1000),
      key: '1000',
    },
  ];

  return (
    <IncreaseAmountWrapper>
      {ButtonData.map(({ label, onClick, key }) => (
        <Button
          {...buttonIncreaseProps}
          key={key}
          onClick={onClick}
          data-cy={`add-${key}-button`}
          isFullWidth
          size="sm"
          isDisabled={isDisabled}
        >
          {label}
        </Button>
      ))}
    </IncreaseAmountWrapper>
  );
};

export type TIncreaseAmountProps = {
  onChange: (value: number) => void;
  currentInputValue: number;
  isDisabled?: boolean;
};

export default IncreaseAmount;
