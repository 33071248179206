import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { CustomButtonProps } from '@/components/Button/Button';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  py: '2',
  w: 'fill-available',
  px: '1',
  color: 'white',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
});
export const FlexBetslipHeading: CSSObject = {
  bg: 'transparent',
  h: '12',
  borderBottom: [null, null, null, '1px solid'],
  borderTopRadius: 'md',
  mx: '6px',
  mt: '6px',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
  '&&': {
    borderBottomColor: 'whiteAlpha.100',
  },
};
export const BetslipTitle: CSSObject = {
  svg: {
    color: 'alpha.500',
  },
};
export const closeIcon: TCloseIcon = {
  boxSize: '8',
  as: CloseCircle,
  color: 'white',
};
export const CloseIconButton: CustomButtonProps = {
  border: 'none',
  bg: 'transparent',
  _hover: {
    border: 'none',
    bg: 'none',
  },
};
export const BetslipHeaderIcon: CSSObject = {
  color: 'alpha.400',
  boxSize: '5',
};
export const FlexIconPropName: CSSObject = {
  gap: '2',
};
export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};
export const BetLengthIndicator: CSSObject = {
  bg: 'alpha.400',
  color: 'alpha.800',
  fontSize: 'xs',
  borderRadius: 'base',
  fontFamily: 'body',
  fontWeight: 'bold',
  boxSize: '5',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  lineHeight: 'normal',
};
export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});
export const BetslipTitleText: CSSObject = {
  fontWeight: 'normal',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
};
