import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  mb: 0,
  gap: '1.5',
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'tertiary',
};
