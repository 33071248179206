import { Box } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { TTransaction } from '@/lib/DBModels';
import { TableText } from '../styles/DesktopTransactionsTable.styles';
import { useWinningsStake } from '@/views/account/Transactions/components/TransactionTable/Services/Transactions.hooks';

type TMultiLegDetailsProps = {
  details: TTransaction;
};

const MultiLegDetails: React.FC<TMultiLegDetailsProps> = ({ details }) => {
  const intl = useIntl();

  const isMysteryBet = details.details?.leg_details?.some(
    (l) => l.price_type === 'mystery_bet'
  );

  const winningsStake = useWinningsStake(details);

  let header;

  if (isMysteryBet) {
    header = intl.formatMessage({
      id: 'account.myBetsCard.msyteryBetHeaderWithRollover',
    });
  } else {
    const legCount = details.details?.leg_details?.length ?? 0;
    const formattedLegCount = legCount.toLocaleString();
    const formattedPrice = details?.details?.bet_odds?.toFixed(2) ?? '0.00'; // Assuming multipliedPrices is a number
    header = `${formattedLegCount} ${intl.formatMessage({
      id: 'account.transactions.leg',
    })} @${formattedPrice}${winningsStake}`;
  }

  return (
    <Box sx={{ label: 'multiLegDetails-Container' }}>
      <TableText>{header}</TableText>
      <TableText fontSize="xs">
        {intl.formatMessage({ id: 'account.transactions.betId' })}:{' '}
        {details.details?.bet_id}
      </TableText>
    </Box>
  );
};

export default MultiLegDetails;
