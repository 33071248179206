import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TData } from './banner.types';
import { queryBanners } from './banner';
import { keys } from '../api.keys';

type TQueryBannerHook = {
  key?: string[];
  options?: UseQueryOptions<TData[], string[]>;
};

export const useQueryBanners = (hook: TQueryBannerHook = {}) => {
  const { key = [], options = {} } = hook;

  const query = useQuery<TData[], string[]>(
    [keys.banners, ...key],
    () => queryBanners(),
    {
      ...options,
    }
  );

  return query;
};
