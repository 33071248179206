export enum EGreenIDStatus {
  // Note: GBG statuses can be confusing, e.g. IN_PROGRESS is assigned straight away after signup. PENDING = pending manual review
  // Detailed description on each status here:
  // https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/60482483/Reference+Tables+V3#ReferenceTablesV3-OverallOutcomeStates
  VERIFIED = 'VERIFIED',
  VERIFIED_ADMIN = 'VERIFIED_ADMIN',
  VERIFIED_WITH_CHANGES = 'VERIFIED_WITH_CHANGES',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  LOCKED_OUT = 'LOCKED_OUT',
}
