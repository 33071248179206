import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  p: '2',
  mt: '0',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: 'md',
  border: '2px',
  // TODO: Update to use theme token
  color: '#8EE1F9',
  mb: '5',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'gamma.600',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-2',
  gap: '1px',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'gamma.600',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  color: 'white',
  bg: 'rgba(0, 0, 0, 0.15)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.06), 0px 0px 8px 0px rgba(0, 0, 0, 0.20) inset',
  mb: '7',
  fontWeight: 'black',
};

export const MultiCardInputContainer: CSSObject = {
  px: '0',
};
