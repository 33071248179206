import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import {
  Box,
  chakra,
  ChakraComponent,
  Icon,
  IconProps,
  Text,
} from '@chakra-ui/react';
import { ChevronRight } from '@styled-icons/fa-solid';
import { styleImports } from './CompetitionsBySport.styles.imports';
import SVGIcon from '../../../../components/SVGIcon';
import IconSvg from '../../../../components/common/IconSvg';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const BoxPageWrapper = chakra(Box, {
  baseStyle: () => ({
    p: '5',
    borderBottomRadius: 'lg',
  }),
});

export const TextTile = chakra(Text, {
  baseStyle: ({ theme }) => ({
    mb: '2',

    ...(theme.themeName === EThemes.Wellbet && {
      fontWeight: 'semibold',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      fontWeight: 'semibold',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.TextTile;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const BoxWrapper = chakra(Box, {
  label: 'SportBoxWrapper',
  baseStyle: ({ theme }) => ({
    mb: '5',
    contain: 'paint',
    borderRadius: 'md',
    border: '1px',

    ...(theme.themeName === EThemes.Wellbet && {
      borderColor: 'blackAlpha.300',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      borderColor: 'blackAlpha.300',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.BoxWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

type TLinkItem = {
  isFeatured?: boolean;
  isFirstItem?: boolean;
  isLastItem?: boolean;
} & LinkProps &
  any;
export const LinkItem = chakra<TLinkItem>(Link, {
  baseStyle: ({ theme, isFeatured, isFirstItem, isLastItem }: any) => ({
    display: 'flex',
    fontWeight: isFeatured ? 'bold' : 'medium',
    alignItems: 'center',
    lineHeight: '1',

    ...(theme.themeName === EThemes.Betgalaxy && {
      px: '1.5',
      py: '3',
      bg: isFeatured ? 'beta.500' : 'white',
      color: isFeatured ? 'white' : 'currentColor',
      transition: 'background ease-in-out .1s',

      '&+&': {
        borderTop: '1px',
        borderTopColor: isFeatured ? 'whiteAlpha.100' : 'gray.200',
      },

      svg: { color: isFeatured ? 'unset' : 'brand.900' },

      _hover: {
        bg: 'gray.200',
      },
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.LinkItem?.(
          isFeatured,
          isFirstItem,
          isLastItem
        );
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'link', TLinkItem>;

export const IconSport = chakra(IconSvg, {
  baseStyle: () => ({
    boxSize: '5',
    mr: '2',
  }),
});

const ChakraIcon = (props: any) => <Icon as={ChevronRight} {...props} />;
export const IconChevron = chakra(ChakraIcon, {
  label: 'sportsList-icon-chevron',
  baseStyle: () => ({
    boxSize: '3',
    color: 'gray.800',
    ml: 'auto',

    ...(() => {
      try {
        return styleImports[themeName]?.IconChevron;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', IconProps>;

export const TextSport = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'sm',

    ...(() => {
      try {
        return styleImports[themeName]?.TextSport;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CompetitionsBySportContainer = chakra(Box, {
  label: 'CompetitionsBySportContainer',
  baseStyle: ({ theme }) => ({
    px: '3.5',

    ...(theme.themeName === EThemes.Betgalaxy && {
      px: [0, '3.5'],
      pb: '1',
      bg: 'white',
      borderBottomRadius: 'md',
    }),

    ...(() => {
      try {
        return styleImports[themeName]?.CompetitionsBySportContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

const BackIcon = styled.div`
  ${({ theme }) => theme.pages.competitionsBySport.backIcon};
`;

const CompetitionsBySportHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) =>
    theme.pages.competitionsBySport.competitionsBySportHeadingContainer};
`;

const CompetitionsContainer = styled.div`
  ${({ theme }) => theme.device.tablet} {
    margin-right: var(--bc-sizes-5);
  }

  ${({ theme }) =>
    theme.pages.competitionsBySport.competitionsBySportContainer};
`;

const CompetitionBySportHeading = styled.div`
  font-size: var(--bc-fontSizes-md);
  margin-bottom: var(--bc-sizes-6);

  ${({ theme }) => theme.pages.competitionsBySport.competitionsBySportHeading};
`;

const CompetitionListHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: var(--bc-sizes-2);

  ${({ theme }) =>
    theme.pages.competitionsBySport.competitionListHeadingContainer};
`;

const CompetitionListHeading = styled.h3`
  font-size: var(--bc-fontSizes-md);

  ${({ theme }) => theme.pages.competitionsBySport.competitionListHeading};
`;

export const CompetitionListItem = chakra(Link, {
  baseStyle: () => ({}),
});

const CompetitionFeaturedListItem = styled(Link)`
  align-items: center;
  display: flex;
  font-size: var(--bc-fontSizes-md);
  padding: var(--bc-sizes-2) var(--bc-sizes-6) var(--bc-sizes-2)
    var(--bc-sizes-12);
  text-decoration: none;

  ${({ theme }) => theme.pages.competitionsBySport.competitionFeaturedListItem};
`;

const CompetitionListItemIcon = styled(SVGIcon)`
  height: var(--bc-sizes-2);
  margin-right: var(--bc-sizes-4);
  width: var(--bc-sizes-2);

  ${({ theme }) => theme.pages.competitionsBySport.competitionListItemIcon};
`;

const SportName = styled.span`
  ${({ theme }) => theme.pages.competitionsBySport.sportName};
`;

const CompetitionListItemName = styled.span`
  ${({ theme }) => theme.pages.competitionsBySport.competitionListItemName};
`;

export {
  BackIcon,
  CompetitionsBySportHeadingContainer,
  CompetitionsContainer,
  CompetitionBySportHeading,
  CompetitionFeaturedListItem,
  CompetitionListItemIcon,
  CompetitionListHeadingContainer,
  CompetitionListHeading,
  SportName,
  CompetitionListItemName,
};
