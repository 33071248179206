export enum EResponsibleGamblingBannerLocation {
  HomepageCarousel = 'HomepageCarousel',
  HomepageFooter = 'HomepageFooter',
  AccountOverview = 'AccountOverview',
  BetslipFooter = 'BetslipFooter',
  ResponsibleGamblingPage = 'ResponsibleGamblingPage',
}

export type TResponsibleGamblingBanner = {
  bannerLocation?: EResponsibleGamblingBannerLocation;
};
