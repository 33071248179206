import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};
export const BoxTakeActionTable: CSSObject = {
  bgGradient: 'linear(to-b, delta.300, delta.400)',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  border: '0',
  p: '3',
};
export const TextTakeAction: CSSObject = {
  color: 'white',
};
