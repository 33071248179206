import { css } from 'styled-components';

export const ButtonOutline = css`
  background: transparent;
  color: ${({ theme }) => theme.basePalette.blue};
  border-color: ${({ theme }) => theme.basePalette.blue};
`;

export const TextThemed = css`
  color: ${({ theme }) => theme.palette.primary};
  font-size: var(--bc-fontSizes-sm);
`;

export const ColourPrimary = css`
  color: ${({ theme }) => theme.palette.primary};
`;
