import { TextProps, CSSObject } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};

export const FormControlGroup: CSSObject = {
  'div.chakra-input__group > input[data-cy="passwordInput"]': {
    bg: 'zeta.100',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
    _placeholder: {
      color: 'alpha.700',
    },
  },
};
