import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'unstyled',
    fontSize: '2xs',
    textShadow: 'none',
    color: 'white',
    h: 'fit-content',
    textTransform: 'uppercase',
    sx: {
      '&&': {
        fontWeight: 'bold',
      },
      svg: {
        bg: 'beta.400',
        p: '0.5',
        color: 'blackAlpha.500',
        borderRadius: 'sm',
      },
    },
    _hover: {
      color: 'beta.400',
    },
  },
};
