import {
  Box,
  ButtonProps,
  chakra,
  ChakraComponent,
  Divider,
  DividerProps,
  Flex,
  Grid,
  Stack,
  Text,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { styleImports } from './DepositLimit.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { Button } from '@/components/Button/Button';
import { TIndex } from '@/components/FormElements/Input';

const themeName = getThemeName();

export const Heading = chakra(Text, {
  label: 'DepositLimitHeading',
  baseStyle: () => ({
    fontSize: 'xl',
    mb: '22px',
  }),
});

export const Subheading = chakra(Text, {
  label: 'DepositLimitSubheading',
  baseStyle: () => ({
    fontSize: 'sm',
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.Subheading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextSubheading = chakra(Text, {
  label: 'DepositLimitTextSubheading',
  baseStyle: () => ({
    fontSize: 'sm',
    mb: '4',
    ...(() => {
      try {
        return styleImports[themeName]?.TextSubheading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ModalHeading = chakra(Text, {
  label: 'DepositLimitModalHeading',
  baseStyle: () => ({
    fontFamily: 'heading',
    fontStyle: 'italic',
    color: 'secondary',
    fontSize: 'md',
    textAlign: 'center',
    mb: '1',
  }),
});

export const UpdateLimitHeading = chakra('p', {
  label: 'DepositLimitUpdateLimitHeading',
  baseStyle: () => ({
    fontSize: 'xl',
    fontStyle: 'normal',
    fontWeight: 'semibold',
    fontFamily: 'body',
  }),
});

export const ModalContentWrapper = chakra(Box, {
  label: 'DepositLimitModalContentWrapper',
  baseStyle: () => ({
    padding: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.ModalContentWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LimitLabel = chakra(Flex, {
  label: 'depositLimit-DepositLimitLimitLabel',
  baseStyle: () => ({
    mr: '1',
    w: '32',

    ...(() => {
      try {
        return styleImports[themeName]?.LimitLabel;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ModalSubheading = chakra(Text, {
  label: 'DepositLimitModalSubheading',
  baseStyle: () => ({
    fontSize: 'sm',
    textAlign: 'left',
    marginTop: '1',
    px: '2',

    ...(() => {
      try {
        return styleImports[themeName]?.ModalSubheading;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LimitValue = chakra(Text, {
  label: 'depositLimit-DepositLimitLimitValue',
  baseStyle: () => ({
    fontWeight: 'bold',
    ...(() => {
      try {
        return styleImports[themeName]?.LimitValue;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PageHeader = chakra(Text, {
  label: 'DepositLimitPageHeader',
  baseStyle: () => ({
    fontSize: 'xl',
    fontWeight: 'bold',
    mb: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.PageHeader;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonWrapper = chakra(Flex, {
  baseStyle: () => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: '4',
    gap: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CancelButton = chakra(Button, {
  baseStyle: () => ({
    w: '100%',
    fontFamily: 'heading',
    bg: 'gray.500',
    textColor: 'white',
    '&:hover': {
      bg: 'black',
    },
    ...(() => {
      try {
        return styleImports[themeName]?.CancelButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonSubmit = chakra(Button, {
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonSubmit;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DepositLimitGrid = chakra(Grid, {
  label: 'DepositLimitGrid',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.DepositLimitGrid;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DepositLimitContainer = chakra(Flex, {
  baseStyle: () => ({
    label: 'depositLimit-DepositLimitContainer',
    flexDir: 'column',
    gap: '3',
    bg: 'gray.100',
    borderRadius: 'base',
    fontSize: 'sm',
    p: '2',
    mb: '5',
    ...(() => {
      try {
        return styleImports[themeName]?.DepositLimitContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const PendingLimitContainer = chakra(Flex, {
  baseStyle: () => ({
    label: 'depositLimit-PendingLimitContainer',
    flexDir: 'column',
    gap: '3',
    borderRadius: 'base',
    border: '1px solid',
    borderColor: 'gray.200',
    fontSize: 'sm',
    p: '2',
    mb: '5',
    ...(() => {
      try {
        return styleImports[themeName]?.PendingLimitContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const LimitWrapper = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
    borderColor: 'gray.100',
    padding: '4',
    mt: '2',
    mb: '4',
  }),
});

export const ToolbarContainer = styled.div`
  ${({ theme }) => theme.pages.accountDepositLimit.toolbarContainer}
`;

export const DepositButton = chakra(Button, {
  baseStyle: () => ({
    w: '100%',
    textTransform: 'uppercase',

    ...(() => {
      try {
        return styleImports[themeName]?.DepositButton;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const FormDivider = chakra('hr', {
  baseStyle: () => ({
    label: 'depositLimit-FormDivider',
    borderTop: '0px',
    ...(() => {
      try {
        return styleImports[themeName]?.FormDivider;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const DepositLimitInputContainer = chakra(Stack, {
  label: 'depositLimit-DepositLimitInputContainer',
  baseStyle: () => ({
    display: 'flex',
    flexDir: 'column',
    flex: 1,
    mt: '5',
    ...(() => {
      try {
        return styleImports[themeName]?.DepositLimitInputContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ModalDivider: ChakraComponent<'hr', DividerProps> = chakra(
  Divider,
  {
    baseStyle: {
      ...(() => {
        try {
          return styleImports[themeName]?.ModalDivider;
        } catch (err) {
          return {};
        }
      })(),
    },
  }
);

export const inputProps: Partial<TIndex> = {
  'data-testid': 'depositLimit-inputProps',
  ...(() => {
    try {
      return styleImports[themeName]?.inputProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonRemoveProps: ButtonProps = {
  'data-test-id': 'depositLimit-buttonRemoveProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonRemoveProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonConfirmModalProps: ButtonProps = {
  'data-test-id': 'depositLimit-buttonConfirmModalProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonConfirmModalProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const buttonRemoveModalProps: ButtonProps = {
  'data-test-id': 'depositLimit-buttonRemoveModalProps',
  ...(() => {
    try {
      return styleImports[themeName]?.buttonRemoveModalProps;
    } catch (err) {
      return {};
    }
  })(),
};
