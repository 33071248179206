import React from 'react';
import { WrapperFlexOnboarding } from '../styles/onboarding.styles';
import AccountVerificationTemplate from './templates/template';
import { createTemplate } from '@/lib/templateSwitch';

export default function AccountVerificationNew() {
  const template = createTemplate(AccountVerificationTemplate);

  return <WrapperFlexOnboarding>{template}</WrapperFlexOnboarding>;
}
