import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  m: 'auto',
  bg: 'beta.100',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 5px #C8B277 inset',
  p: '4',
  borderRadius: 'xl',
};
export const HeaderText: CSSObject = {
  color: 'delta.700',
  fontSize: '18px',
  fontWeight: 'normal',
  p: '0',
  textTransform: 'none',
  fontFamily: 'heading',
};
export const HeaderContainer: CSSObject = {
  p: '0',
};
export const CloseButton: CSSObject = {
  display: 'none',
};
