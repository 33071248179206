import { CSSObject, TextProps } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const titleProps: TextProps = {
  display: 'flex',
  justifyContent: ['space-between', null, 'flex-start'],
};

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
  color: 'gray.500',
  lineHeight: '130%',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
  color: 'gray.500',
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',
  color: 'gray.500',
  fontSize: '12px',

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    borderLeftRadius: 'lg',
    h: '9',
    boxShadow: 'unset',
    color: 'gray.600',
  },
  '.chakra-select': {
    bg: 'zeta.50',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'lg',
    fontSize: 'xs',
    h: '9',
    color: 'gray.400',
    fontWeight: 'sm',
  },
  '.chakra-input': {
    fontSize: 'xs',
    borderRightRadius: 'lg',
    bg: 'gray.50',
    border: '1px solid',
    // borderLeft: 'none',
    borderColor: 'gray.300',
    h: '9',
    boxShadow: 'unset',
    borderLeft: 'none',
    color: 'gray.600',
    _hover: {
      '&&': {
        bg: 'gray.300',
        border: 'none',
      },
    },
    _focus: {
      '&&': {
        bg: 'gray.300',
        border: 'none',
      },
    },
  },
};

export const RadioLimitChoice: CSSObject = {
  bg: 'alpha.50',
  border: 'none',
  '&[data-checked]': {
    bg: 'alpha.600',
    borderWidth: '7px',
    borderStyle: 'solid',
    borderColor: 'alpha.50',
    color: 'alpha.600',
  },
};

export const ButtonSubmit: CSSObject = {
  _disabled: {
    color: 'white',
  },
};
