import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const BoxWrapper: CSSObject = {
  px: ['2', '4'],
};

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: 'none',
  borderRadius: 'xl',
  bg: 'white',
  mb: '2',
  border: 'none',
  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',
    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  p: '2',
  bg: 'white',
  borderBottomRadius: 'lg',
  ...(isNested && {
    borderWidth: '2px',
    borderStyle: ' solid',
    borderColor: 'rgba(0, 0, 0, 0.20)',
    borderBottomRadius: 'lg',
    borderTop: 'none',
    p: '11',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'beta.200',
  color: 'beta.800',
  px: '2',
  py: '2.5',
  border: 'none',
  transition: 'all 0.2s ease-in-out',
  fontWeight: 'bold',
  fontSize: 'sm',
  borderRadius: 'lg',
  '.chakra-accordion__icon': {
    boxSize: ['4', '5'],
    border: '1px',
    borderColor: 'beta.800',
    borderRadius: 'full',
    color: 'beta.800',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    borderBottomRadius: 'none',
    color: 'beta.800',
    fontWeight: '700',
    fontSize: 'sm',
    _hover: {
      borderBottomRadius: 'none',
    },
  },

  _hover: {
    borderRadius: 'lg',
    bg: 'beta.600',
  },

  ...(isNested && {
    borderRadius: 'lg',
    color: 'beta.800',
    px: '2',

    '.chakra-accordion__icon': {
      boxSize: ['4', '5'],
      border: '1px',
      borderColor: 'beta.800',
      borderRadius: 'full',
      color: 'beta.800',
      transition: '.3s ease-in-out',
    },
  }),
});
