import React from 'react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import QuickAccessMenuV1 from '../QuickAccessMenu.v1';

import QuickAccessMenuV2 from '../../QuickAccessMenuV2/QuickAccessMenuV2';
import { combineTemplate } from '@/lib/templateSwitch';
import DefaultTemplate from './template.default';

export default combineTemplate(DefaultTemplate, [
  {
    name: 'comQuickAccessMenu',
    component: FEATURE_FLAGS.IS_DEV ? (
      <QuickAccessMenuV2 />
    ) : (
      <QuickAccessMenuV1 />
    ),
  },
]);
