import { DeepPartial } from '@reduxjs/toolkit';

export enum EPolicyType {
  TermsConditions = 'termsAndConditions',
  Privacy = 'privacyPolicy',
  ResponsibleGambling = 'responsibleGambling',
}

export type TContentInfo = {
  created_at: string;
  related_data: string;
  related_key: string;
  related_val: EPolicyType;
  source_filename: string;
  title: string;
  updated_at: string;
  upload_id: string;
  uri: string;
};

export type THomePage = DeepPartial<{
  content_info: TContentInfo;
  type: string;
}>;
