import { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router';
import { useAppDispatch } from '../hooks/useRedux';
import { TBlockerNavigator } from './mobileapp.types';
import { postMobileAppMessage } from './mobileapp.utils';
import { TBetSlipState } from '../components/Betslip/Services/Betslip.types';
import { mobileAppSyncBetSlip } from '../redux/Betslip.slices';

// This hook handles messaging data to the native app and vice-versa
export const useMobileAppSupport = () => {
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const { navigator } = useContext(NavigationContext);
  const unblockRef = useRef(() => {
    [].forEach((e) => e);
  });

  // This is used to prevent navigation when in a webview - instead send the route to the native app
  useEffect(() => {
    const { block } = navigator as TBlockerNavigator;

    unblockRef.current = block((transition) => {
      if (
        transition.location.pathname.startsWith(
          '/account/settings/responsible-gambling'
        )
      ) {
        document.location = transition.location.pathname;
        return;
      }

      postMobileAppMessage('location_change', {
        location: {
          path: transition.location.pathname,
          search: transition.location.search,
        },
      });
    });
  }, [navigator]);

  // Update the web app location
  const changeRoute = useCallback(
    (url: string) => {
      if (!url) return true;
      history(url);
      return true; // Must return true - library bug
    },
    [history]
  );

  const syncBetSlip = useCallback(
    (data: TBetSlipState) => {
      dispatch(mobileAppSyncBetSlip(data));

      return true; // Must return true - library bug
    },
    [dispatch]
  );

  // Register some global window hooks the native app can call. Need to be in global window
  useEffect(() => {
    window.PWA = {
      changeRoute,
      syncBetSlip,
    };
  }, [changeRoute, dispatch, history, syncBetSlip]);
};
