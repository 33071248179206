import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  mt: '9',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'dreadful',
  fontSize: '48px',
  color: 'alpha.200',
  textShadow: '0px 1px 7px rgba(128, 255, 0, 0.85)',
  textTransform: 'lowercase',
  lineHeight: '78%',
  fontWeight: '400',
  textAlign: 'center',
  mx: '9',
  mb: '1',
};

export const BetSlipEmptySubheading: CSSObject = {
  textAlign: 'center',
  color: 'white',
  fontFamily: 'roboto',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '113%',
  px: '8',
  mt: '3',
};
