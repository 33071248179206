import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '3'],
  mb: ['2', null, 'unset'],
  mt: 'unset',
  borderRadius: 'lg',
  bg: 'alpha.600',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
};

export const BoxWrapper: CSSObject = {
  px: '3.5',
  pb: '1.5',
  pt: '2.5',
};

export const TextHeading: CSSObject = {
  color: 'gamma.200',
  fontSize: 'md',
  fontFamily: 'accentMusashi',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, gamma.500 0%, gamma.600 100%)',
  mr: '0',
  textAlign: 'center',
  color: 'blackAlpha.700',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  width: 'full',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  _hover: {
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.300 100%)',
  },
};
