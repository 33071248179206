import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['2', null, 'unset'],
    mt: ['2', null, '0'],
    mb: 4,
  },
  boxHeading: {
    pt: '0',
    mb: 0,
    ...(!IS_MOBILE_APP && {
      pb: '9',
    }),
    color: 'white',
  },
  textTitle: {
    mt: '0',
    color: 'white',
    fontSize: ['lg', 'sm'],
    fontWeight: 'normal',
    fontFamily: 'Bowlby One',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderBottomRadius: 'md',
        pb: '2',
        mt: '0',
        mb: '2.5',
        display: 'flex',
        gap: ['1', null, '4'],
        flexDirection: ['column', null, 'row'],
        bgGradient: 'linear(to-b, alpha.300, alpha.400)',
        boxShadow: [
          null,
          null,
          '0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset',
          null,
          null,
        ],
        borderRadius: 'md',
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pb: '2',
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    px: '3',
    py: [null, null, '4', null, null],
    w: '100%',
  },
};

export const BoxHeading: CSSObject = {
  pt: '0',
  mb: 0,
  ...(!IS_MOBILE_APP && {
    pb: '9',
  }),
  color: 'white',
};
