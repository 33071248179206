import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/titanbet/images/account/topNavBg.png';

export const ModalBoxWrapper: CSSObject = {
  bgImage: `url(${bg})`,
  border: '2px solid',
  borderColor: 'gamma.500',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  borderRadius: 'md',
  p: '3',
};

export const HeaderText: CSSObject = {
  color: 'gamma.300',
  fontSize: 'md',
  fontWeight: 'normal',
  p: '0',
  textTransform: 'none',
  fontFamily: 'accent',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const CloseButton: CSSObject = {
  display: 'none',
};
