import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '44px',
  w: 'container.xl',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  '& > div:last-of-type': { mr: '0' },
};

export const LinkItem: CSSObject = {
  bg: 'alpha.500',
  w: '162px',

  _hover: {
    bg: 'alpha.700',
  },
};

export const RaceNumber: CSSObject = {
  color: 'white',
};
export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '90px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'white',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  w: '4',
  h: 'full',
  bg: 'alpha.500',
  color: 'white',
  p: '0',
  borderRadius: 'sm',

  '& > svg': { boxSize: '5' },

  _hover: {
    bg: 'alpha.700',
  },
};
