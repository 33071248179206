import { CashOutModalSchema } from './CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bg: 'beta.200',
  },
  stackWrapper: {
    bg: '#353f4b',
  },
};
