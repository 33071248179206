import { CSSObject } from '@chakra-ui/react';
import bg from '../../../../../../assets/questbet/images/homeFeaturedRacing/bg.png';

export const FlexFeatureWrapper: CSSObject = {
  bgImage: `url(${bg})`,
  bgColor: 'alpha.400',
  border: '4px solid',
  borderColor: 'white',
  bgSize: 'cover',
  minH: [null, '350px'],
  px: 0,
  h: [null, '350px'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  p: '5',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  bgRepeat: 'no-repeat',
  bgPos: 'bottom',
  justifyContent: ['center', null, 'flex-start'],
  transformStyle: 'preserve-3d',
  pt: ['0', null, '5'],
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  zIndex: '5',
  h: ['200px', null, 'auto'],
  minH: ['120px', null, 'auto'],

  _after: {
    content: `""`,
    pos: 'absolute',
    top: '2',
    borderRadius: 'full',
    zIndex: '-2',
    bg: 'beta.500',
    boxSize: ['100px', null, '150px'],
  },
};

export const ImageMascot: CSSObject = {
  pos: 'absolute',
  bottom: 0,
  display: ['none', null, 'block'],

  _after: {
    content: `""`,
    pos: 'absolute',
    top: '5',
    borderRadius: 'full',
    zIndex: '-2',
    bg: 'beta.500',
    boxSize: '150px',
  },
};
export const HeadingSlogan: CSSObject = {
  fontFamily: 'strippy',
  textAlign: 'center',
  color: 'black',
  textTransform: 'uppercase',
  fontSize: ['40px', null, '35px'],
  lineHeight: '1',
  letterSpacing: ['5px', null, '13px'],
};

export const SpanSlogan: CSSObject = {
  fontFamily: 'strippy',
  textAlign: 'center',
  color: 'white',
  fontSize: ['60px', null, '75px'],
  lineHeight: '1',
  pl: '1',
  letterSpacing: ['20px', null, '25px'],
};

export const TextFeatureStyledHeading: CSSObject = {
  color: 'white',
};

export const LinkCardHeader: CSSObject = {
  bg: 'white',
  color: 'alpha.700',

  '.chakra-badge.state-prior': { color: 'gray.700' },
};

export const IconCardHeader: CSSObject = {
  color: 'alpha.700',
};

export const TextCardHeader: CSSObject = {
  color: 'alpha.700',
};

export const TextRaceNumber: CSSObject = {
  bg: 'alpha.500',
  color: 'white',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'alpha.50',
  color: 'alpha.800',
  textTransform: 'uppercase',
};

export const FlexGridRunnerItem: CSSObject = {
  bg: 'white',
  borderBottom: 'none',
};

export const TextRunner: CSSObject = {
  color: 'alpha.700',
  fontSize: 'xs',
};

export const Button = (isActive: boolean): CSSObject => ({
  bg: 'beta.500',
  border: '2px solid',
  borderColor: 'white',
  color: 'white',
  boxShadow: 'md',
  borderRadius: 'md',
  transition: 'all .3s linear',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',

  '&:hover': {
    bg: 'beta.700',
    borderColor: 'beta.800',
    boxShadow: 'none',
  },

  '&:focus, &:hover': {
    bg: 'beta.700',
    color: 'white',
    boxShadow: 'none',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    color: 'gray.600',
    border: '2px solid',
    borderColor: 'gray.800',
    boxShadow: 'lg',
  },

  ...(isActive && {
    bg: 'beta.200',
    color: 'beta.800',
    textShadow: 'none',
    boxShadow: 'inset 0px 2px 4px #A97F81',

    '&:focus, &:hover': {
      color: 'white',
      bg: 'beta.700',
      boxShadow: 'none',
    },
  }),
});

export const LinkRaceDetails: CSSObject = {
  color: 'white',
  bg: 'beta.500',
  textTransform: 'uppercase',

  _hover: {
    bg: 'beta.800',
  },
};
