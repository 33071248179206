import { rgba } from 'polished';
import { css } from 'styled-components';

export const container = css`
  background-image: ${({ theme }) => theme.palette.bgGradientSecondary};
  box-shadow: 0 2px 10px 12px rgba(0, 0, 0, 0);
`;

export const containerActive = css`
  background-image: ${({ theme }) => theme.palette.bgGradientSecondary};
  box-shadow: 0 2px 10px 12px rgba(0, 0, 0, 0.5);
  max-width: 230px;
`;

export const headerContainer = css`
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.basePalette.lightBlue, 0.2)};
`;

export const closeIconContainer = css``;

export const closeIcon = css`
  color: ${({ theme }) => theme.palette.white};
`;

export const item = css`
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.basePalette.lightBlue, 0.2)};
`;

export const link = css`
  text-decoration: none;
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.basePalette.lightBlue, 0.2)};
  padding: var(--bc-sizes-3) var(--bc-sizes-4);
`;

export const linkIndented = css`
  text-decoration: none;
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.basePalette.lightBlue, 0.2)};
  font-style: italic;
  padding: var(--bc-sizes-3) var(--bc-sizes-4) var(--bc-sizes-3)
    var(--bc-sizes-9);
`;

export const itemText = css``;

export const itemTextHighlight = css`
  color: ${({ theme }) => theme.basePalette.brightBlueB};
`;

export const itemTextHighlightSecondary = css`
  color: ${({ theme }) => theme.basePalette.blue};
`;

export const backgroundBlock = css``;

export const itemImg = css`
  height: var(--bc-sizes-5);
  width: var(--bc-sizes-5);
  color: ${({ theme }) => theme.basePalette.brightBlueB};
  margin-right: var(--bc-sizes-1);
`;
