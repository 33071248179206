import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTemplate } from '@/helpers/createTemplate';
import { useVerifyIdentity } from '../../../VerifyIdentity/Services/Hooks.VerifyIdentity';
import { isVerified } from '../../services/Overview.utils';
import template from './templates/template';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { usePunterData } from '@/store/AuthStore';

type VerificationStatusButtonProps = {
  isHeader?: boolean;
};

const VerificationStatusButton: FC<VerificationStatusButtonProps> = ({
  isHeader,
}) => {
  const navigate = useNavigate();
  const punterProfile = usePunterData();
  const { verificationStatus } = useAppSelector((state) => state.punter);
  const punterPreviouslyVerified = punterProfile?.identity_verified;
  useVerifyIdentity(false, punterPreviouslyVerified);

  if (
    isHeader &&
    (punterPreviouslyVerified || isVerified(verificationStatus))
  ) {
    return <></>;
  }

  return (
    <>{createTemplate(template(navigate, verificationStatus, isHeader))}</>
  );
};

export default VerificationStatusButton;
