import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: {
    px: 2.5,
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
  },
  buttonCashOutAmount: { px: 2 },
  flexOddsBoostBanner: {
    bg: 'linear-gradient(to, #30363F 0%, #21252B 100%)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.25)',

    borderTop: 'none',
    fontSize: 'md',
  },
  textOddsBoost: {
    color: 'alpha.500',
    fontWeight: 'black',
    fontFamily: 'CrimsonPro',
  },
  iconCashOut: {
    fill: 'beta.500',
  },
  cashoutText: {
    color: 'beta.500',
    textShadow: 'none',
    fontSize: 'sm',
  },
  cashoutValue: {
    color: 'beta.500',
    textShadow: 'none',
    fontSize: 'sm',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'alpha.600',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: 700,
};

export const RaceTime: CSSObject = {
  color: 'gamma.100',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: 500,
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'alpha.600',
  color: 'beta.300',
};
export const BonusBetText: CSSObject = {
  color: 'alpha.600',
  fontSize: '13px',
  fontWeight: 600,
};

export const BetReturn: CSSObject = {
  maxH: '24px',
  borderRadius: '4px',
  border: '1px',
  borderColor: 'beta.500',
  bg: 'gamma.50',
  boxShadow: '0px 0px 5px 0px rgba(12, 30, 90, 0.60) inset',
  alignItems: 'center',
};

export const BoxBetReturnText: CSSObject = {
  pl: '1.5',
  pr: '1',
  color: 'beta.500',
  fontSize: '10px',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
};

export const BoxBetReturnValue: CSSObject = {
  px: '1',
  color: 'white',
  fontSize: '10px',
  fontWeight: 'bold',
  bg: 'beta.500',
  h: '24px',
  alignItems: 'center',
  display: 'flex',
  borderRightRadius: 'base',
};

export const iconLegProps: IconProps = {
  boxSize: '18px',
};
