import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {},
  },
  iconButtonScrollRight: {
    variant: 'secondary',
    h: 7,
    w: 'auto',
    minW: 'auto',
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {},
  },
  iconButtonScrollLeft: {
    variant: 'secondary',
    h: 7,
    w: 'auto',
    minW: 'auto',
  },
  flexWrapper: {
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  },
  buttonGroupSports: {
    borderRadius: 'base',
    display: 'inline-flex',
    flex: '1',
    overflowX: 'auto',
    pl: ['1', '4'],
    py: '5px',
    spacing: '1',
    whiteSpace: 'nowrap',
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    p: '1',
    gap: 1,
    isAttached: true,
    alignItems: 'center',
  },
  scrollIndicator: {
    display: 'none',
  },
};
