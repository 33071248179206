import React from 'react';
import { createTemplate } from '@/helpers/createTemplate';
import GlobalStyles from './conditionals/GlobalStyles/template';
import template from './templates/template';
import ThemeToggle from '@/components/ThemeToggle/ThemeToggle';

export default function SecondaryLayout() {
  return (
    <>
      <GlobalStyles />

      <ThemeToggle />

      {createTemplate(template)}
    </>
  );
}
