/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';

const template: TTemplate = {
  wrapper: <Box as="span" />,
};

export default template;
