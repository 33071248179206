import { CSSObject } from '@chakra-ui/react';
import errorBg from '@/assets/gigabet/images/error/bg.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  justifyContent: 'center',
  bgImage: `url(${errorBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
});
export const MaintenanceContainer = (): CSSObject => ({
  alignItems: 'center',
  color: 'white',
  contain: 'paint',
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  maxW: 'container.md',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',
  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },
});
export const ContentHeadingText: CSSObject = {
  w: ['278px', '468px'],
  color: 'alpha.400',
  fontFamily: 'accent',
  fontSize: '32px',
  lineHeight: 'none',
  mb: '4',
  mt: '4',
};
export const ContentBodyText: CSSObject = {
  fontSize: ['sm', null, 'md'],
  mb: '4',
  span: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'semibold',
    mb: '4',
  },
};
export const buttonRefreshProps: CustomButtonProps = {
  variant: 'quickAccess',
  minW: '102px',
  pt: '0.5',
  fontSize: ['xs', null, 'sm'],
};
export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'white',
};
