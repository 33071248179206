import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/fiestabet/images/homeFeaturedSport/featSport.png';
import featuredMb from '@/assets/fiestabet/images/homeFeaturedSport/featSportMb.png';

export const FlexWrapper: CSSObject = {
  p: '0',
  pl: '0',
  h: ['520px', '300px'],
  border: 'none',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: ['contain', 'cover', '100%'],
  alignItems: 'center',
  justifyContent: 'flex-start',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['0', '2'],
  mt: '4',
  flexDir: ['column', 'row'],
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: '1',
};

export const TextVS: CSSObject = {
  bgGradient: 'linear(to-b, delta.300 22.92%, delta.600 91.67%)',
  boxShadow:
    '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 1px rgba(255, 255, 255, 0.5)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  color: 'delta.800',
  fontSize: '13px',
  fontWeight: 'black',
};

export const TextMisc: CSSObject = {
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.40), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'md',
  color: 'gamma.100',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  fontWeight: 'extrabold',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'extrabold',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  maxW: ['fit-content', null, '380px'],
  mx: ['auto', 'unset'],
  ml: ['auto', '10%'],
  mt: ['2', '4'],
};

export const HeadingSlogan: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  fontFamily: 'Ultra',
  color: 'alpha.400',
  textTransform: 'uppercase',
  fontSize: ['22px', null, '30px'],
  lineHeight: '1',
  textShadow: '0px 3px 0px #5C0508, 0px 4px 4px rgba(0, 0, 0, 0.25)',
  pt: '3',
};

export const SpanSlogan: CSSObject = {
  color: 'delta.500',
  fontSize: ['34px', null, '46px'],
  pl: '2',
};

export const puntButtonProps: CSSObject = {
  border: '2px solid',
  borderColor: 'epsilon.500',
  boxShadow:
    '0px 2px 5px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.7)',
  borderRadius: 'lg',
  color: 'beta.500',
};
