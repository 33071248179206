import { FooterSchema } from './Footer.styles';

export const footerStyles: FooterSchema = {
  footer: {
    position: 'relative',
    mt: ['6', null, null, '3'],
    pt: '0',
    bgSize: 'cover',
    bgPos: 'bottom',
    color: 'white',
  },
  stackGap: '0',
  footerBar: {
    display: 'flex',
    flexDir: ['column', null, 'row'],
    h: '70px',
    pr: '0',
    mb: ['12', '6'],
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: ['center', null, 'unset'],
  },
  footerDownloadImage: {
    border: 'none',
    borderRadius: 'md',
    filter: 'drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.3))',
  },
  footerLink: {
    color: 'alpha.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
  },
  footerLinkService: {
    color: 'alpha.500',
  },
  footerLinkTel: {
    color: 'alpha.500',
    textTransform: 'lowercase',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
  },
  customFooterLink: {
    color: 'alpha.500',
    fontSize: 'sm',
    textTransform: 'capitalize',
    cursor: 'pointer',
    display: 'inline-block',
    mt: '0',
    whiteSpace: 'nowrap',
  },
  weAcceptBox: {
    mb: '4',
  },
  footerLinks: {
    mt: ['4', null, '0'],
    ml: ['unset', null, 'auto'],
    pb: ['4', null, '0'],
    alignItems: 'center',
    justifyContent: ['space-around', null, 'unset'],
    color: 'white',
    fontWeight: 'medium',
    flexDir: ['row', null, 'unset'],
    display: 'inline-flex',
  },
  footerMobile: {
    borderRadius: 'md',
    border: '2px solid',
    borderColor: 'white',
    _last: {
      ml: '1.5',
    },
    w: '32',
  },
  footerText: {
    color: 'white',
  },
  ourPartners: {
    display: 'flex',
    justifyContent: ['center', null, 'inherit'],
    color: 'alpha.500',
    textTransform: 'uppercase',
    fontSize: ['xs', null, '14px'],
    pb: '1',
  },
  weAccept: {
    display: 'flex',
    justifyContent: ['center', null, 'inherit'],
    color: 'alpha.500',
    textTransform: 'uppercase',
    fontSize: ['xs', null, '14px'],
    pb: '1',
  },
  footerContentContainer: {
    px: ['2.5', null, 'unset'],
    gap: ['0', '6'],
    bg: [null, null, 'transparent'],
    justifyContent: 'space-between',
    sx: {
      '&': {
        flexDirection: 'column',
      },
    },
  },
  imageWrapper: {
    mr: '0',
    justifyContent: 'center',
  },
  partnerWrapper: {
    mr: '0',
  },
  partnerImages: {},
  quickLinks: {
    color: 'alpha.500',
    textTransform: 'uppercase',
    fontSize: ['xs', null, '14px'],
    display: 'flex',
    justifyContent: ['center', null, 'inherit'],
    mt: ['2', '0'],
    pb: ['1', '0'],
  },
  nav: {
    fontSize: 'sm',
    fontWeight: 'medium',
    minW: [null, null, '370px'],
    mt: 'unset',
    display: ['flex', null, 'grid'],
    flexDir: 'column',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '28',
    rowGap: ['0', null, '1'],
    alignItems: ['center', null, 'end'],
    gridTemplateAreas: `
      ". ."
    `,

    '> a': {
      color: 'inherit',
    },
  },
  blurb: {
    flex: 'unset',
    textAlign: ['center', null, 'left'],
    color: 'white',
    width: 'unset',
    mb: '0',
    ml: [null, '0'],
    mr: [null, null, '0'],
  },
  blurbHeading: {
    color: 'white',
    ml: 'unset',
    mt: '2',
  },
  blurbHeadingAge: {
    borderColor: 'blackAlpha.50',
    color: 'white',
    border: '3px solid',
    mb: '2',
    boxSize: '8',
    fontSize: 'xs',
  },
  blurbHeadingThink: {
    fontSize: 'xs',
    fontWeight: 'bold',
    mb: '4',
  },
  blurbContent: {
    ml: 'unset',
    mr: [null, null, 'auto'],
  },
  copyright: {
    color: 'white',
    textAlign: ['center', null, 'left'],
    mt: '6',
    px: ['8', 'unset'],
    py: '0',
    fontWeight: ['semibold', 'medium'],
  },
  container: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  emailUs: {
    px: ['16', null, '0'],
    fontSize: ['sm', null, 'md'],
    fontWeight: 'bold',
    textAlign: ['center', null, 'left'],
  },
};
