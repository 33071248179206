/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useAuth } from '../../hooks/useAuth';
import { keys } from '../api.keys';
import {
  mutateAddCard,
  mutateDeleteCard,
  queryCards,
  queryDepositLimit,
  queryFatZebraToken,
} from './deposit';
import { TGetFatZebraToken, TResponseCards } from './deposit.types';

type TQueryOptions<T, TQueryKey extends QueryKey> = {
  options?: Omit<
    UseQueryOptions<T, unknown, T, TQueryKey>,
    'queryFn' | 'queryKey'
  >;
};

export const useQueryFatZebraToken = ({
  options = {},
}: TQueryOptions<TGetFatZebraToken, (string | boolean | undefined)[]>) => {
  const { isAuthenticated } = useAuth();

  return useQuery(
    [keys.depositGetFatZebraToken, isAuthenticated],
    queryFatZebraToken,
    {
      staleTime: 60 * 5000,
      enabled: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useQueryCards = ({
  options = {},
}: TQueryOptions<TResponseCards, (string | boolean | undefined)[]>) => {
  const { isAuthenticated } = useAuth();

  return useQuery([keys.depositGetCards, isAuthenticated], queryCards, {
    enabled: !!isAuthenticated,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useQueryDepositLimit = () => {
  const { isAuthenticated } = useAuth();

  return useQuery([isAuthenticated], queryDepositLimit, {
    enabled: !!isAuthenticated,
    refetchOnWindowFocus: false,
  });
};

export const useMutateAddCard = () => {
  const queryClient = useQueryClient();

  return useMutation(mutateAddCard, {
    onSuccess(res) {
      queryClient.setQueriesData(
        [keys.depositGetCards, true],
        (data?: TResponseCards) => [...(data ?? []), res]
      );
    },
  });
};

export const useMutateDeleteCard = () => {
  const queryClient = useQueryClient();

  return useMutation(mutateDeleteCard, {
    onSuccess(_, req) {
      queryClient.setQueriesData(
        [keys.depositGetCards, true],
        (data?: TResponseCards) => data?.filter((x) => x.card_id !== req.id)
      );
    },
  });
};
