import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  body: {
    color: 'beta.800',
    fontWeight: 'normal',
    pt: '2',
  },
  container: {
    bg: 'beta.300',
    boxShadow:
      '0px 2px 2px 0px var(--bc-colors-whiteAlpha-400) inset,0px -2px 4px 0px var(--bc-colors-blackAlpha-200) inset,0px 4px 4px 0px var(--bc-colors-blackAlpha-400)',
    borderRadius: 'lg',
  },
};
