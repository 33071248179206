import { PropositionButtonSchema } from './PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    fontFamily: 'roboto',
    fontSize: '2xs',
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  },
  buttonBaseBox: {
    fontSize: 'sm',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
