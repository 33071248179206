/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createRef, MutableRefObject, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { FlexBetSlipModalContainer, BetSlipContainer } from './Modal.styles';
import Modal from './Modal';
import Header from './Header/Header';

export type BetslipRefProps = {
  portalRef: MutableRefObject<HTMLDivElement | null>;
  betsRef: { id: string; isFocused: boolean; ref: HTMLInputElement }[];
  betsGroupRef: MutableRefObject<HTMLDivElement | null>;
};

export type BetslipRefSchema = MutableRefObject<BetslipRefProps> | undefined;

export default function ModalContainer() {
  const props = useModal();

  return <ModalView {...props} />;
}

type ModalProps = {
  betslipRef: BetslipRefSchema;
};

function ModalView({ betslipRef }: ModalProps) {
  return (
    <>
      <FlexBetSlipModalContainer data-cy="betSlipModalContainer">
        <BetSlipContainer>
          <Header />
          <Modal betslipRef={betslipRef} />

          {/* This Box is for the keyboard portal */}
          <Box
            ref={(el: HTMLDivElement) => {
              if (betslipRef?.current) {
                betslipRef.current.portalRef.current = el;
              }
            }}
          />
        </BetSlipContainer>
      </FlexBetSlipModalContainer>
    </>
  );
}

const useModal = () => {
  const betslipRef = useRef<BetslipRefProps>({
    portalRef: createRef(),
    betsRef: [],
    betsGroupRef: createRef(),
  });

  return {
    betslipRef,
  };
};
