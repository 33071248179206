import { CSSObject } from '@chakra-ui/react';
import bg from '../../../assets/puntgenie/images/backgrounds/bg.jpg';
import bgAtRetina from '../../../assets/puntgenie/images/backgrounds/bg@2x.jpg';

export const LocationNotAllowedContainer: CSSObject = {
  color: 'white',
  h: undefined,
  minH: '100dvh',
  pos: 'relative',

  _before: {
    bg: `url(${bg}) center/cover no-repeat`,
    boxSize: 'full',
    content: '""',
    left: '0',
    opacity: '0.5',
    pos: 'absolute',
    top: '0',
    zIndex: '-1',

    '@media (-webkit-min-device-pixel-ratio: 2)': {
      bg: `url(${bgAtRetina}) center/cover no-repeat`,
    },
  },

  _after: {
    bgGradient: 'linear(to-b, transparent, epsilon.700)',
    boxSize: 'full',
    content: '""',
    left: '0',
    pos: 'absolute',
    top: '0',
    zIndex: '-1',
  },
};

export const CenterWrapper: CSSObject = {
  display: 'block',
  flexDir: undefined,
  fontSize: ['sm', 'md', 'lg'],
  gap: undefined,
  maxW: '60ch',
  mt: undefined,
  p: '4',
  textAlign: 'center',
};

export const ContentHeadingText: CSSObject = {
  bgClip: 'text',
  bgGradient: 'linear-gradient(to-b, delta.400, delta.600)',
  fontFamily: 'CinzelDecorative',
  fontSize: ['4xl', null, '5xl'],
  fontWeight: 'black',
  lineHeight: 'none',
  mb: '4',
  textAlign: undefined,
  textFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

export const ContentBodyText: CSSObject = {
  fontSize: undefined,

  b: {
    display: 'block',
    fontSize: 'md',
    mb: '3',
  },

  '.chakra-link': {
    fontSize: 'inherit',
    fontWeight: 'semibold',
    textDecoration: 'underline',
  },
};

export const RegistrationContent: CSSObject = {
  fontSize: 'xs',
};
