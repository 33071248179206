import { BoxProps, CSSObject, LinkProps } from '@chakra-ui/react';

export const wrapperProps: BoxProps = {
  sx: {
    'div:first-child': {
      display: 'block',
      color: 'beta.400',
      fontSize: 'sm',
      fontWeight: 'medium',
      mb: '4',
      alignItems: 'center',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mt: '-1px',
      },
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'gray.500',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',

  '+ a': {
    color: 'gray.500',
    fontSize: 'sm',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'medium',
    mx: 0,
    display: 'inline',
  },
};

export const linkLoginProps: LinkProps = {
  color: 'beta.400',
  fontSize: 'sm',
  fontWeight: 'semibold',
  mb: '4',
  display: 'inline-flex',
  alignItems: 'center',

  sx: {
    svg: {
      boxSize: '2',
      mr: '1',
      mt: '-1px',
    },
  },
};
