import { FontFamily, FontScale, FontWeight } from '../../../types';

// TODO: Add font family name here
export const hexBetFontFamily: FontFamily = {
  heading: 'var(--bc-fonts-heading)',
  headingAlt: '"Roboto Bold", sans-serif',
  body: 'var(--bc-fonts-body)',
  bodyAlt: '"Roboto", sans-serif',
  bodyLight: '',
};

// What scale adapts to the font-size relationship (golden ratio, minor third, perfect fourth etc.)
export const hexBetFontScale: FontScale = {
  XXS: 'var(--bc-fontSizes-xs)',
  XS: 'var(--bc-fontSizes-xs)',
  S: 'var(--bc-fontSizes-sm)',
  M: 'var(--bc-fontSizes-md)',
  L: 'var(--bc-fontSizes-lg)',
  XL: 'var(--bc-fontSizes-xl)',
  XXL: 'var(--bc-fontSizes-2xl)',
};

// Font Weight
export const hexBetFontWeight: FontWeight = {
  extraLight: 150,
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
  black: 800,
};
