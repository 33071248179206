import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '3.5',
  mt: ['-3', null, '-5'],
  px: ['2', '0'],
  top: ['0px', '9'],
};

export const ButtonFilter = ({ selected }: TSelected) => ({
  color: selected ? 'beta.500' : 'gray.300',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'beta.500' : 'gray.300',
  color: selected ? 'white' : 'transparent',
});

export const IconRace = () => ({
  boxSize: '6',
});

export const TextFilterLabel: CSSObject = {
  color: 'beta.500',
  fontWeight: 'bold',
  fontSize: '2xs',
};

export const Divider: CSSObject = {
  bg: 'gamma.500',
};
