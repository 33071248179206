import React, { forwardRef, FunctionComponent } from 'react';
import {
  FormLabelProps,
  InputProps,
  FormErrorMessageProps,
  HelpTextProps,
  CSSObject,
} from '@chakra-ui/react';
import InputComponent from './Input';
import HOCs from '../HOCs';

export type TIndex = {
  name: string;
  isFormik?: boolean;
  label?: string;
  error?: string;
  helperText?: string;
  propsLabel?: FormLabelProps;
  propsError?: FormErrorMessageProps;
  propsHelper?: HelpTextProps;
  sxWrapper?: CSSObject;
  InputLeftAddon?: string;
  InputRightAddon?: string;
  InputRightElement?: FunctionComponent;
  InputLeftElement?: FunctionComponent;
  allowInvalidKeys?: boolean;
  ref?: any;
} & InputProps;

const Input = forwardRef(
  (
    {
      isFormik = true,
      name,
      label,
      error,
      helperText,
      propsLabel,
      propsError,
      propsHelper,
      sxWrapper,
      allowInvalidKeys,
      ...rest
    }: TIndex,
    ref?: any
  ) => {
    const id = `${name}-input`;

    return (
      <HOCs
        {...{
          isFormik,
          id,
          name,
          label,
          error,
          helperText,
          propsLabel,
          propsError,
          propsHelper,
          sxWrapper,
          allowInvalidKeys,
        }}
      >
        <InputComponent
          id={id}
          name={name}
          ref={ref}
          allowInvalidKeys={allowInvalidKeys}
          {...rest}
        />
      </HOCs>
    );
  }
);

export default Input;
