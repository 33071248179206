import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'odds',
    fontFamily: 'body',
    color: 'alpha.800',
    textTransform: 'uppercase',
    fontSize: '2xs',
    fontWeight: 'bold',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.40)',
    px: '2',
    _hover: {
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontSize: '2xs',
      fontWeight: 'bold',
    },
  },
}

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'gamma.200',
};
