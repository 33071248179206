import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  bg: (() => {
    if (state === 'ended') return 'beta.500';
    if (state === 'inprogress') return 'alpha.300';
    return 'transparent';
  })(),
  color: state === 'inprogress' ? 'alpha.900' : 'beta.800',
  py: 0,
  '&&': {
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
    fontFamily: 'Montserrat',
  },
});

export const TextTimestamp = {
  fontWeight: 'semibold',
  fontSize: '11px',
};

export const CountdownFlex: CSSObject = {
  color: 'white',
};
