import { BoxProps, FlexProps } from '@chakra-ui/react';
import { styleImports } from './PersonalDetails.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type PersonalDetailsSchema = Partial<{
  wrapperBox: BoxProps;
  betslipPromoWrapper: FlexProps;
}>;

export const personalDetailsStyles: PersonalDetailsSchema = {
  betslipPromoWrapper: {
    'data-testid': 'personal-details-betslipPromoWrapper',

    ...styleImports[themeName]?.personalDetailsStyles?.betslipPromoWrapper,
  },
  wrapperBox: {
    'data-testid': 'personal-details-wrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.personalDetailsStyles?.wrapperBox;
      } catch (err) {
        return {};
      }
    })(),
  },
};
