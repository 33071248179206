import { CSSObject } from '@chakra-ui/react';

export const LoginButton: CSSObject = {
  color: 'blackAlpha.700',
};

export const LinkSignup: CSSObject = {
  display: 'none',
};

export const LinkForgotPassword: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'medium',
};
