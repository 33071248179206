import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    fontSize: 'md',
    fontFamily: 'body',
    color: 'beta.600',
    fontWeight: 'medium',
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
    px: [0, 3],
  },
  navOverride: {
    pb: '0',
  },
  divider: {
    mb: '0',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
};
