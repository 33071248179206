import { CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};

export const DepositButton: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  h: '38px',
  lineHeight: 'none',
  pos: 'relative',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',
  fontFamily: 'Fredoka',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },

  _active: {
    bgGradient: 'linear(to-b, alpha.800, alpha.800)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'alpha.300',
    textShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.45)',
  },
};

export const CancelButton: CSSObject = {
  border: '2px solid',
  borderColor: 'white',
  fontSize: 'sm',
  bg: 'transparent',
  color: 'white',
  fontFamily: 'Fredoka',
  fontWeight: 'black',
  boxShadow: 'none',
  textShadow: 'none',

  '&:hover': {
    bg: 'beta.700',
    color: 'blackAlpha.600',
    borderColor: 'transparent',
    boxShadow: 'none',
    textShadow: 'none',
  },

  _active: {
    bg: 'beta.700',
  },
};

export const ButtonSubmit: CSSObject = {
  fontFamily: 'Fredoka',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  h: '38px',
  lineHeight: 'none',
  pos: 'relative',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },

  _active: {
    bgGradient: 'linear(to-b, alpha.800, alpha.800)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'alpha.300',
    textShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.45)',
  },
};

export const DepositLimitContainer: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  p: '4',
  color: 'white',
  fontWeight: '700',
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '& .chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__left-addon': {
    borderLeftRadius: 'base',
    fontSize: 'sm',
    fontWeight: 'bold',
    bg: 'beta.600',
    color: 'white',
    h: '38px',
    w: '41px',
    justifyContent: 'center',
  },
  '.chakra-input': {
    borderLeftRadius: 'none',
    bg: 'beta.100',
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 'bold',
    borderRightRadius: 'base',
    h: '38px',
  },
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalDivider: CSSObject = {
  display: 'none',
};
