import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Input, Skeleton, Spacer, Text } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { Form, FormikProvider, useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  ButtonCancel,
  ButtonCard,
  ButtonConfirm,
  ButtonQuickDeposit,
  FlexQuickDeposit,
  IconLeftQuickDepositBtn,
  IconRightQuickDepositBtn,
  QuickDepositBody,
  QuickDepositFooter,
  QuickDepositHeader,
  TextQuickDepositBtn,
} from './Styles/QuickDeposit.styles';
import { TResponseCard, TResponseCards } from '@/api/deposit/deposit.types';
import { PaymentStateNotification } from './PaymentStateNotification/PaymentStateNotification';
import { getStrings } from '@/helpers/utils';
import { useQueryCards } from '@/api/deposit/deposit.hooks';
import { useFatZebraToken } from '@/views/account/Deposit/lib/fatzebra';
import { handleDeposit } from '@/views/account/Deposit/lib/deposit';

type TQuickDepositProps = {
  onClose: () => void;
  onProcessing: (isProcessing: boolean) => void;
};

export type TFormikValues = {
  amount: number;
  card: TResponseCard;
  result?: boolean;
};

export const QuickDeposit = ({ onClose, onProcessing }: TQuickDepositProps) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [{ Generic, Account }] = getStrings();
  const {
    Deposit: { Validation },
  } = Account;

  const loadFatZebraToken = useFatZebraToken();

  const { isLoading: isCardsLoading, error } = useQueryCards({
    options: {
      onSuccess(cards: TResponseCards) {
        const card = cards.find(
          (x) =>
            x.card_token === localStorage.getItem('last_selected_card_token')
        );
        if (card) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-void
          void formik.setFieldValue('card', card);
        }
      },
      onError() {
        toast.error(
          intl.formatMessage({
            id: 'account.deposit.toasts.unabletogetcards',
          })
        );
      },
    },
  });

  const handleSubmit = async (
    { amount, card }: TFormikValues,
    { setFieldValue }: FormikHelpers<TFormikValues>
  ) => {
    onProcessing(true);
    try {
      await handleDeposit(loadFatZebraToken, card, amount);
      setFieldValue('result', true);
    } catch (e) {
      setFieldValue('result', false);
    }
  };

  const formik = useFormik<TFormikValues>({
    initialValues: {
      amount: 0,
      card: {
        card_id: 'addCard',
        card_token: 'addCard',
        card_expiry: '',
        card_type: '',
        last_4: '',
      },
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(10, Validation.MinAmount)
        .max(100_000_000, Validation.MaxAmount)
        .required(Generic.Required),
      card: Yup.object().required(Generic.Required),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        {(formik.isSubmitting || formik.values.result !== undefined) && (
          <PaymentStateNotification
            isProcessing={formik.isSubmitting}
            isSuccessful={!!formik.values.result}
          />
        )}

        <Skeleton isLoaded={!isCardsLoading && !error}>
          <FlexQuickDeposit>
            <Input name="amount" hidden />
            <Input name="card" hidden />

            {formik.values.amount !== 0 ? (
              <>
                <QuickDepositHeader>
                  <Text>
                    <FormattedMessage
                      id="qam.quickDeposit.confirm.msg"
                      values={{
                        amount: () => (
                          <Text as="span" fontWeight="strong">
                            {formik.values.amount}
                          </Text>
                        ),
                      }}
                    />
                  </Text>
                </QuickDepositHeader>

                <QuickDepositBody>
                  <ButtonCancel
                    onClick={() => formik.setFieldValue('amount', 0)}
                  >
                    <FormattedMessage id="qam.quickDeposit.cancel" />
                  </ButtonCancel>
                  <Spacer />
                  <ButtonConfirm type="submit" isLoading={formik.isSubmitting}>
                    <FormattedMessage id="qam.quickDeposit.confirm" />
                  </ButtonConfirm>
                </QuickDepositBody>
              </>
            ) : (
              <>
                <QuickDepositHeader>
                  <FormattedMessage id="qam.quickDeposit.desc" />
                  <Spacer />
                  <ButtonCard
                    onClick={() => {
                      onClose();
                      navigate('/account/deposit');
                    }}
                    data-cy="btn-qa-btncard"
                    variant="link"
                  >
                    {formik.values.card.card_token !== 'addCard' ? (
                      <>
                        <IconLeftQuickDepositBtn
                          name={
                            formik.values.card.card_type.toLocaleLowerCase() ===
                            'mastercard'
                              ? 'mastercard'
                              : 'visa'
                          }
                        />
                        <TextQuickDepositBtn>
                          {formik.values.card.last_4}
                        </TextQuickDepositBtn>
                      </>
                    ) : (
                      <TextQuickDepositBtn>
                        <FormattedMessage id="qam.quickDeposit.btnCard" />
                      </TextQuickDepositBtn>
                    )}
                    <IconRightQuickDepositBtn name="angleRightSolid" />
                  </ButtonCard>
                </QuickDepositHeader>

                {formik.values.card.card_token !== 'addCard' && (
                  <QuickDepositBody>
                    <ButtonQuickDeposit
                      onClick={() => formik.setFieldValue('amount', 50)}
                    >
                      <FormattedNumber
                        value={50}
                        style="currency"
                        currency="AUD"
                      />
                    </ButtonQuickDeposit>
                    <Spacer />
                    <ButtonQuickDeposit
                      onClick={() => formik.setFieldValue('amount', 100)}
                    >
                      <FormattedNumber
                        value={100}
                        style="currency"
                        currency="AUD"
                      />
                    </ButtonQuickDeposit>
                    <Spacer />
                    <ButtonQuickDeposit
                      onClick={() => formik.setFieldValue('amount', 200)}
                    >
                      <FormattedNumber
                        value={200}
                        style="currency"
                        currency="AUD"
                      />
                    </ButtonQuickDeposit>
                    <Spacer />
                    <ButtonQuickDeposit
                      onClick={() => {
                        onClose();
                        navigate('/account/deposit');
                      }}
                    >
                      <FormattedMessage id="qam.quickDeposit.otherAmount" />
                    </ButtonQuickDeposit>
                  </QuickDepositBody>
                )}
              </>
            )}

            <QuickDepositFooter>
              {formik.values.card.card_token === 'addCard' ? (
                <FormattedMessage
                  id="qam.quickDeposit.footer.addCard"
                  values={{
                    underline: (text: string) => (
                      <Text as="u" fontWeight="bold">
                        {text}
                      </Text>
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="qam.quickDeposit.footer.chargeCard"
                  values={{
                    maskedcard: () => (
                      <Text as="span">{formik.values.card.last_4}</Text>
                    ),
                  }}
                />
              )}
            </QuickDepositFooter>
          </FlexQuickDeposit>
        </Skeleton>
      </Form>
    </FormikProvider>
  );
};
