import { useField } from 'formik';
import React, { cloneElement, ReactElement } from 'react';

export type TFormikHOC = {
  name: string;
  children: ReactElement;
};

/**
 * HOC to pass in the useField hook into the field component.
 * If the input has isFormik={false} this will not get rendered.
 */
export default function FormikHOC({ name, children }: TFormikHOC) {
  const field = useField({ name });

  return <>{cloneElement(children, { field })}</>;
}
