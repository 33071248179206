import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '8',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'beta.500',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
          color: 'white',
          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
          border: 'none',
        },
      },

      '&[data-closed="true"]': {
        bg: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontWeight: 'semibold',
  color: 'beta.700',
  fontSize: '2xl',
  textTransform: 'capitalize',
};

export const TextRaceConditions: CSSObject = {
  color: 'beta.700',
};

export const RaceAndTimerContainer: CSSObject = {
  mb: ['1.5', null],
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
};

export const RaceDetailsHeading: CSSObject = {
  whiteSpace: 'nowrap',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'beta.400',
    },
  },
  columnGap: '0',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  mt: ['3', null, '2'],
  mx: ['1', null, '4'],
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
  pt: '3px',

  '.quaddie': {
    borderColor: 'beta.400',
    borderWidth: '2px',
    color: 'beta.500',
    boxShadow: 'none',
    bg: 'white',

    '&:active, &[data-active="true"]': {
      bg: 'beta.300',
    },
  },
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'gray.800',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};