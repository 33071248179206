import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const BoxWrapper: CSSObject = {
  px: '2',
  mt: ['3', null, '0'],
};

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'lg',
  mb: '2',
  border: 'none',
  borderBottomRadius: 'base',

  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',

    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  bg: 'white',
  borderBottomRadius: 'lg',
  p: '2',
  ...(isNested && {
    p: '2',
    bg: 'white',
    borderWidth: '2px',
    borderColor: 'blackAlpha.300',
    borderTop: 'none',
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bg: 'alpha.900',
  color: 'white',
  px: '2',
  py: '2.5',
  border: 'none',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 'lg',
  fontWeight: 'bold',
  fontSize: 'sm',

  _hover: {
    bg: 'alpha.600',
  },

  '.chakra-accordion__icon': {
    boxSize: '5',
    border: '1px',
    borderColor: 'white',
    borderRadius: 'full',
    color: 'white',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    borderBottomRadius: 'none',
  },

  ...(isNested && {
    bg: 'alpha.900',
    boxShadow: '0px 1px 0px 0px rgba(105, 109, 219, 0.20)',
    color: 'white',
    px: '2',

    '.chakra-accordion__icon': {
      boxSize: ['4', '5'],
      border: '1px',
      borderRadius: 'full',
      transition: '.3s ease-in-out',
    },
  }),
});
