import React from 'react';
import { Icon } from '@chakra-ui/react';
import { Flask } from '@styled-icons/boxicons-solid/Flask';

import IconSvg from '../../../../common/IconSvg';
import { TSideIcon } from '../../../services/NavIcon.types';

export const HomeIcon = ({ styles }: TSideIcon) => (
  <Icon as={Flask} className="svgIcon" sx={styles.icon} />
);

export const DepositIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Deposit" className="svgIcon" sx={styles.icon} />
);

export const PromoIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="Promo" className="svgIcon" sx={styles.icon} />
);

export const ContactIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="ContactUs" className="svgIcon" sx={styles.icon} />
);

export const MyAccountIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="MyAccount" className="svgIcon" sx={styles.icon} />
);

export const ResponsibleGamblingIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="ResponsibleGambling" className="svgIcon" sx={styles.icon} />
);
