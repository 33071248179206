import { CSSObject } from '@chakra-ui/react';

export const OddsChangedContainer = (
  isInFooter: boolean,
  direction: string
): CSSObject => ({
  border: '2px',
  borderColor: direction === 'increase' ? 'green.800' : '#cea045',
  display: isInFooter ? 'none' : 'flex',
  minH: '12',
  p: '2.5',
  mb: '1',
  mx: '1',
  alignItems: 'center',
  justifyItems: 'center',
  borderRadius: 'lg',
});
