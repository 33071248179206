import React from 'react';
import { Center, Img } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import locationUnavailable from '../../../assets/puntgenie/images/mascots/locationUnavailable.png';
import logo from '../../../assets/puntgenie/images/logo.svg';
import Heading from '../components/Heading';
import Body from '../components/Body';
import GlobalStyles from '../../../layouts/SecondaryLayout/conditionals/GlobalStyles/template';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <Center flex="1" />,
    content: {
      wrapper: <CenterWrapper />,
      logo: (
        <Img src={logo} alt={REACT_APP_THEME_NAME} mb="4" mx="auto" w="177px" />
      ),
      heading: <Heading />,
      desc: <Body />,
    },
  },
  mascots: (
    <Img
      src={locationUnavailable}
      alt=""
      display={['none', null, 'block']}
      mx="auto"
      w="1024px"
    />
  ),
  body: <GlobalStyles />,
};

export default template;
