import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  // There's no token for these
  bg: selected ? '#6CFFE5' : 'blackAlpha.500',
  border: selected ? '1px solid #C9FFF5' : 'none',
  mt: '1',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  borderLeftRadius: index === 0 ? '12' : '0',
  pl: index === 0 ? '0.5' : '0',
  borderRightRadius: index === numberOfSlides - 1 ? '12' : '0',
  pr: index === numberOfSlides - 1 ? '0.5' : '0',
});

export const CarouselWrapper: CSSObject = {
  '& .carousel .slider-wrapper ': {
    w: ['99%', null, '880px'],
  },
};
