import { CSSObject } from '@chakra-ui/react';
import toucan from '@/assets/junglebet/images/header/toucan3x.png';
import wood from '@/assets/junglebet/images/backgrounds/quickAccessMenuBg.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxBallance: CSSObject = {
  bgImage: wood,
  mx: '1',
  borderBottomRadius: 'base',
  '> div:first-of-type': {
    color: 'beta.500',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
  '> div:last-of-type': {
    color: 'white',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  fontFamily: 'CarterOne',
  fontSize: '15px',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.65)',
  w: 'full',
  mt: '2',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px #7F6514 inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.50) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
  sx: {
    '&&': {
      _hover: {
        bg: 'beta.700',
        boxShadow:
          '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px #7F6514 inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.50) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
      },
    },
    '& svg': {
      display: 'none',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
      mb: '1',
    },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  bg: 'white',
  color: 'gray.700',
  borderTop: '1px',
  borderColor: 'gamma.200',

  '&& > p': { transition: 'all 0.2s ease-in-out' },
  ':first-child': {
    borderTop: 'none',
  },
  '&:hover, &:active': {
    p: { transform: 'translate(2px, 0)' },
    bg: 'gray.100',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${toucan})`,
  bgSize: 'contain',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '1',
  display: ['none', null, null, null, 'block'],
  maxW: '92px',
  maxH: '78px',
  w: '92px',
  h: '78px',
  pos: 'absolute',
  bottom: '-4',
  left: '-28',

  'body[data-sensitive="true"] &': {
    display: 'none',
  },
};

export const PopoverHeaderQA: CSSObject = {
  bgImage: wood,
  mx: '1',
  mt: '1',
  border: 'none',
  borderTopRadius: 'base',
  pb: '0',
  color: 'white',
  lineHeight: 'normal',
};

export const PopoverContentQA: CSSObject = {
  bg: 'white',
  boxShadow: '2xl',
  _focus: {
    boxShadow: '2xl',
  },
};

export const FlexBallanceItem: CSSObject = {
  color: 'white',

  p: {
    _last: { fontWeight: 'extrabold' },
  },
};

export const TextBalanceValue: CSSObject = {
  color: 'alpha.500',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'sm',
};
