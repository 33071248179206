import Black from '@/assets/junglebet/fonts/Roboto-Black.ttf';
import BlackItalic from '@/assets/junglebet/fonts/Roboto-BlackItalic.ttf';
import Bold from '@/assets/junglebet/fonts/Roboto-Bold.ttf';
import BoldItalic from '@/assets/junglebet/fonts/Roboto-BoldItalic.ttf';
import ExtraLight from '@/assets/junglebet/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '@/assets/junglebet/fonts/Roboto-ThinItalic.ttf';
import Light from '@/assets/junglebet/fonts/Roboto-Light.ttf';
import LightItalic from '@/assets/junglebet/fonts/Roboto-LightItalic.ttf';
import Regular from '@/assets/junglebet/fonts/Roboto-Regular.ttf';
import Italic from '@/assets/junglebet/fonts/Roboto-Italic.ttf';
import SemiBold from '@/assets/junglebet/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '@/assets/junglebet/fonts/Roboto-MediumItalic.ttf';

import CarterOne from '@/assets/junglebet/fonts/CarterOne-Regular.ttf';
import Grimblocks from '@/assets/junglebet/fonts/Grimblocks.otf';

export const fontFace = `
  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLight}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${ExtraLightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${Light}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${LightItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Regular}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${Italic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${SemiBoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${Bold}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${BoldItalic}') format('opentype');
  }

  @font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${Black}') format('opentype');
  }
  @font-face {
    font-family: 'roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${BlackItalic}') format('opentype');
  }

  @font-face {
    font-family: 'CarterOne';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${CarterOne}') format('opentype');;
  }
  @font-face {
    font-family: 'Grimblocks';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${Grimblocks}') format('opentype');;
  }
`;
