import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  bg: 'transparent',
  mb: '3',
  mt: '1',
  mx: ['-2', '0'],
  px: ['2', '0'],
  gap: '1',
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};
