import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: '0',
  '&&': { px: ['2', '4'], pb: ['0', null, '2'], pt: ['2', '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'normal',
  textTransform: 'normal',
  fontSize: 'md',
  color: 'gamma.300',
  fontFamily: 'accent',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
  boxShadow: [
    null,
    null,
    null,
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
  ],
  bg: 'beta.800',
};

export const WrapperContainer: CSSObject = {
  px: ['2', null, '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
};
