import { CSSObject } from '@chakra-ui/react';

export const IconSGM: CSSObject = {
  h: 'auto',
  mr: '2',
  w: '8',
  color: 'yellow.400',
};

export const LinkWrapper: CSSObject = {
  my: '1',
};
export const TextListName: CSSObject = {
  fontSize: ['xs', null, 'sm'],
};
export const TextSpanName = (): CSSObject => ({
  color: 'alpha.700',
});
export const FlexSubTextWrapper: CSSObject = {
  color: 'gray.500',
  fontSize: ['2xs', null, 'xs'],
};
export const IconSport: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
};
export const IconChevron: CSSObject = {
  color: 'alpha.700',
};

export const FlexEndWrapper: CSSObject = {
  fontSize: '2xs',
  '.chakra-badge.state-prior': {
    fontWeight: 'semibold',
    fontSize: '2xs',
    px: '0',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};
