import React, { useState } from 'react';
import { Box, Button, Divider, Flex, Icon, Show } from '@chakra-ui/react';
import {
  NavLink as ReactRouterNavLink,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Plus } from '@styled-icons/fa-solid/Plus';
import { FormattedMessage } from 'react-intl';
import PrimaryWrapper from '@/wrappers/PrimaryWrapper';
import {
  BoxWrapper,
  CompetitionsBySportContainer,
} from './styles/CompetitionsBySport.styles';
import { CompetitionsBySportProps } from './types';
import Header from '../AllSports/components/Header/Header';
import Nav, { NavLink } from '../components/Nav/Nav';
import ScrollButtonGroup from '../components/ScrollButtonGroup/ScrollButtonGroup';
import { useInfiniteQueryUpcomingMatches } from '@/api/sports/upcomingMatches.hooks';
import { useQueryCompetitions } from '@/api/sports/competitions.hooks';
import CompetitionsMenu from '../components/CompetitionsMenu/CompetitionsMenu';
import CompetitionList from '../components/CompetitionList/CompetitionList';
import Well from '../components/Well/Well';
import PageButton from '../components/PageButton/PageButton';
import { competitionBySportV2Styles } from '@/views/sports/CompetitionsBySport/styles/CompetitionBySportV2.styles';

const CompetitionsBySportV2: React.FC<CompetitionsBySportProps> = () => {
  const { search } = useLocation();
  const { sportName } = useParams();
  const [searchParams] = useSearchParams();
  const { sportId } = { sportId: searchParams.get('sportId') };

  const [selectedSport] = useState<string[]>([sportId ?? '']);
  const cachedSelectedCompetition = sessionStorage.getItem(
    `${sportId}-competition`
  );
  const [selectedCompetition, setSelectedCompetition] = useState(
    cachedSelectedCompetition || 'all'
  );

  const queryCompetitions = useQueryCompetitions({ sport_id: sportId ?? '' });
  const competitions = [
    ...(queryCompetitions.data?.data.competitions ?? []),
  ].sort((a, b) => (a.sort_order ?? Infinity) - (b.sort_order ?? Infinity));
  const hasTopLeague = competitions.some((item) => Boolean(item.is_top_league));

  const params = {
    sport_id: selectedSport,
    competition_id: selectedCompetition === 'all' ? [] : selectedCompetition,
    limit: 50,
    top_markets: sportName?.toLowerCase() !== 'soccer',
  };

  const queryUpcomingMatches = useInfiniteQueryUpcomingMatches(params, {
    getNextPageParam: ({ paging }) => paging?.next_offset,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage } =
    queryUpcomingMatches;

  const upcomingMatches = queryUpcomingMatches.data;

  const encodedSportName = encodeURIComponent(sportName ?? '');

  return (
    <PrimaryWrapper
      pageTitle={sportName}
      pageHeader={<Header title={sportName ?? ''} />}
    >
      <CompetitionsBySportContainer>
        <Nav {...competitionBySportV2Styles.containerNav}>
          <NavLink
            as={ReactRouterNavLink}
            to={`/sports/${encodedSportName}${search}`}
            end
          >
            <FormattedMessage id="sports.competitionsBySport.events" />
          </NavLink>
          <NavLink
            as={ReactRouterNavLink}
            to={`/sports/${encodedSportName}/All${search}`}
            end
          >
            <FormattedMessage
              id="sports.competitionsBySport.all"
              values={{ name: sportName }}
            />
          </NavLink>
        </Nav>

        {hasTopLeague && (
          <ScrollButtonGroup
            isDisabled={!upcomingMatches?.pages[0]?.items.length}
            {...competitionBySportV2Styles.scrollButtonGroup}
          >
            <Button
              isActive={selectedCompetition === 'all'}
              onClick={() => {
                sessionStorage.removeItem(`${sportId}-competition`);
                setSelectedCompetition('all');
              }}
              {...competitionBySportV2Styles.scrollButtonGroupButtonAllLeagues}
            >
              <FormattedMessage id="generic.all" />
            </Button>
            {competitions
              .filter((comp) => !!comp.is_top_league)
              .map((competition) => {
                const handleClick = () => {
                  sessionStorage.removeItem(`${sportId}-competition`);

                  setSelectedCompetition((prevSelected) => {
                    if (prevSelected === competition.competition_id)
                      return 'all';

                    sessionStorage.setItem(
                      `${sportId}-competition`,
                      competition.competition_id
                    );
                    return competition.competition_id;
                  });
                };

                return (
                  <Button
                    key={competition.competition_id}
                    isActive={
                      selectedCompetition === competition.competition_id
                    }
                    onClick={handleClick}
                    {...competitionBySportV2Styles.scrollButtonGroupButtonCompetition}
                  >
                    {competition.competition_name}
                  </Button>
                );
              })}
          </ScrollButtonGroup>
        )}

        <Divider {...competitionBySportV2Styles.scrollButtonGroupDivider} />

        <BoxWrapper>
          <Flex {...competitionBySportV2Styles.mainFlexWrapper}>
            <Show above="sm">
              <CompetitionsMenu competitions={competitions} />
            </Show>
            <Box {...competitionBySportV2Styles.competitionListBoxWrapper}>
              <CompetitionList
                marketTypes={upcomingMatches?.pages[0].main_market_types}
                marketCount={
                  upcomingMatches?.pages[0]?.main_market_types?.length ?? 0
                }
                isLoading={queryUpcomingMatches.isLoading}
                events={
                  upcomingMatches?.pages.flatMap((page) => page.items) ?? []
                }
              />
              {hasNextPage && (
                <Well {...competitionBySportV2Styles.hasNextPageWellOverride}>
                  <PageButton
                    isLoading={isFetchingNextPage}
                    onClick={() => fetchNextPage()}
                    {...competitionBySportV2Styles.hasNextPageButtonOverride}
                  >
                    <FormattedMessage id="generic.showmore" />
                    <Icon
                      as={Plus}
                      {...competitionBySportV2Styles.hasNextPageButtonIcon}
                    />
                  </PageButton>
                </Well>
              )}
            </Box>
          </Flex>
        </BoxWrapper>
      </CompetitionsBySportContainer>
    </PrimaryWrapper>
  );
};

export default CompetitionsBySportV2;
