import { CSSObject } from '@chakra-ui/react';

export const TextLabel: CSSObject = {
  color: undefined,
};

export const TextInfo: CSSObject = {
  bg: 'gray.50',
  borderColor: 'gray.300',
  color: 'gray.700',
  p: '2.5',
};
