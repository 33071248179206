import { ButtonProps, CSSObject } from '@chakra-ui/react';
import woodTexture from '@/assets/junglebet/images/backgrounds/buttonAccessBg.png';

export const ButtonLogIn: CSSObject = {
  color: 'blackAlpha.700',
  border: 0,
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px #7F6514 inset,0px 0px 0px 5px rgba(255, 255, 255, 0.50) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
  fontSize: 'sm',
  fontFamily: 'CarterOne',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  h: '12',
  px: 3,
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  '&:hover, &:focus': {
    bg: 'beta.700',
  },
};

export const ButtonSignUp: CSSObject = {
  h: '12',
  textTransform: 'capitalize',
  px: 3,
  textShadow: '0px 1px 3px rgba(0, 0, 0, 0.40)',
  fontFamily: 'CarterOne',
  fontWeight: 'normal',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
};

export const ButtonOutsideArea = {
  boxShadow: 'none',
};

export const FlexContainer = () => ({
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },

  w: ['100%', null, 'unset'],
  h: ['auto', null, null, null],
  pt: ['4', null, null, '4', 'unset'],
  mb: '2',
  px: '2',
});

export const ButtonGroup: CSSObject = {
  pb: ['0', null, '4'],
  pos: 'relative',
  gap: '1',
};

export const ButtonGroupBets: CSSObject = {
  gap: '1',
};

const betButtonStyles: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  h: '12',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px #7F6514 inset,0px 0px 0px 5px rgba(255, 255, 255, 0.50) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
  color: 'blackAlpha.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  '.btn-span': {
    flexDir: 'column',
    gap: 0.5,
    alignSelf: 'center',
    lineHeight: 1,
  },

  '&:hover, &:focus': {
    bg: 'beta.700',
    color: 'blackAlpha.700',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    boxShadow: 'inset 0px 3px 4px #000000',
  },

  '.chakra-text:first-child': {
    fontSize: '2xs',
    textTransform: 'uppercase',
    color: 'blackAlpha.800',
  },

  '.chakra-text:last-child': {
    fontSize: ['sm', null, null, null, 'md'],
    fontFamily: 'CarterOne',
    color: 'blackAlpha.800',
  },

  '&&&': {
    borderLeftRadius: 'md',
    borderRightRadius: 'md',
  },
};

export const ButtonBets: CSSObject = betButtonStyles;

export const ButtonBetSlip: CSSObject = betButtonStyles;

export const buttonQuickAccessProps: ButtonProps = {
  h: '12',
  pl: '3',
  pr: ['1', '3'],
  lineHeight: 'unset',
  color: 'blackAlpha.700',
  fontFamily: 'CarterOne',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
  sx: {
    '&:hover, &:focus': {
      bgGradient: 'linear(to-b, alpha.700, alpha.800)',
      color: 'blackAlpha.700',
      boxShadow:
        '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 3px 7px 0px rgba(0, 0, 0, 0.45)',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    },
    '.span-balance': {
      fontSize: 'md',
      fontWeight: 'normal',
      color: 'blackAlpha.800',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    },

    '.span-bonus': {
      fontSize: 'xs',
      fontWeight: 'black',
      fontFamily: 'Roboto',
      color: 'blackAlpha.800',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
      svg: {
        pos: 'relative',
        bottom: '-1px',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
      },
    },

    '&[aria-expanded="true"]': {
      bgImage: woodTexture,
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'black',
  boxSize: 'auto',
  ml: '-1.5',

  '& svg': {
    boxSize: '7',
    color: 'white',
  },
});

export const LinkLogo: CSSObject = {
  maxW: 'unset',
  w: ['auto', null, '180px'],
  display: ['block', null, null, 'none'],
  ml: '0',
};

export const FlexButtonGroup: CSSObject = {
  pb: ['0', null, '2'],
  pos: 'relative',
};

export const TextButtonNumber: CSSObject = {
  fontSize: ['xs', null, 'md'],
};
