import React from 'react';
import { Outlet } from 'react-router-dom';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import DepositLimit from '../../../views/onboarding/DepositLimit';
import { useOnboardedUser } from './services/OnboardedRoute.hook';

/**
 * Onboarded routes only allow users to access the view
 * if their account is fully onboarded. If it is not, the user will
 * be redirected to the account-verification route.
 */
export default function OnboardedRoute() {
  const { isOnboarded } = useOnboardedUser();

  // Redirection to the deposit limit page for web happens within the
  // useOnboardedUser hook. The mobile app uses web views so the
  // DepositLimit component is rendered directly instead of renavigation
  if (isOnboarded === false) return IS_MOBILE_APP ? <DepositLimit /> : null;
  return <Outlet />;
}
