const branding = {
  alpha: {
    50: '#FFF8E6',
    100: '#FEF1CD',
    200: '#FDE39A',
    300: '#FDD568',
    400: '#FCC735',
    500: '#FBB903',
    600: '#C99402',
    700: '#976F02',
    800: '#644A01',
    900: '#322501',
  },
  beta: {
    50: '#F4FDE7',
    100: '#E9FACF',
    200: '#D3F59E',
    300: '#BEF16E',
    400: '#A8EC3D',
    500: '#92E70D',
    600: '#75B90A',
    700: '#588B08',
    800: '#3A5C05',
    900: '#1D2E03',
  },
  gamma: {
    50: '#FFF2E6',
    100: '#FFE5CD',
    200: '#FFCA9A',
    300: '#FFB068',
    400: '#FF9535',
    500: '#FF7B03',
    600: '#CC6202',
    700: '#994A02',
    800: '#663101',
    900: '#331901',
  },
  epsilon: {
    50: '#E9EAEA',
    100: '#D4D6D6',
    200: '#A8ADAC',
    300: '#7D8383',
    400: '#515A59',
    500: '#263130',
    600: '#1E2726',
    700: '#192120',
    800: '#161C1C',
    900: '#111615',
  },
};

export const colors = {
  alpha: branding.alpha,
  beta: branding.beta,
  gamma: branding.gamma,
  theta: {},
  delta: {},
  epsilon: branding.epsilon,
  eta: {},
};
