import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/junglebet/images/backgrounds/backgroundRacing.png';

export const TitleContainer = (): CSSObject => ({
  color: 'white',
  width: 'unset',
  borderTopRadius: '6px',
  bgImage: `url(${background})`,
  mt: ['unset', '4'],
  pb: '0',
  py: ['2', '4'],
  fontFamily: 'CarterOne',
  '&&': { px: ['3', '4'], pb: ['1', null, '2'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'base',
  fontSize: 'md',
  textTransform: 'uppercase',
  color: 'white',
  mx: '0',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'CarterOne',
});

export const BoxHeading: CSSObject = {
  mb: '-0.5',
};

export const BoxWrapper: CSSObject = {
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
  mx: ['2', 'unset'],
};

export const WrapperContainer: CSSObject = {
  bgImage: `url(${background})`,
  pb: '4',
  mb: '3',
  borderTopRadius: 'none',
  borderBottomRadius: 'xl',
  boxShadow:
    '0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 5px 0px 0px var(--bc-colors-delta-800), 0px 2px 2px 0px #D17917, 0px -2px 1px 0px rgba(178, 78, 5, 0.50) inset',
};
