import { Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import footerLogo from '@/assets/goldenbet888/images/footer/logoFooter.png';
import footerLogoMb from '@/assets/goldenbet888/images/footer/logoFooterMb.png';

const LogoImage = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Image
      src={isMobile ? footerLogoMb : footerLogo}
      maxW={['170px', null, '230px']}
      maxH={['95px', null, '40px']}
    />
  );
};

export default function index() {
  return <LogoImage />;
}
