import evenShotBg from '@/assets/junglebet/images/backgrounds/evenShotBg.png';
import evenShot from '@/assets/junglebet/images/evenShotMascot.png';
import { EvenShotSchema } from './EvenShot.styles';

export const evenShotStyles: EvenShotSchema = {
  notAvailableText: {
    color: 'beta.500',
    mt: 0,
  },
  oddsValueSpan: {
    color: 'white',
    sx: {
      '&[data-hasbets="true"]': {
        color: 'beta.500',
      },
    },
  },
  hasBetSubTitleText: {
    fontWeight: 'normal',
  },
  comeBackLaterText: {
    color: 'white',
  },
  notYetAvailableText: {
    color: 'beta.500',
    fontWeight: 'bold',
    mt: 0,
  },
  subHeadingText: {
    fontSize: 'sm',
  },
  betPlacedText: {
    textTransform: 'uppercase',
    color: 'beta.500',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'extrabold',
    fontSize: 'lg',
  },
  oddsText: {
    mt: '0',
    mb: '4',
    fontWeight: 'extrabold',
    fontSize: 'lg',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapper: {
    boxShadow:
      '0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset, 0px 0px 0px 2px #113e04 inset, 0px 0px 0px 2px #FDD101',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flexDir: 'column',
    bg: `linear-gradient(180deg, #2D9D0C 0%, #2D9D0C 0%, #2D9D0C 0.01%, #26830B 100%), url(${evenShotBg})`,
    bgBlendMode: 'overlay, normal',
    bgSize: 'cover',
    minH: 'calc(100vh - 350px)',
  },
  headingText: {
    fontFamily: 'CarterOne',
    fontWeight: 'normal',
    color: 'beta.400',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'uppercase',
    fontSize: '46px',
  },
  addToBetslipButton: {
    variant: 'primary',
  },
  loginToView: {
    variant: 'primary',
  },
  evenShotImage: {
    mt: 4,
    mb: 2,
    boxSize: '146px',
    backgroundImage: `url(${evenShot})`,
    backgroundSize: 'cover',
  },
};
