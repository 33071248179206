import { CSSObject } from '@chakra-ui/react';

export const LoginButton: CSSObject = {
  bg: 'beta.600',
  fontSize: 'sm',
  borderRadius: 'md',
  borderColor: 'beta.400',
  color: 'blackAlpha.600',
  boxShadow:
    'px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  fontFamily: 'Caveman',
  letterSpacing: 'wider',
  _hover: {
    bg: 'beta.700',
    color: 'blackAlpha.600',
  },
  _active: {
    bg: 'beta.700',
  },
};

export const LinkSignup: CSSObject = {
  display: 'none',
};

export const LinkForgotPassword: CSSObject = {
  color: 'gamma.500',
  fontSize: 'xs',
  fontWeight: 'medium',
};
