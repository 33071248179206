import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { CustomSwitcher, TextToggle } from '../../styles/Toggle.styles';

export default function ButtonToggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  const toggleCategory = (newCategory: string) => {
    setSearchParams({ category: newCategory });
  };

  return (
    <>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
        onClick={() => toggleCategory('racing')}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>
      <CustomSwitcher isChecked={category && category !== 'racing'}>
        <span className="chakra-switch__track" />
        <span className="chakra-switch__thumb" />
      </CustomSwitcher>
      <TextToggle
        data-cy="sports-toggle-text"
        isActive={category === 'sport'}
        onClick={() => toggleCategory('sport')}
      >
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </TextToggle>
    </>
  );
}
