import React, { FC, ReactNode } from 'react';
import SeoTitle from '../../components/Seo/SeoTitle';
import { EOnboardingPageType } from './OnboardingWrapper.types';
import { createTemplate } from '@/helpers/createTemplate';
import template from './templates/template';

type TOnboardingWrapper = {
  pageTitle: string;
  aside?: ReactNode;
  pageType?: EOnboardingPageType;
};

const OnboardingWrapper: FC<TOnboardingWrapper> = ({
  children,
  aside,
  pageTitle,
  pageType,
}) => (
  <>
    <SeoTitle title={pageTitle} />
    {createTemplate(template(children, aside, pageType))}
  </>
);

export default OnboardingWrapper;
