import { CSSObject } from '@chakra-ui/react';

export const SideNavHeading: CSSObject = {
  fontFamily: 'SkranjiBold',
  fontSize: 'sm',
  fontWeight: 'thin',
  textTransform: 'uppercase',
  color: '#C19365',
  px: '2.5',
  py: '2.5',
  lineHeight: 'none',
  height: 'auto',
  alignItems: 'end',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.200',
  letterSpacing: 'wider',
  _hover: {
    color: '#C19365',
  },
};
