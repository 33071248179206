import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

const authedButtonStyles: CSSObject = {
  bg: 'gamma.500',
  border: '2px solid',
  borderColor: 'white',
  boxShadow:
    '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 7px rgba(0, 0, 0, 0.25)',
  color: 'blackAlpha.700',
  fontFamily: 'Caveman',
  h: '44px',
  lineHeight: 'none',
  minW: '44px',
  pos: 'relative',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.25)',
  textTransform: 'none',
  w: undefined,
  fontSize: 'lg',
  fontWeight: 'normal',
  borderRadius: 'md',

  _hover: {
    bg: 'gamma.600',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 7px rgba(0, 0, 0, 0.25)',
    color: 'blackAlpha.600',
  },

  '.chakra-text': {
    _first: {
      fontSize: 'sm',
    },

    _last: {
      fontSize: 'lg',
    },
  },
};

const unauthedButtonStyles: CSSObject = {
  bg: 'gamma.500',
  border: '2px solid',
  borderColor: 'white',
  boxShadow:
    '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 7px rgba(0, 0, 0, 0.25)',
  color: 'blackAlpha.600',
  fontFamily: 'Caveman',
  fontSize: 'sm',
  fontWeight: 'normal',
  borderRadius: 'md',
  h: '44px',
  pt: '13px',

  _active: {
    bg: 'alpha.200',
    color: 'gray.900',
  },

  _hover: {
    color: 'blackAlpha.600',
    bg: 'gamma.600',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 7px rgba(0, 0, 0, 0.25)',
  },

  _focus: {
    bg: 'alpha.500',
    boxShadow: '0 2px 0 #bc5a00',
    color: 'white',
  },
};

export const FlexContainer = (isHome: boolean): CSSObject => ({
  alignItems: ['center', null, null, null, 'start'],
  pos: 'relative',
  // Custom bg colour to carousel slide, that should only be visible on home
  bg: isHome ? ['#96d9f4', null, 'transparent'] : 'transparent',
});

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  minW: '111px',
};

export const ButtonGroup: CSSObject = {
  gap: '0.5',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  ...authedButtonStyles,
  alignItems: 'flex-start',
  px: '2',

  sx: {
    '&&': {
      _expanded: {
        borderRadius: 'base',
        bg: 'gray.700',
        borderBottom: 'none',
        borderBottomRadius: 'none',

        '.span-balance': {
          color: 'beta.500',
          textShadow: 'none',
        },

        '.account-text': {
          color: 'beta.500',
          textShadow: 'none',
        },

        '.chakra-button__icon svg': {
          color: 'beta.600',
          fontWeight: 'black',
        },

        _after: {
          bottom: 'auto',
          content: ['normal', null, null, null, '""'],
          h: '100%',
          left: 'auto',
        },
      },
    },

    '.chakra-text': {
      _first: {
        fontSize: 'lg',
        textShadow: '0px 1px 1px rgba(255, 255, 255, 0.25)',
      },
    },

    '.span-bonus': {
      fontFamily: 'Roboto',
      color: 'white',
      fontWeight: 'black',
      fontSize: '2xs',
      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.45))',
      mt: [null, null, null, null, '-0.5'],
    },

    '.chakra-button__icon svg': {
      color: 'blackAlpha.600',
      fontWeight: 'black',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.25)',
    },

    _focus: {
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 7px rgba(0, 0, 0, 0.25)',
    },
  },
};

export const ButtonGroupBets: CSSObject = {
  gap: '0.5',

  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: '0',
  },
};

export const ButtonBets: CSSObject = {
  bg: 'beta.600',
  border: '2px solid',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  color: 'blackAlpha.700',
  fontFamily: 'Caveman',
  h: '44px',
  w: '44px',
  lineHeight: 'none',
  borderRadius: 'md',
  pt: '4',

  '&&': {
    borderColor: 'beta.400',
  },

  '.btn-span': {
    flexDirection: 'column',
    gap: '0',
  },

  '.span-bet': {
    fontSize: 'sm',
    fontWeight: 'normal',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  },
  '.span-bet-count': {
    fontSize: 'lg',
    fontWeight: 'normal',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  },

  _hover: {
    '&&': {
      bg: 'beta.700',
      borderColor: 'transparent',
    },
  },
};

export const ButtonBetSlip: CSSObject = {
  ...authedButtonStyles,
  bg: 'beta.600',
  borderColor: 'beta.400',
  border: '2px solid',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  color: 'blackAlpha.700',
  fontFamily: 'Caveman',
  borderRadius: 'md',
  textTransform: 'uppercase',
  h: '44px',
  w: '44px',
  pt: '4',

  '.btn-span': {
    flexDirection: 'column',
    gap: '0',
  },

  _hover: {
    '&&': {
      bg: 'beta.700',
      borderColor: 'transparent',
    },
  },

  _active: {
    bg: 'alpha.500',
    boxShadow: '0 2px 0 #bc5a00',
  },
};

export const ButtonLogIn: CSSObject = unauthedButtonStyles;

export const ButtonSignUp: CSSObject = {
  ...unauthedButtonStyles,
  bg: 'beta.600',
  borderColor: 'beta.400',
  border: '2px solid',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  color: 'blackAlpha.600',
  fontFamily: 'Caveman',
  lineHeight: 'none',
  borderRadius: 'md',

  _hover: {
    '&&': {
      bg: 'beta.700',
      borderColor: 'transparent',
    },
  },
};

export const IconButtonMenu = (isHome: boolean): CSSObject => ({
  bg: 'transparent',
  color: isHome ? 'beta.800' : 'white',
  boxSize: '8',
  w: '8',
  h: '8',
  '& svg': {
    boxSize: '8',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '0',
  pt: ['2', null],
};
