import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'transparent',
  px: 3,
  py: 2,
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.400',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
  _last: {
    borderBottom: 'none',
  },
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (): CSSObject => ({
  color: 'white',
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'beta.400',
  fontSize: 'xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'beta.400',
  boxSize: '30px',
  boxShadow: 'none',
  bg: 'none',
  p: 'none',
};

export const IconChevron: CSSObject = {
  color: 'white',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: 'xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'white',
    boxSize: '5',
  },
};
