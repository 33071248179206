import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  borderRadius: 'unset',
  px: '2',
  mb: 'unset',
  w: 'auto',
  bg: 'white',
  borderBottomRadius: isLast && 'base',
  _hover: {
    bg: 'alpha.50',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'gamma.50',
  borderRadius: 'base',
};

export const Icon: CSSObject = {
  bgGradient: 'linear(180deg, beta.400, beta.600)',
  borderRadius: 'md',
  color: 'alpha.800',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.35) inset',
};

export const TextRace: CSSObject = {
  color: 'gray.700',
  fontWeight: 'medium',
};

export const TextNumber: CSSObject = {
  bg: 'alpha.500',
  h: '4',
  py: '0.5',
};

export const BoxHeading: CSSObject = {
  display: 'none',
  border: 'none',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'gamma.600',
  bg: 'white',
  borderRadius: 'full',
  p: '1.5',
};
