import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: selected ? 'alpha.400' : 'blackAlpha.500',
  border: selected ? '1px solid' : 'unset',
  borderColor: 'alpha.500',
  mt: '1',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  borderLeftRadius: index === 0 ? '2em' : '0',
  pl: index === 0 ? '0.5' : '0',
  borderRightRadius: index === numberOfSlides - 1 ? '2em' : '0',
  pr: index === numberOfSlides - 1 ? '0.5' : '0',
});

export const CarouselWrapper: CSSObject = {
  mb: '1',
};
