import React from 'react';
import {
  NavLink as ReactRouterNavLink,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Box, Divider, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import PrimaryWrapper from '../../../wrappers/PrimaryWrapper';
import { MatchesContainer } from './Matches.styles';
import { MatchesProps } from './types';
import CompetitionList from '../components/CompetitionList/CompetitionList';
import Nav, { NavLink } from '../components/Nav/Nav';
import { useInfiniteQueryUpcomingMatches } from '@/api/sports/upcomingMatches.hooks';
import Well from '../components/Well/Well';
import PageButton from '../components/PageButton/PageButton';
import { matchesV2Styles } from '@/views/sports/Matches/styles/MatchesV2.styles';

const MatchesV2: React.FC<MatchesProps> = () => {
  const { search } = useLocation();
  const { competitionName, sportName } = useParams();
  const [searchParams] = useSearchParams();

  const { sportId, competitionId } = {
    sportId: searchParams.get('sportId'),
    competitionId: searchParams.get('competitionId'),
  };

  const params = {
    sport_id: sportId ?? '',
    competition_id: competitionId ?? '',
    limit: 50,
    top_markets: true,
  };

  const queryUpcomingMatches = useInfiniteQueryUpcomingMatches(params, {
    getNextPageParam: ({ paging }) => paging?.next_offset,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage } =
    queryUpcomingMatches;

  const shouldShowEvents = false;

  return (
    <PrimaryWrapper
      fullWidth
      pageTitle={competitionName || ''}
      pageHeader={
        <Text {...matchesV2Styles.pageHeaderText}>{competitionName}</Text>
      }
    >
      <Box {...matchesV2Styles.mainBoxWrapper}>
        <MatchesContainer>
          {shouldShowEvents && (
            <Nav {...matchesV2Styles.navOverride}>
              <NavLink
                as={ReactRouterNavLink}
                to={`.${search}`}
                end
                {...matchesV2Styles.navLinkOverride}
              >
                <FormattedMessage id="sports.tabs.events" />
              </NavLink>
            </Nav>
          )}

          <Divider {...matchesV2Styles.divider} />
          <CompetitionList
            marketTypes={queryUpcomingMatches?.data?.pages[0].main_market_types}
            marketCount={
              queryUpcomingMatches?.data?.pages[0]?.main_market_types?.length ??
              0
            }
            events={
              queryUpcomingMatches?.data?.pages.flatMap((page) => page.items) ??
              []
            }
            isLoading={queryUpcomingMatches.isLoading}
            shouldDisplayMarketHeadings={sportName?.toLowerCase() !== 'soccer'}
          />

          {hasNextPage && (
            <Well {...matchesV2Styles.hasNextPageWellOverride}>
              <PageButton
                isLoading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                {...matchesV2Styles.nextPageButtonOverride}
              >
                <FormattedMessage id="generic.showmore" />
              </PageButton>
            </Well>
          )}
        </MatchesContainer>
      </Box>
    </PrimaryWrapper>
  );
};

export default MatchesV2;
