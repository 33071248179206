import {
  Box,
  Breadcrumb as BCBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { getOtherRacesSameVenue } from '../../views/races/services/races.actions';
import { ERaceType } from '../../lib/DBModels';
import { useAppDispatch } from '../../hooks/useRedux';
import { getDisplayNameByRaceType, getStrings } from '@/helpers/utils';
import { useBreadcrumbs } from './Breadcrumb.hooks';
import { breadcrumbStyles, ChevronRightIcon } from './Breadcrumb.styles';

import { BreadcrumbProps, TCrumb } from './types';

const generateSearchParams = (params: string[]) =>
  params.length > 0 ? `?${params.join('&')}` : '';

/**
 * === React-Router Path Params -> Search Param Bindings ===
 *
 * If using a URL Path Parameter in a route via React-Router
 * this bindings object can be used to define which search
 * param should be added to the url for the corresponding
 * display value (path parameter)
 *
 * e.g If i want to navigate to /:sportName for display purposes
 * but this page requires a "?sportId=" in order to load data
 * i can add the binding for { sportName: 'sportId' } and the
 * breadcrumb will handle generating the correct state of search
 * parameters in your url for that part of the breadcrumb.
 */
const paramToSearchBindings: { [key: string]: string } = {
  sportName: 'sportId',
  competitionName: 'competitionId',
  tournamentName: 'tournamentId',
  venueName: 'venueId',
  raceNumber: 'raceId',
};

const Breadcrumb: FC<BreadcrumbProps> = ({ crumbs = [] }) => {
  const [{ Generic: Strings }] = getStrings();
  const pathParams = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [otherRaceIds, setOtherRaceIds] = useState<string>();
  const currentRaceId = searchParams.get('raceId') ?? '';

  const { getHref } = useBreadcrumbs();

  useEffect(() => {
    if (currentRaceId !== '') {
      (async () => {
        const otherRacesReq = dispatch(
          getOtherRacesSameVenue(currentRaceId)
        ).unwrap();

        const [otherRaces] = await Promise.all([await otherRacesReq]);

        const checkOtherRaces = otherRaces?.some((el) => el.race_number === 1);

        if (checkOtherRaces) {
          otherRaces
            ?.filter((race) => race.race_number === 1)
            .map((race) => {
              const firstRace = race.race_id;
              setOtherRaceIds(String(firstRace));
              return firstRace;
            });
        }
      })().catch(undefined);
    }
  }, [dispatch, otherRaceIds, searchParams, currentRaceId]);

  // Breadcrumb "regeneration" (Builds an obj with data for path reconstruction)
  // 1. Get current path, pathParams & searchParams
  // 2. Map over each part of the path
  //  2a. check if path part is just a string or has a corresponding pathParam
  //  2b. if just a string add it to the display property of the crumb obj
  //  2c. if path part has corresponding pathParam
  //    2d. get the value from the paramToQueryBindings object using the pathParam as key
  //    2e. store this value on search params of the crumb obj with pathParam value as display
  // 3. store a total "to" value on the crumb Obj, representing total path for that crumb

  const generateCrumb = useCallback(() => {
    // If override crumbs have been passed in, use those instead
    if (crumbs.length > 0) return crumbs;

    let currentPath = '';
    const pathParts = pathname.slice(1, pathname.length).split('/');
    const isRacing = pathParts[0] === 'racing';
    const newCrumbs: TCrumb[] = [];
    const currentSearch: string[] = [];
    let competitionName = '';
    let venueId = '';

    // Loop over each path part
    pathParts.forEach((pathPart, i) => {
      let correspondingPathParam =
        Object.keys(pathParams).find(
          (pathParamKey) =>
            pathParams[pathParamKey] === pathPart.replace(/%20/g, ' ')
        ) || '';

      /* Don't show Racing in breadcrumbs when Next To Jump COS-1208 */
      if (pathParts[1] === 'Next%20To%20Jump' && i === 0) {
        return;
      }

      /* Only show Racing instead of Racing > Today COS-1208 */
      if (pathPart === 'Today') {
        return;
      }

      /* Remove SGM from breadcrumb */
      if (pathPart === 'sgm' && i === 4) {
        return;
      }

      if (!correspondingPathParam) {
        // this part of the path is just a string, no dynamic ids/data required
        currentPath += `/${pathPart}`;
        newCrumbs.push({
          display: pathPart,
          custom: '/racing/Today',
          to: `${currentPath}${generateSearchParams(currentSearch)}`,
        });
      } else {
        // this part of the string has a correspondingPathParam pathParam
        // lets check the paramToSearchBindings for possible search params

        // === NOT HAPPY WITH THIS PART ===
        // Overlap from react-router means that having two routes e.g;
        // /sports/:competitionName
        // /sports/:tournamentName
        // will always just resolve the higher route inside the routes
        // but in the case this is actually a tournament name but has resolved
        // to competition name, the competitionId will be null, so we are manually
        // checking if this is the case if so using the tournamentId instead.
        let correspondingSearchParam = searchParams.get(
          paramToSearchBindings[correspondingPathParam]
        );

        /* Show Venue and race as one breadcrumb */
        if (correspondingPathParam === 'venueName' && isRacing) {
          competitionName = pathPart;

          venueId = correspondingSearchParam
            ? `${paramToSearchBindings[correspondingPathParam]}=${correspondingSearchParam}`
            : '';

          return;
        }

        // edge case for tournament/competition overlap
        if (
          correspondingPathParam === 'competitionName' &&
          !correspondingSearchParam
        ) {
          // the path param was competition, but had no competitionId, so this likely means
          // that we are dealing with a tournament, update pathParam value and retry fetching
          // search param data.
          correspondingPathParam = 'tournamentName';
          correspondingSearchParam = searchParams.get(
            paramToSearchBindings[correspondingPathParam]
          );
        }
        // ================================

        // Build a search string if a corresponding search param exists
        const searchString = correspondingSearchParam
          ? `${paramToSearchBindings[correspondingPathParam]}=${correspondingSearchParam}`
          : '';

        // Update currentSearch, currentPath & build crumb obj
        if (searchString) currentSearch.push(searchString);
        currentPath += `/${pathPart}`;

        const meetingDate = searchParams.get('meetingDate');
        const thirdCrumb = newCrumbs.length === 2;
        newCrumbs.push({
          display: thirdCrumb ? `${competitionName}  ${pathPart}` : pathPart,
          custom: `/Racing/Today?raceType=${pathParts[1]}`,
          to:
            thirdCrumb && isRacing
              ? `/${pathParts[0]}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}?${venueId}&${searchString}&meetingDate=${meetingDate}`
              : `${currentPath}${generateSearchParams(currentSearch)}`,
        });
      }
    });
    return newCrumbs;
  }, [crumbs, pathParams, pathname, searchParams]);

  const breadcrumb = useMemo(() => {
    const newCrumbs = generateCrumb();
    const crumble = newCrumbs.reduce<Record<string, string | undefined>[]>(
      (a, b) => {
        if (b.display) return [...a, b];
        return a;
      },
      []
    );
    const isSport = crumble?.[0]?.display?.toLocaleLowerCase() === 'sports';
    const homeURL = isSport ? '/?category=sport' : '/';

    return (
      <>
        <BreadcrumbItem
          {...breadcrumbStyles.breadcrumbItemBreadcrumb}
          separator={
            crumble.length === 0 ? (
              ''
            ) : (
              <ChevronRightIcon name="angleRightSolid" />
            )
          }
          isCurrentPage={crumble.length === 0}
        >
          <BreadcrumbLink
            {...breadcrumbStyles.breadcrumbLinkItem}
            as={Link}
            to={homeURL}
          >
            {Strings.Home}
          </BreadcrumbLink>
        </BreadcrumbItem>

        {crumble.map((crumb, i) => {
          const raceTypeCrumbDisplay = getDisplayNameByRaceType(
            crumb?.display?.replace(/%20/g, ' ') as ERaceType
          );

          return (
            <BreadcrumbItem
              {...breadcrumbStyles.breadcrumbItemBreadcrumb}
              key={crumb.display}
              isCurrentPage={i === crumble.length - 1}
              spacing="1"
              separator={
                i !== crumble.length - 1 ? (
                  <ChevronRightIcon name="angleRightSolid" />
                ) : (
                  ''
                )
              }
            >
              {/**
               * The isSport here could be moved into the hook,
               * however, the routing needs an overhaul and this
               * is just a quick patch.
               */}
              <BreadcrumbLink<any>
                {...breadcrumbStyles.breadcrumbLinkItem}
                cursor="default"
                {...(i !== crumble.length - 1 && {
                  as: Link,
                  to:
                    isSport && i === 2
                      ? `${getHref(crumb, crumble, i)}&competitionId=${
                          searchParams.get('competitionId') ?? ''
                        }`
                      : getHref(crumb, crumble, i),
                  cursor: 'pointer',
                })}
              >
                {raceTypeCrumbDisplay !== undefined
                  ? decodeURIComponent(raceTypeCrumbDisplay)
                  : decodeURIComponent(crumb?.display ?? '')}{' '}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </>
    );
  }, [Strings.Home, generateCrumb, getHref, searchParams]);

  return (
    <Box {...breadcrumbStyles.breadcrumbWrapper}>
      <BCBreadcrumb>{breadcrumb}</BCBreadcrumb>
    </Box>
  );
};

export default Breadcrumb;
