import { CSSObject } from '@chakra-ui/react';

export const GreenIDContainer: CSSObject = {
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  maxW: ['100vw', null, '500px'],
  bg: 'rgba(78, 178, 255, 0.14)',
  'button#greenid-option-list-toggle': {
    borderRadius: 'md',
    bg: 'blackAlpha.400',
    color: 'white',
    border: 'none',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },
  '#greenid-source-header #greenid-source-title': {
    '#greenid-source-title-heading': {
      color: 'white',
      fontWeight: 'medium',
      fontSize: 'sm',
      fontFamily: 'roboto',
    },
    '.glyphicon-question-sign': {
      color: 'alpha.400',
    },
  },
  '#greenid-intro-words': {
    '.lead': {
      color: 'white',
      fontSize: 'xs',
      fontFamily: 'roboto',
      fontWeight: 'normal',
    },
  },
  '#greenid-intro-content': {
    h1: {
      color: 'white',
      fontSize: 'md',
      fontFamily: 'roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      mb: '4',
      gap: '5px',
      mt: 0,
    },
    p: {
      color: 'white',
      fontWeight: 'normal',
      fontSize: 'sm',
      fontFamily: 'roboto',
    },
  },
  '&&': {
    '#greenid-container': {
      bg: 'unset',
      a: {
        color: 'white',
      },
      '.greenid-helpicon': {
        color: '#FF9F1C',
      },
      '#greenid-source-header': {
        display: 'flex',
        flexDirection: 'column',
      },
      '#greenid-source-content': {
        borderTop: '1px solid',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset',
        borderTopColor: 'blackAlpha.500',
        '#greenid-source-body': {
          p: {
            color: 'white',
            strong: {
              color: 'white',
            },
          },
          '.row': {
            '.form-group': {
              select: {
                borderRadius: 'md',
                bg: 'white',
                color: 'black',
                border: 'none',
                boxShadow:
                  '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
                option: {
                  color: 'black',
                },
              },
              '.control-label': {
                color: 'white',
              },
              'label[for="greenid-bdm-stateselect"]': {
                color: 'white',
              },
              label: {
                color: 'black',
                fontWeight: 'medium',
                fontSize: 'sm',
                fontFamily: 'roboto',
                span: {
                  color: 'white',
                  fontWeight: 'medium',
                  fontSize: 'sm',
                  fontFamily: 'roboto',
                },
              },
              'input[type="text"]': {
                borderLeftRadius: 'md',
                bg: 'blackAlpha.400',
                boxShadow:
                  '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
                border: 'none',
                color: 'white',
                fontWeight: 'normal',
                '&&:hover, &&:focus': {
                  bg: 'blackAlpha.400',
                },
                _placeholder: {
                  color: 'white',
                },
                h: '38px',
              },
            },
          },
          '.checkbox': {
            'input[type="checkbox"]': {
              width: '20px',
              height: '20px',
              bg: 'alpha.400',
              borderWidth: '2px',
              borderColor: 'gray.200',
              marginLeft: '-28px',
              borderRadius: 'sm',

              '&[data-checked]': {
                width: '20px',
                height: '20px',
                bg: 'alpha.400',
                borderColor: 'alpha.400',
              },
              '&[data-checked]:hover': {
                width: '20px',
                height: '20px',
                bg: 'alpha.400',
                borderColor: 'alpha.400',
              },
            },
            label: {
              fontSize: 'xs',
              color: 'white',
              paddingLeft: '28px',
            },
          },
        },
      },
      '.input-group-addon': {
        bg: 'rgba(0, 0, 0, 0.24)',
        border: 'none',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
        '.glyphicon-calendar': {
          color: 'gamma.600',
        },
      },
    },
  },
  '#greenid-submit-button-container': {
    button: {
      borderRadius: 'md',
      bgGradient: 'linear(to-b, gamma.500 0%, gamma.600 100%)',
      boxShadow:
        '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
      color: 'blackAlpha.700',
      fontFamily: 'accentStaat',
      fontSize: 'sm',
      textTransform: 'capitalize',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
      _hover: {
        bgGradient: 'linear(to-b, gamma.300 0%, gamma.300 100%)',
        bg: 'gamma.300',
        boxShadow:
          '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
      },
      ':active, &[data-active="true"]': {
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
        color: 'gamma.500',
        textShadow: 'none',
      },
      _disabled: {
        opacity: 0.4,
        pointerEvents: 'none',
        bgGradient: 'linear(180deg, gamma.500 0%, gamma.600 100%)',
        boxShadow:
          '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
      },

      '&&&': {
        '& > svg, & > span': { zIndex: 9 },
      },
    },
  },
};
