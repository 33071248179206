import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: '#DAFF3E',
  fontSize: 'sm',
  fontWeight: 'bold',
  fontFamily: 'KHInterference',
};

export const SpanBetCount: CSSObject = {
  fontSize: 'xs',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'blackAlpha.700',
  textShadow: ' 0px 1px 0px rgba(255, 255, 255, 0.50)',
  minW: 'unset',
  bg: 'linear-gradient(347deg, #25B871 9.35%, #53F9A9 90.66%)',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.90) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 0px 2px 1px rgba(255, 255, 255, 0.90) inset',

  '&&': {
    borderRadius: 'sm',
  },

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, #B16524, #D36A11)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
    color: '#733924',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '2',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontFamily: 'roboto',
  fontWeight: 'bold',
};
