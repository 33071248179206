/* eslint-disable no-void */
import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'lottie-react';
import { PROMO_URL } from '@/constants/promo';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { useHeader } from '@/components/Headers/HeaderPrimary/services/Header.hooks';

export type TPlacedTreasureHuntProps = {
  isWin: boolean;
};

const CURRENT_BRAND = getThemeConfig().name?.toLocaleLowerCase();

const TREASURE_HUNT_URL = `${PROMO_URL}${CURRENT_BRAND}/treasure-chest/${CURRENT_BRAND}.json`;

const LOTTIE_PROMISE = new Promise((res, rej) => {
  fetch(TREASURE_HUNT_URL, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((data) => {
      res(data);
    })
    .catch((error) => {
      rej(error);
    });
});

function PlacedTreasureHuntComp({ isWin }: TPlacedTreasureHuntProps) {
  const [lottieData, setLottieData] = useState<any>(null);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const playedRef = useRef(false);

  const { refetch } = useHeader(animationCompleted);

  useEffect(() => {
    if (!playedRef.current) {
      LOTTIE_PROMISE.then((data) => {
        setLottieData(data);
        playedRef.current = true;
      }).catch((e) => console.error(e));
    }
  }, []);

  useEffect(() => {
    if (animationCompleted) {
      void refetch();
    }
    // eslint-disable-next-line
  }, [animationCompleted]);

  return (
    <div>
      {lottieData && (
        <Lottie
          loop={!isWin ? 1 : false}
          animationData={lottieData}
          initialSegment={[isWin ? 1 : 276, isWin ? 275 : 551]}
          onComplete={() => {
            setAnimationCompleted(true);
          }}
        />
      )}
    </div>
  );
}

export const PlacedTreasureHunt = React.memo(PlacedTreasureHuntComp);
