import { PersonalDetailsSchema } from './PersonalDetails.styles';

export const personalDetailsStyles: PersonalDetailsSchema = {
  betslipPromoWrapper: {
    sx: {
      '.chakra-switch__label': {
        fontWeight: 'bold',
      },
    },
  },
};
