import { ButtonProps } from '@chakra-ui/react';
import { styleImports } from './CloseBtn.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const closeBtn: ButtonProps = {
  ...(() => {
    try {
      return styleImports[themeName]?.closeBtn;
    } catch (err) {
      return {};
    }
  })(),
};
