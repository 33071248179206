import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  color: 'gamma.500',
  fontFamily: 'Caveman',
  fontSize: '3xl',
  w: '400px',
  textAlign: 'center',
  fontWeight: 'normal',
  lineHeight: '25px',
};

export const TextSecondary: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
};

export const ImageNoRaces: CSSObject = {
  w: '335px',
};
