import { CSSObject } from '@chakra-ui/react';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    bg: 'alpha.400',
    bgImage: 'none',
    boxShadow:
      '0px 2px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
    color: 'alpha.900',
    borderRadius: 'base',
    fontSize: '2xs',
    h: '6',
    px: '1.5',
    textTransform: 'capitalize',

    sx: {
      '&&': {
        minW: '20',
      },

      '&&[data-isbonusbet="true"]': {
        bg: 'alpha.800',
        color: 'white',
      },
    },
  },
  buttonBetPropositionClosedProps: {},
  buttonStakeProps: {
    variant: 'secondary',
    color: 'yellow.900',
    borderRadius: 'sm',
  },
  spanRaceInfo: {
    color: 'white',
  },
  iconMysteryBetFooter: {
    color: 'alpha.200',
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    lineHeight: 'normal',
    w: '100%',
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'gamma.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    px: 3,
    py: 1.5,
    fontSize: 'xs',
  },
  flexWrapperMysteryBetFooterBetReview: {
    fontSize: 'xs',
    w: '100%',
    borderRadius: 'md',
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'gamma.500',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    py: 1.5,
  },
  spanBetOdds: {
    color: 'white',
  },
};

export const BetCardContainer = (): CSSObject => ({
  bg: 'linear-gradient(180deg, var(--bc-colors-brand-600) 0%, var(--bc-colors-brand-700) 100%)',
  borderTopRadius: 'base',
  color: 'white',
  '&::after': {
    content: `""`,
    position: 'absolute',
    bottom: '-30px',
    left: '0',
    width: '100%',
    height: '31px',
    background:
      'linear-gradient(135deg, var(--bc-colors-brand-700) 5px, transparent 0%),linear-gradient(-135deg, var(--bc-colors-brand-700) 5px, transparent 0%)',
    backgroundSize: '13px 32px',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
};

export const ExoticsNumberContainer: CSSObject = {
  w: '6 ',
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const BetOdds: CSSObject = {
  display: 'none',
};

export const EventTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  fontWeight: 'bold',
  color: 'alpha.200',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, white 0%, var(--bc-colors-gray-300) 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'alpha.700',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeCollapse: CSSObject = {
  '&&': {
    m: '0',
    mt: '2',
  },
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});
