import { useIntl } from 'react-intl';
import { TBetSlipBetMulti } from '../../../Services/Betslip.types';

export const useInvalidityReason = (bet: TBetSlipBetMulti | undefined) => {
  const { legs } = bet ?? {};
  const intl = useIntl();

  if ((legs?.length ?? 0) <= 1) {
    return intl.formatMessage({
      id: 'betslip.betslipbetcard.errors.insufficientlegsformulti',
    });
  }

  if ((legs?.length ?? 0) + 1 > 20) {
    return intl.formatMessage({
      id: 'betslip.betslipbetcard.errors.exceededmaximumlegsformulti',
    });
  }

  if (legs?.some(({ price_type }) => price_type === 'starting')) {
    return intl.formatMessage({
      id: 'betSlip.betSlipBetCard.errors.startingPrice',
    });
  }

  return intl.formatMessage({
    id: 'betslip.betslipbetcard.errors.multiplelegsineventformulti',
  });
};

export const useMulti = () => {};
