import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';

export const betCardErrorFlexProps: CSSObject = {
  borderTopRadius: 'md',
};

export const BetCardContainer = (): CSSObject => ({
  // TODO: Fix theme colours
  background: 'linear-gradient(180deg, #3B63C4 0%, #2C4B96 100%)',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: 'md',
  color: 'white',
  mb: '-2.5',

  '.state-prior': { color: 'white' },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'delta.400',
  mt: '1',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  color: 'alpha.100',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  // TODO: Fix theme colours
  bg: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
  my: '1.5',
  mx: '2',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeButton: CSSObject = {
  h: '9',
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'yellow.900',
  borderRadius: 'lg',
  border: '2px solid',
  borderColor: '#CD9000',
  textShadow: 'inherit',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 2px #F7D722',
  _first: {
    borderStartRadius: 'lg',
  },
  _last: {
    borderEndRadius: 'lg',
  },
  _hover: {
    bgGradient: 'linear(to-b, delta.600, delta.700)',
  },
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'alpha.700',
  boxShadow: 'md',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BonusBetsButton = (isBonusBet: boolean) => ({
  bg: isBonusBet
    ? 'delta.800'
    : 'linear-gradient(180deg, #F5C44D 0%, #E7A402 100%)',
  boxShadow: isBonusBet
    ? 'inset 0px 0px 5px rgba(0, 0, 0, 0.4)'
    : '0px 2px 3px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.45)',
  color: isBonusBet ? 'delta.500' : 'delta.900',
  borderRadius: 'md',
  border: isBonusBet ? '1px' : 'none',
  borderColor: 'delta.500',
  fontWeight: 'medium',
  fontSize: '2xs',
  h: '6',
  px: '1.5',
  textShadow: 'none',
  '&&': {
    minW: '20',
  },

  _hover: {
    bg: isBonusBet ? 'blackAlpha.400' : 'gamma.600',
    color: 'white',
    border: isBonusBet ? '1px' : 'none',
    borderColor: 'gray.900',
    boxShadow: 'none',
  },

  _active: {
    boxShadow: 'none',
  },

  _disabled: {
    boxShadow: 'none',
    bg: 'blackAlpha.300',
    color: 'alpha.300',
    textTransform: 'uppercase',
    h: '7',
    opacity: '1',
    border: 'none',
  },
});

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
