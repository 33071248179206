import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/chromabet/images/racing/mysteryBetLogo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: { variant: 'odds' },
  imageLogo: {
    w: '250px',
    src: mysteryLogo,
  },
  iconMystery: {
    mb: '1',
  },
  textSlash: {
    color: 'Midnight Majesty',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  flexWrapperMysteryLabelView: {
    color: 'white',
    fontFamily: 'accent',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
    sx: {
      '&&': {
        svg: {
          mb: '0.5',
        },
      },
    },
  },
  buttonAddToBetslip: {
    variant: 'solid',
    sx: {
      '&&': {
        borderLeftRadius: 'none',
        ms: '0',
        fontSize: ['2xs', null, 'xs'],
      },
    },
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    borderRightRadius: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['2xs', null, 'xs'],
    fontFamily: 'accent',
    fontWeight: 'bold',
    sx: {
      '&&': {
        px: [2, 3],
        svg: {
          mb: '0.5',
        },
      },
    },
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    px: '3',
    color: 'gamma.200',
    overflow: 'hidden',
    mx: 'auto',
  },
  flexWrapperMysteryPlacedBetView: {
    fontFamily: 'ProximaNova',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
    mx: ['0', null, '2'],
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'blackAlpha.300',
    border: '1px solid',
    borderColor: 'beta.400',
    borderRadius: 'base',
    boxSize: '10',
    p: '0',
    w: '38px',
    h: '38px',
    color: 'beta.400',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    fontSize: ['2xs', null, 'xs'],
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'bold',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textPriceInfo: {
    fontSize: ['2xs', null, 'xs'],
    color: 'beta.400',
    fontFamily: 'accent',
    fontWeight: 'bold',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textRollover: {
    fontSize: ['2xs', null, 'xs'],
    color: 'beta.400',
    fontFamily: 'accent',
    fontWeight: 'bold',
    textShadow: 'none',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  textError: {
    color: 'gamma.500',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontStyle: 'normal',
    fontSize: ['2xs', null, 'xs'],
  },
  iconBack: {
    boxSize: '10',
    color: 'white',
  },
  iconRace: { color: 'beta.500', mb: '2' },
  textSpanWhite: {
    color: 'white',
  },
  buttonRollOver: {
    variant: 'secondary',
    h: '7',
    _disabled: {
      bg: 'rgba(0, 0, 0, 0.24)',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      color: 'beta.500',
      textShadow: '0px 0px 8px 0px rgba(125, 253, 42, 0.7)',
    },
  },
  spanSelectionText: {
    color: 'beta.400',
  },
  spanPositionType: {
    color: 'beta.500',
  },
  tooltipIcon: {
    color: 'beta.400',
    fill: 'beta.400',
  },
  buttonGroupAddToBetslip: {
    mr: '2',
    alignItems: 'center',
    maxW: ['250px', null, 'unset'],
  },
};
