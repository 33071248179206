import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  bg: 'alpha.400',
  borderRadius: 'base',
  '& svg': {
    color: 'zeta.400',
  },
};

export const MatchCardContainer: CSSObject = {
  bgGradient: 'linear(to-b, zeta.400, zeta.600)',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow:
    '0px 1px 0px 0px #38190B inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px rgba(255, 255, 255, 0.15) inset',
  w: '100%',
  px: '2',
  py: '1.5',
  _hover: {
    bgGradient: 'linear(to-b, zeta.400, zeta.600)',
    opacity: 0.7,
  },
};

export const MatchName: CSSObject = {
  noOfLines: 'unset',
  fontWeight: 'semibold',
  fontSize: ['sm', null, 'md'],
  color: 'alpha.600',
  mt: '1.5',
};

export const MatchDate: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'extrabold',
  color: 'alpha.500',
  bg: 'blackAlpha.300',
  p: '1',
  borderRadius: 'base',
};

export const TeamItemOdds: CSSObject = {
  color: 'yellow.900',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const Seperator: CSSObject = {
  color: 'blackAlpha.400',
  mx: '0',
  px: '0',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon"
`,
};

export const TextSpanName = (): CSSObject => ({
  color: 'alpha.600',
});

export const CompetitionName: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'extrabold',
  color: 'alpha.400',
};

export const ArrowIcon: CSSObject = {
  boxSize: '7',
  color: 'alpha.600',
  border: '1px',
  borderColor: 'alpha.600',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
