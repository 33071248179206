import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    fontFamily: 'roboto',
    fontSize: '2xs',
    lineHeight: '1.125',
    textTransform: 'none',
    color: 'beta.300',
    minH: '9',
    sx: {
      '&&': {
        minW: '20',
        // _groupActive in component below, only checks for [data-active], which is why we have done this
        '&[data-active="true"] .buttonBaseSpanProps': {
          color: 'beta.300',
          textShadow: 'none',
        }
      },
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    fontWeight: 'extrabold',
    zIndex: '9',
    color: 'black',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    _groupHover: { 
      color: 'beta.300',
      textShadow: 'none',
    },
  },
  buttonBaseBox: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'beta.300',
    textShadow: 'none',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
