import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { styleImports } from './RaceRunnerPropButton.styles.imports';

import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const buttonRunnerPropositionButtonProps: ButtonProps = {
  'data-testid': 'buttonRunnerPropositionButtonProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonRunnerPropositionButtonProps;
    } catch (err) {
      return {};
    }
  })(),
};

export type TRunnerPropositionButton = { isInBetSlip?: boolean };

export const favouriteFlexProps: CSSObject = {
  label: 'raceRunnerPropButton-favouriteFlexProps',
  ...(() => {
    try {
      return styleImports[themeName]?.favouriteFlexProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const favouriteTextProps: CSSObject = {
  label: 'raceRunnerPropButton-favouriteTextProps',
  ...(() => {
    try {
      return styleImports[themeName]?.favouriteTextProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const favouriteIconProps: CSSObject = {
  label: 'raceRunnerPropButton-favouriteIconProps',
  ...(() => {
    try {
      return styleImports[themeName]?.favouriteIconProps;
    } catch (err) {
      return {};
    }
  })(),
};
