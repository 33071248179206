import menuTile from '@/assets/sugarcastle/images/sidenav/sc-menu-mid.png';
import menuTop from '@/assets/sugarcastle/images/sidenav/sc-menu-top.png';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {},
  boxWrapperProps: {
    position: [null, null, 'relative'],
    width: ['100%', null, null, '272px'],
    pt: '0',
    pl: '0',
    pr: '0',
    mt: ['1', null, '0'],
    mb: ['2', null, '2.5'],
    bg: ['none', null, null, `url(${menuTile})`],
    bgRepeat: 'repeat-y',
    backgroundSize: ['inherit', null, null, '272px 100%'],
    borderRadius: 'xl',
    zIndex: '5',
    clipPath: [
      null,
      'polygon(0 12px, 13px 12px, 13px 0, calc(100% - 13px) 0, calc(100% - 13px) 12px, 273px 12px, 273px calc(100% - 12px), calc(100% - 13px) calc(100% - 12px), calc(100% - 13px) 100%, 13px 100%, 13px calc(100% - 12px), 0 calc(100% - 12px), 0 10px)',
      null,
      null,
      null,
    ],
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '272px',
      height: '22px',
      backgroundImage: ['none', null, null, `url(${menuTop})`],
      backgroundSize: ['inherit', null, null, '272px'],
      backgroundRepeat: 'no-repeat',
      zIndex: '10',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '0',
      width: '272px',
      height: '25px',
      backgroundImage: ['none', null, null, `url(${menuTop})`],
      backgroundSize: ['inherit', null, null, '272px'],
      transform: 'rotate(180deg)',
      backgroundRepeat: 'no-repeat',
      zIndex: '10',
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    py: ['0', null, '2.5'],
    px: ['4', null, '6'],
  },
};
