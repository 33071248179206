import { OnRaceBetsSchema } from './OnRaceBets.styles';

export const onRaceBetStyles: OnRaceBetsSchema = {
  wrapper: {
    mb: '2',
    bgGradient: 'linear(to bottom, beta.600, beta.700)',
    border: '1px',
    borderRadius: 'base',
    borderColor: 'transparent',
    flexDirection: 'column',
    p: '2',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'white',
  },
  headerCountText: {
    ml: '1',
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'whiteAlpha.500',
  },
  headerExpandButton: {
    variant: 'primary',
    ml: 'auto',
    mr: '1',
    boxSize: '7',
  },
  toggleIcon: {
    boxSize: '4',
  },
  betsContainer: {
    flexDirection: 'column',
  },
  betCategoryWrapper: {
    alignItems: 'center',
    mt: '2',
  },
  betCategory: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'alpha.700',
  },
  betCategoryCount: {
    fontSize: 'xs',
    fontWeight: 'semibold',
    color: 'alpha.700',
    ml: '1',
  },
  divider: {
    borderWidth: 'px',
    opacity: '1',
    borderColor: 'blackAlpha.400',
    mt: '1.5',
  },
  betRow: {
    py: '2',
  },
  betRowDivider: {
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.200',
    borderBottomStyle: 'dashed',
  },
  groupDivider: {
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.400',
  },
  categoryIcon: {
    color: 'whiteAlpha.500',
    boxSize: '6',
  },
  groupContainer: {
    flexDir: 'column',
    py: 2,
    borderBottomColor: 'blackAlpha.400',
  },
  groupTitleText: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  runnerText: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'extrabold',
    textTransform: 'capitalize',
  },
  runnerBarrierText: {
    color: 'whiteAlpha.500',
    fontSize: 'xs',
  },
  stakeText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  potentialWinningsText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  subtitleText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  exoticsPositionText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    w: 9,
  },
  viewMoreButton: {
    alignItems: 'center',
    justifyContent: 'center',
    mx: -2,
    mb: -2,
    borderTopRadius: 0,
    height: 8,
  },
  viewMoreButtonText: {
    fontWeight: 'black',
    fontSize: 'sm',
    textTransform: 'uppercase',
  },
  srmBlendedLegContainer: {
    flexDir: 'row',
    flex: '1',
  },
  srmBlendedRunnerText: {
    width: 32,
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
    textTransform: 'capitalize',
  },
  srmMarketText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
  },
  srmBlendedWrapper: {
    flexDir: 'column',
    flex: 1,
  },
  flexiText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
  },
  flexiStakeText: {
    width: '16',
    color: 'black',
  },
  flexiStakeValueText: {
    color: 'alpha.400',
    fontWeight: 'bold',
  },
};
