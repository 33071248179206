import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'space-between',
  mx: ['-2', null, '0'],
  minH: ['10', null, 'unset'],

  borderTop: ['1px dashed', 'none'],
  borderColor: 'blackAlpha.300',
  alignItems: 'start',
  mb: [null, '2'],
  bg: ['transparent', 'gray.100'],
  py: '2',
  pr: ['2', '0'],
  px: ['2', null, '0'],
  pl: ['0', null, '2'],
  borderRadius: 'md',
  gap: '1',
};

export const RacerName: CSSObject = {
  color: 'gray.700',
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
};
