export const Select = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      color: 'black',
      '&&': {
        fontSize: 'xs',
        borderRadius: 'md',
        bg: 'gamma.50',
        borderColor: 'gamma.200',
        '&:focus, &:active, &:hover': { bg: 'gamma.100' },
        '&&': {
          h: '38px',
        },
      },
    },
    icon: { color: 'black' },
  },
  sizes: {
    lg: { field: { borderRadius: 'sm' } },
    md: { field: { borderRadius: 'sm' } },
    sm: { field: { borderRadius: 'sm' } },
  },
};
