import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    bgGradient: ['unset', null, null, 'linear(to-b, beta.300, beta.400)'],
    boxShadow: [
      'unset',
      null,
      null,
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
    ],
    borderRadius: 'md',
    mt: ['0', null, null, '3'],
  },
  body: {
    color: 'white',
    fontSize: 'xs',
    mx: '3',
    px: '0',
    py: '3',
    borderBottom: '1px dashed',
    borderBottomColor: 'rgba(0, 0, 0, 0.4)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  },
};
