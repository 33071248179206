import { CSSObject } from '@chakra-ui/react';
import {
  TAccordionButton,
  TAccordionItem,
  TAccordionPanel,
} from './MarketsByMatch.styles';

export const BoxWrapper: CSSObject = {
  px: ['2', '4'],
};

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  boxShadow: 'none',
  borderRadius: 'md',
  bg: 'linear-gradient(180deg, zeta.400 0%, zeta.600 100%)',
  mb: '2',
  border: 'none',
  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',
    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  p: '2',
  bg: 'alpha.300',
  borderBottomRadius: 'base',
  ...(isNested && {
    bg: 'zeta.300',
    boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.15) inset',
    py: 0,
  }),
});

export const AccordionButton = ({ isNested }: TAccordionButton): CSSObject => ({
  bgGradient: 'linear(to-b, zeta.400 0%, zeta.600 100%)',
  borderRadius: 'base',
  color: 'alpha.600',
  px: '2',
  py: '2.5',
  border: 'none',
  transition: 'all 0.2s ease-in-out',
  fontWeight: 'bold',
  fontSize: 'sm',

  '.chakra-accordion__icon': {
    boxSize: ['4', '5'],
    border: '2px',
    borderColor: 'alpha.600',
    borderRadius: 'full',
    color: 'alpha.600',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    borderBottomRadius: 'none',
    bgGradient: 'linear(to-b, zeta.400, zeta.600)',
    color: 'alpha.600',
    _hover: {
      borderBottomRadius: 'none',
      bgGradient: 'linear(to-b, zeta.400 0%, zeta.600 100%)',
      opacity: 0.7,
    },
  },

  _hover: {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, zeta.400 0%, zeta.600 100%)',
    opacity: 0.7,
  },

  ...(isNested && {
    bgGradient: 'linear(to-b, zeta.400 0%, zeta.600 100%)',
    borderRadius: 'base',
    color: 'alpha.600',
    px: '2',

    '.chakra-accordion__icon': {
      boxSize: ['4', '5'],
      color: 'alpha.600',
      transition: '.3s ease-in-out',
    },
  }),
});
