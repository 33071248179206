import React from 'react';
import { Img } from '@chakra-ui/react';
import {
  FlexPageWrapper,
  MaintenanceContainer,
  logoImgProps,
} from '../styles/Maintenance.styles';
import { TTemplate } from '@/helpers/createTemplate';
import logo from '../../../assets/puntcity/images/logo.png';
import Description from '../components/Description';
import GlobalStyles from '../../../layouts/SecondaryLayout/conditionals/GlobalStyles/template';
import Title from '../components/Title';
import ButtonRefresh from '../components/ButtonRefresh';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template = (isMaintenance: boolean): TTemplate => ({
  wrapper: <FlexPageWrapper />,
  content: {
    wrapper: <MaintenanceContainer isMaintenance={isMaintenance} />,
    logo: <Img src={logo} alt={REACT_APP_THEME_NAME} {...logoImgProps} />,
    title: <Title />,
    desc: <Description />,
    btn: <ButtonRefresh />,
    body: <GlobalStyles />,
  },
});

export default template;
