import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'beta.200';
      }
      if (state === 'ended') {
        return 'gray.900';
      }
      // 'prior'
      return 'beta.300';
    },
    color: () => {
      if (state === 'ended') {
        return 'gray.200';
      }
      // 'prior'
      return 'blackAlpha.700';
    },
    boxShadow:
      state === 'ended'
        ? '0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
        : '0px 2px 3px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
    borderRadius: '3px',
    px: 1,
    fontFamily: 'roboto',
    fontWeight: 'bold',
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '15px',
  },
});
