import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'bold ',
  fontFamily: 'Imperator',
  textTransform: 'capitalize',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'beta.400',
  fontWeight: 'normal',
  minW: 'unset',
  border: 'none',
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  boxShadow:
    '0px 1px 1px 0px #2E5B75 inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  transition: 'all .3s ease-in-out',

  _after: { opacity: 0 },
  _hover: {
    _after: { bgGradient: 'linear(to-b, alpha.500, alpha.700)', opacity: 0.5 },
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'body',
  fontWeight: 'bold',
  pos: 'relative',
  bottom: '3px',
};
