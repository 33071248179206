import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'gamma.300',
  fontSize: 'md',
  fontWeight: 'normal',
  fontFamily: 'accent',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'body',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'gamma.300',
  pos: 'relative',
  bottom: '2px',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'gamma.200',
  minW: 'unset',
  bgGradient: 'linear(to-b, beta.400, beta.600)',
  borderRadius: 'md',
  border: 'none',
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)',
  transition: 'all .3s ease-in-out',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)',
    bgGradient: 'linear(to-b, beta.500, beta.700)',
  },
  _focus: {
    bgGradient: 'linear(to-b, alpha.200, alpha.400)',
    color: 'white',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
