import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: 'md',
    bg: '#FFB11A',
    px: '2',
    pt: '1',
    pb: '1.5',
    h: '9',
    display: 'flex',
    alignItems: 'center',
    borderColor: 'beta.500',
    borderStyle: 'solid',
    borderWidth: '2px',
    boxShadow:
      '0px 2px 7px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px #74500B inset, 0px 0px 0px 3.5px #FFD075 inset',
    sx: {
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    textDecoration: 'none',
    sx: {
      '&:first-of-type': {
        textDecoration: 'underline',
        textDecorationColor: 'blackAlpha.700',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'blackAlpha.700',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'blackAlpha.700',
};
