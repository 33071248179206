import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { CustomButtonProps } from '@/components/Button/Button';

export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'blackAlpha.700',
  bg: 'red.300',
  borderRadius: 'md',
  w: '98%',
  mx: 'auto',
  pos: 'relative',
  top: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
  mb: '1',
};

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'blackAlpha.700',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const BetCardContainer = (): CSSObject => ({
  borderTopRadius: 'base',
  color: 'white',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, alpha.700, alpha.800)',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset',
  mb: '2',
});

export const BetCardVStack: CSSObject = {
  pb: '2',
  px: '0.5',
};

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  mr: '-1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'yellow.400',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
};

export const EventTitle: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'sm',
  textTransform: 'capitalize',
  color: 'beta.500',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  color: 'gamma.100',
  textTransform: 'capitalize',
  fontSize: 'xs',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bg: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'gamma.700',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const StakeButton: CSSObject = {
  h: '9',
  bg: 'alpha.500',
  color: 'alpha.900',
  borderRadius: 'sm',
  boxShadow: '0px 2px 0px #8E7922',

  _hover: {
    bg: 'alpha.500',
  },
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'beta.500',
  boxShadow: 'md',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
  _hover: {
    bg: 'beta.700',
    color: 'white',
    boxShadow: 'md',
  },
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean): CSSObject => ({
  color: header ? 'white' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const IconPropositionClosed = (): React.ReactElement => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const DefaultStakeFlexbox: FlexProps = {
  justifyContent: 'space-around',
};

export const EventRule: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontSize: 'xs',
};

export const buttonStakeProps: CustomButtonProps = {
  color: 'white',
  textShadow: '0px 2px 2px rgba(0, 0, 0, 0.30)',
  fontSize: 'xs',
};

export const buttonBonusBetsProps = (
  isBonusBet: boolean,
  isInReview: boolean
): CustomButtonProps => ({
  display: 'flex',
  variant: 'unstyled',
  size: 'xs',
  px: '3',
  alignSelf: 'flex-start',
  textTransform: 'capitalize',
  w: isInReview ? '100%' : 'auto',
  isDisabled: isInReview,
  mt: '2',
  _hover: {
    bg: isBonusBet ? 'delta.800' : 'alpha.600',
    border: isBonusBet ? '1px' : 'none',
    borderColor: 'alpha.300',
    textShadow: 'none',
    boxShadow: 'none',
  },

  _active: {
    boxShadow: 'none',
  },

  _disabled: {
    border: 'none',
    textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
    '& > span': {
      _first: {
        mr: '1',
      },
    },
  },

  ...((): CustomButtonProps => {
    if (isBonusBet && isInReview)
      return {
        sx: {
          '&&': {
            minH: '7',
            mt: '-6px',
            bg: 'blackAlpha.600',
            borderRadius: 'none',
            borderBottomRadius: 'base',
            color: 'beta.600',
            spanProps: {
              fontFamily: 'roboto',
              textShadow: 'none',
              fontWeight: 'medium',
              fontSize: '2xs',
              py: '2',
              textTransform: 'uppercase',
            },
            _hover: {
              border: 'none',
            },
          },
        },
      };

    if (isBonusBet)
      return {
        bg: 'delta.700',
        boxShadow: '0px 0px 5px 0px #FFDD31 inset',
        color: 'white',
        borderRadius: 'md',
        border: '1px',
        borderColor: 'alpha.300',
        sx: {
          '.chakra-button__icon': {
            mr: '0',
            ml: '1',
          },
        },
        spanProps: {
          fontFamily: 'roboto',
          textShadow: 'none',
          fontWeight: 'medium',
          fontSize: '2xs',
          px: '2',
          py: '5px',
        },
      };

    return {
      bg: 'none',
      color: 'delta.800',
      borderRadius: 'md',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
      bgGradient: 'linear(to-b, alpha.400, alpha.600)',
      sx: {
        '.chakra-button__icon': {
          mr: '0',
          ml: '1',
        },
      },
      spanProps: {
        fontFamily: 'roboto',
        textShadow: 'none',
        fontWeight: 'medium',
        fontSize: '2xs',
        px: '1',
        py: '1.5',
      },
    };
  })(),
});

export const buttonBetPropositionClosedProps: CustomButtonProps = {
  variant: 'unstyled',
  px: '7',
  zIndex: '1',
  bg: 'delta.900',
  boxShadow: 'md',
  color: 'black',
  borderRadius: 'md',
  fontSize: 'xs',
  display: 'flex',
  gap: '1',
  spanProps: {
    color: 'white',
    fontFamily: 'roboto',
    fontSize: 'xs',
    fontWeight: 'bold',
    px: '3.5',
    py: '2',
    textTransform: 'uppercase',
  },
  _hover: {
    bg: 'delta.800',
    color: 'white',
    boxShadow: 'md',
  },
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'blackAlpha.300',
  borderRadius: 'md',
  px: '1',
  h: '100%',
};

export const BetCardWrapper: CSSObject = {
  '&&': {
    mt: '0',
  },
};

export const FlexBonusBetsWrapper = (): CSSObject => ({
  '&&': {
    mt: 2,
    pl: '2',
  },
});

export const ExoticsNumberContainer: CSSObject = {
  w: '6',
};
