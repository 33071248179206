import React from 'react';
import Toggle from '../components/Toggle/Toggle';
import ThemeHome from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(ThemeHome, [
  {
    name: 'toggle',
    component: <Toggle />,
    config: {
      before: 'banner',
    },
  },
]);
