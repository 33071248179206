import { basePalette } from './color';
import { BorderStyles } from '../../../types';

// TODO: Update theme colour values
const hexBetBorderStyles: BorderStyles = {
  primary: `1px solid ${basePalette.brightBlue}`,
  secondary: '1px solid #',
  tertiary: `1px solid ${basePalette.faintGrey}`,
  error: `1px solid ${basePalette.warning}`,
  disabled: '1px solid #',
};

export default hexBetBorderStyles;
