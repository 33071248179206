import { CSSObject } from '@chakra-ui/react';
import background from '../../../assets/volcanobet/images/backgrounds/location-unavailable-desktop.png';
import backgroundAtRetina from '../../../assets/volcanobet/images/backgrounds/location-unavailable-desktop@2x.png';
import lavaBottomBackground from '../../../assets/volcanobet/images/backgrounds/location-unavailable-bottom-pattern.png';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: [null, null, 'center'],
  minH: '100dvh',
  bgImage: `url(${background})`,
  bgRepeat: 'repeat',
  bgSize: ['50%', '30%'],

  '@media (-webkit-min-device-pixel-ratio: 2)': {
    bgImage: `url(${backgroundAtRetina})`,
  },

  _after: {
    w: '100%',
    content: `''`,
    bgImage: `url(${lavaBottomBackground})`,
    bgPos: 'bottom',
    bgRepeat: 'repeat-x',
    bgSize: '660px',
    pos: 'absolute',
    bottom: 0,
    height: '230px',
  },
});

export const MaintenanceContainer = (): CSSObject => ({
  bg: 'transparent',
  alignItems: 'center',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: '100%',
  color: 'white',
  contain: 'paint',
  flex: ['1', null, '0'],
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  mt: ['0', null, '12'],
  mx: 'auto',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  maxW: 'container.md',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  width: 'full',
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
});

export const ContentHeadingText: CSSObject = {
  fontFamily: 'Caveman',
  fontSize: ['4xl', null, '6xl'],
  lineHeight: ['6', null, '10'],
  mt: '4',
  mb: ['2.5', null, '4'],
  textTransform: 'lowercase',

  b: {
    bgGradient: 'linear(to-b, alpha.500, gamma.500)',
    bgClip: 'text',
    textShadow: 'none',
    fontWeight: 'normal',
  },
};

export const ContentBodyText: CSSObject = {
  fontSize: ['xs', null, 'sm'],
  mb: ['0', '4'],
  w: ['280px', 'auto'],
};

export const ButtonRefresh: CSSObject = {
  borderColor: 'white',
  boxShadow: 'none',
  color: 'white',

  _hover: {
    borderColor: 'alpha.500',
    boxShadow: 'none',
    color: 'alpha.500',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
};

export const TextDescription: CSSObject = {
  display: 'block',
  fontSize: 'sm',
  mb: ['2.5', null, '0'],
};
