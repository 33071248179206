import React, { ElementType } from 'react';
import { IS_MOBILE_APP } from '../../constants/isMobileApp';
import { useMobileAppSupport } from '../../mobileapp/mobileapp.hook';

// This component exists to insert the useMobileAppSupport hook

function MobileApp() {
  useMobileAppSupport();
  return <></>;
}

export default function Index() {
  let Container: ElementType;
  if (IS_MOBILE_APP) {
    Container = MobileApp;
  } else {
    Container = () => <></>;
  }
  return <Container />;
}
