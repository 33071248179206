import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  h: ['40%', null, '90px'],
  w: 'full',
  bgGradient: [
    'none',
    'none',
    'linear-gradient(180deg, #65430100 0%, var(--bc-colors-beta-800) 100%)',
  ],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'body',
  position: 'absolute',
  top: ['auto', 'auto', '527px'],
  bottom: ['0', null, 'auto'],
};

export const TextItem: CSSObject = {
  color: 'white',
  textAlign: 'center',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textShadow: '0px 4px 4px var(--bc-colors-epsilon-400)',
};

export const LinkItem: CSSObject = {
  color: 'beta.300',
  fontWeight: 'bold',
  textShadow: '0px 4px 4px var(--bc-colors-epsilon-400)',
};
