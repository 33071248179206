import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textTransform: 'uppercase',
  fontSize: ['xl', '2xl'],
  fontWeight: 'normal',
  fontFamily: 'SilvanoWestern',
  textAlign: 'center',
  color: 'gamma.200',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};
export const TextSecondary: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  w: ['288px', null, '420px'],
  textAlign: 'center',
  p: {
    w: '100%',
    fontWeight: 'normal',
  },
};
export const ImageNoRaces: CSSObject = {
  w: ['300px', null, '530px'],
  h: ['100px', null, '220px'],
  mb: '4',
};
