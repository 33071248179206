import { CSSObject } from '@chakra-ui/react';
import { TDot } from './Carousel.styles';

export const CarouselWrapper: CSSObject = {
  '& .control-dots ': {
    bgGradient: 'linear(to-b, rgba(255, 202, 36, 1), rgba(215, 146, 0, 1))',
    rounded: 'base',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.4)',
    w: 'fit-content',
    px: '1',
    left: '50%',
    transform: 'translateX(-50%)',
  },
};

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: selected ? 'beta.700' : 'rgba(0, 0, 0, 0.36)',
});
