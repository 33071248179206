import * as yup from 'yup';

import { getStrings } from '@/helpers/utils';

const [
  {
    Generic,
    Account: { UpdateEmail },
  },
] = getStrings();

const updateEmailSchema = yup
  .object({
    email: yup.string().required(UpdateEmail.EmailRequired),
    password: yup.string().required(Generic.Required),
  })
  .required();

export type UpdateEmailSchema = yup.InferType<typeof updateEmailSchema>;

export default updateEmailSchema;
