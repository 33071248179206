import { CSSObject } from '@chakra-ui/react';

export const ForgotPasswordButton: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  fontSize: 'sm',
  borderRadius: 'base',
  borderColor: 'beta.400',
  color: 'alpha.800',
  mt: '3',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  fontFamily: 'Fredoka',
  fontWeight: 'black',
  textTransform: 'uppercase',
  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    color: 'alpha.800',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  },
  _active: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    border: '2px solid',
    borderColor: '#CDFF98',
    boxShadow: 'none',
    color: 'alpha.800',
    textShadow: 'none',
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  mt: ['0', null, '16'],
};
