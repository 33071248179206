import React from 'react';
import { Global } from '@emotion/react';
import bg from '@/assets/wellbet/images/backgrounds/landscape.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bg})`,
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundAttachment: 'fixed',
          '@media (min-width: 1366px)': {
            backgroundSize: '100%',
            backgroundAttachment: 'fixed',
          },
          // FIXME: remove hard coded hex
          backgroundColor: '#004685',
        },
      }}
    />
  );
}
