import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TextCheckbox: CSSObject = {
  color: 'white',
};

export const buttonCloseAccountProps: CustomButtonProps = {
  variant: 'solid',
  fontFamily: 'CarterOne',
  textTransform: 'capitalize',
  fontWeight: 'normal',
};
