/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
  ButtonGroup,
  chakra,
  Flex,
  Icon,
  Image,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronLeft } from '@styled-icons/entypo/ChevronLeft';
import { CalendarInfo } from '@styled-icons/fluentui-system-filled/CalendarInfo';
import { FormattedMessage } from 'react-intl';
import { useQueryMysteryBet } from '@/api/racing/mysteryBet/mysteryBet.hooks';
import { Button } from '@/components/Button/Button';
import {
  TMysteryBet,
  TMysteryBetData,
  TMysteryBetErrorResponse,
} from '@/api/racing/mysteryBet/mysteryBet.types';
import {
  useAddMysteryBetToBetSlip,
  useBetSlipManageBets,
} from '@/components/Betslip/Services/Betslip.hooks';
import { EGeneralStatus, TRaceMarket, TRaceMeta } from '@/lib/DBModels';
import { badgeStyles } from '../Badges/styles/Badges.styles';
import { mysteryBetStyles } from './styles/MysteryBet.styles';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';
import { TBetForRace } from '@/api/racing/bets/bets.types';
import { getIconBySportName, getPositionValue } from '@/helpers/utils';
import IconSvg from '@/components/common/IconSvg';

type TMysteryBetContainerProps = {
  race: TRaceMeta;
  venueName: string;
  betsForRace?: TBetForRace[];
  market?: TRaceMarket;
};

export default function MysteryBetContainer({
  race,
  venueName,
  betsForRace,
  market,
}: TMysteryBetContainerProps) {
  const [viewMode, setViewMode] = useState<TMysteryBetViewMode>('selection');
  const [selectedProp, setSelectedProp] = useState<TMysteryBet | undefined>(
    undefined
  );

  useEffect(() => {
    setViewMode('selection');
    setSelectedProp(undefined);
  }, [race.race_id]);

  const rollOverEnabled = !!market?.market_info?.mystery_bet_rollover_enabled;

  const params = {
    race_id: race.race_id ?? '',
    ...(selectedProp &&
      rollOverEnabled && {
        rollover_price: selectedProp.price,
      }),
  };

  const query = useQueryMysteryBet({
    params,
  });

  // eslint-disable-next-line prefer-destructuring
  const data = query.data;
  const isLoading = query.isLoading || !query.isFetched;

  const handlePropClick = (bet: TMysteryBet) => {
    setViewMode('review');
    setSelectedProp(bet);
  };

  const handleBackButtonClick = () => {
    setViewMode('selection');
    setSelectedProp(undefined);
  };

  const { addMysteryBetToBetSlip } = useAddMysteryBetToBetSlip();
  const { mysteryBetInBetslip } = useBetSlipManageBets();
  const { setBet } = getBetSlipStoreActions();

  const isInBS = mysteryBetInBetslip('mystery_bet', race.race_id ?? '');

  const handleAddBetToBetslip = () => {
    const rolloverRaceIds = data?.bets.flatMap(
      (bet) => bet.rollovers?.map((s) => ({ race_id: s.race_id })) ?? []
    );

    if (selectedProp) {
      addMysteryBetToBetSlip(
        selectedProp,
        data,
        venueName,
        'Mystery Bet',
        race
      );
    }

    let mysteryBetData;
    if (!selectedProp?.rolled_over) {
      // Remove the rollovers property if the rollover is not enabled
      mysteryBetData = data?.bets.map((bet) => {
        const { rollovers, ...rest } = bet;
        return rest;
      });
    } else {
      mysteryBetData = data;
    }

    setBet({
      id: uuid(),
      type: 'Single',
      odds: selectedProp?.price ?? 0,
      priceType: 'mystery_bet',
      betType: 'win',
      mystery_bet: mysteryBetData,
      event_market_name: venueName,
      rollovers:
        selectedProp?.rolled_over && rolloverRaceIds?.length > 0
          ? rolloverRaceIds
          : undefined,
      misc: {
        ...race,
        race,
      },
    });
  };

  const handleRollover = () => {
    if (selectedProp) {
      setSelectedProp({
        ...selectedProp,
        rolled_over: true,
      });
    }
  };

  const isRolloverOffered =
    data?.bets?.[0].rollovers?.length > 0 &&
    !!market?.market_info?.mystery_bet_rollover_enabled;

  if (race.status !== EGeneralStatus.Open) return null;

  return (
    <AnimatePresence exitBeforeEnter>
      <MysteryBetController
        data={data}
        handleBackButtonClick={handleBackButtonClick}
        selectedProp={selectedProp}
        setViewMode={setViewMode}
        handleRollover={handleRollover}
        handlePropClick={handlePropClick}
        viewMode={viewMode}
        addToBetslip={handleAddBetToBetslip}
        error={query.error as any}
        isInBetslip={isInBS ?? true}
        betsForRace={betsForRace}
        hasRollover={isRolloverOffered}
        isLoading={isLoading}
      />
    </AnimatePresence>
  );
}

type TMysteryBetControllerProps = {
  viewMode?: TMysteryBetViewMode;
  setViewMode?: (mode: TMysteryBetViewMode) => void;
  handlePropClick?: (bet: TMysteryBet) => void;
  handleRollover?: () => void;
  handleBackButtonClick?: () => void;
  selectedProp?: TMysteryBet | undefined;
  data?: TMysteryBetData | undefined;
  addToBetslip?: () => void;
  error?: TMysteryBetErrorResponse;
  isInBetslip?: boolean;
  betsForRace?: TBetForRace[];
  hasRollover?: boolean;
  isLoading?: boolean;
};

export const MysteryBetController = ({
  viewMode,
  setViewMode,
  handlePropClick,
  handleBackButtonClick,
  selectedProp,
  handleRollover,
  data,
  addToBetslip,
  error,
  isInBetslip,
  betsForRace,
  hasRollover,
  isLoading,
}: TMysteryBetControllerProps) => {
  const hasMysteryBet = betsForRace?.some(
    (bet) => bet.price_type === 'mystery_bet'
  );

  if (hasMysteryBet) return <PlacedMysteryBetView betsForRace={betsForRace} />;

  if (error) return null;

  if (viewMode === 'review')
    return (
      <MysteryBetReviewView
        selectedProp={selectedProp}
        handleRollover={handleRollover}
        handleBackButtonClick={handleBackButtonClick}
        data={data}
        addToBetslip={addToBetslip}
        isInBetslip={isInBetslip}
        hasRollover={hasRollover}
        isLoading={isLoading}
      />
    );

  return (
    <MysteryBetSelectionView
      setViewMode={setViewMode}
      handlePropClick={handlePropClick}
      handleBackButtonClick={handleBackButtonClick}
      selectedProp={selectedProp}
      hasRollover={hasRollover}
      data={data}
      isLoading={isLoading}
    />
  );
};

export const MysteryErrorView = () => (
  <motion.div {...mysteryBetStyles.motionLeftToRight}>
    <Flex {...mysteryBetStyles.flexWrapperMysteryErrorView}>
      <Icon color="beta.500" as={CalendarInfo} boxSize="6" />
      <Text {...mysteryBetStyles.textError}>
        <FormattedMessage id="mysteryBet.error" />
      </Text>
      <MysteryTooltipView />
    </Flex>
  </motion.div>
);

export const PlacedMysteryBetView = ({
  betsForRace,
}: Pick<TMysteryBetControllerProps, 'betsForRace'>) => {
  const navigate = useNavigate();
  if (!betsForRace?.length) return null;

  const placedBet = betsForRace.find((bet) => bet.price_type === 'mystery_bet');

  const selection = placedBet?.proposition_info?.find(
    (prop) => prop.leg_num === 1 || prop.leg_num === null
  );

  const rollover = placedBet?.mystery_bet_rollovers?.find(
    (prop) => prop.leg_num === 2
  );

  return (
    <Flex {...mysteryBetStyles.flexWrapperMysteryBetSelectionView}>
      <motion.div {...mysteryBetStyles.motionLeftToRight}>
        <Flex gap="3" flexDir="column">
          <Flex {...mysteryBetStyles.flexWrapperLogoViewBetButton}>
            <Image {...mysteryBetStyles.imageLogo} />
            <Button
              onClick={() => navigate('/account/my-bets')}
              {...mysteryBetStyles.buttonMyBets}
            >
              <FormattedMessage id="mysteryBet.viewInMyBets" />
            </Button>
          </Flex>
          <Flex {...mysteryBetStyles.flexWrapperPlacedBetViewGroup}>
            {selection && (
              <Flex
                {...mysteryBetStyles.flexWrapperMysteryRolloverView}
                {...mysteryBetStyles.flexWrapperMysteryPlacedBetView}
              >
                <chakra.span {...mysteryBetStyles.spanSelectionText}>
                  <FormattedMessage id="racing.mysteryBet.yourSelection" />
                </chakra.span>{' '}
                <chakra.span {...mysteryBetStyles.spanPositionType}>
                  {getPositionValue(selection?.proposition_type)}
                </chakra.span>
                <Text {...mysteryBetStyles.textSlash}>/</Text>
                <chakra.span {...mysteryBetStyles.textSpanWhite}>
                  {selection.runner_number}. {selection?.name}{' '}
                  {selection?.barrier_number
                    ? `(${selection.barrier_number})`
                    : ''}
                </chakra.span>
              </Flex>
            )}

            {rollover && (
              <Flex
                {...mysteryBetStyles.flexWrapperMysteryRolloverView}
                {...mysteryBetStyles.flexWrapperMysteryPlacedBetView}
              >
                <chakra.span
                  {...mysteryBetStyles.textRollover}
                  {...mysteryBetStyles.spanRollOverText}
                >
                  <FormattedMessage id="racing.mysteryBet.rollover" />
                </chakra.span>
                <Flex {...mysteryBetStyles.flexRaceInfo}>
                  <IconSvg
                    {...mysteryBetStyles.iconRace}
                    name={getIconBySportName(rollover?.race_type)}
                  />
                  {rollover?.venue_name} R{rollover?.race_number}
                </Flex>
                <Text {...mysteryBetStyles.textSlash}>/</Text>
                <chakra.span {...mysteryBetStyles.textPriceInfo}>
                  @{placedBet?.bet_odds.toFixed(2)}
                </chakra.span>
                <Text {...mysteryBetStyles.textSlash}>/</Text>
                <chakra.span {...mysteryBetStyles.textSpanWhite}>
                  {getPositionValue(rollover?.proposition_type)}
                </chakra.span>
                <Text {...mysteryBetStyles.textSlash}>/</Text>
                <chakra.span {...mysteryBetStyles.textSpanWhite}>
                  {rollover?.runner_number}. {rollover?.name}{' '}
                  {rollover?.barrier_number
                    ? `(${rollover.barrier_number})`
                    : ''}
                </chakra.span>
              </Flex>
            )}
          </Flex>
        </Flex>
      </motion.div>
    </Flex>
  );
};

export const MysteryBetSelectionView = ({
  handlePropClick,
  data,
  hasRollover,
  isLoading,
}: Pick<
  TMysteryBetControllerProps,
  'handlePropClick' | 'data' | 'hasRollover' | 'isLoading'
>) => {
  if (!data?.bets?.length || isLoading) return null;

  return (
    <Flex {...mysteryBetStyles.flexWrapperMysteryBetSelectionView}>
      <motion.div {...mysteryBetStyles.motionLeftToRight}>
        <Flex {...mysteryBetStyles.flexWrapperLogoOdds}>
          <Image {...mysteryBetStyles.imageLogo} />
          <Flex {...mysteryBetStyles.flexWrapperOdds}>
            {data?.bets
              .filter((bet) => bet.available)
              .map((bet) => (
                <motion.div
                  key={bet.price}
                  {...mysteryBetStyles.configsBetOptionsMotion}
                >
                  <Button
                    disabled={!bet.available}
                    data-active={!bet.available}
                    onClick={() =>
                      handlePropClick ? handlePropClick(bet) : undefined
                    }
                    {...mysteryBetStyles.buttonOdds}
                  >
                    {bet.price.toFixed(2)}
                  </Button>
                </motion.div>
              ))}
            <MysteryTooltipView hasRollover={hasRollover} />
          </Flex>
        </Flex>
      </motion.div>
    </Flex>
  );
};

export const MysteryBetReviewView = ({
  handleBackButtonClick,
  data,
  selectedProp,
  handleRollover,
  addToBetslip,
  isInBetslip,
  hasRollover,
  isLoading,
}: Pick<
  TMysteryBetControllerProps,
  | 'handleBackButtonClick'
  | 'data'
  | 'selectedProp'
  | 'handleRollover'
  | 'addToBetslip'
  | 'isInBetslip'
  | 'hasRollover'
  | 'isLoading'
>) => (
  <Flex
    {...mysteryBetStyles.flexWrapperMysteryBetSelectionView}
    {...mysteryBetStyles.flexWrapperMysteryBetReviewView}
  >
    <motion.div {...mysteryBetStyles.motionRightToLeft}>
      <Flex {...mysteryBetStyles.flexWrapperAddToBetslip}>
        <Flex {...mysteryBetStyles.flexWrapperWithTooltip}>
          <Icon
            as={ChevronLeft}
            {...mysteryBetStyles.iconBack}
            onClick={handleBackButtonClick}
          />
          <ButtonGroup {...mysteryBetStyles.buttonGroupAddToBetslip}>
            <Button {...mysteryBetStyles.buttonMysteryLabel}>
              <MysteryLabelView />
            </Button>
            <Button
              onClick={addToBetslip}
              isDisabled={!!isInBetslip}
              {...mysteryBetStyles.buttonAddToBetslip}
            >
              <FormattedMessage id="generic.addtoBetSlip" /> @
              {selectedProp?.price.toFixed(2)}
            </Button>
          </ButtonGroup>
          <MysteryTooltipView />
        </Flex>
        {isLoading && <Skeleton w="80px" h="10" />}
        {hasRollover && (
          <Button
            isDisabled={!!selectedProp?.rolled_over || !!isInBetslip}
            onClick={handleRollover}
            {...mysteryBetStyles.buttonRollOver}
          >
            <FormattedMessage
              id={
                selectedProp?.rolled_over
                  ? 'mysteryBet.rolledOver'
                  : 'mysteryBet.rollOver'
              }
            />
          </Button>
        )}
      </Flex>

      <MysteryRolloverView data={data} selectedProp={selectedProp} />
    </motion.div>
  </Flex>
);

export const MysteryRolloverView = ({
  data,
  selectedProp,
}: Pick<TMysteryBetControllerProps, 'data' | 'selectedProp'>) => {
  if (!data?.bets?.length || !selectedProp?.rolled_over) return null;

  const firstBet = data?.bets[0];
  const rollover = firstBet?.rollovers?.[0];

  return (
    <motion.div {...mysteryBetStyles.configsMysteryRolloverViewMotion}>
      <Flex {...mysteryBetStyles.flexWrapperRollover}>
        <Flex {...mysteryBetStyles.flexWrapperMysteryRolloverView}>
          <Text {...mysteryBetStyles.textRollover}>
            <FormattedMessage id="racing.mysteryBet.rollover" />
          </Text>{' '}
          <Flex {...mysteryBetStyles.flexRaceInfo}>
            <IconSvg
              {...mysteryBetStyles.iconRace}
              name={getIconBySportName(rollover?.race_type)}
            />{' '}
            {`${rollover?.venue_name} R${rollover?.race_number}`}
          </Flex>{' '}
          <Text {...mysteryBetStyles.textSlash}>/</Text>
          <Text {...mysteryBetStyles.textPriceInfo}>
            @{firstBet?.price.toFixed(2)}
          </Text>
          <Text {...mysteryBetStyles.textSlash}>/</Text>
          <Flex {...mysteryBetStyles.flexWrapperMysteryLabelView}>
            <MysteryLabelView />
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
};

export const MysteryLabelView = () => (
  <>
    <IconSvg name="sports/mystery-bet" {...mysteryBetStyles.iconMystery} />{' '}
    MYSTERY{' '}
    <IconSvg name="sports/mystery-bet" {...mysteryBetStyles.iconMystery} />
  </>
);

const MysteryTooltipView = ({
  hasRollover = true,
}: Pick<TMysteryBetControllerProps, 'hasRollover'>) => (
  <Tooltip
    {...badgeStyles.tooltipWrapper}
    shouldWrapChildren
    hasArrow
    label={
      <VStack alignItems="start">
        <Text {...badgeStyles.textTitle}>
          <FormattedMessage id="generic.mysteryBet" />
        </Text>

        <Text {...badgeStyles.textDescription}>
          <FormattedMessage id="racing.mysteryBet.info" />
        </Text>

        {hasRollover && (
          <>
            <Text {...badgeStyles.textTitle}>
              <FormattedMessage id="racing.mysteryBet.wantRollover" />
            </Text>
            <Text {...badgeStyles.textDescription}>
              <FormattedMessage id="racing.mysteryBet.mysteryBetRolloverInfo" />
            </Text>
          </>
        )}
      </VStack>
    }
  >
    <Flex h="100%" alignItems="center">
      <Flex {...mysteryBetStyles.flexWrapperMysteryTooltipIcon}>
        <IconSvg name="tooltip-icon" {...mysteryBetStyles.tooltipIcon} />
      </Flex>
    </Flex>
  </Tooltip>
);

type TMysteryBetViewMode = 'selection' | 'review';
