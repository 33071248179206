import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'maintenance.heading': `Technical foul! there's an error with the current page.`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
    In the meantime, you can refresh the page, or try again later.`,
  'error.heading': 'Timeout! Slambet is under maintenance.',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
    In the meantime, you can refresh the page, or try again later.`,
  'onboarding.locationnotallowed.header':
    "Looks like you've stepped out of bounds!",
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing Slambet  from outside of Australia.',
  'betslip.betslipmodal.emptybetslipheading': 'Time To Score',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'generic.sportAZ': 'A-Z Sports',
  'sports.allsports.azheading': 'A-Z Sports',
};
