import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'beta.500',

      background: {
        background: 'rgba(129, 83, 255, 0.50)',
        display: 'flex',
        flexDir: 'column',
        bgRepeat: ['no-repeat', null, null, 'unset'],
        bgSize: ['cover', null, null, 'unset'],
        borderRadius: 'lg',
        px: ['0', null, null, '5'],
        py: ['0', null, null, '3.5'],
        mb: ['0', null, null, '4'],
        boxShadow:
          '0px 3px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.10) inset',
        backdropFilter: 'md',
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        borderRadius: 'none',
        background: 'var(--mega-alpha-alpha-900, #7244EE)',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.40)',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          px: '2',
          py: 1,
          border: 'none',
          borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
          boxShadow: [
            '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
            null,
            null,
            '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
          ],

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: '18px',
        mr: '2',
        filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
        color: 'gamma.400',
      },
      headerSX: {
        cursor: 'default',
        color: 'white',
        fontSize: 'xs',
        textShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        height: 'auto',
        boxShadow: 'none',

        py: 0,
        px: [null, null, null, null],

        '&:hover': {
          bg: 'transparent',
          color: 'white',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: [null, null, null, 'md'],
        mb: '0',
      },
      link: {
        ...base.link,
        textTransform: 'uppercase',
        fontSize: 'xs',
        fontWeight: 'bold',
        height: '45px',
        color: 'white',
        fontFamily: 'accent',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: [null, null, null, 'md'],
        background: 'var(--mega-alpha-alpha-900, #7244EE)',
        boxShadow: [
          '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
          null,
          null,
          '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
        ],
        border: [null, null, null, '1px solid rgba(255, 255, 255, 0.05)'],
        borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',

        '&:is(p)': {
          background: 'none',
          py: ['2', '0'],
          border: 'none',
          color: ['gamma.400', 'white'],
          textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          ':hover': {
            border: 'none',
          },
        },
        py: '3',
        px: '2',
        ml: '0',
        '&&': {
          mb: '1.5',
        },

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          bg: 'blackAlpha.700',
          color: 'gamma.400',
          border: '1px solid',
          borderColor: 'gamma.400',
          textShadow: 'none',
        },

        ':active': {
          background: 'alpha.700',
          opacity: '1',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
        },

        '> a, .dropDownIcon, a': {
          color: 'alpha.100',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '2'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        h: '45px',
        mb: '1.5',
        '.dropDownInner': {
          px: '2.5',
          bg: 'blackAlpha.700',
          color: 'gamma.400',
          border: '1px solid',
          borderColor: 'gamma.400',
          textShadow: 'none',
          'svg:not(.dropDownIcon)': {
            fill: 'gamma.400',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'gamma.500',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
