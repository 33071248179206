import {
  chakra,
  ChakraComponent,
  Flex,
  Image,
  Switch,
  Text,
  TextProps,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import { styleImports } from './Toggle.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import IconSvg, { TIconSvg } from '@/components/common/IconSvg';

const themeName = getThemeName();

export const FlexWrapper = chakra(Flex, {
  label: 'toggle-FlexWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.FlexWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const StitchWrapper = chakra(Flex, {
  label: 'toggle-StitchWrapper',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.StitchWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const TextToggle = chakra(Text, {
  label: 'toggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.TextToggle?.(isActive);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

export const Switcher = chakra(Switch, {
  label: 'toggle-ToggleSwitcher',
  shouldForwardProp: (prop) => !['isChecked'].includes(prop),
  baseStyle: ({ isChecked }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.Switcher?.(isChecked);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const CustomSwitcher = chakra('div', {
  label: 'toggle-ToggleSwitcher',
  shouldForwardProp: (prop) => !['isChecked'].includes(prop),
  baseStyle: ({ isChecked }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.CustomSwitcher?.(isChecked);
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ImageLeft = chakra(Image, {
  label: 'toggle-ImageLeft',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ImageLeft;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const ButtonToggle = chakra(Button, {
  label: 'mobileToggle-ButtonToggle',
  shouldForwardProp: (prop) => !['isActive', 'category'].includes(prop),
  baseStyle: ({ isActive, category }: any) => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ButtonToggle?.(isActive, category);
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<
  'div',
  ButtonProps & { isActive: boolean; category: string }
>;

export const Icon = chakra(IconSvg, {
  label: 'mobile-icon-sport',
  baseStyle: () => ({
    boxSize: '5',
    mr: '2',
    ...(() => {
      try {
        return styleImports[themeName]?.Icon;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'svg', TIconSvg>;

export const ImageRight = chakra(Image, {
  label: 'toggle-ImageRight',
  baseStyle: () => ({
    ...(() => {
      try {
        return styleImports[themeName]?.ImageRight;
      } catch (err) {
        return {};
      }
    })(),
  }),
});
