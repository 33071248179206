import React, { ReactElement } from 'react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG } from '@/constants/themeConfig';
import {
  AdditionalLink,
  AdditionalLinkProps,
} from '@/views/Home/links/components/HomeLinks/SportHomeLinks/AdditionalLink';

const themeName = getThemeName();

export type CustomLink = {
  linkElement: ReactElement;
  insertAt: 'start' | 'end';
};

export const getSportHomeLinksConfig = () => {
  const additionalLinks: CustomLink[] = [];
  let spliceCount = 12;

  switch (themeName) {
    case EThemesOG.Sterlingparker: {
      spliceCount = 10;
      break;
    }
    case EThemesOG.Juicybet: {
      spliceCount = 6;
      const linkProps: AdditionalLinkProps = {
        to: '/Sports',
        text: 'All Sports',
      };
      const allSportsLink: CustomLink = {
        linkElement: <AdditionalLink {...linkProps} />,
        insertAt: 'end',
      };
      additionalLinks.push(allSportsLink);
      break;
    }
    case EThemesOG.Oldgill: {
      spliceCount = 10;
      const linkProps: AdditionalLinkProps = {
        to: '/Sports',
        text: 'All Sports',
      };
      const allSportsLink: CustomLink = {
        linkElement: <AdditionalLink {...linkProps} />,
        insertAt: 'start',
      };
      additionalLinks.push(allSportsLink);
      break;
    }
    case EThemesOG.Buffalobet: {
      spliceCount = 5;
      const linkProps: AdditionalLinkProps = {
        to: '/Sports',
        text: 'All Sports',
      };
      const allSportsLink: CustomLink = {
        linkElement: <AdditionalLink {...linkProps} />,
        insertAt: 'end',
      };
      additionalLinks.push(allSportsLink);

      break;
    }
    case EThemesOG.Slambet: {
      spliceCount = 5;
      const linkProps: AdditionalLinkProps = {
        to: '/Sports',
        text: 'All Sports',
      };
      const allSportsLink: CustomLink = {
        linkElement: <AdditionalLink {...linkProps} />,
        insertAt: 'end',
      };
      additionalLinks.push(allSportsLink);

      break;
    }
    default: {
      break;
    }
  }

  return {
    spliceCount,
    additionalLinks,
  };
};
