import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  borderTop: '1px solid',
  borderColor: 'rgba(0, 0, 0, 0.15)',
  color: 'white',
  boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.1)',
  backgroundClip: 'padding-box',
  _last: {
    border: 'none',
    boxShadow: 'none',
  },
};

export const TextListName: CSSObject = {
  fontSize: ['xs', null, 'sm'],
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'white' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'gray.50',
  fontSize: ['2xs', null, 'xs'],
  p: {
    color: 'gamma.400',
  },
  svg: {
    color: 'red.400',
  },
};

export const IconSport: CSSObject = {
  color: 'gamma.400',

  boxShadow: 'none',
  svg: {
    color: 'gamma.400',
  },
};

export const IconChevron: CSSObject = {
  color: 'white',
};

export const FlexEndWrapper: CSSObject = {
  fontSize: '2xs',
  '.chakra-badge.timestamp': {
    fontSize: '2xs',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const SubTextIcon: CSSObject = {
  color: 'white',
  '&&': {
    color: 'white',
  },
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'white',
};
