import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  borderTop: '1px dashed var(--bc-black-alpha-300, rgba(0, 0, 0, 0.16))',
  h: ['63px', null, '47px'],
  px: '12px',
  py: 0,

  gridTemplateRows: 'auto',
  gridTemplateAreas: '"icon details time chevron"',

  _first: { borderTop: 'transparent' },

  '.details': {
    width: '100%',
    overflow: 'hidden',
    pr: '8px',
  },
};
export const TextListName: CSSObject = {
  color: '#7A3B1E',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  mb: ['0', null, '-1px'],
  whiteSpace: 'normal',
};
export const TextSpanName = (): CSSObject => ({
  color: '#7A3B1E',
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'gamma.500',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: '#7A3B1E',
  boxSize: '30px',
  mr: '6px',
  boxShadow: 'none',
  bg: 'none',
  p: 'none',
};

export const IconChevron: CSSObject = {
  color: '#7A3B1E',
  ml: '0',
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '0.5',
  '.countdown-flex, .timestamp': {
    color: '#7A3B1E',
    fontSize: '12px',
    bg: 'transparent',
  },
};
export const FlexCardInfoWrapper: CSSObject = {};
export const TextCardInfoBadge: CSSObject = {};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'blackAlpha.400',
    boxSize: '20px',
  },
};

export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: '#8F4623',
};
