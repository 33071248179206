import { css } from 'styled-components';

/* ** Image Positioning Changing Issue
 *  background-attachment: fixed; not being supported on safari iOS causes the background-size: cover property to take
 *  over sizing based on the div container not the viewport like a fixed background-attachment
 *  behaves on other browsers (web).
 *  Below the native size of the image, the height is fixed to the images native size to ensure
 *  the graphic is not changing positioning because the content height is changing
 */

export const mainContainer = css`
  min-height: 100vh;

  // ** Image Positioning Changing Issue
  background-attachment: initial;
  background-size: auto 1300px;
  // Breakpoint at the native max width of the bg image
  // once the screen size is >= to native size, update to cover
  @media (min-width: 1020px) {
    background-attachment: fixed;
    background-size: cover;
  }
`;

export const mainContainerShouldHideOverflow = css`
  height: 100%;
  overflow: hidden;
  background-position: bottom;
  background-repeat: no-repeat;

  // ** Image Positioning Changing Issue
  background-attachment: initial;
  background-size: auto 1300px;
  // Breakpoint at the native max width of the bg image
  // once the screen size is >= to native size, update to cover
  @media (min-width: 1020px) {
    background-attachment: fixed;
    background-size: cover;
  }
`;

export const contentContainer = css`
  padding: var(--bc-sizes-6) var(--bc-sizes-4);
  flex: 1;
  min-height: auto;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.device.tablet} {
    padding: var(--bc-sizes-14) var(--bc-sizes-16);
  }
`;

export const fullWidthContentContainer = css`
  padding: 0;
  flex: 1;
  min-height: auto;
  position: relative;
  z-index: 1;
`;

export const featureImage = css`
  ${({ theme }) => theme.device.tablet} {
    &:after {
      content: '';
      width: 450px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      background-position: right 50px;
      background-repeat: no-repeat;
    }
  }
`;
