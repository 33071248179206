import React, { useEffect } from 'react';

import {
  ModalPageContainer,
  ModalBoxWrapper,
  HeaderContainer,
  HeaderText,
  ContentContainer,
  CloseButton,
} from './styles/Modal.styles';

import { ModalProps } from './types';

const Modal: React.FC<ModalProps> = ({
  children,
  headerText,
  onClose,
  isOpen,
  medium,
  small,
  xSmall,
  hasOutsideClick = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <ModalPageContainer
      onClick={hasOutsideClick ? onClose : () => {}}
      role="dialog"
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDesc"
    >
      <ModalBoxWrapper
        onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
        medium={medium}
        small={small}
        xSmall={xSmall}
      >
        <HeaderContainer>
          <HeaderText id="dialogTitle">{headerText}</HeaderText>
          <CloseButton onClick={onClose} />
        </HeaderContainer>
        <ContentContainer id="dialogDesc">{children}</ContentContainer>
      </ModalBoxWrapper>
    </ModalPageContainer>
  );
};

export default Modal;
