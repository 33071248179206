import {
  FlexProps,
  TextProps,
  TooltipProps,
  TransformProps,
} from '@chakra-ui/react';
import { styleImports } from './Flucs.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type FlucsSchema = Partial<{
  wrapper: FlexProps;
  innerWrapper: FlexProps;
  priceTitle: TextProps;
  priceNumber: TextProps;
  priceText: TextProps;
  priceTextIncreased: TextProps;
  priceIcon: TextProps;
  priceIconIncreased: TextProps & TransformProps;
  lottieWrapper: FlexProps;
  tooltipWrapper: Partial<TooltipProps>;
}>;

export const flucsStyles: FlucsSchema = {
  wrapper: {
    'data-testid': 'flucs-wrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  innerWrapper: {
    'data-testid': 'flucs-innerWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.innerWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceTitle: {
    'data-testid': 'flucs-priceTitle',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.priceTitle;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceNumber: {
    'data-testid': 'flucs-priceNumber',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.priceNumber;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceText: {
    'data-testid': 'flucs-priceText',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.priceText;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceTextIncreased: {
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.priceTextIncreased;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceIcon: {
    'data-testid': 'flucs-priceIcon',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.priceIcon;
      } catch (err) {
        return {};
      }
    })(),
  },
  priceIconIncreased: {
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.priceIconIncreased;
      } catch (err) {
        return {};
      }
    })(),
  },
  lottieWrapper: {
    'data-testid': 'flucs-lottieWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.lottieWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
  tooltipWrapper: {
    'data-testid': 'flucs-tooltipWrapper',
    ...(() => {
      try {
        return styleImports[themeName]?.flucsStyles?.tooltipWrapper;
      } catch (err) {
        return {};
      }
    })(),
  },
};
