import { CSSObject } from '@chakra-ui/react';

export const FlexWrapper: CSSObject = {
  p: ['2', '4'],
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '13px',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'white',
    border: '2px',
    borderColor: 'beta.500',
    color: 'black',
    borderRightRadius: 'lg',
    fontWeight: 'semibold',

    _placeholder: {
      color: 'gray.200',
    },

    '&:hover, &:active, &:focus, &:visited': {
      bg: 'gray.100',
      borderColor: 'beta.600',
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    bg: 'beta.500',
    color: 'white',
    fontSize: '13px',
    fontWeight: 'black',
    borderLeftRadius: 'lg',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mx: '4',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'lg',
  mb: '4',
};

export const InfoHeader: CSSObject = {
  borderTopRadius: 'lg',
  bg: 'gamma.400',
  h: '30px',
  px: '2',
  py: '1.5',
  alignItems: 'center',
};

export const InfoText: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'bold',
};
