import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { StitchWrapper } from '@/views/Home/components/Toggle/styles/Toggle.styles';
import volleyball from '@/assets/forsport/images/volleyball.svg';

const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  label: 'ToggleFlexWrapper',
  baseStyle: {
    alignItems: 'center',
    contain: 'layout',
    height: '65px',
    justifyContent: 'center',
    mx: 'auto',
    borderRadius: 'lg',
    mb: '4',
    p: '1.5',
    bg: 'linear-gradient(180deg, #2C3A56 0%, #202A3E 100%)',
    border: '1px solid',
    borderColor: '#5C6D8E',
  },
});

const TextToggle: ChakraComponent<'text', TextProps & { isActive: boolean }> =
  chakra(Text, {
    label: 'TextToggle',
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
    baseStyle: ({ isActive }: any) => ({
      fontFamily: 'KHInterference',
      fontWeight: 'bold',
      fontSize: 'xl',
      textTransform: 'uppercase',
      color: isActive ? 'beta.500' : 'gray.400',
      letterSpacing: 'normal',
      px: '2.5',
    }),
  });

const Switcher = chakra(Switch, {
  baseStyle: () => ({
    '.chakra-switch__track': {
      pos: 'relative',
      bg: 'blackAlpha.600',
      boxShadow:
        '0px 2px 2px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    },

    '.chakra-switch__thumb': {
      bg: `url(${volleyball})`,
      bgSize: 'cover',
      boxSize: '34px',
      pos: 'absolute',
      inset: '-1',
      bottom: '-1',
      my: 'auto',
      left: '-2.5',

      '&[data-checked]': { left: '-2.5', ml: '2' },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <StitchWrapper>
        <TextToggle
          data-cy="racing-toggle-text"
          isActive={!category || category === 'racing'}
        >
          Racing
        </TextToggle>

        <Switcher
          onChange={() => {
            setSearchParams({
              category: !category || category === 'racing' ? 'sport' : 'racing',
            });
          }}
          isChecked={!!category && category !== 'racing'}
          colorScheme="blackAlpha"
          size="lg"
          data-cy="categorySwitcher1111"
        />
        <TextToggle
          data-cy="sports-toggle-text"
          isActive={category === 'sport'}
        >
          Sports
        </TextToggle>
      </StitchWrapper>
    </FlexWrapper>
  );
}
