import { useReducer } from 'react';

function reducer<T>(state: T, action: { type: keyof T; payload: T[keyof T] }) {
  return {
    ...state,
    [action.type]: action.payload,
  } as T;
}

export type TSetFieldValue<T> = (key: keyof T, value: T[keyof T]) => void;

export function useComplexState<T>(initialState: T): {
  state: T;
  setFieldValue: TSetFieldValue<T>;
};
export function useComplexState<T, TInitialState>(
  initialState: TInitialState,
  initializer: (value: TInitialState) => T
): { state: T; setFieldValue: TSetFieldValue<T> };
export function useComplexState<T, TInitialState>(
  initialState: TInitialState,
  initializer?: (value: TInitialState) => T
) {
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    initializer as (value: TInitialState) => T
  );

  return {
    state: state as T,
    setFieldValue: (key: keyof T, value: T[keyof T]) =>
      dispatch({
        type: key as unknown as never,
        payload: value as unknown as never,
      }),
  };
}
