import React, { useEffect, useState } from 'react';
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import toast from 'react-hot-toast';

import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Text } from '@chakra-ui/react';
import Modal from '../../../components/Modal';
import Select from '../../../components/FormElements/Select';
import Input from '@/components/FormElements/Input';
import {
  PageHeader,
  Subheading,
  ModalDivider,
  ModalContentWrapper,
  ModalSubheading,
  ButtonWrapper,
  DepositButton,
  DepositLimitGrid,
  DepositLimitContainer,
  PendingLimitContainer,
  LimitLabel,
  LimitValue,
  TextSubheading,
  FormDivider,
  DepositLimitInputContainer,
  inputProps,
  buttonRemoveProps,
  buttonRemoveModalProps,
  buttonConfirmModalProps,
} from './DepositLimit.styles';

import { centsToDollars, formatStake, getStrings } from '@/helpers/utils';
import depositLimitSchema from '../services/schemas/depositLimitSchema';
import { getDepositLimit, postDepositLimit } from '../services/account.actions';
import { DepositLimitData } from '../../onboarding/services/onboarding.types';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { formatDateFullMonth, formatTime } from '../../../lib/Time';
import { ContainerForms } from '../styles/account.styles';
import { Button } from '@/components/Button/Button';
import { useDepositLimit } from '@/store/AuthStore';

const DepositLimit: React.FC = () => {
  dayjs.extend(relativeTime);

  const [
    {
      Account: { DepositLimit: strings },
      Generic,
    },
  ] = getStrings();

  const [showConfirmModal, setShowConfirmModal] = useState<
    'update' | 'remove' | undefined
  >(undefined);
  const intl = useIntl();
  const dispatch = useDispatch();
  const depositLimit = useDepositLimit();
  const currentLimitDollars = (depositLimit?.deposit_limit_current || 0) / 100;
  const waitingLimitDollars = (depositLimit?.deposit_limit_waiting || 0) / 100;

  useEffect(() => {
    dispatch(getDepositLimit());
  }, [dispatch]);

  const initialValues: DepositLimitData = {
    amount: undefined,
    frequency: depositLimit?.deposit_limit_frequency_current
      ? Number(depositLimit?.deposit_limit_frequency_current)
      : undefined,
  };

  const {
    values,
    errors,
    isSubmitting,
    validateForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: (formValues) => {
      const amount =
        showConfirmModal === 'remove' ? null : formValues.amount?.toString();
      const frequency =
        showConfirmModal === 'remove' ? null : formValues.frequency?.toString();
      dispatch(
        postDepositLimit({
          amount,
          frequency,
        })
      );
      setFieldValue('amount', '').catch((err) => console.error(err));
      setSubmitting(false);
      setShowConfirmModal(undefined);
      resetForm();
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validate: async (formValues) => {
      try {
        await validateYupSchema(formValues, depositLimitSchema, true, {
          coolingOffEnds: depositLimit?.deposit_limit_cooldown_ends,
          currentLimit: waitingLimitDollars || currentLimitDollars,
          updateLimitType: showConfirmModal,
        });
      } catch (err) {
        toast.error(String(err));
        return yupToFormErrors(err);
      } finally {
        getDepositLimit();
      }
    },
  });

  const isIncreasingLimit =
    values.amount && currentLimitDollars && values.amount > currentLimitDollars;

  const modalSubheadingId = () => {
    if (showConfirmModal === 'remove') {
      return 'account.updateConfirmModal.subheadingRemoval';
    }
    return isIncreasingLimit
      ? 'account.updateConfirmModal.subheadingIncreasing'
      : 'account.updateConfirmModal.subheadingDecreasing';
  };

  const setLimitId = depositLimit?.deposit_limit_active
    ? 'account.currentlimit.savelimitbuttontext'
    : 'account.depositlimit.setlimit.setlimitbuttontext';

  const newLimitInfo =
    depositLimit?.deposit_limit_cooldown_ends &&
    depositLimit.deposit_limit_waiting
      ? 'account.currentlimit.newLimitInfo'
      : 'account.currentlimit.limitRemovalInfo';

  const frequencyInputIsEmpty =
    !depositLimit?.deposit_limit_current &&
    !depositLimit?.deposit_limit_active &&
    !values.frequency;

  return (
    <AccountWrapper
      pageTitle={strings.depositLimit}
      pageHeader={strings.SetLimit.heading}
    >
      {showConfirmModal && (
        <Modal
          onClose={
            isSubmitting ? () => {} : () => setShowConfirmModal(undefined)
          }
          isOpen={!!showConfirmModal}
          headerText={intl.formatMessage({
            id:
              showConfirmModal === 'update'
                ? 'account.updateconfirmmodal.title'
                : 'account.removeconfirmmodal.title',
          })}
          medium
        >
          <ModalDivider />
          <ModalContentWrapper data-cy="confirmDepositModal">
            <ModalSubheading>
              <FormattedMessage
                id={modalSubheadingId()}
                values={{
                  note: (string: string) => (
                    <Text as="span" fontSize="xs">
                      {string}
                    </Text>
                  ),
                  noteTitle: (string: string) => (
                    <Text fontSize="xs" as="span" fontWeight="bold">
                      {string}
                    </Text>
                  ),
                  br: (
                    <>
                      <br />
                      <br />
                    </>
                  ),
                  amount: values?.amount ? formatStake(values?.amount) : null,
                  frequency: values?.frequency,
                }}
              />
            </ModalSubheading>

            <ButtonWrapper>
              <Button
                onClick={() => setShowConfirmModal(undefined)}
                disabled={isSubmitting}
                data-cy="cancelDepositConfirmation"
                {...buttonRemoveModalProps}
              >
                {strings.UpdateConfirmModal.cancel}
              </Button>
              <Button
                w="100%"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
                data-cy="confirmDepositConfirmation"
                {...buttonConfirmModalProps}
              >
                {strings.UpdateConfirmModal.confirm}
              </Button>
            </ButtonWrapper>
          </ModalContentWrapper>
        </Modal>
      )}

      <DepositLimitGrid>
        <ContainerForms>
          <PageHeader>
            <FormattedMessage id="account.currentlimit.heading" />
          </PageHeader>
          <TextSubheading>
            <FormattedMessage id="account.depositlimit.setlimit.subheading" />
          </TextSubheading>
          <Subheading>
            <FormattedMessage id="account.depositlimit.setlimit.subheading2" />
          </Subheading>
          <FormDivider />
          {!!depositLimit?.deposit_limit_current &&
            !!depositLimit.deposit_limit_active && (
              <DepositLimitContainer>
                <Flex>
                  <LimitLabel>
                    <FormattedMessage id="account.currentlimit.depositlimitlabel" />
                  </LimitLabel>
                  <LimitValue data-cy="currentDepositLimit">
                    {centsToDollars(depositLimit.deposit_limit_current)}
                  </LimitValue>
                </Flex>
                <Flex>
                  <LimitLabel>
                    <FormattedMessage id="account.currentlimit.limitperiodlabel" />
                  </LimitLabel>
                  <LimitValue data-cy="currentDepositFrequency">
                    {strings.CurrentLimit.days.replace(
                      '%',
                      String(
                        depositLimit?.deposit_limit_frequency_current?.toString()
                      )
                    )}
                  </LimitValue>
                </Flex>
              </DepositLimitContainer>
            )}
          {depositLimit?.deposit_limit_cooldown_ends && (
            <PendingLimitContainer>
              {depositLimit?.deposit_limit_waiting && (
                <Flex>
                  <LimitLabel>
                    <FormattedMessage id="account.currentlimit.pendingLimitLabel" />
                  </LimitLabel>
                  <LimitValue data-cy="pendingDepositLimit">
                    {centsToDollars(depositLimit.deposit_limit_waiting)}
                  </LimitValue>
                </Flex>
              )}
              <Flex>
                <FormattedMessage
                  id={newLimitInfo}
                  values={{
                    date: formatDateFullMonth(
                      depositLimit?.deposit_limit_cooldown_ends
                    ),
                    time: formatTime(depositLimit?.deposit_limit_cooldown_ends),
                  }}
                />
              </Flex>
            </PendingLimitContainer>
          )}
          <DepositLimitInputContainer>
            <Input
              label={intl.formatMessage({
                id: 'account.currentlimit.newlimitlabel',
              })}
              placeholder={intl.formatMessage({
                id: 'account.depositlimit.setlimit.inputplaceholder',
              })}
              type="number"
              name="amount"
              data-cy="editDepositLimitAmount"
              InputLeftAddon="$"
              error={errors.amount}
              isFormik={false}
              onChange={handleChange}
              value={values.amount || ''}
              {...inputProps}
            />

            {!depositLimit?.deposit_limit_current && (
              <Select
                label={strings.SetLimit.limitPeriodLabel}
                name="frequency"
                isFormik={false}
                onChange={(e) =>
                  setFieldValue(
                    'frequency',
                    parseInt(e.target.value.trim().replace(' ', ''), 10)
                  )
                }
                data-cy="depositLimitFrequency"
                error={errors.frequency}
                placeholder={Generic.SelectPlaceholder}
              >
                {strings.limitPeriodOptions.map((x, i) => (
                  <option value={x} key={i.toString()} data-testid={x}>
                    {x}
                  </option>
                ))}
              </Select>
            )}
          </DepositLimitInputContainer>
          <DepositButton
            onClick={() => {
              validateForm()
                .then((validationErrors) => {
                  if (Object.keys(validationErrors).length === 0)
                    setShowConfirmModal('update');
                })
                .catch(() => false);
            }}
            disabled={!values.amount || frequencyInputIsEmpty}
            data-cy="updateDepositLimitButton"
          >
            <FormattedMessage id={setLimitId} />
          </DepositButton>
          {!!currentLimitDollars && (
            <DepositButton
              onClick={() => {
                setShowConfirmModal('remove');
                setFieldValue('amount', null).catch((err) =>
                  console.error(err)
                );
              }}
              variant="ghost"
              data-cy="removeDepositLimitButton"
              mt="3"
              {...buttonRemoveProps}
            >
              <FormattedMessage id="account.currentlimit.removelimitbuttontext" />
            </DepositButton>
          )}
        </ContainerForms>
      </DepositLimitGrid>
    </AccountWrapper>
  );
};

export default DepositLimit;
