import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  borderRadius: '10px',
  border: '3px solid',
  borderColor: 'beta.500',
  background: 'rgba(81, 208, 247, 0.10)',
  backdropFilter: 'blur(5px)',
  p: '0.5',
  mb: ['2', null, '4'],
  mt: 0,
};

export const BoxWrapper: CSSObject = {
  h: [null, null, '39'],
  alignItems: 'center',
  display: 'flex',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'smxs',
};

export const ButtonViewMore: CSSObject = {
  bg: 'linear-gradient(180deg, #263BF4 0%, #2534BF 100%)',
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'KHInterference',

  _hover: {
    bg: 'rgba(68, 72, 210, 0.70)',
  },
};
