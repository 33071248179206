import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import EditorRME from 'rich-markdown-editor';
import { styleImports } from './TermsConditions.styles.imports';
import { EThemes } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export const PageHeader = chakra(Flex, {
  baseStyle: () => ({
    borderTopRadius: [null, null, null, 'lg'],
    alignItems: 'center',
    px: 5,
    minH: '100px',
  }),
});

export const TextPageHeader = chakra(Text, {
  baseStyle: () => ({}),
});

export const FlexPageWrapper = chakra(Flex, {
  baseStyle: ({ theme }) => ({
    flexDirection: 'column',
    ...(theme.themeName === EThemes.Wellbet && {
      px: '2',
      pb: '2',
      mt: '0',
      color: 'gray.500',
    }),
    ...(theme.themeName === EThemes.Betgalaxy && {
      bg: 'white',
      px: '4',
      pb: '5',
    }),
    ...(() => {
      try {
        return styleImports[themeName]?.FlexPageWrapper;
      } catch (err) {
        return {};
      }
    })(),
  }),
});

export const Editor = chakra<any>(EditorRME, {
  baseStyle: ({ theme }) => ({
    '&&&': {
      flex: 1,

      '> *': {
        fontFamily: 'body',
        bg: 'transparent',
        color: 'inherit',
      },

      'h1, h2, h3, h4, h5, h6': { mt: '8', mb: '2', fontWeight: 'medium' },
      h1: { _first: { mt: '0' }, fontSize: '3xl' },
      h2: { fontSize: '2xl' },
      h3: { fontSize: 'md' },
      h4: { fontSize: 'sm' },
      p: { fontSize: 'md' },

      ...(theme.themeName === EThemes.Betgalaxy && {
        color: 'black',
        a: { color: 'gamma.900', textDecoration: 'underline' },
        p: '4',
      }),
    },
  }),
});

export const BoxAside = chakra(Box, {
  baseStyle: () => ({
    width: '250px',
    ml: '2',
    borderRadius: 'lg',
    overflow: 'hidden',
    alignSelf: 'flex-start',
  }),
});

export const LinkAside = chakra(Link, {
  baseStyle: ({ theme }) => ({
    display: 'block',
    p: '4',

    ...(theme.themeName === EThemes.Wellbet && {
      bgGradient: 'linear(to-b, alpha.600, alpha.800)',
      color: 'white',
      fontWeight: 'bold',
      fontSize: 'sm',
      transition: 'background .2s ease-in-out',
      borderRadius: 'sm',

      _first: { borderTop: 0 },
      '&:hover, &:active': { bg: 'gamma.300' },
    }),
  }),
});

export const PageHeaderWrapper = styled.div`
  ${({ theme }) => theme.pages.termsAndPrivacy.pageHeaderWrapper};
`;

export const PageHeaderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--bc-sizes-12) 0 var(--bc-sizes-6) var(--bc-sizes-5);

  @media (min-width: 1200px) {
    padding: var(--bc-sizes-12) 0 var(--bc-sizes-6) var(--bc-sizes-2);
  }

  ${({ theme }) => theme.pages.termsAndPrivacy.pageHeaderContainer};
`;

export const PageHeaderText = styled.div`
  ${({ theme }) => theme.pages.termsAndPrivacy.pageHeaderText};
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--bc-sizes-2) var(--bc-sizes-5);

  @media (min-width: 1200px) {
    padding: var(--bc-sizes-12) 0 var(--bc-sizes-6) var(--bc-sizes-2);
  }

  ${({ theme }) => theme.pages.termsAndPrivacy.contentContainer};
`;

export const StyledEditor = styled.div`
  h1 {
    font-size: var(--bc-fontSizes-3xl);
    font-weight: 500;
  }
  h2 {
    font-size: var(--bc-fontSizes-2xl);
    font-weight: 500;
  }
  h3 {
    font-size: var(--bc-fontSizes-md);
    font-weight: 500;
  }
  h4 {
    font-size: var(--bc-fontSizes-sm);
    font-weight: 500;
  }
  p {
    font-size: var(--bc-fontSizes-sm);
    line-height: 24px;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
`;

export const OtherLinks = styled(Link)`
  ${({ theme }) => theme.pages.termsAndPrivacy.otherLinks};
`;
