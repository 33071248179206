import { Box } from '@chakra-ui/react';
import React from 'react';
import banner from '@/assets/wellbet/images/banner/banner.png';
import mobilebanner from '@/assets/wellbet/images/banner/mobilebanner.png';
import Carousel from '@/components/Carousel';

export default function Index() {
  return (
    <Box
      sx={{
        mb: ['1', null, '1.5'],
      }}
    >
      <Carousel
        slides={[
          <Box
            key="banner-image"
            sx={{
              w: '100%',
              h: ['calc(100vh - 60vh)', null, '215px'],
              bgImage: [mobilebanner, null, banner],
              bgSize: 'cover',
              bgRepeat: 'no-repeat',
              borderBottom: '4px',
              borderColor: ['transparent', null, 'white'],
            }}
          />,
        ]}
      />
    </Box>
  );
}
