import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { keys } from '../api.keys';
import { TCmsResponse } from './cms.types';
import { queryCMS } from './cms';

export const useQueryCms = (
  options?: Omit<
    UseQueryOptions<AxiosResponse<TCmsResponse>>,
    'queryKey' | 'queryFn'
  >
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
) => useQuery([keys.promotions], () => queryCMS(), options as any);
