import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    h: '6',
    w: 'auto',
    bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    boxShadow:
      '0px 0px 0px 2px #0C0504 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.36), 0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
    borderRadius: 'md',
    py: '1',
    px: '2.5',
    fontFamily: 'Fraunces',
    color: 'beta.600',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.30)',
    fontWeight: 'black',
    fontSize: 'sm',
    _after: {
      opacity: 0,
    },
  },
};
