import { CSSObject } from '@chakra-ui/react';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'alpha.500',
};

export const HeaderSvg: CSSObject = {
  boxSize: '6',
  color: 'alpha.500',
};

export const BetMultiIcon: CSSObject = {
  bg: 'transparent',
  boxSize: '6',
  color: 'alpha.500',
};

export const BetOdds: CSSObject = {
  color: 'gray.700',
};

export const headerRowStyles: HeaderRowSchema = {
  mysteryBetIcon: {
    fill: 'alpha.500',
  },
  headerRowSvg: {
    fill: 'white',
  },
};
