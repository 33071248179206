import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexContainer = (isHome: boolean) => ({
  pos: [isHome ? 'absolute' : 'relative', null, 'unset'],
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },

  bg: ['white', null, 'transparent'],
  w: ['100%', null, 'unset'],
  p: ['1', null, null, '0'],
  h: ['12', null, '50px'],
  pb: [null, null, '2'],
});

export const ButtonGroup: CSSObject = {
  overflow: 'hidden',
  pb: ['0', null, '2'],
};

export const ButtonLogIn: CSSObject = {
  bg: 'transparent',
  color: 'beta.500',
  fontSize: 'xs',
  border: '2px solid',
  borderColor: 'beta.500',
  borderRadius: 'sm',
  transition: '.3s ease',
  lineHeight: 'inherit',
  h: '37px',
  w: '20',
  maxW: '20',
  px: '4',

  '&:focus': { boxShadow: 'none' },
  '&:hover, &:active': {
    bg: 'transparent',
    color: 'beta.500',
    pl: '6',
  },

  '&:after': {
    content: `"→"`,
    position: 'absolute',
    bg: 'beta.500',
    color: 'white',
    w: '0',
    h: '100%',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '.3s ease',
    transform: 'scale(0, 1)',
    transformOrigin: 'left center',

    zIndex: '1',
    opacity: '1',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
  },
  '&:hover:after': {
    transform: 'scale(1, 1)',
    opacity: '1',
    w: '4',
  },
};

export const ButtonSignUp: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  borderRadius: 'sm',
  transition: '.3s ease',
  border: 'none',
  boxShadow: 'md',
  w: '20',
  maxW: '20',
  px: '4',
  '&:focus': { boxShadow: 'none' },

  '&:hover, &:active': {
    bg: 'beta.500',
    border: 'none',
    borderColor: 'beta.500',
    pl: '8',
  },

  '&:after': {
    content: `"→"`,
    position: 'absolute',
    bg: 'rgba(255, 255, 255, 0.3)',
    w: '0',
    h: '100%',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '.3s ease',
    transform: 'scale(0, 1)',
    transformOrigin: 'left center',
    opacity: '1',
    zIndex: '1',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
  },
  '&:hover:after': {
    transform: 'scale(1, 1)',
    opacity: '1',
    w: '4',
  },
};

export const ButtonBetSlip: CSSObject = {
  color: 'white',
  border: '2px solid',
  boxShadow: 'md',
  transition: 'all .3s ease-in-out',
  height: ['37px', null, '45px'],
  fontSize: ['2xs', null, 'xs'],

  '.span-bet-count': { fontSize: ['xs', null, 'md'] },

  '&:hover, &:active': {
    color: 'white',
  },
};

export const ButtonBets: CSSObject = {
  color: 'white',
  border: '2px solid',
  boxShadow: 'lg',
  transition: 'all .3s ease-in-out',
  height: ['37px', null, '45px'],
  borderRadius: 'md',
  textTransform: 'uppercase',

  '.span-bet': {
    textTransform: ['uppercase', null, 'capitalize'],
    fontSize: ['2xs', null, 'xs'],
  },
  '.span-bet-count': { fontSize: ['xs', null, 'md'] },

  '&:hover, &:active': {
    color: 'white',
  },
};

export const buttonQuickAccessProps: CustomButtonProps = {
  sx: {
    '&&': {
      minW: '100px',
      alignItems: 'flex-start',
      animation: '.5s ease-in-out',
      borderRadius: 'md',
      border: '2px solid',
      borderColor: 'white',
      boxShadow: 'lg',
      textShadow: 'none',
      height: ['37px', null, '45px'],

      '&:hover': {
        bgPos: 'bottom center',
        boxShadow: 'sm',
      },

      '&[aria-expanded="true"]': {
        bg: 'beta.500',

        '.chevron-icon': { color: 'white' },
        '.span-balance': { color: 'white' },
        '.account-text': { color: 'white' },
      },

      '&:after': { bg: 'beta.500' },

      '.span-balance': { color: 'white', fontSize: ['xs', null, 'md'] },
      '.span-bonus': {
        color: 'white',
        textShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
        fontSize: ['2xs', null, 'sm'],
      },
      '.account-text': { color: 'white' },
      '.chevron-icon': { color: 'white' },
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'black',
  boxSize: '8',
  w: '8',
  h: '8',
  '& svg': {
    boxSize: '8',
  },
});

export const LinkLogo: CSSObject = {
  w: ['111px', null, '160px'],
  display: ['block', null, 'none'],
};

export const FlexButtonGroup: CSSObject = {
  pb: ['0', null, '2'],
};

export const TextButtonNumber: CSSObject = {
  fontSize: ['xs', null, 'md'],
};
