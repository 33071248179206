import { CSSObject } from '@chakra-ui/react';
import loader from '../../../../assets/volcanobet/images/loader.svg';

export const FlexWrapper = (): CSSObject => ({
  bg: 'blackAlpha.700',
});

export const SpinnerLoader: CSSObject = {
  bgImage: `url(${loader})`,
};
