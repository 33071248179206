import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  body: {
    color: 'gamma.600',
  },
  container: {
    mx: '-2',
    mt: '-2',
  },
};
