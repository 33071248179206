import { ENotificationType } from './types';
import BetWon from '@/assets/core/icons/BetWon-Notification.svg';
import BetRefund from '@/assets/core/icons/BetRefund-Notification.svg';
import BonusBet from '@/assets/core/icons/BonusBet-Notification.svg';

export function getNotificationStyle(type: ENotificationType): {
  titleColor: string;
  icon: string | null;
} {
  switch (type) {
    case ENotificationType.WinningBet:
      return {
        titleColor: 'green.500',
        icon: BetWon,
      };
    case ENotificationType.VoidedBet:
      return {
        titleColor: 'gray.700',
        icon: BetRefund,
      };
    case ENotificationType.BonusCredit:
      return {
        titleColor: 'gray.700',
        icon: BonusBet,
      };
    default: {
      return {
        titleColor: 'gray.700',
        icon: null,
      };
    }
  }
}
