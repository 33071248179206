import { CSSObject } from '@chakra-ui/react';
import groundBackgroundImage from '../../../../../../assets/puntgenie/images/homeFeaturedRacing/desert-bg.png';

export const FlexFeatureWrapper: CSSObject = {
  bgImage: `url(${groundBackgroundImage})`,
  bgSize: ['100%', null, '102%'],
  bgRepeat: 'no-repeat',
  bgPosition: '0 15%',
  w: '100%',
  minH: [null, '350px'],
  p: '0',
  pt: ['5', null, 'unset'],
  h: [null, null, '350px'],
  borderRadius: 'none',
  borderBottomColor: 'delta.400',
  borderBottom: '3px solid',
  borderColor: 'delta.600',
  flexDir: ['column', null, 'row'],
  mx: ['0', null, '1.5'],
};

export const FlexFeatureCardWrapper: CSSObject = {
  p: '5',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  bgRepeat: 'no-repeat',
  bgPos: 'bottom',
  justifyContent: ['center', null, 'flex-start'],
  transformStyle: 'preserve-3d',
  pt: ['0', null, '5'],
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  zIndex: '5',
  h: 'auto',
  minH: ['120px', null, 'auto'],
  mb: ['-5', null, 'unset'],
};

export const BoxCard: CSSObject = {
  borderRadius: '2xl',
  border: '4px',
  borderColor: 'beta.200',

  boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.3)',
  bg: 'beta.500',
};

export const ImageMascot: CSSObject = {
  pos: ['unset', null, 'absolute'],
  bottom: 0,
  display: ['unset', null, 'block'],
};

export const TextFeatureStyledHeading: CSSObject = {
  color: 'white',
};

export const LinkCardHeader: CSSObject = {
  color: 'gamma.500',

  '.chakra-badge.state-prior': { color: 'gray.700' },
};

export const IconCardHeader: CSSObject = {
  color: 'gamma.500',
};

export const TextCardHeader: CSSObject = {
  color: 'gamma.500',
};

export const TextRaceNumber: CSSObject = {
  bg: 'gamma.900',
  borderRadius: 'sm',
  color: 'white',
};

export const BoxGridHeaderItem: CSSObject = {
  bg: 'blackAlpha.200',
  color: 'gamma.500',
  textTransform: 'uppercase',
};

export const GridCardContent: CSSObject = {
  color: 'alpha.700',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.300',
  _last: {
    borderBottom: 'none',
  },
  ':nth-last-child(2)': {
    borderBottom: 'none',
  },
};

export const TextRunner: CSSObject = {
  fontSize: 'xs',
  color: 'gamma.500',
  fontWeight: 'semibold',
};

export const TextRunnerNumber: CSSObject = {
  fontWeight: 'semibold',
  color: 'gamma.500',
  fontSize: 'xs',
};

export const Button = (isActive: boolean): CSSObject => ({
  color: 'white',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  transition: 'all .3s linear',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  border: '2px',
  borderColor: 'alpha.700',
  boxShadow: 'inset 0px 0px 0px 2px #426DD6',
  lineHeight: '1',
  fontSize: 'sm',
  p: '1.5',

  '&:hover': {
    bg: 'alpha.700',
    borderColor: 'alpha.500',
    boxShadow: 'lg',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    color: 'gray.600',
    borderColor: 'gray.800',
    boxShadow: 'lg',
  },

  ...(isActive && {
    boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
    borderColor: 'transparent',
    bg: 'gamma.600',
    border: 'none',

    color: 'white',
    textShadow: 'none',

    _focus: {
      boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
    },
    _hover: {
      boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
    },
  }),
});

export const LinkRaceDetails: CSSObject = {
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  textTransform: 'uppercase',
  fontSize: '2xs',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',

  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.700)',
    color: 'white',
  },
};

export const HeadingSlogan: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  fontFamily: 'CinzelDecorative',
  fontWeight: 'black',
  color: 'white',
  fontSize: ['3xl', null, '4xl'],
  lineHeight: '1',
  textShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
  zIndex: 'docked',
  top: '50px',
  position: ['unset', null, 'absolute'],
};

export const SpanSlogan: CSSObject = {
  bgGradient: 'linear(to-b, delta.400, delta.600)',
  color: 'transparent',
  bgClip: 'text',
  textShadow: 'none',
  textTransform: 'uppercase',
};
