import React from 'react';
import TemplateToggle from './template/template';
import { useQueryOfferings } from '../../../../api/offerings/offerings.hooks';
import { FEATURE_FLAGS } from '../../../../constants/featureFlags';
import Loader from '../LoaderToggle';
import { createTemplate } from '@/lib/templateSwitch';

export default function Toggle() {
  const { data, isInitialLoading } = useQueryOfferings();
  const toggle = createTemplate(TemplateToggle);

  const hasSports =
    FEATURE_FLAGS.HAS_SPORTS_VIEW && FEATURE_FLAGS.HAS_RACE_VIEW;

  if (!hasSports || (hasSports && !data?.offered_sports?.length)) {
    return <></>;
  }

  if (hasSports && isInitialLoading) return <Loader />;

  return <>{toggle}</>;
}
