/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import { Form as FormikForm, Formik } from 'formik';
import {
  Box,
  Collapse,
  FormErrorMessage,
  HStack,
  Input,
  VStack,
  Flex,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { ContainerForms } from '../styles/account.styles';
import Form, { TFormikValues } from './Components/Form';
import Modal, { TModalFZData } from './Components/Modal';
import InfoModal from './Components/infoModal/InfoModal';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { useQueryDepositLimit } from '@/api/deposit/deposit.hooks';
import { useFatZebraToken } from './lib/fatzebra';
import { dollarsToCents, getStrings } from '@/helpers/utils';
import { handleDeposit } from './lib/deposit';
import { TSetFieldValue, useComplexState } from '@/hooks/useComplexState';
import SavedCardsFZ, {
  PrimerCardsContainer,
} from './Components/SavedCards/SavedCards';
import useEffectOnce from '@/hooks/useOnce';
import {
  PrimerFormErrorsSchema,
  PrimerInputRefs,
  PrimerService,
} from './lib/primer';
import SubmitBtn from './Components/SubmitBtn/SubmitBtn';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { keys } from '@/api/api.keys';
import {
  FormControlGroup,
  FormLabelThemed,
} from '@/components/FormElements/HOCs/FormControl.styles';
import { PromoBannerContainer } from './Components/PromoBanner/PromoBanner';
import { mutateCancelDeposit } from '@/api/deposit/deposit';
import { useFeatureFlag } from '@/store/FeatureFlagStore';
import { depositStyles } from '@/views/account/Deposit/styles/Deposit.styles';
import { NativePmtBtn } from './Components/SubmitBtn/NativePmtBtn';

import {
  ImageCardLogo,
  DefaultText,
  DepositContainer,
  HeaderWrapper,
} from './Components/styles/Form/Form.styles';
import visa from '@/assets/core/icons/visa.svg';
import mastercard from '@/assets/core/icons/mastercard.svg';
import { CardBanner } from './Components/CreditCardBanner/Banner';

export const enum PaymentMethodType {
  APPLE_PAY = 'APPLE_PAY',
  PAYPAL = 'PAYPAL',
  GOOGLE_PAY = 'GOOGLE_PAY',
}

export const enum ManagerType {
  CARD = 'CARD',
  NATIVE = 'NATIVE',
}

export type PaymentMethodInfo = {
  type: PaymentMethodType;
  managerType: ManagerType;
};

export interface BackgroundColor {
  colored: string;
  dark: string;
  light: string;
}

export type PrimerNativeButton = {
  button: any | undefined;
  type: PaymentMethodType;
  iconUrl: IconTypes | undefined;
  paymentMethodName: string | undefined;
  backgroundColor: BackgroundColor | undefined;
  renderPromise: Promise<void> | undefined;
};

export type IconTypes = {
  colored: string;
  dark: string;
  light: string;
};

export interface InputValidationError {
  name: string;
  error: string;
  message: string;
}

export type VaultedCard = {
  cardId: string;
  last4: string;
  expiryYear: string;
  expiryMonth: string;
  cardType: string;
  cardToken: string;
};

export default function Deposit() {
  const [step, setStep] = useState(1);

  const [formValues, setFormValues] = useState<{
    amount: number;
    opted_in_promo?: string;
  }>();

  const { data: dataDeposit } = useQueryDepositLimit();

  const [{ Generic, Account }] = getStrings();
  const {
    Deposit: { Validation },
  } = Account;

  const initialValues: TFormikValues = {
    amount: '',
    card: {
      card_id: 'addCard',
      card_type: '',
      card_token: 'addCard',
      card_expiry: '',
      last_4: 'Add new card',
    },
  };

  // Feature Flags
  const hasNewDeposit = useFeatureFlag('PRIMER_ENABLED');
  const isFZSavedCardsDisabled = useFeatureFlag('FZ_SAVED_CARDS_DISABLED');

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(10, Validation.MinAmount)
      .max(
        (dataDeposit?.remaining ?? 10_000_000_000) / 100, // The limit is in cents
        Validation.MaxAmount
      )
      .required(Validation.MinAmount),
    card: Yup.object().required(Generic.Required),
  });

  // InfoModel
  const { state: infoModelState, setFieldValue } = useComplexState({
    isConfirmationModalOpen: false,
    isPaymentSuccess: false,
  });

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const depositRef = useRef(undefined as unknown as TModalFZData);
  const loadFatZebraToken = useFatZebraToken();

  const onSubmit = async ({ card, amount, opted_in_promo }: TFormikValues) => {
    if (!amount) throw new Error('Unexpected amount');

    try {
      await handleDeposit(
        loadFatZebraToken,
        card,
        Number(amount),
        opted_in_promo,
        depositRef,
        () => setIsLightBoxOpen(true)
      );

      if (IS_MOBILE_APP) return;

      setFieldValue('isPaymentSuccess', true);
      setFieldValue('isConfirmationModalOpen', true);
    } catch (e) {
      setFieldValue('isPaymentSuccess', false);
      setFieldValue('isConfirmationModalOpen', true);
    }
  };

  const newOnSubmit = (
    values: TFormikValues,
    setSubmitting: (submitting: boolean) => void
  ) => {
    setSubmitting(false);
    setFormValues({
      amount: dollarsToCents(Number(values.amount)),
      opted_in_promo: values.opted_in_promo,
    });
    setStep(2);
  };

  return (
    <>
      {IS_MOBILE_APP && (
        <style type="text/css">{`iframe#Cardinal-CCA-IFrame { height: 100% } div#Cardinal-Modal { top: 0px; left: 0; transform: none; height: 100%; }`}</style>
      )}

      <AccountWrapper pageTitle="Deposit Page" pageHeader="Make a deposit">
        <ContainerForms>
          <Formik<TFormikValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={
              hasNewDeposit
                ? (values, { setSubmitting }) =>
                    newOnSubmit(values, setSubmitting)
                : onSubmit
            }
          >
            <FormikForm>
              {!hasNewDeposit && isLightBoxOpen && (
                <Modal
                  data={depositRef.current}
                  setIsLightBoxOpen={setIsLightBoxOpen}
                />
              )}

              {infoModelState.isConfirmationModalOpen && (
                <InfoModal
                  state={infoModelState}
                  setFieldValue={setFieldValue}
                />
              )}

              <CardBanner />

              {!hasNewDeposit && !isFZSavedCardsDisabled && <SavedCardsFZ />}

              {(() => {
                if (hasNewDeposit) {
                  if (step === 1) {
                    return <Form />;
                  }

                  return null;
                }

                return <Form />;
              })()}
            </FormikForm>
          </Formik>

          {hasNewDeposit && step === 2 && !!formValues && (
            <PrimerForm
              formValues={formValues}
              setStep={setStep}
              setFieldValue={setFieldValue}
            />
          )}
        </ContainerForms>
      </AccountWrapper>
    </>
  );
}

/**
 * Primer
 */
type PrimerFormProps = {
  formValues: { amount: number; opted_in_promo?: string };
  setStep: Dispatch<SetStateAction<number>>;
  setFieldValue: TSetFieldValue<{
    isConfirmationModalOpen: boolean;
    isPaymentSuccess: boolean;
  }>;
};

export function PrimerForm({
  formValues,
  setStep,
  setFieldValue,
}: PrimerFormProps) {
  const primerService = useRef<PrimerService>();
  const client = useQueryClient();

  const hostedRefs = useRef<Record<PrimerInputRefs, HTMLDivElement | null>>({
    cardNumber: null,
    expiry: null,
    cvv: null,
    [PaymentMethodType.APPLE_PAY]: null,
    [PaymentMethodType.PAYPAL]: null,
    [PaymentMethodType.GOOGLE_PAY]: null,
  });
  const depositIDRef = useRef<string>();

  /** CARDS */
  const [cards, setCards] = useState<VaultedCard[]>([]);
  const [isCardsLoading, setIsCardsLoading] = useState(true);

  const [isCardsAvailable, setIsCardsAvailable] = useState(false);
  const [nativeTypesAvailable, setNativeTypesAvailable] = useState<
    PaymentMethodType[]
  >([]);
  const [loadedNativeTypes, setLoadedNativeTypes] = useState<
    Map<PaymentMethodType, boolean>
  >(new Map());

  const [formErrors, setFormErrors] = useState<PrimerFormErrorsSchema>({});

  const [cardType, setCardType] = useState<string | null>(null);
  const [nativePmtBtns, setNativePmtBtns] = useState<Map<
    PaymentMethodType,
    PrimerNativeButton
  > | null>(null);

  const errorCardType = cardType
    ? !['visa', 'mastercard'].includes(cardType)
    : false;

  const [isInitiated, setIsInitiated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const primerStagesRef = useRef<
    'init' | 'pending' | 'finalised' | undefined
  >();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [opted_in_promo, setOpted_in_promo] = useState<string>(
    formValues.opted_in_promo ?? ''
  );

  const [lastSelectedCardToken, setLastSelectedCardToken] = useLocalStorage(
    'last_selected_card_token',
    'addCard'
  );

  const [selectedCardID, setSelectedCardID] = useState<string>('addCard');
  const [creditCardFormCreated, setCreditCardFormCreated] = useState(false);
  const isAddCard = selectedCardID === 'addCard';

  useEffectOnce(() => {
    (async () => {
      const onCheckoutComplete = () => {
        setIsSubmitting(false);
        client
          .invalidateQueries([keys.punterAccountOverview])
          .catch(() => undefined);
        setStep(1);
        setFieldValue('isPaymentSuccess', true);
        setFieldValue('isConfirmationModalOpen', true);
        primerStagesRef.current = 'finalised';
      };

      const onCheckoutFail = () => {
        setIsSubmitting(false);

        setStep(1);
        setFieldValue('isPaymentSuccess', false);
        setFieldValue('isConfirmationModalOpen', true);
      };

      primerService.current = new PrimerService();

      const { depositID } =
        (await primerService.current.initiatePrimer({
          amount: formValues.amount,
          opted_in_promo: formValues.opted_in_promo,
          onCheckoutComplete,
          onCheckoutFail,
        })) ?? {};
      depositIDRef.current = depositID;

      const { vaultedPaymentMethods } =
        (await primerService.current.getSavedPayMethods()) ?? {};

      const { availablePayMethods } =
        (await primerService.current.getAvailablePayMethods()) ?? {};

      const nativeTypes: PaymentMethodType[] = [];
      availablePayMethods.forEach((paymentMethod) => {
        const { managerType, type } = paymentMethod;
        switch (managerType) {
          case ManagerType.CARD: {
            const storedCardIndex = vaultedPaymentMethods.findIndex(
              (card) => card.cardId === lastSelectedCardToken
            );

            // Check if stored card is in the vault if not set the next best
            if (storedCardIndex === -1) {
              const token = vaultedPaymentMethods.length
                ? vaultedPaymentMethods[0].cardId
                : 'addCard';
              setSelectedCardID(token);
              setLastSelectedCardToken(token);
            } else {
              setSelectedCardID(vaultedPaymentMethods[storedCardIndex].cardId);
            }

            primerStagesRef.current = 'init';
            setCards(vaultedPaymentMethods ?? []);
            setIsCardsLoading(false);
            setIsCardsAvailable(true);
            break;
          }
          case ManagerType.NATIVE: {
            // Relevant for PayPal, Apple Pay and Google Pay
            nativeTypes.push(type);
            break;
          }
          default:
            break;
        }
      });
      if (nativeTypes) {
        setNativeTypesAvailable(nativeTypes);
      }
      setIsInitiated(true);
    })().catch(() => undefined);

    /**
     * Lifecycle Hook - Component Unmount:
     * Triggers a check to determine if the user has abandoned the flow.
     * "Abandoning the flow" is defined as initiating primer but not making
     * a deposit.
     * If these conditions are met, this function calls the
     * 'mutateCancelDeposit' endpoint to handle the abandonment
     * scenario appropriately.
     */
    return () => {
      if (primerStagesRef.current !== undefined) {
        primerService.current?.clearTimer();
      }

      if (primerStagesRef.current === 'init') {
        (async () => {
          await mutateCancelDeposit({
            deposit_id: depositIDRef.current ?? '',
            status: 'Abandoned',
            reason: 'User abandoned payment flow',
          });
        })().catch(() => undefined);
      }
    };
  });

  /**
   * On input change show validation messages
   */
  const setOnChangeErrors = (errs: PrimerFormErrorsSchema) => {
    const key = Object.keys(errs)?.[0];
    setFormErrors((errors) => ({
      ...errors,
      [key]: {
        ...errs[key],
        touched: errors[key]?.touched || errs[key].touched,
      },
    }));
  };

  /**
   * Refactor this so that we can add this directly on the radio.
   * Requires refactoring of how the card select logic works.
   */
  useEffect(() => {
    if (
      isInitiated &&
      isAddCard &&
      isCardsAvailable &&
      !creditCardFormCreated
    ) {
      (async () => {
        await primerService.current?.createCreditCardFormElements({
          inputIDs: {
            cardNumberID: hostedRefs.current.cardNumber?.id ?? '',
            expiryID: hostedRefs.current.expiry?.id ?? '',
            cvvID: hostedRefs.current.cvv?.id ?? '',
          },
          setOnChangeErrors,
          setCardType,
        });
        setCreditCardFormCreated(true);
      })().catch(() => undefined);
    }
  }, [isAddCard, isInitiated, creditCardFormCreated, isCardsAvailable]);

  useEffect(() => {
    const refContainerIds = nativeTypesAvailable.map(
      (type) => (hostedRefs.current[type] as HTMLDivElement | null)?.id
    );

    const nativeButtonOnClick = () => {
      setIsSubmitting(true);
      primerStagesRef.current = 'pending';
    };

    if (
      nativePmtBtns === null &&
      nativeTypesAvailable.length > 0 &&
      refContainerIds.every((el) => el)
    ) {
      primerService.current
        ?.createNativeFormElements({
          inputIDs: nativeTypesAvailable.reduce(
            (map, type, idx) => map.set(type, refContainerIds[idx] as string),
            new Map<PaymentMethodType, string>()
          ),
          nativeButtonOnClick,
          setNativePmtBtns,
        })
        .catch(() => undefined);
    } else if (nativePmtBtns) {
      nativeTypesAvailable.forEach((nativeButton) => {
        nativePmtBtns
          ?.get(nativeButton)
          ?.renderPromise?.then(() => {
            if (!loadedNativeTypes.get(nativeButton)) {
              setLoadedNativeTypes(
                new Map([
                  ...Array.from(loadedNativeTypes),
                  [nativeButton, true],
                ])
              );
            }
          })
          .catch(() => undefined);
      });
    }
  }, [
    nativePmtBtns,
    nativeTypesAvailable,
    loadedNativeTypes,
    setLoadedNativeTypes,
    setNativePmtBtns,
  ]);

  useEffect(() => {
    // Disable all native buttons to avoid double clicking
    if (nativeTypesAvailable) {
      nativeTypesAvailable.forEach((nativeButton) => {
        nativePmtBtns
          ?.get(nativeButton)
          ?.renderPromise?.then(() => {
            nativePmtBtns?.get(nativeButton)?.button.setDisabled(isSubmitting);
          })
          .catch(() => undefined);
      });
    }
  }, [isSubmitting, nativePmtBtns, nativeTypesAvailable]);

  const handleCardChange = (id: string) => {
    const idx = cards.findIndex((card) => card.cardId === id);
    setSelectedCardID(id);
    setLastSelectedCardToken(cards[idx].cardToken);
  };

  /**
   * On submission show validation errors
   */
  const setOnSubmitErrors = (err: InputValidationError[] | undefined) => {
    const errors =
      err?.reduce<PrimerFormErrorsSchema>(
        (acc, cur) => ({
          ...acc,
          [cur.name]: {
            active: false,
            dirty: true,
            error: cur.message,
            errorCode: cur.error,
            submitted: false,
            touched: true,
            valid: false,
          },
        }),
        {}
      ) ?? {};

    setFormErrors(errors);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      primerStagesRef.current = 'pending';

      await primerService.current?.patchSession({
        opted_in_promo: opted_in_promo || null,
        payment_type:
          selectedCardID === 'addCard' ? 'FIRST_PAYMENT' : 'ECOMMERCE',
      });

      if (isAddCard) {
        await primerService.current?.submitForm({
          setOnSubmitErrors,
        });
      } else {
        await primerService.current?.startPaymentFlow({ selectedCardID });
      }
    } catch (err) {
      console.log('err', err);
      setIsSubmitting(false);
    } finally {
      primerStagesRef.current = 'finalised';
    }
  };

  return (
    <>
      <PromoBannerContainer
        value={opted_in_promo ?? ''}
        handleOnClick={setOpted_in_promo}
      />

      <DepositContainer>
        <HeaderWrapper>
          <DefaultText>
            <FormattedMessage id="account.deposit.labels.selectdeposit" />
          </DefaultText>
          <Flex gap="2">
            <ImageCardLogo src={visa} />
            <ImageCardLogo src={mastercard} />
            {nativeTypesAvailable &&
              nativeTypesAvailable.map((pmtType) => (
                <ImageCardLogo
                  key={pmtType}
                  src={nativePmtBtns?.get(pmtType)?.iconUrl?.light}
                  isLoading={loadedNativeTypes.get(pmtType) ?? true}
                />
              ))}
          </Flex>
        </HeaderWrapper>
      </DepositContainer>

      {nativeTypesAvailable &&
        nativeTypesAvailable.map((pmtType) => (
          <NativePmtBtn
            key={pmtType}
            isNativeLoading={loadedNativeTypes.get(pmtType) ?? true}
            buttonInfo={
              nativePmtBtns?.get(pmtType) ??
              ({ type: pmtType } as PrimerNativeButton)
            }
            ref={(el: HTMLDivElement) => (hostedRefs.current[pmtType] = el)}
          />
        ))}

      {(isCardsAvailable || isCardsLoading) && (
        <>
          <PrimerCardsContainer
            hideTopDivider={nativeTypesAvailable.length > 0}
            isCardsLoading={isCardsLoading}
            vaultCards={cards}
            setVaultCards={setCards}
            handleCardChange={handleCardChange}
            selectedCardID={selectedCardID}
            deleteCard={(id) => primerService.current?.deleteSavedCard(id)}
            setSelectedCardID={setSelectedCardID}
          />

          <Collapse in={isAddCard && !isCardsLoading}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit().catch(() => undefined);
              }}
            >
              <VStack>
                <FormControlGroup
                  w="full"
                  isInvalid={
                    formErrors?.cardholderName?.touched &&
                    !formErrors?.cardholderName?.active &&
                    formErrors?.cardholderName?.error
                  }
                >
                  <FormLabelThemed>Cardholder Name</FormLabelThemed>
                  <Input
                    variant="unstyled"
                    id="checkout-cardholder-name-input"
                    onChange={(e) =>
                      primerService.current?.handleNameChange(e.target.value)
                    }
                    {...depositStyles.inputCardHolderNameProps}
                  />
                  <FormErrorMessage>
                    {formErrors?.cardholderName?.error}
                  </FormErrorMessage>
                </FormControlGroup>

                <FormControlGroup
                  w="full"
                  isInvalid={
                    (formErrors?.['cardNumber-card']?.touched &&
                      !formErrors?.['cardNumber-card']?.active &&
                      formErrors?.['cardNumber-card']?.error) ||
                    errorCardType
                  }
                >
                  <FormLabelThemed>Card Number</FormLabelThemed>
                  <Box
                    id="checkout-card-number-input"
                    ref={(el: HTMLDivElement) =>
                      (hostedRefs.current.cardNumber = el)
                    }
                    h="45px"
                    w="full"
                  />
                  <FormErrorMessage mt={0}>
                    {errorCardType
                      ? 'Card must be either Visa or Mastercard'
                      : formErrors?.['cardNumber-card']?.error}
                  </FormErrorMessage>
                </FormControlGroup>

                <HStack alignItems="flex-start">
                  <FormControlGroup
                    flex="4"
                    isInvalid={
                      formErrors?.['expiryDate-card']?.touched &&
                      !formErrors?.['expiryDate-card']?.active &&
                      formErrors?.['expiryDate-card']?.error
                    }
                  >
                    <FormLabelThemed>Expiry Date</FormLabelThemed>
                    <Box
                      id="checkout-card-expiry-input"
                      ref={(el: HTMLDivElement) =>
                        (hostedRefs.current.expiry = el)
                      }
                      h="45px"
                    />
                    <FormErrorMessage mt={0}>
                      {formErrors?.['expiryDate-card']?.error}
                    </FormErrorMessage>
                  </FormControlGroup>

                  <FormControlGroup
                    flex="1"
                    isInvalid={
                      formErrors?.['cvv-card']?.touched &&
                      !formErrors?.['cvv-card']?.active &&
                      formErrors?.['cvv-card']?.error
                    }
                  >
                    <FormLabelThemed>CCV</FormLabelThemed>
                    <Box
                      id="checkout-card-cvv-input"
                      ref={(el: HTMLDivElement) =>
                        (hostedRefs.current.cvv = el)
                      }
                      h="45px"
                    />
                    <FormErrorMessage mt={0}>
                      {formErrors?.['cvv-card']?.error}
                    </FormErrorMessage>
                  </FormControlGroup>
                </HStack>

                <SubmitBtn
                  isDisabled={errorCardType}
                  isLoading={isCardsLoading || isSubmitting}
                  txt="Add card and deposit"
                />
              </VStack>
            </form>
          </Collapse>
        </>
      )}

      {!isAddCard && isCardsAvailable && (
        <SubmitBtn
          isLoading={isCardsLoading || isSubmitting}
          onClick={handleSubmit}
          type="button"
          txt="Deposit now"
        />
      )}
    </>
  );
}
