import { TMoMSchema } from '@/store/BetSlipStore';
import { TBetSlipBetAPIInput } from '../Betslip.types';
import {
  TBlendedApiInput,
  TEvenShotBestApiInput,
  TExoticExactaApiInput,
  TExoticFirstFourApiInput,
  TExoticQuinellaApiInput,
  TExoticTrifectaApiInput,
  TMoMBetApiInput,
  TMultiPropositionLeg,
  TMysteryBetApiInput,
  TSGMultiApiInput,
  TSRMApiInput,
  TSingleRacingApiInput,
  TSingleSportApiInput,
  TSingleToteBestApiInput,
  TSingleToteMidApiInput,
} from './types';
import {
  TBlendedMoMBet,
  TMultiMoMBet,
  TSGMultiMoMBet,
  TSRMultiMoMBet,
  TSingleRacingMoMLeg,
  TSingleSportMoMLeg,
} from '../../components/Modal/MoM/types';

function isBetSlipBetAPIInput(bet: any): bet is TBetSlipBetAPIInput {
  return 'request_id' in bet;
}

export function normaliseBetsForSubmission(
  betSlipBet: TBetSlipBetAPIInput | TMoMSchema | null
) {
  if (!betSlipBet) {
    return null;
  }

  switch (betSlipBet.type) {
    case 'Single': {
      const bet = betSlipBet as TBetSlipBetAPIInput;
      let type = 'Single';
      if (bet.price_type === 'tote_single_best') {
        type = 'SINGLE_TOTE_BEST';
      }
      if (bet.price_type === 'tote_single_mid') {
        type = 'SINGLE_TOTE_MID';
      }
      if (bet.price_type === 'mystery_bet') {
        type = 'MYSTERY_BET';
      }
      if (bet.price_type === 'even_shot') {
        type = 'EVEN_SHOT';
      }

      switch (type) {
        // TODO: replace with a new type for single bets
        case 'Single': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              odds: bet.odds,
              type: 'Single',
              price_type: bet.price_type,
            },
          } as TSingleSportApiInput;
        }
        case 'SINGLE_SPORT': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              odds: bet.odds,
              type: 'Single',
              price_type: bet.price_type,
            },
          } as TSingleSportApiInput;
        }
        case 'SINGLE_RACING': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              odds: bet.odds,
              type: 'Single',
              price_type: bet.price_type,
            },
          } as TSingleRacingApiInput;
        }
        case 'SINGLE_TOTE_MID': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              odds: bet.odds,
              type: 'Single',
              price_type: 'tote_single_mid',
            },
          } as TSingleToteMidApiInput;
        }
        case 'SINGLE_TOTE_BEST': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              odds: bet.odds,
              type: 'Single',
              price_type: 'tote_single_best',
            },
          } as TSingleToteBestApiInput;
        }
        case 'EVEN_SHOT': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              odds: bet.odds,
              type: 'Single',
              price_type: 'even_shot',
            },
          } as TEvenShotBestApiInput;
        }
        case 'MYSTERY_BET': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              race_id: bet.race_id,
              odds: bet.odds,
              rollovers: bet.rollovers ?? [],
              type: 'Single',
              price_type: 'mystery_bet',
            },
          } as TMysteryBetApiInput;
        }
        default: {
          throw new Error(`Invalid Single submission type: ${type}`);
        }
      }
    }
    case 'Exotics': {
      const bet = betSlipBet as TBetSlipBetAPIInput;
      let subType = '';
      if (bet.sub_type === 'Exacta') {
        subType = 'Exacta';
      }
      if (bet.sub_type === 'FirstFour') {
        subType = 'FirstFour';
      }
      if (bet.sub_type === 'Quinella') {
        subType = 'Quinella';
      }
      if (bet.sub_type === 'Trifecta') {
        subType = 'Trifecta';
      }

      switch (subType) {
        case 'Exacta': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              selection: bet.selection,
              type: 'Exotics',
              sub_type: 'Exacta',
            },
          } as TExoticExactaApiInput;
        }
        case 'FirstFour': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              selection: bet.selection,
              type: 'Exotics',
              sub_type: 'FirstFour',
            },
          } as TExoticFirstFourApiInput;
        }
        case 'Quinella': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              selection: bet.selection,
              type: 'Exotics',
              sub_type: 'Quinella',
            },
          } as TExoticQuinellaApiInput;
        }
        case 'Trifecta': {
          return {
            request_id: bet.request_id,
            stake: bet.stake,
            is_bonus_bet: bet.is_bonus_bet,
            bet_details: {
              proposition_id: bet.proposition_id ?? '',
              selection: bet.selection,
              type: 'Exotics',
              sub_type: 'Trifecta',
            },
          } as TExoticTrifectaApiInput;
        }
        default: {
          throw new Error(
            `Invalid bet submission type for exotics: ${bet.type}`
          );
        }
      }
    }
    case 'Blended': {
      if (isBetSlipBetAPIInput(betSlipBet)) {
        const bet = betSlipBet;

        return {
          request_id: bet.request_id,
          stake: bet.stake,
          is_bonus_bet: bet.is_bonus_bet,
          bet_details: {
            legs: bet.legs ?? [],
            type: 'Blended',
            odds: bet.odds,
          },
        } as TBlendedApiInput;
      }

      const bet = betSlipBet as TBlendedMoMBet;
      return {
        request_id: bet.requestId,
        stake: Number(bet.stake) * 100,
        is_bonus_bet: bet.isBonusBet,
        bet_details: {
          legs: bet.legs.map((leg) => ({
            proposition_id: leg.propositionId,
          })),
          type: 'Blended',
          odds: bet.odds,
        },
      } as TBlendedApiInput;
    }
    case 'SGMulti': {
      if (isBetSlipBetAPIInput(betSlipBet)) {
        const bet = betSlipBet as TBetSlipBetAPIInput;

        return {
          request_id: bet.request_id,
          stake: bet.stake,
          is_bonus_bet: bet.is_bonus_bet,
          bet_details: {
            legs:
              bet.legs?.map((leg) => ({
                proposition_id: leg.proposition_id,
              })) ?? [],
            type: 'SGMulti',
            odds: bet.odds,
          },
        } as TSGMultiApiInput;
      }
      const bet = betSlipBet as TSGMultiMoMBet;
      return {
        request_id: bet.requestId,
        stake: Number(bet.stake) * 100,
        is_bonus_bet: bet.isBonusBet,
        bet_details: {
          legs: bet.legs.map((leg) => ({
            proposition_id: leg.propositionId,
          })),
          type: 'SGMulti',
          odds: bet.odds,
        },
      } as TSGMultiApiInput;
    }
    case 'SRMulti': {
      const bet = betSlipBet as TBetSlipBetAPIInput;

      return {
        request_id: bet.request_id,
        stake: bet.stake,
        is_bonus_bet: bet.is_bonus_bet,
        bet_details: {
          selection: bet.selection ?? [],
          type: 'SRMulti',
          odds: bet.odds,
          race_id: bet.race_id,
        },
      } as TSRMApiInput;
    }
    case 'Multi': {
      // This will be server generated
      const bet = betSlipBet as TMultiMoMBet;

      return {
        request_id: bet.requestId,
        stake: Number(bet.stake) * 100,
        is_bonus_bet: bet.isBonusBet,
        bet_details: {
          legs: bet.legs.map((leg) => {
            switch (leg.type) {
              case 'SingleRacing': {
                const currentLeg = leg as TSingleRacingMoMLeg;
                return {
                  proposition_id: currentLeg.legs.propositionId,
                  odds: currentLeg.odds,
                  price_type: 'fixed',
                  type: 'Single',
                } as TMultiPropositionLeg;
              }
              case 'SingleSports': {
                const currentLeg = leg as TSingleSportMoMLeg;
                return {
                  proposition_id: currentLeg.legs.propositionId,
                  odds: currentLeg.odds,
                  price_type: 'fixed',
                  type: 'Single',
                } as TMultiPropositionLeg;
              }
              case 'Blended': {
                const currentLeg = leg as TBlendedMoMBet;

                return {
                  legs:
                    currentLeg.legs.map((l) => ({
                      proposition_id: l.propositionId,
                    })) ?? [],
                  type: 'Blended',
                  odds: currentLeg.odds,
                } as TBlendedApiInput['bet_details'];
              }
              case 'SGMulti': {
                const currentLeg = leg as TSGMultiMoMBet;

                return {
                  legs:
                    currentLeg.legs.map((l) => ({
                      proposition_id: l.propositionId,
                    })) ?? [],
                  type: 'SGMulti',
                  odds: currentLeg.odds,
                } as TSGMultiApiInput['bet_details'];
              }
              case 'SRMulti': {
                const currentLeg = leg as TSRMultiMoMBet;

                return {
                  selection: currentLeg.selection,
                  type: 'SRMulti',
                  odds: currentLeg.odds,
                  race_id: currentLeg.raceId,
                } as TSRMApiInput['bet_details'];
              }
              default: {
                throw new Error(
                  `Invalid single leg type for multi bet submission: ${leg.type}`
                );
              }
            }
          }),
          type: 'Multi',
          odds: bet.odds,
        },
      } as TMoMBetApiInput;
    }
    default: {
      throw new Error(`Invalid bet submission type: ${betSlipBet.type}`);
    }
  }
}

/**
 * When a MOMbet is submitted, we don't pass through any propositions for an SRMulti.
 *
 * If a proposition is CLOSED for a MOMBet that contains an SRMulti, we don't have the data
 * available to assert that that closed proposition belongs to an SRMulti.
 *
 * The assumption we carry forward is that if a proposition can't be attributed to any
 * other bet types within the MOMBet, then it must belong to the SRMulti.
 *
 * =======================================================================================
 * = If the MOMBet contains multiple SRMulti's, then all of them will be shown as closed =
 * =======================================================================================
 */
export function isPropositionClosedForSRMulti(
  bet: TMultiMoMBet,
  closedPropositionIds: string[]
): boolean {
  if (closedPropositionIds.length === 0) return false;

  const setOfPropositionIds = new Set<string>(closedPropositionIds);

  bet.legs.forEach((leg) => {
    switch (leg.type) {
      case 'SingleRacing': {
        setOfPropositionIds.delete(leg.legs.propositionId);
        break;
      }
      case 'SingleSports': {
        setOfPropositionIds.delete(leg.legs.propositionId);
        break;
      }
      case 'Blended': {
        leg.legs.forEach((l) => {
          setOfPropositionIds.delete(l.propositionId);
        });
        break;
      }
      case 'SGMulti': {
        leg.legs.forEach((l) => {
          setOfPropositionIds.delete(l.propositionId);
        });
        break;
      }
      case 'SRMulti': {
        break;
      }
      default: {
        break;
      }
    }
  });

  return setOfPropositionIds.size > 0;
}
