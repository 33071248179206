export const colors = {
  alpha: {
    100: '#ECFFB4',
    200: '#DEFF7C',
    300: '#D3FF4D',
    400: '#C3FE11',
    500: '#88DA13',
    600: '#72BA0C',
    700: '#5B9607',
    800: '#447104',
    900: '#2E4F00',
  },
  beta: {
    100: '#FFF0CA',
    200: '#FFE093',
    300: '#FFCF57',
    400: '#FFC228',
    500: '#FFB028',
    600: '#EA9E19',
    700: '#CD870D',
    800: '#A06805',
    900: '#684200',
  },
  delta: {
    100: '#AFCDFF',
    200: '#83B2FF4D',
    300: '#4889F34D',
    400: '#3870CB4D',
    500: '#2451994D',
    600: '#1A3253B2',
    700: '#335280',
    800: '#233F67',
    900: '#13294A',
  },
};
