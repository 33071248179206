import { OnRaceBetsSchema } from './OnRaceBets.styles';

export const onRaceBetStyles: OnRaceBetsSchema = {
  wrapper: {
    mb: '2',
    bg: 'alpha.500',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
    border: 'node',
    borderRadius: 'md',
    flexDirection: 'column',
    py: '2',
  },
  headerWrapper: {
    px: '3',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    color: 'beta.500',
  },
  headerCountText: {
    ml: '1',
    fontSize: '2xs',
    fontWeight: 'semibold',
    color: 'white',
  },
  headerExpandButton: {
    variant: 'unstyled',
    ml: 'auto',
    mr: '1',
    color: 'beta.500',
    boxSize: '7',
  },
  toggleIcon: {
    boxSize: '4',
  },
  betsContainer: {
    flexDirection: 'column',
  },
  betCategoryWrapper: {
    alignItems: 'center',
    mt: '2',
  },
  betCategory: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'alpha.700',
  },
  betCategoryCount: {
    fontSize: 'xs',
    fontWeight: 'semibold',
    color: 'alpha.700',
    ml: '1',
  },
  divider: {
    borderWidth: 'px',
    opacity: '1',
    borderColor: 'blackAlpha.400',
    mt: '1.5',
  },
  betRow: {
    py: '2',
    gap: '2',
  },
  betRowDivider: {
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.200',
    borderBottomStyle: 'dashed',
  },
  groupDivider: {
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.400',
  },
  categoryIcon: {
    color: 'alpha.100',
    boxSize: '6',
  },
  groupContainer: {
    flexDir: 'column',
    py: 2,
    px: '3',
    border: 'none',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.2) inset,0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    _last: {
      boxShadow: 'none',
    },
  },
  groupTitleText: {
    color: 'beta.500',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  runnerText: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'extrabold',
    textTransform: 'capitalize',
  },
  runnerBarrierText: {
    color: 'white',
    fontSize: 'xs',
  },
  stakeText: {
    color: 'beta.500',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  potentialWinningsText: {
    color: 'beta.500',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  subtitleText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  exoticsPositionText: {
    color: 'beta.500',
    fontSize: 'xs',
    fontWeight: 'bold',
    w: 9,
  },
  viewMoreButton: {
    alignItems: 'center',
    justifyContent: 'center',
    mb: -2,
    borderTopRadius: 0,
    height: 8,
  },
  viewMoreButtonText: {
    fontWeight: 'black',
    fontSize: 'sm',
    textTransform: 'uppercase',
  },
  srmBlendedLegContainer: {
    flexDir: 'row',
    flex: '1',
  },
  srmBlendedRunnerText: {
    width: 32,
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
    textTransform: 'capitalize',
  },
  srmMarketText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
  },
  srmBlendedWrapper: {
    flexDir: 'column',
    flex: 1,
  },
  flexiText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
  },
};
