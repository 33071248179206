export const en = {
  'racing.errorMessages.noRacesAvailable': 'No races are currently available.',
  'promo.racing.featuredRace': 'Featured Race',
  'promo.sport.featuredSport': 'Refined Rivalry',
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'legal.responsible.section.takeaction.depositlimit.two':
    'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.depositlimit.button': 'Set limit',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.deactivate.button': 'Shut My Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': 'Exclude Yourself',
  'legal.responsible.section.takeaction.lifeexclusion.two':
    'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.lifeexclusion.button': 'Set Exclusion',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.break.two':
    'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.section.takeaction.break.button': 'Suspend My Account',
  'header.betslipBtn.desc': 'Slip',
  'account.mybetscard.bonusapplied': 'Bonus applied!',
  'sideNav.promotions': 'Promotions',
  'sideNav.quickLinks': 'Quick Links',
  sport: 'sports',
  'sports.allsports.azheading': 'A-Z Sports',
  'maintenance.heading': 'Elevating Your Experience',
  'error.heading': 'Pardon Our Mishap',
  'maintenance.error': '<bold>Regrettable Error. Profound Apologies.</bold>',
  'onboarding.locationnotallowed.header': 'A Fleeting Misstep',
  'onboarding.locationnotallowed.subheader':
    '<b>You seem to be accessing Sterling Parker from outside of Australia</b>',
  'onboarding.login.subtitle':
    'Welcome to <Node>Sterling Parker</Node>, the ultimate destination for racing and sports betting. Unlock your account and begin <Node>placing your wagers right away!</Node>',
  'onboarding.register.createAccount': 'Create an account',
  'onboarding.step1.button': 'Join now',
  'onboarding.depositLimit.completeSignup': 'Continue',
  'account.withdraw.addbank': 'Add new bank account',
  'betslip.betslipmodal.emptybetslipheading': 'Time to fill your bowl',
  'betslip.betslipmodal.emptybetslipsubheading':
    'To get started, add your racing and sports selections.',
  'betSlip.potentialReturns': 'Est. Payout',
  'betslip.betslipbetcard.errors.insufficientlegsformulti':
    'To build a Multi, please add more selections to increase your potential returns.',
  'betslip.betslipbetcard.errors.propositionclosed': 'THIS EVENT IS CLOSED',
  'betSlip.betSlipBetCard.approved': 'accepted',
  'betSlip.betSlipBetCard.rejected': 'A total of {amount} has been rejected.',
  'betslip.betslipbetcard.stakeprefix': '$',
  'account.promos.pageTitle': 'Promotions',
};
