import { Image, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/juicybet/images/backgrounds/onboardingLogo.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: (
      <Link
        as={LinkDom}
        to="/"
        maxW={['50vw', '58vw']}
        alignSelf="center"
        mb="auto"
        mt={['5vw', '8vw']}
        display={[null, null, 'none']}
      >
        <Image src={logo} />
      </Link>
    ),
    config: {
      before: 'slogan',
    },
  },
]);
