import { EThemesOG } from '@/constants/themeConfig';
import { getThemeName } from '@/helpers/getThemeConfig';
import DepositLimit from './DepositLimit';
import DepositLimitNew from './DepositLimitNew';

const themeName = getThemeName();

/**
 * Don't add to this list, use the Login import above
 */
const legacyBuildBookies = [
  EThemesOG.Wellbet,
  EThemesOG.Betgalaxy,
  EThemesOG.Questbet,
  EThemesOG.Betroyale,
  EThemesOG.Volcanobet,
  EThemesOG.Fiestabet,
  EThemesOG.Puntgenie,
  EThemesOG.Betprofessor,
];

export default legacyBuildBookies.includes(themeName)
  ? DepositLimit
  : DepositLimitNew;
