import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const RaceRunnerListHeadings: CSSObject = {
  mb: '2',
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'white',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  my: '0',
};

export const ButtonGroupWrapper: CSSObject = {
  py: '1',
  columnGap: '1',
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  border: '2px',
  borderColor: 'gamma.500',
  bg: 'alpha.500',
  borderRadius: 'lg',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '12',
    p: '0',
  },
};

export const RaceHeaderWrapper: CSSObject = {
  bgSize: '100% 100%',
  transition: '.3s ease-in-out',
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
};

export const ResultedStatusLabel: CSSObject = {
  color: 'gamma.900',
  bg: 'gamma.600',
  px: '1',
  py: '1',
  fontSize: '2xs',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  borderRadius: 'sm',
};

export const RaceStatusText: CSSObject = {
  bg: 'gray.500',
  p: '1',
};

export const RaceAndTimerContainer: CSSObject = {
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  borderRadius: 'base',
};

export const TextRaceConditions: CSSObject = {
  color: 'white',

  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'gamma.500',
    pos: 'relative',
    mx: '1.5',
  },

  _last: {
    _after: {
      display: ['none', 'none', 'block'],
      color: 'red',
    },
  },

  span: {
    fontWeight: 'black',
    mr: ['2px', '1'],
    color: 'white',
  },

  b: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
  },
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge.state-prior': {
    color: 'white',
  },
};

export const buttonStyledGroupedProps: CustomButtonProps = {
  variant: 'tab',
  boxSize: '30px',
  minWidth: '7',
  minHeight: '7',
  bg: 'beta.500',
  sx: {
    '&[data-active="true"]': {
      mt: '0',
      bg: 'beta.600',
      color: 'blackAlpha.600',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.10)',
    },
  },
};
