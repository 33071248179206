import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  // Please keep the input name as flexi
  'input[name*="flexi"]': {
    ml: '0',
  },
};

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'uppercase',
};

export const EachWayText: CSSObject = {
  color: 'black',
  fontWeight: 'medium',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
  textTransform: 'uppercase',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'epsilon.500',
  fontWeight: 'black',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: '2xl',
  color: 'epsilon.500',
  fontWeight: 'black',
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'black',
  fontSize: 'sm',
  color: 'epsilon.300',
  bg: 'delta.700',
  textShadow: 'rgba(0, 0, 0, 0.11)',
  border: 'none',
  mt: '1',
  ml: '-0.5',
  _groupFocusWithin: {
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  },
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
});

export const InputBetSlip: CSSObject = {
  border: '2px solid',
  color: 'delta.700',
  fontSize: 'sm',
  fontWeight: 'semibold',
  ml: '1',
  '&&': {
    borderRightRadius: 'md',
    borderColor: 'delta.700',
    borderLeft: '2px solid',
    borderLeftColor: 'delta.700',
    borderLeftRadius: 'md',
    bg: 'epsilon.300',
  },
};
export const FlexiReview: CSSObject = {
  pl: '0',
};

export const TreasureChestIcon: CSSObject = {
  color: 'epsilon.500',
};
