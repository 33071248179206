import { CSSObject } from '@chakra-ui/react';

export const Wrapper: CSSObject = {
  h: ['50px', null, '108px'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const TextItem = {
  color: 'white',
  textAlign: 'center',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
};

export const LinkItem = {
  color: 'beta.500',
  fontWeight: 'bold',
};
