import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { styleImports } from './ResponsibleGamblingBanner.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import {
  EResponsibleGamblingBannerLocation,
  TResponsibleGamblingBanner,
} from '../services/ResponsibleGamblingBanner.types';

const themeName = getThemeName();

export const FlexResponsibleGamblingSlideContainer = chakra(Flex, {
  label: 'FlexResponsibleGamblingSlideContainer',
  baseStyle: () => ({
    bg: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    fontSize: 'lg',
    boxSize: 'full',
    h: '100%',

    ...(() => {
      try {
        return styleImports[themeName]?.FlexResponsibleGamblingSlideContainer;
      } catch (err) {
        return {};
      }
    })(),
  }),
}) as ChakraComponent<'div', FlexProps>;

export const FlexTextContainer = chakra(Flex, {
  label: 'responsibleGamblingBanner-FlexTextContainer',
  baseStyle: () => ({
    maxW: '375px',
    flexDir: 'column',
    gap: '2',
    p: '3',
  }),
}) as ChakraComponent<'div', FlexProps>;

type TTextResponsibleGamblingHeading = TResponsibleGamblingBanner &
  TextProps &
  any;

export const TextResponsibleGamblingHeading =
  chakra<TTextResponsibleGamblingHeading>(Text, {
    shouldForwardProp: (prop) => !['bannerLocation'].includes(prop),
    baseStyle: ({ bannerLocation }: TTextResponsibleGamblingHeading) => ({
      fontSize: () => {
        if (
          bannerLocation === EResponsibleGamblingBannerLocation.HomepageCarousel
        )
          return 'xl';
        if (bannerLocation === EResponsibleGamblingBannerLocation.BetslipFooter)
          return 'sm';
        return 'lg';
      },
      textTransform: 'uppercase',
      fontWeight: 'bold',
      ...(() => {
        try {
          return styleImports[themeName]?.TextResponsibleGamblingHeading?.(
            bannerLocation
          );
        } catch (err) {
          return {};
        }
      })(),
    }),
  }) as ChakraComponent<'p', TTextResponsibleGamblingHeading>;

export const TextResponsibleGamblingCaption =
  chakra<TTextResponsibleGamblingHeading>(Text, {
    shouldForwardProp: (prop) => !['bannerLocation'].includes(prop),
    baseStyle: ({ bannerLocation }: TTextResponsibleGamblingHeading) => ({
      fontSize: () => {
        if (bannerLocation === EResponsibleGamblingBannerLocation.BetslipFooter)
          return 'xs';
        return 'md';
      },
      ...(() => {
        try {
          return styleImports[themeName]?.TextResponsibleGamblingCaption?.(
            bannerLocation
          );
        } catch (err) {
          return {};
        }
      })(),
    }),
  }) as ChakraComponent<'p', TTextResponsibleGamblingHeading>;
