import { CSSObject } from '@chakra-ui/react';

export const TabContainer: CSSObject = {
  borderBottom: '0',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
  borderRadius: 'md',
  px: '4',
};

export const TabWrapper: CSSObject = {
  borderBottom: '0',
  borderRadius: 'md',
  px: '0',
};

export const TabOption = ({ isActive }: any): CSSObject => ({
  color: isActive ? 'beta.500' : 'white',
  textShadow: 'none',
  h: '6',
  fontSize: 'xs',
  px: '3',
  py: '1',
  borderRadius: 'md',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textDecoration: 'none',
  transition: 'all, 0.2s linear',
  lineHeight: 'tall',
  letterSpacing: 'tight',
  bg: isActive ? 'blackAlpha.600' : 'transparent',
  boxShadow: isActive ? '0px 1px 0px 0px #000 inset' : 'none',

  _hover: {
    bg: isActive ? 'blackAlpha.600' : 'transparent',
    color: 'white',
  },
  '&:hover, &:focus': {
    bg: 'blackAlpha.600',
  },
});
