import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {},
  buttonCashOutDesc: { borderRadius: 'md', px: 2 },
  buttonCashOutAmount: {
    borderRadius: 'md',
    boxShadow: 'none',
    px: 2,
    sx: {
      '&:active, &:hover': {
        color: 'white',
        boxShadow: 'none',
        bg: 'beta.700',
      },
    },
  },
  flexOddsBoostBanner: {
    fontWeight: 'bold',
    color: 'delta.700',
    border: 'none',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, delta.100, delta.200)',
  },
  iconOddsBoost: {
    fill: 'delta.700',
  },
  textOddsBoost: {
    color: 'delta.700',
  },
  cashoutValue: {
    color: 'white',
  },
};

export const LinkVenueName: CSSObject = {
  color: 'delta.600',
};

export const RaceTime: CSSObject = {
  color: 'gray.400',
  mt: '1',
};

export const BonusBetCheckMark: CSSObject = {
  bg: 'alpha.400',
  color: 'white',
};

export const BonusBetText: CSSObject = {
  color: 'alpha.400',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  color: 'alpha.400',
  borderColor: 'delta.600',
};

export const BetReturn: CSSObject = {
  bg: 'white',
  color: 'gray.600',
  borderRadius: 'base',
  fontWeight: 'bold',
  fontSize: '2xs',
  border: '1px',
  borderColor: 'delta.300',
};

export const BoxBetReturnValue: CSSObject = {
  bg: 'delta.300',
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'white',
};

export const BoxBetReturnText: CSSObject = {
  pl: '1',
  py: '1',
  color: 'gray.600',
};
