import React, { useEffect, useState } from 'react';
import { Box, Fade, Icon } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { ChevronDown, ChevronUp } from '@styled-icons/evil';

import { getStrings } from '@/helpers/utils';
import Input from '../../../../../../components/FormElements/Input';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useRedux';
import { EGeneralStatus } from '../../../../../../lib/DBModels';
import {
  buttonAddToBetslipProps,
  FlexButtonWrapper,
  FlexInputWrapper,
  FlexWrapper,
  InfoHeader,
  InfoText,
  SelectionPopupContainer,
  TextAddToBetslipButton,
  toteMultiListSelections,
  toteMultiListSelectionsIcon,
} from './styles/SelectionPopup.styles';
import { Button } from '@/components/Button/Button';
import { useGetToteSelections, actions } from '@/store/ToteMultis';
import { calculateCombosForToteMulti } from '../../services/Exotics.utils';
import { useSelectionPopup } from '../../services/Exotics.hooks';
import { setBetSlipOpen, setViewMode } from '@/redux/Betslip.slices';
import { EBetSlipViewMode } from '@/components/Betslip/Services/Betslip.types';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { trackEvent } from '@/lib/analytics';
import { usePunterData } from '@/store/AuthStore';
import { validateStake } from '@/components/Betslip/lib';

const ToteMultiSelection: React.FC = () => {
  const [
    {
      Racing: { Exotics: Strings },
    },
  ] = getStrings();
  const intl = useIntl();
  const [flexiStake, setFlexiStake] = useState<{
    flexi: string;
    stake: string;
  }>({
    flexi: '',
    stake: '',
  });
  const dispatch = useAppDispatch();
  const punterProfile = usePunterData();
  const [expanded, setExpanded] = useState(true);
  const [lastChanged, setLastChanged] = useState<'' | 'stake' | 'flexi'>('');

  const { handleBetSlipAdditionToteMulti } = useSelectionPopup();
  const { clearSelections } = actions;

  const { raceRunnerList } = useAppSelector((state) => state.racing);

  const selections = useGetToteSelections().sort((a, b) =>
    a.raceNumber.localeCompare(b.raceNumber) ? 1 : -1
  );

  const allRacesSelected = selections.every((sel) => sel.selection.size > 0);

  const selectionsAsArray = selections.map((sel) => Array.from(sel.selection));
  const combos = calculateCombosForToteMulti(selectionsAsArray);

  useEffect(() => {
    const flexiLastChanged = lastChanged === 'flexi';

    if (flexiLastChanged) {
      setFlexiStake((prev) => {
        if (prev.flexi === '') return prev;
        const stake = ((Number(prev.flexi) * combos) / 100).toFixed(2);
        return {
          ...prev,
          stake,
        };
      });
    } else {
      setFlexiStake((prev) => {
        if (prev.stake === '') return prev;
        const flexi = ((Number(prev.stake) / combos) * 100).toFixed(2);
        return {
          ...prev,
          flexi,
        };
      });
    }
  }, [combos, lastChanged]);

  const raceNumbersForToteMulti = selections.map((sel) => sel.raceNumber);

  const isValid = selections.some((sel) => sel.selection.size > 0);

  if (raceRunnerList?.raceMeta?.status !== EGeneralStatus.Open) return <></>;

  return (
    <SelectionPopupContainer>
      <Fade in={isValid} role="dialog" unmountOnExit>
        <InfoHeader>
          <InfoText
            data-cy="clearSelectionLink"
            cursor="pointer"
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
            onClick={clearSelections}
          >
            {intl.formatMessage({ id: 'racing.exotics.clearselection' })}
          </InfoText>
          <Box
            sx={{
              pt: '3px',
              display: 'flex',
            }}
          >
            <InfoText>
              {intl.formatMessage({ id: 'racing.exotics.combos' })}: {combos}
            </InfoText>
            <Icon
              onClick={() => setExpanded((prev) => !prev)}
              as={expanded ? ChevronUp : ChevronDown}
              {...toteMultiListSelectionsIcon}
            />
          </Box>
        </InfoHeader>
        {expanded &&
          selections?.map((sel, i) => {
            const joinedSelections = Array.from(sel.selection)
              .sort((a, b) => a - b)
              .join(', ');

            const isLast = i === selections.length - 1;

            return (
              <Box
                key={sel.raceNumber}
                {...toteMultiListSelections}
                className={isLast ? 'last-element' : ''}
              >
                <p className="toteMultiLegNum">{`Leg ${i + 1}`}</p>
                <p>
                  {`${sel.raceNumber}:`}
                  <span>
                    {`${
                      joinedSelections !== ''
                        ? joinedSelections
                        : 'Please select an option'
                    }`}
                  </span>
                </p>
              </Box>
            );
          })}
        <FlexWrapper>
          <FlexInputWrapper>
            <Input
              name="flexi"
              data-cy="flexiInputField"
              size="md"
              isDisabled={!allRacesSelected}
              isFormik={false}
              label={intl.formatMessage({ id: 'generic.flexi' })}
              onChange={(e) => {
                const { value } = e.target;
                let validValue = validateStake(value);

                const hasDecimal = validValue.includes('.');
                if (hasDecimal) {
                  if (validValue.at(-1) === '.') {
                    validValue = validValue.slice(0, -1);
                  } else if (flexiStake.flexi.length < validValue.length) {
                    validValue = flexiStake.flexi;
                  }
                }

                setFlexiStake({
                  flexi: validValue,
                  stake: ((Number(validValue) * combos) / 100).toFixed(2),
                });
                setLastChanged('flexi');
              }}
              placeholder="100"
              type="text"
              value={flexiStake.flexi}
              InputLeftAddon="%"
            />

            <Input
              name="total-stake"
              data-cy="stakeInputField"
              size="md"
              isFormik={false}
              isDisabled={!allRacesSelected}
              label={intl.formatMessage({ id: 'generic.stake' })}
              onChange={(e) => {
                setFlexiStake({
                  flexi: ((Number(e.target.value) / combos) * 100).toFixed(2),
                  stake: e.target.value,
                });
                setLastChanged('stake');
              }}
              placeholder="0.00"
              type="number"
              value={flexiStake.stake}
              InputLeftAddon="$"
            />
          </FlexInputWrapper>

          <FlexButtonWrapper>
            <Button
              data-cy="addToBetSlipExoticButton"
              onClick={() => {
                if (!IS_MOBILE_APP) {
                  handleBetSlipAdditionToteMulti(
                    flexiStake.stake,
                    raceNumbersForToteMulti
                  );
                  dispatch(setViewMode(EBetSlipViewMode.EditingBets));

                  trackEvent('addToBetSlip', {
                    punterId: punterProfile?.punter_id,
                  });

                  dispatch(setBetSlipOpen(true));

                  setFlexiStake({
                    flexi: '',
                    stake: '',
                  });
                }
              }}
              disabled={!allRacesSelected || IS_MOBILE_APP}
              {...buttonAddToBetslipProps}
            >
              <TextAddToBetslipButton as="span">
                {Strings.addToBetSlip}
              </TextAddToBetslipButton>
            </Button>
          </FlexButtonWrapper>
        </FlexWrapper>
      </Fade>
    </SelectionPopupContainer>
  );
};

export default ToteMultiSelection;
