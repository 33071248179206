import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
};

export const ButtonSubmit: CSSObject = {
  bg: 'alpha.500',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
  _hover: {
    bg: 'alpha.700',
  },
  _active: {
    bg: 'alpha.800',
  },
  _disabled: {
    bg: 'alpha.200',
    boxShadow: 'none',
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    h: '9',
  },
  '.chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'xs',
  },
  '.chakra-input': {
    fontSize: 'xs',
    borderLeft: 'none',
    bg: 'gray.50',
    borderColor: 'gray.300',
    borderRadius: 'base',
    _hover: {
      bg: 'gray.100',
    },
  },
};
