import { BoxProps, CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  borderRadius: '5px',
  background: 'var(--bc-beta-500, #30363F)',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
  mt: ['3', null, null, '4'],
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
  borderBottom: '2px solid var(--bc-alpha-600, #D6A64A)',
};

export const boxWrapperNTPProps: BoxProps = {
  mt: 0,
};

export const TextHeading: CSSObject = {
  color: '#D6A64A',
  fontSize: '17px',
  fontFamily: 'CrimsonPro',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '120%',
};
export const ButtonViewMore: CSSObject = {
  padding: '9px 8px',
  background: 'var(--bc-white-alpha-100, rgba(255, 255, 255, 0.06))',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset',
  color: 'var(--bc-alpha-600, #D6A64A)',
  textAlign: 'center',
  fontSize: '16px',
  fontFamily: 'CrimsonPro',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  letterSpacing: '-0.5px',
};
