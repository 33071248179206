import { CSSObject, TextProps } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const titleProps: TextProps = {
  display: 'flex',
  justifyContent: ['space-between', null, 'flex-start'],
};

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  fontWeight: heading ? 'medium' : 'normal',
  fontSize: heading ? 'sm' : 'xs',
  color: 'gray.500',
  lineHeight: '130%',
});

export const RadioText: CSSObject = {
  fontSize: 'xs',
  mb: '2',
  color: 'gray.500',
};

export const RadioLimitChoice: CSSObject = {
  mb: '2',
  bg: 'gray.200',
  border: 'none',
  '&[data-checked]': {
    bg: 'beta.500',
    borderWidth: '7px',
    borderStyle: 'solid',
    borderColor: 'gray.200',
    color: 'beta.500',
    _hover: {
      bg: 'beta.500',
      borderColor: 'gray.200',
    },
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '4',
  color: 'gray.500',
  fontSize: 'xs',

  '.chakra-input__left-addon': {
    bg: 'gray.50',
    fontSize: 'xs',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRight: 'none',
    borderLeftRadius: 'base',
    h: '9',
  },
  '.chakra-select': {
    bg: 'gray.50',
    border: '1px',
    borderColor: 'gray.300',
    borderRadius: 'base',
    fontSize: 'xs',
    h: '9',
  },
  '#limitAmount-input': {
    fontSize: 'xs',
    borderRightRadius: 'base',
    bg: 'gray.50',
    border: '1px solid',
    borderColor: 'gray.300',
    h: '9',
    _hover: {
      bg: 'gray.100',
      borderWidth: '1px',
      borderStyle: ' solid',
      borderColor: 'gray.400',
    },
    _focus: {
      borderWidth: '2px',
      borderStyle: ' solid',
      borderColor: 'blue.300',
      boxShadow: '0px 2px 8px 0px rgba(66, 153, 225, 0.45)',
    },
  },
};
