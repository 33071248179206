import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    color: 'blackAlpha.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    boxSize: '9',
    minWidth: '7',
    minHeight: '7',
    fontSize: 'xs',
    borderRadius: 'md',
    sx: {
      '&&': {
        h: '8',
        w: 'auto',
      },
    },
  },
};

export const FlexOdds: CSSObject = {
  color: 'blackAlpha.400',
  fontSize: 'xs',
  fontWeight: 'semibold',
  bg: ['unset', 'gray.300'],
  alignItems: 'center',
  w: '12',
  justifyContent: 'center',
  px: '1',
  py: ['1', null, '0.5'],
  minW: '10',
  textAlign: 'center',
  borderRadius: 'base',
  whiteSpace: 'nowrap',
  mr: '0',
  h: '9',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'white',
  boxShadow:
    '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: ['1', null, '2'],
};

export const HStackActions: CSSObject = {
  gap: '1',
  alignItems: ['flex-end', 'center'],
};

export const CheckboxRunnerBoxed: CSSObject = {
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    borderColor: 'gray.300',
    borderWidth: '1px',
    w: '4',
    h: '4',
    _hover: {
      bg: 'gray.200',
      borderColor: 'gray.300',
      borderWidth: '1px',
    },
    '&[data-checked]': {
      w: '4',
      h: '4',
      bg: 'white',
      borderColor: 'gray.300',
      color: 'gray.700',
      _hover: {
        bg: 'gray.200',
        borderColor: 'gray.300',
        borderWidth: '1px',
      },
    },
  },
  '.chakra-checkbox__label': {
    marginInlineStart: '0',
  },
  flexDir: 'column',
  gap: '2',
  boxSize: '30px',
  borderRadius: 'base',
  bg: 'blackAlpha.200',
  alignItems: 'center',
  justifyContent: 'center',
};
