import { CSSObject } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  bgGradient: 'linear(to-b, alpha.700, alpha.800)',
  borderRadius: 'md',
  mt: '0',
  mb: '2',
  p: '2',
  color: 'white',
  boxShadow:
    '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset',
};

export const HeaderContainer: CSSObject = {
  gap: '2',
  px: '2',
  pt: '2',
};

export const MultiIconSVG: CSSObject = {
  color: 'white',
};

export const MultiCardsContainer: CSSObject = {
  mx: '-1',
  py: '0',
};

export const FlexEventDetails: CSSObject = {
  gap: '2',
};

export const EmptyMultiIcon: CSSObject = {
  color: 'beta.500',
  alignSelf: 'baseline',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const UnableToCreateMultiWrapper: CSSObject = {
  mb: '7',
  p: '2',
  gap: '1',
  borderRadius: 'lg',
  bg: 'transparent',
  color: 'white',
  border: '1px dashed',
  borderColor: 'whiteAlpha.400',
};

export const MultiCardInputContainer: CSSObject = {
  px: '1',
  '.chakra-input__group': {
    mb: '0',
  },
};

export const EventTitle: CSSObject = {
  color: 'beta.500',
};
