import { CSSObject } from '@chakra-ui/react';

export const TextItem: CSSObject = {
  textTransform: 'capitalize',
  fontFamily: 'Staatliches',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  lineHeight: 'normal',
  pb: '1',
};
