import React from 'react';
import { getLabelName } from './services/RaceRunnerForm.utils';
import {
  BoxWrapper,
  FlexWrapper,
  TextFormInfo,
} from './styles/RaceRunnerForm.styles';

export type TRaceRunnerForm = {
  runner: TRunner;
};

export default function RaceRunnerForm({
  runner: { form_data: formData },
}: TRaceRunnerForm) {
  const isGreyHound = formData?.race_type?.toLowerCase() === 'greyhounds';
  const isHarness = formData?.race_type?.toLowerCase() === 'harness racing';

  return (
    <BoxWrapper>
      <FlexWrapper>
        {formData?.handicap_weight && !isGreyHound && !isHarness && (
          <TextFormInfo data-cy="handicapWeight">{`W: ${formData.handicap_weight}kg`}</TextFormInfo>
        )}
        {formData?.rider_name && (
          <TextFormInfo data-cy="riderName">{`${getLabelName({
            form_data: formData,
          })}: ${formData.rider_name}`}</TextFormInfo>
        )}
      </FlexWrapper>
      <FlexWrapper>
        {formData?.last_5_starts && (
          <TextFormInfo data-cy="lastFiveStarts">{`F: ${formData.last_5_starts}`}</TextFormInfo>
        )}
        {formData?.trainer_name && (
          <TextFormInfo data-cy="trainerName">{`T: ${formData.trainer_name}`}</TextFormInfo>
        )}
      </FlexWrapper>
    </BoxWrapper>
  );
}
