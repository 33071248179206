import { NavigationSchema } from './Navigation.styles';
import betslipBgTexture from '@/assets/templebet/images/backgrounds/betslips-bg-testure.png';
import betslipCornerRope from '@/assets/templebet/images/betslip/rope.png';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    mt: '0',
    mb: '4',
    mx: ['2', null, null, 'unset'],
    px: '0',
    p: ['0', null, null, '1.5'],
    bg: [
      'unset',
      null,
      `linear-gradient(180deg, #FFF1CB, var(--bc-colors-beta-200)), url(${betslipBgTexture})`,
    ],
    boxShadow: [
      'unset',
      null,
      '0px 0px 0px 3px var(--bc-colors-beta-300) inset, 0px 0px 0px 6px #FFF1CB inset, 0px 5px 0px 0px var(--bc-colors-beta-300)',
    ],
    bgSize: '100% auto',
    backgroundPosition: 'top',
    backgroundBlendMode: 'darken',
    borderRadius: '2xl',
    border: ['unset', null, '6px solid'],
    borderColor: ['unset', null, '#FFF1CB'],
    position: 'relative',
    _before: {
      content: ['unset', null, '""'],
      position: 'absolute',
      left: '-10.8px',
      top: '-8.2px',
      background: `url(${betslipCornerRope})`,
      bgSize: '100%',
      height: '12',
      aspectRatio: '1',
      filter: 'drop-shadow(3px 1px 7px black)',
    },
    _after: {
      content: ['unset', null, '""'],
      position: 'absolute',
      right: '-10.5px',
      bottom: '-13.2px',
      background: `url(${betslipCornerRope})`,
      bgSize: '100%',
      height: '12',
      aspectRatio: '1',
      transform: 'rotate(180deg)',
      filter: 'drop-shadow(3px 1px 7px black)',
    },
  },
  boxNavigationWrapperProps: {
    overflowX: 'unset',
  },
  navigationBoxProps: {
    bg: `unset`,
    background: 'unset',
    width: 'full',
    maxW: ['unset', null, null, 'full'],
  },
};
