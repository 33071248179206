import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: '',

      background: {},

      drawer: {
        ...base.drawer,
      },
      drawerBody: {},
      icon: {
        ...base.icon,
      },
      headerSX: {},
      dropDownInner: {
        ...base.dropDownInner,
      },
      linkSX: {
        ...base.linkSX,
      },
      link: {
        ...base.link,
      },

      dropDownContainerClosed: {},
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
      },
    };
  },
  sizes: {},
  variants: {},
};
