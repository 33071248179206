import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  color: 'var(--bc-white, #FFF)',
  textShadow: '0px 3px 10px rgba(0, 0, 0, 0.25)',
  fontSize: '2xl',
  pl: '1',
  pt: '1',
  fontFamily: 'CrimsonPro',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '100%',
  textTransform: 'capitalize',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  mb: ['4px', null, '0px'],
};
