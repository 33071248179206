import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  py: '3.5',
  mt: ['-3', null, '-5'],
  px: ['2', '0'],
  top: ['0px', '9'],
  position: 'unset',
};

export const ButtonFilter = () => ({
  color: 'gamma.500',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'gamma.500' : 'alpha.900',
  border: selected ? 'none' : '1px #51D0F7 solid',
  color: selected ? 'beta.900' : 'transparent',
  fontWeight: 'black',
  mr: '0',
  boxShadow: '0px 1px 1px 0px rgba(65, 22, 65, 0.50) inset',
});

export const IconRace = () => ({
  boxSize: '6',
});

export const TextFilterLabel: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'bold',
  fontSize: '2xs',
};

export const Divider: CSSObject = {
  bg: 'gamma.500',
};
