import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bg: 'epsilon.700',
  border: '2px',
  borderColor: '#5c6d8e',
  color: 'white',
  h: 'full',
  fontSize: '10px',
  textShadow: '0px 2px 1px rgba(0, 0, 0, 0.15)',
  px: '1.5',
  borderRadius: '5px',
  w: '107px',

  _hover: {
    bg: 'beta.500',
  },

  '*': {
    transition: 'all .2s ease-in-out',
  },

  svg: {
    pos: 'relative',
    top: '-1px',
  },

  '&[aria-expanded="true"]': {
    svg: {
      transform: 'rotate(180deg)',
    },
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'linear-gradient(180deg, #263BF4 0%, #2534BF 100%)',
  color: 'gamma.900',
  zIndex: 'docked',
  h: 'full',
  borderRadius: '5px',
};
export const TextTitle: CSSObject = {
  color: 'gamma.900',
};
