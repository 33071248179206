import { CSSObject } from '@chakra-ui/react';
import { TTableText } from '../../../types';
import { CustomButtonProps } from '@/components/Button/Button';

export const TableItem: CSSObject = {
  borderColor: 'blackAlpha.400',
  color: 'white',
  borderRight: 'none',
  borderStyle: 'solid',
  pl: 0,
  py: 2,
};

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'alpha.200' : 'white',
});

export const TableHeadingText: CSSObject = {
  color: 'white',
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
};

export const buttonMoreTableProps: CustomButtonProps = {
  variant: 'solid',
};
