window.BETCLOUD_ENV = window.BETCLOUD_ENV || process.env;

export const APP_NAME = window.BETCLOUD_ENV.REACT_APP_SITE_TITLE;
export const APP_VERSION = '0.1.2';

export const BASE_API_URL = window.BETCLOUD_ENV.REACT_APP_API_URL;
export const FIREBASE_PROJECTID =
  window.BETCLOUD_ENV.REACT_APP_FIREBASE_PROJECTID;

export const GOOGLE_PLACES_KEY =
  window.BETCLOUD_ENV.REACT_APP_GOOGLE_PLACES_API_KEY;
export const GOOGLE_PLACES_SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_KEY}&libraries=places&callback=scriptLoadedHandler`;

export const GREENID_UI_FORM_URL = window.BETCLOUD_ENV.REACT_APP_GREENID_UI_URL;
export const GREENID_CONFIG_URL =
  window.BETCLOUD_ENV.REACT_APP_GREENID_CONFIG_URL;
export const GREENID_CSS_URL = window.BETCLOUD_ENV.REACT_APP_GREENID_CSS_URL;
export const GREENID_ENVIRONMENT =
  window.BETCLOUD_ENV.REACT_APP_GREENID_ENVIRONMENT;
export const GREENID_PLATFORM_API_KEY =
  window.BETCLOUD_ENV.REACT_APP_GREENID_PLATFORM_API_KEY;
export const GREENID_PLATFORM_ACCOUNT_ID =
  window.BETCLOUD_ENV.REACT_APP_GREENID_PLATFORM_ACCOUNT_ID;

export const FAT_ZEBRA_JS = window.BETCLOUD_ENV.REACT_APP_FAT_ZEBRA_JS;
export const FAT_ZEBRA_USERNAME =
  window.BETCLOUD_ENV.REACT_APP_FAT_ZEBRA_USERNAME;

export const PRIMER_ASSETS = {
  js: window.BETCLOUD_ENV.REACT_APP_PRIMER_JS,
  css: window.BETCLOUD_ENV.REACT_APP_PRIMER_CSS,
};

export const AU_DATE_FORMAT = 'dd/MM/yyyy';

export const AU_STATES = ['VIC', 'NSW', 'QLD', 'SA', 'WA', 'NT', 'ACT', 'TAS'];

// Used to start countdown timer when less than n minutes till event start
export const COUNTDOWN_START_MINS = 60;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTIVITY_LOG_TYPE {
  LOGIN = 'Authentication_Login',
  LOGOUT = 'Authentication_Logout',
  RESET_PASSWORD = 'Authentication_ResetPassword',
}

// Only required for activity types fired on FE
export const ACTIVITY_LOG_SUMMARY: { [key: string]: string } = {
  [ACTIVITY_LOG_TYPE.LOGIN]: 'User logged in.',
  [ACTIVITY_LOG_TYPE.LOGOUT]: 'User logged out.',
  [ACTIVITY_LOG_TYPE.RESET_PASSWORD]: 'User reset password.',
};

//
// For string match to hide location fail toast notifications as it fires multiple times if user is in a location not offered
export enum LocationFailMessage {
  NotAcceptable = 'Must include a X-Appengine-Country or x-country header in request',
  Forbidden = 'Cannot use this resource outside of Australia',
}

export enum ELocalErrorMessages {
  WITHDRAWAL_NOT_VERIFIED = 'We were unable to process your withdrawal request. Contact support for further assistance.',
  WITHDRAWAL_NO_BALANCE = 'Your withdrawal request exceeds your current withdrawable balance.  Please update your request or contact support for further assistance.',
  WITHDRAWAL_GENERAL_ERROR = 'There was an error processing your request. Contact support for further assistance.',
  WITHDRAWAL_WRONG_DETAILS = 'An issue was encountered with your bank account information.  Please review your details and try again.',
  DEPOSIT_AMOUNT_TOO_HIGH = 'Your requested deposit amount exceeds your limit. Please update your request and try again.',
  DEPOSIT_FAILED_1 = 'Your requested deposit amount exceeds your limit. Please update your request and try again.',
  DEPOSIT_FAILED_2 = 'There was an error processing your request. Contact support if the issue persists.',
  DEPOSIT_ALREADY_HIT = 'Your requested deposit amount exceeds your remaining limit. Please update your request and try again.',
  DEPOSIT_ZEBRA = 'There was an error processing your request. Contact support if the issue persists.',
  DEPOSIT_NAME_NO_MATCH = 'An issue was encountered with your bank account information. Please review your details and try again.',
  DEPOSIT_NO_DELETE = 'There was an error processing your request. Contact support if the issue persists.',
  ZEBRA_GENERIC = "The card must be in the registered account holder's name.",
  GENERIC = 'Your card was unable to be successfully deleted.  Contact support if the issue persists.',
}

export enum EBeErrorMessages {
  WITHDRAWAL_NOT_VERIFIED = 'Punter atempted to withdraw funds while not withdrawed.',
  WITHDRAWAL_NO_BALANCE = 'Punter attempted to withdraw more than their balance',
  WITHDRAWAL_GENERAL_ERROR = 'No banks found for the punter',
  WITHDRAWAL_WRONG_DETAILS = "Bank account not in punter's name",
  DEPOSIT_AMOUNT_TOO_HIGH = 'Deposit amount too high',
  DEPOSIT_FAILED_1 = 'Deposit failed to create.',
  DEPOSIT_FAILED_2 = 'Failed to create deposit',
  DEPOSIT_ALREADY_HIT = 'Deposit limit already hit',
  DEPOSIT_ZEBRA = 'Zebra response was wrong.',
  DEPOSIT_NAME_NO_MATCH = 'Name on the account did not match.',
  DEPOSIT_NO_DELETE = 'Could not delete card.',
  ZEBRA_GENERIC = 'There was an error....',
  ACCOUNT_CLOSED = 'You cannot currently access your account. Please contact customer service.',
}

export const LOCAL_ERROR_MESSAGES: { [key: string]: string } = {
  [EBeErrorMessages.WITHDRAWAL_NOT_VERIFIED]:
    ELocalErrorMessages.WITHDRAWAL_NOT_VERIFIED,
  [EBeErrorMessages.WITHDRAWAL_NO_BALANCE]:
    ELocalErrorMessages.WITHDRAWAL_NO_BALANCE,
  [EBeErrorMessages.WITHDRAWAL_GENERAL_ERROR]:
    ELocalErrorMessages.WITHDRAWAL_GENERAL_ERROR,
  [EBeErrorMessages.WITHDRAWAL_WRONG_DETAILS]:
    ELocalErrorMessages.WITHDRAWAL_WRONG_DETAILS,
  [EBeErrorMessages.DEPOSIT_AMOUNT_TOO_HIGH]:
    ELocalErrorMessages.DEPOSIT_AMOUNT_TOO_HIGH,
  [EBeErrorMessages.DEPOSIT_FAILED_1]: ELocalErrorMessages.DEPOSIT_FAILED_1,
  [EBeErrorMessages.DEPOSIT_FAILED_2]: ELocalErrorMessages.DEPOSIT_FAILED_2,
  [EBeErrorMessages.DEPOSIT_ALREADY_HIT]:
    ELocalErrorMessages.DEPOSIT_ALREADY_HIT,
  [EBeErrorMessages.DEPOSIT_ZEBRA]: ELocalErrorMessages.DEPOSIT_ZEBRA,
  [EBeErrorMessages.DEPOSIT_NAME_NO_MATCH]:
    ELocalErrorMessages.DEPOSIT_NAME_NO_MATCH,
  [EBeErrorMessages.DEPOSIT_NO_DELETE]: ELocalErrorMessages.DEPOSIT_NO_DELETE,
  [EBeErrorMessages.ZEBRA_GENERIC]: ELocalErrorMessages.ZEBRA_GENERIC,
};
