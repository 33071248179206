import { CSSObject } from '@chakra-ui/react';

export const TextTitle: CSSObject = {
  color: 'alpha.700',
  fontWeight: 'medium',
  fontSize: 'sm',
};

export const SubHeading: CSSObject = {
  fontWeight: 'bold',
  color: 'gray.700',
  fontSize: 'sm',
};

export const BoxBordered: CSSObject = {
  px: '2',
  textColor: 'black',
  maxW: 'container.md',
  pt: '0',
};

export const PageInfo: CSSObject = {
  fontSize: 'xs',
  color: 'gray.700',
  fontWeight: 'md',
};

export const SmallHeading: CSSObject = {
  fontWeight: 'bold',
  mt: '2',
  color: 'gray.700',
  fontSize: 'sm',
};

export const Text: CSSObject = {
  fontSize: 'xs',
  color: 'gray.700',
  fontWeight: 'medium',
};

export const ImageGamblersHelp: CSSObject = {
  w: '168px',
  minW: '168px',
};

export const ButtonAction: CSSObject = {
  w: 'full',
  bg: 'white',
  color: 'alpha.500',
  padding: '2',
  border: '2px solid',
  borderColor: 'alpha.500',
  borderRadius: 'sm',
  fontWeight: 'bold',
  letterSpacing: 'wider',
  '&:hover, &:active': {
    bg: 'alpha.500',
    color: 'white',
  },
};

export const GridWrapper: CSSObject = {
  gridTemplateColumns: '1fr',
  maxW: 'container.sm',
};

export const Link: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
};

export const FlexResponsibleGamblingWrapper: CSSObject = {
  mx: '2',
};
