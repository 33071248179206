import React, { FC } from 'react';
import { getStrings } from '@/helpers/utils';
import OnboardingWrapper from '../../../wrappers/OnboardingWrapper';
import ForgotPasswordProps from './types';
import Aside from '../../../components/Aside/Aside';
import Content from './components/Content/Content';
import { EOnboardingPageType } from '@/wrappers/OnboardingWrapper/OnboardingWrapper.types';

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const [
    {
      Onboarding: { ForgotPassword: ForgotPasswordStrings },
    },
  ] = getStrings();

  return (
    <OnboardingWrapper
      aside={<Aside />}
      pageTitle={ForgotPasswordStrings.PageTitle}
      pageType={EOnboardingPageType.ForgotPassword}
    >
      <Content />
    </OnboardingWrapper>
  );
};

export default ForgotPassword;
