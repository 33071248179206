import { Icon, chakra } from '@chakra-ui/react';

export const IconTrigger = chakra(Icon, {
  baseStyle: () => ({
    pos: ['absolute', null, 'fixed'],
    cursor: 'pointer',
    right: '0',
    bottom: '10',
    bg: 'white',
    boxSize: '8',
    p: '1',
    borderRadius: 'sm',
    zIndex: 'overlay',
  }),
});
