import { v4 as uuid } from 'uuid';
import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ChevronDownCircle } from '@styled-icons/boxicons-regular/ChevronDownCircle';
import { Button } from '@/components/Button/Button';
import { useBlendedBet } from '@/components/Betslip/Services/Betslip.hooks';
import { useAppSelector } from '@/hooks/useRedux';
import { miniBetSlipStyles } from '@/views/sports/NewMatchDetailPage/styles/MiniBetSlip/MiniBetSlip.styles';
import { TSelections } from './services/Blended.hooks';
import { selectionStyles } from '../../../SRMulti/components/Selections/styles/Selections.styles';
import ErrorBanner from '../../../SRMulti/components/ErrorBanner/ErrorBanner';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

type TBlendedSelections = {
  selections: TSelections[];
  clearSelections: () => void;
  price: number | undefined;
  getRunnerByNumber(runnerNumber: number): any;
  venueId: string | null;
  displayErrorBanner: boolean;
  setDisplayErrorBanner(val: boolean): void;
  isLoading: boolean;
};

export default function BlendedSelections({
  selections,
  clearSelections,
  price,
  isLoading,
  getRunnerByNumber,
  venueId,
  displayErrorBanner,
  setDisplayErrorBanner,
}: TBlendedSelections) {
  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const { addToBetSlip } = useBlendedBet();
  const { setBet } = getBetSlipStoreActions();

  const venueObj = raceRunnerList?.venues?.find(
    (venue) => venue?.venue_id === venueId
  );

  const raceMeta = raceRunnerList?.raceMeta;

  const isMarketAvailable = !!venueObj && !!raceMeta && !!selections;

  if (!selections?.length || !isMarketAvailable) return null;

  return (
    <Flex {...selectionStyles.errorWrapper}>
      <Box {...miniBetSlipStyles.slideBox} w="full" position="relative">
        {displayErrorBanner && (
          <ErrorBanner setDisplayErrorBanner={setDisplayErrorBanner} />
        )}
        <Accordion allowToggle defaultIndex={0}>
          <AccordionItem {...miniBetSlipStyles.accordionItem}>
            {({ isExpanded }) => (
              <>
                <chakra.h2 {...miniBetSlipStyles.accordionHeaderText}>
                  <AccordionButton {...miniBetSlipStyles.accordionButton}>
                    <Icon
                      {...miniBetSlipStyles.accordionButtonIcon}
                      as={ChevronDownCircle}
                      transform={isExpanded ? 'scaleY(-1)' : undefined}
                    />
                    <Box as="span">
                      <FormattedMessage
                        id={`racing.srm.${
                          isExpanded ? `hideSelections` : `viewSelections`
                        }`}
                      />
                    </Box>
                  </AccordionButton>
                </chakra.h2>
                <AccordionPanel
                  as="dl"
                  pb={4}
                  {...miniBetSlipStyles.accordionPanel}
                >
                  {selections.map((selection, i) => {
                    const runner = getRunnerByNumber(
                      selection?.runner_number ?? 0
                    ); // Assuming `getRunnerByNumber` is a valid function
                    if (!runner) return;

                    return (
                      <Flex key={i} {...miniBetSlipStyles.selectionFlex}>
                        <chakra.dt {...miniBetSlipStyles.selectionMarketName}>
                          {runner?.number}. {runner?.display_name}{' '}
                          {!!runner?.barrier_number &&
                            `(${runner?.barrier_number})`}
                        </chakra.dt>
                        <chakra.dd
                          {...miniBetSlipStyles.selectionPropositionName}
                        >
                          <span>{selection.odds.toFixed(2)}</span>
                        </chakra.dd>
                      </Flex>
                    );
                  })}
                </AccordionPanel>
                <Stack
                  as="footer"
                  borderTopWidth={isExpanded ? '1px' : undefined}
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                  _before={{
                    opacity: !isExpanded ? '0' : undefined,
                  }}
                  {...miniBetSlipStyles.footerStack}
                >
                  <Flex {...selectionStyles.priceWrap}>
                    <Box {...selectionStyles.priceBox}>
                      <Text {...miniBetSlipStyles.oddsText}>
                        <FormattedMessage
                          id="sports.matchDetailPage.legs"
                          values={{
                            legs: selections.length,
                            strong: (chunks) => (
                              <chakra.strong {...miniBetSlipStyles.legsChunks}>
                                {chunks}
                              </chakra.strong>
                            ),
                          }}
                        />
                      </Text>
                    </Box>
                    <Box {...selectionStyles.priceBox}>
                      <Text {...miniBetSlipStyles.oddsText}>
                        <FormattedMessage
                          id="sports.matchDetailPage.odds"
                          values={{
                            odds: price?.toFixed(2),
                            hasOdds: !!price,
                            strong(chunks) {
                              return (
                                <chakra.strong
                                  {...miniBetSlipStyles.legsChunks}
                                >
                                  {chunks}
                                </chakra.strong>
                              );
                            },
                          }}
                        />
                      </Text>
                    </Box>
                  </Flex>
                  <HStack {...selectionStyles.buttonsWrap}>
                    <Button
                      {...miniBetSlipStyles.clearButton}
                      cursor="pointer"
                      onClick={clearSelections}
                    >
                      <FormattedMessage id="srm.clearSelections" />
                    </Button>

                    <Button
                      isDisabled={isLoading || selections.length <= 1}
                      isLoading={isLoading}
                      {...miniBetSlipStyles.addToSlipButton}
                      onClick={() => {
                        setBet({
                          id: uuid(),
                          type: 'Blended',
                          selection: selections,
                          odds: Number(price),
                          misc: {
                            ...raceMeta,
                            ...venueObj,
                            race: raceMeta,
                            venue: venueObj,
                          },
                        });

                        addToBetSlip({
                          race: raceMeta,
                          placedSelections: selections,
                          venue: venueObj,
                          selectionOdds: Number(price),
                        });
                      }}
                    >
                      <FormattedMessage id="racing.generic.addToBetSlip" />
                    </Button>
                  </HStack>
                </Stack>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
    </Flex>
  );
}
