/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { TTemplate } from '@/helpers/createTemplate';
import {
  FlexBetsPlacedContainer,
  IconBetsPlaced,
  TextBetsPlaced,
} from '../styles/Placed.styles';

const template = (
  bgAnimationRef: React.RefObject<HTMLDivElement>,
  iconAnimationRef: React.RefObject<SVGSVGElement>
): TTemplate => ({
  wrapper: (
    <FlexBetsPlacedContainer
      ref={bgAnimationRef}
      data-cy="betSlipConfirmationText"
    />
  ),
  icon: <IconBetsPlaced ref={iconAnimationRef} as={CheckCircle} />,
  betsPlacedText: (
    <TextBetsPlaced>
      <FormattedMessage id="betslip.betslipmodal.placedbetsconfirmation" />
    </TextBetsPlaced>
  ),
});

export default template;
