import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  fontFamily: 'accent',
};
export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'body',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'alpha.400',
  pos: 'relative',
  bottom: '2px',
};
export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'alpha.900',
  minW: 'unset',
  bgGradient: 'linear(to-b, alpha.100 0%, alpha.500 100%)',
  borderRadius: 'md',
  border: 'none',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  transition: 'all .3s ease-in-out',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  _focus: {
    bg: 'blackAlpha.600',
    color: 'alpha.400',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  },
};
export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};
export const GroupIcon: CSSObject = {
  color: 'white',
};
