import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import volleyball from '@/assets/wellbet/images/toggle/volleyball.svg';

const FlexWrapper = chakra(Flex, {
  baseStyle: () => ({
    borderRadius: 'base',
    contain: 'layout',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, null, 'unset'],
    mb: ['1', null, null, '2.5'],
    gap: '2',

    boxShadow: 'md',
    bg: ['gamma.500', null, 'white'],
  }),
});

const TextToggle = chakra(Text, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontSize: 'sm',
    p: '2',
    flex: [1, 'unset'],

    borderRadius: 'full',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    letterSpacing: '1px',
    px: '5',
    py: '1',
    maxW: '110px',
    textAlign: 'center',
    transition: 'all, 0.2s linear',
    color: ['yellow.900', null, 'alpha.600'],

    // This is just a placeholder
    ...(isActive && {
      color: ['yellow.900', null, 'alpha.600'],
    }),
    ...(!isActive && {
      color: ['yellow.900', null, 'alpha.600'],
    }),
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  baseStyle: () => ({
    bg: 'transparent',

    '.chakra-switch__thumb': {
      bgImage: `url(${volleyball})`,
      bgSize: '100%',
      boxShadow: '1px 0px 3px',
    },
    '.chakra-switch__track': {
      boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
      bg: ['gamma.900', null, 'alpha.700'],
    },

    _checked: {
      bg: 'transparent',

      '.chakra-switch__track': {
        boxShadow: 'inset 0px 3px 3px rgba(0, 0, 0, 0.3)',
        bg: ['gamma.900', null, 'alpha.700'],
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        Racing
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        Sport
      </TextToggle>
    </FlexWrapper>
  );
}
