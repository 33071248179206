import squigglyBorder from '@/assets/goldenbet888/images/sideNav/squigglyBorder.svg';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',
  },
  boxWrapperProps: {
    width: '100%',
    pos: 'relative',
    sx: {
      '&:before': {
        content: ['none', null, '""'],
        position: 'absolute',
        top: '10px',
        right: '10px',
        bottom: '10px',
        left: '10px',
        filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
        border: '0px solid transparent',
        borderImage: `url(${squigglyBorder}) 50`,
        pointerEvents: 'none',
        borderImageWidth: '50px',
        opacity: 0.6,
      },
    },
  },
  boxNavigationWrapperProps: {},
  navigationBoxProps: {
    mb: [4, null, 0],
  },
};
