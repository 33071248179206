import { CSSObject } from '@chakra-ui/react';
import backgroundImage from '../../../assets/fiestabet/images/backgrounds/overviewBg.png';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    pt: ['2', null, 'unset'],
    borderRadius: '0',
    mx: ['2', null, null, '0'],
  },
  boxHeading: {
    sx: {
      '&[data-hasPageHeader="true"]': {
        pb: ['2', null, '1'],
      },
    },
  },
  textTitle: {
    fontSize: 'md',
    fontWeight: 'medium',
    color: 'epsilon.100',
    mb: '2',
    mt: '0',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
      },
    },
  },
  accountInfoContainer: {
    bgImage: `${backgroundImage}`,
    bgColor: 'alpha.600',
    borderRadius: 'md',
    boxShadow: '0px 4px 0px 0px #370F23',
    color: 'alpha.700',
    pb: '2',
    px: [null, null, null, '2'],
    sx: {
      '&&': {
        flexDir: 'column',
      },
    },
  },
  titleContainer: {},
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
  },
};

export const BoxHeading: CSSObject = {
  bgImage: `${backgroundImage}`,
  bgColor: 'alpha.600',
  borderTopRadius: 'base',
  color: 'alpha.700',
  pb: '2',
  pt: '2',
  sx: {
    '&&': {
      mb: '0',
    },
  },
};
