import React, { ChangeEvent, FC, useState } from 'react';
import ReactDatePicker from 'react-date-picker';
import { OnChangeDateCallback } from 'react-calendar';
import { isMobile } from 'react-device-detect';
import { useField } from 'formik';
import dayjs from 'dayjs';
import { Input, InputGroup } from '@chakra-ui/react';
import { DateRange } from '@styled-icons/material/DateRange';
import FormControl from '@/components/FormElements/HOCs/FormControl';
import { getStrings } from '@/helpers/utils';
import { StyledDatePicker, DatePickerIcon } from './styles/DatePicker.styles';
import DatePickerProps from './types';

/**
 * This date picker use the native date picker on mobile devices or the react-date-picker component on anything else
 * See `https://github.com/wojtekmaj/react-date-picker` for maintenance
 */
const DatePicker: FC<DatePickerProps> = ({
  restrictedDirection,
  label,
  errorText,
  name,
  onDateChange,
  signup,
  ...rest
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [{ Generic }] = getStrings();

  const { 2: helpers } = useField(name);
  const { setValue, setError } = helpers;

  const handlePickerDateChange: OnChangeDateCallback = (value) => {
    const today = dayjs();

    if (restrictedDirection === 'future' && dayjs(value) > today) {
      // Prevent future dates being picked
      return setError(Generic.RestrictedFuture);
    }
    if (restrictedDirection === 'past' && dayjs(value) < today) {
      // Prevent past dates being picked
      return setError(Generic.RestrictedPast);
    }

    setValue(value);
    onDateChange?.(value);
  };

  const handleDateChangeNative = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedDate = dayjs(e.target.value);

    if (!parsedDate.isValid()) {
      return setError(Generic.PickDate);
    }

    const today = dayjs();

    if (restrictedDirection === 'future' && parsedDate > today) {
      // Prevent future dates being picked
      return setError(Generic.RestrictedFuture);
    }
    if (restrictedDirection === 'past' && parsedDate < today) {
      // Prevent past dates being picked
      return setError(Generic.RestrictedPast);
    }

    const value = parsedDate.toDate();

    setValue(value);
    onDateChange?.(value);
  };

  return (
    <StyledDatePicker>
      <FormControl
        id={`formElement-DP-${name}`}
        error={errorText}
        label={label}
      >
        <>
          {isMobile ? (
            <InputGroup>
              <Input type="date" onChange={handleDateChangeNative} />
            </InputGroup>
          ) : (
            <ReactDatePicker
              onChange={handlePickerDateChange}
              name={name}
              isOpen={showPicker}
              onCalendarOpen={() => setShowPicker(true)}
              onCalendarClose={() => setShowPicker(false)}
              calendarIcon={<DatePickerIcon as={DateRange} />}
              format="yy-MM-dd"
              {...rest}
            />
          )}
        </>
      </FormControl>
    </StyledDatePicker>
  );
};

export default DatePicker;
