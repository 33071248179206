import { CSSObject } from '@chakra-ui/react';

export const LoginButton: CSSObject = {
  bg: '#FD62FF',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.45), 0px 0px 0px 2px rgba(254, 153, 255, 0.70) inset',
  fontSize: '13px',
  pb: '1',
  fontFamily: 'Sansita One',
  fontWeight: '400',
  borderColor: 'white',
  color: 'beta.700',
  h: '36px',
  _hover: {
    opacity: '0.4',
  },
  _active: {
    bg: 'alpha.800',
  },
};

export const LinkSignup: CSSObject = {
  display: 'none',
};

export const LinkForgotPassword: CSSObject = {
  color: 'alpha.500',
  fontSize: 'xs',
  fontWeight: 'medium',
};
