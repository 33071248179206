import { BoxProps } from '@chakra-ui/react';
import { getThemeName } from '@/helpers/getThemeConfig';
import { styleImports } from './Form.styles.imports';
import { CustomButtonProps } from '@/components/Button/Button';

const themeName = getThemeName();

export const buttonLogInProps: CustomButtonProps = {
  'data-testid': 'form-buttonLogInProps',

  ...(() => {
    try {
      return styleImports[themeName]?.buttonLogInProps;
    } catch (err) {
      return {};
    }
  })(),
};

export const forgotPasswordContainerProps: BoxProps = {
  textAlign: 'right',
  mb: '4',
  mt: '-2',
  ...(() => {
    try {
      return styleImports[themeName]?.forgotPasswordContainerProps;
    } catch (err) {
      return {};
    }
  })(),
};
