import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    color: 'white',
    textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
    fontSize: '2xl',
    fontFamily: 'Spectral',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  navOverride: {
    mb: '0',
  },
  divider: {
    mb: '0',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
  hasNextPageWellOverride: {
    w: '100%',
  },
};
