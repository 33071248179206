import {
  EBetSlipBetSubmissionType,
  EBetSlipViewMode,
  TBetSlipState,
} from './Betslip.types';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';

export const InitialBetSlipState: TBetSlipState = {
  betSlipOpen: false,
  viewMode: EBetSlipViewMode.EditingBets,
  showFieldErrors: [],
  generalError: false,
  totalStake: 0.0,
  potentialReturns: 0.0,
  bets: [],
  submissionLoading: false,
  hasKeypad: false,
  storedDecimal: '',
  betIDFocused: '',
  hasSufficientFunds: true,
  displayOddsChangeBanner: false,
  displayEventsClosedBanner: false,
  updateConfirmFlag: false,
  bonusChance: null,
};

export const getInitialBetSlipState = () => {
  if (IS_MOBILE_APP) {
    return window?.MobileApp_Betslip !== undefined
      ? (window.MobileApp_Betslip as TBetSlipState)
      : InitialBetSlipState;
  }

  return InitialBetSlipState;
};

// This dictates the order that will appear in the bet slip modal
// Multi bets will be added here
export const DisplayedBetTypes = [
  EBetSlipBetSubmissionType.Single,
  EBetSlipBetSubmissionType.Exotics,
  EBetSlipBetSubmissionType.SGMulti,
  EBetSlipBetSubmissionType.Multi,
  EBetSlipBetSubmissionType.EachWay,
  EBetSlipBetSubmissionType.SRMulti,
  EBetSlipBetSubmissionType.Blended,
  EBetSlipBetSubmissionType.ToteMulti,
];
