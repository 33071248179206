import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mx: '0',
    rowGap: 'unset',

    sx: {
      '&&&': {
        gap: ['3', null, null, '4'],
      },
    },
  },

  FlexCard: {
    borderRadius: 'lg',
    bg: 'alpha.900',
    boxShadow:
      '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12)',
    backdropFilter: 'blur(2.5px)',
  },

  LinkCardHeader: {
    bg: 'transparent',
    color: 'beta.400',
  },

  TextCardHeading: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },

  TextRaceNumber: {
    bg: '#FFC31C',
    borderRadius: 'sm',
    color: 'blackAlpha.700',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    fontFamily: 'accent',
    lineHeight: 'normal',
  },

  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    p: '0',
  },

  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
    backdropFilter: 'blur(2.5px)',
  },

  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.400',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    px: '2.5',
  },

  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'bold',
    textShadow: 'none',
  },

  TextRunnerNumber: {
    sx: {
      _last: {
        ':not(:only-of-type)': {
          fontSize: '2xs',
          fontWeight: 'normal',
          pos: 'relative',
          top: '-2px',
          right: '0.5px',
        },
      },
    },
  },

  TextScratched: {
    color: 'white',
  },

  LinkRaceDetails: {
    color: 'alpha.700',
    bgGradient: 'linear(to-b, gamma.300, gamma.500)',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.15)',
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.35)',
    lineHeight: '1.9',
    borderRadius: 'base',
    h: '42px',
    _hover: {
      bgGradient: 'linear(to-b, gamma.200, gamma.200)',
      color: 'alpha.700',
    },
    m: 2.5,
  },

  buttonRaceCardOddsProps: {
    w: '100%',
    fontSize: '15px',
    variant: 'odds',
    h: '10',
    borderRadius: 'md',
    backdropFilter: 'blur(2.5px)',
  },
};

export const IconCardHeader: CSSObject = {
  color: 'beta.400',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
