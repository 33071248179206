import { css } from 'styled-components';

export const sportsContainer = css``;

export const listHeading = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-style: italic;
  font-size: var(--bc-fontSizes-md);
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const listContainer = css`
  z-index: 9;
`;

export const pageHeading = css`
  color: ${({ theme }) => theme.basePalette.blue};
  font-style: italic;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const bgIcon = css`
  position: absolute;
  width: 480px;
  height: 450px;
  right: 0;
  top: 140px;
  z-index: 8;
  display: none;

  ${({ theme }) => theme.device.tablet} {
    display: block;
  }
`;
