import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';
import koi from '@/assets/goldenbet888/images/homeTheming/koi.png';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '2',
  },

  FlexCard: {
    my: '1',
    borderRadius: 'lg',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    backgroundImage: `url(${koi}), linear-gradient(180deg, var(--bc-colors-alpha-200), var(--bc-colors-alpha-500))`,
    backgroundPosition: '-370px 0px',
    bgSize: '364px',
    bgBlendMode: 'overlay',
  },

  LinkCardHeader: {
    bg: 'transparent',
    color: 'beta.400',
  },

  TextCardHeading: {
    color: 'gamma.200',
    fontFamily: 'accentMusashi',
    fontSize: 'md',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    letterSpacing: '1px',
  },

  TextRaceNumber: {
    bg: 'alpha.700',
    borderRadius: '3px',
    color: 'gamma.400',
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.2)',
    lineHeight: 'normal',
  },

  GridCardContent: {
    color: 'alpha.700',
    fontSize: 'sm',
    px: '3px',
    py: '0',
  },

  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'gamma.200',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
    px: '2.5',
  },

  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px dashed',
    borderBottomColor: 'blackAlpha.300',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    px: '2.5',
  },

  TextRunner: {
    fontSize: 'xs',
    color: 'gamma.200',
    fontWeight: 'bold',
  },

  TextRunnerNumber: {
    sx: {
      _last: {
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },

  TextScratched: {
    color: 'gamma.200',
  },

  LinkRaceDetails: {
    borderRadius: 'md',
    h: '42px',
    lineHeight: 'taller',
    bgGradient: 'linear(to-b, gamma.500 0%, gamma.600 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    color: 'blackAlpha.700',
    fontFamily: 'accentStaat',
    fontSize: 'sm',
    textTransform: 'capitalize',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    _hover: {
      bgGradient: 'linear(to-b, gamma.300 0%, gamma.300 100%)',
      boxShadow:
        '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
      color: 'blackAlpha.700',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    },
    pt: 2.5,
    m: 2.5,
  },

  buttonRaceCardOddsProps: {
    w: '44px',
    fontSize: 'sm',
    variant: 'odds',
    h: '9',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};

export const IconCardHeader: CSSObject = {
  color: 'gamma.200',
};
