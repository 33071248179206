import { CSSObject } from '@chakra-ui/react';

export const HeaderText: CSSObject = {
  color: 'beta.800',
  fontSize: 'md',
  fontWeight: 'bold',
  p: '0',
  textTransform: 'none',
};

export const HeaderContainer: CSSObject = {
  px: '3',
  pt: '3',
  pb: '0',
};

export const CloseButton: CSSObject = {
  display: 'none',
};

export const ContentContainer: CSSObject = {
  color: 'white',
  pb: '3',
  pt: '3',
  px: '3',
};

export const ModalBoxWrapper: CSSObject = {
  borderRadius: 'base',
  bg: 'beta.600',
};
