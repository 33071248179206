import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: ['1', null, null, 'unset'],
    mb: 4,
    borderRadius: 'md',
    bg: 'rgba(129, 83, 255, 0.5)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  },
  boxHeading: {
    pt: '0',
    mb: 0,
    mx: ['-2', 0],
    ...(!IS_MOBILE_APP && {
      pb: '9',
    }),
    color: 'white',
  },
  textTitle: {
    mt: '0',
    color: 'white',
    fontSize: ['lg', 'xl'],
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderBottomRadius: 'md',
        pb: '2.5',
        display: 'flex',
        gap: ['0', null, '4'],
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pb: '2',
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    fontFamily: 'accent',
    flexDir: 'column',
    w: '100%',
    borderRadius: 'md',
    px: '3',
    py: '3',
    bg: 'rgba(114, 68, 238, 1)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  },
};
export const BoxHeading: CSSObject = {
  pt: '0',
  mb: 0,
  ...(!IS_MOBILE_APP && {
    pb: '9',
  }),
  color: 'white',
};
