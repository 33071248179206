import { CSSObject } from '@chakra-ui/react';
import innerBg from '@/assets/junglebet/images/switch/toggleInnerBg.png';

export const FlexBetslipHeading: CSSObject = {
  color: 'delta.800',
  h: '12',
  bgImg: `url(${innerBg})`,
  bgPos: 'center',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  borderBottom: '1px',
  borderBottomColor: 'rgba(0, 0, 0, 0.15)',
  px: '3',
  m: '2.5',
  mb: '0',
  borderRadius: 'lg',
  boxShadow:
    '0px 5px 4px 0px rgba(0, 0, 0, 0.20), 0px 3px 0px 0px #A87A42, 0px 0px 0px 3px rgba(255, 255, 255, 0.30) inset',
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontFamily: 'CarterOne',
  color: 'delta.500',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const BetLengthIndicator: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
  borderRadius: 'base',
  bg: 'delta.500',
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  lineHeight: 'normal',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'delta.500',
  boxSize: '5',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const CloseIconButton: CSSObject = {
  color: 'delta.500',
  bg: 'transparent',
  svg: {
    boxSize: '7',
  },
  _hover: {
    bg: 'transparent',
  },
};

export const BetslipTitleText: CSSObject = {
  textShadow: 'none',
};
