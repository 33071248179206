import { CSSObject } from '@chakra-ui/react';

export const SideNavHeading: CSSObject = {
  fontFamily: 'Caveman',
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  color: 'alpha.500',
  letterSpacing: 'widest',
  px: '2',
  pt: '0',
  pb: '1',
  lineHeight: 'none',
  height: '40px',
  alignItems: 'end',
};
