import { CSSObject } from '@chakra-ui/react';

export const RaceMeetingsList: CSSObject = {
  px: '0',
};

export const NextToJumpListWrapper: CSSObject = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'beta.500',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
};
