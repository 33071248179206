import React, { FC, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { logError } from '@/helpers/utils';

import { Container } from './Styles';
import { ReactComponent as DefaultIcon } from '../../assets/core/images/sports/default.svg';

import { SVGIconProps, DynamicSVGImportResponse } from './types';

const { REACT_APP_THEME } = window.BETCLOUD_ENV;

const useSVGImport = (
  themeName: string,
  svgName: string
): DynamicSVGImportResponse => {
  const IconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    (async () => {
      const nameSplit = svgName.split('sports/');
      const iconName = nameSplit.length > 1 ? nameSplit[1] : svgName;
      const iconNameParse = iconName.replace(/ /g, '_');

      try {
        // webpack loader prefix added as webpack dynamic imports have a bug
        // importing SVG's as react-components unless explicitly pre-pending the loader
        // Read more here: https://github.com/facebook/create-react-app/issues/5276#issuecomment-665628393
        IconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/${REACT_APP_THEME}/icons/${iconNameParse}.svg`
          )
        ).default;
      } catch (err: unknown) {
        try {
          IconRef.current = (
            await import(
              `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/core/icons/${iconNameParse}.svg`
            )
          ).default;
        } catch (er) {
          if (er instanceof Error) {
            if (isMounted) setError(er);
          }
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    })().catch(logError);

    return () => {
      isMounted = false;
    };
  }, [svgName, themeName]);

  return { error, loading, Icon: IconRef.current };
};

const SVGIcon: FC<SVGIconProps> = ({
  svgName,
  styles,
  onClick = () => null,
  ...rest
}) => {
  const theme = useTheme();
  const { Icon, error } = useSVGImport(theme.themeName, svgName);
  // eslint-disable-next-line no-console
  if (error)
    console.warn(
      "Could't load svg, ensure you have the correct file and theme names",
      { error }
    );

  return (
    <Container styles={styles} onClick={onClick} {...rest}>
      {Icon ? <Icon width="100%" height="100%" /> : <DefaultIcon />}
    </Container>
  );
};

export default SVGIcon;
