import * as betroyale from './InlineLink.styles.betroyale';
import * as goldenrush from './InlineLink.styles.goldenrush';
import * as juicybet from './InlineLink.styles.juicybet';
import * as puntcity from './InlineLink.styles.puntcity';
import * as questbet from './InlineLink.styles.questbet';
import * as vikingbet from './InlineLink.styles.vikingbet';
import * as volcanobet from './InlineLink.styles.volcanobet';
import * as junglebet from './InlineLink.styles.junglebet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betroyale,
  goldenrush,
  juicybet,
  puntcity,
  questbet,
  vikingbet,
  volcanobet,
  junglebet,
};
